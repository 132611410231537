import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import includes from 'lodash/includes'

import { connect } from 'react-redux'

import { Sidebar, Header, Footer } from '@/layout-components'
import { Outlet } from 'react-router-dom'

const LeftSidebar = (props) => {
  const {
    sidebarToggle,
    sidebarToggleMobile,
    sidebarFixed,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    notificationsDrawerToggle,
    footerFixed,
    contentBackground,
    setOpenFeedbackTour,
  } = props
  return (
    <>
      <div
        className={clsx('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'notification-drawer-open': notificationsDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover,
        })}
      >
        <div>
          <Sidebar
            collapsed={props.collapsed}
            setOpenFeedbackTour={setOpenFeedbackTour}
          />
        </div>
        <div className="app-main">
          <Header
            collapsed={props.collapsed}
            hideBackButton={props.hideBackButton}
          />
          <div className="app-content">
            <div
              className="app-content--inner"
              style={
                props.hideBackButton ? { padding: '.5rem' } : null
              }
            >
              <div className="app-content--inner__wrapper">
                <Outlet />
              </div>
            </div>
            {!includes(window.location.href, 'property-details') &&
              !includes(window.location.href, 'editLot') && (
                <Footer />
              )}
          </div>
        </div>
      </div>
    </>
  )
}

LeftSidebar.propTypes = {
  children: PropTypes.node,
}

const mapStateToProps = (state) => ({
  sidebarToggle: state.DeprecatedThemeOptions.sidebarToggle,
  sidebarToggleMobile:
    state.DeprecatedThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.DeprecatedThemeOptions.sidebarFixed,
  headerFixed: state.DeprecatedThemeOptions.headerFixed,
  headerSearchHover: state.DeprecatedThemeOptions.headerSearchHover,
  headerDrawerToggle: state.DeprecatedThemeOptions.headerDrawerToggle,
  notificationsDrawerToggle:
    state.DeprecatedThemeOptions.notificationsDrawerToggle,

  footerFixed: state.DeprecatedThemeOptions.footerFixed,

  contentBackground: state.DeprecatedThemeOptions.contentBackground,
})

export default connect(mapStateToProps)(LeftSidebar)
