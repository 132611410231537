import React from 'react'

import { ExampleWrapperSeamless } from '@/layout-components'

import PropPage from '@/housefolios-components/PublicProperty/PublicPropertyDashboard'

import { Grid } from '@mui/material'

export default function PublicPropertyDashboardPage(props) {
  return (
    <>
      <ExampleWrapperSeamless>
        <Grid container>
          <PropPage {...props} />
        </Grid>
      </ExampleWrapperSeamless>
    </>
  )
}
