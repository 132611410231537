import { memo, useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Popper from '@mui/material/Popper'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { alpha, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'

function DeleteColumn({
  onSubmit,
  title,
  children,
  label = '',
  type = 'text',
  variant = 'standard',
  columnId,
}) {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSave = () => {
    // console.log('Column Name:', columnName) // Here you would typically add this to your state managing the columns
    onSubmit(columnId)
    handleClose()
  }
  return (
    <div
      style={{ minWidth: 'fit-content', padding: 8, marginTop: 9 }}
    >
      {' '}
      {/* <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button> */}
      {children({ onClick: handleClickOpen })}
      {/* Dialog for adding a new column */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to delete this column and move all
            properties in it to the first column?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default memo(DeleteColumn)
