import Reactour from 'reactour'
import { Button, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'

export const FeedbackTour =
  ({
    isOpenFeedbackTour,
    closeFeedbackTour,
  }) => {
    let goToTo
    let shifted = false
    const lockTour = false
    const { pathname } = useLocation()
    const steps = [
      {
        // selector: '#hubspot-messages-iframe-container',
        //selector: '[data-intercom-frame="true"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
                className="mb-2"
              >
                We encourage feedback as it helps us know how what we
                are doing right and what we can do better.{' '}
              </Typography>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Please give us your feedback in our chat system and
                our support team will take it from there.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenFeedbackTour}
        onRequestClose={closeFeedbackTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }
