/**
 * @ Author: Housefolios
 * @ Create Time: 2022-06-17 10:57:13
 * @ Modified by: David Helmick
 * @ Modified time: 2024-11-19 09:04:57
 * @ Description: BOR Strategy Analysis page
 */

import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import Reactour from 'reactour'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

import CountUp from 'react-countup'
import filter from 'lodash/filter'
import map from 'lodash/map'
import maxBy from 'lodash/maxBy'
import meanBy from 'lodash/meanBy'
import minBy from 'lodash/minBy'
import round from 'lodash/round'
import sum from 'lodash/sum'
import isUndefined from 'lodash/isUndefined'
import difference from 'lodash/difference'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import slice from 'lodash/slice'
const Chart = lazy(() => import('react-apexcharts'))
import isNil from 'lodash/isNil'
import concat from 'lodash/concat'
import head from 'lodash/head'
import last from 'lodash/last'
import includes from 'lodash/includes'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import { useLocation } from 'react-router-dom'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  Divider,
  FormGroup,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  LinearProgress,
  Tooltip,
  Typography,
  Drawer,
  IconButton,
  FormControl,
  Collapse,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'

import { arrayMoveImmutable } from 'array-move'
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc'

import ContactAgent from '../../../Marketplace/ContactAgent'
import GaugeChart from 'react-gauge-chart'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { useMutation } from '@apollo/client'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { Close } from '@mui/icons-material'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { enqueueSnackbar } from 'notistack'
import { assumptionsOptions } from '@/housefolios-components/AssumptionsSettings/Accordion'
import { GET_CARDS } from '@/housefolios-components/Properties/queries'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'

const pieColors = [
  '#bebebd',
  '#008ffb',
  '#ff6efb',
  '#69A251',
  '#38CDC3',
  '#e3e44f',
  '#feb019',
  '#45818E',
  '#d26767',
]

function CenterPageBOR(props) {
  const {
    property,
    analysisInput,
    assumptionsInput,
    renderInputFields,
  } = props

  const [openBORStrategyTour, setOpenBORStrategyTour] =
    useState(false)
  const [openBORTargetsTour, setOpenBORTargetsTour] = useState(false)
  const [openBORBORDetailsTour, setOpenBORBORDetailsTour] =
    useState(false)
  const [openBORBORInputsTour, setOpenBORBORInputsTour] =
    useState(false)
  const [
    openBORComparablePropertiesTour,
    setOpenBORComparablePropertiesTour,
  ] = useState(false)

  const BORStrategyTour = ({
    isOpenBORStrategyTour,
    closeBORStrategyTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="strategy-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                At a glance you can see how well this property will do
                if you follow this strategy. It's based on the targets
                that were either set by you or by default.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="make-offer-button"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Want to purchase this property? Fill out the Make
                Offer form to take the next steps!
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBORStrategyTour}
        onRequestClose={closeBORStrategyTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BORTargetsTour = ({
    isOpenBORTargetsTour,
    closeBORTargetsTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="targets-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The amount of money left over after subtracting the
                Purchase Price from the Resale Price
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="targets-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                IRR measures an investment’s annual return throughout
                the entire property ownership time frame. Unlike a
                cash-on-cash return or cap rate that measures returns
                in annual segments, IRR weighs total earnings from the
                day you purchase your property to the day you sell it.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBORTargetsTour}
        onRequestClose={closeBORTargetsTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BORBORDetailsTour = ({
    isOpenBORBORDetailsTour,
    closeBORBORDetailsTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="netProfitFromSale-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The selling price of the property
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="netProfitFromSale-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Total amount of initial investment into a property,
                including renovation.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="netProfitFromSale-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Total yearly income.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="netProfitFromSale-step-4"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The Net Profit is the Resale Price minus the Total
                Expenses
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBORBORDetailsTour}
        onRequestClose={closeBORBORDetailsTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BORBORInputsTour = ({
    isOpenBORBORInputsTour,
    closeBORBORInputsTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="BORDetails-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The price that the seller is asking for this home.
                *Not necessarily the price that the investor will
                purchase the home for.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="BORDetails-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The ARV is the value of a property after you have
                conducted repairs and are ready to sell
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="BORDetails-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The estimated closing cost the end buyer will have for
                a BOR property. Expressed as a dollar amount or
                percentage of ARV minus the rehab.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="BORDetails-step-4"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The monthly house payment.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="rehabExpenses-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                An estimate for how much rehab is needed for the
                subject property.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="BORDetails-step-6"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                An estimate for how much rehab is needed for the
                subject property per decade the property was built.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="financing-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The interest rate is the amount a lender charges for
                the use of capital, expressed as a percentage of the
                principal. The interest rate is typically noted on an
                annual basis known as the annual percentage rate
                (APR).
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },

      {
        selector: '[data-tour="BORDetails-step-8"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The amount of cash that a purchaser must put down,
                toward the purchase price, to buy real estate.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="financing-step-4"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The length of time that the loan is being amortized.
                Expressed in years.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBORBORInputsTour}
        onRequestClose={closeBORBORInputsTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BORComparablePropertiesTour = ({
    isOpenBORComparablePropertiesTour,
    closeBORComparablePropertiesTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="comparableProperties-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The amount of Comparable Properties we found
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="comparableProperties-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The amount of Potential Comps we found
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="comparableProperties-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The average adjusted value of the comparable
                properties that we found
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="comparableProperties-step-4"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The lowest and highest comparable property prices make
                up the range
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="comparableProperties-step-5"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The average adjusted value of the comparable
                properties divided by sqft
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="comparableProperties-step-6"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The average adjusted value of the three lowest priced
                comparable properties
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="comparableProperties-step-7"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Click this button to get a detailed look at your
                comparable properties and potential comps
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBORComparablePropertiesTour}
        onRequestClose={closeBORComparablePropertiesTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  let results = useQuery(GET_CARDS, {
    variables: { view: 'BOR' },
  })
  let data = results.data || {}
  const cardsLoading = results.loading
  const cardsError = results.error
  let { cards = [] } = data

  const { activeOrg = {} } = props.session.me || {}
  const { acquisition = {}, assumptions = {} } = property
  const { analytics = DEFAULT_ANALYTICS, analysis = {} } = acquisition

  let IRRDataFirst = {}
  let IRRData = []

  const [activeTab, setActiveTab] = useState('0')

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const IRRTable = (args) => {
    let {
      valueAppreciation,
      holdingPeriod,
      cashFlowTotal,
      arrInHoldingPeriodMonthly,
      expensesInHoldingPeriodMonthly,
      totalOutOfPocket,
      growthRateIncome,
      growthRateExpense,
    } = args

    let income = arrInHoldingPeriodMonthly
    let cashFlowInHoldingPeriodMonthly =
      income - expensesInHoldingPeriodMonthly
    cashFlowTotal += cashFlowInHoldingPeriodMonthly * 12

    let month
    let year
    let monthYear

    const today = new Date()

    const IRRDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1,
    )

    for (let index = 1; index < holdingPeriod + 1; index++) {
      if (index === 1) {
        month = IRRDate.toLocaleString('default', { month: 'long' })
        year = IRRDate.getFullYear().toString()
        monthYear = month + ' ' + year
        IRRDataFirst = {
          totalOutOfPocket,
          monthYear,
        }
      } else {
        IRRDate.setMonth(IRRDate.getMonth() + 1)
        let month = IRRDate.toLocaleString('default', {
          month: 'long',
        })
        let year = IRRDate.getFullYear().toString()
        monthYear = month + ' ' + year
        if (index % 12 === 1) {
          IRRData.push({
            flow:
              IRRData.length === 0
                ? cashFlowTotal
                : arrInHoldingPeriodMonthly,
            monthYear,
          })
        }
      }
    }
  }

  IRRTable({
    valueAppreciation: analysis.afterRepairValue,
    holdingPeriod: assumptions.termBOR * 12,
    cashFlowTotal: assumptions.downPaymentBOR,
    arrInHoldingPeriodMonthly: sum(analytics.BOR.annualRentBOR) / 12,
    expensesInHoldingPeriodMonthly: 0,
    totalOutOfPocket: analytics.BOR.allInCostBOR,
    growthRateIncome: assumptions.growthRateIncome,
    growthRateExpense: assumptions.growthRateIncome,
  })

  const tableHeadersFirst = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Total Out of Pocket', align: 'center' },
  ]

  const tableHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Flow', align: 'center' },
  ]

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const currencyFormat2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })

  //const [loader, setLoader] = useState(false)
  const [modal1, setModal1] = useState(null)

  const toggle1 = (name) => {
    setModal1(name)
    //setLoader(false)
  }

  const toggle1Close = () => {
    setModal1(null)
    //setLoader(false)
  }

  const [accordion, setAccordion] = useState(-1)

  const toggleAccordion = (index) => {
    if (index === accordion) setAccordion(-1)
    else setAccordion(index)
  }

  const [maxOfferDescription, setMaxOfferDescription] =
    useState(false)

  const toggleMaxOffer = () => {
    setMaxOfferDescription(!maxOfferDescription)
  }

  const BORFields = filter(
    [
      {
        type: 'dollar',
        name: 'offerPrice',
        label: 'Purchase Price',
        tooltipButtonText: 'Show Purchase Price data',
        value:
          analysisInput.offerPrice === null
            ? //? analysisInput.listPrice.toString()
              ''
            : analysisInput.offerPrice.toString(),
        helper:
          !analysisInput.offerPrice ||
          analysisInput.offerPrice === '0'
            ? '0% Discount to Price'
            : (
                (1 -
                  analysisInput.offerPrice /
                    analysisInput.listPrice) *
                100
              ).toFixed(0) + '% Discount to Price',
        xs: 12,
        lg: 6,
        definition: 'offerPrice',
        tooltipField:
          'The price that the seller is asking for this home. *Not necessarily the price that the investor will purchase the home for.',
        dataTour: 'BORDetails-step-1',
      },
      {
        type: 'dollar',
        name: includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        )
          ? 'afterRepairValueBOR'
          : 'afterRepairValue',
        label: 'After Repair Value',
        tooltipButtonText: 'Show ARV data',
        value: includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        )
          ? analysisInput.afterRepairValueBOR === null
            ? ''
            : analysisInput.afterRepairValueBOR.toString()
          : analysisInput.afterRepairValue === null
            ? ''
            : analysisInput.afterRepairValue.toString(),
        //helper: currencyFormat.format(analytics.BOR.equity) + ' in equity',
        xs: 12,
        lg: 6,
        definition: 'afterRepairValue',
        definitionTarget: includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        )
          ? 'afterRepairValueBOR'
          : 'afterRepairValue',
        tooltipField:
          'The ARV is the value of a property after you have conducted repairs and are ready to sell',
        dataTour: 'BORDetails-step-2',
      },
      {
        type: 'dropdown',
        name: 'closingCostBOR',
        value: assumptionsInput.closingCostBOR,
        label: 'Closing Cost',
        adornmentToggle: assumptionsInput.closingCostBORType,
        adornment: assumptionsInput.closingCostBORType,
        adornmentName: 'closingCostBORType',
        xs: 12,
        lg: 6,
        assumptions: true,
        dropdown: true,
        tooltipField:
          'Fees (attorney/title fees, etc) Paid at Closing. Expressed as a dollar amount or precentage of the purchase prices.',
        dataTour: 'BORDetails-step-3',
      },
      {
        type: 'dollar',
        name: `rentBOR.${props.activeUnit}`,
        label: 'Monthly Income',
        tooltipButtonText: 'Show Monthly Income data',
        value: !analysisInput.rentBOR[props.activeUnit]
          ? ''
          : analysisInput.rentBOR[props.activeUnit].toString(),
        helper:
          property.sqft &&
          property.sqft[props.activeUnit] &&
          analysisInput.rentBOR[props.activeUnit]
            ? currencyFormat2.format(
                analysisInput.rentBOR[props.activeUnit] /
                  property.sqft[props.activeUnit],
              ) + ' rent per sqft'
            : '$0.00 rent per sqft',
        xs: 12,
        lg: 6,
        definition: 'rentBOR',
        // disabled: !isNil(analysisInput.ownerOccupiedBOR) && props.activeUnit === analysisInput.ownerOccupiedBOR,
        tooltipField: 'The monthly house payment',
        dataTour: 'BORDetails-step-4',
      },
      // {
      //   type: "select",
      //   name: "rehabType",
      //   label: "Rehab Type",
      //   value: assumptionsInput.rehabType,
      //   xs: 12,
      //   options: assumptionsOptions(props.session.me.activeOrg)?.rehabOptions,
      //   assumptions: true
      // },
      // {
      //   type: "dollar",
      //   name: "rehabCost",
      //   label: "Est Rehab",
      //   value: analysisInput.rehabCost,
      //   tooltipButtonText: "Show Estimated Rehab data",
      //   xs: 12,
      //   lg: 6,
      //   definition: "rehabCost",
      //   tooltipField: "An expense that is incurred when restoring an entity to its normal or near-normal condition.",
      //   hide: assumptionsInput.rehabType !== 'singleRehabValue',
      //   dataTour: "rehabExpenses-step-1"
      // },
      // {
      //   type: "budgetLines",
      //   name: "detailedRehabCost",
      //   label: "Rehab Costs",
      //   value: analysisInput.detailedRehabCost,
      //   xs: 12,
      //   options: [
      //     "Appliances",
      //     "Cabinets/Vanities",
      //     "Carpet",
      //     "Countertops/Sinks/Toilets",
      //     "Driveway/Decking/Patio",
      //     "Electrical",
      //     "Exterior",
      //     "Flooring",
      //     "Framing",
      //     "Insulation/Drywall",
      //     "Landscaping",
      //     "Masonry",
      //     "Mechanical",
      //     "Molding/Tile/Trim",
      //     "Paint",
      //     "Plans/Permits",
      //     "Plumbing",
      //     "Roof",
      //     "Siding/Gutters",
      //     "Site Prep",
      //     "Staging",
      //     "Windows/Doors",
      //     "Other"
      //   ],
      //   hide: assumptionsInput.rehabType !== 'detailedRehab',
      //   dataTour: "rehabExpenses-step-1"
      // },
      {
        type: 'select',
        name: 'rehabTierBOR',
        label: 'Rehab Tier',
        value: assumptionsInput.rehabTierBOR,
        tooltipButtonText: 'Show Estimated Rehab data',
        xs: 12,
        lg: 6,
        options: filter(
          activeOrg.rehabTiers,
          (option) =>
            isEmpty(option.showMarkets) ||
            includes(
              option.showMarkets,
              analysisInput.marketType ||
                props.affiliate?.defaultMarketType,
            ),
        ),
        optionName: 'rehabTiers',
        memberOptions: filter(
          activeOrg.memberRehabTiers,
          (option) =>
            isEmpty(option.showMarkets) ||
            includes(
              option.showMarkets,
              analysisInput.marketType ||
                props.affiliate?.defaultMarketType,
            ),
        ),
        dynamicMemberOptions: map(
          filter(
            activeOrg.dynamicMemberRehabTiers,
            (option) =>
              isEmpty(option.showMarkets) ||
              includes(
                option.showMarkets,
                analysisInput.marketType ||
                  props.affiliate?.defaultMarketType,
              ),
          ),
          (option) => {
            let breakPointIndex = 0
            const arv = includes(
              assumptionsInput.strategySpecificFields,
              'afterRepairValue',
            )
              ? analysisInput.afterRepairValueBOR === null
                ? null
                : analysisInput.afterRepairValueBOR
              : analysisInput.afterRepairValue === null
                ? null
                : analysisInput.afterRepairValue
            while (
              option?.breakpoints[breakPointIndex] < arv &&
              breakPointIndex < option?.breakpoints.length
            ) {
              breakPointIndex++
            }
            return {
              ...option,
              label: `$${option.values[breakPointIndex]}/Sqft`,
            }
          },
        ),
        removeOption: true,
        assumptions: true,
        //hide: assumptionsInput.rehabType !== 'rehabTier' && assumptionsInput.rehabType !== 'flippingAmericaRehab',
        dataTour: 'rehabExpenses-step-1',
        helper: currencyFormat.format(
          analytics.BOR.rehabTierValueBOR,
        ),
      },
      {
        name: 'marketType',
        value: !analysisInput.marketType
          ? props.affiliate?.defaultMarketType
          : analysisInput.marketType,
        label: 'Market Type',
        options: concat(
          [props.affiliate?.defaultMarketType],
          props.affiliate?.marketTypes,
        ),
        type: 'select',
        xs: 12,
        lg: 6,
        hide: isEmpty(props.affiliate?.marketTypes),
      },
      {
        type: 'dollar',
        name: 'rehabDecadeCostBOR',
        label: 'Rehab Per Decade',
        value: assumptionsInput.rehabDecadeCostBOR,
        xs: 12,
        lg: 6,
        assumptions: true,
        dataTour: 'BORDetails-step-6',
        //hide: assumptionsInput.rehabType !== 'flippingAmericaRehab'
      },
      {
        type: 'percentage',
        name: 'rateBOR',
        label: 'Interest Rate',
        value: assumptionsInput.rateBOR || 0,
        xs: 12,
        lg: 6,
        assumptions: true,
        tooltipField:
          'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
        dataTour: 'financing-step-3',
      },
      {
        type: 'dollar',
        name: 'downPaymentBOR',
        label: 'Down Payment',
        value: assumptionsInput.downPaymentBOR || 0,
        xs: 12,
        lg: 6,
        assumptions: true,
        tooltipField:
          'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate.',
        dataTour: 'BORDetails-step-8',
      },
      {
        type: 'number',
        name: 'termBOR',
        label: 'Term',
        value: assumptionsInput.termBOR || 0,
        adornment: 'Years',
        adornmentToggle: '',
        adornmentPosition: 'end',
        xs: 12,
        lg: 6,
        assumptions: true,
        tooltipField:
          'The length of time that the loan is being amortized. Expressed in years.',
        dataTour: 'financing-step-4',
      },
    ],
    (field) => !field.hide,
  )

  const BORChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      assumptionsInput.rehabBORType === 'detailedRehab'
        ? isEmpty(analysis.detailedRehabCostBOR)
          ? ['Total Rehab']
          : map(
              analysis.detailedRehabCostBOR,
              (line) => line.type || 'Unlabeled',
            )
        : ['Rehab Amount'],
      ['Total Income', 'Downpayment'],
    ),
  }
  const BORChartSeries = concat(
    assumptionsInput.rehabBORType === 'detailedRehab'
      ? isEmpty(analysis.detailedRehabCostBOR)
        ? [0.0001]
        : map(analysis.detailedRehabCostBOR, (line, index) =>
            index === 0
              ? round(line.value, 2) || 0.0001
              : round(line.value, 2) || 0,
          )
      : [round(analytics.BOR.corTotalBOR, 2) || 0.0001],
    [analytics.BOR.grossIncomeBOR, assumptions.downPaymentBOR],
  )

  const strategySummary = {
    name: 'BOR',
    percentage:
      (analytics.BOR.onTargetBOR + analytics.BOR.aboveTargetBOR) /
      (analytics.BOR.belowTargetBOR +
        analytics.BOR.onTargetBOR +
        analytics.BOR.aboveTargetBOR),
    analytics: [
      {
        value: analytics.BOR.averageAnnualReturnBOR || '-',
        arrow: analytics.BOR.averageAnnualReturnBORArrow || 0,
        label: 'Average Annual Return',
        type: 'percent',
        target: assumptions.belowAverageAnnualReturnBOR,
        targetLabel: 'Min Net Profit Target',
        targetName: 'belowAverageAnnualReturnBOR',
        tourStep: 'targets-step-1',
        min: 0,
        max: 30,
        minLabel: '0%',
        maxLabel: '30%',
        description: 'The average annual return',
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Total Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.BOR.grossIncomeBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -All in cost
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.BOR.allInCostBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Total Net
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.BOR.netOperatingIncomeBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  All in cost
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  {currencyFormat.format(
                    round(analytics.BOR.allInCostBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  ROI
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {percentageFormat.format(analytics.BOR.BORNetROI)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  Term
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  {round(assumptions.termBOR, 0)}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Average Annual Return
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {percentageFormat.format(
                    analytics.BOR.averageAnnualReturnBOR,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
      {
        value: analytics.BOR.IRRBOR || '-',
        arrow: analytics.BOR.IRRBORArrow || 0,
        label: 'IRR',
        target: assumptions.belowIRRBOR,
        targetLabel: 'Min IRR Target',
        targetName: 'belowIRRBOR',
        tourStep: 'targets-step-2',
        min: 0,
        max: 30,
        minLabel: '0%',
        maxLabel: '30%',
        description: `IRR measures an investment’s annual return throughout the entire property ownership time frame. Unlike a cash-on-cash return or cap rate that measures returns in annual segments, IRR weighs total earnings from the day you purchase your property to the day you sell it.`,
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography
                  style={{ color: '#9198a8', marginBottom: 20 }}
                >
                  Due to the complexity of this calculation, we only
                  show an overview of what goes into calculating the
                  IRR
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Initial Investment
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.BOR.allInCostBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.BOR.grossIncomeBOR),
                  )}
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>Rent Growth Rate</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>{assumptions.growthRateIncome}%</Typography>
              </Grid>
            </Grid> */}
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Down Payment
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(assumptions.downPaymentBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Holding Period (years)
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.termBOR}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  IRR
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {percentageFormat.format(analytics.BOR.IRRBOR)}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
    ],
    details: [
      {
        value: analytics.BOR.sellPriceBOR || '-',
        label: 'Sale Price',
        type: 'currency',
        tourStep: 'netProfitFromSale-step-1',
        description: 'The Sale Price',
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography
                  style={{ color: '#9198a8', marginBottom: 20 }}
                >
                  Due to the complexity of this calculation, we only
                  show an overview of what goes into calculating the
                  Sale Price
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.BOR.grossIncomeBOR),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Interest Rate
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.rateBOR}%
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Down Payment
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(assumptions.downPaymentBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Holding Period (years)
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.termBOR}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Sale Price
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(analytics.BOR.sellPriceBOR)}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
      {
        value: analytics.BOR.allInCostBOR || '-',
        label: 'All In Cost',
        type: 'currency',
        tourStep: 'netProfitFromSale-step-2',
        description:
          'Total amount of initial investment into a property, including renovation.',
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Purchase Price
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(
                      analysis.offerPrice || analysis.listPrice,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  +Closing Costs
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  +
                  {currencyFormat.format(
                    round(analytics.BOR.closeCostDollarBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  +Custom Acquisition Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  +
                  {currencyFormat.format(
                    round(analytics.customAcquisitionExpenseTotal, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  +Rehab Amount
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  +
                  {currencyFormat.format(
                    round(analytics.BOR.corTotalBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  All-in Cost
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.BOR.allInCostBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
      {
        value: analytics.BOR.grossIncomeBOR || '-',
        label: 'Total Income',
        type: 'currency',
        tourStep: 'netProfitFromSale-step-3',
        description: 'The yearly income',
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Annual Rent
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(analysis.rentBOR) * 12, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'xmark']}
                    style={{ marginRight: 3 }}
                  />
                  Term
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'xmark']}
                    style={{ marginRight: 3 }}
                  />
                  {round(assumptions.termBOR, 0)}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Total Rent
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(
                      analytics.BOR.grossIncomeBOR -
                        assumptions.downPaymentBOR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  +Down Payment
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  +
                  {currencyFormat.format(
                    round(assumptions.downPaymentBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Total Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.BOR.grossIncomeBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
      {
        value: analytics.BOR.netOperatingIncomeBOR || '-',
        label: 'Total Net',
        type: 'currency',
        tourStep: 'netProfitFromSale-step-4',
        description: 'The annual net income',
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Total Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.BOR.grossIncomeBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -All in cost
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.BOR.allInCostBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Total Net
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.BOR.netOperatingIncomeBOR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
    ],
  }

  const compData = [
    {
      label: 'Avg. Adjusted Value',
      value: isEmpty(props.compValues)
        ? 0
        : meanBy(
            filter(props.compValues, 'propertyPrice'),
            'propertyPrice',
          ),
      dataTour: 'comparableProperties-step-3',
    },
    {
      range: true,
      label: 'Adjusted Range',
      value1: isEmpty(props.compValues)
        ? 0
        : minBy(props.compValues, 'propertyPrice').propertyPrice /
          1000,
      value2: isEmpty(props.compValues)
        ? 0
        : maxBy(props.compValues, 'propertyPrice').propertyPrice /
          1000,
      dataTour: 'comparableProperties-step-4',
    },
    {
      label: 'Avg Adjusted Price/sqft',
      value: isEmpty(props.compValues)
        ? 0
        : meanBy(
            filter(props.compValues, 'propertyPerSqft'),
            'propertyPerSqft',
          ),
      dataTour: 'comparableProperties-step-5',
    },
    {
      label: 'Lowest 3 comp avg',
      value: props.lowestAverage.afterRepairValue,
      dataTour: 'comparableProperties-step-6',
    },
  ]

  const BORCardsToOrder = [
    {
      name: 'strategy',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Strategy
              </small>
              <b>BOR</b>
            </div>
            <div>
              <Tooltip title="Quick glance determination seen on the main page">
                <Button
                  onClick={() => setOpenBORStrategyTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
            <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-3" />
            <span
              data-tour="strategy-step-1"
              className="d-flex flex-column flex-grow-1 justify-content-center px-4 pt-4 pb-3"
            >
              <div
                className="mx-auto overflow-hidden"
                style={{ paddingBottom: 20 }}
              >
                <GaugeChart
                  id="chartsGauges3B"
                  nrOfLevels={6}
                  colors={['#f83245', '#f4772e', '#1bc943']}
                  arcWidth={0.4}
                  hideText
                  percent={strategySummary.percentage}
                />
              </div>
              <p
                className="mb-3 text-black-50 text-center"
                style={{ paddingLeft: '10px', paddingRight: '10px' }}
              >
                According to your inputs, a {strategySummary.name}{' '}
                strategy meets{' '}
                {strategySummary.percentage < 0.33 && (
                  <b style={{ color: '#f83245' }}>
                    {round(strategySummary.percentage * 100)}%
                  </b>
                )}
                {strategySummary.percentage > 0.33 &&
                  strategySummary.percentage < 0.66 && (
                    <b style={{ color: '#f4772e' }}>
                      {round(strategySummary.percentage * 100)}%
                    </b>
                  )}
                {strategySummary.percentage > 0.66 && (
                  <b style={{ color: '#1bc943' }}>
                    {round(strategySummary.percentage * 100)}%
                  </b>
                )}{' '}
                of your targets
              </p>
            </span>
            {!props.shared &&
              activeOrg.member !== 'gorillacapital' && (
                <div className="px-4 pb-4 pt-2">
                  <ContactAgent
                    property={property}
                    propertyPage={true}
                    canDrag={props.canDrag}
                  />
                </div>
              )}
          </div>
        </Card>
      ),
    },
    {
      name: 'targets',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Does it meet your
              </small>
              <b>Targets</b>
            </div>
            <div>
              <Tooltip title="Does this property meet your specified targets?">
                <Button
                  onClick={() => setOpenBORTargetsTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          {/* <Grid container direction='column' alignItems='center' justifyContent='space-around' onClick={toggleMaxOffer} className="d-flex bg-white hover-scale-rounded justify-content-between py-3">
               <Grid item className="gridItem2">
                 <Typography variant='h3'>{maxOfferPrice > 0 ? currencyFormat.format(maxOfferPrice) : '-'}</Typography>
               </Grid>
               <Grid item className="gridItem2" style={{ textAlign: 'center' }}>
                 <Typography style={{ color: 'grey' }}>Max offer based on targets</Typography>
               </Grid>
             </Grid>
             <Dialog
               open={maxOfferDescription}
               onClose={toggleMaxOffer}
               maxWidth="lg"
               scroll="body"
               classes={{
                 paper: 'modal-content rounded border-0 bg-white p-3 p-xl-0'
               }}
             >
               <Grid container spacing={0} direction='row'>
                 <Grid item sm={5}>
                   <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                     <div className="flex-grow-1 w-100 d-flex align-items-center">
                       <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                       <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                       <div className="text-white mt-3">
                         <Typography style={{ fontSize: 30, fontWeight: 600 }}>Relevant Data</Typography>
                       </div>
                       <>
                       <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>After Repair Value:</Typography>
                         </Grid>
                         <Grid item>
                         <Typography style={{ color: '#9198a8' }}>{currencyFormat.format(analysisInput.afterRepairValue)}</Typography>
                         </Grid>
                       </Grid>
                       <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>-Rehab Cost</Typography>
                         </Grid>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>-{currencyFormat.format(rehabDollar)}</Typography>
                         </Grid>
                       </Grid>
                       <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>-Buyer Profit</Typography>
                         </Grid>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>-{currencyFormat.format(buyerProfitTotal)}</Typography>
                         </Grid>
                       </Grid>
                       <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>-Closing Cost:</Typography>
                         </Grid>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>-{currencyFormat.format(BORClosingCostDollar)}</Typography>
                         </Grid>
                       </Grid>
                       <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>-Assignment Fee:</Typography>
                         </Grid>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>-{currencyFormat.format(assignmentFeeDollar)}</Typography>
                         </Grid>
                       </Grid>
                       <Divider style={{ backgroundColor: '#9198a8' }} />
                        <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
                          <Grid item>
                            <Typography style={{ color: 'white' }}>Max Offer</Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: 'white' }}>{currencyFormat.format(round(maxOfferPrice, 0))}</Typography>
                          </Grid>
                        </Grid>
                     </>
                       </div>
                     </div>
                   </div>
                 </Grid>
                 <Grid item sm={7}>
                   <div className="hero-wrapper h-100 br-xl-right-0" >
                     <Card className="flex-grow-1 w-100">
                       <CardHeader title="Max Offer" />
                       <Divider />
                       <CardContent>
                         <Grid container direction='row' alignItems="center" justifyContent="center">
                           <Grid item xs={12} style={{ marginBottom: 25 }}>
                             <Card style={{ backgroundColor: '#eff2f5', borderRadius: 0 }}>
                               <CardContent>
                                 <Typography>Max Offer is the highest purchase price that still meets all of your strategy's targets. The Max Offer will show a dash if the calculation is less than zero or no purchase price can meet all targets.</Typography>
                                 <Typography>The Max Offer Price is calculated by determining the the highest purchase price that will meet each specific target then taking the lowest value from them. This means that any value that affects a target besides purchase price can affect the Max Offer Price</Typography>
                               </CardContent>
                             </Card>
                           </Grid>
                         </Grid>
                       </CardContent>
                       <Divider />
                       <CardActions>
                         <Grid container justifyContent="center">
                           <Grid item>
                             <Button variant="contained" color="primary" onClick={toggleMaxOffer}>Close</Button>
                           </Grid>
                         </Grid>
                       </CardActions>
                     </Card>
                   </div>
                 </Grid>
               </Grid>
             </Dialog>
             <Divider /> */}
          <List component="div" className="list-group-flush">
            {map(strategySummary.analytics, (section) => (
              <>
                <ListItem
                  component="a"
                  button
                  data-tour={section.tourStep}
                  href={null}
                  disableRipple
                  onClick={() => toggle1(section.label)}
                  className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                >
                  <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                    {!isUndefined(section.arrow) && (
                      <>
                        {section.arrow ? (
                          section.arrow === 2 ? (
                            <Avatar
                              style={{
                                backgroundColor: '#2296F3',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  color: 'white',
                                }}
                              />
                            </Avatar>
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: '#46de64',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  color: 'white',
                                }}
                              />
                            </Avatar>
                          )
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: '#F05248',
                            }}
                          >
                            <ArrowDownwardIcon
                              style={{
                                color: 'white',
                              }}
                            />
                          </Avatar>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex-fill">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        className="font-weight-bold text-black"
                      >
                        {section.label}
                      </Grid>
                      <Grid item className="ml-auto">
                        <b>
                          {section.value !== '-'
                            ? section.type === 'currency'
                              ? currencyFormat.format(section.value)
                              : section.value > 10
                                ? '+1000%'
                                : percentageFormat.format(
                                    section.value,
                                  )
                            : section.value}
                        </b>
                      </Grid>
                    </Grid>
                    <LinearProgress
                      variant="determinate"
                      className={
                        section.arrow === 0
                          ? 'progress-xs progress-animated-alt my-2 progress-bar-danger'
                          : 'progress-xs progress-animated-alt my-2 progress-bar-success'
                      }
                      //value={!section.target ? 0 : section.arrow ? 100 : section.type === 'currency' ? section.value / section.target * 100 : section.value / section.target * 10000}
                      value={
                        section.arrow
                          ? 100
                          : section.type === 'currency'
                            ? (section.value / section.target) * 100
                            : section.label === 'All-In Cost to ARV'
                              ? 200 -
                                (section.value / section.target) *
                                  10000
                              : (section.value / section.target) *
                                10000
                      }
                    />
                    <div className="d-flex justify-content-between font-size-xs">
                      {!isUndefined(section.arrow) && (
                        <div
                          className={
                            section.arrow === 0
                              ? 'text-danger'
                              : 'text-success'
                          }
                        >
                          {section.arrow === 0
                            ? 'Below target'
                            : section.arrow === 1
                              ? 'Target met'
                              : 'Above target'}
                        </div>
                      )}
                      <div className="opacity-5">
                        Target:{' '}
                        {section.type === 'currency'
                          ? currencyFormat.format(section.target)
                          : section.target + '%'}
                      </div>
                    </div>
                  </div>
                </ListItem>
                <Dialog
                  open={modal1 === section.label}
                  onClose={toggle1Close}
                  maxWidth="lg"
                  scroll="body"
                  classes={{
                    paper:
                      'modal-content rounded border-0 bg-white p-3 p-xl-0',
                  }}
                >
                  {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
                  <Grid container spacing={0} direction="row">
                    <Grid item sm={5}>
                      <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                          <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                            <div className="text-white mt-3">
                              <Typography
                                style={{
                                  fontSize: 30,
                                  fontWeight: 600,
                                }}
                              >
                                Calculation
                              </Typography>
                            </div>
                            {section.calculation}
                            <div className="text-white mt-3">
                              <Typography>
                                * Calculation may be a few dollars off
                                due to rounding
                              </Typography>
                              {section.label === 'Buyer Profit' && (
                                <Typography>
                                  * Resell price includes the
                                  Assignment Fee
                                </Typography>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={7}>
                      <div className="hero-wrapper h-100 br-xl-right-0">
                        <Card className="flex-grow-1 w-100">
                          <CardHeader title={section.label} />
                          <Divider />
                          <CardContent>
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Grid
                                item
                                xs={12}
                                style={{ marginBottom: 25 }}
                              >
                                <Card
                                  style={{
                                    backgroundColor: '#eff2f5',
                                    borderRadius: 0,
                                  }}
                                >
                                  <CardContent>
                                    <Typography>
                                      {section.description}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                              {includes(section.label, 'IRR') && (
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginBottom: 10 }}
                                >
                                  <div className="accordion">
                                    <Card
                                      className={clsx('card-box', {
                                        'panel-open': accordion === 0,
                                      })}
                                    >
                                      <Card>
                                        <div className="card-header">
                                          <div className="panel-title">
                                            <div className="accordion-toggle">
                                              <Button
                                                variant="text"
                                                size="large"
                                                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                                onClick={() =>
                                                  toggleAccordion(0)
                                                }
                                                aria-expanded={
                                                  accordion === 0
                                                }
                                              >
                                                <span>
                                                  Start Month
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={[
                                                    'fas',
                                                    'angle-up',
                                                  ]}
                                                  className="font-size-xl accordion-icon"
                                                />
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        <Collapse
                                          in={accordion === 0}
                                        >
                                          <Paper
                                            style={{
                                              width: '100%',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <TableContainer
                                              style={{
                                                maxHeight: 440,
                                              }}
                                            >
                                              <Table
                                                stickyHeader
                                                aria-label="sticky table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    {tableHeadersFirst.map(
                                                      (row) => (
                                                        <TableCell
                                                          align={
                                                            row.align
                                                          }
                                                        >
                                                          {row.name}
                                                        </TableCell>
                                                      ),
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow
                                                    key={
                                                      IRRDataFirst.name
                                                    }
                                                    style={{
                                                      '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                    }}
                                                  >
                                                    <TableCell
                                                      component="th"
                                                      scope="row"
                                                    >
                                                      {
                                                        IRRDataFirst.monthYear
                                                      }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {currencyFormat2.format(
                                                        IRRDataFirst.totalOutOfPocket,
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Collapse>
                                      </Card>
                                    </Card>
                                    <Card
                                      className={clsx('card-box', {
                                        'panel-open': accordion === 1,
                                      })}
                                    >
                                      <Card>
                                        <div className="card-header">
                                          <div className="panel-title">
                                            <div className="accordion-toggle">
                                              <Button
                                                variant="text"
                                                size="large"
                                                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                                onClick={() =>
                                                  toggleAccordion(1)
                                                }
                                                aria-expanded={
                                                  accordion === 1
                                                }
                                              >
                                                <span>
                                                  Appreciation Months
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={[
                                                    'fas',
                                                    'angle-up',
                                                  ]}
                                                  className="font-size-xl accordion-icon"
                                                />
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        <Collapse
                                          in={accordion === 1}
                                        >
                                          <Paper
                                            style={{
                                              width: '100%',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <TableContainer
                                              style={{
                                                maxHeight: 440,
                                              }}
                                            >
                                              <Table
                                                stickyHeader
                                                aria-label="sticky table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    {tableHeaders.map(
                                                      (row) => (
                                                        <TableCell
                                                          align={
                                                            row.align
                                                          }
                                                        >
                                                          {row.name}
                                                        </TableCell>
                                                      ),
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {map(
                                                    IRRData,
                                                    (data) => (
                                                      <TableRow
                                                        key={
                                                          data.name
                                                        }
                                                        style={{
                                                          '&:last-child td, &:last-child th':
                                                            {
                                                              border: 0,
                                                            },
                                                        }}
                                                      >
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                        >
                                                          {
                                                            data.monthYear
                                                          }
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {currencyFormat2.format(
                                                            data.flow,
                                                          )}
                                                        </TableCell>
                                                      </TableRow>
                                                    ),
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Collapse>
                                      </Card>
                                    </Card>
                                    {/* <Card
                                        className={clsx('card-box', {
                                          'panel-open': accordion === 2
                                        })}>
                                          <Card>
                                            <div className="card-header">
                                              <div className="panel-title">
                                                <div className="accordion-toggle">
                                                  <Button
                                                    variant="text"
                                                    size="large"
                                                    className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                                    onClick={() => toggleAccordion(2)}
                                                    aria-expanded={accordion === 2}>
                                                    <span>Final Month</span>
                                                    <FontAwesomeIcon
                                                      icon={['fas', 'angle-up']}
                                                      className="font-size-xl accordion-icon"
                                                    />
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                            <Collapse in={accordion === 2}>
                                              <Paper style={{ width: '100%', overflow: 'hidden' }}>
                                                <TableContainer style={{ maxHeight: 440 }}>
                                                  <Table stickyHeader aria-label="sticky table">
                                                  <TableHead>
                                                      <TableRow>
                                                        {tableHeaders.map((row) => (
                                                          <TableCell align={row.align}>{row.name}</TableCell>
                                                        ))}
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      <TableRow
                                                        key={last(IRRData).name}
                                                        style={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                      >
                                                        <TableCell component="th" scope="row">{last(IRRData).monthYear}</TableCell>
                                                        <TableCell align="center">{currencyFormat2.format(last(IRRData).flow)}</TableCell>
                                                      </TableRow>
                                                    </TableBody>
                                                  </Table>
                                                </TableContainer>
                                              </Paper>
                                            </Collapse>
                                          </Card>
                                        </Card> */}
                                  </div>
                                </Grid>
                              )}
                              {renderInputFields({
                                type: 'slider',
                                name: section.targetName,
                                label: section.targetLabel,
                                value:
                                  assumptionsInput[
                                    section.targetName
                                  ],
                                xs: 12,
                                assumptions: true,
                                valueLabelFormat:
                                  section.valueLabelFormat
                                    ? section.valueLabelFormat
                                    : (x) =>
                                        x === section.max
                                          ? `${x}+`
                                          : `${x}%`,
                                step: section.step,
                                min: section.min,
                                max: section.max,
                              })}
                            </Grid>
                          </CardContent>
                          <Divider />
                          <CardActions>
                            <Grid container justifyContent="center">
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={toggle1Close}
                                >
                                  Close
                                </Button>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                  </Grid>
                  {/* </BlockUi> */}
                </Dialog>
              </>
            ))}
          </List>
        </Card>
      ),
    },
    {
      name: 'BOR',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Summary
              </small>
              <b>BOR Details</b>
            </div>
            <div>
              <Tooltip title="Breakdown of the resale price minus expenses to show net profit">
                <Button
                  onClick={() => setOpenBORBORDetailsTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <List component="div" className="list-group-flush">
            {map(strategySummary.details, (section) => (
              <>
                <ListItem
                  component="a"
                  button
                  data-tour={section.tourStep}
                  href={null}
                  disableRipple
                  onClick={() => toggle1(section.label)}
                  className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                >
                  <div className="flex-fill">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        className="font-weight-bold text-black"
                      >
                        {section.label}
                      </Grid>
                      <Grid item className="ml-auto">
                        <b>
                          {section.value !== '-'
                            ? section.type === 'currency'
                              ? currencyFormat.format(section.value)
                              : section.value > 10
                                ? '+1000%'
                                : percentageFormat.format(
                                    section.value,
                                  )
                            : section.value}
                        </b>
                      </Grid>
                    </Grid>
                  </div>
                </ListItem>
                <Dialog
                  open={modal1 === section.label}
                  onClose={toggle1Close}
                  maxWidth="lg"
                  scroll="body"
                  classes={{
                    paper:
                      'modal-content rounded border-0 bg-white p-3 p-xl-0',
                  }}
                >
                  {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
                  <Grid container spacing={0} direction="row">
                    <Grid item sm={5}>
                      <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                          <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                            <div className="text-white mt-3">
                              <Typography
                                style={{
                                  fontSize: 30,
                                  fontWeight: 600,
                                }}
                              >
                                Calculation
                              </Typography>
                            </div>
                            {section.calculation}
                            <div className="text-white mt-3">
                              <Typography>
                                * Calculation may be a few dollars off
                                due to rounding
                              </Typography>
                              {section.label === 'Buyer Profit' && (
                                <Typography>
                                  * Resell price includes the
                                  Assignment Fee
                                </Typography>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={7}>
                      <div className="hero-wrapper h-100 br-xl-right-0">
                        <Card className="flex-grow-1 w-100">
                          <CardHeader title={section.label} />
                          <Divider />
                          <CardContent>
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Grid
                                item
                                xs={12}
                                style={{ marginBottom: 25 }}
                              >
                                <Card
                                  style={{
                                    backgroundColor: '#eff2f5',
                                    borderRadius: 0,
                                  }}
                                >
                                  <CardContent>
                                    <Typography>
                                      {section.description}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <Divider />
                          <CardActions>
                            <Grid container justifyContent="center">
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={toggle1Close}
                                >
                                  Close
                                </Button>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                  </Grid>
                  {/* </BlockUi> */}
                </Dialog>
              </>
            ))}
          </List>
        </Card>
      ),
    },
    {
      name: 'BORInputs',
      card: (
        <Card className="card-box w-100">
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Grid
              item
              xs={8}
              style={{
                overflow: 'scroll',
              }} /** TODO: maybe look at if we need the scroll or how to fix bug where scroll bars appear no mattter what*/
            >
              <List
                className="nav-line d-flex mt-3"
                style={{
                  width: (analysisInput.rentBOR.length + 2) * 100,
                }}
              >
                {analysisInput.rentBOR.length > 1 ? (
                  map(analysisInput.rentBOR, (value, index) => (
                    <ListItem
                      button
                      disableRipple
                      selected={props.activeUnit === index}
                      onClick={() => {
                        props.setActiveUnit(index)
                      }}
                    >
                      Unit {index + 1}
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <div className="card-header--title">
                      <b>BOR Inputs</b>
                    </div>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item style={{ marginRight: 20 }}>
              <Tooltip title="Estimated expenses allocated to the renovations of a property">
                <Button
                  onClick={() => setOpenBORBORInputsTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={BORChartOptions}
                      series={BORChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(BORFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'comparableProperties',
      card: props.basicView ? null : (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Comparable Properties</b>
            </div>
            <div>
              <Tooltip title="All costs and expenses associated with sale of this property">
                <Button
                  onClick={() =>
                    setOpenBORComparablePropertiesTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid
                container
                spacing={6}
                className="text-black-50 font-size-sm"
              >
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div data-tour="comparableProperties-step-1">
                    <div className="text-dark pb-1">
                      Comparable Properties
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={props.determinedComps.length}
                            duration={1}
                            separator=","
                            decimals={0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div data-tour="comparableProperties-step-2">
                    <div className="text-dark pb-1">
                      Potential Comps
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              props.compProperties.length -
                              props.determinedComps.length
                            }
                            duration={1}
                            separator=","
                            decimals={0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <List component="div" className="list-group-flush">
              {map(compData, (section) => (
                <ListItem
                  component="a"
                  button
                  disableRipple
                  data-tour={section.dataTour}
                  className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      className="font-weight-bold text-black"
                      style={{ olor: 'text' }}
                    >
                      {section.label}
                    </Grid>
                    {section.range ? (
                      <Grid item>
                        <Grid container direction="row" spacing={1}>
                          <Grid item>
                            <CountUp
                              start={0}
                              end={section.value1}
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </Grid>
                          <Grid item>
                            <Typography>-</Typography>
                          </Grid>
                          <Grid item>
                            <CountUp
                              start={0}
                              end={section.value2}
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item>
                        <CountUp
                          start={0}
                          end={section.value}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix="$"
                        />
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              ))}
            </List>
            <Grid container>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 25 }}
                  onClick={(e) => props.toggleCenter('Comps')}
                  data-tour="comparableProperties-step-7"
                >
                  <Typography>View Comps</Typography>
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'deactive',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Hide Cards</b>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Typography>
              All cards past this one will be hidden
            </Typography>
          </CardContent>
        </Card>
      ),
    },
  ]

  const getOrderDefault = () => {
    const storedValues = !isEmpty(property.BOROrder)
      ? property.BOROrder
      : merge(
          cloneDeep(DEFAULT_PREFERENCES),
          activeOrg.memberPreferences,
          activeOrg.preferences,
          props.session.me ? props.session.me.preferences : {},
        ).BOROrder || []
    const newCards = difference(
      map(BORCardsToOrder, (card) => card.name),
      storedValues,
    )
    const deactiveIndex = findIndex(
      storedValues,
      (value) => value === 'deactive',
    )
    return uniq(
      concat(
        slice(storedValues, 0, deactiveIndex),
        newCards,
        slice(storedValues, deactiveIndex),
      ),
    )
  }

  const [BOROrder, setBOROrder] = useState(getOrderDefault())
  const [orderChanged, setOrderChanged] = useState(false)
  const [orderDefault, setOrderDefault] = useState(false)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setBOROrder(getOrderDefault())
  }, [activeOrg.member])

  const SortableCard = SortableElement(({ item }) => (
    <Grid item sm={6} xl={4} className="d-flex">
      {item}
    </Grid>
  ))
  const SortableGrid = SortableContainer(({ items }) => (
    <Grid container direction="row" spacing={2} className="p-3">
      {map(items, (item, index) => (
        <SortableCard
          key={`item-${index}`}
          index={index}
          item={item}
        />
      ))}
    </Grid>
  ))

  const [updateProperty] = useMutation(UPDATE_PROPERTY)
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newOrder = arrayMoveImmutable(
        BOROrder,
        oldIndex,
        newIndex,
      )
      setBOROrder(newOrder)
      setOrderChanged(true)
    }
  }

  const resetOrder = () => {
    setBOROrder(getOrderDefault())
    setOrderChanged(false)
  }

  const submitOrder = () => {
    setLoader(true)
    const preferences = merge(
      cloneDeep(DEFAULT_PREFERENCES),
      activeOrg.memberPreferences,
      activeOrg.preferences,
    )
    updateProperty({
      refetchQueries: [
        {
          query: GET_PROPERTY,
          variables: { id: property._id },
        },
      ],
      variables: {
        id: property._id,
        propertyInput: {
          BOROrder,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(`Layout saved`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        if (orderDefault) {
          updateOrganization({
            refetchQueries: [
              {
                query: GET_ME,
              },
            ],
            variables: {
              id: activeOrg._id,
              organizationInput: {
                preferences: {
                  ...preferences,
                  BOROrder,
                },
              },
            },
          })
            .then(() => {
              enqueueSnackbar(`Default BOR layout saved`, {
                variant: 'success',
                autoHideDuration: 3000,
              })
              setOrderChanged(false)
              setLoader(false)
            })
            .catch((error) => {
              enqueueSnackbar(`Failed to save default BOR layout`, {
                variant: 'error',
                autoHideDuration: 3000,
              })
            })
        } else {
          setOrderChanged(false)
          setLoader(false)
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to save layout`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const sortedBORCards = sortBy(BORCardsToOrder, [
    function (o) {
      return findIndex(BOROrder, (value) => value === o.name)
    },
  ])

  return (
    <>
      {!props.canDrag && (
        <Grid container direction="row" spacing={2} className="p-3">
          {props.publicImageCard && (
            <Grid
              item
              xs={12}
              md={6}
              lg={props.publicDashboard ? 4 : 6}
              xl={4}
              className="d-flex w-100"
            >
              {props.publicImageCard}
            </Grid>
          )}
          {map(
            slice(
              sortedBORCards,
              0,
              findIndex(
                sortedBORCards,
                (card) => card.name === 'deactive',
              ),
            ),
            (card) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={props.publicDashboard ? 4 : 6}
                xl={4}
                className="d-flex w-100"
              >
                {card.card}
              </Grid>
            ),
          )}
        </Grid>
      )}
      {props.canDrag && (
        <SortableGrid
          items={compact(
            concat(
              [props.publicImageCard],
              map(sortedBORCards, (card) => card.card),
            ),
          )}
          onSortEnd={onSortEnd}
          axis="xy"
        />
      )}
      <Drawer
        open={orderChanged}
        anchor="bottom"
        variant="persistent"
        PaperProps={{
          style: { backgroundColor: '#253152', padding: 15 },
        }}
      >
        {/* <Alert
         className="mb-4"
         style={{ backgroundColor: '#253152', color: 'white' }}
         icon={false}
         action={
           <IconButton onClick={props.resetPropertyChanges}>
             <Close style={{ color: 'white' }} />
           </IconButton>
         }
       > */}
        {/* <Typography style={{ color: 'white', fontWeight: 600 }}>You have unsaved changes</Typography> */}
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item style={{ marginLeft: 20 }}>
            <IconButton onClick={resetOrder} size="large">
              <Close style={{ color: 'white' }} />
            </IconButton>
          </Grid>
          <Grid item style={{ marginLeft: 0 }}>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: '#4290ff',
                color: 'white',
                boxShadow: 'none',
              }}
              onClick={submitOrder}
              disabled={loader}
            >
              <Typography style={{ fontWeight: 600 }}>
                Save
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <FormControl
              component="fieldset"
              className="pr-4"
              style={{ color: 'white' }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={orderDefault}
                      onChange={() => setOrderDefault(!orderDefault)}
                      style={{ color: 'white' }}
                    />
                  }
                  label="Set this as default BOR layout"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Typography style={{ flex: 1 }} />
        </Grid>
      </Drawer>

      {/* TODO COMMENT: Need to alter the tours for the new strategy */}
      <BORStrategyTour
        isOpenBORStrategyTour={openBORStrategyTour}
        closeBORStrategyTour={() => setOpenBORStrategyTour(false)}
      />
      <BORTargetsTour
        isOpenBORTargetsTour={openBORTargetsTour}
        closeBORTargetsTour={() => setOpenBORTargetsTour(false)}
      />
      <BORBORDetailsTour
        isOpenBORBORDetailsTour={openBORBORDetailsTour}
        closeBORBORDetailsTour={() => setOpenBORBORDetailsTour(false)}
      />
      <BORBORInputsTour
        isOpenBORBORInputsTour={openBORBORInputsTour}
        closeBORBORInputsTour={() => setOpenBORBORInputsTour(false)}
      />
      <BORComparablePropertiesTour
        isOpenBORComparablePropertiesTour={
          openBORComparablePropertiesTour
        }
        closeBORComparablePropertiesTour={() =>
          setOpenBORComparablePropertiesTour(false)
        }
      />
      {/*
       */}
    </>
  )
}

export default withSession(CenterPageBOR)
