import React, { useEffect, useRef, useState } from 'react'

import { PageTitle } from '@/layout-components'
import { ExampleWrapperSeamless } from '@/layout-components'

import remove from 'lodash/remove'
import find from 'lodash/find'
import filter from 'lodash/filter'
import slice from 'lodash/slice'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toLower from 'lodash/toLower'
import compact from 'lodash/compact'
import includes from 'lodash/includes'

import AccountSetup from '@/housefolios-components/LaunchpadComponents/accountSetup'
import BonusProperties from '@/housefolios-components/LaunchpadComponents/bonusProperties'
import MonthlyLimit from '@/housefolios-components/LaunchpadComponents/monthlyLimit'
import LenderFinancing from '@/housefolios-components/LaunchpadComponents/lenderFinancing'
import TopLeftRWN from '@/housefolios-components/LaunchpadComponents/topLeftRWN'
import TopLeftNavigation from '@/housefolios-components/LaunchpadComponents/topLeftNavigation'
import TopLeftPaul from '@/housefolios-components/LaunchpadComponents/topLeftPaul'
import TopRightInvestarters from '@/housefolios-components/LaunchpadComponents/topRightInvestarters'
import TopRightNextLevel from '@/housefolios-components/LaunchpadComponents/topRightNextLevel'
import MoreVert from '@/housefolios-components/TitleButtons/MoreVert'
// import ElementsNavigationMenus2 from '../../example-components/ElementsNavigationMenus/NavigationMenus2';

import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import AddPropertyButton from '@/housefolios-components/TitleButtons/AddPropertyButton'
import withSession from '@/housefolios-components/Session/withSession'
import TrainingVideoCardComponent from '@/housefolios-components/LaunchpadComponents/trainingVideos'
import TrainingFileCardComponent from '@/housefolios-components/LaunchpadComponents/trainingFiles'
import { useMutation, useQuery } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { GET_AFFILIATE } from '@/housefolios-components/CreateAccount/CreateAccountCard/queries'
import { GET_MARKETPLACES } from '@/housefolios-components/Marketplace/queries'
import PublicPropertyCard from '@/housefolios-components/PublicProperty/PublicPropertyCard'

import '../../housefolios-components/PublicProperty/styles.css'
import Script from 'react-load-script'
import { GENERIC_SEND_EMAIL } from '@/housefolios-components/Session/mutations'
import { enqueueSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import useAffiliate from '@/hooks/useAffiliate'
import useProperties, { KindOfPage } from '@/hooks/useProperties'

const hostName = import.meta.env.VITE_HOSTNAME

function PublicHomePage(props) {
  const { marketplace, orgName } = props
  let marketplaceVideoURL = marketplace
    ? includes(marketplace.marketplaceVideoURL, 'http://') ||
      includes(marketplace.marketplaceVideoURL, 'https://')
      ? marketplace.marketplaceVideoURL || ''
      : ''
    : ''
  const location = useLocation()
  const contactUs = useRef(null)

  useEffect(() => {
    // Scroll to the target element when the component mounts
    if (location.hash && contactUs.current) {
      contactUs.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [location])

  const urlParams = new URLSearchParams(window.location.search)
  const subdomain = urlParams.get('subdomain') || null
  const subdomainParam = subdomain ? '?subdomain=' + subdomain : ''

  const isURLAbsolute = (url) => {
    return url.indexOf('://') > 0 || url.indexOf('//') === 0
  }

  function resizeHeaderOnScroll() {
    const distanceY =
        window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 200,
      headerEl = document.getElementById('js-header')

    if (distanceY > shrinkOn) {
      headerEl.classList.add('smaller')
    } else {
      headerEl.classList.remove('smaller')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', resizeHeaderOnScroll)
    return () =>
      window.removeEventListener('scroll', resizeHeaderOnScroll)
  }, [])

  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [message, setMessage] = useState()

  const [genericSendEmail] = useMutation(GENERIC_SEND_EMAIL)

  const sendEmail = () => {
    if (hostName === 'app.housefolios.com') {
      genericSendEmail({
        variables: {
          msg: {
            to: [{ addr: marketplace.contactEmail }],
            subject: `You have been contacted`,
            text: `${name} has sent a message from your marketplace ${marketplace.url}. They can be contacted at ${email} or ${phone}. Their message is: \n${message}`,
            html: `${name} has sent a message from your marketplace ${marketplace.url}. They can be contacted at ${email} or ${phone}. Their message is: \n${message}`,
          },
        },
      }).then(() => {
        enqueueSnackbar(`Your Email was Sent`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
      })
    } else {
      genericSendEmail({
        variables: {
          msg: {
            to: [{ addr: 'daniel@housefolios.com' }],
            subject: `Dev/Test You have been contacted `,
            text: `${name} has sent a message from your marketplace ${marketplace.url}. They can be contacted at ${email} or ${phone}. Their message is: \n${message}`,
            html: `${name} has sent a message from your marketplace ${marketplace.url}. They can be contacted at ${email} or ${phone}. Their message is: \n${message}`,
          },
        },
      }).then(() => {
        enqueueSnackbar(`Your Email was Sent`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
      })
    }
  }

  const [{ affiliate, urlAffiliate }] = useAffiliate({})

  const [
    {
      properties,
      count,
      currentPageProperties,
      marketPlaceData,
      loadingMarketPlaceData,
      marketPlaceError,
      ordering,
      page,
      selected,
      search,
      marketplaceSelected,
    },
    {
      selectAll,
      selectProperty,
      getSearchResults,
      getFilteredProperties,
      fetchMoreProperties,
      setOrdering,
      selectMarketplaceProperty,
      setMarketplaceSelected,
      handlePageChange,
      handleRequestSort,
      setSelected,
      setPage,
    },
  ] = useProperties({
    // ...props,
    // selectedPortfolio,
    kind: KindOfPage.PUBLIC_MARKETPLACE,
    marketplace: marketplace.url,
  })

  let unfilteredProperties = !marketplace
    ? filter(
        marketPlaceData?.marketplaceProperties?.properties,
        (property) =>
          new Date(property?.marketplaces?.expirationDate).getTime() >
          Date.now(),
      )
    : marketPlaceData?.marketplaceProperties?.properties
  unfilteredProperties = props.sold
    ? filter(
        unfilteredProperties,
        (property) => toLower(property.status) === 'sold',
      )
    : filter(
        unfilteredProperties,
        (property) => toLower(property.status) !== 'sold',
      )

  if (loadingMarketPlaceData)
    return (
      <Backdrop
        open={loadingMarketPlaceData}
        style={{
          color: '#fff',
          zIndex: 2000,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  if (marketPlaceError) return `Error! ${marketPlaceError?.message}`

  let displayProperties =
    marketplace &&
    compact(
      map(marketplace.featuredProperties, (id) =>
        find(
          marketPlaceData?.marketplaceProperties?.properties,
          (property) => property._id === id,
        ),
      ),
    )
  if (isEmpty(displayProperties))
    displayProperties = slice(unfilteredProperties, 0, 6)

  return (
    <>
      <ExampleWrapperSeamless>
        {/* <header className="masthead">
          <container style={{ textAlign: 'center' }}>
            <div className="intro-text">
              <div className="intro-lead-in">{orgName ? orgName : 'Housefolios'} Marketplace</div>
              <div className="intro-heading text-uppercase">Buy Properties Today!</div>
            </div>
          </container>
        </header> */}

        <section
          className="bg-light page-section2"
          id="forsale"
          style={{ paddingTop: 350, minHeight: 500 }}
        >
          <Grid container>
            {/* <Grid item xs={12} style={{ textAlign: 'center' }}>
                      <h2 className="section-heading text-uppercase">Properties for Sale</h2>
                      <h3 className="section-subheading text-muted">We bring the deals to you!</h3>
                    </Grid> */}
            <Grid
              container
              item
              xs={12}
              spacing={4}
              style={{ padding: 20 }}
            >
              {map(displayProperties, (property) => (
                <PublicPropertyCard
                  property={property}
                  marketplace={marketplace || 'null'}
                  shared
                  subdomainParam={subdomainParam}
                />
              ))}
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <br />
              {unfilteredProperties?.length < 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  href={`/properties${subdomainParam}`}
                  style={{
                    color: 'white',
                    fontWeight: 700,
                    padding: '0.5rem 1rem',
                    fontSize: '1.25rem',
                    lineHeight: '1.5',
                  }}
                >
                  Click to View Properties
                </Button>
              )}
              {unfilteredProperties?.length >= 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  href={`/properties${subdomainParam}`}
                  style={{
                    color: 'white',
                    fontWeight: 700,
                    padding: '0.5rem 1rem',
                    fontSize: '1.25rem',
                    lineHeight: '1.5',
                  }}
                >
                  View All Properties For Sale
                </Button>
              )}
              {marketplace && marketplace.preApprovedURL && (
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  href={marketplace.preApprovedURL}
                  target="_blank"
                  style={{
                    color: 'white',
                    fontWeight: 700,
                    padding: '0.5rem 1rem',
                    fontSize: '1.25rem',
                    lineHeight: '1.5',
                    marginLeft: 5,
                  }}
                >
                  Get Pre Approved
                </Button>
              )}
            </Grid>
          </Grid>
        </section>

        <section className="page-section2" id="sellproperty">
          <Grid container>
            {marketplaceVideoURL && (
              <Grid item xs={12} className="d-flex">
                <div className="d-flex justify-content-center w-100 mb-5">
                  <iframe
                    src={marketplaceVideoURL}
                    /*width="640"*/ width="100%"
                    height="564"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
              </Grid>
            )}
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
            >
              <Grid item lg={12} style={{ textAlign: 'center' }}>
                <h2 className="section-heading text-uppercase">
                  Buy Properties on{' '}
                  {orgName ? orgName : 'Housefolios'} Marketplace
                </h2>
                <h3 className="section-subheading text-muted"></h3>
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <Grid item sm={4}>
                <div className="team-member">
                  <img
                    className="mx-auto rounded-circle"
                    src="/images/icons/undraw_setup_wizard_r6mr.png"
                    alt=""
                  />
                  <h4>Step 1</h4>
                  <p className="text-muted">
                    Identify the properties you are interested in.
                  </p>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="team-member">
                  <img
                    className="mx-auto rounded-circle"
                    src="/images/icons/undraw_calling_kpbp.png"
                    alt=""
                  />
                  <h4>Step 2</h4>
                  <p className="text-muted">
                    Schedule a time to discuss at your convenience.
                  </p>
                </div>
              </Grid>
              <Grid item sm={4}>
                <div className="team-member">
                  <img
                    className="mx-auto rounded-circle"
                    src="/images/icons/undraw_for_sale_viax.png"
                    alt=""
                  />
                  <h4>Step 3</h4>
                  <p className="text-muted">
                    Buy the properties that are right for you!
                  </p>
                </div>
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <div className="col-lg-8 mx-auto text-center">
                <p className="large text-muted">
                  Buying property has never been easier. We run the
                  financial estimates to make sure you find deals
                  inside of your buy buckets.
                </p>
                {marketplace && marketplace.preApprovedURL && (
                  <Button
                    variant="contained"
                    color="primary"
                    component="a"
                    href={marketplace.preApprovedURL}
                    target="_blank"
                    style={{
                      color: 'white',
                      fontWeight: 700,
                      padding: '0.5rem 1rem',
                      fontSize: '1.25rem',
                      lineHeight: '1.5',
                      marginLeft: 5,
                    }}
                  >
                    Get Pre Approved
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </section>

        <section className="page-section2" id="contact">
          {marketplace &&
          marketplace?.url === 'nlinvesting.housefolios.com' ? (
            <Grid container>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
              >
                <Grid item lg={12} className="text-center">
                  <h2
                    className="section-heading text-uppercase"
                    style={{ fontWeight: 700 }}
                  >
                    ALREADY HAVE A PROPERTY?
                  </h2>
                  <h3
                    className="section-subheading text-muted"
                    style={{ fontWeight: 700 }}
                  >
                    Click the button below to have us check it out
                  </h3>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
              >
                <Grid item lg={12} style={{ textAlign: 'center' }}>
                  <div id="success"></div>
                  <Button
                    variant="contained"
                    color="primary"
                    component="a"
                    href="https://housefolios.com/nextlevel"
                    style={{
                      color: 'white',
                      fontWeight: 700,
                      padding: '0.5rem 1rem',
                      fontSize: '1.25rem',
                      lineHeight: '1.5',
                      marginLeft: 5,
                    }}
                  >
                    CHECK IT OUT
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
              >
                <Grid item lg={12} className="text-center">
                  <h2
                    className="section-heading text-uppercase"
                    style={{ fontWeight: 700 }}
                  >
                    Contact Us Today
                  </h2>
                  <h3
                    className="section-subheading text-muted"
                    style={{ fontWeight: 700 }}
                  >
                    Any questions? Contact someone at{' '}
                    {marketplace && marketplace.meetingURL
                      ? orgName
                      : urlAffiliate
                        ? affiliate?.companyName || 'Housefolios'
                        : 'Housefolios'}{' '}
                    today!
                  </h3>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
              >
                <div className="col-lg-12" ref={contactUs}>
                  {marketplace &&
                  marketplace.meetingURL &&
                  isURLAbsolute(marketplace.meetingURL) ? (
                    <div
                      id="calendly"
                      style={{ height: '650px' }}
                    ></div>
                  ) : (
                    <>
                      <iframe
                        name="dummyframe"
                        id="dummyframe"
                        style={{ display: 'none' }}
                      ></iframe>
                      <form
                        action="sendEmail.php"
                        target="dummyframe"
                        name="contactForm"
                        novalidate="novalidate"
                        onSubmit={sendEmail}
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="center"
                        >
                          <Grid
                            item
                            md={6}
                            style={{ textAlign: 'center' }}
                          >
                            <div className="form-group">
                              <input
                                className="form-control"
                                value={name}
                                onChange={(event) =>
                                  setName(event.target.value)
                                }
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Your Name *"
                                required="required"
                                style={{ fontWeight: 700 }}
                                data-validation-required-message="Please enter your name."
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                            <div className="form-group">
                              <input
                                className="form-control"
                                value={email}
                                onChange={(event) =>
                                  setEmail(event.target.value)
                                }
                                id="email"
                                name="email"
                                type="email"
                                placeholder="Your Email *"
                                required="required"
                                style={{ fontWeight: 700 }}
                                data-validation-required-message="Please enter your email address."
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                            <div className="form-group">
                              <input
                                className="form-control"
                                value={phone}
                                onChange={(event) =>
                                  setPhone(event.target.value)
                                }
                                id="phone"
                                name="phone"
                                type="tel"
                                placeholder="Your Phone *"
                                required="required"
                                style={{ fontWeight: 700 }}
                                data-validation-required-message="Please enter your phone number."
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            style={{ textAlign: 'center' }}
                          >
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                value={message}
                                onChange={(event) =>
                                  setMessage(event.target.value)
                                }
                                id="message"
                                name="message"
                                placeholder="Your Message *"
                                required="required"
                                style={{ fontWeight: 700 }}
                                data-validation-required-message="Please enter a message."
                              ></textarea>
                              <p className="help-block text-danger"></p>
                            </div>
                          </Grid>
                          <div className="clearfix"></div>
                          <Grid
                            item
                            lg={12}
                            style={{ textAlign: 'center' }}
                          >
                            <div id="success"></div>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              id="sendMessageButton"
                              name="action"
                              className="text-uppercase"
                              value="Send Message"
                              style={{
                                color: 'white',
                                fontWeight: 700,
                                padding: '0.5rem 1rem',
                              }}
                            >
                              Send Message
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </section>
      </ExampleWrapperSeamless>
      {marketplace &&
        marketplace.meetingURL &&
        isURLAbsolute(marketplace.meetingURL) && (
          <Script
            url={`https://assets.calendly.com/assets/external/widget.js`}
            onLoad={() => {
              window.Calendly.initInlineWidget({
                url:
                  marketplace && marketplace.meetingURL
                    ? marketplace.meetingURL
                    : 'https://calendly.com/jaredgarfield',
                parentElement: document.getElementById('calendly'),
                prefill: {},
                utm: {},
              })
            }}
          />
        )}
    </>
  )
}

export default PublicHomePage
