import React, { useState } from 'react'

import map from 'lodash/map'
import find from 'lodash/find'
import ceil from 'lodash/ceil'
import slice from 'lodash/slice'
import moment from 'moment'
import get from 'lodash/get'
import isString from 'lodash/isString'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import isArray from 'lodash/isArray'

function desc(a, b, orderBy) {
  if (isString(get(a, orderBy)) && isString(get(b, orderBy))) {
    if (!get(b, orderBy) && !get(a, orderBy)) {
      return 0
    }
    if (!get(b, orderBy)) {
      return 1
    }
    if (!get(a, orderBy)) {
      return -1
    }
    if (
      every(get(a, orderBy), (c) => c <= '9' && c >= '0') &&
      every(get(b, orderBy), (c) => c <= '9' && c >= '0')
    ) {
      if (Number(get(b, orderBy)) < Number(get(a, orderBy))) {
        return -1
      }
      if (Number(get(b, orderBy)) > Number(get(a, orderBy))) {
        return 1
      }
      return 0
    } else {
      if (
        get(b, orderBy).toLowerCase() < get(a, orderBy).toLowerCase()
      ) {
        return -1
      }
      if (
        get(b, orderBy).toLowerCase() > get(a, orderBy).toLowerCase()
      ) {
        return 1
      }
      return 0
    }
  } else {
    if (isNil(get(b, orderBy)) && isNil(get(a, orderBy))) {
      return 0
    }
    if (isNil(get(b, orderBy))) {
      return 1
    }
    if (isNil(get(a, orderBy))) {
      return -1
    }
    if (get(b, orderBy) < get(a, orderBy)) {
      return -1
    }
    if (get(b, orderBy) > get(a, orderBy)) {
      return 1
    }
    return 0
  }
}

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

function useSorting({ defaultOrder = 'asc', defaultOrderBy = '' }) {
  const [order, setOrder] = useState(defaultOrder)
  const [orderBy, setOrderBy] = useState(defaultOrderBy)

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'asc'
    setOrder(isDesc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return [
    {
      order,
      orderBy,
    },
    {
      handleRequestSort,
    },
  ]
}

export default useSorting
