import Reactour from 'reactour'
import { Button, Typography, Grid } from '@mui/material'
import { includes } from 'lodash'
import { useLocation } from 'react-router-dom'

export const AnalyzeAreaTour =
  ({
    isOpenAnalyzeAreaTour,
    closeAnalyzeAreaTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = !includes(pathname, '/MarketAnalysis')
    const steps = [
      {
        selector: '[data-tour="analyzeArea-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Click "Market Analysis" to continue
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                goToTo(1)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="analyzeArea-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ textAlign: 'center' }}
                >
                  Property Rankings
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  You can rank your property by clicking on these
                  dropdowns.
                </Typography>
              </Grid>
            </Grid>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              goToTo(2)
            }
        },
      },
      {
        selector: '[data-tour="analyzeArea-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ textAlign: 'center' }}
                >
                  Don't know how to rank properties?
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  You can click on this section to learn how.
                </Typography>
              </Grid>
            </Grid>
          )
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenAnalyzeAreaTour}
        onRequestClose={closeAnalyzeAreaTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }
