/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: David Helmick
 * @ Modified time: 2023-12-13 10:06:10
 * @ Description: Either a drawer on the bottom of the screen or just a button for saving assumptions. Includes a dialog for saving changes to child assumptions.
 */

import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'

import map from 'lodash/map'
import concat from 'lodash/concat'
import forEach from 'lodash/forEach'
import unset from 'lodash/unset'
import isEqual from 'lodash/isEqual'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { useMutation } from '@apollo/client'
import {
  SAVE_ASSUMPTIONS,
  SAVE_ASSUMPTIONS_MULTI,
  SAVE_CHILDREN_ASSUMPTIONS,
} from '../mutations'
import { enqueueSnackbar } from 'notistack'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { GET_ASSUMPTIONS } from '../quries'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}))

function AssumptionsSettingsNotification(props) {
  const classes = useStyles()
  const { setLoader } = props

  const [saveAssumptions] = useMutation(SAVE_ASSUMPTIONS)
  const [saveChildrenAssumptions] = useMutation(
    SAVE_CHILDREN_ASSUMPTIONS,
  )
  const [saveAssumptionsMulti] = useMutation(SAVE_ASSUMPTIONS_MULTI)

  const [openSaveChildDialog, setOpenSaveChildDialog] =
    useState(false)
  const [childChange, setChildChange] = useState(
    props.session.me.limits.portfolioLimit <= 1 ? 'child' : 'self',
  )

  const [openSaveSome, setOpenSaveSome] = useState(false)
  const [saveSome, setSaveSome] = useState('change')

  const handleOpenSaveDialog = () => {
    if (!props.selected) {
      if (
        props.documentType === 'organization' ||
        props.documentType === 'portfolio'
      )
        setOpenSaveChildDialog(true)
      else {
        if (setLoader) setLoader(true)
        saveAssumptions({
          variables: {
            documentId: props.documentId,
            documentType: props.documentType,
            assumptionsInput: props.assumptions,
          },
        }).then(async ({ data }) => {
          enqueueSnackbar(`Success! Changes saved`, {
            variant: 'success',
            autoHideDuration: 3000,
          })
          handleCloseSaveDialog()
          if (props.setIsChanged) props.setIsChanged(false)
        })
      }
    } else {
      setOpenSaveSome(true)
    }
  }

  const handleCloseSaveDialog = () => {
    setOpenSaveChildDialog(false)
    setOpenSaveSome(false)
    //if (setLoader) setLoader(false)
  }

  const onSubmit = () => {
    if (setLoader) setLoader(true)
    handleCloseSaveDialog()
    saveAssumptions({
      variables: {
        documentId: props.documentId,
        documentType: props.documentType,
        assumptionsInput: props.assumptions,
      },
      refetchQueries: [
        {
          query: GET_ASSUMPTIONS,
          variables: {
            documentId: props.documentId,
            documentType: props.documentType,
          },
        },
      ],
    }).then(async ({ data }) => {
      if (childChange !== 'self') {
        saveChildrenAssumptions({
          variables: {
            documentId: props.documentId,
            documentType: props.documentType,
            assumptionsInput: props.assumptions,
            optionThree: childChange === 'grandchild',
          },
        })
      }
      enqueueSnackbar(`Success! Changes saved`, {
        variant: 'success',
        autoHideDuration: 3000,
      })
      if (setLoader) setLoader(false)
      if (props.setIsChanged) props.setIsChanged(false)
    })
  }

  const bulkSubmit = () => {
    if (setLoader) setLoader(true)
    handleCloseSaveDialog()
    let { assumptions, baseAssumptions } = props
    if (saveSome === 'change') {
      forEach(baseAssumptions, (value, key) => {
        if (isEqual(assumptions[key], value)) unset(assumptions, key)
      })
    }
    saveAssumptionsMulti({
      variables: {
        documentIds: props.selected,
        documentType: 'property',
        assumptionsInput: assumptions,
      },
      refetchQueries: () => {
        const assumptionRefetch = map(props.selected, (id) => ({
          query: GET_ASSUMPTIONS,
          variables: { documentId: id, documentType: 'property' },
        }))
        const propertyRefetch = map(props.selected, (id) => ({
          query: GET_PROPERTY,
          variables: { id: id },
        }))
        return concat(assumptionRefetch, propertyRefetch, {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [props.documentId],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        })
      },
    }).then(async ({ data }) => {
      enqueueSnackbar(`Success! Changes saved`, {
        variant: 'success',
        autoHideDuration: 3000,
      })
      if (setLoader) setLoader(false)
      if (props.setIsChanged) props.setIsChanged(false)
    })
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  return (
    <>
      {!props.button && (
        <Drawer
          open={props.isChanged}
          anchor="bottom"
          variant="persistent"
          PaperProps={{
            style: { backgroundColor: '#253152', padding: 15 },
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            wrap="nowrap"
          >
            <Grid item style={{ marginLeft: 0 }}>
              <IconButton
                onClick={props.resetBaseAssumptions}
                size="large"
              >
                <Close style={{ color: 'white' }} />
              </IconButton>
            </Grid>
            <Grid item style={{ marginLeft: 0 }}>
              <Button
                variant="contained"
                size="small"
                style={{
                  backgroundColor: '#4290ff',
                  color: 'white',
                  boxShadow: 'none',
                }}
                onClick={handleOpenSaveDialog}
              >
                <Typography style={{ fontWeight: 600 }}>
                  Save
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white', fontWeight: 600 }}>
                You have unsaved changes
              </Typography>
            </Grid>
          </Grid>
        </Drawer>
      )}

      {props.button && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenSaveDialog}
        >
          Save
        </Button>
      )}

      <Dialog
        open={openSaveChildDialog}
        onClose={handleCloseSaveDialog}
        onKeyDown={stopPropagationForTab}
      >
        <DialogTitle>
          {props.documentType === 'organization'
            ? 'Organization'
            : 'Portfolio'}{' '}
          Assumptions
        </DialogTitle>
        <DialogContent>
          <FormControl
            component="fieldset"
            className={classes.formControl}
          >
            <RadioGroup
              aria-label="assumption application"
              name="childChange"
              className={classes.group}
              value={childChange}
              onChange={(event) => setChildChange(event.target.value)}
            >
              {(props.session.me.limits.portfolioLimit > 1 ||
                props.documentType === 'portfolio') && (
                <FormControlLabel
                  value="self"
                  control={<Radio />}
                  label={
                    props.documentType === 'organization'
                      ? 'Only apply to future portfolios'
                      : 'Only apply to future properties added'
                  }
                />
              )}
              <FormControlLabel
                value="child"
                control={<Radio />}
                label={
                  props.documentType === 'organization'
                    ? props?.session?.me?.activeOrg?.member === 'RWN'
                      ? 'Apply assumptions on future properties'
                      : 'Update all existing portfolio assumptions'
                    : 'Update all existing properties'
                }
              />
              {props.documentType === 'organization' && (
                <FormControlLabel
                  value="grandchild"
                  control={<Radio />}
                  label={
                    props?.session?.me?.activeOrg?.member === 'RWN'
                      ? 'Apply assumptions on existing and future properties'
                      : 'Update all existing portfolios and properties'
                  }
                />
              )}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            size="small"
            onClick={handleCloseSaveDialog}
          >
            <Typography>Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ color: 'white' }}
            onClick={onSubmit}
          >
            <Typography>OK</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSaveSome}
        onClose={handleCloseSaveDialog}
        onKeyDown={stopPropagationForTab}
      >
        <DialogTitle>Bulk Assumptions</DialogTitle>
        <DialogContent>
          <FormControl
            component="fieldset"
            className={classes.formControl}
          >
            <RadioGroup
              aria-label="assumption application"
              name="childChange"
              className={classes.group}
              value={saveSome}
              onChange={(event) => setSaveSome(event.target.value)}
            >
              <FormControlLabel
                value={'change'}
                control={<Radio />}
                label="Apply only modified assumptions"
              />
              <FormControlLabel
                value={'all'}
                control={<Radio />}
                label="Apply all assumptions"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            size="small"
            onClick={handleCloseSaveDialog}
          >
            <Typography>Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ color: 'white' }}
            onClick={bulkSubmit}
          >
            <Typography>OK</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withSession(AssumptionsSettingsNotification)
