import { gql } from '@apollo/client'

export const GET_ORG_FROM_MARKETPLACE = gql`
  query getOrgFromMarketplace($url: String!) {
    getOrgFromMarketplace(url: $url) {
      _id
      name
      marketPlaces {
        name
        url
        meetingURL
        contactEmail
        googleAnalytics
        marketplaceVideoURL
        preApprovedURL
        status
        featuredProperties
        propertyCount
        submittedPropertyCount
        sharePermission
        shareDate
      }
      newMarketplace
    }
  }
`
