import LZMA from 'lzma-web'
const my_lzma = new LZMA()

export function compressAndStore(
  result,
  name,
  cities,
  state,
  reapiInputRef,
) {
  my_lzma.cb.compress(
    JSON.stringify(result),
    1,
    (compressedResult, error) => {
      if (error) {
        console.error('Compression error:', error)
        return
      }
      // Convert the Uint8Array (compressedResult) to a Base64 string
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(compressedResult)),
      )

      const size = new TextEncoder().encode(base64String).length
      if (size < 4 * 1024 * 1024) {
        localStorage.setItem('propertySearch', base64String)
        localStorage.setItem(
          'propertySearch-reapi',
          JSON.stringify(reapiInputRef.current),
        )
        localStorage.setItem(
          'propertySearch-name',
          name ?? `${cities}, ${state}`,
        )
      } else {
        localStorage.setItem('propertySearch', null)
        localStorage.setItem('propertySearch-reapi', null)
        localStorage.setItem('propertySearch-name', '')
      }
    },
    (progressPercentage) => {
      return
    },
  )
}

export function decompressAndLoad() {
  return new Promise((resolve, reject) => {
    const base64String = localStorage.getItem('propertySearch')
    if (!base64String) {
      console.error('No data to decompress')
      reject('No data to decompress')
      return
    }
    const binaryData = Uint8Array.from(atob(base64String), (c) =>
      c.charCodeAt(0),
    )

    my_lzma.cb.decompress(
      binaryData,
      (result, error) => {
        if (error) {
          console.error('Decompression error:', error)
          reject(error)
          return
        }
        const resultString =
          typeof result === 'string'
            ? result
            : new TextDecoder().decode(result)
        try {
          const parsedResult = JSON.parse(resultString)
          resolve(parsedResult)
        } catch (parseError) {
          console.error(
            'Error parsing decompressed result:',
            parseError,
          )
          reject(parseError)
        }
      },
      (progressPercentage) => {
        return
      },
    )
  })
}