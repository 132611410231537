/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: David Helmick
 * @ Modified time: 2024-06-11 18:00:48
 * @ Description: Card showing monthly limit, when limit resets, and option to change plans
 */

import React from 'react'
import { Button, Card } from '@mui/material'

import isEmpty from 'lodash/isEmpty'

import { CircularProgressbar } from 'react-circular-progressbar'
import { useMutation } from '@apollo/client'
import { CREATE_SESSION } from '../mutations'
import withSession from '@/housefolios-components/Session/withSession'
import useAffiliate from '@/hooks/useAffiliate'

function MonthlyLimitComponent(props) {
  const [createBillingPortalSession] = useMutation(CREATE_SESSION)

  const changePlan = async () => {
    var session = await createBillingPortalSession()

    window.open(
      session.data.createBillingPortalSession.url,
      '_blank',
      '',
    )
  }

  const [{ affiliate, affiliateLoading, urlAffiliate }] =
    useAffiliate({
      memberValue: props?.session?.me?.activeOrg?.member,
    })

  var today = new Date()
  var resetDate = !isEmpty(props.session.me.activeOrg.subscriptions)
    ? props.session.me.activeOrg.subscriptions[0].current_period_end
      ? new Date(
          props.session.me.activeOrg.subscriptions[0]
            .current_period_end * 1000,
        )
      : null
    : props.session.me.activeOrg.parentCorp &&
      !isEmpty(props.session.me.activeOrg.parentCorp.subscriptions)
    ? props.session.me.activeOrg.parentCorp.subscriptions[0]
        .current_period_end
      ? new Date(
          props.session.me.activeOrg.parentCorp.subscriptions[0]
            .current_period_end * 1000,
        )
      : null
    : !isEmpty(
        props?.session?.me?.activeOrg?.memberProvidedSubscriptions,
      )
    ? props?.session?.me?.activeOrg?.memberProvidedSubscriptions[0]
        .current_period_end
      ? new Date(
          props?.session?.me?.activeOrg
            ?.memberProvidedSubscriptions[0].current_period_end *
            1000,
        )
      : null
    : null
  if (!resetDate) {
    var month = today.getMonth()
    resetDate = new Date()
    if (month === 11) {
      resetDate.setFullYear(resetDate.getFullYear() + 1, 0, 1)
    } else resetDate.setMonth(month + 1, 1)
  }
  if (
    (resetDate.getFullYear() > today.getFullYear() &&
      (today.getMonth() !== 11 || resetDate.getMonth() !== 0)) ||
    resetDate.getMonth() > today.getMonth() + 1 ||
    resetDate.getMonth() === today.getMonth() + 1 ||
    resetDate.getDate() > today.getDate()
  ) {
    if (resetDate.getDate() > today.getDate()) {
      resetDate.setFullYear(today.getFullYear(), today.getMonth())
      if (resetDate.getMonth() !== today.getMonth())
        resetDate.setDate(0)
    } else {
      resetDate.setFullYear(today.getFullYear(), today.getMonth() + 1)
      if (
        resetDate.getMonth() !== today.getMonth() + 1 &&
        resetDate.getMonth() !== 0
      )
        resetDate.setDate(0)
    }
  }
  const timeToReset = resetDate - today
  const daysToReset = Math.floor(timeToReset / 1000 / 60 / 60 / 24)
  return (
    <Card className="card-box w-100 d-flex align-items-center">
      <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
        <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-2">
          <h6 className="font-weight-bold font-size-xl mb-1 text-black">
            Monthly Limit
          </h6>
        </div>
        <div className="mx-auto">
          <CircularProgressbar
            value={
              (props.session.me.activeOrg.propertyCount.monthlyCount /
                props.session.me.limits.propertyLimit) *
              100
            }
            text={
              props.session.me.activeOrg.propertyCount.monthlyCount +
              '/' +
              Number(props.session.me.limits.propertyLimit)
            }
            strokeWidth={8}
            className="m-3 circular-progress-xxl circular-progress-primary"
          />
        </div>
        {!isEmpty(props.session.me.activeOrg.subscriptions) && (
          <p className="mb-3 text-black-50 text-center">
            Limit resets in {daysToReset} days
          </p>
        )}
        {(props.session.me.subscriptionPlan ===
          'enterprise-affiliate' &&
          props.page === 'launchpad') ||
        urlAffiliate ? (
          <></>
        ) : (
          <div className="px-4 pb-4 pt-2">
            <Button
              onClick={
                isEmpty(props.session.me.activeOrg.subscriptions)
                  ? props.toggleSubscribe
                  : changePlan
              }
              fullWidth
              className="btn-neutral-dark font-weight-bold text-uppercase font-size-sm hover-scale-sm"
            >
              Manage Plan
            </Button>
          </div>
        )}
      </div>
    </Card>
  )
}

export default withSession(MonthlyLimitComponent)
