// ContactDialog.jsx
import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Divider,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ContactDialog = ({
  open,
  handleClose,
  affiliate = null,
  activeOrg = null,
}) => {
  let memberValue = ''
  if (affiliate) memberValue = affiliate
  if (activeOrg) memberValue = activeOrg

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Contact Support</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={12} sm={5}>
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-warning text-warning m-0 d-130">
                  <FontAwesomeIcon
                    icon={['far', 'phone']}
                    className="d-flex align-self-center display-3"
                  />
                </div>
              </div>
              <h4
                className="font-weight-bold mt-4"
                style={{ marginLeft: 20 }}
              >
                {memberValue === 'pep'
                  ? '(725) 331-1001'
                  : '(833) 457-7400'}
              </h4>
            </div>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={12} sm={5}>
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-warning text-danger m-0 d-130">
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    className="d-flex align-self-center display-3"
                  />
                </div>
              </div>
              <h4
                className="font-weight-bold mt-4"
                style={{ marginLeft: -30 }}
              >
                {memberValue === 'pep'
                  ? 'support@propertyedge.pro'
                  : 'support@dealsauce.io'}
              </h4>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

// Define PropTypes for type checking
ContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  affiliate: PropTypes.shape({
    memberValue: PropTypes.string,
  }),
}

// Define default props in case they are not provided
ContactDialog.defaultProps = {
  affiliate: {
    memberValue: '',
  },
}

export default ContactDialog
