import React from 'react'

import clsx from 'clsx'

import { IconButton, Link, List } from '@mui/material'

import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import YouTubeIcon from '@mui/icons-material/YouTube'

import { connect } from 'react-redux'
import useAffiliate from '@/hooks/useAffiliate'
import { Link as RouterLink } from 'react-router-dom'

const Footer = (props) => {
  const { footerShadow, footerBgTransparent } = props
  const [{ affiliate, affiliateLoading, urlDomain, urlAffiliate }] =
    useAffiliate({})

  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent,
        })}
      >
        <div className="app-footer--first">
          <List
            component="div"
            className="nav-neutral-primary d-flex align-items-center"
          >
            <Link
              className="rounded-sm"
              component={RouterLink}
              to="/Terms"
              target="_blank"
              style={{ color: '#a7aabb' }}
            >
              <span>Terms</span>
            </Link>
            <Link
              className="rounded-sm"
              component={RouterLink}
              to="/Privacy"
              target="_blank"
              style={{
                marginLeft: 20,
                marginRight: 20,
                color: '#a7aabb',
              }}
            >
              <span>Privacy</span>
            </Link>
            <Link
              className="rounded-sm"
              component={RouterLink}
              to="/Legal"
              target="_blank"
              style={{ color: '#a7aabb' }}
            >
              <span>Legal Disclaimer</span>
            </Link>
          </List>
        </div>
        <div className="app-footer--second">
          <span>
            Copyright © {new Date().getFullYear()}{' '}
            {(affiliate?.companyName === 'Deal Sauce (Subto)'
              ? 'Deal Sauce'
              : affiliate?.companyName) || 'Housefolios'}
            , inc.
          </span>
        </div>
        {!urlAffiliate && (
          <List
            component="div"
            className="nav-transparent-alt text-nowrap d-flex justify-content-center"
            style={{ paddingRight: 75 }}
          >
            <IconButton
              className="text-white-50"
              target="_blank"
              href="https://www.linkedin.com/company/housefolios/"
              size="large"
            >
              <LinkedInIcon
                style={{ color: '#cfcfcf' }}
                className="font-size-lg"
              />
            </IconButton>
            <IconButton
              className="text-white-50"
              target="_blank"
              href="https://www.facebook.com/Housefolios/"
              size="large"
            >
              <FacebookIcon
                style={{ color: '#cfcfcf' }}
                className="font-size-lg"
              />
            </IconButton>
            <IconButton
              className="text-white-50"
              target="_blank"
              href="https://www.instagram.com/housefolios/"
              size="large"
            >
              <InstagramIcon
                style={{ color: '#cfcfcf' }}
                className="font-size-lg"
              />
            </IconButton>
            <IconButton
              className="text-white-50"
              target="_blank"
              href="https://www.youtube.com/channel/UCrtQVie1gdD6-8puYRM0Auw"
              size="large"
            >
              <YouTubeIcon
                style={{ color: '#cfcfcf' }}
                className="font-size-lg"
              />
            </IconButton>
            <IconButton
              className="text-white-50"
              target="_blank"
              href="https://twitter.com/housefolios"
              size="large"
            >
              <TwitterIcon
                style={{ color: '#cfcfcf' }}
                className="font-size-lg"
              />
            </IconButton>
          </List>
        )}
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  footerShadow: state.DeprecatedThemeOptions.footerShadow,
  footerBgTransparent:
    state.DeprecatedThemeOptions.footerBgTransparent,
})

export default connect(mapStateToProps)(Footer)
