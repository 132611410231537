/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-04 12:23:49
 * @ Description: Dialog for sharing a property with another through email
 */

import React, { useState } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'

import map from 'lodash/map'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import find from 'lodash/find'
import split from 'lodash/split'
import concat from 'lodash/concat'

import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { Query } from '@apollo/client/react/components'
import { GET_ORGS_PROPERTY_SHARED_WITH } from '../queries'
import { useMutation } from '@apollo/client'
import { SHARE_PROPERTY } from '../mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import RemoveSharedProperties from '@/housefolios-components/Properties/RemoveSharedProperties'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Autocomplete from '@mui/material/Autocomplete'
import UpdateSharedProperties from '@/housefolios-components/Properties/UpdateSharedProperties'

function SharePropertyComponent(props) {
  const { notifications, rightClickMenu, propertyId } = props

  const [emails, setEmails] = useState([])
  const [sharePermission, setSharePermission] = useState('viewOnly')
  const [openShareProperty, setOpenShareProperty] = useState(false)
  const [loader, setLoader] = useState(false)
  const handleOpenShareProperty = () => {
    setOpenShareProperty(true)
    setEmails([])
  }
  const handleCloseShareProperty = () => {
    setOpenShareProperty(false)
    setLoader(false)
  }

  const [shareProperty] = useMutation(SHARE_PROPERTY)

  return (
    <>
      {notifications && (
        <MenuItem
          className="nav-item my-1"
          onClick={handleOpenShareProperty}
        >
          <FontAwesomeIcon
            icon={['far', 'share-nodes']}
            className="font-size-lg"
            style={{ marginRight: 10 }}
          />
          <span>Share Property</span>
        </MenuItem>
      )}
      {rightClickMenu && (
        <ListItem button onClick={handleOpenShareProperty}>
          <Grid container direction="row" spacing={1}>
            <Grid item className="gridItem2">
              {/* <ShareIcon /> */}
              <FontAwesomeIcon
                icon={['far', 'share-nodes']}
                className="font-size-lg"
                style={{ marginRight: 2 }}
              />
            </Grid>
            <Grid item className="gridItem2">
              <Typography>Share Property</Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {openShareProperty && (
        <Query
          query={GET_ORGS_PROPERTY_SHARED_WITH}
          variables={{
            propertyId: propertyId,
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return 'Loading...'
            if (error) return `Error! ${error.message}`
            const { orgsPropertySharedWith } = data
            return (
              <Dialog
                open={openShareProperty}
                onClose={handleCloseShareProperty}
                maxWidth="sm"
                fullWidth={true}
                scroll="body"
                onKeyDown={(event) => event.stopPropagation()}
              >
                <DialogTitle>Share this property</DialogTitle>
                <BlockUi
                  blocking={loader}
                  loader={
                    <PacmanLoader
                      color={'var(--primary)'}
                      loading={true}
                    />
                  }
                  message={
                    <div className="text-primary">
                      Sharing Property
                    </div>
                  }
                >
                  <ValidatorForm
                    onSubmit={(event) => {
                      setLoader(true)
                      shareProperty({
                        variables: {
                          id: propertyId,
                          emails,
                          sharePermission,
                        },
                      })
                        .then(() => {
                          enqueueSnackbar(`Email Sent`, {
                            variant: 'success',
                            autoHideDuration: 3000,
                          })
                          handleCloseShareProperty()
                        })
                        .catch((error) => {
                          enqueueSnackbar(`Failed to send email`, {
                            variant: 'error',
                            autoHideDuration: 3000,
                          })
                          setLoader(false)
                        })
                    }}
                    style={{
                      overflowY: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <DialogContent>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Autocomplete
                            multiple
                            id="emails"
                            options={[]}
                            value={emails}
                            freeSolo
                            autoSelect
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Send Email To"
                                placeholder="Emails"
                                helperText="Enter comma separated emails (no spaces). Press enter"
                              />
                            )}
                            onChange={(event, inputs, reason) => {
                              if (
                                'createOption' == reason ||
                                'blur' == reason
                              ) {
                                let values = split(
                                  inputs[inputs.length - 1],
                                  ',',
                                )
                                let validatedItems = []

                                for (let value of values) {
                                  if (
                                    String(value)
                                      .toLowerCase()
                                      .match(
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                      )
                                  )
                                    validatedItems.push(value)
                                }

                                let newList = concat(
                                  emails,
                                  validatedItems,
                                )
                                setEmails(newList)
                              } else if ('removeOption' == reason) {
                                setEmails(inputs)
                              } else if ('clear' == reason) {
                                setEmails([])
                              }
                            }}
                          />
                          {/* <TextValidator
                                           name="email"
                                           type="email"
                                           variant='outlined'
                                           margin='dense'
                                           autoFocus
                                           onChange={event => setEmail(event.target.value)}
                                           value={email}
                                           placeholder="Enter email address..."
                                           label="Email"
                                           fullWidth={true}
                                           validators={[
                                           'required',
                                           'maxStringLength: 50',
                                           'isEmail',
                                           ]}
                                           errorMessages={[
                                           'This field is required',
                                           'You cannot exceed 50 characters',
                                           'Not a valid email address',
                                           ]}
                                       /> */}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            select
                            name="sharePermission"
                            variant="outlined"
                            margin="dense"
                            label="Permission"
                            fullWidth={true}
                            // style={{ width: 175 }}
                            value={sharePermission}
                            onChange={(event) =>
                              setSharePermission(event.target.value)
                            }
                            required={true}
                          >
                            <MenuItem
                              key={'viewOnly'}
                              value={'viewOnly'}
                            >
                              {'View Only'}
                            </MenuItem>
                            <MenuItem
                              key={'canEdit'}
                              value={'canEdit'}
                            >
                              {'Can Edit'}
                            </MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={12} style={{ padding: 10 }}>
                          {!isEmpty(orgsPropertySharedWith) && (
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography>Shared With</Typography>
                              </Grid>
                              {map(orgsPropertySharedWith, (org) => (
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Grid item>
                                      <Avatar>
                                        {head(org.name)}
                                      </Avatar>
                                    </Grid>
                                    <Grid item>
                                      <Grid
                                        container
                                        direction="column"
                                        className="line-height-sm"
                                      >
                                        <Grid item>
                                          <b className="font-size-lg">
                                            {org.name}
                                          </b>
                                        </Grid>
                                        <Grid item>
                                          <span className="text-black-50 d-block">
                                            {org.email}
                                          </span>
                                        </Grid>
                                        <Grid item>
                                          <span className="text-black-50 d-block">
                                            {get(
                                              find(
                                                org.sharedProperties,
                                                ['id', propertyId],
                                              ),
                                              'sharePermission',
                                            ) === 'canEdit'
                                              ? 'Can Edit'
                                              : 'View Only'}
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Typography style={{ flex: 1 }} />
                                    <Grid item>
                                      <UpdateSharedProperties
                                        orgId={org._id}
                                        selected={[propertyId]}
                                        shareAccess
                                        sharePermission={get(
                                          find(org.sharedProperties, [
                                            'id',
                                            propertyId,
                                          ]),
                                          'sharePermission',
                                        )}
                                      />
                                    </Grid>
                                    <Grid item>
                                      <RemoveSharedProperties
                                        orgId={org._id}
                                        selected={[propertyId]}
                                        shareAccess
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="text"
                        size="small"
                        onClick={handleCloseShareProperty}
                      >
                        <Typography>CANCEL</Typography>
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        type="submit"
                        disabled={loader}
                      >
                        <Typography>SHARE</Typography>
                      </Button>
                    </DialogActions>
                  </ValidatorForm>
                </BlockUi>
              </Dialog>
            )
          }}
        </Query>
      )}
    </>
  )
}
export default withSession(SharePropertyComponent)
