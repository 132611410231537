/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2024-08-07 12:17:56
 * @ Description: Dialog providing sharable links to see the pdf reports of the property
 */

import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Card,
  Dialog,
  Fab,
  Grid,
  InputAdornment,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import { getProformaLink } from '@/utils/proforma'
import { getStrategyName } from '@/utils/stratagies'

function SharePDF(props) {
  const { propertyId, handleShareClose } = props

  const hostname = import.meta.env.VITE_HOSTNAME

  const [type, setType] = useState(props.type || 'buyHold')

  useEffect(() => {
    setType(props.type || 'buyHold')
  }, [props.type])

  const proformaLink = getProformaLink(propertyId, [type])
  const previewLink = getProformaLink(propertyId, [type], true)

  const encodedProformaLink = encodeURIComponent(proformaLink)

  const [modal1, setModal1] = useState(false)

  const toggle1 = () => {
    setModal1(!modal1)
    handleShareClose()
  }

  return (
    <>
      <ListItem key="share" button onClick={toggle1}>
        <div className="nav-link-icon">
          <FontAwesomeIcon icon={['far', 'share-nodes']} />
        </div>
        <span>Share Proforma</span>
      </ListItem>
      <Dialog
        scroll="body"
        maxWidth="lg"
        open={modal1}
        onClose={toggle1}
        classes={{
          paper: 'modal-content rounded border-0 bg-white p-3 p-xl-0',
        }}
      >
        <Grid container spacing={0}>
          <Grid container item xs={12}>
            <Grid item>
              <div className="mt-4 mt-xl-0">
                <div className="m-4">
                  <h1 className="display-4 my-3 font-weight-bold">
                    Share Your Report
                  </h1>
                  <p className="font-size-md mb-0 text-black-50">
                    Share the link to your report over social media!
                  </p>
                </div>
                <div className="text-center">
                  <Select
                    variant="outlined"
                    margin="dense"
                    value={type}
                    onChange={(event) => setType(event.target.value)}
                  >
                    <MenuItem key="buyhold" value={'buyHold'} button>
                      <ListItemText
                        primary={getStrategyName(
                          'Buy & Hold',
                          props?.session?.me?.activeOrg?.member,
                        )}
                      />
                    </MenuItem>
                    <MenuItem key="fixFlip" value={'fixFlip'} button>
                      <ListItemText
                        primary={getStrategyName(
                          'Fix & Flip',
                          props?.session?.me?.activeOrg?.member,
                        )}
                      />
                    </MenuItem>
                    <MenuItem
                      key="marketing"
                      value={'marketing'}
                      button
                    >
                      <ListItemText
                        primary={
                          props.session.me.activeOrg
                            .southernImpressions
                            ? getStrategyName(
                                'Build to Rent',
                                props?.session?.me?.activeOrg?.member,
                              )
                            : 'End Buyer'
                        }
                      />
                    </MenuItem>
                    <MenuItem
                      key="shortTermRental"
                      value={'shortTermRental'}
                      button
                    >
                      <ListItemText
                        primary={getStrategyName(
                          'Short Term Rental',
                          props?.session?.me?.activeOrg?.member,
                        )}
                      />
                    </MenuItem>
                    {props?.session?.me?.activeOrg?.member !==
                      'astroflip' && (
                      <MenuItem key="wholesale" value={'wholesale'}>
                        <ListItemText
                          primary={getStrategyName(
                            'Wholesale',
                            props?.session?.me?.activeOrg?.member,
                          )}
                        />
                      </MenuItem>
                    )}
                    {props?.session?.me?.activeOrg?.member ===
                      'astroflip' && (
                      <MenuItem key="astroflip" value={'astroflip'}>
                        <ListItemText
                          primary={getStrategyName(
                            'Astroflip',
                            props?.session?.me?.activeOrg?.member,
                          )}
                        />
                      </MenuItem>
                    )}
                  </Select>
                </div>
                <Card className="shadow-none bg-transparent p-4 border-0">
                  <div className="card-header d-block p-3 pt-0 rounded bg-light">
                    <div className="text-muted text-center mb-3">
                      <small>Click below to share!</small>
                    </div>
                    <div className="text-center">
                      <Grid container direction="row" spacing={1}>
                        <Grid item>
                          <Button
                            className="btn-facebook"
                            target="_blank"
                            href={
                              'https://www.facebook.com/sharer/sharer.php?u=' +
                              encodedProformaLink
                            }
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon
                                icon={['fab', 'facebook']}
                              />
                            </span>
                            <span className="btn-wrapper--label">
                              Facebook
                            </span>
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            className="btn-twitter"
                            href={
                              'https://twitter.com/intent/tweet?text=' +
                              encodedProformaLink
                              //'I%27ve%20been%20using%20Housefolios%20real%20estate%20software%20to%20analyze%20investment%20properties.%20Using%20this%20link%20will%20let%20you%20analyze%2010%20properties%20(and%20give%20me%2010%20more%20properties!)'
                            }
                            target="_blank"
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon
                                icon={['fab', 'twitter']}
                              />
                            </span>
                            <span className="btn-wrapper--label">
                              Twitter
                            </span>
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            className="btn-twitter"
                            href={
                              'https://www.linkedin.com/sharing/share-offsite/?url=' +
                              encodedProformaLink
                            }
                            target="_blank"
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon
                                icon={['fab', 'linkedin']}
                              />
                            </span>
                            <span className="btn-wrapper--label">
                              LinkedIn
                            </span>
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div>
                    <div className="text-center text-muted my-3">
                      <small>Or use your unique link below!</small>
                    </div>
                    <div className="mb-3">
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="textfield-email"
                        value={proformaLink}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CopyToClipboard
                                text={proformaLink}
                                onCopy={() =>
                                  enqueueSnackbar(
                                    `Copied to Clipboard`,
                                    {
                                      variant: 'success',
                                      autoHideDuration: 3000,
                                    },
                                  )
                                }
                              >
                                <Fab size="small" color="primary">
                                  <FileCopyOutlinedIcon
                                    style={{ color: 'white' }}
                                  />
                                </Fab>
                              </CopyToClipboard>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ color: 'white' }}
                          className="font-weight-bold px-4 my-3"
                          target="_blank"
                          href={previewLink}
                        >
                          Preview
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ color: 'white' }}
                          onClick={toggle1}
                          className="font-weight-bold px-4 my-3"
                        >
                          Close
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default withSession(SharePDF)
