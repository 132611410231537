/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-24 19:59:53
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-04 11:56:40
 * @ Description: List of options to filter seen assumptions by.
 */

import React from 'react'
import map from 'lodash/map'
import lodashFilter from 'lodash/filter'
import includes from 'lodash/includes'
import pull from 'lodash/pull'

import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import { getStrategyOptions } from '@/utils/stratagies'

export default function AssumptionsFilter(props) {
  const { filter, setFilter } = props

  // function handleChange(value) {
  //   let newFilters = [...filters]
  //   if (includes(newFilters, value))
  //     pull(newFilters, value)
  //   else
  //     newFilters.push(value)
  //   setFilters(newFilters)
  // }

  return (
    <Card style={{ width: '100%' }}>
      <div className="card-header">
        <div className="card-header--title">
          <b>
            {props.targetOnly
              ? 'Show Targets For'
              : 'Show Assumptions For'}
          </b>
        </div>
      </div>
      <Divider />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ width: '100%' }}
        >
          {/* {map(filterOptions, section => (
            <Grid item key={section.label} xs={6} md={12} xl={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includes(filters, section.label)}
                    onChange={() => handleChange(section.label)}
                    value="checked1"
                    color="primary"
                  />
                }
                label={section.label}
              />
            </Grid>
          ))} */}
          <Grid item style={{ width: '100%' }}>
            <TextField
              select
              variant="outlined"
              margin="dense"
              label="Assumptions Filter"
              fullWidth={true}
              style={{ minWidth: 175 }}
              value={filter}
              onChange={(event) => setFilter(event.target.value)}
              required={true}
            >
              {getStrategyOptions(props.member)}
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
