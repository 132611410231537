import { gql } from '@apollo/client'

export const CREATE_COLUMN = gql`
  mutation CreateKanbanBoardColumn(
    $orgId: ID!
    $input: ColumnInput!
  ) {
    createKanbanBoardColumn(orgId: $orgId, input: $input) {
      errors {
        message
      }
      kanbanBoard {
        columns {
          _id
          name
        }
      }
    }
  }
`

export const UPDATE_COLUMN = gql`
  mutation UpdateKanbanBoardColumn(
    $orgId: ID!
    $columnId: ID!
    $input: ColumnInput!
  ) {
    updateKanbanBoardColumn(
      orgId: $orgId
      columnId: $columnId
      input: $input
    ) {
      errors {
        message
      }
      kanbanBoard {
        columns {
          _id
          name
        }
      }
    }
  }
`

export const DELETE_COLUMN = gql`
  mutation DeleteKanbanBoardColumn($orgId: ID!, $columnId: ID!) {
    deleteKanbanBoardColumn(orgId: $orgId, columnId: $columnId) {
      errors {
        message
      }
      kanbanBoard {
        columns {
          _id
          name
        }
      }
    }
  }
`
