/**
 * @ Author: Housefolios
 * @ Create Time: 2023-03-22 13:41:28
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-04 12:25:54
 * @ Description: Dialog to Update a property or selected properties
 */

import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material'

import map from 'lodash/map'
import concat from 'lodash/concat'

import { enqueueSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import { GET_SHARED_PROPERTIES } from '../queries'
import {
  REMOVE_SHARED_PROPERTIES,
  UPDATE_SHARED_PROPERTIES,
} from '../mutation'
import { GET_ME } from '@/housefolios-components/Session/queries'
import withSession from '@/housefolios-components/Session/withSession'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { GET_ORGS_PROPERTY_SHARED_WITH } from '@/housefolios-components/Property/queries'

function UpdateSharedPropertiesComponent(props) {
  const { bulkActionsNotifications, rightClickMenu, shareAccess } =
    props
  const [loader, setLoader] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)

  const [sharePermission, setSharePermission] = useState(
    props.sharePermission || 'viewOnly',
  )

  const toggleOpen = () => {
    setOpenUpdate(!openUpdate)
  }

  const [updateSharedProperties] = useMutation(
    UPDATE_SHARED_PROPERTIES,
  )

  const handleUpdate = () => {
    setLoader(true)
    updateSharedProperties({
      variables: {
        propertyIds: props.selected,
        orgId: props.orgId,
        sharePermission,
      },
      refetchQueries: concat(
        [
          {
            query: GET_SHARED_PROPERTIES,
            variables: {},
          },
          {
            query: GET_ME,
          },
        ],
        map(props.selected, (propertyId) => {
          return {
            query: GET_ORGS_PROPERTY_SHARED_WITH,
            variables: {
              propertyId,
            },
          }
        }),
      ),
    })
      .then(() => {
        enqueueSnackbar(`Selected Properties Updated`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        if (props.setSelected) props.setSelected([])
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to Update Selected Properties`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
      .finally(() => {
        setLoader(false)
        toggleOpen()
      })
  }

  const isRecipient = props.orgId === props.session.me.activeOrg._id

  return (
    <>
      {bulkActionsNotifications && (
        <Tooltip title="Bulk Update Access">
          <IconButton
            onClick={toggleOpen}
            disabled={loader}
            size="large"
          >
            <FontAwesomeIcon
              icon={['far', 'trash-can']}
              style={{ color: 'white' }}
            />
          </IconButton>
        </Tooltip>
      )}
      {rightClickMenu && (
        <ListItem button onClick={toggleOpen} disabled={loader}>
          <div className="nav-link-icon">
            <FontAwesomeIcon icon={['far', 'trash']} />
          </div>
          <span>Update Properties' Access</span>
        </ListItem>
      )}
      {shareAccess && (
        <IconButton onClick={toggleOpen} size="large">
          <FontAwesomeIcon icon={['far', 'pen-to-square']} />
        </IconButton>
      )}
      <Dialog
        open={openUpdate}
        onClose={toggleOpen}
        maxWidth="sm"
        fullWidth={true}
        scroll="body"
      >
        <DialogTitle>{'Update Access'}</DialogTitle>
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              Removing Shared Properties
            </div>
          }
        >
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  select
                  name="sharePermission"
                  variant="outlined"
                  margin="dense"
                  label="Permission"
                  fullWidth={true}
                  // style={{ width: 175 }}
                  value={sharePermission}
                  onChange={(event) =>
                    setSharePermission(event.target.value)
                  }
                  required={true}
                >
                  <MenuItem key={'viewOnly'} value={'viewOnly'}>
                    {'View Only'}
                  </MenuItem>
                  <MenuItem key={'canEdit'} value={'canEdit'}>
                    {'Can Edit'}
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={toggleOpen}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdate}
            >
              Update
            </Button>
          </DialogActions>
        </BlockUi>
      </Dialog>
    </>
  )
}

export default withSession(UpdateSharedPropertiesComponent)
