/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-09-11 14:39:41
 * @ Description: Simple A to D ranking analysis for a property
 */

import React from 'react'
import map from 'lodash/map'
import selectHouse from '@/assets/images/selectHouse.png'

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Hidden,
  ListItem,
  Typography,
  useMediaQuery,
} from '@mui/material'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import Map from '../../../Properties/Map'

const neighborhoodGrades = [
  {
    value: 'a',
    label: 'A',
  },
  {
    value: 'a-',
    label: 'A-',
  },
  {
    value: 'b+',
    label: 'B+',
  },
  {
    value: 'b',
    label: 'B',
  },
  {
    value: 'b-',
    label: 'B-',
  },
  {
    value: 'c+',
    label: 'C+',
  },
  {
    value: 'c',
    label: 'C',
  },
  {
    value: 'c-',
    label: 'C-',
  },
  {
    value: 'd',
    label: 'D',
  },
]

const propertyGrades = [
  {
    value: 'a',
    label: 'A',
  },
  {
    value: 'a-',
    label: 'A-',
  },
  {
    value: 'b+',
    label: 'B+',
  },
  {
    value: 'b',
    label: 'B',
  },
  {
    value: 'b-',
    label: 'B-',
  },
  {
    value: 'c+',
    label: 'C+',
  },
  {
    value: 'c',
    label: 'C',
  },
  {
    value: 'c-',
    label: 'C-',
  },
  {
    value: 'd',
    label: 'D',
  },
]

export default function CenterPageMarketAnalysisComponent(props) {
  const { property = {}, analysisInput, renderInputFields } = props
  const hiddenDown = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  )

  const rankingFields = [
    {
      type: 'select',
      name: 'neighborhood',
      label: 'Neighborhood',
      value: analysisInput.neighborhood,
      xs: 6,
      options: neighborhoodGrades,
    },
    {
      type: 'select',
      name: 'house',
      label: 'Property',
      value: analysisInput.house,
      xs: 6,
      options: propertyGrades,
    },
  ]

  return (
    <>
      <Grid container spacing={2} style={{ padding: 25 }}>
        <Grid item xs={12} md={6}>
          <Card>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {/* <Grid item>
              <Typography>Map View</Typography>
            </Grid> */}
              <Grid item style={{ width: '100%' }}>
                <Map
                  properties={[property]}
                  propertyPage
                  streetView={false}
                  shared={props.shared}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {!props.public && (
          <Grid item container md={6}>
            <Grid item xs={12}>
              <Card data-tour="analyzeArea-step-2">
                <CardHeader
                  title={
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 550 }}
                    >
                      Class Ranking
                    </Typography>
                  }
                />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                  >
                    {map(rankingFields, (field) =>
                      renderInputFields(field),
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                {/* <CardHeader
                title='Why rank properties?'
                subheader='The rankings help you determine if the projected returns justify the risk of the investment'
              />
              <Divider /> */}
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    spacing={2}
                  >
                    {hiddenDown ? null : (
                      <Grid item>
                        <img
                          src={selectHouse}
                          alt=""
                          className="d-70"
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography
                            variant="h5"
                            style={{ fontWeight: 600 }}
                          >
                            Why rank properties?
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2">
                            The rankings help you determine if the
                            projected returns justify the risk of the
                            investment
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions
                  data-tour="analyzeArea-step-3"
                  style={{ padding: 0 }}
                >
                  {/* <ListItem
                    component="a"
                    button
                    href="http://help.housefolios.com/en/articles/4720725-property-class-rankings"
                    disableRipple
                    target="_blank"
                    style={{ width: '100%' }}
                  >
                    <Typography color="primary">
                      Learn how to rank properties
                    </Typography>
                    <ChevronRightIcon color="primary" />
                  </ListItem> */}
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}
