import React, { useState, useMemo } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
  Box,
  Chip,
  TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import find from 'lodash/find'
import { GET_TEAM } from '@/housefolios-components/Team/queries'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { useMutation, useQuery } from '@apollo/client'

function CommentsDialog({ property, userId = '' }) {
  const [open, setOpen] = useState(false)
  const [comments, setComments] = useState(property.comments || [])
  const [newComment, setNewComment] = useState('')

  const { loading, data, error } = useQuery(GET_TEAM)
  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  const { teamMembers = [] } = data || {}

  const handleClose = () => {
    setOpen(false)
  }

  const handleCommentSubmit = () => {
    if (newComment.trim()) {
      const newComments = [
        {
          text: newComment,
          posterId: userId,
          date: new Date().toLocaleString(),
        },
        ...comments,
      ]
      setComments(newComments)
      setNewComment('')
      updateProperty({
        variables: {
          id: property._id,
          propertyInput: {
            comments: newComments,
          },
        },
      })
    }
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        fullWidth
      >
        <Typography>Open Comments</Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {`${property.address}, ${property.city}, ${property.state}, ${property.zip}`}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {/* Comments Section */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Comments
              </Typography>
              <List
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: 1,
                  minHeight: '300px',
                }}
              >
                {comments.map((comment, index) => {
                  const poster = find(teamMembers, (member) => {
                    return member._id === comment.posterId
                  })
                  const posterName = `${poster?.profile.firstname} ${poster?.profile.lastname}`
                  return (
                    <ListItem key={index} divider>
                      <ListItemText
                        primary={comment.text}
                        secondary={`Posted by ${
                          poster?.profile ? posterName : 'Not on Team'
                        } - ${comment.date}`}
                        style={{ wordWrap: 'break-word' }}
                      />
                    </ListItem>
                  )
                })}
              </List>
              <TextField
                label="New Comment"
                fullWidth
                multiline
                rows={3}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                sx={{ marginTop: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleCommentSubmit}
                fullWidth
                sx={{ marginTop: 1 }}
              >
                Submit Comment
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CommentsDialog
