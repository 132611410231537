/**
 * @ Author: Housefolios
 * @ Create Time: 2024-05-31 17:46:01
 * @ Modified by: David Helmick
 * @ Modified time: 2025-01-27 14:23:10
 * @ Description: Dialog for setting Pita data fields
 */

import React, { useEffect, useRef, useState } from 'react'

import clsx from 'clsx'

import map from 'lodash/map'
import round from 'lodash/round'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import padStart from 'lodash/padStart'
import head from 'lodash/head'
import find from 'lodash/find'
import slice from 'lodash/slice'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import replace from 'lodash/replace'
import isUndefined from 'lodash/isUndefined'
import kebabCase from 'lodash/kebabCase'
import zip from 'lodash/zip'
import unset from 'lodash/unset'
import set from 'lodash/set'
import get from 'lodash/get'
import sum from 'lodash/sum'
import debounce from 'lodash/debounce'

import GaugeChart from 'react-gauge-chart'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import RemoveIcon from '@mui/icons-material/Remove'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import ImageIcon from '@mui/icons-material/Image'
import RoomIcon from '@mui/icons-material/Room'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'

import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import withSession from '@/housefolios-components/Session/withSession'
import { enqueueSnackbar } from 'notistack'
import { NumericFormat } from 'react-number-format'
import { PacmanLoader } from 'react-spinners'
import BlockUi from 'react-block-ui'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import Autocomplete from '@mui/material/Autocomplete'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import {
  GET_COMPPROPERTIES,
  GET_PROPERTY,
} from '@/housefolios-components/PropertyContainer/queries'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_ASSUMPTIONS } from '@/housefolios-components/AssumptionsSettings/quries'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import renderInputFieldsFunction from '@/housefolios-components/Functions/renderInput'
import {
  Compare,
  ExpandLess,
  ExpandMore,
  Tune,
} from '@mui/icons-material'
import useAffiliate from '@/hooks/useAffiliate'
import PropTypes from 'prop-types'
import { Check, InfoOutlined, Info } from '@mui/icons-material'

import {
  filterPreferedStrategies,
  getStrategyName,
} from '@/utils/stratagies'
import { currencyFormat, percentageFormat } from '@/utils/number'
import DisplayRealeflowData from '../../DisplayRealeflowData'
import {
  calculatePITI,
  ContactInformation,
  getPitiDataObject,
} from '@/utils/loanBalance'
import useEstimatesMenu from '@/hooks/useEstimatesMenu'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import CommentsDialog from '../../Comments'

function StepIcon(props) {
  const { active, completed } = props

  const icons = {
    1: 1,
    2: 2,
    3: 3,
  }

  return (
    <div
      className={clsx(
        'd-50 transition-base d-flex align-items-center bg-gray-400 justify-content-center rounded',
        {
          'd-80 bg-primary text-white shadow-primary-sm': active,
          'd-50 bg-success text-white shadow-success-sm': completed,
        },
      )}
    >
      {completed ? (
        <Check className="completed" />
      ) : (
        icons[String(props.icon)]
      )}
    </div>
  )
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}

function getSteps() {
  // return ['PITI', 'Exit Strategy']
  return ['PITI']
}

const gaugeFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
})

function SubtoPITICashDialog(props) {
  const { open, onClose, propertyId, address, city, state, zipcode } =
    props
  const [analysisInput, setAnalysisInput] = useState({})
  const [assumptionsInput, setAssumptionsInput] = useState({})
  const [pitiData, setPitiData] = useState({})
  const [activeUnit, setActiveUnit] = useState(0)

  const [loader, setLoader] = useState(false)

  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  const [{ affiliate, urlAffiliate }] = useAffiliate({})

  const [callProperty, results] = useLazyQuery(GET_PROPERTY, {
    variables: { id: propertyId },
  })
  let { property = {} } = results.data || {}
  const propertyLoading = results.loading || !results.called
  const propertyError = results.error

  const { acquisition = {}, assumptions = {} } = property
  const { analysis = {}, analytics = {} } = acquisition || {}

  // Function to format a Date object to 'YYYY-MM-DD' format
  const formatDate = (input) => {
    let date
    // Check if the input is a string
    if (typeof input === 'string') {
      // Convert the string to a Date object
      date = new Date(input)
    } else if (input instanceof Date) {
      // If it's already a Date object, use it directly
      date = input
    } else {
      // If the input is neither a string nor a Date object, return an error or handle it
      throw new Error(
        'Invalid input: Expected a Date object or a valid date string.',
      )
    }

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const parseDateString = (dateString) => {
    const year = dateString.substring(0, 4)
    const month = dateString.substring(4, 6)
    const day = dateString.substring(6, 8)
    const date = new Date(year, month - 1, day)
    return date
  }

  useEffect(() => {
    setActiveUnit(0)
    if (props.open && props.propertyId && !results.called) {
      callProperty()
    } else if (!props.open || !props.propertyId) {
      setPitiData({})
      setAnalysisInput({})
      setAssumptionsInput({})
      setLoader(false)
    }
  }, [props.open])

  useEffect(() => {
    let propertyTaxes = includes(
      property?.realeflowData?.TaxAmount ||
        property?.realeflowData?.SubjectProperty?.TaxAmount,
      '$',
    )
      ? property?.realeflowData?.TaxAmount ||
        property?.realeflowData?.SubjectProperty?.TaxAmount
      : Number(
          property?.realeflowData?.TaxAmount ||
            property?.realeflowData?.SubjectProperty?.TaxAmount,
        ) / 100
    let financeAmount = Number(
      get(property, 'realeflowData.LoanAmount') ||
        get(property, 'realeflowData.SubjectProperty.TotalLoans') ||
        0,
    )
    let monthlyRate =
      Number(
        get(
          property,
          'realeflowData.SubjectProperty.Mortgage1InterestRate',
        ),
      ) ||
      Number(get(property, 'realeflowData.MortgageInterestRate')) ||
      0
    let propertyInsurance = calculateDefaultInsurance(
      Number(get(property, 'realeflowData.AVM')) ||
        Number(get(property, 'realeflowData.SubjectProperty.AVM')) ||
        0,
    )
    // Getting and formatting the start date

    const startDateOriginal = get(
      property,
      'realeflowData.RecordingDate',
    )
      ? new Date(get(property, 'realeflowData.RecordingDate'))
      : get(
            property,
            'realeflowData.SubjectProperty.Assessor.curr_sale_recording_date',
          )
        ? parseDateString(
            get(
              property,
              'realeflowData.SubjectProperty.Assessor.curr_sale_recording_date',
            ),
          )
        : undefined

    let startDate
    if (startDateOriginal) {
      startDate = formatDate(startDateOriginal)
    }

    const piti = calculatePITI({
      financeAmount,
      monthlyRate,
      propertyTaxes,
      propertyInsurance,
      startDate,
    })

    if (
      // isEmpty(pitiData) &&
      !propertyLoading &&
      !isEmpty(property) &&
      propertyId === property._id &&
      props.open //&&
      // property?.realeflowData
    ) {
      setPitiData(
        getPitiDataObject({
          property,
          piti,
        }),
      )
    }
  }, [propertyLoading, props.open])

  useEffect(() => {
    if (
      isEmpty(analysisInput) &&
      !propertyLoading &&
      !isEmpty(analysis) &&
      props.propertyId === property._id &&
      props.open
    ) {
      let newAnalysisInput = cloneDeep(analysis)
      unset(newAnalysisInput, 'createdBy')
      unset(newAnalysisInput, 'createdAt')
      unset(newAnalysisInput, '__typename')
      // newAnalysisInput.rent = [Number(get(property, 'realeflowData.RentalValue')) || 0]
      setAnalysisInput(newAnalysisInput)

      setStrategies(
        property && !isEmpty(property.strategies)
          ? property.strategies
          : filterPreferedStrategies(
              preferences,
              props?.session?.me?.activeOrg?.member,
              true,
            ),
      )
    }
  }, [analysis, analysisInput, propertyLoading, props.open])

  useEffect(() => {
    if (
      isEmpty(assumptionsInput) &&
      !propertyLoading &&
      !isEmpty(assumptions) &&
      props.propertyId === property._id
    ) {
      let newAssumptionsInput = cloneDeep(assumptions)
      unset(newAssumptionsInput, '_id')
      unset(newAssumptionsInput, '__v')
      unset(newAssumptionsInput, '__typename')
      unset(newAssumptionsInput, 'documentId')
      unset(newAssumptionsInput, 'documentType')
      unset(newAssumptionsInput, 'updatedAt')
      unset(newAssumptionsInput, 'updatedBy')
      setAssumptionsInput(newAssumptionsInput)
    }
  }, [assumptions, assumptionsInput, propertyLoading])

  // const [source, setSource] = useState(null)

  const onChangePiti = (event, source = null) => {
    let { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value) || 0
    let newPitiData = cloneDeep(pitiData)
    //  if (name === 'ownerOccupied') {
    //   newAnalysisInput.ownerOccupied = newAnalysisInput.ownerOccupied !== activeUnit ? activeUnit : -1
    //   name = `rent.${activeUnit}`
    //   newValue = 0
    // }
    set(newPitiData, name, newValue)
    setPitiData(newPitiData)
  }

  const uploadFile = (name) => (file) => {
    let newPitiData = cloneDeep(pitiData)
    set(newPitiData, name, file)
    setPitiData(newPitiData)
  }

  const onChangeAnalysis = (event, source = null) => {
    let { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value) || 0
    let newAnalysisInput = cloneDeep(analysisInput)
    //  if (name === 'ownerOccupied') {
    //   newAnalysisInput.ownerOccupied = newAnalysisInput.ownerOccupied !== activeUnit ? activeUnit : -1
    //   name = `rent.${activeUnit}`
    //   newValue = 0
    // }
    set(newAnalysisInput, name, newValue)
    setAnalysisInput(newAnalysisInput)
  }

  const handleAnalysisAdornmentChange = (e, name, value) => {
    e.preventDefault()
    let newAnalysisInput = { ...analysisInput }
    newAnalysisInput[name] = value
    setAnalysisInput(newAnalysisInput)
    handleAssumptionsMenuClose()
  }

  const onChangeAssumptions = (event, source = null) => {
    let { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value)
    let newAssumptionsInput = cloneDeep(assumptionsInput)
    set(newAssumptionsInput, name, newValue)
    unset(newAssumptionsInput, '_id')
    unset(newAssumptionsInput, '__v')
    unset(newAssumptionsInput, '__typename')
    unset(newAssumptionsInput, 'documentId')
    unset(newAssumptionsInput, 'documentType')
    unset(newAssumptionsInput, 'updatedAt')
    unset(newAssumptionsInput, 'updatedBy')
    setAssumptionsInput(newAssumptionsInput)
  }

  const [anchorElAssumptions, setAnchorElAssumptions] = useState(null)
  const [showFormatDropdown, setShowFormatDropdown] = useState('')

  const toggleAssumptionsMenuFormat = (event, name) => {
    setAnchorElAssumptions(event.target)
    setShowFormatDropdown(name)
  }

  const handleAssumptionsMenuClose = () => {
    setAnchorElAssumptions(null)
  }

  const handleAdornmentChange = (e, name, value) => {
    e.preventDefault()
    let newAssumptions = { ...assumptionsInput }
    set(newAssumptions, name, value)
    unset(newAssumptions, '_id')
    unset(newAssumptions, '__v')
    unset(newAssumptions, '__typename')
    unset(newAssumptions, 'documentId')
    unset(newAssumptions, 'documentType')
    unset(newAssumptions, 'updatedAt')
    unset(newAssumptions, 'updatedBy')
    setAssumptionsInput(newAssumptions)
    handleAssumptionsMenuClose()
  }

  const handleDefinition = (name, anchorEl, target = '') => {
    setMenu(name)
    setMenuTarget(target)
    setOpenNoData(false)
    setAnchorElEstimates(anchorEl)
  }

  const [anchorElEstimates, setAnchorElEstimates] = useState(null)
  const [menu, setMenu] = useState('')
  const [menuTarget, setMenuTarget] = useState('')
  const [estimatesCollapse, setEstimatesCollapse] = useState({})
  const [openNoData, setOpenNoData] = useState(false)

  const [strategies, setStrategies] = useState([])

  const changeMenu = (name, value, source = null) => {
    let newName = name
    let newValue = value
    if (
      includes(name, 'rent') ||
      name == 'avgRate' ||
      name === 'occupancyRate'
    ) {
      // if (assumptionsInput[name + 'Type'] === 'rentRange') {
      //   onChangeRange(
      //     replace(name, 'rent', 'rentLow' || 0) +'.' + activeUnit,
      //     replace(name, 'rent', 'rentHigh' || 0) +'.' + activeUnit,
      //     Number(newValue),
      //     Number(newValue),
      //     source,
      //   )
      //   setMenu('')
      //   setMenuTarget('')
      //   setAnchorElEstimates(null)
      //   return
      // }
      newName = name + '.' + activeUnit
    }
    if (name == 'avgRate' || name === 'occupancyRate')
      onChangeAssumptions(
        {
          target: {
            name: newName,
            value: round(newValue),
            type: 'number',
          },
        },
        source,
      )
    else
      onChangeAnalysis(
        {
          target: { name: newName, value: newValue, type: 'number' },
        },
        source,
      )
    setMenu('')
    setMenuTarget('')
    setAnchorElEstimates(null)
  }

  const onChangeRange = (
    nameLow,
    nameHigh,
    min,
    max,
    source = null,
  ) => {
    let newAnalysisInput = cloneDeep(analysisInput)
    set(newAnalysisInput, nameLow, min)
    set(newAnalysisInput, nameHigh, max)
    setAnalysisInput(newAnalysisInput)
    //  let newSource = null
    //  if (source && !isEqual(source, get(property, `source.${nameLow}`))) {
    //    newSource = cloneDeep(property.source)
    //    if (!isArray(newSource.rent))
    //      newSource.rent = [newSource.rent]
    //    set(newSource, nameLow, source)
    //    setSource(newSource)
    //  }
    //  if (source && !isEqual(source, get(property, `source.${nameHigh}`))) {
    //    newSource = cloneDeep(property.source)
    //    if (!isArray(newSource.rent))
    //      newSource.rent = [newSource.rent]
    //    set(newSource, analysisInput, source)
    //    setSource(newSource)
    //  }
  }

  const toggleCollapse = (name) => {
    let newEstimatesCollapse = { ...estimatesCollapse }
    newEstimatesCollapse[name] = !estimatesCollapse[name]
    setEstimatesCollapse(newEstimatesCollapse)
  }

  const renderInputFields = renderInputFieldsFunction(
    {
      onChangeAnalysis,
      onChangeAssumptions,
    },
    {
      showFormatDropdown,
      anchorElAssumptions,
      toggleAssumptionsMenuFormat,
      handleAssumptionsMenuClose,
      handleAdornmentChange,
      handleAnalysisAdornmentChange,
      handleDefinition,
    },
    props?.session?.me?.activeOrg?.member,
  )()

  const criticalFields = {
    'Cash Critical': [
      // {
      //   type: 'divider',
      // },
      {
        type: 'dollar',
        label: 'List Price',
        name: 'listPrice',
        value: analysisInput.listPrice,
        disabled: true,
        tooltip: 'How much the house is being offered for',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'TC Fee',
        name: 'cash.tcExpenses',
        value: pitiData?.cash?.tcExpenses,
        onChange: onChangePiti,
        tooltip:
          'Every deal should have a Transactional Coordinator (TC) - enter their fee here',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Assignment Fees',
        name: 'cash.assignmentToSelf',
        value: pitiData?.cash?.assignmentToSelf,
        onChange: onChangePiti,
        tooltip:
          "Total Assignment Fees. (Don't forget to pay yourself!) ",
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Disposition Fee',
        name: 'cash.dispoExpenses',
        value: pitiData?.cash?.dispoExpenses,
        onChange: onChangePiti,
        tooltip:
          'Disposition (Dispo) Fees: Some deals cost money to sell. Put the total here',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'JV/Connector Fee',
        name: 'cash.jvConnectorFee',
        value: pitiData?.cash?.jvConnectorFee,
        onChange: onChangePiti,
        tooltip: 'Dont forget to pay the people who helped you',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Closing Expenses',
        name: 'cash.closingCost',
        value: pitiData?.cash?.closingCost,
        onChange: onChangePiti,
        tooltip:
          'Be sure to consider closing expenses to purchase the deal & also sell the deal',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Agent Expense',
        name: 'cash.agentExpense',
        value: pitiData?.cash?.agentExpense,
        onChange: onChangePiti,
        tooltip:
          'Dont forget agents often get paid on both sides of a transaction. Be sure to include their fees or percentage',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Renovation Expenses',
        name: 'cash.renovationExpenses',
        value: pitiData?.cash?.renovationExpenses,
        onChange: onChangePiti,
        tooltip:
          'Enter an estimated cost to renovate the property. Be sure to include  any emergency funds for overages and issues.',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Optional Expenses',
        name: 'cash.optionalExpense',
        value: pitiData?.cash?.optionalExpense,
        onChange: onChangePiti,
        tooltip:
          'Dont forget to pay the people who helped you - Bird dogs, PML/HML (cost of money)',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Purchase Price',
        name: 'offerPrice',
        value: analysisInput.offerPrice,
        definition: 'offerPrice',
        definitionTarget: 'offerPrice',
        tooltip: 'How much you purchase the house for',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'ARV',
        name: 'afterRepairValue',
        value: analysisInput.afterRepairValue,
        definition: 'afterRepairValue',
        definitionTarget: 'afterRepairValue',
        tooltip: 'How much the house if worth after repairs',
        xs: 12,
        lg: 12,
      },
    ],
  }

  const preferences = merge(
    cloneDeep(DEFAULT_PREFERENCES),
    props?.session?.me?.activeOrg?.memberPreferences,
    props?.session?.me?.activeOrg?.preferences,
    {
      general: {
        strategies:
          property && property.portfolio
            ? property.portfolio.strategies
            : [],
      },
    },
  )

  // const uploadFields = [
  //   {
  //     name: 'mortgageStatement',
  //     label: 'Mortgage statement',
  //     type: 'file',
  //     value: pitiData.mortgageStatement,
  //     uploadFile: uploadFile('mortgageStatement'),
  //     buttonText: "Upload Mortgage statement",
  //     xs: 12,
  //     lg: 6,
  //   },
  //   {
  //     name: 'insuranceStatement',
  //     label: 'Insurance statement',
  //     type: 'file',
  //     value: pitiData.insuranceStatement,
  //     uploadFile: uploadFile('insuranceStatement'),
  //     buttonText: "Upload Insurance statement",
  //     xs: 12,
  //     lg: 6,
  //   },
  // ]

  const submitProperty = (property, propertyInput) => {
    // setLoader(true)
    updateProperty({
      variables: {
        id: propertyId,
        propertyInput,
      },
      refetchQueries: [
        {
          query: GET_ME,
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [property?.portfolio._id],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTY,
          variables: {
            id: propertyId,
          },
        },
        {
          query: GET_ASSUMPTIONS,
          variables: {
            documentId: propertyId,
            documentType: 'property',
          },
        },
      ],
    }).then(() => {
      // setLoader(false)
      // // history.push('/property-details/' + propertyId + '/Summary')
      // onClose()
    })
  }

  const debouncedSubmitProperty = useRef(
    debounce(
      (property, propertyInput) =>
        submitProperty(property, propertyInput),
      1000,
    ),
  ).current

  const savePitiToProperty = () => {
    setLoader(true)
    const newAnalysisInput = {
      rehabCost: pitiData?.cash?.renovationExpenses,
      rehabCostBRRRR: pitiData?.cash?.renovationExpenses,
      rehabCostBOR: pitiData?.cash?.renovationExpenses,
      rehabCostFixFlip: pitiData?.cash?.renovationExpenses,
      rehabCostTurnkey: pitiData?.cash?.renovationExpenses,
      rehabCostSTR: pitiData?.cash?.renovationExpenses,
      rehabCostWholesale: pitiData?.cash?.renovationExpenses,
      rehabCostAstroFlip: pitiData?.cash?.renovationExpenses,
    }
    const newAssumptionsInput = {
      closingCost: pitiData?.cash?.closingCost,
      closingCostType: '$',
      closingCostB2R: pitiData?.cash?.closingCost,
      closingCostB2RType: '$',
      closingCostFixFlip: pitiData?.cash?.closingCost,
      closingCostFixFlipType: '$',
      closingCostTurnkey: pitiData?.cash?.closingCost,
      closingCostTurnkeyType: '$',
      closingCostBRRRR: pitiData?.cash?.closingCost,
      closingCostBRRRRType: '$',
      closingCostSTR: pitiData?.cash?.closingCost,
      closingCostSTRType: '$',
      closingCostBOR: pitiData?.cash?.closingCost,
      closingCostBORType: '$',
      dispositionFee: pitiData?.cash?.dispoExpenses,
      dispositionFeeType: '$',
      dispositionFeeB2R: pitiData?.cash?.dispoExpenses,
      dispositionFeeB2RType: '$',
      dispositionFeeBRRRR: pitiData?.cash?.dispoExpenses,
      dispositionFeeBRRRRType: '$',
      dispositionFeeFixFlip: pitiData?.cash?.dispoExpenses,
      dispositionFeeFixFlipType: '$',
      dispositionFeeTurnkey: pitiData?.cash?.dispoExpenses,
      dispositionFeeTurnkeyType: '$',
      dispositionFeeSTR: pitiData?.cash?.dispoExpenses,
      dispositionFeeSTRType: '$',
    }
    updateProperty({
      variables: {
        id: propertyId,
        propertyInput: {
          analysisInput: newAnalysisInput,
          assumptionsInput: newAssumptionsInput,
          pitiData,
        },
      },
      refetchQueries: [
        {
          query: GET_ME,
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [property?.portfolio._id],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTY,
          variables: {
            id: propertyId,
          },
        },
        {
          query: GET_ASSUMPTIONS,
          variables: {
            documentId: propertyId,
            documentType: 'property',
          },
        },
      ],
    }).then(() => {
      setLoader(false)
      // history.push('/property-details/' + propertyId + '/Summary')
      onClose()
    })
  }

  useEffect(() => {
    const propertyInput = {
      strategies,
      analysisInput: analysisInput,
      assumptionsInput: assumptionsInput,
      pitiData,
    }

    let propertyTaxes = includes(
      property?.realeflowData?.TaxAmount ||
        property?.realeflowData?.SubjectProperty?.TaxAmount,
      '$',
    )
      ? property?.realeflowData?.TaxAmount ||
        property?.realeflowData?.SubjectProperty?.TaxAmount
      : Number(
          property?.realeflowData?.TaxAmount ||
            property?.realeflowData?.SubjectProperty?.TaxAmount,
        ) / 100
    let financeAmount = Number(
      get(property, 'realeflowData.LoanAmount') ||
        get(property, 'realeflowData.SubjectProperty.TotalLoans') ||
        0,
    )
    let monthlyRate =
      Number(
        get(
          property,
          'realeflowData.SubjectProperty.Mortgage1InterestRate',
        ),
      ) ||
      Number(get(property, 'realeflowData.MortgageInterestRate')) ||
      0
    let propertyInsurance = calculateDefaultInsurance(
      Number(get(property, 'realeflowData.AVM')) ||
        Number(get(property, 'realeflowData.SubjectProperty.AVM')) ||
        0,
    )
    // Getting and formatting the start date

    const startDateOriginal = get(
      property,
      'realeflowData.RecordingDate',
    )
      ? new Date(get(property, 'realeflowData.RecordingDate'))
      : get(
            property,
            'realeflowData.SubjectProperty.Assessor.curr_sale_recording_date',
          )
        ? parseDateString(
            get(
              property,
              'realeflowData.SubjectProperty.Assessor.curr_sale_recording_date',
            ),
          )
        : undefined

    let startDate
    if (startDateOriginal) {
      startDate = formatDate(startDateOriginal)
    }

    const piti = calculatePITI({
      financeAmount,
      monthlyRate,
      propertyTaxes,
      propertyInsurance,
      startDate,
    })

    const basePitiData = getPitiDataObject({ property, piti })
    if (
      isEmpty(property) ||
      isEmpty(pitiData) ||
      propertyLoading ||
      (isEqual(pitiData, basePitiData) &&
        analysis?.afterRepairValue ===
          analysisInput?.afterRepairValue &&
        analysis?.offerPrice === analysisInput?.offerPrice) ||
      !props.open
    )
      return
    debouncedSubmitProperty(property, propertyInput)
    return () => debouncedSubmitProperty.cancel()
  }, [strategies, analysisInput, assumptionsInput, pitiData])

  const calculateTotalExpense = (data) => {
    const {
      tcExpenses,
      assignmentToSelf,
      dispoExpenses,
      jvConnectorFee,
      closingCost,
      agentExpense,
      renovationExpenses,
      optionalExpense,
    } = data?.cash || {}

    return (
      (Number(tcExpenses) || 0) +
      (Number(assignmentToSelf) || 0) +
      (Number(dispoExpenses) || 0) +
      (Number(jvConnectorFee) || 0) +
      (Number(closingCost) || 0) +
      (Number(agentExpense) || 0) +
      (Number(renovationExpenses) || 0) +
      (Number(optionalExpense) || 0)
    )
  }

  function calculateDefaultInsurance(
    afterRepairValue,
    nationalAverage = 1550,
  ) {
    let defaultInsurance

    if (afterRepairValue <= 300000) {
      // For properties worth $300,000 or less, use the national average
      defaultInsurance = nationalAverage
    } else {
      // For properties worth more than $300,000, scale the insurance proportionally
      const scalingFactor = afterRepairValue / 300000
      defaultInsurance = nationalAverage * scalingFactor
    }

    return defaultInsurance
  }

  const [estimatesMenu] = useEstimatesMenu({
    property,
    activeUnit,
    anchorElEstimates,
    setAnchorElEstimates,
    assumptionsInput,
    onChangeAssumptions,
    handleDefinition,
    menu,
    setMenu,
    menuTarget,
    setMenuTarget,
    changeMenu,
    onChangeRange,
    estimatesCollapse,
    toggleCollapse,
    openNoData,
    setOpenNoData,
    affiliate,
    urlAffiliate,
  })

  return (
    <Dialog
      scroll="body"
      maxWidth="lg"
      open={open}
      onClose={onClose}
      // classes={{ paper: 'modal-content rounded border-0' }}
      fullWidth={true}
      onKeyDown={stopPropagationForTab}
    >
      <BlockUi
        blocking={loader || propertyLoading}
        loader={
          <PacmanLoader color={'var(--primary)'} loading={true} />
        }
        message={
          <div className="text-primary">
            {propertyLoading ? 'Loading' : 'Analyzing'} Property
          </div>
        }
      >
        <DialogTitle id="form-dialog-title">
          Cash Underwriting - {address}, {city}, {state} {zipcode}
        </DialogTitle>
        <DialogContent className="p-4">
          <div className="bg-secondary mb-3">
            <Typography>
              Fill out as much information as you can. The more
              information you provide, the easier it will be to decide
              on an exit strategy
            </Typography>
          </div>
          {/* <div className="bg-secondary mb-3">
            <Stepper
              style={{ padding: 24 }}
              className="stepper-horizontal-1"
              alternativeLabel
              activeStep={activeStep}
              connector={<StepConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={StepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div> */}

          <div>
            <div style={{ paddingTop: '20px' }}>
              <Grid
                container
                spacing={4}
                style={{ display: 'flex', alignItems: 'stretch' }}
              >
                <Grid item xs={6}>
                  <Paper
                    elevation={3}
                    style={{
                      padding: '20px',
                      marginBottom: '20px',
                      flex: 1,
                    }}
                  >
                    {map(
                      filter(
                        criticalFields['Cash Critical'],
                        (field) => field.hide !== true,
                      ),
                      (section) => (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          {renderInputFields(section)}
                          <Tooltip title={section.tooltip}>
                            <IconButton>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ),
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="column" spacing={4}>
                    <Grid item>
                      <Paper
                        elevation={3}
                        style={{
                          padding: '20px',
                          marginBottom: '20px',
                          textAlign: 'center',
                          flex: 1,
                        }}
                      >
                        <Typography variant="h4" component="div">
                          Entry Fee Total:
                        </Typography>
                        <Typography
                          variant="h3"
                          component="div"
                          style={{ fontWeight: 'bold' }}
                        >
                          {currencyFormat.format(
                            calculateTotalExpense(pitiData),
                          )}
                        </Typography>
                        <Typography variant="h4" component="div">
                          Max Allowable Offer:
                        </Typography>
                        <Typography
                          variant="h3"
                          component="div"
                          style={{ fontWeight: 'bold' }}
                        >
                          {currencyFormat.format(
                            analysisInput?.afterRepairValue -
                              calculateTotalExpense(pitiData) || 0,
                          )}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <ContactInformation property={property} />
                    </Grid>
                    <Grid item>
                      <CommentsDialog
                        property={property}
                        userId={props?.session?.me?._id}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          {/* {activeStep === 1 && (
            <Card>
              <CardContent>
                <Typography variant='h4'>Value: {currencyFormat.format(analysisInput?.offerPrice)}</Typography>
                <Typography variant='h6'>PITI: {currencyFormat.format(sum(analysisInput?.rent) - ((pitiData?.principal || 0) + (pitiData?.interest || 0) + (pitiData?.taxes || 0) + (pitiData?.insurance || 0)))}</Typography>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <Typography>
                        What strategies are you interested in?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        id="checkboxes-tags-demo"
                        multiple
                        disableCloseOnSelect
                        options={concat(filterPreferedStrategies(preferences, props?.session?.me?.activeOrg?.member, false), ['Novation'])}
                        value={strategies}
                        onChange={(event, newValue) => {
                          if (!isEmpty(newValue)) setStrategies(newValue)
                        }}
                        disableClearable
                        getOptionLabel={option => getStrategyName(option, props?.session?.me?.activeOrg?.member)}
                        // isOptionEqualToValue={(option, value) =>
                        //   option === value.value && value.active
                        // }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Strategies"
                          />
                        )}
                      />
                    </Grid>
                    {!isEmpty(keepItStrategies) && (
                      <Grid xs={12} style={{ marginTop: 10 }}>
                        <Card>
                          <CardHeader title="Keep It" />
                          <CardContent>
                            <Grid container direction="row" justifyContent="space-around" spacing={1}>
                              {map(keepItStrategies, (section, index) => (
                                <Grid item xs={12} lg={4}>
                                  <Card style={{ borderRadius: 0 }}>
                                    <CardHeader
                                      title={
                                        <Typography
                                          variant="h6"
                                          style={{ fontWeight: 600 }}
                                        >
                                          {getStrategyName(section.label, props?.session?.me?.activeOrg?.member)}
                                        </Typography>
                                      }
                                      subheader={[
                                        `According to your inputs, a ${getStrategyName(section.label, props?.session?.me?.activeOrg?.member)} strategy meets `,
                                        <b
                                          style={{
                                            color:
                                              section.percentage >= 0.66
                                                ? '#1bc943'
                                                : section.percentage >= 0.33
                                                  ? '#f4772e'
                                                  : '#f83245',
                                          }}
                                        >
                                          {gaugeFormat.format(section.percentage)}
                                        </b>,
                                        ` of your targets`,
                                      ]}
                                    // action={
                                    //   <IconButton>
                                    //     <FontAwesomeIcon
                                    //       icon={['far', 'chess-queen-piece']}
                                    //     //className="font-size-md"
                                    //     />
                                    //   </IconButton>
                                    // }
                                    />
                                    <CardContent>
                                      <Grid container direction="column">
                                        <Grid item xs={12}>
                                          <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
                                            <div className="mx-auto">
                                              <GaugeChart
                                                hideText
                                                id={'chartsGauges' + index}
                                                nrOfLevels={6}
                                                colors={[
                                                  '#f83245',
                                                  '#f4772e',
                                                  '#1bc943',
                                                ]}
                                                arcWidth={0.3}
                                                percent={section.percentage}
                                              />
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item className="gridItem2" xs={12} alignContent="center" >
                                          <Card
                                            style={{ backgroundColor: '#eff2f5' }}
                                          >
                                            <CardContent style={{ padding: 10 }}>
                                              <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-around"
                                                alignItems="center"
                                                spacing={0}
                                                wrap="nowrap"
                                                style={{ padding: 0 }}
                                              >
                                                {map(section.analytics, (field) => (
                                                  <Grid item className="gridItem2">
                                                    <Grid
                                                      container
                                                      direction="column"
                                                      alignItems="center"
                                                    >
                                                      <Grid item xs={12}>
                                                        <Typography
                                                          style={{
                                                            whiteSpace: 'nowrap',
                                                            fontSize: 13,
                                                          }}
                                                        >
                                                          {field.label}
                                                        </Typography>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                        <Grid
                                                          container
                                                          direction="row"
                                                          justifyContent="center"
                                                          alignItems="center"
                                                          wrap="nowrap"
                                                        >
                                                          {!isUndefined(
                                                            field.arrow,
                                                          ) && (
                                                              <Grid
                                                                item
                                                                className="gridItem2"
                                                              >
                                                                {field.arrow ? (
                                                                  field.arrow === 2 ? (
                                                                    <Avatar
                                                                      style={{
                                                                        backgroundColor:
                                                                          '#2296F3',
                                                                        height: 15,
                                                                        width: 15,
                                                                      }}
                                                                    >
                                                                      <ArrowUpwardIcon
                                                                        style={{
                                                                          color:
                                                                            'white',
                                                                          fontSize:
                                                                            'small',
                                                                        }}
                                                                      />
                                                                    </Avatar>
                                                                  ) : (
                                                                    <Avatar
                                                                      style={{
                                                                        backgroundColor:
                                                                          '#46de64',
                                                                        height: 15,
                                                                        width: 15,
                                                                      }}
                                                                    >
                                                                      <ArrowUpwardIcon
                                                                        style={{
                                                                          color:
                                                                            'white',
                                                                          fontSize:
                                                                            'small',
                                                                        }}
                                                                      />
                                                                    </Avatar>
                                                                  )
                                                                ) : (
                                                                  <Avatar
                                                                    style={{
                                                                      backgroundColor:
                                                                        '#F05248',
                                                                      height: 15,
                                                                      width: 15,
                                                                    }}
                                                                  >
                                                                    <ArrowDownwardIcon
                                                                      style={{
                                                                        color: 'white',
                                                                        fontSize:
                                                                          'small',
                                                                      }}
                                                                    />
                                                                  </Avatar>
                                                                )}
                                                              </Grid>
                                                            )}
                                                          <Grid
                                                            item
                                                            style={{ marginLeft: 5 }}
                                                          >
                                                            <Typography
                                                              style={{
                                                                fontSize: 12,
                                                                fontWeight: 700,
                                                              }}
                                                            >
                                                              {field.value !== '-'
                                                                ? field.type ===
                                                                  'currency'
                                                                  ? currencyFormat.format(
                                                                    field.value,
                                                                  )
                                                                  : field.type ===
                                                                    'percentage' &&
                                                                    field.value > 1000
                                                                    ? '+1000%'
                                                                    : percentageFormat.format(
                                                                      field.value,
                                                                    )
                                                                : field.value}
                                                            </Typography>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                ))}
                                              </Grid>
                                            </CardContent>
                                            <CardContent>
                                              <Typography variant='h6'>Deal Info</Typography>
                                              <Typography>Current Value: {currencyFormat.format(analysisInput?.offerPrice)}</Typography>
                                              <Typography>Entry Fee: {currencyFormat.format(section.entryFee)}</Typography>
                                              <Typography>Existing Mortgage: {currencyFormat.format(pitiData?.existingMortgage)}</Typography>
                                            </CardContent>
                                            <CardContent>
                                              <Typography variant='h6'>Monthly</Typography>
                                              <Typography>Rent: {currencyFormat.format(section.rent)}</Typography>
                                              <Typography>PITI: {currencyFormat.format(section.piti)}</Typography>
                                              <Typography>Warchest: {currencyFormat.format(section.warchest)}</Typography>
                                              <Typography>Net Income: {currencyFormat.format(section.rent - section.piti - section.warchest)}</Typography>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              ))}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                    {!isEmpty(sellItStrategies) && (
                      <Grid xs={12} style={{ marginTop: 10 }}>
                        <Card>
                          <CardHeader title="Sell It" />
                          <CardContent>
                            <Grid container direction="row" justifyContent="space-around" spacing={1}>
                              {map(sellItStrategies, (section, index) => (
                                <Grid item xs={12} lg={6}>
                                  <Card style={{ borderRadius: 0 }}>
                                    <CardHeader
                                      title={
                                        <Typography
                                          variant="h6"
                                          style={{ fontWeight: 600 }}
                                        >
                                          {getStrategyName(section.label, props?.session?.me?.activeOrg?.member)}
                                        </Typography>
                                      }
                                      subheader={[
                                        `According to your inputs, a ${getStrategyName(section.label, props?.session?.me?.activeOrg?.member)} strategy meets `,
                                        <b
                                          style={{
                                            color:
                                              section.percentage >= 0.66
                                                ? '#1bc943'
                                                : section.percentage >= 0.33
                                                  ? '#f4772e'
                                                  : '#f83245',
                                          }}
                                        >
                                          {gaugeFormat.format(section.percentage)}
                                        </b>,
                                        ` of your targets`,
                                      ]}
                                    // action={
                                    //   <IconButton>
                                    //     <FontAwesomeIcon
                                    //       icon={['far', 'chess-queen-piece']}
                                    //     //className="font-size-md"
                                    //     />
                                    //   </IconButton>
                                    // }
                                    />
                                    <CardContent>
                                      <Grid container direction="column">
                                        <Grid item xs={12}>
                                          <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
                                            <div className="mx-auto">
                                              <GaugeChart
                                                hideText
                                                id={'chartsGauges' + index}
                                                nrOfLevels={6}
                                                colors={[
                                                  '#f83245',
                                                  '#f4772e',
                                                  '#1bc943',
                                                ]}
                                                arcWidth={0.3}
                                                percent={section.percentage}
                                              />
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item className="gridItem2" xs={12}>
                                          <Card
                                            style={{ backgroundColor: '#eff2f5' }}
                                          >
                                            <CardContent style={{ padding: 10 }}>
                                              <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-around"
                                                alignItems="center"
                                                spacing={0}
                                                wrap="nowrap"
                                                style={{ padding: 0 }}
                                              >
                                                {map(section.analytics, (field) => (
                                                  <Grid item className="gridItem2">
                                                    <Grid
                                                      container
                                                      direction="column"
                                                      alignItems="center"
                                                    >
                                                      <Grid item xs={12}>
                                                        <Typography
                                                          style={{
                                                            whiteSpace: 'nowrap',
                                                            fontSize: 13,
                                                          }}
                                                        >
                                                          {field.label}
                                                        </Typography>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                        <Grid
                                                          container
                                                          direction="row"
                                                          justifyContent="center"
                                                          alignItems="center"
                                                          wrap="nowrap"
                                                        >
                                                          {!isUndefined(
                                                            field.arrow,
                                                          ) && (
                                                              <Grid
                                                                item
                                                                className="gridItem2"
                                                              >
                                                                {field.arrow ? (
                                                                  field.arrow === 2 ? (
                                                                    <Avatar
                                                                      style={{
                                                                        backgroundColor:
                                                                          '#2296F3',
                                                                        height: 15,
                                                                        width: 15,
                                                                      }}
                                                                    >
                                                                      <ArrowUpwardIcon
                                                                        style={{
                                                                          color:
                                                                            'white',
                                                                          fontSize:
                                                                            'small',
                                                                        }}
                                                                      />
                                                                    </Avatar>
                                                                  ) : (
                                                                    <Avatar
                                                                      style={{
                                                                        backgroundColor:
                                                                          '#46de64',
                                                                        height: 15,
                                                                        width: 15,
                                                                      }}
                                                                    >
                                                                      <ArrowUpwardIcon
                                                                        style={{
                                                                          color:
                                                                            'white',
                                                                          fontSize:
                                                                            'small',
                                                                        }}
                                                                      />
                                                                    </Avatar>
                                                                  )
                                                                ) : (
                                                                  <Avatar
                                                                    style={{
                                                                      backgroundColor:
                                                                        '#F05248',
                                                                      height: 15,
                                                                      width: 15,
                                                                    }}
                                                                  >
                                                                    <ArrowDownwardIcon
                                                                      style={{
                                                                        color: 'white',
                                                                        fontSize:
                                                                          'small',
                                                                      }}
                                                                    />
                                                                  </Avatar>
                                                                )}
                                                              </Grid>
                                                            )}
                                                          <Grid
                                                            item
                                                            style={{ marginLeft: 5 }}
                                                          >
                                                            <Typography
                                                              style={{
                                                                fontSize: 12,
                                                                fontWeight: 700,
                                                              }}
                                                            >
                                                              {field.value !== '-'
                                                                ? field.type ===
                                                                  'currency'
                                                                  ? currencyFormat.format(
                                                                    field.value,
                                                                  )
                                                                  : field.type ===
                                                                    'percentage' &&
                                                                    field.value > 1000
                                                                    ? '+1000%'
                                                                    : percentageFormat.format(
                                                                      field.value,
                                                                    )
                                                                : field.value}
                                                            </Typography>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                ))}
                                              </Grid>
                                            </CardContent>
                                            <CardContent>
                                              <Typography variant='h6'>Deal Info</Typography>
                                              <Typography>Current Value: {currencyFormat.format(analysisInput?.offerPrice)}</Typography>
                                              <Typography>Entry Fee: {currencyFormat.format(section.entryFee)}</Typography>
                                              <Typography>Existing Mortgage: {currencyFormat.format(pitiData?.existingMortgage)}</Typography>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              ))}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
              </CardContent>
              <CardContent>
                
              </CardContent>
            </Card>
          )} */}
        </DialogContent>
        <DialogActions>
          <Typography style={{ flex: 1 }} />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginRight: 10 }}
            onClick={() => {
              onClose(true)
            }}
          >
            Close
          </Button>
          {activeStep > 0 && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginRight: 10 }}
              onClick={() => {
                setActiveStep(activeStep - 1)
              }}
            >
              Back
            </Button>
          )}
          <Button
            // disabled={isDisabled}
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginRight: 10 }}
            onClick={() => {
              // if (activeStep < steps.length - 1)
              //   setActiveStep(activeStep + 1)
              // else
              savePitiToProperty()
            }}
          >
            {/* {activeStep < steps.length - 1 ? 'Next Step' : 'Finish'} */}
            Finish
          </Button>
        </DialogActions>
      </BlockUi>

      {estimatesMenu}
    </Dialog>
  )
}

export default withSession(SubtoPITICashDialog)
