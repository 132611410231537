/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-24 19:59:53
 * @ Modified by: David Helmick
 * @ Modified time: 2024-05-27 09:13:40
 * @ Description: Assumptions placed within a dialog
 */

import React, { useEffect, useState } from 'react'

import AssumptionsAccordion from '../Accordion'
import AssumptionsFilter from '../AssumptionsFilter'
import Notification from '../Notification'

import unset from 'lodash/unset'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  Tooltip,
} from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TuneIcon from '@mui/icons-material/Tune'
import { useQuery } from '@apollo/client'
import { GET_ASSUMPTIONS } from '../quries'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'

export default function EditAssumptionsComponent(props) {
  const {
    targetOnly,
    sidebarMenu,
    bulkEdit,
    selected,
    documentId,
    documentType,
    documentName,
    defaultStrategy = 'Buy & Hold',
  } = props
  const [openEditAssumptions, setEditAssumptions] = useState(false)
  const handleOpenEditAssumptions = () => {
    setEditAssumptions(true)
  }
  const handleCloseEditAssumptions = () => {
    setEditAssumptions(false)
    setLoader(false)
  }

  const { loading, error, data } = useQuery(GET_ASSUMPTIONS, {
    variables: { documentId, documentType },
  })

  const [filter, setFilter] = useState(defaultStrategy)
  const [assumptions, setAssumptions] = useState({})

  const [baseAssumptions, setBaseAssumptions] = useState({})
  const [loader, setLoader] = useState(false)

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  useEffect(() => {
    if (!loading && data?.assumptions) {
      let {
        _id: RemovedId,
        __typename: RemovedTypename,
        ...newAssumptions
      } = data.assumptions
      setAssumptions(newAssumptions)
      setBaseAssumptions(data.assumptions)
    }
  }, [loading, data])

  if (loading) return null
  if (error) return `Error! ${error.message}`

  return <>
    {sidebarMenu && (
      <ListItem button onClick={handleOpenEditAssumptions}>
        <TuneIcon style={{ marginRight: 5, color: '#757575' }} />
        <span>Edit Assumptions</span>
      </ListItem>
    )}
    {targetOnly && (
      <Tooltip title="Edit Targets">
        <Button
          onClick={handleOpenEditAssumptions}
          className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
        >
          <FontAwesomeIcon icon={['fal', 'pen']} />
        </Button>
      </Tooltip>
    )}
    {bulkEdit && (
      <Tooltip title="Bulk Edit Assumptions">
        <IconButton onClick={handleOpenEditAssumptions} size="large">
          <FontAwesomeIcon
            icon={['far', 'sliders']}
            style={{ color: 'white' }}
          />
        </IconButton>
      </Tooltip>
    )}

    <Dialog
      open={openEditAssumptions}
      onClose={handleCloseEditAssumptions}
      classes={{ paper: 'shadow-lg rounded' }}
      maxWidth="md"
      fullWidth={true}
      onKeyDown={stopPropagationForTab}
    >
      <DialogTitle>
        {targetOnly
          ? 'Edit Target'
          : documentName
            ? `${documentName}'s Assumptions`
            : 'Edit Assumptions'}
      </DialogTitle>
      <DialogContent>
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              Saving Portfolio Assumptions
            </div>
          }
        >
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <AssumptionsFilter
                targetOnly={targetOnly}
                filter={filter}
                setFilter={setFilter}
                member={props.member}
              />
            </Grid>
            <Grid item>
              <AssumptionsAccordion
                assumptions={assumptions}
                setAssumptions={setAssumptions}
                filter={filter}
                targetOnly={targetOnly}
              />
            </Grid>
          </Grid>
        </BlockUi>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCloseEditAssumptions}>
          Cancel
        </Button>
        <Notification
          assumptions={assumptions}
          documentId={documentId}
          selected={selected}
          setIsChanged={handleCloseEditAssumptions}
          documentType={documentType}
          button={true}
          setLoader={setLoader}
          baseAssumptions={baseAssumptions}
        />
      </DialogActions>
    </Dialog >
  </>;
}
