/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-01 15:08:58
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-27 11:20:01
 * @ Description: Property details view showing the comps found for this property
 */

import React, { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cropper from 'react-easy-crop'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import meanBy from 'lodash/meanBy'
import filter from 'lodash/filter'
import remove from 'lodash/remove'
import toUpper from 'lodash/toUpper'
import toLower from 'lodash/toLower'
import truncate from 'lodash/truncate'
import padStart from 'lodash/padStart'
import head from 'lodash/head'
import toString from 'lodash/toString'
import replace from 'lodash/replace'
import intersection from 'lodash/intersection'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import pull from 'lodash/pull'
import ceil from 'lodash/ceil'
import slice from 'lodash/slice'
import intersectionBy from 'lodash/intersectionBy'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import startCase from 'lodash/startCase'
import reduce from 'lodash/reduce'
import forEach from 'lodash/forEach'
import uniqBy from 'lodash/uniqBy'
import uniqWith from 'lodash/uniqWith'
import orderBy from 'lodash/orderBy'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import isString from 'lodash/isString'
import every from 'lodash/every'
import split from 'lodash/split'
import sum from 'lodash/sum'
import find from 'lodash/find'

import Slider1 from 'react-slick'

import CompsCard from '../CompsCard'
import CountUp from 'react-countup'
import FindComps from '../../../Comps/FindComps'

import Script from 'react-load-script'
import loadImage from 'blueimp-load-image'

import { useDropzone } from 'react-dropzone'

import {
  Avatar,
  Backdrop,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  IconButton,
  ImageListItemBar,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import CheckIcon from '@mui/icons-material/Check'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import CompareIcon from '@mui/icons-material/Compare'
import EditIcon from '@mui/icons-material/Edit'
import HomeIcon from '@mui/icons-material/Home'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import ListIcon from '@mui/icons-material/List'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  GET_COMPPROPERTIES,
  GET_PROPERTY,
} from '@/housefolios-components/PropertyContainer/queries'
import { ApolloConsumer, useMutation } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  ADD_COMPPROPERTY,
  IMAGE_UPLOAD,
} from '@/housefolios-components/TitleButtons/AddPropertyButton/mutations'
import { NumericFormat } from 'react-number-format'
import { enqueueSnackbar } from 'notistack'
import {
  DELETE_COMP,
  DELETE_IMAGE_MULTI,
  UPDATE_COMPPROPERTY,
  UPDATE_COMPPROPERTIES,
  SAVE_ANALYSIS,
  DELETE_IMAGE,
  GET_RENTAL_COMPS,
} from '@/housefolios-components/PropertyContainer/mutations'

import Map from '../../../Properties/Map'
import { PacmanLoader } from 'react-spinners'
import BlockUi from 'react-block-ui'
import {
  CompareOutlined,
  DeleteTwoTone,
  GridOnTwoTone,
  KeyboardArrowDownTwoTone,
  PollTwoTone,
  RoomTwoTone,
} from '@mui/icons-material'
import clsx from 'clsx'
import Alert from '@mui/material/Alert'
import DeleteComps from '@/housefolios-components/Comps/DeleteComps'
import MyPropertiesFilter from '@/layout-components/HeaderDrawer'
import CompsList from '../CompsList'
import Pagination from '@mui/material/Pagination'
import { GET_ZIP_VALUE } from '@/housefolios-components/TitleButtons/AddPropertyButton/queries'
import { GET_STOCKPILE } from '@/housefolios-components/Marketplace/queries'
import { STATES } from '@/constants/states'
import Autocomplete from '@mui/material/Autocomplete'
import { useStopwatch } from 'react-timer-hook'
import AllPropertiesFiltered from '@/housefolios-components/Properties/AllPropertiesFiltered'
import RentalCompsCard from '@/housefolios-components/PropertyContainer/CenterPage/RentalCompsCard'
import ImageCropper from '@/housefolios-components/Dialogs/ImageCropper'
import { getZipValues } from '../../../../utils/addProperty'
import { handlePlaceResult } from '@/utils/handlePlaceResult'
import { statesShort } from '@/utils/states'

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
}

const hostname = import.meta.env.VITE_HOSTNAME
const apiKey = import.meta.env.VITE_HOUSEFOLIOS_APIKEY
const apiID = import.meta.env.VITE_HOUSEFOLIOS_APIID
const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY

let sortingChoices = [
  {
    title: 'Price - low to high',
    textAlign: 'left',
    name: 'estval-asc',
  },
  {
    title: 'Price - high to low',
    textAlign: 'left',
    name: 'estval-desc',
  },
  {
    title: 'SqFt - low to high',
    name: 'sqft-asc',
  },
  {
    title: 'SqFt - high to low',
    name: 'sqft-desc',
  },
  {
    title: 'Year - low to high',
    name: 'year-asc',
  },
  {
    title: 'Year - high to low',
    name: 'year-desc',
  },
]

//Sorting function copied from properties/marketplace page. Removed marketplaceOrderBy.
function desc(a, b, orderBy) {
  if (isString(get(a, orderBy)) && isString(get(b, orderBy))) {
    if (!get(b, orderBy) && !get(a, orderBy)) {
      return 0
    }
    if (!get(b, orderBy)) {
      return 1
    }
    if (!get(a, orderBy)) {
      return -1
    }
    if (
      every(get(a, orderBy), (c) => c <= '9' && c >= '0') &&
      every(get(b, orderBy), (c) => c <= '9' && c >= '0')
    ) {
      if (Number(get(b, orderBy)) < Number(get(a, orderBy))) {
        return -1
      }
      if (Number(get(b, orderBy)) > Number(get(a, orderBy))) {
        return 1
      }
      return 0
    } else {
      if (
        get(b, orderBy).toLowerCase() < get(a, orderBy).toLowerCase()
      ) {
        return -1
      }
      if (
        get(b, orderBy).toLowerCase() > get(a, orderBy).toLowerCase()
      ) {
        return 1
      }
      return 0
    }
  } else {
    if (isNil(get(b, orderBy)) && isNil(get(a, orderBy))) {
      return 0
    }
    if (isNil(get(b, orderBy))) {
      return 1
    }
    if (isNil(get(a, orderBy))) {
      return -1
    }
    if (get(b, orderBy) < get(a, orderBy)) {
      return -1
    }
    if (get(b, orderBy) > get(a, orderBy)) {
      return 1
    }
    return 0
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

function SliderArrowNext(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  )
}

function SliderArrowPrev(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  )
}

const REHAB_LEVELS = [
  {
    value: 'Fully Rehabbed',
    label: 'Fully Rehabbed',
  },
  {
    value: 'Partially Rehabbed',
    label: 'Partially Rehabbed',
  },
  {
    value: 'No Rehab Needed',
    label: 'No Rehab Needed',
  },
  {
    value: 'Needs Partial Rehab',
    label: 'Needs Partial Rehab',
  },
  {
    value: 'Needs Major Rehab',
    label: 'Needs Major Rehab',
  },
]

const useStyles = makeStyles((theme) => ({
  compareIcon: {
    fontSize: 23,
  },
}))

function CenterPageComps(props) {
  const { property = {}, comps, organization } = props

  const classes = useStyles()

  const hiddenUp = useMediaQuery((theme) =>
    theme.breakpoints.up('md'),
  )

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const [activeTab, setActiveTab] = useState('1')

  const [compMenu, setCompMenu] = useState(null)
  const handleMenuOpen = (event) => {
    setCompMenu(event.currentTarget)
  }

  const handleMenuClose = (event) => {
    setCompMenu(null)
  }

  const [selectedEditImage, setSelectedEditImage] = useState({})
  const [replaceImage, setReplaceImage] = useState(false)

  const [editImageMoreVert, setEditImageMoreVert] = useState(null)
  const handleEditImageMoreVertOpen = (event, image) => {
    setEditImageMoreVert(event.currentTarget)
    setSelectedEditImage(image)
  }

  const handleEditImageMoreVertClose = (event) => {
    setEditImageMoreVert(null)
    setSelectedEditImage({})
  }

  const [openEditImage, setOpenEditImage] = useState(false)
  const handleOpenEditImage = (image) => {
    setOpenEditImage(true)
    setSelectedEditImage(image)
  }
  const handleCloseEditImage = () => {
    setOpenEditImage(false)
    // handleEditImageMoreVertClose()
  }

  //   const [items, setItems] = React.useState(map(images, image => (
  //     {
  //         id: image._id,
  //         src: image.url,
  //         width: 4,
  //         height: 3,
  //     }
  // )))

  const saveCroppedImage = async (croppedImage, replaceImage) => {
    setLoader(true)
    try {
      const imageId = get(selectedEditImage, `_id`)
      let newSelectedImages = selectedImages
      if (edit) {
        await imageUpload({
          variables: {
            documentId: compPropertyInput.mainProperty,
            imageUrls: [croppedImage],
            collection: 'compProperty',
          },
          refetchQueries: [
            {
              query: GET_COMPPROPERTIES,
              variables: { propertyId: property._id },
            },
            {
              query: GET_PROPERTY,
              variables: { id: property._id },
            },
          ],
        })
      } else {
        newSelectedImages = concat(newSelectedImages, [croppedImage])
        setSelectedImages(newSelectedImages)
      }
      if (replaceImage) {
        if (imageId) {
          let newImages = [...images]
          remove(newImages, (image) => image._id === imageId)
          await deleteFile({
            refetchQueries: [
              {
                query: GET_COMPPROPERTIES,
                variables: { propertyId: property._id },
              },
              {
                query: GET_PROPERTY,
                variables: { id: property._id },
              },
            ],
            variables: {
              _id: imageId,
            },
          })
          // setImages(newImages)
        } else {
          pull(newSelectedImages, selectedEditImage)
          setSelectedImages(newSelectedImages)
        }
      }
      enqueueSnackbar(`Image edited`, {
        variant: 'success',
        autoHideDuration: 3000,
      })
    } catch (e) {
      console.error(e)
    }
    handleCloseEditImage()
    handleEditImageMoreVertClose()
    setLoader(false)
  }

  //const [comps, setComps] = useState('soldComps');
  // const handleChange = event => {
  //   setComps(event.target.value);
  // };

  const [openAddCompAddress, setOpenAddCompAddress] = useState(false)
  const handleOpenAddCompAddress = () => {
    setOpenAddCompAddress(true)
    handleMenuClose()
  }
  const handleCloseAddCompAddress = () => {
    setOpenAddCompAddress(false)
    setLoader(false)
    pause()
  }

  const [loader, setLoader] = useState(false)

  const { seconds, minutes, pause, reset } = useStopwatch({
    autoStart: false,
  })

  const [openAddComp, setOpenAddComp] = useState(false)
  const handleOpenAddComp = () => {
    setOpenAddComp(true)
  }
  const handleCloseAddComp = () => {
    setOpenAddComp(false)
    setLoader(false)
    setAnchorElActionsMenu(null)
    pause()
  }

  const [placeResult, setPlaceResult] = useState(null)

  const [ordering, setOrdering] = useState('')

  const [orderByTarget = 'saleDate', order = 'desc'] = split(
    ordering || 'saleDate-desc',
    '-',
  )

  const [compPropertyInput, setCompPropertyInput] = useState({})
  const [selectedImages, setSelectedImages] = useState([])
  const [images, setImages] = useState([])
  const [edit, setEdit] = useState(false)

  const [openDeleteImage, setOpenDeleteImage] = useState(false)
  const [selectedImageDelete, setSelectedImageDelete] = useState(null)

  const toggleOpenDeleteImage =
    (_id = null) =>
    () => {
      setOpenDeleteImage(!openDeleteImage)
      setSelectedImageDelete(_id)
    }

  const [menu, setMenu] = useState('')
  const [anchorElDefinition, setAnchorElDefinition] = useState(null)
  const [menuName, setMenuName] = useState(null)
  const [menuFormat, setMenuFormat] = useState(null)
  const [propertyData, setPropertyData] = useState(null)
  const [showMenu, setShowMenu] = useState(true)
  const handleMenu = () => {
    setShowMenu(false)
  }

  const handleDefinition = (name, anchorEl, menuName, menuFormat) => {
    setMenu(name)
    setAnchorElDefinition(anchorEl)
    setMenuName(menuName)
    setMenuFormat(menuFormat)
  }

  const onSubmitDelete = async (event, deleteFiles) => {
    setLoader(true)
    const toggle = toggleOpenDeleteImage()
    let newImages = [...images]
    remove(newImages, (image) => image._id === selectedImageDelete)
    deleteFiles()
      .then(async ({ data }) => {
        setSelectedImageDelete(null)
        enqueueSnackbar(`Image Deleted`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        handleEditImageMoreVertClose()
        toggle()
        setImages(newImages)
        setLoader(false)
      })
      .catch((error) => {
        enqueueSnackbar(`Image failed to delete`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        toggle()
        setLoader(false)
      })
    event.preventDefault()
  }

  const searchForComp = async (client) => {
    setLoader(true)
    reset()
    let location = handlePlaceResult(placeResult, property)
    const { loc } = location

    const streetAddress = location.streetAddress

    let data
    let error
    // { data, error } = await client.query({
    //   query: GET_ZILLOW,
    //   variables: {
    //     addressInput: {
    //       address: streetAddress.replace('#', '%23').replace('&', '%26'),
    //       city: location.locality,
    //       state: location.administrative_area_level_1,
    //       zip: location.postal_code,
    //     },
    //   },
    //   errorPolicy: 'ignore',
    // })

    data = {
      apiKey: apiKey,
      orgID: apiID,
      action: 'single_comp',
      type: 'Single Family',
      env:
        hostname === 'localhost' ||
        hostname === 'dev-admin.housefolios.com'
          ? 'dev'
          : 'live',
      address: toUpper(
        streetAddress.replace('#', '%23').replace('&', '%26'),
      ),
      latitude: loc ? loc.lat : null,
      longitude: loc ? loc.lng : null,
      city: toLower(location.locality),
      zips: padStart(
        truncate(location.postal_code, {
          length: 5,
          omission: '',
        }),
        5,
        '0',
      ),
      state: location.administrative_area_level_1,
    }

    const str_json = JSON.stringify(data)
    let response = { ok: false }
    try {
      response = await fetch(
        hostname === 'localhost'
          ? 'http://data.housefolios.com/housefoliosdev-api.php'
          : //? 'http://housefolios-data.test/housefoliosdev-api.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://data.housefolios.com/housefoliosdev-api.php'
            : 'https://data.housefolios.com/housefolios-api.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
    } catch (error) {
      console.log(error)
    }
    let result = response.ok ? await response.json() : []
    let propertyData = reduce(
      result,
      function (result, object) {
        forEach(object, (value, key) => {
          let newValue = Number(value)
          if (isNaN(newValue)) newValue = value
          ;(result[key] || (result[key] = [])).push(newValue)
        })
        return result
      },
      {},
    )
    forEach(propertyData, (value, key) => {
      propertyData[key] = compact(
        uniqWith(
          propertyData[key],
          (obj, oth) => toLower(obj) === toLower(oth),
        ),
      )
    })

    const [zipValues] = await getZipValues(
      client,
      propertyData ? head(propertyData.zip) : location.postal_code,
    )
    // let propertyData = data
    //   ? data.getZillowPropertyData
    //   : null

    // if (propertyData) {
    //   if (propertyData.files === null)
    //     propertyData.files = { url: [] }
    // }

    let selectedImages = []
    try {
      if (propertyData) {
        if (!isEmpty(propertyData.images)) {
          let images = head(JSON.parse(propertyData.images))
          images = Object.values(images)
          selectedImages = images || []
        }
        if (propertyData.image)
          selectedImages.push(propertyData.image)
      }
      const stockpileCheck = await client.query({
        query: GET_STOCKPILE,
        variables: {
          addressInput: {
            address: propertyData
              ? head(propertyData.address)
              : streetAddress,
            city: propertyData
              ? head(propertyData.city)
              : location.locality,
            county: zipValues ? zipValues.county : '',
            state: propertyData
              ? head(propertyData.state)
              : location.administrative_area_level_1,
          },
        },
        errorPolicy: 'ignore',
      })
      const stockpileValues = stockpileCheck
        ? stockpileCheck.data
        : null
      const { stockedImages } = stockpileValues || {}
      if (stockedImages) {
        selectedImages = concat(
          selectedImages,
          compact([stockpileValues.stockedImages.first]),
          stockpileValues.stockedImages.url || [],
        )
        if (isEmpty(selectedImages))
          selectedImages = concat(
            selectedImages,
            compact([stockpileValues.stockedImages.streetView]),
            compact([stockpileValues.stockedImages.staticMap]),
          )
      }
      if (loc) {
        const MAP_KEY = googleApiKey
        const { lat, lng } = loc
        if (isEmpty(selectedImages)) {
          var service = window.google
            ? new window.google.maps.StreetViewService()
            : 0
          if (service !== 0) {
            await new Promise((resolve, reject) => {
              try {
                service.getPanoramaByLocation(
                  { lat: Number(lat), lng: Number(lng) },
                  50,
                  function (panoData) {
                    if (panoData !== null) {
                      var ManLatLng = panoData.location.latLng
                      const heading =
                        window.google.maps.geometry.spherical.computeHeading(
                          ManLatLng,
                          new window.google.maps.LatLng(
                            Number(lat),
                            Number(lng),
                          ),
                        )
                      selectedImages.push(
                        `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${lat},${lng}&heading=${heading}&key=${MAP_KEY}`,
                      )
                    }
                    resolve()
                  },
                )
              } catch (error) {
                console.log(error)
              }
            })
          }
          selectedImages.push(
            `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&size=400x400&markers=color:blue%7C${lat},${lng}&key=${MAP_KEY}`,
          )
        }
      }
    } catch (error) {
      console.log(error)
    }

    setCompPropertyInput({
      mainProperty: property._id,
      address: propertyData
        ? head(propertyData.address)
        : streetAddress,
      city: propertyData
        ? head(propertyData.city)
        : location.locality,
      state: propertyData
        ? head(propertyData.state)
        : location.administrative_area_level_1,
      zip: propertyData
        ? head(propertyData.zip)
        : location.postal_code,
      beds: propertyData
        ? maxBy(sum(propertyData.beds), (n) => Number(n)) || 0
        : '',
      baths: propertyData
        ? maxBy(sum(propertyData.baths), (n) => Number(n)) || 0
        : '',
      sqft: propertyData
        ? maxBy(sum(propertyData.sqft), (n) => Number(n)) || 0
        : '',
      year: propertyData ? head(propertyData.yearBuilt) : '',
      estval: propertyData
        ? Number(head(propertyData.soldPrice))
        : '',
      priceAdjustment: 0,
      daysOnMarket: 0,
      rehabLevel: '',
      location: {
        latitude: Number(loc.lat),
        longitude: Number(loc.lng),
      },
    })
    setPropertyData(propertyData)
    setLoader(false)
    pause()
    setSelectedImages(selectedImages)
    setImages([])
    setEdit(false)
    handleOpenAddComp()
  }

  const [potential, setPotential] = useState(false)

  const openEdit = (comp) => {
    if (props.public || props.shared) return
    setCompPropertyInput({
      mainProperty: comp._id,
      address: comp.address,
      city: comp.city,
      state: comp.state,
      zip: comp.zip,
      beds: comp.beds,
      baths: comp.baths,
      sqft: comp.sqft,
      year: comp.year,
      estval: comp.estval,
      priceAdjustment: comp.priceAdjustment,
      daysOnMarket: comp.daysOnMarket,
      rehabLevel: comp.rehabLevel,
      saleDate: comp.saleDate,
      indexTest: comp.indexTest,
      potential: comp.potential,
    })
    setPropertyData(null)
    setImages(comp.images || [])
    setSelectedImages([])
    setEdit(true)
    setPotential(comp.potential)
    handleOpenAddComp()
  }

  const reloadImages = () => {
    setSelectedImages([...selectedImages])
  }

  useEffect(() => {
    const comp = find(
      props.comps,
      (comp) => comp._id === compPropertyInput.mainProperty,
    )
    if (comp) {
      if (!isEqual(images, comp.images)) setImages(comp.images || [])
      setSelectedImages([])
    }
  }, [props.comps])

  const addImages = (files) => {
    let newSelectedImages = selectedImages
    newSelectedImages.push(files)
    setSelectedImages(newSelectedImages)
    reloadImages()
    enqueueSnackbar(`Image uploaded`, {
      variant: 'success',
      autoHideDuration: 3000,
    })
  }

  const {
    // acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: async (acceptedFiles) => {
      // setFiles(acceptedFiles.map(file => Object.assign(file, {
      //   preview: URL.createObjectURL(file)
      // })));

      if (edit) {
        await Promise.all(
          map(acceptedFiles, (file) => {
            const reader = new FileReader()
            return new Promise((resolve, reject) => {
              reader.onload = async () => {
                const image = reader.result
                await imageUpload({
                  variables: {
                    documentId: compPropertyInput.mainProperty,
                    imageUrls: [image],
                    collection: 'compProperty',
                  },
                  refetchQueries: [
                    {
                      query: GET_COMPPROPERTIES,
                      variables: { propertyId: property._id },
                    },
                    {
                      query: GET_PROPERTY,
                      variables: { id: property._id },
                    },
                  ],
                }).then(async ({ data }) => {
                  // enqueueSnackbar('Image Saved', {
                  //   variant: 'success',
                  //   autohideDuratation: 3000,
                  // })
                  // if (props.handleUpdateMarket) props.handleUpdateMarket()
                  resolve(image)
                })
              }
              reader.readAsDataURL(file)
            })
          }),
        )
        enqueueSnackbar(`Image uploaded`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
      } else {
        map(acceptedFiles, (file) => {
          loadImage(
            file,
            (img) => {
              addImages(img.toDataURL(file.type))
            },
            {
              orientation: true,
              canvas: true,
            },
          )
        })
      }
    },
  })

  // const files = acceptedFiles.map(file => (
  //   <ListItem className="font-size-sm px-3 py-2 text-primary d-flex justify-content-between align-items-center" key={file.path}>
  //     <span>{file.path}</span> <span className="badge badge-pill bg-neutral-warning text-warning">{file.size} bytes</span>
  //   </ListItem>
  // ));

  let autocomplete

  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    var options = {
      types: ['geocode'],
    } // To disable any eslint 'google not defined' error

    // Initialize Google Autocomplete
    /*global google*/ autocomplete =
      new google.maps.places.Autocomplete(
        document.getElementById('autocomplete'),
        options,
      )

    autocomplete.setFields(['address_component', 'geometry'])
    autocomplete.addListener('place_changed', () => {
      setPlaceResult(
        autocomplete.getPlace().address_components
          ? autocomplete.getPlace()
          : null,
      )
    })
  }

  const handleCompChange = (event) => {
    const { name = '', value = '' } = event.target
    let newInput = { ...compPropertyInput }
    let newValue = value
    //if (type === 'number') newValue = Number(value)
    newInput[name] = newValue
    setCompPropertyInput(newInput)
  }

  const onChangeDate = (date) => {
    let newInput = { ...compPropertyInput }
    newInput['saleDate'] = date
    setCompPropertyInput(newInput)
  }

  const onChangeSlider = (event, value, name) => {
    let newInput = { ...compPropertyInput }
    newInput[name] = value
    setCompPropertyInput(newInput)
  }

  const onChangeDateMenu = (date) => {
    let newDate = toString(date)
    newDate = replace(date, /-/g, '')
    if (newDate) {
      const year = Number(date.substring(0, 4))
      const month = Number(date.substring(4, 6))
      const day = Number(date.substring(6, 8))
      newDate = new Date(year, month - 1, day)
      onChangeDate(newDate)
    }
  }

  const renderInput = (field) => {
    if (field.display) {
      return (
        <Grid
          item
          container
          xs={field.xs}
          sm={field.sm}
          md={field.md}
          lg={field.lg}
          xl={field.xl}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography>{field.label}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {field.type === 'dollar'
                ? currencyFormat.format(field.value) || '-'
                : field.value || '-'}
              {field.type === 'percentage' ? '%' : ''}
            </Typography>
          </Grid>
        </Grid>
      )
    } else if (field.type === 'date') {
      return (
        <Grid item xs={field.xs} sm={field.sm}>
          <DatePicker
            name={field.name}
            label={field.label}
            value={field.value}
            onChange={(date) => onChangeDate(date)}
            format="MM/dd/yyyy"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment:
                field.definition &&
                propertyData &&
                propertyData[field.definition] &&
                propertyData[field.definition].length > 1 ? (
                  <InputAdornment
                    position="end"
                    onClick={(event) =>
                      handleDefinition(
                        field.definition,
                        event.target,
                        field.name,
                        field.format,
                      )
                    }
                  >
                    <IconButton size="small" style={{ padding: 5 }}>
                      <FontAwesomeIcon
                        icon={['fal', 'database']}
                        className="font-size-xl"
                      />
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="dense"
                fullWidth
                style={{ borderColor: 'grey' }}
              />
            )}
            views={['year', 'month', 'day']}
            openTo="year"
          />
        </Grid>
      )
    } else if (field.type === 'select') {
      return (
        <Grid item xs={field.xs} sm={field.sm}>
          <Autocomplete
            value={field.value}
            options={field.options}
            onChange={(event, newValue) =>
              handleCompChange({
                target: { name: field.name, value: newValue },
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={field.label}
                variant="outlined"
              />
            )}
          />
        </Grid>
      )
    } else if (field.type === 'currency') {
      return (
        <Grid item xs={field.xs} sm={field.sm}>
          <NumericFormat
            customInput={TextField}
            thousandSeparator={true}
            prefix={'$'}
            fullWidth={true}
            //isNumericString={true}
            decimalScale={0}
            key={field.name}
            name={field.name}
            value={Math.round(field.value)}
            onValueChange={(values) => {
              const { value } = values
              const event = {
                target: {
                  name: field.name,
                  value: value,
                  type: 'number',
                },
                preventDefault: () => {
                  return null
                },
              }
              handleCompChange(event)
            }}
            type={'text'}
            onFocus={(event) => {
              event.target.select()
            }}
            variant="outlined"
            //margin='dense'
            label={field.label}
            InputProps={{
              endAdornment:
                field.definition &&
                propertyData &&
                propertyData[field.definition] &&
                propertyData[field.definition].length > 1 ? (
                  <InputAdornment
                    position="end"
                    onClick={(event) =>
                      handleDefinition(
                        field.definition,
                        event.target,
                        field.name,
                        field.format,
                      )
                    }
                  >
                    <IconButton size="small" style={{ padding: 5 }}>
                      <FontAwesomeIcon
                        icon={['fal', 'database']}
                        className="font-size-xl"
                      />
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />
        </Grid>
      )
    } else if (field.type === 'number') {
      return (
        <Grid item xs={field.xs} sm={field.sm}>
          <NumericFormat
            customInput={TextField}
            thousandSeparator={!field.noSeperator}
            fullWidth={true}
            //isNumericString={true}
            decimalScale={field.decimal || 0}
            key={field.name}
            name={field.name}
            value={field.value}
            allowNegative={false}
            onValueChange={(values) => {
              const { value } = values
              const event = {
                target: {
                  name: field.name,
                  value: value,
                  type: 'number',
                },
                preventDefault: () => {
                  return null
                },
              }
              handleCompChange(event)
            }}
            type={'text'}
            onFocus={(event) => {
              event.target.select()
            }}
            variant="outlined"
            //margin='dense'
            label={field.label}
            InputProps={{
              endAdornment:
                field.definition &&
                propertyData &&
                propertyData[field.definition] &&
                propertyData[field.definition].length > 1 ? (
                  <InputAdornment
                    position="end"
                    onClick={(event) =>
                      handleDefinition(
                        field.definition,
                        event.target,
                        field.name,
                        field.format,
                      )
                    }
                  >
                    <IconButton size="small" style={{ padding: 5 }}>
                      <FontAwesomeIcon
                        icon={['fal', 'database']}
                        className="font-size-xl"
                      />
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />
        </Grid>
      )
    } else {
      return (
        <Grid item xs={field.xs} sm={field.sm}>
          <TextField
            name={field.name}
            fullWidth={true}
            variant="outlined"
            // margin='dense'
            label={field.label}
            value={field.value}
            onChange={handleCompChange}
            InputProps={{
              endAdornment:
                field.definition &&
                propertyData &&
                propertyData[field.definition] &&
                propertyData[field.definition].length > 1 ? (
                  <InputAdornment
                    position="end"
                    onClick={(event) =>
                      handleDefinition(
                        field.definition,
                        event.target,
                        field.name,
                        field.format,
                      )
                    }
                  >
                    <IconButton size="small" style={{ padding: 5 }}>
                      <FontAwesomeIcon
                        icon={['fal', 'database']}
                        className="font-size-xl"
                      />
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />
        </Grid>
      )
    }
  }

  const [backdrop, setBackdrop] = useState(false)

  const [addComp] = useMutation(ADD_COMPPROPERTY)
  // const [getRentalComps] = useMutation(GET_RENTAL_COMPS)
  const [updateComp] = useMutation(UPDATE_COMPPROPERTY)
  const [updateComps] = useMutation(UPDATE_COMPPROPERTIES)
  const [deleteComp] = useMutation(DELETE_COMP)
  const [imageUpload] = useMutation(IMAGE_UPLOAD)
  const [saveAnalysis] = useMutation(SAVE_ANALYSIS)
  const [deleteFile] = useMutation(DELETE_IMAGE)
  const [openDeleteComp, setOpenDeleteComp] = useState(false)

  const [anchorElActionsMenu, setAnchorElActionsMenu] = useState(null)
  const handleClickActionsMenu = (event) => {
    setAnchorElActionsMenu(event.currentTarget)
  }
  const handleCloseActionsMenu = () => {
    setAnchorElActionsMenu(null)
  }

  const toggleDeleteComp = () => {
    setOpenDeleteComp(!openDeleteComp)
  }

  const submitComp =
    (changePotential = false) =>
    () => {
      setLoader(true)
      if (edit) {
        updateComp({
          refetchQueries: [
            {
              query: GET_COMPPROPERTIES,
              variables: {
                propertyId: property._id,
              },
            },
            {
              query: GET_PROPERTY,
              variables: { id: property._id },
            },
          ],
          variables: {
            id: compPropertyInput.mainProperty, //edit had id stored as mainProperty
            compPropertyInput: {
              address: compPropertyInput.address,
              city: compPropertyInput.city,
              state: compPropertyInput.state,
              zip: padStart(compPropertyInput.zip, 5, '0'),
              beds: parseInt(compPropertyInput.beds),
              baths: parseFloat(compPropertyInput.baths),
              sqft: parseInt(compPropertyInput.sqft),
              year: parseInt(compPropertyInput.year),
              estval: parseInt(compPropertyInput.estval),
              daysOnMarket: parseInt(compPropertyInput.daysOnMarket),
              rehabLevel: compPropertyInput.rehabLevel,
              saleDate: compPropertyInput.saleDate
                ? new Date(compPropertyInput.saleDate)
                : null,
              //rentalPrice: parseFloat(rentalPrice),
              priceAdjustment: parseFloat(
                compPropertyInput.priceAdjustment,
              ),
              potential: changePotential ? !potential : potential,
            },
          },
        })
          .then(async ({ data }) => {
            imageUpload({
              refetchQueries: [
                {
                  query: GET_COMPPROPERTIES,
                  variables: { propertyId: property._id },
                },
                {
                  query: GET_PROPERTY,
                  variables: { id: property._id },
                },
              ],
              variables: {
                documentId: compPropertyInput.mainProperty,
                imageUrls: selectedImages,
                collection: 'compProperty',
              },
            })

            enqueueSnackbar(`Comp updated successfully`, {
              variant: 'success',
              autoHideDuration: 3000,
            })
            handleCloseAddComp()
          })
          .catch((error) => {
            enqueueSnackbar(
              `We couldn't update your comp at this time`,
              {
                variant: 'error',
                autoHideDuration: 3000,
              },
            )
            handleCloseAddComp()
          })
      } else {
        addComp({
          refetchQueries: [
            {
              query: GET_COMPPROPERTIES,
              variables: {
                propertyId: property._id,
              },
            },
            {
              query: GET_PROPERTY,
              variables: { id: property._id },
            },
          ],
          variables: {
            compPropertyInput: {
              mainProperty: compPropertyInput.mainProperty,
              address: compPropertyInput.address,
              city: compPropertyInput.city,
              state: compPropertyInput.state,
              zip: toString(padStart(compPropertyInput.zip, 5, '0')),
              beds: parseInt(compPropertyInput.beds),
              baths: parseFloat(compPropertyInput.baths),
              sqft: parseInt(compPropertyInput.sqft),
              year: parseInt(compPropertyInput.year),
              estval: parseInt(compPropertyInput.estval),
              daysOnMarket: parseInt(compPropertyInput.daysOnMarket),
              rehabLevel: compPropertyInput.rehabLevel,
              saleDate: compPropertyInput.saleDate
                ? new Date(compPropertyInput.saleDate)
                : null,
              //rentalPrice: parseFloat(rentalPrice),
              priceAdjustment: parseFloat(
                compPropertyInput.priceAdjustment,
              ),
              location: compPropertyInput.location,
            },
          },
        })
          .then(async ({ data }) => {
            imageUpload({
              refetchQueries: [
                {
                  query: GET_COMPPROPERTIES,
                  variables: { propertyId: property._id },
                },
              ],
              variables: {
                documentId: data.addCompProperty._id,
                imageUrls: selectedImages,
                collection: 'compProperty',
              },
            })
            enqueueSnackbar(`Comp added successfully`, {
              variant: 'success',
              autoHideDuration: 3000,
            })

            handleCloseAddComp()
            handleCloseAddCompAddress()
          })
          .catch((error) => {
            enqueueSnackbar(
              `We couldn't add your comp at this time`,
              {
                variant: 'error',
                autoHideDuration: 3000,
              },
            )
            handleCloseAddComp()
            handleCloseAddCompAddress()
          })
      }
    }

  // const pullRentalComps = () => {
  //   const { location = {} } = property
  //   setBackdrop(true)

  //   getRentalComps({
  //     variables: {
  //       propertyId: property._id,
  //       address: property.address,
  //       city: property.city,
  //       state: property.state,
  //       location: {
  //         latitude: location.latitude,
  //         longitude: location.longitude,
  //       },
  //       beds: sum(property.beds),
  //     },
  //     refetchQueries: [
  //       {
  //         query: GET_COMPPROPERTIES,
  //         variables: {
  //           propertyId: property._id,
  //         },
  //       },
  //       {
  //         query: GET_PROPERTY,
  //         variables: { id: property._id },
  //       },
  //     ],
  //   })
  //     .then(async ({ data }) => {
  //       if (
  //         data.getRentalComps &&
  //         !isEmpty(data.getRentalComps.properties)
  //       )
  //         enqueueSnackbar(`Comps added successfully`, {
  //           variant: 'success',
  //           autoHideDuration: 3000,
  //         })
  //       else
  //         enqueueSnackbar(`We couldn't find any comps at this time`, {
  //           variant: 'error',
  //           autoHideDuration: 3000,
  //         })
  //     })
  //     .catch((error) => {
  //       enqueueSnackbar(`Something went wrong`, {
  //         variant: 'error',
  //         autoHideDuration: 3000,
  //       })
  //     })
  //     .finally(() => {
  //       setBackdrop(false)
  //     })
  // }

  const onDelete = () => {
    deleteComp({
      refetchQueries: [
        {
          query: GET_COMPPROPERTIES,
          variables: {
            propertyId: property._id,
          },
        },
        {
          query: GET_PROPERTY,
          variables: { id: property._id },
        },
      ],
      variables: {
        id: compPropertyInput.mainProperty, //edit stores _id as mainProperty
      },
    })
      .then(async ({ data }) => {
        enqueueSnackbar(`Comp Deleted`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        handleCloseAddComp()
        toggleDeleteComp()
      })
      .catch((error) => {
        enqueueSnackbar(`Error Deleting Comp`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseAddComp()
        toggleDeleteComp()
      })
  }

  const compFields = [
    // {
    //   type: 'text',
    //   name: 'address',
    //   label: 'Address',
    //   value: compPropertyInput.address,
    //   definition: 'address',
    //   xs: 12,
    // },
    {
      type: 'text',
      name: 'city',
      label: 'City',
      value: compPropertyInput.city,
      definition: 'city',
      xs: 12,
    },
    {
      type: 'autocomplete',
      name: 'state',
      label: 'State',
      options: statesShort(),
      value: compPropertyInput.state,
      definition: 'state',
      xs: 6,
    },
    {
      type: 'number',
      name: 'zip',
      label: 'Zip',
      value: compPropertyInput.zip,
      definition: 'zip',
      noSeperator: true,
      xs: 6,
    },
    {
      type: 'number',
      name: 'beds',
      label: 'Beds',
      value: compPropertyInput.beds,
      definition: 'beds',
      xs: 6,
    },
    {
      type: 'number',
      name: 'baths',
      label: 'Baths',
      value: compPropertyInput.baths,
      definition: 'baths',
      decimal: 2,
      xs: 6,
    },
    {
      type: 'number',
      name: 'sqft',
      label: 'Sqft',
      value: compPropertyInput.sqft,
      definition: 'sqft',
      format: numberFormat,
      xs: 6,
    },
    {
      type: 'number',
      name: 'year',
      label: 'Year',
      noSeperator: true,
      value: compPropertyInput.year,
      definition: 'yearBuilt',
      xs: 6,
    },
  ]

  const compFinancialFields = [
    {
      type: 'currency',
      name: 'estval',
      label: 'Sold Price',
      value: compPropertyInput.estval,
      definition: 'price',
      format: currencyFormat,
      xs: 12,
      sm: 6,
    },
    {
      type: 'date',
      name: 'saleDate',
      label: 'Date Sold',
      value: compPropertyInput.saleDate
        ? new Date(compPropertyInput.saleDate).toLocaleDateString(
            'en-US',
          )
        : null,
      definition: 'soldDate',
      xs: 12,
      sm: 6,
    },
    {
      type: 'number',
      name: 'daysOnMarket',
      label: 'Days on Market',
      value: compPropertyInput.daysOnMarket,
      xs: 12,
      sm: 6,
    },
    {
      type: 'select',
      name: 'rehabLevel',
      label: 'Rehab Level',
      value: compPropertyInput.rehabLevel,
      xs: 12,
      sm: 6,
      options: map(REHAB_LEVELS, (rehab) => rehab.value),
    },
  ]

  const widgetsCarousels1B = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
  }

  const [selected, setSelected] = useState([])

  const [search, setSearch] = useState('')
  const [searchedComps, setSearchedComps] = useState(comps)

  const [filtered, setFiltered] = useState(false)
  const [filteredComps, setFilteredComps] = useState(comps)

  const [showMap, setShowMap] = useState(true)
  const [showAnalytics, setShowAnalytics] = useState(true)

  const [searchStatus3, setSearchStatus3] = useState(false)
  const toggleSearch3 = () => setSearchStatus3(!searchStatus3)

  const { page, setPage } = props
  const handleChange = (event, value) => {
    localStorage.setItem(
      `${property._id}-comps-rental-card-page`,
      value,
    )
    setPage(value)
  }

  const [bulkSetCompDialog, setBulkSetCompDialog] = useState(false)
  const [bulkSetPotentialDialog, setBulkSetPotentialDialog] =
    useState(false)

  const handleBulkDialog = (potential = false) => {
    setLoader(true)
    updateComps({
      variables: {
        ids: selected,
        compPropertyInput: { potential },
      },
      refetchQueries: [
        {
          query: GET_COMPPROPERTIES,
          variables: { propertyId: property._id },
        },
      ],
    }).then(async ({ data }) => {
      setLoader(false)
      setSelected([])
      closeBulk()
    })
  }

  const closeBulk = () => {
    setBulkSetCompDialog(false)
    setBulkSetPotentialDialog(false)
  }

  const compare = intersection(
    map(searchedComps, (comp) => comp._id),
    selected,
  )

  let shownComps =
    search && filtered
      ? intersectionBy(searchedComps, filteredComps, '_id')
      : search
        ? [...searchedComps]
        : filtered && filteredComps
          ? [...filteredComps]
          : [...comps] || []

  const actualSelected =
    filter(shownComps, (comp) => includes(selected, comp._id))
      .length > 0

  const compValues = map(comps, (comp) => {
    return {
      _id: comp._id,
      rentalPrice: comp.rentalPrice,
      occupancyRate: comp.occupancyRate,
    }
  })

  const shownCompID = map(shownComps, (comp) => comp._id)
  const shownCompValues = filter(compValues, (value) =>
    includes(shownCompID, value._id),
  )

  const selectComp = (id) => (event) => {
    event.preventDefault()
    let newSelect = [...selected]
    if (includes(newSelect, id)) pull(newSelect, id)
    else newSelect.push(id)
    setSelected(newSelect)
  }

  const selectAll = () => {
    if (compare.length < comps.length)
      setSelected(map(comps, (comp) => comp._id))
    else setSelected([])
  }

  const [isSavedFilter, setIsSavedFilter] = useState(false)

  const getFilteredComps = (filtered) => {
    setFilteredComps(filtered)
    setFiltered(true)
  }

  useEffect(() => {
    const getSearchResults = (string) => {
      if (search === string) return null
      const words = split(string, ' ')
      const searchFilters = map(
        words,
        (word) => new RegExp(toLower(word)),
      )
      const filtered = filter(comps, (comp) => {
        const { createdBy } = comp
        const profile = createdBy
          ? createdBy.profile
          : { firstname: '', lastname: '' }
        return every(
          searchFilters,
          (searchFilter) =>
            searchFilter.test(toLower(comp.address)) ||
            searchFilter.test(toLower(comp.city)) ||
            searchFilter.test(toLower(comp.state)) ||
            searchFilter.test(toLower(comp.zip)) ||
            searchFilter.test(toLower(comp.sqft)) ||
            searchFilter.test(toLower(comp.description)) ||
            searchFilter.test(toLower(profile.firstname)) ||
            searchFilter.test(toLower(profile.lastname)),
        )
      })
      if (isEqual(filtered, searchedComps)) return null
      setSearch(string)
      setSearchedComps(filtered)
    }
    getSearchResults(search)
  }, [comps, search, searchedComps])

  const count = ceil(shownComps.length / 24)
  if (page > count && count) setPage(count || 1)

  return (
    <>
      <Grid container spacing={2} style={{ padding: 25 }}>
        <Grid item xs={12}>
          <Collapse
            in={Boolean(showMenu)}
            style={{
              width: '100%',
              marginLeft: 15,
              marginRight: 15,
              marginBottom: 10,
            }}
          >
            <Card className="d-block d-sm-flex text-center text-md-left card-box p-4 align-items-center bg-secondary justify-content-between flex-row">
              {hiddenUp ? null : (
                <div
                  style={{ position: 'absolute', top: 5, right: 10 }}
                >
                  <Typography
                    variant="subtitle2"
                    onClick={() => handleMenu()}
                  >
                    Hide Menu
                  </Typography>
                </div>
              )}
              <Grid
                container
                className="d-block d-sm-flex align-items-center"
                spacing={2}
              >
                <Grid item>
                  {!props.shared && (
                    <Grid container alignItems="center">
                      <Grid item>
                        <Checkbox
                          checked={compare.length > 0}
                          indeterminate={
                            compare.length < shownComps.length &&
                            compare.length > 0
                          }
                          onClick={selectAll}
                        />
                      </Grid>
                      <Grid item>
                        <Typography>
                          Select All ({shownComps.length})
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {!props.shared && (
                  <Grid item className="mx-4 d-none d-md-block">
                    <div className="divider-v bg-dark opacity-3 position-relative" />
                    <div className="divider-v bg-dark opacity-3 position-relative" />
                  </Grid>
                )}
                <Grid
                  item
                  className="d-flex d-md-block justify-content-center py-3 py-md-0"
                >
                  <div
                    className={clsx(
                      'search-wrapper search-wrapper--grow',
                      { 'is-active': searchStatus3 },
                    )}
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      classes={{ root: 'bg-white' }}
                      className="w-100"
                      value={props.search}
                      onChange={(event) =>
                        setSearch(event.target.value)
                      }
                      placeholder={'Search these rental comps'}
                      id="input-with-icon-textfield22-3"
                      onFocus={toggleSearch3}
                      onBlur={toggleSearch3}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FilterListIcon
                              style={{ color: '#bbbbbb' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
                <Typography style={{ flex: 1 }} />
                {activeTab === '1' && (
                  <Grid item style={{ marginRight: 5 }}>
                    <Select
                      variant="outlined"
                      margin="dense"
                      fullWidth={true}
                      value={ordering}
                      onChange={(event) =>
                        setOrdering(event.target.value)
                      }
                      displayEmpty
                    >
                      {map(sortingChoices, (choice) => (
                        <MenuItem
                          key={choice.name}
                          value={choice.name}
                        >
                          {choice.title}
                        </MenuItem>
                      ))}
                      <MenuItem key={'unselected'} value={''}>
                        Added - new to old
                      </MenuItem>
                    </Select>
                  </Grid>
                )}
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <ButtonGroup /*variant="text"*/>
                        <Tooltip title="Card View">
                          <Button
                            className={clsx(
                              'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm',
                              { active: activeTab === '1' },
                            )}
                            onClick={() => {
                              setActiveTab('1')
                              localStorage.setItem('view', '1')
                            }}
                          >
                            <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                              <GridOnTwoTone />
                            </span>
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </Grid>
                    <Grid item>
                      <ButtonGroup /*variant="text"*/>
                        <Tooltip title="Show Analytics">
                          <Button
                            className={clsx(
                              'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm',
                              { active: showAnalytics },
                            )}
                            onClick={() => {
                              setShowAnalytics(!showAnalytics)
                            }}
                          >
                            <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                              <PollTwoTone />
                            </span>
                          </Button>
                        </Tooltip>
                        <Tooltip title="Show Map">
                          <Button
                            className={clsx(
                              'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm',
                              { active: showMap },
                            )}
                            onClick={() => {
                              setShowMap(!showMap)
                            }}
                          >
                            <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                              <RoomTwoTone />
                            </span>
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {showMap && (
              <Grid item xs={12} xl={6}>
                <Card>
                  {/* {!openAddCompAddress && (<Map properties={[property]} comps={shownComps} propertyPage streetView={false} />)} */}
                  <Map
                    properties={[property]}
                    comps={stableSort(
                      shownComps,
                      getSorting(order, orderByTarget),
                    )}
                    propertyPage
                    streetView={false}
                    hide={openAddCompAddress}
                    openComp={openEdit}
                    shared={props.shared || props.public}
                  />
                </Card>
              </Grid>
            )}
            {showAnalytics && (
              <Grid item xs={12} xl={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Card
                      className="card-box p-4"
                      style={{ height: '100%' }}
                    >
                      <div className="font-size-sm text-uppercase text-second mt-2">
                        Comps Properties
                      </div>
                      <div className="my-2">
                        <div className="d-flex py-4 align-items-center">
                          <div className="d-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-first text-white btn-icon text-center shadow-first mr-3">
                            <HomeIcon style={{ fontSize: 40 }} />
                          </div>
                          <div className="display-3 ml-1">
                            {shownComps.length}
                          </div>
                        </div>
                        <div className="text-black-50 mb-2">
                          The number of rental properties comparable
                          to {property.address}.
                        </div>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card
                      className="card-box p-4"
                      style={{ height: '100%' }}
                    >
                      <div className="font-size-sm text-uppercase text-second mt-2">
                        Average Daily Rate
                      </div>
                      <div className="my-2">
                        <div className="d-flex py-4 align-items-center">
                          <div className="display-4 font-weight-bold ml-1">
                            <CountUp
                              start={0}
                              end={
                                isEmpty(shownCompValues)
                                  ? 0
                                  : meanBy(
                                      filter(
                                        shownCompValues,
                                        'rentalPrice',
                                      ),
                                      'rentalPrice',
                                    )
                              }
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                            />
                          </div>
                        </div>
                        <div className="text-black-50 mb-2">
                          The average daily rate of the rental comp
                          properties below
                        </div>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card
                      data-tour="comps-step-1"
                      className="card-box p-4"
                      style={{ height: '100%' }}
                    >
                      <div className="font-size-sm text-uppercase text-second mt-2">
                        Average Occupancy Rate
                      </div>
                      <div className="my-2">
                        <div className="d-flex py-4 align-items-center">
                          <div className="d-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-first text-white btn-icon text-center shadow-first mr-3">
                            <LinearScaleIcon
                              style={{ fontSize: 40 }}
                            />
                            {/* <FontAwesomeIcon
                            icon={['far', 'envelope']}
                            className="display-4"
                          /> */}
                          </div>
                          <div className="display-3 font-weight-bold ml-1">
                            <CountUp
                              start={0}
                              end={
                                isEmpty(shownCompValues)
                                  ? 0
                                  : meanBy(
                                      filter(
                                        shownCompValues,
                                        'occupancyRate',
                                      ),
                                      'occupancyRate',
                                    ) * 100
                              }
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={2}
                              decimal="."
                              suffix="%"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-black-50 mb-2">
                        The average rate these rental comps are
                        occupied
                      </div>
                    </Card>
                  </Grid>
                  {/* <Grid item xs={12} lg={6}>
                  <Card className="card-box p-4" style={{ height: '100%' }}>
                    <div className="font-size-sm text-uppercase text-second mt-2">
                      Average Property Value
                    </div>
                    <div className="my-2">
                      <div className="d-flex py-4 align-items-center">
                        <div className="d-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-first text-white btn-icon text-center shadow-first mr-3">
                          <SquareFootIcon style={{ fontSize: 40 }} />
                        </div>
                        <div className="display-3 font-weight-bold ml-1">
                          <CountUp
                            start={0}
                            end={isEmpty(shownCompValues) ? 0 : meanBy(filter(shownCompValues, 'propertyPrice'), 'propertyPrice')}
                            duration={1}
                            separator=","
                            delay={1}
                            decimals={2}
                            decimal="."
                            prefix="$"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-black-50 mb-2">
                      The average property value of the rental comp properties below.
                    </div>
                  </Card>
                </Grid> */}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {activeTab === '1' && comps.length > 0 && (
            <>
              <Grid
                container
                direction="column"
                style={{ marginTop: 10 }}
              >
                <Grid item style={{ marginBottom: -10 }}>
                  <Typography variant="h5">
                    Comparable Properties
                  </Typography>
                </Grid>
                <Grid item>
                  {compact(shownComps).length > 0 ? (
                    <>
                      <Grid
                        container
                        spacing={4}
                        style={{ marginTop: 10 }}
                      >
                        {map(
                          slice(
                            stableSort(
                              compact(shownComps),
                              getSorting(order, orderByTarget),
                            ),
                            (page - 1) * 24,
                            page * 24,
                          ),
                          (comp, index) => (
                            <RentalCompsCard
                              comp={comp}
                              index={(page - 1) * 24 + index}
                              property={property}
                              openEdit={openEdit}
                              selected={selected}
                              selectComp={selectComp}
                              shared={props.shared || props.public}
                            />
                          ),
                        )}
                      </Grid>
                      {Boolean(count) && shownComps.length > 0 && (
                        <Grid item xs={12}>
                          <div className="p-3 d-flex justify-content-center">
                            <Pagination
                              className="pagination-first"
                              size="small"
                              count={count}
                              page={page}
                              onChange={handleChange}
                              shared={props.shared || props.public}
                            />
                          </div>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <AllPropertiesFiltered />
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {/* {!props.public && !props.shared && isEmpty(props.comps) && (
        <Grid item>
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div
                className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-first m-0 d-130"
              >
                <CompareIcon
                  style={{ fontSize: 56, color: '#008bff' }}
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              Lets Find Some Rental Comps
            </h4>
            <p className="mb-0 font-size-lg text-muted">
              Use Housefolios to help you find comparable rental
              properties nearby
            </p>
            <div className="pt-4">
              <Button
                data-tour="comps-step-3"
                variant="contained"
                size="small"
                onClick={pullRentalComps}
                style={{ backgroundColor: '#1cc943', color: 'white' }}
              >
                <AddTwoToneIcon
                  style={{ marginRight: 10 }}
                />
                Get Rental Comps
              </Button>
            </div>
            {backdrop && (
              <Backdrop
                open={backdrop}
                style={{ color: '#fff', zIndex: 100000 }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </div>
        </Grid>
      )} */}
      <Collapse
        in={selected.length > 0}
        style={{
          position: 'fixed',
          bottom: '20px',
          width: '75%',
          zIndex: 1100,
        }}
      >
        <Alert
          className="d-block"
          style={{ backgroundColor: '#253152', color: 'white' }}
          icon={false}
          // severity="error"
          // action={
          //   <Button variant='contained' size="small" style={{ backgroundColor: '#4290ff', color: 'white' }}>
          //     <Typography>Save</Typography>
          //   </Button>
          // }
        >
          {/* <Typography style={{ color: 'white', fontWeight: 600 }}>You have unsaved changes</Typography> */}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            style={{ overflow: 'scroll' }}
          >
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item>
                  <Tooltip title="Deselect Comps">
                    <IconButton
                      onClick={() => setSelected([])}
                      size="large"
                    >
                      <FontAwesomeIcon
                        icon={['far', 'xmark']}
                        style={{ color: 'white' }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography
                    style={{ whiteSpace: 'nowrap', marginRight: 20 }}
                  >
                    {selected.length} selected
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              style={{ textAlign: 'center', marginRight: 20 }}
            >
              <Grid container wrap="nowrap">
                <Grid item>
                  <DeleteComps
                    bulkActionsNotifications={true}
                    selected={selected}
                    setSelected={setSelected}
                    propertyId={property._id}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item disabled />
          </Grid>
        </Alert>
      </Collapse>
      <Dialog
        open={openAddCompAddress}
        onClose={handleCloseAddCompAddress}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="sm"
        fullWidth={true}
      >
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=geometry,places`}
          onLoad={handleScriptLoad}
        />
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              <p>Connecting</p>
              <p>
                {(((minutes * 60 + seconds) / 120) * 100).toFixed(1)}%
                Complete
              </p>
            </div>
          }
        >
          <DialogTitle>Add Comp Property</DialogTitle>
          <DialogContent>
            <TextField
              id="autocomplete"
              label="Search Property"
              placeholder="Address"
              fullWidth={true}
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <ApolloConsumer>
              {(client) => (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!placeResult}
                  onClick={() => searchForComp(client)}
                >
                  Search
                </Button>
              )}
            </ApolloConsumer>
          </DialogActions>
        </BlockUi>
        {/* <Card>
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=geometry,places`}
          onLoad={handleScriptLoad}
        />
        <CardHeader title="Add Comp Property" />
        <CardContent>
          <TextField
            id="autocomplete"
            label="Search Property"
            placeholder="Address"
            fullWidth={true}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Typography style={{ flex: 1 }} />
          <ApolloConsumer>
            {client => (
              <Button
                variant="contained"
                color="primary"
                disabled={!placeResult}
                onClick={() => searchForComp(client)}>
                Search
              </Button>
            )}
          </ApolloConsumer>
        </CardActions>
      </Card> */}
      </Dialog>
      <Dialog
        open={openAddComp}
        onClose={handleCloseAddComp}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          {edit ? 'Edit Comp' : 'Add Comp'}{' '}
          {edit && (
            <Avatar
              className={
                compPropertyInput.potential ? 'bg-warning' : null
              }
              style={{
                height: 30,
                width: 30,
                position: 'absolute',
                top: 15,
                right: 20,
                backgroundColor: compPropertyInput.potential
                  ? null
                  : '#2f3f4d',
              }}
            >
              {compPropertyInput.indexTest}
            </Avatar>
          )}
        </DialogTitle>
        {/* <Grid item> */}

        {/* </Grid> */}
        <DialogContent className="p-4">
          <BlockUi
            blocking={loader}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={<div className="text-primary">Saving Comp</div>}
          >
            <Grid container direction="row">
              <Grid item sm={12} style={{ padding: 10 }}>
                <Grid container direction="column">
                  <Slider1
                    className="card-img-top slider-dots"
                    {...widgetsCarousels1B}
                  >
                    {!isEmpty(images) &&
                      map(images, (image) => (
                        <div
                          onClick={(event) => {
                            event.stopPropagation()
                          }}
                        >
                          <Card
                            className="shadow-none"
                            style={{
                              height: 325,
                              backgroundColor: 'white',
                              margin: 1,
                            }}
                          >
                            <Grid
                              container
                              direction="column"
                              justifyContent="center"
                              style={{ height: '100%' }}
                            >
                              <Grid item>
                                <div
                                  className="card-img-wrapper"
                                  style={{
                                    textAlign: 'center',
                                    backgroundColor: 'transparent',
                                    position: 'relative',
                                  }}
                                >
                                  <div
                                    className="blur-load"
                                    style={{
                                      backgroundImage:
                                        'url(' +
                                        image.url_small +
                                        ')',
                                      height: 325,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <img
                                      src={image.url}
                                      className="card-img-top rounded"
                                      style={{
                                        maxHeight: 325,
                                        height: 'auto',
                                        width: 'auto',
                                        borderBottomRightRadius:
                                          '0px',
                                        objectFit: 'contain',
                                      }}
                                      alt="..."
                                      loading="lazy"
                                    />
                                  </div>
                                </div>
                                <Avatar
                                  onClick={(event) =>
                                    handleEditImageMoreVertOpen(
                                      event,
                                      image,
                                    )
                                  }
                                  style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    backgroundColor: '#FA7A87',
                                  }}
                                >
                                  <MoreVertIcon
                                    style={{ color: 'white' }}
                                  />
                                </Avatar>
                                {/* <Avatar
                              onClick={toggleOpenDeleteImage(image._id)}
                              style={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                backgroundColor: '#FA7A87',
                              }}
                            >
                              <DeleteTwoTone style={{ color: 'white' }} />
                            </Avatar> */}
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                      ))}
                    {!isEmpty(selectedImages) &&
                      map(selectedImages, (image) => (
                        <div
                          onClick={(event) => {
                            event.stopPropagation()
                          }}
                        >
                          <Card
                            className="shadow-none"
                            style={{
                              height: 325,
                              backgroundColor: 'white',
                              margin: 1,
                            }}
                          >
                            <Grid
                              container
                              direction="column"
                              justifyContent="center"
                              style={{ height: '100%' }}
                            >
                              <Grid item>
                                <div
                                  className="card-img-wrapper"
                                  style={{
                                    textAlign: 'center',
                                    backgroundColor: 'transparent',
                                  }}
                                >
                                  <div
                                    className="blur-load"
                                    style={{
                                      backgroundImage:
                                        'url(' +
                                        image.url_small +
                                        ')',
                                      height: 325,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <img
                                      src={image.url}
                                      className="card-img-top rounded"
                                      style={{
                                        maxHeight: 325,
                                        height: 'auto',
                                        width: 'auto',
                                        borderBottomRightRadius:
                                          '0px',
                                        objectFit: 'contain',
                                      }}
                                      alt="..."
                                      loading="lazy"
                                    />
                                  </div>
                                </div>
                                <Avatar
                                  onClick={() =>
                                    handleOpenEditImage(image)
                                  }
                                  style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    // backgroundColor: 'primary',
                                  }}
                                >
                                  <EditIcon
                                    style={{ color: 'white' }}
                                  />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                      ))}
                  </Slider1>
                  <Menu
                    anchorEl={editImageMoreVert}
                    keepMounted
                    getContentAnchorEl={null}
                    open={Boolean(editImageMoreVert)}
                    onClose={handleEditImageMoreVertClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    classes={{ list: 'p-0' }}
                  >
                    <div className="p-2">
                      <List
                        component="div"
                        className="nav-pills p-0 m-0 nav-neutral-dark flex-column"
                      >
                        <ListItem
                          button
                          onClick={() =>
                            handleOpenEditImage(selectedEditImage)
                          }
                        >
                          <EditIcon
                            /*className="w-50"*/ style={{
                              marginRight: 10,
                            }}
                          />
                          Edit Image
                        </ListItem>
                        <ListItem
                          button
                          onClick={toggleOpenDeleteImage(
                            selectedEditImage._id,
                          )}
                        >
                          <DeleteTwoTone
                            /*className="w-50"*/ style={{
                              marginRight: 10,
                            }}
                          />
                          Delete Image
                        </ListItem>
                      </List>
                    </div>
                  </Menu>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} style={{ marginBottom: 25 }}>
              <div>
                <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                  <input {...getInputProps()} />
                    <div className="dropzone-inner-wrapper-add-comp">
                      <div style={{ textAlign: 'center' }}>
                        <Button variant='contained' margin='dense' style={{ backgroundColor: '#368CFF', color: 'white' }}>Upload Additional Images</Button>
                      </div>
                  </div>
                </div>
              </div>
            </Grid> */}
              <Grid item sm={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12}>
                    <Typography color="primary" variant="h6">
                      Property Details
                    </Typography>
                  </Grid>
                  {map(compFields, (field) => renderInput(field))}
                  {/* <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Typography color="primary" variant="h6">Financial Details</Typography>
                </Grid>
                {map(compFinancialFields, field => (
                  renderInput(field)
                ))} */}
                  {/* <Grid item xs={12} style={{ marginTop: 5 }}>
                  <Grid container>
                    <Grid item xs={12} style={{ marginBottom: -30 }}>Adjust Comp</Grid>
                    <Grid item xs={12}>
                      <Slider
                        className="slider-first my-3"
                        color="#008bff"
                        value={compPropertyInput.priceAdjustment}
                        onChange={(event, value) => {
                          onChangeSlider(
                            event,
                            value,
                            'priceAdjustment',
                          )
                        }}
                        onChangeCommitted={(event, value) => {
                          onChangeSlider(
                            event,
                            value,
                            'priceAdjustment',
                          )
                        }}
                        marks={true}
                        track={false}
                        valueLabelDisplay="auto"
                        reverse
                        min={-15}
                        max={15}
                        step={2.5}
                        valueLabelFormat={x => `${x}%`}
                        style={{
                          width: '95%',
                          marginLeft: 18,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </BlockUi>
        </DialogContent>
        <DialogActions>
          {edit && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleClickActionsMenu}
            >
              Actions
              <KeyboardArrowDownTwoTone />
            </Button>
          )}
          <Typography style={{ flex: 1 }} />
          <Button
            size="small"
            variant="text"
            onClick={handleCloseAddComp}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={submitComp()}
          >
            Save
          </Button>
        </DialogActions>
        <Menu
          anchorEl={anchorElActionsMenu}
          keepMounted
          open={Boolean(anchorElActionsMenu)}
          onClose={handleCloseActionsMenu}
          classes={{ list: 'p-0' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="dropdown-menu-sm">
            <List
              component="div"
              disablePadding
              className="nav-list-square nav-neutral-primary"
            >
              {/* <ListItem key="changePotential" button onClick={submitComp(true)}>
              <Grid container spacing={2} style={{ padding: 5 }}>
                <Grid item xs={3}><CompareOutlined className={`mr-2 ${classes.compareIcon}`}/></Grid>
                <Grid item xs={9}><span>Set as {potential ? 'Comp' : 'Potential'}</span></Grid>
              </Grid>
            </ListItem> */}
              <ListItem
                key="delete"
                button
                onClick={toggleDeleteComp}
              >
                <Grid container spacing={2} style={{ padding: 5 }}>
                  <Grid item xs={3}>
                    <div className="nav-link-icon2">
                      <FontAwesomeIcon icon={['far', 'trash-can']} />
                    </div>
                  </Grid>
                  <Grid item xs={9}>
                    <span>Delete</span>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </div>
        </Menu>
      </Dialog>
      <Dialog
        open={openDeleteComp}
        onClose={toggleDeleteComp}
        classes={{ paper: 'shadow-lg rounded' }}
      >
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon
                icon={['fas', 'xmark']}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">
            Are you sure you want to delete this?
          </h4>
          <p className="mb-0 font-size-lg text-muted">
            All data will be deleted. This action cannot be undone.
          </p>
          <div className="pt-4">
            <Button
              variant="text"
              onClick={toggleDeleteComp}
              //className="btn-neutral-secondary btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: '#f83145', color: 'white' }}
              onClick={onDelete}
              //className="btn-danger btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Delete</span>
            </Button>
          </div>
        </div>
      </Dialog>
      <ImageCropper
        open={openEditImage}
        imageSrc={selectedEditImage.url || selectedEditImage}
        saveImage={saveCroppedImage}
        replaceOption={true}
        handleClose={handleCloseEditImage}
      />
      <Dialog
        open={openDeleteImage}
        onClose={toggleOpenDeleteImage()}
      >
        <DialogTitle style={{ paddingBottom: '0px' }}>
          Delete Image
        </DialogTitle>

        <Mutation
          mutation={DELETE_IMAGE_MULTI}
          refetchQueries={[
            {
              query: GET_COMPPROPERTIES,
              variables: { propertyId: property._id },
            },
            {
              query: GET_PROPERTY,
              variables: { id: property._id },
            },
          ]}
          variables={{
            ids: [selectedImageDelete],
          }}
        >
          {(deleteFiles, { data, loading, error }) => (
            <form
              className="form"
              onSubmit={(event) => onSubmitDelete(event, deleteFiles)}
            >
              <DialogContent>
                Are you sure you want to delete this image? This
                action can't be undone.
              </DialogContent>
              <DialogActions>
                <Button onClick={toggleOpenDeleteImage()}>
                  CANCEL
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loader}
                >
                  DELETE
                </Button>
              </DialogActions>
            </form>
          )}
        </Mutation>
      </Dialog>

      {menu && (
        <Menu
          anchorEl={anchorElDefinition}
          open={Boolean(anchorElDefinition)}
          onClose={() => handleDefinition('', null, null, null)}
          MenuListProps={{ dense: true }}
          style={{ width: 400 }}
          getContentAnchorEl={null}
          classes={{ list: 'p-0' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="dropdown-menu-xl">
            <MenuItem key="menuTitle" button={false}>
              <Typography variant="h6">{startCase(menu)}</Typography>
            </MenuItem>
            <MenuItem key="menuDescription" button={false}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{
                  whiteSpace: 'normal',
                }}
              >
                We found multiple values for this field. Please choose
                the correct option.
              </Typography>
            </MenuItem>
            {map(propertyData[menu], (value) => (
              <MenuItem
                button
                key={menu + ': ' + value}
                onClick={() => {
                  if (menu === 'soldDate') onChangeDateMenu(value)
                  else
                    handleCompChange({
                      target: { name: menuName, value },
                    })
                  handleDefinition('', null, null)
                }}
              >
                <Typography style={{ flex: 1 }} />
                <ListItemText>
                  {menuFormat ? menuFormat.format(value) : value}
                </ListItemText>
              </MenuItem>
            ))}
          </div>
        </Menu>
      )}
    </>
  )
}

export default CenterPageComps
