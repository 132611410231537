/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-16 11:36:56
 * @ Modified by: David Helmick
 * @ Modified time: 2024-06-11 19:45:50
 * @ Description: Dialog to let user upload a file to use as a proforma
 */

import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, Grid, ListItem } from '@mui/material'

import hero1 from '@/assets/images/hero-bg/hero-1.jpg'
import { enqueueSnackbar } from 'notistack'
import FileUploader from '../UploadProforma/FileUploader'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import useAffiliate from '@/hooks/useAffiliate'

function UploadProformaComponent(props) {
  const { ActionsButton } = props

  const [openUploadProforma, setOpenUploadProforma] = useState(false)
  const [loader, setLoader] = useState(false)
  const handleOpenUploadProforma = () => {
    setOpenUploadProforma(true)
  }
  const handleCloseUploadProforma = () => {
    setOpenUploadProforma(false)
    setLoader(false)
  }

  const [{
    affiliate,
    urlAffiliate
  }]=useAffiliate({})

  return (
    <>
      {ActionsButton && (
        <ListItem button onClick={handleOpenUploadProforma}>
          <div className="nav-link-icon">
            <FontAwesomeIcon icon={['far', 'up-to-line']} />
          </div>
          <span>Upload Proforma</span>
        </ListItem>
      )}
      <Dialog
        open={openUploadProforma}
        onClose={handleCloseUploadProforma}
        maxWidth="lg"
        scroll="body"
        classes={{
          paper: 'modal-content rounded border-0 bg-white p-3 p-xl-0',
        }}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">Uploading Proforma</div>
          }
        >
          <Grid container spacing={0}>
            <Grid item xl={5}>
              <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div
                    className="bg-composed-wrapper--image rounded br-xl-right-0"
                    style={{ backgroundImage: 'url(' + hero1 + ')' }}
                  />
                  <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                  <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                    <div className="text-white mt-3">
                      <h1 className="display-4 my-3 font-weight-bold">
                        Upload your own proforma report
                      </h1>
                      <p className="font-size-md mb-0 text-white-50">
                        When you upload your own file here, {affiliate?.companyName || 'Housefolios'} will allow users to download it on
                        your marketplace.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xl={7}>
              <FileUploader
                proformaFile={props.proformaFile}
                propertyId={props.propertyId}
                setLoader={setLoader}
                collection="proformas"
              />
            </Grid>
          </Grid>
        </BlockUi>
      </Dialog>
    </>
  )
}

export default UploadProformaComponent
