import { MenuItem } from '@mui/material'
import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import replace from 'lodash/replace'
import clone from 'lodash/clone'

export const defaultStrategies = [
  {
    label: 'Buy & Hold',
  },
  {
    label: 'Fix & Flip',
  },
  {
    label: 'Turnkey End Buyer',
  },
  {
    label: 'BRRRR',
  },
  {
    label: 'Short Term Rental',
  },
  {
    label: 'Wholesale',
    memberExcluded: ['astroflip'],
  },
  {
    label: 'Build to Rent',
  },
  {
    label: 'BOR',
    memberOnly: 'flippingamerica',
  },
  {
    label: 'Astroflip',
    memberOnly: 'astroflip',
  },
]

export const getStrategyName = (strategy, member) => {
  if (member === 'pacemorby' && strategy === 'Wholesale') {
    return 'Wholetail'
  }
  if (member === 'astroflip' && strategy === 'Astroflip') {
    return 'Wholesale'
  }
  return strategy
}

export const filterPreferedStrategies = (
  preferences,
  member,
  checkActive = false,
) => {
  return map(
    filter(preferences.general['strategies'], function (strategy) {
      return (
        (checkActive ? strategy.active : true) &&
        (!strategy.memberOnly || strategy.memberOnly === member) &&
        (!strategy.memberExcluded ||
          !includes(strategy.memberExcluded, member))
      )
    }),
    (preference) => preference.value,
  )
}

export const replaceStrategyName = (string, member) => {
  let newString = clone(string)
  map(defaultStrategies, (strategy) => {
    if (includes(string, strategy.label)) {
      newString = replace(
        newString,
        strategy.label,
        getStrategyName(strategy.label, member),
      )
    }
  })
  return newString
}

export const getStrategyOptions = (member, strategies = null) => {
  const strategyOptions =
    strategies ||
    map(
      filter(defaultStrategies, (option) => {
        return (
          (!option.memberOnly || member === option.memberOnly) &&
          (!option.memberExcluded ||
            !includes(option.memberExcluded, member))
        )
      }),
      (strategy) => strategy.label,
    )
  return map(strategyOptions, (strategy) => (
    <MenuItem key={strategy} value={strategy}>
      {getStrategyName(strategy, member)}
    </MenuItem>
  ))
}
