/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: David Helmick
 * @ Modified time: 2024-06-11 19:02:16
 * @ Description: Dialog for editing a marketplace
 */

import React, { useState } from 'react'
import map from 'lodash/map'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  MenuItem,
  Typography,
} from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'

import withSession from '@/housefolios-components/Session/withSession'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { useMutation } from '@apollo/client'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import useAffiliate from '@/hooks/useAffiliate'

function EditMarketplaceComponents(props) {
  const { listItem } = props
  const [name, setName] = useState(props.marketPlace.name)
  const [meetingUrl, setMeetingUrl] = useState(
    props.marketPlace.meetingURL,
  )
  const [contactEmail, setContactEmail] = useState(
    props.marketPlace.contactEmail,
  )
  const [googleAnalytics, setGoogleAnalytics] = useState(
    props.marketPlace.googleAnalytics,
  )
  const [marketplaceVideoURL, setMarketplaceVideoURL] = useState(
    props.marketPlace.marketplaceVideoURL,
  )
  const [preApprovedURL, setPreApprovedURL] = useState(
    props.marketPlace.preApprovedURL,
  )
  const [openAddMarketplace, setAddMarketplace] = useState(false)
  const handleOpenAddMarketplace = () => {
    setAddMarketplace(true)
    setName(props.marketPlace.name || '')
    setMeetingUrl(props.marketPlace.meetingURL || '')
    setContactEmail(props.marketPlace.contactEmail || '')
    setGoogleAnalytics(props.marketPlace.googleAnalytics || '')
    setMarketplaceVideoURL(
      props.marketPlace.marketplaceVideoURL || '',
    )
    setPreApprovedURL(props.marketPlace.preApprovedURL || '')
  }
  const handleCloseAddMarketplace = () => {
    setAddMarketplace(false)
    setLoader(false)
  }

  const { marketPlaces = [], _id } = props.session.me.activeOrg

  const newMarketPlaces = map(marketPlaces, (marketPlace) => {
    return {
      name:
        marketPlace.url === props.marketPlace.url
          ? name
          : marketPlace.name,
      url: marketPlace.url,
      meetingURL:
        marketPlace.url === props.marketPlace.url
          ? meetingUrl
          : marketPlace.meetingURL,
      contactEmail:
        marketPlace.url === props.marketPlace.url
          ? contactEmail
          : marketPlace.contactEmail,
      googleAnalytics:
        marketPlace.url === props.marketPlace.url
          ? googleAnalytics
          : marketPlace.googleAnalytics,
      marketplaceVideoURL:
        marketPlace.url === props.marketPlace.url
          ? marketplaceVideoURL
          : marketPlace.marketplaceVideoURL,
      preApprovedURL:
        marketPlace.url === props.marketPlace.url
          ? preApprovedURL
          : marketPlace.preApprovedURL,
      status: marketPlace.status || 'pending',
      featuredProperties: marketPlace.featuredProperties || [],
    }
  })

  const [{
    affiliate,
    urlAffiliate,
  }]=useAffiliate({})

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const inputFields = [
    {
      value: name,
      label: 'Marketplace Name',
      placeholder: 'Marketplace Name',
      set: setName,
      required: true,
      validators: ['required', 'maxStringLength: 50'],
      errorMessages: [
        'This field is required',
        'You cannot exceed 50 characters',
      ],
    },
    {
      value: props.marketPlace.url,
      label: 'Marketplace URL',
      placeholder: 'Marketplace URL',
      helperText:
        'An external URL that displays your marketplace properties',
      disabled: true,
      // validators: [
      //   'required',
      //   'maxStringLength: 50',
      //   'matchRegexp:^[A-z0-9]+$',
      // ],
      errorMessages: [
        'This field is required',
        'You cannot exceed 50 characters',
        'This field only allows alphanumeric characters',
      ],
      // endAdornment: {
      //   endAdornment: (
      //     <InputAdornment position="end">
      //       .housefolios.com
      //       </InputAdornment>
      //   ),
      // }
    },
    {
      value: meetingUrl,
      label: 'Meeting URL',
      placeholder: 'Meeting URL',
      set: setMeetingUrl,
      helperText:
        '(optional) /Two popular options are Calendly and Hubspot',
      required: false,
      validators: ['maxStringLength: 100', 'matchRegexp:^[^ ]+$'],
      errorMessages: [
        'You cannot exceed 100 characters',
        'This field does not accept spaces',
      ],
    },
    {
      value: contactEmail,
      label: 'Contact Email',
      placeholder: 'Contact Email',
      set: setContactEmail,
      helpertText:
        'Who do you want contacted when somebody is interested?',
      required: true,
      validators: ['required', 'isEmail'],
      errorMessages: ['This field is required', 'Not a valid Email'],
    },
    {
      value: googleAnalytics,
      label: 'Google Analytics',
      placeholder: 'Google Analytics',
      set: setGoogleAnalytics,
      helperText: 'A URL to send analytic information to.',
      required: false,
      validators: ['maxStringLength: 100', 'matchRegexp:^[^ ]+$'],
      errorMessages: [
        'You cannot exceed 100 characters',
        'This field does not accept spaces',
      ],
    },
    {
      value: marketplaceVideoURL,
      label: 'Embedded Video URL',
      placeholder: 'Embedded Video URL',
      set: setMarketplaceVideoURL,
      helperText:
        'An embedded url of the video you want playing on your marketplace',
      required: false,
      validators: ['matchRegexp:^[^ ]+$'],
      errorMessages: ['This field does not accept spaces'],
    },
    {
      value: preApprovedURL,
      label: 'Pre Approved URL',
      placeholder: 'Pre Approved URL',
      set: setPreApprovedURL,
      helperText:
        'Where you want users to go when they click Get Pre Approved on the marketplace',
      required: false,
      validators: ['matchRegexp:^[^ ]+$'],
      errorMessages: ['This field does not accept spaces'],
    },
  ]

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const [loader, setLoader] = useState(false)

  return (
    <>
      {listItem && (
        <>
          <MenuItem
            //component={Link}
            onClick={handleOpenAddMarketplace}
            button
          >
            <EditIcon style={{ marginRight: 5, color: '#757575' }} />
            Edit Marketplace
          </MenuItem>
        </>
      )}
      {/* {marketplaceSettings && (
        <Button
          variant="contained"
          onClick={marketPlaces.length >= marketplaceLimit ? () => props.toggleLimit('marketplace') : handleOpenAddMarketplace}
          size="small"
          style={{ backgroundColor: "#28b854" }}
          className="btn-primary d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3">
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon
              icon={['fas', 'plus']}
            //className="opacity-5"
            />
          </span>
          <span className="btn-wrapper--label" style={{ fontWeight: 600 }}>Create Marketplace</span>
        </Button>
      )}
      {title && (
        <Button
          variant="contained"
          className="btn-primary m-2 font-weight-bolder"
          onClick={marketPlaces.length >= marketplaceLimit ? () => props.toggleLimit('marketplace') : handleOpenAddMarketplace}
        >
          <FontAwesomeIcon
            icon={['fas', 'plus']}
            style={{ marginRight: 5 }}
          />
          Create Marketplace
        </Button>
      )} */}
      <Dialog
        open={openAddMarketplace}
        onClose={handleCloseAddMarketplace}
        fullWidth={true}
        maxWidth="sm"
        onKeyDown={stopPropagationForTab}
      >
        <DialogTitle>Edit Marketplace</DialogTitle>
        <ValidatorForm
          style={{
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={() => {
            setLoader(true)
            updateOrganization({
              variables: {
                id: _id,
                organizationInput: {
                  marketPlaces: newMarketPlaces,
                  //meetingUrl,
                },
              },
              refetchQueries: [
                {
                  query: GET_ME,
                },
              ],
            })
              .then(() => {
                enqueueSnackbar(`Marketplace Edited`, {
                  variant: 'success',
                  autoHideDuration: 3000,
                })
                setLoader(false)
                handleCloseAddMarketplace()
              })
              .catch((error) => {
                enqueueSnackbar(error.message, {
                  variant: 'error',
                  autoHideDuration: 3000,
                })
                setLoader(false)
              })
          }}
        >
          <DialogContent>
            <BlockUi
              blocking={loader}
              loader={
                <PacmanLoader
                  color={'var(--primary)'}
                  loading={true}
                />
              }
              message={
                <div className="text-primary">
                  Updating Marketplace
                </div>
              }
            >
              {/* <Card>
            <CardHeader title="Edit Marketplace" />
            <Divider /> */}
              {/* <CardContent> */}
              <Grid container>
                <Grid item>
                  <Typography>
                    You get your own marketplace website with {affiliate?.companyName || 'Housefolios'}. Please choose your URL. You can not
                    change this later. The website will be setup
                    within 1 business day.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                {map(inputFields, (section) => (
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      margin="dense"
                      fullWidth={true}
                      required={section.required}
                      value={section.value}
                      onChange={(event) =>
                        section.set(event.target.value)
                      }
                      label={section.label}
                      placeholder={section.placeholder}
                      helperText={section.helperText}
                      InputProps={section.endAdornment}
                      validators={section.validators}
                      errorMessages={section.errorMessages}
                      disabled={section.disabled}
                    />
                  </Grid>
                ))}
              </Grid>
              {/* </CardContent> */}
              {/* <Divider />
              <CardActions>
                <Typography style={{ flex: 1 }} />
                <Button variant='text' onClick={handleCloseAddMarketplace}>
                  Cancel
                </Button>
                <Button variant='contained' color='primary' type='submit'>
                  Save
                </Button>
              </CardActions> */}
              {/* </Card> */}
            </BlockUi>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={handleCloseAddMarketplace}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </>
  )
}

export default withSession(EditMarketplaceComponents)
