import React from 'react'
import { PageTitle } from '@/layout-components'
import { ExampleWrapperSeamless } from '@/layout-components'
import { Grid, Typography, Paper } from '@mui/material'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GET_TEAM } from '@/housefolios-components/Team/queries'
import HousefoliosUser from '@/assets/images/Housefolios_user.png'

const PublicTeamPage = (props) => {
  const { orgId } = props

  // Modern Apollo Client hook for querying data
  const { loading, error, data } = useQuery(GET_TEAM, {
    variables: { orgId },
  })

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  const { teamMembers = [] } = data

  return (
    <>
      <PageTitle
        titleHeading="Meet the Team"
        titleDescription="The team of experts that find the properties"
        icon={
          <FontAwesomeIcon
            icon={['far', 'user-group']}
            className="text-primary"
            style={{ fontSize: 30 }}
          />
        }
        style={{ margin: 0 }}
      />

      <ExampleWrapperSeamless>
        <Grid
          container
          style={{ paddingLeft: '30px', paddingRight: '30px' }}
        >
          {teamMembers.map((tm) => (
            <Paper
              elevation={3}
              style={{
                padding: '20px',
                backgroundColor: '#f5f5f5',
                marginBottom: '20px',
              }}
              key={tm.id}
            >
              <Grid
                container
                spacing={4}
                alignItems="center"
                style={{ padding: '20px' }}
                direction={{ xs: 'column', md: 'row' }} // Responsive layout
              >
                {/* Profile Image */}
                <Grid item xs={12} md={2}>
                  <div
                    className="blur-load"
                    style={{
                      backgroundImage: `url(${
                        tm.userImage
                          ? tm.userImage.url_small
                          : HousefoliosUser
                      })`,
                      borderRadius: '50%',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      margin: '0 auto',
                    }}
                  >
                    <img
                      src={
                        tm.userImage
                          ? tm.userImage.url
                          : HousefoliosUser
                      }
                      className="img img-responsive"
                      style={{ maxWidth: '300px', width: '100%' }} // Responsive width
                      alt="Team Member"
                    />
                  </div>
                </Grid>

                {/* Profile Information */}
                <Grid item xs={12} md={10}>
                  <Typography
                    variant="h5"
                    component="h2"
                    gutterBottom
                  >
                    {tm.profile
                      ? `${tm.profile.firstname} ${tm.profile.lastname}`
                      : 'No Name'}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ color: '#1fa7e8' }}
                  >
                    {tm.jobTitle || 'Job Title'}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ marginTop: '10px' }}
                  >
                    {tm.bioDescription || 'Bio Description'}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>
      </ExampleWrapperSeamless>
    </>
  )
}

export default PublicTeamPage
