import { jwtDecode } from 'jwt-decode'

export const saveAuthToken = (token, name, isJWT = true) => {
  if (isJWT) {
    const decodedToken = jwtDecode(token)
    const expiryTime = decodedToken.exp // JWT expiry time is in seconds
    localStorage.setItem(`token${name ?? ''}`, token)
    localStorage.setItem(
      `token${name ?? ''}Expiry`,
      expiryTime.toString(),
    )
  } else {
    localStorage.setItem(`token${name ?? ''}`, token)
  }
}

export const removeAuthToken = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('tokenExpiry')
}

export const getToken = (name) => localStorage.getItem(name)
