/**
 * @ Author: Housefolios
 * @ Create Time: 2022-07-26 09:32:35
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-06-13 19:48:31
 * @ Description: Dialog for apply for lending
 */

import React, { useEffect, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@mui/styles'

import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Step,
  StepConnector,
  StepIcon,
  StepLabel,
  Stepper,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'

import find from 'lodash/find'
import includes from 'lodash/includes'
import map from 'lodash/map'
import toString from 'lodash/toString'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'

import withSession from '@/housefolios-components/Session/withSession'

import { enqueueSnackbar } from 'notistack'
import { useQuery, useMutation } from '@apollo/client'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { NumericFormat } from 'react-number-format'
import RemoveOption from '../RemoveOption'
import AddOption from '../AddOption'
import FileUpload from '@/housefolios-components/PropertyContainer/PropertyDashboard/FileUpload'
import { APPLY_FOR_LENDING } from '../mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import DollarCloud from '@/assets/images/DollarCloud.png'
import { GET_TEAM } from '@/housefolios-components/Team/queries'
import AddUser from '@/housefolios-components/Team/AddUser'
import EditUser from '@/housefolios-components/Team/EditUser'
import {
  getStrategyName,
  getStrategyOptions,
} from '@/utils/stratagies'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(3),
    color: 'white',
    flex: 1,
  },
}))

function getSteps() {
  return ['Loan', 'Applicant', 'Documents']
}

function LendingDialog(props) {
  const classes = useStyles()
  const { property } = props
  const { activeOrg = {} } = props.session.me || {}

  const [loader, setLoader] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  const { loading, data, error } = useQuery(GET_TEAM)

  const { teamMembers = [] } = data || {}

  const [applyForLendingInput, setApplyForLendingInput] = useState(
    property.applyForLending
      ? cloneDeep(property.applyForLending)
      : {
          loanPurpose: property.strategy,
          propertyType: null,
          propertyUnits: 0,
          residenceType: 'Investment Property',
          estimatedValue: String(
            property.acquisition.analysis.afterRepairValue,
          ),
          loanAmount: null,
          rehabBudget: null,
          exitStrategy: null,
          termLength: null,
          entity: null,
          applicant: null,
          creditReport: null,
          loanApplication: null,
          purchaseAgreement: null,
          constructionBudget: null,
          scheduleOfRealEstate: null,
          personalFinancialStatement: null,
          mortgageStatement: null,
          valuationReport: null,
        },
  )

  const onChangeApplyForLending = (event) => {
    const { name, type, value } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(newValue)

    let newApplyForLendingInput = cloneDeep(applyForLendingInput)
    newApplyForLendingInput[name] = newValue
    if (name === 'applicant') {
      const applicantObject =
        find(teamMembers, {
          _id: toString(newApplyForLendingInput.applicant),
        }) || {}
      newApplyForLendingInput['creditReport'] =
        applicantObject.creditReport
      newApplyForLendingInput['scheduleOfRealEstate'] =
        applicantObject.scheduleOfRealEstate
      newApplyForLendingInput['personalFinancialStatement'] =
        applicantObject.personalFinancialStatement
    }
    setApplyForLendingInput(newApplyForLendingInput)
  }

  const uploadFile = (sectionName) => (files) => {
    let newApplyForLendingInput = cloneDeep(applyForLendingInput)
    let newFiles = []
    map(files, (file) => {
      newFiles.push(file)
    })
    set(newApplyForLendingInput, `${sectionName}`, newFiles)
    setApplyForLendingInput(newApplyForLendingInput)
  }

  const deleteFile = (sectionName) => () => {
    let newFiles = []
    let newApplyForLendingInput = cloneDeep(applyForLendingInput)
    set(newApplyForLendingInput, `${sectionName}`, newFiles)
    setApplyForLendingInput(newApplyForLendingInput)
  }

  const { entities = [] } = activeOrg

  const [getFinancing, setGetFinancing] = useState(false)
  const [getEdit, setGetEdit] = useState(false)

  const [applyForLending] = useMutation(APPLY_FOR_LENDING)
  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  useEffect(() => {
    if (
      applyForLendingInput.entity &&
      !find(entities, { value: applyForLendingInput.entity })
    ) {
      onChangeApplyForLending({
        target: { name: 'entity', value: null, type: 'text' },
      })
    }
    if (
      applyForLendingInput.applicant &&
      !isEmpty(teamMembers) &&
      !find(teamMembers, { _id: applyForLendingInput.applicant })
    ) {
      onChangeApplyForLending({
        target: { name: 'applicant', value: null, type: 'text' },
      })
    }
  }, [entities, teamMembers])

  const saveApplyForLending = (applyForLendingInput) => {
    //  updateProperty({
    //    variables: {
    //      id: property._id,
    //      propertyInput: {
    //        applyForLending: applyForLendingInput
    //      },
    //    },
    //    refetchQueries: [
    //      {
    //        query: GET_PROPERTY,
    //        variables: { id: property._id }
    //      }
    //    ]
    //  }).then(data => {
    //  })
  }

  const debouncedSaveApplyForLending = useRef(
    debounce(
      (applyForLendingInput) =>
        saveApplyForLending(applyForLendingInput),
      1000,
    ),
  ).current

  useEffect(() => {
    if (isEqual(applyForLendingInput, property.applyForLending))
      return
    debouncedSaveApplyForLending(applyForLendingInput)
    return () => debouncedSaveApplyForLending.cancel()
  }, [applyForLendingInput])

  const apply = async () => {
    setLoader(true)
    const {
      loanPurpose,
      propertyType,
      propertyUnits,
      residenceType,
      estimatedValue,
      loanAmount,
      rehabBudget,
      exitStrategy,
      termLength,
      entity,
      applicant,
      creditReport,
      loanApplication,
      purchaseAgreement,
      constructionBudget,
      scheduleOfRealEstate,
      personalFinancialStatement,
      mortgageStatement,
      valuationReport,
    } = applyForLendingInput
    applyForLending({
      variables: {
        propertyId: property._id,
        loanPurpose,
        propertyType,
        propertyUnits,
        residenceType,
        estimatedValue,
        loanAmount,
        rehabBudget,
        exitStrategy,
        termLength,
        entity,
        applicant,
        creditReport,
        loanApplication,
        purchaseAgreement,
        constructionBudget,
        scheduleOfRealEstate,
        personalFinancialStatement,
        mortgageStatement,
        valuationReport,
      },
      refetchQueries: [
        {
          query: GET_PROPERTY,
          variables: { id: property._id },
        },
      ],
    }).then(({ data }) => {
      enqueueSnackbar(`Message sent successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      })
      setLoader(false)
      toggleGetFinancing()
    })
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const toggleGetFinancing = () => {
    setGetFinancing(!getFinancing)
    setActiveStep(0)
    setLoader(false)
  }

  const toggleGetEdit = () => {
    setGetEdit(!getEdit)
  }

  function formatDate(date) {
    var today = new Date(date),
      d = new Date(
        today.getTime() + Math.abs(today.getTimezoneOffset() * 60000),
      ),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = '' + d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    while (year.length < 4) year = '0' + year

    return [year, month, day].join('-')
  }

  const applicantObject =
    find(teamMembers, { _id: applyForLendingInput.applicant }) || {}

  const applicantValid =
    applicantObject.DoB &&
    applicantObject.citizenship &&
    applicantObject.address &&
    applicantObject.city &&
    applicantObject.state &&
    applicantObject.zip &&
    applicantObject.FICO &&
    applicantObject.firstTimeInvestor &&
    applicantObject.yearsInvesting &&
    applicantObject.realEstateInvestments &&
    applicantObject.estimatedNetWorth &&
    applicantObject.estimatedLiquidAssets

  const isDisabled =
    (activeStep === 0 &&
      (!applyForLendingInput.loanPurpose ||
        !applyForLendingInput.propertyType ||
        ((applyForLendingInput.propertyType ===
          '2-4 Unit Investment' ||
          applyForLendingInput.propertyType ===
            '5+ Unit Multifamily') &&
          !applyForLendingInput.propertyUnits) ||
        !applyForLendingInput.residenceType ||
        !applyForLendingInput.estimatedValue ||
        !applyForLendingInput.loanAmount ||
        !applyForLendingInput.rehabBudget ||
        !applyForLendingInput.exitStrategy ||
        !applyForLendingInput.termLength ||
        !applyForLendingInput.entity)) ||
    (activeStep === 1 &&
      (!applyForLendingInput.applicant || !applicantValid))

  const orgRole = !props.shared
    ? find(props.session.me.roles, [
        'organization._id',
        props.session.me.activeOrg._id,
      ])
    : null

  const isAdmin =
    !props.shared &&
    (orgRole.role[0] === 'admin' ||
      props.session.me._id ===
        props.session.me.activeOrg.createdBy._id)

  return (
    <>
      {(!activeOrg.member ||
        activeOrg.member === 'paulmccomas' ||
        activeOrg.member === 'roitk' ||
        activeOrg.member === 'dbi') && (
        <Grid container style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <Button
              fullWidth={true}
              variant="outlined"
              color="primary"
              style={{ backgroundColor: '#06BA4B', color: 'white' }}
              classes={{ paper: 'shadow-lg rounded' }}
              onClick={
                property.financingProformaSent
                  ? toggleGetEdit
                  : toggleGetFinancing
              }
              data-tour="applyForLendingButton"
              disabled={props.shared || props.canDrag}
            >
              <Typography>
                {property.financingProformaSent
                  ? 'APPLICATION SENT'
                  : 'APPLY FOR LENDING'}
                {!property.financingProformaSent && (
                  <img src={DollarCloud} style={{ width: '10%' }} />
                )}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
      <Dialog open={getEdit} onClose={toggleGetEdit}>
        <DialogTitle>Edit Application</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have already submitted this property, would you like
            to edit the application and resubmit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Typography style={{ flex: 1 }} />
          <Button variant="outlined" onClick={toggleGetEdit}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              toggleGetEdit()
              toggleGetFinancing()
            }}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={getFinancing}
        onClose={toggleGetFinancing}
        fullScreen
      >
        <AppBar enableColorOnDark className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Apply for Lending
            </Typography>
            <Button
              variant="outlined"
              margin="dense"
              style={{ color: 'white', borderColor: 'white' }}
              onClick={toggleGetFinancing}
            >
              Cancel
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 100,
              paddingLeft: 30,
              paddingRight: 30,
              marginBottom: 50,
            }}
          >
            <Card>
              {/* <DialogTitle>Apply For Lending</DialogTitle> */}
              <BlockUi
                blocking={loader}
                loader={
                  <PacmanLoader
                    color={'var(--primary)'}
                    loading={true}
                  />
                }
                message={
                  <div className="text-primary">Sending Message</div>
                }
              >
                <ValidatorForm>
                  <Card className="card-box">
                    <div className="card-header">
                      <div className="card-header--title">
                        <Typography variant="h6">
                          Lending Wizard
                        </Typography>
                      </div>
                    </div>
                    <div>
                      <div className="bg-secondary mb-3">
                        <Stepper
                          style={{ padding: 24 }}
                          className="stepper-horizontal-1"
                          alternativeLabel
                          activeStep={activeStep}
                          connector={<StepConnector />}
                        >
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel StepIconComponent={StepIcon}>
                                {label}
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </div>
                      <span>
                        <div
                          style={{
                            height: window.innerHeight - 400,
                            overflow: 'scroll',
                          }}
                        >
                          {activeStep === 0 && (
                            <Container>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                style={{ marginTop: 25 }}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    variant="h5"
                                    style={{ fontWeight: 600 }}
                                  >
                                    Loan Details
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Please fill out the fields below
                                    to continue.
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    name="loanPurpose"
                                    fullWidth
                                    select
                                    variant="outlined"
                                    label="Loan Purpose"
                                    value={
                                      applyForLendingInput.loanPurpose
                                    }
                                    onChange={onChangeApplyForLending}
                                    required={true}
                                  >
                                    {getStrategyOptions(
                                      props?.session?.me?.activeOrg
                                        ?.member,
                                    )}
                                  </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    name="propertyType"
                                    fullWidth
                                    select
                                    variant="outlined"
                                    label="Property Type"
                                    value={
                                      applyForLendingInput.propertyType
                                    }
                                    onChange={onChangeApplyForLending}
                                    required={true}
                                  >
                                    <MenuItem
                                      key={'Single-Family Residence'}
                                      value={
                                        'Single-Family Residence'
                                      }
                                    >
                                      {'Single-Family Residence'}
                                    </MenuItem>
                                    <MenuItem
                                      key={
                                        'Single-Family Condominium'
                                      }
                                      value={
                                        'Single-Family Condominium'
                                      }
                                    >
                                      {'Single-Family Condominium'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'Single-Family PUD'}
                                      value={'Single-Family PUD'}
                                    >
                                      {'Single-Family PUD'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'2-4 Unit Investment'}
                                      value={'2-4 Unit Investment'}
                                    >
                                      {'2-4 Unit Investment'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'5+ Unit Multifamily'}
                                      value={'5+ Unit Multifamily'}
                                    >
                                      {'5+ Unit Multifamily'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'Mixed Use'}
                                      value={'Mixed Use'}
                                    >
                                      {'Mixed Use'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'Mobile Home Park'}
                                      value={'Mobile Home Park'}
                                    >
                                      {'Mobile Home Park'}
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                                {(applyForLendingInput.propertyType ===
                                  '2-4 Unit Investment' ||
                                  applyForLendingInput.propertyType ===
                                    '5+ Unit Multifamily') && (
                                  <Grid item xs={6}>
                                    <TextField
                                      name="propertyUnits"
                                      fullWidth
                                      type="number"
                                      variant="outlined"
                                      label="# of Units"
                                      margin="dense"
                                      value={
                                        applyForLendingInput.propertyUnits
                                      }
                                      onChange={
                                        onChangeApplyForLending
                                      }
                                      required={true}
                                    />
                                  </Grid>
                                )}
                                <Grid item xs={6}>
                                  <TextField
                                    name="residenceType"
                                    fullWidth
                                    select
                                    variant="outlined"
                                    margin="dense"
                                    label="Residence Type"
                                    value={
                                      applyForLendingInput.residenceType
                                    }
                                    onChange={onChangeApplyForLending}
                                    required={true}
                                  >
                                    <MenuItem
                                      key={'Primary'}
                                      value={'Primary'}
                                    >
                                      {'Primary'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'Investment Property'}
                                      value={'Investment Property'}
                                    >
                                      {'Investment Property'}
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                  <NumericFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    fullWidth={true}
                                    isNumericString={true}
                                    decimalScale={0}
                                    key={'estimatedValue'}
                                    name={'estimatedValue'}
                                    label="Current Estimate Property Value"
                                    value={
                                      Math.round(
                                        applyForLendingInput.estimatedValue,
                                      ) || ''
                                    }
                                    onValueChange={(values) => {
                                      const { value } = values
                                      const event = {
                                        target: {
                                          name: 'estimatedValue',
                                          value,
                                        },
                                      }
                                      onChangeApplyForLending(event)
                                    }}
                                    type={'text'}
                                    onFocus={(event) => {
                                      event.target.select()
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <NumericFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    fullWidth={true}
                                    isNumericString={true}
                                    decimalScale={0}
                                    key={'loanAmount'}
                                    name={'loanAmount'}
                                    label="Requested Loan Amount"
                                    value={
                                      Math.round(
                                        applyForLendingInput.loanAmount,
                                      ) || ''
                                    }
                                    onValueChange={(values) => {
                                      const { value } = values
                                      const event = {
                                        target: {
                                          name: 'loanAmount',
                                          value,
                                        },
                                      }
                                      onChangeApplyForLending(event)
                                    }}
                                    type={'text'}
                                    onFocus={(event) => {
                                      event.target.select()
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <NumericFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    suffix={'%'}
                                    fullWidth={true}
                                    isNumericString={true}
                                    decimalScale={0}
                                    key={'ltv'}
                                    name={'ltv'}
                                    label="LTV"
                                    value={
                                      applyForLendingInput.loanAmount
                                        ? (applyForLendingInput.estimatedValue /
                                            applyForLendingInput.loanAmount) *
                                          100
                                        : 0
                                    }
                                    type={'text'}
                                    variant="outlined"
                                    margin="dense"
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <NumericFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    fullWidth={true}
                                    isNumericString={true}
                                    decimalScale={0}
                                    key={'rehabBudget'}
                                    name={'rehabBudget'}
                                    label="Rehab Budget"
                                    value={
                                      Math.round(
                                        applyForLendingInput.rehabBudget,
                                      ) || ''
                                    }
                                    onValueChange={(values) => {
                                      const { value } = values
                                      const event = {
                                        target: {
                                          name: 'rehabBudget',
                                          value,
                                        },
                                      }
                                      onChangeApplyForLending(event)
                                    }}
                                    type={'text'}
                                    onFocus={(event) => {
                                      event.target.select()
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    name="exitStrategy"
                                    fullWidth
                                    select
                                    variant="outlined"
                                    margin="dense"
                                    label="Exit Strategy"
                                    value={
                                      applyForLendingInput.exitStrategy
                                    }
                                    onChange={onChangeApplyForLending}
                                    required={true}
                                  >
                                    <MenuItem
                                      key={'Refinance'}
                                      value={'Refinance'}
                                    >
                                      {'Refinance'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'Sell'}
                                      value={'Sell'}
                                    >
                                      {'Sell'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'Hold'}
                                      value={'Hold'}
                                    >
                                      {'Hold'}
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    name="termLength"
                                    fullWidth
                                    select
                                    variant="outlined"
                                    margin="dense"
                                    label="Term Length"
                                    value={
                                      applyForLendingInput.termLength
                                    }
                                    onChange={onChangeApplyForLending}
                                    required={true}
                                  >
                                    <MenuItem
                                      key={'12 months'}
                                      value={'12 months'}
                                    >
                                      {'12 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'18 months'}
                                      value={'18 months'}
                                    >
                                      {'18 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'24 months'}
                                      value={'24 months'}
                                    >
                                      {'24 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'30 months'}
                                      value={'30 months'}
                                    >
                                      {'30 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'36 months'}
                                      value={'36 months'}
                                    >
                                      {'36 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'42 months'}
                                      value={'42 months'}
                                    >
                                      {'42 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'48 months'}
                                      value={'48 months'}
                                    >
                                      {'48 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'54 months'}
                                      value={'54 months'}
                                    >
                                      {'54 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'60 months'}
                                      value={'60 months'}
                                    >
                                      {'60 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'120 months'}
                                      value={'120 months'}
                                    >
                                      {'120 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'180 months'}
                                      value={'180 months'}
                                    >
                                      {'180 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'240 months'}
                                      value={'240 months'}
                                    >
                                      {'240 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'300 months'}
                                      value={'300 months'}
                                    >
                                      {'300 months'}
                                    </MenuItem>
                                    <MenuItem
                                      key={'360 months'}
                                      value={'360 months'}
                                    >
                                      {'360 months'}
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    name="entity"
                                    select
                                    fullWidth={true}
                                    variant="outlined"
                                    margin="dense"
                                    label="Entity"
                                    value={
                                      includes(
                                        entities,
                                        applyForLendingInput.entity,
                                      ) ||
                                      find(entities, {
                                        value:
                                          applyForLendingInput.entity,
                                      })
                                        ? applyForLendingInput.entity
                                        : ''
                                    }
                                    required
                                    onChange={onChangeApplyForLending}
                                    SelectProps={{
                                      renderValue: (x) => {
                                        let option = find(entities, {
                                          value: x,
                                        })
                                        if (option)
                                          return option.label
                                        else if (
                                          includes(entities, x)
                                        )
                                          return x
                                        else return null
                                      },
                                    }}
                                  >
                                    <MenuItem key="" value="">
                                      No Selected Value
                                    </MenuItem>
                                    {map(entities, (option) =>
                                      option.value ? (
                                        option.tip ? (
                                          <Tooltip
                                            title={option.tip}
                                            key={option.value}
                                            value={option.value}
                                          >
                                            <MenuItem>
                                              <Grid
                                                container
                                                justifyContent="space-between"
                                              >
                                                <Grid item>
                                                  <Typography>
                                                    {option.title}
                                                  </Typography>
                                                </Grid>
                                                <Grid item>
                                                  <Typography>
                                                    {option.label}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </MenuItem>
                                          </Tooltip>
                                        ) : (
                                          <MenuItem
                                            key={option.value}
                                            value={option.value}
                                          >
                                            <Grid
                                              container
                                              alignItems="center"
                                            >
                                              <Grid item>
                                                {option.label}
                                              </Grid>
                                              <Typography
                                                style={{ flex: 1 }}
                                              />
                                              <RemoveOption
                                                removeOption={
                                                  'entities'
                                                }
                                                removeOptionLabel={
                                                  'Entity'
                                                }
                                                removeOptionValue={
                                                  option.value
                                                }
                                                removeOptionItemLabel={
                                                  option.label
                                                }
                                              />
                                            </Grid>
                                          </MenuItem>
                                        )
                                      ) : (
                                        <MenuItem
                                          key={option}
                                          value={option}
                                        >
                                          <Grid
                                            container
                                            alignItems="center"
                                          >
                                            <Grid item>{option}</Grid>
                                            <Typography
                                              style={{ flex: 1 }}
                                            />
                                            <RemoveOption
                                              removeOption={
                                                'entities'
                                              }
                                              removeOptionLabel={
                                                'Entity'
                                              }
                                              removeOptionValue={
                                                option
                                              }
                                            />
                                          </Grid>
                                        </MenuItem>
                                      ),
                                    )}
                                    <AddOption
                                      currentValue={
                                        find(entities, {
                                          value:
                                            applyForLendingInput.entity,
                                        })
                                          ? applyForLendingInput.entity
                                          : ''
                                      }
                                      addOption={'entities'}
                                      addOptionLabel={'Entity'}
                                      addOptionTarget={'entity'}
                                      onChangeProperty={
                                        onChangeApplyForLending
                                      }
                                    />
                                  </TextField>
                                </Grid>
                                {applyForLendingInput.entity && (
                                  <Grid
                                    item
                                    xs={6}
                                    container
                                    direction="column"
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-between"
                                      alignItems="center"
                                      style={{ height: 70 }}
                                    >
                                      <Grid item xs={6}>
                                        <Grid
                                          container
                                          direction="column"
                                        >
                                          <Grid item>
                                            <Typography
                                              variant="h6"
                                              style={{
                                                fontWeight: 600,
                                              }}
                                            >
                                              Entity Name
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            style={{ marginTop: -5 }}
                                          >
                                            <Typography
                                              variant="subtitle2"
                                              style={{
                                                color: '#9198a8',
                                              }}
                                            >
                                              Entity Type
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Grid
                                          container
                                          direction="column"
                                        >
                                          <Grid item>
                                            <Typography
                                              variant="h6"
                                              style={{
                                                textAlign: 'right',
                                                fontWeight: 600,
                                              }}
                                            >
                                              {
                                                (
                                                  find(entities, {
                                                    value:
                                                      applyForLendingInput.entity,
                                                  }) || {}
                                                ).label
                                              }
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            style={{ marginTop: -5 }}
                                          >
                                            <Typography
                                              variant="subtitle2"
                                              style={{
                                                textAlign: 'right',
                                                color: '#9198a8',
                                              }}
                                            >
                                              {
                                                (
                                                  find(entities, {
                                                    value:
                                                      applyForLendingInput.entity,
                                                  }) || {}
                                                ).type
                                              }
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            </Container>
                          )}
                          {activeStep === 1 && (
                            <Container>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                style={{ marginTop: 25 }}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    variant="h5"
                                    style={{ fontWeight: 600 }}
                                  >
                                    Applicant Information
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Please fill out the fields below
                                    to continue.
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    name="applicant"
                                    select
                                    fullWidth={true}
                                    variant="outlined"
                                    margin="dense"
                                    label="Applicant"
                                    value={
                                      includes(
                                        teamMembers,
                                        applyForLendingInput.applicant,
                                      ) ||
                                      find(teamMembers, {
                                        _id: applyForLendingInput.applicant,
                                      })
                                        ? applyForLendingInput.applicant
                                        : ''
                                    }
                                    required
                                    onChange={onChangeApplyForLending}
                                    SelectProps={{
                                      renderValue: (x) => {
                                        let option = find(
                                          teamMembers,
                                          { _id: x },
                                        )
                                        if (option && option.profile)
                                          return (
                                            option.profile.firstname +
                                            ' ' +
                                            option.profile.lastname
                                          )
                                        else if (
                                          includes(teamMembers, x)
                                        )
                                          return x
                                        else return '-'
                                      },
                                    }}
                                  >
                                    <MenuItem key="" value="">
                                      No Selected Value
                                    </MenuItem>
                                    {map(teamMembers, (option) => {
                                      const { roles } = option
                                      const includesOrg = find(
                                        roles,
                                        [
                                          'organization._id',
                                          props.session.me.activeOrg
                                            ._id,
                                        ],
                                      )
                                      return option.tip ? (
                                        <Tooltip
                                          title={option.tip}
                                          key={option._id}
                                          value={option._id}
                                        >
                                          <MenuItem>
                                            <Grid
                                              container
                                              justifyContent="space-between"
                                            >
                                              <Grid item>
                                                <Typography>
                                                  {option.title}
                                                </Typography>
                                              </Grid>
                                              <Grid item>
                                                <Typography>
                                                  {option.profile
                                                    ? option.profile
                                                        .firstname +
                                                      ' ' +
                                                      option.profile
                                                        .lastname
                                                    : '-'}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </MenuItem>
                                        </Tooltip>
                                      ) : (
                                        <MenuItem
                                          key={option._id}
                                          value={option._id}
                                        >
                                          <Grid
                                            container
                                            alignItems="center"
                                          >
                                            <Grid item>
                                              {option.profile
                                                ? option.profile
                                                    .firstname +
                                                  ' ' +
                                                  option.profile
                                                    .lastname
                                                : '-'}
                                            </Grid>
                                            <Typography
                                              style={{ flex: 1 }}
                                            />
                                            {includesOrg &&
                                              includesOrg.role[0] !==
                                                'deactive' && (
                                                <EditUser
                                                  lending
                                                  user={option}
                                                />
                                              )}
                                          </Grid>
                                        </MenuItem>
                                      )
                                    })}
                                    {isAdmin && (
                                      <AddUser
                                        menu={true}
                                        currentValue={
                                          find(teamMembers, {
                                            _id: applyForLendingInput.applicant,
                                          })
                                            ? applyForLendingInput.applicant
                                            : ''
                                        }
                                      />
                                    )}
                                  </TextField>
                                </Grid>
                                {applyForLendingInput.applicant && (
                                  <>
                                    <Grid
                                      item
                                      xs={6}
                                      container
                                      direction="column"
                                    >
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="h6"
                                            style={{
                                              fontWeight: 600,
                                            }}
                                          >
                                            Applicant Name
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="h6"
                                            style={{
                                              textAlign: 'right',
                                              fontWeight: 600,
                                            }}
                                          >
                                            {applicantObject.profile
                                              ? applicantObject
                                                  .profile.firstname +
                                                ' ' +
                                                applicantObject
                                                  .profile.lastname
                                              : '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            Applicant DoB
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {formatDate(
                                              applicantObject.DoB,
                                            ) || '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            Citizenship
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {applicantObject.citizenship ||
                                              '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            Address
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {applicantObject.address ||
                                              '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            Address Line 2
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {applicantObject.address2 ||
                                              '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            City
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {applicantObject.city ||
                                              '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            State
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {applicantObject.state ||
                                              '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            Zip
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {applicantObject.zip ||
                                              '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      container
                                      direction="column"
                                    >
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="h6"
                                            style={{
                                              fontWeight: 600,
                                            }}
                                          >
                                            Applicant Name
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="h6"
                                            style={{
                                              textAlign: 'right',
                                              fontWeight: 600,
                                            }}
                                          >
                                            {applicantObject.profile
                                              ? applicantObject
                                                  .profile.firstname +
                                                ' ' +
                                                applicantObject
                                                  .profile.lastname
                                              : '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            FICO
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {applicantObject.FICO ||
                                              '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            Is First Time Investor
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {applicantObject.firstTimeInvestor ||
                                              '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            Years of Experience
                                            Investing
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {applicantObject.yearsInvesting ||
                                              '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            # of Real Estate
                                            Investments in the last 12
                                            months
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {applicantObject.realEstateInvestments ||
                                              '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            Estimated Net Worth
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {applicantObject.estimatedNetWorth ||
                                              '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{ marginTop: -5 }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              color: '#9198a8',
                                            }}
                                          >
                                            Estimated Liquid Assets
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="subtitle2"
                                            style={{
                                              textAlign: 'right',
                                              color: '#9198a8',
                                            }}
                                          >
                                            {applicantObject.estimatedLiquidAssets ||
                                              '-'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    {!applicantValid && (
                                      <Grid item xs={12}>
                                        <Typography>
                                          *This applicant does not
                                          have all required fields
                                          filled out
                                        </Typography>
                                      </Grid>
                                    )}
                                  </>
                                )}
                              </Grid>
                            </Container>
                          )}
                          {activeStep === 2 && (
                            <Container>
                              <Grid cointainer>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="h5"
                                    style={{ fontWeight: 600 }}
                                  >
                                    Documents
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle1">
                                    Please add files below to
                                    continue.
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    Submit Credit Report
                                  </Typography>
                                  <FileUpload
                                    documentId={property._id}
                                    files={
                                      applyForLendingInput.creditReport
                                    }
                                    uploadFile={uploadFile(
                                      'creditReport',
                                    )}
                                    deleteFile={deleteFile(
                                      'creditReport',
                                    )}
                                    collection="Lending"
                                    button
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    Loan Application (1031
                                    Application)
                                  </Typography>
                                  <FileUpload
                                    documentId={property._id}
                                    files={
                                      applyForLendingInput.loanApplication
                                    }
                                    uploadFile={uploadFile(
                                      'loanApplication',
                                    )}
                                    deleteFile={deleteFile(
                                      'loanApplication',
                                    )}
                                    collection="Lending"
                                    button
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    Purchase Agreement (if applicable)
                                  </Typography>
                                  <FileUpload
                                    documentId={property._id}
                                    files={
                                      applyForLendingInput.purchaseAgreement
                                    }
                                    uploadFile={uploadFile(
                                      'purchaseAgreement',
                                    )}
                                    deleteFile={deleteFile(
                                      'purchaseAgreement',
                                    )}
                                    collection="Lending"
                                    button
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    Construction Budget
                                  </Typography>
                                  <FileUpload
                                    documentId={property._id}
                                    files={
                                      applyForLendingInput.constructionBudget
                                    }
                                    uploadFile={uploadFile(
                                      'constructionBudget',
                                    )}
                                    deleteFile={deleteFile(
                                      'constructionBudget',
                                    )}
                                    collection="Lending"
                                    button
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    Schedule of Real Estate Owned
                                  </Typography>
                                  <FileUpload
                                    documentId={property._id}
                                    files={
                                      applyForLendingInput.scheduleOfRealEstate
                                    }
                                    uploadFile={uploadFile(
                                      'scheduleOfRealEstate',
                                    )}
                                    deleteFile={deleteFile(
                                      'scheduleOfRealEstate',
                                    )}
                                    collection="Lending"
                                    button
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    Personal Financial Statement
                                  </Typography>
                                  <FileUpload
                                    documentId={property._id}
                                    files={
                                      applyForLendingInput.personalFinancialStatement
                                    }
                                    uploadFile={uploadFile(
                                      'personalFinancialStatement',
                                    )}
                                    deleteFile={deleteFile(
                                      'personalFinancialStatement',
                                    )}
                                    collection="Lending"
                                    button
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    Mortgage Statement (for refinance)
                                  </Typography>
                                  <FileUpload
                                    documentId={property._id}
                                    files={
                                      applyForLendingInput.mortgageStatement
                                    }
                                    uploadFile={uploadFile(
                                      'mortgageStatement',
                                    )}
                                    deleteFile={deleteFile(
                                      'mortgageStatement',
                                    )}
                                    collection="Lending"
                                    button
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    Valuation Report (appraisal,
                                    comparable properties, AVM, etc.)
                                  </Typography>
                                  <FileUpload
                                    documentId={property._id}
                                    files={
                                      applyForLendingInput.valuationReport
                                    }
                                    uploadFile={uploadFile(
                                      'valuationReport',
                                    )}
                                    deleteFile={deleteFile(
                                      'valuationReport',
                                    )}
                                    collection="Lending"
                                    button
                                  />
                                </Grid>
                              </Grid>
                            </Container>
                          )}
                        </div>
                        <CardActions className="mt-2">
                          <Typography style={{ flex: 1 }} />
                          {/* {activeStep === 0 && (
                          <Button className="btn-primary font-weight-bold" onClick={toggleGetFinancing}>
                            Close
                          </Button>
                        )} */}
                          {activeStep !== 0 && (
                            <Button
                              className="btn-primary font-weight-bold"
                              onClick={handleBack}
                            >
                              Back
                            </Button>
                          )}
                          {activeStep !== 2 && (
                            <Button
                              disabled={isDisabled}
                              className="btn-primary font-weight-bold"
                              onClick={handleNext}
                            >
                              Next
                            </Button>
                          )}
                          {activeStep === 2 && (
                            <Button
                              className="btn-primary font-weight-bold"
                              type="submit"
                              onClick={apply}
                            >
                              Finish
                            </Button>
                          )}
                        </CardActions>
                        {/* </div> */}
                      </span>
                    </div>
                  </Card>
                </ValidatorForm>
              </BlockUi>
            </Card>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default withSession(LendingDialog)
