import { gql } from '@apollo/client'
export const GET_ZIP_BY_CITY_STATE = gql`
  query zipTableByCityState($city: [String]!, $state: String!) {
    zipTableByCityState(city: $city, state: $state) {
      zipcode
      statesLong
      statesShort
      cities
      county
      counties
      arvSqft
      arvBed1
      arvBed2
      arvBed3
      arvBed4
      arvBedPlus
      rentSqft
      rentBed1
      rentBed2
      rentBed3
      rentBed4
      rentBedPlus
      rentSqftBed1
      rentSqftBed2
      rentSqftBed3
      rentSqftBed4
      rentSqftBedPlus
      rentometerBed0
      rentometerBed1
      rentometerBed2
      rentometerBed3
      rentometerBed4
      rentometerLowBed0
      rentometerLowBed1
      rentometerLowBed2
      rentometerLowBed3
      rentometerLowBed4
      rentometerHighBed0
      rentometerHighBed1
      rentometerHighBed2
      rentometerHighBed3
      rentometerHighBed4
      listSqft
      taxesPer
    }
  }
`

export const GET_ZIP_BY_COUNTY_STATE = gql`
  query zipTableByCountyState($county: String!, $state: String!) {
    zipTableByCountyState(county: $county, state: $state) {
      zipcode
      statesLong
      statesShort
      cities
      county
      counties
      arvSqft
      arvBed1
      arvBed2
      arvBed3
      arvBed4
      arvBedPlus
      rentSqft
      rentBed1
      rentBed2
      rentBed3
      rentBed4
      rentBedPlus
      rentSqftBed1
      rentSqftBed2
      rentSqftBed3
      rentSqftBed4
      rentSqftBedPlus
      rentometerBed0
      rentometerBed1
      rentometerBed2
      rentometerBed3
      rentometerBed4
      rentometerLowBed0
      rentometerLowBed1
      rentometerLowBed2
      rentometerLowBed3
      rentometerLowBed4
      rentometerHighBed0
      rentometerHighBed1
      rentometerHighBed2
      rentometerHighBed3
      rentometerHighBed4
      listSqft
      taxesPer
    }
  }
`

export const GET_STATETABLE = gql`
  query states {
    states {
      state
      zips
      cities
      counties
    }
  }
`

export const GET_POPULAR_SEARCHES = gql`
  query popularSearches {
    popularSearches {
      state
      city
      zips
      county
      cities
    }
  }
`
export const CALL_REAPI = gql`
  query callREAPI($REAPIInput: REAPIInput!) {
    callREAPI(REAPIInput: $REAPIInput)
  }
`

export const CALL_REAPI_COUNT = gql`
  query callREAPICount($REAPIInput: REAPIInput!) {
    callREAPICount(REAPIInput: $REAPIInput)
  }
`

export const GET_REALEFLOW_LOGIN_TOKEN = gql`
  query getRealeflowLoginToken {
    getRealeflowLoginToken
  }
`
