import { gql } from '@apollo/client'

export const REQUEST_SHARED_PORTFOLIO = gql`
  mutation requestSharedPortfolio(
    $portfolioId: String!
    $notificationId: String!
  ) {
    requestSharedPortfolio(
      portfolioId: $portfolioId
      notificationId: $notificationId
    ) {
      _id
      orgId
      userId
      action
      actionDateTime
      old
      attachment
    }
  }
`

export const DENY_REQUEST_SHARED_PORTFOLIO = gql`
  mutation denyRequestSharedPortfolio(
    $portfolioId: String!
    $email: String!
    $notificationId: String
  ) {
    denyRequestSharedPortfolio(
      portfolioId: $portfolioId
      email: $email
      notificationId: $notificationId
    ) {
      _id
      orgId
      userId
      action
      actionDateTime
      old
      attachment
    }
  }
`

export const REQUEST_SHARED_MARKETPLACE = gql`
  mutation requestSharedMarketplace(
    $url: String!
    $notificationId: String!
  ) {
    requestSharedMarketplace(
      url: $url
      notificationId: $notificationId
    ) {
      _id
      orgId
      userId
      action
      actionDateTime
      old
      attachment
    }
  }
`

export const DENY_REQUEST_SHARED_MARKETPLACE = gql`
  mutation denyRequestSharedMarketplace(
    $url: String!
    $email: String!
    $notificationId: String
  ) {
    denyRequestSharedMarketplace(
      url: $url
      email: $email
      notificationId: $notificationId
    ) {
      _id
      orgId
      userId
      action
      actionDateTime
      old
      attachment
    }
  }
`

export const REQUEST_SHARED_PROPERTY = gql`
  mutation requestSharedProperty(
    $propertyId: String!
    $notificationId: String!
  ) {
    requestSharedProperty(
      propertyId: $propertyId
      notificationId: $notificationId
    ) {
      _id
      orgId
      userId
      action
      actionDateTime
      old
      attachment
    }
  }
`

export const DENY_REQUEST_SHARED_PROPERTY = gql`
  mutation denyRequestSharedProperty(
    $propertyId: String!
    $email: String!
    $notificationId: String
  ) {
    denyRequestSharedProperty(
      propertyId: $propertyId
      email: $email
      notificationId: $notificationId
    ) {
      _id
      orgId
      userId
      action
      actionDateTime
      old
      attachment
    }
  }
`

export const REQUEST_SHARED_PROPERTIES = gql`
  mutation requestSharedProperties(
    $propertyIds: [String]!
    $notificationId: String!
  ) {
    requestSharedProperties(
      propertyIds: $propertyIds
      notificationId: $notificationId
    ) {
      _id
      orgId
      userId
      action
      actionDateTime
      old
      attachment
    }
  }
`

export const DENY_REQUEST_SHARED_PROPERTIES = gql`
  mutation denyRequestSharedProperties(
    $propertyIds: [String]!
    $email: String!
    $notificationId: String
  ) {
    denyRequestSharedProperties(
      propertyIds: $propertyIds
      email: $email
      notificationId: $notificationId
    ) {
      _id
      orgId
      userId
      action
      actionDateTime
      old
      attachment
    }
  }
`

export const MARK_OLD = gql`
  mutation markOld($id: String!) {
    markOld(id: $id) {
      _id
      orgId
      userId
      action
      actionDateTime
      old
      attachment
    }
  }
`

export const MARK_OLD_MULTI = gql`
  mutation markOldMulti($ids: [String]!) {
    markOldMulti(ids: $ids) {
      _id
      orgId
      userId
      action
      actionDateTime
      old
      attachment
    }
  }
`
