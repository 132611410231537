import { useQuery } from '@apollo/client'
import { GET_REALEFLOW_LOGIN_TOKEN } from '@/housefolios-components/PropertySearch/queries'
import React, { useState, useEffect } from 'react'
import {
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Grid2,
  Paper,
} from '@mui/material'
import withSession from '../Session/withSession'
import includes from 'lodash/includes'
import get from 'lodash/get'
import useAffiliate from '@/hooks/useAffiliate'

function RealeflowRedirect(props) {
  const [leadType, setLeadType] = useState('Leads')

  //COMMENTED OUT BECAUSE OF ISSUES WITH LOCALSTORAGE
  // const [leadType, setLeadType] = useState(
  //   localStorage.getItem('LeadType') || 'Leads',
  // )
  const [hasLoggedInWithToken, setHasLoggedInWithToken] =
    useState(false)
  const [loginCompleted, setLoginCompleted] = useState(false)
  const [iframeSrc, setIframeSrc] = useState('')
  const [availableOptions, setAvailableOptions] = useState([])

  const { loading, error, data, refetch } = useQuery(
    GET_REALEFLOW_LOGIN_TOKEN,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    },
  )

  const [{ affiliate, affiliateLoading, urlAffiliate }] =
    useAffiliate({
      memberValue: props?.session?.me?.activeOrg?.member,
    })

  // Effect for initial token login
  useEffect(() => {
    if (data?.getRealeflowLoginToken && !hasLoggedInWithToken) {
      const { UserId, Token } = data?.getRealeflowLoginToken
      const activeOrg = props?.session?.me?.activeOrg || {}
      const realeflowURL =
        activeOrg?.realeflowURL || 'search.propertyedge.pro'

      const logonWithTokenUrl = `https://${realeflowURL}/Account/Account/LogOnWithToken?id=${encodeURIComponent(
        UserId,
      )}&token=${encodeURIComponent(Token)}`

      // Set iframeSrc to token login URL
      setIframeSrc(logonWithTokenUrl)
      setHasLoggedInWithToken(true)

      // Set availableOptions based on AccountDetails
      const accountDetails =
        data?.getRealeflowLoginToken?.AccountDetails || {}

      // 'Leads' is always available
      const options = [{ value: 'Leads', label: 'Leads' }]

      if (accountDetails?.HasCommercialLeads) {
        options.push({
          value: 'Commercial Leads',
          label: 'Commercial Leads',
        })
      }
      if (accountDetails?.HasResidentialInvestorLeads) {
        options.push({
          value: 'Find Fix & Flippers',
          label: 'Find Fix & Flippers',
        })
      }
      if (accountDetails?.HasCommercialInvestorLeads) {
        options.push({
          value: 'Commercial Investor Leads',
          label: 'Commercial Investor Leads',
        })
      }

      setAvailableOptions(options)

      // Set 'Leads' as default leadType if not already set
      // setLeadType('Leads')
      //COMMENTED OUT BECAUSE OF ISSUES WITH LOCALSTORAGE
      // setLeadType(localStorage.getItem('LeadType') || 'Leads')
    }
  }, [data, hasLoggedInWithToken])

  // Effect to update iframeSrc after login completes
  useEffect(() => {
    if (loginCompleted) {
      const activeOrg = props?.session?.me?.activeOrg || {}
      const realeflowURL =
        activeOrg?.realeflowURL || 'search.propertyedge.pro'

      let leadsUrl = ''

      if (includes(document.referrer, realeflowURL)) {
        if (
          includes(
            document.referrer,
            '/Marketing/Leads/Index/Commercial',
          )
        ) {
          setLeadType('Commercial Leads')
          leadsUrl = `https://${realeflowURL}/Marketing/Leads/Index/Commercial`
        } else if (
          includes(document.referrer, '/Marketing/Leads/Investors')
        ) {
          setLeadType('Find Fix & Flippers')
          leadsUrl = `https://${realeflowURL}/Marketing/Leads/Investors`
        } else {
          leadsUrl = `https://${realeflowURL}/Marketing/Leads`
        }
      } else if (leadType === 'Leads') {
        //COMMENTED OUT BECAUSE OF ISSUES WITH LOCALSTORAGE
        // if (leadType === 'Leads') {
        leadsUrl = `https://${realeflowURL}/Marketing/Leads`
      } else if (leadType === 'Find Fix & Flippers') {
        leadsUrl = `https://${realeflowURL}/Marketing/Leads/Investors`
      } else if (leadType === 'Commercial Investor Leads') {
        leadsUrl = `https://${realeflowURL}/Marketing/Leads/Investors`
      } else if (leadType === 'Commercial Leads') {
        leadsUrl = `https://${realeflowURL}/Marketing/Leads/Index/Commercial`
      }

      // const queryString = window.location.search || ''
      // if (queryString) leadsUrl = leadsUrl + `#/search${queryString}`

      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.get('investorPortfolioHash')) {
        leadsUrl =
          leadsUrl +
          `/Index/Property#/search?investorPortfolioHash=${urlParams.get('investorPortfolioHash')}`

        // Append investorType if present in the URL
        if (urlParams.get('investorType')) {
          leadsUrl += `&investorType=${urlParams.get('investorType')}`
        }
      } else if (urlParams.get('propertyCompsHash')) {
        leadsUrl =
          leadsUrl +
          `/Index/Property#/property/${urlParams.get('propertyCompsHash')}`
      } else if (urlParams.get('investorHash')) {
        leadsUrl =
          leadsUrl +
          `/Index/Investors#/investor/${urlParams.get('investorHash')}`
      }

      //app.propertyedge.pro/investor-search?investorHash=HA7-ac379e2b69b0cf364d0ed93d4660625f6ceef225

      // new "Investor" tab link: https://app.propertyedge.pro/investor-search?investorHash={hash}
      // iframe src: https://search.propertyedge.pro/Marketing/Leads/Investors#/investor/{hash}

      // Comps-to-Property: https://app.propertyedge.pro/property-search?propertyCompsHash=HA7-f68d35c3785f9407a38c91b3ddd2875e3eae71cb
      // Property-to-Investor-Portfolio: https:///search.propertyedge.pro/Marketing/Leads/Index/Property#/search?investorPortfolioHash=HA7-c5d9ce2fc8533f8e9d673476e34b86fb55e5f236
      // You then use those to navigate the iframe to the expected page.
      // Comps-to-Property: https://{{domain}}/Marketing/Leads/Index/Property#/property/HA7-f68d35c3785f9407a38c91b3ddd2875e3eae71cb
      // Property-to-Investor-Portfolio: https://{{domain}}/Marketing/Leads#/search?investorPortfolioHash=HA7-328e83dab830f689764ab9660ffaae618846eae1
      console.log('leadsUrl', leadsUrl)
      setIframeSrc(leadsUrl)
    }
  }, [loginCompleted, leadType])

  const handleLeadChange = (event) => {
    setLeadType(event.target.value)
    setHasLoggedInWithToken(false)
    refetch()
  }

  if (loading)
    return (
      <CircularProgress
        size={80}
        style={{ marginLeft: '50%', marginRight: '50%' }}
      />
    )
  if (error) return <p>Error: {error.message}</p>

  return (
    <div
      style={{
        width: '100%',
        height: '90vh',
        border: 'none',
        alignContent: 'center',
        justifyContent: 'space-around',
      }}
    >
      {/* Dropdown for lead type selection */}
      {availableOptions.length > 1 && (
        <FormControl
          fullWidth
          variant="outlined"
          style={{ marginBottom: '16px' }}
        >
          <InputLabel id="lead-type-label">Lead Type</InputLabel>
          <Select
            labelId="lead-type-label"
            value={leadType}
            onChange={handleLeadChange}
            //COMMENTED OUT BECAUSE OF ISSUES WITH LOCALSTORAGE
            // onChange={(e) => {
            //   setLeadType(e.target.value)
            //   localStorage.setItem('LeadType', e.target.value)
            // }}
            label="Lead Type"
          >
            {availableOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* Iframe rendering */}
      {iframeSrc ? (
        <iframe
          src={iframeSrc}
          allow="geolocation 'src'"
          style={{ width: '100%', height: '100%', border: 'none' }}
          title="Property Search Tool"
          // sandbox="allow-scripts allow-same-origin"
          onLoad={() => {
            console.log('Iframe loaded successfully')
            if (!loginCompleted && hasLoggedInWithToken) {
              // Set loginCompleted to true after login iframe loads
              setLoginCompleted(true)
            }
          }}
          onError={() => console.log('Iframe failed to load')}
        ></iframe>
      ) : (
        <Grid2
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: '100vh',
            backgroundColor: '#f0f2f5', // Light gray background
            padding: 2,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              textAlign: 'center',
              maxWidth: 600,
              width: '100%',
            }}
          >
            <img
              alt="Housefolios"
              src={
                affiliate
                  ? get(
                      affiliate,
                      'sidebar.sidebarMemberFullImage.0.url',
                    ) || '/images/NewLogoBlue.png'
                  : '/images/NewLogoBlue.png'
              }
              style={{
                width: 300,
                marginBottom: 16,
                backgroundColor: '#3b3e66',
                borderRadius: 30,
                boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
              }}
            />
            <Typography variant="h5" gutterBottom>
              We Failed to load the property search
            </Typography>
            <Typography variant="body1">
              Please refresh to try again or contact support.
            </Typography>
          </Paper>
        </Grid2>
      )}
    </div>
  )
}

export default withSession(RealeflowRedirect)
