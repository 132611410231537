/**
 * @ Author: Housefolios
 * @ Create Time: 2022-01-04 14:26:48
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-27 11:18:13
 * @ Description: Button and dialogs for single lot add
 */

import React, { useState, useEffect, useRef } from 'react'
import map from 'lodash/map'
import split from 'lodash/split'
import isEmpty from 'lodash/isEmpty'
import truncate from 'lodash/truncate'
import padStart from 'lodash/padStart'
import head from 'lodash/head'
import find from 'lodash/find'
import unset from 'lodash/unset'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import reduce from 'lodash/reduce'
import uniqWith from 'lodash/uniqWith'
import maxBy from 'lodash/maxBy'
import isNil from 'lodash/isNil'
import isNaN from 'lodash/isNaN'
import toString from 'lodash/toString'
import get from 'lodash/get'
import set from 'lodash/set'
import debounce from 'lodash/debounce'
import last from 'lodash/last'
import pull from 'lodash/pull'
import isEqual from 'lodash/isEqual'
import remove from 'lodash/remove'
import every from 'lodash/every'

import moment from 'moment'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  Tooltip,
  Slider,
  Chip,
  List,
  FormGroup,
  Radio,
  RadioGroup,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import withSession from '@/housefolios-components/Session/withSession'
import { enqueueSnackbar } from 'notistack'
import { NumericFormat } from 'react-number-format'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { ApolloConsumer, useMutation, useQuery } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import Script from 'react-load-script'
import { PacmanLoader } from 'react-spinners'
import BlockUi from 'react-block-ui'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_ASSUMPTIONS } from '@/housefolios-components/AssumptionsSettings/quries'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { useStopwatch } from 'react-timer-hook'
import {
  GET_LOT,
  GET_LOTS,
  GET_LOT_PORTFOLIOS,
  GET_LOT_PROCESSES,
} from '../queries'
import { GET_ZIP_VALUE } from '@/housefolios-components/TitleButtons/AddPropertyButton/queries'
import { ADD_LOT, SAVE_LOT_PROCESSES, UPDATE_LOT } from '../mutations'
import Add from '@mui/icons-material/Add'
import { ADD_PROPERTY } from '@/housefolios-components/TitleButtons/AddPropertyButton/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import {
  SAVE_ANALYSIS,
  UPDATE_PROPERTY,
} from '@/housefolios-components/PropertyContainer/mutations'
import { SAVE_ASSUMPTIONS } from '@/housefolios-components/AssumptionsSettings/mutations'
import { Delete } from '@mui/icons-material'
import FileUpload from '@/housefolios-components/PropertyContainer/PropertyDashboard/FileUpload'
import Autocomplete from '@mui/material/Autocomplete'
import { STATES } from '@/constants/states'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import { DEFAULT_ESTIMATES } from '../../../constants/defaultValues'
import { sum } from 'lodash'
import RemoveOption from '@/housefolios-components/Dialogs/RemoveOption'
import AddOption from '@/housefolios-components/Dialogs/AddOption'
import renderInputFieldsFunction from '../../Functions/renderInput'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import { getPropertyData, getZipValues } from '@/utils/addProperty'
import { handlePlaceResult } from '@/utils/handlePlaceResult'
import { useNavigate } from 'react-router-dom'
import { statesShort } from '@/utils/states'

const hostname = import.meta.env.VITE_HOSTNAME
const apiKey = import.meta.env.VITE_HOUSEFOLIOS_APIKEY
const apiID = import.meta.env.VITE_HOUSEFOLIOS_APIID
const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY

const yesNo = [
  {
    value: 'no',
  },
  {
    value: 'yes',
  },
]

function AddLotButton(props) {
  const portfolio = localStorage.getItem('portfolio') || ''

  let autocomplete

  const { activeOrg } = props.session.me

  const [anchorEl, setAnchorEl] = useState(null)
  //const titleButtons = false
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(null)
  const [modal4, seModal4] = useState(false)
  const toggle4 = () => seModal4(!modal4)
  const [query, setQuery] = useState('')
  const [userListPrice, setUserListPrice] = useState(null)
  const [selectedPortfolio, setSelectedPortfolio] =
    useState(portfolio)
  const [openPortfolio, setOpenPortfolio] = useState(false)
  const [placeResult, setPlaceResult] = useState(null)

  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [county, setCounty] = useState('')
  const [zip, setZip] = useState('')
  const [beds, setBeds] = useState('')
  const [baths, setBaths] = useState('')
  const [sqft, setSqft] = useState('')
  const [year, setYear] = useState('')
  const [zpid, setZpid] = useState('')
  const [MLSID, setMLSID] = useState('')
  const [marketplaceDescription, setMarketplaceDescription] =
    useState('')
  const [newImages, setNewImages] = useState([])
  const [propertyData, setPropertyData] = useState(null)
  // const [analysis, setAnalysis] = useState({})
  // const [estimates, setEstimates] = useState({ ...DEFAULT_ESTIMATES })

  const [loader, setLoader] = useState(false)

  const [avm, setAVM] = useState(null)

  const { seconds, minutes, pause, reset } = useStopwatch({
    autoStart: false,
  })

  const [addLot] = useMutation(ADD_LOT)
  const [addProperty] = useMutation(ADD_PROPERTY)

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const handleClickOpen1 = () => {
    if (props.closeParent) props.closeParent()
    setOpen1(true)
  }
  const handleClose1 = () => {
    setOpen1(false)
  }

  const handleClickOpen2 = (id) => {
    if (props.closeParent) props.closeParent()
    setOpen2(id)
    setLotInput({})
    setAnalysisInput({})
    setAssumptionsInput({})
    setPropertyInput({})
  }
  const handleClose2 = () => {
    setOpen2(null)
  }

  const handleOpenPortfolio = () => {
    setOpenPortfolio(true)
  }
  const handleClosePortfolio = () => {
    setOpenPortfolio(false)
  }

  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    var options = {
      types: ['geocode'],
    } // To disable any eslint 'google not defined' error

    // Initialize Google Autocomplete
    /*global google*/ autocomplete =
      new google.maps.places.Autocomplete(
        document.getElementById('autocomplete'),
        options,
      )

    autocomplete.setFields(['address_component', 'geometry'])
    autocomplete.addListener('place_changed', () =>
      setPlaceResult(
        autocomplete.getPlace().address_components
          ? autocomplete.getPlace()
          : null,
      ),
    )
  }

  let navigate = useNavigate()

  const runQuery = async (client, portfolios, lotPortfolios) => {
    const { propertySearch, property = {} } = props
    setLoader(true)
    reset()
    let location = handlePlaceResult(placeResult, property)
    const { loc } = location

    const streetAddress = location.streetAddress
      ? location.streetAddress
      : property.address
        ? property.address
        : query

    const propertyDataInput = {
      single: true,
      formattedAddress: placeResult
        ? placeResult.formatted_address
        : `${streetAddress} ${location.locality}, ${
            location.administrative_area_level_1
          } ${padStart(
            truncate(location.postal_code, {
              length: 5,
              omission: '',
            }),
            5,
            '0',
          )}`,
    }

    let { propertyData, reapiPropertyData } = await getPropertyData(
      client,
      propertyDataInput,
    )

    const [zipValues] = await getZipValues(
      client,
      location.postal_code,
    )

    const finalAddress = propertyData
      ? head(propertyData.address) || streetAddress
      : streetAddress

    const address = finalAddress
    const city = propertyData
      ? head(propertyData.city) || location.locality
      : location.locality
    const state = propertyData
      ? head(propertyData.state) || zipValues
        ? zipValues.statesShort[0]
        : location.administrative_area_level_1
      : zipValues
        ? zipValues.statesShort[0]
        : location.administrative_area_level_1
    const county = zipValues ? zipValues.county : ''
    const zip = propertyData
      ? head(propertyData.zip) || location.postal_code
      : location.postal_code
    const beds =
      propertySearch && property.beds
        ? property.beds
        : propertyData
          ? maxBy(propertyData.beds, (n) => Number(n)) || ''
          : ''
    const baths =
      propertySearch && property.baths
        ? property.baths
        : propertyData
          ? maxBy(propertyData.baths, (n) => Number(n)) || ''
          : ''
    const sqft =
      propertySearch && property.sqft
        ? property.sqft
        : propertyData
          ? maxBy(propertyData.sqft, (n) => Number(n)) || ''
          : ''
    const year =
      propertySearch && property.yearBuilt
        ? property.yearBuilt
        : propertySearch && property.year
          ? property.year
          : propertyData
            ? head(propertyData.yearBuilt)
            : ''
    const MLSID =
      props.propertySearch && property.mlsID
        ? property.mlsID
        : propertyData
          ? head(propertyData.mlsID)
          : ''
    const marketplaceDescription =
      props.propertySearch && property.description
        ? property.description
        : propertyData
          ? head(propertyData.description)
          : ''
    //setNewImages(selectedImages)
    setPropertyData(propertyData)
    //setAnalysis(analysis)
    //setEstimates(newEstimates)

    // if (location.latitude && location.longitude)
    //   loc = { lat: location.latitude, lng: location.longitude }

    const assumptionsResult = await client.query({
      query: GET_ASSUMPTIONS,
      variables: {
        documentId: selectedPortfolio || props.portfolioId,
        documentType: 'portfolio',
      },
      errorPolicy: 'ignore',
    })
    let data = assumptionsResult.data
    let error = assumptionsResult.error
    let assumptions = error ? null : cloneDeep(data.assumptions)
    if (assumptions) {
      unset(assumptions, '_id')
      unset(assumptions, '__typename')
    }

    const newBeds = parseInt(beds)
    const newSqft = parseInt(sqft)

    let housefoliosARV = ''
    let housefoliosRent = ''
    let housefoliosListPrice = ''
    let housefoliosARVBySqft = ''
    let housefoliosARVByBed = ''
    let housefoliosRentBySqft = ''
    let housefoliosRentByBed = ''
    let housefoliosRentByBedAndSqft = ''
    let rentometer = ''
    let rentometerLow = ''
    let rentometerHigh = ''
    //let arvSource = ''
    if (zipValues) {
      if (newSqft) {
        housefoliosARVBySqft = zipValues.arvSqft * newSqft
        housefoliosRentBySqft = zipValues.rentSqft * newSqft
        housefoliosListPrice = zipValues.listSqft * newSqft
        //arvSource = `Housefolios-SQFT`
      }
      if (newBeds) {
        if (newBeds >= 5) {
          housefoliosARVByBed = zipValues.arvBedPlus
          housefoliosRentByBed = zipValues.rentBedPlus
          housefoliosRentByBedAndSqft =
            zipValues.rentSqftBedPlus * newSqft
        } else if (newBeds === 4) {
          housefoliosARVByBed = zipValues.arvBed4
          housefoliosRentByBed = zipValues.rentBed4
          housefoliosRentByBedAndSqft =
            zipValues.rentSqftBed4 * newSqft
          rentometer = zipValues.rentometerBed4
          rentometerLow = zipValues.rentometerLowBed4
          rentometerHigh = zipValues.rentometerHighBed4
        } else if (newBeds === 3) {
          housefoliosARVByBed = zipValues.arvBed3
          housefoliosRentByBed = zipValues.rentBed3
          housefoliosRentByBedAndSqft =
            zipValues.rentSqftBed3 * newSqft
          rentometer = zipValues.rentometerBed3
          rentometerLow = zipValues.rentometerLowBed3
          rentometerHigh = zipValues.rentometerHighBed3
        } else if (newBeds === 2) {
          housefoliosARVByBed = zipValues.arvBed2
          housefoliosRentByBed = zipValues.rentBed2
          housefoliosRentByBedAndSqft =
            zipValues.rentSqftBed2 * newSqft
          rentometer = zipValues.rentometerBed2
          rentometerLow = zipValues.rentometerLowBed2
          rentometerHigh = zipValues.rentometerHighBed2
        } else if (newBeds === 1) {
          housefoliosARVByBed = zipValues.arvBed1
          housefoliosRentByBed = zipValues.rentBed1
          housefoliosRentByBedAndSqft =
            zipValues.rentSqftBed1 * newSqft
          rentometer = zipValues.rentometerBed1
          rentometerLow = zipValues.rentometerLowBed1
          rentometerHigh = zipValues.rentometerHighBed1
        } else if (newBeds === 0) {
          rentometer = zipValues.rentometerBed0
          rentometerLow = zipValues.rentometerLowBed0
          rentometerHigh = zipValues.rentometerHighBed0
        }
      }

      housefoliosRent = housefoliosRentBySqft
      if (Number(housefoliosRent) < housefoliosRentByBed)
        housefoliosRent = housefoliosRentByBed
      if (Number(housefoliosRent) < housefoliosRentByBedAndSqft)
        housefoliosRent = housefoliosRentByBedAndSqft
      if (Number(housefoliosRent) < rentometerHigh)
        housefoliosRent = rentometerHigh

      housefoliosARV = housefoliosARVBySqft
      if (Number(housefoliosARV) < housefoliosARVByBed) {
        housefoliosARV = housefoliosARVByBed
        //arvSource = `Housefolios-Bed`
      }
    }

    if (propertyData) {
      if (head(propertyData.zestimate))
        if (
          Number(housefoliosARV) <
          Number(head(propertyData.zestimate).amount[0]._)
        ) {
          housefoliosARV = head(propertyData.zestimate).amount[0]._
          //arvSource = `Zillow`
        }
      if (propertyData.rentZestimate)
        if (!housefoliosRent)
          housefoliosRent = head(propertyData.rentZestimate).amount[0]
            ._
    }
    let estimatedListPrice = ''
    if (housefoliosARV && assumptions)
      estimatedListPrice =
        (housefoliosARV * assumptions.estimatedListPrice) / 100
    if (!housefoliosListPrice) {
      housefoliosListPrice = estimatedListPrice
    }

    if (userListPrice) housefoliosListPrice = userListPrice

    let preferences = merge(
      cloneDeep(DEFAULT_PREFERENCES),
      props?.session?.me?.activeOrg?.memberPreferences,
      props?.session?.me?.activeOrg?.preferences,
    )

    forEach(preferences, (preference, key) => {
      let newPreference = preference
      const newValues = filter(
        DEFAULT_PREFERENCES[key],
        (x) => !includes(newPreference, x),
      )
      newPreference = filter(newPreference, (x) =>
        includes(DEFAULT_PREFERENCES[key], x),
      )
      newPreference = concat(newPreference, newValues)
      preferences[key] = newPreference
    })

    const preferencesValues = {
      house: {
        listPrice: propertyData ? head(propertyData.price) : '',
      },
      housefoliosSqft: {
        listPrice: zipValues && sqft ? zipValues.listSqft * sqft : '',
      },
      housefoliosAssumption: {
        listPrice: estimatedListPrice,
      },
    }

    await addProperty({
      variables: {
        propertyInput: {
          portfolioId: head(portfolios)._id,
          address,
          city,
          state,
          county,
          zip: toString(zip),
          beds: parseInt(beds || 0),
          baths: parseFloat(baths || 0),
          sqft: parseInt(sqft || 0),
          year: parseInt(year || 0),
          description: marketplaceDescription,
          analysisInput: {
            listPrice: parseInt(
              userListPrice
                ? userListPrice
                : parseInt(
                    head(
                      compact(
                        map(
                          preferences.listPrice,
                          (pref) => preferencesValues[pref].listPrice,
                        ),
                      ),
                    ),
                  ) || 0,
            ),
            afterRepairValue: Number(
              propertyData && propertyData.value
                ? head(propertyData.value.max)
                : '',
            ),
            propertyTaxes: Number(
              propertyData ? head(propertyData.propertyTaxes) : '',
            ),
            rent: Number(
              propertyData && propertyData.rentalValue
                ? head(propertyData.rentalValue.max)
                : '',
            ),
            rentBOR: Number(
              propertyData && propertyData.rentalValue
                ? head(propertyData.rentalValue.max)
                : '',
            ),
          },
          MLSID: toString(MLSID),
        },
      },
    }).then(async (addPropertyResults) => {
      const addPropertyData = addPropertyResults.data.addProperty
      await addLot({
        variables: {
          lotInput: {
            propertyId: addPropertyData._id,
            portfolioId: selectedPortfolio || props.portfolioId,
            address,
            city,
            state,
            county,
            zip: toString(zip),
            beds: parseInt(beds || 0),
            baths: parseFloat(baths || 0),
            sqft: parseInt(sqft || 0),
            year: parseInt(year || 0),
            description: marketplaceDescription,
            askingPrice: parseInt(
              userListPrice
                ? userListPrice
                : parseInt(
                    head(
                      compact(
                        map(
                          preferences.listPrice,
                          (pref) => preferencesValues[pref].listPrice,
                        ),
                      ),
                    ),
                  ) || 0,
            ),
            //analysisInput: analysis,
            MLSID: toString(MLSID),
            owned: ownedLot === 'owned' ? new Date() : null,
            status:
              ownedLot === 'owned' || ownedLot === 'approved'
                ? 'approved'
                : null,
          },
        },
        refetchQueries: [
          {
            query: GET_ME,
          },
          {
            query: GET_LOT_PORTFOLIOS,
          },
          {
            query: GET_LOTS,
            variables: {
              portfolioId: selectedPortfolio || props.portfolioId,
            },
          },
          {
            query: GET_LOTS,
            variables: {
              portfolioId: map(
                lotPortfolios,
                (portfolio) => portfolio._id,
              ),
            },
          },
        ],
      }).then(async (addLotResult) => {
        const addLotData = addLotResult.data.addLot
        // localStorage.setItem(
        //   'portfolio',
        //   selectedPortfolio,
        // )

        handleClose1()
        setLoader(false)
        pause()
        if (ownedLot === 'owned' || ownedLot === 'approved')
          navigate('/' + addLotData._id + '/editLot')
        else handleClickOpen2(addLotData._id)
      })
    })
  }

  let results = useQuery(GET_LOT, {
    variables: { id: open2 },
  })
  let { lot = {} } = results.data || {}
  if (!lot) lot = {}
  const lotLoading = results.loading
  const lotError = results.error

  results = useQuery(GET_PROPERTY, {
    variables: { id: lot.propertyId },
  })
  let { property = {} } = results.data || {}
  const propertyLoading = results.loading
  const propertyError = results.error
  const propertyRefetch = results
    ? results.refetch
    : () => {
        return
      }

  const {
    acquisition = {},
    assumptions = {},
    assetManagement = {},
    location = { latitude: 0, longitude: 0 },
  } = property
  const { analysis = {}, analytics = DEFAULT_ANALYTICS } = acquisition

  results = useQuery(GET_LOT_PORTFOLIOS)
  let { lotPortfolios = [] } = results.data || {}
  const lotPortfolioLoading = results.loading
  const lotPortfolioError = results.error

  results = useQuery(GET_PORTFOLIOS)
  let { portfolios = [] } = results.data || {}

  results = useQuery(GET_LOT_PROCESSES, {
    variables: {
      lotId: lot.propertyId,
    },
  })
  let { lotProcesses = {} } = results.data || {}
  const lotProcessesLoading = results.loading
  const lotProcessesError = results.error

  const [updateProperty] = useMutation(UPDATE_PROPERTY)
  const [updateLot] = useMutation(UPDATE_LOT)
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)
  const [saveAnalysis] = useMutation(SAVE_ANALYSIS)
  const [saveAssumptions] = useMutation(SAVE_ASSUMPTIONS)

  const [analysisInput, setAnalysisInput] = useState({})
  //  const [source, setSource] = useState(null)

  useEffect(() => {
    if (
      isEmpty(analysisInput) &&
      !propertyLoading &&
      !isEmpty(analysis) &&
      open2
    ) {
      let today = new Date()
      let daysOnMarketCheck = moment
        .duration(moment(today).diff(analysis.addedToMarket))
        .asDays()
      daysOnMarketCheck = Math.abs(daysOnMarketCheck)
      daysOnMarketCheck = Math.round(daysOnMarketCheck)
      setAnalysisInput({
        listPrice:
          analysis.listPrice == null ? null : analysis.listPrice,
        afterRepairValue:
          analysis.afterRepairValue == null
            ? null
            : analysis.afterRepairValue,
        rehabCost:
          analysis.rehabCost == null ? null : analysis.rehabCost,
        rehabCostTurnkey:
          analysis.rehabCostTurnkey == null
            ? null
            : analysis.rehabCostTurnkey,
        HOA: analysis.HOA == null ? null : analysis.HOA,
        HOAType: analysis.HOAType == null ? null : analysis.HOAType,
        //  financingRepairs: analysis.financingRepairs || false,
        resellPrice:
          analysis.resellPrice == null ? null : analysis.resellPrice,
        resellPriceWholesale:
          analysis.resellPriceWholesale == null
            ? null
            : analysis.resellPriceWholesale,
        daysOnMarket:
          daysOnMarketCheck !== analysis.daysOnMarket &&
          analysis.addedToMarket
            ? daysOnMarketCheck
            : analysis.daysOnMarket == null
              ? null
              : analysis.daysOnMarket,
        addedToMarket:
          analysis.addedToMarket == null
            ? null
            : analysis.addedToMarket,
        rent: isNil(analysis.rent) ? [0] : analysis.rent,
        rentLow: analysis.rentLow == null ? [0] : analysis.rentLow,
        rentHigh: analysis.rentHigh == null ? [0] : analysis.rentHigh,
        otherIncome: isNil(analysis.otherIncome)
          ? [0]
          : analysis.otherIncome,
        propertyTaxes:
          analysis.propertyTaxes == null
            ? null
            : analysis.propertyTaxes,
        offerPrice:
          analysis.offerPrice == null ? null : analysis.offerPrice,
        turnkeyOfferPrice: isNil(analysis.turnkeyOfferPrice)
          ? null
          : analysis.turnkeyOfferPrice,
        b2rLotOfferPrice: isNil(analysis.b2rLotOfferPrice)
          ? null
          : analysis.b2rLotOfferPrice,
        b2rConstructionOfferPrice: isNil(
          analysis.b2rConstructionOfferPrice,
        )
          ? null
          : analysis.b2rConstructionOfferPrice,
        b2rMezzanineOfferPrice: isNil(analysis.b2rMezzanineOfferPrice)
          ? [0, 0, 0]
          : analysis.b2rMezzanineOfferPrice,
        hvacAge: analysis.hvacAge,
        furnaceAge: analysis.furnaceAge,
        condensingUnitAge: analysis.condensingUnitAge,
        waterHeaterAge: analysis.waterHeaterAge,
        roofAge: analysis.roofAge,
        area: analysis.area || '',
        house: analysis.house || '',
        neighborhood: analysis.neighborhood || '',
        startingOffer: analysis.startingOffer,
        note: analysis.note,
        maxOffer: analysis.maxOffer,
        userCashOut: analysis.userCashOut,
        lender: analysis.lender,
        lotLender: analysis.lotLender || '',
        constructionLender: analysis.constructionLender || '',
        mezzanineLender: analysis.mezzanineLender || ['', '', ''],
      })
    }
  }, [analysis, analysisInput, propertyLoading, open2])

  const onChangeAnalysis = (event, source = null, quick = false) => {
    const { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value)
    let newAnalysisInput = cloneDeep(analysisInput)
    //  if (name === 'financingRepairs')
    //    newValue = !newAnalysisInput.financingRepairs
    if (
      name === 'userCashOut' &&
      (newValue < 0 ||
        newValue >=
          analytics.BRRRR.maxRefinanceLoanAmount -
            analytics.BRRRR.bridgeLoanAmount)
    )
      newValue = 0
    set(newAnalysisInput, name, newValue)
    if (name === 'afterRepairValue')
      newAnalysisInput['resellPrice'] = newValue
    if (name === 'daysOnMarket') {
      var addedToMarket = new Date()
      addedToMarket.setDate(addedToMarket.getDate() - value)
      newAnalysisInput.addedToMarket = addedToMarket
    }
    setAnalysisInput(newAnalysisInput)
    if (name === 'lotLender') {
      lenderOverride(value, 0)
    } else if (name === 'constructionLender') {
      lenderOverride(value, 1)
    } else if (includes(name, 'mezzanineLender')) {
      const mezIndex = split(name, '.')[1]
      lenderOverride(value, mezIndex)
    }
    let newSource = null
    // if (source) {
    //   newSource = { ...property.source }
    //   set(newSource, name, source)
    // }
    // setSource(newSource)
    if (quick) {
      submitAnalysis(
        lot.propertyId,
        newAnalysisInput,
        newSource,
        propertyRefetch,
      )
      debouncedSubmitAnalysis.cancel()
    }
  }

  const handleAnalysisAdornmentChange = (e, name, value) => {
    e.preventDefault()
    let newAnalysisInput = { ...analysisInput }
    newAnalysisInput[name] = value
    setAnalysisInput(newAnalysisInput)
    handleAssumptionsMenuClose()

    submitAnalysis(lot.propertyId, newAnalysisInput, propertyRefetch)
    debouncedSubmitAnalysis.cancel()
  }

  const submitAnalysis = (
    propertyId,
    analysisInput,
    source = null,
    propertyRefetch,
  ) => {
    saveAnalysis({
      variables: {
        propertyId: propertyId,
        analysisInput: analysisInput,
      },
      refetchQueries: [
        {
          query: GET_PROPERTY,
          variables: { id: propertyId },
        },
      ],
    }).then((data) => {
      // if (source) {
      //   updateProperty({
      //     variables: {
      //       id: propertyId,
      //       propertyInput: {
      //         source
      //       },
      //     },
      //     refetchQueries: [
      //       {
      //         query: GET_PROPERTY,
      //         variables: { id: propertyId }
      //       }
      //     ]
      //   })
      // }
      propertyRefetch()
    })
  }

  const debouncedSubmitAnalysis = useRef(
    debounce(
      (propertyId, analysisInput, source, propertyRefetch) =>
        submitAnalysis(
          propertyId,
          analysisInput,
          source,
          propertyRefetch,
        ),
      1000,
    ),
  ).current

  useEffect(() => {
    if (isEmpty(analysisInput)) return
    debouncedSubmitAnalysis(
      lot.propertyId,
      analysisInput,
      null /*source*/,
      propertyRefetch,
    )
    return () => debouncedSubmitAnalysis.cancel()
  }, [
    analysisInput,
    debouncedSubmitAnalysis,
    /*source,*/ propertyRefetch,
  ])

  const [assumptionsInput, setAssumptionsInput] = useState({})

  useEffect(() => {
    if (isEmpty(assumptionsInput) && !propertyLoading && open2) {
      let newAssumptions = cloneDeep(assumptions)
      unset(newAssumptions, '_id')
      unset(newAssumptions, '__v')
      unset(newAssumptions, '__typename')
      unset(newAssumptions, 'documentId')
      unset(newAssumptions, 'documentType')
      unset(newAssumptions, 'updatedAt')
      unset(newAssumptions, 'updatedBy')
      //copy assumptions until you have 3 loans
      while (
        !isEmpty(newAssumptions) &&
        newAssumptions.mezzanineCashPurchase.length < 3
      ) {
        newAssumptions['mezzanineCashPurchase'].push(
          last(newAssumptions['mezzanineCashPurchase']),
        )
        newAssumptions['mezzanineDownPayment'].push(
          last(newAssumptions['mezzanineDownPayment']),
        )
        newAssumptions['mezzanineRate'].push(
          last(newAssumptions['mezzanineRate']),
        )
        newAssumptions['mezzanineTerm'].push(
          last(newAssumptions['mezzanineTerm']),
        )
        newAssumptions['mezzanineTermType'].push(
          last(newAssumptions['mezzanineTermType']),
        )
        newAssumptions['mezzaninePoints'].push(
          last(newAssumptions['mezzaninePoints']),
        )
      }
      setAssumptionsInput(newAssumptions)
    }
  }, [assumptions, assumptionsInput, propertyLoading])

  const onChangeAssumptions = (event, quick = false) => {
    const { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value)
    let newAssumptionsInput = { ...assumptionsInput }
    if (type === 'checkbox') newValue = !newAssumptionsInput[name]
    if (name === 'allCashPurchase') {
      newAssumptionsInput.allCashPurchase =
        !newAssumptionsInput.allCashPurchase
      newAssumptionsInput['downPayment'] = 100
      newAssumptionsInput['rate'] = 0
      newAssumptionsInput['term'] = 0
      newAssumptionsInput['points'] = 0
      //newAssumptionsInput['payDownAccelerator'] = 0
      newAssumptionsInput['interestOnlyLoan'] = false
    } else if (name === 'allCashPurchaseTurnkey') {
      newAssumptionsInput.allCashPurchaseTurnkey =
        !newAssumptionsInput.allCashPurchaseTurnkey
      newAssumptionsInput['downPaymentTurnkey'] = 100
      newAssumptionsInput['rateTurnkey'] = 0
      newAssumptionsInput['termTurnkey'] = 0
      newAssumptionsInput['pointsTurnkey'] = 0
      //newAssumptionsInput['payDownAcceleratorTurnkey'] = 0
      newAssumptionsInput['interestOnlyLoanTurnkey'] = false
    } else if (name === 'allCashPurchaseFixFlip') {
      newAssumptionsInput.allCashPurchaseFixFlip =
        !newAssumptionsInput.allCashPurchaseFixFlip
      newAssumptionsInput['downPaymentFixFlip'] = 100
      newAssumptionsInput['rateFixFlip'] = 0
      newAssumptionsInput['termFixFlip'] = 0
      newAssumptionsInput['pointsFixFlip'] = 0
      //newAssumptionsInput['payDownAcceleratorFixFlip'] = 0
      newAssumptionsInput['interestOnlyLoanFixFlip'] = false
    } else if (name === 'allCashPurchaseSTR') {
      newAssumptionsInput.allCashPurchaseSTR =
        !newAssumptionsInput.allCashPurchaseSTR
      newAssumptionsInput['downPaymentSTR'] = 100
      newAssumptionsInput['rateSTR'] = 0
      newAssumptionsInput['termSTR'] = 0
      newAssumptionsInput['pointsSTR'] = 0
      //newAssumptionsInput['payDownAcceleratorSTR'] = 0
      newAssumptionsInput['interestOnlyLoanSTR'] = false
    } else if (name === 'bridgeCashPurchase') {
      newValue = !newAssumptionsInput.bridgeCashPurchase
      newAssumptionsInput['bridgeDownPayment'] = 100
      newAssumptionsInput['bridgeRate'] = 0
      newAssumptionsInput['bridgeTerm'] = 0
      newAssumptionsInput['bridgePoints'] = 0
      //newAssumptionsInput['payDownAccelerator'] = 0
      newAssumptionsInput['bridgeInterestOnlyLoan'] = false
    } else if (name === 'refinanceCashPurchase') {
      newAssumptionsInput.refinanceCashPurchase =
        !newAssumptionsInput.refinanceCashPurchase
      newAssumptionsInput['refinanceDownPayment'] = 100
      newAssumptionsInput['refinanceRate'] = 0
      newAssumptionsInput['refinanceTerm'] = 0
      newAssumptionsInput['refinancePoints'] = 0
      //newAssumptionsInput['payDownAccelerator'] = 0
      newAssumptionsInput['refinanceInterestOnlyLoan'] = false
    } else if (name === 'lotCashPurchase') {
      newAssumptionsInput.lotCashPurchase =
        !newAssumptionsInput.lotCashPurchase
      newAssumptionsInput['lotDownPayment'] = 100
      newAssumptionsInput['lotRate'] = 0
      newAssumptionsInput['lotTerm'] = 0
      newAssumptionsInput['lotPoints'] = 0
      //newAssumptionsInput['payDownAccelerator'] = 0
      newAssumptionsInput['lotInterestOnlyLoan'] = false
    } else if (name === 'constructionCashPurchase') {
      newAssumptionsInput.constructionCashPurchase =
        !newAssumptionsInput.constructionCashPurchase
      newAssumptionsInput['constructionDownPayment'] = 100
      newAssumptionsInput['constructionRate'] = 0
      newAssumptionsInput['constructionTerm'] = 0
      newAssumptionsInput['constructionPoints'] = 0
      //newAssumptionsInput['payDownAccelerator'] = 0
      newAssumptionsInput['constructionInterestOnlyLoan'] = false
    } else if (includes(name, 'mezzanineCashPurchase')) {
      const [front, index] = split(name, '.')
      newAssumptionsInput.mezzanineCashPurchase[index] =
        !newAssumptionsInput.mezzanineCashPurchase[index]
      newAssumptionsInput['mezzanineDownPayment'][index] = 100
      newAssumptionsInput['mezzanineRate'][index] = 0
      newAssumptionsInput['mezzanineTerm'][index] = 0
      newAssumptionsInput['mezzaninePoints'][index] = 0
      //newAssumptionsInput['payDownAccelerator'] = 0
      newAssumptionsInput['mezzanineInterestOnlyLoan'][index] = false
    } else if (name === 'costToBuildBuffer') {
      let newAnalysisInput = { ...analysisInput }
      set(
        newAnalysisInput,
        'b2rMezzanineOfferPrice.1',
        (get(lotProcessesInput, 'build.build.buildToCost') *
          newValue) /
          100,
      )
      setAnalysisInput(newAnalysisInput)
      set(newAssumptionsInput, name, newValue)
    } else set(newAssumptionsInput, name, newValue)
    setAssumptionsInput(newAssumptionsInput)
    if (quick) {
      submitAssumptions(lot.propertyId, newAssumptionsInput)
      debouncedSubmitAssumptions.cancel()
    }
  }

  const createNewMezzanine = () => {
    let newAssumptionsInput = { ...assumptionsInput }
    newAssumptionsInput['mezzanineCashPurchase'].push(
      last(newAssumptionsInput['mezzanineCashPurchase']),
    )
    newAssumptionsInput['mezzanineDownPayment'].push(
      last(newAssumptionsInput['mezzanineDownPayment']),
    )
    newAssumptionsInput['mezzanineRate'].push(
      last(newAssumptionsInput['mezzanineRate']),
    )
    newAssumptionsInput['mezzanineTerm'].push(
      last(newAssumptionsInput['mezzanineTerm']),
    )
    newAssumptionsInput['mezzanineTermType'].push(
      last(newAssumptionsInput['mezzanineTermType']),
    )
    newAssumptionsInput['mezzaninePoints'].push(
      last(newAssumptionsInput['mezzaninePoints']),
    )
    //newAssumptionsInput['payDownAccelerator'] = 0
    newAssumptionsInput['mezzanineInterestOnlyLoan'].push(
      last(newAssumptionsInput['mezzanineInterestOnlyLoan']),
    )
    setAssumptionsInput(newAssumptionsInput)
    let newAnalysisInput = { ...analysisInput }
    newAnalysisInput['b2rMezzanineOfferPrice'].push(
      last(newAnalysisInput['b2rMezzanineOfferPrice']),
    )
    newAnalysisInput['mezzanineLender'].push(
      last(newAnalysisInput['mezzanineLender']),
    )
    setAnalysisInput(newAnalysisInput)
  }

  const [anchorElAssumptions, setAnchorElAssumptions] = useState(null)
  const [showFormatDropdown, setShowFormatDropdown] = useState('')

  const toggleAssumptionsMenuFormat = (event, name) => {
    setAnchorElAssumptions(event.target)
    setShowFormatDropdown(name)
  }

  const handleAssumptionsMenuClose = () => {
    setAnchorElAssumptions(null)
  }

  const handleAdornmentChange = (e, name, value) => {
    e.preventDefault()
    let newAssumptions = { ...assumptionsInput }
    set(newAssumptions, name, value)
    setAssumptionsInput(newAssumptions)
    handleAssumptionsMenuClose()

    submitAssumptions(lot.propertyId, newAssumptions)
    debouncedSubmitAssumptions.cancel()
  }

  const lenderOverride = (lenderName, index) => {
    let newAssumptionsInput = { ...assumptionsInput }
    if (index === 0) {
      const lender = find(
        props.session.me.activeOrg.lenders,
        (lender) => lender.value === lenderName,
      )
      newAssumptionsInput['lotDownPayment'] = parseFloat(
        lender.downPayment,
      )
      newAssumptionsInput['lotRate'] = parseFloat(lender.rate)
      newAssumptionsInput['lotTerm'] = parseFloat(lender.term)
      newAssumptionsInput['lotTermType'] = lender.termType
      newAssumptionsInput['lotPoints'] = parseFloat(lender.points)
    } else if (index === 1) {
      const lender = find(
        props.session.me.activeOrg.lenders,
        (lender) => lender.value === lenderName,
      )
      newAssumptionsInput['constructionDownPayment'] = parseFloat(
        lender.downPayment,
      )
      newAssumptionsInput['constructionRate'] = parseFloat(
        lender.rate,
      )
      newAssumptionsInput['constructionTerm'] = parseFloat(
        lender.term,
      )
      newAssumptionsInput['constructionTermType'] = lender.termType
      newAssumptionsInput['constructionPoints'] = parseFloat(
        lender.points,
      )
    } else {
      const mezIndex = index - 2
      const lender = find(
        props.session.me.activeOrg.lenders,
        (lender) => lender.value === lenderName,
      )
      newAssumptionsInput['mezzanineDownPayment'][mezIndex] =
        parseFloat(lender.downPayment)
      newAssumptionsInput['mezzanineRate'][mezIndex] = parseFloat(
        lender.rate,
      )
      newAssumptionsInput['mezzanineTerm'][mezIndex] = parseFloat(
        lender.term,
      )
      newAssumptionsInput['mezzanineTermType'][mezIndex] =
        lender.termType
      newAssumptionsInput['mezzaninePoints'][mezIndex] = parseFloat(
        lender.points,
      )
    }
    setAssumptionsInput(newAssumptionsInput)
  }

  const submitAssumptions = (propertyId, assumptionsInput) => {
    saveAssumptions({
      variables: {
        documentId: propertyId,
        assumptionsInput: assumptionsInput,
        documentType: 'property',
      },
      refetchQueries: [
        {
          query: GET_PROPERTY,
          variables: { id: propertyId },
        },
      ],
    }).then((data) => {
      //handleUpdateMarket()
    })
  }

  const debouncedSubmitAssumptions = useRef(
    debounce(
      (propertyId, assumptionsInput) =>
        submitAssumptions(propertyId, assumptionsInput),
      1000,
    ),
  ).current

  useEffect(() => {
    if (isEmpty(assumptionsInput)) return
    debouncedSubmitAssumptions(lot.propertyId, assumptionsInput)
    return () => debouncedSubmitAssumptions.cancel()
  }, [assumptionsInput, debouncedSubmitAssumptions])

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  const currencyFormat2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })

  const [lotInput, setLotInput] = useState({})

  useEffect(() => {
    if (isEmpty(lotInput) && !lotLoading && Boolean(open2)) {
      // let today = new Date()
      // let daysOnMarketCheck = moment
      //   .duration(moment(today).diff(analysis.addedToMarket))
      //   .asDays()
      // daysOnMarketCheck = Math.abs(daysOnMarketCheck)
      // daysOnMarketCheck = Math.round(daysOnMarketCheck)
      setLotInput({
        propertyId: lot.propertyId,
        address: lot.address,
        city: lot.city,
        county: lot.county,
        state: lot.state,
        zip: lot.zip,
        beds: Number(lot.beds),
        baths: Number(lot.baths),
        sqft: Number(lot.sqft),
        lotSqft: Number(lot.lotSqft),
        year: Number(lot.year),
        description: lot.description,
        water: lot.water,
        sewer: lot.sewer,
        exchange: lot.exchange || [],
        market: lot.market,
        type: lot.type,
        plan: lot.plan,
        zone: lot.zone,
        parcelID: lot.parcelID,
        subdivision: lot.subdivision,
        owned: lot.owned ? new Date(lot.owned) : null,
        askingPrice: Number(lot.askingPrice),
        daysOnMarket: Number(lot.daysOnMarket),
        neighborhood: lot.neighborhood,
        MLSID: lot.MLSID || '',
        dueDiligenceFee: Number(lot.dueDiligenceFee),
        percTest: Number(lot.percTest),
        appraisedValue: Number(lot.appraisedValue),
      })
    }
  }, [lot, lotInput, lotLoading, open2])

  const [propertyInput, setPropertyInput] = useState({})

  useEffect(() => {
    if (
      isEmpty(propertyInput) &&
      !propertyLoading &&
      !isEmpty(property) &&
      Boolean(open2)
    ) {
      setPropertyInput({
        address: property.address,
        city: property.city,
        county: property.county,
        state: property.state,
        zip: property.zip,
        beds: !isNil(property.beds) ? [...property.beds] : [0],
        baths: !isNil(property.baths) ? [...property.baths] : [0],
        sqft: !isNil(property.sqft) ? [...property.sqft] : [0],
        year: property.year,
        description: property.description,
        water: property.water,
        sewer: property.sewer,
        exchange: property.exchange || [],
        market: property.market,
        type: property.type,
        plan: property.plan,
        zone: property.zone,
        parcelID: property.parcelID,
        lotSize: property.lotSize,
        basementSqft: property.basementSqft,
        remodelYear: property.remodelYear,
        parking: property.parking,
        ac: property.ac,
        homeType: property.homeType,
        floodZone: property.floodZone,
        subdivision: property.subdivision,
        propertyType: property.propertyType,
        owned: property.owned ? new Date(property.owned) : null,
        lender: property.lender,
        analysisInput: {
          listPrice: analysis.listPrice,
          daysOnMarket: analysis.daysOnMarket,
          neighborhood: analysis.neighborhood,
        },
        //  source: {
        //    ...property.source
        //  },
        MLSID: property.MLSID || '',
      })
    }
  }, [property, analysis, propertyInput, propertyLoading, open2])

  const onChangeExchange = (event, name, quick = false) => {
    let newLotInput = { ...lotInput }
    let exchange = [...newLotInput.exchange]
    if (includes(exchange, name)) pull(exchange, name)
    else exchange.push(name)
    newLotInput.exchange = exchange
    setLotInput(newLotInput)
    if (quick) {
      onSubmitLot(
        lot._id,
        lot.propertyId,
        newLotInput,
        !isEqual(lot.plan, lotInput.plan) && !isEmpty(lotInput.plan),
        props.session.me.activeOrg,
        propertyRefetch,
      )
      debouncedSubmitLot.cancel()
    }
  }

  const onChangeLot = (event, source = null, quick = false) => {
    const { name = '', value = '', type = '' } = event.target
    let newLotInput = { ...lotInput }
    let newValue = value
    if (type === 'checkbox' && name !== 'owned')
      newValue = !get(lotInput, name)
    if (type === 'number' && name !== 'zip') newValue = Number(value)
    if (name === 'owned')
      newValue = get(newLotInput, name) ? null : new Date()
    set(newLotInput, name, newValue)
    setLotInput(newLotInput)
    if (quick) {
      onSubmitLot(
        lot._id,
        lot.propertyId,
        newLotInput,
        !isEqual(lot.plan, lotInput.plan) && !isEmpty(lotInput.plan),
        props.session.me.activeOrg,
        propertyRefetch,
      )
      debouncedSubmitLot.cancel()
    }
  }

  const onSubmitLot = async (lotId, lotInput, propertyRefetch) => {
    let newLotInput = { ...lotInput }
    updateLot({
      variables: {
        id: lotId,
        lotInput: newLotInput,
      },
      refetchQueries: [
        {
          query: GET_LOT,
          variables: { id: lotId },
        },
      ],
    })
      .then(() => {})
      .catch((error) => {
        enqueueSnackbar(`Changes Failed to Save`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const debouncedSubmitLot = useRef(
    debounce((lotId, lotInput) => onSubmitLot(lotId, lotInput), 1000),
  ).current

  useEffect(() => {
    if (isEmpty(lotInput) || lotLoading) return
    debouncedSubmitLot(lot._id, lotInput)
    return () => debouncedSubmitLot.cancel()
  }, [lotInput, lotLoading, debouncedSubmitLot])

  const planOverride = (newPlan) => {
    let newPropertyInput = cloneDeep(propertyInput)
    const { activeOrg } = props.session.me
    const { plans } = activeOrg
    const plan = find(plans, { value: newPlan })
    if (plan) {
      newPropertyInput.plan = newPlan
      newPropertyInput.beds[0] = parseInt(plan.beds)
      newPropertyInput.baths[0] = parseFloat(plan.baths)
      newPropertyInput.sqft[0] = parseInt(plan.sqft)
      newPropertyInput.analysisInput.listPrice = parseInt(
        plan.listPrice,
      )
      //newPropertyInput.year = parseInt(plan.year)
      setPropertyInput(newPropertyInput)
    }
  }

  const onChangeProperty = (event, source = null) => {
    const { name = '', value = '', type = '' } = event.target
    let newPropertyInput = { ...propertyInput }
    let newValue = value
    if (type === 'checkbox' && name !== 'owned')
      newValue = !get(propertyInput, name)
    if (type === 'number' && name !== 'zip') newValue = Number(value)
    if (includes(name, 'bed')) newValue = parseInt(newValue)
    if (name === 'owned')
      newValue = get(newPropertyInput, name) ? null : new Date()
    set(newPropertyInput, name, newValue)
    setPropertyInput(newPropertyInput)
    if (name === 'plan') planOverride(value)
  }

  const onSubmitProperty = (
    propertyId,
    propertyInput,
    source = null,
  ) => {
    let newPropertyInput = { ...propertyInput }
    //  if (source) {
    //   newPropertyInput["source"] = source
    //  }
    updateProperty({
      variables: {
        id: propertyId,
        propertyInput: newPropertyInput,
      },
      refetchQueries: [
        {
          query: GET_PROPERTY,
          variables: { id: propertyId },
        },
      ],
    })
      .then(() => {
        //  enqueueSnackbar(`Changes Saved`, {
        //    variant: 'success',
        //    autoHideDuration: 3000,
        //  })
        setLoader(false)
      })
      .catch((error) => {
        enqueueSnackbar(`Changes Failed to Save`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const debouncedSubmitProperty = useRef(
    debounce(
      (propertyId, propertyInput, source) =>
        onSubmitProperty(propertyId, propertyInput, source),
      1000,
    ),
  ).current

  useEffect(() => {
    const basePropertyInput = {
      address: property.address,
      city: property.city,
      county: property.county,
      state: property.state,
      zip: property.zip,
      beds: !isNil(property.beds) ? [...property.beds] : [0],
      baths: !isNil(property.baths) ? [...property.baths] : [0],
      sqft: !isNil(property.sqft) ? [...property.sqft] : [0],
      year: property.year,
      description: property.description,
      water: property.water,
      sewer: property.sewer,
      exchange: property.exchange || [],
      market: property.market,
      type: property.type,
      plan: property.plan,
      zone: property.zone,
      parcelID: property.parcelID,
      lotSize: property.lotSize,
      basementSqft: property.basementSqft,
      remodelYear: property.remodelYear,
      parking: property.parking,
      ac: property.ac,
      homeType: property.homeType,
      floodZone: property.floodZone,
      subdivision: property.subdivision,
      propertyType: property.propertyType,
      owned: property.owned ? new Date(property.owned) : null,
      lender: property.lender,
      analysisInput: {
        listPrice: analysis.listPrice,
        daysOnMarket: analysis.daysOnMarket,
        neighborhood: analysis.neighborhood,
      },
      //  source: {
      //    ...property.source
      //  },
      MLSID: property.MLSID || '',
    }
    if (
      isEmpty(propertyInput) ||
      propertyLoading ||
      isEqual(propertyInput, basePropertyInput)
    )
      return
    debouncedSubmitProperty(lot.propertyId, propertyInput /*source*/)
    return () => debouncedSubmitProperty.cancel()
  }, [
    propertyInput,
    propertyLoading,
    debouncedSubmitProperty /*source*/,
  ])

  const [saveLotProcessesObject] = useMutation(SAVE_LOT_PROCESSES)

  const [lotProcessesInput, setLotProcessesInput] = useState({})

  useEffect(() => {
    if (
      isEmpty(lotProcessesInput) &&
      !lotProcessesLoading &&
      !isEmpty(lotProcesses) &&
      open2
    ) {
      let input = cloneDeep(lotProcesses)
      unset(input, '_id')
      unset(input, '__typename')
      setLotProcessesInput(input)
    }
  }, [lotProcesses, lotProcessesInput, lotProcessesLoading])

  const onChangeLotProcess = (event, quick = false) => {
    const { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'checkbox') newValue = !get(lotProcessesInput, name)
    if (type === 'number') newValue = Number(value)
    if (type === 'date') {
      newValue = value
      if (
        !newValue ||
        isNaN(newValue.getTime()) ||
        newValue.getFullYear() > 9999
      )
        return null
    }
    if (name === 'build.build.buildToCost') {
      let newAnalysisInput = { ...analysisInput }
      set(
        newAnalysisInput,
        'b2rMezzanineOfferPrice.1',
        (newValue * assumptions.costToBuildBuffer) / 100,
      )
      setAnalysisInput(newAnalysisInput)
    }
    let newLotProcessesInput = cloneDeep(lotProcessesInput)
    set(newLotProcessesInput, name, newValue)
    setLotProcessesInput(newLotProcessesInput)
    if (quick) {
      submitLotProcess(lot.propertyId, newLotProcessesInput)
      debouncedSubmitLotProcess.cancel()
    }
  }

  const uploadFile = (target) => (file) => {
    let newFiles = []
    newFiles.push(file)
    let newLotProcessesInput = cloneDeep(lotProcessesInput)
    set(newLotProcessesInput, target, newFiles)
    setLotProcessesInput(newLotProcessesInput)
  }

  const deleteFile = (target) => () => {
    let newFiles = []
    let newLotProcessesInput = cloneDeep(lotProcessesInput)
    set(newLotProcessesInput, target, newFiles)
    setLotProcessesInput(newLotProcessesInput)
  }

  const submitLotProcess = (lotId, lotProcessesInput) => {
    saveLotProcessesObject({
      variables: {
        lotId,
        lotProcessesInput: lotProcessesInput,
      },
      refetchQueries: [
        {
          query: GET_LOT_PROCESSES,
          variables: { lotId },
        },
      ],
    }).then((data) => {
      //handleUpdateMarket()
    })
  }

  const debouncedSubmitLotProcess = useRef(
    debounce(
      (lotId, lotProcessesInput) =>
        submitLotProcess(lotId, lotProcessesInput),
      1000,
    ),
  ).current

  useEffect(() => {
    if (isEmpty(lotProcessesInput) || lotLoading) return
    debouncedSubmitLotProcess(lot.propertyId, lotProcessesInput)
    return () => debouncedSubmitLotProcess.cancel()
  }, [lotProcessesInput, lotLoading, debouncedSubmitLotProcess])

  const createNewChangeOrder = () => {
    let newLotProcessesInput = { ...lotProcessesInput }
    let changeOrder = get(
      newLotProcessesInput,
      'build.changeOrder.changeOrder',
    )
    changeOrder = concat(changeOrder, 0)
    set(
      newLotProcessesInput,
      'build.changeOrder.changeOrder',
      changeOrder,
    )
    let changeOrderFile = get(
      newLotProcessesInput,
      'build.changeOrder.changeOrderFile',
    )
    changeOrderFile = concat(changeOrderFile, null)
    set(
      newLotProcessesInput,
      'build.changeOrder.changeOrderFile',
      changeOrderFile,
    )
    setLotProcessesInput(newLotProcessesInput)
  }

  const [ownedLot, setOwnedLot] = useState('edit')

  const renderInputFields = renderInputFieldsFunction(
    {
      onChangeProperty,
      onChangeAnalysis,
      onChangeAssumptions,
      onChangeLot,
      onChangeLotProcess,
      onChangeExchange,
    },
    {
      showFormatDropdown,
      anchorElAssumptions,
      toggleAssumptionsMenuFormat,
      handleAssumptionsMenuClose,
      handleAdornmentChange,
      handleAnalysisAdornmentChange,
    },
    props?.session?.me?.activeOrg?.member,
  )

  const { plans } = activeOrg

  const lotDetailFields = [
    {
      type: 'text',
      name: 'county',
      label: 'County',
      value: propertyInput.county,
      xs: 12,
      details: true,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Street Address',
      value: propertyInput.address,
      xs: 12,
      details: true,
    },
    {
      type: 'text',
      name: 'city',
      label: 'City',
      value: propertyInput.city,
      xs: 12,
      md: 6,
      details: true,
    },
    {
      type: 'autocomplete',
      name: 'state',
      label: 'State',
      value: propertyInput.state,
      options: statesShort(),
      xs: 12,
      md: 6,
      details: true,
    },
    {
      type: 'number',
      name: 'zip',
      label: 'Zip',
      value: propertyInput.zip,
      xs: 12,
      md: 6,
      details: true,
    },
    {
      type: 'text',
      name: 'subdivision',
      label: 'Subdivision',
      value: propertyInput.subdivision,
      xs: 12,
      md: 6,
      details: true,
    },
    {
      type: 'dollar',
      name: 'askingPrice',
      label: 'Asking Price',
      value: isNil(lotInput.askingPrice)
        ? ''
        : lotInput.askingPrice.toString(),
      xs: 12,
      md: 6,
      lot: true,
      tooltipField:
        'The price that the seller is asking for this home. *Not necessarily the price that the investor will purchase the home for.',
      tooltipButtonText: 'Asking Price Options',
    },
    {
      name: 'plan',
      value: propertyInput.plan || '',
      label: 'Plan',
      options: plans,
      optionName: 'plans',
      editOption: true,
      removeOption: true,
      type: 'select',
      xs: 12,
      md: 6,
      details: true,
    },
  ]

  const lotLoanFields = [
    {
      name: 'lotLender',
      value: analysisInput.lotLender || '',
      label: 'Lender',
      options: filter(activeOrg.lenders, (lender) =>
        includes(lender.type, 'Lot'),
      ),
      optionName: 'lenders',
      removeOption: true,
      type: 'select',
      analysis: true,
      xs: 12,
      md: 6,
    },
    {
      type: 'dollar',
      name: 'b2rLotOfferPrice',
      label: 'Loan Amount',
      value: isNil(analysisInput.b2rLotOfferPrice)
        ? ''
        : analysisInput.b2rLotOfferPrice.toString(),
      xs: 12,
      lg: 6,
      analysis: true,
      tooltipField:
        'The dollar amount of the loan meant for the lot.',
    },
    {
      type: 'date',
      name: 'lotStartDate',
      label: 'Start Date',
      value: assumptionsInput.lotStartDate,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField: 'The date the lot loan started.',
    },
    {
      type: 'number',
      name: 'lotDaysOfLoan',
      label: 'Days of loan',
      value: assumptionsInput.lotDaysOfLoan,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The dollar amount of the loan meant for the lot.',
    },
  ]

  const constructionLoanFields = [
    {
      name: 'constructionLender',
      value: analysisInput.constructionLender || '',
      label: 'Lender',
      options: filter(activeOrg.lenders, (lender) =>
        includes(lender.type, 'Construction'),
      ),
      optionName: 'lenders',
      removeOption: true,
      type: 'select',
      analysis: true,
      xs: 12,
      md: 6,
    },
    {
      type: 'dollar',
      name: 'b2rConstructionOfferPrice',
      label: 'Loan Amount',
      value: isNil(analysisInput.b2rConstructionOfferPrice)
        ? ''
        : analysisInput.b2rConstructionOfferPrice.toString(),
      xs: 12,
      lg: 6,
      analysis: true,
      tooltipField:
        'The dollar amount of the loan meant for the construction.',
    },
    {
      type: 'date',
      name: 'constructionStartDate',
      label: 'Start Date',
      value: assumptionsInput.constructionStartDate,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField: 'The date the construction loan started.',
    },
  ]

  const mezzanineLoanFields = (index) => [
    {
      name: `mezzanineLender.${index}`,
      value: get(analysisInput, `mezzanineLender.${index}`),
      label: 'Lender',
      options: filter(activeOrg.lenders, (lender) =>
        includes(lender.type, 'Mezzanine'),
      ),
      optionName: 'lenders',
      removeOption: true,
      type: 'select',
      analysis: true,
      xs: 12,
      md: 6,
    },
    {
      type: 'dollar',
      name: `b2rMezzanineOfferPrice.${index}`,
      label: 'Loan Amount',
      value:
        index === 1
          ? (get(lotProcessesInput, 'build.build.buildToCost') *
              assumptionsInput.costToBuildBuffer) /
            100
          : analysisInput.b2rMezzanineOfferPrice[index],
      xs: 12,
      lg: 6,
      disabled: index === 1,
      analysis: true,
      tooltipField:
        'The dollar amount of the loan meant for the additional expenses that may occur.',
    },
    {
      type: 'date',
      name: `mezzanineStartDate.${index}`,
      label: 'Start Date',
      value: get(assumptionsInput, `mezzanineStartDate.${index}`),
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField: 'The date the mezzanine loan started.',
    },
  ]

  const inputFields = [
    {
      type: 'dollar',
      name: 'resellPrice',
      label: 'Resale Price',
      value: isNil(analysisInput.resellPrice)
        ? ''
        : analysisInput.resellPrice.toString(),
      xs: 12,
      lg: 6,
      analysis: true,
      tooltipField:
        'The price you will resell the property for once the rehab is completed.',
    },
    {
      type: 'dollar',
      label: 'Cost to Build',
      name: 'build.build.buildToCost',
      value: get(lotProcessesInput, 'build.build.buildToCost'),
      xs: 12,
      lg: 6,
      lotProcess: true,
      tooltipField: '',
    },
    {
      type: 'percentage',
      name: 'costToBuildBuffer',
      label: 'Cost to Build Buffer',
      value: assumptionsInput.costToBuildBuffer,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField: '',
    },
    {
      type: 'dropdown',
      name: 'salesCommission',
      label: 'In-House Agent Fee',
      value: assumptionsInput.salesCommission,
      adornmentToggle: assumptionsInput.salesCommissionType,
      adornment: assumptionsInput.salesCommissionType,
      adornmentName: 'salesCommissionType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Sales Commission on the Future Sale of Property. Expressed as a dollar amount or percentage of resale price.',
    },
    {
      type: 'dropdown',
      name: 'sellerClosingCost',
      label: 'Seller Closing Cost',
      value: assumptionsInput.sellerClosingCost,
      adornmentToggle: assumptionsInput.sellerClosingCostType,
      adornment: assumptionsInput.sellerClosingCostType,
      adornmentName: 'sellerClosingCostType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Closing Costs Paid by the Seller. Expressed as a dollar amount or percentage of the resale price',
    },
    {
      type: 'dropdown',
      name: 'dispositionFee',
      label: 'Disposition Fee',
      value: assumptionsInput.dispositionFee,
      adornmentToggle: assumptionsInput.dispositionFeeType,
      adornment: assumptionsInput.dispositionFeeType,
      adornmentName: 'dispositionFeeType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Advanced Fee - Additional marketing fee on top of sales commission. Expressed as a dollar amount or percentage of resale price.',
    },
    {
      type: 'dollar',
      name: 'propertyTaxes',
      label: 'Property Taxes',
      value: isNil(analysisInput.propertyTaxes)
        ? ''
        : analysisInput.propertyTaxes.toString(),
      tooltipButtonText: 'Show Property Tax data',
      xs: 12,
      lg: 6,
      analysis: true,
      definition: 'propertyTaxes',
      tooltipField:
        'A tax paid on property owned by an individual or other legal entity, such as a corporation.',
    },
    {
      type: 'dollar',
      name: 'otherExpense',
      label: 'Other Expenses',
      value:
        assumptionsInput.otherExpense === null
          ? ''
          : assumptionsInput.otherExpense,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Any annual expense that has not already been labeled.',
    },
  ]

  const endBuyerFields = [
    {
      dropdown: true,
      type: 'dropdown',
      label: 'HOA',
      name: 'HOA',
      value: analysisInput.HOA,
      adornment: analysisInput.HOAType || 'Yearly',
      adornmentToggle: analysisInput.HOAType,
      adornmentName: 'HOAType',
      xs: 12,
      lg: 6,
      analysis: true,
      tooltipField:
        'An organization in a subdivision, planned community, or condominium building that makes and enforces rules for the properties and its residents. These residents automatically become members and are required to pay dues, known as HOA fees.',
    },
    {
      type: 'dollar',
      name: 'rent.0',
      label: 'Total Rental Income',
      tooltipButtonText: 'Show Total Rent data',
      value: isNil(head(analysisInput.rent))
        ? ''
        : head(analysisInput.rent).toString(),
      // helper: head(property.sqft) && analysisInput.rent ? currencyFormat.format(analysisInput.rent / head(property.sqft)) + ' rent per sqft' : '$0.00 rent per sqft',
      xs: 12,
      lg: 6,
      definition: 'rent',
      analysis: true,
      tooltipField:
        "A tenant's monthly payment to a landlord for the use of property or land.",
    },
    {
      dropdown: true,
      type: 'dropdown',
      name: 'propertyManagement',
      label: 'Property Mgmt Fee',
      value: assumptionsInput.propertyManagement,
      adornmentToggle: assumptionsInput.propertyManagementType,
      adornment: assumptionsInput.propertyManagementType,
      adornmentName: 'propertyManagementType',
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Property management fee expressed as a dollar amount or percentage of Gross Rental Income (rent - vacancy).',
    },
    {
      dropdown: true,
      type: 'dropdown',
      name: 'propertyInsurance',
      label: 'Annual Insurance',
      value: assumptionsInput.propertyInsurance,
      adornmentToggle: assumptionsInput.propertyInsuranceType,
      adornment: assumptionsInput.propertyInsuranceType,
      adornmentName: 'propertyInsuranceType',
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Annual property insurance premium expressed as a dollar amount or percentage of the purchase price.',
    },
    {
      type: 'dollar',
      name: 'appraisedValue',
      label: 'Appraised Value',
      value: isNil(lotInput.appraisedValue)
        ? ''
        : lotInput.appraisedValue.toString(),
      xs: 12,
      lg: 6,
      lot: true,
      tooltipField:
        'Appraised Value is how much the house is worth right now.',
    },
  ]

  const totalCostOfFinancing =
    analytics.B2R.lotMPInterestOnlyLoanTotal +
    analytics.B2R.constructionMPInterestOnlyLoanTotal +
    sum(analytics.B2R.mezzanineMPInterestOnlyLoanTotal) +
    analytics.B2R.lotPointsAmount +
    analytics.B2R.constructionPointsAmount +
    sum(analytics.B2R.mezzaninePointsAmount)
  const totalExpenses =
    Number(lot.askingPrice) +
    Number(get(lotProcesses, 'build.build.buildToCost') || 0) +
    Number(
      ((get(lotProcesses, 'build.build.buildToCost') || 0) *
        assumptions.costToBuildBuffer) /
        100,
    ) +
    Number(assumptions.otherExpense) +
    Number(analytics.B2R.salesCommissionTotal) +
    Number(analytics.B2R.dispositionFeeDollarB2R) +
    Number(analytics.B2R.sellingClosingCostDollarB2R) +
    totalCostOfFinancing
  const netProfit = (analysis.sellPrice || 0) - (totalExpenses || 0)

  const lotTargetFields = [
    {
      label: 'Net Profit',
      value: netProfit > 0 ? netProfit : 0,
      xs: 12,
      sm: 6,
      decimal: false,
    },
    {
      label: 'Cap Rate',
      value:
        analytics.B2R.capRateB2R > 0 ? analytics.B2R.capRateB2R : 0,
      xs: 12,
      sm: 6,
      decimal: true,
    },
    {
      label: 'Total Cost of Financing',
      value: totalCostOfFinancing > 0 ? totalCostOfFinancing : 0,
      xs: 12,
      sm: 6,
      decimal: true,
    },
    {
      label: 'Total Expenses',
      value: totalExpenses > 0 ? totalExpenses : 0,
      xs: 12,
      sm: 6,
      decimal: false,
    },
    {
      label: 'Cashflow',
      value:
        netProfit +
          analytics.B2R.constructionMPInterestOnlyLoanTotal >
        0
          ? netProfit +
            analytics.B2R.constructionMPInterestOnlyLoanTotal
          : 0,
      xs: 12,
      sm: 6,
      decimal: false,
    },
  ]

  const PerSqftLotTargetFields = [
    {
      label: 'Price to Build per sqft',
      value:
        head(property.sqft) > 0
          ? Number(get(lotProcesses, 'build.build.buildToCost')) +
              Number(
                (get(lotProcesses, 'build.build.buildToCost') *
                  assumptions.costToBuildBuffer) /
                  100,
              ) >
            0
            ? (Number(get(lotProcesses, 'build.build.buildToCost')) +
                Number(
                  (get(lotProcesses, 'build.build.buildToCost') *
                    assumptions.costToBuildBuffer) /
                    100,
                )) /
              head(property.sqft)
            : 0
          : 0,
      xs: 12,
      sm: 6,
      decimal: true,
    },
    {
      label: 'Rent per sqft',
      value:
        head(property.sqft) > 0
          ? head(analysis.rent) > 0
            ? head(analysis.rent) / head(property.sqft)
            : 0
          : 0,
      xs: 12,
      sm: 6,
      decimal: true,
    },
    {
      label: 'Sale Price per sqft',
      value:
        head(property.sqft) > 0
          ? lot.appraisedValue > 0
            ? lot.appraisedValue / head(property.sqft)
            : 0
          : 0,
      xs: 12,
      sm: 6,
      decimal: true,
    },
  ]

  const noFieldEmpty = every(
    concat(lotLoanFields, constructionLoanFields, inputFields),
    (value) => !isNil(value),
  )
  return (
    <>
      {props.title && (
        <Button
          variant="contained"
          onClick={handleClickOpen1}
          // size='small'
          //color='secondary'
          style={{
            backgroundColor: '#28b854',
            color: 'white',
            whiteSpace: 'nowrap',
          }}
          className="btn-success d-40 w-auto mr-3"
        >
          <Add style={{ fontWeight: 600, marginRight: 5 }} />
          <Typography style={{ fontWeight: 600 }}>Add Lot</Typography>
        </Button>
      )}
      {props.launchpad && (
        <Button
          onClick={handleClickOpen1}
          variant="text"
          className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"
        >
          <FontAwesomeIcon
            icon={['fal', 'map-marked-alt']}
            className="h2 d-block mx-auto mb-2 mt-1 text-success"
          />
          <div className="font-weight-bold font-size-md text-black">
            Add Lot
          </div>
          <div className="font-size-md mb-1 text-black-50">
            Listed or off-market
          </div>
        </Button>
      )}
      {props.list && (
        <div className="align-box-row">
          <span
            onClick={(event) => {
              event.stopPropagation()
              handleClickOpen2(props.propertyId)
            }}
          >
            <b>{props.lot.address}</b>
            <span className="text-black-50 d-block">
              {props.lot.city} {props.lot.state}, {props.lot.zip}
            </span>
          </span>
        </div>
      )}
      {props.connected && (
        <Button
          onClick={() => handleClickOpen2(props.propertyId)}
          variant="contained"
          color="primary"
          style={{ marginRight: 10 }}
        >
          Connected Lot
        </Button>
      )}
      {props.context && (
        <ListItem
          component="a"
          button
          onClick={() => handleClickOpen2(props.propertyId)}
          className="rounded-sm"
        >
          <FontAwesomeIcon
            icon={['far', 'pen']}
            className="font-size-lg"
            style={{ marginRight: 10 }}
          />
          <span>Edit Lot</span>
        </ListItem>
      )}

      <ApolloConsumer>
        {(client) => (
          <Query query={GET_PORTFOLIOS}>
            {({ loading, error, refetch, data }) => {
              if (loading) return ''
              const { portfolios = [] } = data
              return (
                <Query query={GET_LOT_PORTFOLIOS}>
                  {({ loading, error, refetch, data }) => (
                    <Dialog
                      open={open1}
                      onClose={handleClose1}
                      onKeyDown={stopPropagationForTab}
                    >
                      <DialogTitle>Add Single Lot</DialogTitle>
                      <ValidatorForm
                        style={{
                          overflowY: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        onSubmit={() =>
                          runQuery(
                            client,
                            portfolios,
                            data ? data.lotPortfolios : [],
                          )
                        }
                      >
                        <DialogContent>
                          <BlockUi
                            blocking={loader}
                            loader={
                              <PacmanLoader
                                color={'var(--primary)'}
                                loading={true}
                              />
                            }
                            message={
                              <div className="text-primary">
                                <p>Connecting</p>
                                <p>
                                  {(
                                    ((minutes * 60 + seconds) / 120) *
                                    100
                                  ).toFixed(1)}
                                  % Complete
                                </p>
                              </div>
                            }
                          >
                            {/* <DialogContent className="p-4"> */}
                            <Script
                              url={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=geometry,places`}
                              onLoad={handleScriptLoad}
                            />
                            <DialogContentText>
                              Type an address below and we will
                              automatically gather data and analyze it
                              for you even if it's not on the MLS!
                            </DialogContentText>
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                            >
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  id="autocomplete"
                                  placeholder="Search Property"
                                  label="Address"
                                  fullWidth={true}
                                  margin="dense"
                                  variant="outlined"
                                  size="small"
                                  name="query"
                                  //value={query}
                                  onChange={(event) =>
                                    setQuery(event.target.value)
                                  }
                                  autoFocus
                                  // required={true}
                                  // helperText={
                                  //   googleNotFound
                                  //     ? 'Address not found, please try again'
                                  //     : ''
                                  // }
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <NumericFormat
                                  customInput={TextValidator}
                                  placeholder="Asking Price"
                                  label="Asking Price"
                                  thousandSeparator={true}
                                  prefix={'$'}
                                  fullWidth={true}
                                  margin="dense"
                                  variant="outlined"
                                  size="small"
                                  onFocus={(event) => {
                                    event.target.select()
                                  }}
                                  allowNegative={false}
                                  decimalScale={0}
                                  value={userListPrice}
                                  onValueChange={(values) => {
                                    const { value } = values
                                    setUserListPrice(value)
                                  }}
                                  validators={['maxStringLength: 20']}
                                  errorMessages={[
                                    'You cannot exceed 20 characters',
                                  ]}
                                  helperText="Not sure? Give us a ballpark estimate"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Query query={GET_LOT_PORTFOLIOS}>
                                  {({ loading, error, data }) => {
                                    if (loading) return ''
                                    if (error)
                                      return `error! ${error.message}`
                                    if (
                                      !find(
                                        data.lotPortfolios,
                                        (portfolio) =>
                                          portfolio._id ===
                                          selectedPortfolio,
                                      )
                                    )
                                      setSelectedPortfolio(
                                        head(data.lotPortfolios)._id,
                                      )
                                    return (
                                      <FormControl
                                        variant="outlined"
                                        margin="dense"
                                        style={{ width: '100%' }}
                                      >
                                        <InputLabel id="demo-simple-select-label">
                                          Portfolio
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          name="selectedPortfolio"
                                          value={selectedPortfolio}
                                          onChange={(event) =>
                                            setSelectedPortfolio(
                                              event.target.value,
                                            )
                                          }
                                          open={openPortfolio}
                                          onOpen={handleOpenPortfolio}
                                          onClose={
                                            handleClosePortfolio
                                          }
                                          MenuProps={{
                                            keepMounted: true,
                                          }}
                                          fullWidth={true}
                                          label="Portfolio"
                                        >
                                          {map(
                                            data.lotPortfolios,
                                            (option) => (
                                              <MenuItem
                                                key={option.name}
                                                value={option._id}
                                              >
                                                {option.name}
                                              </MenuItem>
                                            ),
                                          )}
                                        </Select>
                                      </FormControl>
                                    )
                                  }}
                                </Query>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl>
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={ownedLot}
                                    onChange={(event) =>
                                      setOwnedLot(event.target.value)
                                    }
                                  >
                                    <FormControlLabel
                                      control={<Radio />}
                                      value="edit"
                                      label={
                                        <Typography>
                                          Analyze Lot
                                        </Typography>
                                      }
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      value="approved"
                                      label={
                                        <Typography>
                                          View Lot
                                        </Typography>
                                      }
                                    />
                                    <FormControlLabel
                                      control={<Radio />}
                                      value="owned"
                                      label={
                                        <Typography>
                                          I Own This Lot
                                        </Typography>
                                      }
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            </Grid>
                            {/* </DialogContent> */}
                          </BlockUi>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={handleClose1}
                            variant="text"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!selectedPortfolio || loading}
                          >
                            Search
                          </Button>
                        </DialogActions>
                      </ValidatorForm>
                    </Dialog>
                  )}
                </Query>
              )
            }}
          </Query>
        )}
      </ApolloConsumer>

      <Dialog
        open={Boolean(open2)}
        onClick={(event) => event.stopPropagation()}
        onContextMenu={(event) => event.stopPropagation()}
        onClose={handleClose2}
        onKeyDown={stopPropagationForTab}
      >
        <BlockUi
          blocking={
            loader ||
            propertyLoading ||
            lotPortfolioLoading ||
            lotLoading ||
            lotProcessesLoading ||
            isEmpty(analysisInput)
          }
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              <p>{loader ? 'Updating Lot' : 'Loading'}</p>
            </div>
          }
        >
          <DialogTitle style={{ padding: 15 }}>Edit Lot</DialogTitle>
          {propertyLoading ||
          lotPortfolioLoading ||
          lotLoading ||
          lotProcessesLoading ||
          isEmpty(analysisInput) ? (
            <Typography>Loading</Typography>
          ) : (
            <DialogContent style={{ padding: 15 }}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <Grid container style={{ marginTop: 20 }}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Lot Details
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    {map(lotDetailFields, (field) =>
                      renderInputFields(false)(field),
                    )}
                  </Grid>
                  <Divider
                    style={{
                      fontWeight: 600,
                      marginTop: 25,
                      marginBottom: 25,
                    }}
                  />
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6">Lot Loan</Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    {map(lotLoanFields, (field) =>
                      renderInputFields(false)(field),
                    )}
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Construction Loan
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    {map(constructionLoanFields, (field) =>
                      renderInputFields(false)(field),
                    )}
                  </Grid>
                  {map(
                    analysisInput.b2rMezzanineOfferPrice,
                    (value, index) => (
                      <>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography variant="h6">
                              Mezzanine Loan {index + 1}
                            </Typography>
                          </Grid>
                          {index + 1 ===
                            analysisInput.b2rMezzanineOfferPrice
                              .length && (
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={createNewMezzanine}
                              >
                                <Add />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                        <Grid container direction="row" spacing={1}>
                          {map(mezzanineLoanFields(index), (field) =>
                            renderInputFields(false)(field),
                          )}
                        </Grid>
                      </>
                    ),
                  )}
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6">Input</Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    {map(inputFields, (field) =>
                      renderInputFields(false)(field),
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6">End Buyer</Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1}>
                  {map(endBuyerFields, (field) =>
                    renderInputFields(false)(field),
                  )}
                </Grid>
                <Grid container style={{ marginTop: 20 }}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Targets</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  style={{ marginBottom: 10 }}
                >
                  {map(lotTargetFields, (field) => (
                    <Grid item xs={field.xs} sm={field.sm}>
                      <Card>
                        <CardContent>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              className="gridItem2" /*xs={field.xs} sm={field.sm}*/
                            >
                              <Typography>{field.label}:</Typography>
                            </Grid>
                            <Grid item className="gridItem2">
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                {field.label === 'Net Profit' && (
                                  <Grid item className="gridItem2">
                                    {assumptions.belowB2RNetProfit >
                                    netProfit ? (
                                      <Avatar
                                        style={{
                                          backgroundColor: '#F05248',
                                          height: 15,
                                          width: 15,
                                        }}
                                      >
                                        <ArrowDownwardIcon
                                          style={{
                                            color: 'white',
                                            fontSize: 'small',
                                          }}
                                        />
                                      </Avatar>
                                    ) : (
                                      <Avatar
                                        style={{
                                          backgroundColor: '#46de64',
                                          height: 15,
                                          width: 15,
                                        }}
                                      >
                                        <ArrowUpwardIcon
                                          style={{
                                            color: 'white',
                                            fontSize: 'small',
                                          }}
                                        />
                                      </Avatar>
                                    )}
                                  </Grid>
                                )}
                                {field.label === 'Cap Rate' && (
                                  <Grid item className="gridItem2">
                                    {assumptions.belowB2RCapRate >
                                    analytics.B2R.capRateB2R * 100 ? (
                                      <Avatar
                                        style={{
                                          backgroundColor: '#F05248',
                                          height: 15,
                                          width: 15,
                                        }}
                                      >
                                        <ArrowDownwardIcon
                                          style={{
                                            color: 'white',
                                            fontSize: 'small',
                                          }}
                                        />
                                      </Avatar>
                                    ) : (
                                      <Avatar
                                        style={{
                                          backgroundColor: '#46de64',
                                          height: 15,
                                          width: 15,
                                        }}
                                      >
                                        <ArrowUpwardIcon
                                          style={{
                                            color: 'white',
                                            fontSize: 'small',
                                          }}
                                        />
                                      </Avatar>
                                    )}
                                  </Grid>
                                )}
                                <Grid item className="gridItem2">
                                  <Typography>
                                    {field.label === 'Cap Rate'
                                      ? percentageFormat.format(
                                          field.value,
                                        )
                                      : field.decimal
                                        ? currencyFormat2.format(
                                            field.value,
                                          )
                                        : currencyFormat.format(
                                            field.value,
                                          )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  style={{ marginBottom: 10 }}
                >
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader
                        title={
                          <Typography variant="h6">
                            Per sqft
                          </Typography>
                        }
                      />
                      <Divider />
                      <CardContent>
                        {map(PerSqftLotTargetFields, (field) => (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Grid
                              item
                              className="gridItem2" /*xs={field.xs} sm={field.sm}*/
                            >
                              <Typography>{field.label}:</Typography>
                            </Grid>
                            <Grid item className="gridItem2">
                              <Typography>
                                {field.decimal
                                  ? currencyFormat2.format(
                                      field.value,
                                    )
                                  : currencyFormat.format(
                                      field.value,
                                    )}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={handleClose2} variant="text">
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!noFieldEmpty}
              style={{ backgroundColor: '#ff3f3d', color: 'white' }}
              type="submit"
              onClick={() => {
                setLoader(true)
                updateLot({
                  variables: {
                    id: open2,
                    lotInput: { status: 'rejected' },
                  },
                }).then(() => {
                  setLoader(false)
                  handleClose2()
                })
              }}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              disabled={!noFieldEmpty}
              color="primary"
              type="submit"
              onClick={() => {
                setLoader(true)
                updateLot({
                  variables: {
                    id: open2,
                    lotInput: { status: 'approved' },
                  },
                  refetchQueries: [
                    {
                      query: GET_LOTS,
                    },
                  ],
                }).then(() => {
                  setLoader(false)
                  handleClose2()
                  navigate('/' + open2 + '/editLot')
                })
              }}
            >
              Approve
            </Button>
          </DialogActions>
        </BlockUi>
      </Dialog>
    </>
  )
}

export default withSession(AddLotButton)
