import React from 'react'

import { ExampleWrapperSeamless } from '@/layout-components'

import SignInCard from '@/housefolios-components/SignIn/SignInCard'

import { Grid } from '@mui/material'

export default function SignInPage(props) {
  return (
    <>
      <ExampleWrapperSeamless>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} className="d-flex w-100">
            <SignInCard {...props} />
          </Grid>
        </Grid>
      </ExampleWrapperSeamless>
    </>
  )
}
