/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: Daniel Clark
 * @ Modified time: 2022-05-06 15:24:08
 * @ Description: Table showing all of the organizations transactions
 */

import React, { useState } from 'react'
import Pagination from '@mui/material/Pagination'

import map from 'lodash/map'
import find from 'lodash/find'
import ceil from 'lodash/ceil'
import slice from 'lodash/slice'
import moment from 'moment'
import get from 'lodash/get'
import isString from 'lodash/isString'
import every from 'lodash/every'
import isNil from 'lodash/isNil'

import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  Menu,
  Table,
  TableCell,
  TableSortLabel,
  Tooltip,
} from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HousefoliosUser from '@/assets/images/Housefolios_user.png'
import { Query } from '@apollo/client/react/components'
import { GET_TRANSACTIONS } from '../queries'
import withSession from '@/housefolios-components/Session/withSession'
import { enqueueSnackbar } from 'notistack'
import { GET_ME } from '@/housefolios-components/Session/queries'
import useSorting, {
  stableSort,
  getSorting,
} from '@/hooks/useSorting'

// const status = [
//   {
//     name: orgCreator,
//     color: '#4290ff',
//   },
//   {
//     name: pending,
//     color: '#FF8300',
//   },
//   {
//     name: deactivated,
//     color: '#E4000F',
//   },
//   {
//     name: active,
//     color: '#46de64',
//   },
// ]

function TransactionsTableComponent(props) {
  const [{ order, orderBy }, { handleRequestSort }] = useSorting({
    defaultOrder: 'desc',
    defaultOrderBy: 'created',
  })

  const [page, setPage] = useState(
    parseInt(localStorage.getItem('team-page')) || 1,
  )
  const handleChange = (event, value) => {
    localStorage.setItem('team-page', value)
    setPage(value)
  }

  const visuallyHidden = {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }

  const tableHeaders = [
    { title: 'Payment Amount', name: 'amount' },
    { title: 'Refund Amount', name: 'amount_refunded' },
    { title: 'Description', name: 'description' },
    { title: 'Date', name: 'created' },
  ]

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return (
    <Query query={GET_TRANSACTIONS}>
      {({ loading, error, data }) => {
        if (loading) return 'Loading...'
        if (error) return `Error! ${error.message}`
        const count = ceil(data.transactions.length / 20)
        return (
          <Card
            className="card-box"
            /* mb-spacing-6-x2"*/ style={{ width: '100%' }}
          >
            <div className="card-header pr-2">
              <div className="card-header--title">
                <small>{props.session.me.activeOrg.name}</small>
                <b>Transactions</b>
              </div>
              <div className="card-header--actions"></div>
            </div>
            <CardContent className="p-0">
              <div className="table-responsive-md">
                <Table className="table text-nowrap mb-0">
                  <thead className="thead-light">
                    <tr className="text-center">
                      {map(tableHeaders, (column) => (
                        <TableCell
                          key={column.title}
                          //style={{ whiteSpace: 'nowrap' }}
                          align={column.align}
                          sortDirection={
                            orderBy === column.name ? order : false
                          }
                        >
                          {column.name ? (
                            <TableSortLabel
                              active={orderBy === column.name}
                              direction={order}
                              onClick={(event) =>
                                handleRequestSort(event, column.name)
                              }
                            >
                              {column.title}
                              {orderBy === column.name ? (
                                <span style={visuallyHidden}>
                                  {order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                                </span>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            column.title
                          )}
                        </TableCell>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {map(
                      slice(
                        stableSort(
                          data.transactions,
                          getSorting(order, orderBy),
                        ),
                        (page - 1) * 20,
                        page * 20,
                      ),
                      (transaction) => (
                        <tr>
                          <td>
                            <div>
                              {currencyFormat.format(
                                transaction.amount / 100,
                              )}
                            </div>
                          </td>
                          <td>
                            <div>
                              {transaction.amount_refunded
                                ? currencyFormat.format(
                                    (-1 *
                                      transaction.amount_refunded) /
                                      100,
                                  )
                                : 0}
                            </div>
                          </td>
                          <td>
                            <div>{transaction.description}</div>
                          </td>
                          <td>
                            <div>
                              {moment(transaction.created).format(
                                'MMM Do YYYY',
                              )}
                            </div>
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="divider" />
              <div className="divider" />
              <div className="p-3 d-flex justify-content-center">
                <Pagination
                  className="pagination-first"
                  size="small"
                  count={count}
                  page={page}
                  onChange={handleChange}
                />
              </div>
            </CardContent>
          </Card>
        )
      }}
    </Query>
  )
}

export default withSession(TransactionsTableComponent)
