/**
 * @ Author: Housefolios
 * @ Create Time: 2023-05-31 10:49:49
 * @ Modified by: David Helmick
 * @ Modified time: 2024-06-05 11:04:04
 * @ Description: Dialog to set an org's Buy Box values
 */

import React, { useEffect, useState } from 'react'

import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'
import clsx from 'clsx'

import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'

import { useMutation } from '@apollo/client'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_LOT } from '@/housefolios-components/Lots/queries'
import { Check, InfoOutlined } from '@mui/icons-material'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import Autocomplete from '@mui/material/Autocomplete'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { NumericFormat } from 'react-number-format'
import filter from 'lodash/filter'
import { getStrategyName } from '@/utils/stratagies'

function StepIcon(props) {
  const { active, completed } = props

  const icons = {
    1: 1,
    2: 2,
    3: 3,
  }

  return (
    <div
      className={clsx(
        'd-50 transition-base d-flex align-items-center bg-gray-400 justify-content-center rounded',
        {
          'd-80 bg-primary text-white shadow-primary-sm': active,
          'd-50 bg-success text-white shadow-success-sm': completed,
        },
      )}
    >
      {completed ? (
        <Check className="completed" />
      ) : (
        icons[String(props.icon)]
      )}
    </div>
  )
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}

function getSteps() {
  return ['Strategy', 'Finances', 'Complete']
}

function BuyBoxComponent(props) {
  const [openBuyBox, setOpenBuyBox] = useState(props.isOpen)
  const [loader, setLoader] = useState(false)
  const [buyBox, setBuyBox] = useState(
    cloneDeep(props.session.me.activeOrg.buyBox) || {},
  )
  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  const isDisabled =
    activeStep === 0 &&
    (isEmpty(buyBox.propertyType) || isEmpty(buyBox.strategies))

  useEffect(() => {
    setOpenBuyBox(props.isOpen)
  }, [props.isOpen])

  const preferences = merge(
    cloneDeep(DEFAULT_PREFERENCES),
    props?.session?.me?.activeOrg?.memberPreferences,
    props?.session?.me?.activeOrg?.preferences,
  )

  const handleOpenBuyBox = () => {
    setOpenBuyBox(true)
    setActiveStep(0)
  }
  const handleCloseBuyBox = () => {
    setOpenBuyBox(false)
    setLoader(false)
    setActiveStep(0)
    if (props.handleClose) {
      props.handleClose()
    }
    localStorage.setItem(
      `buyBox-${props.session.me.activeOrg._id}`,
      null,
    )
  }

  const prevStep = () => {
    setActiveStep(activeStep - 1)
    setLoader(false)
  }

  const nextStep = () => {
    setActiveStep(activeStep + 1)
    setLoader(false)
  }

  const handleChange = (name, value) => {
    let newBuyBox = cloneDeep(buyBox)
    newBuyBox[name] = value
    if (name === 'otherProperties' && value === 'No') {
      newBuyBox['otherPropertiesValue'] = null
    }
    setBuyBox(newBuyBox)
  }

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const submitChanges = (direction) => {
    updateOrganization({
      variables: {
        id: props.session.me.activeOrg._id,
        organizationInput: {
          buyBox,
        },
      },
      refetchQueries: [
        {
          query: GET_ME,
        },
      ],
    })
      .then(() => {
        enqueueSnackbar(`Buy Box has Updated`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        if (direction === 'next')
          if (activeStep < steps.length - 1) nextStep()
          else handleCloseBuyBox()
        else prevStep()
      })
      .catch(() => {
        enqueueSnackbar(`Buy Box has failed to Update`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseBuyBox()
      })
  }

  return (
    <>
      <Dialog
        open={openBuyBox}
        onClose={props.noClose ? null : handleCloseBuyBox}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="lg"
        fullWidth={true}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={loader} />
          }
          message={
            <div className="text-primary">Updating Status</div>
          }
        >
          <DialogTitle id="form-dialog-title">Buy Box</DialogTitle>
          <DialogContent className="p-4">
            <div className="bg-secondary mb-3">
              <Typography>
                Filling out your Buy Box will help us customize your
                experience in Housefolios. With this information we
                can help you find properties that match your situation
                and preference. This information is not required,
                however we recommend filling out all fields.
              </Typography>
            </div>
            <div className="bg-secondary mb-3">
              <Stepper
                style={{ padding: 24 }}
                className="stepper-horizontal-1"
                alternativeLabel
                activeStep={activeStep}
                connector={<StepConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={StepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {activeStep === 0 && (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12}>
                  <Typography>
                    {' '}
                    What type of property are you interested in?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    options={[
                      'Single Family',
                      'Multi Family (1-4)',
                      'Multi Family (5-20)',
                      'Multi Family (20+)',
                    ]}
                    disableCloseOnSelect
                    value={buyBox.propertyType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Property Types"
                      />
                    )}
                    onChange={(event, newValue) => {
                      handleChange('propertyType', newValue)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {' '}
                    What strategies are you interested in?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    options={map(
                      filter(
                        preferences.general['strategies'],
                        function (strategy) {
                          return (
                            !strategy.memberOnly ||
                            strategy.memberOnly ===
                              props?.session?.me?.activeOrg?.member
                          )
                        },
                      ),
                      (pref) => pref.value,
                    )}
                    getOptionLabel={(option) =>
                      getStrategyName(
                        option,
                        props?.session?.me?.activeOrg?.member,
                      )
                    }
                    disableCloseOnSelect
                    value={buyBox.strategies}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Strategies"
                      />
                    )}
                    onChange={(event, newValue) => {
                      handleChange('strategies', newValue)
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12}>
                  <Typography>
                    {' '}
                    How much cash (liquid) do you have to invest in
                    Real Estate?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <NumericFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    prefix={'$'}
                    fullWidth={true}
                    isNumericString={true}
                    decimalScale={0}
                    name="liquidCash"
                    label="Liquid Cash"
                    value={buyBox.liquidCash}
                    onValueChange={(values) => {
                      const { value } = values
                      handleChange('liquidCash', value)
                    }}
                    type={'text'}
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    variant="outlined"
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {' '}
                    How much other capital (less liquid) do you have
                    to invest (Home Equity, Insurance Policies with
                    cash value, etc)?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <NumericFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    prefix={'$'}
                    fullWidth={true}
                    isNumericString={true}
                    decimalScale={0}
                    name="homeEquity"
                    label="Home Equity"
                    value={buyBox.homeEquity}
                    onValueChange={(values) => {
                      const { value } = values
                      handleChange('homeEquity', value)
                    }}
                    type={'text'}
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    variant="outlined"
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12}>
                  <NumericFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    prefix={'$'}
                    fullWidth={true}
                    isNumericString={true}
                    decimalScale={0}
                    name="IRA"
                    label="401K/Self directed IRA"
                    value={buyBox.IRA}
                    onValueChange={(values) => {
                      const { value } = values
                      handleChange('IRA', value)
                    }}
                    type={'text'}
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    variant="outlined"
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12}>
                  <NumericFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    prefix={'$'}
                    fullWidth={true}
                    isNumericString={true}
                    decimalScale={0}
                    name="lifeInsurance"
                    label="Cash Value Life Insurance"
                    value={buyBox.lifeInsurance}
                    onValueChange={(values) => {
                      const { value } = values
                      handleChange('lifeInsurance', value)
                    }}
                    type={'text'}
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    variant="outlined"
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12}>
                  <NumericFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    prefix={'$'}
                    fullWidth={true}
                    isNumericString={true}
                    decimalScale={0}
                    name="preciousMetals"
                    label="Precious Metals"
                    value={buyBox.preciousMetals}
                    onValueChange={(values) => {
                      const { value } = values
                      handleChange('preciousMetals', value)
                    }}
                    type={'text'}
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    variant="outlined"
                    margin="dense"
                  />
                </Grid>
              </Grid>
            )}
            {activeStep === 2 && (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12}>
                  <Typography>
                    {' '}
                    How much would a bank allow you to borrow today?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <NumericFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    prefix={'$'}
                    fullWidth={true}
                    isNumericString={true}
                    decimalScale={0}
                    name="bankLimit"
                    label="Bank Limit"
                    value={buyBox.bankLimit}
                    onValueChange={(values) => {
                      const { value } = values
                      handleChange('bankLimit', value)
                    }}
                    type={'text'}
                    onFocus={(event) => {
                      event.target.select()
                    }}
                    variant="outlined"
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {' '}
                    Do you own other investment properties that you
                    would be interested in selling if there was a
                    chance you could buy better cash flowing
                    properties?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={['Yes', 'No']}
                    value={buyBox.otherProperties}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Other Properties"
                      />
                    )}
                    onChange={(event, newValue) => {
                      handleChange('otherProperties', newValue)
                    }}
                  />
                </Grid>
                {buyBox.otherProperties == 'Yes' && (
                  <Grid item xs={12}>
                    <NumericFormat
                      customInput={TextField}
                      thousandSeparator={true}
                      prefix={'$'}
                      fullWidth={true}
                      isNumericString={true}
                      decimalScale={0}
                      name="otherPropertiesValue"
                      label="Other Properties Value"
                      value={buyBox.otherPropertiesValue}
                      onValueChange={(values) => {
                        const { value } = values
                        handleChange('otherPropertiesValue', value)
                      }}
                      type={'text'}
                      onFocus={(event) => {
                        event.target.select()
                      }}
                      variant="outlined"
                      margin="dense"
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Typography style={{ flex: 1 }} />
            {props?.session?.me?.activeOrg?.member !== 'roitk' && (
              <Button
                variant="text"
                onClick={() => {
                  handleCloseBuyBox()
                  enqueueSnackbar(
                    `You can update this in your Settings, Organization Preferences page`,
                    {
                      variant: 'info',
                      autoHideDuration: 3000,
                    },
                  )
                }}
              >
                Skip
              </Button>
            )}
            {activeStep > 0 && (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginRight: 10 }}
                onClick={() => {
                  setLoader(true)
                  submitChanges('previous')
                }}
              >
                Previous Step
              </Button>
            )}
            <Button
              disabled={isDisabled}
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginRight: 10 }}
              onClick={() => {
                setLoader(true)
                submitChanges('next')
              }}
            >
              {activeStep < steps.length - 1 ? 'Next Step' : 'Finish'}
            </Button>
          </DialogActions>
        </BlockUi>
      </Dialog>
    </>
  )
}

export default withSession(BuyBoxComponent)
