/**
 * @ Author: Housefolios
 * @ Create Time: 2021-10-05 11:07:32
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-04 12:06:42
 * @ Description: Main component of the create account page
 */

import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useQuery, useMutation } from '@apollo/client'
import { Mutation, Query } from '@apollo/client/react/components'
import map from 'lodash/map'
import CountUp from 'react-countup'
import replace from 'lodash/replace'
import split from 'lodash/split'
import upperFirst from 'lodash/upperFirst'
import toLower from 'lodash/toLower'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'

import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Backdrop,
  CircularProgress,
} from '@mui/material'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { CREATE_SESSION } from '@/housefolios-components/LaunchpadComponents/mutations'

import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

import {
  ValidatorForm,
  TextValidator,
} from 'react-material-ui-form-validator'

import moneyBackGuarantee from '@/assets/images/moneyBackGuarantee.png'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import ImageIcon from '@mui/icons-material/Image'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

import createAccountLogo from '@/assets/images/NewCheckoutLogo.png'
import bgNewLarge from '@/assets/images/bgNewLarge.png'
import { GET_PARTNER, GET_SUBSCRIPTION_PLAN } from './queries'
import PaymentForm from '../PaymentForm'
import { CHECK_SIGN_UP, GET_COUPON, SIGN_UP } from './mutations'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { includes } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Check } from '@mui/icons-material'
import { SUBSCRIBE } from '@/housefolios-components/Session/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import useAffiliate from '@/hooks/useAffiliate'
import {
  useNavigate,
  useParams,
  Link as RouterLink,
} from 'react-router-dom'

function CreateAccountCardComponent(props) {
  const { planName } = useParams()
  // const [searchStatus1, setSearchStatus1] = useState(false);
  // const toggleSearch1 = () => setSearchStatus1(!searchStatus1);

  const [selectedPlan, setSelectedPlan] = useState(planName)
  const [tab, setTab] = useState(0)

  let navigate = useNavigate()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  let urlFirstName = urlParams.get('firstName') || ''
  let urlEmail = urlParams.get('email') || ''
  let hfpid = urlParams.get('hfpid')
  let refOrg = urlParams.get('org')
  let source = urlParams.get('source')
  let priceLimit = urlParams.get('limit')
  let headerImage = createAccountLogo
  let allowedPlans = []
  let applyCoupon = ''
  let applyPercent = null
  let applyCouponAmount = null
  let disablePromoCode = false
  let memberTrial = 0
  let trialInvoiceAmount = 0

  const isCoached = includes(selectedPlan, 'coaching')

  const [paymentIntervals, setPaymentIntervals] = useState(
    parseInt(urlParams.get('payments')) || 1,
  )

  if (isCoached) {
    disablePromoCode = true
  }
  if (includes(selectedPlan, 'group-coaching') && !applyCoupon) {
    if (paymentIntervals == 1) {
      applyCoupon = 'GTzjUUmX'
    } else if (paymentIntervals == 2) {
      applyCoupon = 'CxxvDO0k'
    } else if (paymentIntervals == 3) {
      applyCoupon = 'arM9K3su'
    }
  }

  //OVERVIEW COMMENT: This is a setup of Affiliate information
  let result = useQuery(GET_PARTNER, {
    variables: { name: source },
  })
  const partnerLoading = result.loading
  const { partner = {} } = result.data || {}

  //// let rockstarReferrer = ["https://go.housefolios.com/rerockstars"]
  //// let myndReferrer = ["https://housefolios.com/mynd/", "https://www.housefolios.com/mynd/"]
  ////if (source === "rerockstars" && (includes(rockstarReferrer, document.referrer) || includes(map(rockstarReferrer, ref => ref+'/'), document.referrer)) && selectedPlan === 'investor-yearly')
  // if (source === "rerockstars" && selectedPlan === 'investor-yearly')
  // {
  //     applyCoupon = 'OXpyi0Nc'
  //     applyCouponAmount = 17700
  //     disablePromoCode = true
  // }
  // ////if (source === "mynd" && (includes(myndReferrer, document.referrer) || includes(map(myndReferrer, ref => ref+'/'), document.referrer)))
  // if (source === "mynd")
  // {
  //     applyCoupon = '9a309m9M'
  //     applyPercent = 50
  //     disablePromoCode = true
  // }

  ////let paulReferrer = ["https://housefolios.com/paul", "https://www.housefolios.com/paul"]
  ////if (source === "paulmccomas" && (includes(paulReferrer, document.referrer) || includes(map(paulReferrer, ref => ref+'/'), document.referrer)))

  //OVERVIEW COMMENT: This is a setup of Member information
  const [{ affiliate, affiliateLoading, urlAffiliate }] =
    useAffiliate({
      memberValue: source,
    })

  if (!affiliateLoading && !partnerLoading) {
    if (isEmpty(affiliate) && isEmpty(partner)) {
      if (!isCoached) {
        source = null
        memberTrial = 7
        trialInvoiceAmount = 1
      }
    } else {
      let { createAccount = {} } = isEmpty(affiliate)
        ? partner
        : affiliate
      if (!createAccount) createAccount = {}
      hfpid = affiliate ? affiliate.hfpid : ''
      headerImage =
        get(createAccount, 'headerImage.0.url') || createAccountLogo
      applyCoupon = createAccount.applyCoupon || ''
      applyPercent = createAccount.applyPercent || null
      applyCouponAmount = createAccount.applyCouponAmount || null
      if (!isCoached) {
        memberTrial = createAccount.memberTrial || 0
        trialInvoiceAmount = createAccount.trialInvoiceAmount || 0
        disablePromoCode = createAccount.disablePromoCode || false
      }
      allowedPlans = createAccount.plans || []
    }
  }

  useEffect(() => {
    if (includes(selectedPlan, 'group-coaching')) {
      if (paymentIntervals == 1) {
        applyCoupon = 'GTzjUUmX'
      } else if (paymentIntervals == 2) {
        applyCoupon = 'CxxvDO0k'
      } else if (paymentIntervals == 3) {
        applyCoupon = 'arM9K3su'
      }
      setCoupon(applyCoupon)
      checkCoupon(applyCoupon)
    } else {
      setCoupon('')
      checkCoupon('')
    }
  }, [selectedPlan])

  useEffect(() => {
    if (isCoached) {
      disablePromoCode = true
    }

    if (!affiliateLoading && !partnerLoading) {
      if (isEmpty(affiliate) && isEmpty(partner)) {
        if (!isCoached) {
          source = null
          memberTrial = 7
          trialInvoiceAmount = 1
        }
      } else {
        let { createAccount = {} } = isEmpty(affiliate)
          ? partner
          : affiliate
        if (!createAccount) createAccount = {}
        hfpid = affiliate ? affiliate.hfpid : ''
        headerImage =
          get(createAccount, 'headerImage.0.url') || createAccountLogo
        applyCoupon = createAccount.applyCoupon || ''
        applyPercent = createAccount.applyPercent || null
        applyCouponAmount = createAccount.applyCouponAmount || null
        if (!isCoached) {
          memberTrial = createAccount.memberTrial || 0
          trialInvoiceAmount = createAccount.trialInvoiceAmount || 0
          disablePromoCode = createAccount.disablePromoCode || false
        }
        allowedPlans = createAccount.plans || []
      }
    }
  }, [isCoached])

  useEffect(() => {
    if (includes(selectedPlan, 'group-coaching')) {
      if (paymentIntervals == 1) {
        applyCoupon = 'GTzjUUmX'
      } else if (paymentIntervals == 2) {
        applyCoupon = 'CxxvDO0k'
      } else if (paymentIntervals == 3) {
        applyCoupon = 'arM9K3su'
      }
      setCoupon(applyCoupon)
      checkCoupon(applyCoupon)
    }
  }, [paymentIntervals])

  const [token, setToken] = useState('')
  //const [element, setElement] = useState(null)
  const [resetToken, setResetToken] = useState(() => {
    return null
  })
  const [coupon, setCoupon] = useState(applyCoupon)
  const [couponValid, setCouponValid] = useState(
    applyCoupon ? true : false,
  )
  const [couponPercent, setCouponPercent] = useState(applyPercent)
  const [couponAmount, setCouponAmount] = useState(applyCouponAmount)

  const [email, setEmail] = useState(urlEmail)
  const [firstName, setFirstName] = useState(urlFirstName)
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [passwordMask, setPasswordMask] = useState(true)

  const [activeTab, setActiveTab] = useState('Annual')

  const tabSwitch = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const [openBillingInfo, setOpenBillingInfo] = useState(false)
  const handleOpenBillingInfo = () => {
    setOpenBillingInfo(true)
  }
  const handleCloseBillingInfo = () => {
    setOpenBillingInfo(false)
  }

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const currencyFormat2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const [createBillingPortalSession] = useMutation(CREATE_SESSION)

  const changePlan = async () => {
    var session = await createBillingPortalSession()

    window.open(
      session.data.createBillingPortalSession.url,
      '_self',
      '',
    )
  }

  //  useEffect(() => {
  //    if (!affiliateLoading && !partnerLoading) {
  //      setCoupon(applyCoupon)
  //      setCouponValid(applyCoupon ? true : false)
  //      setCouponPercent(applyPercent)
  //      setCouponAmount(applyCouponAmount)
  //      if (selectedPlan && !isEmpty(allowedPlans) && !includes(allowedPlans, selectedPlan)) {
  //        setSelectedPlan(head(allowedPlans))
  //      }
  //    }
  //  }, [affiliateLoading, partnerLoading, selectedPlan])

  const [getCoupon] = useMutation(GET_COUPON)

  //  const pricingPlans = [
  //    {
  //      nameMonthly: 'investor-monthly',
  //      nameYearly: 'investor-yearly',
  //      label: 'Investor',
  //      subHeaderMonthly: 'Monthly Plan',
  //      subHeaderYearly: 'Annual Plan',
  //    },
  //    {
  //      nameMonthly: 'growth-monthly',
  //      nameYearly: 'growth-yearly',
  //      label: 'Growth',
  //      subHeaderMonthly: 'Monthly Plan',
  //      subHeaderYearly: 'Annual Plan',
  //    },
  //    {
  //      nameMonthly: 'pro-monthly',
  //      nameYearly: 'pro-yearly',
  //      label: 'Pro',
  //      subHeaderMonthly: 'Monthly Plan',
  //      subHeaderYearly: 'Annual Plan',
  //    },
  //  ]

  const createToken = async (stripe, cardElement, nameOnCard) => {
    if (!nameOnCard) setToken('')
    else {
      await stripe
        // .createToken({
        //   type: 'card',
        //   card: cardElement,
        //   name: nameOnCard,
        // })
        .createToken(cardElement, {
          name: nameOnCard,
        })
        .then((res) => {
          var errorElement = document.getElementById('card-errors')
          if (res.error) {
            setToken('')
            if (errorElement)
              errorElement.textContent = res.error.message
          } else {
            setToken(res.token)
            if (errorElement) errorElement.textContent = null
          }
        })
    }
  }

  const checkCoupon = async (coupon) => {
    if (!coupon) setCouponValid(null)
    else
      await getCoupon({
        variables: {
          code: coupon,
        },
      })
        .then(async ({ data }) => {
          if (
            !data.getCoupon.maxRedemptions ||
            data.getCoupon.maxRedemptions >
              data.getCoupon.timesRedeemed
          ) {
            setCouponValid(true)
            setCouponPercent(data.getCoupon.percentOff)
            setCouponAmount(data.getCoupon.amountOff)
          } else {
            enqueueSnackbar(`Promo Code not valid`, {
              variant: 'error',
              autoHideDuration: 3000,
            })
            setCouponValid(false)
          }
        })
        .catch(async ({ error }) => {
          enqueueSnackbar(`Promo Code not valid`, {
            variant: 'error',
            autoHideDuration: 3000,
          })
          setCouponValid(false)
          setCouponPercent(null)
          setCouponAmount(null)
          setCoupon('')
        })
  }

  const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY
  const stripePromise = loadStripe(stripePublicKey)

  const toggle = (tab) => {
    setSelectedPlan(tab)
  }

  useEffect(() => {
    if (urlParams.get('coupon')) {
      setCoupon(urlParams.get('coupon'))
      checkCoupon(urlParams.get('coupon'))
    } else if (coupon) {
      checkCoupon(coupon)
    }
  }, [])

  const [agree, setAgree] = useState(false)

  const [loader, setLoader] = useState(false)

  const handleSubmit = async (
    signUp,
    checkSignup,
    planID,
    planPrice,
    entryPlanId,
  ) => {
    if (!agree) return
    setLoader(true)
    const productChoice = toLower(selectedPlan).includes('starter')
      ? 'starter'
      : toLower(selectedPlan).includes('investor')
        ? 'investor'
        : toLower(selectedPlan).includes('growth')
          ? 'growth'
          : toLower(selectedPlan).includes('pro')
            ? 'pro'
            : toLower(selectedPlan).includes('enterprise')
              ? 'enterprise'
              : ''
    let gaID = hfpid
      ? upperFirst(productChoice) +
        '_' +
        hfpid.toString() +
        '_' +
        email
      : productChoice + '_' + email

    if (coupon && !couponValid) {
      await getCoupon({
        variables: {
          code: coupon,
        },
      })
        .then(async ({ data }) => {
          if (
            !data.getCoupon.maxRedemptions ||
            data.getCoupon.maxRedemptions >
              data.getCoupon.timesRedeemed
          ) {
            setCouponValid(true)
            setCouponPercent(data.getCoupon.percentOff)
            setCouponAmount(data.getCoupon.amountOff)
          } else {
            enqueueSnackbar(`Promo Code not valid`, {
              variant: 'error',
              autoHideDuration: 3000,
            })
            setCouponValid(false)
          }
        })
        .catch(async ({ error }) => {
          enqueueSnackbar(`Promo Code not valid`, {
            variant: 'error',
            autoHideDuration: 3000,
          })
          setCouponValid(false)
          setCoupon('')
          setCouponPercent(null)
          setCouponAmount(null)
        })
    }
    checkSignup()
      .then(async ({ data }) => {
        signUp().then(async ({ data }) => {
          ReactPixel.track('StartTrial', {
            value: 0,
            currency: 'USD',
            predicted_ltv: 0,
            description:
              `email=${email}` +
              '&' +
              `productChoice=${productChoice}` +
              '&' +
              (hfpid ? `hfpid=${hfpid.toString()}` : ''),
          })
          localStorage.setItem(`buyBox-${data.signUp.orgId}`, true)
          localStorage.setItem('token', data.signUp.token)
          document.cookie = `SourceSignUp=${source}; expires=Wed, 31 Dec 2025 12:00:00 UTC;`
          setLoader(false)
          setAgree(false)
          setTab(selectedPlan ? 2 : 1)
        })
      })
      .catch((error) => {
        console.log('"' + error.message + '"')
        // if (element) element.clear()
        //resetToken()
        setLoader(false)
      })
  }

  let planOptions = [
    {
      title: (
        <Query
          query={GET_SUBSCRIPTION_PLAN}
          variables={{ subscriptionPlan: 'investor-monthly' }}
        >
          {({ loading, error, data }) => {
            if (loading) return 'loading'
            const { subscriptionPlan = {} } = data
            return (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>Investor plan - Monthly</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {currencyFormat.format(
                      subscriptionPlan.price / 100,
                    )}
                    /mo
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
        </Query>
      ),
      textAlign: 'left',
      name: 'investor-monthly',
    },
    {
      title: (
        <Query
          query={GET_SUBSCRIPTION_PLAN}
          variables={{ subscriptionPlan: 'growth-monthly' }}
        >
          {({ loading, error, data }) => {
            if (loading) return 'loading'
            const { subscriptionPlan = {} } = data
            return (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>Growth plan - Monthly</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {currencyFormat.format(
                      subscriptionPlan.price / 100,
                    )}
                    /mo
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
        </Query>
      ),
      textAlign: 'left',
      name: 'growth-monthly',
    },
    {
      title: (
        <Query
          query={GET_SUBSCRIPTION_PLAN}
          variables={{ subscriptionPlan: 'pro-monthly' }}
        >
          {({ loading, error, data }) => {
            if (loading) return 'loading'
            const { subscriptionPlan = {} } = data
            return (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>Pro plan - Monthly</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {currencyFormat.format(
                      subscriptionPlan.price / 100,
                    )}
                    /mo
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
        </Query>
      ),
      textAlign: 'left',
      name: 'pro-monthly',
    },
    {
      title: (
        <Query
          query={GET_SUBSCRIPTION_PLAN}
          variables={{ subscriptionPlan: 'investor-yearly' }}
        >
          {({ loading, error, data }) => {
            if (loading) return 'loading'
            const { subscriptionPlan = {} } = data
            return (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>Investor plan - Yearly</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {currencyFormat.format(
                      subscriptionPlan.price / 12 / 100,
                    )}
                    /mo
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
        </Query>
      ),
      textAlign: 'left',
      name: 'investor-yearly',
    },
    {
      title: (
        <Query
          query={GET_SUBSCRIPTION_PLAN}
          variables={{ subscriptionPlan: 'growth-yearly' }}
        >
          {({ loading, error, data }) => {
            if (loading) return 'loading'
            const { subscriptionPlan = {} } = data
            return (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>Growth plan - Yearly</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {currencyFormat.format(
                      subscriptionPlan.price / 12 / 100,
                    )}
                    /mo
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
        </Query>
      ),
      textAlign: 'left',
      name: 'growth-yearly',
    },
    {
      title: (
        <Query
          query={GET_SUBSCRIPTION_PLAN}
          variables={{ subscriptionPlan: 'pro-yearly' }}
        >
          {({ loading, error, data }) => {
            if (loading) return 'loading'
            const { subscriptionPlan = {} } = data
            return (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>Pro plan - Yearly</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {currencyFormat.format(
                      subscriptionPlan.price / 12 / 100,
                    )}
                    /mo
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
        </Query>
      ),
      textAlign: 'left',
      name: 'pro-yearly',
    },
    {
      title: (
        <Query
          query={GET_SUBSCRIPTION_PLAN}
          variables={{ subscriptionPlan: 'group-coaching' }}
        >
          {({ loading, error, data }) => {
            if (loading) return 'loading'
            const { subscriptionPlan = {} } = data
            return (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>Group Coaching - Monthly</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {currencyFormat.format(
                      subscriptionPlan.price / 100,
                    )}
                    /mo
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
        </Query>
      ),
      textAlign: 'left',
      name: 'group-coaching',
    },
    {
      title: (
        <Query
          query={GET_SUBSCRIPTION_PLAN}
          variables={{ subscriptionPlan: 'personalized-coaching' }}
        >
          {({ loading, error, data }) => {
            if (loading) return 'loading'
            const { subscriptionPlan = {} } = data
            return (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>Personalized Coaching plan</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {currencyFormat.format(
                      subscriptionPlan.price / 100,
                    )}
                    /mo
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
        </Query>
      ),
      textAlign: 'left',
      name: 'personalized-coaching',
    },
  ]

  const { loading, data, error } = useQuery(GET_SUBSCRIPTION_PLAN, {
    variables: { subscriptionPlan: selectedPlan },
    errorPolicy: 'ignore',
  })

  const { subscriptionPlan = {} } = data || {}

  let entryPlanId = ''
  let entrySubscriptionPlan = {}
  const entryPlanResults = useQuery(GET_SUBSCRIPTION_PLAN, {
    variables: {
      subscriptionPlan:
        `${selectedPlan}-entry-fee` +
        (paymentIntervals > 1 ? `-${paymentIntervals}-payments` : ''),
    },
    errorPolicy: 'ignore',
  })
  if (isCoached) {
    const entryData = (entryPlanResults || {}).data || {}
    entrySubscriptionPlan = entryData.subscriptionPlan || {}
    entryPlanId = entrySubscriptionPlan.planId
  }

  useEffect(() => {
    if (!loading && subscriptionPlan && subscriptionPlan.price) {
      ReactPixel.track('InitiateCheckout', {
        value: subscriptionPlan.price / 100,
        currency: 'USD',
        description: selectedPlan,
      })
    }
  }, [selectedPlan, loading, subscriptionPlan])

  if (loading || (entryPlanResults || {}).loading) return 'loading'
  if (!subscriptionPlan) {
    setSelectedPlan(head(planOptions).name)
    return 'Invalid Plan'
  }
  const plan = split(subscriptionPlan.subscriptionPlan, '-')
  let trialEnd = new Date()
  trialEnd.setDate(
    trialEnd.getDate() + subscriptionPlan.trialPeriodDays,
  )

  const referredByDetails = {
    affiliateId: hfpid,
    saleDate: new Date(),
    productSelected: subscriptionPlan.subscriptionPlan,
    productTotalPrice: subscriptionPlan.price,
    couponUsed: coupon,
    active: true,
  }

  const tabSwitch2 = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const pricingPlans = [
    //   {
    //     nameMonthly: 'free-monthly',
    //     nameYearly: 'free-yearly',
    //     label: 'Free',
    //     subHeaderMonthly: '',
    //     subHeaderYearly: '',
    //     search: '*Nationwide property search',
    //     analyze: '*Analyze off-market properties',
    //     comp: '*Sold comp data'
    //  },
    //  {
    //    nameMonthly: 'investor-monthly',
    //    nameYearly: 'investor-yearly',
    //     label: 'Investor',
    //     subHeaderMonthly: 'Monthly Plan',
    //     subHeaderYearly: 'Annual Plan',
    //     search: 'Nationwide property search',
    //     analyze: 'Analyze off-market properties',
    //     comp: 'Sold comp data'
    //  },
    //  {
    //    nameMonthly: 'growth-monthly',
    //    nameYearly: 'growth-yearly',
    //     label: 'Growth',
    //     subHeaderMonthly: 'Monthly Plan',
    //     subHeaderYearly: 'Annual Plan',
    //     search: 'Nationwide property search',
    //     analyze: 'Analyze off-market properties',
    //     comp: 'Sold comp data',
    //     //housefoliosMarketplace: 'List on Housefolios marketplace',
    //   },
    //   {
    //     nameMonthly: 'pro-monthly',
    //     nameYearly: 'pro-yearly',
    //     label: 'Pro',
    //     subHeaderMonthly: 'Monthly Plan',
    //     subHeaderYearly: 'Annual Plan',
    //     search: 'Nationwide property search',
    //     analyze: 'Analyze off-market properties',
    //     comp: 'Sold comp data',
    //     //housefoliosMarketplace: 'List on Housefolios marketplace',
    //   },
    // ] : [
    {
      nameMonthly: 'growth-monthly',
      nameYearly: 'growth-yearly',
      label: 'Growth',
      subHeaderMonthly: 'Monthly Plan',
      subHeaderYearly: 'Annual Plan',
      search: 'Nationwide property search',
      analyze: 'Analyze off-market properties',
      comp: 'Sold comp data',
      //housefoliosMarketplace: 'List on Housefolios marketplace',
    },
    {
      nameMonthly: 'pro-monthly',
      nameYearly: 'pro-yearly',
      label: 'Pro',
      subHeaderMonthly: 'Monthly Plan',
      subHeaderYearly: 'Annual Plan',
      search: 'Nationwide property search',
      analyze: 'Analyze off-market properties',
      comp: 'Sold comp data',
      prioritized: 'Prioritized Customer Service',
      access: 'Access to Pro-Level Resources',
      exclusive: 'Exclusive Training Calls',
      listVerified: 'List Verified Properties on Our Marketplace',
      earlyAccess: 'Early Access to Investor Property Listings',
      //housefoliosMarketplace: 'List on Housefolios marketplace',
    },
    {
      nameMonthly: 'investor-monthly',
      nameYearly: 'investor-yearly',
      label: 'Investor',
      subHeaderMonthly: 'Monthly Plan',
      subHeaderYearly: 'Annual Plan',
      search: 'Nationwide property search',
      analyze: 'Analyze off-market properties',
      comp: 'Sold comp data',
    },
    {
      nameMonthly: 'group-coaching',
      nameYearly: 'group-coaching',
      label: 'Group Coaching',
      subHeaderMonthly: 'Plan',
      subHeaderYearly: 'Plan',
      search: 'Nationwide property search',
      analyze: 'Analyze off-market properties',
      comp: 'Sold comp data',
      prioritized: 'Prioritized Customer Service',
      access: 'Access to Pro-Level Resources',
      exclusive: 'Exclusive Training Calls',
      listVerified: 'List Verified Properties on Our Marketplace',
      earlyAccess: 'Early Access to Investor Property Listings',
      groupCoaching: 'Weekly Group Coaching',
      //housefoliosMarketplace: 'List on Housefolios marketplace',
    },
    {
      nameMonthly: 'personalized-coaching',
      nameYearly: 'personalized-coaching',
      label: 'Personalized Coaching',
      subHeaderMonthly: 'Plan',
      subHeaderYearly: 'Plan',
      search: 'Nationwide property search',
      analyze: 'Analyze off-market properties',
      comp: 'Sold comp data',
      prioritized: 'Prioritized Customer Service',
      access: 'Access to Pro-Level Resources',
      exclusive: 'Exclusive Training Calls',
      listVerified: 'List Verified Properties on Our Marketplace',
      earlyAccess: 'Early Access to Investor Property Listings',
      groupCoaching: 'Weekly Group Coaching',
      personalizedCoaching: 'Monthly One on One Coaching',
      //housefoliosMarketplace: 'List on Housefolios marketplace',
    },
  ]

  if (affiliateLoading)
    return (
      <Backdrop open={true} style={{ color: '#fff', zIndex: 2000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )

  return (
    <>
      {/* <div className="app-wrapper min-vh-100 bg-white">
       <div className="app-main min-vh-100">
         <div className="app-content p-0">
           <div className="app-inner-content-layout--main">
             <div className="flex-grow-1 w-100 d-flex align-items-center">
               <div className="bg-composed-wrapper--content"> */}
      <Mutation
        mutation={CHECK_SIGN_UP}
        variables={{
          userInput: {
            email: toLower(email),
          },
        }}
      >
        {(checkSignup, { data, loading, error }) => {
          const signUpError = error
          return (
            <Mutation
              mutation={SIGN_UP}
              variables={{
                userInput: {
                  profile: {
                    firstname: firstName,
                    lastname: lastName,
                  },
                  email: toLower(email),
                  // userType,
                  organization: firstName + lastName,
                  phone: phoneNumber,
                  // address: billingAddress,
                  // city,
                  // state,
                  // zip: postalCode,
                  password: password,
                  //stripeToken: token,
                  //stripePlanId: subscriptionPlan.planId,
                  referredByDetails: hfpid ? referredByDetails : null,
                  coupon,
                  refOrg: refOrg ? refOrg : null,
                  source,
                  sendEmail: false,
                },
              }}
            >
              {(signUp, { data, loading, error }) => {
                return (
                  <Mutation
                    mutation={SUBSCRIBE}
                    variables={{
                      userInput: {
                        email: toLower(email),
                        stripeToken: token,
                        stripePlanId: subscriptionPlan.planId,
                        coupon,
                        sendEmail: true,
                        stripeEntryPlanId: entryPlanId,
                        paymentIntervals: isCoached
                          ? paymentIntervals
                          : 0,
                      },
                    }}
                    refetchQueries={[
                      {
                        query: GET_ME,
                      },
                    ]}
                  >
                    {(subscribe, { data, loading, error }) => {
                      var errorElement =
                        document.getElementById('card-errors')

                      if (error && errorElement) {
                        errorElement.textContent =
                          error.message.slice(0, 21) ===
                          'GraphQL error: Error:'
                            ? error.message.slice(21)
                            : null
                      }

                      return (
                        <Grid
                          container
                          direction="column"
                          spacing={2}
                          justify-content="center"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <img
                              src={headerImage}
                              style={{ maxWidth: 300, margin: 15 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ValidatorForm
                              onSubmit={() =>
                                handleSubmit(
                                  signUp,
                                  checkSignup,
                                  subscriptionPlan.planId,
                                  subscriptionPlan.price / 100,
                                  entryPlanId,
                                  getCoupon,
                                )
                              }
                            >
                              <BlockUi
                                blocking={loader}
                                loader={
                                  <PacmanLoader
                                    color={'var(--primary)'}
                                    loading={loader}
                                  />
                                }
                                message={
                                  <div className="text-primary">
                                    Creating Account
                                  </div>
                                }
                              >
                                {/* <Grid container spacing={0} className="min-vh-100">
                                             <Grid item xs={12} className="d-flex align-items-center">
                                               <Grid item className="mx-auto"> */}
                                {tab === 0 && (
                                  <Card
                                    style={{
                                      padding: 30,
                                      marginLeft: 15,
                                      marginRight: 15,
                                      marginBottom: 15,
                                      maxWidth: 600,
                                    }}
                                  >
                                    <CardContent>
                                      <Grid
                                        container
                                        direction="column"
                                      >
                                        <Grid
                                          item
                                          style={{ marginBottom: 10 }}
                                        >
                                          <Typography
                                            variant="h5"
                                            style={{
                                              fontWeight: 600,
                                            }}
                                          >
                                            Create Account
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                          >
                                            <Grid item xs={12}>
                                              <Grid
                                                container
                                                direction="row"
                                                spacing={1}
                                              >
                                                <Grid
                                                  item
                                                  xs={12}
                                                  sm={6}
                                                >
                                                  <TextValidator
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    margin="dense"
                                                    placeholder="First Name"
                                                    label="First Name"
                                                    value={firstName}
                                                    onChange={(
                                                      event,
                                                    ) =>
                                                      setFirstName(
                                                        event.target
                                                          .value,
                                                      )
                                                    }
                                                    validators={[
                                                      'required',
                                                      'maxStringLength: 20',
                                                      'matchRegexp:^[A-Z|a-z]+$',
                                                    ]}
                                                    errorMessages={[
                                                      'This field is required',
                                                      'You cannot exceed 20 characters',
                                                      'This field only allows characters in the alphabet',
                                                    ]}
                                                  />
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  sm={6}
                                                >
                                                  <TextValidator
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    margin="dense"
                                                    placeholder="Last Name"
                                                    label="Last Name"
                                                    value={lastName}
                                                    onChange={(
                                                      event,
                                                    ) =>
                                                      setLastName(
                                                        event.target
                                                          .value,
                                                      )
                                                    }
                                                    validators={[
                                                      'required',
                                                      'maxStringLength: 20',
                                                      'matchRegexp:^[A-Z|a-z]+$',
                                                    ]}
                                                    errorMessages={[
                                                      'This field is required',
                                                      'You cannot exceed 20 characters',
                                                      'This field only allows characters in the alphabet',
                                                    ]}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <TextValidator
                                                fullWidth={true}
                                                variant="outlined"
                                                margin="dense"
                                                placeholder="Phone Number"
                                                label="Phone Number"
                                                value={phoneNumber}
                                                onChange={(event) =>
                                                  setPhoneNumber(
                                                    event.target
                                                      .value,
                                                  )
                                                }
                                                validators={[
                                                  'required',
                                                  //eslint-disable-next-line
                                                  'matchRegexp:^[0-9()-]+$',
                                                ]}
                                                errorMessages={[
                                                  'This field is required',
                                                  'Not a valid phone number',
                                                ]}
                                              />
                                            </Grid>
                                            <Grid item xs={12}>
                                              <TextValidator
                                                fullWidth={true}
                                                variant="outlined"
                                                margin="dense"
                                                placeholder="Email Address"
                                                label="Email Address"
                                                value={email}
                                                onChange={(event) =>
                                                  setEmail(
                                                    event.target
                                                      .value,
                                                  )
                                                }
                                                validators={[
                                                  'required',
                                                  'maxStringLength: 50',
                                                  'isEmail',
                                                ]}
                                                errorMessages={[
                                                  'This field is required',
                                                  'You cannot exceed 50 characters',
                                                  'Not a valid email address',
                                                ]}
                                                error={signUpError}
                                                helperText={
                                                  signUpError
                                                    ? signUpError.message ===
                                                      'GraphQL error: Email already exists.'
                                                      ? 'Email already exists'
                                                      : signUpError.message ===
                                                          'GraphQL error: ValidationError: emails: [object Object] is not a valid email'
                                                        ? 'Not a valid email'
                                                        : signUpError.message ===
                                                            'GraphQL error: Error: Your card has insufficient funds.'
                                                          ? ''
                                                          : signUpError.message
                                                    : ''
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={12}>
                                              <TextValidator
                                                fullWidth={true}
                                                variant="outlined"
                                                margin="dense"
                                                placeholder="Password"
                                                label="Password"
                                                value={password}
                                                onChange={(event) =>
                                                  setPassword(
                                                    event.target
                                                      .value,
                                                  )
                                                }
                                                type={
                                                  passwordMask
                                                    ? 'password'
                                                    : 'text'
                                                }
                                                validators={[
                                                  'required',
                                                  'maxStringLength: 50',
                                                  'minStringLength: 7',
                                                ]}
                                                errorMessages={[
                                                  'This field is required',
                                                  'You cannot exceed 50 characters',
                                                  'Password must be at least 7 characters long',
                                                ]}
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      <IconButton
                                                        onClick={() =>
                                                          setPasswordMask(
                                                            !passwordMask,
                                                          )
                                                        }
                                                        size="large"
                                                      >
                                                        {passwordMask ? (
                                                          <RemoveRedEyeIcon />
                                                        ) : (
                                                          <VisibilityOffIcon />
                                                        )}
                                                      </IconButton>
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                              >
                                                <Grid item>
                                                  <Grid
                                                    container
                                                    direction="column"
                                                  >
                                                    <Grid item>
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={
                                                              agree
                                                            }
                                                            required
                                                            onChange={() =>
                                                              setAgree(
                                                                !agree,
                                                              )
                                                            }
                                                          />
                                                        }
                                                        label={
                                                          <span>
                                                            I agree to
                                                            the{' '}
                                                            <Link
                                                              component={
                                                                RouterLink
                                                              }
                                                              to="/Terms"
                                                              underline="hover"
                                                              style={{
                                                                color:
                                                                  '#04a3e3',
                                                              }}
                                                              target="_blank"
                                                            >
                                                              Terms of
                                                              Service
                                                            </Link>{' '}
                                                            and{' '}
                                                            <Link
                                                              component={
                                                                RouterLink
                                                              }
                                                              to="/Privacy"
                                                              underline="hover"
                                                              style={{
                                                                color:
                                                                  '#04a3e3',
                                                              }}
                                                              target="_blank"
                                                            >
                                                              Privacy
                                                              Policy
                                                            </Link>
                                                          </span>
                                                        }
                                                      />
                                                    </Grid>
                                                    <Grid item>
                                                      <Link
                                                        href="/signin"
                                                        underline="hover"
                                                        style={{
                                                          color:
                                                            '#04a3e3',
                                                        }}
                                                        // target="_blank"
                                                      >
                                                        Already have
                                                        an account?
                                                      </Link>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item>
                                                  <Button
                                                    variant="contained"
                                                    margin="dense"
                                                    color="primary"
                                                    type="submit"
                                                  >
                                                    <Typography>
                                                      Next
                                                    </Typography>
                                                  </Button>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                )}

                                {tab === 1 && (
                                  <Card
                                    style={{
                                      padding: 30,
                                      marginLeft: 15,
                                      marginRight: 15,
                                      marginBottom: 15,
                                      maxWidth: 1200,
                                    }}
                                  >
                                    <CardContent>
                                      <Grid
                                        container
                                        direction="column"
                                      >
                                        <Grid item xs={12}>
                                          <Typography
                                            variant="h5"
                                            style={{
                                              fontWeight: 600,
                                            }}
                                          >
                                            Select Plan
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          style={{
                                            textAlign: 'center',
                                            marginBottom: 15,
                                            marginTop: 25,
                                          }}
                                        >
                                          <ButtonGroup
                                            size="small"
                                            variant="text"
                                          >
                                            <Button
                                              style={{
                                                maxWidth: 200,
                                              }}
                                              className={clsx(
                                                'btn-outline-primary btn-transition-none',
                                                {
                                                  active:
                                                    activeTab ===
                                                    'Annual',
                                                },
                                              )}
                                              onClick={() => {
                                                tabSwitch2('Annual')
                                              }}
                                            >
                                              <LocalOfferIcon
                                                style={{
                                                  paddingRight: 5,
                                                }}
                                              />
                                              Annual
                                            </Button>
                                            <Button
                                              style={{
                                                maxWidth: 200,
                                              }}
                                              className={clsx(
                                                'btn-outline-primary btn-transition-none',
                                                {
                                                  active:
                                                    activeTab ===
                                                    'Monthly',
                                                },
                                              )}
                                              onClick={() => {
                                                tabSwitch2('Monthly')
                                              }}
                                            >
                                              <CalendarTodayIcon
                                                style={{
                                                  paddingRight: 5,
                                                }}
                                              />
                                              Monthly
                                            </Button>
                                          </ButtonGroup>
                                        </Grid>
                                        {Boolean(
                                          trialInvoiceAmount,
                                        ) ? (
                                          <Grid
                                            item
                                            xs={12}
                                            style={{ padding: 50 }}
                                          >
                                            <Typography>
                                              *Try the site for $
                                              {trialInvoiceAmount} for
                                              the first {memberTrial}{' '}
                                              days. After your trial
                                              is completed, plans
                                              start at just $49 per
                                              month.
                                            </Typography>
                                          </Grid>
                                        ) : (
                                          <Grid
                                            item
                                            xs={12}
                                            style={{ padding: 50 }}
                                          >
                                            <Typography>
                                              *Try the site free for
                                              the first {memberTrial}{' '}
                                              days. After your trial
                                              is completed, plans
                                              start at just $49 per
                                              month.
                                            </Typography>
                                          </Grid>
                                        )}
                                        <Grid item xs={12}>
                                          <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            spacing={2}
                                          >
                                            {map(
                                              !isEmpty(allowedPlans)
                                                ? filter(
                                                    pricingPlans,
                                                    (section) =>
                                                      includes(
                                                        allowedPlans,
                                                        activeTab ===
                                                          'Monthly'
                                                          ? section.nameMonthly
                                                          : section.nameYearly,
                                                      ),
                                                  )
                                                : pricingPlans,
                                              (section) => (
                                                <Query
                                                  query={
                                                    GET_SUBSCRIPTION_PLAN
                                                  }
                                                  variables={{
                                                    subscriptionPlan:
                                                      activeTab ===
                                                      'Monthly'
                                                        ? section.nameMonthly
                                                        : section.nameYearly,
                                                  }}
                                                >
                                                  {({
                                                    loading,
                                                    error,
                                                    data,
                                                  }) => {
                                                    if (loading)
                                                      return 'loading'
                                                    const {
                                                      subscriptionPlan = {},
                                                    } = data
                                                    return (
                                                      <Grid
                                                        item
                                                        sm={4}
                                                      >
                                                        <Card
                                                          style={{
                                                            margin: 10,
                                                            textAlign:
                                                              'center',
                                                            backgroundColor:
                                                              section.label ===
                                                                'Growth' &&
                                                              '#8ab8f9',
                                                            backgroundImage:
                                                              section.label ===
                                                              'Pro'
                                                                ? 'url(' +
                                                                  bgNewLarge +
                                                                  ')'
                                                                : '',
                                                            borderStyle:
                                                              section.label ===
                                                                'Pro' &&
                                                              'solid',
                                                            borderWidth:
                                                              section.label ===
                                                                'Pro' &&
                                                              'thick',
                                                            borderColor:
                                                              section.label ===
                                                                'Pro' &&
                                                              '#ffd000',
                                                            borderRadius: 5,
                                                            boxShadow:
                                                              '0 4px 10px 0 #bbbbbb',
                                                            minWidth: 300,
                                                          }}
                                                        >
                                                          <Grid
                                                            container
                                                            direction="column"
                                                            alignItems="center"
                                                            justifyContent="flex-start"
                                                          >
                                                            <Grid
                                                              item
                                                            >
                                                              <Typography
                                                                variant="subtitle1"
                                                                className="font-weight-light text-uppercase mt-4"
                                                                style={{
                                                                  marginBottom:
                                                                    -5,
                                                                  color:
                                                                    section.label ===
                                                                    'Pro'
                                                                      ? 'white'
                                                                      : 'black',
                                                                }}
                                                              >
                                                                {activeTab ===
                                                                'Monthly'
                                                                  ? section.subHeaderMonthly
                                                                  : section.subHeaderYearly}
                                                              </Typography>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                            >
                                                              <Typography
                                                                style={{
                                                                  backgroundColor:
                                                                    section.label ===
                                                                    'Pro'
                                                                      ? '#ffd000'
                                                                      : 'lightGrey',
                                                                  borderRadius: 5,
                                                                  fontWeight: 600,
                                                                  marginTop: 10,
                                                                  paddingBottom: 5,
                                                                  paddingLeft: 10,
                                                                  paddingRight: 10,
                                                                  paddingTop: 5,
                                                                }}
                                                              >
                                                                {
                                                                  section.label
                                                                }
                                                              </Typography>
                                                            </Grid>
                                                            <Typography
                                                              variant="h4"
                                                              color="primary"
                                                              className="font-weight-bold text-uppercase"
                                                            ></Typography>
                                                          </Grid>
                                                          <CardContent
                                                            style={{
                                                              color:
                                                                section.label ===
                                                                'Pro'
                                                                  ? 'white'
                                                                  : 'black',
                                                            }}
                                                          >
                                                            <Grid
                                                              container
                                                              direction="column"
                                                              justifyContent="center"
                                                              alignItems="center"
                                                              spacing={
                                                                2
                                                              }
                                                            >
                                                              <Grid
                                                                item
                                                              >
                                                                {(section.groupCoaching ||
                                                                  section.personalizedCoaching) && (
                                                                  <Typography
                                                                    variant="subtitle1"
                                                                    style={{
                                                                      paddingTop: 0,
                                                                    }}
                                                                  >
                                                                    1x{' '}
                                                                    {section.personalizedCoaching
                                                                      ? 'Personal'
                                                                      : 'Group'}{' '}
                                                                    Coaching
                                                                    Fee
                                                                    then
                                                                  </Typography>
                                                                )}
                                                                <Typography
                                                                  variant="h3"
                                                                  className="font-weight-bold"
                                                                  style={{
                                                                    marginBottom:
                                                                      -25,
                                                                  }}
                                                                >
                                                                  {currencyFormat.format(
                                                                    activeTab ===
                                                                      'Monthly' ||
                                                                      section.nameMonthly ===
                                                                        section.nameYearly
                                                                      ? subscriptionPlan.price /
                                                                          100
                                                                      : subscriptionPlan.price /
                                                                          100 /
                                                                          12,
                                                                  )}
                                                                  {section.nameMonthly !==
                                                                    'group-coaching' &&
                                                                    section.nameMonthly !==
                                                                      'personalized-coaching' &&
                                                                    '*'}
                                                                </Typography>
                                                              </Grid>
                                                              {section.label !==
                                                                'Free' && (
                                                                <Grid
                                                                  item
                                                                >
                                                                  <Typography
                                                                    variant="subtitle1"
                                                                    style={{
                                                                      paddingTop: 0,
                                                                    }}
                                                                  >
                                                                    {activeTab ===
                                                                      'Monthly' ||
                                                                    section.nameMonthly ===
                                                                      section.nameYearly
                                                                      ? 'PER MONTH'
                                                                      : `${currencyFormat.format(
                                                                          subscriptionPlan.price /
                                                                            100,
                                                                        )} PER YEAR`}
                                                                  </Typography>
                                                                </Grid>
                                                              )}
                                                            </Grid>
                                                            <Divider
                                                              color="white"
                                                              style={{
                                                                marginTop: 10,
                                                                marginBottom: 20,
                                                                // color: section.label === "Pro" ? "red" : "black",
                                                              }}
                                                            />
                                                            <Grid
                                                              container
                                                              direction="column"
                                                              justifyContent="center"
                                                              alignItems="center"
                                                              spacing={
                                                                2
                                                              }
                                                            >
                                                              {section.personalizedCoaching && (
                                                                <Grid
                                                                  item
                                                                >
                                                                  <Typography>
                                                                    {
                                                                      section.personalizedCoaching
                                                                    }
                                                                  </Typography>
                                                                </Grid>
                                                              )}
                                                              {section.groupCoaching && (
                                                                <Grid
                                                                  item
                                                                >
                                                                  <Typography>
                                                                    {
                                                                      section.groupCoaching
                                                                    }
                                                                  </Typography>
                                                                </Grid>
                                                              )}
                                                              {section.label !==
                                                              'Free' ? (
                                                                <Grid
                                                                  item
                                                                >
                                                                  <Typography>
                                                                    Analyze{' '}
                                                                    <b>
                                                                      {numberFormat.format(
                                                                        subscriptionPlan.propertyCount,
                                                                      )}
                                                                    </b>{' '}
                                                                    Properties/mo
                                                                  </Typography>
                                                                </Grid>
                                                              ) : (
                                                                <Grid
                                                                  item
                                                                  style={{
                                                                    marginTop: 30,
                                                                  }}
                                                                >
                                                                  <Typography>
                                                                    *Analyze{' '}
                                                                    <b>
                                                                      {
                                                                        section.properties
                                                                      }
                                                                    </b>{' '}
                                                                    Properties/mo
                                                                  </Typography>
                                                                </Grid>
                                                              )}
                                                              <Grid
                                                                item
                                                              >
                                                                <Typography>
                                                                  Create
                                                                  up
                                                                  to{' '}
                                                                  <b>
                                                                    {subscriptionPlan.porfolioCount ||
                                                                      1}
                                                                  </b>{' '}
                                                                  portfolio
                                                                </Typography>
                                                              </Grid>
                                                              {section.label !==
                                                                'Investor' && (
                                                                <Grid
                                                                  item
                                                                >
                                                                  <Typography>
                                                                    Add{' '}
                                                                    <b>
                                                                      {subscriptionPlan.teamCount ||
                                                                        0}
                                                                    </b>{' '}
                                                                    team
                                                                    members
                                                                  </Typography>
                                                                </Grid>
                                                              )}
                                                              <Grid
                                                                item
                                                              >
                                                                <Typography>
                                                                  {
                                                                    section.search
                                                                  }
                                                                </Typography>
                                                              </Grid>
                                                              <Grid
                                                                item
                                                              >
                                                                <Typography>
                                                                  {
                                                                    section.analyze
                                                                  }
                                                                </Typography>
                                                              </Grid>
                                                              <Grid
                                                                item
                                                              >
                                                                <Typography>
                                                                  {
                                                                    section.comp
                                                                  }
                                                                </Typography>
                                                              </Grid>
                                                              {section.housefoliosMarketplace && (
                                                                <Grid
                                                                  item
                                                                >
                                                                  <Typography>
                                                                    {
                                                                      section.housefoliosMarketplace
                                                                    }
                                                                  </Typography>
                                                                </Grid>
                                                              )}
                                                              {subscriptionPlan.marketplaceCount >
                                                                0 &&
                                                                subscriptionPlan.marketplaceCount && (
                                                                  <Grid
                                                                    item
                                                                  >
                                                                    <Typography>
                                                                      Create{' '}
                                                                      <b>
                                                                        {
                                                                          subscriptionPlan.marketplaceCount
                                                                        }
                                                                      </b>{' '}
                                                                      Marketplace
                                                                      websites
                                                                    </Typography>
                                                                  </Grid>
                                                                )}
                                                              {section.prioritized && (
                                                                <>
                                                                  <Grid
                                                                    item
                                                                  >
                                                                    <Typography>
                                                                      {
                                                                        section.prioritized
                                                                      }
                                                                    </Typography>
                                                                  </Grid>
                                                                  <Grid
                                                                    item
                                                                  >
                                                                    <Typography>
                                                                      {
                                                                        section.access
                                                                      }
                                                                    </Typography>
                                                                  </Grid>
                                                                  <Grid
                                                                    item
                                                                  >
                                                                    <Typography>
                                                                      {
                                                                        section.exclusive
                                                                      }
                                                                    </Typography>
                                                                  </Grid>
                                                                  <Grid
                                                                    item
                                                                  >
                                                                    <Typography>
                                                                      {
                                                                        section.listVerified
                                                                      }
                                                                    </Typography>
                                                                  </Grid>
                                                                  <Grid
                                                                    item
                                                                  >
                                                                    <Typography>
                                                                      {
                                                                        section.earlyAccess
                                                                      }
                                                                    </Typography>
                                                                  </Grid>
                                                                </>
                                                              )}
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              xs={12}
                                                              style={{
                                                                marginTop: 10,
                                                              }}
                                                            >
                                                              <Button
                                                                fullWidth
                                                                variant="outlined"
                                                                color="primary"
                                                                style={{
                                                                  fontWeight: 600,
                                                                  backgroundColor:
                                                                    'white',
                                                                }}
                                                                onClick={() => {
                                                                  setSelectedPlan(
                                                                    activeTab ===
                                                                      'Monthly'
                                                                      ? section.nameMonthly
                                                                      : section.nameYearly,
                                                                  )
                                                                  setTab(
                                                                    2,
                                                                  )
                                                                }}
                                                              >
                                                                Get
                                                                Started
                                                              </Button>
                                                            </Grid>
                                                            {/* )} */}
                                                          </CardContent>
                                                        </Card>
                                                      </Grid>
                                                    )
                                                  }}
                                                </Query>
                                              ),
                                            )}
                                          </Grid>
                                          {!Boolean(memberTrial) && (
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ padding: 50 }}
                                            >
                                              <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                spacing={2}
                                              >
                                                <Grid item sm={3}>
                                                  <img
                                                    src={
                                                      moneyBackGuarantee
                                                    }
                                                    style={{
                                                      maxWidth: 225,
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid item sm={9}>
                                                  <Grid
                                                    container
                                                    direction="column"
                                                    spacing={2}
                                                  >
                                                    <Grid item>
                                                      <Typography variant="h4">
                                                        What do you
                                                        have to lose
                                                      </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                      <Typography>
                                                        We use
                                                        Housefolios to
                                                        help us with
                                                        our own
                                                        investing. We
                                                        are confident
                                                        that it will
                                                        work for you
                                                        too! However,
                                                        if for any
                                                        reason you are
                                                        not satisfied
                                                        within 30
                                                        days, you can
                                                        get a full
                                                        refund.
                                                      </Typography>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          )}
                                          {!source && (
                                            <Grid
                                              item
                                              xs={12}
                                              style={{
                                                borderStyle: 'solid',
                                                borderWidth: 'thick',
                                                borderColor:
                                                  'lightGrey',
                                                borderRadius: 5,
                                                marginBottom: 20,
                                                paddingBottom: 25,
                                                paddingLeft: 25,
                                                paddingTop: 25,
                                                paddingRight: 5,
                                              }}
                                            >
                                              <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                              >
                                                <Grid item sm={9}>
                                                  <Grid
                                                    container
                                                    direction="column"
                                                  >
                                                    <Grid item>
                                                      <Typography variant="h5">
                                                        Enterprise
                                                        Plans
                                                      </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                      <Typography>
                                                        Are the plans
                                                        outlined above
                                                        not enough for
                                                        your
                                                        organization?
                                                        Do you need to
                                                        analyze more
                                                        than 1,000
                                                        properties
                                                        each month? Or
                                                        do you just
                                                        want to visit
                                                        with someone
                                                        and get a
                                                        walkthrough of
                                                        the platform
                                                        before making
                                                        a decision to
                                                        purchase?
                                                      </Typography>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid
                                                  item
                                                  sm={3}
                                                  style={{
                                                    textAlign:
                                                      'center',
                                                  }}
                                                >
                                                  <Button
                                                    variant="contained"
                                                    disabled
                                                    margin="dense"
                                                    color="primary"
                                                    className="nav-link-simple"
                                                    to="_blank"
                                                    onClick={() =>
                                                      navigate(
                                                        '/support-chat',
                                                      )
                                                    }
                                                  >
                                                    <Typography>
                                                      Contact Us
                                                    </Typography>
                                                  </Button>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                )}

                                {tab === 2 && (
                                  <Card
                                    style={{
                                      padding: 30,
                                      marginLeft: 15,
                                      marginRight: 15,
                                      marginBottom: 15,
                                      maxWidth: 600,
                                    }}
                                  >
                                    <CardContent>
                                      <Grid
                                        container
                                        direction="column"
                                      >
                                        <Grid
                                          item
                                          style={{ marginBottom: 10 }}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            <Grid item>
                                              <Typography
                                                variant="h5"
                                                style={{
                                                  fontWeight: 600,
                                                }}
                                              >
                                                Billing Info
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item>
                                          <TextField
                                            label="Subscription Plan"
                                            value={`${capitalize(
                                              plan[0],
                                            )} Plan - ${capitalize(
                                              plan[1],
                                            )}`}
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            // autoFocus={true}
                                            disabled={true}
                                          />
                                        </Grid>
                                        {isCoached && (
                                          <Grid item className="my-2">
                                            <FormControl
                                              fullWidth
                                              variant="outlined"
                                              margin="dense"
                                            >
                                              <InputLabel id="payment-plans-label">
                                                Payment Plans
                                              </InputLabel>
                                              <Select
                                                labelId="payment-plans-label"
                                                value={
                                                  paymentIntervals
                                                }
                                                onChange={(event) =>
                                                  setPaymentIntervals(
                                                    event.target
                                                      .value,
                                                  )
                                                }
                                                label="Payment Plans"
                                              >
                                                <MenuItem value={1}>
                                                  One Payment
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                  Two Payments
                                                </MenuItem>
                                                <MenuItem value={3}>
                                                  Three Payments
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                            {paymentIntervals > 1 && (
                                              <Typography
                                                style={{
                                                  marginLeft: 12,
                                                  fontSize: 13,
                                                }}
                                              >
                                                Selecting Two or Three
                                                Payments splits the
                                                coaching fee into
                                                multiple monthly
                                                payments. The next
                                                months payment will be
                                                the partial payment of
                                                the coaching fee plus
                                                the monthly
                                                subscription amount
                                              </Typography>
                                            )}
                                          </Grid>
                                        )}
                                        {(!disablePromoCode ||
                                          coupon) && (
                                          <Grid item>
                                            <TextField
                                              variant="outlined"
                                              margin="dense"
                                              placeholder="Add Promo Code"
                                              id="input-with-icon-textfield22-1"
                                              className="valid"
                                              value={coupon}
                                              fullWidth={true}
                                              onChange={(event) =>
                                                setCoupon(
                                                  event.target.value,
                                                )
                                              }
                                              disabled={
                                                disablePromoCode
                                              }
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    {coupon && (
                                                      <Button
                                                        onClick={() =>
                                                          checkCoupon(
                                                            coupon,
                                                            getCoupon,
                                                          )
                                                        }
                                                      >
                                                        Apply
                                                      </Button>
                                                    )}
                                                    {couponValid && (
                                                      <Check
                                                        style={{
                                                          color:
                                                            'green',
                                                        }}
                                                      />
                                                    )}
                                                    {!couponValid && (
                                                      <FontAwesomeIcon
                                                        icon={[
                                                          'far',
                                                          'xmark',
                                                        ]}
                                                        className="font-size-md" /*style={{ color: 'red' }}*/
                                                      />
                                                    )}
                                                  </InputAdornment>
                                                ),
                                              }}
                                            />
                                          </Grid>
                                        )}
                                        <Grid
                                          item
                                          xs={12}
                                          style={{ marginBottom: 10 }}
                                        >
                                          <Grid
                                            container
                                            direction="column"
                                            alignItems="flex-end"
                                          >
                                            {Boolean(memberTrial) ? (
                                              Boolean(
                                                trialInvoiceAmount,
                                              ) ? (
                                                <Grid item>
                                                  <Typography
                                                    style={
                                                      {
                                                        /* marginRight: 10, fontSize: 22, fontWeigh: 90*/
                                                      }
                                                    } /*color='primary'*/
                                                  >
                                                    {memberTrial} day
                                                    trial
                                                  </Typography>
                                                </Grid>
                                              ) : (
                                                <Grid item>
                                                  <Typography
                                                    style={
                                                      {
                                                        /* marginRight: 10, fontSize: 22, fontWeigh: 90*/
                                                      }
                                                    } /*color='primary'*/
                                                  >
                                                    {memberTrial} day
                                                    free trial
                                                  </Typography>
                                                </Grid>
                                              )
                                            ) : null}
                                            <Grid item>
                                              <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                              >
                                                <Grid item>
                                                  <Typography
                                                    style={{
                                                      marginRight: 10 /*fontSize: 22, fontWeigh: 90*/,
                                                    }} /*color='primary'*/
                                                  >
                                                    Total due today
                                                  </Typography>
                                                </Grid>
                                                <Grid item>
                                                  <Typography /*style={{ fontSize: 22, fontWeigh: 900, textAlign: 'right' }} color='primary'*/
                                                  >
                                                    {Boolean(
                                                      memberTrial,
                                                    ) ? (
                                                      Boolean(
                                                        trialInvoiceAmount,
                                                      ) ? (
                                                        <CountUp
                                                          start={0}
                                                          end={
                                                            trialInvoiceAmount
                                                          }
                                                          duration={1}
                                                          delay={1}
                                                          separator=","
                                                          decimals={2}
                                                          prefix="$"
                                                          suffix=""
                                                        />
                                                      ) : (
                                                        <CountUp
                                                          start={0}
                                                          end={0}
                                                          duration={1}
                                                          delay={1}
                                                          separator=","
                                                          decimals={2}
                                                          prefix="$"
                                                          suffix=""
                                                        />
                                                      )
                                                    ) : isCoached ? (
                                                      <CountUp
                                                        start={0}
                                                        end={
                                                          (entrySubscriptionPlan.price *
                                                            (1 -
                                                              couponPercent /
                                                                100) -
                                                            couponAmount) /
                                                          100
                                                        }
                                                        duration={1}
                                                        delay={1}
                                                        separator=","
                                                        decimals={2}
                                                        prefix="$"
                                                        suffix=""
                                                      />
                                                    ) : (
                                                      <CountUp
                                                        start={0}
                                                        end={
                                                          (subscriptionPlan.price *
                                                            (1 -
                                                              couponPercent /
                                                                100) -
                                                            couponAmount) /
                                                          100
                                                        }
                                                        duration={1}
                                                        delay={1}
                                                        separator=","
                                                        decimals={2}
                                                        prefix="$"
                                                        suffix=""
                                                      />
                                                    )}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid item>
                                              <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                              >
                                                <Grid item>
                                                  <Typography
                                                    style={
                                                      {
                                                        /*marginRight: 10, fontSize: 22, fontWeigh: 90*/
                                                      }
                                                    } /*color='primary'*/
                                                  >
                                                    Next payment of{' '}
                                                    {currencyFormat2.format(
                                                      isCoached
                                                        ? (subscriptionPlan.price +
                                                            (paymentIntervals >
                                                            1
                                                              ? entrySubscriptionPlan.price *
                                                                  (1 -
                                                                    couponPercent /
                                                                      100) -
                                                                couponAmount
                                                              : 0)) /
                                                            100
                                                        : (subscriptionPlan.price *
                                                            (1 -
                                                              couponPercent /
                                                                100) -
                                                            couponAmount) /
                                                            100,
                                                    )}{' '}
                                                    due{' '}
                                                    {Boolean(
                                                      memberTrial,
                                                    )
                                                      ? memberTrial +
                                                        ' days'
                                                      : plan[1] ===
                                                            'monthly' ||
                                                          isCoached
                                                        ? 'a month'
                                                        : 'a year'}{' '}
                                                    from now
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            {/* {!isCoached && ( */}
                                            <Grid item>
                                              <Button
                                                variant="text"
                                                color="primary"
                                                size="small"
                                                onClick={() =>
                                                  setTab(1)
                                                }
                                                style={{ padding: 0 }}
                                              >
                                                change
                                              </Button>
                                            </Grid>
                                            {/* )} */}
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Elements
                                            stripe={stripePromise}
                                          >
                                            <PaymentForm
                                              createToken={
                                                createToken
                                              }
                                              //setElement={setElement}
                                              setResetToken={
                                                setResetToken
                                              }
                                            />
                                          </Elements>
                                          <Typography
                                            style={{
                                              color: 'red',
                                              fontSize: 13,
                                              marginTop: 3,
                                              marginLeft: 12,
                                              fontWeight: 400,
                                            }}
                                            id="card-errors"
                                            role="alert"
                                          />
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Button
                                            variant="contained"
                                            margin="dense"
                                            color="primary"
                                            fullWidth
                                            disabled={!token}
                                            onClick={() => {
                                              setLoader(true)
                                              subscribe().finally(
                                                () => {
                                                  const productChoice =
                                                    toLower(
                                                      plan[0] +
                                                        'Plan - ' +
                                                        plan[1],
                                                    ).includes(
                                                      'starter',
                                                    )
                                                      ? 'starter'
                                                      : toLower(
                                                            plan[0] +
                                                              'Plan - ' +
                                                              plan[1],
                                                          ).includes(
                                                            'investor',
                                                          )
                                                        ? 'investor'
                                                        : toLower(
                                                              plan[0] +
                                                                'Plan - ' +
                                                                plan[1],
                                                            ).includes(
                                                              'growth',
                                                            )
                                                          ? 'growth'
                                                          : toLower(
                                                                plan[0] +
                                                                  'Plan - ' +
                                                                  plan[1],
                                                              ).includes(
                                                                'pro',
                                                              )
                                                            ? 'pro'
                                                            : toLower(
                                                                  plan[0] +
                                                                    'Plan - ' +
                                                                    plan[1],
                                                                ).includes(
                                                                  'enterprise',
                                                                )
                                                              ? 'enterprise'
                                                              : ''

                                                  let ltv =
                                                    plan[1] ===
                                                    'monthly'
                                                      ? 6
                                                      : 1.5

                                                  let gaID = hfpid
                                                    ? upperFirst(
                                                        productChoice,
                                                      ) +
                                                      '_' +
                                                      hfpid.toString() +
                                                      '_' +
                                                      email
                                                    : productChoice +
                                                      '_' +
                                                      email

                                                  ReactPixel.track(
                                                    'Purchase',
                                                    {
                                                      value:
                                                        (subscriptionPlan.price *
                                                          (1 -
                                                            couponPercent /
                                                              100) -
                                                          couponAmount) /
                                                        100,
                                                      currency: 'USD',
                                                      predicted_ltv:
                                                        (subscriptionPlan.price *
                                                          ltv) /
                                                        100,
                                                      description:
                                                        `email=${email}` +
                                                        '&' +
                                                        `productChoice=${productChoice}` +
                                                        '&' +
                                                        (hfpid
                                                          ? `hfpid=${hfpid.toString()}`
                                                          : ''),
                                                    },
                                                  )

                                                  ReactGA.plugin.execute(
                                                    'ecommerce',
                                                    'addItem',
                                                    {
                                                      id: gaID,
                                                      name: upperFirst(
                                                        productChoice,
                                                      ),
                                                      sku: subscriptionPlan.planId,
                                                      price:
                                                        (subscriptionPlan.price *
                                                          (1 -
                                                            couponPercent /
                                                              100) -
                                                          couponAmount) /
                                                        100,
                                                      quantity: 1,
                                                      currency: 'USD',
                                                    },
                                                  )

                                                  ReactGA.plugin.execute(
                                                    'ecommerce',
                                                    'addTransaction',
                                                    {
                                                      id: gaID,
                                                      revenue:
                                                        (subscriptionPlan.price *
                                                          (1 -
                                                            couponPercent /
                                                              100) -
                                                          couponAmount) /
                                                        100,
                                                      total:
                                                        (subscriptionPlan.price *
                                                          (1 -
                                                            couponPercent /
                                                              100) -
                                                          couponAmount) /
                                                        100,
                                                      shipping: 0,
                                                      tax: 0,
                                                      currency: 'USD',
                                                    },
                                                  )

                                                  ReactGA.plugin.execute(
                                                    'ecommerce',
                                                    'send',
                                                  )
                                                  ReactGA.plugin.execute(
                                                    'ecommerce',
                                                    'clear',
                                                  )
                                                  setLoader(false)
                                                },
                                              )
                                            }}
                                          >
                                            <Typography>
                                              Begin{' '}
                                              {capitalize(plan[0])}{' '}
                                              Plan
                                            </Typography>
                                          </Button>
                                        </Grid>
                                        {!Boolean(memberTrial) && (
                                          <Grid
                                            item
                                            xs={12}
                                            style={{ marginTop: 10 }}
                                          >
                                            <Typography
                                              style={{
                                                textAlign: 'center',
                                              }}
                                            >
                                              30-day money-back
                                              guarantee
                                            </Typography>
                                          </Grid>
                                        )}
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                )}

                                {/* </Grid>
                                             </Grid>
                                           </Grid> */}
                              </BlockUi>
                            </ValidatorForm>
                          </Grid>
                        </Grid>
                      )
                    }}
                  </Mutation>
                )
              }}
            </Mutation>
          )
        }}
      </Mutation>
      {/* </div>
             </div>
           </div>
         </div>
       </div>
     </div> */}
    </>
  )
}

export default CreateAccountCardComponent
