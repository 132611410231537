/**
 * @ Author: Housefolios
 * @ Create Time: 2021-10-05 11:07:32
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-04 12:11:20
 * @ Description: Dialog to share a marketplace through email.
 */

import React, { useState } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'

import map from 'lodash/map'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import toLower from 'lodash/toLower'
import get from 'lodash/get'
import find from 'lodash/find'
import split from 'lodash/split'
import concat from 'lodash/concat'

import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { Query } from '@apollo/client/react/components'
import { GET_ORGS_MARKETPLACE_SHARED_WITH } from '../queries'
import { useMutation } from '@apollo/client'
import { SHARE_MARKETPLACE } from '../mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import RemoveSharedMarketplace from '../RemoveSharedMarketplace'
import moment from 'moment'
import { GET_AFFILIATES } from '@/housefolios-components/CreateAccount/CreateAccountCard/queries'
import { GET_ACTIVE_ORGS } from '@/housefolios-components/ReportsComponents/Reports/queries'
import Autocomplete from '@mui/material/Autocomplete'
import UpdateSharedMarketplace from '../UpdateSharedMarketplace'

function ShareMarketplaceComponent(props) {
  const { sidebarMenu, marketplace = {}, activeOrg } = props
  const [shareType, setShareType] = useState('email')
  const [emails, setEmails] = useState([])
  const [sharePermission, setSharePermission] = useState('viewOnly')
  const [openShareMarketplace, setOpenShareMarketplace] =
    useState(false)
  const [loader, setLoader] = useState(false)
  const handleOpenShareMarketplace = () => {
    setOpenShareMarketplace(true)
    setEmails([])
  }
  const handleCloseShareMarketplace = () => {
    setOpenShareMarketplace(false)
    setLoader(false)
  }

  const [shareMarketplace] = useMutation(SHARE_MARKETPLACE)

  return (
    <>
      {sidebarMenu && marketplace.status === 'active' && (
        <MenuItem button onClick={handleOpenShareMarketplace}>
          <ShareIcon style={{ marginRight: 5, color: '#757575' }} />
          <span>Share Marketplace</span>
        </MenuItem>
      )}
      <Query
        query={GET_ORGS_MARKETPLACE_SHARED_WITH}
        variables={{
          url: marketplace.url,
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return 'Loading...'
          if (error) return `Error! ${error.message}`
          const { orgsMarketplaceSharedWith } = data
          return (
            <Query query={GET_ACTIVE_ORGS}>
              {({ loading, error, data }) => {
                if (loading) return 'Loading...'
                //if (error) return `Error! ${error.message}`
                const { activeOrgs = [] } = data || {}
                return (
                  <Dialog
                    open={openShareMarketplace}
                    onClose={handleCloseShareMarketplace}
                    maxWidth="sm"
                    fullWidth={true}
                    scroll="body"
                  >
                    <DialogTitle>
                      Share {marketplace.name} marketplace
                    </DialogTitle>
                    <BlockUi
                      blocking={loader}
                      loader={
                        <PacmanLoader
                          color={'var(--primary)'}
                          loading={true}
                        />
                      }
                      message={
                        <div className="text-primary">
                          Sharing Marketplace
                        </div>
                      }
                    >
                      <ValidatorForm
                        onSubmit={(event) => {
                          setLoader(true)
                          shareMarketplace({
                            variables: {
                              url: marketplace.url,
                              emails:
                                shareType === 'email'
                                  ? emails
                                  : map(
                                      activeOrgs,
                                      (org) => org.email,
                                    ),
                              sharePermission,
                            },
                          })
                            .then(() => {
                              enqueueSnackbar(`Email Sent`, {
                                variant: 'success',
                                autoHideDuration: 3000,
                              })
                              handleCloseShareMarketplace()
                            })
                            .catch((error) => {
                              enqueueSnackbar(
                                `Failed to send email`,
                                {
                                  variant: 'error',
                                  autoHideDuration: 3000,
                                },
                              )
                              setLoader(false)
                            })
                        }}
                        style={{
                          overflowY: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <DialogContent>
                          <Grid container direction="row">
                            {props.session.me.activeOrg
                              .isAffiliate && (
                              <Grid item xs={12}>
                                <TextField
                                  select
                                  name="shareType"
                                  variant="outlined"
                                  margin="dense"
                                  label="Share Type"
                                  fullWidth={true}
                                  // style={{ width: 175 }}
                                  value={shareType}
                                  onChange={(event) =>
                                    setShareType(event.target.value)
                                  }
                                  required={true}
                                >
                                  <MenuItem
                                    key={'email'}
                                    value={'email'}
                                  >
                                    {'Share By Email'}
                                  </MenuItem>
                                  <MenuItem
                                    key={'affiliate'}
                                    value={'affiliate'}
                                  >
                                    {'Share By Affiliate'}
                                  </MenuItem>
                                </TextField>
                              </Grid>
                            )}
                            {shareType === 'email' && (
                              <Grid item xs={12}>
                                <Autocomplete
                                  multiple
                                  id="emails"
                                  options={[]}
                                  value={emails}
                                  freeSolo
                                  autoSelect
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Send Email To"
                                      placeholder="Emails"
                                      helperText="Enter comma separated emails (no spaces). Press enter"
                                    />
                                  )}
                                  onChange={(
                                    event,
                                    inputs,
                                    reason,
                                  ) => {
                                    if (
                                      'createOption' == reason ||
                                      'blur' == reason
                                    ) {
                                      let values = split(
                                        inputs[inputs.length - 1],
                                        ',',
                                      )
                                      let validatedItems = []

                                      for (let value of values) {
                                        if (
                                          String(value)
                                            .toLowerCase()
                                            .match(
                                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            )
                                        )
                                          validatedItems.push(value)
                                      }

                                      let newList = concat(
                                        emails,
                                        validatedItems,
                                      )
                                      setEmails(newList)
                                    } else if (
                                      'removeOption' == reason
                                    ) {
                                      setEmails(inputs)
                                    } else if ('clear' == reason) {
                                      setEmails([])
                                    }
                                  }}
                                />
                                {/* <TextValidator
                                                          name="email"
                                                          type="email"
                                                          variant='outlined'
                                                          margin='dense'
                                                          autoFocus
                                                          onChange={event => setEmail(event.target.value)}
                                                          value={email}
                                                          placeholder="Enter email address..."
                                                          label="Email"
                                                          fullWidth={true}
                                                          validators={[
                                                          'required',
                                                          'maxStringLength: 50',
                                                          'isEmail',
                                                          ]}
                                                          errorMessages={[
                                                          'This field is required',
                                                          'You cannot exceed 50 characters',
                                                          'Not a valid email address',
                                                          ]}
                                                      /> */}
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <TextField
                                select
                                name="sharePermission"
                                variant="outlined"
                                margin="dense"
                                label="Permission"
                                fullWidth={true}
                                // style={{ width: 175 }}
                                value={sharePermission}
                                onChange={(event) =>
                                  setSharePermission(
                                    event.target.value,
                                  )
                                }
                                required={true}
                              >
                                <MenuItem
                                  key={'viewOnly'}
                                  value={'viewOnly'}
                                >
                                  {'View Only'}
                                </MenuItem>
                                <MenuItem
                                  key={'canListDelist'}
                                  value={'canListDelist'}
                                >
                                  {'Can List - Needs Approval'}
                                </MenuItem>
                                <MenuItem
                                  key={'canListDelistAutoApprove'}
                                  value={'canListDelistAutoApprove'}
                                >
                                  {'Can List - No Approval'}
                                </MenuItem>
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{ padding: 10 }}
                            >
                              {!isEmpty(
                                orgsMarketplaceSharedWith,
                              ) && (
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography>
                                      Shared With
                                    </Typography>
                                  </Grid>
                                  {map(
                                    orgsMarketplaceSharedWith,
                                    (org) => (
                                      <Grid item xs={12}>
                                        <Grid
                                          container
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                        >
                                          <Grid item>
                                            <Avatar>
                                              {head(org.name)}
                                            </Avatar>
                                          </Grid>
                                          <Grid item>
                                            <Grid
                                              container
                                              direction="column"
                                              className="line-height-sm"
                                            >
                                              <Grid item>
                                                <b className="font-size-lg">
                                                  {org.name}:
                                                </b>{' '}
                                                <span className="text-black-50 d-block">
                                                  {moment(
                                                    get(
                                                      find(
                                                        org.sharedMarketplaces,
                                                        [
                                                          'url',
                                                          marketplace.url,
                                                        ],
                                                      ),
                                                      'shareDate',
                                                    ),
                                                  ).format(
                                                    'MMM Do YYYY',
                                                  )}
                                                </span>{' '}
                                              </Grid>
                                              <Grid item>
                                                <span className="text-black-50 d-block">
                                                  {org.email}
                                                </span>
                                              </Grid>
                                              <Grid item>
                                                <span className="text-black-50 d-block">
                                                  {get(
                                                    find(
                                                      org.sharedMarketplaces,
                                                      [
                                                        'url',
                                                        marketplace.url,
                                                      ],
                                                    ),
                                                    'sharePermission',
                                                  ) ===
                                                  'canListDelistAutoApprove'
                                                    ? 'Can List - No Approval'
                                                    : get(
                                                          find(
                                                            org.sharedMarketplaces,
                                                            [
                                                              'url',
                                                              marketplace.url,
                                                            ],
                                                          ),
                                                          'sharePermission',
                                                        ) ===
                                                        'canListDelist'
                                                      ? 'Can List - Needs Approval'
                                                      : 'View Only'}
                                                </span>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          <Typography
                                            style={{ flex: 1 }}
                                          />
                                          <Grid item>
                                            <UpdateSharedMarketplace
                                              orgId={org._id}
                                              marketplaceURL={
                                                marketplace.url
                                              }
                                              shareAccess
                                              sharePermission={get(
                                                find(
                                                  org.sharedMarketplaces,
                                                  [
                                                    'url',
                                                    marketplace.url,
                                                  ],
                                                ),
                                                'sharePermission',
                                              )}
                                              canList={
                                                get(
                                                  find(
                                                    org.sharedMarketplaces,
                                                    [
                                                      'url',
                                                      marketplace.url,
                                                    ],
                                                  ),
                                                  'sharePermission',
                                                ) === 'canListDelist'
                                              }
                                            />
                                          </Grid>
                                          <Grid item>
                                            <RemoveSharedMarketplace
                                              orgId={org._id}
                                              marketplaceURL={
                                                marketplace.url
                                              }
                                              shareAccess
                                              canList={
                                                get(
                                                  find(
                                                    org.sharedMarketplaces,
                                                    [
                                                      'url',
                                                      marketplace.url,
                                                    ],
                                                  ),
                                                  'sharePermission',
                                                ) === 'canListDelist'
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    ),
                                  )}
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            variant="text"
                            size="small"
                            onClick={handleCloseShareMarketplace}
                          >
                            <Typography>CANCEL</Typography>
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            type="submit"
                            disabled={loader}
                          >
                            <Typography>SHARE</Typography>
                          </Button>
                        </DialogActions>
                      </ValidatorForm>
                    </BlockUi>
                  </Dialog>
                )
              }}
            </Query>
          )
        }}
      </Query>
    </>
  )
}
export default withSession(ShareMarketplaceComponent)
