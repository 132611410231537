/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-02-02 12:22:38
 * @ Description: Summary of analytics for all filtered properties in the portfolio
 */

import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material'

import meanBy from 'lodash/meanBy'
import sumBy from 'lodash/sumBy'
import map from 'lodash/map'
import CountUp from 'react-countup'

export default function PortfolioAnalyticsComponent(props) {
  const [activeTab, setActiveTab] = useState('0')

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const [modal1, setModal1] = useState(null)

  const toggle1 = (name) => {
    setModal1(name)
  }

  const toggle1Close = () => {
    setModal1(null)
  }

  const { properties, marketplace } = props

  let acquisitions = []
  map(properties, (property) => {
    acquisitions.push(property.acquisition)
  })

  let assumptions = []
  map(properties, (property) => {
    assumptions.push(property.assumptions)
  })

  //VARIABLES FOR ANALYTIC DIALOG BOX
  // Properties
  const length = properties.length

  // Out Of Pocket

  // const test = filter(acquisitions, cor => {
  //   return cor.analysis.financingRepairs === false
  // })

  const totalOutOfPocket =
    sumBy(
      acquisitions,
      marketplace
        ? 'analytics.turnkey.totalOutOfPocketTurnkey'
        : 'analytics.buyHold.totalOutOfPocket',
    ) || 0

  // Total Debt
  const loanAmount =
    sumBy(
      acquisitions,
      marketplace
        ? 'analytics.turnkey.loanAmountTurnkey'
        : 'analytics.buyHold.loanAmount',
    ) || 0

  // All-in Costs
  const allInCost =
    sumBy(
      acquisitions,
      marketplace
        ? 'analytics.turnkey.allInCostTurnkey'
        : 'analytics.buyHold.allInCost',
    ) || 0

  // // Total Value
  // const totalValue =
  //   sumBy(acquisitions, 'analysis.afterRepairValue') || 0

  // // Equity
  // const equity =
  //   sumBy(
  //     acquisitions,
  //     marketplace
  //       ? 'analytics.turnkey.equityTurnkey'
  //       : 'analytics.buyHold.equity',
  //   ) || 0

  const IRR = meanBy(
    acquisitions,
    marketplace
      ? 'analytics.turnkey.IRRTurnkey'
      : 'analytics.buyHold.IRR',
  )

  // Cap Rate
  //    const capRate = meanBy(acquisitions, 'analytics.buyHold.capRate') || 0
  const netOperatingIncome =
    sumBy(
      acquisitions,
      marketplace
        ? 'analytics.turnkey.netOperatingIncomeTurnkey'
        : 'analytics.buyHold.netOperatingIncome',
    ) || 0

  const capRate = netOperatingIncome / allInCost || 0

  // Cash On Cash
  //    const cashOnCash = meanBy(acquisitions, 'analytics.buyHold.cashOnCashReturn') || 0
  const cashFlow =
    sumBy(
      acquisitions,
      marketplace
        ? 'analytics.turnkey.cashFlowTurnkey'
        : 'analytics.buyHold.cashFlow',
    ) || 0

  const cashOnCash = cashFlow / totalOutOfPocket || 0

  // Cash Flow - Defined above as cashFlow

  // Net ROI
  // const netROI = meanBy(acquisitions, 'analytics.buyHold.netROI') || 0
  const proceedsAfterSale =
    sumBy(acquisitions, 'analytics.fixFlip.saleProceeds') || 0

  //const percentageOfARV = allInCost / totalValue || 0

  const netROI = proceedsAfterSale / allInCost || 0

  // Sale Proceeds - Defined above as proceedsAfterSale

  // Calculation Values for Analytic Boxes
  //Properties - Got value above

  // Out Of Pocket
  // downPaymentTotal =
  //   sumBy(acquisitions, 'analytics.buyHold.downPaymentTotal') || 0
  // const acquisitionCommissionDollar =
  //   sumBy(acquisitions, 'analytics.acquisitionCommissionDollar') ||  0
  // const acquisitionFee =
  //   sumBy(assumptions, 'assumptions.acquisitionCommissionDollar') || 0
  // const closeCostDollar =
  //   sumBy(acquisitions, 'analytics.buyHold.closeCostDollar') || 0
  // const outOfPocketCostOfRepairs = sumBy(test, 'analytics.buyHold.cor') || 0
  //Based on conditional of if Finance Repair is Y
  // const financingFeeTotal =
  //   sumBy(acquisitions, 'analytics.buyHold.financingFeeTotal') || 0
  ////////////////////////////////////////////////
  // const pointsAmount =
  //   sumBy(acquisitions, 'analytics.buyHold.pointsAmount') || 0

  // Total Debt
  // const totalBeforeDownPayment =
  //   sumBy(acquisitions, 'analytics.buyHold.totalBeforeDownPayment') || 0
  //downPaymentTotal defined above

  // All-in Costs
  // const offerPrice =
  //   sumBy(
  //     acquisitions,
  //     marketplace ? 'analysis.sellPrice' : 'analytics.buyHold.sop',
  //   ) || 0
  // const allInCostCostOfRepairs =
  //   sumBy(acquisitions, 'analytics.buyHold.corTotal') || 0
  //acquisitionCommissionDollar defined above
  //acquisitionFee defined above
  //closeCostDollar defined above
  //pointsAmount defined above

  // Total Value - Got Value above

  // Equity
  //afterRepairValue defined above as totalValue
  //allInCost defined above

  // Gross Yield
  //potentialRent defined above
  //allInCost defined above

  // Cap Rate
  //netOperatingIncome is defined above
  //allInCost defined above

  // Cash on Cash
  //cashFlow defined above
  //totalOutOfPocket defined above

  // Cash Flow (yr)
  //netOperatingIncome is defined above
  // const annualMortgage =
  //   sumBy(acquisitions, 'analytics.buyHold.mp_monthly') * 12 || 0

  // Net ROI
  // const grossAnnualRent =
  //   sumBy(acquisitions, 'analytics.grossAnnualRent') || 0
  // const annualExpenses =
  //   sumBy(acquisitions, 'analytics.buyHold.annualExpenses') || 0

  // Sale Proceeds
  // const salePrice = sumBy(acquisitions, 'analytics.buyHold.salePrice') || 0
  // const salesCommissionTotal =
  //   sumBy(acquisitions, 'analytics.buyHold.salesCommissionTotal') || 0
  // const sellingClosingCostDollar =
  //   sumBy(acquisitions, 'analytics.buyHold.sellingClosingCostDollar') || 0
  // const dispositionFeeDollar =
  //   sumBy(acquisitions, 'analytics.buyHold.dispositionFeeDollar') || 0
  // //totalOutOfPocket defined above
  // const loanBalanceAtHoldingPeriod =
  //   sumBy(acquisitions, 'analytics.buyHold.loanBalanceAtHoldingPeriod') || 0

  //REPLACE totalCashIn with totalOutofPocket
  // const totalCashIn =
  //   sumBy(acquisitions, function(tci) {
  //     return (
  //       tci.analytics.buyHold.allInCost -
  //       tci.analytics.buyHold.loanAmount +
  //       tci.analytics.buyHold.pointsAmount +
  //       tci.analytics.buyHold.financingFeeTotal
  //     )
  //   }) || 0

  // REPLACE netYield with capRate
  // const netYield =
  //   meanBy(acquisitions, function(ny) {
  //     return (
  //       ny.analytics.buyHold.cashFlow /
  //       (ny.analytics.buyHold.allInCost -
  //         ny.analytics.buyHold.loanAmount +
  //         ny.analytics.buyHold.pointsAmount +
  //         ny.analytics.buyHold.financingFeeTotal)
  //     )
  //   }) || 0

  const analytics = [
    {
      label: 'Net Cashflow',
      description: `Net cashflow is the amount of money left after the mortgage and any other expenses are paid.`,
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Gross Annual Income
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Property Mgmt
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Maintenance
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Insurance
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Taxes
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -HOA Fees
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Asset management
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Utilities
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Capex
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Other
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Net Operating Income
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Annual Mortgage
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Net Annual Cashflow
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                <FontAwesomeIcon
                  icon={['fal', 'divide']}
                  style={{ marginRight: 3 }}
                />
                12 months
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Net Monthly Cashflow
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      label: 'CoC ROI',
      description: `A cash-on-cash return calculates the return on the money put in by the investor on a property with a mortgage. Commonly referred to as a Leveraged Return.`,
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Gross Annual Income
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Property Mgmt
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Maintenance
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Insurance
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Taxes
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -HOA Fees
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Asset management
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Utilities
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Capex
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Other
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Net Operating Income
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Annual Mortgage
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Net Annual Cashflow
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                <FontAwesomeIcon
                  icon={['fal', 'divide']}
                  style={{ marginRight: 3 }}
                />
                Total Out-of-Pocket
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Cash on Cash Return
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      label: 'IRR',
      description: `IRR measures an investment’s annual return throughout the entire property ownership time frame. Unlike a cash-on-cash return or cap rate that measures returns in annual segments, IRR weighs total earnings from the day you purchase your property to the day you sell it.`,
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography
                style={{ color: '#9198a8', marginBottom: 20 }}
              >
                Due to the complexity of this calculation, we only
                show an overview of what goes into calculating the IRR
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Initial Investment (Out of pocket)
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Gross Monthly Income
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Rent Growth Rate
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Monthly Expenses
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Expenses Growth Rate
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                After Repair Value
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Appreciation Rate
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Holding Period (years)
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Future property sale
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>IRR</Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      label: 'Yield-on-Cost',
      description: `The Yield-on-Cost is the rate of return an investor can expect from an investment in real estate, assuming they pay all cash.`,
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Gross Annual Income
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Property Mgmt
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Maintenance
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Insurance
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Taxes
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -HOA Fees
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Asset management
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Utilities
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Capex
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Other
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Net Operating Income
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                <FontAwesomeIcon
                  icon={['fal', 'divide']}
                  style={{ marginRight: 3 }}
                />
                All-in-cost
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Yield-on-Cost
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      label: 'Net Profit',
      description:
        'The amount of money left over from selling your home after accounting for the remaining mortgage balance, closing costs, and realtor fees.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Resale Price
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Sales Commission
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Disposititon Fee
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Total Out-of-Pocket
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Remaining Loan Balance
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Net Profit
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      label: 'Net ROI',
      description:
        'Return on investment (ROI) indicates the percentage of investment money that is recouped after the deduction of associated costs.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Resale Price
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Sales Commission
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Disposititon Fee
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Total Out-of-Pocket
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Remaininig Loan Balance
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Net Profit
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                <FontAwesomeIcon
                  icon={['fal', 'divide']}
                  style={{ marginRight: 3 }}
                />
                All-in-cost
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Net ROI
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      label: 'Equity',
      description:
        'Equity is the property’s current market value, less any liens that are attached to that property.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                After Repair Value
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -All-in-cost
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Equity
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      label: 'After Repair Value',
      description:
        'The ARV is the value of a property after you have conducted repairs and are ready to sell.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                The sum of After Repair Values
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      label: 'All-In Cost to ARV',
      description: `The All-In Cost to ARV (After Repair Value) that you purchase the property for. There is a popular term referred to as the “70% rule” when applied to fix and flipping houses.  The 70% rule states that an investor should pay 70 percent of the ARV of a property, minus the repairs needed.  The ARV is the after repaired value and is what a home is worth after it is fully repaired.  This “rule” says 70% but we have still found success at 80% of the ARV.`,
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Purchase Price
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Commission
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Fee
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Closing Costs
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Loan Points
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Rehab Amount
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Custom Acquisition Expenses
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Holding Expenses
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                All-in Cost
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                <FontAwesomeIcon
                  icon={['fal', 'divide']}
                  style={{ marginRight: 3 }}
                />
                After Repair Value
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                All-in Cost to ARV
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
  ]

  // const analyticCardsCol1 = [
  //   {
  //     name: 'Properties',
  //     icon: <FontAwesomeIcon
  //             icon={['fas', 'house-chimney']}
  //             className="display-4"
  //           />,
  //     className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-first text-white btn-icon text-center shadow-first mr-3',
  //     value: length,
  //     prefix: '',
  //     definition: 'Number of Properties contributing to calculations',
  //   },
  //   {
  //     divider: <div className="divider my-3" />,
  //     // name: 'Out-of-Pocket',
  //     name: 'Initial Investment',
  //     icon: <FontAwesomeIcon
  //             icon={['fas', 'wallet']}
  //             className="display-4"
  //           />,
  //     className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-success text-white btn-icon text-center shadow-success mr-3',
  //     value: totalOutOfPocket,
  //     prefix: '$',
  //     definition: 'Total loan amount needed to purchase the properties',
  //   },
  //   {
  //     divider: <div className="divider my-3" />,
  //     name: 'Total Debt',
  //     icon: <FontAwesomeIcon
  //             icon={['fas', 'building-columns']}
  //             className="display-4"
  //           />,
  //     className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-danger text-white btn-icon text-center shadow-danger mr-3',
  //     value: loanAmount,
  //     prefix: '$',
  //     definition: 'Total loan amount needed to purchase the properties',
  //   },
  //   {
  //     divider: <div className="divider my-3" />,
  //     name: 'All-in Costs',
  //     icon: <FontAwesomeIcon
  //             icon={['fas', 'money-check-dollar']}
  //             className="display-4"
  //           />,
  //     className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-warning text-white btn-icon text-center shadow-warning mr-3',
  //     value: allInCost,
  //     prefix: '$',
  //     definition: 'Sum of total costs associated with properties',
  //   },
  // ]
  // const analyticCardsCol2 = [
  //   {
  //     name: 'Total Portfolio Value',
  //     icon: <FontAwesomeIcon
  //             icon={['fas', 'money-check-dollar']}
  //             className="display-4"
  //           />,
  //     className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-warning text-white btn-icon text-center shadow-warning mr-3',
  //     value: '3070500',
  //     prefix: '$',
  //     definition: 'Sum of total costs associated with properties',
  //   },
  //   {
  //     divider: <div className="divider my-3" />,
  //     name: 'Equity Value',
  //     icon: <FontAwesomeIcon
  //             icon={['fas', 'money-check-dollar']}
  //             className="display-4"
  //           />,
  //     className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-warning text-white btn-icon text-center shadow-warning mr-3',
  //     value: '584227',
  //     prefix: '$',
  //     definition: 'Sum of total costs associated with properties',
  //   },
  //   {
  //     divider: <div className="divider my-3" />,
  //     name: 'Current Reserves',
  //     icon: <FontAwesomeIcon
  //             icon={['fas', 'money-check-dollar']}
  //             className="display-4"
  //           />,
  //     className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-warning text-white btn-icon text-center shadow-warning mr-3',
  //     value: '78525',
  //     prefix: '$',
  //     definition: 'Sum of total costs associated with properties',
  //   },
  //   {
  //     divider: <div className="divider my-3" />,
  //     // name: Initial Investment',
  //     name: 'Total Cashflow to Date',
  //     icon: <FontAwesomeIcon
  //             icon={['fas', 'money-check-dollar']}
  //             className="display-4"
  //           />,
  //     className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-warning text-white btn-icon text-center shadow-warning mr-3',
  //     value: '2486273',
  //     prefix: '$',
  //     definition: 'Sum of total costs associated with properties',
  //   },
  // ]

  // const analyticCardsCol3 = [
  //   {
  //     name: 'Projected Gross Income',
  //     icon: <FontAwesomeIcon
  //             icon={['fas', 'money-check-dollar']}
  //             className="display-4"
  //           />,
  //     className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-warning text-white btn-icon text-center shadow-warning mr-3',
  //     value: '355818',
  //     prefix: '$',
  //     definition: 'Projected 12 month income',
  //   },
  //   {
  //     divider: <div className="divider my-3" />,
  //     name: 'Projected Expenses',
  //     icon: <FontAwesomeIcon
  //             icon={['fas', 'money-check-dollar']}
  //             className="display-4"
  //           />,
  //     className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-warning text-white btn-icon text-center shadow-warning mr-3',
  //     value: '149164',
  //     prefix: '$',
  //     definition: 'Sum of total costs associated with properties',
  //   },
  //   {
  //     divider: <div className="divider my-3" />,
  //     name: 'Projected Net income',
  //     icon: <FontAwesomeIcon
  //             icon={['fas', 'money-check-dollar']}
  //             className="display-4"
  //           />,
  //     className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-warning text-white btn-icon text-center shadow-warning mr-3',
  //     value: '163910',
  //     prefix: '$',
  //     definition: 'Sum of total costs associated with properties',
  //   },
  //   // {
  //   //   divider: <div className="divider my-3" />,
  //   //   name: 'Avg. YTD Collection Rate',
  //   //   icon: <FontAwesomeIcon
  //   //           icon={['fas', 'money-check-dollar']}
  //   //           className="display-4"
  //   //         />,
  //   //   className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-warning text-white btn-icon text-center shadow-warning mr-3',
  //   //   value: '90',
  //   //   prefix: '',
  //   //   suffix: '%',
  //   //   definition: 'Sum of total costs associated with properties',
  //   // },
  //   {
  //     divider: <div className="divider my-3" />,
  //     name: 'Current Vacancy Rate',
  //     icon: <FontAwesomeIcon
  //             icon={['fas', 'money-check-dollar']}
  //             className="display-4"
  //           />,
  //     className: 'd-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-warning text-white btn-icon text-center shadow-warning mr-3',
  //     value: '0.00',
  //     prefix: '',
  //     suffix: '%',
  //     definition: 'Sum of total costs associated with properties',
  //   },
  // ]

  return (
    <>
      <div className="mb-spacing-6">
        <Grid container spacing={3}>
          <Grid item lg={7}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card className="card-box p-3">
                  <div className="font-weight-bold font-size-lg text-uppercase text-second mt-2">
                    Properties
                  </div>
                  <div className="my-2">
                    <div className="d-flex flex-wrap py-4 align-items-center">
                      <div className="d-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-first text-white btn-icon text-center shadow-first mr-3">
                        <FontAwesomeIcon
                          icon={['fas', 'house-chimney']}
                          className="display-4"
                        />
                      </div>
                      <div className="display-3 font-weight-bold ml-1">
                        <span className="pr-1">
                          <CountUp
                            start={0}
                            end={length}
                            duration={1}
                            separator=","
                            delay={1}
                            decimal="."
                            decimals={0}
                            prefix=""
                            suffix=""
                          />
                        </span>
                      </div>
                    </div>
                    <div className="text-black-50 font-weight-bold mb-2">
                      Total number of properties that are contributing
                      to calculations.
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card className="card-box p-3">
                  <div className="font-weight-bold font-size-lg text-uppercase text-second mt-2">
                    Out-of-Pocket
                  </div>
                  <div className="my-2">
                    <div className="d-flex flex-wrap py-4 align-items-center">
                      <div className="d-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-success text-white btn-icon text-center shadow-success mr-3">
                        <FontAwesomeIcon
                          icon={['fas', 'wallet']}
                          className="display-4"
                        />
                      </div>
                      <div className="display-3 font-weight-bold ml-1">
                        <span className="pr-1">
                          <CountUp
                            start={0}
                            end={totalOutOfPocket}
                            duration={1}
                            separator=","
                            delay={1}
                            decimals={0}
                            decimal="."
                            prefix="$"
                            suffix=""
                          />
                        </span>
                      </div>
                    </div>
                    <div className="text-black-50 font-weight-bold mb-2">
                      Amount needed for downpayment, closing costs,
                      and rehab.
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card className="card-box p-3">
                  <div className="font-weight-bold font-size-lg text-uppercase text-second mt-2">
                    Total Debt
                  </div>
                  <div className="my-2">
                    <div className="d-flex py-4 flex-wrap align-items-center">
                      <div className="d-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-danger text-white btn-icon text-center shadow-danger mr-3">
                        <FontAwesomeIcon
                          icon={['fas', 'building-columns']}
                          className="display-4"
                        />
                      </div>
                      <div className="display-3 font-weight-bold ml-1">
                        <span className="pr-1">
                          <CountUp
                            start={0}
                            end={loanAmount}
                            duration={1}
                            separator=","
                            delay={1}
                            decimals={0}
                            decimal="."
                            prefix="$"
                            suffix=""
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-black-50 font-weight-bold mb-2">
                    Total loan amount needed to purchase the
                    properties
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card className="card-box p-3">
                  <div className="font-weight-bold font-size-lg text-uppercase text-second mt-2">
                    All-in Costs
                  </div>
                  <div className="my-2">
                    <div className="d-flex flex-wrap py-4 align-items-center">
                      <div className="d-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-warning text-white btn-icon text-center shadow-warning mr-3">
                        <FontAwesomeIcon
                          icon={['fas', 'money-check-dollar']}
                          className="display-4"
                        />
                      </div>
                      <div className="display-3 font-weight-bold ml-1">
                        <span className="pr-1">
                          <CountUp
                            start={0}
                            end={allInCost}
                            duration={1}
                            separator=","
                            delay={1}
                            decimals={0}
                            decimal="."
                            prefix="$"
                            suffix=""
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-black-50 font-weight-bold mb-2">
                    Sum of total costs associated with properties
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5} className="d-flex">
            <Card className="card-box w-100">
              <List
                component="div"
                className="nav-line d-flex nav-tabs-primary mt-4"
              >
                <ListItem
                  button
                  disableRipple
                  selected={activeTab === '0'}
                  onClick={() => {
                    toggle('0')
                  }}
                >
                  <span style={{ fontSize: 20 }}>
                    Cashflow Summary
                  </span>
                  <div className="divider" style={{ width: '95%' }} />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  selected={activeTab === '1'}
                  onClick={() => {
                    toggle('1')
                  }}
                >
                  <span style={{ fontSize: 20 }}>Equity Summary</span>
                  <div className="divider" style={{ width: '95%' }} />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  selected={activeTab === '2'}
                  onClick={() => {
                    toggle('2')
                  }}
                >
                  <span style={{ fontSize: 20 }}>Year-to-Date</span>
                  <div className="divider" style={{ width: '95%' }} />
                </ListItem>
              </List>
              <Divider style={{ marginTop: -10 }} />
              {activeTab === '0' && (
                <List component="div" className="list-group-flush">
                  <ListItem
                    component="a"
                    button
                    href={null}
                    disableRipple
                    onClick={() => toggle1('IRR')}
                    className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-4"
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="font-weight-bold font-size-lg text-black">
                          IRR
                        </div>
                        <div className="d-flex align-items-center font-size-md">
                          <div className="text-black">
                            Annualized rate of return.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="pr-1">
                        <CountUp
                          start={0}
                          end={IRR * 100}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix=""
                          suffix="%"
                          style={{ fontSize: 24 }}
                        />
                      </span>
                    </div>
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href={null}
                    disableRipple
                    onClick={() => toggle1('Yield-on-Cost')}
                    className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-4"
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="font-weight-bold font-size-lg text-black">
                          {/* Yield on Cost */}
                          Cap Rate on Cost
                        </div>
                        <div className="d-flex align-items-center font-size-md">
                          <div className="text-black">
                            Yield on Cost for portfolio.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="pr-1">
                        <CountUp
                          start={0}
                          end={capRate * 100}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix=""
                          suffix="%"
                          style={{ fontSize: 24 }}
                        />
                      </span>
                    </div>
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href={null}
                    disableRipple
                    onClick={() => toggle1('CoC ROI')}
                    className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-4"
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="font-weight-bold font-size-lg text-black">
                          {/* Cash on Cash Return */}
                          Leveraged Return
                        </div>
                        <div className="d-flex align-items-center font-size-md">
                          <div className="text-black">
                            Cash on Cash return for portfolio.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="pr-1">
                        <CountUp
                          start={0}
                          end={cashOnCash * 100}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix=""
                          suffix="%"
                          style={{ fontSize: 24 }}
                        />
                      </span>
                    </div>
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href={null}
                    disableRipple
                    onClick={() => toggle1('Net Cashflow')}
                    className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-4"
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="font-weight-bold font-size-lg text-black">
                          Net Annual Cashflow
                        </div>
                        <div className="d-flex align-items-center font-size-md">
                          <div className="text-black">
                            Net annual cashflow after all expenses are
                            paid.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="pr-1">
                        <CountUp
                          start={0}
                          end={cashFlow}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={0}
                          decimal="."
                          prefix="$"
                          suffix=""
                          style={{ fontSize: 24 }}
                        />
                      </span>
                    </div>
                  </ListItem>
                </List>
              )}
              {activeTab === '1' && (
                <List component="div" className="list-group-flush">
                  {/* <ListItem
                    component="a"
                    button
                    href={null}
                    disableRipple
                    //onClick={(e) => e.preventDefault()}
                    className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-4">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="font-weight-bold font-size-lg text-black">
                          Total Value
                        </div>
                        <div className="d-flex align-items-center font-size-md">
                          <div className="text-black">Total value of portfolio if sold today.</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="pr-1">
                        <CountUp
                          start={0}
                          end={totalValue}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={0}
                          decimal="."
                          prefix="$"
                          suffix=""
                          style={{ fontSize: 24 }}
                        />
                      </span>
                    </div>
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href={null}
                    disableRipple
                    onClick={(e) => e.preventDefault()}
                    className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-4">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="font-weight-bold font-size-lg text-black">
                          Equity
                        </div>
                        <div className="d-flex align-items-center font-size-md">
                          <div className="text-black">Sum of equity for all properties in portfolio.</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="pr-1">
                        <CountUp
                          start={0}
                          end={equity}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={0}
                          decimal="."
                          prefix="$"
                          suffix=""
                          style={{ fontSize: 24 }}
                        />
                      </span>
                    </div>
                  </ListItem> */}
                  <ListItem
                    component="a"
                    button
                    href={null}
                    disableRipple
                    onClick={() => toggle1('Net ROI')}
                    className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-4"
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="font-weight-bold font-size-lg text-black">
                          Net ROI
                        </div>
                        <div className="d-flex align-items-center font-size-md">
                          <div className="text-black">
                            Total value of portfolio if sold today.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="pr-1">
                        <CountUp
                          start={0}
                          end={netROI * 100}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix=""
                          suffix="%"
                          style={{ fontSize: 24 }}
                        />
                      </span>
                    </div>
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href={null}
                    disableRipple
                    onClick={() => toggle1('Net Profit')}
                    className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-4"
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="font-weight-bold font-size-lg text-black">
                          Net Profit
                        </div>
                        <div className="d-flex align-items-center font-size-md">
                          <div className="text-black">
                            Your return after the expenses associated
                            with the sale.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="pr-1">
                        <CountUp
                          start={0}
                          end={proceedsAfterSale}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={0}
                          decimal="."
                          prefix="$"
                          suffix=""
                          style={{ fontSize: 24 }}
                        />
                      </span>
                    </div>
                  </ListItem>
                </List>
              )}
              {activeTab === '2' && (
                <List component="div" className="list-group-flush">
                  <ListItem
                    component="a"
                    button
                    href={null}
                    disableRipple
                    onClick={() => toggle1('IRR')}
                    className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-4"
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="font-weight-bold font-size-lg text-black">
                          Gross Income
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="pr-1">
                        <CountUp
                          start={0}
                          end={IRR * 100}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix="$"
                          suffix=""
                          style={{ fontSize: 24 }}
                        />
                      </span>
                    </div>
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href={null}
                    disableRipple
                    onClick={() => toggle1('IRR')}
                    className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-4"
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="font-weight-bold font-size-lg text-black">
                          Net Income
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="pr-1">
                        <CountUp
                          start={0}
                          end={IRR * 100}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix="$"
                          suffix=""
                          style={{ fontSize: 24 }}
                        />
                      </span>
                    </div>
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href={null}
                    disableRipple
                    onClick={() => toggle1('IRR')}
                    className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-4"
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="font-weight-bold font-size-lg text-black">
                          Expense Ratio
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="pr-1">
                        <CountUp
                          start={0}
                          end={IRR * 100}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix=""
                          suffix="%"
                          style={{ fontSize: 24 }}
                        />
                      </span>
                    </div>
                  </ListItem>
                  <ListItem
                    component="a"
                    button
                    href={null}
                    disableRipple
                    onClick={() => toggle1('IRR')}
                    className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-4"
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="font-weight-bold font-size-lg text-black">
                          Avg. YTD Collection Rate
                        </div>
                        <div className="d-flex align-items-center font-size-md">
                          <div className="text-black">
                            Sum of total costs associated with
                            properties.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="pr-1">
                        <CountUp
                          start={0}
                          end={90}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix=""
                          suffix="%"
                          style={{ fontSize: 24 }}
                        />
                      </span>
                    </div>
                  </ListItem>
                </List>
              )}
            </Card>
          </Grid>
        </Grid>
      </div>
      {map(analytics, (section) => (
        <Dialog
          open={modal1 === section.label}
          onClose={toggle1Close}
          maxWidth="lg"
          scroll="body"
          classes={{
            paper:
              'modal-content rounded border-0 bg-white p-3 p-xl-0',
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                  <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                    <div className="text-white mt-3">
                      <Typography
                        style={{ fontSize: 30, fontWeight: 600 }}
                      >
                        Calculation
                      </Typography>
                    </div>
                    {section.calculation}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={7}>
              <div>
                <Card>
                  <CardHeader title={section.label} />
                  <Divider />
                  <CardContent style={{ height: '100%' }}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Card
                          style={{
                            backgroundColor: '#eff2f5',
                            borderRadius: 0,
                          }}
                        >
                          <CardContent>
                            <Typography>
                              {section.description}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={toggle1Close}
                        >
                          Close
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Dialog>
      ))}
    </>
  )
}
