export const ExcelFormulas = {
  PVIF: function (rate, nper) {
    return Math.pow(1 + rate, nper)
  },

  FVIFA: function (rate, nper) {
    return rate == 0 ? nper : (this.PVIF(rate, nper) - 1) / rate
  },

  PMT: function (args) {
    const {
      rate, //per month
      numPeriods = 0, // (months)
      presentValue,
      futureValue = 0,
      paymentDue = 0 /*  0: end of the period, e.g. end of month (default)  1: beginning of period */,
    } = args

    if (rate !== 0) {
      let q = Math.pow(1 + rate, numPeriods)
      return (
        -(rate * (futureValue + q * presentValue)) /
        ((-1 + q) * (1 + rate * paymentDue))
      )
    } else if (numPeriods !== 0) {
      return -(futureValue + presentValue) / numPeriods
    }

    return 0
  },

  PV: function (rate, nper, pmt, fv) {
    return (
      (-1 * ((pmt * (Math.pow(1 + rate, nper) - 1)) / rate + fv)) /
      Math.pow(1 + rate, nper)
    )
  },

  IPMT: function (pv, pmt, rate, per) {
    const tmp = Math.pow(1 + rate, per)
    return 0 - (pv * tmp * rate + pmt * (tmp - 1))
  },

  PPMT: function (rate, per, nper, pv, fv, type) {
    if (per < 1 || per >= nper + 1) return null
    const pmt = this.PMT(rate, nper, pv, fv, type)
    const ipmt = this.IPMT(pv, pmt, rate, per - 1)
    return pmt - ipmt
  },

  DaysBetween: function (date1, date2) {
    const oneDay = 24 * 60 * 60 * 1000
    return Math.round(
      Math.abs((date1.getTime() - date2.getTime()) / oneDay),
    )
  },

  XNPV: function (rate, values) {
    let xnpv = 0.0
    const firstDate = new Date(values[0].Date)
    for (let key in values) {
      const tmp = values[key]
      const value = tmp.Flow
      const date = new Date(tmp.Date)
      xnpv +=
        value /
        Math.pow(1 + rate, this.DaysBetween(firstDate, date) / 365)
    }
    return xnpv
  },

  XIRR2: function (values, guess) {
    if (!guess) guess = 0.1

    let x1 = 0.0
    let x2 = guess
    let f1 = this.XNPV(x1, values)
    let f2 = this.XNPV(x2, values)

    for (let i = 0; i < 100; i++) {
      if (f1 * f2 < 0.0) break
      if (Math.abs(f1) < Math.abs(f2)) {
        f1 = this.XNPV((x1 += 1.6 * (x1 - x2)), values)
      } else {
        f2 = this.XNPV((x2 += 1.6 * (x2 - x1)), values)
      }
    }

    if (f1 * f2 > 0.0) return 0

    const f = this.XNPV(x1, values)
    let rtb = x2
    let dx = x1 - x2
    if (f < 0.0) {
      rtb = x1
      dx = x2 - x1
    }

    for (let i = 0; i < 100; i++) {
      dx *= 0.5
      let x_mid = rtb + dx
      let f_mid = this.XNPV(x_mid, values)
      if (f_mid <= 0.0) rtb = x_mid
      if (Math.abs(f_mid) < 1.0e-6 || Math.abs(dx) < 1.0e-6)
        return x_mid
    }

    return 0
  },

  XIRR: function (values, guess) {
    if (!guess) guess = 0.1

    var x1 = 0.0
    var x2 = guess
    var f1 = this.XNPV(x1, values)
    var f2 = this.XNPV(x2, values)

    for (var i = 0; i < 100; i++) {
      if (f1 * f2 < 0.0) break
      if (Math.abs(f1) < Math.abs(f2)) {
        f1 = this.XNPV((x1 += 1.6 * (x1 - x2)), values)
      } else {
        f2 = this.XNPV((x2 += 1.6 * (x2 - x1)), values)
      }
    }

    if (f1 * f2 > 0.0) return 0

    var f = this.XNPV(x1, values)
    if (f < 0.0) {
      var rtb = x1
      var dx = x2 - x1
    } else {
      var rtb = x2
      var dx = x1 - x2
    }

    for (var i = 0; i < 100; i++) {
      dx *= 0.5
      var x_mid = rtb + dx
      var f_mid = this.XNPV(x_mid, values)
      if (f_mid <= 0.0) rtb = x_mid
      if (Math.abs(f_mid) < 1.0e-6 || Math.abs(dx) < 1.0e-6)
        return x_mid
    }

    return 0
  },
}
