import React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import FavoriteIcon from '@mui/icons-material/Favorite'
import PanToolIcon from '@mui/icons-material/PanTool'
import ControlCameraIcon from '@mui/icons-material/ControlCamera'
import ShareIcon from '@mui/icons-material/Share'
import Timelapse from '@mui/icons-material/Timelapse'
import Add from '@mui/icons-material/AddCircleOutline'
import ModeEdit from '@mui/icons-material/ModeEdit'
import Save from '@mui/icons-material/Save'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import AddTextInput from '../Dialogs/AddTextInput'
import EditCardDialog from '../Dialogs/EditCardDialog'
import { currencyFormat } from '@/utils/number'

import Select from '../Select'
import {
  gql,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client'
import { GET_TEAM } from '@/housefolios-components/Team/queries'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'

import remove from 'lodash/remove'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const DEFAULT_PROPERTY_STATE = {
  dueDate: Date.now(),
  labels: ['Underwriting Complete', 'Microflip'],
  priority: null,
  onMarket: null,
  pointOfContact: '',
  notes: '',
  exclusiveBuyPrice: null,
}

const PRIORITIES = [
  { _id: 'highest', name: 'Highest' },
  { _id: 'high', name: 'High' },
  { _id: 'medium', name: 'Medium' },
  { _id: 'low', name: 'Low' },
  { _id: 'lowest', name: 'Lowest' }, // Default value
]

const YES_NO_LISTITEMS = [
  { _id: null, name: 'Please select a option' }, // Default value
  { _id: 'yes', name: 'Yes' },
  { _id: 'no', name: 'No' },
]

function KanbanCard({
  property,
  updateLocalProperties = null,
  userId = '',
}) {
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }
  const [expanded, setExpanded] = useState(false)

  // WE NO LONGER HAVE EDITING FIELDS IN THIS COMPONENT. JUST PULL FIELDS FROM PROPERTY
  // const [propertyState, setPropertyState] = useState(
  //   property?.kanbanProcessStageState ?? DEFAULT_PROPERTY_STATE,
  // )
  const propertyState =
    property?.kanbanProcessStageState ?? DEFAULT_PROPERTY_STATE

  // console.log('Property State', propertyState)

  const [isEditing, setIsEditing] = useState(false)
  const { loading, data, error } = useQuery(GET_TEAM)
  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  const { teamMembers = [] } = data || {}
  // console.log('Team members', teamMembers)
  const transformedTeamMembersList = useMemo(
    () =>
      teamMembers?.map((member) => ({
        // _id: member._id,
        name: `${member?.profile.firstname} ${member?.profile.lastname}`,
        _id: `${member?.profile.firstname} ${member?.profile.lastname}`,
      })),
    [teamMembers],
  )
  // console.log('Kanban card rendered')

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: property?._id,
    data: {
      type: 'Card',
      property,
    },
  })
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }
  // const client = useApolloClient()

  // console.log('Property id in cache', client.cache.identify(property))
  // console.log(
  //   'kanbanProcessStageState',
  //   property?.kanbanProcessStageState,
  // )

  const handlePropertyStateSubmit = (
    propertyState,
    comments = [],
  ) => {
    const propertyInput = propertyState
      ? {
          kanbanProcessStageState: { ...propertyState },
          comments,
        }
      : {
          comments,
        }
    updateProperty({
      variables: {
        id: property?._id,
        propertyInput: propertyInput,
      },
      update(cache, { data: { updateProperty } }) {
        const existingProperty = cache.readFragment({
          id: cache.identify(property), // Get the identifier of the property
          fragment: gql`
            fragment ExistingProperty on Property {
              _id
              kanbanProcessStageState
            }
          `,
        })

        // console.log('Existing Property in cache', existingProperty)

        if (existingProperty) {
          // Update only the necessary part of the cache
          cache.writeFragment({
            id: cache.identify(property),
            fragment: gql`
              fragment NewKanbanState on Property {
                kanbanProcessStageState
              }
            `,
            data: {
              kanbanProcessStageState:
                updateProperty?.kanbanProcessStageState,
            },
          })
        }
        // Manually trigger a local state update
        if (
          updateLocalProperties &&
          typeof updateLocalProperties === 'function'
        )
          updateLocalProperties(updateProperty)
      },
    })
    setIsEditing(false)
  }
  const handleExpandClick = useCallback(() => {
    setExpanded((prev) => !prev)
  }, [])

  const handlePropertyStateChange = (e) => {
    // console.log('event ', e)
    if (e.target.name === 'labels') {
      // console.log('Is label')
      setPropertyState((prev) => ({
        ...prev,
        [e.target.name]: [...prev.labels, e.target.value],
      }))
      return
    }
    setPropertyState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const removeLabel = (label) => {
    let newLabels = [...propertyState?.labels]
    remove(newLabels, (o) => o === label)
    setPropertyState((prev) => ({
      ...prev,
      labels: newLabels,
    }))
  }

  if (isDragging) {
    return (
      <Card
        // key={stage?.id}
        sx={{
          maxWidth: 345,
          height: 500,
          marginTop: 2,
          background: 'transparent',
          borderColor: 'primary.main',
          border: 1,
        }}
        ref={setNodeRef}
        style={style}
      >
        <CardHeader />
        <CardContent>
          <Typography
            variant="body2"
            color="text.secondary"
          ></Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      <Card
        {...attributes}
        // {...listeners}
        style={style}
        ref={setNodeRef}
        sx={{ maxWidth: 345, marginTop: 2 }}
      >
        <CardHeader
          // avatar={
          //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
          //     R
          //   </Avatar>
          // }
          // action={
          //   <IconButton aria-label="settings">
          //     <ControlCameraIcon {...listeners} />
          //   </IconButton>
          // }
          title={property?.address}
          subheader={property?.city}
          {...listeners}
        />
        {property?.mainImage && (
          <CardMedia
            component="img"
            height="194"
            image={property?.mainImage}
            alt="Property Image"
            {...listeners}
          />
        )}
        <CardContent>
          <div {...listeners}>
            {/* <Typography
            variant="h6"
            sx={{ fontWeight: 'bold' }}
            color="text.secondary"
          >
            {property?.address}
          </Typography> */}
            <Typography variant="body2" color="text.secondary">
              {`List Price: ${currencyFormat.format(property?.acquisition?.analysis?.listPrice)}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`ARV: ${currencyFormat.format(property?.acquisition?.analysis?.afterRepairValue)}`}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`MAO: ${property?.MAO ? currencyFormat.format(property?.MAO) : '$0'}`}
            </Typography>
          </div>
          <Stack
            spacing={1}
            direction="Column"
            useFlexGap
            flexWrap="wrap"
            sx={{ width: '100%' }}
          >
            {isEditing ? (
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label="Due Date"
                  name="dueDate"
                  value={new Date(propertyState?.dueDate)}
                  onChange={(value) =>
                    handlePropertyStateChange({
                      target: {
                        name: 'dueDate',
                        value,
                      },
                    })
                  }
                />
              </DemoContainer>
            ) : (
              <Chip
                icon={<Timelapse />}
                label={new Date(
                  propertyState?.dueDate ?? Date.now(),
                ).toLocaleDateString()}
              />
            )}
            {isEditing ? (
              <Select
                label="Priority"
                items={PRIORITIES}
                value={propertyState?.priority ?? ''}
                onChange={handlePropertyStateChange}
                name="priority"
              />
            ) : (
              <Chip
                label={`Priority: ${propertyState?.priority ?? 'N/A'}`}
              />
            )}
          </Stack>
        </CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {isEditing ? (
              <Select
                label="Point of Contact"
                items={transformedTeamMembersList}
                value={propertyState?.pointOfContact}
                onChange={handlePropertyStateChange}
                name="pointOfContact"
                sx={{ marginBottom: 2 }}
              />
            ) : (
              <Typography variant="body2" color="text.secondary">
                {`Point of Contact: ${
                  propertyState?.pointOfContact == ''
                    ? 'N/A'
                    : propertyState?.pointOfContact
                }`}
              </Typography>
            )}
            <Stack
              spacing={1}
              direction="column"
              useFlexGap
              flexWrap="wrap"
              sx={{ width: '100%', marginBottom: 1 }}
            >
              {isEditing ? (
                <Select
                  label="On Market"
                  items={YES_NO_LISTITEMS}
                  value={propertyState?.onMarket}
                  onChange={handlePropertyStateChange}
                  name="onMarket"
                  sx={{ minWidth: '150px' }}
                />
              ) : (
                <Chip
                  label={`On or Off Market: ${
                    propertyState?.onMarket ?? 'N/A'
                  }`}
                />
              )}
              {isEditing ? (
                <Select
                  label="Exclusive Buy Price"
                  items={YES_NO_LISTITEMS}
                  value={propertyState?.exclusiveBuyPrice}
                  onChange={handlePropertyStateChange}
                  name="exclusiveBuyPrice"
                  sx={{ minWidth: '150px' }}
                />
              ) : (
                <Chip
                  label={`Exclusive Buy Price: ${
                    propertyState?.exclusiveBuyPrice ?? 'N/A'
                  }`}
                />
              )}
            </Stack>
            <Stack
              direction="row"
              alignContent="space-evenly"
              flexWrap={'wrap'}
              spacing={1}
              useFlexGap
              sx={{ width: '100%' }}
            >
              {isEditing && (
                <AddTextInput
                  title="Add New Label"
                  label="Label Name"
                  onSubmit={(value) =>
                    handlePropertyStateChange({
                      target: {
                        name: 'labels',
                        value,
                      },
                    })
                  }
                >
                  {({ onClick }) => (
                    <Chip
                      icon={<Add />}
                      label="Add new label"
                      variant="outlined"
                      onClick={onClick}
                    />
                  )}
                </AddTextInput>
              )}
              {propertyState?.labels?.map((label) => (
                <Chip
                  label={label}
                  color="success"
                  size="small"
                  onDelete={
                    isEditing ? () => removeLabel(label) : null
                  }
                />
              ))}
            </Stack>
            {isEditing ? (
              <TextField
                id="outlined-multiline-static"
                label="Notes"
                name="notes"
                multiline
                rows={4}
                fullWidth
                margin="normal"
                defaultValue={propertyState?.notes}
                onChange={handlePropertyStateChange}
              />
            ) : (
              <>
                <Typography
                  marginTop={2}
                  marginBottom={0}
                  // paddingBottom={1}
                  paragraph
                >
                  Notes:
                </Typography>
                <Typography paragraph>
                  {propertyState?.notes}
                </Typography>
              </>
            )}
            {/* <Typography paragraph>Description:</Typography>
            <Typography paragraph>{property?.description}</Typography> */}
            {/* <Typography paragraph>Method:</Typography>
            <Typography paragraph>
              Heat 1/2 cup of the broth in a pot until simmering, add
              saffron and set aside for 10 minutes.
            </Typography>
            <Typography paragraph>
              Heat oil in a (14- to 16-inch) paella pan or a large, deep
              skillet over medium-high heat. Add chicken, shrimp and
              chorizo, and cook, stirring occasionally until lightly
              browned, 6 to 8 minutes. Transfer shrimp to a large plate
              and set aside, leaving chicken and chorizo in the pan. Add
              pimentón, bay leaves, garlic, tomatoes, onion, salt and
              pepper, and cook, stirring often until thickened and
              fragrant, about 10 minutes. Add saffron broth and
              remaining 4 1/2 cups chicken broth; bring to a boil.
            </Typography>
            <Typography paragraph>
              Add rice and stir very gently to distribute. Top with
              artichokes and peppers, and cook without stirring, until
              most of the liquid is absorbed, 15 to 18 minutes. Reduce
              heat to medium-low, add reserved shrimp and mussels,
              tucking them down into the rice, and cook again without
              stirring, until mussels have opened and rice is just
              tender, 5 to 7 minutes more. (Discard any mussels that
              don&apos;t open.)
            </Typography>
            <Typography>
              Set aside off of the heat to let rest for 10 minutes, and
              then serve.
            </Typography> */}
          </CardContent>
        </Collapse>
        <CardActions disableSpacing>
          {/* <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton> */}
          {/* {!isEditing && (
            <IconButton aria-label="share">
              <ModeEdit onClick={() => setIsEditing(true)} />
            </IconButton>
          )}
          {isEditing && (
            <IconButton aria-label="share">
              <Save onClick={handlePropertyStateSubmit} />
            </IconButton>
          )} */}
          <IconButton onClick={handleDialogOpen}>
            <ModeEdit />
          </IconButton>
          <IconButton
            href={`/property-details/${property?._id}/Summary`}
            target="_blank"
          >
            <FontAwesomeIcon
              icon={['far', 'arrow-up-right-from-square']}
              className="d-block font-size-xl"
              // style={{ color: '#368cff' }}
              href={`/property-details/${property?._id}/Summary`}
              target="_blank"
            />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
      </Card>
      <EditCardDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
        property={property}
        teamMembers={teamMembers}
        handlePropertyStateSubmit={handlePropertyStateSubmit}
        userId={userId}
      />
    </>
  )
}

export default memo(KanbanCard)
