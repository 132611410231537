/**
 * @ Author: Housefolios
 * @ Create Time: 2021-10-05 11:07:32
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-02-28 16:52:53
 * @ Description: Dialog to cancel subscription and become a free account
 */

import React, { useState } from 'react'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import capitalize from 'lodash/capitalize'
import split from 'lodash/split'
import replace from 'lodash/replace'
import round from 'lodash/round'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Switch,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import withSession from '@/housefolios-components/Session/withSession'
import { ApolloConsumer } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import {
  CANCEL_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  STOPCANCEL_SUBSCRIPTION,
} from '@/housefolios-components/zComponents/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'

function CancelButton(props) {
  const { title, session } = props
  const { activeOrg } = session.me

  const [showCancel, setShowCancel] = useState(false)

  const toggleCancel = () => {
    setShowCancel(!showCancel)
  }

  const [loader, setLoader] = useState(false)

  const [showVerifyCancel, setShowVerifyCancel] = useState(false)

  const toggleVerifyCancel = () => {
    setLoader(false)
    setShowVerifyCancel(!showVerifyCancel)
  }

  const [showCoachingCancel, setShowCoachingCancel] = useState(false)

  const toggleCoachingCancel = () => {
    setLoader(false)
    setShowCoachingCancel(!showCoachingCancel)
  }

  const activeSubs = filter(activeOrg.subscriptions, [
    'disabled',
    false,
  ])

  const [plan, billing] = props.session.me.subscriptionPlan
    ? split(replace(props.session.me.subscriptionPlan, '_', '-'), '-')
    : ''

  const date =
    !isEmpty(activeSubs) && activeSubs[0].current_period_end
      ? new Date(activeSubs[0].current_period_end * 1000)
      : new Date('01/01/1970')
  const dateString = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const toBeCanceled = !isEmpty(
    props.session.me.activeOrg.subscriptions,
  )
    ? props.session.me.activeOrg.subscriptions[0].cancel_at_period_end
    : props.session.me.activeOrg.parentCorp &&
      !isEmpty(props.session.me.activeOrg.parentCorp.subscriptions)
    ? props.session.me.activeOrg.parentCorp.subscriptions[0]
        .cancel_at_period_end
    : true

  const [cancelBeforeCharge, setCancelBeforeCharge] = useState(true)

  return (
    <>
      {title && !isEmpty(activeSubs) && (
        <Button
          variant="contained"
          size="small"
          className="btn-danger"
          style={{ fontWeight: 600 }}
          onClick={
            billing == 'coaching'
              ? toggleCoachingCancel
              : toggleVerifyCancel
          }
        >
          {/* <Close style={{ color: 'white', marginRight: '5px' }} /> */}

          <FontAwesomeIcon
            icon={['far', 'xmark']}
            style={{ marginRight: '10px' }}
            className="font-size-md"
          />
          <span
            className="btn-wrapper--label"
            style={{ fontWeight: 600 }}
          >
            {toBeCanceled
              ? `Plan cancels on ${dateString}`
              : 'Cancel Account'}
          </span>
        </Button>
      )}

      <Dialog
        open={showCoachingCancel}
        onClose={toggleCoachingCancel}
      >
        <DialogTitle>Verify Account</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography>
                As a coaching plan we cannot automatically cancel your
                subscription. If you wish to cancel contact us through
                support
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleCoachingCancel}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showVerifyCancel} onClose={toggleVerifyCancel}>
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={<div className="text-primary">Please Wait</div>}
        >
          <DialogTitle>Verify Account</DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography>
                  Are you sure you want to cancel? We would love you
                  to stay!
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <Typography>Cancel Now</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={cancelBeforeCharge}
                      onChange={() =>
                        setCancelBeforeCharge(!cancelBeforeCharge)
                      }
                      color="primary"
                      className="switch-medium"
                    />
                  </Grid>
                  <Grid item>
                    <Typography>
                      {toBeCanceled
                        ? 'Stop Cancel'
                        : 'Before Next Charge'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!cancelBeforeCharge ? (
                <Grid item>
                  <Typography>
                    Cancelation will occur today. Your account will
                    become a free account and you will lose all
                    features of a paid plan.
                  </Typography>
                </Grid>
              ) : !toBeCanceled ? (
                <Grid item>
                  <Typography>
                    Cancelation will occur on {dateString}. Your
                    account will become a free account and you will
                    lose all features of a paid plan.
                  </Typography>
                </Grid>
              ) : (
                <Grid item>
                  <Typography>
                    Your {capitalize(plan)} plan and will not be
                    canceled and your credit card will be charged{' '}
                    {currencyFormat.format(
                      round(props.session.me.subscriptionPrice / 100),
                    )}{' '}
                    on the next billing period: {dateString}.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          {!cancelBeforeCharge ? (
            <Mutation
              mutation={UPDATE_ORGANIZATION}
              variables={{
                id: activeOrg._id,
                organizationInput: {
                  disabled: false,
                  delinquent: '',
                },
              }}
            >
              {(updateOrganization, { data, loading, error }) => (
                <Mutation
                  mutation={DELETE_SUBSCRIPTION}
                  variables={{
                    id: head(activeOrg.subscriptions)
                      ? head(activeOrg.subscriptions).id
                      : null,
                  }}
                  refetchQueries={[
                    {
                      query: GET_ME,
                    },
                  ]}
                >
                  {(deleteSubscription, { data, loading, error }) => (
                    <DialogActions>
                      <ApolloConsumer>
                        {(client) => (
                          <Button
                            className="btn-danger"
                            variant="contained"
                            onClick={() => {
                              setLoader(true)
                              deleteSubscription().then(
                                ({ data }) => {
                                  updateOrganization().then(
                                    ({ data }) => {
                                      window.open(
                                        'https://docs.google.com/forms/d/e/1FAIpQLSeY8GKTrOwKREf3t41W0N49whxMPylFUsQG4mrQqMQQDJH99A/viewform?usp=sf_link',
                                        '_blank',
                                      )
                                      toggleVerifyCancel()
                                      // localStorage.clear()
                                      // client.resetStore()
                                    },
                                  )
                                },
                              )
                            }}
                          >
                            Cancel
                          </Button>
                        )}
                      </ApolloConsumer>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={toggleVerifyCancel}
                      >
                        {toBeCanceled ? 'Close' : 'Keep Plan'}
                      </Button>
                    </DialogActions>
                  )}
                </Mutation>
              )}
            </Mutation>
          ) : !toBeCanceled ? (
            <Mutation
              mutation={UPDATE_ORGANIZATION}
              variables={{
                id: activeOrg._id,
                organizationInput: {
                  disabled: false,
                  delinquent: '',
                },
              }}
              refetchQueries={[{ query: GET_ME }]}
            >
              {(updateOrganization, { data, loading, error }) => (
                <Mutation
                  mutation={CANCEL_SUBSCRIPTION}
                  variables={{
                    id: head(activeOrg.subscriptions)
                      ? head(activeOrg.subscriptions).id
                      : null,
                  }}
                >
                  {(cancelSubscription, { data, loading, error }) => (
                    <DialogActions>
                      <ApolloConsumer>
                        {(client) => (
                          <Button
                            className="btn-danger"
                            variant="contained"
                            onClick={() => {
                              setLoader(true)
                              cancelSubscription().then(
                                ({ data }) => {
                                  updateOrganization().then(
                                    ({ data }) => {
                                      window.open(
                                        'https://forms.gle/Cbr73Eac1gvDZePd8',
                                        '_blank',
                                      )
                                      toggleVerifyCancel()
                                      // localStorage.clear()
                                      // client.resetStore()
                                    },
                                  )
                                },
                              )
                            }}
                          >
                            Cancel
                          </Button>
                        )}
                      </ApolloConsumer>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={toggleVerifyCancel}
                      >
                        Keep Plan
                      </Button>
                    </DialogActions>
                  )}
                </Mutation>
              )}
            </Mutation>
          ) : (
            <Mutation
              mutation={UPDATE_ORGANIZATION}
              variables={{
                id: activeOrg._id,
                organizationInput: {
                  disabled: false,
                  delinquent: '',
                },
              }}
              refetchQueries={[{ query: GET_ME }]}
            >
              {(updateOrganization, { data, loading, error }) => (
                <Mutation
                  mutation={STOPCANCEL_SUBSCRIPTION}
                  variables={{
                    id: head(activeOrg.subscriptions)
                      ? head(activeOrg.subscriptions).id
                      : null,
                  }}
                >
                  {(
                    stopCancelSubscription,
                    { data, loading, error },
                  ) => (
                    <DialogActions>
                      <Button
                        color="primary"
                        onClick={toggleVerifyCancel}
                      >
                        Close
                      </Button>
                      <ApolloConsumer>
                        {(client) => (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setLoader(true)
                              stopCancelSubscription().then(
                                ({ data }) => {
                                  updateOrganization().then(
                                    ({ data }) => {
                                      //window.open('https://forms.gle/Cbr73Eac1gvDZePd8', "_blank")
                                      toggleVerifyCancel()
                                      // localStorage.clear()
                                      // client.resetStore()
                                    },
                                  )
                                },
                              )
                            }}
                          >
                            Stop Cancel
                          </Button>
                        )}
                      </ApolloConsumer>
                    </DialogActions>
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </BlockUi>
      </Dialog>

      {/* <Dialog open={showCancel} onClose={toggleCancel}>
        <DialogTitle>
          Cancel Account
        </DialogTitle>
        <DialogContent>
          Cancelation would occur on {dateString}. Your account will become a free account and will lose all features of a paid plan.
        </DialogContent>
        <Mutation
          mutation={UPDATE_ORGANIZATION}
          variables={{
            id: activeOrg._id,
            organizationInput: {
              disabled: false,
              delinquent: '',
            }
          }}
          refetchQueries={[
            { query: GET_ME }
          ]}
        >
          {(updateOrganization, { data, loading, error }) => (
            <Mutation
              mutation={CANCEL_SUBSCRIPTION}
              variables={{
                id: head(activeOrg.subscriptions) ? head(activeOrg.subscriptions).id : null,
              }}
            >
              {(cancelSubscription, { data, loading, error }) => (
                <DialogActions>
                  <ApolloConsumer>
                    {client =>
                      (<Button color='primary' onClick={() => {
                        cancelSubscription().then(({ data }) => {
                          updateOrganization().then(({ data }) => {
                            window.open('https://forms.gle/Cbr73Eac1gvDZePd8', "_blank")
                            toggleCancel()
                            // localStorage.clear()
                            // client.resetStore()
                          })
                        })
                      }}>
                        Cancel Plan
                    </Button>)}
                  </ApolloConsumer>
                  <Button variant='contained' color='primary' onClick={toggleCancel}>
                    Keep Plan
                  </Button>
                </DialogActions>
              )}
            </Mutation>
          )}
        </Mutation>
      </Dialog> */}
    </>
  )
}

export default withSession(CancelButton)
