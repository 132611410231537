/**
 * @ Author: Housefolios
 * @ Create Time: 2022-06-01 16:55:02
 * @ Modified by: David Helmick
 * @ Modified time: 2025-01-16 14:40:31
 * @ Description: Dialog for setting critical property fields
 */

import React, { useEffect, useState } from 'react'

import clsx from 'clsx'

import map from 'lodash/map'
import round from 'lodash/round'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import padStart from 'lodash/padStart'
import head from 'lodash/head'
import find from 'lodash/find'
import slice from 'lodash/slice'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import replace from 'lodash/replace'
import isUndefined from 'lodash/isUndefined'
import kebabCase from 'lodash/kebabCase'
import orderBy from 'lodash/orderBy'
import unset from 'lodash/unset'
import set from 'lodash/set'
import get from 'lodash/get'
import remove from 'lodash/remove'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import withSession from '@/housefolios-components/Session/withSession'
import { enqueueSnackbar } from 'notistack'
import { NumericFormat } from 'react-number-format'
import { PacmanLoader } from 'react-spinners'
import BlockUi from 'react-block-ui'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import Autocomplete from '@mui/material/Autocomplete'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import {
  GET_COMPPROPERTIES,
  GET_PROPERTY,
} from '@/housefolios-components/PropertyContainer/queries'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_ASSUMPTIONS } from '@/housefolios-components/AssumptionsSettings/quries'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import renderInputFieldsFunction from '@/housefolios-components/Functions/renderInput'
import {
  Compare,
  ExpandLess,
  ExpandMore,
  Tune,
} from '@mui/icons-material'
import useAffiliate from '@/hooks/useAffiliate'
import {
  filterPreferedStrategies,
  getStrategyName,
} from '@/utils/stratagies'
import useEstimatesMenu from '@/hooks/useEstimatesMenu'

function CriticalFieldsDialog(props) {
  const { open, onClose, propertyId } = props
  const [analysisInput, setAnalysisInput] = useState({})
  const [assumptionsInput, setAssumptionsInput] = useState({})
  const [activeUnit, setActiveUnit] = useState(0)

  const [loader, setLoader] = useState(false)

  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const [{ affiliate, urlAffiliate }] = useAffiliate({})

  const [callProperty, results] = useLazyQuery(GET_PROPERTY, {
    variables: { id: propertyId },
  })
  let { property = {} } = results.data || {}
  const propertyLoading = results.loading || !results.called
  const propertyError = results.error

  const {
    acquisition = {},
    assumptions = {},
    portfolio = {},
  } = property || {}
  const { analysis = {}, analytics = DEFAULT_ANALYTICS } =
    acquisition || {}

  const [callComps, compResults] = useLazyQuery(GET_COMPPROPERTIES, {
    variables: { propertyId: property._id },
  })
  const data = compResults.data
  const compsLoading = compResults.loading || !compResults.called
  const compsError = compResults.error

  let { compProperties = [] } = data || {}
  const determinedComps = filter(
    compProperties,
    (comp) => !comp.potential,
  )

  const [strategy, setStrategy] = useState('Buy & Hold')
  const [accordion, setAccordion] = useState('Universal')

  const toggleAccordion = (tab) => {
    if (accordion === tab) setAccordion('')
    else setAccordion(tab)
  }

  useEffect(() => {
    setActiveUnit(0)
    if (
      props.open &&
      props.propertyId &&
      (!results.called || !compResults.called)
    ) {
      callProperty()
      callComps()
    } else if (!props.open || !props.propertyId) {
      setAnalysisInput({})
      setAssumptionsInput({})
      setActiveUnit(0)
      setLoader(false)
      setStrategy('Buy & Hold')
      setAccordion('Universal')
    }
  }, [props.open])

  useEffect(() => {
    if (
      isEmpty(analysisInput) &&
      !propertyLoading &&
      !isEmpty(analysis) &&
      props.propertyId === property._id
    ) {
      let newAnalysisInput = cloneDeep(analysis)
      unset(newAnalysisInput, 'createdBy')
      unset(newAnalysisInput, 'createdAt')
      unset(newAnalysisInput, '__typename')
      setAnalysisInput(newAnalysisInput)
    }
  }, [analysis, analysisInput, propertyLoading])

  useEffect(() => {
    if (
      isEmpty(assumptionsInput) &&
      !propertyLoading &&
      !isEmpty(assumptions) &&
      props.propertyId === property._id
    ) {
      let newAssumptionsInput = cloneDeep(assumptions)
      unset(newAssumptionsInput, '_id')
      unset(newAssumptionsInput, '__v')
      unset(newAssumptionsInput, '__typename')
      unset(newAssumptionsInput, 'documentId')
      unset(newAssumptionsInput, 'documentType')
      unset(newAssumptionsInput, 'updatedAt')
      unset(newAssumptionsInput, 'updatedBy')
      setAssumptionsInput(newAssumptionsInput)
    }
  }, [assumptions, assumptionsInput, propertyLoading])

  const preferences = merge(
    cloneDeep(DEFAULT_PREFERENCES),
    props?.session?.me?.activeOrg?.memberPreferences,
    props?.session?.me?.activeOrg?.preferences,
    {
      general: {
        strategies:
          property && property.portfolio
            ? property.portfolio.strategies
            : [],
      },
    },
  )
  const strategies =
    property && !isEmpty(property.strategies)
      ? property.strategies
      : filterPreferedStrategies(
          preferences,
          props?.session?.me?.activeOrg?.member,
          true,
        )

  useEffect(() => {
    if (!propertyLoading && !isEmpty(portfolio)) {
      if (includes(strategies, portfolio.strategy))
        setStrategy(portfolio.strategy)
      else setStrategy(head(strategies))
    }
  }, [propertyLoading])

  // const [source, setSource] = useState(null)

  const onChangeAnalysis = (event, source = null) => {
    let { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value) || 0
    let newAnalysisInput = cloneDeep(analysisInput)
    //  if (name === 'ownerOccupied') {
    //   newAnalysisInput.ownerOccupied = newAnalysisInput.ownerOccupied !== activeUnit ? activeUnit : -1
    //   name = `rent.${activeUnit}`
    //   newValue = 0
    // }
    set(newAnalysisInput, name, newValue)
    setAnalysisInput(newAnalysisInput)
  }

  const handleAnalysisAdornmentChange = (e, name, value) => {
    e.preventDefault()
    let newAnalysisInput = { ...analysisInput }
    newAnalysisInput[name] = value
    setAnalysisInput(newAnalysisInput)
    handleAssumptionsMenuClose()
  }

  const onChangeAssumptions = (event, source = null) => {
    let { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value)
    let newAssumptionsInput = cloneDeep(assumptionsInput)
    set(newAssumptionsInput, name, newValue)
    unset(newAssumptionsInput, '_id')
    unset(newAssumptionsInput, '__v')
    unset(newAssumptionsInput, '__typename')
    unset(newAssumptionsInput, 'documentId')
    unset(newAssumptionsInput, 'documentType')
    unset(newAssumptionsInput, 'updatedAt')
    unset(newAssumptionsInput, 'updatedBy')
    setAssumptionsInput(newAssumptionsInput)
  }

  const [anchorElAssumptions, setAnchorElAssumptions] = useState(null)
  const [showFormatDropdown, setShowFormatDropdown] = useState('')

  const toggleAssumptionsMenuFormat = (event, name) => {
    setAnchorElAssumptions(event.target)
    setShowFormatDropdown(name)
  }

  const handleAssumptionsMenuClose = () => {
    setAnchorElAssumptions(null)
  }

  const handleAdornmentChange = (e, name, value) => {
    e.preventDefault()
    let newAssumptions = { ...assumptionsInput }
    set(newAssumptions, name, value)
    unset(newAssumptions, '_id')
    unset(newAssumptions, '__v')
    unset(newAssumptions, '__typename')
    unset(newAssumptions, 'documentId')
    unset(newAssumptions, 'documentType')
    unset(newAssumptions, 'updatedAt')
    unset(newAssumptions, 'updatedBy')
    setAssumptionsInput(newAssumptions)
    handleAssumptionsMenuClose()
  }

  const handleDefinition = (name, anchorEl, target = '') => {
    setMenu(name)
    setMenuTarget(target)
    setOpenNoData(false)
    setAnchorElEstimates(anchorEl)
  }

  const [anchorElEstimates, setAnchorElEstimates] = useState(null)
  const [menu, setMenu] = useState('')
  const [menuTarget, setMenuTarget] = useState('')
  const [estimatesCollapse, setEstimatesCollapse] = useState({})
  const [openNoData, setOpenNoData] = useState(false)

  const changeMenu = (name, value, source = null) => {
    let newName = name
    let newValue = value
    if (
      includes(name, 'rent') ||
      name == 'avgRate' ||
      name === 'occupancyRate'
    ) {
      if (assumptionsInput[name + 'Type'] === 'rentRange') {
        onChangeRange(
          replace(name, 'rent', 'rentLow') + '.' + activeUnit,
          replace(name, 'rent', 'rentHigh') + '.' + activeUnit,
          Number(newValue),
          Number(newValue),
          source,
        )
        setMenu('')
        setMenuTarget('')
        setAnchorElEstimates(null)
        return
      }
      newName = name + '.' + activeUnit
    }
    if (name == 'avgRate' || name === 'occupancyRate')
      onChangeAssumptions(
        {
          target: {
            name: newName,
            value: round(newValue),
            type: 'number',
          },
        },
        source,
      )
    else
      onChangeAnalysis(
        {
          target: { name: newName, value: newValue, type: 'number' },
        },
        source,
      )
    setMenu('')
    setMenuTarget('')
    setAnchorElEstimates(null)
  }

  const onChangeRange = (
    nameLow,
    nameHigh,
    min,
    max,
    source = null,
  ) => {
    let newAnalysisInput = cloneDeep(analysisInput)
    set(newAnalysisInput, nameLow, min)
    set(newAnalysisInput, nameHigh, max)
    setAnalysisInput(newAnalysisInput)
    //  let newSource = null
    //  if (source && !isEqual(source, get(property, `source.${nameLow}`))) {
    //    newSource = cloneDeep(property.source)
    //    if (!isArray(newSource.rent))
    //      newSource.rent = [newSource.rent]
    //    set(newSource, nameLow, source)
    //    setSource(newSource)
    //  }
    //  if (source && !isEqual(source, get(property, `source.${nameHigh}`))) {
    //    newSource = cloneDeep(property.source)
    //    if (!isArray(newSource.rent))
    //      newSource.rent = [newSource.rent]
    //    set(newSource, analysisInput, source)
    //    setSource(newSource)
    //  }
  }

  const toggleCollapse = (name) => {
    let newEstimatesCollapse = { ...estimatesCollapse }
    newEstimatesCollapse[name] = !estimatesCollapse[name]
    setEstimatesCollapse(newEstimatesCollapse)
  }

  const renderInputFields = renderInputFieldsFunction(
    {
      onChangeAnalysis,
      onChangeAssumptions,
    },
    {
      showFormatDropdown,
      anchorElAssumptions,
      toggleAssumptionsMenuFormat,
      handleAssumptionsMenuClose,
      handleAdornmentChange,
      handleAnalysisAdornmentChange,
      handleDefinition,
    },
    props?.session?.me?.activeOrg?.member,
  )()

  //  const renderInputFields = section => (
  //   <Grid item xs={12} sm={section.width} key={section.label}>
  //     {section.select ? (
  //       <Autocomplete
  //         value={section.value}
  //         options={section.options}
  //         onChange={(event, newValue) => section.assumptions ? onChangeAssumptions({ target: { value: newValue }}) : onChangeAnalysis({ target: { value: newValue }})}
  //         renderInput={(params) => <TextField {...params} label={section.label} size="small" variant="outlined" margin='dense' />}
  //       />
  //     ) : section.dropdown ? (
  //       <TextField
  //         name={section.name}
  //         variant="outlined"
  //         margin="dense"
  //         value={section.value || ''}
  //         onChange={section.onChange ? section.onChange : section.assumptions ? onChangeAssumptions : onChangeAnalysis}
  //         placeholder={section.label}
  //         label={section.label}
  //         fullWidth={true}
  //         disabled={section.disabled}
  //         required={section.required}
  //         type='number'
  //         InputProps={{
  //           endAdornment: (
  //             <InputAdornment position="end" margin='dense'>
  //               <Button
  //                 onClick={e => section.disabled ? null : toggleAssumptionsMenuFormat(e, section.adornmentName)}
  //                 size="small"
  //                 margin='dense'
  //                 style={{ padding: 3 }}
  //                 className="btn-neutral-dark d-flex align-items-center">
  //                 <span className="btn-wrapper--label">{section.adornment}</span>
  //                 <span className="btn-wrapper--icon d-flex">
  //                   <FontAwesomeIcon
  //                     icon={['fas', 'chevron-down']}
  //                     className="opacity-8 font-size-xs ml-1"
  //                   />
  //                 </span>
  //               </Button>
  //               <Menu
  //                 anchorEl={anchorElAssumptions}
  //                 keepMounted
  //                 name={section.adornmentName}
  //                 open={Boolean(anchorElAssumptions) && section.adornmentName === showFormatDropdown}
  //                 onClose={handleAssumptionsMenuClose}
  //                 anchorOrigin={{
  //                   vertical: 'center',
  //                   horizontal: 'center'
  //                 }}
  //                 transformOrigin={{
  //                   vertical: 'center',
  //                   horizontal: 'center'
  //                 }}
  //                 classes={{ list: 'p-0' }}
  //               >
  //                 <div className="p-2">
  //                   <List
  //                     component="div"
  //                     className="nav-pills p-0 m-0 nav-neutral-dark flex-column">
  //                     <ListItem
  //                       key="$"
  //                       button
  //                       href={null}
  //                       onClick={(e) => section.assumptions ? handleAdornmentChange(e, section.adornmentName, section.adornmentName === 'termType' || includes(section.adornmentName, 'TermType')  ? 'Months' : section.adornmentName === 'estimatedRehabType' ? 'SQFT $' : section.adornmentName === 'avgRateType' ? 'Daily' : '$') : handleAnalysisAdornmentChange(e, section.adornmentName, includes(section.adornmentName, 'HOA') ? 'Monthly' : '$')}
  //                       selected={section.adornment === '$' || section.adornment === 'SQFT $' || 'Months'}
  //                       className="px-3 mx-2">
  //                       <span>{section.assumptions ? section.adornmentName === 'termType' || includes(section.adornmentName, 'TermType')  ? 'Months' : section.adornmentName === 'estimatedRehabType' ? 'SQFT $' : section.adornmentName === 'avgRateType' ? 'Daily' : '$' : includes(section.adornmentName, 'HOA') ? 'Monthly' : '$'}</span>
  //                     </ListItem>
  //                     <ListItem
  //                       key="%"
  //                       button
  //                       href={null}
  //                       onClick={(e) => section.assumptions ? handleAdornmentChange(e, section.adornmentName, section.adornmentName === 'termType' || includes(section.adornmentName, 'TermType')  ? 'Years' : section.adornmentName === 'estimatedRehabType' ? 'Total $' : section.adornmentName === 'avgRateType' ? 'Weekly' : '%') : handleAnalysisAdornmentChange(e, section.adornmentName, includes(section.adornmentName, 'HOA') ? 'Yearly' : '%')}
  //                       selected={section.adornment === '%' || section.adornment === 'Total $' || 'Years'}
  //                       className="px-3 mx-2">
  //                       <span>{section.assumptions ? section.adornmentName === 'termType' || includes(section.adornmentName, 'TermType')  ? 'Years' : section.adornmentName === 'estimatedRehabType' ? 'Total $' : section.adornmentName === 'avgRateType' ? 'Weekly' : '%' : includes(section.adornmentName, 'HOA') ? 'Yearly' : '%'}</span>
  //                     </ListItem>
  //                   </List>
  //                 </div>
  //               </Menu>
  //             </InputAdornment>
  //           )
  //         }}
  //       />
  //     ) : section.type === 'checkbox' ? (
  //       <Grid
  //         item
  //         xs={section.xs}
  //         sm={section.sm}
  //         md={section.md}
  //         lg={section.lg}
  //         xl={section.xl}
  //       >
  //         <FormControlLabel
  //           onChange={section.onChange ? section.onChange : section.assumptions ? onChangeAssumptions : onChangeAnalysis}
  //           control={<Checkbox id={section.label} checked={section.value} name={section.name} />}
  //           label={section.label}
  //           disabled={section.disabled}
  //           required={section.required}
  //         />
  //       </Grid>
  //     ) : section.type === 'dollar' ? (
  //       <NumericFormat
  //         customInput={TextField}
  //         thousandSeparator={true}
  //         prefix={'$'}
  //         fullWidth={true}
  //         isNumericString={true}
  //         decimalScale={0}
  //         key={section.name}
  //         name={section.name}
  //         value={Math.round(section.value) || ''}
  //         disabled={section.disabled}
  //         onValueChange={values => {
  //           const { value } = values
  //           const event = {
  //             target: {
  //               name: section.name,
  //               value: value,
  //               type: 'number',
  //             },
  //             preventDefault: () => {
  //               return null
  //             },
  //           }
  //           if (section.disabled) return null
  //           if (section.assumptions) onChangeAssumptions(event)
  //           else onChangeAnalysis(event)
  //         }}
  //         type={'text'}
  //         onFocus={event => {
  //           event.target.select()
  //         }}
  //         variant='outlined'
  //         margin='dense'
  //         label={section.label}
  //         fontSize={section.font}
  //         required={section.required}
  //         helperText={
  //           <Typography color="primary" variant="caption" style={section.helperStyle ? section.helperStyle : null}>
  //             {section.helper}
  //           </Typography>
  //         }
  //       />
  //     ) : (
  //       <TextField
  //         required={section.required}
  //         variant="outlined"
  //         size="small"
  //         autoFocus={section.autoFocus}
  //         margin="dense"
  //         name={section.name}
  //         key={section.name}
  //         value={section.value}
  //         onChange={section.assumptions ? onChangeAssumptions : onChangeAnalysis}
  //         // id="input-with-icon-textfield134"
  //         label={section.label}
  //         placeholder={section.placeholder}
  //         type={section.type === 'percentage' ? 'number' : section.type}
  //         error={!section.value && section.label === 'Zip'}
  //         fullWidth
  //         InputProps={{
  //           endAdornment: section.type === 'percentage' ? <InputAdornment position="end">%</InputAdornment> : section.adornmentPosition === 'end' ? <InputAdornment position="end">{section.adornment}</InputAdornment> : null
  //         }}
  //       />
  //     )}
  //   </Grid>
  //  )

  const criticalFields = {
    Universal: [
      {
        type: 'dollar',
        label: 'After Repair Value',
        name: 'afterRepairValue',
        value: analysisInput.afterRepairValue,
        definition: 'afterRepairValue',
        definitionTarget: 'afterRepairValue',
        xs: 12,
        lg: 6,
        hide: includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        ),
      },
      {
        type: 'dollar',
        label: 'Asking Price',
        name: 'listPrice',
        value: analysisInput.listPrice,
        definition: 'listPrice',
        xs: 12,
        lg: 6,
      },
      {
        type: 'dollar',
        label: 'Property Taxes',
        name: 'propertyTaxes',
        value: analysisInput.propertyTaxes,
        definition: 'propertyTaxes',
        xs: 12,
        lg: 6,
      },
      {
        type: 'dollar',
        label: 'Purchase Price',
        name: 'offerPrice',
        value: analysisInput.offerPrice,
        definition: 'offerPrice',
        xs: 12,
        lg: 6,
      },
      {
        dropdown: true,
        type: 'dropdown',
        label: 'HOA',
        name: 'HOA',
        value: analysisInput.HOA,
        adornment: analysisInput.HOAType || 'Yearly',
        adornmentToggle: analysisInput.HOAType,
        adornmentName: 'HOAType',
        xs: 12,
        lg: 6,
      },
    ],
    'Buy & Hold': [
      {
        type: 'dollar',
        label: 'After Repair Value',
        name: 'afterRepairValueBuyHold',
        value: analysisInput.afterRepairValueBuyHold,
        definition: 'afterRepairValue',
        definitionTarget: 'afterRepairValueBuyHold',
        xs: 12,
        lg: 6,
        hide: !includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        ),
      },
      {
        type: 'dollar',
        label: 'Est Rehab',
        name: 'rehabCost',
        value: analysisInput.rehabCost,
        definition: 'rehabCost',
        xs: 12,
        lg: 6,
      },
      {
        type: 'dollar',
        label: 'Resale Price',
        name: 'resellPrice',
        value: analysisInput.resellPrice,
        xs: 12,
        lg: 6,
      },
    ],
    'Fix & Flip': [
      {
        type: 'dollar',
        label: 'After Repair Value',
        name: 'afterRepairValueFixFlip',
        value: analysisInput.afterRepairValueFixFlip,
        definition: 'afterRepairValue',
        definitionTarget: 'afterRepairValueFixFlip',
        xs: 12,
        lg: 6,
        hide: !includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        ),
      },
      {
        type: 'dollar',
        label: 'Est Rehab',
        name: 'rehabCostFixFlip',
        value: analysisInput.rehabCostFixFlip,
        definition: 'rehabCostFixFlip',
        xs: 12,
        lg: 6,
      },
      {
        type: 'dollar',
        label: 'Resale Price',
        name: 'resellPriceFixFlip',
        value: analysisInput.resellPriceFixFlip,
        xs: 12,
        lg: 6,
      },
    ],
    'Turnkey End Buyer': [
      {
        type: 'dollar',
        label: 'After Repair Value',
        name: 'afterRepairValueTurnkey',
        value: analysisInput.afterRepairValueTurnkey,
        definition: 'afterRepairValue',
        definitionTarget: 'afterRepairValueTurnkey',
        xs: 12,
        lg: 6,
        hide: !includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        ),
      },
      {
        type: 'dollar',
        label: 'End Buyer Purchase Price',
        name: 'turnkeyOfferPrice',
        value: analysisInput.turnkeyOfferPrice,
        xs: 12,
        lg: 6,
      },
      {
        type: 'dollar',
        label: 'Est Rehab',
        name: 'rehabCostTurnkey',
        value: analysisInput.rehabCostTurnkey,
        definition: 'rehabCostTurnkey',
        xs: 12,
        lg: 6,
      },
      {
        type: 'dollar',
        label: 'New Asking Price',
        name: 'resellPriceTurnkey',
        value: analysisInput.resellPriceTurnkey,
        xs: 12,
        lg: 6,
      },
      {
        type: 'dollar',
        label: 'Resale Price',
        name: 'resellPriceTurnkey',
        value: analysisInput.resellPriceTurnkey,
        xs: 12,
        lg: 6,
      },
    ],
    BRRRR: [
      {
        type: 'dollar',
        label: 'After Repair Value',
        name: 'afterRepairValueBRRRR',
        value: analysisInput.afterRepairValueBRRRR,
        definition: 'afterRepairValue',
        definitionTarget: 'afterRepairValueBRRRR',
        xs: 12,
        lg: 6,
        hide: !includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        ),
      },
      {
        type: 'dollar',
        label: 'Est Rehab',
        name: 'rehabCostBRRRR',
        value: analysisInput.rehabCostBRRRR,
        definition: 'rehabCostBRRRR',
        xs: 12,
        lg: 6,
      },
      {
        type: 'dollar',
        label: 'Resale Price',
        name: 'resellPriceBRRRR',
        value: analysisInput.resellPriceBRRRR,
        xs: 12,
        lg: 6,
      },
    ],
    'Short Term Rental': [
      {
        type: 'dollar',
        label: 'After Repair Value',
        name: 'afterRepairValueSTR',
        value: analysisInput.afterRepairValueSTR,
        definition: 'afterRepairValue',
        definitionTarget: 'afterRepairValueSTR',
        xs: 12,
        lg: 6,
        hide: !includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        ),
      },
      {
        type: 'dollar',
        label: 'Est Rehab',
        name: 'rehabCostSTR',
        value: analysisInput.rehabCostSTR,
        definition: 'rehabCostSTR',
        xs: 12,
        lg: 6,
      },
      {
        type: 'dropdown',
        name: 'avgRate',
        label: 'Avg Rate',
        value: assumptionsInput.avgRate,
        adornment: assumptionsInput.avgRateType || 'Daily',
        adornmentToggle: assumptionsInput.avgRateType,
        adornmentName: 'avgRateType',
        xs: 12,
        lg: 6,
        // definition: 'avgRate',
        dropdown: true,
        assumptions: true,
      },
    ],
    Wholesale: [
      {
        type: 'dollar',
        label: 'After Repair Value',
        name: 'afterRepairValueWholesale',
        value: analysisInput.afterRepairValueWholesale,
        definition: 'afterRepairValue',
        definitionTarget: 'afterRepairValueWholesale',
        xs: 12,
        lg: 6,
        hide: !includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        ),
      },
      {
        type: 'dollar',
        label: 'Wholesale Resale Price',
        name: 'resellPriceWholesale',
        value: analysisInput.resellPriceWholesale,
        xs: 12,
        lg: 6,
      },
    ],
    BOR: [
      {
        type: 'dollar',
        label: 'After Repair Value',
        name: 'afterRepairValueBOR',
        value: analysisInput.afterRepairValueBOR,
        definition: 'afterRepairValue',
        definitionTarget: 'afterRepairValueBOR',
        xs: 12,
        lg: 6,
        hide: !includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        ),
      },
      {
        type: 'dollar',
        label: 'Est Rehab',
        name: 'rehabCostBOR',
        value: analysisInput.rehabCostBOR,
        definition: 'rehabCostBOR',
        xs: 12,
        lg: 6,
      },
      {
        type: 'percentage',
        name: 'rateBOR',
        label: 'Interest Rate',
        value: assumptionsInput.rateBOR || 0,
        xs: 12,
        lg: 6,
        assumptions: true,
      },

      {
        type: 'dollar',
        name: 'downPaymentBOR',
        label: 'BOR Down Payment',
        value: assumptionsInput.downPaymentBOR || 0,
        xs: 12,
        lg: 6,
        assumptions: true,
      },
      {
        type: 'number',
        name: 'termBOR',
        label: 'Term',
        value: assumptionsInput.termBOR || 0,
        adornment: 'Years',
        adornmentToggle: '',
        adornmentPosition: 'end',
        xs: 12,
        lg: 6,
        assumptions: true,
      },
    ],
    'Build to Rent': [
      {
        type: 'dollar',
        label: 'After Repair Value',
        name: 'afterRepairValueB2R',
        value: analysisInput.afterRepairValueB2R,
        definition: 'afterRepairValue',
        definitionTarget: 'afterRepairValueB2R',
        xs: 12,
        lg: 6,
        hide: !includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        ),
      },
      {
        type: 'dollar',
        label: 'Resale Price',
        name: 'resellPriceB2R',
        value: analysisInput.resellPriceB2R,
        xs: 12,
        lg: 6,
      },
    ],
  }

  let propertyFields = {
    'Buy & Hold': [
      {
        type: 'dollar',
        label: 'Total Rental Income',
        name: `rent.${activeUnit}`,
        value: get(analysisInput, `rent.${activeUnit}`),
        // disabled: activeUnit === analysisInput.ownerOccupied,
        definition: 'rent',
        xs: 12,
        lg: 6,
      },
    ],
    'Fix & Flip': [
      {
        type: 'dollar',
        label: 'Total Rental Income',
        name: `rent.${activeUnit}`,
        value: get(analysisInput, `rent.${activeUnit}`),
        // disabled: activeUnit === analysisInput.ownerOccupiedFixFlip,
        definition: 'rent',
        xs: 12,
        lg: 6,
      },
    ],
    'Turnkey End Buyer': [
      {
        type: 'dollar',
        label: 'Total Rental Income',
        name: `rent.${activeUnit}`,
        value: get(analysisInput, `rent.${activeUnit}`),
        // disabled: activeUnit === analysisInput.ownerOccupiedTurnkey,
        definition: 'rent',
        xs: 12,
        lg: 6,
      },
    ],
    BRRRR: [
      {
        type: 'dollar',
        label: 'Total Rental Income',
        name: `rent.${activeUnit}`,
        value: get(analysisInput, `rent.${activeUnit}`),
        // disabled: activeUnit === analysisInput.ownerOccupiedBRRRR,
        definition: 'rent',
        xs: 12,
        lg: 6,
      },
    ],
    'Build to Rent': [
      {
        type: 'dollar',
        label: 'Total Rental Income',
        name: `rent.${activeUnit}`,
        value: get(analysisInput, `rent.${activeUnit}`),
        // disabled: activeUnit === analysisInput.ownerOccupiedB2R,
        definition: 'rent',
        xs: 12,
        lg: 6,
      },
    ],
    BOR: [
      {
        type: 'dollar',
        label: 'Total Rental Income',
        name: `rentBOR.${activeUnit}`,
        value: get(analysisInput, `rentBOR.${activeUnit}`),
        // disabled: activeUnit === analysisInput.ownerOccupiedBOR,
        definition: 'rentBOR',
        xs: 12,
        lg: 6,
      },
    ],
  }

  // if (get(analysisInput, `rent.length`) > 1) {
  //   propertyFields['Buy & Hold'].push({
  //     type: 'checkbox',
  //     label: 'Owner Occupied',
  //     name: 'ownerOccupied',
  //     value: !isNil(analysisInput.ownerOccupied) && analysisInput.ownerOccupied === activeUnit,
  //     xs: 12,
  //     lg: 6,
  //     tooltipField: 'This unit is occupied by the owner',
  //   })
  //   propertyFields['Fix & Flip'].push({
  //     type: 'checkbox',
  //     label: 'Owner Occupied',
  //     name: 'ownerOccupiedFixFlip',
  //     value: !isNil(analysisInput.ownerOccupiedFixFlip) && analysisInput.ownerOccupiedFixFlip === activeUnit,
  //     xs: 12,
  //     lg: 6,
  //     tooltipField: 'This unit is occupied by the owner',
  //   })
  //   propertyFields['Turnkey End Buyer'].push({
  //     type: 'checkbox',
  //     label: 'Owner Occupied',
  //     name: 'ownerOccupiedTurnkey',
  //     value: !isNil(analysisInput.ownerOccupiedTurnkey) && analysisInput.ownerOccupiedTurnkey === activeUnit,
  //     xs: 12,
  //     lg: 6,
  //     tooltipField: 'This unit is occupied by the owner',
  //   })
  //   propertyFields['BRRRR'].push({
  //     type: 'checkbox',
  //     label: 'Owner Occupied',
  //     name: 'ownerOccupiedBRRRR',
  //     value: !isNil(analysisInput.ownerOccupiedBRRRR) && analysisInput.ownerOccupiedBRRRR === activeUnit,
  //     xs: 12,
  //     lg: 6,
  //     tooltipField: 'This unit is occupied by the owner',
  //   })
  //   propertyFields['Build to Rent'].push({
  //     type: 'checkbox',
  //     label: 'Owner Occupied',
  //     name: 'ownerOccupiedB2R',
  //     value: !isNil(analysisInput.ownerOccupiedB2R) && analysisInput.ownerOccupiedB2R === activeUnit,
  //     xs: 12,
  //     lg: 6,
  //     tooltipField: 'This unit is occupied by the owner',
  //   })
  //   propertyFields['BOR'].push({
  //     type: 'checkbox',
  //     label: 'Owner Occupied',
  //     name: 'ownerOccupiedBOR',
  //     value: !isNil(analysisInput.ownerOccupiedBOR) && analysisInput.ownerOccupiedBOR === activeUnit,
  //     xs: 12,
  //     lg: 6,
  //     tooltipField: 'This unit is occupied by the owner',
  //   })
  // }

  const submitProperty = (skip = false) => {
    setLoader(true)
    updateProperty({
      variables: {
        id: propertyId,
        propertyInput: {
          propertyDashboard: true,
          analysisInput: skip ? null : analysisInput,
          assumptionsInput: skip ? null : assumptionsInput,
        },
      },
      refetchQueries: [
        {
          query: GET_ME,
        },
        {
          query: GET_PROPERTY,
          variables: {
            id: propertyId,
          },
        },
        {
          query: GET_ASSUMPTIONS,
          variables: {
            documentId: propertyId,
            documentType: 'property',
          },
        },
      ],
    }).then(() => {
      setLoader(false)
      // history.push('/property-details/' + propertyId + '/Summary')
      onClose()
    })
  }

  const [estimatesMenu] = useEstimatesMenu({
    property,
    determinedComps,
    activeUnit,
    assumptionsInput,
    onChangeAssumptions,
    anchorElEstimates,
    setAnchorElEstimates,
    handleDefinition,
    menu,
    setMenu,
    menuTarget,
    setMenuTarget,
    changeMenu,
    onChangeRange,
    estimatesCollapse,
    toggleCollapse,
    openNoData,
    setOpenNoData,
    affiliate,
    urlAffiliate,
  })

  return (
    <Dialog
      scroll="body"
      maxWidth="md"
      open={open}
      onClose={onClose}
      // classes={{ paper: 'modal-content rounded border-0' }}
      fullWidth={true}
      onKeyDown={stopPropagationForTab}
    >
      <BlockUi
        blocking={loader || propertyLoading}
        loader={
          <PacmanLoader color={'var(--primary)'} loading={true} />
        }
        message={
          <div className="text-primary">
            {propertyLoading ? 'Loading' : 'Analyzing'} Property
          </div>
        }
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Complete Property Data"
                action={
                  <IconButton
                    onClick={onClose}
                    disabled={loader}
                    size="large"
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent>
                {propertyLoading ? (
                  'Loading'
                ) : (
                  <div
                    className="accordion mb-spacing-6-x2"
                    style={{ width: '100%' }}
                  >
                    <Card
                      key={'Universal'}
                      className={clsx('card-box', {
                        'panel-open': accordion === 'Universal',
                      })}
                    >
                      <Card>
                        <div className="card-header">
                          <div className="panel-title">
                            <div className="accordion-toggle">
                              <Button
                                variant="text"
                                size="large"
                                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                onClick={() =>
                                  toggleAccordion('Universal')
                                }
                                aria-expanded={
                                  accordion === 'Universal'
                                }
                              >
                                <span>Universal</span>
                                <FontAwesomeIcon
                                  icon={['fas', 'angle-up']}
                                  className="font-size-xl accordion-icon"
                                />
                              </Button>
                            </div>
                          </div>
                        </div>
                        <Collapse in={accordion === 'Universal'}>
                          <div className="p-4">
                            <Grid
                              container
                              direction="row"
                              /*alignItems='center'*/ spacing={1}
                            >
                              {map(
                                filter(
                                  criticalFields['Universal'],
                                  (field) => field.hide !== true,
                                ),
                                (section) =>
                                  renderInputFields(section),
                              )}
                            </Grid>
                            <Divider />
                            {!isEmpty(
                              propertyFields['Universal'],
                            ) && (
                              <>
                                <List
                                  className="nav-line d-flex mt-3"
                                  style={{
                                    width:
                                      (analysisInput.rent
                                        ? analysisInput.rent.length
                                        : 1) * 100,
                                  }}
                                >
                                  {analysisInput.rent &&
                                  analysisInput.rent.length > 1 ? (
                                    map(
                                      analysisInput.rent,
                                      (value, index) => (
                                        <ListItem
                                          button
                                          disableRipple
                                          selected={
                                            activeUnit === index
                                          }
                                          onClick={() => {
                                            setActiveUnit(index)
                                          }}
                                        >
                                          Unit {index + 1}
                                        </ListItem>
                                      ),
                                    )
                                  ) : (
                                    <Typography>Property</Typography>
                                  )}
                                </List>
                                <Grid
                                  container
                                  direction="row"
                                  /*alignItems='center'*/ spacing={1}
                                >
                                  {map(
                                    propertyFields['Universal'],
                                    (section) =>
                                      renderInputFields(section),
                                  )}
                                </Grid>
                              </>
                            )}
                          </div>
                        </Collapse>
                      </Card>
                    </Card>
                    {map(strategies, (cardStrategy) => (
                      <Card
                        key={cardStrategy}
                        className={clsx('card-box', {
                          'panel-open': accordion === cardStrategy,
                        })}
                      >
                        <Card>
                          <div className="card-header">
                            <div className="panel-title">
                              <div className="accordion-toggle">
                                <Button
                                  variant="text"
                                  size="large"
                                  className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                  onClick={() =>
                                    toggleAccordion(cardStrategy)
                                  }
                                  aria-expanded={
                                    accordion === cardStrategy
                                  }
                                >
                                  <span>
                                    {getStrategyName(
                                      cardStrategy,
                                      props.session.me.activeOrg
                                        .member,
                                    )}
                                  </span>
                                  <FontAwesomeIcon
                                    icon={['fas', 'angle-up']}
                                    className="font-size-xl accordion-icon"
                                  />
                                </Button>
                              </div>
                            </div>
                          </div>
                          <Collapse in={accordion === cardStrategy}>
                            <div className="p-4">
                              <Grid
                                container
                                direction="row"
                                /*alignItems='center'*/ spacing={1}
                              >
                                {map(
                                  filter(
                                    criticalFields[cardStrategy],
                                    (field) => field.hide !== true,
                                  ),
                                  (section) =>
                                    renderInputFields(section),
                                )}
                              </Grid>
                              <Divider />
                              {!isEmpty(
                                propertyFields[cardStrategy],
                              ) && (
                                <>
                                  <List
                                    className="nav-line d-flex mt-3"
                                    style={{
                                      width:
                                        (analysisInput.rent
                                          ? analysisInput.rent.length
                                          : 1) * 100,
                                    }}
                                  >
                                    {analysisInput.rent &&
                                    analysisInput.rent.length > 1 ? (
                                      map(
                                        analysisInput.rent,
                                        (value, index) => (
                                          <ListItem
                                            button
                                            disableRipple
                                            selected={
                                              activeUnit === index
                                            }
                                            onClick={() => {
                                              setActiveUnit(index)
                                            }}
                                          >
                                            Unit {index + 1}
                                          </ListItem>
                                        ),
                                      )
                                    ) : (
                                      <Typography>
                                        Property
                                      </Typography>
                                    )}
                                  </List>
                                  <Grid
                                    container
                                    direction="row"
                                    /*alignItems='center'*/ spacing={
                                      1
                                    }
                                  >
                                    {map(
                                      propertyFields[cardStrategy],
                                      (section) =>
                                        renderInputFields(section),
                                    )}
                                  </Grid>
                                </>
                              )}
                            </div>
                          </Collapse>
                        </Card>
                      </Card>
                    ))}
                  </div>
                )}
                <Typography>
                  The above fields help us get accurate targets.
                  Housefolios also recommends double checking your
                  assumptions before adding properties.{' '}
                  <a
                    style={{
                      color: '#368CFF',
                      textDecoration: 'underline',
                    }}
                    href={
                      '/property-details/' + propertyId + '/Summary'
                    }
                    onClick={(event) => {
                      event.preventDefault()
                      submitProperty(true)
                    }}
                  >
                    Skip for now
                  </a>
                </Typography>
              </CardContent>
              <CardActions>
                <Typography style={{ flex: 1 }} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => submitProperty()}
                  disabled={
                    isEmpty(analysisInput) ||
                    isEmpty(assumptionsInput)
                  }
                >
                  <Typography>Continue</Typography>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        {estimatesMenu}
      </BlockUi>
    </Dialog>
  )
}

export default withSession(CriticalFieldsDialog)
