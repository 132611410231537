/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: TJ Felman
 * @ Modified time: 2022-03-21 13:22:52
 * @ Description: Dialog to delete a property
 */

import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  ListItem,
} from '@mui/material'

import map from 'lodash/map'

// import { useMutation } from '@apollo/client';
// import { DELETE_PORTFOLIO } from '../mutations';
// import { GET_PORTFOLIOS } from '@/housefolios-pages/Launchpad/queries';
// import { GET_ME } from '@/housefolios-components/Session/queries';
import { enqueueSnackbar } from 'notistack'
import { DELETE_PROPERTIES } from '../../Properties/mutation'
import { useMutation } from '@apollo/client'
import { GET_PROPERTIES, GET_TRASH } from '../../Properties/queries'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'

function DeletePropertyComponent(props) {
  const { button, rightClickMenu } = props
  const [openDeleteProperty, setDeleteProperty] = useState(false)
  const [loader, setLoader] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const handleOpenDeleteProperty = () => {
    setDeleteProperty(true)
    setConfirm(false)
  }
  const handleCloseDeleteProperty = () => {
    setDeleteProperty(false)
    setLoader(false)
  }

  const [deleteProperties] = useMutation(DELETE_PROPERTIES)

  const handleDelete = () => {
    if (props.gotToPortfolio)
      localStorage.setItem('portfolio', props.portfolioId)
    setLoader(true)
    deleteProperties({
      awaitRefetchQueries: true,
      variables: { ids: props.propertyId },
      refetchQueries: [
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [props.portfolioId],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: map(
              props.portfolios,
              (portfolio) => portfolio._id,
            ),
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PORTFOLIOS,
        },
        {
          query: GET_ME,
        },
        {
          query: GET_TRASH,
        },
      ],
    })
      .then(() => {
        enqueueSnackbar(`Selected Properties Deleted`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        handleCloseDeleteProperty()
        if (props.setSelected) props.setSelected([])
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to Delete Selected Properties`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseDeleteProperty()
      })
  }

  return (
    <>
      {button && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleOpenDeleteProperty}
        >
          Delete
        </Button>
      )}
      {rightClickMenu && (
        <ListItem
          component="a"
          button
          //href={null}
          onClick={handleOpenDeleteProperty}
          className="rounded-sm"
        >
          <DeleteOutlineIcon
            style={{ color: 'red', marginRight: '10px' }}
          />
          <span style={{ color: 'red' }}>Delete Property</span>
        </ListItem>
      )}
      <Dialog
        open={openDeleteProperty}
        onClose={handleCloseDeleteProperty}
        classes={{ paper: 'shadow-lg rounded' }}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">Deleting Property Data</div>
          }
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                <FontAwesomeIcon
                  icon={['far', 'trash-can']}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              Are you sure you want to delete this?
            </h4>
            <p className="mb-0 font-size-lg text-muted">
              <FormControl component="fieldset" className="pr-4">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirm}
                        onChange={() => setConfirm(!confirm)}
                      />
                    }
                    label="All data will be deleted. This action cannot be undone."
                  />
                </FormGroup>
              </FormControl>
            </p>
            {/* <p className="mb-0 font-size-lg text-muted">
            All data will be deleted. This action cannot be undone.
          </p> */}
            <div className="pt-4">
              <Button
                variant="text"
                onClick={handleCloseDeleteProperty}
                //className="btn-neutral-secondary btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: '#f83145', color: 'white' }}
                onClick={handleDelete}
                disabled={!confirm}
                //className="btn-danger btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Delete</span>
              </Button>
            </div>
          </div>
        </BlockUi>
      </Dialog>
    </>
  )
}

export default DeletePropertyComponent
