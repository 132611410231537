/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-16 11:36:56
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-27 11:21:04
 * @ Description: Dialog to add a user to the team
 */

import React, { useState } from 'react'

import { useDropzone } from 'react-dropzone'
import map from 'lodash/map'
import compact from 'lodash/compact'

import clsx from 'clsx'

import {
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone'
import CheckIcon from '@mui/icons-material/Check'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import withSession from '@/housefolios-components/Session/withSession'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { ADD_TEAM_MEMBER } from '../mutations'
import { GET_TEAM } from '../queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { useMutation } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { IMAGE_UPLOAD } from '@/housefolios-components/TitleButtons/AddPropertyButton/mutations'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { Add } from '@mui/icons-material'
import FileUpload from '@/housefolios-components/PropertyContainer/PropertyDashboard/FileUpload'
import { NumericFormat } from 'react-number-format'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { STATES } from '@/constants/states'
import Autocomplete from '@mui/material/Autocomplete'
import { CHECK_SIGN_UP } from '@/housefolios-components/CreateAccount/CreateAccountCard/mutations'

function AddUserComponent(props) {
  const { teamTable, menu, currentValue } = props
  const [openFindUser, setFindUser] = useState(false)
  const handleOpenFindUser = () => {
    setFindUser(true)
  }
  const handleCloseFindUser = () => {
    setFindUser(false)
    setLoader(false)
  }

  const [openExistingUser, setExistingUser] = useState(false)
  const handleOpenExistingUser = () => {
    setExistingUser(true)
  }
  const handleCloseExistingUser = () => {
    setExistingUser(false)
    setLoader(false)
  }

  const [openAddUser, setAddUser] = useState(false)
  const handleOpenAddUser = () => {
    setAddUser(true)
  }
  const handleCloseAddUser = () => {
    setAddUser(false)
    setLoader(false)
  }

  const [checkSignup] = useMutation(CHECK_SIGN_UP)
  const [addTeamMember] = useMutation(ADD_TEAM_MEMBER, {
    refetchQueries: [
      {
        query: GET_TEAM,
      },
      {
        query: GET_ME,
      },
    ],
  })
  const [imageUpload] = useMutation(IMAGE_UPLOAD, {
    refetchQueries: [
      {
        query: GET_TEAM,
      },
      {
        query: GET_ME,
      },
    ],
  })

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [phone, setPhone] = useState('')
  const [userType, setUserType] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [bioDescription, setBioDescription] = useState('')

  const [accordian, setAccordian] = useState(false)

  const [DoB, setDoB] = useState(null)
  const [citizenship, setCitizenship] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [FICO, setFICO] = useState('')
  const [firstTimeInvestor, setFirstTimeInvestor] = useState('')
  const [yearsInvesting, setYearsInvesting] = useState('')
  const [realEstateInvestments, setRealEstateInvestments] =
    useState('')
  const [estimatedNetWorth, setEstimatedNetWorth] = useState('')
  const [estimatedLiquidAssets, setEstimatedLiquidAssets] =
    useState('')

  const [creditReport, setCreditReport] = useState([])
  const [scheduleOfRealEstate, setScheduleOfRealEstate] = useState([])
  const [personalFinancialStatement, setPersonalFinancialStatement] =
    useState([])

  const toggleAccordion = () => {
    setAccordian(!accordian)
  }

  const uploadFile = (setter) => (files) => {
    let newFiles = []
    map(files, (file) => {
      newFiles.push(file)
    })
    setter(newFiles)
  }

  const deleteFile = (setter) => () => {
    let newFiles = []
    setter(newFiles)
  }

  const [loader, setLoader] = useState(false)

  const resetState = () => {
    setFirstname('')
    setLastname('')
    setEmail('')
    setRole('')
    setPhone('')
    setUserType('')
    setJobTitle('')
    setBioDescription('')
    setFiles([])
    setDoB(null)
    setCitizenship('')
    setAddress('')
    setAddress2('')
    setCity('')
    setState('')
    setZip('')
    setFICO('')
    setFirstTimeInvestor('')
    setYearsInvesting('')
    setRealEstateInvestments('')
    setEstimatedNetWorth('')
    setEstimatedLiquidAssets('')
    setCreditReport([])
    setScheduleOfRealEstate([])
    setPersonalFinancialStatement([])
    handleCloseFindUser()
    handleCloseExistingUser()
    handleCloseAddUser()
  }

  const findFields = [
    {
      label: 'Email Address',
      placeholder: 'Email Address',
      value: email,
      set: setEmail,
      validators: ['required', 'maxStringLength: 50', 'isEmail'],
      errorMessages: [
        'This field is required',
        'You cannot exceed 50 characters',
        'Not a valid email address',
      ],
      xs: '12',
      md: '12',
    },
  ]

  const existingFields = [
    {
      label: 'Email Address',
      placeholder: 'Email Address',
      value: email,
      set: setEmail,
      validators: ['required', 'maxStringLength: 50', 'isEmail'],
      errorMessages: [
        'This field is required',
        'You cannot exceed 50 characters',
        'Not a valid email address',
      ],
      disabled: true,
      xs: '12',
      md: '12',
    },
    {
      dropdown: true,
      label: 'Role',
      placeholder: 'Role',
      value: role,
      set: setRole,
      options: [
        { value: 'admin', label: 'Admin' },
        { value: 'team', label: 'Team Member' },
      ],
      xs: '12',
      md: '6',
    },
  ]

  const inputFields = [
    {
      label: 'First Name',
      placeholder: 'First Name',
      value: firstname,
      set: setFirstname,
      autoFocus: true,
      validators: [
        'required',
        'maxStringLength: 20',
        'matchRegexp:^[A-z]+$',
      ],
      errorMessages: [
        'This field is required',
        'You cannot exceed 20 characters',
        'This field only allows characters in the alphabet',
      ],
      xs: '12',
      md: '6',
    },
    {
      label: 'Last Name',
      placeholder: 'Last Name',
      value: lastname,
      set: setLastname,
      validators: [
        'required',
        'maxStringLength: 20',
        'matchRegexp:^[A-z]+$',
      ],
      errorMessages: [
        'This field is required',
        'You cannot exceed 20 characters',
        'This field only allows characters in the alphabet',
      ],
      xs: '12',
      md: '6',
    },
    {
      label: 'Email Address',
      placeholder: 'Email Address',
      value: email,
      set: setEmail,
      validators: ['required', 'maxStringLength: 50', 'isEmail'],
      errorMessages: [
        'This field is required',
        'You cannot exceed 50 characters',
        'Not a valid email address',
      ],
      disabled: true,
      xs: '12',
      md: '12',
    },
    {
      label: 'Phone',
      placeholder: 'Phone',
      value: phone,
      set: setPhone,
      validators: ['matchRegexp:^[0-9()-]+$'],
      errorMessages: ['Not a valid phone number'],
      xs: '12',
      md: '6',
    },
    {
      dropdown: true,
      label: 'User Type',
      placeholder: 'User Type',
      value: userType,
      set: setUserType,
      options: [
        { value: 'agent', label: 'Agent' },
        { value: 'broker', label: 'Broker' },
        { value: 'investor', label: 'Investor' },
        { value: 'other', label: 'Other' },
      ],
      xs: '12',
      md: '6',
    },
    {
      dropdown: true,
      label: 'Role',
      placeholder: 'Role',
      value: role,
      set: setRole,
      options: [
        { value: 'admin', label: 'Admin' },
        { value: 'team', label: 'Team Member' },
      ],
      xs: '12',
      md: '6',
    },
    {
      label: 'Job Title',
      placeholder: 'Job Title',
      value: jobTitle,
      set: setJobTitle,
      validators: ['required', 'maxStringLength: 50'],
      errorMessages: [
        'This field is required',
        'You cannot exceed 50 characters',
      ],
      xs: '12',
      md: '6',
    },
    {
      label: 'User Bio',
      placeholder: 'User Bio',
      value: bioDescription,
      set: setBioDescription,
      validators: ['maxStringLength: 3000'],
      errorMessages: ['You cannot exceed 3000 characters'],
      multiline: true,
      rows: '8',
      xs: '12',
      md: '12',
    },
  ]

  const [files, setFiles] = useState([])
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    getRootProps,
    getInputProps,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
      map(acceptedFiles, (file) => {
        const reader = new FileReader()
        reader.onload = async () => {
          const userImage = reader.result
          setFiles([{ url: userImage }])
        }
        reader.readAsDataURL(file)
      })
    },
  })

  const thumbs = map(compact(files), (file) => (
    <div
      key={file.name}
      className="rounded avatar-image overflow-hidden d-140 bg-neutral-dark text-center font-weight-bold text-success d-flex justify-content-center align-items-center"
    >
      <div
        className="blur-load"
        style={{ backgroundImage: 'url(' + file.url_small + ')' }}
      >
        <img
          className="img-fluid img-fit-container rounded-sm"
          src={file.url || file.preview}
          alt="..."
          loading="lazy"
        />
      </div>
    </div>
  ))

  const { limits = {} } = props.session.me
  const { teamLimit } = limits

  const onClickFind = (event) => {
    event.preventDefault()

    checkSignup({
      variables: {
        userInput: {
          email,
        },
      },
    })
      .then(async ({ data }) => {
        handleOpenAddUser()
      })
      .catch((error) => {
        handleOpenExistingUser()
      })
      .finally(() => {
        handleCloseFindUser()
      })
  }

  const onClickExisting = (event) => {
    event.preventDefault()

    const orgUserFirstname = props.session.me.profile.firstname
    const orgUserLastname = props.session.me.profile.lastname
    const orgUserEmail = props.session.me.email
    setLoader(true)
    addTeamMember({
      variables: {
        email: email,
        role: role,
        orgUserInput: {
          orgUserFirstname: orgUserFirstname,
          orgUserLastname: orgUserLastname,
          orgUserEmail: orgUserEmail,
        },
      },
    })
      .then(async ({ data }) => {
        enqueueSnackbar('Invite Sent', {
          variant: 'success',
          autohideDuratation: 3000,
        })
        resetState()
      })
      .catch(async (error) => {
        console.log(error)
        setLoader(false)
        enqueueSnackbar(error.message, {
          variant: 'error',
          autohideDuratation: 3000,
        })
      })
  }

  const onClickAdd = (event) => {
    event.preventDefault()

    const orgUserFirstname = props.session.me.profile.firstname
    const orgUserLastname = props.session.me.profile.lastname
    const orgUserEmail = props.session.me.email
    setLoader(true)
    addTeamMember({
      variables: {
        profileInput: {
          firstname: firstname,
          lastname: lastname,
        },
        email: email,
        role: role,
        phone: phone,
        userType,
        jobTitle: jobTitle,
        bioDescription: bioDescription,
        address,
        address2,
        city,
        state,
        zip,
        DoB,
        citizenship,
        FICO,
        firstTimeInvestor,
        yearsInvesting,
        realEstateInvestments,
        estimatedNetWorth,
        estimatedLiquidAssets,
        creditReport,
        scheduleOfRealEstate,
        personalFinancialStatement,
        orgUserInput: {
          orgUserFirstname: orgUserFirstname,
          orgUserLastname: orgUserLastname,
          orgUserEmail: orgUserEmail,
        },
      },
    })
      .then(async ({ data }) => {
        if (files[0])
          imageUpload({
            variables: {
              documentId: data.addTeamMember._id,
              imageUrls: [files[0].url],
              collection: 'teamMember',
            },
          }).then(async ({ data }) => {
            enqueueSnackbar('Image Saved', {
              variant: 'success',
              autohideDuratation: 3000,
            })
          })
        enqueueSnackbar('Invite Sent', {
          variant: 'success',
          autohideDuratation: 3000,
        })
        resetState()
      })
      .catch(async (error) => {
        console.log(error)
        setLoader(false)
        enqueueSnackbar(error.message, {
          variant: 'error',
          autohideDuratation: 3000,
        })
      })
  }

  return (
    <Query query={GET_TEAM}>
      {({ loading, error, data }) => {
        if (loading) return 'Loading...'
        if (error) return `Error! ${error.message}`
        const count = data.teamMembers.length
        return (
          <>
            {teamTable && (
              <Button
                variant="contained"
                className="btn-primary m-2"
                onClick={
                  count >= teamLimit
                    ? () => props.toggleLimit('team')
                    : handleOpenFindUser
                }
              >
                <Add style={{ color: 'white' }} />
                <span
                  className="btn-wrapper--label"
                  style={{ fontWeight: 600 }}
                >
                  Add User
                </span>
              </Button>
            )}
            {menu && (
              <MenuItem
                style={{
                  textAlign: 'center',
                  color: '#04a3e3',
                  marginLeft: 8,
                }}
                key="addApplicant"
                value={currentValue}
                onClick={
                  count >= teamLimit
                    ? () => props.toggleLimit('team')
                    : handleOpenFindUser
                }
                button
              >
                <Add style={{ marginRight: 10 }} />
                Add Applicant
              </MenuItem>
            )}
            <Dialog
              open={openFindUser}
              onClose={handleCloseFindUser}
              classes={{ paper: 'shadow-lg rounded' }}
              onKeyDown={(event) => event.stopPropagation()}
              maxWidth="sm"
              fullWidth={true}
            >
              <DialogTitle>Add a Team Member</DialogTitle>
              <ValidatorForm
                style={{
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                onSubmit={onClickFind}
              >
                <DialogContent>
                  <BlockUi
                    blocking={loader}
                    loader={
                      <PacmanLoader
                        color={'var(--primary)'}
                        loading={true}
                      />
                    }
                    message={
                      <div className="text-primary">
                        Adding Team Member
                      </div>
                    }
                  >
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                    >
                      <Grid item>
                        <Grid container spacing={2}>
                          {map(findFields, (section) => (
                            <Grid
                              item
                              xs={section.xs}
                              md={section.md}
                            >
                              {section.dropdown ? (
                                <FormControl
                                  variant="outlined"
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel required={true}>
                                    {section.label}
                                  </InputLabel>
                                  <Select
                                    value={section.value}
                                    name={section.name}
                                    onChange={(event) =>
                                      section.set(event.target.value)
                                    }
                                    displayEmpty
                                    required={true}
                                    label={section.label + '*'}
                                    disabled={section.disabled}
                                  >
                                    {map(
                                      section.options,
                                      (option) => (
                                        <MenuItem
                                          value={option.value}
                                          key={option.value}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      ),
                                    )}
                                  </Select>
                                </FormControl>
                              ) : (
                                <TextValidator
                                  fullWidth
                                  label={section.label}
                                  multiline={section.multiline}
                                  autoFocus={section.autoFocus}
                                  value={section.value}
                                  onChange={(event) =>
                                    section.set(event.target.value)
                                  }
                                  placeholder={section.placeholder}
                                  rows={section.rows}
                                  required={true}
                                  validators={section.validators}
                                  errorMessages={
                                    section.errorMessages
                                  }
                                  disabled={section.disabled}
                                  variant="outlined"
                                />
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </BlockUi>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="text"
                    onClick={handleCloseFindUser}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="btn-primary m-2"
                    type="submit"
                  >
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon
                        icon={['fas', 'paper-plane']}
                        className="font-size-lg"
                      />
                    </span>
                    <span className="btn-wrapper--label">Search</span>
                  </Button>
                </DialogActions>
              </ValidatorForm>
            </Dialog>
            <Dialog
              open={openExistingUser}
              onClose={handleCloseExistingUser}
              classes={{ paper: 'shadow-lg rounded' }}
              onKeyDown={(event) => event.stopPropagation()}
              maxWidth="sm"
              fullWidth={true}
            >
              <DialogTitle>Add a Team Member</DialogTitle>
              <ValidatorForm
                style={{
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                onSubmit={onClickExisting}
              >
                <DialogContent>
                  <BlockUi
                    blocking={loader}
                    loader={
                      <PacmanLoader
                        color={'var(--primary)'}
                        loading={true}
                      />
                    }
                    message={
                      <div className="text-primary">
                        Adding Team Member
                      </div>
                    }
                  >
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                    >
                      <Grid item>
                        <Grid container spacing={2}>
                          {map(existingFields, (section) => (
                            <Grid
                              item
                              xs={section.xs}
                              md={section.md}
                            >
                              {section.dropdown ? (
                                <FormControl
                                  variant="outlined"
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel required={true}>
                                    {section.label}
                                  </InputLabel>
                                  <Select
                                    value={section.value}
                                    name={section.name}
                                    onChange={(event) =>
                                      section.set(event.target.value)
                                    }
                                    displayEmpty
                                    required={true}
                                    label={section.label + '*'}
                                    disabled={section.disabled}
                                  >
                                    {map(
                                      section.options,
                                      (option) => (
                                        <MenuItem
                                          value={option.value}
                                          key={option.value}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      ),
                                    )}
                                  </Select>
                                </FormControl>
                              ) : (
                                <TextValidator
                                  fullWidth
                                  label={section.label}
                                  multiline={section.multiline}
                                  autoFocus={section.autoFocus}
                                  value={section.value}
                                  onChange={(event) =>
                                    section.set(event.target.value)
                                  }
                                  placeholder={section.placeholder}
                                  rows={section.rows}
                                  required={true}
                                  validators={section.validators}
                                  errorMessages={
                                    section.errorMessages
                                  }
                                  disabled={section.disabled}
                                  variant="outlined"
                                />
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </BlockUi>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="text"
                    onClick={handleCloseExistingUser}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="btn-primary m-2"
                    type="submit"
                  >
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon
                        icon={['fas', 'paper-plane']}
                        className="font-size-lg"
                      />
                    </span>
                    <span className="btn-wrapper--label">
                      Send Invite
                    </span>
                  </Button>
                </DialogActions>
              </ValidatorForm>
            </Dialog>
            <Dialog
              open={openAddUser}
              onClose={handleCloseAddUser}
              classes={{ paper: 'shadow-lg rounded' }}
              onKeyDown={(event) => event.stopPropagation()}
              maxWidth="sm"
              fullWidth={true}
            >
              <DialogTitle>Add a Team Member</DialogTitle>
              <ValidatorForm
                style={{
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                onSubmit={onClickAdd}
              >
                <DialogContent>
                  <BlockUi
                    blocking={loader}
                    loader={
                      <PacmanLoader
                        color={'var(--primary)'}
                        loading={true}
                      />
                    }
                    message={
                      <div className="text-primary">
                        Adding Team Member
                      </div>
                    }
                  >
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                    >
                      <Grid item>
                        <div className="py-5 d-flex align-items-center justify-content-center">
                          <div className="dropzone rounded">
                            <div
                              {...getRootProps({
                                className: 'dropzone-upload-wrapper',
                              })}
                            >
                              <input {...getInputProps()} />
                              <div className="dropzone-inner-wrapper d-140 rounded dropzone-avatar">
                                <div className="avatar-icon-wrapper d-140 rounded m-2">
                                  <Button
                                    onClick={open}
                                    className="avatar-button badge shadow-sm btn-icon badge-position badge-position--bottom-right border-0 text-indent-0 d-40 badge-circle btn-first text-white"
                                  >
                                    <CameraAltTwoToneIcon className="d-20" />
                                  </Button>

                                  <div>
                                    {isDragAccept && (
                                      <div className="rounded overflow-hidden d-140 bg-success text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                        <CheckIcon className="d-40" />
                                      </div>
                                    )}
                                    {isDragReject && (
                                      <div className="rounded overflow-hidden d-140 bg-danger text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                        <CloseTwoToneIcon className="d-60" />
                                      </div>
                                    )}
                                    {!isDragActive && (
                                      <div className="rounded overflow-hidden d-140 bg-neutral-dark text-center font-weight-bold text-black-50 d-flex justify-content-center align-items-center">
                                        <AccountCircleTwoToneIcon className="d-50" />
                                      </div>
                                    )}
                                  </div>

                                  {thumbs.length > 0 && (
                                    <div>{thumbs}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={2}>
                          {map(inputFields, (section) => (
                            <Grid
                              item
                              xs={section.xs}
                              md={section.md}
                            >
                              {section.dropdown ? (
                                <FormControl
                                  variant="outlined"
                                  style={{ width: '100%' }}
                                >
                                  <InputLabel required={true}>
                                    {section.label}
                                  </InputLabel>
                                  <Select
                                    value={section.value}
                                    name={section.name}
                                    onChange={(event) =>
                                      section.set(event.target.value)
                                    }
                                    displayEmpty
                                    required={true}
                                    label={section.label + '*'}
                                    disabled={section.disabled}
                                  >
                                    {map(
                                      section.options,
                                      (option) => (
                                        <MenuItem
                                          value={option.value}
                                          key={option.value}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      ),
                                    )}
                                  </Select>
                                </FormControl>
                              ) : (
                                <TextValidator
                                  fullWidth
                                  label={section.label}
                                  multiline={section.multiline}
                                  autoFocus={section.autoFocus}
                                  value={section.value}
                                  onChange={(event) =>
                                    section.set(event.target.value)
                                  }
                                  placeholder={section.placeholder}
                                  rows={section.rows}
                                  required={true}
                                  validators={section.validators}
                                  errorMessages={
                                    section.errorMessages
                                  }
                                  disabled={section.disabled}
                                  variant="outlined"
                                />
                              )}
                            </Grid>
                          ))}
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              select
                              variant="outlined"
                              margin="dense"
                              label="First Time Investor?"
                              value={firstTimeInvestor}
                              onChange={(event) =>
                                setFirstTimeInvestor(
                                  event.target.value,
                                )
                              }
                            >
                              <MenuItem key={'Yes'} value={'Yes'}>
                                {'Yes'}
                              </MenuItem>
                              <MenuItem key={'No'} value={'No'}>
                                {'No'}
                              </MenuItem>
                            </TextField>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              label="Years of Experience investing"
                              fullWidth={true}
                              value={yearsInvesting}
                              onChange={(event) =>
                                setYearsInvesting(event.target.value)
                              }
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              label="# of investments the last year"
                              fullWidth={true}
                              value={realEstateInvestments}
                              onChange={(event) =>
                                setRealEstateInvestments(
                                  event.target.value,
                                )
                              }
                              type="number"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <div className="accordion mb-spacing-6-x2" style={{ width: '100%', marginTop: 10 }}>
                      <Card key={'lender'} className={clsx("card-box", { 'panel-open': accordian })}>
                        <Card>
                          <div className="card-header">
                            <div className="panel-title">
                              <div className="accordion-toggle">
                                <Button variant="text" size="large" className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                  onClick={() => toggleAccordion()}
                                  aria-expanded={accordian}>
                                  <span>Lender Applicant Data</span>
                                  <FontAwesomeIcon icon={['fas', 'angle-up']} className="font-size-xl accordion-icon" />
                                </Button>
                              </div>
                            </div>
                          </div>
                          <Collapse in={accordian}>
                            <CardContent style={{ marginTop: -10, marginBottom: -10, marginLeft: 10, marginRight: 10}}>
                              <Grid container className="list-group-flush" spacing={1} >
                                <Grid item xs={6}>
                                    <DatePicker
                                        name="DoB"
                                        label="Date of Birth"
                                        value={DoB}
                                        onChange={newValue => {
                                          setDoB(newValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" margin='dense' fullWidth />}
                                        views={['year', 'month', 'day']}
                                        openTo="year"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    select
                                    variant='outlined'
                                    margin='dense'
                                    label='Citizenship status'
                                    value={citizenship}
                                    onChange={(event) => setCitizenship(event.target.value)}
                                  >
                                    <MenuItem key={'Is Citizen'} value={'Is Citizen'}>
                                      {'Is Citizen'}
                                    </MenuItem>
                                    <MenuItem key={'Is Not Citizen'} value={'Is Not Citizen'}>
                                      {'Is Not Citizen'}
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    variant='outlined'
                                    margin='dense'
                                    label='Street Address'
                                    value={address}
                                    onChange={(event) => setAddress(event.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    variant='outlined'
                                    margin='dense'
                                    label='Street Address Line 2'
                                    value={address2}
                                    onChange={(event) => setAddress2(event.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    variant='outlined'
                                    margin='dense'
                                    label='City'
                                    value={city}
                                    onChange={(event) => setCity(event.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Autocomplete
                                    variant='outlined'
                                    margin='dense'
                                    label="State"
                                    options={statesShort()}
                                    fullWidth={true}
                                    value={state}
                                    onChange={(event, newValue) => {
                                      setState(newValue)
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        label="State"
                                        margin='dense'
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    variant='outlined'
                                    margin='dense'
                                    label='Postal / Zip code'
                                    value={zip}
                                    onChange={(event) => setZip(event.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                <NumericFormat
                                  customInput={TextField}
                                  fullWidth={true}
                                  isNumericString={true}
                                  decimalScale={0}
                                  name="FICO"
                                  value={Math.round(FICO) || ''}
                                  onValueChange={values => {
                                    const { value } = values
                                    setFICO(value)
                                  }}
                                  type={'text'}
                                  onFocus={event => {
                                    event.target.select()
                                  }}
                                  variant='outlined'
                                  margin="dense"
                                  label="FICO Score"
                                />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    select
                                    variant='outlined'
                                    margin='dense'
                                    label='First Time Investor?'
                                    value={firstTimeInvestor}
                                    onChange={(event) => setFirstTimeInvestor(event.target.value)}
                                  >
                                    <MenuItem key={'Yes'} value={'Yes'}>
                                      {'Yes'}
                                    </MenuItem>
                                    <MenuItem key={'No'} value={'No'}>
                                      {'No'}
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    variant='outlined'
                                    margin='dense'
                                    label="Years of Experience in real estate investing"
                                    fullWidth={true}
                                    value={yearsInvesting}
                                    onChange={event => setYearsInvesting(event.target.value)}
                                    type="number"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    variant='outlined'
                                    margin='dense'
                                    label="# of real estate investments in the last 12 months"
                                    fullWidth={true}
                                    value={realEstateInvestments}
                                    onChange={event => setRealEstateInvestments(event.target.value)}
                                    type="number"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <NumericFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    fullWidth={true}
                                    isNumericString={true}
                                    decimalScale={0}
                                    name="Estimated Net Worth"
                                    value={Math.round(estimatedNetWorth) || ''}
                                    onValueChange={values => {
                                      const { value } = values
                                      setEstimatedNetWorth(value)
                                    }}
                                    type={'text'}
                                    onFocus={event => {
                                      event.target.select()
                                    }}
                                    variant='outlined'
                                    margin="dense"
                                    label="Estimated Net Worth"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <NumericFormat
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    fullWidth={true}
                                    isNumericString={true}
                                    decimalScale={0}
                                    name="Estimated Liquid Assets"
                                    value={Math.round(estimatedLiquidAssets) || ''}
                                    onValueChange={values => {
                                      const { value } = values
                                      setEstimatedLiquidAssets(value)
                                    }}
                                    type={'text'}
                                    onFocus={event => {
                                      event.target.select()
                                    }}
                                    variant='outlined'
                                    margin="dense"
                                    label="Estimated Liquid Assets"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>Submit Credit Report</Typography>
                                  <FileUpload
                                    documentId={props.session.me.activeOrg._id}
                                    files={creditReport}
                                    uploadFile={uploadFile(setCreditReport)}
                                    deleteFile={deleteFile(setCreditReport)}
                                    collection="Lending"
                                    button
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>Schedule of Real Estate Owned</Typography>
                                  <FileUpload
                                    documentId={props.session.me.activeOrg._id}
                                    files={scheduleOfRealEstate}
                                    uploadFile={uploadFile(setScheduleOfRealEstate)}
                                    deleteFile={deleteFile(setScheduleOfRealEstate)}
                                    collection="Lending"
                                    button
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>Personal Financial Statement</Typography>
                                  <FileUpload
                                    documentId={props.session.me.activeOrg._id}
                                    files={personalFinancialStatement}
                                    uploadFile={uploadFile(setPersonalFinancialStatement)}
                                    deleteFile={deleteFile(setPersonalFinancialStatement)}
                                    collection="Lending"
                                    button
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Collapse>
                        </Card>
                      </Card>
                  </div> */}
                  </BlockUi>
                </DialogContent>
                <DialogActions>
                  <Button variant="text" onClick={handleCloseAddUser}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="btn-primary m-2"
                    type="submit"
                  >
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon
                        icon={['fas', 'paper-plane']}
                        className="font-size-lg"
                      />
                    </span>
                    <span className="btn-wrapper--label">
                      Send Invite
                    </span>
                  </Button>
                </DialogActions>
              </ValidatorForm>
            </Dialog>
          </>
        )
      }}
    </Query>
  )
}

export default withSession(AddUserComponent)
