import Reactour from 'reactour'
import { Button, Typography, Grid } from '@mui/material'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

export const TakeActionTour =
  ({
    isOpenTakeActionTour,
    closeTakeActionTour,
  }) => {
    const [lockTakeActionTour, setLockTakeActionTour] = useState(true)
    const { pathname } = useLocation()
    let goToTo
    let shifted = false
    //const lockTour = true
    const steps = [
      {
        selector: '[data-tour="takeAction-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                If you accurately completed all the steps above, you
                are ready to take action! Create a report for your
                lender or buyer, list the property on the marketplace,
                or make an offer!
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 1000)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="takeAction-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ textAlign: 'center' }}
                >
                  Here you can create or share reports and list your
                  property on the marketplace.
                </Typography>
              </Grid>
            </Grid>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              //goToTo(2)
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenTakeActionTour}
        onRequestClose={closeTakeActionTour}
        update={pathname}
        startAt={0}
        getCurrentStep={(curr) => setLockTakeActionTour(curr === 0)}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTakeActionTour}
        disableDotsNavigation={lockTakeActionTour}
        showButtons={!lockTakeActionTour}
      />
    )
  }