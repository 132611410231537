import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import includes from 'lodash/includes'

import { connect } from 'react-redux'

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions'
import { Grid, List, ListItem, Typography } from '@mui/material'
import PublicFooter from '@/layout-components/PublicFooter'
import PublicHeader from '@/layout-components/PublicHeader'
import { Outlet } from 'react-router-dom'

const LeftSidebar = (props) => {
  const urlParams = new URLSearchParams(window.location.search)
  const subdomain = urlParams.get('subdomain') || null
  const subdomainParam = subdomain ? '?subdomain=' + subdomain : ''

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile)
  }

  const {
    sidebarShadow,
    sidebarToggle,
    sidebarToggleMobile,
    sidebarFixed,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    notificationsDrawerToggle,
    footerFixed,
    contentBackground,
    setOpenFeedbackTour,
  } = props
  return (
    <>
      <div
        className={clsx(contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'notification-drawer-open': notificationsDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover,
        })}
      >
        {/* <div>
          <div
            className={
              clsx(
                "app-sidebar bg-white",
                { 'app-sidebar--shadow': sidebarShadow }
              )
            }
            style={{ overflowY: 'scroll', borderRight: "1px solid rgba(122, 123, 151, 0.3)" }}
          >
            <div className="px-4">
              <List component="div" className="nav-pills nav-neutral-primary flex-column">
                <ListItem key="Navigation" className="d-flex px-0 pt-1 pb-1 justify-content-between">
                  <Grid container direction='row' alignItems='center' justifyContent='space-between' wrap='nowrap'>
                    <Grid item>
                      <Typography className="text-uppercase font-size-sm text-primary font-weight-bold">Navigation</Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem key="Home" component="a" button href={"/" + subdomainParam}>
                  Home
                </ListItem>
                <ListItem key="Properties" component="a" button href={"/properties" + subdomainParam}>
                  Properties
                </ListItem>
                <ListItem key="Sold Properties" component="a" button href={"/soldproperties" + subdomainParam}>
                  Sold Properties
                </ListItem>
                {props.marketplace && (
                  <ListItem key="Team" component="a" button href={"/team" + subdomainParam}>
                    Team
                  </ListItem>
                )}
                {props.marketplace && (
                  <ListItem key="Blog" component="a" button href={null}>
                    Blog
                  </ListItem>
                )}
                <ListItem key="Contact Us" component="a" button href={null} onClick={null}>
                  Contact Us
                </ListItem>
              </List>
            </div>
          </div>
          <div
            onClick={toggleSidebarMobile}
            className={clsx('app-sidebar-overlay', {
              'is-active': sidebarToggleMobile
            })}
          />
        </div> */}
        {/* <div className="app-main"> */}
        <PublicHeader
          subdomainParam={subdomainParam}
          marketplace={props.marketplace}
          orgName={props.orgName}
          header={props.header}
        />
        <div className="app-content" style={{ paddingLeft: 0 }}>
          <div className="app-content--inner" style={{ padding: 0 }}>
            <div className="app-content--inner__wrapper">
              <Outlet />
            </div>
          </div>
          {/* <PublicFooter /> */}
        </div>
      </div>
      {/* </div> */}
    </>
  )
}

LeftSidebar.propTypes = {
  children: PropTypes.node,
}

const mapStateToProps = (state) => ({
  sidebarShadow: state.DeprecatedThemeOptions.sidebarShadow,
  sidebarToggle: state.DeprecatedThemeOptions.sidebarToggle,
  sidebarToggleMobile:
    state.DeprecatedThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.DeprecatedThemeOptions.sidebarFixed,
  headerFixed: state.DeprecatedThemeOptions.headerFixed,
  headerSearchHover: state.DeprecatedThemeOptions.headerSearchHover,
  headerDrawerToggle: state.DeprecatedThemeOptions.headerDrawerToggle,
  notificationsDrawerToggle:
    state.DeprecatedThemeOptions.notificationsDrawerToggle,

  footerFixed: state.DeprecatedThemeOptions.footerFixed,

  contentBackground: state.DeprecatedThemeOptions.contentBackground,
})

export default connect(mapStateToProps)(LeftSidebar)
