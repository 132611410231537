/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2023-03-29 11:58:14
 * @ Description: Bottom drawer for when marketplace/property-search properties are selected.
 */

import React, { useRef, useState } from 'react'
import Alert from '@mui/material/Alert'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Collapse,
  Grid,
  IconButton,
  Menu,
  Tooltip,
  Typography,
} from '@mui/material'
import Analyze from '../Analyze'
import ListOnMarketplace from '@/housefolios-components/Property/ListOnMarketplace'

import isEmpty from 'lodash/isEmpty'
import concat from 'lodash/concat'
import SubmissionApproval from '../SubmissionApproval'
import OldListOnMarketplace from '@/housefolios-components/Property/OldListOnMarketplace'
import withSession from '@/housefolios-components/Session/withSession'

function MarketplaceBulkActionsNotificationsComponent(props) {
  const marketRef = useRef(null)

  const [showMarket, setShowMarket] = useState(false)
  const onMarketClick = (event) => {
    setShowMarket(!showMarket)
  }
  const closeMarketMenu = () => {
    setShowMarket(false)
  }

  const setSelectedMenuClose = (value) => {
    //closeMenu()
    closeMarketMenu()
    props.setSelected([])
    if (props.myMarketplace) props.setMarketplaceSelected([])
  }

  return (
    <Collapse
      in={
        props.selected.length + props.marketplaceSelected.length > 0
      }
      style={{
        position: 'fixed',
        bottom: '20px',
        width: '75%',
        zIndex: 1100,
      }}
    >
      <Alert
        className="d-block"
        style={{ backgroundColor: '#253152', color: 'white' }}
        icon={false}
        // severity="error"
        // action={
        //   <Button variant='contained' size="small" style={{ backgroundColor: '#4290ff', color: 'white' }}>
        //     <Typography>Save</Typography>
        //   </Button>
        // }
      >
        {/* <Typography style={{ color: 'white', fontWeight: 600 }}>You have unsaved changes</Typography> */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          style={{ overflow: 'scroll' }}
        >
          <Grid item>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item>
                <IconButton
                  onClick={() => {
                    if (props.setSelected) props.setSelected([])
                    if (props.setMarketplaceSelected)
                      props.setMarketplaceSelected([])
                  }}
                  size="large"
                >
                  <FontAwesomeIcon
                    icon={['fas', 'xmark']}
                    style={{ color: 'white' }}
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  style={{ whiteSpace: 'nowrap', marginRight: 20 }}
                >
                  {props.selected.length +
                    props.marketplaceSelected.length}{' '}
                  selected
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ textAlign: 'center' }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              wrap="nowrap"
            >
              {!props.propertySearch &&
                (!isEmpty(props.selected) || props.myMarketplace) && (
                  <Grid item>
                    <Tooltip title="Manage Marketplace Listings">
                      <IconButton
                        onClick={onMarketClick}
                        ref={marketRef}
                        size="large"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'sign-hanging']}
                          style={{ color: 'white' }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={marketRef.current}
                      open={showMarket}
                      onClose={onMarketClick}
                    >
                      {props.session.me.activeOrg.newMarketplace ? (
                        <ListOnMarketplace
                          bulk
                          properties={
                            props.myMarketplace
                              ? concat(
                                  props.selectedProperties,
                                  props.marketplaceSelectedProperties,
                                )
                              : props.selectedProperties
                          }
                          selected={
                            props.myMarketplace
                              ? concat(
                                  props.selected,
                                  props.marketplaceSelected,
                                )
                              : props.selected
                          }
                          setSelected={setSelectedMenuClose}
                          portfolioId={props.selectedPortfolio}
                          portfolios={props.portfolios}
                        />
                      ) : (
                        <OldListOnMarketplace
                          bulk
                          properties={
                            props.myMarketplace
                              ? concat(
                                  props.selectedProperties,
                                  props.marketplaceSelectedProperties,
                                )
                              : props.selectedProperties
                          }
                          selected={
                            props.myMarketplace
                              ? concat(
                                  props.selected,
                                  props.marketplaceSelected,
                                )
                              : props.selected
                          }
                          setSelected={setSelectedMenuClose}
                          portfolioId={props.selectedPortfolio}
                          portfolios={props.portfolios}
                        />
                      )}
                    </Menu>
                  </Grid>
                )}
              {(props.propertySearch ||
                !isEmpty(props.marketplaceSelected)) && (
                <Grid container wrap="nowrap">
                  <Grid item>
                    <Analyze
                      selected={
                        props.propertySearch ? props.selected : []
                      }
                      setSelected={props.setSelected}
                      marketplaceSelected={props.marketplaceSelected}
                      setMarketplaceSelected={
                        props.setMarketplaceSelected
                      }
                      properties={props.properties}
                      portfolios={props.portfolios}
                      propertySearch={props.propertySearch}
                    />
                  </Grid>
                </Grid>
              )}
              {!props.propertySearch &&
                (!isEmpty(props.selected) || props.myMarketplace) &&
                props.submitted && (
                  <SubmissionApproval
                    propertyIds={props.marketplaceSelected}
                    marketplace={props.marketplace}
                    setMarketplaceSelected={
                      props.setMarketplaceSelected
                    }
                  />
                )}
            </Grid>
          </Grid>
          <Grid item>
            <IconButton disabled size="large" />
          </Grid>
        </Grid>
      </Alert>
    </Collapse>
  )
}

export default withSession(
  MarketplaceBulkActionsNotificationsComponent,
)
