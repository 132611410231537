import { gql } from '@apollo/client'
import { AssumptionsFragments } from './fragments'

export const GET_ASSUMPTIONS = gql`
  query assumptions($documentId: ID!, $documentType: String!) {
    assumptions(
      documentId: $documentId
      documentType: $documentType
    ) {
      ...assumptions
    }
  }
  ${AssumptionsFragments.assumptions}
`
