import { gql } from '@apollo/client'

export const GET_SUBSCRIPTION_PLAN = gql`
  query subscriptionPlan($subscriptionPlan: String!) {
    subscriptionPlan(subscriptionPlan: $subscriptionPlan) {
      _id
      subscriptionPlan
      price
      planId
      trialPeriodDays
      propertyCount
      portfolioCount
      teamCount
      marketplaceCount
    }
  }
`
