/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-01 15:08:58
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-03-13 15:45:04
 * @ Description: Create one of the pdf options to either be downloaded, emailed, or viewed in an iframe
 */

import React, {
  Component,
  Fragment,
  useEffect,
  useState,
} from 'react'
import { Mutation, Query } from '@apollo/client/react/components'
import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Legend,
} from 'chart.js'
import Autocomplete from '@mui/material/Autocomplete'

import sum from 'lodash/sum'
import zip from 'lodash/zip'
import unzip from 'lodash/unzip'
import last from 'lodash/last'
import split from 'lodash/split'
import isNaN from 'lodash/isNaN'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import trim from 'lodash/trim'
import startCase from 'lodash/startCase'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import flatMap from 'lodash/flatMap'
import filter from 'lodash/filter'
import meanBy from 'lodash/meanBy'
import mean from 'lodash/mean'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import concat from 'lodash/concat'
import head from 'lodash/head'
import chunk from 'lodash/chunk'
import slice from 'lodash/slice'
import isNil from 'lodash/isNil'
import chain from 'lodash/chain'
import withSession from '@/housefolios-components/Session/withSession'
import {
  ValidatorForm,
  TextValidator,
} from 'react-material-ui-form-validator'
import { Close, ControlCameraOutlined } from '@mui/icons-material'
import { GET_PROPERTY } from '../queries'
import { SEND_EMAIL } from '../mutations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { CONTACT_AGENT } from '@/housefolios-components/Marketplace/mutations'
import { compact } from 'lodash'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import { faBlackTie } from '@fortawesome/free-brands-svg-icons'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from '@/vfs_fontes'
import { getStrategyName } from '@/utils/stratagies'
import { usePrevious } from '@/hooks/usePrevious'
import useAffiliate from '@/hooks/useAffiliate'

// Set vfs_fonts to pdfMake
pdfMake.vfs = pdfFonts.default
// console.log('pdfMake:', pdfMake)

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Legend,
)

const PropertyPDF = (props) => {
  const { me } = props.session
  const isLoggedIn = !isNil(me) ? true : false

  const [{ affiliate, urlAffiliate, urlDomain }] = useAffiliate({})

  const [state, setState] = useState({
    display: props.display ? props.display : false,
    showEmail: false,
    showLink: false,
    emails: [],
    docDefinition: '',
    dataURL: '',
    blob: '',
    queried: false,
    qassumption: false,
    called: false,
    property: {},
    anchorEl: null,
    open: false,
    backdrop: false,
    type: props.type || 'buyHold',
    flyers: [],
    emailImagePage: false,
    southernImpressions: isLoggedIn
      ? me.activeOrg.southernImpressions ||
        (me.activeOrg.parentCorp &&
          me.activeOrg.parentCorp.southernImpressions)
      : false,
  })

  useEffect(() => {
    setState({
      ...state,
      type: props.type || 'buyHold',
    })
  }, [props.type])

  const generatePDF = async (docDefinition) => {
    // Now create the PDF
    return pdfMake.createPdf(docDefinition)
  }

  useEffect(() => {
    let { property } = props
    if (state.display) {
      createDoc2(property)
    } else createDoc(property)
  }, [])

  const prevProps = usePrevious(props)
  const prevState = usePrevious(state)

  useEffect(() => {
    let { property } = props
    if (
      prevState?.type !== state.type ||
      prevProps?.marketing !== props.marketing ||
      prevProps?.fixFlip !== props.fixFlip ||
      prevProps?.wholesale !== props.wholesale ||
      prevProps?.shortTermRental !== props.shortTermRental ||
      ((props.uploaded || state.type === 'uploaded') &&
        !isEqual(
          prevProps?.property.proformaFile,
          property.proformaFile,
        )) ||
      prevState?.emailImagePage !== state.emailImagePage
    ) {
      if (state.display) {
        setState({ ...state, blob: '' })
        createDoc2(property)
      } else createDoc(property)
    }
  })

  const formatDate = (date) => {
    var today = new Date(date),
      d = new Date(today.getTime()),
      //d = new Date(today.getTime() + Math.abs(today.getTimezoneOffset()*60000)),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = '' + d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    while (year.length < 4) year = '0' + year

    return [month, day, year].join('-')
  }

  const handleImageUrl = async (imageUrl) => {
    if (imageUrl === '') return ''
    let dataURL
    try {
      const result = await getBase64ImageFromUrl(imageUrl)
      dataURL = result[0]
      const mimeType = result[1].type
      if (mimeType !== 'image/png' && mimeType !== 'image/jpeg') {
        //convert dataURL to jpeg dataURL
        dataURL = await getBase64JPEGImageFromURL(dataURL)
      }
    } catch (err) {
      console.error(err)
      enqueueSnackbar(
        `Failed to fetch image. If problem persists, contact support`,
        {
          variant: 'error',
          action: (key) => (
            <Fragment>
              <IconButton
                onClick={() => {
                  props.closeSnackbar(key)
                }}
                size="large"
              >
                <Close style={{ color: 'white' }} />
              </IconButton>
            </Fragment>
          ),
        },
      )
    }

    return dataURL
  }

  async function getBase64ImageFromUrl(imageUrl) {
    let res = await fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
    })
    if (!res.ok) {
      return ['']
    }
    let blob = await res.blob()

    return new Promise((resolve, reject) => {
      var reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve([reader.result, blob])
        },
        false,
      )

      reader.onerror = () => {
        return reject(this)
      }
      reader.readAsDataURL(blob)
    })
  }

  //OVERVIEW COMMENT: this function takes a url and makes a jpeg dataURL. we still use the getBase64ImageFromUrl as this function does not create blobs and might not be able to fetch from cors
  async function getBase64JPEGImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image()
      img.setAttribute('crossOrigin', 'anonymous')

      img.onload = () => {
        var canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height

        var ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)

        var dataURL = canvas.toDataURL('image/jpeg')

        resolve(dataURL)
      }

      img.onerror = (error) => {
        reject(error)
      }

      img.src = url
    })
  }

  const toggleEmailModal = () => {
    props.handleShareClose()
    setState({
      ...state,
      showEmail: !state.showEmail,
      emailImagePage: false,
    })
  }

  const onChange = async (event) => {
    const { name = '', value = '' } = event.target
    setState({ ...state, [name]: value })
  }

  const onSubmit = async (event, sendEmail, property) => {
    setState({ ...state, backdrop: true })
    sendEmail()
      .then(async () => {
        setState({ ...state, emails: [], backdrop: false })
        if (props.financingProforma) {
          props.updateProperty({
            refetchQueries: [
              {
                query: GET_PROPERTY,
                variables: { id: props.propertyId },
              },
            ],
          })
          props.handleClose()
        } else toggleEmailModal()
        enqueueSnackbar(`Email Sent`, {
          variant: 'success',
          action: (key) => (
            <Fragment>
              <IconButton
                onClick={() => {
                  props.closeSnackbar(key)
                }}
                size="large"
              >
                <Close style={{ color: 'white' }} />
              </IconButton>
            </Fragment>
          ),
        })
      })
      .catch((error) => {
        setState({ ...state, emails: [], backdrop: false })
        if (props.financingProforma) {
          props.updateProperty({
            refetchQueries: [
              {
                query: GET_PROPERTY,
                variables: { id: props.propertyId },
              },
            ],
          })
          props.handleClose()
        } else toggleEmailModal()
        enqueueSnackbar(`Email Failed to Send`, {
          variant: 'error',
          action: (key) => (
            <Fragment>
              <IconButton
                onClick={() => {
                  props.closeSnackbar(key)
                }}
                size="large"
              >
                <Close style={{ color: 'white' }} />
              </IconButton>
            </Fragment>
          ),
        })
      })
    event.preventDefault()
  }

  const getChartData = (
    property,
    graphTest,
    totalReturnFinancing,
    appreciation,
    cashFlowAccumulation,
    totalInitialInvestmentFinancing,
  ) => {
    // const {
    //   acquisition = {},
    //   assumptions = {},
    //   images = [],
    // } = property

    let debt1 = 0
    let debt3 = 0
    let debt5 = 0
    let debt10 = 0
    let equity1 = 0
    let equity3 = 0
    let equity5 = 0
    let equity10 = 0
    let cashFlow1 = 0
    let cashFlow3 = 0
    let cashFlow5 = 0
    let cashFlow10 = 0
    let appreciation1 = 0
    let appreciation3 = 0
    let appreciation5 = 0
    let appreciation10 = 0

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    for (let key in graphTest) {
      if (key === '0') {
        const tmp = graphTest[key]
        debt1 = tmp.debt
        equity1 = tmp.equity
      } else if (key === '2') {
        const tmp = graphTest[key]
        debt3 = tmp.debt
        equity3 = tmp.equity
      } else if (key === '4') {
        const tmp = graphTest[key]
        debt5 = tmp.debt
        equity5 = tmp.equity
      } else if (key === '9') {
        const tmp = graphTest[key]
        debt10 = tmp.debt
        equity10 = tmp.equity
      }
    }

    for (let key in cashFlowAccumulation) {
      const tmp = cashFlowAccumulation[key]
      if (tmp.year === 1) cashFlow1 = tmp.cashFlow
      if (tmp.year === 3) cashFlow3 = tmp.cashFlow
      if (tmp.year === 5) cashFlow5 = tmp.cashFlow
      if (tmp.year === 10) cashFlow10 = tmp.cashFlow
    }

    for (let key in appreciation) {
      if (key === '0') {
        const tmp = appreciation[key]
        appreciation1 = tmp.appreciation
      } else if (key === '2') {
        const tmp = appreciation[key]
        appreciation3 = tmp.appreciation
      } else if (key === '4') {
        const tmp = appreciation[key]
        appreciation5 = tmp.appreciation
      } else if (key === '9') {
        const tmp = appreciation[key]
        appreciation10 = tmp.appreciation
      }
    }

    var canvas = document.createElement('canvas')
    canvas.width = 600
    canvas.height = 400
    document.body.appendChild(canvas)
    var chart = new Chart(canvas.getContext('2d'), {
      type: 'bar',
      data: {
        labels: ['1', '3', '5', '10'],
        datasets: [
          {
            label:
              'Debt (' +
              (debt1 / 1000).toFixed(0) +
              'k, ' +
              (debt3 / 1000).toFixed(0) +
              'k, ' +
              (debt5 / 1000).toFixed(0) +
              'k, ' +
              (debt10 / 1000).toFixed(0) +
              'k)',
            data: [debt1, debt3, debt5, debt10],
            backgroundColor: '#B2BDC8',
          },
          {
            label:
              'Equity (' +
              (
                (equity1 + totalInitialInvestmentFinancing) /
                1000
              ).toFixed(0) +
              'k, ' +
              (
                (equity3 + totalInitialInvestmentFinancing) /
                1000
              ).toFixed(0) +
              'k, ' +
              (
                (equity5 + totalInitialInvestmentFinancing) /
                1000
              ).toFixed(0) +
              'k, ' +
              (
                (equity10 + totalInitialInvestmentFinancing) /
                1000
              ).toFixed(0) +
              'k)',
            data: [
              equity1 + totalInitialInvestmentFinancing,
              equity3 + totalInitialInvestmentFinancing,
              equity5 + totalInitialInvestmentFinancing,
              equity10 + totalInitialInvestmentFinancing,
            ],
            backgroundColor: '#46A3DE',
          },
          {
            label:
              'Appreciation (' +
              (appreciation1 / 1000).toFixed(0) +
              'k, ' +
              (appreciation3 / 1000).toFixed(0) +
              'k, ' +
              (appreciation5 / 1000).toFixed(0) +
              'k, ' +
              (appreciation10 / 1000).toFixed(0) +
              'k)',
            data: [
              appreciation1.toFixed(2),
              appreciation3.toFixed(2),
              appreciation5.toFixed(2),
              appreciation10.toFixed(2),
            ],
            backgroundColor: '#79CBFF',
          },
          {
            label:
              'Cash Positioning (' +
              (cashFlow1 / 1000).toFixed(0) +
              'k, ' +
              (cashFlow3 / 1000).toFixed(0) +
              'k, ' +
              (cashFlow5 / 1000).toFixed(0) +
              'k, ' +
              (cashFlow10 / 1000).toFixed(0) +
              'k)',
            data: [cashFlow1, cashFlow3, cashFlow5, cashFlow10],
            backgroundColor: '#2F3E4E',
          },
        ],
      },
      options: {
        responsive: false,
        plugins: {
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              font: {
                size: 12,
                weight: 'bold',
              },
              boxWidth: 10,
            },
          },
        },
        animation: false,
        scales: {
          x: {
            stacked: true,
            dataset: {
              barPercentage: 0.5,
            },
          },
          y: {
            stacked: true,
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, values) {
                if (index & 1) return ''
                else
                  return currencyFormater.format(value / 1000) + 'k'
              },
              //max: 200000,
              maxTicksLimit: 20,
              stepSize: 25000,
            },
          },
        },
      },
    })
    const base64 = chart.toBase64Image()
    canvas.remove()
    return base64
  }

  const getFixFlipChartData = (property) => {
    const { acquisition = {} } = property
    const { analytics = DEFAULT_ANALYTICS } = acquisition

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const resellPrice = acquisition.analysis.resellPriceFixFlip
      ? acquisition.analysis.resellPriceFixFlip
      : analytics.fixFlip.arvTotalFixFlip || 0
    const expenses =
      analytics.fixFlip.totalOutOfPocketFixFlip +
      analytics.fixFlip.salesCommissionTotalFixFlip +
      analytics.fixFlip.dispositionFeeDollarFixFlip +
      analytics.fixFlip.loanBalanceAtHoldingPeriod
    const netProfit = analytics.fixFlip.saleProceeds

    var canvas = document.createElement('canvas')
    canvas.width = 600
    canvas.height = 400
    document.body.appendChild(canvas)
    var chart = new Chart(canvas.getContext('2d'), {
      type: 'bar',
      data: {
        labels: ['Resell Price', 'Expenses', 'Net Profit'],
        datasets: [
          {
            data: [resellPrice, expenses, netProfit],
            backgroundColor: ['#1CC943', '#F83244', '#04A3E3'],
          },
        ],
      },
      options: {
        responsive: false,
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false,
          },
        },
        animation: false,
        scales: {
          y: {
            dataset: {
              barPercentage: 0.5,
            },
          },
          x: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, values) {
                if (index & 1) return ''
                else
                  return currencyFormater.format(value / 1000) + 'k'
              },
              //max: 200000,
              maxTicksLimit: 20,
              stepSize: 25000,
            },
          },
        },
      },
    })
    const base64 = chart.toBase64Image()
    canvas.remove()
    return base64
  }

  const graphSection = (args) => {
    const {
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear,
      paydownMonthly,
      holdingPeriod,
    } = args

    let principal = 0
    let amtLeft = 0
    let equity = 0
    let graphData = []
    let loanBalance = 0
    if (holdingPeriod === false) {
      for (let index = 1; index < debtYear + 1; index++) {
        if (index === 1) {
          principal = mortgagePayment - financeAmount * monthlyRate
          amtLeft = financeAmount - principal - paydownMonthly
        } else if (index % 12 === 0) {
          principal = mortgagePayment - amtLeft * monthlyRate
          amtLeft = amtLeft - principal - paydownMonthly

          equity = financeAmount - amtLeft

          if (amtLeft < 0) amtLeft = 0
          graphData.push({
            debt: amtLeft,
            equity: equity,
          })
        } else {
          principal = mortgagePayment - amtLeft * monthlyRate
          amtLeft = amtLeft - principal - paydownMonthly
        }
      }

      return graphData
    } else {
      for (let index = 1; index < debtYear + 1; index++) {
        if (index === 1) {
          principal = mortgagePayment - financeAmount * monthlyRate
          amtLeft = financeAmount - principal - paydownMonthly
        } else if (index === debtYear) {
          principal = mortgagePayment - amtLeft * monthlyRate
          amtLeft = amtLeft - principal - paydownMonthly

          if (amtLeft <= 0) amtLeft = 0

          loanBalance = amtLeft
        } else {
          principal = mortgagePayment - amtLeft * monthlyRate
          amtLeft = amtLeft - principal - paydownMonthly
        }
      }

      return loanBalance
    }
  }

  const appreciationSection = (args) => {
    const { afterRepairValue, homePriceAppreciation, term } = args

    let monthlyAppreciation = 0
    let appreciation = []
    let monthlyPriceAppreciation = homePriceAppreciation / 12 / 100
    let yearlyAppreciation = 0

    for (let index = 1; index < term + 1; index++) {
      if (index === 1) {
        monthlyAppreciation = afterRepairValue
      } else if (index % 12 === 0) {
        monthlyAppreciation =
          monthlyAppreciation +
          monthlyAppreciation * monthlyPriceAppreciation
        yearlyAppreciation = monthlyAppreciation - afterRepairValue

        appreciation.push({
          appreciation: yearlyAppreciation,
        })
      } else {
        monthlyAppreciation =
          monthlyAppreciation +
          monthlyAppreciation * monthlyPriceAppreciation
      }
    }

    return appreciation
  }

  const revenuesSection = (args) => {
    const { revenueYear, rentAmount, annualRevenueIncrease } = args

    let rentalIncome = []

    for (let index = 1; index < revenueYear + 1; index++) {
      if (index === 1)
        rentalIncome = map(rentAmount, (amount) => amount * 12)
      else
        rentalIncome = map(
          rentalIncome,
          (rentalIncome) =>
            rentalIncome * (1 + annualRevenueIncrease),
        )
    }
    return rentalIncome
  }
  const revenuesSectionSTR = (args) => {
    const { revenueYear, avgMonthlyRate, annualRevenueIncrease } =
      args

    let rentalIncome = []

    for (let index = 1; index < revenueYear + 1; index++) {
      if (index === 1)
        rentalIncome = map(avgMonthlyRate, (amount) => amount * 12)
      else
        rentalIncome = map(
          rentalIncome,
          (rentalIncome) =>
            rentalIncome * (1 + annualRevenueIncrease),
        )
    }
    return rentalIncome
  }

  // otherIncomeSection = args => {
  //   const { rentAmount, revenueYear, annualRevenueIncrease} = args

  //   let otherIncome = []

  //   for (let index = 1; index < revenueYear + 1; index++) {
  //     if (index === 1) otherIncome = map(rentAmount, amount => amount * 12)
  //     else otherIncome = map(otherIncome, otherIncome => otherIncome * (1 + annualRevenueIncrease))
  //   }

  //   return otherIncome
  // }

  const cashFlowAccumulationCalc = (args) => {
    const {
      accelerator,
      term,
      rentAmount,
      vacancy,
      propertyTaxes,
      homeInsurance,
      monthlyHOA,
      propertyMgmtPercent,
      maintenance,
      utilities,
      annualRevenueIncrease,
      annualOperatingExpenseIncrease,
      //added so that cash flow is with Financing
      mortgagePayment,
    } = args

    let pdAccelerator = accelerator / 100
    let rent = rentAmount
    let rentIncrease = map(rent, (value) => 0)
    let vac = map(
      zip(rentAmount, vacancy),
      ([rentAmount, vacancy]) => rentAmount * vacancy,
    )
    let revenue = 0
    let propTax = propertyTaxes
    let propTaxIncrease = 0
    let insurance = homeInsurance
    let insuranceIncrease = 0
    let hoa = monthlyHOA
    let hoaIncrease = 0
    let propMgmt = sum(rentAmount) * propertyMgmtPercent
    let main = sum(rentAmount) * maintenance
    let mainIncrease = 0
    let uti = utilities
    let utiIncrease = 0
    let expenses = 0
    let cashFlow = 0
    let cfyear = []
    //added so that cash flow is with Financing
    let mortgage = 0

    for (let index = 1; index < term; index++) {
      if (index > 1 && index < 12) {
        rent = map(
          zip(rent, rentAmount),
          ([rent, rentAmount]) => rent + rentAmount,
        )
        propTax += propertyTaxes
        insurance += homeInsurance
        hoa += monthlyHOA
        //added so that cash flow is with Financing
        mortgage += mortgagePayment
      } else if (index % 12 === 0) {
        if (index / 12 === 1) {
          rent = map(
            zip(rent, rentAmount),
            ([rent, rentAmount]) => rent + rentAmount,
          )
          vac = map(
            zip(rent, vacancy),
            ([rent, vacancy]) => rent * vacancy,
          )
          revenue = sum(rent) - sum(vac)

          propTax += propertyTaxes
          insurance += homeInsurance
          hoa += monthlyHOA
          propMgmt = revenue * propertyMgmtPercent
          main = sum(rent) * maintenance
          expenses = propTax + insurance + hoa + propMgmt + main + uti

          //added so that cash flow is with Financing
          mortgage += mortgagePayment

          cashFlow = revenue - expenses - mortgage
          cashFlow = cashFlow - pdAccelerator * cashFlow

          cfyear.push({
            year: index / 12,
            cashFlow: cashFlow,
          })

          rentIncrease = map(
            rentAmount,
            (rentAmount) =>
              rentAmount + rentAmount * annualRevenueIncrease,
          )
          propTaxIncrease =
            propTax * (1 + annualOperatingExpenseIncrease)
          insuranceIncrease =
            insurance * (1 + annualOperatingExpenseIncrease)
          hoaIncrease = hoa * (1 + annualOperatingExpenseIncrease)
          mainIncrease = main * (1 + annualOperatingExpenseIncrease)
          utiIncrease = uti * (1 + annualOperatingExpenseIncrease)
        } else {
          rent = map(
            zip(rent, rentIncrease),
            ([rent, rentIncrease]) => rent + rentIncrease,
          )
          vac = map(
            zip(rent, vacancy),
            ([rent, vacancy]) => rent * vacancy,
          )
          revenue = sum(rent) - sum(vac)

          propTax += propTaxIncrease
          insurance += insuranceIncrease
          hoa += hoaIncrease
          propMgmt = revenue * propertyMgmtPercent
          main += mainIncrease
          uti += utiIncrease
          expenses = propTax + insurance + hoa + propMgmt + main + uti

          //added so that cash flow is with Financing
          mortgage += mortgagePayment

          cashFlow = revenue - expenses - mortgage
          cashFlow = cashFlow - pdAccelerator * cashFlow

          cfyear.push({
            year: index / 12,
            cashFlow: cashFlow,
          })

          rentIncrease = map(
            rentIncrease,
            (rentIncrease) =>
              rentIncrease + rentIncrease * annualRevenueIncrease,
          )
          propTaxIncrease =
            propTaxIncrease * (1 + annualOperatingExpenseIncrease)
          insuranceIncrease =
            insuranceIncrease * (1 + annualOperatingExpenseIncrease)
          hoaIncrease =
            hoaIncrease * (1 + annualOperatingExpenseIncrease)
          mainIncrease =
            mainIncrease * (1 + annualOperatingExpenseIncrease)
          utiIncrease =
            utiIncrease * (1 + annualOperatingExpenseIncrease)
        }
      } else {
        rent = map(
          zip(rent, rentIncrease),
          ([rent, rentIncrease]) => rent + rentIncrease,
        )
        //added so that cash flow is with Financing
        mortgage += mortgagePayment
      }
    }

    return cfyear
  }

  const expensesSection = (args) => {
    const {
      expenseType,
      revenueYear,
      propertyTaxes,
      homeInsurance,
      yearlyHOA,
      rentAmount,
      maintenance,
      mgmtFee,
      assetManagementFeeTotal,
      otherExpenses,
      utilitiesTypeDollar,
      yearlyReserve,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease,
    } = args

    if (expenseType === 'PT') {
      let ptCalculation = 0

      for (let index = 1; index < revenueYear + 1; index++) {
        if (index === 1) ptCalculation = propertyTaxes
        else
          ptCalculation =
            ptCalculation * (1 + annualOperatingExpenseIncrease)
      }

      return ptCalculation
    } else if (expenseType === 'INSURANCE') {
      let hiCalculation = 0

      for (let index = 1; index < revenueYear + 1; index++) {
        if (index === 1) hiCalculation = homeInsurance
        else
          hiCalculation =
            hiCalculation * (1 + annualOperatingExpenseIncrease)
      }

      return hiCalculation
    } else if (expenseType === 'HOA') {
      let hoaCalculation = 0

      for (let index = 1; index < revenueYear + 1; index++) {
        if (index === 1) hoaCalculation = yearlyHOA
        else
          hoaCalculation =
            hoaCalculation * (1 + annualOperatingExpenseIncrease)
      }

      return hoaCalculation
    } else if (expenseType === 'MAINTENANCE') {
      let mrFee = 0

      for (let index = 1; index < revenueYear + 1; index++) {
        if (index === 1) mrFee = rentAmount * maintenance
        else mrFee = mrFee * (1 + annualOperatingExpenseIncrease)
      }

      return mrFee
    } else if (expenseType === 'ASSET') {
      let amf = 0

      for (let index = 1; index < revenueYear + 1; index++) {
        if (index === 1) amf = assetManagementFeeTotal
        else amf = assetManagementFeeTotal
      }

      return amf
    } else if (expenseType === 'MGMT') {
      let mgmt = 0

      for (let index = 1; index < revenueYear + 1; index++) {
        if (index === 1) mgmt = rentAmount * mgmtFee
        else mgmt = mgmt * (1 + annualOperatingExpenseIncrease)
      }

      return mgmt
    } else if (expenseType === 'OTHER') {
      let other = 0

      for (let index = 1; index < revenueYear + 1; index++) {
        if (index === 1) other = otherExpenses
        else other = other * (1 + annualOperatingExpenseIncrease)
      }

      return other
    } else if (expenseType === 'UTILITY') {
      let utility = 0

      for (let index = 1; index < revenueYear + 1; index++) {
        if (index === 1) utility = utilitiesTypeDollar
        else utility = utility * (1 + annualOperatingExpenseIncrease)
      }

      return utility
    } else if (expenseType === 'RESERVE') {
      let reserve = 0
      for (let index = 1; index < revenueYear + 1; index++) {
        if (index === 1) reserve = yearlyReserve
        else reserve = reserve * (1 + annualOperatingExpenseIncrease)
      }

      return reserve
    } else if (expenseType === 'CUSTOM') {
      let custom = 0

      for (let index = 1; index < revenueYear + 1; index++) {
        if (index === 1) custom = customAnnualExpenseTotal
        else custom = custom * (1 + annualOperatingExpenseIncrease)
      }

      return custom
    } else {
      return 0
    }
  }

  const getCompChartData = (property, comps) => {
    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    var canvas = document.createElement('canvas')
    canvas.width = 500
    canvas.height = 600
    document.body.appendChild(canvas)
    var chart = new Chart(canvas.getContext('2d'), {
      type: 'bar',
      data: {
        labels: concat(
          ['Subject Property'],
          flatMap(
            comps,
            (comp) =>
              `${comp.address} ${comp.city}, ${comp.state} ${comp.zip}`,
          ),
        ),
        datasets: [
          {
            label: 'Sold Price',
            data: concat(
              [property.acquisition.analysis.listPrice],
              flatMap(comps, (comp) => comp.soldPrice),
            ),
            backgroundColor: '#2F3E4E',
          },
        ],
      },
      options: {
        responsive: false,
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            labels: {
              font: {
                size: 12,
                weight: 'bold',
              },
              boxWidth: 10,
            },
          },
        },
        animation: false,
        scales: {
          x: {
            stacked: true,
            dataset: {
              barPercentage: 0.5,
            },
          },
          y: {
            stacked: true,
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, values) {
                if (index & 1) return ''
                else
                  return currencyFormater.format(value / 1000) + 'k'
              },
              //max: 200000,
              maxTicksLimit: 20,
              stepSize: 25000,
            },
          },
        },
      },
    })
    const base64 = chart.toBase64Image()
    canvas.remove()
    return base64
  }

  const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '')

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    return null
  }

  const buyHoldPage1LowerRight = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    proformaDescription = proformaDescription
      ? trim(proformaDescription)
      : description

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    const IRR = acquisition.analytics.buyHold.IRR
      ? (acquisition.analytics.buyHold.IRR * 100).toFixed(2)
      : 0
    const CAPRate = acquisition.analytics.buyHold.capRate
      ? (acquisition.analytics.buyHold.capRate * 100).toFixed(2)
      : 0
    const netROI = acquisition.analytics.fixFlip.netROIFixFlip
      ? (acquisition.analytics.fixFlip.netROIFixFlip * 100).toFixed(2)
      : 0
    const cashOnCash = acquisition.analytics.buyHold.cashOnCashReturn
      ? (
          acquisition.analytics.buyHold.cashOnCashReturn * 100
        ).toFixed(2)
      : 0
    const equity = acquisition.analytics.buyHold.equity
      ? acquisition.analytics.buyHold.equity
      : 0
    const mortgagePayment = acquisition.analytics.buyHold.mp_monthly
      ? acquisition.analytics.buyHold.mp_monthly
      : 0
    const allInCost = acquisition.analytics.buyHold.allInCost
      ? acquisition.analytics.buyHold.allInCost
      : 0
    const saleProceeds = acquisition.analytics.buyHold.saleProceeds
      ? acquisition.analytics.buyHold.saleProceeds
      : 0
    const utilities = acquisition.analytics.buyHold
      .utilitiesTypeDollar
      ? acquisition.analytics.buyHold.utilitiesTypeDollar
      : 0
    const assetManagementFeeTotal = acquisition.analytics.buyHold
      .assetManagementFeeTotal
      ? acquisition.analytics.buyHold.assetManagementFeeTotal
      : 0

    const homeInsurance = acquisition.analytics.buyHold
      .propertyInsuranceDollar
      ? acquisition.analytics.buyHold.propertyInsuranceDollar
      : 0
    const maintenanceCost = acquisition.analytics.buyHold
      .maintenanceCost
      ? acquisition.analytics.buyHold.maintenanceCost
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange'
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.buyHold.hoaTotal
      ? acquisition.analytics.buyHold.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.buyHold
      .arvTotalBuyHold
      ? acquisition.analytics.buyHold.arvTotalBuyHold
      : 0
    // const rehabCost = acquisition.analysis.rehabCost
    //   ? acquisition.analysis.rehabCost
    //   : 0
    const rehabCost = acquisition.analytics.buyHold.corTotal
      ? acquisition.analytics.buyHold.corTotal
      : 0
    // const sellPrice = acquisition.analysis.sellPrice
    //   ? acquisition.analysis.afterRepairValue
    //   : 0
    const downPayment = assumptions.downPayment
      ? assumptions.downPayment
      : 0
    const rate = assumptions.rate ? assumptions.rate : 0
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenance
      ? assumptions.maintenance
      : 0
    const propertyMgmtPercent = acquisition.analytics.buyHold
      .propertyManagementPercentage
      ? acquisition.analytics.buyHold.propertyManagementPercentage
      : 0
    const holdingPeriod = assumptions.rentalHoldingPeriod
      ? assumptions.rentalHoldingPeriod
      : 0

    const otherExpenses = assumptions.otherExpense
      ? assumptions.otherExpense
      : 0
    const ownerReserves = assumptions.ownerReserves
      ? assumptions.ownerReserves
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    const percentOfARV = (allInCost / afterRepairValue) * 100

    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly

    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12

    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const totalReturnFinancingMonthly =
      NOINoFinanceMonthly - mortgagePayment
    const totalReturnNoFinancingMonthly = NOINoFinanceMonthly

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'

    return [
      {
        text: ['Financial ', { text: 'Returns', color: '#3399ff' }],
        fontSize: 18,
        margin: [0, 5, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
          {
            type: 'rect',
            x: 2,
            y: 27,
            w: 175,
            h: 55,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 0,
            y: 25,
            w: 175,
            h: 55,
            r: 2,
            color: '#3399ff',
            fillOpacity: 1,
          },
          {
            type: 'rect',
            x: 192,
            y: 27,
            w: 175,
            h: 55,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 190,
            y: 25,
            w: 175,
            h: 55,
            r: 2,
            color: '#344265',
            fillOpacity: 1,
          },
          {
            type: 'rect',
            x: 2,
            y: 92,
            w: 365,
            h: 92,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 0,
            y: 90,
            w: 365,
            h: 92,
            r: 2,
          },
          {
            type: 'rect',
            x: 0,
            y: 90,
            w: 365,
            h: 92,
            r: 2,
            border: [true, true, true, true],
            color: 'white',
            fillOpacity: 1,
          },
          // {
          //   type: 'rect',
          //   x: 192,
          //   y: 92,
          //   w: 175,
          //   h: 92,
          //   r: 2,
          //   color: 'black',
          //   fillOpacity: 0.5,
          // },
          // {
          //   type: 'rect',
          //   x: 190,
          //   y: 90,
          //   w: 175,
          //   h: 92,
          //   r: 2,
          // },
          // {
          //   type: 'rect',
          //   x: 190,
          //   y: 90,
          //   w: 175,
          //   h: 92,
          //   r: 2,
          //   border: [true, true, true, true],
          //   color: 'white',
          //   fillOpacity: 1,
          // },
          // {
          //   type: 'rect',
          //   x: 185,
          //   y: 110,
          //   w: 85,
          //   h: 50,
          //   r: 2,
          // },
          // {
          //   type: 'rect',
          //   x: 275,
          //   y: 110,
          //   w: 85,
          //   h: 50,
          //   r: 2,
          // },
        ],
      },
      {
        absolutePosition: { x: 220, y: 360 },
        stack: [
          { text: 'Financing', fontSize: 15, color: 'white' },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1.5,
                color: 'white',
              },
            ],
          },
          {
            text: [
              'Cash Flow/mo: ',
              {
                text: currencyFormater.format(
                  totalReturnFinancingMonthly,
                ),
                fontSize: 14,
                bold: true,
              },
            ],
            color: 'white',
            margin: [0, 7, 0, 0],
          },
          //{ text: 'Total ROI: ' + totalROIFinancing1.toFixed(2) + '%', color: 'white' },
        ],
      },
      {
        absolutePosition: { x: 410, y: 360 },
        stack: [
          { text: 'Cash', fontSize: 15, color: 'white' },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1.5,
                color: 'white',
              },
            ],
          },
          {
            text: [
              'Cash Flow/mo: ',
              {
                text: currencyFormater.format(
                  totalReturnNoFinancingMonthly,
                ),
                fontSize: 14,
                bold: true,
              },
            ],
            color: 'white',
            margin: [0, 7, 0, 0],
          },
          //{ text: 'Total ROI: ' + totalROINoFinancing1.toFixed(2) + '%', color: 'white' },
        ],
      },
      {
        absolutePosition: { x: 220, y: 425 },
        stack: [
          {
            text: ['Buy & Hold'],
            fontSize: 16,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 446 },
        stack: [
          {
            text: [
              'Equity:   ',
              {
                text: currencyFormater.format(equity),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 462 },
        stack: [
          {
            text: [
              'Cash on Cash:   ',
              {
                text: cashOnCash + '%',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 478 },
        stack: [
          {
            text: [
              'IRR:  ',
              {
                text: IRR + '%',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 494 },
        stack: [
          {
            text: [
              'CAP Rate:   ',
              {
                text: CAPRate + '%',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      // {
      //   absolutePosition: { x: 410, y: 425 },
      //   stack: [
      //     {
      //       text: ['Fixxx & Flip'],
      //       fontSize: 16,
      //       alignment: 'left',
      //     },
      //   ],
      // },
      // {
      //   absolutePosition: { x: 410, y: 446 },
      //   stack: [
      //     {
      //       text: [
      //         'Net ROI:  ',
      //         {
      //           text: netROI + '%',
      //           fontSize: 14,
      //           bold: true,
      //         },
      //       ],
      //       fontSize: 13,
      //       alignment: 'left',
      //     },
      //   ],
      // },
      // {
      //   absolutePosition: { x: 410, y: 462 },
      //   stack: [
      //     {
      //       text: [
      //         '% of ARV:   ',
      //         {
      //           text: percentOfARV.toFixed(2) + '%',
      //           fontSize: 14,
      //           bold: true,
      //         },
      //       ],
      //       fontSize: 13,
      //       alignment: 'left',
      //     },
      //   ],
      // },
      // {
      //   absolutePosition: { x: 410, y: 478 },
      //   stack: [
      //     {
      //       text: [
      //         'Holding Time:   ',
      //         {
      //           text: holdingPeriod + ' mo.',
      //           fontSize: 14,
      //           bold: true,
      //         },
      //       ],
      //       fontSize: 13,
      //       alignment: 'left',
      //     },
      //   ],
      // },
      // {
      //   absolutePosition: { x: 410, y: 494 },
      //   stack: [
      //     {
      //       text: [
      //         'Equity:   ',
      //         {
      //           text: currencyFormater.format(equity),
      //           fontSize: 14,
      //           bold: true,
      //         },
      //       ],
      //       fontSize: 13,
      //       alignment: 'left',
      //     },
      //   ],
      // },

      {
        text: [
          'Property ',
          { text: 'Information', color: '#3399ff' },
        ],
        fontSize: 18,
        margin: [0, 20, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
        ],
      },
      {
        columns: [
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              {
                text: [
                  'Beds:   ',
                  { text: sum(beds), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Baths:   ',
                  { text: sum(baths), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Sq Feet:   ',
                  {
                    text: sqftString,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              // {
              //   text: 'Parking: ',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Lot Size: ',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
            ],
            margin: [0, 10, 0, 0],
            width: 140,
          },
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              // {
              //   text: 'Rent Range:   ' + currencyFormater.format(rentAmount),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              {
                text: [
                  'Year:   ',
                  {
                    text: property.year,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Monthly Rent:   ',
                  {
                    text: currencyFormater.format(sum(rentAmount)),
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              // {
              //   text: [
              //     'Acquisition Fee:   ',
              //     {
              //       text: currencyFormater.format(
              //         acquisitionFee,
              //       ),
              //       fontSize: 14,
              //       bold: true,
              //     },
              //   ],
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },

              // {
              //   text: 'Net ROI:   ' + netROI + '%',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: '% of ARV:   ' + percentOfARV.toFixed(2) + '%',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Flip Holding Period:   ' + holdingPeriod + ' mo.',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Sale Proceeds:   ' + currencyFormater.format(saleProceeds),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },

              // {
              //   text: 'Property Taxes:   ' + currencyFormater.format(propertyTaxes),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Home Insurance:   ' + currencyFormater.format(homeInsurance),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Annual HOA:   ' + currencyFormater.format(yearlyHOA),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Property Mgmt (' + propertyMgmtPercent + '%):   ' + currencyFormater.format(propertyMgmt),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              //   pageBreak: 'after'
              // },
            ],
            margin: [0, 10, 0, 0],
            width: 'auto',
          },
        ],
      },
      {
        text: proformaDescription,
        margin: [0, 10, 205, 0],
        fontSize: proformaDescription
          ? proformaDescription.length + proformaDescriptionLines >
            280
            ? proformaDescription.length + proformaDescriptionLines >
              350
              ? proformaDescription.length +
                  proformaDescriptionLines >
                500
                ? proformaDescription.length +
                    proformaDescriptionLines >
                  1350
                  ? 6.5
                  : 8
                : 10
              : 12
            : 14
          : 14,
      },
      {
        absolutePosition: { x: 5, y: 765 },
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 580,
            y2: 5,
            lineWidth: 2,
          },
        ],
        margin: [0, 0, 0, 0],
      },
      await defaultFooter(property),
    ]
  }

  const fixFlipPage1LowerRight = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    proformaDescription = proformaDescription
      ? trim(proformaDescription)
      : description

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    const netROI = acquisition.analytics.fixFlip.netROIFixFlip
      ? (acquisition.analytics.fixFlip.netROIFixFlip * 100).toFixed(2)
      : 0

    const saleProceeds = acquisition.analytics.fixFlip.saleProceeds
      ? acquisition.analytics.fixFlip.saleProceeds
      : 0
    const utilities = acquisition.analytics.fixFlip
      .utilitiesTypeDollarFixFlip
      ? acquisition.analytics.fixFlip.utilitiesTypeDollarFixFlip
      : 0
    const assetManagementFeeTotal = acquisition.analytics.fixFlip
      .assetManagementFeeTotalFixFlip
      ? acquisition.analytics.fixFlip.assetManagementFeeTotalFixFlip
      : 0

    const homeInsurance = acquisition.analytics.fixFlip
      .propertyInsuranceDollarFixFlip
      ? acquisition.analytics.fixFlip.propertyInsuranceDollarFixFlip
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange'
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.fixFlip.hoaTotal
      ? acquisition.analytics.fixFlip.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.fixFlip
      .arvTotalFixFlip
      ? acquisition.analytics.fixFlip.arvTotalFixFlip
      : 0
    // const rehabCost = acquisition.analysis.rehabCost
    //   ? acquisition.analysis.rehabCost
    //   : 0
    const rehabCost = acquisition.analytics.fixFlip.corTotalFixFlip
      ? acquisition.analytics.fixFlip.corTotalFixFlip
      : 0
    // const sellPrice = acquisition.analysis.sellPrice
    //   ? acquisition.analytics.fixFlip.arvTotalFixFlip
    //   : 0
    const downPayment = assumptions.downPaymentFixFlip
      ? assumptions.downPaymentFixFlip
      : 0
    const rate = assumptions.rateFixFlip ? assumptions.rateFixFlip : 0
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceFixFlip
      ? assumptions.maintenanceFixFlip
      : 0
    const propertyMgmtPercent = acquisition.analytics.fixFlip
      .propertyManagementPercentageFixFlip
      ? acquisition.analytics.fixFlip
          .propertyManagementPercentageFixFlip
      : 0
    const holdingPeriod = assumptions.holdingPeriod
      ? assumptions.holdingPeriod
      : 0

    const otherExpenses = assumptions.otherExpenseFixFlip
      ? assumptions.otherExpenseFixFlip
      : 0
    const ownerReserves = assumptions.ownerReservesFixFlip
      ? assumptions.ownerReservesFixFlip
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    // const percentOfARV = (allInCost / afterRepairValue) * 100
    const percentageOfARV = acquisition.analytics.fixFlip
      .percentageOfARV
      ? acquisition.analytics.fixFlip.percentageOfARV * 100
      : 0

    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly

    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12

    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'

    return [
      {
        text: ['Financial ', { text: 'Returns', color: '#3399ff' }],
        fontSize: 18,
        margin: [0, 5, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
          {
            type: 'rect',
            x: 0,
            y: 25,
            w: 365,
            h: 92,
            r: 2,
          },
          {
            type: 'rect',
            x: 0,
            y: 25,
            w: 365,
            h: 92,
            r: 2,
            border: [true, true, true, true],
            color: 'white',
            fillOpacity: 1,
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 360 },
        stack: [
          {
            text: ['Fix & Flip'],
            fontSize: 16,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 381 },
        stack: [
          {
            text: [
              'Net Profit:   ',
              {
                text: currencyFormater.format(saleProceeds),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 397 },
        stack: [
          {
            text: [
              'Net ROI:  ',
              {
                text: netROI + '%',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 413 },
        stack: [
          {
            text: [
              'All-In Cost to ARV:   ',
              {
                text: percentageOfARV.toFixed(2) + '%',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      // {
      //   absolutePosition: { x: 220, y: 429 },
      //   stack: [
      //     {
      //       text: [
      //         'Equity:   ',
      //         {
      //           text: currencyFormater.format(equity),
      //           fontSize: 14,
      //           bold: true,
      //         },
      //       ],
      //       fontSize: 13,
      //       alignment: 'left',
      //     },
      //   ],
      // },

      {
        text: [
          'Property ',
          { text: 'Information', color: '#3399ff' },
        ],
        fontSize: 18,
        margin: [0, 20, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
        ],
      },
      {
        columns: [
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              {
                text: [
                  'Beds:   ',
                  { text: sum(beds), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Baths:   ',
                  { text: sum(baths), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
            ],
            margin: [0, 10, 0, 0],
            width: 140,
          },
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              {
                text: [
                  'Sq Feet:   ',
                  {
                    text: sqftString,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Year:   ',
                  {
                    text: property.year,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
            ],
            margin: [0, 10, 0, 0],
            width: 'auto',
          },
        ],
      },
      {
        text: proformaDescription,
        margin: [0, 10, 205, 0],
        fontSize: proformaDescription
          ? proformaDescription.length + proformaDescriptionLines >
            280
            ? proformaDescription.length + proformaDescriptionLines >
              350
              ? proformaDescription.length +
                  proformaDescriptionLines >
                500
                ? proformaDescription.length +
                    proformaDescriptionLines >
                  1350
                  ? 6.5
                  : 8
                : 10
              : 12
            : 14
          : 14,
      },
      {
        absolutePosition: { x: 5, y: 765 },
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 580,
            y2: 5,
            lineWidth: 2,
          },
        ],
        margin: [0, 0, 0, 0],
      },
      await defaultFooter(property),
    ]
  }

  const shortTermRentalPage1LowerRight = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      analysis = {},
      assumptions = {},
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    proformaDescription = proformaDescription
      ? trim(proformaDescription)
      : description

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    // const IRR = acquisition.analytics.IRR
    //   ? (acquisition.analytics.IRR * 100).toFixed(2)
    //   : 0
    const IRR = acquisition.analytics.STR.IRRSTR
      ? (acquisition.analytics.STR.IRRSTR * 100).toFixed(2)
      : 0
    // const CAPRate = acquisition.analytics.capRate
    //   ? (acquisition.analytics.capRate * 100).toFixed(2)
    //   : 0
    const CAPRate = acquisition.analytics.STR.capRateSTR
      ? (acquisition.analytics.STR.capRateSTR * 100).toFixed(2)
      : 0
    const netROI = acquisition.analytics.fixFlip.netROIFixFlip
      ? (acquisition.analytics.fixFlip.netROIFixFlip * 100).toFixed(2)
      : 0
    // const cashOnCash = acquisition.analytics.cashOnCashReturn
    //   ? (acquisition.analytics.cashOnCashReturn * 100).toFixed(2)
    //   : 0
    const cashOnCash = acquisition.analytics.STR.cashOnCashReturnSTR
      ? (acquisition.analytics.STR.cashOnCashReturnSTR * 100).toFixed(
          2,
        )
      : 0
    const mortgagePayment = acquisition.analytics.STR.mortgageSTR
      ? acquisition.analytics.STR.mortgageSTR
      : 0
    const allInCost = acquisition.analytics.STR.allInCostSTR
      ? acquisition.analytics.STR.allInCostSTR
      : 0
    const utilities = acquisition.analytics.STR.utilitiesTypeDollarSTR
      ? acquisition.analytics.STR.utilitiesTypeDollarSTR
      : 0
    const assetManagementFeeTotal = acquisition.analytics.STR
      .assetManagementFeeTotalSTR
      ? acquisition.analytics.STR.assetManagementFeeTotalSTR
      : 0

    const homeInsurance = acquisition.analytics.STR
      .propertyInsuranceDollarSTR
      ? acquisition.analytics.STR.propertyInsuranceDollarSTR
      : 0
    const maintenanceCost = acquisition.analytics.STR
      .maintenanceCostSTR
      ? acquisition.analytics.STR.maintenanceCostSTR
      : 0
    const rentAmount =
      assumptions.rentSTRType === 'rentRange'
        ? acquisition.analysis.rentLowSTR &&
          acquisition.analysis.rentHighSTR
          ? map(
              zip(
                acquisition.analysis.rentLowSTR,
                acquisition.analysis.rentHighSTR,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rentSTR
          ? acquisition.analysis.rentSTR
          : [0]
    const avgRate = map(
      zip(assumptions.avgRate, assumptions.avgRateType),
      ([avgRate, avgRateType]) =>
        avgRateType === 'Weekly'
          ? avgRate
            ? avgRate / 7
            : 150 / 7
          : avgRate
            ? avgRate
            : 150,
    )
    const occupancyRate = assumptions.occupancyRate
      ? assumptions.occupancyRate
      : 0
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.STR.hoaTotal
      ? acquisition.analytics.STR.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.STR.arvTotalSTR
      ? acquisition.analytics.STR.arvTotalSTR
      : 0
    // const rehabCost = acquisition.analysis.rehabCost
    //   ? acquisition.analysis.rehabCost
    //   : 0
    const rehabCost = acquisition.analytics.STR.corTotalSTR
      ? acquisition.analytics.STR.corTotalSTR
      : 0
    // const sellPrice = acquisition.analysis.sellPrice
    //   ? acquisition.analysis.afterRepairValue
    //   : 0
    const downPayment = assumptions.downPaymentSTR
      ? assumptions.downPaymentSTR
      : 0
    const rate = assumptions.rateSTR ? assumptions.rateSTR : 0
    const maintenance = assumptions.maintenanceSTR
      ? assumptions.maintenanceSTR
      : 0
    const propertyMgmtPercent = acquisition.analytics.STR
      .propertyManagementPercentageSTR
      ? acquisition.analytics.STR.propertyManagementPercentageSTR
      : 0
    const holdingPeriod = assumptions.rentalHoldingPeriodSTR
      ? assumptions.rentalHoldingPeriodSTR
      : 0

    const otherExpenses = assumptions.otherExpenseSTR
      ? assumptions.otherExpenseSTR
      : 0
    const ownerReserves = assumptions.ownerReservesSTR
      ? assumptions.ownerReservesSTR
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    const percentOfARV = (allInCost / afterRepairValue) * 100

    const grossAvgRate = sum(
      map(
        zip(avgRate, occupancyRate),
        ([avgRate, occupancy]) => avgRate * (occupancy / 100),
      ),
    )
    const occupancyPercentage = grossAvgRate / sum(avgRate)

    const cashflowSTR = acquisition.analytics.STR.cashFlowSTR / 12
    const cashflowSTRNoFinance = cashflowSTR + mortgagePayment

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const percentageFormat = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
    })

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'

    return [
      {
        text: ['Financial ', { text: 'Returns', color: '#3399ff' }],
        fontSize: 18,
        margin: [0, 5, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
          {
            type: 'rect',
            x: 2,
            y: 27,
            w: 175,
            h: 55,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 0,
            y: 25,
            w: 175,
            h: 55,
            r: 2,
            color: '#3399ff',
            fillOpacity: 1,
          },
          {
            type: 'rect',
            x: 192,
            y: 27,
            w: 175,
            h: 55,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 190,
            y: 25,
            w: 175,
            h: 55,
            r: 2,
            color: '#344265',
            fillOpacity: 1,
          },
          {
            type: 'rect',
            x: 2,
            y: 92,
            w: 365,
            h: 92,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 0,
            y: 90,
            w: 365,
            h: 92,
            r: 2,
          },
          {
            type: 'rect',
            x: 0,
            y: 90,
            w: 365,
            h: 92,
            r: 2,
            border: [true, true, true, true],
            color: 'white',
            fillOpacity: 1,
          },
          // {
          //   type: 'rect',
          //   x: 192,
          //   y: 92,
          //   w: 175,
          //   h: 92,
          //   r: 2,
          //   color: 'black',
          //   fillOpacity: 0.5,
          // },
          // {
          //   type: 'rect',
          //   x: 190,
          //   y: 90,
          //   w: 175,
          //   h: 92,
          //   r: 2,
          // },
          // {
          //   type: 'rect',
          //   x: 190,
          //   y: 90,
          //   w: 175,
          //   h: 92,
          //   r: 2,
          //   border: [true, true, true, true],
          //   color: 'white',
          //   fillOpacity: 1,
          // },
          // {
          //   type: 'rect',
          //   x: 185,
          //   y: 110,
          //   w: 85,
          //   h: 50,
          //   r: 2,
          // },
          // {
          //   type: 'rect',
          //   x: 275,
          //   y: 110,
          //   w: 85,
          //   h: 50,
          //   r: 2,
          // },
        ],
      },
      {
        absolutePosition: { x: 220, y: 360 },
        stack: [
          { text: 'Financing', fontSize: 15, color: 'white' },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1.5,
                color: 'white',
              },
            ],
          },
          {
            text: [
              'Cash Flow/mo: ',
              {
                text: currencyFormater.format(cashflowSTR),
                fontSize: 14,
                bold: true,
              },
            ],
            color: 'white',
            margin: [0, 7, 0, 0],
          },
          //{ text: 'Total ROI: ' + totalROIFinancing1.toFixed(2) + '%', color: 'white' },
        ],
      },
      {
        absolutePosition: { x: 410, y: 360 },
        stack: [
          { text: 'Cash', fontSize: 15, color: 'white' },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1.5,
                color: 'white',
              },
            ],
          },
          {
            text: [
              'Cash Flow/mo: ',
              {
                text: currencyFormater.format(cashflowSTRNoFinance),
                fontSize: 14,
                bold: true,
              },
            ],
            color: 'white',
            margin: [0, 7, 0, 0],
          },
          //{ text: 'Total ROI: ' + totalROINoFinancing1.toFixed(2) + '%', color: 'white' },
        ],
      },
      {
        absolutePosition: { x: 220, y: 425 },
        stack: [
          {
            text: ['Short Term Rental'],
            fontSize: 16,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 446 },
        stack: [
          {
            text: [
              'Net Cash Flow:  ',
              {
                text: currencyFormater.format(cashflowSTR),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 462 },
        stack: [
          {
            text: [
              'Cash on Cash:   ',
              {
                text: cashOnCash + '%',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 478 },
        stack: [
          {
            text: [
              'IRR:  ',
              {
                text: IRR + '%',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 494 },
        stack: [
          {
            text: [
              'Yield-on-Cost:   ',
              {
                text: CAPRate + '%',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      // {
      //   absolutePosition: { x: 410, y: 425 },
      //   stack: [
      //     {
      //       text: ['Fixxx & Flip'],
      //       fontSize: 16,
      //       alignment: 'left',
      //     },
      //   ],
      // },
      // {
      //   absolutePosition: { x: 410, y: 446 },
      //   stack: [
      //     {
      //       text: [
      //         'Net ROI:  ',
      //         {
      //           text: netROI + '%',
      //           fontSize: 14,
      //           bold: true,
      //         },
      //       ],
      //       fontSize: 13,
      //       alignment: 'left',
      //     },
      //   ],
      // },
      // {
      //   absolutePosition: { x: 410, y: 462 },
      //   stack: [
      //     {
      //       text: [
      //         '% of ARV:   ',
      //         {
      //           text: percentOfARV.toFixed(2) + '%',
      //           fontSize: 14,
      //           bold: true,
      //         },
      //       ],
      //       fontSize: 13,
      //       alignment: 'left',
      //     },
      //   ],
      // },
      // {
      //   absolutePosition: { x: 410, y: 478 },
      //   stack: [
      //     {
      //       text: [
      //         'Holding Time:   ',
      //         {
      //           text: holdingPeriod + ' mo.',
      //           fontSize: 14,
      //           bold: true,
      //         },
      //       ],
      //       fontSize: 13,
      //       alignment: 'left',
      //     },
      //   ],
      // },
      // {
      //   absolutePosition: { x: 410, y: 494 },
      //   stack: [
      //     {
      //       text: [
      //         'Equity:   ',
      //         {
      //           text: currencyFormater.format(equity),
      //           fontSize: 14,
      //           bold: true,
      //         },
      //       ],
      //       fontSize: 13,
      //       alignment: 'left',
      //     },
      //   ],
      // },

      {
        text: [
          'Property ',
          { text: 'Information', color: '#3399ff' },
        ],
        fontSize: 18,
        margin: [0, 20, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
        ],
      },
      {
        columns: [
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              {
                text: [
                  'Beds:   ',
                  { text: sum(beds), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Baths:   ',
                  { text: sum(baths), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Sq Feet:   ',
                  {
                    text: sqftString,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              // {
              //   text: 'Parking: ',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Lot Size: ',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
            ],
            margin: [0, 10, 0, 0],
            width: 140,
          },
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              // {
              //   text: 'Rent Range:   ' + currencyFormater.format(rentAmount),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              {
                text: [
                  'Year:   ',
                  {
                    text: property.year,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Avg Daily Rate:   ',
                  {
                    text: currencyFormater.format(sum(avgRate)),
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Occupancy Rate:   ',
                  {
                    text: percentageFormat.format(
                      occupancyPercentage,
                    ),
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              // {
              //   text: [
              //     'Acquisition Fee:   ',
              //     {
              //       text: currencyFormater.format(
              //         acquisitionFee,
              //       ),
              //       fontSize: 14,
              //       bold: true,
              //     },
              //   ],
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },

              // {
              //   text: 'Net ROI:   ' + netROI + '%',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: '% of ARV:   ' + percentOfARV.toFixed(2) + '%',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Flip Holding Period:   ' + holdingPeriod + ' mo.',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Sale Proceeds:   ' + currencyFormater.format(saleProceeds),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },

              // {
              //   text: 'Property Taxes:   ' + currencyFormater.format(propertyTaxes),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Home Insurance:   ' + currencyFormater.format(homeInsurance),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Annual HOA:   ' + currencyFormater.format(yearlyHOA),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Property Mgmt (' + propertyMgmtPercent + '%):   ' + currencyFormater.format(propertyMgmt),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              //   pageBreak: 'after'
              // },
            ],
            margin: [0, 10, 0, 0],
            width: 'auto',
          },
        ],
      },
      {
        text: proformaDescription,
        margin: [0, 10, 205, 0],
        fontSize: proformaDescription
          ? proformaDescription.length + proformaDescriptionLines >
            280
            ? proformaDescription.length + proformaDescriptionLines >
              350
              ? proformaDescription.length +
                  proformaDescriptionLines >
                500
                ? proformaDescription.length +
                    proformaDescriptionLines >
                  1350
                  ? 6.5
                  : 8
                : 10
              : 12
            : 14
          : 14,
      },
      {
        absolutePosition: { x: 5, y: 765 },
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 580,
            y2: 5,
            lineWidth: 2,
          },
        ],
        margin: [0, 0, 0, 0],
      },
      await defaultFooter(property),
    ]
  }

  const southernImpressionsPage1LowerRight = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    proformaDescription = proformaDescription
      ? trim(proformaDescription)
      : description

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    const IRR = acquisition.analytics.turnkey.IRRTurnkey
      ? (acquisition.analytics.turnkey.IRRTurnkey * 100).toFixed(2)
      : 0
    const CAPRate = acquisition.analytics.turnkey.capRateTurnkey
      ? (acquisition.analytics.turnkey.capRateTurnkey * 100).toFixed(
          2,
        )
      : 0
    const cashOnCash = acquisition.analytics.turnkey
      .cashOnCashReturnTurnkey
      ? (
          acquisition.analytics.turnkey.cashOnCashReturnTurnkey * 100
        ).toFixed(2)
      : 0
    const equity = acquisition.analytics.turnkey.equityTurnkey
      ? acquisition.analytics.turnkey.equityTurnkey
      : 0
    const mortgagePayment = acquisition.analytics.turnkey
      .mortgageTurnkey
      ? acquisition.analytics.turnkey.mortgageTurnkey
      : 0
    const financeAmount = acquisition.analytics.turnkey
      .loanAmountTurnkey
      ? acquisition.analytics.turnkey.loanAmountTurnkey
      : 0
    const allInCost = acquisition.analytics.turnkey.allInCostTurnkey
      ? acquisition.analytics.turnkey.allInCostTurnkey
      : 0
    const debtCoverageRatio = acquisition.analytics.turnkey
      .debtCoverageRatioTurnkey
      ? acquisition.analytics.turnkey.debtCoverageRatioTurnkey.toFixed(
          2,
        )
      : 0
    const utilities = acquisition.analytics.turnkey
      .utilitiesTypeDollar
      ? acquisition.analytics.turnkey.utilitiesTypeDollar
      : 0
    const assetManagementFeeTotal = acquisition.analytics.turnkey
      .assetManagementFeeTotalTurnkey
      ? acquisition.analytics.turnkey.assetManagementFeeTotalTurnkey
      : 0
    const dispositionFee = acquisition.analytics.turnkey
      .dispositionFeeDollarTurnkey
      ? acquisition.analytics.turnkey.dispositionFeeDollarTurnkey
      : 0
    const salesCommission = acquisition.analytics.fixFlip
      .salesCommissionTotalTurnkey
      ? acquisition.analytics.fixFlip.salesCommissionTotalTurnkey
      : 0
    const salePrice = acquisition.analysis.resellPriceTurnkey
      ? acquisition.analysis.resellPriceTurnkey
      : acquisition.analytics.turnkey.arvTotalTurnkey || 0
    const paydownMonthly = acquisition.analytics.turnkey
      .paydownMonthlyTurnkey
      ? acquisition.analytics.turnkey.paydownMonthlyTurnkey
      : 0

    const homeInsurance = acquisition.analytics.turnkey
      .propertyInsuranceDollarTurnkey
      ? acquisition.analytics.turnkey.propertyInsuranceDollarTurnkey
      : 0
    const maintenanceCost = acquisition.analytics.turnkey
      .maintenanceCostTurnkey
      ? acquisition.analytics.turnkey.maintenanceCostTurnkey
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange' //SPECIFIC COMMENT: Southern Impressions calculate range with rentHigh value not average of low and high
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => high,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const otherIncome = acquisition.analysis.otherIncome
      ? acquisition.analysis.otherIncome
      : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.turnkey.hoaTotal
      ? acquisition.analytics.turnkey.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.turnkey
      .arvTotalTurnkey
      ? acquisition.analytics.turnkey.arvTotalTurnkey
      : 0
    // const rehabCost = acquisition.analysis.endRehabCost
    //   ? acquisition.analysis.endRehabCost
    //   : 0
    const rehabCost = acquisition.analytics.turnkey.corTotalTurnkey
      ? acquisition.analytics.turnkey.corTotalTurnkey
      : 0
    // const sellPrice = acquisition.analysis.sellPrice
    //   ? acquisition.analysis.afterRepairValue
    //   : 0
    const downPayment = assumptions.downPaymentTurnkey
      ? assumptions.downPaymentTurnkey
      : 0
    const loanType = assumptions.interestOnlyLoanTurnkey
      ? 'Interest Only'
      : 'Not Interest Only'
    const term = assumptions.termTurnkey ? assumptions.termTurnkey : 0
    const termType = assumptions.termTurnkeyType
      ? assumptions.termTurnkeyType
      : 'Months'
    const rate = assumptions.rateTurnkey ? assumptions.rateTurnkey : 0
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceTurnkey
      ? assumptions.maintenanceTurnkey
      : 0
    const propertyMgmtPercent = acquisition.analytics.turnkey
      .propertyManagementPercentageTurnkey
      ? acquisition.analytics.turnkey
          .propertyManagementPercentageTurnkey
      : 0
    const holdingPeriod = assumptions.rentalHoldingPeriodTurnkey
      ? assumptions.rentalHoldingPeriodTurnkey
      : 0

    const otherExpenses = assumptions.otherExpenseTurnkey
      ? assumptions.otherExpenseTurnkey
      : 0
    const ownerReserves = assumptions.ownerReservesTurnkey
      ? assumptions.ownerReservesTurnkey
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    const percentOfARV = (allInCost / afterRepairValue) * 100

    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly

    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12

    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const totalReturnFinancingMonthly =
      NOINoFinanceMonthly - mortgagePayment
    const totalReturnNoFinancingMonthly = NOINoFinanceMonthly

    const monthlyRate = rate / 12 / 100

    const loanBalanceAtHoldingPeriod = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: holdingPeriod,
      paydownMonthly,
      holdingPeriod: true,
    })

    const totalInitialInvestmentFinancing = acquisition.analytics
      .turnkey.totalOutOfPocketTurnkey
      ? acquisition.analytics.turnkey.totalOutOfPocketTurnkey
      : 0

    const saleProceeds =
      salePrice -
      salesCommission -
      //sellingClosingCostDollar -
      loanBalanceAtHoldingPeriod -
      totalInitialInvestmentFinancing -
      dispositionFee

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'

    return [
      {
        canvas: [
          {
            type: 'rect',
            x: 2,
            y: 27,
            w: 175,
            h: 107,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 0,
            y: 25,
            w: 175,
            h: 107,
            r: 2,
          },
          {
            type: 'rect',
            x: 0,
            y: 25,
            w: 175,
            h: 107,
            r: 2,
            border: [true, true, true, true],
            color: 'white',
            fillOpacity: 1,
          },
          {
            type: 'rect',
            x: 192,
            y: 27,
            w: 175,
            h: 107,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 190,
            y: 25,
            w: 175,
            h: 107,
            r: 2,
          },
          {
            type: 'rect',
            x: 190,
            y: 25,
            w: 175,
            h: 107,
            r: 2,
            border: [true, true, true, true],
            color: 'white',
            fillOpacity: 1,
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 330 },
        stack: [
          {
            text: [
              'Financial ',
              { text: 'Returns', color: '#3399ff' },
            ],
            fontSize: 16,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 355 },
        stack: [
          {
            text: [
              'IRR:  ',
              {
                text: IRR + '%',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 370 },
        stack: [
          {
            text: [
              'CAP Rate:   ',
              {
                text: CAPRate + '%',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 385 },
        stack: [
          {
            text: [
              'Cash on Cash:   ',
              {
                text: cashOnCash + '%',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 400 },
        stack: [
          {
            text: [
              'Sale Proceeds:   ',
              {
                text: currencyFormater.format(saleProceeds),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 415 },
        stack: [
          {
            text: [
              'DSCR:   ',
              {
                text: debtCoverageRatio + 'x',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },

      {
        absolutePosition: { x: 410, y: 330 },
        stack: [
          {
            text: [
              'Mortgage ',
              { text: 'Infomation', color: '#3399ff' },
            ],
            fontSize: 16,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 410, y: 355 },
        stack: [
          {
            text: [
              'Loan Amount:  ',
              {
                text: currencyFormater.format(financeAmount),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 410, y: 370 },
        stack: [
          {
            text: [
              'Mortgage Payment:   ',
              {
                text: currencyFormater.format(mortgagePayment),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 410, y: 385 },
        stack: [
          {
            text: [
              'Loan Type: ',
              {
                text: loanType,
                fontSize: 13,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 410, y: 400 },
        stack: [
          {
            text: [
              'Term:   ',
              {
                text: term + ' ' + termType,
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 410, y: 415 },
        stack: [
          {
            text: [
              'Interest Rate:   ',
              {
                text: rate + '%',
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },

      {
        text: [
          'Property ',
          { text: 'Information', color: '#3399ff' },
        ],
        fontSize: 18,
        margin: [0, 20, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
        ],
      },
      {
        columns: [
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              {
                text: [
                  'Beds:   ',
                  { text: sum(beds), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Baths:   ',
                  { text: sum(baths), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Sq Feet:   ',
                  {
                    text: sqftString,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              // {
              //   text: 'Parking: ',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Lot Size: ',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
            ],
            margin: [0, 10, 0, 0],
            width: 140,
          },
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              // {
              //   text: 'Rent Range:   ' + currencyFormater.format(rentAmount),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              {
                text: [
                  'Year:   ',
                  {
                    text: property.year,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Monthly Rent:   ',
                  {
                    text: currencyFormater.format(sum(rentAmount)),
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              // {
              //   text: [
              //     'Acquisition Fee:   ',
              //     {
              //       text: currencyFormater.format(
              //         acquisitionFee,
              //       ),
              //       fontSize: 14,
              //       bold: true,
              //     },
              //   ],
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },

              // {
              //   text: 'Net ROI:   ' + netROI + '%',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: '% of ARV:   ' + percentOfARV.toFixed(2) + '%',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Flip Holding Period:   ' + holdingPeriod + ' mo.',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Sale Proceeds:   ' + currencyFormater.format(saleProceeds),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },

              // {
              //   text: 'Property Taxes:   ' + currencyFormater.format(propertyTaxes),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Home Insurance:   ' + currencyFormater.format(homeInsurance),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Annual HOA:   ' + currencyFormater.format(yearlyHOA),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Property Mgmt (' + propertyMgmtPercent + '%):   ' + currencyFormater.format(propertyMgmt),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              //   pageBreak: 'after'
              // },
            ],
            margin: [0, 10, 0, 0],
            width: 'auto',
          },
        ],
      },
      {
        text: proformaDescription,
        margin: [0, 10, 205, 0],
        fontSize: proformaDescription
          ? proformaDescription.length + proformaDescriptionLines >
            280
            ? proformaDescription.length + proformaDescriptionLines >
              350
              ? proformaDescription.length +
                  proformaDescriptionLines >
                500
                ? proformaDescription.length +
                    proformaDescriptionLines >
                  1350
                  ? 6.5
                  : 8
                : 10
              : 12
            : 14
          : 14,
      },
      {
        absolutePosition: { x: 5, y: 765 },
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 580,
            y2: 5,
            lineWidth: 2,
          },
        ],
        margin: [0, 0, 0, 0],
      },
      await defaultFooter(property),
    ]
  }

  const wholesalePage1LowerRight = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    proformaDescription = proformaDescription
      ? trim(proformaDescription)
      : description

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    const pepMaxOfferPrice =
      (acquisition.analytics.wholesale.arvTotalWholesale *
        assumptions.percentageOfARVWholesale) /
        100 -
      acquisition.analytics.wholesale.rehabDollar
    const buyerProfit = acquisition.analytics.wholesale
      .arvTotalWholesale
      ? acquisition.analytics.wholesale.arvTotalWholesale -
        (pepMaxOfferPrice +
          acquisition.analytics.wholesale.assignmentFeeDollar +
          acquisition.analytics.wholesale.rehabDollar +
          acquisition.analytics.wholesale.arvTotalWholesale * 0.16)
      : 0

    const saleProceeds = acquisition.analytics.wholesale
      .assignmentFeeDollar
      ? acquisition.analytics.wholesale.assignmentFeeDollar
      : 0

    const afterRepairValue =
      acquisition.analytics.wholesale.arvTotalWholesale || 0
    const rehab = acquisition.analytics.wholesale.rehabDollar || 0
    const closingCost =
      acquisition.analytics.wholesale.closingCostWholesaleDollar || 0
    const offerPrice =
      acquisition.analysis.offerPrice ||
      acquisition.analysis.listPrice ||
      0

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'

    return [
      {
        text: ['Financial ', { text: 'Returns', color: '#3399ff' }],
        fontSize: 18,
        margin: [0, 5, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
          {
            type: 'rect',
            x: 2,
            y: 27,
            w: 175,
            h: 55,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 0,
            y: 25,
            w: 175,
            h: 55,
            r: 2,
            color: '#3399ff',
            fillOpacity: 1,
          },
          {
            type: 'rect',
            x: 192,
            y: 27,
            w: 175,
            h: 55,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 190,
            y: 25,
            w: 175,
            h: 55,
            r: 2,
            color: '#344265',
            fillOpacity: 1,
          },
          {
            type: 'rect',
            x: 2,
            y: 92,
            w: 365,
            h: 92,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 0,
            y: 90,
            w: 365,
            h: 92,
            r: 2,
          },
          {
            type: 'rect',
            x: 0,
            y: 90,
            w: 365,
            h: 92,
            r: 2,
            border: [true, true, true, true],
            color: 'white',
            fillOpacity: 1,
          },
          // {
          //   type: 'rect',
          //   x: 192,
          //   y: 92,
          //   w: 175,
          //   h: 92,
          //   r: 2,
          //   color: 'black',
          //   fillOpacity: 0.5,
          // },
          // {
          //   type: 'rect',
          //   x: 190,
          //   y: 90,
          //   w: 175,
          //   h: 92,
          //   r: 2,
          // },
          // {
          //   type: 'rect',
          //   x: 190,
          //   y: 90,
          //   w: 175,
          //   h: 92,
          //   r: 2,
          //   border: [true, true, true, true],
          //   color: 'white',
          //   fillOpacity: 1,
          // },
          // {
          //   type: 'rect',
          //   x: 185,
          //   y: 110,
          //   w: 85,
          //   h: 50,
          //   r: 2,
          // },
          // {
          //   type: 'rect',
          //   x: 275,
          //   y: 110,
          //   w: 85,
          //   h: 50,
          //   r: 2,
          // },
        ],
      },
      {
        absolutePosition: { x: 220, y: 360 },
        stack: [
          { text: 'Net Profit', fontSize: 15, color: 'white' },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1.5,
                color: 'white',
              },
            ],
          },
          {
            text: [
              {
                text: currencyFormater.format(saleProceeds),
                fontSize: 14,
                bold: true,
              },
            ],
            color: 'white',
            margin: [0, 7, 0, 0],
          },
          //{ text: 'Total ROI: ' + totalROIFinancing1.toFixed(2) + '%', color: 'white' },
        ],
      },
      {
        absolutePosition: { x: 410, y: 360 },
        stack: [
          { text: 'Buyer Profit', fontSize: 15, color: 'white' },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1.5,
                color: 'white',
              },
            ],
          },
          {
            text: [
              {
                text: currencyFormater.format(buyerProfit),
                fontSize: 14,
                bold: true,
              },
            ],
            color: 'white',
            margin: [0, 7, 0, 0],
          },
          //{ text: 'Total ROI: ' + totalROINoFinancing1.toFixed(2) + '%', color: 'white' },
        ],
      },
      {
        absolutePosition: { x: 220, y: 425 },
        stack: [
          {
            text: [
              getStrategyName(
                'Wholesale',
                props?.session?.me?.activeOrg?.member,
              ),
            ],
            fontSize: 16,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 446 },
        stack: [
          {
            text: [
              'ARV:   ',
              {
                text: currencyFormater.format(afterRepairValue),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 462 },
        stack: [
          {
            text: [
              'Rehab:   ',
              {
                text: currencyFormater.format(rehab),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 478 },
        stack: [
          {
            text: [
              'Closing Cost:  ',
              {
                text: currencyFormater.format(closingCost),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 494 },
        stack: [
          {
            text: [
              'Resale Price:   ',
              {
                text: currencyFormater.format(pepMaxOfferPrice),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        text: [
          'Property ',
          { text: 'Information', color: '#3399ff' },
        ],
        fontSize: 18,
        margin: [0, 20, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
        ],
      },
      {
        columns: [
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              {
                text: [
                  'Beds:   ',
                  { text: sum(beds), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Baths:   ',
                  { text: sum(baths), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              // {
              //   text: [
              //     'Sq Feet:   ',
              //     {
              //       text: sqftString,
              //       fontSize: 14,
              //       bold: true,
              //     },
              //   ],
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
            ],
            margin: [0, 10, 0, 0],
            width: 140,
          },
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              {
                text: [
                  'Sq Feet:   ',
                  {
                    text: sqftString,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Year:   ',
                  {
                    text: property.year,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
            ],
            margin: [0, 10, 0, 0],
            width: 'auto',
          },
        ],
      },
      {
        text: proformaDescription,
        margin: [0, 10, 205, 0],
        fontSize: proformaDescription
          ? proformaDescription.length + proformaDescriptionLines >
            280
            ? proformaDescription.length + proformaDescriptionLines >
              350
              ? proformaDescription.length +
                  proformaDescriptionLines >
                500
                ? proformaDescription.length +
                    proformaDescriptionLines >
                  1350
                  ? 6.5
                  : 8
                : 10
              : 12
            : 14
          : 14,
      },
      {
        absolutePosition: { x: 5, y: 765 },
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 580,
            y2: 5,
            lineWidth: 2,
          },
        ],
        margin: [0, 0, 0, 0],
      },
      await defaultFooter(property),
    ]
  }

  const astroflipPage1LowerRight = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    proformaDescription = proformaDescription
      ? trim(proformaDescription)
      : description

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    const buyerProfit = acquisition.analytics.astroFlip
      .buyerProfitTotalAstroFlip
      ? acquisition.analytics.astroFlip.buyerProfitTotalAstroFlip
      : 0

    const saleProceeds = acquisition.analytics.astroFlip
      .saleProceedsAstroFlip
      ? acquisition.analytics.astroFlip.saleProceedsAstroFlip
      : 0

    const dispoPrice = acquisition.analytics.astroFlip
      .dispoPriceAstroFlip
      ? acquisition.analytics.astroFlip.dispoPriceAstroFlip
      : 0

    const buyPrice = acquisition.analytics.astroFlip.buyPriceAstroFlip
      ? acquisition.analytics.astroFlip.buyPriceAstroFlip
      : 0

    const afterRepairValue =
      acquisition.analytics.astroFlip.arvTotalAstroFlip || 0
    const rehab =
      acquisition.analytics.astroFlip.rehabDollarAstroFlip || 0
    const closingCost =
      acquisition.analytics.astroFlip.closingCostAstroFlipDollar || 0
    const offerPrice =
      acquisition.analysis.offerPrice ||
      acquisition.analysis.listPrice ||
      0

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'

    return [
      {
        text: ['Financial ', { text: 'Returns', color: '#3399ff' }],
        fontSize: 18,
        margin: [0, 5, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
          {
            type: 'rect',
            x: 2,
            y: 27,
            w: 175,
            h: 55,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 0,
            y: 25,
            w: 175,
            h: 55,
            r: 2,
            color: '#3399ff',
            fillOpacity: 1,
          },
          {
            type: 'rect',
            x: 192,
            y: 27,
            w: 175,
            h: 55,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 190,
            y: 25,
            w: 175,
            h: 55,
            r: 2,
            color: '#344265',
            fillOpacity: 1,
          },
          {
            type: 'rect',
            x: 2,
            y: 92,
            w: 365,
            h: 110,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          {
            type: 'rect',
            x: 0,
            y: 90,
            w: 365,
            h: 110,
            r: 2,
          },
          {
            type: 'rect',
            x: 0,
            y: 90,
            w: 365,
            h: 110,
            r: 2,
            border: [true, true, true, true],
            color: 'white',
            fillOpacity: 1,
          },
          // {
          //   type: 'rect',
          //   x: 192,
          //   y: 92,
          //   w: 175,
          //   h: 92,
          //   r: 2,
          //   color: 'black',
          //   fillOpacity: 0.5,
          // },
          // {
          //   type: 'rect',
          //   x: 190,
          //   y: 90,
          //   w: 175,
          //   h: 92,
          //   r: 2,
          // },
          // {
          //   type: 'rect',
          //   x: 190,
          //   y: 90,
          //   w: 175,
          //   h: 92,
          //   r: 2,
          //   border: [true, true, true, true],
          //   color: 'white',
          //   fillOpacity: 1,
          // },
          // {
          //   type: 'rect',
          //   x: 185,
          //   y: 110,
          //   w: 85,
          //   h: 50,
          //   r: 2,
          // },
          // {
          //   type: 'rect',
          //   x: 275,
          //   y: 110,
          //   w: 85,
          //   h: 50,
          //   r: 2,
          // },
        ],
      },
      {
        absolutePosition: { x: 220, y: 360 },
        stack: [
          { text: 'Net Profit', fontSize: 15, color: 'white' },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1.5,
                color: 'white',
              },
            ],
          },
          {
            text: [
              {
                text: currencyFormater.format(saleProceeds),
                fontSize: 14,
                bold: true,
              },
            ],
            color: 'white',
            margin: [0, 7, 0, 0],
          },
          //{ text: 'Total ROI: ' + totalROIFinancing1.toFixed(2) + '%', color: 'white' },
        ],
      },
      {
        absolutePosition: { x: 410, y: 360 },
        stack: [
          { text: 'Buyer Profit', fontSize: 15, color: 'white' },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1.5,
                color: 'white',
              },
            ],
          },
          {
            text: [
              {
                text: currencyFormater.format(buyerProfit),
                fontSize: 14,
                bold: true,
              },
            ],
            color: 'white',
            margin: [0, 7, 0, 0],
          },
          //{ text: 'Total ROI: ' + totalROINoFinancing1.toFixed(2) + '%', color: 'white' },
        ],
      },
      {
        absolutePosition: { x: 220, y: 425 },
        stack: [
          {
            text: [getStrategyName('Astroflip', organization.member)],
            fontSize: 16,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 446 },
        stack: [
          {
            text: [
              'ARV:   ',
              {
                text: currencyFormater.format(afterRepairValue),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 462 },
        stack: [
          {
            text: [
              'Rehab:   ',
              {
                text: currencyFormater.format(rehab),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 478 },
        stack: [
          {
            text: [
              'Closing Cost:  ',
              {
                text: currencyFormater.format(closingCost),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 494 },
        stack: [
          {
            text: [
              'Buy Price:   ',
              {
                text: currencyFormater.format(buyPrice),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 510 },
        stack: [
          {
            text: [
              'Dispo Price:   ',
              {
                text: currencyFormater.format(dispoPrice),
                fontSize: 14,
                bold: true,
              },
            ],
            fontSize: 13,
            alignment: 'left',
          },
        ],
      },
      {
        text: [
          'Property ',
          { text: 'Information', color: '#3399ff' },
        ],
        fontSize: 18,
        margin: [0, 20, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
        ],
      },
      {
        columns: [
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              {
                text: [
                  'Beds:   ',
                  { text: sum(beds), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Baths:   ',
                  { text: sum(baths), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              // {
              //   text: [
              //     'Sq Feet:   ',
              //     {
              //       text: sqftString,
              //       fontSize: 14,
              //       bold: true,
              //     },
              //   ],
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
            ],
            margin: [0, 10, 0, 0],
            width: 140,
          },
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              {
                text: [
                  'Sq Feet:   ',
                  {
                    text: sqftString,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Year:   ',
                  {
                    text: property.year,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
            ],
            margin: [0, 10, 0, 0],
            width: 'auto',
          },
        ],
      },
      {
        text: proformaDescription,
        margin: [0, 10, 205, 0],
        fontSize: proformaDescription
          ? proformaDescription.length + proformaDescriptionLines >
            280
            ? proformaDescription.length + proformaDescriptionLines >
              350
              ? proformaDescription.length +
                  proformaDescriptionLines >
                500
                ? proformaDescription.length +
                    proformaDescriptionLines >
                  1350
                  ? 6.5
                  : 8
                : 10
              : 12
            : 14
          : 14,
      },
      {
        absolutePosition: { x: 5, y: 765 },
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 580,
            y2: 5,
            lineWidth: 2,
          },
        ],
        margin: [0, 0, 0, 0],
      },
      await defaultFooter(property),
    ]
  }

  const defaultMarketingPage1LowerRight = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    proformaDescription = proformaDescription
      ? trim(proformaDescription)
      : description

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    const IRR = acquisition.analytics.turnkey.IRRTurnkey
      ? (acquisition.analytics.turnkey.IRRTurnkey * 100).toFixed(2)
      : 0
    const CAPRate = acquisition.analytics.turnkey.capRateTurnkey
      ? (acquisition.analytics.turnkey.capRateTurnkey * 100).toFixed(
          2,
        )
      : 0
    const cashOnCash = acquisition.analytics.turnkey
      .cashOnCashReturnTurnkey
      ? (
          acquisition.analytics.turnkey.cashOnCashReturnTurnkey * 100
        ).toFixed(2)
      : 0
    const equity = acquisition.analytics.turnkey.equityTurnkey
      ? acquisition.analytics.turnkey.equityTurnkey
      : 0
    const mortgagePayment = acquisition.analytics.turnkey
      .mortgageTurnkey
      ? acquisition.analytics.turnkey.mortgageTurnkey
      : 0
    const utilities = acquisition.analytics.turnkey
      .utilitiesTypeDollarTurnkey
      ? acquisition.analytics.turnkey.utilitiesTypeDollarTurnkey
      : 0
    const assetManagementFeeTotal = acquisition.analytics.turnkey
      .assetManagementFeeTotalTurnkey
      ? acquisition.analytics.turnkey.assetManagementFeeTotalTurnkey
      : 0
    const homeInsurance = acquisition.analytics.turnkey
      .propertyInsuranceDollarTurnkey
      ? acquisition.analytics.turnkey.propertyInsuranceDollarTurnkey
      : 0
    const maintenanceCost = acquisition.analytics.turnkey
      .maintenanceCostTurnkey
      ? acquisition.analytics.turnkey.maintenanceCostTurnkey
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange'
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const otherIncome = acquisition.analysis.otherIncome
      ? acquisition.analysis.otherIncome
      : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.turnkey.hoaTotal
      ? acquisition.analytics.turnkey.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.turnkey
      .arvTotalTurnkey
      ? acquisition.analytics.turnkey.arvTotalTurnkey
      : 0

    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceTurnkey
      ? assumptions.maintenanceTurnkey
      : 0
    const propertyMgmtPercent = acquisition.analytics.turnkey
      .propertyManagementPercentageTurnkey
      ? acquisition.analytics.turnkey
          .propertyManagementPercentageTurnkey
      : 0
    const otherExpenses = assumptions.otherExpenseTurnkey
      ? assumptions.otherExpenseTurnkey
      : 0
    const ownerReserves = assumptions.ownerReservesTurnkey
      ? assumptions.ownerReservesTurnkey
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    const yearlyHOA = monthlyHOA * 12
    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly

    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const totalReturnFinancingMonthly =
      NOINoFinanceMonthly - mortgagePayment
    const totalReturnNoFinancingMonthly = NOINoFinanceMonthly

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'
    return [
      {
        text: ['Financial ', { text: 'Returns', color: '#3399ff' }],
        fontSize: 18,
        margin: [0, 5, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
          // FINANCING DROP SHADOW
          {
            type: 'rect',
            x: 2,
            y: 27,
            w: 175,
            h: 55,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          // FINANCING BLUE CANVAS
          {
            type: 'rect',
            x: 0,
            y: 25,
            w: 175,
            h: 55,
            r: 2,
            color: '#3399ff',
            fillOpacity: 1,
          },
          // CASH DROP SHADOW
          {
            type: 'rect',
            x: 192,
            y: 27,
            w: 175,
            h: 55,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          // CASH DARK BLUE CANVAS
          {
            type: 'rect',
            x: 190,
            y: 25,
            w: 175,
            h: 55,
            r: 2,
            color: '#344265',
            fillOpacity: 1,
          },
          // GROSS YIELD DROP SHADOW
          {
            type: 'rect',
            x: 7,
            y: 92,
            w: 85,
            h: 50,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          // GROSS YIELD WHITE CANVAS
          {
            type: 'rect',
            x: 5,
            y: 90,
            w: 85,
            h: 50,
            r: 2,
          },
          // GROSS YIELD CANVAS BORDER
          {
            type: 'rect',
            x: 5,
            y: 90,
            w: 85,
            h: 50,
            r: 2,
            border: [true, true, true, true],
            color: 'white',
            fillOpacity: 1,
          },
          // CAP RATE DROP SHADOW
          {
            type: 'rect',
            x: 97,
            y: 92,
            w: 85,
            h: 50,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          // CAP RATE WHITE CANVAS
          {
            type: 'rect',
            x: 95,
            y: 90,
            w: 85,
            h: 50,
            r: 2,
          },
          // CAP RATE CANVAS BORDER
          {
            type: 'rect',
            x: 95,
            y: 90,
            w: 85,
            h: 50,
            r: 2,
            border: [true, true, true, true],
            color: 'white',
            fillOpacity: 1,
          },
          // LEVERAGE RATE DROP SHADOW
          {
            type: 'rect',
            x: 187,
            y: 92,
            w: 85,
            h: 50,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          // LEVERAGE RATE WHITE CANVAS
          {
            type: 'rect',
            x: 185,
            y: 90,
            w: 85,
            h: 50,
            r: 2,
          },
          // LEVERAGE RATE CANVAS BORDER
          {
            type: 'rect',
            x: 185,
            y: 90,
            w: 85,
            h: 50,
            r: 2,
            border: [true, true, true, true],
            color: 'white',
            fillOpacity: 1,
          },
          // EQUITY DROP SHADOW
          {
            type: 'rect',
            x: 277,
            y: 92,
            w: 85,
            h: 50,
            r: 2,
            color: 'black',
            fillOpacity: 0.5,
          },
          // EQUITY WHITE CANVAS
          {
            type: 'rect',
            x: 275,
            y: 90,
            w: 85,
            h: 50,
            r: 2,
          },
          // EQUITY CANVAS BORDER
          {
            type: 'rect',
            x: 275,
            y: 90,
            w: 85,
            h: 50,
            r: 2,
            border: [true, true, true, true],
            color: 'white',
            fillOpacity: 1,
          },
        ],
      },
      {
        absolutePosition: { x: 220, y: 360 },
        stack: [
          { text: 'Financing', fontSize: 15, color: 'white' },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1.5,
                color: 'white',
              },
            ],
          },
          {
            text: [
              'Cash Flow/mo:   ',
              {
                text: currencyFormater.format(
                  totalReturnFinancingMonthly,
                ),
                fontSize: 14,
                bold: true,
              },
            ],
            color: 'white',
            margin: [0, 10, 0, 0],
          },
          //{ text: 'Total ROI: ' + totalROIFinancing1.toFixed(2) + '%', color: 'white' },
        ],
      },
      {
        absolutePosition: { x: 410, y: 360 },
        stack: [
          { text: 'Cash', fontSize: 15, color: 'white' },
          {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 100,
                y2: 0,
                lineWidth: 1.5,
                color: 'white',
              },
            ],
          },
          {
            text: [
              'Cash Flow/mo:   ',
              {
                text: currencyFormater.format(
                  totalReturnNoFinancingMonthly,
                ),
                fontSize: 14,
                bold: true,
              },
            ],
            color: 'white',
            margin: [0, 10, 0, 0],
          },
          //{ text: 'Total ROI: ' + totalROINoFinancing1.toFixed(2) + '%', color: 'white' },
        ],
      },
      IRR !== 0
        ? {
            absolutePosition: { x: -70, y: 430 },
            stack: [
              {
                text: IRR + '%',
                fontSize: 14,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'IRR',
                fontSize: 13,
                alignment: 'center',
              },
            ],
          }
        : {
            absolutePosition: { x: -70, y: 430 },
            stack: [
              {
                text: IRR + '%',
                fontSize: 14,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'IRR',
                fontSize: 13,
                alignment: 'center',
              },
            ],
          },
      CAPRate !== 0
        ? {
            absolutePosition: { x: 115, y: 430 },
            stack: [
              {
                text: CAPRate + '%',
                fontSize: 14,
                bold: true,
                alignment: 'center',
              },
              {
                text: 'CAP Rate',
                fontSize: 13,
                alignment: 'center',
              },
            ],
          }
        : {
            absolutePosition: { x: 115, y: 430 },
            stack: [
              {
                text: '-',
                fontSize: 14,
                bold: true,
                alignment: 'center',
              },
              {
                text: 'CAP Rate',
                fontSize: 13,
                alignment: 'center',
              },
            ],
          },
      cashOnCash !== 0
        ? {
            absolutePosition: { x: 290, y: 430 },
            stack: [
              {
                text: cashOnCash + '%',
                fontSize: 14,
                bold: true,
                alignment: 'center',
              },
              {
                text: 'Cash On Cash',
                fontSize: 13,
                alignment: 'center',
              },
            ],
          }
        : {
            absolutePosition: { x: 290, y: 430 },
            stack: [
              {
                text: '-',
                fontSize: 14,
                bold: true,
                alignment: 'center',
              },
              {
                text: 'Cash On Cash',
                fontSize: 13,
                alignment: 'center',
              },
            ],
          },
      equity >= 0
        ? {
            absolutePosition: { x: 465, y: 430 },
            stack: [
              {
                text: currencyFormater.format(equity),
                fontSize: 14,
                bold: true,
                alignment: 'center',
              },
              {
                text: 'Equity',
                fontSize: 13,
                alignment: 'center',
              },
            ],
          }
        : {
            absolutePosition: { x: 465, y: 430 },
            stack: [
              {
                text: '-',
                fontSize: 14,
                bold: true,
                alignment: 'center',
              },
              {
                text: 'Equity',
                fontSize: 13,
                alignment: 'center',
              },
            ],
          },
      {
        text: [
          'Property ',
          { text: 'Information', color: '#3399ff' },
        ],
        fontSize: 18,
        margin: [0, 30, 0, 0],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 150,
            y2: 5,
            lineWidth: 2,
          },
        ],
      },
      {
        columns: [
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              {
                text: [
                  'Beds:   ',
                  { text: sum(beds), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Baths:   ',
                  { text: sum(baths), fontSize: 14, bold: true },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Sq Feet:   ',
                  {
                    text: sqftString,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              {
                text: [
                  'Year:   ',
                  {
                    text: property.year,
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              // {
              //   text: 'Parking: ',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Lot Size: ',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
            ],
            margin: [0, 10, 0, 0],
            width: 140,
          },
          {
            type: 'square',
            color: '#3399ff',
            ul: [
              // {
              //   text: 'Rent Range:   ' + currencyFormater.format(rentAmount),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              {
                text: [
                  'Monthly Rent:   ',
                  {
                    text: currencyFormater.format(sum(rentAmount)),
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              // {
              //   text: 'Net ROI:   ' + netROI + '%',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: '% of ARV:   ' + percentOfARV.toFixed(2) + '%',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Flip Holding Period:   ' + holdingPeriod + ' mo.',
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Sale Proceeds:   ' + currencyFormater.format(saleProceeds),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },

              {
                text: [
                  'Property Taxes:   ',
                  {
                    text: currencyFormater.format(propertyTaxes),
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              // {
              //   text: ['Home Insurance:   ', {text: currencyFormater.format(homeInsurance), fontSize: 14, bold: true}],
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              {
                text: [
                  'Annual HOA:   ',
                  {
                    text: currencyFormater.format(yearlyHOA),
                    fontSize: 14,
                    bold: true,
                  },
                ],
                color: 'black',
                fontSize: 14,
                margin: 5,
              },
              // {
              //   text: [
              //     'Acquisition Fee:   ',
              //     {
              //       text: currencyFormater.format(
              //         acquisitionFee,
              //       ),
              //       fontSize: 14,
              //       bold: true,
              //     },
              //   ],
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              // },
              // {
              //   text: 'Property Mgmt (' + propertyMgmtPercent + '%):   ' + currencyFormater.format(propertyMgmt),
              //   color: 'black',
              //   fontSize: 14,
              //   margin: 5,
              //   pageBreak: 'after'
              // },
            ],
            margin: [0, 10, 0, 0],
            width: 'auto',
          },
        ],
      },
      {
        text: proformaDescription,
        margin: [0, 10, 205, 0],
        fontSize: proformaDescription
          ? proformaDescription.length + proformaDescriptionLines >
            280
            ? proformaDescription.length + proformaDescriptionLines >
              350
              ? proformaDescription.length +
                  proformaDescriptionLines >
                500
                ? proformaDescription.length +
                    proformaDescriptionLines >
                  1350
                  ? 5.5
                  : 7
                : 8.5
              : 11.5
            : 14
          : 14,
      },
      {
        absolutePosition: { x: 5, y: 765 },
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 580,
            y2: 5,
            lineWidth: 2,
          },
        ],
        margin: [0, 0, 0, 0],
      },
      {
        absolutePosition: { x: 5, y: 765 },
        columns: [
          {
            table: {
              headerRows: 1,
              widths: [70, 100, '*', 180],
              body: [
                [
                  {
                    text: 'Powered By ',
                    alignment: 'left',
                    color: '#8e9194',
                    margin: [0, 10, 0, 0],
                  },
                  {
                    image: await handleImageUrl(
                      urlAffiliate
                        ? get(
                            affiliate,
                            'sidebar.sidebarMemberFullImage.0.url',
                          ) || '/images/NewLogoBlue.png'
                        : '/images/NewLogoBlue.png',
                    ),
                    width: 100,
                    height: 20,
                    margin: [-5, 7, 0, 0],
                  },
                  {
                    text: orgPhone,
                    alignment: 'right',
                    margin: [0, 10, 0, 0],
                  },
                  {
                    image: orgLogo,
                    fit: [125, 50],
                    // width: 150,
                    // height: 75,
                    margin: [0, 0, 0, 0],
                    alignment: 'center',
                    rowSpan: 2,
                  },
                ],
                [
                  {
                    text: 'Create Your Proforma at',
                    alignment: 'left',
                    margin: [0, -3, 0, 0],
                    color: '#8e9194',
                    fontSize: 8,
                    noWrap: true,
                  },
                  {
                    text: urlDomain || 'housefolios.com',
                    link:
                      'https://' +
                      (urlDomain || 'www.housefolios.com') +
                      '/',
                    alignment: 'left',
                    decoration: 'underline',
                    margin: [10, -3, 0, 0],
                    color: '#3399ff',
                    fontSize: 8,
                    //colSpan: 1,
                  },
                  {
                    text: orgEmail,
                    alignment: 'right',
                    margin: [0, -5, 0, 0],
                    fontSize:
                      orgEmail.length > 20
                        ? orgEmail.length > 30
                          ? 8
                          : 10
                        : 12,
                    // margin:
                    //   orgEmail.length > 30
                    //     ? [0, 4, 0, 0]
                    //     : [0, 0, 0, 0],
                  },
                ],
              ],
            },
            margin: [5, 10, 10, 0],
            layout: {
              defaultBorder: false,
            },
          },
        ],
      },
    ]
  }

  const southernImpressionsMarketingPage1LowerRight = async (
    property,
  ) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    proformaDescription = proformaDescription
      ? trim(proformaDescription)
      : description

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    const IRR = acquisition.analytics.turnkey.IRRTurnkey
      ? (acquisition.analytics.turnkey.IRRTurnkey * 100).toFixed(2)
      : 0
    const CAPRate = acquisition.analytics.turnkey.capRateTurnkey
      ? (acquisition.analytics.turnkey.capRateTurnkey * 100).toFixed(
          2,
        )
      : 0
    const cashOnCash = acquisition.analytics.turnkey
      .cashOnCashReturnTurnkey
      ? (
          acquisition.analytics.turnkey.cashOnCashReturnTurnkey * 100
        ).toFixed(2)
      : 0
    const equity = acquisition.analytics.turnkey.equityTurnkey
      ? acquisition.analytics.turnkey.equityTurnkey
      : 0
    const mortgagePayment = acquisition.analytics.turnkey
      .mortgageTurnkey
      ? acquisition.analytics.turnkey.mortgageTurnkey
      : 0
    const utilities = acquisition.analytics.turnkey
      .utilitiesTypeDollarTurnkey
      ? acquisition.analytics.turnkey.utilitiesTypeDollarTurnkey
      : 0
    const assetManagementFeeTotal = acquisition.analytics.turnkey
      .assetManagementFeeTotalTurnkey
      ? acquisition.analytics.turnkey.assetManagementFeeTotalTurnkey
      : 0
    const homeInsurance = acquisition.analytics.turnkey
      .propertyInsuranceDollarTurnkey
      ? acquisition.analytics.turnkey.propertyInsuranceDollarTurnkey
      : 0
    const maintenanceCost = acquisition.analytics.turnkey
      .maintenanceCostTurnkey
      ? acquisition.analytics.turnkey.maintenanceCostTurnkey
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange' //SPECIFIC COMMENT: Southern Impressions calculate range with rentHigh value not average of low and high
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => high,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const otherIncome = acquisition.analysis.otherIncome
      ? acquisition.analysis.otherIncome
      : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.turnkey.hoaTotal
      ? acquisition.analytics.turnkey.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.turnkey
      .arvTotalTurnkey
      ? acquisition.analytics.turnkey.arvTotalTurnkey
      : 0
    const offerPrice = acquisition.analysis.resellPriceTurnkey
      ? acquisition.analysis.resellPriceTurnkey
      : afterRepairValue

    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceTurnkey
      ? assumptions.maintenanceTurnkey
      : 0
    const propertyMgmtPercent = acquisition.analytics.turnkey
      .propertyManagementPercentageTurnkey
      ? acquisition.analytics.turnkey
          .propertyManagementPercentageTurnkey
      : 0
    const otherExpenses = assumptions.otherExpenseTurnkey
      ? assumptions.otherExpenseTurnkey
      : 0
    const ownerReserves = assumptions.ownerReservesTurnkey
      ? assumptions.ownerReservesTurnkey
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    const yearlyHOA = monthlyHOA * 12
    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly

    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const totalReturnFinancingMonthly =
      NOINoFinanceMonthly - mortgagePayment
    const totalReturnNoFinancingMonthly = NOINoFinanceMonthly

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const sqftString = head(sqft)
      ? head(sqft).toLocaleString('en')
      : '0'

    return [
      {
        image: orgLogo,
        fit: [350, 200],
        // width: 150,
        // height: 75,
        margin: [50, 30, 0, 10],
        alignment: 'center',
        rowSpan: 2,
      },
      {
        margin: [0, 20, 0, 10],
        alignment: 'center',
        stack: [
          {
            text: currencyFormater.format(offerPrice),
            fontSize: 34,
          },
          assumptions.rentType !== 'rentRange'
            ? {
                text: [
                  'Rent ',
                  currencyFormater.format(mean(rentAmount)),
                ],
                fontSize: 20,
              }
            : {
                text: [
                  'Rent Range ',
                  currencyFormater.format(
                    mean(acquisition.analysis.rentLow),
                  ),
                  ' - ',
                  currencyFormater.format(
                    mean(acquisition.analysis.rentHigh),
                  ),
                ],
                fontSize: 20,
              },
          assumptions.rentType === 'rentRange'
            ? {
                text: 'Per Unit',
                margin: [100, 0, 0, 0],
              }
            : null,
        ],
      },
      {
        text: 'Property Features',
        fontSize: 18,
        margin: [0, 40, 0, 0],
        alignment: 'center',
      },
      {
        columns: [
          {
            stack: [
              {
                text: [head(beds), ' Bedrooms'],
                color: 'black',
                fontSize: 16,
                margin: 5,
                margin: [5, 5, -50, 5],
              },
              {
                text: [head(baths), ' Bathrooms'],
                color: 'black',
                fontSize: 16,
                margin: [5, 5, -50, 5],
              },
            ],
            margin: [0, 5, 0, 0],
            alignment: 'right',
          },
          {
            canvas: [
              {
                type: 'rect',
                x: 70,
                y: 10,
                w: 1,
                h: 50,
                r: 0,
                color: 'black',
                fillOpacity: 0.5,
              },
            ],
          },
          {
            stack: [
              {
                text: [sqftString, ' sqft'],
                color: 'black',
                fontSize: 16,
                margin: 5,
                margin: [-45, 5, 5, 5],
              },
              {
                text: [property.year, ' Year Built'],
                color: 'black',
                fontSize: 16,
                margin: 5,
                margin: [-45, 5, 5, 5],
              },
            ],
            margin: [0, 5, 0, 0],
            alignment: 'left',
          },
        ],
      },
    ]
  }

  const buyHoldPage2Top = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio

    const IRR = acquisition.analytics.buyHold.IRR
      ? (acquisition.analytics.buyHold.IRR * 100).toFixed(2)
      : 0
    const CAPRate = acquisition.analytics.buyHold.capRate
      ? (acquisition.analytics.buyHold.capRate * 100).toFixed(2)
      : 0
    const mortgagePayment = acquisition.analytics.buyHold.mp_monthly
      ? acquisition.analytics.buyHold.mp_monthly
      : 0
    const financeAmount = acquisition.analytics.buyHold.loanAmount
      ? acquisition.analytics.buyHold.loanAmount
      : 0
    const allInCost = acquisition.analytics.buyHold.allInCost
      ? acquisition.analytics.buyHold.allInCost
      : 0
    const downPaymentTotal = acquisition.analytics.buyHold
      .downPaymentTotal
      ? acquisition.analytics.buyHold.downPaymentTotal
      : 0
    const pointsAmount = acquisition.analytics.buyHold.pointsAmount
      ? acquisition.analytics.buyHold.pointsAmount
      : 0
    const creditFromSeller = assumptions.creditFromSeller
      ? assumptions.creditFromSeller
      : 0
    const closeCostDollar = acquisition.analytics.buyHold
      .closeCostDollar
      ? acquisition.analytics.buyHold.closeCostDollar
      : 0
    const closeCostPercent = acquisition.analytics.buyHold
      .closeCostPercent
      ? acquisition.analytics.buyHold.closeCostPercent
      : 0
    const paydownMonthly = acquisition.analytics.buyHold
      .paydownMonthly
      ? acquisition.analytics.buyHold.paydownMonthly
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollar
      ? acquisition.analytics.acquisitionCommissionDollar
      : 0
    const acquisitionCommissionPercent = acquisition.analytics
      .acquisitionCommissionPercent
      ? acquisition.analytics.acquisitionCommissionPercent
      : 0
    const propertyManagementDollar = acquisition.analytics.buyHold
      .propertyManagementDollar
      ? acquisition.analytics.buyHold.propertyManagementDollar
      : 0
    const utilities = acquisition.analytics.buyHold
      .utilitiesTypeDollar
      ? acquisition.analytics.buyHold.utilitiesTypeDollar
      : 0
    const utilitiesPercent = assumptions.utilities
      ? assumptions.utilities
      : 0
    const financingFeeTotal = acquisition.analytics.buyHold
      .financingFeeTotal
      ? acquisition.analytics.buyHold.financingFeeTotal
      : 0
    const assetManagementFeeTotal = acquisition.analytics.buyHold
      .assetManagementFeeTotal
      ? acquisition.analytics.buyHold.assetManagementFeeTotal
      : 0
    const salePrice = acquisition.analysis.resellPrice
      ? acquisition.analysis.resellPrice
      : acquisition.analytics.buyHold.arvTotalBuyHold || 0
    const propertyAppreciationValue = acquisition.analytics.buyHold
      .propertyAppreciationValue
      ? acquisition.analytics.buyHold.propertyAppreciationValue
      : 0

    const homeInsurance = acquisition.analytics.buyHold
      .propertyInsuranceDollar
      ? acquisition.analytics.buyHold.propertyInsuranceDollar
      : 0
    const maintenanceCost = acquisition.analytics.buyHold
      .maintenanceCost
      ? acquisition.analytics.buyHold.maintenanceCost
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange'
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const otherIncome = acquisition.analysis.otherIncome
      ? acquisition.analysis.otherIncome
      : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.buyHold.hoaTotal
      ? acquisition.analytics.buyHold.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.buyHold
      .arvTotalBuyHold
      ? acquisition.analytics.buyHold.arvTotalBuyHold
      : 0
    const offerPrice = acquisition.analysis.offerPrice
      ? acquisition.analysis.offerPrice
      : acquisition.analysis.listPrice
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentage
      ? acquisition.analysis.financingRepairsPercentage
      : 0
    // const rehabCost = acquisition.analysis.rehabCost
    //   ? acquisition.analysis.rehabCost
    //   : 0
    const rehabCost = acquisition.analytics.buyHold.corTotal
      ? acquisition.analytics.buyHold.corTotal
      : 0
    // const sellPrice = acquisition.analysis.sellPrice
    //   ? acquisition.analysis.afterRepairValue
    //   : 0
    const downPayment = assumptions.downPayment
      ? assumptions.downPayment
      : 0
    const rate = assumptions.rate ? assumptions.rate : 0
    const term = assumptions.term ? assumptions.term : 0
    const termType = assumptions.termType
      ? assumptions.termType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenance
      ? assumptions.maintenance
      : 0
    const propertyMgmtPercent = acquisition.analytics.buyHold
      .propertyManagementPercentage
      ? acquisition.analytics.buyHold.propertyManagementPercentage
      : 0
    const pointPercentage = assumptions.points
      ? assumptions.points
      : 0
    const homePriceAppreciation = assumptions.homePriceAppreciation
      ? assumptions.homePriceAppreciation
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const rentalHoldingPeriod = assumptions.rentalHoldingPeriod
      ? assumptions.rentalHoldingPeriod
      : 0
    const financingFee = acquisition.analytics.buyHold
      .financingFeePercent
      ? acquisition.analytics.buyHold.financingFeePercent
      : 0
    const sellerClosingCost = assumptions.sellerClosingCost
      ? assumptions.sellerClosingCost
      : 0
    const salesCommissionPercent = assumptions.salesCommission
      ? assumptions.salesCommission
      : 0
    const dispositionFee = assumptions.dispositionFee
      ? assumptions.dispositionFee
      : 0
    // const utilities = assumptions.utilities
    //   ? assumptions.utilities
    //   : 0

    const otherExpenses = assumptions.otherExpense
      ? assumptions.otherExpense
      : 0
    const ownerReserves = assumptions.ownerReserves
      ? assumptions.ownerReserves
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    //const cashMortgagePayment = 0
    const percentOfARV = (allInCost / afterRepairValue) * 100

    const monthlyRate = rate / 12 / 100
    const financedRenovationAmount =
      rehabCost - rehabCost * (downPayment / 100) || 0
    const renovationAmount = rehabCost || 0

    const graphTest = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: rentalHoldingPeriod * 12,
      paydownMonthly,
      //holdingPeriod,
      holdingPeriod: false,
    })
    const appreciation = appreciationSection({
      afterRepairValue,
      homePriceAppreciation,
      term: rentalHoldingPeriod * 12,
    })
    const sellPriceAppreciation =
      afterRepairValue +
      (last(appreciation) ? last(appreciation).appreciation : 0)
    const loanBalanceAtHoldingPeriod = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: rentalHoldingPeriod * 12,
      paydownMonthly,
      holdingPeriod: true,
    })
    const sellingClosingCostPercent = acquisition.analytics.buyHold
      .sellingClosingCostPercent
      ? acquisition.analytics.buyHold.sellingClosingCostPercent
      : 0
    const sellingClosingCostTotal =
      assumptions.sellerClosingCostType === '$'
        ? sellerClosingCost
        : (sellerClosingCost / 100) * sellPriceAppreciation
    const dispositionFeeTotal =
      assumptions.dispositionFeeType === '$'
        ? dispositionFee
        : (dispositionFee / 100) * sellPriceAppreciation
    const salesCommission =
      assumptions.salesCommissionType === '$'
        ? salesCommissionPercent
        : (salesCommissionPercent / 100) * sellPriceAppreciation

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      financingFeeTotal +
      pointsAmount -
      creditFromSeller +
      renovationAmount * (1 - financingRepairsPercentage / 100) +
      customAcquisitionExpenseTotal
    const totalInitialInvestmentNoFinancing =
      offerPrice +
      renovationAmount +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      pointsAmount +
      financingFeeTotal +
      ownerReserves +
      customAcquisitionExpenseTotal

    const saleProceeds =
      sellPriceAppreciation -
      salesCommission -
      sellingClosingCostTotal -
      loanBalanceAtHoldingPeriod -
      totalInitialInvestmentFinancing -
      dispositionFeeTotal
    //const totalROIFinancing = (cashFlow / totalInitialInvestmentFinancing) * 100
    //const totalROINoFinancing = (netOperatingIncome / totalInitialInvestmentNoFinancing) * 100
    //const cashFlowNoFinancing = netOperatingIncome
    const yearlyHOA = monthlyHOA * 12
    //let revenueYear = 0
    //let expenseType = ''
    const rentalIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })

    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const vacancyRate1 =
      sum(
        map(
          zip(rentAmount, vacancy),
          ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
        ),
      ) * 12
    const vacancyRate3 = sum(
      map(
        zip(rentalIncome3, vacancy),
        ([rentalIncome3, vacancy]) => rentalIncome3 * (vacancy / 100),
      ),
    )
    const vacancyRate5 = sum(
      map(
        zip(rentalIncome5, vacancy),
        ([rentalIncome5, vacancy]) => rentalIncome5 * (vacancy / 100),
      ),
    )
    const vacancyRate10 = sum(
      map(
        zip(rentalIncome10, vacancy),
        ([rentalIncome10, vacancy]) =>
          rentalIncome10 * (vacancy / 100),
      ),
    )
    const otherIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly
    const grossRentalIncome1 = sum(rentAmount) * 12 - vacancyRate1
    const grossRentalIncome3 = sum(rentalIncome3) - vacancyRate3
    const grossRentalIncome5 = sum(rentalIncome5) - vacancyRate5
    const grossRentalIncome10 = sum(rentalIncome10) - vacancyRate10
    const monthlyRevenue = grossRentalIncomeMonthly + sum(otherIncome)
    const revenueTotal1 = grossRentalIncome1 + sum(otherIncome) * 12
    const revenueTotal3 = grossRentalIncome3 + sum(otherIncome3)
    const revenueTotal5 = grossRentalIncome5 + sum(otherIncome5)
    const revenueTotal10 = grossRentalIncome10 + sum(otherIncome10)
    const propertyTaxes3 = expensesSection({
      expenseType: 'PT',
      revenueYear: 3,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes5 = expensesSection({
      expenseType: 'PT',
      revenueYear: 5,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes10 = expensesSection({
      expenseType: 'PT',
      revenueYear: 10,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance3 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 3,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance5 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 5,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance10 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 10,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA3 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 3,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA5 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 5,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA10 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 10,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyManagement1 = propertyManagementDollar
    const propertyManagement3 =
      assumptions.propertyManagementType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome3) * (propertyMgmtPercent / 100)
    const propertyManagement5 =
      assumptions.propertyManagementType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome5) * (propertyMgmtPercent / 100)
    const propertyManagement10 =
      assumptions.propertyManagementType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome10) * (propertyMgmtPercent / 100)
    const maintenance1 = maintenanceCost
    const maintenance3 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance5 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance10 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const utilities1 = utilities * 12
    const utilities3 =
      assumptions.utilitiesType === '$'
        ? utilities * 12
        : sum(rentalIncome3) * (utilitiesPercent / 100)
    const utilities5 =
      assumptions.utilitiesType === '$'
        ? utilities * 12
        : sum(rentalIncome5) * (utilitiesPercent / 100)
    const utilities10 =
      assumptions.utilitiesType === '$'
        ? utilities * 12
        : sum(rentalIncome10) * (utilitiesPercent / 100)
    const assetFee1 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 1,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee3 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee5 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee10 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const otherExpense3 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense5 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense10 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal3 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal5 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal10 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const totalExpense1 =
      propertyTaxes +
      homeInsurance +
      yearlyHOA +
      propertyManagement1 +
      maintenance1 +
      utilities1 +
      assetFee1 +
      otherExpenses +
      customAnnualExpenseTotal
    const totalExpense3 =
      propertyTaxes3 +
      homeInsurance3 +
      HOA3 +
      propertyManagement3 +
      maintenance3 +
      utilities3 +
      assetFee3 +
      otherExpense3 +
      customAnnualExpenseTotal3
    const totalExpense5 =
      propertyTaxes5 +
      homeInsurance5 +
      HOA5 +
      propertyManagement5 +
      maintenance5 +
      utilities5 +
      assetFee5 +
      otherExpense5 +
      customAnnualExpenseTotal5
    const totalExpense10 =
      propertyTaxes10 +
      homeInsurance10 +
      HOA10 +
      propertyManagement10 +
      maintenance10 +
      utilities10 +
      assetFee10 +
      otherExpense10 +
      customAnnualExpenseTotal10
    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOINoFinance1 = revenueTotal1 - totalExpense1
    const NOINoFinance3 = revenueTotal3 - totalExpense3
    const NOINoFinance5 = revenueTotal5 - totalExpense5
    const NOINoFinance10 = revenueTotal10 - totalExpense10
    const mortgagePaymentYearly = mortgagePayment * 12
    const totalReturnFinancing = []
    const totalReturnFinancing1 =
      NOINoFinance1 - mortgagePaymentYearly
    const totalReturnFinancing3 =
      NOINoFinance3 - mortgagePaymentYearly
    const totalReturnFinancing5 =
      NOINoFinance5 - mortgagePaymentYearly
    const totalReturnFinancing10 =
      NOINoFinance10 - mortgagePaymentYearly
    totalReturnFinancing.push({
      one: totalReturnFinancing1,
      three: totalReturnFinancing3,
      five: totalReturnFinancing5,
      ten: totalReturnFinancing10,
    })

    const cashFlowAccumulation = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: termType === 'Years' ? term * 12 + 1 : term + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })
    const graphDataURL = getChartData(
      property,
      graphTest,
      totalReturnFinancing,
      appreciation,
      cashFlowAccumulation,
      totalInitialInvestmentFinancing,
    )

    //const startingOffer = acquisition.analysis.startingOffer ? acquisition.analysis.startingOffer : acquisition.analysis.listPrice

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    // analysis && '$' + analysis.startingOffer
    //   ? `$ ${analysis.startingOffer}`
    //   : analysis && analysis.offerPrice
    //   ? `$ ${analysis.offerPrice}`
    //   : listPrice

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'

    return [
      {
        width: 400,
        stack: [
          {
            text: [
              'Financial ',
              { text: 'Projections', color: '#3399ff' },
            ],
            fontSize: 18,
            margin: [15, 20, 0, 0],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 15,
                y1: 5,
                x2: 150,
                y2: 5,
                lineWidth: 2,
              },
            ],
          },
          {
            image: graphDataURL,
            width: 400,
            height: 275,
            margin: [10, 20, 0, 0],
          },
        ],
      },
      [
        {
          margin: [20, 30],
          stack: [
            {
              table: {
                headerRows: 1,
                widths: ['*', '*'],
                body: [
                  [
                    {
                      text: 'Financing Assumptions',
                      fillColor: '#3399ff',
                      color: 'white',
                      fontSize: 8,
                      colSpan: 2,
                      alignment: 'center',
                    },
                    { text: '' },
                  ],
                  [
                    {
                      text: 'Loan Amount',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: currencyFormater.format(financeAmount),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text: 'Financed Rehab Amount',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: currencyFormater.format(
                        (financedRenovationAmount *
                          financingRepairsPercentage) /
                          100,
                      ),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text: 'Interest Rate',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: rate + '%',
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text: 'Mortgage (yrs)',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: (termType === 'Years'
                        ? term
                        : term / 12
                      ).toFixed(1),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text: 'Mortgage Payment',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: currencyFormater.format(mortgagePayment),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text:
                        'Paydown Acc. (' + payDownAccelerator + '%)',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: currencyFormater.format(paydownMonthly),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                ],
              },
              layout: {
                defaultBorder: false,
              },
            },
            {
              table: {
                headerRows: 1,
                widths: ['*', '*'],
                body: compact([
                  [
                    {
                      text: 'Initial Investment',
                      fillColor: '#3399ff',
                      color: 'white',
                      fontSize: 8,
                      colSpan: 2,
                      alignment: 'center',
                    },
                    { text: '' },
                  ],
                  downPaymentTotal
                    ? [
                        {
                          text: 'Down Payment (' + downPayment + '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            downPaymentTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  creditFromSeller
                    ? [
                        {
                          text: '-Credit From Seller',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            -1 * creditFromSeller,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  renovationAmount
                    ? [
                        {
                          text: 'Rehab Amount',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            renovationAmount *
                              (1 - financingRepairsPercentage / 100),
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  acquisitionFee
                    ? [
                        {
                          text: 'Acquisition Fee',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            acquisitionFee,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  acquisitionCommissionDollar
                    ? [
                        {
                          text:
                            'Acquisition Commission (' +
                            acquisitionCommissionPercent +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            acquisitionCommissionDollar,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  closeCostDollar
                    ? [
                        {
                          text:
                            'Est Closing Cost (' +
                            closeCostPercent +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            closeCostDollar,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  financingFeeTotal
                    ? [
                        {
                          text:
                            'Financing Fee (' + financingFee + '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            financingFeeTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  pointsAmount
                    ? [
                        {
                          text: 'Points (' + pointPercentage + '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(pointsAmount),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  customAcquisitionExpenseTotal
                    ? [
                        {
                          text: 'Custom Expenses',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            customAcquisitionExpenseTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  [
                    {
                      text: 'Total Out Of Pocket',
                      alignment: 'left',
                      fontSize: 6,
                      bold: true,
                      border: [false, true, false, false],
                    },
                    {
                      text: currencyFormater.format(
                        totalInitialInvestmentFinancing,
                      ),
                      alignment: 'right',
                      fontSize: 6,
                      bold: true,
                      border: [false, true, false, false],
                    },
                  ],
                ]),
              },
              layout: {
                defaultBorder: false,
              },
              margin: [0, 10, 0, 0],
            },
            {
              table: {
                headerRows: 1,
                widths: ['*', '*'],
                body: compact([
                  [
                    {
                      text: 'Net Profit',
                      fillColor: '#3399ff',
                      color: 'white',
                      fontSize: 8,
                      colSpan: 2,
                      alignment: 'center',
                    },
                    { text: '' },
                  ],
                  sellPriceAppreciation
                    ? [
                        {
                          text: 'Sell Price at holding Period',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            sellPriceAppreciation,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  salesCommission
                    ? [
                        {
                          text:
                            'Sales Commission (' +
                            (assumptions.salesCommissionType === '$'
                              ? currencyFormater.format(
                                  salesCommissionPercent,
                                ) + ')'
                              : salesCommissionPercent + '%)'),
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            salesCommission,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  sellingClosingCostTotal
                    ? [
                        {
                          text:
                            'Seller Closing Cost (' +
                            sellingClosingCostPercent +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            sellingClosingCostTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  dispositionFeeTotal
                    ? [
                        {
                          text: 'Disposition Fee',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            dispositionFeeTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  totalInitialInvestmentFinancing
                    ? [
                        {
                          text: 'Total Out Of Pocket',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            totalInitialInvestmentFinancing,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  loanBalanceAtHoldingPeriod
                    ? [
                        {
                          text: 'Loan Balance at Holding Period',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            loanBalanceAtHoldingPeriod,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  [
                    {
                      text: 'Net Profit',
                      alignment: 'left',
                      fontSize: 6,
                      bold: true,
                      border: [false, true, false, false],
                    },
                    {
                      text: currencyFormater.format(saleProceeds),
                      alignment: 'right',
                      fontSize: 6,
                      bold: true,
                      border: [false, true, false, false],
                    },
                  ],
                ]),
              },
              layout: {
                defaultBorder: false,
              },
              margin: [0, 10, 0, 0],
            },
          ],
        },
      ],
    ]
  }

  const fixFlipPage2Top = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      portfolio = {},
      proformaContact,
    } = property

    const mortgagePayment = acquisition.analytics.fixFlip
      .mortgageFixFlip
      ? acquisition.analytics.fixFlip.mortgageFixFlip
      : 0
    const financeAmount = acquisition.analytics.fixFlip
      .loanAmountFixFlip
      ? acquisition.analytics.fixFlip.loanAmountFixFlip
      : 0
    const allInCost = acquisition.analytics.fixFlip.allInCostFixFlip
      ? acquisition.analytics.fixFlip.allInCostFixFlip
      : 0
    const downPaymentTotal = acquisition.analytics.fixFlip
      .downPaymentTotalFixFlip
      ? acquisition.analytics.fixFlip.downPaymentTotalFixFlip
      : 0
    const pointsAmount = acquisition.analytics.fixFlip
      .pointsAmountFixFlip
      ? acquisition.analytics.fixFlip.pointsAmountFixFlip
      : 0
    const creditFromSeller = assumptions.creditFromSeller
      ? assumptions.creditFromSeller
      : 0
    const closeCostDollar = acquisition.analytics.fixFlip
      .closeCostDollarFixFlip
      ? acquisition.analytics.fixFlip.closeCostDollarFixFlip
      : 0
    const closeCostPercent = acquisition.analytics.fixFlip
      .closeCostPercentFixFlip
      ? acquisition.analytics.fixFlip.closeCostPercentFixFlip
      : 0
    const paydownMonthly = acquisition.analytics.fixFlip
      .paydownMonthlyFixFlip
      ? acquisition.analytics.fixFlip.paydownMonthlyFixFlip
      : 0
    const saleProceeds = acquisition.analytics.fixFlip.saleProceeds
      ? acquisition.analytics.fixFlip.saleProceeds
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollar
      ? acquisition.analytics.acquisitionCommissionDollar
      : 0
    const acquisitionCommissionPercent = acquisition.analytics
      .acquisitionCommissionPercent
      ? acquisition.analytics.acquisitionCommissionPercent
      : 0
    const financingFeeTotal = acquisition.analytics.fixFlip
      .financingFeeTotalFixFlip
      ? acquisition.analytics.fixFlip.financingFeeTotalFixFlip
      : 0
    const sellingClosingCost = acquisition.analytics.fixFlip
      .sellingClosingCostDollarFixFlip
      ? acquisition.analytics.fixFlip.sellingClosingCostDollarFixFlip
      : 0
    const sellingClosingCostPercent = acquisition.analytics.fixFlip
      .sellingClosingCostPercentFixFlip
      ? acquisition.analytics.fixFlip.sellingClosingCostPercentFixFlip
      : 0
    const dispositionFee = acquisition.analytics.fixFlip
      .dispositionFeeDollarFixFlip
      ? acquisition.analytics.fixFlip.dispositionFeeDollarFixFlip
      : 0
    const salesCommission = acquisition.analytics.fixFlip
      .salesCommissionTotalFixFlip
      ? acquisition.analytics.fixFlip.salesCommissionTotalFixFlip
      : 0
    const afterRepairValue = acquisition.analytics.fixFlip
      .arvTotalFixFlip
      ? acquisition.analytics.fixFlip.arvTotalFixFlip
      : 0
    const offerPrice = acquisition.analysis.offerPrice
      ? acquisition.analysis.offerPrice
      : acquisition.analysis.listPrice
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentageFixFlip
      ? acquisition.analysis.financingRepairsPercentageFixFlip
      : 0
    // const rehabCost = acquisition.analysis.rehabCost
    //   ? acquisition.analysis.rehabCost
    //   : 0
    const rehabCost = acquisition.analytics.fixFlip.corTotalFixFlip
      ? acquisition.analytics.fixFlip.corTotalFixFlip
      : 0
    // const sellPrice = acquisition.analysis.sellPrice
    //   ? acquisition.analysis.afterRepairValue
    //   : 0
    const salePrice = acquisition.analysis.resellPriceFixFlip
      ? acquisition.analysis.resellPriceFixFlip
      : acquisition.analytics.fixFlip.arvTotalFixFlip || 0
    const downPayment = assumptions.downPaymentFixFlip
      ? assumptions.downPaymentFixFlip
      : 0
    const rate = assumptions.rateFixFlip ? assumptions.rateFixFlip : 0
    const term = assumptions.termFixFlip ? assumptions.termFixFlip : 0
    const termType = assumptions.termFixFlipType
      ? assumptions.termFixFlipType
      : 'Months'
    const pointPercentage = assumptions.pointsFixFlip
      ? assumptions.pointsFixFlip
      : 0
    const homePriceAppreciation = assumptions.homePriceAppreciation
      ? assumptions.homePriceAppreciation
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const holdingPeriod = assumptions.holdingPeriod
      ? assumptions.holdingPeriod
      : 0
    const financingFee = acquisition.analytics.fixFlip
      .financingFeePercentFixFlip
      ? acquisition.analytics.fixFlip.financingFeePercentFixFlip
      : 0
    const salesCommissionPercent = assumptions.salesCommissionFixFlip
      ? assumptions.salesCommissionFixFlip
      : 0

    const homeInsurance = acquisition.analytics.fixFlip
      .propertyInsuranceDollarFixFlip
      ? acquisition.analytics.fixFlip.propertyInsuranceDollarFixFlip
      : 0
    const maintenanceCost = acquisition.analytics.fixFlip
      .maintenanceCostFixFlip
      ? acquisition.analytics.fixFlip.maintenanceCostFixFlip
      : 0
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.fixFlip.hoaTotal
      ? acquisition.analytics.fixFlip.hoaTotal / 12
      : 0
    const utilities = acquisition.analytics.fixFlip
      .utilitiesTypeDollarFixFlip
      ? acquisition.analytics.fixFlip.utilitiesTypeDollarFixFlip
      : 0
    const otherExpenses = assumptions.otherExpenseFixFlip
      ? assumptions.otherExpenseFixFlip
      : 0
    const propertyManagementDollar = acquisition.analytics.fixFlip
      .propertyManagementDollarFixFlip
      ? acquisition.analytics.fixFlip.propertyManagementDollarFixFlip
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    const monthlyReservesDollar = acquisition.analytics.fixFlip
      .monthlyReservesTypeDollarFixFlip
      ? acquisition.analytics.fixFlip.monthlyReservesTypeDollarFixFlip
      : 0

    const monthlyRate = rate / 12 / 100
    const financedRenovationAmount =
      rehabCost - rehabCost * (downPayment / 100) || 0
    const renovationAmount = rehabCost || 0

    const loanBalanceAtHoldingPeriod = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: holdingPeriod,
      paydownMonthly,
      holdingPeriod: true,
    })

    const monthlyHoldingExpenses =
      homeInsurance / 12 +
      propertyManagementDollar / 12 +
      monthlyHOA +
      propertyTaxes / 12 +
      maintenanceCost / 12 +
      utilities +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12 +
      monthlyReservesDollar +
      mortgagePayment

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      sellingClosingCost +
      financingFeeTotal +
      pointsAmount -
      creditFromSeller +
      renovationAmount * (1 - financingRepairsPercentage / 100) +
      customAcquisitionExpenseTotal +
      monthlyHoldingExpenses * holdingPeriod

    const graphDataURL = getFixFlipChartData(property)

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    return [
      {
        width: 400,
        stack: [
          {
            text: [
              'Financial ',
              { text: 'Projections', color: '#3399ff' },
            ],
            fontSize: 18,
            margin: [15, 20, 0, 0],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 15,
                y1: 5,
                x2: 150,
                y2: 5,
                lineWidth: 2,
              },
            ],
          },
          {
            image: graphDataURL,
            width: 400,
            height: 275,
            margin: [10, 20, 0, 0],
          },
        ],
      },
      [
        {
          margin: [20, 30],
          stack: [
            {
              table: {
                headerRows: 1,
                widths: ['*', '*'],
                body: [
                  [
                    {
                      text: 'Financing Assumptions',
                      fillColor: '#3399ff',
                      color: 'white',
                      fontSize: 8,
                      colSpan: 2,
                      alignment: 'center',
                    },
                    { text: '' },
                  ],
                  [
                    {
                      text: 'Loan Amount',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: currencyFormater.format(financeAmount),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text: 'Financed Rehab Amount',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: currencyFormater.format(
                        (financedRenovationAmount *
                          financingRepairsPercentage) /
                          100,
                      ),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text: 'Interest Rate',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: rate + '%',
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text: 'Mortgage (yrs)',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: (termType === 'Years'
                        ? term
                        : term / 12
                      ).toFixed(1),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text: 'Mortgage Payment',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: currencyFormater.format(mortgagePayment),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  // [
                  //   {
                  //     text:
                  //       'Paydown Acc. (' +
                  //       payDownAccelerator +
                  //       '%)',
                  //     alignment: 'left',
                  //     fontSize: 6,
                  //   },
                  //   {
                  //     text: currencyFormater.format(
                  //       paydownMonthly,
                  //     ),
                  //     alignment: 'right',
                  //     fontSize: 6,
                  //   },
                  // ],
                ],
              },
              layout: {
                defaultBorder: false,
              },
            },
            {
              table: {
                headerRows: 1,
                widths: ['*', '*'],
                body: compact([
                  [
                    {
                      text: 'Initial Investment',
                      fillColor: '#3399ff',
                      color: 'white',
                      fontSize: 8,
                      colSpan: 2,
                      alignment: 'center',
                    },
                    { text: '' },
                  ],
                  downPaymentTotal
                    ? [
                        {
                          text: 'Down Payment (' + downPayment + '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            downPaymentTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  creditFromSeller
                    ? [
                        {
                          text: '-Credit From Seller',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            -1 * creditFromSeller,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  renovationAmount
                    ? [
                        {
                          text: 'Rehab Amount',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            renovationAmount *
                              (1 - financingRepairsPercentage / 100),
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  acquisitionFee
                    ? [
                        {
                          text: 'Acquisition Fee',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            acquisitionFee,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  acquisitionCommissionDollar
                    ? [
                        {
                          text:
                            'Acquisition Commission (' +
                            acquisitionCommissionPercent +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            acquisitionCommissionDollar,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  closeCostDollar
                    ? [
                        {
                          text:
                            'Est Closing Cost (' +
                            closeCostPercent +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            closeCostDollar,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  sellingClosingCost
                    ? [
                        {
                          text:
                            'Est Seller Closing Cost (' +
                            sellingClosingCostPercent +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            sellingClosingCost,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  financingFeeTotal
                    ? [
                        {
                          text:
                            'Financing Fee (' + financingFee + '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            financingFeeTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  customAcquisitionExpenseTotal
                    ? [
                        {
                          text: 'Custom Expenses',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            customAcquisitionExpenseTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  pointsAmount
                    ? [
                        {
                          text: 'Points (' + pointPercentage + '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(pointsAmount),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  monthlyHoldingExpenses
                    ? [
                        {
                          text: 'Holding Expenses',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            monthlyHoldingExpenses * holdingPeriod,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  [
                    {
                      text: 'Total Out Of Pocket',
                      alignment: 'left',
                      fontSize: 6,
                      bold: true,
                      border: [false, true, false, false],
                    },
                    {
                      text: currencyFormater.format(
                        totalInitialInvestmentFinancing,
                      ),
                      alignment: 'right',
                      fontSize: 6,
                      bold: true,
                      border: [false, true, false, false],
                    },
                  ],
                ]),
              },
              layout: {
                defaultBorder: false,
              },
              margin: [0, 10, 0, 0],
            },
            {
              table: {
                headerRows: 1,
                widths: ['*', '*'],
                body: compact([
                  [
                    {
                      text: 'Net Profit',
                      fillColor: '#3399ff',
                      color: 'white',
                      fontSize: 8,
                      colSpan: 2,
                      alignment: 'center',
                    },
                    { text: '' },
                  ],
                  salePrice
                    ? [
                        {
                          text: 'Sell Price at holding Period',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(salePrice),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  salesCommission
                    ? [
                        {
                          text:
                            'Sales Commission (' +
                            (assumptions.salesCommissionFixFlipType ===
                            '$'
                              ? currencyFormater.format(
                                  salesCommissionPercent,
                                ) + ')'
                              : salesCommissionPercent + '%)'),
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            salesCommission,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  dispositionFee
                    ? [
                        {
                          text: 'Disposition Fee',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            dispositionFee,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  totalInitialInvestmentFinancing
                    ? [
                        {
                          text: 'Total Out Of Pocket',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            totalInitialInvestmentFinancing,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  loanBalanceAtHoldingPeriod
                    ? [
                        {
                          text: 'Loan Balance',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            loanBalanceAtHoldingPeriod,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  [
                    {
                      text: 'Net Profit',
                      alignment: 'left',
                      fontSize: 6,
                      bold: true,
                      border: [false, true, false, false],
                    },
                    {
                      text: currencyFormater.format(saleProceeds),
                      alignment: 'right',
                      fontSize: 6,
                      bold: true,
                      border: [false, true, false, false],
                    },
                  ],
                ]),
              },
              layout: {
                defaultBorder: false,
              },
              margin: [0, 10, 0, 0],
            },
          ],
        },
      ],
    ]
  }

  const shortTermRentalPage2Top = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio

    const mortgagePayment = acquisition.analytics.STR.mortgageSTR
      ? acquisition.analytics.STR.mortgageSTR
      : 0
    const financeAmount = acquisition.analytics.STR.loanAmountSTR
      ? acquisition.analytics.STR.loanAmountSTR
      : 0
    const allInCost = acquisition.analytics.STR.allInCostSTR
      ? acquisition.analytics.STR.allInCostSTR
      : 0
    const downPaymentTotal = acquisition.analytics.STR
      .downPaymentTotalSTR
      ? acquisition.analytics.STR.downPaymentTotalSTR
      : 0
    const pointsAmount = acquisition.analytics.STR.pointsAmountSTR
      ? acquisition.analytics.STR.pointsAmountSTR
      : 0
    const creditFromSeller = assumptions.creditFromSeller
      ? assumptions.creditFromSeller
      : 0
    const closeCostDollar = acquisition.analytics.STR
      .closeCostDollarSTR
      ? acquisition.analytics.STR.closeCostDollarSTR
      : 0
    const closeCostPercent = acquisition.analytics.STR
      .closeCostPercentSTR
      ? acquisition.analytics.STR.closeCostPercentSTR
      : 0
    const paydownMonthly = acquisition.analytics.STR.paydownMonthlySTR
      ? acquisition.analytics.STR.paydownMonthlySTR
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollar
      ? acquisition.analytics.acquisitionCommissionDollar
      : 0
    const acquisitionCommissionPercent = acquisition.analytics
      .acquisitionCommissionPercent
      ? acquisition.analytics.acquisitionCommissionPercent
      : 0
    const propertyManagementDollar = acquisition.analytics.STR
      .propertyManagementDollarSTR
      ? acquisition.analytics.STR.propertyManagementDollarSTR
      : 0
    const utilities = acquisition.analytics.STR.utilitiesTypeDollarSTR
      ? acquisition.analytics.STR.utilitiesTypeDollarSTR
      : 0
    const utilitiesPercent = assumptions.utilitiesSTR
      ? assumptions.utilitiesSTR
      : 0
    const financingFeeTotal = acquisition.analytics.STR
      .financingFeeTotalSTR
      ? acquisition.analytics.STR.financingFeeTotalSTR
      : 0
    const sellerClosingCost = assumptions.sellerClosingCostSTR
      ? assumptions.sellerClosingCostSTR
      : 0
    const salesCommissionPercent = assumptions.salesCommissionSTR
      ? assumptions.salesCommissionSTR
      : 0
    const dispositionFee = assumptions.dispositionFeeSTR
      ? assumptions.dispositionFeeSTR
      : 0
    const afterRepairValue = acquisition.analytics.STR.arvTotalSTR
      ? acquisition.analytics.STR.arvTotalSTR
      : 0

    const homePriceAppreciation = assumptions.homePriceAppreciation
      ? assumptions.homePriceAppreciation
      : 0

    const rentalHoldingPeriod = assumptions.rentalHoldingPeriodSTR
      ? assumptions.rentalHoldingPeriodSTR
      : 0

    const appreciation = appreciationSection({
      afterRepairValue,
      homePriceAppreciation,
      term: rentalHoldingPeriod * 12,
    })
    const sellPriceAppreciation =
      afterRepairValue +
      (last(appreciation) ? last(appreciation).appreciation : 0)
    const sellingClosingCostPercent = acquisition.analytics.STR
      .sellingClosingCostPercentSTR
      ? acquisition.analytics.STR.sellingClosingCostPercentSTR
      : 0
    const sellingClosingCostTotal =
      assumptions.sellerClosingCostSTRType === '$'
        ? sellerClosingCost
        : (sellerClosingCost / 100) * sellPriceAppreciation
    const dispositionFeeTotal =
      assumptions.dispositionFeeSTRType === '$'
        ? dispositionFee
        : (dispositionFee / 100) * sellPriceAppreciation
    const salesCommission =
      assumptions.salesCommissionSTRType === '$'
        ? salesCommissionPercent
        : (salesCommissionPercent / 100) * sellPriceAppreciation
    const assetManagementFeeTotal = acquisition.analytics.STR
      .assetManagementFeeTotalSTR
      ? acquisition.analytics.STR.assetManagementFeeTotalSTR
      : 0
    const salePrice = acquisition.analysis.resellPriceSTR
      ? acquisition.analysis.resellPriceSTR
      : acquisition.analytics.STR.arvTotalSTR || 0

    const propertyAppreciationValue = acquisition.analytics.STR
      .propertyAppreciationValueSTR
      ? acquisition.analytics.STR.propertyAppreciationValueSTR
      : 0

    const homeInsurance = acquisition.analytics.STR
      .propertyInsuranceDollarSTR
      ? acquisition.analytics.STR.propertyInsuranceDollarSTR
      : 0
    const maintenanceCost = acquisition.analytics.STR
      .maintenanceCostSTR
      ? acquisition.analytics.STR.maintenanceCostSTR
      : 0

    const rentAmount =
      assumptions.rentSTRType === 'rentRange'
        ? acquisition.analysis.rentLowSTR &&
          acquisition.analysis.rentHighSTR
          ? map(
              zip(
                acquisition.analysis.rentLowSTR,
                acquisition.analysis.rentHighSTR,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rentSTR
          ? acquisition.analysis.rentSTR
          : [0]
    // const otherIncome = acquisition.analysis.otherIncome
    //   ? acquisition.analysis.otherIncome
    //   : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.STR.hoaTotal
      ? acquisition.analytics.STR.hoaTotal / 12
      : 0
    const offerPrice = acquisition.analysis.offerPrice
      ? acquisition.analysis.offerPrice
      : acquisition.analysis.listPrice
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentageSTR
      ? acquisition.analysis.financingRepairsPercentageSTR
      : 0
    // const rehabCost = acquisition.analysis.rehabCost
    //   ? acquisition.analysis.rehabCost
    //   : 0
    const rehabCost = acquisition.analytics.STR.corTotalSTR
      ? acquisition.analytics.STR.corTotalSTR
      : 0
    // const sellPrice = acquisition.analysis.sellPrice
    //   ? acquisition.analysis.afterRepairValue
    //   : 0
    const downPayment = assumptions.downPaymentSTR
      ? assumptions.downPaymentSTR
      : 0
    const rate = assumptions.rateSTR ? assumptions.rateSTR : 0
    const term = assumptions.termSTR ? assumptions.termSTR : 0
    const termType = assumptions.termSTRType
      ? assumptions.termSTRType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceSTR
      ? assumptions.maintenanceSTR
      : 0
    const propertyMgmtPercent = acquisition.analytics.STR
      .propertyManagementPercentageSTR
      ? acquisition.analytics.STR.propertyManagementPercentageSTR
      : 0
    const pointPercentage = assumptions.pointsSTR
      ? assumptions.pointsSTR
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const holdingPeriod = assumptions.rentalHoldingPeriodSTR
      ? assumptions.rentalHoldingPeriodSTR
      : 0
    const financingFee = acquisition.analytics.STR
      .financingFeePercentSTR
      ? acquisition.analytics.STR.financingFeePercentSTR
      : 0

    // const utilities = assumptions.utilitiesSTR
    //   ? assumptions.utilitiesSTR
    //   : 0

    const otherExpenses = assumptions.otherExpenseSTR
      ? assumptions.otherExpenseSTR
      : 0
    const ownerReserves = assumptions.ownerReservesSTR
      ? assumptions.ownerReservesSTR
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    //const cashMortgagePayment = 0
    const percentOfARV = (allInCost / afterRepairValue) * 100

    const monthlyRate = rate / 12 / 100
    const financedRenovationAmount =
      rehabCost - rehabCost * (downPayment / 100) || 0
    const renovationAmount = rehabCost || 0

    const graphTest = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: termType === 'Years' ? term * 12 : term,
      paydownMonthly,
      //holdingPeriod,
      holdingPeriod: false,
    })
    const loanBalanceAtHoldingPeriod = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: rentalHoldingPeriod * 12,
      paydownMonthly,
      holdingPeriod: true,
    })

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      financingFeeTotal +
      pointsAmount -
      creditFromSeller +
      renovationAmount * (1 - financingRepairsPercentage / 100) +
      customAcquisitionExpenseTotal
    const totalInitialInvestmentNoFinancing =
      offerPrice +
      renovationAmount +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      pointsAmount +
      financingFeeTotal +
      ownerReserves +
      customAcquisitionExpenseTotal
    //const totalROIFinancing = (cashFlow / totalInitialInvestmentFinancing) * 100
    //const totalROINoFinancing = (netOperatingIncome / totalInitialInvestmentNoFinancing) * 100
    //const cashFlowNoFinancing = netOperatingIncome
    const yearlyHOA = monthlyHOA * 12
    //let revenueYear = 0
    //let expenseType = ''
    const rentalIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })

    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const vacancyRate1 =
      sum(
        map(
          zip(rentAmount, vacancy),
          ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
        ),
      ) * 12
    const vacancyRate3 = sum(
      map(
        zip(rentalIncome3, vacancy),
        ([rentalIncome3, vacancy]) => rentalIncome3 * (vacancy / 100),
      ),
    )
    const vacancyRate5 = sum(
      map(
        zip(rentalIncome5, vacancy),
        ([rentalIncome5, vacancy]) => rentalIncome5 * (vacancy / 100),
      ),
    )
    const vacancyRate10 = sum(
      map(
        zip(rentalIncome10, vacancy),
        ([rentalIncome10, vacancy]) =>
          rentalIncome10 * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly
    const grossRentalIncome1 = sum(rentAmount) * 12 - vacancyRate1
    const grossRentalIncome3 = sum(rentalIncome3) - vacancyRate3
    const grossRentalIncome5 = sum(rentalIncome5) - vacancyRate5
    const grossRentalIncome10 = sum(rentalIncome10) - vacancyRate10
    const propertyTaxes3 = expensesSection({
      expenseType: 'PT',
      revenueYear: 3,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes5 = expensesSection({
      expenseType: 'PT',
      revenueYear: 5,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes10 = expensesSection({
      expenseType: 'PT',
      revenueYear: 10,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance3 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 3,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance5 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 5,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance10 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 10,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA3 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 3,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA5 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 5,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA10 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 10,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyManagement1 = propertyManagementDollar
    const propertyManagement3 =
      assumptions.propertyManagementSTRType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome3) * (propertyMgmtPercent / 100)
    const propertyManagement5 =
      assumptions.propertyManagementSTRType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome5) * (propertyMgmtPercent / 100)
    const propertyManagement10 =
      assumptions.propertyManagementSTRType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome10) * (propertyMgmtPercent / 100)
    const maintenance1 = maintenanceCost
    const maintenance3 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance5 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance10 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const utilities1 = utilities * 12
    const utilities3 =
      assumptions.utilitiesSTRType === '$'
        ? utilities * 12
        : sum(rentalIncome3) * (utilitiesPercent / 100)
    const utilities5 =
      assumptions.utilitiesSTRType === '$'
        ? utilities * 12
        : sum(rentalIncome5) * (utilitiesPercent / 100)
    const utilities10 =
      assumptions.utilitiesSTRType === '$'
        ? utilities * 12
        : sum(rentalIncome10) * (utilitiesPercent / 100)
    const assetFee1 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 1,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee3 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee5 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee10 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const otherExpense3 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense5 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense10 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal3 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal5 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal10 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const saleProceeds =
      sellPriceAppreciation -
      salesCommission -
      sellingClosingCostTotal -
      loanBalanceAtHoldingPeriod -
      totalInitialInvestmentFinancing -
      dispositionFeeTotal
    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const totalExpense1 =
      propertyTaxes +
      homeInsurance +
      yearlyHOA +
      propertyManagement1 +
      maintenance1 +
      utilities1 +
      assetFee1 +
      otherExpenses +
      customAnnualExpenseTotal
    const totalExpense3 =
      propertyTaxes3 +
      homeInsurance3 +
      HOA3 +
      propertyManagement3 +
      maintenance3 +
      utilities3 +
      assetFee3 +
      otherExpense3 +
      customAnnualExpenseTotal3
    const totalExpense5 =
      propertyTaxes5 +
      homeInsurance5 +
      HOA5 +
      propertyManagement5 +
      maintenance5 +
      utilities5 +
      assetFee5 +
      otherExpense5 +
      customAnnualExpenseTotal5
    const totalExpense10 =
      propertyTaxes10 +
      homeInsurance10 +
      HOA10 +
      propertyManagement10 +
      maintenance10 +
      utilities10 +
      assetFee10 +
      otherExpense10 +
      customAnnualExpenseTotal10
    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOINoFinance1 = grossRentalIncome1 - totalExpense1
    const NOINoFinance3 = grossRentalIncome3 - totalExpense3
    const NOINoFinance5 = grossRentalIncome5 - totalExpense5
    const NOINoFinance10 = grossRentalIncome10 - totalExpense10
    const mortgagePaymentYearly = mortgagePayment * 12
    const totalReturnFinancing = []
    const totalReturnFinancing1 =
      NOINoFinance1 - mortgagePaymentYearly
    const totalReturnFinancing3 =
      NOINoFinance3 - mortgagePaymentYearly
    const totalReturnFinancing5 =
      NOINoFinance5 - mortgagePaymentYearly
    const totalReturnFinancing10 =
      NOINoFinance10 - mortgagePaymentYearly
    totalReturnFinancing.push({
      one: totalReturnFinancing1,
      three: totalReturnFinancing3,
      five: totalReturnFinancing5,
      ten: totalReturnFinancing10,
    })

    const cashFlowAccumulation = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: termType === 'Years' ? term * 12 + 1 : term + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })
    const graphDataURL = getChartData(
      property,
      graphTest,
      totalReturnFinancing,
      appreciation,
      cashFlowAccumulation,
      totalInitialInvestmentFinancing,
    )

    //const startingOffer = acquisition.analysis.startingOffer ? acquisition.analysis.startingOffer : acquisition.analysis.listPrice

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    // analysis && '$' + analysis.startingOffer
    //   ? `$ ${analysis.startingOffer}`
    //   : analysis && analysis.offerPrice
    //   ? `$ ${analysis.offerPrice}`
    //   : listPrice

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'

    return [
      {
        width: 400,
        stack: [
          {
            text: [
              'Financial ',
              { text: 'Projections', color: '#3399ff' },
            ],
            fontSize: 18,
            margin: [15, 20, 0, 0],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 15,
                y1: 5,
                x2: 150,
                y2: 5,
                lineWidth: 2,
              },
            ],
          },
          {
            image: graphDataURL,
            width: 400,
            height: 275,
            margin: [10, 20, 0, 0],
          },
        ],
      },
      [
        {
          margin: [20, 30],
          stack: [
            {
              table: {
                headerRows: 1,
                widths: ['*', '*'],
                body: [
                  [
                    {
                      text: 'Financing Assumptions',
                      fillColor: '#3399ff',
                      color: 'white',
                      fontSize: 8,
                      colSpan: 2,
                      alignment: 'center',
                    },
                    { text: '' },
                  ],
                  [
                    {
                      text: 'Loan Amount',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: currencyFormater.format(financeAmount),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text: 'Financed Rehab Amount',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: currencyFormater.format(
                        (financedRenovationAmount *
                          financingRepairsPercentage) /
                          100,
                      ),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text: 'Interest Rate',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: rate + '%',
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text: 'Mortgage (yrs)',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: (termType === 'Years'
                        ? term
                        : term / 12
                      ).toFixed(1),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text: 'Mortgage Payment',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: currencyFormater.format(mortgagePayment),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                  [
                    {
                      text:
                        'Paydown Acc. (' + payDownAccelerator + '%)',
                      alignment: 'left',
                      fontSize: 6,
                    },
                    {
                      text: currencyFormater.format(paydownMonthly),
                      alignment: 'right',
                      fontSize: 6,
                    },
                  ],
                ],
              },
              layout: {
                defaultBorder: false,
              },
            },
            {
              table: {
                headerRows: 1,
                widths: ['*', '*'],
                body: compact([
                  [
                    {
                      text: 'Initial Investment',
                      fillColor: '#3399ff',
                      color: 'white',
                      fontSize: 8,
                      colSpan: 2,
                      alignment: 'center',
                    },
                    { text: '' },
                  ],
                  downPaymentTotal
                    ? [
                        {
                          text: 'Down Payment (' + downPayment + '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            downPaymentTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  creditFromSeller
                    ? [
                        {
                          text: '-Credit From Seller',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            -1 * creditFromSeller,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  renovationAmount
                    ? [
                        {
                          text: 'Rehab Amount',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            renovationAmount *
                              (1 - financingRepairsPercentage / 100),
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  acquisitionFee
                    ? [
                        {
                          text: 'Acquisition Fee',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            acquisitionFee,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  acquisitionCommissionDollar
                    ? [
                        {
                          text:
                            'Acquisition Commission (' +
                            acquisitionCommissionPercent +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            acquisitionCommissionDollar,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  closeCostDollar
                    ? [
                        {
                          text:
                            'Est Closing Cost (' +
                            closeCostPercent +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            closeCostDollar,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  financingFeeTotal
                    ? [
                        {
                          text:
                            'Financing Fee (' + financingFee + '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            financingFeeTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  customAcquisitionExpenseTotal
                    ? [
                        {
                          text: 'Custom Expenses',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            customAcquisitionExpenseTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  pointsAmount
                    ? [
                        {
                          text: 'Points (' + pointPercentage + '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(pointsAmount),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  [
                    {
                      text: 'Total Out Of Pocket',
                      alignment: 'left',
                      fontSize: 6,
                      bold: true,
                      border: [false, true, false, false],
                    },
                    {
                      text: currencyFormater.format(
                        totalInitialInvestmentFinancing,
                      ),
                      alignment: 'right',
                      fontSize: 6,
                      bold: true,
                      border: [false, true, false, false],
                    },
                  ],
                ]),
              },
              layout: {
                defaultBorder: false,
              },
              margin: [0, 10, 0, 0],
            },
            {
              table: {
                headerRows: 1,
                widths: ['*', '*'],
                body: compact([
                  [
                    {
                      text: 'Net Profit',
                      fillColor: '#3399ff',
                      color: 'white',
                      fontSize: 8,
                      colSpan: 2,
                      alignment: 'center',
                    },
                    { text: '' },
                  ],
                  sellPriceAppreciation
                    ? [
                        {
                          text: 'Sell Price at holding Period',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            sellPriceAppreciation,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  salesCommission
                    ? [
                        {
                          text:
                            'Sales Commission (' +
                            (assumptions.salesCommissionSTRType ===
                            '$'
                              ? currencyFormater.format(
                                  salesCommissionPercent,
                                ) + ')'
                              : salesCommissionPercent + '%)'),
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            salesCommission,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  sellingClosingCostTotal
                    ? [
                        {
                          text:
                            'Seller Closing Cost (' +
                            sellingClosingCostPercent +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            sellingClosingCostTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  dispositionFeeTotal
                    ? [
                        {
                          text: 'Disposition Fee',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            dispositionFeeTotal,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  totalInitialInvestmentFinancing
                    ? [
                        {
                          text: 'Total Out Of Pocket',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            totalInitialInvestmentFinancing,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  loanBalanceAtHoldingPeriod
                    ? [
                        {
                          text: 'Loan Balance at Holding Period',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            loanBalanceAtHoldingPeriod,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ]
                    : null,
                  [
                    {
                      text: 'Net Profit',
                      alignment: 'left',
                      fontSize: 6,
                      bold: true,
                      border: [false, true, false, false],
                    },
                    {
                      text: currencyFormater.format(saleProceeds),
                      alignment: 'right',
                      fontSize: 6,
                      bold: true,
                      border: [false, true, false, false],
                    },
                  ],
                ]),
              },
              layout: {
                defaultBorder: false,
              },
              margin: [0, 10, 0, 0],
            },
          ],
        },
      ],
    ]
  }

  const southernImpressionsPage2Top = async (property) => {
    const { acquisition = {}, assumptions = {} } = property

    const mortgagePayment = acquisition.analytics.turnkey
      .mortgageTurnkey
      ? acquisition.analytics.turnkey.mortgageTurnkey
      : 0
    const financeAmount = acquisition.analytics.turnkey
      .loanAmountTurnkey
      ? acquisition.analytics.turnkey.loanAmountTurnkey
      : 0
    const downPaymentTotal = acquisition.analytics.turnkey
      .downPaymentTotalTurnkey
      ? acquisition.analytics.turnkey.downPaymentTotalTurnkey
      : 0
    //const grossAnnualRent = acquisition.analytics.grossAnnualRent ? acquisition.analytics.grossAnnualRent : 0
    const pointsAmount = acquisition.analytics.turnkey
      .pointsAmountTurnkey
      ? acquisition.analytics.turnkey.pointsAmountTurnkey
      : 0
    const closeCostDollar = acquisition.analytics.turnkey
      .closeCostDollarTurnkey
      ? acquisition.analytics.turnkey.closeCostDollarTurnkey
      : 0
    const closeCostPercent = acquisition.analytics.turnkey
      .closeCostPercentTurnkey
      ? acquisition.analytics.turnkey.closeCostPercentTurnkey
      : 0
    const paydownMonthly = acquisition.analytics.turnkey
      .paydownMonthlyTurnkey
      ? acquisition.analytics.turnkey.paydownMonthlyTurnkey
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollarTurnkey
      ? acquisition.analytics.acquisitionCommissionDollarTurnkey
      : 0
    const acquisitionCommissionPercent = acquisition.analytics
      .acquisitionCommissionPercentTurnkey
      ? acquisition.analytics.acquisitionCommissionPercentTurnkey
      : 0
    const propertyManagementDollar = acquisition.analytics.turnkey
      .propertyManagementDollarTurnkey
      ? acquisition.analytics.turnkey.propertyManagementDollarTurnkey
      : 0
    const financingFeeTotal = acquisition.analytics.turnkey
      .financingFeeTotalTurnkey
      ? acquisition.analytics.turnkey.financingFeeTotalTurnkey
      : 0
    const sellingClosingCost = acquisition.analytics.turnkey
      .sellingClosingCostDollarTurnkey
      ? acquisition.analytics.turnkey.sellingClosingCostDollarTurnkey
      : 0
    const sellingClosingCostPercent = acquisition.analytics.turnkey
      .sellingClosingCostPercentTurnkey
      ? acquisition.analytics.turnkey.sellingClosingCostPercentTurnkey
      : 0
    const dispositionFee = acquisition.analytics.turnkey
      .dispositionFeeDollarTurnkey
      ? acquisition.analytics.turnkey.dispositionFeeDollarTurnkey
      : 0
    const salesCommission = acquisition.analytics.turnkey
      .salesCommissionTotalTurnkey
      ? acquisition.analytics.turnkey.salesCommissionTotalTurnkey
      : 0
    const salePrice = acquisition.analysis.resellPriceTurnkey
      ? acquisition.analysis.resellPriceTurnkey
      : acquisition.analytics.turnkey.arvTotalTurnkey || 0
    const propertyAppreciationValueTurnkey = acquisition.analytics
      .turnkey.propertyAppreciationValueTurnkey
      ? acquisition.analytics.turnkey.propertyAppreciationValueTurnkey
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange' //SPECIFIC COMMENT: Southern Impressions calculate range with rentHigh value not average of low and high
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => high,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const otherIncome = acquisition.analysis.otherIncome
      ? acquisition.analysis.otherIncome
      : [0]
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentageTurnkey
      ? acquisition.analysis.financingRepairsPercentageTurnkey
      : false
    // const rehabCost = acquisition.analysis.endRehabCost
    //   ? acquisition.analysis.endRehabCost
    //   : 0
    const rehabCost = acquisition.analytics.turnkey.corTotalTurnkey
      ? acquisition.analytics.turnkey.corTotalTurnkey
      : 0
    // const sellPrice = acquisition.analysis.sellPrice
    //   ? acquisition.analysis.afterRepairValue
    //   : 0
    const downPayment = assumptions.downPaymentTurnkey
      ? assumptions.downPaymentTurnkey
      : 0
    const rate = assumptions.rateTurnkey ? assumptions.rateTurnkey : 0
    const term = assumptions.termTurnkey ? assumptions.termTurnkey : 0
    const termType = assumptions.termTurnkeyType
      ? assumptions.termTurnkeyType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceTurnkey
      ? assumptions.maintenanceTurnkey
      : 0
    const pointPercentage = assumptions.pointsTurnkey
      ? assumptions.pointsTurnkey
      : 0
    const homePriceAppreciation = assumptions.homePriceAppreciation
      ? assumptions.homePriceAppreciation
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const holdingPeriod = assumptions.rentalHoldingPeriodTurnkey
      ? assumptions.rentalHoldingPeriodTurnkey
      : 0
    const financingFee = acquisition.analytics.turnkey
      .financingFeePercentTurnkey
      ? acquisition.analytics.turnkey.financingFeePercentTurnkey
      : 0
    const salesCommissionPercent = assumptions.salesCommissionTurnkey
      ? assumptions.salesCommissionTurnkey
      : 0

    const monthlyRate = rate / 12 / 100
    const financedRenovationAmount =
      rehabCost - rehabCost * (downPayment / 100) || 0
    const renovationAmount = rehabCost || 0

    const loanBalanceAtHoldingPeriod = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: holdingPeriod,
      paydownMonthly,
      holdingPeriod: true,
    })

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      sellingClosingCost +
      financingFeeTotal +
      pointsAmount +
      customAcquisitionExpenseTotal +
      renovationAmount * (1 - financingRepairsPercentage / 100)
    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )

    const saleProceeds =
      propertyAppreciationValueTurnkey -
      salesCommission -
      //sellingClosingCostDollar -
      loanBalanceAtHoldingPeriod -
      totalInitialInvestmentFinancing -
      dispositionFee

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const percentageFormat = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
    })

    return [
      {
        columns: [
          {
            margin: [20, 30],
            stack: [
              {
                table: {
                  headerRows: 1,
                  widths: ['*', '*'],
                  body: [
                    [
                      {
                        text: 'Financing Assumptions',
                        fillColor: '#3399ff',
                        color: 'white',
                        fontSize: 8,
                        colSpan: 2,
                        alignment: 'center',
                      },
                      { text: '' },
                    ],
                    [
                      {
                        text: 'Loan Amount',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(financeAmount),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Financed Rehab Amount',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(
                          (financedRenovationAmount *
                            financingRepairsPercentage) /
                            100,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Interest Rate',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: rate + '%',
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Mortgage (yrs)',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: (termType === 'Years'
                          ? term
                          : term / 12
                        ).toFixed(1),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Mortgage Payment',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(
                          mortgagePayment,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text:
                          'Paydown Acc. (' +
                          payDownAccelerator +
                          '%)',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(paydownMonthly),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                  ],
                },
                layout: {
                  defaultBorder: false,
                },
              },
              {
                table: {
                  headerRows: 1,
                  widths: ['*', '*'],
                  body: [
                    [
                      {
                        text: 'Initial Investment',
                        fillColor: '#3399ff',
                        color: 'white',
                        fontSize: 8,
                        colSpan: 2,
                        alignment: 'center',
                      },
                      { text: '' },
                    ],
                    [
                      {
                        text: 'Down Payment (' + downPayment + '%)',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(
                          downPaymentTotal,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Rehab Amount',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(
                          renovationAmount *
                            (1 - financingRepairsPercentage / 100),
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Acquisition Fee',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(acquisitionFee),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text:
                          'Acquisition Commission (' +
                          acquisitionCommissionPercent +
                          '%)',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(
                          acquisitionCommissionDollar,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text:
                          'Est Closing Cost (' +
                          closeCostPercent +
                          '%)',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(
                          closeCostDollar,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Financing Fee (' + financingFee + '%)',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(
                          financingFeeTotal,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Custom Expenses',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(
                          customAcquisitionExpenseTotal,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Points (' + pointPercentage + '%)',
                        alignment: 'left',
                        fontSize: 6,
                        border: [false, false, false, true],
                      },
                      {
                        text: currencyFormater.format(pointsAmount),
                        alignment: 'right',
                        fontSize: 6,
                        border: [false, false, false, true],
                      },
                    ],
                    [
                      {
                        text: 'Total Out Of Pocket',
                        alignment: 'left',
                        fontSize: 6,
                        bold: true,
                      },
                      {
                        text: currencyFormater.format(
                          totalInitialInvestmentFinancing,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                        bold: true,
                      },
                    ],
                  ],
                },
                layout: {
                  defaultBorder: false,
                },
                margin: [0, 10, 0, 0],
              },
            ],
          },
          {
            margin: [20, 30],
            stack: [
              {
                table: {
                  headerRows: 1,
                  widths: ['*', '*'],
                  body: [
                    [
                      {
                        text: 'Operating Assumptions',
                        fillColor: '#3399ff',
                        color: 'white',
                        fontSize: 8,
                        colSpan: 2,
                        alignment: 'center',
                      },
                      { text: '' },
                    ],
                    [
                      {
                        text: 'Appreciation Rate',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: percentageFormat.format(
                          homePriceAppreciation / 100,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Income Growth Rate',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: percentageFormat.format(
                          annualRevenueIncrease / 100,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Expense Growth Rate',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: percentageFormat.format(
                          annualOperatingExpenseIncrease / 100,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Vacancy Rate',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: percentageFormat.format(
                          vacancyRateMonthly / sum(rentAmount),
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Mgmt Fee',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(
                          propertyManagementDollar,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Maintenance',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: percentageFormat.format(
                          maintenance / 100,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                  ],
                },
                layout: {
                  defaultBorder: false,
                },
              },
              {
                table: {
                  headerRows: 1,
                  widths: ['*', '*'],
                  body: [
                    [
                      {
                        text: 'Sale Proceeds',
                        fillColor: '#3399ff',
                        color: 'white',
                        fontSize: 8,
                        colSpan: 2,
                        alignment: 'center',
                      },
                      { text: '' },
                    ],
                    [
                      {
                        text: 'Sell Price at holding Period',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(
                          propertyAppreciationValueTurnkey,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text:
                          'Sales Commission (' +
                          (assumptions.salesCommissionTurnkeyType ===
                          '$'
                            ? currencyFormater.format(
                                salesCommissionPercent,
                              ) + ')'
                            : salesCommissionPercent + '%)'),
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(
                          salesCommission,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Disposition Fee',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(dispositionFee),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Total Out Of Pocket',
                        alignment: 'left',
                        fontSize: 6,
                      },
                      {
                        text: currencyFormater.format(
                          totalInitialInvestmentFinancing,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                      },
                    ],
                    [
                      {
                        text: 'Loan Balance',
                        alignment: 'left',
                        fontSize: 6,
                        border: [false, false, false, true],
                      },
                      {
                        text: currencyFormater.format(
                          loanBalanceAtHoldingPeriod,
                        ),
                        alignment: 'right',
                        fontSize: 6,
                        border: [false, false, false, true],
                      },
                    ],
                    [
                      {
                        text: 'Sale Proceeds',
                        alignment: 'left',
                        fontSize: 6,
                        bold: true,
                      },
                      {
                        text: currencyFormater.format(saleProceeds),
                        alignment: 'right',
                        fontSize: 6,
                        bold: true,
                      },
                    ],
                  ],
                },
                layout: {
                  defaultBorder: false,
                },
                margin: [0, 10, 0, 0],
              },
            ],
          },
        ],
      },
    ]
  }

  const buyHoldPage2Bottom = async (property) => {
    const {
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    const { legalDescription = '' } = organization

    const mortgagePayment = acquisition.analytics.buyHold.mp_monthly
      ? acquisition.analytics.buyHold.mp_monthly
      : 0
    const downPaymentTotal = acquisition.analytics.buyHold
      .downPaymentTotal
      ? acquisition.analytics.buyHold.downPaymentTotal
      : 0
    const pointsAmount = acquisition.analytics.buyHold.pointsAmount
      ? acquisition.analytics.buyHold.pointsAmount
      : 0
    const creditFromSeller = assumptions.creditFromSeller
      ? assumptions.creditFromSeller
      : 0
    const closeCostDollar = acquisition.analytics.buyHold
      .closeCostDollar
      ? acquisition.analytics.buyHold.closeCostDollar
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollar
      ? acquisition.analytics.acquisitionCommissionDollar
      : 0
    const propertyManagementDollar = acquisition.analytics.buyHold
      .propertyManagementDollar
      ? acquisition.analytics.buyHold.propertyManagementDollar
      : 0
    const utilities = acquisition.analytics.buyHold
      .utilitiesTypeDollar
      ? acquisition.analytics.buyHold.utilitiesTypeDollar
      : 0
    const utilitiesPercent = assumptions.utilities
      ? assumptions.utilities
      : 0
    const financingFeeTotal = acquisition.analytics.buyHold
      .financingFeeTotal
      ? acquisition.analytics.buyHold.financingFeeTotal
      : 0
    const assetManagementFeeTotal = acquisition.analytics.buyHold
      .assetManagementFeeTotal
      ? acquisition.analytics.buyHold.assetManagementFeeTotal
      : 0
    const monthlyReserves = acquisition.analytics.buyHold
      .monthlyReservesTypeDollar
      ? acquisition.analytics.buyHold.monthlyReservesTypeDollar
      : 0

    const homeInsurance = acquisition.analytics.buyHold
      .propertyInsuranceDollar
      ? acquisition.analytics.buyHold.propertyInsuranceDollar
      : 0
    const maintenanceCost = acquisition.analytics.buyHold
      .maintenanceCost
      ? acquisition.analytics.buyHold.maintenanceCost
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange'
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const otherIncome = acquisition.analysis.otherIncome
      ? acquisition.analysis.otherIncome
      : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.buyHold.hoaTotal
      ? acquisition.analytics.buyHold.hoaTotal / 12
      : 0
    const offerPrice = acquisition.analysis.offerPrice
      ? acquisition.analysis.offerPrice
      : acquisition.analysis.listPrice
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentage
      ? acquisition.analysis.financingRepairsPercentage
      : 0
    // const rehabCost = acquisition.analysis.rehabCost
    //   ? acquisition.analysis.rehabCost
    //   : 0
    const rehabCost = acquisition.analytics.buyHold.corTotal
      ? acquisition.analytics.buyHold.corTotal
      : 0
    const term = assumptions.term ? assumptions.term : 0
    const termType = assumptions.termType
      ? assumptions.termType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenance
      ? assumptions.maintenance
      : 0
    const propertyMgmtPercent = acquisition.analytics.buyHold
      .propertyManagementPercentage
      ? acquisition.analytics.buyHold.propertyManagementPercentage
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const assetManagementFee = assumptions.assetManagementFee
      ? assumptions.assetManagementFee
      : 0

    const otherExpenses = assumptions.otherExpense
      ? assumptions.otherExpense
      : 0

    const ownerReserves = assumptions.ownerReserves
      ? assumptions.ownerReserves
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    const renovationAmount = rehabCost || 0

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      financingFeeTotal +
      pointsAmount -
      creditFromSeller +
      renovationAmount * (1 - financingRepairsPercentage / 100) +
      customAcquisitionExpenseTotal
    const totalInitialInvestmentNoFinancing =
      offerPrice +
      renovationAmount +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      pointsAmount +
      financingFeeTotal +
      ownerReserves +
      customAcquisitionExpenseTotal
    const yearlyHOA = monthlyHOA * 12
    const rentalIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const vacancyRate1 =
      sum(
        map(
          zip(rentAmount, vacancy),
          ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
        ),
      ) * 12
    const vacancyRate3 = sum(
      map(
        zip(rentalIncome3, vacancy),
        ([rentalIncome3, vacancy]) => rentalIncome3 * (vacancy / 100),
      ),
    )
    const vacancyRate5 = sum(
      map(
        zip(rentalIncome5, vacancy),
        ([rentalIncome5, vacancy]) => rentalIncome5 * (vacancy / 100),
      ),
    )
    const vacancyRate10 = sum(
      map(
        zip(rentalIncome10, vacancy),
        ([rentalIncome10, vacancy]) =>
          rentalIncome10 * (vacancy / 100),
      ),
    )
    const otherIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly
    const grossRentalIncome1 = sum(rentAmount) * 12 - vacancyRate1
    const grossRentalIncome3 = sum(rentalIncome3) - vacancyRate3
    const grossRentalIncome5 = sum(rentalIncome5) - vacancyRate5
    const grossRentalIncome10 = sum(rentalIncome10) - vacancyRate10
    const monthlyRevenue = grossRentalIncomeMonthly + sum(otherIncome)
    const revenueTotal1 = grossRentalIncome1 + sum(otherIncome) * 12
    const revenueTotal3 = grossRentalIncome3 + sum(otherIncome3)
    const revenueTotal5 = grossRentalIncome5 + sum(otherIncome5)
    const revenueTotal10 = grossRentalIncome10 + sum(otherIncome10)
    const propertyTaxes3 = expensesSection({
      expenseType: 'PT',
      revenueYear: 3,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes5 = expensesSection({
      expenseType: 'PT',
      revenueYear: 5,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes10 = expensesSection({
      expenseType: 'PT',
      revenueYear: 10,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance3 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 3,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance5 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 5,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance10 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 10,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA3 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 3,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA5 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 5,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA10 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 10,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyManagement1 = propertyManagementDollar
    const propertyManagement3 =
      assumptions.propertyManagementType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome3) * (propertyMgmtPercent / 100)
    const propertyManagement5 =
      assumptions.propertyManagementType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome5) * (propertyMgmtPercent / 100)
    const propertyManagement10 =
      assumptions.propertyManagementType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome10) * (propertyMgmtPercent / 100)
    const maintenance1 = maintenanceCost
    const maintenance3 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 3,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance5 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 5,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance10 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 10,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const utilities1 = utilities * 12
    const utilities3 =
      assumptions.utilitiesType === '$'
        ? utilities * 12
        : sum(rentalIncome3) * (utilitiesPercent / 100)
    const utilities5 =
      assumptions.utilitiesType === '$'
        ? utilities * 12
        : sum(rentalIncome5) * (utilitiesPercent / 100)
    const utilities10 =
      assumptions.utilitiesType === '$'
        ? utilities * 12
        : sum(rentalIncome10) * (utilitiesPercent / 100)
    const assetFee1 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 1,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee3 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 3,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee5 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 5,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee10 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 10,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const otherExpense3 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 3,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense5 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 5,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense10 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 10,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal3 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal5 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal10 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const reserves1 = monthlyReserves * 12
    const reserves3 = expensesSection({
      expenseType: 'RESERVE',
      revenueYear: 3,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      yearlyReserve: reserves1,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const reserves5 = expensesSection({
      expenseType: 'RESERVE',
      revenueYear: 5,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      yearlyReserve: reserves1,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const reserves10 = expensesSection({
      expenseType: 'RESERVE',
      revenueYear: 10,
      rentAmount: sum(rentAmount) * 12 - vacancyRate1,
      yearlyReserve: reserves1,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      monthlyReserves +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const totalExpense1 =
      propertyTaxes +
      homeInsurance +
      yearlyHOA +
      propertyManagement1 +
      maintenance1 +
      utilities1 +
      assetFee1 +
      reserves1 +
      otherExpenses +
      customAnnualExpenseTotal
    const totalExpense3 =
      propertyTaxes3 +
      homeInsurance3 +
      HOA3 +
      propertyManagement3 +
      maintenance3 +
      utilities3 +
      assetFee3 +
      reserves3 +
      otherExpense3 +
      customAnnualExpenseTotal3
    const totalExpense5 =
      propertyTaxes5 +
      homeInsurance5 +
      HOA5 +
      propertyManagement5 +
      maintenance5 +
      utilities5 +
      assetFee5 +
      reserves5 +
      otherExpense5 +
      customAnnualExpenseTotal5
    const totalExpense10 =
      propertyTaxes10 +
      homeInsurance10 +
      HOA10 +
      propertyManagement10 +
      maintenance10 +
      utilities10 +
      assetFee10 +
      reserves10 +
      otherExpense10 +
      customAnnualExpenseTotal10
    const expenseRatio1 = (totalExpense1 / revenueTotal1) * 100
    const expenseRatio3 = (totalExpense3 / revenueTotal3) * 100
    const expenseRatio5 = (totalExpense5 / revenueTotal5) * 100
    const expenseRatio10 = (totalExpense10 / revenueTotal10) * 100
    const NOINoFinanceMonthly = monthlyRevenue - totalExpenseMonthly
    const NOINoFinance1 = revenueTotal1 - totalExpense1
    const NOINoFinance3 = revenueTotal3 - totalExpense3
    const NOINoFinance5 = revenueTotal5 - totalExpense5
    const NOINoFinance10 = revenueTotal10 - totalExpense10
    const NOIFinanceMonthly = monthlyRevenue - totalExpenseMonthly
    const NOIFinance1 = revenueTotal1 - totalExpense1
    const NOIFinance3 = revenueTotal3 - totalExpense3
    const NOIFinance5 = revenueTotal5 - totalExpense5
    const NOIFinance10 = revenueTotal10 - totalExpense10
    const mortgagePaymentYearly = mortgagePayment * 12
    const totalReturnFinancing = []
    const totalReturnFinancing1 =
      NOINoFinance1 - mortgagePaymentYearly
    const totalReturnFinancing3 =
      NOINoFinance3 - mortgagePaymentYearly
    const totalReturnFinancing5 =
      NOINoFinance5 - mortgagePaymentYearly
    const totalReturnFinancing10 =
      NOINoFinance10 - mortgagePaymentYearly
    totalReturnFinancing.push({
      one: totalReturnFinancing1,
      three: totalReturnFinancing3,
      five: totalReturnFinancing5,
      ten: totalReturnFinancing10,
    })
    const totalROIFinancing1 =
      (totalReturnFinancing1 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing3 =
      (totalReturnFinancing3 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing5 =
      (totalReturnFinancing5 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing10 =
      (totalReturnFinancing10 / totalInitialInvestmentFinancing) * 100
    const totalReturnNoFinancing1 = NOINoFinance1
    const totalReturnNoFinancing3 = NOINoFinance3
    const totalReturnNoFinancing5 = NOINoFinance5
    const totalReturnNoFinancing10 = NOINoFinance10
    const totalROINoFinancing1 =
      (totalReturnNoFinancing1 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing3 =
      (totalReturnNoFinancing3 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing5 =
      (totalReturnNoFinancing5 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing10 =
      (totalReturnNoFinancing10 / totalInitialInvestmentNoFinancing) *
      100

    const cashFlowAccumulation = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: termType === 'Years' ? term * 12 + 1 : term + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const numberFormat = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const percentageFormat = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
    })

    return [
      {
        margin: [15, 10, 15, 0],
        table: {
          headerRows: 0,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Annual Revenue Increase',
                alignment: 'left',
                fontSize: 6,
              },
              { text: '' },
              {
                text: annualRevenueIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualRevenueIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualRevenueIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualRevenueIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Annual Operating Expense Increase',
                alignment: 'left',
                fontSize: 6,
              },
              { text: '' },
              {
                text: annualOperatingExpenseIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualOperatingExpenseIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualOperatingExpenseIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualOperatingExpenseIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Annual Expense Ratio',
                alignment: 'left',
                fontSize: 6,
              },
              { text: '' },
              {
                text: expenseRatio1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio10.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
            ],
            //[{text: 'Estimated Tax Bracket', alignment: 'left',fontSize: 6,}, {text: '$123.00', alignment: 'right',fontSize: 6,},{text: '0%', alignment: 'right',fontSize: 6,}, {text: '1%', alignment: 'right',fontSize: 6,}, {text: '1%', alignment: 'right',fontSize: 6,}, {text: '1%', alignment: 'right',fontSize: 6,}]
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 0,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              { text: '', alignment: 'left', fontSize: 6 },
              {
                text: 'Monthly',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 1',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 3',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 5',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 10',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 0, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*'],
          body: compact([
            [
              {
                text: 'Revenues',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 6,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            sum(rentAmount)
              ? [
                  {
                    text: 'Potential Rental Income',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(sum(rentAmount)),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      sum(rentAmount) * 12,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(sum(rentalIncome3)),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(sum(rentalIncome5)),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      sum(rentalIncome10),
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            vacancyRate1
              ? [
                  {
                    text:
                      'Vacancy Rate (' +
                      percentageFormat.format(
                        vacancyRateMonthly / sum(rentAmount),
                      ) +
                      ')',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(vacancyRateMonthly),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(vacancyRate1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(vacancyRate3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(vacancyRate5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(vacancyRate10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            sum(otherIncome)
              ? [
                  {
                    text: 'Other Income',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(sum(otherIncome)),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      sum(otherIncome) * 12,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherIncome3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherIncome5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherIncome10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            [
              {
                text: 'Gross Rental Income',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(monthlyRevenue),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(revenueTotal1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(revenueTotal3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(revenueTotal5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(revenueTotal10),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
            ],
          ]),
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*'],
          body: compact([
            [
              {
                text: 'Expenses',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 6,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            propertyTaxes
              ? [
                  {
                    text: 'Estimated Annual Property Taxes',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(propertyTaxes / 12),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(propertyTaxes),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(propertyTaxes3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(propertyTaxes5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(propertyTaxes10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            homeInsurance
              ? [
                  {
                    text: 'Estimated Annual Homeowners Insurance',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(homeInsurance / 12),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(homeInsurance),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(homeInsurance3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(homeInsurance5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(homeInsurance10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            monthlyHOA
              ? [
                  {
                    text: 'Estimated Annual HOA Dues',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(monthlyHOA),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(yearlyHOA),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(HOA3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(HOA5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(HOA10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            propertyManagement1
              ? [
                  {
                    text:
                      'Estimated Annual Prop Mgmt Fee (' +
                      propertyMgmtPercent +
                      '%)',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      (propertyMgmtPercent / 100) *
                        grossRentalIncomeMonthly,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      propertyManagement1,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      propertyManagement3,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      propertyManagement5,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      propertyManagement10,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            reserves1
              ? [
                  {
                    text: 'Estimated Annual Reserves',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(monthlyReserves),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(reserves1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(reserves3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(reserves5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(reserves10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            maintenance1
              ? [
                  {
                    text:
                      'Maintenance & Repairs (' + maintenance + '%)',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      (maintenance / 100) * grossRentalIncomeMonthly,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(maintenance1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(maintenance3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(maintenance5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(maintenance10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            utilities
              ? [
                  {
                    text: 'Utilities',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(utilities),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(utilities1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(utilities3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(utilities5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(utilities10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            assetFee1
              ? [
                  {
                    text:
                      'Asset Management Fee (' +
                      assetManagementFee +
                      '%)',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(assetFee1 / 12),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(assetFee1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(assetFee3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(assetFee5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(assetFee10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            otherExpenses
              ? [
                  {
                    text: 'Other Expenses',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherExpenses / 12),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherExpenses),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherExpense3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherExpense5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherExpense10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            customAnnualExpenseTotal
              ? [
                  {
                    text: 'Custom Expenses',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      customAnnualExpenseTotal / 12,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      customAnnualExpenseTotal,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      customAnnualExpenseTotal3,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      customAnnualExpenseTotal5,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      customAnnualExpenseTotal10,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            [
              {
                text: 'Total Expenses',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(totalExpenseMonthly),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(totalExpense1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(totalExpense3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(totalExpense5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(totalExpense10),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
            ],
          ]),
        },
        layout: {
          defaultBorder: false,
        },
      },
      // {
      //   margin: [15,5,15,0],
      //         table:
      //         {
      //           headerRows: 1,
      //           widths: [150,'*','*','*','*','*'],
      //           body: [
      //             [{text: 'Net Operating Income (NOI)', fillColor: '#3399ff', color: 'white', fontSize: 8, alignment: 'left',}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinanceMonthly)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance1)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance3)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance5)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance10)},],
      //           ]
      //         },
      //         layout: {
      //           defaultBorder: false,
      //         }
      // },
      // {
      //   margin: [15,5,15,0],
      //         table:
      //         {
      //           headerRows: 1,
      //           widths: [150,'*','*','*','*','*'],
      //           body: [
      //             [{text: 'Estimated Annual Property Taxes', alignment: 'left',fontSize: 8,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes / 12), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes * 3), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes * 5), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes * 10), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}],
      //           ]
      //         },
      //         layout: {
      //           defaultBorder: false,
      //         }
      // },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Returns Without Financing',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 6,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'NOI (Cash Available)',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinanceMonthly),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance5),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance10),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            // [{text: 'Mortgage', alignment: 'left',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}],
            // [{text: 'Total Cash Flow', alignment: 'left',fontSize: 6, bold: true}, {text: currencyFormater.format(NOIFinanceMonthly), alignment: 'right',fontSize: 6,bold: true},{text: currencyFormater.format(NOINoFinance1), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance3), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance5), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance10), alignment: 'right',fontSize: 6,bold: true}],
            //[{text: 'Principal Paydown', alignment: 'left',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            //[{text: 'Estimated Tax Savings', alignment: 'left',fontSize: 6, }, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            [
              {
                text: 'Net Cashflow',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing1,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing3,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing5,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing10,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: 'Cap Rate',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing10.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Returns With Financing',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 6,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'NOI (Cash Available)',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinanceMonthly),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance5),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance10),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Mortgage',
                alignment: 'left',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePayment),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
            ],
            //[{text: 'Principal Paydown', alignment: 'left',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            //[{text: 'Estimated Tax Savings', alignment: 'left',fontSize: 6, }, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            [
              {
                text: 'Net Cashflow',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing10),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
            [
              {
                text: 'Cash On Cash',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing10.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 10, 15, 15],
        color: 'gray',
        fontSize: 8,
        text: legalDescription ? legalDescription : '',
        //'The above agent/brokerage makes no warranty or representation about the content of this brochure. While the information displayed herein is thought to be accurate, it is your responsibility to independently confirm its accuracy and completeness. Any projections, opinions, assumptions or estimates are used for example only and do not represent the current or future performance of the property. The above agent/brokerage neither practices accounting nor gives advice regarding tax benefits/liabilities or any other tax, accounting or financial consideration, nor does the above agent/brokerage give advice regarding financial investments. It is strongly recommended that you seek appropriate professional counsel regarding your rights as a homeowner.',
      },
    ]
  }

  const fixFlipPage2Bottom = async (property) => {
    const {
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    const { legalDescription = '' } = organization

    const mortgagePayment = acquisition.analytics.fixFlip
      .mortgageFixFlip
      ? acquisition.analytics.fixFlip.mortgageFixFlip
      : 0
    const financeAmount = acquisition.analytics.fixFlip
      .loanAmountFixFlip
      ? acquisition.analytics.fixFlip.loanAmountFixFlip
      : 0
    const paydownMonthly = acquisition.analytics.fixFlip
      .paydownMonthlyFixFlip
      ? acquisition.analytics.fixFlip.paydownMonthlyFixFlip
      : 0
    const downPaymentTotal = acquisition.analytics.fixFlip
      .downPaymentTotalFixFlip
      ? acquisition.analytics.fixFlip.downPaymentTotalFixFlip
      : 0
    const pointsAmount = acquisition.analytics.fixFlip
      .pointsAmountFixFlip
      ? acquisition.analytics.fixFlip.pointsAmountFixFlip
      : 0
    const creditFromSeller = assumptions.creditFromSeller
      ? assumptions.creditFromSeller
      : 0
    const closeCostDollar = acquisition.analytics.fixFlip
      .closeCostDollarFixFlip
      ? acquisition.analytics.fixFlip.closeCostDollarFixFlip
      : 0
    const closeCostPercent = acquisition.analytics.fixFlip
      .closeCostPercentFixFlip
      ? acquisition.analytics.fixFlip.sellingClosingCostPercentFixFlip
      : 0
    const sellerCloseCostDollar = acquisition.analytics.fixFlip
      .sellingClosingCostDollarFixFlip
      ? acquisition.analytics.fixFlip.sellingClosingCostDollarFixFlip
      : 0
    const sellerCloseCostPercent = acquisition.analytics.fixFlip
      .sellingClosingCostPercentFixFlip
      ? acquisition.analytics.fixFlip.sellingClosingCostPercentFixFlip
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollar
      ? acquisition.analytics.acquisitionCommissionDollar
      : 0
    const acquisitionCommissionPercent = acquisition.analytics
      .acquisitionCommissionPercent
      ? acquisition.analytics.acquisitionCommissionPercent
      : 0
    const propertyManagementDollar = acquisition.analytics.fixFlip
      .propertyManagementDollarFixFlip
      ? acquisition.analytics.fixFlip.propertyManagementDollarFixFlip
      : 0
    const utilities = acquisition.analytics.fixFlip
      .utilitiesTypeDollarFixFlip
      ? acquisition.analytics.fixFlip.utilitiesTypeDollarFixFlip
      : 0
    const utilitiesPercent = assumptions.utilitiesFixFlip
      ? assumptions.utilitiesFixFlip
      : 0
    const financingFeeTotal = acquisition.analytics.fixFlip
      .financingFeeTotalFixFlip
      ? acquisition.analytics.fixFlip.financingFeeTotalFixFlip
      : 0
    const assetManagementFeeTotal = acquisition.analytics.fixFlip
      .assetManagementFeeTotalFixFlip
      ? acquisition.analytics.fixFlip.assetManagementFeeTotalFixFlip
      : 0
    const resellPrice = acquisition.analysis.resellPriceFixFlip
      ? acquisition.analysis.resellPriceFixFlip
      : 0
    const resalePrice = acquisition.analysis.resellPriceFixFlip
      ? acquisition.analysis.resellPriceFixFlip
      : acquisition.analytics.fixFlip.arvTotalFixFlip || 0

    const homeInsurance = acquisition.analytics.fixFlip
      .propertyInsuranceDollarFixFlip
      ? acquisition.analytics.fixFlip.propertyInsuranceDollarFixFlip
      : 0
    const maintenanceCost = acquisition.analytics.fixFlip
      .maintenanceCostFixFlip
      ? acquisition.analytics.fixFlip.maintenanceCostFixFlip
      : 0

    const dispositionFee = acquisition.analytics.fixFlip
      .dispositionFeeDollarFixFlip
      ? acquisition.analytics.fixFlip.dispositionFeeDollarFixFlip
      : 0
    const salesCommission = acquisition.analytics.fixFlip
      .salesCommissionTotalFixFlip
      ? acquisition.analytics.fixFlip.salesCommissionTotalFixFlip
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange'
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.fixFlip.hoaTotal
      ? acquisition.analytics.fixFlip.hoaTotal / 12
      : 0
    const offerPrice = acquisition.analysis.offerPrice
      ? acquisition.analysis.offerPrice
      : acquisition.analysis.listPrice
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentageFixFlip
      ? acquisition.analysis.financingRepairsPercentageFixFlip
      : 0
    // const rehabCost = acquisition.analysis.rehabCost
    //   ? acquisition.analysis.rehabCost
    //   : 0
    const rehabCost = acquisition.analytics.fixFlip.corTotalFixFlip
      ? acquisition.analytics.fixFlip.corTotalFixFlip
      : 0
    const downPayment = assumptions.downPaymentFixFlip
      ? assumptions.downPaymentFixFlip
      : 0
    const rate = assumptions.rateFixFlip ? assumptions.rateFixFlip : 0
    const term = assumptions.termFixFlip ? assumptions.termFixFlip : 0
    const termType = assumptions.termFixFlipType
      ? assumptions.termFixFlipType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceFixFlip
      ? assumptions.maintenanceFixFlip
      : 0
    const pointPercentage = assumptions.pointsFixFlip
      ? assumptions.pointsFixFlip
      : 0
    const financingFee = acquisition.analytics.fixFlip
      .financingFeePercentFixFlip
      ? acquisition.analytics.fixFlip.financingFeePercentFixFlip
      : 0
    const propertyMgmtPercent = acquisition.analytics.fixFlip
      .propertyManagementPercentageFixFlip
      ? acquisition.analytics.fixFlip
          .propertyManagementPercentageFixFlip
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const assetManagementFee = assumptions.assetManagementFee
      ? assumptions.assetManagementFee
      : 0
    const holdingPeriod = assumptions.holdingPeriod
      ? assumptions.holdingPeriod
      : 0

    const otherExpenses = assumptions.otherExpenseFixFlip
      ? assumptions.otherExpenseFixFlip
      : 0

    const ownerReserves = assumptions.ownerReservesFixFlip
      ? assumptions.ownerReservesFixFlip
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    const monthlyReservesDollar = acquisition.analytics.fixFlip
      .monthlyReservesTypeDollarFixFlip
      ? acquisition.analytics.fixFlip.monthlyReservesTypeDollarFixFlip
      : 0

    const renovationAmount = rehabCost || 0

    const acquisitionExpenses =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      financingFeeTotal +
      pointsAmount -
      creditFromSeller +
      renovationAmount * (1 - financingRepairsPercentage / 100) +
      customAcquisitionExpenseTotal

    const yearlyHOA = monthlyHOA * 12
    const rentalIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const vacancyRate1 =
      sum(
        map(
          zip(rentAmount, vacancy),
          ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
        ),
      ) * 12
    const vacancyRate3 = sum(
      map(
        zip(rentalIncome3, vacancy),
        ([rentalIncome3, vacancy]) => rentalIncome3 * (vacancy / 100),
      ),
    )
    const vacancyRate5 = sum(
      map(
        zip(rentalIncome5, vacancy),
        ([rentalIncome5, vacancy]) => rentalIncome5 * (vacancy / 100),
      ),
    )
    const vacancyRate10 = sum(
      map(
        zip(rentalIncome10, vacancy),
        ([rentalIncome10, vacancy]) =>
          rentalIncome10 * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly
    const grossRentalIncome1 = sum(rentAmount) * 12 - vacancyRate1
    const grossRentalIncome3 = sum(rentalIncome3) - vacancyRate3
    const grossRentalIncome5 = sum(rentalIncome5) - vacancyRate5
    const grossRentalIncome10 = sum(rentalIncome10) - vacancyRate10
    const propertyTaxes3 = expensesSection({
      expenseType: 'PT',
      revenueYear: 3,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes5 = expensesSection({
      expenseType: 'PT',
      revenueYear: 5,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes10 = expensesSection({
      expenseType: 'PT',
      revenueYear: 10,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance3 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 3,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance5 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 5,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance10 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 10,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA3 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 3,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA5 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 5,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA10 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 10,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyManagement1 = propertyManagementDollar
    const propertyManagement3 =
      assumptions.propertyManagementFixFlipType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome3) * (propertyMgmtPercent / 100)
    const propertyManagement5 =
      assumptions.propertyManagementFixFlipType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome5) * (propertyMgmtPercent / 100)
    const propertyManagement10 =
      assumptions.propertyManagementFixFlipType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome10) * (propertyMgmtPercent / 100)
    const maintenance1 = maintenanceCost
    const maintenance3 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance5 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance10 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const utilities1 = utilities * 12
    const utilities3 =
      assumptions.utilitiesFixFlipType === '$'
        ? utilities * 12
        : sum(rentalIncome3) * (utilitiesPercent / 100)
    const utilities5 =
      assumptions.utilitiesFixFlipType === '$'
        ? utilities * 12
        : sum(rentalIncome5) * (utilitiesPercent / 100)
    const utilities10 =
      assumptions.utilitiesFixFlipType === '$'
        ? utilities * 12
        : sum(rentalIncome10) * (utilitiesPercent / 100)
    const assetFee1 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 1,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
    })
    const assetFee3 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
    })
    const assetFee5 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
    })
    const assetFee10 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
    })
    const otherExpense3 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense5 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense10 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal3 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal5 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal10 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const totalExpense1 =
      propertyTaxes +
      homeInsurance +
      yearlyHOA +
      propertyManagement1 +
      maintenance1 +
      utilities1 +
      assetFee1 +
      otherExpenses +
      customAnnualExpenseTotal
    const totalExpense3 =
      propertyTaxes3 +
      homeInsurance3 +
      HOA3 +
      propertyManagement3 +
      maintenance3 +
      utilities3 +
      assetFee3 +
      otherExpense3 +
      customAnnualExpenseTotal3
    const totalExpense5 =
      propertyTaxes5 +
      homeInsurance5 +
      HOA5 +
      propertyManagement5 +
      maintenance5 +
      utilities5 +
      assetFee5 +
      otherExpense5 +
      customAnnualExpenseTotal5
    const totalExpense10 =
      propertyTaxes10 +
      homeInsurance10 +
      HOA10 +
      propertyManagement10 +
      maintenance10 +
      utilities10 +
      assetFee10 +
      otherExpense10 +
      customAnnualExpenseTotal10
    const expenseRatio1 = (totalExpense1 / grossRentalIncome1) * 100
    const expenseRatio3 = (totalExpense3 / grossRentalIncome3) * 100
    const expenseRatio5 = (totalExpense5 / grossRentalIncome5) * 100
    const expenseRatio10 =
      (totalExpense10 / grossRentalIncome10) * 100
    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOINoFinance1 = grossRentalIncome1 - totalExpense1
    const NOINoFinance3 = grossRentalIncome3 - totalExpense3
    const NOINoFinance5 = grossRentalIncome5 - totalExpense5
    const NOINoFinance10 = grossRentalIncome10 - totalExpense10
    const NOIFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOIFinance1 = grossRentalIncome1 - totalExpense1
    const NOIFinance3 = grossRentalIncome3 - totalExpense3
    const NOIFinance5 = grossRentalIncome5 - totalExpense5
    const NOIFinance10 = grossRentalIncome10 - totalExpense10
    const mortgagePaymentYearly = mortgagePayment * 12
    const totalReturnFinancing = []
    const totalReturnFinancing1 =
      NOINoFinance1 - mortgagePaymentYearly
    const totalReturnFinancing3 =
      NOINoFinance3 - mortgagePaymentYearly
    const totalReturnFinancing5 =
      NOINoFinance5 - mortgagePaymentYearly
    const totalReturnFinancing10 =
      NOINoFinance10 - mortgagePaymentYearly
    totalReturnFinancing.push({
      one: totalReturnFinancing1,
      three: totalReturnFinancing3,
      five: totalReturnFinancing5,
      ten: totalReturnFinancing10,
    })
    const totalReturnNoFinancing1 = NOINoFinance1
    const totalReturnNoFinancing3 = NOINoFinance3
    const totalReturnNoFinancing5 = NOINoFinance5
    const totalReturnNoFinancing10 = NOINoFinance10

    const monthlyRate = rate / 12 / 100

    const loanBalanceAtHoldingPeriod = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: holdingPeriod,
      paydownMonthly,
      holdingPeriod: true,
    })

    const monthlyHoldingExpenses =
      homeInsurance / 12 +
      propertyManagementDollar / 12 +
      monthlyHOA +
      propertyTaxes / 12 +
      maintenanceCost / 12 +
      utilities +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12 +
      monthlyReservesDollar +
      mortgagePayment

    const dispositionExpenses =
      loanBalanceAtHoldingPeriod +
      sellerCloseCostDollar +
      salesCommission +
      dispositionFee

    const netProfit =
      resalePrice -
      acquisitionExpenses -
      monthlyHoldingExpenses * holdingPeriod -
      dispositionExpenses

    const cashFlowAccumulation = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: termType === 'Years' ? term * 12 + 1 : term + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const numberFormat = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const percentageFormat = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
    })

    return [
      {
        margin: [15, 0, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*'],
          body: compact([
            [
              {
                text: 'Acquisition Expenses',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 2,
                alignment: 'left',
              },
              { text: '' },
            ],
            downPaymentTotal
              ? [
                  {
                    text: 'Down Payment (' + downPayment + '%)',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(downPaymentTotal),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            acquisitionFee
              ? [
                  {
                    text: 'Acquisition Fee',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(acquisitionFee),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            acquisitionCommissionDollar
              ? [
                  {
                    text:
                      'Acquisition Commission (' +
                      acquisitionCommissionPercent +
                      '%)',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      acquisitionCommissionDollar,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            closeCostDollar
              ? [
                  {
                    text:
                      'Est Closing Cost (' + closeCostPercent + '%)',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(closeCostDollar),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            financingFeeTotal
              ? [
                  {
                    text: 'Financing Fee (' + financingFee + '%)',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(financingFeeTotal),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            customAcquisitionExpenseTotal
              ? [
                  {
                    text: 'Custom Expenses',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      customAcquisitionExpenseTotal,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            pointsAmount
              ? [
                  {
                    text: 'Points (' + pointPercentage + '%)',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(pointsAmount),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            creditFromSeller
              ? [
                  {
                    text: '-Credit From Seller',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      -1 * creditFromSeller,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            renovationAmount
              ? [
                  {
                    text: 'Rehab Amount',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      renovationAmount *
                        (1 - financingRepairsPercentage / 100),
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            [
              {
                text: 'Acquisition Expenses',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(acquisitionExpenses),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
            ],
          ]),
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*'],
          body: compact([
            [
              {
                text: `Holding Expenses (${holdingPeriod} months)`,
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                alignment: 'left',
              },
              {
                text: 'Monthly Amount',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                alignment: 'right',
              },
              {
                text: `${holdingPeriod} Month Total`,
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                alignment: 'right',
              },
            ],
            propertyTaxes
              ? [
                  {
                    text: 'Monthly Property Taxes',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(propertyTaxes / 12),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      (propertyTaxes / 12) * holdingPeriod,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            homeInsurance
              ? [
                  {
                    text: 'Monthly Homeowners Insurance',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(homeInsurance / 12),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      (homeInsurance / 12) * holdingPeriod,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            propertyManagementDollar
              ? [
                  {
                    text: 'Property Management',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      propertyManagementDollar / 12,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      (propertyManagementDollar / 12) * holdingPeriod,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            monthlyHOA
              ? [
                  {
                    text: 'Monthly HOA Fees',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(monthlyHOA),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      monthlyHOA * holdingPeriod,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            maintenanceCost
              ? [
                  {
                    text: 'Monthly Maintenance Cost',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      maintenanceCost / 12,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      (maintenanceCost / 12) * holdingPeriod,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            utilities
              ? [
                  {
                    text: 'Monthly Utilities',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(utilities),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      utilities * holdingPeriod,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            monthlyReservesDollar
              ? [
                  {
                    text: 'Monthly Owner Reserves',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      monthlyReservesDollar,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      monthlyReservesDollar * holdingPeriod,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            otherExpenses
              ? [
                  {
                    text: 'Other Monthly Expenses',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherExpenses / 12),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      (otherExpenses / 12) * holdingPeriod,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            customAnnualExpenseTotal
              ? [
                  {
                    text: 'Custom Monthly Expenses',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      customAnnualExpenseTotal / 12,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      (customAnnualExpenseTotal / 12) * holdingPeriod,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            mortgagePayment
              ? [
                  {
                    text: 'Mortgage',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(mortgagePayment),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      mortgagePayment * holdingPeriod,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            [
              {
                text: 'Holding Expenses',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(monthlyHoldingExpenses),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(
                  monthlyHoldingExpenses * holdingPeriod,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
            ],
          ]),
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*'],
          body: compact([
            [
              {
                text: 'Disposition Expenses',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 2,
                alignment: 'left',
              },
              { text: '' },
            ],
            loanBalanceAtHoldingPeriod
              ? [
                  {
                    text: 'Loan Balance at Resale',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      loanBalanceAtHoldingPeriod,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            sellerCloseCostDollar
              ? [
                  {
                    text:
                      'Est Closing Cost (' +
                      sellerCloseCostPercent +
                      '%)',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      sellerCloseCostDollar,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            salesCommission
              ? [
                  {
                    text: 'Sales Commission',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(salesCommission),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            dispositionFee
              ? [
                  {
                    text: 'Disposition Fee',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(dispositionFee),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            [
              {
                text: 'Disposition Expenses',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(dispositionExpenses),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
            ],
          ]),
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*'],
          body: [
            [
              {
                text: 'Net Profit',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 2,
                alignment: 'left',
              },
              { text: '' },
            ],
            [
              {
                text: 'Resell Price',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(resalePrice),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Acquisition Expenses',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(acquisitionExpenses),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Holding Expenses',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(
                  monthlyHoldingExpenses * holdingPeriod,
                ),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Disposition Expenses',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(dispositionExpenses),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Net Profit',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(netProfit),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 10, 15, 15],
        color: 'gray',
        fontSize: 8,
        text: legalDescription ? legalDescription : '',
        //'The above agent/brokerage makes no warranty or representation about the content of this brochure. While the information displayed herein is thought to be accurate, it is your responsibility to independently confirm its accuracy and completeness. Any projections, opinions, assumptions or estimates are used for example only and do not represent the current or future performance of the property. The above agent/brokerage neither practices accounting nor gives advice regarding tax benefits/liabilities or any other tax, accounting or financial consideration, nor does the above agent/brokerage give advice regarding financial investments. It is strongly recommended that you seek appropriate professional counsel regarding your rights as a homeowner.',
      },
    ]
  }

  const shortTermRentalPage2Bottom = async (property) => {
    const {
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    const { legalDescription = '' } = organization

    const avgRate = map(
      zip(assumptions.avgRate, assumptions.avgRateType),
      ([avgRate, avgRateType]) =>
        avgRateType === 'Weekly'
          ? avgRate
            ? avgRate / 7
            : 150 / 7
          : avgRate
            ? avgRate
            : 150,
    )
    const mortgagePayment = acquisition.analytics.STR.mortgageSTR
      ? acquisition.analytics.STR.mortgageSTR
      : 0
    const downPaymentTotal = acquisition.analytics.STR
      .downPaymentTotalSTR
      ? acquisition.analytics.STR.downPaymentTotalSTR
      : 0
    const pointsAmount = acquisition.analytics.STR.pointsAmountSTR
      ? acquisition.analytics.STR.pointsAmountSTR
      : 0
    const creditFromSeller = assumptions.creditFromSeller
      ? assumptions.creditFromSeller
      : 0
    const closeCostDollar = acquisition.analytics.STR
      .closeCostDollarSTR
      ? acquisition.analytics.STR.closeCostDollarSTR
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollar
      ? acquisition.analytics.acquisitionCommissionDollar
      : 0
    const cohostingDollar = acquisition.analytics.STR.cohostingDollar
      ? acquisition.analytics.STR.cohostingDollar / 12
      : 0
    const utilities = acquisition.analytics.STR.utilitiesTypeDollarSTR
      ? acquisition.analytics.STR.utilitiesTypeDollarSTR
      : 0
    const utilitiesPercent = assumptions.utilitiesSTR
      ? assumptions.utilitiesSTR
      : 0
    const financingFeeTotal = acquisition.analytics.STR
      .financingFeeTotal
      ? acquisition.analytics.STR.financingFeeTotal
      : 0
    const assetManagementFeeTotal = acquisition.analytics.STR
      .assetManagementFeeTotalSTR
      ? acquisition.analytics.STR.assetManagementFeeTotalSTR
      : 0

    const homeInsurance = acquisition.analytics.STR
      .propertyInsuranceDollarSTR
      ? acquisition.analytics.STR.propertyInsuranceDollarSTR
      : 0
    const maintenanceCost = acquisition.analytics.STR
      .maintenanceCostSTR
      ? acquisition.analytics.STR.maintenanceCostSTR
      : 0
    const monthlyReserves = acquisition.analytics.STR
      .monthlyReservesTypeDollarSTR
      ? acquisition.analytics.STR.monthlyReservesTypeDollarSTR
      : 0

    const rentAmount =
      assumptions.rentSTRType === 'rentRange'
        ? acquisition.analysis.rentLowSTR &&
          acquisition.analysis.rentHighSTR
          ? map(
              zip(
                acquisition.analysis.rentLowSTR,
                acquisition.analysis.rentHighSTR,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rentSTR
          ? acquisition.analysis.rentSTR
          : [0]
    const otherIncome = acquisition.analysis.otherIncome
      ? acquisition.analysis.otherIncome
      : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.STR.hoaTotal
      ? acquisition.analytics.STR.hoaTotal / 12
      : 0
    const offerPrice = acquisition.analysis.offerPrice
      ? acquisition.analysis.offerPrice
      : acquisition.analysis.listPrice
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentageSTR
      ? acquisition.analysis.financingRepairsPercentageSTR
      : 0
    // const rehabCost = acquisition.analysis.rehabCost
    //   ? acquisition.analysis.rehabCost
    //   : 0
    const rehabCost = acquisition.analytics.STR.corTotalSTR
      ? acquisition.analytics.STR.corTotalSTR
      : 0
    const term = assumptions.termSTR ? assumptions.termSTR : 0
    const termType = assumptions.termSTRType
      ? assumptions.termSTRType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceSTR
      ? assumptions.maintenanceSTR
      : 0
    const cohostingPercentage = acquisition.analytics.STR
      .cohostingPercentage
      ? acquisition.analytics.STR.cohostingPercentage
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const assetManagementFee = assumptions.assetManagementFee
      ? assumptions.assetManagementFee
      : 0

    const otherExpenses = assumptions.otherExpenseSTR
      ? assumptions.otherExpenseSTR
      : 0

    const ownerReserves = assumptions.ownerReservesSTR
      ? assumptions.ownerReservesSTR
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    const occupancyRate = assumptions.occupancyRate
      ? assumptions.occupancyRate
      : 0

    const renovationAmount = rehabCost || 0

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      financingFeeTotal +
      pointsAmount -
      creditFromSeller +
      renovationAmount * (1 - financingRepairsPercentage / 100) +
      customAcquisitionExpenseTotal
    const totalInitialInvestmentNoFinancing =
      offerPrice +
      renovationAmount +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      pointsAmount +
      financingFeeTotal +
      ownerReserves +
      customAcquisitionExpenseTotal
    const yearlyHOA = monthlyHOA * 12
    const rentalIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })

    const avgMonthlyRate = map(
      avgRate,
      (avgRate) => (avgRate * 365) / 12,
    )

    const potentialRentalIncome3 = revenuesSectionSTR({
      revenueYear: 3,
      avgMonthlyRate: avgMonthlyRate,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const potentialRentalIncome5 = revenuesSectionSTR({
      revenueYear: 5,
      avgMonthlyRate: avgMonthlyRate,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const potentialRentalIncome10 = revenuesSectionSTR({
      revenueYear: 10,
      avgMonthlyRate: avgMonthlyRate,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })

    const occupancyRateMonthly = sum(
      map(
        zip(avgMonthlyRate, occupancyRate),
        ([avgMonthlyRate, occupancyRate]) =>
          (avgMonthlyRate * occupancyRate) / 100,
      ),
    )
    const occupancyRate1 = occupancyRateMonthly * 12
    const occupancyRate3 = sum(
      map(
        zip(potentialRentalIncome3, occupancyRate),
        ([avgRate, occupancyRate]) => (avgRate * occupancyRate) / 100,
      ),
    )
    const occupancyRate5 = sum(
      map(
        zip(potentialRentalIncome5, occupancyRate),
        ([avgRate, occupancyRate]) => (avgRate * occupancyRate) / 100,
      ),
    )
    const occupancyRate10 = sum(
      map(
        zip(potentialRentalIncome10, occupancyRate),
        ([avgRate, occupancyRate]) => (avgRate * occupancyRate) / 100,
      ),
    )

    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const vacancyRate1 =
      sum(
        map(
          zip(rentAmount, vacancy),
          ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
        ),
      ) * 12
    const vacancyRate3 = sum(
      map(
        zip(rentalIncome3, vacancy),
        ([rentalIncome3, vacancy]) => rentalIncome3 * (vacancy / 100),
      ),
    )
    const vacancyRate5 = sum(
      map(
        zip(rentalIncome5, vacancy),
        ([rentalIncome5, vacancy]) => rentalIncome5 * (vacancy / 100),
      ),
    )
    const vacancyRate10 = sum(
      map(
        zip(rentalIncome10, vacancy),
        ([rentalIncome10, vacancy]) =>
          rentalIncome10 * (vacancy / 100),
      ),
    )
    const otherIncomeMonthly = sum(otherIncome)
    const otherIncome1 = sum(otherIncome) * 12
    const otherIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const grossRentalIncome1 =
      acquisition.analytics.STR.grossAnnualRentSTR
    const grossRentalIncomeMonthly = map(
      grossRentalIncome1,
      (grossRentalIncome1) => grossRentalIncome1 / 12,
    )
    const grossRentalIncome3 = revenuesSectionSTR({
      revenueYear: 3,
      avgMonthlyRate: grossRentalIncomeMonthly,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const grossRentalIncome5 = revenuesSectionSTR({
      revenueYear: 5,
      avgMonthlyRate: grossRentalIncomeMonthly,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const grossRentalIncome10 = revenuesSectionSTR({
      revenueYear: 10,
      avgMonthlyRate: grossRentalIncomeMonthly,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const monthlyRevenue =
      sum(grossRentalIncomeMonthly) + otherIncomeMonthly
    const revenueTotal1 = sum(grossRentalIncome1) + otherIncome1
    const revenueTotal3 = sum(grossRentalIncome3) + sum(otherIncome3)
    const revenueTotal5 = sum(grossRentalIncome5) + sum(otherIncome5)
    const revenueTotal10 =
      sum(grossRentalIncome10) + sum(otherIncome10)

    const propertyTaxes3 = expensesSection({
      expenseType: 'PT',
      revenueYear: 3,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes5 = expensesSection({
      expenseType: 'PT',
      revenueYear: 5,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes10 = expensesSection({
      expenseType: 'PT',
      revenueYear: 10,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance3 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 3,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance5 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 5,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance10 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 10,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA3 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 3,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA5 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 5,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA10 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 10,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const cohosting1 = acquisition.analytics.STR.cohostingDollar
    const cohosting3 =
      assumptions.cohostingType === '$'
        ? cohostingDollar
        : sum(grossRentalIncome3) * (cohostingPercentage / 100)
    const cohosting5 =
      assumptions.cohostingType === '$'
        ? cohostingDollar
        : sum(grossRentalIncome5) * (cohostingPercentage / 100)
    const cohosting10 =
      assumptions.cohostingType === '$'
        ? cohostingDollar
        : sum(grossRentalIncome10) * (cohostingPercentage / 100)
    const reserves1 = monthlyReserves * 12
    const reserves3 = expensesSection({
      expenseType: 'RESERVE',
      revenueYear: 3,
      rentAmount: sum(grossRentalIncome1),
      yearlyReserve: reserves1,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const reserves5 = expensesSection({
      expenseType: 'RESERVE',
      revenueYear: 5,
      rentAmount: sum(grossRentalIncome1),
      yearlyReserve: reserves1,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const reserves10 = expensesSection({
      expenseType: 'RESERVE',
      revenueYear: 10,
      rentAmount: sum(grossRentalIncome1),
      yearlyReserve: reserves1,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance1 = maintenanceCost
    const maintenance3 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 3,
      rentAmount: sum(grossRentalIncome1),
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance5 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 5,
      rentAmount: sum(grossRentalIncome1),
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance10 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 10,
      rentAmount: sum(grossRentalIncome1),
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const utilities1 = utilities * 12
    const utilities3 =
      assumptions.utilitiesSTRType === '$'
        ? utilities * 12
        : sum(grossRentalIncome3) * (utilitiesPercent / 100)
    const utilities5 =
      assumptions.utilitiesSTRType === '$'
        ? utilities * 12
        : sum(grossRentalIncome5) * (utilitiesPercent / 100)
    const utilities10 =
      assumptions.utilitiesSTRType === '$'
        ? utilities * 12
        : sum(grossRentalIncome10) * (utilitiesPercent / 100)
    const assetFee1 = assetManagementFeeTotal
    const assetFee3 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee5 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee10 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const otherExpense3 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense5 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense10 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal3 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal5 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal10 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      cohostingDollar +
      (maintenance / 100) * sum(grossRentalIncomeMonthly) +
      utilities +
      monthlyReserves +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const totalExpense1 =
      propertyTaxes +
      homeInsurance +
      yearlyHOA +
      cohosting1 +
      maintenance1 +
      utilities1 +
      reserves1 +
      assetFee1 +
      otherExpenses +
      customAnnualExpenseTotal
    const totalExpense3 =
      propertyTaxes3 +
      homeInsurance3 +
      HOA3 +
      cohosting3 +
      maintenance3 +
      utilities3 +
      reserves3 +
      assetFee3 +
      otherExpense3 +
      customAnnualExpenseTotal3
    const totalExpense5 =
      propertyTaxes5 +
      homeInsurance5 +
      HOA5 +
      cohosting5 +
      maintenance5 +
      utilities5 +
      reserves5 +
      assetFee5 +
      otherExpense5 +
      customAnnualExpenseTotal5
    const totalExpense10 =
      propertyTaxes10 +
      homeInsurance10 +
      HOA10 +
      cohosting10 +
      maintenance10 +
      reserves10 +
      utilities10 +
      assetFee10 +
      otherExpense10 +
      customAnnualExpenseTotal10
    const expenseRatio1 = (totalExpense1 / grossRentalIncome1) * 100
    const expenseRatio3 = (totalExpense3 / grossRentalIncome3) * 100
    const expenseRatio5 = (totalExpense5 / grossRentalIncome5) * 100
    const expenseRatio10 =
      (totalExpense10 / grossRentalIncome10) * 100
    const NOINoFinanceMonthly = monthlyRevenue - totalExpenseMonthly
    const NOINoFinance1 = revenueTotal1 - totalExpense1
    const NOINoFinance3 = revenueTotal3 - totalExpense3
    const NOINoFinance5 = revenueTotal5 - totalExpense5
    const NOINoFinance10 = revenueTotal10 - totalExpense10
    const NOIFinanceMonthly = monthlyRevenue - totalExpenseMonthly
    const NOIFinance1 = revenueTotal1 - totalExpense1
    const NOIFinance3 = revenueTotal3 - totalExpense3
    const NOIFinance5 = revenueTotal5 - totalExpense5
    const NOIFinance10 = revenueTotal10 - totalExpense10
    const mortgagePaymentYearly = mortgagePayment * 12
    const totalReturnFinancing = []
    const totalReturnFinancing1 =
      NOINoFinance1 - mortgagePaymentYearly
    const totalReturnFinancing3 =
      NOINoFinance3 - mortgagePaymentYearly
    const totalReturnFinancing5 =
      NOINoFinance5 - mortgagePaymentYearly
    const totalReturnFinancing10 =
      NOINoFinance10 - mortgagePaymentYearly
    totalReturnFinancing.push({
      one: totalReturnFinancing1,
      three: totalReturnFinancing3,
      five: totalReturnFinancing5,
      ten: totalReturnFinancing10,
    })
    const totalROIFinancing1 =
      (totalReturnFinancing1 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing3 =
      (totalReturnFinancing3 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing5 =
      (totalReturnFinancing5 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing10 =
      (totalReturnFinancing10 / totalInitialInvestmentFinancing) * 100
    const totalReturnNoFinancing1 = NOINoFinance1
    const totalReturnNoFinancing3 = NOINoFinance3
    const totalReturnNoFinancing5 = NOINoFinance5
    const totalReturnNoFinancing10 = NOINoFinance10
    const totalROINoFinancing1 =
      (totalReturnNoFinancing1 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing3 =
      (totalReturnNoFinancing3 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing5 =
      (totalReturnNoFinancing5 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing10 =
      (totalReturnNoFinancing10 / totalInitialInvestmentNoFinancing) *
      100

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const numberFormat = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const percentageFormat = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
    })

    return [
      {
        margin: [15, 10, 15, 0],
        table: {
          headerRows: 0,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Annual Revenue Increase',
                alignment: 'left',
                fontSize: 6,
              },
              { text: '' },
              {
                text: annualRevenueIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualRevenueIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualRevenueIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualRevenueIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Annual Operating Expense Increase',
                alignment: 'left',
                fontSize: 6,
              },
              { text: '' },
              {
                text: annualOperatingExpenseIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualOperatingExpenseIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualOperatingExpenseIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualOperatingExpenseIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Annual Expense Ratio',
                alignment: 'left',
                fontSize: 6,
              },
              { text: '' },
              {
                text: expenseRatio1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio10.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
            ],
            //[{text: 'Estimated Tax Bracket', alignment: 'left',fontSize: 6,}, {text: '$123.00', alignment: 'right',fontSize: 6,},{text: '0%', alignment: 'right',fontSize: 6,}, {text: '1%', alignment: 'right',fontSize: 6,}, {text: '1%', alignment: 'right',fontSize: 6,}, {text: '1%', alignment: 'right',fontSize: 6,}]
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 0,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              { text: '', alignment: 'left', fontSize: 6 },
              {
                text: 'Monthly',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 1',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 3',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 5',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 10',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 0, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*'],
          body: compact([
            [
              {
                text: 'Revenues',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 6,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            avgMonthlyRate
              ? [
                  {
                    text: 'Potential Rental Income',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      sum(avgMonthlyRate),
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      sum(avgMonthlyRate) * 12,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      sum(potentialRentalIncome3),
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      sum(potentialRentalIncome5),
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      sum(potentialRentalIncome10),
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            occupancyRate1
              ? [
                  {
                    text: 'Occupancy Rate',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      occupancyRateMonthly,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(occupancyRate1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(occupancyRate3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(occupancyRate5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(occupancyRate10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            otherIncomeMonthly
              ? [
                  {
                    text: 'Other Income',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherIncomeMonthly),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherIncome1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(sum(otherIncome3)),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(sum(otherIncome5)),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(sum(otherIncome10)),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            [
              {
                text: 'Gross Rental Income',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(monthlyRevenue),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(revenueTotal1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(revenueTotal3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(revenueTotal5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(revenueTotal10),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
            ],
          ]),
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*'],
          body: compact([
            [
              {
                text: 'Expenses',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 6,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            propertyTaxes
              ? [
                  {
                    text: 'Estimated Annual Property Taxes',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(propertyTaxes / 12),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(propertyTaxes),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(propertyTaxes3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(propertyTaxes5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(propertyTaxes10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            homeInsurance
              ? [
                  {
                    text: 'Estimated Annual Homeowners Insurance',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(homeInsurance / 12),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(homeInsurance),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(homeInsurance3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(homeInsurance5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(homeInsurance10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            monthlyHOA
              ? [
                  {
                    text: 'Estimated Annual HOA Dues',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(monthlyHOA),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(yearlyHOA),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(HOA3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(HOA5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(HOA10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            cohostingDollar
              ? [
                  {
                    text:
                      'Cohosting Fee (' + cohostingPercentage + '%)',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(cohostingDollar),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(cohosting1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(cohosting3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(cohosting5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(cohosting10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            reserves1
              ? [
                  {
                    text: 'Estimated Annual Reserves',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(monthlyReserves),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(reserves1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(reserves3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(reserves5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(reserves10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            maintenance1
              ? [
                  {
                    text:
                      'Maintenance & Repairs (' + maintenance + '%)',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      (maintenance / 100) *
                        sum(grossRentalIncomeMonthly),
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(maintenance1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(maintenance3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(maintenance5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(maintenance10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            utilities
              ? [
                  {
                    text: 'Utilities',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(utilities),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(utilities1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(utilities3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(utilities5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(utilities10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            assetFee1
              ? [
                  {
                    text:
                      'Asset Management Fee (' +
                      assetManagementFee +
                      '%)',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(assetFee1 / 12),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(assetFee1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(assetFee3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(assetFee5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(assetFee10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            otherExpenses
              ? [
                  {
                    text: 'Other Expenses',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherExpenses / 12),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherExpenses),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherExpense3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherExpense5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(otherExpense10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            customAnnualExpenseTotal
              ? [
                  {
                    text: 'Custom Expenses',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      customAnnualExpenseTotal / 12,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      customAnnualExpenseTotal,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      customAnnualExpenseTotal3,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      customAnnualExpenseTotal5,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      customAnnualExpenseTotal10,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            [
              {
                text: 'Total Expenses',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(totalExpenseMonthly),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(totalExpense1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(totalExpense3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(totalExpense5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: currencyFormater.format(totalExpense10),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
            ],
          ]),
        },
        layout: {
          defaultBorder: false,
        },
      },
      // {
      //   margin: [15,5,15,0],
      //         table:
      //         {
      //           headerRows: 1,
      //           widths: [150,'*','*','*','*','*'],
      //           body: [
      //             [{text: 'Net Operating Income (NOI)', fillColor: '#3399ff', color: 'white', fontSize: 8, alignment: 'left',}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinanceMonthly)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance1)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance3)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance5)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance10)},],
      //           ]
      //         },
      //         layout: {
      //           defaultBorder: false,
      //         }
      // },
      // {
      //   margin: [15,5,15,0],
      //         table:
      //         {
      //           headerRows: 1,
      //           widths: [150,'*','*','*','*','*'],
      //           body: [
      //             [{text: 'Estimated Annual Property Taxes', alignment: 'left',fontSize: 8,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes / 12), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes * 3), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes * 5), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes * 10), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}],
      //           ]
      //         },
      //         layout: {
      //           defaultBorder: false,
      //         }
      // },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Returns Without Financing',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 6,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'NOI (Cash Available)',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinanceMonthly),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance5),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance10),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            // [{text: 'Mortgage', alignment: 'left',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}],
            // [{text: 'Total Cash Flow', alignment: 'left',fontSize: 6, bold: true}, {text: currencyFormater.format(NOIFinanceMonthly), alignment: 'right',fontSize: 6,bold: true},{text: currencyFormater.format(NOINoFinance1), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance3), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance5), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance10), alignment: 'right',fontSize: 6,bold: true}],
            //[{text: 'Principal Paydown', alignment: 'left',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            //[{text: 'Estimated Tax Savings', alignment: 'left',fontSize: 6, }, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            [
              {
                text: 'Net Cashflow',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing1,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing3,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing5,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing10,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
            [
              {
                text: 'Cap Rate',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: totalROINoFinancing1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: totalROINoFinancing3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: totalROINoFinancing5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
              {
                text: totalROINoFinancing10.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, true, false, false],
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Returns With Financing',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 6,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'NOI (Cash Available)',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinanceMonthly),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance5),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance10),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Mortgage',
                alignment: 'left',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePayment),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
            ],
            //[{text: 'Principal Paydown', alignment: 'left',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            //[{text: 'Estimated Tax Savings', alignment: 'left',fontSize: 6, }, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            [
              {
                text: 'Net Cashflow',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing10),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
            [
              {
                text: 'Cash On Cash',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing10.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 10, 15, 15],
        color: 'gray',
        fontSize: 8,
        text: legalDescription ? legalDescription : '',
        //'The above agent/brokerage makes no warranty or representation about the content of this brochure. While the information displayed herein is thought to be accurate, it is your responsibility to independently confirm its accuracy and completeness. Any projections, opinions, assumptions or estimates are used for example only and do not represent the current or future performance of the property. The above agent/brokerage neither practices accounting nor gives advice regarding tax benefits/liabilities or any other tax, accounting or financial consideration, nor does the above agent/brokerage give advice regarding financial investments. It is strongly recommended that you seek appropriate professional counsel regarding your rights as a homeowner.',
      },
    ]
  }

  const southernImpressionsPage2Bottom = async (property) => {
    const {
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    const { legalDescription = '' } = organization

    const mortgagePayment = acquisition.analytics.turnkey
      .mortgageTurnkey
      ? acquisition.analytics.turnkey.mortgageTurnkey
      : 0
    const downPaymentTotal = acquisition.analytics.turnkey
      .downPaymentTotalTurnkey
      ? acquisition.analytics.turnkey.downPaymentTotalTurnkey
      : 0
    const pointsAmount = acquisition.analytics.turnkey
      .pointsAmountTurnkey
      ? acquisition.analytics.turnkey.pointsAmountTurnkey
      : 0
    const closeCostDollar = acquisition.analytics.turnkey
      .closeCostDollarTurnkey
      ? acquisition.analytics.turnkey.closeCostDollarTurnkey
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollarTurnkey
      ? acquisition.analytics.acquisitionCommissionDollarTurnkey
      : 0
    const propertyManagementDollar = acquisition.analytics.turnkey
      .propertyManagementDollarTurnkey
      ? acquisition.analytics.turnkey.propertyManagementDollarTurnkey
      : 0
    const utilities = acquisition.analytics.turnkey
      .utilitiesTypeDollarTurnkey
      ? acquisition.analytics.turnkey.utilitiesTypeDollarTurnkey
      : 0
    const utilitiesPercent = assumptions.utilitiesTurnkey
      ? assumptions.utilitiesTurnkey
      : 0
    const financingFeeTotal = acquisition.analytics.turnkey
      .financingFeeTotalTurnkey
      ? acquisition.analytics.turnkey.financingFeeTotalTurnkey
      : 0
    const assetManagementFeeTotal = acquisition.analytics.turnkey
      .assetManagementFeeTotalTurnkey
      ? acquisition.analytics.turnkey.assetManagementFeeTotalTurnkey
      : 0

    const homeInsurance = acquisition.analytics.turnkey
      .propertyInsuranceDollarTurnkey
      ? acquisition.analytics.turnkey.propertyInsuranceDollarTurnkey
      : 0
    const maintenanceCost = acquisition.analytics.turnkey
      .maintenanceCostTurnkey
      ? acquisition.analytics.turnkey.maintenanceCostTurnkey
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange' //SPECIFIC COMMENT: Southern Impressions calculate range with rentHigh value not average of low and high
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => high,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const otherIncome = acquisition.analysis.otherIncome
      ? acquisition.analysis.otherIncome
      : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.turnkey.hoaTotal
      ? acquisition.analytics.turnkey.hoaTotal / 12
      : 0
    const offerPrice = acquisition.analysis.offerPrice
      ? acquisition.analysis.offerPrice
      : acquisition.analysis.listPrice
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentageTurnkey
      ? acquisition.analysis.financingRepairsPercentageTurnkey
      : 0
    // const rehabCost = acquisition.analysis.endRehabCost
    //   ? acquisition.analysis.endRehabCost
    //   : 0
    const rehabCost = acquisition.analytics.turnkey.corTotalTurnkey
      ? acquisition.analytics.turnkey.corTotalTurnkey
      : 0
    const term = assumptions.termTurnkey ? assumptions.termTurnkey : 0
    const termType = assumptions.termTurnkeyType
      ? assumptions.termTurnkeyType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceTurnkey
      ? assumptions.maintenanceTurnkey
      : 0
    const propertyMgmtPercent = acquisition.analytics.turnkey
      .propertyManagementPercentageTurnkey
      ? acquisition.analytics.turnkey
          .propertyManagementPercentageTurnkey
      : 0
    const sellingClosingCostPercent = acquisition.analytics.turnkey
      .sellingClosingCostPercentTurnkey
      ? acquisition.analytics.turnkey.sellingClosingCostPercentTurnkey
      : 0
    const homePriceAppreciation = assumptions.homePriceAppreciation
      ? assumptions.homePriceAppreciation
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const assetManagementFee = assumptions.assetManagementFee
      ? assumptions.assetManagementFee
      : 0

    const otherExpenses = assumptions.otherExpenseTurnkey
      ? assumptions.otherExpenseTurnkey
      : 0

    const ownerReserves = assumptions.ownerReservesTurnkey
      ? assumptions.ownerReservesTurnkey
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    const renovationAmount = rehabCost || 0

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      financingFeeTotal +
      pointsAmount +
      renovationAmount * (1 - financingRepairsPercentage / 100) +
      customAcquisitionExpenseTotal
    const totalInitialInvestmentNoFinancing =
      offerPrice +
      renovationAmount +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      pointsAmount +
      financingFeeTotal +
      ownerReserves +
      customAcquisitionExpenseTotal
    const yearlyHOA = monthlyHOA * 12
    const rentalIncome2 = revenuesSection({
      revenueYear: 2,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome4 = revenuesSection({
      revenueYear: 4,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncomeMonthly = sum(otherIncome)
    const otherIncome1 = sum(otherIncome) * 12
    const otherIncome2 = revenuesSection({
      revenueYear: 2,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome4 = revenuesSection({
      revenueYear: 4,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const vacancyRate1 =
      sum(
        map(
          zip(rentAmount, vacancy),
          ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
        ),
      ) * 12
    const vacancyRate2 = sum(
      map(
        zip(rentalIncome2, vacancy),
        ([rentalIncome2, vacancy]) => rentalIncome2 * (vacancy / 100),
      ),
    )
    const vacancyRate3 = sum(
      map(
        zip(rentalIncome3, vacancy),
        ([rentalIncome3, vacancy]) => rentalIncome3 * (vacancy / 100),
      ),
    )
    const vacancyRate4 = sum(
      map(
        zip(rentalIncome4, vacancy),
        ([rentalIncome4, vacancy]) => rentalIncome4 * (vacancy / 100),
      ),
    )
    const vacancyRate5 = sum(
      map(
        zip(rentalIncome5, vacancy),
        ([rentalIncome5, vacancy]) => rentalIncome5 * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly
    const grossRentalIncome1 = sum(rentAmount) * 12 - vacancyRate1
    const grossRentalIncome2 = sum(rentalIncome2) - vacancyRate2
    const grossRentalIncome3 = sum(rentalIncome3) - vacancyRate3
    const grossRentalIncome4 = sum(rentalIncome4) - vacancyRate4
    const grossRentalIncome5 = sum(rentalIncome5) - vacancyRate5
    const monthlyRevenue =
      grossRentalIncomeMonthly + otherIncomeMonthly
    const revenueTotal1 = grossRentalIncome1 + otherIncome1
    const revenueTotal2 = grossRentalIncome2 + otherIncome2
    const revenueTotal3 = grossRentalIncome3 + otherIncome3
    const revenueTotal4 = grossRentalIncome4 + otherIncome4
    const revenueTotal5 = grossRentalIncome5 + otherIncome5
    const propertyTaxesDiscount = (propertyTaxes / 2 + 400) / 12
    const propertyTaxes2 = expensesSection({
      expenseType: 'PT',
      revenueYear: 2,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes3 = expensesSection({
      expenseType: 'PT',
      revenueYear: 3,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes4 = expensesSection({
      expenseType: 'PT',
      revenueYear: 4,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes5 = expensesSection({
      expenseType: 'PT',
      revenueYear: 5,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance2 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 2,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance3 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 3,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance4 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 4,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance5 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 5,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA2 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 2,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA3 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 3,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA4 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 4,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA5 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 5,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyManagement1 = propertyManagementDollar
    const propertyManagement2 =
      assumptions.propertyManagementTurnkeyType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome2) * (propertyMgmtPercent / 100)
    const propertyManagement3 =
      assumptions.propertyManagementTurnkeyType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome3) * (propertyMgmtPercent / 100)
    const propertyManagement4 =
      assumptions.propertyManagementTurnkeyType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome4) * (propertyMgmtPercent / 100)
    const propertyManagement5 =
      assumptions.propertyManagementTurnkeyType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome5) * (propertyMgmtPercent / 100)
    const maintenance1 = maintenanceCost
    const maintenance2 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 2,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance3 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance4 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 4,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance5 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const utilities1 = utilities * 12
    const utilities2 =
      assumptions.utilitiesTurnkeyType === '$'
        ? utilities * 12
        : sum(rentalIncome2) * (utilitiesPercent / 100)
    const utilities3 =
      assumptions.utilitiesTurnkeyType === '$'
        ? utilities * 12
        : sum(rentalIncome3) * (utilitiesPercent / 100)
    const utilities4 =
      assumptions.utilitiesTurnkeyType === '$'
        ? utilities * 12
        : sum(rentalIncome4) * (utilitiesPercent / 100)
    const utilities5 =
      assumptions.utilitiesTurnkeyType === '$'
        ? utilities * 12
        : sum(rentalIncome5) * (utilitiesPercent / 100)
    const assetFee1 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 1,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee2 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 2,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee3 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee4 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 4,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee5 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const otherExpense2 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 2,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense3 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense4 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 4,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense5 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal2 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 2,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal3 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal4 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 4,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal5 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const totalExpense1 =
      propertyTaxes +
      homeInsurance +
      yearlyHOA +
      propertyManagement1 +
      maintenance1 +
      utilities1 +
      assetFee1 +
      otherExpenses +
      customAnnualExpenseTotal -
      propertyTaxesDiscount
    const totalExpense2 =
      propertyTaxes2 +
      homeInsurance2 +
      HOA2 +
      propertyManagement2 +
      maintenance2 +
      utilities2 +
      assetFee2 +
      otherExpense2 +
      customAnnualExpenseTotal2
    const totalExpense3 =
      propertyTaxes3 +
      homeInsurance3 +
      HOA3 +
      propertyManagement3 +
      maintenance3 +
      utilities3 +
      assetFee3 +
      otherExpense3 +
      customAnnualExpenseTotal3
    const totalExpense4 =
      propertyTaxes4 +
      homeInsurance4 +
      HOA4 +
      propertyManagement4 +
      maintenance4 +
      utilities4 +
      assetFee4 +
      otherExpense4 +
      customAnnualExpenseTotal4
    const totalExpense5 =
      propertyTaxes5 +
      homeInsurance5 +
      HOA5 +
      propertyManagement5 +
      maintenance5 +
      utilities5 +
      assetFee5 +
      otherExpense5 +
      customAnnualExpenseTotal5
    const expenseRatio1 = (totalExpense1 / grossRentalIncome1) * 100
    const expenseRatio2 = (totalExpense2 / grossRentalIncome2) * 100
    const expenseRatio3 = (totalExpense3 / grossRentalIncome3) * 100
    const expenseRatio4 = (totalExpense4 / grossRentalIncome4) * 100
    const expenseRatio5 = (totalExpense5 / grossRentalIncome5) * 100
    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOINoFinance1 = revenueTotal1 - totalExpense1
    const NOINoFinance2 = revenueTotal2 - totalExpense2
    const NOINoFinance3 = revenueTotal3 - totalExpense3
    const NOINoFinance4 = revenueTotal4 - totalExpense4
    const NOINoFinance5 = revenueTotal5 - totalExpense5
    const NOIFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOIFinance1 = revenueTotal1 - totalExpense1
    const NOIFinance2 = revenueTotal2 - totalExpense2
    const NOIFinance3 = revenueTotal3 - totalExpense3
    const NOIFinance4 = revenueTotal4 - totalExpense4
    const NOIFinance5 = revenueTotal5 - totalExpense5
    const mortgagePaymentYearly = mortgagePayment * 12
    const totalReturnFinancing1 =
      NOINoFinance1 - mortgagePaymentYearly
    const totalReturnFinancing2 =
      NOINoFinance2 - mortgagePaymentYearly
    const totalReturnFinancing3 =
      NOINoFinance3 - mortgagePaymentYearly
    const totalReturnFinancing4 =
      NOINoFinance4 - mortgagePaymentYearly
    const totalReturnFinancing5 =
      NOINoFinance5 - mortgagePaymentYearly
    const totalROIFinancing1 =
      (totalReturnFinancing1 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing2 =
      (totalReturnFinancing2 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing3 =
      (totalReturnFinancing3 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing4 =
      (totalReturnFinancing4 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing5 =
      (totalReturnFinancing5 / totalInitialInvestmentFinancing) * 100
    const totalReturnNoFinancing1 = NOINoFinance1
    const totalReturnNoFinancing2 = NOINoFinance2
    const totalReturnNoFinancing3 = NOINoFinance3
    const totalReturnNoFinancing4 = NOINoFinance4
    const totalReturnNoFinancing5 = NOINoFinance5
    const totalROINoFinancing1 =
      (totalReturnNoFinancing1 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing2 =
      (totalReturnNoFinancing2 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing3 =
      (totalReturnNoFinancing3 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing4 =
      (totalReturnNoFinancing4 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing5 =
      (totalReturnNoFinancing5 / totalInitialInvestmentNoFinancing) *
      100

    const cashFlowAccumulation = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: termType === 'Years' ? term * 12 + 1 : term + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })

    const marketValue1 = offerPrice
    const marketValue2 =
      marketValue1 * (1 + homePriceAppreciation / 100)
    const marketValue3 =
      marketValue2 * (1 + homePriceAppreciation / 100)
    const marketValue4 =
      marketValue3 * (1 + homePriceAppreciation / 100)
    const marketValue5 =
      marketValue4 * (1 + homePriceAppreciation / 100)

    const loanBalance = acquisition.analytics.turnkey
      .loanAmountTurnkey
      ? acquisition.analytics.turnkey.loanAmountTurnkey
      : 0

    const equity1 = marketValue1 - loanBalance
    const equity2 = marketValue2 - loanBalance
    const equity3 = marketValue3 - loanBalance
    const equity4 = marketValue4 - loanBalance
    const equity5 = marketValue5 - loanBalance

    const loanToValueRatio1 = equity1 / marketValue1
    const loanToValueRatio2 = equity2 / marketValue2
    const loanToValueRatio3 = equity3 / marketValue3
    const loanToValueRatio4 = equity4 / marketValue4
    const loanToValueRatio5 = equity5 / marketValue5

    const cashOutPercent = assumptions.refinanceCashOut
      ? assumptions.refinanceCashOut
      : 0

    const potentialCashOut1 =
      (marketValue1 * cashOutPercent) / 100 - loanBalance
    const potentialCashOut2 =
      (marketValue2 * cashOutPercent) / 100 - loanBalance
    const potentialCashOut3 =
      (marketValue3 * cashOutPercent) / 100 - loanBalance
    const potentialCashOut4 =
      (marketValue4 * cashOutPercent) / 100 - loanBalance
    const potentialCashOut5 =
      (marketValue5 * cashOutPercent) / 100 - loanBalance

    const closingCost1 = (equity1 * sellingClosingCostPercent) / 100
    const closingCost2 = (equity2 * sellingClosingCostPercent) / 100
    const closingCost3 = (equity3 * sellingClosingCostPercent) / 100
    const closingCost4 = (equity4 * sellingClosingCostPercent) / 100
    const closingCost5 = (equity5 * sellingClosingCostPercent) / 100

    const proceedsAfterSale1 = equity1 - closingCost1
    const proceedsAfterSale2 = equity2 - closingCost2
    const proceedsAfterSale3 = equity3 - closingCost3
    const proceedsAfterSale4 = equity4 - closingCost4
    const proceedsAfterSale5 = equity5 - closingCost5

    const cumulativeCashFlow = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: 5 * 12 + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })

    const cumulativeCashFlow1 = cumulativeCashFlow[0].cashFlow
    const cumulativeCashFlow2 = cumulativeCashFlow[1].cashFlow
    const cumulativeCashFlow3 = cumulativeCashFlow[2].cashFlow
    const cumulativeCashFlow4 = cumulativeCashFlow[3].cashFlow
    const cumulativeCashFlow5 = cumulativeCashFlow[4].cashFlow

    const netProfit1 =
      proceedsAfterSale1 +
      cumulativeCashFlow1 -
      totalInitialInvestmentFinancing
    const netProfit2 =
      proceedsAfterSale2 +
      cumulativeCashFlow2 -
      totalInitialInvestmentFinancing
    const netProfit3 =
      proceedsAfterSale3 +
      cumulativeCashFlow3 -
      totalInitialInvestmentFinancing
    const netProfit4 =
      proceedsAfterSale4 +
      cumulativeCashFlow4 -
      totalInitialInvestmentFinancing
    const netProfit5 =
      proceedsAfterSale5 +
      cumulativeCashFlow5 -
      totalInitialInvestmentFinancing

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const numberFormat = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const percentageFormat = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
    })

    return [
      {
        margin: [15, 10, 15, 0],
        table: {
          headerRows: 0,
          widths: [150, '*', '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Annual Expense Ratio',
                alignment: 'left',
                fontSize: 6,
              },
              { text: '' },
              {
                text: expenseRatio1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio2.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio4.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
            ],
            //[{text: 'Estimated Tax Bracket', alignment: 'left',fontSize: 6,}, {text: '$123.00', alignment: 'right',fontSize: 6,},{text: '0%', alignment: 'right',fontSize: 6,}, {text: '1%', alignment: 'right',fontSize: 6,}, {text: '1%', alignment: 'right',fontSize: 6,}, {text: '1%', alignment: 'right',fontSize: 6,}]
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 0,
          widths: [150, '*', '*', '*', '*', '*', '*'],
          body: [
            [
              { text: '', alignment: 'left', fontSize: 6 },
              {
                text: 'Monthly',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 1',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 2',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 3',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 4',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 5',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 0, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Revenues',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 7,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'Potential Rental Income',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(sum(rentAmount)),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(sum(rentAmount) * 12),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(sum(rentalIncome2)),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(sum(rentalIncome3)),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(sum(rentalIncome4)),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(sum(rentalIncome5)),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text:
                  'Vacancy Rate (' +
                  percentageFormat.format(
                    vacancyRateMonthly / sum(rentAmount),
                  ) +
                  ')',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(vacancyRateMonthly),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(vacancyRate1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(vacancyRate2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(vacancyRate3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(vacancyRate4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(vacancyRate5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Other Monthly Income',
                alignment: 'left',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherIncomeMonthly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherIncome1),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherIncome2),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherIncome3),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherIncome4),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherIncome5),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: 'Gross Rental Income',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(monthlyRevenue),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(revenueTotal1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(revenueTotal2),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(revenueTotal3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(revenueTotal4),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(revenueTotal5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Expenses',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 7,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'Estimated Annual Property Taxes',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyTaxes / 12),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyTaxes),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyTaxes2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyTaxes3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyTaxes4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyTaxes5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Estimated Annual Homeowners Insurance',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(homeInsurance / 12),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(homeInsurance),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(homeInsurance2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(homeInsurance3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(homeInsurance4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(homeInsurance5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Estimated Annual HOA Dues',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(monthlyHOA),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(yearlyHOA),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(HOA2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(HOA3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(HOA4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(HOA5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text:
                  'Estimated Annual Prop Mgmt Fee (' +
                  propertyMgmtPercent +
                  '%)',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(
                  (propertyMgmtPercent / 100) *
                    grossRentalIncomeMonthly,
                ),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyManagement1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyManagement2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyManagement3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyManagement4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyManagement5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Avg 1 Year Tax Discount',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyTaxesDiscount),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Maintenance & Repairs (' + maintenance + '%)',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(
                  (maintenance / 100) * grossRentalIncomeMonthly,
                ),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(maintenance1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(maintenance2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(maintenance3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(maintenance4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(maintenance5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Utilities',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(utilities),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(utilities1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(utilities2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(utilities3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(utilities4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(utilities5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            // [
            //   {
            //     text:
            //       'Asset Management Fee (' +
            //       assetManagementFee +
            //       '%)',
            //     alignment: 'left',
            //     fontSize: 6,
            //   },
            //   {
            //     text: currencyFormater.format(assetFee1 / 12),
            //     alignment: 'right',
            //     fontSize: 6,
            //   },
            //   {
            //     text: currencyFormater.format(assetFee1),
            //     alignment: 'right',
            //     fontSize: 6,
            //   },
            //   {
            //     text: currencyFormater.format(assetFee2),
            //     alignment: 'right',
            //     fontSize: 6,
            //   },
            //   {
            //     text: currencyFormater.format(assetFee3),
            //     alignment: 'right',
            //     fontSize: 6,
            //   },
            //   {
            //     text: currencyFormater.format(assetFee4),
            //     alignment: 'right',
            //     fontSize: 6,
            //   },
            //   {
            //     text: currencyFormater.format(assetFee5),
            //     alignment: 'right',
            //     fontSize: 6,
            //   },
            // ],
            [
              {
                text: 'Other Expenses',
                alignment: 'left',
                fontSize: 6,
                //border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherExpenses / 12),
                alignment: 'right',
                fontSize: 6,
                //border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherExpenses),
                alignment: 'right',
                fontSize: 6,
                //border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherExpense2),
                alignment: 'right',
                fontSize: 6,
                //border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherExpense3),
                alignment: 'right',
                fontSize: 6,
                //border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherExpense4),
                alignment: 'right',
                fontSize: 6,
                //border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherExpense5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Custom Expenses',
                alignment: 'left',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  customAnnualExpenseTotal / 12,
                ),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  customAnnualExpenseTotal,
                ),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  customAnnualExpenseTotal2,
                ),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  customAnnualExpenseTotal3,
                ),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  customAnnualExpenseTotal4,
                ),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  customAnnualExpenseTotal5,
                ),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: 'Total Expenses',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalExpenseMonthly),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalExpense1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalExpense2),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalExpense3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalExpense4),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalExpense5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      // {
      //   margin: [15,5,15,0],
      //         table:
      //         {
      //           headerRows: 1,
      //           widths: [150,'*','*','*','*','*'],
      //           body: [
      //             [{text: 'Net Operating Income (NOI)', fillColor: '#3399ff', color: 'white', fontSize: 8, alignment: 'left',}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinanceMonthly)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance1)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance3)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance5)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance10)},],
      //           ]
      //         },
      //         layout: {
      //           defaultBorder: false,
      //         }
      // },
      // {
      //   margin: [15,5,15,0],
      //         table:
      //         {
      //           headerRows: 1,
      //           widths: [150,'*','*','*','*','*'],
      //           body: [
      //             [{text: 'Estimated Annual Property Taxes', alignment: 'left',fontSize: 8,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes / 12), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes * 3), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes * 5), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes * 10), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}],
      //           ]
      //         },
      //         layout: {
      //           defaultBorder: false,
      //         }
      // },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Returns Without Financing',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 7,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'NOI (Cash Available)',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinanceMonthly),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            // [{text: 'Mortgage', alignment: 'left',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}],
            // [{text: 'Total Cash Flow', alignment: 'left',fontSize: 6, bold: true}, {text: currencyFormater.format(NOIFinanceMonthly), alignment: 'right',fontSize: 6,bold: true},{text: currencyFormater.format(NOINoFinance1), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance3), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance5), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance10), alignment: 'right',fontSize: 6,bold: true}],
            //[{text: 'Principal Paydown', alignment: 'left',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            //[{text: 'Estimated Tax Savings', alignment: 'left',fontSize: 6, }, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            [
              {
                text: 'Net Cashflow',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing1,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing2,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing3,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing4,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing5,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: 'Cap Rate',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing2.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing4.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Returns With Financing',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 7,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'NOI (Cash Available)',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinanceMonthly),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Mortgage',
                alignment: 'left',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePayment),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
            ],
            //[{text: 'Principal Paydown', alignment: 'left',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            //[{text: 'Estimated Tax Savings', alignment: 'left',fontSize: 6, }, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            [
              {
                text: 'Net Cashflow',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing2),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing4),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
            [
              {
                text: 'Cash On Cash',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing2.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing4.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Loan Analysis',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 7,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'Market Value',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(marketValue1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(marketValue2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(marketValue3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(marketValue4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(marketValue5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            // [{text: 'Mortgage', alignment: 'left',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}],
            // [{text: 'Total Cash Flow', alignment: 'left',fontSize: 6, bold: true}, {text: currencyFormater.format(NOIFinanceMonthly), alignment: 'right',fontSize: 6,bold: true},{text: currencyFormater.format(NOINoFinance1), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance3), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance5), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance10), alignment: 'right',fontSize: 6,bold: true}],
            //[{text: 'Principal Paydown', alignment: 'left',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            //[{text: 'Estimated Tax Savings', alignment: 'left',fontSize: 6, }, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            [
              {
                text: '-Loan Analysis',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(loanBalance),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(loanBalance),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(loanBalance),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(loanBalance),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(loanBalance),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '=Equity',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(equity1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(equity2),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(equity3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(equity4),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(equity5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
            [
              {
                text: 'Loan-to-Value Ratio',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: loanToValueRatio1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: loanToValueRatio2.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: loanToValueRatio3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: loanToValueRatio4.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: loanToValueRatio5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
            [
              {
                text: 'Potential Cash-Out Refi',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(potentialCashOut1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(potentialCashOut2),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(potentialCashOut3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(potentialCashOut4),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(potentialCashOut5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Sale Analysis',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 7,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'Equity',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(equity1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(equity2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(equity3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(equity4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(equity5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: '-Closing Costs',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(closingCost1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(closingCost2),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(closingCost3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(closingCost4),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(closingCost5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: '=Proceeds After Sale',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(proceedsAfterSale1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(proceedsAfterSale2),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(proceedsAfterSale3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(proceedsAfterSale4),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(proceedsAfterSale5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
            [
              {
                text: '+Cumulative Cash Flow',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(cumulativeCashFlow1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(cumulativeCashFlow2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(cumulativeCashFlow3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(cumulativeCashFlow4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(cumulativeCashFlow5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: '-Initial Cash Invested',
                alignment: 'left',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalInitialInvestmentFinancing,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalInitialInvestmentFinancing,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalInitialInvestmentFinancing,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalInitialInvestmentFinancing,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalInitialInvestmentFinancing,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: 'Net Profit',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(netProfit1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(netProfit2),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(netProfit3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(netProfit4),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(netProfit5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'IRR Calculation',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 7,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'Net Cash Flows',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(
                  totalReturnFinancing1 -
                    totalInitialInvestmentFinancing,
                ),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(totalReturnFinancing2),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(totalReturnFinancing3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(totalReturnFinancing4),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(totalReturnFinancing5),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            // [{text: 'Mortgage', alignment: 'left',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}],
            // [{text: 'Total Cash Flow', alignment: 'left',fontSize: 6, bold: true}, {text: currencyFormater.format(NOIFinanceMonthly), alignment: 'right',fontSize: 6,bold: true},{text: currencyFormater.format(NOINoFinance1), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance3), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance5), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance10), alignment: 'right',fontSize: 6,bold: true}],
            //[{text: 'Principal Paydown', alignment: 'left',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            //[{text: 'Estimated Tax Savings', alignment: 'left',fontSize: 6, }, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            [
              {
                text: 'Net Cash Upon Sale',
                alignment: 'left',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(proceedsAfterSale5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: 'Project Cash Flows',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(
                  totalReturnFinancing1 -
                    totalInitialInvestmentFinancing,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing2),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing4),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(
                  totalReturnFinancing5 + proceedsAfterSale5,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 10, 15, 15],
        color: 'gray',
        fontSize: 8,
        text: legalDescription ? legalDescription : '',
        //'The above agent/brokerage makes no warranty or representation about the content of this brochure. While the information displayed herein is thought to be accurate, it is your responsibility to independently confirm its accuracy and completeness. Any projections, opinions, assumptions or estimates are used for example only and do not represent the current or future performance of the property. The above agent/brokerage neither practices accounting nor gives advice regarding tax benefits/liabilities or any other tax, accounting or financial consideration, nor does the above agent/brokerage give advice regarding financial investments. It is strongly recommended that you seek appropriate professional counsel regarding your rights as a homeowner.',
      },
    ]
  }

  const defaultMarketingPage2Bottom = async (property) => {
    const {
      acquisition = {},
      assumptions = {},
      portfolio = {},
    } = property
    const { organization = {} } = portfolio
    const { legalDescription = '' } = organization

    const mortgagePayment = acquisition.analytics.turnkey
      .mortgageTurnkey
      ? acquisition.analytics.turnkey.mortgageTurnkey
      : 0
    const financeAmount = acquisition.analytics.turnkey
      .loanAmountTurnkey
      ? acquisition.analytics.turnkey.loanAmountTurnkey
      : 0
    const downPaymentTotal = acquisition.analytics.turnkey
      .downPaymentTotalTurnkey
      ? acquisition.analytics.turnkey.downPaymentTotalTurnkey
      : 0
    const pointsAmount = acquisition.analytics.turnkey
      .pointsAmountTurnkey
      ? acquisition.analytics.turnkey.pointsAmountTurnkey
      : 0
    const closeCostDollar = acquisition.analytics.turnkey
      .closeCostDollarTurnkey
      ? acquisition.analytics.turnkey.closeCostDollarTurnkey
      : 0
    const paydownMonthly = acquisition.analytics.turnkey
      .paydownMonthlyTurnkey
      ? acquisition.analytics.turnkey.paydownMonthlyTurnkey
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollarTurnkey
      ? acquisition.analytics.acquisitionCommissionDollarTurnkey
      : 0
    const propertyManagementDollar = acquisition.analytics.turnkey
      .propertyManagementDollarTurnkey
      ? acquisition.analytics.turnkey.propertyManagementDollarTurnkey
      : 0
    const utilities = acquisition.analytics.turnkey
      .utilitiesTypeDollarTurnkey
      ? acquisition.analytics.turnkey.utilitiesTypeDollarTurnkey
      : 0
    const utilitiesPercent = assumptions.utilitiesTurnkey
      ? assumptions.utilitiesTurnkey
      : 0
    const financingFeeTotal = acquisition.analytics.turnkey
      .financingFeeTotalTurnkey
      ? acquisition.analytics.turnkey.financingFeeTotalTurnkey
      : 0
    const assetManagementFeeTotal = acquisition.analytics.turnkey
      .assetManagementFeeTotalTurnkey
      ? acquisition.analytics.turnkey.assetManagementFeeTotalTurnkey
      : 0
    const homeInsurance = acquisition.analytics.turnkey
      .propertyInsuranceDollarTurnkey
      ? acquisition.analytics.turnkey.propertyInsuranceDollarTurnkey
      : 0
    const maintenanceCost = acquisition.analytics.turnkey
      .maintenanceCostTurnkey
      ? acquisition.analytics.turnkey.maintenanceCostTurnkey
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange'
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const otherIncome = acquisition.analysis.otherIncome
      ? acquisition.analysis.otherIncome
      : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.turnkey.hoaTotal
      ? acquisition.analytics.turnkey.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.turnkey
      .arvTotalTurnkey
      ? acquisition.analytics.turnkey.arvTotalTurnkey
      : 0
    const offerPrice = acquisition.analysis.resellPriceTurnkey
      ? acquisition.analysis.resellPriceTurnkey
      : afterRepairValue
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentageTurnkey
      ? acquisition.analysis.financingRepairsPercentageTurnkey
      : 0
    // const rehabCost = acquisition.analysis.endRehabCost
    //   ? acquisition.analysis.endRehabCost
    //   : 0
    const rehabCost = acquisition.analytics.turnkey.corTotalTurnkey
      ? acquisition.analytics.turnkey.corTotalTurnkey
      : 0

    const rate = assumptions.rateTurnkey ? assumptions.rateTurnkey : 0
    const term = assumptions.termTurnkey ? assumptions.termTurnkey : 0
    const termType = assumptions.termTurnkeyType
      ? assumptions.termTurnkeyType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceTurnkey
      ? assumptions.maintenanceTurnkey
      : 0
    const propertyMgmtPercent = acquisition.analytics.turnkey
      .propertyManagementPercentageTurnkey
      ? acquisition.analytics.turnkey
          .propertyManagementPercentageTurnkey
      : 0
    const homePriceAppreciation = assumptions.homePriceAppreciation
      ? assumptions.homePriceAppreciation
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const assetManagementFee = assumptions.assetManagementFee
      ? assumptions.assetManagementFee
      : 0
    const otherExpenses = assumptions.otherExpenseTurnkey
      ? assumptions.otherExpenseTurnkey
      : 0
    const ownerReserves = assumptions.ownerReservesTurnkey
      ? assumptions.ownerReservesTurnkey
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    const monthlyRate = rate / 12 / 100
    const renovationAmount = rehabCost || 0

    const graphTest = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: termType === 'Years' ? term * 12 : term,
      paydownMonthly,
    })
    const appreciation = appreciationSection({
      afterRepairValue,
      homePriceAppreciation,
      term: termType === 'Years' ? term * 12 : term,
    })

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      financingFeeTotal +
      pointsAmount +
      renovationAmount * (1 - financingRepairsPercentage / 100) +
      customAcquisitionExpenseTotal
    const totalInitialInvestmentNoFinancing =
      offerPrice +
      renovationAmount +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      pointsAmount +
      financingFeeTotal +
      ownerReserves +
      customAcquisitionExpenseTotal
    const yearlyHOA = monthlyHOA * 12
    const rentalIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const vacancyRate1 =
      sum(
        map(
          zip(rentAmount, vacancy),
          ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
        ),
      ) * 12
    const vacancyRate3 = sum(
      map(
        zip(rentalIncome3, vacancy),
        ([rentalIncome3, vacancy]) => rentalIncome3 * (vacancy / 100),
      ),
    )
    const vacancyRate5 = sum(
      map(
        zip(rentalIncome5, vacancy),
        ([rentalIncome5, vacancy]) => rentalIncome5 * (vacancy / 100),
      ),
    )
    const vacancyRate10 = sum(
      map(
        zip(rentalIncome10, vacancy),
        ([rentalIncome10, vacancy]) =>
          rentalIncome10 * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly
    const grossRentalIncome1 = sum(rentAmount) * 12 - vacancyRate1
    const grossRentalIncome3 = sum(rentalIncome3) - vacancyRate3
    const grossRentalIncome5 = sum(rentalIncome5) - vacancyRate5
    const grossRentalIncome10 = sum(rentalIncome10) - vacancyRate10
    const monthlyRevenue = grossRentalIncomeMonthly + sum(otherIncome)
    const revenueTotal1 = grossRentalIncome1 + sum(otherIncome) * 12
    const revenueTotal3 = grossRentalIncome3 + sum(otherIncome3)
    const revenueTotal5 = grossRentalIncome5 + sum(otherIncome5)
    const revenueTotal10 = grossRentalIncome10 + sum(otherIncome10)
    const propertyTaxes3 = expensesSection({
      expenseType: 'PT',
      revenueYear: 3,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes5 = expensesSection({
      expenseType: 'PT',
      revenueYear: 5,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes10 = expensesSection({
      expenseType: 'PT',
      revenueYear: 10,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance3 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 3,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance5 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 5,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance10 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 10,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA3 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 3,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA5 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 5,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA10 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 10,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyManagement1 = propertyManagementDollar
    const propertyManagement3 =
      assumptions.propertyManagementTurnkeyType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome3) * (propertyMgmtPercent / 100)
    const propertyManagement5 =
      assumptions.propertyManagementTurnkeyType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome5) * (propertyMgmtPercent / 100)
    const propertyManagement10 =
      assumptions.propertyManagementTurnkeyType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome10) * (propertyMgmtPercent / 100)
    const maintenance1 = maintenanceCost
    const maintenance3 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance5 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance10 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const utilities1 = utilities * 12
    const utilities3 =
      assumptions.utilitiesTurnkeyType === '$'
        ? utilities * 12
        : sum(rentalIncome3) * (utilitiesPercent / 100)
    const utilities5 =
      assumptions.utilitiesTurnkeyType === '$'
        ? utilities * 12
        : sum(rentalIncome5) * (utilitiesPercent / 100)
    const utilities10 =
      assumptions.utilitiesTurnkeyType === '$'
        ? utilities * 12
        : sum(rentalIncome10) * (utilitiesPercent / 100)
    const assetFee1 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 1,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee3 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee5 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee10 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const otherExpense3 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense5 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense10 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal3 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal5 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal10 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const totalExpense1 =
      propertyTaxes +
      homeInsurance +
      yearlyHOA +
      propertyManagement1 +
      maintenance1 +
      utilities1 +
      assetFee1 +
      otherExpenses +
      customAnnualExpenseTotal
    const totalExpense3 =
      propertyTaxes3 +
      homeInsurance3 +
      HOA3 +
      propertyManagement3 +
      maintenance3 +
      utilities3 +
      assetFee3 +
      otherExpense3 +
      customAnnualExpenseTotal3
    const totalExpense5 =
      propertyTaxes5 +
      homeInsurance5 +
      HOA5 +
      propertyManagement5 +
      maintenance5 +
      utilities5 +
      assetFee5 +
      otherExpense5 +
      customAnnualExpenseTotal5
    const totalExpense10 =
      propertyTaxes10 +
      homeInsurance10 +
      HOA10 +
      propertyManagement10 +
      maintenance10 +
      utilities10 +
      assetFee10 +
      otherExpense10 +
      customAnnualExpenseTotal10
    const expenseRatio1 = (totalExpense1 / grossRentalIncome1) * 100
    const expenseRatio3 = (totalExpense3 / grossRentalIncome3) * 100
    const expenseRatio5 = (totalExpense5 / grossRentalIncome5) * 100
    const expenseRatio10 =
      (totalExpense10 / grossRentalIncome10) * 100
    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOINoFinance1 = grossRentalIncome1 - totalExpense1
    const NOINoFinance3 = grossRentalIncome3 - totalExpense3
    const NOINoFinance5 = grossRentalIncome5 - totalExpense5
    const NOINoFinance10 = grossRentalIncome10 - totalExpense10
    const NOIFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOIFinance1 = grossRentalIncome1 - totalExpense1
    const NOIFinance3 = grossRentalIncome3 - totalExpense3
    const NOIFinance5 = grossRentalIncome5 - totalExpense5
    const NOIFinance10 = grossRentalIncome10 - totalExpense10
    const mortgagePaymentYearly = mortgagePayment * 12
    const totalReturnFinancing = []
    const totalReturnFinancing1 =
      NOINoFinance1 - mortgagePaymentYearly
    const totalReturnFinancing3 =
      NOINoFinance3 - mortgagePaymentYearly
    const totalReturnFinancing5 =
      NOINoFinance5 - mortgagePaymentYearly
    const totalReturnFinancing10 =
      NOINoFinance10 - mortgagePaymentYearly
    totalReturnFinancing.push({
      one: totalReturnFinancing1,
      three: totalReturnFinancing3,
      five: totalReturnFinancing5,
      ten: totalReturnFinancing10,
    })
    const totalROIFinancing1 =
      (totalReturnFinancing1 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing3 =
      (totalReturnFinancing3 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing5 =
      (totalReturnFinancing5 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing10 =
      (totalReturnFinancing10 / totalInitialInvestmentFinancing) * 100
    const totalReturnNoFinancing1 = NOINoFinance1
    const totalReturnNoFinancing3 = NOINoFinance3
    const totalReturnNoFinancing5 = NOINoFinance5
    const totalReturnNoFinancing10 = NOINoFinance10
    const totalROINoFinancing1 =
      (totalReturnNoFinancing1 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing3 =
      (totalReturnNoFinancing3 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing5 =
      (totalReturnNoFinancing5 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing10 =
      (totalReturnNoFinancing10 / totalInitialInvestmentNoFinancing) *
      100

    const cashFlowAccumulation = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: termType === 'Years' ? term * 12 + 1 : term + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })
    const graphDataURL = getChartData(
      property,
      graphTest,
      totalReturnFinancing,
      appreciation,
      cashFlowAccumulation,
      totalInitialInvestmentFinancing,
    )

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const percentageFormat = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
    })

    return [
      {
        margin: [15, 10, 15, 0],
        table: {
          headerRows: 0,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Annual Revenue Increase',
                alignment: 'left',
                fontSize: 6,
              },
              { text: '' },
              {
                text: annualRevenueIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualRevenueIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualRevenueIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualRevenueIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Annual Operating Expense Increase',
                alignment: 'left',
                fontSize: 6,
              },
              { text: '' },
              {
                text: annualOperatingExpenseIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualOperatingExpenseIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualOperatingExpenseIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: annualOperatingExpenseIncrease + '%',
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Annual Expense Ratio',
                alignment: 'left',
                fontSize: 6,
              },
              { text: '' },
              {
                text: expenseRatio1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: expenseRatio10.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
              },
            ],
            //[{text: 'Estimated Tax Bracket', alignment: 'left',fontSize: 6,}, {text: '$123.00', alignment: 'right',fontSize: 6,},{text: '0%', alignment: 'right',fontSize: 6,}, {text: '1%', alignment: 'right',fontSize: 6,}, {text: '1%', alignment: 'right',fontSize: 6,}, {text: '1%', alignment: 'right',fontSize: 6,}]
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 0,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              { text: '', alignment: 'left', fontSize: 6 },
              {
                text: 'Monthly',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 1',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 3',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 5',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: 'Year 10',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 0, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*'],
          body: compact([
            [
              {
                text: 'Revenues',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 6,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            sum(rentAmount)
              ? [
                  {
                    text: 'Potential Rental Income',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(sum(rentAmount)),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      sum(rentAmount) * 12,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(sum(rentalIncome3)),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(sum(rentalIncome5)),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(
                      sum(rentalIncome10),
                    ),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            vacancyRate1
              ? [
                  {
                    text:
                      'Vacancy Rate (' +
                      percentageFormat.format(
                        vacancyRateMonthly / sum(rentAmount),
                      ) +
                      ')',
                    alignment: 'left',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(vacancyRateMonthly),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(vacancyRate1),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(vacancyRate3),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(vacancyRate5),
                    alignment: 'right',
                    fontSize: 6,
                  },
                  {
                    text: currencyFormater.format(vacancyRate10),
                    alignment: 'right',
                    fontSize: 6,
                  },
                ]
              : null,
            sum(otherIncome)
              ? [
                  {
                    text: 'Other Income',
                    alignment: 'left',
                    fontSize: 6,
                    border: [false, false, false, true],
                  },
                  {
                    text: currencyFormater.format(sum(otherIncome)),
                    alignment: 'right',
                    fontSize: 6,
                    border: [false, false, false, true],
                  },
                  {
                    text: currencyFormater.format(
                      sum(otherIncome) * 12,
                    ),
                    alignment: 'right',
                    fontSize: 6,
                    border: [false, false, false, true],
                  },
                  {
                    text: currencyFormater.format(otherIncome3),
                    alignment: 'right',
                    fontSize: 6,
                    border: [false, false, false, true],
                  },
                  {
                    text: currencyFormater.format(otherIncome5),
                    alignment: 'right',
                    fontSize: 6,
                    border: [false, false, false, true],
                  },
                  {
                    text: currencyFormater.format(otherIncome10),
                    alignment: 'right',
                    fontSize: 6,
                    border: [false, false, false, true],
                  },
                ]
              : null,
            [
              {
                text: 'Gross Rental Income',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(monthlyRevenue),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(revenueTotal1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(revenueTotal3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(revenueTotal5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(revenueTotal10),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ]),
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Expenses',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 6,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'Estimated Annual Property Taxes',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyTaxes / 12),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyTaxes),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyTaxes3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyTaxes5),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyTaxes10),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Estimated Annual Homeowners Insurance',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(homeInsurance / 12),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(homeInsurance),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(homeInsurance3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(homeInsurance5),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(homeInsurance10),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Estimated Annual HOA Dues',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(monthlyHOA),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(yearlyHOA),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(HOA3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(HOA5),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(HOA10),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text:
                  'Estimated Annual Prop Mgmt Fee (' +
                  propertyMgmtPercent +
                  '%)',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(
                  (propertyMgmtPercent / 100) *
                    grossRentalIncomeMonthly,
                ),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyManagement1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyManagement3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyManagement5),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(propertyManagement10),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            // [{text: 'Tenant Placement Fee', alignment: 'left',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            [
              {
                text: 'Maintenance & Repairs (' + maintenance + '%)',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(
                  (maintenance / 100) * grossRentalIncomeMonthly,
                ),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(maintenance1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(maintenance3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(maintenance5),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(maintenance10),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Utilities',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(utilities),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(utilities1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(utilities3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(utilities5),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(utilities10),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            // [
            //   {
            //     text:
            //       'Asset Management Fee (' +
            //       assetManagementFee +
            //       '%)',
            //     alignment: 'left',
            //     fontSize: 6,
            //   },
            //   {
            //     text: currencyFormater.format(assetFee1 / 12),
            //     alignment: 'right',
            //     fontSize: 6,
            //   },
            //   {
            //     text: currencyFormater.format(assetFee1),
            //     alignment: 'right',
            //     fontSize: 6,
            //   },
            //   {
            //     text: currencyFormater.format(assetFee3),
            //     alignment: 'right',
            //     fontSize: 6,
            //   },
            //   {
            //     text: currencyFormater.format(assetFee5),
            //     alignment: 'right',
            //     fontSize: 6,
            //   },
            //   {
            //     text: currencyFormater.format(assetFee10),
            //     alignment: 'right',
            //     fontSize: 6,
            //   },
            // ],
            [
              {
                text: 'Other Expenses',
                alignment: 'left',
                fontSize: 6,
                //border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherExpenses / 12),
                alignment: 'right',
                fontSize: 6,
                //border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherExpenses),
                alignment: 'right',
                fontSize: 6,
                //border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherExpense3),
                alignment: 'right',
                fontSize: 6,
                //border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherExpense5),
                alignment: 'right',
                fontSize: 6,
                //border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(otherExpense10),
                alignment: 'right',
                fontSize: 6,
                //border: [false, false, false, true],
              },
            ],
            [
              {
                text: 'Custom Expenses',
                alignment: 'left',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  customAnnualExpenseTotal / 12,
                ),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  customAnnualExpenseTotal,
                ),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  customAnnualExpenseTotal3,
                ),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  customAnnualExpenseTotal5,
                ),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  customAnnualExpenseTotal10,
                ),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: 'Total Expenses',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalExpenseMonthly),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalExpense1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalExpense3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalExpense5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalExpense10),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      // {
      //   margin: [15,5,15,0],
      //         table:
      //         {
      //           headerRows: 1,
      //           widths: [150,'*','*','*','*','*'],
      //           body: [
      //             [{text: 'Net Operating Income (NOI)', fillColor: '#3399ff', color: 'white', fontSize: 8, alignment: 'left',}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinanceMonthly)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance1)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance3)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance5)}, {fillColor: '#3399ff', color: 'white', fontSize: 6, alignment: 'right', text: currencyFormater.format(NOINoFinance10)},],
      //           ]
      //         },
      //         layout: {
      //           defaultBorder: false,
      //         }
      // },
      // {
      //   margin: [15,5,15,0],
      //         table:
      //         {
      //           headerRows: 1,
      //           widths: [150,'*','*','*','*','*'],
      //           body: [
      //             [{text: 'Estimated Annual Property Taxes', alignment: 'left',fontSize: 8,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes / 12), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes * 3), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes * 5), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}, {text: '$' + (propertyTaxes * 10), alignment: 'right',fontSize: 6,fillColor: '#3399ff', color: 'white',}],
      //           ]
      //         },
      //         layout: {
      //           defaultBorder: false,
      //         }
      // },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Returns Without Financing',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 6,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'NOI (Cash Available)',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinanceMonthly),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance5),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOINoFinance10),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            // [{text: 'Mortgage', alignment: 'left',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}, {text: currencyFormater.format(cashMortgagePayment), alignment: 'right',fontSize: 6,border: [false, false, false, true]}],
            // [{text: 'Total Cash Flow', alignment: 'left',fontSize: 6, bold: true}, {text: currencyFormater.format(NOIFinanceMonthly), alignment: 'right',fontSize: 6,bold: true},{text: currencyFormater.format(NOINoFinance1), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance3), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance5), alignment: 'right',fontSize: 6,bold: true}, {text: currencyFormater.format(NOINoFinance10), alignment: 'right',fontSize: 6,bold: true}],
            //[{text: 'Principal Paydown', alignment: 'left',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            //[{text: 'Estimated Tax Savings', alignment: 'left',fontSize: 6, }, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            [
              {
                text: 'Net Cashflow',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing1,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing3,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing5,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(
                  totalReturnNoFinancing10,
                ),
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
            ],
            [
              {
                text: 'Cap Rate',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: totalROINoFinancing10.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 5, 15, 0],
        table: {
          headerRows: 1,
          widths: [150, '*', '*', '*', '*', '*'],
          body: [
            [
              {
                text: 'Returns With Financing',
                fillColor: '#3399ff',
                color: 'white',
                fontSize: 8,
                colSpan: 6,
                alignment: 'left',
              },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
              { text: '' },
            ],
            [
              {
                text: 'NOI (Cash Available)',
                alignment: 'left',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinanceMonthly),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance1),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance3),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance5),
                alignment: 'right',
                fontSize: 6,
              },
              {
                text: currencyFormater.format(NOIFinance10),
                alignment: 'right',
                fontSize: 6,
              },
            ],
            [
              {
                text: 'Mortgage',
                alignment: 'left',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePayment),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
              {
                text: currencyFormater.format(mortgagePaymentYearly),
                alignment: 'right',
                fontSize: 6,
                border: [false, false, false, true],
              },
            ],
            //[{text: 'Principal Paydown', alignment: 'left',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            //[{text: 'Estimated Tax Savings', alignment: 'left',fontSize: 6, }, {text: '$1', alignment: 'right',fontSize: 6,},{text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}, {text: '$1', alignment: 'right',fontSize: 6,}],
            [
              {
                text: 'Net Cashflow',
                alignment: 'left',
                fontSize: 6,
                bold: true,
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing1),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing3),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing5),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
              {
                text: currencyFormater.format(totalReturnFinancing10),
                alignment: 'right',
                fontSize: 6,
                bold: true,
              },
            ],
            [
              {
                text: 'Cash On Cash',
                alignment: 'left',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: '',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing1.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing3.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing5.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
              {
                text: totalROIFinancing10.toFixed(2) + '%',
                alignment: 'right',
                fontSize: 6,
                bold: true,
                border: [false, false, false, true],
              },
            ],
          ],
        },
        layout: {
          defaultBorder: false,
        },
      },
      {
        margin: [15, 10, 15, 15],
        color: 'gray',
        fontSize: 8,
        text: legalDescription ? legalDescription : '',
      },
    ]
  }

  const southernImpressionsMarketingPageHighlights = async (
    property,
  ) => {
    const {
      acquisition = {},
      assumptions = {},
      portfolio = {},
    } = property
    const { organization = {} } = portfolio
    const { legalDescription = '' } = organization

    const IRR = acquisition.analytics.turnkey.IRRTurnkey
      ? (acquisition.analytics.turnkey.IRRTurnkey * 100).toFixed(2)
      : 0
    const cashOnCash = acquisition.analytics.turnkey
      .cashOnCashReturnTurnkey
      ? (
          acquisition.analytics.turnkey.cashOnCashReturnTurnkey * 100
        ).toFixed(2)
      : 0
    const mortgagePayment = acquisition.analytics.turnkey
      .mortgageTurnkey
      ? acquisition.analytics.turnkey.mortgageTurnkey
      : 0
    const financeAmount = acquisition.analytics.turnkey
      .loanAmountTurnkey
      ? acquisition.analytics.turnkey.loanAmountTurnkey
      : 0
    const downPaymentTotal = acquisition.analytics.turnkey
      .downPaymentTotalTurnkey
      ? acquisition.analytics.turnkey.downPaymentTotalTurnkey
      : 0
    const pointsAmount = acquisition.analytics.turnkey
      .pointsAmountTurnkey
      ? acquisition.analytics.turnkey.pointsAmountTurnkey
      : 0
    const closeCostDollar = acquisition.analytics.turnkey
      .closeCostDollarTurnkey
      ? acquisition.analytics.turnkey.closeCostDollarTurnkey
      : 0
    const paydownMonthly = acquisition.analytics.turnkey
      .paydownMonthlyTurnkey
      ? acquisition.analytics.turnkey.paydownMonthlyTurnkey
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollarTurnkey
      ? acquisition.analytics.acquisitionCommissionDollarTurnkey
      : 0
    const propertyManagementDollar = acquisition.analytics.turnkey
      .propertyManagementDollarTurnkey
      ? acquisition.analytics.turnkey.propertyManagementDollarTurnkey
      : 0
    const utilities = acquisition.analytics.turnkey
      .utilitiesTypeDollarTurnkey
      ? acquisition.analytics.turnkey.utilitiesTypeDollarTurnkey
      : 0
    const utilitiesPercent = assumptions.utilitiesTurnkey
      ? assumptions.utilitiesTurnkey
      : 0
    const financingFeeTotal = acquisition.analytics.turnkey
      .financingFeeTotalTurnkey
      ? acquisition.analytics.turnkey.financingFeeTotalTurnkey
      : 0
    const assetManagementFeeTotal = acquisition.analytics.turnkey
      .assetManagementFeeTotal
      ? acquisition.analytics.turnkey.assetManagementFeeTotal
      : 0
    const homeInsurance = acquisition.analytics.turnkey
      .propertyInsuranceDollarTurnkey
      ? acquisition.analytics.turnkey.propertyInsuranceDollarTurnkey
      : 0
    const maintenanceCost = acquisition.analytics.turnkey
      .maintenanceCostTurnkey
      ? acquisition.analytics.turnkey.maintenanceCostTurnkey
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange' //SPECIFIC COMMENT: Southern Impressions calculate range with rentHigh value not average of low and high
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => high,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const otherIncome = acquisition.analysis.otherIncome
      ? acquisition.analysis.otherIncome
      : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.turnkey.hoaTotal
      ? acquisition.analytics.turnkey.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.turnkey
      .arvTotalTurnkey
      ? acquisition.analytics.turnkey.arvTotalTurnkey
      : 0
    const offerPrice = acquisition.analysis.resellPriceTurnkey
      ? acquisition.analysis.resellPriceTurnkey
      : afterRepairValue
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentageTurnkey
      ? acquisition.analysis.financingRepairsPercentageTurnkey
      : 0
    // const rehabCost = acquisition.analysis.endRehabCost
    //   ? acquisition.analysis.endRehabCost
    //   : 0
    const rehabCost = acquisition.analytics.turnkey.corTotalTurnkey
      ? acquisition.analytics.turnkey.corTotalTurnkey
      : 0

    const rate = assumptions.rateTurnkey ? assumptions.rateTurnkey : 0
    const term = assumptions.termTurnkey ? assumptions.termTurnkey : 0
    const termType = assumptions.termTurnkeyType
      ? assumptions.termTurnkeyType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceTurnkey
      ? assumptions.maintenanceTurnkey
      : 0
    const propertyMgmtPercent = acquisition.analytics.turnkey
      .propertyManagementPercentageTurnkey
      ? acquisition.analytics.turnkey
          .propertyManagementPercentageTurnkey
      : 0
    const homePriceAppreciation = assumptions.homePriceAppreciation
      ? assumptions.homePriceAppreciation
      : 0
    const sellingClosingCostPercent = acquisition.analytics.turnkey
      .sellingClosingCostPercentTurnkey
      ? acquisition.analytics.turnkey.sellingClosingCostPercentTurnkey
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const assetManagementFee = assumptions.assetManagementFee
      ? assumptions.assetManagementFee
      : 0
    const otherExpenses = assumptions.otherExpenseTurnkey
      ? assumptions.otherExpenseTurnkey
      : 0
    const ownerReserves = assumptions.ownerReservesTurnkey
      ? assumptions.ownerReservesTurnkey
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    const monthlyRate = rate / 12 / 100
    const renovationAmount = rehabCost || 0

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      financingFeeTotal +
      pointsAmount +
      renovationAmount * (1 - financingRepairsPercentage) +
      customAcquisitionExpenseTotal
    const totalInitialInvestmentNoFinancing =
      offerPrice +
      renovationAmount +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      pointsAmount +
      financingFeeTotal +
      ownerReserves +
      customAcquisitionExpenseTotal
    const yearlyHOA = monthlyHOA * 12
    const rentalIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncomeMonthly = sum(otherIncome)
    const otherIncome1 = sum(otherIncome) * 12
    const otherIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const vacancyRate1 =
      sum(
        map(
          zip(rentAmount, vacancy),
          ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
        ),
      ) * 12
    const vacancyRate5 = sum(
      map(
        zip(rentalIncome5, vacancy),
        ([rentalIncome5, vacancy]) => rentalIncome5 * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly
    const grossRentalIncome1 = sum(rentAmount) * 12 - vacancyRate1
    const grossRentalIncome5 = sum(rentalIncome5) - vacancyRate5
    const monthlyRevenue =
      grossRentalIncomeMonthly + otherIncomeMonthly
    const revenueTotal1 = grossRentalIncome1 + otherIncome1
    const revenueTotal5 = grossRentalIncome5 + sum(otherIncome5)
    const propertyTaxesDiscount = (propertyTaxes / 2 - 400) / 12
    const propertyTaxes5 = expensesSection({
      expenseType: 'PT',
      revenueYear: 5,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance5 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 5,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA5 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 5,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyManagement1 = propertyManagementDollar
    // const propertyManagement5 =
    // expensesSection({
    //   expenseType: 'MGMT',
    //   revenueYear: 5,
    //   rentAmount: grossRentalIncome1,
    //   mgmtFee: propertyMgmtPercent / 100,
    //   annualOperatingExpenseIncrease:
    //     annualOperatingExpenseIncrease / 100,
    // })
    const propertyManagement5 =
      (grossRentalIncome5 * propertyMgmtPercent) / 100
    const maintenance1 = maintenanceCost
    const maintenance5 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const utilities1 = utilities * 12
    const utilities5 =
      assumptions.utilitiesTurnkeyType === '$'
        ? utilities * 12
        : sum(rentalIncome5) * (utilitiesPercent / 100)
    const assetFee1 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 1,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee5 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const otherExpense5 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 -
      // otherExpenses / 12 -
      propertyTaxesDiscount
    const totalExpense1 =
      propertyTaxes +
      homeInsurance +
      yearlyHOA +
      propertyManagement1 +
      maintenance1 +
      utilities1 +
      assetFee1 -
      //otherExpenses -
      propertyTaxesDiscount * 12
    const totalExpense5 =
      propertyTaxes5 +
      homeInsurance5 +
      HOA5 +
      propertyManagement5 +
      maintenance5 +
      utilities5 +
      assetFee5
    // otherExpense5
    const expenseRatio1 = (totalExpense1 / grossRentalIncome1) * 100
    const expenseRatio5 = (totalExpense5 / grossRentalIncome5) * 100
    const NOINoFinanceMonthly = monthlyRevenue - totalExpenseMonthly
    const NOINoFinance1 = revenueTotal1 - totalExpense1
    const NOINoFinance5 = revenueTotal5 - totalExpense5
    const NOIFinanceMonthly = monthlyRevenue - totalExpenseMonthly
    const NOIFinance1 = revenueTotal1 - totalExpense1
    const NOIFinance5 = revenueTotal5 - totalExpense5
    const mortgagePaymentYearly = mortgagePayment * 12
    const totalReturnFinancing1 =
      NOINoFinance1 - mortgagePaymentYearly
    const totalReturnFinancing5 =
      NOINoFinance5 - mortgagePaymentYearly
    const totalROIFinancing1 =
      (totalReturnFinancing1 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing5 =
      (totalReturnFinancing5 / totalInitialInvestmentFinancing) * 100
    const totalReturnNoFinancing1 = NOINoFinance1
    const totalReturnNoFinancing5 = NOINoFinance5
    const totalROINoFinancing1 =
      (totalReturnNoFinancing1 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing5 =
      (totalReturnNoFinancing5 / totalInitialInvestmentNoFinancing) *
      100

    const cashFlowAccumulation = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: termType === 'Years' ? term * 12 + 1 : term + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })

    const marketValue1 = offerPrice
    const marketValue2 =
      marketValue1 * (1 + homePriceAppreciation / 100)
    const marketValue3 =
      marketValue2 * (1 + homePriceAppreciation / 100)
    const marketValue4 =
      marketValue3 * (1 + homePriceAppreciation / 100)
    const marketValue5 =
      marketValue4 * (1 + homePriceAppreciation / 100)

    const loanBalance = acquisition.analytics.turnkey
      .loanAmountTurnkey
      ? acquisition.analytics.turnkey.loanAmountTurnkey
      : 0

    const loanBalanceAtHoldingPeriod1 = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: 1,
      paydownMonthly,
      holdingPeriod: true,
    })

    const loanBalanceAtHoldingPeriod5 = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: 5,
      paydownMonthly,
      holdingPeriod: true,
    })

    const equity1 = marketValue1 - loanBalanceAtHoldingPeriod1
    const equity5 = marketValue5 - loanBalanceAtHoldingPeriod5

    const loanToValueRatio1 = equity1 / marketValue1
    const loanToValueRatio5 = equity5 / marketValue5

    const cashOutPercent = assumptions.refinanceCashOut
      ? assumptions.refinanceCashOut
      : 0

    const potentialCashOut1 =
      (marketValue1 * cashOutPercent) / 100 - loanBalance
    const potentialCashOut5 =
      (marketValue5 * cashOutPercent) / 100 - loanBalance

    const closingCost1 = (equity1 * sellingClosingCostPercent) / 100
    const closingCost5 = (equity5 * sellingClosingCostPercent) / 100

    const proceedsAfterSale1 = equity1 - closingCost1
    const proceedsAfterSale5 = equity5 - closingCost5

    const cumulativeCashFlow = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: 5 * 12 + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })

    const cumulativeCashFlow1 = cumulativeCashFlow[0].cashFlow
    const cumulativeCashFlow5 = cumulativeCashFlow[4].cashFlow

    const netProfit1 =
      proceedsAfterSale1 +
      cumulativeCashFlow1 -
      totalInitialInvestmentFinancing
    const netProfit5 =
      proceedsAfterSale5 +
      cumulativeCashFlow5 -
      totalInitialInvestmentFinancing

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const percentageFormat = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
    })

    return {
      pageBreak: 'before',
      margin: [15, 10, 15, 15],
      stack: [
        {
          margin: [0, 0, 0, 10],
          columns: [
            {
              stack: [
                {
                  absolutePosition: { x: 0, y: 0 },
                  canvas: [
                    {
                      type: 'rect',
                      x: 0,
                      y: 0,
                      w: 300,
                      h: 50,
                      r: 0,
                      color: '#368CFF',
                      fillOpacity: 0.5,
                    },
                  ],
                },
                {
                  text: 'Highlights',
                  color: '#ffffff',
                  fontSize: 24,
                  margin: [85, 0, 0, 0],
                },
                {
                  absolutePosition: { x: 0, y: 60 },
                  canvas: [
                    {
                      type: 'rect',
                      x: 0,
                      y: 0,
                      w: 300,
                      h: 120,
                      r: 0,
                      color: '#fffd91',
                      fillOpacity: 0.5,
                    },
                  ],
                },
                {
                  text: '5 Year Projected Profit',
                  fontSize: 22,
                  bold: true,
                  alignment: 'center',
                  margin: [0, 30, 0, 0],
                },
                {
                  text: [
                    'Assumes Rent Growth of ',
                    annualRevenueIncrease,
                    '% and',
                  ],
                  fontSize: 14,
                  alignment: 'center',
                },
                {
                  text: [
                    'Appreciation of ',
                    homePriceAppreciation,
                    '% annually',
                  ],
                  fontSize: 14,
                  alignment: 'center',
                },
                {
                  text: currencyFormater.format(netProfit5),
                  fontSize: 20,
                  bold: true,
                  alignment: 'center',
                  margin: [0, 15, 0, 0],
                },
              ],
            },
            {
              margin: [10, 60, 0, 0],
              stack: [
                {
                  text: ['Cash-On-Cash Return - ', cashOnCash, ' %'],
                  fontSize: 18,
                  margin: [0, 10, 30, 0],
                },
                {
                  text: '(1st Year)',
                  fontSize: 14,
                  margin: [0, 0, 0, 0],
                },
                {
                  text: ['5 Year IRR Projection - ', IRR, ' %'],
                  fontSize: 18,
                  margin: [0, 20, 0, 0],
                },
              ],
            },
          ],
        },
        {
          columns: [
            {
              stack: [
                {
                  text: 'Cash Flow',
                  fontSize: 20,
                  bold: true,
                  alignment: 'center',
                  margin: [0, 20, 0, 0],
                },
                {
                  text: 'Year 1 Operational Cash Flow',
                  fontSize: 16,
                  alignment: 'center',
                },
                {
                  margin: [15, 10, 15, 0],
                  table: {
                    headerRows: 1,
                    widths: [150, '*', '*'],
                    body: [
                      [
                        {
                          text: 'Revenues',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'left',
                        },
                        {
                          text: 'Monthly',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'right',
                        },
                        {
                          text: 'Annual',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'right',
                        },
                      ],
                      [
                        {
                          text: 'Potential Rental Income',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            sum(rentAmount),
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            sum(rentAmount) * 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text:
                            'Vacancy Rate (' +
                            percentageFormat.format(
                              vacancyRate1 / 12 / sum(rentAmount),
                            ) +
                            ')',
                          alignment: 'left',
                          fontSize: 6,
                          border: [false, false, false, false],
                        },
                        {
                          text: currencyFormater.format(
                            vacancyRate1 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(vacancyRate1),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text: 'Monthly Other Income',
                          alignment: 'left',
                          fontSize: 6,
                          border: [false, false, false, true],
                        },
                        {
                          text: currencyFormater.format(
                            otherIncomeMonthly,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          border: [false, false, false, true],
                        },
                        {
                          text: currencyFormater.format(otherIncome1),
                          alignment: 'right',
                          fontSize: 6,
                          border: [false, false, false, true],
                        },
                      ],
                      [
                        {
                          text: 'Gross Rental Income',
                          alignment: 'left',
                          fontSize: 6,
                          bold: true,
                        },
                        {
                          text: currencyFormater.format(
                            revenueTotal1 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          bold: true,
                        },
                        {
                          text: currencyFormater.format(
                            revenueTotal1,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          bold: true,
                        },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
                {
                  margin: [15, 5, 15, 0],
                  table: {
                    headerRows: 1,
                    widths: [150, '*', '*'],
                    body: [
                      [
                        {
                          text: 'Expenses',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'left',
                        },
                        {
                          text: 'Monthly',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'right',
                        },
                        {
                          text: 'Annual',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'right',
                        },
                      ],
                      [
                        {
                          text: 'Property Taxes',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            propertyTaxes / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            propertyTaxes,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text: 'Homeowners Insurance',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            homeInsurance / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            homeInsurance,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text: 'HOA Dues',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(monthlyHOA),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(yearlyHOA),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text:
                            'Prop Mgmt Fee (' +
                            propertyMgmtPercent +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            ((propertyMgmtPercent / 100) *
                              grossRentalIncome1) /
                              12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            propertyManagement1,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text: 'Avg 1 Year Tax Discount',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            propertyTaxesDiscount,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            propertyTaxesDiscount * 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text:
                            'Maintenance & Repairs (' +
                            maintenance +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            ((maintenance / 100) *
                              grossRentalIncome1) /
                              12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(maintenance1),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text: 'Utilities',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(utilities),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(utilities1),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      // [
                      //   {
                      //     text:
                      //       'Asset Management Fee (' +
                      //       assetManagementFee +
                      //       '%)',
                      //     alignment: 'left',
                      //     fontSize: 6,
                      //   },
                      //   {
                      //     text: currencyFormater.format(assetFee1 / 12),
                      //     alignment: 'right',
                      //     fontSize: 6,
                      //   },
                      //   {
                      //     text: currencyFormater.format(assetFee1),
                      //     alignment: 'right',
                      //     fontSize: 6,
                      //   },
                      // ],
                      // [
                      //   {
                      //     text: 'Other Expenses',
                      //     alignment: 'left',
                      //     fontSize: 6,
                      //     border: [false, false, false, true],
                      //   },
                      //   {
                      //     text: currencyFormater.format(otherExpenses / 12),
                      //     alignment: 'right',
                      //     fontSize: 6,
                      //     border: [false, false, false, true],
                      //   },
                      //   {
                      //     text: currencyFormater.format(otherExpenses),
                      //     alignment: 'right',
                      //     fontSize: 6,
                      //     border: [false, false, false, true],
                      //   },
                      // ],
                      [
                        {
                          text: 'Total Expenses',
                          alignment: 'left',
                          fontSize: 6,
                          bold: true,
                        },
                        {
                          text: currencyFormater.format(
                            totalExpenseMonthly,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          bold: true,
                        },
                        {
                          text: currencyFormater.format(
                            totalExpense1,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          bold: true,
                        },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
                {
                  margin: [15, 5, 15, 0],
                  table: {
                    headerRows: 1,
                    widths: [150, '*', '*'],
                    body: [
                      [
                        {
                          text: 'Net Performance',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'left',
                        },
                        {
                          text: 'Monthly',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'right',
                        },
                        {
                          text: 'Annual',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'right',
                        },
                      ],
                      [
                        {
                          text: 'Net Operating Income',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            NOIFinanceMonthly,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(NOIFinance1),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text: '-Mortgage',
                          alignment: 'left',
                          fontSize: 6,
                          border: [false, false, false, true],
                        },
                        {
                          text: currencyFormater.format(
                            mortgagePayment,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          border: [false, false, false, true],
                        },
                        {
                          text: currencyFormater.format(
                            mortgagePaymentYearly,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          border: [false, false, false, true],
                        },
                      ],
                      [
                        {
                          text: '=Net Cashflow',
                          alignment: 'left',
                          fontSize: 6,
                          bold: true,
                        },
                        {
                          text: currencyFormater.format(
                            totalReturnFinancing1 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          bold: true,
                        },
                        {
                          text: currencyFormater.format(
                            totalReturnFinancing1,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          bold: true,
                        },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
                {
                  margin: [15, 30, 0, 0],
                  text: 'Year 1 Benefits',
                  fontSize: 20,
                  bold: true,
                  alignment: 'center',
                },
                {
                  margin: [15, 10, 0, 0],
                  widths: [300, '*'],
                  columns: [
                    {
                      stack: [
                        {
                          text: 'Operation Cash Flow',
                          fontSize: 10,
                          margin: [0, 10, 0, 0],
                        },
                        {
                          text: 'Year 1 Income Tax Savings',
                          fontSize: 10,
                          margin: [0, 10, 0, 0],
                        },
                        {
                          text: 'Year 1 Economical Benefits',
                          fontSize: 10,
                          margin: [0, 10, 0, 0],
                        },
                      ],
                    },
                    {
                      stack: [
                        {
                          text: currencyFormater.format(
                            totalReturnFinancing1,
                          ),
                          fontSize: 10,
                          margin: [0, 10, 0, 0],
                          alignment: 'right',
                        },
                        {
                          text: currencyFormater.format(
                            propertyTaxesDiscount * 12,
                          ),
                          fontSize: 10,
                          margin: [0, 10, 0, 0],
                          alignment: 'right',
                        },
                        {
                          text: currencyFormater.format(
                            totalReturnFinancing1 +
                              propertyTaxesDiscount * 12,
                          ),
                          fontSize: 10,
                          margin: [0, 10, 0, 0],
                          alignment: 'right',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              stack: [
                {
                  text: 'Rent Growth',
                  fontSize: 20,
                  bold: true,
                  alignment: 'center',
                  margin: [0, 20, 0, 0],
                },
                {
                  text: 'Year 5 Operational Cash Flow',
                  fontSize: 16,
                  alignment: 'center',
                },
                {
                  margin: [15, 10, 15, 0],
                  table: {
                    headerRows: 1,
                    widths: [150, '*', '*'],
                    body: [
                      [
                        {
                          text: 'Revenues',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'left',
                        },
                        {
                          text: 'Monthly',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'right',
                        },
                        {
                          text: 'Annual',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'right',
                        },
                      ],
                      [
                        {
                          text: 'Potential Rental Income',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            sum(rentalIncome5) / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            sum(rentalIncome5),
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text: 'Vacancy Losses',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            vacancyRate5 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(vacancyRate5),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text: 'Monthly Other Income',
                          alignment: 'left',
                          fontSize: 6,
                          border: [false, false, false, true],
                        },
                        {
                          text: currencyFormater.format(
                            sum(otherIncome5) / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          border: [false, false, false, true],
                        },
                        {
                          text: currencyFormater.format(
                            sum(otherIncome5),
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          border: [false, false, false, true],
                        },
                      ],
                      [
                        {
                          text: 'Gross Rental Income',
                          alignment: 'left',
                          fontSize: 6,
                          bold: true,
                        },
                        {
                          text: currencyFormater.format(
                            revenueTotal5 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          bold: true,
                        },
                        {
                          text: currencyFormater.format(
                            revenueTotal5,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          bold: true,
                        },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
                {
                  margin: [15, 5, 15, 0],
                  table: {
                    headerRows: 1,
                    widths: [150, '*', '*'],
                    body: [
                      [
                        {
                          text: 'Expenses',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'left',
                        },
                        {
                          text: 'Monthly',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'right',
                        },
                        {
                          text: 'Annual',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'right',
                        },
                      ],
                      [
                        {
                          text: 'Property Taxes',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            propertyTaxes5 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            propertyTaxes5,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text: 'Homeowners Insurance',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            homeInsurance5 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            homeInsurance5,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text: 'HOA Dues',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(HOA5 / 12),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(HOA5),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text:
                            'Prop Mgmt Fee (' +
                            propertyMgmtPercent +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            propertyManagement5 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            propertyManagement5,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text:
                            'Maintenance & Repairs (' +
                            maintenance +
                            '%)',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            maintenance5 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(maintenance5),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text: 'Utilities',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            utilities5 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(utilities5),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      // [
                      //   {
                      //     text:
                      //       'Asset Management Fee (' +
                      //       assetManagementFee +
                      //       '%)',
                      //     alignment: 'left',
                      //     fontSize: 6,
                      //   },
                      //   {
                      //     text: currencyFormater.format(assetFee5 / 12),
                      //     alignment: 'right',
                      //     fontSize: 6,
                      //   },
                      //   {
                      //     text: currencyFormater.format(assetFee5),
                      //     alignment: 'right',
                      //     fontSize: 6,
                      //   },
                      // ],
                      // [
                      //   {
                      //     text: 'Other Expenses',
                      //     alignment: 'left',
                      //     fontSize: 6,
                      //     border: [false, false, false, true],
                      //   },
                      //   {
                      //     text: currencyFormater.format(otherExpense5 / 12),
                      //     alignment: 'right',
                      //     fontSize: 6,
                      //     border: [false, false, false, true],
                      //   },
                      //   {
                      //     text: currencyFormater.format(otherExpense5),
                      //     alignment: 'right',
                      //     fontSize: 6,
                      //     border: [false, false, false, true],
                      //   },
                      // ],
                      [
                        {
                          text: 'Total Expenses',
                          alignment: 'left',
                          fontSize: 6,
                          bold: true,
                        },
                        {
                          text: currencyFormater.format(
                            totalExpense5 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          bold: true,
                        },
                        {
                          text: currencyFormater.format(
                            totalExpense5,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          bold: true,
                        },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
                {
                  margin: [15, 5, 15, 0],
                  table: {
                    headerRows: 1,
                    widths: [150, '*', '*'],
                    body: [
                      [
                        {
                          text: 'Net Performance',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'left',
                        },
                        {
                          text: 'Monthly',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'right',
                        },
                        {
                          text: 'Annual',
                          fillColor: '#3399ff',
                          color: 'white',
                          fontSize: 8,
                          alignment: 'right',
                        },
                      ],
                      [
                        {
                          text: 'Net Operating Income',
                          alignment: 'left',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(
                            NOIFinance5 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                        },
                        {
                          text: currencyFormater.format(NOIFinance5),
                          alignment: 'right',
                          fontSize: 6,
                        },
                      ],
                      [
                        {
                          text: '-Mortgage',
                          alignment: 'left',
                          fontSize: 6,
                          border: [false, false, false, true],
                        },
                        {
                          text: currencyFormater.format(
                            mortgagePayment,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          border: [false, false, false, true],
                        },
                        {
                          text: currencyFormater.format(
                            mortgagePaymentYearly,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          border: [false, false, false, true],
                        },
                      ],
                      [
                        {
                          text: '=Net Cashflow',
                          alignment: 'left',
                          fontSize: 6,
                          bold: true,
                        },
                        {
                          text: currencyFormater.format(
                            totalReturnFinancing5 / 12,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          bold: true,
                        },
                        {
                          text: currencyFormater.format(
                            totalReturnFinancing5,
                          ),
                          alignment: 'right',
                          fontSize: 6,
                          bold: true,
                        },
                      ],
                    ],
                  },
                  layout: {
                    defaultBorder: false,
                  },
                },
                {
                  margin: [15, 42, 0, 0],
                  text: 'Appreciation',
                  fontSize: 20,
                  bold: true,
                  alignment: 'center',
                },
                {
                  margin: [15, 10, 0, 0],
                  widths: ['*', 100],
                  columns: [
                    {
                      stack: [
                        {
                          text: 'Original Home Value',
                          fontSize: 10,
                          margin: [0, 10, 0, 0],
                        },
                        {
                          text: '5 Year Projected Value',
                          fontSize: 10,
                          margin: [0, 10, 0, 0],
                        },
                        {
                          text: 'Equity',
                          fontSize: 10,
                          margin: [0, 10, 0, 0],
                        },
                      ],
                    },
                    {
                      stack: [
                        {
                          text: currencyFormater.format(marketValue1),
                          fontSize: 10,
                          margin: [0, 10, 5, 0],
                          alignment: 'right',
                        },
                        {
                          text: currencyFormater.format(marketValue5),
                          fontSize: 10,
                          margin: [0, 10, 5, 0],
                          alignment: 'right',
                        },
                        {
                          text: currencyFormater.format(
                            marketValue5 - marketValue1,
                          ),
                          fontSize: 10,
                          margin: [0, 10, 5, 0],
                          alignment: 'right',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    }
  }

  const southernImpressionsMarketingFloorPlanPage = async (
    property,
  ) => {
    const {
      images = [],
      proformaImages = [],
      proformaFloorPlan = '',
    } = property

    let mainImage = ''

    if (!proformaFloorPlan) {
      if (proformaImages.length <= 0) {
        if (images.length <= 0) {
          mainImage = await handleImageUrl(
            '/images/default_image.png',
          )
        } else {
          mainImage = await handleImageUrl(images[0].url)
        }
      } else {
        mainImage = await handleImageUrl(proformaImages[0])
      }
    } else {
      mainImage = await handleImageUrl(proformaFloorPlan)
    }

    return {
      pageBreak: 'before',
      margin: [15, 10, 15, 15],
      stack: [
        {
          absolutePosition: { x: 0, y: 0 },
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 300,
              h: 50,
              r: 0,
              color: '#368CFF',
              fillOpacity: 0.5,
            },
          ],
        },
        {
          text: 'Floorplan',
          color: '#ffffff',
          fontSize: 24,
          margin: [85, 0, 0, 0],
        },
        // {
        //   text: property.address,
        //   fontSize: 18,
        //   margin: [0, 20, 0, 0],
        //   alignment: "center",
        // },
        {
          text: [
            head(property.beds),
            ' Bedrooms | ',
            head(property.baths),
            ' Bathrooms | ',
            head(property.sqft),
            ' Sqft',
          ],
          fontSize: 14,
          alignment: 'center',
          margin: [0, 40, 0, 0],
        },
        {
          image: mainImage,
          width: 500,
          height: 300,
          margin: [0, 20, 0, 0],
          alignment: 'center',
        },
      ],
    }
  }

  const southernImpressionsPage3 = async (property) => {
    return {
      pageBreak: 'before',
      margin: [15, 10, 15, 15],
      columns: [
        {
          width: '*',
          stack: [
            {
              text: 'Definitions',
              bold: true,
              fontSize: 16,
            },
            {
              text: 'Debt Coverage Ration = Net Operating Income / Monthly Mortgage Payment',
              bold: true,
              fontSize: 14,
              margin: [0, 5, 0, 0],
            },
            {
              text: "This metric compares a property's income to its debt obligations.",
              fontSize: 12,
            },
            {
              text: 'If income and debt obligations are exactly the same, the ration will be exactly 1 (breakeven).',
              fontSize: 12,
            },
            {
              text: 'Properties w/ a DCR greater than 1 are considered profitable, and less than 1 are considered unprofitable.',
              fontSize: 12,
            },
            {
              text: 'Annual Gross Rent Multiplier = Purchase Price / Annual Gross Rent',
              bold: true,
              fontSize: 14,
              margin: [0, 5, 0, 0],
            },
            {
              text: 'This is the number of years the property would take to pay for itself in gross received rent.',
              fontSize: 12,
            },
            {
              text: "It's the ratio of the price of a real estate investment to its annual rental income",
              fontSize: 12,
            },
            {
              text: 'Monthly Gross Rent Multiplier = Purchase Price / Monthly Gross Rent',
              bold: true,
              fontSize: 14,
              margin: [0, 5, 0, 0],
            },
            {
              text: 'This is the number of months the property would take to pay for itself in gross received rent.',
              fontSize: 12,
            },
            {
              text: "It's the ratio of the price of a real estate investment to its monthly rental income",
              fontSize: 12,
            },
            {
              text: 'Capitalization Rate = Net Operating Income / Purchase Price',
              bold: true,
              fontSize: 14,
              margin: [0, 5, 0, 0],
            },
            {
              text: "This is an estimation of an investor's unleveraged return on an investment.",
              fontSize: 12,
            },
            {
              text: 'Cash on Cash Return = Cash Flow / Initial Cash Invested',
              bold: true,
              fontSize: 14,
              margin: [0, 5, 0, 0],
            },
            {
              text: 'This is a rate of return ratio that calculates the total cash earned on the total cash invested (leveraged return).',
              fontSize: 12,
            },
            {
              text: 'GEI Return on Investment = Gross Equity Income / Initial Cash Invested',
              bold: true,
              fontSize: 14,
              margin: [0, 5, 0, 0],
            },
            {
              text: 'This is a rate of return ratio that measures the amount of return on an investment relative to the cost.',
              fontSize: 12,
            },
            {
              text: 'This factors in both cash and non-cash earnings (income, principal reduction, and appreciation).',
              fontSize: 12,
            },
            {
              text: 'GEI Return on Investment with Tax Savings = GEI w/ tax savings / Initial Cash Invested',
              bold: true,
              fontSize: 14,
              margin: [0, 5, 0, 0],
            },
            {
              text: 'This is a rate of return ratio that measures the amount of return on an investment relative to the cost.',
              fontSize: 12,
            },
            {
              text: 'This factors in both cash and non-cash earnings (income, principal reduction, appreciation, and depreciation).',
              fontSize: 12,
            },
            {
              text: 'Tax Savings = Purchase Price x 90% / Depreciation Years x Tax Rate',
              bold: true,
              fontSize: 14,
              margin: [0, 5, 0, 0],
            },
            {
              text: 'The amount you can lower your taxable income and potentially reduce your tax liability through depreciation.',
              fontSize: 12,
            },
            {
              text: 'In some instances, you can accelerate your depreciation and achieve greater tax savings in year 1.',
              fontSize: 12,
            },
            {
              text: 'Internal Rate of Return',
              bold: true,
              fontSize: 14,
              margin: [0, 5, 0, 0],
            },
            {
              text: 'This is the anualized rate of return you would receive based on the time period you hold the property, taking into account when cash is deployed and returned (either through rental income, debt or sale).',
              fontSize: 12,
            },
            {
              text: "Disclaimer: There are no implied or expressed guarantees of the above projections.   Real estate investments are subject to risk and loss of capital. The above projections are based on both current and historical data, however,  future performance cannot be guaranteed as markets and economies shift.  Rents, property taxes, insurance, loan rates, maintenance and vacancy costs all vary depending on micro and macro-economic factors. Buyers should perform their own due diligence to best forecast the potential performance.   The interest rates above are based on today's rates for a buyer with good credit, these rates may change.  The tax savings above is based on depreciation of the asset over 27.5 years and a taxable rate of 37%. Year 1 Cash-on-Cash (w/Tax Savings) is caculated by adding income from operations plus federal income tax savings plus property tax savings in year one at an average of 50% of normalized rates (this is due to year 1 property taxes being based on unimproved land) divided by initial cash invested.  Please cosnsult with your legal and tax professionals regarding all assumptions prior to any purchase.",
              fontSize: 10,
              margin: [0, 15, 0, 0],
            },
          ],
        },
        {
          width: 20,
          text: '',
        },
      ],
    }
  }

  const southernImpressionsMarketingHedgeAgainstInflationPage =
    async (property) => {
      return {
        pageBreak: 'before',
        margin: [15, 10, 15, 15],
        columns: [
          {
            width: '*',
            stack: [
              {
                absolutePosition: { x: 0, y: 0 },
                canvas: [
                  {
                    type: 'rect',
                    x: 0,
                    y: 0,
                    w: 300,
                    h: 50,
                    r: 0,
                    color: '#368CFF',
                    fillOpacity: 0.5,
                  },
                ],
              },
              {
                text: 'Hedge Against Inflation',
                color: '#ffffff',
                fontSize: 24,
                margin: [10, 0, 0, 0],
              },
              {
                text: 'How Does Real Estate Hedge Against Inflation?',
                fontSize: 18,
                bold: true,
                margin: [0, 25, 0, 0],
              },
              {
                text: 'Please see the below excerpts from Forbes.',
                fontSize: 12,
              },
              {
                text: '1. Asset Price Inflation',
                fontSize: 16,
                bold: true,
                margin: [0, 15, 0, 0],
              },
              {
                text: `Let's say that you buy a $1 million apartment building with a $200,000 down payment. After a couple of years, say that there's 10% total inflation. Your building just appreciated in price to $1.1 million. And you might be wondering, "How am I any better off if I have 10% more dollars but each dollar is worth 10% less? Aren't I right back where I started?"`,
                fontSize: 12,
              },
              {
                text: `The answer is "no." This is because you have an $800,000 loan on this property. The mortgage debt payments are outsourced to tenants. Your $200,000 of equity just grew to $300,000. That's a 50% return on your equity despite 10% inflation. You just beat inflation by five times because you leveraged the bank's loan and the tenants' income.`,
                fontSize: 12,
                margin: [0, 5, 0, 0],
              },
              {
                text: '2. Debt Debasement',
                fontSize: 16,
                bold: true,
                margin: [0, 15, 0, 0],
              },
              {
                text: `In this different example, let's say that you own a mobile home park with $1 million of debt on it. If there's 5% annual inflation, you only owe the bank back $950,000 of inflation-adjusted dollars after year one, $900,000 after year two and so on.`,
                fontSize: 12,
              },
              {
                text: `See, the bank does not ask you to repay them in inflation-adjusted dollars, only nominal dollars. Nominal means "in name only." Again, the tenant pays the mortgage principal and interest for you. You have a $50,000 annual tailwind that you've probably never even thought about.`,
                fontSize: 12,
                margin: [0, 5, 0, 0],
              },
              {
                text: '3. Cash Flow Enhancement',
                fontSize: 16,
                bold: true,
                margin: [0, 15, 0, 0],
              },
              {
                text: `In a third example, let's say that you have a rental house with $1,000 of monthly rental income, minus a $500 mortgage payment, minus $300 in operating expenses. This means that you have $200 of passive monthly cash flow after all of your property's expenses are paid.`,
                fontSize: 12,
              },
              {
                text: `With 10% inflation after a couple of years, say that your rent is then $1,100. Your mortgage payment of $500 remains fixed, and your inflation-indexed operating expenses jump up to $330. Now your residual cash flow is $270. Well, this is 35% more than the $200 that you received prior to the 10% monetary inflation.`,
                fontSize: 12,
                margin: [0, 5, 0, 0],
              },
              {
                text: `So, despite 10% inflation, how did you achieve 35% more money in your pocket every month? This is because the property's greatest expense, the principal and interest payment, remains fixed over time. Imagine extrapolating this powerful effect across every property in your portfolio. In my opinion, the ability to secure property with a 30-year fixed amortizing loan is remarkable; the U.S. is one of the very few nations in the world where one has this benefit.`,
                fontSize: 12,
                margin: [0, 5, 0, 0],
              },
              {
                text: "Disclaimer: There is no implied or expressed guarantees of the above projections. Real estate investments are subject to risk and loss of capital. The above projections are based on both current and historical data, however,  future performance cannot be guaranteed as markets and economies shift.  Rents, property taxes, insurance, loan rates, maintenance, and vacancy costs all vary depending on micro and macro-economic factors. Buyers should perform their own due diligence to best forecast the potential performance.   The interest rates above are based on today's rates for a buyer with good credit, these rates may change.  The tax savings above is based on the depreciation of the asset over 27.5 years and a taxable rate of 37%. Please consult with your tax professional related to your specific tax planning.",
                fontSize: 10,
                absolutePosition: { x: 15, y: 735 },
                // margin: [0, 15, 0, 0]
              },
            ],
          },
          {
            width: 20,
            text: '',
          },
        ],
      }
    }

  const imagePages = async (property) => {
    const { images = [] } = property

    const imageUrls = await Promise.all(
      map(images, async (image) => await handleImageUrl(image.url)),
    )

    let pageBreakUrls = chunk(imageUrls, 15).map((pageUrls) => {
      const chunkedUrls = chunk(pageUrls, 3)
      const columnUrls = unzip(chunkedUrls)
      return columnUrls
    })

    if (isEmpty(imageUrls)) return null
    else
      return map(pageBreakUrls, (pageImages) => ({
        pageBreak: 'before',
        margin: [15, 10, 15, 15],
        stack: [
          {
            width: '*',
            stack: [
              {
                text: [
                  'Property ',
                  { text: 'Images', color: '#3399ff' },
                ],
                fontSize: 18,
                margin: [15, 20, 0, 0],
              },
              {
                canvas: [
                  {
                    type: 'line',
                    x1: 15,
                    y1: 5,
                    x2: 150,
                    y2: 5,
                    lineWidth: 2,
                    margin: [0, 0, 0, 10],
                  },
                ],
              },
              {
                columns: map(pageImages, (columnImages) => ({
                  width: 190,
                  stack: map(compact(columnImages), (image) => {
                    return {
                      image: image,
                      width: 185,
                      height: 130,
                      margin: 5,
                    }
                  }),
                })),
              },
            ],
          },
        ],
      }))
  }

  const defaultFooter = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      proformaImagePage = true,
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio

    const whiteImage = await handleImageUrl('/images/white.jpg')

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    return [
      {
        absolutePosition: { x: 5, y: 770 },
        columns: [
          {
            table: {
              headerRows: 1,
              widths: [70, 100, '*', 180],
              body: [
                [
                  {
                    text: 'Powered By ',
                    alignment: 'left',
                    color: '#8e9194',
                    margin: [0, 10, 0, 0],
                  },
                  {
                    image: await handleImageUrl(
                      urlAffiliate
                        ? get(
                            affiliate,
                            'sidebar.sidebarMemberFullImage.0.url',
                          ) || '/images/NewLogoBlue.png'
                        : '/images/NewLogoBlue.png',
                    ),
                    width: 100,
                    height: 20,
                    margin: [-5, 7, 0, 0],
                  },
                  {
                    text: orgPhone,
                    alignment: 'right',
                    margin: [0, 10, 0, 0],
                    // margin:
                    //   orgEmail.length > 30
                    //     ? [0, 4, 0, 0]
                    //     : [0, 0, 0, 0],
                  },
                  {
                    image: orgLogo,
                    fit: [125, 50],
                    // width: 150,
                    // height: 75,
                    margin: [0, 0, 0, 0],
                    alignment: 'center',
                    rowSpan: 2,
                  },
                ],
                [
                  {
                    text: 'Create Your Proforma at',
                    alignment: 'left',
                    margin: [0, -3, 0, 0],
                    color: '#8e9194',
                    fontSize: 8,
                    noWrap: true,
                    //colSpan: 1,
                  },
                  {
                    text: urlDomain || 'housefolios.com',
                    link:
                      'https://' +
                      (urlDomain || 'www.housefolios.com') +
                      '/',
                    alignment: 'left',
                    decoration: 'underline',
                    margin: [10, -3, 0, 0],
                    color: '#3399ff',
                    fontSize: 8,
                    //colSpan: 1,
                  },
                  {
                    text: orgEmail,
                    alignment: 'right',
                    margin: [0, -5, 0, 0],
                    fontSize:
                      orgEmail.length > 20
                        ? orgEmail.length > 30
                          ? 8
                          : 10
                        : 12,
                    // margin:
                    //   orgEmail.length > 30
                    //     ? [0, 4, 0, 0]
                    //     : [0, 0, 0, 0],
                  },
                ],
              ],
            },
            margin: [5, 10, 10, 0],
            layout: {
              defaultBorder: false,
            },
          },
        ],
      },
    ]
  }

  const makePDF = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      proformaImagePage = true,
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    const { legalDescription = '' } = organization
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    // const mainImage = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )
    // const subImageOne = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )
    // const subImageTwo = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )
    // const mapImage = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )

    let mainImage = ''
    let subImageOne = ''
    let subImageTwo = ''
    let mapImage = ''
    if (proformaImages.length <= 0)
      switch (images.length) {
        case 0: {
          mainImage = await handleImageUrl(
            '/images/default_image.png',
          )
          subImageOne = await handleImageUrl(
            '/images/default_image.png',
          )
          subImageTwo = await handleImageUrl(
            '/images/default_image.png',
          )
          mapImage = await handleImageUrl('/images/default_image.png')
          break
        }
        case 1: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = whiteImage
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 2: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 3: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = await handleImageUrl(images[2].url)
          mapImage = whiteImage
          break
        }
        default: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = await handleImageUrl(images[2].url)
          mapImage = await handleImageUrl(images[3].url)
          break
        }
      }
    else
      switch (proformaImages.length) {
        case 1: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = whiteImage
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 2: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 3: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = await handleImageUrl(proformaImages[2])
          mapImage = whiteImage
          break
        }
        default: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = await handleImageUrl(proformaImages[2])
          mapImage = await handleImageUrl(proformaImages[3])
          break
        }
      }

    const IRR = acquisition.analytics.buyHold.IRR
      ? (acquisition.analytics.buyHold.IRR * 100).toFixed(2)
      : 0
    const CAPRate = acquisition.analytics.buyHold.capRate
      ? (acquisition.analytics.buyHold.capRate * 100).toFixed(2)
      : 0
    // const cashFlow = acquisition.analytics.buyHold.cashFlow
    //   ? acquisition.analytics.buyHold.cashFlow
    //   : 0
    const netROI = acquisition.analytics.fixFlip.netROIFixFlip
      ? (acquisition.analytics.fixFlip.netROIFixFlip * 100).toFixed(2)
      : 0
    const cashOnCash = acquisition.analytics.buyHold.cashOnCashReturn
      ? (
          acquisition.analytics.buyHold.cashOnCashReturn * 100
        ).toFixed(2)
      : 0
    const equity = acquisition.analytics.buyHold.equity
      ? acquisition.analytics.buyHold.equity
      : 0
    const mortgagePayment = acquisition.analytics.buyHold.mp_monthly
      ? acquisition.analytics.buyHold.mp_monthly
      : 0
    const financeAmount = acquisition.analytics.buyHold.loanAmount
      ? acquisition.analytics.buyHold.loanAmount
      : 0
    const allInCost = acquisition.analytics.buyHold.allInCost
      ? acquisition.analytics.buyHold.allInCost
      : 0
    const downPaymentTotal = acquisition.analytics.buyHold
      .downPaymentTotal
      ? acquisition.analytics.buyHold.downPaymentTotal
      : 0
    //const grossAnnualRent = acquisition.analytics.grossAnnualRent ? acquisition.analytics.grossAnnualRent : 0
    const pointsAmount = acquisition.analytics.buyHold.pointsAmount
      ? acquisition.analytics.buyHold.pointsAmount
      : 0
    const creditFromSeller = assumptions.creditFromSeller
      ? assumptions.creditFromSeller
      : 0
    const closeCostDollar = acquisition.analytics.buyHold
      .closeCostDollar
      ? acquisition.analytics.buyHold.closeCostDollar
      : 0
    const closeCostPercent = acquisition.analytics.buyHold
      .closeCostPercent
      ? acquisition.analytics.buyHold.closeCostPercent
      : 0
    const paydownMonthly = acquisition.analytics.buyHold
      .paydownMonthly
      ? acquisition.analytics.buyHold.paydownMonthly
      : 0
    const saleProceeds = acquisition.analytics.fixFlip.saleProceeds
      ? acquisition.analytics.fixFlip.saleProceeds
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollar
      ? acquisition.analytics.acquisitionCommissionDollar
      : 0
    const acquisitionCommissionPercent = acquisition.analytics
      .acquisitionCommissionPercent
      ? acquisition.analytics.acquisitionCommissionPercent
      : 0
    const propertyManagementDollar = acquisition.analytics.buyHold
      .propertyManagementDollar
      ? acquisition.analytics.buyHold.propertyManagementDollar
      : 0
    const utilities = acquisition.analytics.buyHold
      .utilitiesTypeDollar
      ? acquisition.analytics.buyHold.utilitiesTypeDollar
      : 0
    const utilitiesPercent = assumptions.utilities
      ? assumptions.utilities
      : 0
    const financingFeeTotal = acquisition.analytics.buyHold
      .financingFeeTotal
      ? acquisition.analytics.buyHold.financingFeeTotal
      : 0
    const sellingClosingCost = acquisition.analytics.buyHold
      .sellingClosingCostDollar
      ? acquisition.analytics.buyHold.sellingClosingCostDollar
      : 0
    const sellingClosingCostPercent = acquisition.analytics.buyHold
      .sellingClosingCostPercent
      ? acquisition.analytics.buyHold.sellingClosingCostPercent
      : 0
    const dispositionFee = acquisition.analytics.buyHold
      .dispositionFeeDollar
      ? acquisition.analytics.buyHold.dispositionFeeDollar
      : 0
    const salesCommission = acquisition.analytics.buyHold
      .salesCommissionTotal
      ? acquisition.analytics.buyHold.salesCommissionTotal
      : 0
    const assetManagementFeeTotal = acquisition.analytics.buyHold
      .assetManagementFeeTotal
      ? acquisition.analytics.buyHold.assetManagementFeeTotal
      : 0
    const salePrice = acquisition.analysis.resellPrice
      ? acquisition.analysis.resellPrice
      : acquisition.analytics.buyHold.arvTotalBuyHold || 0

    const homeInsurance = acquisition.analytics.buyHold
      .propertyInsuranceDollar
      ? acquisition.analytics.buyHold.propertyInsuranceDollar
      : 0
    const maintenanceCost = acquisition.analytics.buyHold
      .maintenanceCost
      ? acquisition.analytics.buyHold.maintenanceCost
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange'
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.buyHold.hoaTotal
      ? acquisition.analytics.buyHold.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.buyHold
      .arvTotalBuyHold
      ? acquisition.analytics.buyHold.arvTotalBuyHold
      : 0
    const offerPrice = acquisition.analysis.offerPrice
      ? acquisition.analysis.offerPrice
      : acquisition.analysis.listPrice
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentage
      ? acquisition.analysis.financingRepairsPercentage
      : 0
    // const rehabCost = acquisition.analysis.endRehabCost
    //   ? acquisition.analysis.endRehabCost
    //   : 0
    const rehabCost = acquisition.analytics.buyHold.corTotal
      ? acquisition.analytics.buyHold.corTotal
      : 0
    // const sellPrice = acquisition.analysis.sellPrice
    //   ? acquisition.analysis.afterRepairValue
    //   : 0
    const downPayment = assumptions.downPayment
      ? assumptions.downPayment
      : 0
    const rate = assumptions.rate ? assumptions.rate : 0
    const term = assumptions.term ? assumptions.term : 0
    const termType = assumptions.termType
      ? assumptions.termType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenance
      ? assumptions.maintenance
      : 0
    const propertyMgmtPercent = acquisition.analytics.buyHold
      .propertyManagementPercentage
      ? acquisition.analytics.buyHold.propertyManagementPercentage
      : 0
    const pointPercentage = assumptions.points
      ? assumptions.points
      : 0
    const homePriceAppreciation = assumptions.homePriceAppreciation
      ? assumptions.homePriceAppreciation
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const holdingPeriod = assumptions.rentalHoldingPeriod
      ? assumptions.rentalHoldingPeriod
      : 0
    // const financingFee = assumptions.financingFee
    //   ? assumptions.financingFee
    //   : 0
    const salesCommissionPercent = assumptions.salesCommission
      ? assumptions.salesCommission
      : 0
    const assetManagementFee = assumptions.assetManagementFee
      ? assumptions.assetManagementFee
      : 0
    // const utilities = assumptions.utilities
    //   ? assumptions.utilities
    //   : 0

    const otherExpenses = assumptions.otherExpense
      ? assumptions.otherExpense
      : 0

    const ownerReserves = assumptions.ownerReserves
      ? assumptions.ownerReserves
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    //const cashMortgagePayment = 0
    const percentOfARV = (allInCost / afterRepairValue) * 100

    const monthlyRate = rate / 12 / 100
    const financedRenovationAmount =
      rehabCost - rehabCost * (downPayment / 100) || 0
    const renovationAmount = rehabCost || 0

    const graphTest = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: termType === 'Years' ? term * 12 : term,
      paydownMonthly,
      //holdingPeriod,
      holdingPeriod: false,
    })
    const appreciation = appreciationSection({
      afterRepairValue,
      homePriceAppreciation,
      term: termType === 'Years' ? term * 12 : term,
    })
    const loanBalanceAtHoldingPeriod = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: holdingPeriod,
      paydownMonthly,
      holdingPeriod: true,
    })

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      financingFeeTotal +
      pointsAmount -
      creditFromSeller +
      renovationAmount * (1 - financingRepairsPercentage / 100) +
      customAcquisitionExpenseTotal
    const totalInitialInvestmentNoFinancing =
      offerPrice +
      renovationAmount +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      pointsAmount +
      financingFeeTotal +
      ownerReserves +
      customAcquisitionExpenseTotal
    //const totalROIFinancing = (cashFlow / totalInitialInvestmentFinancing) * 100
    //const totalROINoFinancing = (netOperatingIncome / totalInitialInvestmentNoFinancing) * 100
    //const cashFlowNoFinancing = netOperatingIncome
    const yearlyHOA = monthlyHOA * 12
    //let revenueYear = 0
    //let expenseType = ''
    const rentalIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const vacancyRate1 =
      sum(
        map(
          zip(rentAmount, vacancy),
          ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
        ),
      ) * 12
    const vacancyRate3 = sum(
      map(
        zip(rentalIncome3, vacancy),
        ([rentalIncome3, vacancy]) => rentalIncome3 * (vacancy / 100),
      ),
    )
    const vacancyRate5 = sum(
      map(
        zip(rentalIncome5, vacancy),
        ([rentalIncome5, vacancy]) => rentalIncome5 * (vacancy / 100),
      ),
    )
    const vacancyRate10 = sum(
      map(
        zip(rentalIncome10, vacancy),
        ([rentalIncome10, vacancy]) =>
          rentalIncome10 * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly
    const grossRentalIncome1 = sum(rentAmount) * 12 - vacancyRate1
    const grossRentalIncome3 = sum(rentalIncome3) - vacancyRate3
    const grossRentalIncome5 = sum(rentalIncome5) - vacancyRate5
    const grossRentalIncome10 = sum(rentalIncome10) - vacancyRate10
    const propertyTaxes3 = expensesSection({
      expenseType: 'PT',
      revenueYear: 3,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes5 = expensesSection({
      expenseType: 'PT',
      revenueYear: 5,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes10 = expensesSection({
      expenseType: 'PT',
      revenueYear: 10,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance3 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 3,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance5 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 5,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance10 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 10,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA3 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 3,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA5 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 5,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA10 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 10,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyManagement1 = propertyManagementDollar
    const propertyManagement3 =
      assumptions.propertyManagementType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome3) * (propertyMgmtPercent / 100)
    const propertyManagement5 =
      assumptions.propertyManagementType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome5) * (propertyMgmtPercent / 100)
    const propertyManagement10 =
      assumptions.propertyManagementType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome10) * (propertyMgmtPercent / 100)
    const maintenance1 = maintenanceCost
    const maintenance3 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance5 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance10 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const utilities1 = utilities * 12
    const utilities3 =
      assumptions.utilitiesType === '$'
        ? utilities * 12
        : sum(rentalIncome3) * (utilitiesPercent / 100)
    const utilities5 =
      assumptions.utilitiesType === '$'
        ? utilities * 12
        : sum(rentalIncome5) * (utilitiesPercent / 100)
    const utilities10 =
      assumptions.utilitiesType === '$'
        ? utilities * 12
        : sum(rentalIncome10) * (utilitiesPercent / 100)
    const assetFee1 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 1,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee3 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee5 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee10 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const otherExpense3 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense5 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense10 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal3 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal5 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal10 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const totalExpense1 =
      propertyTaxes +
      homeInsurance +
      yearlyHOA +
      propertyManagement1 +
      maintenance1 +
      utilities1 +
      assetFee1 +
      otherExpenses +
      customAnnualExpenseTotal
    const totalExpense3 =
      propertyTaxes3 +
      homeInsurance3 +
      HOA3 +
      propertyManagement3 +
      maintenance3 +
      utilities3 +
      assetFee3 +
      otherExpense3 +
      customAnnualExpenseTotal3
    const totalExpense5 =
      propertyTaxes5 +
      homeInsurance5 +
      HOA5 +
      propertyManagement5 +
      maintenance5 +
      utilities5 +
      assetFee5 +
      otherExpense5 +
      customAnnualExpenseTotal5
    const totalExpense10 =
      propertyTaxes10 +
      homeInsurance10 +
      HOA10 +
      propertyManagement10 +
      maintenance10 +
      utilities10 +
      assetFee10 +
      otherExpense10 +
      customAnnualExpenseTotal10
    const saleProceedsTotal =
      salePrice -
      salesCommission -
      sellingClosingCost -
      dispositionFee -
      totalInitialInvestmentFinancing -
      loanBalanceAtHoldingPeriod
    //const expenseRatioMonthly = (totalExpenseMonthly / grossRentalIncomeMonthly) * 100
    const expenseRatio1 = (totalExpense1 / grossRentalIncome1) * 100
    const expenseRatio3 = (totalExpense3 / grossRentalIncome3) * 100
    const expenseRatio5 = (totalExpense5 / grossRentalIncome5) * 100
    const expenseRatio10 =
      (totalExpense10 / grossRentalIncome10) * 100
    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOINoFinance1 = grossRentalIncome1 - totalExpense1
    const NOINoFinance3 = grossRentalIncome3 - totalExpense3
    const NOINoFinance5 = grossRentalIncome5 - totalExpense5
    const NOINoFinance10 = grossRentalIncome10 - totalExpense10
    const NOIFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOIFinance1 = grossRentalIncome1 - totalExpense1
    const NOIFinance3 = grossRentalIncome3 - totalExpense3
    const NOIFinance5 = grossRentalIncome5 - totalExpense5
    const NOIFinance10 = grossRentalIncome10 - totalExpense10
    const mortgagePaymentYearly = mortgagePayment * 12
    const totalReturnFinancingMonthly =
      NOINoFinanceMonthly - mortgagePayment
    const totalReturnFinancing = []
    const totalReturnFinancing1 =
      NOINoFinance1 - mortgagePaymentYearly
    const totalReturnFinancing3 =
      NOINoFinance3 - mortgagePaymentYearly
    const totalReturnFinancing5 =
      NOINoFinance5 - mortgagePaymentYearly
    const totalReturnFinancing10 =
      NOINoFinance10 - mortgagePaymentYearly
    totalReturnFinancing.push({
      one: totalReturnFinancing1,
      three: totalReturnFinancing3,
      five: totalReturnFinancing5,
      ten: totalReturnFinancing10,
    })
    //const totalROIFinancingMonthly = ((NOINoFinanceMonthly -  mortgagePayment)/(downPaymentTotal + ((closeCostPercent/100) * financeAmount))) * 100
    const totalROIFinancing1 =
      (totalReturnFinancing1 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing3 =
      (totalReturnFinancing3 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing5 =
      (totalReturnFinancing5 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing10 =
      (totalReturnFinancing10 / totalInitialInvestmentFinancing) * 100
    const totalReturnNoFinancingMonthly = NOINoFinanceMonthly
    const totalReturnNoFinancing1 = NOINoFinance1
    const totalReturnNoFinancing3 = NOINoFinance3
    const totalReturnNoFinancing5 = NOINoFinance5
    const totalReturnNoFinancing10 = NOINoFinance10
    //const totalROINoFinancingMonthly = ((NOINoFinanceMonthly -  mortgagePayment)/(downPaymentTotal + ((closeCostPercent/100) * financeAmount))) * 100
    const totalROINoFinancing1 =
      (totalReturnNoFinancing1 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing3 =
      (totalReturnNoFinancing3 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing5 =
      (totalReturnNoFinancing5 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing10 =
      (totalReturnNoFinancing10 / totalInitialInvestmentNoFinancing) *
      100

    const cashFlowAccumulation = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: termType === 'Years' ? term * 12 + 1 : term + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })
    const graphDataURL = getChartData(
      property,
      graphTest,
      totalReturnFinancing,
      appreciation,
      cashFlowAccumulation,
      totalInitialInvestmentFinancing,
    )

    //const startingOffer = acquisition.analysis.startingOffer ? acquisition.analysis.startingOffer : acquisition.analysis.listPrice

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    // analysis && '$' + analysis.startingOffer
    //   ? `$ ${analysis.startingOffer}`
    //   : analysis && analysis.offerPrice
    //   ? `$ ${analysis.offerPrice}`
    //   : listPrice

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'

    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [0, 0, 0, 0],
      info: {
        // HIDE ADDRESS SETUP
        // title: property.proformaHideAddress ? `${startCase(property.city)}-${startCase(property.state)}-${property.zip}-finance` : `${property.address}-${startCase(property.city)}-${startCase(property.state)}-${property.zip}-finance`,
        title: `${property.address}-${startCase(
          property.city,
        )}-${startCase(property.state)}-${property.zip}-finance`,
      },
      content: [
        {
          image: mainImage,
          // fit: [600, 300],
          width: 600,
          height: 300,
          margin: [0, 0, 0, 5],
          // alignment: 'center'
        },
        {
          absolutePosition: { x: 150, y: 200 },
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 300,
              h: 100,
              r: 2,
              color: 'black',
              fillOpacity: 0.3,
            },
          ],
        },
        {
          absolutePosition: { x: 0, y: 210 },
          stack: [
            {
              text: currencyFormater.format(offerPrice),
              fontSize: 28,
              color: 'white',
              bold: true,
              alignment: 'center',
            },
            // property.proformaHideAddress ?
            // {
            //   text: '',
            //   fontSize: 16,
            //   color: 'white',
            //   alignment: 'center',
            //   margin: [0, 10, 0, 0],
            // }
            // :
            {
              text: property.address,
              fontSize: 16,
              color: 'white',
              alignment: 'center',
            },
            {
              text:
                startCase(property.city) +
                ' ' +
                startCase(property.state) +
                ', ' +
                property.zip,
              fontSize: 16,
              color: 'white',
              alignment: 'center',
            },
          ],
        },
        {
          columns: [
            {
              width: 215,
              stack: [
                {
                  image: subImageOne,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
                {
                  image: subImageTwo,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
                {
                  image: mapImage,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
              ],
            },
            state.southernImpressions
              ? await southernImpressionsPage1LowerRight(property)
              : await buyHoldPage1LowerRight(property),
          ],
        },
        {
          pageBreak: 'before',
          columns: state.southernImpressions
            ? await southernImpressionsPage2Top(property)
            : await buyHoldPage2Top(property),
        },
        {
          stack: state.southernImpressions
            ? await southernImpressionsPage2Bottom(property)
            : await buyHoldPage2Bottom(property),
        },
        state.southernImpressions
          ? await southernImpressionsPage3(property)
          : null,
        proformaImagePage ? await imagePages(property) : null,
      ],
    }
    return docDefinition
  }

  const makeFixFlipPDF = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      proformaImagePage = true,
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    const { legalDescription = '' } = organization
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    // const mainImage = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )
    // const subImageOne = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )
    // const subImageTwo = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )
    // const mapImage = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )

    let mainImage = ''
    let subImageOne = ''
    let subImageTwo = ''
    let mapImage = ''

    if (proformaImages.length <= 0)
      switch (images.length) {
        case 0: {
          mainImage = await handleImageUrl(
            '/images/default_image.png',
          )
          subImageOne = await handleImageUrl(
            '/images/default_image.png',
          )
          subImageTwo = await handleImageUrl(
            '/images/default_image.png',
          )
          mapImage = await handleImageUrl('/images/default_image.png')
          break
        }
        case 1: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = whiteImage
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 2: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 3: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = await handleImageUrl(images[2].url)
          mapImage = whiteImage
          break
        }
        default: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = await handleImageUrl(images[2].url)
          mapImage = await handleImageUrl(images[3].url)
          break
        }
      }
    else
      switch (proformaImages.length) {
        case 1: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = whiteImage
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 2: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 3: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = await handleImageUrl(proformaImages[2])
          mapImage = whiteImage
          break
        }
        default: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = await handleImageUrl(proformaImages[2])
          mapImage = await handleImageUrl(proformaImages[3])
          break
        }
      }

    const netROI = acquisition.analytics.fixFlip.netROIFixFlip
      ? (acquisition.analytics.fixFlip.netROIFixFlip * 100).toFixed(2)
      : 0
    const mortgagePayment = acquisition.analytics.fixFlip
      .mortgageFixFlip
      ? acquisition.analytics.fixFlip.mortgageFixFlip
      : 0
    const financeAmount = acquisition.analytics.fixFlip
      .loanAmountFixFlip
      ? acquisition.analytics.fixFlip.loanAmountFixFlip
      : 0
    const allInCost = acquisition.analytics.fixFlip.allInCostFixFlip
      ? acquisition.analytics.fixFlip.allInCostFixFlip
      : 0
    const downPaymentTotal = acquisition.analytics.fixFlip
      .downPaymentTotalFixFlip
      ? acquisition.analytics.fixFlip.downPaymentTotalFixFlip
      : 0
    //const grossAnnualRent = acquisition.analytics.grossAnnualRent ? acquisition.analytics.grossAnnualRent : 0
    const pointsAmount = acquisition.analytics.fixFlip
      .pointsAmountFixFlip
      ? acquisition.analytics.fixFlip.pointsAmountFixFlip
      : 0
    const creditFromSeller = assumptions.creditFromSeller
      ? assumptions.creditFromSeller
      : 0
    const closeCostDollar = acquisition.analytics.fixFlip
      .closeCostDollarFixFlip
      ? acquisition.analytics.fixFlip.closeCostDollarFixFlip
      : 0
    const closeCostPercent = acquisition.analytics.fixFlip
      .closeCostPercentFixFlip
      ? acquisition.analytics.fixFlip.closeCostPercentFixFlip
      : 0
    const paydownMonthly = acquisition.analytics.fixFlip
      .paydownMonthlyFixFlip
      ? acquisition.analytics.fixFlip.paydownMonthlyFixFlip
      : 0
    const saleProceeds = acquisition.analytics.fixFlip
      .saleProceedsFixFlip
      ? acquisition.analytics.fixFlip.saleProceedsFixFlip
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollar
      ? acquisition.analytics.acquisitionCommissionDollar
      : 0
    const acquisitionCommissionPercent = acquisition.analytics
      .acquisitionCommissionPercent
      ? acquisition.analytics.acquisitionCommissionPercent
      : 0
    const propertyManagementDollar = acquisition.analytics.fixFlip
      .propertyManagementDollarFixFlip
      ? acquisition.analytics.fixFlip.propertyManagementDollarFixFlip
      : 0
    const utilities = acquisition.analytics.fixFlip
      .utilitiesTypeDollarFixFlip
      ? acquisition.analytics.fixFlip.utilitiesTypeDollarFixFlip
      : 0
    const utilitiesPercent = assumptions.utilitiesFixFlip
      ? assumptions.utilitiesFixFlip
      : 0
    const financingFeeTotal = acquisition.analytics.fixFlip
      .financingFeeTotalFixFlip
      ? acquisition.analytics.fixFlip.financingFeeTotalFixFlip
      : 0
    const sellingClosingCost = acquisition.analytics.fixFlip
      .sellingClosingCostDollarFixFlip
      ? acquisition.analytics.fixFlip.sellingClosingCostDollarFixFlip
      : 0
    const sellingClosingCostPercent = acquisition.analytics.fixFlip
      .sellingClosingCostPercentFixFlip
      ? acquisition.analytics.fixFlip.sellingClosingCostPercentFixFlip
      : 0
    const dispositionFee = acquisition.analytics.fixFlip
      .dispositionFeeDollarFixFlip
      ? acquisition.analytics.fixFlip.dispositionFeeDollarFixFlip
      : 0
    const salesCommission = acquisition.analytics.fixFlip
      .salesCommissionTotalFixFlip
      ? acquisition.analytics.fixFlip.salesCommissionTotalFixFlip
      : 0
    const assetManagementFeeTotal = acquisition.analytics.fixFlip
      .assetManagementFeeTotalFixFlip
      ? acquisition.analytics.fixFlip.assetManagementFeeTotalFixFlip
      : 0
    const salePrice = acquisition.analysis.resellPriceFixFlip
      ? acquisition.analysis.resellPriceFixFlip
      : acquisition.analysis.afterRepair || 0

    const homeInsurance = acquisition.analytics.fixFlip
      .propertyInsuranceDollarFixFlip
      ? acquisition.analytics.fixFlip.propertyInsuranceDollarFixFlip
      : 0
    const maintenanceCost = acquisition.analytics.fixFlip
      .maintenanceCostFixFlip
      ? acquisition.analytics.fixFlip.maintenanceCostFixFlip
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange'
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.fixFlip.hoaTotal
      ? acquisition.analytics.fixFlip.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.fixFlip
      .arvTotalFixFlip
      ? acquisition.analytics.fixFlip.arvTotalFixFlip
      : 0
    const offerPrice = acquisition.analysis.offerPrice
      ? acquisition.analysis.offerPrice
      : acquisition.analysis.listPrice
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentageFixFlip
      ? acquisition.analysis.financingRepairsPercentageFixFlip
      : 0
    // const rehabCost = acquisition.analysis.endRehabCost
    //   ? acquisition.analysis.endRehabCost
    //   : 0
    const rehabCost = acquisition.analytics.fixFlip.corTotalFixFlip
      ? acquisition.analytics.fixFlip.corTotalFixFlip
      : 0
    // const sellPrice = acquisition.analysis.sellPrice
    //   ? acquisition.analysis.afterRepairValue
    //   : 0
    const downPayment = assumptions.downPaymentFixFlip
      ? assumptions.downPaymentFixFlip
      : 0
    const rate = assumptions.rateFixFlip ? assumptions.rateFixFlip : 0
    const term = assumptions.termFixFlip ? assumptions.termFixFlip : 0
    const termType = assumptions.termFixFlipType
      ? assumptions.termFixFlipType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceFixFlip
      ? assumptions.maintenanceFixFlip
      : 0
    const propertyMgmtPercent = acquisition.analytics.fixFlip
      .propertyManagementPercentageFixFlip
      ? acquisition.analytics.fixFlip
          .propertyManagementPercentageFixFlip
      : 0
    const pointPercentage = assumptions.pointsFixFlip
      ? assumptions.pointsFixFlip
      : 0
    const homePriceAppreciation = assumptions.homePriceAppreciation
      ? assumptions.homePriceAppreciation
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const holdingPeriod = assumptions.holdingPeriod
      ? assumptions.holdingPeriod
      : 0
    // const financingFee = assumptions.financingFeeFixFlip
    //   ? assumptions.financingFeeFixFlip
    //   : 0
    const salesCommissionPercent = assumptions.salesCommissionFixFlip
      ? assumptions.salesCommissionFixFlip
      : 0
    const assetManagementFee = assumptions.assetManagementFee
      ? assumptions.assetManagementFee
      : 0
    // const utilities = assumptions.utilitiesFixFlip
    //   ? assumptions.utilitiesFixFlip
    //   : 0

    const otherExpenses = assumptions.otherExpenseFixFlip
      ? assumptions.otherExpenseFixFlip
      : 0
    const ownerReserves = assumptions.ownerReservesFixFlip
      ? assumptions.ownerReservesFixFlip
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    //const cashMortgagePayment = 0
    const percentOfARV = (allInCost / afterRepairValue) * 100

    const monthlyRate = rate / 12 / 100
    const financedRenovationAmount =
      rehabCost - rehabCost * (downPayment / 100) || 0
    const renovationAmount = rehabCost || 0

    const graphTest = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: termType === 'Years' ? term * 12 : term,
      paydownMonthly,
      //holdingPeriod,
      holdingPeriod: false,
    })
    const appreciation = appreciationSection({
      afterRepairValue,
      homePriceAppreciation,
      term: termType === 'Years' ? term * 12 : term,
    })
    const loanBalanceAtHoldingPeriod = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: holdingPeriod,
      paydownMonthly,
      holdingPeriod: true,
    })

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      financingFeeTotal +
      pointsAmount -
      creditFromSeller +
      renovationAmount * (1 - financingRepairsPercentage / 100) +
      customAcquisitionExpenseTotal
    const totalInitialInvestmentNoFinancing =
      offerPrice +
      renovationAmount +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      pointsAmount +
      financingFeeTotal +
      ownerReserves +
      customAcquisitionExpenseTotal
    //const totalROIFinancing = (cashFlow / totalInitialInvestmentFinancing) * 100
    //const totalROINoFinancing = (netOperatingIncome / totalInitialInvestmentNoFinancing) * 100
    //const cashFlowNoFinancing = netOperatingIncome
    const yearlyHOA = monthlyHOA * 12
    //let revenueYear = 0
    //let expenseType = ''
    const rentalIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const vacancyRate1 =
      sum(
        map(
          zip(rentAmount, vacancy),
          ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
        ),
      ) * 12
    const vacancyRate3 = sum(
      map(
        zip(rentalIncome3, vacancy),
        ([rentalIncome3, vacancy]) => rentalIncome3 * (vacancy / 100),
      ),
    )
    const vacancyRate5 = sum(
      map(
        zip(rentalIncome5, vacancy),
        ([rentalIncome5, vacancy]) => rentalIncome5 * (vacancy / 100),
      ),
    )
    const vacancyRate10 = sum(
      map(
        zip(rentalIncome10, vacancy),
        ([rentalIncome10, vacancy]) =>
          rentalIncome10 * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly
    const grossRentalIncome1 = sum(rentAmount) * 12 - vacancyRate1
    const grossRentalIncome3 = sum(rentalIncome3) - vacancyRate3
    const grossRentalIncome5 = sum(rentalIncome5) - vacancyRate5
    const grossRentalIncome10 = sum(rentalIncome10) - vacancyRate10
    const propertyTaxes3 = expensesSection({
      expenseType: 'PT',
      revenueYear: 3,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes5 = expensesSection({
      expenseType: 'PT',
      revenueYear: 5,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes10 = expensesSection({
      expenseType: 'PT',
      revenueYear: 10,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance3 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 3,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance5 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 5,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance10 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 10,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA3 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 3,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA5 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 5,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA10 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 10,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyManagement1 = propertyManagementDollar
    const propertyManagement3 =
      assumptions.propertyManagementFixFlipType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome3) * (propertyMgmtPercent / 100)
    const propertyManagement5 =
      assumptions.propertyManagementFixFlipType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome5) * (propertyMgmtPercent / 100)
    const propertyManagement10 =
      assumptions.propertyManagementFixFlipType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome10) * (propertyMgmtPercent / 100)
    const maintenance1 = maintenanceCost
    const maintenance3 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance5 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance10 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const utilities1 = utilities * 12
    const utilities3 =
      assumptions.utilitiesFixFlipType === '$'
        ? utilities * 12
        : sum(rentalIncome3) * (utilitiesPercent / 100)
    const utilities5 =
      assumptions.utilitiesFixFlipType === '$'
        ? utilities * 12
        : sum(rentalIncome5) * (utilitiesPercent / 100)
    const utilities10 =
      assumptions.utilitiesFixFlipType === '$'
        ? utilities * 12
        : sum(rentalIncome10) * (utilitiesPercent / 100)
    const assetFee1 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 1,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee3 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee5 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee10 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const otherExpense3 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense5 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense10 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal3 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal5 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal10 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const totalExpense1 =
      propertyTaxes +
      homeInsurance +
      yearlyHOA +
      propertyManagement1 +
      maintenance1 +
      utilities1 +
      assetFee1 +
      otherExpenses +
      customAnnualExpenseTotal
    const totalExpense3 =
      propertyTaxes3 +
      homeInsurance3 +
      HOA3 +
      propertyManagement3 +
      maintenance3 +
      utilities3 +
      assetFee3 +
      otherExpense3 +
      customAnnualExpenseTotal3
    const totalExpense5 =
      propertyTaxes5 +
      homeInsurance5 +
      HOA5 +
      propertyManagement5 +
      maintenance5 +
      utilities5 +
      assetFee5 +
      otherExpense5 +
      customAnnualExpenseTotal5
    const totalExpense10 =
      propertyTaxes10 +
      homeInsurance10 +
      HOA10 +
      propertyManagement10 +
      maintenance10 +
      utilities10 +
      assetFee10 +
      otherExpense10 +
      customAnnualExpenseTotal10
    const saleProceedsTotal =
      salePrice -
      salesCommission -
      sellingClosingCost -
      dispositionFee -
      totalInitialInvestmentFinancing -
      loanBalanceAtHoldingPeriod
    //const expenseRatioMonthly = (totalExpenseMonthly / grossRentalIncomeMonthly) * 100
    const expenseRatio1 = (totalExpense1 / grossRentalIncome1) * 100
    const expenseRatio3 = (totalExpense3 / grossRentalIncome3) * 100
    const expenseRatio5 = (totalExpense5 / grossRentalIncome5) * 100
    const expenseRatio10 =
      (totalExpense10 / grossRentalIncome10) * 100
    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOINoFinance1 = grossRentalIncome1 - totalExpense1
    const NOINoFinance3 = grossRentalIncome3 - totalExpense3
    const NOINoFinance5 = grossRentalIncome5 - totalExpense5
    const NOINoFinance10 = grossRentalIncome10 - totalExpense10
    const NOIFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOIFinance1 = grossRentalIncome1 - totalExpense1
    const NOIFinance3 = grossRentalIncome3 - totalExpense3
    const NOIFinance5 = grossRentalIncome5 - totalExpense5
    const NOIFinance10 = grossRentalIncome10 - totalExpense10
    const mortgagePaymentYearly = mortgagePayment * 12
    const totalReturnFinancingMonthly =
      NOINoFinanceMonthly - mortgagePayment
    const totalReturnFinancing = []
    const totalReturnFinancing1 =
      NOINoFinance1 - mortgagePaymentYearly
    const totalReturnFinancing3 =
      NOINoFinance3 - mortgagePaymentYearly
    const totalReturnFinancing5 =
      NOINoFinance5 - mortgagePaymentYearly
    const totalReturnFinancing10 =
      NOINoFinance10 - mortgagePaymentYearly
    totalReturnFinancing.push({
      one: totalReturnFinancing1,
      three: totalReturnFinancing3,
      five: totalReturnFinancing5,
      ten: totalReturnFinancing10,
    })
    //const totalROIFinancingMonthly = ((NOINoFinanceMonthly -  mortgagePayment)/(downPaymentTotal + ((closeCostPercent/100) * financeAmount))) * 100
    const totalROIFinancing1 =
      (totalReturnFinancing1 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing3 =
      (totalReturnFinancing3 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing5 =
      (totalReturnFinancing5 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing10 =
      (totalReturnFinancing10 / totalInitialInvestmentFinancing) * 100
    const totalReturnNoFinancingMonthly = NOINoFinanceMonthly
    const totalReturnNoFinancing1 = NOINoFinance1
    const totalReturnNoFinancing3 = NOINoFinance3
    const totalReturnNoFinancing5 = NOINoFinance5
    const totalReturnNoFinancing10 = NOINoFinance10
    //const totalROINoFinancingMonthly = ((NOINoFinanceMonthly -  mortgagePayment)/(downPaymentTotal + ((closeCostPercent/100) * financeAmount))) * 100
    const totalROINoFinancing1 =
      (totalReturnNoFinancing1 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing3 =
      (totalReturnNoFinancing3 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing5 =
      (totalReturnNoFinancing5 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing10 =
      (totalReturnNoFinancing10 / totalInitialInvestmentNoFinancing) *
      100

    const cashFlowAccumulation = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: termType === 'Years' ? term * 12 + 1 : term + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })
    const graphDataURL = getChartData(
      property,
      graphTest,
      totalReturnFinancing,
      appreciation,
      cashFlowAccumulation,
      totalInitialInvestmentFinancing,
    )

    //const startingOffer = acquisition.analysis.startingOffer ? acquisition.analysis.startingOffer : acquisition.analysis.listPrice

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    // analysis && '$' + analysis.startingOffer
    //   ? `$ ${analysis.startingOffer}`
    //   : analysis && analysis.offerPrice
    //   ? `$ ${analysis.offerPrice}`
    //   : listPrice

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'

    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [0, 0, 0, 0],
      info: {
        // HIDE ADDRESS SETUP
        // title: property.proformaHideAddress ? `${startCase(property.city)}-${startCase(property.state)}-${property.zip}-finance` : `${property.address}-${startCase(property.city)}-${startCase(property.state)}-${property.zip}-finance`,
        title: `${property.address}-${startCase(
          property.city,
        )}-${startCase(property.state)}-${property.zip}-finance`,
      },
      content: [
        {
          image: mainImage,
          // fit: [600, 300],
          width: 600,
          height: 300,
          margin: [0, 0, 0, 5],
          // alignment: 'center'
        },
        {
          absolutePosition: { x: 150, y: 200 },
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 300,
              h: 100,
              r: 2,
              color: 'black',
              fillOpacity: 0.3,
            },
          ],
        },
        {
          absolutePosition: { x: 0, y: 210 },
          stack: [
            {
              text: currencyFormater.format(offerPrice),
              fontSize: 28,
              color: 'white',
              bold: true,
              alignment: 'center',
            },
            // property.proformaHideAddress ?
            // {
            //   text: '',
            //   fontSize: 16,
            //   color: 'white',
            //   alignment: 'center',
            //   margin: [0, 10, 0, 0],
            // }
            // :
            {
              text: property.address,
              fontSize: 16,
              color: 'white',
              alignment: 'center',
            },
            {
              text:
                startCase(property.city) +
                ' ' +
                startCase(property.state) +
                ', ' +
                property.zip,
              fontSize: 16,
              color: 'white',
              alignment: 'center',
            },
          ],
        },
        {
          columns: [
            {
              width: 215,
              stack: [
                {
                  image: subImageOne,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
                {
                  image: subImageTwo,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
                {
                  image: mapImage,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
              ],
            },
            state.southernImpressions
              ? await southernImpressionsPage1LowerRight(property)
              : await fixFlipPage1LowerRight(property),
          ],
        },
        {
          pageBreak: 'before',
          columns: state.southernImpressions
            ? await southernImpressionsPage2Top(property)
            : await fixFlipPage2Top(property),
        },
        {
          stack: state.southernImpressions
            ? await southernImpressionsPage2Bottom(property)
            : await fixFlipPage2Bottom(property),
        },
        state.southernImpressions
          ? await southernImpressionsPage3(property)
          : null,
        proformaImagePage ? await imagePages(property) : null,
      ],
    }
    return docDefinition
  }

  const makeMarketingPDF = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      proformaImagePage = true,
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    const { legalDescription = '' } = organization
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    proformaDescription = proformaDescription
      ? trim(proformaDescription)
      : description

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    // const mainImage = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )
    // const subImageOne = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )
    // const subImageTwo = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )
    // const mapImage = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )

    let mainImage = ''
    let subImageOne = ''
    let subImageTwo = ''
    let mapImage = ''

    if (proformaImages.length <= 0)
      switch (images.length) {
        case 0: {
          mainImage = await handleImageUrl(
            '/images/default_image.png',
          )
          subImageOne = await handleImageUrl(
            '/images/default_image.png',
          )
          subImageTwo = await handleImageUrl(
            '/images/default_image.png',
          )
          mapImage = await handleImageUrl('/images/default_image.png')
          break
        }
        case 1: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = whiteImage
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 2: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 3: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = await handleImageUrl(images[2].url)
          mapImage = whiteImage
          break
        }
        default: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = await handleImageUrl(images[2].url)
          mapImage = await handleImageUrl(images[3].url)
          break
        }
      }
    else
      switch (proformaImages.length) {
        case 1: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = whiteImage
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 2: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 3: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = await handleImageUrl(proformaImages[2])
          mapImage = whiteImage
          break
        }
        default: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = await handleImageUrl(proformaImages[2])
          mapImage = await handleImageUrl(proformaImages[3])
          break
        }
      }

    const IRR = acquisition.analytics.turnkey.IRRTurnkey
      ? (acquisition.analytics.turnkey.IRRTurnkey * 100).toFixed(2)
      : 0
    const CAPRate = acquisition.analytics.turnkey.capRateTurnkey
      ? (acquisition.analytics.turnkey.capRateTurnkey * 100).toFixed(
          2,
        )
      : 0
    const cashFlow = acquisition.analytics.turnkey.cashFlowTurnkey
      ? acquisition.analytics.turnkey.cashFlowTurnkey
      : 0
    //const netROI = acquisition.analytics.turnkey.netROITurnkey ? (acquisition.analytics.turnkey.netROITurnkey * 100).toFixed(2) : 0
    const cashOnCash = acquisition.analytics.turnkey
      .cashOnCashReturnTurnkey
      ? (
          acquisition.analytics.turnkey.cashOnCashReturnTurnkey * 100
        ).toFixed(2)
      : 0
    const equity = acquisition.analytics.turnkey.equityTurnkey
      ? acquisition.analytics.turnkey.equityTurnkey
      : 0
    const mortgagePayment = acquisition.analytics.turnkey
      .mortgageTurnkey
      ? acquisition.analytics.turnkey.mortgageTurnkey
      : 0
    const financeAmount = acquisition.analytics.turnkey
      .loanAmountTurnkey
      ? acquisition.analytics.turnkey.loanAmountTurnkey
      : 0
    const netOperatingIncome = acquisition.analytics.turnkey
      .netOperatingIncomeTurnkey
      ? acquisition.analytics.turnkey.netOperatingIncomeTurnkey
      : 0
    //const allInCost = acquisition.analytics.turnkey.allInCostTurnkey ? acquisition.analytics.turnkey.allInCostTurnkey : 0
    const downPaymentTotal = acquisition.analytics.turnkey
      .downPaymentTotalTurnkey
      ? acquisition.analytics.turnkey.downPaymentTotalTurnkey
      : 0
    //const grossAnnualRent = acquisition.analytics.grossAnnualRent ? acquisition.analytics.grossAnnualRent : 0
    const pointsAmount = acquisition.analytics.turnkey
      .pointsAmountTurnkey
      ? acquisition.analytics.turnkey.pointsAmountTurnkey
      : 0
    const closeCostDollar = acquisition.analytics.turnkey
      .closeCostDollarTurnkey
      ? acquisition.analytics.turnkey.closeCostDollarTurnkey
      : 0
    const closeCostPercent = acquisition.analytics.turnkey
      .closeCostPercentTurnkey
      ? acquisition.analytics.turnkey.closeCostPercentTurnkey
      : 0
    const paydownMonthly = acquisition.analytics.turnkey
      .paydownMonthlyTurnkey
      ? acquisition.analytics.turnkey.paydownMonthlyTurnkey
      : 0
    const acquisitionCommissionDollar = acquisition.analytics
      .acquisitionCommissionDollarTurnkey
      ? acquisition.analytics.acquisitionCommissionDollarTurnkey
      : 0
    const acquisitionCommissionPercent = acquisition.analytics
      .acquisitionCommissionPercentTurnkey
      ? acquisition.analytics.acquisitionCommissionPercentTurnkey
      : 0
    const propertyManagementDollar = acquisition.analytics.turnkey
      .propertyManagementDollarTurnkey
      ? acquisition.analytics.turnkey.propertyManagementDollarTurnkey
      : 0
    const utilities = acquisition.analytics.turnkey
      .utilitiesTypeDollarTurnkey
      ? acquisition.analytics.turnkey.utilitiesTypeDollarTurnkey
      : 0
    const utilitiesPercent = assumptions.utilitiesTurnkey
      ? assumptions.utilitiesTurnkey
      : 0
    const financingFeeTotal = acquisition.analytics.turnkey
      .financingFeeTotalTurnkey
      ? acquisition.analytics.turnkey.financingFeeTotalTurnkey
      : 0
    const assetManagementFeeTotal = acquisition.analytics.turnkey
      .assetManagementFeeTotalTurnkey
      ? acquisition.analytics.turnkey.assetManagementFeeTotalTurnkey
      : 0
    const homeInsurance = acquisition.analytics.turnkey
      .propertyInsuranceDollarTurnkey
      ? acquisition.analytics.turnkey.propertyInsuranceDollarTurnkey
      : 0
    const maintenanceCost = acquisition.analytics.turnkey
      .maintenanceCostTurnkey
      ? acquisition.analytics.turnkey.maintenanceCostTurnkey
      : 0

    const rentAmount =
      assumptions.rentType === 'rentRange'
        ? acquisition.analysis.rentLow &&
          acquisition.analysis.rentHigh
          ? map(
              zip(
                acquisition.analysis.rentLow,
                acquisition.analysis.rentHigh,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rent
          ? acquisition.analysis.rent
          : [0]
    const otherIncome = acquisition.analysis.otherIncome
      ? acquisition.analysis.otherIncome
      : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.turnkey.hoaTotal
      ? acquisition.analytics.turnkey.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.turnkey
      .arvTotalTurnkey
      ? acquisition.analytics.turnkey.arvTotalTurnkey
      : 0
    const offerPrice = acquisition.analysis.resellPriceTurnkey
      ? acquisition.analysis.resellPriceTurnkey
      : afterRepairValue
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentageTurnkey
      ? acquisition.analysis.financingRepairsPercentageTurnkey
      : 0
    // const rehabCost = acquisition.analysis.endRehabCost
    //   ? acquisition.analysis.endRehabCost
    //   : 0
    const rehabCost = acquisition.analytics.turnkey.corTotalTurnkey
      ? acquisition.analytics.turnkey.corTotalTurnkey
      : 0

    const downPayment = assumptions.downPaymentTurnkey
      ? assumptions.downPaymentTurnkey
      : 0
    const rate = assumptions.rateTurnkey ? assumptions.rateTurnkey : 0
    const term = assumptions.termTurnkey ? assumptions.termTurnkey : 0
    const termType = assumptions.termTurnkeyType
      ? assumptions.termTurnkeyType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceTurnkey
      ? assumptions.maintenanceTurnkey
      : 0
    const propertyMgmtPercent = acquisition.analytics.turnkey
      .propertyManagementPercentageTurnkey
      ? acquisition.analytics.turnkey
          .propertyManagementPercentageTurnkey
      : 0
    const pointPercentage = assumptions.pointsTurnkey
      ? assumptions.pointsTurnkey
      : 0
    const homePriceAppreciation = assumptions.homePriceAppreciation
      ? assumptions.homePriceAppreciation
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const financingFee = acquisition.analytics.turnkey
      .financingFeePercentTurnkey
      ? acquisition.analytics.turnkey.financingFeePercentTurnkey
      : 0
    const assetManagementFee = assumptions.assetManagementFee
      ? assumptions.assetManagementFee
      : 0
    const otherExpenses = assumptions.otherExpenseTurnkey
      ? assumptions.otherExpenseTurnkey
      : 0
    const ownerReserves = assumptions.ownerReservesTurnkey
      ? assumptions.ownerReservesTurnkey
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    //const holdingPeriod = assumptions.holdingPeriod ? assumptions.holdingPeriod : 0

    //const cashMortgagePayment = 0
    //const percentOfARV = (allInCost/afterRepairValue) * 100

    const monthlyRate = rate / 12 / 100
    const financedRenovationAmount =
      rehabCost - rehabCost * (downPayment / 100) || 0
    const renovationAmount = rehabCost || 0

    const graphTest = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: termType === 'Years' ? term * 12 : term,
      paydownMonthly,
      holdingPeriod: false,
    })
    const appreciation = appreciationSection({
      afterRepairValue,
      homePriceAppreciation,
      term: termType === 'Years' ? term * 12 : term,
    })

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      financingFeeTotal +
      pointsAmount +
      renovationAmount * (1 - financingRepairsPercentage / 100) +
      customAcquisitionExpenseTotal
    const totalInitialInvestmentNoFinancing =
      offerPrice +
      renovationAmount +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      pointsAmount +
      financingFeeTotal +
      ownerReserves +
      customAcquisitionExpenseTotal
    //const totalROIFinancing = (cashFlow / totalInitialInvestmentFinancing) * 100
    //const totalROINoFinancing = (netOperatingIncome / totalInitialInvestmentNoFinancing) * 100
    const cashFlowNoFinancing = netOperatingIncome
    const yearlyHOA = monthlyHOA * 12
    //let revenueYear = 0
    //let expenseType = ''
    const rentalIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncomeMonthly = sum(otherIncome)
    const otherIncome1 = sum(otherIncome) * 12
    const otherIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const otherIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: otherIncome,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const vacancyRate1 =
      sum(
        map(
          zip(rentAmount, vacancy),
          ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
        ),
      ) * 12
    const vacancyRate3 = sum(
      map(
        zip(rentalIncome3, vacancy),
        ([rentalIncome3, vacancy]) => rentalIncome3 * (vacancy / 100),
      ),
    )
    const vacancyRate5 = sum(
      map(
        zip(rentalIncome5, vacancy),
        ([rentalIncome5, vacancy]) => rentalIncome5 * (vacancy / 100),
      ),
    )
    const vacancyRate10 = sum(
      map(
        zip(rentalIncome10, vacancy),
        ([rentalIncome10, vacancy]) =>
          rentalIncome10 * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly
    const grossRentalIncome1 = sum(rentAmount) * 12 - vacancyRate1
    const grossRentalIncome3 = sum(rentalIncome3) - vacancyRate3
    const grossRentalIncome5 = sum(rentalIncome5) - vacancyRate5
    const grossRentalIncome10 = sum(rentalIncome10) - vacancyRate10
    const monthlyRevenue =
      grossRentalIncomeMonthly + otherIncomeMonthly
    const revenueTotal1 = grossRentalIncome1 + otherIncome1
    const revenueTotal3 = grossRentalIncome3 + sum(otherIncome3)
    const revenueTotal5 = grossRentalIncome5 + sum(otherIncome5)
    const revenueTotal10 = grossRentalIncome10 + sum(otherIncome10)
    const propertyTaxes3 = expensesSection({
      expenseType: 'PT',
      revenueYear: 3,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes5 = expensesSection({
      expenseType: 'PT',
      revenueYear: 5,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes10 = expensesSection({
      expenseType: 'PT',
      revenueYear: 10,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance3 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 3,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance5 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 5,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance10 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 10,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA3 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 3,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA5 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 5,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA10 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 10,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyManagement1 = propertyManagementDollar
    const propertyManagement3 =
      assumptions.propertyManagementTurnkeyType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome3) * (propertyMgmtPercent / 100)
    const propertyManagement5 =
      assumptions.propertyManagementTurnkeyType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome5) * (propertyMgmtPercent / 100)
    const propertyManagement10 =
      assumptions.propertyManagementTurnkeyType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome10) * (propertyMgmtPercent / 100)
    const maintenance1 = maintenanceCost
    const maintenance3 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance5 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance10 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const utilities1 = utilities * 12
    const utilities3 =
      assumptions.utilitiesTurnkeyType === '$'
        ? utilities * 12
        : sum(rentalIncome3) * (utilitiesPercent / 100)
    const utilities5 =
      assumptions.utilitiesTurnkeyType === '$'
        ? utilities * 12
        : sum(rentalIncome5) * (utilitiesPercent / 100)
    const utilities10 =
      assumptions.utilitiesTurnkeyType === '$'
        ? utilities * 12
        : sum(rentalIncome10) * (utilitiesPercent / 100)
    const assetFee1 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 1,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee3 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee5 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee10 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const otherExpense3 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense5 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense10 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal3 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal5 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal10 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const totalExpense1 =
      propertyTaxes +
      homeInsurance +
      yearlyHOA +
      propertyManagement1 +
      maintenance1 +
      utilities1 +
      assetFee1 +
      otherExpenses +
      customAnnualExpenseTotal
    const totalExpense3 =
      propertyTaxes3 +
      homeInsurance3 +
      HOA3 +
      propertyManagement3 +
      maintenance3 +
      utilities3 +
      assetFee3 +
      otherExpense3 +
      customAnnualExpenseTotal3
    const totalExpense5 =
      propertyTaxes5 +
      homeInsurance5 +
      HOA5 +
      propertyManagement5 +
      maintenance5 +
      utilities5 +
      assetFee5 +
      otherExpense5 +
      customAnnualExpenseTotal5
    const totalExpense10 =
      propertyTaxes10 +
      homeInsurance10 +
      HOA10 +
      propertyManagement10 +
      maintenance10 +
      utilities10 +
      assetFee10 +
      otherExpense10 +
      customAnnualExpenseTotal10
    //const expenseRatioMonthly = (totalExpenseMonthly / grossRentalIncomeMonthly) * 100
    const expenseRatio1 = (totalExpense1 / grossRentalIncome1) * 100
    const expenseRatio3 = (totalExpense3 / grossRentalIncome3) * 100
    const expenseRatio5 = (totalExpense5 / grossRentalIncome5) * 100
    const expenseRatio10 =
      (totalExpense10 / grossRentalIncome10) * 100
    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOINoFinance1 = grossRentalIncome1 - totalExpense1
    const NOINoFinance3 = grossRentalIncome3 - totalExpense3
    const NOINoFinance5 = grossRentalIncome5 - totalExpense5
    const NOINoFinance10 = grossRentalIncome10 - totalExpense10
    const NOIFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOIFinance1 = grossRentalIncome1 - totalExpense1
    const NOIFinance3 = grossRentalIncome3 - totalExpense3
    const NOIFinance5 = grossRentalIncome5 - totalExpense5
    const NOIFinance10 = grossRentalIncome10 - totalExpense10
    const mortgagePaymentYearly = mortgagePayment * 12
    const totalReturnFinancingMonthly =
      NOINoFinanceMonthly - mortgagePayment
    const totalReturnFinancing = []
    const totalReturnFinancing1 =
      NOINoFinance1 - mortgagePaymentYearly
    const totalReturnFinancing3 =
      NOINoFinance3 - mortgagePaymentYearly
    const totalReturnFinancing5 =
      NOINoFinance5 - mortgagePaymentYearly
    const totalReturnFinancing10 =
      NOINoFinance10 - mortgagePaymentYearly
    totalReturnFinancing.push({
      one: totalReturnFinancing1,
      three: totalReturnFinancing3,
      five: totalReturnFinancing5,
      ten: totalReturnFinancing10,
    })
    //const totalROIFinancingMonthly = ((NOINoFinanceMonthly -  mortgagePayment)/(downPaymentTotal + ((closeCostPercent/100) * financeAmount))) * 100
    const totalROIFinancing1 =
      (cashFlow / totalInitialInvestmentFinancing) * 100
    const totalReturnNoFinancingMonthly = NOINoFinanceMonthly
    const totalReturnNoFinancing1 = NOINoFinance1
    const totalReturnNoFinancing3 = NOINoFinance3
    const totalReturnNoFinancing5 = NOINoFinance5
    const totalReturnNoFinancing10 = NOINoFinance10
    //const totalROINoFinancingMonthly = ((NOINoFinanceMonthly -  mortgagePayment)/(downPaymentTotal + ((closeCostPercent/100) * financeAmount))) * 100
    const totalROINoFinancing1 =
      (totalReturnNoFinancing1 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing3 =
      (totalReturnNoFinancing3 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing5 =
      (totalReturnNoFinancing5 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing10 =
      (totalReturnNoFinancing10 / totalInitialInvestmentNoFinancing) *
      100

    const cashFlowAccumulation = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: termType === 'Years' ? term * 12 + 1 : term + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })
    const graphDataURL = getChartData(
      property,
      graphTest,
      totalReturnFinancing,
      appreciation,
      cashFlowAccumulation,
      totalInitialInvestmentFinancing,
    )

    //const startingOffer = acquisition.analysis.startingOffer ? acquisition.analysis.startingOffer : acquisition.analysis.listPrice

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    // analysis && '$' + analysis.startingOffer
    //   ? `$ ${analysis.startingOffer}`
    //   : analysis && analysis.offerPrice
    //   ? `$ ${analysis.offerPrice}`
    //   : listPrice

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'
    // Top Images
    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [0, 0, 0, 0],
      info: {
        title: property.proformaHideAddress
          ? `${startCase(property.city)}-${startCase(
              property.state,
            )}-${property.zip}-marketing`
          : `${property.address}-${startCase(
              property.city,
            )}-${startCase(property.state)}-${
              property.zip
            }-marketing`,
      },
      content: [
        {
          image: mainImage,
          // fit: [600, 300],
          width: 600,
          height: 300,
          margin: [0, 0, 0, 5],
          alignment: 'center',
        },
        state.southernImpressions
          ? {
              absolutePosition: { x: 0, y: 0 },
              canvas: [
                {
                  type: 'rect',
                  x: 0,
                  y: 0,
                  w: 300,
                  h: 50,
                  r: 0,
                  color: '#368CFF',
                  fillOpacity: 0.5,
                },
              ],
            }
          : null,
        state.southernImpressions
          ? {
              text:
                startCase(property.city) +
                ' ' +
                startCase(property.state),
              color: '#ffffff',
              fontSize: 24,
              absolutePosition: { x: 20, y: 10 },
              margin: [85, 0, 0, 0],
            }
          : null,
        !state.southernImpressions
          ? {
              absolutePosition: { x: 150, y: 200 },
              canvas: [
                {
                  type: 'rect',
                  x: 0,
                  y: 0,
                  w: 300,
                  h: 100,
                  r: 2,
                  color: 'black',
                  fillOpacity: 0.3,
                },
              ],
            }
          : null,
        !state.southernImpressions
          ? {
              absolutePosition: { x: 0, y: 210 },
              stack: [
                {
                  text: currencyFormater.format(offerPrice),
                  fontSize: 28,
                  color: 'white',
                  bold: true,
                  alignment: 'center',
                },
                property.proformaHideAddress
                  ? {
                      text: '',
                      fontSize: 16,
                      color: 'white',
                      alignment: 'center',
                      margin: [0, 10, 0, 0],
                    }
                  : {
                      text: property.address,
                      fontSize: 16,
                      color: 'white',
                      alignment: 'center',
                    },
                {
                  text:
                    startCase(property.city) +
                    ' ' +
                    startCase(property.state) +
                    ', ' +
                    property.zip,
                  fontSize: 16,
                  color: 'white',
                  alignment: 'center',
                },
              ],
            }
          : null,
        {
          columns: [
            {
              width: 215,
              stack: [
                {
                  image: subImageOne,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
                {
                  image: subImageTwo,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
                {
                  image: mapImage,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
              ],
            },
            state.southernImpressions
              ? await southernImpressionsMarketingPage1LowerRight(
                  property,
                )
              : await defaultMarketingPage1LowerRight(property),
          ],
        },
        state.southernImpressions
          ? await southernImpressionsMarketingFloorPlanPage(property)
          : null,
        state.southernImpressions
          ? await southernImpressionsMarketingPageHighlights(property)
          : [
              {
                pageBreak: 'before',
                columns: [
                  {
                    width: 400,
                    stack: [
                      {
                        text: [
                          'Financial ',
                          { text: 'Projections', color: '#3399ff' },
                        ],
                        fontSize: 18,
                        margin: [15, 20, 0, 0],
                      },
                      {
                        canvas: [
                          {
                            type: 'line',
                            x1: 15,
                            y1: 5,
                            x2: 150,
                            y2: 5,
                            lineWidth: 2,
                          },
                        ],
                      },
                      {
                        image: graphDataURL,
                        width: 400,
                        height: 275,
                        margin: [10, 20, 0, 0],
                      },
                    ],
                  },
                  [
                    {
                      margin: [20, 30],
                      stack: [
                        {
                          table: {
                            headerRows: 1,
                            widths: ['*', '*'],
                            body: [
                              [
                                {
                                  text: 'Financing Assumptions',
                                  fillColor: '#3399ff',
                                  color: 'white',
                                  fontSize: 8,
                                  colSpan: 2,
                                  alignment: 'center',
                                },
                                { text: '' },
                              ],
                              [
                                {
                                  text: 'Loan Amount',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    financeAmount,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text: 'Financed Rehab Amount',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    (financedRenovationAmount *
                                      financingRepairsPercentage) /
                                      100,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text: 'Interest Rate',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: rate + '%',
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text: 'Mortgage (yrs)',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: (termType === 'Years'
                                    ? term
                                    : term / 12
                                  ).toFixed(1),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text: 'Mortgage Payment',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    mortgagePayment,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text:
                                    'Paydown Acc. (' +
                                    payDownAccelerator +
                                    '%)',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    paydownMonthly,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                            ],
                          },
                          layout: {
                            defaultBorder: false,
                          },
                        },
                        {
                          table: {
                            headerRows: 1,
                            widths: ['*', '*'],
                            body: [
                              [
                                {
                                  text: 'Intial Investment',
                                  fillColor: '#3399ff',
                                  color: 'white',
                                  fontSize: 8,
                                  colSpan: 2,
                                  alignment: 'center',
                                },
                                { text: '' },
                              ],
                              [
                                {
                                  text:
                                    'Down Payment (' +
                                    downPayment +
                                    '%)',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    downPaymentTotal,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text: 'Rehab Amount',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    renovationAmount *
                                      (1 -
                                        financingRepairsPercentage /
                                          100),
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text: 'Acquisition Fee',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    acquisitionFee,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text:
                                    'Acquisition Commission (' +
                                    acquisitionCommissionPercent +
                                    '%)',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    acquisitionCommissionDollar,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text:
                                    'Est Closing Cost (' +
                                    closeCostPercent +
                                    '%)',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    closeCostDollar,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text:
                                    'Financing Fee (' +
                                    financingFee +
                                    '%)',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    financingFeeTotal,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text: 'Custom Expenses',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    customAcquisitionExpenseTotal,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text:
                                    'Points (' +
                                    pointPercentage +
                                    '%)',
                                  alignment: 'left',
                                  fontSize: 6,
                                  border: [false, false, false, true],
                                },
                                {
                                  text: currencyFormater.format(
                                    pointsAmount,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                  border: [false, false, false, true],
                                },
                              ],
                              [
                                {
                                  text: 'Total Out Of Pocket',
                                  alignment: 'left',
                                  fontSize: 6,
                                  bold: true,
                                },
                                {
                                  text: currencyFormater.format(
                                    totalInitialInvestmentFinancing,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                  bold: true,
                                },
                              ],
                            ],
                          },
                          layout: {
                            defaultBorder: false,
                          },
                          margin: [0, 10, 0, 0],
                        },
                        {
                          table: {
                            headerRows: 1,
                            widths: ['*', '*'],
                            body: [
                              [
                                {
                                  text: 'Property KPI Without Financing',
                                  fillColor: '#3399ff',
                                  color: 'white',
                                  fontSize: 8,
                                  colSpan: 2,
                                  alignment: 'center',
                                },
                                { text: '' },
                              ],
                              [
                                {
                                  text: 'Annual Cash Flow',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    cashFlowNoFinancing,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text: 'All In Cost',
                                  alignment: 'left',
                                  fontSize: 6,
                                  border: [false, false, false, true],
                                },
                                {
                                  text: currencyFormater.format(
                                    totalInitialInvestmentNoFinancing,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                  border: [false, false, false, true],
                                },
                              ],
                              [
                                {
                                  text: 'Cap Rate',
                                  alignment: 'left',
                                  fontSize: 6,
                                  bold: true,
                                },
                                {
                                  text:
                                    totalROINoFinancing1.toFixed(2) +
                                    '%',
                                  alignment: 'right',
                                  fontSize: 6,
                                  bold: true,
                                },
                              ],
                            ],
                          },
                          layout: {
                            defaultBorder: false,
                          },
                          margin: [0, 10, 0, 0],
                        },
                        {
                          table: {
                            headerRows: 1,
                            widths: ['*', '*'],
                            body: [
                              [
                                {
                                  text: 'Property KPI With Financing',
                                  fillColor: '#3399ff',
                                  color: 'white',
                                  fontSize: 8,
                                  colSpan: 2,
                                  alignment: 'center',
                                },
                                { text: '' },
                              ],
                              [
                                {
                                  text: 'Annual Cash Flow',
                                  alignment: 'left',
                                  fontSize: 6,
                                },
                                {
                                  text: currencyFormater.format(
                                    cashFlow,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                },
                              ],
                              [
                                {
                                  text: 'Total Out Of Pocket',
                                  alignment: 'left',
                                  fontSize: 6,
                                  border: [false, false, false, true],
                                },
                                {
                                  text: currencyFormater.format(
                                    totalInitialInvestmentFinancing,
                                  ),
                                  alignment: 'right',
                                  fontSize: 6,
                                  border: [false, false, false, true],
                                },
                              ],
                              [
                                {
                                  text: 'Cash On Cash',
                                  alignment: 'left',
                                  fontSize: 6,
                                  bold: true,
                                },
                                {
                                  text:
                                    totalROIFinancing1.toFixed(2) +
                                    '%',
                                  alignment: 'right',
                                  fontSize: 6,
                                  bold: true,
                                },
                              ],
                            ],
                          },
                          layout: {
                            defaultBorder: false,
                          },
                          margin: [0, 10, 0, 0],
                        },
                      ],
                    },
                  ],
                ],
              },
              {
                stack: await defaultMarketingPage2Bottom(property),
              },
            ],
        state.southernImpressions
          ? await southernImpressionsMarketingHedgeAgainstInflationPage(
              property,
            )
          : null,
        proformaImagePage ? await imagePages(property) : null,
      ],
    }
    return docDefinition
  }

  const makeShortTermRentalPDF = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      proformaImagePage = true,
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    const { legalDescription = '' } = organization
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    // const mainImage = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )
    // const subImageOne = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )
    // const subImageTwo = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )
    // const mapImage = await handleImageUrl(
    //   'https://housefolios-platform.s3.amazonaws.com/compImages/190712110700-is1qcyyyb6qzzxf.jpg',
    // )

    let mainImage = ''
    let subImageOne = ''
    let subImageTwo = ''
    let mapImage = ''

    if (proformaImages.length <= 0)
      switch (images.length) {
        case 0: {
          mainImage = await handleImageUrl(
            '/images/default_image.png',
          )
          subImageOne = await handleImageUrl(
            '/images/default_image.png',
          )
          subImageTwo = await handleImageUrl(
            '/images/default_image.png',
          )
          mapImage = await handleImageUrl('/images/default_image.png')
          break
        }
        case 1: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = whiteImage
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 2: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 3: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = await handleImageUrl(images[2].url)
          mapImage = whiteImage
          break
        }
        default: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = await handleImageUrl(images[2].url)
          mapImage = await handleImageUrl(images[3].url)
          break
        }
      }
    else
      switch (proformaImages.length) {
        case 1: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = whiteImage
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 2: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 3: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = await handleImageUrl(proformaImages[2])
          mapImage = whiteImage
          break
        }
        default: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = await handleImageUrl(proformaImages[2])
          mapImage = await handleImageUrl(proformaImages[3])
          break
        }
      }

    const mortgagePayment = acquisition.analytics.STR.mortgageSTR
      ? acquisition.analytics.STR.mortgageSTR
      : 0
    const financeAmount = acquisition.analytics.STR.loanAmountSTR
      ? acquisition.analytics.STR.loanAmountSTR
      : 0
    const allInCost = acquisition.analytics.STR.allInCostSTR
      ? acquisition.analytics.STR.allInCostSTR
      : 0
    const downPaymentTotal = acquisition.analytics.STR
      .downPaymentTotalSTR
      ? acquisition.analytics.STR.downPaymentTotalSTR
      : 0
    const pointsAmount = acquisition.analytics.STR.pointsAmountSTR
      ? acquisition.analytics.STR.pointsAmountSTR
      : 0
    const creditFromSeller = assumptions.creditFromSeller
      ? assumptions.creditFromSeller
      : 0
    const closeCostDollar = acquisition.analytics.STR
      .closeCostDollarSTR
      ? acquisition.analytics.STR.closeCostDollarSTR
      : 0
    const closeCostPercent = acquisition.analytics.STR
      .closeCostPercentSTR
      ? acquisition.analytics.STR.closeCostPercentSTR
      : 0
    const paydownMonthly = acquisition.analytics.STR.paydownMonthlySTR
      ? acquisition.analytics.STR.paydownMonthlySTR
      : 0
    const acquisitionCommissionDollar = acquisition.analytics.STR
      .acquisitionCommissionDollar
      ? acquisition.analytics.STR.acquisitionCommissionDollar
      : 0
    const acquisitionCommissionPercent = acquisition.analytics.STR
      .acquisitionCommissionPercent
      ? acquisition.analytics.STR.acquisitionCommissionPercent
      : 0
    const propertyManagementDollar = acquisition.analytics.STR
      .propertyManagementDollarSTR
      ? acquisition.analytics.STR.propertyManagementDollarSTR
      : 0
    const utilities = acquisition.analytics.STR.utilitiesTypeDollarSTR
      ? acquisition.analytics.STR.utilitiesTypeDollarSTR
      : 0
    const utilitiesPercent = assumptions.utilitiesSTR
      ? assumptions.utilitiesSTR
      : 0
    const financingFeeTotal = acquisition.analytics.STR
      .financingFeeTotalSTR
      ? acquisition.analytics.STR.financingFeeTotalSTR
      : 0
    const sellingClosingCost = acquisition.analytics.STR
      .sellingClosingCostDollarSTR
      ? acquisition.analytics.STR.sellingClosingCostDollarSTR
      : 0
    const sellingClosingCostPercent = acquisition.analytics.STR
      .sellingClosingCostPercentSTR
      ? acquisition.analytics.STR.sellingClosingCostPercentSTR
      : 0
    const dispositionFee = acquisition.analytics.STR
      .dispositionFeeDollarSTR
      ? acquisition.analytics.STR.dispositionFeeDollarSTR
      : 0
    const salesCommission = acquisition.analytics.STR
      .salesCommissionTotalSTR
      ? acquisition.analytics.STR.salesCommissionTotalSTR
      : 0
    const assetManagementFeeTotal = acquisition.analytics.STR
      .assetManagementFeeTotalSTR
      ? acquisition.analytics.STR.assetManagementFeeTotalSTR
      : 0
    const salePrice = acquisition.analysis.resellPriceSTR
      ? acquisition.analysis.resellPriceSTR
      : acquisition.analytics.STR.arvTotalSTR || 0

    const homeInsurance = acquisition.analytics.STR
      .propertyInsuranceDollarSTR
      ? acquisition.analytics.STR.propertyInsuranceDollarSTR
      : 0
    const maintenanceCost = acquisition.analytics.STR
      .maintenanceCostSTR
      ? acquisition.analytics.STR.maintenanceCostSTR
      : 0

    const rentAmount =
      assumptions.rentSTRType === 'rentRange'
        ? acquisition.analysis.rentLowSTR &&
          acquisition.analysis.rentHighSTR
          ? map(
              zip(
                acquisition.analysis.rentLowSTR,
                acquisition.analysis.rentHighSTR,
              ),
              ([low, high]) => (low + high) / 2,
            )
          : [0]
        : acquisition.analysis.rentSTR
          ? acquisition.analysis.rentSTR
          : [0]
    const propertyTaxes = acquisition.analysis.propertyTaxes
      ? acquisition.analysis.propertyTaxes
      : 0
    const monthlyHOA = acquisition.analytics.STR.hoaTotal
      ? acquisition.analytics.STR.hoaTotal / 12
      : 0
    const afterRepairValue = acquisition.analytics.STR.arvTotalSTR
      ? acquisition.analytics.STR.arvTotalSTR
      : 0
    const offerPrice = acquisition.analysis.offerPrice
      ? acquisition.analysis.offerPrice
      : acquisition.analysis.listPrice
    const financingRepairsPercentage = acquisition.analysis
      .financingRepairsPercentageSTR
      ? acquisition.analysis.financingRepairsPercentageSTR
      : false
    // const rehabCost = acquisition.analysis.endRehabCost
    //   ? acquisition.analysis.endRehabCost
    //   : 0
    const rehabCost = acquisition.analytics.STR.corTotalSTR
      ? acquisition.analytics.STR.corTotalSTR
      : 0
    // const sellPrice = acquisition.analysis.sellPrice
    //   ? acquisition.analysis.afterRepairValue
    //   : 0
    const downPayment = assumptions.downPaymentSTR
      ? assumptions.downPaymentSTR
      : 0
    const rate = assumptions.rateSTR ? assumptions.rateSTR : 0
    const term = assumptions.termSTR ? assumptions.termSTR : 0
    const termType = assumptions.termSTRType
      ? assumptions.termSTRType
      : 'Months'
    const vacancy = assumptions.vacancy ? assumptions.vacancy : 0
    const maintenance = assumptions.maintenanceSTR
      ? assumptions.maintenanceSTR
      : 0
    const propertyMgmtPercent = acquisition.analytics.STR
      .propertyManagementPercentageSTR
      ? acquisition.analytics.STR.propertyManagementPercentageSTR
      : 0
    const pointPercentage = assumptions.pointsSTR
      ? assumptions.pointsSTR
      : 0
    const homePriceAppreciation = assumptions.homePriceAppreciation
      ? assumptions.homePriceAppreciation
      : 0
    const payDownAccelerator = assumptions.payDownAccelerator
      ? assumptions.payDownAccelerator
      : 0
    const annualRevenueIncrease = assumptions.growthRateIncome
      ? assumptions.growthRateIncome
      : 0
    const annualOperatingExpenseIncrease =
      assumptions.growthRateExpense
        ? assumptions.growthRateExpense
        : 0
    const acquisitionFee = assumptions.acquisitionFee
      ? assumptions.acquisitionFee
      : 0
    const holdingPeriod = assumptions.rentalHoldingPeriodSTR
      ? assumptions.rentalHoldingPeriodSTR
      : 0
    // const financingFee = assumptions.financingFeeSTR
    //   ? assumptions.financingFeeSTR
    //   : 0
    const salesCommissionPercent = assumptions.salesCommissionSTR
      ? assumptions.salesCommissionSTR
      : 0
    const assetManagementFee = assumptions.assetManagementFee
      ? assumptions.assetManagementFee
      : 0
    // const utilities = assumptions.utilities
    //   ? assumptions.utilities
    //   : 0

    const otherExpenses = assumptions.otherExpenseSTR
      ? assumptions.otherExpenseSTR
      : 0
    const ownerReserves = assumptions.ownerReservesSTR
      ? assumptions.ownerReservesSTR
      : 0

    const customAcquisitionExpenseTotal = acquisition.analytics
      .customAcquisitionExpenseTotal
      ? acquisition.analytics.customAcquisitionExpenseTotal
      : 0
    const customAnnualExpenseTotal = acquisition.analytics
      .customMonthlyExpenseTotal
      ? acquisition.analytics.customMonthlyExpenseTotal * 12
      : 0

    //const cashMortgagePayment = 0
    const percentOfARV = (allInCost / afterRepairValue) * 100

    const monthlyRate = rate / 12 / 100
    const financedRenovationAmount =
      rehabCost - rehabCost * (downPayment / 100) || 0
    const renovationAmount = rehabCost || 0

    const graphTest = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: termType === 'Years' ? term * 12 : term,
      paydownMonthly,
      //holdingPeriod,
      holdingPeriod: false,
    })
    const appreciation = appreciationSection({
      afterRepairValue,
      homePriceAppreciation,
      term: termType === 'Years' ? term * 12 : term,
    })
    const loanBalanceAtHoldingPeriod = graphSection({
      financeAmount,
      mortgagePayment,
      monthlyRate,
      debtYear: holdingPeriod,
      paydownMonthly,
      holdingPeriod: true,
    })

    const totalInitialInvestmentFinancing =
      downPaymentTotal +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      financingFeeTotal +
      pointsAmount -
      creditFromSeller +
      renovationAmount * (1 - financingRepairsPercentage / 100) +
      customAcquisitionExpenseTotal
    const totalInitialInvestmentNoFinancing =
      offerPrice +
      renovationAmount +
      acquisitionCommissionDollar +
      acquisitionFee +
      closeCostDollar +
      pointsAmount +
      financingFeeTotal +
      ownerReserves +
      customAcquisitionExpenseTotal
    //const totalROIFinancing = (cashFlow / totalInitialInvestmentFinancing) * 100
    //const totalROINoFinancing = (netOperatingIncome / totalInitialInvestmentNoFinancing) * 100
    //const cashFlowNoFinancing = netOperatingIncome
    const yearlyHOA = monthlyHOA * 12
    //let revenueYear = 0
    //let expenseType = ''
    const rentalIncome3 = revenuesSection({
      revenueYear: 3,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome5 = revenuesSection({
      revenueYear: 5,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const rentalIncome10 = revenuesSection({
      revenueYear: 10,
      rentAmount: rentAmount,
      annualRevenueIncrease: annualRevenueIncrease / 100,
    })
    const vacancyRateMonthly = sum(
      map(
        zip(rentAmount, vacancy),
        ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
      ),
    )
    const vacancyRate1 =
      sum(
        map(
          zip(rentAmount, vacancy),
          ([rentAmount, vacancy]) => rentAmount * (vacancy / 100),
        ),
      ) * 12
    const vacancyRate3 = sum(
      map(
        zip(rentalIncome3, vacancy),
        ([rentalIncome3, vacancy]) => rentalIncome3 * (vacancy / 100),
      ),
    )
    const vacancyRate5 = sum(
      map(
        zip(rentalIncome5, vacancy),
        ([rentalIncome5, vacancy]) => rentalIncome5 * (vacancy / 100),
      ),
    )
    const vacancyRate10 = sum(
      map(
        zip(rentalIncome10, vacancy),
        ([rentalIncome10, vacancy]) =>
          rentalIncome10 * (vacancy / 100),
      ),
    )
    const grossRentalIncomeMonthly =
      sum(rentAmount) - vacancyRateMonthly
    const grossRentalIncome1 = sum(rentAmount) * 12 - vacancyRate1
    const grossRentalIncome3 = sum(rentalIncome3) - vacancyRate3
    const grossRentalIncome5 = sum(rentalIncome5) - vacancyRate5
    const grossRentalIncome10 = sum(rentalIncome10) - vacancyRate10
    const propertyTaxes3 = expensesSection({
      expenseType: 'PT',
      revenueYear: 3,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes5 = expensesSection({
      expenseType: 'PT',
      revenueYear: 5,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyTaxes10 = expensesSection({
      expenseType: 'PT',
      revenueYear: 10,
      propertyTaxes,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance3 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 3,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance5 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 5,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const homeInsurance10 = expensesSection({
      expenseType: 'INSURANCE',
      revenueYear: 10,
      homeInsurance,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA3 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 3,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA5 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 5,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const HOA10 = expensesSection({
      expenseType: 'HOA',
      revenueYear: 10,
      yearlyHOA,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const propertyManagement1 = propertyManagementDollar
    const propertyManagement3 =
      assumptions.propertyManagementSTRType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome3) * (propertyMgmtPercent / 100)
    const propertyManagement5 =
      assumptions.propertyManagementSTRType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome5) * (propertyMgmtPercent / 100)
    const propertyManagement10 =
      assumptions.propertyManagementSTRType === '$'
        ? propertyManagementDollar
        : sum(rentalIncome10) * (propertyMgmtPercent / 100)
    const maintenance1 = maintenanceCost
    const maintenance3 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance5 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const maintenance10 = expensesSection({
      expenseType: 'MAINTENANCE',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      maintenance: maintenance / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const utilities1 = utilities * 12
    const utilities3 =
      assumptions.utilitiesSTRType === '$'
        ? utilities * 12
        : sum(rentalIncome3) * (utilitiesPercent / 100)
    const utilities5 =
      assumptions.utilitiesSTRType === '$'
        ? utilities * 12
        : sum(rentalIncome5) * (utilitiesPercent / 100)
    const utilities10 =
      assumptions.utilitiesSTRType === '$'
        ? utilities * 12
        : sum(rentalIncome10) * (utilitiesPercent / 100)
    const assetFee1 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 1,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee3 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee5 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const assetFee10 = expensesSection({
      expenseType: 'ASSET',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      assetManagementFeeTotal: assetManagementFeeTotal,
      totalInitialInvestmentFinancing,
    })
    const otherExpense3 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense5 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const otherExpense10 = expensesSection({
      expenseType: 'OTHER',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      otherExpenses,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal3 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 3,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal5 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 5,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const customAnnualExpenseTotal10 = expensesSection({
      expenseType: 'CUSTOM',
      revenueYear: 10,
      rentAmount: grossRentalIncome1,
      customAnnualExpenseTotal,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
    })
    const totalExpenseMonthly =
      propertyTaxes / 12 +
      homeInsurance / 12 +
      monthlyHOA +
      (propertyMgmtPercent / 100) * grossRentalIncomeMonthly +
      (maintenance / 100) * grossRentalIncomeMonthly +
      utilities +
      assetManagementFeeTotal / 12 +
      otherExpenses / 12 +
      customAnnualExpenseTotal / 12
    const totalExpense1 =
      propertyTaxes +
      homeInsurance +
      yearlyHOA +
      propertyManagement1 +
      maintenance1 +
      utilities1 +
      assetFee1 +
      otherExpenses +
      customAnnualExpenseTotal
    const totalExpense3 =
      propertyTaxes3 +
      homeInsurance3 +
      HOA3 +
      propertyManagement3 +
      maintenance3 +
      utilities3 +
      assetFee3 +
      otherExpense3 +
      customAnnualExpenseTotal3
    const totalExpense5 =
      propertyTaxes5 +
      homeInsurance5 +
      HOA5 +
      propertyManagement5 +
      maintenance5 +
      utilities5 +
      assetFee5 +
      otherExpense5 +
      customAnnualExpenseTotal5
    const totalExpense10 =
      propertyTaxes10 +
      homeInsurance10 +
      HOA10 +
      propertyManagement10 +
      maintenance10 +
      utilities10 +
      assetFee10 +
      otherExpense10 +
      customAnnualExpenseTotal10
    const saleProceedsTotal =
      salePrice -
      salesCommission -
      sellingClosingCost -
      dispositionFee -
      totalInitialInvestmentFinancing -
      loanBalanceAtHoldingPeriod
    //const expenseRatioMonthly = (totalExpenseMonthly / grossRentalIncomeMonthly) * 100
    const expenseRatio1 = (totalExpense1 / grossRentalIncome1) * 100
    const expenseRatio3 = (totalExpense3 / grossRentalIncome3) * 100
    const expenseRatio5 = (totalExpense5 / grossRentalIncome5) * 100
    const expenseRatio10 =
      (totalExpense10 / grossRentalIncome10) * 100
    const NOINoFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOINoFinance1 = grossRentalIncome1 - totalExpense1
    const NOINoFinance3 = grossRentalIncome3 - totalExpense3
    const NOINoFinance5 = grossRentalIncome5 - totalExpense5
    const NOINoFinance10 = grossRentalIncome10 - totalExpense10
    const NOIFinanceMonthly =
      grossRentalIncomeMonthly - totalExpenseMonthly
    const NOIFinance1 = grossRentalIncome1 - totalExpense1
    const NOIFinance3 = grossRentalIncome3 - totalExpense3
    const NOIFinance5 = grossRentalIncome5 - totalExpense5
    const NOIFinance10 = grossRentalIncome10 - totalExpense10
    const mortgagePaymentYearly = mortgagePayment * 12
    const totalReturnFinancingMonthly =
      NOINoFinanceMonthly - mortgagePayment
    const totalReturnFinancing = []
    const totalReturnFinancing1 =
      NOINoFinance1 - mortgagePaymentYearly
    const totalReturnFinancing3 =
      NOINoFinance3 - mortgagePaymentYearly
    const totalReturnFinancing5 =
      NOINoFinance5 - mortgagePaymentYearly
    const totalReturnFinancing10 =
      NOINoFinance10 - mortgagePaymentYearly
    totalReturnFinancing.push({
      one: totalReturnFinancing1,
      three: totalReturnFinancing3,
      five: totalReturnFinancing5,
      ten: totalReturnFinancing10,
    })
    //const totalROIFinancingMonthly = ((NOINoFinanceMonthly -  mortgagePayment)/(downPaymentTotal + ((closeCostPercent/100) * financeAmount))) * 100
    const totalROIFinancing1 =
      (totalReturnFinancing1 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing3 =
      (totalReturnFinancing3 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing5 =
      (totalReturnFinancing5 / totalInitialInvestmentFinancing) * 100
    const totalROIFinancing10 =
      (totalReturnFinancing10 / totalInitialInvestmentFinancing) * 100
    const totalReturnNoFinancingMonthly = NOINoFinanceMonthly
    const totalReturnNoFinancing1 = NOINoFinance1
    const totalReturnNoFinancing3 = NOINoFinance3
    const totalReturnNoFinancing5 = NOINoFinance5
    const totalReturnNoFinancing10 = NOINoFinance10
    //const totalROINoFinancingMonthly = ((NOINoFinanceMonthly -  mortgagePayment)/(downPaymentTotal + ((closeCostPercent/100) * financeAmount))) * 100
    const totalROINoFinancing1 =
      (totalReturnNoFinancing1 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing3 =
      (totalReturnNoFinancing3 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing5 =
      (totalReturnNoFinancing5 / totalInitialInvestmentNoFinancing) *
      100
    const totalROINoFinancing10 =
      (totalReturnNoFinancing10 / totalInitialInvestmentNoFinancing) *
      100

    const cashFlowAccumulation = cashFlowAccumulationCalc({
      accelerator: payDownAccelerator,
      term: termType === 'Years' ? term * 12 + 1 : term + 1,
      rentAmount: rentAmount || [0],
      vacancy: vacancy
        ? map(vacancy, (vacancy) => vacancy / 100)
        : [0],
      propertyTaxes: propertyTaxes / 12,
      homeInsurance: homeInsurance / 12,
      monthlyHOA,
      propertyMgmtPercent: propertyMgmtPercent / 100,
      maintenance: maintenance / 100,
      utilities: utilities,
      annualRevenueIncrease: annualRevenueIncrease / 100,
      annualOperatingExpenseIncrease:
        annualOperatingExpenseIncrease / 100,
      mortgagePayment,
    })
    const graphDataURL = getChartData(
      property,
      graphTest,
      totalReturnFinancing,
      appreciation,
      cashFlowAccumulation,
      totalInitialInvestmentFinancing,
    )

    //const startingOffer = acquisition.analysis.startingOffer ? acquisition.analysis.startingOffer : acquisition.analysis.listPrice

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    // analysis && '$' + analysis.startingOffer
    //   ? `$ ${analysis.startingOffer}`
    //   : analysis && analysis.offerPrice
    //   ? `$ ${analysis.offerPrice}`
    //   : listPrice

    const sqftString = sum(sqft)
      ? sum(sqft).toLocaleString('en')
      : '0'

    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [0, 0, 0, 0],
      info: {
        // HIDE ADDRESS SETUP
        // title: property.proformaHideAddress ? `${startCase(property.city)}-${startCase(property.state)}-${property.zip}-finance` : `${property.address}-${startCase(property.city)}-${startCase(property.state)}-${property.zip}-finance`,
        title: `${property.address}-${startCase(
          property.city,
        )}-${startCase(property.state)}-${property.zip}-finance`,
      },
      content: [
        {
          image: mainImage,
          // fit: [600, 300],
          width: 600,
          height: 300,
          margin: [0, 0, 0, 5],
          // alignment: 'center'
        },
        {
          absolutePosition: { x: 150, y: 200 },
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 300,
              h: 100,
              r: 2,
              color: 'black',
              fillOpacity: 0.3,
            },
          ],
        },
        {
          absolutePosition: { x: 0, y: 210 },
          stack: [
            {
              text: currencyFormater.format(offerPrice),
              fontSize: 28,
              color: 'white',
              bold: true,
              alignment: 'center',
            },
            // property.proformaHideAddress ?
            // {
            //   text: '',
            //   fontSize: 16,
            //   color: 'white',
            //   alignment: 'center',
            //   margin: [0, 10, 0, 0],
            // }
            // :
            {
              text: property.address,
              fontSize: 16,
              color: 'white',
              alignment: 'center',
            },
            {
              text:
                startCase(property.city) +
                ' ' +
                startCase(property.state) +
                ', ' +
                property.zip,
              fontSize: 16,
              color: 'white',
              alignment: 'center',
            },
          ],
        },
        {
          columns: [
            {
              width: 215,
              stack: [
                {
                  image: subImageOne,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
                {
                  image: subImageTwo,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
                {
                  image: mapImage,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
              ],
            },
            state.southernImpressions
              ? await southernImpressionsPage1LowerRight(property)
              : await shortTermRentalPage1LowerRight(property),
          ],
        },
        {
          pageBreak: 'before',
          columns: state.southernImpressions
            ? await southernImpressionsPage2Top(property)
            : await shortTermRentalPage2Top(property),
        },
        {
          stack: state.southernImpressions
            ? await southernImpressionsPage2Bottom(property)
            : await shortTermRentalPage2Bottom(property),
        },
        state.southernImpressions
          ? await southernImpressionsPage3(property)
          : null,
        proformaImagePage ? await imagePages(property) : null,
      ],
    }
    return docDefinition
  }

  const makeWholesalePDF = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      proformaImagePage = true,
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    const { legalDescription = '' } = organization
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    let mainImage = ''
    let subImageOne = ''
    let subImageTwo = ''
    let mapImage = ''

    if (proformaImages.length <= 0)
      switch (images.length) {
        case 0: {
          mainImage = await handleImageUrl(
            '/images/default_image.png',
          )
          subImageOne = await handleImageUrl(
            '/images/default_image.png',
          )
          subImageTwo = await handleImageUrl(
            '/images/default_image.png',
          )
          mapImage = await handleImageUrl('/images/default_image.png')
          break
        }
        case 1: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = whiteImage
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 2: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 3: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = await handleImageUrl(images[2].url)
          mapImage = whiteImage
          break
        }
        default: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = await handleImageUrl(images[2].url)
          mapImage = await handleImageUrl(images[3].url)
          break
        }
      }
    else
      switch (proformaImages.length) {
        case 1: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = whiteImage
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 2: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 3: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = await handleImageUrl(proformaImages[2])
          mapImage = whiteImage
          break
        }
        default: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = await handleImageUrl(proformaImages[2])
          mapImage = await handleImageUrl(proformaImages[3])
          break
        }
      }

    const listPrice = acquisition.analysis.listPrice

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [0, 0, 0, 0],
      info: {
        // HIDE ADDRESS SETUP
        // title: property.proformaHideAddress ? `${startCase(property.city)}-${startCase(property.state)}-${property.zip}-finance` : `${property.address}-${startCase(property.city)}-${startCase(property.state)}-${property.zip}-finance`,
        title: `${property.address}-${startCase(
          property.city,
        )}-${startCase(property.state)}-${property.zip}-finance`,
      },
      content: [
        {
          image: mainImage,
          // fit: [600, 300],
          width: 600,
          height: 300,
          margin: [0, 0, 0, 5],
          // alignment: 'center'
        },
        {
          absolutePosition: { x: 150, y: 200 },
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 300,
              h: 100,
              r: 2,
              color: 'black',
              fillOpacity: 0.3,
            },
          ],
        },
        {
          absolutePosition: { x: 0, y: 210 },
          stack: [
            {
              text: currencyFormater.format(listPrice),
              fontSize: 28,
              color: 'white',
              bold: true,
              alignment: 'center',
            },
            // property.proformaHideAddress ?
            // {
            //   text: '',
            //   fontSize: 16,
            //   color: 'white',
            //   alignment: 'center',
            //   margin: [0, 10, 0, 0],
            // }
            // :
            {
              text: property.address,
              fontSize: 16,
              color: 'white',
              alignment: 'center',
            },
            {
              text:
                startCase(property.city) +
                ' ' +
                startCase(property.state) +
                ', ' +
                property.zip,
              fontSize: 16,
              color: 'white',
              alignment: 'center',
            },
          ],
        },
        {
          columns: [
            {
              width: 215,
              stack: [
                {
                  image: subImageOne,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
                {
                  image: subImageTwo,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
                {
                  image: mapImage,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
              ],
            },
            await wholesalePage1LowerRight(property),
          ],
        },
        proformaImagePage ? await imagePages(property) : null,
      ],
    }
    return docDefinition
  }

  const makeAstoflipPDF = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      acquisition = {},
      assumptions = {},
      images = [],
      proformaImages = [],
      proformaImagePage = true,
      portfolio = {},
      proformaContact,
    } = property
    const { organization = {} } = portfolio
    const { legalDescription = '' } = organization
    let { proformaDescription = '', description = '' } = property

    const whiteImage = await handleImageUrl('/images/white.jpg')

    let proformaDescriptionLines = proformaDescription
      ? proformaDescription.split(/\r\n|\r|\n/).length
      : 0

    if (proformaDescriptionLines < 4) proformaDescriptionLines *= 50
    else if (proformaDescriptionLines < 8)
      proformaDescriptionLines *= 75
    else proformaDescriptionLines *= 100

    const orgEmail = proformaContact
      ? proformaContact.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = proformaContact
      ? formatPhoneNumber(proformaContact.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    let mainImage = ''
    let subImageOne = ''
    let subImageTwo = ''
    let mapImage = ''

    if (proformaImages.length <= 0)
      switch (images.length) {
        case 0: {
          mainImage = await handleImageUrl(
            '/images/default_image.png',
          )
          subImageOne = await handleImageUrl(
            '/images/default_image.png',
          )
          subImageTwo = await handleImageUrl(
            '/images/default_image.png',
          )
          mapImage = await handleImageUrl('/images/default_image.png')
          break
        }
        case 1: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = whiteImage
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 2: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 3: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = await handleImageUrl(images[2].url)
          mapImage = whiteImage
          break
        }
        default: {
          mainImage = await handleImageUrl(images[0].url)
          subImageOne = await handleImageUrl(images[1].url)
          subImageTwo = await handleImageUrl(images[2].url)
          mapImage = await handleImageUrl(images[3].url)
          break
        }
      }
    else
      switch (proformaImages.length) {
        case 1: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = whiteImage
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 2: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = whiteImage
          mapImage = whiteImage
          break
        }
        case 3: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = await handleImageUrl(proformaImages[2])
          mapImage = whiteImage
          break
        }
        default: {
          mainImage = await handleImageUrl(proformaImages[0])
          subImageOne = await handleImageUrl(proformaImages[1])
          subImageTwo = await handleImageUrl(proformaImages[2])
          mapImage = await handleImageUrl(proformaImages[3])
          break
        }
      }

    const listPrice = acquisition.analysis.listPrice

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [0, 0, 0, 0],
      info: {
        // HIDE ADDRESS SETUP
        // title: property.proformaHideAddress ? `${startCase(property.city)}-${startCase(property.state)}-${property.zip}-finance` : `${property.address}-${startCase(property.city)}-${startCase(property.state)}-${property.zip}-finance`,
        title: `${property.address}-${startCase(
          property.city,
        )}-${startCase(property.state)}-${property.zip}-finance`,
      },
      content: [
        {
          image: mainImage,
          // fit: [600, 300],
          width: 600,
          height: 300,
          margin: [0, 0, 0, 5],
          // alignment: 'center'
        },
        {
          absolutePosition: { x: 150, y: 200 },
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 300,
              h: 100,
              r: 2,
              color: 'black',
              fillOpacity: 0.3,
            },
          ],
        },
        {
          absolutePosition: { x: 0, y: 210 },
          stack: [
            {
              text: currencyFormater.format(listPrice),
              fontSize: 28,
              color: 'white',
              bold: true,
              alignment: 'center',
            },
            // property.proformaHideAddress ?
            // {
            //   text: '',
            //   fontSize: 16,
            //   color: 'white',
            //   alignment: 'center',
            //   margin: [0, 10, 0, 0],
            // }
            // :
            {
              text: property.address,
              fontSize: 16,
              color: 'white',
              alignment: 'center',
            },
            {
              text:
                startCase(property.city) +
                ' ' +
                startCase(property.state) +
                ', ' +
                property.zip,
              fontSize: 16,
              color: 'white',
              alignment: 'center',
            },
          ],
        },
        {
          columns: [
            {
              width: 215,
              stack: [
                {
                  image: subImageOne,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
                {
                  image: subImageTwo,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
                {
                  image: mapImage,
                  width: 200,
                  height: 130,
                  margin: 5,
                },
              ],
            },
            await astroflipPage1LowerRight(property),
          ],
        },
        proformaImagePage ? await imagePages(property) : null,
      ],
    }
    return docDefinition
  }

  const makeCMA = async (property) => {
    const {
      beds = [0],
      baths = [0],
      sqft = [0],
      images = [],
      proformaImages = [],
      portfolio = {},
    } = property
    const { organization = {} } = portfolio
    const { CMALegalDescription = '' } = organization

    const { comps = [] } = props

    const whiteImage = await handleImageUrl('/images/white.jpg')

    const orgEmail = props.session.me.username
      ? props.session.me.username
      : organization.email
        ? organization.email
        : 'Email Not Entered'
    const orgPhone = props.session.me.phone
      ? formatPhoneNumber(props.session.me.phone)
      : organization.phone
        ? formatPhoneNumber(organization.phone)
        : 'Phone Not Entered'
    let orgLogo = property.proformaLogo
      ? await handleImageUrl(property.proformaLogo)
      : organization.logo
        ? organization.logo.url
          ? await handleImageUrl(organization.logo.url)
          : whiteImage
        : whiteImage

    if (!orgLogo) orgLogo = whiteImage

    let mainImage = ''

    if (proformaImages.length <= 0) {
      if (images.length <= 0) {
        mainImage = await handleImageUrl('/images/default_image.png')
      } else {
        mainImage = await handleImageUrl(images[0].url)
      }
    } else {
      mainImage = await handleImageUrl(proformaImages[0])
    }

    const compValues = await Promise.all(
      map(slice(comps, 0, 20), async (comp) => {
        return {
          _id: comp._id,
          address: comp.address,
          city: comp.city,
          state: comp.state,
          zip: comp.zip,
          beds: comp.beds,
          baths: comp.baths,
          propertyPrice: Number(
            comp.estval * (1 + comp.priceAdjustment / 100),
          ),
          soldDate: formatDate(comp.saleDate),
          soldPrice: Number(comp.estval),
          priceAdjustmentPercent: comp.priceAdjustment / 100,
          priceAdjustmentDollar: Number(
            (comp.estval * comp.priceAdjustment) / 100,
          ),
          propertyPerSqft: comp.sqft
            ? Number(comp.estval * (1 + comp.priceAdjustment / 100)) /
                comp.sqft || 0
            : null,
          sqft: comp.sqft,
          daysOnMarket: comp.daysOnMarket,
          image: isEmpty(comp.images)
            ? await handleImageUrl('/images/default_image.png')
            : await handleImageUrl(head(comp.images).url),
          initialListPrice: comp.initialListPrice,
        }
      }),
    )

    const averageCompValues = meanBy(
      filter(compValues, 'propertyPrice'),
      'propertyPrice',
    )

    const chartDataURLs = map(chunk(compValues, 4), (compGroup) =>
      getCompChartData(property, compGroup),
    )

    const currencyFormater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const numberFormat = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

    const percentageFormat = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
    })

    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [0, 0, 0, 0],
      info: {
        // HIDE ADDRESS SETUP
        // title: property.proformaHideAddress ? `${startCase(property.city)}-${startCase(property.state)}-${property.zip}-finance` : `${property.address}-${startCase(property.city)}-${startCase(property.state)}-${property.zip}-finance`,
        title: `${property.address}-${startCase(
          property.city,
        )}-${startCase(property.state)}-${property.zip}-CMA`,
      },
      content: [
        {
          canvas: [
            {
              type: 'rect',
              x: 52,
              y: 42,
              w: 500,
              h: 52,
              r: 2,
              color: 'black',
              fillOpacity: 0.5,
            },
            {
              type: 'rect',
              x: 50,
              y: 40,
              w: 500,
              h: 52,
              r: 2,
            },
            {
              type: 'rect',
              x: 50,
              y: 40,
              w: 500,
              h: 52,
              r: 2,
              border: [true, true, true, true],
              color: 'white',
              fillOpacity: 1,
            },
          ],
        },
        {
          absolutePosition: { x: 125, y: 50 },
          stack: [
            { text: 'Comparative Market Analysis', fontSize: 28 },
          ],
        },
        {
          text: `${property.address} ${property.city}, ${property.state} ${property.zip}`,
          bold: true,
          fontSize: 20,
          margin: [50, 10, 50, 0],
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 50,
              y1: 5,
              x2: 550,
              y2: 5,
              lineWidth: 1,
              color: 'grey',
            },
          ],
        },
        {
          columns: [
            {
              image: mainImage,
              width: 250,
              height: 180,
              margin: [50, 5, 5, 5],
            },
            {
              width: 250,
              stack: [
                { text: 'Prepared By', bold: true },
                {
                  text: `${props.session.me.profile.firstname} ${props.session.me.profile.lastname}`,
                },
                { text: orgPhone },
                { text: orgEmail },
              ],
              margin: [60, 5, 5, 5],
            },
          ],
        },
        {
          absolutePosition: { x: 5, y: 755 },
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 5,
              x2: 580,
              y2: 5,
              lineWidth: 2,
            },
          ],
          margin: [0, 0, 0, 0],
        },
        {
          absolutePosition: { x: 5, y: 760 },
          stack: [
            {
              table: {
                headerRows: 1,
                widths: [70, 100, '*', 180],
                body: [
                  [
                    {
                      text: 'Powered By ',
                      alignment: 'left',
                      color: '#8e9194',
                      margin: [0, 10, 0, 0],
                    },
                    {
                      image: await handleImageUrl(
                        urlAffiliate
                          ? get(
                              affiliate,
                              'sidebar.sidebarMemberFullImage.0.url',
                            ) || '/images/NewLogoBlue.png'
                          : '/images/NewLogoBlue.png',
                      ),
                      width: 100,
                      height: 20,
                      margin: [-5, 7, 0, 0],
                    },
                    {
                      text: orgPhone,
                      alignment: 'right',
                      margin: [0, 10, 0, 0],
                      // margin:
                      //   orgEmail.length > 30
                      //     ? [0, 4, 0, 0]
                      //     : [0, 0, 0, 0],
                    },
                    {
                      image: orgLogo,
                      fit: [125, 50],
                      // width: 150,
                      // height: 75,
                      margin: [0, 0, 0, 0],
                      alignment: 'center',
                      rowSpan: 2,
                    },
                  ],
                  [
                    {
                      text: 'Create Your CMA at',
                      alignment: 'left',
                      margin: [0, -3, 0, 0],
                      color: '#8e9194',
                      fontSize: 8,
                      noWrap: true,
                      //colSpan: 1,
                    },
                    {
                      text: urlDomain || 'housefolios.com',
                      link:
                        'https://' +
                        (urlDomain || 'www.housefolios.com') +
                        '/',
                      alignment: 'left',
                      decoration: 'underline',
                      margin: [0, -3, 0, 0],
                      color: '#3399ff',
                      fontSize: 8,
                      //colSpan: 1,
                    },
                    {
                      text: orgEmail,
                      alignment: 'right',
                      margin: [0, -5, 0, 0],
                      fontSize:
                        orgEmail.length > 20
                          ? orgEmail.length > 30
                            ? 8
                            : 10
                          : 12,
                      // margin:
                      //   orgEmail.length > 30
                      //     ? [0, 4, 0, 0]
                      //     : [0, 0, 0, 0],
                    },
                  ],
                ],
              },
              margin: [5, 10, 10, 0],
              layout: {
                defaultBorder: false,
              },
            },
            {
              margin: [15, 10, 15, 15],
              color: 'gray',
              fontSize: 8,
              text: CMALegalDescription ? CMALegalDescription : '',
              pageBreak: 'after',
              //'The above agent/brokerage makes no warranty or representation about the content of this brochure. While the information displayed herein is thought to be accurate, it is your responsibility to independently confirm its accuracy and completeness. Any projections, opinions, assumptions or estimates are used for example only and do not represent the current or future performance of the property. The above agent/brokerage neither practices accounting nor gives advice regarding tax benefits/liabilities or any other tax, accounting or financial consideration, nor does the above agent/brokerage give advice regarding financial investments. It is strongly recommended that you seek appropriate professional counsel regarding your rights as a homeowner.',
            },
            {
              canvas: [
                {
                  type: 'rect',
                  x: 52,
                  y: 42,
                  w: 500,
                  h: 52,
                  r: 2,
                  color: 'black',
                  fillOpacity: 0.5,
                },
                {
                  type: 'rect',
                  x: 50,
                  y: 40,
                  w: 500,
                  h: 52,
                  r: 2,
                },
                {
                  type: 'rect',
                  x: 50,
                  y: 40,
                  w: 500,
                  h: 52,
                  r: 2,
                  border: [true, true, true, true],
                  color: 'white',
                  fillOpacity: 1,
                },
              ],
            },
            {
              absolutePosition: { x: 200, y: 50 },
              stack: [{ text: 'Subject Property', fontSize: 28 }],
            },
            {
              text: 'Subject Property Details',
              fontSize: 16,
              margin: [50, 10, 50, 0],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 50,
                  y1: 5,
                  x2: 550,
                  y2: 5,
                  lineWidth: 1,
                  color: 'grey',
                },
              ],
            },
            {
              columns: [
                {
                  width: 250,
                  stack: [
                    {
                      image: mainImage,
                      width: 250,
                      height: 180,
                      margin: [50, 5, 5, 30],
                    },
                    {
                      margin: [50, 0, 0, 0],
                      table: {
                        headerRows: 1,
                        widths: [100, 130],
                        body: [
                          [
                            {
                              text: 'Subject Property Overview',
                              colSpan: 2,
                              alignment: 'center',
                            },
                            '',
                          ],
                          [
                            'List Price',
                            {
                              text: currencyFormater.format(
                                property.acquisition.analysis
                                  .listPrice,
                              ),
                              alignment: 'right',
                            },
                          ],
                          [
                            'Bedrooms',
                            { text: sum(beds), alignment: 'right' },
                          ],
                          [
                            'Bathrooms',
                            { text: sum(baths), alignment: 'right' },
                          ],
                          [
                            'Apx Total SqFt',
                            {
                              text: numberFormat.format(sum(sqft)),
                              alignment: 'right',
                            },
                          ],
                          ['Sold Date', ''],
                        ],
                      },
                    },
                  ],
                },
                {
                  width: 250,
                  stack: [
                    { text: 'Subject Address:', bold: true },
                    { text: property.address },
                    {
                      text: `${property.city}, ${property.state} ${property.zip}`,
                      margin: [0, 0, 0, 30],
                    },
                    { text: 'Suggested List Price', bold: true },
                    {
                      text: !isNaN(averageCompValues)
                        ? currencyFormater.format(averageCompValues)
                        : 'Subject property has no comps for comparision',
                    },
                  ],
                  margin: [60, 5, 5, 5],
                },
              ],
            },
            {
              pageBreak: 'before',
              canvas: [
                {
                  type: 'rect',
                  x: 52,
                  y: 42,
                  w: 500,
                  h: 52,
                  r: 2,
                  color: 'black',
                  fillOpacity: 0.5,
                },
                {
                  type: 'rect',
                  x: 50,
                  y: 40,
                  w: 500,
                  h: 52,
                  r: 2,
                },
                {
                  type: 'rect',
                  x: 50,
                  y: 40,
                  w: 500,
                  h: 52,
                  r: 2,
                  border: [true, true, true, true],
                  color: 'white',
                  fillOpacity: 1,
                },
              ],
            },
            {
              absolutePosition: { x: 175, y: 50 },
              stack: [
                { text: 'Sold Comparable Stats', fontSize: 28 },
              ],
            },
            {
              text: 'Sold Properties Comparable Stats',
              fontSize: 16,
              margin: [50, 10, 50, 0],
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 50,
                  y1: 5,
                  x2: 550,
                  y2: 5,
                  lineWidth: 1,
                  color: 'grey',
                },
              ],
            },
            {
              margin: [50, 10, 50, 0],
              columns: [
                {
                  stack: [
                    {
                      text: [
                        { text: 'Lowest List Price: ', bold: true },
                        '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        { text: 'Lowest Sold Price: ', bold: true },
                        !isEmpty(filter(compValues, 'soldPrice'))
                          ? currencyFormater.format(
                              get(
                                minBy(
                                  filter(compValues, 'soldPrice'),
                                  'soldPrice',
                                ),
                                'soldPrice',
                              ),
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        {
                          text: 'Lowest Adjusted Price: ',
                          bold: true,
                        },
                        !isEmpty(filter(compValues, 'propertyPrice'))
                          ? currencyFormater.format(
                              get(
                                minBy(
                                  filter(compValues, 'propertyPrice'),
                                  'propertyPrice',
                                ),
                                'propertyPrice',
                              ),
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        { text: 'Lowest Sqft: ', bold: true },
                        !isEmpty(filter(compValues, 'sqft'))
                          ? numberFormat.format(
                              get(
                                minBy(
                                  filter(compValues, 'sqft'),
                                  'sqft',
                                ),
                                'sqft',
                              ),
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        { text: 'Lowest $/SqFt: ', bold: true },
                        !isEmpty(
                          filter(compValues, 'propertyPerSqft'),
                        )
                          ? currencyFormater.format(
                              get(
                                minBy(
                                  filter(
                                    compValues,
                                    'propertyPerSqft',
                                  ),
                                  'propertyPerSqft',
                                ),
                                'propertyPerSqft',
                              ),
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        { text: 'Lowest DOM: ', bold: true },
                        !isEmpty(filter(compValues, 'daysOnMarket'))
                          ? get(
                              minBy(
                                filter(compValues, 'daysOnMarket'),
                                'daysOnMarket',
                              ),
                              'daysOnMarket',
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: [
                        { text: 'Highest List Price: ', bold: true },
                        '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        { text: 'Highest Sold Price: ', bold: true },
                        !isEmpty(filter(compValues, 'soldPrice'))
                          ? currencyFormater.format(
                              get(
                                maxBy(
                                  filter(compValues, 'soldPrice'),
                                  'soldPrice',
                                ),
                                'soldPrice',
                              ),
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        {
                          text: 'Highest Adjusted Price: ',
                          bold: true,
                        },
                        !isEmpty(filter(compValues, 'propertyPrice'))
                          ? currencyFormater.format(
                              get(
                                maxBy(
                                  filter(compValues, 'propertyPrice'),
                                  'propertyPrice',
                                ),
                                'propertyPrice',
                              ),
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        { text: 'Highest Sqft: ', bold: true },
                        !isEmpty(filter(compValues, 'sqft'))
                          ? numberFormat.format(
                              get(
                                maxBy(
                                  filter(compValues, 'sqft'),
                                  'sqft',
                                ),
                                'sqft',
                              ),
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        { text: 'Highest $/SqFt: ', bold: true },
                        !isEmpty(
                          filter(compValues, 'propertyPerSqft'),
                        )
                          ? currencyFormater.format(
                              get(
                                maxBy(
                                  filter(
                                    compValues,
                                    'propertyPerSqft',
                                  ),
                                  'propertyPerSqft',
                                ),
                                'propertyPerSqft',
                              ),
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        { text: 'Highest DOM: ', bold: true },
                        !isEmpty(filter(compValues, 'daysOnMarket'))
                          ? get(
                              maxBy(
                                filter(compValues, 'daysOnMarket'),
                                'daysOnMarket',
                              ),
                              'daysOnMarket',
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: [
                        { text: 'Average List Price: ', bold: true },
                        '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        { text: 'Average Sold Price: ', bold: true },
                        !isEmpty(filter(compValues, 'soldPrice'))
                          ? currencyFormater.format(
                              meanBy(
                                filter(compValues, 'soldPrice'),
                                'soldPrice',
                              ),
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        {
                          text: 'Average Adjusted Price: ',
                          bold: true,
                        },
                        !isEmpty(filter(compValues, 'propertyPrice'))
                          ? currencyFormater.format(
                              meanBy(
                                filter(compValues, 'propertyPrice'),
                                'propertyPrice',
                              ),
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        { text: 'Average Sqft: ', bold: true },
                        !isEmpty(filter(compValues, 'sqft'))
                          ? numberFormat.format(
                              meanBy(
                                filter(compValues, 'sqft'),
                                'sqft',
                              ),
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        { text: 'Average $/SqFt: ', bold: true },
                        !isEmpty(
                          filter(compValues, 'propertyPerSqft'),
                        )
                          ? currencyFormater.format(
                              meanBy(
                                filter(compValues, 'propertyPerSqft'),
                                'propertyPerSqft',
                              ),
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                    {
                      text: [
                        { text: 'Average DOM: ', bold: true },
                        !isEmpty(filter(compValues, 'daysOnMarket'))
                          ? meanBy(
                              filter(compValues, 'daysOnMarket'),
                              'daysOnMarket',
                            )
                          : '-',
                      ],
                      fontSize: 10,
                      margin: [0, 0, 0, 5],
                    },
                  ],
                },
              ],
            },
            {
              text: 'Subject Property',
              alignment: 'center',
              margin: [0, 30, 0, 0],
            },
            {
              margin: [50, 5, 50, 0],
              table: {
                widths: ['*', '*', '*', 'auto', '*'],
                headerRows: 1,

                body: [
                  [
                    {
                      text: 'Address',
                      alignment: 'center',
                      fontSize: 10,
                    },
                    {
                      text: 'City',
                      alignment: 'center',
                      fontSize: 10,
                    },
                    {
                      text: 'Apx SqFt',
                      alignment: 'center',
                      fontSize: 10,
                    },
                    {
                      text: 'Suggested List Price',
                      alignment: 'center',
                      fontSize: 10,
                    },
                    {
                      text: '$/SqFt',
                      alignment: 'center',
                      fontSize: 10,
                    },
                  ],
                  [
                    { text: property.address, fontSize: 10 },
                    { text: property.city, fontSize: 10 },
                    {
                      text: numberFormat.format(sum(property.sqft)),
                      alignment: 'center',
                      fontSize: 10,
                    },
                    {
                      text: !isNaN(averageCompValues)
                        ? currencyFormater.format(averageCompValues)
                        : '-',
                      alignment: 'right',
                      fontSize: 10,
                    },
                    {
                      text: sum(property.sqft)
                        ? currencyFormater.format(
                            averageCompValues / sum(property.sqft),
                          )
                        : '-',
                      alignment: 'right',
                      fontSize: 10,
                    },
                  ],
                ],
              },
            },
            {
              text: `${
                compValues ? compValues.length : 0
              } Sold Comparables`,
              alignment: 'center',
              margin: [0, 30, 0, 0],
            },
            {
              margin: [50, 5, 50, 0],
              table: {
                widths: [
                  'auto',
                  '*',
                  '*',
                  '*',
                  'auto',
                  'auto',
                  '*',
                  'auto',
                  'auto',
                ],
                headerRows: 1,
                body: concat(
                  [
                    [
                      {
                        text: 'Address',
                        alignment: 'center',
                        fontSize: 8,
                      },
                      {
                        text: 'City',
                        alignment: 'center',
                        fontSize: 8,
                      },
                      {
                        text: 'Apx SqFt',
                        alignment: 'center',
                        fontSize: 8,
                      },
                      {
                        text: 'List Price',
                        alignment: 'center',
                        fontSize: 8,
                      },
                      {
                        text: 'Sold Price',
                        alignment: 'center',
                        fontSize: 8,
                      },
                      {
                        text: 'Adjusted Price',
                        alignment: 'center',
                        fontSize: 8,
                      },
                      {
                        text: '$/SqFt',
                        alignment: 'center',
                        fontSize: 8,
                      },
                      {
                        text: 'SP/LP',
                        alignment: 'center',
                        fontSize: 8,
                      },
                      {
                        text: 'DOM',
                        alignment: 'center',
                        fontSize: 8,
                      },
                    ],
                  ],
                  map(compValues, (comp) => [
                    { text: comp.address, fontSize: 8 },
                    { text: comp.city, fontSize: 8 },
                    {
                      text: numberFormat.format(comp.sqft),
                      alignment: 'center',
                      fontSize: 8,
                    },
                    { text: '-', alignment: 'right', fontSize: 8 },
                    {
                      text: currencyFormater.format(comp.soldPrice),
                      alignment: 'right',
                      fontSize: 8,
                    },
                    {
                      text: currencyFormater.format(
                        comp.propertyPrice,
                      ),
                      alignment: 'right',
                      fontSize: 8,
                    },
                    {
                      text: comp.sqft
                        ? currencyFormater.format(
                            comp.propertyPrice / comp.sqft,
                          )
                        : '-',
                      alignment: 'right',
                      fontSize: 8,
                    },
                    { text: '-', alignment: 'right', fontSize: 8 },
                    {
                      text: comp.daysOnMarket || '-',
                      alignment: 'right',
                      fontSize: 8,
                    },
                  ]),
                ),
              },
            },
            {
              stack: map(compValues, (comp, index) => [
                {
                  pageBreak: 'before',
                  canvas: [
                    {
                      type: 'rect',
                      x: 52,
                      y: 42,
                      w: 500,
                      h: 52,
                      r: 2,
                      color: 'black',
                      fillOpacity: 0.5,
                    },
                    {
                      type: 'rect',
                      x: 50,
                      y: 40,
                      w: 500,
                      h: 52,
                      r: 2,
                    },
                    {
                      type: 'rect',
                      x: 50,
                      y: 40,
                      w: 500,
                      h: 52,
                      r: 2,
                      border: [true, true, true, true],
                      color: 'white',
                      fillOpacity: 1,
                    },
                  ],
                },
                {
                  absolutePosition: { x: 175, y: 50 },
                  stack: [
                    {
                      text: `Sold Comparable ${index + 1}`,
                      fontSize: 28,
                    },
                  ],
                },
                {
                  text: 'Sold Comparable Property Details',
                  fontSize: 16,
                  margin: [50, 10, 50, 0],
                },
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 50,
                      y1: 5,
                      x2: 550,
                      y2: 5,
                      lineWidth: 1,
                      color: 'grey',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 250,
                      stack: [
                        {
                          image: comp.image,
                          width: 250,
                          height: 180,
                          margin: [50, 5, 5, 30],
                        },
                        {
                          margin: [50, 0, 0, 0],
                          table: {
                            headerRows: 1,
                            widths: [100, 130],
                            body: [
                              [
                                {
                                  text: `Comp ${
                                    index + 1
                                  } Chosen Criteria`,
                                  alignment: 'center',
                                },
                                {
                                  text: 'Criteria Value',
                                  alignment: 'center',
                                },
                              ],
                              [
                                'Listing Number',
                                { text: '-', alignment: 'center' },
                              ],
                              [
                                'List Price',
                                { text: '-', alignment: 'center' },
                              ],
                              [
                                'Original List Price',
                                {
                                  text: comp.initialListPrice
                                    ? currencyFormater.format(
                                        comp.initialListPrice,
                                      )
                                    : '-',
                                  alignment: 'center',
                                },
                              ],
                              [
                                'Bedrooms',
                                {
                                  text: comp.beds,
                                  alignment: 'center',
                                },
                              ],
                              [
                                'Bathrooms',
                                {
                                  text: comp.beds,
                                  alignment: 'center',
                                },
                              ],
                              [
                                'Apx Total SqFt',
                                {
                                  text: numberFormat.format(
                                    comp.sqft,
                                  ),
                                  alignment: 'center',
                                },
                              ],
                              [
                                'Sold Price',
                                {
                                  text: currencyFormater.format(
                                    comp.soldPrice,
                                  ),
                                  alignment: 'center',
                                },
                              ],
                              [
                                'Sold Date',
                                {
                                  text: comp.soldDate,
                                  alignment: 'center',
                                },
                              ],
                            ],
                          },
                        },
                      ],
                    },
                    {
                      width: 250,
                      stack: [
                        {
                          text: `Comparable ${index + 1} Address:`,
                          bold: true,
                        },
                        { text: property.address },
                        {
                          text: `${property.city}, ${property.state} ${property.zip}`,
                          margin: [0, 0, 0, 30],
                        },
                        {
                          text: [
                            { text: 'Sold Price: ', bold: true },
                            currencyFormater.format(comp.soldPrice),
                          ],
                        },
                        {
                          text: [
                            { text: 'Adjusted Price: ', bold: true },
                            currencyFormater.format(
                              comp.propertyPrice,
                            ),
                          ],
                        },
                        {
                          text: 'Public Remarks',
                          bold: true,
                          fontSize: 10,
                          margin: [0, 100, 0, 0],
                        },
                        {
                          text: '-',
                          bold: true,
                          fontSize: 10,
                          margin: [0, 5, 0, 0],
                        },
                      ],
                      margin: [60, 5, 5, 5],
                    },
                  ],
                },
              ]),
            },
            {
              stack: map(chunk(compValues, 2), (compPair, index) => [
                {
                  pageBreak: 'before',
                  canvas: [
                    {
                      type: 'rect',
                      x: 52,
                      y: 42,
                      w: 500,
                      h: 52,
                      r: 2,
                      color: 'black',
                      fillOpacity: 0.5,
                    },
                    {
                      type: 'rect',
                      x: 50,
                      y: 40,
                      w: 500,
                      h: 52,
                      r: 2,
                    },
                    {
                      type: 'rect',
                      x: 50,
                      y: 40,
                      w: 500,
                      h: 52,
                      r: 2,
                      border: [true, true, true, true],
                      color: 'white',
                      fillOpacity: 1,
                    },
                  ],
                },
                {
                  absolutePosition: { x: 150, y: 50 },
                  stack: [
                    {
                      text: 'Side by Side Comparrison',
                      fontSize: 28,
                    },
                  ],
                },
                {
                  margin: [50, 20, 40, 0],
                  table: {
                    headerRows: 2,
                    widths: ['auto', '*', '*', '*'],
                    body: [
                      [
                        '',
                        {
                          image: mainImage,
                          width: 120,
                          alignment: 'center',
                        },
                        {
                          image: compPair[0].image,
                          width: 120,
                          alignment: 'center',
                        },
                        {
                          image:
                            compPair.length > 1
                              ? compPair[1].image
                              : whiteImage,
                          width: 120,
                          alignment: 'center',
                        },
                      ],
                      [
                        '',
                        { text: `Subject`, alignment: 'center' },
                        {
                          text: `Comparable ${index * 2 + 1}`,
                          alignment: 'center',
                        },
                        {
                          text:
                            compPair.length > 1
                              ? `Comparable ${index * 2 + 2}`
                              : '',
                          alignment: 'center',
                        },
                      ],
                      [
                        'Address',
                        `${property.address} ${property.city}, ${property.state} ${property.zip}`,
                        `${compPair[0].address} ${compPair[0].city}, ${compPair[0].state} ${compPair[0].zip}`,
                        compPair.length > 1
                          ? `${compPair[1].address} ${compPair[1].city}, ${compPair[1].state} ${compPair[1].zip}`
                          : '',
                      ],
                      [
                        'Suggested List Price',
                        {
                          text: currencyFormater.format(
                            averageCompValues,
                          ),
                          alignment: 'right',
                        },
                        '',
                        '',
                      ],
                      [
                        'Adjusted Price',
                        '',
                        {
                          text: [
                            currencyFormater.format(
                              compPair[0].propertyPrice,
                            ),
                            {
                              text: `(${percentageFormat.format(
                                compPair[0].priceAdjustmentPercent,
                              )})`,
                            },
                          ],
                          alignment: 'right',
                        },
                        {
                          text:
                            compPair.length > 1
                              ? [
                                  currencyFormater.format(
                                    compPair[1].propertyPrice,
                                  ),
                                  {
                                    text: `(${percentageFormat.format(
                                      compPair[1]
                                        .priceAdjustmentPercent,
                                    )})`,
                                  },
                                ]
                              : '',
                          alignment: 'right',
                        },
                      ],
                      [
                        'List/Sell Price',
                        { text: '', alignment: 'center' },
                        { text: '-', alignment: 'center' },
                        {
                          text: compPair.length > 1 ? '-' : '',
                          alignment: 'center',
                        },
                      ],
                      [
                        'Misc Adjustments',
                        '',
                        {
                          text: currencyFormater.format(
                            compPair[0].priceAdjustmentDollar,
                          ),
                          alignment: 'right',
                        },
                        {
                          text:
                            compPair.length > 1
                              ? currencyFormater.format(
                                  compPair[1].priceAdjustmentDollar,
                                )
                              : '',
                          alignment: 'right',
                        },
                      ],
                      [
                        'List Price',
                        {
                          text: currencyFormater.format(
                            property.acquisition.analysis.listPrice,
                          ),
                          alignment: 'center',
                        },
                        { text: '-', alignment: 'center' },
                        { text: '-', alignment: 'center' },
                      ],
                      [
                        'Original List Price',
                        '',
                        {
                          text: compPair[0].initialListPrice
                            ? currencyFormater.format(
                                compPair[0].initialListPrice,
                              )
                            : '-',
                          alignment: 'center',
                        },
                        {
                          text:
                            compPair.length > 1
                              ? compPair[1].initialListPrice
                                ? currencyFormater.format(
                                    compPair[1].initialListPrice,
                                  )
                                : '-'
                              : '',
                          alignment: 'center',
                        },
                      ],
                      [
                        'Bedrooms',
                        {
                          text: sum(beds) || '_',
                          alignment: 'center',
                        },
                        {
                          text: compPair[0].beds || '-',
                          alignment: 'center',
                        },
                        {
                          text:
                            compPair.length > 1
                              ? compPair[1].beds || '-'
                              : '',
                          alignment: 'center',
                        },
                      ],
                      [
                        'Bathrooms',
                        {
                          text: sum(baths) || '_',
                          alignment: 'center',
                        },
                        {
                          text: compPair[0].baths || '-',
                          alignment: 'center',
                        },
                        {
                          text:
                            compPair.length > 1
                              ? compPair[1].baths || '-'
                              : '',
                          alignment: 'center',
                        },
                      ],
                      [
                        'Apx Total SqFt',
                        {
                          text:
                            numberFormat.format(sum(property.sqft)) ||
                            '-',
                          alignment: 'center',
                        },
                        {
                          text:
                            numberFormat.format(compPair[0].sqft) ||
                            '-',
                          alignment: 'center',
                        },
                        {
                          text:
                            compPair.length > 1
                              ? numberFormat.format(
                                  compPair[1].sqft,
                                ) || '-'
                              : '',
                          alignment: 'center',
                        },
                      ],
                      [
                        'Sold Price',
                        '',
                        {
                          text:
                            currencyFormater.format(
                              compPair[0].soldPrice,
                            ) || '-',
                          alignment: 'center',
                        },
                        {
                          text:
                            compPair.length > 1
                              ? currencyFormater.format(
                                  compPair[1].soldPrice,
                                ) || '-'
                              : '',
                          alignment: 'center',
                        },
                      ],
                      [
                        'Sold Date',
                        '',
                        {
                          text: compPair[0].soldDate || '-',
                          alignment: 'center',
                        },
                        {
                          text:
                            compPair.length > 1
                              ? compPair[1].soldDate || '-'
                              : '',
                          alignment: 'center',
                        },
                      ],
                    ],
                  },
                },
              ]),
            },
            map(chartDataURLs, (chartDataURL) => ({
              stack: [
                {
                  pageBreak: 'before',
                  canvas: [
                    {
                      type: 'rect',
                      x: 52,
                      y: 42,
                      w: 500,
                      h: 52,
                      r: 2,
                      color: 'black',
                      fillOpacity: 0.5,
                    },
                    {
                      type: 'rect',
                      x: 50,
                      y: 40,
                      w: 500,
                      h: 52,
                      r: 2,
                    },
                    {
                      type: 'rect',
                      x: 50,
                      y: 40,
                      w: 500,
                      h: 52,
                      r: 2,
                      border: [true, true, true, true],
                      color: 'white',
                      fillOpacity: 1,
                    },
                  ],
                },
                {
                  absolutePosition: { x: 175, y: 50 },
                  stack: [
                    { text: 'Comparable Bar Chart', fontSize: 28 },
                  ],
                },
                {
                  image: chartDataURL,
                  width: 500,
                  height: 700,
                  alignment: 'center',
                  margin: [0, 20, 0, 0],
                },
              ],
            })),
          ],
        },
      ],
    }
    return docDefinition
  }

  // Method to generate and download the PDF
  const generateAndDownloadPDF = async (docDefinition) => {
    const pdfDocGenerator = await generatePDF(docDefinition)
    pdfDocGenerator.getBlob((blob) => {
      const blobUrl = URL.createObjectURL(blob)

      // Create a temporary link element
      const link = document.createElement('a')
      link.href = blobUrl
      link.download =
        docDefinition?.info.title.replace(' ', '_') ?? 'document.pdf' // Set a default file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // Release the blob URL
      URL.revokeObjectURL(blobUrl)
    })
  }

  const downloadPDF = async (property) => {
    if (
      property.proformaFile &&
      (props.uploaded || state.type === 'uploaded')
    ) {
      const dataURL = await getBase64ImageFromUrl(
        property.proformaFile.url,
      )
      window.open(URL.createObjectURL(dataURL[1]), '_blank')
    } else if (
      property?.realeflowReportURL &&
      (props.CMA || state.type === 'CMA')
    ) {
      const dataURL = await getBase64ImageFromUrl(
        property?.realeflowReportURL,
      )
      window.open(URL.createObjectURL(dataURL[1]), '_blank')
    } else {
      const docDefinition =
        props.CMA || state.type === 'CMA'
          ? await makeCMA(property)
          : state.type === 'marketing'
            ? await makeMarketingPDF(property)
            : state.type === 'fixFlip'
              ? await makeFixFlipPDF(property)
              : state.type === 'wholesale'
                ? await makeWholesalePDF(property)
                : state.type === 'astroflip'
                  ? await makeAstoflipPDF(property)
                  : props.shortTermRental ||
                      state.type === 'shortTermRental'
                    ? await makeShortTermRentalPDF(property)
                    : await makePDF(property)

      // console.log('Doc Definition:', docDefinition)

      // Use the generateAndDownloadPDF method to generate and download the PDF
      await generateAndDownloadPDF(docDefinition)

      // // Use the generatePDF method from the class
      // const pdfDocGenerator = await generatePDF(docDefinition)
      // // Use the open method to open the generated PDF in a new window/tab.
      // // Note: Some browsers may block this operation if it is not triggered by a user action
      // pdfDocGenerator.open({}, window)
    }
  }

  const createDoc = async (property) => {
    if (
      property.proformaFile &&
      (props.uploaded || state.type === 'uploaded')
    ) {
      const dataURL = await getBase64ImageFromUrl(
        property.proformaFile.url,
      )
      setState({ ...state, dataURL: dataURL[0] })
    } else if (
      property?.realeflowReportURL &&
      (props.CMA || state.type === 'CMA')
    ) {
      const dataURL = await getBase64ImageFromUrl(
        property?.realeflowReportURL,
      )
      setState({ ...state, dataURL: dataURL[0] })
    } else {
      let docDefinition = {}
      // Assuming makePDF methods are prepared to return docDefinitions.
      if (props.CMA || state.type === 'CMA') {
        docDefinition = await makeCMA(property)
      } else if (state.type === 'marketing') {
        docDefinition = await makeMarketingPDF(property) // Ensure this method returns a docDefinition
      } else if (state.type === 'fixFlip') {
        docDefinition = await makeFixFlipPDF(property)
      } else if (state.type === 'wholesale') {
        docDefinition = await makeWholesalePDF(property)
      } else if (state.type === 'astroflip') {
        docDefinition = await makeAstoflipPDF(property)
      } else if (state.type === 'shortTermRental') {
        docDefinition = await makeShortTermRentalPDF(property)
      } else {
        // Default to a generic makePDF method if none of the above conditions are met
        docDefinition = await makePDF(property)
      }

      const pdfDocGenerator = await generatePDF(docDefinition)
      pdfDocGenerator.getDataUrl((data) => {
        setState({ ...state, dataURL: data })
      })
    }
  }

  const createDoc2 = async (property) => {
    if (
      property.proformaFile &&
      (props.uploaded || state.type === 'uploaded')
    ) {
      const dataURL = await getBase64ImageFromUrl(
        property.proformaFile.url,
      )
      setState({ ...state, blob: URL.createObjectURL(dataURL[1]) })
    } else if (
      property?.realeflowReportURL &&
      (props.CMA || state.type === 'CMA')
    ) {
      const dataURL = await getBase64ImageFromUrl(
        property?.realeflowReportURL,
      )
      setState({ ...state, blob: URL.createObjectURL(dataURL[1]) })
    } else {
      let docDefinition = {}
      // Use the conditions to prepare docDefinition as shown previously.
      if (props.CMA || state.type === 'CMA') {
        docDefinition = await makeCMA(property)
      } else if (state.type === 'marketing') {
        docDefinition = await makeMarketingPDF(property)
      } else if (state.type === 'fixFlip') {
        docDefinition = await makeFixFlipPDF(property)
      } else if (state.type === 'wholesale') {
        docDefinition = await makeWholesalePDF(property)
      } else if (state.type === 'astroflip') {
        docDefinition = await makeAstoflipPDF(property)
      } else if (state.type === 'shortTermRental') {
        docDefinition = await makeShortTermRentalPDF(property)
      } else {
        docDefinition = await makePDF(property)
      }

      const pdfDocGenerator = await generatePDF(docDefinition)
      pdfDocGenerator.getBlob((blob) => {
        setState({ ...state, blob: URL.createObjectURL(blob) })
      })
    }
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const { emails, dataURL, blob, display, type, flyers } = state
  const {
    propertyId,
    editProforma,
    financingProforma,
    updateProperty,
    disabled,
    handleClick,
    handleBackDrop,
    property,
    download,
  } = props

  if (display) {
    if (property.proformaFile && props.uploaded)
      return (
        <iframe
          title={'PropertyPDF'}
          src={property.proformaFile.url}
          height={window.innerHeight}
          width="100%"
        />
      )
    else if (blob)
      return (
        <iframe
          title={'PropertyPDF'}
          src={blob}
          height={window.innerHeight}
          width="100%"
        />
      )
    else
      return (
        <div style={{ textAlign: 'center' }}>Loading Proforma</div>
      )
  } else {
    return (
      <Query query={GET_PROPERTY} variables={{ id: propertyId }}>
        {({ loading, error, data }) => {
          if (loading) return 'Loading...'
          if (error) return `Error! ${error.message}`
          const { property } = data
          return editProforma ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleBackDrop(updateProperty)
                updateProperty({
                  refetchQueries: [
                    {
                      query: GET_PROPERTY,
                      variables: { id: propertyId },
                    },
                  ],
                }).then(({ data: { updateProperty } }) => {
                  const pdfProperty = {
                    _id: property._id,
                    address: property.address,
                    city: property.city,
                    state: property.state,
                    zip: property.zip,
                    beds: property.beds,
                    baths: property.baths,
                    sqft: property.sqft,
                    year: property.year,
                    images: property.images,
                    proformaFile: property.proformaFile,
                    proformaDescription:
                      updateProperty.proformaDescription,
                    proformaImages: updateProperty.proformaImages,
                    proformaImagePage:
                      updateProperty.proformaImagePage,
                    proformaLogo: updateProperty.proformaLogo,
                    proformaFloorPlan:
                      updateProperty.proformaFloorPlan,
                    proformaRehabCost:
                      updateProperty.proformaRehabCost,
                    proformaHideAddress:
                      updateProperty.proformaHideAddress,
                    proformaContact: updateProperty.proformaContact,
                    portfolio: property.portfolio,
                    assumptions: property.assumptions,
                    acquisition: property.acquisition,
                  }
                  downloadPDF(pdfProperty)
                  handleClick()
                })
              }}
              style={{
                color: 'white',
              }}
              disabled={disabled}
            >
              Save
            </Button>
          ) : financingProforma ? (
            <Mutation
              mutation={CONTACT_AGENT}
              variables={{
                propertyId: propertyId,
                firstname: props.session.me.profile.firstname,
                lastname: props.session.me.profile.lastname,
                phone: props.session.me.phone,
                email: props.session.me.email,
                address: property.address,
                city: property.city,
                state: property.state,
                zip: property.zip,
                base64: dataURL,
                message: '',
                financing: true,
              }}
            >
              {(contactAgent, { data, loading, error }) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    onSubmit(event, contactAgent)
                  }}
                  style={{
                    color: 'white',
                  }}
                  disabled={disabled || loading || !dataURL}
                >
                  Send
                </Button>
              )}
            </Mutation>
          ) : download ? (
            <ListItem
              button
              onClick={() => downloadPDF(property)}
              style={{ paddingLeft: 30 }}
            >
              <div className="nav-link-icon">
                <FontAwesomeIcon icon={['far', 'file-lines']} />
              </div>
              <span>
                {props.CMA
                  ? 'CMA'
                  : props.uploaded
                    ? 'Uploaded File'
                    : 'Proforma'}
              </span>
            </ListItem>
          ) : (
            <span>
              <ListItem button onClick={toggleEmailModal}>
                <div className="nav-link-icon">
                  <FontAwesomeIcon icon={['far', 'envelope']} />
                </div>
                <span>{'Email Report'}</span>
              </ListItem>

              <Dialog
                open={state.showEmail}
                onClose={toggleEmailModal}
                maxWidth="xs"
                fullWidth={true}
                onKeyDown={stopPropagationForTab}
              >
                <DialogTitle style={{ paddingBottom: 0 }}>
                  Email Report
                </DialogTitle>
                <BlockUi
                  blocking={state.backdrop}
                  loader={
                    <PacmanLoader
                      color={'var(--primary)'}
                      loading={true}
                    />
                  }
                  message={
                    <div className="text-primary">Sending Email</div>
                  }
                >
                  <Mutation
                    mutation={SEND_EMAIL}
                    variables={{
                      emails: emails,
                      base64: dataURL,
                      CMA: type === 'CMA',
                      attachments: map(
                        flyers,
                        (flyer) => flyer.value,
                      ),
                    }}
                  >
                    {(sendEmail, { data, loading, error }) => (
                      <ValidatorForm
                        onSubmit={(event) => {
                          onSubmit(event, sendEmail)
                        }}
                        style={{
                          overflowY: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <DialogContent>
                          <Grid container direction="row" spacing={1}>
                            <Grid item sm={12}>
                              <Autocomplete
                                multiple
                                id="emails"
                                options={[]}
                                value={emails}
                                freeSolo
                                autoSelect
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Send Email To"
                                    placeholder="Emails"
                                    helperText="Enter comma separated emails (no spaces). Press enter"
                                  />
                                )}
                                onChange={(event, inputs, reason) => {
                                  if (
                                    'createOption' == reason ||
                                    'blur' == reason
                                  ) {
                                    let values = split(
                                      inputs[inputs.length - 1],
                                      ',',
                                    )
                                    let validatedItems = []

                                    for (let value of values) {
                                      if (
                                        String(value)
                                          .toLowerCase()
                                          .match(
                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                          )
                                      )
                                        validatedItems.push(value)
                                    }

                                    let newList = concat(
                                      emails,
                                      validatedItems,
                                    )
                                    setState({
                                      ...state,
                                      emails: newList,
                                    })
                                  } else if (
                                    'removeOption' == reason
                                  ) {
                                    setState({
                                      ...state,
                                      emails: inputs,
                                    })
                                  } else if ('clear' == reason) {
                                    setState({ ...state, emails: [] })
                                  }
                                }}
                              />
                              {/* <TextValidator
                                name="email"
                                type="email"
                                variant='outlined'
                                margin='dense'
                                autoFocus
                                onChange={onChange}
                                value={email}
                                placeholder="Email"
                                label="Send Email To"
                                fullWidth={true}
                                validators={[
                                  'required',
                                  'maxStringLength: 50',
                                  'isEmail',
                                ]}
                                errorMessages={[
                                  'This field is required',
                                  'You cannot exceed 50 characters',
                                  'Not a valid email address',
                                ]}
                              /> */}
                            </Grid>
                            <Grid item sm={12}>
                              <FormControl
                                variant="outlined"
                                margin="dense"
                                fullWidth={true}
                              >
                                <InputLabel>
                                  {'Report type:'}
                                </InputLabel>
                                <Select
                                  name="type"
                                  className="form-control"
                                  value={type}
                                  onChange={(event) =>
                                    setState({
                                      ...state,
                                      [event.target.name]:
                                        event.target.value,
                                      dataURL: null,
                                    })
                                  }
                                  label="Report type:"
                                  inputProps={{
                                    name: 'type',
                                  }}
                                >
                                  <MenuItem
                                    key="buyhold"
                                    value={'buyHold'}
                                    button
                                  >
                                    <ListItemText
                                      primary={
                                        'Proforma - ' +
                                        getStrategyName(
                                          'Buy & Hold',
                                          props?.session?.me
                                            ?.activeOrg?.member,
                                        )
                                      }
                                    />
                                  </MenuItem>
                                  <MenuItem
                                    key="fixFlip"
                                    value={'fixFlip'}
                                    button
                                  >
                                    <ListItemText
                                      primary={
                                        'Proforma - ' +
                                        getStrategyName(
                                          'Fix & Flip',
                                          props?.session?.me
                                            ?.activeOrg?.member,
                                        )
                                      }
                                    />
                                  </MenuItem>
                                  <MenuItem
                                    key="marketing"
                                    value={'marketing'}
                                    button
                                  >
                                    <ListItemText
                                      primary={`Proforma - ${
                                        props.session.me.activeOrg
                                          .southernImpressions
                                          ? getStrategyName(
                                              'Build to Rent',
                                              props?.session?.me
                                                ?.activeOrg?.member,
                                            )
                                          : 'End Buyer'
                                      }`}
                                    />
                                  </MenuItem>
                                  <MenuItem
                                    key="shortTermRental"
                                    value={'shortTermRental'}
                                    button
                                  >
                                    <ListItemText
                                      primary={
                                        'Proforma - ' +
                                        getStrategyName(
                                          'Short Term Rental',
                                          props?.session?.me
                                            ?.activeOrg?.member,
                                        )
                                      }
                                    />
                                  </MenuItem>
                                  {props?.session?.me?.activeOrg
                                    ?.member !== 'astroflip' && (
                                    <MenuItem
                                      key="wholesale"
                                      value={'wholesale'}
                                      button
                                    >
                                      <ListItemText
                                        primary={
                                          'Proforma - ' +
                                          getStrategyName(
                                            'Wholesale',
                                            props?.session?.me
                                              ?.activeOrg?.member,
                                          )
                                        }
                                      />
                                    </MenuItem>
                                  )}
                                  {props?.session?.me?.activeOrg
                                    ?.member === 'astroflip' && (
                                    <MenuItem
                                      key="astroflip"
                                      value={'astroflip'}
                                      button
                                    >
                                      <ListItemText
                                        primary={
                                          'Proforma - ' +
                                          getStrategyName(
                                            'Astroflip',
                                            props?.session?.me
                                              ?.activeOrg?.member,
                                          )
                                        }
                                      />
                                    </MenuItem>
                                  )}
                                  {property.proformaFile && (
                                    <MenuItem value={'uploaded'}>
                                      <ListItemText primary="Uploaded File" />
                                    </MenuItem>
                                  )}
                                  <MenuItem value={'CMA'}>
                                    <ListItemText primary="CMA" />
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            {type !== 'CMA' &&
                              type !== 'uploaded' && (
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    key="proformaImagePage"
                                    control={
                                      <Checkbox
                                        name="proformaImagePage"
                                        type="checkbox"
                                        value={state.emailImagePage}
                                        icon={
                                          <CheckBoxOutlineBlankIcon fontSize="large" />
                                        }
                                        checkedIcon={
                                          <CheckBoxIcon
                                            fontSize="large"
                                            style={{
                                              color: '#2296F3',
                                            }}
                                          />
                                        }
                                        checked={state.emailImagePage}
                                        onClick={(event) =>
                                          setState({
                                            ...state,
                                            emailImagePage:
                                              !state.emailImagePage,
                                            dataURL: null,
                                          })
                                        }
                                        color="primary"
                                        style={{ marginTop: 'auto' }}
                                      />
                                    }
                                    label="Include all property images on the end of the report"
                                  ></FormControlLabel>
                                </Grid>
                              )}
                            {props.session.me.activeOrg
                              .southernImpressions && (
                              <Grid item xs={12}>
                                <Autocomplete
                                  multiple
                                  disableCloseOnSelect
                                  variant="outlined"
                                  margin="dense"
                                  label="Additional Flyers"
                                  options={[
                                    {
                                      label: 'LPS FAQ',
                                      value:
                                        'https://housefolios-sihomes.s3.us-west-1.amazonaws.com/LowPressureSystemFAQ.pdf',
                                    },
                                    {
                                      label: 'PBTS info',
                                      value:
                                        'https://housefolios-sihomes.s3.us-west-1.amazonaws.com/PBTSInformationalFlyer.pdf',
                                    },
                                    {
                                      label:
                                        'Sprinklers and Extinguishers Inspections',
                                      value:
                                        'https://housefolios-sihomes.s3.us-west-1.amazonaws.com/QuadFireSprinklersAndExtinguishersInspectionsFlyer.pdf',
                                    },
                                  ]}
                                  filterSelectedOptions
                                  fullWidth={true}
                                  value={flyers}
                                  onChange={(event, newValue) => {
                                    setState({
                                      ...state,
                                      flyers: newValue,
                                    })
                                  }}
                                  isOptionEqualToValue={isEqual}
                                  getOptionLabel={(option) =>
                                    option.label
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Additional Flyers"
                                    />
                                  )}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={toggleEmailModal}>
                            Cancel
                          </Button>
                          <Button
                            disabled={loading || !dataURL}
                            variant="contained"
                            color="primary"
                            type="submit"
                            startIcon={<SendIcon />}
                          >
                            Send
                          </Button>
                        </DialogActions>
                      </ValidatorForm>
                    )}
                  </Mutation>
                </BlockUi>
                {/* <Backdrop
                    open={state.backdrop}
                    style={{ color: '#fff', zIndex: 100000 }}
                  >
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <CircularProgress
                          variant='indeterminate'
                          color="inherit"
                        />
                      </Grid>
                      <Grid item>
                        <Typography color="inherit">
                          Emailing Proforma
                        </Typography>
                      </Grid>
                    </Grid>
                  </Backdrop> */}
              </Dialog>
            </span>
          )
        }}
      </Query>
    )
  }
}

export default withSession(PropertyPDF)
