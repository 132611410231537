/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: David Helmick
 * @ Modified time: 2022-03-24 16:19:27
 * @ Description: Component for the user to upload files from their system to be used as a proforma
 */

import React from 'react'

import {
  Card,
  Button,
  List,
  ListItem,
  IconButton,
} from '@mui/material'

import head from 'lodash/head'

import { useDropzone } from 'react-dropzone'

import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import CheckIcon from '@mui/icons-material/Check'
import { useMutation } from '@apollo/client'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'

import { GET_SIGNED_REQUEST } from '../../queries'
import { graphql } from '@apollo/client/react/hoc'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import withSession from '@/housefolios-components/Session/withSession'

function UploadProformaFileUploaderComponent(props) {
  const { proformaFile } = props
  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    accept: 'image/*, application/pdf',
    onDrop: async (acceptedFiles) => {
      props.setLoader(true)
      const file = head(acceptedFiles)
      const {
        data: { getSignedRequest },
      } = await props.getSignedRequest({
        variables: {
          fileInput: {
            filename: file.name,
            filetype: file.type,
            collection: 'proformas',
            documentId: props.propertyId,
          },
        },
      })
      const { signedRequest, url } = getSignedRequest

      const options = {
        headers: {
          'Content-type': file.type,
        },
      }
      try {
        await axios.put(signedRequest, file, options)
        const { profile = {} } = props.session.me

        updateProperty({
          variables: {
            id: props.propertyId,
            propertyInput: {
              proformaFile: {
                filename: file.name,
                filesize: file.size,
                filetype: file.type,
                url: url,
                createdAt: new Date(),
                createdBy: `${profile.firstname} ${profile.lastname}`,
              },
            },
          },
          refetchQueries: [
            {
              query: GET_PROPERTY,
              variables: { id: props.propertyId },
            },
          ],
        }).finally(() => {
          props.setLoader(false)
        })
      } catch (error) {
        return error
      }
    },
  })

  const files = proformaFile
    ? [
        <ListItem
          className="font-size-sm px-3 py-2 text-primary d-flex justify-content-between align-items-center"
          key={proformaFile.filename}
        >
          <span>{proformaFile.filename}</span>{' '}
          <span className="badge badge-pill bg-neutral-warning text-warning">
            {proformaFile.filesize} bytes
          </span>
          <IconButton
            onClick={() => {
              props.setLoader(true)
              updateProperty({
                variables: {
                  id: props.propertyId,
                  propertyInput: {
                    proformaFile: {},
                  },
                },
                refetchQueries: [
                  {
                    query: GET_PROPERTY,
                    variables: { id: props.propertyId },
                  },
                ],
              }).finally(() => {
                props.setLoader(false)
              })
            }}
            size="large">
            <FontAwesomeIcon
              icon={['far', 'circle-xmark']}
              className="font-size-md"
            />
          </IconButton>
        </ListItem>,
      ]
    : []

  return (
    <>
      <Card className="mt-4 p-3 p-lg-5 shadow-xxl">
        <div className="dropzone">
          <div
            {...getRootProps({
              className: 'dropzone-upload-wrapper',
            })}
          >
            <input {...getInputProps()} />
            <div className="dropzone-inner-wrapper">
              {isDragAccept && (
                <div>
                  <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                    <CheckIcon className="d-50" />
                  </div>
                  <div className="font-size-sm text-success">
                    All files will be uploaded!
                  </div>
                </div>
              )}
              {isDragReject && (
                <div>
                  <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                    <CloseTwoToneIcon className="d-50" />
                  </div>
                  <div className="font-size-sm text-danger">
                    Some files will be rejected!
                  </div>
                </div>
              )}
              {!isDragActive && (
                <div>
                  <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                    <CloudUploadTwoToneIcon className="d-50" />
                  </div>
                  <div className="font-size-sm">
                    Drag and drop your pdf files here
                  </div>
                </div>
              )}

              <small className="py-2 text-black-50">or</small>
              <div>
                <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                  <span className="px-2">Browse Files</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="font-weight-bold my-4 text-uppercase text-dark font-size-sm text-center">
            Uploaded File
          </div>
          {files.length <= 0 && (
            <div className="text-info text-center font-size-sm">
              Uploaded files will appear here!
            </div>
          )}
          {files.length > 0 && (
            <div>
              {/* <Alert severity="success" className="text-center mb-3">
                You have uploaded <b>{files.length}</b> files!
              </Alert> */}
              <List component="div" className="font-size-sm">
                {files}
              </List>
            </div>
          )}
        </div>
      </Card>
    </>
  )
}

export default graphql(GET_SIGNED_REQUEST, {
  name: 'getSignedRequest',
})(withSession(UploadProformaFileUploaderComponent))
