/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: TJ Felman
 * @ Modified time: 2022-03-21 13:24:17
 * @ Description: Dialog containing ways too get more bonus properties by sharing links
 */

import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Card,
  Dialog,
  Fab,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material'

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import hero1 from '@/assets/images/hero-bg/hero-1.jpg'

import isEmpty from 'lodash/isEmpty'

import { enqueueSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import { CREATE_SESSION } from '@/housefolios-components/LaunchpadComponents/mutations'
import withSession from '@/housefolios-components/Session/withSession'

function BonusPropertiesComponent(props) {
  const hostname = import.meta.env.VITE_HOSTNAME

  const orgLink =
    hostname === 'localhost'
      ? 'http://localhost:3000/createAccount' +
        '/?org=' +
        props.session.me.activeOrg._id
      : hostname === 'dev-admin.housefolios.com'
      ? 'https://dev-admin.housefolios.com/createAccount' +
        '/?org=' +
        props.session.me.activeOrg._id
      : 'https://app.housefolios.com/createAccount/?org=' +
        props.session.me.activeOrg._id

  const encodedOrgLink = encodeURIComponent(
    'https://app.housefolios.com/createAccount?org=' +
      props.session.me.activeOrg._id,
  )

  const { launchpad, sidebarMenu, upsell } = props

  const [modal1, seModal1] = useState(false)

  const toggle1 = () => seModal1(!modal1)

  const [createBillingPortalSession] = useMutation(CREATE_SESSION)

  const changePlan = async () => {
    var session = await createBillingPortalSession()

    window.open(
      session.data.createBillingPortalSession.url,
      '_self',
      '',
    )
  }

  return (
    <>
      {launchpad && (
        <Button
          onClick={toggle1}
          fullWidth
          className="btn-neutral-dark font-weight-bold text-uppercase font-size-sm hover-scale-sm"
        >
          Get Bonus Properties
        </Button>
      )}
      {sidebarMenu && (
        <Button
          onClick={toggle1}
          size="small"
          className="btn-userbox"
          fullWidth={true}
        >
          Get More Properties
        </Button>
      )}
      {upsell && (
        <Button
          onClick={toggle1}
          variant="contained"
          style={{ backgroundColor: '#f58747', color: 'white' }}
        >
          Get Bonus Properties
        </Button>
      )}
      <Dialog
        scroll="body"
        maxWidth="lg"
        open={modal1}
        onClose={toggle1}
        // classes={{
        //   paper: 'modal-content rounded border-0 bg-white p-3 p-xl-0'
        // }}
      >
        <Grid container spacing={0}>
          <Grid container item xs={12}>
            <Grid item xs={12} md={5}>
              <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 br-xl-right-0">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div
                    className="bg-composed-wrapper--image br-xl-right-0"
                    style={{ backgroundImage: 'url(' + hero1 + ')' }}
                  />
                  <div className="bg-composed-wrapper--bg bg-second opacity-7 br-xl-right-0" />
                  <div className="bg-composed-wrapper--content justify-content-start justify-content-xl-start text-center text-xl-left p-5">
                    <div className="text-white">
                      <h1 className="display-4 my-3 font-weight-bold">
                        Upgrade your plan today
                      </h1>
                      <p className="font-size-lg mb-0 text-white-50">
                        Upgrade your plan and get more properties
                        every month!
                      </p>
                      <div className="text-center">
                        <Button
                          variant="contained"
                          target="_blank"
                          color="primary"
                          style={{ color: 'white' }}
                          onClick={
                            isEmpty(
                              props.session.me.activeOrg
                                .subscriptions,
                            )
                              ? props.toggleSubscribe
                              : changePlan
                          }
                          className="font-weight-bold px-4 my-3 w-100"
                        >
                          Upgrade Plan
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={7}>
              <div className="mt-4 mt-xl-0">
                <div className="m-4">
                  <h1 className="display-4 my-3 font-weight-bold">
                    Give 10, Get 10
                  </h1>
                  <p className="font-size-md mb-0 text-black-50">
                    Share your link, whenever somebody signs up, both
                    you and them will get 10 BONUS properties!
                  </p>
                </div>
                <Card className="shadow-none bg-transparent p-4 border-0">
                  <div className="card-header d-block p-3 pt-0 rounded bg-light">
                    <div className="text-muted text-center mb-3">
                      <small>Click below to share!</small>
                    </div>
                    <div className="text-center">
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        wrap="nowrap"
                      >
                        <Grid item>
                          <Button
                            className="btn-facebook mr-2"
                            target="_blank"
                            href={
                              'https://www.facebook.com/sharer/sharer.php?u=' +
                              encodedOrgLink
                            }
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon
                                icon={['fab', 'facebook']}
                              />
                            </span>
                            <span className="btn-wrapper--label">
                              Facebook
                            </span>
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            className="btn-twitter ml-2"
                            href={
                              'https://twitter.com/intent/tweet?text=' +
                              encodedOrgLink +
                              'I%27ve%20been%20using%20Housefolios%20real%20estate%20software%20to%20analyze%20investment%20properties.%20Using%20this%20link%20will%20let%20you%20analyze%2010%20properties%20(and%20give%20me%2010%20more%20properties!)'
                            }
                            target="_blank"
                          >
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon
                                icon={['fab', 'twitter']}
                              />
                            </span>
                            <span className="btn-wrapper--label">
                              Twitter
                            </span>
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div>
                    <div className="text-center text-muted my-3">
                      <small>Or use your unique link below!</small>
                    </div>
                    <div className="mb-3">
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="textfield-email"
                        value={orgLink}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CopyToClipboard
                                text={orgLink}
                                onCopy={() =>
                                  enqueueSnackbar(
                                    `Copied to Clipboard`,
                                    {
                                      variant: 'success',
                                      autoHideDuration: 3000,
                                    },
                                  )
                                }
                              >
                                <Fab size="small" color="primary">
                                  <FileCopyOutlinedIcon
                                    style={{ color: 'white' }}
                                  />
                                </Fab>
                              </CopyToClipboard>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="text-center">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: 'white' }}
                        onClick={toggle1}
                        className="font-weight-bold px-4 my-3"
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default withSession(BonusPropertiesComponent)
