/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-29 10:22:56
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-08-01 15:32:07
 * @ Description: Dialog for adding a new marketplace
 */

import React, { useState } from 'react'
import map from 'lodash/map'
import concat from 'lodash/concat'
import head from 'lodash/head'
import get from 'lodash/get'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Link,
  ListItem,
  Typography,
} from '@mui/material'

import withSession from '@/housefolios-components/Session/withSession'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { useMutation } from '@apollo/client'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { enqueueSnackbar } from 'notistack'
import { Add } from '@mui/icons-material'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { END_TRIAL } from '@/housefolios-components/zComponents/mutations'
import useAffiliate from '@/hooks/useAffiliate'

function AddMarketplaceComponent(props) {
  const { mainNav, marketplaceSettings, title } = props
  const [openAddMarketplace, setAddMarketplace] = useState(false)
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [meetingUrl, setMeetingUrl] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [googleAnalytics, setGoogleAnalytics] = useState('')
  const [marketplaceVideoURL, setMarketplaceVideoURL] = useState('')
  const [preApprovedURL, setPreApprovedURL] = useState('')

  const [openEndTrial, setOpenEndTrial] = useState(false)

  const [endTrial] = useMutation(END_TRIAL)

  const handleOpenAddMarketplace = () => {
    setAddMarketplace(true)
    setName('')
    setUrl('')
    setMeetingUrl('')
    setContactEmail('')
    setGoogleAnalytics('')
    setMarketplaceVideoURL('')
    setPreApprovedURL('')
  }
  const handleCloseAddMarketplace = () => {
    setAddMarketplace(false)
    setLoader(false)
  }

  const [{
    affiliate,
    urlAffiliate,
  }]=useAffiliate({})

  const { marketPlaces = [], _id } = props.session.me.activeOrg

  const newMarketPlaces = map(
    concat(marketPlaces, [
      {
        name: `${name}`,
        url: `${url}.housefolios.com`,
        meetingURL: meetingUrl,
        contactEmail: contactEmail,
        googleAnalytics: googleAnalytics,
        marketplaceVideoURL: marketplaceVideoURL,
        preApprovedURL: preApprovedURL,
        status: 'pending',
      },
    ]),
    (marketPlace) => {
      return {
        name: marketPlace.name,
        url: marketPlace.url.toLowerCase(),
        meetingURL: marketPlace.meetingURL,
        contactEmail: marketPlace.contactEmail,
        googleAnalytics: marketPlace.googleAnalytics,
        marketplaceVideoURL: marketPlace.marketplaceVideoURL,
        preApprovedURL: marketPlace.preApprovedURL,
        status: marketPlace.status,
      }
    },
  )

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const inputFields = [
    {
      value: name,
      label: 'Marketplace Name',
      placeholder: 'Marketplace Name',
      set: setName,
      required: true,
      autoFocus: true,
      validators: ['required', 'maxStringLength: 50'],
      errorMessages: [
        'This field is required',
        'You cannot exceed 50 characters',
      ],
    },
    {
      value: url,
      label: 'Marketplace URL',
      placeholder: 'Marketplace URL',
      set: setUrl,
      helperText:
        'An external URL that displays your marketplace properties',
      required: true,
      validators: [
        'required',
        'maxStringLength: 50',
        'matchRegexp:^[A-z0-9]+$',
      ],
      errorMessages: [
        'This field is required',
        'You cannot exceed 50 characters',
        'This field only allows alphanumeric characters',
      ],
      endAdornment: {
        endAdornment: (
          <InputAdornment position="end">
            .housefolios.com
          </InputAdornment>
        ),
      },
    },
    {
      value: meetingUrl,
      label: 'Meeting URL',
      placeholder: 'Meeting URL',
      set: setMeetingUrl,
      helperText:
        '(optional) /Two popular options are Calendly and Hubspot',
      required: false,
      validators: ['maxStringLength: 100', 'matchRegexp:^[^ ]+$'],
      errorMessages: [
        'You cannot exceed 100 characters',
        'This field does not accept spaces',
      ],
    },
    {
      value: contactEmail,
      label: 'Contact Email',
      placeholder: 'Contact Email',
      set: setContactEmail,
      helpertText:
        'Who do you want contacted when somebody is interested?',
      required: true,
      validators: ['required', 'isEmail'],
      errorMessages: ['This field is required', 'Not a valid Email'],
    },
    {
      value: googleAnalytics,
      label: 'Google Analytics',
      placeholder: 'Google Analytics',
      set: setGoogleAnalytics,
      helperText: 'A URL to send analytic information to.',
      required: false,
      validators: ['maxStringLength: 100', 'matchRegexp:^[^ ]+$'],
      errorMessages: [
        'You cannot exceed 100 characters',
        'This field does not accept spaces',
      ],
    },
    {
      value: marketplaceVideoURL,
      label: 'Embedded Video URL',
      placeholder: 'Embedded Video URL',
      set: setMarketplaceVideoURL,
      helperText:
        'An embedded url of the video you want playing on your marketplace',
      required: false,
      validators: ['maxStringLength: 100', 'matchRegexp:^[^ ]+$'],
      errorMessages: [
        'You cannot exceed 100 characters',
        'This field does not accept spaces',
      ],
    },
    {
      value: preApprovedURL,
      label: 'Pre Approved URL',
      placeholder: 'Pre Approved URL',
      set: setPreApprovedURL,
      helperText:
        'Where you want users to go when they click Get Pre Approved on the marketplace',
      required: false,
      validators: ['maxStringLength: 100', 'matchRegexp:^[^ ]+$'],
      errorMessages: [
        'You cannot exceed 100 characters',
        'This field does not accept spaces',
      ],
    },
  ]

  const { limits = {} } = props.session.me
  const { marketplaceLimit } = limits

  const [loader, setLoader] = useState(false)

  const onTrial =
    (get(props.session.me.activeOrg, 'subscriptions.0.status') ===
    'trialing' && (!props.session.me.activeOrg.isRealeflow))

  return (
    <>
      {mainNav && (
        <>
          <ListItem
            component={Link}
            style={{
              textAlign: 'center',
              color: '#04a3e3',
              marginLeft: 8,
            }}
            onClick={
              marketPlaces.length >= marketplaceLimit
                ? () => props.toggleLimit('marketplace')
                : onTrial
                ? () => setOpenEndTrial(true)
                : handleOpenAddMarketplace
            }
            button
          >
            <Add style={{ marginRight: 10 }} />
            Add New Marketplace
          </ListItem>
        </>
      )}
      {marketplaceSettings && (
        <Button
          variant="contained"
          onClick={
            marketPlaces.length >= marketplaceLimit
              ? () => props.toggleLimit('marketplace')
              : onTrial
              ? () => setOpenEndTrial(true)
              : handleOpenAddMarketplace
          }
          size="small"
          style={{ backgroundColor: '#28b854' }}
          className="btn-primary d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3"
        >
          <Add style={{ color: 'white' }} />
          <span
            className="btn-wrapper--label"
            style={{ fontWeight: 600 }}
          >
            Create Marketplace
          </span>
        </Button>
      )}
      {title && (
        <Button
          variant="contained"
          className="btn-primary m-2 font-weight-bolder"
          onClick={
            marketPlaces.length >= marketplaceLimit
              ? () => props.toggleLimit('marketplace')
              : onTrial
              ? () => setOpenEndTrial(true)
              : handleOpenAddMarketplace
          }
        >
          <Add style={{ color: 'white' }} />
          <span
            className="btn-wrapper--label"
            style={{ fontWeight: 600 }}
          >
            Create Marketplace
          </span>
        </Button>
      )}

      <Dialog
        open={openAddMarketplace}
        onClose={handleCloseAddMarketplace}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>Add New Marketplace</DialogTitle>
        <ValidatorForm
          style={{
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={() => {
            setLoader(true)
            updateOrganization({
              variables: {
                id: _id,
                organizationInput: {
                  marketPlaces: newMarketPlaces,
                  //meetingUrl,
                },
              },
              refetchQueries: [
                {
                  query: GET_ME,
                },
              ],
            })
              .then(() => {
                enqueueSnackbar(`Marketplace Created`, {
                  variant: 'success',
                  autoHideDuration: 3000,
                })
                setLoader(false)
                handleCloseAddMarketplace()
              })
              .catch((error) => {
                enqueueSnackbar(error.message, {
                  variant: 'error',
                  autoHideDuration: 3000,
                })
                setLoader(false)
              })
          }}
        >
          <DialogContent>
            <BlockUi
              blocking={loader}
              loader={
                <PacmanLoader
                  color={'var(--primary)'}
                  loading={true}
                />
              }
              message={
                <div className="text-primary">
                  Creating Marketplace
                </div>
              }
            >
              <Grid container>
                <Grid item>
                  <Typography>
                    You get your own marketplace website with {affiliate?.companyName || 'Housefolios'}. Please choose your URL. You can not
                    change this later. The website will be setup
                    within 1 business day.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                {map(inputFields, (section) => (
                  <Grid key={section.label} item xs={12}>
                    <TextValidator
                      variant="outlined"
                      margin="dense"
                      fullWidth={true}
                      required={section.required}
                      autoFocus={section.autoFocus}
                      value={section.value}
                      onChange={(event) =>
                        section.set(event.target.value)
                      }
                      label={section.label}
                      placeholder={section.placeholder}
                      helperText={section.helperText}
                      InputProps={section.endAdornment}
                      validators={section.validators}
                      errorMessages={section.errorMessages}
                    />
                  </Grid>
                ))}
              </Grid>
            </BlockUi>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={handleCloseAddMarketplace}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>

      <Dialog
        open={openEndTrial}
        onClose={() => setOpenEndTrial(false)}
        maxWidth="sm"
        fullWidth={true}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={<div className="text-primary">Ending Trial</div>}
        >
          <DialogTitle>End the trial</DialogTitle>
          <DialogContent>
            Creating a new marketplace is currently available to users
            not in a trial period. Click below to the End Trial right
            now to be able to create a new marketplace. Ending your
            trial will charge your credit card
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEndTrial(false)}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setLoader(true)
                endTrial({
                  variables: {
                    id: head(props.session.me.activeOrg.subscriptions)
                      ? head(props.session.me.activeOrg.subscriptions)
                          .id
                      : null,
                  },
                }).finally(() => {
                  setOpenEndTrial(false)
                  setLoader(false)
                })
              }}
            >
              End Trial
            </Button>
          </DialogActions>
        </BlockUi>
      </Dialog>
    </>
  )
}

export default withSession(AddMarketplaceComponent)
