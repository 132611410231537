import { gql } from '@apollo/client'

export const ADD_TEAM_MEMBER = gql`
  mutation addTeamMember(
    $email: String!
    $profileInput: profileInput
    $role: String!
    $phone: String
    $userType: String
    $jobTitle: String
    $bioDescription: String
    $address: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $DoB: DateTime
    $citizenship: String
    $FICO: String
    $firstTimeInvestor: String
    $yearsInvesting: String
    $realEstateInvestments: String
    $estimatedNetWorth: String
    $estimatedLiquidAssets: String
    $creditReport: [JSON]
    $scheduleOfRealEstate: [JSON]
    $personalFinancialStatement: [JSON]
    $orgUserInput: orgUserInput!
  ) {
    addTeamMember(
      email: $email
      profileInput: $profileInput
      role: $role
      phone: $phone
      userType: $userType
      jobTitle: $jobTitle
      bioDescription: $bioDescription
      address: $address
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      DoB: $DoB
      citizenship: $citizenship
      FICO: $FICO
      firstTimeInvestor: $firstTimeInvestor
      yearsInvesting: $yearsInvesting
      realEstateInvestments: $realEstateInvestments
      estimatedNetWorth: $estimatedNetWorth
      estimatedLiquidAssets: $estimatedLiquidAssets
      creditReport: $creditReport
      scheduleOfRealEstate: $scheduleOfRealEstate
      personalFinancialStatement: $personalFinancialStatement
      orgUserInput: $orgUserInput
    ) {
      _id
      email
      createdAt
      lastLogin
      profile {
        firstname
        lastname
      }
      roles {
        organization {
          _id
          name
        }
        role
      }
      phones
      jobTitle
      bioDescription
    }
  }
`

export const RESEND_INVITE = gql`
  mutation addTeamMember($email: String!, $orgId: String) {
    resendInvite(email: $email, orgId: $orgId) {
      _id
      email
      createdAt
      lastLogin
      profile {
        firstname
        lastname
      }
      roles {
        organization {
          _id
          name
        }
        role
      }
      phones
    }
  }
`

export const UPDATE_ACTIVATION = gql`
  mutation updateActivation($id: ID!, $orgId: String!) {
    updateActivation(id: $id, orgId: $orgId) {
      user {
        _id
        email
        username
        profile {
          firstname
          lastname
        }
        roles {
          organization {
            _id
            name
          }
          role
        }
        userType
        jobTitle
        bioDescription
        activeOrg {
          _id
          name
        }
        setup
      }
      token
    }
  }
`

export const REMOVE_TEAM_MEMBER = gql`
  mutation removeTeamMember($id: ID!, $orgId: String!) {
    removeTeamMember(id: $id, orgId: $orgId) {
      user {
        _id
        email
        username
        profile {
          firstname
          lastname
        }
        roles {
          organization {
            _id
            name
          }
          role
        }
        userType
        jobTitle
        bioDescription
        activeOrg {
          _id
          name
        }
        setup
      }
      token
    }
  }
`
