import { gql } from '@apollo/client'

export const GET_ZILLOW_COMP = gql`
  query getZillowCompData(
    $zpid: String!
    $propertyInput: propertyInput
  ) {
    getZillowCompData(zpid: $zpid, propertyInput: $propertyInput) {
      zpid
      address
      city
      state
      zip
      year
      baths
      beds
      sqft
      files {
        url
        url_small
      }
      location {
        latitude
        longitude
      }
      zestimate
      rentZestimate
      taxAssessment
    }
  }
`

export const GET_REALEFLOW_COMPS = gql`
  query getRealeflowCompData(
    $propertyId: String!
    $filters: CompFilter!
  ) {
    getRealeflowCompData(propertyId: $propertyId, filters: $filters)
  }
`

export const GET_STATE_VALUE = gql`
  query stateValue($state: String!) {
    stateValue(state: $state) {
      cities
      counties
      zips
      state
    }
  }
`
