import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { Grid2 } from '@mui/material'
import withSession from '@/housefolios-components/Session/withSession'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Popper from '@mui/material/Popper'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import KanbanCard from '../Card'
import { useMemo, memo } from 'react'
import UpdateColumn from '../Dialogs/updateTextInput'
import DeleteColumn from '../Dialogs/deleteTextInput'
import { Delete, Edit } from '@mui/icons-material'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 'max-content',
  marginBottom: '12px',
  cursor: 'pointer', // Ensure the cursor indicates clickability
  display: 'flex',
}))

function Column({
  column,
  properties,
  updateLocalProperties = null,
  handleUpdateColumn,
  handleDeleteColumn,
  userId,
}) {
  // const propertiesIds = useMemo(
  //   () => properties?.map((property) => property?._id),
  //   [properties],
  // )
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column?._id,
    data: {
      type: 'Column',
      column,
    },
  })
  // console.log('Stage', column)
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  if (isDragging) {
    return (
      <Grid2
        item
        // key={column?.id}
        sx={{ width: 320, minWidth: 320 }}
        ref={setNodeRef}
        style={style}
      >
        <Paper
          sx={{
            minHeight: '85vh',
            padding: 2,
            background: 'transparent',
            borderColor: 'primary.main',
            border: 1,
          }}
        ></Paper>
      </Grid2>
    )
  }
  return (
    <Grid2
      item
      //   key={column?.id}
      sx={{ width: 320, minWidth: 320 }}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <Paper
        sx={{
          height: 'calc(85vh - 2px)', // Subtracting 2px for padding
          paddingX: 1,
          paddingY: 2,
          overflow: 'hidden', // Hide any content that overflows the paper
        }}
      >
        <Item>
          <Box
            id={`category-${column._id}`} // Ensure unique IDs for different columns
            sx={{
              fontSize: '12px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              justifyContent: 'right',
              alignContent: 'center',
              // flexBasis: '60%',
              flexGrow: 1,
            }}
            {...listeners}
          >
            {column.name}
          </Box>
          <UpdateColumn
            onSubmit={handleUpdateColumn}
            title={'Edit a Column'}
            columnId={column._id}
            columnName={column.name}
          >
            {({ onClick }) => (
              <IconButton size="small" onClick={onClick}>
                <Edit style={{ fontSize: 18 }} />
              </IconButton>
            )}
          </UpdateColumn>
          <DeleteColumn
            onSubmit={handleDeleteColumn}
            title={'Delete a Column'}
            columnId={column._id}
          >
            {({ onClick }) => (
              <IconButton onClick={onClick} size="small">
                <Delete style={{ fontSize: 18 }} />
              </IconButton>
            )}
          </DeleteColumn>
        </Item>
        <Box
          sx={{
            height: 'calc(100% - 40px)', // Adjust as needed, subtracting padding from total height
            overflowY: 'auto', // Ensure vertical scrolling when content overflows
            // '&::-webkit-scrollbar': {
            //   display: 'none', // Hide the scrollbar for WebKit browsers
            // },
            scrollbarWidth: 'none', // Hide the scrollbar for Firefox
          }}
        >
          <Stack
            spacing={1}
            sx={{
              paddingBottom: 2,
              paddingX: 1,
            }}
          >
            {/* {console.log(
              'map',
              properties?.ids,
              properties?.items?.map((props) => props),
            ) && <></>} */}
            <SortableContext items={properties?.ids ?? []}>
              {properties?.items?.map((property) => (
                <KanbanCard
                  key={property._id}
                  property={property}
                  updateLocalProperties={updateLocalProperties}
                  userId={userId}
                />
              ))}
            </SortableContext>
          </Stack>
        </Box>
      </Paper>
    </Grid2>
  )
}

export default memo(Column)
