import useAffiliate from "@/hooks/useAffiliate"

export const getProformaLink = (propertyId, proformaTypes, propertyDetails = false) => {
    const hostname = import.meta.env.VITE_HOSTNAME
  
    const dest = propertyDetails ? 'property-details' : 'property'
  
    const type = getProformaView(proformaTypes)

    const [{
      affiliate,
      affiliateLoading,
      urlDomain,
      urlAffiliate
    }]=useAffiliate({})
  
    const proformaLink =
      urlDomain
        ? 'https://' + urlDomain + '/' + dest + '/' + propertyId + '/proforma/' + type
        : hostname === 'localhost'
        ? 'http://localhost:3000/' + dest + '/' + propertyId + '/proforma/' + type
        : hostname === 'dev-admin.housefolios.com'
        ? 'https://dev-admin.housefolios.com/' + dest + '/' +
          propertyId +
          '/proforma/' +
          type
        : 'https://app.housefolios.com/' + dest + '/' +
          propertyId +
          '/proforma/' +
          type
  
    return proformaLink
  }
  
  export const getProformaView = (proformaTypes) => {
    let type =
      proformaTypes[0] === 'Astroflip' || proformaTypes[0] === 'astroflip'
      ? 'astroflip'
      : proformaTypes[0] === 'Wholesale' || proformaTypes[0] === 'wholesale'
      ? 'wholesale'
      : proformaTypes[0] === 'Turnkey End Buyer' || proformaTypes[0] === 'marketing'
      ? 'marketing'
      : proformaTypes[0] === 'Fix & Flip' || proformaTypes[0] === 'fixFlip'
      ? 'fixFlip'
      : proformaTypes[0] === 'Short Term Rental' || proformaTypes[0] === 'shortTermRental'
      ? 'shortTermRental'
      : 'buyHold'

  
      for (let i = 1; i < proformaTypes.length; i++) {
        type =
        proformaTypes[i] === 'Astroflip' || proformaTypes[0] === 'astroflip'
        ? 'astroflip'
        : proformaTypes[i] === 'Wholesale' || proformaTypes[0] === 'wholesale'
        ? 'wholesale'
        : proformaTypes[i] === 'Turnkey End Buyer' || proformaTypes[0] === 'marketing'
        ? 'marketing'
        : proformaTypes[i] === 'Fix & Flip' || proformaTypes[0] === 'fixFlip'
        ? 'fixFlip'
        : proformaTypes[i] === 'Short Term Rental' || proformaTypes[0] === 'shortTermRental'
        ? 'shortTermRental'
        : proformaTypes[i] === 'Buy & Hold' || proformaTypes[0] === 'buyHold'
        ? 'buyHold'
        : type
    }
  
    return type
  }