import { useQuery } from '@apollo/client'

import React, { Suspense, useState, useEffect } from 'react'
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
} from 'react-router-dom'
import {
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { AnimatePresence, motion } from 'framer-motion'
import MuiTheme from '@/theme'
import MuiTheme2 from '@/theme/theme2'
import SuspenseLoading from './SuspenseLoading'
import { useTour, FeedbackTour } from '@/housefolios-components/Tours'
import { PublicSidebar } from '@/layout-blueprints'
import PublicPropertyDashboardPage from '@/housefolios-pages/PublicPropertyDashboard'
import PublicMarketplacePage from '@/housefolios-pages/PublicMarketplace'
import PublicTeamPage from '@/housefolios-pages/PublicTeam'
import PublicHomePage from '@/housefolios-pages/PublicHome'
import head from 'lodash/head'
import { pageTransition, pageVariants } from '@/utils/animation'
import { GET_ORG_FROM_MARKETPLACE } from '@/housefolios-components/PublicProperty/queries'
import PdfContainer from '../PropertyContainer/Proforma/pdfContainer'
import Pdf from '../PropertyContainer/Proforma/pdf'
import ProformaRedirect from './ProformaRedirect'

function SubDomain({ subdomain }) {
  const {
    openFeedbackTour,
    setOpenAnalyzeAreaTour,
    setOpenAnalyzeCompsTour,
    setOpenChooseStrategyTour,
    setOpenFeedbackTour,
    setOpenTakeActionTour,
    setOpenTour,
    setOpenVerifyFinancialsTour,
  } = useTour()
  const navigate = useNavigate()
  const { loading, error, data } = useQuery(
    GET_ORG_FROM_MARKETPLACE,
    {
      variables: {
        url: `${subdomain}.housefolios.com`,
      },
    },
  )
  if (loading) return 'loading'
  if (error) return <p>Error: {error.message}</p>

  const { getOrgFromMarketplace = {} } = data || {}
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MuiTheme}>
        <CssBaseline />
        <AnimatePresence>
          <Suspense fallback={<SuspenseLoading />}>
            <Routes>
              <Route
                element={
                  <PublicSidebar
                    marketplace={
                      getOrgFromMarketplace
                        ? head(getOrgFromMarketplace.marketPlaces)
                        : null
                    }
                    orgName={
                      getOrgFromMarketplace
                        ? getOrgFromMarketplace.name
                        : ''
                    }
                  />
                }
              >
                <Route
                  path="/public-property-details/:id/:view"
                  element={
                    <PublicPropertyDashboardPage
                      orgName={
                        getOrgFromMarketplace
                          ? getOrgFromMarketplace.name
                          : ''
                      }
                      marketplace={
                        getOrgFromMarketplace
                          ? head(getOrgFromMarketplace.marketPlaces)
                          : null
                      }
                      setIsOpen={setOpenTour}
                      setIsOpenVerifyFinancialsTour={
                        setOpenVerifyFinancialsTour
                      }
                      setIsOpenAnalyzeCompsTour={
                        setOpenAnalyzeCompsTour
                      }
                      setIsOpenChooseStrategyTour={
                        setOpenChooseStrategyTour
                      }
                      setIsOpenAnalyzeAreaTour={
                        setOpenAnalyzeAreaTour
                      }
                      setIsOpenTakeActionTour={setOpenTakeActionTour}
                    />
                  }
                />

                <Route
                  path="/soldproperties"
                  element={
                    <PublicMarketplacePage
                      orgName={
                        getOrgFromMarketplace
                          ? getOrgFromMarketplace.name
                          : ''
                      }
                      marketplace={
                        getOrgFromMarketplace
                          ? head(getOrgFromMarketplace.marketPlaces)
                          : null
                      }
                      sold
                    />
                  }
                />
                <Route
                  path="/properties"
                  element={
                    <PublicMarketplacePage
                      orgName={
                        getOrgFromMarketplace
                          ? getOrgFromMarketplace.name
                          : ''
                      }
                      marketplace={
                        getOrgFromMarketplace
                          ? head(getOrgFromMarketplace.marketPlaces)
                          : null
                      }
                    />
                  }
                />
                <Route
                  path="/team"
                  element={
                    <PublicTeamPage
                      orgId={
                        getOrgFromMarketplace
                          ? getOrgFromMarketplace._id
                          : ''
                      }
                      orgName={
                        getOrgFromMarketplace
                          ? getOrgFromMarketplace.name
                          : ''
                      }
                      marketplace={
                        getOrgFromMarketplace
                          ? head(getOrgFromMarketplace.marketPlaces)
                          : null
                      }
                    />
                  }
                />

                <Route
                  path="/property/:id/proforma/:type"
                  element={<PdfContainer />}
                />

                <Route
                  path="/property-details/:id/proforma/:type"
                  element={<ProformaRedirect isLoggedIn={false} />}
                />

                <Route
                  path="/"
                  element={
                    <PublicHomePage
                      orgName={
                        getOrgFromMarketplace
                          ? getOrgFromMarketplace.name
                          : ''
                      }
                      marketplace={
                        getOrgFromMarketplace
                          ? head(getOrgFromMarketplace.marketPlaces)
                          : null
                      }
                    />
                  }
                />

                <Route path="/*" element={<Navigate to={'/'} />} />
              </Route>
            </Routes>
          </Suspense>
        </AnimatePresence>
        <FeedbackTour
          isOpenFeedbackTour={openFeedbackTour}
          closeFeedbackTour={() => setOpenFeedbackTour(false)}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default SubDomain
