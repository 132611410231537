import { gql } from '@apollo/client'

export const ADD_LOT = gql`
  mutation addLot($lotInput: lotInput!, $noDuplicates: Boolean) {
    addLot(lotInput: $lotInput, noDuplicates: $noDuplicates) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      strategy
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      portfolio {
        _id
        name
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const UPDATE_LOT = gql`
  mutation updateLot($id: ID!, $lotInput: lotInput!) {
    updateLot(id: $id, lotInput: $lotInput) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      strategy
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      owned
      appraisedValue
      portfolio {
        _id
        name
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const SAVE_LOT_PROCESSES = gql`
  mutation saveLotProcessesObject(
    $lotId: ID!
    $lotProcessesInput: lotProcessesInput!
  ) {
    saveLotProcessesObject(
      lotId: $lotId
      lotProcessesInput: $lotProcessesInput
    ) {
      _id
      lot
      build
      sale
    }
  }
`

export const DELETE_LOT = gql`
  mutation deleteLot($id: ID!) {
    deleteLot(id: $id) {
      count
    }
  }
`

export const DELETE_LOTS = gql`
  mutation deleteLots($ids: [ID!]) {
    deleteLots(ids: $ids) {
      count
    }
  }
`
