import React, { useEffect, useRef, useState } from 'react'

import { PageTitle } from '@/layout-components'
import { ExampleWrapperSeamless } from '@/layout-components'

import find from 'lodash/find'
import head from 'lodash/head'
import toLower from 'lodash/toLower'
import map from 'lodash/map'
import filter from 'lodash/filter'
import intersectionBy from 'lodash/intersectionBy'
import intersection from 'lodash/intersection'
import ceil from 'lodash/ceil'
import pull from 'lodash/pull'
import includes from 'lodash/includes'
import slice from 'lodash/slice'
import replace from 'lodash/replace'
import isEmpty from 'lodash/isEmpty'
import concat from 'lodash/concat'
import remove from 'lodash/remove'
import get from 'lodash/get'
import isString from 'lodash/isString'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import meanBy from 'lodash/meanBy'
import split from 'lodash/split'
import compact from 'lodash/compact'
import cloneDeep from 'lodash/cloneDeep'

import PropertySearchArea from '@/housefolios-components/PropertySearch/SearchArea'
import PropertySearchBar from '@/housefolios-components/PropertySearchBar'
import AddPropertyButton from '@/housefolios-components/PropertySearch/SearchArea'
import PropertyCard from '@/housefolios-components/Property/PropertyCard'
import PropertyList from '@/housefolios-components/Property/PropertyList'
import PropertiesNav from '@/housefolios-components/Properties/PropertiesNav'
import MoreVert from '@/housefolios-components/TitleButtons/MoreVert'
import {
  Backdrop,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  TextField,
} from '@mui/material'
import { Query } from '@apollo/client/react/components'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import Pagination from '@mui/material/Pagination'
import withSession from '@/housefolios-components/Session/withSession'
import Notification from '@/housefolios-components/Marketplace/BulkActionsNotification'
import AllPropertiesFiltered from '@/housefolios-components/Properties/AllPropertiesFiltered'
import { SearchTwoTone } from '@mui/icons-material'
import { decompressAndLoad } from '@/functions/lzma.js'
import useProperties from '@/hooks/useProperties'
import { useQuery } from '@apollo/client'

function PropertySearchPage(props) {
  const [results, setResults] = useState([])
  const [searchName, setSearchName] = useState(null)
  const [loadingResults, setLoadingResults] = useState(true)
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem('view') ? localStorage.getItem('view') : '1',
  )

  const [refreshListPage, setRefreshListPage] = useState(false)
  const [showMenu, setShowMenu] = useState(true)

  const { data, loading, error } = useQuery(GET_PORTFOLIOS)

  const selectedPortfolio = localStorage.getItem('portfolio')
    ? localStorage.getItem('portfolio')
    : ''

  let portfolioData = null
  let portfolios = []
  if (data) {
    portfolios = data?.portfolios
    portfolioData =
      find(data?.portfolios, { _id: selectedPortfolio }) ||
      head(data?.portfolios)
  }

  const [
    {
      selected,
      properties,
      count,
      currentPageProperties,
      ordering,
      marketplaceSelected,
      search,
      page,
      averagePerSqft,
    },
    {
      setSelected,
      setPage,
      setMarketplaceSelected,
      selectAll,
      selectProperty,
      getSearchResults,
      getFilteredProperties,
      setOrdering,
      selectMarketplaceProperty,
      handlePageChange,
    },
  ] = useProperties({
    selectedPortfolio,
    kind: 'search',
    searchResults: results,
    propertySearch: true,
    ...props,
  })

  const handleMenu = () => {
    setShowMenu(false)
  }

  const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY

  const getGoogleImages = async (property) => {
    const MAP_KEY = googleApiKey
    const lat = property.latitude
    const lng = property.longitude
    if (lat && lng) {
      var service = window.google
        ? new window.google.maps.StreetViewService()
        : 0
      let images = []
      if (service !== 0) {
        await new Promise((resolve, reject) => {
          service.getPanoramaByLocation(
            { lat: Number(lat), lng: Number(lng) },
            50,
            function (panoData) {
              if (panoData !== null) {
                var ManLatLng = panoData.location.latLng
                const heading =
                  window.google.maps.geometry.spherical.computeHeading(
                    ManLatLng,
                    new window.google.maps.LatLng(
                      Number(lat),
                      Number(lng),
                    ),
                  )
                images.push(
                  `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${lat},${lng}&heading=${heading}&key=${MAP_KEY}`,
                )
              }
              resolve()
            },
          )
        })
      }
      images.push(
        `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&size=400x400&markers=color:blue%7C${lat},${lng}&key=${MAP_KEY}`,
      )
      return images
    }
  }

  const handleResults = async (results, searchName) => {
    let searchResults = cloneDeep(results)
    await Promise.all(
      map(searchResults, async (property) => {
        if (property.image)
          property.image = replace(property.image, /&webp=true/g, '')
        //if (property.images && !property.marketplaces) property.images = replace(property.images, /&webp=true/g, '')
        if (
          isEmpty(property.images) &&
          !property.image &&
          !property.marketplaces
        )
          property.images = await getGoogleImages(property)
      }),
    )
    setResults(searchResults)
    setSearchName(searchName)
    //swaps boolean value to trigger list page reset.
    setRefreshListPage(!refreshListPage)
    setPage(1)
  }

  const reapiInputRef = useRef({
    mls_active: true,
    beds: {},
    baths: {},
    sqft: {},
    year: {},
    price: {},
    vacant: undefined,
    lien: undefined,
    cashBuyer: undefined,
    preForeclosure: undefined,
    auction: undefined,
    reo: undefined,
    highEquity: undefined,
    freeClear: undefined,
    mlsFailed: undefined,
    taxDelinquentYear: {},
    propertyType: undefined,
  })

  useEffect(() => {
    let compressedResults = localStorage.getItem('propertySearch')
    if (compressedResults && compressedResults !== 'null') {
      compressedResults = map(
        split(compressedResults, ','),
        (value) => Number(value),
      )
      decompressAndLoad()
        .then((results) => {
          setResults(results) // Use the result to set your application's state
        })
        .catch((error) => {
          console.error(
            'Failed to decompress and load results:',
            error,
          )
        })
    } else {
      setLoadingResults(false)
    }
  }, [])

  if (loadingResults) {
    return (
      <Backdrop
        open={loadingResults}
        style={{ color: '#fff', zIndex: 2000 }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignContent="center"
        >
          <Grid item>
            <CircularProgress
              color="inherit"
              style={{ marginLeft: 70 }}
            />
          </Grid>
          <Grid item>
            <Typography>Loading Previous Results</Typography>
          </Grid>
        </Grid>
      </Backdrop>
    )
  }

  return portfolioData ? (
    <>
      <PageTitle
        titleHeading="Property Search"
        titleDescription="Search for available listings in a particular area"
        icon={
          <SearchTwoTone fontSize="large" className="text-primary" />
        }
      >
        {(!props.session.me.activeOrg.isRealeflow) && (
          <AddPropertyButton title setResults={handleResults} />
        )}
        <MoreVert page="Property-Search" />
      </PageTitle>
      <ExampleWrapperSeamless>
        <PropertySearchBar
          setResults={handleResults}
          reapiInputRef={reapiInputRef}
        />
        {/* {results.length > 0 && ( */}
        <>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className="gridItem2" xs={12}>
              {!showMenu && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        style={{ marginRight: 100 }}
                        onClick={() => {
                          setShowMenu(!showMenu)
                        }}
                        size="small"
                        defaultChecked
                      />
                    }
                    label="Show Menu"
                  />
                </FormGroup>
              )}
            </Grid>
            <PropertiesNav
              handleMenu={handleMenu}
              showMenu={showMenu}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              search={search}
              getSearchResults={getSearchResults}
              selectAll={selectAll}
              selectProperty={selectProperty}
              selected={selected}
              properties={results}
              filteredProperties={properties}
              currentPageProperties={currentPageProperties}
              getFilteredProperties={getFilteredProperties}
              marketplace="propertySearch"
              propertySearch
              searchName={searchName}
              ordering={ordering}
              setOrdering={setOrdering}
            />
          </Grid>
        </>
        {/* )} */}
        {/* <PropertySearchArea topEnterCity /> */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={4}
          style={{ marginTop: 10 }}
        >
          {compact(properties).length > 0 ? (
            <>
              {activeTab === '2' && (
                <>
                  <Grid item xs={12}>
                    <PropertyList
                      properties={properties}
                      portfolio={portfolioData}
                      portfolios={portfolios}
                      selectedPortfolio={selectedPortfolio}
                      selected={concat(selected, marketplaceSelected)}
                      selectAll={selectAll}
                      selectProperty={selectProperty}
                      selectMarketplaceProperty={
                        selectMarketplaceProperty
                      }
                      handlePageChange={handlePageChange}
                      page={page}
                      marketplace="propertySearch"
                      propertySearch
                      propertySearchResetPage={refreshListPage}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      className="mb-spacing-6-x2"
                    >
                      {/* <Grid item>
                              <Typography>*** Blue Bar = Owned Property</Typography>
                            </Grid>
                            <Grid item>
                              <Typography>Green Dot = Marketplace Property</Typography>
                            </Grid> */}
                      <Grid item>
                        <Typography>
                          Grey Header = Fields in Column are Editable
                          for Properties you Edit Permission for
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {activeTab === '1' && (
                <>
                  <Grid container item xs={12} spacing={4}>
                    {map(currentPageProperties, (property) => (
                      <PropertyCard
                        property={property}
                        properties={properties}
                        selected={concat(
                          selected,
                          marketplaceSelected,
                        )}
                        portfolios={portfolios}
                        selectedPortfolio={selectedPortfolio}
                        selectProperty={selectProperty}
                        selectMarketplaceProperty={
                          selectMarketplaceProperty
                        }
                        marketplace="propertySearch"
                        propertySearch
                        averagePerSqft={averagePerSqft}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <div className="p-3 d-flex justify-content-center">
                      <Pagination
                        className="pagination-first"
                        size="small"
                        count={count}
                        page={page}
                        onChange={handlePageChange}
                        marketplace="propertySearch"
                      />
                    </div>
                  </Grid>
                </>
              )}
            </>
          ) : results.length > 0 ? (
            <Grid item xs={12}>
              <AllPropertiesFiltered />
            </Grid>
          ) : (
            <Grid item xs={12}>
              {/* <PropertySearchArea setResults={handleResults} /> */}
            </Grid>
          )}
          <Grid item xs={12}>
            <PropertySearchArea
              setResults={handleResults}
              reapiInputRef={reapiInputRef}
            />
          </Grid>
        </Grid>
        <Notification
          selected={selected}
          setSelected={setSelected}
          marketplaceSelected={marketplaceSelected}
          setMarketplaceSelected={setMarketplaceSelected}
          properties={properties}
          portfolios={portfolios}
          selectedPortfolio={selectedPortfolio}
          propertySearch
        />
      </ExampleWrapperSeamless>
    </>
  ) : (
    <></>
  )
}

export default withSession(PropertySearchPage)
