import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Button,
  Typography
} from '@mui/material';

const AmortizationDialog = ({
  open,
  onClose,
  tableHeaders,
  amortizationData,
  currencyFormat2,
  assumptions,
  showAdditionalPrincipal = true, // New prop with default value
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Amortization Schedule</DialogTitle>
      <DialogContent>
        <Paper style={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer style={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((row) => (
                    <TableCell align={row.align} key={row.name}>
                      {row.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {amortizationData.map((row) => (
                  <TableRow
                    key={row.name}
                    style={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.monthYear}
                    </TableCell>
                    <TableCell align="center">
                      {currencyFormat2.format(row.interest)}
                    </TableCell>
                    <TableCell align="center">
                      {currencyFormat2.format(row.principal)}
                    </TableCell>
                    {showAdditionalPrincipal && (
                      <TableCell align="center">
                        {currencyFormat2.format(row.additionalPrincipal)}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      {currencyFormat2.format(row.amtLeft)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Typography>
          These calculations were{' '}
          {!assumptions.payDownGrowth ? <b>not </b> : ''} made using
          growth rates
        </Typography>
        <Typography style={{ flex: 1 }} />
        <Button onClick={onClose}>
          <Typography>Close</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AmortizationDialog;
