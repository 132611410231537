import { gql } from '@apollo/client'

export const GET_AWAITING_REALEFLOW_PROPERTIES = gql`
  query getAwaitingRealeflowProperties {
    getAwaitingRealeflowProperties
  }
`
export const GET_UNPROCESSED_PROPERTY_LEADS = gql`
  query GetUnproccessedLeadsBatch($orgId: String) {
    getUnproccessedLeadsBatch(orgId: $orgId) {
      success
      error {
        message
      }
      batches {
        _id
        lock
        createdAt
        leads
      }
    }
  }
`
