import React, { useEffect, useMemo, useState } from 'react'
import map from 'lodash/map'
import slice from 'lodash/slice'
import filter from 'lodash/filter'
import ceil from 'lodash/ceil'
import intersection from 'lodash/intersection'
import intersectionBy from 'lodash/intersectionBy'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'
import pull from 'lodash/pull'
import get from 'lodash/get'
import split from 'lodash/split'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import orderBy from 'lodash/orderBy'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import remove from 'lodash/remove'
import isEmpty from 'lodash/isEmpty'
import meanBy from 'lodash/meanBy'

import {
  GET_LENDER_PROPERTIES,
  GET_SHARED_PROPERTIES,
  GET_PROPERTIES,
  GET_JV_PROPERTIES,
} from '@/housefolios-components/Properties/queries'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import {
  getSorting,
  stableSort,
} from '@/housefolios-components/Properties/sortTable'
import { useQuery } from '@apollo/client'
import { GET_TRASH } from '../housefolios-components/Properties/queries'
import TrashProperties from '@/housefolios-components/Properties/TrashProperties'
import { GET_LOTS } from '@/housefolios-components/Lots/queries'
import { GET_MARKETPLACES } from '@/housefolios-components/Marketplace/queries'

export const KindOfPage = Object.freeze({
  MY_PROPERTIES: 'my-properties',
  TRASH: 'trash',
  SEARCH: 'search',
  MARKETPLACE: 'marketplace',
  PUBLIC_MARKETPLACE: 'public-marketplace',
  LOTS: 'lots',
})

function useProperties(props) {
  const {
    isTrashedProperties = false,
    kind = KindOfPage.MY_PROPERTIES,
    marketplace,
    propertySearch = false,
    searchResults,
  } = props
  const selectedPortfolio = localStorage.getItem('portfolio')
    ? localStorage.getItem('portfolio')
    : ''
  const [selected, setSelected] = useState([])

  const [marketplaceSelected, setMarketplaceSelected] = useState([])
  const [search, setSearch] = useState('')
  const [filtered, setFiltered] = useState(false)
  const [filteredProperties, setFilteredProperties] = useState([])
  const [searchedProperties, setSearchedProperties] = useState([])
  const [ordering, setOrdering] = useState('')
  const [
    propertySearchOrderBy,
    marketOrderBy,
    defaultOrderBy,
    order,
  ] = split(ordering, '-')
  const [page, setPage] = useState(
    parseInt(
      localStorage.getItem(
        kind === KindOfPage.TRASH
          ? 'trash-page'
          : kind === KindOfPage.SEARCH
            ? 'propertySearch-page'
            : marketplace
              ? marketplace === 'null'
                ? `housefolios-marketplace-page`
                : `${marketplace}-marketplace-page`
              : selectedPortfolio === 'null' || !selectedPortfolio
                ? kind === KindOfPage.LOTS
                  ? 'myLots-page'
                  : 'myProperties-page'
                : `${selectedPortfolio}-portfolio-page`,
      ),
    ) || 1,
  )

  // Fetch Properties
  const {
    loading: loadingProperties,
    error: propertiesError,
    data: propertiesData,
    fetchMore: fetchMoreProperties,
  } = useQuery(
    props.jv
      ? GET_JV_PROPERTIES
      : props.lender
        ? GET_LENDER_PROPERTIES
        : props.shared
          ? GET_SHARED_PROPERTIES
          : GET_PROPERTIES,
    {
      variables: props.jv
        ? {
            jvLenders: [
              props.session.me.activeOrg.submittedProperties.jv,
            ],
          }
        : props.lender
          ? {
              lenders: [
                props.session.me.activeOrg.submittedProperties.lender,
              ],
            }
          : props.shared
            ? {}
            : selectedPortfolio !== 'null'
              ? {
                  portfolioId: [props?.portfolioData?._id],
                  limit: GET_PROPERTIES_QUERY_LIMIT,
                }
              : {
                  portfolioId: map(
                    props?.portfolios,
                    (portfolio) => portfolio._id,
                  ),
                  limit: GET_PROPERTIES_QUERY_LIMIT,
                },
      skip:
        !props?.portfoliosData && kind !== KindOfPage.MY_PROPERTIES,
      // onCompleted: (data) => {
      //   console.log('useQuery ran', data)
      // },
      // onError: (error) => {
      //   // console.log('useQuery error', error)
      // },
    },
  )

  // Fetch Trash Properties
  const {
    loading: loadingTrashedProperties,
    data: trashedPropertiesData,
    error: trashedPropertiesError,
  } = useQuery(GET_TRASH, {
    skip: kind !== KindOfPage.TRASH,
  })

  // Fetch lots
  const {
    loading: loadingLots,
    data: lotsData,
    error: lotsError,
  } = useQuery(GET_LOTS, {
    skip: kind !== KindOfPage.LOTS,
    variables:
      selectedPortfolio !== 'null'
        ? { portfolioId: props?.portfolioData?._id }
        : {
            portfolioId: map(
              props?.lotPortfolios,
              (portfolio) => portfolio?._id,
            ),
          },
  })

  // Fetch marketplace properties
  const {
    loading: loadingMarketPlaceData,
    error: marketPlaceError,
    data: marketPlaceData,
    fetchMore: fetchMoreMarketPlaceProperties,
  } = useQuery(
    GET_MARKETPLACES,
    kind === KindOfPage.MARKETPLACE
      ? {
          skip: kind !== KindOfPage.MARKETPLACE,
          variables: {
            marketplaces:
              marketplace !== 'null' && marketplace
                ? [marketplace]
                : props?.session?.me?.activeOrg?.member === 'RWN'
                  ? map(
                      props.session.me.activeOrg.marketPlaces,
                      (market) => market.url,
                    )
                  : ['housefolios', 'myGrayLabel'],
            approved: !props.submitted,
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        }
      : kind === KindOfPage.PUBLIC_MARKETPLACE
        ? {
            skip: kind !== KindOfPage.PUBLIC_MARKETPLACE,
            variables: {
              marketplaces:
                marketplace !== 'null' && marketplace
                  ? [marketplace]
                  : ['housefolios', 'myGrayLabel'],
              approved: !props.submitted,
              sold: props.sold ? true : false,
              limit: GET_PROPERTIES_QUERY_LIMIT,
            },
          }
        : {
            skip: true,
          },
  )

  // Function to get search results based on input string
  const getSearchResults = (string) => {
    // Check if the search string is the same as the current search
    if (search === string) return null
    // Split the search string into words and create regex filters
    const words = split(string, ' ')
    const searchFilters = map(
      words,
      (word) => new RegExp(toLower(word)),
    )
    // Filter properties based on search criteria

    const filtered = filter(
      kind === KindOfPage.LOTS
        ? lotsData.lots
        : kind === KindOfPage.TRASH
          ? trashedPropertiesData?.trashedProperties
          : kind === KindOfPage.SEARCH
            ? searchResults
            : kind === KindOfPage.MARKETPLACE ||
                kind === KindOfPage.PUBLIC_MARKETPLACE
              ? marketPlaceData?.marketplaceProperties?.properties
              : props.jv
                ? propertiesData?.jvProperties
                : props.lender
                  ? propertiesData?.lenderProperties
                  : props.shared
                    ? propertiesData?.sharedProperties
                    : propertiesData.properties.properties,

      (property) => {
        const { createdBy } = property
        const profile = createdBy
          ? createdBy.profile
          : { firstname: '', lastname: '' }
        return every(
          searchFilters,
          (searchFilter) =>
            searchFilter.test(toLower(property.address)) ||
            searchFilter.test(toLower(property.city)) ||
            searchFilter.test(toLower(property.state)) ||
            searchFilter.test(toLower(property.zip)) ||
            searchFilter.test(toLower(property.sqft)) ||
            searchFilter.test(toLower(property.description)) ||
            searchFilter.test(toLower(profile.firstname)) ||
            searchFilter.test(toLower(profile.lastname)),
        )
      },
    )
    // Update search state and set searched properties
    setSearch(string)
    setSearchedProperties(filtered)
  }

  // Function to filter properties based on a specific criteria
  const getFilteredProperties = (filtered) => {
    setFilteredProperties(filtered)
    setFiltered(true)
  }

  // Logic to order and filter properties based on search and filter criteria
  let properties =
    kind === KindOfPage.LOTS
      ? orderBy(
          search && filtered
            ? intersectionBy(
                searchedProperties,
                filteredProperties,
                '_id',
              )
            : search
              ? searchedProperties
              : filtered && filteredProperties
                ? filteredProperties
                : lotsData?.lots || [],
          'createdAt',
          'desc',
        )
      : kind === KindOfPage.TRASH
        ? orderBy(
            search && filtered
              ? intersectionBy(
                  searchedProperties,
                  filteredProperties,
                  '_id',
                )
              : search
                ? searchedProperties
                : filtered && filteredProperties
                  ? filteredProperties
                  : trashedPropertiesData?.trashedProperties || [],
            'deletedAt',
            'desc',
          )
        : [
              KindOfPage.MARKETPLACE,
              KindOfPage.PUBLIC_MARKETPLACE,
            ].includes(kind)
          ? orderBy(
              search && filtered
                ? intersectionBy(
                    searchedProperties,
                    filteredProperties,
                    '_id',
                  )
                : search
                  ? searchedProperties
                  : filtered && filteredProperties
                    ? filteredProperties
                    : marketPlaceData?.marketplaceProperties
                        ?.properties || [],
              'marketplaces.expirationDate',
              'desc',
            )
          : orderBy(
              search && filtered
                ? intersectionBy(
                    searchedProperties,
                    filteredProperties,
                    '_id',
                  )
                : search
                  ? searchedProperties
                  : filtered && filteredProperties
                    ? filteredProperties
                    : props.jv
                      ? propertiesData?.jvProperties
                      : props.lender
                        ? propertiesData?.lenderProperties || []
                        : props.shared
                          ? propertiesData?.sharedProperties || []
                          : propertySearch
                            ? searchResults || []
                            : propertiesData?.properties
                                ?.properties || [],
              'createdAt',
              'desc',
            )

  if (kind === KindOfPage.PUBLIC_MARKETPLACE) {
    if (!marketplace) {
      properties = filter(
        properties,
        (property) =>
          new Date(property.marketplaces.expirationDate).getTime() >
          Date.now(),
      )
    }
    properties = props.sold
      ? filter(
          properties,
          (property) => toLower(property.status) === 'sold',
        )
      : filter(
          properties,
          (property) => toLower(property.status) !== 'sold',
        )
  }

  // Calculate the number of pages based on number of properties
  const count = ceil(properties.length / 24)

  useEffect(() => {
    if (page > count) setPage(count || 1)
  }, [])

  useEffect(() => {
    setSelected([])
    setMarketplaceSelected([])
    setSearch('')
    switch (kind) {
      case KindOfPage.MY_PROPERTIES:
        // console.log('useEffect run')
        setPage(
          parseInt(
            localStorage.getItem(
              selectedPortfolio === 'null' || !selectedPortfolio
                ? 'myProperties-page'
                : `${selectedPortfolio}-portfolio-page`,
            ),
          ) || 1,
        )
        break
      case KindOfPage.MARKETPLACE:
      case KindOfPage.PUBLIC_MARKETPLACE:
        setPage(
          parseInt(
            localStorage.getItem(
              marketplace === 'null' || !marketplace
                ? 'housefolios-marketplace-card-page'
                : `${marketplace}-marketplace-card-page`,
            ),
          ) || 1,
        )
        break
      default:
        break
    }
  }, [selectedPortfolio, marketplace])

  // Function to select a property by its ID
  const selectProperty = (id) => (event) => {
    event.preventDefault()
    let newSelect = [...selected]
    if (includes(newSelect, id)) pull(newSelect, id)
    else newSelect.push(id)
    setSelected(newSelect)
  }

  // Get a list of properties based on current page
  const currentPageProperties = useMemo(() => {
    return slice(
      stableSort(
        compact(properties),
        getSorting(
          order,
          kind === KindOfPage.SEARCH
            ? propertySearchOrderBy
            : defaultOrderBy,
          marketOrderBy,
        ),
      ),
      (page - 1) * 24,
      page * 24,
    )
  }, [
    page,
    properties,
    defaultOrderBy,
    marketOrderBy,
    propertySearchOrderBy,
  ])

  // Function to select all properties for more operations
  const selectAll = (onCurrentPageOnly = true) => {
    switch (kind) {
      case KindOfPage.MY_PROPERTIES:
        return selectAllSharedLogic(
          onCurrentPageOnly,
          currentPageProperties,
          properties,
          selected,
          marketplaceSelected,
          setSelected,
          setMarketplaceSelected,
          props,
          propertySearch,
        )
      case KindOfPage.MARKETPLACE:
      case KindOfPage.PUBLIC_MARKETPLACE:
        return selectAllSharedLogic(
          onCurrentPageOnly,
          currentPageProperties,
          properties,
          selected,
          marketplaceSelected,
          setSelected,
          setMarketplaceSelected,
          props,
          propertySearch,
        )
      case KindOfPage.TRASH:
        return () => {
          const compare = intersection(
            map(
              onCurrentPageOnly ? currentPageProperties : properties,
              (property) => property._id,
            ),
            selected,
          )
          if (
            compare.length <
            (onCurrentPageOnly
              ? currentPageProperties.length
              : properties.length)
          )
            setSelected(
              map(
                onCurrentPageOnly
                  ? currentPageProperties
                  : properties,
                (property) => property._id,
              ),
            )
          else setSelected([])
        }
      case KindOfPage.SEARCH:
        return () => {
          let unownedProperties = filter(
            onCurrentPageOnly ? currentPageProperties : properties,
            (property) =>
              get(property, 'portfolio.organization._id') !==
              props.session.me.activeOrg._id,
          )
          const compare = intersection(
            map(unownedProperties, (property) => property._id),
            concat(selected, marketplaceSelected),
          )
          if (compare.length < unownedProperties.length) {
            let search = [...unownedProperties]
            const market = remove(
              search,
              (property) =>
                !isEmpty(
                  get(property, 'marketplaces.approvedMarketplace'),
                ),
            )
            setSelected(map(search, (property) => property._id))
            setMarketplaceSelected(
              map(market, (property) => property._id),
            )
          } else {
            setSelected([])
            setMarketplaceSelected([])
          }
        }
      case KindOfPage.LOTS:
        return () => {
          const compare = intersection(
            map(
              onCurrentPageOnly ? currentPageProperties : properties,
              (property) => property._id,
            ),
            selected,
          )
          if (
            compare.length <
            (onCurrentPageOnly
              ? currentPageProperties.length
              : properties.length)
          )
            setSelected(
              map(
                onCurrentPageOnly
                  ? currentPageProperties
                  : properties,
                (property) => property._id,
              ),
            )
          else setSelected([])
        }
      default:
        return () => null
        break
    }
  }
  /**
   * Handles shared logic for selecting all properties based on certain conditions.
   * This function can be used across different kinds of pages or scenarios where similar
   * selection logic is required.
   *
   * @param {boolean} onCurrentPageOnly - Determines if the operation should be restricted to the current page.
   * @param {Array} currentPageProperties - Array of property objects visible on the current page.
   * @param {Array} properties - Array of all property objects relevant to the operation.
   * @param {Array} selected - Currently selected property IDs.
   * @param {Array} marketplaceSelected - Currently selected marketplace property IDs.
   * @param {function} setSelected - Function to update the state of selected property IDs.
   * @param {function} setMarketplaceSelected - Function to update the state of selected marketplace property IDs.
   * @param {Object} props - Component props, expected to contain session information.
   * @param {boolean} propertySearch - Indicates if a property search is active.
   * @return {Function} A function that when executed will apply the selection logic.
   */
  function selectAllSharedLogic(
    onCurrentPageOnly,
    currentPageProperties,
    properties,
    selected,
    marketplaceSelected,
    setSelected,
    setMarketplaceSelected,
    props,
    propertySearch,
  ) {
    return () => {
      // Determine the overlap between currently visible/total properties and those selected.
      const compare = intersection(
        map(
          onCurrentPageOnly ? currentPageProperties : properties,
          (property) => property._id,
        ),
        concat(selected, marketplaceSelected),
      )

      // Check if not all properties are selected.
      if (
        compare.length <
        (onCurrentPageOnly
          ? currentPageProperties.length
          : properties.length)
      ) {
        // Collect all relevant properties.
        let ownedProperties = [
          ...(onCurrentPageOnly ? currentPageProperties : properties),
        ]
        // Filter out properties based on ownership criteria.
        let unownedProperties = remove(
          ownedProperties,
          (property) =>
            !(
              isNil(get(property, 'portfolio.organization._id')) &&
              !propertySearch
            ) &&
            get(property.node, 'portfolio.organization._id') !==
              props.session.me.activeOrg._id,
        )
        // Update the selected properties IDs.
        setSelected(map(ownedProperties, (property) => property._id))
        // Update the marketplace selected properties IDs.
        setMarketplaceSelected(
          map(unownedProperties, (property) => property._id),
        )
      } else {
        // If all properties are already selected, clear all selections.
        setSelected([])
        setMarketplaceSelected([])
      }
    }
  }

  const selectMarketplaceProperty = (id) => (event) => {
    event.preventDefault()
    let newSelect = [...marketplaceSelected]
    if (includes(newSelect, id)) pull(newSelect, id)
    else newSelect.push(id)
    setMarketplaceSelected(newSelect)
  }

  const propertyValues = map(properties, (property) => {
    return {
      _id: property._id,
      propertyPrice: Number(
        property.price ||
          get(property, 'acquisition.analysis.resellPrice'),
      ),
      propertyPerSqft: property.sqft
        ? Number(
            property.price ||
              get(property, 'acquisition.analysis.resellPrice'),
          ) / property.sqft || 0
        : null,
      sqft: property.sqft,
    }
  })

  const averagePerSqft = meanBy(
    filter(propertyValues, 'propertyPerSqft'),
    'propertyPerSqft',
  )

  const handlePageChange = (event, value) => {
    switch (kind) {
      case KindOfPage.MY_PROPERTIES:
        if (selectedPortfolio === 'null' || !selectedPortfolio)
          localStorage.setItem('myProperties-page', value)
        else
          localStorage.setItem(
            `${selectedPortfolio}-portfolio-page`,
            value,
          )
        setPage(value || 1)
        break
      case KindOfPage.TRASH:
        localStorage.setItem(`trash-page`, value)
        setPage(value)
        break
      case KindOfPage.SEARCH:
        localStorage.setItem('propertySearch-page', value)
        setPage(value)
        break
      case KindOfPage.MARKETPLACE:
      case KindOfPage.PUBLIC_MARKETPLACE:
        localStorage.setItem(
          marketplace
            ? marketplace === 'null'
              ? 'housefolios-marketplace-page'
              : `${marketplace}-marketplace-page`
            : 'housefolios-marketplace-page',
          value,
        )
        setPage(value)
        break
      case KindOfPage.LOTS:
        localStorage.setItem('myLots-page', value)
        setPage(value)
        break
      default:
        break
    }
  }

  const handleRequestSort = (
    event,
    property,
    market,
    propertySearch,
  ) => {
    const isDesc = defaultOrderBy === property && order === 'asc'
    const newOrder = isDesc ? 'desc' : 'asc'
    const orderBy = property
    const marketOrderBy = market ? market : property
    const propertySearchOrderBy = propertySearch
      ? propertySearch
      : property
    const newOrdering =
      propertySearchOrderBy +
      '-' +
      marketOrderBy +
      '-' +
      orderBy +
      '-' +
      newOrder
    setOrdering(newOrdering)
  }

  return [
    {
      selected,
      filtered,
      filteredProperties,
      searchedProperties,
      loadingProperties,
      propertiesError,
      propertiesData,
      properties,
      loadingMarketPlaceData,
      marketPlaceError,
      marketPlaceData,
      count,
      currentPageProperties,
      ordering,
      averagePerSqft,
      trashedProperties: trashedPropertiesData?.trashedProperties,
      trashedPropertiesError,
      loadingTrashedProperties,
      selectedPortfolio,
      marketplaceSelected,
      search,
      page,
      loadingLots,
      lotsData,
      lotsError,
    },
    {
      setSelected,
      setPage,
      setSearch,
      setMarketplaceSelected,
      fetchMoreProperties:
        kind === KindOfPage.MARKETPLACE ||
        kind === KindOfPage.PUBLIC_MARKETPLACE
          ? fetchMoreMarketPlaceProperties
          : fetchMoreProperties,
      setFiltered,
      setFilteredProperties,
      setSearchedProperties,
      selectAll,
      selectProperty,
      getSearchResults,
      getFilteredProperties,
      setOrdering,
      selectMarketplaceProperty,
      handlePageChange,
      handleRequestSort,
    },
  ]
}

export default useProperties
