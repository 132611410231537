/**
 * @ Author: Housefolios
 * @ Create Time: 2023-06-29 18:19:55
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-01-30 15:51:36
 * @ Description: Dialog to skipTrace properties
 */

import React, { useEffect, useState } from 'react'

import map from 'lodash/map'
import find from 'lodash/find'
import concat from 'lodash/concat'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_PORTFOLIOS } from '../../Portfolio/queries'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import withSession from '@/housefolios-components/Session/withSession'
import {
  CALL_BULK_REAPI_SKIPTRACE,
  CALL_REAPI_SKIPTRACE,
} from '@/housefolios-components/PropertySearch/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from '@/housefolios-components/CreateAccount/PaymentForm'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { BUY_SKIP_TRACE_CREDITS } from '../mutations'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import StripeOneTimePurchase from '../StripeOneTimePurchase/'

function SkipTraceComponent(props) {
  const { bulk, sidebarMenu, portfolioId, setSelected } = props
  const [openSkipTrace, setSkipTrace] = useState(false)
  const [openNotEnoughCredits, setOpenNotEnoughCredits] =
    useState(false)
  const [openBuyCredits, setOpenBuyCredits] = useState(false)
  const [buyCreditsAmount, setBuyCreditsAmount] = useState(25)

  const [skipDups, setSkipDups] = useState(bulk)

  const [callProperties, propertyResults] = useLazyQuery(
    GET_PROPERTIES,
    {
      variables: { portfolioId: portfolioId },
    },
  )
  const data = propertyResults.data
  const loading = propertyResults.loading || !propertyResults.called
  const error = propertyResults.error

  let { properties = [] } = data || {}
  if (isEmpty(properties)) properties = props.properties || []

  const filteredProperties = filter(properties, (property) =>
    isEmpty(property.skipTraceResults),
  )
  const usedProperties = skipDups ? filteredProperties : properties

  const [token, setToken] = useState('')
  const [recharge, setRecharge] = useState(false)
  const [resetToken, setResetToken] = useState(() => {
    return null
  })

  const [loader, setLoader] = useState(false)

  const handleOpenSkipTrace = () => {
    setSkipTrace(true)
  }
  const handleCloseSkipTrace = () => {
    setSkipTrace(false)
    setSkipDups(bulk)
    setLoader(false)
  }

  const handleOpenNotEnoughCredits = () => {
    setOpenNotEnoughCredits(true)
  }
  const handleCloseNotEnoughCredits = () => {
    setOpenNotEnoughCredits(false)
    setLoader(false)
  }

  const handleOpenBuyCredits = () => {
    handleCloseSkipTrace()
    setOpenNotEnoughCredits(false)
    setOpenBuyCredits(true)
    setBuyCreditsAmount(25)
  }
  const handleCloseBuyCredits = () => {
    setOpenBuyCredits(false)
    setLoader(false)
  }

  useEffect(() => {
    if (!loading) {
      setLoader(false)
      if (remainingCredits >= 0) {
        handleOpenSkipTrace()
      } else {
        handleOpenNotEnoughCredits()
      }
    }
  }, [loading])

  const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY
  const stripePromise = loadStripe(stripePublicKey)

  const handleRechargeToggle = () => {
    setRecharge(!recharge)
    setToken('')
    resetToken()
  }

  const createToken = async (stripe, cardElement, nameOnCard) => {
    if (!nameOnCard) setToken('')
    else {
      await stripe
        .createToken(cardElement, {
          name: nameOnCard,
        })
        .then((res) => {
          var errorElement = document.getElementById('card-errors')
          if (res.error) {
            setToken('')
            if (errorElement)
              errorElement.textContent = res.error.message
          } else {
            setToken(res.token)
            if (errorElement) errorElement.textContent = null
          }
        })
    }
  }

  const [callREAPISkipTrace] = useMutation(CALL_REAPI_SKIPTRACE)
  const [callBulkREAPISkipTrace] = useMutation(
    CALL_BULK_REAPI_SKIPTRACE,
  )
  const [buySkipTraceCredits] = useMutation(BUY_SKIP_TRACE_CREDITS)

  const skipTrace = () => {
    setLoader(true)
    callBulkREAPISkipTrace({
      awaitRefetchQueries: true,
      variables: {
        propertyIds: map(usedProperties, (property) => property._id),
      },
      refetchQueries: concat(
        [
          {
            query: GET_ME,
          },
          {
            query: GET_PORTFOLIOS,
          },
          {
            query: GET_PROPERTIES,
            variables: {
              portfolioId: [props.portfolioId],
              limit: GET_PROPERTIES_QUERY_LIMIT,
            },
          },
        ],
        map(usedProperties, (property) => {
          return {
            query: GET_PROPERTY,
            variables: { id: property._id },
          }
        }),
      ),
    })
      .then(async ({ data }) => {
        enqueueSnackbar('Skip trace result success', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        handleCloseSkipTrace()
        if (props.setSelected) props.setSelected([])
      })
      .catch((error) => {
        enqueueSnackbar(`Skip trace result failed`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseSkipTrace()
      })
  }

  const buyCredits = () => {
    setLoader(true)
    buySkipTraceCredits({
      awaitRefetchQueries: true,
      variables: {
        credits: buyCreditsAmount,
        token,
      },
      refetchQueries: [{ query: GET_ME }],
    })
      .then(async ({ data }) => {
        enqueueSnackbar('Credits added successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        })
        handleCloseBuyCredits()
        if (props.setSelected) props.setSelected([])
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to buy credits: ` + error.message, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseBuyCredits()
      })
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const currencyFormat2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const remainingCredits =
    props.session.me.activeOrg.skipTraceCredits -
    usedProperties.length

  return (
    <>
      {!bulk && !portfolioId && (
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={isEmpty(filteredProperties)}
            onClick={
              remainingCredits >= 0
                ? handleOpenSkipTrace
                : handleOpenNotEnoughCredits
            }
          >
            Skip Trace
          </Button>
        </Grid>
      )}
      {bulk && (
        <Tooltip title="Bulk Skip Trace">
          <IconButton
            onClick={
              remainingCredits >= 0
                ? handleOpenSkipTrace
                : handleOpenNotEnoughCredits
            }
            size="large"
          >
            <FontAwesomeIcon
              icon={['far', 'address-card']}
              style={{ color: 'white' }}
            />
          </IconButton>
        </Tooltip>
      )}
      {sidebarMenu && portfolioId && (
        <ListItem
          button
          onClick={() => {
            if (loading) {
              setLoader(true)
              callProperties()
            } else if (remainingCredits >= 0) {
              handleOpenSkipTrace()
            } else {
              handleOpenNotEnoughCredits()
            }
          }}
        >
          <FontAwesomeIcon
            icon={['far', 'address-card']}
            style={{ marginRight: 5, color: '#757575' }}
          />
          <span>Skip Trace Portfolio</span>
        </ListItem>
      )}
      <Dialog
        open={openSkipTrace}
        onClose={handleCloseSkipTrace}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="xs"
        fullWidth={true}
        onKeyDown={stopPropagationForTab}
      >
        {/* <Card> */}
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">Getting Skip Trace</div>
          }
        >
          <DialogTitle id="form-dialog-title">Skip Trace</DialogTitle>
          <DialogContent className="p-4">
            <Grid container direction="column">
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography>Available Skiptraces:</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {props.session.me.activeOrg.skipTraceCredits}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography>Needed Skiptraces:</Typography>
                </Grid>
                <Grid item>
                  <Typography>{usedProperties.length}</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography>Remaining Skiptraces:</Typography>
                </Grid>
                <Grid item>
                  <Typography>{remainingCredits}</Typography>
                </Grid>
              </Grid>
              <Grid item style={{ marginTop: 25 }}>
                <p className="mb-0 font-size-lg text-muted">
                  <FormControl component="fieldset" className="pr-4">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={skipDups}
                            onChange={() => setSkipDups(!skipDups)}
                          />
                        }
                        label="Do not skip trace properties that have already been skip traced."
                      />
                    </FormGroup>
                  </FormControl>
                  {/* Delete this portfolio along with all the properties inside. This can't be undone. */}
                </p>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {remainingCredits < 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenBuyCredits}
                //className="btn-danger btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Buy More</span>
              </Button>
            )}
            <Button variant="text" onClick={handleCloseSkipTrace}>
              Cancel
            </Button>
            <Button
              onClick={skipTrace}
              variant="contained"
              color="primary"
              disabled={remainingCredits < 0 || usedProperties == 0}
            >
              Continue
            </Button>
          </DialogActions>
        </BlockUi>
      </Dialog>

      <Dialog
        open={openNotEnoughCredits}
        onClose={handleCloseNotEnoughCredits}
        classes={{ paper: 'shadow-lg rounded' }}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={<div className="text-primary">No Credits</div>}
        >
          <div className="text-center p-5 container-fluid">
            {/* Warning Text */}
            <h4 className="font-weight-bold mt-3">
              Looks like you are out of skip trace credits
            </h4>

            {/* Warning Icon */}
            <div className="d-flex justify-content-center my-3">
              <div
                className="d-flex align-items-center justify-content-center rounded-circle bg-neutral-warning text-warning"
                style={{ width: 60, height: 60 }}
              >
                <FontAwesomeIcon
                  icon={['fas', 'exclamation']}
                  className="display-4"
                />
              </div>
            </div>

            {/* Title */}
            <h2 className="font-weight-bold my-4">
              12¢ Skip Tracing
            </h2>

            {/* Description */}
            <span
              className="font-size-md px-4 mt-2 text-wrap"
              style={{ lineHeight: '1.6' }}
            >
              <strong>
                Access contact, demographic, and social data with
                ease.
              </strong>
            </span>
            <p
              className="font-size-md px-4 text-wrap"
              style={{ lineHeight: '1.6' }}
            >
              Purchase a minimum of 25 credits for just{' '}
              <strong>$3.00</strong>. Credits are added to your
              account and can be used anytime—no expiration, no
              hassle.
            </p>

            {/* Buttons */}
            <div className="d-flex flex-column flex-md-row justify-content-center gap-3 mt-4">
              <Button
                variant="text"
                onClick={handleCloseNotEnoughCredits}
                className="px-4 py-2"
              >
                <span className="btn-wrapper--label">Close</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenBuyCredits}
                className="px-4 py-2"
              >
                <span className="btn-wrapper--label">Next</span>
              </Button>
            </div>
          </div>
        </BlockUi>
      </Dialog>

      <StripeOneTimePurchase
        open={openBuyCredits}
        onClose={handleCloseBuyCredits}
        onSubmit={buyCredits}
        loader={loader}
        stripePromise={stripePromise}
        recharge={recharge}
        handleRechargeToggle={handleRechargeToggle}
        createToken={createToken}
        setResetToken={setResetToken}
        buyCreditsAmount={buyCreditsAmount}
        setBuyCreditsAmount={setBuyCreditsAmount}
        session={props.session}
        title={'Buy Credits'}
      />
    </>
  )
}

export default withSession(SkipTraceComponent)
