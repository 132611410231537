/**
 * @ Author: Housefolios
 * @ Create Time: 2022-05-13 15:04:45
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-04 16:24:17
 * @ Description: Dialog to add options from dropdowns
 */

import React, { useEffect, useState } from 'react'

import map from 'lodash/map'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import clone from 'lodash/clone'
import pullAt from 'lodash/pullAt'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import cloneDeep from 'lodash/cloneDeep'
import head from 'lodash/head'
import isNil from 'lodash/isNil'
import concat from 'lodash/concat'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import { useMutation } from '@apollo/client'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import withSession from '@/housefolios-components/Session/withSession'
import { UPDATE_LOT } from '@/housefolios-components/Lots/mutations'
import { UPDATE_PROPERTIES } from '@/housefolios-components/Portfolio/mutations'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_LOT } from '@/housefolios-components/Lots/queries'
import { Add, InfoOutlined } from '@mui/icons-material'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import Autocomplete from '@mui/material/Autocomplete'
import FileUpload from '@/housefolios-components/PropertyContainer/PropertyDashboard/FileUpload'

import { STATES } from '@/constants/states'
import { NumericFormat } from 'react-number-format'
import useAffiliate from '@/hooks/useAffiliate'
import { currencyFormat } from '@/utils/number'
import { UPDATE_AFFILIATE } from '@/housefolios-components/PartnerPortal/mutations'
import { GET_AFFILIATE } from '@/housefolios-components/CreateAccount/CreateAccountCard/queries'

function AddOptionComponent(props) {
  const {
    currentValue,
    edit,
    editIndex,
    currentOption,
    addOption,
    addOptionLabel,
    addOptionTarget,
    onChangeAnalysis,
    onChangeLotProcess,
    onChangeProperty,
    onChangeOffer,
    onChangeAssumptions,
  } = props
  const [loader, setLoader] = useState(false)
  const [openAddOption, setOpenAddOption] = useState(false)
  const [addOptionValue, setAddOptionValue] = useState('')

  const [addOptionBeds, setAddOptionBeds] = useState()
  const [addOptionBaths, setAddOptionBaths] = useState()
  const [addOptionSqft, setAddOptionSqft] = useState()
  const [addOptionListPrice, setAddOptionListPrice] = useState()
  const [addOptionImages, setAddOptionImages] = useState([])
  const [addOptionFiles, setAddOptionFiles] = useState([])

  const [addOptionType, setAddOptionType] = useState([])
  const [addOptionDownPayment, setAddOptionDownPayment] = useState()
  const [addOptionRate, setAddOptionRate] = useState()
  const [addOptionTerm, setAddOptionTerm] = useState()
  const [addOptionTermType, setAddOptionTermType] = useState('Months')
  const [addOptionPoints, setAddOptionPoints] = useState()

  const [addOptionContract, setAddOptionContract] = useState()
  const [addOptionPhone, setAddOptionPhone] = useState()
  const [addOptionEmail, setAddOptionEmail] = useState()
  const [addOptionFee, setAddOptionFee] = useState()
  const [addOptionFeeType, setAddOptionFeeType] = useState('%')

  const [addOptionAddress, setAddOptionAddress] = useState()
  const [addOptionAddress2, setAddOptionAddress2] = useState()
  const [addOptionCity, setAddOptionCity] = useState()
  const [addOptionState, setAddOptionState] = useState()
  const [addOptionZip, setAddOptionZip] = useState()

  const [addOptionRehabPercentage, setAddOptionRehabPercentage] =
    useState()
  const [addOptionInterestOnly, setAddOptionInterestOnly] =
    useState(false)

  //SPECIFIC COMMENT: Value for storing single dropdowns instead of multi dropdown values
  const [addOptionSingleType, setAddOptionSingleType] = useState()

  const [addOptionCost, setAddOptionCost] = useState()
  const [addOptionToolTip, setAddOptionToolTip] = useState()
  const [addOptionShowMarkets, setAddOptionShowMarkets] = useState([])

  const [addOptionSigner, setAddOptionSigner] = useState()
  const [addOptionDaysToClose, setAddOptionDaysToClose] = useState()
  const [addOptionInspectionPeriod, setAddOptionInspectionPeriod] =
    useState()
  const [
    addOptionEarnestMoneyDeposit,
    setAddOptionEarnestMoneyDeposit,
  ] = useState()
  const [addOptionStatements, setAddOptionStatements] = useState()
  const [addOptionConditions, setAddOptionConditions] = useState()

  const handleOpenAddOption = (event) => {
    setOpenAddOption(true)
    setLoader(false)
    //SPECIFIC COMMENT: set values based on option if you are editing existing option
    if (edit) {
      if (addOptionLabel === 'Plan') {
        setAddOptionValue(currentOption.value)
        setAddOptionBeds(currentOption.beds)
        setAddOptionBaths(currentOption.baths)
        setAddOptionSqft(currentOption.sqft)
        setAddOptionListPrice(currentOption.listPrice)
        setAddOptionImages(currentOption.images || [])
        setAddOptionFiles(currentOption.files || [])
      } else if (addOptionLabel === 'Lender') {
        if (addOptionTarget === 'lender') {
          setAddOptionValue(currentOption.value)
          setAddOptionDownPayment(currentOption.downPayment)
          setAddOptionRehabPercentage(currentOption.rehabPercentage)
          setAddOptionPoints(currentOption.points)
          setAddOptionInterestOnly(currentOption.interestOnly)
          setAddOptionFee(currentOption.fee)
          setAddOptionFeeType(currentOption.feeType)
        } else {
          setAddOptionValue(currentOption.value)
          setAddOptionType(currentOption.type)
          setAddOptionDownPayment(currentOption.downPayment)
          setAddOptionRate(currentOption.rate)
          setAddOptionTerm(currentOption.term)
          setAddOptionTermType(currentOption.termType)
        }
      } else if (
        addOptionLabel === 'Builder' ||
        addOptionLabel === 'Reseller'
      ) {
        setAddOptionValue(currentOption.value)
        setAddOptionContract(currentOption.contract)
        setAddOptionPhone(currentOption.phone)
        setAddOptionEmail(currentOption.email)
        setAddOptionFee(currentOption.fee)
        setAddOptionFeeType(currentOption.feeType)
      } else if (addOptionLabel === 'Entity') {
        setAddOptionValue(currentOption.value)
        setAddOptionType(currentOption.type)
        setAddOptionAddress(currentOption.address)
        setAddOptionAddress2(currentOption.address2)
        setAddOptionCity(currentOption.city)
        setAddOptionState(currentOption.state)
        setAddOptionZip(currentOption.zip)
      } else if (addOptionLabel === 'Buyer/Seller Profile') {
        setAddOptionValue(currentOption.value)
        setAddOptionSigner(currentOption.signer)
        setAddOptionPhone(currentOption.phone)
        setAddOptionEmail(currentOption.email)
        setAddOptionAddress(currentOption.address)
        setAddOptionAddress2(currentOption.address2)
        setAddOptionCity(currentOption.city)
        setAddOptionState(currentOption.state)
        setAddOptionZip(currentOption.zip)
        setAddOptionImages(
          currentOption.logoImage ? [currentOption.logoImage] : [],
        )
        setAddOptionDaysToClose(currentOption.daysToClose)
        setAddOptionInspectionPeriod(currentOption.inspectionPeriod)
        setAddOptionEarnestMoneyDeposit(currentOption.earnestMoney)
        setAddOptionStatements(currentOption.statements)
        setAddOptionConditions(currentOption.conditions)
      } else if (addOptionLabel === 'Rehab Tier') {
        setAddOptionValue(currentOption.title)
        setAddOptionCost(currentOption.value)
        setAddOptionShowMarkets(currentOption.showMarkets || [])
        setAddOptionToolTip(currentOption.tip)
      } else if (addOptionLabel === 'Custom Column') {
        setAddOptionValue(currentOption.value)
        setAddOptionSingleType(currentOption.type)
        setAddOptionType(currentOption.view)
      } else {
        setAddOptionValue(currentOption)
      }
    }
    event.stopPropagation()
    event.preventDefault()
  }

  const handleCloseAddOption = () => {
    setOpenAddOption(false)
    setAddOptionValue('')
    setAddOptionBeds(null)
    setAddOptionBaths(null)
    setAddOptionSqft(null)
    setAddOptionListPrice(null)
    setAddOptionImages([])
    setAddOptionFiles([])
    setAddOptionType([])
    setAddOptionDownPayment(null)
    setAddOptionRate(null)
    setAddOptionTerm(null)
    setAddOptionTermType('Months')
    setAddOptionContract(null)
    setAddOptionEmail(null)
    setAddOptionPhone(null)
    setAddOptionEmail(null)
    setAddOptionFee(null)
    setAddOptionFeeType('%')
    setAddOptionSingleType(null)
    setAddOptionCost(null)
    setAddOptionToolTip(null)
    setAddOptionShowMarkets([])
    setLoader(false)
  }

  const [anchorElAssumptions, setAnchorElAssumptions] = useState(null)
  const [showFormatDropdown, setShowFormatDropdown] = useState('')

  const toggleAssumptionsMenuFormat = (event, name) => {
    setAnchorElAssumptions(event.target)
    setShowFormatDropdown(name)
  }

  const handleAssumptionsMenuClose = () => {
    setAnchorElAssumptions(null)
  }

  const uploadImage = (files) => {
    let newFiles = clone(addOptionImages) || []
    map(files, (file) => {
      newFiles.push(file)
    })
    setAddOptionImages(newFiles)
  }

  const deleteImage = (index) => {
    let newFiles = clone(addOptionImages) || []
    pullAt(newFiles, index)
    setAddOptionImages(newFiles)
  }

  const uploadFile = (files) => {
    let newFiles = clone(addOptionFiles) || []
    map(files, (file) => {
      newFiles.push(file)
    })
    setAddOptionFiles(newFiles)
  }

  const deleteFile = (index) => {
    let newFiles = clone(addOptionFiles) || []
    pullAt(newFiles, index)
    setAddOptionFiles(newFiles)
  }

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)
  const [updateAffiliate] = useMutation(UPDATE_AFFILIATE)

  const [{ affiliate }] = useAffiliate({
    memberValue: props?.session?.me?.activeOrg?.member,
  })

  useEffect(() => {
    ValidatorForm.addValidationRule('noDuplicate', (value) => {
      //SPECIFIC COMMENT: Fail test if duplicate and you are not editing. Change latter is we decide to allow editing value.
      if (
        !edit &&
        find(
          props.session.me.activeOrg[addOption] || [],
          (option) => option.value === value,
        )
      ) {
        return false
      }
      return true
    })
    return () => {
      ValidatorForm.removeValidationRule('noDuplicate')
    }
  })

  return (
    <>
      {props.button ? (
        <Tooltip title={`Add ${addOptionLabel}`}>
          <IconButton
            color="primary"
            variant="outlined"
            size="small"
            onClick={handleOpenAddOption}
          >
            <Add fontSize="large" />
          </IconButton>
        </Tooltip>
      ) : props.edit ? (
        <Tooltip title={`Edit`}>
          <IconButton
            color="primary"
            variant="outlined"
            size="small"
            onClick={handleOpenAddOption}
          >
            <FontAwesomeIcon
              icon={['far', 'pen']}
              className="font-size-lg"
            />
          </IconButton>
        </Tooltip>
      ) : (
        <MenuItem
          style={{
            textAlign: 'center',
            color: '#04a3e3',
            marginLeft: 8,
          }}
          key={addOptionLabel}
          value={currentValue}
          onClick={handleOpenAddOption}
          button
        >
          <Add style={{ marginRight: 10 }} />
          Add {addOptionLabel}
        </MenuItem>
      )}

      <Dialog
        open={openAddOption}
        onClose={handleCloseAddOption}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="xs"
        fullWidth={true}
        onClick={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              Adding {addOptionLabel}
            </div>
          }
        >
          <DialogTitle>
            {edit ? 'Edit' : 'Add'} {addOptionLabel}
          </DialogTitle>
          <ValidatorForm
            style={{
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
            onSubmit={() => {
              const { activeOrg } = props.session.me
              setLoader(true)
              let newOptions = props.isAffiliate
                ? cloneDeep(affiliate[addOption]) || []
                : cloneDeep(activeOrg[addOption]) || []
              //SPECIFIC COMMENT: Create new option based off of type of option
              let newOption
              if (addOptionLabel === 'Plan') {
                newOption = {
                  active: true,
                  label: addOptionValue,
                  value: addOptionValue,
                  beds: addOptionBeds,
                  baths: addOptionBaths,
                  sqft: addOptionSqft,
                  listPrice: addOptionListPrice,
                  images: addOptionImages,
                  files: addOptionFiles,
                }
              } else if (addOptionLabel === 'Lender') {
                if (addOptionTarget === 'lender') {
                  newOption = {
                    active: true,
                    label: addOptionValue,
                    value: addOptionValue,
                    downPayment: addOptionDownPayment,
                    rehabPercentage: addOptionRehabPercentage,
                    points: addOptionPoints,
                    interestOnly: addOptionInterestOnly,
                    fee: addOptionFee,
                    feeType: addOptionFeeType,
                  }
                } else {
                  newOption = {
                    active: true,
                    label: addOptionValue,
                    value: addOptionValue,
                    type: addOptionType,
                    downPayment: addOptionDownPayment,
                    rate: addOptionRate,
                    term: addOptionTerm,
                    termType: addOptionTermType,
                    points: addOptionPoints,
                  }
                }
              } else if (
                addOptionLabel === 'Builder' ||
                addOptionLabel === 'Reseller'
              ) {
                newOption = {
                  active: true,
                  label: addOptionValue,
                  value: addOptionValue,
                  contract: addOptionContract,
                  phone: addOptionPhone,
                  email: addOptionEmail,
                  fee: addOptionFee,
                  feeType: addOptionFeeType,
                }
              } else if (addOptionLabel === 'Entity') {
                newOption = {
                  active: true,
                  label: addOptionValue,
                  value: addOptionValue,
                  type: addOptionSingleType,
                  address: addOptionAddress,
                  address2: addOptionAddress2,
                  city: addOptionCity,
                  state: addOptionState,
                  zip: addOptionZip,
                }
              } else if (addOptionLabel === 'Buyer/Seller Profile') {
                newOption = {
                  active: true,
                  label: addOptionValue,
                  value: addOptionValue,
                  signer: addOptionSigner,
                  phone: addOptionPhone,
                  email: addOptionEmail,
                  address: addOptionAddress,
                  address2: addOptionAddress2,
                  city: addOptionCity,
                  state: addOptionState,
                  zip: addOptionZip,
                  logoImage: head(addOptionImages),
                  daysToClose: addOptionDaysToClose,
                  inspectionPeriod: addOptionInspectionPeriod,
                  earnestMoney: addOptionEarnestMoneyDeposit,
                  statements: addOptionStatements,
                  conditions: addOptionConditions,
                }
              } else if (addOptionLabel === 'Rehab Tier') {
                newOption = {
                  active: true,
                  title: addOptionValue,
                  label: `$${addOptionCost}/sqft`,
                  value: Number(addOptionCost),
                  showMarkets: addOptionShowMarkets,
                  tip: addOptionToolTip,
                }
              } else if (addOptionLabel === 'Custom Column') {
                newOption = {
                  active: true,
                  title: addOptionValue,
                  label: addOptionValue,
                  value: addOptionValue,
                  type: addOptionSingleType,
                  view: addOptionType,
                }
              } else {
                newOption = addOptionValue
              }
              //SPECIFIC COMMENT: If edit replace existing option
              if (edit) {
                if (isNil(editIndex)) {
                  const index = findIndex(
                    props.session.me.activeOrg[addOption] || [],
                    (option) => option.value === addOptionValue,
                  )
                  newOptions[index] = newOption
                } else {
                  newOptions[editIndex] = newOption
                }
              } else {
                const existingIndex = findIndex(
                  props.session.me.activeOrg[addOption] || [],
                  (option) =>
                    option.value === addOptionValue ||
                    option.value === Number(addOptionCost),
                )
                if (!props.isAffiliate && existingIndex >= 0) {
                  newOptions[existingIndex] = newOption
                } else {
                  newOptions.push(newOption)
                }
              }
              let organizationInput = {}
              organizationInput[addOption] = newOptions
              if (props.isAffiliate) {
                updateAffiliate({
                  variables: {
                    affiliateId: affiliate._id,
                    affiliateInput: organizationInput,
                  },
                  refetchQueries: [
                    {
                      query: GET_ME,
                    },
                    {
                      query: GET_AFFILIATE,
                      variables: {
                        memberValue: affiliate?.memberValue,
                      },
                    },
                  ],
                })
                  .then(() => {
                    enqueueSnackbar(`Option has been Added`, {
                      variant: 'success',
                      autoHideDuration: 3000,
                    })
                    handleCloseAddOption()
                  })
                  .catch((error) => {
                    console.log(error)
                    enqueueSnackbar(`Option has failed to be Added`, {
                      variant: 'error',
                      autoHideDuration: 3000,
                    })
                    handleCloseAddOption()
                  })
              } else {
                updateOrganization({
                  awaitRefetchQueries: true,
                  variables: {
                    id: activeOrg._id,
                    organizationInput,
                  },
                  refetchQueries: [
                    {
                      query: GET_ME,
                    },
                  ],
                })
                  .then(() => {
                    if (addOptionLabel === 'Lender') {
                      if (addOptionTarget === 'lender')
                        onChangeAnalysis({
                          target: {
                            name: addOptionTarget,
                            value: addOptionValue,
                            type: 'text',
                          },
                        })
                      else if (
                        addOptionTarget === 'lotLender' &&
                        includes(addOptionType, 'Lot') &&
                        addOptionTarget === 'constructionLender' &&
                        includes(addOptionType, 'Construction') &&
                        addOptionTarget === 'mezzanineLender' &&
                        includes(addOptionType, 'Mezzanine')
                      )
                        onChangeAnalysis({
                          target: {
                            name: addOptionTarget,
                            value: addOptionValue,
                            type: 'text',
                          },
                        })
                    } else if (
                      addOptionLabel ===
                        'Custom Acquisition Expenses Type' ||
                      addOptionLabel ===
                        'Custom Monthly Expenses Type'
                    ) {
                      onChangeAnalysis({
                        target: {
                          name: addOptionTarget,
                          value: addOptionValue,
                          type: 'text',
                        },
                      })
                    } else if (
                      addOptionLabel === 'Builder' ||
                      addOptionLabel === 'Reseller'
                    ) {
                      onChangeLotProcess({
                        target: {
                          name: addOptionTarget,
                          value: addOptionValue,
                          type: 'text',
                        },
                      })
                    } else if (addOptionLabel === 'Rehab Tier') {
                      onChangeAssumptions({
                        target: {
                          name: addOptionTarget,
                          value: addOptionCost,
                          type: 'number',
                        },
                      })
                    } else if (
                      onChangeOffer &&
                      addOptionLabel === 'Entity'
                    ) {
                      onChangeOffer({
                        target: {
                          name: addOptionTarget,
                          value: addOptionValue,
                          type: 'text',
                        },
                      })
                    } else {
                      onChangeProperty({
                        target: {
                          name: addOptionTarget,
                          value: addOptionValue,
                          type: addOptionSingleType || 'text',
                        },
                      })
                    }
                    enqueueSnackbar(`Option has been Added`, {
                      variant: 'success',
                      autoHideDuration: 3000,
                    })
                    handleCloseAddOption()
                  })
                  .catch((error) => {
                    console.log(error)
                    enqueueSnackbar(`Option has failed to be Added`, {
                      variant: 'error',
                      autoHideDuration: 3000,
                    })
                    handleCloseAddOption()
                  })
              }
            }}
          >
            <DialogContent className="p-4">
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12}>
                  <TextValidator
                    variant="outlined"
                    margin="dense"
                    label={addOptionLabel + ' name'}
                    fullWidth={true}
                    value={addOptionValue}
                    onChange={(event) =>
                      setAddOptionValue(event.target.value)
                    }
                    required={true}
                    validators={['required', 'noDuplicate']}
                    errorMessages={[
                      'This field is required',
                      'This value is already used for an option',
                    ]}
                    autoFocus
                    disabled={edit}
                  />
                </Grid>
                {addOptionLabel === 'Plan' && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Beds"
                        fullWidth={true}
                        value={addOptionBeds}
                        onChange={(event) =>
                          setAddOptionBeds(event.target.value)
                        }
                        required={true}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Baths"
                        fullWidth={true}
                        value={addOptionBaths}
                        onChange={(event) =>
                          setAddOptionBaths(event.target.value)
                        }
                        required={true}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Sqft"
                        fullWidth={true}
                        value={addOptionSqft}
                        onChange={(event) =>
                          setAddOptionSqft(event.target.value)
                        }
                        required={true}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Suggested Price"
                        fullWidth={true}
                        value={addOptionListPrice}
                        onChange={(event) =>
                          setAddOptionListPrice(event.target.value)
                        }
                        required={true}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FileUpload
                        documentId={props.session.me.activeOrg._id}
                        files={addOptionImages}
                        uploadFile={uploadImage}
                        deleteFile={deleteImage}
                        filetypes={['image/*']}
                        maxFiles={10 - addOptionImages.length}
                        disabled={addOptionImages.length === 10}
                        buttonText="Upload images (Max 10)"
                        button
                        collection="Plans"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FileUpload
                        documentId={props.session.me.activeOrg._id}
                        files={addOptionFiles}
                        uploadFile={uploadFile}
                        deleteFile={deleteFile}
                        // filetypes={["image/*"]}
                        maxFiles={10 - addOptionFiles.length}
                        disabled={addOptionFiles.length === 10}
                        buttonText="Upload files (Max 10)"
                        button
                        collection="Plans"
                      />
                    </Grid>
                  </>
                )}
                {addOptionLabel === 'Lender' && (
                  <>
                    {addOptionTarget === 'lender' ? (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            label="% of Purchase Price"
                            value={100 - addOptionDownPayment || 0}
                            onChange={(event) =>
                              setAddOptionDownPayment(
                                100 - event.target.value,
                              )
                            }
                            fullWidth={true}
                            required={true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            label="% of Rehab"
                            fullWidth={true}
                            value={addOptionRehabPercentage}
                            onChange={(event) =>
                              setAddOptionRehabPercentage(
                                event.target.value,
                              )
                            }
                            required={true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            label="Points"
                            fullWidth={true}
                            value={addOptionPoints}
                            onChange={(event) =>
                              setAddOptionPoints(event.target.value)
                            }
                            required={true}
                            // InputProps={{
                            //   endAdornment: <InputAdornment position="end">%</InputAdornment>
                            // }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                          >
                            <Grid item className="gridItem2">
                              <Checkbox
                                color="primary"
                                type="checkbox"
                                value={addOptionInterestOnly}
                                checked={Boolean(
                                  addOptionInterestOnly,
                                )}
                                onChange={(event) =>
                                  setAddOptionInterestOnly(
                                    !addOptionInterestOnly,
                                  )
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              className="gridItem2"
                              style={{ fontSize: 14 }}
                            >
                              Interest Only Loan
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            label="Financing Fee"
                            fullWidth={true}
                            value={addOptionFee}
                            onChange={(event) =>
                              setAddOptionFee(event.target.value)
                            }
                            required={true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  margin="dense"
                                >
                                  <Button
                                    onClick={(e) =>
                                      toggleAssumptionsMenuFormat(
                                        e,
                                        'addOptionFeeType',
                                      )
                                    }
                                    size="small"
                                    margin="dense"
                                    style={{ padding: 3 }}
                                    className="btn-neutral-dark d-flex align-items-center"
                                  >
                                    <span className="btn-wrapper--label">
                                      {addOptionFeeType}
                                    </span>
                                    <span className="btn-wrapper--icon d-flex">
                                      <FontAwesomeIcon
                                        icon={['fas', 'chevron-down']}
                                        className="opacity-8 font-size-xs ml-1"
                                      />
                                    </span>
                                  </Button>
                                  <Menu
                                    anchorEl={anchorElAssumptions}
                                    keepMounted
                                    name={'addOptionFeeType'}
                                    open={
                                      Boolean(anchorElAssumptions) &&
                                      'addOptionFeeType' ===
                                        showFormatDropdown
                                    }
                                    onClose={
                                      handleAssumptionsMenuClose
                                    }
                                    anchorOrigin={{
                                      vertical: 'center',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'center',
                                      horizontal: 'center',
                                    }}
                                    classes={{ list: 'p-0' }}
                                  >
                                    <div className="p-2">
                                      <List
                                        component="div"
                                        className="nav-pills p-0 m-0 nav-neutral-dark flex-column"
                                      >
                                        <ListItem
                                          key="$"
                                          button
                                          href={null}
                                          onClick={(e) => {
                                            setAddOptionFeeType('$')
                                            handleAssumptionsMenuClose()
                                          }}
                                          selected={'$'}
                                          className="px-3 mx-2"
                                        >
                                          <span>{'$'}</span>
                                        </ListItem>
                                        <ListItem
                                          key="%"
                                          button
                                          href={null}
                                          onClick={(e) => {
                                            setAddOptionFeeType('%')
                                            handleAssumptionsMenuClose()
                                          }}
                                          selected={'%'}
                                          className="px-3 mx-2"
                                        >
                                          <span>{'%'}</span>
                                        </ListItem>
                                      </List>
                                    </div>
                                  </Menu>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12}>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            variant="outlined"
                            margin="dense"
                            label="Loan Type"
                            options={[
                              'Lot',
                              'Construction',
                              'Mezzanine',
                            ]}
                            filterSelectedOptions
                            fullWidth={true}
                            value={addOptionType}
                            onChange={(event, newValue) => {
                              setAddOptionType(newValue)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Loan Type*"
                              />
                            )}
                            renderTags={(value, getTagProps) => (
                              <>
                                {map(value, (v, index) => (
                                  <Chip
                                    color="primary"
                                    label={v}
                                    {...getTagProps({ index })}
                                  />
                                ))}
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            label="Down Payment"
                            value={addOptionDownPayment}
                            onChange={(event) =>
                              setAddOptionDownPayment(
                                event.target.value,
                              )
                            }
                            fullWidth={true}
                            required={true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            label="Rate"
                            fullWidth={true}
                            value={addOptionRate}
                            onChange={(event) =>
                              setAddOptionRate(event.target.value)
                            }
                            required={true}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            margin="dense"
                            label="Points"
                            fullWidth={true}
                            value={addOptionPoints}
                            onChange={(event) =>
                              setAddOptionPoints(event.target.value)
                            }
                            required={true}
                            // InputProps={{
                            //   endAdornment: <InputAdornment position="end">%</InputAdornment>
                            // }}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {(addOptionLabel === 'Builder' ||
                  addOptionLabel === 'Reseller') && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Contact Name"
                        fullWidth={true}
                        value={addOptionContract}
                        onChange={(event) =>
                          setAddOptionContract(event.target.value)
                        }
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        variant="outlined"
                        margin="dense"
                        label="Phone"
                        fullWidth={true}
                        value={addOptionPhone}
                        onChange={(event) =>
                          setAddOptionPhone(event.target.value)
                        }
                        required={true}
                        validators={[
                          'required',
                          //eslint-disable-next-line
                          'matchRegexp:^[0-9()-]+$',
                        ]}
                        errorMessages={[
                          'This field is required',
                          'Not a valid phone number',
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        variant="outlined"
                        margin="dense"
                        label="Email"
                        fullWidth={true}
                        value={addOptionEmail}
                        onChange={(event) =>
                          setAddOptionEmail(event.target.value)
                        }
                        required={true}
                        validators={[
                          'required',
                          'maxStringLength: 50',
                          'isEmail',
                        ]}
                        errorMessages={[
                          'This field is required',
                          'You cannot exceed 50 characters',
                          'Not a valid email address',
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Fee"
                        fullWidth={true}
                        value={addOptionFee}
                        onChange={(event) =>
                          setAddOptionFee(event.target.value)
                        }
                        required={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              margin="dense"
                            >
                              <Button
                                onClick={(e) =>
                                  toggleAssumptionsMenuFormat(
                                    e,
                                    'addOptionFeeType',
                                  )
                                }
                                size="small"
                                margin="dense"
                                style={{ padding: 3 }}
                                className="btn-neutral-dark d-flex align-items-center"
                              >
                                <span className="btn-wrapper--label">
                                  {addOptionFeeType}
                                </span>
                                <span className="btn-wrapper--icon d-flex">
                                  <FontAwesomeIcon
                                    icon={['fas', 'chevron-down']}
                                    className="opacity-8 font-size-xs ml-1"
                                  />
                                </span>
                              </Button>
                              <Menu
                                anchorEl={anchorElAssumptions}
                                keepMounted
                                name={'addOptionFeeType'}
                                open={
                                  Boolean(anchorElAssumptions) &&
                                  'addOptionFeeType' ===
                                    showFormatDropdown
                                }
                                onClose={handleAssumptionsMenuClose}
                                anchorOrigin={{
                                  vertical: 'center',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'center',
                                  horizontal: 'center',
                                }}
                                classes={{ list: 'p-0' }}
                              >
                                <div className="p-2">
                                  <List
                                    component="div"
                                    className="nav-pills p-0 m-0 nav-neutral-dark flex-column"
                                  >
                                    <ListItem
                                      key="$"
                                      button
                                      href={null}
                                      onClick={(e) => {
                                        setAddOptionFeeType('$')
                                        handleAssumptionsMenuClose()
                                      }}
                                      selected={'$'}
                                      className="px-3 mx-2"
                                    >
                                      <span>{'$'}</span>
                                    </ListItem>
                                    <ListItem
                                      key="%"
                                      button
                                      href={null}
                                      onClick={(e) => {
                                        setAddOptionFeeType('%')
                                        handleAssumptionsMenuClose()
                                      }}
                                      selected={'%'}
                                      className="px-3 mx-2"
                                    >
                                      <span>{'%'}</span>
                                    </ListItem>
                                  </List>
                                </div>
                              </Menu>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}
                {addOptionLabel === 'Entity' && (
                  <>
                    <Grid item xs={12}>
                      <TextValidator
                        variant="outlined"
                        margin="dense"
                        label="Entity Type"
                        select
                        fullWidth={true}
                        value={addOptionSingleType}
                        onChange={(event) =>
                          setAddOptionSingleType(event.target.value)
                        }
                        required={true}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      >
                        <MenuItem key={'LLC'} value={'LLC'}>
                          {'LLC'}
                        </MenuItem>
                        <MenuItem
                          key={'Incorporated'}
                          value={'Incorporated'}
                        >
                          {'Incorporated'}
                        </MenuItem>
                        <MenuItem
                          key={'Limited Partner'}
                          value={'Limited Partner'}
                        >
                          {'Limited Partner'}
                        </MenuItem>
                        <MenuItem key={'Estate'} value={'Estate'}>
                          {'Estate'}
                        </MenuItem>
                        <MenuItem
                          key={'Joint Venture'}
                          value={'Joint Venture'}
                        >
                          {'Joint Venture'}
                        </MenuItem>
                        <MenuItem
                          key={'Non Profit Corporation'}
                          value={'Non Profit Corporation'}
                        >
                          {'Non Profit Corporation'}
                        </MenuItem>
                        <MenuItem key={'Trust'} value={'Trust'}>
                          {'Trust'}
                        </MenuItem>
                        <MenuItem key={'Other'} value={'Other'}>
                          {'Other'}
                        </MenuItem>
                      </TextValidator>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="Street Address"
                        value={addOptionAddress}
                        onChange={(event) =>
                          setAddOptionAddress(event.target.value)
                        }
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="Street Address Line 2"
                        value={addOptionAddress2}
                        onChange={(event) =>
                          setAddOptionAddress2(event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="City"
                        value={addOptionCity}
                        onChange={(event) =>
                          setAddOptionCity(event.target.value)
                        }
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        variant="outlined"
                        margin="dense"
                        label="State"
                        options={map(
                          STATES,
                          (state) => state.shortname,
                        )}
                        fullWidth={true}
                        value={addOptionState}
                        onChange={(event, newValue) => {
                          setAddOptionState(newValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="State*"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="Postal / Zip code"
                        value={addOptionZip}
                        onChange={(event) =>
                          setAddOptionZip(event.target.value)
                        }
                        required={true}
                      />
                    </Grid>
                  </>
                )}
                {addOptionLabel === 'Buyer/Seller Profile' && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="Signer"
                        value={addOptionSigner}
                        onChange={(event) =>
                          setAddOptionSigner(event.target.value)
                        }
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        variant="outlined"
                        margin="dense"
                        label="Phone"
                        fullWidth={true}
                        value={addOptionPhone}
                        onChange={(event) =>
                          setAddOptionPhone(event.target.value)
                        }
                        required={true}
                        validators={[
                          'required',
                          //eslint-disable-next-line
                          'matchRegexp:^[0-9()-]+$',
                        ]}
                        errorMessages={[
                          'This field is required',
                          'Not a valid phone number',
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        variant="outlined"
                        margin="dense"
                        label="Email"
                        fullWidth={true}
                        value={addOptionEmail}
                        onChange={(event) =>
                          setAddOptionEmail(event.target.value)
                        }
                        required={true}
                        validators={[
                          'required',
                          'maxStringLength: 50',
                          'isEmail',
                        ]}
                        errorMessages={[
                          'This field is required',
                          'You cannot exceed 50 characters',
                          'Not a valid email address',
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="Street Address"
                        value={addOptionAddress}
                        onChange={(event) =>
                          setAddOptionAddress(event.target.value)
                        }
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="Street Address Line 2"
                        value={addOptionAddress2}
                        onChange={(event) =>
                          setAddOptionAddress2(event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="City"
                        value={addOptionCity}
                        onChange={(event) =>
                          setAddOptionCity(event.target.value)
                        }
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        variant="outlined"
                        margin="dense"
                        label="State"
                        options={map(
                          STATES,
                          (state) => state.shortname,
                        )}
                        fullWidth={true}
                        value={addOptionState}
                        onChange={(event, newValue) => {
                          setAddOptionState(newValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="State*"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="Postal / Zip code"
                        value={addOptionZip}
                        onChange={(event) =>
                          setAddOptionZip(event.target.value)
                        }
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FileUpload
                        documentId={props.session.me.activeOrg._id}
                        files={addOptionImages}
                        uploadFile={uploadImage}
                        deleteFile={deleteImage}
                        filetypes={['image/*']}
                        maxFiles={1}
                        disabled={addOptionImages.length === 1}
                        buttonText="Upload Logo"
                        button
                        collection="Profiles"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Days to Close"
                        fullWidth={true}
                        value={addOptionDaysToClose}
                        onChange={(event) =>
                          setAddOptionDaysToClose(event.target.value)
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Inspection Period (Days)"
                        fullWidth={true}
                        value={addOptionInspectionPeriod}
                        onChange={(event) =>
                          setAddOptionInspectionPeriod(
                            event.target.value,
                          )
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NumericFormat
                        customInput={TextField}
                        thousandSeparator={true}
                        prefix={'$'}
                        fullWidth={true}
                        isNumericString={true}
                        decimalScale={0}
                        name="addOptionEarnestMoneyDeposit"
                        value={
                          Math.round(addOptionEarnestMoneyDeposit) ||
                          ''
                        }
                        onValueChange={(values) => {
                          const { value } = values
                          setAddOptionEarnestMoneyDeposit(value)
                        }}
                        type={'text'}
                        onFocus={(event) => {
                          event.target.select()
                        }}
                        variant="outlined"
                        margin="dense"
                        label="Earnest Money Deposit"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Include the Following Statements"
                        fullWidth={true}
                        value={addOptionStatements}
                        multiline={true}
                        rows={4}
                        onChange={(event) =>
                          setAddOptionStatements(event.target.value)
                        }
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Conditions"
                        fullWidth={true}
                        value={addOptionConditions}
                        multiline={true}
                        rows={4}
                        onChange={(event) =>
                          setAddOptionConditions(event.target.value)
                        }
                        type="text"
                      />
                    </Grid>
                  </>
                )}
                {addOptionLabel === 'Rehab Tier' && (
                  <>
                    <Grid item xs={6}>
                      <NumericFormat
                        customInput={TextField}
                        thousandSeparator={true}
                        prefix={'$'}
                        fullWidth={true}
                        isNumericString={true}
                        decimalScale={0}
                        name="addOptionCost"
                        value={Math.round(addOptionCost) || ''}
                        onValueChange={(values) => {
                          const { value } = values
                          setAddOptionCost(value)
                        }}
                        type={'text'}
                        onFocus={(event) => {
                          event.target.select()
                        }}
                        variant="outlined"
                        margin="dense"
                        label="Cost/SQFT"
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="Tooltip"
                        value={addOptionToolTip}
                        onChange={(event) =>
                          setAddOptionToolTip(event.target.value)
                        }
                      />
                    </Grid>
                    {!isEmpty(affiliate?.marketTypes) && (
                      <Grid item xs={12}>
                        <Autocomplete
                          id="checkboxes-tags-demo"
                          options={concat(
                            [affiliate.defaultMarketType],
                            affiliate.marketTypes,
                          )}
                          value={addOptionShowMarkets}
                          onChange={(event, newValue) => {
                            setAddOptionShowMarkets(newValue)
                          }}
                          multiple
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Show on Market Types"
                              placeholder="Show on Market Types"
                              helperText="If no market types are selected it will be visible on all market types"
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </>
                )}
                {/* {addOptionLabel === 'Rehab Tier' && !isEmpty(affiliate.marketTypes) && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        label="Tooltip"
                        value={addOptionToolTip}
                        onChange={(event) =>
                          setAddOptionToolTip(event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{affiliate.defaultMarketType}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <NumericFormat
                        customInput={TextField}
                        thousandSeparator={true}
                        prefix={'$'}
                        fullWidth={true}
                        isNumericString={true}
                        decimalScale={0}
                        name="addOptionCost"
                        value={Math.round(addOptionCost) || ''}
                        onValueChange={(values) => {
                          const { value } = values
                          setAddOptionCost(value)
                        }}
                        type={'text'}
                        onFocus={(event) => {
                          event.target.select()
                        }}
                        variant="outlined"
                        margin="dense"
                        label="Cost/SQFT"
                        required={true}
                      />
                    </Grid>
                    {map(affiliate.marketTypes, (marketType, index) => (
                      <>
                        <Grid item xs={6}>
                          <Typography>{marketType}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <NumericFormat
                            customInput={TextField}
                            thousandSeparator={true}
                            prefix={'$'}
                            fullWidth={true}
                            isNumericString={true}
                            decimalScale={0}
                            name={`addOptionMarketCosts.${index}`}
                            value={addOptionMarketCosts?.length > index ? Math.round(addOptionMarketCosts[index]) || '' : ''}
                            onValueChange={(values) => {
                              const { value } = values
                              let newCosts = cloneDeep(addOptionMarketCosts) || []
                              newCosts[index] = value
                              setAddOptionMarketCosts(newCosts)
                            }}
                            type={'text'}
                            onFocus={(event) => {
                              event.target.select()
                            }}
                            variant="outlined"
                            margin="dense"
                            label="Cost/SQFT"
                            required={true}
                          />
                        </Grid>
                      </>
                    ))}
                  </>
                )} */}
                {addOptionLabel === 'Custom Column' && (
                  <>
                    <Grid item xs={12}>
                      <TextValidator
                        variant="outlined"
                        margin="dense"
                        label="Column Type"
                        select
                        fullWidth={true}
                        value={addOptionSingleType}
                        onChange={(event) =>
                          setAddOptionSingleType(event.target.value)
                        }
                        required={true}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      >
                        <MenuItem key={'string'} value={'string'}>
                          {'Text'}
                        </MenuItem>
                        <MenuItem key={'number'} value={'number'}>
                          {'Number'}
                        </MenuItem>
                        <MenuItem key={'date'} value={'date'}>
                          {'Date'}
                        </MenuItem>
                        <MenuItem key={'boolean'} value={'boolean'}>
                          {'Yes/No'}
                        </MenuItem>
                      </TextValidator>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        variant="outlined"
                        margin="dense"
                        label="Views"
                        options={['Underwriting', 'Offer to Close']}
                        filterSelectedOptions
                        fullWidth={true}
                        value={addOptionType}
                        onChange={(event, newValue) => {
                          setAddOptionType(newValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Views*"
                          />
                        )}
                        renderTags={(value, getTagProps) => (
                          <>
                            {map(value, (v, index) => (
                              <Chip
                                color="primary"
                                label={v}
                                {...getTagProps({ index })}
                              />
                            ))}
                          </>
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="text" onClick={handleCloseAddOption}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginRight: 10 }}
                disabled={
                  !addOptionValue ||
                  loader ||
                  (addOptionLabel === 'Plan' &&
                    (!addOptionBeds ||
                      !addOptionBaths ||
                      !addOptionSqft ||
                      !addOptionListPrice)) ||
                  (addOptionLabel === 'Lender' &&
                    addOptionTarget !== 'lender' &&
                    (isEmpty(addOptionType) ||
                      !addOptionDownPayment ||
                      !addOptionRate ||
                      !addOptionPoints)) ||
                  ((addOptionLabel === 'Builder' ||
                    addOptionLabel === 'Reseller') &&
                    (!addOptionContract ||
                      !addOptionPhone ||
                      !addOptionEmail ||
                      !addOptionFee ||
                      !addOptionFeeType)) ||
                  (addOptionLabel === 'Entity' &&
                    (!addOptionSingleType ||
                      !addOptionAddress ||
                      !addOptionCity ||
                      !addOptionState ||
                      !addOptionZip)) ||
                  (addOptionLabel === 'Buyer/Seller Profile' &&
                    (!addOptionSigner ||
                      !addOptionPhone ||
                      !addOptionEmail ||
                      !addOptionAddress ||
                      !addOptionCity ||
                      !addOptionState ||
                      !addOptionZip)) ||
                  (addOptionLabel === 'Custom Column' &&
                    (isEmpty(addOptionType) || !addOptionSingleType))
                }
              >
                Save
              </Button>
            </DialogActions>
          </ValidatorForm>
        </BlockUi>
      </Dialog>
    </>
  )
}

export default withSession(AddOptionComponent)
