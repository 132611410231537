import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import concat from 'lodash/concat'
import merge from 'lodash/merge'
import map from 'lodash/map'
import slice from 'lodash/slice'
import difference from 'lodash/difference'
import findIndex from 'lodash/findIndex'

import { makeStyles } from '@mui/styles'

import { Card, CardContent, Divider, Typography } from '@mui/material'

function usePreferences({ activeOrg = {} }) {
  const buyAndHoldCardsToOrder = filter(
    [
      {
        name: 'strategy',
        card: (
          <img
            src="/images/buyAndHoldCards/Summary.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'targets',
        card: (
          <img
            src="/images/buyAndHoldCards/Targets.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'monthlyCashflow',
        card: (
          <img
            src="/images/buyAndHoldCards/Cashflow.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'acquisition',
        card: (
          <img
            src="/images/buyAndHoldCards/AcquisitionExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'income',
        card: (
          <img
            src="/images/buyAndHoldCards/Income.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'expenses',
        card: (
          <img
            src="/images/buyAndHoldCards/MonthlyExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'rehab',
        card: (
          <img
            src="/images/buyAndHoldCards/RehabExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'financing',
        card: (
          <img
            src="/images/buyAndHoldCards/Financing.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'projection',
        card: (
          <img
            src="/images/buyAndHoldCards/Assumptions.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'cashPositioning',
        card: (
          <img
            src="/images/buyAndHoldCards/CashPositioning.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'disposition',
        card: (
          <img
            src="/images/fixAndFlipCards/DispositionExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'comparableProperties',
        card: (
          <img
            src="/images/universalStrategyCards/CompsCard.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'deactive',
        card: (
          <Card className="card-box w-100">
            <div
              className="card-header"
              style={{ marginBottom: 5, marginTop: 5 }}
            >
              <div className="card-header--title">
                <b>Hide Cards</b>
              </div>
            </div>
            <Divider />
            <CardContent>
              <Typography>
                All cards past this one will be hidden
              </Typography>
            </CardContent>
          </Card>
        ),
      },
    ],
    (card) => !card.hide,
  )

  const fixAndFlipCardsToOrder = filter(
    [
      {
        name: 'bigPicture',
        hide: !activeOrg?.isRealeflow,
        card: (
          <img
            src="/images/fixAndFlipCards/BigPicture.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'bigPictureSummary',
        hide: !activeOrg?.isRealeflow,
        card: (
          <img
            src="/images/fixAndFlipCards/BigPictureSummary.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'strategy',
        card: (
          <img
            src="/images/fixAndFlipCards/Summary.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'targets',
        card: (
          <img
            src="/images/fixAndFlipCards/Targets.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'netProfit',
        card: (
          <img
            src="/images/fixAndFlipCards/NetProfit.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'acquisition',
        card: (
          <img
            src="/images/fixAndFlipCards/AcquisitionExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'income',
        card: (
          <img
            src="/images/fixAndFlipCards/Income.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'rehab',
        card: (
          <img
            src="/images/fixAndFlipCards/RehabExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'holding',
        card: (
          <img
            src="/images/fixAndFlipCards/HoldingExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'financing',
        card: (
          <img
            src="/images/fixAndFlipCards/Financing.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'disposition',
        card: (
          <img
            src="/images/fixAndFlipCards/DispositionExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'comparableProperties',
        card: (
          <img
            src="/images/universalStrategyCards/CompsCard.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'deactive',
        card: (
          <Card className="card-box w-100">
            <div
              className="card-header"
              style={{ marginBottom: 5, marginTop: 5 }}
            >
              <div className="card-header--title">
                <b>Hide Cards</b>
              </div>
            </div>
            <Divider />
            <CardContent>
              <Typography>
                All cards past this one will be hidden
              </Typography>
            </CardContent>
          </Card>
        ),
      },
    ],
    (card) => !card.hide,
  )

  const turnkeyCardsToOrder = filter(
    [
      {
        name: 'strategy',
        card: (
          <img
            src="/images/turnkeyCards/Summary.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'targets',
        card: (
          <img
            src="/images/turnkeyCards/Targets.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'monthlyCashflow',
        card: (
          <img
            src="/images/turnkeyCards/Cashflow.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'acquisition',
        card: (
          <img
            src="/images/turnkeyCards/AcquisitionExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'income',
        card: (
          <img
            src="/images/turnkeyCards/Income.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'expenses',
        card: (
          <img
            src="/images/turnkeyCards/MonthlyExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'rehab',
        card: (
          <img
            src="/images/turnkeyCards/RehabExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'financing',
        card: (
          <img
            src="/images/turnkeyCards/Financing.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'projection',
        card: (
          <img
            src="/images/turnkeyCards/Assumptions.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'cashPositioning',
        card: (
          <img
            src="/images/buyAndHoldCards/CashPositioning.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'disposition',
        card: (
          <img
            src="/images/fixAndFlipCards/DispositionExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'comparableProperties',
        card: (
          <img
            src="/images/universalStrategyCards/CompsCard.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'deactive',
        card: (
          <Card className="card-box w-100">
            <div
              className="card-header"
              style={{ marginBottom: 5, marginTop: 5 }}
            >
              <div className="card-header--title">
                <b>Hide Cards</b>
              </div>
            </div>
            <Divider />
            <CardContent>
              <Typography>
                All cards past this one will be hidden
              </Typography>
            </CardContent>
          </Card>
        ),
      },
    ],
    (card) => !card.hide,
  )

  const BRRRRCardsToOrder = filter(
    [
      {
        name: 'strategy',
        card: (
          <img
            src="/images/BRRRRCards/Strategy.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'targets',
        card: (
          <img
            src="/images/BRRRRCards/Targets.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'monthlyCashflow',
        card: (
          <img
            src="/images/BRRRRCards/MonthlyCashflow.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'acquisition',
        card: (
          <img
            src="/images/BRRRRCards/AcquisitionExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'income',
        card: (
          <img
            src="/images/BRRRRCards/GrossIncome.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'expenses',
        card: (
          <img
            src="/images/BRRRRCards/MonthlyExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'rehab',
        card: (
          <img
            src="/images/BRRRRCards/RehabExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'financing',
        card: (
          <img
            src="/images/BRRRRCards/Loans.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'projection',
        card: (
          <img
            src="/images/BRRRRCards/ValueOverTime.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'cashPositioning',
        card: (
          <img
            src="/images/BRRRRCards/CashPositioning.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'disposition',
        card: (
          <img
            src="/images/fixAndFlipCards/DispositionExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'comparableProperties',
        card: (
          <img
            src="/images/universalStrategyCards/CompsCard.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'deactive',
        card: (
          <Card className="card-box w-100">
            <div
              className="card-header"
              style={{ marginBottom: 5, marginTop: 5 }}
            >
              <div className="card-header--title">
                <b>Hide Cards</b>
              </div>
            </div>
            <Divider />
            <CardContent>
              <Typography>
                All cards past this one will be hidden
              </Typography>
            </CardContent>
          </Card>
        ),
      },
    ],
    (card) => !card.hide,
  )

  const shortTermRentalCardsToOrder = filter(
    [
      {
        name: 'strategy',
        card: (
          <img
            src="/images/shortTermRentalCards/Summary.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'targets',
        card: (
          <img
            src="/images/shortTermRentalCards/Targets.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'monthlyCashflow',
        card: (
          <img
            src="/images/shortTermRentalCards/Cashflow.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'acquisition',
        card: (
          <img
            src="/images/shortTermRentalCards/AcquisitionExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'income',
        card: (
          <img
            src="/images/shortTermRentalCards/Income.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'expenses',
        card: (
          <img
            src="/images/shortTermRentalCards/MonthlyExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'rehab',
        card: (
          <img
            src="/images/shortTermRentalCards/RehabExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'financing',
        card: (
          <img
            src="/images/shortTermRentalCards/Financing.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'projection',
        card: (
          <img
            src="/images/shortTermRentalCards/Assumptions.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'cashPositioning',
        card: (
          <img
            src="/images/shortTermRentalCards/CashPositioning.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'disposition',
        card: (
          <img
            src="/images/shortTermRentalCards/DispositionExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'comparableProperties',
        card: (
          <img
            src="/images/universalStrategyCards/CompsCard.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'deactive',
        card: (
          <Card className="card-box w-100">
            <div
              className="card-header"
              style={{ marginBottom: 5, marginTop: 5 }}
            >
              <div className="card-header--title">
                <b>Hide Cards</b>
              </div>
            </div>
            <Divider />
            <CardContent>
              <Typography>
                All cards past this one will be hidden
              </Typography>
            </CardContent>
          </Card>
        ),
      },
    ],
    (card) => !card.hide,
  )

  const buildToRentCardsToOrder = filter(
    [
      {
        name: 'strategy',
        card: (
          <img
            src="/images/buildToRentCards/Summary.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'targets',
        card: (
          <img
            src="/images/buildToRentCards/Targets.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'monthlyCashflow',
        card: (
          <img
            src="/images/buildToRentCards/Cashflow.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'income',
        card: (
          <img
            src="/images/buildToRentCards/Income.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'expenses',
        card: (
          <img
            src="/images/buildToRentCards/MonthlyExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'financing',
        card: (
          <img
            src="/images/buildToRentCards/Financing.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'endFinancing',
        card: (
          <img
            src="/images/buildToRentCards/EndFinancing.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'projection',
        card: (
          <img
            src="/images/buildToRentCards/Assumptions.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'cashPositioning',
        card: (
          <img
            src="/images/buildToRentCards/CashPositioning.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'disposition',
        card: (
          <img
            src="/images/buildToRentCards/DispositionExpenses.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'comparableProperties',
        card: (
          <img
            src="/images/universalStrategyCards/CompsCard.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'deactive',
        card: (
          <Card className="card-box w-100">
            <div
              className="card-header"
              style={{ marginBottom: 5, marginTop: 5 }}
            >
              <div className="card-header--title">
                <b>Hide Cards</b>
              </div>
            </div>
            <Divider />
            <CardContent>
              <Typography>
                All cards past this one will be hidden
              </Typography>
            </CardContent>
          </Card>
        ),
      },
    ],
    (card) => !card.hide,
  )

  const wholesaleCardsToOrder = filter(
    [
      {
        name: 'bigPicture',
        hide: !activeOrg?.isRealeflow,
        card: (
          <img
            src="/images/wholesaleCards/BigPicture.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'bigPictureSummary',
        hide: !activeOrg?.isRealeflow,
        card: (
          <img
            src="/images/wholesaleCards/BigPictureSummary.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'strategy',
        card: (
          <img
            src="/images/wholesaleCards/Summary.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'targets',
        card: (
          <img
            src="/images/wholesaleCards/Targets.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'netProfit',
        card: (
          <img
            src="/images/wholesaleCards/NetProfit.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'wholesale',
        card: (
          <img
            src="/images/wholesaleCards/Wholesale.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'comparableProperties',
        card: (
          <img
            src="/images/universalStrategyCards/CompsCard.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'deactive',
        card: (
          <Card className="card-box w-100">
            <div
              className="card-header"
              style={{ marginBottom: 5, marginTop: 5 }}
            >
              <div className="card-header--title">
                <b>Hide Cards</b>
              </div>
            </div>
            <Divider />
            <CardContent>
              <Typography>
                All cards past this one will be hidden
              </Typography>
            </CardContent>
          </Card>
        ),
      },
    ],
    (card) => !card.hide,
  )

  const BORCardsToOrder = filter(
    [
      {
        name: 'strategy',
        card: (
          <img
            src="/images/BORCards/Summary.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'targets',
        card: (
          <img
            src="/images/BORCards/Targets.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'BOR',
        card: (
          <img
            src="/images/BORCards/BOR.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'BORInputs',
        card: (
          <img
            src="/images/BORCards/BORInputs.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'comparableProperties',
        card: (
          <img
            src="/images/universalStrategyCards/CompsCard.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'deactive',
        card: (
          <Card className="card-box w-100">
            <div
              className="card-header"
              style={{ marginBottom: 5, marginTop: 5 }}
            >
              <div className="card-header--title">
                <b>Hide Cards</b>
              </div>
            </div>
            <Divider />
            <CardContent>
              <Typography>
                All cards past this one will be hidden
              </Typography>
            </CardContent>
          </Card>
        ),
      },
    ],
    (card) => !card.hide,
  )

  const astroflipCardsToOrder = filter(
    [
      {
        name: 'strategy',
        card: (
          <img
            src="/images/wholesaleCards/Summary.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'targets',
        card: (
          <img
            src="/images/wholesaleCards/Targets.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'netProfit',
        card: (
          <img
            src="/images/wholesaleCards/NetProfit.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'astroflip',
        card: (
          <img
            src="/images/wholesaleCards/Wholesale.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'comparableProperties',
        card: (
          <img
            src="/images/universalStrategyCards/CompsCard.png"
            alt=""
            style={{ width: '100%' }}
          />
        ),
      },
      {
        name: 'deactive',
        card: (
          <Card className="card-box w-100">
            <div
              className="card-header"
              style={{ marginBottom: 5, marginTop: 5 }}
            >
              <div className="card-header--title">
                <b>Hide Cards</b>
              </div>
            </div>
            <Divider />
            <CardContent>
              <Typography>
                All cards past this one will be hidden
              </Typography>
            </CardContent>
          </Card>
        ),
      },
    ],
    (card) => !card.hide,
  )

  // const getOrderDefault = (storedValues = [], cardsToOrder) => {
  //   const newCards = difference(
  //     map(
  //       filter(cardsToOrder, (card) => !card.hide),
  //       (card) => card.name,
  //     ),
  //     storedValues,
  //   )
  //   console.log(storedValues)
  //   console.log(newCards)
  //   const deactiveIndex = findIndex(
  //     storedValues,
  //     (value) => value === 'deactive',
  //   )
  //   return concat(
  //     slice(storedValues, 0, deactiveIndex),
  //     newCards,
  //     slice(storedValues, deactiveIndex),
  //   )
  // }

  return [
    {
      buyAndHoldCardsToOrder,
      fixAndFlipCardsToOrder,
      turnkeyCardsToOrder,
      BRRRRCardsToOrder,
      shortTermRentalCardsToOrder,
      buildToRentCardsToOrder,
      wholesaleCardsToOrder,
      BORCardsToOrder,
      astroflipCardsToOrder,
    },
    // {
    //   getOrderDefault,
    // },
  ]
}

export default usePreferences
