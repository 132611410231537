/**
 * @ Author: Housefolios
 * @ Create Time: 2022-04-26 15:44:38
 * @ Modified by: David Helmick
 * @ Modified time: 2022-12-01 19:10:09
 * @ Description: Dialog for removing access to a shared marketplace
 */

import React, { useState } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material'

import map from 'lodash/map'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'

import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { Query } from '@apollo/client/react/components'
import {
  GET_MARKETPLACES,
  GET_ORGS_MARKETPLACE_SHARED_WITH,
} from '../queries'
import { useMutation } from '@apollo/client'
import { REMOVE_SHARED_MARKETPLACE } from '../mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { Close } from '@mui/icons-material'

function RemoveSharedMarketplaceComponent(props) {
  const {
    orgId,
    marketplaceURL,
    rightMenu,
    sidebarMenu,
    shareAccess,
    canList,
  } = props

  const [openUnshareMarketplace, setOpenUnshareMarketplace] =
    useState(null)
  const [loader, setLoader] = useState(false)

  const handleOpenUnshareMarketplace = (event) => {
    setOpenUnshareMarketplace(true)
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const handleCloseUnshareMarketplace = (event) => {
    setOpenUnshareMarketplace(false)
    setLoader(false)
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const [removeSharedMarketplace] = useMutation(
    REMOVE_SHARED_MARKETPLACE,
  )

  const isRecipient = orgId === props.session.me.activeOrg._id

  return (
    <>
      {rightMenu && (
        <ListItem button onClick={handleOpenUnshareMarketplace}>
          <Close style={{ marginRight: 5, color: '#757575' }} />
          <span>Remove Shared Marketplace</span>
        </ListItem>
      )}
      {shareAccess && (
        <IconButton
          onClick={handleOpenUnshareMarketplace}
          size="large"
        >
          <FontAwesomeIcon icon={['far', 'circle-xmark']} />
        </IconButton>
      )}
      {sidebarMenu && (
        <Tooltip
          title="Remove Marketplace"
          classes={{ tooltip: 'tooltip-first' }}
          arrow
        >
          <IconButton
            onClick={handleOpenUnshareMarketplace}
            style={{
              backgroundColor: 'white',
              width: 30,
              height: 30,
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={openUnshareMarketplace}
        onClose={handleCloseUnshareMarketplace}
        maxWidth="sm"
        fullWidth={true}
        scroll="body"
        onClick={(event) => event.stopPropagation()}
      >
        <DialogTitle>
          {isRecipient
            ? 'Remove Shared Marketplace'
            : 'Remove Access'}
        </DialogTitle>
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              Removing Shared Marketplace
            </div>
          }
        >
          <DialogContent>
            <DialogContentText>
              {isRecipient
                ? 'Are you sure you want to remove this? You will lose access to this shared marketplace.'
                : `Are you sure you want to remove access to this marketplace for this user? ${
                    canList
                      ? 'They may have properties listed on the marketplace. If you remove their access, all of their properties will be removed from the marketplace'
                      : ''
                  }`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={handleCloseUnshareMarketplace}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: '#f83145', color: 'white' }}
              onClick={() => {
                removeSharedMarketplace({
                  variables: {
                    orgId,
                    url: marketplaceURL,
                  },
                  refetchQueries: [
                    {
                      query: GET_ORGS_MARKETPLACE_SHARED_WITH,
                      variables: {
                        url: marketplaceURL,
                      },
                    },
                    {
                      query: GET_ME,
                    },
                    {
                      query: GET_MARKETPLACES,
                      variables: {
                        marketplaces: [marketplaceURL],
                        approved: true,
                      },
                    },
                  ],
                })
                  .then(() => {
                    enqueueSnackbar(
                      `Marketplace succsesfully Unshared`,
                      {
                        variant: 'success',
                        autoHideDuration: 3000,
                      },
                    )
                    handleCloseUnshareMarketplace()
                  })
                  .catch((error) => {
                    enqueueSnackbar(
                      `Failed to Unshared Marketplace`,
                      {
                        variant: 'error',
                        autoHideDuration: 3000,
                      },
                    )
                    handleCloseUnshareMarketplace()
                  })
              }}
            >
              Remove
            </Button>
          </DialogActions>
        </BlockUi>
      </Dialog>
    </>
  )
}
export default withSession(RemoveSharedMarketplaceComponent)
