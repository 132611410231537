import React, { lazy, useState } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Grid,
  Menu,
  Tooltip,
  Typography,
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

const CreatePortfolio = lazy(() =>
  import('@/housefolios-components/Portfolio/CreatePortfolio'),
)
const AddPropertyButton = lazy(() =>
  import('@/housefolios-components/TitleButtons/AddPropertyButton'),
)
const CreateNewOrg = lazy(() =>
  import(
    '@/housefolios-components/OrganizationSettings/CreateNewOrg'
  ),
)
const UploadProperties = lazy(() =>
  import('@/housefolios-components/Properties/UploadProperties'),
)

// const inputFields = [
//   {
//     icon1: 'far',
//     icon2: 'play-circle',
//     title: 'Add Property',
//     subTitle: 'Specific address',
//     onClick: (handleOpenCreateNewPortfolio)
//   },
//   {
//     icon1: 'far',
//     icon2: 'question-circle',
//     title: 'Upload Properties',
//     subTitle: 'Multiple addresses'
//   },
//   {
//     icon1: 'fas',
//     icon2: 'magnifying-glass',
//     title: 'Property Searcy',
//     subTitle: 'Search active listings'
//   },
//   {
//     icon1: 'fas',
//     icon2: 'sign-hanging',
//     title: 'Marketplace',
//     subTitle: 'Pre-analyzed deals'
//   },
//   {
//     icon1: 'far',
//     icon2: 'folder',
//     title: 'Create Portfolio',
//     subTitle: 'Keep it organized'
//   },
//   {
//     icon1: 'far',
//     icon2: 'building',
//     title: 'Organization',
//     subTitle: 'Starting new project?'
//   },
// ]

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const HeaderDots = (props) => {
  const [anchorEl3, setAnchorEl3] = useState(null)

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget)
  }
  const handleClose3 = () => {
    setAnchorEl3(null)
  }

  // const [completed, setCompleted] = useState(0);

  // React.useEffect(() => {
  //   function progress() {
  //     setCompleted((oldCompleted) => {
  //       if (oldCompleted === 100) {
  //         return 0;
  //       }
  //       const diff = Math.random() * 10;
  //       return Math.min(oldCompleted + diff, 100);
  //     });
  //   }

  //   const timer = setInterval(progress, 500);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  const inputFields =
    props?.session?.me?.activeOrg?.member === 'RWN'
      ? [
          {
            icon1: 'far',
            icon2: 'map-marker-plus',
            title: 'Single Address',
            subTitle: 'Listed or off-market',
            //onClick: handleOpenCreateNewPortfolio
            component: (
              <AddPropertyButton
                key="add"
                headerDot={true}
                closeParent={handleClose3}
                disabled={!Boolean(anchorEl3)}
              />
            ),
          },
          {
            icon1: 'far',
            icon2: 'file-excel',
            title: 'Multiple Addresses',
            subTitle: 'Upload Spreadsheet',
            component: (
              <UploadProperties
                key="upload"
                menu
                closeParent={handleClose3}
                disabled={!Boolean(anchorEl3)}
              />
            ),
          },
          {
            icon1: 'far',
            icon2: 'sign-hanging',
            title: 'Marketplace',
            subTitle: 'Pre-analyzed deals',
            href: '/marketplace',
            xs: 12,
          },
          {
            icon1: 'far',
            icon2: 'fa-folder-plus',
            title: 'Create Portfolio',
            subTitle: 'Keep it organized',
            component: (
              <CreatePortfolio
                key="newportfolio"
                headerDot={true}
                disabled={!Boolean(anchorEl3)}
              />
            ),
          },
          {
            icon1: 'far',
            icon2: 'building',
            title: 'Organization',
            subTitle: 'Starting new project?',
            component: (
              <CreateNewOrg
                key="neworg"
                headerDot={true}
                disabled={!Boolean(anchorEl3)}
              />
            ),
          },
        ]
      : [
          {
            icon1: 'far',
            icon2: 'map-marker-plus',
            title: 'Single Address',
            subTitle: 'Listed or off-market',
            //onClick: handleOpenCreateNewPortfolio
            component: (
              <AddPropertyButton
                key="add"
                headerDot={true}
                closeParent={handleClose3}
                disabled={!Boolean(anchorEl3)}
              />
            ),
          },
          {
            icon1: 'far',
            icon2: 'file-excel',
            title: 'Multiple Addresses',
            subTitle: 'Upload Spreadsheet',
            component: (
              <UploadProperties
                key="upload"
                menu
                closeParent={handleClose3}
                disabled={!Boolean(anchorEl3)}
              />
            ),
          },
          {
            icon1: 'far',
            icon2: 'magnifying-glass',
            title: 'Property Search',
            subTitle: 'Search active listings',
            href: '/property-search',
          },
          {
            icon1: 'far',
            icon2: 'sign-hanging',
            title: 'Marketplace',
            subTitle: 'Pre-analyzed deals',
            href: '/marketplace',
          },
          {
            icon1: 'far',
            icon2: 'fa-folder-plus',
            title: 'Create Portfolio',
            subTitle: 'Keep it organized',
            component: (
              <CreatePortfolio
                key="newportfolio"
                headerDot={true}
                disabled={!Boolean(anchorEl3)}
              />
            ),
          },
          {
            icon1: 'far',
            icon2: 'building',
            title: 'Organization',
            subTitle: 'Starting new project?',
            component: (
              <CreateNewOrg
                key="neworg"
                headerDot={true}
                disabled={!Boolean(anchorEl3)}
              />
            ),
          },
        ]

  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
        {/* <span className="pr-2">
          <Badge
            variant="dot"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            badgeContent=" "
            classes={{ badge: 'bg-success badge-circle' }}>
            <Button
              onClick={handleClick1}
              className="btn-transition-none bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
              <span>
                <NotificationsActiveTwoToneIcon />
              </span>
            </Button>
          </Badge>

          <Popover
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            classes={{
              paper: 'app-header-dots'
            }}>
            <div className="popover-custom-lg overflow-hidden">
              <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                <div className="bg-composed-img-3 bg-composed-wrapper--image" />
                <div className="bg-composed-wrapper--content text-white px-2 py-4">
                  <h4 className="font-size-xl font-weight-bold mb-2">
                    Notifications
                  </h4>
                  <p className="opacity-8 mb-0">
                    You have <b className="text-success">472</b> new messages
                  </p>
                </div>
              </div>
              <div className="mx-2">
                <Tabs
                  className="nav-tabs-primary"
                  value={value}
                  variant="fullWidth"
                  onChange={handleChange}>
                  <Tab label="Timeline" />
                  <Tab label="Tasks" />
                  <Tab label="Reports" />
                </Tabs>
              </div>
              <div className="height-280">
                <PerfectScrollbar>
                  <TabPanel value={value} index={0}>
                    <div className="timeline-list timeline-list-offset timeline-list-offset-dot">
                      <div className="timeline-item">
                        <div className="timeline-item-offset">9:25</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            1991
                          </h4>
                          <p>
                            The World Wide Web goes live with its first web
                            page.
                          </p>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">9:25</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            Java exam day
                          </h4>
                          <p>
                            Bill Clinton's presidential scandal makes it online.
                          </p>
                          <div className="avatar-wrapper-overlap mt-2 mb-1">
                            <div className="avatar-icon-wrapper avatar-icon-sm">
                              <div className="avatar-icon">
                                <img alt="..." src={avatar1} />
                              </div>
                            </div>
                            <div className="avatar-icon-wrapper avatar-icon-sm">
                              <div className="avatar-icon">
                                <img alt="..." src={avatar2} />
                              </div>
                            </div>
                            <div className="avatar-icon-wrapper avatar-icon-sm">
                              <div className="avatar-icon">
                                <img alt="..." src={avatar6} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">9:25</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            Business investor meeting
                          </h4>
                          <p>
                            Mosaic, the first graphical browser, is introduced
                            to the average consumer.
                          </p>
                          <div className="mt-3">
                            <a href="#/" onClick={(e) => e.preventDefault()}>
                              <img
                                alt="..."
                                className="img-fluid rounded mr-3 shadow-sm"
                                src={people1}
                                width="70"
                              />
                            </a>
                            <a href="#/" onClick={(e) => e.preventDefault()}>
                              <img
                                alt="..."
                                className="img-fluid rounded shadow-sm"
                                src={people3}
                                width="70"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">9:25</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            Learning round table gathering
                          </h4>
                          <p>First ever iPod launches.</p>
                          <div className="mt-2">
                            <Button
                              size="small"
                              variant="contained"
                              className="btn-primary">
                              Submit Report
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="timeline-item">
                        <div className="timeline-item-offset">9:25</div>
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon" />
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            2003
                          </h4>
                          <p>
                            MySpace becomes the most popular social network.
                          </p>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div className="text-center my-2">
                      <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                          <FontAwesomeIcon
                            icon={['fas', 'xmark']}
                            className="d-flex align-self-center display-3"
                          />
                        </div>
                      </div>
                      <h6 className="font-weight-bold font-size-lg mb-1 mt-4 text-black">
                        Incoming messages
                      </h6>
                      <p className="text-black-50 mb-0">
                        You have pending actions to take care of.
                      </p>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <div className="text-center text-black font-size-lg pb-1 font-weight-bold">
                      Total Sales
                      <small className="d-block text-black-50">
                        Total performance for selected period
                      </small>
                    </div>
                    <div className="px-2 pb-3 pt-2">
                      <Chart
                        options={chartHeaderDotsOptions}
                        series={chartHeaderDotsData}
                        type="bar"
                        height={148}
                      />
                    </div>
                  </TabPanel>
                </PerfectScrollbar>
              </div>
              <Divider />
              <div className="text-center py-3">
                <Badge
                  color="error"
                  variant="dot"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}>
                  <Button
                    className="btn-gradient bg-midnight-bloom px-4"
                    variant="contained">
                    <span className="btn-wrapper--label">Learn more</span>
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </span>
                  </Button>
                </Badge>
              </div>
            </div>
          </Popover>
        </span> */}
        {/* <span className="pr-2">
          <Badge
            variant="dot"
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            badgeContent=" "
            classes={{ badge: 'bg-danger badge-circle-sm' }}>
            <Button
              onClick={handleClick2}
              className="btn-transition-none bg-neutral-danger text-danger font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
              <span>
                <PollTwoToneIcon />
              </span>
            </Button>
          </Badge>
          <Popover
            open={open2}
            anchorEl={anchorEl2}
            onClose={handleClose2}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <List
              component="div"
              className="list-group-flush text-left bg-transparent">
              <ListItem>
                <div className="align-box-row">
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    variant="dot">
                    <Avatar alt="Travis Howard" src={avatar7} />
                  </Badge>
                  <div className="pl-3">
                    <span className="font-weight-bold d-block">
                      Emma Taylor
                    </span>
                    <small className="pb-0 text-black-50 d-block">
                      This is an accountant profile
                    </small>
                  </div>
                </div>
              </ListItem>
              <ListItem className="d-block py-2">
                <div className="align-box-row mb-1">
                  <div>
                    <small className="font-weight-bold">
                      Profile completion
                    </small>
                  </div>
                </div>
                <LinearProgress
                  variant="determinate"
                  value={85}
                  className="progress-bar-success progress-animated-alt progress-bar-rounded"
                />
                <div className="align-box-row progress-bar--label mt-1 text-muted">
                  <small className="text-dark">0</small>
                  <small className="ml-auto">100%</small>
                </div>
              </ListItem>
              <ListItem className="d-block text-center p-3">
                <Button
                  variant="contained"
                  size="small"
                  className="btn-primary">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'right-from-bracket']} />
                  </span>
                  <span className="btn-wrapper--label">Logout</span>
                </Button>
              </ListItem>
            </List>
          </Popover>
        </span> */}
        <span className="pr-2">
          <Tooltip title="Common Actions">
            <Button
              onClick={handleClick3}
              style={{ backgroundColor: '#28b854' }}
              className="font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded btn-transition-none"
            >
              <span>
                <AddIcon style={{ color: 'white' }} />
              </span>
            </Button>
          </Tooltip>
          <Menu
            anchorEl={anchorEl3}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl3)}
            classes={{ list: 'p-0' }}
            onClose={handleClose3}
          >
            <div className="dropdown-menu-xl overflow-hidden p-0">
              <div className="grid-menu grid-menu-2col">
                <Grid container spacing={0}>
                  {map(inputFields, (section) => {
                    if (!section.component) {
                      return (
                        <Grid
                          key={section.title}
                          item
                          sm={section.xs || 6}
                        >
                          <Button
                            href={section.href}
                            style={{ textAlign: 'center' }}
                            variant="text"
                            disabled={!Boolean(anchorEl3)}
                            className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"
                          >
                            <FontAwesomeIcon
                              icon={[
                                `${section.icon1}`,
                                `${section.icon2}`,
                              ]}
                              className="h2 d-block mb-2 mx-auto mt-1 text-success"
                            />
                            <div className="font-weight-bold text-black">
                              {section.title}
                            </div>
                            <div className="font-size-md mb-1 text-black-50">
                              {section.subTitle}
                            </div>
                          </Button>
                        </Grid>
                      )
                    } else {
                      return section.component
                    }
                  })}
                  {/* <Grid item sm={6}>
                    <Button
                      variant="text"
                      className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1">
                      <FontAwesomeIcon
                        icon={['far', 'circle-question']}
                        className="h2 d-block mx-auto mb-2 mt-1 text-success"
                      />
                      <div className="font-weight-bold text-black">
                        Upload Properties
                      </div>
                      <div className="font-size-md mb-1 text-black-50">
                        Multiple addresses
                      </div>
                    </Button>
                  </Grid>
                  <Grid item sm={6}>
                    <Button
                      variant="text"
                      className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1">
                      <FontAwesomeIcon
                        icon={['far', 'compass']}
                        className="h2 d-block mb-2 mx-auto mt-1 text-success"
                      />
                      <div className="font-weight-bold text-black">
                        Create Portfolio
                      </div>
                      <div className="font-size-md mb-1 text-black-50">
                        Keep it organized
                      </div>
                    </Button>
                  </Grid>
                  <Grid item sm={6}>
                    <Button
                      variant="text"
                      className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1">
                      <FontAwesomeIcon
                        icon={['far', 'comment-dots']}
                        className="h2 d-block mx-auto mb-2 mt-1 text-success"
                      />
                      <div className="font-weight-bold text-black">Organization</div>
                      <div className="font-size-md mb-1 text-black-50">
                        Starting new project?
                      </div>
                    </Button>
                  </Grid> */}
                </Grid>
              </div>
            </div>
          </Menu>
        </span>
      </div>
    </>
  )
}

export default HeaderDots
