import React, { useState, useMemo } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
  Box,
  Chip,
  TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import find from 'lodash/find'

const PRIORITIES = [
  { _id: 'highest', name: 'Highest' },
  { _id: 'high', name: 'High' },
  { _id: 'medium', name: 'Medium' },
  { _id: 'low', name: 'Low' },
  { _id: 'lowest', name: 'Lowest' },
]

const YES_NO_LISTITEMS = [
  { _id: null, name: 'Please select an option' },
  { _id: 'yes', name: 'Yes' },
  { _id: 'no', name: 'No' },
]

function EditCardDialog({
  open,
  handleClose,
  property,
  handlePropertyStateSubmit,
  teamMembers = [],
  userId,
}) {
  const [comments, setComments] = useState(property.comments || [])
  const [newComment, setNewComment] = useState('')
  const [propertyState, setPropertyState] = useState(
    property.kanbanProcessStageState || {
      dueDate: Date.now(),
      priority: '',
      pointOfContact: '',
      onMarket: null,
      exclusiveBuyPrice: null,
      labels: [],
    },
  )
  const [addLabelDialogOpen, setAddLabelDialogOpen] = useState(false)
  const [newLabel, setNewLabel] = useState('')

  const transformedTeamMembersList = useMemo(
    () =>
      teamMembers?.map((member) => ({
        _id: `${member?.profile.firstname} ${member?.profile.lastname}`,
        name: `${member?.profile.firstname} ${member?.profile.lastname}`,
      })),
    [teamMembers],
  )

  const handleCommentSubmit = () => {
    if (newComment.trim()) {
      const newComments = [
        {
          text: newComment,
          posterId: userId,
          date: new Date().toLocaleString(),
        },
        ...comments,
      ]
      setComments(newComments)
      setNewComment('')
      if (typeof handlePropertyStateSubmit === 'function') {
        handlePropertyStateSubmit(null, newComments) //Save comments
      }
    }
  }

  const handlePropertyStateChange = (field, value) => {
    setPropertyState((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleAddLabel = () => {
    if (newLabel.trim()) {
      setPropertyState((prevState) => ({
        ...prevState,
        labels: [...prevState.labels, newLabel],
      }))
      setNewLabel('')
      setAddLabelDialogOpen(false)
    }
  }

  const handleRemoveLabel = (labelToRemove) => {
    setPropertyState((prevState) => ({
      ...prevState,
      labels: prevState.labels.filter(
        (label) => label !== labelToRemove,
      ),
    }))
  }

  const handleSaveClick = () => {
    if (typeof handlePropertyStateSubmit === 'function') {
      handlePropertyStateSubmit(propertyState, comments) // Trigger the save logic
    }
    handleClose() // Close the dialog after saving
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {`${property.address}, ${property.city}, ${property.state}, ${property.zip}`}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {/* Comments Section */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                Comments
              </Typography>
              <List
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: 1,
                  minHeight: '300px',
                }}
              >
                {comments.map((comment, index) => {
                  const poster = find(teamMembers, (member) => {
                    return member._id === comment.posterId
                  })
                  const posterName = `${poster?.profile.firstname} ${poster?.profile.lastname}`
                  return (
                    <ListItem key={index} divider>
                      <ListItemText
                        primary={comment.text}
                        secondary={`Posted by ${
                          poster?.profile ? posterName : 'Not on Team'
                        } - ${comment.date}`}
                        style={{ wordWrap: 'break-word' }}
                      />
                    </ListItem>
                  )
                })}
              </List>
              <TextField
                label="New Comment"
                fullWidth
                multiline
                rows={3}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                sx={{ marginTop: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleCommentSubmit}
                fullWidth
                sx={{ marginTop: 1 }}
              >
                Submit Comment
              </Button>
            </Grid>

            {/* Input Fields */}
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                {/* Due Date Field */}
                <DatePicker
                  label="Due Date"
                  value={new Date(propertyState.dueDate)}
                  onChange={(value) =>
                    handlePropertyStateChange('dueDate', value)
                  }
                  renderInput={(params) => <TextField {...params} />}
                  fullWidth
                />

                {/* Priority Field */}
                <TextField
                  label="Priority"
                  select
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={propertyState.priority || ''}
                  onChange={(e) =>
                    handlePropertyStateChange(
                      'priority',
                      e.target.value,
                    )
                  }
                  SelectProps={{
                    native: true,
                  }}
                >
                  {!propertyState.priority && (
                    <option value="" disabled>
                      Please select a priority
                    </option>
                  )}
                  {PRIORITIES.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>

                {/* Point of Contact Field */}
                <TextField
                  label="Point of Contact"
                  select
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={propertyState.pointOfContact || ''}
                  onChange={(e) =>
                    handlePropertyStateChange(
                      'pointOfContact',
                      e.target.value,
                    )
                  }
                  SelectProps={{
                    native: true,
                  }}
                >
                  {!propertyState.pointOfContact && (
                    <option value="" disabled>
                      Please select a contact
                    </option>
                  )}
                  {transformedTeamMembersList.map((member) => (
                    <option key={member._id} value={member._id}>
                      {member.name}
                    </option>
                  ))}
                </TextField>

                {/* On Market Field */}
                <TextField
                  label="On Market"
                  select
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={propertyState.onMarket || ''}
                  onChange={(e) =>
                    handlePropertyStateChange(
                      'onMarket',
                      e.target.value,
                    )
                  }
                  SelectProps={{
                    native: true,
                  }}
                >
                  {!propertyState.onMarket && (
                    <option value="" disabled>
                      Please select an option
                    </option>
                  )}
                  {YES_NO_LISTITEMS.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>

                {/* Exclusive Buy Price Field */}
                <TextField
                  label="Exclusive Buy Price"
                  select
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={propertyState.exclusiveBuyPrice || ''}
                  onChange={(e) =>
                    handlePropertyStateChange(
                      'exclusiveBuyPrice',
                      e.target.value,
                    )
                  }
                  SelectProps={{
                    native: true,
                  }}
                >
                  {!propertyState.exclusiveBuyPrice && (
                    <option value="" disabled>
                      Please select an option
                    </option>
                  )}
                  {YES_NO_LISTITEMS.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>

                {/* Labels Field */}
                <Box>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => setAddLabelDialogOpen(true)}
                    sx={{ marginBottom: 1 }}
                  >
                    Add new label
                  </Button>
                  <Box
                    sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}
                  >
                    {propertyState.labels.map((label, index) => (
                      <Chip
                        key={index}
                        label={label}
                        onDelete={() => handleRemoveLabel(label)}
                        color="primary"
                        sx={{ marginBottom: 1 }}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button
            onClick={handleSaveClick}
            color="primary"
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add New Label Dialog */}
      <Dialog
        open={addLabelDialogOpen}
        onClose={() => setAddLabelDialogOpen(false)}
      >
        <DialogTitle>Add New Label</DialogTitle>
        <DialogContent>
          <TextField
            label="Label Name"
            fullWidth
            value={newLabel}
            onChange={(e) => setNewLabel(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setAddLabelDialogOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleAddLabel} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditCardDialog
