import { gql } from '@apollo/client'

export const COPY_PROPERTY = gql`
  mutation copyProperty(
    $propertyId: String!
    $portfolioId: String!
    $noDuplicates: Boolean
    $marketplace: Boolean
    $keepAssumptions: Boolean
  ) {
    copyProperty(
      propertyId: $propertyId
      portfolioId: $portfolioId
      noDuplicates: $noDuplicates
      marketplace: $marketplace
      keepAssumptions: $keepAssumptions
    ) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      propertyType
      propertyUnitAmount
      propertyDashboard
      hideAddress
      hideDaysOnMarketplace
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
      compCount
      lot {
        _id
        owned
        status
      }
      portfolio {
        _id
        name
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const COPY_PROPERTIES = gql`
  mutation copyProperties(
    $propertyIds: [String!]
    $portfolioId: String!
    $noDuplicates: Boolean
    $marketplace: Boolean
    $keepAssumptions: Boolean
  ) {
    copyProperties(
      propertyIds: $propertyIds
      portfolioId: $portfolioId
      noDuplicates: $noDuplicates
      marketplace: $marketplace
      keepAssumptions: $keepAssumptions
    ) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      propertyType
      propertyUnitAmount
      propertyDashboard
      hideAddress
      hideDaysOnMarketplace
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
      compCount
      lot {
        _id
        owned
        status
      }
      portfolio {
        _id
        name
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const SHARE_PROPERTY = gql`
  mutation shareProperty(
    $id: ID!
    $emails: [String!]
    $sharePermission: String
    $notificationId: String
  ) {
    shareProperty(
      id: $id
      emails: $emails
      sharePermission: $sharePermission
      notificationId: $notificationId
    ) {
      count
    }
  }
`

export const ADD_SHARED_PROPERTY = gql`
  mutation addSharedProperty($token: String!, $orgId: String!) {
    addSharedProperty(token: $token, orgId: $orgId) {
      _id
    }
  }
`
