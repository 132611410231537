import React, { useState } from 'react'

export function useTour() {
  const [openTour, setOpenTour] = useState(false)
  const [openVerifyFinancialsTour, setOpenVerifyFinancialsTour] =
    useState(false)
  const [openAnalyzeCompsTour, setOpenAnalyzeCompsTour] =
    useState(false)
  const [openChooseStrategyTour, setOpenChooseStrategyTour] =
    useState(false)
  const [openAnalyzeAreaTour, setOpenAnalyzeAreaTour] =
    useState(false)
  const [openTakeActionTour, setOpenTakeActionTour] = useState(false)
  const [openFeedbackTour, setOpenFeedbackTour] = useState(false)
  return {
    openAnalyzeAreaTour,
    openAnalyzeCompsTour,
    openChooseStrategyTour,
    openFeedbackTour,
    openTakeActionTour,
    openTour,
    openVerifyFinancialsTour,
    setOpenAnalyzeAreaTour,
    setOpenAnalyzeCompsTour,
    setOpenChooseStrategyTour,
    setOpenFeedbackTour,
    setOpenTakeActionTour,
    setOpenTour,
    setOpenVerifyFinancialsTour,
  }
}
