import { gql } from '@apollo/client'

export const ADD_PROPERTY = gql`
  mutation addProperty(
    $propertyInput: propertyInput!
    $noDuplicates: Boolean
    $propertySearch: Boolean
  ) {
    addProperty(
      propertyInput: $propertyInput
      noDuplicates: $noDuplicates
      propertySearch: $propertySearch
    ) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      hideAddress
      propertyType
      propertyUnitAmount
      propertyDashboard
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
      portfolio {
        _id
        name
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const ADD_COMPPROPERTY = gql`
  mutation addCompProperty($compPropertyInput: compPropertyInput!) {
    addCompProperty(compPropertyInput: $compPropertyInput) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      createdAt
      updatedAt
      estval
      initialListPrice
      daysOnMarket
      rehabLevel
      saleDate
      rentalPrice
      occupancyRate
      accommodates
      rentalURL
      rentalImage
      priceAdjustment
      potential
      rental
      propertyType
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      createdBy {
        _id
        email
        username
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const ADD_COMPPROPERTY_BACKEND = gql`
  mutation addCompsBackend($propertyIds: [String]!) {
    addCompsBackend(propertyIds: $propertyIds) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      createdAt
      updatedAt
      estval
      initialListPrice
      daysOnMarket
      rehabLevel
      saleDate
      rentalPrice
      occupancyRate
      accommodates
      rentalURL
      rentalImage
      priceAdjustment
      potential
      rental
      propertyType
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      createdBy {
        _id
        email
        username
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const IMAGE_UPLOAD = gql`
  mutation imageUpload(
    $documentId: String!
    $imageUrls: [String]!
    $collection: String
  ) {
    imageUpload(
      documentId: $documentId
      imageUrls: $imageUrls
      collection: $collection
    ) {
      _id
      estval
      address
      city
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      propertyType
      propertyUnitAmount
      propertyDashboard
      hideAddress
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
    }
  }
`

export const ADD_COMPPROPERTIES = gql`
  mutation addCompProperties(
    $compPropertyInputs: [compPropertyInput!]
  ) {
    addCompProperties(compPropertyInputs: $compPropertyInputs) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      createdAt
      updatedAt
      estval
      initialListPrice
      daysOnMarket
      rehabLevel
      saleDate
      rentalPrice
      occupancyRate
      accommodates
      rentalURL
      rentalImage
      priceAdjustment
      potential
      rental
      propertyType
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      createdBy {
        _id
        email
        username
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const IMAGE_UPLOAD_MULTI = gql`
  mutation imageUploadMulti(
    $propertyImagePairs: [propertyImagePair]!
    $collection: String
    $first: Boolean
  ) {
    imageUploadMulti(
      propertyImagePairs: $propertyImagePairs
      collection: $collection
      first: $first
    ) {
      _id
      estval
      address
      city
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      propertyType
      propertyUnitAmount
      propertyDashboard
      hideAddress
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
    }
  }
`
