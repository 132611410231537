import { useApolloClient } from '@apollo/client'
import { CALL_REAPI } from '@/housefolios-components/PropertySearch/queries'
import { GET_ZIP_VALUE } from '@/housefolios-components/TitleButtons/AddPropertyButton/queries'

import head from 'lodash/head'
import padStart from 'lodash/padStart'
import truncate from 'lodash/truncate'

export const getPropertyData = async (client, input) => {
  //SPECIFIC COMMENT: If we wish to revert back to house canary we need to do 3 things.
  // 1) uncomment the call house canary query
  // 2) make call reapi pep only again
  // 3) comment out the convert reapi to housecanary code
  // const canaryResult = await client.query({
  //   query: CALL_HOUSE_CANARY,
  //   variables: {
  //     address: streetAddress,
  //     zipcode: padStart(truncate(location.postal_code, {
  //       length: 5,
  //       omission: '',
  //     }), 5, '0'),
  //     // propertyDetails: false,
  //     // salesHistory: true,
  //     // flood: true,
  //     // blockCrime: true,
  //     // school: true,
  //     // taxHistory: true,
  //   },
  //   errorPolicy: 'ignore',
  // })
  // const canaryData = canaryResult.data || {}
  // const canaryError = canaryResult.error
  // if (canaryError) {
  //   console.log(canaryError)
  // }

  // let propertyData = canaryData.callHouseCanary
  let propertyData

  let reapiPropertyData = null
  // if ((props.session.me.activeOrg.isRealeflow)) {
  const {
    data: { callREAPI },
  } = await client.query({
    query: CALL_REAPI,
    variables: {
      REAPIInput: input,
    },
  })
  reapiPropertyData = callREAPI || []
  // const {
  //   data: { callREAPISkipTrace },
  // } = await client.query({
  //   query: CALL_REAPI_SKIPTRACE,
  //   variables: {
  //     address: streetAddress,
  //     city: location.locality,
  //     state: location.administrative_area_level_1,
  //     zip: padStart(truncate(location.postal_code, {
  //       length: 5,
  //       omission: '',
  //     }), 5, '0'),
  //   }
  // })
  // }

  //SPECIFIC COMMENT: Converting reapi data into the form of housecanary data
  propertyData = {
    address: [head(reapiPropertyData)?.address],
    city: [head(reapiPropertyData)?.city],
    state: [head(reapiPropertyData)?.state],
    zip: [head(reapiPropertyData)?.zip],
    beds: [head(reapiPropertyData)?.beds],
    baths: [head(reapiPropertyData)?.baths],
    sqft: [head(reapiPropertyData)?.sqft],
    year: [head(reapiPropertyData)?.yearBuilt],
    value: {
      min: [head(reapiPropertyData)?.estimatedValue],
      max: [head(reapiPropertyData)?.estimatedValue],
      mean: [head(reapiPropertyData)?.estimatedValue],
    },
  }

  return {
    propertyData,
    reapiPropertyData,
  }
}

export const getZipValues = async (client, zip) => {
  const zipResult = await client.query({
    query: GET_ZIP_VALUE,
    variables: {
      zipcode: zip
        ? padStart(
            truncate(zip, {
              length: 5,
              omission: '',
            }),
            5,
            '0',
          )
        : '',
    },
    errorPolicy: 'ignore',
  })
  const data = zipResult.data
  const error = zipResult.error
  if (error) {
    console.log(error)
  }
  const zipValues = !data ? null : data.zipValue
  return [zipValues, zipResult.loading]
}
