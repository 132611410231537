/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:20:09
 * @ Modified by: David Helmick
 * @ Modified time: 2021-12-16 12:41:10
 * @ Description: Component wrapper to catch and display errors
 */

import React, { Component } from 'react'
import { Typography } from '@mui/material'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, errorMessage: '' }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, errorMessage: error.message })
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Typography
          align="center"
          variant="h1"
          style={{ marginTop: '30%' }}
        >
          Something went wrong.
          <Typography variant="h4">
            {this.state.errorMessage}
            <Typography variant="body1">
              Try refreshing the page. If error persists then contact
              support.
            </Typography>
          </Typography>
        </Typography>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
