import { gql } from '@apollo/client'

export const NEW_REALEFLOW_IMPORT_SUBSCRIPTION = gql`
  subscription NewLeadsFromRealeflow($orgId: String!) {
    newLeadsFromRealeflow(orgId: $orgId) {
      awaitingRealeflow
      duplicateProperties
      orgId
    }
  }
`
export const LEADS_NOTIFICATION = gql`
  subscription LeadsNotification($orgId: String!, $userId: String) {
    getNewLeadsNotification(orgId: $orgId, userId: $userId) {
      message
      notificationType
      batchId
      dateImported
      orgId
      leadsCount
    }
  }
`
