import juice from 'juice'

export function pepContactAgentEmail(
  affiliateParams,
  address = '',
  city = '',
  state = '',
  zip = '',
  offerPrice = '',
  conditions = '',
  statements = '',
  inspectionPeriod = '',
  daysToClose = '',
  earnestMoney = '',
  entityName = '',
  entityAddress = '',
  entityCity = '',
  entityState = '',
  entityZip = '',
  entityEmail = '',
  entityPhone = '',
  entitySigner = '',
  introduction = '',
) {
  const {
    headerLogo,
    hideSocialIcons,
    hideOrgInfo,
    affiliateName,
    affiliateURL,
    linkStart,
  } = affiliateParams

  const fullHtml = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Your Home Offer</title>
    <style>
      /* Your styles here */
      body {
        margin: 0;
        padding: 0;
        background-color: #f4f4f4;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
      .container {
        max-width: 600px;
        margin: 20px auto;
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }
      .header {
        background-color: #ffffff;
        padding: 20px;
        text-align: center;
      }
      .header img {
        max-width: 150px;
        height: auto;
      }
      .divider {
        border-top: 3px solid #00A4E4;
        margin: 0 20px;
      }
      .content {
        padding: 20px;
        color: #333333;
      }
      .intro {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.6;
      }
      .intro h1 {
        font-size: 24px;
        color: #00A4E4;
        margin-bottom: 10px;
        text-align: center;
      }
      .offer-details, .buyer-info {
        margin: 20px 0;
        border: 1px solid #e0e0e0;
        padding: 15px;
        background-color: #f9f9f9;
      }
      .offer-details p,
      .buyer-info p {
        font-size: 16px;
        line-height: 1.6;
        margin: 10px 0;
      }
      .offer-details p strong,
      .buyer-info p strong {
        color: #555555;
      }
      .footer {
        background-color: #fafafa;
        padding: 20px;
        text-align: center;
        font-size: 12px;
        color: #888888;
        border-top: 1px solid #e0e0e0;
      }
      @media only screen and (max-width: 600px) {
        .container {
          width: 100% !important;
        }
        .divider {
          margin: 0 10px;
        }
        .header, .content, .footer {
          padding: 15px;
        }
      }
    </style>
  </head>
  <body>
    <div class="container">
      <!-- Header with Logo -->
      <div class="header">
        <a href="${affiliateURL ? affiliateURL : 'https://housefolios.com'}" target="_blank">
          <img src="${headerLogo || 'https://housefolios-platform.s3.amazonaws.com/logos/NewLogoBlue.png'}" alt="Housefolios Logo" />
        </a>
        <div class="divider"></div>
      </div>
      <!-- Content Section with Intro -->
      <div class="content">
        <div class="intro">
          ${introduction}
        </div>
        <div class="offer-details">
          <p><strong>Address:</strong> ${address} ${city}, ${state} ${zip}</p>
          <p><strong>Offer Price:</strong> ${offerPrice}</p>
          <p><strong>Conditions:</strong> ${conditions}</p>
          <p><strong>Include the Following Statements:</strong> ${statements}</p>
          <p><strong>Inspection Period (Days):</strong> ${inspectionPeriod}</p>
          <p><strong>Days to Close:</strong> ${daysToClose}</p>
          <p><strong>Earnest Money Deposit:</strong> ${earnestMoney}</p>
        </div>
        <div class="buyer-info">
          <p><strong>Buyer/Entity Name:</strong> ${entityName}</p>
          <p><strong>Buyer/Entity Address:</strong> ${entityAddress} ${entityCity}, ${entityState} ${entityZip}</p>
          <p><strong>Buyer/Entity Contact Email:</strong> ${entityEmail}</p>
          <p><strong>Signer for Buyer/Entity:</strong> ${entitySigner}</p>
        </div>
      </div>
      <!-- Optional Footer Content -->
      ${emailFooter ? emailFooter(hideSocialIcons, hideOrgInfo) : ''}
      <div class="footer">
        <p>© ${new Date().getFullYear()} ${entityName}. All rights reserved.</p>
      </div>
    </div>
  </body>
  </html>
  `

  // Juice inlines the styles, but then extract only the <body> content:
  const inlinedHtml = juice(fullHtml)
  const bodyStart = inlinedHtml.indexOf('<body')
  const bodyEnd = inlinedHtml.indexOf('</body>')
  const bodyContent = inlinedHtml.substring(bodyStart + 6, bodyEnd)

  return fullHtml
}

function socialIcons() {
  return `
		<table class="social_icons" cellpadding="0" cellspacing="0" width="100%" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;" valign="top">
			<tbody>
				<tr style="vertical-align: top;" valign="top">
					<td style="word-break: break-word; vertical-align: top; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
						<table class="social_table" align="center" cellpadding="0" cellspacing="0" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-tspace: 0; mso-table-rspace: 0; mso-table-bspace: 0; mso-table-lspace: 0;" valign="top">
							<tbody>
								<tr style="vertical-align: top; display: inline-block; text-align: center;" align="center" valign="top">
									<td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 2.5px; padding-left: 2.5px;" valign="top"><a href="https://www.facebook.com/housefolios" target="_blank"><img width="32" height="32" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/circle-color/facebook@2x.png" alt="Facebook" title="Facebook" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
									<td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 2.5px; padding-left: 2.5px;" valign="top"><a href="https://twitter.com/housefolios" target="_blank"><img width="32" height="32" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/circle-color/twitter@2x.png" alt="Twitter" title="Twitter" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
									<td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 2.5px; padding-left: 2.5px;" valign="top"><a href="https://instagram.com/housefolios?igshid=10a2wmzdy2jjf" target="_blank"><img width="32" height="32" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/circle-color/instagram@2x.png" alt="Instagram" title="Instagram" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
									<td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 2.5px; padding-left: 2.5px;" valign="top"><a href="https://www.linkedin.com/company/34763748/" target="_blank"><img width="32" height="32" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/circle-color/linkedin@2x.png" alt="LinkedIn" title="LinkedIn" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
									<td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 2.5px; padding-left: 2.5px;" valign="top"><a href="https://www.youtube.com/" target="_blank"><img width="32" height="32" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/circle-color/youtube@2x.png" alt="YouTube" title="YouTube" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</tbody>
		</table>
	`
}

function footerOrgInfo() {
  return `
		<!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
		<div style="color:#555555;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
			<div class="txtTinyMce-wrapper" style="line-height: 1.2; font-size: 12px; color: #555555; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
				<p style="margin: 0; font-size: 14px; line-height: 1.2; word-break: break-word; text-align: center; mso-line-height-alt: 17px; margin-top: 0; margin-bottom: 0;">1280 S 800 E Suite 200 Orem, UT 84097</p>
																		<p style="font-size: 14px; line-height: 1.2; word-break: break-word; text-align: center; mso-line-height-alt: 17px; margin: 0;">Check out our website: <a href="housefolios.com" target="_blank">housefolios.com</a></p>												</div>
		<!--[if mso]></td></tr></table><![endif]-->
	`
}

function emailFooter(hideSocialIcons = false, hideOrgInfo = false) {
  return `
		${hideSocialIcons ? '' : socialIcons()}
		<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
			<tbody>
				<tr style="vertical-align: top;" valign="top">
					<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
						<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 3px solid #00A4E4; width: 100%;" align="center" role="presentation" valign="top">
							<tbody>
								<tr style="vertical-align: top;" valign="top">
									<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" valign="top"><span></span></td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</tbody>
		</table>
		${hideOrgInfo ? '' : footerOrgInfo()}
		<table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
			<tbody>
				<tr style="vertical-align: top;" valign="top">
					<td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
						<table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; height: 45px; width: 100%;" align="center" role="presentation" height="45" valign="top">
							<tbody>
								<tr style="vertical-align: top;" valign="top">
									<td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" height="45" valign="top"><span></span></td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</tbody>
		</table>
	`
}
