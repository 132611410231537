/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-16 11:36:56
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-27 11:21:12
 * @ Description: Button and dialogs for single property add
 */

import React, { useEffect, useState } from 'react'
import map from 'lodash/map'
import round from 'lodash/round'
import isEmpty from 'lodash/isEmpty'
import truncate from 'lodash/truncate'
import padStart from 'lodash/padStart'
import head from 'lodash/head'
import find from 'lodash/find'
import slice from 'lodash/slice'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import reduce from 'lodash/reduce'
import uniqWith from 'lodash/uniqWith'
import maxBy from 'lodash/maxBy'
import startCase from 'lodash/startCase'
import isNaN from 'lodash/isNaN'
import toString from 'lodash/toString'
import isArray from 'lodash/isArray'
import get from 'lodash/get'
import set from 'lodash/set'
import unset from 'lodash/unset'
import fill from 'lodash/fill'
import sum from 'lodash/sum'

import './style.css'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import withSession from '@/housefolios-components/Session/withSession'
import { enqueueSnackbar } from 'notistack'
import { NumericFormat } from 'react-number-format'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { ApolloConsumer, useMutation, useQuery } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import Script from 'react-load-script'
import {
  ADD_COMPPROPERTY_BACKEND,
  ADD_PROPERTY,
  IMAGE_UPLOAD,
} from './mutations'
import {
  CALL_AIR_DNA,
  CALL_HOUSE_CANARY,
  GET_SMART_ASSET,
  GET_ZIP_VALUE,
} from './queries'
import GoogleMapReact from 'google-map-react'
import { PacmanLoader } from 'react-spinners'
import BlockUi from 'react-block-ui'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import UploadProperties from '@/housefolios-components/Properties/UploadProperties'
import Alert from '@mui/material/Alert'
import { Add } from '@mui/icons-material'
import CreatePortfolio from '@/housefolios-components/Portfolio/CreatePortfolio'
import { GET_ASSUMPTIONS } from '@/housefolios-components/AssumptionsSettings/quries'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { GET_STOCKPILE } from '@/housefolios-components/Marketplace/queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { STATES } from '@/constants/states'
import Autocomplete from '@mui/material/Autocomplete'
import { useStopwatch } from 'react-timer-hook'
import CriticalFields from '@/housefolios-components/Dialogs/CriticalFields'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import { getPropertyData, getZipValues } from '@/utils/addProperty'
import { handlePlaceResult } from '@/utils/handlePlaceResult'
import { DEFAULT_ESTIMATES } from '@/constants/defaultValues'
import useAddPropertyDetails from '../../../hooks/useAddPropertyDetails'
import PropertyInformationDialogs from '@/housefolios-components/Dialogs/PropertyInformationDialogs'
import {
  filterPreferedStrategies,
  getStrategyName,
} from '@/utils/stratagies'
import { useNavigate } from 'react-router-dom'
import { statesShort } from '@/utils/states'

const hostname = import.meta.env.VITE_HOSTNAME
const apiKey = import.meta.env.VITE_HOUSEFOLIOS_APIKEY
const apiID = import.meta.env.VITE_HOUSEFOLIOS_APIID
const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY

const MapMarker = ({ icon }) => <div>{icon}</div>

function AddPropertyButton(props) {
  const portfolioId = localStorage.getItem('portfolio') || ''

  let autocomplete

  const [anchorEl, setAnchorEl] = useState(null)
  //const titleButtons = false
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [modal4, setModal4] = useState(false)
  const toggle4 = () => setModal4(!modal4)
  const [modal5, setModal5] = useState(false)
  const [property, setProperty] = useState({})
  const toggle5 = (property = null) => {
    setProperty(property)
    setModal5(!modal5)
  }
  const [query, setQuery] = useState('')
  // const [userListPrice, setUserListPrice] = useState(0)
  const [selectedPortfolio, setSelectedPortfolio] =
    useState(portfolioId)
  const [openPortfolio, setOpenPortfolio] = useState(false)
  const [placeResult, setPlaceResult] = useState(null)

  const [propertyType, setPropertyType] = useState('Single Family')
  const [propertyUnitAmount, setPropertyUnitAmount] = useState(1)
  const [activeUnit, setActiveUnit] = useState(0)

  const [
    addPropertyDetailsValues,
    addPropertyDetailsSetters,
    { calculatePropertyDetailsFromData },
  ] = useAddPropertyDetails()

  const { propertyData } = addPropertyDetailsValues

  const [strategy, setStrategy] = useState('')

  const [loader, setLoader] = useState(false)

  const [menu, setMenu] = useState('')
  const [anchorElDefinition, setAnchorElDefinition] = useState(null)
  const [menuChange, setMenuChange] = useState(null)

  //const [avm, setAVM] = useState(null)

  const { seconds, minutes, pause, reset } = useStopwatch({
    autoStart: false,
  })

  const [addProperty] = useMutation(ADD_PROPERTY)
  const [imageUpload] = useMutation(IMAGE_UPLOAD)
  const [addCompsBackend] = useMutation(ADD_COMPPROPERTY_BACKEND)

  let navigate = useNavigate()

  const { monthlyCount, bonusProperties = 0 } =
    props.session.me.activeOrg.propertyCount

  const { limits = {} } = props.session.me

  const LIMIT = limits.propertyLimit + bonusProperties

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const { loading, data, error } = useQuery(GET_PORTFOLIOS)
  const { portfolios = [] } = data || {}
  const portfolio =
    find(
      portfolios,
      (portfolio) => portfolio._id === selectedPortfolio,
    ) || {}

  //SPECIFIC COMMENT: strategies state starts with all active strategies based on preferences
  const preferences = merge(
    cloneDeep(DEFAULT_PREFERENCES),
    props?.session?.me?.activeOrg?.memberPreferences,
    props?.session?.me?.activeOrg?.preferences,
    { general: { strategies: portfolio.strategies } },
  )
  const [strategies, setStrategies] = useState(
    filterPreferedStrategies(
      preferences,
      props?.session?.me?.activeOrg?.member,
      true,
    ),
  )

  if (!includes(strategies, strategy) && !isEmpty(strategies))
    setStrategy(head(strategies))

  useEffect(() => {
    if (!loading && !isEmpty(portfolios)) {
      const portfolio =
        find(
          portfolios,
          (portfolio) => portfolio._id === selectedPortfolio,
        ) || {}
      if (portfolio) {
        setStrategy(portfolio.strategy)
        setStrategies(
          filterPreferedStrategies(
            preferences,
            props?.session?.me?.activeOrg?.member,
            true,
          ),
        )
      }
    }
  }, [portfolios, loading])

  useEffect(() => {
    if (!isEmpty(props.placeResult)) {
      handleClickOpen1()
      setPlaceResult(props.placeResult)
    }
  }, [props.placeResult])

  const imageUploader = (
    imageUpload,
    imageUrls,
    id,
    collection,
    first = false,
  ) => {
    imageUpload({
      variables: {
        imageUrls,
        documentId: id,
        collection,
        first,
      },
    })
  }

  const handleClickOpen1 = () => {
    if (props.closeParent) props.closeParent()
    setOpen1(true)
    setQuery('')
    // setUserListPrice(0)
    setPlaceResult(null)
    setSelectedPortfolio(portfolioId)
    setActiveUnit(0)
    setPropertyType('Single Family')
  }
  const handleClose1 = () => {
    setOpen1(false)
  }

  const handleClickOpen2 = () => {
    setOpen2(true)
  }
  const handleClose2 = () => {
    setOpen2(false)
  }

  const handleClose4 = () => {
    setModal4(false)
  }

  const handleOpenPortfolio = () => {
    setOpenPortfolio(true)
  }
  const handleClosePortfolio = () => {
    setOpenPortfolio(false)
  }

  function openAddProperty(event) {
    setAnchorEl(event.currentTarget)
  }
  function handleCloseAddProperty() {
    setAnchorEl(null)
  }

  const handleDefinition = (name, anchorEl, onChange) => {
    setMenu(name)
    setAnchorElDefinition(anchorEl)
    setMenuChange(onChange)
  }

  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    var options = {
      types: ['geocode'],
    } // To disable any eslint 'google not defined' error

    // Initialize Google Autocomplete
    /*global google*/ autocomplete =
      new google.maps.places.Autocomplete(
        document.getElementById('autocomplete'),
        options,
      )

    autocomplete.setFields([
      'address_component',
      'geometry',
      'formatted_address',
    ])
    autocomplete.addListener('place_changed', () => {
      setPlaceResult(
        autocomplete.getPlace().address_components
          ? autocomplete.getPlace()
          : null,
      )
    })
  }

  const inputFields =
    props?.session?.me?.activeOrg?.member === 'RWN'
      ? [
          {
            icon1: 'far',
            icon2: 'location-plus',
            title: 'Single Address',
            subTitle: 'Listed or off-market',
            onClick: () => {
              handleClickOpen1()
              handleCloseAddProperty()
            },
          },
          {
            icon1: 'far',
            icon2: 'file-excel',
            title: 'Multiple Addresses',
            subTitle: 'Upload spreadsheet',
            component: (
              <UploadProperties
                key="upload"
                menu
                closeParent={handleCloseAddProperty}
              />
            ),
          },
          {
            icon1: 'far',
            icon2: 'sign-hanging',
            title: 'Marketplace',
            subTitle: 'Pre-analyzed deals',
            href: '/marketplace',
            xs: 12,
          },
        ]
      : [
          {
            icon1: 'far',
            icon2: 'location-plus',
            title: 'Single Address',
            subTitle: 'Listed or off-market',
            onClick: () => {
              handleClickOpen1()
              handleCloseAddProperty()
            },
          },
          {
            icon1: 'far',
            icon2: 'file-excel',
            title: 'Multiple Addresses',
            subTitle: 'Upload spreadsheet',
            component: (
              <UploadProperties
                key="upload"
                menu
                closeParent={handleCloseAddProperty}
              />
            ),
          },
          {
            icon1: 'far',
            icon2: 'magnifying-glass',
            title: 'Property Search',
            subTitle: 'Search active listings',
            href: '/property-search',
          },
          {
            icon1: 'far',
            icon2: 'sign-hanging',
            title: 'Marketplace',
            subTitle: 'Pre-analyzed deals',
            href: '/marketplace',
          },
        ]

  const correctAddressLocationFields = [
    {
      label: 'Address',
      placeholder: 'Address',
      value: addPropertyDetailsValues.address,
      onChange: (event) =>
        addPropertyDetailsSetters.setAddress(event.target.value),
      type: 'address',
      definition: 'address',
      width: 12,
    },
    {
      label: 'City',
      placeholder: 'City',
      value: addPropertyDetailsValues.city,
      onChange: (event) =>
        addPropertyDetailsSetters.setCity(event.target.value),
      definition: 'city',
      width: 6,
    },
    {
      label: 'County',
      placeholder: 'County',
      value: addPropertyDetailsValues.county,
      onChange: (event) =>
        addPropertyDetailsSetters.setCounty(event.target.value),
      definition: 'county',
      width: 6,
    },
    {
      label: 'State',
      placeholder: 'State',
      value: addPropertyDetailsValues.state,
      select: true,
      options: statesShort(),
      onChange: (event) =>
        addPropertyDetailsSetters.setState(event.target.value),
      definition: 'state',
      width: 6,
      required: 'true',
    },
    {
      label: 'Zip',
      placeholder: 'Zip',
      value: addPropertyDetailsValues.zip,
      onChange: (event) =>
        addPropertyDetailsSetters.setZip(event.target.value),
      definition: 'zip',
      type: 'number',
      width: 6,
      required: 'true',
    },
  ]

  const onChangeMultiField = (values, newValue, setter) => {
    let newValues = [...values]
    set(newValues, activeUnit, Number(newValue))
    setter(newValues)
  }

  const correctAddressPropertyDetailsFields = [
    {
      label: 'Beds',
      placeholder: 'Beds',
      value: get(addPropertyDetailsValues.beds, activeUnit),
      autoFocus: true,
      onChange: (event) =>
        onChangeMultiField(
          addPropertyDetailsValues.beds,
          event.target.value >= 0 ? event.target.value : 0,
          addPropertyDetailsSetters.setBeds,
        ),
      type: 'number',
      definition: 'beds',
      width: 6,
      required: 'true',
    },
    {
      label: 'Baths',
      placeholder: 'Baths',
      value: get(addPropertyDetailsValues.baths, activeUnit),
      onChange: (event) =>
        onChangeMultiField(
          addPropertyDetailsValues.baths,
          event.target.value >= 0 ? event.target.value : 0,
          addPropertyDetailsSetters.setBaths,
        ),
      type: 'number',
      definition: 'baths',
      width: 6,
    },
    {
      label: 'Sqft',
      placeholder: 'Sqft',
      value: get(addPropertyDetailsValues.sqft, activeUnit),
      onChange: (event) =>
        onChangeMultiField(
          addPropertyDetailsValues.sqft,
          event.target.value >= 0 ? event.target.value : 0,
          addPropertyDetailsSetters.setSqft,
        ),
      type: 'number',
      definition: 'sqft',
      width: 6,
      required: 'true',
    },
    {
      label: 'Year',
      placeholder: 'Year',
      value: addPropertyDetailsValues.year,
      onChange: (event) =>
        addPropertyDetailsSetters.setYear(
          event.target.value >= 0 ? event.target.value : 0,
        ),
      type: 'number',
      definition: 'yearBuilt',
      width: 6,
    },
  ]

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
  })

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const runQuery = async (client, portfolios) => {
    const { propertySearch, property } = props
    setLoader(true)
    reset()
    let location = handlePlaceResult(placeResult, [property])
    const { loc } = location

    const streetAddress = location.streetAddress
      ? location.streetAddress
      : property.address
        ? property.address
        : query

    const propertyDataInput = {
      single: true,
      formattedAddress: placeResult
        ? placeResult.formatted_address
        : `${streetAddress} ${location.locality}, ${
            location.administrative_area_level_1
          } ${padStart(
            truncate(location.postal_code, {
              length: 5,
              omission: '',
            }),
            5,
            '0',
          )}`,
    }

    let { propertyData, reapiPropertyData } = await getPropertyData(
      client,
      propertyDataInput,
    )

    let response
    let str_json
    let result
    let data
    let error

    const [zipValues] = await getZipValues(
      client,
      location.postal_code,
    )

    const finalAddress = propertyData
      ? head(propertyData.address) || streetAddress
      : streetAddress

    let length = 1
    if (includes(propertyType, '2')) length = 2
    else if (includes(propertyType, '3')) length = 3
    else if (includes(propertyType, '4')) length = 4
    else if (propertyType === 'Custom Unit Amount')
      length = propertyUnitAmount || 1

    const newValues = calculatePropertyDetailsFromData(
      propertySearch,
      property,
      finalAddress,
      propertyData,
      reapiPropertyData,
      loc,
      zipValues,
      length,
    )

    if (
      //(finalAddress === streetAddress && placeResult) ||
      //props.propertySearch
      !preferences.general.isThisYourProperty
    ) {
      setLoader(false)
      pause()
      handleClose1()
      handleClose4()
      runMutation(client, portfolios, newValues)
    } else {
      setLoader(false)
      pause()
      handleClose1()
      toggle4()
    }
  }

  const runMutation = async (client, portfolios, property) => {
    setLoader(true)
    const {
      address,
      city,
      state,
      county,
      zip,
      beds,
      baths,
      sqft,
      year,
      zpid,
      MLSID,
      marketplaceDescription,
      userListPrice,
      newImages,
      propertyData,
      reapiPropertyData,
      location,
    } = property
    let selectedImages = newImages
    let loc = location
    if (location.latitude && location.longitude)
      loc = {
        lat: Number(location.latitude),
        lng: Number(location.longitude),
      }

    let data
    let error

    const stockpileCheck = await client.query({
      query: GET_STOCKPILE,
      variables: {
        addressInput: {
          address,
          city,
          county,
          state,
        },
      },
      errorPolicy: 'ignore',
    })
    const stockpileValues = stockpileCheck
      ? stockpileCheck.data
      : null
    const { stockedImages } = stockpileValues || {}
    if (stockedImages) {
      selectedImages = concat(
        selectedImages,
        compact([stockpileValues.stockedImages.first]),
        stockpileValues.stockedImages.url || [],
      )
      if (isEmpty(selectedImages))
        selectedImages = concat(
          selectedImages,
          compact([stockpileValues.stockedImages.streetView]),
          compact([stockpileValues.stockedImages.staticMap]),
        )
    }
    if (loc) {
      const MAP_KEY = googleApiKey
      const { lat, lng } = loc
      if (isEmpty(selectedImages)) {
        var service = window.google
          ? new window.google.maps.StreetViewService()
          : 0
        if (service !== 0) {
          await new Promise((resolve, reject) => {
            service.getPanoramaByLocation(
              { lat: Number(lat), lng: Number(lng) },
              50,
              function (panoData) {
                if (panoData !== null) {
                  var ManLatLng = panoData.location.latLng
                  const heading =
                    window.google.maps.geometry.spherical.computeHeading(
                      ManLatLng,
                      new window.google.maps.LatLng(
                        Number(lat),
                        Number(lng),
                      ),
                    )
                  selectedImages.push(
                    `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${lat},${lng}&heading=${heading}&key=${MAP_KEY}`,
                  )
                }
                resolve()
              },
            )
          })
        }
        selectedImages.push(
          `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&size=400x400&markers=color:blue%7C${lat},${lng}&key=${MAP_KEY}`,
        )
      }
    }

    let { propertySearch } = props
    const assumptionsResult = await client.query({
      query: GET_ASSUMPTIONS,
      variables: {
        documentId: props.propertySearch
          ? props.portfolioId
          : selectedPortfolio || props.portfolioId,
        documentType: 'portfolio',
      },
      errorPolicy: 'ignore',
    })
    data = assumptionsResult.data
    error = assumptionsResult.error
    let assumptions = error ? null : cloneDeep(data.assumptions)
    if (assumptions) {
      unset(assumptions, '_id')
      unset(assumptions, '__typename')
    }

    const [zipValues] = await getZipValues(
      client,
      propertyData ? head(zip) : location.postal_code,
    )

    const newBeds = parseInt(beds)
    const newSqft = parseInt(sqft)

    let housefoliosARV = ''
    let housefoliosRent = ''
    let housefoliosListPrice = ''
    let housefoliosARVBySqft = ''
    let housefoliosARVByBed = ''
    let housefoliosRentBySqft = ''
    let housefoliosRentByBed = ''
    let housefoliosRentByBedAndSqft = ''
    let rentometer = ''
    let rentometerLow = ''
    let rentometerHigh = ''
    //let arvSource = ''
    if (zipValues) {
      if (newSqft) {
        housefoliosARVBySqft = zipValues.arvSqft * newSqft
        housefoliosRentBySqft = zipValues.rentSqft * newSqft
        housefoliosListPrice = zipValues.listSqft * newSqft
        //arvSource = `Housefolios-SQFT`
      }
      if (newBeds) {
        if (newBeds >= 5) {
          housefoliosARVByBed = zipValues.arvBedPlus
          housefoliosRentByBed = zipValues.rentBedPlus
          housefoliosRentByBedAndSqft =
            zipValues.rentSqftBedPlus * newSqft
        } else if (newBeds === 4) {
          housefoliosARVByBed = zipValues.arvBed4
          housefoliosRentByBed = zipValues.rentBed4
          housefoliosRentByBedAndSqft =
            zipValues.rentSqftBed4 * newSqft
          rentometer = zipValues.rentometerBed4
          rentometerLow = zipValues.rentometerLowBed4
          rentometerHigh = zipValues.rentometerHighBed4
        } else if (newBeds === 3) {
          housefoliosARVByBed = zipValues.arvBed3
          housefoliosRentByBed = zipValues.rentBed3
          housefoliosRentByBedAndSqft =
            zipValues.rentSqftBed3 * newSqft
          rentometer = zipValues.rentometerBed3
          rentometerLow = zipValues.rentometerLowBed3
          rentometerHigh = zipValues.rentometerHighBed3
        } else if (newBeds === 2) {
          housefoliosARVByBed = zipValues.arvBed2
          housefoliosRentByBed = zipValues.rentBed2
          housefoliosRentByBedAndSqft =
            zipValues.rentSqftBed2 * newSqft
          rentometer = zipValues.rentometerBed2
          rentometerLow = zipValues.rentometerLowBed2
          rentometerHigh = zipValues.rentometerHighBed2
        } else if (newBeds === 1) {
          housefoliosARVByBed = zipValues.arvBed1
          housefoliosRentByBed = zipValues.rentBed1
          housefoliosRentByBedAndSqft =
            zipValues.rentSqftBed1 * newSqft
          rentometer = zipValues.rentometerBed1
          rentometerLow = zipValues.rentometerLowBed1
          rentometerHigh = zipValues.rentometerHighBed1
        } else if (newBeds === 0) {
          rentometer = zipValues.rentometerBed0
          rentometerLow = zipValues.rentometerLowBed0
          rentometerHigh = zipValues.rentometerHighBed0
        }
      }

      housefoliosRent = housefoliosRentBySqft
      if (Number(housefoliosRent) < housefoliosRentByBed)
        housefoliosRent = housefoliosRentByBed
      if (Number(housefoliosRent) < housefoliosRentByBedAndSqft)
        housefoliosRent = housefoliosRentByBedAndSqft
      if (Number(housefoliosRent) < rentometerHigh)
        housefoliosRent = rentometerHigh

      housefoliosARV = housefoliosARVBySqft
      if (Number(housefoliosARV) < housefoliosARVByBed) {
        housefoliosARV = housefoliosARVByBed
        //arvSource = `Housefolios-Bed`
      }
    }

    if (propertyData) {
      if (head(propertyData.zestimate))
        if (
          Number(housefoliosARV) <
          Number(head(propertyData.zestimate).amount[0]._)
        ) {
          housefoliosARV = head(propertyData.zestimate).amount[0]._
          //arvSource = `Zillow`
        }
      if (propertyData.rentZestimate)
        if (!housefoliosRent)
          housefoliosRent = head(propertyData.rentZestimate).amount[0]
            ._
    }
    let estimatedListPrice = ''
    if (housefoliosARV && assumptions)
      estimatedListPrice =
        (housefoliosARV * assumptions.estimatedListPrice) / 100
    if (!housefoliosListPrice) {
      housefoliosListPrice = estimatedListPrice
    }

    let housefoliosRehab = ''
    if (assumptions)
      if (assumptions.estimatedRehabType === 'SQFT $' && sqft)
        housefoliosRehab = assumptions.estimatedRehab * Number(sqft)
      else if (assumptions.estimatedRehabType === 'Total $')
        housefoliosRehab = assumptions.estimatedRehab
    let today = new Date()

    const smartAssetResult = await client.query({
      query: GET_SMART_ASSET,
      variables: {
        county: zipValues ? zipValues.county : '',
        state: state,
      },
      errorPolicy: 'ignore',
    })
    data = smartAssetResult.data
    error = smartAssetResult.error
    const smartAssetData = error ? null : data.smartAsset

    // let airdna = null
    // if (
    //   hostname !== 'localhost' &&
    //   hostname !== 'dev-admin.housefolios.com'
    // ) {
    //   const airdnaResult = await client.query({
    //     query: CALL_AIR_DNA,
    //     variables: {
    //       address,
    //       city,
    //       state,
    //       zip: padStart(
    //         truncate(zip, {
    //           length: 5,
    //           omission: '',
    //         }),
    //         5,
    //         '0',
    //       ),
    //       beds: sum(beds),
    //       baths: sum(baths),
    //     },
    //     errorPolicy: 'ignore',
    //   })
    //   data = airdnaResult.data
    //   error = airdnaResult.error
    //   if (error) {
    //     console.log(error)
    //   }
    //   airdna = !data ? null : data.callAirDNA
    // }

    if (userListPrice) housefoliosListPrice = userListPrice
    if (propertySearch) housefoliosListPrice = props.property.price

    // let housefoliosCounty = ''
    // if (propertyData && smartAssetData)
    //   if (head(propertyData.taxAssessment))
    //     housefoliosCounty =
    //       (head(propertyData.taxAssessment) *
    //         smartAssetData.avgCountyTaxRate) /
    //       100

    let preferences = merge(
      cloneDeep(DEFAULT_PREFERENCES),
      props?.session?.me?.activeOrg?.memberPreferences,
      props?.session?.me?.activeOrg?.preferences,
    )
    const completePropertyData =
      preferences.general.completePropertyData

    forEach(preferences, (preference, key) => {
      let newPreference = preference
      const newValues = filter(
        DEFAULT_PREFERENCES[key],
        (x) => !includes(newPreference, x),
      )
      newPreference = filter(newPreference, (x) =>
        includes(DEFAULT_PREFERENCES[key], x),
      )
      newPreference = concat(newPreference, newValues)
      preferences[key] = newPreference
    })

    //SPECIFIC COMMENT: Only listprice needed for now
    let preferencesValues = {
      house: {
        listPrice: propertyData ? head(propertyData.price) : '',
      },
      housefoliosSqft: {
        listPrice: zipValues && sqft ? zipValues.listSqft * sqft : '',
      },
      housefoliosAssumption: {
        listPrice: estimatedListPrice,
      },
    }

    const analysisListPrice = Number(
      props.propertySearch
        ? props.property.price
        : userListPrice
          ? userListPrice
          : Number(
              head(
                compact(
                  map(
                    preferences.listPrice,
                    (pref) => preferencesValues[pref].listPrice,
                  ),
                ),
              ),
            ) || 0,
    )

    //SPECIFIC COMMENT: Second preferences calculation can use calculated analysisListPrice
    preferencesValues = {
      listPriceEstimate: {
        propertyTaxes: zipValues
          ? (analysisListPrice || housefoliosListPrice) *
            zipValues.taxesPer
          : '',
      },
      house: {
        listPrice: propertyData ? head(propertyData.price) : '',
      },
      housefoliosSqft: {
        listPrice: zipValues && sqft ? zipValues.listSqft * sqft : '',
      },
      housefoliosGreatest: {
        rent: housefoliosRent,
        afterRepairValue: housefoliosARV,
      },
      housefoliosAssumption: {
        rehabCost: housefoliosRehab || '',
        rent: assumptions
          ? assumptions.estimatedRentType === '$'
            ? assumptions.estimatedRent
            : assumptions.estimatedRentType === 'Rent/Sqft'
              ? sqft
                ? assumptions.estimatedRent * sqft
                : ''
              : analysisListPrice || housefoliosListPrice
                ? ((analysisListPrice || housefoliosListPrice) *
                    assumptions.estimatedRent) /
                  100
                : ''
          : '',
        listPrice: estimatedListPrice,
        propertyTaxes:
          (analysisListPrice || housefoliosListPrice) && assumptions
            ? assumptions.estimatedTaxesType === '%'
              ? ((analysisListPrice || housefoliosListPrice) *
                  assumptions.estimatedTaxes) /
                100
              : assumptions.estimatedTaxesType === '$'
                ? assumptions.estimatedTaxes
                : ''
            : '',
        offerPrice: assumptions
          ? (housefoliosListPrice *
              assumptions.offerToListPriceRatio) /
              100 || ''
          : '',
      },
      // housefoliosLow: {
      //   afterRepairValue: avm ? avm.low : '',
      // },
      // housefoliosAverage: {
      //   afterRepairValue: avm ? avm.finalValue : '',
      // },
      // housefoliosHigh: {
      //   afterRepairValue: avm ? avm.high : '',
      // },
      reAPI: {
        afterRepairValue:
          propertyData && propertyData.value
            ? head(propertyData.value.mean)
            : '',
      },
      // houseCanary: {
      //   propertyTaxes: propertyData
      //     ? head(propertyData.propertyTaxes)
      //     : '',
      // },
      // houseCanaryLow: {
      //   afterRepairValue:
      //     propertyData && propertyData.value
      //       ? head(propertyData.value.min)
      //       : '',
      //   rent:
      //     propertyData && propertyData.rentalValue
      //       ? head(propertyData.rentalValue.min)
      //       : '',
      // },
      // houseCanaryAverage: {
      //   afterRepairValue:
      //     propertyData && propertyData.value
      //       ? head(propertyData.value.mean)
      //       : '',
      //   rent:
      //     propertyData && propertyData.rentalValue
      //       ? head(propertyData.rentalValue.mean)
      //       : '',
      // },
      // houseCanaryHigh: {
      //   afterRepairValue:
      //     propertyData && propertyData.value
      //       ? head(propertyData.value.max)
      //       : '',
      //   rent:
      //     propertyData && propertyData.rentalValue
      //       ? head(propertyData.rentalValue.max)
      //       : '',
      // },
      averageCompValues: {
        afterRepairValue: '',
      },
      averageLowestCompValues: {
        afterRepairValue: '',
      },
      smartAsset: {
        propertyTaxes:
          housefoliosListPrice && smartAssetData
            ? (housefoliosListPrice *
                smartAssetData.avgCountyTaxRate) /
              100
            : '',
      },
      rentometerHigh: {
        rent: rentometerHigh,
      },
      rentometer: {
        rent: rentometer,
      },
      rentometerLow: {
        rent: rentometerLow,
      },
      // airdna: {
      //   avgRate: airdna
      //     ? airdna.property_stats
      //       ? airdna.property_stats.adr.ltm
      //       : ''
      //     : '',
      //   occupancyRate: airdna
      //     ? airdna.property_stats
      //       ? airdna.property_stats.occupancy.ltm * 100
      //       : ''
      //     : '',
      // },
    }

    const afterRepairValue = Number(
      head(
        compact(
          map(
            preferences.afterRepairValue,
            (pref) => preferencesValues[pref].afterRepairValue,
          ),
        ),
      ) || '',
    )

    const rehabDollar = assumptions.rehabTier * sqft
    const resellPrice1 = afterRepairValue - rehabDollar
    const buyerProfitTotal =
      (assumptions.belowBuyerProfit / 100) * resellPrice1
    const resellPrice2 = resellPrice1 - buyerProfitTotal
    const closingCostWholesaleDollar =
      assumptions.closingCostWholesaleType === '%'
        ? (assumptions.closingCostWholesale / 100) * resellPrice2
        : assumptions.closingCostWholesale
    const resellPrice = resellPrice2 - closingCostWholesaleDollar

    let length = 1
    if (includes(propertyType, '2')) length = 2
    else if (includes(propertyType, '3')) length = 3
    else if (includes(propertyType, '4')) length = 4
    else if (propertyType === 'Custom Unit Amount')
      length = propertyUnitAmount || 1

    let rent = fill(new Array(length), 0)
    let otherIncome = fill(new Array(length), 0)

    rent[0] = Number(
      head(
        compact(
          map(
            preferences.rent,
            (pref) => preferencesValues[pref].rent,
          ),
        ),
      ) || 0,
    )

    const analysis = {
      listPrice: Number(
        props.propertySearch
          ? props.property.price
          : userListPrice
            ? userListPrice
            : Number(
                head(
                  compact(
                    map(
                      preferences.listPrice,
                      (pref) => preferencesValues[pref].listPrice,
                    ),
                  ),
                ),
              ) || 0,
      ),
      offerPrice: Number(
        head(
          compact(
            map(
              preferences.offerPrice,
              (pref) => preferencesValues[pref].offerPrice,
            ),
          ),
        ) || 0,
      ),
      afterRepairValue: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      afterRepairValueBuyHold: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      afterRepairValueFixFlip: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      afterRepairValueTurnkey: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      afterRepairValueSTR: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      afterRepairValueBRRRR: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      afterRepairValueB2R: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      afterRepairValueBOR: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      afterRepairValueWholesale: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      resellPrice: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      resellPriceB2R: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      resellPriceBRRRR: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      resellPriceFixFlip: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      resellPriceTurnkey: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      resellPriceSTR: Number(
        head(
          compact(
            map(
              preferences.afterRepairValue,
              (pref) => preferencesValues[pref].afterRepairValue,
            ),
          ),
        ) || 0,
      ),
      resellPriceWholesale: Number(resellPrice > 0 ? resellPrice : 0),
      rehabCost: Number(
        head(
          compact(
            map(
              preferences.rehabCost,
              (pref) => preferencesValues[pref].rehabCost,
            ),
          ),
        ) || 0,
      ),
      rehabCostBRRRR: Number(
        head(
          compact(
            map(
              preferences.rehabCost,
              (pref) => preferencesValues[pref].rehabCost,
            ),
          ),
        ) || 0,
      ),
      rehabCostBOR: Number(
        head(
          compact(
            map(
              preferences.rehabCost,
              (pref) => preferencesValues[pref].rehabCost,
            ),
          ),
        ) || 0,
      ),
      rehabCostFixFlip: Number(
        head(
          compact(
            map(
              preferences.rehabCost,
              (pref) => preferencesValues[pref].rehabCost,
            ),
          ),
        ) || 0,
      ),
      rehabCostTurnkey: Number(
        head(
          compact(
            map(
              preferences.rehabCost,
              (pref) => preferencesValues[pref].rehabCost,
            ),
          ),
        ) || 0,
      ),
      rehabCostSTR: Number(
        head(
          compact(
            map(
              preferences.rehabCost,
              (pref) => preferencesValues[pref].rehabCost,
            ),
          ),
        ) || 0,
      ),
      rehabCostWholesale: Number(
        head(
          compact(
            map(
              preferences.rehabCost,
              (pref) => preferencesValues[pref].rehabCost,
            ),
          ),
        ) || 0,
      ),
      rehabCostAstroFlip: Number(
        head(
          compact(
            map(
              preferences.rehabCost,
              (pref) => preferencesValues[pref].rehabCost,
            ),
          ),
        ) || 0,
      ),
      rent: rent,
      rentBOR: rent,
      otherIncome: otherIncome,
      propertyTaxes: Number(
        head(
          compact(
            map(
              preferences.propertyTaxes,
              (pref) => preferencesValues[pref].propertyTaxes,
            ),
          ),
        ) || 0,
      ),
      daysOnMarket: 0,
      addedToMarket: today || '',
    }

    let source = {}
    forEach(preferences, (value, key) => {
      if (isArray(value))
        source[key] = find(
          value,
          (pref) =>
            preferencesValues[pref] && preferencesValues[pref][key],
        )
    })
    if (props.propertySearch ? props.property.price : userListPrice)
      unset(source, 'listPrice')

    let estimates = DEFAULT_ESTIMATES

    estimates = {
      ...estimates,
      // listPriceEstimate: {
      //   ...estimates.listPriceEstimate,
      //   propertyTaxes:
      //     analysis && zipValues
      //       ? analysis.listPrice * zipValues.taxesPer
      //       : '',
      // },
      house: {
        ...estimates.house,
        listPrice: propertyData ? head(propertyData.price) : '',
      },
      // housefolios: {
      //   ...estimates.housefolios,
      //   // propertyTaxes:
      //   //   propertyData && zipValues
      //   //     ? propertyData.taxAssessment *
      //   //     zipValues.taxesPer
      //   //     : '',
      // },
      // housefoliosCounty: {
      //   ...estimates.housefoliosCounty,
      //   //propertyTaxes: housefoliosCounty ? housefoliosCounty : '',
      // },
      // housefoliosSqft: {
      //   ...estimates.housefoliosSqft,
      //   afterRepairValue: housefoliosARVBySqft || '',
      //   rent: housefoliosRentBySqft || '',
      //   listPrice:
      //     zipValues && sqft ? zipValues.listSqft * sqft : '',
      // },
      housefoliosAssumption: {
        ...estimates.housefoliosAssumption,
        rehabCost: housefoliosRehab || '',
        rehabCostBRRRR: housefoliosRehab || '',
        rehabCostBOR: housefoliosRehab || '',
        rehabCostFixFlip: housefoliosRehab || '',
        rehabCostTurnkey: housefoliosRehab || '',
        rehabCostSTR: housefoliosRehab || '',
        rehabCostWholesale: housefoliosRehab || '',
        rehabCostAstroFlip: housefoliosRehab || '',
        rent: assumptions
          ? assumptions.estimatedRentType === '$'
            ? assumptions.estimatedRent
            : assumptions.estimatedRentType === 'Rent/Sqft'
              ? sqft
                ? assumptions.estimatedRent * sqft
                : ''
              : analysis.listPrice || housefoliosListPrice
                ? ((analysis.listPrice || housefoliosListPrice) *
                    assumptions.estimatedRent) /
                  100
                : ''
          : '',
        rentBOR: assumptions
          ? assumptions.estimatedRentBORType === '$'
            ? assumptions.estimatedRentBOR
            : assumptions.estimatedRentBORType === 'Rent/Sqft'
              ? sqft
                ? assumptions.estimatedRentBOR * sqft
                : ''
              : analysis.listPrice || housefoliosListPrice
                ? ((analysis.listPrice || housefoliosListPrice) *
                    assumptions.estimatedRentBOR) /
                  100
                : ''
          : '',
        listPrice: analysis.afterRepairValue
          ? (analysis.afterRepairValue *
              assumptions.estimatedListPrice) /
            100
          : (housefoliosARV * assumptions.estimatedListPrice) / 100,
        propertyTaxes:
          (analysis.listPrice || housefoliosListPrice) && assumptions
            ? assumptions.estimatedTaxesType === '%'
              ? ((analysis.listPrice || housefoliosListPrice) *
                  assumptions.estimatedTaxes) /
                100
              : assumptions.estimatedTaxesType === '$'
                ? assumptions.estimatedTaxes
                : ''
            : '',
        offerPrice: assumptions
          ? ((analysis.listPrice || housefoliosListPrice) *
              assumptions.offerToListPriceRatio) /
              100 || ''
          : '',
      },
      // housefoliosBed: {
      //   ...estimates.housefoliosBed,
      //   afterRepairValue: housefoliosARVByBed || '',
      //   rent: housefoliosRentByBed || '',
      // },
      // housefoliosBedAndSqft: {
      //   ...estimates.housefoliosBedAndSqft,
      //   rent: housefoliosRentByBedAndSqft || '',
      // },
      // housefoliosLow: {
      //   ...estimates.housefoliosLow,
      //   afterRepairValue: avm ? avm.low : '',
      // },
      // housefoliosAverage: {
      //   ...estimates.housefoliosAverage,
      //   afterRepairValue: avm ? avm.finalValue : '',
      // },
      // housefoliosHigh: {
      //   ...estimates.housefoliosHigh,
      //   afterRepairValue: avm ? avm.high : '',
      // },
      reAPI: {
        ...estimates.reAPI,
        afterRepairValue:
          propertyData && propertyData.value
            ? head(propertyData.value.mean)
            : '',
      },
      // houseCanary: {
      //   ...estimates.houseCanary,
      //   propertyTaxes: propertyData
      //     ? head(propertyData.propertyTaxes)
      //     : '',
      // },
      // houseCanaryLow: {
      //   ...estimates.houseCanaryLow,
      //   afterRepairValue:
      //     propertyData && propertyData.value
      //       ? head(propertyData.value.min)
      //       : '',
      //   rent:
      //     propertyData && propertyData.rentalValue
      //       ? head(propertyData.rentalValue.min)
      //       : '',
      //   rentBOR:
      //     propertyData && propertyData.rentalValue
      //       ? head(propertyData.rentalValue.min)
      //       : '',
      // },
      // houseCanaryAverage: {
      //   ...estimates.houseCanaryAverage,
      //   afterRepairValue:
      //     propertyData && propertyData.value
      //       ? head(propertyData.value.mean)
      //       : '',
      //   rent:
      //     propertyData && propertyData.rentalValue
      //       ? head(propertyData.rentalValue.mean)
      //       : '',
      //   rentBOR:
      //     propertyData && propertyData.rentalValue
      //       ? head(propertyData.rentalValue.mean)
      //       : '',
      // },
      // houseCanaryHigh: {
      //   ...estimates.houseCanaryHigh,
      //   afterRepairValue:
      //     propertyData && propertyData.value
      //       ? head(propertyData.value.max)
      //       : '',
      //   rent:
      //     propertyData && propertyData.rentalValue
      //       ? head(propertyData.rentalValue.max)
      //       : '',
      //   rentBOR:
      //     propertyData && propertyData.rentalValue
      //       ? head(propertyData.rentalValue.max)
      //       : '',
      // },
      smartAsset: {
        ...estimates.smartAsset,
        propertyTaxes:
          (analysis.listPrice || housefoliosListPrice) &&
          smartAssetData
            ? ((analysis.listPrice || housefoliosListPrice) *
                smartAssetData.avgCountyTaxRate) /
              100
            : '',
      },
      // zillow: {
      //   ...estimates.zillow,
      //   // rent: propertyData
      //   //   ? propertyData.rentZestimate
      //   //     ? propertyData.rentZestimate.amount[0]._
      //   //     : ''
      //   //   : '',
      //   // afterRepairValue: propertyData
      //   //   ? propertyData.zestimate
      //   //     ? propertyData.zestimate.amount[0]._
      //   //     : ''
      //   //   : '',
      // },
      rentometer: {
        ...estimates.rentometer,
        rent: rentometer,
        rentBOR: rentometer,
      },
      rentometerLow: {
        ...estimates.rentometerLow,
        rent: rentometerLow,
        rentBOR: rentometerLow,
      },
      rentometerHigh: {
        ...estimates.rentometerHigh,
        rent: rentometerHigh,
        rentBOR: rentometerHigh,
      },
      // airdna: {
      //   ...estimates.airdna,
      //   avgRate: airdna
      //     ? airdna.property_stats
      //       ? airdna.property_stats.adr.ltm
      //       : ''
      //     : '',
      //   occupancyRate: airdna
      //     ? airdna.property_stats
      //       ? airdna.property_stats.occupancy.ltm * 100
      //       : ''
      //     : '',
      // },
    }

    let assumptionsInput = {
      vacancy: fill(new Array(length), 0),
      avgRate: fill(new Array(length), 0),
      occupancyRate: fill(new Array(length), 0),
    }

    assumptionsInput.vacancy[0] = parseInt(
      assumptions ? assumptions.vacancy : 0,
    )
    assumptionsInput.avgRate[0] = Number(
      head(
        compact(
          map(
            preferences.avgRate,
            (pref) => preferencesValues[pref].avgRate,
          ),
        ),
      ) || 0,
    )
    assumptionsInput.occupancyRate[0] = Number(
      head(
        compact(
          map(
            preferences.occupancyRate,
            (pref) => preferencesValues[pref].occupancyRate,
          ),
        ),
      ) || 0,
    )

    await addProperty({
      variables: {
        propertyInput: {
          portfolioId: props.propertySearch
            ? props.portfolioId
            : selectedPortfolio || props.portfolioId,
          address,
          city,
          state,
          county,
          zip: toString(zip),
          beds: beds,
          baths: baths,
          sqft: sqft,
          year: parseInt(year),
          description: toString(marketplaceDescription),
          estval: Number(analysis.listPrice),
          strategy,
          strategies,
          analysisInput: analysis,
          assumptionsInput,
          estimates,
          source,
          zpid,
          MLSID: toString(MLSID),
          propertyType,
          propertyUnitAmount,
          propertyDashboard: !completePropertyData,
          ownership: reapiPropertyData
            ? reapiPropertyData.ownership
            : null,
          apn: reapiPropertyData ? reapiPropertyData.apn : null,
          linkedProperties: reapiPropertyData
            ? reapiPropertyData.linkedProperties
            : null,
          lien: reapiPropertyData ? reapiPropertyData.lien : null,
          mortgageBalance: reapiPropertyData
            ? parseInt(reapiPropertyData.mortgageBalance)
            : null,
          estimatedValue: reapiPropertyData
            ? reapiPropertyData.estimatedValue
            : null,
          estimatedEquity: reapiPropertyData
            ? reapiPropertyData.estimatedEquity
            : null,
        },
      },
      refetchQueries: [
        {
          query: GET_ME,
        },
        {
          query: GET_PORTFOLIOS,
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: props.propertySearch
              ? [props.portfolioId]
              : [selectedPortfolio || props.portfolioId],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: map(
              portfolios,
              (portfolio) => portfolio._id,
            ),
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
      ],
    }).then(async (addPropertyResult) => {
      const addPropertyData = addPropertyResult.data.addProperty
      if (includes(head(selectedImages), googleApiKey)) {
        imageUploader(
          imageUpload,
          selectedImages,
          addPropertyData._id,
          'property',
          false,
        )
      } else {
        imageUploader(
          imageUpload,
          slice(selectedImages, 0, 1),
          addPropertyData._id,
          'property',
          true,
        )
        if (selectedImages.length > 1)
          imageUploader(
            imageUpload,
            slice(selectedImages, 1),
            addPropertyData._id,
            'property',
            false,
          )
      }
      // localStorage.setItem('portfolio', selectedPortfolio)

      if (!props.session.me.activeOrg.isRealeflow)
        addCompsBackend({
          variables: {
            propertyIds: [addPropertyData._id],
          },
        })

      // const id = addPropertyData._id
      // let { data, error } = await client.query({
      //   query: GET_MLS_COMPS_BY_ZIP,
      //   variables: {
      //     propertyInput: {
      //       county: county,
      //       city: city,
      //       zip: zip,
      //       beds: Number(beds),
      //       baths: Number(baths),
      //       sqft: Number(sqft),
      //       year: Number(year),
      //       location: loc ? loc : addPropertyData.location ? addPropertyData.location : null
      //     },
      //   },
      //   errorPolicy: 'all'
      // })
      // if (error) console.log(error)
      // const getMLSCompData = data
      //   ? data.getMLSCompsByZip
      //   : null

      // if (props.propertySearch && location) {
      //   const MAP_KEY = 'AIzaSyAUwGp8s4WGjxFAEL6weeRAsILFWATDL_s'
      //   const { latitude, longitude } = location
      //   if (isEmpty(selectedImages)) {
      //     var service = window.google
      //       ? new window.google.maps.StreetViewService()
      //       : 0
      //     if (service !== 0) {
      //       await new Promise((resolve, reject) => {
      //         service.getPanoramaByLocation(
      //           { lat: latitude, lng: longitude },
      //           50,
      //           function (panoData) {
      //             if (panoData !== null) {
      //               var ManLatLng = panoData.location.latLng
      //               const heading = window.google.maps.geometry.spherical.computeHeading(
      //                 ManLatLng,
      //                 new window.google.maps.LatLng(
      //                   latitude,
      //                   longitude,
      //                 ),
      //               )
      //               selectedImages.push(
      //                 `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${latitude},${longitude}&heading=${heading}&key=${MAP_KEY}`,
      //               )
      //             }
      //             resolve()
      //           },
      //         )
      //       })
      //     }
      //   }
      //   selectedImages.push(
      //     `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&size=400x400&markers=color:blue%7C${latitude},${longitude}&key=${MAP_KEY}`,
      //   )
      // }

      // if (!isEmpty(getMLSCompData)) {
      //   // this.setState({
      //   //   progress: 0,
      //   //   progressText: 'Searching For Comps',
      //   // })
      //   let propertyImagePairs =  []
      //   for (let i = 0; i < getMLSCompData.length; i++) {
      //     const data = getMLSCompData[i]
      //     let images = []
      //     if (data.files) {
      //       images = map(data.files.url, url => window.location.protocol + url)
      //     }
      //     await addComp({
      //       variables: {
      //         compPropertyInput: {
      //           mainProperty: id,
      //           address: data.address,
      //           city: data.city,
      //           state: data.state,
      //           zip: truncate(data.zip, { length: 5, omission: '' }),
      //           beds: parseInt(data.beds),
      //           baths: parseFloat(data.baths),
      //           sqft: parseInt(data.sqft),
      //           year: parseInt(data.year),
      //           estval: parseInt(data.estimate),
      //           saleDate: data.saleDate,
      //         },
      //       },
      //     }).then(({ data }) => {
      //       propertyImagePairs.push({
      //         propertyId: data.addCompProperty._id,
      //         imageUrls: images,
      //       })
      //       // this.setState({
      //       //   progress: ((i + 1) / getMLSCompData.length) * 100,
      //       // })
      //     })
      //   }
      //   imageUploadMulti({
      //     variables: {
      //       propertyImagePairs,
      //       collection: 'compProperty'
      //     }
      //   })
      // }
      // else {
      //   let { data, error } = await client.query({
      //     query: GET_ZILLOW_COMP,
      //     variables: {
      //       zpid: zpid,
      //       propertyInput: {
      //         beds: Number(beds),
      //         baths: Number(baths),
      //         sqft: Number(sqft),
      //         year: Number(year),
      //         location: loc ? loc : location ? location : null
      //       },
      //     },
      //     errorPolicy: 'all'
      //   })
      //   if (error) console.log(error)
      //   const getZillowCompData = data
      //     ? data.getZillowCompData
      //     : null

      //   if (getZillowCompData) {
      //     // this.setState({
      //     //   progress: 0,
      //     //   progressText: 'Searching For Comps',
      //     // })
      //     for (let i = 0; i < getZillowCompData.length; i++) {
      //       const data = getZillowCompData[i]
      //       let images = []
      //       if (data.files) {
      //         images = data.files.url
      //       } else if (data.location) {
      //         const MAP_KEY = 'AIzaSyAUwGp8s4WGjxFAEL6weeRAsILFWATDL_s'
      //         const { latitude, longitude } = data.location
      //         var service = window.google
      //           ? new window.google.maps.StreetViewService()
      //           : 0
      //         if (service !== 0) {
      //           await new Promise((resolve, reject) => {
      //             service.getPanoramaByLocation(
      //               { lat: latitude, lng: longitude },
      //               50,
      //               function (panoData) {
      //                 if (panoData !== null) {
      //                   var ManLatLng = panoData.location.latLng
      //                   const heading = window.google.maps.geometry.spherical.computeHeading(
      //                     ManLatLng,
      //                     new window.google.maps.LatLng(
      //                       latitude,
      //                       longitude,
      //                     ),
      //                   )
      //                   images.push(
      //                     `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${latitude},${longitude}&heading=${heading}&key=${MAP_KEY}`,
      //                   )
      //                 }
      //                 resolve()
      //               },
      //             )
      //           })
      //         }
      //         images.push(
      //           `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&size=400x400&markers=color:blue%7C${latitude},${longitude}&key=${MAP_KEY}`,
      //         )
      //       }
      //       await addComp({
      //         variables: {
      //           compPropertyInput: {
      //             mainProperty: id,
      //             address: data.address,
      //             city: data.city,
      //             state: data.state,
      //             zip: truncate(data.zip, { length: 5, omission: '' }),
      //             beds: parseInt(data.beds),
      //             baths: parseFloat(data.baths),
      //             sqft: parseInt(data.sqft),
      //             year: parseInt(data.year),
      //             estval: parseInt(data.zestimate.amount[0]._),
      //             rentalPrice: data.rentZestimate
      //               ? parseFloat(data.rentZestimate.amount[0]._)
      //               : parseFloat(''),
      //           },
      //         },
      //       }).then(({ data }) => {
      //         imageUploader(
      //           imageUpload,
      //           images,
      //           data.addCompProperty._id,
      //           'compProperty',
      //         )
      //         // this.setState({
      //         //   progress: ((i + 1) / getZillowCompData.length) * 100,
      //         // })
      //       })
      //     }
      //   }
      // }
      handleClose4()
      handleClose2()
      setLoader(false)
      pause()
      if (!props.propertySearch && completePropertyData)
        toggle5(addPropertyData)
      else if (!props.propertySearch)
        navigate(
          '/property-details/' + addPropertyData._id + '/Summary',
        )
    })
  }

  return (
    <>
      {props.title && (
        <Button
          variant="contained"
          onClick={
            monthlyCount >= LIMIT
              ? () => props.toggleLimit('property')
              : openAddProperty
          }
          // size='small'
          //color='secondary'
          style={{
            backgroundColor: '#28b854',
            color: 'white',
            whiteSpace: 'nowrap',
          }}
          className="btn-success d-40 w-auto mr-3"
        >
          <Add style={{ fontWeight: 600, marginRight: 5 }} />
          <Typography style={{ fontWeight: 600 }}>
            Add Property
          </Typography>
        </Button>
      )}
      {props.launchpad && (
        <Button
          onClick={
            monthlyCount >= LIMIT
              ? () => props.toggleLimit('property')
              : handleClickOpen1
          }
          variant="text"
          className="btn-outline-secondary border-0 w-100 h-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"
        >
          <FontAwesomeIcon
            icon={['far', 'location-plus']}
            className="h2 d-block mx-auto mb-2 mt-1 text-success"
          />
          <div className="font-weight-bold font-size-md text-black">
            Add Property
          </div>
          <div className="font-size-md mb-1 text-black-50">
            Listed or off-market
          </div>
        </Button>
      )}
      {props.headerDot && (
        <Grid item sm={6}>
          <Button
            onClick={
              monthlyCount >= LIMIT
                ? () => props.toggleLimit('property')
                : handleClickOpen1
            }
            style={{ textAlign: 'center' }}
            variant="text"
            disabled={props.disabled}
            className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"
          >
            <FontAwesomeIcon
              icon={['far', 'location-plus']}
              className="h2 d-block mb-2 mx-auto mt-1 text-success"
            />
            <div className="font-weight-bold text-black">
              Single Property
            </div>
            <div className="font-size-md mb-1 text-black-50">
              Listed or off-market
            </div>
          </Button>
        </Grid>
      )}
      {props.investarters && (
        <Button
          onClick={
            monthlyCount >= LIMIT
              ? () => props.toggleLimit('property')
              : openAddProperty
          }
          variant="text"
          className="btn-outline-secondary border-0 w-100 h-100 d-block btn-transition-none rounded-0 shadow-none px-1"
        >
          <FontAwesomeIcon
            icon={['far', 'location-plus']}
            className="h2 d-block mx-auto mb-2 mt-1 text-success"
          />
          <div className="font-weight-bold font-size-md text-black">
            Add Property
          </div>
          <div className="font-size-md mb-1 text-black-50">
            Listed or off-market
          </div>
        </Button>
      )}
      {/* {props.nextLevel && (
      <Button
        onClick={monthlyCount >= LIMIT ? () => props.toggleLimit('property') : openAddProperty}
        variant="text"
        className="btn-outline-secondary border-0 w-100 h-100 d-block btn-transition-none rounded-0 shadow-none px-1">
        <FontAwesomeIcon
          icon={['far', 'location-plus']}
          className="h2 d-block mx-auto mb-2 mt-1 text-success"
        />
        <div className="font-weight-bold font-size-md text-black">
          Add Property
        </div>
        <div className="font-size-md mb-1 text-black-50">
          Listed or off-market
        </div>
      </Button>
    )} */}

      <Menu
        id="userMenu"
        component="div"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        classes={{ list: 'p-0' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseAddProperty}
      >
        <div className="dropdown-menu-xl overflow-hidden p-0">
          <div className="grid-menu grid-menu-2col">
            <Grid container spacing={0}>
              {map(inputFields, (section) => {
                if (section.component) return section.component
                else
                  return (
                    <Grid
                      item
                      sm={section.xs || 6}
                      key={section.title}
                    >
                      <Button
                        href={section.href}
                        style={{ textAlign: 'center' }}
                        variant="text"
                        className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"
                        onClick={section.onClick}
                      >
                        <FontAwesomeIcon
                          icon={[
                            `${section.icon1}`,
                            `${section.icon2}`,
                          ]}
                          //icon={section.icon}
                          className="h2 d-block mb-2 mx-auto mt-1 text-success"
                        />
                        <div className="font-weight-bold text-black">
                          {section.title}
                        </div>
                        <div className="font-size-md mb-1 text-black-50">
                          {section.subTitle}
                        </div>
                      </Button>
                    </Grid>
                  )
              })}
            </Grid>
          </div>
        </div>
      </Menu>

      <ApolloConsumer>
        {(client) => (
          <Query query={GET_PORTFOLIOS}>
            {({ loading, error, refetch, data }) => (
              <>
                <Dialog
                  open={open1}
                  onClose={handleClose1}
                  onKeyDown={stopPropagationForTab}
                >
                  <DialogTitle>Add Single Property</DialogTitle>
                  <ValidatorForm
                    style={{
                      overflowY: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    onSubmit={() => runQuery(client, data.portfolios)}
                  >
                    <DialogContent>
                      <BlockUi
                        blocking={loader}
                        loader={
                          <PacmanLoader
                            color={'var(--primary)'}
                            loading={true}
                          />
                        }
                        message={
                          <div className="text-primary">
                            <p>Connecting</p>
                            <p>
                              {(
                                ((minutes * 60 + seconds) / 120) *
                                100
                              ).toFixed(1)}
                              % Complete
                            </p>
                          </div>
                        }
                      >
                        {/* <DialogContent className="p-4"> */}
                        <Script
                          url={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=geometry,places`}
                          onLoad={handleScriptLoad}
                        />
                        <DialogContentText>
                          Type an address below and we will
                          automatically gather data and analyze it for
                          you even if it's not on the MLS!
                        </DialogContentText>
                        <Grid container direction="row" spacing={1}>
                          <Grid item xs={12}>
                            <Autocomplete
                              value={propertyType}
                              options={[
                                'Single Family',
                                'Multi Family - 2 Units',
                                'Multi Family - 3 Units',
                                'Multi Family - 4 Units',
                                'Custom Unit Amount',
                              ]}
                              onChange={(event, newValue) =>
                                setPropertyType(newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Property Type"
                                  size="small"
                                  variant="outlined"
                                  margin="dense"
                                />
                              )}
                            />
                          </Grid>
                          {propertyType === 'Custom Unit Amount' && (
                            <Grid item xs={12}>
                              <TextField
                                label="Units"
                                type="number"
                                fullWidth={true}
                                margin="dense"
                                variant="outlined"
                                size="small"
                                name="propertyUnitAmount"
                                value={propertyUnitAmount}
                                onChange={(event) =>
                                  setPropertyUnitAmount(
                                    Number(event.target.value),
                                  )
                                }
                                min={1}
                              />
                            </Grid>
                          )}
                          {props.placeResult ? (
                            <Grid item xs={12} sm={12}>
                              <TextField
                                id="autocomplete"
                                placeholder="Search Property"
                                label="Address"
                                fullWidth={true}
                                margin="dense"
                                variant="outlined"
                                size="small"
                                name="query"
                                value={
                                  props.placeResult.formatted_address
                                }
                                // onChange={event => setQuery(event.target.value)}
                                autoFocus
                                required={true}
                                disabled={true}
                                // helperText={
                                //   googleNotFound
                                //     ? 'Address not found, please try again'
                                //     : ''
                                // }
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={12}>
                              <TextField
                                id="autocomplete"
                                placeholder="Search Property"
                                label="Address"
                                fullWidth={true}
                                margin="dense"
                                variant="outlined"
                                size="small"
                                name="query"
                                //value={query}
                                onChange={(event) =>
                                  setQuery(event.target.value)
                                }
                                autoFocus
                                required={true}
                                // helperText={
                                //   googleNotFound
                                //     ? 'Address not found, please try again'
                                //     : ''
                                // }
                              />
                            </Grid>
                          )}
                          {/* <Grid item xs={12} sm={6}>
                          <NumericFormat
                            customInput={TextValidator}
                            placeholder="Asking Price*"
                            label="Asking Price*"
                            thousandSeparator={true}
                            prefix={'$'}
                            fullWidth={true}
                            margin="dense"
                            variant="outlined"
                            size="small"
                            value={userListPrice}
                            onFocus={event => {
                              event.target.select()
                            }}
                            allowNegative={false}
                            decimalScale={0}
                            onValueChange={values => {
                              const { value } = values
                              setUserListPrice(value)
                            }}
                            validators={['required', 'maxStringLength: 20']}
                            errorMessages={[
                              'Field is required',
                              'You cannot exceed 20 characters',
                            ]}
                            helperText='Not sure? Give us a ballpark estimate'
                          />
                        </Grid> */}
                          <Grid item xs={12} sm={6}>
                            <Query query={GET_PORTFOLIOS}>
                              {({ loading, error, data }) => {
                                if (loading) return ''
                                if (error)
                                  return `error! ${error.message}`
                                if (
                                  !find(
                                    data.portfolios,
                                    (portfolio) =>
                                      portfolio._id ===
                                      selectedPortfolio,
                                  )
                                )
                                  setSelectedPortfolio(
                                    head(data.portfolios)._id,
                                  )
                                return (
                                  <FormControl
                                    variant="outlined"
                                    margin="dense"
                                    style={{ width: '100%' }}
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Portfolio
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      name="selectedPortfolio"
                                      value={selectedPortfolio}
                                      onChange={(event) =>
                                        setSelectedPortfolio(
                                          event.target.value,
                                        )
                                      }
                                      open={openPortfolio}
                                      onOpen={handleOpenPortfolio}
                                      onClose={handleClosePortfolio}
                                      MenuProps={{
                                        keepMounted: true,
                                      }}
                                      fullWidth={true}
                                      label="Portfolio"
                                    >
                                      {props.session.me.activeOrg
                                        .member === 'RWN' && (
                                        <MenuItem
                                          key="My Properties"
                                          value={
                                            data.portfolios
                                              ? data.portfolios[0]._id
                                              : null
                                          }
                                        >
                                          My Properties
                                        </MenuItem>
                                      )}
                                      {props.session.me.activeOrg
                                        .member !== 'RWN' &&
                                        map(
                                          data.portfolios,
                                          (option) => (
                                            <MenuItem
                                              key={option.name}
                                              value={option._id}
                                            >
                                              {option.name}
                                            </MenuItem>
                                          ),
                                        )}
                                      {props.session.me.activeOrg
                                        .member !== 'RWN' && (
                                        <CreatePortfolio
                                          dropdown={true}
                                          setNewPortfolio={
                                            setSelectedPortfolio
                                          }
                                          closeMenu={
                                            handleClosePortfolio
                                          }
                                        />
                                      )}
                                    </Select>
                                  </FormControl>
                                )
                              }}
                            </Query>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextValidator
                              select
                              variant="outlined"
                              margin="dense"
                              label="Property Strategy"
                              fullWidth={true}
                              value={strategy}
                              onChange={(event) =>
                                setStrategy(event.target.value)
                              }
                              required={true}
                              validators={['required']}
                              errorMessages={[
                                'This field is required',
                              ]}
                            >
                              {map(strategies, (strategy) => (
                                <MenuItem
                                  key={strategy}
                                  value={strategy}
                                >
                                  {getStrategyName(
                                    strategy,
                                    props?.session?.me?.activeOrg
                                      ?.member,
                                  )}
                                </MenuItem>
                              ))}
                            </TextValidator>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              id="checkboxes-tags-demo"
                              multiple
                              disableCloseOnSelect
                              options={filterPreferedStrategies(
                                preferences,
                                props?.session?.me?.activeOrg?.member,
                                false,
                              )}
                              value={strategies}
                              onChange={(event, newValue) => {
                                if (!isEmpty(newValue))
                                  setStrategies(newValue)
                              }}
                              getOptionLabel={(option) =>
                                getStrategyName(
                                  option,
                                  props?.session?.me?.activeOrg
                                    ?.member,
                                )
                              }
                              disableClearable
                              // isOptionEqualToValue={(option, value) =>
                              //   option === value.value && value.active
                              // }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Strategies"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        {/* </DialogContent> */}
                      </BlockUi>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose1} variant="text">
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                          !placeResult /*|| !userListPrice || !selectedPortfolio*/
                        }
                      >
                        Search
                      </Button>
                    </DialogActions>
                  </ValidatorForm>
                </Dialog>

                <Dialog
                  scroll="body"
                  maxWidth="md"
                  open={modal4}
                  onClose={handleClose4}
                  classes={{
                    paper: 'modal-content rounded border-0',
                  }}
                  fullWidth={true}
                  onKeyDown={stopPropagationForTab}
                >
                  <BlockUi
                    blocking={loader}
                    loader={
                      <PacmanLoader
                        color={'var(--primary)'}
                        loading={true}
                      />
                    }
                    message={
                      <div className="text-primary">
                        Analyzing Property
                      </div>
                    }
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={5}>
                        <div
                          style={{ height: '100%', width: '100%' }}
                        >
                          <GoogleMapReact
                            fullWidth={true}
                            defaultCenter={
                              addPropertyDetailsValues?.location
                            }
                            defaultZoom={17}
                          >
                            <MapMarker
                              lat={
                                addPropertyDetailsValues?.location.lat
                              }
                              lng={
                                addPropertyDetailsValues?.location.lng
                              }
                              icon={
                                <Avatar src="/images/SubjectProertyIcon.svg" />
                              }
                              /*text="Property"*/
                            />
                          </GoogleMapReact>
                        </div>

                        {/* STREET VIEW IS BLINKING - WE WILL FIX LATER
                      <Map properties={[{ location: { latitude: location.lat, longitude: location.lng } }]} propertyPage streetView={true} height="100%" /> 
                      */}
                        {/* <img
                        alt="..."
                        className="rounded br-xl-right-0 img-fit-container"
                        src={stock1}
                      /> */}
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Card>
                          <CardHeader
                            title="Is this the property?"
                            action={
                              <IconButton
                                onClick={handleClose4}
                                disabled={loader}
                                size="large"
                              >
                                <CloseOutlinedIcon />
                              </IconButton>
                            }
                          />
                          <Divider />
                          <CardContent>
                            <Grid
                              container
                              direction="column"
                              spacing={1}
                            >
                              <Grid item>
                                <Collapse
                                  in={
                                    !addPropertyDetailsValues.beds ||
                                    !addPropertyDetailsValues.sqft ||
                                    !addPropertyDetailsValues.zip
                                  }
                                >
                                  <Alert
                                    className="mb-4"
                                    severity="error"
                                  >
                                    Missing necessary data. Click fix
                                    button.
                                  </Alert>
                                </Collapse>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant="h5"
                                  style={{ fontWeight: 600 }}
                                >
                                  {currencyFormat.format(
                                    addPropertyDetailsValues.userListPrice,
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                }}
                              >
                                <Typography>
                                  {addPropertyDetailsValues.address},{' '}
                                  {addPropertyDetailsValues.city},{' '}
                                  {addPropertyDetailsValues.state}{' '}
                                  {addPropertyDetailsValues.zip}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                }}
                              >
                                <Typography
                                  style={{ color: '#949798' }}
                                >
                                  {sum(addPropertyDetailsValues.beds)}{' '}
                                  bds |{' '}
                                  {sum(
                                    addPropertyDetailsValues.baths,
                                  )}{' '}
                                  ba |{' '}
                                  {formatter.format(
                                    sum(
                                      addPropertyDetailsValues.sqft,
                                    ),
                                  )}{' '}
                                  sqft |{' '}
                                  {addPropertyDetailsValues.year} year
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="column"
                                spacing={1}
                              >
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ boxShadow: 'none' }}
                                    disabled={
                                      loader ||
                                      !addPropertyDetailsValues.beds ||
                                      !addPropertyDetailsValues.sqft ||
                                      !addPropertyDetailsValues.zip
                                    }
                                    fullWidth={true}
                                    onClick={() =>
                                      runMutation(
                                        client,
                                        data.portfolios,
                                        addPropertyDetailsValues,
                                      )
                                    }
                                  >
                                    <Typography>
                                      Analyze Property
                                    </Typography>
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: 'black',
                                      backgroundColor: '#e3e7eb',
                                      boxShadow: 'none',
                                    }}
                                    disabled={loader}
                                    fullWidth={true}
                                    onClick={handleClickOpen2}
                                  >
                                    <Typography>
                                      Fix Address / Property Details
                                    </Typography>
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>
                  </BlockUi>
                </Dialog>

                <PropertyInformationDialogs
                  open={modal5}
                  onClose={() => toggle5()}
                  property={property}
                />

                <Dialog
                  // classes={{ paper: 'modal-content bg-white rounded-lg modal-dark' }}
                  open={open2}
                  onClose={handleClose2}
                  onKeyDown={stopPropagationForTab}
                >
                  <DialogTitle>Correct the address</DialogTitle>
                  <DialogContent
                    style={{ paddingTop: 15, paddingBottom: 15 }}
                  >
                    <BlockUi
                      blocking={loader}
                      loader={
                        <PacmanLoader
                          color={'var(--primary)'}
                          loading={true}
                        />
                      }
                      message={
                        <div className="text-primary">
                          Updating Property
                        </div>
                      }
                    >
                      <DialogContent>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          className="mb-0"
                        >
                          <Grid item xs={12}>
                            <Typography
                              color="primary"
                              style={{
                                fontWeight: 600,
                                marginBottom: -10,
                              }}
                            >
                              Location
                            </Typography>
                          </Grid>
                          {map(
                            correctAddressLocationFields,
                            (section) => (
                              <Grid
                                item
                                xs={12}
                                sm={section.width}
                                key={section.label}
                              >
                                {section.select ? (
                                  <Autocomplete
                                    value={section.value}
                                    options={section.options}
                                    onChange={(event, newValue) =>
                                      section.onChange({
                                        target: { value: newValue },
                                      })
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={section.label}
                                        size="small"
                                        variant="outlined"
                                        margin="dense"
                                      />
                                    )}
                                  />
                                ) : (
                                  <TextField
                                    required={section.required}
                                    variant="outlined"
                                    size="small"
                                    autoFocus={section.autoFocus}
                                    margin="dense"
                                    value={section.value}
                                    onChange={section.onChange}
                                    // id="input-with-icon-textfield134"
                                    label={section.label}
                                    placeholder={section.placeholder}
                                    type={section.type}
                                    error={
                                      !section.value &&
                                      section.label === 'Zip'
                                    }
                                    fullWidth
                                    InputProps={{
                                      endAdornment:
                                        section.definition &&
                                        propertyData &&
                                        propertyData[
                                          section.definition
                                        ] &&
                                        propertyData[
                                          section.definition
                                        ].length > 1 ? (
                                          <InputAdornment
                                            position="end"
                                            onClick={(event) =>
                                              handleDefinition(
                                                section.definition,
                                                event.target,
                                                () =>
                                                  section.onChange,
                                              )
                                            }
                                          >
                                            <IconButton
                                              size="small"
                                              style={{ padding: 5 }}
                                            >
                                              <FontAwesomeIcon
                                                icon={[
                                                  'fal',
                                                  'database',
                                                ]}
                                                className="font-size-xl"
                                              />
                                            </IconButton>
                                          </InputAdornment>
                                        ) : null,
                                    }}
                                  />
                                )}
                              </Grid>
                            ),
                          )}
                        </Grid>
                        <Divider
                          style={{ marginTop: 20, marginBottom: 20 }}
                        />
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          className="mb-0"
                        >
                          <Grid item xs={12}>
                            <List
                              className="nav-line d-flex mt-3"
                              style={{
                                width:
                                  addPropertyDetailsValues?.beds
                                    .length * 100,
                              }}
                            >
                              {addPropertyDetailsValues?.beds.length >
                              1 ? (
                                map(
                                  addPropertyDetailsValues.beds,
                                  (value, index) => (
                                    <ListItem
                                      button
                                      disableRipple
                                      selected={activeUnit === index}
                                      onClick={() => {
                                        setActiveUnit(index)
                                      }}
                                    >
                                      Unit {index + 1}
                                    </ListItem>
                                  ),
                                )
                              ) : (
                                <Typography
                                  color="primary"
                                  style={{
                                    fontWeight: 600,
                                    marginBottom: -10,
                                  }}
                                >
                                  Property Details
                                </Typography>
                              )}
                            </List>
                          </Grid>
                          {map(
                            correctAddressPropertyDetailsFields,
                            (section) => (
                              <Grid
                                item
                                xs={12}
                                sm={section.width}
                                key={section.label}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  autoFocus={section.autoFocus}
                                  margin="dense"
                                  value={section.value}
                                  onChange={section.onChange}
                                  // id="input-with-icon-textfield134"
                                  label={section.label}
                                  placeholder={section.placeholder}
                                  type={section.type}
                                  required={section.required}
                                  fullWidth
                                  InputProps={{
                                    endAdornment:
                                      section.definition &&
                                      propertyData &&
                                      propertyData[
                                        section.definition
                                      ] &&
                                      propertyData[section.definition]
                                        .length > 1 ? (
                                        <InputAdornment
                                          position="end"
                                          onClick={(event) =>
                                            handleDefinition(
                                              section.definition,
                                              event.target,
                                              () => section.onChange,
                                            )
                                          }
                                        >
                                          <IconButton
                                            size="small"
                                            style={{ padding: 5 }}
                                          >
                                            <FontAwesomeIcon
                                              icon={[
                                                'fal',
                                                'database',
                                              ]}
                                              className="font-size-xl"
                                            />
                                          </IconButton>
                                        </InputAdornment>
                                      ) : null,
                                  }}
                                />
                              </Grid>
                            ),
                          )}
                        </Grid>
                      </DialogContent>
                    </BlockUi>
                  </DialogContent>
                  <DialogActions>
                    <Typography style={{ flex: 1, color: 'red' }}>
                      Required Fields *
                    </Typography>
                    <Button
                      onClick={handleClose2}
                      variant="text"
                      disabled={loader}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        loader ||
                        !addPropertyDetailsValues.beds ||
                        !addPropertyDetailsValues.sqft ||
                        !addPropertyDetailsValues.zip
                      }
                      onClick={() =>
                        runMutation(
                          client,
                          data.portfolios,
                          addPropertyDetailsValues,
                        )
                      }
                    >
                      Analyze
                    </Button>
                  </DialogActions>
                </Dialog>

                {menu && (
                  <Menu
                    anchorEl={anchorElDefinition}
                    open={Boolean(anchorElDefinition)}
                    onClose={() => handleDefinition('', null, null)}
                    MenuListProps={{ dense: true }}
                    style={{ width: 400 }}
                    getContentAnchorEl={null}
                    classes={{ list: 'p-0' }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div className="dropdown-menu-xl">
                      <MenuItem key="menuTitle" button={false}>
                        <Typography variant="h6">
                          {startCase(menu)}
                        </Typography>
                      </MenuItem>
                      <MenuItem key="menuDescription" button={false}>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          style={{
                            whiteSpace: 'normal',
                          }}
                        >
                          We found multiple values for this field.
                          Please choose the correct option.
                        </Typography>
                      </MenuItem>
                      {map(propertyData[menu], (value) => (
                        <MenuItem
                          button
                          key={menu + ': ' + value}
                          onClick={() => {
                            menuChange({ target: { value } })
                            handleDefinition('', null, null)
                          }}
                        >
                          <Typography style={{ flex: 1 }} />
                          <ListItemText>{value}</ListItemText>
                        </MenuItem>
                      ))}
                    </div>
                  </Menu>
                )}
              </>
            )}
          </Query>
        )}
      </ApolloConsumer>
    </>
  )
}

export default withSession(AddPropertyButton)
