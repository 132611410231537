/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-04 11:52:13
 * @ Description: Dialog to send contact agent email
 */

import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from '@mui/styles'

import {
  Alert,
  AppBar,
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  MenuItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'

import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import head from 'lodash/head'
import map from 'lodash/map'
import get from 'lodash/get'
import includes from 'lodash/includes'
import split from 'lodash/split'
import concat from 'lodash/concat'
import clone from 'lodash/clone'
import pullAt from 'lodash/pullAt'

import withSession from '@/housefolios-components/Session/withSession'
import ContactAgentStepper from '../ContactAgentStepper'
import housefoliosLogo from '@/assets/images/HousefoliosCloudTransparent.png'

import { enqueueSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { CONTACT_AGENT } from '../mutations'
import BlockUi from 'react-block-ui'
import CheckIcon from '@mui/icons-material/Check'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import { PacmanLoader } from 'react-spinners'
import { NumericFormat } from 'react-number-format'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { useDropzone } from 'react-dropzone'
import AddOption from '@/housefolios-components/Dialogs/AddOption'
import RemoveOption from '@/housefolios-components/Dialogs/RemoveOption'
import { pepContactAgentEmail } from '@/constants/emails'
import useAffiliate from '@/hooks/useAffiliate'
import ReactQuill from 'react-quill'
import { modules } from '@/constants/reactQuill'
import FileUpload from '@/housefolios-components/PropertyContainer/PropertyDashboard/FileUpload'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(3),
    color: 'white',
    flex: 1,
  },
}))

function ContactAgentComponent(props) {
  const classes = useStyles()
  const {
    session: {
      me: {
        // profile: { firstname = '', lastname = '' },
        // email = '',
        // phone = '',
        activeOrg,
        profile = {},
      },
    },
    property: { _id, address = '', city = '', state = '', zip = '' },
  } = props

  const targetMarket = find(
    activeOrg.marketPlaces,
    (marketplace) => props.marketplace === marketplace.url,
  )
  let contactEmail = ''
  if (targetMarket) contactEmail = targetMarket.contactEmail

  const preferences = merge(
    cloneDeep(DEFAULT_PREFERENCES),
    props?.session?.me?.activeOrg?.memberPreferences,
    props?.session?.me?.activeOrg?.preferences,
  )

  const { contactAgentPreferences = {}, contactAgentTemplate = {} } =
    preferences

  const [file, setFile] = useState([])
  const uploadFile = (files) => {
    let newFiles = clone(file) || []
    map(files, (file) => {
      newFiles.push(file)
    })
    setFile(newFiles)
  }

  const deleteFile = (index) => {
    let newFiles = clone(file) || []
    pullAt(newFiles, index)
    setFile(newFiles)
  }

  const [saveDefaultValue, setSaveDefaultValue] = useState(false)

  const [openAddComp, setOpenAddComp] = useState(false)
  const [loader, setLoader] = useState(false)
  const handleOpenAddComp = () => {
    setOpenAddComp(true)
  }
  const handleCloseAddComp = () => {
    setOpenAddComp(false)
  }

  const [firstname, setFirstname] = useState(
    props.session.me.profile.firstname,
  )
  const [lastname, setLastname] = useState(
    props.session.me.profile.lastname,
  )
  const [email, setEmail] = useState(props.session.me.email)
  const [phone, setPhone] = useState(props.session.me.phone)

  const [message, setMessage] = useState('')

  const [offerPrice, setOfferPrice] = useState(
    props.defaultOffer
      ? props.defaultOffer
      : props.property.acquisition
        ? props.property.acquisition.analysis.offerPrice
        : '',
  )

  const [conditions, setConditions] = useState(
    contactAgentPreferences?.conditions ||
      contactAgentTemplate?.conditions ||
      '',
  )
  const [statements, setStatements] = useState(
    contactAgentPreferences?.statements ||
      contactAgentTemplate?.statements ||
      '',
  )
  const [inspectionPeriod, setInspectionPeriod] = useState(
    contactAgentPreferences?.inspectionPeriod ||
      contactAgentTemplate?.inspectionPeriod ||
      '',
  )
  const [daysToClose, setDaysToClose] = useState(
    contactAgentPreferences?.daysToClose ||
      contactAgentTemplate?.daysToClose ||
      '',
  )
  const [earnestMoney, setEarnestMoney] = useState(
    contactAgentPreferences?.earnestMoney ||
      contactAgentTemplate?.earnestMoney ||
      '',
  )
  const [entityName, setEntityName] = useState(activeOrg.name)
  const [entityAddress, setEntityAddress] = useState(
    activeOrg.address,
  )
  const [entityCity, setEntityCity] = useState(activeOrg.city)
  const [entityState, setEntityState] = useState(activeOrg.state)
  const [entityZip, setEntityZip] = useState(activeOrg.zip)
  const [entityEmail, setEntityEmail] = useState(activeOrg.email)
  const [entitySigner, setEntitySigner] = useState(
    profile.firstname + ' ' + profile.lastname,
  )

  const [buyerSellerProfile, setBuyerSellerProfile] = useState(
    head(activeOrg?.buyerSellerProfile)?.value || '',
  )

  const selectedBuyerSellerProfile = find(
    activeOrg?.buyerSellerProfiles || [],
    (profile) => {
      return profile.value === buyerSellerProfile
    },
  )

  const [{ affiliate, affiliateLoading, urlAffiliate }] =
    useAffiliate({
      memberValue: activeOrg?.member,
    })

  const getPepEmailParams = () => {
    let linkStart = ''

    const hostname = import.meta.env.VITE_HOSTNAME

    if (hostname == 'localhost') {
      linkStart = `http://localhost:3000/`
    } else if (hostname == 'dev-admin.housefolios.com') {
      linkStart = `https://dev-admin.housefolios.com/`
    } else {
      linkStart = `https://app.housefolios.com/`
    }

    return {
      headerLogo:
        selectedBuyerSellerProfile?.logoImage?.url ||
        get(affiliate, 'sidebar.sidebarMemberSmallImage.0.url') ||
        housefoliosLogo,
      hideSocialIcons: true,
      hideOrgInfo: true,
      affiliateName: affiliate?.companyName,
      affiliateURL: urlAffiliate,
      linkStart,
    }
  }

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const [introduction, setIntroduction] =
    useState(`<h1>We're Interested in Your Property</h1>
          <p>Dear Homeowner,</p>
          <p>
            Thank you for considering us as potential buyers for your property. After reviewing your listing at ${address} ${city}, ${state} ${zip}, we are excited about the opportunity to present you with an offer. We understand that selling a home is a significant decision, and our goal is to provide a smooth and transparent experience.
          </p>`)

  const { analysis = {} } = props.property.acquisition || {}

  useEffect(() => {
    setOfferPrice(
      props.defaultOffer
        ? props.defaultOffer
        : analysis.offerPrice || 0,
    )
  }, [analysis.offerPrice, props.defaultOffer])

  const [manualContactEmail, setManualContactEmail] = useState([])
  const [ccEmail, setCCEmail] = useState([])
  const [bccEmail, setBCCEmail] = useState([])

  const [contactAgent] = useMutation(CONTACT_AGENT)
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const contact = () => {
    setLoader(true)
    if (props.session.me.activeOrg.isRealeflow) {
      contactAgent({
        variables: {
          propertyId: _id,
          address,
          contactEmail: manualContactEmail,
          ccEmail: ccEmail,
          bccEmail: bccEmail,
          html: pepContactAgentEmail(
            getPepEmailParams(),
            address,
            city,
            state,
            zip,
            currencyFormat.format(offerPrice),
            selectedBuyerSellerProfile?.conditions,
            selectedBuyerSellerProfile?.statements,
            selectedBuyerSellerProfile?.inspectionPeriod,
            selectedBuyerSellerProfile?.daysToClose,
            selectedBuyerSellerProfile?.earnestMoney,
            selectedBuyerSellerProfile?.value,
            selectedBuyerSellerProfile?.address,
            selectedBuyerSellerProfile?.city,
            selectedBuyerSellerProfile?.state,
            selectedBuyerSellerProfile?.zip,
            selectedBuyerSellerProfile?.email,
            selectedBuyerSellerProfile?.phone,
            selectedBuyerSellerProfile?.signer,
            introduction,
          ),
          entityEmail: selectedBuyerSellerProfile?.email || '',
          pep: true,
          base64: map(file, (f) => f.url),
        },
      }).then(({ data }) => {
        enqueueSnackbar(`Message sent successfully`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        setLoader(false)
        handleCloseAddComp()
        updateOrganization({
          variables: {
            id: props.session.me.activeOrg._id,
            organizationInput: {
              preferences: {
                ...preferences,
                contactAgentPreferences: {
                  conditions,
                  statements,
                  inspectionPeriod,
                  daysToClose,
                  earnestMoney,
                },
              },
            },
          },
          refetchQueries: [{ query: GET_ME }],
        })
        setManualContactEmail([])
        setCCEmail([])
        setBCCEmail([])
        setIntroduction(`<h1>We're Interested in Your Property</h1>
          <p>Dear Homeowner,</p>
          <p>
            Thank you for considering us as potential buyers for your property. After reviewing your listing at ${address} ${city}, ${state} ${zip}, we are excited about the opportunity to present you with an offer. We understand that selling a home is a significant decision, and our goal is to provide a smooth and transparent experience.
          </p>`)
        setFile([])
      })
    } else {
      contactAgent({
        variables: {
          message,
          propertyId: _id,
          contactEmail: [contactEmail],
          firstname,
          lastname,
          phone,
          email,
          address,
          city,
          state,
          zip,
          RWN: true,
        },
      }).then(({ data }) => {
        enqueueSnackbar(`Message sent successfully`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        setLoader(false)
        handleCloseAddComp()
      })
    }
  }

  const disabled = props.property.offerMade

  // const files = file.map((file) => (
  //   <ListItem
  //     className="font-size-sm px-3 py-2 text-primary d-flex justify-content-between align-items-center"
  //     key={file.path}
  //   >
  //     <span>{file.path}</span>{' '}
  //     <span className="badge badge-pill bg-neutral-warning text-warning">
  //       {file.size} bytes
  //     </span>
  //   </ListItem>
  // ))

  return (
    <>
      {/* <Button onClick={handleOpenAddComp} className="btn-outline-primary d-flex align-items-center justify-content-center d-40 p-0 rounded-pill">
        <AddTwoToneIcon className="w-50" />
      </Button> */}
      {props.marketplace && (
        <Button
          fullWidth
          variant="contained"
          onClick={handleOpenAddComp}
        >
          Contact Agent
        </Button>
      )}
      {props.action && (
        <ListItem
          button
          onClick={handleOpenAddComp}
          disabled={disabled}
        >
          <div className="nav-link-icon">
            <FontAwesomeIcon icon={['far', 'file-invoice-dollar']} />
          </div>
          <span>{disabled ? 'Offer Submitted' : 'Make Offer'}</span>
        </ListItem>
      )}
      {props.propertyPage && (
        <Button
          data-tour="make-offer-button"
          // data-tour-buyHold="strategy-step-1"
          data-tour-fixFlip="strategy-step-2"
          // data-tour-turnkey="strategy-step-1"
          // data-tour-brrrr="strategy-step-1"
          // data-tour-shortTermRental="strategy-step-1"
          // data-tour-wholesale="strategy-step-1"
          // data-tour-buildToRent="strategy-step-1"
          fullWidth
          variant="contained"
          className="btn-primary font-weight-bold text-uppercase font-size-sm hover-scale-sm"
          onClick={handleOpenAddComp}
          disabled={props.canDrag || disabled}
        >
          {disabled ? 'Offer Submitted' : 'Make Offer'}
        </Button>
      )}
      {props.propertySummary && (
        // <Button fullWidth variant='contained' color="primary" onClick={handleOpenAddComp}>
        <Button
          fullWidth
          className="btn-neutral-first"
          onClick={handleOpenAddComp}
          disabled={disabled}
        >
          {disabled ? 'Offer Submitted' : 'Make Offer'}
        </Button>
      )}

      <Dialog
        open={openAddComp}
        onClose={handleCloseAddComp}
        classes={{ paper: 'shadow-lg rounded' }}
        // fullScreen={props?.session?.me?.activeOrg?.member !== 'RWN'}
        onClick={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
        maxWidth="lg"
        fullWidth={true}
      >
        {props?.session?.me?.activeOrg?.member !== 'RWN' &&
          !props.session.me.activeOrg.isRealeflow && (
            <>
              <AppBar enableColorOnDark className={classes.appBar}>
                <Toolbar>
                  <Typography variant="h6" className={classes.title}>
                    Message to Agent
                  </Typography>
                  <Button
                    variant="outlined"
                    margin="dense"
                    style={{ color: 'white', borderColor: 'white' }}
                    onClick={handleCloseAddComp}
                  >
                    Cancel
                  </Button>
                </Toolbar>
              </AppBar>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 100,
                    paddingLeft: 30,
                    paddingRight: 30,
                    marginBottom: 100,
                  }}
                >
                  <Card>
                    <ContactAgentStepper
                      property={props.property}
                      handleCloseAddComp={handleCloseAddComp}
                      marketplace={props.marketplace}
                    />
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        {props.session.me.activeOrg.isRealeflow && (
          <ValidatorForm onSubmit={contact}>
            <DialogTitle>
              <Typography>Contact Seller</Typography>
            </DialogTitle>
            <CardContent>
              <BlockUi
                blocking={loader}
                loader={
                  <PacmanLoader
                    color={'var(--primary)'}
                    loading={true}
                  />
                }
                message={
                  <div className="text-primary">Sending Message</div>
                }
              >
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Grid item xs={12}>
                      <TextField
                        name="buyerSellerProfiles"
                        select
                        fullWidth={true}
                        variant="outlined"
                        margin="dense"
                        label="Buyer/Seller Profile"
                        value={
                          includes(
                            activeOrg?.buyerSellerProfiles || [],
                            buyerSellerProfile,
                          ) ||
                          find(activeOrg?.buyerSellerProfiles || [], {
                            value: buyerSellerProfile,
                          })
                            ? buyerSellerProfile
                            : ''
                        }
                        required
                        onChange={(event) =>
                          setBuyerSellerProfile(event.target.value)
                        }
                        SelectProps={{
                          renderValue: (x) => {
                            let option = find(
                              activeOrg?.buyerSellerProfiles,
                              {
                                value: x,
                              },
                            )
                            if (option) return option.label
                            else if (
                              includes(
                                activeOrg?.buyerSellerProfiles,
                                x,
                              )
                            )
                              return x
                            else return null
                          },
                        }}
                      >
                        <MenuItem key="" value="">
                          No Selected Value
                        </MenuItem>
                        {map(
                          activeOrg?.buyerSellerProfiles,
                          (option) =>
                            option.value ? (
                              option.tip ? (
                                <Tooltip
                                  title={option.tip}
                                  key={option.value}
                                  value={option.value}
                                >
                                  <MenuItem>
                                    <Grid
                                      container
                                      justifyContent="space-between"
                                    >
                                      <Grid item>
                                        <Typography>
                                          {option.title}
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography>
                                          {option.label}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </MenuItem>
                                </Tooltip>
                              ) : (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  <Grid container alignItems="center">
                                    <Grid item>{option.label}</Grid>
                                    <Typography style={{ flex: 1 }} />
                                    <RemoveOption
                                      removeOption={
                                        'buyerSellerProfiles'
                                      }
                                      removeOptionLabel={
                                        'Buyer/Seller Profile'
                                      }
                                      removeOptionValue={option.value}
                                      removeOptionItemLabel={
                                        option.label
                                      }
                                    />
                                  </Grid>
                                </MenuItem>
                              )
                            ) : (
                              <MenuItem key={option} value={option}>
                                <Grid container alignItems="center">
                                  <Grid item>{option}</Grid>
                                  <Typography style={{ flex: 1 }} />
                                  <RemoveOption
                                    removeOption={
                                      'buyerSellerProfiles'
                                    }
                                    removeOptionLabel={
                                      'Buyer/Seller Profile'
                                    }
                                    removeOptionValue={option}
                                  />
                                </Grid>
                              </MenuItem>
                            ),
                        )}
                        <AddOption
                          currentValue={buyerSellerProfile}
                          addOption={'buyerSellerProfiles'}
                          addOptionLabel={'Buyer/Seller Profile'}
                          addOptionTarget={'buyerSellerProfile'}
                          onChangeProperty={(event) =>
                            setBuyerSellerProfile(event.target.value)
                          }
                        />
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        Which email would you like to send the offer
                        to?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        id="emails"
                        options={[]}
                        value={manualContactEmail}
                        freeSolo
                        autoSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Send Email To"
                            placeholder="Emails"
                            helperText="Enter comma separated emails (no spaces). Press enter"
                          />
                        )}
                        onChange={(event, inputs, reason) => {
                          console.log(reason)
                          if (
                            'createOption' == reason ||
                            'blur' == reason
                          ) {
                            let values = split(
                              inputs[inputs.length - 1],
                              ',',
                            )
                            let validatedItems = []

                            for (let value of values) {
                              if (
                                String(value)
                                  .toLowerCase()
                                  .match(
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  )
                              )
                                validatedItems.push(value)
                            }

                            let newList = concat(
                              manualContactEmail,
                              validatedItems,
                            )
                            setManualContactEmail(newList)
                          } else if ('removeOption' == reason) {
                            setManualContactEmail(inputs)
                          } else if ('clear' == reason) {
                            setManualContactEmail([])
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>CC Emails</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        id="ccemails"
                        options={[]}
                        value={ccEmail}
                        freeSolo
                        autoSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="CC Emails"
                            placeholder="Emails"
                            helperText="Enter comma separated emails (no spaces). Press enter"
                          />
                        )}
                        onChange={(event, inputs, reason) => {
                          if (
                            'createOption' == reason ||
                            'blur' == reason
                          ) {
                            let values = split(
                              inputs[inputs.length - 1],
                              ',',
                            )
                            let validatedItems = []

                            for (let value of values) {
                              if (
                                String(value)
                                  .toLowerCase()
                                  .match(
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  )
                              )
                                validatedItems.push(value)
                            }

                            let newList = concat(
                              ccEmail,
                              validatedItems,
                            )
                            setCCEmail(newList)
                          } else if ('removeOption' == reason) {
                            setCCEmail(inputs)
                          } else if ('clear' == reason) {
                            setCCEmail([])
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>BCC Emails</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        id="ccemails"
                        options={[]}
                        value={bccEmail}
                        freeSolo
                        autoSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="BCC Emails"
                            placeholder="Emails"
                            helperText="Enter comma separated emails (no spaces). Press enter"
                          />
                        )}
                        onChange={(event, inputs, reason) => {
                          if (
                            'createOption' == reason ||
                            'blur' == reason
                          ) {
                            let values = split(
                              inputs[inputs.length - 1],
                              ',',
                            )
                            let validatedItems = []

                            for (let value of values) {
                              if (
                                String(value)
                                  .toLowerCase()
                                  .match(
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  )
                              )
                                validatedItems.push(value)
                            }

                            let newList = concat(
                              bccEmail,
                              validatedItems,
                            )
                            setBCCEmail(newList)
                          } else if ('removeOption' == reason) {
                            setBCCEmail(inputs)
                          } else if ('clear' == reason) {
                            setBCCEmail([])
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ReactQuill
                        theme="snow"
                        value={introduction}
                        onChange={(value) => {
                          setIntroduction(value)
                        }}
                        placeholder="This is my message to the seller"
                        style={{ height: 320 }}
                        modules={modules}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 40 }}>
                      <FileUpload
                        documentId={_id}
                        files={file}
                        uploadFile={uploadFile}
                        deleteFile={deleteFile}
                        filetypes={['image/*']}
                        notSave
                        // maxFiles={1}
                        buttonText="Add Files"
                        button
                        collection="Profiles"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        height: '100%',
                      }}
                    >
                      <iframe
                        srcDoc={pepContactAgentEmail(
                          getPepEmailParams(),
                          address,
                          city,
                          state,
                          zip,
                          currencyFormat.format(offerPrice),
                          selectedBuyerSellerProfile?.conditions,
                          selectedBuyerSellerProfile?.statements,
                          selectedBuyerSellerProfile?.inspectionPeriod,
                          selectedBuyerSellerProfile?.daysToClose,
                          selectedBuyerSellerProfile?.earnestMoney,
                          selectedBuyerSellerProfile?.value,
                          selectedBuyerSellerProfile?.address,
                          selectedBuyerSellerProfile?.city,
                          selectedBuyerSellerProfile?.state,
                          selectedBuyerSellerProfile?.zip,
                          selectedBuyerSellerProfile?.email,
                          selectedBuyerSellerProfile?.phone,
                          selectedBuyerSellerProfile?.signer,
                          introduction,
                        )}
                        title="Email Preview"
                        style={{
                          width: '100%',
                          height: '100%',
                          border: 'none',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </BlockUi>
            </CardContent>
            <Divider />
            <CardActions>
              <Typography style={{ flex: 1 }} />
              <Button
                size="small"
                variant="text"
                onClick={handleCloseAddComp}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
              >
                Send
              </Button>
            </CardActions>
          </ValidatorForm>
        )}
        {props?.session?.me?.activeOrg?.member === 'RWN' && (
          <ValidatorForm onSubmit={contact}>
            <DialogTitle>
              <Typography>Message to Agent</Typography>
            </DialogTitle>
            <CardContent>
              <BlockUi
                blocking={loader}
                loader={
                  <PacmanLoader
                    color={'var(--primary)'}
                    loading={true}
                  />
                }
                message={
                  <div className="text-primary">Sending Message</div>
                }
              >
                <Grid container direction="row" spacing={2}>
                  <Grid item sm={6}>
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      // margin='dense'
                      label="First Name"
                      value={firstname}
                      onChange={(event) =>
                        setFirstname(event.target.value)
                      }
                      required={true}
                      validators={[
                        'required',
                        'maxStringLength: 20',
                        'matchRegexp:^[A-z ]+$',
                      ]}
                      errorMessages={[
                        'This field is required',
                        'You cannot exceed 20 characters',
                        'This field only allows characters in the alphabet and spaces',
                      ]}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      // margin='dense'
                      label="Last Name"
                      value={lastname}
                      onChange={(event) =>
                        setLastname(event.target.value)
                      }
                      required={true}
                      validators={[
                        'required',
                        'maxStringLength: 20',
                        'matchRegexp:^[A-z ]+$',
                      ]}
                      errorMessages={[
                        'This field is required',
                        'You cannot exceed 20 characters',
                        'This field only allows characters in the alphabet and spaces',
                      ]}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      // margin='dense'
                      label="Email Address"
                      value={email}
                      onChange={(event) =>
                        setEmail(event.target.value)
                      }
                      required={true}
                      validators={[
                        'required',
                        'maxStringLength: 50',
                        'isEmail',
                      ]}
                      errorMessages={[
                        'This field is required',
                        'You cannot exceed 50 characters',
                        'Not a valid email address',
                      ]}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      // margin='dense'
                      label="Phone"
                      value={phone}
                      onChange={(event) =>
                        setPhone(event.target.value)
                      }
                      required={true}
                      validators={[
                        'required',
                        'matchRegexp:^[0-9()-]+$',
                      ]}
                      errorMessages={[
                        'This field is required',
                        'Not a valid phone number',
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      fullWidth
                      variant="outlined"
                      // margin='dense'
                      label="Comments"
                      value={message}
                      onChange={(event) =>
                        setMessage(event.target.value)
                      }
                      required={true}
                      multiline={true}
                      rows={4}
                      validators={[
                        'required',
                        'maxStringLength: 3000',
                      ]}
                      errorMessages={[
                        'This field is required',
                        'You cannot exceed 3000 characters',
                      ]}
                    />
                  </Grid>
                </Grid>
              </BlockUi>
            </CardContent>
            <Divider />
            <CardActions>
              <Typography style={{ flex: 1 }} />
              <Button
                size="small"
                variant="text"
                onClick={handleCloseAddComp}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
              >
                Send
              </Button>
            </CardActions>
          </ValidatorForm>
        )}
      </Dialog>
    </>
  )
}

export default withSession(ContactAgentComponent)
