import { gql } from '@apollo/client'

export const IMPORT_LEADS = gql`
  mutation ImportLeads(
    $batchId: String!
    $orgId: String!
    $portfolioId: String!
    $importDuplicates: Boolean!
  ) {
    importLeads(
      batchId: $batchId
      orgId: $orgId
      portfolioId: $portfolioId
      importDuplicates: $importDuplicates
    ) {
      success
    }
  }
`

export const DELETE_BATCH = gql`
  mutation deleteBatch($batchId: String!, $orgId: String!) {
    deleteBatch(batchId: $batchId, orgId: $orgId) {
      success
    }
  }
`
