/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-16 11:36:56
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-31 09:48:37
 * @ Description: Summary page used for properties that are not owned
 */

import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import map from 'lodash/map'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import get from 'lodash/get'
import find from 'lodash/find'
import filter from 'lodash/filter'
import sum from 'lodash/sum'
import isNil from 'lodash/isNil'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import replace from 'lodash/replace'
import padStart from 'lodash/padStart'
import truncate from 'lodash/truncate'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Hidden,
  Tooltip,
  Typography,
  IconButton,
  Badge,
  Backdrop,
  CircularProgress,
  useMediaQuery,
} from '@mui/material'

import Slider from 'react-slick'

import withSession from '@/housefolios-components/Session/withSession'
import GaugeChart from 'react-gauge-chart'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import RemoveIcon from '@mui/icons-material/Remove'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import ImageIcon from '@mui/icons-material/Image'
import RoomIcon from '@mui/icons-material/Room'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'

import Map from '../../../Properties/Map'
import { useMutation, useLazyQuery } from '@apollo/client'
import {
  CALL_HOUSE_CANARY_PROPERTY_EXPLORER,
  UPDATE_PROPERTY,
} from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { Home, Image } from '@mui/icons-material'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import EditStrategies from '@/housefolios-components/Dialogs/EditStrategies'
import { CALL_REAPI_SKIPTRACE } from '@/housefolios-components/PropertySearch/mutations'
import SkipTrace from '@/housefolios-components/Dialogs/SkipTrace'
import { head } from 'lodash'
import { getStrategyName } from '@/utils/stratagies'
import CardSummary from '@/housefolios-components/PropertyContainer/CenterPage/summary/summaryCard'

function CenterPageSummary(props) {
  const { property } = props
  const { acquisition = {} } = property
  const { analytics = DEFAULT_ANALYTICS, analysis = {} } = acquisition

  const hiddenDown = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  )
  const hiddenUp = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'),
  )

  const [activeTab, setActiveTab] = useState('Images')

  const [loading, setLoading] = useState(false)
  const [callPropertyExplorerReport] = useMutation(
    CALL_HOUSE_CANARY_PROPERTY_EXPLORER,
  )

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  // const [callREAPISkipTrace] = useMutation(
  //   CALL_REAPI_SKIPTRACE,
  //   {
  //     variables: {
  //       address: property.address,
  //       city: property.city,
  //       state: property.state,
  //       zip: padStart(
  //         truncate(property.zip, {
  //           length: 5,
  //           omission: '',
  //         }),
  //         5,
  //         '0',
  //       ),
  //     },
  //   },
  // )

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })

  const gaugeFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
  })

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const propertyDetails1 = [
    {
      name: 'Beds',
      value: sum(property.beds),
    },
    {
      name: 'Baths',
      value: sum(property.baths),
    },
    {
      name: 'Sqft',
      value: sum(property.sqft),
      formatter: numberFormat,
    },
    {
      name: 'Yr Built',
      value: property.year,
    },
    // {
    //   name: 'Property Type',
    //   value: 'Single Family'
    // },
    // {
    //   name: 'Rental Status',
    //   value: 'Leased',
    // },
    // {
    //   name: 'Parking',
    //   value: '2-car garage'
    // },
    // {
    //   name: 'Cooling',
    //   value: 'Swamp Cooler'
    // },
    // {
    //   name: 'Heating',
    //   value: 'Forced air'
    // },
  ]

  const propertyDetails2 = [
    {
      name: 'Water',
      value: property.water,
    },
    {
      name: 'Sewer',
      value: property.sewer,
    },
    {
      name: 'Market',
      value: property.market,
    },
    {
      name: 'Type',
      value: property.type,
    },
    {
      name: 'Plan',
      value: property.plan,
    },
    {
      name: 'Zone',
      value: property.zone,
    },
    {
      name: 'Parcel ID',
      value: property.parcelID,
    },
  ]

  const propertyDetailsPEP = [
    {
      name: 'APN',
      value: property.apn,
    },
    {
      name: 'Lien',
      value: property.lien ? 'True' : 'False',
    },
    {
      name: 'Mortgage Balance',
      value: property.mortgageBalance,
      formatter: currencyFormat,
    },
    {
      name: 'Estimated Value',
      value: property.estimatedValue,
      formatter: currencyFormat,
    },
    {
      name: 'Estimated Equity',
      value: property.estimatedEquity,
      formatter: currencyFormat,
    },
    {
      name: 'Owner 1',
      value: get(property, 'ownership.owner1FullName') || '-',
    },
    {
      name: 'Owner 2',
      value: get(property, 'ownership.owner2FullName') || '-',
    },
    {
      name: 'Linked Property Count',
      value: get(property, 'linkedProperties.totalOwned') || '-',
    },
  ]

  const strategyCards = [
    {
      name: 'Buy & Hold',
      label: 'Buy & Hold',
      percentage:
        (analytics?.buyHold?.onTarget +
          analytics?.buyHold?.aboveTarget) /
        (analytics?.buyHold?.belowTarget +
          analytics?.buyHold?.onTarget +
          analytics?.buyHold?.aboveTarget),
      page: 'BuyHold',
      afterRepairValue: analytics?.buyHold?.arvTotalBuyHold,
      analytics: [
        {
          value: analytics?.buyHold?.cashFlow / 12 || '-',
          arrow: analytics?.buyHold?.cashFlowArrow || 0,
          label: 'Net Cashflow',
          type: 'currency',
        },
        {
          value: analytics?.buyHold?.cashOnCashReturn || '-',
          arrow: analytics?.buyHold?.cashOnCashReturnArrow || 0,
          label: 'CoC ROI',
          type: 'percentage',
        },
        {
          value: analytics?.buyHold?.IRR || '-',
          arrow: analytics?.buyHold?.IRRArrow || 0,
          label: 'IRR',
          type: 'percentage',
        },
        {
          value: analytics?.buyHold?.capRate || '-',
          arrow: analytics?.buyHold?.capRateArrow || 0,
          label: 'Yield on Cost',
          type: 'percentage',
        },
      ],
    },
    {
      name: 'Fix & Flip',
      label: 'Flip Analysis',
      percentage:
        (analytics?.fixFlip?.onTargetFlip +
          analytics?.fixFlip?.aboveTargetFlip) /
        (analytics?.fixFlip?.belowTargetFlip +
          analytics?.fixFlip?.onTargetFlip +
          analytics?.fixFlip?.aboveTargetFlip),
      page: 'FixFlip',
      afterRepairValue: analytics?.fixFlip?.arvTotalFixFlip,
      analytics: [
        {
          value: analytics?.fixFlip?.saleProceeds || '-',
          arrow: analytics?.fixFlip?.saleProceedsArrow || 0,
          label: 'Net Profit',
          type: 'currency',
        },
        {
          value: analytics?.fixFlip?.netROIFixFlip || '-',
          arrow: analytics?.fixFlip?.netROIArrow || 0,
          label: 'Net ROI',
          type: 'percentage',
        },
        {
          value: analytics?.fixFlip?.percentageOfARV || '-',
          arrow: analytics?.fixFlip?.percentageOfARVArrow || 0,
          label: 'All-In Cost to ARV',
          type: 'percentage',
        },
      ],
    },
    {
      name: 'Turnkey End Buyer',
      label: 'Turnkey: End Buyer',
      percentage:
        (analytics?.turnkey?.onTargetTurnkey +
          analytics?.turnkey?.aboveTargetTurnkey) /
        (analytics?.turnkey?.belowTargetTurnkey +
          analytics?.turnkey?.onTargetTurnkey +
          analytics?.turnkey?.aboveTargetTurnkey),
      page: 'Turnkey',
      afterRepairValue: analytics?.turnkey?.arvTotalTurnkey,
      analytics: [
        {
          value: analytics?.turnkey?.cashFlowTurnkey / 12 || '-',
          arrow: analytics?.turnkey?.cashFlowTurnkeyArrow || 0,
          label: 'Net Cashflow',
          type: 'currency',
        },
        {
          value: analytics?.turnkey?.cashOnCashReturnTurnkey || '-',
          arrow:
            analytics?.turnkey?.cashOnCashReturnTurnkeyArrow || 0,
          label: 'CoC ROI',
          type: 'percentage',
        },
        {
          value: analytics?.turnkey?.capRateTurnkey || '-',
          arrow: analytics?.turnkey?.capRateTurnkeyArrow || 0,
          label: 'Cap Rate',
          type: 'percentage',
        },
        {
          value: analytics?.turnkey?.IRRTurnkey || '-',
          arrow: analytics?.turnkey?.IRRTurnkeyArrow || 0,
          label: 'IRR',
          type: 'percentage',
        },
      ],
    },
    {
      name: 'BRRRR',
      label: 'BRRRR',
      percentage:
        (analytics?.BRRRR?.onTargetBRRRR +
          analytics?.BRRRR?.aboveTargetBRRRR) /
        (analytics?.BRRRR?.belowTargetBRRRR +
          analytics?.BRRRR?.onTargetBRRRR +
          analytics?.BRRRR?.aboveTargetBRRRR),
      page: 'BRRRR',
      afterRepairValue: analytics?.BRRRR?.arvTotalBRRRR,
      analytics: [
        {
          value: analytics?.BRRRR?.cashFlowBRRRR / 12 || '-',
          arrow: analytics?.BRRRR?.cashFlowBRRRRArrow || 0,
          label: 'Net Cashflow',
          type: 'currency',
        },
        {
          value: analytics?.BRRRR?.cashOnCashReturnBRRRR || '-',
          arrow: analytics?.BRRRR?.cashOnCashReturnBRRRRArrow || 0,
          label: 'CoC ROI',
          type: 'percentage',
        },
        {
          value: analytics?.BRRRR?.IRRBRRRR || '-',
          arrow: analytics?.BRRRR?.IRRBRRRRArrow || 0,
          label: 'IRR',
          type: 'percentage',
        },
        {
          value: analytics?.BRRRR?.capRateBRRRR || '-',
          arrow: analytics?.BRRRR?.capRateBRRRRArrow || 0,
          label: 'Yield on Cost',
          type: 'percentage',
        },
      ],
    },
    {
      name: 'Short Term Rental',
      label: 'Short Term Rental',
      percentage:
        (analytics?.STR?.onTargetSTR +
          analytics?.STR?.aboveTargetSTR) /
        (analytics?.STR?.belowTargetSTR +
          analytics?.STR?.onTargetSTR +
          analytics?.STR?.aboveTargetSTR),
      page: 'ShortTermRental',
      afterRepairValue: analytics?.STR?.arvTotalSTR,
      analytics: [
        {
          value: analytics?.STR?.cashFlowSTR / 12 || '-',
          arrow: analytics?.STR?.cashFlowSTRArrow || 0,
          label: 'Net Cashflow',
          type: 'currency',
        },
        {
          value: analytics?.STR?.cashOnCashReturnSTR || '-',
          arrow: analytics?.STR?.cashOnCashReturnSTRArrow || 0,
          label: 'CoC ROI',
          type: 'percentage',
        },
        {
          value: analytics?.STR?.IRRSTR || '-',
          arrow: analytics?.STR?.IRRSTRArrow || 0,
          label: 'IRR',
          type: 'percentage',
        },
        {
          value: analytics?.STR?.capRateSTR || '-',
          arrow: analytics?.STR?.capRateSTRArrow || 0,
          label: 'Yield on Cost',
          type: 'percentage',
        },
      ],
    },
    {
      name: 'Wholesale',
      label: 'Wholesale',
      percentage:
        (analytics?.wholesale?.onTargetWholesale +
          analytics?.wholesale?.aboveTargetWholesale) /
        (analytics?.wholesale?.belowTargetWholesale +
          analytics?.wholesale?.onTargetWholesale +
          analytics?.wholesale?.aboveTargetWholesale),
      page: 'Wholesale',
      afterRepairValue: analytics?.wholesale?.arvTotalWholesale,
      analytics: [
        {
          value: analytics?.wholesale?.saleProceedsWholesale || '-',
          arrow:
            analytics?.wholesale?.saleProceedsWholesaleArrow || 0,
          label: 'Net Profit',
          type: 'currency',
        },
        {
          value: analytics?.wholesale?.buyerProfitTotal || '-',
          arrow: analytics?.wholesale?.buyerProfitArrow || 0,
          label: 'Buyer Profit',
          type: 'currency',
        },
      ],
    },
    {
      name: 'Build to Rent',
      label: 'Build to Rent',
      percentage:
        (analytics?.B2R?.onTargetB2R +
          analytics?.B2R?.aboveTargetB2R) /
        (analytics?.B2R?.belowTargetB2R +
          analytics?.B2R?.onTargetB2R +
          analytics?.B2R?.aboveTargetB2R),
      page: 'BuildToRent',
      afterRepairValue: analytics?.B2R?.arvTotalB2R,
      analytics: [
        {
          value: analytics?.B2R?.cashFlowB2R / 12 || '-',
          arrow: analytics?.B2R?.cashFlowB2RArrow || 0,
          label: 'Net Cashflow',
          type: 'currency',
        },
        {
          value: analytics?.B2R?.cashOnCashReturnB2R || '-',
          arrow: analytics?.B2R?.cashOnCashReturnB2RArrow || 0,
          label: 'CoC ROI',
          type: 'percentage',
        },
        {
          value: analytics?.B2R?.capRateB2R || '-',
          arrow: analytics?.B2R?.capRateB2RArrow || 0,
          label: 'Cap Rate',
          type: 'percentage',
        },
        {
          value: analytics?.B2R?.IRRB2R || '-',
          arrow: analytics?.B2R?.IRRArrowB2R || 0,
          label: 'IRR',
          type: 'percentage',
        },
      ],
    },
    {
      name: 'BOR',
      label: 'BOR',
      percentage:
        (analytics?.BOR?.onTargetBOR +
          analytics?.BOR?.aboveTargetBOR) /
        (analytics?.BOR?.belowTargetBOR +
          analytics?.BOR?.onTargetBOR +
          analytics?.BOR?.aboveTargetBOR),
      page: 'BOR',
      afterRepairValue: analytics?.BOR?.arvTotalBOR,
      analytics: [
        {
          value: analytics?.BOR?.averageAnnualReturnBOR || '-',
          arrow: analytics?.BOR?.averageAnnualReturnBORArrow || 0,
          label: 'CoC ROI',
          type: 'percentage',
        },
        {
          value: analytics?.BOR?.IRRBOR || '-',
          arrow: analytics?.BOR?.IRRBORArrow || 0,
          label: 'IRR',
          type: 'percentage',
        },
      ],
    },
    {
      name: 'Astroflip',
      label: 'Astroflip',
      percentage:
        (analytics?.astroFlip?.onTargetAstroFlip +
          analytics?.astroFlip?.aboveTargetAstroFlip) /
        (analytics?.astroFlip?.belowTargetAstroFlip +
          analytics?.astroFlip?.onTargetAstroFlip +
          analytics?.astroFlip?.aboveTargetAstroFlip),
      page: 'Astroflip',
      afterRepairValue: analytics?.astroFlip?.arvTotalAstroFlip,
      analytics: [
        {
          value: analytics?.astroFlip?.saleProceedsAstroFlip || '-',
          arrow:
            analytics?.astroFlip?.saleProceedsAstroFlipArrow || 0,
          label: 'Net Profit',
          type: 'currency',
        },
        {
          value:
            analytics?.astroFlip?.buyerProfitTotalAstroFlip || '-',
          arrow: analytics?.astroFlip?.buyerProfitAstroFlipArrow || 0,
          label: 'Buyer Profit',
          type: 'currency',
        },
      ],
    },
  ]

  function SliderArrowNext(props) {
    const { className, onClick } = props
    return (
      <div className={className} onClick={onClick}>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} />
      </div>
    )
  }

  function SliderArrowPrev(props) {
    const { className, onClick } = props
    return (
      <div className={className} onClick={onClick}>
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
      </div>
    )
  }

  const slider = useRef()
  const [sliderIndex, setSliderIndex] = useState(0)
  const widgetsCarousels1B = {
    dots: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
    afterChange: (currentSlide) => setSliderIndex(currentSlide),
  }

  // useEffect(() => {
  //   let index = findIndex(
  //     property.images,
  //     image => image.url === property.mainImage,
  //   )
  //   if (slider.current)
  //     slider.current.slickGoTo(index > 0 ? index : 0)
  // }, [property])

  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  // const switchMain = (index) => () => {
  //   updateProperty({
  //     refetchQueries: [
  //       {
  //         query: GET_PROPERTY,
  //         variables: { id: property._id }
  //       }
  //     ],
  //     variables: {
  //       id: property._id,
  //       propertyInput: { mainImage: property.images[index]._id },
  //     },
  //   }).then(data => {
  //     props.handleUpdateMarket()
  //   })
  // }

  const dateCreated = new Date(property.createdAt)
  const dateCreatedString = `${
    dateCreated.getMonth() + 1
  }/${dateCreated.getDate()}/${dateCreated.getFullYear()}`

  const tabSwitch = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const gorillaRehabDays = isNil(analysis.rehabDays)
    ? analysis.rehabCost / 500 + 55
    : analysis.rehabDays
  const gorillaFee =
    ((analysis.offerPrice === null
      ? analysis.listPrice
      : analysis.offerPrice) +
      analysis.rehabCost) *
    0.0005 *
    gorillaRehabDays
  const gorillaMinFee =
    (analysis.offerPrice === null
      ? analysis.listPrice
      : analysis.offerPrice) > 100000
      ? (analysis.offerPrice === null
          ? analysis.listPrice
          : analysis.offerPrice) * 0.06
      : 6000
  const gorillaFinalFee =
    gorillaFee > gorillaMinFee ? gorillaFee : gorillaMinFee
  const gorillaSplit =
    gorillaRehabDays <= 30
      ? 0.05
      : gorillaRehabDays <= 60
        ? 0.1
        : gorillaRehabDays <= 90
          ? 0.2
          : gorillaRehabDays <= 120
            ? 0.3
            : 0.4

  const plan =
    find(props.session.me.activeOrg.plans || [], {
      value: property.plan,
    }) || {}
  const planImages = plan.images || []

  return (
    <>
      <Grid container spacing={2} style={{ padding: 25 }}>
        <Grid item xs={12} sm={7} xl={8}>
          <Card className="card-box" style={{ height: '100%' }}>
            <div className="card-header d-flex align-items-center justify-content-between card-header-alt">
              <ButtonGroup variant="text">
                <Button
                  className={clsx(
                    'btn-outline-primary btn-transition-none',
                    { active: activeTab === 'Images' },
                  )}
                  onClick={() => {
                    tabSwitch('Images')
                  }}
                >
                  <ImageIcon style={{ paddingRight: 5 }} />
                  Images
                </Button>
                <Button
                  className={clsx(
                    'btn-outline-primary btn-transition-none',
                    { active: activeTab === 'Map' },
                  )}
                  onClick={() => {
                    tabSwitch('Map')
                  }}
                >
                  <RoomIcon style={{ paddingRight: 5 }} />
                  Map
                </Button>
                <Button
                  className={clsx(
                    'btn-outline-primary btn-transition-none',
                    { active: activeTab === 'Street' },
                  )}
                  onClick={() => {
                    tabSwitch('Street')
                  }}
                >
                  <DirectionsWalkIcon style={{ paddingRight: 5 }} />
                  Street
                </Button>
              </ButtonGroup>

              {hiddenDown ? null : (
                <>
                  {activeTab === 'Images' && (
                    <div>
                      <Tooltip title="Manage Images">
                        <Button
                          onClick={() => props.toggleCenter('Images')}
                          className="d-44 bg-neutral-first rounded"
                        >
                          <FontAwesomeIcon
                            icon={['far', 'image-polaroid']}
                            className="text-first font-size-lg"
                          />
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
            </div>
            {activeTab === 'Images' && (
              <div>
                <div>
                  <Slider
                    ref={slider}
                    className="card-img-top slider-dots"
                    {...widgetsCarousels1B}
                    style={{ maxWidth: 720 }}
                  >
                    {!isEmpty(
                      compact(concat(property.images, planImages)),
                    )
                      ? map(
                          compact(
                            concat(property.images, planImages),
                          ),
                          (image, index) => (
                            <div>
                              <Card
                                className="shadow-none"
                                style={{
                                  backgroundColor: 'white',
                                  margin: 1,
                                }}
                              >
                                <Grid
                                  container
                                  direction="column"
                                  justifyContent="center"
                                  style={{ height: 500 }}
                                >
                                  <Grid item>
                                    <div
                                      className="card-img-wrapper"
                                      style={{
                                        textAlign: 'center',
                                        backgroundColor:
                                          'transparent',
                                      }}
                                    >
                                      <div
                                        className="blur-load"
                                        style={{
                                          backgroundImage:
                                            'url(' +
                                            image.url_small +
                                            ')',
                                          height: 500,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <img
                                          src={image.url}
                                          className="card-img-top rounded"
                                          style={{
                                            maxHeight: 500,
                                            height: 'auto',
                                            borderBottomRightRadius:
                                              '0px',
                                            objectFit: 'fill',
                                          }}
                                          alt="..."
                                          loading="lazy"
                                        />
                                      </div>
                                    </div>
                                    {/* {!props.shared && !props.public ? image.url === property.mainImage ? (
                              <Chip
                                icon={<Home style={{ color: 'white' }} />}
                                label="Primary Image"
                                style={{
                                  position: 'absolute',
                                  bottom: 10,
                                  left: 10,
                                  color: 'white',
                                  fontWeight: 600,
                                  backgroundColor: '#ffaf00',
                                }}
                              />
                            ) : index < property.images.length ? (
                                <Chip
                                  icon={<Image />}
                                  label="Make Primary Image"
                                  onClick={switchMain(index)}
                                  style={{
                                    position: 'absolute',
                                    bottom: 10,
                                    left: 10,
                                  }}
                                />
                              ) : null : null} */}
                                  </Grid>
                                </Grid>
                              </Card>
                            </div>
                          ),
                        )
                      : null}
                    {compact(concat(property.images, planImages))
                      .length < 5 && (
                      <div>
                        <Card
                          className="shadow-none"
                          style={{ backgroundColor: 'white' }}
                        >
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            style={{ height: 500 }}
                          >
                            <Grid item>
                              <a
                                href={
                                  sliderIndex ===
                                  compact(
                                    concat(
                                      property.images,
                                      planImages,
                                    ),
                                  ).length
                                    ? props?.session?.me?.activeOrg
                                        ?.isRealeflow
                                      ? ''
                                      : `https://www.comehome.com/property-details/${replace(
                                          property.address,
                                          /\s/g,
                                          '-',
                                        )}-${replace(
                                          property.city,
                                          /\s/g,
                                          '-',
                                        )}-${property.state}-${
                                          property.zip
                                        }?modal=photo-list`
                                    : null
                                }
                                target="_blank"
                              >
                                <div
                                  onClick={(event) => {
                                    event.preventDefault()
                                    if (
                                      sliderIndex ===
                                      compact(
                                        concat(
                                          property.images,
                                          planImages,
                                        ),
                                      ).length
                                    )
                                      props?.session?.me?.activeOrg
                                        ?.isRealeflow
                                        ? ''
                                        : window.open(
                                            `https://www.comehome.com/property-details/${replace(
                                              property.address,
                                              /\s/g,
                                              '-',
                                            )}-${replace(
                                              property.city,
                                              /\s/g,
                                              '-',
                                            )}-${property.state}-${
                                              property.zip
                                            }?modal=photo-list`,
                                            '_blank',
                                          )
                                  }}
                                  className="card-img-wrapper"
                                  style={{
                                    height: '100%',
                                    textAlign: 'center',
                                    backgroundColor: 'transparent',
                                  }}
                                >
                                  <img
                                    src={
                                      props?.session?.me?.activeOrg
                                        ?.member === 'pacemorby' ||
                                      props?.session?.me?.activeOrg
                                        ?.member === 'astroflip'
                                        ? '/images/DealSauce-Logo-Tan.png'
                                        : props?.session?.me
                                              ?.activeOrg?.member ===
                                            'pep'
                                          ? '/images/pep-icon-only.png'
                                          : '/images/comehome_small.png'
                                    }
                                    className="card-img-top rounded"
                                    style={{
                                      maxHeight: 500,
                                      maxWidth: 500,
                                      height: 'auto',
                                      width: 'auto',
                                      borderBottomRightRadius: '0px',
                                      objectFit: 'contain',
                                    }}
                                    alt="..."
                                  />
                                </div>
                              </a>
                            </Grid>
                          </Grid>
                        </Card>
                      </div>
                    )}
                  </Slider>
                </div>

                {hiddenUp ? null : (
                  <div style={{ margin: 10 }}>
                    <Button
                      fullWidth
                      className="btn-neutral-first"
                      onClick={() => props.toggleCenter('Images')}
                    >
                      <ImageOutlinedIcon style={{ marginRight: 5 }} />
                      Manage Images
                    </Button>
                  </div>
                )}
              </div>
            )}
            {activeTab === 'Map' && (
              <Map
                properties={[property]}
                propertyPage
                streetView={false}
                setView={toggle}
              />
            )}
            {activeTab === 'Street' && (
              <Map
                properties={[property]}
                propertyPage
                streetView={true}
                setView={toggle}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} xl={4}>
          <CardSummary
            property={property}
            dateCreatedString={dateCreatedString}
            props={props}
            analytics={analytics}
            analysis={analysis}
            currencyFormat={currencyFormat}
            numberFormat={numberFormat}
            strategyCards={strategyCards}
            gorillaSplit={gorillaSplit}
            gorillaFinalFee={gorillaFinalFee}
            loading={loading}
            setLoading={setLoading}
            callPropertyExplorerReport={callPropertyExplorerReport}
            setOpenMemberDisclaimer={props.setOpenMemberDisclaimer}
            setOpenMember={props.setOpenMember}
            toggleLimit={props.toggleLimit}
            toggleCenter={props.toggleCenter}
            userId={props?.session?.me?._id}
          />
          {/* <Card style={{ width: '100%', borderRadius: 0 }}>
          <CardHeader
            title={<Typography variant="h6" style={{ fontWeight: 600 }}>Summary</Typography>}
            subheader={<Typography style={{ color: '#a5a8a9' }}>Added by {property.createdBy ? property.createdBy.profile.firstname + ' ' + property.createdBy.profile.lastname[0] : 'Unknown'} on {dateCreatedString}</Typography>}
            action={!props.public ?
              <Tooltip title="Edit Summary Details">
                <Button onClick={() => props.toggleCenter("Edit")} className="d-44 bg-neutral-first rounded">
                  <FontAwesomeIcon
                    icon={['far', 'check']}
                    className="text-first font-size-lg"
                  />
                </Button>
              </Tooltip> : null
            }
          />
          <Divider />
          <CardContent>
            <div className="font-size-sm py-3 rounded-sm">
              {map(summaryDetails, section => (
                section.name !== 'Status' ? (
                  <div className="d-flex justify-content-between py-2">
                    <span className="font-weight-bold" style={{ fontSize: 16 }}>
                      {section.name}:
                    </span>
                    <span className="text-black-50" style={{ fontSize: 16 }}>{section.formatter ? section.formatter.format(section.value) || '-' : section.value || '-'}</span>
                  </div>
                ) : (
                    <div className="d-flex justify-content-between py-2">
                      <span className="font-weight-bold" style={{ fontSize: 16 }}>
                        {section.name}:
                      </span>
                      <span className="badge badge-success" style={{ fontSize: 16 }}>{section.formatter || '-'}</span>
                    </div>
                  )
              ))}
              <div className="font-size-sm p-3 bg-secondary rounded-sm" style={{ marginLeft: -10, marginRight: -10 }}>
                <div className="d-flex justify-content-between">
                  <span className="font-weight-bold" style={{ fontSize: 16 }}>
                    ARV:
                  </span>
                  <span className="text-black-50" style={{ fontSize: 16 }}>{currencyFormat.format(analysis.afterRepairValue)}</span>
                </div>
                <div className="d-flex justify-content-between py-2">
                  <span className="font-weight-bold" style={{ fontSize: 16 }}>
                    Rent:
                  </span>
                  <span className="text-black-50" style={{ fontSize: 16 }}>{currencyFormat.format(analysis.rent)}</span>
                </div>
              </div>
            </div>
          </CardContent>
          {!props.public && (
            <CardActions>
              <Grid container>
                <Grid item xs={12} justifyContent="flex-end">
                  <ContactAgent property={property} propertySummary={true} />
                </Grid>
              </Grid>
            </CardActions>
          )}
        </Card> */}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ padding: 25, paddingTop: 0 }}
      >
        <Grid item xs={12}>
          <Grid container direction="row" spacing={2}>
            {map(
              filter(strategyCards, (card) =>
                !isEmpty(property.strategies)
                  ? includes(property.strategies, card.name)
                  : find(
                      get(props.preferences, 'general.strategies'),
                      { value: card.name, active: true },
                    ),
              ),
              (section, index) => (
                <Grid item xs={12} lg={4}>
                  <Card style={{ borderRadius: 0 }}>
                    <CardHeader
                      title={
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 600 }}
                        >
                          {getStrategyName(
                            section.label,
                            props?.session?.me?.activeOrg?.member,
                          )}
                        </Typography>
                      }
                      subheader={[
                        `According to your inputs, a ${getStrategyName(
                          section.label,
                          props?.session?.me?.activeOrg?.member,
                        )} strategy meets `,
                        <b
                          style={{
                            color:
                              section.percentage >= 0.66
                                ? '#1bc943'
                                : section.percentage >= 0.33
                                  ? '#f4772e'
                                  : '#f83245',
                          }}
                        >
                          {gaugeFormat.format(section.percentage)}
                        </b>,
                        ` of your targets`,
                      ]}
                      // action={
                      //   <IconButton>
                      //     <FontAwesomeIcon
                      //       icon={['far', 'chess-queen-piece']}
                      //     //className="font-size-md"
                      //     />
                      //   </IconButton>
                      // }
                    />
                    <CardContent>
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
                            {/* <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-2" /> */}
                            <div className="mx-auto">
                              <GaugeChart
                                hideText
                                id={'chartsGauges' + index}
                                nrOfLevels={6}
                                colors={[
                                  '#f83245',
                                  '#f4772e',
                                  '#1bc943',
                                ]}
                                arcWidth={0.3}
                                percent={section.percentage}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item className="gridItem2" xs={12}>
                          <Card
                            style={{ backgroundColor: '#eff2f5' }}
                          >
                            <CardContent style={{ padding: 10 }}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                                spacing={0}
                                wrap="nowrap"
                                style={{ padding: 0 }}
                              >
                                {map(section.analytics, (field) => (
                                  <Grid item className="gridItem2">
                                    <Grid
                                      container
                                      direction="column"
                                      alignItems="center"
                                    >
                                      <Grid item xs={12}>
                                        <Typography
                                          style={{
                                            whiteSpace: 'nowrap',
                                            fontSize: 13,
                                          }}
                                        >
                                          {field.label}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="center"
                                          alignItems="center"
                                          wrap="nowrap"
                                        >
                                          {!isUndefined(
                                            field.arrow,
                                          ) && (
                                            <Grid
                                              item
                                              className="gridItem2"
                                            >
                                              {field.arrow ? (
                                                field.arrow === 2 ? (
                                                  <Avatar
                                                    style={{
                                                      backgroundColor:
                                                        '#2296F3',
                                                      height: 15,
                                                      width: 15,
                                                    }}
                                                  >
                                                    <ArrowUpwardIcon
                                                      style={{
                                                        color:
                                                          'white',
                                                        fontSize:
                                                          'small',
                                                      }}
                                                    />
                                                  </Avatar>
                                                ) : (
                                                  <Avatar
                                                    style={{
                                                      backgroundColor:
                                                        '#46de64',
                                                      height: 15,
                                                      width: 15,
                                                    }}
                                                  >
                                                    <ArrowUpwardIcon
                                                      style={{
                                                        color:
                                                          'white',
                                                        fontSize:
                                                          'small',
                                                      }}
                                                    />
                                                  </Avatar>
                                                )
                                              ) : (
                                                <Avatar
                                                  style={{
                                                    backgroundColor:
                                                      '#F05248',
                                                    height: 15,
                                                    width: 15,
                                                  }}
                                                >
                                                  <ArrowDownwardIcon
                                                    style={{
                                                      color: 'white',
                                                      fontSize:
                                                        'small',
                                                    }}
                                                  />
                                                </Avatar>
                                              )}
                                            </Grid>
                                          )}
                                          <Grid
                                            item
                                            style={{ marginLeft: 5 }}
                                          >
                                            <Typography
                                              style={{
                                                fontSize: 12,
                                                fontWeight: 700,
                                              }}
                                            >
                                              {/* {field.value !== '-' ? field.type === 'currency' ? currencyFormat.format(field.value) : percentageFormat.format(field.value) : field.value} */}
                                              {field.value !== '-'
                                                ? field.type ===
                                                  'currency'
                                                  ? currencyFormat.format(
                                                      field.value,
                                                    )
                                                  : field.type ===
                                                        'percentage' &&
                                                      field.value >
                                                        1000
                                                    ? '+1000%'
                                                    : percentageFormat.format(
                                                        field.value,
                                                      )
                                                : field.value}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        style={{ marginBottom: '10px' }}
                        onClick={() =>
                          props.toggleCenter(section.page)
                        }
                      >
                        <Typography style={{ fontWeight: 600 }}>
                          View Full Analysis
                        </Typography>
                        <FontAwesomeIcon
                          icon={['far', 'arrow-right']}
                          className="font-size-lg"
                          style={{ marginLeft: 10 }}
                        />
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ),
            )}
            {/* EDIT STRATEGIES DIDN'T LOOK GREAT SO IT IS COMMENTED OUT. IT IS ALREADY IN THE LEFT NAV */}
            {/* <EditStrategies property={property} selected={[property._id]} editProperty /> */}
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ width: '100%' }}>
          <Card>
            <CardContent>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600, marginBottom: 10 }}
                  >
                    Description
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {property.description || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 15, marginBottom: 15 }} />
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600, marginBottom: 10 }}
                  >
                    Property Details
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    {map(propertyDetails1, (section) => (
                      <Grid item xs={3} style={{ marginBottom: 10 }}>
                        <Typography>
                          <span style={{ fontWeight: 600 }}>
                            {section.name}:
                          </span>{' '}
                          {section.formatter
                            ? section.formatter.format(section.value)
                            : section.value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                {!props.public &&
                  (!isEmpty(props.session.me.activeOrg.affiliate) ||
                    props?.session?.me?.activeOrg?.member) && (
                    <Grid item>
                      <Grid container direction="row">
                        {map(propertyDetails2, (section) => (
                          <Grid
                            item
                            xs={3}
                            style={{ marginBottom: 10 }}
                          >
                            <Typography>
                              <span style={{ fontWeight: 600 }}>
                                {section.name}:
                              </span>{' '}
                              {section.formatter
                                ? section.formatter.format(
                                    section.value,
                                  )
                                : section.value}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                {props.session.me.activeOrg.isRealeflow &&
                  props.session.me.activeOrg.member === 'pep' && (
                    <Grid item>
                      <Grid container direction="row">
                        {map(propertyDetailsPEP, (section) => (
                          <Grid
                            item
                            xs={3}
                            style={{ marginBottom: 10 }}
                          >
                            <Typography>
                              <span style={{ fontWeight: 600 }}>
                                {section.name}:
                              </span>{' '}
                              {section.formatter
                                ? section.formatter.format(
                                    section.value,
                                  )
                                : section.value}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid container direction="row">
                        <SkipTrace properties={[property]} />
                        {!isEmpty(property.skipTraceResults) && (
                          <>
                            <Grid item xs={3}>
                              <Typography>
                                <span style={{ fontWeight: 600 }}>
                                  Contact Phone:
                                </span>{' '}
                                {get(
                                  property,
                                  'skipTraceResults.identity.phones.0.phoneDisplay',
                                )}{' '}
                                ({' '}
                                {get(
                                  property,
                                  'skipTraceResults.identity.phones.0.phoneType',
                                )}{' '}
                                )
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography>
                                <span style={{ fontWeight: 600 }}>
                                  Contact Email:
                                </span>{' '}
                                {get(
                                  property,
                                  'skipTraceResults.identity.emails.0.email',
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography>
                                <span style={{ fontWeight: 600 }}>
                                  Contact Name:
                                </span>{' '}
                                {get(
                                  property,
                                  'skipTraceResults.identity.names.0.fullName',
                                )}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default withSession(CenterPageSummary)
