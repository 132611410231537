import Reactour from 'reactour'
import { Button, Typography } from '@mui/material'
import { includes } from 'lodash'
import { useLocation } from 'react-router-dom'

export const VerifyFinancialsTour = 
  ({
    isOpenVerifyFinancialsTour,
    closeVerifyFinancialsTour,
  }) => {
    const { pathname } = useLocation()
    let goToTo
    let shifted = false
    const lockTour = !includes(pathname, '/')
    const steps = [
      {
        selector: '[data-tour="verifyFinancials-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Click on any of the following strategies and you can
                dial in your financials.{' '}
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                goToTo(1)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="verifyFinancials-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Click the plus icon if you don't see the strategy the
                fits your end goal and add it.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                goToTo(1)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenVerifyFinancialsTour}
        onRequestClose={closeVerifyFinancialsTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }
