/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-01 15:08:58
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-03-20 14:37:38
 * @ Description: Card with details about the property used for the card view on the portfolio, marketplace, and property search pages
 */

import React, { useState, useEffect, useRef } from 'react'
import {
  ContextMenu,
  MenuItem,
  ContextMenuTrigger,
} from 'react-contextmenu'

import DeleteProperty from '../DeleteProperty'
import DuplicateProperty from '../DuplicateProperty'
import Analyze from '@/housefolios-components/Marketplace/Analyze'

import moment from 'moment'

import Slider from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ExportPortfolio from '../../Portfolio/ExportPortfolio'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import isUndefined from 'lodash/isUndefined'
import includes from 'lodash/includes'
import findIndex from 'lodash/findIndex'
import slice from 'lodash/slice'
import cloneDeep from 'lodash/cloneDeep'
import assign from 'lodash/assign'
import get from 'lodash/get'
import set from 'lodash/set'
import sum from 'lodash/sum'
import startsWith from 'lodash/startsWith'
import find from 'lodash/find'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import merge from 'lodash/merge'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isNil from 'lodash/isNil'
import replace from 'lodash/replace'
import filter from 'lodash/filter'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import ContactAgent from '@/housefolios-components/Marketplace/ContactAgent'
import withSession from '@/housefolios-components/Session/withSession'
import { useMutation } from '@apollo/client'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { Close } from '@mui/icons-material'
import TrashProperty from '../TrashProperty'
import RestoreProperty from '../RestoreProperty'
import { GET_ME } from '@/housefolios-components/Session/queries'
import ShareProperty from '../ShareProperty'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import CriticalFields from '@/housefolios-components/Dialogs/CriticalFields'
import SubmissionApproval from '@/housefolios-components/Marketplace/SubmissionApproval'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import { getProformaLink } from '@/utils/proforma'

import {
  currencyFormat,
  numberFormat,
  percentageFormat,
} from '@/utils/number'
import SubtoPITICash from '@/housefolios-components/Dialogs/SubtoPITI/Cash/'
import SubtoPITICreative from '@/housefolios-components/Dialogs/SubtoPITI/Creative/'

import PropertyInformationDialogs from '@/housefolios-components/Dialogs/PropertyInformationDialogs'

function SliderArrowNext(props) {
  const { className, onClick } = props
  return (
    <div
      className={className}
      onClick={(event) => {
        onClick(event)
        event.stopPropagation()
      }}
    >
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  )
}

function SliderArrowPrev(props) {
  const { className, onClick } = props
  return (
    <div
      className={className}
      onClick={(event) => {
        onClick(event)
        event.stopPropagation()
      }}
    >
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  )
}

function PropertyCardComponent(props) {
  const { property, propertySearch } = props
  const hostname = import.meta.env.VITE_HOSTNAME
  const attributes = {
    className: 'nav-item my-1',
  }
  const slider = useRef()
  const slider1 = useRef()
  const widgetsCarousels1B = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
  }

  const [showImages, setShowImages] = useState(false)
  const [images, setImages] = useState([])

  const toggleImages =
    (images = []) =>
    (event) => {
      event.stopPropagation()
      setShowImages(!showImages)
      setImages(images)
    }

  const [heart, setHeart] = useState(property.favorite)

  useEffect(() => {
    // let index = findIndex(
    //   property.images,
    //   image => image.url === property.mainImage,
    // )
    // slider.current.slickGoTo(index > 0 ? index : 0)
    setHeart(property.favorite)
  }, [property])

  const [openCriticalFields, setOpenCriticalFields] = useState(false)
  const [redirectToEdit, setRedirectToEdit] = useState(false)

  const handleOpenCriticalFields = (directToEdit = false) => {
    setOpenCriticalFields(true)
    setRedirectToEdit(directToEdit)
  }

  const handleCloseCriticalFields = () => {
    setOpenCriticalFields(false)
  }

  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  const [openAccept, setOpenAccept] = useState(false)
  const [openReject, setOpenReject] = useState(false)

  const [openPITICashData, setOpenPITICashData] = useState(false)
  const [openPITICreativeData, setOpenPITICreativeData] =
    useState(false)

  const phpSend = (remove = false) => {
    if (remove) {
      let data = {
        _id: property._id,
        remove_property: remove,
      }

      const str_json = JSON.stringify(data)

      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    } else {
      let data = cloneDeep(property)
      let expRelist = { expRelist: true }
      let activeMP = []
      data.beds = sum(data.beds)
      data.baths = sum(data.baths)
      data.sqft = sum(data.sqft)
      if (get(data, 'acquisition.analysis')) {
        map(data.acquisition.analysis, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `acquisition.analysis.${key}`,
              sum(get(data, `acquisition.analysis.${key}`)),
            )
          }
        })
      }
      if (get(data, 'assumptions')) {
        map(data.assumptions, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `assumptions.${key}`,
              sum(get(data, `assumptions.${key}`)),
            )
          }
        })
      }
      if (get(data, 'acquisition.analytics')) {
        map(data.acquisition.analytics, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `acquisition.analytics.${key}`,
              sum(get(data, `acquisition.analytics.${key}`)),
            )
          } else if (isObject(value)) {
            map(value, (secondKey, value) => {
              if (isArray(value)) {
                set(
                  data,
                  `acquisition.analytics.${key}.${secondKey}`,
                  sum(
                    get(
                      data,
                      `acquisition.analytics.${key}.${secondKey}`,
                    ),
                  ),
                )
              }
            })
          }
        })
      }
      const { proformaType } = property.marketplaces
      const proformaLink = getProformaLink(data._id, [proformaType])

      data.proformaURL = proformaLink
      if (
        property.marketplaces &&
        !isEmpty(property.marketplaces.approvedMarketplace)
      ) {
        activeMP.push({
          marketplaces: {
            marketplaceName: property.marketplaces.marketplaceName,
            approvedMarketplace:
              property.marketplaces.approvedMarketplace,
            expirationDate: new Date(
              Date.now() + 7 * 24 * 60 * 60 * 1000,
            ),
            extraTags: property.marketplaces.extraTags,
            proformaType: property.marketplaces.proformaType,
          },
        })

        data = assign(data, activeMP[0], expRelist)
      }

      const str_json = JSON.stringify(data)
      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    }
  }

  const marketplaceOwned =
    (isNil(get(property, 'portfolio.organization._id')) &&
      !props.propertySearch) ||
    get(property, 'portfolio.organization._id') ===
      props.session.me.activeOrg._id ||
    find(
      concat(
        props.portfolios,
        filter(
          props.session.me.activeOrg.sharedPortfolios,
          (portfolio) => portfolio.sharePermission === 'canEdit',
        ),
      ),
      (portfolio) => portfolio._id === get(property, 'portfolio._id'),
    ) ||
    find(
      filter(
        props.session.me.activeOrg.sharedProperties,
        (property) => property.sharePermission === 'canEdit',
      ),
      (sharedProperty) => sharedProperty.id === property._id,
    )
  const owned = property.owned && marketplaceOwned

  const pricePerSqft = property?.sqft
    ? Number(
        property.price ||
          get(property, 'acquisition.analysis.resellPrice'),
      ) / property.sqft || 0
    : null

  let createdAt = new Date(property.createdAt)
  let createdAtYear = createdAt.getFullYear()
  let createdAtMonth = createdAt.getMonth() + 1
  let createdAtDay = createdAt.getDate()
  createdAt =
    createdAtMonth + '/' + createdAtDay + '/' + createdAtYear

  const today = new Date()
  let endDate = new Date(property.deletedAt)
  endDate.setDate(endDate.getDate() + 7)
  const daysUntilDeleted = moment
    .duration(moment(endDate).diff(today))
    .asDays()
    .toFixed(0)

  let listDate =
    !props.trash && !props.propertySearch && props.marketplace
      ? new Date(property.marketplaces.expirationDate)
      : ''
  if (listDate) {
    listDate.setDate(listDate.getDate() - 7)
    let listDateYear = listDate.getFullYear()
    let listDateMonth = listDate.getMonth() + 1
    let listDateDay = listDate.getDate()
    listDate = listDateMonth + '/' + listDateDay + '/' + listDateYear
  }

  let realeflowSoldDate =
    get(property, 'realeflowData.SoldDateText') ||
    get(property, 'realeflowData.SubjectProperty.SoldDateText') ||
    get(property, 'realeflowData.LastSalesDate')

  // const analytics = props?.session?.me?.activeOrg?.member === 'RWN' ? [
  //   {
  //     value: property.acquisition.analysis ? property.acquisition.analysis.resellPrice * 0.20 : '-',
  //     label: 'Down Payment',
  //     type: 'currency'
  //   },
  //   {
  //     value: property.acquisition.analysis ? property.acquisition.analysis.rent : '-',
  //     label: 'Est Rental Income',
  //     type: 'currency'
  //   },
  //   {
  //     value: property.acquisition.analytics ? property.acquisition.analytics.buyHold.cashFlow > 0 ? property.acquisition.analytics.buyHold.cashFlow / 12 : '-' : '-',
  //     label: 'Est. Cashflow',
  //     type: 'currency'
  //   },
  // ] : props.propertySearch && !(property.marketplaces && !isEmpty(property.marketplaces.approvedMarketplace)) ? [] : (property.strategy === 'Fix & Flip' && !props.marketplace) || (property.marketplaceStrategy === 'Fix & Flip' && props.marketplace) ? [
  //   {
  //     value: property.acquisition.analytics ? (property.strategy === 'Turnkey End Buyer' && !props.marketplace) || (property.marketplaceStrategy === 'Turnkey End Buyer' && props.marketplace) ? property.acquisition.analytics.turnkey.netROITurnkey : property.acquisition.analytics.fixFlip.netROIFixFlip : '-',
  //     arrow: property.acquisition.analytics ? (property.strategy === 'Turnkey End Buyer' && !props.marketplace) || (property.marketplaceStrategy === 'Turnkey End Buyer' && props.marketplace) ? 0 : property.acquisition.analytics.fixFlip.netROIArrow : 0,
  //     label: 'Net ROI',
  //     type: 'percent'
  //   },
  //   {
  //     value: property.acquisition.analytics ? property.acquisition.analytics.fixFlip.saleProceeds : '-',
  //     arrow: property.acquisition.analytics ? property.acquisition.analytics.fixFlip.saleProceedsArrow : 0,
  //     label: 'Net Profit',
  //     type: 'currency'
  //   },
  //   {
  //     value: property.acquisition.analytics ? (property.strategy === 'Turnkey End Buyer' && !props.marketplace) || (property.marketplaceStrategy === 'Turnkey End Buyer' && props.marketplace) ? property.acquisition.analytics.turnkey.allInCostTurnkey > 0 ? property.acquisition.analytics.turnkey.allInCostTurnkey : '-' : property.acquisition.analytics.buyHold.allInCost > 0 ? property.acquisition.analytics.buyHold.allInCost : '-' : '-',
  //     label: 'All in Cost',
  //     type: 'currency'
  //   },
  //   {
  //     value: property.acquisition.analysis ? property.acquisition.analysis.afterRepairValue : '-',
  //     label: 'ARV',
  //     type: 'currency'
  //   },
  // ] : [
  //   {
  //     value: property.acquisition.analytics ? (property.strategy === 'Turnkey End Buyer' && !props.marketplace) || (property.marketplaceStrategy === 'Turnkey End Buyer' && props.marketplace) ? property.acquisition.analytics.turnkey.IRRTurnkey : property.acquisition.analytics.buyHold.IRR : '-',
  //     arrow: property.acquisition.analytics ? (property.strategy === 'Turnkey End Buyer' && !props.marketplace) || (property.marketplaceStrategy === 'Turnkey End Buyer' && props.marketplace) ? property.acquisition.analytics.turnkey.IRRTurnkeyArrow : property.acquisition.analytics.buyHold.IRRArrow : 0,
  //     label: 'IRR',
  //     type: 'percent',
  //   },
  //   {
  //     value: property.acquisition.analytics ? (property.strategy === 'Turnkey End Buyer' && !props.marketplace) || (property.marketplaceStrategy === 'Turnkey End Buyer' && props.marketplace) ? property.acquisition.analytics.turnkey.capRateTurnkey : property.acquisition.analytics.buyHold.capRate : '-',
  //     arrow: property.acquisition.analytics ? (property.strategy === 'Turnkey End Buyer' && !props.marketplace) || (property.marketplaceStrategy === 'Turnkey End Buyer' && props.marketplace) ? property.acquisition.analytics.turnkey.capRateTurnkeyArrow : property.acquisition.analytics.buyHold.capRateArrow : 0,
  //     label: (property.strategy === 'Turnkey End Buyer' && !props.marketplace) || (property.marketplaceStrategy === 'Turnkey End Buyer' && props.marketplace) ? 'Cap Rate' : 'Yield-on-Cost',
  //     type: 'percent',
  //   },
  //   {
  //     value: property.acquisition.analytics ? (property.strategy === 'Turnkey End Buyer' && !props.marketplace) || (property.marketplaceStrategy === 'Turnkey End Buyer' && props.marketplace) ? property.acquisition.analytics.turnkey.cashOnCashReturnTurnkey : property.acquisition.analytics.buyHold.cashOnCashReturn : '-',
  //     arrow: property.acquisition.analytics ? (property.strategy === 'Turnkey End Buyer' && !props.marketplace) || (property.marketplaceStrategy === 'Turnkey End Buyer' && props.marketplace) ? property.acquisition.analytics.turnkey.cashOnCashReturnTurnkeyArrow : property.acquisition.analytics.buyHold.cashOnCashReturnArrow : '-',
  //     label: 'Cash on Cash',
  //     type: 'percent',
  //   },
  //   {
  //     value: property.acquisition.analytics ? (property.strategy === 'Turnkey End Buyer' && !props.marketplace) || (property.marketplaceStrategy === 'Turnkey End Buyer' && props.marketplace) ? property.acquisition.analytics.turnkey.equityTurnkey > 0 ? property.acquisition.analytics.turnkey.equityTurnkey : '-' : property.acquisition.analytics.buyHold.equity> 0 ? property.acquisition.analytics.buyHold.equity : '-'  : '-',
  //     label: 'Equity',
  //     type: 'currency'
  //   },
  // ]

  const analytics =
    props.propertySearch &&
    !(
      property.marketplaces &&
      !isEmpty(property.marketplaces.approvedMarketplace)
    )
      ? []
      : (property.strategy === 'Build to Rent' &&
            !props.marketplace) ||
          (property.marketplaceStrategy === 'Build to Rent' &&
            props.marketplace)
        ? [
            {
              value:
                property.acquisition &&
                property.acquisition.analytics &&
                property.acquisition.analytics.B2R
                  ? property.acquisition.analytics.B2R.cashFlowB2R /
                    12
                  : '-',
              arrow:
                property.acquisition &&
                property.acquisition.analytics &&
                property.acquisition.analytics.B2R
                  ? property.acquisition.analytics.B2R
                      .cashFlowB2RArrow
                  : 0,
              label: 'Net Cashflow',
              type: 'currency',
            },
            {
              value:
                property.acquisition &&
                property.acquisition.analytics &&
                property.acquisition.analytics.B2R
                  ? property.acquisition.analytics.B2R
                      .cashOnCashReturnB2R
                  : '-',
              arrow:
                property.acquisition &&
                property.acquisition.analytics &&
                property.acquisition.analytics.B2R
                  ? property.acquisition.analytics.B2R
                      .cashOnCashReturnB2RArrow
                  : 0,
              label: 'CoC ROI',
              type: 'percent',
            },
            {
              value:
                property.acquisition &&
                property.acquisition.analytics &&
                property.acquisition.analytics.B2R
                  ? property.acquisition.analytics.B2R.IRRB2R
                  : '-',
              arrow:
                property.acquisition &&
                property.acquisition.analytics &&
                property.acquisition.analytics.B2R
                  ? property.acquisition.analytics.B2R.IRRB2RArrow
                  : 0,
              label: 'IRR',
              type: 'percent',
            },
            {
              value:
                property.acquisition &&
                property.acquisition.analytics &&
                property.acquisition.analytics.B2R
                  ? property.acquisition.analytics.B2R.capRateB2R
                  : '-',
              arrow:
                property.acquisition &&
                property.acquisition.analytics &&
                property.acquisition.analytics.B2R
                  ? property.acquisition.analytics.B2R.capRateB2RArrow
                  : 0,
              label: 'Yield-on-Cost',
              type: 'percent',
            },
          ]
        : (property.strategy === 'Wholesale' && !props.marketplace) ||
            (property.marketplaceStrategy === 'Wholesale' &&
              props.marketplace)
          ? [
              {
                value:
                  property.acquisition &&
                  property.acquisition.analytics &&
                  property.acquisition.analytics.wholesale
                    ? property.acquisition.analytics.wholesale
                        .saleProceedsWholesale
                    : '-',
                arrow:
                  property.acquisition &&
                  property.acquisition.analytics &&
                  property.acquisition.analytics.wholesale
                    ? property.acquisition.analytics.wholesale
                        .saleProceedsWholesaleArrow
                    : 0,
                label: 'Net Profit',
                type: 'currency',
              },
              {
                value:
                  property.acquisition &&
                  property.acquisition.analytics &&
                  property.acquisition.analytics.wholesale
                    ? property.acquisition.analytics.wholesale
                        .buyerProfitTotal
                    : '-',
                arrow:
                  property.acquisition &&
                  property.acquisition.analytics &&
                  property.acquisition.analytics.wholesale
                    ? property.acquisition.analytics.wholesale
                        .buyerProfitArrow
                    : 0,
                label: 'Buyer Profit',
                type: 'currency',
              },
            ]
          : (property.strategy === 'Astroflip' &&
                !props.marketplace) ||
              (property.marketplaceStrategy === 'Astroflip' &&
                props.marketplace)
            ? [
                {
                  value:
                    property.acquisition &&
                    property.acquisition.analytics &&
                    property.acquisition.analytics.astroFlip
                      ? property.acquisition.analytics.astroFlip
                          ?.saleProceedsAstroFlip
                      : '-',
                  arrow:
                    property.acquisition &&
                    property.acquisition.analytics &&
                    property.acquisition.analytics.astroFlip
                      ? property.acquisition.analytics.astroFlip
                          ?.saleProceedsAstroFlipArrow
                      : 0,
                  label: 'Net Profit',
                  type: 'currency',
                },
                {
                  value:
                    property.acquisition &&
                    property.acquisition.analytics &&
                    property.acquisition.analytics.astroFlip
                      ? property.acquisition.analytics.astroFlip
                          .buyerProfitTotalAstroFlip
                      : '-',
                  arrow:
                    property.acquisition &&
                    property.acquisition.analytics &&
                    property.acquisition.analytics.astroFlip
                      ? property.acquisition.analytics.astroFlip
                          .buyerProfitAstroFlipArrow
                      : 0,
                  label: 'Buyer Profit',
                  type: 'currency',
                },
              ]
            : (property.strategy === 'BOR' && !props.marketplace) ||
                (property.marketplaceStrategy === 'BOR' &&
                  props.marketplace)
              ? [
                  {
                    value:
                      property.acquisition &&
                      property.acquisition.analytics &&
                      property.acquisition.analytics.BOR
                        ? property.acquisition.analytics.BOR
                            .averageAnnualReturnBOR
                        : '-',
                    arrow:
                      property.acquisition &&
                      property.acquisition.analytics &&
                      property.acquisition.analytics.BOR
                        ? property.acquisition.analytics.BOR
                            .averageAnnualReturnBORArrow
                        : 0,
                    label: 'Average Annual Return',
                    type: 'percent',
                  },
                  {
                    value:
                      property.acquisition &&
                      property.acquisition.analytics &&
                      property.acquisition.analytics.BOR
                        ? property.acquisition.analytics.BOR.IRRBOR
                        : '-',
                    arrow:
                      property.acquisition &&
                      property.acquisition.analytics &&
                      property.acquisition.analytics.BOR
                        ? property.acquisition.analytics.BOR
                            .IRRBORArrow
                        : 0,
                    label: 'IRR',
                    type: 'percent',
                  },
                ]
              : (property.strategy === 'Short Term Rental' &&
                    !props.marketplace) ||
                  (property.marketplaceStrategy ===
                    'Short Term Rental' &&
                    props.marketplace)
                ? [
                    {
                      value:
                        property.acquisition &&
                        property.acquisition.analytics &&
                        property.acquisition.analytics.STR
                          ? property.acquisition.analytics.STR
                              .cashFlowSTR / 12
                          : '-',
                      arrow:
                        property.acquisition &&
                        property.acquisition.analytics &&
                        property.acquisition.analytics.STR
                          ? property.acquisition.analytics.STR
                              .cashFlowSTRArrow
                          : 0,
                      label: 'Net Cashflow',
                      type: 'currency',
                    },
                    {
                      value:
                        property.acquisition &&
                        property.acquisition.analytics &&
                        property.acquisition.analytics.STR
                          ? property.acquisition.analytics.STR
                              .cashOnCashReturnSTR
                          : '-',
                      arrow:
                        property.acquisition &&
                        property.acquisition.analytics &&
                        property.acquisition.analytics.STR
                          ? property.acquisition.analytics.STR
                              .cashOnCashReturnSTRArrow
                          : 0,
                      label: 'CoC ROI',
                      type: 'percent',
                    },
                    {
                      value:
                        property.acquisition &&
                        property.acquisition.analytics &&
                        property.acquisition.analytics.STR
                          ? property.acquisition.analytics.STR.IRRSTR
                          : '-',
                      arrow:
                        property.acquisition &&
                        property.acquisition.analytics &&
                        property.acquisition.analytics.STR
                          ? property.acquisition.analytics.STR
                              .IRRSTRArrow
                          : 0,
                      label: 'IRR',
                      type: 'percent',
                    },
                    {
                      value:
                        property.acquisition &&
                        property.acquisition.analytics &&
                        property.acquisition.analytics.STR
                          ? property.acquisition.analytics.STR
                              .capRateSTR
                          : '-',
                      arrow:
                        property.acquisition &&
                        property.acquisition.analytics &&
                        property.acquisition.analytics.STR
                          ? property.acquisition.analytics.STR
                              .capRateSTRArrow
                          : 0,
                      label: 'Yield-on-Cost',
                      type: 'percent',
                    },
                  ]
                : (property.strategy === 'BRRRR' &&
                      !props.marketplace) ||
                    (property.marketplaceStrategy === 'BRRRR' &&
                      props.marketplace)
                  ? [
                      {
                        value:
                          property.acquisition &&
                          property.acquisition.analytics &&
                          property.acquisition.analytics.BRRRR
                            ? property.acquisition.analytics.BRRRR
                                .cashFlowBRRRR / 12
                            : '-',
                        arrow:
                          property.acquisition &&
                          property.acquisition.analytics &&
                          property.acquisition.analytics.BRRRR
                            ? property.acquisition.analytics.BRRRR
                                .cashFlowBRRRRArrow
                            : 0,
                        label: 'Net Cashflow',
                        type: 'currency',
                      },
                      {
                        value:
                          property.acquisition &&
                          property.acquisition.analytics &&
                          property.acquisition.analytics.BRRRR
                            ? property.acquisition.analytics.BRRRR
                                .cashOnCashReturnBRRRR
                            : '-',
                        arrow:
                          property.acquisition &&
                          property.acquisition.analytics &&
                          property.acquisition.analytics.BRRRR
                            ? property.acquisition.analytics.BRRRR
                                .cashOnCashReturnBRRRRArrow
                            : 0,
                        label: 'CoC ROI',
                        type: 'percent',
                      },
                      {
                        value:
                          property.acquisition &&
                          property.acquisition.analytics &&
                          property.acquisition.analytics.BRRRR
                            ? property.acquisition.analytics.BRRRR
                                .IRRBRRRR
                            : '-',
                        arrow:
                          property.acquisition &&
                          property.acquisition.analytics &&
                          property.acquisition.analytics.BRRRR
                            ? property.acquisition.analytics.BRRRR
                                .IRRBRRRRArrow
                            : 0,
                        label: 'IRR',
                        type: 'percent',
                      },
                      {
                        value:
                          property.acquisition &&
                          property.acquisition.analytics &&
                          property.acquisition.analytics.BRRRR
                            ? property.acquisition.analytics.BRRRR
                                .capRateBRRRR
                            : '-',
                        arrow:
                          property.acquisition &&
                          property.acquisition.analytics &&
                          property.acquisition.analytics.BRRRR
                            ? property.acquisition.analytics.BRRRR
                                .capRateBRRRRArrow
                            : 0,
                        label: 'Yield-on-Cost',
                        type: 'percent',
                      },
                    ]
                  : (property.strategy === 'Turnkey End Buyer' &&
                        !props.marketplace) ||
                      (property.marketplaceStrategy ===
                        'Turnkey End Buyer' &&
                        props.marketplace)
                    ? [
                        {
                          value:
                            property.acquisition &&
                            property.acquisition.analytics &&
                            property.acquisition.analytics.turnkey
                              ? property.acquisition.analytics.turnkey
                                  .cashFlowTurnkey / 12
                              : '-',
                          arrow:
                            property.acquisition &&
                            property.acquisition.analytics &&
                            property.acquisition.analytics.turnkey
                              ? property.acquisition.analytics.turnkey
                                  .cashFlowTurnkeyArrow
                              : 0,
                          label: 'Net Cashflow',
                          type: 'currency',
                        },
                        {
                          value:
                            property.acquisition &&
                            property.acquisition.analytics &&
                            property.acquisition.analytics.turnkey
                              ? property.acquisition.analytics.turnkey
                                  .cashOnCashReturnTurnkey
                              : '-',
                          arrow:
                            property.acquisition &&
                            property.acquisition.analytics &&
                            property.acquisition.analytics.turnkey
                              ? property.acquisition.analytics.turnkey
                                  .cashOnCashReturnTurnkeyArrow
                              : 0,
                          label: 'CoC ROI',
                          type: 'percent',
                        },
                        {
                          value:
                            property.acquisition &&
                            property.acquisition.analytics &&
                            property.acquisition.analytics.turnkey
                              ? property.acquisition.analytics.turnkey
                                  .capRateTurnkey
                              : '-',
                          arrow:
                            property.acquisition &&
                            property.acquisition.analytics &&
                            property.acquisition.analytics.turnkey
                              ? property.acquisition.analytics.turnkey
                                  .capRateTurnkeyArrow
                              : 0,
                          label: 'Cap Rate',
                          type: 'percent',
                        },
                        {
                          value:
                            property.acquisition &&
                            property.acquisition.analytics &&
                            property.acquisition.analytics.turnkey
                              ? property.acquisition.analytics.turnkey
                                  .IRRTurnkey
                              : '-',
                          arrow:
                            property.acquisition &&
                            property.acquisition.analytics &&
                            property.acquisition.analytics.turnkey
                              ? property.acquisition.analytics.turnkey
                                  .IRRTurnkeyArrow
                              : 0,
                          label: 'IRR',
                          type: 'percent',
                        },
                      ]
                    : (property.strategy === 'Fix & Flip' &&
                          !props.marketplace) ||
                        (property.marketplaceStrategy ===
                          'Fix & Flip' &&
                          props.marketplace)
                      ? [
                          {
                            value:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.fixFlip
                                ? property.acquisition.analytics
                                    .fixFlip.saleProceeds
                                : '-',
                            arrow:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.fixFlip
                                ? property.acquisition.analytics
                                    .fixFlip.saleProceedsArrow
                                : 0,
                            label: 'Net Profit',
                            type: 'currency',
                          },
                          {
                            value:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.fixFlip
                                ? property.acquisition.analytics
                                    .fixFlip.netROIFixFlip
                                : '-',
                            arrow:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.fixFlip
                                ? property.acquisition.analytics
                                    .fixFlip.netROIArrow
                                : 0,
                            label: 'Net ROI',
                            type: 'percent',
                          },
                          {
                            value:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.fixFlip
                                ? property.acquisition.analytics
                                    .fixFlip.percentageOfARV
                                : '-',
                            arrow:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.fixFlip
                                ? property.acquisition.analytics
                                    .fixFlip.percentageOfARVArrow
                                : 0,
                            label: 'All-In Cost to ARV',
                            type: 'percent',
                          },
                        ]
                      : [
                          {
                            value:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.buyHold
                                ? property.acquisition.analytics
                                    .buyHold.cashFlow / 12
                                : '-',
                            arrow:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.buyHold
                                ? property.acquisition.analytics
                                    .buyHold.cashFlowArrow
                                : 0,
                            label: 'Net Cashflow',
                            type: 'currency',
                          },
                          {
                            value:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.buyHold
                                ? property.acquisition.analytics
                                    .buyHold.cashOnCashReturn
                                : '-',
                            arrow:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.buyHold
                                ? property.acquisition.analytics
                                    .buyHold.cashOnCashReturnArrow
                                : 0,
                            label: 'CoC ROI',
                            type: 'percent',
                          },
                          {
                            value:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.buyHold
                                ? property.acquisition.analytics
                                    .buyHold.IRR
                                : '-',
                            arrow:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.buyHold
                                ? property.acquisition.analytics
                                    .buyHold.IRRArrow
                                : 0,
                            label: 'IRR',
                            type: 'percent',
                          },
                          {
                            value:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.buyHold
                                ? property.acquisition.analytics
                                    .buyHold.capRate
                                : '-',
                            arrow:
                              property.acquisition &&
                              property.acquisition.analytics &&
                              property.acquisition.analytics.buyHold
                                ? property.acquisition.analytics
                                    .buyHold.capRateArrow
                                : 0,
                            label: 'Yield-on-Cost',
                            type: 'percent',
                          },
                        ]

  const plan =
    find(props.session.me.activeOrg.plans || [], {
      value: property.plan,
    }) || {}
  const planImages = plan.images || []

  let preferences = merge(
    cloneDeep(DEFAULT_PREFERENCES),
    props?.session?.me?.activeOrg?.memberPreferences,
    props?.session?.me?.activeOrg?.preferences,
  )

  return (
    <Grid
      item
      xs={12}
      md={props.fullWidth ? 12 : 6}
      xl={props.fullWidth ? 12 : 3}
      className="mb-spacing-6"
    >
      <Tooltip
        title={
          (props.marketplace && !marketplaceOwned) || props.trash
            ? ''
            : props.shared || marketplaceOwned
              ? 'Click to see analysis'
              : 'Click to see analysis or right click for more options'
        }
      >
        <div
          className="card card-box-hover-rise"
          style={{ backgroundColor: 'white', position: 'relative' }}
        >
          {owned && (
            <span className="ribbon-angle ribbon-angle--top-right ribbon-primary">
              <small>
                <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                  Owned
                </Typography>
              </small>
            </span>
          )}
          {props.propertySearch &&
            !marketplaceOwned &&
            !props.shared &&
            property.marketplaces &&
            !isEmpty(property.marketplaces.approvedMarketplace) && (
              <span className="ribbon-angle ribbon-angle--top-right ribbon-success">
                <small style={{ textAlign: 'center' }}>
                  <Typography
                    style={{ fontSize: 14, fontWeight: 600 }}
                  >
                    Marketplace
                  </Typography>
                </small>
              </span>
            )}
          {props.marketplace &&
            !props.propertySearch &&
            !props.trash &&
            property.acquisition && (
              <span className="ribbon-angle ribbon-angle--top-right ribbon-primary">
                <small style={{ textAlign: 'center' }}>
                  <Typography
                    style={{
                      fontSize:
                        property.status && property.status.length > 13
                          ? 10
                          : 14,
                      fontWeight: 600,
                    }}
                  >
                    {property.status}
                  </Typography>
                </small>
              </span>
            )}
          <ContextMenuTrigger
            /*renderTag="span"*/ id={`ContextMenuTrigger_${property._id}`}
            attributes={{ style: { height: '100%' } }}
          >
            <a
              href={
                props.trash
                  ? '/trash'
                  : props.marketplace && !marketplaceOwned
                    ? '/marketplace'
                    : `/property-details/${property._id}/Summary`
              }
              onClick={
                props.jv && property.jvStatus === 'New'
                  ? () => {
                      updateProperty({
                        variables: {
                          id: property._id,
                          propertyInput: {
                            jvStatus: 'In Process',
                            jvTimeline: {
                              ...property.jvTimeline,
                              viewed: new Date(),
                            },
                          },
                        },
                        refetchQueries: [
                          {
                            query: GET_PROPERTIES,
                            variables: {
                              portfolioId: [props.selectedPortfolio],
                              limit: GET_PROPERTIES_QUERY_LIMIT,
                            },
                          },
                          {
                            query: GET_PROPERTY,
                            variables: { id: property._id },
                          },
                        ],
                      })
                    }
                  : props.lender && property.lenderStatus === 'New'
                    ? () => {
                        updateProperty({
                          variables: {
                            id: property._id,
                            propertyInput: {
                              lenderStatus: 'In Process',
                              lenderTimeline: {
                                ...property.lenderTimeline,
                                viewed: new Date(),
                              },
                            },
                          },
                          refetchQueries: [
                            {
                              query: GET_PROPERTIES,
                              variables: {
                                portfolioId: [
                                  props.selectedPortfolio,
                                ],
                                limit: GET_PROPERTIES_QUERY_LIMIT,
                              },
                            },
                            {
                              query: GET_PROPERTY,
                              variables: { id: property._id },
                            },
                          ],
                        })
                      }
                    : (!props.shared || props.sharedProperties) &&
                        ((props.marketplace && !marketplaceOwned) ||
                          props.trash)
                      ? (event) => {
                          event.preventDefault()
                          ;(props.propertySearch &&
                            property.marketplaces &&
                            !isEmpty(
                              property.marketplaces
                                .approvedMarketplace,
                            )) ||
                          (!marketplaceOwned &&
                            !props.propertySearch &&
                            !props.sharedProperties &&
                            !props.trash)
                            ? props.selectMarketplaceProperty(
                                property._id,
                              )(event)
                            : props.selectProperty(property._id)(
                                event,
                              )
                        }
                      : (event) => {
                          event.preventDefault()
                          handleOpenCriticalFields()
                        }
              }
            >
              {/* ----If property is owned then we're getting rid of the chips---- */}

              <div className="card-badges">
                {/* <Grid
                  container
                  spacing={1}
                  style={{ textAlign: 'right' }}
                > */}
                {props.jv && (
                  // <Grid item>
                  <Chip
                    label={property.jvStatus}
                    style={{
                      maxWidth: 'none',
                      position: 'absolute',
                      right: 20,
                      top: 20,
                      backgroundColor:
                        property.jvStatus === 'Rejected'
                          ? 'red'
                          : property.jvStatus === 'Accepted'
                            ? '#00bf00'
                            : 'orange',
                      color: 'white',
                      fontWeight: 600,
                    }}
                  />
                  // </Grid>
                )}
                {props.lender && (
                  <Grid item>
                    <Chip
                      label={property.lenderStatus}
                      style={{
                        maxWidth: 'none',
                        position: 'absolute',
                        right: 20,
                        top: 20,
                        backgroundColor:
                          property.lenderStatus === 'Rejected'
                            ? 'red'
                            : property.lenderStatus === 'Accepted'
                              ? '#00bf00'
                              : 'orange',
                        color: 'white',
                        fontWeight: 600,
                      }}
                    />
                  </Grid>
                )}
                {/* {!props.propertySearch && !owned && (
                    <Grid item>
                      <Tooltip title="Property Status">
                        <div className="badge badge-pill rounded" style={{ backgroundColor: 'white', color: '#4290ff' }}>{property.acquisition.activeStage ? property.acquisition.activeStage : 'new'}</div>
                      </Tooltip>
                    </Grid>
                  )}
                  {props.marketplace && property.marketplaces && !owned && map(property.marketplaces.extraTags, extra => (
                    <Grid item>
                      <Tooltip title="Extra Tag">
                        <div className="badge badge-pill rounded" style={{ backgroundColor: 'white', color: '#4290ff' }}>{extra}</div>
                      </Tooltip>
                    </Grid>
                  ))}
                  {!props.marketplace && !owned && (
                    <Grid item>
                      <Tooltip title="Selected Strategy">
                        <div className="badge badge-pill rounded" style={{ backgroundColor: '#00bf00', color: 'white' }}>{property.strategy}</div>
                      </Tooltip>
                    </Grid>
                  )}
                  {!props.marketplace && !owned && props?.session?.me?.activeOrg?.member !== 'RWN' && (
                    <Grid item>
                      <Tooltip title="Current Portfolio">
                        <div className="badge badge-pill rounded" style={{ backgroundColor: '#f4782e', color: 'white' }}>{property.portfolio.name}</div>
                      </Tooltip>
                    </Grid>
                  )} */}
                {/* </Grid> */}
              </div>
              <Slider
                ref={slider}
                className="card-img-top slider-dots"
                {...widgetsCarousels1B}
                style={{ height: '325px' }}
              >
                {/* {props.propertySearch && !property.marketplaces && property.images && startsWith(property.images, "http") ? (
                  <div>
                    <Card className="shadow-none" style={{ height: 325, backgroundColor: 'white', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, margin: 1, }} onClick={toggleImages([property.images])}>
                      <Grid container direction='column' justifyContent='center' style={{ height: '100%' }}>
                        <Grid item>
                          <div href={props.propertySearch ? '/property-search' :`/property-details/${property._id}/Summary`} onClick={(event) => { if (props.marketplace && !marketplaceOwned) event.preventDefault(); else if (!property.propertyDashboard && !props.shared && preferences.general.completePropertyData) { event.preventDefault(); handleOpenCriticalFields() } }} className="card-img-wrapper" style={{ textAlign: 'center', backgroundColor: 'transparent' }}>
                            <img src={property.images} className="card-img-top" style={{ objectFit: 'contain' }} alt="..." />
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                ) : props.propertySearch && !property.marketplaces && property.images ? map(slice(Object.values(JSON.parse(property.images)), 0, 5), image => (
                  <div key={image}>
                    <Card className="shadow-none" style={{ height: 325, backgroundColor: 'white', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, margin: 1, }} onClick={toggleImages(JSON.parse(property.images))}>
                      <Grid container direction='column' justifyContent='center' style={{ height: '100%' }}>
                        <Grid item>
                          <div href={props.propertySearch ? '/property-search' :`/property-details/${property._id}/Summary`} onClick={(event) => { if (props.marketplace && !marketplaceOwned) event.preventDefault(); else if (!property.propertyDashboard && !props.shared && preferences.general.completePropertyData) { event.preventDefault(); handleOpenCriticalFields() } }} className="card-img-wrapper" style={{ textAlign: 'center', backgroundColor: 'transparent', borderBottomRightRadius: '0px', }}>
                            <img src={image} className="card-img-top" style={{ objectFit: 'contain' }} alt="..." />
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                )) : props.propertySearch && !property.marketplaces && property.image ? (
                    <div>
                      <Card className="shadow-none" style={{ height: 325, backgroundColor: 'white', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, margin: 1, }} onClick={toggleImages([property.image])}>
                        <Grid container direction='column' justifyContent='center' style={{ height: '100%' }}>
                          <Grid item>
                            <div href={props.propertySearch ? '/property-search' :`/property-details/${property._id}/Summary`} onClick={(event) => { if (props.marketplace && !marketplaceOwned) event.preventDefault(); else if (!property.propertyDashboard && !props.shared && preferences.general.completePropertyData) { event.preventDefault(); handleOpenCriticalFields() } }} className="card-img-wrapper" style={{ textAlign: 'center', backgroundColor: 'transparent' }}>
                              <img src={property.image} className="card-img-top" style={{ objectFit: 'contain' }} alt="..." />
                            </div>
                          </Grid>
                        </Grid>
                      </Card>
                    </div> */}
                {props.propertySearch &&
                !property.marketplaces &&
                property.images
                  ? map(slice(property.images, 0, 5), (image) => (
                      <div key={image}>
                        <Card
                          className="shadow-none"
                          style={{
                            height: 325,
                            backgroundColor: 'white',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            margin: 1,
                          }}
                          onClick={toggleImages(property.images)}
                        >
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            style={{ height: '100%' }}
                          >
                            <Grid item>
                              <div
                                href={
                                  props.propertySearch
                                    ? '/property-search'
                                    : `/property-details/${property._id}/Summary`
                                }
                                onClick={(event) => {
                                  if (
                                    props.marketplace &&
                                    !marketplaceOwned
                                  )
                                    event.preventDefault()
                                  else {
                                    event.preventDefault()
                                    handleOpenCriticalFields()
                                  }
                                }}
                                className="card-img-wrapper"
                                style={{
                                  textAlign: 'center',
                                  backgroundColor: 'transparent',
                                  borderBottomRightRadius: '0px',
                                }}
                              >
                                <img
                                  src={image}
                                  className="card-img-top"
                                  style={{ objectFit: 'contain' }}
                                  alt="..."
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      </div>
                    ))
                  : !isEmpty(
                        compact(concat(property.images, planImages)),
                      )
                    ? map(
                        slice(
                          compact(
                            concat(property.images, planImages),
                          ),
                          0,
                          5,
                        ),
                        (image) => (
                          <div key={image.url}>
                            <Card
                              className="shadow-none"
                              style={{
                                height: 325,
                                backgroundColor: 'white',
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                                margin: 1,
                              }}
                            >
                              <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                style={{ height: '100%' }}
                              >
                                <Grid item>
                                  <div
                                    href={
                                      props.propertySearch
                                        ? '/property-search'
                                        : props.trash
                                          ? '/trash'
                                          : props.marketplace &&
                                              !marketplaceOwned
                                            ? '/marketplace'
                                            : `/property-details/${property._id}/Summary`
                                    }
                                    onClick={(event) => {
                                      if (
                                        (props.marketplace &&
                                          !marketplaceOwned) ||
                                        props.trash
                                      )
                                        event.preventDefault()
                                      else {
                                        event.preventDefault()
                                        handleOpenCriticalFields()
                                      }
                                    }}
                                    className="card-img-wrapper"
                                    style={{
                                      textAlign: 'center',
                                      backgroundColor: 'transparent',
                                      borderBottomRightRadius: '0px',
                                    }}
                                  >
                                    <div
                                      className="blur-load"
                                      style={{
                                        backgroundImage:
                                          'url(' +
                                          image.url_small +
                                          ')',
                                        height: 325,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <img
                                        src={image.url}
                                        className="card-img-top"
                                        style={{
                                          objectFit: 'contain',
                                          maxHeight: 325,
                                        }}
                                        alt="..."
                                        loading="lazy"
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </Card>
                          </div>
                        ),
                      )
                    : null}
                {compact(concat(property.images, planImages)).length <
                  5 && (
                  <div>
                    <Card
                      className="shadow-none"
                      style={{
                        height: 325,
                        backgroundColor: 'white',
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        margin: 1,
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        style={{ height: '100%' }}
                      >
                        <a
                          href={
                            props?.session?.me?.activeOrg?.isRealeflow
                              ? ''
                              : `https://www.comehome.com/property-details/${replace(
                                  property.address,
                                  /\s/g,
                                  '-',
                                )}-${replace(
                                  property.city,
                                  /\s/g,
                                  '-',
                                )}-${property.state}-${
                                  property.zip
                                }?modal=photo-list`
                          }
                          target="_blank"
                          onClick={(event) => event.stopPropagation()}
                          className="card-img-wrapper"
                          style={{
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                          }}
                        >
                          <Grid
                            item
                            onClick={(event) => {
                              event.preventDefault()
                              props?.session?.me?.activeOrg
                                ?.isRealeflow
                                ? ''
                                : window.open(
                                    `https://www.comehome.com/property-details/${replace(
                                      property.address,
                                      /\s/g,
                                      '-',
                                    )}-${replace(
                                      property.city,
                                      /\s/g,
                                      '-',
                                    )}-${property.state}-${
                                      property.zip
                                    }?modal=photo-list`,
                                    '_blank',
                                  )
                            }}
                          >
                            <img
                              src={
                                props?.session?.me?.activeOrg
                                  ?.member === 'pacemorby' ||
                                props?.session?.me?.activeOrg
                                  ?.member === 'astroflip'
                                  ? '/images/DealSauce-Logo-Tan.png'
                                  : props?.session?.me?.activeOrg
                                        ?.member === 'pep'
                                    ? '/images/PEP_logoNew.png'
                                    : '/images/comehome_small.png'
                              }
                              className="card-img-top"
                              // style={{ objectFit: 'contain' }}
                              alt="..."
                            />
                          </Grid>
                        </a>
                      </Grid>
                    </Card>
                  </div>
                )}
              </Slider>
              {!props.marketplace && !props.shared && (
                <Avatar
                  style={{
                    position: 'absolute',
                    left: 5,
                    top: 275,
                    backgroundColor: 'white',
                    border: '0.1px solid darkgray',
                  }}
                  onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    setHeart(!heart)
                    updateProperty({
                      variables: {
                        id: property._id,
                        propertyInput: {
                          favorite: !heart,
                        },
                      },
                      refetchQueries: [
                        {
                          query: GET_PROPERTIES,
                          variables: {
                            portfolioId: [props.selectedPortfolio],
                            limit: GET_PROPERTIES_QUERY_LIMIT,
                          },
                        },
                        {
                          query: GET_PROPERTY,
                          variables: { id: property._id },
                        },
                      ],
                    })
                  }}
                >
                  <FontAwesomeIcon
                    icon={[heart ? 'fas' : 'fal', 'heart']}
                    className="d-block font-size-xl"
                    style={{ color: '#368cff' }}
                  />
                </Avatar>
              )}
              <Avatar
                style={{
                  position: 'absolute',
                  left: !props.marketplace && !props.shared ? 55 : 5,
                  top: 275,
                  backgroundColor: 'white',
                  border: '0.1px solid darkgray',
                }}
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  window.open(
                    `/property-details/${property._id}/Summary`,
                    '_blank', // <- This is what makes it open in a new window.
                  )
                }}
              >
                <FontAwesomeIcon
                  icon={['far', 'arrow-up-right-from-square']}
                  className="d-block font-size-xl"
                  style={{ color: '#368cff' }}
                  href={`/property-details/${property._id}/Comp`}
                  target="_blank"
                />
              </Avatar>
              {marketplaceOwned &&
                property.marketplaces &&
                includes(
                  property.marketplaces.approvedMarketplace,
                  'housefolios',
                ) &&
                new Date(
                  property.marketplaces.expirationDate,
                ).getTime() < Date.now() && (
                  <Chip
                    label="Relist"
                    style={{
                      position: 'absolute',
                      right: 50,
                      top: 280,
                      backgroundColor: 'red',
                      color: 'white',
                      fontWeight: 600,
                    }}
                    onClick={(event) => {
                      event.preventDefault()
                      updateProperty({
                        variables: {
                          id: property._id,
                          propertyInput: {
                            marketplaces: {
                              marketplaceName:
                                property.marketplaces.marketplaceName,
                              approvedMarketplace:
                                property.marketplaces
                                  .approvedMarketplace,
                              expirationDate: new Date(
                                Date.now() + 7 * 24 * 60 * 60 * 1000,
                              ),
                              extraTags:
                                property.marketplaces.extraTags,
                              proformaType:
                                property.marketplaces.proformaType,
                            },
                            marketplaceSubmittedBy:
                              props.session.me._id,
                          },
                        },
                        refetchQueries: [
                          {
                            query: GET_ME,
                          },
                          {
                            query: GET_PROPERTIES,
                            variables: {
                              portfolioId: [props.selectedPortfolio],
                              limit: GET_PROPERTIES_QUERY_LIMIT,
                            },
                          },
                          {
                            query: GET_PROPERTY,
                            variables: { id: property._id },
                          },
                        ],
                      })
                      // phpSend()
                    }}
                  />
                )}
              {marketplaceOwned &&
                property.marketplaces &&
                !isEmpty(
                  property.marketplaces.approvedMarketplace,
                ) && (
                  <Tooltip
                    title={
                      <Grid container direction="column">
                        {map(
                          property.marketplaces.approvedMarketplace,
                          (marketplace) => (
                            <Grid item key={marketplace}>
                              <Typography
                                style={
                                  marketplace === 'housefolios' &&
                                  new Date(
                                    property.marketplaces.expirationDate,
                                  ).getTime() < Date.now()
                                    ? { color: 'red' }
                                    : null
                                }
                              >
                                {marketplace}
                              </Typography>
                            </Grid>
                          ),
                        )}
                      </Grid>
                    }
                  >
                    <Avatar
                      style={{
                        position: 'absolute',
                        right: 5,
                        top: 275,
                        backgroundColor: '#28b854',
                        border: '0.1px solid white',
                      }}
                    >
                      {/* <Avatar style={{ position: 'absolute', right: 5, top: 275, backgroundColor: 'white', border: '0.1px solid darkgray'}}> */}
                      <FontAwesomeIcon
                        icon={['fa', 'sign-hanging']}
                        className="d-block font-size-xl"
                      />
                    </Avatar>
                  </Tooltip>
                )}
              <div className="card-tr-actions">
                {/* <div style={{ height: 20, width: 20, backgroundColor: 'white' }}> */}
                {/* Show checkbox if not shared and is either not marketplace, not belonging to active org, or is trash*/}
                {(!props.shared ||
                  props.sharedProperties) /*&& !props.trash*/ && (
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      ;(props.propertySearch &&
                        property.marketplaces &&
                        !isEmpty(
                          property.marketplaces.approvedMarketplace,
                        )) ||
                      (!marketplaceOwned &&
                        !props.propertySearch &&
                        !props.sharedProperties &&
                        !props.trash)
                        ? props.selectMarketplaceProperty(
                            property._id,
                          )(event)
                        : props.selectProperty(property._id)(event)
                    }}
                    size="large"
                  >
                    <Checkbox
                      checked={includes(props.selected, property._id)}
                      style={{
                        height: 20,
                        width: 20,
                        backgroundColor: 'white',
                        borderRadius: 0,
                      }}
                    />
                  </IconButton>
                )}
                {/* </div> */}
                {/* <Button className="btn-link p-0 font-size-xl" variant="text">
                  <FontAwesomeIcon icon={['far', 'square']} className="font-size-lg" />
                </Button> */}
              </div>
              {/* <div className="card-badges">
              <div className="badge badge-pill badge-warning">Buy & Hold</div>
            </div>
            <div className="card-badges" style={{ marginRight: 75 }}>
              <div className="badge badge-pill badge-warning">Screening</div>
            </div> */}
              <CardContent
                className="card-body-avatar"
                style={{
                  height: props.marketplace
                    ? 'calc(100% - 410px)'
                    : 'calc(100% - 330px)',
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                >
                  <Grid container item style={{ width: '100%' }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      //spacing={2}
                    >
                      <Grid item>
                        <h5
                          className="font-weight-bold"
                          style={{ fontSize: 26 }}
                        >
                          {currencyFormat.format(
                            propertySearch && !property.marketplaces
                              ? property.price
                              : props.marketplace && !props.trash
                                ? get(
                                    property,
                                    'acquisition.analysis.resellPrice',
                                  ) || 0
                                : get(
                                    property,
                                    'acquisition.analysis.listPrice',
                                  ) || 0,
                          )}
                        </h5>
                      </Grid>
                      <Grid item>
                        <Typography className="font-size-sm">
                          {sum(property.beds) || '-'} bd |{' '}
                          {sum(property.baths) || '-'} ba |{' '}
                          {sum(property.sqft)
                            ? numberFormat.format(
                                Number(sum(property.sqft)),
                              )
                            : '-'}{' '}
                          sqft |{' '}
                          {propertySearch && property.yearBuilt
                            ? property.yearBuilt
                            : property.year || '-'}{' '}
                          yr
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ width: '100%' }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <h5
                          className="card-title font-weight-bold font-size-sm"
                          style={{}}
                        >
                          {property.address}, {property.city},{' '}
                          {property.state} {property.zip}
                        </h5>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!props.marketplace && !owned && (
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      //spacing={2}
                    >
                      <Grid item>
                        <Typography className="font-size-sm">
                          Date Added: {createdAt}
                        </Typography>
                      </Grid>
                      {props.submitted && (
                        <Grid item>
                          <Typography className="font-size-sm">
                            Submitted By:{' '}
                            {property.marketplaceSubmittedBy &&
                            property.marketplaceSubmittedBy.profile
                              ? `${property.marketplaceSubmittedBy.profile.firstname} ${property.marketplaceSubmittedBy.profile.lastname} `
                              : ''}
                          </Typography>
                        </Grid>
                      )}
                      {props.lender && (
                        <Grid item>
                          <Typography className="font-size-sm">
                            Submitted By:{' '}
                            {property.lenderSubmittedBy &&
                            property.lenderSubmittedBy.profile
                              ? `${property.lenderSubmittedBy.profile.firstname} ${property.lenderSubmittedBy.profile.lastname} `
                              : ''}
                          </Typography>
                        </Grid>
                      )}
                      {props.jv && (
                        <Grid item>
                          <Typography className="font-size-sm">
                            Submitted By:{' '}
                            {property.jvSubmittedBy &&
                            property.jvSubmittedBy.profile
                              ? `${property.jvSubmittedBy.profile.firstname} ${property.jvSubmittedBy.profile.lastname} `
                              : ''}
                          </Typography>
                        </Grid>
                      )}
                      {props.session.me.activeOrg.isRealeflow &&
                        !isEmpty(
                          props.session.me.activeOrg?.kanbanBoard
                            ?.columns,
                        ) && (
                          <Grid item>
                            <Typography>
                              {find(
                                props.session.me.activeOrg.kanbanBoard
                                  .columns,
                                (column) => {
                                  return (
                                    column._id ===
                                    property.kanbanProcessStageId
                                  )
                                },
                              )?.name ||
                                props.session.me.activeOrg.kanbanBoard
                                  .columns[0]?.name}
                            </Typography>
                          </Grid>
                        )}
                    </Grid>
                  )}
                  {props.marketplace &&
                    !props.propertySearch &&
                    !props.trash &&
                    !owned && (
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        //spacing={2}
                      >
                        <Grid item>
                          <Typography className="font-size-sm">
                            List Date: {listDate}
                          </Typography>
                        </Grid>
                        {props.submitted && (
                          <Grid item>
                            <Typography className="font-size-sm">
                              Submitted By:{' '}
                              {property.marketplaceSubmittedBy &&
                              property.marketplaceSubmittedBy.profile
                                ? `${property.marketplaceSubmittedBy.profile.firstname} ${property.marketplaceSubmittedBy.profile.lastname} `
                                : ''}
                            </Typography>
                          </Grid>
                        )}
                        {props.lender && (
                          <Grid item>
                            <Typography className="font-size-sm">
                              Submitted By:{' '}
                              {property.lenderSubmittedBy &&
                              property.lenderSubmittedBy.profile
                                ? `${property.lenderSubmittedBy.profile.firstname} ${property.lenderSubmittedBy.profile.lastname} `
                                : ''}
                            </Typography>
                          </Grid>
                        )}
                        {props.jv && (
                          <Grid item>
                            <Typography className="font-size-sm">
                              Submitted By:{' '}
                              {property.jvSubmittedBy &&
                              property.jvSubmittedBy.profile
                                ? `${property.jvSubmittedBy.profile.firstname} ${property.jvSubmittedBy.profile.lastname} `
                                : ''}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  {realeflowSoldDate && (
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      //spacing={2}
                    >
                      <Grid item>
                        <Typography className="font-size-sm">
                          Last Sold Date: {realeflowSoldDate}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {props.trash && (
                    <Grid item>
                      <Typography
                        color="error"
                        className="font-size-sm"
                      >
                        Deletes in: {daysUntilDeleted} days
                      </Typography>
                    </Grid>
                  )}
                  <div style={{ flex: 1 }} />
                  {(!propertySearch ||
                    (property.marketplaces &&
                      !isEmpty(
                        property.marketplaces.approvedMarketplace,
                      ))) && (
                    <Grid item style={{ width: '100%' }}>
                      <Card style={{ backgroundColor: '#eff2f5' }}>
                        <CardContent style={{ padding: 10 }}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                            wrap="nowrap"
                            style={{ padding: 0 }}
                          >
                            {map(analytics, (field) => (
                              <Grid
                                item
                                className="gridItem2"
                                key={field.label}
                              >
                                <Grid
                                  container
                                  direction="column"
                                  alignItems="center"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    className="gridItem2"
                                  >
                                    <Typography
                                      style={{
                                        whiteSpace: 'nowrap',
                                        fontSize: 13,
                                      }}
                                    >
                                      {field.label}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    className="gridItem2"
                                  >
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                      wrap="nowrap"
                                    >
                                      {!isUndefined(field.arrow) && (
                                        <Grid item>
                                          {field.arrow ? (
                                            field.arrow === 2 ? (
                                              <Avatar
                                                style={{
                                                  backgroundColor:
                                                    '#2296F3',
                                                  height: 15,
                                                  width: 15,
                                                }}
                                              >
                                                <ArrowUpwardIcon
                                                  style={{
                                                    color: 'white',
                                                    fontSize: 'small',
                                                  }}
                                                />
                                              </Avatar>
                                            ) : (
                                              <Avatar
                                                style={{
                                                  backgroundColor:
                                                    '#46de64',
                                                  height: 15,
                                                  width: 15,
                                                }}
                                              >
                                                <ArrowUpwardIcon
                                                  style={{
                                                    color: 'white',
                                                    fontSize: 'small',
                                                  }}
                                                />
                                              </Avatar>
                                            )
                                          ) : (
                                            <Avatar
                                              style={{
                                                backgroundColor:
                                                  '#F05248',
                                                height: 15,
                                                width: 15,
                                              }}
                                            >
                                              <ArrowDownwardIcon
                                                style={{
                                                  color: 'white',
                                                  fontSize: 'small',
                                                }}
                                              />
                                            </Avatar>
                                          )}
                                        </Grid>
                                      )}
                                      <Grid
                                        item
                                        style={{ marginLeft: 5 }}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: 11,
                                            fontWeight: 600,
                                          }}
                                        >
                                          {/* {field.value !== '-' ? field.type === 'currency' ? currencyFormat.format(field.value) : percentageFormat.format(field.value * 100) + '%' : field.value} */}
                                          {field.value !== '-'
                                            ? field.type ===
                                              'currency'
                                              ? currencyFormat.format(
                                                  field.value,
                                                )
                                              : field.type ===
                                                    'percent' &&
                                                  field.value > 10
                                                ? '+1000%'
                                                : percentageFormat.format(
                                                    field.value,
                                                  )
                                            : field.value}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                  {propertySearch &&
                    !(
                      property.marketplaces &&
                      !isEmpty(
                        property.marketplaces.approvedMarketplace,
                      )
                    ) && (
                      <Grid item style={{ width: '100%' }}>
                        <Card
                          style={{
                            backgroundColor: '#eff2f5',
                            position: 'sticky',
                          }}
                        >
                          <CardContent style={{ padding: 10 }}>
                            <Grid
                              container
                              direction="row"
                              justifyContent="space-around"
                              alignItems="center"
                              spacing={0}
                              wrap="nowrap"
                              style={{ padding: 0 }}
                            >
                              <Grid item key={'average'}>
                                <Grid
                                  container
                                  direction="column"
                                  alignItems="center"
                                >
                                  <Grid item xs={12}>
                                    <Typography
                                      style={{
                                        whiteSpace: 'nowrap',
                                        fontSize: 13,
                                      }}
                                    >
                                      Average per sqft
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                      wrap="nowrap"
                                    >
                                      <Grid item>
                                        {pricePerSqft >
                                        props.averagePerSqft ? (
                                          <Avatar
                                            style={{
                                              backgroundColor:
                                                '#2296F3',
                                              height: 15,
                                              width: 15,
                                            }}
                                          >
                                            <ArrowUpwardIcon
                                              style={{
                                                color: 'white',
                                                fontSize: 'small',
                                              }}
                                            />
                                          </Avatar>
                                        ) : pricePerSqft ===
                                          props.averagePerSqft ? (
                                          <Avatar
                                            style={{
                                              backgroundColor:
                                                '#46de64',
                                              height: 15,
                                              width: 15,
                                            }}
                                          >
                                            <ArrowUpwardIcon
                                              style={{
                                                color: 'white',
                                                fontSize: 'small',
                                              }}
                                            />
                                          </Avatar>
                                        ) : (
                                          <Avatar
                                            style={{
                                              backgroundColor:
                                                '#F05248',
                                              height: 15,
                                              width: 15,
                                            }}
                                          >
                                            <ArrowDownwardIcon
                                              style={{
                                                color: 'white',
                                                fontSize: 'small',
                                              }}
                                            />
                                          </Avatar>
                                        )}
                                      </Grid>
                                      <Grid
                                        item
                                        style={{ marginLeft: 5 }}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: 11,
                                            fontWeight: 600,
                                          }}
                                        >
                                          {currencyFormat.format(
                                            pricePerSqft,
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                </Grid>
              </CardContent>
            </a>
            {props.marketplace && (
              <>
                <Divider />
                <CardActions>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    {props.marketplace &&
                      !marketplaceOwned &&
                      !props.trash &&
                      (props.session.me.activeOrg.parentCorp &&
                      !props.propertySearch ? (
                        <>
                          <Grid item xs={12} sm={6}>
                            <Button
                              fullWidth
                              variant="contained"
                              margin="dense"
                              color="primary"
                              component="a"
                              href={`/property-details/${property._id}/proforma/buyHold`}
                              target="_blank"
                            >
                              <FontAwesomeIcon
                                icon={['far', 'file-lines']}
                                className="font-size-md mr-2"
                              />
                              <Typography>
                                Financing Proforma
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              fullWidth
                              variant="contained"
                              margin="dense"
                              color="primary"
                              component="a"
                              href={`/property-details/${property._id}/proforma/marketing`}
                              target="_blank"
                            >
                              <FontAwesomeIcon
                                icon={['far', 'file-lines']}
                                className="font-size-md mr-2"
                              />
                              <Typography>
                                Marketing Proforma
                              </Typography>
                            </Button>
                          </Grid>
                        </>
                      ) : props.submitted ? (
                        <SubmissionApproval
                          propertyIds={[property._id]}
                          marketplace={props.marketplace}
                          removeProperty={
                            includes(props.selected, property._id)
                              ? props.selectMarketplaceProperty(
                                  property._id,
                                )
                              : null
                          }
                        />
                      ) : (
                        <>
                          <Grid item xs={6}>
                            <ContactAgent
                              property={property}
                              marketplace={props.marketplace}
                            />
                            {/* <Button fullWidth={true} variant='contained'>
                                Contact Agent
                              </Button> */}
                          </Grid>
                          <Grid item xs={6}>
                            <Analyze
                              selected={
                                property.marketplaces &&
                                !isEmpty(
                                  property.marketplaces
                                    .approvedMarketplace,
                                )
                                  ? []
                                  : [property._id]
                              }
                              marketplaceSelected={
                                property.marketplaces &&
                                !isEmpty(
                                  property.marketplaces
                                    .approvedMarketplace,
                                )
                                  ? [property._id]
                                  : []
                              }
                              properties={props.properties}
                              portfolios={props.portfolios}
                              propertySearch={props.propertySearch}
                            />
                          </Grid>
                        </>
                      ))}
                    {props.marketplace &&
                      marketplaceOwned &&
                      !props.trash && (
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            component="a"
                            href={`/property-details/${property._id}/Summary`}
                            onClick={(event) => {
                              event.preventDefault()
                              handleOpenCriticalFields()
                            }}
                            variant="contained"
                            color="primary"
                          >
                            View
                          </Button>
                        </Grid>
                      )}
                    {props.trash && (
                      <>
                        <Grid item xs={6}>
                          <RestoreProperty
                            button
                            portfolios={props.portfolios}
                            propertyId={property._id}
                            portfolioId={
                              property.portfolio
                                ? property.portfolio._id
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DeleteProperty
                            button
                            portfolios={props.portfolios}
                            propertyId={property._id}
                            portfolioId={props.selectedPortfolio}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardActions>
              </>
            )}
            {(props?.session?.me?.activeOrg?.member === 'pacemorby' ||
              props?.session?.me?.activeOrg?.member ===
                'astroflip') &&
              property.realeflowData && (
                <Grid
                  container
                  justifyContent="center"
                  spacing={2}
                  style={{ padding: '0 16px' }}
                >
                  <Grid item xs={12} sm={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => setOpenPITICashData(true)}
                      style={{ margin: 5 }}
                    >
                      Cash
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => setOpenPITICreativeData(true)}
                      style={{ margin: 5 }}
                    >
                      Creative
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      href={`/property-details/${property._id}/Comps`}
                      style={{ margin: 5 }}
                      target="_blank"
                    >
                      Comps
                    </Button>
                  </Grid>
                </Grid>
              )}
          </ContextMenuTrigger>
        </div>
      </Tooltip>
      {!props.marketplace && !props.shared && !props.trash && (
        <div className="position-relative">
          <ContextMenu
            className="
              dropdown-menu
              shadow-xxl
              bg-white
              rounded-sm
              nav
              nav-neutral-first
              nav-pills
              flex-column
            "
            id={`ContextMenuTrigger_${property._id}`}
          >
            <ListItem
              key="edit"
              component="a"
              button
              href={`/property-details/${property._id}/Edit`}
              onClick={(event) => {
                event.preventDefault()
                handleOpenCriticalFields(true)
              }}
              className="rounded-sm"
            >
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <FontAwesomeIcon
                    icon={['far', 'pen']}
                    className="font-size-lg"
                  />
                </Grid>
                <Grid item>
                  <Typography>Edit Property</Typography>
                </Grid>
              </Grid>
            </ListItem>
            <DuplicateProperty
              rightClickMenu={true}
              portfolios={props.portfolios}
              selectedPortfolio={props.selectedPortfolio}
              propertyId={property._id}
            />
            <ExportPortfolio
              rightClickMenu={true}
              properties={[property]}
            />
            <ShareProperty
              rightClickMenu={true}
              propertyId={property._id}
              portfolios={props.portfolios}
            />
            <TrashProperty
              rightClickMenu={true}
              portfolios={props.portfolios}
              propertyId={property._id}
              portfolioId={props.selectedPortfolio}
            />
          </ContextMenu>
        </div>
      )}

      <Dialog
        open={showImages}
        onClose={toggleImages()}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>
          <IconButton
            onClick={toggleImages()}
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Slider
            ref={slider1}
            className="card-img-top slider-dots-light"
            {...widgetsCarousels1B}
          >
            {!isEmpty(images) ? (
              map(images, (image) => (
                <div>
                  <Card
                    className="shadow-none"
                    style={{
                      height: 325,
                      backgroundColor: 'black',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      style={{ height: '100%' }}
                    >
                      <Grid item>
                        <a
                          href="/#"
                          onClick={(event) => {
                            event.preventDefault()
                          }}
                          className="card-img-wrapper"
                          style={{
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                            borderBottomRightRadius: '0px',
                          }}
                        >
                          <div
                            className="blur-load"
                            style={{
                              backgroundImage:
                                'url(' + image.url_small + ')',
                              height: 325,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={image.url}
                              className="card-img-top"
                              style={{
                                maxHeight: 325,
                                height: 'auto',
                                width: 'auto',
                                objectFit: 'contain',
                              }}
                              alt="..."
                              loading="lazy"
                            />
                          </div>
                        </a>
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              ))
            ) : (
              <div>
                <Card
                  className="shadow-none"
                  style={{
                    height: 325,
                    backgroundColor: 'black',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    style={{ height: '100%' }}
                  >
                    <Grid item>
                      <a
                        href="/#"
                        onClick={(event) => {
                          event.preventDefault()
                        }}
                        className="card-img-wrapper"
                        style={{
                          textAlign: 'center',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <img
                          src="/images/default_image.png"
                          className="card-img-top"
                          style={{
                            maxHeight: 325,
                            height: 'auto',
                            width: 'auto',
                            objectFit: 'contain',
                          }}
                          alt="..."
                        />
                      </a>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            )}
          </Slider>
        </DialogContent>
      </Dialog>
      <PropertyInformationDialogs
        open={openCriticalFields}
        onClose={handleCloseCriticalFields}
        property={property}
        shared={props.shared}
        url={
          redirectToEdit
            ? `/property-details/${property._id}/Edit`
            : `/property-details/${property._id}/Summary`
        }
      />
      <SubtoPITICash
        open={openPITICashData}
        onClose={() => setOpenPITICashData(false)}
        propertyId={property?._id}
        address={property?.address}
        city={property.city}
        state={property.state}
        zipcode={property?.zip}
      />
      <SubtoPITICreative
        open={openPITICreativeData}
        onClose={() => setOpenPITICreativeData(false)}
        propertyId={property?._id}
        address={property?.address}
        city={property.city}
        state={property.state}
        zipcode={property?.zip}
      />
    </Grid>
  )
}

export default withSession(PropertyCardComponent)
