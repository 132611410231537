import React from 'react'

import { PageTitle } from '@/layout-components'
import { ExampleWrapperSeamless } from '@/layout-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MonthlyLimit from '@/housefolios-components/LaunchpadComponents/monthlyLimit'
import BonusProperties from '@/housefolios-components/LaunchpadComponents/bonusProperties'
import SaveMoney from '@/housefolios-components/Billing/SaveMoney'
import CancelPlanButton from '@/housefolios-components/TitleButtons/CancelPlanButton'
import { Grid } from '@mui/material'
import withSession from '@/housefolios-components/Session/withSession'
import TransactionsTable from '@/housefolios-components/Billing/TransactionsTable'

function BillingSettingsPage(props) {
  return (
    <>
      <PageTitle
        titleHeading="Billing Settings"
        titleDescription="Make sure your information is up to date"
        icon={
          <FontAwesomeIcon
            style={{ fontSize: 30 }}
            icon={['far', 'file-lines']}
            className="text-primary"
          />
        }
      >
        <CancelPlanButton title />
      </PageTitle>
      <ExampleWrapperSeamless>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item xs={12} md={4} className="d-flex">
            <MonthlyLimit page={'billing'} />
          </Grid>
          <Grid item xs={12} md={4} className="d-flex">
            <BonusProperties
              session={props.session}
              page={'billing'}
            />
          </Grid>
          <Grid item xs={12} md={4} className="d-flex">
            <SaveMoney />
          </Grid>
          <Grid itm xs={12} md={12} className="d-flex">
            <TransactionsTable />
          </Grid>
        </Grid>
      </ExampleWrapperSeamless>
    </>
  )
}

export default withSession(BillingSettingsPage)
