import floor from 'lodash/floor'
import isNil from 'lodash/isNil'
import sum from 'lodash/sum'

export const INITIAL_FILTERS = {
  LocationFilters: [
    {
      Header: 'State',
      target: 'state',
      value: [],
      active: false,
      filterType: 'chip',
    },
    {
      Header: 'City',
      target: 'city',
      value: [],
      active: false,
      filterType: 'chip',
    },
    {
      Header: 'County',
      target: 'county',
      value: [],
      active: false,
      filterType: 'chip',
    },
    {
      Header: 'Subdivision',
      target: 'subdivision',
      value: [],
      active: false,
      filterType: 'chip',
    },
    {
      Header: 'Zip',
      target: 'zip',
      value: [],
      active: false,
      filterType: 'chip',
    },
  ],
  StrategyFilters: [
    {
      Header: 'Buy & Hold',
      target: 'strategy',
      value: 'Buy & Hold',
      active: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Fix & Flip',
      target: 'strategy',
      value: 'Fix & Flip',
      active: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Turnkey',
      target: 'strategy',
      value: 'Turnkey End Buyer',
      active: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'BRRRR',
      target: 'strategy',
      value: 'BRRRR',
      active: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Short Term Rental',
      target: 'strategy',
      value: 'Short Term Rental',
      active: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Wholesale',
      target: 'strategy',
      value: 'Wholesale',
      active: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Build to Rent',
      target: 'strategy',
      value: 'Build to Rent',
      active: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'BOR',
      target: 'strategy',
      value: 'BOR',
      active: false,
      filterType: 'checkbox',
      xs: 6,
    },
  ],
  FavoriteFilters: [
    {
      Header: 'Favorite Properties Only',
      target: 'favorite',
      value: true,
      active: false,
      filterType: 'checkbox',
      xs: 12,
    },
  ],
  OwnedFilters: [
    {
      Header: 'Owned Properties Only',
      lotHeader: 'Owned Lots Only',
      target: 'owned',
      active: false,
      filterType: 'checkbox',
      xs: 12,
    },
  ],
  PropertyFilters: [
    {
      Header: 'Asking Price',
      target: 'acquisition.analysis.listPrice',
      marketplaceTarget: 'acquisition.analysis.resellPrice',
      propertySearchTarget: 'price',
      lotTarget: 'askingPrice',
      compsTarget: 'estval',
      min: null,
      max: null,
      active: false,
      currency: true,
      options: [
        50000, 75000, 100000, 125000, 150000, 175000, 200000, 225000,
        250000, 275000, 300000, 325000, 350000, 375000, 400000,
        425000, 450000, 475000, 500000, 525000, 550000, 575000,
        600000, 625000, 650000, 675000, 700000, 750000, 800000,
        850000, 900000, 950000, 1000000,
      ],
      formatOption: (option) => {
        let newOption = floor(option / 1000)
        if (newOption >= 1000) return `$${floor(newOption / 1000)}M`
        return `$${newOption}k`
      },
      filterType: 'minmax',
    },
    {
      Header: 'Days on market',
      target: 'acquisition.analysis.daysOnMarket',
      lotTarget: 'daysOnMarket',
      compsTarget: 'daysOnMarket',
      min: null,
      max: null,
      active: false,
      options: [1, 3, 7, 14, 30, 60, 90, 180],
      filterType: 'minmax',
    },
    {
      Header: 'Date Added',
      target: 'createdAt',
      min: null,
      max: null,
      active: false,
      type: 'date',
      filterType: 'minmax',
    },
    {
      Header: 'Date Sold',
      //target: 'acquisition.analysis.daysOnMarket',
      compsTarget: 'saleDate',
      min: null,
      max: null,
      active: false,
      type: 'date',
      filterType: 'minmax',
    },
    {
      Header: 'Distance',
      //target: 'acquisition.analysis.daysOnMarket',
      compsTarget: 'distance',
      min: null,
      max: null,
      active: false,
      options: [0, 0.25, 0.5, 0.75, 1, 1.5, 2, 2.5, 3, 4, 5, 7.5, 10],
      filterType: 'minmax',
    },
    {
      Header: 'Beds',
      target: 'beds',
      min: null,
      max: null,
      active: false,
      options: [0, 1, 2, 3, 4, 5],
      parseFunction: (value) => sum(value),
      filterType: 'minmax',
    },
    {
      Header: 'Baths',
      target: 'baths',
      min: null,
      max: null,
      active: false,
      options: [0, 1, 1.5, 2, 3, 4],
      parseFunction: (value) => sum(value),
      filterType: 'minmax',
    },
    {
      Header: 'Sq Ft',
      target: 'sqft',
      min: null,
      max: null,
      active: false,
      options: [
        500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750,
        3000, 3500, 4000, 4500, 5000, 6000, 7000,
      ],
      parseFunction: (value) => sum(value),
      filterType: 'minmax',
    },
    {
      Header: 'Yr Built',
      target: 'year',
      propertySearchTarget: 'yearBuilt',
      min: null,
      max: null,
      type: 'date',
      dateViews: ['year'],
      view: 'year',
      active: false,
      //   options: [
      //     2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015,
      //     2010, 2005, 2000, 1990, 1980, 1970, 1960, 1950, 1940, 1920,
      //     1900, 1850, 1800, 1750, 1700,
      //   ],
      parseFunction: (value) => new Date(value, 0).getTime(),
      filterType: 'minmax',
    },
    {
      Header: 'Property Type',
      target: 'propertyType',
      value: [],
      active: false,
      filterType: 'chip',
    },
    {
      Header: 'Marketplace Listings',
      target: 'marketplaces.approvedMarketplace',
      value: [],
      active: false,
      filterType: 'chip',
    },
    {
      Header: 'Rehab Levels',
      target: 'rehabLevel',
      value: [],
      active: false,
      filterType: 'chip',
    }, //compOnly
    {
      Header: 'Added By',
      target: 'createdBy.email',
      value: [],
      active: false,
      filterType: 'chip',
    },
    {
      Header: 'Comp Count',
      target: 'compCount',
      min: null,
      max: null,
      active: false,
      options: [
        1, 3, 5, 7, 10, 15, 20, 25, 30, 40, 50, 75, 100, 150, 200,
        250, 300,
      ],
      filterType: 'minmax',
    },
  ],
  MoreDetailFilters: [
    {
      Header: 'On Market',
      target: 'mlsActive',
      value: '',
      options: [true, false, undefined],
      formatOption: (option) =>
        isNil(option) ? 'Both' : option ? 'Yes' : 'No',
      active: false,
      filterType: 'dropdown',
    },
    {
      Header: 'Vacant',
      target: 'vacant',
      value: '',
      options: [true, false, undefined],
      formatOption: (option) =>
        isNil(option) ? 'Both' : option ? 'Yes' : 'No',
      active: false,
      filterType: 'dropdown',
    },
    {
      Header: 'Liens',
      target: 'taxLien',
      value: '',
      options: [true, false, undefined],
      formatOption: (option) =>
        isNil(option) ? 'Both' : option ? 'Yes' : 'No',
      active: false,
      filterType: 'dropdown',
    },
    {
      Header: 'Pre-Foreclosures',
      target: 'preForeclosure',
      value: '',
      options: [true, false, undefined],
      formatOption: (option) =>
        isNil(option) ? 'Both' : option ? 'Yes' : 'No',
      active: false,
      filterType: 'dropdown',
    },
    {
      Header: 'Auctions',
      target: 'auction',
      value: '',
      options: [true, false, undefined],
      formatOption: (option) =>
        isNil(option) ? 'Both' : option ? 'Yes' : 'No',
      active: false,
      filterType: 'dropdown',
    },
    {
      Header: 'Bank Owned',
      target: 'reo',
      value: '',
      options: [true, false, undefined],
      formatOption: (option) =>
        isNil(option) ? 'Both' : option ? 'Yes' : 'No',
      active: false,
      filterType: 'dropdown',
    },
    {
      Header: 'High Equity',
      target: 'highEquity',
      value: '',
      options: [true, false, undefined],
      formatOption: (option) =>
        isNil(option) ? 'Both' : option ? 'Yes' : 'No',
      active: false,
      filterType: 'dropdown',
    },
    {
      Header: 'Free and Clear',
      target: 'freeClear',
      value: '',
      options: [true, false, undefined],
      formatOption: (option) =>
        isNil(option) ? 'Both' : option ? 'Yes' : 'No',
      active: false,
      filterType: 'dropdown',
    },
    {
      Header: 'Failed Listings',
      target: 'mlsFailed',
      value: '',
      options: [true, false, undefined],
      formatOption: (option) =>
        isNil(option) ? 'Both' : option ? 'Yes' : 'No',
      active: false,
      filterType: 'dropdown',
    },
    {
      Header: 'Tax Delinquent Year',
      target: 'taxDelinquentYear',
      value: [],
      active: false,
      filterType: 'chip',
    },
  ],
  StageFilters: [
    // {
    //   Header: 'Needs Info',
    //   value: 'needsInfo',
    //   active: false,
    //   filterType: 'checkbox',
    //  xs: 6,
    // },
    {
      Header: 'New',
      value: 'new',
      active: false,
      avalibility: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Coming Soon',
      value: 'comingSoon',
      active: false,
      avalibility: true,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Available',
      value: 'available',
      active: false,
      avalibility: true,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'New Construction',
      value: 'newConstruction',
      active: false,
      avalibility: true,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Active',
      value: 'active',
      active: false,
      avalibility: true,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Pending',
      value: 'pending',
      active: false,
      avalibility: true,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Contingent',
      value: 'contingent',
      active: false,
      avalibility: true,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Sold',
      value: 'sold',
      active: false,
      avalibility: true,
      filterType: 'checkbox',
      xs: 6,
    },
    // {
    //   Header: 'Analysis',
    //   value: 'analysis',
    //   active: false,
    //   avalibility: false,
    //   filterType: 'checkbox',
    //  xs: 6,
    // },
    // {
    //   Header: 'Offer',
    //   value: 'offer',
    //   active: false,
    //   avalibility: false,
    //   filterType: 'checkbox',
    //  xs: 6,
    // },
    {
      Header: 'Under Contract',
      value: 'underContract',
      active: false,
      avalibility: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Due Diligence',
      value: 'dueDiligence',
      active: false,
      avalibility: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Closed',
      value: 'closed',
      active: false,
      avalibility: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Rehab',
      value: 'rehab',
      active: false,
      avalibility: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Under Mgmt',
      value: 'underMgmt',
      active: false,
      avalibility: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Approved',
      value: 'approved',
      active: false,
      avalibility: false,
      filterType: 'checkbox',
      xs: 6,
    },
    {
      Header: 'Rejected',
      value: 'rejected',
      active: false,
      avalibility: false,
      filterType: 'checkbox',
      xs: 6,
    },
  ],
  BuyHoldFinancialFilters: [
    {
      Header: 'Monthly Rent',
      target: 'acquisition.analysis.rent',
      min: null,
      max: null,
      active: false,
      currency: true,
      options: [
        400, 500, 600, 700, 800, 900, 1000, 1250, 1500, 1750, 2000,
        2250, 2500, 2750, 3000, 3250, 3500, 3750, 4000, 4500, 5000,
        5500, 6000, 6500, 7000, 8000, 9000, 10000, 15000,
      ],
      formatOption: (option) => `$${option}`,
      parseFunction: (value) => sum(value),
      filterType: 'minmax',
    },
    {
      Header: 'Net Monthly Cashflow',
      target: 'acquisition.analytics.buyHold.cashFlow',
      marketplaceTarget:
        'acquisition.analytics.turkey.cashFlowTurnkey',
      min: null,
      max: null,
      divisor: 0.08334,
      active: false,
      percentage: false,
      options: [
        100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800,
        900, 1000, 2000, 3000, 4000, 5000,
      ],
      formatOption: (option) => `$${option}`,
      filterType: 'minmax',
    },
    {
      Header: 'Cash on Cash Return',
      target: 'acquisition.analytics.buyHold.cashOnCashReturn',
      marketplaceTarget:
        'acquisition.analytics.turnkey.cashOnCashReturnTurnkey',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'Yield-on-Cost',
      target: 'acquisition.analytics.buyHold.capRate',
      marketplaceTarget:
        'acquisition.analytics.turnkey.capRateTurnkey',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'IRR',
      target: 'acquisition.analytics.buyHold.IRR',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'Equity',
      target: 'acquisition.analytics.buyHold.equity',
      marketplaceTarget:
        'acquisition.analytics.turnkey.equityTurnkey',
      min: null,
      max: null,
      active: false,
      percentage: false,
      options: [
        1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000,
        15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000,
      ],
      formatOption: (option) => {
        let newOption = floor(option / 1000)
        if (newOption >= 1000) return `$${floor(newOption / 1000)}M`
        return `$${newOption}k`
      },
      filterType: 'minmax',
    },
  ],
  FixFlipFinancialFilters: [
    {
      Header: 'Net ROI',
      target: 'acquisition.analytics.fixFlip.netROIFixFlip',
      marketplaceTarget:
        'acquisition.analytics.turnkey.netROITurnkey',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'Net Profit',
      target: 'acquisition.analytics.fixFlip.saleProceeds',
      marketplaceTarget:
        'acquisition.analytics.fixFlip.percentageOfARV',
      min: null,
      max: null,
      active: false,
      currency: true,
      options: [
        1000, 3000, 5000, 10000, 15000, 20000, 25000, 30000, 35000,
        40000, 45000, 50000, 60000, 70000, 80000, 90000, 100000,
        250000, 500000, 750000, 1000000,
      ],
      formatOption: (option) => {
        let newOption = floor(option / 1000)
        if (newOption >= 1000) return `$${floor(newOption / 1000)}M`
        return `$${newOption}k`
      },
      filterType: 'minmax',
    },
    {
      Header: 'All In Cost',
      target: 'acquisition.analytics.fixFlip.allInCost',
      marketplaceTarget:
        'acquisition.analytics.turnkey.allInCostTurnkey',
      min: null,
      max: null,
      active: false,
      currency: true,
      options: [
        10000, 20000, 30000, 40000, 50000, 75000, 100000, 125000,
        150000, 175000, 200000, 225000, 250000, 275000, 300000,
        350000, 400000, 450000, 500000, 600000, 700000, 800000,
        900000, 1000000,
      ],
      formatOption: (option) => {
        let newOption = floor(option / 1000)
        if (newOption >= 1000) return `$${floor(newOption / 1000)}M`
        return `$${newOption}k`
      },
      filterType: 'minmax',
    },
    {
      Header: 'All-in Cost to ARV',
      target: 'acquisition.analytics.fixFlip.percentageOfARV',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [90, 85, 80, 75, 70, 65, 60, 55, 50],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
  ],
  TurnkeyFinancialFilters: [
    {
      Header: 'Turnkey Net ROI',
      target: 'acquisition.analytics.fixFlip.netROIFixFlip',
      marketplaceTarget:
        'acquisition.analytics.turnkey.netROITurnkey',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1000, 3000, 5000, 10000, 15000, 20000, 25000, 30000, 35000,
        40000, 45000, 50000, 60000, 70000, 80000, 90000, 100000,
      ],
      formatOption: (option) => {
        let newOption = floor(option / 1000)
        if (newOption >= 1000) return `$${floor(newOption / 1000)}M`
        return `$${newOption}k`
      },
      filterType: 'minmax',
    },
    {
      Header: 'Turnkey Sale Proceeds',
      target: 'acquisition.analytics.fixFlip.saleProceeds',
      marketplaceTarget:
        'acquisition.analytics.fixFlip.percentageOfARV',
      min: null,
      max: null,
      active: false,
      currency: true,
      options: [
        5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'Turnkey All In Cost',
      target: 'acquisition.analytics.turnkey.allInCostTurnkey',
      //marketplaceTarget: 'acquisition.analytics.allInCostTurnkey',
      min: null,
      max: null,
      active: false,
      currency: true,
      options: [
        10000, 20000, 30000, 40000, 50000, 75000, 100000, 125000,
        150000, 175000, 200000, 225000, 250000, 275000, 300000,
        350000, 400000, 450000, 500000, 600000, 700000, 800000,
        900000, 1000000,
      ],
      formatOption: (option) => {
        let newOption = floor(option / 1000)
        if (newOption >= 1000) return `$${floor(newOption / 1000)}M`
        return `$${newOption}k`
      },
      filterType: 'minmax',
    },
    {
      Header: 'Turnkey All-in Cost to ARV',
      target: 'acquisition.analytics.fixFlip.percentageOfARV',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [90, 85, 80, 75, 70, 65, 60, 55, 50],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'Turnkey Monthly Rent',
      target: 'acquisition.analysis.rent',
      min: null,
      max: null,
      active: false,
      currency: true,
      options: [
        400, 500, 600, 700, 800, 900, 1000, 1250, 1500, 1750, 2000,
        2250, 2500, 2750, 3000, 3250, 3500, 3750, 4000, 4500, 5000,
        5500, 6000, 6500, 7000, 8000, 9000, 10000, 15000,
      ],
      formatOption: (option) => `$${option}`,
      filterType: 'minmax',
    },
    {
      Header: 'Turnkey Net Monthly Cashflow',
      target: 'acquisition.analytics.turnkey.cashFlowTurnkey',
      marketplaceTarget:
        'acquisition.analytics.turnkey.cashFlowTurnkey',
      min: null,
      max: null,
      divisor: 0.08334,
      active: false,
      percentage: false,
      options: [
        100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800,
        900, 1000, 2000, 3000, 4000, 5000,
      ],
      formatOption: (option) => `$${option}`,
      filterType: 'minmax',
    },
    {
      Header: 'Turnkey Cash on Cash Return',
      target: 'acquisition.analytics.turnkey.cashOnCashReturnTurnkey',
      marketplaceTarget:
        'acquisition.analytics.turnkey.cashOnCashReturnTurnkey',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'Turnkey CAP Rate',
      target: 'acquisition.analytics.turnkey.capRateTurnkey',
      marketplaceTarget:
        'acquisition.analytics.turnkey.capRateTurnkey',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'Turnkey IRR',
      target: 'acquisition.analytics.turnkey.IRRTurnkey',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'Turnkey Equity',
      target: 'acquisition.analytics.turnkey.equityTurnkey',
      marketplaceTarget:
        'acquisition.analytics.turnkey.equityTurnkey',
      min: null,
      max: null,
      active: false,
      percentage: false,
      options: [
        1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000,
        15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000,
      ],
      formatOption: (option) => {
        let newOption = floor(option / 1000)
        if (newOption >= 1000) return `$${floor(newOption / 1000)}M`
        return `$${newOption}k`
      },
      filterType: 'minmax',
    },
  ],
  BRRRRFinancialFilters: [
    // {
    //   Header: 'Monthly Rent',
    //   target: 'acquisition.analysis.rent',
    //   min: null,
    //   max: null,
    //   active: false,
    //   currency: true,
    //   options: [400, 500, 600, 700, 800, 900, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000, 3250, 3500, 3750, 4000, 4500, 5000, 5500, 6000, 6500, 7000, 8000, 9000, 10000, 15000],
    //   formatOption: (option) => `$${option}`,
    //   filterType: 'minmax',
    // },
    {
      Header: 'BRRRR Net Monthly Cashflow',
      target: 'acquisition.analytics.BRRRR.cashFlowBRRRR',
      min: null,
      max: null,
      divisor: 0.08334,
      active: false,
      percentage: false,
      options: [
        100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800,
        900, 1000, 2000, 3000, 4000, 5000,
      ],
      formatOption: (option) => `$${option}`,
      filterType: 'minmax',
    },
    {
      Header: 'BRRRR Cash on Cash Return',
      target: 'acquisition.analytics.BRRRR.cashOnCashReturnBRRRR',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'BRRRR Yield-on-Cost',
      target: 'acquisition.analytics.BRRRR.capRateBRRRR',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'BRRRR IRR',
      target: 'acquisition.analytics.BRRRR.IRRBRRRR',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    // {
    //   Header: 'Equity',
    //   target: 'acquisition.analytics.equity',
    //   marketplaceTarget: 'acquisition.analytics.equityTurnkey',
    //   min: null,
    //   max: null,
    //   active: false,
    //   percentage: false,
    //   options: [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000],
    //   formatOption: (option) => {
    //     let newOption = floor(option / 1000)
    //     if (newOption >= 1000)
    //       return `$${floor(newOption / 1000)}M`
    //     return `$${newOption}k`
    //   },
    //   filterType: 'minmax',
    // },
  ],
  STRFinancialFilters: [
    // {
    //   Header: 'Monthly Rent',
    //   target: 'acquisition.analysis.rent',
    //   min: null,
    //   max: null,
    //   active: false,
    //   currency: true,
    //   options: [400, 500, 600, 700, 800, 900, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000, 3250, 3500, 3750, 4000, 4500, 5000, 5500, 6000, 6500, 7000, 8000, 9000, 10000, 15000],
    //   formatOption: (option) => `$${option}`,
    //   filterType: 'minmax',
    // },
    {
      Header: 'STR Net Monthly Cashflow',
      target: 'acquisition.analytics.STR.cashFlowSTR',
      min: null,
      max: null,
      divisor: 0.08334,
      active: false,
      percentage: false,
      options: [
        100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800,
        900, 1000, 2000, 3000, 4000, 5000,
      ],
      formatOption: (option) => `$${option}`,
      filterType: 'minmax',
    },
    {
      Header: 'STR Cash on Cash Return',
      target: 'acquisition.analytics.STR.cashOnCashReturnSTR',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'STR Yield-on-Cost',
      target: 'acquisition.analytics.STR.capRateSTR',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'IRR STR',
      target: 'acquisition.analytics.STR.IRRSTR',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    // {
    //   Header: 'Equity',
    //   target: 'acquisition.analytics.equity',
    //   marketplaceTarget: 'acquisition.analytics.equityTurnkey',
    //   min: null,
    //   max: null,
    //   active: false,
    //   percentage: false,
    //   options: [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000],
    //   formatOption: (option) => {
    //     let newOption = floor(option / 1000)
    //     if (newOption >= 1000)
    //       return `$${floor(newOption / 1000)}M`
    //     return `$${newOption}k`
    //   },
    //   filterType: 'minmax',
    // },
  ],
  buildToRentFinancialFilters: [
    {
      Header: 'B2R All In Cost',
      target: 'acquisition.analytics.B2R.allInCostB2R',
      min: null,
      max: null,
      active: false,
      currency: true,
      options: [
        10000, 20000, 30000, 40000, 50000, 75000, 100000, 125000,
        150000, 175000, 200000, 225000, 250000, 275000, 300000,
        350000, 400000, 450000, 500000, 600000, 700000, 800000,
        900000, 1000000,
      ],
      formatOption: (option) => {
        let newOption = floor(option / 1000)
        if (newOption >= 1000) return `$${floor(newOption / 1000)}M`
        return `$${newOption}k`
      },
      filterType: 'minmax',
    },
    {
      Header: 'B2R Net Monthly Cashflow',
      target: 'acquisition.analytics.B2R.cashFlowB2R',
      min: null,
      max: null,
      divisor: 0.08334,
      active: false,
      percentage: false,
      options: [
        100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800,
        900, 1000, 2000, 3000, 4000, 5000,
      ],
      formatOption: (option) => `$${option}`,
      filterType: 'minmax',
    },
    {
      Header: 'B2R Cash on Cash Return',
      target: 'acquisition.analytics.B2R.cashOnCashReturnB2R',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'B2R CAP Rate',
      target: 'acquisition.analytics.B2R.capRateB2R',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'IRR B2R',
      target: 'acquisition.analytics.B2R.IRRB2R',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'B2R Equity',
      target: 'acquisition.analytics.B2R.equityB2R',
      marketplaceTarget: 'acquisition.analytics.B2R.equityB2R',
      min: null,
      max: null,
      active: false,
      percentage: false,
      options: [
        1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000,
        15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000,
      ],
      formatOption: (option) => {
        let newOption = floor(option / 1000)
        if (newOption >= 1000) return `$${floor(newOption / 1000)}M`
        return `$${newOption}k`
      },
      filterType: 'minmax',
    },
  ],
  WholesaleFilters: [
    {
      Header: 'Wholesale Net Profit',
      target: 'acquisition.analytics.wholesale.saleProceedsWholesale',
      min: null,
      max: null,
      active: false,
      currency: true,
      options: [
        1000, 3000, 5000, 10000, 15000, 20000, 25000, 30000, 35000,
        40000, 45000, 50000, 60000, 70000, 80000, 90000, 100000,
        250000, 500000, 750000, 1000000,
      ],
      formatOption: (option) => {
        let newOption = floor(option / 1000)
        if (newOption >= 1000) return `$${floor(newOption / 1000)}M`
        return `$${newOption}k`
      },
      filterType: 'minmax',
    },
  ],
  BORFinancialFilters: [
    {
      Header: 'Average Annual Return',
      target: 'acquisition.analytics.BOR.averageAnnualReturnBOR',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
    {
      Header: 'IRR BOR',
      target: 'acquisition.analytics.BOR.IRRBOR',
      min: null,
      max: null,
      divisor: 100,
      active: false,
      percentage: true,
      options: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      formatOption: (option) => `${option}%`,
      filterType: 'minmax',
    },
  ],
  AstroFlipFilters: [
    {
      Header: 'Astroflip Net Profit',
      target: 'acquisition.analytics.astroFlip.saleProceedsAstroFlip',
      min: null,
      max: null,
      active: false,
      currency: true,
      options: [
        1000, 3000, 5000, 10000, 15000, 20000, 25000, 30000, 35000,
        40000, 45000, 50000, 60000, 70000, 80000, 90000, 100000,
        250000, 500000, 750000, 1000000,
      ],
      formatOption: (option) => {
        let newOption = floor(option / 1000)
        if (newOption >= 1000) return `$${floor(newOption / 1000)}M`
        return `$${newOption}k`
      },
      filterType: 'minmax',
    },
  ],
  AffiliateFilters: [
    {
      Header: 'Zoning',
      target: 'zone',
      value: [],
      active: false,
      filterType: 'chip',
    },
    {
      Header: 'Type',
      target: 'type',
      value: [],
      active: false,
      filterType: 'chip',
    },
    {
      Header: 'Plan',
      target: 'plan',
      value: [],
      active: false,
      filterType: 'chip',
    },
    {
      Header: 'Market',
      target: 'market',
      value: [],
      active: false,
      filterType: 'chip',
    },
    {
      Header: 'Salesperson',
      target: 'salesperson',
      value: [],
      active: false,
      filterType: 'chip',
    },
  ],
}
