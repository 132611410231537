/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-04 12:09:44
 * @ Description: Main content of the contact agent dialog for non-RWN users
 */

import React, { useState, useEffect } from 'react'
import map from 'lodash/map'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import withSession from '@/housefolios-components/Session/withSession'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import truncate from 'lodash/truncate'
import { useDropzone } from 'react-dropzone'

import {
  Button,
  Card,
  CardActions,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  Step,
  Stepper,
  StepLabel,
  Typography,
  TextField,
  Checkbox,
  MenuItem,
  ListItem,
  List,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import { useMutation, useQuery } from '@apollo/client'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import Check from '@mui/icons-material/Check'
import StepConnector from '@mui/material/StepConnector'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { CONTACT_AGENT } from '../mutations'
import { enqueueSnackbar } from 'notistack'
import { NumericFormat } from 'react-number-format'
import { GET_OPTIONS } from '../queries'
import Autocomplete from '@mui/material/Autocomplete'
import Alert from '@mui/material/Alert'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { faAddressBook } from '@fortawesome/pro-duotone-svg-icons'

function StepIcon(props) {
  const { active, completed } = props

  const icons = {
    1: (
      <FontAwesomeIcon
        icon={['far', 'file-invoice-dollar']}
        className="font-size-xl"
      />
    ),
    2: <FontAwesomeIcon icon={['far', 'user']} />,
    3: <FontAwesomeIcon icon={['far', 'landmark']} />,
    4: <FontAwesomeIcon icon={['fal', 'paper-plane']} />,
  }

  return (
    <div
      className={clsx(
        'd-50 transition-base d-flex align-items-center bg-gray-400 justify-content-center rounded',
        {
          'd-80 bg-primary text-white shadow-primary-sm': active,
          'd-50 bg-success text-white shadow-success-sm': completed,
        },
      )}
    >
      {completed ? (
        <Check className="completed" />
      ) : (
        icons[String(props.icon)]
      )}
    </div>
  )
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}

function getSteps() {
  // return ['Offer Details', 'Buyer Info', 'Funding Info', 'Review & Submit'];
  return ['Offer', 'Buyer', 'Funding', 'Review']
}

function ContactAgentStepperComponent(props) {
  const {
    session: {
      me: {
        // profile: { firstname = '', lastname = '' },
        // email = '',
        // phone = '',
        activeOrg,
      },
    },
    property: { _id, address = '', city = '', state = '', zip = '' },
  } = props

  const targetMarket = find(
    activeOrg.marketPlaces,
    (marketplace) => props.marketplace === marketplace.url,
  )
  let contactEmail = ''
  if (targetMarket && props.marketplace)
    contactEmail = targetMarket.contactEmail
  //const [imageUpload] = useMutation(IMAGE_UPLOAD)
  const [activeStep, setActiveStep] = useState(0)
  const [message, setMessage] = useState('')
  const [firstname, setFirstname] = useState(
    props.session.me.profile.firstname,
  )
  const [lastname, setLastname] = useState(
    props.session.me.profile.lastname,
  )
  const [email, setEmail] = useState(props.session.me.email)
  const [phone, setPhone] = useState(props.session.me.phone)
  const [offerPrice, setOfferPrice] = useState()
  const [exchange1031, setExchange1031] = useState(false)
  const [buyer, setBuyer] = useState()
  const [name, setName] = useState()
  const [method, setMethod] = useState()
  const [lender, setLender] = useState()
  const [file, setFile] = useState()
  const steps = getSteps()

  useEffect(() => {
    setMessage('')
    setFirstname(props.session.me.profile.firstname)
    setLastname(props.session.me.profile.lastname)
    setEmail(props.session.me.email)
    setPhone(props.session.me.phone)
  }, [
    props.property,
    props.session.me.email,
    props.session.me.phone,
    props.session.me.profile.firstname,
    props.session.me.profile.lastname,
  ])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const [loader, setLoader] = useState(false)

  //const [addProperties] = useMutation(ADD_PROPERTIES)

  const [contactAgent] = useMutation(CONTACT_AGENT)

  const contact = () => {
    setLoader(true)
    contactAgent({
      variables: {
        message,
        propertyId: _id,
        contactEmail: [contactEmail],
        firstname,
        lastname,
        phone,
        email,
        address,
        city,
        state,
        zip,
        buyer,
        offerPrice: currencyFormat.format(offerPrice),
        exchange1031,
        title: name,
        funding: method,
        base64: file,
        makeOffer: !Boolean(props.marketplace),
      },
      refetchQueries: Boolean(props.marketplace)
        ? []
        : [
            {
              query: GET_PROPERTY,
              variables: { id: _id },
            },
          ],
    }).then(({ data }) => {
      enqueueSnackbar(`Message sent successfully`, {
        variant: 'success',
        autoHideDuration: 3000,
      })
      setLoader(false)
      props.handleCloseAddComp()
    })
  }

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    accept: 'image/*, application/pdf',
    onDrop: (acceptedFiles) => {
      const reader = new FileReader()
      reader.onload = async () => {
        const base64 = reader.result
        setFile(base64)
      }
      reader.readAsDataURL(acceptedFiles[0])
    },
  })

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  let results = useQuery(GET_OPTIONS, {
    variables: { name: 'method' },
  })
  const methodLoading = results.loading || false
  const methodError = results.error || null
  const methodOptions = results.data
    ? results.data.options.options
    : []

  results = useQuery(GET_OPTIONS, {
    variables: { name: 'buyer' },
  })
  const buyerLoading = results.loading || false
  const buyerError = results.error || null
  const buyerOptions = results.data
    ? results.data.options.options
    : []

  results = useQuery(GET_OPTIONS, {
    variables: { name: 'lender' },
  })
  const lenderLoading = results.loading || false
  const lenderError = results.error || null
  const lenderOptions = results.data
    ? results.data.options.options
    : []

  if (methodLoading || buyerLoading || lenderLoading) return null
  if (methodError) return methodError.message
  if (buyerError) return buyerError.message
  if (lenderError) return lenderError.message

  const files = acceptedFiles.map((file) => (
    <ListItem
      className="font-size-sm px-3 py-2 text-primary d-flex justify-content-between align-items-center"
      key={file.path}
    >
      <span>{file.path}</span>{' '}
      <span className="badge badge-pill bg-neutral-warning text-warning">
        {file.size} bytes
      </span>
    </ListItem>
  ))

  const column1 = [
    // {
    //   type: 'double',
    //   left1: 'Address',
    //   left2: 'City, state, zip',
    //   right1: address,
    //   right2: `${city}, ${state} ${zip}`,
    //   divider: 'true',
    // },
    {
      left1: 'Offer',
      right1: currencyFormat.format(offerPrice),
      divider: 'true',
    },
    {
      left1: 'Funding',
      right1: find(methodOptions, (option) => option.value === method)
        ? find(methodOptions, (option) => option.value === method)
            .label
        : '',
      divider: 'true',
    },
    {
      // type: 'double',
      left1: 'Proof of Funds',
      left2: 'File name',
      right1: 'Uploaded',
      right2: !isEmpty(acceptedFiles)
        ? truncate(acceptedFiles[0].name)
        : '',
      divider: 'true',
    },
    {
      type: 'double',
      left1: 'Buyer',
      left2: 'Email',
      right1: find(buyerOptions, (option) => option.value === buyer)
        ? find(buyerOptions, (option) => option.value === buyer).label
        : '',
      right2: email,
      divider: 'true',
    },
    {
      left1: 'Phone',
      right1: phone,
      // divider: 'true',
    },
  ]

  // const column2 = [
  //   {
  //     type: 'double',
  //     left1: 'Buyer',
  //     left2: 'Email',
  //     right1: find(buyerOptions, option => option.value === buyer) ? find(buyerOptions, option => option.value === buyer).label : '',
  //     right2: email,
  //     divider: 'true',
  //   },
  //   {
  //     left1: 'Phone',
  //     right1: phone,
  //     divider: 'true',
  //   },
  //   {
  //     type: 'double',
  //     left1: 'Address',
  //     left2: 'City, state, zip',
  //     right1: address,
  //     right2: `${city}, ${state} ${zip}`
  //   },
  // ]

  const isDisabled =
    activeStep === 0
      ? isNil(offerPrice) || !buyer
      : activeStep === 1
        ? !name ||
          !firstname ||
          !lastname ||
          !phone ||
          !email ||
          !message
        : activeStep === 2
          ? !method || !file
          : false

  return (
    <BlockUi
      blocking={loader}
      loader={
        <PacmanLoader color={'var(--primary)'} loading={true} />
      }
      message={<div className="text-primary">Sending Message</div>}
    >
      <ValidatorForm>
        <Card className="card-box">
          <div className="card-header" style={{ overflow: 'scroll' }}>
            <div className="card-header--title">
              <Typography variant="h6">Make offer Wizard</Typography>
            </div>
          </div>
          <div>
            <div className="bg-secondary mb-3">
              <Stepper
                style={{ padding: 24 }}
                className="stepper-horizontal-1"
                alternativeLabel
                activeStep={activeStep}
                connector={<StepConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={StepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <span>
              <div /*style={{ maxHeight: window.innerHeight - 300 }}*/
              >
                {activeStep === 0 && (
                  <Container>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      style={{ marginTop: 25 }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: 600 }}
                        >
                          Offer Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          Please fill out the fields below to
                          continue.
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <NumericFormat
                          customInput={TextField}
                          thousandSeparator={true}
                          prefix={'$'}
                          fullWidth={true}
                          isNumericString={true}
                          autoFocus
                          decimalScale={0}
                          key="offerPrice"
                          name="offerPrice"
                          value={offerPrice || ''}
                          onValueChange={(values) => {
                            const { value } = values
                            setOfferPrice(Number(value))
                            // const event = {
                            //   target: {
                            //     name: 'offerPrice',
                            //     value: value,
                            //     type: 'number',
                            //   },
                            //   preventDefault: () => {
                            //     return null
                            //   },
                            // }
                            // onChange(event)
                          }}
                          type={'text'}
                          onFocus={(event) => {
                            event.target.select()
                          }}
                          variant="outlined"
                          margin="dense"
                          label="Offer Price"
                          helperText={`Asking price is ${
                            props.property.acquisition
                              ? currencyFormat.format(
                                  props.property.acquisition
                                    ? props.property.acquisition
                                        .analysis.listPrice
                                    : 0,
                                )
                              : '-'
                          }`}
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          select
                          variant="outlined"
                          margin="dense"
                          fullWidth={true}
                          value={buyer}
                          onChange={(event) =>
                            setBuyer(event.target.value)
                          }
                          label="Buyer is"
                        >
                          {map(buyerOptions, (option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid
                          container
                          alignItems="center"
                          wrap="nowrap"
                        >
                          <Grid item>
                            <FormControlLabel
                              color="primary"
                              label="This will be part of a 1031 exchange"
                              labelPlacement="end"
                              control={<Checkbox />}
                              checked={exchange1031}
                              onClick={() => {
                                setExchange1031(!exchange1031)
                              }}
                            />
                          </Grid>
                          {/* <Grid item style={{ paddingLeft: 5 }}>
                            <Typography>This will be part of a 1031 exchange</Typography>
                          </Grid> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>
                )}
                {activeStep === 1 && (
                  <Container>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      style={{ marginTop: 25 }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: 600 }}
                        >
                          Buyer Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          Please fill out the fields below to
                          continue.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextValidator
                          fullWidth
                          variant="outlined"
                          // margin='dense'
                          label="Name to appear on title"
                          helperText="Please write the name exactly as you wish it to appear on the title"
                          autoFocus
                          FormHelperTextProps={{
                            style: {
                              color: '#368CFF',
                            },
                          }}
                          value={name}
                          onChange={(event) =>
                            setName(event.target.value)
                          }
                          required={true}
                          validators={[
                            'required',
                            'maxStringLength: 50',
                          ]}
                          errorMessages={[
                            'This field is required',
                            'You cannot exceed 50 characters',
                          ]}
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <TextValidator
                          fullWidth
                          variant="outlined"
                          // margin='dense'
                          label="First Name"
                          value={firstname}
                          onChange={(event) =>
                            setFirstname(event.target.value)
                          }
                          required={true}
                          validators={[
                            'required',
                            'maxStringLength: 20',
                            'matchRegexp:^[A-z ]+$',
                          ]}
                          errorMessages={[
                            'This field is required',
                            'You cannot exceed 20 characters',
                            'This field only allows characters in the alphabet and spaces',
                          ]}
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <TextValidator
                          fullWidth
                          variant="outlined"
                          // margin='dense'
                          label="Last Name"
                          value={lastname}
                          onChange={(event) =>
                            setLastname(event.target.value)
                          }
                          required={true}
                          validators={[
                            'required',
                            'maxStringLength: 20',
                            'matchRegexp:^[A-z ]+$',
                          ]}
                          errorMessages={[
                            'This field is required',
                            'You cannot exceed 20 characters',
                            'This field only allows characters in the alphabet and spaces',
                          ]}
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <TextValidator
                          fullWidth
                          variant="outlined"
                          // margin='dense'
                          label="Email Address"
                          value={email}
                          onChange={(event) =>
                            setEmail(event.target.value)
                          }
                          required={true}
                          validators={[
                            'required',
                            'maxStringLength: 50',
                            'isEmail',
                          ]}
                          errorMessages={[
                            'This field is required',
                            'You cannot exceed 50 characters',
                            'Not a valid email address',
                          ]}
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <TextValidator
                          fullWidth
                          variant="outlined"
                          // margin='dense'
                          label="Phone"
                          value={phone}
                          onChange={(event) =>
                            setPhone(event.target.value)
                          }
                          required={true}
                          validators={[
                            'required',
                            'matchRegexp:^[0-9()-]+$',
                          ]}
                          errorMessages={[
                            'This field is required',
                            'Not a valid phone number',
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextValidator
                          fullWidth
                          variant="outlined"
                          // margin='dense'
                          label="Comments"
                          value={message}
                          onChange={(event) =>
                            setMessage(event.target.value)
                          }
                          required={true}
                          multiline={true}
                          rows={4}
                          validators={[
                            'required',
                            'maxStringLength: 3000',
                          ]}
                          errorMessages={[
                            'This field is required',
                            'You cannot exceed 3000 characters',
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                )}
                {activeStep === 2 && (
                  <Container>
                    {/* ******* Cash Purchase ******* */}
                    {/* <Grid container direction="row" spacing={2} style={{ marginTop: 25 }}>
                      <Grid item xs={12}>
                        <Typography variant='h5' style={{ fontWeight: 600}}>Funding Information</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='subtitle1'>Please fill out the fields below to continue.</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant='outlined'
                          margin='dense'
                          fullWidth={true}
                          label="Method of Funding"
                        />
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 25, marginBottom: 25 }} />
                    <Grid cointainer>
                      <Grid item xs={12}>
                        <Typography variant='h5' style={{ fontWeight: 600}}>Pre-approval letter / Proof of funds</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='subtitle1'>Please add one more file below to continue.</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="dropzone">
                          <div
                            {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                            <input {...getInputProps()} />
                            <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt">
                              {isDragAccept && (
                                <div>
                                  <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg text-white">
                                    <CheckIcon className="d-50" />
                                  </div>
                                  <div className="font-size-xs text-success">
                                    We're ready to start!
                                  </div>
                                </div>
                              )}
                              {isDragReject && (
                                <div>
                                  <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg text-white">
                                    <CloseTwoToneIcon className="d-50" />
                                  </div>
                                  <div className="font-size-xs text-danger">
                                    These files are not images!
                                  </div>
                                </div>
                              )}
                              {!isDragActive && (
                                <div>
                                  <div className="d-90 btn-icon mb-3 hover-scale-rounded bg-white shadow-light-sm rounded-lg text-first">
                                    <CloudUploadTwoToneIcon className="d-40" />
                                  </div>
                                  <div className="font-size-sm">
                                    Drag and drop images here
                                  </div>
                                </div>
                              )}
                              <small className="py-2 text-black-50">
                                or
                              </small>
                              <div>
                                <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                                  <span className="px-2">
                                    Browse Files
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid> */}

                    {/* ******* Financing ******* */}
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      style={{ marginTop: 25 }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: 600 }}
                        >
                          Funding Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          Please fill out the fields below to
                          continue.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          select
                          variant="outlined"
                          margin="dense"
                          value={method}
                          onChange={(event) =>
                            setMethod(event.target.value)
                          }
                          fullWidth={true}
                          label="Method of Funding"
                        >
                          {map(methodOptions, (option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {method !== 'Cash' && (
                        <Grid item xs={12}>
                          {/* <Autocomplete
                            freeSolo
                            inputValue={lender}
                            //value={lender}
                            options={lenderOptions}
                            getOptionLabel={(option) => option.label}
                            //onChange={(event, newValue) => setLender(newValue)}
                            onInputChange={(event, newValue) => setLender(newValue)}
                            renderInput={(params) => <TextField {...params} label="Name of Lender" variant="outlined" margin='dense' />}
                          /> */}
                          <TextField
                            value={lender}
                            onChange={(event) =>
                              setLender(event.target.value)
                            }
                            variant="outlined"
                            margin="dense"
                            fullWidth={true}
                            label="Name of Lender"
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Divider
                      style={{ marginTop: 25, marginBottom: 25 }}
                    />
                    <Grid cointainer>
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: 600 }}
                        >
                          Pre-approval letter / Proof of funds
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          Please add one more file below to continue.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="dropzone">
                          <div
                            {...getRootProps({
                              className: 'dropzone-upload-wrapper',
                            })}
                          >
                            <input {...getInputProps()} />
                            <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt">
                              {isDragAccept && (
                                <div>
                                  <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg text-white">
                                    <CheckIcon className="d-50" />
                                  </div>
                                  <div className="font-size-xs text-success">
                                    We're ready to start!
                                  </div>
                                </div>
                              )}
                              {isDragReject && (
                                <div>
                                  <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg text-white">
                                    <CloseTwoToneIcon className="d-50" />
                                  </div>
                                  <div className="font-size-xs text-danger">
                                    These files are not images!
                                  </div>
                                </div>
                              )}
                              {!isDragActive && (
                                <div>
                                  <div className="d-90 btn-icon mb-3 hover-scale-rounded bg-white shadow-light-sm rounded-lg text-first">
                                    <CloudUploadTwoToneIcon className="d-40" />
                                  </div>
                                  <div className="font-size-sm">
                                    Drag and drop images here
                                  </div>
                                </div>
                              )}
                              <small className="py-2 text-black-50">
                                or
                              </small>
                              <div>
                                <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                                  <span className="px-2">
                                    Browse Files
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {files.length <= 0 ? (
                            <div className="text-info text-center font-size-sm my-4">
                              Uploaded file will appear here!
                            </div>
                          ) : (
                            <div className="font-weight-bold my-4 text-uppercase text-dark font-size-sm text-center">
                              Uploaded File
                            </div>
                          )}
                          {files.length > 0 && (
                            <div>
                              <Alert
                                severity="success"
                                className="text-center mb-3"
                              >
                                You have uploaded{' '}
                                <b>{files.length}</b> file!
                              </Alert>
                              <List
                                component="div"
                                className="font-size-sm"
                              >
                                {files}
                              </List>
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                )}
                {activeStep === 3 && (
                  <Container>
                    <Grid
                      container
                      direction="row"
                      /*spacing={2}*/ style={{ marginTop: 25 }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: 600 }}
                        >
                          Review & Submit
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: 25 }}>
                        <Typography variant="subtitle1">
                          Please verify all the information below is
                          correct before submitting.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: 50 }}>
                        <Card
                          style={{
                            padding: 25,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderColor: 'black',
                            borderWidth: 1,
                          }}
                        >
                          <Grid container direction="column">
                            <Grid item>
                              <Grid
                                container
                                direction="column"
                                alignItems="center"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                <Grid item className="gridItem2">
                                  <Typography
                                    variant="h6"
                                    style={{ fontWeight: 600 }}
                                  >
                                    {address}
                                  </Typography>
                                </Grid>
                                <Grid item className="gridItem2">
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      textAlign: 'right',
                                      color: '#9198a8',
                                    }}
                                  >{`${city}, ${state} ${zip}`}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Divider style={{ marginTop: 15 }} />
                            {map(column1, (section) =>
                              section.type !== 'double' ? (
                                <span>
                                  <Grid item>
                                    <Grid
                                      container
                                      justifyContent="space-between"
                                      alignItems="center"
                                      style={{ height: 70 }}
                                    >
                                      <Grid
                                        item
                                        className="gridItem2"
                                      >
                                        <Typography
                                          variant="h6"
                                          style={{ fontWeight: 600 }}
                                        >
                                          {section.left1}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        className="gridItem2"
                                      >
                                        <Typography
                                          variant="h6"
                                          style={{
                                            textAlign: 'right',
                                            fontWeight: 600,
                                          }}
                                        >
                                          {section.right1}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {section.divider === 'true' && (
                                    <Divider />
                                  )}
                                </span>
                              ) : (
                                <span>
                                  <Grid item>
                                    <Grid
                                      container
                                      justifyContent="space-between"
                                      alignItems="center"
                                      style={{
                                        height: 70,
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      <Grid
                                        item
                                        className="gridItem2"
                                      >
                                        <Grid
                                          container
                                          direction="column"
                                          style={{
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography
                                              variant="h6"
                                              style={{
                                                fontWeight: 600,
                                              }}
                                            >
                                              {section.left1}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography
                                              variant="subtitle2"
                                              style={{
                                                color: '#9198a8',
                                              }}
                                            >
                                              {section.left2}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        item
                                        className="gridItem2"
                                      >
                                        <Grid
                                          container
                                          direction="column"
                                          style={{
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography
                                              variant="h6"
                                              style={{
                                                textAlign: 'right',
                                                fontWeight: 600,
                                              }}
                                            >
                                              {section.right1}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            className="gridItem2"
                                          >
                                            <Typography
                                              variant="subtitle2"
                                              style={{
                                                textAlign: 'right',
                                                color: '#9198a8',
                                              }}
                                            >
                                              {section.right2}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  {section.divider === 'true' && (
                                    <Divider />
                                  )}
                                </span>
                              ),
                            )}
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </Container>
                )}
              </div>
              {/* <div className="card-footer mt-4 p-4 d-flex align-items-center justify-content-between bg-secondary"> */}
              <CardActions className="mt-2">
                <Typography style={{ flex: 1 }} />
                {activeStep !== 0 && (
                  <Button
                    className="btn-primary font-weight-bold"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                )}
                {activeStep !== 3 && (
                  <Button
                    disabled={isDisabled}
                    className="btn-primary font-weight-bold"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
                {activeStep === 3 && (
                  <Button
                    className="btn-primary font-weight-bold"
                    type="submit"
                    onClick={contact}
                  >
                    Finish
                  </Button>
                )}
              </CardActions>
              {/* </div> */}
            </span>
          </div>
        </Card>
      </ValidatorForm>
    </BlockUi>
  )
}

export default withSession(ContactAgentStepperComponent)
