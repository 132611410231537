/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-24 19:59:53
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-04 15:55:55
 * @ Description: All of the assumption fields placed in an accordion
 */

import React, { act, Component } from 'react'

import clsx from 'clsx'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import flatMap from 'lodash/flatMap'
import remove from 'lodash/remove'
import includes from 'lodash/includes'
import startsWith from 'lodash/startsWith'
import set from 'lodash/set'
import get from 'lodash/get'
import find from 'lodash/find'
import filter from 'lodash/filter'
import concat from 'lodash/concat'
import round from 'lodash/round'
import isNil from 'lodash/isNil'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Button,
  Card,
  Collapse,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Dialog,
  Grid,
  InputAdornment,
  List,
  ListItem,
  Menu,
  MenuItem,
  Slider,
  Tooltip,
  Typography,
  Checkbox,
  TextField,
} from '@mui/material'
import { NumericFormat } from 'react-number-format'
import { Fragment } from 'react'
import RemoveOption from '@/housefolios-components/Dialogs/RemoveOption'
import AddOption from '@/housefolios-components/Dialogs/AddOption'
import withSession from '@/housefolios-components/Session/withSession'
import Autocomplete from '@mui/material/Autocomplete'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { getStrategyName } from '@/utils/stratagies'

export const assumptionsOptions = (activeOrg) => {
  return {
    strategySpecificFields: [
      {
        value: 'afterRepairValue',
        label: 'ARV',
      },
    ],
    rentTypes: [
      {
        value: 'singleRent',
        label: 'Single Rent',
      },
      {
        value: 'rentRange',
        label: 'Rent Range',
      },
    ],
    rehabOptions: filter(
      [
        {
          value: 'singleRehabValue',
          label: 'Single Rehab Value',
        },
        {
          value: 'rehabTier',
          label: 'Rehab Tier',
        },
        {
          value: 'detailedRehab',
          label: 'Detailed Rehab',
          hide: activeOrg.isRealeflow,
        },
        {
          value: 'rehabCalc',
          label: 'Detailed Rehab',
          hide: !activeOrg.isRealeflow,
        },

        // {
        //   value: 'flippingAmericaRehab',
        //   label: 'Flipping America Rehab',
        // },
      ],
      (option) => !option.hide,
    ),
  }
}

class AssumptionsSettingsAccordion extends Component {
  constructor(props) {
    super(props)
    this.toggleAccordion = this.toggleAccordion.bind(this)
    this.state = {
      accordion: 0,
      showAcquisitionAdvanced: false,
      showFormatDropdown: null,
      anchorEl: null,
      modal1: null,
    }
  }

  toggleMenuFormat = (event, name) => {
    this.setState({
      anchorEl: event.target,
      showFormatDropdown: name,
    })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  toggle1 = (name) => {
    this.setState({ modal1: name })
  }

  toggle1Close = () => {
    this.setState({ modal1: null })
  }

  toggleAccordion(tab) {
    const prevState = this.state.accordion
    const state = prevState === tab ? null : tab

    this.setState({
      accordion: state,
    })
  }

  handleChange = (event) => {
    const { assumptions, setAssumptions, setIsChanged } = this.props
    const { name, value, type } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(newValue)
    else if (type === 'text') newValue = String(newValue)
    let newAssumptions = { ...assumptions }
    set(newAssumptions, name, newValue)
    setAssumptions(newAssumptions)
    if (setIsChanged) setIsChanged(true)
  }

  handleAdornmentChange = (e, name, value) => {
    e.preventDefault()
    const { assumptions, setAssumptions, setIsChanged } = this.props
    let newAssumptions = { ...assumptions }
    set(newAssumptions, name, value)
    setAssumptions(newAssumptions)
    if (setIsChanged) setIsChanged(true)
    this.handleClose()
  }

  handleCheckbox = (event) => {
    const { assumptions, setAssumptions, setIsChanged } = this.props
    const { name } = event.target
    let newAssumptions = { ...assumptions }
    set(newAssumptions, name, !get(newAssumptions, name))
    setAssumptions(newAssumptions)
    if (setIsChanged) setIsChanged(true)
  }

  handleChangeSlider = (event, value, name) => {
    const { assumptions, setAssumptions, setIsChanged } = this.props
    let newAssumptions = { ...assumptions }
    set(newAssumptions, name, Number(value))
    setAssumptions(newAssumptions)
    if (setIsChanged) setIsChanged(true)
  }

  renderInputFields = (
    field /*anchorEl, saveAssumptions, downPayment*/,
  ) => {
    const marks = [
      {
        value: field.min,
        label: field.minLabel,
      },
      {
        value: field.max,
        label: field.maxLabel,
      },
    ]

    const { anchorEl, showFormatDropdown } = this.state

    return field.dropdown === true ? (
      <Grid item xs={12} md={6}>
        <NumericFormat
          customInput={TextField}
          thousandSeparator={field.adornment === '$' ? true : false}
          prefix={field.adornment === '$' ? '$' : ''}
          suffix={field.adornment === '%' ? '%' : ''}
          fullWidth={true}
          isNumericString={true}
          // decimalScale={0}
          key={field.name}
          name={field.name}
          value={field.value || ''}
          onValueChange={(values) => {
            const { value } = values
            // console.log(value)
            const event = {
              target: {
                name: field.name,
                value: value,
                type: 'number',
              },
              preventDefault: () => {
                return null
              },
            }
            if (Number(value) !== field.value) {
              if (field.onChange) field.onChange(event)
              else this.handleChange(event)
            }
          }}
          type={'text'}
          onFocus={(event) => {
            event.target.select()
          }}
          variant="outlined"
          label={field.label}
          placeholder={field.label}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" margin="dense">
                <Button
                  onClick={(e) =>
                    this.toggleMenuFormat(e, field.adornmentName)
                  }
                  size="small"
                  margin="dense"
                  style={{ padding: 3 }}
                  className="btn-neutral-dark d-flex align-items-center"
                >
                  <span className="btn-wrapper--label">
                    {field.adornment}
                  </span>
                  <span className="btn-wrapper--icon d-flex">
                    <FontAwesomeIcon
                      icon={['fas', 'chevron-down']}
                      className="opacity-8 font-size-xs ml-1"
                    />
                  </span>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  name={field.adornmentName}
                  open={
                    Boolean(anchorEl) &&
                    field.adornmentName === showFormatDropdown
                  }
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                  classes={{ list: 'p-0' }}
                >
                  <div className="p-2">
                    <List
                      component="div"
                      className="nav-pills p-0 m-0 nav-neutral-dark flex-column"
                    >
                      <ListItem
                        button
                        href={null}
                        onClick={(e) =>
                          this.handleAdornmentChange(
                            e,
                            field.adornmentName,
                            includes(field.adornmentName, 'term') ||
                              includes(field.adornmentName, 'Term')
                              ? 'Months'
                              : includes(
                                    field.adornmentName,
                                    'estimatedRehab',
                                  )
                                ? 'SQFT $'
                                : '$',
                          )
                        }
                        selected={
                          field.adornment === '$' ||
                          field.adornment === 'SQFT $' ||
                          field.adornment === 'Months'
                        }
                        className="px-3 mx-2"
                      >
                        <span>
                          {includes(field.adornmentName, 'term') ||
                          includes(field.adornmentName, 'Term')
                            ? 'Months'
                            : includes(
                                  field.adornmentName,
                                  'estimatedRehab',
                                )
                              ? 'SQFT $'
                              : '$'}
                        </span>
                      </ListItem>
                      <ListItem
                        button
                        href={null}
                        onClick={(e) =>
                          this.handleAdornmentChange(
                            e,
                            field.adornmentName,
                            includes(field.adornmentName, 'term') ||
                              includes(field.adornmentName, 'Term')
                              ? 'Years'
                              : includes(
                                    field.adornmentName,
                                    'estimatedRehab',
                                  )
                                ? 'Total $'
                                : '%',
                          )
                        }
                        selected={
                          field.adornment === '%' ||
                          field.adornment === 'Total $' ||
                          field.adornment === 'Years'
                        }
                        className="px-3 mx-2"
                      >
                        <span>
                          {includes(field.adornmentName, 'term') ||
                          includes(field.adornmentName, 'Term')
                            ? 'Years'
                            : includes(
                                  field.adornmentName,
                                  'estimatedRehab',
                                )
                              ? 'Total $'
                              : '%'}
                        </span>
                      </ListItem>
                      {field.adornmentName ===
                        'estimatedRentType' && (
                        <ListItem
                          button
                          href={null}
                          onClick={(e) =>
                            this.handleAdornmentChange(
                              e,
                              field.adornmentName,
                              'Rent/Sqft',
                            )
                          }
                          selected={field.adornment === 'Rent/Sqft'}
                          className="px-3 mx-2"
                        >
                          <span>{'Rent/Sqft'}</span>
                        </ListItem>
                      )}
                    </List>
                  </div>
                </Menu>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    ) : field.sectionName === 'Targets' ? (
      <>
        <ListItem
          component="a"
          button
          href={null}
          disableRipple
          onClick={() => this.toggle1(field.name)}
          className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography style={{ marginBottom: -25 }}>
                {field.label}
              </Typography>
              <Slider
                className="slider-primary my-3"
                track="inverted"
                valueLabelDisplay="auto"
                onClick={(event) => event.stopPropagation()}
                //getAriaValueText={field.label}
                onChange={(event, value) => {
                  this.handleChangeSlider(event, value, field.name)
                }}
                value={Number(field.value)}
                min={field.min}
                max={field.max}
                step={field.step ? field.step : 1}
                valueLabelFormat={
                  field.valueLabelFormat
                    ? field.valueLabelFormat
                    : (x) => (x === field.max ? `${x}+` : `${x}%`)
                }
                marks={marks}
              />
            </Grid>
          </Grid>
        </ListItem>
        <Dialog
          open={this.state.modal1 === field.name}
          onClose={this.toggle1Close}
          maxWidth="lg"
          scroll="body"
          classes={{
            paper:
              'modal-content rounded border-0 bg-white p-3 p-xl-0',
          }}
        >
          {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
          <Grid container spacing={0} direction="row">
            <Grid item sm={5}>
              <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                  <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                    <div className="text-white mt-3">
                      <Typography
                        style={{ fontSize: 30, fontWeight: 600 }}
                      >
                        Calculation
                      </Typography>
                    </div>
                    {field.calculation}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item sm={7}>
              <div className="hero-wrapper h-100 br-xl-right-0">
                <Card className="flex-grow-1 w-100">
                  <CardHeader title={field.label} />
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={12} style={{ marginBottom: 25 }}>
                        <Card
                          style={{
                            backgroundColor: '#eff2f5',
                            borderRadius: 0,
                          }}
                        >
                          <CardContent>
                            <Typography>{field.tip}</Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography style={{ marginBottom: -25 }}>
                          {field.label}
                        </Typography>
                        <Slider
                          className="slider-primary my-3"
                          track="inverted"
                          valueLabelDisplay="auto"
                          //getAriaValueText={field.label}
                          onChange={(event, value) => {
                            this.handleChangeSlider(
                              event,
                              value,
                              field.name,
                            )
                          }}
                          value={Number(field.value)}
                          min={field.min}
                          max={field.max}
                          step={field.step ? field.step : 1}
                          valueLabelFormat={
                            field.valueLabelFormat
                              ? field.valueLabelFormat
                              : (x) =>
                                  x === field.max ? `${x}+` : `${x}%`
                          }
                          marks={marks}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.toggle1Close}
                        >
                          Close
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </div>
            </Grid>
          </Grid>
          {/* </BlockUi> */}
        </Dialog>
      </>
    ) : field.type === 'autocomplete' ? (
      <Grid item xs={12} md={6}>
        <Autocomplete
          id="checkboxes-tags-demo"
          multiple
          options={map(field.options, (option) => option.value)}
          getOptionLabel={(option) => {
            return (
              find(
                field.options,
                (field) => field.value === option,
              ) || {}
            ).label
          }}
          value={field.value}
          onChange={(e, newValue) => {
            const event = {
              target: {
                name: field.name,
                value: newValue,
                type: 'array',
              },
              preventDefault: () => {
                return null
              },
            }
            field.onChange
              ? field.onChange(event)
              : this.handleChange(event)
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {
                (
                  find(
                    field.options,
                    (field) => field.value === option,
                  ) || {}
                ).label
              }
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={field.label}
              placeholder={field.label}
              margin="dense"
            />
          )}
        />
      </Grid>
    ) : field.checkbox ? (
      <Grid item xs={12} md={6}>
        <Grid container direction="row" alignItems="center">
          <Grid item className="gridItem2">
            <Checkbox
              color="primary"
              key={field.name + '_key'}
              name={field.name}
              type="checkbox"
              value={field.value}
              checked={Boolean(field.value)}
              onChange={
                field.onChange ? field.onChange : this.handleCheckbox
              }
            />
          </Grid>
          <Grid item className="gridItem2" style={{ fontSize: 14 }}>
            {field.label}
          </Grid>
        </Grid>
      </Grid>
    ) : field.adornment === '$' ? (
      <Grid item xs={12} md={6}>
        <NumericFormat
          customInput={TextField}
          thousandSeparator={true}
          prefix={'$'}
          fullWidth={true}
          isNumericString={true}
          decimalScale={2}
          key={field.name}
          name={field.name}
          value={round(field.value, 2) || ''}
          onValueChange={(values) => {
            const { value } = values
            const event = {
              target: {
                name: field.name,
                value: value,
                type: 'number',
              },
              preventDefault: () => {
                return null
              },
            }
            if (Number(value) !== field.value) {
              if (field.onChange) field.onChange(event)
              else this.handleChange(event)
            }
          }}
          type={'text'}
          onFocus={(event) => {
            event.target.select()
          }}
          variant="outlined"
          label={field.label}
        />
      </Grid>
    ) : field.options ? (
      <Grid item xs={12} md={6}>
        <TextField
          name={field.name}
          variant="outlined"
          //  margin="dense"
          type={field.type}
          style={{ marginTop: 0 }}
          value={
            includes(
              concat(
                field.options,
                field.memberOptions,
                field.dynamicMemberOptions,
              ),
              field.value,
            ) ||
            includes(
              concat(
                field.options,
                field.memberOptions,
                field.dynamicMemberOptions,
              ),
              Number(field.value),
            ) ||
            find(
              concat(
                field.options,
                field.memberOptions,
                field.dynamicMemberOptions,
              ),
              { value: field.value },
            )
              ? field.value
              : find(
                    concat(
                      field.options,
                      field.memberOptions,
                      field.dynamicMemberOptions,
                    ),
                    { value: Number(field.value) },
                  )
                ? Number(field.value)
                : Number(field.value)
          }
          onChange={
            field.onChange
              ? field.onChange
              : (event) =>
                  this.handleChange({
                    target: { ...event.target, type: field.type },
                  })
          }
          placeholder={field.label}
          label={field.label}
          select
          fullWidth={true}
          SelectProps={{
            renderValue: (x) => {
              let option = find(
                concat(
                  field.options,
                  field.memberOptions,
                  field.dynamicMemberOptions,
                ),
                { value: x },
              )
              if (option)
                return option.active || isNil(option.active)
                  ? option.label
                  : `Deactivated - ${option.label}`
              else if (
                includes(
                  concat(
                    field.options,
                    field.memberOptions,
                    field.dynamicMemberOptions,
                  ),
                  x,
                )
              )
                return x
              else if (!x) return ''
              else return 'Deleted Option - ' + x
            },
          }}
        >
          {map(
            filter(field.dynamicMemberOptions, (option) => {
              return (
                option.active ||
                isNil(option.active) ||
                option === field.value ||
                option.value === field.value ||
                Number(option.value) === field.value
              )
            }),
            (option) =>
              option.tip || option.title ? (
                <Tooltip
                  title={option.tip}
                  key={option.title}
                  value={option.value}
                >
                  <MenuItem>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography>{option.title}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>$/Sqft Varies</Typography>
                      </Grid>
                      <Grid item>
                        <Typography>MO</Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                </Tooltip>
              ) : (
                <MenuItem key={option.value} value={option.value}>
                  <Grid container alignItems="center">
                    <Grid item>{option.label}</Grid>
                    <Typography style={{ flex: 1 }} />
                    <Grid item>
                      <Typography>MO</Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ),
          )}
          {map(
            filter(field.memberOptions, (option) => {
              return (
                option.active ||
                isNil(option.active) ||
                option === field.value ||
                option.value === field.value ||
                Number(option.value) === field.value
              )
            }),
            (option) =>
              option.value ? (
                option.tip || option.title ? (
                  <Tooltip
                    title={option.tip}
                    key={option.value}
                    value={option.value}
                  >
                    <MenuItem>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography>{option.title}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>{option.label}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>MO</Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Tooltip>
                ) : (
                  <MenuItem key={option.value} value={option.value}>
                    <Grid container alignItems="center">
                      <Grid item>{option.label}</Grid>
                      <Typography style={{ flex: 1 }} />
                      <Grid item>
                        <Typography>MO</Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                )
              ) : (
                <MenuItem key={option} value={option}>
                  <Grid container alignItems="center">
                    <Grid item>{option}</Grid>
                    <Typography style={{ flex: 1 }} />
                    <Grid item>
                      <Typography>MO</Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ),
          )}
          {map(
            filter(field.options, (option) => {
              return (
                option.active ||
                isNil(option.active) ||
                option === field.value ||
                option.value === field.value ||
                Number(option.value) === Number(field.value)
              )
            }),
            (option) =>
              option.value ? (
                option.tip || option.title ? (
                  <Tooltip
                    title={option.tip}
                    key={option.value}
                    value={option.value}
                  >
                    <MenuItem value={option.value}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography>{option.title}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>{option.label}</Typography>
                        </Grid>
                        {field.removeOption &&
                          (option.active || isNil(option.active)) && (
                            <Grid item>
                              <RemoveOption
                                removeOption={field.optionName}
                                removeOptionLabel={field.label}
                                removeOptionValue={option.value}
                                removeOptionItemLabel={option.title}
                              />
                            </Grid>
                          )}
                      </Grid>
                    </MenuItem>
                  </Tooltip>
                ) : (
                  <MenuItem key={option.value} value={option.value}>
                    <Grid container alignItems="center">
                      <Grid item>{option.label}</Grid>
                      <Typography style={{ flex: 1 }} />
                      {field.removeOption &&
                        (option.active || isNil(option.active)) && (
                          <RemoveOption
                            removeOption={field.optionName}
                            removeOptionLabel={field.label}
                            removeOptionValue={option.value}
                            removeOptionItemLabel={option.label}
                          />
                        )}
                    </Grid>
                  </MenuItem>
                )
              ) : (
                <MenuItem key={option} value={option}>
                  <Grid container alignItems="center">
                    <Grid item>{option}</Grid>
                    <Typography style={{ flex: 1 }} />
                    {field.removeOption &&
                      (option.active || isNil(option.active)) && (
                        <RemoveOption
                          removeOption={field.optionName}
                          removeOptionLabel={field.label}
                          removeOptionValue={option}
                        />
                      )}
                  </Grid>
                </MenuItem>
              ),
          )}
          {field.optionName && (
            <AddOption
              currentValue={
                includes(field.options, field.value) ||
                find(field.options, { value: field.value })
                  ? field.value
                  : ''
              }
              addOption={field.optionName}
              addOptionLabel={field.label}
              addOptionTarget={field.name}
              onChangeAssumptions={
                field.onChange ? field.onChange : this.handleChange
              }
            />
          )}
        </TextField>
      </Grid>
    ) : (
      <Grid item xs={12} md={6}>
        <TextField
          name={field.name}
          variant="outlined"
          //margin="dense"
          value={field.value || ''}
          onChange={
            field.onChange ? field.onChange : this.handleChange
          }
          placeholder={field.label}
          label={field.label}
          type="number"
          fullWidth={true}
        />
      </Grid>
    )
  }

  render() {
    const { showAcquisitionAdvanced } = this.state
    const { assumptions } = this.props
    const { rehabTiers, memberRehabTiers, dynamicMemberRehabTiers } =
      this.props?.session.me.activeOrg
    let fieldSections = [
      {
        sectionName: 'Acquisition',
        description: 'Acquisition panel',
        panel: 0,
        fields: [
          {
            dropdown: true,
            label: 'Closing Cost',
            adornmentToggle: assumptions.closingCostType,
            adornment: assumptions.closingCostType,
            adornmentName: 'closingCostType',
            adornmentPosition: 'start',
            name: 'closingCost',
            value: assumptions.closingCost,
            tip: 'Fees (attorney/title fees, etc) Paid at Closing. Expressed as a dollar amount or precentage of the purchase prices.',
            filters: ['Buy & Hold'],
          },
          {
            dropdown: true,
            label: 'Closing Cost',
            adornmentToggle: assumptions.closingCostTurnkeyType,
            adornment: assumptions.closingCostTurnkeyType,
            adornmentName: 'closingCostTurnkeyType',
            adornmentPosition: 'start',
            name: 'closingCostTurnkey',
            value: assumptions.closingCostTurnkey,
            tip: 'Fees (attorney/title fees, etc) Paid at Closing. Expressed as a dollar amount or precentage of the purchase prices.',
            filters: ['Turnkey End Buyer'],
          },
          {
            dropdown: true,
            label: 'Closing Cost',
            adornmentToggle: assumptions.closingCostFixFlipType,
            adornment: assumptions.closingCostFixFlipType,
            adornmentName: 'closingCostFixFlipType',
            adornmentPosition: 'start',
            name: 'closingCostFixFlip',
            value: assumptions.closingCostFixFlip,
            tip: 'Fees (attorney/title fees, etc) Paid at Closing. Expressed as a dollar amount or precentage of the purchase prices.',
            filters: ['Fix & Flip'],
          },
          {
            dropdown: true,
            label: 'Closing Cost',
            adornmentToggle: assumptions.closingCostBRRRRType,
            adornment: assumptions.closingCostBRRRRType,
            adornmentName: 'closingCostBRRRRType',
            adornmentPosition: 'start',
            name: 'closingCostBRRRR',
            value: assumptions.closingCostBRRRR,
            tip: 'Fees (attorney/title fees, etc) Paid at Closing. Expressed as a dollar amount or precentage of the purchase prices.',
            filters: ['BRRRR'],
          },
          {
            dropdown: true,
            label: 'Closing Cost',
            adornmentToggle: assumptions.closingCostSTRType,
            adornment: assumptions.closingCostSTRType,
            adornmentName: 'closingCostSTRType',
            adornmentPosition: 'start',
            name: 'closingCostSTR',
            value: assumptions.closingCostSTR,
            tip: 'Fees (attorney/title fees, etc) Paid at Closing. Expressed as a dollar amount or precentage of the purchase prices.',
            filters: ['Short Term Rental'],
          },
          {
            dropdown: true,
            label: 'Closing Cost',
            adornmentToggle: assumptions.closingCostBORType,
            adornment: assumptions.closingCostBORType,
            adornmentName: 'closingCostBORType',
            adornmentPosition: 'start',
            name: 'closingCostBOR',
            value: assumptions.closingCostBOR,
            tip: 'Fees (attorney/title fees, etc) Paid at Closing. Expressed as a dollar amount or precentage of the purchase prices.',
            filters: ['BOR'],
          },
          {
            label: 'Credit from Seller',
            adornmentToggle: '',
            adornment: '$',
            adornmentPosition: 'start',
            name: 'creditFromSeller',
            value: assumptions.creditFromSeller,
            tip: 'A seller credit is the dollar amount the seller will put towards your closing costs.',
            filters: [
              'Buy & Hold',
              'Turnkey End Buyer',
              'Fix & Flip',
              'BRRRR',
              'Short Term Rental',
            ],
          },
          {
            checkbox: true,
            label: 'Show Advanced Fields',
            name: 'showAcquisitionAdvanced',
            value: showAcquisitionAdvanced,
            filters: [
              'Buy & Hold',
              'Turnkey End Buyer',
              'Fix & Flip',
              'BRRRR',
              'Short Term Rental',
              'Build to Rent',
            ],
            onChange: () =>
              this.setState({
                showAcquisitionAdvanced: !showAcquisitionAdvanced,
              }),
          },
          {
            dropdown: true,
            label: 'Acquisition Commission',
            adornmentToggle: assumptions.acquisitionCommissionType,
            adornment: assumptions.acquisitionCommissionType,
            adornmentName: 'acquisitionCommissionType',
            adornmentPosition: 'start',
            name: 'acquisitionCommission',
            value: assumptions.acquisitionCommission,
            tip: 'Advanced Fee - Used in addition to sales of typical sales commissions expressed as a percentage of the Purchase price or a fixed dollar amount.',
            filters: [
              'Buy & Hold',
              'Turnkey End Buyer',
              'Fix & Flip',
              'BRRRR',
              'Short Term Rental',
            ],
            acquisitionAdvanced: true,
          },
          {
            label: 'Acquisition Fee',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'acquisitionFee',
            value: assumptions.acquisitionFee,
            //FOR PERCENTAGE
            //tip: 'Advanced Fee - Used in addition to sales of typical sales commissions expressed as a percentage of the purchase price.',
            tip: 'Advanced Fee - Used in addition to sales of typical sales commissions expressed as a fixed dollar amount',
            filters: [
              'Buy & Hold',
              'Turnkey End Buyer',
              'Fix & Flip',
              'BRRRR',
              'Short Term Rental',
            ],
            acquisitionAdvanced: true,
          },
          // {
          //   label: 'Asset Mgmt Fee',
          //   adornment: '%',
          //   adornmentToggle: '',
          //   adornmentPosition: 'start',
          //   name: 'assetManagementFee',
          //   value: assumptions.assetManagementFee,
          //   tip:
          //     'Advanced Fee - Sometimes used as a percentage of total equity invested.',
          //   filters: ['Buy & Hold', 'Turnkey End Buyer', 'Fix & Flip', 'Wholesale'],
          //   acquisitionAdvanced: true,
          // },
          {
            dropdown: true,
            label: 'Financing Fee',
            adornmentToggle: assumptions.financingFeeType,
            adornment: assumptions.financingFeeType,
            adornmentName: 'financingFeeType',
            adornmentPosition: 'start',
            name: 'financingFee',
            value: assumptions.financingFee,
            tip: 'Advanced Fee - A fee that the real estate professional charges for securing financing on the property. Expressed as a percentage of the loan amount.',
            filters: ['Buy & Hold'],
            acquisitionAdvanced: true,
          },
          {
            dropdown: true,
            label: 'Financing Fee',
            adornmentToggle: assumptions.financingFeeTurnkeyType,
            adornment: assumptions.financingFeeTurnkeyType,
            adornmentName: 'financingFeeTurnkeyType',
            adornmentPosition: 'start',
            name: 'financingFeeTurnkey',
            value: assumptions.financingFeeTurnkey,
            tip: 'Advanced Fee - A fee that the real estate professional charges for securing financing on the property. Expressed as a percentage of the loan amount.',
            filters: ['Turnkey End Buyer'],
            acquisitionAdvanced: true,
          },
          {
            dropdown: true,
            label: 'Financing Fee',
            adornmentToggle: assumptions.financingFeeFixFlipType,
            adornment: assumptions.financingFeeFixFlipType,
            adornmentName: 'financingFeeFixFlipType',
            adornmentPosition: 'start',
            name: 'financingFeeFixFlip',
            value: assumptions.financingFeeFixFlip,
            tip: 'Advanced Fee - A fee that the real estate professional charges for securing financing on the property. Expressed as a percentage of the loan amount.',
            filters: ['Fix & Flip'],
            acquisitionAdvanced: true,
          },
          {
            dropdown: true,
            label: 'Financing Fee',
            adornmentToggle: assumptions.financingFeeBRRRRType,
            adornment: assumptions.financingFeeBRRRRType,
            adornmentName: 'financingFeeBRRRRType',
            adornmentPosition: 'start',
            name: 'financingFeeBRRRR',
            value: assumptions.financingFeeBRRRR,
            tip: 'Advanced Fee - A fee that the real estate professional charges for securing financing on the property. Expressed as a percentage of the loan amount.',
            filters: ['BRRRR'],
            acquisitionAdvanced: true,
          },
          {
            dropdown: true,
            label: 'Financing Fee',
            adornmentToggle: assumptions.financingFeeSTRType,
            adornment: assumptions.financingFeeSTRType,
            adornmentName: 'financingFeeSTRType',
            adornmentPosition: 'start',
            name: 'financingFeeSTR',
            value: assumptions.financingFeeSTR,
            tip: 'Advanced Fee - A fee that the real estate professional charges for securing financing on the property. Expressed as a percentage of the loan amount.',
            filters: ['Short Term Rental'],
            acquisitionAdvanced: true,
          },
          {
            dropdown: true,
            label: 'Financing Fee',
            adornmentToggle: assumptions.financingFeeB2RType,
            adornment: assumptions.financingFeeB2RType,
            adornmentName: 'financingFeeB2RType',
            adornmentPosition: 'start',
            name: 'financingFeeB2R',
            value: assumptions.financingFeeB2R,
            tip: 'Advanced Fee - A fee that the real estate professional charges for securing financing on the property. Expressed as a percentage of the loan amount.',
            filters: ['Build to Rent'],
            acquisitionAdvanced: true,
          },
          {
            label: 'One-Time Owner Reserve',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'ownerReserves',
            value: assumptions.ownerReserves,
            tip: 'A one-time reserve you set aside (ex: $5,000) to cover unforeseen repairs, etc. This amount is in addition to the monthly capex (reserve) you set aside each month.',
            filters: ['Buy & Hold'],
            acquisitionAdvanced: true,
          },
          {
            label: 'One-Time Owner Reserve',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'ownerReservesTurnkey',
            value: assumptions.ownerReservesTurnkey,
            tip: 'A one-time reserve you set aside (ex: $5,000) to cover unforeseen repairs, etc. This amount is in addition to the monthly capex (reserve) you set aside each month.',
            filters: ['Turnkey End Buyer'],
            acquisitionAdvanced: true,
          },
          {
            label: 'One-Time Owner Reserve',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'ownerReservesFixFlip',
            value: assumptions.ownerReservesFixFlip,
            tip: 'A one-time reserve you set aside (ex: $5,000) to cover unforeseen repairs, etc. This amount is in addition to the monthly capex (reserve) you set aside each month.',
            filters: ['Fix & Flip'],
            acquisitionAdvanced: true,
          },
          {
            label: 'One-Time Owner Reserve',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'ownerReservesSTR',
            value: assumptions.ownerReservesSTR,
            tip: 'A one-time reserve you set aside (ex: $5,000) to cover unforeseen repairs, etc. This amount is in addition to the monthly capex (reserve) you set aside each month.',
            filters: ['Short Term Rental'],
            acquisitionAdvanced: true,
          },
          {
            label: 'One-Time Owner Reserve',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'ownerReservesB2R',
            value: assumptions.ownerReservesB2R,
            tip: 'A one-time reserve you set aside (ex: $5,000) to cover unforeseen repairs, etc. This amount is in addition to the monthly capex (reserve) you set aside each month.',
            filters: ['Build to Rent'],
            acquisitionAdvanced: true,
          },
          {
            label: 'One-Time Owner Reserve',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'ownerReservesBRRRR',
            value: assumptions.ownerReservesBRRRR,
            tip: 'A one-time reserve you set aside (ex: $5,000) to cover unforeseen repairs, etc. This amount is in addition to the monthly capex (reserve) you set aside each month.',
            filters: ['BRRRR'],
            acquisitionAdvanced: true,
          },
          {
            type: 'autocomplete',
            label: 'Strategy Specific Fields',
            name: 'strategySpecificFields',
            value: assumptions.strategySpecificFields || [],
            // tip: 'Select fields to use different values per strategy instead of using a universal value',
            options: assumptionsOptions(
              this.props?.session.me.activeOrg,
            )?.strategySpecificFields,
            filters: [
              'Buy & Hold',
              'Turnkey End Buyer',
              'Fix & Flip',
              'BRRRR',
              'Short Term Rental',
              'Wholesale',
              'Build to Rent',
              'BOR',
              'Astroflip',
            ],
          },
        ],
      },
      {
        sectionName: 'Expense',
        description: 'Recurring Expenses panel',
        panel: 1,
        fields: [
          {
            dropdown: true,
            label: 'Annual Property Insurance',
            adornmentToggle: assumptions.propertyInsuranceType,
            adornment: assumptions.propertyInsuranceType,
            adornmentName: 'propertyInsuranceType',
            adornmentPosition: 'start',
            name: 'propertyInsurance',
            value: assumptions.propertyInsurance,
            tip: 'Annual property insurance premium expressed as a dollar amount or percentage of the purchase price.',
            filters: ['Buy & Hold'],
          },
          {
            dropdown: true,
            label: 'Annual Property Insurance',
            adornmentToggle: assumptions.propertyInsuranceTurnkeyType,
            adornment: assumptions.propertyInsuranceTurnkeyType,
            adornmentName: 'propertyInsuranceTurnkeyType',
            adornmentPosition: 'start',
            name: 'propertyInsuranceTurnkey',
            value: assumptions.propertyInsuranceTurnkey,
            tip: 'Annual property insurance premium expressed as a dollar amount or percentage of the purchase price.',
            filters: ['Turnkey End Buyer'],
          },
          {
            dropdown: true,
            label: 'Annual Property Insurance',
            adornmentToggle: assumptions.propertyInsuranceFixFlipType,
            adornment: assumptions.propertyInsuranceFixFlipType,
            adornmentName: 'propertyInsuranceFixFlipType',
            adornmentPosition: 'start',
            name: 'propertyInsuranceFixFlip',
            value: assumptions.propertyInsuranceFixFlip,
            tip: 'Annual property insurance premium expressed as a dollar amount or percentage of the purchase price.',
            filters: ['Fix & Flip'],
          },
          {
            dropdown: true,
            label: 'Annual Property Insurance',
            adornmentToggle: assumptions.propertyInsuranceBRRRRType,
            adornment: assumptions.propertyInsuranceBRRRRType,
            adornmentName: 'propertyInsuranceBRRRRType',
            adornmentPosition: 'start',
            name: 'propertyInsuranceBRRRR',
            value: assumptions.propertyInsuranceBRRRR,
            tip: 'Annual property insurance premium expressed as a dollar amount or percentage of the purchase price.',
            filters: ['BRRRR'],
          },
          {
            dropdown: true,
            label: 'Annual Property Insurance',
            adornmentToggle: assumptions.propertyInsuranceSTRType,
            adornment: assumptions.propertyInsuranceSTRType,
            adornmentName: 'propertyInsuranceSTRType',
            adornmentPosition: 'start',
            name: 'propertyInsuranceSTR',
            value: assumptions.propertyInsuranceSTR,
            tip: 'Annual property insurance premium expressed as a dollar amount or percentage of the purchase price.',
            filters: ['Short Term Rental'],
          },
          {
            dropdown: true,
            label: 'Annual Property Insurance',
            adornmentToggle: assumptions.propertyInsuranceB2RType,
            adornment: assumptions.propertyInsuranceB2RType,
            adornmentName: 'propertyInsuranceB2RType',
            adornmentPosition: 'start',
            name: 'propertyInsuranceB2R',
            value: assumptions.propertyInsuranceB2R,
            tip: 'Annual property insurance premium expressed as a dollar amount or percentage of the purchase price.',
            filters: ['Build to Rent'],
          },
          {
            dropdown: true,
            label: 'Property Mgmt Fee',
            adornmentToggle: assumptions.propertyManagementType,
            adornment: assumptions.propertyManagementType,
            adornmentName: 'propertyManagementType',
            adornmentPosition: 'start',
            name: 'propertyManagement',
            value: assumptions.propertyManagement,
            tip: 'Property management fee expressed as a dollar amount or percentage of Gross Rental Income (rent - vacancy).',
            filters: ['Buy & Hold'],
          },
          {
            dropdown: true,
            label: 'Property Mgmt Fee',
            adornmentToggle:
              assumptions.propertyManagementFixFlipType,
            adornment: assumptions.propertyManagementFixFlipType,
            adornmentName: 'propertyManagementFixFlipType',
            adornmentPosition: 'start',
            name: 'propertyManagementFixFlip',
            value: assumptions.propertyManagementFixFlip,
            tip: 'Property management fee expressed as a dollar amount or percentage of Gross Rental Income (rent - vacancy).',
            filters: ['Fix & Flip'],
          },
          {
            dropdown: true,
            label: 'Property Mgmt Fee',
            adornmentToggle:
              assumptions.propertyManagementTurnkeyType,
            adornment: assumptions.propertyManagementTurnkeyType,
            adornmentName: 'propertyManagementTurnkeyType',
            adornmentPosition: 'start',
            name: 'propertyManagementTurnkey',
            value: assumptions.propertyManagementTurnkey,
            tip: 'Property management fee expressed as a dollar amount or percentage of Gross Rental Income (rent - vacancy).',
            filters: ['Turnkey End Buyer'],
          },
          {
            dropdown: true,
            label: 'Property Mgmt Fee',
            adornmentToggle: assumptions.propertyManagementBRRRRType,
            adornment: assumptions.propertyManagementBRRRRType,
            adornmentName: 'propertyManagementBRRRRType',
            adornmentPosition: 'start',
            name: 'propertyManagementBRRRR',
            value: assumptions.propertyManagementBRRRR,
            tip: 'Property management fee expressed as a dollar amount or percentage of Gross Rental Income (rent - vacancy).',
            filters: ['BRRRR'],
          },
          {
            dropdown: true,
            label: 'Property Mgmt Fee',
            adornmentToggle: assumptions.propertyManagementB2RType,
            adornment: assumptions.propertyManagementB2RType,
            adornmentName: 'propertyManagementB2RType',
            adornmentPosition: 'start',
            name: 'propertyManagementB2R',
            value: assumptions.propertyManagementB2R,
            tip: 'Property management fee expressed as a dollar amount or percentage of Gross Rental Income (rent - vacancy).',
            filters: ['Build to Rent'],
          },
          {
            label: 'Annual Maintenance',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'maintenance',
            value: assumptions.maintenance,
            tip: 'The annual cost of maintenance repairs to the property expressed as a percentage of Gross Rental Income (rent - vacancy).',
            filters: ['Buy & Hold'],
          },
          {
            label: 'Annual Maintenance',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'maintenanceTurnkey',
            value: assumptions.maintenanceTurnkey,
            tip: 'The annual cost of maintenance repairs to the property expressed as a percentage of Gross Rental Income (rent - vacancy).',
            filters: ['Turnkey End Buyer'],
          },
          {
            label: 'Annual Maintenance',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'maintenanceFixFlip',
            value: assumptions.maintenanceFixFlip,
            tip: 'The annual cost of maintenance repairs to the property expressed as a percentage of Gross Rental Income (rent - vacancy).',
            filters: ['Fix & Flip'],
          },
          {
            label: 'Annual Maintenance',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'maintenanceBRRRR',
            value: assumptions.maintenanceBRRRR,
            tip: 'The annual cost of maintenance repairs to the property expressed as a percentage of Gross Rental Income (rent - vacancy).',
            filters: ['BRRRR'],
          },
          {
            label: 'Annual Maintenance',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'maintenanceSTR',
            value: assumptions.maintenanceSTR,
            tip: 'The annual cost of maintenance repairs to the property expressed as a percentage of Gross Rental Income (rent - vacancy).',
            filters: ['Short Term Rental'],
          },
          {
            label: 'Annual Maintenance',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'maintenanceB2R',
            value: assumptions.maintenanceB2R,
            tip: 'The annual cost of maintenance repairs to the property expressed as a percentage of Gross Rental Income (rent - vacancy).',
            filters: ['Build to Rent'],
          },
          {
            label: 'Vacancy Rate',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'vacancy.0',
            value: get(assumptions, 'vacancy.0'),
            tip: 'The percentage of time that a property is not rented out during the course of a year.',
            filters: [
              'Buy & Hold',
              'Turnkey End Buyer',
              'Fix & Flip',
              'BRRRR',
              'Build to Rent',
            ],
          },
          {
            dropdown: true,
            label: 'Monthly Utilities',
            adornment: assumptions.utilitiesType,
            adornmentToggle: assumptions.utilitiesType,
            adornmentName: 'utilitiesType',
            adornmentPosition: 'start',
            name: 'utilities',
            value: assumptions.utilities,
            tip: 'Includes electricity, water, sewer, gas, garbage, etc.  Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
            filters: ['Buy & Hold'],
          },
          {
            dropdown: true,
            label: 'Monthly Utilities',
            adornment: assumptions.utilitiesTurnkeyType,
            adornmentToggle: assumptions.utilitiesTurnkeyType,
            adornmentName: 'utilitiesTurnkeyType',
            adornmentPosition: 'start',
            name: 'utilitiesTurnkey',
            value: assumptions.utilitiesTurnkey,
            tip: 'Includes electricity, water, sewer, gas, garbage, etc.  Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
            filters: ['Turnkey End Buyer'],
          },
          {
            dropdown: true,
            label: 'Monthly Utilities',
            adornment: assumptions.utilitiesFixFlipType,
            adornmentToggle: assumptions.utilitiesFixFlipType,
            adornmentName: 'utilitiesFixFlipType',
            adornmentPosition: 'start',
            name: 'utilitiesFixFlip',
            value: assumptions.utilitiesFixFlip,
            tip: 'Includes electricity, water, sewer, gas, garbage, etc.  Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
            filters: ['Fix & Flip'],
          },
          {
            dropdown: true,
            label: 'Monthly Utilities',
            adornment: assumptions.utilitiesBRRRRType,
            adornmentToggle: assumptions.utilitiesBRRRRType,
            adornmentName: 'utilitiesBRRRRType',
            adornmentPosition: 'start',
            name: 'utilitiesBRRRR',
            value: assumptions.utilitiesBRRRR,
            tip: 'Includes electricity, water, sewer, gas, garbage, etc.  Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
            filters: ['BRRRR'],
          },
          {
            dropdown: true,
            label: 'Monthly Utilities',
            adornment: assumptions.utilitiesSTRType,
            adornmentToggle: assumptions.utilitiesSTRType,
            adornmentName: 'utilitiesSTRType',
            adornmentPosition: 'start',
            name: 'utilitiesSTR',
            value: assumptions.utilitiesSTR,
            tip: 'Includes electricity, water, sewer, gas, garbage, etc.  Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
            filters: ['Short Term Rental'],
          },
          {
            dropdown: true,
            label: 'Monthly Utilities',
            adornment: assumptions.utilitiesB2RType,
            adornmentToggle: assumptions.utilitiesB2RType,
            adornmentName: 'utilitiesB2RType',
            adornmentPosition: 'start',
            name: 'utilitiesB2R',
            value: assumptions.utilitiesB2R,
            tip: 'Includes electricity, water, sewer, gas, garbage, etc.  Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
            filters: ['Build to Rent'],
          },
          {
            dropdown: true,
            label: 'Capex (reserve/mo)',
            adornment: assumptions.monthlyReservesType,
            adornmentToggle: assumptions.monthlyReservesType,
            adornmentName: 'monthlyReservesType',
            adornmentPosition: 'start',
            name: 'monthlyReserves',
            value: assumptions.monthlyReserves,
            tip: 'The amount of monthly income you want to put aside each month as a reserve for future expenses.  This includes capital expenditure (capex) items such as the roof, appliances, water, heater, etc. Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
            filters: ['Buy & Hold'],
          },
          {
            dropdown: true,
            label: 'Capex (reserve/mo)',
            adornment: assumptions.monthlyReservesFixFlipType,
            adornmentToggle: assumptions.monthlyReservesFixFlipType,
            adornmentName: 'monthlyReservesFixFlipType',
            adornmentPosition: 'start',
            name: 'monthlyReservesFixFlip',
            value: assumptions.monthlyReservesFixFlip,
            tip: 'The amount of monthly income you want to put aside each month as a reserve for future expenses.  This includes capital expenditure (capex) items such as the roof, appliances, water, heater, etc. Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
            filters: ['Fix & Flip'],
          },
          {
            dropdown: true,
            label: 'Capex (reserve/mo)',
            adornment: assumptions.monthlyReservesTurnkeyType,
            adornmentToggle: assumptions.monthlyReservesTurnkeyType,
            adornmentName: 'monthlyReservesTurnkeyType',
            adornmentPosition: 'start',
            name: 'monthlyReservesTurnkey',
            value: assumptions.monthlyReservesTurnkey,
            tip: 'The amount of monthly income you want to put aside each month as a reserve for future expenses.  This includes capital expenditure (capex) items such as the roof, appliances, water, heater, etc. Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
            filters: ['Turnkey End Buyer'],
          },
          {
            dropdown: true,
            label: 'Capex (reserve/mo)',
            adornment: assumptions.monthlyReservesBRRRRType,
            adornmentToggle: assumptions.monthlyReservesBRRRRType,
            adornmentName: 'monthlyReservesBRRRRType',
            adornmentPosition: 'start',
            name: 'monthlyReservesBRRRR',
            value: assumptions.monthlyReservesBRRRR,
            tip: 'The amount of monthly income you want to put aside each month as a reserve for future expenses.  This includes capital expenditure (capex) items such as the roof, appliances, water, heater, etc. Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
            filters: ['BRRRR'],
          },
          {
            dropdown: true,
            label: 'Capex (reserve/mo)',
            adornment: assumptions.monthlyReservesSTRType,
            adornmentToggle: assumptions.monthlyReservesSTRType,
            adornmentName: 'monthlyReservesSTRType',
            adornmentPosition: 'start',
            name: 'monthlyReservesSTR',
            value: assumptions.monthlyReservesSTR,
            tip: 'The amount of monthly income you want to put aside each month as a reserve for future expenses.  This includes capital expenditure (capex) items such as the roof, appliances, water, heater, etc. Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
            filters: ['Short Term Rental'],
          },
          {
            dropdown: true,
            label: 'Capex (reserve/mo)',
            adornment: assumptions.monthlyReservesB2RType,
            adornmentToggle: assumptions.monthlyReservesB2RType,
            adornmentName: 'monthlyReservesB2RType',
            adornmentPosition: 'start',
            name: 'monthlyReservesB2R',
            value: assumptions.monthlyReservesB2R,
            tip: 'The amount of monthly income you want to put aside each month as a reserve for future expenses.  This includes capital expenditure (capex) items such as the roof, appliances, water, heater, etc. Expressed as a dollar amount or a percentage of Monthly Gross Rental Income (rent - vacancy).',
            filters: ['Build to Rent'],
          },
          {
            label: 'Other Annual Expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'otherExpense',
            value: assumptions.otherExpense,
            tip: 'Any annual expense that has not already been labeled.',
            filters: ['Buy & Hold'],
          },
          {
            label: 'Other Annual Expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'otherExpenseTurnkey',
            value: assumptions.otherExpenseTurnkey,
            tip: 'Any annual expense that has not already been labeled.',
            filters: ['Turnkey End Buyer'],
          },
          {
            label: 'Other Annual Expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'otherExpenseFixFlip',
            value: assumptions.otherExpenseFixFlip,
            tip: 'Any annual expense that has not already been labeled.',
            filters: ['Fix & Flip'],
          },
          {
            label: 'Other Annual Expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'otherExpenseBRRRR',
            value: assumptions.otherExpenseBRRRR,
            tip: 'Any annual expense that has not already been labeled.',
            filters: ['BRRRR'],
          },
          {
            label: 'Other Annual Expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'otherExpenseSTR',
            value: assumptions.otherExpenseSTR,
            tip: 'Any annual expense that has not already been labeled.',
            filters: ['Short Term Rental'],
          },
          {
            label: 'Other Annual Expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'otherExpenseB2R',
            value: assumptions.otherExpenseB2R,
            tip: 'Any annual expense that has not already been labeled.',
            filters: ['Build to Rent'],
          },
          {
            label: 'Cohosting Fee',
            dropdown: true,
            adornmentToggle: assumptions.cohostingType,
            adornment: assumptions.cohostingType,
            adornmentName: 'cohostingType',
            adornmentPosition: 'start',
            name: 'cohosting',
            value: assumptions.cohosting,
            tip: 'Cohosting fee expressed as a % of Gross Rental Income (rent - (1 - occcupancy)).',
            filters: ['Short Term Rental'],
          },
          {
            label: 'Occupancy Rate',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'occupancyRate.0',
            value: get(assumptions, 'occupancyRate.0'),
            tip: 'The percentage of time that a property is rented out for short term rentals.',
            filters: ['Short Term Rental'],
          },
        ],
      },
      {
        sectionName: 'Financing: Traditional Loan',
        description: 'Financing panel',
        panel: 2,
        fields: [
          {
            checkbox: true,
            label: 'Cash Purchase',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'allCashPurchase',
            value: assumptions.allCashPurchase,
            type: 'select',
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Buy & Hold'],
            onChange: () => {
              const { assumptions, setAssumptions, setIsChanged } =
                this.props
              let newAssumptions = { ...assumptions }
              newAssumptions.allCashPurchase =
                !newAssumptions.allCashPurchase
              newAssumptions['downPayment'] = 100
              newAssumptions['rate'] = 0
              newAssumptions['term'] = 0
              newAssumptions['points'] = 0
              //newAssumptions['payDownAccelerator'] = 0
              newAssumptions['interestOnlyLoan'] = false
              setAssumptions(newAssumptions)
              if (setIsChanged) setIsChanged(true)
            },
          },
          {
            checkbox: true,
            label: 'Cash Purchase',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'allCashPurchaseTurnkey',
            value: assumptions.allCashPurchaseTurnkey,
            type: 'select',
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Turnkey End Buyer'],
            onChange: () => {
              const { assumptions, setAssumptions, setIsChanged } =
                this.props
              let newAssumptions = { ...assumptions }
              newAssumptions.allCashPurchaseTurnkey =
                !newAssumptions.allCashPurchaseTurnkey
              newAssumptions['downPaymentTurnkey'] = 100
              newAssumptions['rateTurnkey'] = 0
              newAssumptions['termTurnkey'] = 0
              newAssumptions['pointsTurnkey'] = 0
              //newAssumptions['payDownAcceleratorTurnkey'] = 0
              newAssumptions['interestOnlyLoanTurnkey'] = false
              setAssumptions(newAssumptions)
              if (setIsChanged) setIsChanged(true)
            },
          },
          {
            checkbox: true,
            label: 'Cash Purchase',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'allCashPurchaseFixFlip',
            value: assumptions.allCashPurchaseFixFlip,
            type: 'select',
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Fix & Flip'],
            onChange: () => {
              const { assumptions, setAssumptions, setIsChanged } =
                this.props
              let newAssumptions = { ...assumptions }
              newAssumptions.allCashPurchaseFixFlip =
                !newAssumptions.allCashPurchaseFixFlip
              newAssumptions['downPaymentFixFlip'] = 100
              newAssumptions['rateFixFlip'] = 0
              newAssumptions['termFixFlip'] = 0
              newAssumptions['pointsFixFlip'] = 0
              //newAssumptions['payDownAcceleratorFixFlip'] = 0
              newAssumptions['interestOnlyLoanFixFlip'] = false
              setAssumptions(newAssumptions)
              if (setIsChanged) setIsChanged(true)
            },
          },
          {
            checkbox: true,
            label: 'Cash Purchase',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'allCashPurchaseSTR',
            value: assumptions.allCashPurchaseSTR,
            type: 'select',
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Short Term Rental'],
            onChange: () => {
              const { assumptions, setAssumptions, setIsChanged } =
                this.props
              let newAssumptions = { ...assumptions }
              newAssumptions.allCashPurchaseSTR =
                !newAssumptions.allCashPurchaseSTR
              newAssumptions['downPaymentSTR'] = 100
              newAssumptions['rateSTR'] = 0
              newAssumptions['termSTR'] = 0
              newAssumptions['pointsSTR'] = 0
              //newAssumptions['payDownAcceleratorSTR'] = 0
              newAssumptions['interestOnlyLoanSTR'] = false
              setAssumptions(newAssumptions)
              if (setIsChanged) setIsChanged(true)
            },
          },
          {
            label: 'Down Payment',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'downPayment',
            value: assumptions.downPayment,
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Buy & Hold'],
            notCashPurchase: true,
          },
          {
            label: 'Down Payment',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'downPaymentTurnkey',
            value: assumptions.downPaymentTurnkey,
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Turnkey End Buyer'],
            notCashPurchase: true,
          },
          {
            label: 'Down Payment',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'downPaymentFixFlip',
            value: assumptions.downPaymentFixFlip,
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Fix & Flip'],
            notCashPurchase: true,
          },
          {
            label: 'Down Payment',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'downPaymentSTR',
            value: assumptions.downPaymentSTR,
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Short Term Rental'],
            notCashPurchase: true,
          },
          {
            label: 'Interest Rate',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rate',
            value: assumptions.rate,
            tip: 'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
            filters: ['Buy & Hold'],
            notCashPurchase: true,
          },
          {
            label: 'Interest Rate',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rateTurnkey',
            value: assumptions.rateTurnkey,
            tip: 'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
            filters: ['Turnkey End Buyer'],
            notCashPurchase: true,
          },
          {
            label: 'Interest Rate',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rateFixFlip',
            value: assumptions.rateFixFlip,
            tip: 'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
            filters: ['Fix & Flip'],
            notCashPurchase: true,
          },
          {
            label: 'Interest Rate',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rateSTR',
            value: assumptions.rateSTR,
            tip: 'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
            filters: ['Short Term Rental'],
            notCashPurchase: true,
          },
          {
            dropdown: true,
            label: 'Financing Term',
            adornment: assumptions.termType,
            adornmentToggle: assumptions.termType,
            adornmentName: 'termType',
            name: 'term',
            value: assumptions.term,
            tip: 'The length of time that the loan is being amortized. Expressed in months or years.',
            filters: ['Buy & Hold'],
            gtZero: true,
            notCashPurchase: true,
          },
          {
            dropdown: true,
            label: 'Financing Term',
            adornment: assumptions.termTurnkeyType,
            adornmentToggle: assumptions.termTurnkeyType,
            adornmentName: 'termTurnkeyType',
            name: 'termTurnkey',
            value: assumptions.termTurnkey,
            tip: 'The length of time that the loan is being amortized. Expressed in months or years.',
            filters: ['Turnkey End Buyer'],
            gtZero: true,
            notCashPurchase: true,
          },
          {
            dropdown: true,
            label: 'Financing Term',
            adornment: assumptions.termFixFlipType,
            adornmentToggle: assumptions.termFixFlipType,
            adornmentName: 'termFixFlipType',
            name: 'termFixFlip',
            value: assumptions.termFixFlip,
            tip: 'The length of time that the loan is being amortized. Expressed in months or years.',
            filters: ['Fix & Flip'],
            gtZero: true,
            notCashPurchase: true,
          },
          {
            dropdown: true,
            label: 'Financing Term',
            adornment: assumptions.termSTRType,
            adornmentToggle: assumptions.termSTRType,
            adornmentName: 'termSTRType',
            name: 'termSTR',
            value: assumptions.termSTR,
            tip: 'The length of time that the loan is being amortized. Expressed in months or years.',
            filters: ['Short Term Rental'],
            gtZero: true,
            notCashPurchase: true,
          },
          {
            label: 'Points',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'points',
            value: assumptions.points,
            tip: 'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
            filters: ['Buy & Hold'],
            notCashPurchase: true,
          },
          {
            label: 'Points',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'pointsTurnkey',
            value: assumptions.pointsTurnkey,
            tip: 'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
            filters: ['Turnkey End Buyer'],
            notCashPurchase: true,
          },
          {
            label: 'Points',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'pointsFixFlip',
            value: assumptions.pointsFixFlip,
            tip: 'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
            filters: ['Fix & Flip'],
            notCashPurchase: true,
          },
          {
            label: 'Points',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'pointsSTR',
            value: assumptions.pointsSTR,
            tip: 'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
            filters: ['Short Term Rental'],
            notCashPurchase: true,
          },
          {
            checkbox: true,
            label: 'Interest Only Loan',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'interestOnlyLoan',
            value: assumptions.interestOnlyLoan,
            type: 'select',
            tip: 'A loan that only requires the payment of interest, not principal.',
            filters: ['Buy & Hold'],
            notCashPurchase: true,
          },
          {
            checkbox: true,
            label: 'Interest Only Loan',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'interestOnlyLoanTurnkey',
            value: assumptions.interestOnlyLoanTurnkey,
            type: 'select',
            tip: 'A loan that only requires the payment of interest, not principal.',
            filters: ['Turnkey End Buyer'],
            notCashPurchase: true,
          },
          {
            checkbox: true,
            label: 'Interest Only Loan',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'interestOnlyLoanFixFlip',
            value: assumptions.interestOnlyLoanFixFlip,
            type: 'select',
            tip: 'A loan that only requires the payment of interest, not principal.',
            filters: ['Fix & Flip'],
            notCashPurchase: true,
          },
          {
            checkbox: true,
            label: 'Interest Only Loan',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'interestOnlyLoanSTR',
            value: assumptions.interestOnlyLoanSTR,
            type: 'select',
            tip: 'A loan that only requires the payment of interest, not principal.',
            filters: ['Short Term Rental'],
            notCashPurchase: true,
          },
        ],
      },
      {
        sectionName: 'Financing: Bridge Loan',
        description: 'Financing panel',
        panel: 3,
        fields: [
          {
            checkbox: true,
            label: 'Cash Purchase',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'bridgeCashPurchase',
            value: assumptions.bridgeCashPurchase,
            type: 'select',
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['BRRRR'],
            onChange: () => {
              const { assumptions, setAssumptions, setIsChanged } =
                this.props
              let newAssumptions = { ...assumptions }
              newAssumptions.bridgeCashPurchase =
                !newAssumptions.bridgeCashPurchase
              newAssumptions['bridgeDownPayment'] = 100
              newAssumptions['bridgeRate'] = 0
              newAssumptions['bridgeTerm'] = 0
              newAssumptions['bridgePoints'] = 0
              //newAssumptions['payDownAccelerator'] = 0
              newAssumptions['bridgeInterestOnlyLoan'] = false
              setAssumptions(newAssumptions)
              if (setIsChanged) setIsChanged(true)
            },
          },
          {
            label: 'Down Payment',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'bridgeDownPayment',
            value: assumptions.bridgeDownPayment,
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['BRRRR'],
            notCashPurchase: true,
          },
          {
            label: 'Interest Rate',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'bridgeRate',
            value: assumptions.bridgeRate,
            tip: 'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
            filters: ['BRRRR'],
            notCashPurchase: true,
          },
          {
            dropdown: true,
            label: 'Financing Term',
            adornment: assumptions.bridgeTermType,
            adornmentToggle: assumptions.bridgeTermType,
            adornmentName: 'bridgeTermType',
            name: 'bridgeTerm',
            value: assumptions.bridgeTerm,
            tip: 'The length of time that the loan is being amortized. Expressed in months or years. Expresed in months or years.',
            filters: ['BRRRR'],
            gtZero: true,
            notCashPurchase: true,
          },
          {
            label: 'Points',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'bridgePoints',
            value: assumptions.bridgePoints,
            tip: 'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
            filters: ['BRRRR'],
            notCashPurchase: true,
          },
          {
            checkbox: true,
            label: 'Interest Only Loan',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'bridgeInterestOnlyLoan',
            value: assumptions.bridgeInterestOnlyLoan,
            type: 'select',
            tip: 'A loan that only requires the payment of interest, not principal.',
            filters: ['BRRRR'],
            notCashPurchase: true,
          },
        ],
      },
      {
        sectionName: 'Financing: Refinance',
        description: 'Financing panel',
        panel: 4,
        fields: [
          {
            checkbox: true,
            label: 'Cash Purchase',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'refinanceCashPurchase',
            value: assumptions.refinanceCashPurchase,
            type: 'select',
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['BRRRR'],
            onChange: () => {
              const { assumptions, setAssumptions, setIsChanged } =
                this.props
              let newAssumptions = { ...assumptions }
              newAssumptions.refinanceCashPurchase =
                !newAssumptions.refinanceCashPurchase
              newAssumptions['refinanceDownPayment'] = 100
              newAssumptions['refinanceRate'] = 0
              newAssumptions['refinanceTerm'] = 0
              newAssumptions['refinancePoints'] = 0
              //newAssumptions['payDownAccelerator'] = 0
              newAssumptions['refinanceInterestOnlyLoan'] = false
              setAssumptions(newAssumptions)
              if (setIsChanged) setIsChanged(true)
            },
          },
          {
            label: 'Down Payment',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'refinanceDownPayment',
            value: assumptions.refinanceDownPayment,
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['BRRRR'],
            notCashPurchase: true,
          },
          {
            label: 'Interest Rate',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'refinanceRate',
            value: assumptions.refinanceRate,
            tip: 'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
            filters: ['BRRRR'],
            notCashPurchase: true,
          },
          {
            dropdown: true,
            label: 'Financing Term',
            adornment: assumptions.refinanceTermType,
            adornmentToggle: assumptions.refinanceTermType,
            adornmentName: 'refinanceTermType',
            name: 'refinanceTerm',
            value: assumptions.refinanceTerm,
            tip: 'The length of time that the loan is being amortized. Expressed in months or years.',
            filters: ['BRRRR'],
            gtZero: true,
            notCashPurchase: true,
          },
          {
            label: 'Points',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'refinancePoints',
            value: assumptions.refinancePoints,
            tip: 'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
            filters: ['BRRRR'],
            notCashPurchase: true,
          },
          {
            label: 'Cash Out',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'refinanceCashOut',
            value: assumptions.refinanceCashOut,
            tip: "A lender's limit of how much they will allow you to cash out. Expressed as a percentage of ARV.",
            filters: ['BRRRR'],
            notCashPurchase: true,
          },
          {
            checkbox: true,
            label: 'Interest Only Loan',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'refinanceInterestOnlyLoan',
            value: assumptions.refinanceInterestOnlyLoan,
            type: 'select',
            tip: 'A loan that only requires the payment of interest, not principal.',
            filters: ['BRRRR'],
            notCashPurchase: true,
          },
        ],
      },
      {
        sectionName: 'Financing: Lot Loan',
        description: 'Financing panel',
        panel: 5,
        fields: [
          {
            checkbox: true,
            label: 'Cash Purchase',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'lotCashPurchase',
            value: assumptions.lotCashPurchase,
            type: 'select',
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Build to Rent'],
            onChange: () => {
              const { assumptions, setAssumptions, setIsChanged } =
                this.props
              let newAssumptions = { ...assumptions }
              newAssumptions.lotCashPurchase =
                !newAssumptions.lotCashPurchase
              newAssumptions['lotDownPayment'] = 100
              newAssumptions['lotRate'] = 0
              newAssumptions['lotTerm'] = 0
              newAssumptions['lotPoints'] = 0
              //newAssumptions['payDownAccelerator'] = 0
              newAssumptions['lotInterestOnlyLoan'] = false
              setAssumptions(newAssumptions)
              if (setIsChanged) setIsChanged(true)
            },
          },
          {
            label: 'Down Payment',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'lotDownPayment',
            value: assumptions.lotDownPayment,
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
          {
            label: 'Interest Rate',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'lotRate',
            value: assumptions.lotRate,
            tip: 'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
          // {
          //   dropdown: true,
          //   label: 'Financing Term',
          //   adornment: assumptions.lotTermType,
          //   adornmentToggle: assumptions.lotTermType,
          //   adornmentName: 'lotTermType',
          //   name: 'lotTerm',
          //   value: assumptions.lotTerm,
          //   tip: 'The length of time that the loan is being amortized. Expressed in months or years.',
          //   filters: ['Build to Rent'],
          //   gtZero: true,
          //   notCashPurchase: true,
          // },
          {
            label: 'Points',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'lotPoints',
            value: assumptions.lotPoints,
            tip: 'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
          {
            checkbox: true,
            label: 'Interest Only Loan',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'lotInterestOnlyLoan',
            value: assumptions.lotInterestOnlyLoan,
            type: 'select',
            tip: 'A loan that only requires the payment of interest, not principal.',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
        ],
      },
      {
        sectionName: 'Financing: Construction Loan',
        description: 'Financing panel',
        panel: 6,
        fields: [
          {
            checkbox: true,
            label: 'Cash Purchase',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'constructionCashPurchase',
            value: assumptions.constructionCashPurchase,
            type: 'select',
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Build to Rent'],
            onChange: () => {
              const { assumptions, setAssumptions, setIsChanged } =
                this.props
              let newAssumptions = { ...assumptions }
              newAssumptions.constructionCashPurchase =
                !newAssumptions.constructionCashPurchase
              newAssumptions['constructionDownPayment'] = 100
              newAssumptions['constructionRate'] = 0
              newAssumptions['constructionTerm'] = 0
              newAssumptions['constructionPoints'] = 0
              //newAssumptions['payDownAccelerator'] = 0
              newAssumptions['constructionInterestOnlyLoan'] = false
              setAssumptions(newAssumptions)
              if (setIsChanged) setIsChanged(true)
            },
          },
          {
            label: 'Down Payment',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'constructionDownPayment',
            value: assumptions.constructionDownPayment,
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
          {
            label: 'Interest Rate',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'constructionRate',
            value: assumptions.constructionRate,
            tip: 'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
          // {
          //   dropdown: true,
          //   label: 'Financing Term',
          //   adornment: assumptions.constructionTermType,
          //   adornmentToggle: assumptions.constructionTermType,
          //   adornmentName: 'constructionTermType',
          //   name: 'constructionTerm',
          //   value: assumptions.constructionTerm,
          //   tip: 'The length of time that the loan is being amortized. Expressed in months or years.',
          //   filters: ['Build to Rent'],
          //   gtZero: true,
          //   notCashPurchase: true,
          // },
          {
            label: 'Points',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'constructionPoints',
            value: assumptions.constructionPoints,
            tip: 'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
          {
            checkbox: true,
            label: 'Interest Only Loan',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'constructionInterestOnlyLoan',
            value: assumptions.constructionInterestOnlyLoan,
            type: 'select',
            tip: 'A loan that only requires the payment of interest, not principal.',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
        ],
      },
      {
        sectionName: 'Financing: Mezzanine Loan',
        description: 'Financing panel',
        panel: 7,
        fields: [
          {
            checkbox: true,
            label: 'Cash Purchase',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'mezzanineCashPurchase.0',
            value: get(assumptions, 'mezzanineCashPurchase.0'),
            type: 'select',
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Build to Rent'],
            onChange: () => {
              const { assumptions, setAssumptions, setIsChanged } =
                this.props
              let newAssumptions = { ...assumptions }
              newAssumptions.mezzanineCashPurchase[0] =
                !newAssumptions.mezzanineCashPurchase[0]
              newAssumptions['mezzanineDownPayment'][0] = 100
              newAssumptions['mezzanineRate'][0] = 0
              newAssumptions['mezzanineTerm'][0] = 0
              newAssumptions['mezzaninePoints'][0] = 0
              //newAssumptions['payDownAccelerator'] = 0
              newAssumptions['mezzanineInterestOnlyLoan'][0] = false
              setAssumptions(newAssumptions)
              if (setIsChanged) setIsChanged(true)
            },
          },
          {
            label: 'Down Payment',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'mezzanineDownPayment.0',
            value: get(assumptions, 'mezzanineDownPayment.0'),
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
          {
            label: 'Interest Rate',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'mezzanineRate.0',
            value: get(assumptions, 'mezzanineRate.0'),
            tip: 'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
          // {
          //   dropdown: true,
          //   label: 'Financing Term',
          //   adornment: get(assumptions, 'mezzanineTermType.0'),
          //   adornmentToggle: get(assumptions, 'mezzanineTermType.0'),
          //   adornmentName: 'mezzanineTermType.0',
          //   name: 'mezzanineTerm.0',
          //   value: get(assumptions, 'mezzanineTerm.0'),
          //   tip: 'The length of time that the loan is being amortized. Expressed in months or years.',
          //   filters: ['Build to Rent'],
          //   gtZero: true,
          //   notCashPurchase: true,
          // },
          {
            label: 'Points',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'mezzaninePoints.0',
            value: get(assumptions, 'mezzaninePoints.0'),
            tip: 'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
          {
            checkbox: true,
            label: 'Interest Only Loan',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'mezzanineInterestOnlyLoan.0',
            value: get(assumptions, 'mezzanineInterestOnlyLoan.0'),
            type: 'select',
            tip: 'A loan that only requires the payment of interest, not principal.',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
          {
            label: 'Cost To Build Buffer',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'costToBuildBuffer',
            value: get(assumptions, 'costToBuildBuffer'),
            tip: '',
            filters: ['Build to Rent'],
            notCashPurchase: true,
          },
        ],
      },
      {
        sectionName: 'Financing: BOR',
        description: 'Financing panel',
        panel: 8,
        fields: [
          {
            label: 'Down Payment',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'downPaymentBOR',
            value: assumptions.downPaymentBOR,
            tip: 'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate.',
            filters: ['BOR'],
            notCashPurchase: true,
          },
          {
            label: 'Interest Rate',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rateBOR',
            value: assumptions.rateBOR,
            tip: 'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
            filters: ['BOR'],
            notCashPurchase: true,
          },
          {
            //dropdown: true,
            label: 'Financing Term (Years)',
            adornment: 'Years',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'termBOR',
            value: assumptions.termBOR,
            tip: 'The length of time that the loan is being amortized. Expressed in years.',
            filters: ['BOR'],
            gtZero: true,
            notCashPurchase: true,
          },
        ],
      },
      {
        sectionName: 'Disposition',
        description: 'Disposition panel',
        panel: 9,
        fields: [
          {
            dropdown: true,
            label: 'Sales Commission',
            adornment: assumptions.salesCommissionType,
            adornmentToggle: assumptions.salesCommissionType,
            adornmentName: 'salesCommissionType',
            adornmentPosition: 'start',
            name: 'salesCommission',
            value: assumptions.salesCommission,
            tip: 'Sales Commission on the Future Sale of Property. Expressed as a dollar amount or percentage of resale price.',
            filters: ['Buy & Hold'],
          },
          {
            dropdown: true,
            label: 'Sales Commission',
            adornment: assumptions.salesCommissionFixFlipType,
            adornmentToggle: assumptions.salesCommissionFixFlipType,
            adornmentName: 'salesCommissionFixFlipType',
            adornmentPosition: 'start',
            name: 'salesCommissionFixFlip',
            value: assumptions.salesCommissionFixFlip,
            tip: 'Sales Commission on the Future Sale of Property. Expressed as a dollar amount or percentage of resale price.',
            filters: ['Fix & Flip'],
          },
          {
            dropdown: true,
            label: 'Sales Commission',
            adornment: assumptions.salesCommissionTurnkeyType,
            adornmentToggle: assumptions.salesCommissionTurnkeyType,
            adornmentName: 'salesCommissionTurnkeyType',
            adornmentPosition: 'start',
            name: 'salesCommissionTurnkey',
            value: assumptions.salesCommissionTurnkey,
            tip: 'Sales Commission on the Future Sale of Property. Expressed as a dollar amount or percentage of resale price.',
            filters: ['Turnkey End Buyer'],
          },
          {
            dropdown: true,
            label: 'Sales Commission',
            adornment: assumptions.salesCommissionSTRType,
            adornmentToggle: assumptions.salesCommissionSTRType,
            adornmentName: 'salesCommissionSTRType',
            adornmentPosition: 'start',
            name: 'salesCommissionSTR',
            value: assumptions.salesCommissionSTR,
            tip: 'Sales Commission on the Future Sale of Property. Expressed as a dollar amount or percentage of resale price.',
            filters: ['Short Term Rental'],
          },
          {
            dropdown: true,
            label: 'Sales Commission',
            adornment: assumptions.salesCommissionBRRRRType,
            adornmentToggle: assumptions.salesCommissionBRRRRType,
            adornmentName: 'salesCommissionBRRRRType',
            adornmentPosition: 'start',
            name: 'salesCommissionBRRRR',
            value: assumptions.salesCommissionBRRRR,
            tip: 'Sales Commission on the Future Sale of Property. Expressed as a dollar amount or percentage of resale price.',
            filters: ['BRRRR'],
          },
          {
            dropdown: true,
            label: 'Sales Commission',
            adornment: assumptions.salesCommissionB2RType,
            adornmentToggle: assumptions.salesCommissionB2RType,
            adornmentName: 'salesCommissionB2RType',
            adornmentPosition: 'start',
            name: 'salesCommissionB2R',
            value: assumptions.salesCommissionB2R,
            tip: 'Sales Commission on the Future Sale of Property. Expressed as a dollar amount or percentage of resale price.',
            filters: ['Build to Rent'],
          },
          {
            dropdown: true,
            label: 'Disposition Fee',
            adornmentToggle: assumptions.dispositionFeeType,
            adornment: assumptions.dispositionFeeType,
            adornmentName: 'dispositionFeeType',
            adornmentPosition: 'start',
            name: 'dispositionFee',
            value: assumptions.dispositionFee,
            tip: 'Advanced Fee - Additional marketing fee on top of sales commission. Expressed as a dollar amount or percentage of resale price.',
            filters: ['Buy & Hold'],
          },
          {
            dropdown: true,
            label: 'Disposition Fee',
            adornmentToggle: assumptions.dispositionFeeFixFlipType,
            adornment: assumptions.dispositionFeeFixFlipType,
            adornmentName: 'dispositionFeeFixFlipType',
            adornmentPosition: 'start',
            name: 'dispositionFeeFixFlip',
            value: assumptions.dispositionFeeFixFlip,
            tip: 'Advanced Fee - Additional marketing fee on top of sales commission. Expressed as a dollar amount or percentage of resale price.',
            filters: ['Fix & Flip'],
          },
          {
            dropdown: true,
            label: 'Disposition Fee',
            adornmentToggle: assumptions.dispositionFeeTurnkeyType,
            adornment: assumptions.dispositionFeeTurnkeyType,
            adornmentName: 'dispositionFeeTurnkeyType',
            adornmentPosition: 'start',
            name: 'dispositionFeeTurnkey',
            value: assumptions.dispositionFeeTurnkey,
            tip: 'Advanced Fee - Additional marketing fee on top of sales commission. Expressed as a dollar amount or percentage of resale price.',
            filters: ['Turnkey End Buyer'],
          },
          {
            dropdown: true,
            label: 'Disposition Fee',
            adornmentToggle: assumptions.dispositionFeeBRRRRType,
            adornment: assumptions.dispositionFeeBRRRRType,
            adornmentName: 'dispositionFeeBRRRRType',
            adornmentPosition: 'start',
            name: 'dispositionFeeBRRRR',
            value: assumptions.dispositionFeeBRRRR,
            tip: 'Advanced Fee - Additional marketing fee on top of sales commission. Expressed as a dollar amount or percentage of resale price.',
            filters: ['BRRRR'],
          },
          {
            dropdown: true,
            label: 'Disposition Fee',
            adornmentToggle: assumptions.dispositionFeeSTRType,
            adornment: assumptions.dispositionFeeSTRType,
            adornmentName: 'dispositionFeeSTRType',
            adornmentPosition: 'start',
            name: 'dispositionFeeSTR',
            value: assumptions.dispositionFeeSTR,
            tip: 'Advanced Fee - Additional marketing fee on top of sales commission. Expressed as a dollar amount or percentage of resale price.',
            filters: ['Short Term Rental'],
          },
          {
            dropdown: true,
            label: 'Disposition Fee',
            adornmentToggle: assumptions.dispositionFeeB2RType,
            adornment: assumptions.dispositionFeeB2RType,
            adornmentName: 'dispositionFeeB2RType',
            adornmentPosition: 'start',
            name: 'dispositionFeeB2R',
            value: assumptions.dispositionFeeB2R,
            tip: 'Advanced Fee - Additional marketing fee on top of sales commission. Expressed as a dollar amount or percentage of resale price.',
            filters: ['Build to Rent'],
          },
          {
            dropdown: true,
            label: 'Seller Closing Cost',
            adornmentToggle: assumptions.sellerClosingCostType,
            adornment: assumptions.sellerClosingCostType,
            adornmentName: 'sellerClosingCostType',
            adornmentPosition: 'start',
            name: 'sellerClosingCost',
            value: assumptions.sellerClosingCost,
            tip: 'Closing Costs Paid by the Seller. Expressed as a dollar amount or percentage of the resale price',
            filters: ['Buy & Hold'],
          },
          {
            dropdown: true,
            label: 'Seller Closing Cost',
            adornmentToggle: assumptions.sellerClosingCostFixFlipType,
            adornment: assumptions.sellerClosingCostFixFlipType,
            adornmentName: 'sellerClosingCostFixFlipType',
            adornmentPosition: 'start',
            name: 'sellerClosingCostFixFlip',
            value: assumptions.sellerClosingCostFixFlip,
            tip: 'Closing Costs Paid by the Seller. Expressed as a dollar amount or percentage of the resale price',
            filters: ['Fix & Flip'],
          },
          {
            dropdown: true,
            label: 'Seller Closing Cost',
            adornmentToggle: assumptions.sellerClosingCostTurnkeyType,
            adornment: assumptions.sellerClosingCostTurnkeyType,
            adornmentName: 'sellerClosingCostTurnkeyType',
            adornmentPosition: 'start',
            name: 'sellerClosingCostTurnkey',
            value: assumptions.sellerClosingCostTurnkey,
            tip: 'Closing Costs Paid by the Seller. Expressed as a dollar amount or percentage of the resale price',
            filters: ['Turnkey End Buyer'],
          },
          {
            dropdown: true,
            label: 'Seller Closing Cost',
            adornmentToggle: assumptions.sellerClosingCostSTRType,
            adornment: assumptions.sellerClosingCostSTRType,
            adornmentName: 'sellerClosingCostSTRType',
            adornmentPosition: 'start',
            name: 'sellerClosingCostSTR',
            value: assumptions.sellerClosingCostSTR,
            tip: 'Closing Costs Paid by the Seller. Expressed as a dollar amount or percentage of the resale price',
            filters: ['Short Term Rental'],
          },
          {
            dropdown: true,
            label: 'Seller Closing Cost',
            adornmentToggle: assumptions.sellerClosingCostBRRRRType,
            adornment: assumptions.sellerClosingCostBRRRRType,
            adornmentName: 'sellerClosingCostBRRRRType',
            adornmentPosition: 'start',
            name: 'sellerClosingCostBRRRR',
            value: assumptions.sellerClosingCostBRRRR,
            tip: 'Closing Costs Paid by the Seller. Expressed as a dollar amount or percentage of the resale price',
            filters: ['BRRRR'],
          },
          {
            dropdown: true,
            label: 'Seller Closing Cost',
            adornmentToggle: assumptions.sellerClosingCostB2RType,
            adornment: assumptions.sellerClosingCostB2RType,
            adornmentName: 'sellerClosingCostB2RType',
            adornmentPosition: 'start',
            name: 'sellerClosingCostB2R',
            value: assumptions.sellerClosingCostB2R,
            tip: 'Closing Costs Paid by the Seller. Expressed as a dollar amount or percentage of the resale price',
            filters: ['Build to Rent'],
          },
          {
            label: 'Rental Holding Period (years)',
            adornment: '',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rentalHoldingPeriod',
            value: assumptions.rentalHoldingPeriod,
            tip: 'A holding period is the amount of time the investment is held by an investor or the period between the purchase and sale of the property. Expressed in years.',
            filters: ['Buy & Hold'],
          },
          {
            label: 'Rental Holding Period (years)',
            adornment: '',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rentalHoldingPeriodTurnkey',
            value: assumptions.rentalHoldingPeriodTurnkey,
            tip: 'A holding period is the amount of time the investment is held by an investor or the period between the purchase and sale of the property. Expressed in years.',
            filters: ['Turnkey End Buyer'],
          },
          {
            label: 'Rental Holding Period (years)',
            adornment: '',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rentalHoldingPeriodBRRRR',
            value: assumptions.rentalHoldingPeriodBRRRR,
            tip: 'A holding period is the amount of time the investment is held by an investor or the period between the purchase and sale of the property. Expressed in years.',
            filters: ['BRRRR'],
          },
          {
            label: 'Rental Holding Period (years)',
            adornment: '',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rentalHoldingPeriodSTR',
            value: assumptions.rentalHoldingPeriodSTR,
            tip: 'A holding period is the amount of time the investment is held by an investor or the period between the purchase and sale of the property. Expressed in years.',
            filters: ['Short Term Rental'],
          },
          {
            label: 'Rental Holding Period (years)',
            adornment: '',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rentalHoldingPeriodB2R',
            value: assumptions.rentalHoldingPeriodB2R,
            tip: 'A holding period is the amount of time the investment is held by an investor or the period between the purchase and sale of the property. Expressed in years.',
            filters: ['Build to Rent'],
          },
          {
            type: 'select',
            label: 'Rent Type',
            name: 'rentType',
            value: assumptions.rentType,
            tip: 'Rent can be stored as either a value or a range',
            options: assumptionsOptions(
              this.props?.session.me.activeOrg,
            )?.rentTypes,
            filters: [
              'Buy & Hold',
              'Fix & Flip',
              'Turnkey End Buyer',
              'BRRRR',
              'Build to Rent',
            ],
          },
          {
            type: 'select',
            label: 'Rent Type',
            name: 'rentBORType',
            value: assumptions.rentBORType,
            tip: 'Rent can be stored as either a value or a range',
            options: assumptionsOptions(
              this.props?.session.me.activeOrg,
            )?.rentTypes,
            filters: ['BOR'],
          },
          {
            label: 'Rehab Period (months)',
            adornment: '',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rehabPeriod',
            value: assumptions.rehabPeriod,
            tip: 'A holding period is the amount of time the investment is held by an investor or the period between the purchase and sale of the property. Expressed in months.',
            filters: ['Buy & Hold'],
          },
          {
            label: 'Rehab Period (months)',
            adornment: '',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rehabPeriodBRRRR',
            value: assumptions.rehabPeriodBRRRR,
            tip: 'A holding period is the amount of time the investment is held by an investor or the period between the purchase and sale of the property. Expressed in months.',
            filters: ['BRRRR'],
          },
          {
            label: 'Rehab Period (months)',
            adornment: '',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rehabPeriodSTR',
            value: assumptions.rehabPeriodSTR,
            tip: 'A holding period is the amount of time the investment is held by an investor or the period between the purchase and sale of the property. Expressed in months.',
            filters: ['Short Term Rental'],
          },
          {
            // dropdown: true,
            type: 'select',
            label: 'Rehab Type',
            name: 'rehabType',
            options: assumptionsOptions(
              this.props?.session.me.activeOrg,
            )?.rehabOptions,
            value: assumptions.rehabType,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Buy & Hold'],
          },
          {
            // dropdown: true,
            type: 'select',
            label: 'Rehab Type',
            name: 'rehabFixFlipType',
            options: assumptionsOptions(
              this.props?.session.me.activeOrg,
            )?.rehabOptions,
            value: assumptions.rehabFixFlipType,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Fix & Flip'],
          },
          {
            // dropdown: true,
            type: 'select',
            label: 'End Rehab Type',
            name: 'rehabTurnkeyType',
            options: assumptionsOptions(
              this.props?.session.me.activeOrg,
            )?.rehabOptions,
            value: assumptions.rehabTurnkeyType,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Turnkey End Buyer'],
          },
          {
            // dropdown: true,
            type: 'select',
            label: 'Rehab Type',
            name: 'rehabBRRRRType',
            options: assumptionsOptions(
              this.props?.session.me.activeOrg,
            )?.rehabOptions,
            value: assumptions.rehabBRRRRType,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['BRRRR'],
          },
          {
            // dropdown: true,
            type: 'select',
            label: 'Rehab Type',
            name: 'rehabSTRType',
            options: assumptionsOptions(
              this.props?.session.me.activeOrg,
            )?.rehabOptions,
            value: assumptions.rehabSTRType,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Short Term Rental'],
          },
          {
            // dropdown: true,
            type: 'select',
            label: 'Rehab Type',
            name: 'rehabBORType',
            options: assumptionsOptions(
              this.props?.session.me.activeOrg,
            )?.rehabOptions,
            value: assumptions.rehabBORType,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['BOR'],
          },
          {
            // dropdown: true,
            label: 'Rehab Tier',
            name: 'rehabTier',
            options: rehabTiers,
            optionName: 'rehabTiers',
            memberOptions: memberRehabTiers,
            dynamicMemberOptions: dynamicMemberRehabTiers,
            type: 'text',
            removeOption: true,
            value: assumptions.rehabTier,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Buy & Hold'],
          },
          {
            // dropdown: true,
            label: 'Rehab Tier',
            name: 'rehabTierFixFlip',
            options: rehabTiers,
            optionName: 'rehabTiers',
            memberOptions: memberRehabTiers,
            dynamicMemberOptions: dynamicMemberRehabTiers,
            type: 'text',
            removeOption: true,
            value: assumptions.rehabTierFixFlip,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Fix & Flip'],
          },
          {
            // dropdown: true,
            label: 'Rehab Tier',
            name: 'rehabTierTurnkey',
            options: rehabTiers,
            optionName: 'rehabTiers',
            memberOptions: memberRehabTiers,
            dynamicMemberOptions: dynamicMemberRehabTiers,
            type: 'text',
            removeOption: true,
            value: assumptions.rehabTierTurnkey,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Turnkey End Buyer'],
          },
          {
            // dropdown: true,
            label: 'Rehab Tier',
            name: 'rehabTierBRRRR',
            options: rehabTiers,
            optionName: 'rehabTiers',
            memberOptions: memberRehabTiers,
            dynamicMemberOptions: dynamicMemberRehabTiers,
            type: 'text',
            removeOption: true,
            value: assumptions.rehabTierBRRRR,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['BRRRR'],
          },
          {
            // dropdown: true,
            label: 'Rehab Tier',
            name: 'rehabTierSTR',
            options: rehabTiers,
            optionName: 'rehabTiers',
            memberOptions: memberRehabTiers,
            dynamicMemberOptions: dynamicMemberRehabTiers,
            type: 'text',
            removeOption: true,
            value: assumptions.rehabTierSTR,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Short Term Rental'],
          },
          {
            // dropdown: true,
            label: 'Rehab Tier',
            name: 'rehabTierBOR',
            options: rehabTiers,
            optionName: 'rehabTiers',
            memberOptions: memberRehabTiers,
            dynamicMemberOptions: dynamicMemberRehabTiers,
            type: 'text',
            removeOption: true,
            value: assumptions.rehabTierBOR,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['BOR'],
          },
          {
            // dropdown: true,
            label: 'Rehab Decade Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rehabDecadeCost',
            value: assumptions.rehabDecadeCost,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Buy & Hold'],
          },
          {
            // dropdown: true,
            label: 'Rehab Decade Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rehabDecadeCostFixFlip',
            value: assumptions.rehabDecadeCostFixFlip,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Fix & Flip'],
          },
          {
            // dropdown: true,
            label: 'Rehab Decade Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rehabDecadeCostTurnkey',
            value: assumptions.rehabDecadeCostTurnkey,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Turnkey End Buyer'],
          },
          {
            // dropdown: true,
            label: 'Rehab Decade Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rehabDecadeCostBRRRR',
            value: assumptions.rehabDecadeCostBRRRR,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['BRRRR'],
          },
          {
            // dropdown: true,
            label: 'Rehab Decade Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rehabDecadeCostSTR',
            value: assumptions.rehabDecadeCostSTR,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Short Term Rental'],
          },
          {
            // dropdown: true,
            label: 'Rehab Decade Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rehabDecadeCostBOR',
            value: assumptions.rehabDecadeCostBOR,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['BOR'],
          },
          {
            dropdown: true,
            label: 'Rehab Contingency',
            adornment: assumptions.rehabContingencyType,
            adornmentToggle: assumptions.rehabContingencyType,
            adornmentName: 'rehabContingencyType',
            adornmentPosition: 'end',
            name: 'rehabContingency',
            value: assumptions.rehabContingency,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Buy & Hold'],
          },
          {
            dropdown: true,
            label: 'Rehab Contingency',
            adornment: assumptions.rehabContingencyFixFlipType,
            adornmentToggle: assumptions.rehabContingencyFixFlipType,
            adornmentName: 'rehabContingencyFixFlipType',
            adornmentPosition: 'end',
            name: 'rehabContingencyFixFlip',
            value: assumptions.rehabContingencyFixFlip,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Fix & Flip'],
          },
          {
            dropdown: true,
            label: 'Rehab Contingency',
            adornment: assumptions.rehabContingencyTurnkeyType,
            adornmentToggle: assumptions.rehabContingencyTurnkeyType,
            adornmentName: 'rehabContingencyTurnkeyType',
            adornmentPosition: 'end',
            name: 'rehabContingencyTurnkey',
            value: assumptions.rehabContingencyTurnkey,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Turnkey End Buyer'],
          },
          {
            dropdown: true,
            label: 'Rehab Contingency',
            adornment: assumptions.rehabContingencyBRRRRType,
            adornmentToggle: assumptions.rehabContingencyBRRRRType,
            adornmentName: 'rehabContingencyBRRRRType',
            adornmentPosition: 'end',
            name: 'rehabContingencyBRRRR',
            value: assumptions.rehabContingencyBRRRR,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['BRRRR'],
          },
          {
            dropdown: true,
            label: 'Rehab Contingency',
            adornment: assumptions.rehabContingencySTRType,
            adornmentToggle: assumptions.rehabContingencySTRType,
            adornmentName: 'rehabContingencySTRType',
            adornmentPosition: 'end',
            name: 'rehabContingencySTR',
            value: assumptions.rehabContingencySTR,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Short Term Rental'],
          },
          {
            dropdown: true,
            label: 'Rehab Contingency',
            adornment: assumptions.rehabContingencyBORType,
            adornmentToggle: assumptions.rehabContingencyBORType,
            adornmentName: 'rehabContingencyBORType',
            adornmentPosition: 'end',
            name: 'rehabContingencyBOR',
            value: assumptions.rehabContingencyBOR,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['BOR'],
          },
          {
            label: 'Holding Period (months)',
            adornment: '',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'holdingPeriod',
            value: assumptions.holdingPeriod,
            tip: 'A holding period is the amount of time the investment is held by an investor or the period between the purchase and sale of the property. Expressed in months.',
            filters: ['Fix & Flip'],
          },
          {
            label: 'Home Price Appreciation',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'homePriceAppreciation',
            value: assumptions.homePriceAppreciation,
            tip: 'The % of Appreciation in Total Dollar Value that the Home has gained or lost each year. Expressed as a percentage of ARV (after repair value). If your holding period is less than 12 months, this field will have no effect.',
            filters: [
              'Buy & Hold',
              'Turnkey End Buyer',
              'BRRRR',
              'Short Term Rental',
              'Build to Rent',
            ],
          },
          {
            label: 'Rental Rate Increase',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'growthRateIncome',
            value: assumptions.growthRateIncome,
            tip: 'The % that the annual income, from rent, has increased each year. Expressed as a percentage of gross rental income.',
            filters: [
              'Buy & Hold',
              'Turnkey End Buyer',
              'BRRRR',
              'Short Term Rental',
              'Build to Rent',
            ],
          },
          {
            label: 'Expense Rate Increase',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'growthRateExpense',
            value: assumptions.growthRateExpense,
            tip: 'The % increase in property expenses per year. Expressed as a percentage of gross rental income. If your holding period is less than 12 months, this field will have no effect.',
            filters: [
              'Buy & Hold',
              'Turnkey End Buyer',
              'BRRRR',
              'Short Term Rental',
              'Build to Rent',
            ],
          },
        ],
      },
      {
        sectionName: 'Screening Assumptions',
        description: 'Screening Assumptions',
        panel: 10,
        fields: [
          {
            dropdown: true,
            label: 'Est. Rent (monthly)',
            name: 'estimatedRent',
            adornment: assumptions.estimatedRentType,
            adornmentToggle: assumptions.estimatedRentType,
            adornmentName: 'estimatedRentType',
            adornmentPosition: 'start',
            value: assumptions.estimatedRent,
            sectionName: 'Screening Assumptions',
            tip: 'The estimated rent you will receive from a property based on the asking price.',
            filters: [
              'Buy & Hold',
              'Fix & Flip',
              'Turnkey End Buyer',
              'BRRRR',
              'Build to Rent',
            ],
          },
          {
            dropdown: true,
            label: 'Est. Rent (monthly)',
            name: 'estimatedRentBOR',
            adornment: assumptions.estimatedRentBORType,
            adornmentToggle: assumptions.estimatedRentBORType,
            adornmentName: 'estimatedRentBORType',
            adornmentPosition: 'start',
            value: assumptions.estimatedRentBOR,
            sectionName: 'Screening Assumptions',
            tip: 'The estimated rent you will receive from a property based on the asking price.',
            filters: ['BOR'],
          },
          {
            dropdown: true,
            name: 'avgRate.0',
            label: 'Avg Rate',
            value: get(assumptions, 'avgRate.0'),
            adornment: get(assumptions, 'avgRateType.0') || 'Daily',
            adornmentToggle: get(assumptions, 'avgRateType.0'),
            adornmentName: 'avgRateType.0',
            dropdown: true,
            sectionName: 'Screening Assumptions',
            tip: 'The amount you charge the occupant per day/week',
            filters: ['Short Term Rental'],
          },
          {
            dropdown: true,
            label: 'Est. Property Taxes (annual)',
            name: 'estimatedTaxes',
            adornment: assumptions.estimatedTaxesType,
            adornmentToggle: assumptions.estimatedTaxesType,
            adornmentName: 'estimatedTaxesType',
            adornmentPosition: 'start',
            value: assumptions.estimatedTaxes,
            tip: 'If no property taxes are found, we use this assumption to give you an estimate.',
            filters: [
              'Buy & Hold',
              'Turnkey End Buyer',
              'Fix & Flip',
              'BRRRR',
              'Short Term Rental',
              'Build to Rent',
            ],
          },
          {
            label: 'Est. Asking Price (% of ARV)',
            name: 'estimatedListPrice',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            value: assumptions.estimatedListPrice,
            tip: 'If no asking price is found.  This gives an estimate asking price based on a percentage of the ARV.',
            //"Used when the property isn't listed, or if we are restricted from getting the asking price for legal reasons. Calculated as a percentage of the ARV. If the ARV is $100k and you put 90%, your estimated asking price will be $90k.",
            filters: [
              'Buy & Hold',
              'Turnkey End Buyer',
              'Fix & Flip',
              'BRRRR',
              'Short Term Rental',
              'Wholesale',
              'BOR',
              'Astroflip',
            ],
          },
          {
            dropdown: true,
            label: 'Est. Rehab',
            adornment: assumptions.estimatedRehabType,
            adornmentToggle: assumptions.estimatedRehabType,
            adornmentName: 'estimatedRehabType',
            adornmentPosition: 'start',
            name: 'estimatedRehab',
            value: assumptions.estimatedRehab,
            sectionName: 'Screening Assumptions',
            tip: 'If no Rehab Amount is found, we use this assumption to give you an estimate',
            filters: ['Buy & Hold'],
          },
          {
            dropdown: true,
            label: 'Est. End Rehab',
            adornment: assumptions.estimatedRehabTurnkeyType,
            adornmentToggle: assumptions.estimatedRehabTurnkeyType,
            adornmentName: 'estimatedRehabTurnkeyType',
            adornmentPosition: 'start',
            name: 'estimatedRehabTurnkey',
            value: assumptions.estimatedRehabTurnkey,
            sectionName: 'Screening Assumptions',
            tip: 'If no Rehab Amount is found, we use this assumption to give you an estimate',
            filters: ['Turnkey End Buyer'],
          },
          {
            dropdown: true,
            label: 'Est. Rehab',
            adornment: assumptions.estimatedRehabFixFlipType,
            adornmentToggle: assumptions.estimatedRehabFixFlipType,
            adornmentName: 'estimatedRehabFixFlipType',
            adornmentPosition: 'start',
            name: 'estimatedRehabFixFlip',
            value: assumptions.estimatedRehabFixFlip,
            sectionName: 'Screening Assumptions',
            tip: 'If no Rehab Amount is found, we use this assumption to give you an estimate',
            filters: ['Fix & Flip'],
          },
          {
            dropdown: true,
            label: 'Est. Rehab',
            adornment: assumptions.estimatedRehabBRRRRType,
            adornmentToggle: assumptions.estimatedRehabBRRRRType,
            adornmentName: 'estimatedRehabBRRRRType',
            adornmentPosition: 'start',
            name: 'estimatedRehabBRRRR',
            value: assumptions.estimatedRehabBRRRR,
            sectionName: 'Screening Assumptions',
            tip: 'If no Rehab Amount is found, we use this assumption to give you an estimate',
            filters: ['BRRRR'],
          },
          {
            dropdown: true,
            label: 'Est. Rehab',
            adornment: assumptions.estimatedRehabSTRType,
            adornmentToggle: assumptions.estimatedRehabSTRType,
            adornmentName: 'estimatedRehabSTRType',
            adornmentPosition: 'start',
            name: 'estimatedRehabSTR',
            value: assumptions.estimatedRehabSTR,
            sectionName: 'Screening Assumptions',
            tip: 'If no Rehab Amount is found, we use this assumption to give you an estimate',
            filters: ['Short Term Rental'],
          },
          {
            dropdown: true,
            label: 'Est. Rehab',
            adornment: assumptions.estimatedRehabBORType,
            adornmentToggle: assumptions.estimatedRehabBORType,
            adornmentName: 'estimatedRehabBORType',
            adornmentPosition: 'start',
            name: 'estimatedRehabBOR',
            value: assumptions.estimatedRehabBOR,
            sectionName: 'Screening Assumptions',
            tip: 'If no Rehab Amount is found, we use this assumption to give you an estimate',
            filters: ['BOR'],
          },
          {
            label: 'Asking Price to Sell Ratio',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'listPriceToSalePriceRatio',
            value: assumptions.listPriceToSalePriceRatio,
            tip: 'When you eventually sell this house, what % of your asking price will it actually be sold for? Ex: A home listed with an asking price of $100k that ended up selling for $95k is a 95% ratio.',
            filters: ['Fix & Flip'],
          },
          {
            label: 'Offer to Asking Price Ratio',
            adornment: '%',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'offerToListPriceRatio',
            value: assumptions.offerToListPriceRatio,
            tip: 'The percentage of Asking Price you want your Purchase Price to be.  Ex:  90%, Asking Price = $100,000 and Purchase Price = $90,000.',
            filters: [
              'Buy & Hold',
              'Fix & Flip',
              'BRRRR',
              'Short Term Rental',
              'Wholesale',
              'BOR',
              'Astroflip',
            ],
          },
        ],
      },
      {
        sectionName: getStrategyName(
          'Wholesale',
          this.props?.session?.me?.activeOrg?.member,
        ),
        description: getStrategyName(
          'Wholesale',
          this.props?.session?.me?.activeOrg?.member,
        ),
        panel: 11,
        fields: [
          {
            // dropdown: true,
            type: 'select',
            label: 'Rehab Type',
            name: 'rehabWholesaleType',
            options: assumptionsOptions(
              this.props?.session.me.activeOrg,
            )?.rehabOptions,
            value: assumptions.rehabWholesaleType,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Wholesale'],
          },
          {
            // dropdown: true,
            label: 'Rehab Tier',
            name: 'rehabTierWholesale',
            options: rehabTiers,
            optionName: 'rehabTiers',
            memberOptions: memberRehabTiers,
            dynamicMemberOptions: dynamicMemberRehabTiers,
            type: 'text',
            removeOption: true,
            value: assumptions.rehabTierWholesale,
            sectionName: 'Wholesale',
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Wholesale'],
          },
          {
            // dropdown: true,
            label: 'Rehab Decade Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rehabDecadeCostWholesale',
            value: assumptions.rehabDecadeCostWholesale,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Wholesale'],
          },
          {
            dropdown: true,
            label: 'Rehab Contingency',
            adornment: assumptions.rehabContingencyWholesaleType,
            adornmentToggle:
              assumptions.rehabContingencyWholesaleType,
            adornmentName: 'rehabContingencyWholesaleType',
            adornmentPosition: 'end',
            name: 'rehabContingencyWholesale',
            value: assumptions.rehabContingencyWholesale,
            // tip: 'The estimated cost of rehab for the buyer in the wholesale process.',
            filters: ['Wholesale'],
          },
          // {
          //   dropdown: true,
          //   label: 'Buyer Profit',
          //   name: 'buyerProfit',
          //   adornment: assumptions.buyerProfitType,
          //   adornmentToggle: assumptions.buyerProfitType,
          //   adornmentName: 'buyerProfitType',
          //   adornmentPosition: 'start',
          //   value: assumptions.buyerProfit,
          //   sectionName: 'Wholesale',
          //   tip: 'The targeted percentage profit the buyer expects.',
          //   filters: ['Wholesale'],
          // },
          {
            dropdown: true,
            label: 'Closing Cost',
            name: 'closingCostWholesale',
            adornment: assumptions.closingCostWholesaleType,
            adornmentToggle: assumptions.closingCostWholesaleType,
            adornmentName: 'closingCostWholesaleType',
            adornmentPosition: 'start',
            value: assumptions.closingCostWholesale,
            sectionName: 'Wholesale',
            tip: 'The estimated closing cost the end buyer will have for a wholesale property. Expressed as a dollar amount or percentage of ARV minus the rehab.',
            filters: ['Wholesale'],
          },
          {
            dropdown: true,
            label: 'Assignment Fee',
            name: 'assignmentFee',
            adornment: assumptions.assignmentFeeType,
            adornmentToggle: assumptions.assignmentFeeType,
            adornmentName: 'assignmentFeeType',
            adornmentPosition: 'start',
            value: assumptions.assignmentFee,
            sectionName: 'Wholesale',
            tip: 'The amount that you, the seller, expects to make on the wholesale property. Expressed as a dollar amount or percentage of ARV minus the rehab and closing costs.',
            filters: ['Wholesale'],
          },
        ],
      },
      {
        sectionName: getStrategyName(
          'Astroflip',
          this.props?.session?.me?.activeOrg?.member,
        ),
        description: getStrategyName(
          'Astroflip',
          this.props?.session?.me?.activeOrg?.member,
        ),
        panel: 11,
        fields: [
          {
            // dropdown: true,
            type: 'select',
            label: 'Rehab Type',
            name: 'rehabAstroFlipType',
            options: [
              {
                value: 'singleRehabValue',
                label: 'Single Rehab Value',
              },
              {
                value: 'astroFlipRehab',
                label: 'Astroflip Rehab',
              },
            ],
            value: assumptions.rehabAstroFlipType,
            filters: ['Astroflip'],
          },
          {
            // dropdown: true,
            label: 'Rehab Tier',
            name: 'rehabTierAstroFlip',
            options: rehabTiers,
            optionName: 'rehabTiers',
            memberOptions: memberRehabTiers,
            dynamicMemberOptions: dynamicMemberRehabTiers,
            type: 'text',
            removeOption: true,
            value: assumptions.rehabTierAstroFlip,
            sectionName: 'Astroflip',
            filters: ['Astroflip'],
          },
          {
            type: 'number',
            name: 'additionalSqftAstroFlip',
            label: 'Additional Sqft',
            value: assumptions.additionalSqftAstroFlip,
            sectionName: 'Astroflip',
            filters: ['Astroflip'],
          },
          {
            // dropdown: true,
            label: 'Rehab Decade Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'rehabDecadeCostAstroFlip',
            value: assumptions.rehabDecadeCostAstroFlip,
            filters: ['Astroflip'],
          },
          {
            dropdown: true,
            label: 'Rehab Contingency',
            adornment: assumptions.rehabContingencyAstroFlipType,
            adornmentToggle:
              assumptions.rehabContingencyAstroFlipType,
            adornmentName: 'rehabContingencyAstroFlipType',
            adornmentPosition: 'end',
            name: 'rehabContingencyAstroFlip',
            value: assumptions.rehabContingencyAstroFlip,
            filters: ['Astroflip'],
          },
          {
            dropdown: true,
            label: 'Buyer Profit',
            name: 'belowAstroFlipBuyerProfit',
            adornment: assumptions.belowAstroFlipBuyerProfitType,
            adornmentToggle:
              assumptions.belowAstroFlipBuyerProfitType,
            adornmentName: 'belowAstroFlipBuyerProfitType',
            adornmentPosition: 'start',
            value: assumptions.belowAstroFlipBuyerProfit,
            sectionName: 'Astroflip',
            tip: 'The targeted percentage profit the buyer expects.',
            filters: ['Astroflip'],
          },
          {
            dropdown: true,
            label: 'Closing Cost',
            name: 'closingCostAstroFlip',
            adornment: assumptions.closingCostAstroFlipType,
            adornmentToggle: assumptions.closingCostAstroFlipType,
            adornmentName: 'closingCostAstroFlipType',
            adornmentPosition: 'start',
            value: assumptions.closingCostAstroFlip,
            sectionName: 'AstroFlip',
            tip: 'The estimated closing cost the end buyer will have for a AstroFlip property. Expressed as a dollar amount or percentage of ARV minus the rehab.',
            filters: ['Astroflip'],
          },
          {
            dropdown: true,
            label: 'Assignment Fee',
            name: 'assignmentFeeAstroFlip',
            adornment: assumptions.assignmentFeeAstroFlipType,
            adornmentToggle: assumptions.assignmentFeeAstroFlipType,
            adornmentName: 'assignmentFeeAstroFlipType',
            adornmentPosition: 'start',
            value: assumptions.assignmentFeeAstroFlip,
            sectionName: 'AstroFlip',
            tip: 'The amount that you, the seller, expects to make on the AstroFlip property. Expressed as a dollar amount or percentage of ARV minus the rehab and closing costs.',
            filters: ['Astroflip'],
          },
          {
            dropdown: true,
            label: 'Carry Cost',
            name: 'carryCostAstroFlip',
            adornment: assumptions.carryCostAstroFlipType,
            adornmentToggle: assumptions.carryCostAstroFlipType,
            adornmentName: 'carryCostAstroFlipType',
            adornmentPosition: 'start',
            value: assumptions.carryCostAstroFlip,
            sectionName: 'AstroFlip',
            tip: 'The amount that the endbuyer can expect to pay for holding the property during fix & flip of the AstroFlip property. Expressed as a dollar amount or percentage of ARV minus the rehab and closing costs.',
            filters: ['Astroflip'],
          },
        ],
      },
      {
        sectionName:
          'Targets: ' +
          getStrategyName(
            'Buy & Hold',
            this.props?.session?.me?.activeOrg?.member,
          ),
        description:
          'Targets: ' +
          getStrategyName(
            'Buy & Hold',
            this.props?.session?.me?.activeOrg?.member,
          ) +
          ' panel',
        panel: 12,
        fields: [
          {
            label: 'Min Net Monthly Cashflow',
            name: 'belowCashFlow',
            value: assumptions.belowCashFlow,
            // marks: cashFlowMarks,
            min: 100,
            max: 2000,
            minLabel: '$100',
            maxLabel: '$2,000',
            step: 100,
            valueLabelFormat: (x) =>
              x >= 1000 ? `${x / 1000}k` : `${x}`,
            sectionName: 'Targets',
            tip: `Net cashflow is the amount of money left after the mortgage and any other expenses are paid.`,
            filters: ['Buy & Hold'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Mortgage
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  12 months
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Monthly Cashflow
                </Typography>
              </>
            ),
          },
          {
            label: 'Min Cash on Cash',
            name: 'belowCashOnCashReturn',
            value: assumptions.belowCashOnCashReturn,
            // marks: buyHoldCashOnCashReturnMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `A cash-on-cash return calculates the return on the money put in by the investor on a property with a mortgage. Commonly referred to as a Leveraged Return.`,
            filters: ['Buy & Hold'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Mortgage
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  Total Out-of-Pocket
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Cash on Cash Return
                </Typography>
              </>
            ),
          },
          {
            label: 'Min IRR',
            name: 'belowIRR',
            value: assumptions.belowIRR,
            // marks: capRateMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `IRR measures an investment’s annual return throughout the entire property ownership time frame. Unlike a cash-on-cash return or cap rate that measures returns in annual segments, IRR weighs total earnings from the day you purchase your property to the day you sell it.`,
            filters: ['Buy & Hold'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Initial Investment (Out of pocket)
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Monthly Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Rent Growth Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Monthly Expenses
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Expenses Growth Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  After Repair Value
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Appreciation Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Holding Period (years)
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Future property sale
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  IRR
                </Typography>
              </>
            ),
          },
          {
            label: 'Min Yield-on-Cost',
            name: 'belowCapRate',
            value: assumptions.belowCapRate,
            // marks: capRateMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `The Yield-on-Cost is the rate of return an investor can expect from an investment in real estate, assuming they pay all cash.`,
            filters: ['Buy & Hold'],
            // sublabel: (
            //   <Grid item xs={6} style={{ fontWeight: '800' }}>
            //     Buy & Hold
            //   </Grid>
            // ),
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  All-in-cost
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Yield-on-Cost
                </Typography>
              </>
            ),
          },
        ],
      },
      {
        sectionName:
          'Targets: ' +
          getStrategyName(
            'Fix & Flip',
            this.props?.session?.me?.activeOrg?.member,
          ),
        description:
          'Targets: ' +
          getStrategyName(
            'Fix & Flip',
            this.props?.session?.me?.activeOrg?.member,
          ) +
          ' panel',
        panel: 13,
        fields: this.props?.session.me.activeOrg.isRealeflow
          ? [
              {
                label: 'Min Net Profit',
                name: 'belowNetProfitPep',
                value: assumptions.belowNetProfitPep,
                // marks: netROIMarks,
                min: 0,
                max: 25,
                minLabel: '0%',
                maxLabel: '25%',
                sectionName: 'Targets',
                tip: 'Profit as a percentage of ARV',
                filters: ['Fix & Flip'],
                divider: <div className="lll" />,
                // sublabel: (
                //   <Grid item xs={6} style={{ fontWeight: '800' }}>
                //     Flip Analysis
                //   </Grid>
                // ),
                calculation: (
                  <>
                    <Typography style={{ color: '#9198a8' }}>
                      Resale Price
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Sales Commission
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Disposititon Fee
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Total Out-of-Pocket
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Remaining Loan Balance
                    </Typography>
                    <Divider style={{ backgroundColor: '#9198a8' }} />
                    <Typography style={{ color: 'white' }}>
                      Net Profit
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      After Repair Value
                    </Typography>
                    <Typography style={{ color: 'white' }}>
                      Net Profit to ARV
                    </Typography>
                  </>
                ),
              },
              {
                label: 'Min Holding + Closing Cost',
                name: 'belowHoldingClosingCostPep',
                value: assumptions.belowHoldingClosingCostPep,
                // marks: aRVMarks,
                min: 0,
                max: 25,
                minLabel: '0%',
                maxLabel: '25%',
                sectionName: 'Targets',
                tip: `Holding + Closing Cost as a percentage of ARV`,
                filters: ['Fix & Flip'],
                calculation: (
                  <>
                    <Typography style={{ color: '#9198a8' }}>
                      Closing Costs
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      +Holding Expenses
                    </Typography>
                    <Divider style={{ backgroundColor: '#9198a8' }} />
                    <Typography style={{ color: 'white' }}>
                      Holding + Closing
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      After Repair Value
                    </Typography>
                    <Divider style={{ backgroundColor: '#9198a8' }} />
                    <Typography style={{ color: 'white' }}>
                      Holding + Closing to ARV
                    </Typography>
                  </>
                ),
              },
              {
                label: 'Total Cost Financing',
                name: 'belowTotalCostFinancingPep',
                value: assumptions.belowTotalCostFinancingPep,
                // marks: saleProceedsMarks,
                min: 0,
                max: 25,
                minLabel: '0%',
                maxLabel: '25%',
                sectionName: 'Targets',
                tip: 'Total Cost Financing as a percentage of ARV',
                filters: ['Fix & Flip'],
                calculation: (
                  <>
                    <Typography style={{ color: '#9198a8' }}>
                      Financing Fee
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      +Loan Points
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      +Remaining Loan Balance
                    </Typography>
                    <Divider style={{ backgroundColor: '#9198a8' }} />
                    <Typography style={{ color: 'white' }}>
                      Total Cost Financing
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      After Repair Value
                    </Typography>
                    <Divider style={{ backgroundColor: '#9198a8' }} />
                    <Typography style={{ color: 'white' }}>
                      Total Cost Financing to ARV
                    </Typography>
                  </>
                ),
              },
            ]
          : [
              {
                label: 'Min Net ROI',
                name: 'belowNetROI',
                value: assumptions.belowNetROI,
                // marks: netROIMarks,
                min: 0,
                max: 25,
                minLabel: '0%',
                maxLabel: '25%',
                sectionName: 'Targets',
                tip: 'Return on investment (ROI) indicates the percentage of investment money that is recouped after the deduction of associated costs.',
                filters: ['Fix & Flip'],
                divider: <div className="lll" />,
                // sublabel: (
                //   <Grid item xs={6} style={{ fontWeight: '800' }}>
                //     Flip Analysis
                //   </Grid>
                // ),
                calculation: (
                  <>
                    <Typography style={{ color: '#9198a8' }}>
                      Resale Price
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Sales Commission
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Disposititon Fee
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Total Out-of-Pocket
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Remaining Loan Balance
                    </Typography>
                    <Divider style={{ backgroundColor: '#9198a8' }} />
                    <Typography style={{ color: 'white' }}>
                      Net Profit
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      All-in-cost
                    </Typography>
                    <Typography style={{ color: 'white' }}>
                      Net ROI
                    </Typography>
                  </>
                ),
              },
              {
                label: 'Min All-In Cost to ARV',
                name: 'belowARV',
                value: assumptions.belowARV,
                // marks: aRVMarks,
                min: 50,
                max: 90,
                minLabel: '50%',
                maxLabel: '90%',
                sectionName: 'Targets',
                tip: `The All-In Cost to ARV (After Repair Value) that you purchase the property for. There is a popular term referred to as the “70% rule” when applied to fix and flipping houses.  The 70% rule states that an investor should pay 70 percent of the ARV of a property, minus the repairs needed.  The ARV is the after repaired value and is what a home is worth after it is fully repaired.  This “rule” says 70% but we have still found success at 80% of the ARV.`,
                filters: ['Fix & Flip'],
                calculation: (
                  <>
                    <Typography style={{ color: '#9198a8' }}>
                      Purchase Price
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      +Acquisition Commission
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      +Acquisition Fee
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      +Closing Costs
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      +Loan Points
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      +Rehab Amount
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      +Holding Expenses
                    </Typography>
                    <Divider style={{ backgroundColor: '#9198a8' }} />
                    <Typography style={{ color: 'white' }}>
                      All-in Cost
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'divide']}
                        style={{ marginRight: 3 }}
                      />
                      After Repair Value
                    </Typography>
                    <Divider style={{ backgroundColor: '#9198a8' }} />
                    <Typography style={{ color: 'white' }}>
                      All-in Cost to ARV
                    </Typography>
                  </>
                ),
              },
              {
                label: 'Net Profit',
                name: 'belowSaleProceeds',
                value: assumptions.belowSaleProceeds,
                // marks: saleProceedsMarks,
                min: 0,
                max: 35000,
                minLabel: '$ 0',
                maxLabel: '$ 35k',
                step: 1000,
                valueLabelFormat: (x) =>
                  x >= 1000 ? `${x / 1000}k` : `${x}`,
                sectionName: 'Targets',
                tip: 'The amount of money left over from selling your home after accounting for the remaining mortgage balance, closing costs, and realtor fees.',
                filters: ['Fix & Flip'],
                calculation: (
                  <>
                    <Typography style={{ color: '#9198a8' }}>
                      Resale Price
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Sales Commission
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Disposititon Fee
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Total Out-of-Pocket
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Remaining Loan Balance
                    </Typography>
                    <Divider style={{ backgroundColor: '#9198a8' }} />
                    <Typography style={{ color: 'white' }}>
                      Net Profit
                    </Typography>
                  </>
                ),
              },
            ],
      },
      {
        sectionName:
          'Targets: ' +
          getStrategyName(
            'Turnkey',
            this.props?.session?.me?.activeOrg?.member,
          ),
        description:
          'Targets: ' +
          getStrategyName(
            'Turnkey',
            this.props?.session?.me?.activeOrg?.member,
          ) +
          ' panel',
        panel: 14,
        fields: [
          {
            label: 'Min Cap Rate',
            name: 'belowSellingCapRate',
            value: assumptions.belowSellingCapRate,
            // marks: sellingCapRateMarks,
            min: 0,
            max: 25,
            minLabel: '0%',
            maxLabel: '25%',
            sectionName: 'Targets',
            tip: 'The capitalization rate (cap rate) is utilized for several reasons inside real estate investing including real estate valuation, comparing properties to each other, analyzing a market, etc. The higher the cap rate the higher your returns/risk.',
            filters: ['Turnkey End Buyer'],
            divider: <div className="lll" />,
            sublabel: (
              <Grid item xs={6} style={{ fontWeight: '800' }}>
                Turnkey: End Buyer
              </Grid>
            ),
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  All-in-cost
                </Typography>
                <Typography style={{ color: 'white' }}>
                  Cap Rate
                </Typography>
              </>
            ),
          },
          {
            label: 'Min Cash on Cash',
            name: 'belowSellingCashOnCashReturn',
            value: assumptions.belowSellingCashOnCashReturn,
            // marks: fixFlipCashOnCashReturnMarks,
            min: 0,
            max: 25,
            minLabel: '0%',
            maxLabel: '25%',
            sectionName: 'Targets',
            tip: 'A cash-on-cash return calculates the return on the money put in by the investor on a property with a mortgage.  Commonly referred to as a Leveraged Return.',
            filters: ['Turnkey End Buyer'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Mortgage
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  Total Out-of-Pocket
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Cash on Cash Return
                </Typography>
              </>
            ),
          },
          {
            label: 'Min IRR',
            name: 'belowIRRTurnkey',
            value: assumptions.belowIRRTurnkey,
            // marks: capRateMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `The internal rate of return (IRR) measures the rate of return earned on an investment during a specific time frame. It includes the recurring cash flow (rent, etc.) and any profits made from the property’s sale. Simply put, IRR represents a property’s net cash flow and expected appreciation divided by the hold time. While IRR is a helpful way to estimate your asset’s performance over the entire time that you plan to hold it, it relies heavily on forecasting years of cash flow and a projected sale price.`,
            filters: ['Turnkey End Buyer'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Initial Investment (Out of pocket)
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Monthly Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Rent Growth Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Monthly Expenses
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Expenses Growth Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  After Repair Value
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Appreciation Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Holding Period (years)
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Future property sale
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  IRR
                </Typography>
              </>
            ),
          },
          {
            label: 'Min Net Monthly Cashflow',
            name: 'belowCashFlowTurnkey',
            value: assumptions.belowCashFlowTurnkey,
            //marks: cashFlowTurnkeyMarks,
            min: 0,
            max: 2000,
            minLabel: '$ 0k',
            maxLabel: '$ 2k',
            step: 100,
            valueLabelFormat: (x) =>
              x >= 1000 ? `${x / 1000}k` : `${x}`,
            sectionName: 'Targets',
            tip: 'Net cashflow is the amount of money left after the mortgage and any other expenses are paid.',
            filters: ['Turnkey End Buyer'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Mortgage
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  12 months
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Monthly Cashflow
                </Typography>
              </>
            ),
          },
        ],
      },
      {
        sectionName:
          'Targets: ' +
          getStrategyName(
            'BRRRR',
            this.props?.session?.me?.activeOrg?.member,
          ),
        description:
          'Targets: ' +
          getStrategyName(
            'BRRRR',
            this.props?.session?.me?.activeOrg?.member,
          ) +
          ' panel',
        panel: 15,
        fields: [
          {
            label: 'Min Net Monthly Cashflow',
            name: 'belowCashFlowBRRRR',
            value: assumptions.belowCashFlowBRRRR,
            // marks: cashFlowMarks,
            min: 100,
            max: 2000,
            minLabel: '$100',
            maxLabel: '$2,000',
            step: 100,
            valueLabelFormat: (x) =>
              x >= 1000 ? `${x / 1000}k` : `${x}`,
            sectionName: 'Targets',
            tip: `Net cashflow is the amount of money left after the mortgage and any other expenses are paid.`,
            filters: ['BRRRR'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Refinance Mortgage
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  12 months
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Monthly Cashflow
                </Typography>
              </>
            ),
          },
          {
            label: 'Min Cash on Cash',
            name: 'belowCashOnCashReturnBRRRR',
            value: assumptions.belowCashOnCashReturnBRRRR,
            // marks: buyHoldCashOnCashReturnMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `A cash-on-cash return calculates the return on the money put in by the investor on a property with a mortgage. Commonly referred to as a Leveraged Return.`,
            filters: ['BRRRR'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Refinance Mortgage
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  Total Out-of-Pocket
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Cash on Cash Return
                </Typography>
              </>
            ),
          },
          {
            label: 'Min IRR',
            name: 'belowIRRBRRRR',
            value: assumptions.belowIRRBRRRR,
            // marks: capRateMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `IRR measures an investment’s annual return throughout the entire property ownership time frame. Unlike a cash-on-cash return or cap rate that measures returns in annual segments, IRR weighs total earnings from the day you purchase your property to the day you sell it.`,
            filters: ['BRRRR'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Initial Investment (Out of pocket)
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Monthly Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Rent Growth Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Monthly Expenses
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Expenses Growth Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  After Repair Value
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Appreciation Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Holding Period (years)
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Future property sale
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  IRR
                </Typography>
              </>
            ),
          },
          {
            label: 'Min Yield-on-Cost',
            name: 'belowCapRateBRRRR',
            value: assumptions.belowCapRateBRRRR,
            // marks: capRateMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `The Yield-on-Cost is the rate of return an investor can expect from an investment in real estate, assuming they pay all cash.`,
            filters: ['BRRRR'],
            // sublabel: (
            //   <Grid item xs={6} style={{ fontWeight: '800' }}>
            //     Buy & Hold
            //   </Grid>
            // ),
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  All-in-cost
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Yield-on-Cost
                </Typography>
              </>
            ),
          },
        ],
      },
      {
        sectionName:
          'Targets: ' +
          getStrategyName(
            'Short Term Rental',
            this.props?.session?.me?.activeOrg?.member,
          ),
        description:
          'Targets: ' +
          getStrategyName(
            'Short Term Rental',
            this.props?.session?.me?.activeOrg?.member,
          ) +
          ' panel',
        panel: 16,
        fields: [
          {
            label: 'Min Net Monthly Cashflow',
            name: 'belowCashFlowSTR',
            value: assumptions.belowCashFlowSTR,
            // marks: cashFlowMarks,
            min: 100,
            max: 2000,
            minLabel: '$100',
            maxLabel: '$2,000',
            step: 100,
            valueLabelFormat: (x) =>
              x >= 1000 ? `${x / 1000}k` : `${x}`,
            sectionName: 'Targets',
            tip: `Net cashflow is the amount of money left after the mortgage and any other expenses are paid.`,
            filters: ['Short Term Rental'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Cohosting
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Mortgage
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  12 months
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Monthly Cashflow
                </Typography>
              </>
            ),
          },
          {
            label: 'Min Cash on Cash',
            name: 'belowCashOnCashReturnSTR',
            value: assumptions.belowCashOnCashReturnSTR,
            // marks: buyHoldCashOnCashReturnMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `A cash-on-cash return calculates the return on the money put in by the investor on a property with a mortgage. Commonly referred to as a Leveraged Return.`,
            filters: ['Short Term Rental'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Cohosting
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Mortgage
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  Total Out-of-Pocket
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Cash on Cash Return
                </Typography>
              </>
            ),
          },
          {
            label: 'Min IRR',
            name: 'belowIRRSTR',
            value: assumptions.belowIRRSTR,
            // marks: capRateMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `IRR measures an investment’s annual return throughout the entire property ownership time frame. Unlike a cash-on-cash return or cap rate that measures returns in annual segments, IRR weighs total earnings from the day you purchase your property to the day you sell it.`,
            filters: ['Short Term Rental'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Initial Investment (Out of pocket)
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Monthly Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Rent Growth Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Monthly Expenses
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Expenses Growth Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  After Repair Value
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Appreciation Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Holding Period (years)
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Future property sale
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  IRR
                </Typography>
              </>
            ),
          },
          {
            label: 'Min Yield-on-Cost',
            name: 'belowCapRateSTR',
            value: assumptions.belowCapRateSTR,
            // marks: capRateMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `The Yield-on-Cost is the rate of return an investor can expect from an investment in real estate, assuming they pay all cash.`,
            filters: ['Short Term Rental'],
            // sublabel: (
            //   <Grid item xs={6} style={{ fontWeight: '800' }}>
            //     Buy & Hold
            //   </Grid>
            // ),
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Cohosting
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  All-in-cost
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Yield-on-Cost
                </Typography>
              </>
            ),
          },
        ],
      },
      {
        sectionName:
          'Targets: ' +
          getStrategyName(
            'Build to Rent',
            this.props?.session?.me?.activeOrg?.member,
          ),
        description:
          'Targets: ' +
          getStrategyName(
            'Build to Rent',
            this.props?.session?.me?.activeOrg?.member,
          ) +
          ' panel',
        panel: 17,
        fields: [
          {
            label: 'Min Cap Rate',
            name: 'belowB2RCapRate',
            value: assumptions.belowB2RCapRate,
            // marks: sellingCapRateMarks,
            min: 0,
            max: 25,
            minLabel: '0%',
            maxLabel: '25%',
            sectionName: 'Targets',
            tip: 'The capitalization rate (cap rate) is utilized for several reasons inside real estate investing including real estate valuation, comparing properties to each other, analyzing a market, etc. The higher the cap rate the higher your returns/risk.',
            filters: ['Build to Rent'],
            divider: <div className="lll" />,
            sublabel: (
              <Grid item xs={6} style={{ fontWeight: '800' }}>
                Turnkey: End Buyer
              </Grid>
            ),
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  All-in-cost
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Cap Rate
                </Typography>
              </>
            ),
          },
          {
            label: 'Min Cash on Cash',
            name: 'belowB2RCashOnCashReturn',
            value: assumptions.belowB2RCashOnCashReturn,
            // marks: fixFlipCashOnCashReturnMarks,
            min: 0,
            max: 25,
            minLabel: '0%',
            maxLabel: '25%',
            sectionName: 'Targets',
            tip: 'A cash-on-cash return calculates the return on the money put in by the investor on a property with a mortgage.  Commonly referred to as a Leveraged Return.',
            filters: ['Build to Rent'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Mortgage
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  Total Out-of-Pocket
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Cash on Cash Return
                </Typography>
              </>
            ),
          },
          {
            label: 'Min IRR',
            name: 'belowB2RIRR',
            value: assumptions.belowB2RIRR,
            // marks: capRateMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `The internal rate of return (IRR) measures the rate of return earned on an investment during a specific time frame. It includes the recurring cash flow (rent, etc.) and any profits made from the property’s sale. Simply put, IRR represents a property’s net cash flow and expected appreciation divided by the hold time. While IRR is a helpful way to estimate your asset’s performance over the entire time that you plan to hold it, it relies heavily on forecasting years of cash flow and a projected sale price.`,
            filters: ['Build to Rent'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Initial Investment (Out of pocket)
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Monthly Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Rent Growth Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Monthly Expenses
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Expenses Growth Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  After Repair Value
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Appreciation Rate
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Holding Period (years)
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Future property sale
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  IRR
                </Typography>
              </>
            ),
          },
          {
            label: 'Min Net Monthly Cashflow',
            name: 'belowB2RCashFlow',
            value: assumptions.belowB2RCashFlow,
            //marks: cashFlowTurnkeyMarks,
            min: 0,
            max: 2000,
            minLabel: '$ 0k',
            maxLabel: '$ 2k',
            step: 100,
            valueLabelFormat: (x) =>
              x >= 1000 ? `${x / 1000}k` : `${x}`,
            sectionName: 'Targets',
            tip: 'Net cashflow is the amount of money left after the mortgage and any other expenses are paid.',
            filters: ['Build to Rent'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Mortgage
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  12 months
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Monthly Cashflow
                </Typography>
              </>
            ),
          },
          // {
          //   label: 'Min Net Profit',
          //   name: 'belowB2RNetProfit',
          //   value: assumptions.belowB2RNetProfit,
          //   //marks: cashFlowTurnkeyMarks,
          //   min: 25000,
          //   max: 75000,
          //   minLabel: '$ 25k',
          //   maxLabel: '$ 75k',
          //   step: 100,
          //   valueLabelFormat: x => x >= 1000 ? `${x / 1000}k` : `${x}`,
          //   sectionName: 'Targets',
          //   tip: 'The amount of money left over from selling your home after accounting for the remaining mortgage balance, closing costs, and realtor fees.',
          //   filters: ['Build to Rent'],
          // },
        ],
      },
      {
        sectionName:
          'Targets: ' +
          getStrategyName(
            'Wholesale',
            this.props?.session?.me?.activeOrg?.member,
          ),
        description:
          'Targets: ' +
          getStrategyName(
            'Wholesale',
            this.props?.session?.me?.activeOrg?.member,
          ) +
          ' panel',
        panel: 18,
        fields: [
          {
            label: 'Net Profit',
            name: 'belowWholesaleSaleProceeds',
            value: assumptions.belowWholesaleSaleProceeds,
            // marks: saleProceedsMarks,
            min: 0,
            max: 35000,
            minLabel: '$ 0',
            maxLabel: '$ 35k',
            step: 1000,
            valueLabelFormat: (x) =>
              x >= 1000 ? `${x / 1000}k` : `${x}`,
            sectionName: 'Targets',
            tip: 'The amount of money left over after subtracting the Purchase Price from the Resale Price',
            filters: ['Wholesale'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Resale Price
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Purchase Price
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Profit
                </Typography>
              </>
            ),
          },
          {
            label: 'Min Buyer Profit',
            name: 'belowBuyerProfit',
            value: assumptions.belowBuyerProfit,
            // marks: capRateMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `The targeted percentage profit the buyer expects.`,
            filters: ['Wholesale'],
            // sublabel: (
            //   <Grid item xs={6} style={{ fontWeight: '800' }}>
            //     Buy & Hold
            //   </Grid>
            // ),
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  After Repair Value:
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Rehab Cost:
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Resell Price:
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Closing Cost:
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Buyer Profit
                </Typography>
              </>
            ),
          },
        ],
      },
      {
        sectionName:
          'Targets: ' +
          getStrategyName(
            'BOR',
            this.props?.session?.me?.activeOrg?.member,
          ),
        description:
          'Targets: ' +
          getStrategyName(
            'BOR',
            this.props?.session?.me?.activeOrg?.member,
          ) +
          ' panel',
        panel: 19,
        fields: [
          {
            label: 'Min Average Annual Return',
            name: 'belowAverageAnnualReturnBOR',
            value: assumptions.belowAverageAnnualReturnBOR,
            // marks: saleProceedsMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: 'The average annual return',
            filters: ['BOR'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Total Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -All in cost
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: '#9198a8' }}>
                  Total Net
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  All in cost
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: '#9198a8' }}>
                  ROI
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  Term
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Average Annual Return
                </Typography>
              </>
            ),
          },
          {
            label: 'Min IRR',
            name: 'belowIRRBOR',
            value: assumptions.belowIRRBOR,
            // marks: capRateMarks,
            min: 0,
            max: 30,
            minLabel: '0%',
            maxLabel: '30%',
            sectionName: 'Targets',
            tip: `IRR measures an investment’s annual return throughout the entire property ownership time frame. Unlike a cash-on-cash return or cap rate that measures returns in annual segments, IRR weighs total earnings from the day you purchase your property to the day you sell it..`,
            filters: ['BOR'],
            // sublabel: (
            //   <Grid item xs={6} style={{ fontWeight: '800' }}>
            //     Buy & Hold
            //   </Grid>
            // ),
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Initial Investment
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Down Payment
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  Holding Period (years)
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  IRR
                </Typography>
              </>
            ),
          },
        ],
      },
      {
        sectionName:
          'Targets: ' +
          getStrategyName(
            'Astroflip',
            this.props?.session?.me?.activeOrg?.member,
          ),
        description:
          'Targets: ' +
          getStrategyName(
            'Astroflip',
            this.props?.session?.me?.activeOrg?.member,
          ) +
          ' panel',
        panel: 18,
        fields: [
          {
            label: 'Net Profit',
            name: 'belowAstroFlipSaleProceeds',
            value: assumptions.belowAstroFlipSaleProceeds,
            // marks: saleProceedsMarks,
            min: 0,
            max: 35000,
            minLabel: '$ 0',
            maxLabel: '$ 35k',
            step: 1000,
            valueLabelFormat: (x) =>
              x >= 1000 ? `${x / 1000}k` : `${x}`,
            sectionName: 'Targets',
            tip: 'The amount of money left over after subtracting the Purchase Price from the Resale Price',
            filters: ['Astroflip'],
            calculation: (
              <>
                <Typography style={{ color: '#9198a8' }}>
                  Resale Price
                </Typography>
                <Typography style={{ color: '#9198a8' }}>
                  -Purchase Price
                </Typography>
                <Divider style={{ backgroundColor: '#9198a8' }} />
                <Typography style={{ color: 'white' }}>
                  Net Profit
                </Typography>
              </>
            ),
          },
          assumptions.belowAstroFlipBuyerProfitType === '%'
            ? {
                label: 'Min Buyer Profit',
                name: 'belowAstroFlipBuyerProfit',
                value: assumptions.belowAstroFlipBuyerProfit,
                // marks: capRateMarks,
                min: 0,
                max: 30,
                minLabel: '0%',
                maxLabel: '30%',
                sectionName: 'Targets',
                tip: `The targeted percentage profit the buyer expects.`,
                filters: ['Astroflip'],
                // sublabel: (
                //   <Grid item xs={6} style={{ fontWeight: '800' }}>
                //     Buy & Hold
                //   </Grid>
                // ),
                calculation: (
                  <>
                    <Typography style={{ color: '#9198a8' }}>
                      After Repair Value:
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Rehab Cost:
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Resell Price:
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Closing Cost:
                    </Typography>
                    <Divider style={{ backgroundColor: '#9198a8' }} />
                    <Typography style={{ color: 'white' }}>
                      Buyer Profit
                    </Typography>
                  </>
                ),
              }
            : {
                label: 'Min Buyer Profit',
                name: 'belowAstroFlipBuyerProfit',
                value: assumptions.belowAstroFlipBuyerProfit,
                // marks: capRateMarks,
                min: 0,
                max: 35000,
                minLabel: '$ 0',
                maxLabel: '$ 35k',
                step: 1000,
                valueLabelFormat: (x) =>
                  x >= 1000 ? `${x / 1000}k` : `${x}`,
                sectionName: 'Targets',
                tip: `The targeted percentage profit the buyer expects.`,
                filters: ['Astroflip'],
                // sublabel: (
                //   <Grid item xs={6} style={{ fontWeight: '800' }}>
                //     Buy & Hold
                //   </Grid>
                // ),
                calculation: (
                  <>
                    <Typography style={{ color: '#9198a8' }}>
                      After Repair Value:
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Rehab Cost:
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Resell Price:
                    </Typography>
                    <Typography style={{ color: '#9198a8' }}>
                      -Closing Cost:
                    </Typography>
                    <Divider style={{ backgroundColor: '#9198a8' }} />
                    <Typography style={{ color: 'white' }}>
                      Buyer Profit
                    </Typography>
                  </>
                ),
              },
        ],
      },
    ]

    let pepDealsauceEstimateSections = [
      {
        sectionName: 'Roofing',
        description: 'Roofing panel',
        panel: 'e0',
        fields: [
          {
            label: 'Roof Replacement Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateRoofCostPerSqft',
            value: assumptions.estimateRoofCostPerSqft,
            sectionName: 'Roofing',
          },
          {
            label: 'Roof Removal Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateRoofRemoval',
            value: assumptions.estimateRoofRemoval,
            sectionName: 'Roofing',
          },
          {
            label: 'Roof Cert Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateRoofCert',
            value: assumptions.estimateRoofCert,
            sectionName: 'Roofing',
          },
          {
            label: 'Detatched garage roofing cost per sqft',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateDetacheGarageRoofingPerSqft',
            value: assumptions.estimateDetacheGarageRoofingPerSqft,
            sectionName: 'Roofing',
          },
          {
            label: 'Soffit and/or fascia replacement cost per foot',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateSoffitAndFasciaCost',
            value: assumptions.estimateSoffitAndFasciaCost,
            sectionName: 'Roofing',
          },
          {
            label: 'Miscellaneous roofing expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateOtherRoofExpenses',
            value: assumptions.estimateOtherRoofExpenses,
            sectionName: 'Roofing',
          },
        ],
      },
      {
        sectionName: 'Windows',
        description: 'Windows panel',
        panel: 'e1',
        fields: [
          {
            label: 'Window Replacement Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateWindowReplacement',
            value: assumptions.estimateWindowReplacement,
            sectionName: 'Windows',
          },
          {
            label: 'Window Pane Repair Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateWindowPaneRepair',
            value: assumptions.estimateWindowPaneRepair,
            sectionName: 'Windows',
          },
          {
            label: 'Shutter Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateShutterRepair',
            value: assumptions.estimateShutterRepair,
            sectionName: 'Windows',
          },
          {
            label: 'Miscellaneous window expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateOtherWindowExpenses',
            value: assumptions.estimateOtherWindowExpenses,
            sectionName: 'Windows',
          },
        ],
      },
      {
        sectionName: 'Landscaping',
        description: 'Landscaping panel',
        panel: 'e2',
        fields: [
          {
            label: 'Simple front yard cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateSimpleFrontYard',
            value: assumptions.estimateSimpleFrontYard,
            sectionName: 'Landscaping',
          },
          {
            label: 'New sprinkler system ($400 - $950',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateSprinkler',
            value: assumptions.estimateSprinkler,
            sectionName: 'Landscaping',
          },
          {
            label: 'Cost to umbrella a tree',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateUmbrellaTreeCost',
            value: assumptions.estimateUmbrellaTreeCost,
            sectionName: 'Landscaping',
          },
          {
            label:
              'Remove large tree with thick trunk cost ($2000 - $3000)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateRemoveTreeCost',
            value: assumptions.estimateRemoveTreeCost,
            sectionName: 'Landscaping',
          },
          {
            label: 'Fencing (wood $16, vinyl $22 per linear foot)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateFencing',
            value: assumptions.estimateFencing,
            sectionName: 'Landscaping',
          },
          {
            label:
              'Concrete ($5/sq ft to remove and $7/sq ft to install)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateConcretePerSqft',
            value: assumptions.estimateConcretePerSqft,
            sectionName: 'Landscaping',
          },
          {
            label: 'Miscellaneous landscaping expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateOtherLandscapingExpenses',
            value: assumptions.estimateOtherLandscapingExpenses,
            sectionName: 'Landscaping',
          },
        ],
      },
      {
        sectionName: 'Exterior',
        description: 'Exterior panel',
        panel: 'e3',
        fields: [
          {
            label:
              'Paint cost per sqft (input $2.50 for a 2 story house requiring scaffolding & ladders)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimatePaintCostPerSqftExterior',
            value: assumptions.estimatePaintCostPerSqftExterior,
            sectionName: 'Exterior',
          },
          {
            label: 'Power wash',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimatePowerWash',
            value: assumptions.estimatePowerWash,
            sectionName: 'Exterior',
          },
          {
            label: 'Cement fiber siding per sqft (hardi-panel)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateCementFiberSidingPerSqft',
            value: assumptions.estimateCementFiberSidingPerSqft,
            sectionName: 'Exterior',
          },
          {
            label: 'Vinyl siding per sqft ($4-$6)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateVinylSidingPerSqft',
            value: assumptions.estimateVinylSidingPerSqft,
            sectionName: 'Exterior',
          },
          {
            label: 'Stucco per sqft ($6-$8)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateStuccoPerSqft',
            value: assumptions.estimateStuccoPerSqft,
            sectionName: 'Exterior',
          },
          {
            label: 'Front porch',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateFrontPorch',
            value: assumptions.estimateFrontPorch,
            sectionName: 'Exterior',
          },
          {
            label: 'Back deck/porch',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateBackPorch',
            value: assumptions.estimateBackPorch,
            sectionName: 'Exterior',
          },
          {
            label: 'Miscellaneous exterior expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateOtherExteriorExpenses',
            value: assumptions.estimateOtherExteriorExpenses,
            sectionName: 'Exterior',
          },
        ],
      },
      {
        sectionName: 'Interior',
        description: 'Interior panel',
        panel: 'e4',
        fields: [
          {
            label: 'Paint cost per sqft',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimatePaintCostPerSqftInterior',
            value: assumptions.estimatePaintCostPerSqftInterior,
            sectionName: 'Interior',
          },
          {
            label:
              "Patch drywall (2' x 2' path = $25 or 8' x 4' = $30)",
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimatePatchDrywall',
            value: assumptions.estimatePatchDrywall,
            sectionName: 'Interior',
          },
          {
            label: 'Scrape popcorn off ceilings sqft',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateScrapePopcornPerSqft',
            value: assumptions.estimateScrapePopcornPerSqft,
            sectionName: 'Interior',
          },
          {
            label: 'Texture wall per sqft',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateTextureWallPerSqft',
            value: assumptions.estimateTextureWallPerSqft,
            sectionName: 'Interior',
          },
          {
            label: 'Wallpaper removal cost per room',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateWallPaperRemoval',
            value: assumptions.estimateWallPaperRemoval,
            sectionName: 'Interior',
          },
          {
            label: 'Baseboards and casings (whole house) $1 per sqft',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateBaseboardsAndCasingsPerSqft',
            value: assumptions.estimateBaseboardsAndCasingsPerSqft,
            sectionName: 'Interior',
          },
          {
            label: 'Frame outs cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateFrameOuts',
            value: assumptions.estimateFrameOuts,
            sectionName: 'Interior',
          },
          {
            label: 'Miscellaneous interior expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateOtherInteriorExpenses',
            value: assumptions.estimateOtherInteriorExpenses,
            sectionName: 'Interior',
          },
        ],
      },
      {
        sectionName: 'Flooring',
        description: 'Flooring panel',
        panel: 'e5',
        fields: [
          {
            label: 'Luxury Vinyl Plank (LVP) cost per sqft',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateLuxuryVinylPlankPerSqft',
            value: assumptions.estimateLuxuryVinylPlankPerSqft,
            sectionName: 'Flooring',
          },
          {
            label: 'Tile cost per sqft',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateTilePerSqft',
            value: assumptions.estimateTilePerSqft,
            sectionName: 'Flooring',
          },
          {
            label:
              'Hardwood or laminate new $8 or refinish $2.50 (sqft)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateHardwoodPerSqft',
            value: assumptions.estimateHardwoodPerSqft,
            sectionName: 'Flooring',
          },
          {
            label:
              'Carpet (sqft) but usually purchased by square yard',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateCarpetPerSqft',
            value: assumptions.estimateCarpetPerSqft,
            sectionName: 'Flooring',
          },
          {
            label: 'Miscellaneous flooring expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateFlooringExpenses',
            value: assumptions.estimateFlooringExpenses,
            sectionName: 'Flooring',
          },
        ],
      },
      {
        sectionName: 'Doors',
        description: 'Doors panel',
        panel: 'e6',
        fields: [
          {
            label: 'Front door with hardware cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateFrontDoorCost',
            value: assumptions.estimateFrontDoorCost,
            sectionName: 'Doors',
          },
          {
            label: 'Exterior door with hardware cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateExteriorDoorCost',
            value: assumptions.estimateExteriorDoorCost,
            sectionName: 'Doors',
          },
          {
            label: 'Sliding glass door or exterior French door cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateSlidingDoorCost',
            value: assumptions.estimateSlidingDoorCost,
            sectionName: 'Doors',
          },
          {
            label: 'Interior door kits with hardware cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateInteriorDoorCost',
            value: assumptions.estimateInteriorDoorCost,
            sectionName: 'Doors',
          },
          {
            label: 'Interior French doors with hardware cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateInteriorFrenchDoorCost',
            value: assumptions.estimateInteriorFrenchDoorCost,
            sectionName: 'Doors',
          },
          {
            label:
              'Pocket door - entire kit with framing & drywall repair cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimatePocketDoorCost',
            value: assumptions.estimatePocketDoorCost,
            sectionName: 'Doors',
          },
          {
            label: 'Pocket door - install door only cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimatePocketDoorInstallOnlyCost',
            value: assumptions.estimatePocketDoorInstallOnlyCost,
            sectionName: 'Doors',
          },
          {
            label: 'Barn door cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateBarnDoorCost',
            value: assumptions.estimateBarnDoorCost,
            sectionName: 'Doors',
          },
          {
            label: "Garage doors (9' = $1,000, 16' = $1,200)",
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateGarageDoorCost',
            value: assumptions.estimateGarageDoorCost,
            sectionName: 'Doors',
          },
          {
            label: 'Miscellaneous door expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateOtherDoorExpenses',
            value: assumptions.estimateOtherDoorExpenses,
            sectionName: 'Doors',
          },
        ],
      },
      {
        sectionName: 'Electrical',
        description: 'Electrical panel',
        panel: 'e7',
        fields: [
          {
            label: 'Rewire the whole house ($4,500 - $5,500)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateRewire',
            value: assumptions.estimateRewire,
            sectionName: 'Electrical',
          },
          {
            label: 'Exterior light costs',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateExteriorLightCost',
            value: assumptions.estimateExteriorLightCost,
            sectionName: 'Electrical',
          },
          {
            label: 'Interior light cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateInteriorLightCost',
            value: assumptions.estimateInteriorLightCost,
            sectionName: 'Electrical',
          },
          {
            label: 'Ceiling Fan cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateCeilingFanCost',
            value: assumptions.estimateCeilingFanCost,
            sectionName: 'Electrical',
          },
          {
            label: 'Recessed can light cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateRecessedCanLightCost',
            value: assumptions.estimateRecessedCanLightCost,
            sectionName: 'Electrical',
          },
          {
            label: 'Change out plugs and switches cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimatePlugSwitchReplacementCost',
            value: assumptions.estimatePlugSwitchReplacementCost,
            sectionName: 'Electrical',
          },
          {
            label: 'Smoke detector cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateSmokeDetectorCost',
            value: assumptions.estimateSmokeDetectorCost,
            sectionName: 'Electrical',
          },
          {
            label:
              'Change or replace electrical panel ($2,000 - $3,000)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateElectricalPanel',
            value: assumptions.estimateElectricalPanel,
            sectionName: 'Electrical',
          },
          {
            label: 'Miscellaneous electrical expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateOtherElectricalExpenses',
            value: assumptions.estimateOtherElectricalExpenses,
            sectionName: 'Electrical',
          },
        ],
      },
      {
        sectionName: 'Plumbing',
        description: 'Plumbing panel',
        panel: 'e8',
        fields: [
          {
            label: 'Water Heater Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateWaterHeaterCost',
            value: assumptions.estimateWaterHeaterCost,
            sectionName: 'Plumbing',
          },
          {
            label: 'Replumb the whole house ($4,500)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateReplumbHouse',
            value: assumptions.estimateReplumbHouse,
            sectionName: 'Plumbing',
          },
          {
            label: 'Miscellaneous plumbing expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateOtherPlumbingExpenses',
            value: assumptions.estimateOtherPlumbingExpenses,
            sectionName: 'Plumbing',
          },
        ],
      },
      {
        sectionName: 'HVAC',
        description: 'HVAC panel',
        panel: 'e9',
        fields: [
          {
            label:
              'Install a complete system A/C and heat cost ($6,500 - $7,500)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateACInstallCost',
            value: assumptions.estimateACInstallCost,
            sectionName: 'HVAC',
          },
          {
            label:
              'Take existing forced air heat and add air conditioning cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateAddACToForcedAirHeat',
            value: assumptions.estimateAddACToForcedAirHeat,
            sectionName: 'HVAC',
          },
          {
            label: 'Gas Pack for roof top cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateGasPackForRoof',
            value: assumptions.estimateGasPackForRoof,
            sectionName: 'HVAC',
          },
          {
            label: 'Furnace Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateFurnace',
            value: assumptions.estimateFurnace,
            sectionName: 'HVAC',
          },
          {
            label: 'Wall Heater Cost ($1,000 - $1,500)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateWallHeaterCost',
            value: assumptions.estimateWallHeaterCost,
            sectionName: 'HVAC',
          },
          {
            label: 'AC Condenser Cost ($1,800)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateACCondenserCost',
            value: assumptions.estimateACCondenserCost,
            sectionName: 'HVAC',
          },
          {
            label: 'New Registers for whole house cost ($350)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateNewRegisters',
            value: assumptions.estimateNewRegisters,
            sectionName: 'HVAC',
          },
          {
            label: 'Service HVAC',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateServiceHVAC',
            value: assumptions.estimateServiceHVAC,
            sectionName: 'HVAC',
          },
          {
            label: 'Miscellaneous HVAC expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateOtherHVACExpenses',
            value: assumptions.estimateOtherHVACExpenses,
            sectionName: 'HVAC',
          },
        ],
      },
      {
        sectionName: 'Kitchen',
        description: 'Kitchen panel',
        panel: 'e10',
        fields: [
          {
            label:
              'Kitchen Remodel Cost ($9,000 for normal kitchen & increase for larger or higher end)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateKitchenRemodelCost',
            value: assumptions.estimateKitchenRemodelCost,
            sectionName: 'Kitchen',
          },
          {
            label: 'Refrigerator Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateRefrigerator',
            value: assumptions.estimateRefrigerator,
            sectionName: 'Kitchen',
          },
          {
            label: 'Half Kitchen (everything except cabinets) cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateHalfKitchen',
            value: assumptions.estimateHalfKitchen,
            sectionName: 'Kitchen',
          },
          {
            label: 'Open Kitchen Wall Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateKitchenWallOpenCost',
            value: assumptions.estimateKitchenWallOpenCost,
            sectionName: 'Kitchen',
          },
          {
            label: 'Miscellaneous Kitchen Expenses',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateOtherKitchenExpenses',
            value: assumptions.estimateOtherKitchenExpenses,
            sectionName: 'Kitchen',
          },
        ],
      },
      {
        sectionName: 'Bathrooms',
        description: 'Bathrooms panel',
        panel: 'e11',
        fields: [
          {
            label: 'Bathroom Renovation Cost ($4,500 - $6,500)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateBathroomRenovationCost',
            value: assumptions.estimateBathroomRenovationCost,
            sectionName: 'Bathrooms',
          },
          {
            label: 'Tub, shower valve, tile surround cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateTubShowerTileCost',
            value: assumptions.estimateTubShowerTileCost,
            sectionName: 'Bathrooms',
          },
          {
            label: 'Tile surround cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateTileCost',
            value: assumptions.estimateTileCost,
            sectionName: 'Bathrooms',
          },
          {
            label: 'New tub Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateTubCost',
            value: assumptions.estimateTubCost,
            sectionName: 'Bathrooms',
          },
          {
            label: 'Glaze tub Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateTubGlazeCost',
            value: assumptions.estimateTubGlazeCost,
            sectionName: 'Bathrooms',
          },
          {
            label: 'Shower valves Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateShowerCost',
            value: assumptions.estimateShowerCost,
            sectionName: 'Bathrooms',
          },
          {
            label: 'Vanity Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateVanityCost',
            value: assumptions.estimateVanityCost,
            sectionName: 'Bathrooms',
          },
          {
            label: 'Toilet Cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateTolietCost',
            value: assumptions.estimateTolietCost,
            sectionName: 'Bathrooms',
          },
          {
            label:
              'Mirror, medicine cabinet, towel bar, toilet paper holder cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateMirrorCabinetTowerBarToiletPaperHolderCost',
            value:
              assumptions.estimateMirrorCabinetTowerBarToiletPaperHolderCost,
            sectionName: 'Bathrooms',
          },
          {
            label: 'Miscellaneous bathroom cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateOtherBathroomExpenses',
            value: assumptions.estimateOtherBathroomExpenses,
            sectionName: 'Bathrooms',
          },
        ],
      },
      {
        sectionName: 'Miscellaneous',
        description: 'Miscellaneous panel',
        panel: 'e12',
        fields: [
          {
            label: 'Demo and dumpster cost (2 - 3 average per job)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateDemoDumpster',
            value: assumptions.estimateDemoDumpster,
            sectionName: 'Miscellaneous',
          },
          {
            label: 'Final clean up',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateFinalCleanUp',
            value: assumptions.estimateFinalCleanUp,
            sectionName: 'Miscellaneous',
          },
          {
            label:
              'Pool (assess with pool contractor during due diligence period) $5000 if quick # is needed for drain, resurface, and mechanicals',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimatePool',
            value: assumptions.estimatePool,
            sectionName: 'Miscellaneous',
          },
          {
            label:
              'Fireplace facing - tile or stone (reface to mantle = $1,000) (reface to ceiling = $1,500)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateFireplace',
            value: assumptions.estimateFireplace,
            sectionName: 'Miscellaneous',
          },
          {
            label:
              'Basement (unfinished small = $6,000, unfinished large = $12,000)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateBasement',
            value: assumptions.estimateBasement,
            sectionName: 'Miscellaneous',
          },
          {
            label: 'Mold ($3,000 - $8,000)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateMold',
            value: assumptions.estimateMold,
            sectionName: 'Miscellaneous',
          },
          {
            label: 'French drain cost ($2,500 - $3,500)',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateFrenchDrainCost',
            value: assumptions.estimateFrenchDrainCost,
            sectionName: 'Miscellaneous',
          },
          {
            label: 'Foundation repair',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateFoundationRepair',
            value: assumptions.estimateFoundationRepair,
            sectionName: 'Miscellaneous',
          },
          {
            label: 'Termite treatment',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateTermiteTreatment',
            value: assumptions.estimateTermiteTreatment,
            sectionName: 'Miscellaneous',
          },
          {
            label: 'Miscellaneous other cost',
            adornment: '$',
            adornmentToggle: '',
            adornmentPosition: 'start',
            name: 'estimateOtherExpenses',
            value: assumptions.estimateOtherExpenses,
            sectionName: 'Miscellaneous',
          },
        ],
      },
    ]

    remove(
      fieldSections,
      (section) =>
        !includes(
          uniq(flatMap(section.fields, (field) => field.filters)),
          this.props.filter,
        ),
    )

    if (this.props.targetOnly)
      remove(
        fieldSections,
        (section) => !startsWith(section.sectionName, 'Targets'),
      )

    return (
      <>
        <div
          className="accordion mb-spacing-6-x2"
          style={{ width: '100%' }}
        >
          {map(
            this.props?.session?.me?.activeOrg?.member === 'pep' ||
              this.props?.session?.me?.activeOrg?.member ===
                'pacemorby'
              ? concat(fieldSections, pepDealsauceEstimateSections)
              : fieldSections,
            (section) => (
              <Card
                key={section.panel}
                className={clsx('card-box', {
                  'panel-open':
                    this.state.accordion === section.panel,
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() =>
                            this.toggleAccordion(section.panel)
                          }
                          aria-expanded={
                            this.state.accordion === section.panel
                          }
                        >
                          <span>{section.sectionName}</span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse
                    in={this.state.accordion === section.panel}
                  >
                    <CardContent
                      style={{
                        marginTop: -10,
                        marginBottom: -10,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                    >
                      {includes(section.sectionName, 'Targets') ? (
                        <List
                          component="div"
                          className="list-group-flush"
                        >
                          {map(
                            filter(
                              section.fields,
                              (field) =>
                                (!field.filters ||
                                  includes(
                                    field.filters,
                                    this.props.filter,
                                  )) &&
                                (!field.acquisitionAdvanced ||
                                  this.state.showAcquisitionAdvanced),
                            ),
                            (field) => (
                              <Fragment key={field.label}>
                                {field.tip
                                  ? // <Tooltip title={field.tip}>
                                    //   {this.renderInputFields(field)}
                                    // </Tooltip>
                                    this.renderInputFields(field)
                                  : this.renderInputFields(field)}
                              </Fragment>
                            ),
                          )}
                        </List>
                      ) : (
                        <Grid container spacing={2}>
                          {map(
                            filter(
                              section.fields,
                              (field) =>
                                (!field.filters ||
                                  includes(
                                    field.filters,
                                    this.props.filter,
                                  )) &&
                                (!field.acquisitionAdvanced ||
                                  this.state.showAcquisitionAdvanced),
                            ),
                            (field) => (
                              <Fragment key={field.label}>
                                {field.tip
                                  ? // <Tooltip title={field.tip}>
                                    //   {this.renderInputFields(field)}
                                    // </Tooltip>
                                    this.renderInputFields(field)
                                  : this.renderInputFields(field)}
                              </Fragment>
                            ),
                          )}
                        </Grid>
                      )}
                    </CardContent>
                  </Collapse>
                </Card>
              </Card>
            ),
          )}
        </div>
      </>
    )
  }
}

export default withSession(AssumptionsSettingsAccordion)
