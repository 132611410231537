/**
 * @ Author: Housefolios
 * @ Create Time: 2022-04-26 15:44:38
 * @ Modified by: Keone Hope
 * @ Modified time: 2023-01-24 15:53:03
 * @ Description: Dialog for removing access to a shared portfolio
 */

import React, { useState } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material'

import map from 'lodash/map'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'

import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { Query } from '@apollo/client/react/components'
import { GET_ORGS_PORTFOLIO_SHARED_WITH } from '../queries'
import { useMutation } from '@apollo/client'
import {
  REMOVE_SHARED_PORTFOLIO,
  SHARE_PORTFOLIO,
} from '../mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { Close } from '@mui/icons-material'

function RemoveSharedPortfolioComponent(props) {
  const { orgId, portfolioId, rightMenu, sidebarMenu, shareAccess } =
    props

  const [openUnsharePortfolio, setOpenUnsharePortfolio] =
    useState(null)
  const [loader, setLoader] = useState(false)

  const handleOpenUnsharePortfolio = (event) => {
    setOpenUnsharePortfolio(true)
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const handleCloseUnsharePortfolio = (event) => {
    setOpenUnsharePortfolio(false)
    setLoader(false)
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const [removeSharedPortfolio] = useMutation(REMOVE_SHARED_PORTFOLIO)

  const isRecipient = orgId === props.session.me.activeOrg._id

  return (
    <>
      {rightMenu && (
        <ListItem button onClick={handleOpenUnsharePortfolio}>
          <Close style={{ marginRight: 5, color: '#757575' }} />
          <span>Remove Shared Portfolio</span>
        </ListItem>
      )}
      {shareAccess && (
        <IconButton onClick={handleOpenUnsharePortfolio} size="large">
          <FontAwesomeIcon icon={['far', 'circle-xmark']} />
        </IconButton>
      )}
      {sidebarMenu && (
        <Tooltip
          title="Remove Portfolio"
          classes={{ tooltip: 'tooltip-first' }}
          arrow
        >
          <IconButton
            onClick={handleOpenUnsharePortfolio}
            style={{
              backgroundColor: 'white',
              width: 30,
              height: 30,
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={openUnsharePortfolio}
        onClose={handleCloseUnsharePortfolio}
        maxWidth="sm"
        fullWidth={true}
        scroll="body"
        onClick={(event) => event.stopPropagation()}
      >
        <DialogTitle>
          {isRecipient ? 'Remove Shared Portfolio' : 'Remove Access'}
        </DialogTitle>
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              Removing Shared Portfolio
            </div>
          }
        >
          <DialogContent>
            <DialogContentText>
              {isRecipient
                ? 'Are you sure you want to remove this? You will lose access to this shared portfolio.'
                : 'Are you sure you want to remove access to this portfolio for this user?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={handleCloseUnsharePortfolio}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: '#f83145', color: 'white' }}
              onClick={() => {
                removeSharedPortfolio({
                  variables: {
                    orgId,
                    portfolioId,
                  },
                  refetchQueries: [
                    {
                      query: GET_ORGS_PORTFOLIO_SHARED_WITH,
                      variables: {
                        portfolioId: portfolioId,
                      },
                    },
                    {
                      query: GET_ME,
                    },
                  ],
                })
                  .then(() => {
                    enqueueSnackbar(
                      `Portfolio succsesfully Unshared`,
                      {
                        variant: 'success',
                        autoHideDuration: 3000,
                      },
                    )
                    handleCloseUnsharePortfolio()
                  })
                  .catch((error) => {
                    enqueueSnackbar(`Failed to Unshared Portfolio`, {
                      variant: 'error',
                      autoHideDuration: 3000,
                    })
                    handleCloseUnsharePortfolio()
                  })
              }}
            >
              Remove
            </Button>
          </DialogActions>
        </BlockUi>
      </Dialog>
    </>
  )
}
export default withSession(RemoveSharedPortfolioComponent)
