// Import createTheme and adaptV4Theme from @mui/material/styles
import { createTheme } from '@mui/material/styles'
import shadows from './shadows'

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: '#368CFF',
      //main: '#008aff'
      //main: '#ffaf00'
    },
    grey: {
      300: '#fefefe',
      A100: '#f8f9ff',
    },
    secondary: {
      main: '#4191ff',
    },
    error: {
      main: '#f83245',
    },
    success: {
      main: '#1bc943',
    },
    info: {
      main: '#11c5db',
    },
    warning: {
      main: '#f4772e',
    },
    helpers: {
      primary: '#3c44b1',
      main: 'rgba(25, 46, 91, .035)',
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1300,
      xl: 2000,
    },
  },
  shape: {
    borderRadius: '0.2rem',
  },
  components: {
    MuiCssBaseline: {
      // styleOverrides: {
      //   '*': {
      //     '&::-webkit-scrollbar': {
      //       width: 8, // Adjust the width for horizontal scrollbar
      //       height: 8, // Adjust the height for vertical scrollbar
      //     },
      //     '&::-webkit-scrollbar-thumb': {
      //       backgroundColor: '#1976d2',
      //       borderRadius: '0.75rem',
      //     },
      //   },
      //   // Additional styles for nested scrollbars
      //   '* *::-webkit-scrollbar': {
      //     width: 8,
      //     height: 8,
      //   },
      //   '* *::-webkit-scrollbar-thumb': {
      //     backgroundColor: '#1976d2',
      //     borderRadius: '0.75rem',
      //   },
      // },
    },
    MuiButton: {
      styleOverrides: {
        // outlinedSmall: {
        //   padding: '6px 20px',
        //   fontSize: 14
        // },
        // textSmall: {
        //   padding: '6px 20px',
        //   fontSize: 14
        // },

        // sizeMedium: {
        //   padding: '10px 22px',
        //   fontSize: 15
        // },
        // outlinedLarge: {
        //   padding: '16px 28px',
        //   fontSize: 16
        // },
        // textLarge: {
        //   padding: '16px 28px',
        //   fontSize: 16
        // },
        root: {
          textTransform: 'none',
          fontWeight: 'normal',
          padding: '10px 22px',
          fontSize: 14,
        },
        sizeSmall: {
          padding: '6px 20px',
          fontSize: 14,
        },
        sizeLarge: {
          padding: '16px 28px',
          fontSize: 16,
        },
        outlined: {
          padding: '10px 22px',
          fontSize: 14,
        },
        text: {
          padding: '10px 22px',
          fontSize: 14,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#070919',
          padding: '8px 16px',
          fontSize: '13px',
        },
        arrow: {
          color: '#070919',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          // backgroundColor: '#fff', // Background color of the popover
          borderRadius: '4px', // Border radius
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)', // Box shadow
          width: 'max-content',
          // padding: 8,
        },
        root: {
          // display: 'inline-block', // Display as inline-block
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '8px 0', // Padding of the list
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: '8px', // Top padding of each list item
          paddingBottom: '8px', // Bottom padding of each list item
        },
        button: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Background color on hover
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
  },
  typography: {
    fontFamily: ['Heebo', 'sans-serif'].join(','),
    fontSize: 14,
  },
  shadows,
})

export default MuiTheme
