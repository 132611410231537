/**
 * @ Author: Housefolios
 * @ Create Time: 2021-10-05 11:07:32
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-01-10 16:00:40
 * @ Description: Dialog tied to withSession allowing for free accounts to change to a subscription plan
 */

import React, { Component, useState } from 'react'
import {
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Card,
  Radio,
  Typography,
  DialogTitle,
  Link,
  Collapse,
  Stepper,
  Step,
  StepLabel,
  Grid,
  TextField,
  Backdrop,
  CircularProgress,
  Switch,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import map from 'lodash/map'
import toLower from 'lodash/toLower'
import startCase from 'lodash/startCase'
import upperFirst from 'lodash/upperFirst'
import round from 'lodash/round'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import head from 'lodash/head'
import split from 'lodash/split'
import {
  GET_COUPON,
  GET_SUBSCRIPTION_PLAN,
} from '../CreateAccount/CreateAccountCard/mutations'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from '../CreateAccount/PaymentForm'
import { useQuery } from '@apollo/client'
import { Mutation, Query } from '@apollo/client/react/components'
import { GET_ME } from '../Session/queries'
import { enqueueSnackbar } from 'notistack'

import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { CREATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { SUBSCRIBE } from './mutations'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_PARTNER } from '@/housefolios-components/CreateAccount/CreateAccountCard/queries'
import { Check } from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAffiliate from '@/hooks/useAffiliate'
import ContactDialog from '../Dialogs/ContactSupport'

const SubscribeDialog = (props) => {
  const [state, setState] = useState({
    subscriptionPlanChoice: 'investor',
    subscriptionPlanChoicePrice: '9',
    activeTab: 0,
    coupon: '',
    couponValid: null,
    couponPercent: '',
    couponAmount: '',
    stripeToken: '',
    element: null,
    circle: false,
    expand: false,
    yearly: false,
    recharge: false,
    paymentIntervals: 1,
  })

  const [resetToken, setResetToken] = useState(() => {
    return null
  })

  const openCouponField = () => {
    setState({ ...state, expand: true })
  }

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const nextTab = () => {
    setState({ ...state, activeTab: state.activeTab + 1 })
  }

  const previousTab = () => {
    setState({ ...state, activeTab: state.activeTab - 1 })
  }

  const handleToggle = () =>
    setState({ ...state, yearly: !state.yearly })

  const handleRechargeToggle = () => {
    setState({ ...state, recharge: !state.recharge, stripeToken: '' })
    if (state.element) state.element.clear()
    resetToken()
  }

  const createToken = async (stripe, cardElement, nameOnCard) => {
    if (!nameOnCard) setState({ ...state, stripeToken: '' })
    else {
      await stripe
        .createToken(cardElement, {
          name: nameOnCard,
        })
        .then((res) => {
          var errorElement = document.getElementById('card-errors')
          if (res.error) {
            setState({ ...state, stripeToken: '' })
            if (errorElement)
              errorElement.textContent = res.error.message
          } else {
            setState({ ...state, stripeToken: res.token })
            if (errorElement) errorElement.textContent = null
          }
        })
    }
  }

  const setElement = (element) => {
    setState({ ...state, element })
  }

  const handleCheckCoupon = async (coupon, checkCoupon) => {
    // try {
    //const couponCheck = await checkCoupon()
    if (!coupon) setState({ ...state, couponValid: null })
    else
      await checkCoupon({
        variables: {
          code: coupon,
        },
      })
        .then(async ({ data }) => {
          setState({
            ...state,
            couponValid: true,
            couponPercent: data.getCoupon.percentOff,
            couponAmount: data.getCoupon.amountOff,
          })
        })
        .catch(async ({ error }) => {
          enqueueSnackbar(`Promo Code not valid`, {
            variant: 'error',
            autoHideDuration: 3000,
          })
          setState({
            ...state,
            couponValid: false,
            couponAmount: '',
            couponPercent: '',
            coupon: '',
          })
        })
    // } catch (error) {
    //   return error
    // }
  }

  const onChangePlan = (value, checkCoupon) => {
    // setState({ ...state,
    //   subscriptionPlanChoice: value,
    // })
    if (includes(value, 'group-coaching')) {
      const { paymentIntervals } = state
      let applyCoupon = ''
      if (paymentIntervals === 1) {
        applyCoupon = 'GTzjUUmX'
      } else if (paymentIntervals === 2) {
        applyCoupon = 'CxxvDO0k'
      } else if (paymentIntervals === 3) {
        applyCoupon = 'arM9K3su'
      }
      setState({
        ...state,
        subscriptionPlanChoice: value,
        coupon: applyCoupon,
      })
      handleCheckCoupon(applyCoupon, checkCoupon)
    } else {
      setState({
        ...state,
        subscriptionPlanChoice: value,
        couponValid: false,
        couponAmount: '',
        couponPercent: '',
        coupon: '',
      })
    }
  }

  const onChangePayments = (value, coupon, checkCoupon) => {
    const { subscriptionPlanChoice } = state
    let applyCoupon = coupon
    if (includes(subscriptionPlanChoice, 'group-coaching')) {
      if (value == 1) {
        applyCoupon = 'GTzjUUmX'
      } else if (value == 2) {
        applyCoupon = 'CxxvDO0k'
      } else if (value == 3) {
        applyCoupon = 'arM9K3su'
      }
      setState({
        ...state,
        paymentIntervals: value,
        coupon: applyCoupon,
      })
      handleCheckCoupon(applyCoupon, checkCoupon)
    } else {
      setState({ ...state, paymentIntervals: value })
    }
  }

  const handleSubmit = async (subscribe, planPrice, stripePlanId) => {
    const { subscriptionPlan } = state
    setState({ ...state, circle: true })

    subscribe()
      .then(async ({ data }) => {
        ReactPixel.track('StartTrial', {
          value: planPrice,
          currency: 'USD',
          predicted_ltv: planPrice * 6,
        })
        ReactGA.plugin.require('ecommerce')
        ReactGA.plugin.execute('ecommerce', 'addItem', {
          id: stripePlanId,
          name: upperFirst(subscriptionPlan),
          sku: stripePlanId,
          price: planPrice,
          quantity: 1,
          currency: 'USD',
        })
        ReactGA.plugin.execute('ecommerce', 'addTransaction', {
          id: Math.random(),
          revenue: planPrice,
          total: planPrice,
          shipping: 0,
          tax: 0,
          currency: 'USD',
        })
        ReactGA.plugin.execute('ecommerce', 'send')
        ReactGA.plugin.execute('ecommerce', 'clear')

        setState({ ...state, circle: false, activeTab: 0 })
        resetToken()
        props.toggleDialog()
        localStorage.setItem('token', data.subscribe.token)
      })
      .catch((error) => {
        var errorElement = document.getElementById('card-errors')
        //if (state.element) state.element.clear()
        resetToken()
        setState({ ...state, circle: false })
        if (error && errorElement) {
          errorElement.textContent =
            error.message.slice(0, 21) === 'GraphQL error: Error:'
              ? error.message.slice(21)
              : null
        }
      })
  }

  const { open, newOrg } = props
  const {
    activeTab,
    subscriptionPlanChoice,
    paymentIntervals,
    stripeToken,
    coupon,
    couponValid,
    couponPercent,
    couponAmount,
    yearly,
  } = state
  const billing = yearly ? '-yearly' : '-monthly'
  const isCoaching = includes(subscriptionPlanChoice, 'coaching')
  const planCards = [
    // {
    //   name: 'starter',
    //   value: 'starter',
    // },
    {
      name: 'investor',
      value: 'investor',
      extra: 'Nationwide property search',
    },
    {
      name: 'growth',
      value: 'growth',
      extra: 'Investor Plan + Create up to 10 portfolios',
    },
    {
      name: 'pro',
      value: 'pro',
      extra: 'Growth Plan + Create 10 Marketplace websites',
    },
    {
      name: 'group-coaching',
      value: 'group-coaching',
      extra: 'Pro Plan + Group Coaching',
      coaching: true,
      ribbon: true,
    },
    {
      name: 'personalized-coaching',
      value: 'personalized-coaching',
      extra: 'Group Coaching + Personalized Coaching',
      coaching: true,
    },
  ]

  const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY
  const stripePromise = loadStripe(stripePublicKey)

  const defaultOrgName =
    props.session.me.profile.firstName +
    props.session.me.profile.lastName

  const plainFormat = new Intl.NumberFormat()

  const isRWN = props?.session?.me?.activeOrg?.member === 'RWN'

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const childCorp = props.session.me.activeOrg.parentCorp || null

  const [{ affiliate, affiliateLoading, urlAffiliate }] =
    useAffiliate({
      memberValue: props?.session?.me?.activeOrg?.member,
    })

  return (
    <Query
      query={GET_PARTNER}
      variables={{ name: props?.session?.me?.activeOrg?.member }}
    >
      {({ loading, error, data }) => {
        const partnerLoading = loading
        const { partner = {} } = data || {}

        let allowedPlans = []
        let applyCoupon = ''
        let applyPercent = null
        let applyCouponAmount = null
        let disablePromoCode = isCoaching
        let memberTrial = 0
        let trialInvoiceAmount = 0

        if (!affiliateLoading && !partnerLoading && !props.newOrg) {
          if (!isEmpty(affiliate) || !isEmpty(partner)) {
            let { createAccount = {} } = isEmpty(affiliate)
              ? partner
              : affiliate
            if (!createAccount) createAccount = {}
            //hfpid = affiliate ? affiliate.hfpid : ''
            //headerImage = get(createAccount, 'headerImage.0.url') || createAccountLogo
            applyCoupon = createAccount.applyCoupon || ''
            applyPercent = createAccount.applyPercent || null
            applyCouponAmount =
              createAccount.applyCouponAmount || null
            if (!isCoaching) {
              memberTrial = createAccount.memberTrial || 0
              trialInvoiceAmount =
                createAccount.trialInvoiceAmount || 0
              disablePromoCode =
                createAccount.disablePromoCode || false
            }
            allowedPlans = createAccount.plans || []
            // if (
            //   applyCoupon &&
            //   !state.coupon &&
            //   !state.expand
            // ) {
            //   setState({ ...state,
            //     coupon: applyCoupon,
            //     couponValid: applyCoupon ? true : false,
            //     couponPercent: applyPercent,
            //     couponAmount: applyCouponAmount,
            //     expand: true,
            //   })
            // }
            // if (
            //   subscriptionPlanChoice &&
            //   !isEmpty(allowedPlans) &&
            //   !includes(
            //     allowedPlans,
            //     subscriptionPlanChoice +
            //       (isCoaching ? '' : billing),
            //   )
            // ) {
            //   let selectedPlan = head(createAccount.plans)
            //   selectedPlan = split(selectedPlan, '-')
            //   setState({ ...state,
            //     subscriptionPlanChoice: selectedPlan[0],
            //     yearly: selectedPlan[1] === 'yearly',
            //   })
            // }
          } else {
            memberTrial = 7
            trialInvoiceAmount = 1
          }
        }

        return (
          <>
            <Dialog
              open={
                open && !props?.session?.me?.activeOrg?.isRealeflow
              }
              onClose={() => {
                setState({
                  ...state,
                  activeTab: 0,
                  stripeToken: '',
                  recharge: false,
                })
                props.toggleDialog()
              }}
              fullWidth
              maxWidth="xs"
            >
              {state.circle && (
                <div>
                  <Backdrop
                    open={state.circle}
                    style={{ color: '#fff', zIndex: 2000 }}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              )}
              <DialogTitle>
                {newOrg || childCorp
                  ? 'Create a New Organization'
                  : 'Upgrade Your Plan'}
                <Stepper
                  activeStep={activeTab}
                  color="primary"
                  style={{ padding: 10 }}
                >
                  {map(['Select Plan', 'Payment'], (label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </DialogTitle>
              <DialogContent>
                {activeTab === 0 && (
                  <Grid container spacing={2}>
                    <Grid
                      item
                      style={{ width: '100%', marginTop: 10 }}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={{ marginBottom: 10 }}
                      >
                        <Grid item>
                          <Typography variant="h6">
                            Pay Monthly
                          </Typography>
                        </Grid>
                        <Grid item style={{ marginTop: -3 }}>
                          <Switch
                            checked={yearly}
                            onChange={handleToggle}
                            color="primary"
                            className="switch-medium"
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">
                            Pay Annually
                          </Typography>
                          <Typography
                            style={{
                              textAlign: 'center',
                              color: '#2096f3',
                            }}
                          >
                            Save 20%
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {map(
                      !isEmpty(allowedPlans)
                        ? filter(planCards, (plan) =>
                            includes(
                              allowedPlans,
                              plan.value +
                                (plan.coaching ? '' : billing),
                            ),
                          )
                        : planCards,
                      (plan) => (
                        <Query
                          key={plan.value}
                          query={GET_SUBSCRIPTION_PLAN}
                          variables={{
                            subscriptionPlan:
                              plan.value +
                              (plan.coaching ? '' : billing),
                          }}
                        >
                          {({ loading, error, data }) => {
                            if (
                              loading ||
                              partnerLoading ||
                              affiliateLoading
                            )
                              return 'Loading...'
                            if (error)
                              return `Error! ${error.message}`

                            const { subscriptionPlan } = data
                            return (
                              <Mutation mutation={GET_COUPON}>
                                {(
                                  getCoupon,
                                  { loading, error, data },
                                ) => {
                                  return (
                                    <Grid
                                      item
                                      style={{ width: '100%' }}
                                    >
                                      <Card>
                                        {plan.ribbon && (
                                          <span className="ribbon-angle ribbon-angle--top-right ribbon-warning">
                                            <small>Popular</small>
                                          </span>
                                        )}
                                        <Grid
                                          container
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                          style={{ margin: 1 }}
                                        >
                                          <Grid item>
                                            <Radio
                                              name="subscriptionPlanChoice"
                                              value={plan.value}
                                              checked={
                                                subscriptionPlanChoice ===
                                                plan.value
                                              }
                                              onChange={(event) =>
                                                onChangePlan(
                                                  event.target.value,
                                                  getCoupon,
                                                )
                                              }
                                            />
                                          </Grid>
                                          <Grid item>
                                            <Typography variant="h6">
                                              <span
                                                style={{
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                {startCase(plan.name)}{' '}
                                                -{' '}
                                              </span>
                                              {currencyFormat.format(
                                                isRWN
                                                  ? round(
                                                      (subscriptionPlan.price *
                                                        0.8) /
                                                        100,
                                                      0,
                                                    )
                                                  : subscriptionPlan.price /
                                                      100,
                                              )}
                                              {(plan.name ===
                                                'group-coaching' ||
                                                plan.name ===
                                                  'personalized-coaching') &&
                                                '*'}
                                            </Typography>
                                            <Typography
                                              variant="subtitle2"
                                              style={{
                                                color: 'gray',
                                              }}
                                            >
                                              Analyze{' '}
                                              <b>
                                                {plainFormat.format(
                                                  subscriptionPlan.propertyCount,
                                                )}
                                              </b>{' '}
                                              properties each month
                                            </Typography>
                                            {plan.extra &&
                                              !props.session.me
                                                .activeOrg.member && (
                                                <Typography
                                                  variant="subtitle2"
                                                  style={{
                                                    color: 'gray',
                                                  }}
                                                >
                                                  {plan.extra}
                                                </Typography>
                                              )}
                                          </Grid>
                                        </Grid>
                                      </Card>
                                    </Grid>
                                  )
                                }}
                              </Mutation>
                            )
                          }}
                        </Query>
                      ),
                    )}
                    <Typography
                      variant="subtitle2"
                      style={{ color: 'gray' }}
                    >
                      * Subscription price after paying a one time
                      coaching fee. Does not have annual plan.
                    </Typography>
                  </Grid>
                )}
                {activeTab === 1 && (
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    spacing={1}
                  >
                    {!(newOrg || childCorp) &&
                      props.session.me.activeOrg.stripeCustomerID && (
                        <Grid
                          item
                          style={{
                            width: '100%',
                            marginTop: 10,
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            style={{ marginBottom: 10 }}
                          >
                            <Grid item>
                              <Typography>New Card</Typography>
                            </Grid>
                            <Grid item style={{ marginTop: -3 }}>
                              <Switch
                                checked={state.recharge}
                                onChange={handleRechargeToggle}
                                color="primary"
                                className="switch-medium"
                              />
                            </Grid>
                            <Grid item>
                              <Typography>Existing Card</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    <Grid item>
                      <Query
                        query={GET_SUBSCRIPTION_PLAN}
                        variables={{
                          subscriptionPlan:
                            subscriptionPlanChoice +
                            (isCoaching ? '' : billing),
                        }}
                      >
                        {({ loading, error, data }) => {
                          if (
                            loading ||
                            partnerLoading ||
                            affiliateLoading
                          )
                            return 'Loading...'
                          if (error) return `Error! ${error.message}`

                          const { subscriptionPlan } = data
                          return (
                            <Query
                              query={GET_SUBSCRIPTION_PLAN}
                              variables={{
                                subscriptionPlan:
                                  `${subscriptionPlanChoice}-entry-fee` +
                                  (paymentIntervals > 1
                                    ? `-${paymentIntervals}-payments`
                                    : ''),
                              }}
                            >
                              {({ loading, error, data }) => {
                                if (
                                  loading ||
                                  partnerLoading ||
                                  affiliateLoading
                                )
                                  return 'Loading...'
                                if (error)
                                  return `Error! ${error.message}`

                                const entrySubscriptionPlan =
                                  data.subscriptionPlan || {}
                                return (
                                  <Card
                                    style={{
                                      boxShadow: 'none',
                                      marginBottom: -10,
                                    }}
                                  >
                                    <CardContent
                                      style={{
                                        backgroundColor: '#e3e3e3',
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                      >
                                        <Grid item>
                                          <Typography>
                                            {yearly && !isCoaching
                                              ? 'Annual '
                                              : 'Monthly '}
                                            {
                                              startCase(
                                                subscriptionPlanChoice,
                                              ).split(' ')[0]
                                            }
                                            {' Plan'}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          container
                                          item
                                          justifyContent="space-between"
                                        >
                                          <Grid item>
                                            <Typography>
                                              {'Subtotal'}
                                            </Typography>
                                          </Grid>
                                          <hr
                                            style={{
                                              width: '60%',
                                              borderWidth: 0,
                                              backgroundColor:
                                                'black',
                                              height: '1px',
                                              marginTop: 'auto',
                                              marginBottom: 'auto',
                                            }}
                                          />
                                          <Grid item>
                                            <Typography>
                                              {currencyFormat.format(
                                                isRWN
                                                  ? round(
                                                      (subscriptionPlan.price *
                                                        0.8) /
                                                        100,
                                                      0,
                                                    )
                                                  : isCoaching
                                                    ? entrySubscriptionPlan.price /
                                                      100
                                                    : subscriptionPlan.price /
                                                      100,
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                        {couponPercent && (
                                          <Grid
                                            container
                                            item
                                            justifyContent="space-between"
                                          >
                                            <Grid item>
                                              <Typography
                                                style={{
                                                  color: '#46de64',
                                                }}
                                              >
                                                Promo Code
                                              </Typography>
                                            </Grid>
                                            <hr
                                              style={{
                                                width: '60%',
                                                borderWidth: 0,
                                                backgroundColor:
                                                  'black',
                                                height: '1px',
                                                marginTop: 'auto',
                                                marginBottom: 'auto',
                                              }}
                                            />
                                            <Grid item>
                                              <Typography
                                                style={{
                                                  color: '#46de64',
                                                }}
                                              >
                                                -
                                                {currencyFormat.format(
                                                  (((isCoaching
                                                    ? entrySubscriptionPlan.price
                                                    : subscriptionPlan.price) /
                                                    100) *
                                                    couponPercent) /
                                                    100,
                                                )}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        )}
                                        {couponAmount && (
                                          <Grid
                                            container
                                            item
                                            justifyContent="space-between"
                                          >
                                            <Grid item>
                                              <Typography
                                                style={{
                                                  color: '#46de64',
                                                }}
                                              >
                                                Promo Code
                                              </Typography>
                                            </Grid>
                                            <hr
                                              style={{
                                                width: '60%',
                                                borderWidth: 0,
                                                backgroundColor:
                                                  'black',
                                                height: '1px',
                                                marginTop: 'auto',
                                                marginBottom: 'auto',
                                              }}
                                            />
                                            <Grid item>
                                              <Typography
                                                style={{
                                                  color: '#46de64',
                                                }}
                                              >
                                                -
                                                {currencyFormat.format(
                                                  couponAmount / 100,
                                                )}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        )}
                                      </Grid>
                                      <Grid
                                        container
                                        item
                                        justifyContent="space-between"
                                      >
                                        <Grid item>
                                          <Typography>
                                            Total due today
                                          </Typography>
                                        </Grid>
                                        <hr
                                          style={{
                                            width: '45%',
                                            borderWidth: 0,
                                            backgroundColor: 'black',
                                            height: '1px',
                                            marginTop: 'auto',
                                            marginBottom: 'auto',
                                          }}
                                        />
                                        <Grid item>
                                          <Typography>
                                            {currencyFormat.format(
                                              isRWN
                                                ? round(
                                                    (subscriptionPlan.price *
                                                      0.8) /
                                                      100,
                                                    0,
                                                  )
                                                : ((isCoaching
                                                    ? entrySubscriptionPlan.price
                                                    : subscriptionPlan.price) *
                                                    (1 -
                                                      couponPercent /
                                                        100) -
                                                    couponAmount) /
                                                    100,
                                            )}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid item>
                                        <Typography
                                          style={{
                                            fontWeigh: 900,
                                          }}
                                        >
                                          Next payment due a{' '}
                                          {!yearly || isCoaching
                                            ? 'month'
                                            : 'year'}{' '}
                                          from now.
                                        </Typography>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                )
                              }}
                            </Query>
                          )
                        }}
                      </Query>
                    </Grid>
                    {state.expand === false &&
                      !includes(
                        subscriptionPlanChoice,
                        'group-coaching',
                      ) &&
                      !includes(
                        subscriptionPlanChoice,
                        'personalized-coaching',
                      ) && (
                        <Grid item>
                          <Typography
                            variant="subtitle2"
                            style={{
                              marginLeft: 5,
                              marginTop: 20,
                              marginBottom: -10,
                            }}
                          >
                            <Link
                              href="#"
                              underline="always"
                              onClick={openCouponField}
                            >
                              Have a promo code?
                            </Link>
                          </Typography>
                        </Grid>
                      )}
                    <Collapse
                      in={
                        state.expand ||
                        includes(
                          subscriptionPlanChoice,
                          'group-coaching',
                        )
                      }
                    >
                      <Grid
                        item
                        style={{
                          padding: 4,
                          marginTop: 15,
                          marginBottom: -5,
                        }}
                      >
                        <Mutation mutation={GET_COUPON}>
                          {(getCoupon, { loading, error, data }) => {
                            if (
                              loading ||
                              partnerLoading ||
                              affiliateLoading
                            )
                              return 'Loading...'
                            // if (error)
                            //   setState({ ...state,
                            //     couponValid: false,
                            //   })
                            if (isCoaching) {
                              return null
                            } else {
                              return (
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Grid item xs={12}>
                                    <TextField
                                      variant="outlined"
                                      margin="dense"
                                      placeholder="Add Promo Code"
                                      id="input-with-icon-textfield22-1"
                                      className="valid"
                                      name="coupon"
                                      value={coupon}
                                      fullWidth={true}
                                      onChange={handleChange}
                                      disabled={disablePromoCode}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {coupon && (
                                              <Button
                                                onClick={() =>
                                                  handleCheckCoupon(
                                                    coupon,
                                                    getCoupon,
                                                  )
                                                }
                                              >
                                                Apply
                                              </Button>
                                            )}
                                            {couponValid && (
                                              <Check
                                                style={{
                                                  color: 'green',
                                                }}
                                              />
                                            )}
                                            {!couponValid && (
                                              <FontAwesomeIcon
                                                icon={[
                                                  'far',
                                                  'xmark',
                                                ]}
                                                className="font-size-md" /*style={{ color: 'red' }}*/
                                              />
                                            )}
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              )
                            }
                          }}
                        </Mutation>
                      </Grid>
                    </Collapse>
                    {isCoaching && (
                      <Grid item style={{ marginTop: 15 }}>
                        <Mutation mutation={GET_COUPON}>
                          {(getCoupon, { loading, error, data }) => {
                            if (
                              loading ||
                              partnerLoading ||
                              affiliateLoading
                            )
                              return 'Loading...'
                            // if (error)
                            //   setState({ ...state,
                            //     couponValid: false,
                            //   })
                            return (
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item xs={12}>
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                  >
                                    <InputLabel id="payment-plans-label">
                                      Payment Plans
                                    </InputLabel>
                                    <Select
                                      labelId="payment-plans-label"
                                      value={paymentIntervals}
                                      onChange={(event) =>
                                        onChangePayments(
                                          event.target.value,
                                          coupon,
                                          getCoupon,
                                        )
                                      }
                                      label="Payment Plans"
                                    >
                                      <MenuItem value={1}>
                                        One Payment
                                      </MenuItem>
                                      <MenuItem value={2}>
                                        Two Payments
                                      </MenuItem>
                                      <MenuItem value={3}>
                                        Three Payments
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                  {paymentIntervals > 1 && (
                                    <Typography
                                      style={{
                                        marginLeft: 12,
                                        fontSize: 13,
                                      }}
                                    >
                                      Selecting Two or Three Payments
                                      splits the coaching fee into
                                      multiple monthly payments. The
                                      next months payment will be the
                                      partial payment of the coaching
                                      fee plus the monthly
                                      subscription amount
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                            )
                          }}
                        </Mutation>
                      </Grid>
                    )}
                    <Collapse in={!state.recharge}>
                      <Grid item style={{ marginTop: 15 }}>
                        <div
                          className="example"
                          style={{ boxSizing: 'unset' }}
                        >
                          <Elements stripe={stripePromise}>
                            <PaymentForm
                              createToken={createToken}
                              setElement={setElement}
                              setResetToken={setResetToken}
                            />
                          </Elements>
                          <Typography>
                            <div
                              id="card-errors"
                              role="alert"
                              style={{
                                color: 'red',
                                fontSize: 13,
                                marginTop: 3,
                                marginLeft: 12,
                                fontWeight: 400,
                              }}
                            ></div>
                          </Typography>
                        </div>
                      </Grid>
                    </Collapse>
                    <Grid item>
                      <Typography
                        style={{
                          marginLeft: 12,
                          marginBottom: 5,
                          fontSize: 13,
                        }}
                      >
                        By continuing, you agree to our{' '}
                        <a
                          href="/Terms"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {'Terms & Conditions'}
                        </a>{' '}
                        and our{' '}
                        <a
                          href="/Privacy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!newOrg && childCorp && (
                  <Grid item>
                    <Typography
                      style={{
                        marginLeft: 12,
                        marginTop: 5,
                        marginBottom: 5,
                        fontSize: 13,
                      }}
                    >
                      As a corporate organization you can't change
                      your subscription so this will create a new
                      organization.
                    </Typography>
                  </Grid>
                )}
              </DialogContent>
              <Query
                query={GET_SUBSCRIPTION_PLAN}
                variables={{
                  subscriptionPlan:
                    subscriptionPlanChoice +
                    (isCoaching ? '' : billing),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading || partnerLoading || affiliateLoading)
                    return 'Loading...'
                  if (error) return `Error! ${error.message}`

                  const { subscriptionPlan } = data
                  const stripePlanId = subscriptionPlan.planId
                  return (
                    <Query
                      query={GET_SUBSCRIPTION_PLAN}
                      variables={{
                        subscriptionPlan:
                          `${subscriptionPlanChoice}-entry-fee` +
                          (paymentIntervals > 1
                            ? `-${paymentIntervals}-payments`
                            : ''),
                      }}
                    >
                      {({ loading, error, data }) => {
                        if (
                          loading ||
                          partnerLoading ||
                          affiliateLoading
                        )
                          return 'Loading...'
                        if (error) return `Error! ${error.message}`

                        const entrySubscriptionPlan =
                          data.subscriptionPlan || {}
                        const entryPlanId =
                          entrySubscriptionPlan.planId
                        return (
                          <DialogActions>
                            {activeTab === 0 && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={nextTab}
                              >
                                NEXT
                              </Button>
                            )}
                            {activeTab === 1 &&
                              (newOrg || childCorp) && (
                                <Mutation
                                  mutation={CREATE_ORGANIZATION}
                                  variables={{
                                    organizationInput: {
                                      name: defaultOrgName,
                                      email: toLower(
                                        props.session.me.email,
                                      ),
                                      stripeToken,
                                      stripePlanId,
                                      coupon,
                                      stripeEntryPlanId: entryPlanId,
                                      paymentIntervals: isCoaching
                                        ? paymentIntervals
                                        : 0,
                                    },
                                  }}
                                  refetchQueries={[
                                    {
                                      query: GET_ME,
                                    },
                                  ]}
                                >
                                  {(
                                    createOrganization,
                                    { data, loading, error },
                                  ) => {
                                    var errorElement =
                                      document.getElementById(
                                        'card-errors',
                                      )

                                    if (error) {
                                      errorElement.textContent =
                                        error.message
                                    }
                                    return (
                                      <div>
                                        <Button onClick={previousTab}>
                                          BACK
                                        </Button>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() =>
                                            handleSubmit(
                                              createOrganization,
                                              subscriptionPlan.price /
                                                100,
                                              stripePlanId,
                                            )
                                          }
                                          disabled={!stripeToken}
                                        >
                                          SUBMIT
                                        </Button>
                                      </div>
                                    )
                                  }}
                                </Mutation>
                              )}
                            {activeTab === 1 &&
                              !(newOrg || childCorp) && (
                                <Mutation
                                  mutation={SUBSCRIBE}
                                  variables={{
                                    userInput: {
                                      email: toLower(
                                        props.session.me.email,
                                      ),
                                      stripeToken,
                                      stripePlanId,
                                      coupon,
                                      stripeEntryPlanId: entryPlanId,
                                      paymentIntervals: isCoaching
                                        ? paymentIntervals
                                        : 0,
                                    },
                                  }}
                                  refetchQueries={[
                                    {
                                      query: GET_ME,
                                    },
                                    {
                                      query: GET_PORTFOLIOS,
                                    },
                                  ]}
                                >
                                  {(
                                    subscribe,
                                    { data, loading, error },
                                  ) => {
                                    var errorElement =
                                      document.getElementById(
                                        'card-errors',
                                      )

                                    if (error) {
                                      errorElement.textContent =
                                        error.message
                                    }
                                    return (
                                      <div>
                                        <Button onClick={previousTab}>
                                          BACK
                                        </Button>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() =>
                                            handleSubmit(
                                              subscribe,
                                              subscriptionPlan.price /
                                                100,
                                            )
                                          }
                                          disabled={
                                            !stripeToken &&
                                            !state.recharge
                                          }
                                        >
                                          SUBMIT
                                        </Button>
                                      </div>
                                    )
                                  }}
                                </Mutation>
                              )}
                          </DialogActions>
                        )
                      }}
                    </Query>
                  )
                }}
              </Query>
            </Dialog>
            <ContactDialog
              open={
                open && props?.session?.me?.activeOrg?.isRealeflow
              }
              handleClose={props.toggleDialog}
              affiliate={affiliate?.memberValue}
            />
          </>
        )
      }}
    </Query>
  )
}

export default SubscribeDialog
