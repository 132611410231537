import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

export const useTokenExpiryChecker = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [renewSession, setRenewSession] = useState(false)
  useEffect(() => {
    const checkExpiry = () => {
      const expiryTime = localStorage.getItem('tokenExpiry')
      const currentTime = Math.floor(Date.now() / 1000) // Convert to seconds
      //   console.log(
      //     'expiryTime:',
      //     expiryTime,
      //     ', currentTime:',
      //     currentTime,
      //   )
      const timeLeft = parseInt(expiryTime) - currentTime // Time left in seconds

      // Check if the session is about to end in the next 20 minutes
      if (timeLeft <= 1200 && timeLeft > 0) {
        // 1200 seconds = 20 minutes
        // Calculate minutes left for a more user-friendly message
        const minutesLeft = Math.ceil(timeLeft / 60)

        // Show notification every 5 minutes within the last 20 minutes
        if (minutesLeft % 5 === 0 || timeLeft === 1200) {
          return enqueueSnackbar(
            `Session about to end in ${minutesLeft} minute(s)`,
            {
              variant: 'warning',
              autoHideDuration: 5 * 60 * 1000,
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              action: (key) => (
                <>
                  <IconButton onClick={() => closeSnackbar(key)} size="large">
                    <CloseIcon style={{ color: 'white' }} />
                  </IconButton>
                </>
              ),
            },
          );
        }
      }

      if (expiryTime && currentTime >= parseInt(expiryTime) - 300) {
        // 300 seconds = 5 minutes
        setRenewSession(true)
      }
    }

    // Check expiry periodically
    const interval = setInterval(checkExpiry, 60000) // Check every minute

    // Cleanup interval on component unmount
    return () => clearInterval(interval)
  }, [])

  return { renewSession }
}
