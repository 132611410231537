/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-04-01 12:18:05
 * @ Description: Component wrapper used to pass user information from the GET_ME query to other components along with other component that can be accessed universally
 */

import React from 'react'
import { Query } from '@apollo/client/react/components'
import { GET_ME } from './queries'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'
import ErrorBoundary from './ErrorBoundary'
import SubscribeDialog from './subscribeDialog'
import Upsell from '@/housefolios-components/Upsell'

import isEmpty from 'lodash/isEmpty'
import SessionOutDialog from '../Dialogs/SessionOut'

const withSession = (Component) => (props) => {
  const [openSubscribe, setOpenSubscribe] = React.useState(false)
  const toggleSubscribe = (limit) => {
    setOpenSubscribe(!openSubscribe)
  }
  const [openLimit, setOpenLimit] = React.useState('')

  const toggleLimit = (limit) => {
    setOpenLimit(limit)
  }
  const closeLimit = () => {
    setOpenLimit('')
  }
  //console.log("session", props)
  return (
    <Query
      query={GET_ME}
      // fetchPolicy="cache-and-network"
    >
      {({ loading, data, refetch }) => {
        if (loading)
          return (
            <Backdrop
              open={loading}
              style={{ color: '#fff', zIndex: 2000 }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )
        if (data) {
          return (
            <ErrorBoundary>
              <Component
                {...props}
                session={data}
                refetch={refetch}
                toggleSubscribe={toggleSubscribe}
                toggleLimit={
                  data
                    ? data.me
                      ? data.me.activeOrg
                        ? isEmpty(data.me.activeOrg.subscriptions)
                          ? toggleSubscribe
                          : toggleLimit
                        : toggleLimit
                      : toggleLimit
                    : toggleLimit
                }
              />
              {data.me && Boolean(openSubscribe) && (
                <SubscribeDialog
                  session={data}
                  open={openSubscribe}
                  toggleDialog={toggleSubscribe}
                />
              )}
              {Boolean(openLimit) && (
                <Upsell
                  openLimit={openLimit}
                  session={data}
                  closeLimit={closeLimit}
                />
              )}
            </ErrorBoundary>
          )
        }
        return <SessionOutDialog />
      }}
    </Query>
  )
}

export default withSession
