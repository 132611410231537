import { gql } from '@apollo/client'

export const DELETE_PROPERTIES = gql`
  mutation deleteProperties($ids: [ID!]) {
    deleteProperties(ids: $ids) {
      count
    }
  }
`

export const REMOVE_SHARED_PROPERTIES = gql`
  mutation removeSharedProperties(
    $propertyIds: [String]!
    $orgId: String!
  ) {
    removeSharedProperties(propertyIds: $propertyIds, orgId: $orgId) {
      _id
    }
  }
`

export const UPDATE_SHARED_PROPERTIES = gql`
  mutation updateSharedProperties(
    $propertyIds: [String]!
    $orgId: String!
    $sharePermission: String!
  ) {
    updateSharedProperties(
      propertyIds: $propertyIds
      orgId: $orgId
      sharePermission: $sharePermission
    ) {
      _id
    }
  }
`

export const SHARE_PROPERTIES = gql`
  mutation shareProperties(
    $ids: [ID]!
    $emails: [String!]
    $sharePermission: String
    $notificationId: String
  ) {
    shareProperties(
      ids: $ids
      emails: $emails
      sharePermission: $sharePermission
      notificationId: $notificationId
    ) {
      count
    }
  }
`

export const ADD_SHARED_PROPERTIES = gql`
  mutation addSharedProperties($token: String!, $orgId: String!) {
    addSharedProperties(token: $token, orgId: $orgId) {
      _id
    }
  }
`
