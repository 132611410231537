/**
 * @ Author: Housefolios
 * @ Create Time: 2023-01-09 15:48:40
 * @ Modified by: David Helmick
 * @ Modified time: 2024-11-21 19:37:52
 * @ Description: cards with rental comp information
 */

import React, { useEffect, useState } from 'react'

import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'

import Slider1 from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Avatar,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Slider,
  Typography,
  IconButton,
  Button,
} from '@mui/material'

import { UPDATE_COMPPROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_COMPPROPERTIES } from '@/housefolios-components/PropertyContainer/queries'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { useMutation } from '@apollo/client'
import withSession from '@/housefolios-components/Session/withSession'

var months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

function SliderArrowNext(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  )
}

function SliderArrowPrev(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  )
}

function CenterPageCompsCardComponent(props) {
  const { openEdit, comp, property } = props

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
  })

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const widgetsCarousels1B = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
  }

  const [inputAdjustment, setInputAdjustment] = useState(
    comp.priceAdjustment,
  )

  useEffect(() => {
    setInputAdjustment(comp.priceAdjustment)
  }, [comp])

  const [updateCompProperty] = useMutation(UPDATE_COMPPROPERTY)

  const onChangeSlider = (
    event,
    value,
    name,
    updateCompProperty = null,
  ) => {
    setInputAdjustment(value)
    if (updateCompProperty) {
      updateCompProperty({
        variables: {
          id: comp._id,
          compPropertyInput: {
            priceAdjustment: Number(value),
          },
        },
        refetchQueries: [
          {
            query: GET_COMPPROPERTIES,
            variables: { propertyId: property._id },
          },
        ],
      })
    }
  }

  return (
    <Grid
      item
      xs={12}
      lg={6}
      xl={4}
      className="mb-spacing-6 d-flex w-100"
    >
      <div
        className="card card-box-hover-rise"
        style={{ backgroundColor: 'white', width: '100%' }}
      >
        <Slider1
          className="card-img-top slider-dots"
          {...widgetsCarousels1B}
        >
          {comp.rentalImage ? (
            <div
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <Card
                className="shadow-none"
                style={{
                  height: 325,
                  backgroundColor: 'white',
                  margin: 1,
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  style={{ height: '100%' }}
                >
                  <Grid item>
                    <div
                      className="card-img-wrapper"
                      onClick={
                        props.shared ? null : () => openEdit(comp)
                      }
                      style={{
                        textAlign: 'center',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <img
                        src={comp.rentalImage}
                        className="card-img-top rounded"
                        style={{
                          maxHeight: 325,
                          height: 'auto',
                          borderBottomRightRadius: '0px',
                          objectFit: 'contain',
                        }}
                        alt="..."
                      />
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </div>
          ) : (
            <div
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <Card
                className="shadow-none"
                style={{
                  height: 325,
                  backgroundColor: 'white',
                  margin: 1,
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  style={{ height: '100%' }}
                >
                  <Grid item>
                    <div
                      className="card-img-wrapper"
                      onClick={
                        props.shared ? null : () => openEdit(comp)
                      }
                      style={{
                        textAlign: 'center',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <img
                        src="/images/default_image.png"
                        className="card-img-top rounded"
                        style={{
                          maxHeight: 325,
                          height: 'auto',
                          borderBottomRightRadius: '0px',
                          objectFit: 'contain',
                        }}
                        alt="..."
                      />
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </div>
          )}
        </Slider1>
        <div
          className="card-tr-actions"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'transparent',
          }}
        >
          <Grid container spacing={1} style={{ textAlign: 'left' }}>
            {!props.shared && (
              <Grid item>
                <IconButton
                  onClick={props.selectComp(comp._id)}
                  size="large"
                >
                  <Checkbox
                    checked={includes(props.selected, comp._id)}
                    style={{
                      height: 20,
                      width: 20,
                      backgroundColor: 'white',
                      borderRadius: 0,
                    }}
                  />
                </IconButton>
              </Grid>
            )}
            <Grid item>
              <Avatar
                className={props.potential ? 'bg-warning' : null}
                style={{
                  height: 30,
                  width: 30,
                  backgroundColor: props.potential ? null : '#2f3f4d',
                }}
              >
                {props.index + 1}
              </Avatar>
            </Grid>
          </Grid>
        </div>
        {/* <div className="card-tr-actions">
          <Button className="btn-link p-0 font-size-xl" variant="text">
            <FontAwesomeIcon icon={['far', 'square']} className="font-size-lg" />
          </Button>
        </div> */}
        <CardContent>
          <Grid container direction="column">
            <Grid item>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>
                  {/* <Typography style={{ fontSize: 26, fontWeight: 600 }}>{currencyFormat.format(comp.estval)}</Typography> */}
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: 16 }}>
                    {comp.beds} bd | {comp.baths} ba | Accommodates{' '}
                    {comp.accommodates}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>
                  <Typography
                    style={{ fontSize: 16, fontWeight: 600 }}
                    noWrap={true}
                  >
                    {comp.city}, {comp.state} {comp.zip}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ marginBottom: 10, marginTop: 10 }}>
              <Card style={{ backgroundColor: '#eff2f5' }}>
                <CardContent style={{ padding: 10 }}>
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              whiteSpace: 'nowrap',
                              color: '#949798',
                              fontWeight: 600,
                            }}
                          >
                            Avg Daily Rate
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              whiteSpace: 'nowrap',
                              color: '#008bff',
                              fontWeight: 600,
                            }}
                          >
                            {currencyFormat.format(comp.rentalPrice)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              whiteSpace: 'nowrap',
                              color: '#949798',
                              fontWeight: 600,
                            }}
                          >
                            Occupancy Rate
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              whiteSpace: 'nowrap',
                              fontWeight: 600,
                            }}
                          >
                            {comp.occupancyRate
                              ? percentageFormat.format(
                                  comp.occupancyRate,
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              whiteSpace: 'nowrap',
                              color: '#949798',
                              fontWeight: 600,
                            }}
                          >
                            Distance
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              whiteSpace: 'nowrap',
                              fontWeight: 600,
                              color:
                                parseFloat(
                                  merge(
                                    DEFAULT_PREFERENCES,
                                    props.session.me.activeOrg
                                      .preferences,
                                  ).findComps.maxDistance,
                                ) < comp.distance &&
                                comp.potential &&
                                '#f4772e',
                            }}
                          >
                            {numberFormat.format(comp.distance)} miles
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item style={{ marginTop: 10 }}>
              <Button
                fullWidth
                variant="outlined"
                onClick={(event) =>
                  window.open(
                    comp.rentalURL,
                    '_blank',
                    'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
                  )
                }
              >
                AIRBNB
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </Grid>
  )
}

export default withSession(CenterPageCompsCardComponent)
