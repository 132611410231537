/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: David Helmick
 * @ Modified time: 2023-01-30 16:30:47
 * @ Description: Page content that displays if a marketplace has no properties
 */

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function EmptyPortfolio(props) {

  return (
    <div className="text-center p-5">
      <div className="avatar-icon-wrapper rounded-circle m-0">
        <div style={{ backgroundColor: '#c1ebcf', color: '#2ab755' }} className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130">
          <FontAwesomeIcon
            icon={['fas', 'sign-hanging']}
            className="d-flex align-self-center display-3 "
          />
        </div>
      </div>
      <h4 className="font-weight-bold mt-4">No {props.sold ? 'sold ' : ''}properties on this Marketplace</h4>
      <p className="mb-0 font-size-lg text-muted">
        There are currently no {props.sold ? 'sold ' : ''}houses listed on this Marketplace. Check again soon!
      </p>
    </div>
  );
}