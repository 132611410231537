/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-16 11:36:56
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-04 12:26:14
 * @ Description: Page that shows when logging in without a active org. Includes options to signup for a free account
 */

import React, { useState } from 'react'
import toLower from 'lodash/toLower'
import upperFirst from 'lodash/upperFirst'
import { GET_ME } from '../../Session/queries'

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material'

import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

import particles2 from '@/assets/images/hero-bg/particles-2.svg'
import deactivatedImage from '@/assets/images/DeactivatedImage.png'
import { enqueueSnackbar } from 'notistack'
import CreateNewOrg from '@/housefolios-components/OrganizationSettings/CreateNewOrg'
import { ApolloConsumer } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { signOut } from '@/housefolios-components/SignOut'
import withSession from '@/housefolios-components/Session/withSession'
import { CREATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'

function DeactivatedPageComponent(props) {
  const { session } = props
  const { email } = session.me
  const [openCreateFreeAccount, setOpenCreateFreeAccount] =
    useState(false)

  const handleOpenCreateFreeAccount = () => {
    setOpenCreateFreeAccount(true)
  }
  const handleCloseCreateFreeAccount = () => {
    setOpenCreateFreeAccount(false)
  }

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  let hfpid = urlParams.get('hfpid')

  const [loader, setLoader] = useState(false)

  const handleSubmit = async (createOrganization) => {
    setLoader(true)
    const productChoice = 'free'
    const planPrice = 0
    const planID = 'free'
    let gaID = hfpid
      ? upperFirst(productChoice) +
        '_' +
        hfpid.toString() +
        '_' +
        email
      : productChoice + '_' + email

    createOrganization()
      .then(async ({ data }) => {
        ReactPixel.track('StartTrial', {
          value: planPrice,
          currency: 'USD',
          predicted_ltv: planPrice * 6,
          description:
            `email=${email}` +
            '&' +
            `productChoice=${productChoice}` +
            '&' +
            (hfpid ? `hfpid=${hfpid.toString()}` : ''),
        })
        ReactGA.plugin.execute('ecommerce', 'addItem', {
          id: gaID,
          name: upperFirst(productChoice),
          sku: planID,
          price: planPrice,
          quantity: 1,
          currency: 'USD',
        })
        ReactGA.plugin.execute('ecommerce', 'addTransaction', {
          id: gaID,
          revenue: planPrice,
          total: planPrice,
          shipping: 0,
          tax: 0,
          currency: 'USD',
        })
        ReactGA.plugin.execute('ecommerce', 'send')
        ReactGA.plugin.execute('ecommerce', 'clear')

        localStorage.setItem(
          `buyBox-${data.createOrganization.orgId}`,
          true,
        )
        localStorage.setItem('token', data.createOrganization.token)
        //await props.refetch()
        // props.history.push('/launchpad')
        window.location.reload()
        setLoader(false)
        //this.props.history.push(routes.MARKETPLACE + 'card')
      })
      .catch((error) => {
        console.log('"' + error.message + '"')
        // if (
        //   error.message !== 'GraphQL error: Email already exists.'
        // ) {
        //   ReactPixel.track('StartTrial', {
        //     value: planPrice,
        //     currency: 'USD',
        //     predicted_ltv: planPrice * 6,
        //     description:
        //       `email=${email}` +
        //       '&' +
        //       `productChoice=${productChoice}` +
        //       '&' +
        //       (hfpid ? `hfpid=${hfpid.toString()}` : ''),
        //   })
        //   ReactGA.plugin.execute('ecommerce', 'addItem', {
        //     id: gaID + error.message,
        //     name: upperFirst(productChoice),
        //     sku: stripePlanId,
        //     price: planPrice,
        //     quantity: 1,
        //     currency: 'USD',
        //   })
        //   ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        //     id: gaID + error.message,
        //     revenue: planPrice,
        //     total: planPrice,
        //     shipping: 0,
        //     tax: 0,
        //     currency: 'USD',
        //   })
        //   ReactGA.plugin.execute('ecommerce', 'send')
        //   ReactGA.plugin.execute('ecommerce', 'clear')
        // }
        // if (element) element.clear()
        setLoader(false)
      })
  }
  return (
    <>
      {/* <ListItem button onClick={handleOpenEditPortfolio} selected>
        <AttachFileOutlinedIcon className="mr-2" />
        Test3
      </ListItem>
      <Dialog
        open={openEditPortfolio}
        onClose={handleCloseEditPortfolio}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="xl"
        fullWidth={true}
      > */}
      <div className="hero-wrapper--content">
        <div
          className="bg-composed-wrapper--image bg-composed-filter-rm opacity-7"
          style={{ backgroundImage: 'url(' + particles2 + ')' }}
        />
        <div className="bg-composed-wrapper--content">
          <div className="py-3 py-xl-5">
            <Container className="text-black py-5">
              <Grid container spacing={6}>
                <Grid
                  item
                  xl={6}
                  className="d-flex align-items-center"
                >
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="badge badge-pill rounded badge-success px-4 h-auto py-1">
                        ACCOUNT NOT ACTIVE
                      </div>
                    </div>
                    <div className="text-black mt-3">
                      <h1 className="display-2 mb-3 font-weight-bold">
                        You aren't tied to an active organization.
                      </h1>
                      <p className="font-size-lg text-black-50">
                        This occurs when you've canceled your account
                        or been removed as a team member of an active
                        organization.
                      </p>
                      <p className="text-black-50">
                        If this is an error, contact your Org Creator.
                        If you are the Org Creator then message our
                        support team
                      </p>
                      <div className="divider border-2 border-dark my-4 border-light opacity-2 rounded-circle w-25" />
                      <div>
                        <CreateNewOrg deactive={true} />
                        <ApolloConsumer>
                          {(client) => (
                            <Button
                              href={null}
                              onClick={handleOpenCreateFreeAccount}
                              // onClick={() => signOut(client, props.history, props?.session?.me?.activeOrg?.member)}
                              size="large"
                              variant="text"
                              className="btn-outline-first d-block d-sm-inline-block ml-0 mt-3 mt-sm-0 ml-sm-3"
                            >
                              <span>Sign Out</span>
                            </Button>
                          )}
                        </ApolloConsumer>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xl={6}
                  className="px-0 d-none d-xl-flex align-items-center"
                >
                  <img
                    src={deactivatedImage}
                    className="w-100 mx-auto d-block img-fluid"
                    alt="..."
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
      <Dialog
        open={openCreateFreeAccount}
        onClose={handleCloseCreateFreeAccount}
        maxWidth="xs"
        fullWidth={true}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">Changing Account Type</div>
          }
        >
          {/* <DialogTitle>Test</DialogTitle> */}
          <DialogContent>
            Before you leave sign up for a free account!
          </DialogContent>
          <DialogActions>
            <ApolloConsumer>
              {(client) => (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      signOut(
                        client,
                        props?.session?.me?.activeOrg?.member,
                      )
                    }
                  >
                    Sign Out
                  </Button>
                  <Mutation
                    mutation={CREATE_ORGANIZATION}
                    variables={{
                      organizationInput: {
                        name:
                          session.me.profile.firstname +
                          session.me.profile.lastname,
                        email: toLower(session.me.email),
                      },
                      freeAccount: true,
                    }}
                    refetchQueries={[
                      {
                        query: GET_ME,
                      },
                    ]}
                  >
                    {(
                      createOrganization,
                      { data, loading, error },
                    ) => {
                      if (error) {
                        console.log(error.message)
                      }
                      return (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleSubmit(createOrganization)
                          }
                        >
                          Free Account
                        </Button>
                      )
                    }}
                  </Mutation>
                </>
              )}
            </ApolloConsumer>
          </DialogActions>
        </BlockUi>
      </Dialog>
      {/* </Dialog> */}
    </>
  )
}

export default withSession(DeactivatedPageComponent)
