import { useState } from 'react'

import head from 'lodash/head'
import fill from 'lodash/fill'
import maxBy from 'lodash/maxBy'
import isEmpty from 'lodash/isEmpty'

function useAddPropertyDetails() {
  const [userListPrice, setUserListPrice] = useState()
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [county, setCounty] = useState('')
  const [zip, setZip] = useState('')
  const [beds, setBeds] = useState([''])
  const [baths, setBaths] = useState([''])
  const [sqft, setSqft] = useState([''])
  const [year, setYear] = useState('')
  const [zpid, setZpid] = useState('')
  const [MLSID, setMLSID] = useState('')
  const [marketplaceDescription, setMarketplaceDescription] =
    useState('')
  const [newImages, setNewImages] = useState([])
  const [propertyData, setPropertyData] = useState(null)
  const [reapiPropertyData, setReapiPropertyData] = useState(null)
  const [location, setLocation] = useState({})

  const calculatePropertyDetailsFromData = (
    propertySearch,
    property,
    finalAddress,
    propertyData,
    reapiPropertyData,
    location,
    zipValues,
    unitCount,
  ) => {
    let selectedImages = []
    if (propertySearch && !property.marketplaces) {
      if (property.images) {
        try {
          let images = JSON.parse(property.images)
          images = Object.values(images)
          selectedImages = images || []
        } catch (error) {
          console.log(error)
        }
      }
      if (property.image) selectedImages.push(property.image)
    } else if (propertyData) {
      if (!isEmpty(propertyData.images)) {
        try {
          let images = JSON.parse(head(propertyData.images))
          images = Object.values(images)
          selectedImages = images || []
        } catch (error) {
          console.log(error)
        }
      }
      if (propertyData.image) selectedImages.push(propertyData.image)
    }

    let beds = fill(new Array(unitCount), 0)
    let baths = fill(new Array(unitCount), 0)
    let sqft = fill(new Array(unitCount), 0)

    beds[0] = parseFloat(
      propertySearch && property.beds
        ? property.beds
        : propertyData
        ? maxBy(propertyData.beds, (n) => Number(n)) || 0
        : 0,
    )
    baths[0] = parseFloat(
      propertySearch && property.baths
        ? property.baths
        : propertyData
        ? maxBy(propertyData.baths, (n) => Number(n)) || 0
        : 0,
    )
    sqft[0] = parseInt(
      propertySearch && property.sqft
        ? property.sqft
        : propertyData
        ? maxBy(propertyData.sqft, (n) => Number(n)) || 0
        : 0,
    )

    setAddress(finalAddress)
    setCity(
      propertyData
        ? head(propertyData.city) || location.locality
        : location.locality,
    )
    setState(
      propertyData
        ? head(propertyData.state) || zipValues
          ? zipValues.statesShort[0]
          : location.administrative_area_level_1
        : zipValues
        ? zipValues.statesShort[0]
        : location.administrative_area_level_1,
    )
    setCounty(zipValues ? zipValues.county : '')
    setZip(
      propertyData
        ? head(propertyData.zip) || location.postal_code
        : location.postal_code,
    )
    setBeds(beds)
    setBaths(baths)
    setSqft(sqft)
    setYear(
      propertySearch && property.yearBuilt
        ? property.yearBuilt
        : propertySearch && property.year
        ? property.year
        : propertyData
        ? head(propertyData.year)
        : '',
    )
    setZpid(propertyData ? head(propertyData.zpid) : '')
    setMLSID(
      propertySearch && property.mlsID
        ? property.mlsID
        : propertyData
        ? head(propertyData.mlsID)
        : '',
    )
    setMarketplaceDescription(
      propertySearch && property.description
        ? property.description
        : propertyData
        ? head(propertyData.description)
        : '',
    )
    setUserListPrice(
      !isEmpty(propertyData) && propertyData.value
        ? head(propertyData.value.max) || 0
        : 0,
    )
    setNewImages(selectedImages)
    setPropertyData(propertyData)
    setReapiPropertyData(head(reapiPropertyData))
    setLocation(location)

    return {
      address: finalAddress,
      city: propertyData
        ? head(propertyData.city) || location.locality
        : location.locality,
      state: propertyData
        ? head(propertyData.state) || zipValues
          ? zipValues.statesShort[0]
          : location.administrative_area_level_1
        : zipValues
        ? zipValues.statesShort[0]
        : location.administrative_area_level_1,
      county: zipValues ? zipValues.county : '',
      zip: propertyData
        ? head(propertyData.zip) || location.postal_code
        : location.postal_code,
      beds,
      baths,
      sqft,
      year:
        propertySearch && property.yearBuilt
          ? property.yearBuilt
          : propertySearch && property.year
          ? property.year
          : propertyData
          ? head(propertyData.year)
          : '',
      zpid: propertyData ? head(propertyData.zpid) : '',
      mlsID:
        propertySearch && property.mlsID
          ? property.mlsID
          : propertyData
          ? head(propertyData.mlsID)
          : '',
      marketplaceDescription:
        propertySearch && property.description
          ? property.description
          : propertyData
          ? head(propertyData.description)
          : '',
      userListPrice:
        !isEmpty(propertyData) && propertyData.value
          ? head(propertyData.value.max) || 0
          : 0,
      newImages: selectedImages,
      propertyData,
      reapiPropertyData,
      location: location,
    }
  }

  return [
    {
      userListPrice,
      address,
      city,
      state,
      county,
      zip,
      beds,
      baths,
      sqft,
      year,
      zpid,
      MLSID,
      marketplaceDescription,
      newImages,
      propertyData,
      reapiPropertyData,
      location,
    },
    {
      setUserListPrice,
      setAddress,
      setCity,
      setState,
      setCounty,
      setZip,
      setBeds,
      setBaths,
      setSqft,
      setYear,
      setZpid,
      setMLSID,
      setMarketplaceDescription,
      setNewImages,
      setPropertyData,
      setReapiPropertyData,
      setLocation,
    },
    {
      calculatePropertyDetailsFromData,
    },
  ]
}

export default useAddPropertyDetails
