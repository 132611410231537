import React from 'react'

import clsx from 'clsx'

import { IconButton, Link, List, Typography } from '@mui/material'

import get from 'lodash/get'

import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import YouTubeIcon from '@mui/icons-material/YouTube'

import { connect } from 'react-redux'
import useAffiliate from '@/hooks/useAffiliate'
import { Link as RouterLink } from 'react-router-dom'

const PublicFooter = (props) => {
  const { footerShadow, footerBgTransparent } = props

  const hostname = import.meta.env.VITE_HOSTNAME

  const [{ affiliate, affiliateLoading, urlDomain, urlAffiliate }] =
    useAffiliate({})

  const homeLink = urlDomain
    ? 'https://' + urlDomain + '/'
    : hostname === 'localhost'
      ? 'http://localhost:3000/'
      : hostname === 'dev-admin.housefolios.com'
        ? 'https://dev-admin.housefolios.com/'
        : 'https://app.housefolios.com/'

  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent,
        })}
      >
        <div className="app-footer--first">
          <span>
            Copyright © {new Date().getFullYear()}{' '}
            {(affiliate?.companyName === 'Deal Sauce (Subto)'
              ? 'Deal Sauce'
              : affiliate?.companyName) || 'Housefolios'}
            Marketplace 2019-2021
          </span>
        </div>
        <div className="app-footer--second">
          <a href={homeLink}>
            <Typography color="primary">Powered by</Typography>
            <img
              alt="Housefolios"
              src={
                urlAffiliate
                  ? get(
                      affiliate,
                      'sidebar.sidebarMemberFullImage.0.url',
                    ) || '/images/NewLogoBlue.png'
                  : '/images/NewLogoBlue.png'
              }
              style={{ width: 150, marginLeft: 5, marginRight: 5 }}
            />
          </a>
        </div>
        <List
          component="div"
          className="nav-neutral-primary d-flex align-items-center"
        >
          <Link
            className="rounded-sm"
            component={RouterLink}
            to="/Terms"
            target="_blank"
            style={{ color: '#a7aabb' }}
          >
            <span>Terms</span>
          </Link>
          <Link
            className="rounded-sm"
            component={RouterLink}
            to="/Privacy"
            target="_blank"
            style={{
              marginLeft: 20,
              marginRight: 20,
              color: '#a7aabb',
            }}
          >
            <span>Privacy</span>
          </Link>
          <Link
            className="rounded-sm"
            component={RouterLink}
            to="/Legal"
            target="_blank"
            style={{ color: '#a7aabb' }}
          >
            <span>Legal Disclaimer</span>
          </Link>
        </List>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  footerShadow: state.DeprecatedThemeOptions.footerShadow,
  footerBgTransparent:
    state.DeprecatedThemeOptions.footerBgTransparent,
})

export default connect(mapStateToProps)(PublicFooter)
