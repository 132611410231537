/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2024-06-07 11:18:43
 * @ Description: Card to review and change billing plan
 */

import React, { useState } from 'react'
import clsx from 'clsx'
import map from 'lodash/map'
import split from 'lodash/split'
import upperFirst from 'lodash/upperFirst'
import round from 'lodash/round'
import isEmpty from 'lodash/isEmpty'
import replace from 'lodash/replace'
import { makeStyles } from '@mui/styles'
import {
  AppBar,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Dialog,
  Grid,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material'
import withSession from '@/housefolios-components/Session/withSession'
import { useMutation } from '@apollo/client'
import { CREATE_SESSION } from '@/housefolios-components/LaunchpadComponents/mutations'
import SubscriptionPlanCard from './SubscriptionPlanCard'
import { currencyFormat } from '@/utils/number'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(3),
    color: 'white',
    flex: 1,
  },
}))
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function SaveMoneyComponent(props) {
  const classes = useStyles()

  const [createBillingPortalSession] = useMutation(CREATE_SESSION)

  const [showPlans, setShowPlans] = useState(false)
  const handleOpenShowPlans = () => {
    setShowPlans(true)
    // if (props.closeParent)
    //   props.closeParent()
  }
  const handleCloseShowPlans = () => {
    setShowPlans(false)
  }

  const [plan, billing] = props.session.me.subscriptionPlan
    ? split(replace(props.session.me.subscriptionPlan, '_', '-'), '-')
    : ''

  const [activeTab, setActiveTab] = useState(
    billing === 'yearly' ? 'checkedB' : 'checkedC',
  )

  function tabSwitch(tab) {
    if (activeTab !== tab) setActiveTab(tab)
  }

  async function changePlan() {
    var session = await createBillingPortalSession()

    window.open(
      session.data.createBillingPortalSession.url,
      '_self',
      '',
    )
  }

  const pricingPlans = props.session.me.activeOrg.currentlyFree
    ? [
        {
          nameMonthly: 'free-monthly',
          nameYearly: 'free-yearly',
          label: 'Free',
          subHeaderMonthly: '',
          subHeaderYearly: '',
          search: '*Nationwide property search',
          analyze: '*Analyze off-market properties',
          comp: '*Sold comp data',
        },
        {
          nameMonthly: 'investor-monthly',
          nameYearly: 'investor-yearly',
          label: 'Investor',
          subHeaderMonthly: 'Monthly Plan',
          subHeaderYearly: 'Annual Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
        },
        {
          nameMonthly: 'growth-monthly',
          nameYearly: 'growth-yearly',
          label: 'Growth',
          subHeaderMonthly: 'Monthly Plan',
          subHeaderYearly: 'Annual Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
          //housefoliosMarketplace: 'List on Housefolios marketplace',
        },
        {
          nameMonthly: 'pro-monthly',
          nameYearly: 'pro-yearly',
          label: 'Pro',
          subHeaderMonthly: 'Monthly Plan',
          subHeaderYearly: 'Annual Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
          //housefoliosMarketplace: 'List on Housefolios marketplace',
        },
      ]
    : [
        {
          nameMonthly: 'investor-monthly',
          nameYearly: 'investor-yearly',
          label: 'Investor',
          subHeaderMonthly: 'Monthly Plan',
          subHeaderYearly: 'Annual Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
        },
        {
          nameMonthly: 'growth-monthly',
          nameYearly: 'growth-yearly',
          label: 'Growth',
          subHeaderMonthly: 'Monthly Plan',
          subHeaderYearly: 'Annual Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
          //housefoliosMarketplace: 'List on Housefolios marketplace',
        },
        {
          nameMonthly: 'pro-monthly',
          nameYearly: 'pro-yearly',
          label: 'Pro',
          subHeaderMonthly: 'Monthly Plan',
          subHeaderYearly: 'Annual Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
          //housefoliosMarketplace: 'List on Housefolios marketplace',
        },
      ]

  const date = !isEmpty(props.session.me.activeOrg.subscriptions)
    ? props.session.me.activeOrg.subscriptions[0].current_period_end
      ? new Date(
          props.session.me.activeOrg.subscriptions[0]
            .current_period_end * 1000,
        )
      : new Date('01/01/1970')
    : props.session.me.activeOrg.parentCorp &&
      !isEmpty(props.session.me.activeOrg.parentCorp.subscriptions)
    ? props.session.me.activeOrg.parentCorp.subscriptions[0]
        .current_period_end
      ? new Date(
          props.session.me.activeOrg.parentCorp.subscriptions[0]
            .current_period_end * 1000,
        )
      : new Date('01/01/1970')
    : props?.session?.me?.activeOrg?.memberProvidedSubscriptions &&
      !isEmpty(
        props?.session?.me?.activeOrg?.memberProvidedSubscriptions,
      )
    ? new Date(
        props?.session?.me?.activeOrg?.memberProvidedSubscriptions[0]
          .current_period_end * 1000,
      )
    : new Date('01/01/1970')
  const dateString = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`
  const noSub = dateString === '1/1/1970' ? true : false
  const disabled = !isEmpty(props.session.me.activeOrg.subscriptions)
    ? props.session.me.activeOrg.subscriptions[0].cancel_at_period_end
    : props.session.me.activeOrg.parentCorp &&
      !isEmpty(props.session.me.activeOrg.parentCorp.subscriptions)
    ? props.session.me.activeOrg.parentCorp.subscriptions[0]
        .cancel_at_period_end
    : true
  const isCoached = props.session.me.subscriptionPlan
    ? props.session.me.subscriptionPlan === 'group-coaching' ||
      props.session.me.subscriptionPlan === 'personalized-coaching'
      ? true
      : false
    : false
  return (
    <>
      <Card
        className="card-box w-100 d-flex flex-column flex-grow-1 justify-content-center align-items-center shadow-xxl text-center p-4 text-white"
        style={{ backgroundColor: '#253152', position: 'relative' }}
      >
        <span className="ribbon-angle ribbon-angle--top-right ribbon-primary">
          <small>Selected</small>
        </span>
        <h5 className="display-5 font-weight-bold mb-4">
          {plan ? upperFirst(plan) : 'Special'} Plan
        </h5>
        <div className="divider bg-white-10 mb-4" />
        <div>
          <div className="mb-2">
            <span className="display-2 font-weight-bold">
              {isCoached
                ? '$149'
                : currencyFormat.format(
                    round(props.session.me.subscriptionPrice / 100),
                  )}
            </span>
            <span className="opacity-8">
              /{' '}
              {isCoached
                ? 'month'
                : props.session.me.subscriptionPlan ===
                  'enterprise-affiliate'
                ? 'monthly'
                : billing === 'monthly'
                ? 'month'
                : 'year'}
            </span>
          </div>
          {!props.session.me.activeOrg.currentlyFree && !noSub && (
            <ul className="list-unstyled opacity-6 mb-4">
              <li>
                {disabled
                  ? 'Plan will be canceled on: '
                  : 'Next billing period '}{' '}
                <b style={{ color: 'white' }}>{dateString}</b>
              </li>
            </ul>
          )}
        </div>
        <div className="divider bg-white-10 mb-4" />
        {billing === 'monthly' && !disabled && (
          <>
            <div className="mb-3 font-size-lg font-weight-bold">
              {/* Switch to annual billing & save 20% */}
              Save 20% with annual billing
            </div>
          </>
        )}

        {props?.session?.me?.activeOrg?.member === 'RWN' && (
          <Button
            onClick={
              isEmpty(props.session.me.activeOrg.subscriptions)
                ? props.toggleSubscribe
                : changePlan
            }
            fullWidth
            className="btn-primary font-weight-bold shadow-sm-dark text-uppercase font-size-sm d-inline-block"
          >
            Manage Plan
          </Button>
        )}
        {props?.session?.me?.activeOrg?.member !== 'RWN' &&
          !isCoached && (
            <Button
              className="btn-primary font-weight-bold shadow-sm-dark text-uppercase font-size-sm d-inline-block"
              fullWidth
              onClick={handleOpenShowPlans}
              target="_blank"
            >
              {billing === 'yearly'
                ? 'Compare Plans'
                : 'Get more by upgrading'}
            </Button>
          )}
      </Card>
      <Dialog
        fullScreen
        open={showPlans}
        onClose={handleCloseShowPlans}
        TransitionComponent={Transition}
      >
        <AppBar enableColorOnDark className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Subscription Plans
            </Typography>
            <Button
              variant="outlined"
              margin="dense"
              style={{ color: 'white', borderColor: 'white' }}
              onClick={handleCloseShowPlans}
            >
              Cancel
            </Button>
          </Toolbar>
        </AppBar>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{
            // padding: 100,
            paddingTop: 100,
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 25,
          }}
        >
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant="h5" color="textPrimary">
              Save 20% with annual billing{' '}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              marginTop: 5,
              marginBottom: 20,
            }}
          >
            <ButtonGroup size="small" variant="text">
              <Button
                className={clsx(
                  'btn-outline-primary btn-transition-none',
                  { active: activeTab === 'checkedC' },
                )}
                onClick={() => {
                  tabSwitch('checkedC')
                }}
              >
                Monthly
              </Button>
              <Button
                className={clsx(
                  'btn-outline-primary btn-transition-none',
                  { active: activeTab === 'checkedB' },
                )}
                onClick={() => {
                  tabSwitch('checkedB')
                }}
              >
                Annual
              </Button>
            </ButtonGroup>
          </Grid>
          {map(pricingPlans, (section) => (
            <SubscriptionPlanCard
              key={section.id} // Assuming each section has a unique ID
              activeTab={activeTab}
              section={section}
              session={props?.session}
              toggleSubscribe={props?.toggleSubscribe}
              changePlan={changePlan}
            />
          ))}
        </Grid>
        {props.session.me.activeOrg.currentlyFree && (
          <Grid
            item
            xs={12}
            style={{
              position: 'absolute',
              bottom: '50px',
              left: '50px',
            }}
          >
            <Typography style={{ fontSize: 12 }}>
              * Included in the free account you have the ability to
              analyze 5 properties. The only way to be able to add
              additional properties is by earning them through our
              bonus property program.
            </Typography>
          </Grid>
        )}
      </Dialog>
    </>
  )
}

export default withSession(SaveMoneyComponent)
