/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2024-08-07 13:11:15
 * @ Description: Dialog to restore a property from the trash
 */

import React, { useState } from 'react'

import map from 'lodash/map'
import find from 'lodash/find'
import head from 'lodash/head'
import unset from 'lodash/unset'

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material'

import { useMutation } from '@apollo/client'
import { FolderOpenOutlined } from '@mui/icons-material'
import {
  GET_PROPERTIES,
  GET_TRASH,
} from '@/housefolios-components/Properties/queries'
import { GET_ASSUMPTIONS } from '@/housefolios-components/AssumptionsSettings/quries'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import withSession from '@/housefolios-components/Session/withSession'
import CreatePortfolio from '@/housefolios-components/Portfolio/CreatePortfolio'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'

//Based off of Restore Properties which is based off of Change Portfolio

function RestorePropertyComponent(props) {
  const { button } = props
  const [openChangePortfolio, setChangePortfolio] = useState(false)
  const [loader, setLoader] = useState(false)
  const handleOpenChangePortfolio = () => {
    setChangePortfolio(true)
  }
  const handleCloseChangePortfolio = () => {
    setChangePortfolio(false)
    setLoader(false)
  }

  const [openPortfolio, setOpenPortfolio] = useState(false)

  const handleOpenPortfolio = () => {
    setOpenPortfolio(true)
  }
  const handleClosePortfolio = () => {
    setOpenPortfolio(false)
  }

  const [assumptionsChange, setAssumptionsChange] = useState('new')
  const [strategyChange, setStrategyChange] = useState('new')

  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  const [selectedPortfolio, setSelectedPortfolio] = useState(
    props.portfolioId
      ? props.portfolioId
      : head(props.portfolios)._id,
  )

  const movePortfolio = () => {
    localStorage.setItem('portfolio', selectedPortfolio)
    setLoader(true)
    let propertyInput = {
      portfolioId: selectedPortfolio,
      strategy: find(props.portfolios, { _id: selectedPortfolio })
        .strategy,
      deleted: false,
    }
    if (strategyChange === 'same') unset(propertyInput, 'strategy')
    updateProperty({
      awaitRefetchQueries: true,
      variables: {
        id: props.propertyId,
        propertyInput,
        keepAssumptions: assumptionsChange === 'same',
      },
      refetchQueries: [
        {
          query: GET_PORTFOLIOS,
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [selectedPortfolio],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [props.portfolioId],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_TRASH,
        },
        {
          query: GET_ASSUMPTIONS,
          variables: {
            documentId: props.propertyId,
            documentType: 'property',
          },
        },
        {
          query: GET_PROPERTY,
          variables: {
            id: props.propertyId,
          },
        },
      ],
      // onCompleted: (data) =>
      //   console.log(
      //     'Restore property',
      //     data.updateProperty.__typename,
      //     data,
      //   ),
    })
      .then(async ({ data }) => {
        if (props.setAssumptionsInput) {
          let newAssumptions = find(props.portfolios, {
            _id: selectedPortfolio,
          }).assumptions
          unset(newAssumptions, '_id')
          unset(newAssumptions, '__v')
          unset(newAssumptions, '__typename')
          unset(newAssumptions, 'documentId')
          unset(newAssumptions, 'documentType')
          unset(newAssumptions, 'updatedAt')
          unset(newAssumptions, 'updatedBy')
          props.setAssumptionsInput(newAssumptions)
        }
        enqueueSnackbar(
          `Property was restored to ${
            find(props.portfolios, { _id: selectedPortfolio }).name
          }`,
          {
            variant: 'success',
            autoHideDuration: 3000,
          },
        )
        handleCloseChangePortfolio()
        if (props.setSelected) props.setSelected([])
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to restore property`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseChangePortfolio()
      })
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  return (
    <>
      {button && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={
            props?.session?.me?.activeOrg?.member !== 'RWN'
              ? handleOpenChangePortfolio
              : movePortfolio
          }
        >
          Restore Property
        </Button>
      )}
      <Dialog
        open={openChangePortfolio}
        onClose={handleCloseChangePortfolio}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="xs"
        fullWidth={true}
        onKeyDown={stopPropagationForTab}
      >
        {/* <Card> */}
        <DialogTitle id="form-dialog-title">
          Restore Property
        </DialogTitle>
        <DialogContent className="p-4">
          <BlockUi
            blocking={loader}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={
              <div className="text-primary">Restore Property</div>
            }
          >
            {/* <CardHeader title="Change Portfolio" />
            <Divider /> */}
            {/* <CardContent>
              <div> */}
            <Container style={{ padding: 0 }}>
              <Grid
                container
                direction="row"
                spacing={2}
                style={{ marginTop: 3 }}
              >
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{ width: '100%' }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Select Portfolio
                    </InputLabel>
                    <Select
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      //id=""
                      select
                      label="Select Portfolio"
                      value={selectedPortfolio}
                      onChange={(event) =>
                        setSelectedPortfolio(event.target.value)
                      }
                      open={openPortfolio}
                      onOpen={handleOpenPortfolio}
                      onClose={handleClosePortfolio}
                      MenuProps={{ keepMounted: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FolderOpenOutlined />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end"></InputAdornment>
                        ),
                      }}
                      disabled={
                        props?.session?.me?.activeOrg?.member ===
                        'RWN'
                      }
                    >
                      {props?.session?.me?.activeOrg?.member ===
                        'RWN' && (
                        <MenuItem
                          key="My Properties"
                          value={
                            props.portfolios
                              ? props.portfolios[0]._id
                              : null
                          }
                        >
                          My Properties
                        </MenuItem>
                      )}
                      {props?.session?.me?.activeOrg?.member !==
                        'RWN' &&
                        map(props.portfolios, (option) => (
                          <MenuItem
                            key={option.name}
                            value={option._id}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      {props?.session?.me?.activeOrg?.member !==
                        'RWN' && (
                        <CreatePortfolio
                          dropdown={true}
                          setNewPortfolio={setSelectedPortfolio}
                          closeMenu={handleClosePortfolio}
                        />
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 600 }}>
                    Assumptions
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name="assumptionsChange"
                      value={assumptionsChange}
                      onChange={(event) =>
                        setAssumptionsChange(event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="new"
                        control={<Radio />}
                        label="Apply the selected portfolio's assumptions to this property"
                      />
                      <FormControlLabel
                        value="same"
                        control={<Radio />}
                        label="Keep this property's assumptions the same"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Typography style={{ fontWeight: 600 }}>
                    Strategy
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name="strategyChange"
                      value={strategyChange}
                      onChange={(event) =>
                        setStrategyChange(event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="new"
                        control={<Radio />}
                        label="Apply the selected portfolio's strategy to this property"
                      />
                      <FormControlLabel
                        value="same"
                        control={<Radio />}
                        label="Keep this property's strategy the same"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {/* <Grid item>
                      <Checkbox />
                    </Grid>
                    <Grid item>
                      <Typography>Apply assumptions from {find(props.portfolios, { _id: selectedPortfolio }).strategy}</Typography>
                    </Grid> */}
              </Grid>
            </Container>
            {/* </div>
            </CardContent> */}
            {/* <Divider /> */}
          </BlockUi>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCloseChangePortfolio}>
            Cancel
          </Button>
          <Button
            onClick={movePortfolio}
            variant="contained"
            color="primary"
          >
            Restore
          </Button>
        </DialogActions>
        {/* <CardActions>
              <Typography style={{ flex: 1 }} />
              <Button
                variant='text'
                onClick={handleCloseChangePortfolio}
              >
                Cancel
              </Button>
              <Button onClick={movePortfolio} variant='contained' color='primary'>
                Change
              </Button>
            </CardActions> */}
        {/* </Card> */}
      </Dialog>
    </>
  )
}

export default withSession(RestorePropertyComponent)
