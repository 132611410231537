/**
 * @ Author: Housefolios
 * @ Create Time: 2021-10-05 11:07:32
 * @ Modified by: David Helmick
 * @ Modified time: 2024-06-07 14:43:22
 * @ Description: Dialog to copy a property
 */

import React, { useState } from 'react'

import map from 'lodash/map'
import head from 'lodash/head'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'

import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import { useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { COPY_PROPERTY } from '../mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import withSession from '@/housefolios-components/Session/withSession'
import CreatePortfolio from '@/housefolios-components/Portfolio/CreatePortfolio'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'

function DuplicatePropertyComponent(props) {
  const { notifications, rightClickMenu, underwriting } = props

  const [openDuplicateProperty, setDuplicateProperty] =
    useState(false)
  const [loader, setLoader] = useState(false)
  const handleOpenDuplicateProperty = () => {
    setDuplicateProperty(true)
    setLoader(false)
  }
  const handleCloseDuplicateProperty = () => {
    setDuplicateProperty(false)
  }

  const [openPortfolio, setOpenPortfolio] = useState(false)

  const handleOpenPortfolio = () => {
    setOpenPortfolio(true)
  }
  const handleClosePortfolio = () => {
    setOpenPortfolio(false)
  }

  const [selectedPortfolio, setSelectedPortfolio] = useState(
    props.selectedPortfolio || head(props.portfolios)._id,
  )
  //const [selectedPortfolio, setSelectedPortfolio] = useState(props.selectedPortfolio !== 'null' ? props.selectedPortfolio : head(props.portfolios)._id)

  const [copyProperty] = useMutation(COPY_PROPERTY)

  const onSubmit = () => {
    setLoader(true)
    copyProperty({
      awaitRefetchQueries: true,
      variables: {
        propertyId: props.propertyId,
        portfolioId: selectedPortfolio,
      },
      refetchQueries: [
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [selectedPortfolio],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: map(
              props.portfolios,
              (portfolio) => portfolio._id,
            ),
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PORTFOLIOS,
        },
        {
          query: GET_ME,
        },
      ],
    })
      .then(() => {
        enqueueSnackbar(`Property was Duplicated`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        handleCloseDuplicateProperty()
        if (props.setSelected) props.setSelected([])
      })
      .catch((error) => {
        enqueueSnackbar(`Property Failed to Duplicate`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseDuplicateProperty()
      })
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const { monthlyCount, bonusProperties = 0 } =
    props.session.me.activeOrg.propertyCount

  const { limits } = props.session.me

  const LIMIT = limits.propertyLimit + bonusProperties

  return (
    <>
      {rightClickMenu && (
        <ListItem
          component="a"
          button
          onClick={handleOpenDuplicateProperty}
          className="rounded-sm"
        >
          <Grid container direction="row" spacing={1}>
            <Grid item className="gridItem2">
              <FontAwesomeIcon
                icon={['far', 'copy']}
                className="font-size-lg"
              />
            </Grid>
            <Grid item className="gridItem2">
              <Typography>
                {props.buttonText
                  ? props.buttonText
                  : 'Duplicate Property'}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {notifications && (
        <MenuItem
          className="nav-item my-1"
          onClick={handleOpenDuplicateProperty}
        >
          <FontAwesomeIcon
            icon={['far', 'copy']}
            className="font-size-lg"
            style={{ marginRight: 10 }}
          />
          <span>Duplicate Property</span>
        </MenuItem>
      )}
      {underwriting && (
        <Button
          variant="contained"
          color="primary"
          onClick={
            monthlyCount + 1 > LIMIT
              ? () => props.toggleLimit('property')
              : handleOpenDuplicateProperty
          }
          style={{ marginLeft: 15 }}
        >
          Underwriting
        </Button>
      )}
      <Dialog
        open={openDuplicateProperty}
        onClose={handleCloseDuplicateProperty}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="xs"
        fullWidth={true}
        onKeyDown={stopPropagationForTab}
      >
        <DialogTitle id="form-dialog-title">
          Duplicate Property
        </DialogTitle>
        <DialogContent className="p-4">
          <BlockUi
            blocking={loader}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={
              <div className="text-primary">Duplicating Property</div>
            }
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item>
                <Typography>
                  Choose the portfolio where you'd like to place the
                  property duplicate. This will not affect the
                  original property.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  style={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Change Portfolio
                  </InputLabel>
                  <Select
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    //id=""
                    label="Select Portfolio"
                    value={selectedPortfolio}
                    onChange={(event) =>
                      setSelectedPortfolio(event.target.value)
                    }
                    open={openPortfolio}
                    onOpen={handleOpenPortfolio}
                    onClose={handleClosePortfolio}
                    MenuProps={{ keepMounted: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FolderOpenOutlinedIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end"></InputAdornment>
                      ),
                    }}
                    disabled={
                      props?.session?.me?.activeOrg?.member === 'RWN'
                    }
                  >
                    {props?.session?.me?.activeOrg?.member ===
                      'RWN' && (
                      <MenuItem
                        key="My Properties"
                        value={
                          props.portfolios
                            ? props.portfolios[0]._id
                            : null
                        }
                      >
                        My Properties
                      </MenuItem>
                    )}
                    {props?.session?.me?.activeOrg?.member !==
                      'RWN' &&
                      map(props.portfolios, (option) => (
                        <MenuItem
                          key={option.name}
                          value={option._id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    {props?.session?.me?.activeOrg?.member !==
                      'RWN' && (
                      <CreatePortfolio
                        dropdown={true}
                        setNewPortfolio={setSelectedPortfolio}
                        closeMenu={handleClosePortfolio}
                      />
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </BlockUi>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={handleCloseDuplicateProperty}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 10 }}
            disabled={loader}
            onClick={onSubmit}
          >
            Duplicate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withSession(DuplicatePropertyComponent)
