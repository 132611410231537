/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2024-11-21 19:37:32
 * @ Description: Map marker with unique icon and attached infowindow property card
 */

import React, { useRef, useState } from 'react'

import { Marker, InfoWindow } from 'react-google-maps'
import {
  Grid,
  Tooltip,
  Card,
  CardContent,
  Typography,
  Avatar,
  Chip,
} from '@mui/material'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import cloneDeep from 'lodash/cloneDeep'
import assign from 'lodash/assign'
import slice from 'lodash/slice'
import sum from 'lodash/sum'
import get from 'lodash/get'
import set from 'lodash/set'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import merge from 'lodash/merge'

import {
  ApolloProvider,
  useMutation,
  ApolloClient,
  ApolloLink,
  split,
  InMemoryCache,
  HttpLink,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import includes from 'lodash/includes'
import { signOut } from '@/housefolios-components/SignOut'
import { SnackbarProvider } from 'notistack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slider from 'react-slick'
import withSession from '@/housefolios-components/Session/withSession'
import {
  ArrowDownward,
  ArrowUpward,
  Remove,
} from '@mui/icons-material'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTIES } from '../queries'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'

import './style.css'
import { GET_ME } from '@/housefolios-components/Session/queries'
import CriticalFields from '@/housefolios-components/Dialogs/CriticalFields'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import { getProformaLink } from '@/utils/proforma'
import PropertyInformationDialogs from '@/housefolios-components/Dialogs/PropertyInformationDialogs'

function SliderArrowNext(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  )
}

function SliderArrowPrev(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  )
}

function CustomMapMarkerComponent(props) {
  const urlParams = new URLSearchParams(window.location.search)
  const subdomain = urlParams.get('subdomain') || null
  const subdomainParam = subdomain ? '?subdomain=' + subdomain : ''

  const { shared } = props

  const httpLink = new HttpLink({
    uri: import.meta.env.VITE_URI,
  })
  const hostname = import.meta.env.VITE_HOSTNAME

  const wsLink = new GraphQLWsLink(
    createClient({
      uri: import.meta.env.VITE_WSURI,
      options: {
        reconnect: true,
      },
    }),
  )

  const terminatingLink = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return (
        kind === 'OperationDefinition' && operation === 'subscription'
      )
    },
    wsLink,
    httpLink,
  )

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers = { ...headers, 'x-token': token }
      }

      return { headers }
    })

    return forward(operation)
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        const expired = includes(message, 'Your session expired')

        if (message === 'UNAUTHENTICATED' || expired) {
          signOut(client)
        }
      })
    }

    if (networkError) {
      if (networkError.statusCode === 401) {
        signOut(client)
      }
    }
  })

  const link = ApolloLink.from([authLink, errorLink, terminatingLink])

  const cache = new InMemoryCache()

  const client = new ApolloClient({
    link,
    cache,
  })

  const { showInfoWindow, setShowInfoWindow } = props
  const toggleWindow = () => {
    if (showInfoWindow) setShowInfoWindow(null)
    else setShowInfoWindow(property._id)
  }

  const { property, marketplace, propertySearch } = props

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const percentageFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  })

  const slider = useRef()
  const widgetsCarousels1B = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
  }

  const analytics = !property.acquisition
    ? {}
    : !shared && props?.session?.me?.activeOrg?.member === 'RWN'
      ? [
          {
            value: property.acquisition.analysis
              ? property.acquisition.analysis.resellPrice * 0.2
              : '-',
            label: 'Down Payment',
            type: 'currency',
          },
          {
            value: property.acquisition.analysis
              ? property.acquisition.analysis.rent
              : '-',
            label: 'Est Rental Income',
            type: 'currency',
          },
          {
            value: property.acquisition.analytics
              ? property.acquisition.analytics.buyHold.cashFlow > 0
                ? property.acquisition.analytics.buyHold.cashFlow / 12
                : '-'
              : '-',
            label: 'Est. Cashflow',
            type: 'currency',
          },
        ]
      : props.propertySearch
        ? []
        : (property.strategy === 'Fix & Flip' &&
              !props.marketplace) ||
            (property.marketplaceStrategy === 'Fix & Flip' &&
              props.marketplace)
          ? [
              {
                value: property.acquisition.analytics
                  ? (property.strategy === 'Turnkey End Buyer' &&
                      !props.marketplace) ||
                    (property.marketplaceStrategy ===
                      'Turnkey End Buyer' &&
                      props.marketplace)
                    ? property.acquisition.analytics.turnkey
                        .netROITurnkey
                    : property.acquisition.analytics.fixFlip
                        .netROIFixFlip
                  : '-',
                arrow: property.acquisition.analytics
                  ? (property.strategy === 'Turnkey End Buyer' &&
                      !props.marketplace) ||
                    (property.marketplaceStrategy ===
                      'Turnkey End Buyer' &&
                      props.marketplace)
                    ? 0
                    : property.acquisition.analytics.fixFlip
                        .netROIArrow
                  : 0,
                label: 'Net ROI',
              },
              {
                value: property.acquisition.analytics
                  ? property.acquisition.analytics.fixFlip
                      .saleProceeds
                  : '-',
                arrow: property.acquisition.analytics
                  ? property.acquisition.analytics.fixFlip
                      .saleProceedsArrow
                  : 0,
                label: 'Net Profit',
                type: 'currency',
              },
              {
                value: property.acquisition.analytics
                  ? (property.strategy === 'Turnkey End Buyer' &&
                      !props.marketplace) ||
                    (property.marketplaceStrategy ===
                      'Turnkey End Buyer' &&
                      props.marketplace)
                    ? property.acquisition.analytics.turnkey
                        .allInCostTurnkey > 0
                      ? property.acquisition.analytics.turnkey
                          .allInCostTurnkey
                      : '-'
                    : property.acquisition.analytics.buyHold
                          .allInCost > 0
                      ? property.acquisition.analytics.buyHold
                          .allInCost
                      : '-'
                  : '-',
                label: 'All in Cost',
                type: 'currency',
              },
              {
                value: property.acquisition.analysis
                  ? property.acquisition.analytics.fixFlip
                      .arvTotalFixFlip
                  : '-',
                label: 'ARV',
                type: 'currency',
              },
            ]
          : [
              {
                value: property.acquisition.analytics
                  ? (property.strategy === 'Turnkey End Buyer' &&
                      !props.marketplace) ||
                    (property.marketplaceStrategy ===
                      'Turnkey End Buyer' &&
                      props.marketplace)
                    ? property.acquisition.analytics.turnkey
                        .IRRTurnkey
                    : property.acquisition.analytics.buyHold.IRR
                  : '-',
                arrow: property.acquisition.analytics
                  ? (property.strategy === 'Turnkey End Buyer' &&
                      !props.marketplace) ||
                    (property.marketplaceStrategy ===
                      'Turnkey End Buyer' &&
                      props.marketplace)
                    ? property.acquisition.analytics.turnkey
                        .IRRTurnkeyArrow
                    : property.acquisition.analytics.buyHold.IRRArrow
                  : 0,
                label: 'IRR',
              },
              {
                value: property.acquisition.analytics
                  ? (property.strategy === 'Turnkey End Buyer' &&
                      !props.marketplace) ||
                    (property.marketplaceStrategy ===
                      'Turnkey End Buyer' &&
                      props.marketplace)
                    ? property.acquisition.analytics.turnkey
                        .capRateTurnkey
                    : property.acquisition.analytics.buyHold.capRate
                  : '-',
                arrow: property.acquisition.analytics
                  ? (property.strategy === 'Turnkey End Buyer' &&
                      !props.marketplace) ||
                    (property.marketplaceStrategy ===
                      'Turnkey End Buyer' &&
                      props.marketplace)
                    ? property.acquisition.analytics.turnkey
                        .capRateTurnkeyArrow
                    : property.acquisition.analytics.buyHold
                        .capRateArrow
                  : 0,
                label:
                  (property.strategy === 'Turnkey End Buyer' &&
                    !props.marketplace) ||
                  (property.marketplaceStrategy ===
                    'Turnkey End Buyer' &&
                    props.marketplace)
                    ? 'Cap Rate'
                    : 'Yield-on-Cost',
              },
              {
                value: property.acquisition.analytics
                  ? (property.strategy === 'Turnkey End Buyer' &&
                      !props.marketplace) ||
                    (property.marketplaceStrategy ===
                      'Turnkey End Buyer' &&
                      props.marketplace)
                    ? property.acquisition.analytics.turnkey
                        .cashOnCashReturnTurnkey
                    : property.acquisition.analytics.buyHold
                        .cashOnCashReturn
                  : '-',
                arrow: property.acquisition.analytics
                  ? (property.strategy === 'Turnkey End Buyer' &&
                      !props.marketplace) ||
                    (property.marketplaceStrategy ===
                      'Turnkey End Buyer' &&
                      props.marketplace)
                    ? property.acquisition.analytics.turnkey
                        .cashOnCashReturnTurnkeyArrow
                    : property.acquisition.analytics.buyHold
                        .cashOnCashReturnArrow
                  : '-',
                label: 'Cash on Cash',
              },
              {
                value: property.acquisition.analytics
                  ? (property.strategy === 'Turnkey End Buyer' &&
                      !props.marketplace) ||
                    (property.marketplaceStrategy ===
                      'Turnkey End Buyer' &&
                      props.marketplace)
                    ? property.acquisition.analytics.turnkey
                        .equityTurnkey > 0
                      ? property.acquisition.analytics.turnkey
                          .equityTurnkey
                      : '-'
                    : property.acquisition.analytics.buyHold.equity >
                        0
                      ? property.acquisition.analytics.buyHold.equity
                      : '-'
                  : '-',
                label: 'Equity',
                type: 'currency',
              },
            ]

  const upArrows = filter(analytics, (analytic) => analytic.arrow)
  const downArrows = filter(
    analytics,
    (analytic) => !analytic.arrow && !isNil(analytic.arrow),
  )
  const noArrows = filter(analytics, (analytic) =>
    isNil(analytic.arrow),
  )

  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  const phpSend = (remove = false) => {
    if (remove) {
      let data = {
        _id: property._id,
        remove_property: remove,
      }

      const str_json = JSON.stringify(data)
      //SOURCE COMMENT: I wanted to replace XMLHttpRequest so I followed the example of https://developers.google.com/web/updates/2015/03/introduction-to-fetch#:~:text=fetch()%20allows%20you%20to,the%20complex%20API%20of%20XMLHttpRequest.
      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    } else {
      let data = cloneDeep(property)
      let expRelist = { expRelist: true }
      let activeMP = []
      data.beds = sum(data.beds)
      data.baths = sum(data.baths)
      data.sqft = sum(data.sqft)
      if (get(data, 'acquisition.analysis')) {
        map(data.acquisition.analysis, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `acquisition.analysis.${key}`,
              sum(get(data, `acquisition.analysis.${key}`)),
            )
          }
        })
      }
      if (get(data, 'assumptions')) {
        map(data.assumptions, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `assumptions.${key}`,
              sum(get(data, `assumptions.${key}`)),
            )
          }
        })
      }
      if (get(data, 'acquisition.analytics')) {
        map(data.acquisition.analytics, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `acquisition.analytics.${key}`,
              sum(get(data, `acquisition.analytics.${key}`)),
            )
          } else if (isObject(value)) {
            map(value, (secondKey, value) => {
              if (isArray(value)) {
                set(
                  data,
                  `acquisition.analytics.${key}.${secondKey}`,
                  sum(
                    get(
                      data,
                      `acquisition.analytics.${key}.${secondKey}`,
                    ),
                  ),
                )
              }
            })
          }
        })
      }
      const { proformaType } = property.marketplaces
      const proformaLink = getProformaLink(data._id, [proformaType])

      data.proformaURL = proformaLink
      if (
        property.marketplaces &&
        !isEmpty(property.marketplaces.marketplaceName)
      ) {
        activeMP.push({
          marketplaces: {
            marketplaceName: property.marketplaces.marketplaceName,
            approvedMarketplace:
              property.marketplaces.approvedMarketplace,
            expirationDate: new Date(
              Date.now() + 7 * 24 * 60 * 60 * 1000,
            ),
            extraTags: property.marketplaces.extraTags,
            proformaType: property.marketplaces.proformaType,
          },
        })

        data = assign(data, activeMP[0], expRelist)
      }

      const str_json = JSON.stringify(data)

      //SOURCE COMMENT: I wanted to replace XMLHttpRequest so I followed the example of https://developers.google.com/web/updates/2015/03/introduction-to-fetch#:~:text=fetch()%20allows%20you%20to,the%20complex%20API%20of%20XMLHttpRequest.
      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    }
  }

  const [openCriticalFields, setOpenCriticalFields] = useState(false)

  const handleOpenCriticalFields = () => {
    setOpenCriticalFields(true)
  }

  const handleCloseCriticalFields = () => {
    setOpenCriticalFields(false)
  }

  let preferences = shared
    ? cloneDeep(DEFAULT_PREFERENCES)
    : merge(
        cloneDeep(DEFAULT_PREFERENCES),
        props?.session?.me?.activeOrg?.memberPreferences,
        props?.session?.me?.activeOrg?.preferences,
      )

  // useEffect(() => {
  //   let index = findIndex(
  //     property.images,
  //     image => image.url === property.mainImage,
  //   )
  //   //slider.current.slickGoTo(index > 0 ? index : 0)
  // }, [property])

  //label={currencyFormat.format(propertySearch ? property.price : props.marketplace ? property.acquisition.analysis.resellPrice : property.acquisition.analysis.listPrice)}
  return (
    <Marker
      position={props.position}
      icon={props.icon}
      zIndex={999}
      onMouseOver={() => setShowInfoWindow(property._id)}
    >
      {showInfoWindow === property._id &&
        !props.propertyPage &&
        !props.noInfoWindow && (
          <>
            <InfoWindow
              style={{ padding: -10 }}
              open={showInfoWindow === property._id}
              onClick={(event) => event.stopPropagation()}
              onCloseClick={toggleWindow}
              options={{
                pixelOffset: new window.google.maps.Size(205, 200),
              }}
            >
              <ApolloProvider client={client}>
                <SnackbarProvider maxSnack={3}>
                  <a
                    href={
                      props.public
                        ? `/public-property-details/${property._id}/Summary${subdomainParam}`
                        : `/property-details/${property._id}/Summary`
                    }
                    onClick={
                      props.marketplace
                        ? (event) => event.preventDefault()
                        : (event) => {
                            event.preventDefault()
                            handleOpenCriticalFields()
                          }
                    }
                  >
                    <Slider
                      ref={slider}
                      className="card-img-top slider-dots"
                      {...widgetsCarousels1B}
                      style={{ width: 300 }}
                    >
                      {props.propertySearch &&
                      !property.marketplaces &&
                      property.images ? (
                        map(JSON.parse(property.images), (image) => (
                          <div key={image}>
                            <Card
                              className="shadow-none"
                              style={{
                                height: 250,
                                backgroundColor: 'black',
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                              }}
                            >
                              <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                style={{ height: '100%' }}
                              >
                                <Grid item>
                                  <div
                                    href={
                                      props.propertySearch
                                        ? '/property-search'
                                        : props.public
                                          ? `/public-property-details/${property._id}/Summary${subdomainParam}`
                                          : `/property-details/${property._id}/Summary`
                                    }
                                    onClick={(event) => {
                                      if (props.marketplace)
                                        event.preventDefault()
                                      else {
                                        event.preventDefault()
                                        handleOpenCriticalFields()
                                      }
                                    }}
                                    className="card-img-wrapper"
                                    style={{
                                      textAlign: 'center',
                                      backgroundColor: 'transparent',
                                      borderBottomRightRadius: '0px',
                                    }}
                                  >
                                    <img
                                      src={image}
                                      className="card-img-top"
                                      style={{ objectFit: 'contain' }}
                                      alt="..."
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Card>
                          </div>
                        ))
                      ) : props.propertySearch &&
                        !property.marketplaces &&
                        property.image ? (
                        <div>
                          <Card
                            className="shadow-none"
                            style={{
                              height: 250,
                              backgroundColor: 'black',
                              borderBottomLeftRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                          >
                            <Grid
                              container
                              direction="column"
                              justifyContent="center"
                              style={{ height: '100%' }}
                            >
                              <Grid item>
                                <div
                                  href={
                                    props.propertySearch
                                      ? '/property-search'
                                      : props.public
                                        ? `/public-property-details/${property._id}/Summary${subdomainParam}`
                                        : `/property-details/${property._id}/Summary`
                                  }
                                  onClick={(event) => {
                                    if (props.marketplace)
                                      event.preventDefault()
                                    else {
                                      event.preventDefault()
                                      handleOpenCriticalFields()
                                    }
                                  }}
                                  className="card-img-wrapper"
                                  style={{
                                    textAlign: 'center',
                                    backgroundColor: 'transparent',
                                  }}
                                >
                                  <img
                                    src={property.image}
                                    className="card-img-top"
                                    style={{ objectFit: 'contain' }}
                                    alt="..."
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                      ) : !isEmpty(property.images) ? (
                        map(slice(property.images, 0, 5), (image) => (
                          <div key={image.url}>
                            <Card
                              className="shadow-none"
                              style={{
                                height: 250,
                                backgroundColor: 'black',
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                              }}
                            >
                              <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                style={{ height: '100%' }}
                              >
                                <Grid item>
                                  <div
                                    href={
                                      props.marketplace
                                        ? '/marketplace'
                                        : props.public
                                          ? `/public-property-details/${property._id}/Summary${subdomainParam}`
                                          : `/property-details/${property._id}/Summary`
                                    }
                                    onClick={(event) => {
                                      if (props.marketplace)
                                        event.preventDefault()
                                      else {
                                        event.preventDefault()
                                        handleOpenCriticalFields()
                                      }
                                    }}
                                    className="card-img-wrapper"
                                    style={{
                                      textAlign: 'center',
                                      backgroundColor: 'transparent',
                                      borderBottomRightRadius: '0px',
                                    }}
                                  >
                                    <div
                                      className="blur-load"
                                      style={{
                                        backgroundImage:
                                          'url(' +
                                          image.url_small +
                                          ')',
                                        height: 250,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <img
                                        src={image.url}
                                        className="card-img-top"
                                        style={{
                                          objectFit: 'contain',
                                          maxHeight: 250,
                                        }}
                                        alt="..."
                                        loading="lazy"
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </Card>
                          </div>
                        ))
                      ) : (
                        <div>
                          <Card
                            className="shadow-none"
                            style={{
                              height: 250,
                              backgroundColor: 'black',
                              borderBottomLeftRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                          >
                            <Grid
                              container
                              direction="column"
                              justifyContent="center"
                              style={{ height: '100%' }}
                            >
                              <Grid item>
                                <a
                                  href={
                                    props.marketplace
                                      ? '/marketplace'
                                      : props.public
                                        ? `/public-property-details/${property._id}/Summary${subdomainParam}`
                                        : `/property-details/${property._id}/Summary`
                                  }
                                  onClick={(event) => {
                                    if (props.marketplace)
                                      event.preventDefault()
                                    else {
                                      event.preventDefault()
                                      handleOpenCriticalFields()
                                    }
                                  }}
                                  className="card-img-wrapper"
                                  style={{
                                    textAlign: 'center',
                                    backgroundColor: 'transparent',
                                  }}
                                >
                                  <img
                                    src="/images/default_image.png"
                                    className="card-img-top"
                                    style={{ objectFit: 'contain' }}
                                    alt="..."
                                  />
                                </a>
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                      )}
                    </Slider>
                    <CardContent className="card-body-avatar">
                      <Grid container direction="column">
                        <Grid item>
                          <Grid
                            container
                            item
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <h5
                                className="font-weight-bold"
                                style={{ fontSize: 26 }}
                              >
                                {currencyFormat.format(
                                  propertySearch
                                    ? property.price
                                    : props.marketplace
                                      ? property.acquisition.analysis
                                          .resellPrice
                                      : property.acquisition.analysis
                                          .listPrice,
                                )}
                              </h5>
                            </Grid>
                            <Grid item>
                              <Tooltip
                                onClick={(event) =>
                                  event.preventDefault()
                                }
                                title={
                                  <Grid container direction="column">
                                    <Grid item>
                                      <Typography>
                                        Status:{' '}
                                        {property.acquisition
                                          .activeStage
                                          ? property.acquisition
                                              .activeStage
                                          : 'new'}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography>
                                        Strategy: {property.strategy}
                                      </Typography>
                                    </Grid>
                                    {!marketplace && (
                                      <Grid item>
                                        <Typography>
                                          Portfolio:{' '}
                                          {property.portfolio?.name}
                                        </Typography>
                                      </Grid>
                                    )}
                                    {!marketplace && (
                                      <Grid item>
                                        <Typography>
                                          Favorite:{' '}
                                          {property.favorite
                                            ? 'Y'
                                            : 'N'}
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                }
                              >
                                <Avatar
                                  style={{
                                    color: 'black',
                                    backgroundColor: 'transparent',
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={['fal', 'info-circle']}
                                  />
                                </Avatar>
                              </Tooltip>
                            </Grid>
                            {!marketplace &&
                              property.marketplaces &&
                              !isEmpty(
                                property.marketplaces
                                  .approvedMarketplace,
                              ) && (
                                <Grid
                                  item
                                  style={{ paddingBottom: 5 }}
                                >
                                  <Tooltip
                                    onClick={(event) =>
                                      event.preventDefault()
                                    }
                                    title={
                                      <Grid
                                        container
                                        direction="column"
                                      >
                                        {map(
                                          property.marketplaces
                                            .approvedMarketplace,
                                          (marketplace) => (
                                            <Grid
                                              item
                                              key={marketplace}
                                            >
                                              <Typography
                                                style={
                                                  marketplace ===
                                                    'housefolios' &&
                                                  new Date(
                                                    property.marketplaces.expirationDate,
                                                  ).getTime() <
                                                    Date.now()
                                                    ? { color: 'red' }
                                                    : null
                                                }
                                              >
                                                {marketplace}
                                              </Typography>
                                            </Grid>
                                          ),
                                        )}
                                      </Grid>
                                    }
                                  >
                                    <Avatar
                                      style={{
                                        color: 'black',
                                        backgroundColor:
                                          'transparent',
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={['fad', 'sign-hanging']}
                                        className="d-block font-size-xl"
                                      />
                                    </Avatar>
                                  </Tooltip>
                                </Grid>
                              )}
                            {!marketplace &&
                              property.marketplaces &&
                              includes(
                                property.marketplaces
                                  .approvedMarketplace,
                                'housefolios',
                              ) &&
                              new Date(
                                property.marketplaces.expirationDate,
                              ).getTime() < Date.now() && (
                                <Grid item>
                                  <Chip
                                    label="Relist"
                                    style={{
                                      backgroundColor: 'red',
                                      color: 'white',
                                      fontWeight: 600,
                                    }}
                                    onClick={(event) => {
                                      event.preventDefault()
                                      updateProperty({
                                        variables: {
                                          id: property._id,
                                          propertyInput: {
                                            marketplaces: {
                                              marketplaceName:
                                                property.marketplaces
                                                  .marketplaceName,
                                              approvedMarketplace:
                                                property.marketplaces
                                                  .approvedMarketplace,
                                              expirationDate:
                                                new Date(
                                                  Date.now() +
                                                    7 *
                                                      24 *
                                                      60 *
                                                      60 *
                                                      1000,
                                                ),
                                              extraTags:
                                                property.marketplaces
                                                  .extraTags,
                                              proformaType:
                                                property.marketplaces
                                                  .proformaType,
                                            },
                                          },
                                        },
                                        refetchQueries: [
                                          {
                                            query: GET_ME,
                                          },
                                          {
                                            query: GET_PROPERTIES,
                                            variables: {
                                              portfolioId: [
                                                props.selectedPortfolio,
                                              ],
                                              limit:
                                                GET_PROPERTIES_QUERY_LIMIT,
                                            },
                                          },
                                          {
                                            query: GET_PROPERTY,
                                            variables: {
                                              id: property._id,
                                            },
                                          },
                                        ],
                                      })
                                      // phpSend()
                                    }}
                                  />
                                </Grid>
                              )}
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography className="font-size-sm">
                            {sum(property.beds)} bd |{' '}
                            {sum(property.baths)} ba |{' '}
                            {numberFormat.format(sum(property.sqft))}{' '}
                            sqft |{' '}
                            {propertySearch && property.yearBuilt
                              ? property.yearBuilt
                              : property.year}{' '}
                            yr
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            onClick={(event) =>
                              event.preventDefault()
                            }
                            title={`${property.address}, ${property.city}, ${property.state} ${property.zip}`}
                          >
                            <Typography
                              variant="h5"
                              className="card-title font-weight-bold font-size-sm"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 200,
                              }}
                            >
                              {property.address}, {property.city},{' '}
                              {property.state} {property.zip}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            {!isEmpty(upArrows) && (
                              <Tooltip
                                onClick={(event) =>
                                  event.preventDefault()
                                }
                                title={
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={1}
                                    wrap="nowrap"
                                    style={{ padding: 0 }}
                                  >
                                    {map(upArrows, (field) => (
                                      <Grid item key={field.label}>
                                        <Grid
                                          container
                                          direction="column"
                                          alignItems="center"
                                        >
                                          <Grid item xs={12}>
                                            <Typography
                                              style={{
                                                whiteSpace: 'nowrap',
                                                fontSize: 13,
                                              }}
                                            >
                                              {field.label}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Grid
                                              container
                                              direction="row"
                                              justifyContent="center"
                                              alignItems="center"
                                              wrap="nowrap"
                                            >
                                              <Grid
                                                item
                                                style={{
                                                  marginLeft: 5,
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontSize: 11,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {field.value !== '-'
                                                    ? field.type ===
                                                      'currency'
                                                      ? currencyFormat.format(
                                                          field.value,
                                                        )
                                                      : percentageFormat.format(
                                                          field.value *
                                                            100,
                                                        ) + '%'
                                                    : field.value}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Grid>
                                }
                              >
                                <Grid item>
                                  <Avatar
                                    style={{
                                      backgroundColor: 'transparent',
                                    }}
                                  >
                                    <ArrowUpward className="text-success" />
                                  </Avatar>
                                  <Typography
                                    style={{ marginLeft: 5 }}
                                  >
                                    {upArrows.length}/
                                    {analytics.length}
                                  </Typography>
                                </Grid>
                              </Tooltip>
                            )}
                          </Grid>
                          <Grid item>
                            {!isEmpty(downArrows) && (
                              <Tooltip
                                onClick={(event) =>
                                  event.preventDefault()
                                }
                                title={
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={1}
                                    wrap="nowrap"
                                    style={{ padding: 0 }}
                                  >
                                    {map(downArrows, (field) => (
                                      <Grid item key={field.label}>
                                        <Grid
                                          container
                                          direction="column"
                                          alignItems="center"
                                        >
                                          <Grid item xs={12}>
                                            <Typography
                                              style={{
                                                whiteSpace: 'nowrap',
                                                fontSize: 13,
                                              }}
                                            >
                                              {field.label}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Grid
                                              container
                                              direction="row"
                                              justifyContent="center"
                                              alignItems="center"
                                              wrap="nowrap"
                                            >
                                              <Grid
                                                item
                                                style={{
                                                  marginLeft: 5,
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontSize: 11,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {field.value !== '-'
                                                    ? field.type ===
                                                      'currency'
                                                      ? currencyFormat.format(
                                                          field.value,
                                                        )
                                                      : percentageFormat.format(
                                                          field.value *
                                                            100,
                                                        ) + '%'
                                                    : field.value}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Grid>
                                }
                              >
                                <Grid item>
                                  <Avatar
                                    style={{
                                      backgroundColor: 'transparent',
                                    }}
                                  >
                                    <ArrowDownward color="error" />
                                  </Avatar>
                                  <Typography
                                    style={{ marginLeft: 5 }}
                                  >
                                    {downArrows.length}/
                                    {analytics.length}
                                  </Typography>
                                </Grid>
                              </Tooltip>
                            )}
                          </Grid>
                          <Grid item>
                            {!isEmpty(noArrows) && (
                              <Tooltip
                                onClick={(event) =>
                                  event.preventDefault()
                                }
                                title={
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                    spacing={1}
                                    wrap="nowrap"
                                    style={{ padding: 0 }}
                                  >
                                    {map(noArrows, (field) => (
                                      <Grid item key={field.label}>
                                        <Grid
                                          container
                                          direction="column"
                                          alignItems="center"
                                        >
                                          <Grid item xs={12}>
                                            <Typography
                                              style={{
                                                whiteSpace: 'nowrap',
                                                fontSize: 13,
                                              }}
                                            >
                                              {field.label}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Grid
                                              container
                                              direction="row"
                                              justifyContent="center"
                                              alignItems="center"
                                              wrap="nowrap"
                                            >
                                              <Grid
                                                item
                                                style={{
                                                  marginLeft: 5,
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontSize: 11,
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {field.value !== '-'
                                                    ? field.type ===
                                                      'currency'
                                                      ? currencyFormat.format(
                                                          field.value,
                                                        )
                                                      : percentageFormat.format(
                                                          field.value *
                                                            100,
                                                        ) + '%'
                                                    : field.value}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Grid>
                                }
                              >
                                <Grid item>
                                  <Avatar
                                    style={{
                                      backgroundColor: 'transparent',
                                    }}
                                  >
                                    <Remove color="primary" />
                                  </Avatar>
                                  <Typography
                                    style={{ marginLeft: 5 }}
                                  >
                                    {noArrows.length}/
                                    {analytics.length}
                                  </Typography>
                                </Grid>
                              </Tooltip>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </a>
                </SnackbarProvider>
              </ApolloProvider>
            </InfoWindow>
            {!shared && (
              <PropertyInformationDialogs
                open={openCriticalFields}
                onClose={handleCloseCriticalFields}
                property={property}
                shared={shared}
                url={
                  props.public
                    ? `/public-property-details/${property._id}/Summary${subdomainParam}`
                    : `/property-details/${property._id}/Summary`
                }
              />
            )}
          </>
        )}
    </Marker>
  )
}

export default withSession(CustomMapMarkerComponent)
