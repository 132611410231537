/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2025-02-28 17:33:09
 * @ Description: Component used to add files from the user's system
 */

import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import includes from 'lodash/includes'
import head from 'lodash/head'
import map from 'lodash/map'
import isNil from 'lodash/isNil'
import {
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { graphql } from '@apollo/client/react/hoc'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_SIGNED_REQUEST } from '@/housefolios-components/PropertyContainer/queries'
import { startsWith } from 'lodash'

const MAXSIZE = 100000000 //bytes
const FILETYPES = ['application/pdf', 'image/*']

class FileUpload extends Component {
  state = {
    error: null,
    progress: null,
    openDelete: null,
    confirm: false,
  }
  validateFile = (file) => {
    if (file.size > MAXSIZE) {
      this.setState({ error: 'file is too big' })
      return false
    }

    // if (!includes(FILETYPES, file.type)) {
    //   this.setState({
    //     error: 'File Type not accepted',
    //     progress: null,
    //   })
    //   return false
    // }
    return true
  }
  getBase64ImageFromFile = async (file) => {
    if (!file) return null
    return new Promise((resolve, reject) => {
      var reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result)
        },
        false,
      )

      reader.onerror = () => {
        return reject(this)
      }
      reader.readAsDataURL(file)
    })
  }
  handleOnDrop = async (files, rejectedFiles) => {
    this.setState({ error: null, progress: files.length })
    let fileData = await Promise.all(
      map(files, async (file) => {
        if (this.validateFile(file)) {
          if (this.props.notSave) {
            this.setState({ progress: this.state.progress - 1 })
            const { profile = {} } = this.props.session.me
            const url = await this.getBase64ImageFromFile(file)
            return {
              filename: file.name,
              filesize: file.size,
              filetype: file.type,
              url: url,
              createdAt: new Date(),
              createdBy: `${profile.firstname} ${profile.lastname}`,
            }
          }
          const {
            data: { getSignedRequest },
          } = await this.props.getSignedRequest({
            variables: {
              fileInput: {
                filename: file.name,
                filetype: file.type,
                collection: this.props.collection,
                documentId: this.props.documentId,
              },
            },
          })
          const { signedRequest, url } = getSignedRequest

          const options = {
            headers: {
              'Content-type': file.type,
            },
          }
          try {
            await axios.put(signedRequest, file, options)
            this.setState({ progress: this.state.progress - 1 })
            const { profile = {} } = this.props.session.me
            return {
              filename: file.name,
              filesize: file.size,
              filetype: file.type,
              url: url,
              createdAt: new Date(),
              createdBy: `${profile.firstname} ${profile.lastname}`,
            }
          } catch (error) {
            return error
          }
        }
      }),
    )
    this.props.uploadFile(fileData)
    map(rejectedFiles, ({ file, errors }) => {
      // if (this.validateFile(file)) {
      //   const fileReader = new FileReader()
      //   fileReader.addEventListener(
      //     'load',
      //     () => {
      //       const images = this.state.imgSrc
      //       images.push(fileReader.result)
      //       this.setState({ imgSrc: images })
      //     },
      //     false,
      //   )
      //   fileReader.readAsDataURL(file)
      // }
      this.setState({ error: head(errors).message })
    })
  }

  render() {
    const { error = null, progress } = this.state
    const { files = null } = this.props
    const {
      button,
      notMulti = false,
      readOnly = false,
      writeOnly = false,
    } = this.props
    return (
      <Grid container direction="column">
        {!button &&
          map(files, (file) => {
            return (
              <Grid item key={Math.random()}>
                <div>
                  <div
                    className="blur-load"
                    style={{
                      backgroundImage: 'url(' + file.url_small + ')',
                    }}
                  >
                    <img
                      alt={file.filename}
                      src={file.url}
                      style={{ width: 360 }}
                    />
                  </div>
                </div>
              </Grid>
            )
          })}
        <Grid item>
          <Grid
            item
            container
            alignItems="center"
            style={
              this.props.buttonStyle
                ? this.props.buttonStyle
                : { marginTop: 25, marginBottom: 25 }
            }
          >
            {!readOnly && (
              <Grid item xs={writeOnly ? 12 : 10}>
                <Dropzone
                  onDrop={this.handleOnDrop}
                  accept={
                    this.props.filetypes
                      ? this.props.filetypes.join(',')
                      : null
                  }
                  maxSize={MAXSIZE}
                  multiple={!notMulti}
                  maxFiles={
                    this.props.maxFiles ? this.props.maxFiles : null
                  }
                  disabled={this.props.shared || this.props.disabled}
                  noClick
                >
                  {({ getRootProps, getInputProps, open }) => (
                    <section style={{ padding: 0 }}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button
                          size="small"
                          variant="contained"
                          color={
                            this.props.buttonColor
                              ? this.props.buttonColor
                              : 'primary'
                          }
                          onClick={open}
                          disabled={
                            this.props.shared || this.props.disabled
                          }
                        >
                          {this.props.buttonIcon
                            ? this.props.buttonIcon
                            : ''}
                          {this.props.buttonText
                            ? this.props.buttonText
                            : 'Upload File'}
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
            )}
            {!writeOnly &&
              map(files, (file, index) => (
                <Grid
                  onClick={(event) => event.stopPropagation()}
                  item
                  style={{ marginLeft: 10 }}
                >
                  <Tooltip title={file.filename}>
                    {startsWith(file.url, 'data:') ? (
                      <a
                        onClick={() => {
                          var iframe =
                            "<iframe width='100%' height='100%' src='" +
                            file.url +
                            "'></iframe>"
                          var x = window.open()
                          x.document.open()
                          x.document.write(iframe)
                          x.document.close()
                        }}
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'paperclip']}
                          className="accordion-icon"
                        />
                      </a>
                    ) : (
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'paperclip']}
                          className="accordion-icon"
                        />
                      </a>
                    )}
                  </Tooltip>
                  {!readOnly && this.props.deleteFile && (
                    <IconButton
                      onClick={() =>
                        this.setState({ openDelete: index })
                      }
                      disabled={this.props.shared}
                      size="large"
                    >
                      <FontAwesomeIcon
                        icon={['far', 'circle-xmark']}
                        className="font-size-md"
                      />
                    </IconButton>
                  )}
                </Grid>
              ))}
          </Grid>
          {error && <div className="text-danger">{error}</div>}
        </Grid>
        <Dialog
          open={!isNil(this.state.openDelete)}
          onClose={() => this.setState({ openDelete: null })}
          classes={{ paper: 'shadow-lg rounded' }}
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                <FontAwesomeIcon
                  icon={['fas', 'trash-can']}
                  size="10x"
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">Delete File?</h4>
            <p className="mb-0 font-size-lg text-muted">
              <FormControl component="fieldset" className="pr-4">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.confirm}
                        onChange={() =>
                          this.setState({
                            confirm: !this.state.confirm,
                          })
                        }
                      />
                    }
                    label="Are you sure you want to delete this"
                  />
                </FormGroup>
              </FormControl>
              {/* Delete this portfolio along with all the properties inside. This can't be undone. */}
            </p>
            <div className="pt-4">
              <Button
                variant="text"
                onClick={() => this.setState({ openDelete: null })}
                //className="btn-neutral-secondary btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: '#f83145', color: 'white' }}
                onClick={() => {
                  this.props.deleteFile(this.state.openDelete)
                  this.setState({ openDelete: null })
                }}
                disabled={!this.state.confirm}
              >
                <span className="btn-wrapper--label">Delete</span>
              </Button>
            </div>
          </div>
        </Dialog>
        <Backdrop
          open={progress > 0}
          style={{ color: '#fff', zIndex: 100000 }}
        >
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <CircularProgress
                variant="indeterminate"
                color="inherit"
              />
            </Grid>
            <Grid item>
              <Typography color="inherit">Uploading Files</Typography>
            </Grid>
          </Grid>
        </Backdrop>
      </Grid>
    )
  }
}

export default withSession(
  graphql(GET_SIGNED_REQUEST, {
    name: 'getSignedRequest',
  })(FileUpload),
)
