import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  Grid,
  Typography,
  Switch,
  TextField,
  MenuItem,
  Button,
} from '@mui/material'
import { ValidatorForm } from 'react-material-ui-form-validator'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from '@/housefolios-components/CreateAccount/PaymentForm' // adjust the import path as needed

// A helper for currency formatting
const currencyFormat2 = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const StripeOneTimePurchase = ({
  open, // controls dialog open state
  onClose, // callback to close dialog
  onSubmit, // form submit handler
  loader, // boolean for loading state
  stripePromise, // Stripe promise for Elements
  recharge, // boolean for toggling new/existing card
  handleRechargeToggle, // toggle handler for the Switch
  createToken, // function to create a token via PaymentForm
  setResetToken, // function to reset token in PaymentForm
  buyCreditsAmount, // current selected credits amount
  setBuyCreditsAmount, // handler to change credits amount
  addOnPrices,
  addOnPackage,
  session, // user session containing organization info
  title, //title on the dialog
}) => {
  let totalDue
  if (title === 'Buy Credits' && buyCreditsAmount) {
    totalDue = currencyFormat2.format(buyCreditsAmount * 0.12)
  } else if (title === 'Buy Add On' && addOnPrices) {
    totalDue = currencyFormat2.format(addOnPrices)
  }
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose} fullWidth>
      <ValidatorForm
        style={{
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
        onSubmit={onSubmit}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">Purchase In Progress</div>
          }
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            <Container>
              <div className="p-4">
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={4}
                >
                  {session?.me?.activeOrg?.stripeCustomerID && (
                    <Grid item xs={12} style={{ width: '100%' }}>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        style={{ marginBottom: 10 }}
                      >
                        <Grid item>
                          <Typography>New Card</Typography>
                        </Grid>
                        <Grid item style={{ marginTop: -3 }}>
                          <Switch
                            checked={recharge}
                            onChange={handleRechargeToggle}
                            color="primary"
                            className="switch-medium"
                          />
                        </Grid>
                        <Grid item>
                          <Typography>Existing Card</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} className="d-flex">
                    <Grid container>
                      <Grid item xs={12}>
                        <h5 className="font-size-xl mb-1 font-weight-bold">
                          Purchase details
                        </h5>
                      </Grid>
                      <Grid item>
                        <p className="text-black-50 mb-4">
                          Please review the purchase details below
                          before pressing Finish
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container direction="column">
                          <Grid item>
                            {title === 'Buy Credits' && (
                              <TextField
                                select
                                value={buyCreditsAmount}
                                onChange={(event) =>
                                  setBuyCreditsAmount(
                                    event.target.value,
                                  )
                                }
                                variant="outlined"
                                label="Number of Credits"
                                fullWidth
                              >
                                {[
                                  25, 50, 75, 100, 150, 200, 250, 500,
                                  1000, 2500,
                                ].map((num) => (
                                  <MenuItem key={num} value={num}>
                                    {num} Credits
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                            {title === 'Buy Add On' && (
                              <Grid item>
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Typography
                                      className="font-weight-semi-bold"
                                      style={{ marginTop: 10 }}
                                    >
                                      {addOnPackage} Add On
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography
                                  className="font-weight-bold"
                                  style={{ marginTop: 10 }}
                                >
                                  Total Due
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  className="font-weight-bold"
                                  style={{ marginTop: 10 }}
                                >
                                  {totalDue}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!recharge && (
                    <Grid item xs={12}>
                      <Grid container direction="column">
                        <Grid item>
                          <h5 className="font-size-xl mb-1 font-weight-bold">
                            Payment details
                          </h5>
                        </Grid>

                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            spacing={2}
                          >
                            <Grid item xs={12}>
                              <Elements stripe={stripePromise}>
                                <PaymentForm
                                  createToken={createToken}
                                  setResetToken={setResetToken}
                                  autoFocus
                                />
                              </Elements>
                              <Typography
                                style={{
                                  color: 'red',
                                  fontSize: 13,
                                  marginTop: 3,
                                  marginLeft: 12,
                                  fontWeight: 400,
                                }}
                                id="card-errors"
                                role="alert"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Container>
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#f8f9fe' }}>
            <Button
              className="btn-primary font-weight-bold"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className="btn-primary font-weight-bold"
              disabled={loader}
              type="submit"
            >
              Finish
            </Button>
          </DialogActions>
        </BlockUi>
      </ValidatorForm>
    </Dialog>
  )
}

export default StripeOneTimePurchase
