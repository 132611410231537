/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2024-10-29 19:59:39
 * @ Description: Google map component that displays passed down properties and comps
 */

import React, { useState } from 'react'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import head from 'lodash/head'
import last from 'lodash/last'
import filter from 'lodash/filter'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

import {
  Marker,
  GoogleMap,
  StreetViewPanorama,
  withGoogleMap,
  withScriptjs,
  Polyline,
  Polygon,
  InfoWindow,
} from 'react-google-maps'
import CustomMapMarker from '../CustomMapMarker'
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer'
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import useAffiliate from '@/hooks/useAffiliate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { currencyFormat, numberFormat } from '@/utils/number'

const readRealeflowDateString = (date) => {
  if (!date) return ''
  const year = date.substr(0, 4)
  const month = date.substr(4, 2)
  const day = date.substr(6, 2)
  return `${year}-${month}-${day}`
}

const GoogleMapWithMarkers = withScriptjs(
  withGoogleMap((props) => {
    const {
      openComp,
      path = [],
      setPath,
      drawingCallback,
      customIcon,
    } = props
    const bounds = new window.google.maps.LatLngBounds()

    const [{ affiliate, affiliateLoading, urlAffiliate }] =
      useAffiliate({})

    var icon = {
      url: customIcon
        ? customIcon
        : urlAffiliate
        ? get(affiliate, 'sidebar.sidebarMemberSmallImage.0.url') ||
          '/images/SubjectProertyIcon.svg'
        : '/images/SubjectProertyIcon.svg', // url
      scaledSize: new window.google.maps.Size(50, 50), // scaled size
      origin: new window.google.maps.Point(0, 0), // origin
      anchor: new window.google.maps.Point(25, 25), // anchor
    }
    const [showInfoWindow, setShowInfoWindow] = useState(null)
    const [editClick, setEditClick] = useState(null)
    const [drawingMode, setDrawingMode] = useState(false)
    const [editMode, setEditMode] = useState(false)
    // const [path, setPath] = useState([]);
    const [compWindow, setCompWindow] = useState(null)

    const [boundsSet, setBoundsSet] = useState(false)

    const polygon = new window.google.maps.Polygon({
      paths: path,
    })

    const handleMapClick = (e) => {
      setDrawingMode(!drawingMode)
      if (drawingMode && drawingCallback) {
        // if currently true it is being set to false
        drawingCallback(polygon)
      }
    }

    const handleMapMove = (e) => {
      const lat = e.latLng.lat()
      const lng = e.latLng.lng()
      const newPath = [...path, { lat, lng }]
      setPath(newPath)
    }

    const [updateProperty] = useMutation(UPDATE_PROPERTY)

    if (affiliateLoading)
      return (
        <Backdrop open={true} style={{ color: '#fff', zIndex: 2000 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )

    return (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={props.start}
        onClick={(event) => {
          setShowInfoWindow(null)
          if (props.propertyPage) setEditClick(event.latLng)
          if (props.setPath) {
            // setEditClick(event.latLng)
            handleMapMove(event)
            handleMapClick(event)
          }
        }}
        onMouseOut={(event) => {
          setDrawingMode(false)
          if (drawingMode && drawingCallback) drawingCallback(polygon)
        }}
        onMouseMove={(event) => {
          if (drawingMode) {
            handleMapMove(event)
          }
        }}
        onIdle={(event) => {
          setDrawingMode(false)
          if (drawingMode && drawingCallback) drawingCallback(polygon)
        }}
        options={{
          gestureHandling: 'cooperative',
          fullscreenControl: !setPath,
        }}
        style={{ position: 'relative' }}
        ref={(map) => {
          if (
            map &&
            (props.properties.length > 1 || !isEmpty(props.comps)) &&
            !showInfoWindow &&
            !boundsSet
          ) {
            map.fitBounds(bounds, 10)
            setBoundsSet(true)
          }
        }}
      >
        <StreetViewPanorama
          defaultPosition={props.start}
          visible={props.streetView}
          onCloseClick={
            props.setView ? () => props.setView('Map') : null
          }
          pov={{
            heading: props.heading ? props.heading : 0,
            pitch: 0,
          }}
        />
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={10}
        >
          {map(props.properties, (property) => {
            const { location = {} } = property
            const position = {
              lat: Number(location.latitude),
              lng: Number(location.longitude),
            }
            bounds.extend(new window.google.maps.LatLng(position))
            return (
              <CustomMapMarker
                icon={icon}
                position={position}
                property={property}
                propertyPage={props.propertyPage}
                selected={props.selected}
                selectProperty={props.selectProperty}
                portfolios={props.portfolios}
                marketplace={props.marketplace}
                shared={props.shared}
                public={props.public}
                noInfoWindow={props.noInfoWindow}
                showInfoWindow={showInfoWindow}
                setShowInfoWindow={setShowInfoWindow}
              />
            )
          })}
        </MarkerClusterer>
        {editClick && head(props.properties) && (
          <Marker position={editClick} />
        )}
        {editClick && head(props.properties) && props.propertyPage && (
          <Button
            variant="contained"
            color="primary"
            style={{ position: 'absolute', left: 5, bottom: 10 }}
            onClick={() => {
              updateProperty({
                variables: {
                  id: head(props.properties)._id,
                  propertyInput: {
                    location: {
                      latitude: editClick.lat(),
                      longitude: editClick.lng(),
                    },
                  },
                },
                refetchQueries: [
                  {
                    query: GET_PROPERTY,
                    variables: { id: head(props.properties)._id },
                  },
                ],
              }).then(() => {
                setEditClick(null)
              })
            }}
          >
            Save new location
          </Button>
        )}
        {!isEmpty(path) && (
          <Polyline
            path={path}
            onClick={(event) => handleMapClick(event)}
            options={{
              strokeColor: '#FF0000',
              editable: editMode,
              draggable: true,
              geodesic: true,
            }}
          />
        )}
        {!isEmpty(path) && (
          <Polyline
            path={[head(path), last(path)]}
            onClick={(event) => handleMapClick(event)}
            options={{
              strokeColor: '#FF0000',
              // editable: editMode,
              // draggable: true,
              geodesic: true,
            }}
          />
        )}
        {/* {!isEmpty(path) && (
          <Polygon path={path} onClick={event => handleMapClick(event)} options={{ strokeColor: '#FF0000', editable: editMode, draggable: true, geodesic: true, }} />
        )} */}
        {!isEmpty(path) && (
          <Button
            variant="contained"
            color="primary"
            style={{ position: 'absolute', left: 5, bottom: 10 }}
            onClick={() => {
              setEditClick(null)
              setPath([])
            }}
          >
            Clear Line
          </Button>
        )}
        {/* {!isEmpty(path) && (
          <Button
            variant="contained"
            color="primary"
            style={{ position: 'absolute', left: 120, bottom: 10 }}
            onClick={() => {
              setEditMode(!editMode)
            }}
          >
            Edit Mode
          </Button>
        )} */}
        {drawingMode && (
          <Button
            variant="contained"
            style={{ position: 'absolute', right: 60, top: 10 }}
          >
            Drawing - Click Mouse to Stop
          </Button>
        )}
        {!isEmpty(props.comps) &&
          map(props.comps, (compObj, index) => {
            let comp = cloneDeep(compObj)
            const loc = {
              //Conditionals used for realeflow comp data
              latitude: comp.location?.latitude || comp.location?.lat,
              longitude:
                comp?.location?.longitude || comp?.location?.lon,
            }
            const point = new window.google.maps.LatLng({
              lat: Number(loc.latitude),
              lng: Number(loc.longitude),
            })

            const isInside = window.google.maps.geometry
              ? window.google.maps.geometry.poly.containsLocation(
                  point,
                  polygon,
                )
              : false

            comp.indexTest = (index + 1).toString()
            bounds.extend(point)
            return (
              <Marker
                position={{
                  lat: Number(loc.latitude),
                  lng: Number(loc.longitude),
                }}
                label={{
                  text: (index + 1).toString(),
                  color: 'white',
                  fontSize: '20px',
                }}
                icon={{
                  url: '/images/compsBorder.svg',
                  scaledSize: new window.google.maps.Size(50, 50),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(25, 25),
                }}
                key={Math.random()}
                onClick={
                  props.compWindow
                    ? () => setCompWindow(comp?._id)
                    : () => openComp(comp)
                }
              >
                {compWindow === comp?._id && props.compWindow && (
                  <InfoWindow
                    style={{ padding: 10 }}
                    open={showInfoWindow === comp?._id}
                    onClick={(event) => event.stopPropagation()}
                    onCloseClick={() => setCompWindow(null)}
                    // options={{
                    //   pixelOffset: new window.google.maps.Size(205, 200),
                    // }}
                  >
                    <Grid container direction={'column'}>
                      <Grid
                        container
                        item
                        direction={'row'}
                        justifyContent={'space-between'}
                      >
                        <Grid item>
                          <Typography>
                            {comp?.address_number +
                              ' ' +
                              comp?.address_street +
                              ' ' +
                              (comp?.address_suffix || '')}
                          </Typography>
                          <Typography>
                            {comp?.address_city},{' '}
                            {comp?.address_state}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => {
                              window.open(
                                `https://www.google.com/search?q=${
                                  comp?.address_number +
                                  ' ' +
                                  comp?.address_street +
                                  ' ' +
                                  (comp?.address_suffix || '')
                                }`,
                                '_blank',
                                // 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
                              )
                            }}
                          >
                            <FontAwesomeIcon
                              icon={['far', 'magnifying-glass']}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid container item direction={'row'}>
                        <Grid item>
                          {numberFormat.format(
                            comp?.sum_building_sq_ft,
                          )}{' '}
                          sqft * {comp?.bedrooms} bed *{' '}
                          {comp?.bath_full} baths *{' '}
                          {comp?.LookupCodes?.PropertyType}
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid
                        container
                        item
                        direction={'row'}
                        spacing={1}
                      >
                        <Grid item>
                          <b>
                            {currencyFormat.format(
                              comp?.status_info?.price_sold,
                            )}
                          </b>
                        </Grid>
                        <Grid item>
                          {readRealeflowDateString(
                            comp?.status_info?.date_sold,
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </InfoWindow>
                )}
              </Marker>
            )
          })}
        {!isEmpty(props.potentialComps) &&
          map(props.potentialComps, (comp, index) => {
            const loc = comp.location
            const indexTest = (index + 1).toString() // Keep it as a separate variable
            bounds.extend(
              new window.google.maps.LatLng({
                lat: Number(loc.latitude),
                lng: Number(loc.longitude),
              }),
            )
            return (
              <Marker
                position={{
                  lat: Number(loc.latitude),
                  lng: Number(loc.longitude),
                }}
                label={{
                  text: (index + 1).toString(),
                  color: 'white',
                  fontSize: '20px',
                }}
                icon={{
                  url: '/images/potentialCompsBorder.svg',
                  scaledSize: new window.google.maps.Size(50, 50),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(25, 25),
                }}
                key={Math.random()}
                onClick={() => openComp(comp)}
              ></Marker>
            )
          })}
      </GoogleMap>
    )
  }),
)

export default function PropertiesMapComponent(props) {
  const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY
  const [heading, setHeading] = useState()

  const { comps = [], potentialComps = [] } = props

  //const [streetView, setStreetView] = useState(false)
  const { latitude = 41.850033, longitude = -87.6500523 } = !isEmpty(
    props.properties,
  )
    ? props.properties[0].location || {}
    : {}

  var service = window.google
    ? new window.google.maps.StreetViewService()
    : 0

  const start = { lat: Number(latitude), lng: Number(longitude) }
  if (service !== 0 && !heading) {
    service.getPanoramaByLocation(start, 50, function (panoData) {
      if (panoData !== null) {
        var ManLatLng = panoData.location.latLng
        setHeading(
          window.google.maps.geometry.spherical.computeHeading(
            ManLatLng,
            new window.google.maps.LatLng(
              Number(latitude),
              Number(longitude),
            ),
          ),
        )
      }
    })
  }

  return (
    <>
      <div
        className={props.className || 'w-100'}
        style={{
          height: props.height ? props.height : '525px',
          width: '100%',
        }}
      >
        {!props.hide && (
          <GoogleMapWithMarkers
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div style={{ height: '100%', width: '100%' }} />
            }
            mapElement={<div style={{ height: '100%' }} />}
            start={start}
            streetView={props.streetView}
            heading={heading}
            comps={comps}
            potentialComps={potentialComps}
            properties={filter(props.properties, (property) => {
              return !isEmpty(property.location)
            })}
            openComp={props.openComp}
            propertyPage={props.propertyPage}
            path={props.path}
            setPath={props.setPath}
            drawingCallback={props.drawingCallback}
            shared={props.shared}
            public={props.public}
            noInfoWindow={props.noInfoWindow}
            compWindow={props.compWindow}
            customIcon={props.customIcon}
          />
        )}
      </div>
    </>
  )
}
