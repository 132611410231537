/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-01 15:08:58
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-27 11:19:43
 * @ Description: Property details view showing the comps found for this property
 */

import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cropper from 'react-easy-crop'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import meanBy from 'lodash/meanBy'
import filter from 'lodash/filter'
import remove from 'lodash/remove'
import toUpper from 'lodash/toUpper'
import toLower from 'lodash/toLower'
import sum from 'lodash/sum'
import padStart from 'lodash/padStart'
import head from 'lodash/head'
import toString from 'lodash/toString'
import replace from 'lodash/replace'
import intersection from 'lodash/intersection'
import includes from 'lodash/includes'
import isEqual from 'lodash/isEqual'
import pull from 'lodash/pull'
import ceil from 'lodash/ceil'
import slice from 'lodash/slice'
import intersectionBy from 'lodash/intersectionBy'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import startCase from 'lodash/startCase'
import reduce from 'lodash/reduce'
import forEach from 'lodash/forEach'
import uniqWith from 'lodash/uniqWith'
import orderBy from 'lodash/orderBy'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import isString from 'lodash/isString'
import every from 'lodash/every'
import split from 'lodash/split'
import kebabCase from 'lodash/kebabCase'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import set from 'lodash/set'
import round from 'lodash/round'

import Slider1 from 'react-slick'

import CompsCard from '../CompsCard'
import CountUp from 'react-countup'
import FindComps from '../../../Comps/FindComps'

import Script from 'react-load-script'
import loadImage from 'blueimp-load-image'

import { useDropzone } from 'react-dropzone'

import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  IconButton,
  ImageListItemBar,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Popover,
  Typography,
  FormControl,
  InputLabel,
  TableSortLabel,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone'
import CheckIcon from '@mui/icons-material/Check'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import CompareIcon from '@mui/icons-material/Compare'
import EditIcon from '@mui/icons-material/Edit'
import HomeIcon from '@mui/icons-material/Home'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import ListIcon from '@mui/icons-material/List'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  GET_COMPPROPERTIES,
  GET_PROPERTY,
} from '@/housefolios-components/PropertyContainer/queries'
import {
  ApolloConsumer,
  useLazyQuery,
  useMutation,
} from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  ADD_COMPPROPERTY,
  ADD_COMPPROPERTIES,
  IMAGE_UPLOAD,
  IMAGE_UPLOAD_MULTI,
} from '@/housefolios-components/TitleButtons/AddPropertyButton/mutations'
import { NumericFormat } from 'react-number-format'
import { enqueueSnackbar } from 'notistack'
import {
  DELETE_COMP,
  DELETE_IMAGE_MULTI,
  UPDATE_COMPPROPERTY,
  UPDATE_COMPPROPERTIES,
  SAVE_ANALYSIS,
  DELETE_IMAGE,
  DELETE_COMP_MULTI,
} from '@/housefolios-components/PropertyContainer/mutations'

import Gallery from 'react-photo-gallery'
import { arrayMoveImmutable } from 'array-move'
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc'

import Map from '../../../Properties/Map'
import GoogleMapReact from 'google-map-react'
import { PacmanLoader } from 'react-spinners'
import BlockUi from 'react-block-ui'
import {
  CheckBox,
  CheckBoxOutlineBlank,
  CloseOutlined,
  DeleteTwoTone,
  DragIndicator,
  GridOnTwoTone,
  KeyboardArrowDownTwoTone,
  PollTwoTone,
  RoomTwoTone,
} from '@mui/icons-material'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import clsx from 'clsx'
import Alert from '@mui/material/Alert'
import DeleteComps from '@/housefolios-components/Comps/DeleteComps'
import MyPropertiesFilter from '@/layout-components/HeaderDrawer'
import CompsList from '../CompsList'
import Pagination from '@mui/material/Pagination'
import { GET_ZIP_VALUE } from '@/housefolios-components/TitleButtons/AddPropertyButton/queries'
import { GET_STOCKPILE } from '@/housefolios-components/Marketplace/queries'
import { STATES } from '@/constants/states'
import Autocomplete from '@mui/material/Autocomplete'
import { useStopwatch } from 'react-timer-hook'
import AllPropertiesFiltered from '@/housefolios-components/Properties/AllPropertiesFiltered'
import { CALL_REAPI } from '@/housefolios-components/PropertySearch/queries'
import ImageCropper from '@/housefolios-components/Dialogs/ImageCropper'
import withSession from '@/housefolios-components/Session/withSession'
import { handlePlaceResult } from '@/utils/handlePlaceResult'
import { getZipValues } from '@/utils/addProperty'
import { GET_REALEFLOW_COMPS } from '@/housefolios-components/Comps/queries'
import useSorting from '@/hooks/useSorting'
import { statesShort } from '@/utils/states'

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
}

const hostname = import.meta.env.VITE_HOSTNAME
const apiKey = import.meta.env.VITE_HOUSEFOLIOS_APIKEY
const apiID = import.meta.env.VITE_HOUSEFOLIOS_APIID
const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY

let sortingChoices = [
  {
    title: 'Price - low to high',
    textAlign: 'left',
    name: 'estval-asc',
  },
  {
    title: 'Price - high to low',
    textAlign: 'left',
    name: 'estval-desc',
  },
  {
    title: 'SqFt - low to high',
    name: 'sqft-asc',
  },
  {
    title: 'SqFt - high to low',
    name: 'sqft-desc',
  },
  {
    title: 'Year - low to high',
    name: 'year-asc',
  },
  {
    title: 'Year - high to low',
    name: 'year-desc',
  },
]

//Sorting function copied from properties/marketplace page. Removed marketplaceOrderBy.
function desc(a, b, orderBy) {
  if (isString(get(a, orderBy)) && isString(get(b, orderBy))) {
    if (!get(b, orderBy) && !get(a, orderBy)) {
      return 0
    }
    if (!get(b, orderBy)) {
      return 1
    }
    if (!get(a, orderBy)) {
      return -1
    }
    if (
      every(get(a, orderBy), (c) => c <= '9' && c >= '0') &&
      every(get(b, orderBy), (c) => c <= '9' && c >= '0')
    ) {
      if (Number(get(b, orderBy)) < Number(get(a, orderBy))) {
        return -1
      }
      if (Number(get(b, orderBy)) > Number(get(a, orderBy))) {
        return 1
      }
      return 0
    } else {
      if (
        get(b, orderBy).toLowerCase() < get(a, orderBy).toLowerCase()
      ) {
        return -1
      }
      if (
        get(b, orderBy).toLowerCase() > get(a, orderBy).toLowerCase()
      ) {
        return 1
      }
      return 0
    }
  } else {
    if (isNil(get(b, orderBy)) && isNil(get(a, orderBy))) {
      return 0
    }
    if (isNil(get(b, orderBy))) {
      return 1
    }
    if (isNil(get(a, orderBy))) {
      return -1
    }
    if (get(b, orderBy) < get(a, orderBy)) {
      return -1
    }
    if (get(b, orderBy) > get(a, orderBy)) {
      return 1
    }
    return 0
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

function SliderArrowNext(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  )
}

function SliderArrowPrev(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  )
}

const REHAB_LEVELS = [
  {
    value: 'Fully Rehabbed',
    label: 'Fully Rehabbed',
  },
  {
    value: 'Partially Rehabbed',
    label: 'Partially Rehabbed',
  },
  {
    value: 'No Rehab Needed',
    label: 'No Rehab Needed',
  },
  {
    value: 'Needs Partial Rehab',
    label: 'Needs Partial Rehab',
  },
  {
    value: 'Needs Major Rehab',
    label: 'Needs Major Rehab',
  },
]

const realeflowStatusValues = [
  {
    value: 'S',
    label: 'Sold',
  },
  {
    value: 'A',
    label: 'Avaliable',
  },
  {
    value: 'P',
    label: 'Pending',
  },
]

const useStyles = makeStyles((theme) => ({
  sortableHelper: {
    zIndex: 5000,
  },
  compareIcon: {
    fontSize: 23,
  },
}))

function CenterPageComps(props) {
  const { property = {}, comps, compValues, organization } = props

  const classes = useStyles()

  const hiddenUp = useMediaQuery((theme) =>
    theme.breakpoints.up('md'),
  )

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const [activeTab, setActiveTab] = useState(
    localStorage.getItem('comp-view')
      ? localStorage.getItem('comp-view')
      : props.session.me.activeOrg.isRealeflow
        ? '2'
        : '1',
  )

  const [alignment, setAlignment] = useState('actual')

  const toggleAlignment = (event) => {
    setAlignment(event.target?.value)
  }

  const [compMenu, setCompMenu] = useState(null)
  const [findCompMenu, setFindCompMenu] = useState(null)
  const [realeflowCompFilters, setRealeflowCompFilters] = useState({
    beds: {
      min: Number(property.beds) - 1,
      max: Number(property.beds) + 1,
    },
    baths: {
      min: Number(property.baths) - 1,
      max: Number(property.baths) + 1,
    },
    distance: 0.5,
    squareFeet: {
      min: Number(property.sqft) - 250,
      max: Number(property.sqft) + 250,
    },
    yearBuilt: {
      min: Number(property.year) - 5,
      max: Number(property.year) + 5,
    },
    lastSoldDays: 180,
    propertyType: 'SINGLE_FAMILY_RESIDENTIAL',
    status: [
      {
        value: 'S',
        label: 'Sold',
      },
    ],
  })
  const [realeflowCompMenus, setRealeflowCompMenus] = useState({})
  const handleMenuOpen = (event) => {
    setCompMenu(event.currentTarget)
  }

  const handleMenuClose = (event) => {
    setCompMenu(null)
  }

  const toggleRealeflowCompFilters = (name) => (event) => {
    let newMenus = { ...realeflowCompMenus }
    if (newMenus[name]) {
      newMenus[name] = null
    } else {
      newMenus[name] = event.currentTarget
    }
    setRealeflowCompMenus(newMenus)
  }

  const [realeflowIncludedIds, setRealeflowIncludedIds] = useState([])
  const [realeflowExcludedIds, setRealeflowExcludedIds] = useState([])
  const [realeflowPath, setRealeflowPath] = useState([])

  const [openSearchWarning, setOpenSearchWarning] = useState(false)
  const [openNoCompWarning, setOpenNoCompWarning] = useState(false)
  const [openDuplicateWarning, setOpenDuplicateWarning] =
    useState(false)
  const [duplicateComps, setDuplicateComps] = useState([])

  const [realeflowOrder, { handleRequestSort }] = useSorting({
    defaultOrder: 'desc',
  })

  const visuallyHidden = {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }

  const readRealeflowDateString = (date) => {
    if (!date) return ''
    const year = date.substr(0, 4)
    const month = date.substr(4, 2)
    const day = date.substr(6, 2)
    return `${year}-${month}-${day}`
  }

  const handleFindMenuOpen = (event) => {
    setFindCompMenu(event.currentTarget)
    setRealeflowPath([])
    setRealeflowIncludedIds([])
    setRealeflowExcludedIds([])
    triggerRealeflowSearch()
  }

  const handleFindMenuClose = (event) => {
    setFindCompMenu(null)
    setRealeflowPath([])
    setRealeflowIncludedIds([])
    setRealeflowExcludedIds([])
    setLoader(false)
  }

  const onChangeRealeflowFilter = (event) => {
    const { name, value, type } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value)
    let newFilters = cloneDeep(realeflowCompFilters)
    set(newFilters, name, newValue)
    setRealeflowCompFilters(newFilters)
  }

  const toggleIncludeComp = (compId) => {
    let newIds = [...realeflowIncludedIds]
    if (includes(newIds, compId)) pull(newIds, compId)
    else newIds.push(compId)
    setRealeflowIncludedIds(newIds)
  }

  const toggleIncludeAllComp = () => {
    const filteredComps = filter(
      results?.data?.getRealeflowCompData,
      (data) => {
        const { property } = data
        return !includes(realeflowExcludedIds, property?._id)
      },
    )
    if (filteredComps.length > realeflowIncludedIds.length)
      setRealeflowIncludedIds(
        map(filteredComps, (comp) => comp?.property?._id),
      )
    else setRealeflowIncludedIds([])
  }

  const toggleExcludeComp = (compId) => {
    let newIds = [...realeflowExcludedIds]
    if (includes(newIds, compId)) pull(newIds, compId)
    else newIds.push(compId)
    setRealeflowExcludedIds(newIds)
  }

  const [getRealeflowCompData, results] = useLazyQuery(
    GET_REALEFLOW_COMPS,
  )

  const triggerRealeflowSearch = () => {
    setRealeflowIncludedIds([])
    setRealeflowExcludedIds([])
    getRealeflowCompData({
      variables: {
        propertyId: property._id,
        filters: {
          ...realeflowCompFilters,
          status: map(
            realeflowCompFilters?.status,
            (selected) => selected?.value,
          ),
          polygon: realeflowPath,
          // includedIds: realeflowIncludedIds,
          // excludedIds: realeflowExcludedIds,
        },
      },
    })
  }

  const triggerRealeflowSearchReset = () => {
    setRealeflowIncludedIds([])
    setRealeflowExcludedIds([])
  }

  const [selectedEditImage, setSelectedEditImage] = useState({})
  const [replaceImage, setReplaceImage] = useState(false)

  const [editImageMoreVert, setEditImageMoreVert] = useState(null)
  const handleEditImageMoreVertOpen = (event, image) => {
    setEditImageMoreVert(event.currentTarget)
    setSelectedEditImage(image)
  }

  const handleEditImageMoreVertClose = (event) => {
    setEditImageMoreVert(null)
    setSelectedEditImage({})
  }

  const [openEditImage, setOpenEditImage] = useState(false)
  const handleOpenEditImage = (image) => {
    setOpenEditImage(true)
    setSelectedEditImage(image)
  }
  const handleCloseEditImage = () => {
    setOpenEditImage(false)
    // handleEditImageMoreVertClose()
  }

  const saveCroppedImage = async (croppedImage, replaceImage) => {
    setLoader(true)
    try {
      const imageId = get(selectedEditImage, `_id`)
      let newSelectedImages = selectedImages
      if (edit) {
        await imageUpload({
          variables: {
            documentId: compPropertyInput.mainProperty,
            imageUrls: [croppedImage],
            collection: 'compProperty',
          },
          refetchQueries: [
            {
              query: GET_COMPPROPERTIES,
              variables: { propertyId: property._id },
            },
            {
              query: GET_PROPERTY,
              variables: { id: property._id },
            },
          ],
        })
      } else {
        newSelectedImages = concat(newSelectedImages, [croppedImage])
        setSelectedImages(newSelectedImages)
      }
      if (replaceImage) {
        if (imageId) {
          let newImages = [...images]
          remove(newImages, (image) => image._id === imageId)
          await deleteFile({
            refetchQueries: [
              {
                query: GET_COMPPROPERTIES,
                variables: { propertyId: property._id },
              },
              {
                query: GET_PROPERTY,
                variables: { id: property._id },
              },
            ],
            variables: {
              _id: imageId,
            },
          })
          // setImages(newImages)
        } else {
          pull(newSelectedImages, selectedEditImage)
          setSelectedImages(newSelectedImages)
        }
      }
      enqueueSnackbar(`Image edited`, {
        variant: 'success',
        autoHideDuration: 3000,
      })
    } catch (e) {
      console.error(e)
    }
    handleCloseEditImage()
    handleEditImageMoreVertClose()
    setLoader(false)
  }

  //const [comps, setComps] = useState('soldComps');
  // const handleChange = event => {
  //   setComps(event.target.value);
  // };

  const [openAddCompAddress, setOpenAddCompAddress] = useState(false)
  const handleOpenAddCompAddress = () => {
    setOpenAddCompAddress(true)
    handleMenuClose()
  }
  const handleCloseAddCompAddress = () => {
    setOpenAddCompAddress(false)
    setLoader(false)
    pause()
  }

  const [openVerifyComp, setOpenVerifyComp] = useState(false)
  const handleOpenVerifyComp = () => {
    setOpenVerifyComp(true)
    handleMenuClose()
  }
  const handleCloseVerifyComp = () => {
    setOpenVerifyComp(false)
    setLoader(false)
    pause()
  }

  const [openFixComp, setOpenFixComp] = useState(false)
  const handleOpenFixComp = () => {
    setOpenFixComp(true)
    handleMenuClose()
  }
  const handleCloseFixComp = () => {
    setOpenFixComp(false)
    setLoader(false)
    pause()
  }

  const [loader, setLoader] = useState(false)

  const { seconds, minutes, pause, reset } = useStopwatch({
    autoStart: false,
  })

  const [openAddComp, setOpenAddComp] = useState(false)
  const handleOpenAddComp = () => {
    setOpenAddComp(true)
  }
  const handleCloseAddComp = () => {
    setOpenAddComp(false)
    setLoader(false)
    setAnchorElActionsMenu(null)
    pause()
  }

  const [placeResult, setPlaceResult] = useState(null)

  const [ordering, setOrdering] = useState('')

  const [orderByTarget = 'saleDate', order = 'desc'] = split(
    ordering || 'saleDate-desc',
    '-',
  )

  const [compPropertyInput, setCompPropertyInput] = useState({})
  const [selectedImages, setSelectedImages] = useState([])
  const [images, setImages] = useState([])
  const [edit, setEdit] = useState(false)

  const [items, setItems] = React.useState(
    map(concat(selectedImages, images), (image) => ({
      id: image._id,
      src: image.url,
      url_small: image.url_small,
      width: 4,
      height: 3,
    })),
  )

  const ref = useRef(null)
  const [minHeight, setMinHeight] = React.useState(0)

  const [openManageImages, setOpenManageImages] = useState(false)

  const [openDeleteImage, setOpenDeleteImage] = useState(false)
  const [selectedImageDelete, setSelectedImageDelete] = useState(null)

  const toggleOpenDeleteImage =
    (_id = null) =>
    () => {
      setOpenDeleteImage(!openDeleteImage)
      setSelectedImageDelete(_id)
    }

  const toggleOpenManageImages = () => {
    setOpenManageImages(!openManageImages)
    setMinHeight(0)
  }

  const [menu, setMenu] = useState('')
  const [anchorElDefinition, setAnchorElDefinition] = useState(null)
  const [menuName, setMenuName] = useState(null)
  const [menuFormat, setMenuFormat] = useState(null)
  const [propertyData, setPropertyData] = useState(null)
  const [showMenu, setShowMenu] = useState(true)
  const handleMenu = () => {
    setShowMenu(false)
  }

  const handleDefinition = (name, anchorEl, menuName, menuFormat) => {
    setMenu(name)
    setAnchorElDefinition(anchorEl)
    setMenuName(menuName)
    setMenuFormat(menuFormat)
  }

  const onSubmitDelete = async (event, deleteFiles) => {
    setLoader(true)
    const toggle = toggleOpenDeleteImage()
    let newImages = [...images]
    remove(newImages, (image) => image._id === selectedImageDelete)
    deleteFiles()
      .then(async ({ data }) => {
        setSelectedImageDelete(null)
        enqueueSnackbar(`Image Deleted`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        handleEditImageMoreVertClose()
        toggle()
        setImages(newImages)
        setLoader(false)
      })
      .catch((error) => {
        enqueueSnackbar(`Image failed to delete`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        toggle()
        setLoader(false)
      })
    event.preventDefault()
  }

  const searchForComp = async (client) => {
    setLoader(true)
    reset()
    let location = handlePlaceResult(placeResult, property)
    const { loc } = location

    const streetAddress = location.streetAddress

    let data
    let error
    // { data, error } = await client.query({
    //   query: GET_ZILLOW,
    //   variables: {
    //     addressInput: {
    //       address: streetAddress.replace('#', '%23').replace('&', '%26'),
    //       city: location.locality,
    //       state: location.administrative_area_level_1,
    //       zip: location.postal_code,
    //     },
    //   },
    //   errorPolicy: 'ignore',
    // })

    const {
      data: { callREAPI },
    } = await client.query({
      query: CALL_REAPI,
      variables: {
        REAPIInput: {
          single: true,
          formattedAddress: placeResult.formatted_address,
        },
      },
    })
    let result = callREAPI || []

    // data = {
    //   apiKey: apiKey,
    //   orgID: apiID,
    //   action: 'single_comp',
    //   type: 'Single Family',
    //   env: hostname === 'localhost' || hostname === 'dev-admin.housefolios.com' ? 'dev' : 'live' ,
    //   address: toUpper(streetAddress.replace('#', '%23').replace('&', '%26')),
    //   latitude: loc ? loc.lat : null,
    //   longitude: loc ? loc.lng : null,
    //   city: toLower(location.locality),
    //   zips: padStart(truncate(location.postal_code, {
    //     length: 5,
    //     omission: '',
    //   }), 5, '0'),
    //   state: location.administrative_area_level_1,
    // }

    // const str_json = JSON.stringify(data)
    // let response = { ok: false }
    // try {
    //   response = await fetch(
    //     hostname === 'localhost'
    //     ? 'http://data.housefolios.com/housefoliosdev-api.php'
    //     //? 'http://housefolios-data.test/housefoliosdev-api.php'
    //     : hostname === 'dev-admin.housefolios.com'
    //         ? 'https://data.housefolios.com/housefoliosdev-api.php'
    //         : 'https://data.housefolios.com/housefolios-api.php',
    //     {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: str_json,
    //     },
    //   )
    // } catch (error) {
    //   console.log(error)
    // }
    // let result = response.ok ? await response.json() : []
    let propertyData = reduce(
      result,
      function (result, object) {
        forEach(object, (value, key) => {
          let newValue = Number(value)
          if (isNaN(newValue)) newValue = value
          ;(result[key] || (result[key] = [])).push(newValue)
        })
        return result
      },
      {},
    )
    forEach(propertyData, (value, key) => {
      propertyData[key] = compact(
        uniqWith(
          propertyData[key],
          (obj, oth) => toLower(obj) === toLower(oth),
        ),
      )
    })

    const [zipValues] = await getZipValues(
      client,
      propertyData ? head(propertyData.zip) : location.postal_code,
    )

    // let propertyData = data
    //   ? data.getZillowPropertyData
    //   : null

    // if (propertyData) {
    //   if (propertyData.files === null)
    //     propertyData.files = { url: [] }
    // }

    let selectedImages = []
    try {
      if (propertyData) {
        if (!isEmpty(propertyData.images)) {
          let images = head(JSON.parse(propertyData.images))
          images = Object.values(images)
          selectedImages = images || []
        }
        if (propertyData.image)
          selectedImages.push(propertyData.image)
      }
      const stockpileCheck = await client.query({
        query: GET_STOCKPILE,
        variables: {
          addressInput: {
            address: propertyData
              ? head(propertyData.address)
              : streetAddress,
            city: propertyData
              ? head(propertyData.city)
              : location.locality,
            county: zipValues ? zipValues.county : '',
            state: propertyData
              ? head(propertyData.state)
              : location.administrative_area_level_1,
          },
        },
        errorPolicy: 'ignore',
      })
      const stockpileValues = stockpileCheck
        ? stockpileCheck.data
        : null
      const { stockedImages } = stockpileValues || {}
      if (stockedImages) {
        selectedImages = concat(
          selectedImages,
          compact([stockpileValues.stockedImages.first]),
          stockpileValues.stockedImages.url || [],
        )
        if (isEmpty(selectedImages))
          selectedImages = concat(
            selectedImages,
            compact([stockpileValues.stockedImages.streetView]),
            compact([stockpileValues.stockedImages.staticMap]),
          )
      }
      if (loc) {
        const MAP_KEY = googleApiKey
        const { lat, lng } = loc
        if (isEmpty(selectedImages)) {
          var service = window.google
            ? new window.google.maps.StreetViewService()
            : 0
          if (service !== 0) {
            await new Promise((resolve, reject) => {
              try {
                service.getPanoramaByLocation(
                  { lat: Number(lat), lng: Number(lng) },
                  50,
                  function (panoData) {
                    if (panoData !== null) {
                      var ManLatLng = panoData.location.latLng
                      const heading =
                        window.google.maps.geometry.spherical.computeHeading(
                          ManLatLng,
                          new window.google.maps.LatLng(
                            Number(lat),
                            Number(lng),
                          ),
                        )
                      selectedImages.push(
                        `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${lat},${lng}&heading=${heading}&key=${MAP_KEY}`,
                      )
                    }
                    resolve()
                  },
                )
              } catch (error) {
                console.log(error)
              }
            })
          }
          selectedImages.push(
            `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&size=400x400&markers=color:blue%7C${lat},${lng}&key=${MAP_KEY}`,
          )
        }
      }
    } catch (error) {
      console.log(error)
    }

    let saleDate = null
    if (propertyData) {
      let date = toString(
        head(
          orderBy(propertyData.soldDate, (value) => value, 'desc'),
        ),
      )
      date = replace(date, /-/g, '')
      if (date) {
        const year = Number(date.substring(0, 4))
        const month = Number(date.substring(4, 6))
        const day = Number(date.substring(6, 8))
        date = new Date(year, month - 1, day)
        saleDate = date
      }
    }

    setCompPropertyInput({
      mainProperty: property._id,
      address: propertyData
        ? head(propertyData.address)
        : streetAddress,
      city: propertyData
        ? head(propertyData.city)
        : location.locality,
      state: propertyData
        ? head(propertyData.state)
        : location.administrative_area_level_1,
      zip: propertyData
        ? head(propertyData.zip)
        : location.postal_code,
      beds: propertyData ? head(propertyData.beds) : '',
      baths: propertyData ? head(propertyData.baths) : '',
      sqft: propertyData ? head(propertyData.sqft) : '',
      year: propertyData ? head(propertyData.yearBuilt) : '',
      estval: propertyData
        ? Number(head(propertyData.soldPrice))
        : '',
      priceAdjustment: 0,
      daysOnMarket: 0,
      rehabLevel: '',
      propertyType: propertyData ? propertyData.propertyType : '',
      saleDate,
      location: {
        latitude: Number(loc.lat),
        longitude: Number(loc.lng),
      },
    })
    setPropertyData(propertyData)
    setLoader(false)
    pause()
    setSelectedImages(selectedImages)
    setImages([])
    setEdit(false)
    handleOpenVerifyComp()
    handleCloseAddCompAddress()
  }

  const [potential, setPotential] = useState(false)

  const openEdit = (comp) => {
    if (props.public || props.shared) return
    setCompPropertyInput({
      mainProperty: comp._id,
      address: comp.address,
      city: comp.city,
      state: comp.state,
      zip: comp.zip,
      beds: comp.beds,
      baths: comp.baths,
      sqft: comp.sqft,
      year: comp.year,
      estval: comp.estval,
      priceAdjustment: comp.priceAdjustment,
      daysOnMarket: comp.daysOnMarket,
      rehabLevel: comp.rehabLevel,
      propertyType: comp.propertyType,
      saleDate: comp.saleDate,
      indexTest: comp.indexTest,
      potential: comp.potential,
    })
    setPropertyData(null)
    setImages(comp.images || [])
    setSelectedImages([])
    setEdit(true)
    setPotential(comp.potential)
    handleOpenAddComp()
  }

  const reloadImages = () => {
    setSelectedImages([...selectedImages])
  }

  useEffect(() => {
    const comp = find(
      props.comps,
      (comp) => comp._id === compPropertyInput.mainProperty,
    )
    if (comp) {
      if (!isEqual(images, comp.images)) setImages(comp.images || [])
      setSelectedImages([])
    }
  }, [props.comps])

  const addImages = (files) => {
    let newSelectedImages = selectedImages
    newSelectedImages.push(files)
    setSelectedImages(newSelectedImages)
    reloadImages()
    enqueueSnackbar(`Image uploaded`, {
      variant: 'success',
      autoHideDuration: 3000,
    })
  }

  const {
    // acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: async (acceptedFiles) => {
      // setFiles(acceptedFiles.map(file => Object.assign(file, {
      //   preview: URL.createObjectURL(file)
      // })));

      if (edit) {
        await Promise.all(
          map(acceptedFiles, (file) => {
            const reader = new FileReader()
            return new Promise((resolve, reject) => {
              reader.onload = async () => {
                const image = reader.result
                await imageUpload({
                  variables: {
                    documentId: compPropertyInput.mainProperty,
                    imageUrls: [image],
                    collection: 'compProperty',
                  },
                  refetchQueries: [
                    {
                      query: GET_COMPPROPERTIES,
                      variables: { propertyId: property._id },
                    },
                    {
                      query: GET_PROPERTY,
                      variables: { id: property._id },
                    },
                  ],
                }).then(async ({ data }) => {
                  // enqueueSnackbar('Image Saved', {
                  //   variant: 'success',
                  //   autohideDuratation: 3000,
                  // })
                  // if (props.handleUpdateMarket) props.handleUpdateMarket()
                  resolve(image)
                })
              }
              reader.readAsDataURL(file)
            })
          }),
        )
        enqueueSnackbar(`Image uploaded`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
      } else {
        map(acceptedFiles, (file) => {
          loadImage(
            file,
            (img) => {
              addImages(img.toDataURL(file.type))
            },
            {
              orientation: true,
              canvas: true,
            },
          )
        })
      }
    },
  })

  // const files = acceptedFiles.map(file => (
  //   <ListItem className="font-size-sm px-3 py-2 text-primary d-flex justify-content-between align-items-center" key={file.path}>
  //     <span>{file.path}</span> <span className="badge badge-pill bg-neutral-warning text-warning">{file.size} bytes</span>
  //   </ListItem>
  // ));

  let autocomplete

  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    var options = {
      types: ['geocode'],
    } // To disable any eslint 'google not defined' error

    // Initialize Google Autocomplete
    /*global google*/ autocomplete =
      new google.maps.places.Autocomplete(
        document.getElementById('autocomplete'),
        options,
      )

    autocomplete.setFields([
      'address_component',
      'geometry',
      'formatted_address',
    ])
    autocomplete.addListener('place_changed', () => {
      setPlaceResult(
        autocomplete.getPlace().address_components
          ? autocomplete.getPlace()
          : null,
      )
    })
  }

  const handleCompChange = (event) => {
    const { name = '', value = '' } = event.target
    let newInput = { ...compPropertyInput }
    let newValue = value
    //if (type === 'number') newValue = Number(value)
    newInput[name] = newValue
    setCompPropertyInput(newInput)
  }

  const onChangeDate = (date) => {
    let newInput = { ...compPropertyInput }
    newInput['saleDate'] = date
    setCompPropertyInput(newInput)
  }

  const onChangeSlider = (event, value, name) => {
    let newInput = { ...compPropertyInput }
    newInput[name] = value
    setCompPropertyInput(newInput)
  }

  const onChangeDateMenu = (date) => {
    // Ensure date is properly formatted to remove any potential time zone issues
    let newDate = date instanceof Date ? date : new Date(date)

    if (!isNaN(newDate)) {
      const formattedDate = newDate
        .toISOString()
        .substring(0, 10)
        .replace(/-/g, '')
      const year = Number(formattedDate.substring(0, 4))
      const month = Number(formattedDate.substring(4, 6))
      const day = Number(formattedDate.substring(6, 8))
      newDate = new Date(year, month - 1, day)

      // Call the onChangeDate with the new date
      onChangeDate(newDate)
    }
  }

  const renderInput = (field) => {
    if (field.display) {
      return (
        <Grid
          item
          container
          xs={field.xs}
          sm={field.sm}
          md={field.md}
          lg={field.lg}
          xl={field.xl}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography>{field.label}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {field.type === 'dollar'
                ? currencyFormat.format(field.value) || '-'
                : field.value || '-'}
              {field.type === 'percentage' ? '%' : ''}
            </Typography>
          </Grid>
        </Grid>
      )
    } else if (field.type === 'date') {
      return (
        <Grid item xs={field.xs} sm={field.sm}>
          <DatePicker
            name={field.name}
            label={field.label}
            value={field.value ? new Date(field.value) : null} // Convert ISO string to Date object
            onChange={(date) => onChangeDate(date)}
            format="MM/dd/yyyy"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment:
                field.definition &&
                propertyData &&
                propertyData[field.definition] &&
                propertyData[field.definition].length > 1 ? (
                  <InputAdornment
                    position="end"
                    onClick={(event) =>
                      handleDefinition(
                        field.definition,
                        event.target,
                        field.name,
                        field.format,
                      )
                    }
                  >
                    <IconButton size="small" style={{ padding: 5 }}>
                      <FontAwesomeIcon
                        icon={['fal', 'database']}
                        className="font-size-xl"
                      />
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="dense"
                fullWidth
                style={{ borderColor: 'grey' }}
              />
            )}
            views={['year', 'month', 'day']}
            openTo="year"
          />
        </Grid>
      )
    } else if (field.type === 'select') {
      return (
        <Grid item xs={field.xs} sm={field.sm}>
          <Autocomplete
            id="autocomplete"
            value={field.value}
            options={field.options}
            onChange={(event, newValue) =>
              handleCompChange({
                target: { name: field.name, value: newValue },
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={field.label}
                variant="outlined"
              />
            )}
          />
        </Grid>
      )
    } else if (field.type === 'currency') {
      return (
        <Grid item xs={field.xs} sm={field.sm}>
          <NumericFormat
            customInput={TextField}
            thousandSeparator={true}
            prefix={'$'}
            fullWidth={true}
            //isNumericString={true}
            decimalScale={0}
            key={field.name}
            name={field.name}
            value={Math.round(field.value)}
            onValueChange={(values) => {
              const { value } = values
              const event = {
                target: {
                  name: field.name,
                  value: value,
                  type: 'number',
                },
                preventDefault: () => {
                  return null
                },
              }
              handleCompChange(event)
            }}
            type={'text'}
            onFocus={(event) => {
              event.target.select()
            }}
            variant="outlined"
            //margin='dense'
            label={field.label}
            InputProps={{
              endAdornment:
                field.definition &&
                propertyData &&
                propertyData[field.definition] &&
                propertyData[field.definition].length > 1 ? (
                  <InputAdornment
                    position="end"
                    onClick={(event) =>
                      handleDefinition(
                        field.definition,
                        event.target,
                        field.name,
                        field.format,
                      )
                    }
                  >
                    <IconButton size="small" style={{ padding: 5 }}>
                      <FontAwesomeIcon
                        icon={['fal', 'database']}
                        className="font-size-xl"
                      />
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />
        </Grid>
      )
    } else if (field.type === 'number') {
      return (
        <Grid item xs={field.xs} sm={field.sm}>
          <NumericFormat
            customInput={TextField}
            thousandSeparator={!field.noSeperator}
            fullWidth={true}
            //isNumericString={true}
            decimalScale={field.decimal || 0}
            key={field.name}
            name={field.name}
            value={field.value}
            allowNegative={false}
            onValueChange={(values) => {
              const { value } = values
              const event = {
                target: {
                  name: field.name,
                  value: value,
                  type: 'number',
                },
                preventDefault: () => {
                  return null
                },
              }
              handleCompChange(event)
            }}
            type={'text'}
            onFocus={(event) => {
              event.target.select()
            }}
            variant="outlined"
            //margin='dense'
            label={field.label}
            InputProps={{
              endAdornment:
                field.definition &&
                propertyData &&
                propertyData[field.definition] &&
                propertyData[field.definition].length > 1 ? (
                  <InputAdornment
                    position="end"
                    onClick={(event) =>
                      handleDefinition(
                        field.definition,
                        event.target,
                        field.name,
                        field.format,
                      )
                    }
                  >
                    <IconButton size="small" style={{ padding: 5 }}>
                      <FontAwesomeIcon
                        icon={['fal', 'database']}
                        className="font-size-xl"
                      />
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />
        </Grid>
      )
    } else {
      return (
        <Grid item xs={field.xs} sm={field.sm}>
          <TextField
            name={field.name}
            fullWidth={true}
            variant="outlined"
            // margin='dense'
            label={field.label}
            value={field.value}
            onChange={handleCompChange}
            InputProps={{
              endAdornment:
                field.definition &&
                propertyData &&
                propertyData[field.definition] &&
                propertyData[field.definition].length > 1 ? (
                  <InputAdornment
                    position="end"
                    onClick={(event) =>
                      handleDefinition(
                        field.definition,
                        event.target,
                        field.name,
                        field.format,
                      )
                    }
                  >
                    <IconButton size="small" style={{ padding: 5 }}>
                      <FontAwesomeIcon
                        icon={['fal', 'database']}
                        className="font-size-xl"
                      />
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />
        </Grid>
      )
    }
  }

  const [addComp] = useMutation(ADD_COMPPROPERTY)
  const [addComps] = useMutation(ADD_COMPPROPERTIES)
  const [updateComp] = useMutation(UPDATE_COMPPROPERTY)
  const [updateComps] = useMutation(UPDATE_COMPPROPERTIES)
  const [deleteComp] = useMutation(DELETE_COMP)
  const [deleteComps] = useMutation(DELETE_COMP_MULTI)
  const [imageUpload] = useMutation(IMAGE_UPLOAD)
  const [imageUploadMulti] = useMutation(IMAGE_UPLOAD_MULTI)
  const [saveAnalysis] = useMutation(SAVE_ANALYSIS)
  const [deleteFile] = useMutation(DELETE_IMAGE)
  const [openDeleteComp, setOpenDeleteComp] = useState(false)

  const imageUploader = (
    propertyImagePairs,
    collection,
    first = false,
  ) => {
    imageUploadMulti({
      variables: {
        propertyImagePairs,
        collection,
        first,
      },
      refetchQueries: [
        {
          query: GET_COMPPROPERTIES,
          variables: { propertyId: property._id },
        },
        {
          query: GET_PROPERTY,
          variables: { id: property._id },
        },
      ],
    })
  }

  const [anchorElActionsMenu, setAnchorElActionsMenu] = useState(null)
  const handleClickActionsMenu = (event) => {
    setAnchorElActionsMenu(event.currentTarget)
  }
  const handleCloseActionsMenu = () => {
    setAnchorElActionsMenu(null)
  }

  const toggleDeleteComp = () => {
    setOpenDeleteComp(!openDeleteComp)
  }

  const submitComp =
    (changePotential = false) =>
    () => {
      setLoader(true)
      if (edit) {
        updateComp({
          refetchQueries: [
            {
              query: GET_COMPPROPERTIES,
              variables: {
                propertyId: property._id,
              },
            },
            {
              query: GET_PROPERTY,
              variables: { id: property._id },
            },
          ],
          variables: {
            id: compPropertyInput.mainProperty, //edit had id stored as mainProperty
            compPropertyInput: {
              address: compPropertyInput.address,
              city: compPropertyInput.city,
              state: compPropertyInput.state,
              zip: padStart(compPropertyInput.zip, 5, '0'),
              beds: parseInt(compPropertyInput.beds),
              baths: parseFloat(compPropertyInput.baths),
              sqft: parseFloat(compPropertyInput.sqft),
              year: parseInt(compPropertyInput.year),
              estval: parseInt(compPropertyInput.estval),
              daysOnMarket: parseInt(compPropertyInput.daysOnMarket),
              rehabLevel: compPropertyInput.rehabLevel,
              propertyType: compPropertyInput.propertyType,
              saleDate: compPropertyInput.saleDate
                ? new Date(compPropertyInput.saleDate)
                : null,
              //rentalPrice: parseFloat(rentalPrice),
              priceAdjustment: parseFloat(
                compPropertyInput.priceAdjustment,
              ),
              potential: changePotential ? !potential : potential,
            },
          },
        })
          .then(async ({ data }) => {
            imageUpload({
              refetchQueries: [
                {
                  query: GET_COMPPROPERTIES,
                  variables: { propertyId: property._id },
                },
                {
                  query: GET_PROPERTY,
                  variables: { id: property._id },
                },
              ],
              variables: {
                documentId: compPropertyInput.mainProperty,
                imageUrls: selectedImages,
                collection: 'compProperty',
              },
            })

            enqueueSnackbar(`Comp updated successfully`, {
              variant: 'success',
              autoHideDuration: 3000,
            })
            handleCloseAddComp()
          })
          .catch((error) => {
            enqueueSnackbar(
              `We couldn't update your comp at this time`,
              {
                variant: 'error',
                autoHideDuration: 3000,
              },
            )
            handleCloseAddComp()
          })
      } else {
        addComp({
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_COMPPROPERTIES,
              variables: {
                propertyId: property._id,
              },
            },
            {
              query: GET_PROPERTY,
              variables: { id: property._id },
            },
          ],
          variables: {
            compPropertyInput: {
              mainProperty: compPropertyInput.mainProperty,
              address: compPropertyInput.address,
              city: compPropertyInput.city,
              state: compPropertyInput.state,
              zip: toString(padStart(compPropertyInput.zip, 5, '0')),
              beds: parseInt(compPropertyInput.beds),
              baths: parseFloat(compPropertyInput.baths),
              sqft: parseFloat(compPropertyInput.sqft),
              year: parseInt(compPropertyInput.year),
              estval: parseInt(compPropertyInput.estval),
              daysOnMarket: parseInt(compPropertyInput.daysOnMarket),
              rehabLevel: compPropertyInput.rehabLevel,
              propertyType: compPropertyInput.propertyType,
              saleDate: compPropertyInput.saleDate
                ? new Date(compPropertyInput.saleDate)
                : null,
              //rentalPrice: parseFloat(rentalPrice),
              priceAdjustment: parseFloat(
                compPropertyInput.priceAdjustment,
              ),
              location: compPropertyInput.location,
            },
          },
        })
          .then(async ({ data }) => {
            imageUpload({
              refetchQueries: [
                {
                  query: GET_COMPPROPERTIES,
                  variables: { propertyId: property._id },
                },
              ],
              variables: {
                documentId: data.addCompProperty._id,
                imageUrls: selectedImages,
                collection: 'compProperty',
              },
            })
            enqueueSnackbar(`Comp added successfully`, {
              variant: 'success',
              autoHideDuration: 3000,
            })

            handleCloseAddCompAddress()
            handleCloseVerifyComp()
            handleCloseFixComp()
            openEdit(data.addCompProperty)
          })
          .catch((error) => {
            enqueueSnackbar(
              `We couldn't add your comp at this time`,
              {
                variant: 'error',
                autoHideDuration: 3000,
              },
            )
            handleCloseAddCompAddress()
            handleCloseVerifyComp()
            handleCloseFixComp()
          })
      }
    }

  const onDelete = () => {
    deleteComp({
      refetchQueries: [
        {
          query: GET_COMPPROPERTIES,
          variables: {
            propertyId: property._id,
          },
        },
        {
          query: GET_PROPERTY,
          variables: { id: property._id },
        },
      ],
      variables: {
        id: compPropertyInput.mainProperty, //edit stores _id as mainProperty
      },
    })
      .then(async ({ data }) => {
        enqueueSnackbar(`Comp Deleted`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        handleCloseAddComp()
        toggleDeleteComp()
      })
      .catch((error) => {
        enqueueSnackbar(`Error Deleting Comp`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseAddComp()
        toggleDeleteComp()
      })
  }

  const checkForRealeflowDups = () => {
    const selectedAddressHashs = map(
      filter(results?.data?.getRealeflowCompData, (data) => {
        const { property } = data
        return includes(realeflowIncludedIds, property?._id)
      }),
      (comp) => comp?.property?.address_hash,
    )
    const duplicateIds = compact(
      map(
        filter(props.comps, (comp) => {
          return includes(selectedAddressHashs, comp?.addressHash)
        }),
        (comp) => comp._id,
      ),
    )
    if (isEmpty(realeflowIncludedIds)) {
      setOpenNoCompWarning(true)
    } else if (isEmpty(duplicateIds)) {
      importRealeflowComps()
    } else {
      setDuplicateComps(duplicateIds)
      setOpenDuplicateWarning(true)
    }
  }

  const deleteDuplicates = () => {
    deleteComps({
      variables: {
        ids: duplicateComps,
      },
    }).then(() => {
      setDuplicateComps([])
    })
  }

  const importRealeflowComps = () => {
    setLoader(true)
    const selectedComps = filter(
      results?.data?.getRealeflowCompData,
      (data) => {
        const { property } = data
        return includes(realeflowIncludedIds, property?._id)
      },
    )

    const mainPropertyId = property._id

    const compPropertyInputs = map(selectedComps, (comp) => {
      const { property } = comp
      const address =
        property?.mailing_std_full_street_address ||
        property?.address_number +
          ' ' +
          property?.address_direction_pre +
          ' ' +
          property?.address_street +
          ' ' +
          property?.address_direction_post +
          ' ' +
          (property?.address_suffix || '')
      return {
        mainProperty: mainPropertyId,
        address: address,
        city: property?.address_city,
        state: property?.address_state,
        zip: toString(padStart(property?.address_zip, 5, '0')),
        beds: parseInt(property?.bedrooms),
        baths: parseFloat(property?.bath_total_calc),
        sqft: parseFloat(property?.building_area),
        year: parseInt(property?.effective_year_built),
        estval: parseInt(property?.property_value),
        // daysOnMarket: parseInt(compPropertyInput.daysOnMarket),
        // rehabLevel: compPropertyInput.rehabLevel,
        propertyType: property?.LookupCodes?.PropertyType,
        saleDate: property?.status_info?.date_sold
          ? new Date(
              readRealeflowDateString(
                property?.status_info?.date_sold,
              ),
            )
          : null,
        //rentalPrice: parseFloat(rentalPrice),
        // priceAdjustment: parseFloat(
        //   0,
        // ),
        location: {
          latitude: property?.location?.lat,
          longitude: property?.location?.lon,
        },
        realeflowData: property,
        addressHash: property?.address_hash,
      }
    })
    addComps({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_COMPPROPERTIES,
          variables: {
            propertyId: property._id,
          },
        },
        {
          query: GET_PROPERTY,
          variables: { id: property._id },
        },
      ],
      variables: {
        compPropertyInputs,
      },
    })
      .then(async ({ data }) => {
        enqueueSnackbar(`Comps added successfully`, {
          variant: 'success',
          autoHideDuration: 3000,
        })

        const MAP_KEY = googleApiKey

        let propertyImagePairs = compact(
          await Promise.all(
            map(data?.addCompProperties, async (comp) => {
              const { latitude, longitude } = comp?.location
              let images = []
              if (latitude && longitude) {
                await new Promise((resolve, reject) => {
                  try {
                    var service = window.google
                      ? new window.google.maps.StreetViewService()
                      : 0
                    if (service !== 0) {
                      service.getPanoramaByLocation(
                        {
                          lat: Number(latitude),
                          lng: Number(longitude),
                        },
                        50,
                        function (panoData) {
                          if (panoData !== null) {
                            var ManLatLng = panoData.location.latLng
                            const heading =
                              window.google.maps.geometry.spherical.computeHeading(
                                ManLatLng,
                                new window.google.maps.LatLng(
                                  Number(latitude),
                                  Number(longitude),
                                ),
                              )
                            images.push(
                              `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${latitude},${longitude}&heading=${heading}&key=${MAP_KEY}`,
                            )
                          }
                          resolve()
                        },
                      )
                    } else resolve()
                  } catch (error) {
                    console.log(error)
                  }
                })
                images.push(
                  `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&size=400x400&markers=color:blue%7C${latitude},${longitude}&key=${MAP_KEY}`,
                )
              }

              if (!isEmpty(images)) {
                return {
                  propertyId: comp?._id,
                  imageUrls: images || [],
                }
              }
            }),
          ),
        )

        imageUploader(propertyImagePairs, 'compProperty', false)

        handleFindMenuClose()
      })
      .catch((error) => {
        enqueueSnackbar(`We couldn't add your comps at this time`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleFindMenuClose()
      })
  }

  function formatDate(date) {
    if (!date) return ''
    var today = new Date(date),
      d = new Date(
        today.getTime() + Math.abs(today.getTimezoneOffset() * 60000),
      ),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = '' + d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    while (year.length < 4) year = '0' + year

    return [year, month, day].join('-')
  }

  const realeflowTableHeaders = [
    { title: 'Address', name: 'property.address_number' },
    {
      title: 'Distance',
      name: 'distance',
      formatter: (x) => round(x, 2),
    },
    { title: 'Beds', name: 'property.bedrooms' },
    { title: 'Baths', name: 'property.bath_full' },
    {
      title: 'Sold Price',
      name: 'property.status_info.price_sold',
      formatter: (x) => currencyFormat.format(x),
    },
    {
      title: 'Sold Date',
      name: 'property.status_info.date_sold',
      formatter: (x) => formatDate(readRealeflowDateString(x)),
    },
    { title: 'Rent Est', name: 'property.rentEst' },
    { title: 'AVM', name: 'property.avm' },
    {
      title: 'SqFt',
      name: 'property.sum_building_sq_ft',
      formatter: (x) => {
        const difference = x - Number(property.sqft)
        return (
          <>
            <Typography>{numberFormat.format(x)}</Typography>
            <Typography>
              ({difference > 0 ? '+' : '-'}
              {Math.abs(difference)})
            </Typography>
          </>
        )
      },
    },
    { title: 'Year Built', name: 'property.effective_year_built' },
  ]

  const compAddressFields = [
    {
      type: 'text',
      name: 'address',
      label: 'Address',
      value: compPropertyInput.address,
      definition: 'address',
      xs: 12,
    },
    {
      type: 'text',
      name: 'city',
      label: 'City',
      value: compPropertyInput.city,
      definition: 'city',
      xs: 12,
    },
    {
      type: 'autocomplete',
      name: 'state',
      label: 'State',
      options: statesShort(),
      value: compPropertyInput.state,
      definition: 'state',
      xs: 6,
    },
    {
      type: 'number',
      name: 'zip',
      label: 'Zip',
      value: compPropertyInput.zip,
      definition: 'zip',
      noSeperator: true,
      xs: 6,
    },
  ]

  const compDetailFields = [
    {
      type: 'number',
      name: 'beds',
      label: 'Beds',
      value: compPropertyInput.beds,
      definition: 'beds',
      xs: 6,
    },
    {
      type: 'number',
      name: 'baths',
      label: 'Baths',
      value: compPropertyInput.baths,
      definition: 'baths',
      decimal: 2,
      xs: 6,
    },
    {
      type: 'number',
      name: 'sqft',
      label: 'Sqft',
      value: compPropertyInput.sqft,
      definition: 'sqft',
      format: numberFormat,
      xs: 6,
    },
    {
      type: 'number',
      name: 'year',
      label: 'Year',
      noSeperator: true,
      value: compPropertyInput.year,
      definition: 'yearBuilt',
      xs: 6,
    },
  ]

  const compFields = concat(compAddressFields, compDetailFields)

  const compFinancialFields = [
    {
      type: 'currency',
      name: 'estval',
      label: 'Sold Price',
      value: compPropertyInput.estval,
      definition: 'price',
      format: currencyFormat,
      xs: 12,
      sm: 6,
    },
    {
      type: 'date',
      name: 'saleDate',
      label: 'Date Sold',
      value: compPropertyInput.saleDate
        ? new Date(compPropertyInput.saleDate).toLocaleDateString(
            'en-US',
          )
        : null,
      definition: 'soldDate',
      xs: 12,
      sm: 6,
    },
    {
      type: 'number',
      name: 'daysOnMarket',
      label: 'Days on Market',
      value: compPropertyInput.daysOnMarket,
      xs: 12,
      sm: 6,
    },
    {
      type: 'select',
      name: 'rehabLevel',
      label: 'Rehab Level',
      value: compPropertyInput.rehabLevel,
      xs: 12,
      sm: 6,
      options: map(REHAB_LEVELS, (rehab) => rehab.value),
    },
    {
      type: 'text',
      name: 'propertyType',
      label: 'Property Type',
      value: compPropertyInput.propertyType,
      xs: 12,
      sm: 6,
    },
  ]

  const widgetsCarousels1B = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
  }

  const [selected, setSelected] = useState([])

  const [search, setSearch] = useState('')
  const [searchedComps, setSearchedComps] = useState(comps)

  const [filtered, setFiltered] = useState(false)
  const [filteredComps, setFilteredComps] = useState(comps)

  const [showMap, setShowMap] = useState(false)
  const [showAnalytics, setShowAnalytics] = useState(false)

  const [path, setPath] = useState([])
  const [openSelectedDrawing, setOpenSelectedDrawing] =
    useState(false)
  const [selectedDrawing, setSelectedDrawing] = useState([])

  const compDrawingCallback = (polygon) => {
    const excludedComps = filter(comps, (comp) => {
      const loc = comp.location || {}
      const point = new window.google.maps.LatLng({
        lat: Number(loc.latitude),
        lng: Number(loc.longitude),
      })

      const isInside = window.google.maps.geometry
        ? window.google.maps.geometry.poly.containsLocation(
            point,
            polygon,
          )
        : false

      return !isInside
    })
    setSelectedDrawing(map(excludedComps, (comp) => comp._id))
    setOpenSelectedDrawing(true)
  }

  const handleExcludedComps = (potential = false) => {
    setLoader(true)
    if (potential) {
      updateComps({
        variables: {
          ids: selectedDrawing,
          compPropertyInput: { potential },
        },
        refetchQueries: [
          {
            query: GET_COMPPROPERTIES,
            variables: { propertyId: property._id },
          },
        ],
      }).then(async ({ data }) => {
        setLoader(false)
        setSelectedDrawing([])
        setOpenSelectedDrawing(false)
      })
    } else {
      deleteComps({
        variables: {
          ids: selectedDrawing,
          compPropertyInput: { potential },
        },
        refetchQueries: [
          {
            query: GET_COMPPROPERTIES,
            variables: { propertyId: property._id },
          },
        ],
      }).then(async ({ data }) => {
        setLoader(false)
        setSelectedDrawing([])
        setOpenSelectedDrawing(false)
      })
    }
  }

  const [searchStatus3, setSearchStatus3] = useState(false)
  const toggleSearch3 = () => setSearchStatus3(!searchStatus3)

  const { page, setPage } = props
  const handleChange = (event, value) => {
    localStorage.setItem(
      `${property._id}-comps-actual-card-page`,
      value,
    )
    setPage(value)
  }

  const { potentialPage, setPotentialPage } = props
  const handlePotentialChange = (event, value) => {
    localStorage.setItem(
      `${property._id}-comps-potential-card-page`,
      value,
    )
    setPotentialPage(value)
  }

  const [bulkSetCompDialog, setBulkSetCompDialog] = useState(false)
  const [bulkSetPotentialDialog, setBulkSetPotentialDialog] =
    useState(false)

  const handleBulkDialog = (potential = false) => {
    setLoader(true)
    updateComps({
      variables: {
        ids: selected,
        compPropertyInput: { potential },
      },
      refetchQueries: [
        {
          query: GET_COMPPROPERTIES,
          variables: { propertyId: property._id },
        },
      ],
    }).then(async ({ data }) => {
      setLoader(false)
      setSelected([])
      closeBulk()
    })
  }

  const closeBulk = () => {
    setBulkSetCompDialog(false)
    setBulkSetPotentialDialog(false)
  }

  let shownComps =
    search && filtered
      ? intersectionBy(searchedComps, filteredComps, '_id')
      : search
        ? [...searchedComps]
        : filtered && filteredComps
          ? [...filteredComps]
          : [...comps] || []

  let unfilteredComps = cloneDeep(comps)
  let unfilteredPotentialComps = remove(
    unfilteredComps,
    (comp) => comp.potential,
  )

  let potentialComps = remove(shownComps, (comp) => comp.potential)
  // const potentialCompID = map(potentialComps, comp => comp._id)
  // const potentialSelected = every(selected, id => includes(potentialCompID, id))

  const potentialSelected =
    filter(potentialComps, (comp) => includes(selected, comp._id))
      .length > 0
  const actualSelected =
    filter(shownComps, (comp) => includes(selected, comp._id))
      .length > 0

  const shownCompID = map(shownComps, (comp) => comp._id)
  const shownCompValues = filter(compValues, (value) =>
    includes(shownCompID, value._id),
  )

  const allShownComps = concat(shownComps, potentialComps)
  const selectAllComps =
    alignment === 'potential' ? potentialComps : shownComps
  const compare = intersection(
    map(selectAllComps, (comp) => comp._id),
    selected,
  )

  const selectComp = (id) => (event) => {
    event.preventDefault()
    let newSelect = [...selected]
    if (includes(newSelect, id)) pull(newSelect, id)
    else newSelect.push(id)
    setSelected(newSelect)
  }

  const selectAll = () => {
    if (compare.length < selectAllComps.length)
      setSelected(map(selectAllComps, (comp) => comp._id))
    else setSelected([])
  }

  const [filterReset, setFilterReset] = useState(null)
  const [isSavedFilter, setIsSavedFilter] = useState(false)

  const getFilteredComps = (filtered) => {
    setFilteredComps(filtered)
    setFiltered(true)
  }

  useEffect(() => {
    const getSearchResults = (string) => {
      if (search === string) return null
      const words = split(string, ' ')
      const searchFilters = map(
        words,
        (word) => new RegExp(toLower(word)),
      )
      const filtered = filter(comps, (comp) => {
        const { createdBy } = comp
        const profile = createdBy
          ? createdBy.profile
          : { firstname: '', lastname: '' }
        return every(
          searchFilters,
          (searchFilter) =>
            searchFilter.test(toLower(comp.address)) ||
            searchFilter.test(toLower(comp.city)) ||
            searchFilter.test(toLower(comp.state)) ||
            searchFilter.test(toLower(comp.zip)) ||
            searchFilter.test(toLower(comp.sqft)) ||
            searchFilter.test(toLower(comp.description)) ||
            searchFilter.test(toLower(profile.firstname)) ||
            searchFilter.test(toLower(profile.lastname)),
        )
      })
      if (isEqual(filtered, searchedComps)) return null
      setSearch(string)
      setSearchedComps(filtered)
    }
    getSearchResults(search)
  }, [comps, search, searchedComps])

  const count = ceil(shownComps.length / 24)
  if (page > count && count) setPage(count || 1)

  const potentialCount = ceil(potentialComps.length / 24)
  if (potentialPage > potentialCount && potentialCount)
    setPage(potentialCount || 1)

  React.useEffect(() => {
    let photos = map(concat(selectedImages, images), (image) => ({
      id: image._id,
      src: image.url,
      url_small: image.url_small,
      width: 4,
      height: 3,
    }))
    setItems(photos)
  }, [images, selectedImages])

  const Photo = ({
    index,
    onClick,
    photo,
    margin,
    direction,
    top,
    left,
  }) => {
    const imgStyle = { margin: margin, cursor: 'pointer' }
    const selectedImgStyle = {
      // transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
      // transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
    }
    const cont = {
      backgroundColor: '#eee',
      cursor: 'pointer',
      overflow: 'hidden',
      position: 'relative',
    }

    const isSelected = find(selected, (value) => value === photo.id)
    //calculate x,y scale
    // const sx = (100 - (30 / photo.width) * 100) / 100;
    // const sy = (100 - (30 / photo.height) * 100) / 100;
    //selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;

    if (direction === 'column') {
      imgStyle.position = 'absolute'
      imgStyle.left = left
      imgStyle.top = top
    }

    return (
      <div
        style={{
          margin,
          height: photo.height,
          width: photo.width,
          ...cont,
        }}
        //className={!isSelected ? "not-selected" : ""}
        className="not-selected"
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            width: photo.width,
            height: 50,
            background: 'rgba(0, 0, 0, 0.5',
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item style={{ width: 'auto' }}>
              <DragIndicator
                style={{
                  color: 'white',
                  marginTop: 10,
                  marginRight: 5,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div
          className="blur-load"
          style={{ backgroundImage: 'url(' + photo.url_small + ')' }}
        >
          <img
            style={
              isSelected
                ? { ...imgStyle, ...selectedImgStyle }
                : { ...imgStyle }
            }
            {...photo}
            alt="img"
            loading="lazy"
          />
        </div>
        <style>{`.not-selected:hover{outline:2px solid #06befa}`}</style>
      </div>
    )
  }

  const SortablePhoto = SortableElement((params) => (
    <Grid item xs={12} md={6}>
      <Avatar
        style={{
          height: 30,
          width: 30,
          backgroundColor: '#2f3f4d',
          position: 'absolute',
          right: 70,
          top: 20,
        }}
      >
        {params.indexNumber + 1}
      </Avatar>
      <div
        className="blur-load"
        style={{
          backgroundImage: 'url(' + params.photo.url_small + ')',
          height: 225,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          aspectRatio: '4/3',
        }}
      >
        <img
          src={params.photo.src}
          className="card-img-top rounded"
          style={{
            maxHeight: 225,
            maxWidth: 300,
            height: 'auto',
            width: 'auto',
            aspectRatio: '4/3',
            borderBottomRightRadius: '0px',
            objectFit: 'contain',
          }}
          alt="..."
          loading="lazy"
        />
      </div>
    </Grid>
  ))
  const SortableGallery = SortableContainer(({ items }) => (
    <Grid container justifyContent="justify-between" spacing={2}>
      {map(items, (photo, index) => (
        <SortablePhoto
          photo={photo}
          indexNumber={index}
          index={index}
        />
      ))}
    </Grid>
  ))

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (props.shared) return
    //if (oldIndex === newIndex) selectPhoto(items[oldIndex])
    else {
      const newOrder = arrayMoveImmutable(items, oldIndex, newIndex)
      // if (ref.current) {
      //   setMinHeight(ref.current.offsetHeight)
      // }
      setItems(newOrder)
      updateComp({
        variables: {
          id: compPropertyInput.mainProperty,
          compPropertyInput: {
            imageOrder: map(newOrder, (item) => item.id),
          },
        },
      }).then(({ data }) => {
        const { updateCompProperty } = data
        setImages(updateCompProperty.images)
      })
    }
  }

  return (
    <>
      <Grid container spacing={2} style={{ padding: 25 }}>
        <Grid item className="gridItem2" xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            // className="d-block d-sm-flex align-items-center"
          >
            <Grid item className="gridItem2">
              {!props.public && !props.shared && (
                <>
                  <Grid item>
                    <Button
                      data-tour="comps-step-3"
                      variant="contained"
                      size="small"
                      //onClick={handleMenuOpen}
                      onClick={
                        props.session &&
                        props.session.me &&
                        props.session.me.activeOrg &&
                        props.session.me.activeOrg.isRealeflow &&
                        property?.realeflowData
                          ? handleMenuOpen
                          : handleOpenAddCompAddress
                      }
                      style={{
                        backgroundColor: '#1cc943',
                        color: 'white',
                      }}
                    >
                      <AddTwoToneIcon
                        /*className="w-50"*/ style={{
                          marginRight: 10,
                        }}
                      />
                      Add Comp
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
            {!showMenu && (
              <Grid item className="gridItem2">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        onClick={() => {
                          setShowMenu(!showMenu)
                        }}
                        size="small"
                        defaultChecked
                      />
                    }
                    label="Show Menu"
                  />
                </FormGroup>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Collapse
            in={Boolean(showMenu)}
            style={{
              width: '100%',
              marginLeft: 15,
              marginRight: 15,
              marginBottom: 10,
            }}
          >
            <Card className="d-block d-sm-flex text-center text-md-left card-box p-4 align-items-center bg-secondary justify-content-between flex-row">
              {hiddenUp ? null : (
                <div
                  style={{ position: 'absolute', top: 5, right: 10 }}
                >
                  <Typography
                    variant="subtitle2"
                    onClick={() => handleMenu()}
                  >
                    Hide Menu
                  </Typography>
                </div>
              )}
              <Grid
                container
                className="d-block d-sm-flex align-items-center"
                spacing={2}
              >
                <Grid item>
                  {!props.shared && (
                    <Grid container alignItems="center">
                      <Grid item>
                        <Checkbox
                          checked={compare.length > 0}
                          indeterminate={
                            compare.length < selectAllComps.length &&
                            compare.length > 0
                          }
                          onClick={selectAll}
                        />
                      </Grid>
                      <Grid item>
                        <ToggleButtonGroup
                          color="primary"
                          value={alignment}
                          exclusive
                          onChange={toggleAlignment}
                          aria-label="Platform"
                          className="mr-3"
                        >
                          <ToggleButton value="actual">
                            Actual ({shownComps?.length})
                          </ToggleButton>
                          <ToggleButton value="potential">
                            Potential ({potentialComps?.length})
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {!props.shared && (
                  <Grid item className="mx-4 d-none d-md-block">
                    <div className="divider-v bg-dark opacity-3 position-relative" />
                    <div className="divider-v bg-dark opacity-3 position-relative" />
                  </Grid>
                )}
                <Grid
                  item
                  className="d-flex d-md-block justify-content-center py-3 py-md-0"
                >
                  <div
                    className={clsx(
                      'search-wrapper search-wrapper--grow',
                      { 'is-active': searchStatus3 },
                    )}
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      classes={{ root: 'bg-white' }}
                      className="w-100"
                      value={props.search}
                      onChange={(event) =>
                        setSearch(event.target.value)
                      }
                      placeholder={'Search these comps'}
                      id="input-with-icon-textfield22-3"
                      onFocus={toggleSearch3}
                      onBlur={toggleSearch3}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FilterListIcon
                              style={{ color: '#bbbbbb' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
                <Typography style={{ flex: 1 }} />
                {activeTab === '1' && (
                  <Grid item style={{ marginRight: 5 }}>
                    <Select
                      variant="outlined"
                      margin="dense"
                      fullWidth={true}
                      value={ordering}
                      onChange={(event) =>
                        setOrdering(event.target.value)
                      }
                      displayEmpty
                    >
                      {map(sortingChoices, (choice) => (
                        <MenuItem
                          key={choice.name}
                          value={choice.name}
                        >
                          {choice.title}
                        </MenuItem>
                      ))}
                      <MenuItem key={'unselected'} value={''}>
                        Added - new to old
                      </MenuItem>
                    </Select>
                  </Grid>
                )}
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    wrap="nowrap"
                  >
                    {filterReset && (
                      <Grid item>
                        <Chip
                          color={isSavedFilter ? 'primary' : 'grey'}
                          variant="outlined"
                          label="Filters Applied"
                          onDelete={filterReset}
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <ButtonGroup /*variant="text"*/>
                        <Tooltip title="Card View">
                          <Button
                            className={clsx(
                              'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm',
                              { active: activeTab === '1' },
                            )}
                            onClick={() => {
                              setActiveTab('1')
                              localStorage.setItem('comp-view', '1')
                            }}
                          >
                            <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                              <GridOnTwoTone />
                            </span>
                          </Button>
                        </Tooltip>
                        <Tooltip title="List View">
                          <Button
                            className={clsx(
                              'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm',
                              { active: activeTab === '2' },
                            )}
                            onClick={() => {
                              setActiveTab('2')
                              localStorage.setItem('comp-view', '2')
                            }}
                          >
                            <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                              <ListIcon />
                            </span>
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </Grid>
                    <Grid item>
                      <ButtonGroup /*variant="text"*/>
                        <Tooltip title="Show Analytics">
                          <Button
                            className={clsx(
                              'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm',
                              { active: showAnalytics },
                            )}
                            onClick={() => {
                              setShowAnalytics(!showAnalytics)
                            }}
                          >
                            <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                              <PollTwoTone />
                            </span>
                          </Button>
                        </Tooltip>
                        <Tooltip title="Show Map">
                          <Button
                            className={clsx(
                              'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm',
                              { active: showMap },
                            )}
                            onClick={() => {
                              setShowMap(!showMap)
                            }}
                          >
                            <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                              <RoomTwoTone />
                            </span>
                          </Button>
                        </Tooltip>
                        <MyPropertiesFilter
                          openFilterDrawer={true}
                          comps={true}
                          properties={props.comps}
                          getFilteredProperties={getFilteredComps}
                          setFilterReset={setFilterReset}
                          setIsSavedFilter={setIsSavedFilter}
                          activeOrg={
                            props.session && props.session.me
                              ? props.session.me.activeOrg
                              : {}
                          }
                        />
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {showMap && (
              <Grid item xs={12} xl={6}>
                <Card>
                  {/* {!openAddCompAddress && (<Map properties={[property]} comps={shownComps} propertyPage streetView={false} />)} */}
                  <Map
                    properties={[property]}
                    comps={stableSort(
                      shownComps,
                      getSorting(order, orderByTarget),
                    )}
                    potentialComps={stableSort(
                      potentialComps,
                      getSorting(order, orderByTarget),
                    )}
                    streetView={false}
                    hide={openAddCompAddress}
                    openComp={openEdit}
                    shared={props.shared || props.public}
                    noInfoWindow
                    path={path}
                    setPath={setPath}
                    drawingCallback={compDrawingCallback}
                  />
                </Card>
              </Grid>
            )}
            {showAnalytics && (
              <Grid item xs={12} xl={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Card
                      className="card-box p-4"
                      style={{ height: '100%' }}
                    >
                      <div className="font-size-sm text-uppercase text-second mt-2">
                        Comps Properties
                      </div>
                      <div className="my-2">
                        <div className="d-flex py-4 align-items-center">
                          <div className="d-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-first text-white btn-icon text-center shadow-first mr-3">
                            <HomeIcon style={{ fontSize: 40 }} />
                          </div>
                          <div className="display-3 ml-1">
                            {shownComps.length}
                          </div>
                        </div>
                        <div className="text-black-50 mb-2">
                          The number of properties comparable to{' '}
                          {property.address}.
                        </div>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card
                      className="card-box p-4"
                      style={{ height: '100%' }}
                    >
                      <div className="font-size-sm text-uppercase text-second mt-2">
                        Adjusted Range
                      </div>
                      <div className="my-2">
                        <div className="d-flex py-4 align-items-center">
                          <div className="display-4 font-weight-bold ml-1">
                            <CountUp
                              start={0}
                              end={
                                isEmpty(shownCompValues)
                                  ? 0
                                  : minBy(
                                      shownCompValues,
                                      'propertyPrice',
                                    ).propertyPrice / 1000
                              }
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                            <span> - </span>
                            <CountUp
                              start={0}
                              end={
                                isEmpty(shownCompValues)
                                  ? 0
                                  : maxBy(
                                      shownCompValues,
                                      'propertyPrice',
                                    ).propertyPrice / 1000
                              }
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </div>
                        </div>
                        <div className="text-black-50 mb-2">
                          The adjusted value range of the comp
                          properties below (in thousands)
                        </div>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card
                      data-tour="comps-step-1"
                      className="card-box p-4"
                      style={{ height: '100%' }}
                    >
                      <div className="font-size-sm text-uppercase text-second mt-2">
                        Avg. Adjusted Value
                      </div>
                      <div className="my-2">
                        <div className="d-flex py-4 align-items-center">
                          <div className="d-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-first text-white btn-icon text-center shadow-first mr-3">
                            <LinearScaleIcon
                              style={{ fontSize: 40 }}
                            />
                            {/* <FontAwesomeIcon
                            icon={['far', 'envelope']}
                            className="display-4"
                          /> */}
                          </div>
                          <div className="display-3 font-weight-bold ml-1">
                            <CountUp
                              start={0}
                              end={
                                isEmpty(shownCompValues)
                                  ? 0
                                  : meanBy(
                                      filter(
                                        shownCompValues,
                                        'propertyPrice',
                                      ),
                                      'propertyPrice',
                                    )
                              }
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={0}
                              decimal="."
                              prefix="$"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-black-50 mb-2">
                        This number helps you to confirm the ARV of
                        the subject property
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Card
                      className="card-box p-4"
                      style={{ height: '100%' }}
                    >
                      <div className="font-size-sm text-uppercase text-second mt-2">
                        Avg. Adjusted price/sqft
                      </div>
                      <div className="my-2">
                        <div className="d-flex py-4 align-items-center">
                          <div className="d-60 rounded border-0 card-icon-wrapper flex-shrink-0 bg-first text-white btn-icon text-center shadow-first mr-3">
                            <SquareFootIcon
                              style={{ fontSize: 40 }}
                            />
                          </div>
                          <div className="display-3 font-weight-bold ml-1">
                            <CountUp
                              start={0}
                              end={
                                isEmpty(shownCompValues)
                                  ? 0
                                  : meanBy(
                                      filter(
                                        shownCompValues,
                                        'propertyPerSqft',
                                      ),
                                      'propertyPerSqft',
                                    )
                              }
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={2}
                              decimal="."
                              prefix="$"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-black-50 mb-2">
                        According to adjusted $/sqft, the subject
                        property's value is{' '}
                        <span className="font-weight-bold">
                          {isEmpty(shownCompValues)
                            ? '-'
                            : currencyFormat.format(
                                meanBy(
                                  filter(
                                    shownCompValues,
                                    'propertyPerSqft',
                                  ),
                                  'propertyPerSqft',
                                ) * property.sqft || 0,
                              )}
                        </span>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isEmpty(comps) && (
          <Grid item xs={12}>
            {activeTab === '2' && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={4}
              >
                {unfilteredComps.length > 0 ? (
                  compact(shownComps).length > 0 ? (
                    <CompsList
                      comps={map(
                        stableSort(
                          compact(shownComps),
                          getSorting(order, orderByTarget),
                        ),
                        (comp, index) => ({
                          ...comp,
                          mapIndex: index,
                        }),
                      )}
                      ordering={ordering}
                      property={property}
                      openEdit={openEdit}
                      selected={selected}
                      selectComp={selectComp}
                      selectAll={selectAll}
                      shared={props.shared || props.public}
                    />
                  ) : (
                    <Grid item>
                      <AllPropertiesFiltered />
                    </Grid>
                  )
                ) : (
                  <Grid item>
                    <div className="text-center p-5">
                      <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div
                          style={{
                            backgroundColor: '#cfe1fa',
                            color: '#008aff',
                          }}
                          className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130"
                        >
                          <FontAwesomeIcon
                            icon={['fas', 'house-chimney']}
                            className="d-flex align-self-center display-3 "
                          />
                        </div>
                      </div>
                      <h4 className="font-weight-bold mt-4">
                        No Comps Found...
                      </h4>
                      <p className="mb-0 font-size-lg text-muted">
                        This property has no comps
                      </p>
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
            {activeTab === '1' && (
              <>
                <Grid item style={{ marginBottom: -10 }}>
                  <Typography variant="h5">
                    Comparable Properties
                  </Typography>
                </Grid>
                <Grid container item>
                  {unfilteredComps.length > 0 ? (
                    compact(shownComps).length > 0 ? (
                      <>
                        <Grid
                          container
                          spacing={4}
                          style={{ marginTop: 10 }}
                        >
                          {map(
                            slice(
                              stableSort(
                                compact(shownComps),
                                getSorting(order, orderByTarget),
                              ),
                              (page - 1) * 24,
                              page * 24,
                            ),
                            (comp, index) => (
                              <CompsCard
                                organization={organization}
                                comp={comp}
                                index={(page - 1) * 24 + index}
                                property={property}
                                openEdit={openEdit}
                                selected={selected}
                                selectComp={selectComp}
                                shared={props.shared || props.public}
                              />
                            ),
                          )}
                        </Grid>
                        {Boolean(count) && shownComps.length > 0 && (
                          <Grid item xs={12}>
                            <div className="p-3 d-flex justify-content-center">
                              <Pagination
                                className="pagination-first"
                                size="small"
                                count={count}
                                page={page}
                                onChange={handlePotentialChange}
                                shared={props.shared || props.public}
                              />
                            </div>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <AllPropertiesFiltered />
                      </Grid>
                    )
                  ) : (
                    <Grid item xs={12}>
                      <div className="text-center p-5">
                        <div className="avatar-icon-wrapper rounded-circle m-0">
                          <div
                            style={{
                              backgroundColor: '#cfe1fa',
                              color: '#008aff',
                            }}
                            className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130"
                          >
                            <FontAwesomeIcon
                              icon={['fas', 'house-chimney']}
                              className="d-flex align-self-center display-3 "
                            />
                          </div>
                        </div>
                        <h4 className="font-weight-bold mt-4">
                          No Comps Found...
                        </h4>
                        <p className="mb-0 font-size-lg text-muted">
                          This property has no comps
                        </p>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            <Divider style={{ marginBottom: 30 }} />
            {activeTab === '2' && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={4}
              >
                {unfilteredPotentialComps.length > 0 ? (
                  compact(potentialComps).length > 0 ? (
                    <CompsList
                      comps={map(
                        stableSort(
                          compact(potentialComps),
                          getSorting(order, orderByTarget),
                        ),
                        (comp, index) => ({
                          ...comp,
                          mapIndex: index,
                        }),
                      )}
                      ordering={ordering}
                      property={property}
                      openEdit={openEdit}
                      selected={selected}
                      selectComp={selectComp}
                      selectAll={selectAll}
                      shared={props.shared || props.public}
                      title="Potential Comps"
                    />
                  ) : (
                    <Grid item xs={12}>
                      <AllPropertiesFiltered />
                    </Grid>
                  )
                ) : (
                  <Grid item xs={12}>
                    <div className="text-center p-5">
                      <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div
                          style={{
                            backgroundColor: '#cfe1fa',
                            color: '#008aff',
                          }}
                          className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130"
                        >
                          <FontAwesomeIcon
                            icon={['fas', 'house-chimney']}
                            className="d-flex align-self-center display-3 "
                          />
                        </div>
                      </div>
                      <h4 className="font-weight-bold mt-4">
                        No Comps Found...
                      </h4>
                      <p className="mb-0 font-size-lg text-muted">
                        This property has no potential comps
                      </p>
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
            {activeTab === '1' && (
              <>
                <Grid item style={{ marginBottom: -10 }}>
                  <Typography variant="h5">
                    Potential Comparable Properties
                  </Typography>
                </Grid>
                <Grid container item>
                  {unfilteredPotentialComps.length > 0 ? (
                    compact(potentialComps).length > 0 ? (
                      <>
                        <Grid
                          container
                          spacing={4}
                          style={{ marginTop: 10 }}
                        >
                          {map(
                            slice(
                              stableSort(
                                compact(potentialComps),
                                getSorting(order, orderByTarget),
                              ),
                              (potentialPage - 1) * 24,
                              potentialPage * 24,
                            ),
                            (comp, index) => (
                              <CompsCard
                                organization={organization}
                                comp={comp}
                                index={
                                  (potentialPage - 1) * 24 + index
                                }
                                property={property}
                                openEdit={openEdit}
                                selected={selected}
                                selectComp={selectComp}
                                shared={props.shared || props.public}
                                potential={true}
                              />
                            ),
                          )}
                        </Grid>
                        {Boolean(potentialCount) &&
                          potentialComps.length > 0 && (
                            <Grid item xs={12}>
                              <div className="p-3 d-flex justify-content-center">
                                <Pagination
                                  className="pagination-first"
                                  size="small"
                                  count={potentialCount}
                                  page={potentialPage}
                                  onChange={handlePotentialChange}
                                  shared={
                                    props.shared || props.public
                                  }
                                />
                              </div>
                            </Grid>
                          )}
                      </>
                    ) : (
                      <Grid item xs={12}>
                        <AllPropertiesFiltered />
                      </Grid>
                    )
                  ) : (
                    <Grid item xs={12}>
                      <div className="text-center p-5">
                        <div className="avatar-icon-wrapper rounded-circle m-0">
                          <div
                            style={{
                              backgroundColor: '#cfe1fa',
                              color: '#008aff',
                            }}
                            className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130"
                          >
                            <FontAwesomeIcon
                              icon={['fas', 'house-chimney']}
                              className="d-flex align-self-center display-3 "
                            />
                          </div>
                        </div>
                        <h4 className="font-weight-bold mt-4">
                          No Comps Found...
                        </h4>
                        <p className="mb-0 font-size-lg text-muted">
                          This property has no potential comps
                        </p>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Grid>
      {!props.public && !props.shared && isEmpty(props.comps) && (
        <Grid item>
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div
                /*style={{ backgroundColor: '#d3e1f8', color: '#008bff' }}*/ className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-first m-0 d-130"
              >
                <CompareIcon
                  style={{ fontSize: 56, color: '#008bff' }}
                />
                {/* <FontAwesomeIcon
                  icon={['far', 'folder-open']}
                  className="d-flex align-self-center display-3 "
                /> */}
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              Lets Find Some Comps
            </h4>
            <p className="mb-0 font-size-lg text-muted">
              Use Housefolios to help you find comparable properties
              nearby
            </p>
            <p className="mb-0 font-size-lg text-muted">
              Find comps are run in the background when a property is
              first analyzed. Please wait a few minutes to see if
              comps will be found.
            </p>
            <div className="pt-4">
              <Button
                data-tour="comps-step-3"
                variant="contained"
                size="small"
                //onClick={handleMenuOpen}
                onClick={
                  props.session &&
                  props.session.me &&
                  props.session.me.activeOrg &&
                  props.session.me.activeOrg.isRealeflow &&
                  property?.realeflowData
                    ? handleMenuOpen
                    : handleOpenAddCompAddress
                }
                style={{ backgroundColor: '#1cc943', color: 'white' }}
              >
                <AddTwoToneIcon
                  /*className="w-50"*/ style={{ marginRight: 10 }}
                />
                Add Comp
              </Button>
            </div>
          </div>
        </Grid>
      )}
      {!props.shared && (
        <Menu
          anchorEl={compMenu}
          keepMounted
          getContentAnchorEl={null}
          open={Boolean(compMenu)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{ list: 'p-0' }}
        >
          <div className="p-2">
            <List
              component="div"
              className="nav-pills p-0 m-0 nav-neutral-dark flex-column"
            >
              <ListItem button onClick={handleOpenAddCompAddress}>
                <AddTwoToneIcon style={{ marginRight: 10 }} />
                Add Comp
              </ListItem>
              {props?.session?.me?.activeOrg?.isRealeflow && (
                <ListItem button onClick={handleFindMenuOpen}>
                  <AddTwoToneIcon style={{ marginRight: 10 }} />
                  Find Comps
                </ListItem>
              )}
              {!props.session.me.activeOrg.isRealeflow && (
                <FindComps
                  property={property}
                  comps={props.comps}
                  compValues={props.compValues}
                  handleMenuClose={handleMenuClose}
                  compsBackendLoading={props.compsBackendLoading}
                />
              )}
            </List>
          </div>
        </Menu>
      )}
      <Collapse
        in={selected.length > 0}
        style={{
          position: 'fixed',
          bottom: '20px',
          width: '75%',
          zIndex: 1100,
        }}
      >
        <Alert
          className="d-block"
          style={{ backgroundColor: '#253152', color: 'white' }}
          icon={false}
          // severity="error"
          // action={
          //   <Button variant='contained' size="small" style={{ backgroundColor: '#4290ff', color: 'white' }}>
          //     <Typography>Save</Typography>
          //   </Button>
          // }
        >
          {/* <Typography style={{ color: 'white', fontWeight: 600 }}>You have unsaved changes</Typography> */}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            style={{ overflow: 'scroll' }}
          >
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid item>
                  <Tooltip title="Deselect Comps">
                    <IconButton
                      onClick={() => setSelected([])}
                      size="large"
                    >
                      <FontAwesomeIcon
                        icon={['far', 'xmark']}
                        style={{ color: 'white' }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography
                    style={{ whiteSpace: 'nowrap', marginRight: 20 }}
                  >
                    {selected.length} selected
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              style={{ textAlign: 'center', marginRight: 20 }}
            >
              <Grid container wrap="nowrap">
                {selected.length === 1 && (
                  <Grid item>
                    <Tooltip title="Edit Comp">
                      <IconButton
                        style={{ color: 'white' }}
                        disabled={loader}
                        onClick={() =>
                          openEdit(
                            find(
                              comps,
                              (comp) => comp._id === head(selected),
                            ),
                          )
                        }
                        size="large"
                      >
                        <EditIcon className="mr-2" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                {potentialSelected && (
                  <Grid item>
                    <Tooltip title="Bulk Set as Comp">
                      <IconButton
                        disabled={loader}
                        onClick={() => setBulkSetCompDialog(true)}
                        size="large"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'house-circle-check']}
                          style={{ color: 'white' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                {actualSelected && (
                  <Grid item>
                    <Tooltip title="Bulk Set as Potential">
                      <IconButton
                        disabled={loader}
                        onClick={() =>
                          setBulkSetPotentialDialog(true)
                        }
                        size="large"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'house-circle-exclamation']}
                          style={{ color: 'white' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item>
                  <DeleteComps
                    bulkActionsNotifications={true}
                    selected={selected}
                    setSelected={setSelected}
                    propertyId={property._id}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item disabled />
          </Grid>
        </Alert>
      </Collapse>
      <Dialog
        open={openAddCompAddress}
        onClose={handleCloseAddCompAddress}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="sm"
        fullWidth={true}
      >
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=geometry,places`}
          onLoad={handleScriptLoad}
        />
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              <p>Connecting</p>
              <p>
                {(((minutes * 60 + seconds) / 120) * 100).toFixed(1)}%
                Complete
              </p>
            </div>
          }
        >
          <DialogTitle>Add Comp Property</DialogTitle>
          <DialogContent>
            <TextField
              id="autocomplete"
              label="Search Property"
              placeholder="Address"
              fullWidth={true}
              autoFocus
              style={{
                marginTop: 10,
              }}
            />
          </DialogContent>
          <DialogActions>
            <ApolloConsumer>
              {(client) => (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!placeResult}
                  onClick={() => searchForComp(client)}
                >
                  Search
                </Button>
              )}
            </ApolloConsumer>
          </DialogActions>
        </BlockUi>
        {/* <Card>
          <Script
            url={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=geometry,places`}
            onLoad={handleScriptLoad}
          />
          <CardHeader title="Add Comp Property" />
          <CardContent>
            <TextField
              id="autocomplete"
              label="Search Property"
              placeholder="Address"
              fullWidth={true}
            />
          </CardContent>
          <Divider />
          <CardActions>
            <Typography style={{ flex: 1 }} />
            <ApolloConsumer>
              {client => (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!placeResult}
                  onClick={() => searchForComp(client)}>
                  Search
                </Button>
              )}
            </ApolloConsumer>
          </CardActions>
        </Card> */}
      </Dialog>

      <Dialog
        scroll="body"
        maxWidth="md"
        open={openVerifyComp}
        onClose={handleCloseVerifyComp}
        classes={{ paper: 'shadow-lg rounded' }}
        fullWidth={true}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={<div className="text-primary">Analyzing Comp</div>}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} sm={5}>
              <div style={{ height: '100%', width: '100%' }}>
                <Map
                  properties={[compPropertyInput]}
                  height="100%"
                  streetView={false}
                  hide={openAddCompAddress}
                  noInfoWindow
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Card>
                <CardHeader
                  title="Is this the comp?"
                  action={
                    <IconButton
                      onClick={handleCloseVerifyComp}
                      disabled={loader}
                      size="large"
                    >
                      <CloseOutlined />
                    </IconButton>
                  }
                />
                <Divider />
                <CardContent>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="h5"
                        style={{ fontWeight: 600 }}
                      >
                        {currencyFormat.format(
                          compPropertyInput.estval || 0,
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <Typography>
                        {compPropertyInput.address},{' '}
                        {compPropertyInput.city},{' '}
                        {compPropertyInput.state}{' '}
                        {compPropertyInput.zip}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <Typography style={{ color: '#949798' }}>
                        {compPropertyInput.beds || '-'} bds |{' '}
                        {compPropertyInput.baths || '-'} ba |{' '}
                        {compPropertyInput.sqft
                          ? numberFormat.format(
                              compPropertyInput.sqft,
                            )
                          : '-'}{' '}
                        sqft | {compPropertyInput.year || '-'} year
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid item xs={12}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ boxShadow: 'none' }}
                          disabled={loader}
                          fullWidth={true}
                          onClick={submitComp()}
                        >
                          <Typography>Add Comp</Typography>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{
                            color: 'black',
                            backgroundColor: '#e3e7eb',
                            boxShadow: 'none',
                          }}
                          disabled={loader}
                          fullWidth={true}
                          onClick={handleOpenFixComp}
                        >
                          <Typography>
                            Fix Address / Comp Details
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </BlockUi>
      </Dialog>

      <Dialog
        // classes={{ paper: 'modal-content bg-white rounded-lg modal-dark' }}
        open={openFixComp}
        onClose={handleCloseFixComp}
      >
        <DialogTitle>Correct the address</DialogTitle>
        <DialogContent style={{ paddingTop: 15, paddingBottom: 15 }}>
          <BlockUi
            blocking={loader}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={
              <div className="text-primary">Updating Comp</div>
            }
          >
            <DialogContent>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
                className="mb-0"
              >
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 600, marginBottom: -10 }}
                  >
                    Location
                  </Typography>
                </Grid>
                {map(compAddressFields, (field) =>
                  renderInput(field),
                )}
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
                className="mb-0"
              >
                <Grid item xs={12}>
                  <List className="nav-line d-flex mt-3">
                    <Typography
                      color="primary"
                      style={{ fontWeight: 600, marginBottom: -10 }}
                    >
                      Comp Details
                    </Typography>
                  </List>
                </Grid>
                {map(compDetailFields, (field) => renderInput(field))}
              </Grid>
            </DialogContent>
          </BlockUi>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseFixComp}
            variant="text"
            disabled={loader}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loader}
            onClick={submitComp()}
          >
            Add Comp
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddComp}
        onClose={handleCloseAddComp}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          {edit ? 'Edit Comp' : 'Add Comp'}{' '}
          {edit && (
            <Avatar
              className={
                compPropertyInput.potential ? 'bg-warning' : null
              }
              style={{
                height: 30,
                width: 30,
                position: 'absolute',
                top: 15,
                right: 20,
                backgroundColor: compPropertyInput.potential
                  ? null
                  : '#2f3f4d',
              }}
            >
              {compPropertyInput.indexTest}
            </Avatar>
          )}
        </DialogTitle>
        {/* <Grid item> */}

        {/* </Grid> */}
        <DialogContent className="p-4">
          <BlockUi
            blocking={loader}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={<div className="text-primary">Saving Comp</div>}
          >
            <Grid container direction="row">
              <Grid item sm={12} style={{ padding: 10 }}>
                <Grid container direction="column">
                  <Slider1
                    className="card-img-top slider-dots"
                    {...widgetsCarousels1B}
                  >
                    {!isEmpty(selectedImages) &&
                      map(selectedImages, (image) => (
                        <div
                          onClick={(event) => {
                            event.stopPropagation()
                          }}
                        >
                          <Card
                            className="shadow-none"
                            style={{
                              height: 325,
                              backgroundColor: 'white',
                              margin: 1,
                            }}
                          >
                            <Grid
                              container
                              direction="column"
                              justifyContent="center"
                              style={{ height: '100%' }}
                            >
                              <Grid item>
                                <div
                                  className="card-img-wrapper"
                                  style={{
                                    textAlign: 'center',
                                    backgroundColor: 'transparent',
                                  }}
                                >
                                  <img
                                    src={image}
                                    className="card-img-top rounded"
                                    style={{
                                      maxHeight: 325,
                                      height: 'auto',
                                      width: 'auto',
                                      borderBottomRightRadius: '0px',
                                      objectFit: 'contain',
                                    }}
                                    alt="..."
                                  />
                                </div>
                                <Avatar
                                  onClick={() =>
                                    handleOpenEditImage(image)
                                  }
                                  style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    // backgroundColor: 'primary',
                                  }}
                                >
                                  <EditIcon
                                    style={{ color: 'white' }}
                                  />
                                </Avatar>
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                      ))}
                    {!isEmpty(images) &&
                      map(images, (image) => (
                        <div
                          onClick={(event) => {
                            event.stopPropagation()
                          }}
                        >
                          <Card
                            className="shadow-none"
                            style={{
                              height: 325,
                              backgroundColor: 'white',
                              margin: 1,
                            }}
                          >
                            <Grid
                              container
                              direction="column"
                              justifyContent="center"
                              style={{ height: '100%' }}
                            >
                              <Grid item>
                                <div
                                  className="card-img-wrapper"
                                  style={{
                                    textAlign: 'center',
                                    backgroundColor: 'transparent',
                                    position: 'relative',
                                  }}
                                >
                                  <div
                                    className="blur-load"
                                    style={{
                                      backgroundImage:
                                        'url(' +
                                        image.url_small +
                                        ')',
                                      height: 325,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <img
                                      src={image.url}
                                      className="card-img-top rounded"
                                      style={{
                                        maxHeight: 325,
                                        height: 'auto',
                                        width: 'auto',
                                        borderBottomRightRadius:
                                          '0px',
                                        objectFit: 'contain',
                                      }}
                                      alt="..."
                                      loading="lazy"
                                    />
                                  </div>
                                </div>
                                <Avatar
                                  onClick={(event) =>
                                    handleEditImageMoreVertOpen(
                                      event,
                                      image,
                                    )
                                  }
                                  style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    backgroundColor: '#FA7A87',
                                  }}
                                >
                                  <MoreVertIcon
                                    style={{ color: 'white' }}
                                  />
                                </Avatar>
                                {/* <Avatar
                              onClick={toggleOpenDeleteImage(image._id)}
                              style={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                backgroundColor: '#FA7A87',
                              }}
                            >
                              <DeleteTwoTone style={{ color: 'white' }} />
                            </Avatar> */}
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                      ))}
                  </Slider1>
                  <Menu
                    anchorEl={editImageMoreVert}
                    keepMounted
                    getContentAnchorEl={null}
                    open={Boolean(editImageMoreVert)}
                    onClose={handleEditImageMoreVertClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    classes={{ list: 'p-0' }}
                  >
                    <div className="p-2">
                      <List
                        component="div"
                        className="nav-pills p-0 m-0 nav-neutral-dark flex-column"
                      >
                        <ListItem
                          button
                          onClick={() =>
                            handleOpenEditImage(selectedEditImage)
                          }
                        >
                          <EditIcon
                            /*className="w-50"*/ style={{
                              marginRight: 10,
                            }}
                          />
                          Edit Image
                        </ListItem>
                        <ListItem
                          button
                          onClick={toggleOpenDeleteImage(
                            selectedEditImage._id,
                          )}
                        >
                          <DeleteTwoTone
                            /*className="w-50"*/ style={{
                              marginRight: 10,
                            }}
                          />
                          Delete Image
                        </ListItem>
                        <ListItem
                          button
                          onClick={toggleOpenManageImages}
                        >
                          <ImageOutlinedIcon
                            style={{ marginRight: 10 }}
                          />
                          Manage Images
                        </ListItem>
                      </List>
                    </div>
                  </Menu>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 25 }}>
                <div>
                  <div
                    {...getRootProps({
                      className: 'dropzone-upload-wrapper',
                    })}
                  >
                    <input {...getInputProps()} />
                    <div className="dropzone-inner-wrapper-add-comp">
                      {/* {isDragAccept && (
                        <div>
                          <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                            <CheckIcon className="d-50" />
                          </div>
                          <div className="font-size-sm text-success">
                            All files will be uploaded!
                              </div>
                        </div>
                      )}
                      {isDragReject && (
                        <div>
                          <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                            <CloseTwoToneIcon className="d-50" />
                          </div>
                          <div className="font-size-sm text-danger">
                            Some files will be rejected!
                              </div>
                        </div>
                      )}
                      {!isDragActive && (
                        <div>
                          <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                            <CloudUploadTwoToneIcon className="d-50" />
                          </div>
                          <div className="font-size-sm">
                            Drag and drop files here <span className="font-size-xs text-dark">(jpg/png images)</span>
                          </div>
                        </div>
                      )}
                      <small className="py-2 text-black-50">
                        or
                          </small>
                      <div>
                        <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                          <span className="px-2">
                            Browse Files
                              </span>
                        </Button>
                      </div> */}
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          variant="contained"
                          margin="dense"
                          style={{
                            backgroundColor: '#368CFF',
                            color: 'white',
                          }}
                        >
                          Upload Additional Images
                        </Button>
                      </div>
                      {/* <div style={{ position: 'absolute', top: 10, right: 10 }}>
                      <IconButton>
                        <FontAwesomeIcon
                          icon={[ 'far', 'images']}
                        />
                      </IconButton>
                    </div> */}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12}>
                    <Typography color="primary" variant="h6">
                      Property Details
                    </Typography>
                  </Grid>
                  {map(compFields, (field) => renderInput(field))}
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <Typography color="primary" variant="h6">
                      Financial Details
                    </Typography>
                  </Grid>
                  {map(compFinancialFields, (field) =>
                    renderInput(field),
                  )}
                  <Grid item xs={12} style={{ marginTop: 5 }}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{ marginBottom: -30 }}
                      >
                        Adjust Comp
                      </Grid>
                      <Grid item xs={12}>
                        <Slider
                          className="slider-first my-3"
                          color="#008bff"
                          value={compPropertyInput.priceAdjustment}
                          onChange={(event, value) => {
                            onChangeSlider(
                              event,
                              value,
                              'priceAdjustment',
                            )
                          }}
                          onChangeCommitted={(event, value) => {
                            onChangeSlider(
                              event,
                              value,
                              'priceAdjustment',
                            )
                          }}
                          marks={true}
                          track={false}
                          valueLabelDisplay="auto"
                          reverse
                          min={-15}
                          max={15}
                          step={2.5}
                          valueLabelFormat={(x) => `${x}%`}
                          style={{
                            width: '95%',
                            marginLeft: 18,
                          }}
                        />
                      </Grid>
                      {/* <Grid item xs={12} style={{ marginTop: -30 }}>
                      <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>Worse</Grid>
                        <Grid item>Same</Grid>
                        <Grid item>Better</Grid>
                      </Grid>
                    </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </BlockUi>
        </DialogContent>
        <DialogActions>
          {edit && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleClickActionsMenu}
            >
              Actions
              <KeyboardArrowDownTwoTone />
            </Button>
          )}
          <Typography style={{ flex: 1 }} />
          <Button
            size="small"
            variant="text"
            onClick={handleCloseAddComp}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={submitComp()}
          >
            Save
          </Button>
        </DialogActions>
        <Menu
          anchorEl={anchorElActionsMenu}
          keepMounted
          open={Boolean(anchorElActionsMenu)}
          onClose={handleCloseActionsMenu}
          classes={{ list: 'p-0' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="dropdown-menu-sm">
            <List
              component="div"
              disablePadding
              className="nav-list-square nav-neutral-primary"
            >
              <ListItem
                key="changePotential"
                button
                onClick={submitComp(true)}
              >
                <Grid container spacing={2} style={{ padding: 5 }}>
                  <Grid item xs={3}>
                    {potential ? (
                      <FontAwesomeIcon
                        icon={['far', 'house-circle-check']}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={['far', 'house-circle-exclamation']}
                      />
                    )}
                  </Grid>
                  <Grid item xs={9}>
                    <span>
                      Set as {potential ? 'Comp' : 'Potential'}
                    </span>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                key="zillow"
                button
                onClick={() =>
                  window.open(
                    `https://www.zillow.com/homes/${kebabCase(
                      compPropertyInput.address,
                    )},-${compPropertyInput.state},-${
                      compPropertyInput.zip
                    }_rb/`,
                    '_blank' /*'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400'*/,
                  )
                }
              >
                <Grid container spacing={2} style={{ padding: 5 }}>
                  <Grid item xs={3}>
                    <FontAwesomeIcon
                      icon={['far', 'arrow-up-right-from-square']}
                      className="font-size-lg"
                      style={{ marginRight: 2 }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>Open Zillow</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem
                key="delete"
                button
                onClick={toggleDeleteComp}
              >
                <Grid container spacing={2} style={{ padding: 5 }}>
                  <Grid item xs={3}>
                    <FontAwesomeIcon icon={['far', 'trash-can']} />
                  </Grid>
                  <Grid item xs={9}>
                    <span>Delete</span>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </div>
        </Menu>
      </Dialog>
      <Dialog
        open={openDeleteComp}
        onClose={toggleDeleteComp}
        classes={{ paper: 'shadow-lg rounded' }}
      >
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon
                icon={['fas', 'xmark']}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">
            Are you sure you want to delete this?
          </h4>
          <p className="mb-0 font-size-lg text-muted">
            All data will be deleted. This action cannot be undone.
          </p>
          <div className="pt-4">
            <Button
              variant="text"
              onClick={toggleDeleteComp}
              //className="btn-neutral-secondary btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: '#f83145', color: 'white' }}
              onClick={onDelete}
              //className="btn-danger btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Delete</span>
            </Button>
          </div>
        </div>
      </Dialog>
      <ImageCropper
        open={openEditImage}
        imageSrc={selectedEditImage.url || selectedEditImage}
        saveImage={saveCroppedImage}
        replaceOption={true}
        handleClose={handleCloseEditImage}
      />
      <Dialog
        open={openDeleteImage}
        onClose={toggleOpenDeleteImage()}
      >
        <DialogTitle style={{ paddingBottom: '0px' }}>
          Delete Image
        </DialogTitle>

        <Mutation
          mutation={DELETE_IMAGE_MULTI}
          refetchQueries={[
            {
              query: GET_COMPPROPERTIES,
              variables: { propertyId: property._id },
            },
            {
              query: GET_PROPERTY,
              variables: { id: property._id },
            },
          ]}
          variables={{
            ids: [selectedImageDelete],
          }}
        >
          {(deleteFiles, { data, loading, error }) => (
            <form
              className="form"
              onSubmit={(event) => onSubmitDelete(event, deleteFiles)}
            >
              <DialogContent>
                Are you sure you want to delete this image? This
                action can't be undone.
              </DialogContent>
              <DialogActions>
                <Button onClick={toggleOpenDeleteImage()}>
                  CANCEL
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loader}
                >
                  DELETE
                </Button>
              </DialogActions>
            </form>
          )}
        </Mutation>
      </Dialog>
      <Dialog open={bulkSetCompDialog} onClose={() => closeBulk()}>
        <DialogTitle>Mark Actual</DialogTitle>
        <DialogContent>
          Would you like to set the selected potential comps as actual
          comps?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setBulkSetCompDialog(false)
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              handleBulkDialog(false)
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={bulkSetPotentialDialog}
        onClose={() => closeBulk()}
      >
        <DialogTitle>Mark Potential</DialogTitle>
        <DialogContent>
          Would you like to set the selected comps as potential comps?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setBulkSetPotentialDialog(false)
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              handleBulkDialog(true)
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSelectedDrawing}
        onClose={() => setOpenSelectedDrawing(false)}
      >
        <DialogTitle>Out of Bounds</DialogTitle>
        <DialogContent>
          What would you like to do with the comps outside the shape?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSelectedDrawing(false)
              setPath([])
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              handleExcludedComps(true)
            }}
          >
            Mark Potential
          </Button>
          <Button
            style={{ backgroundColor: '#f83145', color: 'white' }}
            variant="contained"
            onClick={() => {
              handleExcludedComps(false)
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openManageImages}
        onClose={toggleOpenManageImages}
        fullWidth
        maxWidth="md"
        style={{ minHeight: minHeight }}
      >
        <DialogTitle>Manage Comp Images</DialogTitle>
        <DialogContent ref={ref}>
          <DialogContentText>
            Drag the Comp's images to reorder them
          </DialogContentText>
          <SortableGallery
            items={items}
            onSortEnd={onSortEnd}
            axis={'xy'}
            helperClass={classes.sortableHelper}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOpenManageImages}>Close</Button>
        </DialogActions>
      </Dialog>

      {menu && (
        <Menu
          anchorEl={anchorElDefinition}
          open={Boolean(anchorElDefinition)}
          onClose={() => handleDefinition('', null, null, null)}
          MenuListProps={{ dense: true }}
          style={{ width: 400 }}
          getContentAnchorEl={null}
          classes={{ list: 'p-0' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="dropdown-menu-xl">
            <MenuItem key="menuTitle" button={false}>
              <Typography variant="h6">{startCase(menu)}</Typography>
            </MenuItem>
            <MenuItem key="menuDescription" button={false}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                style={{
                  whiteSpace: 'normal',
                }}
              >
                We found multiple values for this field. Please choose
                the correct option.
              </Typography>
            </MenuItem>
            {map(propertyData[menu], (value) => (
              <MenuItem
                button
                key={menu + ': ' + value}
                onClick={() => {
                  if (menu === 'soldDate') onChangeDateMenu(value)
                  else
                    handleCompChange({
                      target: { name: menuName, value },
                    })
                  handleDefinition('', null, null)
                }}
              >
                <Typography style={{ flex: 1 }} />
                <ListItemText>
                  {menuFormat ? menuFormat.format(value) : value}
                </ListItemText>
              </MenuItem>
            ))}
          </div>
        </Menu>
      )}

      <Dialog
        fullScreen
        open={findCompMenu}
        onClose={handleFindMenuClose}
        style={{ maxHeight: window.innerHeight }}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              <p>Connecting</p>
              <p>
                {(((minutes * 60 + seconds) / 120) * 100).toFixed(1)}%
                Complete
              </p>
            </div>
          }
        >
          <DialogActions>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleFindMenuClose}
              aria-label="close"
            >
              {/* <CloseIcon /> */}
            </IconButton>
          </DialogActions>

          <DialogContent
            style={{
              overflow: 'scroll',
            }}
          >
            <Grid container spacing={2}>
              {/* Filters Section */}
              <Grid item xs={12}>
                <Grid container spacing={1} justifyContent="center">
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ width: '100%' }}
                    >
                      <TextField
                        value={`${
                          realeflowCompFilters?.beds?.min || 0
                        }-${realeflowCompFilters?.beds?.max || ''}`}
                        label={'Beds'}
                        onClick={toggleRealeflowCompFilters('beds')}
                      />
                    </FormControl>
                    <Menu
                      anchorEl={realeflowCompMenus['beds']}
                      keepMounted
                      open={Boolean(realeflowCompMenus['beds'])}
                      onClose={toggleRealeflowCompFilters('beds')}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      classes={{ list: 'p-0' }}
                    >
                      <div className="p-2">
                        <Grid
                          container
                          direction={'row'}
                          alignItems="center"
                          justifyContent="space-between"
                          wrap="nowrap"
                        >
                          <Grid item xs={5}>
                            <NumericFormat
                              customInput={TextField}
                              fullWidth={true}
                              //isNumericString={true}
                              decimalScale={0}
                              name={'beds.min'}
                              value={realeflowCompFilters?.beds?.min}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const { value } = values
                                const event = {
                                  target: {
                                    name: 'beds.min',
                                    value: value,
                                    type: 'number',
                                  },
                                  preventDefault: () => {
                                    return null
                                  },
                                }
                                onChangeRealeflowFilter(event)
                              }}
                              type={'text'}
                              onFocus={(event) => {
                                event.target.select()
                              }}
                              variant="outlined"
                              //margin='dense'
                              label={'Beds Min'}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography>-</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <NumericFormat
                              customInput={TextField}
                              fullWidth={true}
                              //isNumericString={true}
                              decimalScale={0}
                              name={'beds.max'}
                              value={realeflowCompFilters?.beds?.max}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const { value } = values
                                const event = {
                                  target: {
                                    name: 'beds.max',
                                    value: value,
                                    type: 'number',
                                  },
                                  preventDefault: () => {
                                    return null
                                  },
                                }
                                onChangeRealeflowFilter(event)
                              }}
                              type={'text'}
                              onFocus={(event) => {
                                event.target.select()
                              }}
                              variant="outlined"
                              //margin='dense'
                              label={'Beds Max'}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Menu>
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ width: '100%', minWidth: 150 }}
                    >
                      <TextField
                        value={`${
                          realeflowCompFilters?.baths?.min || 0
                        }-${realeflowCompFilters?.baths?.max || ''}`}
                        label={'Baths'}
                        onClick={toggleRealeflowCompFilters('baths')}
                      />
                    </FormControl>
                    <Menu
                      anchorEl={realeflowCompMenus['baths']}
                      keepMounted
                      open={Boolean(realeflowCompMenus['baths'])}
                      onClose={toggleRealeflowCompFilters('baths')}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      classes={{ list: 'p-0' }}
                    >
                      <div className="p-2">
                        <Grid
                          container
                          direction={'row'}
                          alignItems="center"
                          justifyContent="space-between"
                          wrap="nowrap"
                        >
                          <Grid item xs={5}>
                            <NumericFormat
                              customInput={TextField}
                              fullWidth={true}
                              //isNumericString={true}
                              decimalScale={2}
                              name={'baths.min'}
                              value={realeflowCompFilters?.baths?.min}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const { value } = values
                                const event = {
                                  target: {
                                    name: 'baths.min',
                                    value: value,
                                    type: 'number',
                                  },
                                  preventDefault: () => {
                                    return null
                                  },
                                }
                                onChangeRealeflowFilter(event)
                              }}
                              type={'text'}
                              onFocus={(event) => {
                                event.target.select()
                              }}
                              variant="outlined"
                              //margin='dense'
                              label={'Baths Min'}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography>-</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <NumericFormat
                              customInput={TextField}
                              fullWidth={true}
                              //isNumericString={true}
                              decimalScale={2}
                              name={'baths.max'}
                              value={realeflowCompFilters?.baths?.max}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const { value } = values
                                const event = {
                                  target: {
                                    name: 'baths.max',
                                    value: value,
                                    type: 'number',
                                  },
                                  preventDefault: () => {
                                    return null
                                  },
                                }
                                onChangeRealeflowFilter(event)
                              }}
                              type={'text'}
                              onFocus={(event) => {
                                event.target.select()
                              }}
                              variant="outlined"
                              //margin='dense'
                              label={'Baths Max'}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Menu>
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ width: '100%', minWidth: 150 }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Distance
                      </InputLabel>
                      <Select
                        label="Distance"
                        value={realeflowCompFilters.distance}
                        onChange={onChangeRealeflowFilter}
                        name="distance"
                        sx={{ marginBottom: 2 }}
                      >
                        <MenuItem key="distance-0.5" value={0.5}>
                          0.5
                        </MenuItem>
                        <MenuItem key="distance-1" value={1}>
                          1
                        </MenuItem>
                        <MenuItem key="distance-1.5" value={1.5}>
                          1.5
                        </MenuItem>
                        <MenuItem key="distance-2" value={2}>
                          2
                        </MenuItem>
                        <MenuItem key="distance-2.5" value={2.5}>
                          2.5
                        </MenuItem>
                        <MenuItem key="distance-3" value={3}>
                          3
                        </MenuItem>
                        <MenuItem key="distance-5" value={5}>
                          5
                        </MenuItem>
                        <MenuItem key="distance-7" value={7}>
                          7
                        </MenuItem>
                        <MenuItem key="distance-10" value={10}>
                          10
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ width: '100%', minWidth: 150 }}
                    >
                      <TextField
                        value={`${
                          realeflowCompFilters?.squareFeet?.min || 0
                        }-${
                          realeflowCompFilters?.squareFeet?.max || ''
                        }`}
                        label={'Square Feet'}
                        onClick={toggleRealeflowCompFilters('sqft')}
                      />
                    </FormControl>
                    <Menu
                      anchorEl={realeflowCompMenus['sqft']}
                      keepMounted
                      open={Boolean(realeflowCompMenus['sqft'])}
                      onClose={toggleRealeflowCompFilters('sqft')}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      classes={{ list: 'p-0' }}
                    >
                      <div className="p-2">
                        <Grid
                          container
                          direction={'row'}
                          alignItems="center"
                          justifyContent="space-between"
                          wrap="nowrap"
                        >
                          <Grid item xs={5}>
                            <NumericFormat
                              customInput={TextField}
                              fullWidth={true}
                              //isNumericString={true}
                              decimalScale={0}
                              name={'squareFeet.min'}
                              value={
                                realeflowCompFilters?.squareFeet?.min
                              }
                              allowNegative={false}
                              onValueChange={(values) => {
                                const { value } = values
                                const event = {
                                  target: {
                                    name: 'squareFeet.min',
                                    value: value,
                                    type: 'number',
                                  },
                                  preventDefault: () => {
                                    return null
                                  },
                                }
                                onChangeRealeflowFilter(event)
                              }}
                              type={'text'}
                              onFocus={(event) => {
                                event.target.select()
                              }}
                              variant="outlined"
                              //margin='dense'
                              label={'Square Feet Min'}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography>-</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <NumericFormat
                              customInput={TextField}
                              fullWidth={true}
                              //isNumericString={true}
                              decimalScale={0}
                              name={'squareFeet.max'}
                              value={
                                realeflowCompFilters?.squareFeet?.max
                              }
                              allowNegative={false}
                              onValueChange={(values) => {
                                const { value } = values
                                const event = {
                                  target: {
                                    name: 'squareFeet.max',
                                    value: value,
                                    type: 'number',
                                  },
                                  preventDefault: () => {
                                    return null
                                  },
                                }
                                onChangeRealeflowFilter(event)
                              }}
                              type={'text'}
                              onFocus={(event) => {
                                event.target.select()
                              }}
                              variant="outlined"
                              //margin='dense'
                              label={'Square Feet Max'}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Menu>
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ width: '100%', minWidth: 150 }}
                    >
                      <TextField
                        value={`${
                          realeflowCompFilters?.yearBuilt?.min || 0
                        }-${
                          realeflowCompFilters?.yearBuilt?.max || ''
                        }`}
                        label={'Year Built'}
                        onClick={toggleRealeflowCompFilters(
                          'yearBuilt',
                        )}
                      />
                    </FormControl>
                    <Menu
                      anchorEl={realeflowCompMenus['yearBuilt']}
                      keepMounted
                      open={Boolean(realeflowCompMenus['yearBuilt'])}
                      onClose={toggleRealeflowCompFilters(
                        'yearBuilt',
                      )}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      classes={{ list: 'p-0' }}
                    >
                      <div className="p-2">
                        <Grid
                          container
                          direction={'row'}
                          alignItems="center"
                          justifyContent="space-between"
                          wrap="nowrap"
                        >
                          <Grid item xs={5}>
                            <NumericFormat
                              customInput={TextField}
                              fullWidth={true}
                              //isNumericString={true}
                              decimalScale={0}
                              name={'yearBuilt.min'}
                              value={
                                realeflowCompFilters?.yearBuilt?.min
                              }
                              allowNegative={false}
                              onValueChange={(values) => {
                                const { value } = values
                                const event = {
                                  target: {
                                    name: 'yearBuilt.min',
                                    value: value,
                                    type: 'number',
                                  },
                                  preventDefault: () => {
                                    return null
                                  },
                                }
                                onChangeRealeflowFilter(event)
                              }}
                              type={'text'}
                              onFocus={(event) => {
                                event.target.select()
                              }}
                              variant="outlined"
                              //margin='dense'
                              label={'Year Built Min'}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Typography>-</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <NumericFormat
                              customInput={TextField}
                              fullWidth={true}
                              //isNumericString={true}
                              decimalScale={0}
                              name={'yearBuilt.max'}
                              value={
                                realeflowCompFilters?.yearBuilt?.max
                              }
                              allowNegative={false}
                              onValueChange={(values) => {
                                const { value } = values
                                const event = {
                                  target: {
                                    name: 'yearBuilt.max',
                                    value: value,
                                    type: 'number',
                                  },
                                  preventDefault: () => {
                                    return null
                                  },
                                }
                                onChangeRealeflowFilter(event)
                              }}
                              type={'text'}
                              onFocus={(event) => {
                                event.target.select()
                              }}
                              variant="outlined"
                              //margin='dense'
                              label={'Year Built Max'}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Menu>
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ width: '100%', minWidth: 150 }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Sold Date
                      </InputLabel>
                      <Select
                        label="Sold Date"
                        value={realeflowCompFilters.lastSoldDays}
                        onChange={onChangeRealeflowFilter}
                        name="lastSoldDays"
                        sx={{ marginBottom: 2 }}
                      >
                        <MenuItem
                          key="lastSoldDays-null"
                          value={null}
                        >
                          All time
                        </MenuItem>
                        <MenuItem key="lastSoldDays-30" value={30}>
                          1 Month
                        </MenuItem>
                        <MenuItem key="lastSoldDays-60" value={60}>
                          2 Months
                        </MenuItem>
                        <MenuItem key="lastSoldDays-90" value={90}>
                          3 Months
                        </MenuItem>
                        <MenuItem key="lastSoldDays-180" value={180}>
                          6 Months
                        </MenuItem>
                        <MenuItem key="lastSoldDays-365" value={365}>
                          1 Year
                        </MenuItem>
                        <MenuItem key="lastSoldDays-730" value={730}>
                          2 Year
                        </MenuItem>
                        <MenuItem
                          key="lastSoldDays-1095"
                          value={1095}
                        >
                          3 Year
                        </MenuItem>
                        <MenuItem
                          key="lastSoldDays-1825"
                          value={1825}
                        >
                          5 Year
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ width: '100%', minWidth: 150 }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Property Type
                      </InputLabel>
                      <Select
                        label="Property Type"
                        value={realeflowCompFilters.propertyType}
                        onChange={onChangeRealeflowFilter}
                        name="propertyType"
                        sx={{ marginBottom: 2 }}
                      >
                        <MenuItem
                          key="SINGLE_FAMILY_RESIDENTIAL"
                          value={'SINGLE_FAMILY_RESIDENTIAL'}
                        >
                          Single
                        </MenuItem>
                        <MenuItem
                          key="CONDOMINIUM"
                          value={'CONDOMINIUM'}
                        >
                          Condo
                        </MenuItem>
                        <MenuItem key="TOWNHOME" value={'TOWNHOME'}>
                          Townhome
                        </MenuItem>
                        <MenuItem
                          key="MULTIFAMILY"
                          value={'MULTIFAMILY'}
                        >
                          Multi
                        </MenuItem>
                        <MenuItem key="APARTMENT" value={'APARTMENT'}>
                          Apartment
                        </MenuItem>
                        <MenuItem key="OTHER" value={'OTHER'}>
                          Other
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ width: '100%', minWidth: 150 }}
                    >
                      {/* <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel> */}
                      <Autocomplete
                        multiple
                        //id="fixed-tags-demo"
                        options={realeflowStatusValues}
                        getOptionLabel={(option) => {
                          return option?.label
                        }}
                        disableCloseOnSelect
                        value={realeflowCompFilters.status}
                        onChange={(event, value) =>
                          onChangeRealeflowFilter({
                            target: {
                              value,
                              name: 'status',
                              type: 'text',
                            },
                          })
                        }
                        renderOption={(
                          props,
                          option,
                          { selected },
                        ) => (
                          <li {...props}>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlank fontSize="small" />
                              }
                              checkedIcon={
                                <CheckBox
                                  fontSize="small"
                                  style={{ color: '#2296F3' }}
                                />
                              }
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option?.label}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Status"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {/* Add more filter buttons here */}
                  <Typography style={{ flex: 1 }} />
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={triggerRealeflowSearchReset}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={
                        isEmpty(realeflowIncludedIds) &&
                        isEmpty(realeflowExcludedIds)
                          ? triggerRealeflowSearch
                          : () => setOpenSearchWarning(true)
                      }
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {/* Map Section */}
              <Grid item xs={5}>
                <Map
                  properties={[property]}
                  comps={map(
                    stableSort(
                      filter(
                        results?.data?.getRealeflowCompData,
                        (data) => {
                          const { property } = data
                          return !includes(
                            realeflowExcludedIds,
                            property?._id,
                          )
                        },
                      ),
                      getSorting(
                        realeflowOrder?.order,
                        realeflowOrder?.orderBy,
                      ),
                    ),
                    (data) => data.property,
                  )}
                  streetView={false}
                  shared={props.shared || props.public}
                  path={realeflowPath}
                  setPath={setRealeflowPath}
                  noInfoWindow
                  compWindow
                  customIcon={'/images/deal-sauce-map-icon.png'}
                  height="1000px"
                />
              </Grid>

              {/* Property List Section */}
              <Grid item xs={7} style={{ overflow: 'scroll' }}>
                <Table className="table text-nowrap mb-0">
                  <TableHead className="thead-light">
                    <TableRow>
                      <TableCell>
                        <IconButton
                          onClick={() => toggleIncludeAllComp()}
                        >
                          <FontAwesomeIcon
                            icon={['far', 'thumbs-up']}
                            style={{
                              color:
                                filter(
                                  results?.data?.getRealeflowCompData,
                                  (data) => {
                                    const { property } = data
                                    return !includes(
                                      realeflowExcludedIds,
                                      property?._id,
                                    )
                                  },
                                )?.length <=
                                realeflowIncludedIds?.length
                                  ? 'blue'
                                  : null,
                            }}
                          />
                        </IconButton>
                        #
                      </TableCell>
                      <TableCell
                        key={'Address'}
                        //style={{ whiteSpace: 'nowrap' }}
                        sortDirection={
                          realeflowOrder?.orderBy ===
                          'property.address_number'
                            ? realeflowOrder?.order
                            : false
                        }
                      >
                        {'property.address_number' ? (
                          <TableSortLabel
                            active={
                              realeflowOrder?.orderBy ===
                              'property.address_number'
                            }
                            direction={realeflowOrder?.order}
                            onClick={(event) =>
                              handleRequestSort(
                                event,
                                'property.address_number',
                              )
                            }
                          >
                            {'Address'}
                            {realeflowOrder?.orderBy ===
                            'property.address_number' ? (
                              <span style={visuallyHidden}>
                                {realeflowOrder?.order === 'desc'
                                  ? 'sorted descending'
                                  : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          'Address'
                        )}
                      </TableCell>
                      <TableCell>Google Search</TableCell>
                      {map(
                        slice(realeflowTableHeaders, 1),
                        (column) => (
                          <TableCell
                            key={column.title}
                            //style={{ whiteSpace: 'nowrap' }}
                            align={column.align}
                            sortDirection={
                              realeflowOrder?.orderBy === column.name
                                ? realeflowOrder?.order
                                : false
                            }
                          >
                            {column.name ? (
                              <TableSortLabel
                                active={
                                  realeflowOrder?.orderBy ===
                                  column.name
                                }
                                direction={realeflowOrder?.order}
                                onClick={(event) =>
                                  handleRequestSort(
                                    event,
                                    column.name,
                                  )
                                }
                              >
                                {column.title}
                                {realeflowOrder?.orderBy ===
                                column.name ? (
                                  <span style={visuallyHidden}>
                                    {realeflowOrder?.order === 'desc'
                                      ? 'sorted descending'
                                      : 'sorted ascending'}
                                  </span>
                                ) : null}
                              </TableSortLabel>
                            ) : (
                              column.title
                            )}
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={'Subject'}>
                      <TableCell>Subject Property</TableCell>
                      <TableCell>
                        <Typography>{property?.address}</Typography>
                        <Typography>
                          {property?.city}, {property.state}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            window.open(
                              `https://www.google.com/search?q=${property?.address} ${property?.city}, ${property.state}`,
                              '_blank',
                              // 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
                            )
                          }}
                        >
                          <FontAwesomeIcon
                            icon={['far', 'magnifying-glass']}
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>{sum(property.beds)}</TableCell>
                      <TableCell>{sum(property.baths)}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>
                        {property.afterRepairValue}
                      </TableCell>
                      <TableCell>{property?.sqft}</TableCell>
                      <TableCell>{property?.year}</TableCell>
                    </TableRow>
                    {map(
                      stableSort(
                        filter(
                          results?.data?.getRealeflowCompData,
                          (data) => {
                            const { property } = data
                            return !includes(
                              realeflowExcludedIds,
                              property?._id,
                            )
                          },
                        ),
                        getSorting(
                          realeflowOrder?.order,
                          realeflowOrder?.orderBy,
                        ),
                      ),
                      (data, index) => {
                        const { property } = data
                        const address =
                          property?.mailing_std_full_street_address ||
                          property?.address_number +
                            ' ' +
                            property?.address_direction_pre +
                            ' ' +
                            property?.address_street +
                            ' ' +
                            property?.address_direction_post +
                            ' ' +
                            (property?.address_suffix || '')
                        return (
                          <TableRow style={{ position: 'relative' }}>
                            <TableCell>
                              <IconButton
                                onClick={() =>
                                  toggleIncludeComp(property._id)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={['far', 'thumbs-up']}
                                  style={{
                                    color: includes(
                                      realeflowIncludedIds,
                                      property._id,
                                    )
                                      ? 'blue'
                                      : null,
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  toggleExcludeComp(property._id)
                                }
                                disabled={includes(
                                  realeflowIncludedIds,
                                  property._id,
                                )}
                              >
                                <FontAwesomeIcon
                                  icon={['far', 'thumbs-down']}
                                />
                              </IconButton>
                              {index + 1}
                            </TableCell>
                            <TableCell>
                              <Typography>{address}</Typography>
                              <Typography>
                                {property?.address_city},{' '}
                                {property?.address_state}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  window.open(
                                    `https://www.google.com/search?q=${address} ${property?.address_city}, ${property?.address_state}`,
                                    '_blank',
                                    // 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
                                  )
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={['far', 'magnifying-glass']}
                                />
                              </IconButton>
                            </TableCell>
                            {map(
                              slice(realeflowTableHeaders, 1),
                              (column) => (
                                <TableCell>
                                  <div>
                                    {column.formatter
                                      ? column.formatter(
                                          get(data, column.name),
                                        )
                                      : get(data, column.name)}
                                  </div>
                                </TableCell>
                              ),
                            )}
                          </TableRow>
                        )
                      },
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions style={{ justifyContent: 'flex-end' }}>
            <Button variant="outlined" onClick={handleFindMenuClose}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={checkForRealeflowDups}
            >
              Import
            </Button>
          </DialogActions>
        </BlockUi>
      </Dialog>

      <Dialog
        open={openSearchWarning}
        onClose={() => setOpenSearchWarning(false)}
      >
        <DialogContent>
          <DialogContentText>
            Warning! Searching again will reset your list of approved
            and rejected comps. Do you wish to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setOpenSearchWarning(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              triggerRealeflowSearch()
              setOpenSearchWarning(false)
            }}
          >
            Search
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openNoCompWarning}
        onClose={() => setOpenNoCompWarning(false)}
      >
        <DialogContent>
          <DialogContentText>
            Warning! You have not selected any comps to import. Please
            press the thumb up in order to select a comp
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setOpenNoCompWarning(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDuplicateWarning}
        onClose={() => setOpenDuplicateWarning(false)}
      >
        <DialogContent>
          <DialogContentText>
            Warning! You already have {duplicateComps?.length} comps
            that are duplicates of those you selected. Do you want to
            add them as duplicates or overwrite the existing comps?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setOpenDuplicateWarning(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              deleteDuplicates()
              importRealeflowComps()
              setOpenDuplicateWarning(false)
            }}
          >
            Overwrite
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              importRealeflowComps()
              setDuplicateComps([])
              setOpenDuplicateWarning(false)
            }}
          >
            Duplicate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withSession(CenterPageComps)
