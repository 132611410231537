import React, { useState } from 'react'
import round from 'lodash/round'
import concat from 'lodash/concat'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Button,
  Card,
  Divider,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
} from '@mui/material'

import map from 'lodash/map'
import includes from 'lodash/includes'
import intersection from 'lodash/intersection'
import remove from 'lodash/remove'

import { CircularProgressbar } from 'react-circular-progressbar'
import withSession from '@/housefolios-components/Session/withSession'
import { useMutation } from '@apollo/client'
import { UPDATE_USER } from '@/housefolios-components/PersonalSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'

function AccountSetupComponent(props) {
  const {} = props
  const hiddenDown = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  )
  const hiddenUp = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'),
  )
  const paragraphs = [
    {
      icon: (
        <FontAwesomeIcon
          icon={['fa', 'check']}
          style={{ color: '#368cff' }}
          className="font-size-xl"
        />
      ),
      title: 'More than 50 lessons',
      description:
        "Each lesson is designed to prepare you for the decisions you'll make as a real estate investor, from choosing a market to picking a property manager.",
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={['fa', 'check']}
          style={{ color: '#368cff' }}
          className="font-size-xl"
        />
      ),
      title: 'More than 45 videos',
      description:
        "You'll enjoy learning at your own pace and at the time you choose by watching videos tailored to each subject covered by the course.",
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={['fa', 'check']}
          style={{ color: '#368cff' }}
          className="font-size-xl"
        />
      ),
      title: '6 Live Weekly Video Calls',
      description:
        'Many people say that these weekly calls are even better than the video lessons because they can bring their own unique questions, learn from other investors, and get unique insights from Jared during live video calls.',
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={['fa', 'check']}
          style={{ color: '#368cff' }}
          className="font-size-xl"
        />
      ),
      title: 'A Private Facebook Community',
      description:
        "You will be enrolled in a group for your specific class as well as a general member group. Both are exclusive to members of the Wealth Watch community. You'll always have place to ask your questions, before, during, and after your course.",
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={['fa', 'check']}
          style={{ color: '#368cff' }}
          className="font-size-xl"
        />
      ),
      title: 'Handouts, Templates, and Resources',
      description:
        "As part of the lessons, there are handouts and templates to download and print to use in your investing activities. You'll also have access to audio-only version of the lessons for listening to while commuting or walking the dog.",
    },
  ]

  return (
    <Card style={{ padding: 25 }}>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h4" style={{ fontWeight: 600 }}>
            The Right Markets, Timing, and People
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            You are about to join a group of like-minded investors
            that want to master the ins and outs of real estate
            investing. You'll be a great fit! We are excited to work
            with you to help you take full control of your real estate
            investing strategies and decisions.{' '}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" style={{ fontStyle: 'italic' }}>
            You're about to gain access to:
          </Typography>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 10, marginBottom: 30 }} />
      {hiddenDown ? null : (
        <>
          {map(paragraphs, (section) => (
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={2}
                  wrap="nowrap"
                >
                  <Grid item style={{ marginTop: 7 }}>
                    {section.icon}
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: 600 }}
                        >
                          {section.title}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>{section.description}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={1}
            wrap="nowrap"
          >
            <Grid item>
              <img
                src="/images/JaredGLogo.png"
                style={{ height: 80, width: 70, marginTop: 10 }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                href="https://www.jaredgarfield.com"
                target="_blank"
                style={{ marginTop: 10 }}
              >
                Wealth Watch Home
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {hiddenUp ? null : (
        <>
          {map(paragraphs, (section) => (
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: 600 }}
                    >
                      {section.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{section.description}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={1}
            wrap="nowrap"
          >
            <Grid item>
              <img
                src="/images/JaredGLogo.png"
                style={{ height: 70, width: 60, marginTop: 10 }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                href="https://www.jaredgarfield.com"
                target="_blank"
              >
                <Typography style={{ whiteSpace: 'nowrap' }}>
                  Wealth Watch Home
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  )
}

export default withSession(AccountSetupComponent)
