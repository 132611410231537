/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-02-02 12:03:47
 * @ Description: Component displayed when all properties are filtered out
 */

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function AllPropertiesFiltered() {

  return (
    <div className="text-center p-5">
      <div className="avatar-icon-wrapper rounded-circle m-0">
        <div style={{ backgroundColor: '#cfe1fa', color: '#008aff' }} className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130">
          <FontAwesomeIcon
            icon={['fas', 'house-chimney']}
            className="d-flex align-self-center display-3 "
          />
        </div>
      </div>
      <h4 className="font-weight-bold mt-4">No Results Match...</h4>
      <p className="mb-0 font-size-lg text-muted">
        The active filters are hiding the properties
      </p>
    </div>
  );
}