/**
 * @ Author: Housefolios
 * @ Create Time: 2023-03-03 16:33:10
 * @ Modified by: David Helmick
 * @ Modified time: 2024-11-20 15:09:02
 * @ Description: Dialog for editing images by cropping or rotating
 */

import React, {
  useCallback,
  useRef,
  useState,
  Fragment,
  useEffect,
} from 'react'
import map from 'lodash/map'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import pullAt from 'lodash/pullAt'
import get from 'lodash/get'
import head from 'lodash/head'

import { useMutation } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'

import Gallery from 'react-photo-gallery'
import { arrayMoveImmutable } from 'array-move'
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc'
import { MenuItem, TextField } from '@mui/material'
import { createStyles } from '@mui/styles'
import Cropper from 'react-easy-crop'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import {
  Close,
  Delete,
  DragIndicator,
  Edit,
} from '@mui/icons-material'
import FlipIcon from '@mui/icons-material/Flip'
import {
  Drawer,
  FormControlLabel,
  Grid,
  Tooltip,
  IconButton,
  List,
  ListItemText,
  ListItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Checkbox,
  Card,
  Slider,
} from '@mui/material'
import {
  DELETE_IMAGE,
  DELETE_IMAGE_MULTI,
  UPDATE_PROPERTY,
} from '@/housefolios-components/PropertyContainer/mutations'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { enqueueSnackbar } from 'notistack'
import { IMAGE_UPLOAD } from '@/housefolios-components/TitleButtons/AddPropertyButton/mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
}

function PropertyImages(props) {
  const { imageSrc } = props
  const hostname = import.meta.env.VITE_HOSTNAME

  const [circle, setCircle] = useState(false)

  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(1)
  const [rotation, setRotation] = React.useState(0)
  const [horizontalFlip, setHorizontalFlip] = React.useState(false)
  const [verticalFlip, setVerticalFlip] = React.useState(false)
  const [aspect, setAspect] = React.useState(4 / 3)
  const [croppedAreaPixels, setCroppedAreaPixels] =
    React.useState(null)
  const onCropComplete = React.useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
    },
    [],
  )

  const [replaceImage, setReplaceImage] = useState(false)
  const [resizeImage, setResizeImage] = useState(false)
  const [resizedImage, setResizedImage] = useState(null)

  const changeImage = async (imageSrc) => {
    setResizedImage(await resizeImageFunction(imageSrc))
  }

  useEffect(() => {
    changeImage(imageSrc)
  }, [imageSrc, aspect])

  const handleClose = () => {
    setCrop({ x: 0, y: 0 })
    setZoom(1)
    setRotation(0)
    setHorizontalFlip(false)
    setVerticalFlip(false)
    setCroppedAreaPixels(null)
    setReplaceImage(false)
    setResizeImage(false)
    setResizedImage(null)
    setCircle(false)

    props.handleClose()
  }

  // const showCroppedImage = useCallback(async () => {
  //     try {
  //       const croppedImage = await getCroppedImg(
  //         imageSrc,
  //         croppedAreaPixels,
  //         rotation
  //       )
  //       console.log('donee', { croppedImage })
  //       setCroppedImage(croppedImage)
  //     } catch (e) {
  //       console.error(e)
  //     }
  //   }, [imageSrc, croppedAreaPixels, rotation])

  //   const onClose = useCallback(() => {
  //     setCroppedImage(null)
  //   }, [])

  //   const onFileChange = async (e) => {
  //     if (e.target.files && e.target.files.length > 0) {
  //       const file = e.target.files[0]
  //       let imageDataUrl = await readFile(file)

  //       // apply rotation if needed
  //       const orientation = await getOrientation(file)
  //       const rotation = ORIENTATION_TO_ANGLE[orientation]
  //       if (rotation) {
  //         imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
  //       }

  //       setImageSrc(imageDataUrl)
  //     }
  //   }

  const createImage = (url) => {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', (error) => reject(error))
      image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = url
    })
  }

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
  }

  function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation)

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    }
  }

  async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false },
  ) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      return null
    }

    const rotRad = getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation,
    )

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw rotated image
    ctx.drawImage(image, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
    )

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

    // As Base64 string
    return canvas.toDataURL('image/png')

    // As a blob
    // return new Promise((resolve, reject) => {
    //   canvas.toBlob((file) => {
    //     resolve(URL.createObjectURL(file))
    //   }, 'image/jpeg')
    // })
  }

  const saveCroppedImage = useCallback(async () => {
    setCircle(true)
    try {
      const croppedImage = await getCroppedImg(
        resizeImage ? resizedImage : imageSrc,
        croppedAreaPixels,
        rotation,
        { horizontal: horizontalFlip, vertical: verticalFlip },
      )
      await props.saveImage(croppedImage, replaceImage)
      handleClose()
      setCircle(false)
    } catch (e) {
      console.error(e)
      setCircle(false)
    }
  }, [props.saveImage, croppedAreaPixels, rotation, replaceImage])

  const toggleHorizontalFlip = () => {
    setHorizontalFlip(!horizontalFlip)
    setRotation(360 - rotation)
  }

  const toggleVerticalFlip = () => {
    setVerticalFlip(!verticalFlip)
    setRotation(360 - rotation)
  }

  async function resizeImageFunction(imageSrc) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      return null
    }

    const imageWider = image.width > image.height

    if (imageWider) {
      canvas.width = image.width
      canvas.height = image.width * (1 / aspect)
    } else {
      canvas.width = image.height * aspect
      canvas.height = image.height
    }

    ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
    return canvas.toDataURL()
  }

  return (
    <Dialog
      open={props.open}
      maxWidth="md"
      fullWidth={true}
      onClose={handleClose}
    >
      <BlockUi
        blocking={circle}
        loader={
          <PacmanLoader color={'var(--primary)'} loading={true} />
        }
        message={<div className="text-primary">Editing Image</div>}
      >
        <DialogTitle>
          <Typography>Edit Image</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ position: 'relative', height: 500 }}>
                <div className="crop-container">
                  <Cropper
                    image={resizeImage ? resizedImage : imageSrc}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={aspect}
                    transform={`translate(${crop.x}px, ${
                      crop.y
                    }px) rotate(${rotation}deg) scale(${
                      horizontalFlip ? -zoom : zoom
                    }, ${verticalFlip ? -zoom : zoom})`}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className="controls" style={{ width: '100%' }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            variant="overline"
                            // classes={{ root: classes.sliderLabel }}
                          >
                            Zoom
                          </Typography>
                        </Grid>
                        <Grid item>
                          <input
                            type="range"
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                              setZoom(e.target.value)
                            }}
                            className="zoom-range"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            variant="overline"
                            // classes={{ root: classes.sliderLabel }}
                          >
                            Rotation
                          </Typography>
                        </Grid>
                        <Grid item>
                          <input
                            type="range"
                            value={rotation}
                            min={0}
                            max={360}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                              setRotation(e.target.value)
                            }}
                            // className="zoom-range"
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip title={'Flip Image Horizontally'}>
                            <IconButton
                              onClick={toggleHorizontalFlip}
                              size="large"
                            >
                              <FlipIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip title={'Flip Image Vertically'}>
                            <IconButton
                              style={{ rotate: '90deg' }}
                              onClick={toggleVerticalFlip}
                              size="large"
                            >
                              <FlipIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <TextField
                        name="aspect"
                        fullWidth
                        select
                        variant="outlined"
                        label="Aspect Ratio"
                        value={aspect}
                        onChange={(event) =>
                          setAspect(event.target.value)
                        }
                        style={{ width: '200px' }}
                        SelectProps={{
                          MenuProps: { style: { width: '100%' } },
                        }}
                      >
                        <MenuItem key={'4:3'} value={4 / 3}>
                          {'4:3'}
                        </MenuItem>
                        <MenuItem key={'16:9'} value={16 / 9}>
                          {'16:9'}
                        </MenuItem>
                        <MenuItem key={'Square'} value={1}>
                          {'Square'}
                        </MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {props.replaceOption && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={replaceImage}
                  onClick={() => setReplaceImage(!replaceImage)}
                  //value="checked1"
                  color="primary"
                />
              }
              style={{ marginLeft: 5 }}
              label="Replace Image"
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={resizeImage}
                onClick={() => setResizeImage(!resizeImage)}
                //value="checked1"
                color="primary"
              />
            }
            style={{ marginLeft: 5 }}
            label="Resize Image to Aspect Ratio"
          />
          <Typography style={{ flex: 1 }} />
          <Button onClick={handleClose}>
            <Typography>Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={saveCroppedImage}
          >
            Save
          </Button>
        </DialogActions>
      </BlockUi>
    </Dialog>
  )
}

export default withSession(PropertyImages)
