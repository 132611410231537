import { gql } from '@apollo/client'

export const GET_NOTIFICATIONS = gql`
  query notifications {
    notifications {
      _id
      orgId
      userId
      action
      actionDateTime
      old
      system
      actionLink
      actionLinkTarget
      attachment
    }
  }
`
