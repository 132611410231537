/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-12 13:45:24
 * @ Description: Footer of the sign in page
 */

import React, { useState } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import YouTubeIcon from '@mui/icons-material/YouTube'

import get from 'lodash/get'

import logo from '@/assets/images/NewLogoBlue.png'

import {
  Button,
  Grid,
  Hidden,
  List,
  Link,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
} from '@mui/material'
import useAffiliate from '@/hooks/useAffiliate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContactDialog from '@/housefolios-components/Dialogs/ContactSupport/'

export default function SignInFooterComponent(props) {
  const hiddenSmDown = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  )
  const hiddenSmUp = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'),
  )

  const [openContact, setOpenContact] = useState(false)

  function getCookie(cName) {
    const name = cName + '='
    const cDecoded = decodeURIComponent(document.cookie) //to be careful
    const cArr = cDecoded.split('; ')
    let res
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length)
    })
    return res
  }

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  let source = urlParams.get('source')
  let sourceCookie = getCookie('SourceSignUp')
  const [{ affiliate, affiliateLoading, urlAffiliate }] =
    useAffiliate({
      memberValue:
        sourceCookie === 'null' ? source : sourceCookie || source,
    })

  return (
    <>
      <Grid item className="w-100">
        {hiddenSmUp ? null : (
          <Grid
            container
            className="w-100"
            direction="row"
            style={{
              backgroundColor: '#253152',
              padding: 50,
              position: 'relative',
              marginTop: 25,
            }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={3}
                style={{ minHeight: '100vh', padding: '20px' }} // Optional: Adjusts height and padding
              >
                <Grid
                  item
                  style={{
                    display: 'flex',
                    flexDirection: 'column', // Stack logo and button vertically
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  <img
                    src={
                      get(
                        affiliate,
                        'sidebar.sidebarMemberFullImage.0.url',
                      ) || logo
                    }
                    alt="Housefolios"
                    style={{
                      height: 40,
                      display: 'block',
                      margin: 'auto',
                    }}
                  />
                </Grid>

                {!urlAffiliate && (
                  <Grid item>
                    <Typography
                      style={{
                        color: '#ffffff',
                        textAlign: 'center',
                      }}
                    >
                      Helping real estate investors find, fund, and
                      manage single-family residential portfolios to
                      grow lasting wealth.
                    </Typography>
                  </Grid>
                )}

                {urlAffiliate && (
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      onClick={() => setOpenContact(true)}
                      style={{ color: '#1976d2' }} // Optional: Style for the Contact Support button
                    >
                      Contact Support
                    </Button>
                  </Grid>
                )}

                <Grid item>
                  <Grid container direction="row">
                    {!urlAffiliate && (
                      <Grid item xs={6}>
                        <Grid
                          container
                          direction="column"
                          alignItems="flex-start"
                        >
                          <Grid item>
                            <Typography
                              variant="h6"
                              style={{ color: '#ffffff' }}
                            >
                              Get Started
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Link
                              href="/createAccount"
                              style={{ color: '#cfcfcf' }}
                            >
                              Sign up
                            </Link>
                          </Grid>
                          <Grid item>
                            <Link
                              href="/signin"
                              target="blank"
                              style={{ color: '#cfcfcf' }}
                            >
                              Login
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {/* <Grid item xs={6}>
                    <Grid container direction='column' alignItems='flex-start'>
                      <Grid item>
                        <Link variant='h6' style={{ color: '#ffffff' }}>About</Link>
                      </Grid> 
                      <Grid item>
                        <Link style={{ color: '#cfcfcf' }}>
                          Our Mission
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link style={{ color: '#cfcfcf' }}>
                          Blog
                        </Link>
                      </Grid><Grid item>
                        <Link style={{ color: '#cfcfcf' }}>
                          Brand Assets
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link style={{ color: '#cfcfcf' }}>
                          Partnerships
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                  >
                    {/* <Grid item>
                <Link variant='h6' style={{ color: '#ffffff' }}>Social Media</Link>
              </Grid> */}
                    {!urlAffiliate && (
                      <Grid item>
                        <List
                          component="div"
                          className="justify-content-center justify-content-xl-start"
                        >
                          <Button
                            className="btn-linkedin btn-icon btn-animated-icon-lg d-30 p-0 mr-2"
                            target="_blank"
                            href="https://www.linkedin.com/company/housefolios/"
                            onClick={(e) => e.preventDefault()}
                          >
                            <span className="btn-wrapper--icon">
                              <LinkedInIcon
                                style={{ color: '#cfcfcf' }}
                                className="font-size-lg"
                              />
                            </span>
                          </Button>
                          <Button
                            className="btn-facebook btn-icon btn-animated-icon-lg d-30 p-0 mr-2"
                            target="_blank"
                            href="https://www.facebook.com/Housefolios/"
                            onClick={(e) => e.preventDefault()}
                          >
                            <span className="btn-wrapper--icon">
                              <FacebookIcon
                                style={{ color: '#cfcfcf' }}
                                className="font-size-lg"
                              />
                            </span>
                          </Button>
                          <Button
                            className="btn-instagram btn-icon btn-animated-icon-lg d-30 p-0 mr-2"
                            target="_blank"
                            href="https://www.instagram.com/housefolios/"
                            onClick={(e) => e.preventDefault()}
                          >
                            <span className="btn-wrapper--icon">
                              <InstagramIcon
                                style={{ color: '#cfcfcf' }}
                                className="font-size-lg"
                              />
                            </span>
                          </Button>
                          <Button
                            className="btn-youtube btn-icon btn-animated-icon-lg d-30 p-0 mr-2"
                            target="_blank"
                            href="https://www.youtube.com/channel/UCrtQVie1gdD6-8puYRM0Auw"
                            onClick={(e) => e.preventDefault()}
                          >
                            <span className="btn-wrapper--icon">
                              <YouTubeIcon
                                style={{ color: '#cfcfcf' }}
                                className="font-size-lg"
                              />
                            </span>
                          </Button>
                          <Button
                            className="btn-twitter btn-icon btn-animated-icon-lg d-30 p-0 mr-2"
                            target="_blank"
                            href="https://twitter.com/housefolios"
                            onClick={(e) => e.preventDefault()}
                          >
                            <span className="btn-wrapper--icon">
                              <TwitterIcon
                                style={{ color: '#cfcfcf' }}
                                className="font-size-lg"
                              />
                            </span>
                          </Button>
                        </List>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography style={{ color: '#cfcfcf' }}>
                    Copyright {new Date().getFullYear()} © by
                    {(affiliate?.companyName === 'Deal Sauce (Subto)'
                      ? 'Deal Sauce'
                      : affiliate?.companyName) || 'Housefolios'}{' '}
                    Inc. All rights Reserved.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {hiddenSmDown ? null : (
          <Grid
            container
            className="w-100"
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            style={{ backgroundColor: '#253152', padding: 50 }}
          >
            <Grid item style={{ maxWidth: 300 }}>
              <Grid
                container
                direction="column"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid
                  item
                  style={{
                    display: 'flex',
                    flexDirection: 'column', // Stack logo and button vertically
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  <img
                    src={
                      get(
                        affiliate,
                        'sidebar.sidebarMemberFullImage.0.url',
                      ) || logo
                    }
                    alt="Housefolios"
                    style={{
                      height: 40,
                      display: 'block',
                      margin: 'auto',
                    }}
                  />
                  {!urlAffiliate && (
                    <Grid item>
                      <Typography
                        style={{ color: '#ffffff', paddingTop: 20 }}
                      >
                        Helping real estate investors find, fund, and
                        manage single-family residential portfolios to
                        grow lasting wealth.
                      </Typography>
                    </Grid>
                  )}
                  {urlAffiliate && (
                    <Button onClick={() => setOpenContact(true)}>
                      Contact Support
                    </Button>
                  )}{' '}
                </Grid>
              </Grid>
            </Grid>
            {!urlAffiliate && (
              <Grid
                item
                style={{ paddingLeft: 100, paddingRight: 100 }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Typography
                      variant="h6"
                      style={{ color: '#ffffff' }}
                    >
                      Get Started
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Link
                      href="/createAccount"
                      style={{ color: '#cfcfcf' }}
                    >
                      Sign up
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      href="/signin"
                      target="blank"
                      style={{ color: '#cfcfcf' }}
                    >
                      Login
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* <Grid item>
            <Grid container direction='column' alignItems='flex-start'>
              <Grid item>
                <Link variant='h6' style={{ color: '#ffffff' }}>About</Link>
              </Grid> 
              <Grid item>
                <Link style={{ color: '#cfcfcf' }}>
                  Our Mission
                </Link>
              </Grid>
              <Grid item>
                <Link style={{ color: '#cfcfcf' }}>
                  Blog
                </Link>
              </Grid><Grid item>
                <Link style={{ color: '#cfcfcf' }}>
                  Brand Assets
                </Link>
              </Grid>
              <Grid item>
                <Link style={{ color: '#cfcfcf' }}>
                  Partnerships
                </Link>
              </Grid>
            </Grid>
          </Grid> */}
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems="flex-start"
              >
                {/* <Grid item>
                <Link variant='h6' style={{ color: '#ffffff' }}>Social Media</Link>
              </Grid> */}
                {!urlAffiliate && (
                  <Grid item>
                    <List
                      component="div"
                      className="justify-content-center justify-content-xl-start"
                    >
                      <Button
                        className="btn-linkedin btn-icon btn-animated-icon-lg d-30 p-0 mr-2"
                        target="_blank"
                        href="https://www.linkedin.com/company/housefolios/"
                      >
                        <span className="btn-wrapper--icon">
                          <LinkedInIcon
                            style={{ color: '#cfcfcf' }}
                            className="font-size-lg"
                          />
                        </span>
                      </Button>
                      <Button
                        className="btn-facebook btn-icon btn-animated-icon-lg d-30 p-0 mr-2"
                        target="_blank"
                        href="https://www.facebook.com/Housefolios/"
                      >
                        <span className="btn-wrapper--icon">
                          <FacebookIcon
                            style={{ color: '#cfcfcf' }}
                            className="font-size-lg"
                          />
                        </span>
                      </Button>
                      <Button
                        className="btn-instagram btn-icon btn-animated-icon-lg d-30 p-0 mr-2"
                        target="_blank"
                        href="https://www.instagram.com/housefolios/"
                      >
                        <span className="btn-wrapper--icon">
                          <InstagramIcon
                            style={{ color: '#cfcfcf' }}
                            className="font-size-lg"
                          />
                        </span>
                      </Button>
                      <Button
                        className="btn-youtube btn-icon btn-animated-icon-lg d-30 p-0 mr-2"
                        target="_blank"
                        href="https://www.youtube.com/channel/UCrtQVie1gdD6-8puYRM0Auw"
                      >
                        <span className="btn-wrapper--icon">
                          <YouTubeIcon
                            style={{ color: '#cfcfcf' }}
                            className="font-size-lg"
                          />
                        </span>
                      </Button>
                      <Button
                        className="btn-twitter btn-icon btn-animated-icon-lg d-30 p-0 mr-2"
                        target="_blank"
                        href="https://twitter.com/housefolios"
                      >
                        <span className="btn-wrapper--icon">
                          <TwitterIcon
                            style={{ color: '#cfcfcf' }}
                            className="font-size-lg"
                          />
                        </span>
                      </Button>
                    </List>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 15 }}>
              <Typography
                style={{ color: '#cfcfcf', textAlign: 'center' }}
              >
                Copyright {new Date().getFullYear()} © by{' '}
                {(affiliate?.companyName === 'Deal Sauce (Subto)'
                  ? 'Deal Sauce'
                  : affiliate?.companyName) || 'Housefolios'}{' '}
                Inc. All rights Reserved.
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <ContactDialog
        open={openContact}
        handleClose={() => setOpenContact(false)}
        affiliate={affiliate?.memberValue}
      />
    </>
  )
}
