import { gql } from '@apollo/client'

export const UPDATE_USER = gql`
  mutation updateUser($id: ID, $userInput: userInput!) {
    updateUser(id: $id, userInput: $userInput) {
      user {
        _id
        email
        username
        profile {
          firstname
          lastname
        }
        preferences
        roles {
          organization {
            _id
            name
          }
          role
        }
        phone
        userType
        jobTitle
        bioDescription
        activeOrg {
          _id
          name
        }
        setup
        address
        address2
        city
        state
        zip
        DoB
        citizenship
        FICO
        firstTimeInvestor
        yearsInvesting
        realEstateInvestments
        estimatedNetWorth
        estimatedLiquidAssets
        creditReport
        scheduleOfRealEstate
        personalFinancialStatement
      }
      token
    }
  }
`
