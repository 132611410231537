import React from 'react'

import { ExampleWrapperSeamless } from '@/layout-components'

import ForgotPassword from '@/housefolios-components/SignIn/ForgotPassword'

import { Grid } from '@mui/material'

export default function ForgotPasswordPage() {
  return (
    <>
      <ExampleWrapperSeamless>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} className="d-flex w-100">
            <ForgotPassword />
          </Grid>
        </Grid>
      </ExampleWrapperSeamless>
    </>
  )
}
