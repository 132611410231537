/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2024-11-21 19:37:45
 * @ Description: cards with comp information for card view
 */

import React, { useEffect, useState } from 'react'

import includes from 'lodash/includes'
import map from 'lodash/map'
import merge from 'lodash/merge'
import replace from 'lodash/replace'
import compact from 'lodash/compact'
import slice from 'lodash/slice'

import Slider1 from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Avatar,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Slider,
  Typography,
  IconButton,
} from '@mui/material'

import { UPDATE_COMPPROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_COMPPROPERTIES } from '@/housefolios-components/PropertyContainer/queries'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { useMutation } from '@apollo/client'
import withSession from '@/housefolios-components/Session/withSession'

var months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

function SliderArrowNext(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  )
}

function SliderArrowPrev(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  )
}

function CenterPageCompsCardComponent(props) {
  const { openEdit, comp, property } = props

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const moneysqftFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const widgetsCarousels1B = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
  }

  const [inputAdjustment, setInputAdjustment] = useState(
    comp.priceAdjustment,
  )

  useEffect(() => {
    setInputAdjustment(comp.priceAdjustment)
  }, [comp])

  const [updateCompProperty] = useMutation(UPDATE_COMPPROPERTY)

  const onChangeSlider = (
    event,
    value,
    name,
    updateCompProperty = null,
  ) => {
    setInputAdjustment(value)
    if (updateCompProperty) {
      updateCompProperty({
        variables: {
          id: comp._id,
          compPropertyInput: {
            priceAdjustment: Number(value),
          },
        },
        refetchQueries: [
          {
            query: GET_COMPPROPERTIES,
            variables: { propertyId: property._id },
          },
        ],
      })
    }
  }

  const date = new Date(comp.saleDate)
  let createdAt = new Date(comp.createdAt)
  let createdAtYear = createdAt.getFullYear()
  let createdAtMonth = createdAt.getMonth() + 1
  let createdAtDay = createdAt.getDate()
  createdAt =
    createdAtMonth + '/' + createdAtDay + '/' + createdAtYear

  let createdAt2 = new Date()
  createdAt2.setMonth(createdAt2.getMonth() - 12)

  return (
    <Grid
      item
      xs={12}
      lg={6}
      xl={4}
      className="mb-spacing-6 d-flex w-100"
    >
      <div
        className="card card-box-hover-rise"
        style={{ backgroundColor: 'white', width: '100%' }}
      >
        <div
          className="card-badges"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'transparent',
          }}
        >
          <Grid container spacing={1} style={{ textAlign: 'right' }}>
            {comp.saleDate && (
              <Grid item>
                {/* <div className="badge badge-pill" style={{ backgroundColor: '#ff3f3d', color: 'white' }}>Sold {months[date.getMonth()] + ' ' + date.getFullYear()}</div> */}
                <div
                  className="badge badge-pill"
                  style={{
                    color: 'white',
                    backgroundColor:
                      createdAt2.getTime() > date.getTime() &&
                      comp.potential
                        ? '#f4772e'
                        : '#2f3f4d',
                  }}
                >
                  Sold{' '}
                  {months[date.getMonth()] + ' ' + date.getFullYear()}
                </div>
              </Grid>
            )}
          </Grid>
        </div>
        <Slider1
          className="card-img-top slider-dots"
          {...widgetsCarousels1B}
        >
          {map(slice(comp.images, 0, 5), (image) => (
            <div
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <Card
                className="shadow-none"
                style={{
                  height: 325,
                  backgroundColor: 'white',
                  margin: 1,
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  style={{ height: '100%' }}
                >
                  <Grid item>
                    <div
                      className="card-img-wrapper"
                      onClick={
                        props.shared ? null : () => openEdit(comp)
                      }
                      style={{
                        textAlign: 'center',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <div
                        className="blur-load"
                        style={{
                          backgroundImage:
                            'url(' + image.url_small + ')',
                          height: 325,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={image.url}
                          className="card-img-top rounded"
                          style={{
                            maxHeight: 325,
                            height: 'auto',
                            borderBottomRightRadius: '0px',
                            objectFit: 'contain',
                          }}
                          alt="..."
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </div>
          ))}
          {compact(comp.images).length < 5 && (
            <div
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <Card
                className="shadow-none"
                style={{
                  height: 325,
                  backgroundColor: 'white',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  margin: 1,
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  style={{ height: '100%' }}
                >
                  <a
                    href={
                      props?.session?.me?.activeOrg?.isRealeflow
                        ? ''
                        : `https://www.comehome.com/property-details/${replace(
                            comp.address,
                            /\s/g,
                            '-',
                          )}-${replace(comp.city, /\s/g, '-')}-${
                            comp.state
                          }-${comp.zip}?modal=photo-list`
                    }
                    target="_blank"
                    onClick={(event) => event.stopPropagation()}
                    className="card-img-wrapper"
                    style={{
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Grid
                      item
                      onClick={(event) => {
                        event.preventDefault()
                        props?.session?.me?.activeOrg?.isRealeflow
                          ? ''
                          : window.open(
                              `https://www.comehome.com/property-details/${replace(
                                comp.address,
                                /\s/g,
                                '-',
                              )}-${replace(comp.city, /\s/g, '-')}-${
                                comp.state
                              }-${comp.zip}?modal=photo-list`,
                              '_blank',
                            )
                      }}
                    >
                      <img
                        src={
                          props?.session?.me?.activeOrg?.member ===
                            'pacemorby' ||
                          props?.session?.me?.activeOrg?.member ===
                            'astroflip'
                            ? '/images/DealSauce-Logo-Tan.png'
                            : props?.session?.me?.activeOrg
                                  ?.member === 'pep'
                              ? '/images/pep-icon-only.png'
                              : '/images/comehome_small.png'
                        }
                        className="card-img-top"
                        style={{
                          maxHeight: 500,
                          maxWidth: 500,
                          height: 'auto',
                          width: 'auto',
                          borderBottomRightRadius: '0px',
                          objectFit: 'contain',
                        }}
                        alt="..."
                      />
                    </Grid>
                  </a>
                </Grid>
              </Card>
            </div>
          )}
        </Slider1>
        <div
          className="card-tr-actions"
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'transparent',
          }}
        >
          <Grid container spacing={1} style={{ textAlign: 'left' }}>
            {!props.shared && (
              <Grid item>
                <IconButton
                  onClick={props.selectComp(comp._id)}
                  size="large"
                >
                  <Checkbox
                    checked={includes(props.selected, comp._id)}
                    style={{
                      height: 20,
                      width: 20,
                      backgroundColor: 'white',
                      borderRadius: 0,
                    }}
                  />
                </IconButton>
              </Grid>
            )}
            <Grid
              item
              onClick={props.shared ? null : () => openEdit(comp)}
            >
              <Avatar
                className={props.potential ? 'bg-warning' : null}
                style={{
                  height: 30,
                  width: 30,
                  backgroundColor: props.potential ? null : '#2f3f4d',
                }}
              >
                {props.index + 1}
              </Avatar>
            </Grid>
          </Grid>
        </div>
        {/* <div className="card-tr-actions">
          <Button className="btn-link p-0 font-size-xl" variant="text">
            <FontAwesomeIcon icon={['far', 'square']} className="font-size-lg" />
          </Button>
        </div> */}
        <CardContent
          onClick={props.shared ? null : () => openEdit(comp)}
        >
          <Grid container direction="column">
            <Grid item>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>
                  <Typography
                    style={{ fontSize: 26, fontWeight: 600 }}
                  >
                    {currencyFormat.format(comp.estval)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: 16 }}>
                    {comp.beds} bd | {comp.baths} ba |{' '}
                    {numberFormat.format(comp.sqft)} sqft |{' '}
                    {comp.year} yr
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>
                  <Typography
                    style={{ fontSize: 16, fontWeight: 600 }}
                    noWrap={true}
                  >
                    {comp.address}, {comp.city}, {comp.state}{' '}
                    {comp.zip}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography className="font-size-sm">
                Date Added: {createdAt}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="font-size-sm">
                Last Sold Date:{' '}
                {comp.saleDate
                  ? new Date(comp.saleDate).toLocaleDateString(
                      'en-US',
                    )
                  : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item style={{ marginBottom: 10, marginTop: 10 }}>
            <Card style={{ backgroundColor: '#eff2f5' }}>
              <CardContent style={{ padding: 10 }}>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          style={{
                            whiteSpace: 'nowrap',
                            color: '#949798',
                            fontWeight: 600,
                          }}
                        >
                          Adjustment
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            whiteSpace: 'nowrap',
                            color: '#008bff',
                            fontWeight: 600,
                          }}
                        >
                          {currencyFormat.format(
                            comp.estval *
                              (comp.priceAdjustment / 100),
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          style={{
                            whiteSpace: 'nowrap',
                            color: '#949798',
                            fontWeight: 600,
                          }}
                        >
                          $/sqft
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            whiteSpace: 'nowrap',
                            fontWeight: 600,
                          }}
                        >
                          {comp.sqft
                            ? moneysqftFormat.format(
                                (comp.estval *
                                  (1 + comp.priceAdjustment / 100)) /
                                  comp.sqft,
                              )
                            : '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          style={{
                            whiteSpace: 'nowrap',
                            color: '#949798',
                            fontWeight: 600,
                          }}
                        >
                          Distance
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            whiteSpace: 'nowrap',
                            fontWeight: 600,
                            color:
                              parseFloat(
                                merge(
                                  DEFAULT_PREFERENCES,
                                  props.session.me
                                    ? props.session.me.activeOrg
                                        .preferences
                                    : {},
                                ).findComps.maxDistance,
                              ) < comp.distance &&
                              comp.potential &&
                              '#f4772e',
                          }}
                        >
                          {numberFormat.format(comp.distance)} miles
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: -30 }}>
            Adjust Comp
          </Grid>
          <Grid
            item
            md={12}
            style={{ marginTop: 5 }}
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <Slider
              className="slider-first my-3"
              color="#008bff"
              value={inputAdjustment}
              onChange={
                props.shared
                  ? null
                  : (event, value) => {
                      onChangeSlider(event, value, 'priceAdjustment')
                    }
              }
              onChangeCommitted={
                props.shared
                  ? null
                  : (event, value) => {
                      onChangeSlider(
                        event,
                        value,
                        'priceAdjustment',
                        updateCompProperty,
                      )
                    }
              }
              marks={true}
              track={false}
              valueLabelDisplay="auto"
              reverse
              min={-15}
              max={15}
              step={2.5}
              valueLabelFormat={(x) => `${x}%`}
              style={{
                width: '88%',
                marginLeft: 18,
              }}
            />
          </Grid>
          {/* <Grid item style={{ marginTop: -20 }}>
            <Grid container direction='row' alignItems='center' justifyContent='space-between' wrap='nowrap'>
              <Grid item>
                <Typography>Worse</Typography>
              </Grid>
              <Grid item>
                <Typography>Same</Typography>
              </Grid>
              <Grid item>
                <Typography>Better</Typography>
              </Grid>
            </Grid>
          </Grid> */}
        </CardContent>
      </div>
    </Grid>
  )
}

export default withSession(CenterPageCompsCardComponent)
