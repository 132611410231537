/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: David Helmick
 * @ Modified time: 2022-04-18 18:40:46
 * @ Description: Bulk delete comps button and dialog
 */

import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
} from '@mui/material'

// import DeleteIcon from '@mui/icons-material/Delete';
// import { useMutation } from '@apollo/client';
// import { DELETE_PORTFOLIO } from '../mutations';
// import { GET_PORTFOLIOS } from '@/housefolios-pages/Launchpad/queries';
// import { GET_ME } from '@/housefolios-components/Session/queries';
import { enqueueSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { DELETE_COMP_MULTI } from '@/housefolios-components/PropertyContainer/mutations'
import {
  GET_COMPPROPERTIES,
  GET_PROPERTY,
} from '@/housefolios-components/PropertyContainer/queries'

function DeleteCompsComponent(props) {
  const { bulkActionsNotifications } = props
  const [openDeletePortfolio, setDeletePortfolio] = useState(false)
  const [loader, setLoader] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const handleOpenDeleteProperties = () => {
    setDeletePortfolio(true)
    setConfirm(false)
  }
  const handleCloseDeleteProperties = () => {
    setDeletePortfolio(false)
    setLoader(false)
  }

  const [deleteCompProperties] = useMutation(DELETE_COMP_MULTI)

  const handleDelete = () => {
    setLoader(true)
    deleteCompProperties({
      awaitRefetchQueries: true,
      variables: { ids: props.selected },
      refetchQueries: [
        {
          query: GET_PROPERTY,
          variables: { id: props.propertyId },
        },
        {
          query: GET_COMPPROPERTIES,
          variables: { propertyId: props.propertyId },
        },
      ],
    })
      .then(() => {
        enqueueSnackbar(`Selected Comps Deleted`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        handleCloseDeleteProperties()
        if (props.setSelected) props.setSelected([])
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to Delete Selected Comps`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseDeleteProperties()
      })
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  return (
    <>
      {bulkActionsNotifications && (
        <Tooltip title="Bulk Delete">
          <IconButton
            onClick={handleOpenDeleteProperties}
            size="large"
          >
            <FontAwesomeIcon
              icon={['far', 'trash-can']}
              style={{ color: 'white' }}
            />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={openDeletePortfolio}
        onClose={handleCloseDeleteProperties}
        classes={{ paper: 'shadow-lg rounded' }}
        onKeyDown={stopPropagationForTab}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={<div className="text-primary">Deleting Comp</div>}
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                <FontAwesomeIcon
                  icon={['far', 'trash-can']}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">Delete Comps?</h4>
            <p className="mb-0 font-size-lg text-muted">
              <FormControl component="fieldset" className="pr-4">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirm}
                        onChange={() => setConfirm(!confirm)}
                      />
                    }
                    label="Delete these comps. This action can't be undone"
                  />
                </FormGroup>
              </FormControl>
            </p>
            <div className="pt-4">
              <Button
                variant="text"
                onClick={handleCloseDeleteProperties}
              >
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: '#f83145', color: 'white' }}
                onClick={handleDelete}
                disabled={!confirm}
              >
                <span className="btn-wrapper--label">Delete</span>
              </Button>
            </div>
          </div>
        </BlockUi>
      </Dialog>
    </>
  )
}

export default DeleteCompsComponent
