/**
 * @ Author: Housefolios
 * @ Create Time: 2024-05-31 17:46:01
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-04-02 09:27:53
 * @ Description: Dialog for setting Pita data fields
 */

import React, { useEffect, useMemo, useRef, useState } from 'react'

import clsx from 'clsx'

import map from 'lodash/map'
import round from 'lodash/round'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import padStart from 'lodash/padStart'
import head from 'lodash/head'
import find from 'lodash/find'
import slice from 'lodash/slice'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import replace from 'lodash/replace'
import isUndefined from 'lodash/isUndefined'
import debounce from 'lodash/debounce'
import sum from 'lodash/sum'
import unset from 'lodash/unset'
import set from 'lodash/set'
import get from 'lodash/get'
import sumBy from 'lodash/sumBy'

import GaugeChart from 'react-gauge-chart'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import RemoveIcon from '@mui/icons-material/Remove'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import ImageIcon from '@mui/icons-material/Image'
import RoomIcon from '@mui/icons-material/Room'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'

import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import withSession from '@/housefolios-components/Session/withSession'
import { enqueueSnackbar } from 'notistack'
import { NumericFormat } from 'react-number-format'
import { PacmanLoader } from 'react-spinners'
import BlockUi from 'react-block-ui'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import Autocomplete from '@mui/material/Autocomplete'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import {
  GET_COMPPROPERTIES,
  GET_PROPERTY,
} from '@/housefolios-components/PropertyContainer/queries'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_ASSUMPTIONS } from '@/housefolios-components/AssumptionsSettings/quries'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import renderInputFieldsFunction from '@/housefolios-components/Functions/renderInput'
import {
  Add,
  Compare,
  ExpandLess,
  ExpandMore,
  Tune,
} from '@mui/icons-material'
import useAffiliate from '@/hooks/useAffiliate'
import PropTypes from 'prop-types'
import { Check, InfoOutlined, Info } from '@mui/icons-material'

import {
  filterPreferedStrategies,
  getStrategyName,
} from '@/utils/stratagies'
import { currencyFormat, percentageFormat } from '@/utils/number'
import DisplayRealeflowData from '../../DisplayRealeflowData'
import {
  calculatePITI,
  ContactInformation,
  getMortgage,
  getPitiDataObject,
} from '@/utils/loanBalance'
import useEstimatesMenu from '@/hooks/useEstimatesMenu'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import { useNavigate } from 'react-router-dom'
import CommentsDialog from '../../Comments'

function StepIcon(props) {
  const { active, completed } = props

  const icons = {
    1: 1,
    2: 2,
    3: 3,
  }

  return (
    <div
      className={clsx(
        'd-50 transition-base d-flex align-items-center bg-gray-400 justify-content-center rounded',
        {
          'd-80 bg-primary text-white shadow-primary-sm': active,
          'd-50 bg-success text-white shadow-success-sm': completed,
        },
      )}
    >
      {completed ? (
        <Check className="completed" />
      ) : (
        icons[String(props.icon)]
      )}
    </div>
  )
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}

function getSteps() {
  // return ['PITI', 'Exit Strategy']
  return ['PITI']
}

const gaugeFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
})

function SubtoPITIDialog(props) {
  const { open, onClose, propertyId, address, city, state, zipcode } =
    props
  const [analysisInput, setAnalysisInput] = useState({})
  const [assumptionsInput, setAssumptionsInput] = useState({})
  const [pitiData, setPitiData] = useState({})
  const [pitiAnalytics, setPitiAnalytics] = useState({})
  const [activeUnit, setActiveUnit] = useState(0)
  const [financingTab, setFinancingTab] = useState('HML')

  const [loader, setLoader] = useState(false)

  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  let navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  const [{ affiliate, urlAffiliate }] = useAffiliate({})

  const [callProperty, results] = useLazyQuery(GET_PROPERTY, {
    variables: { id: propertyId },
  })
  let { property = {} } = results.data || {}
  const propertyLoading = results.loading || !results.called
  const propertyError = results.error

  const { acquisition = {}, assumptions = {} } = property
  const { analysis = {}, analytics = {} } = acquisition || {}

  const parseDateString = (dateString) => {
    const year = dateString.substring(0, 4)
    const month = dateString.substring(4, 6)
    const day = dateString.substring(6, 8)
    const date = new Date(year, month - 1, day)
    return date
  }

  // Function to format a Date object to 'YYYY-MM-DD' format
  const formatDate = (input) => {
    let date
    // Check if the input is a string
    if (typeof input === 'string') {
      // Convert the string to a Date object
      date = new Date(input)
    } else if (input instanceof Date) {
      // If it's already a Date object, use it directly
      date = input
    } else {
      // If the input is neither a string nor a Date object, return an error or handle it
      throw new Error(
        'Invalid input: Expected a Date object or a valid date string.',
      )
    }

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  useEffect(() => {
    setActiveUnit(0)
    setFinancingTab('HML')
    if (props.open && props.propertyId && !results.called) {
      callProperty()
    } else if (!props.open || !props.propertyId) {
      setPitiData({})
      setPitiAnalytics({})
      setAnalysisInput({})
      setAssumptionsInput({})
      setLoader(false)
    }
  }, [props.open])

  useEffect(() => {
    let propertyTaxes = includes(
      property?.realeflowData?.TaxAmount ||
        property?.realeflowData?.SubjectProperty?.TaxAmount,
      '$',
    )
      ? property?.realeflowData?.TaxAmount ||
        property?.realeflowData?.SubjectProperty?.TaxAmount
      : Number(
          property?.realeflowData?.TaxAmount ||
            property?.realeflowData?.SubjectProperty?.TaxAmount,
        ) / 100
    let financeAmount = Number(
      get(property, 'realeflowData.LoanAmount') ||
        get(property, 'realeflowData.SubjectProperty.TotalLoans') ||
        0,
    )
    let monthlyRate =
      Number(
        get(
          property,
          'realeflowData.SubjectProperty.Mortgage1InterestRate',
        ),
      ) ||
      Number(get(property, 'realeflowData.MortgageInterestRate')) ||
      0
    let propertyInsurance = calculateDefaultInsurance(
      Number(get(property, 'realeflowData.AVM')) ||
        Number(get(property, 'realeflowData.SubjectProperty.AVM')) ||
        0,
    )
    // Getting and formatting the start date

    const startDateOriginal = get(
      property,
      'realeflowData.RecordingDate',
    )
      ? new Date(get(property, 'realeflowData.RecordingDate'))
      : get(
            property,
            'realeflowData.SubjectProperty.Assessor.curr_sale_recording_date',
          )
        ? parseDateString(
            get(
              property,
              'realeflowData.SubjectProperty.Assessor.curr_sale_recording_date',
            ),
          )
        : undefined

    let startDate
    if (startDateOriginal) {
      startDate = formatDate(startDateOriginal)
    }

    const piti = calculatePITI({
      financeAmount,
      monthlyRate,
      propertyTaxes,
      propertyInsurance,
      startDate,
    })

    if (
      // isEmpty(pitiData) &&
      !propertyLoading &&
      !isEmpty(property) &&
      propertyId === property._id &&
      props.open //&&
      // property?.realeflowData
    ) {
      const pitiData = getPitiDataObject({
        property,
        piti,
      })
      setPitiData(pitiData)
      setPitiAnalytics(calculateAnalytics(pitiData, assumptionsInput))

      setStrategies(
        property && !isEmpty(property.strategies)
          ? property.strategies
          : filterPreferedStrategies(
              preferences,
              props?.session?.me?.activeOrg?.member,
              true,
            ),
      )

      if (!isEmpty(analysis) && isEmpty(analysisInput)) {
        let newAnalysisInput = cloneDeep(analysis)
        unset(newAnalysisInput, 'createdBy')
        unset(newAnalysisInput, 'createdAt')
        unset(newAnalysisInput, '__typename')
        // newAnalysisInput.rent = [Number(get(property, 'realeflowData.RentalValue')) || 0]
        setAnalysisInput(newAnalysisInput)
      }

      if (!isEmpty(assumptions) && isEmpty(assumptionsInput)) {
        let newAssumptionsInput = cloneDeep(assumptions)
        unset(newAssumptionsInput, '_id')
        unset(newAssumptionsInput, '__v')
        unset(newAssumptionsInput, '__typename')
        unset(newAssumptionsInput, 'documentId')
        unset(newAssumptionsInput, 'documentType')
        unset(newAssumptionsInput, 'updatedAt')
        unset(newAssumptionsInput, 'updatedBy')
        setAssumptionsInput(newAssumptionsInput)
      }
    }
  }, [propertyLoading, props.open])

  // const [source, setSource] = useState(null)

  const onChangePiti = (event, source = null) => {
    let { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value) || 0
    else if (type === 'checkbox') newValue = !get(pitiData, name)
    let newPitiData = cloneDeep(pitiData)
    //  if (name === 'ownerOccupied') {
    //   newAnalysisInput.ownerOccupied = newAnalysisInput.ownerOccupied !== activeUnit ? activeUnit : -1
    //   name = `rent.${activeUnit}`
    //   newValue = 0
    // }
    set(newPitiData, name, newValue)
    setPitiData(newPitiData)
    // setPitiAnalytics(calculateAnalytics(newPitiData, assumptionsInput))
  }

  const addAdditionalLoan = () => {
    let newPitiData = cloneDeep(pitiData)
    newPitiData?.creative.existingLoans.push({})
    setPitiData(newPitiData)
    // setPitiAnalytics(calculateAnalytics(newPitiData, assumptionsInput))
  }

  const uploadFile = (name) => (file) => {
    let newPitiData = cloneDeep(pitiData)
    set(newPitiData, name, file)
    setPitiData(newPitiData)
    // setPitiAnalytics(calculateAnalytics(newPitiData, assumptionsInput))
  }

  const onChangeAnalysis = (event, source = null) => {
    let { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value) || 0
    let newAnalysisInput = cloneDeep(analysisInput)
    //  if (name === 'ownerOccupied') {
    //   newAnalysisInput.ownerOccupied = newAnalysisInput.ownerOccupied !== activeUnit ? activeUnit : -1
    //   name = `rent.${activeUnit}`
    //   newValue = 0
    // }
    set(newAnalysisInput, name, newValue)
    setAnalysisInput(newAnalysisInput)
  }

  const handleAnalysisAdornmentChange = (e, name, value) => {
    e.preventDefault()
    let newAnalysisInput = { ...analysisInput }
    newAnalysisInput[name] = value
    setAnalysisInput(newAnalysisInput)
    handleAssumptionsMenuClose()
  }

  const onChangeAssumptions = (event, source = null) => {
    let { name = '', value = '', type = '' } = event.target
    let newValue = value
    if (type === 'number') newValue = Number(value)
    let newAssumptionsInput = cloneDeep(assumptionsInput)
    set(newAssumptionsInput, name, newValue)
    unset(newAssumptionsInput, '_id')
    unset(newAssumptionsInput, '__v')
    unset(newAssumptionsInput, '__typename')
    unset(newAssumptionsInput, 'documentId')
    unset(newAssumptionsInput, 'documentType')
    unset(newAssumptionsInput, 'updatedAt')
    unset(newAssumptionsInput, 'updatedBy')
    setAssumptionsInput(newAssumptionsInput)
  }

  const [anchorElAssumptions, setAnchorElAssumptions] = useState(null)
  const [showFormatDropdown, setShowFormatDropdown] = useState('')

  const toggleAssumptionsMenuFormat = (event, name) => {
    setAnchorElAssumptions(event.target)
    setShowFormatDropdown(name)
  }

  const handleAssumptionsMenuClose = () => {
    setAnchorElAssumptions(null)
  }

  const handleAdornmentChange = (e, name, value) => {
    e.preventDefault()
    let newAssumptions = { ...assumptionsInput }
    set(newAssumptions, name, value)
    unset(newAssumptions, '_id')
    unset(newAssumptions, '__v')
    unset(newAssumptions, '__typename')
    unset(newAssumptions, 'documentId')
    unset(newAssumptions, 'documentType')
    unset(newAssumptions, 'updatedAt')
    unset(newAssumptions, 'updatedBy')
    setAssumptionsInput(newAssumptions)
    handleAssumptionsMenuClose()
  }

  const handleDefinition = (name, anchorEl, target = '') => {
    setMenu(name)
    setMenuTarget(target)
    setOpenNoData(false)
    setAnchorElEstimates(anchorEl)
  }

  const [anchorElEstimates, setAnchorElEstimates] = useState(null)
  const [menu, setMenu] = useState('')
  const [menuTarget, setMenuTarget] = useState('')
  const [estimatesCollapse, setEstimatesCollapse] = useState({})
  const [openNoData, setOpenNoData] = useState(false)

  const [strategies, setStrategies] = useState([])

  const monthlyPayment = get(pitiData, 'creative.monthlyPayment') || 0
  const equityBalance = get(pitiData, 'creative.equityBalance') || 0
  const annualSellerInterestRate =
    get(pitiData, 'creative.sellerInterestRate') || 0

  // Calculate monthly interest (assuming the seller interest rate is annual)
  const monthlyInterest =
    (equityBalance * annualSellerInterestRate) / 100 / 12

  // Calculate the portion of the monthly payment going toward principal
  const monthlyPrincipalPayment = monthlyPayment - monthlyInterest

  // Calculate total principal paid over the term
  const totalPrincipalPaid =
    monthlyPrincipalPayment * get(pitiData, `creative.sellerTerm`) ||
    0

  const changeMenu = (name, value, source = null) => {
    let newName = name
    let newValue = value
    if (
      includes(name, 'rent') ||
      name == 'avgRate' ||
      name === 'occupancyRate'
    ) {
      // if (assumptionsInput[name + 'Type'] === 'rentRange') {
      //   onChangeRange(
      //     replace(name, 'rent', 'rentLow' || 0) +'.' + activeUnit,
      //     replace(name, 'rent', 'rentHigh' || 0) +'.' + activeUnit,
      //     Number(newValue),
      //     Number(newValue),
      //     source,
      //   )
      //   setMenu('')
      //   setMenuTarget('')
      //   setAnchorElEstimates(null)
      //   return
      // }
      // newName = name + '.' + activeUnit
      onChangePiti({
        target: { name: newName, value: newValue, type: 'number' },
        source,
      })
    }
    // if (name == 'avgRate' || name === 'occupancyRate')
    //   onChangeAssumptions(
    //     {
    //       target: {
    //         name: newName,
    //         value: round(newValue),
    //         type: 'number',
    //       },
    //     },
    //     source,
    //   )
    else
      onChangeAnalysis(
        {
          target: { name: newName, value: newValue, type: 'number' },
        },
        source,
      )
    setMenu('')
    setMenuTarget('')
    setAnchorElEstimates(null)
  }

  const onChangeRange = (
    nameLow,
    nameHigh,
    min,
    max,
    source = null,
  ) => {
    let newAnalysisInput = cloneDeep(analysisInput)
    set(newAnalysisInput, nameLow, min)
    set(newAnalysisInput, nameHigh, max)
    setAnalysisInput(newAnalysisInput)
    //  let newSource = null
    //  if (source && !isEqual(source, get(property, `source.${nameLow}`))) {
    //    newSource = cloneDeep(property.source)
    //    if (!isArray(newSource.rent))
    //      newSource.rent = [newSource.rent]
    //    set(newSource, nameLow, source)
    //    setSource(newSource)
    //  }
    //  if (source && !isEqual(source, get(property, `source.${nameHigh}`))) {
    //    newSource = cloneDeep(property.source)
    //    if (!isArray(newSource.rent))
    //      newSource.rent = [newSource.rent]
    //    set(newSource, analysisInput, source)
    //    setSource(newSource)
    //  }
  }

  const toggleCollapse = (name) => {
    let newEstimatesCollapse = { ...estimatesCollapse }
    newEstimatesCollapse[name] = !estimatesCollapse[name]
    setEstimatesCollapse(newEstimatesCollapse)
  }

  const renderInputFields = renderInputFieldsFunction(
    {
      onChangeAnalysis,
      onChangeAssumptions,
    },
    {
      showFormatDropdown,
      anchorElAssumptions,
      toggleAssumptionsMenuFormat,
      handleAssumptionsMenuClose,
      handleAdornmentChange,
      handleAnalysisAdornmentChange,
      handleDefinition,
    },
    props?.session?.me?.activeOrg?.member,
  )()

  const criticalFields = {
    HomeDetails: [
      {
        type: 'dollar',
        label: 'Purchase Price',
        name: 'offerPrice',
        value: analysisInput.offerPrice,
        definition: 'offerPrice',
        definitionTarget: 'offerPrice',
        tooltip: 'How much you purchase the house for',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'As is Value',
        name: 'creative.asIsValue',
        value:
          pitiData?.creative?.asIsValue ||
          property?.realeflowData?.AssessedTotal ||
          property.realeflowData?.SubjectProperty?.AssessedTotalValue,
        onChange: onChangePiti,
        tooltip: 'How much the house is valued right now',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'After Repair Value',
        name: 'afterRepairValue',
        value: analysisInput.afterRepairValue,
        definition: 'afterRepairValue',
        definitionTarget: 'afterRepairValue',
        tooltip: 'How much the house could be worth after repairs',
        xs: 12,
        lg: 12,
      },
      {
        type: 'datePicker',
        label: 'Origination Date',
        name: 'creative.originDate',
        value: pitiData?.creative?.originDate,
        onChange: onChangePiti,
        tooltip: 'The date of origination',
        xs: 12,
        lg: 12,
      },
      {
        type: 'text',
        label: 'Loan type',
        name: 'creative.loanType',
        value: pitiData?.creative?.loanType,
        onChange: onChangePiti,
        tooltip: 'The type of loan currently used',
        xs: 12,
        lg: 12,
      },
      {
        type: 'divider',
      },
      {
        type: 'dollar',
        label: 'Rent',
        name: 'creative.rent',
        value: pitiData?.creative?.rent,
        definition: 'rent',
        definitionTarget: 'creative.rent',
        onChange: onChangePiti,
        tooltip: 'How much you could rent the house for',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Avg Daily Rate',
        name: 'creative.avgRate',
        value: pitiData?.creative?.avgRate,
        // definition: 'avgRate',
        definitionTarget: 'creative.avgRate',
        onChange: onChangePiti,
        tooltip:
          'How much you could get per night as a short term rental',
        xs: 12,
        lg: 12,
      },
    ],
    PITI: [
      {
        type: 'dollar',
        label: 'Principal',
        name: 'creative.principal',
        value: pitiData?.creative?.principal,
        onChange: onChangePiti,
        tooltip:
          'How much of your monthly mortgage payment is paying off the loan. This value is an estimate',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Interest',
        name: 'creative.interest',
        value: pitiData?.creative?.interest,
        onChange: onChangePiti,
        tooltip:
          'How much of your monthly mortgage payment is paying off the interest on the loan. This value is an estimate',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Taxes',
        name: 'creative.taxes',
        value: pitiData?.creative?.taxes,
        onChange: onChangePiti,
        tooltip:
          'How much of your monthly mortgage payment is paying for property taxes. This value is an estimate',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Insurance',
        name: 'creative.insurance',
        value: pitiData?.creative?.insurance,
        onChange: onChangePiti,
        tooltip:
          'How much of your monthly mortgage payment is paying for property insurance. This value is an estimate',
        xs: 12,
        lg: 12,
      },
    ],
    EntryFee: [
      {
        type: 'dollar',
        label: 'Cash to Seller',
        name: 'creative.moneyToSeller',
        value: pitiData?.creative?.moneyToSeller,
        onChange: onChangePiti,
        tooltip: 'How much the seller needs',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Arrears / Liens',
        name: 'creative.arrears',
        value: pitiData?.creative?.arrears,
        onChange: onChangePiti,
        tooltip: 'The total of arrears and liens the property has',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Agent Fee',
        name: 'creative.agentFee',
        value: pitiData?.creative?.agentFee,
        onChange: onChangePiti,
        tooltip: 'The fee you pay the agent, if applicable',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Assignments',
        name: 'creative.assignmentFee',
        value: pitiData?.creative?.assignmentFee,
        onChange: onChangePiti,
        tooltip: 'The total assignment fee amount',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Closing Costs & TC Expenses',
        name: 'creative.transactionCoordinatorExpenses',
        value: pitiData?.creative?.transactionCoordinatorExpenses,
        onChange: onChangePiti,
        tooltip:
          'The total of closing costs and transaction coordinator expenses',
        xs: 12,
        lg: 12,
      },
      {
        type: 'divider',
      },
      {
        type: 'dollar',
        label: 'Cost of Money / Lending Expenses',
        name: 'creative.lendingExpenses',
        value: pitiData?.creative?.lendingExpenses,
        onChange: onChangePiti,
        tooltip: 'Total expenses dealing with lending',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Est. Rehab',
        name: 'creative.estRehab',
        value: pitiData?.creative?.estRehab,
        onChange: onChangePiti,
        tooltip: 'How much money it costs to rehab the property',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Furnishings',
        name: 'creative.furnishings',
        value: pitiData?.creative?.furnishings,
        onChange: onChangePiti,
        tooltip: 'How much it costs to furnish the property',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Utilities / Holding',
        name: 'creative.holdingCosts',
        value: pitiData?.creative?.holdingCosts,
        onChange: onChangePiti,
        tooltip:
          'How much utilities and other monthly expenses will cost you while not receiving income',
        xs: 12,
        lg: 12,
      },
      // {
      //   type: 'dollar',
      //   label: 'Annual HOA',
      //   name: 'annualHOA',
      //   value: pitiData.annualHOA,
      //   onChange: onChangePiti,
      //   xs: 12,
      //   lg: 6,
      // },
      // {
      //   dropdown: true,
      //   type: 'dropdown',
      //   label: 'HOA',
      //   name: 'HOA',
      //   value: analysisInput.HOA,
      //   adornment: analysisInput.HOAType || 'Yearly',
      //   adornmentToggle: analysisInput.HOAType,
      //   adornmentName: 'HOAType',
      //   xs: 12,
      //   lg: 6,
      // },

      // {
      //   type: 'dropdown',
      //   name: 'acquisitionCommission',
      //   label: 'Acq Commission',
      //   value: assumptionsInput.acquisitionCommission,
      //   adornmentToggle: assumptionsInput.acquisitionCommissionType,
      //   adornment: assumptionsInput.acquisitionCommissionType,
      //   adornmentName: 'acquisitionCommissionType',
      //   // xs: 6,
      //   xs: 12,
      //   lg: 6,
      //   assumptions: true,
      //   dropdown: true,
      //   tooltipField:
      //     ' Used in addition to sales of typical sales commissions expressed as a percentage of the Purchase price or a fixed dollar amount.',
      // },
      // {
      //   type: 'dollar',
      //   label: 'Arrears',
      //   name: 'arrears',
      //   value: pitiData.arrears,
      //   onChange: onChangePiti,
      //   xs: 12,
      //   lg: 6,
      // },
      // {
      //   type: 'dollar',
      //   label: 'liens',
      //   name: 'liens',
      //   value: pitiData.liens,
      //   onChange: onChangePiti,
      //   xs: 12,
      //   lg: 6,
      // },
      // {
      //   type: 'dollar',
      //   label: 'Est Rehab',
      //   name: 'rehabCost',
      //   value: analysisInput.rehabCost,
      //   definition: 'rehabCost',
      //   xs: 12,
      //   lg: 6,
      // },
      // {
      //   type: 'budgetLines',
      //   showall: true,
      //   name: 'customAcquisitionExpenses',
      //   value: analysisInput.customAcquisitionExpenses,
      //   label: 'Custom Acquisition Expenses',
      //   options:
      //     props.session.me.activeOrg.customAcquisitionExpenseTypes,
      //   xs: 12,
      //   optionName: 'customAcquisitionExpenseTypes',
      //   removeOption: true,
      // },
    ],
    Financing: [
      // {
      //   type: 'percentage',
      //   label: '% of Purchase Price',
      //   name: `creative.${financingTab}.percentageOfPurchasePrice`,
      //   value: get(
      //     pitiData,
      //     `creative.${financingTab}.percentageOfPurchasePrice`,
      //   ),
      //   onChange: onChangePiti,
      //   tooltip:
      //     'The percentage of the purchase price that is being covered by the loan',
      //   xs: 12,
      //   lg: 12,
      // },
      {
        type: 'dollar',
        label: 'Loan Amount',
        name: `creative.${financingTab}.loanAmount`,
        value: get(pitiData, `creative.${financingTab}.loanAmount`),
        onChange: onChangePiti,
        tooltip: 'Starting value of the loan',
        xs: 12,
        lg: 12,
      },
      {
        type: 'date',
        label: 'Loan Start Date',
        name: `creative.${financingTab}.loanStartDate`,
        value: get(
          pitiData,
          `creative.${financingTab}.loanStartDate`,
        ),
        onChange: onChangePiti,
        tooltip: 'Starting date of the loan',
        xs: 12,
        lg: 12,
      },
      {
        type: 'percentage',
        label: 'Interest Rate %',
        name: `creative.${financingTab}.interestRate`,
        value: get(pitiData, `creative.${financingTab}.interestRate`),
        onChange: onChangePiti,
        tooltip: 'Rate of interest',
        xs: 12,
        lg: 12,
      },
      {
        type: 'percentage',
        label: '% of Rehab Financed',
        name: `creative.${financingTab}.percentageOfRehabFinanced`,
        value: get(
          pitiData,
          `creative.${financingTab}.percentageOfRehabFinanced`,
        ),
        onChange: onChangePiti,
        tooltip:
          'Percentage of rehab costs being covered by the loan',
        xs: 12,
        lg: 12,
      },
      {
        type: 'number',
        label: 'Points',
        name: `creative.${financingTab}.points`,
        value: get(pitiData, `creative.${financingTab}.points`),
        onChange: onChangePiti,
        tooltip:
          'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
        xs: 12,
        lg: 12,
      },
      {
        type: 'number',
        label: 'Financing Terms',
        name: `creative.${financingTab}.term`,
        value: get(pitiData, `creative.${financingTab}.term`),
        onChange: onChangePiti,
        tooltip:
          'The length of time that the loan is being amortized. Expressed in months.',
        xs: 12,
        lg: 12,
      },
      {
        type: 'checkbox',
        label: 'Interest Only',
        name: `creative.${financingTab}.interestOnlyLoan`,
        value: get(
          pitiData,
          `creative.${financingTab}.interestOnlyLoan`,
        ),
        onChange: onChangePiti,
        tooltip:
          'A loan that only requires the payment of interest, not principal.',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Financing Fee',
        name: `creative.${financingTab}.financingFee`,
        value: get(pitiData, `creative.${financingTab}.financingFee`),
        onChange: onChangePiti,
        tooltip:
          'A fee that the real estate professional charges for securing financing on the property. Expressed as a percentage of the loan amount.',
        xs: 12,
        lg: 12,
      },
    ],
    SellerFinancing: [
      {
        type: 'dollar',
        label: 'Equity Balance',
        name: `creative.equityBalance`,
        value: get(pitiData, `creative.equityBalance`),
        onChange: onChangePiti,
        tooltip: 'How much equity the owner has in the House',
        xs: 12,
        lg: 12,
      },
      {
        type: 'percentage',
        label: 'Interest Rate %',
        name: `creative.sellerInterestRate`,
        value: get(pitiData, `creative.sellerInterestRate`),
        onChange: onChangePiti,
        tooltip: 'Seller Finance Rate of interest',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Monthly Payment',
        name: `creative.monthlyPayment`,
        value: get(pitiData, `creative.monthlyPayment`),
        onChange: onChangePiti,
        tooltip:
          'The amount that will be paid each month to pay off the loan',
        xs: 12,
        lg: 12,
      },
      {
        type: 'number',
        label: 'Term in Months',
        name: `creative.sellerTerm`,
        value: get(pitiData, `creative.sellerTerm`),
        onChange: onChangePiti,
        tooltip:
          'How many months you will be paying your Monthly Payment',
        xs: 12,
        lg: 12,
      },
      {
        type: 'dollar',
        label: 'Balloon Amount',
        name: `creative.balloonAmount`,
        value:
          (get(pitiData, `creative.equityBalance`) || 0) -
          totalPrincipalPaid,
        // onChange: onChangePiti,
        tooltip:
          'How much of the loan will remain by the end of the term',
        xs: 12,
        lg: 12,
        disabled: true,
      },
    ],
  }

  const preferences = merge(
    cloneDeep(DEFAULT_PREFERENCES),
    props?.session?.me?.activeOrg?.memberPreferences,
    props?.session?.me?.activeOrg?.preferences,
    {
      general: {
        strategies:
          property && property.portfolio
            ? property.portfolio.strategies
            : [],
      },
    },
  )

  // const uploadFields = [
  //   {
  //     name: 'mortgageStatement',
  //     label: 'Mortgage statement',
  //     type: 'file',
  //     value: pitiData.mortgageStatement,
  //     uploadFile: uploadFile('mortgageStatement'),
  //     buttonText: "Upload Mortgage statement",
  //     xs: 12,
  //     lg: 6,
  //   },
  //   {
  //     name: 'insuranceStatement',
  //     label: 'Insurance statement',
  //     type: 'file',
  //     value: pitiData.insuranceStatement,
  //     uploadFile: uploadFile('insuranceStatement'),
  //     buttonText: "Upload Insurance statement",
  //     xs: 12,
  //     lg: 6,
  //   },
  // ]

  const submitProperty = (
    property,
    propertyInput,
    assumptionsInput /*skip = false*/,
  ) => {
    // setLoader(true)
    updateProperty({
      variables: {
        id: propertyId,
        propertyInput,
        // propertyInput: {
        //   // propertyDashboard: true,
        //   strategies,
        //   analysisInput: skip ? null : analysisInput,
        //   assumptionsInput: skip ? null : assumptionsInput,
        //   pitiData,
        // },
      },
      refetchQueries: [
        {
          query: GET_ME,
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [property?.portfolio._id],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTY,
          variables: {
            id: propertyId,
          },
        },
        {
          query: GET_ASSUMPTIONS,
          variables: {
            documentId: propertyId,
            documentType: 'property',
          },
        },
      ],
    }).then(() => {
      const { pitiData } = propertyInput
      setPitiAnalytics(calculateAnalytics(pitiData, assumptionsInput))
      // setLoader(false)
      // history.push('/property-details/' + propertyId + '/Summary')
      // onClose()
    })
  }

  const debouncedSubmitProperty = useRef(
    debounce(
      (property, propertyInput, assumptionsInput) =>
        submitProperty(property, propertyInput, assumptionsInput),
      1000,
    ),
  ).current

  useEffect(() => {
    const propertyInput = {
      strategies,
      analysisInput: analysisInput,
      assumptionsInput: assumptionsInput,
      pitiData,
    }

    let propertyTaxes = includes(
      property?.realeflowData?.TaxAmount ||
        property?.realeflowData?.SubjectProperty?.TaxAmount,
      '$',
    )
      ? property?.realeflowData?.TaxAmount ||
        property?.realeflowData?.SubjectProperty?.TaxAmount
      : Number(
          property?.realeflowData?.TaxAmount ||
            property?.realeflowData?.SubjectProperty?.TaxAmount,
        ) / 100
    let financeAmount = Number(
      get(property, 'realeflowData.LoanAmount') ||
        get(property, 'realeflowData.SubjectProperty.TotalLoans') ||
        0,
    )
    let monthlyRate =
      Number(
        get(
          property,
          'realeflowData.SubjectProperty.Mortgage1InterestRate',
        ),
      ) ||
      Number(get(property, 'realeflowData.MortgageInterestRate')) ||
      0
    let propertyInsurance = calculateDefaultInsurance(
      Number(get(property, 'realeflowData.AVM')) ||
        Number(get(property, 'realeflowData.SubjectProperty.AVM')) ||
        0,
    )
    // Getting and formatting the start date

    const startDateOriginal = get(
      property,
      'realeflowData.RecordingDate',
    )
      ? new Date(get(property, 'realeflowData.RecordingDate'))
      : get(
            property,
            'realeflowData.SubjectProperty.Assessor.curr_sale_recording_date',
          )
        ? parseDateString(
            get(
              property,
              'realeflowData.SubjectProperty.Assessor.curr_sale_recording_date',
            ),
          )
        : undefined

    let startDate
    if (startDateOriginal) {
      startDate = formatDate(startDateOriginal)
    }

    const piti = calculatePITI({
      financeAmount,
      monthlyRate,
      propertyTaxes,
      propertyInsurance,
      startDate,
    })

    const basePitiData = getPitiDataObject({ property, piti })
    if (
      isEmpty(property) ||
      isEmpty(pitiData) ||
      propertyLoading ||
      (isEqual(pitiData, basePitiData) &&
        analysis?.afterRepairValue ===
          analysisInput?.afterRepairValue &&
        analysis?.offerPrice === analysisInput?.offerPrice) ||
      !props.open
    )
      return
    debouncedSubmitProperty(property, propertyInput, assumptionsInput)
    return () => debouncedSubmitProperty.cancel()
  }, [strategies, analysisInput, assumptionsInput, pitiData])

  const savePitiToProperty = () => {
    setLoader(true)
    let rentArray = new Array(analysisInput?.rent?.length)
    let avgRateArray = new Array(analysisInput?.rent?.length)
    let avgRateArrayType = new Array(analysisInput?.rent?.length)
    for (let i = 0; i < rentArray.length; i++) {
      rentArray[i] = 0
      avgRateArray[i] = 0
      avgRateArrayType[i] = 'Daily'
    }
    rentArray[0] = pitiData?.creative?.rent
    avgRateArray[0] = pitiData?.creative?.avgRate
    const newAnalysisInput = {
      rent: rentArray,
      rehabCost: pitiData?.creative?.estRehab,
      rehabCostBRRRR: pitiData?.creative?.estRehab,
      rehabCostBOR: pitiData?.creative?.estRehab,
      rehabCostFixFlip: pitiData?.creative?.estRehab,
      rehabCostTurnkey: pitiData?.creative?.estRehab,
      rehabCostSTR: pitiData?.creative?.estRehab,
      rehabCostWholesale: pitiData?.creative?.estRehab,
      rehabCostAstroFlip: pitiData?.creative?.estRehab,
    }
    const newAssumptionsInput = {
      avgRate: avgRateArray,
      avgRateType: avgRateArrayType,
      utilities: pitiData?.creative?.holdingCosts,
      utilitiesType: '$',
      utilitiesB2R: pitiData?.creative?.holdingCosts,
      utilitiesB2RType: '$',
      utilitiesBRRRR: pitiData?.creative?.holdingCosts,
      utilitiesBRRRRType: '$',
      utilitiesFixFlip: pitiData?.creative?.holdingCosts,
      utilitiesFixFlipType: '$',
      utilitiesTurnkey: pitiData?.creative?.holdingCosts,
      utilitiesTurnkeyType: '$',
      utilitiesSTR: pitiData?.creative?.holdingCosts,
      utilitiesSTRType: '$',
    }
    updateProperty({
      variables: {
        id: propertyId,
        propertyInput: {
          // propertyDashboard: true,
          strategies,
          analysisInput: newAnalysisInput,
          assumptionsInput: newAssumptionsInput,
          pitiData,
        },
      },
      refetchQueries: [
        {
          query: GET_ME,
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [property?.portfolio._id],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTY,
          variables: {
            id: propertyId,
          },
        },
        {
          query: GET_ASSUMPTIONS,
          variables: {
            documentId: propertyId,
            documentType: 'property',
          },
        },
      ],
    }).then(() => {
      setLoader(false)
      // history.push('/property-details/' + propertyId + '/Summary')
      onClose()
    })
  }

  const calculatePitiTotal = (data) => {
    const { principal, interest, taxes, insurance } =
      data?.creative || {}
    return (
      (Number(principal) || 0) +
      (Number(interest) || 0) +
      (Number(taxes) || 0) +
      (Number(insurance) || 0)
    )
  }

  const calculateEntryFee = (data) => {
    const {
      // rehabCost,
      moneyToSeller,
      arrears,
      agentFee,
      assignmentFee,
      transactionCoordinatorExpenses,
      lendingExpenses,
      furnishings,
      holdingCosts,
      estRehab,
      // acquisitionCommission,
      // customAcquisitionExpenses,
      // annualHOA,
      HML,
      PML,
      existingLoans,
    } = data?.creative || {}

    const pointsAmount =
      ((HML?.loanAmount * HML?.points) / 100 || 0) +
      ((PML?.loanAmount * PML?.points) / 100 || 0) +
      sum(
        map(existingLoans, (loan) => {
          return (loan?.loanAmount * loan?.points) / 100 || 0
        }),
      )

    return (
      // (Number(rehabCost) || 0) +
      (Number(moneyToSeller) || 0) +
      (Number(arrears) || 0) +
      (Number(agentFee) || 0) +
      (Number(assignmentFee) || 0) +
      (Number(transactionCoordinatorExpenses) || 0) +
      (Number(lendingExpenses) || 0) +
      (Number(estRehab) || 0) +
      (Number(furnishings) || 0) +
      (Number(holdingCosts) || 0) +
      (Number(HML?.financingFee) || 0) +
      (Number(PML?.financingFee) || 0) +
      (sumBy(existingLoans, 'financingFee') || 0) +
      pointsAmount
      // (Number(acquisitionCommission) || 0) +
      // (Number(customAcquisitionExpenses) || 0) +
      // (Number(annualHOA) || 0)
    )
  }
  function calculateDefaultInsurance(
    afterRepairValue,
    nationalAverage = 1550,
  ) {
    let defaultInsurance

    if (afterRepairValue <= 300000) {
      // For properties worth $300,000 or less, use the national average
      defaultInsurance = nationalAverage
    } else {
      // For properties worth more than $300,000, scale the insurance proportionally
      const scalingFactor = afterRepairValue / 300000
      defaultInsurance = nationalAverage * scalingFactor
    }

    return defaultInsurance
  }

  const getExistingLoans = (pitiData) => {
    return (
      (pitiData?.creative?.HML?.loanAmount || 0) +
        (pitiData?.creative?.PML?.loanAmount || 0) +
        sumBy(pitiData?.creative?.existingLoans, 'loanAmount') || 0
    )
  }

  const calculateAnalytics = (pitiData, assumptionsInput) => {
    const { asIsValue, assignmentFee, estRehab } =
      pitiData?.creative || {}
    const pitiTotal = calculatePitiTotal(pitiData)
    const entryFee = calculateEntryFee(pitiData)
    const existingLoans = getExistingLoans(pitiData)
    const yearlyCashFlow =
      pitiData?.creative?.rent * 12 - pitiTotal - existingLoans
    const cashOnCashReturn =
      entryFee > 0 ? yearlyCashFlow / entryFee : yearlyCashFlow
    const capRate =
      Number(asIsValue) > 0
        ? (yearlyCashFlow - entryFee) / Number(asIsValue)
        : yearlyCashFlow - entryFee

    const yearlyCashFlowSTR =
      pitiData?.creative?.avgRate * 365 - pitiTotal - existingLoans
    const cashOnCashReturnSTR =
      entryFee > 0 ? yearlyCashFlowSTR / entryFee : yearlyCashFlowSTR
    const capRateSTR =
      Number(asIsValue) > 0
        ? (yearlyCashFlowSTR - entryFee) / Number(asIsValue)
        : yearlyCashFlowSTR - entryFee

    const fixFlipNetProfit =
      (analysisInput?.afterRepairValue || 0) -
      (analysisInput?.offerPrice || 0) -
      entryFee
    const fixFlipROI =
      (analysisInput?.afterRepairValue || 0) > 0
        ? fixFlipNetProfit / (analysisInput?.afterRepairValue || 0)
        : fixFlipNetProfit

    const wholesaleNetProfit =
      Number(asIsValue) - (analysisInput?.offerPrice || 0)
    const wholesaleBuyerProfit = Number(assignmentFee)
    const buyerProfit1 =
      analysisInput?.afterRepairValue - Number(estRehab)

    return {
      buyHold: {
        cashFlow: yearlyCashFlow,
        cashFlowArrow:
          yearlyCashFlow < assumptionsInput?.belowCashFlow ? 0 : 1,
        cashOnCashReturn: cashOnCashReturn,
        cashOnCashReturnArrow:
          cashOnCashReturn * 100 <
          assumptionsInput?.belowCashOnCashReturn
            ? 0
            : 1,
        capRate: capRate,
        capRateArrow:
          capRate * 100 < assumptionsInput?.belowCapRate ? 0 : 1,
      },
      fixFlip: {
        saleProceeds: fixFlipNetProfit,
        saleProceedsArrow:
          fixFlipNetProfit < assumptionsInput?.belowSaleProceeds
            ? 0
            : 1,
        netROIFixFlip: fixFlipROI,
        netROIArrow:
          fixFlipROI * 100 < assumptionsInput?.belowNetROI ? 0 : 1,
      },
      turnkey: {
        cashFlowTurnkey: yearlyCashFlow,
        cashFlowTurnkeyArrow:
          yearlyCashFlow < assumptionsInput?.belowCashFlowTurnkey
            ? 0
            : 1,
        cashOnCashReturnTurnkey: cashOnCashReturn,
        cashOnCashReturnTurnkeyArrow:
          cashOnCashReturn * 100 <
          assumptionsInput?.belowSellingCashOnCashReturn
            ? 0
            : 1,
        capRateTurnkey: capRate,
        capRateTurnkeyArrow:
          capRate * 100 < assumptionsInput?.belowSellingCapRate
            ? 0
            : 1,
      },
      BRRRR: {
        cashFlowBRRRR: yearlyCashFlow,
        cashFlowBRRRRArrow:
          yearlyCashFlow < assumptionsInput?.belowCashFlowBRRRR
            ? 0
            : 1,
        cashOnCashReturnBRRRR: cashOnCashReturn,
        cashOnCashReturnBRRRRArrow:
          cashOnCashReturn * 100 <
          assumptionsInput?.belowCashOnCashReturnBRRRR
            ? 0
            : 1,
        capRateBRRRR: capRate,
        capRateBRRRRArrow:
          capRate * 100 < assumptionsInput?.belowCapRateBRRRR ? 0 : 1,
      },
      STR: {
        cashFlowSTR: yearlyCashFlowSTR,
        cashFlowSTRArrow:
          yearlyCashFlowSTR < assumptionsInput?.belowCashFlowSTR
            ? 0
            : 1,
        cashOnCashReturnSTR: cashOnCashReturnSTR,
        cashOnCashReturnSTRArrow:
          cashOnCashReturnSTR * 100 <
          assumptionsInput?.belowCashOnCashReturnSTR
            ? 0
            : 1,
        capRateSTR: capRateSTR,
        capRateSTRArrow:
          capRateSTR * 100 < assumptionsInput?.belowCapRateSTR
            ? 0
            : 1,
      },
      B2R: {
        cashFlowB2R: yearlyCashFlow,
        cashFlowB2RArrow:
          yearlyCashFlow < assumptionsInput?.belowCashFlowB2R ? 0 : 1,
        cashOnCashReturnB2R: cashOnCashReturn,
        cashOnCashReturnB2RArrow:
          cashOnCashReturn * 100 <
          assumptionsInput?.belowCashOnCashReturnB2R
            ? 0
            : 1,
        capRateB2R: capRate,
        capRateB2RArrow:
          capRate * 100 < assumptionsInput?.belowCapRateB2R ? 0 : 1,
      },
      BOR: {
        averageAnnualReturnBOR: cashOnCashReturn,
        averageAnnualReturnBORArrow:
          cashOnCashReturn * 100 <
          assumptionsInput?.belowAverageAnnualReturnBOR
            ? 0
            : 1,
      },
      wholesale: {
        saleProceedsWholesale: wholesaleNetProfit,
        saleProceedsWholesaleArrow:
          wholesaleNetProfit <
          assumptionsInput?.belowWholesaleSaleProceeds
            ? 0
            : 1,
        buyerProfitTotal: wholesaleBuyerProfit,
        buyerProfitArrow:
          wholesaleBuyerProfit <
          Math.floor(assumptionsInput?.belowBuyerProfit / 100) *
            buyerProfit1
            ? 0
            : 1,
      },
    }
  }

  const renderStrategyCards = (pitiAnalytics, stratagies) => {
    const strategyCards = [
      {
        name: 'Buy & Hold',
        label: 'Buy & Hold',
        percentage:
          (pitiAnalytics?.buyHold?.cashFlowArrow +
            pitiAnalytics?.buyHold?.cashOnCashReturnArrow +
            pitiAnalytics?.buyHold?.capRateArrow) /
          3,
        page: 'BuyHold',
        afterRepairValue: analytics?.buyHold?.arvTotalBuyHold,
        analytics: [
          {
            value: pitiAnalytics?.buyHold?.cashFlow / 12 || '-',
            arrow: pitiAnalytics?.buyHold?.cashFlowArrow || 0,
            label: 'Net Cashflow',
            type: 'currency',
          },
          {
            value: pitiAnalytics?.buyHold?.cashOnCashReturn || '-',
            arrow: pitiAnalytics?.buyHold?.cashOnCashReturnArrow || 0,
            label: 'CoC ROI',
            type: 'percentage',
          },
          {
            value: pitiAnalytics?.buyHold?.capRate || '-',
            arrow: pitiAnalytics?.buyHold?.capRateArrow || 0,
            label: 'Yield on Cost',
            type: 'percentage',
          },
        ],
      },
      {
        name: 'Fix & Flip',
        label: 'Flip Analysis',
        percentage:
          (pitiAnalytics?.fixFlip?.saleProceedsArrow +
            pitiAnalytics?.fixFlip?.netROIArrow) /
          2,
        page: 'FixFlip',
        afterRepairValue: analytics?.fixFlip?.arvTotalFixFlip,
        analytics: [
          {
            value: pitiAnalytics?.fixFlip?.saleProceeds || '-',
            arrow: pitiAnalytics?.fixFlip?.saleProceedsArrow || 0,
            label: 'Net Profit',
            type: 'currency',
          },
          {
            value: pitiAnalytics?.fixFlip?.netROIFixFlip || '-',
            arrow: pitiAnalytics?.fixFlip?.netROIArrow || 0,
            label: 'Net ROI',
            type: 'percentage',
          },
        ],
      },
      {
        name: 'Turnkey End Buyer',
        label: 'Turnkey: End Buyer',
        percentage:
          (pitiAnalytics?.turnkey?.cashFlowTurnkeyArrow +
            pitiAnalytics?.turnkey?.cashOnCashReturnTurnkeyArrow +
            pitiAnalytics?.turnkey?.capRateTurnkeyArrow) /
          3,
        page: 'Turnkey',
        afterRepairValue: analytics?.turnkey?.arvTotalTurnkey,
        analytics: [
          {
            value:
              pitiAnalytics?.turnkey?.cashFlowTurnkey / 12 || '-',
            arrow: pitiAnalytics?.turnkey?.cashFlowTurnkeyArrow || 0,
            label: 'Net Cashflow',
            type: 'currency',
          },
          {
            value:
              pitiAnalytics?.turnkey?.cashOnCashReturnTurnkey || '-',
            arrow:
              pitiAnalytics?.turnkey?.cashOnCashReturnTurnkeyArrow ||
              0,
            label: 'CoC ROI',
            type: 'percentage',
          },
          {
            value: pitiAnalytics?.turnkey?.capRateTurnkey || '-',
            arrow: pitiAnalytics?.turnkey?.capRateTurnkeyArrow || 0,
            label: 'Cap Rate',
            type: 'percentage',
          },
        ],
      },
      {
        name: 'BRRRR',
        label: 'BRRRR',
        percentage:
          (pitiAnalytics?.BRRRR?.cashFlowBRRRRArrow +
            pitiAnalytics?.BRRRR?.cashOnCashReturnBRRRRArrow +
            pitiAnalytics?.BRRRR?.capRateBRRRRArrow) /
          3,
        page: 'BRRRR',
        afterRepairValue: analytics?.BRRRR?.arvTotalBRRRR,
        analytics: [
          {
            value: pitiAnalytics?.BRRRR?.cashFlowBRRRR / 12 || '-',
            arrow: pitiAnalytics?.BRRRR?.cashFlowBRRRRArrow || 0,
            label: 'Net Cashflow',
            type: 'currency',
          },
          {
            value: pitiAnalytics?.BRRRR?.cashOnCashReturnBRRRR || '-',
            arrow:
              pitiAnalytics?.BRRRR?.cashOnCashReturnBRRRRArrow || 0,
            label: 'CoC ROI',
            type: 'percentage',
          },
          {
            value: pitiAnalytics?.BRRRR?.capRateBRRRR || '-',
            arrow: pitiAnalytics?.BRRRR?.capRateBRRRRArrow || 0,
            label: 'Yield on Cost',
            type: 'percentage',
          },
        ],
      },
      {
        name: 'Short Term Rental',
        label: 'Short Term Rental',
        percentage:
          (pitiAnalytics?.STR?.cashFlowSTRArrow +
            pitiAnalytics?.STR?.cashOnCashReturnSTRArrow +
            pitiAnalytics?.STR?.capRateSTRArrow) /
          3,
        page: 'ShortTermRental',
        afterRepairValue: analytics?.STR?.arvTotalSTR,
        analytics: [
          {
            value: pitiAnalytics?.STR?.cashFlowSTR / 12 || '-',
            arrow: pitiAnalytics?.STR?.cashFlowSTRArrow || 0,
            label: 'Net Cashflow',
            type: 'currency',
          },
          {
            value: pitiAnalytics?.STR?.cashOnCashReturnSTR || '-',
            arrow: pitiAnalytics?.STR?.cashOnCashReturnSTRArrow || 0,
            label: 'CoC ROI',
            type: 'percentage',
          },
          {
            value: pitiAnalytics?.STR?.capRateSTR || '-',
            arrow: pitiAnalytics?.STR?.capRateSTRArrow || 0,
            label: 'Yield on Cost',
            type: 'percentage',
          },
        ],
      },
      {
        name: 'Wholesale',
        label: 'Wholesale',
        percentage:
          (pitiAnalytics?.wholesale?.saleProceedsWholesaleArrow +
            pitiAnalytics?.wholesale?.buyerProfitArrow) /
          2,
        page: 'Wholesale',
        afterRepairValue: analytics?.wholesale?.arvTotalWholesale,
        analytics: [
          {
            value:
              pitiAnalytics?.wholesale?.saleProceedsWholesale || '-',
            arrow:
              pitiAnalytics?.wholesale?.saleProceedsWholesaleArrow ||
              0,
            label: 'Net Profit',
            type: 'currency',
          },
          {
            value: pitiAnalytics?.wholesale?.buyerProfitTotal || '-',
            arrow: pitiAnalytics?.wholesale?.buyerProfitArrow || 0,
            label: 'Buyer Profit',
            type: 'currency',
          },
        ],
      },
      {
        name: 'Build to Rent',
        label: 'Build to Rent',
        percentage:
          (pitiAnalytics?.B2R?.cashFlowB2RArrow +
            pitiAnalytics?.B2R?.cashOnCashReturnB2RArrow +
            pitiAnalytics?.B2R?.capRateB2RArrow) /
          3,
        page: 'BuildToRent',
        afterRepairValue: analytics?.B2R?.arvTotalB2R,
        analytics: [
          {
            value: pitiAnalytics?.B2R?.cashFlowB2R / 12 || '-',
            arrow: pitiAnalytics?.B2R?.cashFlowB2RArrow || 0,
            label: 'Net Cashflow',
            type: 'currency',
          },
          {
            value: pitiAnalytics?.B2R?.cashOnCashReturnB2R || '-',
            arrow: pitiAnalytics?.B2R?.cashOnCashReturnB2RArrow || 0,
            label: 'CoC ROI',
            type: 'percentage',
          },
          {
            value: pitiAnalytics?.B2R?.capRateB2R || '-',
            arrow: pitiAnalytics?.B2R?.capRateB2RArrow || 0,
            label: 'Cap Rate',
            type: 'percentage',
          },
        ],
      },
      {
        name: 'BOR',
        label: 'BOR',
        percentage: pitiAnalytics?.BOR?.averageAnnualReturnBORArrow,
        page: 'BOR',
        afterRepairValue: analytics?.BOR?.arvTotalBOR,
        analytics: [
          {
            value: pitiAnalytics?.BOR?.averageAnnualReturnBOR || '-',
            arrow:
              pitiAnalytics?.BOR?.averageAnnualReturnBORArrow || 0,
            label: 'CoC ROI',
            type: 'percentage',
          },
        ],
      },
      // {
      //   name: 'Astroflip',
      //   label: 'Astroflip',
      //   percentage:
      //     (analytics?.astroFlip?.onTargetAstroFlip +
      //       analytics?.astroFlip?.aboveTargetAstroFlip) /
      //     (analytics?.astroFlip?.belowTargetAstroFlip +
      //       analytics?.astroFlip?.onTargetAstroFlip +
      //       analytics?.astroFlip?.aboveTargetAstroFlip),
      //   page: 'Astroflip',
      //   afterRepairValue: analytics?.astroFlip?.arvTotalAstroFlip,
      //   analytics: [
      //     {
      //       value: analytics?.astroFlip?.saleProceedsAstroFlip || '-',
      //       arrow:
      //         analytics?.astroFlip?.saleProceedsAstroFlipArrow || 0,
      //       label: 'Net Profit',
      //       type: 'currency',
      //     },
      //     {
      //       value:
      //         analytics?.astroFlip?.buyerProfitTotalAstroFlip || '-',
      //       arrow: analytics?.astroFlip?.buyerProfitAstroFlipArrow || 0,
      //       label: 'Buyer Profit',
      //       type: 'currency',
      //     },
      //   ],
      // },
    ]

    return map(
      filter(strategyCards, (card) =>
        !isEmpty(strategies)
          ? includes(strategies, card.name)
          : find(get(props.preferences, 'general.strategies'), {
              value: card.name,
              active: true,
            }),
      ),
      (section, index) => (
        <Grid item xs={12}>
          <Card
            style={{
              borderRadius: 0,
              marginTop: 20,
            }}
          >
            <CardHeader
              title={
                <Typography variant="h6" style={{ fontWeight: 600 }}>
                  {getStrategyName(
                    section.label,
                    props.session.me.activeOrg.member,
                  )}
                </Typography>
              }
              subheader={[
                `According to your inputs, a ${getStrategyName(
                  section.label,
                  props.session.me.activeOrg.member,
                )} strategy meets `,
                <b
                  style={{
                    color:
                      section.percentage >= 0.66
                        ? '#1bc943'
                        : section.percentage >= 0.33
                          ? '#f4772e'
                          : '#f83245',
                  }}
                >
                  {gaugeFormat.format(section.percentage)}
                </b>,
                ` of your targets`,
              ]}
              // action={
              //   <IconButton>
              //     <FontAwesomeIcon
              //       icon={['far', 'chess-queen-piece']}
              //     //className="font-size-md"
              //     />
              //   </IconButton>
              // }
            />
            <CardContent>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
                    {/* <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-2" /> */}
                    <div className="mx-auto">
                      <GaugeChart
                        hideText
                        id={'chartsGauges' + index}
                        nrOfLevels={6}
                        colors={['#f83245', '#f4772e', '#1bc943']}
                        arcWidth={0.3}
                        percent={section.percentage}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item className="gridItem2" xs={12}>
                  <Card
                    style={{
                      backgroundColor: '#eff2f5',
                    }}
                  >
                    <CardContent style={{ padding: 10 }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={0}
                        wrap="nowrap"
                        style={{ padding: 0 }}
                      >
                        {map(section.analytics, (field) => (
                          <Grid item className="gridItem2">
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <Typography
                                  style={{
                                    whiteSpace: 'nowrap',
                                    fontSize: 13,
                                  }}
                                >
                                  {field.label}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {!isUndefined(field.arrow) && (
                                    <Grid item className="gridItem2">
                                      {field.arrow ? (
                                        field.arrow === 2 ? (
                                          <Avatar
                                            style={{
                                              backgroundColor:
                                                '#2296F3',
                                              height: 15,
                                              width: 15,
                                            }}
                                          >
                                            <ArrowUpwardIcon
                                              style={{
                                                color: 'white',
                                                fontSize: 'small',
                                              }}
                                            />
                                          </Avatar>
                                        ) : (
                                          <Avatar
                                            style={{
                                              backgroundColor:
                                                '#46de64',
                                              height: 15,
                                              width: 15,
                                            }}
                                          >
                                            <ArrowUpwardIcon
                                              style={{
                                                color: 'white',
                                                fontSize: 'small',
                                              }}
                                            />
                                          </Avatar>
                                        )
                                      ) : (
                                        <Avatar
                                          style={{
                                            backgroundColor:
                                              '#F05248',
                                            height: 15,
                                            width: 15,
                                          }}
                                        >
                                          <ArrowDownwardIcon
                                            style={{
                                              color: 'white',
                                              fontSize: 'small',
                                            }}
                                          />
                                        </Avatar>
                                      )}
                                    </Grid>
                                  )}
                                  <Grid
                                    item
                                    style={{
                                      marginLeft: 5,
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 700,
                                      }}
                                    >
                                      {/* {field.value !== '-' ? field.type === 'currency' ? currencyFormat.format(field.value) : percentageFormat.format(field.value) : field.value} */}
                                      {field.value !== '-'
                                        ? field.type === 'currency'
                                          ? currencyFormat.format(
                                              field.value,
                                            )
                                          : field.type ===
                                                'percentage' &&
                                              field.value > 1000
                                            ? '+1000%'
                                            : percentageFormat.format(
                                                field.value,
                                              )
                                        : field.value}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                style={{ marginBottom: '10px' }}
                onClick={() =>
                  navigate(
                    `/property-details/${property._id}/${section.page}`,
                  )
                }
              >
                <Typography style={{ fontWeight: 600 }}>
                  View Full Analysis
                </Typography>
                <FontAwesomeIcon
                  icon={['far', 'arrow-right']}
                  className="font-size-lg"
                  style={{ marginLeft: 10 }}
                />
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ),
    )
  }

  const displayStrategyCards = useMemo(
    () => renderStrategyCards(pitiAnalytics, strategies),
    [pitiAnalytics, strategies],
  )

  const [estimatesMenu] = useEstimatesMenu({
    property,
    activeUnit,
    anchorElEstimates,
    setAnchorElEstimates,
    assumptionsInput,
    onChangeAssumptions,
    handleDefinition,
    menu,
    setMenu,
    menuTarget,
    setMenuTarget,
    changeMenu,
    onChangeRange,
    estimatesCollapse,
    toggleCollapse,
    openNoData,
    setOpenNoData,
    affiliate,
    urlAffiliate,
  })

  return (
    <Dialog
      scroll="body"
      maxWidth="lg"
      open={open}
      onClose={onClose}
      // classes={{ paper: 'modal-content rounded border-0' }}
      fullWidth={true}
      onKeyDown={stopPropagationForTab}
    >
      <BlockUi
        blocking={loader || propertyLoading}
        loader={
          <PacmanLoader color={'var(--primary)'} loading={true} />
        }
        message={
          <div className="text-primary">
            {propertyLoading ? 'Loading' : 'Analyzing'} Property
          </div>
        }
      >
        <DialogTitle id="form-dialog-title">
          Creative Underwriting - {address}, {city}, {state} {zipcode}
        </DialogTitle>
        <DialogContent className="p-4">
          <div className="bg-secondary mb-3">
            <Typography>
              Fill out as much information as you can. The more
              information you provide, the easier it will be to decide
              on an exit strategy
            </Typography>
          </div>
          {/* <div className="bg-secondary mb-3">
            <Stepper
              style={{ padding: 24 }}
              className="stepper-horizontal-1"
              alternativeLabel
              activeStep={activeStep}
              connector={<StepConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={StepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div> */}

          <div>
            {property.realeflowData && (
              <div style={{ paddingTop: '20px' }}>
                <Grid container direction="row" spacing={4}>
                  <Grid item xs={6}>
                    {/* Top Section */}
                    <Grid
                      container
                      spacing={4}
                      style={{
                        display: 'flex',
                        alignItems: 'stretch',
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Paper
                          elevation={3}
                          style={{
                            padding: '20px',
                            marginBottom: '20px',
                            flex: 1,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                Loan Amount:{' '}
                                {includes(
                                  property?.realeflowData
                                    .LoanAmount ||
                                    property?.realeflowData
                                      ?.SubjectProperty?.TotalLoans,
                                  '$',
                                )
                                  ? property?.realeflowData
                                      .LoanAmount ||
                                    property?.realeflowData
                                      ?.SubjectProperty?.TotalLoans
                                  : currencyFormat.format(
                                      property?.realeflowData
                                        .LoanAmount ||
                                        property?.realeflowData
                                          ?.SubjectProperty
                                          ?.TotalLoans,
                                    )}
                              </Typography>
                              <Typography variant="body1">
                                Mortgage Interest Rate:{' '}
                                {property?.realeflowData
                                  ?.SubjectProperty
                                  ?.Mortgage1InterestRate
                                  ? includes(
                                      property?.realeflowData
                                        ?.SubjectProperty
                                        ?.Mortgage1InterestRate,
                                      '%',
                                    )
                                    ? property?.realeflowData
                                        ?.SubjectProperty
                                        ?.Mortgage1InterestRate
                                    : percentageFormat.format(
                                        Number(
                                          property?.realeflowData
                                            ?.SubjectProperty
                                            ?.Mortgage1InterestRate,
                                        ) / 100,
                                      )
                                  : property?.realeflowData
                                        ?.MortgageInterestRate
                                    ? includes(
                                        property?.realeflowData
                                          ?.MortgageInterestRate,
                                        '%',
                                      )
                                      ? property?.realeflowData
                                          ?.MortgageInterestRate
                                      : percentageFormat.format(
                                          Number(
                                            property?.realeflowData
                                              ?.MortgageInterestRate,
                                          ) / 100,
                                        )
                                    : percentageFormat.format(0)}
                              </Typography>
                              <Typography variant="body1">
                                Mortgage Payment:{' '}
                                {property?.realeflowData
                                  ?.SubjectProperty
                                  ?.MortgagePaymentAmount
                                  ? includes(
                                      property?.realeflowData
                                        ?.SubjectProperty
                                        ?.MortgagePaymentAmount,
                                      '$',
                                    )
                                    ? property?.realeflowData
                                        ?.SubjectProperty
                                        ?.MortgagePaymentAmount
                                    : currencyFormat.format(
                                        property?.realeflowData
                                          ?.SubjectProperty
                                          ?.MortgagePaymentAmount,
                                      )
                                  : property?.realeflowData
                                        ?.EstimatedMortgagePayment
                                    ? includes(
                                        property?.realeflowData
                                          ?.EstimatedMortgagePayment,
                                        '$',
                                      )
                                      ? property?.realeflowData
                                          ?.EstimatedMortgagePayment
                                      : currencyFormat.format(
                                          property?.realeflowData
                                            ?.EstimatedMortgagePayment,
                                        )
                                    : currencyFormat.format(0)}
                              </Typography>
                              <Typography variant="body1">
                                Annual Taxes:{' '}
                                {includes(
                                  property?.realeflowData
                                    ?.TaxAmount ||
                                    property?.realeflowData
                                      ?.SubjectProperty?.TaxAmount,
                                  '$',
                                )
                                  ? property?.realeflowData
                                      ?.TaxAmount ||
                                    property?.realeflowData
                                      ?.SubjectProperty?.TaxAmount
                                  : currencyFormat.format(
                                      property?.realeflowData
                                        ?.TaxAmount / 100 ||
                                        property?.realeflowData
                                          ?.SubjectProperty
                                          ?.TaxAmount / 100 ||
                                        0,
                                    )}
                              </Typography>
                              <Typography variant="body1">
                                Estimated Remaining Balance:{' '}
                                {includes(
                                  property?.realeflowData
                                    .EstimatedMortgageBalance ||
                                    property?.realeflowData
                                      ?.SubjectProperty?.Assessor
                                      ?.total_loans_pref,
                                  '$',
                                )
                                  ? property?.realeflowData
                                      .EstimatedMortgageBalance ||
                                    property?.realeflowData
                                      ?.SubjectProperty?.Assessor
                                      ?.total_loans_pref
                                  : currencyFormat.format(
                                      property?.realeflowData
                                        .EstimatedMortgageBalance ||
                                        property?.realeflowData
                                          ?.SubjectProperty?.Assessor
                                          ?.total_loans_pref,
                                    )}
                              </Typography>
                            </Grid>
                          </Grid>
                          <div className="my-2">
                            <DisplayRealeflowData
                              property={property}
                              button
                            />
                          </div>
                          <Divider />
                          {map(
                            filter(
                              criticalFields['HomeDetails'],
                              (field) => field.hide !== true,
                            ),
                            (section) => {
                              if (section.type === 'divider') {
                                return (
                                  <Divider
                                    style={{
                                      backgroundColor: 'transparent',
                                      borderTop: '1px dashed grey',
                                      height: '0px',
                                      margin: '20px 0',
                                    }}
                                  />
                                )
                              } else {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent:
                                          'space-between',
                                      }}
                                    >
                                      {renderInputFields(section)}
                                      <Tooltip
                                        title={section.tooltip}
                                      >
                                        <IconButton>
                                          <Info />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </>
                                )
                              }
                            },
                          )}
                        </Paper>
                      </Grid>
                    </Grid>

                    {/* Middle Section */}
                    <Grid
                      container
                      spacing={4}
                      style={{
                        display: 'flex',
                        alignItems: 'stretch',
                      }}
                    >
                      <Grid item xs={12}>
                        <Paper
                          elevation={3}
                          style={{
                            padding: '20px',
                            marginBottom: '20px',
                            flex: 1,
                          }}
                        >
                          <Typography
                            variant="h4"
                            component="div"
                            textAlign="center"
                          >
                            PITI Total:
                          </Typography>
                          <Typography
                            variant="h3"
                            component="div"
                            textAlign="center"
                            style={{ fontWeight: 'bold' }}
                          >
                            {currencyFormat.format(
                              calculatePitiTotal(pitiData),
                            )}
                          </Typography>
                          <Divider />
                          {map(
                            filter(
                              criticalFields['PITI'],
                              (field) => field.hide !== true,
                            ),
                            (section) => {
                              if (section.type === 'divider') {
                                return (
                                  <Divider
                                    style={{
                                      backgroundColor: 'transparent',
                                      borderTop: '1px dashed grey',
                                      height: '0px',
                                      margin: '20px 0',
                                    }}
                                  />
                                )
                              } else {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent:
                                          'space-between',
                                      }}
                                    >
                                      {renderInputFields(section)}
                                      <Tooltip
                                        title={section.tooltip}
                                      >
                                        <IconButton>
                                          <Info />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </>
                                )
                              }
                            },
                          )}
                        </Paper>
                      </Grid>
                    </Grid>

                    {/* Bottom Section */}
                    <Grid
                      container
                      spacing={4}
                      style={{
                        display: 'flex',
                        alignItems: 'stretch',
                      }}
                    >
                      <Grid item xs={12}>
                        <Paper
                          elevation={3}
                          style={{
                            padding: '20px',
                            marginBottom: '20px',
                            flex: 1,
                          }}
                        >
                          <Typography
                            variant="h4"
                            component="div"
                            textAlign="center"
                          >
                            Entry Fee:
                          </Typography>
                          <Typography
                            variant="h3"
                            component="div"
                            textAlign="center"
                            style={{
                              fontWeight: 'bold',
                              paddingBottom: '20px',
                            }}
                          >
                            {currencyFormat.format(
                              calculateEntryFee(pitiData),
                            )}
                          </Typography>
                          <Typography
                            variant="h4"
                            component="div"
                            textAlign="center"
                          >
                            % of Purchase Price:
                          </Typography>
                          <Typography
                            variant="h3"
                            component="div"
                            textAlign="center"
                            style={{ fontWeight: 'bold' }}
                          >
                            {percentageFormat.format(
                              calculateEntryFee(pitiData) /
                                analysisInput.offerPrice || 0,
                            )}
                          </Typography>
                          <Divider />
                          {map(
                            filter(
                              criticalFields['EntryFee'],
                              (field) => field.hide !== true,
                            ),
                            (section) => {
                              if (section.type === 'divider') {
                                return (
                                  <Divider
                                    style={{
                                      backgroundColor: 'transparent',
                                      borderTop: '1px dashed grey',
                                      height: '0px',
                                      margin: '20px 0',
                                    }}
                                  />
                                )
                              } else {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent:
                                          'space-between',
                                      }}
                                    >
                                      {renderInputFields(section)}
                                      <Tooltip
                                        title={section.tooltip}
                                      >
                                        <IconButton>
                                          <Info />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </>
                                )
                              }
                            },
                          )}
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={4}
                      style={{
                        display: 'flex',
                        alignItems: 'stretch',
                      }}
                    >
                      <Grid item xs={12}>
                        <Paper
                          elevation={3}
                          style={{
                            padding: '20px',
                            marginBottom: '20px',
                            flex: 1,
                          }}
                        >
                          <Typography
                            variant="h4"
                            component="div"
                            textAlign="center"
                          >
                            Seller Finance Terms:
                          </Typography>
                          <Typography
                            variant="h3"
                            component="div"
                            textAlign="center"
                            style={{
                              fontWeight: 'bold',
                              paddingBottom: '20px',
                            }}
                          >
                            {currencyFormat.format(
                              totalPrincipalPaid,
                            )}
                          </Typography>

                          <Divider />
                          {map(
                            filter(
                              criticalFields['SellerFinancing'],
                              (field) => field.hide !== true,
                            ),
                            (section) => {
                              if (section.type === 'divider') {
                                return (
                                  <Divider
                                    style={{
                                      backgroundColor: 'transparent',
                                      borderTop: '1px dashed grey',
                                      height: '0px',
                                      margin: '20px 0',
                                    }}
                                  />
                                )
                              } else {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent:
                                          'space-between',
                                      }}
                                    >
                                      {renderInputFields(section)}
                                      <Tooltip
                                        title={section.tooltip}
                                      >
                                        <IconButton>
                                          <Info />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </>
                                )
                              }
                            },
                          )}
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={4}
                      style={{
                        display: 'flex',
                        alignItems: 'stretch',
                      }}
                    >
                      <Grid item xs={12}>
                        <Paper
                          elevation={3}
                          style={{
                            padding: '20px',
                            marginBottom: '20px',
                            flex: 1,
                          }}
                        >
                          <Typography
                            variant="h4"
                            component="div"
                            textAlign="center"
                          >
                            Total Monthly Payment:
                          </Typography>
                          <Typography
                            variant="h3"
                            component="div"
                            textAlign="center"
                            style={{
                              fontWeight: 'bold',
                              paddingBottom: '20px',
                            }}
                          >
                            {currencyFormat.format(
                              getMortgage(pitiData?.creative?.HML) +
                                getMortgage(pitiData?.creative?.PML) +
                                sumBy(
                                  pitiData?.creative?.existingLoans,
                                  getMortgage,
                                ),
                            )}
                          </Typography>
                          <Grid
                            item
                            style={{
                              overflow: 'scroll',
                              width: '100%',
                            }}
                          >
                            <List
                              className="nav-line d-flex mt-3"
                              style={{
                                width:
                                  150 +
                                  pitiData?.creative?.existingLoans
                                    ?.length *
                                    100,
                              }}
                            >
                              <ListItem
                                button
                                disableRipple
                                selected={financingTab === 'HML'}
                                onClick={() => {
                                  setFinancingTab('HML')
                                }}
                                style={{ paddingBottom: 0 }}
                              >
                                <Typography
                                  className="card-header--title"
                                  style={{ textAlign: 'center' }}
                                >
                                  HML
                                </Typography>
                                <div
                                  className="divider"
                                  style={{
                                    width: '55%',
                                  }}
                                />
                              </ListItem>
                              <ListItem
                                button
                                disableRipple
                                selected={financingTab === 'PML'}
                                onClick={() => {
                                  setFinancingTab('PML')
                                }}
                                style={{ paddingBottom: 0 }}
                              >
                                <Typography
                                  className="card-header--title"
                                  style={{ textAlign: 'center' }}
                                >
                                  PML
                                </Typography>
                                <div
                                  className="divider"
                                  style={{ width: '55%' }}
                                />
                              </ListItem>
                              {map(
                                pitiData?.creative?.existingLoans,
                                (loan, index) => (
                                  <ListItem
                                    button
                                    disableRipple
                                    selected={
                                      financingTab ===
                                      `existingLoans.${index}`
                                    }
                                    onClick={() => {
                                      setFinancingTab(
                                        `existingLoans.${index}`,
                                      )
                                    }}
                                    style={{ paddingBottom: 0 }}
                                  >
                                    <Typography
                                      className="card-header--title"
                                      style={{ textAlign: 'center' }}
                                    >
                                      Existing Loan {index + 1}
                                    </Typography>
                                    <div
                                      className="divider"
                                      style={{ width: '55%' }}
                                    />
                                  </ListItem>
                                ),
                              )}
                              <ListItem
                                button
                                disableRipple
                                style={{ padding: 0 }}
                                // selected={financingTab === 'HML'}
                              >
                                <IconButton
                                  size="small"
                                  onClick={addAdditionalLoan}
                                >
                                  <Add />
                                </IconButton>
                              </ListItem>
                            </List>
                          </Grid>
                          <Divider />
                          {map(
                            filter(
                              criticalFields['Financing'],
                              (field) => field.hide !== true,
                            ),
                            (section) => {
                              if (section.type === 'divider') {
                                return (
                                  <Divider
                                    style={{
                                      backgroundColor: 'transparent',
                                      borderTop: '1px dashed grey',
                                      height: '0px',
                                      margin: '20px 0',
                                    }}
                                  />
                                )
                              } else {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent:
                                          'space-between',
                                      }}
                                    >
                                      {renderInputFields(section)}
                                      <Tooltip
                                        title={section.tooltip}
                                      >
                                        <IconButton>
                                          <Info />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </>
                                )
                              }
                            },
                          )}
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      spacing={4}
                      direction="column"
                      style={{
                        display: 'flex',
                        alignItems: 'stretch',
                      }}
                    >
                      <Grid item>
                        <Paper
                          elevation={3}
                          style={{
                            padding: '20px',
                            marginBottom: '20px',
                            flex: 1,
                          }}
                        >
                          <Grid item xs={12}>
                            <Autocomplete
                              id="checkboxes-tags-demo"
                              multiple
                              disableCloseOnSelect
                              options={filterPreferedStrategies(
                                preferences,
                                props?.session?.me?.activeOrg?.member,
                                false,
                              )}
                              value={strategies}
                              onChange={(event, newValue) => {
                                if (!isEmpty(newValue))
                                  setStrategies(newValue)
                              }}
                              disableClearable
                              getOptionLabel={(option) =>
                                getStrategyName(
                                  option,
                                  props?.session?.me?.activeOrg
                                    ?.member,
                                )
                              }
                              // isOptionEqualToValue={(option, value) =>
                              //   option === value.value && value.active
                              // }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Strategies"
                                />
                              )}
                            />
                          </Grid>
                          {displayStrategyCards}
                        </Paper>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          spacing={4}
                        >
                          <ContactInformation property={property} />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <CommentsDialog
                          property={property}
                          userId={props?.session?.me?._id}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Typography style={{ flex: 1 }} />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginRight: 10 }}
            onClick={() => {
              onClose(true)
            }}
          >
            Close
          </Button>
          {activeStep > 0 && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginRight: 10 }}
              onClick={() => {
                setActiveStep(activeStep - 1)
              }}
            >
              Back
            </Button>
          )}
          <Button
            // disabled={isDisabled}
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginRight: 10 }}
            onClick={() => {
              // if (activeStep < steps.length - 1)
              //   setActiveStep(activeStep + 1)
              // else
              savePitiToProperty()
            }}
          >
            {/* {activeStep < steps.length - 1 ? 'Next Step' : 'Finish'} */}
            Finish
          </Button>
        </DialogActions>
      </BlockUi>

      {estimatesMenu}
    </Dialog>
  )
}

export default withSession(SubtoPITIDialog)
