import React from 'react'

import EnterpriseAccount from '@/housefolios-components/CreateAccount/EnterpriseAccount'

export default function EnterpriseAccountPage(props) {
  return (
    <>
      {/* <ExampleWrapperContainer>
        <Grid container direction='row' alignItems='stretch' justifyContent='center'>
          <Grid item xs={12} md={6} > */}
      <EnterpriseAccount {...props} />
      {/* </Grid>
        </Grid >
      </ExampleWrapperContainer> */}
    </>
  )
}
