/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-09-11 14:41:02
 * @ Description: Dialog to copy a portfolio and all of its properties
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import map from 'lodash/map'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import head from 'lodash/head'
import get from 'lodash/get'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Dialog,
  Grid,
  Typography,
  Card,
  Button,
  List,
  ListItem,
  MenuItem,
  TextField,
} from '@mui/material'

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import AssumptionsAccordion from '@/housefolios-components/AssumptionsSettings/Accordion'
import hero1 from '@/assets/images/hero-bg/hero-1.jpg'
import { useMutation } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { COPY_PORTFOLIO } from '../mutations'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { enqueueSnackbar } from 'notistack'
import { GET_PORTFOLIOS } from '../queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { PacmanLoader } from 'react-spinners'
import BlockUi from 'react-block-ui'
import withSession from '@/housefolios-components/Session/withSession'
import Autocomplete from '@mui/material/Autocomplete'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import {
  filterPreferedStrategies,
  getStrategyName,
  getStrategyOptions,
} from '@/utils/stratagies'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function DuplicatePortfolioComponent(props) {
  const { sidebarMenu, portfolio } = props

  const [openCopyPortfolio, setCopyPortfolio] = useState(false)
  const [loader, setLoader] = useState(false)
  const handleOpenCopyPortfolio = () => {
    setCopyPortfolio(true)
    setLoader(false)
  }
  const handleCloseCopyPortfolio = () => {
    setCopyPortfolio(false)
  }

  const [name, setName] = useState('Copy - ' + portfolio.name)
  const [description, setDescription] = useState(
    portfolio.description || '',
  )
  const [strategy, setStrategy] = useState(portfolio.strategy)
  const [assumptions, setAssumptions] = useState({})

  const [preferences, setPreferences] = useState(
    merge(
      cloneDeep(DEFAULT_PREFERENCES),
      props?.session?.me?.activeOrg?.memberPreferences,
      props?.session?.me?.activeOrg?.preferences,
      { general: { strategies: portfolio.strategies } },
    ),
  )

  if (
    !includes(
      filterPreferedStrategies(
        preferences,
        props?.session?.me?.activeOrg?.member,
        true,
      ),
      strategy,
    )
  )
    setStrategy(
      head(
        filterPreferedStrategies(
          preferences,
          props?.session?.me?.activeOrg?.member,
          true,
        ),
      ),
    )

  const handleChangeDropdown = (event, value, collection, name) => {
    let newPreferences = { ...preferences }
    let newValue = value
    if (name === 'strategies') {
      newPreferences[collection][name] = map(
        newPreferences[collection][name],
        (preference) => {
          return {
            value: preference.value,
            active: includes(newValue, preference.value),
            memberOnly: preference.memberOnly,
            memberExcluded: preference.memberExcluded,
          }
        },
      )
    } else newPreferences[collection][name] = newValue
    setPreferences(newPreferences)
  }

  useEffect(() => {
    const {
      _id: RemovedId,
      __typename: RemovedTypename,
      ...defaultAssumptions
    } = portfolio.assumptions

    setName('Copy - ' + portfolio.name)
    setDescription(portfolio.description)
    setStrategy(portfolio.strategy)
    setAssumptions(defaultAssumptions)
    setPreferences(
      merge(
        cloneDeep(DEFAULT_PREFERENCES),
        props?.session?.me?.activeOrg?.memberPreferences,
        props?.session?.me?.activeOrg?.preferences,
        { general: { strategies: portfolio.strategies } },
      ),
    )
  }, [portfolio])

  const [copyPortfolio] = useMutation(COPY_PORTFOLIO)

  const [activeTab, setActiveTab] = useState('0')

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const { limits = {} } = props.session.me

  return (
    <Query query={GET_PORTFOLIOS}>
      {({ loading, error, data }) => {
        if (loading) return 'Loading...'
        if (error) return `Error! ${error.message}`
        const { portfolios = [] } = data
        return (
          <>
            {sidebarMenu && (
              <ListItem
                button
                onClick={
                  portfolios.length >= limits.portfolioLimit
                    ? () => props.toggleLimit('portfolio')
                    : handleOpenCopyPortfolio
                }
              >
                <FileCopyOutlinedIcon
                  style={{ marginRight: 5, color: '#757575' }}
                />
                <span>Duplicate Portfolio</span>
              </ListItem>
            )}
            <Dialog
              open={openCopyPortfolio}
              onClose={handleCloseCopyPortfolio}
              maxWidth="lg"
              scroll="body"
              classes={{
                paper:
                  'modal-content rounded border-0 bg-white p-3 p-xl-0',
              }}
              onKeyDown={stopPropagationForTab}
            >
              <BlockUi
                blocking={loader}
                loader={
                  <PacmanLoader
                    color={'var(--primary)'}
                    loading={true}
                  />
                }
                message={
                  <div className="text-primary">
                    Duplicating Portfolio
                  </div>
                }
              >
                <ValidatorForm
                  onSubmit={() => {
                    setLoader(true)
                    copyPortfolio({
                      variables: {
                        portfolioId: portfolio._id,
                        portfolioInput: {
                          name,
                          strategy,
                          strategies: get(
                            preferences,
                            'general.strategies',
                          ),
                          description,
                          assumptions,
                        },
                      },
                      refetchQueries: [
                        {
                          query: GET_PORTFOLIOS,
                        },
                        {
                          query: GET_ME,
                        },
                      ],
                    }).then(() => {
                      enqueueSnackbar(`Changes Saved`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                      })
                      handleCloseCopyPortfolio()
                    })
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xl={5}>
                      <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image rounded br-xl-right-0"
                            style={{
                              backgroundImage: 'url(' + hero1 + ')',
                            }}
                          />
                          <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                          <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                            <div className="text-white mt-3">
                              <h1 className="display-4 my-3 font-weight-bold">
                                Duplicate portfolio?
                              </h1>
                              <p className="font-size-md mb-0 text-white-50">
                                No new data is pulled when duplicating
                                a portfolio. So, it does not count
                                against your monthly analyzing limit.
                                This is helpful when wanting to play
                                around with numbers without messing up
                                your current inputs.
                              </p>
                              <div className="divider border-1 mx-auto mx-xl-0 my-5 border-light opacity-2 rounded w-25" />
                              {/* <div>
                                <Button
                                  href="http://help.housefolios.com/en/articles/4658540-duplicate-portfolio"
                                  target="_blank"
                                  //onClick={(e) => e.preventDefault()}
                                  style={{
                                    backgroundColor: '#f4782e',
                                    color: 'white',
                                  }}
                                >
                                  <span className="btn-wrapper--label">
                                    Learn More
                                  </span>
                                  <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon
                                      icon={['fas', 'arrow-right']}
                                    />
                                  </span>
                                </Button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xl={7}>
                      <div className="mt-4 mt-xl-0">
                        <List className="nav-tabs nav-tabs-primary tabs-animated tabs-animated-line justify-content-center d-flex">
                          <ListItem
                            button
                            className="px-0 mx-3"
                            disableRipple
                            selected={activeTab === '0'}
                            onClick={() => {
                              toggle('0')
                            }}
                          >
                            <span className="py-1 font-weight-bold">
                              Portfolio Details
                            </span>
                          </ListItem>
                          {/* <ListItem
                            button
                            className="px-0 mx-3"
                            disableRipple
                            selected={activeTab === '1'}
                            onClick={() => {
                              toggle('1');
                            }}>
                            <span className="py-1 font-weight-bold">Portfolio Assumptions</span>
                          </ListItem> */}
                        </List>

                        <div
                          className={clsx('tab-item-wrapper', {
                            active: activeTab === '0',
                          })}
                          index={0}
                        >
                          <Card className="shadow-none bg-transparent p-4 border-0">
                            <div>
                              <div className="mb-3">
                                <TextValidator
                                  variant="outlined"
                                  margin="dense"
                                  label="Portfolio Name"
                                  fullWidth={true}
                                  value={name}
                                  onChange={(event) =>
                                    setName(event.target.value)
                                  }
                                  required={true}
                                  validators={[
                                    'required',
                                    'maxStringLength: 20',
                                  ]}
                                  errorMessages={[
                                    'This field is required',
                                    'You cannot exceed 20 characters',
                                  ]}
                                />
                              </div>
                              <div className="mb-3">
                                <TextValidator
                                  variant="outlined"
                                  margin="dense"
                                  label="Portfolio Description"
                                  fullWidth={true}
                                  value={description}
                                  onChange={(event) =>
                                    setDescription(event.target.value)
                                  }
                                />
                              </div>
                              <div className="mb-3">
                                <Autocomplete
                                  id="checkboxes-tags-demo"
                                  multiple
                                  disableCloseOnSelect
                                  options={filterPreferedStrategies(
                                    preferences,
                                    props?.session?.me?.activeOrg
                                      ?.member,
                                    false,
                                  )}
                                  value={filterPreferedStrategies(
                                    preferences,
                                    props?.session?.me?.activeOrg
                                      ?.member,
                                    true,
                                  )}
                                  onChange={(event, newValue) => {
                                    if (!isEmpty(newValue))
                                      handleChangeDropdown(
                                        event,
                                        newValue,
                                        'general',
                                        'strategies',
                                      )
                                  }}
                                  getOptionLabel={(option) =>
                                    getStrategyName(
                                      option,
                                      props.session.me.activeOrg
                                        .member,
                                    )
                                  }
                                  disableClearable
                                  // isOptionEqualToValue={(
                                  //   option,
                                  //   value,
                                  // ) =>
                                  //   option === value.value &&
                                  //   value.active
                                  // }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Strategies"
                                    />
                                  )}
                                />
                              </div>
                              <div className="mb-3">
                                <TextValidator
                                  select
                                  variant="outlined"
                                  margin="dense"
                                  label="Default Property Strategy"
                                  fullWidth={true}
                                  value={strategy}
                                  onChange={(event) =>
                                    setStrategy(event.target.value)
                                  }
                                  required={true}
                                  validators={['required']}
                                  errorMessages={[
                                    'This field is required',
                                  ]}
                                >
                                  {map(
                                    filter(
                                      preferences.general[
                                        'strategies'
                                      ],
                                      'active',
                                    ),
                                    (preference) => (
                                      <MenuItem
                                        key={preference.value}
                                        value={preference.value}
                                      >
                                        {getStrategyName(
                                          preference.value,
                                          props.session.me.activeOrg
                                            .member,
                                        )}
                                      </MenuItem>
                                    ),
                                  )}
                                </TextValidator>
                              </div>
                              <div className="text-center">
                                <Button
                                  variant="contained"
                                  className="font-weight-bold px-4 my-3"
                                  color="primary"
                                  style={{ color: 'white' }}
                                  type="submit"
                                >
                                  Create Portfolio
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </div>
                        <div
                          className={clsx('tab-item-wrapper', {
                            active: activeTab === '1',
                          })}
                          index={1}
                        >
                          <Card className="shadow-none bg-transparent p-4 border-0">
                            <div>
                              <Grid
                                container
                                direction="column"
                                alignItems="center"
                              >
                                <Grid item>
                                  <AssumptionsAccordion
                                    assumptions={assumptions}
                                    setAssumptions={setAssumptions}
                                  />
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    className="font-weight-bold px-4 my-3"
                                    color="primary"
                                    style={{ color: 'white' }}
                                    type="submit"
                                    disabled={loader}
                                  >
                                    Create Portfolio
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          </Card>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </BlockUi>
            </Dialog>
          </>
        )
      }}
    </Query>
  )
}

export default withSession(DuplicatePortfolioComponent)
