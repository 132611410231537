/**
 * @ Author: Housefolios
 * @ Create Time: 2022-11-07 18:16:32
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-26 15:44:27
 * @ Description: Function for producing the estimatesMenu
 */

import React, { Fragment, useEffect, useRef, useState } from 'react'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import remove from 'lodash/remove'
import lodashOrderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import isUndefined from 'lodash/isUndefined'
import kebabCase from 'lodash/kebabCase'
import includes from 'lodash/includes'
import replace from 'lodash/replace'
import sortBy from 'lodash/sortBy'
import meanBy from 'lodash/meanBy'
import slice from 'lodash/slice'
import get from 'lodash/get'
import split from 'lodash/split'
import ceil from 'lodash/ceil'
import head from 'lodash/head'
import round from 'lodash/round'
import debounce from 'lodash/debounce'
import isNil from 'lodash/isNil'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid2,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableCell,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  Close,
  Compare,
  Edit,
  ExpandLess,
  ExpandMore,
  Tune,
} from '@mui/icons-material'
import { DEFAULT_ESTIMATES } from '@/constants/defaultValues'
import useAffiliate from '@/hooks/useAffiliate'

import useSorting, {
  getSorting,
  stableSort,
} from '@/hooks/useSorting'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NumericFormat } from 'react-number-format'
import { useMutation } from '@apollo/client'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import RehabCalc from '@/housefolios-components/Dialogs/RehabCalc'
import { createPepDealsauceEstimateFields } from '@/utils/rehabCalc'

function useEstimatesMenu(props) {
  const {
    property,
    determinedComps,
    activeUnit,
    assumptionsInput,
    onChangeAssumptions,
    anchorElEstimates,
    setAnchorElEstimates,
    handleDefinition,
    menu,
    setMenu,
    menuTarget,
    setMenuTarget,
    changeMenu,
    onChangeRange,
    estimatesCollapse,
    toggleCollapse,
    openNoData,
    setOpenNoData,
    affiliate,
    urlAffiliate,
  } = props

  const [openCompDialog, setOpenCompDialog] = useState(false)
  const [selectedComp, setSelectedComp] = useState(null)

  const [openPepDeaulsauceEstimate, setOpenPepDealsauceEstimate] =
    useState(false)
  const [pepDealsauceAccordion, setPepDealsauceAccordion] =
    useState('e0')

  const togglePepDealsauceAccordion = (panel) => {
    if (pepDealsauceAccordion === panel) {
      setPepDealsauceAccordion(null)
    } else {
      setPepDealsauceAccordion(panel)
    }
  }

  const [defaultOrderByTarget = 'saleDate', defaultOrder = 'desc'] =
    split(props.ordering || 'saleDate-desc', '-')

  const [{ order, orderBy }, { handleRequestSort }] = useSorting({
    defaultOrder,
    defaultOrderBy: defaultOrderByTarget,
  })

  const [page, setPage] = useState(
    1,
    // parseInt(
    //   localStorage.getItem(
    //     `${property._id}-comps-actual-list-page`,
    //   ),
    // ) || 1,
  )
  const handleChange = (event, value) => {
    // localStorage.setItem(
    //   `${property._id}-comps-actual-list-page`,
    //   value,
    // )
    setPage(value)
  }

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  function formatDate(date) {
    var today = new Date(date),
      d = new Date(
        today.getTime() + Math.abs(today.getTimezoneOffset() * 60000),
      ),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = '' + d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    while (year.length < 4) year = '0' + year

    return [year, month, day].join('-')
  }

  const tableHeaders = [
    { title: 'Address', name: 'address' },
    { title: 'City', name: 'city' },
    { title: 'State', name: 'state' },
    { title: 'Zip', name: 'zip' },
    { title: 'Beds', name: 'beds' },
    { title: 'Baths', name: 'baths' },
    {
      title: 'SqFt',
      name: 'sqft',
      formatter: (x) => numberFormat.format(x),
    },
    { title: 'Year', name: 'year' },
    {
      title: 'Sold Price',
      name: 'estval',
      formatter: (x) => currencyFormat.format(x),
    },
    {
      title: 'Date Sold',
      name: 'saleDate',
      formatter: (x) => formatDate(x),
    },
    {
      title: 'Distance Away',
      name: 'distance',
      formatter: (x) => numberFormat.format(x),
    },
    {
      title: 'Days on Market (D.O.M)',
      name: 'daysOnMarket',
      formatter: (x) => {
        if (!isNil(x)) return numberFormat.format(x)
        else return '-'
      },
    },
    { title: 'Rehab Level', name: 'rehabLevel' },
    {
      title: 'Price Adjustment',
      name: 'priceAdjustment',
      formatter: (x) => Number(x) + '%',
    },
    {
      title: 'Date Added',
      name: 'createdAt',
      formatter: (x) => formatDate(x),
    },
    { title: 'Added By', name: 'createdBy.username' },
  ]

  const visuallyHidden = {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }

  const count = ceil(determinedComps?.length / 25)
  if (page > count && count) setPage(count || 1)

  const addedDate = new Date(property.createdAt).toDateString()

  const menuTitle = {
    rent: 'Rent',
    rentBOR: 'Rent',
    afterRepairValue: 'After Repair Value',
    listPrice: 'Estimated Asking Price',
    propertyTaxes: 'Estimated Annual Taxes',
    rehabCost: 'Estimated Rehab Cost',
    rehabCostBRRRR: 'Estimated Rehab Cost',
    rehabCostBOR: 'Estimated Rehab Cost',
    rehabCostFixFlip: 'Estimated Rehab Cost',
    rehabCostTurnkey: 'Estimated Rehab Cost',
    rehabCostSTR: 'Estimated Rehab Cost',
    rehabCostWholesale: 'Estimated Rehab Cost',
    rehabCostAstroFlip: 'Estimated Rehab Cost',

    offerPrice: 'Estimated Purchase Price',
    // avgRate: 'Estimated Daily Rate',
    // occupancyRate: 'Estimated Occupancy Rate',
  }
  const description = {
    rent: `Estimated monthly rental income from property.`,
    rentBOR: `Estimated monthly rental income from property.`,
    afterRepairValue: `After Repair Value (ARV) is the estimated worth of a property after all the repair have been completed.`,
    listPrice: `These figures do not represent the actual asking price. Instead, they are estimates based on comparable home sales in the area or derived from your assumptions. While the estimate may be accurate for properties currently on the market, it might not be as reliable for off-market properties.`,
    //'We were unable to find a asking price for this property. Here is our Asking Price Estimate These numbers were gathered when the property was first added. To see the most current data, add the property again.',
    propertyTaxes: `Estimated annual taxes for the property`,
    rehabCost: `Estimated cost to rehab the property`,
    rehabCostBRRRR: `Estimated cost to rehab the property`,
    rehabCostBOR: `Estimated cost to rehab the property`,
    rehabCostFixFlip: `Estimated cost to rehab the property`,
    rehabCostTurnkey: `Estimated cost to rehab the property`,
    rehabCostSTR: `Estimated cost to rehab the property`,
    rehabCostWholesale: `Estimated cost to rehab the property`,
    rehabCostAstroFlip: `Estimated cost to rehab the property`,
    offerPrice: `Estimated purchase price`,
    // avgRate: 'Estimated Daily Rate',
    // occupancyRate: 'Estimated Occupancy Rate',
  }
  const estimates = merge(DEFAULT_ESTIMATES, property.estimates)

  const compValues = map(determinedComps, (comp) => {
    return {
      _id: comp._id,
      propertyPrice: Number(
        comp.estval * (1 + comp.priceAdjustment / 100),
      ),
      propertyPerSqft: comp.sqft
        ? Number(comp.estval * (1 + comp.priceAdjustment / 100)) /
            comp.sqft || 0
        : null,
      sqft: comp.sqft,
    }
  })

  //const useDefault = !hasEstimates && !isUndefined(defaults[menu])

  const average = {
    afterRepairValue: meanBy(
      filter(compValues, 'propertyPrice'),
      'propertyPrice',
    ),
    secondary: 'Adjusted comp average',
  }
  const lowestAverage = {
    afterRepairValue: meanBy(
      slice(
        sortBy(filter(compValues, 'propertyPrice'), 'propertyPrice'),
        0,
        3,
      ),
      'propertyPrice',
    ),
    secondary: 'Adjusted lowest 3 comp average',
  }

  let menuArray = [
    // {
    //   field: estimates.listPriceEstimate,
    //   icon: <Avatar className="rounded"><LocalOffer /></Avatar>,
    // },
    {
      field: estimates.house,
      icon: (
        <Avatar
          className="rounded"
          imgProps={{ className: 'app-sidebar-logo--icon' }}
          src={
            urlAffiliate
              ? get(
                  affiliate,
                  'sidebar.sidebarMemberSmallImage.0.url',
                ) || '/images/LeftNavLogo.png'
              : '/images/LeftNavLogo.png'
          }
        />
      ),
      source: 'house',
    },
    // {
    //   field: estimates.housefolios,
    //   icon: <Avatar className="rounded"><CenterFocusStrong /></Avatar>,
    // },
    // {
    //   field: estimates.housefoliosCounty,
    //   icon: <Avatar className="rounded"><NotListedLocation /></Avatar>,
    // },
    // {
    //   field: estimates.housefoliosSqft,
    //   icon: <Avatar className="rounded"><SquareFootIcon /></Avatar>,
    // },
    //  {
    //    field: estimates.housefoliosAssumptionSqft,
    //    icon: <Avatar className="rounded"><SquareFootIcon /></Avatar>,
    //    source: 'housefoliosAssumptionSqft',
    //  },
    // {
    //   field: estimates.housefoliosBed,
    //   icon: <Avatar className="rounded"><HotelIcon /></Avatar>,
    // },
    // {
    //   field: estimates.housefoliosBedAndSqft,
    //   icon: <Avatar className="rounded"><HomeIcon /></Avatar>,
    // },
    // {
    //   field: 'housefolios',
    //   icon: <Avatar className="rounded" src="/images/LeftNavLogo.png" />,
    //   secondary: 'Range provided by Housefolios',
    //   min: estimates.housefoliosLow,
    //   mid: estimates.housefoliosAverage,
    //   max: estimates.housefoliosHigh,
    //   minLabel: 'Min',
    //   midLabel: 'Mean',
    //   maxLabel: 'Max',
    //   sourceMin: 'housefoliosLow',
    //   source: 'housefoliosAverage',
    //   sourceMax: 'housefoliosHigh',
    // },
    {
      field: estimates.reAPI,
      icon: <Avatar className="rounded" src="/images/REAPI.png" />,
      source: 'reAPI',
    },
    // {
    //   field: estimates.houseCanary,
    //   icon: (
    //     <Avatar className="rounded" src="/images/houseCanary.png" />
    //   ),
    //   source: 'houseCanary',
    // },
    // {
    //   field: 'houseCanary',
    //   icon: (
    //     <Avatar className="rounded" src="/images/houseCanary.png" />
    //   ),
    //   secondary: 'Range provided by HouseCanary',
    //   min: estimates.houseCanaryLow,
    //   mid: estimates.houseCanaryAverage,
    //   max: estimates.houseCanaryHigh,
    //   minLabel: 'Min',
    //   midLabel: 'Mean',
    //   maxLabel: 'Max',
    //   sourceMin: 'houseCanaryLow',
    //   source: 'houseCanaryAverage',
    //   sourceMax: 'houseCanaryHigh',
    // },
    {
      field: estimates.smartAsset,
      icon: (
        <Avatar className="rounded" src="/images/smart_asset.png" />
      ),
      source: 'smartAsset',
    },
    // {
    //   field: estimates.airdna,
    //   icon: <Avatar className="rounded" src="/images/airDNA.png" />,
    //   source: 'airdna',
    // },
    // {
    //   field: estimates.zillow,
    //   icon: <Avatar className="rounded" src="/images/ZillowLogo.png" />,
    // },
    // {
    //   field: 'rentometer',
    //   icon: <Avatar className="rounded" src="/images/Rentometer_Logo.png" />,
    //   secondary: 'Range provided by Rentometer',
    //   min: estimates.rentometerLow,
    //   mid: estimates.rentometer,
    //   max: estimates.rentometerHigh,
    //   minLabel: 'Min',
    //   midLabel: 'Mean',
    //   maxLabel: 'Max',
    //   sourceMin: 'rentometerLow',
    //   source: 'rentometer',
    //   sourceMax: 'rentometerHigh',
    // },
    {
      field: estimates.housefoliosAssumption,
      icon: (
        <Avatar className="rounded">
          <Tune />
        </Avatar>
      ),
      source: 'housefoliosAssumption',
    },
  ]

  if (
    affiliate?.memberValue === 'pep' ||
    affiliate?.memberValue === 'pacemorby'
  ) {
    menuArray.push({
      field: {
        ...estimates?.affiliate,
        secondary: `${affiliate?.companyName} Estimate`,
      },
      icon: (
        <Avatar
          className="rounded"
          imgProps={{ className: 'app-sidebar-logo--icon' }}
          src={
            get(affiliate, 'sidebar.sidebarMemberSmallImage.0.url') ||
            '/images/LeftNavLogo.png'
          }
        />
      ),
      action: (
        <>
          <IconButton
            onClick={(event) => {
              event.stopPropagation()
              setOpenPepDealsauceEstimate(true)
            }}
            style={{ backgroundColor: '#bebebd' }}
          >
            <Edit style={{ color: 'white' }} />
            <RehabCalc
              open={openPepDeaulsauceEstimate}
              onClose={() => setOpenPepDealsauceEstimate(false)}
              property={property}
              affiliate={affiliate}
              targetValue={menu?.replace('Cost', 'CalcTotal')}
              assumptionsInput={assumptionsInput}
              changeMenu={changeMenu}
              onChangeAssumptions={onChangeAssumptions}
            />
          </IconButton>
        </>
      ),
      calcValue: (value, assumptionsInput) => {
        const sections = createPepDealsauceEstimateFields(
          property,
          value,
          assumptionsInput,
        )
        return sum(
          map(sections, (section) => {
            return sumBy(section.fields, 'total')
          }),
        )
      },
      source: 'affiliate',
    })
  }

  // console.log('Menu Array:', menuArray)
  // console.log('estimates:', estimates)
  // console.log('property', property.estimates)
  menuArray = filter(
    menuArray,
    (item) =>
      (item.field && !isUndefined(item.field[menu])) ||
      (item.mid && !isUndefined(item.mid[menu])),
  )
  if (menu === 'afterRepairValue') {
    menuArray.push({
      field: average,
      icon: (
        <Avatar>
          <Compare />
        </Avatar>
      ),
      source: 'averageCompValues',
    })
    menuArray.push({
      field: lowestAverage,
      icon: (
        <Avatar>
          <Compare />
        </Avatar>
      ),
      source: 'averageLowestCompValues',
    })
  }
  let dropdowns = remove(menuArray, (item) => item.mid)
  let noDataFound = remove(
    menuArray,
    (item) => !item.field[menu] && !item.calcValue,
  )
  menuArray = lodashOrderBy(
    menuArray,
    (item) => Number(item.field[menu]),
    'desc',
  )
  let menuSearch = [
    {
      field: estimates.zillowSearch,
      icon: <Avatar src="/images/ZillowLogo.png" />,
      onClick: () =>
        window.open(
          `https://www.zillow.com/homes/${kebabCase(
            property.address,
          )},-${property.state},-${property.zip}_rb/`,
          '_blank',
          // 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
        ),
    },
    {
      field: estimates.smartAssetSearch,
      icon: <Avatar src="/images/smart_asset.png" />,
      onClick: () =>
        window.open(
          `https://smartasset.com/taxes/property-taxes`,
          '_blank',
          'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
        ),
    },
    {
      field: estimates.googleSearch,
      icon: <Avatar src="/images/googleLogo.svg" />,
      onClick: () =>
        window.open(
          `https://www.google.com/search?q=${property.address}, ${property.city} ${property.state} ${property.zip}`,
          '_blank',
          // 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
        ),
    },
    {
      field: estimates.compSearch,
      icon: (
        <Avatar>
          <Compare />
        </Avatar>
      ),
      onClick: () => setOpenCompDialog(true),
    },
  ]
  menuSearch = filter(
    menuSearch,
    (item) => item.field && !isUndefined(item.field[menu]),
  )

  const currencyFormater = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
  })

  return [
    <>
      <Menu
        anchorEl={anchorElEstimates}
        open={Boolean(anchorElEstimates)}
        onClose={() => handleDefinition('', null)}
        MenuListProps={{ dense: true }}
        style={{ width: 400 }}
        classes={{ list: 'p-0' }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="dropdown-menu-xl">
          <MenuItem
            key="menuTitle"
            style={{ margin: 5 }}
            button={false}
          >
            <Typography variant="h6">{menuTitle[menu]}</Typography>
          </MenuItem>
          <MenuItem
            key="menuDescription"
            style={{ margin: 5 }}
            button={false}
          >
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{
                whiteSpace: 'normal',
              }}
            >
              {description[menu]}
            </Typography>
          </MenuItem>
          {/* {!isUndefined(source[menu]) && (
            <MenuItem>
              <Avatar className="rounded" style={{ marginRight: 10 }}>
                  <TodayIcon />
              </Avatar>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    {source[menu]
                      ? source[menu]
                      : 'Current Value'}
                  </Typography>
                }
                secondary="Source"
              />
            </MenuItem>
          )} */}
          {map(dropdowns, (item) => (
            <span>
              <MenuItem
                key={item.field}
                style={{ margin: 5 }}
                onClick={() => {
                  if (
                    includes(menu, 'rent') &&
                    assumptionsInput[menu + 'Type'] === 'rentRange'
                  ) {
                    onChangeRange(
                      replace(menu, 'rent', 'rentLow') +
                        '.' +
                        activeUnit,
                      replace(menu, 'rent', 'rentHigh') +
                        '.' +
                        activeUnit,
                      item.min[menu],
                      item.max[menu],
                      item.source,
                    )
                    setMenu('')
                    setMenuTarget('')
                    setAnchorElEstimates(null)
                  } else toggleCollapse(item.field)
                }}
              >
                <span style={{ marginRight: 10 }}>{item.icon}</span>
                <ListItemText
                  primary={
                    item.min[menu] && item.max[menu]
                      ? `${currencyFormater.format(
                          item.min[menu],
                        )} - ${currencyFormater.format(
                          item.max[menu],
                        )}`
                      : item.min[menu] && item.mid[menu]
                        ? `${currencyFormater.format(
                            item.min[menu],
                          )} - ${currencyFormater.format(
                            item.mid[menu],
                          )}`
                        : item.mid[menu] && item.max[menu]
                          ? `${currencyFormater.format(
                              item.mid[menu],
                            )} - ${currencyFormater.format(
                              item.max[menu],
                            )}`
                          : item.mid[menu]
                            ? `${currencyFormater.format(
                                item.mid[menu],
                              )} - ${currencyFormater.format(
                                item.mid[menu],
                              )}`
                            : '-'
                  }
                  secondary={
                    item.secondary === 'Provided by House'
                      ? 'Provided by ' +
                        (affiliate
                          ? affiliate?.companyName || 'House'
                          : 'House')
                      : item.secondary
                  }
                />
                {estimatesCollapse[item.field] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </MenuItem>
              <Collapse
                in={estimatesCollapse[item.field]}
                unmountOnExit
                onKeyDown={(event) => event.stopPropagation()}
              >
                <List dense>
                  <MenuItem
                    key="min"
                    style={{ margin: 5 }}
                    onClick={() => {
                      changeMenu(
                        menuTarget || menu,
                        item.min[menu],
                        item.sourceMin,
                      )
                    }}
                  >
                    {item.minLabel}
                    <span style={{ flex: 1 }} />{' '}
                    {item.min[menu]
                      ? currencyFormater.format(item.min[menu])
                      : '-'}
                  </MenuItem>
                  <MenuItem
                    key="mid"
                    style={{ margin: 5 }}
                    onClick={() => {
                      changeMenu(
                        menuTarget || menu,
                        item.mid[menu],
                        item.source,
                      )
                    }}
                  >
                    {item.midLabel}
                    <span style={{ flex: 1 }} />{' '}
                    {item.mid[menu]
                      ? currencyFormater.format(item.mid[menu])
                      : '-'}
                  </MenuItem>
                  <MenuItem
                    key="max"
                    style={{ margin: 5 }}
                    onClick={() => {
                      changeMenu(
                        menuTarget || menu,
                        item.max[menu],
                        item.sourceMax,
                      )
                    }}
                  >
                    {item.maxLabel}
                    <span style={{ flex: 1 }} />{' '}
                    {item.max[menu]
                      ? currencyFormater.format(item.max[menu])
                      : '-'}
                  </MenuItem>
                </List>
              </Collapse>
            </span>
          ))}
          {map(menuArray, (item) => (
            <MenuItem
              key={item.field.secondary}
              style={{ margin: 5 }}
              onClick={() => {
                changeMenu(
                  menuTarget || menu,
                  item.calcValue
                    ? item.calcValue(
                        estimates?.affiliate[menu] || {},
                        assumptionsInput,
                      )
                    : item.field[menu],
                  item.source,
                )
              }}
            >
              <span style={{ marginRight: 10 }}>{item.icon}</span>
              <ListItemText
                primary={
                  item.field[menu] || item.calcValue
                    ? menu === 'occupancyRate'
                      ? percentageFormat.format(
                          item.field[menu] / 100,
                        )
                      : currencyFormater.format(
                          item.calcValue
                            ? item.calcValue(
                                estimates?.affiliate[menu] || {},
                                assumptionsInput,
                              )
                            : item.field[menu],
                        )
                    : '-'
                }
                secondary={
                  item.field.secondary === 'Provided by Zillow'
                    ? `${
                        menu === 'rent' ? 'Rent ' : ''
                      }Zestimate provided by Zillow`
                    : item.field.secondary === 'Provided by House'
                      ? 'Provided by ' +
                        (affiliate
                          ? affiliate?.companyName || 'House'
                          : 'House')
                      : item.field.secondary
                }
              />
              {item.action && <span>{item.action}</span>}
            </MenuItem>
          ))}
          {!isEmpty(noDataFound) && (
            <span>
              <MenuItem
                key="No Data Found"
                style={{ margin: 5 }}
                onClick={() => {
                  setOpenNoData(!openNoData)
                }}
              >
                <ListItemText primary="No Data Found" />
                {openNoData ? <ExpandLess /> : <ExpandMore />}
              </MenuItem>
              <Collapse
                in={openNoData}
                unmountOnExit
                onKeyDown={(event) => event.stopPropagation()}
              >
                {map(noDataFound, (item) => (
                  <MenuItem
                    style={{ margin: 5 }}
                    key={item.field.secondary}
                    onClick={() => {
                      changeMenu(
                        menuTarget || menu,
                        item.calcValue
                          ? item.calcValue(
                              property?.estimates?.affiliate[menu],
                              assumptionsInput,
                            )
                          : item.field[menu],
                        item.source,
                      )
                    }}
                  >
                    <span style={{ marginRight: 10 }}>
                      {item.icon}
                    </span>
                    <ListItemText
                      primary={
                        item.field[menu]
                          ? menu === 'occupancyRate'
                            ? percentageFormat.format(
                                item.field[menu] / 100,
                              )
                            : currencyFormater.format(
                                item.calcValue
                                  ? item.calcValue(
                                      property?.estimates?.affiliate[
                                        menu
                                      ],
                                      assumptionsInput,
                                    )
                                  : item.field[menu],
                              )
                          : '-'
                      }
                      secondary={
                        item.field.secondary === 'Provided by Zillow'
                          ? `${
                              menu === 'rent' ? 'Rent ' : ''
                            }Zestimate provided by Zillow`
                          : item.field.secondary ===
                              'Provided by House'
                            ? 'Provided by ' +
                              (affiliate
                                ? affiliate?.companyName || 'House'
                                : 'House')
                            : item.field.secondary
                      }
                    />
                    {item.action && <span>{item.action}</span>}
                  </MenuItem>
                ))}
              </Collapse>
            </span>
          )}
          {!isEmpty(menuSearch) && (
            <MenuItem key="Search" style={{ margin: 5 }}>
              <ListItemText primary="Links" />
            </MenuItem>
          )}
          {map(menuSearch, (item) => (
            <MenuItem
              key={item.field.secondary}
              style={{ margin: 5 }}
              onClick={item.onClick}
            >
              <span style={{ marginRight: 10 }}>{item.icon}</span>
              <ListItemText
                primary={item.field[menu]}
                secondary={
                  item.field.secondary === 'Provided by House'
                    ? 'Provided by ' +
                      (affiliate
                        ? affiliate?.companyName || 'House'
                        : 'House')
                    : item.field.secondary
                }
              />
            </MenuItem>
          ))}
          {/* {useDefault && (
            <MenuItem
              onClick={() => {
                changeMenu(
                  menu,
                  defaults[menu].default,
                )
              }}
            >
              <Avatar className="rounded" style={{ marginRight: 10 }}>
                <Avatar src="/images/EppraisalLogo.png" />
              </Avatar>
              <ListItemText
                primary={
                  this.state[
                    defaults[menu].default
                  ]
                    ? currencyFormater.format(
                      this.state[
                      defaults[menu]
                        .default
                      ],
                    )
                    : '-'
                }
                secondary={`We couldn't find an ${defaults[menu].label}. This number is the ${defaults[menu].defaultLabel}`}
              />
            </MenuItem>
          )} */}
        </div>
      </Menu>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openCompDialog}
        onClose={() => setOpenCompDialog(false)}
      >
        <DialogTitle>Select a Comp to use as an estimate</DialogTitle>
        <DialogContent>
          <Card className="mb-spacing-6-x2">
            <div className="card-header pr-2">
              <div className="card-header--title">
                {/* <small>{property.address}</small> */}
                <b>Comps</b>
              </div>
            </div>
            <CardContent className="p-0">
              {isEmpty(determinedComps) ? (
                <Typography style={{ margin: 5 }}>
                  This property has no Comps. Either add one or change
                  a potential comp's status.
                </Typography>
              ) : (
                <>
                  <div className="table-responsive">
                    <Table className="table text-nowrap mb-0">
                      <thead className="thead-light">
                        <tr className="text-center">
                          <TableCell />
                          {map(tableHeaders, (column) => (
                            <TableCell
                              key={column.title}
                              //style={{ whiteSpace: 'nowrap' }}
                              align={column.align}
                              sortDirection={
                                orderBy === column.name
                                  ? order
                                  : false
                              }
                            >
                              {column.name ? (
                                <TableSortLabel
                                  active={orderBy === column.name}
                                  direction={order}
                                  onClick={(event) =>
                                    handleRequestSort(
                                      event,
                                      column.name,
                                    )
                                  }
                                >
                                  {column.title}
                                  {orderBy === column.name ? (
                                    <span style={visuallyHidden}>
                                      {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                    </span>
                                  ) : null}
                                </TableSortLabel>
                              ) : (
                                column.title
                              )}
                            </TableCell>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {map(
                          slice(
                            stableSort(
                              determinedComps || [],
                              getSorting(order, orderBy),
                            ),
                            (page - 1) * 25,
                            page * 25,
                          ),
                          (comp, index) => (
                            <tr style={{ position: 'relative' }}>
                              {!props.shared && (
                                <td className="text-center">
                                  <Checkbox
                                    color="primary"
                                    id="CustomCheckbox4"
                                    className="align-self-start"
                                    checked={
                                      selectedComp?._id === comp._id
                                    }
                                    onClick={() =>
                                      setSelectedComp(comp)
                                    }
                                  />
                                </td>
                              )}
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="avatar-icon-wrapper mr-2">
                                    {compact(comp.images)?.length >=
                                    5 ? (
                                      <div className="avatar-icon rounded d-flex justify-content-center align-items-center">
                                        <div
                                          className="blur-load"
                                          style={{
                                            backgroundImage:
                                              'url(' +
                                              head(comp.images)
                                                .url_small +
                                              ')',
                                          }}
                                        >
                                          <img
                                            src={
                                              head(comp.images).url
                                            }
                                            loading="lazy"
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <a
                                        href={
                                          props?.session?.me
                                            ?.activeOrg?.isRealeflow
                                            ? ''
                                            : `https://www.comehome.com/property-details/${replace(
                                                comp.address,
                                                /\s/g,
                                                '-',
                                              )}-${replace(
                                                comp.city,
                                                /\s/g,
                                                '-',
                                              )}-${comp.state}-${
                                                comp.zip
                                              }?modal=photo-list`
                                        }
                                        target="_blank"
                                      >
                                        <div
                                          onClick={(event) => {
                                            event.preventDefault()
                                            props?.session?.me
                                              ?.activeOrg?.isRealeflow
                                              ? ''
                                              : window.open(
                                                  `https://www.comehome.com/property-details/${replace(
                                                    comp.address,
                                                    /\s/g,
                                                    '-',
                                                  )}-${replace(
                                                    comp.city,
                                                    /\s/g,
                                                    '-',
                                                  )}-${comp.state}-${
                                                    comp.zip
                                                  }?modal=photo-list`,
                                                  '_blank',
                                                )
                                          }}
                                          className="avatar-icon rounded"
                                        >
                                          <img
                                            src={
                                              props?.session?.me
                                                ?.activeOrg
                                                ?.member ===
                                                'pacemorby' ||
                                              props?.session?.me
                                                ?.activeOrg
                                                ?.member ===
                                                'astroflip'
                                                ? '/images/DealSauce-Logo-Tan.png'
                                                : props?.session?.me
                                                      ?.activeOrg
                                                      ?.member ===
                                                    'pep'
                                                  ? '/images/pep-icon-only.png'
                                                  : '/images/comehome_small.png'
                                            }
                                            alt="..."
                                          />
                                        </div>
                                      </a>
                                    )}
                                  </div>
                                  <div>
                                    <a
                                      className="font-weight-bold text-black"
                                      title="..."
                                    >
                                      {comp.address}
                                    </a>
                                  </div>
                                </div>
                              </td>
                              {map(
                                slice(tableHeaders, 1),
                                (column) => (
                                  <td>
                                    <div>
                                      {column.formatter
                                        ? column.formatter(
                                            get(comp, column.name),
                                          )
                                        : get(comp, column.name)}
                                    </div>
                                  </td>
                                ),
                              )}
                            </tr>
                          ),
                        )}
                      </tbody>
                    </Table>
                  </div>
                  <div className="divider" />
                  <div className="divider" />
                  {count && (
                    <div className="p-3 d-flex justify-content-center">
                      <Pagination
                        className="pagination-first"
                        size="small"
                        count={count}
                        page={page}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => setOpenCompDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="btn-primary m-2"
            disabled={!selectedComp}
            onClick={() => {
              //Grabs estavl as the menu variable is afterRepair value which comp doesn't have
              changeMenu(
                menuTarget || menu,
                selectedComp['estval'],
                //selectedComp[menu],
                'compSearch',
              )
              setOpenCompDialog(false)
            }}
          >
            Use Selected Comp
          </Button>
        </DialogActions>
      </Dialog>
    </>,
  ]
}

export default useEstimatesMenu
