import { gql } from '@apollo/client'

export const GET_ZILLOW = gql`
  query getZillowPropertyData($addressInput: addressInput!) {
    getZillowPropertyData(addressInput: $addressInput) {
      zpid
      address
      city
      state
      zip
      year
      baths
      beds
      sqft
      files {
        url
        url_small
      }
      zestimate
      rentZestimate
      taxAssessment
      description
    }
  }
`

export const GET_ZIP_VALUE = gql`
  query zipValue($zipcode: String!) {
    zipValue(zipcode: $zipcode) {
      zipcode
      statesLong
      statesShort
      cities
      county
      counties
      arvSqft
      arvBed1
      arvBed2
      arvBed3
      arvBed4
      arvBedPlus
      rentSqft
      rentBed1
      rentBed2
      rentBed3
      rentBed4
      rentBedPlus
      rentSqftBed1
      rentSqftBed2
      rentSqftBed3
      rentSqftBed4
      rentSqftBedPlus
      rentometerBed0
      rentometerBed1
      rentometerBed2
      rentometerBed3
      rentometerBed4
      rentometerLowBed0
      rentometerLowBed1
      rentometerLowBed2
      rentometerLowBed3
      rentometerLowBed4
      rentometerHighBed0
      rentometerHighBed1
      rentometerHighBed2
      rentometerHighBed3
      rentometerHighBed4
      listSqft
      taxesPer
    }
  }
`

export const GET_SMART_ASSET = gql`
  query smartAsset($county: String, $state: String) {
    smartAsset(county: $county, state: $state) {
      _id
      county
      state
      avgCountyTaxRate
      dateUTC
    }
  }
`

export const GET_ZILLOW_COMP = gql`
  query getZillowCompData(
    $zpid: String!
    $propertyInput: propertyInput
  ) {
    getZillowCompData(zpid: $zpid, propertyInput: $propertyInput) {
      zpid
      address
      city
      state
      zip
      year
      baths
      beds
      sqft
      files {
        url
        url_small
      }
      location {
        latitude
        longitude
      }
      zestimate
      rentZestimate
      taxAssessment
    }
  }
`

export const GET_MLS_COMPS_BY_ZIP = gql`
  query getMLSCompsByZip($propertyInput: propertyInput!) {
    getMLSCompsByZip(propertyInput: $propertyInput) {
      address
      city
      state
      zip
      year
      baths
      beds
      sqft
      files {
        url
        url_small
      }
      taxAssessment
      estimate
      saleDate
    }
  }
`

export const GET_MLS_RENTAL_COMPS_BY_ZIP = gql`
  query getMLSRentalCompsByZip($propertyInput: propertyInput!) {
    getMLSRentalCompsByZip(propertyInput: $propertyInput) {
      address
      city
      state
      zip
      year
      baths
      beds
      sqft
      files {
        url
        url_small
      }
      taxAssessment
      estimate
      saleDate
    }
  }
`

export const CALL_HOUSE_CANARY = gql`
  query callHouseCanary(
    $address: String
    $zipcode: String
    $propertyDetails: Boolean
    $salesHistory: Boolean
    $flood: Boolean
    $blockCrime: Boolean
    $school: Boolean
    $taxHistory: Boolean
  ) {
    callHouseCanary(
      address: $address
      zipcode: $zipcode
      propertyDetails: $propertyDetails
      salesHistory: $salesHistory
      flood: $flood
      blockCrime: $blockCrime
      school: $school
      taxHistory: $taxHistory
    )
  }
`

export const CALL_AIR_DNA = gql`
  query callAirDNA(
    $address: String
    $city: String
    $state: String
    $zip: String
    $beds: Int
    $baths: Float
  ) {
    callAirDNA(
      address: $address
      city: $city
      state: $state
      zip: $zip
      beds: $beds
      baths: $baths
    )
  }
`
