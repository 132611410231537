/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2024-08-02 12:33:38
 * @ Description: Dialog to delete a portfolio
 */

import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  ListItem,
} from '@mui/material'

import includes from 'lodash/includes'

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { useMutation } from '@apollo/client'
import { DELETE_PORTFOLIO } from '../mutations'
import { Query } from '@apollo/client/react/components'
import { GET_ORGS_PORTFOLIO_SHARED_WITH } from '../queries'
import { GET_PORTFOLIOS } from '@/housefolios-pages/Launchpad/queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { enqueueSnackbar } from 'notistack'
import { GET_TRASH } from '@/housefolios-components/Properties/queries'
import { useNavigate } from 'react-router-dom'

function DeletePortfolioComponent(props) {
  const { sidebarMenu, portfolio = {} } = props
  const [openDeletePortfolio, setDeletePortfolio] = useState(false)
  const handleOpenDeletePortfolio = () => {
    setDeletePortfolio(true)
  }
  const handleCloseDeletePortfolio = () => {
    setDeletePortfolio(false)
  }

  const [deletePortfolio] = useMutation(DELETE_PORTFOLIO)

  const [confirm, setConfirm] = useState(false)

  let navigate = useNavigate()

  const handleDelete = () => {
    setDeletePortfolio(false)
    setConfirm(false)
    let properyCheck = false
    const url = window.location.href
    if (includes(url, 'property-details')) properyCheck = true

    deletePortfolio({
      variables: { id: portfolio._id },
      refetchQueries: [
        {
          query: GET_PORTFOLIOS,
        },
        {
          query: GET_TRASH,
        },
        {
          query: GET_ME,
        },
      ],
    })
      .then(async ({ data }) => {
        if (properyCheck)
          navigate('/my-properties')
        enqueueSnackbar(
          `${portfolio.name} and all its properties have been deleted`,
          {
            variant: 'success',
            autoHideDuration: 3000,
          },
        )
        handleCloseDeletePortfolio()
        props.handleClose()
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to Delete Portfolio`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseDeletePortfolio()
      })
  }

  return (
    <>
      {sidebarMenu && (
        <ListItem button onClick={handleOpenDeletePortfolio}>
          <DeleteOutlinedIcon
            style={{ marginRight: 5, color: 'red' }}
          />
          <span style={{ color: 'red' }}>Delete Portfolio</span>
        </ListItem>
      )}
      <Dialog
        open={openDeletePortfolio}
        onClose={handleCloseDeletePortfolio}
        classes={{ paper: 'shadow-lg rounded' }}
      >
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon
                icon={['fas', 'trash-can']}
                size="10x"
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">Delete Portfolio?</h4>
          <Query
            query={GET_ORGS_PORTFOLIO_SHARED_WITH}
            variables={{
              portfolioId: portfolio._id,
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return 'loading'
              if (error) return `Error! ${error.message}`
              const { orgsPortfolioSharedWith } = data
              if (orgsPortfolioSharedWith.length > 0) {
                return (
                  <>
                    <h5>This portfolio is shared with others</h5>
                    <p className="mb-0 font-size-lg text-muted">
                      <FormControl
                        component="fieldset"
                        className="pr-4"
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={confirm}
                                onChange={() => setConfirm(!confirm)}
                              />
                            }
                            label="Delete this shared portfolio it's properties."
                          />
                        </FormGroup>
                      </FormControl>
                      {/* Delete this portfolio along with all the properties inside. This can't be undone. */}
                    </p>
                  </>
                )
              } else {
                return (
                  <p className="mb-0 font-size-lg text-muted">
                    <FormControl
                      component="fieldset"
                      className="pr-4"
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={confirm}
                              onChange={() => setConfirm(!confirm)}
                            />
                          }
                          label="Delete this portfolio it's properties."
                        />
                      </FormGroup>
                    </FormControl>
                    {/* Delete this portfolio along with all the properties inside. This can't be undone. */}
                  </p>
                )
              }
            }}
          </Query>
          <div className="pt-4">
            <Button
              variant="text"
              onClick={handleCloseDeletePortfolio}
              //className="btn-neutral-secondary btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: '#f83145', color: 'white' }}
              onClick={handleDelete}
              disabled={!confirm}
            >
              <span className="btn-wrapper--label">Delete</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default DeletePortfolioComponent
