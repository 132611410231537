/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-01-10 15:58:23
 * @ Description: Main component for free account signup pagee
 */

import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import toLower from 'lodash/toLower'
import upperFirst from 'lodash/upperFirst'

import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import NewLogo from '@/assets/images/NewLogo.png'

import {
  Button,
  Grid,
  InputAdornment,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
} from '@mui/material'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { Mutation } from '@apollo/client/react/components'
import { enqueueSnackbar } from 'notistack'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  CHECK_SIGN_UP,
  FREE_SIGN_UP,
  SIGN_UP,
} from '../CreateAccountCard/mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { Link as RouterLink } from 'react-router-dom'

function FreeAccountComponent(props) {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [passwordMask, setPasswordMask] = useState(true)

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  let hfpid = urlParams.get('hfpid')
  let refOrg = urlParams.get('org')
  let planID = urlParams.get('planID') || 'free'

  const [agree, setAgree] = useState(false)

  const [loader, setLoader] = useState(false)

  const handleSubmit = async (signUp, checkSignup) => {
    setLoader(true)
    const productChoice = 'free'
    const planPrice = 0
    let gaID = hfpid
      ? upperFirst(productChoice) +
        '_' +
        hfpid.toString() +
        '_' +
        email
      : productChoice + '_' + email

    checkSignup()
      .then(async ({ data }) => {
        signUp()
          .then(async ({ data }) => {
            ReactPixel.track('StartTrial', {
              value: planPrice,
              currency: 'USD',
              predicted_ltv: planPrice * 6,
              description:
                `email=${email}` +
                '&' +
                `productChoice=${productChoice}` +
                '&' +
                (hfpid ? `hfpid=${hfpid.toString()}` : ''),
            })
            ReactGA.plugin.execute('ecommerce', 'addItem', {
              id: gaID,
              name: upperFirst(productChoice),
              sku: planID,
              price: planPrice,
              quantity: 1,
              currency: 'USD',
            })
            ReactGA.plugin.execute('ecommerce', 'addTransaction', {
              id: gaID,
              revenue: planPrice,
              total: planPrice,
              shipping: 0,
              tax: 0,
              currency: 'USD',
            })
            ReactGA.plugin.execute('ecommerce', 'send')
            ReactGA.plugin.execute('ecommerce', 'clear')

            localStorage.setItem(
              'token',
              data.createFreeAccount.token,
            )
            localStorage.setItem(
              `buyBox-${data.createFreeAccount.orgId}`,
              true,
            )
            //await props.refetch()
            // props.history.push('/launchpad')
            window.location.reload()
            setLoader(false)
            //this.props.history.push(routes.MARKETPLACE + 'card')
          })
          .catch((error) => {
            console.log('"' + error.message + '"')
            // if (
            //   error.message !== 'GraphQL error: Email already exists.'
            // ) {
            //   ReactPixel.track('StartTrial', {
            //     value: planPrice,
            //     currency: 'USD',
            //     predicted_ltv: planPrice * 6,
            //     description:
            //       `email=${email}` +
            //       '&' +
            //       `productChoice=${productChoice}` +
            //       '&' +
            //       (hfpid ? `hfpid=${hfpid.toString()}` : ''),
            //   })
            //   ReactGA.plugin.execute('ecommerce', 'addItem', {
            //     id: gaID + error.message,
            //     name: upperFirst(productChoice),
            //     sku: stripePlanId,
            //     price: planPrice,
            //     quantity: 1,
            //     currency: 'USD',
            //   })
            //   ReactGA.plugin.execute('ecommerce', 'addTransaction', {
            //     id: gaID + error.message,
            //     revenue: planPrice,
            //     total: planPrice,
            //     shipping: 0,
            //     tax: 0,
            //     currency: 'USD',
            //   })
            //   ReactGA.plugin.execute('ecommerce', 'send')
            //   ReactGA.plugin.execute('ecommerce', 'clear')
            // }
            // if (element) element.clear()
            setLoader(false)
          })
      })
      .catch((error) => {
        console.log('"' + error.message + '"')
        // if (element) element.clear()
        setLoader(false)
      })
  }

  const referredByDetails = {
    affiliateId: hfpid,
    saleDate: new Date(),
    productSelected: 'free',
    productTotalPrice: 0,
    couponUsed: null,
    active: true,
  }

  return (
    <>
      {/* <div className="app-wrapper bg-white min-vh-100"> */}
      <Grid
        container
        direction="row"
        spacing={0}
        className="min-vh-100"
      >
        <Grid item md={5} xl={6} className="d-flex">
          <div
            className="hero-wrapper w-100 bg-composed-wrapper min-vh-lg-100"
            style={{ backgroundColor: '#368CFF' }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="min-vh-100"
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  spacing={3}
                  style={{ padding: 50 }}
                >
                  <Grid
                    item
                    style={{ textAlign: 'center', marginBottom: 10 }}
                  >
                    <img alt="Housefolios" src={NewLogo} />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={1}
                        style={{ textAlign: 'center' }}
                      >
                        <FontAwesomeIcon
                          icon={['far', 'house-laptop']}
                          style={{ color: 'white', fontSize: 36 }}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography
                          style={{ color: 'white', marginLeft: 10 }}
                        >
                          Analyze an investment property in seconds,
                          not hours
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={1}
                        style={{ textAlign: 'center' }}
                      >
                        <img
                          src="images/nationIcon.png"
                          alt="Nationwide Search"
                          style={{ width: 40, padding: 0 }}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography
                          style={{ color: 'white', marginLeft: 10 }}
                        >
                          Search nationwide for properties across all
                          active MLS listings
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={1}
                        style={{ textAlign: 'center' }}
                      >
                        <FontAwesomeIcon
                          icon={['far', 'arrow-down-wide-short']}
                          style={{ color: 'white', fontSize: 36 }}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography
                          style={{ color: 'white', marginLeft: 10 }}
                        >
                          Filter and review specific investing
                          strategies like fix & flip, buy & hold,
                          turnkey, and more
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={1}
                        style={{ textAlign: 'center' }}
                      >
                        <FontAwesomeIcon
                          icon={['far', 'sign-hanging']}
                          style={{ color: 'white', fontSize: 36 }}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography
                          style={{ color: 'white', marginLeft: 10 }}
                        >
                          List your properties on your own
                          customizable website
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={1}
                        style={{ textAlign: 'center' }}
                      >
                        <FontAwesomeIcon
                          icon={['far', 'file-chart-column']}
                          style={{ color: 'white', fontSize: 36 }}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography
                          style={{ color: 'white', marginLeft: 10 }}
                        >
                          Generate proformas with a single click
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ padding: 25 }}>
                    <Grid container direction="column">
                      <Grid
                        item
                        style={{
                          padding: 10,
                          border: 'solid',
                          borderWidth: 3,
                          borderColor: 'white',
                          borderRadius: 5,
                        }}
                      >
                        <Typography
                          align="center"
                          style={{ color: 'white' }}
                        >
                          "I used to hire analysts or virtual
                          assistants to help me find properties...
                          <b>
                            Housefolios is cheaper and frankly makes a
                            lot less mistakes
                          </b>
                          ."
                        </Typography>
                        <Typography
                          align="center"
                          style={{ color: 'white', marginTop: 5 }}
                        >
                          -Rob, California
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          md={7}
          xl={6}
          className="d-flex align-items-center"
        >
          <div className="app-main min-vh-100">
            <div className="app-content p-0">
              <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content py-5">
                    <Mutation
                      mutation={CHECK_SIGN_UP}
                      variables={{
                        userInput: {
                          email: toLower(email),
                        },
                      }}
                    >
                      {(checkSignup, { data, loading, error }) => {
                        const signUpError = error
                        return (
                          <Mutation
                            mutation={FREE_SIGN_UP}
                            variables={{
                              userInput: {
                                profile: {
                                  firstname: firstName,
                                  lastname: lastName,
                                },
                                email: toLower(email),
                                //userType,
                                organization: firstName + lastName,
                                phone: phoneNumber,
                                // address: billingAddress,
                                // city,
                                // state,
                                // zip: postalCode,
                                stripePlanId: planID,
                                password: password,
                                referredByDetails: hfpid
                                  ? referredByDetails
                                  : null,
                                //coupon,
                                refOrg: refOrg ? refOrg : null,
                              },
                            }}
                          >
                            {(
                              createFreeAccount,
                              { data, loading, error },
                            ) => {
                              var errorElement =
                                document.getElementById('card-errors')

                              if (error && errorElement) {
                                errorElement.textContent =
                                  error.message.slice(0, 21) ===
                                  'GraphQL error: Error:'
                                    ? error.message.slice(21)
                                    : null
                              }

                              return (
                                <ValidatorForm
                                  onSubmit={() =>
                                    handleSubmit(
                                      createFreeAccount,
                                      checkSignup,
                                    )
                                  }
                                >
                                  <BlockUi
                                    blocking={loader}
                                    loader={
                                      <PacmanLoader
                                        color={'var(--primary)'}
                                        loading={loader}
                                      />
                                    }
                                    message={
                                      <div className="text-primary">
                                        Creating Account
                                      </div>
                                    }
                                  >
                                    <Grid
                                      container
                                      spacing={0}
                                      justifyContent="space-around"
                                    >
                                      <Grid
                                        item
                                        className="d-flex align-items-center"
                                      >
                                        <Grid
                                          item
                                          className="mx-auto"
                                        >
                                          <Grid
                                            container
                                            direction="column"
                                            justifyContent="space-evenly"
                                          >
                                            <Grid item>
                                              <div className="text-center mb-5">
                                                <h1 className="display-4 mb-1 font-weight-bold">
                                                  SIGN UP FOR YOUR
                                                  FREE ACCOUNT
                                                </h1>
                                              </div>
                                            </Grid>
                                            <Grid item>
                                              <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                spacing={2}
                                                style={{
                                                  paddingLeft: 50,
                                                  paddingRight: 50,
                                                }}
                                              >
                                                <Grid item xs={12}>
                                                  <TextValidator
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    placeholder="Email Address"
                                                    label="Email Address"
                                                    value={email}
                                                    onChange={(
                                                      event,
                                                    ) =>
                                                      setEmail(
                                                        event.target
                                                          .value,
                                                      )
                                                    }
                                                    validators={[
                                                      'required',
                                                      'maxStringLength: 50',
                                                      'isEmail',
                                                    ]}
                                                    errorMessages={[
                                                      'This field is required',
                                                      'You cannot exceed 50 characters',
                                                      'Not a valid email address',
                                                    ]}
                                                    error={
                                                      signUpError
                                                    }
                                                    helperText={
                                                      signUpError
                                                        ? signUpError.message ===
                                                          'GraphQL error: Email already exists.'
                                                          ? 'Email already exists'
                                                          : signUpError.message ===
                                                              'GraphQL error: ValidationError: emails: [object Object] is not a valid email'
                                                            ? 'Not a valid email'
                                                            : signUpError.message ===
                                                                'GraphQL error: Error: Your card has insufficient funds.'
                                                              ? ''
                                                              : signUpError.message
                                                        : ''
                                                    }
                                                  />
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <TextValidator
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    placeholder="Password"
                                                    label="Password"
                                                    value={password}
                                                    onChange={(
                                                      event,
                                                    ) =>
                                                      setPassword(
                                                        event.target
                                                          .value,
                                                      )
                                                    }
                                                    type={
                                                      passwordMask
                                                        ? 'password'
                                                        : 'text'
                                                    }
                                                    validators={[
                                                      'required',
                                                      'maxStringLength: 50',
                                                      'minStringLength: 7',
                                                    ]}
                                                    errorMessages={[
                                                      'This field is required',
                                                      'You cannot exceed 50 characters',
                                                      'Password must be at least 7 characters long',
                                                    ]}
                                                    InputProps={{
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <IconButton
                                                            onClick={() =>
                                                              setPasswordMask(
                                                                !passwordMask,
                                                              )
                                                            }
                                                            size="large"
                                                          >
                                                            {passwordMask ? (
                                                              <RemoveRedEyeIcon />
                                                            ) : (
                                                              <VisibilityOffIcon />
                                                            )}
                                                          </IconButton>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <TextValidator
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    placeholder="First Name"
                                                    label="First Name"
                                                    value={firstName}
                                                    onChange={(
                                                      event,
                                                    ) =>
                                                      setFirstName(
                                                        event.target
                                                          .value,
                                                      )
                                                    }
                                                    validators={[
                                                      'required',
                                                      'maxStringLength: 20',
                                                      'matchRegexp:^[A-Z|a-z]+$',
                                                    ]}
                                                    errorMessages={[
                                                      'This field is required',
                                                      'You cannot exceed 20 characters',
                                                      'This field only allows characters in the alphabet',
                                                    ]}
                                                  />
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <TextValidator
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    placeholder="Last Name"
                                                    label="Last Name"
                                                    value={lastName}
                                                    onChange={(
                                                      event,
                                                    ) =>
                                                      setLastName(
                                                        event.target
                                                          .value,
                                                      )
                                                    }
                                                    validators={[
                                                      'required',
                                                      'maxStringLength: 20',
                                                      'matchRegexp:^[A-Z|a-z]+$',
                                                    ]}
                                                    errorMessages={[
                                                      'This field is required',
                                                      'You cannot exceed 20 characters',
                                                      'This field only allows characters in the alphabet',
                                                    ]}
                                                  />
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <TextValidator
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    placeholder="Phone Number"
                                                    label="Phone Number"
                                                    value={
                                                      phoneNumber
                                                    }
                                                    onChange={(
                                                      event,
                                                    ) =>
                                                      setPhoneNumber(
                                                        event.target
                                                          .value,
                                                      )
                                                    }
                                                    validators={[
                                                      'required',
                                                      'matchRegexp:^[0-9()-]+$',
                                                    ]}
                                                    errorMessages={[
                                                      'This field is required',
                                                      'Not a valid phone number',
                                                    ]}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              item
                                              container
                                              direction="row"
                                              alignItems="center"
                                              justifyContent="space-between"
                                              xs={12}
                                              style={{
                                                paddingLeft: 50,
                                                paddingRight: 50,
                                              }}
                                            >
                                              <Grid item>
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={agree}
                                                      required
                                                      onChange={() =>
                                                        setAgree(
                                                          !agree,
                                                        )
                                                      }
                                                    />
                                                  }
                                                  label={
                                                    <Typography>
                                                      I have read and
                                                      agree to the{' '}
                                                      <Link
                                                        component={
                                                          RouterLink
                                                        }
                                                        to="/Terms"
                                                        underline="hover"
                                                        style={{
                                                          color:
                                                            '#04a3e3',
                                                        }}
                                                        target="_blank"
                                                      >
                                                        Terms of
                                                        Service
                                                      </Link>{' '}
                                                      and{' '}
                                                      <Link
                                                        component={
                                                          RouterLink
                                                        }
                                                        to="/Privacy"
                                                        underline="hover"
                                                        style={{
                                                          color:
                                                            '#04a3e3',
                                                        }}
                                                        target="_blank"
                                                      >
                                                        Privacy Policy
                                                      </Link>
                                                    </Typography>
                                                  }
                                                />
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{
                                                marginTop: 25,
                                                textAlign: 'center',
                                              }}
                                            >
                                              <Button
                                                variant="contained"
                                                type="submit"
                                                disabled={!agree}
                                                color="primary"
                                                style={{
                                                  fontWeight: 600,
                                                }} /*style={{ backgroundColor: '#04a3e3', color: 'white' }}*/
                                              >
                                                <Typography>
                                                  Sign Up
                                                </Typography>
                                              </Button>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{
                                                marginTop: 10,
                                                textAlign: 'center',
                                              }}
                                            >
                                              <Typography>
                                                Already a Housefolios
                                                member?
                                                <Link
                                                  href="/signin"
                                                  underline="hover"
                                                  style={{
                                                    color: '#04a3e3',
                                                  }}
                                                >
                                                  {' '}
                                                  Log in
                                                </Link>
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </BlockUi>
                                </ValidatorForm>
                              )
                            }}
                          </Mutation>
                        )
                      }}
                    </Mutation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default FreeAccountComponent
