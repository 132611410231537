import React, { useEffect } from 'react'

import clsx from 'clsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from '@mui/material'

import includes from 'lodash/includes'
import toLower from 'lodash/toLower'
import startcase from 'lodash/startCase'
import split from 'lodash/split'
import replace from 'lodash/replace'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import {
  setSidebarToggle,
  setSidebarToggleMobile,
} from '../../reducers/ThemeOptions'

import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import withSession from '@/housefolios-components/Session/withSession'

const SidebarHeader = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile)
  }
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle)
  }
  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,

    sidebarToggle,
    setSidebarToggle,
  } = props

  useEffect(() => {
    if (props.collapsed) {
      setSidebarToggle(true)
    } else {
      setSidebarToggle(false)
    }
  }, [props.collapsed, setSidebarToggle])

  let isMember = false
  let sidebarMemberFullImage = '/images/LeftNavLogo.png'
  let sidebarMemberFullImageStyle = { width: 250, marginRight: 5 }
  let sidebarMemberSmallImage = '/images/LeftNavLogo.png'
  let sidebarMemberSmallImageStyle = { width: 30 }

  if (
    props.session.me.activeOrg.parentCorp &&
    props.session.me.activeOrg.parentCorp.logo
  ) {
    isMember = true
    sidebarMemberFullImage =
      props.session.me.activeOrg.parentCorp.logo.url
    sidebarMemberFullImageStyle = {
      width: 120,
      marginRight: 5,
      marginLeft: 25,
      marginTop: 15,
    }
    sidebarMemberSmallImage =
      props.session.me.activeOrg.parentCorp.logo.url
    sidebarMemberSmallImageStyle = { width: 30 }
  }

  const { affiliate = {} } = props
  if (isEmpty(affiliate)) isMember = false
  else {
    const { sidebar = {} } = affiliate
    isMember = true
    sidebarMemberFullImage =
      get(sidebar, 'sidebarMemberFullImage.0.url') ||
      '/images/defaultSidebarFullLogo.png'
    sidebarMemberFullImageStyle =
      sidebar.sidebarMemberFullImageStyle || {
        width: 120,
        marginRight: 5,
        marginLeft: 25,
        marginTop: 15,
      }
    sidebarMemberSmallImage =
      get(sidebar, 'sidebarMemberSmallImage.0.url') ||
      '/images/defaultSidebarSmallLogo.png'
    sidebarMemberSmallImageStyle =
      sidebar.sidebarMemberSmallImageStyle || { width: 50 }
  }

  if (isMember) {
    if (props?.session?.me?.activeOrg?.member === 'RWN') {
      sidebarMemberFullImage = '/images/YellowRWNLogo.png'
      sidebarMemberFullImageStyle = { width: 250, marginRight: 5 }
      sidebarMemberSmallImage = '/images/SmallRWNLogo.png'
      sidebarMemberSmallImageStyle = { width: 30 }
    }
  }

  return (
    <>
      <div
        className="app-sidebar--header"
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <div
          className="app-sidebar-logo"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <NavLink
            id="leftNavHeaderLogo"
            to="/"
            title="Housefolios"
            className="app-sidebar-logo-link"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
            }}
          >
            {isMember && !sidebarToggle ? (
              <img
                alt="Housefolios"
                src={sidebarMemberFullImage}
                style={{ maxHeight: '50px', maxWidth: '100%' }}
              />
            ) : (
              <div
                className="app-sidebar-logo--icon-OLD"
                style={{
                  backgroundColor: 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  maxHeight: '50px',
                }}
              >
                <img
                  alt="Housefolios"
                  style={{ maxHeight: '75px', maxWidth: '100%' }}
                  src={sidebarMemberSmallImage}
                />
              </div>
            )}
            {!isMember && (
              <div
                className="app-sidebar-logo--text"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  color: '#fff',
                  marginLeft: '10px',
                }}
              >
                <span>
                  {startcase(
                    split(
                      replace(
                        props.session.me.subscriptionPlan,
                        '_',
                        '-',
                      ),
                      '-',
                    )[0],
                  )}{' '}
                  Plan
                </span>
                <b>Housefolios</b>
              </div>
            )}
          </NavLink>
          {/* <NavLink
            to="/"
            title="Realwealth"
            className="app-sidebar-logo">
            <div className="app-sidebar-logo--icon">
              <img
                alt="Realwealth"
                src={RWNlogo}
              />
            </div>
            <div className="app-sidebar-logo--text">
              <span style={{ color: '#fe8347' }}>Marketplace</span>
              <b>Realwealth</b>
            </div>
          </NavLink> */}
        </div>
        {!includes(
          toLower(window.location.pathname),
          'property-details',
        ) && (
          <Tooltip title="Collapse sidebar" placement="right" arrow>
            <Button
              id="collapseSidebarButton"
              onClick={toggleSidebar}
              className="btn btn-sm collapse-sidebar-btn"
            >
              <MenuOpenIcon />
            </Button>
          </Tooltip>
        )}
        {!includes(
          toLower(window.location.pathname),
          'property-details',
        ) && (
          <Button
            id="toggleMainNavMobileButton"
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile },
            )}
            onClick={toggleSidebarMobile}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </Button>
        )}
        {!includes(
          toLower(window.location.pathname),
          'property-details',
        ) && (
          <Tooltip title="Expand sidebar" placement="right" arrow>
            <Button
              id="expandSidebarButton"
              onClick={toggleSidebar}
              className="expand-sidebar-btn btn btn-sm"
            >
              <FontAwesomeIcon icon={['fas', 'left-right']} />
            </Button>
          </Tooltip>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  sidebarToggle: state.DeprecatedThemeOptions.sidebarToggle,
  sidebarToggleMobile:
    state.DeprecatedThemeOptions.sidebarToggleMobile,
})

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) =>
    dispatch(setSidebarToggleMobile(enable)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSession(SidebarHeader))
