import { gql } from '@apollo/client'
import { AssumptionsFragments } from '@/housefolios-components/AssumptionsSettings/fragments'
import {
  AcquisitionFragments,
  AssetManagementFragments,
  OfferToCloseFragments,
} from '@/housefolios-components/Property/fragments'

export const GET_MARKETPLACES = gql`
  query marketplaceProperties(
    $marketplaces: [String]
    $orgIds: [String]
    $approved: Boolean
    $sold: Boolean
    $limit: Int
    $cursor: String
    $filter: String
    $sort: String
  ) {
    marketplaceProperties(
      marketplaces: $marketplaces
      orgIds: $orgIds
      approved: $approved
      sold: $sold
      limit: $limit
      cursor: $cursor
      filter: $filter
      sort: $sort
    ) {
      cursor
      totalCount
      hasNextPage
      marketplaces
      approved
      sold
      properties {
        _id
        estval
        comments
        address
        city
        county
        state
        zip
        beds
        baths
        sqft
        createdAt
        updatedAt
        strategy
        marketplaceStrategy
        strategies
        favorite
        owned
        propertyType
        propertyUnitAmount
        propertyDashboard
        marketplaces {
          marketplaceName
          approvedMarketplace
          expirationDate
          extraTags
          proformaType
        }
        marketplaceSubmittedBy {
          email
          profile {
            firstname
            lastname
          }
        }
        hideAddress
        hideDaysOnMarketplace
        status
        location {
          latitude
          longitude
        }
        heading
        year
        description
        notes
        marketplaceAnalytics
        proformaDescription
        proformaFile {
          filename
          filetype
          filesize
          url
          url_small
          createdBy
          createdAt
        }
        realeflowReportURL
        images {
          _id
          url
          url_small
        }
        mainImage
        compCount
        lot {
          _id
          owned
          status
        }
        portfolio {
          _id
          name
          strategies
          strategy
          organization {
            _id
            name
            email
            phone
            logo {
              url
              url_small
            }
            legalDescription
            CMALegalDescription
            member
          }
          assumptions {
            ...assumptions
          }
        }
        createdBy {
          email
          profile {
            firstname
            lastname
          }
        }
        acquisition {
          activeStage
          analytics {
            ...analytics
          }
          analysis {
            ...analysis
          }
        }
        assumptions
        source
        MLS
        MLSAgent {
          brokerage
          name
          phone
          email
        }
        duplicate
        lender
        lenderStatus
        lenderTimeline
        lenderSubmittedBy {
          email
          profile {
            firstname
            lastname
          }
        }
        jv
        jvStatus
        jvTimeline
        jvSubmittedBy {
          email
          profile {
            firstname
            lastname
          }
        }
        sewer
        water
        market
        type
        plan
        zone
        parcelID
        checklists {
          underwritingChecklist
          offerToCloseChecklist
          wholesaleChecklist
          lotChecklist
          buildChecklist
          saleChecklist
          fixFlipChecklist
          affiliateChecklist
          BORChecklist
          astroFlipChecklist
        }
        otherFiles
        marketplaceFiles
        kanbanProcessStageId
        videoWalkthrough
        southernImpressions
        jvFile
        budgetItems {
          name
          budget
          budgetItemProjectedStartDate
          budgetItemProjectedEndDate
          budgetItemActualStartDate
          budgetItemActualEndDate
          lines {
            name
            amount
          }
          files
        }
        subdivision
        customFields
        houseCanaryPropertyExplorerLink
        ownership
        apn
        linkedProperties
        lien
        mortgageBalance
        estimatedValue
        estimatedEquity
        skipTraceResults
        pitiData
        assetManagement {
          ...assetManagement
        }
        offerToClose {
          ...offerToClose
        }
      }
    }
  }
  ${AcquisitionFragments.analysis}
  ${AcquisitionFragments.analytics}
  ${AssumptionsFragments.assumptions}
  ${AssetManagementFragments.assetManagement}
  ${OfferToCloseFragments.offerToClose}
`

export const GET_OPTIONS = gql`
  query options($name: String!) {
    options(name: $name) {
      name
      options
    }
  }
`

export const GET_STOCKPILE = gql`
  query stockedImages($addressInput: addressInput!) {
    stockedImages(addressInput: $addressInput) {
      first
      url
      staticMap
      streetView
    }
  }
`

export const GET_ORGS_MARKETPLACE_SHARED_WITH = gql`
  query orgsMarketplaceSharedWith($url: String!) {
    orgsMarketplaceSharedWith(url: $url) {
      _id
      marketPlaces {
        name
        url
        meetingURL
        contactEmail
        googleAnalytics
        marketplaceVideoURL
        preApprovedURL
        status
        featuredProperties
        propertyCount
        submittedPropertyCount
      }
      sharedPortfolios {
        _id
        name
        strategy
        strategies
        description
        propertyCount
        defaultPortfolio
        sharePermission
        assumptions {
          ...assumptions
        }
        organization {
          _id
          name
        }
      }
      sharedMarketplaces {
        name
        url
        meetingURL
        contactEmail
        googleAnalytics
        marketplaceVideoURL
        preApprovedURL
        status
        featuredProperties
        propertyCount
        sharePermission
        shareDate
      }
      sharedProperties
      meetingUrl
      phone
      email
      website
      legalDescription
      CMALegalDescription
      delinquent
      name
      address
      city
      state
      zip
      stripeCustomerID
      subscriptions
      logo {
        url
        url_small
      }
      createdAt
      createdBy {
        _id
      }
    }
  }
  ${AssumptionsFragments.assumptions}
`
