import { useState } from 'react';

const useAmortizationDialog = (initialOpen = false) => {
  const [openAmortization, setOpenAmortization] = useState(initialOpen);

  const handleOpenAmortization = () => {
    setOpenAmortization(true);
  };

  const handleCloseAmortization = () => {
    setOpenAmortization(false);
  };

  return {
    openAmortization,
    handleOpenAmortization,
    handleCloseAmortization,
  };
};

export default useAmortizationDialog;
