import React from 'react'
import clsx from 'clsx'

import PropertiesFilter from '@/housefolios-components/Properties/Filter'

import { Button, Tooltip } from '@mui/material'

import FilterListTwoToneIcon from '@mui/icons-material/FilterListTwoTone'

import { connect } from 'react-redux'

import { setHeaderDrawerToggle } from '../../reducers/ThemeOptions'

import PerfectScrollbar from 'react-perfect-scrollbar'

const HeaderDrawer = (props) => {
  const { openFilterDrawer } = props

  const { headerDrawerToggle, setHeaderDrawerToggle } = props

  const toggleHeaderDrawer = () => {
    setHeaderDrawerToggle(!headerDrawerToggle)
  }

  return (
    <>
      {openFilterDrawer && (
        <Tooltip title="Show Filter">
          <Button
            onClick={toggleHeaderDrawer}
            variant="outlined"
            className={
              'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm'
            }
            style={
              props.propertySearch
                ? {}
                : {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }
            }
          >
            <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
              <FilterListTwoToneIcon />
            </span>
          </Button>
        </Tooltip>
      )}

      <div className="app-drawer-content">
        <Tooltip arrow title="Close drawer" placement="left">
          <Button
            size="small"
            onClick={toggleHeaderDrawer}
            className="close-drawer-btn bg-white p-0 d-40"
            id="CloseDrawerTooltip"
          >
            <div
              className={clsx(
                'navbar-toggler hamburger hamburger--elastic',
                {
                  'is-active': headerDrawerToggle,
                },
              )}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </div>
          </Button>
        </Tooltip>
        <div className="vh-100 shadow-overflow">
          <PerfectScrollbar>
            <PropertiesFilter
              propertySearch={props.propertySearch}
              lot={props.lot}
              comps={props.comps}
              properties={props.properties}
              getFilteredProperties={props.getFilteredProperties}
              setFilterReset={props.setFilterReset}
              search={props.search}
              getSearchResults={props.getSearchResults}
              setIsSavedFilter={props.setIsSavedFilter}
              activeOrg={props.activeOrg}
              public={props.public}
              toggleDrawer={toggleHeaderDrawer}
            />
          </PerfectScrollbar>
        </div>
      </div>

      <div
        onClick={toggleHeaderDrawer}
        className={clsx('app-drawer-overlay', {
          'is-active': headerDrawerToggle,
        })}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  headerDrawerToggle: state.DeprecatedThemeOptions.headerDrawerToggle,
})

const mapDispatchToProps = (dispatch) => ({
  setHeaderDrawerToggle: (enable) =>
    dispatch(setHeaderDrawerToggle(enable)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderDrawer)
