import { gql } from '@apollo/client'
import { AcquisitionFragments } from '@/housefolios-components/Property/fragments'

export const SAVE_ANALYSIS = gql`
  mutation saveAnalysis(
    $analysisInput: AnalysisInput!
    $propertyId: ID!
  ) {
    saveAnalysis(
      analysisInput: $analysisInput
      propertyId: $propertyId
    ) {
      _id
      property {
        _id
      }
      analysis {
        ...analysis
      }
    }
  }
  ${AcquisitionFragments.analysis}
`

export const UPDATE_PROPERTY = gql`
  mutation updateProperty(
    $id: ID!
    $propertyInput: propertyInput!
    $keepAssumptions: Boolean
  ) {
    updateProperty(
      id: $id
      propertyInput: $propertyInput
      keepAssumptions: $keepAssumptions
    ) {
      _id
      estval
      comments
      address
      city
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      kanbanProcessStageState
      kanbanProcessStageId
      propertyType
      propertyUnitAmount
      propertyDashboard
      hideAddress
      hideDaysOnMarketplace
      status
      createdAt
      updatedAt
      plan
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
      compCount
      lot {
        _id
        owned
        status
      }
      description
      notes
      propertyDashboard
      proformaDescription
      proformaImages
      proformaImagePage
      proformaLogo
      proformaFloorPlan
      proformaRehabCost
      proformaHideAddress
      proformaContact
      financingProformaSent
      applyForLending
      buyAndHoldOrder
      fixAndFlipOrder
      turnkeyOrder
      BRRRROrder
      shortTermRentalOrder
      wholesaleOrder
      BOROrder
      proformaFile {
        filename
        filetype
        filesize
        url
        url_small
        createdBy
        createdAt
      }
      realeflowReportURL
      portfolio {
        _id
        name
        organization {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
        }
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      acquisition {
        activeStage
        analysis {
          ...analysis
        }
      }
    }
  }
  ${AcquisitionFragments.analysis}
`

export const DELETE_IMAGE = gql`
  mutation deleteFile($_id: ID!) {
    deleteFile(_id: $_id) {
      count
    }
  }
`

export const DELETE_IMAGE_MULTI = gql`
  mutation deleteFiles($ids: [ID]!) {
    deleteFiles(ids: $ids) {
      count
    }
  }
`

export const UPDATE_COMPPROPERTY = gql`
  mutation updateCompProperty(
    $id: ID!
    $compPropertyInput: compPropertyInput!
  ) {
    updateCompProperty(
      id: $id
      compPropertyInput: $compPropertyInput
    ) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      createdAt
      updatedAt
      estval
      initialListPrice
      daysOnMarket
      rehabLevel
      saleDate
      rentalPrice
      occupancyRate
      accommodates
      rentalURL
      rentalImage
      priceAdjustment
      potential
      rental
      propertyType
      addressHash
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      imageOrder
      createdBy {
        _id
        email
        username
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const UPDATE_COMPPROPERTIES = gql`
  mutation updateCompProperties(
    $ids: [ID]!
    $compPropertyInput: compPropertyInput!
  ) {
    updateCompProperties(
      ids: $ids
      compPropertyInput: $compPropertyInput
    ) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      createdAt
      updatedAt
      estval
      initialListPrice
      daysOnMarket
      rehabLevel
      saleDate
      rentalPrice
      accommodates
      occupancyRate
      rentalURL
      rentalImage
      priceAdjustment
      potential
      rental
      propertyType
      addressHash
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      imageOrder
      createdBy {
        _id
        email
        username
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const DELETE_COMP = gql`
  mutation deleteCompProperty($id: ID!) {
    deleteCompProperty(id: $id) {
      count
    }
  }
`

export const DELETE_COMP_MULTI = gql`
  mutation deleteCompProperties($ids: [ID]!) {
    deleteCompProperties(ids: $ids) {
      count
    }
  }
`

export const SEND_EMAIL = gql`
  mutation emailPDF(
    $emails: [String!]
    $base64: String!
    $CMA: Boolean
    $attachments: [String]
  ) {
    emailPDF(
      emails: $emails
      base64: $base64
      CMA: $CMA
      attachments: $attachments
    ) {
      count
    }
  }
`

export const SAVE_OFFER_TO_CLOSE = gql`
  mutation saveOfferToCloseObject(
    $propertyId: ID!
    $offerToCloseInput: offerToCloseInput!
  ) {
    saveOfferToCloseObject(
      propertyId: $propertyId
      offerToCloseInput: $offerToCloseInput
    ) {
      _id
      propertyId
    }
  }
`

export const SAVE_ASSET_MANAGEMENT = gql`
  mutation saveAssetManagement(
    $propertyId: ID!
    $assetManagementInput: assetManagementInput!
  ) {
    saveAssetManagement(
      propertyId: $propertyId
      assetManagementInput: $assetManagementInput
    ) {
      _id
      propertyId
    }
  }
`

export const GET_RENTAL_COMPS = gql`
  mutation getRentalComps(
    $propertyId: String!
    $address: String
    $city: String
    $state: String
    $zip: String
    $beds: Int
    $baths: Float
    $location: LocationInput
  ) {
    getRentalComps(
      propertyId: $propertyId
      address: $address
      city: $city
      state: $state
      zip: $zip
      beds: $beds
      baths: $baths
      location: $location
    )
  }
`

export const CALL_HOUSE_CANARY_PROPERTY_EXPLORER = gql`
  mutation callPropertyExplorerReport(
    $propertyId: String!
    $address: String
    $zipcode: String
  ) {
    callPropertyExplorerReport(
      propertyId: $propertyId
      address: $address
      zipcode: $zipcode
    ) {
      _id
      estval
      comments
      address
      city
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      propertyType
      propertyUnitAmount
      propertyDashboard
      hideAddress
      hideDaysOnMarketplace
      status
      createdAt
      updatedAt
      plan
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
      compCount
      lot {
        _id
        owned
        status
      }
      description
      notes
      propertyDashboard
      proformaDescription
      proformaImages
      proformaImagePage
      proformaLogo
      proformaFloorPlan
      proformaRehabCost
      proformaHideAddress
      proformaContact
      financingProformaSent
      applyForLending
      buyAndHoldOrder
      fixAndFlipOrder
      turnkeyOrder
      BRRRROrder
      shortTermRentalOrder
      wholesaleOrder
      BOROrder
      proformaFile {
        filename
        filetype
        filesize
        url
        url_small
        createdBy
        createdAt
      }
      realeflowReportURL
      portfolio {
        _id
        name
        organization {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
        }
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      acquisition {
        activeStage
        analysis {
          ...analysis
        }
      }
    }
  }
  ${AcquisitionFragments.analysis}
`

export const CALL_SPRUCE = gql`
  mutation callSpruce(
    $propertyId: String!
    $data: JSON!
    $file: JSON
  ) {
    callSpruce(propertyId: $propertyId, data: $data, file: $file) {
      _id
      estval
      comments
      address
      city
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      propertyType
      propertyUnitAmount
      propertyDashboard
      hideAddress
      hideDaysOnMarketplace
      status
      createdAt
      updatedAt
      plan
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
      compCount
      lot {
        _id
        owned
        status
      }
      description
      notes
      propertyDashboard
      proformaDescription
      proformaImages
      proformaImagePage
      proformaLogo
      proformaFloorPlan
      proformaRehabCost
      proformaHideAddress
      proformaContact
      financingProformaSent
      applyForLending
      buyAndHoldOrder
      fixAndFlipOrder
      turnkeyOrder
      BRRRROrder
      shortTermRentalOrder
      wholesaleOrder
      BOROrder
      proformaFile {
        filename
        filetype
        filesize
        url
        url_small
        createdBy
        createdAt
      }
      realeflowReportURL
      portfolio {
        _id
        name
        organization {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
        }
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      acquisition {
        activeStage
        analysis {
          ...analysis
        }
      }
    }
  }
  ${AcquisitionFragments.analysis}
`

export const CANCEL_SPRUCE = gql`
  mutation cancelSpruce($propertyId: String!, $reason: String!) {
    cancelSpruce(propertyId: $propertyId, reason: $reason) {
      _id
      estval
      comments
      address
      city
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      propertyType
      propertyUnitAmount
      propertyDashboard
      hideAddress
      hideDaysOnMarketplace
      status
      createdAt
      updatedAt
      plan
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
      compCount
      lot {
        _id
        owned
        status
      }
      description
      notes
      propertyDashboard
      proformaDescription
      proformaImages
      proformaImagePage
      proformaLogo
      proformaFloorPlan
      proformaRehabCost
      proformaHideAddress
      proformaContact
      financingProformaSent
      applyForLending
      buyAndHoldOrder
      fixAndFlipOrder
      turnkeyOrder
      BRRRROrder
      shortTermRentalOrder
      wholesaleOrder
      BOROrder
      proformaFile {
        filename
        filetype
        filesize
        url
        url_small
        createdBy
        createdAt
      }
      realeflowReportURL
      portfolio {
        _id
        name
        organization {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
        }
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      acquisition {
        activeStage
        analysis {
          ...analysis
        }
      }
    }
  }
  ${AcquisitionFragments.analysis}
`
