import React, { useEffect, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { closeSnackbar, enqueueSnackbar } from 'notistack'

import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import Close from '@mui/icons-material/Close'

import {
  CREATE_COLUMN,
  DELETE_COLUMN,
  UPDATE_COLUMN,
} from '@/housefolios-components/Board/mutation'
import { GET_ME } from '@/housefolios-components/Session/queries'

function useKanbanBoardColumn(props) {
  const { kanbanBoard, _id: orgId } = props?.session.me.activeOrg
  // console.log(kanbanBoard)

  const [columns, setColumns] = useState(kanbanBoard?.columns)
  const [
    createColumn,
    // {
    //   loading: loadingCreateColumn,
    //   data: createColumnData,
    //   error: createColumnError,
    // },
  ] = useMutation(CREATE_COLUMN)

  const [updateColumn] = useMutation(UPDATE_COLUMN)

  const [deleteColumn] = useMutation(DELETE_COLUMN)

  // useEffect(() => {
  //   if (columns?.length < 1) {
  //     handleAddColumn('Initial')
  //   }
  // }, [])
  const columnIds = useMemo(
    () => columns?.map((column) => column._id),
    [columns],
  )
  // console.log('ColumnIds', columnIds)
  // console.log('Columns', columns)
  useEffect(() => {
    setColumns((prev) => kanbanBoard?.columns)
  }, [kanbanBoard])

  function handleAddColumn(columnName) {
    return createColumn({
      variables: {
        orgId,
        input: {
          columnName,
        },
      },
      update(cache, { data: { createKanbanBoardColumn } }) {
        try {
          const data = cache.readQuery({ query: GET_ME })
          const activeOrg = data.me.activeOrg

          // Check if kanbanBoard field exists
          if (!activeOrg.kanbanBoard) {
            // If not, add kanbanBoard field with columns array
            activeOrg.kanbanBoard = {
              columns: [],
            }
          }

          // Create a new array combining existing columns and new columns
          const updatedColumns = [
            // ...activeOrg.kanbanBoard.columns,
            // ...createKanbanBoardColumn.kanbanBoard.columns.filter(
            //   (column) =>
            //     !activeOrg.kanbanBoard.columns.includes(column),
            // ),
            ...createKanbanBoardColumn.kanbanBoard.columns,
          ]

          // Update activeOrg with the new columns
          const updatedActiveOrg = {
            ...activeOrg,
            kanbanBoard: {
              ...activeOrg.kanbanBoard,
              columns: updatedColumns,
            },
          }

          // Update the cache with the modified data
          cache.writeQuery({
            query: GET_ME,
            data: {
              ...data,
              me: {
                ...data.me,
                activeOrg: updatedActiveOrg,
              },
            },
          })
          enqueueSnackbar('New column added!', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          // throw Error('Test')
        } catch (error) {
          // Optionally, you can re-throw the error to propagate it
          // throw error;
          // console.error('Error updating cache:', error)
          enqueueSnackbar(
            'Failed to update board with new Column. Please refresh the page.',
            {
              variant: 'error',
              autoHideDuration: null,
              action: (key) => (
                <>
                  <IconButton
                    onClick={() => {
                      closeSnackbar(key)
                    }}
                    size="large"
                  >
                    <Close style={{ color: 'white' }} />
                  </IconButton>
                </>
              ),
            },
          )
        }
      },
      onError: () => {
        enqueueSnackbar(
          'Something went wrong. Unable to add column. Please try again!',
          {
            variant: 'error',
            autoHideDuration: null,
            action: (key) => (
              <>
                <IconButton
                  onClick={() => {
                    closeSnackbar(key)
                  }}
                  size="large"
                >
                  <Close style={{ color: 'white' }} />
                </IconButton>
              </>
            ),
          },
        )
      },
    })
  }

  function handleUpdateColumn(columnId, columnName) {
    return updateColumn({
      variables: {
        orgId,
        columnId,
        input: {
          columnName,
        },
      },
      update(cache, { data: { updateKanbanBoardColumn } }) {
        try {
          const data = cache.readQuery({ query: GET_ME })
          const activeOrg = data.me.activeOrg

          // Check if kanbanBoard field exists
          if (!activeOrg.kanbanBoard) {
            // If not, add kanbanBoard field with columns array
            activeOrg.kanbanBoard = {
              columns: [],
            }
          }

          // Create a new array combining existing columns and new columns
          const updatedColumns = [
            // ...activeOrg.kanbanBoard.columns,
            // ...createKanbanBoardColumn.kanbanBoard.columns.filter(
            //   (column) =>
            //     !activeOrg.kanbanBoard.columns.includes(column),
            // ),
            ...updateKanbanBoardColumn.kanbanBoard.columns,
          ]

          // Update activeOrg with the new columns
          const updatedActiveOrg = {
            ...activeOrg,
            kanbanBoard: {
              ...activeOrg.kanbanBoard,
              columns: updatedColumns,
            },
          }

          // Update the cache with the modified data
          cache.writeQuery({
            query: GET_ME,
            data: {
              ...data,
              me: {
                ...data.me,
                activeOrg: updatedActiveOrg,
              },
            },
          })
          enqueueSnackbar('Column updated!', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          // throw Error('Test')
        } catch (error) {
          // Optionally, you can re-throw the error to propagate it
          // throw error;
          // console.error('Error updating cache:', error)
          enqueueSnackbar(
            'Failed to update board with new Column name. Please refresh the page.',
            {
              variant: 'error',
              autoHideDuration: null,
              action: (key) => (
                <>
                  <IconButton
                    onClick={() => {
                      closeSnackbar(key)
                    }}
                    size="large"
                  >
                    <Close style={{ color: 'white' }} />
                  </IconButton>
                </>
              ),
            },
          )
        }
      },
      onError: () => {
        enqueueSnackbar(
          'Something went wrong. Unable to update column. Please try again!',
          {
            variant: 'error',
            autoHideDuration: null,
            action: (key) => (
              <>
                <IconButton
                  onClick={() => {
                    closeSnackbar(key)
                  }}
                  size="large"
                >
                  <Close style={{ color: 'white' }} />
                </IconButton>
              </>
            ),
          },
        )
      },
    })
  }

  function handleDeleteColumn(columnId) {
    return deleteColumn({
      variables: {
        orgId,
        columnId,
      },
      update(cache, { data: { deleteKanbanBoardColumn } }) {
        try {
          const data = cache.readQuery({ query: GET_ME })
          const activeOrg = data.me.activeOrg

          // Check if kanbanBoard field exists
          if (!activeOrg.kanbanBoard) {
            // If not, add kanbanBoard field with columns array
            activeOrg.kanbanBoard = {
              columns: [],
            }
          }

          // Create a new array combining existing columns and new columns
          const updatedColumns = [
            // ...activeOrg.kanbanBoard.columns,
            // ...createKanbanBoardColumn.kanbanBoard.columns.filter(
            //   (column) =>
            //     !activeOrg.kanbanBoard.columns.includes(column),
            // ),
            ...deleteKanbanBoardColumn.kanbanBoard.columns,
          ]

          // Update activeOrg with the new columns
          const updatedActiveOrg = {
            ...activeOrg,
            kanbanBoard: {
              ...activeOrg.kanbanBoard,
              columns: updatedColumns,
            },
          }

          // Update the cache with the modified data
          cache.writeQuery({
            query: GET_ME,
            data: {
              ...data,
              me: {
                ...data.me,
                activeOrg: updatedActiveOrg,
              },
            },
          })
          enqueueSnackbar('Column deleted!', {
            variant: 'success',
            autoHideDuration: 3000,
          })
          // throw Error('Test')
        } catch (error) {
          // Optionally, you can re-throw the error to propagate it
          // throw error;
          // console.error('Error updating cache:', error)
          enqueueSnackbar(
            'Failed to update board after removing the column. Please refresh the page.',
            {
              variant: 'error',
              autoHideDuration: null,
              action: (key) => (
                <>
                  <IconButton
                    onClick={() => {
                      closeSnackbar(key)
                    }}
                    size="large"
                  >
                    <Close style={{ color: 'white' }} />
                  </IconButton>
                </>
              ),
            },
          )
        }
      },
      onError: () => {
        enqueueSnackbar(
          'Something went wrong. Unable to add column. Please try again!',
          {
            variant: 'error',
            autoHideDuration: null,
            action: (key) => (
              <>
                <IconButton
                  onClick={() => {
                    closeSnackbar(key)
                  }}
                  size="large"
                >
                  <Close style={{ color: 'white' }} />
                </IconButton>
              </>
            ),
          },
        )
      },
    })
  }
  return {
    columns,
    columnIds,
    setColumns,
    handleAddColumn,
    handleUpdateColumn,
    handleDeleteColumn,
  }
}

export default useKanbanBoardColumn
