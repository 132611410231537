/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-16 11:36:56
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-04-01 12:42:44
 * @ Description: All the feature of the property search search function. Containing dialogs, menus, and the no properties view allowing for search by city, saved searchs, and recent searchs
 */

import React, { useState } from 'react'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'
import compact from 'lodash/compact'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'
import findIndex from 'lodash/findIndex'
import remove from 'lodash/remove'
import head from 'lodash/head'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import join from 'lodash/join'
import slice from 'lodash/slice'
import chunk from 'lodash/chunk'
import every from 'lodash/every'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputBase,
  List,
  ListItem,
  Menu,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  FormControl,
  FormGroup,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from '@mui/material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import SearchIcon from '@mui/icons-material/Search'
import PropertySearchBar from '@/housefolios-components/PropertySearchBar'

import Script from 'react-load-script'
import { ApolloConsumer, useMutation, useQuery } from '@apollo/client'
import {
  GET_POPULAR_SEARCHES,
  GET_ZIP_BY_CITY_STATE,
  GET_ZIP_BY_COUNTY_STATE,
} from '../queries'
import Autocomplete from '@mui/material/Autocomplete'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { GET_STATETABLE } from '../queries'
import { UPDATE_TABLE } from '../mutations'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import withSession from '@/housefolios-components/Session/withSession'
import { MoreVertOutlined } from '@mui/icons-material'
import { useStopwatch } from 'react-timer-hook'
import { GET_MARKETPLACES } from '@/housefolios-components/Marketplace/queries'
import { GENERIC_SEND_EMAIL } from '@/housefolios-components/Session/mutations'
import AddSearch from '@/housefolios-components/Dialogs/AddSearch'
import LZMA from 'lzma-web'
const my_lzma = new LZMA()

const hostname = import.meta.env.VITE_HOSTNAME
const apiKey = import.meta.env.VITE_HOUSEFOLIOS_APIKEY
const apiID = import.meta.env.VITE_HOUSEFOLIOS_APIID
const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY

function SearchArea(props) {
  const { title, topEnterCity, bottomMenus, reapiInputRef } = props

  const [anchorElMenu1, setAnchorElMenu1] = useState(null)
  const handleOpenMenu1 = (event) => {
    setAnchorElMenu1(event.currentTarget)
  }

  const handleCloseMenu1 = () => {
    setAnchorElMenu1(null)
  }

  return (
    <ApolloConsumer>
      {(client) => (
        <div className="pt-4">
          {topEnterCity === true && (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <InputBase
                  // sx={{ ml: 1, flex: 1 }}
                  placeholder="Enter a city"
                  variant="outlined"
                  // inputProps={{ 'aria-label': 'search google maps' }}
                />
              </Grid>
              <Grid item>
                <IconButton
                  type="submit"
                  sx={{ p: '10px' }}
                  aria-label="search"
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
                {/* <TextField
                   variant='outlined'
                   placeholder='Enter a city'
                   fullWidth={true}
                 /> */}
              </Grid>
            </Grid>
          )}
          {title === true ? (
            <Button
              variant="contained"
              onClick={(event) => handleOpenMenu1(event)}
              size="small"
              style={{ backgroundColor: '#008aff' }}
              className="btn-primary d-40 py-0 px-4 w-auto mx-0 mr-3 mr-lg-0 mx-lg-3 mb-4"
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
              </span>
              <span
                className="btn-wrapper--label"
                style={{ fontWeight: 600 }}
              >
                Search Area
              </span>
            </Button>
          ) : (
            <div className="text-center p-5">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <AddSearch
                  button
                  setResults={props.setResults}
                  reapiInputRef={reapiInputRef}
                />
              </Grid>
            </div>
          )}
          <Menu
            anchorEl={anchorElMenu1}
            keepMounted
            open={Boolean(anchorElMenu1)}
            onClose={handleCloseMenu1}
            classes={{ list: 'p-0' }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className="dropdown-menu-xl">
              <List
                component="div"
                className="nav-list-square nav-neutral-primary pb-0"
              >
                <ListItem
                  button
                  onClick={(event) => {
                    event.preventDefault()
                    handleCloseMenu1()
                    var input = document.getElementById(
                      'propertySearch-autocomplete',
                    )
                    input.value = ''
                    setTimeout(() => {
                      input.focus()
                    }, 20)
                  }}
                >
                  <div className="nav-link-icon">
                    <FontAwesomeIcon
                      icon={['fas', 'magnifying-glass']}
                    />
                  </div>
                  <span>Search New City</span>
                </ListItem>
                <AddSearch
                  menu
                  setResults={props.setResults}
                  closeSearchMenu={handleCloseMenu1}
                  reapiInputRef={reapiInputRef}
                />
              </List>
            </div>
          </Menu>
        </div>
      )}
    </ApolloConsumer>
  )
}

export default withSession(SearchArea)
