/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: David Helmick
 * @ Modified time: 2024-06-11 18:01:44
 * @ Description: Card detailing number of bonus properties and how to get more
 */

import React from 'react'
import BonusProperties from '../../Properties/BonusProperties'

import { Card } from '@mui/material'

import { CircularProgressbar } from 'react-circular-progressbar'
import withSession from '@/housefolios-components/Session/withSession'
import useAffiliate from '@/hooks/useAffiliate'

function BonusPropertiesComponent(props) {
  const [{ affiliate, affiliateLoading, urlAffiliate }] =
    useAffiliate({
      memberValue: props?.session?.me?.activeOrg?.member,
    })
  if (
    props.session.me.subscriptionPlan === 'enterprise-affiliate' &&
    props.page === 'launchpad'
  )
    return null
  else
    return (
      <Card className="card-box w-100 d-flex align-items-center">
        <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
          <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-2 ">
            <h6 className="font-weight-bold font-size-xl mb-1 text-black">
              Bonus Properties
            </h6>
          </div>
          <div className="mx-auto">
            <CircularProgressbar
              value={
                props.session.me.activeOrg.propertyCount
                  .bonusProperties > 0
                  ? 100
                  : 0
              }
              text={props.session.me.activeOrg.propertyCount.bonusProperties.toString()}
              strokeWidth={8}
              className="m-3 circular-progress-xxl circular-progress-primary"
            />
          </div>
          <p className="mb-3 text-black-50 text-center">
            Give 10 properties, get 10 properties!
          </p>
          {!urlAffiliate && (
            <div className="px-4 pb-4 pt-2">
              <BonusProperties
                session={props.session}
                launchpad={true}
              />
            </div>
          )}
        </div>
      </Card>
    )
}

export default withSession(BonusPropertiesComponent)
