import { gql } from '@apollo/client'

export const NEW_MESSAGE_SUBSCRIPTION = gql`
  subscription newMessage($myId: String!, $withUser: [String]) {
    newMessage(myId: $myId, withUser: $withUser) {
      text
      createdAt
      sender
    }
  }
`
export const NEW_CHAT_SUBSCRIPTION = gql`
  subscription newChat {
    newChat {
      _id
      dateCreated
      requesterId
      requester {
        username
      }
    }
  }
`
export const NEW_CHAT_MESSAGE_SUBSCRIPTION = gql`
  subscription newChatMessage {
    chatHasNewMessage {
      chatId
    }
  }
`
