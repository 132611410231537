/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-30 16:41:43
 * @ Modified by: David Helmick
 * @ Modified time: 2024-10-10 14:51:31
 * @ Description: Bar allowing changing views and opening/closing other features on all pages listing properties
 */

import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import filter from 'lodash/filter'
import get from 'lodash/get'
import includes from 'lodash/includes'
import intersection from 'lodash/intersection'
import map from 'lodash/map'

import MyPropertiesFilter from '@/layout-components/HeaderDrawer'

import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  Grid,
  Hidden,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'

// import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterListIcon from '@mui/icons-material/FilterList'
import GridOnTwoToneIcon from '@mui/icons-material/GridOnTwoTone'
import ListIcon from '@mui/icons-material/List'
import ViewKanbanIcon from '@mui/icons-material/ViewKanban'
import PollTwoToneIcon from '@mui/icons-material/PollTwoTone'
import RoomTwoToneIcon from '@mui/icons-material/RoomTwoTone'

import PortfolioAnalytics from '../../Portfolio/Analytics'
import Map from '../Map'
import withSession from '@/housefolios-components/Session/withSession'
import SelectAllPropertiesToggleCheckbox from './SelectAllPropertiesCheckbox'

let sortingChoices = [
  {
    title: 'Price - low to high',
    textAlign: 'left',
    name: 'price-acquisition.analysis.resellPrice-acquisition.analysis.listPrice-asc',
  },
  {
    title: 'Price - high to low',
    textAlign: 'left',
    name: 'price-acquisition.analysis.resellPrice-acquisition.analysis.listPrice-desc',
  },
  {
    title: 'SqFt - low to high',
    name: 'sqft-sqft-sqft-asc',
  },
  {
    title: 'SqFt - high to low',
    name: 'sqft-sqft-sqft-desc',
  },
  {
    title: 'Year - low to high',
    name: 'yearBuilt-year-year-asc',
  },
  {
    title: 'Year - high to low',
    name: 'yearBuilt-year-year-desc',
  },
]

function PropertiesNavComponent(props) {
  const {
    activeTab,
    setActiveTab,
    properties = [],
    filteredProperties = [],
    currentPageProperties = [],
    selected = [],
    selectAll,
    getSearchResults,
    search,
    showMenu,
    handleMenu,
  } = props

  const [searchStatus3, setSearchStatus3] = useState(false)
  const toggleSearch3 = () => setSearchStatus3(!searchStatus3)

  useEffect(() => {
    if (
      !props?.session?.me?.activeOrg?.isRealeflow &&
      activeTab === '3'
    )
      toggle('1')
  }, [])

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      localStorage.setItem('view', tab)
    }
  }

  const [showMap, setShowMap] = useState(false)
  const [showAnalytics, setShowAnalytics] = useState(false)

  const compare = intersection(
    map(filteredProperties, (property) => property._id),
    selected,
  )

  const [filterReset, setFilterReset] = useState(null)
  const [isSavedFilter, setIsSavedFilter] = useState(false)

  useEffect(() => {
    getSearchResults(search)
  }, [properties, filteredProperties, getSearchResults, search])

  const hiddenUp = useMediaQuery((theme) =>
    theme.breakpoints.up('md'),
  )

  return (
    <>
      <Collapse in={Boolean(showMenu)} style={{ width: '100%' }}>
        <Grid item xs={12}>
          <Card className="d-block d-sm-flex text-center text-md-left card-box p-4 align-items-center bg-secondary justify-content-between flex-row">
            {hiddenUp ? null : (
              <div
                style={{ position: 'absolute', top: 5, right: 10 }}
              >
                <Typography
                  variant="subtitle2"
                  onClick={() => handleMenu()}
                >
                  Hide Menu
                </Typography>
              </div>
            )}

            <Grid
              container
              spacing={1}
              className="d-block d-sm-flex align-items-center"
            >
              {(!props.shared || props.sharedProperties) && (
                <SelectAllPropertiesToggleCheckbox
                  currentpage={
                    props.propertySearch
                      ? filter(
                          currentPageProperties,
                          (property) =>
                            get(
                              property,
                              'portfolio.organization._id',
                            ) !== props.session.me.activeOrg._id,
                        ).length
                      : currentPageProperties.length
                  }
                  allPages={
                    props.propertySearch
                      ? filter(
                          filteredProperties,
                          (property) =>
                            get(
                              property,
                              'portfolio.organization._id',
                            ) !== props.session.me.activeOrg._id,
                        ).length
                      : filteredProperties.length
                  }
                >
                  {(value, SelectLabel) => (
                    <>
                      <FormControlLabel
                        label=""
                        control={
                          <Checkbox
                            checked={compare.length > 0}
                            indeterminate={
                              compare.length <
                                filter(
                                  value === 'all'
                                    ? filteredProperties
                                    : currentPageProperties,
                                  (property) =>
                                    get(
                                      property,
                                      'portfolio.organization._id',
                                    ) !==
                                    props.session.me.activeOrg._id,
                                ).length && compare.length > 0
                            }
                            onClick={selectAll(
                              value === 'all' ? false : true,
                            )}
                          />
                        }
                        // label={
                        //   <Typography>

                        //     {/* {props.propertySearch
                        //       ? filter(
                        //           value === 'all'
                        //             ? filteredProperties
                        //             : currentPageProperties,
                        //           (property) =>
                        //             get(
                        //               property,
                        //               'portfolio.organization._id',
                        //             ) !==
                        //             props.session.me.activeOrg._id,
                        //         ).length
                        //       : value === 'all'
                        //       ? filteredProperties.length
                        //       : currentPageProperties.length} */}
                        //   </Typography>
                        // }
                        style={{ marginRight: 0 }}
                      />
                      <SelectLabel />
                    </>
                  )}
                </SelectAllPropertiesToggleCheckbox>
              )}
              {!props.shared && (
                <Grid item className="mx-3 d-none d-md-block">
                  <div className="divider-v bg-dark opacity-3 position-relative" />
                  <div className="divider-v bg-dark opacity-3 position-relative" />
                </Grid>
              )}
              {!props.propertySearch && (
                <Grid
                  item
                  className="d-flex d-md-block justify-content-center py-3 py-md-0"
                >
                  <div
                    className={clsx(
                      'search-wrapper search-wrapper--grow',
                      { 'is-active': searchStatus3 },
                    )}
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      classes={{ root: 'bg-white' }}
                      className="w-100"
                      value={props.search}
                      onChange={(event) =>
                        props.getSearchResults(event.target.value)
                      }
                      placeholder={
                        props.propertySearch
                          ? 'Search these properties'
                          : props.marketplace
                          ? 'Search this marketplace'
                          : 'Search this portfolio...'
                      }
                      id="input-with-icon-textfield22-3"
                      label={props.propertySearch && props.searchName}
                      onFocus={toggleSearch3}
                      onBlur={toggleSearch3}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FilterListIcon
                              style={{ color: '#bbbbbb' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
              )}
              <Typography style={{ flex: 1 }} />
              {/* {props.propertySearch && (
              <Grid item style={{ marginRight: 10 }}>
                <Grid container direction='row' spacing={2}>
                  <Grid item>
                    <Typography>City:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{props.properties[0].city}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )} */}
              {activeTab === '1' &&
                !includes(window.location.href, 'my-lots') && (
                  <Grid item className="gridItem2">
                    <Select
                      variant="outlined"
                      margin="dense"
                      fullWidth={true}
                      value={props.ordering}
                      onChange={(event) =>
                        props.setOrdering(event.target.value)
                      }
                      displayEmpty
                    >
                      {map(sortingChoices, (choice) => (
                        <MenuItem
                          key={choice.name}
                          value={choice.name}
                        >
                          {choice.title}
                        </MenuItem>
                      ))}
                      <MenuItem key={'unselected'} value={''}>
                        Added - new to old
                      </MenuItem>
                    </Select>
                  </Grid>
                )}
              <Grid item className="gridItem2">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  {filterReset && (
                    <Grid item>
                      <Chip
                        color={
                          isSavedFilter ? 'primary' : 'secondary'
                        }
                        variant="outlined"
                        label="Filters Applied"
                        onDelete={filterReset}
                      />
                    </Grid>
                  )}
                  {!includes(window.location.href, 'my-lots') && (
                    <Grid item className="gridItem2">
                      <ButtonGroup /*variant="text"*/>
                        <Tooltip title="Card View">
                          <Button
                            className={clsx(
                              'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm',
                              { active: activeTab === '1' },
                            )}
                            onClick={() => {
                              toggle('1')
                            }}
                          >
                            <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                              <GridOnTwoToneIcon />
                            </span>
                          </Button>
                        </Tooltip>
                        <Tooltip title="List View">
                          <Button
                            className={clsx(
                              'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm',
                              { active: activeTab === '2' },
                            )}
                            onClick={() => {
                              toggle('2')
                            }}
                          >
                            <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                              <ListIcon />
                            </span>
                          </Button>
                        </Tooltip>
                        {props?.session?.me?.activeOrg
                          ?.isRealeflow && (
                          <Tooltip title="Kanban View">
                            <Button
                              className={clsx(
                                'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm',
                                { active: activeTab === '3' },
                              )}
                              onClick={() => {
                                toggle('3')
                              }}
                              // href="/board"
                            >
                              <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                                <ViewKanbanIcon />
                              </span>
                            </Button>
                          </Tooltip>
                        )}
                      </ButtonGroup>
                    </Grid>
                  )}
                  {!props.propertySearch && (
                    <Grid item className="gridItem2">
                      <ButtonGroup /*variant="text"*/>
                        {!includes(
                          window.location.href,
                          'my-lots',
                        ) && (
                          <>
                            <Tooltip title="Show Analytics">
                              <Button
                                className={clsx(
                                  'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm',
                                  { active: showAnalytics },
                                )}
                                onClick={() => {
                                  setShowAnalytics(!showAnalytics)
                                }}
                              >
                                <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                                  <PollTwoToneIcon />
                                </span>
                              </Button>
                            </Tooltip>
                            <Tooltip title="Show Map">
                              <Button
                                className={clsx(
                                  'btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm',
                                  { active: showMap },
                                )}
                                onClick={() => {
                                  setShowMap(!showMap)
                                }}
                              >
                                <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                                  <RoomTwoToneIcon />
                                </span>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                        <MyPropertiesFilter
                          openFilterDrawer={true}
                          propertySearch={props.propertySearch}
                          lot={props.lot}
                          properties={properties}
                          getFilteredProperties={
                            props.getFilteredProperties
                          }
                          setFilterReset={setFilterReset}
                          setIsSavedFilter={setIsSavedFilter}
                          search={search}
                          getSearchResults={getSearchResults}
                          public={props.public}
                          activeOrg={
                            props.session.me
                              ? props.session.me.activeOrg
                              : {}
                          }
                        />
                        {/* <Button className={clsx("btn-primary btn-transition-none d-40 p-0 btn-icon btn-animated-icon-sm", { active: activeTab1 === '5' })} onClick={() => { toggle1('5'); }}>
                        <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                          <FilterListTwoToneIcon />
                        </span>
                      </Button> */}
                      </ButtonGroup>
                    </Grid>
                  )}
                  {props.propertySearch && (
                    <>
                      <Grid item>
                        <ButtonGroup /*variant="text"*/>
                          <MyPropertiesFilter
                            openFilterDrawer={true}
                            propertySearch={props.propertySearch}
                            lot={props.lot}
                            properties={properties}
                            getFilteredProperties={
                              props.getFilteredProperties
                            }
                            setFilterReset={setFilterReset}
                            setIsSavedFilter={setIsSavedFilter}
                            search={search}
                            getSearchResults={getSearchResults}
                            public={props.public}
                            activeOrg={
                              props.session.me
                                ? props.session.me.activeOrg
                                : {}
                            }
                          />
                        </ButtonGroup>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {showMap && (
          <Grid item xs={12}>
            <Collapse in={showMap}>
              <Map
                properties={filteredProperties}
                selected={props.selected}
                selectProperty={props.selectProperty}
                portfolios={props.portfolios}
                marketplace={props.marketplace}
                shared={props.shared}
                public={props.public}
              />
            </Collapse>
          </Grid>
        )}
        {!props.propertySearch && (
          <Grid item xs={12}>
            <Collapse in={showAnalytics}>
              <PortfolioAnalytics
                properties={filteredProperties}
                marketplace={props.marketplace}
              />
            </Collapse>
          </Grid>
        )}
      </Collapse>
    </>
  )
}

export default withSession(PropertiesNavComponent)
