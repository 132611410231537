import { gql } from '@apollo/client'

export const AssumptionsFragments = {
  assumptions: gql`
    fragment assumptions on Assumptions {
      _id
      assetManagementFee
      acquisitionCommission
      acquisitionCommissionType
      acquisitionFee
      additionalSqftAstroFlip
      assignmentFee
      assignmentFeeType
      carryCostAstroFlip
      carryCostAstroFlipType
      closingCost
      closingCostType
      closingCostFixFlip
      closingCostFixFlipType
      closingCostTurnkey
      closingCostTurnkeyType
      closingCostBRRRR
      closingCostBRRRRType
      closingCostSTR
      closingCostSTRType
      closingCostBOR
      closingCostBORType
      closingCostB2R
      closingCostB2RType
      creditFromSeller
      downPayment
      downPaymentBOR
      downPaymentFixFlip
      downPaymentTurnkey
      downPaymentSTR
      dispositionFee
      dispositionFeeType
      dispositionFeeB2R
      dispositionFeeB2RType
      dispositionFeeBRRRR
      dispositionFeeBRRRRType
      dispositionFeeFixFlip
      dispositionFeeFixFlipType
      dispositionFeeTurnkey
      dispositionFeeTurnkeyType
      dispositionFeeSTR
      dispositionFeeSTRType
      estimatedListPrice
      estimatedRent
      estimatedRentType
      estimatedRentBOR
      estimatedRentBORType
      estimatedRehab
      estimatedRehabType
      estimatedRehabBOR
      estimatedRehabBORType
      estimatedRehabBRRRR
      estimatedRehabBRRRRType
      estimatedRehabFixFlip
      estimatedRehabFixFlipType
      estimatedRehabTurnkey
      estimatedRehabTurnkeyType
      estimatedRehabSTR
      estimatedRehabSTRType
      estimatedTaxes
      estimatedTaxesType
      financingFee
      financingFeeType
      financingFeeB2R
      financingFeeB2RType
      financingFeeBRRRR
      financingFeeBRRRRType
      financingFeeFixFlip
      financingFeeFixFlipType
      financingFeeTurnkey
      financingFeeTurnkeyType
      financingFeeSTR
      financingFeeSTRType
      growthRateIncome
      growthRateExpense
      holdingPeriod
      rentalHoldingPeriod
      rentalHoldingPeriodB2R
      rentalHoldingPeriodBRRRR
      rentalHoldingPeriodTurnkey
      rentalHoldingPeriodSTR
      rentType
      rentBORType
      rehabPeriod
      rehabPeriodBRRRR
      rehabPeriodSTR
      rehabType
      rehabBORType
      rehabBRRRRType
      rehabFixFlipType
      rehabTurnkeyType
      rehabSTRType
      rehabWholesaleType
      rehabAstroFlipType
      rehabTier
      rehabTierBOR
      rehabTierBRRRR
      rehabTierFixFlip
      rehabTierTurnkey
      rehabTierSTR
      rehabTierWholesale
      rehabTierAstroFlip
      rehabDecadeCost
      rehabDecadeCostBOR
      rehabDecadeCostBRRRR
      rehabDecadeCostFixFlip
      rehabDecadeCostTurnkey
      rehabDecadeCostSTR
      rehabDecadeCostWholesale
      rehabDecadeCostAstroFlip
      rehabContingency
      rehabContingencyType
      rehabContingencyBOR
      rehabContingencyBORType
      rehabContingencyBRRRR
      rehabContingencyBRRRRType
      rehabContingencyFixFlip
      rehabContingencyFixFlipType
      rehabContingencyTurnkey
      rehabContingencyTurnkeyType
      rehabContingencySTR
      rehabContingencySTRType
      rehabContingencyWholesale
      rehabContingencyWholesaleType
      rehabContingencyAstroFlip
      rehabContingencyAstroFlipType
      homePriceAppreciation
      investorTaxRate
      interestOnlyLoan
      interestOnlyLoanFixFlip
      interestOnlyLoanTurnkey
      interestOnlyLoanSTR
      allCashPurchase
      allCashPurchaseFixFlip
      allCashPurchaseTurnkey
      allCashPurchaseSTR
      avgRate
      avgRateType
      listPriceToSalePriceRatio
      offerToListPriceRatio
      maintenance
      maintenanceB2R
      maintenanceBRRRR
      maintenanceFixFlip
      maintenanceTurnkey
      maintenanceSTR
      utilities
      utilitiesType
      utilitiesB2R
      utilitiesB2RType
      utilitiesBRRRR
      utilitiesBRRRRType
      utilitiesFixFlip
      utilitiesFixFlipType
      utilitiesTurnkey
      utilitiesTurnkeyType
      utilitiesSTR
      utilitiesSTRType
      monthlyReserves
      monthlyReservesType
      monthlyReservesB2R
      monthlyReservesB2RType
      monthlyReservesBRRRR
      monthlyReservesBRRRRType
      monthlyReservesFixFlip
      monthlyReservesFixFlipType
      monthlyReservesTurnkey
      monthlyReservesTurnkeyType
      monthlyReservesSTR
      monthlyReservesSTRType
      otherExpense
      otherExpenseB2R
      otherExpenseBRRRR
      otherExpenseFixFlip
      otherExpenseTurnkey
      otherExpenseSTR
      cohosting
      cohostingType
      occupancyRate
      ownerReserves
      ownerReservesB2R
      ownerReservesBRRRR
      ownerReservesTurnkey
      ownerReservesFixFlip
      ownerReservesSTR
      bridgeCashPurchase
      bridgeDownPayment
      bridgeRate
      bridgeTerm
      bridgeTermType
      bridgePoints
      bridgeInterestOnlyLoan
      refinanceCashPurchase
      refinanceCashOut
      refinanceDownPayment
      refinanceRate
      refinanceTerm
      refinanceTermType
      refinancePoints
      refinanceInterestOnlyLoan
      lotCashPurchase
      lotDownPayment
      lotRate
      lotTerm
      lotTermType
      lotPoints
      lotInterestOnlyLoan
      lotStartDate
      lotDaysOfLoan
      constructionCashPurchase
      constructionDownPayment
      constructionRate
      constructionTerm
      constructionTermType
      constructionPoints
      constructionInterestOnlyLoan
      constructionStartDate
      mezzanineCashPurchase
      mezzanineDownPayment
      mezzanineRate
      mezzanineTerm
      mezzanineTermType
      mezzaninePoints
      mezzanineInterestOnlyLoan
      mezzanineStartDate
      costToBuildBuffer
      points
      pointsFixFlip
      pointsTurnkey
      pointsSTR
      propertyInsurance
      propertyInsuranceType
      propertyInsuranceTurnkey
      propertyInsuranceTurnkeyType
      propertyInsuranceFixFlip
      propertyInsuranceFixFlipType
      propertyInsuranceBRRRR
      propertyInsuranceBRRRRType
      propertyInsuranceSTR
      propertyInsuranceSTRType
      propertyInsuranceB2R
      propertyInsuranceB2RType
      propertyManagement
      propertyManagementType
      propertyManagementFixFlip
      propertyManagementFixFlipType
      propertyManagementTurnkey
      propertyManagementTurnkeyType
      propertyManagementBRRRR
      propertyManagementBRRRRType
      propertyManagementB2R
      propertyManagementB2RType
      payDownAccelerator
      payDownGrowth
      rate
      rateBOR
      rateFixFlip
      rateTurnkey
      rateSTR
      salesCommission
      salesCommissionType
      salesCommissionB2R
      salesCommissionB2RType
      salesCommissionBRRRR
      salesCommissionBRRRRType
      salesCommissionFixFlip
      salesCommissionFixFlipType
      salesCommissionTurnkey
      salesCommissionTurnkeyType
      salesCommissionSTR
      salesCommissionSTRType
      sellerClosingCost
      sellerClosingCostType
      sellerClosingCostB2R
      sellerClosingCostB2RType
      sellerClosingCostBRRRR
      sellerClosingCostBRRRRType
      sellerClosingCostFixFlip
      sellerClosingCostFixFlipType
      sellerClosingCostTurnkey
      sellerClosingCostTurnkeyType
      sellerClosingCostSTR
      sellerClosingCostSTRType
      term
      termType
      termBOR
      termFixFlip
      termFixFlipType
      termTurnkey
      termTurnkeyType
      termSTR
      termSTRType
      closingCostWholesale
      closingCostWholesaleType
      closingCostAstroFlip
      closingCostAstroFlipType
      percentageOfARVFixFlip
      percentageOfARVWholesale
      vacancy
      belowCashFlow
      belowCapRate
      aboveCapRate
      belowIRR
      aboveIRR
      belowIRRTurnkey
      aboveIRRTurnkey
      belowCashOnCashReturn
      aboveCashOnCashReturn
      belowSellingCapRate
      aboveSellingCapRate
      belowSellingCashOnCashReturn
      aboveSellingCashOnCashReturn
      belowCashFlowTurnkey
      belowNetROI
      aboveNetROI
      belowARV
      aboveARV
      belowSaleProceeds
      aboveSaleProceeds
      belowCashFlowBRRRR
      belowCashOnCashReturnBRRRR
      belowIRRBRRRR
      belowCapRateBRRRR
      belowCashFlowSTR
      belowCashOnCashReturnSTR
      belowIRRSTR
      belowCapRateSTR
      belowB2RIRR
      belowB2RCashFlow
      belowB2RNetProfit
      belowB2RCapRate
      belowB2RCashOnCashReturn
      belowWholesaleSaleProceeds
      belowBuyerProfit
      belowAstroFlipSaleProceeds
      belowAstroFlipBuyerProfit
      belowAstroFlipBuyerProfitType
      belowAverageAnnualReturnBOR
      belowIRRBOR
      belowNetProfitPep
      belowHoldingClosingCostPep
      belowTotalCostFinancingPep
      strategySpecificFields

      estimateRoofCostPerSqft
      estimateRoofRemoval
      estimateRoofCert
      estimateDetacheGarageRoofingPerSqft
      estimateSoffitAndFasciaCost
      estimateOtherRoofExpenses

      estimateWindowReplacement
      estimateWindowPaneRepair
      estimateShutterRepair
      estimateOtherWindowExpenses

      estimateSimpleFrontYard
      estimateSprinkler
      estimateUmbrellaTreeCost
      estimateRemoveTreeCost
      estimateFencing
      estimateConcretePerSqft
      estimateOtherLandscapingExpenses

      estimatePaintCostPerSqftExterior
      estimatePowerWash
      estimateCementFiberSidingPerSqft
      estimateVinylSidingPerSqft
      estimateStuccoPerSqft
      estimateFrontPorch
      estimateBackPorch
      estimateOtherExteriorExpenses

      estimatePaintCostPerSqftInterior
      estimatePatchDrywall
      estimateScrapePopcornPerSqft
      estimateTextureWallPerSqft
      estimateWallPaperRemoval
      estimateBaseboardsAndCasingsPerSqft
      estimateFrameOuts
      estimateOtherInteriorExpenses

      estimateLuxuryVinylPlankPerSqft
      estimateTilePerSqft
      estimateHardwoodPerSqft
      estimateCarpetPerSqft
      estimateFlooringExpenses

      estimateFrontDoorCost
      estimateExteriorDoorCost
      estimateSlidingDoorCost
      estimateInteriorDoorCost
      estimateInteriorFrenchDoorCost
      estimatePocketDoorCost
      estimatePocketDoorInstallOnlyCost
      estimateBarnDoorCost
      estimateGarageDoorCost
      estimateOtherDoorExpenses

      estimateRewire
      estimateExteriorLightCost
      estimateInteriorLightCost
      estimateCeilingFanCost
      estimateRecessedCanLightCost
      estimatePlugSwitchReplacementCost
      estimateSmokeDetectorCost
      estimateElectricalPanel
      estimateOtherElectricalExpenses

      estimateWaterHeaterCost
      estimateReplumbHouse
      estimateOtherPlumbingExpenses

      estimateACInstallCost
      estimateAddACToForcedAirHeat
      estimateGasPackForRoof
      estimateFurnace
      estimateWallHeaterCost
      estimateACCondenserCost
      estimateNewRegisters
      estimateServiceHVAC
      estimateOtherHVACExpenses

      estimateKitchenRemodelCost
      estimateRefrigerator
      estimateHalfKitchen
      estimateKitchenWallOpenCost
      estimateOtherKitchenExpenses

      estimateBathroomRenovationCost
      estimateTubShowerTileCost
      estimateTileCost
      estimateTubCost
      estimateTubGlazeCost
      estimateShowerCost
      estimateVanityCost
      estimateTolietCost
      estimateMirrorCabinetTowerBarToiletPaperHolderCost
      estimateOtherBathroomExpenses

      estimateDemoDumpster
      estimateFinalCleanUp
      estimatePool
      estimateFireplace
      estimateBasement
      estimateMold
      estimateFrenchDrainCost
      estimateFoundationRepair
      estimateTermiteTreatment
      estimateOtherExpenses
    }
  `,
}
