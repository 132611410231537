/**
 * @ Author: Housefolios
 * @ Create Time: 2024-06-03 15:58:01
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-10-17 14:32:32
 * @ Description: Component that handles logic to determine if it should display dialogs asking for more property information such as the critical fields dialog
 */

import React, { useEffect, useState } from 'react'

import clsx from 'clsx'

import map from 'lodash/map'
import round from 'lodash/round'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import padStart from 'lodash/padStart'
import head from 'lodash/head'
import find from 'lodash/find'
import slice from 'lodash/slice'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import replace from 'lodash/replace'
import isUndefined from 'lodash/isUndefined'
import kebabCase from 'lodash/kebabCase'
import orderBy from 'lodash/orderBy'
import unset from 'lodash/unset'
import set from 'lodash/set'
import get from 'lodash/get'
import remove from 'lodash/remove'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import withSession from '@/housefolios-components/Session/withSession'
import { enqueueSnackbar } from 'notistack'
import { NumericFormat } from 'react-number-format'
import { PacmanLoader } from 'react-spinners'
import BlockUi from 'react-block-ui'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import Autocomplete from '@mui/material/Autocomplete'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import {
  GET_COMPPROPERTIES,
  GET_PROPERTY,
} from '@/housefolios-components/PropertyContainer/queries'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_ASSUMPTIONS } from '@/housefolios-components/AssumptionsSettings/quries'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import renderInputFieldsFunction from '@/housefolios-components/Functions/renderInput'
import {
  Compare,
  ExpandLess,
  ExpandMore,
  Tune,
} from '@mui/icons-material'
import CriticalFields from '../CriticalFields'
import SubtoPITICash from '../SubtoPITI/Cash/'
import SubtoPITICreative from '../SubtoPITI/Creative/'
import { useNavigate } from 'react-router-dom'

function PropertyInformationDialogHandler(props) {
  const { open, onClose, property = {}, shared, url } = props

  let navigate = useNavigate()

  let preferences = merge(
    cloneDeep(DEFAULT_PREFERENCES),
    props?.session?.me?.activeOrg?.memberPreferences,
    props?.session?.me?.activeOrg?.preferences,
  )

  const propertyCriticalFieldCheck =
    !property?.propertyDashboard &&
    !shared &&
    preferences.general.completePropertyData
  const pitiDataCheck =
    (props?.session?.me?.activeOrg?.member === 'pacemorby' ||
      props?.session?.me?.activeOrg?.member === 'astroflip') &&
    !property?.pitiData &&
    property?.realeflowData

  const [openCriticalFields, setOpenCriticalFields] = useState(false)
  const [openPITICashData, setOpenPITICashData] = useState(false)
  const [openPITICreativeData, setOpenPITICreativeData] =
    useState(false)

  const handleCloseCriticalFields = () => {
    setOpenCriticalFields(false)
    navigate(url || '/property-details/' + property?._id + '/Summary')
    onClose()
  }

  const handleClosePITICashData = (skip = false) => {
    setOpenPITICashData(false)
    if (propertyCriticalFieldCheck && !skip)
      setOpenCriticalFields(true)
    else {
      // navigate(url || '/property-details/' + property?._id + '/Summary')
      onClose()
    }
  }

  const handleClosePITICreativeData = (skip = false) => {
    setOpenPITICreativeData(false)
    if (propertyCriticalFieldCheck && !skip)
      setOpenCriticalFields(true)
    else {
      // navigate(url || '/property-details/' + property?._id + '/Summary')
      onClose()
    }
  }

  useEffect(() => {
    if (open) {
      if (pitiDataCheck) setOpenPITICreativeData(true)
      else if (propertyCriticalFieldCheck) setOpenCriticalFields(true)
      else {
        navigate(
          url || '/property-details/' + property?._id + '/Summary',
        )
        onClose()
      }
    }
  }, [open])
  return (
    <>
      <SubtoPITICash
        open={openPITICashData}
        onClose={handleClosePITICashData}
        propertyId={property?._id}
        address={property?.address}
        city={property?.city}
        state={property?.state}
        zipcode={property?.zip}
      />
      <SubtoPITICreative
        open={openPITICreativeData}
        onClose={handleClosePITICreativeData}
        propertyId={property?._id}
        address={property?.address}
        city={property?.city}
        state={property?.state}
        zipcode={property?.zip}
      />
      <CriticalFields
        open={openCriticalFields}
        onClose={handleCloseCriticalFields}
        propertyId={property?._id}
      />
    </>
  )
}

export default withSession(PropertyInformationDialogHandler)
