/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-30 16:41:43
 * @ Modified by: David Helmick
 * @ Modified time: 2025-01-27 14:26:17
 * @ Description: The step by step process of uploading properties from a CSV file.
 */

import React, { Fragment, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import withSession from '@/housefolios-components/Session/withSession'
import Alert from '@mui/material/Alert'
import { useDropzone } from 'react-dropzone'
import Autocomplete from '@mui/material/Autocomplete'

import map from 'lodash/map'
import pullAt from 'lodash/pullAt'
import forEach from 'lodash/forEach'
import toLower from 'lodash/toLower'
import truncate from 'lodash/truncate'
import padStart from 'lodash/padStart'
import remove from 'lodash/remove'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import findKey from 'lodash/findKey'
import set from 'lodash/set'
import unset from 'lodash/unset'
import head from 'lodash/head'
import round from 'lodash/round'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import chunk from 'lodash/chunk'
import clone from 'lodash/clone'
import indexOf from 'lodash/indexOf'
import isEqual from 'lodash/isEqual'
import filter from 'lodash/filter'
import slice from 'lodash/slice'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import includes from 'lodash/includes'
import isArray from 'lodash/isArray'
import uniqWith from 'lodash/uniqWith'
import reduce from 'lodash/reduce'
import toUpper from 'lodash/toUpper'
import trim from 'lodash/trim'
import toString from 'lodash/toString'
import keys from 'lodash/keys'

// import csv from 'csv'
import Papa from 'papaparse'

import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormGroup,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Step,
  Stepper,
  StepLabel,
  Table,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material'

import Check from '@mui/icons-material/Check'
import CheckIcon from '@mui/icons-material/Check'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import FolderIcon from '@mui/icons-material/Folder'
import StepConnector from '@mui/material/StepConnector'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'

import { enqueueSnackbar } from 'notistack'
import { ArrowForward, Close } from '@mui/icons-material'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { ApolloConsumer, useMutation, useQuery } from '@apollo/client'
import { ADD_PROPERTIES } from '@/housefolios-components/Marketplace/mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { GET_ASSUMPTIONS } from '@/housefolios-components/AssumptionsSettings/quries'
import {
  CALL_AIR_DNA,
  GET_SMART_ASSET,
  GET_ZIP_VALUE,
} from '@/housefolios-components/TitleButtons/AddPropertyButton/queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import CreatePortfolio from '@/housefolios-components/Portfolio/CreatePortfolio'
import {
  ADD_COMPPROPERTY_BACKEND,
  IMAGE_UPLOAD_MULTI,
} from '@/housefolios-components/TitleButtons/AddPropertyButton/mutations'
import { CREATE_SESSION } from '@/housefolios-components/LaunchpadComponents/mutations'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { GET_STOCKPILE } from '@/housefolios-components/Marketplace/queries'
import { STATES } from '@/constants/states'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import {
  DEFAULT_ESTIMATES,
  propertyColumns,
} from '@/constants/defaultValues'
import { getPropertyData, getZipValues } from '@/utils/addProperty'

import * as XLSX from 'xlsx/xlsx.mjs'

const hostname = import.meta.env.VITE_HOSTNAME
const apiKey = import.meta.env.VITE_HOUSEFOLIOS_APIKEY
const apiID = import.meta.env.VITE_HOUSEFOLIOS_APIID
const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY

function StepIcon(props) {
  const { active, completed } = props

  const icons = {
    1: <CloudUploadIcon />,
    2: <ViewColumnIcon />,
    3: <FolderIcon />,
  }

  return (
    <div
      className={clsx(
        'd-50 transition-base d-flex align-items-center bg-gray-400 justify-content-center rounded',
        {
          'd-80 bg-primary text-white shadow-primary-sm': active,
          'd-50 bg-success text-white shadow-success-sm': completed,
        },
      )}
    >
      {completed ? (
        <Check className="completed" />
      ) : (
        icons[String(props.icon)]
      )}
    </div>
  )
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}

function getSteps() {
  // return ['Organization Details', 'Select Plan', 'Payment Details'];
  return ['Details', 'Plan', 'Payment']
}

function UploadPropertiesStepperComponent(props) {
  const [activeStep, setActiveStep] = useState(0)

  const steps = getSteps()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const [imageUploadMulti] = useMutation(IMAGE_UPLOAD_MULTI)
  const [addCompsBackend] = useMutation(ADD_COMPPROPERTY_BACKEND)

  const imageUploader = async (propertyImagePairs) => {
    imageUploadMulti({
      variables: {
        propertyImagePairs,
      },
      refetchQueries: [
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [selectedPortfolio],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: map(
              props.portfolios,
              (portfolio) => portfolio._id,
            ),
          },
        },
      ],
    }).catch((error) => {
      console.log(error)
    })
  }

  const [fileColumns, setFileColumns] = useState([])
  const [mappedColumns, setMappedColumns] = useState({})
  const [fileData, setFileData] = useState([])
  if (isEmpty(props.session.me.activeOrg.affiliate)) {
    remove(propertyColumns, (col) => col.affiliate)
  }
  const options = propertyColumns
  // const options = propertyColumns.map((option) => {
  //   const firstLetter = option.label[0].toUpperCase();
  //   return {
  //     firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
  //     ...option
  //   };
  // });

  const [propertyCountLimit, setPropertyCountLimit] = useState(-1)
  const [toTheLimit, setToTheLimit] = useState(false)
  const [noRows, setNoRows] = useState(false)

  const [openPortfolio, setOpenPortfolio] = useState(false)

  const handleOpenPortfolio = () => {
    setOpenPortfolio(true)
  }
  const handleClosePortfolio = () => {
    setOpenPortfolio(false)
  }

  const [createBillingPortalSession] = useMutation(CREATE_SESSION)

  const changePlan = async () => {
    var session = await createBillingPortalSession()

    window.open(
      session.data.createBillingPortalSession.url,
      '_self',
      '',
    )
  }

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: [
      'text/csv',
      'text/x-csv',
      'application/x-csv',
      'application/csv',
      'text/x-comma-separated-values',
      'text/comma-separated-values',
      'application/vnd.ms-excel',
    ],
    onDrop: (acceptedFiles) => {
      const { monthlyCount, bonusProperties = 0 } =
        props.session.me.activeOrg.propertyCount

      const { limits = {} } = props.session.me

      const LIMIT = limits.propertyLimit + bonusProperties

      // Create a FileReader to read the file
      const reader = new FileReader()

      if (
        acceptedFiles[0]?.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        reader.onload = function () {
          var workbook = XLSX.read(reader.result, {
            type: 'binary',
          })

          workbook.SheetNames.forEach(function (sheetName) {
            // Here is your object
            var XL_row_object = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheetName],
            )
            var json_object = JSON.stringify(XL_row_object)
            // console.log(json_object)
          })

          // COMMENT: We really should consider refactoring the upload to work with objects instead of this odd array setup. If we do we can change papa.parse to header: true for csv and use this code to create objects directly for xlsx
          //  // Here is your object
          //  var XL_row_object = XLSX.utils.sheet_to_row_object_array(
          //   workbook.Sheets[workbook.SheetNames[0]],
          // )
          // var data = JSON.stringify(XL_row_object)

          // Convert to csv
          var XL_csv = XLSX.utils.sheet_to_csv(
            workbook.Sheets[workbook.SheetNames[0]],
            { header: 1 },
          )

          // console.log(XL_csv)

          Papa.parse(XL_csv, {
            header: false, // This treats the first row as column headers, making each row an object
            skipEmptyLines: true,
            complete: (result) => {
              const data = result.data
              let csvLength = data.length

              // console.log(data)

              if (csvLength > 0) {
                setPropertyCountLimit(-1)
                setNoRows(false)

                // Handling the LIMIT and monthly count
                if (csvLength + monthlyCount >= LIMIT) {
                  setPropertyCountLimit(LIMIT - monthlyCount)
                }

                // Extracting file columns from the first data row (object keys)
                const fileColumns = data[0]
                setFileColumns(fileColumns)

                // Slicing the data if necessary, considering the monthly count and LIMIT
                const slicedData = data.slice(0, LIMIT - monthlyCount)
                setFileData(slicedData)

                // Mapping columns
                let mappedColumns = {}
                fileColumns.forEach((header) => {
                  propertyColumns.forEach((column) => {
                    if (column.alts) {
                      column.alts.forEach((alt) => {
                        if (
                          alt.toLowerCase() === header.toLowerCase()
                        ) {
                          mappedColumns[column.name] = header
                        }
                      })
                    }
                  })
                })
                setMappedColumns(mappedColumns)
              } else {
                setNoRows(true)
              }
            },
          })
        }

        reader.readAsBinaryString(acceptedFiles[0])
      } else {
        // Define what happens on file load
        reader.onload = () => {
          // Use PapaParse to parse the CSV data
          // console.log(reader.result)
          Papa.parse(reader.result, {
            header: false, // This treats the first row as column headers, making each row an object
            skipEmptyLines: true,
            complete: (result) => {
              const data = result.data
              let csvLength = data.length

              if (csvLength > 0) {
                setPropertyCountLimit(-1)
                setNoRows(false)

                // Handling the LIMIT and monthly count
                if (csvLength + monthlyCount >= LIMIT) {
                  setPropertyCountLimit(LIMIT - monthlyCount)
                }

                // Extracting file columns from the first data row (object keys)
                const fileColumns = data[0]
                setFileColumns(fileColumns)

                // Slicing the data if necessary, considering the monthly count and LIMIT
                const slicedData = data.slice(0, LIMIT - monthlyCount)
                setFileData(slicedData)

                // Mapping columns
                let mappedColumns = {}
                fileColumns.forEach((header) => {
                  propertyColumns.forEach((column) => {
                    if (column.alts) {
                      column.alts.forEach((alt) => {
                        if (
                          alt.toLowerCase() === header.toLowerCase()
                        ) {
                          mappedColumns[column.name] = header
                        }
                      })
                    }
                  })
                })
                setMappedColumns(mappedColumns)
              } else {
                setNoRows(true)
              }
            },
          })
        }
        reader.readAsText(acceptedFiles[0])
      }
    },
  })

  const [loader, setLoader] = useState(false)
  const [progress, setProgress] = useState(0)

  const [addProperties] = useMutation(ADD_PROPERTIES)

  const onSubmit = (client) => async () => {
    setLoader(true)
    setProgress(0)
    let columnNames = {}
    forEach(mappedColumns, (value, key) => {
      if (!isEmpty(value)) {
        const columnIndex = indexOf(fileColumns, value)
        columnNames[key] = columnIndex
      }
    })

    var propertyData = map(fileData, (p) => {
      let property = clone(columnNames)
      forEach(property, (value, key) => {
        property[key] = p[value]
      })
      return property
    })

    let failed = []

    for (let i = 0; i < propertyData.length; i++) {
      for (let k = i + 1; k < propertyData.length; k++) {
        if (isEqual(propertyData[i], propertyData[k])) {
          failed.push(propertyData[k])
          pullAt(propertyData, [k])
          k--
        }
      }
    }

    const assumptionQuery = await client.query({
      query: GET_ASSUMPTIONS,
      variables: {
        documentId: selectedPortfolio,
        documentType: 'portfolio',
      },
    })

    const { assumptions } = assumptionQuery.data

    const dataBatches = await chunk(propertyData, 10)
    const batchNumber = dataBatches.length
    let count = 0

    let inputBatches = []
    for (let i = 0; i < batchNumber; i++) {
      const dataBatch = dataBatches[i]
      const propertyInput = await Promise.all(
        map(dataBatch, async (inputData) => {
          const {
            streetName = '',
            streetNumber = '',
            city = '',
            county = '',
            zip = '',
            beds = '',
            baths = '',
            halfBaths = '',
            sqft = '',
            year = '',
            description = '',
            parcelID = null,
            type = null,
            sewer = null,
            water = null,
          } = inputData

          let { address = '', state = '' } = inputData

          if (!address && streetNumber && streetName)
            address = streetNumber + ' ' + streetName

          if (!address || (!zip && !city)) {
            failed.push(inputData)
            return null
          }

          const [zipValues] = await getZipValues(client, zip)

          let housefoliosARV = ''
          let housefoliosRent = ''
          let housefoliosListPrice = ''
          let housefoliosARVBySqft = ''
          let housefoliosARVByBed = ''
          let housefoliosRentBySqft = ''
          let housefoliosRentByBed = ''
          let housefoliosRentByBedAndSqft = ''
          let rentometer = ''
          let rentometerLow = ''
          let rentometerHigh = ''
          if (zipValues) {
            if (sqft) {
              housefoliosARVBySqft =
                zipValues.arvSqft *
                Number(sqft ? sqft.replace(/[^0-9.]+/g, '') : '')
              housefoliosRentBySqft =
                zipValues.rentSqft *
                Number(sqft ? sqft.replace(/[^0-9.]+/g, '') : '')
              housefoliosListPrice =
                zipValues.listSqft *
                Number(sqft ? sqft.replace(/[^0-9.]+/g, '') : '')
              housefoliosARV = housefoliosARVBySqft
              housefoliosRent = housefoliosRentBySqft
            }
            if (beds) {
              const bedsInt = Number(beds)
              if (bedsInt >= 5) {
                housefoliosARVByBed = zipValues.arvBedPlus
                housefoliosRentByBed = zipValues.rentBedPlus
                housefoliosRentByBedAndSqft =
                  zipValues.rentSqftBedPlus * sqft
              } else if (bedsInt === 4) {
                housefoliosARVByBed = zipValues.arvBed4
                housefoliosRentByBed = zipValues.rentBed4
                housefoliosRentByBedAndSqft =
                  zipValues.rentSqftBed4 * sqft
                rentometer = zipValues.rentometerBed4
                rentometerLow = zipValues.rentometerLowBed4
                rentometerHigh = zipValues.rentometerHighBed4
              } else if (bedsInt === 3) {
                housefoliosARVByBed = zipValues.arvBed3
                housefoliosRentByBed = zipValues.rentBed3
                housefoliosRentByBedAndSqft =
                  zipValues.rentSqftBed3 * sqft
                rentometer = zipValues.rentometerBed3
                rentometerLow = zipValues.rentometerLowBed3
                rentometerHigh = zipValues.rentometerHighBed3
              } else if (bedsInt === 2) {
                housefoliosARVByBed = zipValues.arvBed2
                housefoliosRentByBed = zipValues.rentBed2
                housefoliosRentByBedAndSqft =
                  zipValues.rentSqftBed2 * sqft
                rentometer = zipValues.rentometerBed2
                rentometerLow = zipValues.rentometerLowBed2
                rentometerHigh = zipValues.rentometerHighBed2
              } else if (bedsInt === 1) {
                housefoliosARVByBed = zipValues.arvBed1
                housefoliosRentByBed = zipValues.rentBed1
                housefoliosRentByBedAndSqft =
                  zipValues.rentSqftBed1 * sqft
                rentometer = zipValues.rentometerBed1
                rentometerLow = zipValues.rentometerLowBed1
                rentometerHigh = zipValues.rentometerHighBed1
              } else if (bedsInt === 0) {
                rentometer = zipValues.rentometerBed0
                rentometerLow = zipValues.rentometerLowBed0
                rentometerHigh = zipValues.rentometerHighBed0
              }
              if (housefoliosARVByBed > housefoliosARV) {
                housefoliosARV = housefoliosARVByBed
              }
              if (housefoliosRentByBed > housefoliosRent)
                housefoliosRent = housefoliosRentByBed
              if (housefoliosRentByBedAndSqft > housefoliosRent)
                housefoliosRent = housefoliosRentByBedAndSqft
              if (rentometerHigh > housefoliosRent)
                housefoliosRent = rentometerHigh
            }
          }
          if (!housefoliosListPrice && assumptions)
            if (inputData.afterRepairValue)
              housefoliosListPrice =
                (Number(
                  inputData.afterRepairValue.replace(/[^0-9.]+/g, ''),
                ) *
                  assumptions.estimatedListPrice) /
                100
            else
              housefoliosListPrice =
                (housefoliosARV * assumptions.estimatedListPrice) /
                100

          if (!housefoliosRent && assumptions)
            if (assumptions.estimatedRentType === '%')
              housefoliosRent = inputData.listPrice
                ? (inputData.listPrice.replace(/[^0-9.]+/g, '') *
                    assumptions.estimatedRent) /
                  100
                : (housefoliosListPrice * assumptions.estimatedRent) /
                  100
            else if (assumptions.estimatedRentType === 'Rent/Sqft')
              housefoliosRent = sqft
                ? assumptions.estimatedRent * Number(sqft)
                : ''
            else housefoliosRent = assumptions.estimatedRent

          let housefoliosRehab = ''
          if (assumptions)
            if (assumptions.estimatedRehabType === 'SQFT $' && sqft)
              housefoliosRehab =
                assumptions.estimatedRehab * Number(sqft)
            else if (assumptions.estimatedRehabType === 'Total $')
              housefoliosRehab = assumptions.estimatedRehab

          let addedToMarket = new Date()
          if (inputData.daysOnMarket) {
            addedToMarket.setDate(
              addedToMarket.getDate() - inputData.daysOnMarket,
            )
          }

          let foundState = find(
            STATES,
            (obj) => toLower(obj.name) === state,
          )
          if (foundState) state = foundState.shortname

          const smartAssetResult = await client.query({
            query: GET_SMART_ASSET,
            variables: {
              county: county
                ? county
                : zipValues
                  ? zipValues.county
                  : '',
              state: state,
            },
            errorPolicy: 'ignore',
          })
          const smartAssetData = !smartAssetResult.data
            ? null
            : smartAssetResult.data.smartAsset

          foundState = find(
            STATES,
            (obj) => toLower(obj.shortname) === state,
          )
          if (!foundState) state = ''

          const propertyDataInput = {
            single: true,
            formattedAddress: `${address} ${city}, ${county} ${padStart(
              truncate(zip, {
                length: 5,
                omission: '',
              }),
              5,
              '0',
            )}`,
          }

          let { propertyData, reapiPropertyData } =
            await getPropertyData(client, propertyDataInput)

          //   let data = {
          //     apiKey: apiKey,
          //     orgID: apiID,
          //     action: 'single_property',
          //     type: 'Single Family',
          //     env: hostname === 'localhost' || hostname === 'dev-admin.housefolios.com' ? 'dev' : 'live' ,
          //     address: toUpper(address),
          //     // latitude: String(latitude),
          //     // longitude: String(longitude),
          //     city: toLower(city),
          //     zips: padStart(truncate(zip, {
          //       length: 5,
          //       omission: '',
          //     }), 5, '0'),
          //     state: state,
          //   }

          //   let str_json = JSON.stringify(data)
          //   let response = { ok: false }
          //   try {
          //     response = await fetch(
          //       hostname === 'localhost'
          //       ? 'http://data.housefolios.com/housefoliosdev-api.php'
          //       //? 'http://housefolios-data.test/housefoliosdev-api.php'
          //       : hostname === 'dev-admin.housefolios.com'
          //           ? 'https://data.housefolios.com/housefoliosdev-api.php'
          //           : 'https://data.housefolios.com/housefolios-api.php',
          //       {
          //         method: 'POST',
          //         headers: {
          //           'Content-Type': 'application/json',
          //         },
          //         body: str_json,
          //       },
          //     )
          //   } catch (error) {
          //     console.log(error)
          //   }
          //   let result = response.ok ? await response.json() : []
          //   let propertyData = reduce(result, function(result, object) {
          //     forEach(object, (value, key) => {
          //       let newValue = Number(value)
          //       if (isNaN(newValue))
          //         newValue = value;
          //       (result[key] || (result[key] = [])).push(newValue);
          //     })
          //     return result;
          //   }, {})

          //   let avm
          //   if(!isEmpty(propertyData))
          //   {
          //   forEach(propertyData, (value, key) => {
          //     propertyData[key] = compact(uniqWith(propertyData[key], (obj, oth) => toLower(obj) === toLower(oth)))
          //   })
          //   data = {
          //     apiKey: apiKey,
          //     orgID: apiID,
          //     action: 'avm',
          //     env: hostname === 'localhost' || hostname === 'dev-admin.housefolios.com' ? 'dev' : 'live' ,
          //     listingId: head(propertyData.listingid),
          //     zipcode: head(propertyData.zip)
          //   }

          //   str_json = JSON.stringify(data)
          //   response = { ok: false }
          //   if (data.listingId && data.zipcode) {
          //     try {
          //       response = await fetch(
          //         hostname === 'localhost'
          //         ? 'http://data.housefolios.com/housefoliosdev-api.php'
          //         //? 'http://housefolios-data.test/housefoliosdev-api.php'
          //           : hostname === 'dev-admin.housefolios.com'
          //             ? 'https://data.housefolios.com/housefoliosdev-api.php'
          //             : 'https://data.housefolios.com/housefolios-api.php',
          //         {
          //           method: 'POST',
          //           headers: {
          //             'Content-Type': 'application/json',
          //           },
          //           body: str_json,
          //         },
          //       )
          //     } catch (error) {
          //       console.log(error)
          //     }
          //   }
          //   result = response.ok ? await response.json() : []

          //   avm = head(result)
          // }
          // else
          // {
          //   avm = ""
          // }

          // let airdna = null
          // if (
          //   hostname !== 'localhost' &&
          //   hostname !== 'dev-admin.housefolios.com'
          // ) {
          //   const airdnaResult = await client.query({
          //     query: CALL_AIR_DNA,
          //     variables: {
          //       address,
          //       city,
          //       state,
          //       zip: padStart(
          //         truncate(zip, {
          //           length: 5,
          //           omission: '',
          //         }),
          //         5,
          //         '0',
          //       ),
          //       beds: Number(beds),
          //       baths: Number(baths),
          //     },
          //     errorPolicy: 'ignore',
          //   })
          //   const data = airdnaResult.data
          //   const error = airdnaResult.error
          //   if (error) {
          //     console.log(error)
          //   }
          //   airdna = !data ? null : data.callAirDNA
          // }

          let preferences = merge(
            cloneDeep(DEFAULT_PREFERENCES),
            props?.session?.me?.activeOrg?.memberPreferences,
            props?.session?.me?.activeOrg?.preferences,
          )

          forEach(preferences, (preference, key) => {
            let newPreference = preference
            const newValues = filter(
              DEFAULT_PREFERENCES[key],
              (x) => !includes(newPreference, x),
            )
            newPreference = filter(newPreference, (x) =>
              includes(DEFAULT_PREFERENCES[key], x),
            )
            newPreference = concat(newPreference, newValues)
            preferences[key] = newPreference
          })

          //SPECIFIC COMMENT: Only listprice needed for now
          let preferencesValues = {
            house: {
              // listPrice:
              //   propertyData
              //     ? propertyData.PRICE
              //     : '',
            },
            housefoliosSqft: {
              listPrice:
                zipValues && sqft ? zipValues.listSqft * sqft : '',
            },
            housefoliosAssumption: {
              listPrice: inputData.afterRepairValue
                ? (Number(
                    inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    ),
                  ) *
                    assumptions.estimatedListPrice) /
                  100
                : (housefoliosARV * assumptions.estimatedListPrice) /
                  100,
            },
          }

          const analysisListPrice = Number(
            inputData.listPrice
              ? inputData.listPrice.replace(/[^0-9.]+/g, '')
              : head(
                  compact(
                    map(
                      preferences.listPrice,
                      (pref) => preferencesValues[pref].listPrice,
                    ),
                  ),
                ) || 0,
          )

          //SPECIFIC COMMENT: Second preferences calculation can use calculated analysisListPrice
          preferencesValues = {
            listPriceEstimate: {
              propertyTaxes:
                inputData.listPrice && zipValues
                  ? Number(
                      inputData.listPrice.replace(/[^0-9.]+/g, ''),
                    ) * zipValues.taxesPer
                  : (analysisListPrice || housefoliosListPrice) &&
                      zipValues
                    ? (analysisListPrice || housefoliosListPrice) *
                      zipValues.taxesPer
                    : '',
            },
            house: {
              // listPrice:
              //   propertyData
              //     ? propertyData.PRICE
              //     : '',
            },
            housefoliosSqft: {
              listPrice:
                zipValues && sqft ? zipValues.listSqft * sqft : '',
            },
            housefoliosGreatest: {
              rent: housefoliosRent,
              afterRepairValue: housefoliosARV,
            },
            housefoliosAssumption: {
              rehabCost: housefoliosRehab || '',
              rent: assumptions
                ? assumptions.estimatedRentType === '$'
                  ? assumptions.estimatedRent
                  : assumptions.estimatedRentType === 'Rent/Sqft'
                    ? sqft
                      ? assumptions.estimatedRent * sqft
                      : ''
                    : analysisListPrice || housefoliosListPrice
                      ? ((analysisListPrice || housefoliosListPrice) *
                          assumptions.estimatedRent) /
                        100
                      : ''
                : '',
              listPrice: inputData.afterRepairValue
                ? (Number(
                    inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    ),
                  ) *
                    assumptions.estimatedListPrice) /
                  100
                : (housefoliosARV * assumptions.estimatedListPrice) /
                  100,
              propertyTaxes: assumptions
                ? inputData.listPrice &&
                  assumptions.estimatedTaxesType === '%'
                  ? (Number(
                      inputData.listPrice.replace(/[^0-9.]+/g, ''),
                    ) *
                      assumptions.estimatedTaxes) /
                    100
                  : (analysisListPrice || housefoliosListPrice) &&
                      assumptions.estimatedTaxesType === '%'
                    ? ((analysisListPrice || housefoliosListPrice) *
                        assumptions.estimatedTaxes) /
                      100
                    : assumptions.estimatedTaxesType === '$'
                      ? assumptions.estimatedTaxes
                      : ''
                : '',
              offerPrice: assumptions
                ? inputData.listPrice
                  ? (inputData.listPrice.replace(/[^0-9.]+/g, '') *
                      assumptions.offerToListPriceRatio) /
                    100
                  : ((analysisListPrice || housefoliosListPrice) *
                      assumptions.offerToListPriceRatio) /
                      100 || ''
                : '',
            },
            // housefoliosLow: {
            //   afterRepairValue: avm ? avm.low : '',
            // },
            // housefoliosAverage: {
            //   afterRepairValue: avm ? avm.finalValue : '',
            // },
            // housefoliosHigh: {
            //   afterRepairValue: avm ? avm.high : '',
            // },
            reAPI: {
              afterRepairValue:
                propertyData && propertyData.value
                  ? head(propertyData.value.mean)
                  : '',
            },
            // houseCanary: {
            //   propertyTaxes: propertyData
            //     ? head(propertyData.propertyTaxes)
            //     : '',
            // },
            // houseCanaryLow: {
            //   afterRepairValue:
            //     propertyData && propertyData.value
            //       ? head(propertyData.value.min)
            //       : '',
            //   rent:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.min)
            //       : '',
            // },
            // houseCanaryAverage: {
            //   afterRepairValue:
            //     propertyData && propertyData.value
            //       ? head(propertyData.value.mean)
            //       : '',
            //   rent:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.mean)
            //       : '',
            // },
            // houseCanaryHigh: {
            //   afterRepairValue:
            //     propertyData && propertyData.value
            //       ? head(propertyData.value.max)
            //       : '',
            //   rent:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.max)
            //       : '',
            // },
            averageCompValues: {
              afterRepairValue: '',
            },
            averageLowestCompValues: {
              afterRepairValue: '',
            },
            smartAsset: {
              propertyTaxes:
                inputData.listPrice && smartAssetData
                  ? (Number(
                      inputData.listPrice.replace(/[^0-9.]+/g, ''),
                    ) *
                      smartAssetData.avgCountyTaxRate) /
                    100
                  : (analysisListPrice || housefoliosListPrice) &&
                      smartAssetData
                    ? ((analysisListPrice || housefoliosListPrice) *
                        smartAssetData.avgCountyTaxRate) /
                      100
                    : '',
            },
            rentometerHigh: {
              rent: rentometerHigh,
            },
            rentometer: {
              rent: rentometer,
            },
            rentometerLow: {
              rent: rentometerLow,
            },
            // airdna: {
            //   avgRate: airdna
            //     ? airdna.property_stats
            //       ? airdna.property_stats.adr.ltm
            //       : ''
            //     : '',
            //   occupancyRate: airdna
            //     ? airdna.property_stats
            //       ? airdna.property_stats.occupancy.ltm * 100
            //       : ''
            //     : '',
            // },
          }

          const afterRepairValue = Number(
            inputData.afterRepairValue
              ? inputData.afterRepairValue.replace(/[^0-9.]+/g, '')
              : head(
                  compact(
                    map(
                      preferences.afterRepairValue,
                      (pref) =>
                        preferencesValues[pref].afterRepairValue,
                    ),
                  ),
                ) || 0,
          )

          const rehabDollar = assumptions.rehabTier * sqft
          const resellPrice1 = afterRepairValue - rehabDollar
          const buyerProfitTotal =
            (assumptions.belowBuyerProfit / 100) * resellPrice1
          const resellPrice2 = resellPrice1 - buyerProfitTotal
          const closingCostWholesaleDollar =
            assumptions.closingCostWholesaleType === '%'
              ? (assumptions.closingCostWholesale / 100) *
                resellPrice2
              : assumptions.closingCostWholesale
          const resellPrice =
            resellPrice2 - closingCostWholesaleDollar

          const analysisInput = {
            listPrice: round(
              Number(
                inputData.listPrice
                  ? inputData.listPrice.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.listPrice,
                          (pref) => preferencesValues[pref].listPrice,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValue: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueBuyHold: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueFixFlip: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueTurnkey: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueSTR: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueBRRRR: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueB2R: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueBOR: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueWholesale: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rent: round(
              Number(
                inputData.rent
                  ? inputData.rent.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rent,
                          (pref) => preferencesValues[pref].rent,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rentBOR: round(
              Number(
                inputData.rent
                  ? inputData.rent.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rent,
                          (pref) => preferencesValues[pref].rent,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rentLow: round(
              Number(
                inputData.rentLow
                  ? inputData.rentLow.replace(/[^0-9.]+/g, '')
                  : 0,
              ),
            ),
            rentLowBOR: round(
              Number(
                inputData.rentLow
                  ? inputData.rentLow.replace(/[^0-9.]+/g, '')
                  : 0,
              ),
            ),
            rentHigh: round(
              Number(
                inputData.rentHigh
                  ? inputData.rentHigh.replace(/[^0-9.]+/g, '')
                  : 0,
              ),
            ),
            rentHighBOR: round(
              Number(
                inputData.rentHigh
                  ? inputData.rentHigh.replace(/[^0-9.]+/g, '')
                  : 0,
              ),
            ),
            offerPrice: round(
              Number(
                inputData.offerPrice
                  ? inputData.offerPrice.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.offerPrice,
                          (pref) =>
                            preferencesValues[pref].offerPrice,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            daysOnMarket: Number(
              inputData.daysOnMarket
                ? inputData.daysOnMarket.replace(/[^0-9.]+/g, '')
                : '',
            ),
            addedToMarket: addedToMarket,
            rehabCost: round(
              Number(
                inputData.rehabCost
                  ? inputData.rehabCost.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostBRRRR: round(
              Number(
                inputData.rehabCost
                  ? inputData.rehabCost.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostBOR: round(
              Number(
                inputData.rehabCost
                  ? inputData.rehabCost.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostFixFlip: round(
              Number(
                inputData.rehabCost
                  ? inputData.rehabCost.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostTurnkey: round(
              Number(
                inputData.rehabCost
                  ? inputData.rehabCost.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostSTR: round(
              Number(
                inputData.rehabCost
                  ? inputData.rehabCost.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostWholesale: round(
              Number(
                inputData.rehabCost
                  ? inputData.rehabCost.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostAstroFlip: round(
              Number(
                inputData.rehabCost
                  ? inputData.rehabCost.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            HOA: Number(
              inputData.HOA
                ? inputData.HOA.replace(/[^0-9.]+/g, '')
                : 0,
            ),
            HOAType: inputData.HOAType
              ? inputData.HOAType.replace(/[^0-9.]+/g, '')
              : 'Yearly',
            propertyTaxes: round(
              Number(
                inputData.propertyTaxes
                  ? inputData.propertyTaxes.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.propertyTaxes,
                          (pref) =>
                            preferencesValues[pref].propertyTaxes,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            resellPrice: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    ) // * assumptions.listPriceToSalePriceRatio / 100
                  : housefoliosARV
                    ? housefoliosARV // * assumptions.listPriceToSalePriceRatio / 100
                    : 0,
              ),
            ),
            resellPriceB2R: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    ) // * assumptions.listPriceToSalePriceRatio / 100
                  : housefoliosARV
                    ? housefoliosARV // * assumptions.listPriceToSalePriceRatio / 100
                    : 0,
              ),
            ),
            resellPriceBRRRR: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    ) // * assumptions.listPriceToSalePriceRatio / 100
                  : housefoliosARV
                    ? housefoliosARV // * assumptions.listPriceToSalePriceRatio / 100
                    : 0,
              ),
            ),
            resellPriceFixFlip: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    ) // * assumptions.listPriceToSalePriceRatio / 100
                  : housefoliosARV
                    ? housefoliosARV // * assumptions.listPriceToSalePriceRatio / 100
                    : 0,
              ),
            ),
            resellPriceTurnkey: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    ) // * assumptions.listPriceToSalePriceRatio / 100
                  : housefoliosARV
                    ? housefoliosARV // * assumptions.listPriceToSalePriceRatio / 100
                    : 0,
              ),
            ),
            resellPriceSTR: round(
              Number(
                inputData.afterRepairValue
                  ? inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    ) // * assumptions.listPriceToSalePriceRatio / 100
                  : housefoliosARV
                    ? housefoliosARV // * assumptions.listPriceToSalePriceRatio / 100
                    : 0,
              ),
            ),
            resellPriceWholesale: round(
              Number(resellPrice > 0 ? resellPrice : 0),
            ),
          }

          let source = {}
          forEach(preferences, (value, key) => {
            if (isArray(value) && !inputData[key])
              source[key] = find(
                value,
                (pref) =>
                  preferencesValues[pref] &&
                  preferencesValues[pref][key],
              )
          })

          let estimates = DEFAULT_ESTIMATES

          estimates = {
            ...estimates,
            // listPriceEstimate: {
            //   ...estimates.listPriceEstimate,
            //   propertyTaxes:
            //     analysisInput && zipValues
            //       ? analysisInput.listPrice * zipValues.taxesPer
            //       : '',
            // },
            house: {
              ...estimates.house,
              // listPrice:
              //   propertyData
              //     ? propertyData.PRICE
              //     : '',
            },
            // housefolios: {
            //   ...estimates.housefolios,
            //   // propertyTaxes:
            //   //   propertyData && zipValues
            //   //     ? propertyData.taxAssessment *
            //   //     zipValues.taxesPer
            //   //     : '',
            // },
            // housefoliosCounty: {
            //   ...estimates.housefoliosCounty,
            //   //propertyTaxes: housefoliosCounty ? housefoliosCounty : '',
            // },
            // housefoliosSqft: {
            //   ...estimates.housefoliosSqft,
            //   afterRepairValue: housefoliosARVBySqft || '',
            //   rent: housefoliosRentBySqft || '',
            //   listPrice:
            //     zipValues && sqft ? zipValues.listSqft * sqft : '',
            // },
            housefoliosAssumption: {
              ...estimates.housefoliosAssumption,
              rehabCost: housefoliosRehab || '',
              rehabCostBRRRR: housefoliosRehab || '',
              rehabCostBOR: housefoliosRehab || '',
              rehabCostFixFlip: housefoliosRehab || '',
              rehabCostTurnkey: housefoliosRehab || '',
              rehabCostSTR: housefoliosRehab || '',
              rehabCostWholesale: housefoliosRehab || '',
              rehabCostAstroFlip: housefoliosRehab || '',
              rent: assumptions
                ? assumptions.estimatedRentType === '$'
                  ? assumptions.estimatedRent
                  : assumptions.estimatedRentType === 'Rent/Sqft'
                    ? sqft
                      ? assumptions.estimatedRent * sqft
                      : ''
                    : analysisInput.listPrice || housefoliosListPrice
                      ? ((analysisInput.listPrice ||
                          housefoliosListPrice) *
                          assumptions.estimatedRent) /
                        100
                      : ''
                : '',
              rentBOR: assumptions
                ? assumptions.estimatedRentBORType === '$'
                  ? assumptions.estimatedRentBOR
                  : assumptions.estimatedRentBORType === 'Rent/Sqft'
                    ? sqft
                      ? assumptions.estimatedRentBOR * sqft
                      : ''
                    : analysisInput.listPrice || housefoliosListPrice
                      ? ((analysisInput.listPrice ||
                          housefoliosListPrice) *
                          assumptions.estimatedRentBOR) /
                        100
                      : ''
                : '',
              listPrice: analysisInput.afterRepairValue
                ? (analysisInput.afterRepairValue *
                    assumptions.estimatedListPrice) /
                  100
                : (housefoliosARV * assumptions.estimatedListPrice) /
                  100,
              propertyTaxes:
                (analysisInput.listPrice || housefoliosListPrice) &&
                assumptions
                  ? assumptions.estimatedTaxesType === '%'
                    ? ((analysisInput.listPrice ||
                        housefoliosListPrice) *
                        assumptions.estimatedTaxes) /
                      100
                    : assumptions.estimatedTaxesType === '$'
                      ? assumptions.estimatedTaxes
                      : ''
                  : '',
              offerPrice: assumptions
                ? ((analysisInput.listPrice || housefoliosListPrice) *
                    assumptions.offerToListPriceRatio) /
                    100 || ''
                : '',
            },
            // housefoliosBed: {
            //   ...estimates.housefoliosBed,
            //   afterRepairValue: housefoliosARVByBed || '',
            //   rent: housefoliosRentByBed || '',
            // },
            // housefoliosBedAndSqft: {
            //   ...estimates.housefoliosBedAndSqft,
            //   rent: housefoliosRentByBedAndSqft || '',
            // },
            // housefoliosLow: {
            //   ...estimates.housefoliosLow,
            //   afterRepairValue: avm ? avm.low : '',
            // },
            // housefoliosAverage: {
            //   ...estimates.housefoliosAverage,
            //   afterRepairValue: avm ? avm.finalValue : '',
            // },
            // housefoliosHigh: {
            //   ...estimates.housefoliosHigh,
            //   afterRepairValue: avm ? avm.high : '',
            // },
            reAPI: {
              ...estimates.reAPI,
              afterRepairValue:
                propertyData && propertyData.value
                  ? head(propertyData.value.mean)
                  : '',
            },
            // houseCanary: {
            //   ...estimates.houseCanary,
            //   propertyTaxes: propertyData
            //     ? head(propertyData.propertyTaxes)
            //     : '',
            // },
            // houseCanaryLow: {
            //   ...estimates.houseCanaryLow,
            //   afterRepairValue:
            //     propertyData && propertyData.value
            //       ? head(propertyData.value.min)
            //       : '',
            //   rent:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.min)
            //       : '',
            //   rentBOR:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.min)
            //       : '',
            // },
            // houseCanaryAverage: {
            //   ...estimates.houseCanaryAverage,
            //   afterRepairValue:
            //     propertyData && propertyData.value
            //       ? head(propertyData.value.mean)
            //       : '',
            //   rent:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.mean)
            //       : '',
            //   rentBOR:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.mean)
            //       : '',
            // },
            // houseCanaryHigh: {
            //   ...estimates.houseCanaryHigh,
            //   afterRepairValue:
            //     propertyData && propertyData.value
            //       ? head(propertyData.value.max)
            //       : '',
            //   rent:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.max)
            //       : '',
            //   rentBOR:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.max)
            //       : '',
            // },
            smartAsset: {
              ...estimates.smartAsset,
              propertyTaxes:
                (analysisInput.listPrice || housefoliosListPrice) &&
                smartAssetData
                  ? ((analysisInput.listPrice ||
                      housefoliosListPrice) *
                      smartAssetData.avgCountyTaxRate) /
                    100
                  : '',
            },
            // zillow: {
            //   ...estimates.zillow,
            //   // rent: propertyData
            //   //   ? propertyData.rentZestimate
            //   //     ? propertyData.rentZestimate.amount[0]._
            //   //     : ''
            //   //   : '',
            //   // afterRepairValue: propertyData
            //   //   ? propertyData.zestimate
            //   //     ? propertyData.zestimate.amount[0]._
            //   //     : ''
            //   //   : '',
            // },
            rentometer: {
              ...estimates.rentometer,
              rent: rentometer,
              rentBOR: rentometer,
            },
            rentometerLow: {
              ...estimates.rentometerLow,
              rent: rentometerLow,
              rentBOR: rentometerLow,
            },
            rentometerHigh: {
              ...estimates.rentometerHigh,
              rent: rentometerHigh,
              rentBOR: rentometerHigh,
            },
            // airdna: {
            //   ...estimates.airdna,
            //   avgRate: airdna
            //     ? airdna.property_stats
            //       ? airdna.property_stats.adr.ltm
            //       : ''
            //     : '',
            //   occupancyRate: airdna
            //     ? airdna.property_stats
            //       ? airdna.property_stats.occupancy.ltm * 100
            //       : ''
            //     : '',
            // },
          }

          let assumptionsInput = { ...assumptions }
          unset(assumptionsInput, '_id')
          unset(assumptionsInput, '__typename')

          const assumptionKeys = keys(assumptionsInput)

          forEach(inputData, (value, key) => {
            if (assumptionsInput[key]) {
              const filteredKeys = filter(
                assumptionKeys,
                (checkKey) =>
                  includes(toLower(checkKey), toLower(key)) &&
                  !includes(checkKey, 'Type'),
              )
              forEach(filteredKeys, (key) => {
                set(assumptionsInput, key, Number(value))
              })
            }
          })

          // const assumptionsInput = {
          //   propertyManagement: !isNil(
          //     assumptions.propertyManagement,
          //   )
          //     ? assumptions.propertyManagement
          //     : ASSUMPTIONS_DEFAULT.propertyManagement,
          //   maintenance: !isNil(assumptions.maintenance)
          //     ? assumptions.maintenance
          //     : ASSUMPTIONS_DEFAULT.maintenance,
          //   vacancy: !isNil(assumptions.vacancy)
          //     ? assumptions.vacancy
          //     : ASSUMPTIONS_DEFAULT.vacancy,
          //   downPayment: !isNil(assumptions.downPayment)
          //     ? assumptions.downPayment
          //     : ASSUMPTIONS_DEFAULT.downPayment,
          //   points: !isNil(assumptions.points)
          //     ? assumptions.points
          //     : ASSUMPTIONS_DEFAULT.points,
          //   financingFee: !isNil(assumptions.financingFee)
          //     ? assumptions.financingFee
          //     : ASSUMPTIONS_DEFAULT.financingFee,
          //   closingCost: !isNil(assumptions.closingCost)
          //     ? assumptions.closingCost
          //     : ASSUMPTIONS_DEFAULT.closingCost,
          //   acquisitionCommission: !isNil(
          //     assumptions.acquisitionCommission,
          //   )
          //     ? assumptions.acquisitionCommission
          //     : ASSUMPTIONS_DEFAULT.acquisitionCommission,
          //   acquisitionCommissionType: !isNil(
          //     assumptions.acquisitionCommissionType,
          //   )
          //     ? assumptions.acquisitionCommissionType
          //     : ASSUMPTIONS_DEFAULT.acquisitionCommissionType,
          //   acquisitionFee: !isNil(assumptions.acquisitionFee)
          //     ? assumptions.acquisitionFee
          //     : ASSUMPTIONS_DEFAULT.acquisitionFee,
          //   assetManagementFee: !isNil(
          //     assumptions.assetManagementFee,
          //   )
          //     ? assumptions.assetManagementFee
          //     : ASSUMPTIONS_DEFAULT.assetManagementFee,
          //   interestOnlyLoan: !isNil(assumptions.interestOnlyLoan)
          //     ? assumptions.interestOnlyLoan
          //     : ASSUMPTIONS_DEFAULT.interestOnlyLoan,
          //   rate: !isNil(assumptions.rate)
          //     ? assumptions.rate
          //     : ASSUMPTIONS_DEFAULT.rate,
          //   term: !isNil(assumptions.term)
          //     ? assumptions.term
          //     : ASSUMPTIONS_DEFAULT.term,
          //   holdingPeriod: !isNil(assumptions.holdingPeriod)
          //     ? assumptions.holdingPeriod
          //     : ASSUMPTIONS_DEFAULT.holdingPeriod,
          //   otherExpense: !isNil(assumptions.otherExpense)
          //     ? assumptions.otherExpense
          //     : ASSUMPTIONS_DEFAULT.otherExpense,
          //   propertyInsurance: !isNil(assumptions.propertyInsurance)
          //     ? assumptions.propertyInsurance
          //     : ASSUMPTIONS_DEFAULT.propertyInsurance,
          //   ownerReserves: !isNil(assumptions.ownerReserves)
          //     ? assumptions.ownerReserves
          //     : ASSUMPTIONS_DEFAULT.ownerReserves,
          //   payDownAccelerator: !isNil(
          //     assumptions.payDownAccelerator,
          //   )
          //     ? assumptions.payDownAccelerator
          //     : ASSUMPTIONS_DEFAULT.payDownAccelerator,
          //   growthRateIncome: !isNil(assumptions.growthRateIncome)
          //     ? assumptions.growthRateIncome
          //     : ASSUMPTIONS_DEFAULT.growthRateIncome,
          //   growthRateExpense: !isNil(assumptions.growthRateExpense)
          //     ? assumptions.growthRateExpense
          //     : ASSUMPTIONS_DEFAULT.growthRateExpense,
          //   listPriceToSalePriceRatio: !isNil(
          //     assumptions.listPriceToSalePriceRatio,
          //   )
          //     ? assumptions.listPriceToSalePriceRatio
          //     : ASSUMPTIONS_DEFAULT.listPriceToSalePriceRatio,
          //   homePriceAppreciation: !isNil(
          //     assumptions.homePriceAppreciation,
          //   )
          //     ? assumptions.homePriceAppreciation
          //     : ASSUMPTIONS_DEFAULT.homePriceAppreciation,
          //   salesCommission: !isNil(assumptions.salesCommission)
          //     ? assumptions.salesCommission
          //     : ASSUMPTIONS_DEFAULT.salesCommission,
          //   sellerClosingCost: !isNil(assumptions.sellerClosingCost)
          //     ? assumptions.sellerClosingCost
          //     : ASSUMPTIONS_DEFAULT.sellerClosingCost,
          //   sellerClosingCostType: !isNil(
          //     assumptions.sellerClosingCostType,
          //   )
          //     ? assumptions.sellerClosingCostType
          //     : ASSUMPTIONS_DEFAULT.sellerClosingCostType,
          //   dispositionFee: !isNil(assumptions.dispositionFee)
          //     ? assumptions.dispositionFee
          //     : ASSUMPTIONS_DEFAULT.dispositionFee,
          //   estimatedRehabCost: !isNil(
          //     assumptions.estimatedRehabCost,
          //   )
          //     ? assumptions.estimatedRehabCost
          //     : ASSUMPTIONS_DEFAULT.estimatedRehabCost,
          //   investorTaxRate: !isNil(assumptions.investorTaxRate)
          //     ? assumptions.investorTaxRate
          //     : ASSUMPTIONS_DEFAULT.investorTaxRate,
          // belowCapRate: assumptions.belowCapRate
          //   ? assumptions.belowCapRate
          //   : assumptions.belowCapRate,
          // aboveCapRate: assumptions.aboveCapRate
          //   ? assumptions.aboveCapRate
          //   : assumptions.aboveCapRate,
          // belowCashOnCashReturn: assumptions.belowCashOnCashReturn
          //   ? assumptions.belowCashOnCashReturn
          //   : assumptions.belowCashOnCashReturn,
          // aboveCashOnCashReturn: assumptions.aboveCashOnCashReturn
          //   ? assumptions.aboveCashOnCashReturn
          //   : assumptions.aboveCashOnCashReturn,
          // belowIRR: inputData.belowIRR
          //   ? inputData.belowIRR
          //   : assumptions.belowIRR,
          // aboveIRR: inputData.aboveIRR
          //   ? inputData.aboveIRR
          //   : assumptions.aboveIRR,
          // belowEquity: inputData.belowEquity
          //   ? inputData.belowEquity
          //   : assumptions.belowEquity,
          // aboveEquity: inputData.aboveEquity
          //   ? inputData.aboveEquity
          //   : assumptions.aboveEquity,
          // belowSellingCapRate: assumptions.belowSellingCapRate
          //   ? assumptions.belowSellingCapRate
          //   : assumptions.belowSellingCapRate,
          // aboveSellingCapRate: assumptions.aboveSellingCapRate
          //   ? assumptions.aboveSellingCapRate
          //   : assumptions.aboveSellingCapRate,
          // belowSellingCashOnCashReturn: assumptions.belowSellingCashOnCashReturn
          //   ? assumptions.belowSellingCashOnCashReturn
          //   : assumptions.belowSellingCashOnCashReturn,
          // aboveSellingCashOnCashReturn: assumptions.aboveSellingCashOnCashReturn
          //   ? assumptions.aboveSellingCashOnCashReturn
          //   : assumptions.aboveSellingCashOnCashReturn,
          // belowNetROI: assumptions.belowNetROI
          //   ? assumptions.belowNetROI
          //   : assumptions.belowNetROI,
          // aboveNetROI: assumptions.aboveNetROI
          //   ? assumptions.aboveNetROI
          //   : assumptions.aboveNetROI,
          // belowARV: assumptions.belowARV
          //   ? assumptions.belowARV
          //   : assumptions.belowARV,
          // aboveARV: assumptions.aboveARV
          //   ? assumptions.aboveARV
          //   : assumptions.aboveARV,
          // }

          return {
            portfolioId: selectedPortfolio,
            address: address,
            city: city ? city : '',
            state: state
              ? state
              : zipValues
                ? zipValues.statesShort[0]
                : '',
            county: county
              ? county
              : zipValues
                ? zipValues.county
                : '',
            zip: zip
              ? padStart(
                  truncate(zip, { length: 5, omission: '' }),
                  5,
                  '0',
                )
              : '',
            beds: Number(beds),
            baths: Number(baths) + Number(halfBaths) / 2,
            sqft: Number(sqft ? sqft.replace(/[^0-9.]+/g, '') : ''),
            year: Number(year),
            analysisInput: analysisInput,
            description: toString(description),
            estimates,
            source,
            MLSID: inputData.MLSID,
            parcelID,
            type,
            sewer,
            water,
            assumptionsInput: assumptionsInput,
          }
        }),
      )

      count++
      setProgress((count / batchNumber) * 50)

      inputBatches.push(propertyInput)

      localStorage.setItem('portfolio', selectedPortfolio)
    }

    let uploadedProperties = 0
    let addedProperties = []
    for (let i = 0; i < batchNumber; i++) {
      const propertyInputs = inputBatches[i]
      const valid = compact(propertyInputs)
      uploadedProperties += valid.length
      const { data } = await addProperties({
        variables: {
          propertyInputs: valid,
          noDuplicates: noDup,
        },
        refetchQueries: [
          {
            query: GET_ME,
          },
          {
            query: GET_PORTFOLIOS,
          },
          {
            query: GET_PROPERTIES,
            variables: { portfolioId: [selectedPortfolio] },
          },
          {
            query: GET_PROPERTIES,
            variables: {
              portfolioId: map(
                props.portfolios,
                (portfolio) => portfolio._id,
              ),
            },
          },
        ],
      })
      addedProperties = concat(addedProperties, data.addProperties)

      count++
      setProgress((count / batchNumber) * 50)
    }

    const propertyImagePairs = compact(
      await Promise.all(
        map(propertyData, async (property) => {
          // let images = await Promise.all(
          //   map(photo.urls, async url => {
          //     return 'https:' + url
          //   }),
          // )
          const propertyAdded = find(
            compact(addedProperties),
            (obj) => obj.address === property.address,
          )
          if (propertyAdded) {
            let images = []
            const stockpileCheck = await client.query({
              query: GET_STOCKPILE,
              variables: {
                addressInput: {
                  address: propertyAdded.address,
                  city: propertyAdded.city,
                  county: propertyAdded.county,
                  state: propertyAdded.state,
                },
              },
              errorPolicy: 'ignore',
            })
            const stockpileValues = stockpileCheck
              ? stockpileCheck.data
              : null
            const { stockedImages } = stockpileValues || {}
            if (stockedImages)
              images =
                !isEmpty(stockedImages.url) || stockedImages.first
                  ? compact(
                      concat(
                        [stockedImages.first],
                        stockedImages.url,
                      ),
                    ) || []
                  : compact([
                      stockedImages.streetView,
                      stockedImages.staticMap,
                    ]) || []
            let loc = propertyAdded.location || {}
            const { latitude, longitude } = loc
            try {
              if (latitude && longitude) {
                const MAP_KEY = googleApiKey
                if (isEmpty(images)) {
                  var service = window.google
                    ? new window.google.maps.StreetViewService()
                    : 0
                  if (service !== 0) {
                    await new Promise((resolve, reject) => {
                      try {
                        service.getPanoramaByLocation(
                          {
                            lat: Number(latitude),
                            lng: Number(longitude),
                          },
                          50,
                          function (panoData) {
                            if (panoData !== null) {
                              var ManLatLng = panoData.location.latLng
                              const heading =
                                window.google.maps.geometry.spherical.computeHeading(
                                  ManLatLng,
                                  new window.google.maps.LatLng(
                                    Number(latitude),
                                    Number(longitude),
                                  ),
                                )
                              images.push(
                                `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${latitude},${longitude}&heading=${heading}&key=${MAP_KEY}`,
                              )
                            }
                            resolve()
                          },
                        )
                      } catch (error) {
                        console.log(error)
                      }
                    })
                  }
                  images.push(
                    `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&size=400x400&markers=color:blue%7C${latitude},${longitude}&key=${MAP_KEY}`,
                  )
                }
                return {
                  propertyId: propertyAdded._id,
                  imageUrls: images || [],
                }
              }
            } catch (error) {
              console.log(error)
            }
          }
          return null
        }),
      ),
    )
    imageUploader(propertyImagePairs)

    if (!props.session.me.activeOrg.isRealeflow)
      addCompsBackend({
        variables: {
          propertyIds: map(
            compact(addedProperties),
            (property) => property._id,
          ),
        },
      })

    const dup =
      addedProperties.length - compact(addedProperties).length
    if (dup > 0)
      enqueueSnackbar(`${dup} duplicate properties not added`, {
        variant: 'error',
        action: (key) => (
          <Fragment>
            <IconButton
              onClick={() => {
                props.closeSnackbar(key)
              }}
              size="large"
            >
              <Close style={{ color: 'white' }} />
            </IconButton>
          </Fragment>
        ),
      })

    enqueueSnackbar(
      `${uploadedProperties - dup} properties uploaded`,
      {
        variant: 'success',
        action: (key) => (
          <IconButton
            onClick={() => {
              props.closeSnackbar(key)
            }}
            size="large"
          >
            <Close style={{ color: 'white' }} />
          </IconButton>
        ),
      },
    )
    if (failed.length > 0) {
      enqueueSnackbar(`${failed.length} properties failed`, {
        variant: 'error',
        action: (key) => (
          <Fragment>
            {/* <Button
                onClick={() =>
                  this.setState({ openFailDialog: true })
                }
                style={{ color: 'white' }}
              >
                SEE FAILED PROPERTIES
              </Button> */}
            <IconButton
              onClick={() => {
                props.closeSnackbar(key)
              }}
              size="large"
            >
              <Close style={{ color: 'white' }} />
            </IconButton>
          </Fragment>
        ),
      })
      // this.setState({ failed: failed })
    }
    setLoader(false)
    props.handleCloseUploadProperties()
    handleNext()
  }

  const files = acceptedFiles.map((file) => (
    <ListItem
      className="font-size-sm px-3 py-2 text-primary d-flex justify-content-between align-items-center"
      key={file.path}
    >
      <span>{file.path}</span>{' '}
      <span className="badge badge-pill bg-neutral-warning text-warning">
        {file.size} bytes
      </span>
    </ListItem>
  ))

  React.useEffect(() => {
    setFileColumns([])
    setMappedColumns({})
    setFileData([])
    setSelectedPortfolio(null)
    setPropertyCountLimit(-1)
    setNoDup(false)
    setNoRows(false)
    setLoader(false)
  }, [props.openUploadProperties])

  var { loading, error, data } = useQuery(GET_PORTFOLIOS)
  const { portfolios } = data || {}
  const [selectedPortfolio, setSelectedPortfolio] = useState()
  const [noDup, setNoDup] = useState(true)
  if (loading) return 'loading'
  if (error) return error
  if (!selectedPortfolio && portfolios)
    setSelectedPortfolio(head(portfolios)._id)

  const remainingRequired = filter(
    options,
    (option) => option.required && !mappedColumns[option.name],
  )

  return (
    <BlockUi
      blocking={loader}
      loader={
        <PacmanLoader color={'var(--primary)'} loading={true} />
      }
      message={
        <div className="text-primary">
          <p>
            {progress >= 50
              ? 'Uploading Properties'
              : 'Gathering Data'}
          </p>
          <p>{progress.toFixed(1)}% Complete</p>
        </div>
      }
    >
      {/* {props.listItem && (
        <ListItem
          component="a"
          button
          disableRipple
          onClick={handleOpenCreateNewDialog}
          className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
          <div className="d-flex align-items-center">
            <div className="avatar-icon-wrapper d-50 mr-3">
              <div className="avatar-icon rounded-circle d-50">
                <IconButton style={{ backgroundColor: 'black' }}>
                  <AddIcon style={{ color: 'white' }} />
                </IconButton>
              </div>
            </div>
            <div>
              <div className="font-weight-bold font-size-sm text-black">
                Create New Organization
                </div>
              <div className="d-flex align-items-center font-size-xs">
                <div>Manage multiple orgs in 1 account</div>
              </div>
            </div>
          </div>
        </ListItem>
      )} */}

      {/* <Dialog
        maxWidth="lg"
        open={openCreateNewOrgDialog}
        onClose={handleCloseCreateNewDialog}
        fullWidth
      >
        <DialogContent> */}
      <Card className="card-box">
        <div className="card-header">
          <div className="card-header--title">
            <Typography variant="h6">
              Upload Properties Wizard
            </Typography>
          </div>
        </div>
        <div>
          <div className="bg-secondary mb-3">
            <Stepper
              style={{ padding: 24 }}
              className="stepper-horizontal-1"
              alternativeLabel
              activeStep={activeStep}
              connector={<StepConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={StepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {activeStep === steps.length ? (
            <div className="text-center p-5">
              <div className="text-center p-5">
                <div className="avatar-icon-wrapper rounded-circle m-0">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
                    <FontAwesomeIcon
                      icon={['far', 'check']}
                      className="d-flex align-self-center display-3"
                    />
                  </div>
                </div>
                <h4 className="font-weight-bold mt-4">
                  Spreadsheet Uploaded
                </h4>
                <div className="pt-4">
                  <Button
                    onClick={props.handleCloseUploadProperties}
                    className="btn-success font-weight-bold rounded hover-scale-lg mx-1"
                    size="large"
                    endIcon={<ArrowForward />}
                  >
                    <span className="btn-wrapper--label">
                      Close Properties Uploader
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            // <ValidatorForm onSubmit={activeStep === steps.length - 1 ? handleAdd : handleNext}>
            <span>
              <div
                style={{
                  maxHeight: window.innerHeight - 300,
                  overflow: 'scroll',
                }}
              >
                {activeStep === 0 && (
                  <Container>
                    <div className="p-4">
                      {/* <Card className="mt-4 p-3 p-lg-5 shadow-xxl"> */}
                      <div className="dropzone">
                        <div
                          {...getRootProps({
                            className: 'dropzone-upload-wrapper',
                          })}
                        >
                          <input {...getInputProps()} />
                          <div className="dropzone-inner-wrapper">
                            {isDragAccept && (
                              <div>
                                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                                  <CheckIcon className="d-50" />
                                </div>
                                <div className="font-size-sm text-success">
                                  All files will be uploaded!
                                </div>
                              </div>
                            )}
                            {isDragReject && (
                              <div>
                                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                                  <CloseTwoToneIcon className="d-50" />
                                </div>
                                <div className="font-size-sm text-danger">
                                  Some files will be rejected!
                                </div>
                              </div>
                            )}
                            {!isDragActive && (
                              <div>
                                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                                  <CloudUploadTwoToneIcon className="d-50" />
                                </div>
                                <div className="font-size-sm">
                                  Drag and drop csv file
                                </div>
                              </div>
                            )}

                            <small className="py-2 text-black-50">
                              or
                            </small>
                            <div>
                              <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                                <span className="px-2">
                                  Browse Files
                                </span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {files.length <= 0 ? (
                          <div className="text-info text-center font-size-sm my-4">
                            Uploaded file will appear here!
                          </div>
                        ) : (
                          <div className="font-weight-bold my-4 text-uppercase text-dark font-size-sm text-center">
                            Uploaded File
                          </div>
                        )}
                        {files.length > 0 &&
                          propertyCountLimit < 0 &&
                          !noRows && (
                            <div>
                              <Alert
                                severity="success"
                                className="text-center mb-3"
                              >
                                You have uploaded{' '}
                                <b>{files.length}</b> file!
                              </Alert>
                              <List
                                component="div"
                                className="font-size-sm"
                              >
                                {files}
                              </List>
                            </div>
                          )}
                        {propertyCountLimit >= 0 && (
                          <div>
                            <Alert
                              severity="error"
                              className="text-center mb-3"
                              action={
                                <Button
                                  onClick={
                                    isEmpty(
                                      props.session.me.activeOrg
                                        .subscriptions,
                                    )
                                      ? props.toggleSubscribe
                                      : changePlan
                                  }
                                  variant="outlined"
                                  color="primary"
                                >
                                  Upgrade
                                </Button>
                              }
                            >
                              Cannot upload CSV. This would exceed the
                              monthly property limit.
                            </Alert>
                            <List
                              component="div"
                              className="font-size-sm"
                            >
                              {files}
                            </List>
                            <FormControlLabel
                              onChange={() =>
                                setToTheLimit(!toTheLimit)
                              }
                              control={
                                <Checkbox
                                  checked={toTheLimit}
                                  name="default"
                                  disabled={
                                    propertyCountLimit === 0
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label={
                                propertyCountLimit === 0
                                  ? 'You have reached your monthly limit'
                                  : `Add first ${propertyCountLimit} properties from uploaded document`
                              }
                            />
                          </div>
                        )}
                        {noRows && (
                          <div>
                            <Alert
                              severity="error"
                              className="text-center mb-3"
                            >
                              Cannot upload CSV. CSV data is
                              incomplete.
                            </Alert>
                            <List
                              component="div"
                              className="font-size-sm"
                            >
                              {files}
                            </List>
                          </div>
                        )}
                      </div>
                      {/* </Card> */}
                    </div>
                  </Container>
                )}
                {activeStep === 1 && (
                  <Container style={{ padding: 0 }}>
                    <div className="p-2">
                      <Card className="card-box mb-spacing-6-x2">
                        {/* <div className="card-header pr-2">
                            <div className="card-header--title">
                              <small>Tables</small>
                              <b>Example table with custom content</b>
                            </div>
                            <div className="card-header--actions">
                              <Button
                                href={null}
                                onClick={(e) => e.preventDefault()}
                                size="small"
                                className="btn-link text-primary"
                                id="RefreshTooltip1">
                                <FontAwesomeIcon icon={['fas', 'cog']} spin />
                              </Button>
                            </div>
                          </div> */}
                        {remainingRequired.length > 0 && (
                          <Grid container className="p-3" spacing={1}>
                            <Grid item>
                              <Typography
                                variant="subtitle1"
                                style={{
                                  color: 'red',
                                  fontWeight: 600,
                                }}
                              >
                                These fields are required:
                              </Typography>
                            </Grid>
                            {map(remainingRequired, (field) => (
                              <Grid item>
                                <Typography variant="subtitle1">
                                  {field.label},
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                        <CardContent className="p-0">
                          <div className="table-responsive-md">
                            <Table className="table text-nowrap mb-0">
                              <thead /*className="thead-light"*/>
                                <tr key="header">
                                  <th>Matched</th>
                                  <th>File Header Column</th>
                                  <th className="text-center">
                                    Preview
                                  </th>
                                  <th>Housefolios Field</th>
                                </tr>
                              </thead>
                              <tbody>
                                {map(fileColumns, (row, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        {findKey(
                                          mappedColumns,
                                          (col) => col === row,
                                        ) && (
                                          <Button
                                            className="btn-pill d-40 btn-icon btn-animated-icon p-0 mr-3"
                                            style={{
                                              backgroundColor:
                                                findKey(
                                                  mappedColumns,
                                                  (col) =>
                                                    col === row,
                                                )
                                                  ? '#1cc943'
                                                  : '#ff3f3d',
                                              color: 'white',
                                            }}
                                          >
                                            <span className="btn-wrapper--icon d-flex">
                                              <FontAwesomeIcon
                                                icon={
                                                  findKey(
                                                    mappedColumns,
                                                    (col) =>
                                                      col === row,
                                                  )
                                                    ? ['far', 'check']
                                                    : ['far', 'xmark']
                                                }
                                                className="font-size-lg"
                                              />
                                            </span>
                                          </Button>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="align-box-row">
                                        {row}
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      {truncate(fileData[0][index], {
                                        length: 20,
                                      })}
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <Container
                                          style={{ minWidth: 300 }}
                                        >
                                          <Autocomplete
                                            options={options}
                                            value={
                                              find(options, [
                                                'name',
                                                findKey(
                                                  mappedColumns,
                                                  (col) =>
                                                    col === row,
                                                ),
                                              ]) || null
                                            }
                                            onChange={(
                                              event,
                                              value,
                                            ) => {
                                              let newMap = {
                                                ...mappedColumns,
                                              }
                                              if (value) {
                                                if (
                                                  findKey(
                                                    mappedColumns,
                                                    (col) =>
                                                      col === row,
                                                  )
                                                )
                                                  unset(
                                                    newMap,
                                                    findKey(
                                                      mappedColumns,
                                                      (col) =>
                                                        col === row,
                                                    ),
                                                  )
                                                newMap[value.name] =
                                                  row
                                              } else
                                                unset(
                                                  newMap,
                                                  findKey(
                                                    mappedColumns,
                                                    (col) =>
                                                      col === row,
                                                  ),
                                                )
                                              setMappedColumns(newMap)
                                            }}
                                            groupBy={(option) => {
                                              // if (option.required && !mappedColumns[option.name])
                                              //   return 'Remaining Required Fields'
                                              return option.group
                                            }}
                                            getOptionLabel={(
                                              option,
                                            ) => option.label}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder="Leave Blank"
                                                variant="outlined"
                                                fullWidth
                                              />
                                            )}
                                          />
                                        </Container>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                                {/* <tr>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <Button className="btn-pill d-40 btn-icon btn-animated-icon p-0 mr-3" style={{ backgroundColor: '#1cc943', color: 'white' }}>
                                          <span className="btn-wrapper--icon d-flex">
                                            <FontAwesomeIcon
                                              icon={['far', 'check']}
                                              className="font-size-lg"
                                            />
                                          </span>
                                        </Button>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="align-box-row">Lot #</div>
                                    </td>
                                    <td className="text-center">
                                      462
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <Container>
                                          <Autocomplete
                                            id="grouped-demo"
                                            options={options.sort(
                                              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                                            )}
                                            groupBy={(option) => option.firstLetter}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                // label="With categories"
                                                variant="outlined"
                                                fullWidth
                                              />
                                            )}
                                          />
                                        </Container>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <Button size="small" className="btn btn-pill d-40 btn-icon btn-animated-icon p-0 mr-3" style={{ backgroundColor: '#ff3f3d', color: 'white' }}>
                                          <span className="btn-wrapper--icon d-flex">
                                            <FontAwesomeIcon
                                              icon={['far', 'xmark']}
                                              className="font-size-lg"
                                            />
                                          </span>
                                        </Button>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="align-box-row">Street Address</div>
                                    </td>
                                    <td className="text-center">
                                      462
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <Container>
                                          <Autocomplete
                                            id="grouped-demo"
                                            options={options.sort(
                                              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                                            )}
                                            groupBy={(option) => option.firstLetter}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                // label="With categories"
                                                variant="outlined"
                                                fullWidth
                                              />
                                            )}
                                          />
                                        </Container>
                                      </div>
                                    </td>
                                  </tr> */}
                              </tbody>
                            </Table>
                          </div>
                          <div className="divider" />
                          <div className="divider" />
                          {/* <div className="p-3 d-flex justify-content-center">
                              <Pagination className="pagination-first" size="small" count={8} />
                            </div> */}
                        </CardContent>
                      </Card>
                    </div>
                  </Container>
                )}
                {activeStep === 2 && (
                  <Container>
                    <div className="p-4">
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            Where should we put the properties?
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            variant="outlined"
                            margin="dense"
                            style={{ width: '100%' }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Portfolio
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="selectedPortfolio"
                              value={selectedPortfolio}
                              onChange={(event) =>
                                setSelectedPortfolio(
                                  event.target.value,
                                )
                              }
                              open={openPortfolio}
                              onOpen={handleOpenPortfolio}
                              onClose={handleClosePortfolio}
                              MenuProps={{ keepMounted: true }}
                              fullWidth={true}
                              label="Portfolio"
                              disabled={
                                props?.session?.me?.activeOrg
                                  ?.member === 'RWN'
                              }
                            >
                              {props?.session?.me?.activeOrg
                                ?.member === 'RWN' && (
                                <MenuItem
                                  key="My Properties"
                                  value={
                                    portfolios
                                      ? portfolios[0]._id
                                      : null
                                  }
                                >
                                  My Properties
                                </MenuItem>
                              )}
                              {props?.session?.me?.activeOrg
                                ?.member !== 'RWN' &&
                                map(portfolios, (option) => (
                                  <MenuItem
                                    key={option.name}
                                    value={option._id}
                                  >
                                    {option.name}
                                  </MenuItem>
                                ))}
                              {props?.session?.me?.activeOrg
                                ?.member !== 'RWN' && (
                                <CreatePortfolio
                                  dropdown={true}
                                  setNewPortfolio={
                                    setSelectedPortfolio
                                  }
                                  closeMenu={handleClosePortfolio}
                                />
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={noDup}
                                  checked={noDup}
                                  onChange={() => setNoDup(!noDup)}
                                />
                              }
                              label="Don't import properties that already exist in my account"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </div>
                  </Container>
                )}
              </div>
              <div className="card-footer mt-4 p-4 d-flex align-items-center justify-content-between bg-secondary">
                {activeStep !== 0 && (
                  <Button
                    /*disabled={activeStep === 0}*/ className="btn-primary font-weight-bold"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                )}
                {activeStep === 0 && (
                  <Button
                    /*disabled={activeStep === 0}*/ className="btn-primary font-weight-bold"
                    onClick={props.handleCloseUploadProperties}
                  >
                    Cancel
                  </Button>
                  // <CancelButton stepper={true} />
                )}
                <Typography style={{ flex: 1 }} />
                <ApolloConsumer>
                  {(client) => (
                    <Button
                      className="btn-primary font-weight-bold"
                      disabled={
                        (propertyCountLimit > 0 && !toTheLimit) ||
                        propertyCountLimit === 0 ||
                        (activeStep === 0 && files.length <= 0) ||
                        noRows ||
                        (activeStep === 1 &&
                          !(
                            (mappedColumns['address'] ||
                              (mappedColumns['streetNumber'] &&
                                mappedColumns['streetName'])) &&
                            mappedColumns['city'] &&
                            mappedColumns['zip'] &&
                            mappedColumns['beds'] &&
                            mappedColumns['baths'] &&
                            mappedColumns['sqft']
                          ))
                      }
                      onClick={
                        activeStep === steps.length - 1
                          ? onSubmit(client)
                          : handleNext
                      }
                    >
                      {activeStep === steps.length - 1
                        ? 'Finish'
                        : 'Next'}
                    </Button>
                  )}
                </ApolloConsumer>
              </div>
            </span>
            // </ValidatorForm>
          )}
        </div>
      </Card>
      {/* </DialogContent>
      </Dialog> */}
    </BlockUi>
  )
}

export default withSession(UploadPropertiesStepperComponent)
