/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-30 16:41:43
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-28 15:45:03
 * @ Description: The filtering options for all pages with properties listed
 */

import React, { Component, Fragment } from 'react'
import clsx from 'clsx'

import Autocomplete from '@mui/material/Autocomplete'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import flatmap from 'lodash/flatMap'
import toLower from 'lodash/toLower'
import difference from 'lodash/difference'
import get from 'lodash/get'
import remove from 'lodash/remove'
import pullAt from 'lodash/pullAt'
import isArray from 'lodash/isArray'
import includes from 'lodash/includes'
import sortedUniqBy from 'lodash/sortedUniqBy'
import startCase from 'lodash/startCase'
import floor from 'lodash/floor'
import slice from 'lodash/slice'
import merge from 'lodash/merge'
import unset from 'lodash/unset'
import forEach from 'lodash/forEach'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import sum from 'lodash/sum'
import trim from 'lodash/trim'

import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Container,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  InputLabel,
} from '@mui/material'
import withSession from '@/housefolios-components/Session/withSession'
import { Mutation } from '@apollo/client/react/components'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { Close, FilterList } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { INITIAL_FILTERS } from './defaultValues'
import { debounce } from 'lodash'
import LZMA from 'lzma-web'
import {
  getStrategyName,
  replaceStrategyName,
} from '@/utils/stratagies'
const my_lzma = new LZMA()

class PropertiesFilterComponent extends Component {
  constructor(props) {
    super(props)
    this.toggleAccordion = this.toggleAccordion.bind(this)
    this.state = {
      accordion: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      currentFilters: cloneDeep(INITIAL_FILTERS),
      activeTab: '',
      activeTab1: '',
      saveFilter: false,
      show: false,
      show1: false,
      open: false,
      name: '',
      activeName: '',
      filters: props.activeOrg.filters || [],
      hasChanged: false,
      searchStatus: false,
    }
  }

  componentDidMount() {
    let {
      LocationFilters,
      StrategyFilters,
      FavoriteFilters,
      OwnedFilters,
      PropertyFilters,
      MoreDetailFilters,
      StageFilters,
      BuyHoldFinancialFilters,
      FixFlipFinancialFilters,
      TurnkeyFinancialFilters,
      BRRRRFinancialFilters,
      STRFinancialFilters,
      buildToRentFinancialFilters,
      WholesaleFilters,
      BORFinancialFilters,
      AstroFlipFilters,
      AffiliateFilters,
    } = this.state.currentFilters
    if (this.props.propertySearch) {
      pullAt(LocationFilters, [2])
      //StrategyFilters = []
      pullAt(PropertyFilters, [1, 2, 3, 4, 10, 11, 12, 13])
      // StageFilters = []
      // FinancialFilters = []
    } else if (this.props.lot) {
      //pullAt(LocationFilters, [2])
      //StrategyFilters = []
      pullAt(PropertyFilters, [3, 4, 9, 10, 11, 13])
      // StageFilters = []
      // FinancialFilters = []
    } else if (this.props.comps) {
      pullAt(LocationFilters, [2, 3])
      //StrategyFilters = []
      pullAt(PropertyFilters, [10, 13])
      // StageFilters = []
      // FinancialFilters = []
    } else if (this.props.public) {
      //pullAt(LocationFilters, [])
      //StrategyFilters = []
      pullAt(PropertyFilters, [3, 4, 10, 11, 12])
      // StageFilters = []
      // FinancialFilters = []
    } else {
      //pullAt(LocationFilters, [])
      //StrategyFilters = []
      pullAt(PropertyFilters, [3, 4, 11])
      // StageFilters = []
      // FinancialFilters = []
    }
    this.setState({
      currentFilters: {
        LocationFilters,
        StrategyFilters,
        FavoriteFilters,
        OwnedFilters,
        PropertyFilters,
        MoreDetailFilters,
        StageFilters,
        BuyHoldFinancialFilters,
        FixFlipFinancialFilters,
        TurnkeyFinancialFilters,
        BRRRRFinancialFilters,
        STRFinancialFilters,
        buildToRentFinancialFilters,
        WholesaleFilters,
        BORFinancialFilters,
        AstroFlipFilters,
        AffiliateFilters,
      },
    })
    this.handleFilters()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(this.props.properties, prevProps.properties) ||
      !this.isFiltersEqual(prevState) ||
      prevProps.search !== this.props.search
    )
      this.handleFilters()
  }

  isFiltersEqual = (prevState) => {
    const { currentFilters } = this.state

    return isEqual(currentFilters, prevState.currentFilters)
  }

  reset = () => {
    let {
      LocationFilters,
      StrategyFilters,
      FavoriteFilters,
      OwnedFilters,
      PropertyFilters,
      MoreDetailFilters,
      StageFilters,
      BuyHoldFinancialFilters,
      FixFlipFinancialFilters,
      TurnkeyFinancialFilters,
      BRRRRFinancialFilters,
      STRFinancialFilters,
      buildToRentFinancialFilters,
      WholesaleFilters,
      BORFinancialFilters,
      AstroFlipFilters,
      AffiliateFilters,
    } = cloneDeep(INITIAL_FILTERS)
    if (this.props.propertySearch) {
      pullAt(LocationFilters, [2])
      //StrategyFilters = []
      pullAt(PropertyFilters, [1, 2, 3, 4, 10, 11, 12, 13])
      // StageFilters = []
      // FinancialFilters = []
    } else if (this.props.lot) {
      //pullAt(LocationFilters, [2])
      //StrategyFilters = []
      pullAt(PropertyFilters, [3, 4, 9, 10, 11, 13])
      // StageFilters = []
      // FinancialFilters = []
    } else if (this.props.comps) {
      pullAt(LocationFilters, [2, 3])
      //StrategyFilters = []
      pullAt(PropertyFilters, [9, 10, 13])
      // StageFilters = []
      // FinancialFilters = []
    } else if (this.props.public) {
      //pullAt(LocationFilters, [])
      //StrategyFilters = []
      pullAt(PropertyFilters, [3, 4, 10, 11, 12])
      // StageFilters = []
      // FinancialFilters = []
    } else {
      //pullAt(LocationFilters, [])
      //StrategyFilters = []
      pullAt(PropertyFilters, [3, 4, 11])
      // StageFilters = []
      // FinancialFilters = []
    }
    this.setState({
      currentFilters: {
        LocationFilters,
        StrategyFilters,
        FavoriteFilters,
        OwnedFilters,
        PropertyFilters,
        MoreDetailFilters,
        StageFilters,
        BuyHoldFinancialFilters,
        FixFlipFinancialFilters,
        TurnkeyFinancialFilters,
        BRRRRFinancialFilters,
        STRFinancialFilters,
        buildToRentFinancialFilters,
        WholesaleFilters,
        BORFinancialFilters,
        AstroFlipFilters,
        AffiliateFilters,
      },
      activeName: '',
      hasChanged: false,
    })
    if (this.props.propertySearch) this.props.getSearchResults('')
  }

  toggleSearch = () => {
    this.setState({ searchStatus: !this.state.searchStatus })
  }

  handleFilterClick(key) {
    let newFilters = cloneDeep(this.state.currentFilters)

    forEach(newFilters, (filter) => {
      for (let i = 0; i < filter.length; i++) {
        if (filter[i].Header === key)
          filter[i].active = !filter[i].active
      }
    })

    this.setState({
      currentFilters: newFilters,
      hasChanged: true,
    })
    //this.handleFilters()
  }

  handleChangeChip = (name, value) => {
    let newFilters = cloneDeep(this.state.currentFilters)

    forEach(newFilters, (filter) => {
      for (let i = 0; i < filter.length; i++) {
        if (filter[i].Header === name) {
          filter[i].value = value
          filter[i].active = !isEmpty(value)
        }
      }
    })

    this.setState({
      currentFilters: newFilters,
      hasChanged: true,
    })
    //this.handleFilters()
  }

  handleChangeDropdown = (event) => {
    let { name, value } = event.target
    let newFilters = cloneDeep(this.state.currentFilters)

    forEach(newFilters, (filter) => {
      for (let i = 0; i < filter.length; i++) {
        if (filter[i].Header === name) {
          filter[i].value = value
          filter[i].active = !isNil(value)
        }
      }
    })

    this.setState({
      currentFilters: newFilters,
      hasChanged: true,
    })
    //this.handleFilters()
  }

  handleChangeString = (key) => {
    let newFilters = cloneDeep(this.state.currentFilters)

    forEach(newFilters, (filter) => {
      for (let i = 0; i < filter.length; i++) {
        if (filter[i].Header === key)
          filter[i].active = !filter[i].active
      }
    })

    this.setState({
      currentFilters: newFilters,
      hasChanged: true,
    })
    //this.handleFilters()
  }

  handleChangeMin = (event, max) => {
    let { name, value } = event.target
    if (Number(value) > Number(max) && max && value) value = max
    if (!value && name !== 'Beds' && name !== 'Baths') value = null
    let newFilters = cloneDeep(this.state.currentFilters)

    forEach(newFilters, (filter) => {
      for (let i = 0; i < filter.length; i++) {
        if (filter[i].Header === name) {
          filter[i].min = value
          filter[i].active = !isNil(value) || !isNil(filter[i].max)
        }
      }
    })

    this.setState({
      currentFilters: newFilters,
      hasChanged: true,
    })
    //this.handleFilters()
  }

  // Debounce the handleChangeMin method
  debouncedHandleChangeMin = debounce(this.handleChangeMin, 600) // Adjust 500ms to your needs

  handleChangeMax = (event, min) => {
    let { name, value } = event.target
    if (Number(value) < Number(min) && min && value) value = min
    if (!value) value = null
    let newFilters = cloneDeep(this.state.currentFilters)

    forEach(newFilters, (filter) => {
      for (let i = 0; i < filter.length; i++) {
        if (filter[i].Header === name) {
          filter[i].max = value
          filter[i].active = !isNil(value) || !isNil(filter[i].min)
        }
      }
    })

    this.setState({
      currentFilters: newFilters,
      hasChanged: true,
    })
    //this.handleFilters()
  }

  // Debounce the handleChangeMin method
  debouncedHandleChangeMax = debounce(this.handleChangeMax, 1200) // Adjust 500ms to your needs

  filterStringChips = (filtered, filters) => {
    const { marketplace, propertySearch, comps, lot } = this.props
    let anti = filtered
    let diff = filtered
    let check = true
    map(filters, (filter) => {
      if (filter.active && !isEmpty(filter.value)) {
        anti = filtered
        map(filter.value, (value) => {
          check = false
          let target =
            lot && filter.lotTarget
              ? filter.lotTarget
              : comps && filter.compsTarget
                ? filter.compsTarget
                : propertySearch && filter.propertySearchTarget
                  ? filter.propertySearchTarget
                  : marketplace && filter.marketplaceTarget
                    ? filter.marketplaceTarget
                    : filter.target
          anti = anti.filter(
            (property) =>
              isArray(get(property, target))
                ? !includes(trim(get(property, target)), trim(value))
                : trim(toLower(get(property, target))) !==
                  trim(toLower(value)), //arrays are case sensitive
          )
        })
        diff = difference(diff, anti)
      }
    })
    if (check) return filtered
    return diff
  }

  filterDropdowns = (filtered, filters) => {
    const { marketplace, propertySearch, comps, lot } = this.props
    map(filters, (filter) => {
      if (filter.active) {
        filtered = filtered.filter((property) => {
          return (
            get(
              property,
              lot && filter.lotTarget
                ? filter.lotTarget
                : comps && filter.compsTarget
                  ? filter.compsTarget
                  : propertySearch && filter.propertySearchTarget
                    ? filter.propertySearchTarget
                    : marketplace && filter.marketplaceTarget
                      ? filter.marketplaceTarget
                      : filter.target,
            ) === filter.value &&
            get(
              property,
              lot && filter.lotTarget
                ? filter.lotTarget
                : comps && filter.compsTarget
                  ? filter.compsTarget
                  : propertySearch && filter.propertySearchTarget
                    ? filter.propertySearchTarget
                    : marketplace && filter.marketplaceTarget
                      ? filter.marketplaceTarget
                      : filter.target,
            ) != null
          )
        })
      }
    })
    return filtered
  }

  filterMinMax = (filtered, filters) => {
    const { marketplace, propertySearch, comps, lot } = this.props
    map(filters, (filter) => {
      if (filter.active) {
        //if filter has a parseFunction or is type date than parse then with that. Else parseFloat
        const parse =
          filter.parseFunction && !comps
            ? filter.parseFunction
            : filter.type === 'date'
              ? (date) => new Date(date).getTime()
              : parseFloat
        //parse min/max based on if its a date or not
        const parseMinMax =
          filter.type === 'date'
            ? (date) => new Date(date).getTime()
            : parseFloat
        if (filter.min)
          filtered = filtered.filter((property) => {
            return (
              parse(
                get(
                  property,
                  lot && filter.lotTarget
                    ? filter.lotTarget
                    : comps && filter.compsTarget
                      ? filter.compsTarget
                      : propertySearch && filter.propertySearchTarget
                        ? filter.propertySearchTarget
                        : marketplace && filter.marketplaceTarget
                          ? filter.marketplaceTarget
                          : filter.target,
                ),
              ) >=
                parseMinMax(filter.min) / (filter.divisor || 1) &&
              filter.min &&
              get(
                property,
                lot && filter.lotTarget
                  ? filter.lotTarget
                  : comps && filter.compsTarget
                    ? filter.compsTarget
                    : propertySearch && filter.propertySearchTarget
                      ? filter.propertySearchTarget
                      : marketplace && filter.marketplaceTarget
                        ? filter.marketplaceTarget
                        : filter.target,
              ) != null
            )
          })
        if (filter.max)
          filtered = filtered.filter((property) => {
            return (
              parse(
                get(
                  property,
                  lot && filter.lotTarget
                    ? filter.lotTarget
                    : comps && filter.compsTarget
                      ? filter.compsTarget
                      : propertySearch && filter.propertySearchTarget
                        ? filter.propertySearchTarget
                        : marketplace && filter.marketplaceTarget
                          ? filter.marketplaceTarget
                          : filter.target,
                ),
              ) <=
                parseMinMax(filter.max) / (filter.divisor || 1) &&
              filter.max &&
              get(
                property,
                lot && filter.lotTarget
                  ? filter.lotTarget
                  : comps && filter.compsTarget
                    ? filter.compsTarget
                    : propertySearch && filter.propertySearchTarget
                      ? filter.propertySearchTarget
                      : marketplace && filter.marketplaceTarget
                        ? filter.marketplaceTarget
                        : filter.target,
              ) != null
            )
          })
      }
    })
    return filtered
  }

  filterOut = (filtered, filters) => {
    const { marketplace, propertySearch, comps, lot } = this.props
    let anti = filtered
    let check = true
    map(filters, (filter) => {
      if (filter.active) {
        check = false
        //if no value only check if it doesn't exists
        if (filter.value)
          anti = anti.filter(
            (property) =>
              property[
                lot && filter.lotTarget
                  ? filter.lotTarget
                  : comps && filter.compsTarget
                    ? filter.compsTarget
                    : propertySearch && filter.propertySearchTarget
                      ? filter.propertySearchTarget
                      : marketplace && filter.marketplaceTarget
                        ? filter.marketplaceTarget
                        : filter.target
              ] !== filter.value,
          )
        else
          anti = anti.filter(
            (property) =>
              !property[
                lot && filter.lotTarget
                  ? filter.lotTarget
                  : comps && filter.compsTarget
                    ? filter.compsTarget
                    : propertySearch && filter.propertySearchTarget
                      ? filter.propertySearchTarget
                      : marketplace && filter.marketplaceTarget
                        ? filter.marketplaceTarget
                        : filter.target
              ],
          )
      }
    })
    if (check) return filtered
    return difference(filtered, anti)
  }

  filterOutStage = (filtered, filters) => {
    let anti = filtered
    let check = true
    map(filters, (filter) => {
      if (filter.active) {
        check = false
        if (filter.value !== 'new') {
          if (filter.value === 'needsInfo') {
            anti = anti.filter((property) =>
              this.props.lot
                ? property.status
                : property.acquisition.analysis.listPrice > 0 &&
                  (property.acquisition.analysis.afterRepairValue >
                    0 ||
                    property.acquisition.analysis
                      .afterRepairValueBuyHold > 0 ||
                    analysis.afterRepairValueFixFlip > 0 ||
                    property.acquisition.analysis
                      .afterRepairValueTurnkey > 0 ||
                    property.acquisition.analysis
                      .afterRepairValueSTR > 0 ||
                    property.acquisition.analysis
                      .afterRepairValueB2R > 0 ||
                    property.acquisition.analysis
                      .afterRepairValueBRRRR > 0 ||
                    property.acquisition.analysis
                      .afterRepairValueBOR > 0 ||
                    property.acquisition.analysis
                      .afterRepairValueWholesale > 0) &&
                  property.acquisition.analysis.rent > 0 &&
                  property.acquisition.analysis.resellPrice > 0 &&
                  property.acquisition.analysis.offerPrice > 0,
            )
          } else {
            anti = anti.filter((property) =>
              this.props.lot
                ? property.status !== filter.value
                : !property.acquisition ||
                  property.acquisition.activeStage !== filter.value,
            )
          }
        } else {
          anti = anti.filter(
            (property) =>
              this.props.lot
                ? property.status
                : property.acquisition &&
                  property.acquisition.activeStage,
            // !(
            //   property.acquisition.analysis.listPrice > 0 &&
            //   property.acquisition.analysis.afterRepairValue > 0 &&
            //   property.acquisition.analysis.rent > 0 &&
            //   property.acquisition.analysis.resellPrice > 0 &&
            //   property.acquisition.analysis.offerPrice > 0
            // ),
          )
        }
      }
    })
    if (check) return filtered
    return difference(filtered, anti)
  }

  handleFilters = () => {
    const {
      properties,
      getFilteredProperties,
      setFilterReset,
      propertySearch,
      setIsSavedFilter,
    } = this.props
    const { currentFilters, activeName } = this.state
    let filtered = properties
    let filters = { ...currentFilters }
    const { StageFilters } = filters
    unset(filters, 'StageFilters')
    let activeFilter = false
    if (isEmpty(this.props.activeOrg.affiliate))
      unset(filters, 'AffiliateFilters')
    forEach(filters, (filters, key) => {
      filtered = this.filterStringChips(
        filtered,
        filter(filters, ['filterType', 'chip']),
      )
      filtered = this.filterDropdowns(
        filtered,
        filter(filters, ['filterType', 'dropdown']),
      )
      filtered = this.filterOut(
        filtered,
        filter(filters, ['filterType', 'checkbox']),
      )
      // filtered = this.filterOut(filtered, filter(filters, ["filterType", "string"]))
      filtered = this.filterMinMax(
        filtered,
        filter(filters, ['filterType', 'minmax']),
      )
      if (!every(filters, { active: false })) activeFilter = true
    })
    filtered = this.filterOutStage(filtered, StageFilters)
    if (!every(StageFilters, { active: false })) activeFilter = true
    if (propertySearch && this.props.search) activeFilter = true
    if (activeFilter) {
      if (
        this.props.propertySearch ||
        this.props.lot ||
        this.props.comps
      ) {
        setFilterReset(() => this.reset)
      } else if (activeName) {
        setFilterReset(() => this.reset)
        setIsSavedFilter(true)
      } else {
        setFilterReset(() => this.toggleModal)
        setIsSavedFilter(false)
      }
    } else setFilterReset(null)
    getFilteredProperties(filtered)
    // if (propertySearch) {
    //   my_my_lzma.compress(JSON.stringify(filtered), 1, (result, error) => {
    //     const size = new TextEncoder().encode(JSON.stringify(result)).length
    //     if (size < 4 * 1024 * 1024)
    //       localStorage.setItem('propertySearch', result)
    //     else
    //       localStorage.setItem('propertySearch', null)
    //   }, percent => { return })
    // }
  }

  renderMinMaxFilters = (data) => {
    if (data.Header === 'Beds' || data.Header === 'Baths')
      return (
        <>
          <Grid
            item
            key={data.Header}
            xs={12}
            style={{ marginTop: 15 }}
          >
            <Typography style={{ textAlign: 'left' }}>
              {replaceStrategyName(
                data.Header,
                this?.props?.session?.me?.activeOrg?.member,
              )}
            </Typography>
          </Grid>
          <Grid item key={`${data.Header} field`} xs={12}>
            <ButtonGroup>
              {map(data.options, (option) => (
                <Button
                  key={option}
                  style={{
                    color: data.min === option ? 'white' : 'black',
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                  }}
                  onClick={(event) =>
                    this.handleChangeMin(
                      {
                        target: {
                          name: data.Header,
                          value: data.min === option ? null : option,
                        },
                      },
                      data.max,
                    )
                  }
                  className={clsx(
                    { 'btn-primary': data.min === option },
                    { active: data.min === option },
                  )}
                >
                  {option}+
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
        </>
      )
    else if (data.type === 'date')
      return (
        <>
          <Grid item key={data.Header} xs={12}>
            <Typography style={{ textAlign: 'left' }}>
              {replaceStrategyName(
                data.Header,
                this?.props?.session?.me?.activeOrg?.member,
              )}
            </Typography>
          </Grid>
          <Grid item key={`${data.Header} field`} xs={12}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item key={`${data.Header} first`} xs={5}>
                <FormControl fullWidth variant="outlined">
                  {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}

                  <DatePicker
                    name={data.Header}
                    disableFuture={
                      data.Header === 'Yr Built' ? true : false
                    }
                    value={data.min}
                    onChange={(date) =>
                      this.debouncedHandleChangeMin(
                        {
                          target: {
                            name: data.Header,
                            value: date,
                            type: 'date',
                          },
                        },
                        data.max,
                      )
                    }
                    format="MM/dd/yyyy"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        style={{ borderColor: 'grey' }}
                        placeholder={replaceStrategyName(
                          data.Header,
                          this?.props?.session?.me?.activeOrg?.member,
                        )}
                      />
                    )}
                    views={
                      data?.dateViews
                        ? data.dateViews
                        : ['year', 'month', 'day']
                    }
                    openTo={data?.view ? data?.view : 'month'}
                  />
                </FormControl>
              </Grid>
              <Grid item key={`${data.Header} to`} xs={1}>
                <Typography>to</Typography>
              </Grid>
              <Grid item key={`${data.Header} last`} xs={5}>
                <FormControl fullWidth variant="outlined">
                  {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}

                  <DatePicker
                    name={data.Header}
                    disableFuture={
                      data.Header === 'Yr Built' ? true : false
                    }
                    value={data.max}
                    onChange={(date) =>
                      this.debouncedHandleChangeMax(
                        {
                          target: {
                            name: data.Header,
                            value: date,
                            type: 'date',
                          },
                        },
                        data.min,
                      )
                    }
                    format="MM/dd/yyyy"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        style={{ borderColor: 'grey' }}
                        placeholder={replaceStrategyName(
                          data.Header,
                          this?.props?.session?.me?.activeOrg?.member,
                        )}
                      />
                    )}
                    views={
                      data?.dateViews
                        ? data.dateViews
                        : ['year', 'month', 'day']
                    }
                    openTo={data?.view ? data?.view : 'month'}
                    shouldDisableYear={
                      data.Header === 'Yr Built'
                        ? (year) => year?.getFullYear() < data?.min
                        : (year) => false
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </>
      )
    return (
      <>
        <Grid item key={data.Header} xs={12}>
          <Typography style={{ textAlign: 'left' }}>
            {replaceStrategyName(
              data.Header,
              this?.props?.session?.me?.activeOrg?.member,
            )}
          </Typography>
        </Grid>
        <Grid item key={`${data.Header} field`} xs={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid item key={`${data.Header} first`} xs={5}>
              <FormControl fullWidth variant="outlined">
                {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}
                <Select
                  //labelId="demo-simple-select-outlined-label"
                  name={data.Header}
                  id="demo-simple-select-outlined"
                  value={data.min || ''}
                  onChange={(event) =>
                    this.handleChangeMin(event, data.max)
                  }
                  margin="dense"
                  displayEmpty
                >
                  <MenuItem key="blank" value={''}>
                    <em>No Min</em>
                  </MenuItem>
                  {map(
                    filter(data.options, (option) => {
                      return option <= data.max || !data.max
                    }),
                    (option) => (
                      <MenuItem key={option} value={option}>
                        {data.formatOption
                          ? data.formatOption(option)
                          : option}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item key={`${data.Header} to`} xs={1}>
              <Typography>to</Typography>
            </Grid>
            <Grid item key={`${data.Header} last`} xs={5}>
              <FormControl fullWidth variant="outlined">
                {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}
                <Select
                  //labelId="demo-simple-select-outlined-label"
                  name={data.Header}
                  id="demo-simple-select-outlined"
                  value={data.max || ''}
                  onChange={(event) =>
                    this.handleChangeMax(event, data.min)
                  }
                  margin="dense"
                  displayEmpty
                >
                  <MenuItem key="blank" value={''}>
                    <em>No Max</em>
                  </MenuItem>
                  {map(
                    filter(data.options, (option) => {
                      return option >= data.min || !data.min
                    }),
                    (option) => (
                      <MenuItem key={option} value={option}>
                        {data.formatOption
                          ? data.formatOption(option)
                          : option}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  renderStringChipFilters = (data) => {
    const { properties } = this.props
    let options = filter(
      sortedUniqBy(
        sortBy(
          flatmap(properties, (property) => {
            return isArray(get(property, data.target))
              ? map(get(property, data.target))
              : get(property, data.target)
          }),
        ),
        toLower,
      ),
      (value) => value !== 'null' && value,
    )
    if (
      data.target !== 'state' &&
      data.target !== 'zip' &&
      data.target !== 'marketplaces.approvedMarketplace' &&
      data.target !== 'createdBy.email' &&
      data.target !== 'propertyType'
    )
      options = map(options, (option) => startCase(toLower(option)))
    return (
      <Grid item key={`${data.Header} field`}>
        <Autocomplete
          multiple
          //id="fixed-tags-demo"
          options={options}
          disableCloseOnSelect
          value={data.value}
          onChange={(event, value) =>
            this.handleChangeChip(data.Header, value)
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                color="primary"
                key={index}
                label={option}
                {...getTagProps({ index })}
                //disabled={index === 0}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              margin="dense"
              label={replaceStrategyName(
                data.Header,
                this?.props?.session?.me?.activeOrg?.member,
              )}
              fullWidth
            />
          )}
        />
      </Grid>
    )
  }

  renderCheckboxFilters = (filter) => (
    <Grid
      item
      key={`${filter.Header} field`}
      xs={filter.xs}
      style={{ textAlign: 'left' }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={filter.active}
            onChange={() => this.handleFilterClick(filter.Header)}
            value={filter.Header}
          />
        }
        label={
          this.props.lot && filter.lotHeader
            ? filter.lotHeader
            : filter.target === 'strategy'
              ? getStrategyName(
                  filter.Header,
                  this?.props?.session?.me?.activeOrg?.member,
                )
              : filter.Header
        }
      />
    </Grid>
  )

  renderDropdownFilters = (data) => {
    return (
      <Grid item key={`${data.Header} field`}>
        <TextField
          select
          name={data.Header}
          value={data.value}
          onChange={(event) => this.handleChangeDropdown(event)}
          margin="dense"
          variant="outlined"
          label={replaceStrategyName(
            data.Header,
            this?.props?.session?.me?.activeOrg?.member,
          )}
          fullWidth
        >
          {map(data.options, (option) => (
            <MenuItem key={option} value={option}>
              {data.formatOption ? data.formatOption(option) : option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    )
  }

  renderFilters = (filter) => {
    if (filter.filterType === 'chip')
      return this.renderStringChipFilters(filter)
    if (filter.filterType === 'dropdown')
      return this.renderDropdownFilters(filter)
    else if (filter.filterType === 'string')
      return this.renderStringFilters(filter)
    else if (filter.filterType === 'minmax')
      return this.renderMinMaxFilters(filter)
    else if (filter.filterType === 'checkbox')
      return this.renderCheckboxFilters(filter)
  }

  removeTypename = (obj) => {
    for (var prop in obj) {
      if (prop === '__typename') delete obj[prop]
      else if (typeof obj[prop] === 'object')
        this.removeTypename(obj[prop])
    }
  }

  selectFilter = (event, value) => {
    this.setState({
      ...merge(cloneDeep(this.state), value),
      name: '',
      activeName: value ? value.name : '',
    })
    if (!value) this.reset()
    //this.handleFilters()
  }

  deleteFilter = (filter, filters, updateOrganization) => {
    let newFilters = filters
    remove(newFilters, (newFilter) => {
      return newFilter.name === filter
    })
    map(newFilters, this.removeTypename)
    updateOrganization({
      variables: {
        id: this.props.activeOrg._id,
        organizationInput: { filters: newFilters },
      },
    })
      .then(() => {
        enqueueSnackbar('Filter was deleted', {
          variant: 'success',
          action: (key) => (
            <Fragment>
              <IconButton
                onClick={() => {
                  this.props.closeSnackbar(key)
                }}
                size="large"
              >
                <Close style={{ color: 'white' }} />
              </IconButton>
            </Fragment>
          ),
        })
        this.setState({ activeName: '', show1: false })
        this.reset()
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          action: (key) => (
            <Fragment>
              <IconButton
                onClick={() => {
                  this.props.closeSnackbar(key)
                }}
                size="large"
              >
                <Close style={{ color: 'white' }} />
              </IconButton>
            </Fragment>
          ),
        })
        this.setState({ activeName: '', show1: false })
        this.reset()
      })
  }

  onSubmit = (event, filters, updateOrganization) => {
    this.setState({ circle: true })
    const { name, currentFilters } = this.state
    let newFilters = filters
    let newCurrentFilters = { ...currentFilters }
    forEach(currentFilters, (filters, key) => {
      newCurrentFilters[key] = map(filters, (filter) => {
        return filter.filterType === 'minmax'
          ? {
              Header: filter.Header,
              active: filter.active,
              min: filter.min,
              max: filter.max,
            }
          : {
              Header: filter.Header,
              active: filter.active,
              value: filter.value,
            }
      })
    })
    const newFilter = {
      name,
      currentFilters: newCurrentFilters,
    }
    if (newFilter) newFilters.push(newFilter)
    map(newFilters, this.removeTypename)
    this.setState({ name: '' })
    updateOrganization({
      variables: {
        id: this.props.activeOrg._id,
        organizationInput: { filters: newFilters },
      },
    })
      .then(async ({ data }) => {
        enqueueSnackbar('Filter was saved', {
          variant: 'success',
          action: (key) => (
            <Fragment>
              <IconButton
                onClick={() => {
                  this.props.closeSnackbar(key)
                }}
                size="large"
              >
                <Close style={{ color: 'white' }} />
              </IconButton>
            </Fragment>
          ),
        })
        this.setState({
          show: false,
          name: '',
          activeName: name,
          hasChanged: false,
        })
        this.props.setIsSavedFilter(true)
      })
      .catch(async (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          action: (key) => (
            <Fragment>
              <IconButton
                onClick={() => {
                  this.props.closeSnackbar(key)
                }}
                size="large"
              >
                <Close style={{ color: 'white' }} />
              </IconButton>
            </Fragment>
          ),
        })
        this.setState({
          circle: false,
          show: false,
          name: '',
          activeName: name,
        })
      })
  }

  toggleModal = () => {
    this.setState((prevState, props) => {
      return { show: !prevState.show, marketPlace: '' }
    })
  }
  toggleModal1 = () => {
    this.setState((prevState, props) => {
      return { show1: !prevState.show1, marketPlace: '' }
    })
  }
  toggleSnackbar = () => {
    this.setState((prevState, props) => {
      return { open: !prevState.open, marketPlace: '' }
    })
  }

  toggleAccordion(tab) {
    const prevState = this.state.accordion
    const state = prevState.map((x, index) =>
      tab === index ? !x : false,
    )

    this.setState({
      accordion: state,
    })
  }

  toggleActiveTab = (highlight) => {
    this.setState({ activeTab: highlight })
  }
  toggleActiveTab1 = (highlight) => {
    this.setState({ activeTab1: highlight })
  }

  // handleChange3 = event => {
  //   const { name = '' } = event.target
  //   this.setState({ name })
  // }

  // renderInput = (field, error) => {
  //   return (
  //     <div>

  //     </div>
  //   )efe
  // }

  render() {
    const {
      show,
      show1,
      name,
      activeName,
      currentFilters: {
        LocationFilters,
        StrategyFilters,
        FavoriteFilters,
        OwnedFilters,
        PropertyFilters,
        MoreDetailFilters,
        StageFilters,
        BuyHoldFinancialFilters,
        FixFlipFinancialFilters,
        TurnkeyFinancialFilters,
        BRRRRFinancialFilters,
        STRFinancialFilters,
        buildToRentFinancialFilters,
        WholesaleFilters,
        BORFinancialFilters,
        AstroFlipFilters,
        AffiliateFilters,
      },
    } = this.state

    return (
      <>
        <div className="accordion mb-spacing-6-x2">
          <Card
            className={clsx('card-box', {
              'panel-open': this.state.accordion[0],
            })}
          >
            <div className="p-4">
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={1}
              >
                <Grid item key="Header" xs={12}>
                  <Typography variant="h6" style={{ marginLeft: 5 }}>
                    Property Filter
                  </Typography>
                </Grid>
                {!this.props.lot &&
                  !this.props.propertySearch &&
                  !this.props.comps && (
                    <Grid item key="Saved Filters" xs={12}>
                      <Autocomplete
                        id="combo-box-demo"
                        value={activeName}
                        options={this.state.filters}
                        noOptionsText="No saved filters yet"
                        getOptionLabel={(option) =>
                          option.name ? option.name : option
                        }
                        isOptionEqualToValue={(option, value) => {
                          return option.name === value
                        }}
                        onChange={this.selectFilter}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Saved Filter"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  )}
              </Grid>
            </div>
            <Divider />
            {this.props.propertySearch && (
              <div style={{ margin: 20 }}>
                <TextField
                  variant="outlined"
                  size="small"
                  classes={{ root: 'bg-white' }}
                  className="w-100"
                  value={this.props.search}
                  onChange={(event) =>
                    this.props.getSearchResults(event.target.value)
                  }
                  placeholder={
                    this.props.propertySearch
                      ? 'Search these properties'
                      : this.props.marketplace
                        ? 'Search this marketplace'
                        : 'Search this portfolio...'
                  }
                  id="input-with-icon-textfield22-3"
                  label={
                    this.props.propertySearch && this.props.searchName
                  }
                  onFocus={this.toggleSearch}
                  onBlur={this.toggleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FilterList style={{ color: '#bbbbbb' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
            <Card>
              <div className="card-header">
                <div className="panel-title">
                  <div className="accordion-toggle">
                    <Button
                      variant="text"
                      size="large"
                      className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                      onClick={() => this.toggleAccordion(0)}
                      aria-expanded={this.state.accordion[0]}
                    >
                      <span>Property Details</span>
                      <FontAwesomeIcon
                        icon={['fas', 'angle-up']}
                        className="font-size-xl accordion-icon"
                      />
                    </Button>
                  </div>
                </div>
              </div>
              <Collapse in={this.state.accordion[0]}>
                <div className="p-4">
                  <Grid container direction="column" spacing={1}>
                    {map(PropertyFilters, (filter) =>
                      this.renderFilters(filter),
                    )}
                  </Grid>
                </div>
              </Collapse>
            </Card>
          </Card>
          <Card
            className={clsx('card-box', {
              'panel-open': this.state.accordion[1],
            })}
          >
            <Card>
              <div className="card-header">
                <div className="panel-title">
                  <div className="accordion-toggle">
                    <Button
                      variant="text"
                      size="large"
                      className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                      onClick={() => this.toggleAccordion(1)}
                      aria-expanded={this.state.accordion[1]}
                    >
                      <span>Location</span>
                      <FontAwesomeIcon
                        icon={['fas', 'angle-up']}
                        className="font-size-xl accordion-icon"
                      />
                    </Button>
                  </div>
                </div>
              </div>
              <Collapse in={this.state.accordion[1]}>
                <div className="p-4">
                  <Container>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      spacing={1}
                    >
                      {map(LocationFilters, (filter) =>
                        this.renderFilters(filter),
                      )}
                    </Grid>
                  </Container>
                </div>
              </Collapse>
            </Card>
          </Card>
          {!this.props.propertySearch && !this.props.comps && (
            <Card
              className={clsx('card-box', {
                'panel-open': this.state.accordion[2],
              })}
            >
              <Card>
                <div className="card-header">
                  <div className="panel-title">
                    <div className="accordion-toggle">
                      <Button
                        variant="text"
                        size="large"
                        className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                        onClick={() => this.toggleAccordion(2)}
                        aria-expanded={this.state.accordion[2]}
                      >
                        <span>Status</span>
                        <FontAwesomeIcon
                          icon={['fas', 'angle-up']}
                          className="font-size-xl accordion-icon"
                        />
                      </Button>
                    </div>
                  </div>
                </div>
                <Collapse in={this.state.accordion[2]}>
                  <div className="p-4">
                    <FormControl
                      component="fieldset"
                      className="pr-4"
                    >
                      <FormLabel
                        component="legend"
                        style={{ textAlign: 'left' }}
                      >
                        Availability
                      </FormLabel>
                      <FormGroup>
                        <Grid container>
                          {map(
                            filter(StageFilters, [
                              'avalibility',
                              true,
                            ]),
                            (filter) => this.renderFilters(filter),
                          )}
                        </Grid>
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      style={{ marginTop: 20 }}
                      className="pr-4"
                    >
                      <FormLabel
                        component="legend"
                        style={{ textAlign: 'left' }}
                      >
                        Stage
                      </FormLabel>
                      <FormGroup>
                        <Grid container>
                          {map(
                            filter(StageFilters, [
                              'avalibility',
                              false,
                            ]),
                            (filter) => this.renderFilters(filter),
                          )}
                        </Grid>
                      </FormGroup>
                    </FormControl>
                  </div>
                </Collapse>
              </Card>
            </Card>
          )}
          {!this.props.lot &&
            !this.props.propertySearch &&
            !this.props.comps && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[3],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(3)}
                          aria-expanded={this.state.accordion[3]}
                        >
                          <span>Strategy</span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[3]}>
                    <div className="p-4">
                      <FormControl
                        component="fieldset"
                        className="pr-4"
                      >
                        <FormGroup>
                          <Grid container direction="row">
                            {map(StrategyFilters, (filter) =>
                              this.renderFilters(filter),
                            )}
                          </Grid>
                        </FormGroup>
                      </FormControl>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {!this.props.lot &&
            !this.props.propertySearch &&
            !this.props.comps &&
            !this.props.public && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[4],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(4)}
                          aria-expanded={this.state.accordion[4]}
                        >
                          <span>Favorite</span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[4]}>
                    <div className="p-4">
                      <FormControl
                        component="fieldset"
                        className="pr-4"
                      >
                        <FormGroup>
                          <Grid container direction="row">
                            {map(FavoriteFilters, (filter) =>
                              this.renderFilters(filter),
                            )}
                          </Grid>
                        </FormGroup>
                      </FormControl>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {!this.props.propertySearch &&
            !this.props.comps &&
            !this.props.public && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[5],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(5)}
                          aria-expanded={this.state.accordion[5]}
                        >
                          <span>Owned</span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[5]}>
                    <div className="p-4">
                      <FormControl
                        component="fieldset"
                        className="pr-4"
                      >
                        <FormGroup>
                          <Grid container>
                            {map(OwnedFilters, (filter) =>
                              this.renderFilters(filter),
                            )}
                          </Grid>
                        </FormGroup>
                      </FormControl>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {!this.props.lot &&
            !this.props.propertySearch &&
            !this.props.comps && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[6],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(6)}
                          aria-expanded={this.state.accordion[6]}
                        >
                          <span>
                            {getStrategyName(
                              'Buy & Hold',
                              this?.props?.session?.me?.activeOrg
                                ?.member,
                            )}{' '}
                            Financials
                          </span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[6]}>
                    <div className="p-4">
                      <Grid container direction="column" spacing={1}>
                        {map(BuyHoldFinancialFilters, (filter) =>
                          this.renderFilters(filter),
                        )}
                      </Grid>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {!this.props.lot &&
            !this.props.propertySearch &&
            !this.props.comps && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[7],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(7)}
                          aria-expanded={this.state.accordion[7]}
                        >
                          <span>
                            {getStrategyName(
                              'Fix & Flip',
                              this?.props?.session?.me?.activeOrg
                                ?.member,
                            )}{' '}
                            Financials
                          </span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[7]}>
                    <div className="p-4">
                      <Grid container direction="column" spacing={1}>
                        {map(FixFlipFinancialFilters, (filter) =>
                          this.renderFilters(filter),
                        )}
                      </Grid>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {!this.props.lot &&
            !this.props.propertySearch &&
            !this.props.comps && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[8],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(8)}
                          aria-expanded={this.state.accordion[8]}
                        >
                          <span>
                            {getStrategyName(
                              'Turnkey',
                              this?.props?.session?.me?.activeOrg
                                ?.member,
                            )}{' '}
                            Financials
                          </span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[8]}>
                    <div className="p-4">
                      <Grid container direction="column" spacing={1}>
                        {/* <Grid item xs={12}>
                      <Typography variant='h6' style={{ marginBottom: 10, color: '#04a3e2' }}>Turnkey Operators</Typography>
                    </Grid>
                    {map(slice(TurnkeyFinancialFilters, 4), filter => (
                      this.renderFilters(filter)
                    ))}
                  <Divider style={{ marginTop: 25, marginBottom: 20 }} /> */}
                        <Grid item key="Header" xs={12}>
                          <Typography
                            variant="h6"
                            style={{
                              marginBottom: 10,
                              color: '#04a3e2',
                            }}
                          >
                            End Buyer
                          </Typography>
                        </Grid>
                        {map(
                          slice(
                            TurnkeyFinancialFilters,
                            4,
                            TurnkeyFinancialFilters.length,
                          ),
                          (filter) => this.renderFilters(filter),
                        )}
                      </Grid>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {!this.props.lot &&
            !this.props.propertySearch &&
            !this.props.comps && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[9],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(9)}
                          aria-expanded={this.state.accordion[9]}
                        >
                          <span>
                            {getStrategyName(
                              'BRRRR',
                              this?.props?.session?.me?.activeOrg
                                ?.member,
                            )}{' '}
                            Financials
                          </span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[9]}>
                    <div className="p-4">
                      <Grid container direction="column" spacing={1}>
                        {map(BRRRRFinancialFilters, (filter) =>
                          this.renderFilters(filter),
                        )}
                      </Grid>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {!this.props.lot &&
            !this.props.propertySearch &&
            !this.props.comps && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[10],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(10)}
                          aria-expanded={this.state.accordion[10]}
                        >
                          <span>
                            {getStrategyName(
                              'Short Term Rental',
                              this?.props?.session?.me?.activeOrg
                                ?.member,
                            )}{' '}
                            Financials
                          </span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[10]}>
                    <div className="p-4">
                      <Grid container direction="column" spacing={1}>
                        {map(STRFinancialFilters, (filter) =>
                          this.renderFilters(filter),
                        )}
                      </Grid>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {!this.props.lot &&
            !this.props.propertySearch &&
            !this.props.comps && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[11],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(11)}
                          aria-expanded={this.state.accordion[11]}
                        >
                          <span>
                            {getStrategyName(
                              'Build to Rent',
                              this?.props?.session?.me?.activeOrg
                                ?.member,
                            )}{' '}
                            Financials
                          </span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[11]}>
                    <div className="p-4">
                      <Grid container direction="column" spacing={1}>
                        {map(buildToRentFinancialFilters, (filter) =>
                          this.renderFilters(filter),
                        )}
                      </Grid>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {!this.props.lot &&
            !this.props.propertySearch &&
            !this.props.comps &&
            this?.props?.session?.me?.activeOrg?.member !==
              'astroflip' && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[12],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(12)}
                          aria-expanded={this.state.accordion[12]}
                        >
                          <span>
                            {getStrategyName(
                              'Wholesale',
                              this?.props?.session?.me?.activeOrg
                                ?.member,
                            )}{' '}
                            Financials
                          </span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[12]}>
                    <div className="p-4">
                      <Grid container direction="column" spacing={1}>
                        {map(WholesaleFilters, (filter) =>
                          this.renderFilters(filter),
                        )}
                      </Grid>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {!this.props.lot &&
            !this.props.propertySearch &&
            !this.props.comps &&
            this?.props?.session?.me?.activeOrg?.member ===
              'flippingamerica' && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[13],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(13)}
                          aria-expanded={this.state.accordion[13]}
                        >
                          <span>
                            {getStrategyName(
                              'BOR',
                              this?.props?.session?.me?.activeOrg
                                ?.member,
                            )}{' '}
                            Financials
                          </span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[13]}>
                    <div className="p-4">
                      <Grid container direction="column" spacing={1}>
                        {map(BORFinancialFilters, (filter) =>
                          this.renderFilters(filter),
                        )}
                      </Grid>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {!this.props.lot &&
            !this.props.propertySearch &&
            !this.props.comps &&
            this?.props?.session?.me?.activeOrg?.member ===
              'astroflip' && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[14],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(14)}
                          aria-expanded={this.state.accordion[14]}
                        >
                          <span>
                            {getStrategyName(
                              'Astroflip',
                              this?.props?.session?.me?.activeOrg
                                ?.member,
                            )}{' '}
                            Financials
                          </span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[14]}>
                    <div className="p-4">
                      <Grid container direction="column" spacing={1}>
                        {map(AstroFlipFilters, (filter) =>
                          this.renderFilters(filter),
                        )}
                      </Grid>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {!this.props.lot &&
            !this.props.propertySearch &&
            !this.props.comps &&
            !this.props.marketplace &&
            !isEmpty(this.props.activeOrg.affiliate) && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[15],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(15)}
                          aria-expanded={this.state.accordion[15]}
                        >
                          <span>Affiliate</span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[15]}>
                    <div className="p-4">
                      <Container>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          spacing={1}
                        >
                          {map(AffiliateFilters, (filter) =>
                            this.renderFilters(filter),
                          )}
                        </Grid>
                      </Container>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          {/* I BELIEVE THIS IS UNUSED CODE NOW SINCE WE USE REALEFLOW */}
          {this.props.propertySearch &&
            this.props.session.me.activeOrg.isRealeflow && (
              <Card
                className={clsx('card-box', {
                  'panel-open': this.state.accordion[16],
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => this.toggleAccordion(16)}
                          aria-expanded={this.state.accordion[16]}
                        >
                          <span>More Details</span>
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={this.state.accordion[16]}>
                    <div className="p-4">
                      <Container>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          spacing={2}
                        >
                          {map(MoreDetailFilters, (filter) =>
                            this.renderFilters(filter),
                          )}
                        </Grid>
                      </Container>
                    </div>
                  </Collapse>
                </Card>
              </Card>
            )}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            style={{ padding: 20 }}
          >
            {!this.props.lot &&
              !this.props.propertySearch &&
              !this.props.comps && (
                <Grid item key="save" xs={12}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    fullWidth={true}
                    onClick={this.toggleModal}
                    disabled={!this.state.hasChanged}
                  >
                    <FontAwesomeIcon
                      icon={['far', 'floppy-disk']}
                      className="font-size-lg"
                      style={{ marginRight: 10 }}
                    />
                    <Typography>Save Filter</Typography>
                  </Button>
                </Grid>
              )}
            <Grid item key="clear" xs={12}>
              <Button
                variant="contained"
                size="small"
                onClick={this.reset}
                fullWidth={true}
              >
                <FontAwesomeIcon
                  icon={['fas', 'xmark']}
                  className="font-size-lg"
                  style={{ marginRight: 10 }}
                />
                <Typography>Clear Filters</Typography>
              </Button>
            </Grid>
            <Grid item key="see" xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={this.props.toggleDrawer}
                fullWidth={true}
              >
                {/* <FontAwesomeIcon
                icon={['fas', 'xmark']}
                className="font-size-lg"
                style={{ marginRight: 10 }}
              /> */}
                <Typography>See Filtered Properties</Typography>
              </Button>
            </Grid>
            {activeName &&
              !this.props.lot &&
              !this.props.propertySearch &&
              !this.props.comps && (
                <Grid item key="delete" xs={12}>
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    onClick={this.toggleModal1}
                    disabled={!activeName}
                  >
                    <Typography>Delete Saved Filter</Typography>
                  </Button>
                </Grid>
              )}
          </Grid>

          {/* Save Filter Dialog */}
          <Mutation
            mutation={UPDATE_ORGANIZATION}
            refetchQueries={[{ query: GET_ME }]}
          >
            {(updateOrganization, { data, loading, error }) => (
              <Dialog
                open={show}
                onClose={this.toggleModal}
                classes={{ paper: 'shadow-lg rounded' }}
                maxWidth="xs"
                fullWidth={true}
              >
                <DialogTitle>Save Filter</DialogTitle>
                <ValidatorForm
                  style={{
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  onSubmit={(event) =>
                    this.onSubmit(
                      event,
                      this.state.filters,
                      updateOrganization,
                    )
                  }
                >
                  <DialogContent>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Grid item key="name" xs={12}>
                        <TextValidator
                          variant="outlined"
                          margin="dense"
                          label="Filter Name"
                          autoFocus
                          fullWidth={true}
                          value={name}
                          onChange={(event) =>
                            this.setState({
                              name: event.target.value,
                            })
                          }
                          required={true}
                          validators={[
                            'required',
                            'maxStringLength: 20',
                          ]}
                          errorMessages={[
                            'This field is required',
                            'You cannot exceed 20 characters',
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    {/* <Button variant='contained' size='small' onClick={this.reset} fullWidth={true}>
                  <FontAwesomeIcon
                    icon={['fas', 'xmark']}
                    className="font-size-lg"
                    style={{ marginRight: 10 }}
                  />
                  <Typography>Clear Filters</Typography>
                </Button> */}
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.reset()
                        this.toggleModal()
                      }}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'xmark']}
                        className="font-size-lg"
                        style={{ marginRight: 10 }}
                      />
                      <Typography>Clear Filters</Typography>
                    </Button>
                    <Typography style={{ flex: 1 }} />
                    <Button variant="text" onClick={this.toggleModal}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ marginRight: 10 }}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </ValidatorForm>
              </Dialog>
            )}
          </Mutation>

          {/* <Mutation
            mutation={UPDATE_ORGANIZATION}
            refetchQueries={[{ query: GET_ME }]}
          >
            {(updateOrganization, { data, loading, error }) => (
              <ValidatorForm onSubmit={event => this.onSubmit(event, this.state.filters, updateOrganization)}>
                <Card>
                  <CardHeader title="Save Filter" />
                  <Divider />
                  <CardContent>
                    <Grid container direction='row' alignItems='center' justifyContent='center' spacing={1}>
                      <Grid item xs={12}>
                        <TextValidator
                          variant='outlined'
                          margin='dense'
                          label="Filter Name"
                          fullWidth={true}
                          value={name}
                          onChange={event => this.setState({ name: event.target.value })}
                          required={true}
                          validators={['required', 'maxStringLength: 20']}
                          errorMessages={[
                            'This field is required',
                            'You cannot exceed 20 characters',
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Typography style={{ flex: 1 }} />
                    <Button variant='text' onClick={this.toggleModal}>Cancel</Button>
                    <Button variant='contained' color="primary" type='submit' style={{ marginRight: 10 }}>Save</Button>
                  </CardActions>
                </Card>
              </ValidatorForm>
            )}
          </Mutation>
        </Dialog> */}

          {/* Delete Saved Filter Dialog */}
          <Dialog
            open={show1}
            onClose={this.toggleModal1}
            classes={{ paper: 'shadow-lg rounded' }}
            maxWidth="xs"
            fullWidth={true}
          >
            <Card>
              <CardHeader title="Delete Filter" />
              <Divider />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid item key="delete" xs={12}>
                    <div className="text-center p-5">
                      <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                          <FontAwesomeIcon
                            icon={['fas', 'xmark']}
                            className="d-flex align-self-center display-3"
                          />
                        </div>
                      </div>
                      <h4 className="font-weight-bold mt-4">
                        Are you sure you want to delete this?
                      </h4>
                      <p className="mb-0 font-size-lg text-muted">
                        All data will be deleted. This action cannot
                        be undone.
                      </p>
                      <Mutation
                        mutation={UPDATE_ORGANIZATION}
                        refetchQueries={[{ query: GET_ME }]}
                      >
                        {(
                          updateOrganization,
                          { data, loading, error },
                        ) => (
                          <div className="pt-4">
                            <Button
                              variant="text"
                              onClick={this.toggleModal1}
                              //className="btn-neutral-secondary btn-pill mx-1"
                            >
                              <span className="btn-wrapper--label">
                                Cancel
                              </span>
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: '#f83145',
                                color: 'white',
                              }}
                              onClick={() =>
                                this.deleteFilter(
                                  activeName,
                                  this.state.filters,
                                  updateOrganization,
                                )
                              }
                              //className="btn-danger btn-pill mx-1"
                            >
                              <span className="btn-wrapper--label">
                                Delete
                              </span>
                            </Button>
                          </div>
                        )}
                      </Mutation>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Dialog>
        </div>
      </>
    )
  }
}

export default withSession(PropertiesFilterComponent)
