import React, { useEffect } from 'react'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import union from 'lodash/union'
import intersection from 'lodash/intersection'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

function FetchMore(props) {
  const {
    loadMore = false,
    fetchMoreProperties,
    cursor = null,
  } = props
  useEffect(() => {
    if (loadMore) {
      fetchMoreProperties({
        variables: {
          cursor,
          limit: GET_PROPERTIES_QUERY_LIMIT,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult

          if (!isEmpty(fetchMoreResult.marketplaceProperties)) {
            if (
              !isEqual(
                prevResult.marketplaceProperties.marketplaces,
                fetchMoreResult.marketplaceProperties.marketplaces,
              )
            )
              return prevResult
            else if (
              isNil(
                fetchMoreResult.marketplaceProperties.approved,
              ) !==
                isNil(prevResult.marketplaceProperties.approved) ||
              isNil(fetchMoreResult.marketplaceProperties.sold) !==
                isNil(prevResult.marketplaceProperties.sold)
            )
              return prevResult
            else if (
              fetchMoreResult.marketplaceProperties.approved !==
                prevResult.marketplaceProperties.approved ||
              fetchMoreResult.marketplaceProperties.sold !==
                prevResult.marketplaceProperties.sold
            )
              return prevResult
            return {
              ...fetchMoreResult,
              marketplaceProperties: {
                ...fetchMoreResult.marketplaceProperties,
                properties: [
                  ...prevResult.marketplaceProperties.properties,
                  ...fetchMoreResult.marketplaceProperties.properties,
                ],
              },
            }
          } else {
            //COMMENT: This code works even if the portfolioIds are in different orders, but it is more complicated and we don't expect that situation to come up
            //if (union(prevResult.properties.portfolioId, fetchMoreResult.properties.portfolioId).length !== intersection(prevResult.properties.portfolioId, fetchMoreResult.properties.portfolioId).length) return prevResult
            if (
              !isEqual(
                prevResult.properties.portfolioId,
                fetchMoreResult.properties.portfolioId,
              )
            )
              return prevResult
            return {
              ...fetchMoreResult,
              properties: {
                ...fetchMoreResult.properties,
                properties: [
                  ...prevResult.properties.properties,
                  ...fetchMoreResult.properties.properties,
                ],
              },
            }
          }
        },
      })
    }
  }, [loadMore, fetchMoreProperties, cursor])
  return null
}

export default FetchMore
