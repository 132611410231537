/**
 * @ Author: Housefolios
 * @ Create Time: 2022-06-01 16:55:02
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-03-05 11:26:20
 * @ Description: Dialog for setting critical property fields
 */

import React, { useEffect, useRef, useState } from 'react'

import clsx from 'clsx'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import remove from 'lodash/remove'
import lodashOrderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import isUndefined from 'lodash/isUndefined'
import kebabCase from 'lodash/kebabCase'
import includes from 'lodash/includes'
import replace from 'lodash/replace'
import sortBy from 'lodash/sortBy'
import meanBy from 'lodash/meanBy'
import slice from 'lodash/slice'
import get from 'lodash/get'
import split from 'lodash/split'
import ceil from 'lodash/ceil'
import head from 'lodash/head'
import round from 'lodash/round'
import debounce from 'lodash/debounce'
import isNil from 'lodash/isNil'
import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import sum from 'lodash/sum'
import sumBy from 'lodash/sumBy'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Grid2,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import withSession from '@/housefolios-components/Session/withSession'
import { NumericFormat } from 'react-number-format'
import { createPepDealsauceEstimateFields } from '@/utils/rehabCalc'
import { useMutation } from '@apollo/client'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'

function RehabCalcDialog(props) {
  const {
    open,
    onClose,
    property,
    affiliate,
    targetValue,
    assumptionsInput,
    changeMenu,
    onChangeAnalysis,
    onChangeAssumptions,
  } = props

  const [accordion, setAccordion] = useState('ep')

  const toggleAccordion = (panel) => {
    if (accordion === panel) {
      setAccordion(null)
    } else {
      setAccordion(panel)
    }
  }

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  const [affiliateEstimatesInput, setAffiliateEstimatesInput] =
    useState({})
  useEffect(() => {
    if (property?.estimates?.affiliate) {
      setAffiliateEstimatesInput(
        property?.estimates?.affiliate[
          targetValue.replace('CalcTotal', 'Cost')
        ] || {},
      )
    }
  }, [property.estimates, targetValue])

  const handleAffiliateEstimateChange = (event) => {
    const { name, type, value } = event.target
    console.log('estimatechange', name)
    let newAffiliateEstimates = cloneDeep(affiliateEstimatesInput)
    let newValue = value
    if (type === 'number') newValue = Number(value)
    else if (type === 'checkbox') {
      newValue = !newAffiliateEstimates[name]
    }
    newAffiliateEstimates[name] = newValue
    setAffiliateEstimatesInput(newAffiliateEstimates)
  }

  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  const updateAffiliateEstimates = (
    property,
    affiliateEstimatesInput,
    assumptionsInput,
    targetValue,
  ) => {
    let affiliateEstimates =
      cloneDeep(property?.estimates?.affiliate) || {}
    affiliateEstimates[targetValue.replace('CalcTotal', 'Cost')] =
      affiliateEstimatesInput
    // let newAnalysisInput = {}
    const sumTotal = sum(
      map(
        createPepDealsauceEstimateFields(
          property,
          affiliateEstimatesInput,
          assumptionsInput,
        ),
        (section) => {
          return sumBy(section.fields, 'total')
        },
      ),
    )
    // newAnalysisInput[targetValue] = sumTotal
    updateProperty({
      variables: {
        id: property?._id,
        propertyInput: {
          // analysisInput: newAnalysisInput,
          estimates: {
            ...property.estimates,
            affiliate: {
              ...affiliateEstimates,
            },
          },
        },
      },
      // refetchQueries: [
      //   { query: GET_PROPERTY, variables: { id: property?._id } },
      // ],
    }).then(() => {
      //COMMENT: triggering changeMenu would cause the dialog to close
      // if (
      //   changeMenu &&
      //   property?.acquisition?.analysis[
      //     targetValue.replace('Cost', 'CalcTotal')
      //   ] !== sumTotal
      // ) {
      //   changeMenu(targetValue, sumTotal, 'affiliate')
      // }
      if (
        onChangeAnalysis &&
        property?.acquisition?.analysis[targetValue] !== sumTotal
      ) {
        onChangeAnalysis({
          target: {
            name: targetValue,
            value: sumTotal,
            type: 'number',
          },
        })
      }
    })
  }

  const debouncedUpdateAffiliateEstimates = useRef(
    debounce(
      (
        property,
        affiliateEstimatesInput,
        assumptionsInput,
        targetValue,
      ) =>
        updateAffiliateEstimates(
          property,
          affiliateEstimatesInput,
          assumptionsInput,
          targetValue,
        ),
      1000,
    ),
  ).current

  useEffect(() => {
    if (!isEmpty(property) && targetValue) {
      debouncedUpdateAffiliateEstimates(
        property,
        affiliateEstimatesInput,
        assumptionsInput,
        targetValue,
      )
    }
    return () => debouncedUpdateAffiliateEstimates.cancel()
  }, [
    property.estimates,
    affiliateEstimatesInput,
    assumptionsInput,
    targetValue,
  ])

  const renderInput = (name, value, type, booleanLabel = '') => {
    if (type === 'Boolean') {
      return (
        <Grid2 container direction="row" alignItems="center">
          <Grid2>
            <Typography>{booleanLabel}</Typography>
          </Grid2>
          <Grid2 className="gridItem2">
            <Checkbox
              color="primary"
              key={name + '_key'}
              name={name}
              type="checkbox"
              value={value}
              checked={Boolean(value)}
              onChange={handleAffiliateEstimateChange}
            />
          </Grid2>
          {/* <Grid2 className="gridItem2" style={{ fontSize: 14 }}>
            {label}
          </Grid2> */}
        </Grid2>
      )
    } else if (type === 'number') {
      return (
        <Grid2>
          <TextField
            name={name}
            variant="outlined"
            //margin="dense"
            value={value || ''}
            onChange={handleAffiliateEstimateChange}
            placeholder="Enter a Number"
            // label={label}
            type="number"
            // fullWidth={true}
          />
        </Grid2>
      )
    } else if (type === 'disabled') {
      return (
        <Grid2>
          <Typography>{value}</Typography>
        </Grid2>
      )
    } else {
      return (
        <Grid2>
          <NumericFormat
            customInput={TextField}
            thousandSeparator={true}
            prefix={'$'}
            // fullWidth={true}
            placeholder="Enter a Dollar Amount"
            isNumericString={true}
            decimalScale={2}
            key={name}
            name={name}
            value={round(value, 2) || ''}
            onValueChange={(values) => {
              // const { value } = values
              const event = {
                target: {
                  name: name,
                  value: values.value,
                  type: 'number',
                },
                preventDefault: () => {
                  return null
                },
              }
              if (Number(values.value) !== value) {
                // if (field.onChange) field.onChange(event)
                // else this.handleChange(event)
                onChangeAssumptions(event)
              }
            }}
            type={'text'}
            onFocus={(event) => {
              event.target.select()
            }}
            variant="outlined"
            // label={label}
          />
        </Grid2>
      )
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle
        style={{
          backgroundColor: '#253053',
          padding: '15px',
          borderRadius: '5px',
          fontWeight: 'bold',
          color: '#FFF',
        }}
      >
        <Grid2 container direction="row">
          <Grid2>{affiliate?.companyName} Estimate</Grid2>
          <Typography style={{ flex: 1 }} />
          <Grid2>
            {currencyFormat.format(
              sum(
                map(
                  createPepDealsauceEstimateFields(
                    property,
                    affiliateEstimatesInput,
                    assumptionsInput,
                  ),
                  (section) => {
                    return sumBy(section.fields, 'total')
                  },
                ),
              ),
            )}
          </Grid2>
        </Grid2>
      </DialogTitle>
      <DialogContent style={{ marginTop: 20 }}>
        {map(
          createPepDealsauceEstimateFields(
            property,
            affiliateEstimatesInput,
            assumptionsInput,
          ),
          (section) => (
            <Card
              key={section.panel}
              className={clsx('card-box', {
                'panel-open': accordion === section.panel,
              })}
            >
              <Card>
                <div
                  className="card-header"
                  style={{
                    backgroundColor:
                      accordion === section.panel
                        ? '#8cc63f'
                        : '#ccc',
                  }}
                >
                  <div
                    className="panel-title"
                    style={{ width: '100%' }}
                  >
                    <div className="accordion-toggle">
                      <Button
                        variant="text"
                        size="large"
                        className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                        onClick={() => toggleAccordion(section.panel)}
                        aria-expanded={accordion === section.panel}
                        style={{ width: '100%' }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {section.sectionIcon}
                          <span style={{ paddingLeft: '5px' }}>
                            {section.sectionName}
                          </span>
                        </div>
                        <Typography style={{ flex: 1 }} />
                        {!section.noTotal && (
                          <Typography style={{ paddingRight: 10 }}>
                            {currencyFormat.format(
                              sumBy(section.fields, 'total'),
                            )}
                          </Typography>
                        )}
                        <FontAwesomeIcon
                          icon={['fas', 'angle-up']}
                          className="font-size-xl accordion-icon"
                        />
                      </Button>
                    </div>
                  </div>
                </div>
                <Collapse in={accordion === section.panel}>
                  <CardContent
                    style={{
                      marginTop: -10,
                      marginBottom: -10,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  >
                    <List
                      component="div"
                      className="list-group-flush"
                    >
                      {map(section.fields, (line) => (
                        <ListItem key={line.label}>
                          <Grid2
                            container
                            spacing={2}
                            direction={'row'}
                            alignItems={'center'}
                            style={{ width: '100%' }}
                          >
                            <Grid2>
                              <Typography>{line.label}</Typography>
                            </Grid2>
                            <Typography style={{ flex: 1 }} />
                            {line.secondType &&
                              renderInput(
                                line.secondName,
                                line.secondValue,
                                line.secondType,
                                line.secondBooleanLabel,
                              )}
                            {line.type &&
                              renderInput(
                                line.name,
                                line.value,
                                line.type,
                                line.booleanLabel,
                              )}
                            {line.assumptionName &&
                              renderInput(
                                line.assumptionName,
                                line.assumptionValue,
                                'assumption',
                              )}
                            {!isNil(line.total) && (
                              <Grid2 size={2}>
                                <Typography
                                  style={{ textAlign: 'end' }}
                                >
                                  {currencyFormat.format(line.total)}
                                </Typography>
                              </Grid2>
                            )}
                          </Grid2>
                        </ListItem>
                      ))}
                      {section.notesName && (
                        <ListItem>
                          <TextField
                            name={section.notesName}
                            variant="outlined"
                            //margin="dense"
                            value={section.notes || ''}
                            onChange={handleAffiliateEstimateChange}
                            placeholder="Notes"
                            label={'Notes'}
                            type="text"
                            multiline
                            minRows={4}
                            fullWidth={true}
                          />
                        </ListItem>
                      )}
                    </List>
                  </CardContent>
                </Collapse>
              </Card>
            </Card>
          ),
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Close
        </Button>
        {/* <Button
            variant="contained"
            className="btn-primary m-2"
            disabled={!selectedComp}
            onClick={() => {
              //Grabs estavl as the menu variable is afterRepair value which comp doesn't have
              changeMenu(
                menuTarget || menu,
                selectedComp['estval'],
                //selectedComp[menu],
                'compSearch',
              )
              setOpenCompDialog(false)
            }}
          >
            Use this total
          </Button> */}
      </DialogActions>
    </Dialog>
  )
}

export default withSession(RehabCalcDialog)
