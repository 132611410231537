import { gql } from '@apollo/client'

export const CREATE_SESSION = gql`
  mutation {
    createBillingPortalSession
  }
`

export const UPDATE_USER_BY_EMAIL = gql`
  mutation updateUserByEmail(
    $email: String!
    $userInput: userInput!
  ) {
    updateUserByEmail(email: $email, userInput: $userInput) {
      _id
      email
      username
      profile {
        firstname
        lastname
      }
      preferences
      roles {
        organization {
          _id
          name
        }
        role
      }
      phone
      userType
      jobTitle
      bioDescription
      activeOrg {
        _id
        name
      }
      setup
      address
      address2
      city
      state
      zip
      DoB
      citizenship
      FICO
      firstTimeInvestor
      yearsInvesting
      realEstateInvestments
      estimatedNetWorth
      estimatedLiquidAssets
      creditReport
      scheduleOfRealEstate
      personalFinancialStatement
    }
  }
`

export const RESET_CALCS = gql`
  mutation {
    resetCalcs
  }
`
