/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-30 16:41:43
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-09-11 14:45:20
 * @ Description: The dialog for uploading properties from a CSV file. Main content being located in the UploadStepper
 */

import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  AppBar,
  Avatar,
  Button,
  Card,
  Dialog,
  Grid,
  Hidden,
  Link,
  Slide,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material'

import UploadStepper from './/UploadStepper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Query } from '@apollo/client/react/components'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(3),
    color: 'white',
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function FullScreenDialog(props) {
  const classes = useStyles()
  const hiddenDown = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  )
  const hiddenUp = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'),
  )
  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const [openUploadProperties, setUploadProperties] = useState(false)
  const handleOpenUploadProperties = () => {
    setUploadProperties(true)
    if (props.closeParent) props.closeParent()
  }
  const handleCloseUploadProperties = () => {
    setUploadProperties(false)
  }

  return (
    <Query query={GET_PORTFOLIOS}>
      {({ loading, error, data }) => {
        //if (loading) return 'Loading...'
        if (error) return `Error! ${error.message}`
        const { portfolios = [] } = data || {}
        return (
          <>
            {props.nav && (
              <Button
                variant="text"
                className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"
                onClick={handleOpenUploadProperties}
              >
                <FontAwesomeIcon
                  icon={['fas', 'upload']}
                  className="h2 d-block mb-2 mx-auto mt-1 text-success"
                />
                <div className="font-weight-bold font-size-md text-black">
                  Upload Properties
                </div>
                <div className="font-size-md mb-1 text-black-50">
                  Upload a csv file of properties
                </div>
              </Button>
            )}
            {props.launchpad && (
              <Button
                variant="text"
                className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"
                onClick={handleOpenUploadProperties}
              >
                <FontAwesomeIcon
                  icon={['far', 'file-excel']}
                  className="h2 d-block mb-2 mx-auto mt-1 text-success"
                />
                <div className="font-weight-bold font-size-md text-black">
                  Upload Properties
                </div>
                <div className="font-size-md mb-1 text-black-50">
                  Upload spreadsheet
                </div>
              </Button>
            )}
            {props.menu && (
              <Grid item sm={6}>
                <Button
                  style={{ textAlign: 'center' }}
                  variant="text"
                  className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"
                  onClick={handleOpenUploadProperties}
                  disabled={props.disabled}
                >
                  <FontAwesomeIcon
                    icon={[`far`, `file-excel`]}
                    //icon={section.icon}
                    className="h2 d-block mb-2 mx-auto mt-1 text-success"
                  />
                  <div className="font-weight-bold text-black">
                    Multiple Addresses
                  </div>
                  <div className="font-size-md mb-1 text-black-50">
                    Upload spreadsheet
                  </div>
                </Button>
              </Grid>
            )}
            <Dialog
              fullScreen
              open={openUploadProperties}
              onClose={handleCloseUploadProperties}
              onKeyDown={stopPropagationForTab}
              TransitionComponent={Transition}
            >
              <AppBar enableColorOnDark className={classes.appBar}>
                <Toolbar>
                  <Typography variant="h6" className={classes.title}>
                    Upload Properties
                  </Typography>
                  <Button
                    variant="outlined"
                    margin="dense"
                    style={{ color: 'white', borderColor: 'white' }}
                    onClick={handleCloseUploadProperties}
                  >
                    Cancel
                  </Button>
                </Toolbar>
              </AppBar>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ paddingLeft: 30, paddingRight: 30 }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: 100,
                        marginBottom: 50,
                      }}
                    >
                      {/* <Card>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing={2}
                          style={{ padding: 20 }}
                        >
                          {hiddenDown ? null : (
                            <>
                              <Grid item>
                                <Avatar
                                  aria-label="recipe"
                                  className="bg-neutral-success text-success"
                                  style={{ width: 50, height: 50 }}
                                >
                                  <FontAwesomeIcon
                                    icon={['far', 'file-xmark']}
                                    style={{ fontSize: 30 }}
                                  />
                                </Avatar>
                              </Grid>
                              <Grid item>
                                <Grid container direction="column">
                                  <Grid item>
                                    <Typography>
                                      Having troubles with your file?
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography>
                                      Click the button to the right
                                      for a video and troubleshooting
                                      tips, or download a sample file{' '}
                                      <Link
                                        target="_blank"
                                        href="https://docs.google.com/spreadsheets/d/1BFzyiyDKCZJAidBoo5qFm8c7METFkkgvD0okQOMmoOk/edit"
                                        style={{
                                          textDecoration: 'underline',
                                        }}
                                      >
                                        HERE
                                      </Link>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Typography style={{ flex: 1 }} />
                              <Grid item>
                                <Button
                                  size="small"
                                  variant="contained"
                                  margin="dense"
                                  style={{
                                    backgroundColor: '#1cc943',
                                    color: 'white',
                                  }}
                                  target="_blank"
                                  href="http://help.housefolios.com/en/articles/3968659-how-to-upload-a-list-of-properties"
                                >
                                  <Typography>Instruction</Typography>
                                  <FontAwesomeIcon
                                    icon={['far', 'arrow-right']}
                                    className="font-size-lg"
                                    style={{ marginLeft: 5 }}
                                  />
                                </Button>
                              </Grid>
                            </>
                          )}

                          {hiddenUp ? null : (
                            <>
                              <Grid
                                item
                                style={{ textAlign: 'center' }}
                              >
                                <Grid container direction="column">
                                  <Grid item>
                                    <Typography>
                                      Having troubles with your file?
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography>
                                      Click the button to the right
                                      for a video and troubleshooting
                                      tips, or download a sample file{' '}
                                      <Link
                                        target="_blank"
                                        href="https://docs.google.com/spreadsheets/d/1BFzyiyDKCZJAidBoo5qFm8c7METFkkgvD0okQOMmoOk/edit"
                                        style={{
                                          textDecoration: 'underline',
                                        }}
                                      >
                                        HERE
                                      </Link>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{ textAlign: 'center' }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  margin="dense"
                                  style={{
                                    backgroundColor: '#1cc943',
                                    color: 'white',
                                  }}
                                  target="_blank"
                                  href="http://help.housefolios.com/en/articles/3968659-how-to-upload-a-list-of-properties"
                                >
                                  <Typography>Instruction</Typography>
                                  <FontAwesomeIcon
                                    icon={['far', 'arrow-right']}
                                    className="font-size-lg"
                                    style={{ marginLeft: 5 }}
                                  />
                                </Button>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Card> */}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 100,
                      }}
                    >
                      <UploadStepper
                        openUploadProperties={openUploadProperties}
                        handleCloseUploadProperties={
                          handleCloseUploadProperties
                        }
                        portfolios={portfolios}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Dialog>
          </>
        )
      }}
    </Query>
  )
}
