import React from 'react'

import clsx from 'clsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from '@mui/material'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { connect } from 'react-redux'
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions'

import { NavLink } from 'react-router-dom'

import projectLogo from '@/assets/images/react.svg'

import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone'
import RoomTwoToneIcon from '@mui/icons-material/RoomTwoTone'
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone'
import CollectionsTwoToneIcon from '@mui/icons-material/CollectionsTwoTone'
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone'
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone'
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone'
import CameraTwoToneIcon from '@mui/icons-material/CameraTwoTone'

const SidebarCollapsed = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile)
  }

  const {
    sidebarShadow,
    sidebarStyle,
    sidebarToggleMobile,
    setSidebarToggleMobile,
  } = props

  return (
    <>
      <div
        className={clsx(
          'app-sidebar app-sidebar--collapsed app-sidebar--mini',
          sidebarStyle,
          { 'app-sidebar--shadow': sidebarShadow },
        )}
      >
        <div className="app-sidebar--header">
          <div className="app-sidebar-logo">
            <NavLink
              to="/DashboardAnalytics"
              title="Bamburgh React Admin Dashboard with Material-UI PRO"
              className="app-sidebar-logo"
            >
              <div className="app-sidebar-logo--icon">
                <img
                  alt="Bamburgh React Admin Dashboard with Material-UI PRO"
                  src={projectLogo}
                />
              </div>
            </NavLink>
          </div>
        </div>

        <div className="app-sidebar--content">
          <PerfectScrollbar>
            <ul className="mt-2 sidebar-menu-collapsed">
              <li>
                <Tooltip
                  classes={{
                    tooltip: 'tooltip-secondary text-nowrap',
                  }}
                  arrow
                  placement="right"
                  title="Chat"
                >
                  <NavLink className={({ isActive }) => (isActive ? "active" : "")} to="/PageChat">
                    <span>
                      <VerifiedUserTwoToneIcon />
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  classes={{
                    tooltip: 'tooltip-secondary text-nowrap',
                  }}
                  arrow
                  placement="right"
                  title="Calendar"
                >
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to="/PageCalendar"
                  >
                    <span>
                      <RoomTwoToneIcon />
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  classes={{
                    tooltip: 'tooltip-secondary text-nowrap',
                  }}
                  arrow
                  placement="right"
                  title="File Manager"
                >
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to="/PageFileManager"
                  >
                    <span>
                      <CameraAltTwoToneIcon />
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  classes={{
                    tooltip: 'tooltip-secondary text-nowrap',
                  }}
                  arrow
                  placement="right"
                  title="Projects"
                >
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to="/PageProjects"
                  >
                    <span>
                      <CollectionsTwoToneIcon />
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  classes={{
                    tooltip: 'tooltip-secondary text-nowrap',
                  }}
                  arrow
                  placement="right"
                  title="Profile"
                >
                  <NavLink className={({ isActive }) => (isActive ? "active" : "")} to="/PageProfile">
                    <span>
                      <FavoriteTwoToneIcon />
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  classes={{
                    tooltip: 'tooltip-secondary text-nowrap',
                  }}
                  arrow
                  placement="right"
                  title="Monitoring Dashboard"
                >
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to="/DashboardMonitoring"
                  >
                    <span>
                      <BusinessCenterTwoToneIcon />
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  classes={{
                    tooltip: 'tooltip-secondary text-nowrap',
                  }}
                  arrow
                  placement="right"
                  title="Analytics Dashboard"
                >
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to="/DashboardAnalytics"
                  >
                    <span>
                      <AssessmentTwoToneIcon />
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  classes={{
                    tooltip: 'tooltip-secondary text-nowrap',
                  }}
                  arrow
                  placement="right"
                  title="Statistics Dashboard"
                >
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to="/DashboardStatistics"
                  >
                    <span>
                      <CameraTwoToneIcon />
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
            </ul>
            <div className="text-center mb-2">
              <Tooltip
                classes={{ tooltip: 'tooltip-secondary text-nowrap' }}
                arrow
                placement="right"
                title="Back to dashboard"
              >
                <Button
                  component={NavLink}
                  variant="contained"
                  className="btn-warning btn-icon m-1 p-0 shadow-none text-center font-size-lg d-40 rounded"
                  to="/DashboardAnalytics"
                >
                  <FontAwesomeIcon icon={['fas', 'arrow-left']} />
                </Button>
              </Tooltip>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-sidebar-overlay', {
          'is-active': sidebarToggleMobile,
        })}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  sidebarShadow: state.DeprecatedThemeOptions.sidebarShadow,
  sidebarStyle: state.DeprecatedThemeOptions.sidebarStyle,
  sidebarToggleMobile:
    state.DeprecatedThemeOptions.sidebarToggleMobile,
})

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) =>
    dispatch(setSidebarToggleMobile(enable)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarCollapsed)
