import { gql } from '@apollo/client'

export const GET_TRAINING_BUTTONS = gql`
  query trainingButtons($page: String!, $member: String) {
    trainingButtons(page: $page, member: $member) {
      _id
      icon
      text
      url
      popup
      link
      linkTarget
      type
    }
  }
`
