/**
 * @ Author: Housefolios
 * @ Create Time: 2021-10-05 11:07:32
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-01-10 16:07:20
 * @ Description: Main component of the create enterprise account page
 */

import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { Mutation, Query } from '@apollo/client/react/components'
import map from 'lodash/map'
import CountUp from 'react-countup'
import split from 'lodash/split'
import upperFirst from 'lodash/upperFirst'
import toLower from 'lodash/toLower'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import head from 'lodash/head'

import {
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Check } from '@mui/icons-material'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

import {
  ValidatorForm,
  TextValidator,
} from 'react-material-ui-form-validator'

import newLogoBlue from '@/assets/images/NewLogoBlue.png'
import { GET_SUBSCRIPTION_PLAN } from './queries'
import PaymentForm from '../PaymentForm'
import { CHECK_SIGN_UP, GET_COUPON, SIGN_UP } from './mutations'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { GET_AFFILIATE } from '../CreateAccountCard/queries'
import { useParams, Link as RouterLink } from 'react-router-dom'

function CreateEnterpriseAccountComponent(props) {
  // const [searchStatus1, setSearchStatus1] = useState(false);
  // const toggleSearch1 = () => setSearchStatus1(!searchStatus1);
  const { planName = 'enterprise-affiliate' } = useParams()

  const [activeTab1, setActiveTab1] = useState(planName)

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  let hfpid = urlParams.get('hfpid')
  let refOrg = urlParams.get('org')
  let source = urlParams.get('source')

  let rockstarReferrer = ['https://go.housefolios.com/rerockstars']
  let isRockstar = false
  if (
    source === 'rerockstars' &&
    (includes(rockstarReferrer, document.referrer) ||
      includes(
        map(rockstarReferrer, (ref) => ref + '/'),
        document.referrer,
      )) &&
    activeTab1 === 'investor-yearly'
  )
    isRockstar = true

  let myndReferrer = [
    'https://housefolios.com/mynd/',
    'https://www.housefolios.com/mynd/',
  ]
  let isMynd = false
  if (
    source === 'mynd' &&
    (includes(myndReferrer, document.referrer) ||
      includes(
        map(myndReferrer, (ref) => ref + '/'),
        document.referrer,
      ))
  )
    isMynd = true

  //let paulReferrer = ["https://housefolios.com/paul", "https://www.housefolios.com/paul"]
  let isPaul = false
  //if (source === "paulmccomas" && (includes(paulReferrer, document.referrer) || includes(map(paulReferrer, ref => ref+'/'), document.referrer)))
  if (source === 'paulmccomas') {
    isPaul = true
    hfpid = 'A1'
  }

  let isGorilla = false
  if (source === 'gorillacapital') {
    isGorilla = true
    hfpid = 'A2'
  }

  let isInvestarters = false
  if (source === 'investarters') {
    isInvestarters = true
    hfpid = 'A3'
  }
  //let thinkRealtyReferrer = ["https://housefolios.com/thinkrealty/", "https://www.housefolios.com/thinkrealty/"]
  let isThinkRealty = false
  if (source === 'thinkrealty') {
    isThinkRealty = true
    hfpid = 'A4'
  }
  let isFlipology = false
  if (source === 'flipology') {
    isFlipology = true
    hfpid = 'A5'
  }
  let isFlippingAmerica = false
  if (source === 'flippingamerica') {
    isFlippingAmerica = true
    hfpid = 'A6'
  }
  let isPrivateMoneyClub = false
  if (source === 'privatemoneyclub') {
    isPrivateMoneyClub = true
    hfpid = '76'
  }
  let isSyndicatedCapital = false
  if (source === 'syndicatedcapital') {
    isSyndicatedCapital = true
    hfpid = 'A8'
  }
  let isTwoGuysTakeOnRealEstate = false
  if (source === 'twoguystakeonrealestate') {
    isTwoGuysTakeOnRealEstate = true
    hfpid = 'A9'
  }

  // If none of the sources booleans are true the source isn't valid
  if (
    !isRockstar &&
    !isMynd &&
    !isPaul &&
    !isGorilla &&
    !isInvestarters
  )
    source = null

  const [token, setToken] = useState('')
  //const [element, setElement] = useState(null)
  const [resetToken, setResetToken] = useState(() => {
    return null
  })
  //  const [coupon, setCoupon] = useState(isRockstar ? 'OXpyi0Nc' : isMynd ? '9a309m9M' : (isThinkRealty || isInvestarters) ? '8Ta1SQ2E' : '')
  //  const [couponValid, setCouponValid] = useState(isRockstar || isMynd || isThinkRealty || isInvestarters)
  //  const [couponPercent, setCouponPercent] = useState(isMynd ? 50 : (isThinkRealty || isInvestarters) ? 10 : null)
  //  const [couponAmount, setCouponAmount] = useState(isRockstar ? 17700 : null)
  const [affiliate, setAffiliate] = useState('')

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [passwordMask, setPasswordMask] = useState(true)

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const createToken = async (stripe, cardElement, nameOnCard) => {
    if (!nameOnCard) setToken('')
    else {
      await stripe
        // .createToken({
        //   type: 'card',
        //   card: cardElement,
        //   name: nameOnCard,
        // })
        .createToken(cardElement, {
          name: nameOnCard,
        })
        .then((res) => {
          var errorElement = document.getElementById('card-errors')
          if (res.error) {
            setToken('')
            if (errorElement)
              errorElement.textContent = res.error.message
          } else {
            setToken(res.token)
            if (errorElement) errorElement.textContent = null
          }
        })
    }
  }

  const [getAffiliate, results] = useLazyQuery(GET_AFFILIATE, {
    variables: {
      memberValue: affiliate,
    },
  })

  const affiliateValid =
    results.data &&
    !isEmpty(results.data.affiliate) &&
    results.data.affiliate.planDetails
  const affiliatePrice = affiliateValid
    ? results.data.affiliate.planDetails.price
    : null
  const affiliateBilling = affiliateValid
    ? results.data.affiliate.planDetails.paymentInterval
    : 'monthly'

  const checkAffiliate = async (affiliate) => {
    if (affiliate) getAffiliate()
  }

  const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY
  const stripePromise = loadStripe(stripePublicKey)

  const toggle = (tab) => {
    setActiveTab1(tab)
  }

  useEffect(() => {
    if (urlParams.get('affiliate')) {
      setAffiliate(urlParams.get('affiliate'))
      checkAffiliate(urlParams.get('affiliate'))
    }
  }, [])

  const [agree, setAgree] = useState(false)

  const [loader, setLoader] = useState(false)

  const handleSubmit = async (
    signUp,
    checkSignup,
    planID,
    planPrice,
  ) => {
    if (!token || !agree) return
    setLoader(true)
    const productChoice = 'enterprise-affiliate'

    let gaID = hfpid
      ? upperFirst(productChoice) +
        '_' +
        hfpid.toString() +
        '_' +
        email
      : productChoice + '_' + email

    checkSignup()
      .then(async ({ data }) => {
        signUp()
          .then(async ({ data }) => {
            ReactPixel.track('StartTrial', {
              value: planPrice,
              currency: 'USD',
              predicted_ltv: planPrice * 6,
              description:
                `email=${email}` +
                '&' +
                `productChoice=${productChoice}` +
                '&' +
                (hfpid ? `hfpid=${hfpid.toString()}` : ''),
            })
            ReactPixel.track('Purchase', {
              value: planPrice,
              currency: 'USD',
              predicted_ltv: planPrice * 6,
              description:
                `email=${email}` +
                '&' +
                `productChoice=${productChoice}` +
                '&' +
                (hfpid ? `hfpid=${hfpid.toString()}` : ''),
            })
            ReactGA.plugin.execute('ecommerce', 'addItem', {
              id: gaID,
              name: upperFirst(productChoice),
              sku: planID,
              price: planPrice,
              quantity: 1,
              currency: 'USD',
            })
            ReactGA.plugin.execute('ecommerce', 'addTransaction', {
              id: gaID,
              revenue: planPrice,
              total: planPrice,
              shipping: 0,
              tax: 0,
              currency: 'USD',
            })
            ReactGA.plugin.execute('ecommerce', 'send')
            ReactGA.plugin.execute('ecommerce', 'clear')

            localStorage.setItem(`buyBox-${data.signUp.orgId}`, true)
            localStorage.setItem('token', data.signUp.token)
            //  await props.refetch()
            window.location.reload()
            setLoader(false)
          })
          .catch((error) => {
            console.log('"' + error.message + '"')
            resetToken()
            setLoader(false)
          })
      })
      .catch((error) => {
        console.log('"' + error.message + '"')
        resetToken()
        setLoader(false)
      })
  }

  let planOptions = [
    {
      title: (
        <Query
          query={GET_SUBSCRIPTION_PLAN}
          variables={{ subscriptionPlan: 'enterprise-affiliate' }}
        >
          {({ loading, error, data }) => {
            if (loading) return 'loading'
            const { subscriptionPlan = {} } = data
            return (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography>
                    Enterprise Affiliate - Monthly
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {currencyFormat.format(affiliatePrice / 100)}/mo
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
        </Query>
      ),
      textAlign: 'left',
      name: 'enterprise-affiliate',
    },
  ]

  const { loading, data, error } = useQuery(GET_SUBSCRIPTION_PLAN, {
    variables: { subscriptionPlan: activeTab1 },
    errorPolicy: 'ignore',
  })

  const { subscriptionPlan = {} } = data || {}

  useEffect(() => {
    if (!loading && affiliatePrice) {
      ReactPixel.track('InitiateCheckout', {
        value: affiliatePrice / 100,
        currency: 'USD',
        description: activeTab1,
      })
    }
  }, [activeTab1, loading, affiliatePrice])

  if (loading) return 'loading'
  if (!subscriptionPlan) {
    setActiveTab1(head(planOptions).name)
    return 'Invalid Plan'
  }
  const plan = split(subscriptionPlan.subscriptionPlan, '-')
  let trialEnd = new Date()
  trialEnd.setDate(
    trialEnd.getDate() + subscriptionPlan.trialPeriodDays,
  )

  const referredByDetails = {
    affiliateId: hfpid,
    saleDate: new Date(),
    productSelected: subscriptionPlan.subscriptionPlan,
    productTotalPrice: affiliatePrice,
    //couponUsed: coupon,
    active: true,
  }

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Mutation
                    mutation={CHECK_SIGN_UP}
                    variables={{
                      userInput: {
                        email: toLower(email),
                      },
                    }}
                  >
                    {(checkSignup, { data, loading, error }) => {
                      const signUpError = error
                      return (
                        <Mutation
                          mutation={SIGN_UP}
                          variables={{
                            userInput: {
                              profile: {
                                firstname: firstName,
                                lastname: lastName,
                              },
                              email: toLower(email),
                              // userType,
                              organization: firstName + lastName,
                              phone: phoneNumber,
                              // address: billingAddress,
                              // city,
                              // state,
                              // zip: postalCode,
                              password: password,
                              stripeToken: token,
                              //stripePlanId: subscriptionPlan.planId,
                              referredByDetails: hfpid
                                ? referredByDetails
                                : null,
                              refOrg: refOrg ? refOrg : null,
                              source,
                              affiliatePlan: affiliate,
                            },
                          }}
                        >
                          {(signUp, { data, loading, error }) => {
                            var errorElement =
                              document.getElementById('card-errors')

                            if (error && errorElement) {
                              errorElement.textContent =
                                error.message.slice(0, 21) ===
                                'GraphQL error: Error:'
                                  ? error.message.slice(21)
                                  : null
                            }

                            return (
                              <ValidatorForm
                                onSubmit={() =>
                                  handleSubmit(
                                    signUp,
                                    checkSignup,
                                    subscriptionPlan.planId,
                                    affiliatePrice / 100,
                                  )
                                }
                              >
                                <BlockUi
                                  blocking={loader}
                                  loader={
                                    <PacmanLoader
                                      color={'var(--primary)'}
                                      loading={loader}
                                    />
                                  }
                                  message={
                                    <div className="text-primary">
                                      Creating Account
                                    </div>
                                  }
                                >
                                  <Grid
                                    container
                                    spacing={0}
                                    className="min-vh-100"
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      className="d-flex align-items-center"
                                    >
                                      <Grid item className="mx-auto">
                                        <Card
                                          style={{
                                            padding: 30,
                                            maxWidth: 600,
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction="column"
                                            // justifyContent='space-evenly'
                                          >
                                            <Grid
                                              item
                                              style={{
                                                textAlign: 'center',
                                                paddingTop: 50,
                                                marginBottom: 50,
                                              }}
                                            >
                                              <img
                                                alt="Housefolios"
                                                src={newLogoBlue}
                                                style={{ width: 275 }}
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              style={{
                                                marginBottom: 10,
                                              }}
                                            >
                                              <Typography
                                                variant="h5"
                                                style={{
                                                  fontWeight: 600,
                                                }}
                                              >
                                                Affiliate Membership
                                                Signup
                                              </Typography>
                                            </Grid>
                                            <Grid item>
                                              <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                              >
                                                <Grid item xs={12}>
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    spacing={1}
                                                  >
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                    >
                                                      <TextValidator
                                                        fullWidth={
                                                          true
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        placeholder="First Name"
                                                        label="First Name"
                                                        value={
                                                          firstName
                                                        }
                                                        onChange={(
                                                          event,
                                                        ) =>
                                                          setFirstName(
                                                            event
                                                              .target
                                                              .value,
                                                          )
                                                        }
                                                        validators={[
                                                          'required',
                                                          'maxStringLength: 20',
                                                          'matchRegexp:^[A-Z|a-z]+$',
                                                        ]}
                                                        errorMessages={[
                                                          'This field is required',
                                                          'You cannot exceed 20 characters',
                                                          'This field only allows characters in the alphabet',
                                                        ]}
                                                      />
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                    >
                                                      <TextValidator
                                                        fullWidth={
                                                          true
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        placeholder="Last Name"
                                                        label="Last Name"
                                                        value={
                                                          lastName
                                                        }
                                                        onChange={(
                                                          event,
                                                        ) =>
                                                          setLastName(
                                                            event
                                                              .target
                                                              .value,
                                                          )
                                                        }
                                                        validators={[
                                                          'required',
                                                          'maxStringLength: 20',
                                                          'matchRegexp:^[A-Z|a-z]+$',
                                                        ]}
                                                        errorMessages={[
                                                          'This field is required',
                                                          'You cannot exceed 20 characters',
                                                          'This field only allows characters in the alphabet',
                                                        ]}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <TextValidator
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    margin="dense"
                                                    placeholder="Phone Number"
                                                    label="Phone Number"
                                                    value={
                                                      phoneNumber
                                                    }
                                                    onChange={(
                                                      event,
                                                    ) =>
                                                      setPhoneNumber(
                                                        event.target
                                                          .value,
                                                      )
                                                    }
                                                    validators={[
                                                      'required',
                                                      //eslint-disable-next-line
                                                      'matchRegexp:^[0-9()-]+$',
                                                    ]}
                                                    errorMessages={[
                                                      'This field is required',
                                                      'Not a valid phone number',
                                                    ]}
                                                  />
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <TextValidator
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    margin="dense"
                                                    placeholder="Email Address"
                                                    label="Email Address"
                                                    value={email}
                                                    onChange={(
                                                      event,
                                                    ) =>
                                                      setEmail(
                                                        event.target
                                                          .value,
                                                      )
                                                    }
                                                    validators={[
                                                      'required',
                                                      'maxStringLength: 50',
                                                      'isEmail',
                                                    ]}
                                                    errorMessages={[
                                                      'This field is required',
                                                      'You cannot exceed 50 characters',
                                                      'Not a valid email address',
                                                    ]}
                                                    error={
                                                      signUpError
                                                    }
                                                    helperText={
                                                      signUpError
                                                        ? signUpError.message ===
                                                          'GraphQL error: Email already exists.'
                                                          ? 'Email already exists'
                                                          : signUpError.message ===
                                                              'GraphQL error: ValidationError: emails: [object Object] is not a valid email'
                                                            ? 'Not a valid email'
                                                            : signUpError.message ===
                                                                'GraphQL error: Error: Your card has insufficient funds.'
                                                              ? ''
                                                              : signUpError.message
                                                        : ''
                                                    }
                                                  />
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <TextValidator
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    margin="dense"
                                                    placeholder="Password"
                                                    label="Password"
                                                    value={password}
                                                    onChange={(
                                                      event,
                                                    ) =>
                                                      setPassword(
                                                        event.target
                                                          .value,
                                                      )
                                                    }
                                                    type={
                                                      passwordMask
                                                        ? 'password'
                                                        : 'text'
                                                    }
                                                    validators={[
                                                      'required',
                                                      'maxStringLength: 50',
                                                      'minStringLength: 7',
                                                    ]}
                                                    errorMessages={[
                                                      'This field is required',
                                                      'You cannot exceed 50 characters',
                                                      'Password must be at least 7 characters long',
                                                    ]}
                                                    InputProps={{
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <IconButton
                                                            onClick={() =>
                                                              setPasswordMask(
                                                                !passwordMask,
                                                              )
                                                            }
                                                            size="large"
                                                          >
                                                            {passwordMask ? (
                                                              <RemoveRedEyeIcon />
                                                            ) : (
                                                              <VisibilityOffIcon />
                                                            )}
                                                          </IconButton>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Divider
                                              style={{
                                                marginTop: 20,
                                                marginBottom: 10,
                                              }}
                                            />
                                            <Grid
                                              item
                                              style={{
                                                marginBottom: 10,
                                              }}
                                            >
                                              <Typography
                                                variant="h5"
                                                style={{
                                                  fontWeight: 600,
                                                }}
                                              >
                                                Plan
                                              </Typography>
                                            </Grid>
                                            <Grid item>
                                              <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                              >
                                                {/* <Grid item xs={12}>
                                                 <Select
                                                   variant='outlined'
                                                   margin='dense'
                                                   fullWidth={true}
                                                   value={activeTab1}
                                                   onChange={event => toggle(event.target.value)}
                                                   displayEmpty
                                                   disabled
                                                 >
                                                   {map(planOptions, plan => (
                                                     <MenuItem key={plan.name} value={plan.name}>
                                                       {plan.title}
                                                     </MenuItem>
                                                   ))}
                                                 </Select>
                                               </Grid> */}
                                                <Grid
                                                  item
                                                  xs={12}
                                                  style={{
                                                    marginTop: 5,
                                                  }}
                                                >
                                                  <Grid
                                                    container
                                                    direction="row"
                                                  >
                                                    <Grid item>
                                                      <TextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        placeholder="Add Affiliate Code"
                                                        id="input-with-icon-textfield22-1"
                                                        value={
                                                          affiliate
                                                        }
                                                        fullWidth={
                                                          true
                                                        }
                                                        onChange={(
                                                          event,
                                                        ) =>
                                                          setAffiliate(
                                                            event
                                                              .target
                                                              .value,
                                                          )
                                                        }
                                                        disabled={
                                                          affiliateValid
                                                        }
                                                        InputProps={{
                                                          endAdornment:
                                                            (
                                                              <InputAdornment position="end">
                                                                {affiliate && (
                                                                  <Button
                                                                    onClick={() =>
                                                                      checkAffiliate(
                                                                        affiliate,
                                                                      )
                                                                    }
                                                                  >
                                                                    Apply
                                                                  </Button>
                                                                )}
                                                                {affiliateValid && (
                                                                  <Check
                                                                    style={{
                                                                      color:
                                                                        'green',
                                                                    }}
                                                                  />
                                                                )}
                                                                {!affiliateValid && (
                                                                  <FontAwesomeIcon
                                                                    icon={[
                                                                      'far',
                                                                      'xmark',
                                                                    ]}
                                                                    className="font-size-md"
                                                                    style={{
                                                                      color:
                                                                        'red',
                                                                    }}
                                                                  />
                                                                )}
                                                              </InputAdornment>
                                                            ),
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  className="mt-2"
                                                >
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    wrap="nowrap"
                                                  >
                                                    <Grid item>
                                                      <Typography
                                                        style={{
                                                          fontSize: 22,
                                                          fontWeigh: 90,
                                                        }}
                                                        color="primary"
                                                      >
                                                        Total due
                                                        today
                                                      </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                      <Typography
                                                        style={{
                                                          fontSize: 22,
                                                          fontWeigh: 900,
                                                          textAlign:
                                                            'right',
                                                        }}
                                                        color="primary"
                                                      >
                                                        <CountUp
                                                          start={0}
                                                          end={
                                                            !affiliateValid
                                                              ? 0
                                                              : affiliatePrice /
                                                                100 /*(subscriptionPlan.price * (1 - couponPercent / 100) - couponAmount) / 100*/
                                                          }
                                                          duration={1}
                                                          delay={1}
                                                          separator=","
                                                          decimals={2}
                                                          prefix="$ "
                                                          suffix=""
                                                        />
                                                      </Typography>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item>
                                                  <Typography
                                                    style={{
                                                      fontSize: 16,
                                                      fontWeigh: 900,
                                                      color: 'red',
                                                    }}
                                                  >
                                                    {/* {(isPaul || isGorilla || isInvestarter) ? '***Next payment due 3 days from now' : ***Next payment of {(plan[1] === 'monthly' || 'enterprise-affiliate') ? 'a month' : 'a year'} from now.} */}
                                                    ***Next payment of{' '}
                                                    {currencyFormat.format(
                                                      affiliatePrice /
                                                        100,
                                                    )}{' '}
                                                    due{' '}
                                                    {isPaul ||
                                                    isGorilla ||
                                                    isInvestarters
                                                      ? '3 Days'
                                                      : affiliateBilling ===
                                                          'monthly'
                                                        ? 'a month'
                                                        : 'a year'}{' '}
                                                    from now.
                                                    {/* ***Next payment of {currencyFormat.format((subscriptionPlan.price * (1 - couponPercent / 100) - couponAmount) / 100)} due {(memberTrial) ? `${memberTrial} Days` : plan[1] === 'monthly' ? 'a month' : 'a year'} from now. */}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Divider
                                              style={{
                                                marginTop: 20,
                                                marginBottom: 10,
                                              }}
                                            />
                                            <Grid item>
                                              <Grid container>
                                                <Grid
                                                  item
                                                  style={{
                                                    marginBottom: 10,
                                                  }}
                                                >
                                                  <Typography
                                                    variant="h5"
                                                    style={{
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    Billing Info
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <Elements
                                                    stripe={
                                                      stripePromise
                                                    }
                                                  >
                                                    <PaymentForm
                                                      createToken={
                                                        createToken
                                                      }
                                                      //setElement={setElement}
                                                      setResetToken={
                                                        setResetToken
                                                      }
                                                    />
                                                  </Elements>
                                                  <Typography
                                                    style={{
                                                      color: 'red',
                                                      fontSize: 13,
                                                      marginTop: 3,
                                                      marginLeft: 12,
                                                      fontWeight: 400,
                                                    }}
                                                    id="card-errors"
                                                    role="alert"
                                                  />
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={agree}
                                                    required
                                                    onChange={() =>
                                                      setAgree(!agree)
                                                    }
                                                  />
                                                }
                                                label={
                                                  <Typography>
                                                    I agree to the{' '}
                                                    <Link
                                                      component={
                                                        RouterLink
                                                      }
                                                      to="/Terms"
                                                      underline="hover"
                                                      style={{
                                                        color:
                                                          '#04a3e3',
                                                      }}
                                                      target="_blank"
                                                    >
                                                      Terms of Service
                                                    </Link>{' '}
                                                    and{' '}
                                                    <Link
                                                      component={
                                                        RouterLink
                                                      }
                                                      to="/Privacy"
                                                      underline="hover"
                                                      style={{
                                                        color:
                                                          '#04a3e3',
                                                      }}
                                                      target="_blank"
                                                    >
                                                      Privacy Policy
                                                    </Link>
                                                  </Typography>
                                                }
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{
                                                marginTop: 25,
                                              }}
                                            >
                                              <Button
                                                fullWidth={true}
                                                variant="contained"
                                                type="submit"
                                                disabled={
                                                  !token ||
                                                  !agree ||
                                                  !affiliateValid
                                                }
                                                color="primary" /*style={{ backgroundColor: '#04a3e3', color: 'white' }}*/
                                              >
                                                <Typography>
                                                  BEGIN AFFILIATE PLAN
                                                </Typography>
                                              </Button>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{
                                                marginTop: 25,
                                              }}
                                            >
                                              <Link
                                                href="/signin"
                                                underline="hover"
                                                style={{
                                                  color: '#04a3e3',
                                                }}
                                                target="_blank"
                                              >
                                                Already have an
                                                account?
                                              </Link>
                                            </Grid>
                                          </Grid>
                                        </Card>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </BlockUi>
                              </ValidatorForm>
                            )
                          }}
                        </Mutation>
                      )
                    }}
                  </Mutation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateEnterpriseAccountComponent
