import React, { useState, useEffect } from 'react'

import map from 'lodash/map'
import find from 'lodash/find'
import compact from 'lodash/compact'
import get from 'lodash/get'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import CheckIcon from '@mui/icons-material/Check'
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone'
import CreateNewOrg from '@/housefolios-components/OrganizationSettings/CreateNewOrg'

import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { useDropzone } from 'react-dropzone'

import HousefoliosUser from '@/assets/images/Housefolios_user.png'

import withSession from '@/housefolios-components/Session/withSession'
import { IMAGE_UPLOAD } from '@/housefolios-components/TitleButtons/AddPropertyButton/mutations'
import { ApolloConsumer, useMutation } from '@apollo/client'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { signOut } from '@/housefolios-components/SignOut'
import { UPDATE_USER } from '@/housefolios-components/PersonalSettings/mutations'
import { GET_PORTFOLIOS } from '@/housefolios-pages/Launchpad/queries'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

const HeaderUserbox = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [files, setFiles] = useState([props.session.me.userImage])
  const [imageUpload] = useMutation(IMAGE_UPLOAD)
  const [updateUser] = useMutation(UPDATE_USER)
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    getRootProps,
    getInputProps,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
      map(acceptedFiles, (file) => {
        const reader = new FileReader()
        reader.onload = async () => {
          const userImage = reader.result
          imageUpload({
            variables: {
              documentId: props.session.me._id,
              imageUrls: [userImage],
              collection: 'teamMember',
            },
            refetchQueries: [{ query: GET_ME }],
          }).then(async ({ data }) => {
            enqueueSnackbar('Image Saved', {
              variant: 'success',
              autohideDuratation: 3000,
            })
          })
        }
        reader.readAsDataURL(file)
      })
    },
  })

  let navigate = useNavigate()

  // const hiddenXsDown = useMediaQuery((theme) =>
  //   theme.breakpoints.down('xs'),
  // )

  const hostname = import.meta.env.VITE_HOSTNAME

  const dynamicSpacing =
    hostname === 'localhost' ||
    hostname === 'dev-admin.housefolios.com'
      ? 'mr-4'
      : 'mr-2'

  const thumbs = map(compact(files), (file) => (
    <div
      key={file.name}
      className="rounded avatar-image overflow-hidden d-140 bg-neutral-dark text-center font-weight-bold text-success d-flex justify-content-center align-items-center"
    >
      <div
        className="blur-load"
        style={{ backgroundImage: 'url(' + file.url_small + ')' }}
      >
        <img
          className="img-fluid img-fit-container rounded-sm"
          src={file.url || file.preview}
          alt="..."
          loading="lazy"
        />
      </div>
    </div>
  ))

  useEffect(
    () => () => {
      compact(files).forEach((file) =>
        URL.revokeObjectURL(file.preview),
      )
    },
    [files],
  )

  useEffect(() => {
    setFiles([props.session.me.userImage])
  }, [props.session.me.userImage])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const role = find(
    props.session.me.roles,
    (role) =>
      role.organization._id === props.session.me.activeOrg._id,
  )

  return (
    <>
      <Tooltip title="User Actions">
        {/* <Button
        variant="text"
        onClick={handleClick}
        className="btn-transition-none text-left ml-2 mr-2 p-0 bg-transparent d-flex align-items-center"
        disableRipple
      >
        <div className="d-flex align-items-center justify-content-between py-2">
          <div className="d-flex align-items-center">
            <div className="d-block p-0 avatar-icon-wrapper mr-2">
              <div className="avatar-icon rounded">
                <img src={compact(files).length > 0 ? compact(files)[0].url : HousefoliosUser} alt="..." />
              </div>
            </div>
            {hiddenXsDown? null:
            
            <div>
              <a href="#/" onClick={e => e.preventDefault()} className="font-weight-bold" title="...">{props.session.me.profile.firstname} {props.session.me.profile.lastname}</a>
              <span className="text-black-50 d-block">{role.organization.name} Org.</span>
            </div>
            }
          </div>
          <div className="d-flex align-items-center">
            <span className="pl-1 pl-xl-3">
              <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
            </span>
          </div>
        </div>
      </Button> */}
        <Button onClick={handleClick} disableRipple>
          <div className="d-block p-0 avatar-icon-wrapper">
            <div className="avatar-icon rounded d-flex justify-content-center align-items-center">
              <div
                className="blur-load"
                style={{
                  backgroundImage:
                    'url(' + compact(files).length > 0
                      ? compact(files)[0].url_small
                      : HousefoliosUser + ')',
                }}
              >
                <img
                  src={
                    compact(files).length > 0
                      ? compact(files)[0].url
                      : HousefoliosUser
                  }
                  alt="..."
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="d-none d-xl-block pl-2">
            <div className="font-weight-bold text-black pt-2 line-height-1">
              {props.session.me.profile.firstname}{' '}
              {props.session.me.profile.lastname}
            </div>
            <span className="text-black-50">
              {role ? role.role[0] : ''}
            </span>
          </div>
          <span className="pl-1 pl-xl-3">
            <FontAwesomeIcon
              icon={['fas', 'angle-down']}
              className="opacity-5"
            />
          </span>
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        // Here you can add specifics to control the positioning
        //classes={{ list: 'p-0' }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        // slotProps={{
        //   paper: {
        //     style: {
        //       maxHeight: 600,
        //       width: '100%',
        //       maxWidth: 400,
        //       overflow: 'hidden',
        //     },
        //   },
        // }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ margin: 10 }}
        >
          <div className="py-4 d-flex align-items-center justify-content-center">
            <div className="dropzone rounded">
              <div
                {...getRootProps({
                  className: 'dropzone-upload-wrapper',
                })}
              >
                <input {...getInputProps()} />
                <div className="dropzone-inner-wrapper d-140 rounded dropzone-avatar">
                  <div className="avatar-icon-wrapper d-140 rounded m-2">
                    <Tooltip title="Upload Personal Image">
                      <Button
                        onClick={open}
                        className="avatar-button badge shadow-sm btn-icon badge-position badge-position--bottom-right border-0 text-indent-0 d-40 badge-circle btn-first text-white"
                      >
                        <CameraAltTwoToneIcon className="d-20" />
                      </Button>
                    </Tooltip>

                    <div>
                      {isDragAccept && (
                        <div className="rounded overflow-hidden d-140 bg-success text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                          <CheckIcon className="d-40" />
                        </div>
                      )}
                      {isDragReject && (
                        <div className="rounded overflow-hidden d-140 bg-danger text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                          <CloseTwoToneIcon className="d-60" />
                        </div>
                      )}
                      {!isDragActive && (
                        <div className="rounded overflow-hidden d-140 bg-neutral-dark text-center font-weight-bold text-black-50 d-flex justify-content-center align-items-center">
                          {/* <FontAwesomeIcon
                          icon={['fas', 'building']}
                          className="d-100"
                        /> */}
                          <img
                            src={HousefoliosUser}
                            className="d-140"
                            alt="..."
                          />
                        </div>
                      )}
                    </div>

                    {thumbs.length > 0 && <div>{thumbs}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Grid item>
            <Typography style={{ fontWeight: 600 }}>
              {props.session.me.profile.firstname}{' '}
              {props.session.me.profile.lastname}
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ marginBottom: 10, padding: 10 }}>
              {props.session.me.email || props.session.me.username}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              wrap="nowrap"
            >
              <Grid item>
                <Tooltip title="Personal Page">
                  <Button
                    href="/settings/personal"
                    style={{ backgroundColor: '#358cff', width: 50 }}
                  >
                    <FontAwesomeIcon
                      style={{ color: 'white' }}
                      icon={['far', 'user']}
                      size="2x"
                    />
                  </Button>
                </Tooltip>
              </Grid>
              {!props?.session?.me?.activeOrg?.isRealeflow && (
                <Grid item>
                  <Tooltip title="Billing Page">
                    <Button
                      href="/settings/billing"
                      style={{
                        backgroundColor: '#e5474c',
                        width: 50,
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: 'white' }}
                        icon={['far', 'file-invoice-dollar']}
                        size="2x"
                      />
                    </Button>
                  </Tooltip>
                </Grid>
              )}

              <Grid item>
                <ApolloConsumer>
                  {(client) => (
                    <Tooltip title="Log Out">
                      <Button
                        onClick={() =>
                          signOut(
                            client,
                            props?.session?.me?.activeOrg?.member,
                          )
                        }
                        style={{
                          backgroundColor: '#797d95',
                          width: 50,
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: 'white' }}
                          icon={['far', 'right-from-bracket']}
                          size="2x"
                        />
                      </Button>
                    </Tooltip>
                  )}
                </ApolloConsumer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <div
          onKeyDown={(event) => event.stopPropagation()}
          className="scroll-area-sm shadow-overflow"
        >
          <PerfectScrollbar>
            <List component="div" className="list-group-flush">
              {compact(
                map(props.session.me.roles, (roleOrg) => {
                  if (
                    roleOrg.organization &&
                    roleOrg.role[0] !== 'deactive' &&
                    roleOrg.organization._id !== 'superAdmin'
                  ) {
                    if (
                      roleOrg.organization._id ===
                      props.session.me.activeOrg._id
                    )
                      return (
                        <ListItem
                          component="a"
                          key={roleOrg.organization}
                          button
                          href="/settings/organization"
                          disableRipple
                          className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                        >
                          <div className="d-flex align-items-center">
                            <div className="avatar-icon-wrapper d-50 mr-3">
                              <IconButton
                                style={{ backgroundColor: '#28b854' }}
                                size="large"
                              >
                                <FontAwesomeIcon
                                  icon={['far', 'building']}
                                  style={{ color: 'white' }}
                                  // size="md"
                                />
                              </IconButton>
                            </div>
                            <div>
                              <div className="font-weight-bold font-size-sm text-black">
                                {roleOrg.organization.name}
                              </div>
                              <div className="d-flex align-items-center font-size-xs">
                                <div className="badge badge-success badge-circle border-white border-1 mr-2">
                                  Completed
                                </div>
                                <div className="text-success">
                                  Selected
                                </div>
                              </div>
                            </div>
                          </div>
                        </ListItem>
                      )
                    else
                      return (
                        <ListItem
                          component="a"
                          key={roleOrg.organization._id}
                          button
                          href={null}
                          disableRipple
                          onClick={(e) => {
                            const preventRefresh = (e) => {
                              // Cancel the event
                              e.preventDefault()
                              // Chrome requires returnValue to be set
                              e.returnValue =
                                'Refreshing right now could result in lost data. Do you wish to continue?'
                            }
                            window.addEventListener(
                              'beforeunload',
                              preventRefresh,
                            )
                            updateUser({
                              variables: {
                                userInput: {
                                  organization:
                                    roleOrg.organization._id,
                                },
                              },
                              refetchQueries: [
                                {
                                  query: GET_ME,
                                },
                                {
                                  query: GET_PORTFOLIOS,
                                },
                              ],
                            }).then(({ data }) => {
                              localStorage.setItem(
                                'token',
                                data.updateUser.token,
                              )
                              window.removeEventListener(
                                'beforeunload',
                                preventRefresh,
                              )
                              navigate(
                                get(
                                  props.session.me,
                                  'preferences.general.defaultPage.value',
                                ) ||
                                  get(
                                    roleOrg,
                                    'organization.preferences.general.defaultPage.value',
                                  ) ||
                                  '/launchpad',
                              )
                              handleClose()
                              window.location.reload()
                            })
                          }}
                          className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                        >
                          <div className="d-flex align-items-center">
                            <div className="avatar-icon-wrapper d-50 mr-3">
                              <IconButton
                                style={{ backgroundColor: '#797d95' }}
                                size="large"
                              >
                                <FontAwesomeIcon
                                  icon={['far', 'building']}
                                  style={{ color: 'white' }}
                                  // size="md"
                                />
                              </IconButton>
                            </div>
                            <div>
                              <div className="font-weight-bold font-size-sm text-black">
                                {roleOrg.organization.name}
                              </div>
                              <div className="d-flex align-items-center font-size-xs">
                                <div
                                  style={{
                                    backgroundColor: '#797d95',
                                  }}
                                  className="badge badge-circle border-white border-1 mr-2"
                                >
                                  Completed
                                </div>
                                <div style={{ color: '#797d95' }}>
                                  Not Selected
                                </div>
                              </div>
                            </div>
                          </div>
                        </ListItem>
                      )
                  }
                }),
              )}
              {Boolean(anchorEl) && <CreateNewOrg listItem />}
              {/* <ListItem
              component="a"
              button
              href="#/"
              disableRipple
              onClick={(e) => e.preventDefault()}
              className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3">
              <div className="d-flex align-items-center">
                <div className="avatar-icon-wrapper d-50 mr-3">
                  <div className="avatar-icon rounded-circle d-50">
                    <IconButton style={{ backgroundColor: 'black' }}>
                      <AddIcon style={{ color: 'white' }} />
                    </IconButton>
                  </div>
                </div>
                <div>
                  <div className="font-weight-bold font-size-sm text-black">
                    Create New Organization
                  </div>
                  <div className="d-flex align-items-center font-size-xs">
                    <div>Manage multiple orgs in 1 account</div>
                  </div>
                </div>
              </div>
            </ListItem> */}
            </List>
          </PerfectScrollbar>
        </div>
        {/* <div className="card-footer d-flex justify-content-between">
          <Button size="small" className="btn-neutral-success">
            Action
          </Button>
          <Button
            variant="text"
            size="small"
            className="btn-link btn-transparent btn-link-success px-0">
            <span>View details</span>
          </Button>
        </div> */}
        {/* </div> */}
      </Menu>
    </>
  )
}

export default withSession(HeaderUserbox)
