import { gql } from '@apollo/client'

export const CREATE_ORGANIZATION = gql`
  mutation createOrganization(
    $organizationInput: organizationInput!
    $freeAccount: Boolean
  ) {
    createOrganization(
      organizationInput: $organizationInput
      freeAccount: $freeAccount
    ) {
      user {
        _id
        email
        username
        profile {
          firstname
          lastname
        }
        preferences
        roles {
          organization {
            _id
            name
          }
          role
        }
        userType
        jobTitle
        bioDescription
        activeOrg {
          _id
          name
        }
        setup
      }
      token
      orgId
    }
  }
`

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization(
    $id: ID!
    $organizationInput: organizationInput!
  ) {
    updateOrganization(
      id: $id
      organizationInput: $organizationInput
    ) {
      _id
      meetingUrl
      name
      address
      city
      state
      zip
      phone
      email
      website
      legalDescription
      CMALegalDescription
      logo {
        url
        url_small
      }
      MLSData {
        MLS
        STATE
        MLSGrid
        username
        password
      }
    }
  }
`

export const CREATE_PARTNER_ORGANIZATION = gql`
  mutation createPartnerOrg($userInput: userInput!) {
    createPartnerOrg(userInput: $userInput) {
      _id
      email
      username
      profile {
        firstname
        lastname
      }
      preferences
      roles {
        organization {
          _id
          name
        }
        role
      }
      userType
      jobTitle
      bioDescription
      activeOrg {
        _id
        name
      }
      setup
    }
  }
`

export const CANCEL_PARTNER_ORGANIZATION = gql`
  mutation cancelPartnerOrg($orgId: ID!) {
    cancelPartnerOrg(orgId: $orgId) {
      _id
      meetingUrl
      name
      address
      city
      state
      zip
      phone
      email
      website
      legalDescription
      CMALegalDescription
      logo {
        url
        url_small
      }
      MLSData {
        MLS
        STATE
        MLSGrid
        username
        password
      }
    }
  }
`
