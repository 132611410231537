import { gql } from '@apollo/client'

export const GET_TRANSACTIONS = gql`
  query transactions {
    transactions {
      _id
      customer
      amount
      amount_refunded
      description
      invoice
      payment_intent
      created
      organizationId
      orgName
    }
  }
`
