import React, { useState } from 'react'

import { connect } from 'react-redux'

import {
  Button,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  Menu,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { ArrowBack } from '@mui/icons-material'

import {
  setSidebarToggleMobile,
  setNotificationsDrawerToggle,
} from '../../reducers/ThemeOptions'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import withSession from '@/housefolios-components/Session/withSession'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

// const drawerWidth = 540;
// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//   },
// }));

const Header = (props) => {
  // const classes = useStyles()
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    subdomainParam,
    orgName,
    marketplace,
  } = props

  const hiddenMdDown = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  )
  const hiddenMdUp = useMediaQuery((theme) =>
    theme.breakpoints.up('md'),
  )

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile)
  }

  let navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  const [anchorElMainMenu, setAnchorElMainMenu] = useState(null)
  const handleClickMainMenu = (event) => {
    setAnchorElMainMenu(event.currentTarget)
  }
  const handleCloseMainMenu = () => {
    setAnchorElMainMenu(null)
  }

  return (
    // <header
    //   className={clsx('app-header', 'masthead')} style={{ left: 0 }}>
    //   <div className="app-header--pane">
    //     {/* <button
    //       className={clsx(
    //         'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
    //         { 'is-active': sidebarToggleMobile }
    //       )}
    //       onClick={toggleSidebarMobile}>
    //       <span className="hamburger-box">
    //         <span className="hamburger-inner" />
    //       </span>
    //     </button> */}
    //     {/* {props.collapsed && ( */}
    //       {/* <Tooltip title="Previous Page">
    //         <IconButton onClick={goBack}>
    //           <KeyboardBackspaceOutlinedIcon />
    //         </IconButton>
    //       </Tooltip> */}
    //     {/* )} */}
    //     {/* <HeaderSearch /> */}
    //     {/* <HeaderMenu /> */}
    //   </div>
    //   <div className="app-header--pane">
    //     <Grid container direction="row" className="nav-pills nav-neutral-primary" spacing={2}>
    //       {/* <Grid item key="Navigation" className="d-flex px-0 pt-1 pb-1 justify-content-between">
    //         <Grid container direction='row' alignItems='center' justifyContent='space-between' wrap='nowrap'>
    //           <Grid item>
    //             <Typography className="text-uppercase font-size-sm text-primary font-weight-bold">Navigation</Typography>
    //           </Grid>
    //         </Grid>
    //       </Grid> */}
    //       <Grid item key="Home">
    //         <Button component="a" href={"/" + subdomainParam} style={{ color: 'white', borderBottom: window.location.pathname === '/' ? 'solid' : '' }}>
    //           Home
    //         </Button>
    //       </Grid>
    //       <Grid item key="Properties">
    //         <Button component="a" href={"/properties" + subdomainParam} style={{ color: 'white', borderBottom: window.location.pathname === '/properties' ? 'solid' : '' }}>
    //           Properties
    //         </Button>
    //       </Grid>
    //       <Grid item key="Sold Properties">
    //         <Button component="a" href={"/soldproperties" + subdomainParam} style={{ color: 'white', borderBottom: window.location.pathname === '/soldproperties' ? 'solid' : '' }}>
    //           Sold Properties
    //         </Button>
    //       </Grid>
    //       {props.marketplace && (
    //         <Grid item key="Team">
    //           <Button component="a" href={"/team" + subdomainParam} style={{ color: 'white', borderBottom: window.location.pathname === '/team' ? 'solid' : '' }}>
    //             Team
    //           </Button>
    //         </Grid>
    //       )}
    //       {/* {props.marketplace && (
    //         <Grid item key="Blog">
    //           <Button component="a" href={null} style={{ color: 'white' }}>
    //             Blog
    //           </Button>
    //         </Grid>
    //       )} */}
    //       <Grid item key="Contact Us">
    //         <Button component="a" href={null} onClick={null} style={{ color: 'white' }}>
    //           Contact Us
    //         </Button>
    //       </Grid>
    //     </Grid>
    //     {/* <HeaderDrawer /> */}
    //   </div>
    //   <container style={{ textAlign: 'center' }}>
    //     <div className="intro-text">
    //       <div className="intro-lead-in">{orgName ? orgName : 'Housefolios'} Marketplace</div>
    //       <div className="intro-heading text-uppercase">Buy Properties Today!</div>
    //     </div>
    //   </container>
    // </header>
    <div id="wrapper">
      <header
        id="js-header"
        className={
          window.location.pathname === '/'
            ? 'masthead'
            : 'masthead smaller'
        }
      >
        <div className="container clearfix">
          <Grid
            container
            direction="row"
            className="nav-pills nav-neutral-primary"
            spacing={1}
            justifyContent="flex-end"
            style={{ marginTop: 5 }}
          >
            <Grid item className="gridItem2">
              <Tooltip title="Previous Page">
                <IconButton onClick={goBack} size="large">
                  <KeyboardBackspaceOutlinedIcon
                    style={{ color: 'white' }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Typography style={{ flex: 1 }} />
            {hiddenMdDown ? null : (
              <>
                <Grid item key="Home">
                  <Button
                    component="a"
                    href={
                      marketplace?.url ===
                      'nlinvesting.housefolios.com'
                        ? 'https://nextlevelpropertyinvesting.com/home/'
                        : '/' + subdomainParam
                    }
                    style={{
                      color: 'white',
                      borderBottom:
                        window.location.pathname === '/'
                          ? 'solid'
                          : '',
                      borderRadius: 0,
                      fontSize: 18,
                    }}
                  >
                    Home
                  </Button>
                </Grid>
                <Grid item key="Properties">
                  <Button
                    component="a"
                    href={'/properties' + subdomainParam}
                    style={{
                      color: 'white',
                      borderBottom:
                        window.location.pathname === '/properties'
                          ? 'solid'
                          : '',
                      borderRadius: 0,
                      fontSize: 18,
                    }}
                  >
                    View Properties
                  </Button>
                </Grid>
                <Grid item key="Sold Properties">
                  <Button
                    component="a"
                    href={'/soldproperties' + subdomainParam}
                    style={{
                      color: 'white',
                      borderBottom:
                        window.location.pathname === '/soldproperties'
                          ? 'solid'
                          : '',
                      borderRadius: 0,
                      fontSize: 18,
                    }}
                  >
                    Sold Properties
                  </Button>
                </Grid>
                {props.marketplace && (
                  <Grid item key="Team">
                    <Button
                      component="a"
                      href={'/team' + subdomainParam}
                      style={{
                        color: 'white',
                        borderBottom:
                          window.location.pathname === '/team'
                            ? 'solid'
                            : '',
                        borderRadius: 0,
                        fontSize: 18,
                      }}
                    >
                      Team
                    </Button>
                  </Grid>
                )}
                {/* {props.marketplace && (
                <Grid item key="Blog">
                  <Button component="a" href={null} style={{ color: 'white' }}>
                    Blog
                  </Button>   
                </Grid>
              )} */}
                <Grid item key="Contact Us">
                  <Button
                    component="a"
                    href={
                      marketplace?.url ===
                      'nlinvesting.housefolios.com'
                        ? 'https://nextlevelpropertyinvesting.com/contact-us/'
                        : '/' + subdomainParam + '#contactUs'
                    }
                    style={{
                      color: 'white',
                      borderRadius: 0,
                      fontSize: 18,
                    }}
                  >
                    Contact Us
                  </Button>
                </Grid>
              </>
            )}
            {hiddenMdUp ? null : (
              <>
                <Grid item className="gridItem2">
                  <IconButton
                    onClick={handleClickMainMenu}
                    style={{ marginRight: 10 }}
                    size="large"
                  >
                    <FontAwesomeIcon
                      icon={['fa', 'bars']}
                      style={{ color: 'white' }}
                    />
                  </IconButton>
                </Grid>
                <Menu
                  anchorEl={anchorElMainMenu}
                  keepMounted
                  open={Boolean(anchorElMainMenu)}
                  onClose={handleCloseMainMenu}
                  classes={{ list: 'p-0' }}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div className="dropdown-menu-xl">
                    <List
                      component="div"
                      className="nav-list-square nav-neutral-primary"
                    >
                      <ListItem
                        button
                        component="a"
                        href={
                          marketplace?.url ===
                          'nlinvesting.housefolios.com'
                            ? 'https://nextlevelpropertyinvesting.com/home/'
                            : '/' + subdomainParam
                        }
                      >
                        <span>Home</span>
                      </ListItem>
                      <ListItem
                        button
                        component="a"
                        href={'/properties' + subdomainParam}
                      >
                        <span>View Properties</span>
                      </ListItem>
                      <ListItem
                        button
                        component="a"
                        href={'/soldproperties' + subdomainParam}
                      >
                        <span>Sold Properties</span>
                      </ListItem>
                      <ListItem
                        button
                        component="a"
                        href={'/team' + subdomainParam}
                      >
                        <span>Team</span>
                      </ListItem>
                      <ListItem
                        button
                        component="a"
                        href={
                          marketplace?.url ===
                          'nlinvesting.housefolios.com'
                            ? 'https://nextlevelpropertyinvesting.com/contact-us/'
                            : '/' + subdomainParam + '#contactUs'
                        }
                        onClick={null}
                      >
                        <span>Contact Us</span>
                      </ListItem>
                    </List>
                  </div>
                </Menu>
              </>
            )}
          </Grid>
        </div>
        <container style={{ textAlign: 'center' }}>
          <div className="intro-text">
            <div className="intro-lead-in">
              {marketplace ? marketplace.name : 'Housefolios'}{' '}
              Marketplace
            </div>
            <div className="intro-heading text-uppercase">
              Buy Properties Today!
            </div>
          </div>
        </container>
      </header>
    </div>
  )
}

const mapStateToProps = (state) => ({
  headerShadow: state.DeprecatedThemeOptions.headerShadow,
  headerBgTransparent:
    state.DeprecatedThemeOptions.headerBgTransparent,
  sidebarToggleMobile:
    state.DeprecatedThemeOptions.sidebarToggleMobile,
  notificationsDrawerToggle:
    state.DeprecatedThemeOptions.notificationsDrawerToggle,
})

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) =>
    dispatch(setSidebarToggleMobile(enable)),
  setNotificationsDrawerToggle: (enable) =>
    dispatch(setNotificationsDrawerToggle(enable)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSession(Header))
