import * as React from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material'

function SelectAllPropertiesToggleCheckbox({ children, ...props }) {
  const [alignment, setAlignment] = React.useState('current')

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment)
  }

  const SelectLabel = () => {
    return (
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        className="mr-3"
      >
        <ToggleButton value="all">
          All ({props?.allPages})
        </ToggleButton>
        <ToggleButton value="current">
          Page ({props?.currentpage})
        </ToggleButton>
      </ToggleButtonGroup>
    )
  }

  return (
    <Grid item>
      {/* <Grid container alignItems='center'>
    <Grid item>
      <Checkbox checked={compare.length > 0} indeterminate={compare.length < filter(filteredProperties, property => get(property, 'portfolio.organization._id') !== props.session.me.activeOrg._id).length && compare.length > 0} onClick={selectAll} />
    </Grid>
    <Grid item>
      <Typography>Select All ({props.marketplace ? filter(filteredProperties, property => get(property, 'portfolio.organization._id') !== props.session.me.activeOrg._id).length : filteredProperties.length})</Typography>
    </Grid>
  </Grid> */}

      {children(alignment, SelectLabel)}
    </Grid>
  )
}

export default SelectAllPropertiesToggleCheckbox
