/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2025-01-07 15:39:12
 * @ Description: Dialog for creating a new org
 */

import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import map from 'lodash/map'
import toLower from 'lodash/toLower'
import upperFirst from 'lodash/upperFirst'
import startCase from 'lodash/startCase'
import filter from 'lodash/filter'
import includes from 'lodash/includes'

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Step,
  Stepper,
  StepLabel,
  TextField,
  Typography,
  Radio,
  InputAdornment,
  CircularProgress,
  List,
  Checkbox,
  Menu,
  Switch,
  Select,
} from '@mui/material'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Check from '@mui/icons-material/Check'
import StepConnector from '@mui/material/StepConnector'
import AddIcon from '@mui/icons-material/Add'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import BusinessIcon from '@mui/icons-material/Business'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { useMutation, useQuery } from '@apollo/client'
import { Mutation, Query } from '@apollo/client/react/components'
import { GET_SUBSCRIPTION_PLAN } from '@/housefolios-components/CreateAccount/CreateAccountCard/queries'
import {
  CHECK_SIGN_UP,
  GET_COUPON,
} from '@/housefolios-components/CreateAccount/CreateAccountCard/mutations'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from '@/housefolios-components/CreateAccount/PaymentForm'
import {
  CREATE_ORGANIZATION,
  CREATE_PARTNER_ORGANIZATION,
} from '../mutations'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_ME } from '@/housefolios-components/Session/queries'

import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { enqueueSnackbar } from 'notistack'
import { Add, ArrowForward } from '@mui/icons-material'
import { UPDATE_USER } from '@/housefolios-components/PersonalSettings/mutations'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import useAffiliate from '@/hooks/useAffiliate'
import ContactDialog from '@/housefolios-components/Dialogs/ContactSupport'

function StepIcon(props) {
  const { active, completed } = props

  const icons = {
    1: <BusinessIcon />,
    2: <RadioButtonCheckedIcon />,
    3: <CreditCardIcon />,
  }

  return (
    <div
      className={clsx(
        'd-50 transition-base d-flex align-items-center bg-gray-400 justify-content-center rounded',
        {
          'd-80 bg-primary text-white shadow-primary-sm': active,
          'd-50 bg-success text-white shadow-success-sm': completed,
        },
      )}
    >
      {completed ? (
        <Check className="completed" />
      ) : (
        icons[String(props.icon)]
      )}
    </div>
  )
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}

function getSteps() {
  return ['Organization Details', 'Select Plan', 'Payment Details']
}

function CreateNewOrgComponent(props) {
  const [activeStep, setActiveStep] = useState(0)
  const [name, setName] = useState('')
  const [orgType, setOrgType] = useState('Personal Organization')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [selectedPlan, setSelectedPlan] = useState('')
  const [subscriptionPlan, setSubscriptionPlan] = useState('')
  const [planPrice, setPlanPrice] = useState(0)
  const [planBilling, setPlanBilling] = useState('')

  const [openContact, setOpenContact] = useState(false)

  const [{ affiliate, urlAffiliate }] = useAffiliate({
    memberValue: props?.session?.me?.activeOrg?.member,
  })

  const isCoached = includes(subscriptionPlan, 'coaching')

  let navigate = useNavigate()

  const [paymentIntervals, setPaymentIntervals] = useState(1)

  const [token, setToken] = useState('')
  const [recharge, setRecharge] = useState(false)
  // const [element, setElement] = useState(null)
  const [resetToken, setResetToken] = useState(() => {
    return null
  })
  const [coupon, setCoupon] = useState('')
  //const [couponValid, setCouponValid] = useState(null)
  const [couponPercent, setCouponPercent] = useState(null)
  const [couponAmount, setCouponAmount] = useState(null)
  const [circle, setCircle] = useState(false)

  const [newDefault, setNewDefault] = useState(false)

  const [createOrganization] = useMutation(CREATE_ORGANIZATION)
  const [createPartnerOrg] = useMutation(CREATE_PARTNER_ORGANIZATION)
  const [updateUser] = useMutation(UPDATE_USER)

  const [newOrgId, setNewOrgId] = useState(null)

  const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY
  const stripePromise = props.disabled
    ? null
    : loadStripe(stripePublicKey)

  const handleRechargeToggle = () => {
    setRecharge(!recharge)
    setToken('')
    resetToken()
  }

  const createToken = async (stripe, cardElement, nameOnCard) => {
    if (!nameOnCard) setToken('')
    else {
      await stripe
        .createToken(cardElement, {
          name: nameOnCard,
        })
        .then((res) => {
          var errorElement = document.getElementById('card-errors')
          if (res.error) {
            setToken('')
            if (errorElement)
              errorElement.textContent = res.error.message
          } else {
            setToken(res.token)
            if (errorElement) errorElement.textContent = null
          }
        })
    }
  }

  const [getCoupon] = useMutation(GET_COUPON)

  useEffect(() => {
    if (includes(subscriptionPlan, 'group-coaching')) {
      let applyCoupon = ''
      if (paymentIntervals == 1) {
        applyCoupon = 'GTzjUUmX'
      } else if (paymentIntervals == 2) {
        applyCoupon = 'CxxvDO0k'
      } else if (paymentIntervals == 3) {
        applyCoupon = 'arM9K3su'
      }
      setCoupon(applyCoupon)
      checkCoupon(applyCoupon)
    } else {
      setCoupon('')
      checkCoupon('')
    }
  }, [subscriptionPlan, paymentIntervals])

  const checkCoupon = async (coupon) => {
    //if (!coupon) setCouponValid(null)
    if (coupon)
      await getCoupon({
        variables: {
          code: coupon,
        },
      })
        .then(async ({ data }) => {
          if (
            !data.getCoupon.maxRedemptions ||
            data.getCoupon.maxRedemptions >
              data.getCoupon.timesRedeemed
          ) {
            //setCouponValid(true)
            setCouponPercent(data.getCoupon.percentOff)
            setCouponAmount(data.getCoupon.amountOff)
          } else {
            enqueueSnackbar(`Promo Code not valid`, {
              variant: 'error',
              autoHideDuration: 3000,
            })
            //setCouponValid(false)
          }
        })
        .catch(async ({ error }) => {
          enqueueSnackbar(`Promo Code not valid`, {
            variant: 'error',
            autoHideDuration: 3000,
          })
          //setCouponValid(false)
          setCoupon('')
        })
    else {
      setCoupon('')
      setCouponPercent('')
      setCouponAmount('')
    }
  }

  const steps = getSteps()

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  let entryPlanId = ''
  let entrySubscriptionPlan = {}
  const entryPlanResults = useQuery(GET_SUBSCRIPTION_PLAN, {
    variables: {
      subscriptionPlan:
        `${subscriptionPlan}-entry-fee` +
        (paymentIntervals > 1 ? `-${paymentIntervals}-payments` : ''),
    },
    errorPolicy: 'ignore',
  })
  if (isCoached) {
    const entryData = (entryPlanResults || {}).data || {}
    entrySubscriptionPlan = entryData.subscriptionPlan || {}
    entryPlanId = entrySubscriptionPlan.planId
  }

  const handleAdd = () => {
    if (!token && !recharge) return
    setCircle(true)
    createOrganization({
      variables: {
        organizationInput: {
          name: name,
          email: toLower(props.session.me.email),
          stripeToken: token,
          stripePlanId: selectedPlan,
          coupon,
          stripeEntryPlanId: entryPlanId,
          paymentIntervals: isCoached ? paymentIntervals : 0,
        },
      },
      refetchQueries: [
        {
          query: GET_ME,
        },
      ],
    })
      .then(({ data }) => {
        ReactPixel.track('StartTrial', {
          value: planPrice,
          currency: 'USD',
          predicted_ltv: planPrice * 6,
        })
        ReactGA.plugin.require('ecommerce')
        ReactGA.plugin.execute('ecommerce', 'addItem', {
          id: selectedPlan,
          name: upperFirst(subscriptionPlan),
          sku: selectedPlan,
          price: planPrice,
          quantity: 1,
          currency: 'USD',
        })
        ReactGA.plugin.execute('ecommerce', 'addTransaction', {
          id: Math.random(),
          revenue: planPrice,
          total: planPrice,
          shipping: 0,
          tax: 0,
          currency: 'USD',
        })
        ReactGA.plugin.execute('ecommerce', 'send')
        ReactGA.plugin.execute('ecommerce', 'clear')
        localStorage.setItem('token', data.createOrganization.token)
        setNewOrgId(data.createOrganization.orgId)
        //if (props.history) props.history.push("/launchpad")
        //window.location.reload()
        handleNext()
        setCircle(false)
      })
      .catch((error) => {
        // if (element) element.clear()
        var errorElement = document.getElementById('card-errors')
        resetToken()
        if (error && errorElement) {
          errorElement.textContent =
            error.message.slice(0, 21) === 'GraphQL error: Error:'
              ? error.message.slice(21)
              : null
        }
        setCircle(false)
      })
  }

  const handleAddPartner = () => {
    setCircle(true)
    createPartnerOrg({
      variables: {
        userInput: {
          profile: {
            firstname: firstName,
            lastname: lastName,
          },
          email: toLower(email),
          // userType,
          organization: name,
          phone: phoneNumber,
          // address: billingAddress,
          // city,
          // state,
          // zip: postalCode,
          stripePlanId: selectedPlan,
          coupon,
        },
      },
      refetchQueries: [
        {
          query: GET_ME,
        },
      ],
    })
      .then(({ data }) => {
        ReactPixel.track('StartTrial', {
          value: planPrice,
          currency: 'USD',
          predicted_ltv: planPrice * 6,
        })
        ReactGA.plugin.require('ecommerce')
        ReactGA.plugin.execute('ecommerce', 'addItem', {
          id: selectedPlan,
          name: upperFirst(subscriptionPlan),
          sku: selectedPlan,
          price: planPrice,
          quantity: 1,
          currency: 'USD',
        })
        ReactGA.plugin.execute('ecommerce', 'addTransaction', {
          id: Math.random(),
          revenue: planPrice,
          total: planPrice,
          shipping: 0,
          tax: 0,
          currency: 'USD',
        })
        ReactGA.plugin.execute('ecommerce', 'send')
        ReactGA.plugin.execute('ecommerce', 'clear')
        // localStorage.setItem(
        //   'token',
        //   data.createOrganization.token,
        // )
        //if (props.history) props.history.push("/launchpad")
        //window.location.reload()
        handleCloseCreateNewDialog()
        setCircle(false)
      })
      .catch((error) => {
        console.log(error)
        // if (element) element.clear()
        setCircle(false)
      })
  }

  const [openCreateNewOrgDialog, setCreateNewOrgDialog] =
    useState(false)
  const handleOpenCreateNewDialog = () => {
    if (props.session?.me?.activeOrg?.isRealeflow) {
      setOpenContact(true)
    } else {
      setCreateNewOrgDialog(true)
    }
  }
  const handleCloseCreateNewDialog = () => {
    setCreateNewOrgDialog(false)
    handleReset()
    setName('')
    setOrgType('Personal Organization')
    setSelectedPlan('')
    setSubscriptionPlan('')
    setPlanPrice(0)
    setPlanBilling('')
    setToken('')
    //setElement(null)
    setCoupon('')
    //setCouponValid(null)
    setCouponPercent(null)
    setCouponAmount(null)
  }

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const currencyFormat2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const pricingPlans = props.session.me.activeOrg.currentlyFree
    ? [
        {
          nameMonthly: 'free-monthly',
          nameYearly: 'free-yearly',
          label: 'Free',
          subHeaderMonthly: '',
          subHeaderYearly: '',
          search: '*Nationwide property search',
          analyze: '*Analyze off-market properties',
          comp: '*Sold comp data',
        },
        {
          nameMonthly: 'investor-monthly',
          nameYearly: 'investor-yearly',
          label: 'Investor',
          subHeaderMonthly: 'Monthly Plan',
          subHeaderYearly: 'Annual Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
        },
        {
          nameMonthly: 'growth-monthly',
          nameYearly: 'growth-yearly',
          nameMonthlyPartner: 'partner-growth-monthly',
          partnerWarning:
            'Minimum total price of $250 for all partner orgs',
          label: 'Growth',
          subHeaderMonthly: 'Monthly Plan',
          subHeaderYearly: 'Annual Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
          //housefoliosMarketplace: 'List on Housefolios marketplace',
        },
        {
          nameMonthly: 'pro-monthly',
          nameYearly: 'pro-yearly',
          label: 'Pro',
          subHeaderMonthly: 'Monthly Plan',
          subHeaderYearly: 'Annual Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
          //housefoliosMarketplace: 'List on Housefolios marketplace',
        },
      ]
    : [
        {
          nameMonthly: 'investor-monthly',
          nameYearly: 'investor-yearly',
          label: 'Investor',
          subHeaderMonthly: 'Monthly Plan',
          subHeaderYearly: 'Annual Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
        },
        {
          nameMonthly: 'growth-monthly',
          nameYearly: 'growth-yearly',
          nameMonthlyPartner: 'partner-growth-monthly',
          partnerWarning:
            'Minimum total price of $250 for all partner orgs',
          label: 'Growth',
          subHeaderMonthly: 'Monthly Plan',
          subHeaderYearly: 'Annual Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
          //housefoliosMarketplace: 'List on Housefolios marketplace',
        },
        {
          nameMonthly: 'pro-monthly',
          nameYearly: 'pro-yearly',
          label: 'Pro',
          subHeaderMonthly: 'Monthly Plan',
          subHeaderYearly: 'Annual Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
          //housefoliosMarketplace: 'List on Housefolios marketplace',
        },
        {
          nameMonthly: 'group-coaching',
          label: 'Group Coaching',
          subHeaderMonthly: 'Plan',
          subHeaderYearly: 'Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
          groupCoaching: 'Weekly Group Coaching',
          //housefoliosMarketplace: 'List on Housefolios marketplace',
        },
        {
          nameMonthly: 'personalized-coaching',
          label: 'Personalized Coaching',
          subHeaderMonthly: 'Plan',
          subHeaderYearly: 'Plan',
          search: 'Nationwide property search',
          analyze: 'Analyze off-market properties',
          comp: 'Sold comp data',
          groupCoaching: 'Weekly Group Coaching',
          personalizedCoaching: 'Monthly One on One Coaching',
          //housefoliosMarketplace: 'List on Housefolios marketplace',
        },
      ]

  return (
    <>
      {props.orgSettings && (
        <MenuItem onClick={handleOpenCreateNewDialog}>
          <FontAwesomeIcon
            icon={['fas', 'plus']}
            className="font-size-lg"
            style={{ marginRight: 10, width: '1rem' }}
          />
          <Typography>Create Organization</Typography>
        </MenuItem>
      )}
      {props.listItem && (
        <ListItem
          component="a"
          key="newOrg"
          button
          disableRipple
          onClick={handleOpenCreateNewDialog}
          className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
        >
          <div className="d-flex align-items-center">
            <div className="avatar-icon-wrapper d-50 mr-3">
              <div className="avatar-icon rounded-circle d-50">
                <IconButton
                  style={{ backgroundColor: 'black' }}
                  size="large"
                >
                  <AddIcon style={{ color: 'white' }} />
                </IconButton>
              </div>
            </div>
            <div>
              <div className="font-weight-bold font-size-sm text-black">
                Create New Organization
              </div>
              <div className="d-flex align-items-center font-size-xs">
                <div>Manage multiple orgs in 1 account</div>
              </div>
            </div>
          </div>
        </ListItem>
      )}
      {props.headerDot && (
        <Grid item sm={6}>
          <Button
            onClick={handleOpenCreateNewDialog}
            style={{ textAlign: 'center' }}
            variant="text"
            disabled={props.disabled}
            className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"
          >
            <FontAwesomeIcon
              icon={[`far`, `building`]}
              className="h2 d-block mb-2 mx-auto mt-1 text-success"
            />
            <div className="font-weight-bold text-black">
              Organization
            </div>
            <div className="font-size-md mb-1 text-black-50">
              Starting new project?
            </div>
          </Button>
        </Grid>
      )}
      {props.title && (
        <Button
          variant="contained"
          className="btn-primary m-2 font-weight-bolder"
          onClick={handleOpenCreateNewDialog}
        >
          <Add style={{ color: 'white' }} />
          <span
            className="btn-wrapper--label"
            style={{ fontWeight: 600 }}
          >
            Create Organization
          </span>
        </Button>
      )}
      {props.deactive && (
        <Button
          href={null}
          onClick={handleOpenCreateNewDialog}
          size="large"
          className="btn-first d-block d-sm-inline-block"
        >
          <span className="btn-wrapper--label">Choose Plan</span>
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
          </span>
        </Button>
      )}

      <Dialog
        maxWidth="lg"
        open={openCreateNewOrgDialog}
        onClose={handleCloseCreateNewDialog}
        fullWidth
        onKeyDown={(event) => event.stopPropagation()}
      >
        <ValidatorForm
          style={{
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={
            activeStep === steps.length - 1
              ? orgType === 'Partner Organization'
                ? handleAddPartner
                : handleAdd
              : handleNext
          }
        >
          <BlockUi
            blocking={circle}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={
              <div className="text-primary">
                Creating Organization
              </div>
            }
          >
            <DialogTitle>Create New Organization</DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              {/* <Card className="card-box">
          <div className="card-header">
            <div className="card-header--title">
              <Typography variant='h6'>Create New Organization</Typography>
            </div>
          </div> */}
              <div>
                <div className="bg-secondary mb-3">
                  <Stepper
                    style={{ padding: 24 }}
                    className="stepper-horizontal-1"
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<StepConnector />}
                  >
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel StepIconComponent={StepIcon}>
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>

                {activeStep === steps.length ? (
                  <div className="text-center p-5">
                    {/* <div className="avatar-icon-wrapper rounded-circle m-0">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-warning text-warning m-0 d-130">
                    <FontAwesomeIcon icon={['far', 'lightbulb']} className="d-flex align-self-center display-3" />
                  </div>
                </div>
                <h4 className="font-weight-bold mt-4">You finished all steps!</h4>
                <p className="mb-0 font-size-lg text-muted">Customize your stepper fast and easy!</p>
                <div className="pt-4"> */}
                    {circle && <CircularProgress />}
                    {!circle && (
                      <div className="text-center p-5">
                        <div className="avatar-icon-wrapper rounded-circle m-0">
                          <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
                            <FontAwesomeIcon
                              icon={['far', 'check']}
                              className="d-flex align-self-center display-3"
                            />
                          </div>
                        </div>
                        <h4 className="font-weight-bold mt-4">
                          New organization created
                        </h4>
                        <p className="mb-0 font-size-lg text-muted">
                          You can switch between organizations by
                          clicking on your avatar at the top right
                        </p>
                        <div className="pt-4">
                          <Button
                            className="btn-success font-weight-bold rounded hover-scale-lg mx-1"
                            size="large"
                            endIcon={<ArrowForward />}
                            onClick={(e) => {
                              updateUser({
                                variables: {
                                  userInput: {
                                    organization: newOrgId,
                                    defaultOrg: newDefault
                                      ? newOrgId
                                      : null,
                                  },
                                },
                                refetchQueries: [
                                  {
                                    query: GET_ME,
                                  },
                                  {
                                    query: GET_PORTFOLIOS,
                                  },
                                ],
                              }).then(async ({ data }) => {
                                localStorage.setItem(
                                  'token',
                                  data.updateUser.token,
                                )
                                if (navigate) navigate('/launchpad')
                                window.location.reload()
                              })
                              e.preventDefault()
                            }}
                          >
                            <span className="btn-wrapper--label">
                              Go to new organization
                            </span>
                          </Button>
                        </div>
                        <div style={{ marginTop: 10 }}>
                          <FormControlLabel
                            onChange={() =>
                              setNewDefault(!newDefault)
                            }
                            control={
                              <Checkbox
                                checked={newDefault}
                                name="default"
                              />
                            }
                            label="Make New Org Your Default Org"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {activeStep === 0 && (
                      <Container>
                        <div className="p-4">
                          <h5 className="font-size-xl mb-1 font-weight-bold">
                            Organization Details
                          </h5>
                          <p className="text-black-50 mb-4">
                            Complete the fields below to setup your
                            new organization
                          </p>
                          <Grid container spacing={4}>
                            {props.session.me.activeOrg
                              .stripeCustomerID &&
                              props.session.me.activeOrg
                                .southernImpressions && (
                                <Grid item xs={12}>
                                  <TextField
                                    name="orgType"
                                    select
                                    fullWidth={true}
                                    variant="outlined"
                                    label="Organization Type"
                                    value={orgType}
                                    onChange={(event) => {
                                      setOrgType(event.target.value)
                                      setRecharge(false)
                                    }}
                                  >
                                    <MenuItem
                                      value="Personal Organization"
                                      key="Personal Organization"
                                    >
                                      Personal Organization
                                    </MenuItem>
                                    <MenuItem
                                      value="Partner Organization"
                                      key="Partner Organization"
                                    >
                                      Partner Organization
                                    </MenuItem>
                                  </TextField>
                                </Grid>
                              )}
                            <Grid item xs={12}>
                              <TextValidator
                                fullWidth
                                label="Organization Name*"
                                variant="outlined"
                                autoFocus
                                value={name}
                                onChange={(event) =>
                                  setName(event.target.value)
                                }
                                validators={[
                                  'required',
                                  'maxStringLength: 20',
                                ]}
                                errorMessages={[
                                  'This field is required',
                                  'You cannot exceed 20 characters',
                                ]}
                              />
                            </Grid>
                            {orgType === 'Partner Organization' && (
                              <>
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    direction="row"
                                    spacing={1}
                                  >
                                    <Grid item xs={12} sm={6}>
                                      <TextValidator
                                        fullWidth={true}
                                        variant="outlined"
                                        placeholder="First Name"
                                        label="First Name"
                                        value={firstName}
                                        onChange={(event) =>
                                          setFirstName(
                                            event.target.value,
                                          )
                                        }
                                        validators={[
                                          'required',
                                          'maxStringLength: 20',
                                          'matchRegexp:^[A-Z|a-z]+$',
                                        ]}
                                        errorMessages={[
                                          'This field is required',
                                          'You cannot exceed 20 characters',
                                          'This field only allows characters in the alphabet',
                                        ]}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextValidator
                                        fullWidth={true}
                                        variant="outlined"
                                        placeholder="Last Name"
                                        label="Last Name"
                                        value={lastName}
                                        onChange={(event) =>
                                          setLastName(
                                            event.target.value,
                                          )
                                        }
                                        validators={[
                                          'required',
                                          'maxStringLength: 20',
                                          'matchRegexp:^[A-Z|a-z]+$',
                                        ]}
                                        errorMessages={[
                                          'This field is required',
                                          'You cannot exceed 20 characters',
                                          'This field only allows characters in the alphabet',
                                        ]}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextValidator
                                    fullWidth={true}
                                    variant="outlined"
                                    placeholder="Phone Number"
                                    label="Phone Number"
                                    value={phoneNumber}
                                    onChange={(event) =>
                                      setPhoneNumber(
                                        event.target.value,
                                      )
                                    }
                                    validators={[
                                      'required',
                                      //eslint-disable-next-line
                                      'matchRegexp:^[0-9()-]+$',
                                    ]}
                                    errorMessages={[
                                      'This field is required',
                                      'Not a valid phone number',
                                    ]}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextValidator
                                    fullWidth={true}
                                    variant="outlined"
                                    placeholder="Email Address"
                                    label="Email Address"
                                    value={email}
                                    onChange={(event) =>
                                      setEmail(event.target.value)
                                    }
                                    validators={[
                                      'required',
                                      'maxStringLength: 50',
                                      'isEmail',
                                    ]}
                                    errorMessages={[
                                      'This field is required',
                                      'You cannot exceed 50 characters',
                                      'Not a valid email address',
                                    ]}
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </div>
                      </Container>
                    )}
                    {activeStep === 1 && (
                      <Container>
                        <div className="p-4">
                          <h5 className="font-size-xl mb-1 font-weight-bold">
                            Monthly Plans
                          </h5>
                          <p className="text-black-50 mb-4">
                            Select a monthly plan or save 20% with
                            annual billing
                          </p>
                          <Grid container>
                            <Grid item xs={12}>
                              <div className="mb-spacing-6-x2">
                                {map(
                                  filter(pricingPlans, (plan) =>
                                    orgType === 'Partner Organization'
                                      ? plan.nameMonthlyPartner
                                      : plan.nameMonthly,
                                  ),
                                  (plan) => (
                                    <Query
                                      query={GET_SUBSCRIPTION_PLAN}
                                      variables={{
                                        subscriptionPlan:
                                          orgType ===
                                          'Partner Organization'
                                            ? plan.nameMonthlyPartner
                                            : plan.nameMonthly,
                                      }}
                                    >
                                      {({ loading, error, data }) => {
                                        if (loading)
                                          return 'Loading...'
                                        if (error)
                                          return `Error! ${error.message}`
                                        const { subscriptionPlan } =
                                          data
                                        return (
                                          <Accordion>
                                            <AccordionSummary
                                              expandIcon={
                                                <ExpandMoreIcon />
                                              }
                                              aria-label="Expand"
                                              aria-controls="additional-actions1-content"
                                              id="additional-actions1-header"
                                            >
                                              <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) =>
                                                  event.stopPropagation()
                                                }
                                                onFocus={(event) =>
                                                  event.stopPropagation()
                                                }
                                                control={
                                                  <Radio
                                                    checked={
                                                      selectedPlan ===
                                                      subscriptionPlan.planId
                                                    }
                                                    value={
                                                      subscriptionPlan.planId
                                                    }
                                                    onChange={(
                                                      event,
                                                    ) => {
                                                      setSelectedPlan(
                                                        event.target
                                                          .value,
                                                      )
                                                      setSubscriptionPlan(
                                                        plan.nameMonthly,
                                                      )
                                                      setPlanPrice(
                                                        subscriptionPlan.price /
                                                          100,
                                                      )
                                                      setPlanBilling(
                                                        'monthly',
                                                      )
                                                    }}
                                                    className="mr-2"
                                                  />
                                                }
                                                label={
                                                  <Typography
                                                    style={{
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    {plan.label} -{' '}
                                                    {(plan.personalizedCoaching ||
                                                      plan.groupCoaching) &&
                                                      `1x ${
                                                        plan.personalizedCoaching
                                                          ? 'Personal'
                                                          : 'Group'
                                                      } Coaching Fee then`}{' '}
                                                    {currencyFormat.format(
                                                      subscriptionPlan.price /
                                                        100,
                                                    )}
                                                    /mo
                                                  </Typography>
                                                }
                                              />
                                            </AccordionSummary>
                                            <List>
                                              {/* <ListItem>
                                            <Typography variant='h3' className='font-weight-bold' style={{ marginBottom: -25 }}>
                                              {currencyFormat.format(subscriptionPlan.price / 100)}
                                            </Typography>
                                          </ListItem>
                                          {plan.label !== 'Free' && (
                                            <ListItem>
                                              <Typography variant='subtitle1' style={{ paddingTop: 0 }}>
                                                {"PER MONTH"}
                                              </Typography>
                                            </ListItem>
                                          )} */}

                                              {plan.personalizedCoaching && (
                                                <ListItem>
                                                  <Typography color="textSecondary">
                                                    Monthly One on One
                                                    Coaching
                                                  </Typography>
                                                </ListItem>
                                              )}
                                              {plan.groupCoaching && (
                                                <ListItem>
                                                  <Typography color="textSecondary">
                                                    Weekly Group
                                                    Coaching
                                                  </Typography>
                                                </ListItem>
                                              )}
                                              {plan.label !==
                                              'Free' ? (
                                                <ListItem>
                                                  <Typography color="textSecondary">
                                                    Analyze{' '}
                                                    <b>
                                                      {numberFormat.format(
                                                        subscriptionPlan.propertyCount,
                                                      )}
                                                    </b>{' '}
                                                    Properties/mo
                                                  </Typography>
                                                </ListItem>
                                              ) : (
                                                <ListItem>
                                                  <Typography color="textSecondary">
                                                    *Analyze{' '}
                                                    <b>
                                                      {
                                                        plan.properties
                                                      }
                                                    </b>{' '}
                                                    Properties/mo
                                                  </Typography>
                                                </ListItem>
                                              )}
                                              <ListItem>
                                                <Typography color="textSecondary">
                                                  Create up to{' '}
                                                  <b>
                                                    {subscriptionPlan.porfolioCount ||
                                                      1}
                                                  </b>{' '}
                                                  portfolio
                                                </Typography>
                                              </ListItem>
                                              <ListItem>
                                                <Typography color="textSecondary">
                                                  Add{' '}
                                                  <b>
                                                    {subscriptionPlan.teamCount ||
                                                      0}
                                                  </b>{' '}
                                                  team members
                                                </Typography>
                                              </ListItem>
                                              <ListItem>
                                                <Typography color="textSecondary">
                                                  {plan.search}
                                                </Typography>
                                              </ListItem>
                                              <ListItem>
                                                <Typography color="textSecondary">
                                                  {plan.analyze}
                                                </Typography>
                                              </ListItem>
                                              <ListItem>
                                                <Typography color="textSecondary">
                                                  {plan.comp}
                                                </Typography>
                                              </ListItem>
                                              {plan.housefoliosMarketplace && (
                                                <ListItem>
                                                  <Typography color="textSecondary">
                                                    {
                                                      plan.housefoliosMarketplace
                                                    }
                                                  </Typography>
                                                </ListItem>
                                              )}
                                              {subscriptionPlan.marketplaceCount >
                                                0 &&
                                                subscriptionPlan.marketplaceCount && (
                                                  <ListItem>
                                                    <Typography color="textSecondary">
                                                      Create{' '}
                                                      <b>
                                                        {
                                                          subscriptionPlan.marketplaceCount
                                                        }
                                                      </b>{' '}
                                                      Marketplace
                                                      websites
                                                    </Typography>
                                                  </ListItem>
                                                )}
                                              {orgType ===
                                                'Partner Organization' &&
                                                plan.partnerWarning && (
                                                  <ListItem>
                                                    <Typography color="textSecondary">
                                                      {
                                                        plan.partnerWarning
                                                      }
                                                    </Typography>
                                                  </ListItem>
                                                )}
                                            </List>
                                          </Accordion>
                                        )
                                      }}
                                    </Query>
                                  ),
                                )}
                              </div>
                            </Grid>
                          </Grid>

                          <h5 className="font-size-xl mb-1 font-weight-bold">
                            Save with Annual Plans
                          </h5>
                          <p className="text-black-50 mb-4">
                            Save 20% with annual billing
                          </p>
                          <Grid container>
                            <Grid item xs={12}>
                              <div className="mb-spacing-6-x2">
                                {map(
                                  filter(pricingPlans, (plan) =>
                                    orgType === 'Partner Organization'
                                      ? plan.nameYearlyPartner
                                      : plan.nameYearly,
                                  ),
                                  (plan) => (
                                    <Query
                                      query={GET_SUBSCRIPTION_PLAN}
                                      variables={{
                                        subscriptionPlan:
                                          plan.nameYearly,
                                      }}
                                    >
                                      {({ loading, error, data }) => {
                                        if (loading)
                                          return 'Loading...'
                                        if (error)
                                          return `Error! ${error.message}`
                                        const { subscriptionPlan } =
                                          data
                                        return (
                                          <Accordion>
                                            <AccordionSummary
                                              expandIcon={
                                                <ExpandMoreIcon />
                                              }
                                              aria-label="Expand"
                                              aria-controls="additional-actions1-content"
                                              id="additional-actions1-header"
                                            >
                                              <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) =>
                                                  event.stopPropagation()
                                                }
                                                onFocus={(event) =>
                                                  event.stopPropagation()
                                                }
                                                control={
                                                  <Radio
                                                    checked={
                                                      selectedPlan ===
                                                      subscriptionPlan.planId
                                                    }
                                                    value={
                                                      subscriptionPlan.planId
                                                    }
                                                    onChange={(
                                                      event,
                                                    ) => {
                                                      setSelectedPlan(
                                                        event.target
                                                          .value,
                                                      )
                                                      setSubscriptionPlan(
                                                        plan.nameYearly,
                                                      )
                                                      setPlanPrice(
                                                        subscriptionPlan.price /
                                                          100,
                                                      )
                                                      setPlanBilling(
                                                        'yearly',
                                                      )
                                                    }}
                                                    className="mr-2"
                                                  />
                                                }
                                                label={
                                                  <Typography
                                                    style={{
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    {plan.label} -{' '}
                                                    {currencyFormat.format(
                                                      subscriptionPlan.price /
                                                        100,
                                                    )}
                                                    /yr
                                                  </Typography>
                                                }
                                              />
                                            </AccordionSummary>
                                            <List>
                                              {/* <ListItem>
                                            <Typography variant='h3' className='font-weight-bold' style={{ marginBottom: -25 }}>
                                              {currencyFormat.format(subscriptionPlan.price / 100 / 12)}
                                            </Typography>
                                          </ListItem>
                                          {plan.label !== 'Free' && (
                                            <ListItem>
                                              <Typography variant='subtitle1' style={{ paddingTop: 0 }}>
                                              {`${currencyFormat.format(subscriptionPlan.price / 100)} PER YEAR`}
                                              </Typography>
                                            </ListItem>
                                          )} */}
                                              {plan.label !==
                                              'Free' ? (
                                                <ListItem>
                                                  <Typography color="textSecondary">
                                                    Analyze{' '}
                                                    <b>
                                                      {numberFormat.format(
                                                        subscriptionPlan.propertyCount,
                                                      )}
                                                    </b>{' '}
                                                    Properties/mo
                                                  </Typography>
                                                </ListItem>
                                              ) : (
                                                <ListItem>
                                                  <Typography color="textSecondary">
                                                    *Analyze{' '}
                                                    <b>
                                                      {
                                                        plan.properties
                                                      }
                                                    </b>{' '}
                                                    Properties/mo
                                                  </Typography>
                                                </ListItem>
                                              )}
                                              <ListItem>
                                                <Typography color="textSecondary">
                                                  Create up to{' '}
                                                  <b>
                                                    {subscriptionPlan.porfolioCount ||
                                                      1}
                                                  </b>{' '}
                                                  portfolio
                                                </Typography>
                                              </ListItem>
                                              <ListItem>
                                                <Typography color="textSecondary">
                                                  Add{' '}
                                                  <b>
                                                    {subscriptionPlan.teamCount ||
                                                      0}
                                                  </b>{' '}
                                                  team members
                                                </Typography>
                                              </ListItem>
                                              <ListItem>
                                                <Typography color="textSecondary">
                                                  {plan.search}
                                                </Typography>
                                              </ListItem>
                                              <ListItem>
                                                <Typography color="textSecondary">
                                                  {plan.analyze}
                                                </Typography>
                                              </ListItem>
                                              <ListItem>
                                                <Typography color="textSecondary">
                                                  {plan.comp}
                                                </Typography>
                                              </ListItem>
                                              {plan.housefoliosMarketplace && (
                                                <ListItem>
                                                  <Typography color="textSecondary">
                                                    {
                                                      plan.housefoliosMarketplace
                                                    }
                                                  </Typography>
                                                </ListItem>
                                              )}
                                              {subscriptionPlan.marketplaceCount >
                                                0 &&
                                                subscriptionPlan.marketplaceCount && (
                                                  <ListItem>
                                                    <Typography color="textSecondary">
                                                      Create{' '}
                                                      <b>
                                                        {
                                                          subscriptionPlan.marketplaceCount
                                                        }
                                                      </b>{' '}
                                                      Marketplace
                                                      websites
                                                    </Typography>
                                                  </ListItem>
                                                )}
                                            </List>
                                          </Accordion>
                                        )
                                      }}
                                    </Query>
                                  ),
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Container>
                    )}
                    {activeStep === 2 && (
                      <Container>
                        <div className="p-4">
                          <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            spacing={4}
                          >
                            {props.session.me.activeOrg
                              .stripeCustomerID &&
                              orgType !== 'Partner Organization' && (
                                <Grid
                                  item
                                  xs={12}
                                  style={{ width: '100%' }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    spacing={2}
                                    justifyContent="center"
                                    style={{ marginBottom: 10 }}
                                  >
                                    <Grid item>
                                      <Typography>
                                        New Card
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      style={{ marginTop: -3 }}
                                    >
                                      <Switch
                                        checked={recharge}
                                        onChange={
                                          handleRechargeToggle
                                        }
                                        color="primary"
                                        className="switch-medium"
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Typography>
                                        Existing Card
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            {!recharge &&
                              orgType !== 'Partner Organization' && (
                                <Grid item sm={6}>
                                  <Grid container direction="column">
                                    <Grid item>
                                      <h5 className="font-size-xl mb-1 font-weight-bold">
                                        Payment details
                                      </h5>
                                    </Grid>
                                    <Grid item>
                                      <p className="text-black-50 mb-4">
                                        Complete the fields below to
                                        setup your new organization
                                      </p>
                                    </Grid>
                                    <Grid item>
                                      <Grid
                                        container
                                        direction="column"
                                        spacing={2}
                                      >
                                        {!isCoached && (
                                          <Grid item xs={12}>
                                            <div
                                              className={clsx(
                                                'search-wrapper search-wrapper--grow',
                                                {
                                                  'is-active': true,
                                                },
                                              )}
                                              style={{
                                                width: '100%',
                                              }}
                                            >
                                              <TextField
                                                variant="outlined"
                                                placeholder="Add Promo Code"
                                                size="small"
                                                id="input-with-icon-textfield22-1"
                                                value={coupon}
                                                onChange={(event) =>
                                                  setCoupon(
                                                    event.target
                                                      .value,
                                                  )
                                                }
                                                fullWidth={true}
                                                style={{
                                                  width: '100%',
                                                }}
                                                disabled={includes(
                                                  subscriptionPlan,
                                                  'group-coaching',
                                                )}
                                                // onFocus={toggleSearch1}
                                                // onBlur={toggleSearch1}
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      {coupon && (
                                                        <Button
                                                          onClick={() =>
                                                            checkCoupon(
                                                              coupon,
                                                            )
                                                          }
                                                        >
                                                          Apply
                                                        </Button>
                                                      )}
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                // helperText={
                                                //   couponValid ===
                                                //   false ? (
                                                //     <Typography
                                                //       style={{
                                                //         color:
                                                //           'red',
                                                //         //marginLeft: -13,
                                                //         fontSize: 13,
                                                //         fontWeight: 400,
                                                //       }}
                                                //     >
                                                //       Coupon
                                                //       not
                                                //       valid
                                                //     </Typography>
                                                //   ) : null
                                                // }
                                              />
                                            </div>
                                          </Grid>
                                        )}
                                        {isCoached && (
                                          <Grid item>
                                            <FormControl
                                              fullWidth
                                              variant="outlined"
                                              margin="dense"
                                            >
                                              <InputLabel id="payment-plans-label">
                                                Payment Plans
                                              </InputLabel>
                                              <Select
                                                labelId="payment-plans-label"
                                                value={
                                                  paymentIntervals
                                                }
                                                onChange={(event) =>
                                                  setPaymentIntervals(
                                                    event.target
                                                      .value,
                                                  )
                                                }
                                                label="Payment Plans"
                                              >
                                                <MenuItem value={1}>
                                                  One Payment
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                  Two Payments
                                                </MenuItem>
                                                <MenuItem value={3}>
                                                  Three Payments
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                            {paymentIntervals > 1 && (
                                              <Typography
                                                style={{
                                                  marginLeft: 12,
                                                  fontSize: 13,
                                                }}
                                              >
                                                Selecting Two or Three
                                                Payments splits the
                                                coaching fee into
                                                multiple monthly
                                                payments. The next
                                                months payment will be
                                                the partial payment of
                                                the coaching fee plus
                                                the monthly
                                                subscription amount
                                              </Typography>
                                            )}
                                          </Grid>
                                        )}
                                        <Grid item xs={12}>
                                          <Elements
                                            stripe={stripePromise}
                                          >
                                            <PaymentForm
                                              createToken={
                                                createToken
                                              }
                                              //setElement={setElement}
                                              setResetToken={
                                                setResetToken
                                              }
                                              autoFocus
                                            />
                                          </Elements>
                                          <Typography
                                            style={{
                                              color: 'red',
                                              fontSize: 13,
                                              marginTop: 3,
                                              marginLeft: 12,
                                              fontWeight: 400,
                                            }}
                                            id="card-errors"
                                            role="alert"
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            <Grid item sm={6} className="d-flex">
                              <Grid container>
                                <Grid item xs={12}>
                                  <h5 className="font-size-xl mb-1 font-weight-bold">
                                    Purchase details
                                  </h5>
                                </Grid>
                                <Grid item>
                                  <p className="text-black-50 mb-4">
                                    Please review the purchase details
                                    below before pressing Finish
                                  </p>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container direction="column">
                                    <Grid item>
                                      <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <Typography>
                                            Organization Name
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography>
                                            {name}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <Typography>
                                            Plan Type
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          <Typography>
                                            {planBilling === 'yearly'
                                              ? 'Annual '
                                              : 'Monthly '}
                                            {
                                              startCase(
                                                subscriptionPlan,
                                              ).split(' ')[0]
                                            }
                                            {' Plan'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <Typography>
                                            Subtotal
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          {/* <Typography>{currencyFormat2.format(planPrice)}/{planBilling === 'yearly' ? 'yr' : 'mo'}</Typography> */}
                                          <Typography>
                                            {currencyFormat2.format(
                                              isCoached
                                                ? entrySubscriptionPlan.price /
                                                    100
                                                : planPrice,
                                            )}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    {(couponAmount ||
                                      couponPercent) && (
                                      <Grid item>
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Grid item>
                                            <Typography
                                              style={{
                                                color: '#46de64',
                                              }}
                                            >
                                              Promo Code
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography
                                              style={{
                                                color: '#46de64',
                                              }}
                                            >
                                              -
                                              {currencyFormat2.format(
                                                couponAmount
                                                  ? couponAmount / 100
                                                  : (couponPercent /
                                                      100) *
                                                      (isCoached
                                                        ? entrySubscriptionPlan.price /
                                                          100
                                                        : planPrice),
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    )}
                                    <Grid item>
                                      <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <Typography className="font-weight-bold">
                                            Total due today
                                          </Typography>
                                        </Grid>
                                        <Grid item>
                                          {/* <Typography className="font-weight-bold">{currencyFormat2.format(planPrice - (couponAmount ? couponAmount / 100 : (couponPercent / 100 * planPrice)))}/{planBilling === 'yearly' ? 'yr' : 'mo'}</Typography> */}
                                          <Typography className="font-weight-bold">
                                            {currencyFormat2.format(
                                              (isCoached
                                                ? entrySubscriptionPlan.price /
                                                  100
                                                : planPrice) -
                                                (couponAmount
                                                  ? couponAmount / 100
                                                  : (couponPercent /
                                                      100) *
                                                    (isCoached
                                                      ? entrySubscriptionPlan.price /
                                                        100
                                                      : planPrice)),
                                            )}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item>
                                      <Typography
                                        style={{ fontWeigh: 900 }}
                                      >
                                        Next payment due a{' '}
                                        {planBilling === 'monthly'
                                          ? 'month'
                                          : 'year'}{' '}
                                        from now.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Container>
                    )}
                  </div>
                  // </ValidatorForm>
                )}
              </div>
              {/* </Card> */}
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#f8f9fe' }}>
              {activeStep !== 0 && activeStep !== steps.length && (
                <Button
                  /*disabled={activeStep === 0}*/ className="btn-primary font-weight-bold"
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}
              {activeStep === 0 && (
                <Button
                  /*disabled={activeStep === 0}*/ className="btn-primary font-weight-bold"
                  onClick={handleCloseCreateNewDialog}
                >
                  Cancel
                </Button>
              )}
              {activeStep === steps.length && (
                <Button
                  /*disabled={activeStep === 0}*/ className="btn-primary font-weight-bold"
                  onClick={handleCloseCreateNewDialog}
                >
                  Close
                </Button>
              )}
              {activeStep !== steps.length && (
                <Button
                  className="btn-primary font-weight-bold"
                  disabled={
                    circle ||
                    (activeStep === steps.length - 1 &&
                      !token &&
                      !recharge &&
                      orgType !== 'Partner Organization') ||
                    (activeStep === steps.length - 2 && !selectedPlan)
                  }
                  type="submit"
                >
                  {activeStep === steps.length - 1
                    ? 'Finish'
                    : 'Next'}
                </Button>
              )}
            </DialogActions>
          </BlockUi>
        </ValidatorForm>
      </Dialog>

      <ContactDialog
        open={openContact}
        handleClose={() => setOpenContact(false)}
        affiliate={affiliate?.memberValue}
      />

      {/* <Alert
      icon={false}
      variant="filled"
      className="text-white mb-4"
      severity="success">
      This is a success alert - check it out!
    </Alert> */}
    </>
  )
}

export default withSession(CreateNewOrgComponent)
