import React from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PageTitle = (props) => {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleIconBox,
    pageTitleDescription,
    titleHeading,
    titleDescription,
    icon,
    style,
    children,
  } = props

  return (
    <>
      <div
        className={clsx(
          'app-page-title',
          pageTitleStyle,
          pageTitleBackground,
          {
            'app-page-title--shadow': pageTitleShadow,
          },
        )}
        style={style}
      >
        <div>
          <div className="app-page-title--first">
            {pageTitleIconBox && (
              <div className="app-page-title--iconbox d-70">
                <div className="d-70 d-flex align-items-center justify-content-center display-1">
                  {icon ? (
                    icon
                  ) : (
                    <FontAwesomeIcon
                      icon={['fad', 'rocket-launch']}
                      className="text-primary"
                      style={{ fontSize: 30 }}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="app-page-title--heading">
              <h1>{titleHeading}</h1>
              {pageTitleDescription && (
                <div className="app-page-title--description">
                  {titleDescription}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">{children}</div>
      </div>
      {/* <Notifications /> */}
    </>
  )
}

const mapStateToProps = (state) => ({
  pageTitleStyle: state.DeprecatedThemeOptions.pageTitleStyle,
  pageTitleBackground:
    state.DeprecatedThemeOptions.pageTitleBackground,
  pageTitleShadow: state.DeprecatedThemeOptions.pageTitleShadow,
  pageTitleIconBox: state.DeprecatedThemeOptions.pageTitleIconBox,
  pageTitleDescription:
    state.DeprecatedThemeOptions.pageTitleDescription,
})

export default connect(mapStateToProps)(PageTitle)
