import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Popper from '@mui/material/Popper'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { alpha, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import MenuItem from '@mui/material/MenuItem'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import { closeSnackbar, enqueueSnackbar } from 'notistack'

import Close from '@mui/icons-material/Close'
import Select from '../Select'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}))

const Header = ({
  portfolios = [],
  handlePorfolioChange,
  selectedPorfolio,
  disablePortfolio = false,
  searchWord,
  handleSearchChange,
  triggerPropertiesSort,
}) => {
  const [snackBarKey, setSnackBarKey] = useState(1)

  useEffect(() => {
    if (disablePortfolio)
      setSnackBarKey((key) =>
        Number(
          enqueueSnackbar(
            'Add a column to enable Portfolio selection',
            {
              variant: 'info',
              autoHideDuration: null,
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
              action: (key) => (
                <>
                  <IconButton
                    onClick={() => {
                      closeSnackbar(key)
                    }}
                    size="large"
                  >
                    <Close style={{ color: 'white' }} />
                  </IconButton>
                </>
              ),
            },
          ),
        ),
      )
    else closeSnackbar(snackBarKey)
    // return () => {
    //   closeSnackbar()
    // }
  }, [disablePortfolio])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: '#fff',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          marginBottom: 2,
          padding: 1,
        }}
      >
        <Toolbar sx={{ gap: 2 }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            Properties Board
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedPorfolio}
            onClick={triggerPropertiesSort}
          >
            Sort by Due Date
          </Button>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              value={searchWord}
              onChange={handleSearchChange}
            />
          </Search>
          <Select
            name="portfolio"
            label={'Portfolio'}
            items={portfolios}
            value={selectedPorfolio}
            onChange={handlePorfolioChange}
            disabled={disablePortfolio}
          />
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header
