import { gql } from '@apollo/client'

export const CHARGE_CUSTOMER = gql`
  mutation chargeCustomer(
    $amount: Int
    $invoiceMessage: String
    $planId: String
  ) {
    chargeCustomer(
      amount: $amount
      invoiceMessage: $invoiceMessage
      planId: $planId
    ) {
      paid
      receipt
    }
  }
`

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($token: JSON!) {
    createCustomer(token: $token) {
      _id
      name
      address
      city
      state
      zip
      phone
      email
      website
      userType
      logo {
        url
        url_small
      }
      MLSData {
        MLS
        STATE
        username
        password
      }
    }
  }
`

export const SIGN_UP = gql`
  mutation signUp($userInput: userInput!) {
    signUp(userInput: $userInput) {
      token
      orgId
    }
  }
`

export const CHECK_SIGN_UP = gql`
  mutation checkSignup($userInput: userInput!) {
    checkSignup(userInput: $userInput)
  }
`

export const GET_PRODUCTS = gql`
  query listProducts {
    listProducts {
      id
      name
      active
      pricingPlans {
        id
        active
        created
        interval
        nickname
        trial_period_days
        amount
        metadata
        tiers
      }
    }
  }
`

export const GET_COUPON = gql`
  mutation getCoupon($code: String!) {
    getCoupon(code: $code) {
      _id
      name
      code
      amountOff
      percentOff
      timesRedeemed
      maxRedemptions
    }
  }
`

export const CHECK_COUPON = gql`
  mutation checkCoupon($code: String!) {
    checkCoupon(code: $code) {
      _id
      name
      code
      amountOff
      percentOff
      timesRedeemed
      maxRedemptions
    }
  }
`

export const ADD_TRAFFIC = gql`
  mutation createAffiliateTraffic(
    $affiliateTrafficInput: AffiliateTrafficInput!
  ) {
    createAffiliateTraffic(
      affiliateTrafficInput: $affiliateTrafficInput
    ) {
      subscriptionPlan
      hfpid
      date
    }
  }
`

export const GET_SUBSCRIPTION_PLAN = gql`
  query subscriptionPlan($subscriptionPlan: String!) {
    subscriptionPlan(subscriptionPlan: $subscriptionPlan) {
      _id
      subscriptionPlan
      price
      productId
      planId
      propertyCount
      portfolioCount
      teamCount
      marketplaceCount
    }
  }
`
