/**
 * @ Author: Housefolios
 * @ Create Time: 2022-05-31 10:06:07
 * @ Modified by: David Helmick
 * @ Modified time: 2024-07-31 17:04:07
 * @ Description: Dialog to Add/Edit saved searches
 */

import React, { useState } from 'react'

import map from 'lodash/map'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'
import join from 'lodash/join'
import slice from 'lodash/slice'
import head from 'lodash/head'
import filter from 'lodash/filter'
import concat from 'lodash/concat'
import toLower from 'lodash/toLower'
import compact from 'lodash/compact'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import chunk from 'lodash/chunk'
import pullAt from 'lodash/pullAt'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { ApolloConsumer, useMutation, useQuery } from '@apollo/client'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import {
  CALL_REAPI,
  CALL_REAPI_COUNT,
  GET_POPULAR_SEARCHES,
} from '@/housefolios-components/PropertySearch/queries'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import withSession from '@/housefolios-components/Session/withSession'
import { UPDATE_LOT } from '@/housefolios-components/Lots/mutations'
import { UPDATE_PROPERTIES } from '@/housefolios-components/Portfolio/mutations'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_LOT } from '@/housefolios-components/Lots/queries'
import {
  Add,
  DeleteOutlined,
  InfoOutlined,
  MoreVertOutlined,
} from '@mui/icons-material'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import Autocomplete from '@mui/material/Autocomplete'
import { useStopwatch } from 'react-timer-hook'
import {
  GET_STATETABLE,
  GET_ZIP_BY_CITY_STATE,
  GET_ZIP_BY_COUNTY_STATE,
} from '@/housefolios-components/PropertySearch/queries'
import { GET_MARKETPLACES } from '@/housefolios-components/Marketplace/queries'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { compressAndStore } from "@/functions/lzma.js"
// import { setSearchParam } from '@/utils/url'

const hostname = import.meta.env.VITE_HOSTNAME
const apiKey = import.meta.env.VITE_HOUSEFOLIOS_APIKEY
const apiID = import.meta.env.VITE_HOUSEFOLIOS_APIID
const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY

function AddSearchComponent(props) {
  const { closeSearchMenu, menu, button, reapiInputRef } = props
  const [placeResult, setPlaceResult] = useState(null)
  const [state, setState] = useState('')
  const [county, setCounty] = useState('')
  const [altCities, setAltCities] = useState([])
  const [selectedCities, setSelectedCities] = useState([])
  const [zips, setZips] = useState([])
  const [selectedZips, setSelectedZips] = useState([])

  const [price, setPrice] = useState({})
  const [beds, setBeds] = useState({})
  const [baths, setBaths] = useState({})
  const [sqft, setSqft] = useState({})
  const [year, setYear] = useState({})

  const [mlsActive, setMLSActive] = useState(true)
  const [vacant, setVacant] = useState('both')
  const [lien, setLien] = useState('both')
  const [preForeclosure, setPreForeclosure] = useState('both')
  const [cashBuyer, setCashBuyer] = useState('both')
  const [auction, setAuction] = useState('both')
  const [reo, setReo] = useState('both')
  const [highEquity, setHighEquity] = useState('both')
  const [freeClear, setFreeClear] = useState('both')
  const [mlsFailed, setMLSFailed] = useState('both')
  const [taxDelinquentYear, setTaxDelinquentYear] = useState({})
  const [propertyType, setPropertyType] = useState('ANY')

  const [openSearchDialog, setOpenSearchDialog] = useState(false)
  const [searchCount, setSearchCount] = useState()
  const [searchType, setSearchType] = useState('')
  const [search, setSearch] = useState({})
  const [noSave, setNoSave] = useState(false)
  const [openThreshold, setOpenThreshold] = useState(false)

  const [name, setName] = useState('')

  const [loader, setLoader] = useState(false)
  const [backdrop, setBackdrop] = useState(false)

  const [open1, setOpen1] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [openNoPropertiesAvailable, setOpenNoPropertiesAvailable] =
    useState(false)
  const [openNearbyCities, setOpenNearbyCities] = useState(false)

  const [anchorEl, setAnchorEl] = useState()
  const [selectedSearch, setSelectedSearch] = useState({})

  const handleOpenMenuSearch = (search) => (event) => {
    event.stopPropagation()
    setAnchorEl(event.target)
    setSelectedSearch(search)
  }

  const handleCloseMenuSearch = (event) => {
    setAnchorEl(null)
    setSelectedSearch({})
  }

  const [openDeleteSearch, setOpenDeleteSearch] = useState(false)
  const [confirm, setConfirm] = useState(false)

  const handleOpenDeleteSearch = () => {
    setOpenDeleteSearch(true)
  }

  const handleCloseDeleteSearch = () => {
    setOpenDeleteSearch(false)
    setConfirm(false)
  }

  const { loading, error, data } = useQuery(GET_STATETABLE)

  const { seconds, minutes, pause, reset } = useStopwatch({
    autoStart: false,
  })

  const handleClickOpen1 = () => {
    setOpen1(true)
    setCounty('')
    setState('')
  }
  const handleClose1 = () => {
    setOpen1(false)
    setName('')
    setSelectedCities([])
    setSelectedZips([])
    setPrice({})
    setBeds({})
    setBaths({})
    setSqft({})
    setYear({})
    setMLSActive(true)
    setVacant('both')
    setLien('both')
    setCashBuyer('both')
    setPreForeclosure('both')
    setAuction('both')
    setReo('both')
    setHighEquity('both')
    setFreeClear('both')
    setMLSFailed('both')
    setTaxDelinquentYear({})
    setPropertyType('ANY')
    if (closeSearchMenu) closeSearchMenu()
  }

  const handleClickOpen2 = async (client, county, state) => {
    setLoader(true)
    reset()
    let cities = []
    let zips
    try {
      const { data = {} } = await client.query({
        query: GET_ZIP_BY_COUNTY_STATE,
        variables: {
          state,
          county,
        },
      })
      cities = data.zipTableByCountyState
        ? sortBy(
          uniq(
            flatten(
              map(data.zipTableByCountyState, (zip) => zip.cities),
            ),
          ),
        ) || []
        : []
      zips = data.zipTableByCountyState
        ? map(data.zipTableByCountyState, (zip) => zip.zipcode) || []
        : []
    } catch (error) {
      console.log(error)
    }
    setAltCities(cities)
    setSelectedCities([])
    setZips(zips)
    setSelectedZips([])
    setPrice({})
    setBeds({})
    setBaths({})
    setSqft({})
    setYear({})
    setMLSActive(true)
    setVacant('both')
    setLien('both')
    setCashBuyer('both')
    setPreForeclosure('both')
    setAuction('both')
    setReo('both')
    setHighEquity('both')
    setFreeClear('both')
    setMLSFailed('both')
    setTaxDelinquentYear({})
    setPropertyType('ANY')
    // setOpen1(false);
    setLoader(false)
    pause()
    // setOpen2(true);
  }

  const handleClickEdit = (client, search) => async (event) => {
    event.stopPropagation()
    setLoader(true)
    reset()
    const { data = {} } = await client.query({
      query: GET_ZIP_BY_COUNTY_STATE,
      variables: {
        state: search.state,
        county: search.county,
      },
      errorPolicy: 'ignore',
    })
    setName(search.name)
    setState(search.state)
    setCounty(search.county)
    const cities = data.zipTableByCountyState
      ? sortBy(
        uniq(
          flatten(
            map(data.zipTableByCountyState, (zip) => zip.cities),
          ),
        ),
      ) || []
      : []
    setAltCities(cities)
    setSelectedCities(search.cities)
    const zips = data.zipTableByCountyState
      ? map(data.zipTableByCountyState, (zip) => zip.zipcode) || []
      : []
    setZips(zips)
    setSelectedZips(search.zips)
    setPrice(search.price)
    setBeds(search.beds)
    setBaths(search.baths)
    setSqft(search.sqft)
    setYear(search.year)
    setMLSActive(search.mlsActive)
    setVacant(search.vacant)
    setLien(search.lien)
    setCashBuyer(search.cashBuyer)
    setPreForeclosure(search.preForeclosure)
    setAuction(search.auction)
    setReo(search.reo)
    setHighEquity(search.highEquity)
    setFreeClear(search.freeClear)
    setMLSFailed(search.mlsFailed)
    setTaxDelinquentYear(search.taxDelinquentYear)
    setPropertyType(search.propertyType)
    setLoader(false)
    pause()
    setOpen1(true)
    if (closeSearchMenu) closeSearchMenu()
  }

  const handleOpenNoPropertiesAvailable = () => {
    setOpenNoPropertiesAvailable(true)
  }
  const handleCloseNoPropertiesAvailable = () => {
    setOpenNoPropertiesAvailable(false)
  }
  const handleOpenNearbyCities = () => {
    setOpenNearbyCities(true)
  }
  const handleCloseNearbyCities = () => {
    setOpenNearbyCities(false)
    setLoader(false)
    pause()
  }

  //SPECIFIC COMMENT: We no longer get altCities
  // const searchMultipleCities = (client) => async () => {
  //   let zips = []
  //   let county = []
  //   if (selectedCities.length !== 0) {
  //     setLoader(true)
  //     reset()
  //     for (let i = 0; i < selectedCities.length; i++) {
  //       const {
  //         data: { zipTableByCityState },
  //       } = await client.query({
  //         query: GET_ZIP_BY_CITY_STATE,
  //         variables: {
  //           city: selectedCities,
  //           state: state,
  //         },
  //       })
  //       const newZips = map(zipTableByCityState, (zip) => zip.zipcode)
  //       for (let k = 0; k < newZips.length; k++) {
  //         zips.push(newZips[k])
  //       }
  //       const newCounties = map(zipTableByCityState, (zip) => zip.counties)
  //       for (let k = 0; k < newCounties.length; k++) {
  //         county.push(newCounties[k])
  //       }
  //     }
  //     county = uniq(flatten(county))
  //     const countyZips = uniq(flatten(await Promise.all(map(county, async county => {
  //       const { data: { zipTableByCountyState } } = await client.query({
  //         query: GET_ZIP_BY_COUNTY_STATE,
  //         variables: {
  //           state,
  //           county,
  //         },
  //         errorPolicy: 'ignore',
  //       })
  //       return zipTableByCountyState ? map(zipTableByCountyState, zip => zip.zipcode) || [] : []
  //     }))))
  //     let data = {
  //       apiKey: apiKey,
  //       orgID: apiID,
  //       action: 'saved_property_search',
  //       type: 'Single Family',
  //       env: hostname === 'localhost' || hostname === 'dev-admin.housefolios.com' ? 'dev' : 'live' ,
  //       cities: selectedCities,
  //       state: state,
  //       zips: zips,
  //       county: county,
  //       countyZips: countyZips,
  //     }
  //     if (zips.length !== 0) {
  //       const str_json = JSON.stringify(data)
  //       let response = { ok: false }
  //       response = await fetch(
  //         hostname === 'localhost'
  //         ? 'http://data.housefolios.com/housefoliosdev-api.php'
  //         //? 'http://housefolios-data.test/housefoliosdev-api.php'
  //           : hostname === 'dev-admin.housefolios.com'
  //             ? 'https://data.housefolios.com/housefoliosdev-api.php'
  //             : 'https://data.housefolios.com/housefolios-api.php',
  //         {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: str_json,
  //         },
  //       )
  //       if (!response.ok) {
  //         handleCloseNearbyCities()
  //         props.setResults([], null)
  //         localStorage.setItem('propertySearch', null)
  //         localStorage.setItem('propertySearch-name', '')
  //         enqueueSnackbar(
  //           `Something has gone wrong. Please contact support`,
  //           {
  //             variant: 'error',
  //             autoHideDuration: 3000,
  //           },
  //         )
  //         return null
  //       }
  //       let result = await response.json()
  //       const {
  //         data: { marketplaceProperties },
  //       } = await client.query({
  //         query: GET_MARKETPLACES,
  //         variables: {
  //           marketplaces: ['housefolios', 'myGrayLabel'],
  //           approved: true,
  //         },
  //       })
  //       const newSearch = {
  //         name: selectedCities.length > 3 ? `${selectedCities.length} ${state} Cities` : `${join(slice(selectedCities, 0, 3), ', ')}, ${state}`,
  //         state,
  //         county: head(county),
  //         cities: selectedCities,
  //         zips: zips,
  //       }
  //       updateOrganization({
  //         variables: {
  //           id: props.session.me.activeOrg._id,
  //           organizationInput: {
  //             newRecentSearch: newSearch,
  //           }
  //         },
  //         refetchQueries: [
  //           { query: GET_ME }
  //         ],
  //       })
  //       const filteredMarketplaceProperties = filter(marketplaceProperties, property => includes(zips, property.zip) && new Date(property.marketplaces.expirationDate).getTime() > Date.now())
  //       result = concat(filteredMarketplaceProperties, result)
  //       handleCloseNearbyCities()
  //       //setCity('')
  //       props.setResults(result, `Alternative Cities`)
  //       my_lzma.compress(JSON.stringify(result), 1, (result, error) => {
  //         const size = new TextEncoder().encode(JSON.stringify(result)).length
  //         if (size < 4 * 1024 * 1024) {
  //           localStorage.setItem('propertySearch', result)
  //           localStorage.setItem('propertySearch-name', `Alternative Cities`)
  //         }
  //         else {
  //           localStorage.setItem('propertySearch', null)
  //           localStorage.setItem('propertySearch-name', '')
  //         }
  //       }, percent => { return })
  //     } else {
  //       handleCloseNearbyCities()
  //       enqueueSnackbar(
  //         `These properties could not be found`,
  //         {
  //           variant: 'warning',
  //           autoHideDuration: 3000,
  //         },
  //       )
  //       //setCity('')
  //     }
  //   } else {
  //     handleCloseNearbyCities()
  //   }
  // }

  const popularResult = useQuery(GET_POPULAR_SEARCHES)

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const countSearch = async (
    client,
    savedSearch,
    searchType,
    noSave = false,
  ) => {
    setBackdrop(true)
    reset()
    let zips = [...savedSearch.zips] || []
    let cities = [...savedSearch.cities] || []

    const {
      data: { callREAPICount },
    } = await client.query({
      query: CALL_REAPI_COUNT,
      variables: {
        REAPIInput: {
          mls_active:
            savedSearch.mlsActive === 'both'
              ? undefined
              : savedSearch.mlsActive,
          cities: cities,
          state: savedSearch.state,
          zips,
          beds: savedSearch.beds,
          baths: savedSearch.baths,
          sqft: savedSearch.sqft,
          year: savedSearch.year,
          price: savedSearch.price,
          vacant:
            savedSearch.vacant === 'both'
              ? undefined
              : savedSearch.vacant,
          lien:
            savedSearch.lien === 'both'
              ? undefined
              : savedSearch.lien,
          cashBuyer:
            savedSearch.cashBuyer === 'both'
              ? undefined
              : savedSearch.cashBuyer,
          preForeclosure:
            savedSearch.preForeclosure === 'both'
              ? undefined
              : savedSearch.preForeclosure,
          auction:
            savedSearch.auction === 'both'
              ? undefined
              : savedSearch.auction,
          reo:
            savedSearch.reo === 'both' ? undefined : savedSearch.reo,
          highEquity:
            savedSearch.highEquity === 'both'
              ? undefined
              : savedSearch.highEquity,
          freeClear:
            savedSearch.freeClear === 'both'
              ? undefined
              : savedSearch.freeClear,
          mlsFailed:
            savedSearch.mlsFailed === 'both'
              ? undefined
              : savedSearch.mlsFailed,
          taxDelinquentYear: savedSearch.taxDelinquentYear,
          propertyType:
            savedSearch.propertyType === 'ANY'
              ? undefined
              : savedSearch.propertyType,
        },
      },
    })
    let result = callREAPICount
    if (result > 0) {
      setSearchCount(result)
      setOpenSearchDialog(true)
      setSearchType(searchType)
      setSearch(savedSearch)
      setNoSave(noSave)
    } else handleOpenNoPropertiesAvailable()
    setBackdrop(false)
  }

  const searchSavedSearch = async (client) => {
    setBackdrop(true)
    let zips = [...search.zips] || []
    let cities = [...search.cities] || []
    const {
      data: { callREAPI },
    } = await client.query({
      query: CALL_REAPI,
      variables: {
        REAPIInput: {
          mls_active:
            search.mlsActive === 'both'
              ? undefined
              : search.mlsActive,
          cities: cities,
          state: search.state,
          zips,
          beds: search.beds,
          baths: search.baths,
          sqft: search.sqft,
          year: search.year,
          price: search.price,
          vacant:
            search.vacant === 'both' ? undefined : search.vacant,
          lien: search.lien === 'both' ? undefined : search.lien,
          cashBuyer:
            search.cashBuyer === 'both'
              ? undefined
              : search.cashBuyer,
          preForeclosure:
            search.preForeclosure === 'both'
              ? undefined
              : search.preForeclosure,
          auction:
            search.auction === 'both' ? undefined : search.auction,
          reo: search.reo === 'both' ? undefined : search.reo,
          highEquity:
            search.highEquity === 'both'
              ? undefined
              : search.highEquity,
          freeClear:
            search.freeClear === 'both'
              ? undefined
              : search.freeClear,
          mlsFailed:
            search.mlsFailed === 'both'
              ? undefined
              : search.mlsFailed,
          taxDelinquentYear: search.taxDelinquentYear,
          propertyType:
            search.propertyType === 'ANY'
              ? undefined
              : search.propertyType,
        },
      },
    })
    let result = callREAPI || []
    // if (search.cities.length !== 0) {
    //   for (let i = 0; i < search.cities.length; i++) {
    //     const {
    //       data: { zipTableByCityState },
    //     } = await client.query({
    //       query: GET_ZIP_BY_CITY_STATE,
    //       variables: {
    //         city: search.cities,
    //         state: search.state,
    //       },
    //     })
    //     const newZips = map(zipTableByCityState, (zip) => zip.zipcode)
    //     for (let k = 0; k < newZips.length; k++) {
    //       zips.push(newZips[k])
    //     }
    //   }
    // }
    // const lowercaseCities = search.cities.map(v => toLower(v))
    // zips = uniq(zips)
    // let countyZips = []
    // if (search.county) {
    //   const { data: { zipTableByCountyState = [] } } = await client.query({
    //     query: GET_ZIP_BY_COUNTY_STATE,
    //     variables: {
    //       state: search.state,
    //       county: search.county,
    //     },
    //     errorPolicy: 'ignore',
    //   })
    //   countyZips = zipTableByCountyState ? map(zipTableByCountyState, zip => zip.zipcode) || [] : []
    // }
    // let cities = selectedCities
    // let isAltCity = false
    // const chunkZips = chunk(zips, 50)
    // let result = compact(uniq(flatten(await Promise.all(map(chunkZips, async zips => {
    //   let data = {
    //     ...search,
    //     apiKey: apiKey,
    //     orgID: apiID,
    //     action: 'saved_property_search',
    //     type: 'Single Family',
    //     env: hostname === 'localhost' || hostname === 'dev-admin.housefolios.com' ? 'dev' : 'live' ,
    //     cities: lowercaseCities,
    //     zips: zips,
    //     county: search.county ? [search.county] : [],
    //     countyZips: countyZips,
    //   }
    //   const str_json = JSON.stringify(data)
    //   let response = { ok: false }
    //   response = await fetch(
    //     hostname === 'localhost'
    //     ? 'http://data.housefolios.com/housefoliosdev-api.php'
    //     //? 'http://housefolios-data.test/housefoliosdev-api.php'
    //       : hostname === 'dev-admin.housefolios.com'
    //         ? 'https://data.housefolios.com/housefoliosdev-api.php'
    //         : 'https://data.housefolios.com/housefolios-api.php',
    //       {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: str_json,
    //     },
    //   )
    //   if (!response.ok) {
    //     // setBackdrop(false)
    //     // setAltCities([])
    //     // setSelectedCities([])
    //     // props.setResults([], null)
    //     // localStorage.setItem('propertySearch', null)
    //     // localStorage.setItem('propertySearch-name', '')
    //     // handleClose3()
    //     enqueueSnackbar(
    //       `Something has gone wrong. Please contact support`,
    //       {
    //         variant: 'error',
    //         autoHideDuration: 3000,
    //       },
    //     )
    //     return []
    //   }
    //   return await response.json()
    // })))))
    // if (every(result, object => Object.keys(object)[0] === 'altCity')) isAltCity = true
    // else {
    //   result = filter(result, object => Object.keys(object)[0] !== 'altCity')
    // }
    const {
      data: { marketplaceProperties },
    } = await client.query({
      query: GET_MARKETPLACES,
      variables: {
        marketplaces: ['housefolios', 'myGrayLabel'],
        approved: true,
      },
    })
    const newSearch = {
      name: search.name || `${head(search.cities)}, ${search.state}`,
      ...search,
      searchType: searchType,
      searchCount: searchCount,
      searchParameters: {
        mls_active:
          search.mlsActive === 'both' ? undefined : search.mlsActive,
        cities: cities,
        state: search.state,
        zips,
        beds: search.beds,
        baths: search.baths,
        sqft: search.sqft,
        year: search.year,
        price: search.price,
        vacant: search.vacant === 'both' ? undefined : search.vacant,
        lien: search.lien === 'both' ? undefined : search.lien,
        cashBuyer:
          search.cashBuyer === 'both' ? undefined : search.cashBuyer,
        preForeclosure:
          search.preForeclosure === 'both'
            ? undefined
            : search.preForeclosure,
        auction:
          search.auction === 'both' ? undefined : search.auction,
        reo: search.reo === 'both' ? undefined : search.reo,
        highEquity:
          search.highEquity === 'both'
            ? undefined
            : search.highEquity,
        freeClear:
          search.freeClear === 'both' ? undefined : search.freeClear,
        mlsFailed:
          search.mlsFailed === 'both' ? undefined : search.mlsFailed,
        taxDelinquentYear: search.taxDelinquentYear,
        propertyType:
          search.propertyType === 'ANY'
            ? undefined
            : search.propertyType,
      },
    }
    const filteredMarketplaceProperties = filter(
      marketplaceProperties,
      (property) =>
        (includes(zips, property.zip) ||
          (includes(cities, property.city) &&
            search.state === property.state)) &&
        new Date(property.marketplaces.expirationDate).getTime() >
        Date.now(),
    )
    // //OVERVIEW COMMENT: IF WE DON'T RECEIVE CITIES FROM PROPERTY SEARCH AND THERE ARE MARKETPLACE PROPERTIES IN THIS CITY, SHOW BOTH.
    // if (!isAltCity) result = concat(filteredMarketplaceProperties, result)
    // else
    // {
    //   //OVERVIEW COMMENT: IF WE RECEIVE ALT CITIES FROM PROPERTY SEARCH, BUT THERE ARE MARKETPLACE PROPERTIES IN THIS CITY, JUST SHOW THE MARKETPLACE PROPERTIES. ELSE, JUST SHOW PROPERTY SEARCH PROPERTIES
    //   if (filteredMarketplaceProperties.length > 0) result = filteredMarketplaceProperties
    //   //else result = result
    // }
    //SPECIFIC COMMENT: Alt cities not compatiable with REAPI
    result = concat(filteredMarketplaceProperties, result)
    if (!noSave) {
      let listingIds = compact(
        map(result, (value) => value.listingid),
      )
      search.prevResult = listingIds
      search.prevDate = new Date()
      let savedSearches = compact([
        ...props.session.me.activeOrg.savedSearches,
      ])
      let index
      if (!isEmpty(selectedSearch)) {
        let prevIndex = findIndex(
          savedSearches,
          (search) => search.name === selectedSearch.name,
        )
        if (prevIndex < 0) savedSearches.push(search)
        else {
          index = findIndex(
            savedSearches,
            (savedSearch) => savedSearch.name === search.name,
          )
          if (index < 0 || index === prevIndex)
            savedSearches[prevIndex] = search
          else {
            enqueueSnackbar(
              `Name of saved search is already being used`,
              {
                variant: 'error',
                autoHideDuration: 3000,
              },
            )
            setBackdrop(false)
            return
          }
        }
      } else {
        index = findIndex(
          savedSearches,
          (savedSearch) => search.name === savedSearch.name,
        )
        if (index < 0) savedSearches.push(search)
        else {
          enqueueSnackbar(
            `Name of saved search is already being used`,
            {
              variant: 'error',
              autoHideDuration: 3000,
            },
          )
          setBackdrop(false)
          return
        }
      }
      updateOrganization({
        variables: {
          id: props.session.me.activeOrg._id,
          organizationInput: {
            newRecentSearch: newSearch,
            savedSearches,
          },
        },
        refetchQueries: [{ query: GET_ME }],
      })
    } else {
      updateOrganization({
        variables: {
          id: props.session.me.activeOrg._id,
          organizationInput: {
            newRecentSearch: newSearch,
          },
        },
        refetchQueries: [{ query: GET_ME }],
      })
    }
    const autocompleteInput = document.getElementById(
      'propertySearch-autocomplete',
    )
    if (result.length === 0) {
      setBackdrop(false)
      setAltCities([])
      setSelectedCities([])
      props.setResults([], null)
      // Clear the value of the autocomplete input field
      if (autocompleteInput) autocompleteInput.value = ''
      localStorage.setItem('propertySearch', null)
      localStorage.setItem('propertySearch-name', '')
      handleClose1()
      setOpenSearchDialog(false)
      handleOpenNoPropertiesAvailable()
    } else if (Object.keys(result[0])[0] === 'altCity') {
      setBackdrop(false)
      setAltCities(map(result, (city) => city.altCity))
      setSelectedCities([])
      props.setResults([], null)
      // Clear the value of the autocomplete input field
      if (autocompleteInput) autocompleteInput.value = ''
      localStorage.setItem('propertySearch', null)
      localStorage.setItem('propertySearch-name', '')
      handleClose1()
      setOpenSearchDialog(false)
      handleOpenNoPropertiesAvailable()
    } else {
      setBackdrop(false)
      handleClose1()
      setOpenSearchDialog(false)
      handleCloseMenuSearch()
      //setCity('')
      props.setResults(
        result,
        search.name || `${head(search.cities)}, ${search.state}`,
      )
      // Change the value of the autocomplete input field
      if (autocompleteInput)
        autocompleteInput.value =
          search.name || `${head(search.cities)}, ${search.state}`
      // setSearchParam(search.name)
      compressAndStore(result, search.name, search.cities, search.state, reapiInputRef.current)
    }
  }

  const saveSearch = (client) => () => {
    //setLoader(true)
    const newSearch = {
      name,
      state,
      county,
      cities: selectedCities,
      zips: selectedZips,
      price,
      beds,
      baths,
      sqft,
      year,
      mlsActive,
      vacant,
      lien,
      cashBuyer,
      preForeclosure,
      auction,
      reo,
      highEquity,
      freeClear,
      mlsFailed,
      taxDelinquentYear,
      propertyType,
    }
    countSearch(client, newSearch, 'saved search')
  }

  const handleDelete = () => {
    let savedSearches = compact([
      ...props.session.me.activeOrg.savedSearches,
    ])
    let index = findIndex(
      savedSearches,
      (search) => search.name === selectedSearch.name,
    )
    if (index >= 0) pullAt(savedSearches, index)
    else {
      enqueueSnackbar(`Search is already deleted`, {
        variant: 'error',
        autoHideDuration: 3000,
      })
      setBackdrop(false)
      return
    }
    updateOrganization({
      variables: {
        id: props.session.me.activeOrg._id,
        organizationInput: {
          savedSearches,
        },
      },
      refetchQueries: [{ query: GET_ME }],
    }).then(() => {
      enqueueSnackbar(`Search deleted`, {
        variant: 'success',
        autoHideDuration: 3000,
      })
      handleCloseDeleteSearch()
      handleCloseMenuSearch()
    })
  }

  if (loading || popularResult.loading) return 'Loading...'
  if (error) return error.message
  if (popularResult.error) return popularResult.error.message

  const states = sortBy(map(data.states, (state) => state.state))
  const counties = state
    ? sortBy(
      find(data.states, (obj) => obj.state === state)
        ? find(data.states, (obj) => obj.state === state)
          .counties || []
        : [],
    )
    : sortBy(
      flatten(
        map(data.states, (state) => (state ? state.counties : [])),
      ),
    )
  return (
    <ApolloConsumer>
      {(client) => (
        <>
          {button && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              spacing={4}
            >
              <Grid item xs={12} sm={4} className="d-flex">
                <Card style={{ width: '100%' }}>
                  <CardHeader
                    title="Saved Searches"
                    action={
                      <IconButton
                        size="small"
                        onClick={handleClickOpen1}
                      >
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                      </IconButton>
                    }
                  />
                  <Divider />
                  <CardContent>
                    {isEmpty(
                      props.session.me.activeOrg.savedSearches,
                    ) && (
                        <ListItem>
                          <span>No saved searches...</span>
                        </ListItem>
                      )}
                    <List
                      component="div"
                      className="nav-list-square nav-neutral-primary ml-2"
                    // subheader="Recent Searches"
                    >
                      {map(
                        props.session.me.activeOrg.savedSearches,
                        (savedSearch) => (
                          <ListItem
                            fullWidth
                            key={savedSearch.name}
                            button
                            onClick={() =>
                              countSearch(
                                client,
                                {
                                  ...reapiInputRef.current,
                                  ...savedSearch,
                                  mlsActive:
                                    reapiInputRef.current.mls_active,
                                },
                                'saved search',
                                true,
                              )
                            }
                          >
                            <span>{savedSearch.name}</span>
                            <Typography style={{ flex: 1 }} />
                            <IconButton
                              onClick={handleOpenMenuSearch(
                                savedSearch,
                              )}
                              size="large"
                            >
                              <MoreVertOutlined />
                            </IconButton>
                          </ListItem>
                        ),
                      )}
                    </List>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      name="editDeleteSearch"
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenuSearch}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                      classes={{ list: 'p-0' }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <ListItem
                        button
                        onClick={handleClickEdit(
                          client,
                          selectedSearch,
                        )}
                      >
                        <FontAwesomeIcon
                          icon={['far', 'pen']}
                          style={{ marginLeft: 5, marginRight: 5 }}
                        />
                        Edit Search
                      </ListItem>
                      <ListItem
                        button
                        onClick={handleOpenDeleteSearch}
                      >
                        <DeleteOutlined
                          style={{ marginRight: 5, color: 'red' }}
                        />
                        <span style={{ color: 'red' }}>
                          Delete Search
                        </span>
                      </ListItem>
                    </Menu>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex">
                <Card style={{ width: '100%' }}>
                  <CardHeader title="Recent Searches">
                    Test
                  </CardHeader>
                  <Divider />
                  <CardContent>
                    {isEmpty(
                      props.session.me.activeOrg.recentSearches,
                    ) && (
                        <ListItem>
                          <span>No recent searches...</span>
                        </ListItem>
                      )}
                    <List
                      component="div"
                      className="nav-list-square nav-neutral-primary ml-2"
                    // subheader="Recent Searches"
                    >
                      {map(
                        props.session.me.activeOrg.recentSearches,
                        (recentSearch) => (
                          <ListItem
                            fullWidth
                            key={recentSearch.name}
                            button
                            onClick={() =>
                              countSearch(
                                client,
                                {
                                  ...reapiInputRef.current,
                                  ...recentSearch,
                                  mlsActive:
                                    reapiInputRef.current.mls_active,
                                },
                                'recent search',
                                !Boolean(recentSearch.prevDate),
                              )
                            }
                          >
                            <span>{recentSearch.name}</span>
                          </ListItem>
                        ),
                      )}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4} className="d-flex">
                <Card style={{ width: '100%' }}>
                  <CardHeader title="Popular Searches">
                    Test
                  </CardHeader>
                  <Divider />
                  <CardContent>
                    {map(
                      popularResult.data.popularSearches,
                      (popularSearch) => (
                        <ListItem
                          key={`${popularSearch.city}, ${popularSearch.state}`}
                          button
                          onClick={() =>
                            countSearch(
                              client,
                              {
                                ...reapiInputRef.current,
                                ...popularSearch,
                                mlsActive:
                                  reapiInputRef.current.mls_active,
                              },
                              'popular search',
                              true,
                            )
                          }
                        >
                          <span>{`${popularSearch.city}, ${popularSearch.state}`}</span>
                        </ListItem>
                      ),
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            // <Grid item>
            //   <div onClick={handleClickOpen1} className="m-3 btn-input-select">
            //     <div className="d-30 d-flex align-items-center justify-content-center rounded-pill bg-default">
            //       <FontAwesomeIcon icon={['fas', 'plus']} />
            //     </div>
            //     <div className="font-weight-bold text-primary opacity-6 mt-2">
            //       Add new
            //     </div>
            //     <div className="opacity-6">
            //       Click to Addd
            //     </div>
            //   </div>
            // </Grid>
          )}
          {menu && (
            <ListItem button onClick={handleClickOpen1}>
              <div className="nav-link-icon">
                <FontAwesomeIcon icon={['fas', 'plus']} />
              </div>
              <span>Create saved search</span>
            </ListItem>
          )}
          <Dialog
            open={open1}
            onClose={handleClose1}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
            onKeyDown={(event) => event.stopPropagation()}
            onClick={(event) => event.stopPropagation()}
          >
            <BlockUi
              blocking={backdrop}
              loader={
                <PacmanLoader
                  color={'var(--primary)'}
                  loading={true}
                />
              }
              message={
                <div className="text-primary">
                  <p>Searching for properties</p>
                </div>
              }
            >
              <DialogTitle id="form-dialog-title">
                Create Saved Search
              </DialogTitle>
              <DialogContent className="p-3">
                <DialogContentText className="mb-2">
                  Customize your property search and then save it!
                  Once saved, you can run that search at the click of
                  a button.
                </DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required={true}
                      variant="outlined"
                      margin="dense"
                      fullWidth={true}
                      label="Search Name"
                      placeholder="Name"
                      value={name}
                      onChange={(event) =>
                        setName(event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      select
                      options={states}
                      value={state}
                      onChange={(event, newValue) => {
                        setState(newValue || '')
                        if (newValue) {
                          const state =
                            find(
                              data.states,
                              (obj) => obj.state === newValue,
                            ) || {}
                          if (!includes(state.counties, county))
                            setCounty('')
                          else
                            handleClickOpen2(client, county, newValue)
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          variant="outlined"
                          margin="dense"
                          placeholder="State"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      options={counties}
                      value={county}
                      onChange={(event, newValue) => {
                        setCounty(newValue)
                        if (newValue)
                          handleClickOpen2(client, newValue, state)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="County"
                          variant="outlined"
                          margin="dense"
                          placeholder="County"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Collapse in={state && county}>
                  <Divider
                    style={{ marginTop: 25, marginBottom: 25 }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginBottom: -15 }}>
                      <Typography>Location</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        //id="fixed-tags-demo"
                        options={altCities}
                        disableCloseOnSelect
                        value={selectedCities}
                        onChange={(event, value) =>
                          setSelectedCities(value)
                        }
                        renderOption={(
                          props,
                          option,
                          { selected },
                        ) => (
                          <li {...props}>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={
                                <CheckBoxIcon
                                  fontSize="small"
                                  style={{ color: '#2296F3' }}
                                />
                              }
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cities"
                            variant="outlined"
                            margin="dense"
                            placeholder="Cities"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        //id="fixed-tags-demo"
                        options={zips}
                        value={selectedZips}
                        onChange={(event, value) =>
                          setSelectedZips(value)
                        }
                        renderOption={(
                          props,
                          option,
                          { selected },
                        ) => (
                          <li {...props}>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={
                                <CheckBoxIcon
                                  fontSize="small"
                                  style={{ color: '#2296F3' }}
                                />
                              }
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Zips"
                            variant="outlined"
                            margin="dense"
                            placeholder="Zip Codes"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Divider
                    style={{ marginTop: 25, marginBottom: 25 }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginBottom: -15 }}>
                      <Typography>Property Details</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Min Asking Price"
                        fullWidth={true}
                        value={price.min}
                        onChange={(event) =>
                          setPrice({
                            ...price,
                            min: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Max Asking Price"
                        fullWidth={true}
                        value={price.max}
                        onChange={(event) =>
                          setPrice({
                            ...price,
                            max: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Beds Min"
                        fullWidth={true}
                        value={beds.min}
                        onChange={(event) =>
                          setBeds({
                            ...beds,
                            min: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Beds Max"
                        fullWidth={true}
                        value={beds.max}
                        onChange={(event) =>
                          setBeds({
                            ...beds,
                            max: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Baths Min"
                        fullWidth={true}
                        value={baths.min}
                        onChange={(event) =>
                          setBaths({
                            ...baths,
                            min: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Baths Max"
                        fullWidth={true}
                        value={baths.max}
                        onChange={(event) =>
                          setBaths({
                            ...baths,
                            max: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Sqft Min"
                        fullWidth={true}
                        value={sqft.min}
                        onChange={(event) =>
                          setSqft({
                            ...sqft,
                            min: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Sqft Max"
                        fullWidth={true}
                        value={sqft.max}
                        onChange={(event) =>
                          setSqft({
                            ...sqft,
                            max: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Year Min"
                        fullWidth={true}
                        value={year.min}
                        onChange={(event) =>
                          setYear({
                            ...year,
                            min: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        label="Year Max"
                        fullWidth={true}
                        value={year.max}
                        onChange={(event) =>
                          setYear({
                            ...year,
                            max: event.target.value,
                          })
                        }
                        type="number"
                      />
                    </Grid>
                  </Grid>
                  <Divider
                    style={{ marginTop: 25, marginBottom: 25 }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginBottom: -15 }}>
                      <Typography>More Details</Typography>
                    </Grid>
                    {/* <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="On Market"
                        fullWidth={true}
                        value={mlsActive}
                        onChange={event => setMLSActive(event.target.value)}
                        type="number"
                      >
                        <MenuItem key="yes" value={true}>Yes</MenuItem>
                        <MenuItem key="no" value={false}>No</MenuItem>
                        <MenuItem key="both" value={'both'}>Both</MenuItem>
                      </TextField>
                    </Grid> */}
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Vacant"
                        fullWidth={true}
                        value={vacant}
                        onChange={(event) =>
                          setVacant(event.target.value)
                        }
                        type="number"
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Liens"
                        fullWidth={true}
                        value={lien}
                        onChange={(event) =>
                          setLien(event.target.value)
                        }
                        type="number"
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Cash Buyer"
                        fullWidth={true}
                        value={lien}
                        onChange={(event) =>
                          setCashBuyer(event.target.value)
                        }
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Pre-Foreclosures"
                        fullWidth={true}
                        value={preForeclosure}
                        onChange={(event) =>
                          setPreForeclosure(event.target.value)
                        }
                        type="number"
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Auctions"
                        fullWidth={true}
                        value={auction}
                        onChange={(event) =>
                          setAuction(event.target.value)
                        }
                        type="number"
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Bank Owned"
                        fullWidth={true}
                        value={reo}
                        onChange={(event) =>
                          setReo(event.target.value)
                        }
                        type="number"
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="High Equity"
                        fullWidth={true}
                        value={highEquity}
                        onChange={(event) =>
                          setHighEquity(event.target.value)
                        }
                        type="number"
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Free and Clear"
                        fullWidth={true}
                        value={freeClear}
                        onChange={(event) =>
                          setFreeClear(event.target.value)
                        }
                        type="number"
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        select
                        variant="outlined"
                        margin="dense"
                        label="Failed Listings"
                        fullWidth={true}
                        value={mlsFailed}
                        onChange={(event) =>
                          setMLSFailed(event.target.value)
                        }
                        type="number"
                      >
                        <MenuItem key="yes" value={true}>
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value={false}>
                          No
                        </MenuItem>
                        <MenuItem key="both" value={'both'}>
                          Both
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={6}>
                      <DatePicker
                        name="taxDelinquentYear.min"
                        label="Tax Delinquent Year Min"
                        value={taxDelinquentYear.min || null}
                        maxDate={taxDelinquentYear.max}
                        onChange={(newValue) => {
                          setTaxDelinquentYear({
                            ...taxDelinquentYear,
                            min: newValue,
                          })
                        }}
                        fullWidth={true}
                        views={['year']}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                          />
                        )}
                        disableFuture
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <DatePicker
                        name="taxDelinquentYear.max"
                        label="Tax Delinquent Year Max"
                        value={taxDelinquentYear.max || null}
                        minDate={taxDelinquentYear.min}
                        onChange={(newValue) => {
                          setTaxDelinquentYear({
                            ...taxDelinquentYear,
                            max: newValue,
                          })
                        }}
                        fullWidth={true}
                        views={['year']}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                          />
                        )}
                        disableFuture
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Autocomplete
                        id="checkboxes-tags-demo"
                        name="propertyType"
                        label="Property Type"
                        options={[
                          'ANY',
                          'SFR',
                          'MFR',
                          'LAND',
                          'CONDO',
                          'MOBILE',
                          'OTHER',
                        ]}
                        value={propertyType}
                        onChange={(event, newValue) => {
                          setPropertyType(newValue)
                        }}
                        disableClearable
                        renderOption={(
                          props,
                          option,
                          { selected },
                        ) => (
                          <li {...props}>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={
                                <CheckBoxIcon fontSize="small" />
                              }
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Property Type"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </DialogContent>
              <DialogActions className=" pr-4">
                <Grid container direction="row">
                  <Typography style={{ flex: 1 }} />
                  <Grid item>
                    <Button onClick={handleClose1} variant="text">
                      <Typography style={{ fontWeight: 600 }}>
                        Cancel
                      </Typography>
                    </Button>
                    <Button
                      onClick={saveSearch(client)}
                      disabled={
                        (isEmpty(selectedCities) &&
                          isEmpty(selectedZips)) ||
                        !state ||
                        !county ||
                        !name
                      }
                      className="btn-primary shadow-none"
                      style={{ marginLeft: 5 }}
                    >
                      <Typography style={{ fontWeight: 600 }}>
                        Save
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </BlockUi>
          </Dialog>

          {/* Search Count */}
          <Dialog
            open={openSearchDialog}
            onClose={() => setOpenSearchDialog(false)}
            onClick={(event) => event.stopPropagation()}
            classes={{ paper: 'shadow-lg rounded' }}
          >
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div
                  className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130"
                  style={{ backgroundColor: '#ffddca' }}
                >
                  <FontAwesomeIcon
                    icon={['far', 'location-exclamation']}
                    className="d-flex align-self-center display-3"
                    style={{ fontSize: 56, color: '#ff8132' }}
                  />
                </div>
              </div>
              <h4 className="font-weight-bold mt-4">
                This property search will return {searchCount} number
                of properties, do you want to proceed
              </h4>
              <div className="pt-4">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      fullWidth={true}
                      onClick={() => {
                        setOpenSearchDialog(false)
                      }}
                      style={{
                        backgroundColor: '#008aff',
                        color: 'white',
                      }}
                    >
                      <Typography>No</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      fullWidth={true}
                      variant="contained"
                      onClick={() => {
                        if (searchCount > 6000) setOpenThreshold(true)
                        else searchSavedSearch(client)
                      }}
                      style={{
                        backgroundColor: '#008aff',
                        color: 'white',
                      }}
                    >
                      <Typography>Yes</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Dialog>

          {/* Threshold Overflow */}
          <Dialog
            open={openThreshold}
            onClose={() => setOpenThreshold(false)}
            onClick={(event) => event.stopPropagation()}
            classes={{ paper: 'shadow-lg rounded' }}
          >
            <div className="text-center p-5">
              <p className="font-weight-bold mt-4">
                Before we can do a search of that many properties, we
                would like to chat with them to understand who they
                are and their business goals. Please select the live
                chat option in the Suppot menu of the left navigation
                bar.
              </p>
              <p>
                You can also make use of the search criteria to filter
                the property result set to a smaller ammount.
              </p>
              <div className="pt-4">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      fullWidth={true}
                      onClick={() => {
                        setOpenThreshold(false)
                        setSearchCount(-1)
                      }}
                      style={{
                        backgroundColor: '#008aff',
                        color: 'white',
                      }}
                    >
                      <Typography>Close</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Dialog>

          {/* No properties in searched area */}
          <Dialog
            open={openNoPropertiesAvailable}
            onClose={handleCloseNoPropertiesAvailable}
            onClick={(event) => event.stopPropagation()}
            classes={{ paper: 'shadow-lg rounded' }}
          >
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div
                  className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130"
                  style={{ backgroundColor: '#ffddca' }}
                >
                  <FontAwesomeIcon
                    icon={['far', 'location-exclamation']}
                    className="d-flex align-self-center display-3"
                    style={{ fontSize: 56, color: '#ff8132' }}
                  />
                </div>
              </div>
              <h4 className="font-weight-bold mt-4">
                No Data yet. Try again tomorrow!
              </h4>
              <p className="mb-0 font-size-lg text-muted">
                We work with national data providers to see available
                property listings. We will check if any of them cover
                your desired city. It may take up to 24 hours.
              </p>
              <div className="pt-4">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  {/* {!isEmpty(altCities) && (
                    <Grid item>
                      <Button
                        fullWidth={true}
                        variant="text"
                        onClick={() => {
                          handleCloseNoPropertiesAvailable()
                          handleOpenNearbyCities()
                        }}
                        style={{ backgroundColor: '#ff8132', color: 'white' }}
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'map-marker-check']}
                          className="d-flex align-self-center display-3 font-size-lg mr-2"
                        />
                        <Typography>See Nearby Cities</Typography>
                      </Button>
                    </Grid>
                  )} */}
                  {/* <Grid item>
                    <Button
                      fullWidth={true}
                      variant="contained"
                      onClick={() => {
                        handleCloseNoPropertiesAvailable()
                        handleOpenCitySearch()
                      }}
                      style={{ backgroundColor: '#008aff', color: 'white' }}
                    >
                      <FontAwesomeIcon
                        icon={['far', 'magnifying-glass']}
                        className="d-flex align-self-center display-3 font-size-lg mr-2"
                      />
                      <Typography>New Search</Typography>
                    </Button>
                  </Grid> */}
                </Grid>
              </div>
            </div>
          </Dialog>

          {/* Nearby Cities */}
          {/* SPECIFIC COMMENT: This should no longer be used */}
          {/* <Dialog
            open={openNearbyCities}
            onClose={handleCloseNearbyCities}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth='sm'
            onClick={event => event.stopPropagation()}
          >
            <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />} message={<div className="text-primary" ><p>Connecting</p><p>{((minutes * 60 + seconds) / 120 * 100).toFixed(1)}% Complete</p></div>}>
              <DialogTitle id="form-dialog-title">Nearby Cities</DialogTitle>
              <DialogContent className="p-4">
                <DialogContentText className="mb-4">
                  Here are some nearby cities that we have data for now!
                </DialogContentText>
                <Grid container>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={altCities}
                      disableCloseOnSelect
                      value={selectedCities}
                      onChange={(event, value) =>
                        setSelectedCities(value)
                      }
                       renderOption={(props, option, { selected }) => (
            <li {...props}>
                          <Checkbox
                            icon={
                              <CheckBoxOutlineBlankIcon fontSize="small" />
                            }
                            checkedIcon={
                              <CheckBoxIcon
                                fontSize="small"
                                style={{ color: '#2296F3' }}
                              />
                            }
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          fullWidth={true}
                          placeholder='Cities with data'
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className=" pr-4">
                <Grid container direction='row' alignItems='center' >
                  <Typography style={{ flex: 1 }} />
                  <Grid item>
                    <Button
                      onClick={handleCloseNearbyCities}
                      variant="text"
                    >
                      <Typography style={{ fontWeight: 600, }}>Cancel</Typography>
                    </Button>
                    <Button onClick={searchMultipleCities(client)} className="btn-primary shadow-none" disabled={isEmpty(selectedCities)}>
                      <Typography style={{ fontWeight: 600 }}>Search</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </BlockUi>
          </Dialog> */}

          <Dialog
            open={openDeleteSearch && !isEmpty(selectedSearch)}
            onClose={handleCloseDeleteSearch}
            classes={{ paper: 'shadow-lg rounded' }}
          >
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                  <FontAwesomeIcon
                    icon={['fas', 'trash-can']}
                    size="10x"
                    className="d-flex align-self-center display-3"
                  />
                </div>
              </div>
              <h4 className="font-weight-bold mt-4">
                Delete {selectedSearch.name} Search?
              </h4>
              <p className="mb-0 font-size-lg text-muted">
                <FormControl component="fieldset" className="pr-4">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={confirm}
                          onChange={() => setConfirm(!confirm)}
                        />
                      }
                      label="Delete this saved search"
                    />
                  </FormGroup>
                </FormControl>
              </p>
              <div className="pt-4">
                <Button
                  variant="text"
                  onClick={handleCloseDeleteSearch}
                >
                  <span className="btn-wrapper--label">Cancel</span>
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#f83145',
                    color: 'white',
                  }}
                  onClick={handleDelete}
                  disabled={!confirm}
                >
                  <span className="btn-wrapper--label">Delete</span>
                </Button>
              </div>
            </div>
          </Dialog>
          <Backdrop
            open={backdrop && !open1}
            style={{
              color: '#fff',
              zIndex: 2000,
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </ApolloConsumer>
  )
}

export default withSession(AddSearchComponent)
