/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-16 11:36:56
 * @ Modified by: David Helmick
 * @ Modified time: 2024-05-28 12:14:52
 * @ Description: Edit user information of a team member
 */

import React, { useState } from 'react'

import { useDropzone } from 'react-dropzone'
import map from 'lodash/map'
import compact from 'lodash/compact'
import find from 'lodash/find'
import filter from 'lodash/filter'

import clsx from 'clsx'

import {
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone'
import CheckIcon from '@mui/icons-material/Check'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import withSession from '@/housefolios-components/Session/withSession'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { GET_TEAM } from '../queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { useMutation } from '@apollo/client'
import { IMAGE_UPLOAD } from '@/housefolios-components/TitleButtons/AddPropertyButton/mutations'
import { enqueueSnackbar } from 'notistack'
import { UPDATE_USER } from '@/housefolios-components/PersonalSettings/mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import FileUpload from '@/housefolios-components/PropertyContainer/PropertyDashboard/FileUpload'
import { NumericFormat } from 'react-number-format'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { STATES } from '@/constants/states'
import Autocomplete from '@mui/material/Autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function EditUserComponent(props) {
  const { personalSettings, teamTable, lending, user } = props
  const includesOrg = find(user.roles, [
    'organization._id',
    props.session.me.activeOrg._id,
  ])
  const [openEditUser, setEditUser] = useState(false)
  const [firstname, setFirstname] = useState(user.profile.firstname)
  const [lastname, setLastname] = useState(user.profile.lastname)
  const [email, setEmail] = useState(user.email)
  const [role, setRole] = useState(includesOrg.role[0])
  const [phone, setPhone] = useState(user.phone)
  const [userType, setUserType] = useState(user.userType)
  const [jobTitle, setJobTitle] = useState(user.jobTitle)
  const [bioDescription, setBioDescription] = useState(
    user.bioDescription,
  )

  const [accordian, setAccordian] = useState(false)

  const [DoB, setDoB] = useState(null)
  const [citizenship, setCitizenship] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [FICO, setFICO] = useState('')
  const [firstTimeInvestor, setFirstTimeInvestor] = useState('')
  const [yearsInvesting, setYearsInvesting] = useState('')
  const [realEstateInvestments, setRealEstateInvestments] =
    useState('')
  const [estimatedNetWorth, setEstimatedNetWorth] = useState('')
  const [estimatedLiquidAssets, setEstimatedLiquidAssets] =
    useState('')

  const [creditReport, setCreditReport] = useState([])
  const [scheduleOfRealEstate, setScheduleOfRealEstate] = useState([])
  const [personalFinancialStatement, setPersonalFinancialStatement] =
    useState([])

  const toggleAccordion = () => {
    setAccordian(!accordian)
  }

  const uploadFile = (setter) => (files) => {
    let newFiles = []
    map(files, (file) => {
      newFiles.push(file)
    })
    setter(newFiles)
  }

  const deleteFile = (setter) => () => {
    let newFiles = []
    setter(newFiles)
  }

  const [loader, setLoader] = useState(false)

  const handleOpenEditUser = (event) => {
    event.stopPropagation()
    setFirstname(user.profile.firstname)
    setLastname(user.profile.lastname)
    setEmail(user.email)
    setRole(includesOrg.role[0])
    setPhone(user.phone)
    setUserType(user.userType)
    setJobTitle(user.jobTitle)
    setBioDescription(user.bioDescription)
    setFiles([user.userImage])
    setDoB(user.DoB)
    setCitizenship(user.citizenship)
    setAddress(user.address)
    setAddress2(user.address2)
    setCity(user.city)
    setState(user.state)
    setZip(user.zip)
    setFICO(user.FICO)
    setFirstTimeInvestor(user.firstTimeInvestor)
    setYearsInvesting(user.yearsInvesting)
    setRealEstateInvestments(user.realEstateInvestments)
    setEstimatedNetWorth(user.estimatedNetWorth)
    setEstimatedLiquidAssets(user.estimatedLiquidAssets)
    setCreditReport(user.creditReport)
    setScheduleOfRealEstate(user.scheduleOfRealEstate)
    setPersonalFinancialStatement(user.personalFinancialStatement)
    setLoader(false)
    setEditUser(true)
  }
  const handleCloseEditUser = () => {
    setEditUser(false)
    setFirstname('')
    setLastname('')
    setEmail('')
    setRole('')
    setPhone('')
    setUserType('')
    setJobTitle('')
    setBioDescription('')
    setFiles([])
    setDoB(null)
    setCitizenship('')
    setAddress('')
    setAddress2('')
    setCity('')
    setState('')
    setZip('')
    setFICO('')
    setFirstTimeInvestor('')
    setYearsInvesting('')
    setRealEstateInvestments('')
    setEstimatedNetWorth('')
    setEstimatedLiquidAssets('')
    setCreditReport([])
    setScheduleOfRealEstate([])
    setPersonalFinancialStatement([])
    setLoader(false)
  }

  const orgRole = find(props.session.me.roles, [
    'organization._id',
    props.session.me.activeOrg._id,
  ])

  const isAdmin =
    orgRole.role[0] === 'admin' ||
    props.session.me._id === props.session.me.activeOrg.createdBy._id

  const isNotSelf = user._id !== props.session.me._id
  const activeOnThisOrgOnly =
    filter(user.roles, (role) => role[0] !== 'deactive').length === 1 // SPECIFIC COMMENT: This doesn't check if the only active org is this one as you shouldn't be able to edit deactivated users.
  const adminCantEdit = !isAdmin || !activeOnThisOrgOnly
  const noChangeAllowed = isNotSelf && adminCantEdit

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [
      {
        query: GET_TEAM,
      },
      {
        query: GET_ME,
      },
    ],
  })
  const [imageUpload] = useMutation(IMAGE_UPLOAD, {
    refetchQueries: [
      {
        query: GET_TEAM,
      },
      {
        query: GET_ME,
      },
    ],
  })

  const inputFields = [
    {
      label: 'First Name',
      placeholder: 'First Name',
      value: firstname,
      set: setFirstname,
      validators: [
        'required',
        'maxStringLength: 20',
        'matchRegexp:^[A-z]+$',
      ],
      errorMessages: [
        'This field is required',
        'You cannot exceed 20 characters',
        'This field only allows characters in the alphabet',
      ],
      disabled: noChangeAllowed,
      xs: '12',
      md: '6',
    },
    {
      label: 'Last Name',
      placeholder: 'Last Name',
      value: lastname,
      set: setLastname,
      validators: [
        'required',
        'maxStringLength: 20',
        'matchRegexp:^[A-z]+$',
      ],
      errorMessages: [
        'This field is required',
        'You cannot exceed 20 characters',
        'This field only allows characters in the alphabet',
      ],
      disabled: noChangeAllowed,
      xs: '12',
      md: '6',
    },
    {
      label: 'Email Address',
      placeholder: 'Email Address',
      value: email,
      // set: setEmail,
      set: () => {
        return null
      },
      validators: ['required', 'maxStringLength: 50', 'isEmail'],
      errorMessages: [
        'This field is required',
        'You cannot exceed 50 characters',
        'Not a valid email address',
      ],
      disabled: noChangeAllowed,
      xs: '12',
      md: '12',
    },
    {
      label: 'Phone',
      placeholder: 'Phone',
      value: phone,
      set: setPhone,
      validators: ['matchRegexp:^[0-9()-]+$'],
      errorMessages: ['Not a valid phone number'],
      disabled: noChangeAllowed,
      xs: '12',
      md: '6',
    },
    {
      dropdown: true,
      label: 'User Type',
      placeholder: 'User Type',
      value: userType,
      set: setUserType,
      options: [
        { value: 'agent', label: 'Agent' },
        { value: 'broker', label: 'Broker' },
        { value: 'investor', label: 'Investor' },
        { value: 'other', label: 'Other' },
      ],
      disabled: noChangeAllowed,
      xs: '12',
      md: '6',
    },
    {
      dropdown: true,
      label: 'Role',
      placeholder: 'Role',
      value: role,
      set: setRole,
      options: [
        { value: 'admin', label: 'Admin' },
        { value: 'team', label: 'Team Member' },
      ],
      xs: '12',
      md: '6',
    },
    {
      label: 'Job Title',
      placeholder: 'Job Title',
      value: jobTitle,
      set: setJobTitle,
      validators: ['required', 'maxStringLength: 50'],
      errorMessages: [
        'This field is required',
        'You cannot exceed 50 characters',
      ],
      disabled: noChangeAllowed,
      xs: '12',
      md: '6',
    },
    {
      label: 'User Bio',
      placeholder: 'User Bio',
      value: bioDescription,
      set: setBioDescription,
      validators: ['maxStringLength: 3000'],
      errorMessages: ['You cannot exceed 3000 characters'],
      multiline: true,
      rows: '8',
      disabled: noChangeAllowed,
      xs: '12',
      md: '12',
    },
  ]

  const [files, setFiles] = useState([props.user.userImage])
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    getRootProps,
    getInputProps,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
    disabled: noChangeAllowed,
    // onDrop: acceptedFiles => {
    //   setFiles(acceptedFiles.map(file => Object.assign(file, {
    //     preview: URL.createObjectURL(file)
    //   })));
    //   map(acceptedFiles, file => {
    //     const reader = new FileReader()
    //     reader.onload = async () => {
    //       const userImage = reader.result
    //       setFiles([{url: userImage}])
    //     }
    //     reader.readAsDataURL(file)
    //   })
    // }
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
      map(acceptedFiles, (file) => {
        const reader = new FileReader()
        reader.onload = async () => {
          const userImage = reader.result
          imageUpload({
            variables: {
              documentId: user._id,
              imageUrls: [userImage],
              collection: 'teamMember',
            },
            refetchQueries: [{ query: GET_ME }],
          }).then(async ({ data }) => {
            enqueueSnackbar('Image Saved', {
              variant: 'success',
              autohideDuratation: 3000,
            })
          })
        }
        reader.readAsDataURL(file)
      })
    },
  })

  const thumbs = map(compact(files), (file) => (
    <div
      key={file.name}
      className="rounded avatar-image overflow-hidden d-140 bg-neutral-dark text-center font-weight-bold text-success d-flex justify-content-center align-items-center"
    >
      <div
        className="blur-load"
        style={{ backgroundImage: 'url(' + file.url_small + ')' }}
      >
        <img
          className="img-fluid img-fit-container rounded-sm"
          src={file.url || file.preview}
          alt="..."
          loading="lazy"
        />
      </div>
    </div>
  ))

  const onClick = (event) => {
    event.preventDefault()
    setLoader(true)

    updateUser({
      variables: {
        id: user._id,
        userInput: {
          profile: {
            firstname: firstname,
            lastname: lastname,
          },
          // email: email,
          role: role,
          phone: phone,
          userType,
          jobTitle: jobTitle,
          bioDescription: bioDescription,
          address,
          address2,
          city,
          state,
          zip,
          DoB,
          citizenship,
          FICO,
          firstTimeInvestor,
          yearsInvesting,
          realEstateInvestments,
          estimatedNetWorth,
          estimatedLiquidAssets,
          creditReport,
          scheduleOfRealEstate,
          personalFinancialStatement,
        },
      },
    })
      .then(async ({ data }) => {
        enqueueSnackbar('Changes Saved', {
          variant: 'success',
          autohideDuratation: 3000,
        })
        setLoader(false)
        // if (files[0])
        //   imageUpload({
        //     variables: {
        //       documentId: user._id,
        //       imageUrls: [files[0].url],
        //       collection: 'teamMember',
        //     },
        //   }).then( async ({ data }) => {
        //     enqueueSnackbar('Image Saved', {
        //       variant: 'success',
        //       autohideDuratation: 3000,
        //     })
        //   })
        handleCloseEditUser()
      })
      .catch(async (error) => {
        console.log(error)
        setLoader(false)
      })
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  return (
    <>
      {teamTable && (
        <ListItem button onClick={handleOpenEditUser} key="edit">
          <span className="btn-wrapper--label">Edit User</span>
        </ListItem>
      )}
      {personalSettings && (
        <MenuItem
          // button
          onClick={handleOpenEditUser}
          key="edit"
        >
          <span className="btn-wrapper--label">Edit User</span>
        </MenuItem>
      )}
      {lending && (
        <IconButton onClick={handleOpenEditUser} size="large">
          <FontAwesomeIcon
            icon={['far', 'pen']}
            className="font-size-lg"
            style={{ marginRight: 10 }}
          />
        </IconButton>
      )}
      <Dialog
        open={openEditUser}
        onClose={handleCloseEditUser}
        classes={{ paper: 'shadow-lg rounded' }}
        onKeyDown={stopPropagationForTab}
        onClick={(event) => event.stopPropagation()}
      >
        <DialogTitle>Edit User</DialogTitle>
        <ValidatorForm
          style={{
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={onClick}
        >
          <DialogContent>
            <BlockUi
              blocking={loader}
              loader={
                <PacmanLoader
                  color={'var(--primary)'}
                  loading={true}
                />
              }
              message={
                <div className="text-primary">
                  Updating Team Member
                </div>
              }
            >
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <div className="py-5 d-flex align-items-center justify-content-center">
                    <div className="dropzone rounded">
                      <div
                        {...getRootProps({
                          className: 'dropzone-upload-wrapper',
                        })}
                      >
                        <input {...getInputProps()} />
                        <div className="dropzone-inner-wrapper d-140 rounded dropzone-avatar">
                          <div className="avatar-icon-wrapper d-140 rounded m-2">
                            {!noChangeAllowed && (
                              <Tooltip title="Upload Personal Image">
                                <Button
                                  onClick={open}
                                  className="avatar-button badge shadow-sm btn-icon badge-position badge-position--bottom-right border-0 text-indent-0 d-40 badge-circle btn-first text-white"
                                >
                                  <CameraAltTwoToneIcon className="d-20" />
                                </Button>
                              </Tooltip>
                            )}
                            <div>
                              {isDragAccept && (
                                <div className="rounded overflow-hidden d-140 bg-success text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                  <CheckIcon className="d-40" />
                                </div>
                              )}
                              {isDragReject && (
                                <div className="rounded overflow-hidden d-140 bg-danger text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                  <CloseTwoToneIcon className="d-60" />
                                </div>
                              )}
                              {!isDragActive && (
                                <div className="rounded overflow-hidden d-140 bg-neutral-dark text-center font-weight-bold text-black-50 d-flex justify-content-center align-items-center">
                                  <AccountCircleTwoToneIcon className="d-50" />
                                </div>
                              )}
                            </div>

                            {thumbs.length > 0 && <div>{thumbs}</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    {map(inputFields, (section) => (
                      <Grid item xs={section.xs} md={section.md}>
                        {section.dropdown ? (
                          <FormControl
                            variant="outlined"
                            style={{ width: '100%' }}
                          >
                            <InputLabel required={true}>
                              {section.label}
                            </InputLabel>
                            <Select
                              value={section.value}
                              name={section.name}
                              onChange={(event) =>
                                section.set(event.target.value)
                              }
                              displayEmpty
                              required={true}
                              disabled={section.disabled}
                              label={section.label + '*'}
                            >
                              {map(section.options, (option) => (
                                <MenuItem
                                  value={option.value}
                                  key={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <TextValidator
                            fullWidth
                            label={section.label}
                            multiline={section.multiline}
                            value={section.value}
                            onChange={(event) =>
                              section.set(event.target.value)
                            }
                            placeholder={section.placeholder}
                            rows={section.rows}
                            required={true}
                            validators={section.validators}
                            errorMessages={section.errorMessages}
                            disabled={section.disabled}
                            variant="outlined"
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <div
                className="accordion mb-spacing-6-x2"
                style={{ width: '100%', marginTop: 10 }}
              >
                <Card
                  key={'lender'}
                  className={clsx('card-box', {
                    'panel-open': accordian,
                  })}
                >
                  <Card>
                    <div className="card-header">
                      <div className="panel-title">
                        <div className="accordion-toggle">
                          <Button
                            variant="text"
                            size="large"
                            className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                            onClick={() => toggleAccordion()}
                            aria-expanded={accordian}
                          >
                            <span>Lender Applicant Data</span>
                            <FontAwesomeIcon
                              icon={['fas', 'angle-up']}
                              className="font-size-xl accordion-icon"
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <Collapse in={accordian}>
                      <CardContent
                        style={{
                          marginTop: -10,
                          marginBottom: -10,
                          marginLeft: 10,
                          marginRight: 10,
                        }}
                      >
                        <Grid
                          container
                          className="list-group-flush"
                          spacing={1}
                        >
                          <Grid item xs={6}>
                            <DatePicker
                              name="DoB"
                              label="Date of Birth"
                              value={DoB}
                              onChange={(newValue) => {
                                setDoB(newValue)
                              }}
                              disabled={noChangeAllowed}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                />
                              )}
                              views={['year', 'month', 'day']}
                              openTo="year"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              select
                              variant="outlined"
                              margin="dense"
                              label="Citizenship status"
                              value={citizenship}
                              disabled={noChangeAllowed}
                              onChange={(event) =>
                                setCitizenship(event.target.value)
                              }
                            >
                              <MenuItem
                                key={'Is Citizen'}
                                value={'Is Citizen'}
                              >
                                {'Is Citizen'}
                              </MenuItem>
                              <MenuItem
                                key={'Is Not Citizen'}
                                value={'Is Not Citizen'}
                              >
                                {'Is Not Citizen'}
                              </MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              label="Street Address"
                              value={address}
                              disabled={noChangeAllowed}
                              onChange={(event) =>
                                setAddress(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              label="Street Address Line 2"
                              value={address2}
                              disabled={noChangeAllowed}
                              onChange={(event) =>
                                setAddress2(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              label="City"
                              value={city}
                              disabled={noChangeAllowed}
                              onChange={(event) =>
                                setCity(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              variant="outlined"
                              margin="dense"
                              label="State"
                              options={map(
                                STATES,
                                (state) => state.shortname,
                              )}
                              fullWidth={true}
                              value={state}
                              disabled={noChangeAllowed}
                              onChange={(event, newValue) => {
                                setState(newValue)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="State"
                                  margin="dense"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              label="Postal / Zip code"
                              value={zip}
                              disabled={noChangeAllowed}
                              onChange={(event) =>
                                setZip(event.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <NumericFormat
                              customInput={TextField}
                              fullWidth={true}
                              isNumericString={true}
                              decimalScale={0}
                              name="FICO"
                              value={Math.round(FICO) || ''}
                              onValueChange={(values) => {
                                const { value } = values
                                setFICO(value)
                              }}
                              type={'text'}
                              onFocus={(event) => {
                                event.target.select()
                              }}
                              variant="outlined"
                              margin="dense"
                              label="FICO Score"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              select
                              variant="outlined"
                              margin="dense"
                              label="First Time Investor?"
                              value={firstTimeInvestor}
                              disabled={noChangeAllowed}
                              onChange={(event) =>
                                setFirstTimeInvestor(
                                  event.target.value,
                                )
                              }
                            >
                              <MenuItem key={'Yes'} value={'Yes'}>
                                {'Yes'}
                              </MenuItem>
                              <MenuItem key={'No'} value={'No'}>
                                {'No'}
                              </MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              label="Years of Experience in real estate investing"
                              fullWidth={true}
                              value={yearsInvesting}
                              disabled={noChangeAllowed}
                              onChange={(event) =>
                                setYearsInvesting(event.target.value)
                              }
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              variant="outlined"
                              margin="dense"
                              label="# of real estate investments in the last 12 months"
                              fullWidth={true}
                              value={realEstateInvestments}
                              disabled={noChangeAllowed}
                              onChange={(event) =>
                                setRealEstateInvestments(
                                  event.target.value,
                                )
                              }
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <NumericFormat
                              customInput={TextField}
                              thousandSeparator={true}
                              prefix={'$'}
                              fullWidth={true}
                              isNumericString={true}
                              decimalScale={0}
                              name="Estimated Net Worth"
                              disabled={noChangeAllowed}
                              value={
                                Math.round(estimatedNetWorth) || ''
                              }
                              onValueChange={(values) => {
                                const { value } = values
                                setEstimatedNetWorth(value)
                              }}
                              type={'text'}
                              onFocus={(event) => {
                                event.target.select()
                              }}
                              variant="outlined"
                              margin="dense"
                              label="Estimated Net Worth"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <NumericFormat
                              customInput={TextField}
                              thousandSeparator={true}
                              prefix={'$'}
                              fullWidth={true}
                              isNumericString={true}
                              decimalScale={0}
                              name="Estimated Liquid Assets"
                              disabled={noChangeAllowed}
                              value={
                                Math.round(estimatedLiquidAssets) ||
                                ''
                              }
                              onValueChange={(values) => {
                                const { value } = values
                                setEstimatedLiquidAssets(value)
                              }}
                              type={'text'}
                              onFocus={(event) => {
                                event.target.select()
                              }}
                              variant="outlined"
                              margin="dense"
                              label="Estimated Liquid Assets"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>
                              Submit Credit Report
                            </Typography>
                            <FileUpload
                              documentId={user._id}
                              files={creditReport}
                              uploadFile={uploadFile(setCreditReport)}
                              deleteFile={deleteFile(setCreditReport)}
                              collection="Lending"
                              readOnly={noChangeAllowed}
                              button
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>
                              Schedule of Real Estate Owned
                            </Typography>
                            <FileUpload
                              documentId={user._id}
                              files={scheduleOfRealEstate}
                              uploadFile={uploadFile(
                                setScheduleOfRealEstate,
                              )}
                              deleteFile={deleteFile(
                                setScheduleOfRealEstate,
                              )}
                              collection="Lending"
                              readOnly={noChangeAllowed}
                              button
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>
                              Personal Financial Statement
                            </Typography>
                            <FileUpload
                              documentId={user._id}
                              files={personalFinancialStatement}
                              uploadFile={uploadFile(
                                setPersonalFinancialStatement,
                              )}
                              deleteFile={deleteFile(
                                setPersonalFinancialStatement,
                              )}
                              collection="Lending"
                              readOnly={noChangeAllowed}
                              button
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Card>
              </div>
              {noChangeAllowed && (
                <div>
                  * This User cannot be edited because they are apart
                  of another organization
                </div>
              )}
            </BlockUi>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseEditUser}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className="btn-primary m-2"
              type="submit"
            >
              <span className="btn-wrapper--label">Save</span>
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </>
  )
}

export default withSession(EditUserComponent)
