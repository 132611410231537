import isEmpty from 'lodash/isEmpty'
export const handlePlaceResult = (placeResult, property = {}) => {
  let location = {
    street_number: 'short_name',
    route: 'short_name',
    administrative_area_level_1: 'short_name',
    locality: 'long_name',
    postal_code: 'long_name',
  }
  location.loc = placeResult
    ? {
        lat: placeResult.geometry.location.lat(),
        lng: placeResult.geometry.location.lng(),
      }
    : null
  if (placeResult) {
    for (let i = 0; i < placeResult.address_components.length; i++) {
      const addressType = placeResult.address_components[i].types[0]
      if (location[addressType])
        location[addressType] =
          placeResult.address_components[i][location[addressType]]
    }
    for (var prop in location) {
      if (Object.prototype.hasOwnProperty.call(location, prop)) {
        if (
          location[prop] === 'long_name' ||
          location[prop] === 'short_name'
        )
          location[prop] = ''
      }
    }
  } else if (!isEmpty(property)) {
    location = {
      administrative_area_level_1: property.state,
      locality: property.city,
      postal_code: property.zip,
    }
    // if (props.propertySearch)
    //   this.setState({
    //     backDrop: true,
    //     progress: 0,
    //     progressText: 'Finding Property Data',
    //   })
  } else {
    location = {
      street_number: '',
      route: '',
      administrative_area_level_1: '',
      locality: '',
      postal_code: '',
    }
  }
  location.streetAddress = placeResult
    ? location.route.includes(' S St') ||
      location.route.includes(' N St') ||
      location.route.includes(' W St') ||
      location.route.includes(' E St')
      ? location.street_number +
        ' ' +
        location.route.replace(' St', '')
      : location.street_number + ' ' + location.route
    : ''
  return location
}
