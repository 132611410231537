/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:20:09
 * @ Modified by: David Helmick
 * @ Modified time: 2024-08-02 18:44:46
 * @ Description: funcction that handles the signout proocedure
 */

import { useNavigate } from "react-router-dom"

export function signOut (client, member = null) {
  // let navigate = useNavigate()
  client.resetStore()
  // if (member === 'RWN')
  //   navigate('RWNRedirect')
  // if (member === 'GRE')
  //   navigate('GRERedirect')
  localStorage.clear()
  // history.push(routes.SIGN_IN)
}