/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: David Helmick
 * @ Modified time: 2024-07-31 17:07:44
 * @ Description: Using link in forgot password email users can change their password here
 */

import React, { useEffect, useRef, useState } from 'react'

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  IconButton,
  Link,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import { UPDATE_USER } from '@/housefolios-components/PersonalSettings/mutations'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useParams } from 'react-router-dom';

function ResetPasswordComponent(props) {
  const { token = '', } = useParams()

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [newPasswordMask, setNewPasswordMask] = useState(true)
  const [confirmPasswordMask, setConfirmPasswordMask] = useState(true)
  const [tokenExpired, setTokenExpired] = useState(false)

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== newPassword) {
        return false
      }
      return true
    })
    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch')
    }
  })

  const ref = useRef(null)

  const checkPassword = async () => {
    if (ref.current && newPassword && confirmPassword)
      ref.current.isFormValid(false)
  }

  useEffect(() => {
    checkPassword()
  }, [newPassword])

  const [updateUser] = useMutation(UPDATE_USER)

  const onSubmit = (event) => {
    localStorage.setItem('token', token)
    updateUser({
      variables: {
        userInput: {
          password: newPassword,
          needPasswordReset: false,
        },
      },
    })
      .then(async ({ data }) => {
        localStorage.setItem('token', data.updateUser.token)
        window.location.reload()
      })
      .catch((error) => {
        localStorage.removeItem('token')
        setTokenExpired(true)
        return error
      })
    event.preventDefault()
  }
  return (
    <>
      <div className="app-wrapper bg-white min-vh-100">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Grid
                    item
                    md={10}
                    lg={8}
                    xl={4}
                    className="mx-auto"
                  >
                    <Card>
                      {tokenExpired ? (
                        <CardContent>
                          <div className="text-center mb-5">
                            <h1 className="display-4 mb-1 font-weight-bold">
                              Oops it looks like your link has
                              expired.
                            </h1>
                            <p className="font-size-lg mb-0">
                              Please go to the{' '}
                              <Link
                                href="/ForgotPassword"
                                style={{ color: 'blue' }}
                              >
                                <u>Forgot Password</u>
                              </Link>{' '}
                              page and enter the email address
                              associated with your account. If that
                              doesn't work please contact the team
                              member that added you.
                            </p>
                          </div>
                        </CardContent>
                      ) : (
                        <CardContent>
                          <div className="text-center mb-5">
                            <h1 className="display-4 mb-1 font-weight-bold">
                              Reset Password
                            </h1>
                            <p className="font-size-lg mb-0 text-black-50">
                              Create your new password!
                            </p>
                          </div>
                          <ValidatorForm
                            ref={ref}
                            onSubmit={(event) => onSubmit(event)}
                          >
                            <div>
                              <label className="font-weight-bold mb-2">
                                New Password
                              </label>
                              <TextValidator
                                fullWidth
                                variant="outlined"
                                id="textfield-email"
                                value={newPassword}
                                onChange={(event) =>
                                  setNewPassword(event.target.value)
                                }
                                type={
                                  newPasswordMask
                                    ? 'password'
                                    : 'text'
                                }
                                validators={[
                                  'required',
                                  'maxStringLength: 50',
                                  'minStringLength: 7',
                                ]}
                                errorMessages={[
                                  'This field is required',
                                  'You cannot exceed 50 characters',
                                  'Password must be at least 7 characters long',
                                ]}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() =>
                                          setNewPasswordMask(
                                            !newPasswordMask,
                                          )
                                        }
                                        size="small"
                                      >
                                        <Avatar
                                          style={{
                                            backgroundColor:
                                              'transparent',
                                            color: '#929292',
                                          }}
                                        >
                                          {newPasswordMask ? (
                                            <RemoveRedEyeIcon />
                                          ) : (
                                            <VisibilityOffIcon />
                                          )}
                                        </Avatar>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                // InputProps={{
                                //   startAdornment: (
                                //     <InputAdornment position="start">
                                //       <MailOutlineTwoToneIcon style={{ color: '#04a3e3' }} />
                                //     </InputAdornment>
                                //   ),
                                // }}
                              />
                            </div>
                            <div>
                              <label className="font-weight-bold mb-2 mt-2">
                                Confirm Password
                              </label>
                              <TextValidator
                                fullWidth
                                variant="outlined"
                                id="textfield-email"
                                value={confirmPassword}
                                onChange={(event) =>
                                  setConfirmPassword(
                                    event.target.value,
                                  )
                                }
                                validators={[
                                  'required',
                                  'maxStringLength: 50',
                                  'minStringLength: 7',
                                  'isPasswordMatch',
                                ]}
                                type={
                                  confirmPasswordMask
                                    ? 'password'
                                    : 'text'
                                }
                                errorMessages={[
                                  'This field is required',
                                  'You cannot exceed 50 characters',
                                  'Password must be at least 7 characters long',
                                  'Does not match password',
                                ]}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() =>
                                          setConfirmPasswordMask(
                                            !confirmPasswordMask,
                                          )
                                        }
                                        size="small"
                                      >
                                        <Avatar
                                          style={{
                                            backgroundColor:
                                              'transparent',
                                            color: '#929292',
                                          }}
                                        >
                                          {confirmPasswordMask ? (
                                            <RemoveRedEyeIcon />
                                          ) : (
                                            <VisibilityOffIcon />
                                          )}
                                        </Avatar>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                // InputProps={{
                                //   startAdornment: (
                                //     <InputAdornment position="start">
                                //       <MailOutlineTwoToneIcon style={{ color: '#04a3e3' }} />
                                //     </InputAdornment>
                                //   ),
                                // }}
                              />
                            </div>
                            <div className="text-center mb-5">
                              <Button
                                fullWidth
                                className="text-uppercase font-weight-bold font-size-sm mt-4"
                                type="submit"
                                style={{
                                  backgroundColor: '#04a3e3',
                                  color: 'white',
                                }}
                              >
                                Create Password
                              </Button>
                            </div>
                          </ValidatorForm>
                        </CardContent>
                      )}
                    </Card>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPasswordComponent
