import React, { useEffect } from 'react'
import clsx from 'clsx'

import { connect } from 'react-redux'

import isEmpty from 'lodash/isEmpty'

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions'

import { SidebarHeader, SidebarMenu } from '@/layout-components'
import SidebarFooter from '@/layout-components/SidebarFooter'
import withSession from '@/housefolios-components/Session/withSession'
import { useQuery } from '@apollo/client'
import RenewSession from '@/housefolios-components/Session/RenewSession'
import useNewMessageSubscription from '@/hooks/useNewMessageSubscription'
import useAffiliate from '@/hooks/useAffiliate'
import { Backdrop, CircularProgress } from '@mui/material'

const Sidebar = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile)
  }

  const {
    sidebarStyle,
    sidebarShadow,
    sidebarFooter,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setOpenFeedbackTour,
  } = props

  let isMember = false

  const [{ affiliate, affiliateLoading, urlAffiliate }] =
    useAffiliate({
      memberValue: props?.session?.me?.activeOrg?.member,
    })

  useNewMessageSubscription(props?.session?.me?._id, ['admin'], true)

  if (!affiliateLoading) {
    if (isEmpty(affiliate)) isMember = false
    else {
      isMember = true
    }
  }

  if (affiliateLoading)
    return (
      <Backdrop open={true} style={{ color: '#fff', zIndex: 2000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )

  return (
    <>
      <div
        className={clsx('app-sidebar', sidebarStyle, {
          'app-sidebar--shadow': sidebarShadow,
        })}
      >
        <SidebarHeader
          affiliate={affiliate}
          collapsed={props.collapsed}
        />
        <div className="app-sidebar--content">
          <SidebarMenu
            affiliate={affiliate}
            setOpenFeedbackTour={setOpenFeedbackTour}
          />
        </div>
        {isMember && sidebarFooter && !urlAffiliate && (
          <SidebarFooter />
        )}
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-sidebar-overlay', {
          'is-active': sidebarToggleMobile,
        })}
      />
      <RenewSession />
    </>
  )
}

const mapStateToProps = (state) => ({
  sidebarShadow: state.DeprecatedThemeOptions.sidebarShadow,
  sidebarFooter: state.DeprecatedThemeOptions.sidebarFooter,
  sidebarStyle: state.DeprecatedThemeOptions.sidebarStyle,
  sidebarToggleMobile:
    state.DeprecatedThemeOptions.sidebarToggleMobile,
})

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) =>
    dispatch(setSidebarToggleMobile(enable)),
})

export default withSession(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar),
)
