import { gql } from '@apollo/client'
import { AssumptionsFragments } from '@/housefolios-components/AssumptionsSettings/fragments'
import { AcquisitionFragments } from '@/housefolios-components/Property/fragments'

export const UPDATE_PORTFOLIO = gql`
  mutation updatePortfolio(
    $id: ID!
    $portfolioInput: PortfolioInput!
  ) {
    updatePortfolio(id: $id, portfolioInput: $portfolioInput) {
      _id
      name
    }
  }
`

export const ADD_PORTFOLIO = gql`
  mutation addPortfolio($portfolioInput: PortfolioInput!) {
    addPortfolio(portfolioInput: $portfolioInput) {
      _id
      name
      propertyCount
      defaultPortfolio
      assumptions {
        ...assumptions
      }
      organization {
        _id
        name
      }
    }
  }
  ${AssumptionsFragments.assumptions}
`

export const COPY_PORTFOLIO = gql`
  mutation copyPortfolio(
    $portfolioInput: PortfolioInput!
    $portfolioId: String!
  ) {
    copyPortfolio(
      portfolioInput: $portfolioInput
      portfolioId: $portfolioId
    ) {
      _id
      name
      propertyCount
      defaultPortfolio
      assumptions {
        ...assumptions
      }
    }
  }
  ${AssumptionsFragments.assumptions}
`

export const DELETE_PORTFOLIO = gql`
  mutation deletePortfolio($id: ID!) {
    deletePortfolio(id: $id) {
      count
    }
  }
`

export const SHARE_PORTFOLIO = gql`
  mutation sharePortfolio(
    $id: ID!
    $emails: [String!]
    $sharePermission: String
    $notificationId: String
  ) {
    sharePortfolio(
      id: $id
      emails: $emails
      sharePermission: $sharePermission
      notificationId: $notificationId
    ) {
      count
    }
  }
`

export const ADD_SHARED_PORTFOLIO = gql`
  mutation addSharedPortfolio($token: String!, $orgId: String!) {
    addSharedPortfolio(token: $token, orgId: $orgId) {
      _id
    }
  }
`

export const REMOVE_SHARED_PORTFOLIO = gql`
  mutation removeSharedPortfolio(
    $portfolioId: String!
    $orgId: String!
  ) {
    removeSharedPortfolio(portfolioId: $portfolioId, orgId: $orgId) {
      _id
    }
  }
`

export const UPDATE_SHARED_PORTFOLIO = gql`
  mutation updateSharedPortfolio(
    $portfolioId: String!
    $orgId: String!
    $sharePermission: String!
  ) {
    updateSharedPortfolio(
      portfolioId: $portfolioId
      orgId: $orgId
      sharePermission: $sharePermission
    ) {
      _id
    }
  }
`

export const UPDATE_PROPERTIES = gql`
  mutation updateProperties(
    $ids: [ID!]
    $propertyInput: propertyInput!
    $keepAssumptions: Boolean
  ) {
    updateProperties(
      ids: $ids
      propertyInput: $propertyInput
      keepAssumptions: $keepAssumptions
    ) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      estval
      comments
      strategy
      marketplaceStrategy
      strategies
      propertyType
      propertyUnitAmount
      propertyDashboard
      hideAddress
      hideDaysOnMarketplace
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
      compCount
      lot {
        _id
        owned
        status
      }
      description
      propertyDashboard
      proformaImages
      proformaImagePage
      proformaLogo
      proformaFloorPlan
      proformaRehabCost
      proformaHideAddress
      buyAndHoldOrder
      fixAndFlipOrder
      turnkeyOrder
      BRRRROrder
      shortTermRentalOrder
      buildToRentOrder
      plan
      kanbanProcessStageId
      portfolio {
        _id
        name
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      acquisition {
        analytics {
          ...analytics
        }
        analysis {
          ...analysis
        }
      }
    }
  }
  ${AcquisitionFragments.analysis}
  ${AcquisitionFragments.analytics}
`
