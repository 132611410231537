/**
 * @ Author: Housefolios
 * @ Create Time: 2023-03-22 13:37:52
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-04 12:17:21
 * @ Description: Dialog for updating access to a shared portfolio
 */

import React, { useState } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import map from 'lodash/map'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'

import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { Query } from '@apollo/client/react/components'
import { GET_ORGS_PORTFOLIO_SHARED_WITH } from '../queries'
import { useMutation } from '@apollo/client'
import {
  REMOVE_SHARED_PORTFOLIO,
  SHARE_PORTFOLIO,
  UPDATE_SHARED_PORTFOLIO,
} from '../mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { Close } from '@mui/icons-material'

function UpdateSharedPortfolioComponent(props) {
  const { orgId, portfolioId, rightMenu, sidebarMenu, shareAccess } =
    props

  const [openUpdatePortfolio, setOpenUpdatePortfolio] = useState(null)
  const [loader, setLoader] = useState(false)

  const [sharePermission, setSharePermission] = useState(
    props.sharePermission || 'viewOnly',
  )

  const handleOpenUpdatePortfolio = (event) => {
    setOpenUpdatePortfolio(true)
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const handleCloseUpdatePortfolio = (event) => {
    setOpenUpdatePortfolio(false)
    setLoader(false)
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const [updateSharedPortfolio] = useMutation(UPDATE_SHARED_PORTFOLIO)

  return (
    <>
      {rightMenu && (
        <ListItem button onClick={handleOpenUpdatePortfolio}>
          <Close style={{ marginRight: 5, color: '#757575' }} />
          <span>Update Shared Portfolio</span>
        </ListItem>
      )}
      {shareAccess && (
        <IconButton onClick={handleOpenUpdatePortfolio} size="large">
          <FontAwesomeIcon icon={['far', 'pen-to-square']} />
        </IconButton>
      )}
      {sidebarMenu && (
        <Tooltip
          title="Update Portfolio"
          classes={{ tooltip: 'tooltip-first' }}
          arrow
        >
          <IconButton
            onClick={handleOpenUpdatePortfolio}
            style={{
              backgroundColor: 'white',
              width: 30,
              height: 30,
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={openUpdatePortfolio}
        onClose={handleCloseUpdatePortfolio}
        maxWidth="sm"
        fullWidth={true}
        scroll="body"
        onClick={(event) => event.stopPropagation()}
      >
        <DialogTitle>{'Update Access'}</DialogTitle>
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              Removing Shared Portfolio
            </div>
          }
        >
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  select
                  name="sharePermission"
                  variant="outlined"
                  margin="dense"
                  label="Permission"
                  fullWidth={true}
                  // style={{ width: 175 }}
                  value={sharePermission}
                  onChange={(event) =>
                    setSharePermission(event.target.value)
                  }
                  required={true}
                >
                  <MenuItem key={'viewOnly'} value={'viewOnly'}>
                    {'View Only'}
                  </MenuItem>
                  <MenuItem key={'canEdit'} value={'canEdit'}>
                    {'Can Edit'}
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={handleCloseUpdatePortfolio}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateSharedPortfolio({
                  variables: {
                    orgId,
                    portfolioId,
                    sharePermission,
                  },
                  refetchQueries: [
                    {
                      query: GET_ORGS_PORTFOLIO_SHARED_WITH,
                      variables: {
                        portfolioId: portfolioId,
                      },
                    },
                    {
                      query: GET_ME,
                    },
                  ],
                })
                  .then(() => {
                    enqueueSnackbar(
                      `Portfolio succsesfully Updated`,
                      {
                        variant: 'success',
                        autoHideDuration: 3000,
                      },
                    )
                    handleCloseUpdatePortfolio()
                  })
                  .catch((error) => {
                    enqueueSnackbar(`Failed to Updated Portfolio`, {
                      variant: 'error',
                      autoHideDuration: 3000,
                    })
                    handleCloseUpdatePortfolio()
                  })
              }}
            >
              Update
            </Button>
          </DialogActions>
        </BlockUi>
      </Dialog>
    </>
  )
}
export default withSession(UpdateSharedPortfolioComponent)
