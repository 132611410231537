/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-04 12:15:08
 * @ Description: Dialog for sharing a portfolio with another through email
 */

import React, { useState } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'

import map from 'lodash/map'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import get from 'lodash/get'
import find from 'lodash/find'
import concat from 'lodash/concat'
import split from 'lodash/split'

import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { Query } from '@apollo/client/react/components'
import { GET_ORGS_PORTFOLIO_SHARED_WITH } from '../queries'
import { useMutation } from '@apollo/client'
import {
  REMOVE_SHARED_PORTFOLIO,
  SHARE_PORTFOLIO,
} from '../mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RemoveSharedPortfolio from '../RemoveSharedPortfolio'
import Autocomplete from '@mui/material/Autocomplete'
import UpdateSharedPortfolio from '../UpdateSharedPortfolio'

function SharePortfolioComponent(props) {
  const { sidebarMenu, portfolio = {} } = props

  const [emails, setEmails] = useState([])
  const [openSharePortfolio, setOpenSharePortfolio] = useState(false)
  const [openUnsharePortfolio, setOpenUnsharePortfolio] =
    useState(null)
  const [sharePermission, setSharePermission] = useState('viewOnly')
  const [loader, setLoader] = useState(false)
  const handleOpenSharePortfolio = () => {
    setOpenSharePortfolio(true)
    setEmails([])
  }
  const handleCloseSharePortfolio = () => {
    setOpenSharePortfolio(false)
    setLoader(false)
  }

  const handleOpenUnsharePortfolio = (id) => {
    setOpenUnsharePortfolio(id)
  }

  const handleCloseUnsharePortfolio = () => {
    setOpenUnsharePortfolio(null)
  }

  const [sharePortfolio] = useMutation(SHARE_PORTFOLIO)
  const [removeSharedPortfolio] = useMutation(REMOVE_SHARED_PORTFOLIO)

  return (
    <>
      {sidebarMenu && (
        <ListItem button onClick={handleOpenSharePortfolio}>
          <ShareIcon style={{ marginRight: 5, color: '#757575' }} />
          <span>Share Portfolio</span>
        </ListItem>
      )}
      <Query
        query={GET_ORGS_PORTFOLIO_SHARED_WITH}
        variables={{
          portfolioId: portfolio._id,
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return 'Loading...'
          if (error) return `Error! ${error.message}`
          const { orgsPortfolioSharedWith } = data
          return (
            <Dialog
              open={openSharePortfolio}
              onClose={handleCloseSharePortfolio}
              maxWidth="sm"
              fullWidth={true}
              scroll="body"
            >
              <DialogTitle>
                Share {portfolio.name} portfolio
              </DialogTitle>
              <BlockUi
                blocking={loader}
                loader={
                  <PacmanLoader
                    color={'var(--primary)'}
                    loading={true}
                  />
                }
                message={
                  <div className="text-primary">
                    Sharing Portfolio
                  </div>
                }
              >
                <ValidatorForm
                  onSubmit={(event) => {
                    setLoader(true)
                    sharePortfolio({
                      variables: {
                        id: portfolio._id,
                        emails,
                        sharePermission,
                      },
                    })
                      .then(() => {
                        enqueueSnackbar(`Email Sent`, {
                          variant: 'success',
                          autoHideDuration: 3000,
                        })
                        handleCloseSharePortfolio()
                      })
                      .catch((error) => {
                        enqueueSnackbar(`Failed to send email`, {
                          variant: 'error',
                          autoHideDuration: 3000,
                        })
                        setLoader(false)
                      })
                  }}
                  style={{
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <DialogContent>
                    <Grid container direction="row">
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          id="emails"
                          options={[]}
                          value={emails}
                          freeSolo
                          autoSelect
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Send Email To"
                              placeholder="Emails"
                              helperText="Enter comma separated emails (no spaces). Press enter"
                            />
                          )}
                          onChange={(event, inputs, reason) => {
                            if (
                              'createOption' == reason ||
                              'blur' == reason
                            ) {
                              let values = split(
                                inputs[inputs.length - 1],
                                ',',
                              )
                              let validatedItems = []

                              for (let value of values) {
                                if (
                                  String(value)
                                    .toLowerCase()
                                    .match(
                                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    )
                                )
                                  validatedItems.push(value)
                              }

                              let newList = concat(
                                emails,
                                validatedItems,
                              )
                              setEmails(newList)
                            } else if ('removeOption' == reason) {
                              setEmails(inputs)
                            } else if ('clear' == reason) {
                              setEmails([])
                            }
                          }}
                        />
                        {/* <TextValidator
                                          name="email"
                                          type="email"
                                          variant='outlined'
                                          margin='dense'
                                          autoFocus
                                          onChange={event => setEmail(event.target.value)}
                                          value={email}
                                          placeholder="Enter email address..."
                                          label="Email"
                                          fullWidth={true}
                                          validators={[
                                          'required',
                                          'maxStringLength: 50',
                                          'isEmail',
                                          ]}
                                          errorMessages={[
                                          'This field is required',
                                          'You cannot exceed 50 characters',
                                          'Not a valid email address',
                                          ]}
                                      /> */}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          select
                          name="sharePermission"
                          variant="outlined"
                          margin="dense"
                          label="Permission"
                          fullWidth={true}
                          // style={{ width: 175 }}
                          value={sharePermission}
                          onChange={(event) =>
                            setSharePermission(event.target.value)
                          }
                          required={true}
                        >
                          <MenuItem
                            key={'viewOnly'}
                            value={'viewOnly'}
                          >
                            {'View Only'}
                          </MenuItem>
                          <MenuItem key={'canEdit'} value={'canEdit'}>
                            {'Can Edit'}
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} style={{ padding: 10 }}>
                        {!isEmpty(orgsPortfolioSharedWith) && (
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography>Shared With</Typography>
                            </Grid>
                            {map(orgsPortfolioSharedWith, (org) => (
                              <>
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Grid item>
                                      <Avatar>
                                        {head(org.name)}
                                      </Avatar>
                                    </Grid>
                                    <Grid item>
                                      <Grid
                                        container
                                        direction="column"
                                        className="line-height-sm"
                                      >
                                        <Grid item>
                                          <b className="font-size-lg">
                                            {org.name}
                                          </b>
                                        </Grid>
                                        <Grid item>
                                          <span className="text-black-50 d-block">
                                            {org.email}
                                          </span>
                                        </Grid>
                                        <Grid item>
                                          <span className="text-black-50 d-block">
                                            {get(
                                              find(
                                                org.sharedPortfolios,
                                                [
                                                  '_id',
                                                  portfolio._id,
                                                ],
                                              ),
                                              'sharePermission',
                                            ) === 'canEdit'
                                              ? 'Can Edit'
                                              : 'View Only'}
                                          </span>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Typography style={{ flex: 1 }} />
                                    <Grid item>
                                      <UpdateSharedPortfolio
                                        orgId={org._id}
                                        portfolioId={portfolio._id}
                                        sharePermission={get(
                                          find(org.sharedPortfolios, [
                                            '_id',
                                            portfolio._id,
                                          ]),
                                          'sharePermission',
                                        )}
                                        shareAccess
                                      />
                                    </Grid>
                                    <Grid item>
                                      <RemoveSharedPortfolio
                                        orgId={org._id}
                                        portfolioId={portfolio._id}
                                        shareAccess
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </>
                            ))}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="text"
                      size="small"
                      onClick={handleCloseSharePortfolio}
                    >
                      <Typography>CANCEL</Typography>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      type="submit"
                      disabled={loader}
                    >
                      <Typography>SHARE</Typography>
                    </Button>
                  </DialogActions>
                </ValidatorForm>
              </BlockUi>
            </Dialog>
          )
        }}
      </Query>
    </>
  )
}
export default withSession(SharePortfolioComponent)
