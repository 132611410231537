/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: David Helmick
 * @ Modified time: 2024-06-19 20:03:22
 * @ Description: Component to upload new images to a property
 */

import React, { useEffect, useState, useCallback } from 'react'

import { Button, Divider, Grid } from '@mui/material'
import Alert from '@mui/material/Alert'
import map from 'lodash/map'

import { useDropzone } from 'react-dropzone'

import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import CheckIcon from '@mui/icons-material/Check'
import { useMutation } from '@apollo/client'
import { IMAGE_UPLOAD } from '@/housefolios-components/TitleButtons/AddPropertyButton/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { enqueueSnackbar } from 'notistack'
import { head, property } from 'lodash'

function CenterPageImagesUploaderComponent(props) {
  const { propertyId } = props
  const [files, setFiles] = useState([])

  const [imageUpload] = useMutation(IMAGE_UPLOAD)

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
      await Promise.all(
        map(acceptedFiles, (file) => {
          const reader = new FileReader()
          return new Promise((resolve, reject) => {
            reader.onload = async () => {
              const image = reader.result
              await imageUpload({
                variables: {
                  documentId: propertyId,
                  imageUrls: [image],
                  collection: 'property',
                },
                refetchQueries: [
                  {
                    query: GET_PROPERTY,
                    variables: { id: propertyId },
                  },
                ],
              }).then(async ({ data }) => {
                enqueueSnackbar('Image Saved', {
                  variant: 'success',
                  autohideDuratation: 3000,
                })
                // if (props.handleUpdateMarket)
                //   props.handleUpdateMarket()
                resolve(image)
              })
            }
            reader.readAsDataURL(file)
          })
        }),
      )
      // const fileURLs = await Promise.all(map(acceptedFiles, file => {
      //   const reader = new FileReader()
      //   return new Promise((resolve, reject) => {
      //     reader.onload = async () => {
      //       const image = reader.result
      //       resolve(image)
      //     }
      //     reader.readAsDataURL(file)
      //   })
      // }))
      // for (let i = 0; i < fileURLs.length; i++) {
      //   const image = fileURLs[i]
      //   await imageUpload({
      //     variables: {
      //       documentId: propertyId,
      //       imageUrls: [image],
      //       collection: 'property',
      //     },
      //     refetchQueries: [
      //       { query: GET_PROPERTY, variables: { id: propertyId } }
      //     ]
      //   }).then(async ({ data }) => {
      //     enqueueSnackbar('Image Saved', {
      //       variant: 'success',
      //       autohideDuratation: 3000,
      //     })
      //     if (props.handleUpdateMarket) props.handleUpdateMarket()
      //   })
      // }
    },
  })

  const thumbs = files.map((file) => (
    <Grid item md={4} className="p-2" key={file.name}>
      <div
        className="p-2 bg-white shadow-xxl border-dark card-box d-flex overflow-hidden"
        style={{ width: '100%' }}
      >
        <img
          className="img-fluid img-fit-container rounded-sm"
          src={file.preview}
          alt="..."
        />
      </div>
    </Grid>
  ))

  useEffect(
    () => () => {
      files.forEach((file) =>
        URL.revokeObjectURL(file ? file.preview : null),
      )
    },
    [files],
  )

  return (
    <>
      <div className="p-4">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <div className="dropzone">
              <div
                {...getRootProps({
                  className: 'dropzone-upload-wrapper',
                })}
              >
                <input {...getInputProps()} />
                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt">
                  {isDragAccept && (
                    <div>
                      <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg text-white">
                        <CheckIcon className="d-50" />
                      </div>
                      <div className="font-size-xs text-success">
                        Valid Images!
                      </div>
                    </div>
                  )}
                  {isDragReject && (
                    <div>
                      <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg text-white">
                        <CloseTwoToneIcon className="d-50" />
                      </div>
                      <div className="font-size-xs text-danger">
                        These files are not images!
                      </div>
                    </div>
                  )}
                  {!isDragActive && (
                    <div>
                      <div className="d-90 btn-icon mb-3 hover-scale-rounded bg-white shadow-light-sm rounded-lg text-first">
                        <CloudUploadTwoToneIcon className="d-40" />
                      </div>
                      <div className="font-size-sm">
                        Drag and drop images here
                      </div>
                    </div>
                  )}
                  <small className="py-2 text-black-50">or</small>
                  <div>
                    <Button className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                      <span className="px-2">Browse Files</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="p-3 bg-secondary">
        {thumbs.length > 0 && (
          <div>
            <Alert severity="success" className="text-center mb-3">
              You have uploaded <b>{thumbs.length}</b> files!
            </Alert>
            {/* <Grid container spacing={0}>
                {thumbs}
              </Grid> */}
          </div>
        )}
      </div>
      <Divider style={{ marginBottom: 5 }} />
    </>
  )
}

export default CenterPageImagesUploaderComponent
