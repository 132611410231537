/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2024-05-29 14:43:12
 * @ Description: Card to review and change billing plan
 */

import React, { useState } from 'react'
import clsx from 'clsx'
import map from 'lodash/map'
import split from 'lodash/split'
import upperFirst from 'lodash/upperFirst'
import round from 'lodash/round'
import { makeStyles } from '@mui/styles'
import isEmpty from 'lodash/isEmpty'
import replace from 'lodash/replace'
import {
  AppBar,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Dialog,
  Grid,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material'

import withSession from '@/housefolios-components/Session/withSession'
import { useMutation } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { CREATE_SESSION } from '@/housefolios-components/LaunchpadComponents/mutations'
import { GET_SUBSCRIPTION_PLAN } from '@/housefolios-components/CreateAccount/CreateAccountCard/mutations'
import LenderFinancingGradient from '@/assets/images/LenderFinancingGradient.png'
import DollarCloud from '@/assets/images/DollarCloud.png'
import FinancingNews from '@/assets/images/Financing_News.png'
import Lending from '../../Dialogs/Lending'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(3),
    color: 'white',
    flex: 1,
  },
}))
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function LenderFinancing(props) {
  const { property } = props
  const classes = useStyles()

  return (
    <>
      <Card
        className="w-100 d-flex flex-column flex-grow-1 justify-content-center align-items-center shadow-xxl text-center p-4 text-white"
        style={{
          backgroundSize: 'cover',
          backgroundImage: 'url(' + LenderFinancingGradient + ')',
          position: 'relative',
        }}
      >
        {/* <img src={FinancingNews} style={{ width: '100%', padding: 0 }} /> */}
        <span className="ribbon-angle ribbon-angle--top-right ribbon-warning">
          <small>New</small>
        </span>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={3}
        >
          <Grid item className="gridItem2">
            <img src={DollarCloud} style={{ maxWidth: 100 }} />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid item style={{ textAlign: 'left' }}>
                <Typography
                  variant="h5"
                  style={{ fontFamily: 'revert' }}
                >
                  Looking for a lender?
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: 'left' }}>
                <Typography variant="h5">
                  Housefolios now offers{' '}
                  <b
                    style={{ fontSize: '30px', fontStyle: 'italic' }}
                  >
                    100% FINANCING
                  </b>{' '}
                  on properties!
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: 'left' }}>
                <Typography variant="subtitle2">
                  *To apply for lending, select an analyzed property,
                  go to Financing, and click “Apply for Lending”.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* <Typography style={{ flex: 1 }} />
                <Grid item style={{ marginRight: 50}}>
                  <Lending property={property} shared={props.shared} canDrag={props.canDrag} />
                </Grid>        */}
        </Grid>
      </Card>
    </>
  )
}

export default withSession(LenderFinancing)
