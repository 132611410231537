import RehabCalc from '@/housefolios-components/Dialogs/RehabCalc'
import { InputAdornment, TextField, Typography } from '@mui/material'
import sum from 'lodash/sum'
import { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import KingBedIcon from '@mui/icons-material/KingBed'
import BathtubIcon from '@mui/icons-material/Bathtub'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import HomeIcon from '@mui/icons-material/Home';
import RoofingIcon from '@mui/icons-material/Roofing';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import YardIcon from '@mui/icons-material/Yard';
import VillaIcon from '@mui/icons-material/Villa';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'; // Interior
import LayersIcon from '@mui/icons-material/Layers'; // Flooring
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Doors
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'; // Electrical
import PlumbingIcon from '@mui/icons-material/Plumbing'; // Plumbing
import AcUnitIcon from '@mui/icons-material/AcUnit'; // HVAC
import KitchenIcon from '@mui/icons-material/Kitchen'; // Kitchen
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'; // Miscellaneous



const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const numberFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

export const createPepDealsauceEstimateFields = (
  property,
  estimateValues = {},
  assumptionsInput = {},
) => {
  const propertySqft =
    estimateValues.estimateSqft || sum(property?.sqft)
  const oneCarGarageSqft = estimateValues.oneCarGarage ? 275 : 0
  const twoCarGarageSqft = estimateValues.twoCarGarage ? 500 : 0
  const garageSqft = oneCarGarageSqft + twoCarGarageSqft
  const roofMultiplier = 1.5
  const totalSqft = propertySqft + garageSqft

  return [
    {
      sectionName: 'Property',
      description: 'Property panel',
      sectionIcon: <HomeIcon />,
      panel: 'ep',
      noTotal: true,
      fields: [
        {
          label: 'Beds',
          icon: <KingBedIcon />,
          name: 'estimateBeds',
          value: estimateValues.estimateBeds || sum(property?.beds),
          type: 'number',
          sectionName: 'Property',
        },
        {
          label: 'Baths',
          icon: <BathtubIcon />,
          name: 'estimateBaths',
          value: estimateValues.estimateBaths || sum(property?.baths),
          type: 'number',
          sectionName: 'Property',
        },
        {
          label: 'Sqft',
          icon: <SquareFootIcon />,
          name: 'estimateSqft',
          value: estimateValues.estimateSqft || sum(property?.sqft),
          type: 'number',
          sectionName: 'Property',
        },
        {
          label: 'Year',
          icon: <CalendarTodayIcon />,
          name: 'estimateYear',
          value: estimateValues.estimateYear || property?.year,
          type: 'number',
          sectionName: 'Property',
        },
      ],
    },
    {
      sectionName: 'Roofing',
      sectionIcon: <RoofingIcon />,
      description: 'Roofing panel',
      panel: 'e0',
      notes: estimateValues.roofNotes,
      notesName: 'roofNotes',
      fields: [
        {
          label: 'Does the roof need to be replaced',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateRoofCostPerSqft',
          assumptionValue: assumptionsInput.estimateRoofCostPerSqft,
          name: 'replaceRoof',
          value: estimateValues.replaceRoof,
          type: 'Boolean',
          total: estimateValues.replaceRoof
            ? (assumptionsInput.estimateRoofCostPerSqft *
                (propertySqft * roofMultiplier)) /
                100 || 0
            : 0,
          sectionName: 'Roofing',
        },
        {
          label: 'Are you tearing off the old roof?',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateRoofRemoval',
          assumptionValue: assumptionsInput.estimateRoofRemoval,
          name: 'removeRoof',
          value: estimateValues.removeRoof,
          type: 'Boolean',
          total: estimateValues.removeRoof
            ? assumptionsInput.estimateRoofRemoval +
                ((garageSqft * roofMultiplier) / 100) * 50 || 0
            : 0,
          sectionName: 'Roofing',
        },
        {
          label: 'Roof Cert',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateRoofCert',
          assumptionValue: assumptionsInput.estimateRoofCert,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateRoofCert,
          sectionName: 'Roofing',
        },
        {
          label: 'Detatched garage roofing',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateDetacheGarageRoofingPerSqft',
          assumptionValue:
            assumptionsInput.estimateDetacheGarageRoofingPerSqft,
          name: 'twoCarGarage',
          value: estimateValues.twoCarGarage,
          type: 'Boolean',
          booleanLabel: 'Two Car',
          secondName: 'oneCarGarage',
          secondValue: estimateValues.oneCarGarage,
          secondType: 'Boolean',
          secondBooleanLabel: 'One Car',
          total:
            (assumptionsInput.estimateDetacheGarageRoofingPerSqft *
              (garageSqft * roofMultiplier)) /
              100 || 0,
          sectionName: 'Roofing',
        },
        {
          label:
            'How many linear feet of soffit and/or fascia need to be replaced',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateSoffitAndFasciaCost',
          assumptionValue:
            assumptionsInput.estimateSoffitAndFasciaCost,
          name: 'soffitAndFasciaSqft',
          value: estimateValues.soffitAndFasciaSqft,
          type: 'number',
          total:
            estimateValues.soffitAndFasciaSqft *
              assumptionsInput.estimateSoffitAndFasciaCost || 0,
          sectionName: 'Roofing',
        },
        {
          label: 'Miscellaneous roofing expenses',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateOtherRoofExpenses',
          assumptionValue: assumptionsInput.estimateOtherRoofExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateOtherRoofExpenses || 0,
          sectionName: 'Roofing',
        },
      ],
    },
    {
      sectionName: 'Windows',
      sectionIcon: <ViewCompactIcon />,
      description: 'Windows panel',
      panel: 'e1',
      notes: estimateValues.windowNotes,
      notesName: 'windowNotes',
      fields: [
        {
          label: 'How many windows are you replacing?',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateWindowReplacement',
          assumptionValue: assumptionsInput.estimateWindowReplacement,
          name: 'replaceWindowCount',
          value: estimateValues.replaceWindowCount,
          type: 'number',
          total:
            estimateValues.replaceWindowCount *
              assumptionsInput.estimateWindowReplacement || 0,
          sectionName: 'Windows',
        },
        {
          label: 'How many broken window panes are being repaired?',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateWindowPaneRepair',
          assumptionValue: assumptionsInput.estimateWindowPaneRepair,
          name: 'paneRepairCount',
          value: estimateValues.paneRepairCount,
          type: 'number',
          total:
            estimateValues.paneRepairCount *
              assumptionsInput.estimateWindowPaneRepair || 0,
          sectionName: 'Windows',
        },
        {
          label: 'How many sets of shutters are you adding?',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateShutterRepair',
          assumptionValue: assumptionsInput.estimateShutterRepair,
          name: 'shutterRepairCount',
          value: estimateValues.shutterRepairCount,
          type: 'number',
          total:
            estimateValues.shutterRepairCount *
              assumptionsInput.estimateShutterRepair || 0,
          sectionName: 'Windows',
        },
        {
          label: 'Miscellaneous window expenses',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateOtherWindowExpenses',
          assumptionValue:
            assumptionsInput.estimateOtherWindowExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateOtherWindowExpenses,
          sectionName: 'Windows',
        },
      ],
    },
    {
      sectionName: 'Landscaping',
      sectionIcon: <YardIcon />,
      description: 'Landscaping panel',
      panel: 'e2',
      notes: estimateValues.landscapingNotes,
      notesName: 'landscapingNotes',
      fields: [
        {
          label: 'Simple front yard',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateSimpleFrontYard',
          assumptionValue: assumptionsInput.estimateSimpleFrontYard,
          name: 'frontYardCount',
          value: estimateValues.frontYardCount,
          type: 'number',
          total:
            estimateValues.frontYardCount *
              assumptionsInput.estimateSimpleFrontYard || 0,
          sectionName: 'Landscaping',
        },
        {
          label: 'New sprinkler system ($400 - $950)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateSprinkler',
          assumptionValue: assumptionsInput.estimateSprinkler,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateSprinkler,
          sectionName: 'Landscaping',
        },
        {
          label: 'Umbrella a tree',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateUmbrellaTreeCost',
          assumptionValue: assumptionsInput.estimateUmbrellaTreeCost,
          name: 'umbrellaTreeCount',
          value: estimateValues.umbrellaTreeCount,
          type: 'number',
          total:
            estimateValues.umbrellaTreeCount *
              assumptionsInput.estimateUmbrellaTreeCost || 0,
          sectionName: 'Landscaping',
        },
        {
          label:
            'Remove large tree with a thick trunk ($2000 - $3000)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateRemoveTreeCost',
          assumptionValue: assumptionsInput.estimateRemoveTreeCost,
          name: 'removeTreeCount',
          value: estimateValues.removeTreeCount,
          type: 'number',
          total:
            estimateValues.removeTreeCount *
              assumptionsInput.estimateRemoveTreeCost || 0,
          sectionName: 'Landscaping',
        },
        {
          label: 'Fencing (wood $16, vinyl $22 per linear foot)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateFencing',
          assumptionValue: assumptionsInput.estimateFencing,
          name: 'fencingFeet',
          value: estimateValues.fencingFeet,
          type: 'number',
          total:
            estimateValues.fencingFeet *
              assumptionsInput.estimateFencing || 0,
          sectionName: 'Landscaping',
        },
        {
          label:
            'Concrete ($5/sq ft to remove and $7/sq ft to install)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateConcretePerSqft',
          assumptionValue: assumptionsInput.estimateConcretePerSqft,
          name: 'concreteSqft',
          value: estimateValues.concreteSqft,
          type: 'number',
          total:
            estimateValues.concreteSqft *
              assumptionsInput.estimateConcretePerSqft || 0,
          sectionName: 'Landscaping',
        },
        {
          label: 'Miscellaneous landscaping expenses',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateOtherLandscapingExpenses',
          assumptionValue:
            assumptionsInput.estimateOtherLandscapingExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateOtherLandscapingExpenses,
          sectionName: 'Landscaping',
        },
      ],
    },
    {
      sectionName: 'Exterior',
      sectionIcon: <VillaIcon />,
      description: 'Exterior panel',
      panel: 'e3',
      notes: estimateValues.exteriorNotes,
      notesName: 'exteriorNotes',
      fields: [
        {
          label:
            'Paint cost per sqft (input $2.50 for a 2 story house requiring scaffolding & ladders)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimatePaintCostPerSqftExterior',
          assumptionValue:
            assumptionsInput.estimatePaintCostPerSqftExterior,
          name: '',
          value: numberFormat.format(totalSqft) + ' sqft',
          type: 'disabled',
          disabled: true,
          total:
            totalSqft *
              assumptionsInput.estimatePaintCostPerSqftExterior || 0,
          sectionName: 'Exterior',
        },
        {
          label: 'Power wash',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimatePowerWash',
          assumptionValue: assumptionsInput.estimatePowerWash,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimatePowerWash,
          sectionName: 'Exterior',
        },
        {
          label: 'Cement fiber siding per sqft (hardi-panel)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateCementFiberSidingPerSqft',
          assumptionValue:
            assumptionsInput.estimateCementFiberSidingPerSqft,
          name: 'cementFiberSqft',
          value: estimateValues.cementFiberSqft,
          type: 'number',
          total:
            estimateValues.cementFiberSqft *
              assumptionsInput.estimateCementFiberSidingPerSqft || 0,
          sectionName: 'Exterior',
        },
        {
          label: 'Vinyl siding per sqft ($4-$6)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateVinylSidingPerSqft',
          assumptionValue:
            assumptionsInput.estimateVinylSidingPerSqft,
          name: 'vinlySidingSqft',
          value: estimateValues.vinlySidingSqft,
          type: 'number',
          total:
            estimateValues.vinlySidingSqft *
              assumptionsInput.estimateVinylSidingPerSqft || 0,
          sectionName: 'Exterior',
        },
        {
          label: 'Stucco per sqft ($6-$8)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateStuccoPerSqft',
          assumptionValue: assumptionsInput.estimateStuccoPerSqft,
          name: 'stuccoSqft',
          value: estimateValues.stuccoSqft,
          type: 'number',
          total:
            estimateValues.stuccoSqft *
              assumptionsInput.estimateStuccoPerSqft || 0,
          sectionName: 'Exterior',
        },
        {
          label: 'Front porch',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateFrontPorch',
          assumptionValue: assumptionsInput.estimateFrontPorch,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateFrontPorch,
          sectionName: 'Exterior',
        },
        {
          label: 'Back deck/porch',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateBackPorch',
          assumptionValue: assumptionsInput.estimateBackPorch,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateBackPorch,
          sectionName: 'Exterior',
        },
        {
          label: 'Miscellaneous exterior expenses',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateOtherExteriorExpenses',
          assumptionValue:
            assumptionsInput.estimateOtherExteriorExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateOtherExteriorExpenses,
          sectionName: 'Exterior',
        },
      ],
    },
    {
      sectionName: 'Interior',
      sectionIcon: <MeetingRoomIcon />,
      description: 'Interior panel',
      panel: 'e4',
      notes: estimateValues.interiorNotes,
      notesName: 'interiorNotes',
      fields: [
        {
          label: 'Paint',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimatePaintCostPerSqftInterior',
          assumptionValue:
            assumptionsInput.estimatePaintCostPerSqftInterior,
          name: '',
          value: numberFormat.format(propertySqft) + ' sqft',
          type: 'disabled',
          disabled: true,
          total:
            propertySqft *
              assumptionsInput.estimatePaintCostPerSqftInterior || 0,
          sectionName: 'Interior',
        },
        {
          label:
            "Patch drywall (2' x 2' path = $25 or 8' x 4' = $30)",
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimatePatchDrywall',
          assumptionValue: assumptionsInput.estimatePatchDrywall,
          name: 'patchDrywallCount',
          value: estimateValues.patchDrywallCount,
          type: 'number',
          total:
            estimateValues.patchDrywallCount *
              assumptionsInput.estimatePatchDrywall || 0,
          sectionName: 'Interior',
        },
        {
          label: 'Scrape popcorn off ceilings sqft',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateScrapePopcornPerSqft',
          assumptionValue:
            assumptionsInput.estimateScrapePopcornPerSqft,
          name: 'popcornSqft',
          value: estimateValues.popcornSqft,
          type: 'number',
          total:
            estimateValues.popcornSqft *
              assumptionsInput.estimateScrapePopcornPerSqft || 0,
          sectionName: 'Interior',
        },
        {
          label: 'Texture wall per sqft',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateTextureWallPerSqft',
          assumptionValue:
            assumptionsInput.estimateTextureWallPerSqft,
          name: 'textureWallSqft',
          value: estimateValues.textureWallSqft,
          type: 'number',
          total:
            estimateValues.textureWallSqft *
              assumptionsInput.estimateTextureWallPerSqft || 0,
          sectionName: 'Interior',
        },
        {
          label:
            'Wallpaper removal (insert number of rooms requiring removal)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateWallPaperRemoval',
          assumptionValue: assumptionsInput.estimateWallPaperRemoval,
          name: 'wallpaperRemovalCount',
          value: estimateValues.wallpaperRemovalCount,
          type: 'number',
          total:
            estimateValues.wallpaperRemovalCount *
              assumptionsInput.estimateWallPaperRemoval || 0,
          sectionName: 'Interior',
        },
        {
          label: 'Baseboards and casings (whole house) $1 per sqft',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateBaseboardsAndCasingsPerSqft',
          assumptionValue:
            assumptionsInput.estimateBaseboardsAndCasingsPerSqft,
          name: '',
          value: numberFormat.format(propertySqft) + ' sqft',
          type: 'disabled',
          disabled: true,
          total:
            propertySqft *
              assumptionsInput.estimateBaseboardsAndCasingsPerSqft ||
            0,
          sectionName: 'Interior',
        },
        {
          label: 'Frame outs',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateFrameOuts',
          assumptionValue: assumptionsInput.estimateFrameOuts,
          name: 'frameOutCount',
          value: estimateValues.frameOutCount,
          type: 'number',
          total:
            estimateValues.frameOutCount *
              assumptionsInput.estimateFrameOuts || 0,
          sectionName: 'Interior',
        },
        {
          label: 'Miscellaneous interior expenses',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateOtherInteriorExpenses',
          assumptionValue:
            assumptionsInput.estimateOtherInteriorExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateOtherInteriorExpenses,
          sectionName: 'Interior',
        },
      ],
    },
    {
      sectionName: 'Flooring',
      sectionIcon: <LayersIcon />,
      description: 'Flooring panel',
      panel: 'e5',
      notes: estimateValues.flooringNotes,
      notesName: 'flooringNotes',
      fields: [
        {
          label: 'Luxury Vinyl Plank (LVP)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateLuxuryVinylPlankPerSqft',
          assumptionValue:
            assumptionsInput.estimateLuxuryVinylPlankPerSqft,
          name: '',
          value: numberFormat.format(propertySqft) + ' sqft',
          type: 'disabled',
          disabled: true,
          total:
            propertySqft *
              assumptionsInput.estimateLuxuryVinylPlankPerSqft || 0,
          sectionName: 'Flooring',
        },
        {
          label: 'Tile (sqft)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateTilePerSqft',
          assumptionValue: assumptionsInput.estimateTilePerSqft,
          name: 'tileSqft',
          value: estimateValues.tileSqft,
          type: 'number',
          total:
            estimateValues.tileSqft *
              assumptionsInput.estimateTilePerSqft || 0,
          sectionName: 'Flooring',
        },
        {
          label:
            'Hardwood or laminate new $8 or refinish $2.50 (sqft)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateHardwoodPerSqft',
          assumptionValue: assumptionsInput.estimateHardwoodPerSqft,
          name: 'hardwoodSqft',
          value: estimateValues.hardwoodSqft,
          type: 'number',
          total:
            estimateValues.hardwoodSqft *
              assumptionsInput.estimateHardwoodPerSqft || 0,
          sectionName: 'Flooring',
        },
        {
          label: 'Carpet (sqft) but usually purchased by square yard',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateCarpetPerSqft',
          assumptionValue: assumptionsInput.estimateCarpetPerSqft,
          name: 'carpetSqft',
          value: estimateValues.carpetSqft,
          type: 'number',
          total:
            estimateValues.carpetSqft *
              assumptionsInput.estimateCarpetPerSqft || 0,
          sectionName: 'Flooring',
        },
        {
          label: 'Miscellaneous flooring expenses',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateFlooringExpenses',
          assumptionValue: assumptionsInput.estimateFlooringExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateFlooringExpenses,
          sectionName: 'Flooring',
        },
      ],
    },
    {
      sectionName: 'Doors',
      sectionIcon: <ExitToAppIcon />,
      description: 'Doors panel',
      panel: 'e6',
      notes: estimateValues.doorNotes,
      notesName: 'doorNotes',
      fields: [
        {
          label: 'Front door with hardware',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateFrontDoorCost',
          assumptionValue: assumptionsInput.estimateFrontDoorCost,
          name: 'frontDoorCount',
          value: estimateValues.frontDoorCount,
          type: 'number',
          total:
            estimateValues.frontDoorCount *
              assumptionsInput.estimateFrontDoorCost || 0,
          sectionName: 'Doors',
        },
        {
          label: 'Exterior door with hardware',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateExteriorDoorCost',
          assumptionValue: assumptionsInput.estimateExteriorDoorCost,
          name: 'exteriorDoorCount',
          value: estimateValues.exteriorDoorCount,
          type: 'number',
          total:
            estimateValues.exteriorDoorCount *
              assumptionsInput.estimateExteriorDoorCost || 0,
          sectionName: 'Doors',
        },
        {
          label: 'Sliding glass door or exterior French door',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateSlidingDoorCost',
          assumptionValue: assumptionsInput.estimateSlidingDoorCost,
          name: 'slidingDoorCount',
          value: estimateValues.slidingDoorCount,
          type: 'number',
          total:
            estimateValues.slidingDoorCount *
              assumptionsInput.estimateSlidingDoorCost || 0,
          sectionName: 'Doors',
        },
        {
          label: 'Interior door kits with hardware',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateInteriorDoorCost',
          assumptionValue: assumptionsInput.estimateInteriorDoorCost,
          name: 'interiorDoorCount',
          value: estimateValues.interiorDoorCount,
          type: 'number',
          total:
            estimateValues.interiorDoorCount *
              assumptionsInput.estimateInteriorDoorCost || 0,
          sectionName: 'Doors',
        },
        {
          label: 'Interior French doors with hardware',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateInteriorFrenchDoorCost',
          assumptionValue:
            assumptionsInput.estimateInteriorFrenchDoorCost,
          name: 'interiorFrenchDoorCount',
          value: estimateValues.interiorFrenchDoorCount,
          type: 'number',
          total:
            estimateValues.interiorFrenchDoorCount *
              assumptionsInput.estimateInteriorFrenchDoorCost || 0,
          sectionName: 'Doors',
        },
        {
          label:
            'Pocket door - entire kit with framing & drywall repair',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimatePocketDoorCost',
          assumptionValue: assumptionsInput.estimatePocketDoorCost,
          name: 'pocketDoorCount',
          value: estimateValues.pocketDoorCount,
          type: 'number',
          total:
            estimateValues.pocketDoorCount *
              assumptionsInput.estimatePocketDoorCost || 0,
          sectionName: 'Doors',
        },
        {
          label: 'Pocket door - install door only',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimatePocketDoorInstallOnlyCost',
          assumptionValue:
            assumptionsInput.estimatePocketDoorInstallOnlyCost,
          name: 'pocketDoorInstallCount',
          value: estimateValues.pocketDoorInstallCount,
          type: 'number',
          total:
            estimateValues.pocketDoorInstallCount *
              assumptionsInput.estimatePocketDoorInstallOnlyCost || 0,
          sectionName: 'Doors',
        },
        {
          label: 'Barn door',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateBarnDoorCost',
          assumptionValue: assumptionsInput.estimateBarnDoorCost,
          name: 'barnDoorCount',
          value: estimateValues.barnDoorCount,
          type: 'number',
          total:
            estimateValues.barnDoorCount *
              assumptionsInput.estimateBarnDoorCost || 0,
          sectionName: 'Doors',
        },
        {
          label: "Garage doors (9' = $1,000, 16' = $1,200)",
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateGarageDoorCost',
          assumptionValue: assumptionsInput.estimateGarageDoorCost,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateGarageDoorCost,
          sectionName: 'Doors',
        },
        {
          label: 'Miscellaneous door expenses',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateOtherDoorExpenses',
          assumptionValue: assumptionsInput.estimateOtherDoorExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateOtherDoorExpenses,
          sectionName: 'Doors',
        },
      ],
    },
    {
      sectionName: 'Electrical',
      sectionIcon: <ElectricalServicesIcon />,
      description: 'Electrical panel',
      panel: 'e7',
      notes: estimateValues.electricalNotes,
      notesName: 'electricalNotes',
      fields: [
        {
          label: 'Rewire the whole house ($4,500 - $5,500)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateRewire',
          assumptionValue: assumptionsInput.estimateRewire,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateRewire,
          sectionName: 'Electrical',
        },
        {
          label: 'Exterior lights',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateExteriorLightCost',
          assumptionValue: assumptionsInput.estimateExteriorLightCost,
          name: 'exteriorLightCount',
          value: estimateValues.exteriorLightCount,
          type: 'number',
          total:
            estimateValues.exteriorLightCount *
              assumptionsInput.estimateExteriorLightCost || 0,
          sectionName: 'Electrical',
        },
        {
          label: 'Interior lights',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateInteriorLightCost',
          assumptionValue: assumptionsInput.estimateInteriorLightCost,
          name: 'interiorLightCount',
          value: estimateValues.interiorLightCount,
          type: 'number',
          total:
            estimateValues.interiorLightCount *
              assumptionsInput.estimateInteriorLightCost || 0,
          sectionName: 'Electrical',
        },
        {
          label: 'Ceiling Fans',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateCeilingFanCost',
          assumptionValue: assumptionsInput.estimateCeilingFanCost,
          name: 'fanCount',
          value: estimateValues.fanCount,
          type: 'number',
          total:
            estimateValues.fanCount *
              assumptionsInput.estimateCeilingFanCost || 0,
          sectionName: 'Electrical',
        },
        {
          label: 'Recessed can light',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateRecessedCanLightCost',
          assumptionValue:
            assumptionsInput.estimateRecessedCanLightCost,
          name: 'recessedCanLightCount',
          value: estimateValues.recessedCanLightCount,
          type: 'number',
          total:
            estimateValues.recessedCanLightCount *
              assumptionsInput.estimateRecessedCanLightCost || 0,
          sectionName: 'Electrical',
        },
        {
          label: 'Change out plugs and switches',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimatePlugSwitchReplacementCost',
          assumptionValue:
            assumptionsInput.estimatePlugSwitchReplacementCost,
          name: 'plugSwitchReplacement',
          value: estimateValues.plugSwitchReplacement,
          type: 'Boolean',
          total: estimateValues.plugSwitchReplacement
            ? assumptionsInput.estimatePlugSwitchReplacementCost
            : 0,
          sectionName: 'Electrical',
        },
        {
          label:
            'Smoke detectors ( 1 in common area, 1 in each bedroom) 5 minimum per house',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateSmokeDetectorCost',
          assumptionValue: assumptionsInput.estimateSmokeDetectorCost,
          name: 'smokeDetectorCount',
          value: estimateValues.smokeDetectorCount,
          type: 'number',
          total:
            estimateValues.smokeDetectorCount *
              assumptionsInput.estimateSmokeDetectorCost || 0,
          sectionName: 'Electrical',
        },
        {
          label:
            'Change or replace electrical panel ($2,000 - $3,000)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateElectricalPanel',
          assumptionValue: assumptionsInput.estimateElectricalPanel,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateElectricalPanel,
          sectionName: 'Electrical',
        },
        {
          label: 'Miscellaneous electrical expenses',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateOtherElectricalExpenses',
          assumptionValue:
            assumptionsInput.estimateOtherElectricalExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateOtherElectricalExpenses,
          sectionName: 'Electrical',
        },
      ],
    },
    {
      sectionName: 'Plumbing',
      sectionIcon: <PlumbingIcon />,
      description: 'Plumbing panel',
      panel: 'e8',
      notes: estimateValues.plumbingNotes,
      notesName: 'plumbingNotes',
      fields: [
        {
          label: 'Water Heater',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateWaterHeaterCost',
          assumptionValue: assumptionsInput.estimateWaterHeaterCost,
          name: 'waterHeaterCount',
          value: estimateValues.waterHeaterCount,
          type: 'number',
          total:
            estimateValues.waterHeaterCount *
              assumptionsInput.estimateWaterHeaterCost || 0,
          sectionName: 'Plumbing',
        },
        {
          label: 'Replumb the whole house ($4,500)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateReplumbHouse',
          assumptionValue: assumptionsInput.estimateReplumbHouse,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateReplumbHouse,
          sectionName: 'Plumbing',
        },
        {
          label: 'Miscellaneous plumbing expenses',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateOtherPlumbingExpenses',
          assumptionValue:
            assumptionsInput.estimateOtherPlumbingExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateOtherPlumbingExpenses,
          sectionName: 'Plumbing',
        },
      ],
    },
    {
      sectionName: 'HVAC',
      sectionIcon: <AcUnitIcon />,
      description: 'HVAC panel',
      panel: 'e9',
      notes: estimateValues.hvacNotes,
      notesName: 'hvacNotes',
      fields: [
        {
          label:
            'Install a complete system A/C and heat ($6,500 - $7,500)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateACInstallCost',
          assumptionValue: assumptionsInput.estimateACInstallCost,
          name: 'installAC',
          value: estimateValues.installAC,
          type: 'Boolean',
          total: estimateValues.installAC
            ? assumptionsInput.estimateACInstallCost
            : 0,
          sectionName: 'HVAC',
        },
        {
          label:
            'Take existing forced air heat and add air conditioning',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateAddACToForcedAirHeat',
          assumptionValue:
            assumptionsInput.estimateAddACToForcedAirHeat,
          name: 'addACToAirHeat',
          value: estimateValues.addACToAirHeat,
          type: 'Boolean',
          total: estimateValues.addACToAirHeat
            ? assumptionsInput.estimateAddACToForcedAirHeat
            : 0,
          sectionName: 'HVAC',
        },
        {
          label: 'Gas Pack for roof top',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateGasPackForRoof',
          assumptionValue: assumptionsInput.estimateGasPackForRoof,
          name: 'getGasPack',
          value: estimateValues.getGasPack,
          type: 'Boolean',
          total: estimateValues.getGasPack
            ? assumptionsInput.estimateGasPackForRoof
            : 0,
          sectionName: 'HVAC',
        },
        {
          label: 'Furnace',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateFurnace',
          assumptionValue: assumptionsInput.estimateFurnace,
          name: 'installFurnace',
          value: estimateValues.installFurnace,
          type: 'Boolean',
          total: estimateValues.installFurnace
            ? assumptionsInput.estimateFurnace
            : 0,
          sectionName: 'HVAC',
        },
        {
          label: 'Wall Heaters ($1,000 - $1,500)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateWallHeaterCost',
          assumptionValue: assumptionsInput.estimateWallHeaterCost,
          name: 'wallHeaterCount',
          value: estimateValues.wallHeaterCount,
          type: 'number',
          total:
            estimateValues.wallHeaterCount *
              assumptionsInput.estimateWallHeaterCost || 0,
          sectionName: 'HVAC',
        },
        {
          label: 'AC Condenser ($1,800)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateACCondenserCost',
          assumptionValue: assumptionsInput.estimateACCondenserCost,
          name: 'condensetCount',
          value: estimateValues.condensetCount,
          type: 'number',
          total:
            estimateValues.condensetCount *
              assumptionsInput.estimateACCondenserCost || 0,
          sectionName: 'HVAC',
        },
        {
          label: 'New Registers for whole house ($350)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateNewRegisters',
          assumptionValue: assumptionsInput.estimateNewRegisters,
          name: 'newRegisters',
          value: estimateValues.newRegisters,
          type: 'Boolean',
          total: estimateValues.newRegisters
            ? assumptionsInput.estimateNewRegisters
            : 0,
          sectionName: 'HVAC',
        },
        {
          label: 'Service HVAC',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateServiceHVAC',
          assumptionValue: assumptionsInput.estimateServiceHVAC,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateServiceHVAC,
          sectionName: 'HVAC',
        },
        {
          label: 'Miscellaneous HVAC expenses',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateOtherHVACExpenses',
          assumptionValue: assumptionsInput.estimateOtherHVACExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateOtherHVACExpenses,
          sectionName: 'HVAC',
        },
      ],
    },
    {
      sectionName: 'Kitchen',
      sectionIcon: <KitchenIcon />,
      description: 'Kitchen panel',
      panel: 'e10',
      notes: estimateValues.kitchenNotes,
      notesName: 'kitchenNotes',
      fields: [
        {
          label:
            'Remodel entire kitchen ($9,000 for normal kitchen & increase for larger or higher end)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateKitchenRemodelCost',
          assumptionValue:
            assumptionsInput.estimateKitchenRemodelCost,
          name: 'remodelKitchen',
          value: estimateValues.remodelKitchen,
          type: 'Boolean',
          total: estimateValues.remodelKitchen
            ? assumptionsInput.estimateKitchenRemodelCost
            : 0,
          sectionName: 'Kitchen',
        },
        {
          label: 'Refrigerator',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateRefrigerator',
          assumptionValue: assumptionsInput.estimateRefrigerator,
          name: 'remodelRefrigerator',
          value: estimateValues.remodelRefrigerator,
          type: 'Boolean',
          total: estimateValues.remodelRefrigerator
            ? assumptionsInput.estimateRefrigerator
            : 0,
          sectionName: 'Kitchen',
        },
        {
          label: 'Half Kitchen (everything except cabinets)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateHalfKitchen',
          assumptionValue: assumptionsInput.estimateHalfKitchen,
          name: 'remodelHalfKitchen',
          value: estimateValues.remodelHalfKitchen,
          type: 'Boolean',
          total: estimateValues.remodelHalfKitchen
            ? assumptionsInput.estimateHalfKitchen
            : 0,
          sectionName: 'Kitchen',
        },
        {
          label: 'How many walls do you need to open up?',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateKitchenWallOpenCost',
          assumptionValue:
            assumptionsInput.estimateKitchenWallOpenCost,
          name: 'openWallCount',
          value: estimateValues.openWallCount,
          type: 'number',
          total:
            estimateValues.openWallCount *
              assumptionsInput.estimateKitchenWallOpenCost || 0,
          sectionName: 'Kitchen',
        },
        {
          label: 'Miscellaneous Kitchen Expenses',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateOtherKitchenExpenses',
          assumptionValue:
            assumptionsInput.estimateOtherKitchenExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateOtherKitchenExpenses,
          sectionName: 'Kitchen',
        },
      ],
    },
    {
      sectionName: 'Bathrooms',
      sectionIcon: <BathtubIcon />,
      description: 'Bathrooms panel',
      panel: 'e11',
      notes: estimateValues.bathroomNotes,
      notesName: 'bathroomNotes',
      fields: [
        {
          label: 'Coomplete renovation ($4,500 - $6,500)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateBathroomRenovationCost',
          assumptionValue:
            assumptionsInput.estimateBathroomRenovationCost,
          name: 'bathroomRenovationCount',
          value: estimateValues.bathroomRenovationCount,
          type: 'number',
          total:
            estimateValues.bathroomRenovationCount *
              assumptionsInput.estimateBathroomRenovationCost || 0,
          sectionName: 'Bathrooms',
        },
        {
          label: 'Tub, shower valve, tile surround',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateTubShowerTileCost',
          assumptionValue: assumptionsInput.estimateTubShowerTileCost,
          name: 'tubShowerTileCount',
          value: estimateValues.tubShowerTileCount,
          type: 'number',
          total:
            estimateValues.tubShowerTileCount *
              assumptionsInput.estimateTubShowerTileCost || 0,
          sectionName: 'Bathrooms',
        },
        {
          label: 'Tile surround',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateTileCost',
          assumptionValue: assumptionsInput.estimateTileCost,
          name: 'tileCount',
          value: estimateValues.tileCount,
          type: 'number',
          total:
            estimateValues.tileCount *
              assumptionsInput.estimateTileCost || 0,
          sectionName: 'Bathrooms',
        },
        {
          label: 'New tub',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateTubCost',
          assumptionValue: assumptionsInput.estimateTubCost,
          name: 'tubCount',
          value: estimateValues.tubCount,
          type: 'number',
          total:
            estimateValues.tubCount *
              assumptionsInput.estimateTubCost || 0,
          sectionName: 'Bathrooms',
        },
        {
          label: 'Glaze tub',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateTubGlazeCost',
          assumptionValue: assumptionsInput.estimateTubGlazeCost,
          name: 'tubGlazeCount',
          value: estimateValues.tubGlazeCount,
          type: 'number',
          total:
            estimateValues.tubGlazeCount *
              assumptionsInput.estimateTubGlazeCost || 0,
          sectionName: 'Bathrooms',
        },
        {
          label: 'Shower valves',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateShowerCost',
          assumptionValue: assumptionsInput.estimateShowerCost,
          name: 'showerCount',
          value: estimateValues.showerCount,
          type: 'number',
          total:
            estimateValues.showerCount *
              assumptionsInput.estimateShowerCost || 0,
          sectionName: 'Bathrooms',
        },
        {
          label: 'Vanity',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateVanityCost',
          assumptionValue: assumptionsInput.estimateVanityCost,
          name: 'vanityCount',
          value: estimateValues.vanityCount,
          type: 'number',
          total:
            estimateValues.vanityCount *
              assumptionsInput.estimateVanityCost || 0,
          sectionName: 'Bathrooms',
        },
        {
          label: 'Toilet',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateTolietCost',
          assumptionValue: assumptionsInput.estimateTolietCost,
          name: 'tolietCount',
          value: estimateValues.tolietCount,
          type: 'number',
          total:
            estimateValues.tolietCount *
              assumptionsInput.estimateTolietCost || 0,
          sectionName: 'Bathrooms',
        },
        {
          label:
            'Mirror, medicine cabinet, towel bar, toilet paper holder',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName:
            'estimateMirrorCabinetTowerBarToiletPaperHolderCost',
          assumptionValue:
            assumptionsInput.estimateMirrorCabinetTowerBarToiletPaperHolderCost,
          name: 'mirrorCabinetTowerBarToiletPaperHolderCount',
          value:
            estimateValues.mirrorCabinetTowerBarToiletPaperHolderCount,
          type: 'number',
          total:
            estimateValues.mirrorCabinetTowerBarToiletPaperHolderCount *
              assumptionsInput.estimateMirrorCabinetTowerBarToiletPaperHolderCost ||
            0,
          sectionName: 'Bathrooms',
        },
        {
          label: 'Miscellaneous bathroom cost',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateOtherBathroomExpenses',
          assumptionValue:
            assumptionsInput.estimateOtherBathroomExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateOtherBathroomExpenses,
          sectionName: 'Bathrooms',
        },
      ],
    },
    {
      sectionName: 'Miscellaneous',
      sectionIcon: <MiscellaneousServicesIcon />,
      description: 'Miscellaneous panel',
      panel: 'e12',
      notes: estimateValues.miscNotes,
      notesName: 'miscNotes',
      fields: [
        {
          label: 'Demo and dumpster (2 - 3 average per job)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateDemoDumpster',
          assumptionValue: assumptionsInput.estimateDemoDumpster,
          name: 'demoDumpsterCount',
          value: estimateValues.demoDumpsterCount,
          type: 'number',
          total:
            estimateValues.demoDumpsterCount *
              assumptionsInput.estimateDemoDumpster || 0,
          sectionName: 'Miscellaneous',
        },
        {
          label: 'Final clean up',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateFinalCleanUp',
          assumptionValue: assumptionsInput.estimateFinalCleanUp,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateFinalCleanUp,
          sectionName: 'Miscellaneous',
        },
        {
          label:
            'Pool (assess with pool contractor during due diligence) $5000 if quick # is needed for drain, resurface, and mechanicals',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimatePool',
          assumptionValue: assumptionsInput.estimatePool,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimatePool,
          sectionName: 'Miscellaneous',
        },
        {
          label:
            'Fireplace facing - tile or stone (reface to mantle = $1,000) (reface to ceiling = $1,500)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateFireplace',
          assumptionValue: assumptionsInput.estimateFireplace,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateFireplace,
          sectionName: 'Miscellaneous',
        },
        {
          label:
            'Basement (unfinished small = $6,000, unfinished large = $12,000)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateBasement',
          assumptionValue: assumptionsInput.estimateBasement,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateBasement,
          sectionName: 'Miscellaneous',
        },
        {
          label: 'Mold ($3,000 - $8,000)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateMold',
          assumptionValue: assumptionsInput.estimateMold,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateMold,
          sectionName: 'Miscellaneous',
        },
        {
          label: 'French drain ($2,500 - $3,500)',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateFrenchDrainCost',
          assumptionValue: assumptionsInput.estimateFrenchDrainCost,
          name: 'frenchDrainCount',
          value: estimateValues.frenchDrainCount,
          type: 'number',
          total:
            estimateValues.frenchDrainCount *
              assumptionsInput.estimateFrenchDrainCost || 0,
          sectionName: 'Miscellaneous',
        },
        {
          label: 'Foundation repair',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateFoundationRepair',
          assumptionValue: assumptionsInput.estimateFoundationRepair,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateFoundationRepair,
          sectionName: 'Miscellaneous',
        },
        {
          label: 'Termite treatment',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateTermiteTreatment',
          assumptionValue: assumptionsInput.estimateTermiteTreatment,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateTermiteTreatment,
          sectionName: 'Miscellaneous',
        },
        {
          label: 'Miscellaneous other cost',
          adornment: '$',
          adornmentToggle: '',
          adornmentPosition: 'start',
          assumptionName: 'estimateOtherExpenses',
          assumptionValue: assumptionsInput.estimateOtherExpenses,
          name: '',
          value: '',
          type: '',
          total: assumptionsInput.estimateOtherExpenses,
          sectionName: 'Miscellaneous',
        },
      ],
    },
  ]
}

export const RehabCalcInputField = (props) => {
  const [openDialog, setOpenDialog] = useState(false)
  return (
    <>
      <NumericFormat
        customInput={TextField}
        thousandSeparator={true}
        prefix={'$'}
        fullWidth={true}
        isNumericString={true}
        decimalScale={0}
        key={props.name}
        name={props.name}
        value={Math.round(props.value) || ''}
        type={'text'}
        onFocus={(event) => {
          event.target.select()
        }}
        variant="outlined"
        margin="dense"
        label={props.label}
        disabled={props.disabled}
        onClick={() => setOpenDialog(true)}
        slotProps={{
          input: {
            readOnly: true,
            startAdornment: props.adornmentNumber ? (
              <InputAdornment
                position="start"
                sx={{ marginRight: 0.25 }}
              >
                <Typography
                  sx={{
                    width: 20, // Smaller size
                    height: 20,
                    borderRadius: '50%',
                    backgroundColor: 'black', // Black background
                    color: 'white', // White text
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    fontSize: '0.75rem', // Smaller text
                    padding: 0, // Remove padding
                    textAlign: 'center', // Ensure centered alignment
                    marginLeft: '-6px', // Adjust spacing on the left
                  }}
                >
                  {props.adornmentNumber}
                </Typography>
              </InputAdornment>
            ) : null,
          },
        }}
      />
      {!props.disabled && (
        <RehabCalc
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          property={props?.property}
          affiliate={props?.affiliate}
          targetValue={props?.name}
          assumptionsInput={props?.assumptionsInput}
          analysisInput={props?.analysisInput}
          onChangeAnalysis={props?.onChangeAnalysis}
          onChangeAssumptions={props?.onChangeAssumptions}
        />
      )}
    </>
  )
}
