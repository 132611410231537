import { gql } from '@apollo/client'

export const UPDATE_TABLE = gql`
  mutation updateTable($tableInputs: [tableInput]!) {
    updateTable(tableInputs: $tableInputs) {
      zipcode
      stateLong
      stateShort
      arvSqft
      rentSqft
    }
  }
`

export const CALL_REAPI_SKIPTRACE = gql`
  mutation callREAPISkipTrace($propertyId: String!) {
    callREAPISkipTrace(propertyId: $propertyId)
  }
`

export const CALL_BULK_REAPI_SKIPTRACE = gql`
  mutation callBulkREAPISkipTrace($propertyIds: [String]) {
    callBulkREAPISkipTrace(propertyIds: $propertyIds)
  }
`
