/**
 * @ Author: Housefolios
 * @ Create Time: 2023-01-23 11:22:37
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-03-20 14:46:01
 * @ Description: Card with details about the property used for the card view on the public marketplace page
 */

import React, { useState, useEffect, useRef } from 'react'

import moment from 'moment'

import Slider from 'react-slick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import isUndefined from 'lodash/isUndefined'
import includes from 'lodash/includes'
import findIndex from 'lodash/findIndex'
import slice from 'lodash/slice'
import cloneDeep from 'lodash/cloneDeep'
import assign from 'lodash/assign'
import get from 'lodash/get'
import set from 'lodash/set'
import sum from 'lodash/sum'
import startsWith from 'lodash/startsWith'
import find from 'lodash/find'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import merge from 'lodash/merge'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isNil from 'lodash/isNil'
import replace from 'lodash/replace'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import { useMutation } from '@apollo/client'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { Close } from '@mui/icons-material'
import { getProformaLink } from '@/utils/proforma'

function SliderArrowNext(props) {
  const { className, onClick } = props
  return (
    <div
      className={className}
      onClick={(event) => {
        onClick(event)
        event.stopPropagation()
      }}
    >
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  )
}

function SliderArrowPrev(props) {
  const { className, onClick } = props
  return (
    <div
      className={className}
      onClick={(event) => {
        onClick(event)
        event.stopPropagation()
      }}
    >
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  )
}

function PublicPropertyCardComponent(props) {
  const { property, propertySearch } = props
  const hostname = import.meta.env.VITE_HOSTNAME
  const attributes = {
    className: 'nav-item my-1',
  }
  const slider = useRef()
  const slider1 = useRef()
  const widgetsCarousels1B = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    adaptiveHeight: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
  }

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })

  const [showImages, setShowImages] = useState(false)
  const [images, setImages] = useState([])

  const toggleImages =
    (images = []) =>
    (event) => {
      event.stopPropagation()
      setShowImages(!showImages)
      setImages(images)
    }

  // const [heart, setHeart] = useState(property?.favorite)

  // useEffect(() => {
  //   // let index = findIndex(
  //   //   property?.images,
  //   //   image => image.url === property?.mainImage,
  //   // )
  //   // slider.current.slickGoTo(index > 0 ? index : 0)
  //   setHeart(property?.favorite)
  // }, [property])

  const [openCriticalFields, setOpenCriticalFields] = useState(false)

  const handleOpenCriticalFields = () => {
    setOpenCriticalFields(true)
  }

  const handleCloseCriticalFields = () => {
    setOpenCriticalFields(false)
  }

  const [updateProperty] = useMutation(UPDATE_PROPERTY)

  const [openAccept, setOpenAccept] = useState(false)
  const [openReject, setOpenReject] = useState(false)

  const phpSend = (remove = false) => {
    if (remove) {
      let data = {
        _id: property?._id,
        remove_property: remove,
      }

      const str_json = JSON.stringify(data)

      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    } else {
      let data = cloneDeep(property)
      let expRelist = { expRelist: true }
      let activeMP = []
      data.beds = sum(data.beds)
      data.baths = sum(data.baths)
      data.sqft = sum(data.sqft)
      if (get(data, 'acquisition.analysis')) {
        map(data.acquisition.analysis, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `acquisition.analysis.${key}`,
              sum(get(data, `acquisition.analysis.${key}`)),
            )
          }
        })
      }
      if (get(data, 'assumptions')) {
        map(data.assumptions, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `assumptions.${key}`,
              sum(get(data, `assumptions.${key}`)),
            )
          }
        })
      }
      if (get(data, 'acquisition.analytics')) {
        map(data.acquisition.analytics, (value, key) => {
          if (isArray(value)) {
            set(
              data,
              `acquisition.analytics.${key}`,
              sum(get(data, `acquisition.analytics.${key}`)),
            )
          } else if (isObject(value)) {
            map(value, (secondKey, value) => {
              if (isArray(value)) {
                set(
                  data,
                  `acquisition.analytics.${key}.${secondKey}`,
                  sum(
                    get(
                      data,
                      `acquisition.analytics.${key}.${secondKey}`,
                    ),
                  ),
                )
              }
            })
          }
        })
      }
      const { proformaType } = property.marketplaces
      const proformaLink = getProformaLink(data?._id, [proformaType])

      data.proformaURL = proformaLink
      if (
        property.marketplaces &&
        !isEmpty(property.marketplaces.approvedMarketplace)
      ) {
        activeMP.push({
          marketplaces: {
            marketplaceName: property.marketplaces.marketplaceName,
            approvedMarketplace:
              property.marketplaces.approvedMarketplace,
            expirationDate: new Date(
              Date.now() + 7 * 24 * 60 * 60 * 1000,
            ),
            extraTags: property.marketplaces.extraTags,
            proformaType: property.marketplaces.proformaType,
          },
        })

        data = assign(data, activeMP[0], expRelist)
      }

      const str_json = JSON.stringify(data)
      fetch(
        hostname === 'localhost'
          ? 'http://marketplace.housefolios.com/api/parserDev.php'
          : //? 'http://housefolios-marketplace.test/api/parserDev.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://marketplace.housefolios.com/api/parserDev.php'
            : 'https://marketplace.housefolios.com/api/parser.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
        .then(function (response) {
          if (response.status !== 200) {
            console.log(
              'Looks like there was a problem. Status Code: ' +
                response.status,
            )
            return
          }

          // Examine the text in the response
          response.json().then(function (data) {
            console.log(data)
          })
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })

      // const request = new XMLHttpRequest()
      // request.open(
      //   'POST',
      //   'https://marketplace.housefolios.com/api/parser.php',
      //   true,
      // )
      // request.setRequestHeader('Content-type', 'application/json')
      // request.send(str_json)
    }
  }

  const pricePerSqft = property?.sqft
    ? Number(
        property.price ||
          get(property, 'acquisition.analysis.resellPrice'),
      ) / property.sqft || 0
    : null

  let createdAt = new Date(property?.createdAt)
  let createdAtYear = createdAt.getFullYear()
  let createdAtMonth = createdAt.getMonth() + 1
  let createdAtDay = createdAt.getDate()
  createdAt =
    createdAtMonth + '/' + createdAtDay + '/' + createdAtYear

  const today = new Date()
  let endDate = new Date(property?.deletedAt)
  endDate.setDate(endDate.getDate() + 7)
  const daysUntilDeleted = moment
    .duration(moment(endDate).diff(today))
    .asDays()
    .toFixed(0)

  let listDate =
    !props.trash &&
    !props.propertySearch &&
    props.marketplace &&
    property?.marketplaces
      ? new Date(property?.marketplaces.expirationDate)
      : ''
  if (listDate) {
    listDate.setDate(listDate.getDate() - 7)
    let listDateYear = listDate.getFullYear()
    let listDateMonth = listDate.getMonth() + 1
    let listDateDay = listDate.getDate()
    listDate = listDateMonth + '/' + listDateDay + '/' + listDateYear
  }

  const analytics = map(
    property?.marketplaceAnalytics,
    (analytics) => {
      return {
        ...analytics,
        value: analytics.sum
          ? sum(get(property, analytics.target)) /
            (analytics.divisor || 1)
          : get(property, analytics.target) /
            (analytics.divisor || 1),
        arrow: get(property, analytics.target + 'Arrow'),
      }
    },
  )

  let resellPrice = ''
  switch (property.marketplaceStrategy) {
    case 'Buy & Hold':
      resellPrice = get(property, 'acquisition.analysis.resellPrice')
      break
    case 'Fix & Flip':
      resellPrice = get(
        property,
        'acquisition.analysis.resellPriceFixFlip',
      )
      break
    case 'Turnkey End Buyer':
      resellPrice = get(
        property,
        'acquisition.analysis.resellPriceTurnkey',
      )
      break
    case 'BRRRR':
      resellPrice = get(
        property,
        'acquisition.analysis.resellPriceBRRRR',
      )
      break
    case 'Short Term Rental':
      resellPrice = get(
        property,
        'acquisition.analysis.resellPriceSTR',
      )
      break
    case 'Wholesale':
      resellPrice = get(
        property,
        'acquisition.analysis.resellPriceWholesale',
      )
      break
    case 'Build to Rent':
      resellPrice = get(
        property,
        'acquisition.analysis.resellPriceB2R',
      )
      break
    case 'BOR':
      resellPrice = get(
        property,
        'acquisition.analytics.BOR.arvTotalBOR',
      )
      break
  }

  return (
    <Grid
      item
      xs={12}
      md={props.fullWidth ? 12 : 4}
      xl={props.fullWidth ? 12 : 3}
      className="mb-spacing-6"
    >
      <div
        className="card card-box-hover-rise"
        style={{ backgroundColor: 'white', position: 'relative' }}
      >
        {props.marketplace &&
          !props.propertySearch &&
          !props.trash &&
          property?.acquisition &&
          property?.marketplaces &&
          !isEmpty(property?.marketplaces.extraTags) && (
            <span className="ribbon-angle ribbon-angle--top-left ribbon-primary">
              <small style={{ textAlign: 'center' }}>
                <Typography
                  style={{
                    fontSize:
                      property?.marketplaces.extraTags[0].length > 13
                        ? 10
                        : 14,
                    fontWeight: 600,
                  }}
                >
                  {property?.marketplaces.extraTags[0]}
                </Typography>
              </small>
            </span>
          )}
        {props.marketplace &&
          !props.propertySearch &&
          !props.trash &&
          property?.acquisition && (
            <span className="ribbon-angle ribbon-angle--top-right ribbon-primary">
              <small style={{ textAlign: 'center' }}>
                <Typography
                  style={{
                    fontSize:
                      property?.status && property?.status.length > 13
                        ? 10
                        : 14,
                    fontWeight: 600,
                  }}
                >
                  {property?.status}
                </Typography>
              </small>
            </span>
          )}
        <a
          href={
            props.preview
              ? null
              : props.trash
                ? '/trash'
                : `/public-property-details/${property?._id}/Summary${props.subdomainParam}`
          }
        >
          <Slider
            ref={slider}
            className="card-img-top slider-dots"
            {...widgetsCarousels1B}
          >
            {props.propertySearch &&
            !property.marketplaces &&
            property.images &&
            startsWith(property.images, 'http') ? (
              <div>
                <Card
                  className="shadow-none"
                  style={{
                    height: 325,
                    backgroundColor: 'white',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    margin: 1,
                  }}
                  onClick={toggleImages([property.images])}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    style={{ height: '100%' }}
                  >
                    <Grid item>
                      <div
                        href={
                          props.preview
                            ? null
                            : props.propertySearch
                              ? '/property-search'
                              : `/public-property-details/${property?._id}/Summary${props.subdomainParam}`
                        }
                        className="card-img-wrapper"
                        style={{
                          textAlign: 'center',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <img
                          src={property.images}
                          className="card-img-top"
                          style={{ objectFit: 'contain' }}
                          alt="..."
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            ) : props.propertySearch &&
              !property.marketplaces &&
              property.images ? (
              map(
                slice(
                  Object.values(JSON.parse(property.images)),
                  0,
                  5,
                ),
                (image) => (
                  <div key={image}>
                    <Card
                      className="shadow-none"
                      style={{
                        height: 325,
                        backgroundColor: 'white',
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        margin: 1,
                      }}
                      onClick={toggleImages(
                        JSON.parse(property.images),
                      )}
                    >
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        style={{ height: '100%' }}
                      >
                        <Grid item>
                          <div
                            href={
                              props.preview
                                ? null
                                : props.propertySearch
                                  ? '/property-search'
                                  : `/public-property-details/${property?._id}/Summary${props.subdomainParam}`
                            }
                            className="card-img-wrapper"
                            style={{
                              textAlign: 'center',
                              backgroundColor: 'transparent',
                              borderBottomRightRadius: '0px',
                            }}
                          >
                            <img
                              src={image}
                              className="card-img-top"
                              style={{ objectFit: 'contain' }}
                              alt="..."
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
                ),
              )
            ) : props.propertySearch &&
              !property.marketplaces &&
              property.image ? (
              <div>
                <Card
                  className="shadow-none"
                  style={{
                    height: 325,
                    backgroundColor: 'white',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    margin: 1,
                  }}
                  onClick={toggleImages([property.image])}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    style={{ height: '100%' }}
                  >
                    <Grid item>
                      <div
                        href={
                          props.preview
                            ? null
                            : props.propertySearch
                              ? '/property-search'
                              : `/public-property-details/${property?._id}/Summary${props.subdomainParam}`
                        }
                        className="card-img-wrapper"
                        style={{
                          textAlign: 'center',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <img
                          src={property.image}
                          className="card-img-top"
                          style={{ objectFit: 'contain' }}
                          alt="..."
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            ) : !isEmpty(compact(property.images)) ? (
              map(slice(compact(property.images), 0, 5), (image) => (
                <div key={image.url}>
                  <Card
                    className="shadow-none"
                    style={{
                      height: 325,
                      backgroundColor: 'white',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      margin: 1,
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      style={{ height: '100%' }}
                    >
                      <Grid item>
                        <div
                          href={
                            props.preview
                              ? null
                              : props.propertySearch
                                ? '/property-search'
                                : props.trash
                                  ? '/trash'
                                  : `/public-property-details/${property?._id}/Summary${props.subdomainParam}`
                          }
                          className="card-img-wrapper"
                          style={{
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                            borderBottomRightRadius: '0px',
                          }}
                        >
                          <div
                            className="blur-load"
                            style={{
                              backgroundImage:
                                'url(' + image.url_small + ')',
                              height: 325,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={image.url}
                              className="card-img-top"
                              style={{
                                objectFit: 'contain',
                                maxHeight: 325,
                              }}
                              alt="..."
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              ))
            ) : null}
          </Slider>
          <CardContent
            className="card-body-avatar"
            style={{
              height: props.marketplace
                ? 'calc(100% - 410px)'
                : 'calc(100% - 330px)',
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="space-between"
            >
              <Grid container item style={{ width: '100%' }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  //spacing={2}
                >
                  <Grid item>
                    <h5
                      className="font-weight-bold"
                      style={{ fontSize: 26 }}
                    >
                      {currencyFormat.format(resellPrice)}
                    </h5>
                  </Grid>
                  <Grid item>
                    <Typography className="font-size-sm">
                      {sum(property.beds) || '-'} bd |{' '}
                      {sum(property.baths) || '-'} ba |{' '}
                      {sum(property.sqft)
                        ? numberFormat.format(
                            Number(sum(property.sqft)),
                          )
                        : '-'}{' '}
                      sqft |{' '}
                      {propertySearch && property.yearBuilt
                        ? property.yearBuilt
                        : property.year || '-'}{' '}
                      yr
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <h5
                      className="card-title font-weight-bold font-size-sm"
                      style={{}}
                    >
                      {!property.hideAddress
                        ? property.address + ', '
                        : ''}
                      {property.city}, {property.state} {property.zip}
                    </h5>
                  </Grid>
                </Grid>
              </Grid>
              {!props.marketplace && (
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  //spacing={2}
                >
                  <Grid item>
                    <Typography className="font-size-sm">
                      Date Added: {createdAt}
                    </Typography>
                  </Grid>
                  {props.submitted && (
                    <Grid item>
                      <Typography className="font-size-sm">
                        Submitted By:{' '}
                        {property.marketplaceSubmittedBy &&
                        property.marketplaceSubmittedBy.profile
                          ? `${property.marketplaceSubmittedBy.profile.firstname} ${property.marketplaceSubmittedBy.profile.lastname} `
                          : ''}
                      </Typography>
                    </Grid>
                  )}
                  {props.lender && (
                    <Grid item>
                      <Typography className="font-size-sm">
                        Submitted By:{' '}
                        {property.lenderSubmittedBy &&
                        property.lenderSubmittedBy.profile
                          ? `${property.lenderSubmittedBy.profile.firstname} ${property.lenderSubmittedBy.profile.lastname} `
                          : ''}
                      </Typography>
                    </Grid>
                  )}
                  {props.jv && (
                    <Grid item>
                      <Typography className="font-size-sm">
                        Submitted By:{' '}
                        {property.jvSubmittedBy &&
                        property.jvSubmittedBy.profile
                          ? `${property.jvSubmittedBy.profile.firstname} ${property.jvSubmittedBy.profile.lastname} `
                          : ''}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
              {props.marketplace &&
                !props.propertySearch &&
                !props.trash && (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    //spacing={2}
                  >
                    <Grid item>
                      <Typography className="font-size-sm">
                        List Date: {listDate}
                      </Typography>
                    </Grid>
                    {props.submitted && (
                      <Grid item>
                        <Typography className="font-size-sm">
                          Submitted By:{' '}
                          {property.marketplaceSubmittedBy &&
                          property.marketplaceSubmittedBy.profile
                            ? `${property.marketplaceSubmittedBy.profile.firstname} ${property.marketplaceSubmittedBy.profile.lastname} `
                            : ''}
                        </Typography>
                      </Grid>
                    )}
                    {props.lender && (
                      <Grid item>
                        <Typography className="font-size-sm">
                          Submitted By:{' '}
                          {property.lenderSubmittedBy &&
                          property.lenderSubmittedBy.profile
                            ? `${property.lenderSubmittedBy.profile.firstname} ${property.lenderSubmittedBy.profile.lastname} `
                            : ''}
                        </Typography>
                      </Grid>
                    )}
                    {props.jv && (
                      <Grid item>
                        <Typography className="font-size-sm">
                          Submitted By:{' '}
                          {property.jvSubmittedBy &&
                          property.jvSubmittedBy.profile
                            ? `${property.jvSubmittedBy.profile.firstname} ${property.jvSubmittedBy.profile.lastname} `
                            : ''}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              {props.trash && (
                <Grid item>
                  <Typography color="error" className="font-size-sm">
                    Deletes in: {daysUntilDeleted} days
                  </Typography>
                </Grid>
              )}
              <div style={{ flex: 1 }} />
              {(!propertySearch ||
                (property.marketplaces &&
                  !isEmpty(
                    property.marketplaces.approvedMarketplace,
                  ))) && (
                <Grid item style={{ width: '100%' }}>
                  <Card style={{ backgroundColor: '#eff2f5' }}>
                    <CardContent style={{ padding: 10 }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        wrap="nowrap"
                        style={{ padding: 0 }}
                      >
                        {map(analytics, (field) => (
                          <Grid
                            item
                            className="gridItem2"
                            key={field.label}
                          >
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                className="gridItem2"
                              >
                                <Typography
                                  style={{
                                    whiteSpace: 'nowrap',
                                    fontSize: 13,
                                  }}
                                >
                                  {field.label}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className="gridItem2"
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  wrap="nowrap"
                                >
                                  {!isUndefined(field.arrow) && (
                                    <Grid item>
                                      {field.arrow ? (
                                        field.arrow === 2 ? (
                                          <Avatar
                                            style={{
                                              backgroundColor:
                                                '#2296F3',
                                              height: 15,
                                              width: 15,
                                            }}
                                          >
                                            <ArrowUpwardIcon
                                              style={{
                                                color: 'white',
                                                fontSize: 'small',
                                              }}
                                            />
                                          </Avatar>
                                        ) : (
                                          <Avatar
                                            style={{
                                              backgroundColor:
                                                '#46de64',
                                              height: 15,
                                              width: 15,
                                            }}
                                          >
                                            <ArrowUpwardIcon
                                              style={{
                                                color: 'white',
                                                fontSize: 'small',
                                              }}
                                            />
                                          </Avatar>
                                        )
                                      ) : (
                                        <Avatar
                                          style={{
                                            backgroundColor:
                                              '#F05248',
                                            height: 15,
                                            width: 15,
                                          }}
                                        >
                                          <ArrowDownwardIcon
                                            style={{
                                              color: 'white',
                                              fontSize: 'small',
                                            }}
                                          />
                                        </Avatar>
                                      )}
                                    </Grid>
                                  )}
                                  <Grid
                                    item
                                    style={{ marginLeft: 5 }}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: 11,
                                        fontWeight: 600,
                                      }}
                                    >
                                      {/* {field.value !== '-' ? field.type === 'currency' ? currencyFormat.format(field.value) : percentageFormat.format(field.value * 100) + '%' : field.value} */}
                                      {field.value !== '-'
                                        ? field.type === 'currency'
                                          ? currencyFormat.format(
                                              field.value,
                                            )
                                          : field.type ===
                                                'percent' &&
                                              field.value > 10
                                            ? '+1000%'
                                            : percentageFormat.format(
                                                field.value,
                                              )
                                        : field.value}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                    {/* <Divider />
                      <CardActions>
                        <Grid container direction='row' alignItems='center' spacing={1} style={{ marginTop: 5, marginBottom: 5 }}>
                          <Grid item xs={12} sm={6}>
                            <Button fullWidth variant="contained" margin='dense' color="primary" component="a" href={`/property/${property?._id}/proforma/buyHold`} target="_blank">
                                <FontAwesomeIcon icon={['far', 'file-lines']} className="font-size-md mr-2" />
                              <Typography>Financing Proforma</Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button fullWidth variant="contained" margin='dense' color="primary" component="a" href={`/property/${property?._id}/marketingProforma`} target="_blank">
                                <FontAwesomeIcon icon={['far', 'file-lines']} className="font-size-md mr-2" />
                              <Typography>Marketing Proforma</Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </CardActions> */}
                  </Card>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </a>
      </div>

      <Dialog
        open={showImages}
        onClose={toggleImages()}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>
          <IconButton
            onClick={toggleImages()}
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Slider
            ref={slider1}
            className="card-img-top slider-dots-light"
            {...widgetsCarousels1B}
          >
            {!isEmpty(images) ? (
              map(images, (image) => (
                <div>
                  <Card
                    className="shadow-none"
                    style={{
                      height: 325,
                      backgroundColor: 'black',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      style={{ height: '100%' }}
                    >
                      <Grid item>
                        <a
                          href="/#"
                          onClick={(event) => {
                            event.preventDefault()
                          }}
                          className="card-img-wrapper"
                          style={{
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                            borderBottomRightRadius: '0px',
                          }}
                        >
                          <img
                            src={image}
                            className="card-img-top"
                            style={{
                              maxHeight: 325,
                              height: 'auto',
                              width: 'auto',
                              objectFit: 'contain',
                            }}
                            alt="..."
                          />
                        </a>
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              ))
            ) : (
              <div>
                <Card
                  className="shadow-none"
                  style={{
                    height: 325,
                    backgroundColor: 'black',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    style={{ height: '100%' }}
                  >
                    <Grid item>
                      <a
                        href="/#"
                        onClick={(event) => {
                          event.preventDefault()
                        }}
                        className="card-img-wrapper"
                        style={{
                          textAlign: 'center',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <img
                          src="/images/default_image.png"
                          className="card-img-top"
                          style={{
                            maxHeight: 325,
                            height: 'auto',
                            width: 'auto',
                            objectFit: 'contain',
                          }}
                          alt="..."
                        />
                      </a>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            )}
          </Slider>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default PublicPropertyCardComponent
