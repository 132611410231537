import { gql } from '@apollo/client'

export const SUBSCRIBE = gql`
  mutation subscribe($userInput: userInput!) {
    subscribe(userInput: $userInput) {
      token
    }
  }
`

export const GENERIC_SEND_EMAIL = gql`
  mutation genericSendEmail($msg: JSON!) {
    genericSendEmail(msg: $msg)
  }
`
