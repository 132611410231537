/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-28 15:41:59
 * @ Description: Dialog to export the properties in a portfolio as a CSV file
 */

import React, { Component } from 'react'
import find from 'lodash/find'
import compact from 'lodash/compact'
import pullAllBy from 'lodash/pullAllBy'
import concat from 'lodash/concat'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { CSVLink } from 'react-csv'
// import Icon from '@mui/material/Icon'
// import ImportExportIcon from '@mui/icons-material/ImportExport'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import {
  Grid,
  IconButton,
  Tooltip,
  Checkbox,
  ListItem,
  Backdrop,
  CircularProgress,
  Typography,
} from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Query } from '@apollo/client/react/components'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
// import {Parser} from "json2csv"

const HEADERS = {
  propertyHeaders: [
    { label: 'Address', value: 'address', active: false },
    { label: 'County', value: 'county', active: false },
    { label: 'Subdivision', value: 'subdivision', active: false },
    { label: 'City', value: 'city', active: false },
    { label: 'State', value: 'state', active: false },
    { label: 'Zip', value: 'zip', active: false },
    { label: 'Beds', value: 'beds', active: false },
    { label: 'Baths', value: 'baths', active: false },
    { label: 'SqFt', value: 'sqft', active: false },
    { label: 'Year Built', value: 'year', active: false },
    { label: 'Description', value: 'description', active: false },
    { label: 'Strategy', value: 'strategy', active: false },
  ],
  acquisitionHeaders: [
    {
      label: 'Asking Price',
      value: 'acquisition.analysis.listPrice',
      active: false,
    },
    {
      label: 'Days On Market',
      value: 'acquisition.analysis.daysOnMarket',
      active: false,
    },
    {
      label: 'Purchase Price',
      value: 'acquisition.analysis.offerPrice',
      active: false,
    },
    {
      label: 'Est Rehab Cost',
      value: 'acquisition.analysis.rehabCost',
      active: false,
    },
    // {
    //   label: 'Finance Repairs',
    //   value: 'acquisition.analysis.financingRepairs',
    //   active: false,
    // },
    {
      label: 'After Repair Value',
      value: 'acquisition.analysis.afterRepairValue',
      active: false,
    },
    {
      label: 'Rental Price Monthly',
      value: 'acquisition.analysis.rent',
      active: false,
    },
    {
      label: 'Taxes Annual',
      value: 'acquisition.analysis.propertyTaxes',
      active: false,
    },
    {
      label: 'HOA',
      value: 'acquisition.analytics.buyHold.HOATotal',
      active: false,
    },
    {
      label: 'Sell Price',
      value: 'acquisition.analysis.resellPrice',
      active: false,
    },
    // { label: 'Stage', value: 'acquisition.activeStage', active: false },
  ],
  assumptionHeaders: [
    {
      label: 'Asset Managment Fee',
      value: 'assumptions.assetManagmentFee',
      active: false,
    },
    {
      label: 'Acquistion commission',
      value: 'assumptions.acquisitionCommission',
      active: false,
    },
    {
      label: 'Acquistion Fee',
      value: 'assumptions.acquisitionFee',
      active: false,
    },
    {
      label: 'Closing Cost',
      value: 'assumptions.closingCost',
      active: false,
    },
    {
      label: 'Down Payment',
      value: 'assumptions.downPayment',
      active: false,
    },
    {
      label: 'Disposition Fee',
      value: 'assumptions.dispositionFee',
      active: false,
    },
    {
      label: 'Estimated Rehab Per sqft',
      value: 'assumptions.estimatedRehab',
      active: false,
    },
    {
      label: 'Financing Fee',
      value: 'assumptions.financingFee',
      active: false,
    },
    {
      label: 'Growth Rate Income',
      value: 'assumptions.growthRateIncome',
      active: false,
    },
    {
      label: 'Growth Rate Expense',
      value: 'assumptions.growthRateExpense',
      active: false,
    },
    {
      label: 'Flip Holding Period (months)',
      value: 'assumptions.holdingPeriod',
      active: false,
    },
    {
      label: 'Rental Holding Period (years)',
      value: 'assumptions.rentalHoldingPeriod',
      active: false,
    },
    {
      label: 'Rehab Period (month)',
      value: 'assumptions.rehabPeriod',
      active: false,
    },
    {
      label: 'Home Price Appreciation',
      value: 'assumptions.homePriceAppreciation',
      active: false,
    },
    {
      label: 'Investor Tax Rate',
      value: 'assumptions.investorTaxRate',
      active: false,
    },
    {
      label: 'Interest Only Loan',
      value: 'assumptions.interestOnlyLoan',
      active: false,
    },
    {
      label: 'All Cash Purchase',
      value: 'assumptions.allCashPurchase',
      active: false,
    },
    {
      label: 'Asking Price to Sale Price Ratio',
      value: 'assumptions.listPriceToSalePriceRatio',
      active: false,
    },
    {
      label: 'Maintenance',
      value: 'assumptions.maintenance',
      active: false,
    },
    {
      label: 'utilities',
      value: 'assumptions.utilities',
      active: false,
    },
    {
      label: 'Capex (reserve/mo)',
      value: 'assumptions.monthlyReserves',
      active: false,
    },
    {
      label: 'Other Expense',
      value: 'assumptions.otherExpense',
      active: false,
    },
    {
      label: 'Owner Reserves',
      value: 'assumptions.ownerReserves',
      active: false,
    },
    { label: 'Points', value: 'assumptions.points', active: false },
    {
      label: 'Property Insurance',
      value: 'assumptions.propertyInsurance',
      active: false,
    },
    {
      label: 'Property Management',
      value: 'assumptions.propertyManagement',
      active: false,
    },
    {
      label: 'Pay Down Accelerator',
      value: 'assumptions.payDownAccelerator',
      active: false,
    },
    { label: 'Rate', value: 'assumptions.rate', active: false },
    {
      label: 'Sales commission',
      value: 'assumptions.salesCommission',
      active: false,
    },
    {
      label: 'Seller Closing Cost',
      value: 'assumptions.sellerClosingCost',
      active: false,
    },
    { label: 'Term', value: 'assumptions.term', active: false },
    {
      label: 'Term Type',
      value: 'assumptions.termType',
      active: false,
    },
    { label: 'Vacancy', value: 'assumptions.vacancy', active: false },
    // {
    //   label: 'Below Target: Cap Rate',
    //   value: 'assumptions.belowCapRate',
    //   active: false,
    // },
    // {
    //   label: 'Above Target: Cap Rate',
    //   value: 'assumptions.aboveCapRate',
    //   active: false,
    // },
    // {
    //   label: 'Below Target: Cash on Cash Return',
    //   value: 'assumptions.belowCashOnCashReturn',
    //   active: false,
    // },
    // {
    //   label: 'Above Target: Cash on Cash Return',
    //   value: 'assumptions.aboveCashOnCashReturn',
    //   active: false,
    // },
    // {
    //   label: 'Below Target: IRR',
    //   value: 'assumptions.belowIRR',
    //   active: false,
    // },
    // {
    //   label: 'Above Target: IRR',
    //   value: 'assumptions.aboveIRR',
    //   active: false,
    // },
    // {
    //   label: 'Below Target: Equity',
    //   value: 'assumptions.belowEquity',
    //   active: false,
    // },
    // {
    //   label: 'Above Target: Equity',
    //   value: 'assumptions.aboveEquity',
    //   active: false,
    // },
    // {
    //   label: 'Below Target: Selling Cap Rate',
    //   value: 'assumptions.belowSellingCapRate',
    //   active: false,
    // },
    // {
    //   label: 'Above Target: Selling Cap Rate',
    //   value: 'assumptions.aboveSellingCapRate',
    //   active: false,
    // },
    // {
    //   label: 'Below Target: Net ROI',
    //   value: 'assumptions.belowNetROI',
    //   active: false,
    // },
    // {
    //   label: 'Above Target: Net ROI',
    //   value: 'assumptions.aboveNetROI',
    //   active: false,
    // },
    // {
    //   label: 'Below Target: ARV',
    //   value: 'assumptions.belowARV',
    //   active: false,
    // },
    // {
    //   label: 'Above Target: ARV',
    //   value: 'assumptions.aboveARV',
    //   active: false,
    // },
    {
      label: 'MLS ID',
      value: 'MLSAgent.MLSID',
      active: false,
    },
    {
      label: 'Agent Name',
      value: 'MLSAgent.name',
      active: false,
    },
    {
      label: 'Brokerage',
      value: 'MLSAgent.brokerage',
      active: false,
    },
  ],
  AffiliateHeaders: [
    {
      label: 'Water',
      value: 'water',
      active: false,
    },
    {
      label: 'Sewer',
      value: 'sewer',
      active: false,
    },
    {
      label: 'Property Type',
      value: 'type',
      active: false,
    },
    {
      label: 'market',
      value: 'market',
      active: false,
    },
    {
      label: 'Plan',
      value: 'plan',
      active: false,
    },
    {
      label: 'Zone',
      value: 'zone',
      active: false,
    },
    {
      label: 'Parcel Id',
      value: 'parcelID',
      active: false,
    },
  ],
}

class ExportCSV extends Component {
  state = {
    show: false,
    selectedProperties: [],
    ...HEADERS,
  }

  toggleModal = () => {
    const { selected, properties } = this.props
    let selectedProperties = []
    if (selected) {
      for (let i = 0; i < selected.length; i++)
        selectedProperties.push(
          find(properties, ['_id', selected[i]]),
        )
    }
    selectedProperties = compact(selectedProperties)
    this.setState((prevState) => {
      return {
        show: !prevState.show,
        selectedProperties: selectedProperties,
        ...HEADERS,
      }
    })
  }

  hideModal = () => {
    this.setState((prevState) => {
      return { show: !prevState.show }
    })
  }

  selectProperty = (property) => {
    let { selectedProperties } = this.state
    selectedProperties.push(property)
    this.setState({ selectedProperties: selectedProperties })
  }

  removeProperty = (property) => {
    let { selectedProperties } = this.state
    pullAllBy(selectedProperties, [property], '_id')
    this.setState({ selectedProperties: selectedProperties })
  }

  getHeaders = () => {
    const {
      propertyHeaders,
      acquisitionHeaders,
      assumptionHeaders,
      AffiliateHeaders,
    } = this.state
    let headers = concat(
      propertyHeaders,
      acquisitionHeaders,
      assumptionHeaders,
      // AffiliateHeaders,
    )
    if (
      !isEmpty(this.props.session.me.activeOrg.affiliate) ||
      this.props?.session?.me?.activeOrg?.member
    )
      headers = concat(headers, AffiliateHeaders)
    let selected = []
    for (let i = 0; i < headers.length; i++) {
      if (headers[i].active) selected.push(headers[i])
    }
    return headers
  }

  renderProperty = (property) => {
    const { selectedProperties } = this.state
    const index = findIndex(selectedProperties, ['_id', property._id])
    if (index === -1)
      return (
        <Grid container alignItems="center" key={property._id}>
          <Grid item>
            <Checkbox
              onChange={() => this.selectProperty(property)}
              id={property._id}
              icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
              checkedIcon={
                <CheckBoxIcon
                  fontSize="large"
                  style={{ color: '#2296F3' }}
                />
              }
              checked={false}
            />
          </Grid>
          <Grid item>
            <label
              htmlFor={property._id}
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
              }}
            >
              {property.address}
            </label>
          </Grid>
        </Grid>
      )
    else
      return (
        <Grid container alignItems="center">
          <Grid item key={property._id}>
            <Checkbox
              onChange={() => this.removeProperty(property)}
              id={property._id}
              icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
              checkedIcon={
                <CheckBoxIcon
                  fontSize="large"
                  style={{ color: '#2296F3' }}
                />
              }
              checked={true}
            />
          </Grid>
          <Grid item>
            <label
              htmlFor={property._id}
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
              }}
            >
              {property.address}
            </label>
          </Grid>
        </Grid>
      )
  }

  render() {
    const {
      selectedPortfolioId = '',
      properties,
      //color = 'white',
      rightClickMenu,
      sidebarMenu,
      bulkActionsNotifications,
    } = this.props

    return (
      <Query
        query={GET_PROPERTIES}
        variables={
          selectedPortfolioId
            ? {
                portfolioId: [selectedPortfolioId],
                limit: GET_PROPERTIES_QUERY_LIMIT,
              }
            : {
                portfolioId: map(
                  this.props.portfolios,
                  (portfolio) => portfolio._id,
                ),
              }
        }
      >
        {({ loading, error, refetch, data }) => {
          if (loading && !properties)
            return (
              <Backdrop
                open={loading}
                style={{
                  color: '#fff',
                  zIndex: 2000,
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )
          if (error) return `Error! ${error.message}`
          let csvData
          if (properties) csvData = properties
          else csvData = data.properties?.properties

          // const json2csvParser = new Parser({ fields: this.getHeaders() })
          // let csv = json2csvParser.parse(csvData)
          const csvHeaders = map(this.getHeaders(), (header) => {
            return {
              label: header.label,
              key: header.value,
            }
          })

          return (
            <>
              {sidebarMenu && (
                <CSVLink
                  type="Button"
                  data={csvData}
                  headers={csvHeaders}
                  uFEFF={false}
                  filename={'export.csv'}
                  // enclosingCharacter=''
                  onClick={() => {
                    enqueueSnackbar(
                      `Success! Portfolio was exported to CSV`,
                      {
                        variant: 'success',
                        autoHideDuration: 3000,
                      },
                    )
                    //this.props.closeMenu()
                  }}
                  style={{ textDecoration: 'none', width: '100%' }}
                >
                  <ListItem button>
                    <CloudDownloadOutlinedIcon
                      style={{ marginRight: 5, color: '#757575' }}
                    />
                    <span>Export Portfolio</span>
                  </ListItem>
                </CSVLink>
              )}
              {rightClickMenu && (
                <CSVLink
                  type="Button"
                  data={csvData}
                  headers={csvHeaders}
                  uFEFF={false}
                  filename={'export.csv'}
                  // enclosingCharacter=''
                  onClick={() => {
                    enqueueSnackbar(
                      `Success! Property was exported to CSV`,
                      {
                        variant: 'success',
                        autoHideDuration: 3000,
                      },
                    )
                    //this.props.closeMenu()
                  }}
                  style={{ textDecoration: 'none', width: '100%' }}
                >
                  <ListItem button>
                    <Grid container direction="row" spacing={1}>
                      <Grid item className="gridItem2">
                        <FontAwesomeIcon
                          icon={['far', 'cloud-arrow-down']}
                          style={{ marginRight: -2 }}
                        />
                      </Grid>
                      <Grid item className="gridItem2">
                        <Typography>Export Property</Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                </CSVLink>
              )}
              {bulkActionsNotifications && (
                <CSVLink
                  type="Button"
                  data={csvData}
                  headers={csvHeaders}
                  uFEFF={false}
                  filename={'export.csv'}
                  // enclosingCharacter=''
                  onClick={() => {
                    enqueueSnackbar(
                      `Success! Selected properties was exported to CSV`,
                      {
                        variant: 'success',
                        autoHideDuration: 3000,
                      },
                    )
                    //this.props.setSelected([])
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <Tooltip title="Export Properties as CSV">
                    <IconButton size="large">
                      <FontAwesomeIcon
                        icon={['fal', 'cloud-download']}
                        style={{ color: 'white' }}
                      />
                    </IconButton>
                  </Tooltip>
                </CSVLink>
              )}

              {/* <Dialog open={show} onClose={this.toggleModal} dividers>
              <DialogTitle>
                Select Properties to Export as a CSV
              </DialogTitle>
              <DialogContent dividers>
                {map(properties, this.renderProperty)}
              </DialogContent>
              <DialogActions>
                <Button variant="text" onClick={this.toggleModal}>
                  Cancel
                </Button>
                <CSVLink
                  type="Button"
                  data={csvData}
                  uFEFF={false}
                  filename={'export.csv'}
                  onClick={() => {
                    this.hideModal()
                    this.props.clearSelected()
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <Button color="primary" variant="contained">
                    Export
                  </Button>
                </CSVLink>
              </DialogActions>
            </Dialog> */}
            </>
          )
        }}
      </Query>
    )
  }
}

export default withSession(ExportCSV)
