/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-16 11:36:56
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-18 10:27:51
 * @ Description: Card containing the sign in fields and button
 */

import React from 'react'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import logo from '@/assets/images/NewLogoBlue.png'
import SignInFooter from '../SignInFooter/index'

import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  Hidden,
  Link,
  Typography,
  InputAdornment,
  Tooltip,
  IconButton,
  useMediaQuery,
  Backdrop,
  CircularProgress,
} from '@mui/material'

import housefoliosLogo from '@/assets/images/HousefoliosCloudTransparent.png'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { useQuery, gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { GET_AFFILIATE } from '@/housefolios-components/CreateAccount/CreateAccountCard/queries'
import { saveAuthToken } from '@/utils/auth'
import useAffiliate from '@/hooks/useAffiliate'
import { useNavigate } from 'react-router-dom'

const hostName = import.meta.env.VITE_HOSTNAME

const SIGN_IN = gql`
  mutation ($login: String!, $password: String!) {
    signIn(login: $login, password: $password) {
      token
      member
      reset
    }
  }
`

export default function SignInCardComponent(props) {
  function getCookie(cName) {
    const name = cName + '='
    const cDecoded = decodeURIComponent(document.cookie) //to be careful
    const cArr = cDecoded.split('; ')
    let res
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length)
    })
    return res
  }

  let navigate = useNavigate()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  let source = urlParams.get('source')
  let sourceCookie = getCookie('SourceSignUp')
  let setCookie = false
  let headerImage = housefoliosLogo
  const [{ affiliate, affiliateLoading, urlAffiliate }] =
    useAffiliate({
      memberValue:
        sourceCookie === 'null' ? source : sourceCookie || source,
    })
  // let result = useQuery(GET_AFFILIATE, {
  //   variables: {
  //     memberValue:
  //       sourceCookie === 'null' ? source : sourceCookie || source,
  //   },
  // })
  // const affiliateLoading = result.loading
  // const { affiliate = {} } = result.data || {}

  if (!affiliateLoading) {
    if (isEmpty(affiliate)) setCookie = true
    else {
      const { signIn = {} } = affiliate
      headerImage =
        get(signIn, 'headerImage.0.url') || housefoliosLogo
    }
  }

  const [login, setLogin] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [passwordIsMasked, setPasswordIsMasked] = React.useState(true)

  const [loader, setLoader] = React.useState(false)

  const hiddenSmDown = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  )
  const hiddenSmUp = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'),
  )

  const onSubmit = (event, signIn, subscriptionPlan) => {
    event.preventDefault()
    setLoader(true)

    signIn()
      .then(async ({ data }) => {
        if (data.signIn.reset) {
          navigate(`/resetpassword/${data.signIn.token}`)
        } else {
          saveAuthToken(data.signIn.token)
          localStorage.setItem('trialBanner', 'true')
          if (setCookie && data) {
            data.signIn.member &&
              (document.cookie = `SourceSignUp=${data.signIn.member}; expires=Wed, 31 Dec 2025 12:00:00 UTC;`)
          }
          // props.refetch()
          window.location.replace('/')
        }
        //props.history.push('/launchpad')
        setLoader(false)
        //props.history.push(routes.PROPERTIES + 'card')
      })
      .catch((error) => {
        setLoader(false)
        console.log(error)
      })
  }

  const togglePasswordMask = () => {
    setPasswordIsMasked(!passwordIsMasked)
  }

  if (affiliateLoading)
    return (
      <Backdrop open={true} style={{ color: '#fff', zIndex: 2000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className="min-vh-100"
      >
        {hiddenSmUp ? null : (
          <Grid item style={{ textAlign: 'center', paddingTop: 50 }}>
            <img
              src={headerImage}
              alt="Housefolios"
              style={{ height: 40 }}
            />
          </Grid>
        )}
        {hiddenSmDown ? null : (
          <Grid item style={{ textAlign: 'center' }}>
            {/* <img src={logo} alt="Housefolios" /> */}
          </Grid>
        )}
        <Grid item style={{ padding: 10 }}>
          <Mutation
            mutation={SIGN_IN}
            variables={{ login, password }}
          >
            {(signIn, { data, loading, error }) => (
              <Card style={{ maxWidth: 400 }}>
                <CardContent>
                  <Grid container direction="column" spacing={4}>
                    <Grid item>
                      <Grid
                        container
                        direction="column" // Change to column to stack vertically
                        alignItems="center"
                        spacing={2} // Optional: add some space between the items
                      >
                        {hiddenSmDown ? null : (
                          <Grid item>
                            <img
                              src={headerImage}
                              alt="Housefolios"
                              style={{ height: 50 }}
                            />
                          </Grid>
                        )}
                        <Grid item>
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 600 }}
                          >
                            Welcome back
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            Use your credentials to sign in
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* <Grid item>
                    <Button
                      variant='outlined'
                      fullWidth={true}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={['fab', 'google']}
                        size="1x"
                      />
                      <Typography>SIGN IN WITH GOOGLE</Typography>
                    </Button>
                  </Grid>
                  <Grid item style={{ textAlign: 'center' }}>
                    <Typography>OR</Typography>
                  </Grid> */}
                    <Grid item>
                      <BlockUi
                        blocking={loader}
                        loader={
                          <PacmanLoader
                            color={'var(--primary)'}
                            loading={loader}
                          />
                        }
                        message={
                          <div className="text-primary">
                            Verifying User
                          </div>
                        }
                      >
                        <ValidatorForm
                          onSubmit={(event) =>
                            onSubmit(event, signIn)
                          }
                        >
                          <Grid container direction="row" spacing={2}>
                            <Grid item xs={12}>
                              <TextValidator
                                variant="outlined"
                                label="Email Address"
                                placeholder="Email Address"
                                fullWidth={true}
                                value={login}
                                onChange={(event) =>
                                  setLogin(event.target.value)
                                }
                                autoFocus
                                type="text"
                                error={error}
                                helperText={
                                  error
                                    ? error.message ===
                                      'GraphQL error: No user found with this login credentials.'
                                      ? 'Email not found'
                                      : ''
                                    : ''
                                }
                                validators={[
                                  'required',
                                  'isEmail',
                                  'maxStringLength: 50',
                                ]}
                                errorMessages={[
                                  'This field is required',
                                  'Not a valid email address',
                                  'You cannot exceed 50 characters',
                                ]}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextValidator
                                variant="outlined"
                                label="Password"
                                placeholder="Password"
                                value={password}
                                onChange={(event) =>
                                  setPassword(event.target.value)
                                }
                                fullWidth={true}
                                type={
                                  passwordIsMasked
                                    ? 'password'
                                    : 'text'
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={togglePasswordMask}
                                        size="small"
                                      >
                                        <Avatar
                                          style={{
                                            backgroundColor:
                                              'transparent',
                                            color: '#929292',
                                          }}
                                        >
                                          {passwordIsMasked ? (
                                            <RemoveRedEyeIcon />
                                          ) : (
                                            <VisibilityOffIcon />
                                          )}
                                        </Avatar>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                error={error}
                                helperText={
                                  error
                                    ? error.message ===
                                      'GraphQL error: Invalid password.'
                                      ? 'Incorrect login credentials'
                                      : ''
                                    : ''
                                }
                                validators={[
                                  'required',
                                  'maxStringLength: 50',
                                ]}
                                errorMessages={[
                                  'This field is required',
                                  'You cannot exceed 50 characters',
                                ]}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                type="submit"
                                // color='primary'
                                fullWidth={true}
                                style={{
                                  backgroundColor: '#04a3e3',
                                  color: 'white',
                                }}
                              >
                                <Typography>LOG IN</Typography>
                              </Button>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                wrap="nowrap"
                              >
                                {window.location.hostname !==
                                  'app.dealsauce.io' &&
                                  window.location.hostname !==
                                    'app.propertyedge.pro' && (
                                    <Grid item>
                                      <Link
                                        href={
                                          hostName === 'localhost' ||
                                          hostName ===
                                            'dev-admin.housefolios.com' ||
                                          hostName ===
                                            'dev-admin-beta.housefolios.com'
                                            ? '/createAccount'
                                            : 'https://housefolios.com/pricing'
                                        }
                                      >
                                        Create account
                                      </Link>
                                    </Grid>
                                  )}
                                <Typography style={{ flex: 1 }} />
                                <Grid
                                  item
                                  style={{ textAlign: 'end' }}
                                >
                                  <Link href="/ForgotPassword">
                                    Forgot password
                                  </Link>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Card
                                style={{
                                  backgroundColor: '#f4f5fc',
                                  boxShadow: 'none',
                                }}
                              >
                                <Tooltip title="Some features may not work on other browsers">
                                  <CardContent
                                    style={{ padding: 10 }}
                                  >
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Grid item>
                                        <Typography
                                          style={{ fontSize: 15 }}
                                        >
                                          <InfoOutlinedIcon
                                            style={{
                                              color: '#04a3e3',
                                              marginRight: 5,
                                            }}
                                          />
                                          {(affiliate?.companyName
                                            ? affiliate.companyName.includes(
                                                ' (',
                                              )
                                              ? affiliate.companyName.split(
                                                  ' (',
                                                )[0]
                                              : affiliate.companyName
                                            : 'Housefolios') + ' '}
                                          works best on Chrome
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Tooltip>
                              </Card>
                            </Grid>
                          </Grid>
                        </ValidatorForm>
                      </BlockUi>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Mutation>
        </Grid>
        <SignInFooter />
      </Grid>
    </>
  )
}
