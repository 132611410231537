import React from 'react'

import CreateAccountCard from '@/housefolios-components/CreateAccount/CreateAccountCard'
import bgImage from '@/assets/images/bgNewLarge.png'
import { Avatar, Grid, Typography } from '@mui/material'

export default function CreateAccountPage(props) {
  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="min-vh-100"
        style={{
          backgroundSize: 'cover',
          backgroundImage: 'url(' + bgImage + ')',
        }}
      >
        <Grid item>
          <CreateAccountCard {...props} />
        </Grid>
      </Grid>
    </>
  )
}
