import React from 'react';

import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

const PresentationLayout = (props) => {

  return <Outlet />;
};

PresentationLayout.propTypes = {
  children: PropTypes.node
};

export default PresentationLayout;
