import { gql } from '@apollo/client'

export const APPLY_FOR_LENDING = gql`
  mutation applyForLending(
    $propertyId: String!
    $loanPurpose: String
    $propertyType: String
    $propertyUnits: Int
    $residenceType: String
    $estimatedValue: String
    $loanAmount: String
    $rehabBudget: String
    $exitStrategy: String
    $termLength: String
    $entity: JSON
    $applicant: JSON
    $creditReport: [JSON]
    $loanApplication: [JSON]
    $purchaseAgreement: [JSON]
    $constructionBudget: [JSON]
    $scheduleOfRealEstate: [JSON]
    $personalFinancialStatement: [JSON]
    $mortgageStatement: [JSON]
    $valuationReport: [JSON]
  ) {
    applyForLending(
      propertyId: $propertyId
      loanPurpose: $loanPurpose
      propertyType: $propertyType
      propertyUnits: $propertyUnits
      residenceType: $residenceType
      estimatedValue: $estimatedValue
      loanAmount: $loanAmount
      rehabBudget: $rehabBudget
      exitStrategy: $exitStrategy
      termLength: $termLength
      entity: $entity
      applicant: $applicant
      creditReport: $creditReport
      loanApplication: $loanApplication
      purchaseAgreement: $purchaseAgreement
      constructionBudget: $constructionBudget
      scheduleOfRealEstate: $scheduleOfRealEstate
      personalFinancialStatement: $personalFinancialStatement
      mortgageStatement: $mortgageStatement
      valuationReport: $valuationReport
    ) {
      count
    }
  }
`

export const BUY_SKIP_TRACE_CREDITS = gql`
  mutation buySkipTraceCredits($credits: Int!, $token: JSON!) {
    buySkipTraceCredits(credits: $credits, token: $token) {
      _id
      meetingUrl
      name
      address
      city
      state
      zip
      phone
      email
      website
      legalDescription
      CMALegalDescription
      logo {
        url
        url_small
      }
      MLSData {
        MLS
        STATE
        MLSGrid
        username
        password
      }
      skipTraceCredits
    }
  }
`
