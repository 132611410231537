import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import map from 'lodash/map'
import sum from 'lodash/sum'
import head from 'lodash/head'
import kebabCase from 'lodash/kebabCase'

import Comps from '@/housefolios-components/PropertyContainer/CenterPage/Comps'
import PropertySummary from '@/housefolios-components/PropertyContainer/CenterPage/summary'
import MarketAnalysis from '@/housefolios-components/PropertyContainer/CenterPage/MarketAnalysis'

import {
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  List,
  ListItem,
  Menu,
  Typography,
} from '@mui/material'

import PerfectScrollbar from 'react-perfect-scrollbar'

import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'

import { useQuery } from '@apollo/client'
import { GET_ZILLOW } from '@/housefolios-components/TitleButtons/AddPropertyButton/queries'
import { GET_COMPPROPERTIES } from '@/housefolios-components/PropertyContainer/queries'
import { useNavigate, useParams } from 'react-router-dom'

export default function PublicProperty(props) {
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false)

  const { view = 'Summary' } = useParams()

  const urlParams = new URLSearchParams(window.location.search)

  let { loading, data } = useQuery(GET_ZILLOW, {
    variables: {
      addressInput: {
        address: urlParams
          .get('address')
          .replace('#', '%23')
          .replace('&', '%26'),
        city: urlParams.get('city'),
        state: urlParams.get('state'),
        zip: urlParams.get('zip'),
      },
    },
  })
  //const { getZillowPropertyData = {} } = data || {}
  const getZillowPropertyData = {
    zpid: 0,
    address: '123 Fake St.',
    city: 'Townsville',
    state: 'ZZ',
    zip: '55555',
    beds: 4,
    baths: 3,
    sqft: 5000,
    year: 1993,
    location: {
      latitude: 35,
      longitude: -80,
    },
    files: {
      url: [],
    },
    zestimate: {
      amount: [{ _: 10000 }],
    },
    rentZestimate: {
      amount: [{ _: 333 }],
    },
    taxAssessment: 500,
    description: 'Hardcoded test property.',
  }
  const propertyLoading = loading

  let property = getZillowPropertyData
  property.acquisition = {
    analysis: {
      listPrice: property.zestimate
        ? property.zestimate.amount[0]._
        : null,
      rent: property.rentZestimate
        ? property.rentZestimate.amount[0]._
        : null,
    },
  }
  property.images = []
  if (getZillowPropertyData.files)
    map(getZillowPropertyData.files.url, (url) => {
      property.images.push({ url: url })
    })

  let result = useQuery(GET_COMPPROPERTIES, {
    variables: { propertyId: property?._id },
  })
  data = result.data
  loading = result.loading
  const { compProperties = [] } = data || {}

  const compValues = map(compProperties, (comp) => {
    return {
      propertyPrice: Number(
        comp.estval * (1 + comp.priceAdjustment / 100),
      ),
      propertyPerSqft: comp.sqft
        ? Number(comp.estval * (1 + comp.priceAdjustment / 100)) /
            comp.sqft || null
        : null,
      sqft: comp.sqft,
    }
  })

  const toggleSidebarMenu = () =>
    setIsSidebarMenuOpen(!isSidebarMenuOpen)

  //const [activeTab, setActiveTab] = useState('Summary');
  const activeTab = view

  const [openSignup, setOpenSignup] = useState(false)

  const toggleSignup = () => {
    setOpenSignup(!openSignup)
  }

  let navigate = useNavigate()

  const toggle = (tab) => {
    if (
      tab !== 'Summary' &&
      tab !== 'Comps' &&
      tab !== 'MarketAnalysis'
    )
      setOpenSignup(true)
    else if (activeTab !== tab)
      navigate(
        `/publicproperty/${tab}?address=${urlParams.get(
          'address',
        )}&city=${urlParams.get('city')}&state=${urlParams.get(
          'state',
        )}&zip=${urlParams.get('zip')}`,
      )
    //setActiveTab(tab);
  }

  const renderCentralPage = (property) => {
    switch (activeTab) {
      case 'Summary':
        return (
          <PropertySummary
            property={property}
            toggleCenter={toggle}
            public
          />
        )
      case 'Comps':
        return (
          <Comps
            property={property}
            toggleCenter={toggle}
            comps={compProperties}
            compValues={compValues}
            public
          />
        )
      case 'MarketAnalysis':
        return (
          <MarketAnalysis
            property={property}
            toggleCenter={toggle}
            analysisInput={property.acquisition.analysis}
            public
          />
        )
      default:
        return null
    }
  }

  const [anchorElMoreOptionsMenu, setAnchorElMoreOptionsMenu] =
    useState(null)
  const handleClickMoreOptionsMenu = (event) => {
    setAnchorElMoreOptionsMenu(event.currentTarget)
  }
  const handleCloseMoreOptionsMenu = () => {
    setAnchorElMoreOptionsMenu(null)
  }

  const currencyFormater = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
  })

  if (loading || propertyLoading) return 'Loading...'
  //if (error) return error.message
  //if (propertyError) return propertyError.message

  return (
    <Grid
      item
      xs={12}
      className="app-inner-content-layout app-inner-content-layout-fixed"
    >
      <div className="btn-md-pane d-lg-none px-4 order-0">
        <Button
          onClick={toggleSidebarMenu}
          size="small"
          className="btn-primary p-0 btn-icon d-40"
        >
          <FontAwesomeIcon icon={['far', 'ellipsis-vertical']} />
        </Button>
      </div>
      <div
        className={clsx(
          'app-inner-content-layout--sidebar bg-white app-inner-content-layout--sidebar__lg order-1',
          { 'layout-sidebar-open': isSidebarMenuOpen },
        )}
      >
        <PerfectScrollbar>
          <div className="px-4 pt-4">
            <List
              component="div"
              className="nav-pills nav-neutral-primary flex-column"
            >
              <ListItem className="d-flex px-0 pt-1 pb-1 justify-content-between">
                <div className="text-uppercase font-size-sm text-primary font-weight-bold">
                  Property
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href={null}
                onClick={(e) => toggle('Summary')}
                selected={activeTab === 'Summary'}
              >
                <DonutLargeOutlinedIcon className="mr-2" />
                Property Summary
              </ListItem>
            </List>
            <div className="divider my-1" />
            <List
              component="div"
              className="nav-pills nav-neutral-primary flex-column"
            >
              <ListItem className="d-flex px-0 pt-1 pb-1 justify-content-between">
                <div className="text-uppercase font-size-sm text-primary font-weight-bold">
                  Area
                </div>
              </ListItem>
              <ListItem
                component="a"
                button
                href={null}
                onClick={(e) => toggle('Comps')}
                selected={activeTab === 'Comps'}
              >
                <FontAwesomeIcon
                  icon={['far', 'house-circle-check']}
                />
                Comps
              </ListItem>
              <ListItem
                component="a"
                button
                href={null}
                onClick={(e) => toggle('MarketAnalysis')}
                selected={activeTab === 'MarketAnalysis'}
              >
                <MapOutlinedIcon className="mr-2" />
                Market Analysis
              </ListItem>
            </List>
          </div>
        </PerfectScrollbar>
      </div>
      <div className="app-inner-content-layout--main order-3 order-lg-2 card-box bg-secondary">
        <PerfectScrollbar>
          <div
            className="card-header rounded-0 bg-white p-4 border-bottom sticky-header"
            style={{ top: '0px', zIndex: 1000 }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <div className="d-block p-0 avatar-icon-wrapper">
                      <div className="avatar-icon rounded d-flex justify-content-center align-items-center">
                        <div
                          className="blur-load"
                          style={{
                            backgroundImage:
                              'url(' + head(property.images)
                                ? head(property.images).url_small
                                : '/images/default_image.png' + ')',
                          }}
                        >
                          <img
                            src={
                              /*property.mainImage*/ head(
                                property.images,
                              )
                                ? head(property.images).url
                                : '/images/default_image.png'
                            }
                            alt="..."
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item>
                            <h5
                              className="card-title font-weight-bold"
                              style={{
                                fontSize: 26,
                                marginBottom: 0,
                              }}
                            >
                              {currencyFormater.format(
                                property.acquisition.analysis
                                  .listPrice,
                              ) || '-'}
                            </h5>
                          </Grid>
                          <Grid item>
                            <h5
                              className="card-title font-size-sm"
                              style={{ marginBottom: 0 }}
                            >
                              {sum(property.beds) || '-'} bd |{' '}
                              {sum(property.baths) || '-'} ba |{' '}
                              {formatter.format(sum(property.sqft)) ||
                                '-'}{' '}
                              sqft | {property.year || '-'} yr
                            </h5>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {property.address}, {property.city}{' '}
                          {property.state} {property.zip}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      className="d-44 btn-warning"
                      variant="contained"
                      onClick={handleClickMoreOptionsMenu}
                    >
                      <FontAwesomeIcon
                        icon={['far', 'ellipsis-vertical']}
                        className="font-size-xl"
                        color="#fcfcfc"
                      />
                    </Button>
                    <Menu
                      anchorEl={anchorElMoreOptionsMenu}
                      keepMounted
                      open={Boolean(anchorElMoreOptionsMenu)}
                      onClose={handleCloseMoreOptionsMenu}
                      classes={{ list: 'p-0' }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <div className="dropdown-menu-xl">
                        <List
                          component="div"
                          className="nav-list-square nav-neutral-primary"
                        >
                          <ListItem className="nav-item--header">
                            <span style={{ color: '#909497' }}>
                              More Options
                            </span>
                          </ListItem>
                          <ListItem
                            button
                            onClick={() =>
                              window.open(
                                `https://www.zillow.com/homes/${kebabCase(
                                  property.address,
                                )},-${property.state},-${
                                  property.zip
                                }_rb/`,
                                '_blank' /*'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400'*/,
                              )
                            }
                          >
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                            >
                              <Grid item>
                                <FontAwesomeIcon
                                  icon={[
                                    'far',
                                    'arrow-up-right-from-square',
                                  ]}
                                  className="font-size-lg"
                                  style={{ marginRight: 2 }}
                                />
                              </Grid>
                              <Grid item>
                                <Typography>Open Zillow</Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          {/* <Divider className="my-2" />
                          <ListItem button onClick={handleMoreOptionsClick}>
                            <div className="nav-link-icon">
                              <FontAwesomeIcon icon={['far', 'circle-question']} />
                            </div>
                            <span>Need Some Help</span>
                            <Typography style={{ flex: 1 }} />
                            {openMoreOptionsMenu ? <ExpandLess /> : <ExpandMore />}
                          </ListItem>
                          <Collapse in={openMoreOptionsMenu} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItem button style={{ paddingLeft: 30 }}>
                                <div className="nav-link-icon">
                                  <FontAwesomeIcon icon={['far', 'circle-play']} />
                                </div>
                                <span>Watch Video</span>
                              </ListItem>
                              <ListItem button style={{ paddingLeft: 30 }}>
                                <div className="nav-link-icon">
                                  <FontAwesomeIcon icon={['far', 'question-square']} />
                                </div>
                                <span>FAQs</span>
                              </ListItem>
                              <ListItem button style={{ paddingLeft: 30 }}>
                                <div className="nav-link-icon">
                                  <FontAwesomeIcon icon={['far', 'compass']} />
                                </div>
                                <span>Take a tour</span>
                              </ListItem>
                              <ListItem button style={{ paddingLeft: 30 }} className=" nav-link-simple" onClick={null}>
                                <div className="nav-link-icon">
                                  <FontAwesomeIcon icon={['far', 'message-lines']} />
                                </div>
                                <span>Live Chat</span>
                              </ListItem>
                              <Query
                                query={GET_TRAINING_BUTTONS}
                                variables={{
                                  page: `Property-${activeTab}`,
                                }}
                              >
                                {({loading, error, data }) => {
                                  if (loading) return 'loading'
                                  const { trainingButtons = [] } = data
                                  return map(trainingButtons, trainingButton => (
                                    <ListItem key={trainingButton?._id} button component={trainingButton.link ? "a" : null} href={trainingButton.link || null} target={trainingButton.linkTarget || null} style={{ paddingLeft: 30 }} className="nav-link-simple">
                                      <div className="nav-link-icon">
                                        <FontAwesomeIcon icon={trainingButton.icon} />
                                      </div>
                                      <span>{trainingButton.text}</span>
                                    </ListItem>
                                  ))
                                }}
                              </Query>
                            </List>
                          </Collapse> */}
                        </List>
                      </div>
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {renderCentralPage(property)}
        </PerfectScrollbar>
      </div>

      <Dialog
        open={openSignup}
        onClose={toggleSignup}
        classes={{ paper: 'shadow-lg rounded' }}
      >
        <Card>
          <CardContent>
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div
                  style={{
                    backgroundColor: '#b3d7ff',
                    color: '#008aff',
                  }}
                  className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130"
                >
                  <FontAwesomeIcon
                    icon={['far', 'user-plus']}
                    className="d-flex align-self-center display-2 "
                  />
                </div>
              </div>
              <h4 className="font-weight-bold mt-4">
                Create account for full analysis!
              </h4>
              <p className="mb-0 font-size-lg text-muted">
                Create account to see full analysis that includes ARV,
                comps, rehab, closing costs, projections, and so much
                more!
              </p>
              <div className="pt-4">
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  justifyContent="space-around"
                >
                  <Grid item>
                    <Button
                      className="btn-warning"
                      variant="contained"
                    >
                      Watch Demo
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary">
                      Plan Options
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </CardContent>
          {/* <Divider />
          <CardActions>
            <Typography style={{ flex: 1 }} />
            <Button variant='text' onClick={handleCloseEditPortfolio}>Cancel</Button>
            <Button variant='contained' color="primary" type='submit' style={{ marginRight: 10 }}>Save</Button>
          </CardActions> */}
        </Card>
      </Dialog>
    </Grid>
  )
}
