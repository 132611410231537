import React from 'react'
import { useQuery } from '@apollo/client'
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { GET_SUBSCRIPTION_PLAN } from '@/housefolios-components/CreateAccount/CreateAccountCard/mutations'
import { currencyFormat, numberFormat } from '@/utils/number'

function SubscriptionPlanCard({
  activeTab,
  section,
  session,
  toggleSubscribe,
  changePlan,
}) {
  const { loading, error, data } = useQuery(GET_SUBSCRIPTION_PLAN, {
    variables: {
      subscriptionPlan:
        activeTab === 'checkedC'
          ? section.nameMonthly
          : section.nameYearly,
    },
  })
  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  const { subscriptionPlan = {} } = data
  return (
    <Grid item sm={3}>
      <Card style={{ margin: 10, textAlign: 'center', position: 'relative' }}>
        {activeTab === 'checkedC'
          ? section.nameMonthly === session.me.subscriptionPlan && (
              <span className="ribbon-angle ribbon-angle--top-right ribbon-primary">
                <small>Current</small>
              </span>
            )
          : section.nameYearly === session.me.subscriptionPlan && (
              <span className="ribbon-angle ribbon-angle--top-right ribbon-primary">
                <small>Current</small>
              </span>
            )}
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className="font-weight-light text-uppercase mt-4"
              style={{ marginBottom: -5 }}
            >
              {activeTab === 'checkedC'
                ? section.subHeaderMonthly
                : section.subHeaderYearly}
            </Typography>
          </Grid>
          <Grid item></Grid>
          <Typography
            variant="h4"
            color="primary"
            className="font-weight-bold text-uppercase"
          >
            {section.label}
          </Typography>
        </Grid>
        <CardContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography
                variant="h3"
                className="font-weight-bold"
                style={{ marginBottom: -25 }}
              >
                {currencyFormat.format(
                  activeTab === 'checkedC'
                    ? subscriptionPlan.price / 100
                    : subscriptionPlan.price / 100 / 12,
                )}
              </Typography>
            </Grid>
            {section.label !== 'Free' && (
              <Grid item>
                <Typography
                  variant="subtitle1"
                  style={{ paddingTop: 0 }}
                >
                  {activeTab === 'checkedC'
                    ? 'PER MONTH'
                    : `${currencyFormat.format(
                        subscriptionPlan.price / 100,
                      )} PER YEAR`}
                </Typography>
              </Grid>
            )}

            {section.label !== 'Free' ? (
              <Grid item>
                <Typography>
                  Analyze{' '}
                  <b>
                    {numberFormat.format(
                      subscriptionPlan.propertyCount,
                    )}
                  </b>{' '}
                  Properties/mo
                </Typography>
              </Grid>
            ) : (
              <Grid item style={{ marginTop: 30 }}>
                <Typography>
                  *Analyze <b>{section.properties}</b> Properties/mo
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Typography>
                Create up to{' '}
                <b>{subscriptionPlan.porfolioCount || 1}</b> portfolio
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Add <b>{subscriptionPlan.teamCount || 0}</b> team
                members
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{section.search}</Typography>
            </Grid>
            <Grid item>
              <Typography>{section.analyze}</Typography>
            </Grid>
            <Grid item>
              <Typography>{section.comp}</Typography>
            </Grid>
            {section.housefoliosMarketplace && (
              <Grid item>
                <Typography>
                  {section.housefoliosMarketplace}
                </Typography>
              </Grid>
            )}
            {subscriptionPlan.marketplaceCount > 0 &&
              subscriptionPlan.marketplaceCount && (
                <Grid item>
                  <Typography>
                    Create <b>{subscriptionPlan.marketplaceCount}</b>{' '}
                    Marketplace websites
                  </Typography>
                </Grid>
              )}
          </Grid>
          {((activeTab === 'checkedC' &&
            section.nameMonthly !== session.me.subscriptionPlan) ||
            (activeTab === 'checkedB' &&
              section.nameYearly !== session.me.subscriptionPlan)) &&
            !session.me.activeOrg.currentlyFree && (
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <Button
                  onClick={
                    isEmpty(session.me.activeOrg.subscriptions)
                      ? toggleSubscribe
                      : changePlan
                  }
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  Manage Plan
                </Button>
              </Grid>
            )}
        </CardContent>
      </Card>
    </Grid>
  )
}

export default SubscriptionPlanCard
