/**
 * @ Author: Housefolios
 * @ Create Time: 2022-09-02 10:50:32
 * @ Modified by: David Helmick
 * @ Modified time: 2022-10-13 18:23:33
 * @ Description: Dialog to approve/reject properties submitted to a shared marketplace.
 */

import React, { useState } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'

import map from 'lodash/map'
import pull from 'lodash/pull'
import isEmpty from 'lodash/isEmpty'
import sum from 'lodash/sum'
import isArray from 'lodash/isArray'
import get from 'lodash/get'
import set from 'lodash/set'
import assign from 'lodash/assign'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'

import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { Query } from '@apollo/client/react/components'
import {
  GET_MARKETPLACES,
  GET_ORGS_MARKETPLACE_SHARED_WITH,
} from '../queries'
import { useMutation } from '@apollo/client'
import {
  APPROVE_PROPERTIES,
  REJECT_PROPERTIES,
  SHARE_MARKETPLACE,
} from '../mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import RemoveSharedMarketplace from '../RemoveSharedMarketplace'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { GET_ME } from '@/housefolios-components/Session/queries'

const hostname = import.meta.env.VITE_HOSTNAME

function SubmissionApprovalComponent(props) {
  const { marketplace = '', propertyIds } = props

  const [openAccept, setOpenAccept] = useState(false)
  const [openReject, setOpenReject] = useState(false)
  const [loader, setLoader] = useState(false)

  const [approveProperties] = useMutation(APPROVE_PROPERTIES)
  const [rejectProperties] = useMutation(REJECT_PROPERTIES)

  // const phpSend = (marketplaces, remove = false) => {
  //   if (remove) {

  //     let data = {
  //       _id: property._id,
  //       remove_property: remove,
  //     }

  //     const str_json = JSON.stringify(data)

  //     //SOURCE COMMENT: I wanted to replace XMLHttpRequest so I followed the example of https://developers.google.com/web/updates/2015/03/introduction-to-fetch#:~:text=fetch()%20allows%20you%20to,the%20complex%20API%20of%20XMLHttpRequest.
  //     fetch(
  //       hostname === 'localhost'
  //       ? 'http://marketplace.housefolios.com/api/parserDev.php'
  //       //? 'http://housefolios-marketplace.test/api/parserDev.php'
  //       : hostname === 'dev-admin.housefolios.com'
  //         ? 'https://marketplace.housefolios.com/api/parserDev.php'
  //         : 'https://marketplace.housefolios.com/api/parser.php',
  //     {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: str_json,
  //         },
  //     )
  //     .then(
  //       function(response) {
  //         if (response.status !== 200) {
  //           console.log('Looks like there was a problem. Status Code: ' +
  //             response.status);
  //           return;
  //         }

  //         // Examine the text in the response
  //         response.json().then(function(data) {
  //           console.log(data);
  //         });
  //       }
  //     )
  //     .catch(function(err) {
  //       console.log('Fetch Error :-S', err);
  //     });

  //     // const request = new XMLHttpRequest()
  //     // request.open(
  //     //   'POST',
  //     //   'https://marketplace.housefolios.com/api/parser.php',
  //     //   true,
  //     // )
  //     // request.setRequestHeader('Content-type', 'application/json')
  //     // request.send(str_json)
  //   } else {

  //     let data = cloneDeep(property)
  //     data.beds = sum(data.beds)
  //     data.baths = sum(data.baths)
  //     data.sqft = sum(data.sqft)
  //     if (get(data, 'acquisition.analysis')) {
  //       map(data.acquisition.analysis, (value, key) => {
  //         if (isArray(value)) {
  //           set(data, `acquisition.analysis.${key}`, sum(get(data, `acquisition.analysis.${key}`)))
  //         }
  //       })
  //     }
  //     if (get(data, 'assumptions')) {
  //       map(data.assumptions, (value, key) => {
  //         if (isArray(value)) {
  //           set(data, `assumptions.${key}`, sum(get(data, `assumptions.${key}`)))
  //         }
  //       })
  //     }
  //     if (get(data, 'acquisition.analytics')) {
  //      map(data.acquisition.analytics, (value, key) => {
  //        if (isArray(value)) {
  //           set(data, `acquisition.analytics.${key}`, sum(get(data, `acquisition.analytics.${key}`)))
  //        } else if (isObject(value)) {
  //          map(value, (secondKey, value) => {
  //            if (isArray(value)) {
  //               set(data, `acquisition.analytics.${key}.${secondKey}`, sum(get(data, `acquisition.analytics.${key}.${secondKey}`)))
  //            }
  //          })
  //        }
  //      })
  //     }

  //     let activeMP = []
  //     if (marketplaces) {
  //       activeMP.push({
  //         marketplaces: {
  //           ...marketplaces,
  //         },
  //       })

  //       data = assign(data, activeMP[0])
  //     }

  //     const str_json = JSON.stringify(data)

  //     //SOURCE COMMENT: I wanted to replace XMLHttpRequest so I followed the example of https://developers.google.com/web/updates/2015/03/introduction-to-fetch#:~:text=fetch()%20allows%20you%20to,the%20complex%20API%20of%20XMLHttpRequest.
  //     fetch(
  //       hostname === 'localhost'
  //       ? 'http://marketplace.housefolios.com/api/parserDev.php'
  //       //? 'http://housefolios-marketplace.test/api/parserDev.php'
  //       : hostname === 'dev-admin.housefolios.com'
  //         ? 'https://marketplace.housefolios.com/api/parserDev.php'
  //         : 'https://marketplace.housefolios.com/api/parser.php',
  //     {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: str_json,
  //         },
  //     )
  //     .then(
  //       function(response) {
  //         if (response.status !== 200) {
  //           console.log('Looks like there was a problem. Status Code: ' +
  //             response.status);
  //           return;
  //         }

  //         // Examine the text in the response
  //         response.json().then(function(data) {
  //           console.log(data);
  //         });
  //       }
  //     )
  //     .catch(function(err) {
  //       console.log('Fetch Error :-S', err);
  //     });

  //     // const request = new XMLHttpRequest()
  //     // request.open(
  //     //   'POST',
  //     //   'https://marketplace.housefolios.com/api/parser.php',
  //     //   true,
  //     // )
  //     // request.setRequestHeader('Content-type', 'application/json')
  //     // request.send(str_json)
  //   }
  // }

  return (
    <>
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          style={{ backgroundColor: '#3edf6e', color: 'white' }}
          onClick={() => setOpenAccept(true)}
        >
          Accept
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          style={{ backgroundColor: '#ff3f3d', color: 'white' }}
          onClick={() => setOpenReject(true)}
        >
          Reject
        </Button>
      </Grid>

      <Dialog
        open={openReject}
        onClose={() => setOpenReject(false)}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>
          Reject {propertyIds.length > 1 ? 'Properties' : 'Property'}
        </DialogTitle>
        <DialogContent>
          Would you like to reject{' '}
          {propertyIds.length > 1
            ? 'these properties'
            : 'this property'}
          ?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReject(false)}>No</Button>
          <Button
            variant="contained"
            style={{ backgroundColor: '#ff3f3d', color: 'white' }}
            onClick={(event) => {
              // let newMarkets = [...property.marketplaces.marketplaceName]
              // let newApprovedMarkets = [...property.marketplaces.approvedMarketplace]
              // pull(newMarkets, marketplace)
              // pull(newApprovedMarkets, marketplace)
              // const marketplaces = {
              //   marketplaceName: newMarkets,
              //   approvedMarketplace: newApprovedMarkets,
              //   expirationDate: new Date(
              //     Date.now() + 7 * 24 * 60 * 60 * 1000,
              //   ),
              //   extraTags: property.marketplaces.extraTags,
              // }
              rejectProperties({
                variables: {
                  propertyIds,
                  marketplaceURL:
                    marketplace === 'null'
                      ? 'housefolios'
                      : marketplace,
                },
                refetchQueries: concat(
                  [
                    {
                      query: GET_ME,
                    },
                    {
                      query: GET_MARKETPLACES,
                      variables: {
                        marketplaces: ['housefolios', 'myGrayLabel'],
                        approved: true,
                      },
                    },
                    {
                      query: GET_MARKETPLACES,
                      variables: {
                        marketplaces: ['housefolios', 'myGrayLabel'],
                        orgIds: [props.session.me.activeOrg._id],
                        approved: true,
                      },
                    },
                    {
                      query: GET_MARKETPLACES,
                      variables: {
                        marketplaces: ['housefolios', 'myGrayLabel'],
                        approved: false,
                      },
                    },
                    {
                      query: GET_MARKETPLACES,
                      variables: {
                        marketplaces: ['housefolios', 'myGrayLabel'],
                        orgIds: [props.session.me.activeOrg._id],
                        approved: false,
                      },
                    },
                  ],
                  map(propertyIds, (id) => {
                    return {
                      query: GET_PROPERTY,
                      variables: { id: id },
                    }
                  }),
                  map(
                    props.session.me.activeOrg.marketPlaces,
                    (marketplace) => {
                      return {
                        query: GET_MARKETPLACES,
                        variables: {
                          marketplaces: [marketplace.url],
                          approved: true,
                        },
                      }
                    },
                  ),
                  map(
                    props.session.me.activeOrg.marketPlaces,
                    (marketplace) => {
                      return {
                        query: GET_MARKETPLACES,
                        variables: {
                          marketplaces: [marketplace.url],
                          approved: false,
                        },
                      }
                    },
                  ),
                  map(
                    props.session.me.activeOrg.sharedMarketplaces,
                    (marketplace) => {
                      return {
                        query: GET_MARKETPLACES,
                        variables: {
                          marketplaces: [marketplace.url],
                          approved: true,
                        },
                      }
                    },
                  ),
                  map(
                    props.session.me.activeOrg.sharedMarketplaces,
                    (marketplace) => {
                      return {
                        query: GET_MARKETPLACES,
                        variables: {
                          marketplaces: [marketplace.url],
                          approved: false,
                        },
                      }
                    },
                  ),
                ),
              }).then(() => {
                // const remove = isEmpty(newMarkets)
                // phpSend(marketplaces, remove)
                enqueueSnackbar(
                  `${
                    propertyIds.length > 1 ? 'Properties' : 'Property'
                  } has been rejected`,
                  {
                    variant: 'error',
                    autoHideDuration: 3000,
                  },
                )
                if (props.setMarketplaceSelected)
                  props.setMarketplaceSelected([])
                if (props.removeProperty) props.removeProperty(event)
              })
              setOpenReject(false)
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAccept}
        onClose={() => setOpenAccept(false)}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>
          Accept {propertyIds.length > 1 ? 'Properties' : 'Property'}
        </DialogTitle>
        <DialogContent>
          Would you like to accept{' '}
          {propertyIds.length > 1
            ? 'these properties'
            : 'this property'}
          ?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAccept(false)}>No</Button>
          <Button
            variant="contained"
            style={{ backgroundColor: '#3edf6e', color: 'white' }}
            onClick={(event) => {
              // let newApprovedwMarkets = [...property.marketplaces.approvedMarketplace]
              // newApprovedwMarkets.push(marketplace)
              // const marketplaces = {
              //   marketplaceName: property.marketplaces.marketplaceName,
              //   approvedMarketplace: newApprovedwMarkets,
              //   expirationDate: new Date(
              //     Date.now() + 7 * 24 * 60 * 60 * 1000,
              //   ),
              //   extraTags: property.marketplaces.extraTags,
              // }
              approveProperties({
                variables: {
                  propertyIds,
                  marketplaceURL:
                    marketplace === 'null'
                      ? 'housefolios'
                      : marketplace,
                },
                refetchQueries: concat(
                  [
                    {
                      query: GET_ME,
                    },
                    {
                      query: GET_MARKETPLACES,
                      variables: {
                        marketplaces: ['housefolios', 'myGrayLabel'],
                        approved: true,
                      },
                    },
                    {
                      query: GET_MARKETPLACES,
                      variables: {
                        marketplaces: ['housefolios', 'myGrayLabel'],
                        orgIds: [props.session.me.activeOrg._id],
                        approved: true,
                      },
                    },
                    {
                      query: GET_MARKETPLACES,
                      variables: {
                        marketplaces: ['housefolios', 'myGrayLabel'],
                        approved: false,
                      },
                    },
                    {
                      query: GET_MARKETPLACES,
                      variables: {
                        marketplaces: ['housefolios', 'myGrayLabel'],
                        orgIds: [props.session.me.activeOrg._id],
                        approved: false,
                      },
                    },
                  ],
                  map(propertyIds, (id) => {
                    return {
                      query: GET_PROPERTY,
                      variables: { id: id },
                    }
                  }),
                  map(
                    props.session.me.activeOrg.marketPlaces,
                    (marketplace) => {
                      return {
                        query: GET_MARKETPLACES,
                        variables: {
                          marketplaces: [marketplace.url],
                          approved: true,
                        },
                      }
                    },
                  ),
                  map(
                    props.session.me.activeOrg.marketPlaces,
                    (marketplace) => {
                      return {
                        query: GET_MARKETPLACES,
                        variables: {
                          marketplaces: [marketplace.url],
                          approved: false,
                        },
                      }
                    },
                  ),
                  map(
                    props.session.me.activeOrg.sharedMarketplaces,
                    (marketplace) => {
                      return {
                        query: GET_MARKETPLACES,
                        variables: {
                          marketplaces: [marketplace.url],
                          approved: true,
                        },
                      }
                    },
                  ),
                  map(
                    props.session.me.activeOrg.sharedMarketplaces,
                    (marketplace) => {
                      return {
                        query: GET_MARKETPLACES,
                        variables: {
                          marketplaces: [marketplace.url],
                          approved: false,
                        },
                      }
                    },
                  ),
                ),
              }).then(() => {
                //phpSend(marketplaces)
                enqueueSnackbar(
                  `${
                    propertyIds.length > 1 ? 'Properties' : 'Property'
                  } has been accepted`,
                  {
                    variant: 'success',
                    autoHideDuration: 3000,
                  },
                )
                if (props.setMarketplaceSelected)
                  props.setMarketplaceSelected([])
                if (props.removeProperty) props.removeProperty(event)
              })
              setOpenAccept(false)
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default withSession(SubmissionApprovalComponent)
