import { gql } from '@apollo/client'
import { AssumptionsFragments } from '@/housefolios-components/AssumptionsSettings/fragments'
import {
  AcquisitionFragments,
  AssetManagementFragments,
  OfferToCloseFragments,
} from '@/housefolios-components/Property/fragments'

export const GET_PROPERTY = gql`
  query property($id: ID!) {
    property(id: $id) {
      _id
      estval
      comments
      address
      city
      county
      state
      zip
      beds
      baths
      sqft
      strategy
      marketplaceStrategy
      strategies
      hideAddress
      hideDaysOnMarketplace
      status
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      favorite
      owned
      propertyType
      propertyUnitAmount
      propertyDashboard
      marketplaces {
        marketplaceName
        approvedMarketplace
        expirationDate
        extraTags
        proformaType
      }
      mainImage
      compCount
      lot {
        _id
        owned
        status
      }
      description
      notes
      proformaDescription
      proformaImages
      proformaImagePage
      proformaLogo
      proformaFloorPlan
      proformaRehabCost
      proformaHideAddress
      proformaContact
      financingProformaSent
      applyForLending
      propertyManagementGroups
      buyAndHoldOrder
      fixAndFlipOrder
      turnkeyOrder
      BRRRROrder
      shortTermRentalOrder
      buildToRentOrder
      wholesaleOrder
      BOROrder
      proformaFile {
        filename
        filetype
        filesize
        url
        url_small
        createdBy
        createdAt
      }
      realeflowReportURL
      deletedAt
      zpid
      portfolio {
        _id
        name
        strategies
        strategy
        organization {
          _id
          name
          email
          phone
          logo {
            url
            url_small
          }
          legalDescription
          CMALegalDescription
          member
        }
        assumptions {
          ...assumptions
        }
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      assumptions
      acquisition {
        activeStage
        analysis {
          ...analysis
        }
        analytics {
          ...analytics
        }
      }
      source
      estimates
      MLSID
      MLSAgent {
        MLSID
        brokerage
        name
        phone
        email
      }
      lender
      lenderStatus
      lenderTimeline
      jv
      jvStatus
      jvTimeline
      spruceOrderId
      sewer
      water
      exchange
      owned
      market
      type
      plan
      zone
      parcelID
      lotSize
      basementSqft
      remodelYear
      parking
      ac
      homeType
      floodZone
      offerMade
      checklists {
        underwritingChecklist
        offerToCloseChecklist
        wholesaleChecklist
        lotChecklist
        buildChecklist
        saleChecklist
        fixFlipChecklist
        affiliateChecklist
        BORChecklist
        astroFlipChecklist
      }
      otherFiles
      marketplaceFiles
      videoWalkthrough
      kanbanProcessStageId
      southernImpressions
      jvFile
      budgetItems {
        name
        budget
        budgetItemProjectedStartDate
        budgetItemProjectedEndDate
        budgetItemActualStartDate
        budgetItemActualEndDate
        lines {
          name
          amount
        }
        files
      }
      subdivision
      customFields
      houseCanaryPropertyExplorerLink
      ownership
      apn
      linkedProperties
      lien
      mortgageBalance
      estimatedValue
      estimatedEquity
      skipTraceResults
      pitiData
      realeflowData
      assetManagement {
        ...assetManagement
      }
      offerToClose {
        ...offerToClose
      }
    }
  }
  ${AcquisitionFragments.analysis}
  ${AcquisitionFragments.analytics}
  ${AssumptionsFragments.assumptions}
  ${AssetManagementFragments.assetManagement}
  ${OfferToCloseFragments.offerToClose}
`

export const GET_COMPPROPERTIES = gql`
  query compProperties($propertyId: String!) {
    compProperties(propertyId: $propertyId) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      year
      estval
      initialListPrice
      daysOnMarket
      rehabLevel
      saleDate
      distance
      rentalPrice
      accommodates
      occupancyRate
      rentalURL
      rentalImage
      priceAdjustment
      potential
      rental
      propertyType
      addressHash
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      images {
        _id
        url
        url_small
      }
      createdBy {
        _id
        username
        email
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const COMP_ADDED = gql`
  subscription compAdded($propertyId: String!) {
    compAdded(propertyId: $propertyId) {
      _id
      address
      city
      state
      zip
      beds
      baths
      sqft
      year
      estval
      initialListPrice
      daysOnMarket
      rehabLevel
      saleDate
      distance
      rentalPrice
      accommodates
      occupancyRate
      rentalURL
      rentalImage
      priceAdjustment
      potential
      rental
      propertyType
      addressHash
      createdAt
      updatedAt
      location {
        latitude
        longitude
      }
      images {
        _id
        url
        url_small
      }
      createdBy {
        _id
        username
        email
        profile {
          firstname
          lastname
        }
      }
    }
  }
`

export const COMPS_LOADED = gql`
  subscription compsLoaded($propertyId: String!) {
    compsLoaded(propertyId: $propertyId)
  }
`

export const GET_SIGNED_REQUEST = gql`
  mutation getSignedRequest($fileInput: fileInput!) {
    getSignedRequest(fileInput: $fileInput) {
      signedRequest
      url
    }
  }
`

export const OFFER = gql`
  query offer($propertyId: ID!) {
    offer(propertyId: $propertyId) {
      buyer {
        name
        address
        city
        state
        zip
        note
        updatedBy
        updatedAt
      }
      seller {
        name
        address
        city
        state
        zip
        note
        updatedBy
        updatedAt
      }
      contract {
        dateContractReceived
        offerAmount
        bindingDate
        bindingAmount
        contractCloseDate
        files
        updatedBy
        updatedAt
      }
      earnestMoney {
        earnestMoneyDueDate
        earnestMoneyAmount
        holder
        earnestMoneyReceived
        buyerSignature
        sellerSignature
        files
        updatedBy
        updatedAt
      }
      homeInspection {
        dateReadyForInspection
        dateInspectionOrdered
        dateInspectionReceived
        dateDueDiligence
        files
        updatedBy
        updatedAt
      }
      appraisal {
        dateAppraisalOrdered
        dateAppraisalReceived
        appraisedValue
        files
        updatedBy
        updatedAt
      }
      titleWork {
        titleCompany
        titleCompanyContact
        titleCompanyPhone
        titleCompanyEmail
        titleWorkOrdered
        titleWorkCompleted
        titleOrderInvolvement
        borrowerType
        entity
        applicant
        borrowerFirstName
        borrowerLastName
        borrowerEmail
        borrowerPhone
        sellerType
        sellerEntity
        sellerApplicant
        sellerFirstName
        sellerLastName
        sellerEmail
        sellerPhone
        loanAmount
        purchasePrice
        files
        spruceFiles
        updatedBy
        updatedAt
      }
      closing {
        dateFinalWalkThrough
        closingDate
        dateClosingCompleted
        dateClearToFund
        attorneyInformation
        attorneyPhone
        attorneyEmail
        clearToClose
        files
        updatedBy
        updatedAt
      }
      createdAt
      createdBy {
        _id
        profile {
          firstname
          lastname
        }
      }
    }
  }
`
