export let propertyColumns = [
  {
    name: 'address',
    label: 'Full Street Address',
    alts: ['address', 'street address', 'st address'],
    group: 'Address',
    required: true,
  },
  {
    name: 'streetNumber',
    label: 'Street Number',
    alts: [
      'street number',
      'stn',
      'st#',
      'stnum',
      'streetnumber',
      'st number',
      'st #',
      'st num',
      'st. #',
      'st. number',
      'number',
      'num',
      'address - street number',
    ],
    group: 'Address',
  },
  {
    name: 'streetName',
    label: 'Street Name',
    alts: [
      'street name',
      'st name',
      'st nm',
      'st. name',
      'str',
      'street',
      'st.',
      'address - street name',
    ],
    group: 'Address',
    divider: true,
  },
  {
    name: 'county',
    label: 'County',
    alts: ['county'],
    group: 'Address',
  },
  {
    name: 'city',
    label: 'City',
    alts: ['city', 'city/location'],
    group: 'Address',
    required: true,
  },
  {
    name: 'state',
    label: 'State',
    alts: ['state', 'st', 'stateorprovince'],
    group: 'Address',
  },
  {
    name: 'zip',
    label: 'Zip',
    alts: ['zip', 'zip code', 'zipcode'],
    group: 'Address',
    required: true,
  },
  {
    name: 'beds',
    label: 'Beds',
    alts: [
      'beds',
      'bds',
      'bed',
      'br',
      '#br',
      'bedrooms',
      'bdrms',
      'bedstotal',
      'total br',
    ],
    group: 'Property Details',
    required: true,
  },
  {
    name: 'baths',
    label: 'Baths',
    alts: [
      'baths',
      'bathrooms',
      'bths',
      'fbths',
      'bath',
      'baf',
      'bathrooms (full)',
      'bath (full)',
      'baths: total',
      'baths full',
      'full bathrooms',
      'full baths',
      'bathstotal',
      'total bth',
    ],
    group: 'Property Details',
    required: true,
  },
  {
    name: 'halfBaths',
    label: 'Half Baths',
    alts: [
      'hbths',
      '1/2 baths',
      '1/2 bths',
      'half baths',
      'bah',
      'bathrooms (half)',
      'bath (half)',
      'baths half',
    ],
    group: 'Property Details',
  },
  {
    name: 'sqft',
    label: 'Square Feet',
    alts: [
      'square feet',
      'sqft',
      'sq feet',
      'sq ft',
      'agfinsf',
      'agsf',
      'apxsqft',
      'sf',
      'square footage',
      'heated area',
      'building sqft',
      'totalsqft',
      'sqftmainupper',
      'total sf',
    ],
    group: 'Property Details',
    required: true,
  },
  {
    name: 'year',
    label: 'Year Built',
    alts: [
      'year built',
      'year',
      'yr',
      'tax year built',
      'yr built',
      'yearbuilt',
    ],
    group: 'Property Details',
  },
  {
    label: 'Asking Price',
    name: 'listPrice',
    alts: [
      'price',
      'asking price',
      'lp',
      'list',
      'listp',
      'list p',
      'listprice',
      'listing price',
      'current price',
    ],
    group: 'Property Details',
    required: true,
  },
  {
    name: 'description',
    label: 'Property Description',
    alts: [
      'marketing remarks',
      'marketing text',
      'property description',
      'description',
      'marketing',
      'marketing description',
      'propertydescription',
    ],
    group: 'Property Details',
  },
  {
    label: 'Days on Market',
    name: 'daysOnMarket',
    alts: ['cdom', 'dom', 'days on market'],
    group: 'Property Details',
  },
  {
    label: 'MLS ID',
    name: 'MLSID',
    group: 'Property Details',
  },
  {
    label: 'After Repair Value',
    name: 'afterRepairValue',
    alts: ['after repair value', 'arv'],
    group: 'Financial Details',
  },
  {
    label: 'Monthly Rent',
    name: 'rent',
    alts: [
      'rent',
      'monthly rent',
      'estimated monthly rent',
      'rental price monthly',
    ],
    group: 'Financial Details',
  },
  {
    label: 'Rent Range Low',
    name: 'rentLow',
    alts: ['low rent'],
    group: 'Financial Details',
    affiliate: true,
  },
  {
    label: 'Rent Range High',
    name: 'rentHigh',
    alts: ['high rent'],
    group: 'Financial Details',
    affiliate: true,
  },
  {
    label: 'Purchase Price',
    name: 'offerPrice',
    alts: ['purchase price', 'offer price'],
    group: 'Financial Details',
  },
  {
    label: 'Rehab Cost',
    name: 'rehabCost',
    alts: ['est rehab cost'],
    group: 'Financial Details',
  },
  {
    label: 'HOA',
    name: 'HOA',
    alts: ['estimated hoa', 'annual hoa'],
    group: 'Financial Details',
  },
  {
    label: 'Property Taxes',
    name: 'propertyTaxes',
    alts: [
      'annual property taxes (BN)',
      'property taxes',
      'estimated taxes',
      'taxes',
      'tax',
      'ptax',
      'propertytaxes',
      'ptaxes',
      'tax amount',
      'annual taxes',
      'property tax',
      'annual tax',
      'taxes annual',
    ],
    group: 'Financial Details',
  },
  {
    label: 'Annual Property Insurance',
    name: 'propertyInsurance',
    alts: [
      'annual property insurance',
      'property insurance',
      'estimated property insurance',
      'est property insurance',
      'est insurance',
      'estimated insurance',
      'property insurance',
      'insurance',
      'p insurance',
      'p. insurance',
      'prop. insurance',
    ],
    group: 'Expense Assumptions',
  },
  {
    label: 'Property Mgmt Fee',
    name: 'propertyManagement',
    alts: [
      'ann. mgmt fee',
      'management fee',
      'estimated management',
      'estimated property management',
    ],
    group: 'Expense Assumptions',
  },
  {
    label: 'Annual Maintenance',
    name: 'maintenance',
    alts: ['estimated maintenance', 'estimated maintenance'],
    group: 'Expense Assumptions',
  },
  {
    label: 'Vacancy Rate',
    name: 'vacancy.0',
    alts: ['estimated vacancy'],
    group: 'Expense Assumptions',
  },
  {
    label: 'Monthly Utilities',
    name: 'utilities',
    group: 'Expense Assumptions',
  },
  {
    label: 'Monthly Reserves (capex)',
    name: 'monthlyReserves',
    group: 'Expense Assumptions',
  },
  {
    label: 'Other Annual Expenses',
    name: 'otherExpense',
    group: 'Expense Assumptions',
  },
  {
    label: 'Pay Down Accelerator',
    name: 'payDownAccelerator',
    group: 'Financing Assumptions',
  },
  {
    label: 'Down Payment',
    name: 'downPayment',
    alts: ['down payment'],
    group: 'Financing Assumptions',
  },
  {
    label: 'Interest Rate',
    name: 'rate',
    alts: ['interest'],
    group: 'Financing Assumptions',
  },
  {
    label: 'Financing terms',
    name: 'term',
    alts: ['term'],
    group: 'Financing Assumptions',
  },
  {
    label: 'Points',
    name: 'points',
    group: 'Financing Assumptions',
  },
  {
    label: 'All Cash Purchase',
    name: 'allCashPurchase',
    group: 'Financing Assumptions',
  },
  {
    label: 'Interest Only Loan',
    name: 'interestOnlyLoan',
    group: 'Financing Assumptions',
  },
  {
    label: 'Acquisition Commission',
    name: 'acquisitionCommission',
    group: 'Acquisition Assumptions',
  },
  {
    label: 'Acquisition Fee',
    name: 'acquisitionFee',
    group: 'Acquisition Assumptions',
  },
  {
    label: 'Closing Cost',
    name: 'closingCost',
    group: 'Acquisition Assumptions',
  },
  {
    label: 'Asset Mgmt Fee',
    name: 'assetManagementFee',
    group: 'Acquisition Assumptions',
  },
  {
    label: 'Financing Fee',
    name: 'financingFee',
    group: 'Acquisition Assumptions',
  },
  {
    label: 'Credit From Seller',
    name: 'creditFromSeller',
    group: 'Acquisition Assumptions',
  },
  {
    label: 'Owner Reserves',
    name: 'ownerReserves',
    group: 'Acquisition Assumptions',
  },
  {
    label: 'Sales Commission',
    name: 'salesCommission',
    group: 'Disposition Assumptions',
  },
  {
    label: 'Disposition Fee',
    name: 'dispositionFee',
    group: 'Disposition Assumptions',
  },

  {
    label: 'Seller Closing Cost',
    name: 'sellerClosingCost',
    group: 'Disposition Assumptions',
  },
  {
    label: 'Asking Price to Sell Ratio',
    name: 'listPriceToSalePriceRatio',
    group: 'Disposition Assumptions',
  },
  {
    label: 'Holding Period (months)',
    name: 'holdingPeriod',
    group: 'Disposition Assumptions',
  },
  {
    label: 'Rental Holding Period (years)',
    name: 'rentalHoldingPeriod',
    group: 'Disposition Assumptions',
  },
  {
    label: 'Home Price Appreciation',
    name: 'homePriceAppreciation',
    group: 'Disposition Assumptions',
  },
  {
    label: 'Rental Rate Increase',
    name: 'growthRateIncome',
    group: 'Disposition Assumptions',
  },
  {
    label: 'Expense Rate Increase',
    name: 'growthRateExpense',
    group: 'Disposition Assumptions',
  },
  {
    label: 'Parcel ID',
    name: 'parcelID',
    group: 'Affiliate',
    affiliate: true,
  },
  {
    label: 'Type of building',
    name: 'type',
    group: 'Affiliate',
    affiliate: true,
  },
  {
    label: 'Sewer',
    name: 'sewer',
    group: 'Affiliate',
    affiliate: true,
  },
  {
    label: 'Water',
    name: 'water',
    group: 'Affiliate',
    affiliate: true,
  },
]

export const DEFAULT_ESTIMATES = {
  // listPriceEstimate: {
  //   secondary: 'Estimated From Asking Price',
  //   propertyTaxes: '',
  // },
  house: {
    secondary: 'Provided by House',
    listPrice: '',
  },
  // housefolios: {
  //   secondary: 'Based on Approx. Assessed Value',
  //   propertyTaxes: '',
  // },
  // housefoliosCounty: {
  //   secondary: 'Estimate based on County',
  //   propertyTaxes: '',
  // },
  // housefoliosSqft: {
  //   secondary: 'Estimate based on Sqft',
  //   afterRepairValue: '',
  //   rent: '',
  //   listPrice: '',
  // },
  housefoliosAssumption: {
    secondary: 'Based on Assumptions',
    rehabCost: '',
    rehabCostBRRRR: '',
    rehabCostBOR: '',
    rehabCostFixFlip: '',
    rehabCostTurnkey: '',
    rehabCostSTR: '',
    rehabCostWholesale: '',
    rehabCostAstroFlip: '',
    rent: '',
    rentBOR: '',
    listPrice: '',
    propertyTaxes: '',
    offerPrice: '',
  },
  // housefoliosBed: {
  //   secondary: 'Estimate based on Bedrooms',
  //   afterRepairValue: '',
  //   rent: '',
  // },
  // housefoliosBedAndSqft: {
  //   secondary: 'Estimate based on Sqft & Beds',
  //   rent: '',
  // },
  smartAsset: {
    secondary: 'Provided by Smart Asset',
    propertyTaxes: '',
  },
  // airdna: {
  //   secondary: 'Provided by AirDNA',
  //   avgRate: '',
  //   occupancyRate: '',
  // },
  zillow: {
    secondary: 'Provided by Zillow',
    rent: '',
    rentBOR: '',
    afterRepairValue: '',
  },
  zillowSearch: {
    secondary: 'Search on Zillow',
    listPrice: 'Open Zillow',
    propertyTaxes: 'Open Zillow',
  },
  googleSearch: {
    secondary: 'Search on Google',
    listPrice: 'Open Google',
    propertyTaxes: 'Open Google',
  },
  //  housefoliosLow: {
  //    secondary: 'Low Estimate Provided by Housefolios',
  //    afterRepairValue: '',
  //  },
  //  housefoliosAverage: {
  //    secondary: 'Provided by Housefolios',
  //    afterRepairValue: '',
  //  },
  //  housefoliosHigh: {
  //    secondary: 'High Estimate Provided by Housefolios',
  //    afterRepairValue: '',
  //  },
  // houseCanary: {
  //   secondary: 'Provided by HouseCanary',
  //   propertyTaxes: '',
  // },
  // houseCanaryLow: {
  //   secondary: 'Low Estimate Provided by HouseCanary',
  //   afterRepairValue: '',
  //   rent: '',
  //   rentBOR: '',
  // },
  // houseCanaryAverage: {
  //   secondary: 'Provided by HouseCanary',
  //   afterRepairValue: '',
  //   rent: '',
  //   rentBOR: '',
  // },
  // houseCanaryHigh: {
  //   secondary: 'High Estimate Provided by HouseCanary',
  //   afterRepairValue: '',
  //   rent: '',
  //   rentBOR: '',
  // },
  reAPI: {
    secondary: 'Estimated AVM Provided by REAPI',
    afterRepairValue: '',
  },
  smartAssetSearch: {
    secondary: 'Search Smart Asset',
    //propertyTaxes: 'Open Smart Asset',
  },
  compSearch: {
    secondary: 'Use a comp value',
    afterRepairValue: 'Open Comp Select',
  },
  //  rentometer: {
  //    secondary: 'Provided By Rentometer',
  //    rent: '',
  //    rentBOR: '',
  //  },
  //  rentometerLow: {
  //    secondary: 'Low Estimate Provided By Rentometer',
  //    rent: '',
  //    rentBOR: '',
  //  },
  //  rentometerHigh: {
  //    secondary: 'High Estimate Provided By Rentometer',
  //    rent: '',
  //    rentBOR: '',
  //  },
  affiliate: {
    secondary: 'Provided by Affiliate',
    rehabCost: '',
    rehabCostBRRRR: '',
    rehabCostBOR: '',
    rehabCostFixFlip: '',
    rehabCostTurnkey: '',
    rehabCostSTR: '',
    rehabCostWholesale: '',
    rehabCostAstroFlip: '',
  },
}
