/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-01 15:08:58
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-26 12:28:37
 * @ Description: Wholesale Strategy Analysis page
 */

import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import Reactour from 'reactour'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CountUp from 'react-countup'
import filter from 'lodash/filter'
import map from 'lodash/map'
import maxBy from 'lodash/maxBy'
import meanBy from 'lodash/meanBy'
import minBy from 'lodash/minBy'
import round from 'lodash/round'
import sum from 'lodash/sum'
import isUndefined from 'lodash/isUndefined'
import difference from 'lodash/difference'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import slice from 'lodash/slice'
import includes from 'lodash/includes'
import get from 'lodash/get'
import concat from 'lodash/concat'
import compact from 'lodash/compact'
import max from 'lodash/max'
import uniq from 'lodash/uniq'
const Chart = lazy(() => import('react-apexcharts'))

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  Divider,
  FormGroup,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  LinearProgress,
  Tooltip,
  Typography,
  Drawer,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'

import { arrayMoveImmutable } from 'array-move'
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc'

import ContactAgent from '../../../Marketplace/ContactAgent'
import GaugeChart from 'react-gauge-chart'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { useMutation } from '@apollo/client'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { Close } from '@mui/icons-material'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { enqueueSnackbar } from 'notistack'
import { assumptionsOptions } from '@/housefolios-components/AssumptionsSettings/Accordion'
import { GET_CARDS } from '@/housefolios-components/Properties/queries'
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import { getStrategyName } from '@/utils/stratagies'
import { useLocation } from 'react-router-dom'
import {
  ContactInformation,
  getCashEntryFee,
  getCreativeEntryFee,
  getPitiTotal,
} from '@/utils/loanBalance'
import SubtoPITICash from '@/housefolios-components/Dialogs/SubtoPITI/Cash/'
import SubtoPITICreative from '@/housefolios-components/Dialogs/SubtoPITI/Creative/'
import CommentsDialog from '@/housefolios-components/Dialogs/Comments'

const pieColors = [
  '#bebebd',
  '#008ffb',
  '#ff6efb',
  '#69A251',
  '#38CDC3',
  '#e3e44f',
  '#feb019',
  '#45818E',
  '#d26767',
]

function CenterPageWholesale(props) {
  const {
    property,
    propertyInput,
    analysisInput,
    assumptionsInput,
    renderInputFields,
  } = props

  const [openWholesaleStrategyTour, setOpenWholesaleStrategyTour] =
    useState(false)
  const [openWholesaleTargetsTour, setOpenWholesaleTargetsTour] =
    useState(false)
  const [
    openWholesaleNetProfitFromSaleTour,
    setOpenWholesaleNetProfitFromSaleTour,
  ] = useState(false)
  const [
    openWholesaleWholesaleDetailsTour,
    setOpenWholesaleWholesaleDetailsTour,
  ] = useState(false)

  const WholesaleStrategyTour = ({
    isOpenWholesaleStrategyTour,
    closeWholesaleStrategyTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour-wholesale="strategy-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Want to purchase this property? Fill out the Make
                Offer form to take the next steps!
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenWholesaleStrategyTour}
        onRequestClose={closeWholesaleStrategyTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const WholesaleTargetsTour = ({
    isOpenWholesaleTargetsTour,
    closeWholesaleTargetsTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="targets-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The amount of money left over after subtracting the
                Purchase Price from the Resale Price
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="targets-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The amount of money left over for the End Buyer after
                subtracting Expenses from the ARV
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenWholesaleTargetsTour}
        onRequestClose={closeWholesaleTargetsTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const WholesaleNetProfitFromSaleTour = ({
    isOpenWholesaleNetProfitFromSaleTour,
    closeWholesaleNetProfitFromSaleTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="netProfitFromSale-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The selling price of the property
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="netProfitFromSale-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Monthly Expenses is a sum of all the monthly values
                from the all the fields in the Monthly Expenses card
                below
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="netProfitFromSale-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The Net Profit is the Resale Price minus the Total
                Expenses
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenWholesaleNetProfitFromSaleTour}
        onRequestClose={closeWholesaleNetProfitFromSaleTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const WholesaleWholesaleDetailsTour = ({
    isOpenWholesaleWholesaleDetailsTour,
    closeWholesaleWholesaleDetailsTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="wholesaleDetails-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The price that the seller is asking for this home.
                *Not necessarily the price that the investor will
                purchase the home for.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="wholesaleDetails-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The ARV is the value of a property after you have
                conducted repairs and are ready to sell
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="wholesaleDetails-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The price you will resell the property for once the
                rehab is completed.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="wholesaleDetails-step-4"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                An estimate for how much rehab is needed for the
                subject property.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="wholesaleDetails-step-5"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The targeted percentage profit the buyer expects.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="wholesaleDetails-step-6"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The estimated closing cost the end buyer will have for
                a wholesale property. Expressed as a dollar amount or
                percentage of ARV minus the rehab.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="wholesaleDetails-step-7"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The amount that you, the seller, expects to make on
                the wholesale property. Expressed as a dollar amount
                or percentage of ARV minus the rehab and closing
                costs. Expressed as a dollar amount or percentage of
                ARV minus the rehab and closing costs.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenWholesaleWholesaleDetailsTour}
        onRequestClose={closeWholesaleWholesaleDetailsTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  let results = useQuery(GET_CARDS, {
    variables: { view: 'Wholesale' },
  })
  let data = results.data || {}
  const cardsLoading = results.loading
  const cardsError = results.error
  let { cards = [] } = data

  const { activeOrg = {} } = props.session.me || {}
  const { acquisition = {}, assumptions = {} } = property
  const { analytics = DEFAULT_ANALYTICS, analysis = {} } = acquisition

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })

  //const [loader, setLoader] = useState(false)
  const [modal1, setModal1] = useState(null)
  const [dealSauceView, setDealSauceView] = useState('creative')
  const [openPITICashData, setOpenPITICashData] = useState(false)
  const [openPITICreativeData, setOpenPITICreativeData] =
    useState(false)

  const pitiTotal = getPitiTotal(property)
  const creativeEntryFee = getCreativeEntryFee(property)
  const cashEntryFee = getCashEntryFee(property)

  const toggle1 = (name) => {
    setModal1(name)
    //setLoader(false)
  }

  const toggle1Close = () => {
    setModal1(null)
    //setLoader(false)
  }

  const [maxOfferDescription, setMaxOfferDescription] =
    useState(false)

  const toggleMaxOffer = () => {
    setMaxOfferDescription(!maxOfferDescription)
  }

  const rehabDollar = analytics.wholesale.rehabDollar //assumptions.rehabTier * sum(property.sqft)
  const maxOfferPrice1 =
    analytics.wholesale.arvTotalWholesale - rehabDollar
  const buyerProfitTotal =
    (assumptions.belowBuyerProfit / 100) * maxOfferPrice1
  const maxOfferPrice2 = maxOfferPrice1 - buyerProfitTotal
  const closingCostWholesaleDollar =
    analytics.wholesale.closingCostWholesaleDollar //assumptions.closingCostWholesaleType === '%' ? (assumptions.closingCostWholesale / 100) * maxOfferPrice2 : assumptions.closingCostWholesale
  const maxOfferPrice = maxOfferPrice2 - closingCostWholesaleDollar
  const assignmentFeeDollar = analytics.wholesale.assignmentFeeDollar //assumptions.assignmentFeeType === '%' ? (assumptions.assignmentFee / 100) * maxOfferPrice3 : assumptions.assignmentFee
  //const maxOfferPrice = maxOfferPrice3 - assignmentFeeDollar
  // const maxOfferPrice = analysisInput.resellPrice - (assumptions.rehabTier * sum(property.sqft)) - (assumptions.buyerProfitType === '%' ? (assumptions.buyerProfit / 100) * analysis.resellPrice : assumptions.buyerProfit) - (assumptions.closingCostWholesaleType === '%' ? (assumptions.closingCostWholesale / 100) * analysis.resellPrice : assumptions.closingCostWholesale) - (assumptions.assignmentFeeType === '%' ? (assumptions.assignmentFee / 100) * analysis.resellPrice : assumptions.assignmentFee)

  const pepMaxOfferPrice = Math.max(
    (includes(
      assumptionsInput.strategySpecificFields,
      'afterRepairValue',
    )
      ? analysisInput.afterRepairValueWholesale || 0
      : analysisInput.afterRepairValue) *
      (assumptionsInput.percentageOfARVWholesale / 100) -
      analytics.wholesale.rehabDollar,
    0,
  )
  // - assignmentFeeDollar

  const effectiveOfferPrice =
    analysisInput.pepAdjustedPriceWholesale || pepMaxOfferPrice

  const pepEstProfit =
    analytics.wholesale.arvTotalWholesale -
    (analytics.wholesale.arvTotalWholesale * 0.15 +
      analytics.wholesale.rehabDollar +
      pepMaxOfferPrice)

  const dispoPrice = pepMaxOfferPrice + assignmentFeeDollar

  const pepEstFlipProfit =
    analytics.wholesale.arvTotalWholesale -
    (pepMaxOfferPrice +
      assignmentFeeDollar +
      analytics.wholesale.rehabDollar +
      analytics.wholesale.arvTotalWholesale * 0.15)

  const pepMaxEstProfit = max([
    // pepEstFlipProfit,
    0.1 * analytics.wholesale.arvTotalWholesale,
    25000,
  ])

  const pepBigPictureFields = filter(
    [
      {
        type: 'dollar',
        name: 'listPrice',
        label: 'Asking Price',
        value:
          analysisInput.listPrice === null
            ? ''
            : analysisInput.listPrice.toString(),
        xs: 12,
        lg: 6,
        definition: 'listPrice',
        // tooltipField: 'The price that the seller is asking for this home. *Not necessarily the price that the investor will purchase the home for.',
        // tooltipButtonText: 'Asking Price Options',
        adornmentNumber: 1,
      },
      {
        type: 'dollar',
        name: includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        )
          ? 'afterRepairValueWholesale'
          : 'afterRepairValue',
        label: 'After Repair Value',
        // tooltipButtonText: 'Show ARV data',
        value: includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        )
          ? analysisInput.afterRepairValueWholesale === null
            ? ''
            : analysisInput.afterRepairValueWholesale.toString()
          : analysisInput.afterRepairValue === null
            ? ''
            : analysisInput.afterRepairValue.toString(),
        //helper: currencyFormat.format(analytics.fixFlip.equity) + ' in equity',
        xs: 12,
        lg: 6,
        definition: 'afterRepairValue',
        definitionTarget: includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        )
          ? 'afterRepairValueWholesale'
          : 'afterRepairValue',
        // tooltipField: 'The ARV is the value of a property after you have conducted repairs and are ready to sell',
        dataTour: 'acquisitionExpenses-step-6',
        dataTourDatabase: 'acquisitionExpenses-step-7',
        adornmentNumber: 2,
      },
      {
        type: 'select',
        name: 'rehabWholesaleType',
        label: 'Rehab Type',
        value: assumptionsInput.rehabWholesaleType,
        xs: 12,
        options: assumptionsOptions(activeOrg)?.rehabOptions,
        assumptions: true,
        adornmentNumber: 3,
      },
      {
        type: 'dollar',
        name: 'rehabCostWholesale',
        label: 'Est Rehab',
        value: analysisInput.rehabCostWholesale,
        // tooltipButtonText: 'Show Estimated Rehab data',
        xs: 12,
        lg: 6,
        definition: 'rehabCostWholesale',
        // tooltipField: 'An expense that is incurred when restoring an entity to its normal or near-normal condition.',
        hide:
          assumptionsInput.rehabWholesaleType !== 'singleRehabValue',
        dataTour: 'wholesaleDetails-step-4',
        adornmentNumber: 4,
      },
      {
        type: 'budgetLines',
        name: 'detailedRehabCostWholesale',
        label: 'Rehab Costs',
        value: analysisInput.detailedRehabCostWholesale,
        xs: 12,
        options: [
          'Appliances',
          'Cabinets/Vanities',
          'Carpet',
          'Countertops/Sinks/Toilets',
          'Driveway/Decking/Patio',
          'Electrical',
          'Exterior',
          'Flooring',
          'Framing',
          'Insulation/Drywall',
          'Landscaping',
          'Masonry',
          'Mechanical',
          'Molding/Tile/Trim',
          'Paint',
          'Plans/Permits',
          'Plumbing',
          'Roof',
          'Siding/Gutters',
          'Site Prep',
          'Staging',
          'Windows/Doors',
          'Other',
        ],
        hide: assumptionsInput.rehabWholesaleType !== 'detailedRehab',
        dataTour: 'wholesaleDetails-step-4',
        adornmentNumber: 4,
      },
      {
        type: 'rehabCalc',
        name: 'rehabCalcTotalWholesale',
        label: 'Rehab Calculator',
        value: analysisInput.rehabCalcTotalWholesale,
        xs: 12,
        lg: 6,
        property: property,
        assumptionsInput: assumptionsInput,
        affiliate: props.affiliate,
        hide: assumptionsInput.rehabWholesaleType !== 'rehabCalc',
        dataTour: 'wholesaleDetails-step-4',
        adornmentNumber: 4,
      },
      {
        type: 'select',
        name: 'rehabTierWholesale',
        label: 'Rehab Tier',
        value: assumptionsInput.rehabTierWholesale,
        options: filter(
          activeOrg.rehabTiers,
          (option) =>
            isEmpty(option.showMarkets) ||
            includes(
              option.showMarkets,
              analysisInput.marketType ||
                props.affiliate?.defaultMarketType,
            ),
        ),
        optionName: 'rehabTiers',
        memberOptions: filter(
          activeOrg.memberRehabTiers,
          (option) =>
            isEmpty(option.showMarkets) ||
            includes(
              option.showMarkets,
              analysisInput.marketType ||
                props.affiliate?.defaultMarketType,
            ),
        ),
        dynamicMemberOptions: map(
          filter(
            activeOrg.dynamicMemberRehabTiers,
            (option) =>
              isEmpty(option.showMarkets) ||
              includes(
                option.showMarkets,
                analysisInput.marketType ||
                  props.affiliate?.defaultMarketType,
              ),
          ),
          (option) => {
            let breakPointIndex = 0
            const arv = includes(
              assumptionsInput.strategySpecificFields,
              'afterRepairValue',
            )
              ? analysisInput.afterRepairValueWholesale === null
                ? null
                : analysisInput.afterRepairValueWholesale
              : analysisInput.afterRepairValue === null
                ? null
                : analysisInput.afterRepairValue
            while (
              option?.breakpoints[breakPointIndex] < arv &&
              breakPointIndex < option?.breakpoints.length
            ) {
              breakPointIndex++
            }
            return {
              ...option,
              label: `$${option.values[breakPointIndex]}/Sqft`,
            }
          },
        ),
        removeOption: true,
        xs: 12,
        lg: 6,
        assumptions: true,
        helper: currencyFormat.format(
          analytics.wholesale.rehabTierValueWholesale,
        ),
        //tooltipField: 'An expense that is incurred when restoring an entity to its normal or near-normal condition.',
        hide:
          assumptionsInput.rehabWholesaleType !== 'rehabTier' &&
          assumptionsInput.rehabWholesaleType !==
            'flippingAmericaRehab',
        dataTour: 'wholesaleDetails-step-4',
        adornmentNumber: 4,
      },
      {
        name: 'marketType',
        value: !analysisInput.marketType
          ? props.affiliate?.defaultMarketType
          : analysisInput.marketType,
        label: 'Market Type',
        options: concat(
          [props.affiliate?.defaultMarketType],
          props.affiliate?.marketTypes,
        ),
        type: 'select',
        xs: 12,
        lg: 6,
        hide:
          isEmpty(props.affiliate?.marketTypes) ||
          (assumptionsInput.rehabWholesaleType !== 'rehabTier' &&
            assumptionsInput.rehabWholesaleType !==
              'flippingAmericaRehab'),
      },
      {
        type: 'dollar',
        name: 'rehabDecadeCostWholesale',
        label: 'Rehab Per Decade',
        value: assumptionsInput.rehabDecadeCostWholesale,
        xs: 12,
        lg: 6,
        assumptions: true,
        hide:
          assumptionsInput.rehabWholesaleType !==
          'flippingAmericaRehab',
      },
      {
        type: 'percentage',
        name: 'rehabContingencyWholesale',
        value: assumptionsInput.rehabContingencyWholesale,
        adornmentToggle:
          assumptionsInput.rehabContingencyWholesaleType,
        adornment: assumptionsInput.rehabContingencyWholesaleType,
        adornmentName: 'rehabContingencyWholesaleType',
        helper:
          assumptionsInput.rehabContingencyWholesaleType === '%'
            ? currencyFormat.format(
                (property.sqft *
                  assumptionsInput.rehabTierWholesale *
                  assumptionsInput.rehabContingencyWholesale) /
                  100,
              )
            : null,
        label: 'Rehab Contingency',
        xs: 12,
        lg: 6,
        assumptions: true,
        dropdown: true,
        // tooltipField: 'Percentage of calculated rehab cost added as a saftey margin',
        // dataTour: "wholesaleDetails-step-5"
        adornmentNumber: 5,
      },
      {
        type: 'dropdown',
        name: 'assignmentFee',
        value: assumptionsInput.assignmentFee,
        label: 'Assignment Fee',
        adornmentToggle: assumptionsInput.assignmentFeeType,
        adornment: assumptionsInput.assignmentFeeType,
        adornmentName: 'assignmentFeeType',
        xs: 12,
        lg: 6,
        assumptions: true,
        dropdown: true,
        // tooltipField: 'The amount that you, the seller, expects to make on the wholesale property. Expressed as a dollar amount or percentage of ARV minus the rehab and closing costs.',
        dataTour: 'wholesaleDetails-step-7',
        adornmentNumber: 6,
      },
      {
        type: 'percentage',
        name: 'percentageOfARVWholesale',
        label: 'Percent of ARV',
        // tooltipButtonText: 'Show Purchase Price data',
        helper: currencyFormat.format(
          (includes(
            assumptionsInput.strategySpecificFields,
            'afterRepairValue',
          )
            ? analysisInput.afterRepairValueWholesale === null
              ? 0
              : Number(analysisInput.afterRepairValueWholesale)
            : analysisInput.afterRepairValue === null
              ? 0
              : Number(analysisInput.afterRepairValue)) *
            (assumptionsInput.percentageOfARVWholesale / 100),
        ),
        value: assumptionsInput.percentageOfARVWholesale,
        xs: 12,
        lg: 6,
        assumptions: true,
        // tooltipField: 'Percentage of ARV used to calculate Offer Price',
        adornmentNumber: 7,
      },
      {
        type: 'dollar',
        name: 'pepAdjustedPriceWholesale',
        label: 'Adjusted Purchase Price',
        // tooltipButtonText: 'Show Purchase Price data',
        value:
          analysisInput.pepAdjustedPriceWholesale?.toString() || '',
        xs: 12,
        lg: 6,
        // tooltipField: 'The price that the seller is asking for this home. *Not necessarily the price that the investor will purchase the home for.',
        adornmentNumber: 8,
      },
    ],
    (field) => !field.hide,
  )

  const wholesaleFields = filter(
    [
      {
        type: 'dollar',
        name: 'offerPrice',
        label: 'Purchase Price',
        tooltipButtonText: 'Show Purchase Price data',
        value:
          analysisInput.offerPrice === null
            ? //? analysisInput.listPrice.toString()
              ''
            : analysisInput.offerPrice.toString(),
        helper:
          !analysisInput.offerPrice ||
          analysisInput.offerPrice === '0'
            ? '0% Discount to Price'
            : (
                (1 -
                  analysisInput.offerPrice /
                    analysisInput.listPrice) *
                100
              ).toFixed(0) + '% Discount to Price',
        xs: 12,
        lg: 6,
        definition: 'offerPrice',
        tooltipField:
          'The price that the seller is asking for this home. *Not necessarily the price that the investor will purchase the home for.',
        dataTour: 'wholesaleDetails-step-1',
      },
      {
        type: 'dollar',
        name: includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        )
          ? 'afterRepairValueWholesale'
          : 'afterRepairValue',
        label: 'After Repair Value',
        tooltipButtonText: 'Show ARV data',
        value: includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        )
          ? analysisInput.afterRepairValueWholesale === null
            ? ''
            : analysisInput.afterRepairValueWholesale.toString()
          : analysisInput.afterRepairValue === null
            ? ''
            : analysisInput.afterRepairValue.toString(),
        //helper: currencyFormat.format(analytics.wholesale.equity) + ' in equity',
        xs: 12,
        lg: 6,
        definition: 'afterRepairValue',
        definitionTarget: includes(
          assumptionsInput.strategySpecificFields,
          'afterRepairValue',
        )
          ? 'afterRepairValueWholesale'
          : 'afterRepairValue',
        tooltipField:
          'The ARV is the value of a property after you have conducted repairs and are ready to sell',
        dataTour: 'wholesaleDetails-step-2',
      },
      {
        type: 'dollar',
        name: 'resellPriceWholesale',
        label: 'Resale Price',
        value:
          analysisInput.resellPriceWholesale === null
            ? ''
            : analysisInput.resellPriceWholesale.toString(),
        xs: 12,
        lg: 6,
        tooltipField:
          'The price you will resell the property for once the rehab is completed.',
        dataTour: 'wholesaleDetails-step-3',
      },
      {
        type: 'select',
        name: 'rehabWholesaleType',
        label: 'Rehab Type',
        value: assumptionsInput.rehabWholesaleType,
        xs: 12,
        options: assumptionsOptions(activeOrg)?.rehabOptions,
        assumptions: true,
      },
      {
        type: 'dollar',
        name: 'rehabCostWholesale',
        label: 'Est Rehab',
        value: analysisInput.rehabCostWholesale,
        tooltipButtonText: 'Show Estimated Rehab data',
        xs: 12,
        lg: 6,
        definition: 'rehabCostWholesale',
        tooltipField:
          'An expense that is incurred when restoring an entity to its normal or near-normal condition.',
        hide:
          assumptionsInput.rehabWholesaleType !== 'singleRehabValue',
        dataTour: 'wholesaleDetails-step-4',
      },
      {
        type: 'budgetLines',
        name: 'detailedRehabCostWholesale',
        label: 'Rehab Costs',
        value: analysisInput.detailedRehabCostWholesale,
        xs: 12,
        options: [
          'Appliances',
          'Cabinets/Vanities',
          'Carpet',
          'Countertops/Sinks/Toilets',
          'Driveway/Decking/Patio',
          'Electrical',
          'Exterior',
          'Flooring',
          'Framing',
          'Insulation/Drywall',
          'Landscaping',
          'Masonry',
          'Mechanical',
          'Molding/Tile/Trim',
          'Paint',
          'Plans/Permits',
          'Plumbing',
          'Roof',
          'Siding/Gutters',
          'Site Prep',
          'Staging',
          'Windows/Doors',
          'Other',
        ],
        hide: assumptionsInput.rehabWholesaleType !== 'detailedRehab',
        dataTour: 'wholesaleDetails-step-4',
      },
      {
        type: 'rehabCalc',
        name: 'rehabCalcTotalWholesale',
        label: 'Rehab Calculator',
        value: analysisInput.rehabCalcTotalWholesale,
        xs: 12,
        lg: 6,
        property: property,
        assumptionsInput: assumptionsInput,
        affiliate: props.affiliate,
        hide: assumptionsInput.rehabWholesaleType !== 'rehabCalc',
        dataTour: 'wholesaleDetails-step-4',
      },
      {
        type: 'select',
        name: 'rehabTierWholesale',
        label: 'Rehab Tier',
        value: assumptionsInput.rehabTierWholesale,
        options: filter(
          activeOrg.rehabTiers,
          (option) =>
            isEmpty(option.showMarkets) ||
            includes(
              option.showMarkets,
              analysisInput.marketType ||
                props.affiliate?.defaultMarketType,
            ),
        ),
        optionName: 'rehabTiers',
        memberOptions: filter(
          activeOrg.memberRehabTiers,
          (option) =>
            isEmpty(option.showMarkets) ||
            includes(
              option.showMarkets,
              analysisInput.marketType ||
                props.affiliate?.defaultMarketType,
            ),
        ),
        dynamicMemberOptions: map(
          filter(
            activeOrg.dynamicMemberRehabTiers,
            (option) =>
              isEmpty(option.showMarkets) ||
              includes(
                option.showMarkets,
                analysisInput.marketType ||
                  props.affiliate?.defaultMarketType,
              ),
          ),
          (option) => {
            let breakPointIndex = 0
            const arv = includes(
              assumptionsInput.strategySpecificFields,
              'afterRepairValue',
            )
              ? analysisInput.afterRepairValueWholesale === null
                ? null
                : analysisInput.afterRepairValueWholesale
              : analysisInput.afterRepairValue === null
                ? null
                : analysisInput.afterRepairValue
            while (
              option?.breakpoints[breakPointIndex] < arv &&
              breakPointIndex < option?.breakpoints.length
            ) {
              breakPointIndex++
            }
            return {
              ...option,
              label: `$${option.values[breakPointIndex]}/Sqft`,
            }
          },
        ),
        removeOption: true,
        xs: 12,
        lg: 6,
        assumptions: true,
        helper: currencyFormat.format(
          analytics.wholesale.rehabTierValueWholesale,
        ),
        //tooltipField: 'An expense that is incurred when restoring an entity to its normal or near-normal condition.',
        hide:
          assumptionsInput.rehabWholesaleType !== 'rehabTier' &&
          assumptionsInput.rehabWholesaleType !==
            'flippingAmericaRehab',
        dataTour: 'wholesaleDetails-step-4',
      },
      {
        name: 'marketType',
        value: !analysisInput.marketType
          ? props.affiliate?.defaultMarketType
          : analysisInput.marketType,
        label: 'Market Type',
        options: concat(
          [props.affiliate?.defaultMarketType],
          props.affiliate?.marketTypes,
        ),
        type: 'select',
        xs: 12,
        lg: 6,
        hide:
          isEmpty(props.affiliate?.marketTypes) ||
          (assumptionsInput.rehabWholesaleType !== 'rehabTier' &&
            assumptionsInput.rehabWholesaleType !==
              'flippingAmericaRehab'),
      },
      {
        type: 'dollar',
        name: 'rehabDecadeCostWholesale',
        label: 'Rehab Per Decade',
        value: assumptionsInput.rehabDecadeCostWholesale,
        xs: 12,
        lg: 6,
        assumptions: true,
        hide:
          assumptionsInput.rehabWholesaleType !==
          'flippingAmericaRehab',
      },
      {
        type: 'percentage',
        name: 'belowBuyerProfit',
        value: assumptionsInput.belowBuyerProfit,
        label: 'Min Buyer Profit',
        xs: 12,
        lg: 6,
        assumptions: true,
        tooltipField:
          'The targeted percentage profit the buyer expects.',
        dataTour: 'wholesaleDetails-step-5',
      },
      {
        type: 'dropdown',
        name: 'closingCostWholesale',
        value: assumptionsInput.closingCostWholesale,
        label: 'Closing Cost',
        adornmentToggle: assumptionsInput.closingCostWholesaleType,
        adornment: assumptionsInput.closingCostWholesaleType,
        adornmentName: 'closingCostWholesaleType',
        xs: 12,
        lg: 6,
        assumptions: true,
        dropdown: true,
        tooltipField:
          'The estimated closing cost the end buyer will have for a wholesale property. Expressed as a dollar amount or percentage of ARV minus the rehab.',
        dataTour: 'wholesaleDetails-step-6',
      },
      {
        type: 'dropdown',
        name: 'assignmentFee',
        value: assumptionsInput.assignmentFee,
        label: 'Assignment Fee',
        adornmentToggle: assumptionsInput.assignmentFeeType,
        adornment: assumptionsInput.assignmentFeeType,
        adornmentName: 'assignmentFeeType',
        xs: 12,
        lg: 6,
        assumptions: true,
        dropdown: true,
        tooltipField:
          'The amount that you, the seller, expects to make on the wholesale property. Expressed as a dollar amount or percentage of ARV minus the rehab and closing costs.',
        dataTour: 'wholesaleDetails-step-7',
      },
    ],
    (field) => !field.hide,
  )

  const extraPepProfit = pepMaxOfferPrice - effectiveOfferPrice
  const dataValues = [
    analytics.wholesale.arvTotalWholesale * 0.1,
    25000,
  ] // Replace with your actual data

  // Determine the colors dynamically
  // const sortedIndices =
  //   dataValues[0] > dataValues[1] ? [0, 1] : [1, 0]
  // const dynamicColors = sortedIndices.map((index) =>
  //   index === 0 ? '#1BC943' : '#AAEA16',
  // )

  const bigPepChartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false, // Hides the menu
      },
    },
    plotOptions: {
      bar: {
        // distributed: true, // This ensures each bar gets a different color
      },
    },
    legend: {
      show: true, // Hides the legend
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#1BC943', extraPepProfit > 0 ? '#AAEA16' : '#F83244'],
    // colors: dynamicColors,
    // colors: ['#008FFB', '#00E396', '#FEB019'], // Applies per data point when `distributed: true`
    xaxis: {
      // categories: ['Est Profit', '10% of ARV', '$25,000'],
      categories: ['10% of ARV', '$25,000'],
      labels: {
        show: true, // Ensure it's enabled only once
        // style: { colors: ['#008FFB', '#00E396', '#FEB019'] },
      },
    },
    yaxis: {
      labels: {
        formatter: currencyFormat.format,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<span>' +
          // ['Est Profit', '10% of ARV', '$25,000'][dataPointIndex] +
          ['10% of ARV', '$25,000'][dataPointIndex] +
          ': ' +
          currencyFormat.format(series[seriesIndex][dataPointIndex]) +
          '</span>' +
          '</div>'
        )
      },
    },
  }

  const bigPepChartSeries = [
    {
      name: 'Mimimum target profit',
      data: dataValues,
    },
    {
      name: 'Profit from adjusted price',
      data: [extraPepProfit, extraPepProfit],
    },
  ]

  const wholesaleChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: [
      'Rehab Amount',
      'Buyer Profit',
      'Closing Cost',
      'Assignment Fee',
    ],
  }
  const wholesaleChartSeries = [
    rehabDollar,
    buyerProfitTotal,
    closingCostWholesaleDollar,
    assignmentFeeDollar || 0,
  ]

  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ['#1CC943', '#F83244', '#04A3E3'],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<span>' +
          //[w.globals.labels][dataPointIndex] +
          ['Resale Price', 'Expenses', 'Net Profit'][dataPointIndex] +
          ': ' +
          currencyFormat.format(series[seriesIndex][dataPointIndex]) +
          '</span>' +
          '</div>'
        )
      },
    },
    xaxis: {
      categories: ['Res...', 'Exp...', 'Net...'],
      labels: {
        rotate: -30,
        rotateAlways: true,
        offsetY: -10,
        formatter: currencyFormat.format,
      },
    },
  }
  const chartSeries = [
    {
      data: [
        round(analysis.resellPriceWholesale),
        round(analysis.offerPrice || analysis.listPrice),
        round(analytics.wholesale.saleProceedsWholesale),
      ],
      // colors: [
      //   ranges: [
      //     {
      //       from: 100,
      //       to: 0,
      //       color: '#f83145',
      //     },
      //   ],
      // ],
    },
  ]

  const calculationDialogs = [
    {
      value: pepMaxOfferPrice,
      label: 'Property Edge Pro - Max Offer Price',
      description: 'Highest Recommended Offer Price',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                After Repair Value
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  analytics.wholesale.arvTotalWholesale,
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                *Percentage of ARV
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                *
                {percentageFormat.format(
                  assumptions.percentageOfARVWholesale / 100,
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Suggested Offer Price
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  (analytics.wholesale.arvTotalWholesale *
                    assumptions.percentageOfARVWholesale) /
                    100,
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Rehab Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -{currencyFormat.format(round(rehabDollar, 0))}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -10% of ARV
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -
                {currencyFormat.format(
                  analytics.wholesale.arvTotalWholesale * 0.1,
                )}
              </Typography>
            </Grid>
          </Grid> */}
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Offer Price
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(pepMaxOfferPrice)}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
  ]

  const pitiFields = [
    {
      type: 'dollar',
      label: 'Principal',
      name: 'pitiData.principal',
      value: propertyInput?.pitiData.principal,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'dollar',
      label: 'Interest',
      name: 'pitiData.interest',
      value: propertyInput?.pitiData.interest,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'dollar',
      label: 'Taxes',
      name: 'pitiData.taxes',
      value: propertyInput?.pitiData.taxes,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'dollar',
      label: 'Insurance',
      name: 'pitiData.insurance',
      value: propertyInput?.pitiData.insurance,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'text',
      label: 'Loan type',
      name: 'pitiData.loanType',
      value: propertyInput?.pitiData.loanType,
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      type: 'datePicker',
      label: 'Origination Date',
      name: 'pitiData.originDate',
      value: new Date(propertyInput?.pitiData.originDate),
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      name: 'pitiData.mortgageStatement',
      label: 'Mortgage statement',
      type: 'file',
      value: propertyInput?.pitiData.mortgageStatement,
      uploadFile: props.uploadFileProperty(
        'pitiData.mortgageStatement',
      ),
      // deleteFile: props.deleteFileProperty(),
      buttonText: 'Upload Mortgage statement',
      xs: 12,
      lg: 6,
      details: true,
    },
    {
      name: 'pitiData.insuranceStatement',
      label: 'Insurance statement',
      type: 'file',
      value: propertyInput?.pitiData.insuranceStatement,
      uploadFile: props.uploadFileProperty(
        'pitiData.insuranceStatement',
      ),
      // deleteFile: props.deleteFileProperty(),
      buttonText: 'Upload Insurance statement',
      xs: 12,
      lg: 6,
      details: true,
    },
  ]

  const strategySummary = {
    name: 'Wholesale',
    percentage:
      (analytics.wholesale.onTargetWholesale +
        analytics.wholesale.aboveTargetWholesale) /
      (analytics.wholesale.belowTargetWholesale +
        analytics.wholesale.onTargetWholesale +
        analytics.wholesale.aboveTargetWholesale),
    analytics: [
      {
        value: analytics.wholesale.saleProceedsWholesale || '-',
        arrow: analytics.wholesale.saleProceedsWholesaleArrow || 0,
        label: 'Net Profit',
        type: 'currency',
        target: assumptions.belowWholesaleSaleProceeds,
        targetLabel: 'Min Net Profit Target',
        targetName: 'belowWholesaleSaleProceeds',
        tourStep: 'targets-step-1',
        min: 0,
        max: 35000,
        minLabel: '$ 0',
        maxLabel: '$ 35k',
        step: 1000,
        valueLabelFormat: (x) =>
          x >= 1000 ? `${x / 1000}k` : `${x}`,
        description:
          'The amount of money left over after subtracting the Purchase Price from the Resale Price',
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Resale Price
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analysis.resellPriceWholesale, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Purchase Price
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analysis.offerPrice || analysis.listPrice,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Profit
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(
                      analytics.wholesale.saleProceedsWholesale,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
      {
        value: analytics.wholesale.buyerProfitTotal || '-',
        arrow: analytics.wholesale.buyerProfitArrow || 0,
        label: 'Buyer Profit',
        type: 'currency',
        target: buyerProfitTotal,
        targetLabel: 'Min Buyer Profit Target',
        targetName: 'belowBuyerProfit',
        tourStep: 'targets-step-2',
        min: 0,
        max: 30,
        description:
          'The amount of money left over for the End Buyer after subtracting Expenses from the ARV',
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  After Repair Value:
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    analytics.wholesale.arvTotalWholesale,
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Rehab Cost:
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -{currencyFormat.format(rehabDollar)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Resell Price:
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analysis.resellPriceWholesale, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Closing Cost:
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -{currencyFormat.format(closingCostWholesaleDollar)}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Buyer Profit
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.wholesale.buyerProfitTotal, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            {/* 
            //SPECIFIC COMMENT: ASSIGNMENT FEE IS INCLUDED IN THE RESELL PRICE
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>Assignment Fee:</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>{currencyFormat.format(assignmentFeeDollar)}</Typography>
              </Grid>
            </Grid> */}
          </>
        ),
      },
    ],
  }

  const compData = [
    {
      label: 'Avg. Adjusted Value',
      value: isEmpty(props.compValues)
        ? 0
        : meanBy(
            filter(props.compValues, 'propertyPrice'),
            'propertyPrice',
          ),
    },
    {
      range: true,
      label: 'Adjusted Range',
      value1: isEmpty(props.compValues)
        ? 0
        : minBy(props.compValues, 'propertyPrice').propertyPrice /
          1000,
      value2: isEmpty(props.compValues)
        ? 0
        : maxBy(props.compValues, 'propertyPrice').propertyPrice /
          1000,
    },
    {
      label: 'Avg Adjusted Price/sqft',
      value: isEmpty(props.compValues)
        ? 0
        : meanBy(
            filter(props.compValues, 'propertyPerSqft'),
            'propertyPerSqft',
          ),
    },
    {
      label: 'Lowest 3 comp avg',
      value: props.lowestAverage.afterRepairValue,
    },
  ]

  const wholesaleCardsToOrder = [
    {
      name: 'bigPicture',
      hide: !activeOrg.isRealeflow,
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Big Picture</b>
            </div>
            {/* <div>
              <Tooltip title="Projected fund totals an investor will need to produce in order to acquire the property">
                <Button onClick={() => setOpenFixFlipFinancingTour(true)} className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div> */}
          </div>
          <Divider />
          <CardContent>
            {/* <p className="mb-3 text-black-80 text-center" data-tour="financing-step-3">
              Based on your <b>{currencyFormat.format(analytics.fixFlip.allInCostFixFlip)}</b> <Tooltip title='The total amount of money this deal costs, including rehab, closing costs, points, etc.'><span className="text-first" onClick={() => toggle1('All-in Cost')} style={{ textDecoration: 'underline' }}>all-in cost</span></Tooltip>, you'll need to pay <b>{currencyFormat.format(analytics.fixFlip.totalOutOfPocketFixFlip)}</b> <Tooltip title='Total amount that the buyer has to cover with their own money.'><span className="text-first" onClick={() => toggle1('Total Out-of-Pocket')} style={{ textDecoration: 'underline' }}>out-of-pocket</span></Tooltip> to cover the downpayment, closing costs, and rehab for this property.
            </p> */}
            <Grid container>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  // alignItems="center"
                  spacing={1}
                >
                  {map(pepBigPictureFields, (section) =>
                    renderInputFields(section),
                  )}
                </Grid>
                {/* {!props.shared && (
                  <div className="px-4 pb-4 pt-2">
                    <ContactAgent
                      property={property}
                      propertyPage={true}
                      canDrag={props.canDrag}
                      defaultOffer={effectiveOfferPrice}
                    />
                  </div>
                )} */}
              </Grid>
            </Grid>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid
                container
                className="d-flex bg-white justify-content-between py-3"
                onClick={() =>
                  toggle1('Property Edge Pro - Max Offer Price')
                }
              >
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center py-3"
                >
                  <div data-tour="financing-step-1">
                    <div className="text-dark pb-1">
                      Suggested Offer Price
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              pepMaxOfferPrice > 0
                                ? pepMaxOfferPrice
                                : 0
                            }
                            duration={1}
                            separator=","
                            decimals={0}
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center  py-3"
                >
                  <div data-tour="financing-step-2">
                    <div className="text-dark pb-1">
                      Strength of Offer
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          {analysis.listPrice ? (
                            <CountUp
                              start={0}
                              end={round(
                                (effectiveOfferPrice /
                                  analysis.listPrice || 0) * 100,
                                0,
                              )}
                              duration={1}
                              // delay={2}
                              separator=","
                              decimals={0}
                              suffix="%"
                            />
                          ) : (
                            <Typography>-</Typography>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div data-tour="financing-step-2">
                    <div className="text-dark pb-1">Dispo Price</div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          {analysis.listPrice ? (
                            <CountUp
                              start={0}
                              end={dispoPrice}
                              duration={1}
                              separator=","
                              decimals={0}
                              prefix="$"
                            />
                          ) : (
                            <Typography>-</Typography>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div data-tour="financing-step-1">
                    <div className="text-dark pb-1">
                      Estimated Flip Profit
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={pepMaxEstProfit + extraPepProfit}
                            duration={1}
                            separator=","
                            decimals={0}
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'bigPictureSummary',
      hide: !activeOrg.isRealeflow,
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <b>Big Picture Summary</b>
            </div>
            {/* <div>
              <Tooltip title="Quick glance determination seen on the main page">
                <Button onClick={() => setOpenFixFlipStrategyTour(true)} className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div> */}
          </div>
          <Divider />
          <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
            <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-3" />
            <span
              data-tour-wholesale="gauge-step-1"
              className="d-flex flex-column flex-grow-1 justify-content-center px-4 pt-4 pb-3"
            >
              <div
                className="mx-auto overflow-hidden"
                style={{ paddingBottom: 20 }}
              >
                <GaugeChart
                  id="chartsGauges3B"
                  nrOfLevels={6}
                  colors={['#f83245', '#f4772e', '#1bc943']}
                  arcWidth={0.4}
                  hideText
                  percent={
                    effectiveOfferPrice / analysis.listPrice > 0.9
                      ? 1
                      : effectiveOfferPrice / analysis.listPrice < 0.7
                        ? 0
                        : (effectiveOfferPrice / analysis.listPrice -
                            0.7) *
                            ((5 * 2) / 3) +
                          1 / 6
                  }
                />
              </div>
              <p
                className="mb-3 text-black-80 text-center"
                style={{ paddingLeft: '10px', paddingRight: '10px' }}
              >
                Your current Strength of Offer is{' '}
                {analysis.listPrice
                  ? `${round(
                      (effectiveOfferPrice / analysis.listPrice ||
                        0) * 100,
                      0,
                    )}
                  %${' '}`
                  : '-'}
              </p>
            </span>
            <span
              data-tour-wholesale="gauge-step-2"
              className="d-flex flex-column flex-grow-1 justify-content-center px-4 pt-4 pb-3"
            >
              <div
                className="mx-auto overflow-hidden"
                style={{ paddingBottom: 20, width: '100%' }}
              >
                <Suspense fallback={<div>Loading Chart...</div>}>
                  <Chart
                    options={bigPepChartOptions}
                    series={bigPepChartSeries}
                    type="bar"
                  />
                </Suspense>
              </div>
              <p
                className="mb-3 text-black-80 text-center"
                style={{ paddingLeft: '10px', paddingRight: '10px' }}
              >
                {/* Between your Net Profit and 10% of the ARV,{' '}
                {pepEstFlipProfit >
                0.1 * analytics.wholesale.arvTotalWholesale
                  ? 'Net Profit'
                  : '10% of ARV'}{' '}
                is higher with a total of{' '}
                {currencyFormat.format(
                  max([
                    pepEstFlipProfit,
                    0.1 * analytics.wholesale.arvTotalWholesale,
                  ]),
                )}{' '} */}
                Check to see that the total profit is at least $25,000
                or 10% of ARV, whichever is higher.
              </p>
            </span>
            {!props.shared && (
              <div className="px-4 pb-4 pt-2">
                <ContactAgent
                  property={property}
                  propertyPage={true}
                  canDrag={props.canDrag}
                  defaultOffer={effectiveOfferPrice}
                />
              </div>
            )}
          </div>
        </Card>
      ),
    },
    {
      name: 'strategy',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Strategy
              </small>
              <b>
                {getStrategyName(
                  'Wholesale',
                  props?.session?.me?.activeOrg?.member,
                )}
              </b>
            </div>
            <div>
              <Tooltip title="Quick glance determination seen on the main page">
                <Button
                  onClick={() => setOpenWholesaleStrategyTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
            <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-3" />
            <div className="mx-auto" style={{ paddingBottom: 20 }}>
              <GaugeChart
                id="chartsGauges3B"
                nrOfLevels={6}
                colors={['#f83245', '#f4772e', '#1bc943']}
                arcWidth={0.4}
                hideText
                percent={strategySummary.percentage}
              />
            </div>
            <p
              className="mb-3 text-black-80 text-center"
              style={{ paddingLeft: '10px', paddingRight: '10px' }}
            >
              According to your inputs, a {strategySummary.name}{' '}
              strategy meets{' '}
              {strategySummary.percentage < 0.33 && (
                <b style={{ color: '#f83245' }}>
                  {round(strategySummary.percentage * 100)}%
                </b>
              )}
              {strategySummary.percentage > 0.33 &&
                strategySummary.percentage < 0.66 && (
                  <b style={{ color: '#f4772e' }}>
                    {round(strategySummary.percentage * 100)}%
                  </b>
                )}
              {strategySummary.percentage > 0.66 && (
                <b style={{ color: '#1bc943' }}>
                  {round(strategySummary.percentage * 100)}%
                </b>
              )}{' '}
              of your targets
            </p>
            {!props.shared &&
              activeOrg.member !== 'gorillacapital' &&
              activeOrg.member !== 'paulmccomas' && (
                <div className="px-4 pb-4 pt-2">
                  <ContactAgent
                    property={property}
                    propertyPage={true}
                    canDrag={props.canDrag}
                  />
                </div>
              )}
            {activeOrg.member === 'paulmccomas' && (
              <div className="px-4 pb-4 pt-2">
                {property.jv ? (
                  <Card className="card-box">
                    <CardContent className="px-4 py-3">
                      <div className="pb-3 d-flex justify-content-between">
                        Property Submission Status
                      </div>
                      <div className="d-flex align-items-center justify-content-start">
                        <span
                          style={{
                            color: 'white',
                            backgroundColor: '#368CFF',
                            paddingLeft: 5,
                            paddingRight: 5,
                          }}
                        >
                          {property.jvStatus}
                        </span>
                      </div>
                    </CardContent>
                  </Card>
                ) : property.lender ? (
                  <Card className="card-box">
                    <CardContent className="px-4 py-3">
                      <div className="pb-3 d-flex justify-content-between">
                        Property Submission Status
                      </div>
                      <div className="d-flex align-items-center justify-content-start">
                        <span
                          style={{
                            color: 'white',
                            backgroundColor: '#368CFF',
                            paddingLeft: 5,
                            paddingRight: 5,
                          }}
                        >
                          {property.lenderStatus}
                        </span>
                      </div>
                    </CardContent>
                  </Card>
                ) : (
                  <>
                    <Button
                      fullWidth
                      className="btn-success"
                      onClick={
                        !includes(
                          props.session.me.subscriptionPlan,
                          'growth',
                        ) &&
                        !includes(
                          props.session.me.subscriptionPlan,
                          'pro',
                        )
                          ? () => props.toggleLimit('paul')
                          : get(
                                activeOrg,
                                'subscriptions.0.status',
                              ) === 'trialing'
                            ? () => props.setOpenEndTrial(true)
                            : (analytics.wholesale.onTargetWholesale +
                                  analytics.wholesale
                                    .aboveTargetWholesale) /
                                  (analytics.wholesale
                                    .belowTargetWholesale +
                                    analytics.wholesale
                                      .onTargetWholesale +
                                    analytics.wholesale
                                      .aboveTargetWholesale) <
                                  0.66 || property.images.length < 5
                              ? () =>
                                  props.setOpenMemberDisclaimer(true)
                              : () => props.setOpenMember(true)
                      }
                    >
                      {/* <span className="btn-wrapper--icon">
                        <img src="/images/PaulMcComasHeadShot.png" alt="paul" height={50} />
                      </span> */}
                      <span
                        className="btn-wrapper--label"
                        style={{ fontSize: 16 }}
                      >
                        Submit to Housefolios
                      </span>
                    </Button>
                    <Card
                      className="card-box"
                      style={{ borderRadius: 0 }}
                    >
                      <CardContent className="px-4 py-3">
                        <div className="pb-3 d-flex justify-content-between">
                          JV Requirements
                        </div>
                        <div className="d-flex align-items-center justify-content-start">
                          <FontAwesomeIcon
                            icon={[
                              'far',
                              property.images.length < 5
                                ? 'xmark'
                                : 'check',
                            ]}
                            className="text-first font-size-xxl"
                          />
                          <div className="mx-4">
                            Upload at least 5 Images
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-start">
                          <FontAwesomeIcon
                            icon={[
                              'far',
                              (analytics.wholesale.onTargetWholesale +
                                analytics.wholesale
                                  .aboveTargetWholesale) /
                                (analytics.wholesale
                                  .belowTargetWholesale +
                                  analytics.wholesale
                                    .onTargetWholesale +
                                  analytics.wholesale
                                    .aboveTargetWholesale) <
                              0.66
                                ? 'xmark'
                                : 'check',
                            ]}
                            className="text-first font-size-xxl"
                          />
                          <div className="mx-4">Targets Met</div>
                        </div>
                      </CardContent>
                    </Card>
                  </>
                )}
              </div>
            )}
          </div>
        </Card>
      ),
    },
    {
      name: 'targets',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Does it meet your
              </small>
              <b>Targets</b>
            </div>
            <div>
              <Tooltip title="Does this property meet your specified targets?">
                <Button
                  onClick={() => setOpenWholesaleTargetsTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-around"
            onClick={toggleMaxOffer}
            className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
          >
            <Grid item className="gridItem2">
              <Typography variant="h3">
                {maxOfferPrice > 0
                  ? currencyFormat.format(maxOfferPrice)
                  : '-'}
              </Typography>
            </Grid>
            <Grid
              item
              className="gridItem2"
              style={{ textAlign: 'center' }}
            >
              <Typography style={{ color: 'grey' }}>
                Max offer based on targets
              </Typography>
            </Grid>
          </Grid>
          <Dialog
            open={maxOfferDescription}
            onClose={toggleMaxOffer}
            maxWidth="lg"
            scroll="body"
            classes={{
              paper:
                'modal-content rounded border-0 bg-white p-3 p-xl-0',
            }}
          >
            {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
            <Grid container spacing={0} direction="row">
              <Grid item sm={5}>
                <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                  <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                    <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                      <div className="text-white mt-3">
                        <Typography
                          style={{ fontSize: 30, fontWeight: 600 }}
                        >
                          Relevant Data
                        </Typography>
                      </div>
                      <>
                        {/* <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>Purchase Price:</Typography>
                         </Grid>
                         <Grid item>
                         <Typography style={{ color: '#9198a8' }}>{currencyFormat.format(analysisInput.offerPrice ? analysisInput.offerPrice : analysisInput.listPrice)}</Typography>
                         </Grid>
                       </Grid>
                       <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>Resale Price:</Typography>
                         </Grid>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>{currencyFormat.format(analysisInput.resellPriceWholesale)}</Typography>
                         </Grid>
                       </Grid> */}
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              After Repair Value:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.wholesale.arvTotalWholesale,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              -Rehab Cost
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              -{currencyFormat.format(rehabDollar)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              -Buyer Profit
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              -
                              {currencyFormat.format(
                                buyerProfitTotal,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              -Closing Cost:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              -
                              {currencyFormat.format(
                                closingCostWholesaleDollar,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        {/* <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>-Assignment Fee:</Typography>
                         </Grid>
                         <Grid item>
                           <Typography style={{ color: '#9198a8' }}>-{currencyFormat.format(assignmentFeeDollar)}</Typography>
                         </Grid>
                       </Grid> */}
                        <Divider
                          style={{ backgroundColor: '#9198a8' }}
                        />
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: 'white' }}>
                              Max Offer
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: 'white' }}>
                              {currencyFormat.format(
                                round(maxOfferPrice, 0),
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={7}>
                <div className="hero-wrapper h-100 br-xl-right-0">
                  <Card className="flex-grow-1 w-100">
                    <CardHeader title="Max Offer" />
                    <Divider />
                    <CardContent>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ marginBottom: 25 }}
                        >
                          <Card
                            style={{
                              backgroundColor: '#eff2f5',
                              borderRadius: 0,
                            }}
                          >
                            <CardContent>
                              <Typography>
                                Max Offer is the highest purchase
                                price that still meets all of your
                                strategy's targets. The Max Offer will
                                show a dash if the calculation is less
                                than zero or no purchase price can
                                meet all targets.
                              </Typography>
                              <Typography>
                                The Max Offer Price is calculated by
                                determining the the highest purchase
                                price that will meet each specific
                                target then taking the lowest value
                                from them. This means that any value
                                that affects a target besides purchase
                                price can affect the Max Offer Price
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={toggleMaxOffer}
                          >
                            Close
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </div>
              </Grid>
            </Grid>
            {/* </BlockUi> */}
          </Dialog>
          <Divider />
          <List component="div" className="list-group-flush">
            {map(strategySummary.analytics, (section) => (
              <>
                <ListItem
                  component="a"
                  button
                  data-tour={section.tourStep}
                  href={null}
                  disableRipple
                  onClick={() => toggle1(section.label)}
                  className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                >
                  <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                    {!isUndefined(section.arrow) && (
                      <>
                        {section.arrow ? (
                          section.arrow === 2 ? (
                            <Avatar
                              style={{
                                backgroundColor: '#2296F3',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  color: 'white',
                                }}
                              />
                            </Avatar>
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: '#46de64',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  color: 'white',
                                }}
                              />
                            </Avatar>
                          )
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: '#F05248',
                            }}
                          >
                            <ArrowDownwardIcon
                              style={{
                                color: 'white',
                              }}
                            />
                          </Avatar>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex-fill">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        className="font-weight-bold text-black"
                      >
                        {section.label}
                      </Grid>
                      <Grid item className="ml-auto">
                        <b>
                          {section.value !== '-'
                            ? section.type === 'currency'
                              ? currencyFormat.format(section.value)
                              : section.value > 10
                                ? '+1000%'
                                : percentageFormat.format(
                                    section.value,
                                  )
                            : section.value}
                        </b>
                      </Grid>
                    </Grid>
                    <LinearProgress
                      variant="determinate"
                      className={
                        section.arrow === 0
                          ? 'progress-xs progress-animated-alt my-2 progress-bar-danger'
                          : 'progress-xs progress-animated-alt my-2 progress-bar-success'
                      }
                      //value={!section.target ? 0 : section.arrow ? 100 : section.type === 'currency' ? section.value / section.target * 100 : section.value / section.target * 10000}
                      value={
                        section.arrow
                          ? 100
                          : section.type === 'currency'
                            ? (section.value / section.target) * 100
                            : section.label === 'All-In Cost to ARV'
                              ? 200 -
                                (section.value / section.target) *
                                  10000
                              : (section.value / section.target) *
                                10000
                      }
                    />
                    <div className="d-flex justify-content-between font-size-xs">
                      {!isUndefined(section.arrow) && (
                        <div
                          className={
                            section.arrow === 0
                              ? 'text-danger'
                              : 'text-success'
                          }
                        >
                          {section.arrow === 0
                            ? 'Below target'
                            : section.arrow === 1
                              ? 'Target met'
                              : 'Above target'}
                        </div>
                      )}
                      <div className="opacity-5">
                        Target:{' '}
                        {section.type === 'currency'
                          ? currencyFormat.format(section.target)
                          : section.target + '%'}
                      </div>
                    </div>
                  </div>
                </ListItem>
                <Dialog
                  open={modal1 === section.label}
                  onClose={toggle1Close}
                  maxWidth="lg"
                  scroll="body"
                  classes={{
                    paper:
                      'modal-content rounded border-0 bg-white p-3 p-xl-0',
                  }}
                >
                  {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
                  <Grid container spacing={0} direction="row">
                    <Grid item sm={5}>
                      <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                          <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                            <div className="text-white mt-3">
                              <Typography
                                style={{
                                  fontSize: 30,
                                  fontWeight: 600,
                                }}
                              >
                                Calculation
                              </Typography>
                            </div>
                            {section.calculation}
                            <div className="text-white mt-3">
                              <Typography>
                                * Calculation may be a few dollars off
                                due to rounding
                              </Typography>
                              {section.label === 'Buyer Profit' && (
                                <Typography>
                                  * Resell price includes the
                                  Assignment Fee
                                </Typography>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={7}>
                      <div className="hero-wrapper h-100 br-xl-right-0">
                        <Card className="flex-grow-1 w-100">
                          <CardHeader title={section.label} />
                          <Divider />
                          <CardContent>
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Grid
                                item
                                xs={12}
                                style={{ marginBottom: 25 }}
                              >
                                <Card
                                  style={{
                                    backgroundColor: '#eff2f5',
                                    borderRadius: 0,
                                  }}
                                >
                                  <CardContent>
                                    <Typography>
                                      {section.description}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                              {renderInputFields({
                                type: 'slider',
                                name: section.targetName,
                                label: section.targetLabel,
                                value:
                                  assumptionsInput[
                                    section.targetName
                                  ],
                                xs: 12,
                                assumptions: true,
                                valueLabelFormat:
                                  section.valueLabelFormat
                                    ? section.valueLabelFormat
                                    : (x) =>
                                        x === section.max
                                          ? `${x}+`
                                          : `${x}%`,
                                step: section.step,
                                min: section.min,
                                max: section.max,
                              })}
                            </Grid>
                          </CardContent>
                          <Divider />
                          <CardActions>
                            <Grid container justifyContent="center">
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={toggle1Close}
                                >
                                  Close
                                </Button>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                  </Grid>
                  {/* </BlockUi> */}
                </Dialog>
              </>
            ))}
          </List>
        </Card>
      ),
    },
    {
      name: 'netProfit',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Summary
              </small>
              <b>Net Profit from Sale</b>
            </div>
            <div>
              <Tooltip title="Breakdown of the resale price minus expenses to show net profit">
                <Button
                  onClick={() =>
                    setOpenWholesaleNetProfitFromSaleTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                <Grid
                  container
                  className="text-black-80 font-size-sm"
                >
                  <Grid
                    item
                    md={4}
                    data-tour="netProfitFromSale-step-1"
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">
                        Resale Price
                      </div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-success badge-circle mr-2">
                            fast
                          </div>
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                analysis.resellPriceWholesale / 1000
                              }
                              duration={1}
                              delay={2}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    data-tour="netProfitFromSale-step-2"
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">Expenses</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-danger badge-circle mr-2">
                            normal
                          </div>
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                (analysis.offerPrice ||
                                  analysis.listPrice) / 1000
                              }
                              duration={1}
                              delay={2}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    data-tour="netProfitFromSale-step-3"
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">Net Profit</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          <div className="badge badge-primary badge-circle mr-2">
                            slow
                          </div>
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                analytics.wholesale
                                  .saleProceedsWholesale / 1000
                              }
                              duration={1}
                              delay={2}
                              separator=","
                              decimals={0}
                              prefix="$"
                              suffix="k"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <>
                <Suspense fallback={<div>Loading Chart...</div>}>
                  <Chart
                    options={chartOptions}
                    series={chartSeries}
                    type="bar"
                  />
                </Suspense>
              </>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'wholesale',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>
                {getStrategyName(
                  'Wholesale',
                  props?.session?.me?.activeOrg?.member,
                )}{' '}
                Details
              </b>
            </div>
            <div>
              <Tooltip title="Estimated expenses allocated to the renovations of a property">
                <Button
                  onClick={() =>
                    setOpenWholesaleWholesaleDetailsTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={wholesaleChartOptions}
                      series={wholesaleChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(wholesaleFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'comparableProperties',
      card: props.basicView ? null : (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Comparable Properties</b>
            </div>
            <div>
              <Tooltip title="All costs and expenses associated with sale of this property">
                <Button
                  /*onClick={() => setOpenBuyHoldDispositionExpensesTour(true)}*/ className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid container className="text-black-80 font-size-sm">
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Comparable Properties
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={props.determinedComps.length}
                            duration={1}
                            separator=","
                            decimals={0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Potential Comps
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              props.compProperties.length -
                              props.determinedComps.length
                            }
                            duration={1}
                            separator=","
                            decimals={0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <List component="div" className="list-group-flush">
              {map(compData, (section) => (
                <ListItem
                  component="a"
                  button
                  disableRipple
                  className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      className="font-weight-bold text-black"
                      style={{ olor: 'text' }}
                    >
                      {section.label}
                    </Grid>
                    {section.range ? (
                      <Grid item>
                        <Grid container direction="row" spacing={1}>
                          <Grid item>
                            <CountUp
                              start={0}
                              end={section.value1}
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </Grid>
                          <Grid item>
                            <Typography>-</Typography>
                          </Grid>
                          <Grid item>
                            <CountUp
                              start={0}
                              end={section.value2}
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item>
                        <CountUp
                          start={0}
                          end={section.value}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix="$"
                        />
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              ))}
            </List>
            <Grid container>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 25 }}
                  onClick={(e) => props.toggleCenter('Comps')}
                >
                  <Typography>View Comps</Typography>
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'piti',
      hide:
        props?.session?.me?.activeOrg?.member !== 'pacemorby' &&
        props?.session?.me?.activeOrg?.member !== 'astroflip',
      card: props.basicView ? null : (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Deal Sauce Summary</b>
            </div>
            <Select
              value={dealSauceView}
              name="dealSauceView"
              onChange={(event) =>
                setDealSauceView(event.target.value)
              }
              style={{ width: '100%' }}
            >
              <MenuItem value="cash" key="cash">
                Cash
              </MenuItem>
              <MenuItem value="creative" key="creative">
                Creative
              </MenuItem>
            </Select>
          </div>
          <Divider />
          <CardContent>
            {dealSauceView === 'cash' && (
              <>
                <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                  <Grid
                    container
                    className="text-black-80 font-size-sm"
                  >
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          Entry Fee
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div
                            className="d-flex p-1 align-items-center"
                            style={{ minHeight: 50 }}
                          >
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={cashEntryFee}
                                duration={0}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          Max Allowable Offer
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div
                            className="d-flex p-1 align-items-center"
                            style={{ minHeight: 50 }}
                          >
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={
                                  analysis.afterRepairValue -
                                  cashEntryFee
                                }
                                duration={0}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid item xs={12} style={{ marginTop: 35 }}>
                  <Grid container direction="column" spacing={4}>
                    <ContactInformation property={property} />
                  </Grid>
                </Grid>
                <CommentsDialog
                  property={property}
                  userId={props?.session?.me?._id}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 25 }}
                  onClick={(e) => setOpenPITICashData(true)}
                >
                  <Typography>View Cash Underwriting</Typography>
                </Button>
              </>
            )}
            {dealSauceView === 'creative' && (
              <>
                <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                  <Grid
                    container
                    className="text-black-80 font-size-sm"
                  >
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          Entry Fee
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={creativeEntryFee}
                                duration={1}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">PITI</div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={pitiTotal}
                                duration={1}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          % of Purchase Price
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={
                                  analysis?.offerPrice &&
                                  analysis.offerPrice !== 0
                                    ? (creativeEntryFee /
                                        analysis.offerPrice) *
                                      100
                                    : 0
                                }
                                duration={1}
                                delay={1}
                                decimals={2}
                                decimal="."
                                suffix="%"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container direction="column" spacing={4}>
                      <ContactInformation property={property} />
                    </Grid>
                  </Grid>
                  <CommentsDialog
                    property={property}
                    userId={props?.session?.me?._id}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: 25 }}
                    onClick={(e) => setOpenPITICreativeData(true)}
                  >
                    <Typography>
                      View Creative Underwriting
                    </Typography>
                  </Button>
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'deactive',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Hide Cards</b>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Typography>
              All cards past this one will be hidden
            </Typography>
          </CardContent>
        </Card>
      ),
    },
  ]

  const getOrderDefault = () => {
    const storedValues = !isEmpty(property.wholesaleOrder)
      ? property.wholesaleOrder
      : merge(
          cloneDeep(DEFAULT_PREFERENCES),
          activeOrg.memberPreferences,
          activeOrg.preferences,
          props.session.me ? props.session.me.preferences : {},
        ).wholesaleOrder || []
    const newCards = difference(
      map(
        filter(wholesaleCardsToOrder, (card) => !card.hide),
        (card) => card.name,
      ),
      storedValues,
    )
    const deactiveIndex = findIndex(
      storedValues,
      (value) => value === 'deactive',
    )
    return uniq(
      concat(
        slice(storedValues, 0, deactiveIndex),
        newCards,
        slice(storedValues, deactiveIndex),
      ),
    )
  }

  const [wholesaleOrder, setWholesaleOrder] =
    useState(getOrderDefault())

  useEffect(() => {
    setWholesaleOrder(getOrderDefault())
  }, [activeOrg.member])

  const [orderChanged, setOrderChanged] = useState(false)
  const [orderDefault, setOrderDefault] = useState(false)
  const [loader, setLoader] = useState(false)

  const SortableCard = SortableElement(({ item }) => (
    <Grid item sm={6} xl={4} className="d-flex">
      {item}
    </Grid>
  ))
  const SortableGrid = SortableContainer(({ items }) => (
    <Grid container direction="row" spacing={2} className="p-3">
      {map(items, (item, index) => (
        <SortableCard
          key={`item-${index}`}
          index={index}
          item={item}
        />
      ))}
    </Grid>
  ))

  const [updateProperty] = useMutation(UPDATE_PROPERTY)
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newOrder = arrayMoveImmutable(
        wholesaleOrder,
        oldIndex,
        newIndex,
      )
      setWholesaleOrder(newOrder)
      setOrderChanged(true)
    }
  }

  const resetOrder = () => {
    setWholesaleOrder(getOrderDefault())
    setOrderChanged(false)
  }

  const submitOrder = () => {
    setLoader(true)
    const preferences = merge(
      cloneDeep(DEFAULT_PREFERENCES),
      activeOrg.memberPreferences,
      activeOrg.preferences,
    )
    updateProperty({
      refetchQueries: [
        {
          query: GET_PROPERTY,
          variables: { id: property._id },
        },
      ],
      variables: {
        id: property._id,
        propertyInput: {
          wholesaleOrder,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(`Layout saved`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        if (orderDefault) {
          updateOrganization({
            refetchQueries: [
              {
                query: GET_ME,
              },
            ],
            variables: {
              id: activeOrg._id,
              organizationInput: {
                preferences: {
                  ...preferences,
                  wholesaleOrder,
                },
              },
            },
          })
            .then(() => {
              enqueueSnackbar(`Default Wholesale layout saved`, {
                variant: 'success',
                autoHideDuration: 3000,
              })
              setOrderChanged(false)
              setLoader(false)
            })
            .catch((error) => {
              enqueueSnackbar(
                `Failed to save default Wholesale layout`,
                {
                  variant: 'error',
                  autoHideDuration: 3000,
                },
              )
            })
        } else {
          setOrderChanged(false)
          setLoader(false)
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to save layout`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const sortedWholesaleCards = sortBy(
    filter(wholesaleCardsToOrder, (card) => !card.hide),
    [
      function (o) {
        return findIndex(wholesaleOrder, (value) => value === o.name)
      },
    ],
  )

  return (
    <>
      {!props.canDrag && (
        <Grid container direction="row" spacing={2} className="p-3">
          {props.publicImageCard && (
            <Grid
              item
              xs={12}
              md={6}
              lg={props.publicDashboard ? 4 : 6}
              xl={4}
              className="d-flex w-100"
            >
              {props.publicImageCard}
            </Grid>
          )}
          {map(
            slice(
              sortedWholesaleCards,
              0,
              findIndex(
                sortedWholesaleCards,
                (card) => card.name === 'deactive',
              ),
            ),
            (card) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={props.publicDashboard ? 4 : 6}
                xl={4}
                className="d-flex w-100"
              >
                {card.card}
              </Grid>
            ),
          )}
        </Grid>
      )}
      {props.canDrag && (
        <SortableGrid
          items={compact(
            concat(
              [props.publicImageCard],
              map(sortedWholesaleCards, (card) => card.card),
            ),
          )}
          onSortEnd={onSortEnd}
          axis="xy"
        />
      )}
      {map(calculationDialogs, (section) => (
        <Dialog
          open={modal1 === section.label}
          onClose={toggle1Close}
          maxWidth="lg"
          scroll="body"
          classes={{
            paper:
              'modal-content rounded border-0 bg-white p-3 p-xl-0',
          }}
        >
          {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                  <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                    <div className="text-white mt-3">
                      <Typography
                        style={{ fontSize: 30, fontWeight: 600 }}
                      >
                        Calculation
                      </Typography>
                    </div>
                    {section.calculation}
                    <div className="text-white mt-3">
                      <Typography>
                        * Calculation may be a few dollars off due to
                        rounding
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={7}>
              <div>
                <Card>
                  <CardHeader title={section.label} />
                  <Divider />
                  <CardContent style={{ height: '100%' }}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Card
                          style={{
                            backgroundColor: '#eff2f5',
                            borderRadius: 0,
                          }}
                        >
                          <CardContent>
                            <Typography>
                              {section.description}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={toggle1Close}
                        >
                          Close
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </div>
            </Grid>
          </Grid>
          {/* </BlockUi> */}
        </Dialog>
      ))}
      <SubtoPITICash
        open={openPITICashData}
        onClose={() => setOpenPITICashData(false)}
        propertyId={property?._id}
        address={property?.address}
        city={property.city}
        state={property.state}
        zipcode={property?.zip}
      />
      <SubtoPITICreative
        open={openPITICreativeData}
        onClose={() => setOpenPITICreativeData(false)}
        propertyId={property?._id}
        address={property?.address}
        city={property.city}
        state={property.state}
        zipcode={property?.zip}
      />
      <Drawer
        open={orderChanged}
        anchor="bottom"
        variant="persistent"
        PaperProps={{
          style: { backgroundColor: '#253152', padding: 15 },
        }}
      >
        {/* <Alert
         className="mb-4"
         style={{ backgroundColor: '#253152', color: 'white' }}
         icon={false}
         action={
           <IconButton onClick={props.resetPropertyChanges}>
             <Close style={{ color: 'white' }} />
           </IconButton>
         }
       > */}
        {/* <Typography style={{ color: 'white', fontWeight: 600 }}>You have unsaved changes</Typography> */}
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item style={{ marginLeft: 20 }}>
            <IconButton onClick={resetOrder} size="large">
              <Close style={{ color: 'white' }} />
            </IconButton>
          </Grid>
          <Grid item style={{ marginLeft: 0 }}>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: '#4290ff',
                color: 'white',
                boxShadow: 'none',
              }}
              onClick={submitOrder}
              disabled={loader}
            >
              <Typography style={{ fontWeight: 600 }}>
                Save
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <FormControl
              component="fieldset"
              className="pr-4"
              style={{ color: 'white' }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={orderDefault}
                      onChange={() => setOrderDefault(!orderDefault)}
                      style={{ color: 'white' }}
                    />
                  }
                  label="Set this as default Wholesale layout"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Typography style={{ flex: 1 }} />
        </Grid>
      </Drawer>

      <WholesaleStrategyTour
        isOpenWholesaleStrategyTour={openWholesaleStrategyTour}
        closeWholesaleStrategyTour={() =>
          setOpenWholesaleStrategyTour(false)
        }
      />
      <WholesaleTargetsTour
        isOpenWholesaleTargetsTour={openWholesaleTargetsTour}
        closeWholesaleTargetsTour={() =>
          setOpenWholesaleTargetsTour(false)
        }
      />
      <WholesaleNetProfitFromSaleTour
        isOpenWholesaleNetProfitFromSaleTour={
          openWholesaleNetProfitFromSaleTour
        }
        closeWholesaleNetProfitFromSaleTour={() =>
          setOpenWholesaleNetProfitFromSaleTour(false)
        }
      />
      <WholesaleWholesaleDetailsTour
        isOpenWholesaleWholesaleDetailsTour={
          openWholesaleWholesaleDetailsTour
        }
        closeWholesaleWholesaleDetailsTour={() =>
          setOpenWholesaleWholesaleDetailsTour(false)
        }
      />
    </>
  )
}

export default withSession(CenterPageWholesale)
