/**
 * @ Author: Housefolios
 * @ Create Time: 2022-05-12 12:23:15
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-09-18 14:16:25
 * @ Description: Dialog to move a property to a diferent portfolio
 */

import React, { useState } from 'react'

import map from 'lodash/map'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import concat from 'lodash/concat'
import head from 'lodash/head'
import get from 'lodash/get'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'

import { useMutation } from '@apollo/client'
import { UPDATE_PROPERTIES } from '../../Portfolio/mutations'
import { FolderOpenOutlined } from '@mui/icons-material'
import { GET_PORTFOLIOS } from '../../Portfolio/queries'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import { GET_ASSUMPTIONS } from '@/housefolios-components/AssumptionsSettings/quries'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import CreatePortfolio from '../../Portfolio/CreatePortfolio'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'

import moment from 'moment'
import { currencyFormat, numberFormat } from '@/utils/number'

function DisplayRealeflowDataComponent(props) {
  const { property = {}, menuItem, button } = props
  const [openDisplayRealeflowData, setDisplayRealeflowData] =
    useState(false)
  const handleOpenDisplayRealeflowData = () => {
    setDisplayRealeflowData(true)
  }
  const handleCloseDisplayRealeflowData = () => {
    setDisplayRealeflowData(false)
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const parseDateSince = (date) => {
    const oldTime = new Date(date)

    return moment(oldTime).fromNow()
  }

  const { realeflowData = {} } = property

  const { SubjectProperty = {} } = realeflowData || {}

  // Using the correct key
  let actualFirstName = Object.keys(realeflowData).find((key) =>
    key.includes('FirstName'),
  )

  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
  })

  const sections = [
    {
      section: 'Owner Insights',
      fields: [
        {
          name: 'Owner Name',
          value:
            (realeflowData[actualFirstName] ||
              SubjectProperty.FirstName) +
            ' ' +
            (realeflowData.LastName || SubjectProperty.LastName),
        },
        {
          name: 'Owner Type',
          value: realeflowData.OwnerType || SubjectProperty.OwnerType,
        },
        {
          name: 'Length of Ownership',
          value: parseDateSince(
            realeflowData.LastSalesDate ||
              SubjectProperty.LastSalesDate,
          ),
        },
        {
          name: 'Estimated Equity',
          value: includes(
            realeflowData.LTV || SubjectProperty.Ltv,
            '%',
          )
            ? realeflowData.LTV || SubjectProperty.Ltv
            : percentageFormat.format(
                (100 -
                  Number(realeflowData.LTV || SubjectProperty.Ltv)) /
                  100,
              ),
        },
        {
          name: 'Potentially Inherited',
          value: !isEmpty(SubjectProperty)
            ? get(
                SubjectProperty,
                'Assessor.Leadpipes.PotentiallyInherited',
              )
              ? 'Yes'
              : 'No'
            : realeflowData.PotentiallyInherited === '1'
            ? 'Yes'
            : 'No',
        },
        {
          name: 'Lives in Home',
          value: realeflowData.placeholder === '1' ? 'Yes' : 'No',
        },
        {
          name: 'Absentee Owner',
          value: !isEmpty(SubjectProperty)
            ? get(SubjectProperty, 'Assessor.Leadpipes.AbsenteeOwner')
              ? 'Yes'
              : 'No'
            : realeflowData.AbsenteeOwner === '1'
            ? 'Yes'
            : 'No',
        },
        {
          name: 'Out of State Owner',
          value: realeflowData.placeholder === '1' ? 'Yes' : 'No',
        },
        {
          name: 'Mailing Address',
          value: !isEmpty(SubjectProperty)
            ? SubjectProperty.MailingAddress +
              ' ' +
              SubjectProperty.MailingCity +
              ', ' +
              SubjectProperty.MailingState +
              ' ' +
              SubjectProperty.MailingZipCode
            : realeflowData.RecipientAddress +
              ' ' +
              realeflowData.RecipientCity +
              ', ' +
              realeflowData.RecipientState +
              ' ' +
              realeflowData.RecipientPostalCode,
        },
      ],
    },
    {
      section: 'Property Insights',
      subSections: [
        {
          section: 'Property Details',
          fields: [
            {
              name: 'Home Type',
              value:
                realeflowData.PropertyType ||
                SubjectProperty.PropertyType,
            },
            {
              name: 'House Style',
              value:
                realeflowData.HouseStyle ||
                SubjectProperty.HouseStyle,
            },
            {
              name: 'Year Built',
              value:
                realeflowData.YearBuilt || SubjectProperty.YearBuilt,
            },
            {
              name: 'Square Feet',
              value: numberFormat.format(
                realeflowData.SquareFootage ||
                  SubjectProperty.SquareFeet,
              ),
            },
            {
              name: 'Bath',
              value: numberFormat.format(
                realeflowData.Baths || SubjectProperty.Bathrooms,
              ),
            },
            {
              name: 'Beds',
              value: numberFormat.format(
                realeflowData.Beds || SubjectProperty.Bedrooms,
              ),
            },
            {
              name: 'Property Taxes',
              value: includes(
                realeflowData.TaxAmount || SubjectProperty.TaxAmount,
                '$',
              )
                ? realeflowData.TaxAmount || SubjectProperty.TaxAmount
                : currencyFormat.format(
                    Number(
                      realeflowData.TaxAmount ||
                        SubjectProperty.TaxAmount,
                    ) / 100,
                  ) + ' (annual)',
            },
            {
              name: 'Last Sale',
              value: includes(
                realeflowData.LastSalesPrice ||
                  SubjectProperty.SalesPrice,
                '$',
              )
                ? (realeflowData.LastSalesPrice ||
                    SubjectProperty.SalesPrice) +
                  ' (' +
                  (realeflowData.LastSalesDate ||
                    SubjectProperty.LastSalesDate) +
                  ')'
                : currencyFormat.format(
                    Number(
                      realeflowData.LastSalesPrice ||
                        SubjectProperty.SalesPrice,
                    ),
                  ) +
                  ' (' +
                  (realeflowData.LastSalesDate ||
                    SubjectProperty.LastSalesDate) +
                  ')',
            },
            {
              name: 'Owner Type',
              value:
                realeflowData.OwnerType || SubjectProperty.OwnerType,
            },
            {
              name: 'Stories',
              value: numberFormat.format(
                realeflowData.Stories || SubjectProperty.Stories,
              ),
            },
            {
              name: 'Number of Units',
              value: numberFormat.format(
                realeflowData.Units || SubjectProperty.TotalUnits,
              ),
            },
            {
              name: 'Cooling',
              value:
                realeflowData.AirConditioning ||
                SubjectProperty.AirConditioning,
            },
            {
              name: 'Heating Fuel',
              value:
                realeflowData.HeatingFuel ||
                SubjectProperty.HeatingFuel,
            },
            {
              name: 'Subdivision',
              value:
                realeflowData.Subdivision ||
                SubjectProperty.Subdivision,
            },
            {
              name: 'Zoning',
              value: realeflowData.Zoning || SubjectProperty.Zoning,
            },
            // {
            //   name: "Legal Description",
            //   value: realeflowData.placeholder,
            // },
            // {
            //   name: "Parcel Number",
            //   value: realeflowData.placeholder,
            // },
            // {
            //   name: "Flooring",
            //   value: realeflowData.placeholder,
            // },
            {
              name: 'Garage',
              value: realeflowData.Garage || SubjectProperty.Garage,
            },
            {
              name: 'Lot Size',
              value:
                numberFormat.format(
                  realeflowData.LotSizeSqFt ||
                    SubjectProperty.LotSquareFeet,
                ) + ' lot sqft',
            },
          ],
        },
        {
          section: 'Equity',
          fields: [
            {
              name: 'Estimated Mortgage Balance',
              value: includes(
                realeflowData.EstimatedMortgageBalance ||
                  SubjectProperty?.Assessor
                    ?.estimated_mortgage_balance,
                '$',
              )
                ? realeflowData.EstimatedMortgageBalance ||
                  SubjectProperty?.Assessor
                    ?.estimated_mortgage_balance
                : currencyFormat.format(
                    Number(
                      realeflowData.EstimatedMortgageBalance ||
                        SubjectProperty?.Assessor
                          ?.estimated_mortgage_balance,
                    ),
                  ),
            },
            {
              name: 'Mortgage Amount',
              value: includes(
                realeflowData.LoanAmount ||
                  SubjectProperty.TotalLoans,
                '$',
              )
                ? realeflowData.LoanAmount ||
                  SubjectProperty.TotalLoans
                : currencyFormat.format(
                    realeflowData.LoanAmount ||
                      SubjectProperty.TotalLoans,
                  ),
            },
            {
              name: 'Loan to Value',
              value: includes(
                realeflowData.LTV || SubjectProperty.Ltv,
                '%',
              )
                ? realeflowData.LTV || SubjectProperty.Ltv
                : percentageFormat.format(
                    Number(realeflowData.LTV || SubjectProperty.Ltv) /
                      100,
                  ),
            },
          ],
        },
        {
          section: 'Valuations',
          fields: [
            {
              name: 'AVM',
              value: includes(
                realeflowData.AVM || SubjectProperty?.Assessor?.avm,
                '$',
              )
                ? realeflowData.AVM || SubjectProperty?.Assessor?.avm
                : currencyFormat.format(
                    realeflowData.AVM ||
                      SubjectProperty?.Assessor?.avm,
                  ),
            },
            {
              name: 'Market Value Amount',
              value: includes(
                realeflowData.MarketValue ||
                  SubjectProperty.MarketTotalValue,
                '$',
              )
                ? realeflowData.MarketValue ||
                  SubjectProperty.MarketTotalValue
                : currencyFormat.format(
                    realeflowData.MarketValue ||
                      SubjectProperty.MarketTotalValue,
                  ),
            },
            {
              name: 'Assessed Total',
              value: includes(
                realeflowData.AssessedTotal ||
                  SubjectProperty.AssessedTotalValue,
                '$',
              )
                ? realeflowData.AssessedTotal ||
                  SubjectProperty.AssessedTotalValue
                : currencyFormat.format(
                    realeflowData.AssessedTotal ||
                      SubjectProperty.AssessedTotalValue,
                  ),
            },
          ],
        },
        {
          section: 'Construction & Materials',
          fields: [
            {
              name: 'Condition',
              value:
                realeflowData.Condition || SubjectProperty.Condition,
            },
            {
              name: 'Exterior',
              value:
                realeflowData.Exterior || SubjectProperty.Exterior,
            },
            {
              name: 'Interior Walls',
              value:
                realeflowData.InteriorWalls ||
                SubjectProperty.InteriorWalls,
            },
            {
              name: 'Basement',
              value:
                realeflowData.Basement || SubjectProperty.Basement,
            },
            {
              name: 'Roof',
              value: realeflowData.Roof || SubjectProperty.Roof,
            },
            {
              name: 'Roof Shape',
              value:
                realeflowData.RoofShape || SubjectProperty.RoofType,
            },
            {
              name: 'Water',
              value: realeflowData.Water || SubjectProperty.Water,
            },
            {
              name: 'Sewer',
              value: realeflowData.Sewer || SubjectProperty.Sewer,
            },
            {
              name: 'Location Influence',
              value:
                realeflowData.LocationInfluence ||
                SubjectProperty.LocationInfluence,
            },
            {
              name: 'Heating',
              value: realeflowData.Heating || SubjectProperty.Heating,
            },
            {
              name: 'Heating Fuel',
              value:
                realeflowData.HeatingFuel ||
                SubjectProperty.HeatingFuel,
            },
            {
              name: 'A/C',
              value:
                realeflowData.AirConditioning ||
                SubjectProperty.AirConditioning,
            },
            {
              name: 'Fireplace',
              value: !isEmpty(SubjectProperty)
                ? SubjectProperty.Fireplace === '1'
                  ? 'Yes'
                  : 'No'
                : realeflowData.Fireplace === '1'
                ? 'Yes'
                : 'No',
            },
            {
              name: 'Garage',
              value: realeflowData.Garage || SubjectProperty.Garage,
            },
            {
              name: 'Patio',
              value: realeflowData.Patio || SubjectProperty.Patio,
            },
            {
              name: 'Pool',
              value: realeflowData.Pool || SubjectProperty.Pool,
            },
            {
              name: 'Porch',
              value: realeflowData.Porch || SubjectProperty.Porch,
            },
          ],
        },
        {
          section: 'Building Size',
          fields: [
            {
              name: 'Building Area',
              value:
                numberFormat.format(
                  realeflowData.SquareFootage ||
                    SubjectProperty.SquareFeet,
                ) + ' sqft',
            },
            {
              name: 'Living Area',
              value:
                numberFormat.format(
                  realeflowData.SquareFootage ||
                    SubjectProperty.SquareFeet,
                ) + ' sqft',
            },
            // {
            //   name: 'Gross Area',
            //   value: realeflowData.placeholder
            // },
            // {
            //   name: 'Adjacent Area',
            //   value: realeflowData.placeholder
            // },
            // {
            //   name: 'Basement Area',
            //   value: realeflowData.placeholder
            // },
            // {
            //   name: 'Garage Area',
            //   value: realeflowData.placeholder
            // },
          ],
        },
      ],
    },
  ]

  return (
    <>
      {button && (
        <Button
          variant="contained"
          fullWidth
          onClick={handleOpenDisplayRealeflowData}
          style={{ marginBottom: '10px' }}
        >
          <div className="nav-link-icon">
            <FontAwesomeIcon icon={['far', 'folder']} />
          </div>
          <span>View All Data</span>
        </Button>
      )}
      {menuItem && (
        <ListItem button onClick={handleOpenDisplayRealeflowData}>
          <div className="nav-link-icon">
            <FontAwesomeIcon icon={['far', 'folder']} />
          </div>
          <span>View All Data</span>
        </ListItem>
      )}
      <Dialog
        open={openDisplayRealeflowData}
        onClose={handleCloseDisplayRealeflowData}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="lg"
        fullWidth={true}
        onKeyDown={stopPropagationForTab}
      >
        <DialogTitle id="form-dialog-title">
          Property Data
        </DialogTitle>
        <DialogContent className="p-4">
          <Container style={{ padding: 0 }}>
            <Grid
              container
              direction="row"
              spacing={2}
              style={{ marginTop: 3 }}
            >
              {map(sections, (section) => (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      {section.section}
                    </Typography>
                  </Grid>
                  {map(section.fields, (field) => (
                    <>
                      <Grid item xs={6}>
                        <Typography bold>{field.name}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{field.value}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </>
                  ))}
                  {map(section.subSections, (section) => (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {section.section}
                        </Typography>
                      </Grid>
                      {map(section.fields, (field) => (
                        <>
                          <Grid item xs={6}>
                            <Typography bold>{field.name}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>{field.value}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </>
                      ))}
                    </>
                  ))}
                </>
              ))}
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={handleCloseDisplayRealeflowData}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withSession(DisplayRealeflowDataComponent)
