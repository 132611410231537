/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: TJ Felman
 * @ Modified time: 2022-03-21 13:22:52
 * @ Description: Dialog to delete a property
 */

import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dialog } from '@mui/material'

import BlockUi from 'react-block-ui'

function SessionOutDialog(props) {
  function handleLogIn() {
    window.location.reload()
  }
  return (
    <>
      <Dialog
        open={true}
        onClose={handleLogIn}
        classes={{ paper: 'shadow-lg rounded' }}
      >
        <BlockUi
          // blocking={}
          // loader={
          //   <PacmanLoader color={'var(--primary)'} loading={true} />
          // }
          message={
            <div className="text-primary">Restoring session ...</div>
          }
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                <FontAwesomeIcon
                  icon={['far', 'clock']}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              Connection Failed!
            </h4>
            <div className="pt-4">
              <Button
                variant="contained"
                style={{ backgroundColor: '#f83145', color: 'white' }}
                onClick={handleLogIn}
                //className="btn-danger btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Refresh</span>
              </Button>
            </div>
          </div>
        </BlockUi>
      </Dialog>
    </>
  )
}

export default SessionOutDialog
