import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import SelectMUI from '@mui/material/Select'

export default function Select({
  label,
  items = [],
  onChange,
  value,
  name,
  ...props
}) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <SelectMUI
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name={name ?? ''}
          value={value}
          label={label}
          onChange={onChange}
          {...props}
        >
          {items.map((item) => (
            <MenuItem value={item?._id}>{item?.name}</MenuItem>
          ))}
        </SelectMUI>
      </FormControl>
    </Box>
  )
}
