/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-11-19 11:10:58
 * @ Description: Dialog for the creation of a new portfolio
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Dialog,
  Grid,
  Typography,
  Card,
  Button,
  Link,
  List,
  ListItem,
  MenuItem,
  TextField,
} from '@mui/material'

import Autocomplete from '@mui/material/Autocomplete'

import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'
import head from 'lodash/head'

import hero1 from '@/assets/images/hero-bg/hero-1.jpg'
import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import { useMutation } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { ADD_PORTFOLIO } from '../mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_PORTFOLIOS } from '../queries'
import { Add } from '@mui/icons-material'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import {
  filterPreferedStrategies,
  getStrategyName,
} from '@/utils/stratagies'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function CreatePortfolioComponent(props) {
  const { headerDot, launchpad, sidebarMenu } = props

  const [openCreateNewPortfolio, setCreateNewPortfolio] =
    useState(false)

  const [loader, setLoader] = useState(false)
  const { limits, activeOrg } = props.session.me
  const { portfolioLimit } = limits

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [strategy, setStrategy] = useState(
    merge(
      cloneDeep(DEFAULT_PREFERENCES),
      props?.session?.me?.activeOrg?.memberPreferences,
      props?.session?.me?.activeOrg?.preferences,
    ).general['defaultStrategy'],
  )
  const [assumptions, setAssumptions] = useState({})

  const {
    _id: RemovedId,
    __typename: RemovedTypename,
    ...defaultAssumptions
  } = activeOrg.assumptions

  const [preferences, setPreferences] = useState(
    merge(
      cloneDeep(DEFAULT_PREFERENCES),
      props?.session?.me?.activeOrg?.memberPreferences,
      props?.session?.me?.activeOrg?.preferences,
    ),
  )

  if (
    !includes(
      filterPreferedStrategies(
        preferences,
        props?.session?.me?.activeOrg?.member,
        true,
      ),
      strategy,
    )
  )
    setStrategy(
      head(
        filterPreferedStrategies(
          preferences,
          props?.session?.me?.activeOrg?.member,
          true,
        ),
      ),
    )

  const handleChangeDropdown = (event, value, collection, name) => {
    let newPreferences = { ...preferences }
    let newValue = value
    if (name === 'strategies') {
      newPreferences[collection][name] = map(
        newPreferences[collection][name],
        (preference) => {
          return {
            value: preference.value,
            active: includes(newValue, preference.value),
            memberOnly: preference.memberOnly,
            memberExcluded: preference.memberExcluded,
          }
        },
      )
    } else newPreferences[collection][name] = newValue
    setPreferences(newPreferences)
  }

  const handleOpenCreateNewPortfolio = () => {
    if (props.closeMenu) props.closeMenu()
    setCreateNewPortfolio(true)
    setName('')
    setDescription('')
    setStrategy(preferences.general['defaultStrategy'])
    setAssumptions(defaultAssumptions)
    setLoader(false)
    setPreferences(
      merge(
        cloneDeep(DEFAULT_PREFERENCES),
        props?.session?.me?.activeOrg?.memberPreferences,
        props?.session?.me?.activeOrg?.preferences,
      ),
    )
  }
  const handleCloseCreateNewPortfolio = () => {
    setCreateNewPortfolio(false)
    setName('')
    setDescription('')
    setStrategy(preferences.general['defaultStrategy'])
    setAssumptions(defaultAssumptions)
  }

  const [addPortfolio] = useMutation(ADD_PORTFOLIO)

  const [activeTab, setActiveTab] = useState('0')

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <Query query={GET_PORTFOLIOS}>
      {({ loading, error, data }) => {
        //if (loading) return 'Loading...'
        if (error) return `Error! ${error.message}`
        const { portfolios = [] } = data || {}
        return (
          <>
            {sidebarMenu && (
              <>
                <ListItem
                  component={Link}
                  style={{
                    textAlign: 'center',
                    color: '#04a3e3',
                    marginLeft: 8,
                  }}
                  onClick={
                    portfolios.length >= portfolioLimit
                      ? () => props.toggleLimit('portfolio')
                      : handleOpenCreateNewPortfolio
                  }
                  disabled={loading}
                  button
                >
                  <Add style={{ marginRight: 10 }} />
                  Add Portfolio
                </ListItem>
              </>
              // <Grid container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
              //   <Grid item onClick={handleOpenCreateNewPortfolio}>
              //     <Typography>Add Portfolio</Typography>
              //   </Grid>
              // </Grid>
            )}
            {props.dropdown && (
              <ListItem
                style={{
                  textAlign: 'center',
                  color: '#04a3e3',
                  marginLeft: 8,
                }}
                onClick={
                  portfolios.length >= portfolioLimit
                    ? () => props.toggleLimit('portfolio')
                    : handleOpenCreateNewPortfolio
                }
                disabled={loading}
                button
              >
                <Add style={{ marginRight: 10 }} />
                Add Portfolio
              </ListItem>
            )}
            {headerDot && (
              <Grid item sm={6}>
                <Button
                  onClick={
                    portfolios.length >= portfolioLimit
                      ? () => props.toggleLimit('portfolio')
                      : handleOpenCreateNewPortfolio
                  }
                  variant="text"
                  disabled={props.disabled || loading}
                  className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"
                >
                  <FontAwesomeIcon
                    icon={['far', 'folder-plus']}
                    className="h2 d-block mb-2 mx-auto mt-1 text-success"
                  />
                  <div className="font-weight-bold text-black">
                    Create Portfolio
                  </div>
                  <div className="font-size-md mb-1 text-black-50">
                    Keep it organized
                  </div>
                </Button>
              </Grid>
            )}
            {launchpad && (
              <Button
                variant="text"
                className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"
                disabled={loading}
                onClick={
                  portfolios.length >= portfolioLimit
                    ? () => props.toggleLimit('portfolio')
                    : handleOpenCreateNewPortfolio
                }
              >
                <FontAwesomeIcon
                  icon={['far', 'folder-plus']}
                  className="h2 d-block mx-auto mb-2 mt-1 text-success"
                />
                <div className="font-weight-bold font-size-md text-black">
                  Create Portfolio
                </div>
                <div className="font-size-md mb-1 text-black-50">
                  Keep it organized
                </div>
              </Button>
            )}
            <Dialog
              open={openCreateNewPortfolio}
              onClose={handleCloseCreateNewPortfolio}
              maxWidth="lg"
              scroll="body"
              classes={{
                paper:
                  'modal-content rounded border-0 bg-white p-3 p-xl-0',
              }}
              onKeyDown={(event) => event.stopPropagation()}
              onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <BlockUi
                blocking={loader}
                loader={
                  <PacmanLoader
                    color={'var(--primary)'}
                    loading={true}
                  />
                }
                message={
                  <div className="text-primary">
                    Creating Portfolio
                  </div>
                }
              >
                <Grid container spacing={0}>
                  <Grid item xs={12} lg={5}>
                    <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
                      <div className="flex-grow-1 w-100 d-flex align-items-center">
                        <div
                          className="bg-composed-wrapper--image rounded br-xl-right-0"
                          style={{
                            backgroundImage: 'url(' + hero1 + ')',
                          }}
                        />
                        <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                        <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                          <div className="text-white mt-3">
                            <h1 className="display-4 my-3 font-weight-bold">
                              What you need to know about portfolios
                            </h1>
                            <p className="font-size-md mb-0 text-white-50">
                              All your properties are organized into
                              portfolios. Portfolios help you save
                              time by applying your assumptions to the
                              properties moved into it.
                            </p>
                            <div className="divider border-1 mx-auto mx-xl-0 my-5 border-light opacity-2 rounded w-25" />
                            {/* <div>
                              <Button
                                href="https://help.housefolios.com/adding-a-portfolio"
                                target="_blank"
                                //onClick={(e) => e.preventDefault()}
                                style={{
                                  backgroundColor: '#f4782e',
                                  color: 'white',
                                }}
                              >
                                <span className="btn-wrapper--label">
                                  Learn More
                                </span>
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon
                                    icon={['fas', 'arrow-right']}
                                  />
                                </span>
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={7}>
                    <ValidatorForm
                      id="addPortfolio"
                      onSubmit={(event) => {
                        setLoader(true)
                        addPortfolio({
                          awaitRefetchQueries: true,
                          variables: {
                            portfolioInput: {
                              name,
                              strategy,
                              strategies: get(
                                preferences,
                                'general.strategies',
                              ),
                              description,
                              assumptions,
                            },
                          },
                          refetchQueries: [
                            {
                              query: GET_PORTFOLIOS,
                            },
                            {
                              query: GET_ME,
                            },
                          ],
                        }).then(({ data }) => {
                          enqueueSnackbar(`Portfolio Created`, {
                            variant: 'success',
                            autoHideDuration: 3000,
                          })
                          if (props.setNewPortfolio)
                            props.setNewPortfolio(
                              data.addPortfolio._id,
                            )
                          handleCloseCreateNewPortfolio()
                        })
                      }}
                    >
                      <div className="mt-4 mt-xl-0">
                        <List className="nav-tabs nav-tabs-primary tabs-animated tabs-animated-line justify-content-center d-flex">
                          <ListItem
                            button
                            className="px-0 mx-3"
                            disableRipple
                            selected={activeTab === '0'}
                            onClick={() => {
                              toggle('0')
                            }}
                          >
                            <span className="py-1 font-weight-bold">
                              Portfolio Details
                            </span>
                          </ListItem>
                          {/* <ListItem
                            button
                            className="px-0 mx-3"
                            disableRipple
                            selected={activeTab === '1'}
                            onClick={() => {
                              toggle('1');
                            }}>
                            <span className="py-1 font-weight-bold">Portfolio Assumptions</span>
                          </ListItem> */}
                        </List>

                        <div
                          className={clsx('tab-item-wrapper', {
                            active: activeTab === '0',
                          })}
                          index={0}
                        >
                          <Card className="shadow-none bg-transparent p-4 border-0">
                            <div>
                              <div className="mb-3">
                                <TextValidator
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth={true}
                                  required={true}
                                  autoFocus
                                  value={name}
                                  onChange={(event) =>
                                    setName(event.target.value)
                                  }
                                  label="Portfolio Name"
                                  validators={[
                                    'required',
                                    'maxStringLength: 50',
                                  ]}
                                  errorMessages={[
                                    'This field is required',
                                    'You cannot exceed 50 characters',
                                  ]}
                                />
                              </div>
                              <div className="mb-3">
                                <TextField
                                  variant="outlined"
                                  margin="dense"
                                  label="Portfolio Description"
                                  fullWidth={true}
                                  value={description}
                                  onChange={(event) =>
                                    setDescription(event.target.value)
                                  }
                                />
                              </div>
                              <div className="mb-3">
                                <Autocomplete
                                  id="checkboxes-tags-demo"
                                  multiple
                                  disableCloseOnSelect
                                  options={filterPreferedStrategies(
                                    preferences,
                                    props?.session?.me?.activeOrg
                                      ?.member,
                                    false,
                                  )}
                                  value={filterPreferedStrategies(
                                    preferences,
                                    props?.session?.me?.activeOrg
                                      ?.member,
                                    true,
                                  )}
                                  onChange={(event, newValue) => {
                                    if (!isEmpty(newValue))
                                      handleChangeDropdown(
                                        event,
                                        newValue,
                                        'general',
                                        'strategies',
                                      )
                                  }}
                                  getOptionLabel={(option) =>
                                    getStrategyName(
                                      option,
                                      props.session.me.activeOrg
                                        .member,
                                    )
                                  }
                                  disableClearable
                                  // isOptionEqualToValue={(
                                  //   option,
                                  //   value,
                                  // ) =>
                                  //   option === value.value &&
                                  //   value.active
                                  // }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Strategies"
                                    />
                                  )}
                                />
                              </div>
                              <div className="mb-3">
                                <TextField
                                  select
                                  variant="outlined"
                                  margin="dense"
                                  label="Default Property Strategy"
                                  fullWidth={true}
                                  value={strategy}
                                  onChange={(event) =>
                                    setStrategy(event.target.value)
                                  }
                                  required={true}
                                >
                                  {map(
                                    filter(
                                      preferences.general[
                                        'strategies'
                                      ],
                                      'active',
                                    ),
                                    (preference) => (
                                      <MenuItem
                                        key={preference.value}
                                        value={preference.value}
                                      >
                                        {getStrategyName(
                                          preference.value,
                                          props.session.me.activeOrg
                                            .member,
                                        )}
                                      </MenuItem>
                                    ),
                                  )}
                                </TextField>
                              </div>
                              <div className="text-center">
                                <Button
                                  variant="contained"
                                  className="font-weight-bold px-4 my-3"
                                  color="primary"
                                  style={{ color: 'white' }}
                                  disabled={
                                    loader || !name || !strategy
                                  }
                                  type="submit"
                                  form="addPortfolio"
                                >
                                  Create Portfolio
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </div>
                        {/* <div
                          className={clsx('tab-item-wrapper', {
                            active: activeTab === '1'
                          })}
                          index={1}>
                          <Card className="shadow-none bg-transparent p-4 border-0">
                            <div>
                              <Grid container direction='column' alignItems='center'>
                                <Grid item>
                                  <AssumptionsAccordion assumptions={assumptions} setAssumptions={setAssumptions} />
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    className="font-weight-bold px-4 my-3"
                                    color="primary"
                                    style={{ color: 'white' }}
                                    type='submit'
                                  >
                                    Create Portfolio
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          </Card>
                        </div> */}
                      </div>
                    </ValidatorForm>
                  </Grid>
                </Grid>
              </BlockUi>
            </Dialog>
          </>
        )
      }}
    </Query>
  )
}

export default withSession(CreatePortfolioComponent)
