import { gql } from '@apollo/client'

export const UPDATE_ACTIVATION = gql`
  mutation updateActivation($id: ID!, $orgId: String!) {
    updateActivation(id: $id, orgId: $orgId) {
      user {
        _id
        email
        username
        profile {
          firstname
          lastname
        }
        roles {
          organization {
            _id
            name
          }
          role
        }
        userType
        jobTitle
        bioDescription
        activeOrg {
          _id
          name
        }
        setup
      }
      token
    }
  }
`

export const DELETE_SUBSCRIPTION = gql`
  mutation deleteSubscription($id: String!) {
    deleteSubscription(id: $id) {
      _id
      subscriptions
    }
  }
`

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($id: String!) {
    cancelSubscription(id: $id) {
      _id
      subscriptions
    }
  }
`

export const STOPCANCEL_SUBSCRIPTION = gql`
  mutation stopCancelSubscription($id: String!) {
    stopCancelSubscription(id: $id) {
      _id
      subscriptions
    }
  }
`

export const END_TRIAL = gql`
  mutation endTrial($id: String!) {
    endTrial(id: $id) {
      _id
      subscriptions
    }
  }
`
