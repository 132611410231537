import { gql } from '@apollo/client'
import { AssumptionsFragments } from '@/housefolios-components/AssumptionsSettings/fragments'

export const GET_PORTFOLIOS = gql`
  query portfolios {
    portfolios {
      _id
      name
      strategy
      strategies
      description
      propertyCount
      defaultPortfolio
      assumptions {
        ...assumptions
      }
      organization {
        _id
        name
      }
    }
  }
  ${AssumptionsFragments.assumptions}
`
