import React, { useState } from 'react'
import hero7 from '@/assets/images/hero-bg/hero-7.jpg'

// import MarketingHeaders2 from "../../example-components/MarketingHeaders/MarketingHeaders2";

import Script from 'react-load-script'

import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone'
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone'
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone'
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone'
import LocalLibraryTwoToneIcon from '@mui/icons-material/LocalLibraryTwoTone'
import GridOnTwoToneIcon from '@mui/icons-material/GridOnTwoTone'
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  TextField,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'

export default function PublicLanding() {
  const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY

  const [query, setQuery] = useState('')
  const [placeResult, setPlaceResult] = useState(null)
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState(false)

  const toggleOpen = () => {
    setOpen(!open)
  }

  let autocomplete

  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    var options = {
      types: ['geocode'],
    } // To disable any eslint 'google not defined' error

    // Initialize Google Autocomplete
    /*global google*/ autocomplete =
      new google.maps.places.Autocomplete(
        document.getElementById('autocomplete'),
        options,
      )

    autocomplete.setFields(['address_component', 'geometry'])
    autocomplete.addListener('place_changed', () =>
      setPlaceResult(
        autocomplete.getPlace().address_components
          ? autocomplete.getPlace()
          : null,
      ),
    )
  }

  const searchProperty = () => {
    setLoader(true)
    let location = {
      street_number: 'long_name',
      route: 'long_name',
      administrative_area_level_1: 'short_name',
      locality: 'long_name',
      postal_code: 'long_name',
    }
    if (placeResult) {
      for (
        let i = 0;
        i < placeResult.address_components.length;
        i++
      ) {
        const addressType = placeResult.address_components[i].types[0]
        if (location[addressType])
          location[addressType] =
            placeResult.address_components[i][location[addressType]]
      }
      for (var prop in location) {
        if (Object.prototype.hasOwnProperty.call(location, prop)) {
          if (
            location[prop] === 'long_name' ||
            location[prop] === 'short_name'
          )
            location[prop] = ''
        }
      }
    }

    const streetAddress = placeResult
      ? location.street_number + ' ' + location.route
      : query

    window.location.href = `/publicproperty/Summary?address=${streetAddress}&city=${location.locality}&state=${location.administrative_area_level_1}&zip=${location.postal_code}`
  }

  return (
    <>
      <div className="divider border-2 bg-second border-second" />
      <div className="hero-wrapper bg-composed-wrapper bg-serious-blue">
        <div className="header-top-section pb-3">
          <Container>
            <div className="header-nav-wrapper header-nav-wrapper-lg rounded navbar-dark">
              <div className="app-nav-logo">
                <a
                  href="/"
                  onClick={(e) => e.preventDefault()}
                  title="Housefolios"
                  className="app-nav-logo"
                >
                  <div className="app-nav-logo--icon rounded-sm">
                    <img
                      alt="Housefolios"
                      src={'/images/LeftNavLogo.png'}
                    />
                  </div>
                  <div className="app-nav-logo--text">
                    <span>General</span>

                    <b>bamburgh</b>
                  </div>
                </a>
              </div>
              <div className="header-nav-menu d-none d-lg-block">
                <ul className="d-flex justify-content-center">
                  <li>
                    <a
                      className="rounded-sm"
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="rounded-sm"
                    >
                      Features
                      <span className="opacity-5 dropdown-arrow">
                        <FontAwesomeIcon
                          icon={['fas', 'angle-down']}
                        />
                      </span>
                    </a>
                    <div className="submenu-dropdown submenu-dropdown--xxl">
                      <Grid container spacing={0}>
                        <Grid item lg={6} className="z-over">
                          <div className="shadow-sm-dark bg-white rounded">
                            <div className="px-4 text-uppercase pt-4 pb-2 text-primary font-weight-bold font-size-sm">
                              Applications
                            </div>
                            <List
                              component="div"
                              className="nav-pills nav-neutral-warning nav-lg flex-column px-3 pb-3"
                            >
                              <ListItem
                                component="a"
                                button
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="px-3"
                              >
                                <div className="d-flex">
                                  <BusinessCenterTwoToneIcon className="text-black-50" />
                                  <div className="pl-3 text-black">
                                    <div className="font-weight-bold">
                                      General
                                    </div>
                                    <div className="text-black-50 font-size-sm">
                                      Multi-purpose user interface for
                                      dashboards
                                    </div>
                                  </div>
                                </div>
                              </ListItem>

                              <ListItem
                                component="a"
                                button
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="px-3"
                              >
                                <div className="d-flex">
                                  <PeopleAltTwoToneIcon className="text-black-50" />
                                  <div className="pl-3 text-black">
                                    <div className="font-weight-bold">
                                      Messenger
                                    </div>
                                    <div className="text-black-50 font-size-sm">
                                      Niche application UI for
                                      building chat windows
                                    </div>
                                  </div>
                                </div>
                              </ListItem>

                              <ListItem
                                component="a"
                                button
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="px-3"
                              >
                                <div className="d-flex">
                                  <GridOnTwoToneIcon className="text-black-50" />
                                  <div className="pl-3 text-black">
                                    <div className="font-weight-bold">
                                      Commerce
                                    </div>
                                    <div className="text-black-50 font-size-sm">
                                      Build a commerce related app
                                      with this template
                                    </div>
                                  </div>
                                </div>
                              </ListItem>
                            </List>
                          </div>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          className="d-flex align-items-center"
                        >
                          <div className="shadow-sm-dark w-100 bg-second rounded-right p-3">
                            <List
                              component="div"
                              className="nav-pills nav-neutral-secondary nav-lg flex-column p-0"
                            >
                              <ListItem
                                component="a"
                                button
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="px-3 text-white-50"
                              >
                                <div className="d-flex">
                                  <NotificationsActiveTwoToneIcon className="text-white-50" />
                                  <div className="pl-3 text-white">
                                    <div className="font-weight-bold">
                                      Crypto
                                    </div>
                                    <div className="text-white-50 font-size-sm">
                                      User interface inspired by a
                                      cryptocurrency exchange
                                    </div>
                                  </div>
                                </div>
                              </ListItem>

                              <ListItem
                                component="a"
                                button
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="px-3 text-white-50"
                              >
                                <div className="d-flex">
                                  <LocalLibraryTwoToneIcon className="text-white-50" />
                                  <div className="pl-3 text-white">
                                    <div className="font-weight-bold">
                                      Learning
                                      <span className="ml-3 badge badge-info">
                                        Soon
                                      </span>
                                    </div>
                                    <div className="text-white-50 font-size-sm">
                                      Courses platform template to
                                      start development faster
                                    </div>
                                  </div>
                                </div>
                              </ListItem>

                              <ListItem
                                component="a"
                                button
                                href="#/"
                                onClick={(e) => e.preventDefault()}
                                className="px-3 text-white-50"
                              >
                                <div className="d-flex">
                                  <SettingsTwoToneIcon className="text-white-50" />
                                  <div className="pl-3 text-white">
                                    <div className="font-weight-bold">
                                      Banking
                                      <span className="ml-3 badge badge-info">
                                        Soon
                                      </span>
                                    </div>
                                    <div className="text-white-50 font-size-sm">
                                      Financial dashboard template
                                      inspired by banking apps
                                    </div>
                                  </div>
                                </div>
                              </ListItem>
                            </List>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </li>
                  <li>
                    <a
                      className="rounded-sm"
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                    >
                      Pricing
                    </a>
                  </li>
                </ul>
              </div>
              <div className="header-nav-actions flex-grow-0 flex-lg-grow-1">
                <span className="d-none d-lg-block">
                  <Button
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="text-uppercase font-weight-bold text-nowrap font-size-xs shadow-sm-dark btn-warning"
                  >
                    Start Trial
                  </Button>
                </span>
                <span className="d-block d-lg-none">
                  <button
                    className={clsx(
                      'navbar-toggler hamburger hamburger--elastic',
                    )}
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner" />
                    </span>
                  </button>
                </span>
              </div>
            </div>
          </Container>
        </div>
        <div className="divider bg-white-5" />
        <div className="hero-wrapper--content">
          <div
            className="bg-composed-wrapper--image"
            style={{ backgroundImage: 'url(' + hero7 + ')' }}
          />
          <div className="bg-composed-wrapper--bg bg-premium-dark opacity-4" />
          <div className="bg-composed-wrapper--content">
            <Container className="z-over text-white pt-4">
              <div className="py-5 text-center d-flex justify-content-center">
                <Grid
                  item
                  md={11}
                  lg={10}
                  xl={8}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div>
                    <h2 className="display-3 font-weight-bold">
                      Housefolios Property Analysis
                    </h2>
                    <p className="font-size-xl py-3 text-white-50">
                      Type in an address below to see an analysis of a
                      property! Or create an account to see a full
                      analysis
                    </p>
                    <div className="pt-3">
                      <Button
                        onClick={toggleOpen}
                        size="large"
                        className="btn-pill shadow-second-sm btn-warning"
                      >
                        <span className="btn-wrapper--label">
                          Search for a Property
                        </span>
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon
                            icon={['fas', 'arrow-right']}
                          />
                        </span>
                      </Button>
                    </div>
                  </div>
                </Grid>
              </div>
              <Grid container spacing={6} className="pt-4">
                <Grid item md={6} xl={4}>
                  <Card className="card-box-hover-rise card-box-hover rounded-lg text-center p-3 p-xl-4 mb-xl-5 d-block">
                    <div className="d-130 object-skew hover-scale-sm icon-blob btn-icon text-danger mx-auto">
                      <svg
                        className="blob-wrapper opacity-1"
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g transform="translate(300,300)">
                          <path
                            d="M169,-144C206.7,-87.5,216.5,-18,196.9,35.7C177.3,89.4,128.3,127.1,75.2,150.7C22,174.2,-35.4,183.5,-79.7,163.1C-124,142.7,-155.1,92.6,-164.1,40.9C-173.1,-10.7,-160.1,-64,-129,-118.9C-98,-173.8,-49,-230.4,8.3,-237.1C65.7,-243.7,131.3,-200.4,169,-144Z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                      <div className="blob-icon-wrapper">
                        <NotificationsActiveTwoToneIcon />
                      </div>
                    </div>
                    <h5 className="font-weight-bold font-size-lg text-second mb-2">
                      Gathered Data
                    </h5>
                    <p className="mb-4 text-black-50">
                      When you search an address our software will
                      gather data (ARV, comps, rent, ext.) from a
                      variety of data sources for you
                    </p>
                  </Card>
                </Grid>
                <Grid
                  item
                  md={6}
                  xl={4}
                  className="d-none d-md-block"
                >
                  <Card className="card-box-hover-rise card-box-hover rounded-lg text-center p-3 p-xl-4 mb-xl-5 d-block">
                    <div className="d-130 object-skew hover-scale-sm icon-blob btn-icon text-warning mx-auto">
                      <svg
                        className="blob-wrapper opacity-1"
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g transform="translate(300,300)">
                          <path
                            d="M155,-128.8C192.2,-77,207.7,-13,197.7,50.9C187.7,114.8,152.2,178.6,96.7,208.2C41.1,237.9,-34.6,233.4,-102.6,204C-170.6,174.7,-231.1,120.6,-246.7,55.4C-262.4,-9.9,-233.2,-86.3,-184.6,-140.7C-136,-195.2,-68,-227.6,-4.6,-223.9C58.9,-220.3,117.8,-180.6,155,-128.8Z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                      <div className="blob-icon-wrapper">
                        <SettingsTwoToneIcon />
                      </div>
                    </div>
                    <h5 className="font-weight-bold font-size-lg text-second mb-2">
                      Strategies
                    </h5>
                    <p className="mb-4 text-black-50">
                      The software runs all the number to help you
                      determine which strategy is best. Buy & Hold?
                      Flip? Wholesale? Turnkey?
                    </p>
                  </Card>
                </Grid>
                <Grid
                  item
                  md={6}
                  xl={4}
                  className="d-none d-lg-block"
                >
                  <Card className="card-box-hover-rise card-box-hover rounded-lg text-center p-3 p-xl-4 mb-xl-5 d-block">
                    <div className="d-130 object-skew hover-scale-sm icon-blob btn-icon text-success mx-auto">
                      <svg
                        className="blob-wrapper opacity-1"
                        viewBox="0 0 600 600"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g transform="translate(300,300)">
                          <path
                            d="M170.4,-137.2C213.2,-82.3,234.8,-11.9,223.6,56.7C212.4,125.2,168.5,191.9,104.3,226.6C40.2,261.3,-44.1,264,-104,229.8C-163.9,195.7,-199.4,124.6,-216.2,49.8C-233,-25.1,-231,-103.9,-191.9,-158C-152.7,-212.1,-76.4,-241.6,-6.3,-236.6C63.8,-231.6,127.7,-192.2,170.4,-137.2Z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                      <div className="blob-icon-wrapper">
                        <LocalLibraryTwoToneIcon />
                      </div>
                    </div>
                    <h5 className="font-weight-bold font-size-lg text-second mb-2">
                      Watch Demo Video
                    </h5>
                    <p className="mb-4 text-black-50">
                      Watch a short video covering all the features
                      avaliable to you once you start your trial with
                      us!
                    </p>
                    <a
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="transition-base rounded-pill py-1 px-4 text-lowercase font-size-xs bg-neutral-success text-success d-inline-flex"
                    >
                      watch video
                    </a>
                  </Card>
                </Grid>
              </Grid>
            </Container>
            <div className="shadow-container-blocks-1 z-below">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
              >
                <path
                  fill="var(--light)"
                  fillOpacity="1"
                  d="M0,288L48,250.7C96,213,192,139,288,106.7C384,75,480,85,576,112C672,139,768,181,864,176C960,171,1056,117,1152,128C1248,139,1344,213,1392,250.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        // classes={{ paper: 'modal-content bg-white rounded-lg modal-dark' }}
        open={open}
        onClose={toggleOpen}
      >
        <DialogTitle>Search for a Property</DialogTitle>
        <DialogContent className="p-4">
          <BlockUi
            blocking={loader}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={<div className="text-primary">Connecting</div>}
          >
            <Script
              url={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=geometry,places`}
              onLoad={handleScriptLoad}
            />
            <DialogContentText>
              Type an address below and we will automatically
              gather data and analyze it for you even if it's not on
              the MLS!
            </DialogContentText>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="autocomplete"
                  placeholder="Search Property"
                  label="Address"
                  fullWidth={true}
                  margin="dense"
                  variant="outlined"
                  size="small"
                  name="query"
                  //value={query}
                  onChange={(event) => setQuery(event.target.value)}
                  autoFocus
                  required={true}
                  // helperText={
                  //   googleNotFound
                  //     ? 'Address not found, please try again'
                  //     : ''
                  // }
                />
              </Grid>
            </Grid>
          </BlockUi>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOpen} variant="text">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={searchProperty}
            disabled={!placeResult}
          >
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
