/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-29 10:22:56
 * @ Modified by: Daniel Clark
 * @ Modified time: 2023-03-20 09:38:13
 * @ Description: Various action buttons for different type of notifications
 */

import React, { useState } from 'react'
import startsWith from 'lodash/startsWith'
import includes from 'lodash/includes'
import split from 'lodash/split'
import head from 'lodash/head'
import compact from 'lodash/compact'
import join from 'lodash/join'
import drop from 'lodash/drop'

import mimeDb from 'mime-db'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import AddSharedPortfolio from '@/housefolios-components/Portfolio/AddSharedPortfolio'
import AddSharedMarketplace from '@/housefolios-components/Marketplace/AddSharedMarketplace'
import AddSharedProperty from '@/housefolios-components/Property/AddSharedProperty'
import { Check } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import { useMutation } from '@apollo/client'
import {
  REQUEST_SHARED_PORTFOLIO,
  DENY_REQUEST_SHARED_PORTFOLIO,
  REQUEST_SHARED_MARKETPLACE,
  DENY_REQUEST_SHARED_MARKETPLACE,
  MARK_OLD,
  REQUEST_SHARED_PROPERTY,
  DENY_REQUEST_SHARED_PROPERTY,
  DENY_REQUEST_SHARED_PROPERTIES,
  REQUEST_SHARED_PROPERTIES,
} from './mutations'
import { GET_NOTIFICATIONS } from './queries'
import { SHARE_PORTFOLIO } from '@/housefolios-components/Portfolio/mutations'
import { SHARE_MARKETPLACE } from '@/housefolios-components/Marketplace/mutations'
import { SHARE_PROPERTY } from '@/housefolios-components/Property/mutations'
import AddSharedProperties from '@/housefolios-components/Properties/AddSharedProperties'
import { SHARE_PROPERTIES } from '@/housefolios-components/Properties/mutation'
import FileUpload from '@/housefolios-components/PropertyContainer/PropertyDashboard/FileUpload'
import { GENERIC_SEND_EMAIL } from '@/housefolios-components/Session/mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'

const hostName = import.meta.env.VITE_HOSTNAME

function NotificationsManageButtonComponent(props) {
  const { sectionName, section } = props
  const [requestSharedPortfolio] = useMutation(
    REQUEST_SHARED_PORTFOLIO,
  )
  const [denyRequestSharedPortfolio] = useMutation(
    DENY_REQUEST_SHARED_PORTFOLIO,
  )
  const [sharePortfolio] = useMutation(SHARE_PORTFOLIO)

  const [requestSharedMarketplace] = useMutation(
    REQUEST_SHARED_MARKETPLACE,
  )
  const [denyRequestSharedMarketplace] = useMutation(
    DENY_REQUEST_SHARED_MARKETPLACE,
  )
  const [shareMarketplace] = useMutation(SHARE_MARKETPLACE)

  const [requestSharedProperty] = useMutation(REQUEST_SHARED_PROPERTY)
  const [denyRequestSharedProperty] = useMutation(
    DENY_REQUEST_SHARED_PROPERTY,
  )
  const [shareProperty] = useMutation(SHARE_PROPERTY)

  const [requestSharedProperties] = useMutation(
    REQUEST_SHARED_PROPERTIES,
  )
  const [denyRequestSharedProperties] = useMutation(
    DENY_REQUEST_SHARED_PROPERTIES,
  )
  const [shareProperties] = useMutation(SHARE_PROPERTIES)

  const [anchorEl, setAnchorEl] = useState(null)
  const [menu, setMenu] = useState()

  const openMenu = (event, id) => {
    setAnchorEl(event.target)
    setMenu(id)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMenu(null)
  }

  const [openAddShare, setOpenAddShare] = useState(false)
  const [addShareToken, setAddShareToken] = useState('')
  const [addShareType, setAddShareType] = useState('')

  const handleOpenAdd = (token, type) => {
    setOpenAddShare(true)
    setAddShareToken(token)
    setAddShareType(type)
  }

  const handleCloseAdd = () => {
    setOpenAddShare(false)
    setAddShareToken('')
    setAddShareType('')
  }

  const [openAddJV, setOpenAddJV] = useState(false)
  const [addJV, setAddJV] = useState(null)
  const [addJVAddress, setAddJVAddress] = useState('')

  const handleOpenJV = (address) => {
    setOpenAddJV(true)
    setAddJV(null)
    setAddJVAddress(address)
  }

  const handleCloseJV = () => {
    setOpenAddJV(false)
    setAddJV(null)
    setAddJVAddress('')
  }

  const [markOld] = useMutation(MARK_OLD)

  const [genericSendEmail] = useMutation(GENERIC_SEND_EMAIL)

  const [loader, setLoader] = useState(false)

  return (
    <>
      {startsWith(
        section.action,
        'Could not add shared portfolio',
      ) && (
        <Grid item>
          <Button
            variant="contained"
            disabled={!section.attachment}
            onClick={(event) => {
              event.stopPropagation()
              requestSharedPortfolio({
                variables: {
                  portfolioId: section.attachment,
                  notificationId: section._id,
                },
                refetchQueries: [{ query: GET_NOTIFICATIONS }],
              })
                .then(() => {
                  enqueueSnackbar(`Request Access was sent`, {
                    variant: 'success',
                    autoHideDuration: 3000,
                  })
                })
                .catch(() => {
                  enqueueSnackbar(`Request Access failed to send`, {
                    variant: 'error',
                    autoHideDuration: 3000,
                  })
                })
            }}
          >
            {section.attachment
              ? 'Request Access'
              : 'Request Access was sent'}
          </Button>
        </Grid>
      )}
      {includes(section.action, 'requests access to portfolio') &&
        section.attachment && (
          <Grid item>
            <Button
              variant="contained"
              onClick={(event) => {
                event.stopPropagation()
                openMenu(event, section._id)
              }}
            >
              Manage
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              name={section._id}
              open={Boolean(anchorEl) && menu === section._id}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              classes={{ list: 'p-0' }}
            >
              <MenuItem
                button
                onClick={() => {
                  sharePortfolio({
                    variables: {
                      id: section.attachment,
                      emails: [head(split(section.action, ' '))],
                      notificationId: section._id,
                    },
                    refetchQueries: [{ query: GET_NOTIFICATIONS }],
                  })
                    .then(() => {
                      enqueueSnackbar(`Email Sent`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                      })
                      handleClose()
                    })
                    .catch((error) => {
                      enqueueSnackbar(`Failed to send email`, {
                        variant: 'error',
                        autoHideDuration: 3000,
                      })
                      handleClose(false)
                    })
                }}
              >
                Accept
              </MenuItem>
              <MenuItem
                button
                onClick={() => {
                  denyRequestSharedPortfolio({
                    variables: {
                      portfolioId: section.attachment,
                      email: head(split(section.action, ' ')),
                      notificationId: section._id,
                    },
                    refetchQueries: [{ query: GET_NOTIFICATIONS }],
                  })
                    .then(() => {
                      enqueueSnackbar(`Portfolio was not shared`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                      })
                      handleClose()
                    })
                    .catch((error) => {
                      enqueueSnackbar(
                        `Failed to send notification of denial`,
                        {
                          variant: 'error',
                          autoHideDuration: 3000,
                        },
                      )
                      handleClose(false)
                    })
                }}
              >
                Decline
              </MenuItem>
            </Menu>
          </Grid>
        )}
      {includes(
        section.action,
        'wants to share the following portfolio with you',
      ) && (
        <Grid item>
          <Button
            variant="contained"
            onClick={(event) => {
              event.stopPropagation()
              handleOpenAdd(section.attachment, 'portfolio')
            }}
          >
            Manage
          </Button>
        </Grid>
      )}
      {startsWith(
        section.action,
        'Could not add shared marketplace',
      ) && (
        <Grid item>
          <Button
            variant="contained"
            disabled={!section.attachment}
            onClick={(event) => {
              event.stopPropagation()
              requestSharedMarketplace({
                variables: {
                  url: section.attachment,
                  notificationId: section._id,
                },
                refetchQueries: [{ query: GET_NOTIFICATIONS }],
              })
                .then(() => {
                  enqueueSnackbar(`Request Access was sent`, {
                    variant: 'success',
                    autoHideDuration: 3000,
                  })
                })
                .catch(() => {
                  enqueueSnackbar(`Request Access failed to send`, {
                    variant: 'error',
                    autoHideDuration: 3000,
                  })
                })
            }}
          >
            {section.attachment
              ? 'Request Access'
              : 'Request Access was sent'}
          </Button>
        </Grid>
      )}
      {includes(section.action, 'requests access to marketplace') &&
        section.attachment && (
          <Grid item>
            <Button
              variant="contained"
              onClick={(event) => {
                event.stopPropagation()
                openMenu(event, section._id)
              }}
            >
              Manage
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              name={section._id}
              open={Boolean(anchorEl) && menu === section._id}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              classes={{ list: 'p-0' }}
            >
              <MenuItem
                button
                onClick={() => {
                  shareMarketplace({
                    variables: {
                      url: section.attachment,
                      emails: [head(split(section.action, ' '))],
                      notificationId: section._id,
                    },
                    refetchQueries: [{ query: GET_NOTIFICATIONS }],
                  })
                    .then(() => {
                      enqueueSnackbar(`Email Sent`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                      })
                      handleClose()
                    })
                    .catch((error) => {
                      enqueueSnackbar(`Failed to send email`, {
                        variant: 'error',
                        autoHideDuration: 3000,
                      })
                      handleClose(false)
                    })
                }}
              >
                Accept
              </MenuItem>
              <MenuItem
                button
                onClick={() => {
                  denyRequestSharedMarketplace({
                    variables: {
                      url: section.attachment,
                      email: head(split(section.action, ' ')),
                      notificationId: section._id,
                    },
                    refetchQueries: [{ query: GET_NOTIFICATIONS }],
                  })
                    .then(() => {
                      enqueueSnackbar(`Marketplace was not shared`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                      })
                      handleClose()
                    })
                    .catch((error) => {
                      enqueueSnackbar(
                        `Failed to send notification of denial`,
                        {
                          variant: 'error',
                          autoHideDuration: 3000,
                        },
                      )
                      handleClose(false)
                    })
                }}
              >
                Decline
              </MenuItem>
            </Menu>
          </Grid>
        )}
      {includes(
        section.action,
        'wants to share the following marketplace with you',
      ) && (
        <Grid item>
          <Button
            variant="contained"
            onClick={(event) => {
              event.stopPropagation()
              handleOpenAdd(section.attachment, 'marketplace')
            }}
          >
            Manage
          </Button>
        </Grid>
      )}
      {startsWith(
        section.action,
        'Could not add shared property',
      ) && (
        <Grid item>
          <Button
            variant="contained"
            disabled={!section.attachment}
            onClick={(event) => {
              event.stopPropagation()
              requestSharedProperty({
                variables: {
                  propertyId: section.attachment,
                  notificationId: section._id,
                },
                refetchQueries: [{ query: GET_NOTIFICATIONS }],
              })
                .then(() => {
                  enqueueSnackbar(`Request Access was sent`, {
                    variant: 'success',
                    autoHideDuration: 3000,
                  })
                })
                .catch(() => {
                  enqueueSnackbar(`Request Access failed to send`, {
                    variant: 'error',
                    autoHideDuration: 3000,
                  })
                })
            }}
          >
            {section.attachment
              ? 'Request Access'
              : 'Request Access was sent'}
          </Button>
        </Grid>
      )}
      {startsWith(
        section.action,
        'Could not add shared properties',
      ) && (
        <Grid item>
          <Button
            variant="contained"
            disabled={!section.attachment}
            onClick={(event) => {
              event.stopPropagation()
              requestSharedProperties({
                variables: {
                  propertyIds: split(section.attachment, ','),
                  notificationId: section._id,
                },
                refetchQueries: [{ query: GET_NOTIFICATIONS }],
              })
                .then(() => {
                  enqueueSnackbar(`Request Access was sent`, {
                    variant: 'success',
                    autoHideDuration: 3000,
                  })
                })
                .catch(() => {
                  enqueueSnackbar(`Request Access failed to send`, {
                    variant: 'error',
                    autoHideDuration: 3000,
                  })
                })
            }}
          >
            {section.attachment
              ? 'Request Access'
              : 'Request Access was sent'}
          </Button>
        </Grid>
      )}
      {includes(section.action, 'requests access to property') &&
        section.attachment && (
          <Grid item>
            <Button
              variant="contained"
              onClick={(event) => {
                event.stopPropagation()
                openMenu(event, section._id)
              }}
            >
              Manage
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              name={section._id}
              open={Boolean(anchorEl) && menu === section._id}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              classes={{ list: 'p-0' }}
            >
              <MenuItem
                button
                onClick={() => {
                  shareProperty({
                    variables: {
                      id: section.attachment,
                      emails: [head(split(section.action, ' '))],
                      notificationId: section._id,
                    },
                    refetchQueries: [{ query: GET_NOTIFICATIONS }],
                  })
                    .then(() => {
                      enqueueSnackbar(`Email Sent`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                      })
                      handleClose()
                    })
                    .catch((error) => {
                      enqueueSnackbar(`Failed to send email`, {
                        variant: 'error',
                        autoHideDuration: 3000,
                      })
                      handleClose(false)
                    })
                }}
              >
                Accept
              </MenuItem>
              <MenuItem
                button
                onClick={() => {
                  denyRequestSharedProperty({
                    variables: {
                      propertyId: section.attachment,
                      email: head(split(section.action, ' ')),
                      notificationId: section._id,
                    },
                    refetchQueries: [{ query: GET_NOTIFICATIONS }],
                  })
                    .then(() => {
                      enqueueSnackbar(`Property was not shared`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                      })
                      handleClose()
                    })
                    .catch((error) => {
                      enqueueSnackbar(
                        `Failed to send notification of denial`,
                        {
                          variant: 'error',
                          autoHideDuration: 3000,
                        },
                      )
                      handleClose(false)
                    })
                }}
              >
                Decline
              </MenuItem>
            </Menu>
          </Grid>
        )}
      {includes(section.action, 'requests access to') &&
        includes(section.action, 'properties') &&
        section.attachment && (
          <Grid item>
            <Button
              variant="contained"
              onClick={(event) => {
                event.stopPropagation()
                openMenu(event, section._id)
              }}
            >
              Manage
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              name={section._id}
              open={Boolean(anchorEl) && menu === section._id}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              classes={{ list: 'p-0' }}
            >
              <MenuItem
                button
                onClick={() => {
                  shareProperties({
                    variables: {
                      ids: split(section.attachment, ','),
                      emails: [head(split(section.action, ' '))],
                      notificationId: section._id,
                    },
                    refetchQueries: [{ query: GET_NOTIFICATIONS }],
                  })
                    .then(() => {
                      enqueueSnackbar(`Email Sent`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                      })
                      handleClose()
                    })
                    .catch((error) => {
                      enqueueSnackbar(`Failed to send email`, {
                        variant: 'error',
                        autoHideDuration: 3000,
                      })
                      handleClose(false)
                    })
                }}
              >
                Accept
              </MenuItem>
              <MenuItem
                button
                onClick={() => {
                  denyRequestSharedProperties({
                    variables: {
                      propertyIds: split(section.attachment, ','),
                      email: head(split(section.action, ' ')),
                      notificationId: section._id,
                    },
                    refetchQueries: [{ query: GET_NOTIFICATIONS }],
                  })
                    .then(() => {
                      enqueueSnackbar(`Properties was not shared`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                      })
                      handleClose()
                    })
                    .catch((error) => {
                      enqueueSnackbar(
                        `Failed to send notification of denial`,
                        {
                          variant: 'error',
                          autoHideDuration: 3000,
                        },
                      )
                      handleClose(false)
                    })
                }}
              >
                Decline
              </MenuItem>
            </Menu>
          </Grid>
        )}
      {includes(
        section.action,
        'wants to share the following property with you',
      ) && (
        <Grid item>
          <Button
            variant="contained"
            onClick={(event) => {
              event.stopPropagation()
              handleOpenAdd(section.attachment, 'property')
            }}
          >
            Manage
          </Button>
        </Grid>
      )}
      {includes(section.action, 'wants to share') &&
        includes(section.action, 'properties with you') && (
          <Grid item>
            <Button
              variant="contained"
              onClick={(event) => {
                event.stopPropagation()
                handleOpenAdd(section.attachment, 'properties')
              }}
            >
              Manage
            </Button>
          </Grid>
        )}
      {includes(section.action, 'is interested in a JV for') &&
        includes(
          section.action,
          'Click below for the next steps',
        ) && (
          <Grid item>
            <Button
              variant="contained"
              onClick={(event) => {
                event.stopPropagation()
                let address = split(
                  section.action,
                  'is interested in a JV for',
                )[1]
                address = split(
                  address,
                  'Click below for the next steps',
                )[0]
                handleOpenJV(address)
              }}
            >
              Next Steps
            </Button>
          </Grid>
        )}
      {sectionName === 'New' && (
        <Grid item>
          <IconButton
            onClick={(event) => {
              event.stopPropagation()
              markOld({ variables: { id: section._id } })
            }}
            edge="end"
            size="large"
          >
            <Check />
          </IconButton>
        </Grid>
      )}
      <Dialog
        open={openAddShare}
        onClose={handleCloseAdd}
        fullWidth={true}
        onClick={(event) => event.stopPropagation()}
      >
        <DialogContent>
          {addShareType === 'portfolio' ? (
            <AddSharedPortfolio
              {...props}
              token={addShareToken}
              handleClose={handleCloseAdd}
            />
          ) : addShareType === 'marketplace' ? (
            <AddSharedMarketplace
              {...props}
              token={addShareToken}
              handleClose={handleCloseAdd}
            />
          ) : addShareType === 'properties' ? (
            <AddSharedProperties
              {...props}
              token={addShareToken}
              handleClose={handleCloseAdd}
            />
          ) : (
            <AddSharedProperty
              {...props}
              token={addShareToken}
              handleClose={handleCloseAdd}
            />
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAddJV}
        onClose={handleCloseJV}
        fullWidth={true}
        onClick={(event) => event.stopPropagation()}
      >
        <DialogContent>
          <BlockUi
            blocking={loader}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={
              <div className="text-primary">
                Resetting Organization Assumptions
              </div>
            }
          >
            <Grid container direction="column">
              <Grid item>
                <Typography>Next Steps</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  1. Please download and fill out this{' '}
                  <a
                    href="/images/PR_Investments_JV_Agreement.pdf"
                    download
                    style={{ textDecoration: 'underline' }}
                  >
                    JV Agreement
                  </a>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  2. Upload the filled out version and send it to us.
                </Typography>
                <FileUpload
                  documentId={section._id}
                  files={compact([addJV])}
                  uploadFile={(files) => {
                    setAddJV(head(files))
                  }}
                  deleteFile={() => {
                    setAddJV(null)
                  }}
                  button
                  notSave
                  notMulti={true}
                  collection="jvFile"
                />
              </Grid>
              <Grid item>
                <Typography>3. Wait for us to reach out</Typography>
              </Grid>
            </Grid>
          </BlockUi>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJV}>Close</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!addJV}
            onClick={async () => {
              setLoader(true)
              let base64 = addJV ? addJV.url : null
              const parts = split(base64, ';base64,')
              const mime = join(drop(parts[0], 5), '')
              const mimeData = mimeDb[mime]
              const extension = mimeData.extensions[0]

              if (hostName === 'app.housefolios.com') {
                genericSendEmail({
                  variables: {
                    msg: {
                      to: [
                        { addr: 'jared.garfield@housefolios.com' },
                        { addr: 'gabby.rich@housefolios.com' },
                        { addr: 'jared@housefolios.com' },
                      ],
                      subject: `JV document uploaded from Housefolios`,
                      text: `${props.session.me.activeOrg.name} has uploaded a JV document about ${addJVAddress}`,
                      html: `${props.session.me.activeOrg.name} has uploaded a JV document about ${addJVAddress}`,
                      attachments: [
                        {
                          content: parts[1],
                          type: mime,
                          filename: `JV.${extension}`,
                        },
                      ],
                    },
                  },
                }).then(() => {
                  setLoader(false)
                  enqueueSnackbar(`JV Email was Sent`, {
                    variant: 'success',
                    autoHideDuration: 3000,
                  })
                  handleCloseJV()
                })
              } else {
                genericSendEmail({
                  variables: {
                    msg: {
                      to: [
                        { addr: 'daniel@housefolios.com' },
                        { addr: 'jared@housefolios.com' },
                      ],
                      subject: `Dev/Test JV document uploaded from Housefolios `,
                      text: `${props.session.me.activeOrg.name} has uploaded a JV document about ${addJVAddress}`,
                      html: `${props.session.me.activeOrg.name} has uploaded a JV document about ${addJVAddress}`,
                      attachments: [
                        {
                          content: parts[1],
                          type: mime,
                          filename: `JV.${extension}`,
                        },
                      ],
                    },
                  },
                }).then(() => {
                  setLoader(false)
                  enqueueSnackbar(`JV Email was Sent`, {
                    variant: 'success',
                    autoHideDuration: 3000,
                  })
                  handleCloseJV()
                })
              }
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withSession(NotificationsManageButtonComponent)
