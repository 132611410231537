import React from 'react'

import { ExampleWrapperSeamless } from '@/layout-components'

import FreeAccount from '@/housefolios-components/CreateAccount/FreeAccount'

import { Grid } from '@mui/material'

export default function ForgotPasswordPage() {
  return (
    <>
      <ExampleWrapperSeamless>
        <Grid container justifyContent="center">
          <Grid item xs={12} className="d-flex">
            <FreeAccount />
          </Grid>
        </Grid>
      </ExampleWrapperSeamless>
    </>
  )
}
