/**
 * @ Author: Housefolios
 * @ Create Time: 2021-10-27 15:01:56
 * @ Modified by: David Helmick
 * @ Modified time: 2025-02-21 11:36:54
 * @ Description: BRRRR Strategy Analysis page
 */

import React, { Suspense, lazy, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import Reactour from 'reactour'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

import CountUp from 'react-countup'
import map from 'lodash/map'
import maxBy from 'lodash/maxBy'
import meanBy from 'lodash/meanBy'
import minBy from 'lodash/minBy'
import isUndefined from 'lodash/isUndefined'
import concat from 'lodash/concat'
import sum from 'lodash/sum'
import round from 'lodash/round'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import difference from 'lodash/difference'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import zip from 'lodash/zip'
import slice from 'lodash/slice'
import isNil from 'lodash/isNil'
import replace from 'lodash/replace'
import head from 'lodash/head'
import last from 'lodash/last'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
const Chart = lazy(() => import('react-apexcharts'))

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  LinearProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Drawer,
  IconButton,
  FormControl,
  Collapse,
  Select,
} from '@mui/material'

import dark365Square from '@/assets/images/365_square_Dark.png'

import { arrayMoveImmutable } from 'array-move'
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc'

import ContactAgent from '../../../Marketplace/ContactAgent'
import GaugeChart from 'react-gauge-chart'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import '../../style.css'
import { UPDATE_PROPERTY } from '@/housefolios-components/PropertyContainer/mutations'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'
import { Mutation } from '@apollo/client/react/components'

import PropertyPDF from '../../Proforma/pdf'
import { useMutation } from '@apollo/client'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { Close } from '@mui/icons-material'
import withSession from '@/housefolios-components/Session/withSession'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { enqueueSnackbar } from 'notistack'
import { GET_CARDS } from '@/housefolios-components/Properties/queries'
import Lending from '@/housefolios-components/Dialogs/Lending'

import { assumptionsOptions } from '@/housefolios-components/AssumptionsSettings/Accordion' //SPECIFIC COMMENT: Don't remove. Used for rehab card fields
import { DEFAULT_ANALYTICS } from '@/housefolios-components/Property/fragments'
import { useLocation } from 'react-router-dom'
import useAmortizationDialog from '@/hooks/useAmortizationDialog'
import AmortizationDialog from '@/housefolios-components/Dialogs/Amortization'
import { loanBalance } from '@/utils/loanBalance'

import {
  ContactInformation,
  getCashEntryFee,
  getCreativeEntryFee,
  getPitiTotal,
} from '@/utils/loanBalance'
import SubtoPITICash from '@/housefolios-components/Dialogs/SubtoPITI/Cash/'
import SubtoPITICreative from '@/housefolios-components/Dialogs/SubtoPITI/Creative/'
import CommentsDialog from '@/housefolios-components/Dialogs/Comments'
import { RehabCalcInputField } from '@/utils/rehabCalc'

const pieColors = [
  '#bebebd',
  '#008ffb',
  '#ff6efb',
  '#69A251',
  '#38CDC3',
  '#e3e44f',
  '#feb019',
  '#45818E',
  '#d26767',
]

function CenterPageBRRRR(props) {
  const {
    property,
    analysisInput,
    assumptionsInput,
    renderInputFields,
  } = props

  const {
    openAmortization,
    handleOpenAmortization,
    handleCloseAmortization,
  } = useAmortizationDialog()

  const [openBRRRRStrategyTour, setOpenBRRRRStrategyTour] =
    useState(false)
  const [openBRRRRTargetsTour, setOpenBRRRRTargetsTour] =
    useState(false)
  const [
    openBRRRRMonthlyCashflowTour,
    setOpenBRRRRMonthlyCashflowTour,
  ] = useState(false)
  const [
    openBRRRRAcquisitionExpensesTour,
    setOpenBRRRRAcquisitionExpensesTour,
  ] = useState(false)
  const [
    openBRRRRGrossMonthlyIncomeTour,
    setOpenBRRRRGrossMonthlyIncomeTour,
  ] = useState(false)
  const [
    openBRRRRMonthlyExpensesTour,
    setOpenBRRRRMonthlyExpensesTour,
  ] = useState(false)
  const [openBRRRRRehabExpensesTour, setOpenBRRRRRehabExpensesTour] =
    useState(false)
  const [openBRRRRBridgeLoanTour, setOpenBRRRRBridgeLoanTour] =
    useState(false)
  const [openBRRRRRefinanceLoanTour, setOpenBRRRRRefinanceLoanTour] =
    useState(false)
  const [openBRRRRValueOverTimeTour, setOpenBRRRRValueOverTimeTour] =
    useState(false)
  const [
    openBRRRRCashPositioningTour,
    setOpenBRRRRCashPositioningTour,
  ] = useState(false)

  const BRRRRStrategyTour = ({
    isOpenBRRRRStrategyTour,
    closeBRRRRStrategyTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour-brrrr="strategy-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Want to purchase this property? Fill out the Make
                Offer form to take the next steps!
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBRRRRStrategyTour}
        onRequestClose={closeBRRRRStrategyTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BRRRRTargetsTour = ({
    isOpenBRRRRTargetsTour,
    closeBRRRRTargetsTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="targets-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Net cashflow is the amount of money left after the
                mortgage and any other expenses are paid.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="targets-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                A cash-on-cash return calculates the return on the
                money put in by the investor on a property with a
                mortgage. Commonly referred to as a Leveraged Return.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="targets-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                IRR measures an investment’s annual return throughout
                the entire property ownership time frame. Unlike a
                cash-on-cash return or cap rate that measures returns
                in annual segments, IRR weighs total earnings from the
                day you purchase your property to the day you sell it.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="targets-step-4"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The Yield-on-Cost is the rate of return an investor
                can expect from an investment in real estate, assuming
                they pay all cash.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBRRRRTargetsTour}
        onRequestClose={closeBRRRRTargetsTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BRRRRMonthlyCashflowTour = ({
    isOpenBRRRRMonthlyCashflowTour,
    closeBRRRRMonthlyCashflowTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="monthlyCashflow-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Monthly Income includes Total Rental Income minus the
                Vacancy Rate plus the Other Monthly Income
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="monthlyCashflow-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Monthly Expenses is a sum of all the monthly values
                from the all the fields in the Monthly Expenses card
                below
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="monthlyCashflow-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Monthly Cashflow is your Monthly Income minus your
                Monthly Expenses
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBRRRRMonthlyCashflowTour}
        onRequestClose={closeBRRRRMonthlyCashflowTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BRRRRAcquisitionExpensesTour = ({
    isOpenBRRRRAcquisitionExpensesTour,
    closeBRRRRAcquisitionExpensesTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="acquisitionExpenses-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Check this box to see additional field options such as
                the acquisition commision $/%, Acquisition Fee,
                Financing Fee & Owner Reserve.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="acquisitionExpenses-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                This is how long this property has been on the market.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="acquisitionExpenses-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The price that has been agreed upon by the owner and
                buyer for the transferring of a property.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="acquisitionExpenses-step-4"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The ARV is the value of a property after you have
                conducted repairs and are ready to sell.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="acquisitionExpenses-step-5"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Fees (attorney/title fees, etc) Paid at Closing.
                Expressed as a dollar amount or precentage of the
                purchase prices.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="acquisitionExpenses-step-6"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                A seller credit is the dollar amount the seller will
                put towards your closing costs.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBRRRRAcquisitionExpensesTour}
        onRequestClose={closeBRRRRAcquisitionExpensesTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BRRRRGrossMonthlyIncomeTour = ({
    isOpenBRRRRGrossMonthlyIncomeTour,
    closeBRRRRGrossMonthlyIncomeTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="grossMonthlyIncome-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                A tenant's monthly payment to a landlord for the use
                of property or land.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="grossMonthlyIncome-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The percentage of time that a property is not rented
                out during the course of a year.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="grossMonthlyIncome-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Additional income you earn through the property. Some
                examples might be coin-op laundry, parking, storage,
                etc.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBRRRRGrossMonthlyIncomeTour}
        onRequestClose={closeBRRRRGrossMonthlyIncomeTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BRRRRMonthlyExpensesTour = ({
    isOpenBRRRRMonthlyExpensesTour,
    closeBRRRRMonthlyExpensesTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="monthlyExpenses-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Annual property insurance premium expressed as a
                dollar amount or percentage of the purchase price.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="monthlyExpenses-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Property management fee expressed as a dollar amount
                or percentage of Gross Rental Income (rent - vacancy).
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="monthlyExpenses-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                An organization in a subdivision, planned community,
                or condominium building that makes and enforces rules
                for the properties and its residents. These residents
                automatically become members and are required to pay
                dues, known as HOA fees.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="monthlyExpenses-step-4"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                A tax paid on property owned by an individual or other
                legal entity, such as a corporation.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="monthlyExpenses-step-5"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The annual cost of maintenance repairs to the property
                expressed as a percentage of Gross Rental Income (rent
                - vacancy).
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="monthlyExpenses-step-6"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Includes electricity, water, sewer, gas, garbage, etc.
                Expressed as a dollar amount or a percentage of
                Monthly Gross Rental Income (rent - vacancy).
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="monthlyExpenses-step-7"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The amount of monthly income you want to put aside
                each month as a reserve for future expenses. This
                includes capital expenditure (capex) items such as the
                roof, appliances, water, heater, etc. Expressed as a
                dollar amount or a percentage of Monthly Gross Rental
                Income (rent - vacancy).
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="monthlyExpenses-step-8"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Any annual expense that has not already been labeled.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="monthlyExpenses-step-9"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The amount you pay every month toward your mortgage
                loan. This field is not editable. To change your
                mortgage amount, see the Financing card below.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBRRRRMonthlyExpensesTour}
        onRequestClose={closeBRRRRMonthlyExpensesTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BRRRRRehabExpensesTour = ({
    isOpenBRRRRRehabExpensesTour,
    closeBRRRRRehabExpensesTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="rehabExpenses-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                An expense that is incurred when restoring an entity
                to its normal or near-normal condition.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="rehabExpenses-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The cost of the bridge loan during your rehab period
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBRRRRRehabExpensesTour}
        onRequestClose={closeBRRRRRehabExpensesTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BRRRRBridgeLoanTour = ({
    isOpenBRRRRBridgeLoanTour,
    closeBRRRRBridgeLoanTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="bridgeLoan-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Investor pays 100% of the purchase price in cash and
                does not have a mortgage payment.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="bridgeLoan-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The amount of cash that a purchaser must put down,
                toward the purchase price, to buy real estate. The
                down payment amount is a percentage of the total
                purchase price, dictated by the lender.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="bridgeLoan-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The interest rate is the amount a lender charges for
                the use of capital, expressed as a percentage of the
                principal. The interest rate is typically noted on an
                annual basis known as the annual percentage rate
                (APR).
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="bridgeLoan-step-4"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The length of time that the loan is being amortized.
                Expressed in months or years.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="bridgeLoan-step-5"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Often when you take out a loan, you will pay "points"
                (aka: fees) on the loan. It is essentially prepaid
                interest charged by the lender. One point is equal to
                1% of the loan amount. For example, a $100,000 loan
                with 2 points would be $2,000.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="bridgeLoan-step-6"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                A loan that only requires the payment of interest, not
                principal.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="bridgeLoan-step-7"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Your down payment adjusts what percentage of your
                repairs will be financed.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBRRRRBridgeLoanTour}
        onRequestClose={closeBRRRRBridgeLoanTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BRRRRRefinanceLoanTour = ({
    isOpenBRRRRRefinanceLoanTour,
    closeBRRRRRefinanceLoanTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="refinanceLoan-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Investor pays 100% of the purchase price in cash and
                does not have a mortgage payment.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="refinanceLoan-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The amount of cash that a purchaser must put down,
                toward the purchase price, to buy real estate. The
                down payment amount is a percentage of the total
                purchase price, dictated by the lender.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="refinanceLoan-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The interest rate is the amount a lender charges for
                the use of capital, expressed as a percentage of the
                principal. The interest rate is typically noted on an
                annual basis known as the annual percentage rate
                (APR).
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="refinanceLoan-step-4"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The length of time that the loan is being amortized.
                Expressed in months or years.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="refinanceLoan-step-5"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Often when you take out a loan, you will pay "points"
                (aka: fees) on the loan. It is essentially prepaid
                interest charged by the lender. One point is equal to
                1% of the loan amount. For example, a $100,000 loan
                with 2 points would be $2,000.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="refinanceLoan-step-6"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                A lender's limit of how much they will allow you to
                cash out. Expressed as a percentage of ARV.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="refinanceLoan-step-7"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The actual amount of money you plan to take out of the
                loan
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="refinanceLoan-step-8"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                A loan that only requires the payment of interest, not
                principal.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="refinanceLoan-step-9"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Your down payment adjusts what percentage of your
                repairs will be financed.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBRRRRRefinanceLoanTour}
        onRequestClose={closeBRRRRRefinanceLoanTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BRRRRValueOverTimeTour = ({
    isOpenBRRRRValueOverTimeTour,
    closeBRRRRValueOverTimeTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="valueOverTime-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                A holding period is the amount of time the investment
                is held by an investor or the period between the
                purchase and sale of the property.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="valueOverTime-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The % of Appreciation in Total Dollar Value that the
                Home has gained or lost each year. Expressed as a
                percentage of ARV (after repair value). If your
                holding period is less than 12 months, this field will
                have no effect.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="valueOverTime-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The % that the annual income, from rent, has increased
                each year. Expressed as a percentage of gross rental
                income.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="valueOverTime-step-4"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                The % increase in property expenses per year.
                Expressed as a percentage of gross rental income. If
                your holding period is less than 12 months, this field
                will have no effect.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="valueOverTime-step-5"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Coming soon
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="valueOverTime-step-6"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Includes the rent and expense growth rate values in
                the Property Paid off calculation
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBRRRRValueOverTimeTour}
        onRequestClose={closeBRRRRValueOverTimeTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  const BRRRRCashPositioningTour = ({
    isOpenBRRRRCashPositioningTour,
    closeBRRRRCashPositioningTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = false
    const steps = [
      {
        selector: '[data-tour="cashPositioning-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              {/* //SOURCE COMMENT: Needed Total Return definition https://www.millionacres.com/real-estate-basics/articles/how-to-calculate-a-rental-propertys-return-on-investment/#:~:text=For%20a%20simplified%20example%2C%20if,performance%20after%20you%20sell%20it. */}
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Total return is a combination of income and equity
                appreciation. For a simplified example, if you pay
                $100,000 in cash to acquire a property and it
                generates net income of $10,000 in a year and
                increases in value by $8,000, you have a total return
                of $18,000, or 18%.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="cashPositioning-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              {/* //SOURCE COMMENT: Needed Total Return definition https://www.millionacres.com/real-estate-basics/articles/how-to-calculate-a-rental-propertys-return-on-investment/#:~:text=For%20a%20simplified%20example%2C%20if,performance%20after%20you%20sell%20it. */}
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Total return is a combination of income and equity
                appreciation. For a simplified example, if you pay
                $100,000 in cash to acquire a property and it
                generates net income of $10,000 in a year and
                increases in value by $8,000, you have a total return
                of $18,000, or 18%.
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                setTimeout(() => goToTo(1), 500)
                shifted = true
              }
            }
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenBRRRRCashPositioningTour}
        onRequestClose={closeBRRRRCashPositioningTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }

  let results = useQuery(GET_CARDS, {
    variables: { view: 'BRRRR' },
  })
  let data = results.data || {}
  const cardsLoading = results.loading
  const cardsError = results.error
  let { cards = [] } = data

  const { activeOrg = {} } = props.session.me || {}
  const { acquisition = {}, assumptions = {} } = property
  const { analytics = DEFAULT_ANALYTICS, analysis = {} } = acquisition

  const [activeTab, setActiveTab] = useState('0')

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  let amortizationData = loanBalance({
    financeAmount: analytics.BRRRR.refinanceLoanAmount,
    netOperatingIncome: analytics.BRRRR.netOperatingIncomeBRRRR,
    mortgagePayment: analytics.BRRRR.refinanceMortgage,
    monthlyRate: assumptions.refinanceRate / 100 / 12,
    holdingPeriod: assumptions.rentalHoldingPeriodBRRRR * 12,
    payDownAccelerator: assumptions.payDownAccelerator,
    payDownGrowth: assumptions.payDownGrowth,
    growthRateIncome: assumptions.growthRateIncome,
    growthRateExpense: assumptions.growthRateIncome,
  })

  // const [openAmortization, setOpenAmortization] = useState(false)
  // const handleOpenAmortization = () => {
  //   setOpenAmortization(true)
  // }
  // const handleCloseAmortization = () => {
  //   setOpenAmortization(false)
  // }

  const tableHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Interest', align: 'center' },
    { title: '', name: 'Principal', align: 'center' },
    { title: '', name: 'Additional Principal', align: 'center' },
    { title: '', name: 'Balance', align: 'center' },
  ]

  const dollarPercentAmount = (ofAmount, value, peramt) => {
    let typeData = []
    let dollarAmount = 0
    let percentAmount = 0

    if (peramt === '%') {
      dollarAmount = (value / 100) * ofAmount
      percentAmount = value
    } else if (peramt === '$') {
      dollarAmount = value
      if (ofAmount !== 0)
        percentAmount = ((value / ofAmount) * 100).toFixed(2)
    }

    typeData.push({
      dollar: dollarAmount,
      percent: percentAmount,
    })

    return typeData
  }

  let IRRDataFirst = {}
  let IRRData = []
  let IRRDataLast = {}

  const IRRTable = (args) => {
    let {
      valueAppreciation, //arv ? arv : sellPrice
      grossAnnualRent,
      annualExpenses,
      mp_monthly,
      rentalHoldingPeriod,
      growthRateIncomeMonthly,
      growthRateExpenseMonthly,
      rehabPeriod,
      totalOutOfPocket,
      homePriceAppreciation,
      listPriceToSalePriceRatio,
      salesCommission,
      salesCommissionType,
      sellingClosingCostDollar,
      loanBalanceAtHoldingPeriod,
      dispositionFee,
      dispositionFeeType,
    } = args

    let cashFlowTotal = 0
    let arrInHoldingPeriodMonthly = grossAnnualRent / 12
    let expensesInHoldingPeriodMonthly = annualExpenses / 12
    let cashFlowInHoldingPeriodMonthly = 0
    let salePrice = 0

    let month
    let year
    let monthYear

    const today = new Date()

    const IRRDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1,
    )

    for (let index = 1; index < rentalHoldingPeriod + 1; index++) {
      if (index > 1) {
        arrInHoldingPeriodMonthly *= 1 + growthRateIncomeMonthly
        expensesInHoldingPeriodMonthly *= 1 + growthRateExpenseMonthly
      }
      let income =
        index > rehabPeriod + 1 ? arrInHoldingPeriodMonthly : 0
      cashFlowInHoldingPeriodMonthly =
        mp_monthly < 0
          ? income - expensesInHoldingPeriodMonthly
          : income - expensesInHoldingPeriodMonthly - mp_monthly
      if (index === 1) {
        month = IRRDate.toLocaleString('default', { month: 'long' })
        year = IRRDate.getFullYear().toString()
        monthYear = month + ' ' + year
        IRRDataFirst = {
          totalOutOfPocket,
          monthYear,
        }
      } else {
        valueAppreciation *= 1 + homePriceAppreciation / 12 / 100
        IRRDate.setMonth(IRRDate.getMonth() + 1)
        let month = IRRDate.toLocaleString('default', {
          month: 'long',
        })
        let year = IRRDate.getFullYear().toString()
        monthYear = month + ' ' + year
        if (index < rentalHoldingPeriod) {
          IRRData.push({
            flow: cashFlowInHoldingPeriodMonthly,
            income,
            expenses: expensesInHoldingPeriodMonthly,
            mortgage: mp_monthly,
            monthYear,
          })
        } else if (index === rentalHoldingPeriod) {
          let cashFlowInHoldingPeriod = cashFlowTotal

          salePrice =
            (listPriceToSalePriceRatio / 100) * valueAppreciation
          let salesCommissionTotal =
            salesCommissionType === '$'
              ? salesCommission
              : (salesCommission / 100) * salePrice

          const dispositionFeeIRRDP = dollarPercentAmount(
            salePrice,
            dispositionFee,
            dispositionFeeType,
          )

          const lastMonthCashFlow =
            salePrice -
            loanBalanceAtHoldingPeriod -
            salesCommissionTotal -
            sellingClosingCostDollar -
            dispositionFeeIRRDP[0].dollar +
            cashFlowInHoldingPeriodMonthly

          IRRDataLast = {
            lastMonthCashFlow,
            monthYear,
          }
        }
      }
    }
  }

  IRRTable({
    valueAppreciation: analytics.BRRRR.arvTotalBRRRR
      ? analytics.BRRRR.arvTotalBRRRR
      : analysis.resellPriceBRRRR,
    grossAnnualRent: sum(analytics.grossAnnualRent),
    annualExpenses: analytics.BRRRR.annualExpensesBRRRR,
    mp_monthly: analytics.BRRRR.refinanceMortgage,
    rentalHoldingPeriod: assumptions.rentalHoldingPeriodBRRRR * 12,
    growthRateIncomeMonthly: assumptions.growthRateIncome / 12 / 100,
    growthRateExpenseMonthly:
      assumptions.growthRateExpense / 12 / 100,
    rehabPeriod: assumptions.rehabPeriodBRRRR,
    totalOutOfPocket: analytics.BRRRR.totalOutOfPocketBRRRR,
    homePriceAppreciation: assumptions.homePriceAppreciation,
    listPriceToSalePriceRatio: assumptions.listPriceToSalePriceRatio,
    salesCommission: assumptions.salesCommissionBRRRR,
    salesCommissionType: assumptions.salesCommissionBRRRRType,
    sellingClosingCostDollar:
      analytics.BRRRR.sellingClosingCostDollarBRRRR,
    loanBalanceAtHoldingPeriod:
      analytics.BRRRR.loanBalanceAtHoldingPeriodBRRRR,
    dispositionFee: assumptions.dispositionFeeBRRRR,
    dispositionFeeType: assumptions.dispositionFeeBRRRRType,
  })

  const IRRTableFirstHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Total Out of Pocket', align: 'center' },
  ]

  const IRRTableHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Income', align: 'center' },
    { title: '', name: 'Expenses', align: 'center' },
    { title: '', name: 'Mortgage', align: 'center' },
    { title: '', name: 'Cashflow', align: 'center' },
  ]

  const IRRTableLastHeaders = [
    { title: '', name: 'Date', align: 'left' },
    { title: '', name: 'Last Month Cashflow', align: 'center' },
  ]

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const currencyFormat2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const percentageFormat = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  })

  // const numberFormat = new Intl.NumberFormat('en-US', {
  //   minimumFractionDigits: 0,
  //   maximumFractionDigits: 0,
  // })

  const [openManagementCompanies, setOpenManagementCompanies] =
    useState(false)
  const handleOpenManagementCompanies = () => {
    setOpenManagementCompanies(true)
  }
  const handleCloseManagementCompanies = () => {
    setOpenManagementCompanies(false)
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const [modal1, setModal1] = useState(null)
  const [dealSauceView, setDealSauceView] = useState('creative')
  const [openPITICashData, setOpenPITICashData] = useState(false)
  const [openPITICreativeData, setOpenPITICreativeData] =
    useState(false)

  const pitiTotal = getPitiTotal(property)
  const creativeEntryFee = getCreativeEntryFee(property)
  const cashEntryFee = getCashEntryFee(property)

  const toggle1 = (name) => {
    setModal1(name)
  }

  const toggle1Close = () => {
    setModal1(null)
  }

  const [accordion, setAccordion] = useState(-1)

  const toggleAccordion = (index) => {
    if (index === accordion) setAccordion(-1)
    else setAccordion(index)
  }

  const [maxOfferDescription, setMaxOfferDescription] =
    useState(false)

  const toggleMaxOffer = () => {
    setMaxOfferDescription(!maxOfferDescription)
  }

  const [getFinancing, setGetFinancing] = useState(null)

  const toggleGetFinancing = (name) => {
    setGetFinancing(name)
  }

  const toggleGetFinancingClose = () => {
    setGetFinancing(null)
  }
  const cashFlowAccumulationCalculate = (args) => {
    const {
      accelerator,
      term,
      rentAmount,
      vacancy,
      propertyTaxes,
      homeInsurance,
      monthlyHOA,
      propertyMgmtPercent,
      maintenance,
      utilities,
      annualRevenueIncrease,
      annualOperatingExpenseIncrease,
      //added so that cash flow is with Financing
      mortgagePayment,
    } = args

    let pdAccelerator = accelerator / 100
    let rent = rentAmount
    let rentIncrease = map(rent, (value) => 0)
    let vac = map(
      zip(rentAmount, vacancy),
      ([rentAmount, vacancy]) => rentAmount * vacancy,
    )
    let revenue = 0
    let propTax = propertyTaxes
    let propTaxIncrease = 0
    let insurance = homeInsurance
    let insuranceIncrease = 0
    let hoa = monthlyHOA
    let hoaIncrease = 0
    let propMgmt = sum(rentAmount) * propertyMgmtPercent
    let main = sum(rentAmount) * maintenance
    let mainIncrease = 0
    let uti = utilities
    let utiIncrease = 0
    let expenses = 0
    let cashFlow = 0
    let cfyear = []
    //added so that cash flow is with Financing
    let mortgage = 0

    for (let index = 1; index < term; index++) {
      if (index > 1 && index < 12) {
        rent = map(
          zip(rent, rentAmount),
          ([rent, rentAmount]) => rent + rentAmount,
        )
        propTax += propertyTaxes
        insurance += homeInsurance
        hoa += monthlyHOA
        //added so that cash flow is with Financing
        mortgage += mortgagePayment
      } else if (index % 12 === 0) {
        if (index / 12 === 1) {
          rent = map(
            zip(rent, rentAmount),
            ([rent, rentAmount]) => rent + rentAmount,
          )
          vac = map(
            zip(rent, vacancy),
            ([rent, vacancy]) => rent * vacancy,
          )
          revenue = sum(rent) - sum(vac)

          propTax += propertyTaxes
          insurance += homeInsurance
          hoa += monthlyHOA
          propMgmt = revenue * propertyMgmtPercent
          main = sum(rent) * maintenance
          expenses = propTax + insurance + hoa + propMgmt + main + uti

          //added so that cash flow is with Financing
          mortgage += mortgagePayment

          cashFlow = revenue - expenses - mortgage
          cashFlow = cashFlow - pdAccelerator * cashFlow

          cfyear.push({
            year: index / 12,
            cashFlow: cashFlow,
          })

          rentIncrease = map(
            rentAmount,
            (rentAmount) =>
              rentAmount + rentAmount * annualRevenueIncrease,
          )
          propTaxIncrease =
            propTax * (1 + annualOperatingExpenseIncrease)
          insuranceIncrease =
            insurance * (1 + annualOperatingExpenseIncrease)
          hoaIncrease = hoa * (1 + annualOperatingExpenseIncrease)
          mainIncrease = main * (1 + annualOperatingExpenseIncrease)
          utiIncrease = uti * (1 + annualOperatingExpenseIncrease)
        } else {
          rent = map(
            zip(rent, rentIncrease),
            ([rent, rentIncrease]) => rent + rentIncrease,
          )
          vac = map(
            zip(rent, vacancy),
            ([rent, vacancy]) => rent * vacancy,
          )
          revenue = sum(rent) - sum(vac)

          propTax += propTaxIncrease
          insurance += insuranceIncrease
          hoa += hoaIncrease
          propMgmt = revenue * propertyMgmtPercent
          main += mainIncrease
          uti += utiIncrease
          expenses = propTax + insurance + hoa + propMgmt + main + uti

          //added so that cash flow is with Financing
          mortgage += mortgagePayment

          cashFlow = revenue - expenses - mortgage
          cashFlow = cashFlow - pdAccelerator * cashFlow

          cfyear.push({
            year: index / 12,
            cashFlow: cashFlow,
          })

          rentIncrease = map(
            rentIncrease,
            (rentIncrease) =>
              rentIncrease + rentIncrease * annualRevenueIncrease,
          )
          propTaxIncrease =
            propTaxIncrease * (1 + annualOperatingExpenseIncrease)
          insuranceIncrease =
            insuranceIncrease * (1 + annualOperatingExpenseIncrease)
          hoaIncrease =
            hoaIncrease * (1 + annualOperatingExpenseIncrease)
          mainIncrease =
            mainIncrease * (1 + annualOperatingExpenseIncrease)
          utiIncrease =
            utiIncrease * (1 + annualOperatingExpenseIncrease)
        }
      } else {
        rent = map(
          zip(rent, rentIncrease),
          ([rent, rentIncrease]) => rent + rentIncrease,
        )
        //added so that cash flow is with Financing
        mortgage += mortgagePayment
      }
    }

    return cfyear
  }

  const cashFlowAccumulation = cashFlowAccumulationCalculate({
    accelerator: assumptions.payDownAccelerator || 0,
    term:
      assumptions.termBRRRRType === 'Years'
        ? (assumptions.termBRRRR || 0) * 12 + 1
        : (assumptions.termBRRRR || 0) + 1,
    rentAmount: analysis.rent || [0],
    vacancy: assumptions.vacancy
      ? map(assumptions.vacancy, (vacancy) => vacancy / 100)
      : [0],
    propertyTaxes: (analysis.propertyTaxes || 0) / 12,
    homeInsurance:
      (analytics.BRRRR.refinancePropertyInsuranceDollar || 0) / 12,
    monthlyHOA: analytics.BRRRR.hoaTotal / 12 || 0,
    propertyMgmtPercent:
      (assumptions.propertyManagementBRRRR || 0) / 100,
    maintenance: (assumptions.maintenanceBRRRR || 0) / 100,
    utilities: analytics.BRRRR.utilitiesTypeDollarBRRRR || 0,
    annualRevenueIncrease: (assumptions.growthRateIncome || 0) / 100,
    annualOperatingExpenseIncrease:
      (assumptions.growthRateExpense || 0) / 100,
    mortgagePayment: analytics.BRRRR.refinanceMortgage || 0,
  })

  let cashFlow1 = 0
  let cashFlow3 = 0
  let cashFlow5 = 0
  let cashFlow10 = 0

  for (let key in cashFlowAccumulation) {
    const tmp = cashFlowAccumulation[key]
    if (tmp.year === 1) cashFlow1 = tmp.cashFlow
    if (tmp.year === 3) cashFlow3 = tmp.cashFlow
    if (tmp.year === 5) cashFlow5 = tmp.cashFlow
    if (tmp.year === 10) cashFlow10 = tmp.cashFlow
  }

  const options = {
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['1 yr', '3 yrs', '5 yrs', '10 yrs'],
    },
    yaxis: {
      labels: {
        formatter: currencyFormat.format,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<span>' +
          //[w.globals.labels][dataPointIndex] +
          ['1 yr', '3 yrs', '5 yrs', '10 yrs'][dataPointIndex] +
          ': ' +
          currencyFormat.format(series[seriesIndex][dataPointIndex]) +
          '</span>' +
          '</div>'
        )
      },
    },
  }
  const series = [
    {
      data: [
        round(cashFlow1),
        round(cashFlow3),
        round(cashFlow5),
        round(cashFlow10),
      ],
    },
  ]

  const basicAcquisitionFields = [
    {
      type: 'dollar',
      name: 'offerPrice',
      label: 'Purchase Price',
      value:
        analysisInput.offerPrice === null
          ? //? analysisInput.listPrice.toString()
            ''
          : analysisInput.offerPrice.toString(),
      helper:
        !analysisInput.offerPrice || analysisInput.offerPrice === '0'
          ? '0% Discount to Price'
          : (
              (1 -
                analysisInput.offerPrice / analysisInput.listPrice) *
              100
            ).toFixed(0) + '% Discount to Price',
      // xs: 6,
      xs: 12,
      lg: 6,
      definition: 'offerPrice',
      tooltipField:
        'The price that has been agreed upon by the owner and buyer for the transferring of a property.',
      tooltipButtonText: 'Show Purchase Price data',
      dataTour: 'acquisitionExpenses-step-3',
    },
    {
      type: 'dollar',
      name: includes(
        assumptionsInput.strategySpecificFields,
        'afterRepairValue',
      )
        ? 'afterRepairValueBRRRR'
        : 'afterRepairValue',
      label: 'After Repair Value',
      tooltipButtonText: 'Show ARV data',
      value: includes(
        assumptionsInput.strategySpecificFields,
        'afterRepairValue',
      )
        ? analysisInput.afterRepairValueBRRRR === null
          ? ''
          : analysisInput.afterRepairValueBRRRR.toString()
        : analysisInput.afterRepairValue === null
          ? ''
          : analysisInput.afterRepairValue.toString(),
      //helper: currencyFormat.format(analytics.buyHold.equity) + ' in equity',
      // xs: 6,
      xs: 12,
      lg: 6,
      definition: 'afterRepairValue',
      definitionTarget: includes(
        assumptionsInput.strategySpecificFields,
        'afterRepairValue',
      )
        ? 'afterRepairValueBRRRR'
        : 'afterRepairValue',
      tooltipField:
        'The ARV is the value of a property after you have conducted repairs and are ready to sell.',
      dataTour: 'acquisitionExpenses-step-4',
    },
    {
      type: 'dropdown',
      name: 'closingCostBRRRR',
      value: assumptionsInput.closingCostBRRRR,
      label: 'Closing Cost',
      adornmentToggle: assumptionsInput.closingCostBRRRRType,
      adornment: assumptionsInput.closingCostBRRRRType,
      adornmentName: 'closingCostBRRRRType',
      // xs: 6,
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Fees (attorney/title fees, etc) Paid at Closing. Expressed as a dollar amount or precentage of the purchase prices.',
      dataTour: 'acquisitionExpenses-step-5',
    },
    {
      type: 'dollar',
      name: 'creditFromSeller',
      value: assumptionsInput.creditFromSeller,
      label: 'Credit From Seller',
      // xs: 6,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A seller credit is the dollar amount the seller will put towards your closing costs.',
      dataTour: 'acquisitionExpenses-step-6',
    },
  ]

  const advancedAcquisitionFields = [
    {
      type: 'dropdown',
      name: 'acquisitionCommission',
      label: 'Acq Commission',
      value: assumptionsInput.acquisitionCommission,
      adornmentToggle: assumptionsInput.acquisitionCommissionType,
      adornment: assumptionsInput.acquisitionCommissionType,
      adornmentName: 'acquisitionCommissionType',
      // xs: 6,
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Advanced Fee - Used in addition to sales of typical sales commissions expressed as a percentage of the Purchase price or a fixed dollar amount.',
    },
    {
      type: 'dollar',
      name: 'acquisitionFee',
      label: 'Acquisition Fee',
      value: assumptionsInput.acquisitionFee,
      // xs: 6,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Advanced Fee - Used in addition to sales of typical sales commissions expressed as a fixed dollar amount',
    },
    // {
    //   type: 'percentage',
    //   name: 'assetManagementFee',
    //   label: 'Asset Mgmt Fee',
    //   value: assumptionsInput.assetManagementFee,
    //   xs: 6,
    //   assumptions: true,
    // },
    {
      type: 'dropdown',
      name: 'financingFeeBRRRR',
      label: 'Financing Fee',
      value: assumptionsInput.financingFeeBRRRR,
      adornment: assumptionsInput.financingFeeBRRRRType,
      adornmentToggle: assumptionsInput.financingFeeBRRRRType,
      adornmentName: 'financingFeeBRRRRType',
      // xs: 6,
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Advanced Fee - A fee that the real estate professional charges for securing financing on the property. Expressed as a percentage of the loan amount.',
    },
    {
      type: 'dollar',
      name: 'ownerReservesBRRRR',
      label: '1x Owner Reserve',
      value: assumptionsInput.ownerReservesBRRRR,
      // xs: 6,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A one-time reserve you set aside (ex: $5,000) to cover unforeseen repairs, etc. This amount is in addition to the monthly capex (reserve) you set aside each month.',
    },
  ]

  const customAcquisitonField = [
    {
      type: 'budgetLines',
      name: 'customAcquisitionExpenses',
      value: analysisInput.customAcquisitionExpenses,
      label: 'Custom Acquisition Expenses',
      options: activeOrg.customAcquisitionExpenseTypes,
      xs: 12,
      optionName: 'customAcquisitionExpenseTypes',
      removeOption: true,
    },
  ]

  const propertyManagementCompanies = [
    {
      image:
        'https://assets-global.website-files.com/5ebdcacf6fe55d6b95c4f2a1/5f244161238395a76fe1c352_logo-dark-vertical.svg',
      name: 'Mynd',
      // link: 'https://www.mynd.co/partners/housefolios',
      link: 'https://www.mynd.co',
      value: 'mynd',
    },
    {
      image: dark365Square,
      name: 'home365',
      link: 'https://www.home365.co',
      value: 'home365',
    },
    {
      image: '/images/guardianAsset.jpeg',
      name: 'Guardian Asset',
      link: 'https://guardianassetmgt.com/Home/Contact',
      value: 'guardianAsset',
    },
  ]

  const uncheckAdvanced = every(
    advancedAcquisitionFields,
    (field) => !field.value,
  )

  const [state, setState] = React.useState({
    checkedA: !uncheckAdvanced,
    checkedB: false,
    checkedF: false,
    checkedG: false,
  })

  const acquisitionChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      [
        'Purchase Price',
        'Bridge Closing Costs',
        'Acquisition Commission',
        'Acquisition Fee',
        /*'Asset Mangement Fee',*/ 'Points',
        'Financing Fee',
        'One-time Owner Reserve',
      ],
      map(
        analysis.customAcquisitionExpenses,
        (expense) => expense.value,
      ),
    ),
  }
  const acquisitionChartSeries = concat(
    [
      round(
        analysis.offerPrice === null
          ? analysis.listPrice
          : analysis.offerPrice,
        2,
      ) || 0.0001,
      round(analytics.BRRRR.closeCostDollarBridge, 2) || 0,
      round(analytics.acquisitionCommissionDollar, 2) || 0,
      round(assumptions.acquisitionFee, 2) || 0,
      /*round(analytics.BRRRR.assetManagementFeeTotalBRRRR, 2) || 0,*/ round(
        analytics.BRRRR.bridgePointsAmount +
          analytics.BRRRR.refinancePointsAmount,
        2,
      ) || 0,
      round(analytics.BRRRR.refinancingFeeTotal, 2) || 0,
      round(assumptions.ownerReservesBRRRR, 2) || 0,
    ],
    map(
      analysis.customAcquisitionExpenses,
      (expense) => round(expense.value, 2) || 0,
    ),
  )

  const grossMonthlyIncomeFields = filter(
    [
      {
        type: 'select',
        name: 'rentType',
        label: 'Rent Type',
        value: assumptionsInput.rentType,
        options: assumptionsOptions(activeOrg)?.rentTypes,
        xs: 12,
        lg: 12,
        assumptions: true,
      },
      {
        type: 'dollar',
        name: `rent.${props.activeUnit}`,
        label: 'Total Rental Income',
        tooltipButtonText: 'Show Total Rent data',
        value:
          analysisInput.rent[props.activeUnit] === null
            ? ''
            : analysisInput.rent[props.activeUnit].toString(),
        helper:
          property.sqft &&
          property.sqft[props.activeUnit] &&
          analysisInput.rent[props.activeUnit]
            ? currencyFormat2.format(
                analysisInput.rent[props.activeUnit] /
                  property.sqft[props.activeUnit],
              ) + ' rent per sqft'
            : '$0.00 rent per sqft',
        xs: 12,
        lg: 6,
        definition: 'rent',
        // disabled: !isNil(analysisInput.ownerOccupiedBRRRR) && props.activeUnit === analysisInput.ownerOccupiedBRRRR,
        tooltipField:
          "A tenant's monthly payment to a landlord for the use of property or land.",
        hide: assumptionsInput.rentType !== 'singleRent',
        dataTour: 'grossMonthlyIncome-step-1',
      },
      {
        type: 'dollar',
        name: `rentLow.${props.activeUnit}`,
        label: 'Rent Range Low',
        tooltipButtonText: 'Show Total Rent data',
        value:
          analysisInput.rentLow[props.activeUnit] === null
            ? ''
            : analysisInput.rentLow[props.activeUnit].toString(),
        xs: 12,
        lg: 6,
        definition: 'rent',
        tooltipField: 'Lower end of projected rent',
        hide: assumptionsInput.rentType !== 'rentRange',
        dataTour: '',
      },
      {
        type: 'dollar',
        name: `rentHigh.${props.activeUnit}`,
        label: 'Rent Range High',
        tooltipButtonText: 'Show Total Rent data',
        value:
          analysisInput.rentHigh[props.activeUnit] === null
            ? ''
            : analysisInput.rentHigh[props.activeUnit].toString(),
        xs: 12,
        lg: 6,
        definition: 'rent',
        tooltipField: 'Higher end of projected rent',
        hide: assumptionsInput.rentType !== 'rentRange',
        dataTour: '',
      },
      {
        type: 'percentage',
        name: `vacancy.${props.activeUnit}`,
        label: 'Vacancy Rate',
        value: assumptionsInput.vacancy[props.activeUnit],
        xs: 12,
        lg: 6,
        assumptions: true,
        tooltipField:
          'The percentage of time that a property is not rented out during the course of a year.',
        dataTour: 'grossMonthlyIncome-step-2',
      },
      {
        type: 'dollar',
        name: `otherIncome.${props.activeUnit}`,
        label: 'Other Monthly Income',
        value:
          analysisInput.otherIncome[props.activeUnit] === null
            ? ''
            : analysisInput.otherIncome[props.activeUnit].toString(),
        xs: 12,
        lg: 6,
        tooltipField:
          'Additional income you earn through the property. Some examples might be coin-op laundry, parking, storage, etc.',
        dataTour: 'grossMonthlyIncome-step-3',
      },
    ],
    (field) => !field.hide,
  )

  const grossMonthlyIncomeChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      map(
        analytics.grossAnnualRent,
        (value, index) => `Unit ${index + 1} Monthly Rental Income`,
      ),
      map(
        analysis.otherIncome,
        (value, index) => `Unit ${index + 1} Other Monthly Income`,
      ),
    ),
  }
  const grossMonthlyIncomeChartSeries = concat(
    map(
      analytics.grossAnnualRent,
      (grossAnnualRent, index) =>
        round(grossAnnualRent / 12, 2) || (index === 0 ? 0.0001 : 0),
    ),
    map(
      analysis.otherIncome,
      (otherIncome) => round(otherIncome, 2) || 0,
    ),
  )

  const expenseCard =
    find(
      cards,
      (card) =>
        card.name === 'expenses' && card.member === activeOrg.member,
    ) ||
    find(
      cards,
      (card) => card.name === 'expenses' && card.member === null,
    ) ||
    {}
  const monthlyExpensesFields = map(
    get(expenseCard, 'card.fields'),
    (field) => {
      const input = field.analytics
        ? analytics.BRRRR
        : field.assumptions
          ? assumptionsInput
          : analysisInput
      const name =
        field.name === 'mortgage'
          ? 'refinanceMortgage'
          : field.suffix
            ? includes(field.name, 'Type')
              ? replace(field.name, 'Type', 'BRRRRType')
              : field.name + 'BRRRR'
            : field.name
      const adornmentName =
        field.suffix && field.adornmentName
          ? includes(field.adornmentName, 'Type')
            ? replace(field.adornmentName, 'Type', 'BRRRRType')
            : field.adornmentName + 'BRRRR'
          : field.adornmentName
      const definition =
        field.suffix && field.definition
          ? includes(field.definition, 'Type')
            ? replace(field.definition, 'Type', 'BRRRRType')
            : field.definition + 'BRRRR'
          : field.definition
      const options =
        field.options && field.assumptions
          ? get(assumptionsOptions(activeOrg), field.options) ||
            get(activeOrg, field.options) ||
            field.options
          : get(activeOrg, field.options) || field.options
      return {
        ...field,
        name,
        value: get(input, name),
        adornmentToggle: get(input, adornmentName),
        adornment: get(input, adornmentName),
        adornmentName: adornmentName,
        definition: definition,
        options: options,
      }
    },
  )

  const monthlyExpensesChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      [
        'Property Insurance',
        'Property Mgmt Fee',
        'Monthly HOA',
        'Property Taxes',
        'Maintenance',
        'Utilities',
        'Capex',
        'Other Expenses',
        'Mortgage',
      ],
      map(analysis.customMonthlyExpenses, (expense) => expense.type),
    ),
  }
  const monthlyExpensesChartSeries = concat(
    [
      round(
        analytics.BRRRR.refinancePropertyInsuranceDollar / 12,
        2,
      ) || 0.0001,
      round(analytics.BRRRR.propertyManagementDollarBRRRR / 12, 2) ||
        0,
      round(analytics.BRRRR.hoaTotal / 12, 2) || 0,
      round(analysis.propertyTaxes / 12, 2) || 0,
      round(analytics.BRRRR.maintenanceCostBRRRR / 12, 2) || 0,
      round(analytics.BRRRR.utilitiesTypeDollarBRRRR, 2) || 0,
      round(analytics.BRRRR.monthlyReservesTypeDollarBRRRR, 2) || 0,
      round(assumptions.otherExpenseBRRRR / 12, 2) || 0,
      round(analytics.BRRRR.refinanceMortgage, 2) || 0,
    ],
    map(
      analysis.customMonthlyExpenses,
      (expense) => round(expense.value, 2) || 0,
    ),
  )

  // const rehabCard =
  //   find(
  //     cards,
  //     (card) =>
  //       card.name === 'rehab' && card.member === activeOrg.member,
  //   ) ||
  //   find(
  //     cards,
  //     (card) => card.name === 'rehab' && card.member === null,
  //   ) ||
  //   {}
  // const rehabFields = filter(
  //   map(get(rehabCard, 'card.fields'), (field) => {
  //     const input = field.analytics
  //       ? analytics.BRRRR
  //       : field.assumptions
  //       ? assumptionsInput
  //       : analysisInput
  //     const options =
  //       field.options && field.assumptions
  //         ? get(assumptionsOptions(activeOrg), field.options) ||
  //           get(activeOrg, field.options) ||
  //           field.options
  //         : get(activeOrg, field.options) || field.options
  //     const memberOptions =
  //       field.memberOptions && field.assumptions
  //         ? get(assumptionsOptions(activeOrg), field.memberOptions) ||
  //           get(activeOrg, field.memberOptions)
  //         : get(activeOrg, field.memberOptions)
  //     const name = field.suffix
  //       ? includes(field.name, 'Type')
  //         ? replace(field.name, 'Type', 'BRRRRType')
  //         : field.name + 'BRRRR'
  //       : field.name
  //     const hide =
  //       field.suffix && field.hide
  //         ? includes(field.hide, 'Type')
  //           ? replace(field.hide, 'Type', 'BRRRRType')
  //           : field.hide + 'BRRRR'
  //         : field.hide
  //     const adornmentName =
  //       field.suffix && field.adornmentName
  //         ? includes(field.adornmentName, 'Type')
  //           ? replace(field.adornmentName, 'Type', 'BRRRRType')
  //           : field.adornmentName + 'BRRRR'
  //         : field.adornmentName
  //     const definition =
  //       field.suffix && field.definition
  //         ? includes(field.definition, 'Type')
  //           ? replace(field.definition, 'Type', 'BRRRRType')
  //           : field.definition + 'BRRRR'
  //         : field.definition
  //     const helper = includes(field.name, 'rehabTier')
  //       ? currencyFormat.format(
  //           (field.value ? eval(field.value) : get(input, name)) *
  //             (assumptionsInput.percentageOfARVFixFlip / 100) *
  //             propertyInput.sqft,
  //         ) + ' Total'
  //       : null
  //     return {
  //       ...field,
  //       name,
  //       value: get(input, name),
  //       adornmentToggle: get(input, adornmentName),
  //       adornment: get(input, adornmentName),
  //       adornmentName: adornmentName,
  //       definition: definition,
  //       options: filter(
  //         options,
  //         (option) =>
  //           isEmpty(option.showMarkets) ||
  //           includes(option.showMarkets, analysisInput.marketType),
  //       ),
  //       memberOptions: filter(
  //         memberOptions,
  //         (option) =>
  //           isEmpty(option.showMarkets) ||
  //           includes(option.showMarkets, analysisInput.marketType),
  //       ),
  //       helper,
  //       hide,
  //     }
  //   }),
  //   (field) => !field.hide || !eval(field.hide),
  // )
  const rehabFields = filter(
    [
      {
        type: 'select',
        name: 'rehabBRRRRType',
        label: 'Rehab Type',
        value: assumptionsInput.rehabBRRRRType,
        xs: 12,
        options: assumptionsOptions(activeOrg)?.rehabOptions,
        suffix: true,
        assumptions: true,
      },
      {
        type: 'dollar',
        name: 'rehabCostBRRRR',
        label: 'Est Rehab',
        value: analysisInput.rehabCostBRRRR,
        tooltipButtonText: 'Show Estimated Rehab data',
        xs: 12,
        lg: 6,
        definition: 'rehabCostBRRRR',
        tooltipField:
          'An expense that is incurred when restoring an entity to its normal or near-normal condition.',
        hide: assumptionsInput.rehabBRRRRType !== 'singleRehabValue',
        dataTour: 'rehabExpenses-step-1',
      },
      {
        type: 'budgetLines',
        name: 'detailedRehabCostBRRRR',
        label: 'Rehab Costs',
        value: analysisInput.detailedRehabCostBRRRR,
        xs: 12,
        options: [
          'Appliances',
          'Cabinets/Vanities',
          'Carpet',
          'Countertops/Sinks/Toilets',
          'Driveway/Decking/Patio',
          'Electrical',
          'Exterior',
          'Flooring',
          'Framing',
          'Insulation/Drywall',
          'Landscaping',
          'Masonry',
          'Mechanical',
          'Molding/Tile/Trim',
          'Paint',
          'Plans/Permits',
          'Plumbing',
          'Roof',
          'Siding/Gutters',
          'Site Prep',
          'Staging',
          'Windows/Doors',
          'Other',
        ],
        hide: assumptionsInput.rehabBRRRRType !== 'detailedRehab',
        dataTour: 'rehabExpenses-step-1',
      },
      {
        type: 'rehabCalc',
        name: 'rehabCalcTotalBRRRR',
        label: 'Rehab Calculator',
        value: analysisInput.rehabCalcTotalBRRRR,
        xs: 12,
        lg: 6,
        property: property,
        assumptionsInput: assumptionsInput,
        affiliate: props.affiliate,
        hide: assumptionsInput.rehabBRRRRType !== 'rehabCalc',
        dataTour: 'rehabExpenses-step-1',
      },
      {
        type: 'select',
        name: 'rehabTierBRRRR',
        label: 'Rehab Tier',
        value: assumptionsInput.rehabTierBRRRR,
        tooltipButtonText: 'Show Estimated Rehab data',
        xs: 12,
        lg: 6,
        options: filter(
          activeOrg.rehabTiers,
          (option) =>
            isEmpty(option.showMarkets) ||
            includes(
              option.showMarkets,
              analysisInput.marketType ||
                props.affiliate?.defaultMarketType,
            ),
        ),
        optionName: 'rehabTiers',
        memberOptions: filter(
          activeOrg.memberRehabTiers,
          (option) =>
            isEmpty(option.showMarkets) ||
            includes(
              option.showMarkets,
              analysisInput.marketType ||
                props.affiliate?.defaultMarketType,
            ),
        ),
        dynamicMemberOptions: map(
          filter(
            activeOrg.dynamicMemberRehabTiers,
            (option) =>
              isEmpty(option.showMarkets) ||
              includes(
                option.showMarkets,
                analysisInput.marketType ||
                  props.affiliate?.defaultMarketType,
              ),
          ),
          (option) => {
            let breakPointIndex = 0
            const arv = includes(
              assumptionsInput.strategySpecificFields,
              'afterRepairValue',
            )
              ? analysisInput.afterRepairValueBRRRR === null
                ? null
                : analysisInput.afterRepairValueBRRRR
              : analysisInput.afterRepairValue === null
                ? null
                : analysisInput.afterRepairValue
            while (
              option?.breakpoints[breakPointIndex] < arv &&
              breakPointIndex < option?.breakpoints.length
            ) {
              breakPointIndex++
            }
            return {
              ...option,
              label: `$${option.values[breakPointIndex]}/Sqft`,
            }
          },
        ),
        removeOption: true,
        assumptions: true,
        hide:
          assumptionsInput.rehabBRRRRType !== 'rehabTier' &&
          assumptionsInput.rehabBRRRRType !== 'flippingAmericaRehab',
        dataTour: 'rehabExpenses-step-1',
        helper: currencyFormat.format(
          analytics.BRRRR.rehabTierValueBRRRR,
        ),
      },
      {
        name: 'marketType',
        value: !analysisInput.marketType
          ? props.affiliate?.defaultMarketType
          : analysisInput.marketType,
        label: 'Market Type',
        options: concat(
          [props.affiliate?.defaultMarketType],
          props.affiliate?.marketTypes,
        ),
        type: 'select',
        xs: 12,
        lg: 6,
        hide:
          isEmpty(props.affiliate?.marketTypes) ||
          (assumptionsInput.rehabBRRRRType !== 'rehabTier' &&
            assumptionsInput.rehabBRRRRType !==
              'flippingAmericaRehab'),
      },
      {
        type: 'dollar',
        name: 'rehabDecadeCostBRRRR',
        value: assumptionsInput.rehabDecadeCostBRRRR,
        label: 'Rehab Per Decade',
        xs: 12,
        lg: 6,
        assumptions: true,
        hide:
          assumptionsInput.rehabBRRRRType !== 'flippingAmericaRehab',
      },
      {
        type: 'number',
        name: 'rehabPeriodBRRRR',
        value: assumptionsOptions(activeOrg)?.rehabPeriodBRRRR,
        label: 'Rehab Period (mo)',
        xs: 12,
        lg: 6,
        tooltipField:
          'AnA holding period is the amount of time the investment is held by an investor or the period between the purchase and sale of the property. Expressed in months.',
        assumptions: true,
      },
      {
        type: 'dollar',
        name: 'bridgeRehabHoldingCost',
        value: analytics.BRRRR.bridgeRehabHoldingCost,
        label: 'Holding Cost',
        xs: 12,
        lg: 6,
        disabled: true,
        analytics: true,
        tooltipField:
          'The cost of the bridge loan during your rehab period',
        dataTour: 'rehabExpenses-step-2',
      },
      {
        type: 'dropdown',
        name: 'rehabContingencyBRRRR',
        value: assumptionsInput.rehabContingencyBRRRR,
        adornment: assumptionsInput.rehabContingencyBRRRRType,
        adornmentToggle: assumptionsInput.rehabContingencyBRRRRType,
        adornmentName: 'rehabContingencyBRRRRType',
        label: 'Rehab Contingency',
        xs: 12,
        lg: 6,
        tooltipField:
          'Percentage of calculated rehab cost added as a saftey margin',
        assumptions: true,
        dropdown: true,
        helper:
          assumptionsInput.rehabContingencyBRRRRType === '%'
            ? currencyFormat.format(
                (analytics.BRRRR.corTotalBRRRR *
                  assumptionsInput.rehabContingencyBRRRR) /
                  100,
              )
            : null,
      },
    ],
    (field) => !field.hide,
  )

  const rehabChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: concat(
      assumptionsInput.rehabBRRRRType === 'detailedRehab'
        ? isEmpty(analysis.detailedRehabCostBRRRR)
          ? ['Total Rehab']
          : map(
              analysis.detailedRehabCostBRRRR,
              (line) => line.type || 'Unlabeled',
            )
        : ['Total Rehab'],
      ['Holding Costs During Bridge Loan'],
    ),
  }
  const rehabChartSeries = concat(
    assumptionsInput.rehabBRRRRType === 'detailedRehab'
      ? isEmpty(analysis.detailedRehabCostBRRRR)
        ? [0.0001]
        : map(analysis.detailedRehabCostBRRRR, (line, index) =>
            index === 0
              ? round(line.value, 2) || 0.0001
              : round(line.value, 2) || 0,
          )
      : [round(analytics.BRRRR.corTotalBRRRR, 2) || 0.0001],
    [
      round(
        analytics.BRRRR.holdingExpensesBridge *
          (assumptions.bridgeTermType === 'Years'
            ? assumptions.bridgeTerm * 12
            : assumptions.bridgeTerm),
        2,
      ) || 0,
    ],
  )

  const financingFields = [
    {
      type: 'checkbox',
      label: 'Cash Purchase',
      name: 'bridgeCashPurchase',
      value: assumptionsInput.bridgeCashPurchase,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Investor pays 100% of the purchase price in cash and does not have a mortgage payment.',
      dataTour: 'bridgeLoan-step-1',
    },
    {
      type: 'percentage',
      name: 'bridgeDownPayment',
      label: 'Down Payment',
      value: assumptionsInput.bridgeDownPayment || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
      dataTour: 'bridgeLoan-step-2',
    },
    {
      type: 'percentage',
      name: 'financingRepairsPercentageBRRRR',
      label: '% of Rehab',
      value: analysisInput.financingRepairsPercentageBRRRR || 0,
      xs: 12,
      lg: 6,
      tooltipField:
        'The percentage of the repair cost that is being covered by the loan',
      dataTour: 'bridgeLoan-step-7',
    },
    {
      type: 'percentage',
      name: 'bridgeRate',
      label: 'Interest Rate',
      value: assumptionsInput.bridgeRate || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
      dataTour: 'bridgeLoan-step-3',
    },
    {
      type: 'dropdown',
      name: 'bridgeTerm',
      label: 'Financing Term',
      value: assumptionsInput.bridgeTerm || 0,
      adornment: assumptionsInput.bridgeTermType,
      adornmentToggle: assumptionsInput.bridgeTermType,
      adornmentName: 'bridgeTermType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'The length of time that the loan is being amortized. Expressed in months or years.',
      dataTour: 'bridgeLoan-step-4',
    },
    {
      type: 'number',
      name: 'bridgePoints',
      label: 'Points',
      value: assumptionsInput.bridgePoints || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
      dataTour: 'bridgeLoan-step-5',
    },
    {
      type: 'checkbox',
      name: 'bridgeInterestOnlyLoan',
      label: 'Interest Only Loan',
      value: assumptionsInput.bridgeInterestOnlyLoan,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A loan that only requires the payment of interest, not principal.',
      dataTour: 'bridgeLoan-step-6',
    },
    // {
    //   type: 'checkbox',
    //   name: 'financingRepairsBRRRR',
    //   label: 'Finance Repairs',
    //   value: analysisInput.financingRepairsBRRRR || false,
    //   xs: 12,
    //   lg: 6,
    //   tooltipField: 'Your down payment adjusts what percentage of your repairs will be financed.',
    //   dataTour: "bridgeLoan-step-7",
    // },
  ]

  const refinancingFields = [
    {
      type: 'checkbox',
      label: 'Cash Purchase',
      name: 'refinanceCashPurchase',
      value: assumptionsInput.refinanceCashPurchase,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Investor pays 100% of the purchase price in cash and does not have a mortgage payment.',
      dataTour: 'refinanceLoan-step-1',
    },
    {
      type: 'percentage',
      name: 'refinanceDownPayment',
      label: 'Down Payment',
      value: assumptionsInput.refinanceDownPayment || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The amount of cash that a purchaser must put down, toward the purchase price, to buy real estate. The down payment amount is a percentage of the total purchase price, dictated by the lender.',
      dataTour: 'refinanceLoan-step-2',
    },
    {
      type: 'percentage',
      name: 'refinanceRate',
      label: 'Interest Rate',
      value: assumptionsInput.refinanceRate || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The interest rate is the amount a lender charges for the use of capital, expressed as a percentage of the principal. The interest rate is typically noted on an annual basis known as the annual percentage rate (APR).',
      dataTour: 'refinanceLoan-step-3',
    },
    {
      type: 'dropdown',
      name: 'refinanceTerm',
      label: 'Financing Term',
      value: assumptionsInput.refinanceTerm || 0,
      adornment: assumptionsInput.refinanceTermType,
      adornmentToggle: assumptionsInput.refinanceTermType,
      adornmentName: 'refinanceTermType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'The length of time that the loan is being amortized. Expressed in months or years.',
      dataTour: 'refinanceLoan-step-4',
    },
    {
      type: 'number',
      name: 'refinancePoints',
      label: 'Points',
      value: assumptionsInput.refinancePoints || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'Often when you take out a loan, you will pay "points" (aka: fees) on the loan. It is essentially prepaid interest charged by the lender. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
      dataTour: 'refinanceLoan-step-5',
    },
    {
      type: 'percentage',
      name: 'refinanceCashOut',
      label: 'Cash Out',
      value: assumptionsInput.refinanceCashOut || 0,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        "A lender's limit of how much they will allow you to cash out. Expressed as a percentage of ARV.",
      dataTour: 'refinanceLoan-step-6',
    },
    {
      type: 'dollar',
      name: 'userCashOut',
      label: 'Actual Cash Out',
      value:
        !analysisInput.userCashOut ||
        analysisInput.userCashOut <= 0 ||
        analysisInput.userCashOut >
          analytics.BRRRR.maxRefinanceLoanAmount -
            analytics.BRRRR.bridgeLoanAmount
          ? analytics.BRRRR.maxRefinanceLoanAmount -
              analytics.BRRRR.bridgeLoanAmount >
            0
            ? analytics.BRRRR.maxRefinanceLoanAmount -
              analytics.BRRRR.bridgeLoanAmount
            : 0
          : analysisInput.userCashOut,
      xs: 12,
      lg: 6,
      tooltipField:
        'The actual amount of money you plan to take out of the loan',
      dataTour: 'refinanceLoan-step-7',
    },
    {
      type: 'checkbox',
      name: 'refinanceInterestOnlyLoan',
      label: 'Interest Only Loan',
      value: assumptionsInput.refinanceInterestOnlyLoan,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'A loan that only requires the payment of interest, not principal.',
      dataTour: 'refinanceLoan-step-8',
    },
    // {
    //   type: 'checkbox',
    //   name: 'financingRepairsBRRRR',
    //   label: 'Finance Repairs',
    //   value: analysisInput.financingRepairsBRRRR || false,
    //   xs: 12,
    //   lg: 6,
    //   tooltipField: 'Your down payment adjusts what percentage of your repairs will be financed.',
    //   dataTour: "refinanceLoan-step-9",
    // },
  ]

  const projectionCard =
    find(
      cards,
      (card) =>
        card.name === 'projection' &&
        card.member === activeOrg.member,
    ) ||
    find(
      cards,
      (card) => card.name === 'projection' && card.member === null,
    ) ||
    {}
  const projectionAssumptionsFields = map(
    get(projectionCard, 'card.fields'),
    (field) => {
      const input = field.analytics
        ? analytics.BRRRR
        : field.assumptions
          ? assumptionsInput
          : analysisInput
      const name = field.suffix
        ? includes(field.name, 'Type')
          ? replace(field.name, 'Type', 'BRRRRType')
          : field.name + 'BRRRR'
        : field.name
      const adornmentName =
        field.suffix && field.adornmentName
          ? includes(field.adornmentName, 'Type')
            ? replace(field.adornmentName, 'Type', 'BRRRRType')
            : field.adornmentName + 'BRRRR'
          : field.adornmentName
      const definition =
        field.suffix && field.definition
          ? includes(field.definition, 'Type')
            ? replace(field.definition, 'Type', 'BRRRRType')
            : field.definition + 'BRRRR'
          : field.definition
      return {
        ...field,
        name,
        value: get(input, name),
        adornmentToggle: get(input, adornmentName),
        adornment: get(input, adornmentName),
        adornmentName: adornmentName,
        definition: definition,
      }
    },
  )

  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: ['#1CC943', '#F83244', '#04A3E3'],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<span>' +
          //[w.globals.labels][dataPointIndex] +
          ['Income', 'Expenses', 'Cashflow'][dataPointIndex] +
          ': ' +
          currencyFormat.format(series[seriesIndex][dataPointIndex]) +
          '</span>' +
          '</div>'
        )
      },
    },
    xaxis: {
      categories: ['Inc...', 'Exp...', 'Cash...'],
      labels: {
        rotate: -30,
        rotateAlways: true,
        offsetY: -10,
        formatter: currencyFormat.format,
      },
    },
  }
  const chartSeries = [
    {
      data: [
        round(sum(grossMonthlyIncomeChartSeries)),
        round(sum(monthlyExpensesChartSeries)),
        round(analytics.BRRRR.cashFlowBRRRR / 12),
      ],
      // colors: [
      //   ranges: [
      //     {
      //       from: 100,
      //       to: 0,
      //       color: '#f83145',
      //     },
      //   ],
      // ],
    },
  ]

  const strategySummary = {
    name: 'BRRRR',
    percentage:
      (analytics.BRRRR.onTargetBRRRR +
        analytics.BRRRR.aboveTargetBRRRR) /
      (analytics.BRRRR.belowTargetBRRRR +
        analytics.BRRRR.onTargetBRRRR +
        analytics.BRRRR.aboveTargetBRRRR),
    analytics: [
      {
        value: analytics.BRRRR.cashFlowBRRRR / 12 || '-',
        arrow: analytics.BRRRR.cashFlowBRRRRArrow || 0,
        label: 'Net Cashflow',
        type: 'currency',
        target: assumptions.belowCashFlowBRRRR,
        targetLabel: 'Min Net Cashflow Target',
        targetName: 'belowCashFlowBRRRR',
        tourStep: 'targets-step-1',
        min: 100,
        max: 2000,
        minLabel: '$100',
        maxLabel: '$2,000',
        step: 100,
        valueLabelFormat: (x) =>
          x >= 1000 ? `${x / 1000}k` : `${x}`,
        description: `Net cashflow is the amount of money left after the mortgage and any other expenses are paid.`,
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(grossMonthlyIncomeChartSeries) * 12),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.propertyManagementDollarBRRRR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.BRRRR.maintenanceCostBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR
                        .refinancePropertyInsuranceDollar,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analysis.propertyTaxes, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.BRRRR.hoaTotal, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.assetManagementFeeTotalBRRRR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.utilitiesTypeDollarBRRRR * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.monthlyReservesTypeDollarBRRRR *
                        12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(assumptions.otherExpenseBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.customMonthlyExpenseTotal * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.BRRRR.netOperatingIncomeBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Bridge Mortgage
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -{currencyFormat.format(round(analytics.BRRRR.bridgeMortgage * 12, 0))}
                </Typography>
              </Grid>
            </Grid> */}
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Refinance Mortgage
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.BRRRR.refinanceMortgage * 12, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.BRRRR.cashFlowBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  12 months
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  12
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Monthly Cashflow
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.BRRRR.cashFlowBRRRR / 12, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
      {
        value: analytics.BRRRR.cashOnCashReturnBRRRR || '-',
        arrow: analytics.BRRRR.cashOnCashReturnBRRRRArrow || 0,
        label: 'CoC ROI',
        target: assumptions.belowCashOnCashReturnBRRRR,
        targetLabel: 'Min Cash on Cash Return Target',
        targetName: 'belowCashOnCashReturnBRRRR',
        tourStep: 'targets-step-2',
        min: 0,
        max: 30,
        minLabel: '0%',
        maxLabel: '30%',
        description: `A cash-on-cash return calculates the return on the money put in by the investor on a property with a mortgage. Commonly referred to as a Leveraged Return.`,
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(grossMonthlyIncomeChartSeries) * 12),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.propertyManagementDollarBRRRR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.BRRRR.maintenanceCostBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR
                        .refinancePropertyInsuranceDollar,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analysis.propertyTaxes, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.BRRRR.hoaTotal, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.assetManagementFeeTotalBRRRR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.utilitiesTypeDollarBRRRR * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.monthlyReservesTypeDollarBRRRR *
                        12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(assumptions.otherExpenseBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.customMonthlyExpenseTotal * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.BRRRR.netOperatingIncomeBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Bridge Mortgage
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -{currencyFormat.format(round(analytics.BRRRR.bridgeMortgage * 12, 0))}
                </Typography>
              </Grid>
            </Grid> */}
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Annual Refinance Mortgage
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.BRRRR.refinanceMortgage * 12, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Annual Cashflow
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.BRRRR.cashFlowBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  Total Out-of-Pocket
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  {currencyFormat.format(
                    round(analytics.BRRRR.totalOutOfPocketBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Cash on Cash Return
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {percentageFormat.format(
                    analytics.BRRRR.cashOnCashReturnBRRRR,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
      {
        value: analytics.BRRRR.IRRBRRRR || '-',
        arrow: analytics.BRRRR.IRRBRRRRArrow || 0,
        label: 'IRR',
        target: assumptions.belowIRRBRRRR,
        targetLabel: 'Min IRR Target',
        targetName: 'belowIRRBRRRR',
        tourStep: 'targets-step-3',
        min: 0,
        max: 30,
        minLabel: '0%',
        maxLabel: '30%',
        description: `IRR measures an investment’s annual return throughout the entire property ownership time frame. Unlike a cash-on-cash return or cap rate that measures returns in annual segments, IRR weighs total earnings from the day you purchase your property to the day you sell it.`,
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography
                  style={{ color: '#9198a8', marginBottom: 20 }}
                >
                  Due to the complexity of this calculation, we only
                  show an overview of what goes into calculating the
                  IRR
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Initial Investment (Out of pocket)
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.BRRRR.totalOutOfPocketBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Monthly Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(grossMonthlyIncomeChartSeries)),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Rent Growth Rate
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.growthRateIncome > 1000
                    ? '+1000%'
                    : assumptions.growthRateIncome}
                  %
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Monthly Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(monthlyExpensesChartSeries)),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Expenses Growth Rate
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.growthRateExpense > 1000
                    ? '+1000%'
                    : assumptions.growthRateExpense}
                  %
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  After Repair Value
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(analytics.BRRRR.arvTotalBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Appreciation Rate
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.homePriceAppreciation > 1000
                    ? '+1000%'
                    : assumptions.homePriceAppreciation}
                  %
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Holding Period (years)
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {assumptions.rentalHoldingPeriodBRRRR}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Future property sale
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.refinanceFutureSaleValue,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  IRR
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {analytics.BRRRR.IRRBRRRR > 1000
                    ? '+1000%'
                    : percentageFormat.format(
                        analytics.BRRRR.IRRBRRRR,
                      )}
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>-Annual Expenses</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>-1,308</Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>Net Operating Income</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>14892</Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon icon={['fal', 'divide']} style={{ marginRight: 3 }} />All-in-cost
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon icon={['fal', 'divide']} style={{ marginRight: 3 }} />14,892
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 5, marginBottom: 5 }}>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>Cap Rate</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>12.82%</Typography>
              </Grid>
            </Grid> */}
          </>
        ),
      },
      {
        value: analytics.BRRRR.capRateBRRRR || '-',
        arrow: analytics.BRRRR.capRateBRRRRArrow || 0,
        label: 'Yield-on-Cost',
        target: assumptions.belowCapRateBRRRR,
        targetLabel: 'Min Yield on Cost Target',
        targetName: 'belowCapRateBRRRR',
        tourStep: 'targets-step-4',
        min: 0,
        max: 30,
        minLabel: '0%',
        maxLabel: '30%',
        description: `The Yield-on-Cost is the rate of return an investor can expect from an investment in real estate, assuming they pay all cash.`,
        calculation: (
          <>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  Gross Annual Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  {currencyFormat.format(
                    round(sum(grossMonthlyIncomeChartSeries) * 12),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Property Mgmt
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.propertyManagementDollarBRRRR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Maintenance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.BRRRR.maintenanceCostBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Insurance
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR
                        .refinancePropertyInsuranceDollar,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Taxes
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analysis.propertyTaxes, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -HOA Fees
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(analytics.BRRRR.hoaTotal, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Asset management
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.assetManagementFeeTotalBRRRR,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Utilities
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.utilitiesTypeDollarBRRRR * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Capex
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.BRRRR.monthlyReservesTypeDollarBRRRR *
                        12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Other
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(assumptions.otherExpenseBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -Custom Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  -
                  {currencyFormat.format(
                    round(
                      analytics.customMonthlyExpenseTotal * 12,
                      0,
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Net Operating Income
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {currencyFormat.format(
                    round(analytics.BRRRR.netOperatingIncomeBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  All-in-cost
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: '#9198a8' }}>
                  <FontAwesomeIcon
                    icon={['fal', 'divide']}
                    style={{ marginRight: 3 }}
                  />
                  {currencyFormat.format(
                    round(analytics.BRRRR.allInCostBRRRR, 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#9198a8' }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  Yield-on-Cost
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: 'white' }}>
                  {analytics.BRRRR.capRateBRRRR > 1000
                    ? '+1000%'
                    : percentageFormat.format(
                        analytics.BRRRR.capRateBRRRR,
                      )}
                </Typography>
              </Grid>
            </Grid>
          </>
        ),
      },
    ],
  }

  const financials = [
    {
      value: analytics.BRRRR.allInCostBridge,
      label: 'Bridge All-in Cost',
      description:
        'Total amount of initial investment into a property, including renovation and points paid for financing.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Purchase Price
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  round(analysis.offerPrice || analysis.listPrice, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Commission
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.acquisitionCommissionDollar, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.acquisitionFee, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Bridge Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.closeCostDollarBridge, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Holding Costs During Bridge Loan
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.BRRRR.holdingExpensesBridge *
                      (assumptions.bridgeTermType === 'Years'
                        ? assumptions.bridgeTerm * 12
                        : assumptions.bridgeTerm),
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Bridge Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.bridgePointsAmount, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Rehab Amount
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.repairsOutOfPocketBRRRR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Owner Reserves
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.ownerReservesBRRRR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Custom Acquisition Expenses
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.customAcquisitionExpenseTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                All-in Cost
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(
                  round(analytics.BRRRR.allInCostBridge, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      value: analytics.BRRRR.totalOutOfPocketBridge,
      label: 'Bridge Total Out-of-Pocket',
      description:
        'Total amount of cash that a buyer has put into a property of his/her own capital.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Bridge Down Payment
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  round(analytics.BRRRR.bridgeDownPaymentTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Commission
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.acquisitionCommissionDollar, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.acquisitionFee, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Bridge Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.closeCostDollarBridge, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Rehab Holding Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.BRRRR.holdingExpensesBridge *
                      (assumptions.bridgeTermType === 'Years'
                        ? assumptions.bridgeTerm * 12
                        : assumptions.bridgeTerm),
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Bridge Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.bridgePointsAmount, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Custom Acquisition Expenses
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.customAcquisitionExpenseTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -Credit From Seller
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                -
                {currencyFormat.format(
                  round(assumptions.creditFromSeller, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Rehab Amount
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.repairsOutOfPocketBRRRR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Total Out-of-Pocket
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(
                  round(analytics.BRRRR.totalOutOfPocketBridge, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      value: analytics.BRRRR.allInCostBRRRR,
      label: 'Refinance All-in Cost',
      description:
        'Total amount of initial investment into a property, including renovation and points paid for financing.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Purchase Price
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  round(analysis.offerPrice || analysis.listPrice, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Commission
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.acquisitionCommissionDollar, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Acquisition Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.acquisitionFee, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Bridge Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.closeCostDollarBridge, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Refinance Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.closeCostDollarRefinance, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Holding Costs During Bridge Loan
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(
                    analytics.BRRRR.holdingExpensesBridge *
                      (assumptions.bridgeTermType === 'Years'
                        ? assumptions.bridgeTerm * 12
                        : assumptions.bridgeTerm),
                    0,
                  ),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Bridge Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.bridgePointsAmount, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Refinance Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.refinancePointsAmount, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Rehab Amount
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.repairsOutOfPocketBRRRR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Financing Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.refinancingFeeTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Owner Reserves
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(assumptions.ownerReservesBRRRR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Custom Acquisition Expenses
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.customAcquisitionExpenseTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                All-in Cost
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(
                  round(analytics.BRRRR.allInCostBRRRR, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      value: analytics.BRRRR.totalOutOfPocketRefinance,
      label: 'Refinance Total Out-of-Pocket',
      description:
        'Total amount of cash that a buyer has put into a property of his/her own capital.',
      calculation: (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                Refinance Down Payment
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                {currencyFormat.format(
                  round(analytics.BRRRR.refinanceDownPaymentTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Refinance Closing Costs
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.closeCostDollarRefinance, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Financing Fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.refinancingFeeTotal, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +Refinance Loan Points
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: '#9198a8' }}>
                +
                {currencyFormat.format(
                  round(analytics.BRRRR.refinancePointsAmount, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={{ backgroundColor: '#9198a8' }} />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <Grid item>
              <Typography style={{ color: 'white' }}>
                Total Out-of-Pocket
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'white' }}>
                {currencyFormat.format(
                  round(analytics.BRRRR.totalOutOfPocketRefinance, 0),
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      ),
    },
  ]

  const dispositionFields = [
    {
      type: 'dollar',
      name: 'resellPriceBRRRR',
      label: 'Resale Price',
      value:
        analysisInput.resellPriceBRRRR === null
          ? ''
          : analysisInput.resellPriceBRRRR.toString(),
      xs: 12,
      lg: 6,
      tooltipField:
        'The price you would sell the property for once the rehab is completed.',
      dataTour: 'dispositionExpenses-step-2',
    },
    {
      type: 'dropdown',
      name: 'sellerClosingCostBRRRR',
      label: 'Seller Closing Cost',
      value: assumptionsInput.sellerClosingCostBRRRR,
      adornmentToggle: assumptionsInput.sellerClosingCostBRRRRType,
      adornment: assumptionsInput.sellerClosingCostBRRRRType,
      adornmentName: 'sellerClosingCostBRRRRType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Closing Costs Paid by the Seller. Expressed as a dollar amount or percentage of the resale price',
      dataTour: 'dispositionExpenses-step-3',
    },
    {
      type: 'dropdown',
      name: 'salesCommissionBRRRR',
      label: 'Sales Commission',
      value: assumptionsInput.salesCommissionBRRRR,
      adornmentToggle: assumptionsInput.salesCommissionBRRRRType,
      adornment: assumptionsInput.salesCommissionBRRRRType,
      adornmentName: 'salesCommissionBRRRRType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Sales Commission on the Future Sale of Property. Expressed as a dollar amount or percentage of resale price.',
      dataTour: 'dispositionExpenses-step-4',
    },
    {
      type: 'dropdown',
      name: 'dispositionFeeBRRRR',
      label: 'Disposition Fee',
      value: assumptionsInput.dispositionFeeBRRRR,
      adornmentToggle: assumptionsInput.dispositionFeeBRRRRType,
      adornment: assumptionsInput.dispositionFeeBRRRRType,
      adornmentName: 'dispositionFeeBRRRRType',
      xs: 12,
      lg: 6,
      assumptions: true,
      dropdown: true,
      tooltipField:
        'Advanced Fee - Additional marketing fee on top of sales commission. Expressed as a dollar amount or percentage of resale price.',
      dataTour: 'dispositionExpenses-step-5',
    },
    {
      type: 'percentage',
      name: 'homePriceAppreciation',
      label: 'Home Price Appreciation',
      value: assumptionsInput.homePriceAppreciation,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The % of Appreciation in Total Dollar Value that the Home has gained or lost each year. Expressed as a percentage of ARV (after repair value). If your holding period is less than 12 months, this field will have no effect.',
      dataTour: 'dispositionExpenses-step-6',
    },
    {
      type: 'percentage',
      name: 'growthRateExpense',
      label: 'Expense Growth Rate',
      value: assumptionsInput.growthRateExpense,
      xs: 12,
      lg: 6,
      assumptions: true,
      tooltipField:
        'The % increase in property expenses per year. Expressed as a percentage of gross rental income. If your holding period is less than 12 months, this field will have no effect.',
      dataTour: 'dispositionExpenses-step-7',
    },
    {
      type: 'dollar',
      name: 'loanBalanceAtHoldingPeriodBRRRR',
      label: 'Loan Balance at Resale',
      value:
        analytics.BRRRR.loanBalanceAtHoldingPeriodBRRRR === null
          ? ''
          : analytics.BRRRR.loanBalanceAtHoldingPeriodBRRRR,
      xs: 12,
      lg: 6,
      disabled: true,
      tooltipField:
        'This field is not editable. The remaining loan amount at the end of your flip holding period.',
      dataTour: 'dispositionExpenses-step-8',
    },
  ]

  const dispositionChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              formatter: (value) => currencyFormat.format(value),
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: (value) => {
                const total = sum(value.config.series)
                return currencyFormat.format(total)
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value) => currencyFormat.format(value),
      },
    },
    legend: {
      position: 'bottom',
      show: false,
    },
    colors: pieColors,
    labels: [
      'Seller Closing Cost',
      'Sales Commission',
      'Disposition Fee',
      'Loan Balance at Resale',
    ],
  }
  const dispositionChartSeries = [
    round(analytics.BRRRR.sellingClosingCostDollarBRRRR, 2) || 0.0001,
    round(analytics.BRRRR.salesCommissionTotalBRRRR, 2) || 0,
    round(analytics.BRRRR.dispositionFeeDollarBRRRR, 2) || 0,
    round(analytics.BRRRR.loanBalanceAtHoldingPeriodBRRRR, 2) || 0,
  ]

  const compData = [
    {
      label: 'Avg. Adjusted Value',
      value: isEmpty(props.compValues)
        ? 0
        : meanBy(
            filter(props.compValues, 'propertyPrice'),
            'propertyPrice',
          ),
    },
    {
      range: true,
      label: 'Adjusted Range',
      value1: isEmpty(props.compValues)
        ? 0
        : minBy(props.compValues, 'propertyPrice').propertyPrice /
          1000,
      value2: isEmpty(props.compValues)
        ? 0
        : maxBy(props.compValues, 'propertyPrice').propertyPrice /
          1000,
    },
    {
      label: 'Avg Adjusted Price/sqft',
      value: isEmpty(props.compValues)
        ? 0
        : meanBy(
            filter(props.compValues, 'propertyPerSqft'),
            'propertyPerSqft',
          ),
    },
    {
      label: 'Lowest 3 comp avg',
      value: props.lowestAverage.afterRepairValue,
    },
  ]

  const maxCashOut =
    analytics.BRRRR.maxRefinanceLoanAmount -
      analytics.BRRRR.bridgeLoanAmount >
    0
      ? analytics.BRRRR.maxRefinanceLoanAmount -
        analytics.BRRRR.bridgeLoanAmount
      : 0

  const BRRRRCardsToOrder = [
    {
      name: 'strategy',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Strategy
              </small>
              <b>BRRRR</b>
            </div>
            <div>
              <Tooltip title="Quick glance determination seen on the main page">
                <Button
                  onClick={() => setOpenBRRRRStrategyTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <div className="d-flex flex-column flex-grow-1 justify-content-center w-100">
            <div className="card-header-alt d-flex justify-content-center px-4 pt-4 pb-3" />
            <div className="mx-auto" style={{ paddingBottom: 20 }}>
              <GaugeChart
                id="chartsGauges3B"
                nrOfLevels={6}
                colors={['#f83245', '#f4772e', '#1bc943']}
                arcWidth={0.4}
                hideText
                percent={strategySummary.percentage}
              />
            </div>
            <p
              className="mb-3 text-black-50 text-center"
              style={{ paddingLeft: '10px', paddingRight: '10px' }}
            >
              According to your inputs, a {strategySummary.name}{' '}
              strategy meets{' '}
              {strategySummary.percentage < 0.33 && (
                <b style={{ color: '#f83245' }}>
                  {round(strategySummary.percentage * 100)}%
                </b>
              )}
              {strategySummary.percentage > 0.33 &&
                strategySummary.percentage < 0.66 && (
                  <b style={{ color: '#f4772e' }}>
                    {round(strategySummary.percentage * 100)}%
                  </b>
                )}
              {strategySummary.percentage > 0.66 && (
                <b style={{ color: '#1bc943' }}>
                  {round(strategySummary.percentage * 100)}%
                </b>
              )}{' '}
              of your targets
            </p>
            {!props.shared &&
              activeOrg.member !== 'gorillacapital' && (
                <div className="px-4 pb-4 pt-2">
                  <ContactAgent
                    property={property}
                    propertyPage={true}
                    canDrag={props.canDrag}
                  />
                </div>
              )}
          </div>
        </Card>
      ),
    },
    {
      name: 'targets',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Does it meet your
              </small>
              <b>Targets</b>
            </div>
            <div>
              <Tooltip title="Does this property meet your specified targets?">
                <Button
                  onClick={() => setOpenBRRRRTargetsTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-around"
            onClick={toggleMaxOffer}
            className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
          >
            <Grid item className="gridItem2">
              <Typography
                variant="h3"
                style={{ textAlign: 'center' }}
              >
                {analytics.BRRRR.maxBRRRRPrice
                  ? currencyFormat.format(
                      analytics.BRRRR.maxBRRRRPrice,
                    )
                  : '-'}
              </Typography>
            </Grid>
            <Grid
              item
              className="gridItem2"
              style={{ textAlign: 'center' }}
            >
              <Typography style={{ color: 'grey' }}>
                Max offer based on targets
              </Typography>
            </Grid>
          </Grid>
          <Dialog
            open={maxOfferDescription}
            onClose={toggleMaxOffer}
            maxWidth="lg"
            scroll="body"
            classes={{
              paper:
                'modal-content rounded border-0 bg-white p-3 p-xl-0',
            }}
          >
            {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
            <Grid container spacing={0} direction="row">
              <Grid item sm={5}>
                <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                  <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                    <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                      <div className="text-white mt-3">
                        <Typography
                          style={{ fontSize: 30, fontWeight: 600 }}
                        >
                          Relevant Data
                        </Typography>
                      </div>
                      <>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Purchase Price:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analysisInput.offerPrice
                                  ? analysisInput.offerPrice
                                  : analysisInput.listPrice,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              ARV:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.BRRRR.arvTotalBRRRR,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              All In Cost:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.BRRRR.allInCostBRRRR,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Total Out Of Pocket:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.BRRRR.totalOutOfPocketBRRRR,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Annual Cash Flow:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {currencyFormat.format(
                                analytics.BRRRR.cashFlowBRRRR,
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              Holding Period (yr):
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ color: '#9198a8' }}>
                              {
                                assumptionsInput.rentalHoldingPeriodBRRRR
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item sm={7}>
                <div className="hero-wrapper h-100 br-xl-right-0">
                  <Card className="flex-grow-1 w-100">
                    <CardHeader title="Max Offer" />
                    <Divider />
                    <CardContent>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ marginBottom: 25 }}
                        >
                          <Card
                            style={{
                              backgroundColor: '#eff2f5',
                              borderRadius: 0,
                            }}
                          >
                            <CardContent>
                              <Typography>
                                Max Offer is the highest purchase
                                price that still meets all of your
                                strategy's targets. The Max Offer will
                                show a dash if the calculation is less
                                than zero or no purchase price can
                                meet all targets.
                              </Typography>
                              <Typography>
                                The Max Offer Price is calculated by
                                determining the the highest purchase
                                price that will meet each specific
                                target then taking the lowest value
                                from them. This means that any value
                                that affects a target besides purchase
                                price can affect the Max Offer Price
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={toggleMaxOffer}
                          >
                            Close
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </div>
              </Grid>
            </Grid>
            {/* </BlockUi> */}
          </Dialog>
          <Divider />
          <List component="div" className="list-group-flush">
            {map(strategySummary.analytics, (section) => (
              <>
                <ListItem
                  component="a"
                  button
                  data-tour={section.tourStep}
                  href={null}
                  disableRipple
                  onClick={() => toggle1(section.label)}
                  className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                >
                  <div className="avatar-icon-wrapper avatar-initials d-50 mr-3">
                    {!isUndefined(section.arrow) && (
                      <>
                        {section.arrow ? (
                          section.arrow === 2 ? (
                            <Avatar
                              style={{
                                backgroundColor: '#2296F3',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  color: 'white',
                                }}
                              />
                            </Avatar>
                          ) : (
                            <Avatar
                              style={{
                                backgroundColor: '#46de64',
                              }}
                            >
                              <ArrowUpwardIcon
                                style={{
                                  color: 'white',
                                }}
                              />
                            </Avatar>
                          )
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: '#F05248',
                            }}
                          >
                            <ArrowDownwardIcon
                              style={{
                                color: 'white',
                              }}
                            />
                          </Avatar>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex-fill">
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        className="font-weight-bold text-black"
                      >
                        {section.label}
                      </Grid>
                      <Grid item className="ml-auto">
                        <b>
                          {section.value !== '-'
                            ? section.type === 'currency'
                              ? currencyFormat.format(section.value)
                              : section.value > 10
                                ? '+1000%'
                                : percentageFormat.format(
                                    section.value,
                                  )
                            : section.value}
                        </b>
                      </Grid>
                    </Grid>
                    <LinearProgress
                      variant="determinate"
                      className={
                        section.arrow === 0
                          ? 'progress-xs progress-animated-alt my-2 progress-bar-danger'
                          : 'progress-xs progress-animated-alt my-2 progress-bar-success'
                      }
                      value={
                        !section.target
                          ? 0
                          : section.arrow
                            ? 100
                            : section.type === 'currency'
                              ? (section.value / section.target) * 100
                              : (section.value / section.target) *
                                10000
                      }
                    />
                    <div className="d-flex justify-content-between font-size-xs">
                      {!isUndefined(section.arrow) && (
                        <div
                          className={
                            section.arrow === 0
                              ? 'text-danger'
                              : 'text-success'
                          }
                        >
                          {section.arrow === 0
                            ? 'Below target'
                            : section.arrow === 1
                              ? 'Target met'
                              : 'Above target'}
                        </div>
                      )}
                      <div className="opacity-5">
                        Target:{' '}
                        {section.type === 'currency'
                          ? currencyFormat.format(section.target)
                          : section.target + '%'}
                      </div>
                    </div>
                  </div>
                </ListItem>
                <Dialog
                  open={modal1 === section.label}
                  onClose={toggle1Close}
                  maxWidth="lg"
                  scroll="body"
                  classes={{
                    paper:
                      'modal-content rounded border-0 bg-white p-3 p-xl-0',
                  }}
                >
                  {/* <BlockUi blocking={loader} loader={<PacmanLoader color={'var(--primary)'} loading={true} />}> */}
                  <Grid container spacing={0} direction="row">
                    <Grid item sm={5}>
                      <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100  br-xl-right-0">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                          <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                            <div className="text-white mt-3">
                              <Typography
                                style={{
                                  fontSize: 30,
                                  fontWeight: 600,
                                }}
                              >
                                Calculation
                              </Typography>
                            </div>
                            {section.calculation}
                            <div className="text-white mt-3">
                              <Typography>
                                * Calculation may be a few dollars off
                                due to rounding
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={7}>
                      <div className="hero-wrapper h-100 br-xl-right-0">
                        <Card className="flex-grow-1 w-100">
                          <CardHeader title={section.label} />
                          <Divider />
                          <CardContent>
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Grid
                                item
                                xs={12}
                                style={{ marginBottom: 25 }}
                              >
                                <Card
                                  style={{
                                    backgroundColor: '#eff2f5',
                                    borderRadius: 0,
                                  }}
                                >
                                  <CardContent>
                                    <Typography>
                                      {section.description}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                              {includes(section.label, 'IRR') && (
                                <Grid
                                  item
                                  xs={12}
                                  style={{ marginBottom: 10 }}
                                >
                                  <div className="accordion">
                                    <Card
                                      className={clsx('card-box', {
                                        'panel-open': accordion === 0,
                                      })}
                                    >
                                      <Card>
                                        <div className="card-header">
                                          <div className="panel-title">
                                            <div className="accordion-toggle">
                                              <Button
                                                variant="text"
                                                size="large"
                                                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                                onClick={() =>
                                                  toggleAccordion(0)
                                                }
                                                aria-expanded={
                                                  accordion === 0
                                                }
                                              >
                                                <span>
                                                  Start Month
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={[
                                                    'fas',
                                                    'angle-up',
                                                  ]}
                                                  className="font-size-xl accordion-icon"
                                                />
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        <Collapse
                                          in={accordion === 0}
                                        >
                                          <Paper
                                            style={{
                                              width: '100%',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <TableContainer
                                              style={{
                                                maxHeight: 440,
                                              }}
                                            >
                                              <Table
                                                stickyHeader
                                                aria-label="sticky table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    {IRRTableFirstHeaders.map(
                                                      (row) => (
                                                        <TableCell
                                                          align={
                                                            row.align
                                                          }
                                                        >
                                                          {row.name}
                                                        </TableCell>
                                                      ),
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow
                                                    key={
                                                      IRRDataFirst.name
                                                    }
                                                    style={{
                                                      '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                    }}
                                                  >
                                                    <TableCell
                                                      component="th"
                                                      scope="row"
                                                    >
                                                      {
                                                        IRRDataFirst.monthYear
                                                      }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {currencyFormat2.format(
                                                        IRRDataFirst.totalOutOfPocket,
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Collapse>
                                      </Card>
                                    </Card>
                                    <Card
                                      className={clsx('card-box', {
                                        'panel-open': accordion === 1,
                                      })}
                                    >
                                      <Card>
                                        <div className="card-header">
                                          <div className="panel-title">
                                            <div className="accordion-toggle">
                                              <Button
                                                variant="text"
                                                size="large"
                                                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                                onClick={() =>
                                                  toggleAccordion(1)
                                                }
                                                aria-expanded={
                                                  accordion === 1
                                                }
                                              >
                                                <span>
                                                  Appreciation Months
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={[
                                                    'fas',
                                                    'angle-up',
                                                  ]}
                                                  className="font-size-xl accordion-icon"
                                                />
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        <Collapse
                                          in={accordion === 1}
                                        >
                                          <Paper
                                            style={{
                                              width: '100%',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <TableContainer
                                              style={{
                                                maxHeight: 440,
                                              }}
                                            >
                                              <Table
                                                stickyHeader
                                                aria-label="sticky table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    {IRRTableHeaders.map(
                                                      (row) => (
                                                        <TableCell
                                                          align={
                                                            row.align
                                                          }
                                                        >
                                                          {row.name}
                                                        </TableCell>
                                                      ),
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {IRRData.map(
                                                    (row) => (
                                                      <>
                                                        <TableRow
                                                          key={
                                                            row.name
                                                          }
                                                          style={{
                                                            '&:last-child td, &:last-child th':
                                                              {
                                                                border: 0,
                                                              },
                                                          }}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {
                                                              row.monthYear
                                                            }
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {currencyFormat2.format(
                                                              row.income,
                                                            )}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {currencyFormat2.format(
                                                              row.expenses,
                                                            )}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {currencyFormat2.format(
                                                              row.mortgage,
                                                            )}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {currencyFormat2.format(
                                                              row.flow,
                                                            )}
                                                          </TableCell>
                                                        </TableRow>
                                                      </>
                                                    ),
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Collapse>
                                      </Card>
                                    </Card>
                                    <Card
                                      className={clsx('card-box', {
                                        'panel-open': accordion === 2,
                                      })}
                                    >
                                      <Card>
                                        <div className="card-header">
                                          <div className="panel-title">
                                            <div className="accordion-toggle">
                                              <Button
                                                variant="text"
                                                size="large"
                                                className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                                                onClick={() =>
                                                  toggleAccordion(2)
                                                }
                                                aria-expanded={
                                                  accordion === 2
                                                }
                                              >
                                                <span>
                                                  Final Month
                                                </span>
                                                <FontAwesomeIcon
                                                  icon={[
                                                    'fas',
                                                    'angle-up',
                                                  ]}
                                                  className="font-size-xl accordion-icon"
                                                />
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        <Collapse
                                          in={accordion === 2}
                                        >
                                          <Paper
                                            style={{
                                              width: '100%',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <TableContainer
                                              style={{
                                                maxHeight: 440,
                                              }}
                                            >
                                              <Table
                                                stickyHeader
                                                aria-label="sticky table"
                                              >
                                                <TableHead>
                                                  <TableRow>
                                                    {IRRTableLastHeaders.map(
                                                      (row) => (
                                                        <TableCell
                                                          align={
                                                            row.align
                                                          }
                                                        >
                                                          {row.name}
                                                        </TableCell>
                                                      ),
                                                    )}
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow
                                                    key={
                                                      IRRDataLast.name
                                                    }
                                                    style={{
                                                      '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                    }}
                                                  >
                                                    <TableCell
                                                      component="th"
                                                      scope="row"
                                                    >
                                                      {
                                                        IRRDataLast.monthYear
                                                      }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {currencyFormat2.format(
                                                        IRRDataLast.lastMonthCashFlow,
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Collapse>
                                      </Card>
                                    </Card>
                                  </div>
                                </Grid>
                              )}
                              {renderInputFields({
                                type: 'slider',
                                name: section.targetName,
                                label: section.targetLabel,
                                value:
                                  assumptionsInput[
                                    section.targetName
                                  ],
                                xs: 12,
                                assumptions: true,
                                valueLabelFormat:
                                  section.valueLabelFormat
                                    ? section.valueLabelFormat
                                    : (x) =>
                                        x === section.max
                                          ? `${x}+`
                                          : `${x}%`,
                                step: section.step,
                                min: section.min,
                                max: section.max,
                              })}
                            </Grid>
                          </CardContent>
                          <Divider />
                          <CardActions>
                            <Grid container justifyContent="center">
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={toggle1Close}
                                >
                                  Close
                                </Button>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </div>
                    </Grid>
                  </Grid>
                  {/* </BlockUi> */}
                </Dialog>
              </>
            ))}
          </List>
        </Card>
      ),
    },
    {
      name: 'monthlyCashflow',
      card: (
        <Card className="card-box w-100">
          <div className="card-header">
            <div className="card-header--title">
              <small className="d-block text-uppercase mt-1">
                Summary
              </small>
              <b>Monthly Cashflow</b>
            </div>
            <div>
              <Tooltip title="Shows the income, expenses, and projected overall cashflow of the property">
                <Button
                  onClick={() =>
                    setOpenBRRRRMonthlyCashflowTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid container className="text-black-50 font-size-sm">
                <Grid
                  item
                  md={4}
                  data-tour="monthlyCashflow-step-1"
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">Income</div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <div className="badge badge-success badge-circle mr-2">
                          fast
                        </div>
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              sum(grossMonthlyIncomeChartSeries) /
                              1000
                            }
                            duration={1}
                            delay={2}
                            decimals={1}
                            decimal="."
                            prefix="$"
                            suffix="k"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={4}
                  data-tour="monthlyCashflow-step-2"
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">Expenses</div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <div className="badge badge-danger badge-circle mr-2">
                          normal
                        </div>
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              sum(monthlyExpensesChartSeries) / 1000
                            }
                            duration={1}
                            delay={2}
                            decimals={1}
                            decimal="."
                            prefix="$"
                            suffix="k"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={4}
                  data-tour="monthlyCashflow-step-3"
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">Cashflow</div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <div className="badge badge-primary badge-circle mr-2">
                          slow
                        </div>
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              analytics.BRRRR.cashFlowBRRRR /
                              12 /
                              1000
                            }
                            duration={1}
                            delay={2}
                            decimals={1}
                            decimal="."
                            prefix="$"
                            suffix="k"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <>
              <Suspense fallback={<div>Loading Chart...</div>}>
                <Chart
                  options={chartOptions}
                  series={chartSeries}
                  type="bar"
                  width="100%"
                  height="auto"
                />
              </Suspense>
            </>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'acquisition',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Acquisition Expenses</b>
            </div>
            <div>
              <Tooltip title="The total projected expenses required to purchase the property">
                <Button
                  onClick={() =>
                    setOpenBRRRRAcquisitionExpensesTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={acquisitionChartOptions}
                      series={acquisitionChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  /*alignItems='center'*/ spacing={1}
                >
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item data-tour="acquisitionExpenses-step-1">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="Advanced Fields"
                              checked={state.checkedA}
                              onChange={handleChange}
                              name="checkedA"
                            />
                          }
                          label="Advanced Fields"
                        />
                      </FormGroup>
                    </Grid>
                    {(!props.publicDashboard ||
                      !property.hideDaysOnMarketplace) && (
                      <Grid
                        item
                        data-tour="acquisitionExpenses-step-2"
                      >
                        <Tooltip title="Refers to the number of days a house is listed on the market and the day it is sold.">
                          <Typography
                            style={{
                              fontSize: 14,
                              textAlign: 'right',
                              backgroundColor: '#e8ecef',
                              padding: 5,
                            }}
                          >
                            <FontAwesomeIcon
                              icon={['far', 'clock']}
                              style={{ marginRight: 5 }}
                            />
                            {analysisInput.daysOnMarket} days on
                            market
                          </Typography>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                  {map(
                    state.checkedA
                      ? concat(
                          basicAcquisitionFields,
                          advancedAcquisitionFields,
                          customAcquisitonField,
                        )
                      : concat(
                          basicAcquisitionFields,
                          customAcquisitonField,
                        ),
                    (field) => renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'income',
      card: (
        <Card className="card-box w-100" style={{ width: '100%' }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Grid item xs={8} style={{ overflow: 'scroll' }}>
              <List
                className="nav-line d-flex"
                style={{
                  width:
                    analysisInput.rent.length > 1
                      ? analysisInput.rent.length * 100
                      : '100%',
                }}
              >
                {analysisInput.rent.length > 1 ? (
                  map(analysisInput.rent, (value, index) => (
                    <ListItem
                      button
                      disableRipple
                      selected={props.activeUnit === index}
                      onClick={() => {
                        props.setActiveUnit(index)
                      }}
                      className="mt-3"
                    >
                      Unit {index + 1}
                    </ListItem>
                  ))
                ) : (
                  <ListItem
                    className="my-1"
                    style={{ width: '100%' }}
                  >
                    <div className="card-header--title">
                      <b>Gross Monthly Income</b>
                    </div>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item style={{ marginRight: 20 }}>
              <Tooltip title="Total income generated from the property on a monthly basis">
                <Button
                  onClick={() =>
                    setOpenBRRRRGrossMonthlyIncomeTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={grossMonthlyIncomeChartOptions}
                      series={grossMonthlyIncomeChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  spacing={1}
                >
                  {map(grossMonthlyIncomeFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'expenses',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Monthly Expenses</b>
            </div>
            <div>
              <Tooltip title="Projected monthly expenditures and applicable costs associated with the property">
                <Button
                  onClick={() =>
                    setOpenBRRRRMonthlyExpensesTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={monthlyExpensesChartOptions}
                      series={monthlyExpensesChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(monthlyExpensesFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid item container direction="row" spacing={1}>
                  {!isEmpty(property.propertyManagementGroups) && (
                    <Grid item xs={12}>
                      <Button
                        fullWidth={true}
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={handleOpenManagementCompanies}
                      >
                        Property Management Companies
                      </Button>
                    </Grid>
                  )}
                  {/* {activeOrg.member === 'birmingham' && ( */}
                  <Grid item xs={12}>
                    <Button
                      fullWidth={true}
                      variant="contained"
                      size="small"
                      color="primary"
                      target="_blank"
                      href="https://jjc.arcanainsurancehub.com"
                      style={{ marginTop: 25 }}
                    >
                      Get Insurance Quote
                    </Button>
                  </Grid>
                  {/* )} */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'rehab',
      card: (
        <Card className="card-box w-100" style={{ width: '100%' }}>
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Rehab Expenses</b>
            </div>
            <div>
              <Tooltip title="Estimated expenses allocated to the renovations of a property">
                <Button
                  onClick={() => setOpenBRRRRRehabExpensesTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={rehabChartOptions}
                      series={rehabChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(rehabFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'financing',
      card: (
        <Card className="card-box w-100">
          {/* <div className="card-header" style={{ marginBottom: 5, marginTop: 5 }}>
            <div className="card-header--title">
              <b>Financingg</b>
            </div>
            <div>
              <Tooltip title="Projected fund totals an investor will need to produce in order to acquire the property">
                <Button className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div> */}
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <List component="div" className="nav-line d-flex mt-3">
                <ListItem
                  button
                  disableRipple
                  selected={activeTab === '0'}
                  onClick={() => {
                    toggle('0')
                  }}
                >
                  <span className="card-header--title">
                    Bridge Loan
                  </span>
                  <div className="divider" style={{ width: '80%' }} />
                </ListItem>
                <ListItem
                  button
                  disableRipple
                  selected={activeTab === '1'}
                  onClick={() => {
                    toggle('1')
                  }}
                >
                  <span className="card-header--title">
                    Refinance Loan
                  </span>
                  <div className="divider" style={{ width: '80%' }} />
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <Tooltip title="Projected fund totals an investor will need to produce in order to acquire the property">
                <Button
                  onClick={() =>
                    activeTab === '0'
                      ? setOpenBRRRRBridgeLoanTour(true)
                      : setOpenBRRRRRefinanceLoanTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 mr-4 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: -12 }} />
          <CardContent>
            {activeTab === '0' && (
              <>
                <Grid
                  container
                  className="rounded p-2 mb-2 bg-secondary text-center border-light border-1"
                >
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">Loan</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-success badge-circle mr-2">
                        fast
                        </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={analytics.BRRRR.bridgeLoanAmount}
                              duration={1}
                              separator=","
                              decimals={0}
                              prefix="$"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">
                        Mortgage/mo
                      </div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-warning badge-circle mr-2">
                        normal
                        </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={analytics.BRRRR.bridgeMortgage}
                              duration={1}
                              delay={2}
                              separator=","
                              decimals={0}
                              prefix="$"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">DSCR</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-danger badge-circle mr-2">
                        slow
                        </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                analytics.BRRRR
                                  .debtCoverageRatioBridge
                              }
                              duration={1}
                              delay={2}
                              separator=","
                              decimals={2}
                              suffix="x"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <p className="mb-3 text-black-50 text-center">
                  Based on your{' '}
                  <b>
                    {currencyFormat.format(
                      analytics.BRRRR.allInCostBridge,
                    )}
                  </b>{' '}
                  <Tooltip title="The total amount of money this deal costs, including rehab, closing costs, points, etc.">
                    <span
                      className="text-first"
                      onClick={() => toggle1('Bridge All-in Cost')}
                      style={{ textDecoration: 'underline' }}
                    >
                      all-in cost
                    </span>
                  </Tooltip>
                  , you'll need to pay{' '}
                  <b>
                    {currencyFormat.format(
                      analytics.BRRRR.totalOutOfPocketBridge,
                    )}
                  </b>{' '}
                  <Tooltip title="Total amount that the buyer has to cover with their own money.">
                    <span
                      className="text-first"
                      onClick={() =>
                        toggle1('Bridge Total Out-of-Pocket')
                      }
                      style={{ textDecoration: 'underline' }}
                    >
                      out-of-pocket
                    </span>
                  </Tooltip>{' '}
                  to cover the downpayment, closing costs, and rehab
                  for this property.
                </p>
                <Divider
                  style={{ marginBottom: 15, marginTop: 15 }}
                />
                <Grid container>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      {map(financingFields, (section) =>
                        renderInputFields(section),
                      )}
                    </Grid>
                    {(!activeOrg.isAffiliate ||
                      (activeOrg.isAffiliate?.memberValue !==
                        'flippingamerica' &&
                        activeOrg.isAffiliate !==
                          'gorillacapital')) &&
                      activeOrg.member !== 'flippingamerica' &&
                      activeOrg.member !== 'gorillacapital' && (
                        <Lending
                          property={property}
                          shared={props.shared}
                          canDrag={props.canDrag}
                        />
                      )}
                  </Grid>
                </Grid>
              </>
            )}
            {activeTab === '1' && (
              <>
                <Grid
                  container
                  className="rounded p-2 mb-2 bg-secondary text-center border-light border-1"
                >
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">Loan</div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-success badge-circle mr-2">
                        fast
                        </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={
                                analytics.BRRRR.refinanceLoanAmount
                              }
                              duration={1}
                              separator=","
                              decimals={0}
                              prefix="$"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">
                        Mortgage/mo
                      </div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-warning badge-circle mr-2">
                        normal
                        </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={analytics.BRRRR.refinanceMortgage}
                              duration={1}
                              delay={2}
                              separator=","
                              decimals={0}
                              prefix="$"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    className="d-flex justify-content-center"
                  >
                    <div>
                      <div className="text-dark pb-1">
                        Max Cash Out
                      </div>
                      <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                        <div className="d-flex p-1 align-items-center">
                          {/* <div className="badge badge-danger badge-circle mr-2">
                        slow
                        </div> */}
                          <span className="d-20 w-auto">
                            <CountUp
                              start={0}
                              end={maxCashOut}
                              duration={1}
                              delay={2}
                              separator=","
                              decimals={0}
                              prefix="$"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <p className="mb-3 text-black-50 text-center">
                  Based on your{' '}
                  <b>
                    {currencyFormat.format(
                      analytics.BRRRR.allInCostBRRRR,
                    )}
                  </b>{' '}
                  <Tooltip title="The total amount of money this deal costs, including rehab, closing costs, points, etc.">
                    <span
                      className="text-first"
                      onClick={() => toggle1('Refinance All-in Cost')}
                      style={{ textDecoration: 'underline' }}
                    >
                      all-in cost
                    </span>
                  </Tooltip>
                  , you will be able to pull out up to{' '}
                  <b>{currencyFormat.format(maxCashOut)}</b> of your{' '}
                  <b>
                    {currencyFormat.format(
                      analytics.BRRRR.totalOutOfPocketBridge,
                    )}
                  </b>{' '}
                  <Tooltip title="Total amount that the buyer has to cover with their own money.">
                    <span
                      className="text-first"
                      onClick={() =>
                        toggle1('Bridge Total Out-of-Pocket')
                      }
                      style={{ textDecoration: 'underline' }}
                    >
                      out-of-pocket
                    </span>
                  </Tooltip>{' '}
                  invested into this property, leaving{' '}
                  <b>
                    {currencyFormat.format(
                      analytics.BRRRR.totalOutOfPocketBridge -
                        maxCashOut >
                        0
                        ? analytics.BRRRR.totalOutOfPocketBridge -
                            maxCashOut >
                            0
                        : 0,
                    )}
                  </b>{' '}
                  in the deal. You will be required to bring{' '}
                  <b>
                    {currencyFormat.format(
                      analytics.BRRRR.totalOutOfPocketRefinance,
                    )}
                  </b>{' '}
                  <Tooltip title="Total amount that the buyer has to cover with their own money.">
                    <span
                      className="text-first"
                      onClick={() =>
                        toggle1('Refinance Total Out-of-Pocket')
                      }
                      style={{ textDecoration: 'underline' }}
                    >
                      additional
                    </span>
                  </Tooltip>{' '}
                  to the closing to refinance this deal.
                </p>
                <Divider
                  style={{ marginBottom: 15, marginTop: 15 }}
                />
                <Grid container>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      {map(refinancingFields, (section) =>
                        renderInputFields(section),
                      )}
                    </Grid>
                    {(!activeOrg.isAffiliate ||
                      (activeOrg.isAffiliate?.memberValue !==
                        'flippingamerica' &&
                        activeOrg.isAffiliate !==
                          'gorillacapital')) &&
                      activeOrg.member !== 'flippingamerica' &&
                      activeOrg.member !== 'gorillacapital' && (
                        <Lending
                          property={property}
                          shared={props.shared}
                          canDrag={props.canDrag}
                        />
                      )}
                  </Grid>
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'projection',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Value Over Time</b>
            </div>
            <div>
              <Tooltip title="Projected paydown timelines and cashflow options available on this property">
                <Button
                  onClick={() => setOpenBRRRRValueOverTimeTour(true)}
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid container className="text-black-50 font-size-sm">
                <Grid
                  item
                  md={4}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Property Paid off
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        {/* <div className="badge badge-success badge-circle mr-2">
                        fast
                      </div> */}
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              analytics.BRRRR
                                .refinancePropertyPayOffYears
                            }
                            duration={1}
                            separator=","
                            decimals={1}
                            suffix=" yrs"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={4}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Value at payoff
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        {/* <div className="badge badge-success badge-circle mr-2">
                        fast
                      </div> */}
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              analytics.BRRRR
                                .refinancePropertyAppreciationValue
                            }
                            duration={1}
                            separator=","
                            decimals={0}
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={4}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Cashflow at payoff
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        {/* <div className="badge badge-warning badge-circle mr-2">
                        normal
                      </div> */}
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              analytics.BRRRR
                                .refinanceCashFlowAtPayoff
                            }
                            duration={1}
                            delay={2}
                            separator=","
                            decimals={0}
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            {analytics.BRRRR.cashFlowBRRRR >= 0 ? (
              <p className="mb-3 text-black-50 text-center">
                If you took{' '}
                <b>
                  {currencyFormat.format(
                    ((analytics.BRRRR.cashFlowBRRRR / 12) *
                      analytics.payDownAccelerator) /
                      100,
                  )}
                </b>{' '}
                ({analytics.payDownAccelerator}% of the net cashflow)
                and paid it towards the{' '}
                <Tooltip title="The amount you borrowed from the lender.">
                  <span
                    className="text-first"
                    style={{ textDecoration: 'underline' }}
                  >
                    loan principal
                  </span>
                </Tooltip>{' '}
                each month, this property could be paid off in{' '}
                <b>
                  {analytics.BRRRR.refinancePropertyPayOffYears.toFixed(
                    1,
                  )}{' '}
                  years
                </b>
                {analytics.BRRRR.refinancePropertyPayOffYears.toFixed(
                  1,
                ) ===
                (assumptions.refinanceTermType === 'Years'
                  ? assumptions.refinanceTerm
                  : assumptions.refinanceTerm / 12
                ).toFixed(1)
                  ? ''
                  : ` instead of ${
                      assumptions.refinanceTerm
                        ? (assumptions.refinanceTermType === 'Years'
                            ? assumptions.refinanceTerm
                            : assumptions.refinanceTerm / 12
                          ).toFixed(1)
                        : 0
                    }`}
                .
              </p>
            ) : (
              <p className="mb-3 text-black-50 text-center">
                The cashflow of this property is less than zero, which
                means you can't take a percent of the cashflow and pay
                it towards the loan balance.
              </p>
            )}

            <Divider style={{ marginBottom: 15, marginTop: 15 }} />
            <Grid container>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(projectionAssumptionsFields, (section) =>
                    renderInputFields(section),
                  )}
                  {/* <Grid item xs={12}>
                    <Typography id="continuous-slider" gutterBottom>
                      Paydown Accelerator (% of net cashflow)
                    </Typography>
                    <Slider value={75} />
                    <Grid container direction='row' justifyContent='space-between'>
                      <Grid item>
                        <Typography id="continuous-slider" gutterBottom>
                          0%
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>100%</Typography>
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'cashPositioning',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Cash Positioning</b>
            </div>
            <div>
              <Tooltip title="Projected cash growth over one, three, five, and ten years">
                <Button
                  onClick={() =>
                    setOpenBRRRRCashPositioningTour(true)
                  }
                  className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Suspense fallback={<div>Loading Chart...</div>}>
              <Chart options={options} series={series} type="bar" />
            </Suspense>{' '}
            <Tooltip
              title={`Total Return of ${assumptions.rentalHoldingPeriodBRRRR} years`}
            >
              <div
                className="rounded p-2 mb-2 bg-secondary text-center border-light border-1"
                onClick={() => toggle1('Total Return')}
              >
                <Grid container justifyContent="space-around">
                  <Grid item data-tour="cashPositioning-step-1">
                    <Typography>Total Return</Typography>
                    <Typography>
                      {currencyFormat.format(
                        analytics.BRRRR.totalReturnOnInvestmentBRRRR,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item data-tour="cashPositioning-step-2">
                    <Typography>Total Return %</Typography>
                    <Typography>
                      {percentageFormat.format(
                        analytics.BRRRR.totalOutOfPocketBRRRR
                          ? analytics.BRRRR
                              .totalReturnOnInvestmentBRRRR /
                              analytics.BRRRR.totalOutOfPocketBRRRR
                          : 0,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Tooltip>
            {/* <Grid container direction="row">
              <Grid item xs={12}>
                <Button
                  variant='outlined'
                  fullWidth
                  onClick={handleOpenAmortization}
                >
                  <Typography>Amortization</Typography>
                </Button>
              </Grid>
            </Grid> */}
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'disposition',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Disposition Expenses</b>
            </div>
            {/* <div>
              <Tooltip title="All costs and expenses associated with sale of this property">
                <Button onClick={() => setOpenTurnkeyDispositionExpensesTour(true)} className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div> */}
          </div>
          <Divider />
          <CardContent>
            <Grid content>
              <Grid item>
                <div className="d-flex justify-content-center">
                  <Suspense fallback={<div>Loading Chart...</div>}>
                    <Chart
                      options={dispositionChartOptions}
                      series={dispositionChartSeries}
                      type="donut"
                      width="300"
                    />
                  </Suspense>
                </div>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  {map(dispositionFields, (field) =>
                    renderInputFields(field),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'comparableProperties',
      card: props.basicView ? null : (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Comparable Properties</b>
            </div>
            <div>
              <Tooltip title="All costs and expenses associated with sale of this property">
                <Button
                  /*onClick={() => setOpenBuyHoldDispositionExpensesTour(true)}*/ className="btn-neutral-primary d-30 border-0 p-0 text-left d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={['fal', 'info-circle']} />
                </Button>
              </Tooltip>
            </div>
          </div>
          <Divider />
          <CardContent>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid container className="text-black-50 font-size-sm">
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Comparable Properties
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={props.determinedComps.length}
                            duration={1}
                            separator=","
                            decimals={0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Potential Comps
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              props.compProperties.length -
                              props.determinedComps.length
                            }
                            duration={1}
                            separator=","
                            decimals={0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <List component="div" className="list-group-flush">
              {map(compData, (section) => (
                <ListItem
                  component="a"
                  button
                  disableRipple
                  className="d-flex bg-white hover-scale-rounded justify-content-between py-3"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      className="font-weight-bold text-black"
                      style={{ olor: 'text' }}
                    >
                      {section.label}
                    </Grid>
                    {section.range ? (
                      <Grid item>
                        <Grid container direction="row" spacing={1}>
                          <Grid item>
                            <CountUp
                              start={0}
                              end={section.value1}
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </Grid>
                          <Grid item>
                            <Typography>-</Typography>
                          </Grid>
                          <Grid item>
                            <CountUp
                              start={0}
                              end={section.value2}
                              duration={1}
                              separator=","
                              delay={1}
                              decimals={1}
                              decimal="."
                              prefix="$"
                              suffix="k"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item>
                        <CountUp
                          start={0}
                          end={section.value}
                          duration={1}
                          separator=","
                          delay={1}
                          decimals={2}
                          decimal="."
                          prefix="$"
                        />
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              ))}
            </List>
            <Grid container>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 25 }}
                  onClick={(e) => props.toggleCenter('Comps')}
                >
                  <Typography>View Comps</Typography>
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'piti',
      hide:
        props?.session?.me?.activeOrg?.member !== 'pacemorby' &&
        props?.session?.me?.activeOrg?.member !== 'astroflip',
      card: props.basicView ? null : (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Deal Sauce Summary</b>
            </div>
            <Select
              value={dealSauceView}
              name="dealSauceView"
              onChange={(event) =>
                setDealSauceView(event.target.value)
              }
              style={{ width: '100%' }}
            >
              <MenuItem value="cash" key="cash">
                Cash
              </MenuItem>
              <MenuItem value="creative" key="creative">
                Creative
              </MenuItem>
            </Select>
          </div>
          <Divider />
          <CardContent>
            {dealSauceView === 'cash' && (
              <>
                <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                  <Grid
                    container
                    className="text-black-50 font-size-sm"
                  >
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          Entry Fee
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div
                            className="d-flex p-1 align-items-center"
                            style={{ minHeight: 50 }}
                          >
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={cashEntryFee}
                                duration={0}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          Max Allowable Offer
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div
                            className="d-flex p-1 align-items-center"
                            style={{ minHeight: 50 }}
                          >
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={
                                  analysis.afterRepairValue -
                                  cashEntryFee
                                }
                                duration={0}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid item xs={12}>
                  <Grid container direction="column" spacing={4}>
                    <ContactInformation property={property} />
                  </Grid>
                </Grid>
                <CommentsDialog
                  property={property}
                  userId={props?.session?.me?._id}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: 25 }}
                  onClick={(e) => setOpenPITICashData(true)}
                >
                  <Typography>View Cash Underwriting</Typography>
                </Button>
              </>
            )}
            {dealSauceView === 'creative' && (
              <>
                <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
                  <Grid
                    container
                    className="text-black-50 font-size-sm"
                  >
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          Entry Fee
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={creativeEntryFee}
                                duration={1}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">PITI</div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={pitiTotal}
                                duration={1}
                                separator=","
                                delay={1}
                                decimals={0}
                                decimal="."
                                prefix="$"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      className="d-flex justify-content-center"
                    >
                      <div>
                        <div className="text-dark pb-1">
                          % of Purchase Price
                        </div>
                        <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                          <div className="d-flex p-1 align-items-center">
                            <span className="d-20 w-auto">
                              <CountUp
                                start={0}
                                end={
                                  analysis?.offerPrice &&
                                  analysis.offerPrice !== 0
                                    ? (creativeEntryFee /
                                        analysis.offerPrice) *
                                      100
                                    : 0
                                }
                                duration={1}
                                delay={1}
                                decimals={2}
                                decimal="."
                                suffix="%"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container direction="column" spacing={4}>
                      <ContactInformation property={property} />
                    </Grid>
                  </Grid>
                  <CommentsDialog
                    property={property}
                    userId={props?.session?.me?._id}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: 25 }}
                    onClick={(e) => setOpenPITICreativeData(true)}
                  >
                    <Typography>
                      View Creative Underwriting
                    </Typography>
                  </Button>
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      ),
    },
    {
      name: 'deactive',
      card: (
        <Card className="card-box w-100">
          <div
            className="card-header"
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            <div className="card-header--title">
              <b>Hide Cards</b>
            </div>
          </div>
          <Divider />
          <CardContent>
            <Typography>
              All cards past this one will be hidden
            </Typography>
          </CardContent>
        </Card>
      ),
    },
  ]

  const getOrderDefault = () => {
    const storedValues = !isEmpty(property.BRRRROrder)
      ? property.BRRRROrder
      : merge(
          cloneDeep(DEFAULT_PREFERENCES),
          activeOrg.memberPreferences,
          activeOrg.preferences,
          props.session.me ? props.session.me.preferences : {},
        ).BRRRROrder || []
    const newCards = difference(
      map(BRRRRCardsToOrder, (card) => card.name),
      storedValues,
    )
    const deactiveIndex = findIndex(
      storedValues,
      (value) => value === 'deactive',
    )
    return uniq(
      concat(
        slice(storedValues, 0, deactiveIndex),
        newCards,
        slice(storedValues, deactiveIndex),
      ),
    )
  }

  const [BRRRROrder, setBRRRROrder] = useState(getOrderDefault())
  const [orderChanged, setOrderChanged] = useState(false)
  const [orderDefault, setOrderDefault] = useState(false)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    setBRRRROrder(getOrderDefault())
  }, [activeOrg.member])

  const SortableCard = SortableElement(({ item }) => (
    <Grid item sm={6} xl={4} className="d-flex">
      {item}
    </Grid>
  ))
  const SortableGrid = SortableContainer(({ items }) => (
    <Grid container direction="row" spacing={2} className="p-3">
      {map(items, (item, index) => (
        <SortableCard
          key={`item-${index}`}
          index={index}
          item={item}
        />
      ))}
    </Grid>
  ))

  const [updateProperty] = useMutation(UPDATE_PROPERTY)
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newOrder = arrayMoveImmutable(
        BRRRROrder,
        oldIndex,
        newIndex,
      )
      setBRRRROrder(newOrder)
      setOrderChanged(true)
    }
  }

  const resetOrder = () => {
    setBRRRROrder(getOrderDefault())
    setOrderChanged(false)
  }

  const submitOrder = () => {
    setLoader(true)
    const preferences = merge(
      cloneDeep(DEFAULT_PREFERENCES),
      activeOrg.memberPreferences,
      activeOrg.preferences,
    )
    updateProperty({
      refetchQueries: [
        {
          query: GET_PROPERTY,
          variables: { id: property._id },
        },
      ],
      variables: {
        id: property._id,
        propertyInput: {
          BRRRROrder,
        },
      },
    })
      .then(() => {
        enqueueSnackbar(`Layout saved`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        if (orderDefault) {
          updateOrganization({
            refetchQueries: [
              {
                query: GET_ME,
              },
            ],
            variables: {
              id: activeOrg._id,
              organizationInput: {
                preferences: {
                  ...preferences,
                  BRRRROrder,
                },
              },
            },
          })
            .then(() => {
              enqueueSnackbar(`Default BRRRR layout saved`, {
                variant: 'success',
                autoHideDuration: 3000,
              })
              setOrderChanged(false)
              setLoader(false)
            })
            .catch((error) => {
              enqueueSnackbar(`Failed to save default BRRRR layout`, {
                variant: 'error',
                autoHideDuration: 3000,
              })
            })
        } else {
          setOrderChanged(false)
          setLoader(false)
        }
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to save layout`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
  }

  const sortedBRRRRCards = sortBy(BRRRRCardsToOrder, [
    function (o) {
      return findIndex(BRRRROrder, (value) => value === o.name)
    },
  ])

  return (
    <>
      {!props.canDrag && (
        <Grid container direction="row" spacing={2} className="p-3">
          {props.publicImageCard && (
            <Grid
              item
              xs={12}
              md={6}
              lg={props.publicDashboard ? 4 : 6}
              xl={4}
              className="d-flex w-100"
            >
              {props.publicImageCard}
            </Grid>
          )}
          {map(
            slice(
              sortedBRRRRCards,
              0,
              findIndex(
                sortedBRRRRCards,
                (card) => card.name === 'deactive',
              ),
            ),
            (card) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={props.publicDashboard ? 4 : 6}
                xl={4}
                className="d-flex w-100"
              >
                {card.card}
              </Grid>
            ),
          )}
        </Grid>
      )}
      {props.canDrag && (
        <SortableGrid
          items={compact(
            concat(
              [props.publicImageCard],
              map(sortedBRRRRCards, (card) => card.card),
            ),
          )}
          onSortEnd={onSortEnd}
          axis="xy"
        />
      )}
      {map(financials, (section) => (
        <Dialog
          open={modal1 === section.label}
          onClose={toggle1Close}
          maxWidth="lg"
          scroll="body"
          classes={{
            paper:
              'modal-content rounded border-0 bg-white p-3 p-xl-0',
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={5}>
              <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                  <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                    <div className="text-white mt-3">
                      <Typography
                        style={{ fontSize: 30, fontWeight: 600 }}
                      >
                        Calculation
                      </Typography>
                    </div>
                    {section.calculation}
                    <div className="text-white mt-3">
                      <Typography>
                        * Calculation may be a few dollars off due to
                        rounding
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={7}>
              <div>
                <Card>
                  <CardHeader title={section.label} />
                  <Divider />
                  <CardContent style={{ height: '100%' }}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        <Card
                          style={{
                            backgroundColor: '#eff2f5',
                            borderRadius: 0,
                          }}
                        >
                          <CardContent>
                            <Typography>
                              {section.description}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={toggle1Close}
                        >
                          Close
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Dialog>
      ))}
      <Dialog
        open={openManagementCompanies}
        onClose={handleCloseManagementCompanies}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Property Management Companies</DialogTitle>
        <DialogContent style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Grid container direction="column" spacing={2}>
            {map(
              filter(propertyManagementCompanies, (company) =>
                includes(
                  property.propertyManagementGroups,
                  company.value,
                ),
              ),
              (section) => (
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <div className="rounded avatar-image overflow-hidden d-40 bg-neutral-dark text-center font-weight-bold text-success d-flex justify-content-center align-items-center">
                        <img
                          className="img-fluid img-fit-container rounded-sm"
                          src={section.image}
                          alt="..."
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography>{section.name}</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        href={section.link}
                        target="_blank"
                      >
                        <Typography>View</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ),
            )}
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modal1 === 'Total Return'}
        onClose={toggle1Close}
        maxWidth="lg"
        scroll="body"
        classes={{
          paper: 'modal-content rounded border-0 bg-white p-3 p-xl-0',
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <div className="hero-wrapper bg-composed-wrapper bg-light-pure h-100 rounded br-xl-right-0">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--bg bg-second opacity-7 rounded br-xl-right-0" />
                <div className="bg-composed-wrapper--content justify-content-center justify-content-xl-start text-center text-xl-left p-5">
                  <div className="text-white mt-3">
                    <Typography
                      style={{ fontSize: 30, fontWeight: 600 }}
                    >
                      Calculation
                    </Typography>
                  </div>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        Cash flow
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        {currencyFormat.format(
                          round(analytics.BRRRR.cashFlowBRRRR, 0),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        +Principal Reduction
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        +
                        {currencyFormat.format(
                          round(
                            analytics.BRRRR.principalReductionBRRRR,
                            0,
                          ),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        +Anticipated Annual Appreciation
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        +
                        {currencyFormat.format(
                          round(
                            analytics.BRRRR
                              .anticipatedAnnualAppreciationBRRRR,
                            0,
                          ),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ backgroundColor: '#9198a8' }} />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: 'white' }}>
                        Total Return
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: 'white' }}>
                        {currencyFormat.format(
                          round(
                            analytics.BRRRR
                              .totalReturnOnInvestmentBRRRR,
                            0,
                          ),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        <FontAwesomeIcon
                          icon={['fal', 'divide']}
                          style={{ marginRight: 3 }}
                        />
                        Total Out Of Pocket
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: '#9198a8' }}>
                        <FontAwesomeIcon
                          icon={['fal', 'divide']}
                          style={{ marginRight: 3 }}
                        />
                        {currencyFormat.format(
                          round(
                            analytics.BRRRR.totalOutOfPocketBRRRR,
                            0,
                          ),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ backgroundColor: '#9198a8' }} />
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginTop: 5, marginBottom: 5 }}
                  >
                    <Grid item>
                      <Typography style={{ color: 'white' }}>
                        Total Return %
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: 'white' }}>
                        {percentageFormat.format(
                          analytics.BRRRR.totalOutOfPocketBRRRR
                            ? analytics.BRRRR
                                .totalReturnOnInvestmentBRRRR /
                                analytics.BRRRR.totalOutOfPocketBRRRR
                            : 0,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className="text-white mt-3">
                    <Typography>
                      * Calculation may be a few dollars off due to
                      rounding
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div>
              <Card>
                <CardHeader title="Total Return" />
                <Divider />
                <CardContent style={{ height: '100%' }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                      <Card
                        style={{
                          backgroundColor: '#eff2f5',
                          borderRadius: 0,
                        }}
                      >
                        <CardContent>
                          <Typography>
                            The actual rate of return of an investment
                            over a given evaluation period which
                            includes income and appreciation.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={toggle1Close}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </div>
          </Grid>
        </Grid>
      </Dialog>

      <AmortizationDialog
        open={openAmortization}
        onClose={handleCloseAmortization}
        tableHeaders={tableHeaders}
        amortizationData={amortizationData}
        currencyFormat2={currencyFormat2}
        assumptions={assumptions}
        showAdditionalPrincipal={false} // Pass false to not render the additional principal cell
      />
      <SubtoPITICash
        open={openPITICashData}
        onClose={() => setOpenPITICashData(false)}
        propertyId={property?._id}
        address={property?.address}
        city={property.city}
        state={property.state}
        zipcode={property?.zip}
      />
      <SubtoPITICreative
        open={openPITICreativeData}
        onClose={() => setOpenPITICreativeData(false)}
        propertyId={property?._id}
        address={property?.address}
        city={property.city}
        state={property.state}
        zipcode={property?.zip}
      />
      <Drawer
        open={orderChanged}
        anchor="bottom"
        variant="persistent"
        PaperProps={{
          style: { backgroundColor: '#253152', padding: 15 },
        }}
      >
        {/* <Alert
        className="mb-4"
        style={{ backgroundColor: '#253152', color: 'white' }}
        icon={false}
        action={
          <IconButton onClick={props.resetPropertyChanges}>
            <Close style={{ color: 'white' }} />
          </IconButton>
        }
      > */}
        {/* <Typography style={{ color: 'white', fontWeight: 600 }}>You have unsaved changes</Typography> */}
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item style={{ marginLeft: 20 }}>
            <IconButton onClick={resetOrder} size="large">
              <Close style={{ color: 'white' }} />
            </IconButton>
          </Grid>
          <Grid item style={{ marginLeft: 0 }}>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: '#4290ff',
                color: 'white',
                boxShadow: 'none',
              }}
              onClick={submitOrder}
              disabled={loader}
            >
              <Typography style={{ fontWeight: 600 }}>
                Save
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <FormControl
              component="fieldset"
              className="pr-4"
              style={{ color: 'white' }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={orderDefault}
                      onChange={() => setOrderDefault(!orderDefault)}
                      style={{ color: 'white' }}
                    />
                  }
                  label="Set this as default BRRRR layout"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Typography style={{ flex: 1 }} />
        </Grid>
      </Drawer>

      <BRRRRStrategyTour
        isOpenBRRRRStrategyTour={openBRRRRStrategyTour}
        closeBRRRRStrategyTour={() => setOpenBRRRRStrategyTour(false)}
      />
      <BRRRRTargetsTour
        isOpenBRRRRTargetsTour={openBRRRRTargetsTour}
        closeBRRRRTargetsTour={() => setOpenBRRRRTargetsTour(false)}
      />
      <BRRRRMonthlyCashflowTour
        isOpenBRRRRMonthlyCashflowTour={openBRRRRMonthlyCashflowTour}
        closeBRRRRMonthlyCashflowTour={() =>
          setOpenBRRRRMonthlyCashflowTour(false)
        }
      />
      <BRRRRAcquisitionExpensesTour
        isOpenBRRRRAcquisitionExpensesTour={
          openBRRRRAcquisitionExpensesTour
        }
        closeBRRRRAcquisitionExpensesTour={() =>
          setOpenBRRRRAcquisitionExpensesTour(false)
        }
      />
      <BRRRRGrossMonthlyIncomeTour
        isOpenBRRRRGrossMonthlyIncomeTour={
          openBRRRRGrossMonthlyIncomeTour
        }
        closeBRRRRGrossMonthlyIncomeTour={() =>
          setOpenBRRRRGrossMonthlyIncomeTour(false)
        }
      />
      <BRRRRMonthlyExpensesTour
        isOpenBRRRRMonthlyExpensesTour={openBRRRRMonthlyExpensesTour}
        closeBRRRRMonthlyExpensesTour={() =>
          setOpenBRRRRMonthlyExpensesTour(false)
        }
      />
      <BRRRRRehabExpensesTour
        isOpenBRRRRRehabExpensesTour={openBRRRRRehabExpensesTour}
        closeBRRRRRehabExpensesTour={() =>
          setOpenBRRRRRehabExpensesTour(false)
        }
      />
      <BRRRRBridgeLoanTour
        isOpenBRRRRBridgeLoanTour={openBRRRRBridgeLoanTour}
        closeBRRRRBridgeLoanTour={() =>
          setOpenBRRRRBridgeLoanTour(false)
        }
      />
      <BRRRRRefinanceLoanTour
        isOpenBRRRRRefinanceLoanTour={openBRRRRRefinanceLoanTour}
        closeBRRRRRefinanceLoanTour={() =>
          setOpenBRRRRRefinanceLoanTour(false)
        }
      />
      <BRRRRValueOverTimeTour
        isOpenBRRRRValueOverTimeTour={openBRRRRValueOverTimeTour}
        closeBRRRRValueOverTimeTour={() =>
          setOpenBRRRRValueOverTimeTour(false)
        }
      />
      <BRRRRCashPositioningTour
        isOpenBRRRRCashPositioningTour={openBRRRRCashPositioningTour}
        closeBRRRRCashPositioningTour={() =>
          setOpenBRRRRCashPositioningTour(false)
        }
      />
    </>
  )
}

export default withSession(CenterPageBRRRR)
