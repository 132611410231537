/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-16 11:36:56
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-01-10 16:01:05
 * @ Description: Page that shows when logging in to a delinquent org. Has options to retry and/or change payment method
 */

import React, { useState } from 'react'
import { gql } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material'

import map from 'lodash/map'

import particles2 from '@/assets/images/hero-bg/particles-2.svg'
import delinquentImage from '@/assets/images/DelinquentImage.png'
// import MarketingHeaders6 from '../../../example-components/MarketingHeaders/MarketingHeaders6';

import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import { ApolloConsumer, useMutation } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { signOut } from '@/housefolios-components/SignOut'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { Fragment } from 'react'
import { Close } from '@mui/icons-material'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from '@/housefolios-components/CreateAccount/PaymentForm'
import CancelPlanButton from '@/housefolios-components/TitleButtons/CancelPlanButton'
import { UPDATE_USER } from '@/housefolios-components/PersonalSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import CreateNewOrg from '@/housefolios-components/OrganizationSettings/CreateNewOrg'

const RECHARGE_CARD = gql`
  mutation ($planId: String) {
    rechargeCard(planId: $planId)
  }
`

const UPDATE_CARD = gql`
  mutation ($planId: String, $token: JSON!) {
    updateAndChargeCard(planId: $planId, token: $token)
  }
`

function DelinquentPageComponent(props) {
  const [showUpdate, setShowUpdate] = useState(false)

  const toggleShowUpdate = () => {
    setShowUpdate(!showUpdate)
  }

  const [showSwitch, setShowSwitch] = useState(false)

  const toggleShowSwitch = () => {
    setShowSwitch(!showSwitch)
  }

  const [updateUser] = useMutation(UPDATE_USER)

  const [loader, setLoading] = useState(false)

  const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY
  const stripePromise = loadStripe(stripePublicKey)
  const { activeOrg } = props.session.me

  const [stripeToken, setStripeToken] = useState('')
  //const [element, setElement] = useState(null)
  const [resetToken, setResetToken] = useState(() => {
    return null
  })

  const createToken = async (stripe, cardElement, nameOnCard) => {
    if (!nameOnCard) setStripeToken('')
    else {
      let { token } = await stripe.createToken(cardElement, {
        name: nameOnCard,
      })
      setStripeToken(token)
    }
  }

  const handleSubmit = (mutation) => {
    setLoading(true)
    mutation()
      .then(({ data }) =>
        data === null
          ? () => {
              setLoading(false)
              resetToken()
            }
          : window.location.reload(),
      )
      .catch((error) => {
        enqueueSnackbar(`Error: ` + error.message, {
          variant: 'error',
          action: (key) => (
            <Fragment>
              <IconButton
                onClick={() => {
                  props.closeSnackbar(key)
                }}
                size="large"
              >
                <Close style={{ color: 'white' }} />
              </IconButton>
            </Fragment>
          ),
        })
        setLoading(false)
        resetToken()
      })
  }

  return (
    <BlockUi
      blocking={loader}
      loader={
        <PacmanLoader color={'var(--primary)'} loading={true} />
      }
      message={
        <div className="text-primary">Attempting New Charge</div>
      }
    >
      {/* <ListItem button onClick={handleOpenEditPortfolio} selected>
        <AttachFileOutlinedIcon className="mr-2" />
        Test4
      </ListItem>
      <Dialog
        open={openEditPortfolio}
        onClose={handleCloseEditPortfolio}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="xl"
        fullWidth={true}
      > */}
      <div className="hero-wrapper bg-composed-wrapper bg-white">
        <div className="hero-wrapper--content">
          <div
            className="bg-composed-wrapper--image bg-composed-filter-rm opacity-7"
            style={{ backgroundImage: 'url(' + particles2 + ')' }}
          />
          <div className="bg-composed-wrapper--content">
            <div className="py-3 py-xl-5">
              <Container className="text-black py-5">
                <Grid container spacing={6}>
                  <Grid
                    item
                    xl={6}
                    className="d-flex align-items-center"
                  >
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="badge badge-pill rounded badge-danger px-4 h-auto py-1">
                          ACCOUNT PAUSED
                        </div>
                      </div>
                      <div className="text-black mt-3">
                        <h1 className="display-2 mb-3 font-weight-bold">
                          Oops! Your Payment Method Didn't Work
                        </h1>
                        <p className="font-size-lg text-black-50">
                          Your payment method ending in{' '}
                          {props.session.me.activeOrg.delinquent !==
                          '44444'
                            ? props.session.me.activeOrg.delinquent
                            : ''}{' '}
                          was not successful. Please update your
                          payment information or try recharging your
                          credit card.
                        </p>
                        <p className="text-black-50">
                          <b>Need Help?</b> Use the chat bubble at the
                          bottom right of the screen to contact us!
                        </p>
                        <div className="divider border-2 border-dark my-4 border-light opacity-2 rounded-circle w-25" />
                        <div>
                          <Button
                            href={null}
                            onClick={(e) => toggleShowUpdate()}
                            size="large"
                            className="btn-first d-block d-sm-inline-block"
                          >
                            <span className="btn-wrapper--label">
                              Update Payment Info
                            </span>
                            <span className="btn-wrapper--icon">
                              <FontAwesomeIcon
                                icon={['fas', 'arrow-right']}
                              />
                            </span>
                          </Button>
                          <Mutation mutation={RECHARGE_CARD}>
                            {(
                              rechargeCard,
                              { data, loading, error },
                            ) => (
                              <Button
                                href={null}
                                onClick={() =>
                                  handleSubmit(rechargeCard)
                                }
                                size="large"
                                variant="text"
                                className="btn-outline-first d-block d-sm-inline-block ml-0 mt-3 mt-sm-0 ml-sm-3"
                              >
                                <span>Recharge Card on File</span>
                              </Button>
                            )}
                          </Mutation>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xl={6}
                    className="px-0 d-none d-xl-flex align-items-center"
                  >
                    <img
                      src={delinquentImage}
                      className="w-100 mx-auto d-block img-fluid"
                      alt="..."
                    />
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        </div>
        <div className="hero-footer py-3 py-xl-5">
          <Container>
            <Grid container spacing={6}>
              <Grid item md={6} xl={4}>
                <Card className="card-box-hover-rise card-box-hover card-box-alt card-border-top border-danger mb-5 pb-4 bg-secondary shadow-xxl">
                  <h3 className="font-size-lg font-weight-bold mt-5 mb-4">
                    Cancel Account
                  </h3>
                  <p className="text-dark px-4 mb-4">
                    If you'd like to cancel your account, Click the
                    button below.
                  </p>
                  <CancelPlanButton title />
                  {/* <Button
                    href={null}
                    onClick={toggleCancel}
                    className="btn-link btn-link-danger mb-2 p-0"
                    title="Cancel Account">
                    <span>Cancel Account</span>
                  </Button> */}
                </Card>
              </Grid>
              <Grid item md={6} xl={4}>
                <Card className="card-box-hover-rise card-box-hover card-box-alt card-border-top border-first mb-5 pb-4 bg-secondary shadow-xxl">
                  <h3 className="font-size-lg font-weight-bold mt-5 mb-4">
                    Sign Out
                  </h3>
                  <p className="text-dark px-4 mb-4">
                    You can sign out of your account by clicking the
                    button below.
                  </p>
                  <ApolloConsumer>
                    {(client) => (
                      <Button
                        href={null}
                        onClick={() =>
                          signOut(client, activeOrg.member)
                        }
                        className="btn-link btn-link-first mb-2 p-0"
                        title="Sign Out"
                      >
                        <span>Sign Out</span>
                      </Button>
                    )}
                  </ApolloConsumer>
                </Card>
              </Grid>
              <Grid item md={6} xl={4} className="d-none d-xl-block">
                <Card className="card-box-hover-rise card-box-hover card-box-alt card-border-top border-warning mb-5 pb-4 bg-secondary shadow-xxl">
                  <h3 className="font-size-lg font-weight-bold mt-5 mb-4">
                    Switch Organization
                  </h3>
                  <p className="text-dark px-4 mb-4">
                    Switch to another organization by clicking the
                    below button.
                  </p>
                  <Button
                    onClick={toggleShowSwitch}
                    className="btn-link btn-link-first mb-2 p-0"
                    title="Switch Organization"
                  >
                    Switch Organization
                  </Button>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <Dialog open={showSwitch} onClose={toggleShowSwitch}>
        <DialogTitle>Switch Organization</DialogTitle>
        <DialogContent>
          <List component="div" className="list-group-flush">
            {map(props.session.me.roles, (roleOrg) => {
              if (
                roleOrg.organization &&
                roleOrg.role[0] !== 'deactive' &&
                roleOrg.organization._id !== 'superAdmin'
              ) {
                if (
                  roleOrg.organization._id ===
                  props.session.me.activeOrg._id
                )
                  return (
                    <ListItem
                      component="a"
                      key={roleOrg.organization}
                      button
                      href={null}
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3"
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ width: '100%' }}
                      >
                        <div className="avatar-icon-wrapper d-50 mr-3">
                          <IconButton
                            style={{ backgroundColor: '#28b854' }}
                            size="large"
                          >
                            <FontAwesomeIcon
                              icon={['far', 'building']}
                              style={{ color: 'white' }}
                              size="1x"
                            />
                          </IconButton>
                        </div>
                        <div>
                          <div className="font-weight-bold font-size-sm text-black">
                            {roleOrg.organization.name}
                          </div>
                          <div className="d-flex align-items-center font-size-xs">
                            <div className="badge badge-success badge-circle border-white border-1 mr-2">
                              Completed
                            </div>
                            <div className="text-success">
                              Selected
                            </div>
                          </div>
                        </div>
                        <div style={{ flex: 1 }} />
                        <div
                          onClick={(event) => event.stopPropagation()}
                        >
                          <Chip
                            color="primary"
                            disabled={
                              roleOrg.organization._id ===
                              props.session.me.defaultOrg
                            }
                            label={
                              roleOrg.organization._id ===
                              props.session.me.defaultOrg
                                ? 'Default Org'
                                : 'Make Default'
                            }
                            onClick={(e) => {
                              updateUser({
                                variables: {
                                  userInput: {
                                    defaultOrg:
                                      roleOrg.organization._id,
                                  },
                                },
                                refetchQueries: [
                                  {
                                    query: GET_ME,
                                  },
                                ],
                              }).then(async ({ data }) => {
                                localStorage.setItem(
                                  'token',
                                  data.updateUser.token,
                                )
                                //window.location.reload()
                              })
                              e.preventDefault()
                            }}
                          />
                        </div>
                      </div>
                    </ListItem>
                  )
                else
                  return (
                    <ListItem
                      component="a"
                      button
                      href={null}
                      disableRipple
                      onClick={(e) => {
                        updateUser({
                          variables: {
                            userInput: {
                              organization: roleOrg.organization._id,
                            },
                          },
                          refetchQueries: [
                            {
                              query: GET_ME,
                            },
                            {
                              query: GET_PORTFOLIOS,
                            },
                          ],
                        }).then(async ({ data }) => {
                          localStorage.setItem(
                            'token',
                            data.updateUser.token,
                          )
                          window.location.reload()
                        })
                        e.preventDefault()
                      }}
                      className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center py-3"
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ width: '100%' }}
                      >
                        <div className="avatar-icon-wrapper d-50 mr-3">
                          <IconButton
                            style={{ backgroundColor: '#797d95' }}
                            size="large"
                          >
                            <FontAwesomeIcon
                              icon={['far', 'building']}
                              style={{ color: 'white' }}
                              // size="md"
                            />
                          </IconButton>
                        </div>
                        <div>
                          <div className="font-weight-bold font-size-sm text-black">
                            {roleOrg.organization.name}
                          </div>
                          <div className="d-flex align-items-center font-size-xs">
                            <div
                              style={{ backgroundColor: '#797d95' }}
                              className="badge badge-circle border-white border-1 mr-2"
                            >
                              Completed
                            </div>
                            <div style={{ color: '#797d95' }}>
                              Not Selected
                            </div>
                          </div>
                        </div>
                        <div style={{ flex: 1 }} />
                        <div
                          onClick={(event) => event.stopPropagation()}
                        >
                          <Chip
                            color="primary"
                            disabled={
                              roleOrg.organization._id ===
                              props.session.me.defaultOrg
                            }
                            label={
                              roleOrg.organization._id ===
                              props.session.me.defaultOrg
                                ? 'Default Org'
                                : 'Make Default'
                            }
                            onClick={(e) => {
                              updateUser({
                                variables: {
                                  userInput: {
                                    defaultOrg:
                                      roleOrg.organization._id,
                                  },
                                },
                                refetchQueries: [
                                  {
                                    query: GET_ME,
                                  },
                                ],
                              }).then(async ({ data }) => {
                                localStorage.setItem(
                                  'token',
                                  data.updateUser.token,
                                )
                                //window.location.reload()
                              })
                              e.preventDefault()
                            }}
                          />
                        </div>
                      </div>
                    </ListItem>
                  )
              }
            })}
            <CreateNewOrg listItem />
          </List>
        </DialogContent>
      </Dialog>
      {/* </Dialog> */}
      {/* <Dialog open={showCancel} onClose={toggleCancel}>
        <DialogTitle>
          Immediately lose access
        </DialogTitle>
        <DialogContent>
          Canceling will immediately lock you out of your account. You will not be able to see or access any of your properties
        </DialogContent>
        <Mutation
          mutation={UPDATE_ORGANIZATION}
          variables={{
            id: activeOrg._id,
            organizationInput: {
              disabled: false,
              delinquent: '',
            }
          }}
        >
          {(updateOrganization, { data, loading, error }) => (
            <Mutation
              mutation={DELETE_SUBSCRIPTION}
              variables={{
                id: head(activeOrg.subscriptions) ? head(activeOrg.subscriptions).id : null,
              }}
            >
              {(deleteSubscription, { data, loading, error }) => (
                <DialogActions>
                  <ApolloConsumer>
                    {client =>
                      (<Button color='primary' onClick={() => {
                        deleteSubscription().then(({ data }) => {
                          updateOrganization().then(({ data }) => {
                            window.open('https://docs.google.com/forms/d/e/1FAIpQLSeY8GKTrOwKREf3t41W0N49whxMPylFUsQG4mrQqMQQDJH99A/viewform?usp=sf_link', "_blank")
                            // toggleCancel()
                            // localStorage.clear()
                            // client.resetStore()
                          })
                        })
                      }}>
                        Cancel now
                    </Button>)}
                  </ApolloConsumer>
                  <Button variant='contained' color='primary' onClick={toggleCancel}>
                    Keep Plan
                  </Button>
                </DialogActions>
              )}
            </Mutation>
          )}
        </Mutation>
      </Dialog> */}
      <Dialog open={showUpdate} onClose={toggleShowUpdate}>
        <CardContent>
          <Grid item>
            <Grid container>
              <Grid>
                <Typography style={{ fontWeight: 600 }}>
                  Update Credit Card
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Grid
              container
              direction="column"
              style={{
                backgroundColor: '#eef2f6',
              }}
            >
              <Grid item style={{ padding: 10 }}>
                <div className="example">
                  <Elements stripe={stripePromise}>
                    <PaymentForm
                      createToken={createToken}
                      //setElement={setElement}
                      setResetToken={setResetToken}
                    />
                  </Elements>
                </div>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    marginLeft: 12,
                    marginBottom: 5,
                    fontSize: 13,
                  }}
                >
                  By continuing, you agree to our{' '}
                  <a href="/Terms" target="_blank" rel="noreferrer">
                    {'Terms & Conditions'}
                  </a>{' '}
                  and our{' '}
                  <a href="/Privacy" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  margin: 10,
                }}
              >
                <Mutation
                  mutation={UPDATE_CARD}
                  variables={{ token: stripeToken }}
                >
                  {(
                    updateAndChargeCard,
                    { data, loading, error },
                  ) => (
                    <div>
                      <Button
                        disabled={!stripeToken}
                        variant="contained"
                        size="large"
                        type="submit"
                        fullWidth
                        style={{
                          backgroundColor: '#2096f3',
                          color: 'white',
                        }}
                        onClick={() => {
                          handleSubmit(updateAndChargeCard)
                          toggleShowUpdate()
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          //justifyContent="space-between"
                        >
                          <Grid item>UPDATE AND CHARGE CARD</Grid>
                        </Grid>
                      </Button>
                    </div>
                  )}
                </Mutation>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Dialog>
    </BlockUi>
  )
}

export default withSession(DelinquentPageComponent)
