/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2024-10-17 13:59:06
 * @ Description: Table showing all of the property files
 */

import React, { useState } from 'react'
import Pagination from '@mui/material/Pagination'

import map from 'lodash/map'
import find from 'lodash/find'
import ceil from 'lodash/ceil'
import slice from 'lodash/slice'
import moment from 'moment'
import get from 'lodash/get'
import isString from 'lodash/isString'
import every from 'lodash/every'
import isNil from 'lodash/isNil'

import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Table,
  TableCell,
  TableSortLabel,
  Tooltip,
} from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import withSession from '@/housefolios-components/Session/withSession'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import FileUpload from '@/housefolios-components/PropertyContainer/PropertyDashboard/FileUpload'
import { property } from 'lodash'
import Add from '@mui/icons-material/Add'

import useSorting, {
  getSorting,
  stableSort,
} from '@/hooks/useSorting'
import { handleDownload } from '@/utils/files'

// const status = [
//   {
//     name: orgCreator,
//     color: '#4290ff',
//   },
//   {
//     name: pending,
//     color: '#FF8300',
//   },
//   {
//     name: deactivated,
//     color: '#E4000F',
//   },
//   {
//     name: active,
//     color: '#46de64',
//   },
// ]

function ManageFiles(props) {
  const [loader, setLoader] = useState(false)
  const [selectedFile, setSelectedFile] = useState('')
  const [openDelete, setOpenDelete] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const handleOpenDelete = (file) => {
    setOpenDelete(true)
    setSelectedFile(file)
    setConfirm(false)
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
    setSelectedFile(null)
    setLoader(false)
  }

  const [{ order, orderBy }, { handleRequestSort }] = useSorting({
    defaultOrder: 'asc',
    defaultOrderBy: 'createdAt',
  })

  const [page, setPage] = useState(
    parseInt(localStorage.getItem('team-page')) || 1,
  )
  const handleChange = (event, value) => {
    localStorage.setItem('team-page', value)
    setPage(value)
  }

  const toggleRemove = (url) => {
    setLoader(true)
    const file = find(props.files, ['url', url])
    props.deleteFileFromList(file)
    handleCloseDelete()
  }

  const visuallyHidden = {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }

  const tableHeaders = props.public
    ? [
        { title: 'File Name', name: 'filename' },
        { title: 'File Type', name: 'filetype' },
      ]
    : [
        { title: 'File Name', name: 'filename' },
        { title: 'File Location', name: 'collection' },
        { title: 'File Type', name: 'filetype' },
        { title: 'Created By', name: 'createdBy' },
        { title: 'Created Date', name: 'createdAt' },
      ]

  const count = ceil(props.files.length / 20)

  return (
    <Card
      className="card-box"
      /* mb-spacing-6-x2"*/ style={{ margin: 25 }}
    >
      <div className="card-header pr-2">
        <div className="card-header--title">
          <b>Files</b>
        </div>
        <div className="card-header--actions">
          <FileUpload
            documentId={property._id}
            uploadFile={props.uploadFileOther}
            writeOnly
            button
            buttonColor="primary"
            buttonText="Add Files"
            buttonIcon={<Add style={{ marginRight: 10 }} />}
            collection={'otherFile'}
            shared={props.shared}
          />
        </div>
      </div>
      <CardContent className="p-0">
        <div className="table-responsive-md">
          <Table className="table mb-0">
            <thead className="thead-light">
              <tr className="text-center">
                {map(tableHeaders, (column) => (
                  <TableCell
                    key={column.title}
                    //style={{ whiteSpace: 'nowrap' }}
                    align={column.align}
                    sortDirection={
                      orderBy === column.name ? order : false
                    }
                  >
                    {column.name ? (
                      <TableSortLabel
                        active={orderBy === column.name}
                        direction={order}
                        onClick={(event) =>
                          handleRequestSort(event, column.name)
                        }
                      >
                        {column.title}
                        {orderBy === column.name ? (
                          <span style={visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      column.title
                    )}
                  </TableCell>
                ))}
                <TableCell>Action</TableCell>
              </tr>
            </thead>
            <tbody>
              {map(
                slice(
                  stableSort(props.files, getSorting(order, orderBy)),
                  (page - 1) * 20,
                  page * 20,
                ),
                (file) => {
                  return (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div>
                            <a
                              href={file.url}
                              target="_blank"
                              rel="noreferrer"
                              className="font-weight-bold text-black"
                              title="..."
                            >
                              {file.filename}
                            </a>
                          </div>
                        </div>
                      </td>
                      {!props.public && (
                        <td>
                          <div>{file.collection}</div>
                        </td>
                      )}
                      <td>
                        <div>{file.filetype}</div>
                      </td>
                      {!props.public && (
                        <td>
                          <div>{file.createdBy}</div>
                        </td>
                      )}
                      {!props.public && (
                        <td>
                          <div>
                            {moment(file.createdAt).format(
                              'MMM Do YYYY',
                            )}
                          </div>
                        </td>
                      )}
                      <td>
                        <div>
                          {!props.shared &&
                            file.collection !== 'Plan Files' && (
                              <Tooltip title="Delete File">
                                <IconButton
                                  onClick={(event) =>
                                    handleOpenDelete(file.url)
                                  }
                                  style={{
                                    backgroundColor: '#e4eeff',
                                    height: 30,
                                    width: 30,
                                  }}
                                  size="large"
                                >
                                  <FontAwesomeIcon
                                    icon={['far', 'circle-xmark']}
                                    className="font-size-lg"
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          {props.public && (
                            <Tooltip title="Download File">
                              <IconButton
                                onClick={() =>
                                  handleDownload(
                                    file.url,
                                    file.filename,
                                  )
                                }
                                style={{
                                  backgroundColor: '#e4eeff',
                                  height: 30,
                                  width: 30,
                                }}
                                size="large"
                              >
                                <FontAwesomeIcon
                                  icon={['far', 'cloud-arrow-down']}
                                  className="font-size-lg"
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                },
              )}
            </tbody>
          </Table>
        </div>
        <div className="divider" />
        <div className="divider" />
        <div className="p-3 d-flex justify-content-center">
          <Pagination
            className="pagination-first"
            size="small"
            count={count}
            page={page}
            onChange={handleChange}
          />
        </div>
      </CardContent>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        classes={{ paper: 'shadow-lg rounded' }}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={<div className="text-primary">Deleting File</div>}
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                <FontAwesomeIcon
                  icon={['fas', 'trash-can']}
                  size="10x"
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">Delete File?</h4>
            <p className="mb-0 font-size-lg text-muted">
              <FormControl component="fieldset" className="pr-4">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirm}
                        onChange={() => setConfirm(!confirm)}
                      />
                    }
                    label="Are you sure you want to delete this"
                  />
                </FormGroup>
              </FormControl>
              {/* Delete this portfolio along with all the properties inside. This can't be undone. */}
            </p>
            <div className="pt-4">
              <Button
                variant="text"
                onClick={handleCloseDelete}
                //className="btn-neutral-secondary btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: '#f83145', color: 'white' }}
                onClick={(event) => toggleRemove(selectedFile)}
                disabled={!confirm}
              >
                <span className="btn-wrapper--label">Delete</span>
              </Button>
            </div>
          </div>
        </BlockUi>
      </Dialog>
    </Card>
  )
}

export default withSession(ManageFiles)
