import { Navigate, useNavigate, useParams } from 'react-router-dom'

function ProformaRedirect(props) {
  // const token = getToken('tokenRealeflow')
  // const userId = getToken('tokenRealeflowId')
  const { isLoggedIn } = props
  let { id, type } = useParams()
  const navigate = useNavigate()

  let url
  if (isLoggedIn) url = `/property-details/${id}/proforma/${type}`
  else url = `/property/${id}/proforma/${type}`

  return <Navigate to={url} />
}

export default ProformaRedirect
