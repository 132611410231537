/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2024-08-07 13:16:31
 * @ Description: Dialog to send a property to the trash
 */

import React, { useEffect, useState } from 'react'

import {
  Grid,
  ListItem,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import { MenuItem } from 'react-contextmenu'
import map from 'lodash/map'

import { enqueueSnackbar } from 'notistack'
import { useMutation, useLazyQuery } from '@apollo/client'
import { GET_ORGS_PROPERTY_SHARED_WITH } from '../queries'
import { GET_PROPERTIES, GET_TRASH } from '../../Properties/queries'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { UPDATE_PROPERTIES } from '@/housefolios-components/Portfolio/mutations'
import withSession from '@/housefolios-components/Session/withSession'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import { GET_PROPERTY } from '@/housefolios-components/PropertyContainer/queries'

function TrashPropertyComponent(props) {
  const { rightClickMenu } = props
  const [loader, setLoader] = useState(false)
  const [openDeleteProperty, setDeleteProperty] = useState(false)
  const handleOpenDeleteProperty = () => {
    setDeleteProperty(true)
  }
  const handleCloseDeleteProperty = () => {
    setDeleteProperty(false)
  }
  const [confirm, setConfirm] = useState(false)
  const [updateProperties] = useMutation(UPDATE_PROPERTIES)

  const handleTrash = () => {
    if (props.goToTrash)
      localStorage.setItem('portfolio', props.portfolioId)
    setLoader(true)
    updateProperties({
      variables: {
        ids: props.propertyId,
        propertyInput: {
          deleted: true,
          deletedAt: new Date(),
          deletedOrg: props.session.me.activeOrg._id,
        },
      },
      refetchQueries: [
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [props.portfolioId],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: map(
              props.portfolios,
              (portfolio) => portfolio._id,
            ),
          },
        },
        { 
          query: GET_PROPERTY,
          variables: {
            id: props.propertyId
          },
        },
        {
          query: GET_TRASH,
        },
        {
          query: GET_PORTFOLIOS,
        },
        {
          query: GET_ME,
        },
      ],
    })
      .then(() => {
        enqueueSnackbar(`Selected Properties Trashed`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        handleCloseDeleteProperty()
        if (props.setSelected) props.setSelected([])
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to Trash Selected Properties`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseDeleteProperty()
      })
  }

  const [findSharedOrgs, results] = useLazyQuery(
    GET_ORGS_PROPERTY_SHARED_WITH,
    {
      variables: {
        propertyId: props.propertyId,
      },
    },
  )

  const { data, error, loading, called } = results

  useEffect(() => {
    if (called && !loading) {
      if (orgsPropertySharedWith.length <= 0) handleTrash()
      else handleOpenDeleteProperty()
    }
  }, [called, loading])

  if (loading) return 'loading'
  if (error) return `Error! ${error.message}`
  const { orgsPropertySharedWith = [] } = data || {}

  return (
    <>
      {rightClickMenu && (
        <ListItem
          component="a"
          button
          href={
            called &&
            props.goToTrash &&
            orgsPropertySharedWith.length <= 0
              ? '/trash'
              : null
          }
          onClick={
            !called
              ? findSharedOrgs
              : orgsPropertySharedWith.length <= 0
              ? handleTrash
              : handleOpenDeleteProperty
          }
          disabled={loader}
          className="rounded-sm"
        >
          <Grid container direction="row" spacing={1}>
            <Grid item className="gridItem2">
              <FontAwesomeIcon
                icon={['far', 'trash']}
                className="font-size-lg"
                style={{ color: 'red', marginRight: 2 }}
              />
            </Grid>
            <Grid item className="gridItem2">
              <Typography style={{ color: 'red' }}>
                Trash Property
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      <Dialog
        open={openDeleteProperty}
        onClose={handleCloseDeleteProperty}
        classes={{ paper: 'shadow-lg rounded' }}
      >
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon
                icon={['fas', 'trash-can']}
                size="10x"
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">
            Trash Shared Property?
          </h4>
          <p className="mb-0 font-size-lg text-muted">
            <FormControl component="fieldset" className="pr-4">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirm}
                      onChange={() => setConfirm(!confirm)}
                    />
                  }
                  label="Remove this property for all shared users?"
                />
              </FormGroup>
            </FormControl>
            {/* Delete this portfolio along with all the properties inside. This can't be undone. */}
          </p>
          <div className="pt-4">
            <Button
              variant="text"
              onClick={handleCloseDeleteProperty}
              //className="btn-neutral-secondary btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: '#f83145', color: 'white' }}
              href={props.goToTrash ? '/trash' : null}
              onClick={handleTrash}
              disabled={!confirm}
            >
              <span className="btn-wrapper--label">Trash</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default withSession(TrashPropertyComponent)
