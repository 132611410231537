/**
 * @ Author: Housefolios
 * @ Create Time: 2021-10-05 11:07:32
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-04 12:05:41
 * @ Description: Stripe payment form for credit cards.
 */

import { TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import debounce from 'lodash/debounce'

const PaymentForm = (props) => {
  const [cardTrigger, setCardTrigger] = useState(false)
  const [nameOnCard, setNameOnCard] = useState('')

  const stripe = useStripe()
  const elements = useElements()

  const { createToken, setResetToken } = props

  useEffect(() => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    setResetToken(() => () => {
      createToken(stripe, elements.getElement(CardElement), '')
    })
    //putting createToken in the dependancy array causes infinite updating
  }, [stripe, elements, setResetToken])

  const makeToken = (stripe, elements, nameOnCard) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    const cardElement = elements.getElement(CardElement)
    createToken(stripe, cardElement, nameOnCard)
  }

  const debouncedMakeToken = useRef(
    debounce(
      (stripe, elements, nameOnCard) =>
        makeToken(stripe, elements, nameOnCard),
      0,
    ),
  ).current

  useEffect(() => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    debouncedMakeToken(stripe, elements, nameOnCard)
    return () => debouncedMakeToken.cancel()
  }, [stripe, elements, cardTrigger, nameOnCard, debouncedMakeToken])

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        backgroundColor: 'white',
        //fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        //hidePostalCode: true,
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  }
  return (
    <>
      <div
        className="checkout"
        style={{
          border: 1,
          borderStyle: 'solid',
          borderColor: '#b7babd',
          borderRadius: '3px',
          paddingLeft: 14,
          paddingRight: 14,
          paddingTop: 11,
          paddingBottom: 11,
          // height: 56,
          height: 40,
          marginBottom: 5,
        }}
      >
        <CardElement
          // style={{ verticalAlign: 'middle' }}
          options={CARD_ELEMENT_OPTIONS}
          onChange={() => setCardTrigger(!cardTrigger)}
          onBlur={() => setCardTrigger(!cardTrigger)}
          //hidePostalCode={true}
          onReady={(element) => {
            if (props.setElement) {
              props.setElement(element)
            }
            element.focus()
          }}
          //ref={(instance) => { if (props.autoFocus && instance) instance._element.on("ready", () => { instance._element.focus() }) }}
        />
      </div>
      <TextField
        fullWidth={true}
        variant="outlined"
        margin="dense"
        placeholder="Name on Card"
        label="Name on Card"
        value={nameOnCard}
        onChange={(event) => setNameOnCard(event.target.value)}
        onBlur={() => setCardTrigger(!cardTrigger)}
        type="text"
      />
    </>
  )
}

export default PaymentForm
