import React from 'react'

import { Typography } from '@mui/material'

import withSession from '@/housefolios-components/Session/withSession'
import { connect } from 'react-redux'
import {
  setSidebarToggleMobile,
  setSidebarToggle,
} from '../../reducers/ThemeOptions'

const SidebarFooter = (props) => {
  return (
    <>
      <div className="app-sidebar--footer">
        <Typography color="primary">Powered by</Typography>
        <img
          alt="Housefolios"
          src={'/images/NewLogoBlue.png'}
          style={{ width: 150, marginLeft: 5, marginRight: 5 }}
        />
        {/* <ul>
          <li>
            <Tooltip arrow placement="top" title="Analytics Dashboard">
              <Button
                variant="text"
                className="btn-transition-none d-40 mx-2 p-0">
                <ListAltTwoToneIcon />
              </Button>
            </Tooltip>
          </li>
          <li>
            <Tooltip arrow placement="top" title="Crypto Components">
              <Button
                variant="text"
                className="btn-transition-none d-40 mx-2 p-0">
                <MarkunreadTwoToneIcon />
              </Button>
            </Tooltip>
          </li>
          <li>
            <Tooltip arrow placement="top" title="Buttons">
              <Button
                variant="text"
                className="btn-transition-none d-40 mx-2 p-0">
                <LinkedCameraTwoToneIcon />
              </Button>
            </Tooltip>
          </li>
        </ul> */}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  sidebarUserbox: state.DeprecatedThemeOptions.sidebarUserbox,
  sidebarToggle: state.DeprecatedThemeOptions.sidebarToggle,
  sidebarToggleMobile:
    state.DeprecatedThemeOptions.sidebarToggleMobile,
})

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) =>
    dispatch(setSidebarToggleMobile(enable)),
})

export default withSession(
  connect(mapStateToProps, mapDispatchToProps)(SidebarFooter),
)
