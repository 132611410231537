/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-27 11:19:03
 * @ Description: Dialog for adding properties from property search or marketplace
 */

import React, { Fragment, useState } from 'react'

import map from 'lodash/map'
import head from 'lodash/head'
import concat from 'lodash/concat'
import find from 'lodash/find'
import compact from 'lodash/compact'
import includes from 'lodash/includes'
import chunk from 'lodash/chunk'
import truncate from 'lodash/truncate'
import padStart from 'lodash/padStart'
import round from 'lodash/round'
import isEmpty from 'lodash/isEmpty'
import slice from 'lodash/slice'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import isArray from 'lodash/isArray'
import unset from 'lodash/unset'
import toString from 'lodash/toString'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { ApolloConsumer, useMutation } from '@apollo/client'
import { COPY_PROPERTIES } from '@/housefolios-components/Property/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { enqueueSnackbar } from 'notistack'
import { ADD_PROPERTIES } from '../mutations'
import {
  ADD_COMPPROPERTY_BACKEND,
  IMAGE_UPLOAD_MULTI,
} from '@/housefolios-components/TitleButtons/AddPropertyButton/mutations'
import {
  CALL_AIR_DNA,
  GET_SMART_ASSET,
  GET_ZIP_VALUE,
} from '@/housefolios-components/TitleButtons/AddPropertyButton/queries'
import { Close } from '@mui/icons-material'
import withSession from '@/housefolios-components/Session/withSession'
import CreatePortfolio from '@/housefolios-components/Portfolio/CreatePortfolio'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import { GET_STOCKPILE } from '../queries'
import { STATES } from '@/constants/states'
import Autocomplete from '@mui/material/Autocomplete'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'
import { DEFAULT_ESTIMATES } from '@/constants/defaultValues'
import { getPropertyData, getZipValues } from '@/utils/addProperty'
import { useNavigate } from 'react-router-dom'
import { statesShort } from '@/utils/states'

const hostname = import.meta.env.VITE_HOSTNAME
const apiKey = import.meta.env.VITE_HOUSEFOLIOS_APIKEY
const apiID = import.meta.env.VITE_HOUSEFOLIOS_APIID
const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY

function MarketplaceAnalyzeComponent(props) {
  const [openAnalyzeProperty, setOpenAnalyzeProperty] =
    useState(false)
  const [openCorrectAddress, setOpenCorrectAddress] = useState(false)
  const [selectedPortfolio, setSelectedPortfolio] = useState(
    head(props.portfolios)._id,
  )
  const [noDups, setNoDups] = useState(true)
  const [loader, setLoader] = useState(false)
  const [progress, setProgress] = useState(0)
  const [assumptionsChange, setAssumptionsChange] = useState('new')
  const [marketplaceAnalysis, setMarketplaceAnalysis] =
    useState(false)

  const [openPortfolio, setOpenPortfolio] = useState(false)

  const [addressFix, setAddress] = useState('')
  const [cityFix, setCity] = useState('')
  const [stateFix, setState] = useState('')
  const [countyFix, setCounty] = useState('')
  const [zipFix, setZip] = useState('')
  const [bedsFix, setBeds] = useState('')
  const [bathsFix, setBaths] = useState('')
  const [sqftFix, setSqft] = useState('')
  const [yearFix, setYear] = useState('')

  const [activeFields, setActiveFields] = useState([])

  const handleOpenPortfolio = () => {
    setOpenPortfolio(true)
  }
  const handleClosePortfolio = () => {
    setOpenPortfolio(false)
  }

  const handleOpenAnalyzeProperty = () => {
    setOpenAnalyzeProperty(true)
    setLoader(false)
    const property = find(props.properties, [
      '_id',
      props.selected[0],
    ])
    if (property) {
      let newDisabled = []
      setAddress(property.address)
      if (!property.address) newDisabled.push('address')
      setCity(property.city)
      if (!property.city) newDisabled.push('city')
      setState(property.state)
      if (!property.state) newDisabled.push('state')
      setCounty(property.county)
      if (!property.county) newDisabled.push('county')
      setZip(property.zip)
      if (!property.zip) newDisabled.push('zip')
      setBeds(property.beds ? property.beds[0] : null)
      if (isEmpty(property.beds)) newDisabled.push('beds')
      setBaths(property.baths ? property.baths[0] : null)
      if (isEmpty(property.baths)) newDisabled.push('baths')
      setSqft(property.sqft ? property.sqft[0] : null)
      if (isEmpty(property.sqft)) newDisabled.push('sqft')
      setYear(property.year || property.yearBuilt)
      if (!property.year && !property.yearBuilt)
        newDisabled.push('year')
      setActiveFields(newDisabled)
    }
  }
  const handleCloseAnalyzeProperty = () => {
    setOpenAnalyzeProperty(false)
    setProgress(0)
    setAddress('')
    setCity('')
    setState('')
    setCounty('')
    setZip('')
    setBeds('')
    setBaths('')
    setSqft('')
    setYear('')
    setActiveFields([])
    handleCloseCorrectAddress()
  }

  const handleOpenCorrectAddress = () => {
    setOpenCorrectAddress(true)
  }
  const handleCloseCorrectAddress = () => {
    setOpenCorrectAddress(false)
    setProgress(0)
  }

  const handleChange = (event) => {
    setSelectedPortfolio(event.target.value)
  }

  const handleNoDups = () => {
    setNoDups(!noDups)
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const correctAddressLocationFields = [
    {
      name: 'address',
      label: 'Address',
      placeholder: 'Address',
      value: addressFix,
      onChange: (event) => setAddress(event.target.value),
      type: 'address',
      definition: 'address',
      width: 12,
    },
    {
      name: 'city',
      label: 'City',
      placeholder: 'City',
      value: cityFix,
      onChange: (event) => setCity(event.target.value),
      definition: 'city',
      width: 6,
    },
    {
      name: 'county',
      label: 'County',
      placeholder: 'County',
      value: countyFix,
      onChange: (event) => setCounty(event.target.value),
      definition: 'county',
      width: 6,
    },
    {
      name: 'state',
      label: 'State',
      placeholder: 'State',
      value: stateFix,
      select: true,
      options: statesShort(),
      onChange: (event) => setState(event.target.value),
      definition: 'state',
      width: 6,
    },
    {
      name: 'zip',
      label: 'Zip',
      placeholder: 'Zip',
      value: zipFix,
      onChange: (event) => setZip(event.target.value),
      definition: 'zip',
      type: 'number',
      width: 6,
    },
  ]

  const correctAddressPropertyDetailsFields = [
    {
      name: 'beds',
      label: 'Beds',
      placeholder: 'Beds',
      value: bedsFix,
      autoFocus: true,
      onChange: (event) => setBeds(event.target.value),
      type: 'number',
      definition: 'beds',
      width: 6,
    },
    {
      name: 'baths',
      label: 'Baths',
      placeholder: 'Baths',
      value: bathsFix,
      onChange: (event) => setBaths(event.target.value),
      type: 'number',
      definition: 'baths',
      width: 6,
    },
    {
      name: 'sqft',
      label: 'Sqft',
      placeholder: 'Sqft',
      value: sqftFix,
      onChange: (event) => setSqft(event.target.value),
      type: 'number',
      definition: 'sqft',
      width: 6,
    },
    {
      name: 'year',
      label: 'Year',
      placeholder: 'Year',
      value: yearFix,
      onChange: (event) => setYear(event.target.value),
      type: 'number',
      definition: 'yearBuilt',
      width: 6,
    },
  ]

  const [copyProperties] = useMutation(COPY_PROPERTIES)
  const [addProperties] = useMutation(ADD_PROPERTIES)
  const [imageUploadMulti] = useMutation(IMAGE_UPLOAD_MULTI)
  const [addCompsBackend] = useMutation(ADD_COMPPROPERTY_BACKEND)

  let navigate = useNavigate()

  const { monthlyCount, bonusProperties = 0 } =
    props.session.me.activeOrg.propertyCount

  const { limits = {} } = props.session.me

  const LIMIT = limits.propertyLimit + bonusProperties

  const imageUploader = async (propertyImagePairs, first = false) => {
    imageUploadMulti({
      variables: {
        propertyImagePairs,
        first,
      },
      refetchQueries: [
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [selectedPortfolio],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: map(
              props.portfolios,
              (portfolio) => portfolio._id,
            ),
          },
        },
      ],
    }).catch((error) => {
      console.log(error)
    })
  }

  const handleAnalyze = (client) => () => {
    if (!isEmpty(props.selected)) addSearch(client)
    if (!isEmpty(props.marketplaceSelected))
      handleMarketplaceAnalyze()
  }

  const handleMarketplaceAnalyze = () => {
    //OVERVIEW COMMENT: analyze marketplace properties
    setLoader(true)
    copyProperties({
      //awaitRefetchQueries: true,
      variables: {
        propertyIds: props.marketplaceSelected,
        portfolioId: selectedPortfolio,
        noDuplicates: noDups,
        marketplace: marketplaceAnalysis,
        keepAssumptions: assumptionsChange === 'same',
      },
      refetchQueries: [
        {
          query: GET_ME,
        },
        {
          query: GET_PORTFOLIOS,
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [selectedPortfolio],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: map(
              props.portfolios,
              (portfolio) => portfolio._id,
            ),
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
      ],
    })
      .then(async ({ data }) => {
        if (
          concat(props.selected, props.marketplaceSelected).length > 1
        ) {
          const successful = compact(data.copyProperties)
          if (successful.length > 0)
            enqueueSnackbar(
              `${successful.length} Properties Added to ${
                props?.session?.me?.activeOrg?.member === 'RWN'
                  ? 'My Properties'
                  : find(props.portfolios, { _id: selectedPortfolio })
                      .name
              }`,
              {
                variant: 'success',
                autoHideDuration: 3000,
              },
            )
          if (successful.length < data.copyProperties.length)
            enqueueSnackbar(
              `${
                data.copyProperties.length - successful.length
              } Properties discarded as duplicates`,
              {
                variant: 'error',
                autoHideDuration: 3000,
              },
            )
        } else {
          navigate(
            '/property-details/' +
              head(compact(data?.copyProperties))._id +
              '/Summary',
          )
        }
        handleCloseAnalyzeProperty()
        if (props.setMarketplaceSelected)
          props.setMarketplaceSelected([])
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to add Selected Properties`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseAnalyzeProperty()
      })
  }

  const addSearch = async (client) => {
    //OVERVIEW COMMENT: analyze property search properties
    setLoader(true)
    setProgress(0)
    const portfolioAssumptions = find(props.portfolios, [
      '_id',
      selectedPortfolio,
    ]).assumptions
    const assumptions = portfolioAssumptions
    // ? merge(ASSUMPTIONS_DEFAULT, portfolioAssumptions)
    // : ASSUMPTIONS_DEFAULT

    const propertyData = compact(
      map(props.properties, (property) => {
        if (includes(props.selected, property._id)) return property
      }),
    )

    const dataBatches = await chunk(propertyData, 10)
    const batchNumber = dataBatches.length
    let count = 0

    let inputBatches = []
    for (let i = 0; i < batchNumber; i++) {
      const dataBatch = dataBatches[i]
      const propertyInput = await Promise.all(
        map(dataBatch, async (inputData) => {
          const {
            address = addressFix,
            city = cityFix,
            state = stateFix,
            county = countyFix,
            zip = zipFix,
            beds = bedsFix,
            baths = bathsFix,
            halfBaths = '',
            sqft = sqftFix,
            year = yearFix,
            yearBuilt = '',
            description = '',
          } = inputData

          if (!address || (!zip && !city)) {
            //failed.push(inputData)
            return null
          }

          const [zipValues] = await getZipValues(client, zip)

          let housefoliosARV = ''
          let housefoliosRent = ''
          let housefoliosListPrice = ''
          let housefoliosARVBySqft = ''
          let housefoliosARVByBed = ''
          let housefoliosRentBySqft = ''
          let housefoliosRentByBed = ''
          let housefoliosRentByBedAndSqft = ''
          let rentometer = ''
          let rentometerLow = ''
          let rentometerHigh = ''
          if (zipValues) {
            if (sqft) {
              housefoliosARVBySqft =
                zipValues.arvSqft *
                Number(sqft ? sqft.replace(/[^0-9.]+/g, '') : '')
              housefoliosRentBySqft =
                zipValues.rentSqft *
                Number(sqft ? sqft.replace(/[^0-9.]+/g, '') : '')
              housefoliosListPrice =
                zipValues.listSqft *
                Number(sqft ? sqft.replace(/[^0-9.]+/g, '') : '')
              housefoliosARV = housefoliosARVBySqft
              housefoliosRent = housefoliosRentBySqft
            }
            if (beds) {
              const bedsInt = Number(beds)
              if (bedsInt >= 5) {
                housefoliosARVByBed = zipValues.arvBedPlus
                housefoliosRentByBed = zipValues.rentBedPlus
                housefoliosRentByBedAndSqft =
                  zipValues.rentSqftBedPlus * sqft
              } else if (bedsInt === 4) {
                housefoliosARVByBed = zipValues.arvBed4
                housefoliosRentByBed = zipValues.rentBed4
                housefoliosRentByBedAndSqft =
                  zipValues.rentSqftBed4 * sqft
                rentometer = zipValues.rentometerBed4
                rentometerLow = zipValues.rentometerLowBed4
                rentometerHigh = zipValues.rentometerHighBed4
              } else if (bedsInt === 3) {
                housefoliosARVByBed = zipValues.arvBed3
                housefoliosRentByBed = zipValues.rentBed3
                housefoliosRentByBedAndSqft =
                  zipValues.rentSqftBed3 * sqft
                rentometer = zipValues.rentometerBed3
                rentometerLow = zipValues.rentometerLowBed3
                rentometerHigh = zipValues.rentometerHighBed3
              } else if (bedsInt === 2) {
                housefoliosARVByBed = zipValues.arvBed2
                housefoliosRentByBed = zipValues.rentBed2
                housefoliosRentByBedAndSqft =
                  zipValues.rentSqftBed2 * sqft
                rentometer = zipValues.rentometerBed2
                rentometerLow = zipValues.rentometerLowBed2
                rentometerHigh = zipValues.rentometerHighBed2
              } else if (bedsInt === 1) {
                housefoliosARVByBed = zipValues.arvBed1
                housefoliosRentByBed = zipValues.rentBed1
                housefoliosRentByBedAndSqft =
                  zipValues.rentSqftBed1 * sqft
                rentometer = zipValues.rentometerBed1
                rentometerLow = zipValues.rentometerLowBed1
                rentometerHigh = zipValues.rentometerHighBed1
              } else if (bedsInt === 0) {
                rentometer = zipValues.rentometerBed0
                rentometerLow = zipValues.rentometerLowBed0
                rentometerHigh = zipValues.rentometerHighBed0
              }
              if (housefoliosARVByBed > housefoliosARV) {
                housefoliosARV = housefoliosARVByBed
              }
              if (housefoliosRentByBed > housefoliosRent)
                housefoliosRent = housefoliosRentByBed
              if (housefoliosRentByBedAndSqft > housefoliosRent)
                housefoliosRent = housefoliosRentByBedAndSqft
              if (rentometerHigh > housefoliosRent)
                housefoliosRent = rentometerHigh
            }
          }
          if (!housefoliosListPrice && assumptions)
            if (inputData.afterRepairValue)
              housefoliosListPrice =
                (Number(
                  inputData.afterRepairValue.replace(/[^0-9.]+/g, ''),
                ) *
                  assumptions.estimatedListPrice) /
                100
            else
              housefoliosListPrice =
                (housefoliosARV * assumptions.estimatedListPrice) /
                100

          if (!housefoliosRent && assumptions)
            if (assumptions.estimatedRentType === '%')
              housefoliosRent = inputData.listPrice
                ? (inputData.listPrice.replace(/[^0-9.]+/g, '') *
                    assumptions.estimatedRent) /
                  100
                : (housefoliosListPrice * assumptions.estimatedRent) /
                  100
            else if (assumptions.estimatedRentType === 'Rent/Sqft')
              housefoliosRent = sqft
                ? assumptions.estimatedRent * Number(sqft)
                : ''
            else housefoliosRent = assumptions.estimatedRent

          let housefoliosRehab = ''
          if (assumptions)
            if (assumptions.estimatedRehabType === 'SQFT $' && sqft)
              housefoliosRehab =
                assumptions.estimatedRehab * Number(sqft)
            else if (assumptions.estimatedRehabType === 'Total $')
              housefoliosRehab = assumptions.estimatedRehab

          let addedToMarket = new Date()
          if (inputData.daysOnMarket) {
            addedToMarket.setDate(
              addedToMarket.getDate() - inputData.daysOnMarket,
            )
          }

          const smartAssetResult = await client.query({
            query: GET_SMART_ASSET,
            variables: {
              county: zipValues ? zipValues.county : '',
              state: state,
            },
            errorPolicy: 'ignore',
          })
          const smartAssetData = !smartAssetResult.data
            ? null
            : smartAssetResult.data.smartAsset

          // let airdna = null
          // if (
          //   hostname !== 'localhost' &&
          //   hostname !== 'dev-admin.housefolios.com'
          // ) {
          //   const airdnaResult = await client.query({
          //     query: CALL_AIR_DNA,
          //     variables: {
          //       address,
          //       city,
          //       state,
          //       zip: padStart(
          //         truncate(zip, {
          //           length: 5,
          //           omission: '',
          //         }),
          //         5,
          //         '0',
          //       ),
          //       beds: Number(beds),
          //       baths: Number(baths),
          //     },
          //     errorPolicy: 'ignore',
          //   })
          //   const data = airdnaResult.data
          //   const error = airdnaResult.error
          //   if (error) {
          //     console.log(error)
          //   }
          //   airdna = !data ? null : data.callAirDNA
          // }

          let preferences = merge(
            cloneDeep(DEFAULT_PREFERENCES),
            props?.session?.me?.activeOrg?.memberPreferences,
            props?.session?.me?.activeOrg?.preferences,
          )

          forEach(preferences, (preference, key) => {
            let newPreference = preference
            const newValues = filter(
              DEFAULT_PREFERENCES[key],
              (x) => !includes(newPreference, x),
            )
            newPreference = filter(newPreference, (x) =>
              includes(DEFAULT_PREFERENCES[key], x),
            )
            newPreference = concat(newPreference, newValues)
            preferences[key] = newPreference
          })

          const propertyDataInput = {
            single: true,
            formattedAddress: `${
              address || addressFix
            } ${city}, ${county} ${padStart(
              truncate(zip, {
                length: 5,
                omission: '',
              }),
              5,
              '0',
            )}`,
          }

          let { propertyData, reapiPropertyData } =
            await getPropertyData(client, propertyDataInput)

          // const avm = head(result)

          //SPECIFIC COMMENT: Only listprice needed for now
          let preferencesValues = {
            house: {
              // listPrice:
              //   propertyData
              //     ? propertyData.PRICE
              //     : '',
            },
            housefoliosAssumption: {
              listPrice: inputData.afterRepairValue
                ? (Number(
                    inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    ),
                  ) *
                    assumptions.estimatedListPrice) /
                  100
                : (housefoliosARV * assumptions.estimatedListPrice) /
                  100,
            },
          }

          const analysisListPrice = Number(
            inputData.price
              ? inputData.price.replace(/[^0-9.]+/g, '')
              : head(
                  compact(
                    map(
                      preferences.listPrice,
                      (pref) => preferencesValues[pref].listPrice,
                    ),
                  ),
                ) || 0,
          )

          //SPECIFIC COMMENT: Second preferences calculation can use calculated analysisListPrice
          preferencesValues = {
            listPriceEstimate: {
              propertyTaxes:
                inputData.listPrice && zipValues
                  ? Number(
                      inputData.listPrice.replace(/[^0-9.]+/g, ''),
                    ) * zipValues.taxesPer
                  : (analysisListPrice || housefoliosListPrice) &&
                      zipValues
                    ? (analysisListPrice || housefoliosListPrice) *
                      zipValues.taxesPer
                    : '',
            },
            house: {
              // listPrice:
              //   propertyData
              //     ? propertyData.PRICE
              //     : '',
            },
            // housefoliosSqft: {
            //   listPrice:
            //     zipValues && sqft ? zipValues.listSqft * sqft : '',
            // },
            housefoliosGreatest: {
              rent: housefoliosRent,
              afterRepairValue: housefoliosARV,
            },
            housefoliosAssumption: {
              rehabCost: housefoliosRehab || '',
              rent: assumptions
                ? assumptions.estimatedRentType === '$'
                  ? assumptions.estimatedRent
                  : assumptions.estimatedRentType === 'Rent/Sqft'
                    ? sqft
                      ? assumptions.estimatedRent * sqft
                      : ''
                    : analysisListPrice || housefoliosListPrice
                      ? ((analysisListPrice || housefoliosListPrice) *
                          assumptions.estimatedRent) /
                        100
                      : ''
                : '',
              listPrice: inputData.afterRepairValue
                ? (Number(
                    inputData.afterRepairValue.replace(
                      /[^0-9.]+/g,
                      '',
                    ),
                  ) *
                    assumptions.estimatedListPrice) /
                  100
                : (housefoliosARV * assumptions.estimatedListPrice) /
                  100,
              propertyTaxes: assumptions
                ? inputData.listPrice &&
                  assumptions.estimatedTaxesType === '%'
                  ? (Number(
                      inputData.listPrice.replace(/[^0-9.]+/g, ''),
                    ) *
                      assumptions.estimatedTaxes) /
                    100
                  : (analysisListPrice || housefoliosListPrice) &&
                      assumptions.estimatedTaxesType === '%'
                    ? ((analysisListPrice || housefoliosListPrice) *
                        assumptions.estimatedTaxes) /
                      100
                    : assumptions.estimatedTaxesType === '$'
                      ? assumptions.estimatedTaxes
                      : ''
                : '',
              offerPrice: assumptions
                ? inputData.listPrice
                  ? (inputData.listPrice.replace(/[^0-9.]+/g, '') *
                      assumptions.offerToListPriceRatio) /
                    100
                  : ((analysisListPrice || housefoliosListPrice) *
                      assumptions.offerToListPriceRatio) /
                      100 || ''
                : '',
            },
            // housefoliosLow: {
            //   afterRepairValue: avm ? avm.low : 0,
            // },
            // housefoliosAverage: {
            //   afterRepairValue: avm ? avm.finalValue : 0,
            // },
            // housefoliosHigh: {
            //   afterRepairValue: avm ? avm.high : 0,
            // },
            reAPI: {
              afterRepairValue:
                propertyData && propertyData.value
                  ? head(propertyData.value.mean)
                  : '',
            },
            // houseCanary: {
            //   propertyTaxes: propertyData
            //     ? head(propertyData.propertyTaxes)
            //     : '',
            // },
            // houseCanaryLow: {
            //   afterRepairValue:
            //     propertyData && propertyData.value
            //       ? head(propertyData.value.min)
            //       : '',
            //   rent:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.min)
            //       : '',
            // },
            // houseCanaryAverage: {
            //   afterRepairValue:
            //     propertyData && propertyData.value
            //       ? head(propertyData.value.mean)
            //       : '',
            //   rent:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.mean)
            //       : '',
            // },
            // houseCanaryHigh: {
            //   afterRepairValue:
            //     propertyData && propertyData.value
            //       ? head(propertyData.value.max)
            //       : '',
            //   rent:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.max)
            //       : '',
            // },
            averageCompValues: {
              afterRepairValue: 0,
            },
            averageLowestCompValues: {
              afterRepairValue: 0,
            },
            smartAsset: {
              propertyTaxes:
                inputData.listPrice && smartAssetData
                  ? (Number(
                      inputData.listPrice.replace(/[^0-9.]+/g, ''),
                    ) *
                      smartAssetData.avgCountyTaxRate) /
                    100
                  : (analysisListPrice || housefoliosListPrice) &&
                      smartAssetData
                    ? ((analysisListPrice || housefoliosListPrice) *
                        smartAssetData.avgCountyTaxRate) /
                      100
                    : '',
            },
            rentometerHigh: {
              rent: rentometerHigh,
            },
            rentometer: {
              rent: rentometer,
            },
            rentometerLow: {
              rent: rentometerLow,
            },
            // airdna: {
            //   avgRate: airdna
            //     ? airdna.property_stats
            //       ? airdna.property_stats.adr.ltm
            //       : ''
            //     : '',
            //   occupancyRate: airdna
            //     ? airdna.property_stats
            //       ? airdna.property_stats.occupancy.ltm * 100
            //       : ''
            //     : '',
            // },
          }

          const afterRepairValue = Number(
            inputData.afterRepairValue
              ? inputData.afterRepairValue.replace(/[^0-9.]+/g, '')
              : head(
                  compact(
                    map(
                      preferences.afterRepairValue,
                      (pref) =>
                        preferencesValues[pref].afterRepairValue,
                    ),
                  ),
                ) || 0,
          )
          const rehabDollar = assumptions.rehabTier * sqft
          const resellPrice1 = afterRepairValue - rehabDollar
          const buyerProfitTotal =
            (assumptions.belowBuyerProfit / 100) * resellPrice1
          const resellPrice2 = resellPrice1 - buyerProfitTotal
          const closingCostWholesaleDollar =
            assumptions.closingCostWholesaleType === '%'
              ? (assumptions.closingCostWholesale / 100) *
                resellPrice2
              : assumptions.closingCostWholesale
          const resellPrice =
            resellPrice2 - closingCostWholesaleDollar

          const analysisInput = {
            listPrice: round(
              Number(
                inputData.price
                  ? inputData.price.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.listPrice,
                          (pref) => preferencesValues[pref].listPrice,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValue: round(
              Number(
                afterRepairValue
                  ? afterRepairValue
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueBuyHold: round(
              Number(
                afterRepairValue
                  ? afterRepairValue
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueFixFlip: round(
              Number(
                afterRepairValue
                  ? afterRepairValue
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueTurnkey: round(
              Number(
                afterRepairValue
                  ? afterRepairValue
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueSTR: round(
              Number(
                afterRepairValue
                  ? afterRepairValue
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueB2R: round(
              Number(
                afterRepairValue
                  ? afterRepairValue
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueBRRRR: round(
              Number(
                afterRepairValue
                  ? afterRepairValue
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueBOR: round(
              Number(
                afterRepairValue
                  ? afterRepairValue
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            afterRepairValueWholesale: round(
              Number(
                afterRepairValue
                  ? afterRepairValue
                  : head(
                      compact(
                        map(
                          preferences.afterRepairValue,
                          (pref) =>
                            preferencesValues[pref].afterRepairValue,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rent: round(
              Number(
                inputData.rent
                  ? inputData.rent.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rent,
                          (pref) => preferencesValues[pref].rent,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rentBOR: round(
              Number(
                inputData.rentBOR
                  ? inputData.rentBOR.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rent,
                          (pref) => preferencesValues[pref].rent,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            offerPrice: round(
              Number(
                inputData.offerPrice
                  ? inputData.offerPrice.replace(/[^0-9.]+/g, '')
                  : inputData.price
                    ? inputData.price.replace(/[^0-9.]+/g, '')
                    : head(
                        compact(
                          map(
                            preferences.offerPrice,
                            (pref) =>
                              preferencesValues[pref].offerPrice,
                          ),
                        ),
                      ) || 0,
              ),
            ),
            daysOnMarket: Number(
              inputData.daysOnMarket
                ? inputData.daysOnMarket.replace(/[^0-9.]+/g, '')
                : '',
            ),
            addedToMarket: addedToMarket,
            rehabCost: round(
              Number(
                inputData.rehabCost
                  ? inputData.rehabCost.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostBRRRR: round(
              Number(
                inputData.rehabCostBRRRR
                  ? inputData.rehabCostBRRRR.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostBOR: round(
              Number(
                inputData.rehabCostBOR
                  ? inputData.rehabCostBOR.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostFixFlip: round(
              Number(
                inputData.rehabCostFixFlip
                  ? inputData.rehabCostFixFlip.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostTurnkey: round(
              Number(
                inputData.rehabCostTurnkey
                  ? inputData.rehabCostTurnkey.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostSTR: round(
              Number(
                inputData.rehabCostSTR
                  ? inputData.rehabCostSTR.replace(/[^0-9.]+/g, '')
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostWholesale: round(
              Number(
                inputData.rehabCostWholesale
                  ? inputData.rehabCostWholesale.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            rehabCostAstroFlip: round(
              Number(
                inputData.rehabCostAstroFlip
                  ? inputData.rehabCostAstroFlip.replace(
                      /[^0-9.]+/g,
                      '',
                    )
                  : head(
                      compact(
                        map(
                          preferences.rehabCost,
                          (pref) => preferencesValues[pref].rehabCost,
                        ),
                      ),
                    ) || 0,
              ),
            ),
            HOA: Number(
              inputData.HOA
                ? inputData.HOA.replace(/[^0-9.]+/g, '')
                : '',
            ),
            HOAType: inputData.HOAType
              ? inputData.HOAType.replace(/[^0-9.]+/g, '')
              : '',
            propertyTaxes: round(
              Number(
                inputData.propertyTaxes
                  ? inputData.propertyTaxes.replace(/[^0-9.]+/g, '')
                  : inputData.taxes
                    ? inputData.taxes.replace(/[^0-9.]+/g, '')
                    : head(
                        compact(
                          map(
                            preferences.propertyTaxes,
                            (pref) =>
                              preferencesValues[pref].propertyTaxes,
                          ),
                        ),
                      ) || 0,
              ),
            ),
            resellPrice: round(
              Number(
                afterRepairValue
                  ? afterRepairValue // * assumptions.listPriceToSalePriceRatio / 100
                  : head(
                        compact(
                          map(
                            preferences.afterRepairValue,
                            (pref) =>
                              preferencesValues[pref]
                                .afterRepairValue,
                          ),
                        ),
                      )
                    ? head(
                        compact(
                          map(
                            preferences.afterRepairValue,
                            (pref) =>
                              preferencesValues[pref]
                                .afterRepairValue,
                          ),
                        ),
                      )
                    : housefoliosARV
                      ? housefoliosARV // * assumptions.listPriceToSalePriceRatio / 100
                      : '',
              ),
            ),
            resellPriceB2R: round(
              Number(
                afterRepairValue
                  ? afterRepairValue // * assumptions.listPriceToSalePriceRatio / 100
                  : head(
                        compact(
                          map(
                            preferences.afterRepairValue,
                            (pref) =>
                              preferencesValues[pref]
                                .afterRepairValue,
                          ),
                        ),
                      )
                    ? head(
                        compact(
                          map(
                            preferences.afterRepairValue,
                            (pref) =>
                              preferencesValues[pref]
                                .afterRepairValue,
                          ),
                        ),
                      )
                    : housefoliosARV
                      ? housefoliosARV // * assumptions.listPriceToSalePriceRatio / 100
                      : '',
              ),
            ),
            resellPriceBRRRR: round(
              Number(
                afterRepairValue
                  ? afterRepairValue // * assumptions.listPriceToSalePriceRatio / 100
                  : head(
                        compact(
                          map(
                            preferences.afterRepairValue,
                            (pref) =>
                              preferencesValues[pref]
                                .afterRepairValue,
                          ),
                        ),
                      )
                    ? head(
                        compact(
                          map(
                            preferences.afterRepairValue,
                            (pref) =>
                              preferencesValues[pref]
                                .afterRepairValue,
                          ),
                        ),
                      )
                    : housefoliosARV
                      ? housefoliosARV // * assumptions.listPriceToSalePriceRatio / 100
                      : '',
              ),
            ),
            resellPriceFixFlip: round(
              Number(
                afterRepairValue
                  ? afterRepairValue // * assumptions.listPriceToSalePriceRatio / 100
                  : head(
                        compact(
                          map(
                            preferences.afterRepairValue,
                            (pref) =>
                              preferencesValues[pref]
                                .afterRepairValue,
                          ),
                        ),
                      )
                    ? head(
                        compact(
                          map(
                            preferences.afterRepairValue,
                            (pref) =>
                              preferencesValues[pref]
                                .afterRepairValue,
                          ),
                        ),
                      )
                    : housefoliosARV
                      ? housefoliosARV // * assumptions.listPriceToSalePriceRatio / 100
                      : '',
              ),
            ),
            resellPriceTurnkey: round(
              Number(
                afterRepairValue
                  ? afterRepairValue // * assumptions.listPriceToSalePriceRatio / 100
                  : head(
                        compact(
                          map(
                            preferences.afterRepairValue,
                            (pref) =>
                              preferencesValues[pref]
                                .afterRepairValue,
                          ),
                        ),
                      )
                    ? head(
                        compact(
                          map(
                            preferences.afterRepairValue,
                            (pref) =>
                              preferencesValues[pref]
                                .afterRepairValue,
                          ),
                        ),
                      )
                    : housefoliosARV
                      ? housefoliosARV // * assumptions.listPriceToSalePriceRatio / 100
                      : '',
              ),
            ),
            resellPriceSTR: round(
              Number(
                afterRepairValue
                  ? afterRepairValue // * assumptions.listPriceToSalePriceRatio / 100
                  : head(
                        compact(
                          map(
                            preferences.afterRepairValue,
                            (pref) =>
                              preferencesValues[pref]
                                .afterRepairValue,
                          ),
                        ),
                      )
                    ? head(
                        compact(
                          map(
                            preferences.afterRepairValue,
                            (pref) =>
                              preferencesValues[pref]
                                .afterRepairValue,
                          ),
                        ),
                      )
                    : housefoliosARV
                      ? housefoliosARV // * assumptions.listPriceToSalePriceRatio / 100
                      : '',
              ),
            ),
            resellPriceWholesale: round(
              Number(resellPrice > 0 ? resellPrice : 0),
            ),
          }

          let source = {}
          forEach(preferences, (value, key) => {
            if (isArray(value) && !inputData[key])
              source[key] = find(
                value,
                (pref) =>
                  preferencesValues[pref] &&
                  preferencesValues[pref][key],
              )
          })
          if (inputData.taxes) unset(source, 'taxes')
          if (inputData.price) {
            unset(source, 'listPrice')
            unset(source, 'offerPrice')
          }

          let estimates = DEFAULT_ESTIMATES

          estimates = {
            ...estimates,
            // listPriceEstimate: {
            //   ...estimates.listPriceEstimate,
            //   propertyTaxes:
            //     analysisInput && zipValues
            //       ? analysisInput.listPrice * zipValues.taxesPer
            //       : '',
            // },
            house: {
              ...estimates.house,
              // listPrice:
              //   propertyData
              //     ? propertyData.PRICE
              //     : '',
            },
            // housefolios: {
            //   ...estimates.housefolios,
            //   // propertyTaxes:
            //   //   propertyData && zipValues
            //   //     ? propertyData.taxAssessment *
            //   //     zipValues.taxesPer
            //   //     : '',
            // },
            // housefoliosCounty: {
            //   ...estimates.housefoliosCounty,
            //   //propertyTaxes: housefoliosCounty ? housefoliosCounty : '',
            // },
            // housefoliosSqft: {
            //   ...estimates.housefoliosSqft,
            //   afterRepairValue: housefoliosARVBySqft || '',
            //   rent: housefoliosRentBySqft || '',
            //   listPrice:
            //     zipValues && sqft ? zipValues.listSqft * sqft : '',
            // },
            housefoliosAssumption: {
              ...estimates.housefoliosAssumption,
              rehabCost: housefoliosRehab || '',
              rehabCostBRRRR: housefoliosRehab || '',
              rehabCostBOR: housefoliosRehab || '',
              rehabCostFixFlip: housefoliosRehab || '',
              rehabCostTurnkey: housefoliosRehab || '',
              rehabCostSTR: housefoliosRehab || '',
              rehabCostWholesale: housefoliosRehab || '',
              rehabCostAstroFlip: housefoliosRehab || '',
              rent: assumptions
                ? assumptions.estimatedRentType === '$'
                  ? assumptions.estimatedRent
                  : assumptions.estimatedRentType === 'Rent/Sqft'
                    ? sqft
                      ? assumptions.estimatedRent * sqft
                      : ''
                    : analysisInput.listPrice || housefoliosListPrice
                      ? ((analysisInput.listPrice ||
                          housefoliosListPrice) *
                          assumptions.estimatedRent) /
                        100
                      : ''
                : '',
              rentBOR: assumptions
                ? assumptions.estimatedRentBORType === '$'
                  ? assumptions.estimatedRentBOR
                  : assumptions.estimatedRentBORType === 'Rent/Sqft'
                    ? sqft
                      ? assumptions.estimatedRentBOR * sqft
                      : ''
                    : analysisInput.listPrice || housefoliosListPrice
                      ? ((analysisInput.listPrice ||
                          housefoliosListPrice) *
                          assumptions.estimatedRentBOR) /
                        100
                      : ''
                : '',
              listPrice: analysisInput.afterRepairValue
                ? (analysisInput.afterRepairValue *
                    assumptions.estimatedListPrice) /
                  100
                : (housefoliosARV * assumptions.estimatedListPrice) /
                  100,
              propertyTaxes:
                (analysisInput.listPrice || housefoliosListPrice) &&
                assumptions
                  ? assumptions.estimatedTaxesType === '%'
                    ? ((analysisInput.listPrice ||
                        housefoliosListPrice) *
                        assumptions.estimatedTaxes) /
                      100
                    : assumptions.estimatedTaxesType === '$'
                      ? assumptions.estimatedTaxes
                      : ''
                  : '',
              offerPrice: assumptions
                ? ((analysisInput.listPrice || housefoliosListPrice) *
                    assumptions.offerToListPriceRatio) /
                    100 || ''
                : '',
            },
            // housefoliosBed: {
            //   ...estimates.housefoliosBed,
            //   afterRepairValue: housefoliosARVByBed || '',
            //   rent: housefoliosRentByBed || '',
            // },
            // housefoliosBedAndSqft: {
            //   ...estimates.housefoliosBedAndSqft,
            //   rent: housefoliosRentByBedAndSqft || '',
            // },
            // housefoliosLow: {
            //   ...estimates.housefoliosLow,
            //   afterRepairValue: avm ? avm.low : '',
            // },
            // housefoliosAverage: {
            //   ...estimates.housefoliosAverage,
            //   afterRepairValue: avm ? avm.finalValue : '',
            // },
            // housefoliosHigh: {
            //   ...estimates.housefoliosHigh,
            //   afterRepairValue: avm ? avm.high : '',
            // },
            reAPI: {
              ...estimates.reAPI,
              afterRepairValue:
                propertyData && propertyData.value
                  ? head(propertyData.value.mean)
                  : '',
            },
            // houseCanary: {
            //   ...estimates.houseCanary,
            //   propertyTaxes: propertyData
            //     ? head(propertyData.propertyTaxes)
            //     : '',
            // },
            // houseCanaryLow: {
            //   ...estimates.houseCanaryLow,
            //   afterRepairValue:
            //     propertyData && propertyData.value
            //       ? head(propertyData.value.min)
            //       : '',
            //   rent:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.min)
            //       : '',
            //   rentBOR:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.min)
            //       : '',
            // },
            // houseCanaryAverage: {
            //   ...estimates.houseCanaryAverage,
            //   afterRepairValue:
            //     propertyData && propertyData.value
            //       ? head(propertyData.value.mean)
            //       : '',
            //   rent:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.mean)
            //       : '',
            //   rentBOR:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.mean)
            //       : '',
            // },
            // houseCanaryHigh: {
            //   ...estimates.houseCanaryHigh,
            //   afterRepairValue:
            //     propertyData && propertyData.value
            //       ? head(propertyData.value.max)
            //       : '',
            //   rent:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.max)
            //       : '',
            //   rentBOR:
            //     propertyData && propertyData.rentalValue
            //       ? head(propertyData.rentalValue.max)
            //       : '',
            // },
            smartAsset: {
              ...estimates.smartAsset,
              propertyTaxes:
                (analysisInput.listPrice || housefoliosListPrice) &&
                smartAssetData
                  ? ((analysisInput.listPrice ||
                      housefoliosListPrice) *
                      smartAssetData.avgCountyTaxRate) /
                    100
                  : '',
            },
            // zillow: {
            //   ...estimates.zillow,
            //   // rent: propertyData
            //   //   ? propertyData.rentZestimate
            //   //     ? propertyData.rentZestimate.amount[0]._
            //   //     : ''
            //   //   : '',
            //   // afterRepairValue: propertyData
            //   //   ? propertyData.zestimate
            //   //     ? propertyData.zestimate.amount[0]._
            //   //     : ''
            //   //   : '',
            // },
            rentometer: {
              ...estimates.rentometer,
              rent: rentometer,
              rentBOR: rentometer,
            },
            rentometerLow: {
              ...estimates.rentometerLow,
              rent: rentometerLow,
              rentBOR: rentometerLow,
            },
            rentometerHigh: {
              ...estimates.rentometerHigh,
              rent: rentometerHigh,
              rentBOR: rentometerHigh,
            },
            // airdna: {
            //   ...estimates.airdna,
            //   avgRate: airdna
            //     ? airdna.property_stats
            //       ? airdna.property_stats.adr.ltm
            //       : ''
            //     : '',
            //   occupancyRate: airdna
            //     ? airdna.property_stats
            //       ? airdna.property_stats.occupancy.ltm * 100
            //       : ''
            //     : '',
            // },
          }

          return {
            portfolioId: selectedPortfolio,
            address: address || addressFix,
            city: city ? city : cityFix,
            state: state
              ? state
              : zipValues
                ? zipValues.statesShort[0]
                : '',
            county: county
              ? county
              : zipValues
                ? zipValues.county
                : '',
            zip: zip
              ? padStart(
                  truncate(zip, { length: 5, omission: '' }),
                  5,
                  '0',
                )
              : padStart(
                  truncate(zipFix, { length: 5, omission: '' }),
                  5,
                  '0',
                ) || '',
            beds: Number(beds) || Number(bedsFix),
            baths:
              (baths ? Number(baths) : Number(bathsFix)) +
              Number(halfBaths) / 2,
            sqft: Number(
              sqft
                ? sqft.replace(/[^0-9.]+/g, '')
                : sqftFix
                  ? sqftFix.replace(/[^0-9.]+/g, '')
                  : '',
            ),
            year: year
              ? Number(year)
              : Number(yearBuilt) || Number(yearFix),
            analysisInput: analysisInput,
            assumptionsInput: {
              vacancy: parseInt(
                assumptions ? assumptions.vacancy : 0,
              ),
              avgRate: Number(
                head(
                  compact(
                    map(
                      preferences.avgRate,
                      (pref) => preferencesValues[pref].avgRate,
                    ),
                  ),
                ) || 0,
              ),
              occupancyRate: Number(
                head(
                  compact(
                    map(
                      preferences.occupancyRate,
                      (pref) => preferencesValues[pref].occupancyRate,
                    ),
                  ),
                ) || 0,
              ),
            },
            description: toString(description),
            MLSID: props.propertySearch ? inputData.mlsID : '',
            source,
            estimates,
          }
        }),
      )

      count++
      setProgress((count / batchNumber) * 50)

      inputBatches.push(propertyInput)
    }
    let addedProperties = []
    for (let i = 0; i < batchNumber; i++) {
      const propertyInputs = inputBatches[i]
      const valid = compact(propertyInputs)
      const result = await addProperties({
        //awaitRefetchQueries: true,
        variables: {
          propertyInputs: valid,
          noDuplicates: noDups,
          propertySearch: props.propertySearch,
        },
        refetchQueries: [
          {
            query: GET_ME,
          },
          {
            query: GET_PORTFOLIOS,
          },
          {
            query: GET_PROPERTIES,
            variables: { portfolioId: [selectedPortfolio] },
          },
          {
            query: GET_PROPERTIES,
            variables: {
              portfolioId: map(
                props.portfolios,
                (portfolio) => portfolio._id,
              ),
            },
          },
        ],
      })
      const { data } = result
      addedProperties = concat(addedProperties, data.addProperties)

      count++
      setProgress((count / batchNumber) * 50)
    }

    const dup =
      addedProperties.length - compact(addedProperties).length
    if (dup > 0)
      enqueueSnackbar(`${dup} duplicate properties not added`, {
        variant: 'error',
        action: (key) => (
          <Fragment>
            <IconButton
              onClick={() => {
                props.closeSnackbar(key)
              }}
              size="large"
            >
              <Close style={{ color: 'white' }} />
            </IconButton>
          </Fragment>
        ),
      })
    if (compact(addedProperties).length > 0) {
      const propertyFirstImagePairs = compact(
        await Promise.all(
          map(propertyData, async (property) => {
            // let images = await Promise.all(
            //   map(photo.urls, async url => {
            //     return 'https:' + url
            //   }),
            // )
            const propertyAdded = find(
              compact(addedProperties),
              (obj) => obj.address === property.address,
            )
            if (propertyAdded && property.images) {
              // let images = JSON.parse(property.images)
              // images = Object.values(images)
              let images = property.images
              if (!isEmpty(images))
                return {
                  propertyId: propertyAdded._id,
                  imageUrls: [images[0]],
                }
              return null
            } else if (propertyAdded && property.image)
              return {
                propertyId: propertyAdded._id,
                imageUrls: [property.image],
              }
            else if (propertyAdded) {
              const stockpileCheck = await client.query({
                query: GET_STOCKPILE,
                variables: {
                  addressInput: {
                    address: propertyAdded.address,
                    city: propertyAdded.city,
                    county: propertyAdded.county,
                    state: propertyAdded.state,
                  },
                },
                errorPolicy: 'ignore',
              })
              const stockpileValues = stockpileCheck
                ? stockpileCheck.data
                : null
              const { stockedImages } = stockpileValues || {}
              if (stockedImages)
                return {
                  propertyId: propertyAdded._id,
                  imageUrls: compact([stockedImages.first]) || [],
                }
            }
            return null
          }),
        ),
      )
      imageUploader(propertyFirstImagePairs, true)

      const propertyImagePairs = compact(
        await Promise.all(
          map(propertyData, async (property) => {
            // let images = await Promise.all(
            //   map(photo.urls, async url => {
            //     return 'https:' + url
            //   }),
            // )
            const propertyAdded = find(
              compact(addedProperties),
              (obj) => obj.address === property.address,
            )
            if (propertyAdded && property.images) {
              // let images = JSON.parse(property.images)
              // images = Object.values(images)
              let images = property.images
              if (!isEmpty(images))
                return {
                  propertyId: propertyAdded._id,
                  imageUrls: slice(images, 1) || [],
                }
              return null
            } else if (propertyAdded) {
              let images = []
              const stockpileCheck = await client.query({
                query: GET_STOCKPILE,
                variables: {
                  addressInput: {
                    address: propertyAdded.address,
                    city: propertyAdded.city,
                    county: propertyAdded.county,
                    state: propertyAdded.state,
                  },
                },
                errorPolicy: 'ignore',
              })
              const stockpileValues = stockpileCheck
                ? stockpileCheck.data
                : null
              const { stockedImages } = stockpileValues || {}
              if (stockedImages)
                images =
                  !isEmpty(stockedImages.url) || stockedImages.first
                    ? stockedImages.url || []
                    : compact([
                        stockedImages.streetView,
                        stockedImages.staticMap,
                      ]) || []
              let loc = propertyAdded.location || {}
              const { latitude, longitude } = loc
              try {
                if (latitude && longitude) {
                  const MAP_KEY = googleApiKey
                  if (isEmpty(images)) {
                    var service = window.google
                      ? new window.google.maps.StreetViewService()
                      : 0
                    if (service !== 0) {
                      await new Promise((resolve, reject) => {
                        try {
                          service.getPanoramaByLocation(
                            {
                              lat: Number(latitude),
                              lng: Number(longitude),
                            },
                            50,
                            function (panoData) {
                              if (panoData !== null) {
                                var ManLatLng =
                                  panoData.location.latLng
                                const heading =
                                  window.google.maps.geometry.spherical.computeHeading(
                                    ManLatLng,
                                    new window.google.maps.LatLng(
                                      Number(latitude),
                                      Number(longitude),
                                    ),
                                  )
                                images.push(
                                  `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${latitude},${longitude}&heading=${heading}&key=${MAP_KEY}`,
                                )
                              }
                              resolve()
                            },
                          )
                        } catch (error) {
                          console.log(error)
                        }
                      })
                    }
                    images.push(
                      `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&size=400x400&markers=color:blue%7C${latitude},${longitude}&key=${MAP_KEY}`,
                    )
                  }
                  return {
                    propertyId: propertyAdded._id,
                    imageUrls: images || [],
                  }
                }
              } catch (error) {
                console.log(error)
              }
            }
            return null
          }),
        ),
      )
      imageUploader(propertyImagePairs)

      if (!props.session.me.activeOrg.isRealeflow)
        addCompsBackend({
          variables: {
            propertyIds: map(
              propertyImagePairs,
              (pair) => pair.propertyId,
            ),
          },
        })

      if (
        concat(props.selected, props.marketplaceSelected).length > 1
      ) {
        const portfolioName = find(props.portfolios, [
          '_id',
          selectedPortfolio,
        ]).name
        const action = (key) => (
          <Fragment>
            {/* <Button
              style={{ color: 'white' }}
              onClick={() => {
                handlePortfolio(selectedPortfolio)
              }}
            >
              VIEW PORTFOLIO
            </Button> */}
            <a
              href="/my-properties"
              target="_blank"
              style={{ color: 'White' }}
              onClick={() =>
                localStorage.setItem('portfolio', selectedPortfolio)
              }
            >
              Go to Portfolio
            </a>
            <IconButton
              onClick={() => {
                props.closeSnackbar(key)
              }}
              size="large"
            >
              <Close style={{ color: 'white' }} />
            </IconButton>
          </Fragment>
        )
        enqueueSnackbar(
          `Analzyed properties moved to ${
            props?.session?.me?.activeOrg?.member === 'RWN'
              ? 'My Properties'
              : `${portfolioName} portfolio`
          }`,
          {
            variant: 'info',
            persist: true,
            action,
          },
        )
      } else {
        navigate(
          '/property-details/' +
            head(compact(addedProperties))._id +
            '/Summary',
        )
      }
    }
    handleCloseAnalyzeProperty()
    if (props.setSelected) props.setSelected([])
  }

  return (
    <>
      <Button
        fullWidth={true}
        variant="contained"
        color="primary"
        onClick={
          monthlyCount +
            props.selected.length +
            props.marketplaceSelected.length >
          LIMIT
            ? () => props.toggleLimit('property')
            : handleOpenAnalyzeProperty
        }
      >
        Analyze
      </Button>

      <Dialog
        open={openAnalyzeProperty}
        onClose={handleCloseAnalyzeProperty}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Analyze</DialogTitle>
        <DialogContent className="p-4">
          <BlockUi
            blocking={loader}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={
              props.propertySearch ? (
                <div className="text-primary">
                  <p>Analyzing Property</p>
                  <p>{progress.toFixed(1)}% Complete</p>
                </div>
              ) : (
                <div className="text-primary">Analyzing Property</div>
              )
            }
          >
            <DialogContentText className="mb-4">
              {props?.session?.me?.activeOrg?.member === 'RWN'
                ? 'Housefolios automatically gathers data and analyzes the property. Each analyzed property reduces your bonus property amount'
                : 'When a property is moved to a portfolio, Housefolios automatically gathers data and analyzes the property.  Each analyzed property counts towards your monthly limit.'}
            </DialogContentText>
            <Grid container>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  style={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Portfolio
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="selectedPortfolio"
                    value={selectedPortfolio}
                    onChange={handleChange}
                    open={openPortfolio}
                    onOpen={handleOpenPortfolio}
                    onClose={handleClosePortfolio}
                    MenuProps={{ keepMounted: true }}
                    fullWidth={true}
                    label="Portfolio"
                    disabled={
                      props?.session?.me?.activeOrg?.member === 'RWN'
                    }
                  >
                    {props?.session?.me?.activeOrg?.member ===
                      'RWN' && (
                      <MenuItem
                        key="My Properties"
                        value={
                          props.portfolios
                            ? props.portfolios[0]._id
                            : null
                        }
                      >
                        My Properties
                      </MenuItem>
                    )}
                    {props?.session?.me?.activeOrg?.member !==
                      'RWN' &&
                      map(props.portfolios, (option) => (
                        <MenuItem
                          key={option.name}
                          value={option._id}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    <CreatePortfolio
                      dropdown={true}
                      setNewPortfolio={setSelectedPortfolio}
                      closeMenu={handleClosePortfolio}
                    />
                  </Select>
                </FormControl>
              </Grid>
              {!props.propertySearch && (
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{ width: '100%' }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Analysis Values
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="marketplaceAnalysis"
                      value={marketplaceAnalysis}
                      onChange={(event) =>
                        setMarketplaceAnalysis(event.target.value)
                      }
                      MenuProps={{ keepMounted: true }}
                      fullWidth={true}
                      label="Analysis Values"
                    >
                      <MenuItem key="My Properties" value={false}>
                        Analyze With Current Values (Recommended)
                      </MenuItem>
                      <MenuItem key="My Properties" value={true}>
                        Analyze with End Buyer Values
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {!props.propertySearch && (
                <Grid item>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name="assumptionsChange"
                      value={assumptionsChange}
                      onChange={(event) =>
                        setAssumptionsChange(event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="new"
                        control={<Radio />}
                        label={
                          props?.session?.me?.activeOrg?.member ===
                          'RWN'
                            ? 'Apply your assumptions to this property'
                            : "Apply the selected portfolio's assumptions to this property"
                        }
                      />
                      <FormControlLabel
                        value="same"
                        control={<Radio />}
                        label="Keep this property's assumptions the same"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={noDups}
                      onClick={handleNoDups}
                    />
                  }
                  label="Don't import properties that already exist in your account"
                />
              </Grid>
            </Grid>
          </BlockUi>
        </DialogContent>
        <DialogActions className=" pr-4">
          <Grid container direction="row" alignItems="center">
            {props.selected.length === 1 &&
              props.marketplaceSelected.length === 0 &&
              !isEmpty(activeFields) && (
                <Grid item>
                  <Button
                    variant="contained"
                    style={{
                      color: 'black',
                      backgroundColor: '#e3e7eb',
                      boxShadow: 'none',
                    }}
                    disabled={loader}
                    fullWidth={true}
                    onClick={handleOpenCorrectAddress}
                  >
                    <Typography>
                      Fix Address / Property Details
                    </Typography>
                  </Button>
                </Grid>
              )}
            <Typography style={{ flex: 1 }} />
            <Grid item style={{ textAlign: 'right' }}>
              <Button
                onClick={handleCloseAnalyzeProperty}
                variant="text"
                style={{ color: '#008aff' }}
                className="mr-2 shadow-none"
              >
                <Typography
                  style={{ fontWeight: 600, color: '#4290ff' }}
                >
                  Cancel
                </Typography>
              </Button>
              <ApolloConsumer>
                {(client) => (
                  <Button
                    className="btn-first shadow-none"
                    disabled={loader}
                    onClick={handleAnalyze(client)}
                  >
                    <Typography style={{ fontWeight: 600 }}>
                      Analyze
                    </Typography>
                  </Button>
                )}
              </ApolloConsumer>
            </Grid>
          </Grid>
        </DialogActions>
        {/* </BlockUi> */}
      </Dialog>
      {/* </BlockUi> */}
      <Dialog
        // classes={{ paper: 'modal-content bg-white rounded-lg modal-dark' }}
        open={openCorrectAddress}
        onClose={handleCloseCorrectAddress}
        onKeyDown={stopPropagationForTab}
      >
        <DialogTitle>Correct the address</DialogTitle>
        <DialogContent style={{ paddingTop: 15, paddingBottom: 15 }}>
          <BlockUi
            blocking={loader}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={
              <div className="text-primary">Analyzing Property</div>
            }
          >
            <DialogContent>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
                className="mb-0"
              >
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 600, marginBottom: -10 }}
                  >
                    Location
                  </Typography>
                </Grid>
                {map(correctAddressLocationFields, (section) => (
                  <Grid
                    item
                    xs={12}
                    sm={section.width}
                    key={section.label}
                  >
                    {section.select ? (
                      <Autocomplete
                        value={section.value}
                        options={section.options}
                        onChange={(event, newValue) =>
                          section.onChange({
                            target: { value: newValue },
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={section.label}
                            size="small"
                            variant="outlined"
                            margin="dense"
                          />
                        )}
                        disabled={
                          !includes(activeFields, section.name)
                        }
                      />
                    ) : (
                      <TextField
                        variant="outlined"
                        size="small"
                        autoFocus={section.autoFocus}
                        margin="dense"
                        value={section.value}
                        onChange={section.onChange}
                        // id="input-with-icon-textfield134"
                        label={section.label}
                        placeholder={section.placeholder}
                        type={section.type}
                        fullWidth
                        disabled={
                          !includes(activeFields, section.name)
                        }
                        // InputProps={{
                        //   endAdornment: section.definition && propertyData && propertyData[section.definition] && propertyData[section.definition].length > 1 ? (
                        //     <InputAdornment position="end" onClick={event => handleDefinition(section.definition, event.target, () => section.onChange)}>
                        //       <IconButton size='small' style={{ padding: 5 }}>
                        //         <FontAwesomeIcon icon={['fal', 'database']} className="font-size-xl" />
                        //       </IconButton>
                        //     </InputAdornment>
                        //   ) : null
                        // }}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
                className="mb-0"
              >
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 600, marginBottom: -10 }}
                  >
                    Property Details
                  </Typography>
                </Grid>
                {map(
                  correctAddressPropertyDetailsFields,
                  (section) => (
                    <Grid
                      item
                      xs={12}
                      sm={section.width}
                      key={section.label}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        autoFocus={section.autoFocus}
                        margin="dense"
                        value={section.value}
                        onChange={section.onChange}
                        // id="input-with-icon-textfield134"
                        label={section.label}
                        placeholder={section.placeholder}
                        type={section.type}
                        fullWidth
                        disabled={
                          !includes(activeFields, section.name)
                        }
                        // InputProps={{
                        //   endAdornment: section.definition && propertyData && propertyData[section.definition] && propertyData[section.definition].length > 1 ? (
                        //     <InputAdornment position="end" onClick={event => handleDefinition(section.definition, event.target, () => section.onChange)}>
                        //       <IconButton size='small' style={{ padding: 5 }}>
                        //         <FontAwesomeIcon icon={['fal', 'database']} className="font-size-xl" />
                        //       </IconButton>
                        //     </InputAdornment>
                        //   ) : null
                        // }}
                      />
                    </Grid>
                  ),
                )}
              </Grid>
            </DialogContent>
          </BlockUi>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseCorrectAddress}
            variant="text"
            disabled={loader}
          >
            Cancel
          </Button>
          <ApolloConsumer>
            {(client) => (
              <Button
                className="btn-first shadow-none"
                disabled={loader || !bedsFix || !sqftFix || !zipFix}
                onClick={handleAnalyze(client)}
              >
                <Typography style={{ fontWeight: 600 }}>
                  Analyze
                </Typography>
              </Button>
            )}
          </ApolloConsumer>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default withSession(MarketplaceAnalyzeComponent)
