import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'

const MinimalLayout = (props) => {
  const { children, headerDrawerToggle } = props

  return (
    <div
      className={clsx({
        'header-drawer-open': headerDrawerToggle,
      })}
    >
      <Outlet />
    </div>
  )
}

MinimalLayout.propTypes = {
  children: PropTypes.node,
}

const mapStateToProps = (state) => ({
  headerDrawerToggle:
    state.DeprecatedThemeOptions?.headerDrawerToggle,
})

export default connect(mapStateToProps)(MinimalLayout)
