import { gql } from '@apollo/client'

export const GET_TEAM = gql`
  query teamMembers($orgId: ID) {
    teamMembers(orgId: $orgId) {
      _id
      email
      username
      address
      city
      zip
      state
      createdAt
      lastLogin
      profile {
        firstname
        lastname
      }
      preferences
      roles {
        organization {
          _id
          name
        }
        role
      }
      phones
      phone
      profileURL
      userType
      jobTitle
      bioDescription
      restrictedMarketplaces
      userImage {
        url
        url_small
      }
      address
      address2
      city
      state
      zip
      DoB
      citizenship
      FICO
      firstTimeInvestor
      yearsInvesting
      realEstateInvestments
      estimatedNetWorth
      estimatedLiquidAssets
      creditReport
      scheduleOfRealEstate
      personalFinancialStatement
    }
  }
`
