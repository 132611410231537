import React from 'react'

const Legal = (props) => {
  let member =
    props?.member === 'pep'
      ? 'Property Edge Pro'
      : props?.member === 'pacemorby' || props?.member === 'astroflip'
        ? 'Dealsauce'
        : 'Housefolios'

  return (
    <section
      className="section"
      style={{
        backgroundColor: '#FFFFFF',
        padding: '40px 0', // Adds vertical padding to the section
      }}
    >
      <div
        className="container"
        style={{
          maxWidth: '800px', // Limit container width
          margin: '0 auto', // Center container
          padding: '0 20px', // Horizontal padding for small screens
        }}
      >
        <div className="row">
          <div
            className="box-lg"
            style={{
              padding: '20px',
              backgroundColor: '#f9f9f9', // Light background for content area
              borderRadius: '8px', // Rounded corners
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Subtle shadow
            }}
          >
            <h3 style={{ marginBottom: '20px' }}>
              Housefolios Legal Disclaimer
            </h3>
            <p style={{ lineHeight: '1.6', marginBottom: '0' }}>
              Housefolios Inc. (“Housefolios”) provides the use of
              this website and the service available through this
              website, including the software services made available
              through subscriptions offered through this website
              (collectively, the “Service”), for informational
              purposes only, and you should not construe the
              information or other material provided through the
              Service (collectively, “Content”) as legal, tax,
              investment, financial, or other advice. Housefolios is
              not registered as an investment adviser with the
              Securities and Exchange Commission or any other
              governmental or regulatory authority and does not intend
              to register as an investment adviser. You alone assume
              the sole responsibility of evaluating the merits and
              risks associated with the use of Content on the Service
              before making any investment decisions based on the
              Content. In exchange for using the Service, you agree
              not to hold Housefolios or its affiliates liable for any
              possible claim for damages arising from or relating to
              any decision you make based on the Content made
              available to you through the Service. There are risks
              associated with investing in real estate. Loss of
              principal is possible, and investments are likely to be
              highly illiquid. Past investment performance is not a
              guarantee or predictor of future investment performance.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Legal
