import { gql } from '@apollo/client'
import { AssumptionsFragments } from '@/housefolios-components/AssumptionsSettings/fragments'
import { AcquisitionFragments } from '@/housefolios-components/Property/fragments'

export const ADD_PROPERTIES = gql`
  mutation addProperties(
    $propertyInputs: [propertyInput!]
    $noDuplicates: Boolean
    $propertySearch: Boolean
  ) {
    addProperties(
      propertyInputs: $propertyInputs
      noDuplicates: $noDuplicates
      propertySearch: $propertySearch
    ) {
      _id
      estval
      comments
      address
      city
      county
      state
      zip
      beds
      baths
      sqft
      createdAt
      updatedAt
      strategy
      marketplaceStrategy
      strategies
      propertyType
      propertyUnitAmount
      propertyDashboard
      hideAddress
      hideDaysOnMarketplace
      status
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
      portfolio {
        _id
        name
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      acquisition {
        activeStage
        analytics {
          ...analytics
        }
        analysis {
          ...analysis
        }
      }
      assumptions
    }
  }
  ${AcquisitionFragments.analysis}
  ${AcquisitionFragments.analytics}
`

export const APPROVE_PROPERTIES = gql`
  mutation approveProperties(
    $propertyIds: [String]!
    $marketplaceURL: String
  ) {
    approveProperties(
      propertyIds: $propertyIds
      marketplaceURL: $marketplaceURL
    ) {
      _id
      estval
      comments
      address
      city
      county
      state
      zip
      beds
      baths
      sqft
      createdAt
      updatedAt
      strategy
      marketplaceStrategy
      strategies
      propertyType
      propertyUnitAmount
      propertyDashboard
      hideAddress
      hideDaysOnMarketplace
      status
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
      portfolio {
        _id
        name
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      acquisition {
        activeStage
        analytics {
          ...analytics
        }
        analysis {
          ...analysis
        }
      }
      assumptions
    }
  }
  ${AcquisitionFragments.analysis}
  ${AcquisitionFragments.analytics}
`

export const REJECT_PROPERTIES = gql`
  mutation rejectProperties(
    $propertyIds: [String]!
    $marketplaceURL: String
  ) {
    rejectProperties(
      propertyIds: $propertyIds
      marketplaceURL: $marketplaceURL
    ) {
      _id
      estval
      comments
      address
      city
      county
      state
      zip
      beds
      baths
      sqft
      createdAt
      updatedAt
      strategy
      marketplaceStrategy
      strategies
      propertyType
      propertyUnitAmount
      propertyDashboard
      hideAddress
      hideDaysOnMarketplace
      status
      location {
        latitude
        longitude
      }
      year
      images {
        _id
        url
        url_small
      }
      mainImage
      portfolio {
        _id
        name
      }
      createdBy {
        email
        profile {
          firstname
          lastname
        }
      }
      acquisition {
        activeStage
        analytics {
          ...analytics
        }
        analysis {
          ...analysis
        }
      }
      assumptions
    }
  }
  ${AcquisitionFragments.analysis}
  ${AcquisitionFragments.analytics}
`

export const CONTACT_AGENT = gql`
  mutation contactAgent(
    $propertyId: String!
    $message: String
    $contactEmail: [String]
    $ccEmail: [String]
    $bccEmail: [String]
    $marketplaceCard: Boolean
    $firstname: String
    $lastname: String
    $phone: String
    $email: String
    $address: String
    $city: String
    $state: String
    $zip: String
    $buyer: String
    $offerPrice: String
    $exchange1031: Boolean
    $title: String
    $funding: String
    $base64: [String]
    $conditions: String
    $statements: String
    $inspectionPeriod: String
    $daysToClose: String
    $earnestMoney: String
    $entityName: String
    $entityAddress: String
    $entityCity: String
    $entityState: String
    $entityZip: String
    $entityEmail: String
    $entitySigner: String
    $html: String
    $pep: Boolean
    $financing: Boolean
    $RWN: Boolean
    $makeOffer: Boolean
  ) {
    contactAgent(
      propertyId: $propertyId
      message: $message
      contactEmail: $contactEmail
      ccEmail: $ccEmail
      bccEmail: $bccEmail
      marketplaceCard: $marketplaceCard
      firstname: $firstname
      lastname: $lastname
      phone: $phone
      email: $email
      address: $address
      city: $city
      state: $state
      zip: $zip
      buyer: $buyer
      offerPrice: $offerPrice
      exchange1031: $exchange1031
      title: $title
      funding: $funding
      base64: $base64
      conditions: $conditions
      statements: $statements
      inspectionPeriod: $inspectionPeriod
      daysToClose: $daysToClose
      earnestMoney: $earnestMoney
      entityName: $entityName
      entityAddress: $entityAddress
      entityCity: $entityCity
      entityState: $entityState
      entityZip: $entityZip
      entityEmail: $entityEmail
      entitySigner: $entitySigner
      html: $html
      pep: $pep
      financing: $financing
      RWN: $RWN
      makeOffer: $makeOffer
    ) {
      count
    }
  }
`

export const SHARE_MARKETPLACE = gql`
  mutation shareMarketplace(
    $url: String!
    $emails: [String]!
    $sharePermission: String
    $notificationId: String
  ) {
    shareMarketplace(
      url: $url
      emails: $emails
      sharePermission: $sharePermission
      notificationId: $notificationId
    ) {
      count
    }
  }
`

export const ADD_SHARED_MARKETPLACE = gql`
  mutation addSharedMarketplace($token: String!, $orgId: String!) {
    addSharedMarketplace(token: $token, orgId: $orgId) {
      _id
    }
  }
`

export const REMOVE_SHARED_MARKETPLACE = gql`
  mutation removeSharedMarketplace($url: String!, $orgId: String!) {
    removeSharedMarketplace(url: $url, orgId: $orgId) {
      _id
    }
  }
`

export const UPDATE_SHARED_MARKETPLACE = gql`
  mutation updateSharedMarketplace(
    $url: String!
    $orgId: String!
    $sharePermission: String!
  ) {
    updateSharedMarketplace(
      url: $url
      orgId: $orgId
      sharePermission: $sharePermission
    ) {
      _id
    }
  }
`

export const REQUEST_HOUSEFOLIOS_ACCESS = gql`
  mutation requestHousefoliosMarketplaceAccess {
    requestHousefoliosMarketplaceAccess {
      count
    }
  }
`
