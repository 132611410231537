import { gql } from '@apollo/client'

export const DEFAULT_ANALYTICS = {
  buyHold: {},
  fixFlip: {},
  turnkey: {},
  BRRRR: {},
  STR: {},
  B2R: {},
  BOR: {},
  wholesale: {},
  astroFlip: {},
}

export const AcquisitionFragments = {
  analysis: gql`
    fragment analysis on Analysis {
      listPrice
      afterRepairValue
      afterRepairValueB2R
      afterRepairValueBRRRR
      afterRepairValueBOR
      afterRepairValueBuyHold
      afterRepairValueFixFlip
      afterRepairValueTurnkey
      afterRepairValueSTR
      afterRepairValueWholesale
      afterRepairValueAstroFlip
      marketType
      rehabCost
      rehabCostBRRRR
      rehabCostBOR
      rehabCostFixFlip
      rehabCostTurnkey
      rehabCostSTR
      rehabCostWholesale
      rehabCostAstroFlip
      detailedRehabCost
      detailedRehabCostBRRRR
      detailedRehabCostBOR
      detailedRehabCostFixFlip
      detailedRehabCostTurnkey
      detailedRehabCostSTR
      detailedRehabCostWholesale
      detailedRehabCostAstroFlip
      rehabCalcTotal
      rehabCalcTotalBRRRR
      rehabCalcTotalBOR
      rehabCalcTotalFixFlip
      rehabCalcTotalTurnkey
      rehabCalcTotalSTR
      rehabCalcTotalWholesale
      rehabCalcTotalAstroFlip
      customAcquisitionExpenses
      customMonthlyExpenses
      rehabDays
      HOA
      HOAType
      financingRepairs
      financingRepairsBRRRR
      financingRepairsB2R
      financingRepairsFixFlip
      financingRepairsTurnkey
      financingRepairsSTR
      financingRepairsPercentage
      financingRepairsPercentageBRRRR
      financingRepairsPercentageB2R
      financingRepairsPercentageFixFlip
      financingRepairsPercentageTurnkey
      financingRepairsPercentageSTR
      resellPrice
      resellPriceB2R
      resellPriceBRRRR
      resellPriceFixFlip
      resellPriceTurnkey
      resellPriceSTR
      resellPriceWholesale
      daysOnMarket
      addedToMarket
      rent
      rentBOR
      rentLow
      rentLowBOR
      rentHigh
      rentHighBOR
      otherIncome
      propertyTaxes
      offerPrice
      turnkeyOfferPrice
      b2rLotOfferPrice
      b2rConstructionOfferPrice
      b2rMezzanineOfferPrice
      pepAdjustedPriceFixFlip
      pepAdjustedPriceWholesale
      hvacAge
      furnaceAge
      condensingUnitAge
      waterHeaterAge
      roofAge
      area
      house
      neighborhood
      startingOffer
      note
      maxOffer
      userCashOut
      lender
      lotLender
      constructionLender
      mezzanineLender
      createdAt
    }
  `,

  analytics: gql`
    fragment analytics on Analytics {
      buyHold
      turnkey
      fixFlip
      BRRRR
      STR
      B2R
      wholesale
      BOR
      astroFlip
      acquisitionCommissionDollar
      acquisitionCommissionPercent
      acquisitionCommissionDollarTurnkey
      acquisitionCommissionPercentTurnkey
      annualRent
      customAcquisitionExpenseTotal
      customMonthlyExpenseTotal
      grossAnnualRent
      payDownAccelerator
      payDownAcceleratorInHoldingPeriod
      sop
      sopTurnkey
      vacancyCost
    }
  `,
}

//SPECIFIC COMMENT: Old analytics incase we decide to change it.
// analytics: gql`
//     fragment analytics on Analytics {
//       buyHold {
//         belowTarget
//         onTarget
//         aboveTarget
//         maxBuyAndHoldPrice
//         allInCost
//         annualExpenses
//         annualRent
//         anticipatedAnnualAppreciation
//         assetManagementFeeTotal
//         capRate
//         capRateArrow
//         cashFlow
//         cashFlowArrow
//         cashFlowAtPayoff
//         cashOnCashReturn
//         cashOnCashReturnArrow
//         closeCostDollar
//         closeCostPercent
//         corTotal
//         debtCoverageRatio
//         dispositionFeeDollar
//         dispositionFeePercent
//         downPaymentTotal
//         equity
//         financingFeeTotal
//         grossAnnualRent
//         hoaTotal
//         IRR
//         IRRArrow
//         loanAmount
//         loanBalanceAtHoldingPeriodBH
//         maintenanceCost
//         monthlyReservesTypeDollar
//         monthlyReservesTypePercent
//         mp_monthly
//         mpInterestOnlyLoan
//         netOperatingIncome
//         paydownMonthly
//         pointsAmount
//         propertyAppreciationValue
//         propertyPayOffMonths
//         propertyPayOffYears
//         futureSaleValue
//         principalReduction
//         propertyInsuranceDollar
//         propertyInsurancePercent
//         propertyManagementDollar
//         propertyManagementPercentage
//         repairsOutOfPocket
//         salesCommissionTotal
//         sellingClosingCostDollar
//         sellingClosingCostPercent
//         totalBeforeDownPayment
//         totalReturnOnInvestment
//         totalOutOfPocket
//         utilitiesTypeDollar
//         utilitiesTypePercent
//       }
//       turnkey {
//         belowTargetTurnkey
//         onTargetTurnkey
//         aboveTargetTurnkey
//         maxTurnkeyPrice
//         allInCostTurnkey
//         annualExpensesTurnkey
//         annualRent
//         anticipatedAnnualAppreciationTurnkey
//         assetManagementFeeTotalTurnkey
//         capRateTurnkey
//         capRateTurnkeyArrow
//         cashFlowTurnkey
//         cashFlowTurnkeyArrow
//         cashFlowAtPayoffTurnkey
//         cashOnCashReturnTurnkey
//         cashOnCashReturnTurnkeyArrow
//         closeCostDollarTurnkey
//         closeCostPercentTurnkey
//         corTotalTurnkey
//         debtCoverageRatioTurnkey
//         dispositionFeeDollarTurnkey
//         dispositionFeePercentTurnkey
//         downPaymentTotalTurnkey
//         equityTurnkey
//         financingFeeTotalTurnkey
//         grossAnnualRent
//         hoaTotal
//         IRRTurnkey
//         IRRTurnkeyArrow
//         loanAmountTurnkey
//         loanBalanceAtHoldingPeriodTurnkey
//         maintenanceCostTurnkey
//         propertyInsuranceDollarTurnkey
//         propertyInsurancePercentTurnkey
//         mortgageTurnkey
//         mpInterestOnlyLoanTurnkey
//         netROITurnkey
//         netOperatingIncomeTurnkey
//         paydownMonthlyTurnkey
//         propertyAppreciationValueTurnkey
//         futureSaleValueTurnkey
//         principalReductionTurnkey
//         propertyManagementDollarTurnkey
//         propertyManagementPercentageTurnkey
//         propertyPayOffMonthsTurnkey
//         propertyPayOffYearsTurnkey
//         pointsAmountTurnkey
//         monthlyReservesTypeDollarTurnkey
//         monthlyReservesTypePercentTurnkey
//         repairsOutOfPocketTurnkey
//         salesCommissionTotalTurnkey
//         sellingClosingCostDollarTurnkey
//         sellingClosingCostPercentTurnkey
//         totalBeforeDownPaymentTurnkey
//         totalReturnOnInvestmentTurnkey
//         totalOutOfPocketTurnkey
//         utilitiesTypeDollarTurnkey
//         utilitiesTypePercentTurnkey
//       }
//       fixFlip {
//         belowTargetFlip
//         onTargetFlip
//         aboveTargetFlip
//         maxFixAndFlipPrice
//         allInCostFixFlip
//         annualExpensesFixFlip
//         annualRent
//         assetManagementFeeTotalFixFlip
//         closeCostDollarFixFlip
//         closeCostPercentFixFlip
//         corTotalFixFlip
//         debtCoverageRatioFixFlip
//         dispositionFeeDollarFixFlip
//         dispositionFeePercentFixFlip
//         downPaymentTotalFixFlip
//         financingFeeTotalFixFlip
//         grossAnnualRent
//         hoaTotal
//         loanAmountFixFlip
//         loanBalanceAtHoldingPeriod
//         maintenanceCostFixFlip
//         monthlyReservesTypeDollarFixFlip
//         monthlyReservesTypePercentFixFlip
//         mortgageFixFlip
//         mpInterestOnlyLoanFixFlip
//         netOperatingIncomeFixFlip
//         netROIArrow,
//         netROIFixFlip
//         paydownMonthlyFixFlip
//         percentageOfARV
//         percentageOfARVArrow,
//         pointsAmountFixFlip
//         propertyInsuranceDollarFixFlip
//         propertyInsurancePercentFixFlip
//         propertyManagementDollarFixFlip
//         propertyManagementPercentageFixFlip
//         repairsOutOfPocketFixFlip
//         saleProceeds
//         saleProceedsArrow
//         salesCommissionTotalFixFlip
//         sellingClosingCostDollarFixFlip
//         sellingClosingCostPercentFixFlip
//         totalBeforeDownPaymentFixFlip
//         totalOutOfPocketFixFlip
//         utilitiesTypeDollarFixFlip
//         utilitiesTypePercentFixFlip
//       }
//       BRRRR {
//         belowTargetBRRRR
//         onTargetBRRRR
//         aboveTargetBRRRR
//         maxBRRRRPrice
//         allInCostBRRRR
//         annualExpensesBRRRR
//         annualRent
//         anticipatedAnnualAppreciationBRRRR
//         assetManagementFeeTotalBRRRR
//         bridgeDownPaymentTotal
//         bridgeLoanAmount
//         bridgeMortgage
//         bridgeMPInterestOnlyLoan
//         bridgePointsAmount
//         bridgeRehabHoldingCost
//         capRateBRRRR
//         capRateBRRRRArrow
//         cashFlowBRRRR
//         cashFlowBRRRRArrow
//         cashOnCashReturnBRRRR
//         cashOnCashReturnBRRRRArrow
//         closeCostDollarBRRRR
//         closeCostPercentBRRRR
//         corTotalBRRRR
//         debtCoverageRatioBridge
//         debtCoverageRatioRefinance
//         dispositionFeeDollarBRRRR
//         dispositionFeePercentBRRRR
//         grossAnnualRent
//         hoaTotal
//         IRRBRRRR
//         IRRBRRRRArrow
//         loanBalanceAtHoldingPeriodBRRRR
//         maintenanceCostBRRRR
//         maxRefinanceLoanAmount
//         monthlyReservesTypeDollarBRRRR
//         monthlyReservesTypePercentBRRRR
//         netOperatingIncomeBRRRR
//         paydownMonthlyBRRRR
//         principalReductionBRRRR
//         propertyInsurancePercentBRRRR
//         propertyManagementDollarBRRRR
//         propertyManagementPercentageBRRRR
//         refinanceCashFlowAtPayoff
//         refinanceDownPaymentTotal
//         refinancingFeeTotal
//         refinanceFutureSaleValue
//         refinanceLoanAmount
//         refinanceMortgage
//         refinanceMPInterestOnlyLoan
//         refinancePropertyAppreciationValue
//         refinancePropertyInsuranceDollar
//         refinancePropertyInsurancePercent
//         refinancePropertyPayOffMonths
//         refinancePropertyPayOffYears
//         refinancePointsAmount
//         repairsOutOfPocketBRRRR
//         salesCommissionTotalBRRRR
//         sellingClosingCostDollarBRRRR
//         sellingClosingCostPercentBRRRR
//         totalReturnOnInvestmentBRRRR
//         totalOutOfPocketBRRRR
//         utilitiesTypeDollarBRRRR
//         utilitiesTypePercentBRRRR
//       }
//       STR {
//         belowTargetSTR
//         onTargetSTR
//         aboveTargetSTR
//         maxSTRPrice
//         allInCostSTR
//         annualExpensesSTR
//         annualRentSTR
//         anticipatedAnnualAppreciationSTR
//         assetManagementFeeTotalSTR
//         capRateSTR
//         capRateSTRArrow
//         cashFlowSTR
//         cashFlowSTRArrow
//         cashFlowAtPayoffSTR
//         cashOnCashReturnSTR
//         cashOnCashReturnSTRArrow
//         cohostingDollar
//         cohostingPercentage
//         closeCostDollarSTR
//         closeCostPercentSTR
//         corTotalSTR
//         debtCoverageRatioSTR
//         dispositionFeeDollarSTR
//         dispositionFeePercentSTR
//         downPaymentTotalSTR
//         financingFeeTotalSTR
//         grossAnnualRentSTR
//         hoaTotal
//         IRRSTR
//         IRRSTRArrow
//         loanAmountSTR
//         loanBalanceAtHoldingPeriodSTR
//         maintenanceCostSTR
//         monthlyReservesTypeDollarSTR
//         monthlyReservesTypePercentSTR
//         mortgageSTR
//         mpInterestOnlyLoanSTR
//         netOperatingIncomeSTR
//         paydownMonthlySTR
//         pointsAmountSTR
//         propertyAppreciationValueSTR
//         futureSaleValueSTR
//         principalReductionSTR
//         propertyPayOffMonthsSTR
//         propertyPayOffYearsSTR
//         propertyInsuranceDollarSTR
//         propertyInsurancePercentSTR
//         repairsOutOfPocketSTR
//         salesCommissionTotalSTR
//         sellingClosingCostDollarSTR
//         sellingClosingCostPercentSTR
//         totalBeforeDownPaymentSTR
//         totalReturnOnInvestmentSTR
//         totalOutOfPocketSTR
//         utilitiesTypeDollarSTR
//         utilitiesTypePercentSTR
//       }
//       B2R {
//         belowTargetB2R
//         onTargetB2R
//         aboveTargetB2R
//         maxB2RPrice
//         allInCostB2R
//         annualExpensesB2R
//         annualrent
//         anticipatedAnnualAppreciationB2R
//         assetManagementFeeTotalB2R
//         b2rAcquisitionCommissionDollar
//         b2rAcquisitionCommissionPercent
//         b2rCloseCostDollar
//         b2rCloseCostPercent
//         capRateB2R
//         capRateB2RArrow
//         cashFlowB2R
//         cashFlowB2RArrow
//         cashOnCashReturnB2R
//         cashOnCashReturnB2RArrow
//         constructionDownPaymentTotal
//         constructionFinancingFeeTotal
//         constructionFutureSaleValue
//         constructionLoanAmount
//         constructionMortgage
//         constructionMPInterestOnlyLoan
//         constructionMPInterestOnlyLoanTotal
//         constructionPrincipalReduction
//         constructionPropertyAppreciationValue
//         constructionPropertyPayOffMonths
//         constructionPropertyPayOffYears
//         constructionPointsAmount
//         debtCoverageRatioLot
//         debtCoverageRatioConstruction
//         debtCoverageRatioMezzanine
//         dispositionFeeDollarB2R
//         dispositionFeePercentB2R
//         equityB2R
//         grossAnnualRent
//         hoaTotal
//         IRRB2R
//         IRRB2RArrow
//         lotDownPaymentTotal
//         lotFinancingFeeTotal
//         lotFutureSaleValue
//         lotLoanAmount
//         lotMortgage
//         lotMPInterestOnlyLoan
//         lotMPInterestOnlyLoanTotal
//         lotPrincipalReduction
//         lotPropertyAppreciationValue
//         lotPropertyPayOffMonths
//         lotPropertyPayOffYears
//         lotPointsAmount
//         maintenanceCostB2R
//         mezzanineDownPaymentTotal
//         mezzanineFinancingFeeTotal
//         mezzanineFutureSaleValue
//         mezzanineLoanAmount
//         mezzanineMortgage
//         mezzanineMPInterestOnlyLoan
//         mezzanineMPInterestOnlyLoanTotal
//         mezzaninePrincipalReduction
//         mezzaninePropertyAppreciationValue
//         mezzaninePropertyPayOffMonths
//         mezzaninePropertyPayOffYears
//         monthlyReservesTypeDollarB2R
//         monthlyReservesTypePercentB2R
//         netOperatingIncomeB2R
//         propertyInsuranceDollarB2R
//         propertyInsurancePercentB2R
//         propertyManagementDollarB2R
//         propertyManagementPercentageB2R
//         salesCommissionTotalB2R
//         sellingClosingCostDollarB2R
//         sellingClosingCostPercentB2R
//         totalReturnOnInvestmentB2R
//         totalOutOfPocketB2R
//         utilitiesTypeDollarB2R
//         utilitiesTypePercentB2R
//       }
//       wholesale {
//         belowTargetWholesale
//         onTargetWholesale
//         aboveTargetWholesale
//         assignmentFeeDollar
//         buyerProfitTotal
//         buyerProfitArrow
//         rehabDollar
//         saleProceedsWholesale
//         saleProceedsWholesaleArrow
//         closingCostWholesaleDollar
//       }
//       BOR {
//         belowTargetBOR
//         onTargetBOR
//         aboveTargetBOR
//         allInCostBOR
//         annualRentBOR
//         averageAnnualReturnBOR
//         averageAnnualReturnBORArrow
//         BORNetROI
//         closeCostDollarBOR
//         closeCostPercentBOR
//         corTotalBOR
//         grossIncomeBOR
//         IRRBOR
//         IRRBORArrow
//         netOperatingIncomeBOR
//         sellPriceBOR
//       }
//       acquisitionCommissionDollar
//       acquisitionCommissionPercent
//       acquisitionCommissionDollarTurnkey
//       acquisitionCommissionPercentTurnkey
//       payDownAccelerator
//       payDownAcceleratorInHoldingPeriod
//       sop
//       sopTurnkey
//       vacancyCost
//     }
//   }
// `

export const AssetManagementFragments = {
  assetManagement: gql`
    fragment assetManagement on AssetManagement {
      lease {
        rent
        term
        expirationDate
        startDate
        endDate
        files
        updatedBy
        updatedAt
      }
      mortgage {
        principal
        interest
        additionalPrincipal
        mortgageMonth
        updatedBy
        updatedAt
      }
      equity {
        afterRepairValue
        currentLoanAmount
        updatedBy
        updatedAt
      }
      receiveRent {
        collectedAmount
        rentPeriod
        paymentMethod
        collectedDate
        lateFee
        otherIncome
        updatedBy
        updatedAt
      }
      expenses {
        expenseAmount
        expenseType
        expenseDate
        expenseName
        expenseDescription
        updatedBy
        updatedAt
      }
      distributions {
        distributionAmount
        distributionDate
        distributionPayee
        distributionDescription
        updatedBy
        updatedAt
      }
      manager {
        managerType
        companyName
        dateHired
        endDate
        updatedBy
        updatedAt
      }
      lastOwner {
        lastOwnerStatement
        files
        updatedBy
        updatedAt
      }
      vacancy {
        startDate
        endDate
        updatedBy
        updatedAt
      }
      totalOutOfPocket
      originalARV
      createdAt
    }
  `,
}

export const OfferToCloseFragments = {
  offerToClose: gql`
    fragment offerToClose on OfferToClose {
      buyer {
        name
        address
        city
        state
        zip
        note
        updatedBy
        updatedAt
      }
      seller {
        name
        address
        city
        state
        zip
        note
        updatedBy
        updatedAt
      }
      contract {
        dateContractReceived
        offerAmount
        bindingDate
        bindingAmount
        contractCloseDate
        files
        updatedBy
        updatedAt
      }
      earnestMoney {
        earnestMoneyDueDate
        earnestMoneyAmount
        holder
        earnestMoneyReceived
        buyerSignature
        sellerSignature
        files
        updatedBy
        updatedAt
      }
      homeInspection {
        dateReadyForInspection
        dateInspectionOrdered
        dateInspectionReceived
        dateDueDiligence
        files
        updatedBy
        updatedAt
      }
      appraisal {
        dateAppraisalOrdered
        dateAppraisalReceived
        appraisedValue
        files
        updatedBy
        updatedAt
      }
      titleWork {
        titleCompany
        titleCompanyContact
        titleCompanyPhone
        titleCompanyEmail
        titleWorkOrdered
        titleWorkCompleted
        titleOrderInvolvement
        borrowerType
        entity
        applicant
        borrowerFirstName
        borrowerLastName
        borrowerEmail
        borrowerPhone
        sellerType
        sellerEntity
        sellerApplicant
        sellerFirstName
        sellerLastName
        sellerEmail
        sellerPhone
        loanAmount
        purchasePrice
        files
        spruceFiles
        updatedBy
        updatedAt
      }
      closing {
        dateFinalWalkThrough
        closingDate
        dateClosingCompleted
        dateClearToFund
        attorneyInformation
        attorneyPhone
        attorneyEmail
        clearToClose
        files
        updatedBy
        updatedAt
      }
      createdAt
    }
  `,
}
