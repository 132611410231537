/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-30 16:41:43
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-03-21 09:18:25
 * @ Description: Dialog for changing the preferences
 */

import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import map from 'lodash/map'
import merge from 'lodash/merge'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import concat from 'lodash/concat'
import forEach from 'lodash/forEach'
import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'
import isNil from 'lodash/isNil'
import set from 'lodash/set'
import unset from 'lodash/unset'
import head from 'lodash/head'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import slice from 'lodash/slice'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import pullAt from 'lodash/pullAt'
import intersection from 'lodash/intersection'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { arrayMoveImmutable } from 'array-move'
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc'

import { makeStyles } from '@mui/styles'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import { useMutation } from '@apollo/client'
import { UPDATE_ORGANIZATION } from '../mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import BuyBox from '@/housefolios-components/Dialogs/BuyBox'
import { propertyClasses } from '@/housefolios-components/Comps/FindComps'
import useAffiliate from '@/hooks/useAffiliate'
import {
  defaultStrategies,
  filterPreferedStrategies,
  getStrategyName,
} from '@/utils/stratagies'
import usePreferences from '@/hooks/usePreferences'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  sortableHelper: {
    zIndex: 5000,
  },
}))

const Root = styled('div')({
  display: 'flex',
  height: '100%',
  // flexDirection: 'column',
})
const ContentRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: '100%',
})

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  width: '100vw',
  ...(open && {
    width: `calc(100vw - ${drawerWidth}px)`,
    marginLeft: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}))

const DrawerStyled = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
  },
  height: '100%',
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const Content = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const SortableHelper = styled('div')({
  zIndex: 5000,
})

export const DEFAULT_PREFERENCES = {
  rent: [
    // 'houseCanaryHigh',
    // 'houseCanaryAverage',
    // 'houseCanaryLow',
    'housefoliosAssumption',
  ], //'rentometerHigh', 'rentometer', 'rentometerLow'],
  // avgRate: ['airdna'],
  // occupancyRate: ['airdna'],
  afterRepairValue: [
    // 'houseCanaryHigh',
    'averageCompValues',
    // 'houseCanaryAverage',
    // 'houseCanaryLow',
    'reAPI',
  ],
  listPrice: ['house', 'housefoliosAssumption'],
  propertyTaxes: [
    // 'houseCanary',
    'smartAsset',
    'housefoliosAssumption',
  ],
  rehabCost: ['housefoliosAssumption'],
  offerPrice: ['housefoliosAssumption'],
  buyAndHoldOrder: [
    'strategy',
    'targets',
    'monthlyCashflow',
    'acquisition',
    'income',
    'expenses',
    'rehab',
    'financing',
    'projection',
    'cashPositioning',
    'disposition',
    'comparableProperties',
    'deactive',
  ],
  fixAndFlipOrder: [
    'bigPicture',
    'bigPictureSummary',
    'strategy',
    'targets',
    'netProfit',
    'acquisition',
    'income',
    'rehab',
    'holding',
    'financing',
    'disposition',
    'comparableProperties',
    'deactive',
  ],
  turnkeyOrder: [
    'strategy',
    'targets',
    'monthlyCashflow',
    'acquisition',
    'income',
    'expenses',
    'rehab',
    'financing',
    'projection',
    'cashPositioning',
    'disposition',
    'comparableProperties',
    'deactive',
  ],
  BRRRROrder: [
    'strategy',
    'targets',
    'monthlyCashflow',
    'acquisition',
    'income',
    'expenses',
    'rehab',
    'financing',
    'projection',
    'cashPositioning',
    'disposition',
    'comparableProperties',
    'deactive',
  ],
  shortTermRentalOrder: [
    'strategy',
    'targets',
    'monthlyCashflow',
    'acquisition',
    'income',
    'expenses',
    'rehab',
    'financing',
    'projection',
    'cashPositioning',
    'disposition',
    'comparableProperties',
    'deactive',
  ],
  buildToRentOrder: [
    'strategy',
    'targets',
    'monthlyCashflow',
    'income',
    'expenses',
    'financing',
    'endFinancing',
    'projection',
    'cashPositioning',
    'disposition',
    'comparableProperties',
    'deactive',
  ],
  wholesaleOrder: [
    'bigPicture',
    'bigPictureSummary',
    'strategy',
    'targets',
    'netProfit',
    'wholesale',
    'comparableProperties',
    'deactive',
  ],
  BOROrder: [
    'strategy',
    'targets',
    'BOR',
    'BORInputs',
    'comparableProperties',
    'deactive',
  ],
  launchPad: [
    'Property Search',
    'Add Property',
    'Upload Properties',
    'Marketplace',
    'Create Portfolio',
    'Edit Assumptions',
    'Personal',
    'Organization',
    'Team',
    'My Marketplaces',
    'Billing',
    'Add Lot',
    'Tools',
  ],
  contactAgentPreferences: {
    conditions: '',
    statements: '',
    inspectionPeriod: '',
    daysToClose: '',
    earnestMoney: '',
  },
  contactAgentTemplate: {
    conditions: '',
    statements: '',
    inspectionPeriod: '',
    daysToClose: '',
    earnestMoney: '',
  },
  findComps: {
    beds: { min: -1, max: 1 },
    baths: { min: -1, max: 1 },
    sqft: { min: 0.75, max: 1.25 },
    lotSqft: { min: 0, max: 0 },
    year: { min: -10, max: 10 },
    maxDistance: 'Draw Comp Area',
    soldBy: '12',
    activeMLS: '',
    propertyType: '',
    propertyClass: '',
    compCount: 250,
  },
  general: {
    defaultPage: {
      label: 'Launchpad',
      value: '/launchpad',
    },
    defaultStrategy: 'Buy & Hold',
    strategies: [
      {
        value: 'Buy & Hold',
        active: true,
      },
      {
        value: 'Fix & Flip',
        active: true,
      },
      {
        value: 'Turnkey End Buyer',
        active: true,
      },
      {
        value: 'BRRRR',
        active: true,
      },
      {
        value: 'Short Term Rental',
        active: true,
      },
      {
        value: 'Wholesale',
        active: false,
        memberExcluded: ['astroflip'],
      },
      {
        value: 'Build to Rent',
        active: false,
      },
      {
        value: 'BOR',
        active: false,
        memberOnly: 'flippingamerica',
      },
      {
        value: 'Astroflip',
        active: false,
        memberOnly: 'astroflip',
      },
    ],
    // rehabOptions: [
    //   {
    //     value: 'singleRehabValue',
    //     label: 'Single Rehab Value',
    //     active: true,
    //   },
    //   {
    //     value: 'detailedRehab',
    //     label: 'Detailed Rehab',
    //     active: true,
    //   },
    //   {
    //     value: 'rehabTier',
    //     label: 'Rehab Tier',
    //     active: true,
    //   },
    //   {
    //     value: 'flippingAmericaRehab',
    //     label: 'Flipping America Rehab',
    //     active: false,
    //   },
    // ],
    isThisYourProperty: true,
    completePropertyData: true,
  },
}

function OrganizationPreferencesComponent(props) {
  const theme = useTheme()
  const classes = useStyles()
  const [
    openOrganizationPreferences,
    setOpenOrganizationPreferences,
  ] = useState(false)
  const [openBuyBox, setOpenBuyBox] = useState(false)
  const handleOpenOrganizationPreferences = () => {
    setOpenOrganizationPreferences(true)
    setOpenBuyBox(false)
  }
  const handleCloseOrganizationPreferences = () => {
    setOpenOrganizationPreferences(false)
  }

  const [open, setOpen] = React.useState(true)
  const handleDrawerOpen = () => {
    setOpen((prev) => true)
  }
  const handleDrawerClose = () => {
    setOpen((prev) => false)
  }

  const [tab, setTab] = useState('General')

  const [{ affiliate, urlAffiliate }] = useAffiliate({})

  const listItems = [
    {
      name: 'General',
      icon: <SettingsTwoToneIcon />,
    },
    {
      name: 'Field Options',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'database']}
          className="font-size-xl mr-2"
        />
      ),
      // },{
      //   name: 'Comps',
      //   icon: <FontAwesomeIcon icon={['far','house-circle-check']} className="font-size-xl mr-2" />,
    },
    {
      name: 'Strategy Page Layout',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'object-ungroup']}
          className="font-size-xl mr-2"
        />
      ),
    },
    {
      name: 'Launchpad',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'rocket-launch']}
          className="font-size-xl mr-2"
        />
      ),
    },
  ]

  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const [preferences, setPreferences] = useState(
    merge(
      cloneDeep(DEFAULT_PREFERENCES),
      cloneDeep(props?.session?.me?.activeOrg?.memberPreferences),
      cloneDeep(props?.session?.me?.activeOrg?.preferences),
    ),
  )

  useEffect(() => {
    let newPreferences = merge(
      cloneDeep(DEFAULT_PREFERENCES),
      cloneDeep(props?.session?.me?.activeOrg?.memberPreferences),
      cloneDeep(props?.session?.me?.activeOrg?.preferences),
    )
    forEach(newPreferences, (preference, key) => {
      let newPreference = preference
      if (isArray(newPreference)) {
        const newValues = filter(
          DEFAULT_PREFERENCES[key],
          (x) => !includes(newPreference, x),
        )
        newPreference = filter(newPreference, (x) =>
          includes(DEFAULT_PREFERENCES[key], x),
        )
        const deactiveIndex = findIndex(
          newPreference,
          (value) => value === 'deactive',
        )
        if (deactiveIndex >= 0)
          newPreference = concat(
            slice(newPreference, 0, deactiveIndex),
            newValues,
            slice(newPreference, deactiveIndex),
          )
        else newPreference = concat(newPreference, newValues)
      }
      newPreferences[key] = newPreference
    })
    if (
      !includes(
        filterPreferedStrategies(
          preferences,
          props?.session?.me?.activeOrg?.member,
          true,
        ),
        newPreferences.general['defaultStrategy'],
      )
    )
      set(
        newPreferences,
        'general.defaultStrategy',
        head(
          filterPreferedStrategies(
            preferences,
            props?.session?.me?.activeOrg?.member,
            true,
          ),
        ),
      )
    if (
      !isEqual(
        newPreferences,
        props?.session?.me?.activeOrg?.preferences,
      )
    )
      setPreferences(newPreferences)
  }, [props?.session?.me?.activeOrg?.preferences])

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const [openReset, setOpenReset] = useState(false)
  const [resetAll, setResetAll] = useState('all')

  const defaultPages = filter(
    [
      {
        label: 'Launchpad',
        value: '/launchpad',
      },
      {
        label: 'Property Search',
        value: '/property-search',
      },
      {
        label: 'My Properties',
        value: '/my-properties',
      },
      {
        label: 'Housefolios Marketplace',
        value: '/marketplace',
        hide: urlAffiliate,
      },
    ],
    (option) => !option.hide,
  )

  const menuTitle = [
    {
      name: 'listPrice',
      label: 'Asking Price',
    },
    {
      name: 'offerPrice',
      label: 'Purchase Price',
    },
    {
      name: 'afterRepairValue',
      label: 'After Repair Value',
    },
    {
      name: 'rent',
      label: 'Total Rental Income',
    },
    {
      name: 'propertyTaxes',
      label: 'Annual Taxes',
    },
    {
      name: 'rehabCost',
      label: 'Estimated Rehab',
    },
    // {
    //   name: 'avgRate',
    //   label: 'Average Rate',
    // },
    // {
    //   name: 'occupancyRate',
    //   label: 'Occupancy Rate',
    // },
  ]

  const compFields = [
    {
      name: 'beds',
      label: 'Beds Ranges',
      min: -5,
      max: 5,
    },
    {
      name: 'baths',
      label: 'Baths Ranges',
      min: -5,
      max: 5,
    },
    {
      name: 'sqft',
      label: 'SqFt Ranges',
      min: 0,
      max: 2,
      step: 0.25,
      valueLabelFormat: (x) => x * 100 + '%',
    },
    // {
    //   name: 'lotSqft',
    //   label: 'Lot SqFt Ranges',
    //   min: 0,
    //   max: 2,
    //   step: 0.25,
    //   valueLabelFormat: x => (x*100) + '%',
    // },
    {
      name: 'year',
      label: 'Year Built Ranges',
      min: -50,
      max: 50,
    },
    {
      label: 'Max Distance (miles)*',
      placeholder: 'Max Distance (miles)*',
      name: 'maxDistance',
      suffix: 'miles',
      options: [
        'Draw Comp Area',
        '0.25',
        '.5',
        '.75',
        '1',
        '1.5',
        '2',
        '2.5',
        '3',
        '4',
        '5',
      ],
      width: '6',
    },
    {
      label: 'Sold Within (months)*',
      placeholder: 'Sold Within (months)*',
      name: 'soldBy',
      suffix: 'months',
      options: ['3', '6', '12', '18', '24', '36', '48', '60'],
      width: '6',
    },
    // {
    //   label: 'Market',
    //   placeholder: 'Both',
    //   name: 'activeMLS',
    //   options: ['Active MLS', 'Off Market', 'Both'],
    //   width: '6'
    //  },
    {
      label: 'Property Type',
      placeholder: 'Property Type',
      name: 'propertyType',
      options: [
        'ANY',
        'SFR',
        'MFR',
        'LAND',
        'CONDO',
        'MOBILE',
        'OTHER',
      ],
      width: '6',
    },
    {
      label: 'Property Class',
      multiple: true,
      placeholder: 'Property Class',
      name: 'propertyClass',
      options: propertyClasses,
      width: '6',
    },
  ]

  const compParams = {
    beds: {
      min:
        4 + preferences.findComps.beds.min > 0
          ? 4 + preferences.findComps.beds.min
          : 0,
      max:
        4 + preferences.findComps.beds.max > 0
          ? 4 + preferences.findComps.beds.max
          : 0,
    },
    baths: {
      min:
        2 + preferences.findComps.baths.min > 0
          ? 2 + preferences.findComps.baths.min
          : 0,
      max:
        2 + preferences.findComps.baths.max > 0
          ? 2 + preferences.findComps.baths.max
          : 0,
    },
    sqft: {
      min:
        2000 * preferences.findComps.sqft.min > 0
          ? 2000 * preferences.findComps.sqft.min
          : 0,
      max:
        2000 * preferences.findComps.sqft.max > 0
          ? 2000 * preferences.findComps.sqft.max
          : 0,
    },
    year: {
      min:
        1965 + preferences.findComps.year.min > 0
          ? 1965 + preferences.findComps.year.min
          : 0,
      max:
        1965 + preferences.findComps.year.max > 0
          ? 1965 + preferences.findComps.year.max
          : 0,
    },
  }

  const soldBy = preferences.findComps.soldBy
  const maxDistance = preferences.findComps.maxDistance

  const propertyDetailsFields = [
    {
      label: 'Beds Min',
      placeholder: 'Beds Min',
      name: 'beds.min',
      value: compParams.beds.min,
      width: '6',
    },
    {
      label: 'Beds Max',
      placeholder: 'Beds Min',
      name: 'beds.max',
      value: compParams.beds.max,
      required: true,
      width: '6',
    },
    {
      label: 'Baths Min',
      placeholder: 'Baths Min',
      name: 'baths.min',
      value: compParams.baths.min,
      width: '6',
    },
    {
      label: 'Baths Max',
      placeholder: 'Baths Min',
      name: 'baths.max',
      value: compParams.baths.max,
      required: true,
      width: '6',
    },
    {
      label: 'Sqft Min',
      placeholder: 'Sqft Min',
      name: 'sqft.min',
      value: compParams.sqft.min,
      width: '6',
    },
    {
      label: 'Sqft Max',
      placeholder: 'Sqft Min',
      name: 'sqft.max',
      value: compParams.sqft.max,
      required: true,
      width: '6',
    },
    {
      label: 'Year Min',
      placeholder: 'Year Min',
      name: 'year.min',
      value: compParams.year.min,
      width: '6',
    },
    {
      label: 'Year Max',
      placeholder: 'Year Max',
      name: 'year.max',
      value: compParams.year.max,
      required: true,
      width: '6',
    },
  ]

  const otherFields = [
    {
      label: 'Max Distance (miles)*',
      placeholder: 'Max Distance (miles)*',
      value: maxDistance,
      suffix: 'miles',
      options: [
        '0.25',
        '.5',
        '.75',
        '1',
        '1.5',
        '2',
        '2.5',
        '3',
        '4',
        '5',
      ],
      width: '6',
    },
    {
      label: 'Sold Within (months)*',
      placeholder: 'Sold Within (months)*',
      value: soldBy,
      suffix: 'months',
      options: ['3', '6', '12', '18', '24'],
      width: '6',
    },
  ]

  const listDescriptions = {
    house: 'Provided by House',
    housefolios: 'Based on Approx. Assessed Value',
    housefoliosCounty: 'Estimate based on County',
    housefoliosGreatest:
      'Estimate based on the greatest of Sqft/Beds',
    housefoliosSqft: 'Estimate based on Sqft',
    housefoliosAssumption: 'Based on Assumptions',
    housefoliosBed: 'Estimate based on Bedrooms',
    housefoliosBedAndSqft: 'Estimate based on Sqft & Beds',
    smartAsset: 'Provided by Smart Asset',
    // rentometerHigh: 'Provided by Rentometer - High value',
    // rentometer: 'Provided by Rentometer - Mid value',
    // rentometerLow: 'Provided by Rentometer- Low value',
    listPriceEstimate: 'Estimated From Asking Price',
    // housefoliosAverage: 'Average value from Housefoliios',
    // housefoliosHigh: 'High value from Housefoliios',
    // housefoliosLow: 'Low value from Housefoliios',
    reAPI: 'Estimated AVM Provided by REAPI',
    // houseCanary: 'Provided by HouseCanary',
    // houseCanaryAverage: 'Provided by HouseCanary - Mid value',
    // houseCanaryHigh: 'Provided by HouseCanary - High value',
    // houseCanaryLow: 'Provided by HouseCanary - Low value',
    // airdna: 'Provided by AirDNA',
    averageCompValues: 'Adjusted Comp Average',
    averageLowestCompValues: 'Adjusted Lowest 3 Comp Average',
  }

  const [
    {
      buyAndHoldCardsToOrder,
      fixAndFlipCardsToOrder,
      turnkeyCardsToOrder,
      BRRRRCardsToOrder,
      shortTermRentalCardsToOrder,
      buildToRentCardsToOrder,
      wholesaleCardsToOrder,
      BORCardsToOrder,
      astroflipCardsToOrder,
    },
    // { getOrderDefault },
  ] = usePreferences({ activeOrg: props.session.me.activeOrg })

  const menuTitleStategy = filter(
    [
      {
        name: 'buyAndHoldOrder',
        label: 'Buy & Hold',
        cards: buyAndHoldCardsToOrder,
      },
      {
        name: 'fixAndFlipOrder',
        label: 'Fix & Flip',
        cards: fixAndFlipCardsToOrder,
      },
      {
        name: 'turnkeyOrder',
        label: 'Turnkey End Buyer',
        cards: turnkeyCardsToOrder,
      },
      {
        name: 'BRRRROrder',
        label: 'BRRRR',
        cards: BRRRRCardsToOrder,
      },
      {
        name: 'shortTermRentalOrder',
        label: 'Short Term Rental',
        cards: shortTermRentalCardsToOrder,
      },
      {
        name: 'buildToRentOrder',
        label: 'Build to Rent',
        cards: buildToRentCardsToOrder,
      },
      {
        name: 'wholesaleOrder',
        label: 'Wholesale',
        cards: wholesaleCardsToOrder,
        memberExcluded: ['astroflip'],
      },
      {
        name: 'BOROrder',
        label: 'BOR',
        cards: BORCardsToOrder,
        memberOnly: 'flippingamerica',
      },
      {
        name: 'astroflipOrder',
        label: 'Astroflip',
        cards: astroflipCardsToOrder,
        memberOnly: 'astroflip',
      },
    ],
    (strategy) => {
      return (
        (!strategy.memberOnly ||
          strategy.memberOnly ===
            props?.session?.me?.activeOrg?.member) &&
        (!strategy.memberExcluded ||
          !includes(
            strategy.memberExcluded,
            props?.session?.me?.activeOrg?.member,
          ))
      )
    },
  )

  let launchPadButtons = [
    {
      title: 'Property Search',
      subtitle: 'Search active listings',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'magnifying-glass']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
    {
      title: 'Add Property',
      subtitle: 'Listed or off-market',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'location-plus']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
    {
      title: 'Upload Properties',
      subtitle: 'Upload spreadsheet',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'file-excel']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
    {
      title: 'Marketplace',
      subtitle: 'Pre-analyzed deals',
      icon: (
        <FontAwesomeIcon
          icon={['fad', 'sign-hanging']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
    {
      title: 'Create Portfolio',
      subtitle: 'Keep it organiized',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'folder-plus']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
    {
      title: 'Edit Assumptions',
      subtitle: 'Set assumptions & targets',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'sliders']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
    {
      title: 'Personal',
      subtitle: 'Personal Information',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'user']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
    {
      title: 'Organization',
      subtitle: 'Organization Information',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'building']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
    {
      title: 'Team',
      subtitle: 'List of Team Members',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'user-group']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
    {
      title: 'My Marketplaces',
      subtitle: 'Your Marketplaces',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'sign-hanging']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
    {
      title: 'Billing',
      subtitle: 'Plan and Billing details',
      icon: (
        <FontAwesomeIcon
          icon={['far', 'file-lines']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
    {
      title: 'Add Lot',
      subtitle: 'Listed or off-market',
      icon: (
        <FontAwesomeIcon
          icon={['fal', 'map-marked-alt']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
    {
      title: 'Tools',
      subtitle: 'Access various tools',
      icon: (
        <FontAwesomeIcon
          icon={['fas', 'hammer']}
          className="h2 d-block mb-2 mx-auto mt-1 text-success"
        />
      ),
    },
  ]

  if (!props.session.me.activeOrg.manageLots)
    pullAt(launchPadButtons, 11)

  const onSortEnd =
    (items) =>
    ({ oldIndex, newIndex, collection }) => {
      let newPreferences = { ...preferences }
      newPreferences[collection] = intersection(
        newPreferences[collection],
        items,
      )
      newPreferences[collection] = arrayMoveImmutable(
        newPreferences[collection],
        oldIndex,
        newIndex,
      )
      setPreferences(newPreferences)
      updateOrganization({
        variables: {
          id: props.session.me.activeOrg._id,
          organizationInput: {
            preferences: newPreferences,
          },
        },
        refetchQueries: [{ query: GET_ME }],
      })
    }

  const handleChangeSlider = (
    event,
    value,
    collection,
    name,
    commit = false,
  ) => {
    let newPreferences = { ...preferences }
    newPreferences[collection][name] = {
      min: value[0],
      max: value[1],
    }
    setPreferences(newPreferences)
    if (commit) {
      updateOrganization({
        variables: {
          id: props.session.me.activeOrg._id,
          organizationInput: {
            preferences: newPreferences,
          },
        },
        refetchQueries: [{ query: GET_ME }],
      })
    }
  }

  const handleChangeDropdown = (event, value, collection, name) => {
    let newPreferences = { ...preferences }
    let newValue = value
    if (name === 'strategies') {
      newPreferences[collection][name] = map(
        newPreferences[collection][name],
        (preference) => {
          return {
            value: preference.value,
            active: includes(newValue, preference.value),
            memberOnly: preference.memberOnly,
            memberExcluded: preference.memberExcluded,
          }
        },
      )
    } else newPreferences[collection][name] = newValue
    setPreferences(newPreferences)
    updateOrganization({
      variables: {
        id: props.session.me.activeOrg._id,
        organizationInput: {
          preferences: newPreferences,
        },
      },
      refetchQueries: [{ query: GET_ME }],
    })
  }

  const handleReset = () => {
    let newPreferences = merge(
      cloneDeep(DEFAULT_PREFERENCES),
      cloneDeep(props?.session?.me?.activeOrg?.memberPreferences),
    )
    if (resetAll !== 'all') {
      if (tab === 'Comps') {
        newPreferences = { findComps: newPreferences.findComps }
      } else if (tab === 'Strategy Page Layout') {
        newPreferences = {
          buyAndHoldOrder: newPreferences.buyAndHoldOrder,
          fixAndFlipOrder: newPreferences.fixAndFlipOrder,
          turnkeyOrder: newPreferences.turnkeyOrder,
          BRRRROrder: newPreferences.BRRRROrder,
          shortTermRentalOrder: newPreferences.shortTermRentalOrder,
          buildToRentOrder: newPreferences.buildToRentOrder,
          wholesaleOrder: newPreferences.wholesaleOrder,
          BOROrder: newPreferences.BOROrder,
        }
      } else if (tab === 'Launchpad') {
        newPreferences = {
          launchPad: newPreferences.launchPad,
        }
      } else if (tab === 'General') {
        newPreferences = {
          general: newPreferences.general,
        }
      } else {
        unset(newPreferences, 'findComps')
        unset(newPreferences, 'buyAndHoldOrder')
        unset(newPreferences, 'fixAndFlipOrder')
        unset(newPreferences, 'turnkeyOrder')
        unset(newPreferences, 'BRRRROrder')
        unset(newPreferences, 'shortTermRentalOrder')
        unset(newPreferences, 'buildToRentOrder')
        unset(newPreferences, 'wholesaleOrder')
        unset(newPreferences, 'BOROrder')
        unset(newPreferences, 'fieldOptions')
        unset(newPreferences, 'general')
      }
    }
    newPreferences = merge(cloneDeep(preferences), newPreferences)
    setPreferences(newPreferences)
    updateOrganization({
      variables: {
        id: props.session.me.activeOrg._id,
        organizationInput: {
          preferences: newPreferences,
        },
      },
      refetchQueries: [{ query: GET_ME }],
    })
      .then(() => {
        enqueueSnackbar(`Preferences Successfully Reset`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        setOpenReset(false)
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to Reset Preferences`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        setOpenReset(false)
      })
  }

  const SortableItem = SortableElement(({ value }) => (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <FontAwesomeIcon
                icon={['fas', 'grip-vertical']}
                style={{ color: '#D3D3D3' }}
                className="font-size-xl"
              />
            </Grid>
            <Grid item>
              <Typography>
                {listDescriptions[value]?.replace(
                  'Provided by House',
                  'Provided by ' +
                    (affiliate
                      ? affiliate?.companyName || 'House'
                      : 'House'),
                )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  ))

  const SortableList = SortableContainer(({ items, collection }) => {
    return (
      <Grid container spacing={1}>
        {map(items, (value, index) => (
          <SortableItem
            key={`item-${value}`}
            index={index}
            value={value}
            collection={collection}
          />
        ))}
      </Grid>
    )
  })

  const SortablePhoto = SortableElement(({ value }) => (
    <Grid item sm={4} xl={3} className="d-flex">
      {value}
    </Grid>
  ))

  const SortableGallery = SortableContainer(
    ({ items, collection }) => {
      return (
        <Grid container direction="row" spacing={1}>
          {map(items, (value, index) => (
            <SortablePhoto
              key={`item-${value}`}
              index={index}
              value={value}
              collection={collection}
            />
          ))}
        </Grid>
      )
    },
  )

  const SortableLaunchpadItem = SortableElement(({ value }) => (
    <Grid item sm={4} className="d-flex">
      <Button
        style={{ textAlign: 'center' }}
        variant="text"
        className="btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"
      >
        {value.icon}
        <div className="font-weight-bold font-size-md text-black">
          {value.title}
        </div>
        <div className="font-size-md mb-1 text-black-50">
          {value.subtitle}
        </div>
      </Button>
    </Grid>
  ))

  const SortableLaunchpad = SortableContainer(
    ({ items, collection }) => {
      const extra = 3 - (items.length % 3)
      return (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h4">Active</Typography>
          </Grid>
          <Grid item>
            <Card className="card-box">
              <div className="grid-menu grid-menu-3col">
                <Grid container direction="row" spacing={1}>
                  {map(slice(items, 0, 6), (value, index) => (
                    <SortableLaunchpadItem
                      key={`item-${value}`}
                      index={index}
                      value={value}
                      collection={collection}
                    />
                  ))}
                </Grid>
              </div>
            </Card>
          </Grid>
          <Grid item>
            <Typography variant="h4">Available</Typography>
          </Grid>
          <Grid item>
            {/* <Card className="card-box"> */}
            <div className="grid-menu grid-menu-3col">
              <Grid container direction="row" spacing={1}>
                {map(slice(items, 6), (value, index) => (
                  <SortableLaunchpadItem
                    key={`item-${value}`}
                    index={index + 6}
                    value={value}
                    collection={collection}
                  />
                ))}
                {map([...Array(extra)], () => (
                  <Grid item sm={4} />
                ))}
              </Grid>
            </div>
            {/* </Card> */}
          </Grid>
        </Grid>
      )
    },
  )

  return (
    <Box>
      <MenuItem onClick={handleOpenOrganizationPreferences}>
        <FontAwesomeIcon
          icon={['fas', 'asterisk']}
          className="font-size-lg"
          style={{ marginRight: 10, width: '1rem' }}
        />
        <Typography>Organization Preferences</Typography>
      </MenuItem>

      {/* <Button variant='outlined' color='primary' size='small' fullWidth={true} onClick={handleOpenOrganizationPreferences}>
         <Typography>Organization Preferences</Typography>
       </Button> */}
      <Dialog
        open={openOrganizationPreferences}
        onClose={handleCloseOrganizationPreferences}
        fullScreen
      >
        <Root>
          <DrawerStyled
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose} size="large">
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {map(listItems, (section) => (
                <ListItemButton
                  selected={tab === section.name}
                  onClick={() => {
                    setTab(section.name)
                    handleDrawerClose(false)
                  }}
                >
                  <ListItemIcon sx={{ paddingLeft: 2 }}>
                    {section.icon}
                  </ListItemIcon>
                  <ListItemText>{section.name}</ListItemText>
                </ListItemButton>
              ))}
              {!urlAffiliate && (
                <ListItemButton
                  onClick={() => {
                    handleCloseOrganizationPreferences()
                    setOpenBuyBox(true)
                  }}
                >
                  <ListItemIcon sx={{ paddingLeft: 2 }}>
                    <FontAwesomeIcon
                      icon={['far', 'crosshairs']}
                      className="font-size-xl mr-2"
                    />
                  </ListItemIcon>
                  <ListItemText>Buy Box</ListItemText>
                </ListItemButton>
              )}
            </List>
          </DrawerStyled>
          <ContentRoot>
            <CssBaseline />
            {/* <AppBar enableColorOnDark className={classes.appBar}>
           <Toolbar>
             <Typography variant="h6" className={classes.title}>
               Organization Preferences
             </Typography>
             <Typography style={{ flex: 1 }} />
             <Button variant="outlined" margin='dense' style={{ color: 'white', borderColor: 'white' }} onClick={handleCloseOrganizationPreferences}>
               Cancel
             </Button>
           </Toolbar>
         </AppBar> */}
            <AppBarStyled
              position="fixed"
              // sx={{ height: 30 }}
              // className={clsx(classes.appBar, {
              //   [classes.appBarShift]: open,
              // })}
              open={open}
            >
              <Toolbar>
                <MenuButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx({ hide: open })}
                  size="large"
                  sx={{
                    color: 'white',
                    ...(open && { display: 'none' }),
                  }}
                >
                  <MenuIcon />
                </MenuButton>
                <Typography
                  variant="h6"
                  style={{ color: 'white' }}
                  noWrap
                >
                  Organization Preferences
                </Typography>
                <Typography style={{ flex: 1 }} />
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    color: 'white',
                    borderColor: 'white',
                    marginRight: 5,
                  }}
                  onClick={() => setOpenReset(true)}
                >
                  Reset
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ color: 'white', borderColor: 'white' }}
                  onClick={handleCloseOrganizationPreferences}
                >
                  Close
                </Button>
              </Toolbar>
            </AppBarStyled>
            {/* <Drawer
           className={classes.drawer}
           variant="persistent"
           classes={{
             paper: classes.drawerPaper,
           }}
           anchor="left"
         >
           <div className={classes.toolbar} />
            <List>
            {map(listItems, section => (
              <ListItem button onClick={() => setTab(section.name)}>
                <ListItemIcon style={{ paddingLeft: 20 }}>
                  {section.icon}
                </ListItemIcon>
                <ListItemText>{section.name}</ListItemText>
             </ListItem>
           ))}
           </List>
         </Drawer> */}

            {/* <main className={classes.content}>
            <div className={classes.toolbar} /> */}
            <Content open={open}>
              <DrawerHeader />
              {tab === 'General' && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      style={{ paddingTop: 25 }}
                    >
                      General
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 10, paddingBottom: 25 }}
                  >
                    <Typography variant="subtitle1">
                      A list of general preferences for your{' '}
                      {affiliate?.companyName || 'Housefolios'}{' '}
                      account
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Home Page</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Choose which page you want to go to when you log
                      in to {affiliate?.companyName || 'Housefolios'}.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <Autocomplete
                      id="checkboxes-tags-demo"
                      options={defaultPages}
                      value={preferences.general['defaultPage']}
                      onChange={(event, newValue) => {
                        handleChangeDropdown(
                          event,
                          newValue,
                          'general',
                          'defaultPage',
                        )
                      }}
                      disableClearable
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Default Page"
                          placeholder="Default Page"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Strategy</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Choose which strategies you want displayed on
                      your Property Dashboard.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <Autocomplete
                      id="checkboxes-tags-demo"
                      multiple
                      disableCloseOnSelect
                      options={filterPreferedStrategies(
                        preferences,
                        props?.session?.me?.activeOrg?.member,
                        false,
                      )}
                      value={filterPreferedStrategies(
                        preferences,
                        props?.session?.me?.activeOrg?.member,
                        true,
                      )}
                      getOptionLabel={(option) =>
                        getStrategyName(
                          option,
                          props?.session?.me?.activeOrg?.member,
                        )
                      }
                      onChange={(event, newValue) => {
                        if (!isEmpty(newValue))
                          handleChangeDropdown(
                            event,
                            newValue,
                            'general',
                            'strategies',
                          )
                      }}
                      disableClearable
                      // isOptionEqualToValue={(option, value) =>
                      //   option === value.value && value.active
                      // }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Strategies"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Default Strategy
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Choose which is the default strategy used for
                      portfolios.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <Autocomplete
                      id="checkboxes-tags-demo"
                      options={filterPreferedStrategies(
                        preferences,
                        props?.session?.me?.activeOrg?.member,
                        true,
                      )}
                      getOptionLabel={(option) =>
                        getStrategyName(
                          option,
                          props?.session?.me?.activeOrg?.member,
                        )
                      }
                      value={preferences.general['defaultStrategy']}
                      onChange={(event, newValue) => {
                        if (!isEmpty(newValue))
                          handleChangeDropdown(
                            event,
                            newValue,
                            'general',
                            'defaultStrategy',
                          )
                      }}
                      disableClearable
                      isOptionEqualToValue={(option, value) =>
                        option === value.value && value.active
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Strategies"
                        />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Typography variant="h6">Rehab Option</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Choose which rehab options you want avaliable on the Property Dashboard.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <Autocomplete
                      id="checkboxes-tags-demo"
                      multiple
                      disableCloseOnSelect
                      options={map(get(preferences, 'general.rehabOptions'), option => option.label)}
                      value={map(filter(get(preferences, 'general.rehabOptions'), options => options.active), option => option.label)}
                      // getOptionLabel={option => getStrategyName(option, props?.session?.me?.activeOrg?.member)}
                      onChange={(event, newValue) => {
                        if (!isEmpty(newValue))
                          handleChangeDropdown(
                            event,
                            newValue,
                            'general',
                            'rehabOptions',
                          )
                      }}
                      disableClearable
                      // isOptionEqualToValue={(option, value) =>
                      //   option === value.value && value.active
                      // }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Rehab Options"
                        />
                      )}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Add Property</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Toggle on/off the Complete Property Data window
                      while Adding Properties.
                    </Typography>
                    {/* <Typography variant='subtitle2'>Toggle on/off the Is This Your Property and Complete Property Data window while Adding Properties.</Typography> */}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <TextField
                      variant="outlined"
                      margin="dense"
                      select
                      value={
                        preferences.general['isThisYourProperty']
                      }
                      onChange={(event) =>
                        handleChangeDropdown(
                          event,
                          event.target.value,
                          'general',
                          'isThisYourProperty',
                        )
                      }
                      fullWidth={true}
                      label="Is This Your Property"
                    >
                      <MenuItem key="on" value={true}>
                        On
                      </MenuItem>
                      <MenuItem key="off" value={false}>
                        Off
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  >
                    <TextField
                      variant="outlined"
                      margin="dense"
                      select
                      value={
                        preferences.general['completePropertyData']
                      }
                      onChange={(event) =>
                        handleChangeDropdown(
                          event,
                          event.target.value,
                          'general',
                          'completePropertyData',
                        )
                      }
                      fullWidth={true}
                      label="Complete Property Data"
                    >
                      <MenuItem key="on" value={true}>
                        On
                      </MenuItem>
                      <MenuItem key="off" value={false}>
                        Off
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              )}
              {tab === 'Field Options' && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      style={{ paddingTop: 25 }}
                    >
                      Field Options
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 10, paddingBottom: 25 }}
                  >
                    <Typography variant="subtitle1">
                      Change your data source preferences. Rearrange
                      the order by dragging them. The top is your most
                      preferred.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                    // className={classes.accordionRoot}
                    >
                      {map(menuTitle, (title) => (
                        <Accordion
                          expanded={expanded === title.name}
                          onChange={handleChange(title.name)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography
                            // className={classes.accordionHeading}
                            >
                              {title.label}
                            </Typography>
                          </AccordionSummary>
                          <Divider />
                          <AccordionDetails>
                            <SortableHelper>
                              <SortableList
                                items={preferences[title.name]}
                                collection={title.name}
                                onSortEnd={onSortEnd(
                                  preferences[title.name],
                                )}
                                helperClass={classes.sortableHelper}
                              />
                            </SortableHelper>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              )}
              {tab === 'Comps' && (
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      style={{ paddingTop: 25 }}
                    >
                      Comps
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    justifyContent="center"
                    style={{ paddingTop: 10, paddingBottom: 50 }}
                  >
                    {/* <Typography variant='h6'>Change your default values for finding comps. Preferences are in comparasion to the property values.</Typography> */}
                    <Typography variant="subtitle1">
                      Adjusting these ranges helps Housefolios know
                      what you consider a Comp property. They also
                      change the values in the Example section which
                      reflect a Comp search for a Property with 4
                      Beds, 2 Baths, 2000 Sqft and built in 1965
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                      spacing={5}
                    >
                      <Grid item xs={12} sm={6}>
                        <div
                        // className={classes.accordionRoot}
                        >
                          <Grid
                            container
                            direction="column"
                            spacing={1}
                          >
                            {map(compFields, (field) => {
                              if (
                                !isNil(
                                  preferences.findComps[field.name]
                                    .min,
                                )
                              ) {
                                return (
                                  <Grid item xs={12}>
                                    <Typography
                                      style={{ marginBottom: -25 }}
                                    >
                                      {field.label}
                                    </Typography>
                                    <Slider
                                      className="slider-primary my-3"
                                      track="inverted"
                                      valueLabelDisplay="on"
                                      //getAriaValueText={field.label}
                                      onChange={(event, value) => {
                                        handleChangeSlider(
                                          event,
                                          value,
                                          'findComps',
                                          field.name,
                                          false,
                                        )
                                      }}
                                      onChangeCommitted={(
                                        event,
                                        value,
                                      ) => {
                                        handleChangeSlider(
                                          event,
                                          value,
                                          'findComps',
                                          field.name,
                                          true,
                                        )
                                      }}
                                      value={[
                                        preferences.findComps[
                                          field.name
                                        ].min,
                                        preferences.findComps[
                                          field.name
                                        ].max,
                                      ]}
                                      min={field.min}
                                      max={field.max}
                                      step={
                                        field.step ? field.step : 1
                                      }
                                      valueLabelFormat={
                                        field.valueLabelFormat
                                          ? field.valueLabelFormat
                                          : (x) => x
                                      }
                                    />
                                  </Grid>
                                )
                              } else {
                                return (
                                  <Grid
                                    xs={12}
                                    style={{ marginBottom: 10 }}
                                  >
                                    <Autocomplete
                                      id="checkboxes-tags-demo"
                                      options={field.options}
                                      value={
                                        preferences.findComps[
                                          field.name
                                        ]
                                      }
                                      onChange={(event, newValue) => {
                                        handleChangeDropdown(
                                          event,
                                          newValue,
                                          'findComps',
                                          field.name,
                                        )
                                      }}
                                      disableClearable
                                      renderOption={(
                                        props,
                                        option,
                                        { selected },
                                      ) => (
                                        <li {...props}>
                                          <Checkbox
                                            icon={
                                              <CheckBoxOutlineBlank fontSize="small" />
                                            }
                                            checkedIcon={
                                              <CheckBox fontSize="small" />
                                            }
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                          />
                                          {option +
                                            ' ' +
                                            field.suffix}
                                        </li>
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          label={field.label}
                                          placeholder={field.label}
                                        />
                                      )}
                                    />
                                  </Grid>
                                )
                              }
                            })}
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {/* <Typography variant='subtitle1'>Adjusting the sliders and dropdowns change the values below. The values below are an example of Comps for a Property with 4 Beds, 2 Baths, 2000 Sqft and built in 1965</Typography> */}
                        <Card>
                          <CardContent style={{ padding: 10 }}>
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              className="mb-0"
                            >
                              <Grid item xs={12}>
                                <Typography
                                  color="primary"
                                  style={{
                                    fontWeight: 600,
                                    marginBottom: -10,
                                  }}
                                >
                                  Example Comp
                                </Typography>
                              </Grid>
                              {map(
                                propertyDetailsFields,
                                (section) => (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={section.width}
                                  >
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      autoFocus={section.autoFocus}
                                      margin="dense"
                                      value={section.value || ''}
                                      // onChange={event => onChange(event, section.name)}
                                      // id="input-with-icon-textfield134"
                                      label={section.label}
                                      required={section.required}
                                      placeholder={
                                        section.placeholder
                                      }
                                      disabled
                                      type="number"
                                      min={0}
                                      fullWidth
                                    />
                                  </Grid>
                                ),
                              )}
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              className="mb-0"
                            >
                              <Grid item xs={12}></Grid>
                              {map(otherFields, (section) => (
                                <Grid item xs={12} sm={section.width}>
                                  <Autocomplete
                                    id="checkboxes-tags-demo"
                                    options={section.options}
                                    value={section.value}
                                    // onChange={(event, newValue) => {
                                    //   section.setter(newValue)
                                    // }}
                                    disabled
                                    disableClearable
                                    renderOption={(
                                      props,
                                      option,
                                      { selected },
                                    ) => (
                                      <li {...props}>
                                        <Checkbox
                                          icon={
                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                          }
                                          checkedIcon={
                                            <CheckBoxIcon fontSize="small" />
                                          }
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option +
                                          ' ' +
                                          section.suffix}
                                      </li>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        label={section.label}
                                        placeholder={section.label}
                                      />
                                    )}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 'Strategy Page Layout' && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      style={{ paddingTop: 25 }}
                    >
                      Strategy Page Layout
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 10, paddingBottom: 25 }}
                  >
                    <Typography variant="subtitle1">
                      Change your data layout preferences. Rearrange
                      the order by dragging them.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                    // className={classes.accordionRoot}
                    >
                      {map(menuTitleStategy, (title) => (
                        <Accordion
                          expanded={expanded === title.name}
                          onChange={handleChange(title.name)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography
                            // className={classes.accordionHeading}
                            >
                              {getStrategyName(
                                title.label,
                                props?.session?.me?.activeOrg?.member,
                              )}
                            </Typography>
                          </AccordionSummary>
                          <Divider />
                          <AccordionDetails>
                            <SortableHelper>
                              <SortableGallery
                                items={map(
                                  sortBy(title.cards, [
                                    function (o) {
                                      return findIndex(
                                        preferences[title.name],
                                        (value) => value === o.name,
                                      )
                                    },
                                  ]),
                                  (card) => card.card,
                                )}
                                collection={title.name}
                                onSortEnd={onSortEnd(
                                  map(
                                    title.cards,
                                    (card) => card.name,
                                  ),
                                )}
                                helperClass={classes.sortableHelper}
                                axis="xy"
                              />
                            </SortableHelper>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              )}
              {tab === 'Launchpad' && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      style={{ paddingTop: 25 }}
                    >
                      Launchpad
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 10, paddingBottom: 25 }}
                  >
                    <Typography variant="subtitle1">
                      Customize the Launchpad action buttons to your
                      liking.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <SortableHelper>
                      <SortableLaunchpad
                        items={sortBy(launchPadButtons, [
                          (o) => {
                            const idx = findIndex(
                              preferences['launchPad'],
                              (value) => value === o.title,
                            )
                            return idx === -1 ? 9999 : idx
                          },
                        ])}
                        collection={'launchPad'}
                        onSortEnd={onSortEnd(
                          map(
                            launchPadButtons,
                            (button) => button.title,
                          ),
                        )}
                        helperClass={classes.sortableHelper}
                        axis="xy"
                      />
                    </SortableHelper>
                  </Grid>
                </Grid>
              )}
            </Content>
          </ContentRoot>
        </Root>
      </Dialog>

      <Dialog open={openReset} onClose={() => setOpenReset(false)}>
        <DialogTitle>Reset Preferences</DialogTitle>
        <DialogContent>
          <FormControl
            component="fieldset"
            // className={classes.formControl}
          >
            <RadioGroup
              aria-label="reset action"
              name="resetAll"
              // className={classes.group}
              value={resetAll}
              onChange={(event) => setResetAll(event.target.value)}
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                label={'Reset all preferences to default settings'}
              />
              <FormControlLabel
                value="tab"
                control={<Radio />}
                label={`Reset preferences in ${tab} to default settings`}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReset(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleReset}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {!urlAffiliate && <BuyBox isOpen={openBuyBox} />}
    </Box>
  )
}
export default withSession(OrganizationPreferencesComponent)
