/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-29 10:22:56
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-05-23 09:16:16
 * @ Description: Find comps for property based off preferences
 */

import React, { Fragment, useEffect, useState } from 'react'

import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import map from 'lodash/map'
import set from 'lodash/set'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import compact from 'lodash/compact'
import forEach from 'lodash/forEach'
import every from 'lodash/every'
import toLower from 'lodash/toLower'
import replace from 'lodash/replace'
import toString from 'lodash/toString'
import slice from 'lodash/slice'
import isNil from 'lodash/isNil'
import concat from 'lodash/concat'
import truncate from 'lodash/truncate'
import padStart from 'lodash/padStart'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import includes from 'lodash/includes'
import uniqWith from 'lodash/uniqWith'
import orderBy from 'lodash/orderBy'
import meanBy from 'lodash/meanBy'
import uniqBy from 'lodash/uniqBy'
import uniq from 'lodash/uniq'
import has from 'lodash/has'
import sum from 'lodash/sum'
import head from 'lodash/head'
import round from 'lodash/round'

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  ListItem,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  GET_COMPPROPERTIES,
  GET_PROPERTY,
} from '@/housefolios-components/PropertyContainer/queries'
import {
  CALL_REAPI,
  GET_ZIP_BY_CITY_STATE,
} from '@/housefolios-components/PropertySearch/queries'
import {
  ADD_COMPPROPERTY,
  IMAGE_UPLOAD_MULTI,
} from '@/housefolios-components/TitleButtons/AddPropertyButton/mutations'
import { enqueueSnackbar } from 'notistack'
import { ApolloConsumer, useMutation, useQuery } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import {
  Close,
  ContactSupportOutlined,
  Search,
} from '@mui/icons-material'
import Alert from '@mui/material/Alert'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { GET_STATE_VALUE } from '../queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GET_STOCKPILE } from '@/housefolios-components/Marketplace/queries'
import { GET_ZIP_VALUE } from '@/housefolios-components/TitleButtons/AddPropertyButton/queries'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import withSession from '@/housefolios-components/Session/withSession'
import { useStopwatch } from 'react-timer-hook'
import { SAVE_ANALYSIS } from '@/housefolios-components/PropertyContainer/mutations'
import Map from '../../Properties/Map'
import { getZipValues } from '@/utils/addProperty'

const hostname = import.meta.env.VITE_HOSTNAME
const apiKey = import.meta.env.VITE_HOUSEFOLIOS_APIKEY
const apiID = import.meta.env.VITE_HOUSEFOLIOS_APIID
const googleApiKey = import.meta.env.VITE_GOOGLE_APIKEY

export const propertyClasses = [
  '0  UNKNOWN',
  '101 DAIRY FARM',
  '102 DESERT OR BARREN LAND',
  '103 FARM, CROPS',
  '104 FEEDLOTS',
  '105 FARM (IRRIGATED OR DRY)',
  '106 HORTICULTURE, ORNAMENTAL (AGRICULTURAL)',
  '107 IRRIGATION, FLOOD CONTROL',
  '108 LIVESTOCK, ANIMALS',
  '109 MISCELLANEOUS STRUCTURES - RANCH, FARM FIXTURES',
  '110 ORCHARD (FRUIT, NUT)',
  '111 ORCHARDS, GROVES',
  '112 PASTURE',
  '113 POULTRY FARM (CHICKEN, TURKEY, FISH, BEES, RABBITS)',
  '114 RANCH',
  '115 RESERVOIR, WATER SUPPLY',
  '116 RURAL IMPROVED / NON-RESIDENTIAL',
  '117 RANGE LAND (GRAZING)',
  '118 AGRICULTURAL/RURAL (GENERAL)',
  '119 TRUCK CROPS',
  '120 TIMBERLAND, FOREST, TREES',
  '121 VINEYARD',
  '122 WELL SITE (AGRICULTURAL)',
  '123 WILDLIFE (REFUGE)',
  '124 CONVENIENCE STORE (7-11)',
  '125 APPLIANCE STORE (CIRCUIT CITY, GOODS BUYS, BEST BUY)',
  '126 AUTO REPAIR, GARAGE',
  '127 VEHICLE SALES, VEHICLE RENTALS (AUTO/TRUCK/RV/BOAT/ETC)',
  '128 BAKERY',
  '129 BAR, TAVERN',
  '130 COMMERCIAL BUILDING, MAIL ORDER, SHOW ROOM (NON-AUTO), WAREHOUSE',
  '131 BED & BREAKFAST',
  '132 CASINO',
  '133 CEMETERY, FUNERAL HOME, MORTUARY (COMMERCIAL)',
  '134 COMMON AREA (COMMERCIAL, NOT SHOPPING CENTER)',
  '135 COMMERCIAL (GENERAL)',
  '136 COMMERCIAL OFFICE (GENERAL)',
  '137 CONVENIENCE STORE (W/FUEL PUMP)',
  '138 COMMERCIAL CONDOMINIUM (NOT OFFICES)',
  '139 CONDOMINIUM OFFICES',
  '140 STORE/OFFICE (MIXED USE)',
  '141 DEPARTMENT STORE (APPAREL, HOUSEHOLD GOODS, FURNITURE)',
  '142 DENTAL BUILDING',
  '143 DEPARTMENT STORE (MULTI-STORY)',
  '144 GARDEN CENTER, HOME IMPROVEMENT (DO-IT-YOURSELF)',
  '145 DRUG STORE, PHARMACY',
  '146 DRIVE-THRU RESTAURANT, FAST FOOD',
  '147 DRY CLEANER',
  '148 RESTAURANT',
  '149 FARM SUPPLY & EQUIPMENT (COMMERCIAL)',
  '150 FINANCIAL BUILDING',
  '151 GROCERY, SUPERMARKET',
  '152 HOSPITAL - PRIVATE',
  '153 HOTEL/MOTEL',
  '154 HOTEL-RESORT',
  '155 HOTEL',
  '156 KENNEL',
  '157 LAUNDROMAT (SELF-SERVICE)',
  '158 LIQUOR STORE',
  '159 MOBILE COMMERCIAL UNITS',
  '160 MEDICAL BUILDING',
  '161 MIXED USE (COMMERCIAL/INDUSTRIAL)',
  '162 MOBILE HOME PARK, TRAILER PARK',
  '163 MOTEL',
  '164 COMMERCIAL MULTI-PARCEL MISCELLANEOUS',
  '165 COMMERCIAL MISCELLANEOUS',
  '166 NIGHTCLUB (COCKTAIL LOUNGE)',
  '167 NEIGHBORHOOD: SHOPPING CENTER, STRIP CENTER, ENTERPRISE ZONE',
  '168 NURSERY, GREENHOUSE, FLORIST (RETAIL, WHOLESALE)',
  '169 OFFICE BUILDING',
  '170 OFFICE BUILDING (MULTI-STORY)',
  '171 COMMERCIAL OFFICE/RESIDENTIAL (MIXED USE)',
  '172 PARKING GARAGE, PARKING STRUCTURE',
  '173 PRINTER - RETAIL (PIP, QWIKCOPY, ETC)',
  '174 PARKING LOT',
  '175 DAY CARE, PRE-SCHOOL (COMMERCIAL)',
  '176 PROFESSIONAL BUILDING (MULTI-STORY)',
  '177 PROFESSIONAL BUILDING (LEGAL, INSURANCE, REAL ESTATE, BUSINESS)',
  '178 RETAIL STORES (PERSONAL SERVICES, PHOTOGRAPHY, TRAVEL)',
  '179 REGIONAL: SHOPPING CENTER, MALL (W/ANCHOR)',
  '180 GAS STATION',
  '181 SINGLE FAMILY RESIDENTIAL',
  '182 SHOPPING CENTER COMMON AREA (PARKING ETC)',
  '183 COMMUNITY: SHOPPING CENTER, MINI-MALL',
  '184 SKYSCRAPER/HIGH-RISE (COMMERCIAL OFFICES)',
  '185 SERVICE STATION W/CONVENIENCE STORE (FOOD MART)',
  '186 SERVICE STATION (FULL SERVICE)',
  '187 STORES & APARTMENTS',
  '188 STORE, RETAIL OUTLET',
  '189 TAKE-OUT RESTAURANT (FOOD PREPARATION)',
  '190 TRUCK STOP (FUEL AND DINER)',
  '191 SERVICE SHOP (TV, RADIO, ELECTRIC, PLUMBING)',
  '192 VETERINARY, ANIMAL HOSPITAL',
  '193 CAR WASH',
  '194 WHOLESALE OUTLET, DISCOUNT STORE (FRANCHISE)',
  '195 ASSEMBLY (LIGHT INDUSTRIAL)',
  '196 BULK STORAGE, TANKS (GASOLINE, FUEL, ETC)',
  '197 CANNERY',
  '198 CONSTRUCTION/CONTRACTING SERVICES (INDUSTRIAL)',
  '199 CHEMICAL',
  '200 COMMON AREA (INDUSTRIAL)',
  '201 CONDOMINIUMS (INDUSTRIAL)',
  '202 COLD STORAGE',
  '203 DISTILLERY, BREWERY, BOTTLING',
  '204 DUMP SITE',
  '205 FACTORY (APPAREL, TEXTILE, LEATHER, MEDIUM MFG)',
  '206 FOOD PROCESSING',
  '207 FOUNDRY, INDUSTRIAL PLANT (METAL, RUBBER, PLASTIC)',
  '208 FOOD PACKING, PACKING PLANT (FRUIT, VEGETABLE, MEAT, DAIRY)',
  '209 GRAIN ELEVATOR',
  '210 HEAVY INDUSTRIAL (GENERAL)',
  '211 HEAVY MANUFACTURING',
  '212 INDUSTRIAL (GENERAL)',
  '213 INDUSTRIAL PARK',
  '214 LABOR CAMPS (INDUSTRIAL)',
  '215 LIGHT INDUSTRIAL (10% IMPROVED OFFICE SPACE; MACHINE SHOP)',
  '216 INDUSTRIAL LOFT BUILDING, LOFT BUILDING',
  '217 LUMBERYARD, BUILDING MATERIALS',
  '218 LUMBER & WOOD PRODUCT MFG (INCLUDING FURNITURE)',
  '219 MARINE FACILITY/BOARD REPAIRS (SMALL CRAFT, SAILBOAT)',
  '220 MANUFACTURING (LIGHT)',
  '221 MILL (FEED, GRAIN, PAPER, LUMBER, TEXTILE, PULP',
  '222 MINING, MINERAL, QUARRIES',
  '223 INDUSTRIAL MISCELLANEOUS',
  '224 MULTI-TENANT INDUSTRIAL BUILDING',
  '225 PAPER PRODUCT MFG & RELATED PRODUCTS',
  '226 REFINERY, PETROLEUM PRODUCTS',
  '227 PRINTING * PUBLISHING (LIGHT INDUSTRIAL)',
  '228 PROCESSING PLANT (MINERALS, CEMENT, ROCK, GRAVEL, GLASS, CLAY)',
  '229 MINI-WAREHOUSE, STORAGE',
  '230 QUARRIES (SAND, GRAVEL, ROCK)',
  '231 R&D FACILITY, LABORATORY, RESEARCH FACILITY, COSMETICS, PHARMACEUTICAL',
  '232 RECYCLING (METAL, PAPER, GLASS)',
  '233 SHIPYARD - BUILT OR REPAIRED (SEAGOING VESSELS)',
  '234 SLAUGHTER HOUSE, STOCKYARD',
  '235 STORAGE YARD (JUNK, AUTO WRECKING, SALVAGE)',
  '236 STORAGE YARD, OPEN STORAGE (LIGHT EQUIPMENT, MATERIAL)',
  '237 SUGAR REFINERY',
  '238 WAREHOUSE, STORAGE',
  '239 WINERY',
  '240 WASTE DISPOSAL, SEWAGE (PROCESSING, DISPOSAL, STORAGE, TREATMENT)',
  '241 COMMON AREA (MISC)',
  '242 EASEMENT (MISC)',
  '243 HOMESTEAD (MISC)',
  '244 LEASEHOLD RIGHTS (MISC)',
  '245 PETROLEUM & GAS WELLS (MISC)',
  '246 PIPELINE OR RIGHT-OF-WAY',
  '247 POSSESSORY INTEREST (MISC)',
  '248 RAIL (RIGHT-OF-WAY & TRACK)',
  '249 ROAD (RIGHT-OF-WAY)',
  '250 ROYALTY INTEREST',
  '251 RIGHT-OF-WAY (NOT RAIL, ROAD OR UTILITY)',
  '252 SUB-SURFACE RIGHTS (MINERAL)',
  '253 SURFACE RIGHTS (GRAZING, TIMBER, COAL, ETC.)',
  '254 UNKNOWN',
  '255 UTILITIES (RIGHT-OF-WAY ONLY)',
  '256 WATER RIGHTS (MISC)',
  '257 WORKING INTEREST',
  '258 AIRPORT & RELATED',
  '259 ARCADES (AMUSEMENT)',
  '260 ARENA, CONVENTION CENTER',
  '261 AUDITORIUM',
  '262 OUTDOOR RECREATION: BEACH, MOUNTAIN, DESERT',
  '263 POOL HALL, BILLIARD PARLOR',
  '264 BOWLING ALLEY',
  '265 BUS TERMINAL',
  '266 COMMERCIAL AUTO TRANSPORTATION/STORAGE',
  '267 COUNTRY CLUB',
  '268 CENTRALLY ASSESSED',
  '269 CHARITABLE ORGANIZATION, FRATERNAL',
  '270 CLUBS, LODGES, PROFESSIONAL ASSOCIATIONS',
  '271 COMMUNITY CENTER (EXEMPT)',
  '272 COMMUNICATIONS',
  '273 CAMPGROUND, RV PARK',
  '274 COLLEGE, UNIVERSITY, VOCATIONAL SCHOOL - PRIVATE',
  '275 CREMATORIUM, MORTUARY (EXEMPT)',
  '276 CABLE TV STATION',
  '277 CITY, MUNICIPAL, TOWN, VILLAGE OWNED (EXEMPT)',
  '278 COUNTY OWNED (EXEMPT)',
  '279 DANCE HALL',
  '280 DISTRIBUTION WAREHOUSE (REGIONAL)',
  '281 DRIVE-IN THEATER',
  '282 CEMETERY (EXEMPT)',
  '283 EMERGENCY (POLICE, FIRE, RESCUE, SHELTERS, ANIMAL SHELTER)',
  '284 EXEMPT (FULL OR PARTIAL)',
  '285 FAIRGROUNDS',
  '286 FEDERAL PROPERTY (EXEMPT)',
  '287 FISH CAMPS, GAME CLUB TARGET SHOOTING',
  '288 FOREST (PARK, RESERVE, RECREATION, CONSERVATION)',
  '289 FREEWAYS, STATE HWYS',
  '290 DRIVING RANGE (GOLF)',
  '291 TRANSPORTATION (GENERAL)',
  '292 GO-CARTS, MINIATURE GOLD, WATER SLIDES',
  '293 GOLF COURSE',
  '294 GOVERNMENTAL / PUBLIC USE (GENERAL)',
  '295 GOVT. ADMINISTRATIVE OFFICE (FEDERAL, STATE, LOCAL, COURT HOUSE)',
  '296 GYM, HEALTH SPA',
  '297 HISTORICAL DISTRICT',
  '298 CULTURAL, HISTORICAL (MONUMENTS, HOMES, MUSEUMS, OTHER)',
  '299 HISTORICAL TRANSIENT LODGING (HOTEL, MOTEL)',
  '300 HARBOR & MARINE TRANSPORTATION',
  '301 HISTORICAL OFFICE',
  '302 HOSPITAL - PUBLIC',
  '303 HISTORICAL PARK, SITE, MISC.',
  '304 HISTORICAL - PRIVATE (GENERAL)',
  '305 HISTORICAL RECREATION, ENTERTAINMENT',
  '306 HISTORICAL RESIDENCE',
  '307 HISTORICAL RETAIL',
  '308 HISTORICAL WAREHOUSE',
  '309 INDIAN LANDS',
  '310 INSTITUTIONAL (GENERAL)',
  '311 MARINA, BOAT SLIPS, YACHT CLUB, BOAT LANDING',
  '312 MEDICAL CLINIC',
  '313 MICROWAVE',
  '314 MILITARY (OFFICE, BASE, POST, PORT, RESERVE, WEAPON RANGE, TEST SITES)',
  '315 MISCELLANEOUS (GENERAL)',
  '316 MUSEUM, LIBRARY, ART GALLERY (RECREATIONAL)',
  '317 NATURAL RESOURCES',
  '318 RECREATIONAL NON-TAXABLE (CAMPS, BOY SCOUTS)',
  '319 CORRECTIONAL FACILITY, JAILS, PRISONS, INSANE ASYLUM',
  "320 CHILDREN'S HOME, ORPHANAGE",
  '321 PUBLIC HEALTH CARE FACILITY (EXEMPT)',
  '322 PARK, PLAYGROUND, PICNIC AREA',
  '323 PIERS, WHARF (RECREATION)',
  '324 POLLUTION CONTROL',
  '325 POST OFFICE',
  '326 PUBLIC SWIMMING POOL',
  '327 AMUSEMENT PARK, TOURIST ATTRACTION',
  '328 PAROCHIAL SCHOOL, PRIVATE SCHOOL',
  '329 PUBLIC UTILITY (ELECTRIC, WATER, GAS, ETC.)',
  '330 RAILROAD & RELATED',
  '331 RACQUET COURT, TENNIS COURT',
  '332 RECREATIONAL CENTER',
  '333 REGULATING DISTRICTS & ASSESSMENTS; TAX ABATEMENT',
  '334 RECREATIONAL/ENTERTAINMENT (GENERAL)',
  '335 REDEVELOPMENT AGENCY OR ZONE',
  '336 RELIGIOUS, CHURCH, WORSHIP (SYNAGOGUE, TEMPLE, PARSONAGE)',
  '337 RIDING STABLE, TRAILS',
  '338 ROADS, STREETS, BRIDGES',
  '339 HOMES (RETIRED, HANDICAP, REST, CONVALESCENT, NURSING)',
  '340 RADIO OR TV STATION',
  '341 SBE - SPECIAL ASSESSMENTS',
  '342 PUBLIC SCHOOL (ADMINISTRATION, CAMPUS, DORMS, INSTRUCTION)',
  '343 SKATING RINK, ICE SKATING, ROLLER SKATING',
  '344 STATE OWNED (EXEMPT)',
  '345 SPECIAL PURPOSE',
  '346 STADIUM',
  '347 TELEGRAPH, TELEPHONE',
  '348 THEATER (MOVIE)',
  '349 TRANSPORTATION (AIR, RAIL, BUS)',
  '350 RACE TRACK (AUTO, DOG, HORSE)',
  '351 TRUCK TERMINAL (MOTOR FREIGHT)',
  '352 COLLEGES, UNIVERSITY - PUBLIC',
  '353 PRIVATE UTILITY (ELECTRIC, WATER, GAS, ETC.)',
  '354 WELFARE, SOCIAL SERVICE, LOW INCOME HOUSING (EXEMPT)',
  '355 ZOO',
  '356 OTHER EXEMPT PROPERTY',
  '357 GARDEN APT, COURT APT (5+ UNITS)',
  '358 HIGH-RISE APARTMENTS',
  '359 APARTMENT HOUSE (100+ UNITS)',
  '360 APARTMENTS (GENERIC)',
  '361 APARTMENT HOUSE (5+ UNITS)',
  '362 BOARDING/ROOMING HOUSE, APT HOTEL',
  '363 BUNGALOW (RESIDENTIAL)',
  '364 CLUSTER HOME',
  '365 COMMON AREA (RESIDENTIAL)',
  '366 CONDOMINIUM',
  '367 COOPERATIVE',
  '368 DORMITORY, GROUP QUARTERS (RESIDENTIAL)',
  '369 DUPLEX (2 UNITS, ANY COMBINATION)',
  '370 FRATERNITY HOUSE, SORORITY HOUSE',
  '371 MANUFACTURED, MODULAR, PRE-FABRICATED HOMES',
  '372 MULTI-FAMILY DWELLINGS (GENERIC, ANY COMBINATION)',
  '373 MOBILE HOME',
  '374 RESIDENTIAL MULTI-PARCEL MISCELLANEOUS',
  '375 MISCELLANEOUS (RESIDENTIAL)',
  '376 PATIO HOME',
  '377 PLANNED UNIT DEVELOPMENT (PUD)',
  '378 QUADPLEX (4 UNITS, ANY COMBINATION)',
  '379 CONDOMINIUM DEVELOPMENT (ASSOCIATION ASSESSMENT)',
  '380 RESIDENTIAL (GENERAL/SINGLE)',
  '381 RESIDENTIAL INCOME (GENERAL/MULTI-FAMILY)',
  '382 ROW HOUSE',
  '383 RURAL RESIDENCE',
  '384 SEASONAL, CABIN, VACATION RESIDENCE',
  '385 SINGLE FAMILY RESIDENCE',
  '386 TOWNHOUSE',
  '387 TIMESHARE',
  '388 TRIPLEX (3 UNITS, ANY COMBINATION)',
  '389 VACANT LAND',
  '390 ZERO LOT LINE (RESIDENTIAL)',
  '391 ABANDONED SITE, CONTAMINATED SITE',
  '392 AGRICULTURAL (UNIMPROVED) - VACANT LAND',
  '393 VACANT COMMERCIAL',
  '394 GOVERNMENT - VACANT LAND',
  '395 INDUSTRIAL - VACANT LAND',
  '396 INSTITUTIONAL - VACANT LAND',
  '397 VACANT MISCELLANEOUS',
  '398 MULTI-FAMILY - VACANT LAND',
  '399 PRIVATE PRESERVE, OPEN SPACE - VACANT LAND',
  '400 RECREATIONAL - VACANT LAND',
  '401 RESIDENTIAL - VACANT LAND',
  '402 UNDER CONSTRUCTION',
  '403 UNUSABLE LAND (REMNANT, STEEP, ETC.)',
  '404 WASTE LAND, MARSH, SWAMP, SUBMERGED - VACANT LAND',
  '406 WATER AREA (LAKES, RIVER, SHORE) - VACANT LAND',
  '407 COMMON AREA (MISC.)',
  '408 TEMPORARY STRUCTURES',
  '409 VACANT LAND - EXEMPT',
  '410 SPORTS COMPLEX',
  '411 PERSONAL PROPERTY (GENERAL)',
  '412 PET BOARDING & GROOMING',
  '413 CROPS (IN GROUND)',
  '414 STRUCTURES (GENERAL)',
  '415 AIRCRAFT',
  '416 LANDOMINIUM',
  '417 SURFACE RIGHTS (GRAZING, TIMBER, COAL, ETC.)',
  '418 RESIDENTIAL PARKING GARAGE',
  '419 INVENTORY',
  '420 MOTOR VEHICLES (CARS, TRUCKS, ETC.)',
  '421 CONDOMINIUM BUILDING (RESIDENTIAL)',
  '422 MISC STRUCTURES NOT OTHERWISE CLASSED (BILLBOARDS, ETC.)',
  '423 BARNDOMINIUM',
  '424 SUB-SURFACE RIGHTS (MINERAL)',
  '425 GOODS IN TRANSIT',
  '426 RIGHT-OF-WAY (NOT RAIL, ROAD OR UTILITY)',
  '427 SPACECRAFT',
  '428 RAIL (RIGHT-OF-WAY & TRACK)',
  '429 STRUCTURES ON LEASED LAND',
  '430 ROYALTY INTEREST',
  '431 POSSESSORY INTEREST (MISC.)',
  '432 WATERCRAFT (SHIPS, BOATS, PWCS, ETC.)',
  '433 CAR WASH - SELF-SERVE',
  '434 ROLLING STOCK (RAILROAD)',
  '435 WATER RIGHTS (MISC.)',
  '436 MISC PERSONAL PROPERTY NOT OTHERWISE CLASSED',
  '437 INTANGIBLE PERSONAL PROPERTY',
  '438 LEASEHOLD RIGHTS (MISC.)',
  '439 MISC. BUSINESS PERSONAL PROPERTY NOT OTHERWISE CLASSED',
  '440 HOMESTEAD (MISC.)',
  '441 VEHICLES (GENERAL)',
  '442 UTILITIES (RIGHT-OF-WAY ONLY)',
  '443 PIPELINE OR RIGHT-OF-WAY',
  '444 MISC VEHICLES NOT OTHERWISE CLASSED (ANTIQUES, ETC.)',
  '445 BUSINESS PERSONAL PROPERTY (GENERAL)',
  '446 CROPS (HARVESTED)',
  '447 TINY HOUSE',
  '448 RESIDENTIAL STORAGE SPACE',
  '449 ROADSIDE MARKET',
  '450 CANNABIS GROW FACILITY',
  '451 CELLULAR',
  '452 GARDEN HOME',
  '453 VACANT LAND - DESTROYED/UNINHABITABLE IMPROVEMENT',
  '454 ROAD (RIGHT-OF-WAY)',
  '455 EQUIPMENT / SUPPLIES',
  '456 PETROLEUM & GAS WELLS (MISC.)',
  '457 WORKING INTEREST',
  '458 CAR WASH - AUTOMATED',
  '459 CANNABIS DISPENSARY',
  '460 RECREATIONAL VEHICLES / TRAVEL TRAILERS',
  '461 COOPERATIVE BUILDING (RESIDENTIAL)',
  '462 VACANT LAND - UNSPECIFIED IMPROVEMENT',
  '463 PARCELS WITH IMPROVEMENTS, USE NOT SPECIFIED',
  '464 BARBER/HAIR SALON',
  '465 EASEMENT (MISC.)',
  '466 LIVESTOCK (ANIMALS, FISH, BIRDS, ETC.)',
]

async function getCompDistance(startLoc, compLoc) {
  try {
    const startLat = startLoc.lat / (180 / Math.PI)
    const startLng = startLoc.lng / (180 / Math.PI)
    const compLat = compLoc.lat / (180 / Math.PI)
    const compLng = compLoc.lng / (180 / Math.PI)
    const distance =
      3963.0 *
      Math.acos(
        Math.sin(startLat) * Math.sin(compLat) +
          Math.cos(startLat) *
            Math.cos(compLat) *
            Math.cos(compLng - startLng),
      )
    return distance
  } catch (error) {
    return error
  }
}

function FindCompsComponent(props) {
  const { property = {} } = props
  //  const { setHighlightComp } = props
  const propertyId = property._id
  const hiddenDown = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  )
  const hiddenUp = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'),
  )
  const [progress, setProgress] = React.useState(0)
  const [circle, setCircle] = React.useState(false)
  const [noDups, setNoDups] = React.useState(true)
  const [findCompPercent, setFindCompPercent] = React.useState(0)
  const [findCompPercentStep, setFindCompPercentStep] =
    React.useState(0)

  const { seconds, minutes, pause, reset } = useStopwatch({
    autoStart: false,
  })

  const handleNoDups = () => {
    setNoDups(!noDups)
  }

  const [openClose, setOpenClose] = useState(false)
  const [suggestionOpenClose, setSuggestionOpenClose] =
    useState(false)
  const [compSuggestion, setCompSuggestion] = useState('')

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setCircle(false)
    pause()
  }

  const imageUploader = (
    imageUploadMulti,
    propertyImagePairs,
    collection,
    first = false,
  ) => {
    imageUploadMulti({
      variables: {
        propertyImagePairs,
        collection,
        first,
      },
    })
  }

  let preferences = merge(
    cloneDeep(DEFAULT_PREFERENCES),
    props?.session?.me?.activeOrg?.memberPreferences,
    props?.session?.me?.activeOrg?.preferences,
  )

  const pepSqftRange =
    sum(property.sqft) < 1000
      ? 150
      : sum(property.sqft) < 2000
      ? 200
      : sum(property.sqft) * 0.1
  const pepMaxYear = property.year <= 1938 ? 1950 : property.year + 12

  const [cities, setCities] = useState([property.city])
  const [zips, setZips] = useState([property.zip])
  const [compParams, setCompParams] = useState({
    beds: sum(property.beds)
      ? {
          min:
            sum(property.beds) + preferences.findComps.beds.min > 0
              ? sum(property.beds) + preferences.findComps.beds.min
              : 0,
          max:
            sum(property.beds) + preferences.findComps.beds.max > 0
              ? sum(property.beds) + preferences.findComps.beds.max
              : 0,
        }
      : '',
    baths: sum(property.baths)
      ? {
          min:
            sum(property.baths) + preferences.findComps.baths.min > 0
              ? sum(property.baths) + preferences.findComps.baths.min
              : 0,
          max:
            sum(property.baths) + preferences.findComps.baths.max > 0
              ? sum(property.baths) + preferences.findComps.baths.max
              : 0,
        }
      : '',
    sqft: sum(property.sqft)
      ? props.session.me.activeOrg.isRealeflow
        ? {
            min: sum(property.sqft) - pepSqftRange,
            max: sum(property.sqft) + pepSqftRange,
          }
        : {
            min:
              sum(property.sqft) * preferences.findComps.sqft.min > 0
                ? sum(property.sqft) * preferences.findComps.sqft.min
                : 0,
            max:
              sum(property.sqft) * preferences.findComps.sqft.max > 0
                ? sum(property.sqft) * preferences.findComps.sqft.max
                : 0,
          }
      : '',
    lotsqft: sum(property.sqft)
      ? {
          min: /*sum(property.sqft) * preferences.findComps.sqft.min > 0 ? sum(property.sqft) * preferences.findComps.sqft.min : 0*/ 0,
          max: /*sum(property.sqft) * preferences.findComps.sqft.max > 0 ? sum(property.sqft) * preferences.findComps.sqft.max : 0*/ 0,
        }
      : '',
    year: property.year
      ? props.session.me.activeOrg.isRealeflow
        ? { min: 0, max: pepMaxYear }
        : {
            min:
              property.year + preferences.findComps.year.min > 0
                ? property.year + preferences.findComps.year.min
                : 0,
            max:
              property.year + preferences.findComps.year.max > 0
                ? property.year + preferences.findComps.year.max
                : 0,
          }
      : '',
  })

  const [soldBy, setSoldBy] = useState(preferences.findComps.soldBy)
  const [maxDistance, setMaxDistance] = useState(
    preferences.findComps.maxDistance,
  )
  const [activeMLS, setActiveMLS] = useState('Off Market')
  const [propertyType, setPropertyType] = useState('')
  const [propertyClass, setPropertyClass] = useState([])
  const [compLimit, setCompLimit] = useState(250)
  const [path, setPath] = useState([])

  const [saveAnalysis] = useMutation(SAVE_ANALYSIS)

  const [open, setOpen] = useState(false)
  const toggle = () => {
    if (props.handleMenuClose) props.handleMenuClose()
    setOpen(true)
  }
  const closeFindComps = () => {
    setOpen(false)
  }

  const onChange = (event, target) => {
    let newParams = { ...compParams }
    if (Number(event.target.value) >= 0 || !event.target.value)
      set(newParams, target, Number(event.target.value))
    setCompParams(newParams)
  }

  useEffect(() => {
    setCompParams({
      beds: sum(property.beds)
        ? {
            min:
              sum(property.beds) + preferences.findComps.beds.min > 0
                ? sum(property.beds) + preferences.findComps.beds.min
                : 0,
            max:
              sum(property.beds) + preferences.findComps.beds.max > 0
                ? sum(property.beds) + preferences.findComps.beds.max
                : 0,
          }
        : '',
      baths: sum(property.baths)
        ? {
            min:
              sum(property.baths) + preferences.findComps.baths.min >
              0
                ? sum(property.baths) +
                  preferences.findComps.baths.min
                : 0,
            max:
              sum(property.baths) + preferences.findComps.baths.max >
              0
                ? sum(property.baths) +
                  preferences.findComps.baths.max
                : 0,
          }
        : '',
      sqft: sum(property.sqft)
        ? props.session.me.activeOrg.isRealeflow
          ? {
              min: sum(property.sqft) - pepSqftRange,
              max: sum(property.sqft) + pepSqftRange,
            }
          : {
              min:
                sum(property.sqft) * preferences.findComps.sqft.min >
                0
                  ? sum(property.sqft) *
                    preferences.findComps.sqft.min
                  : 0,
              max:
                sum(property.sqft) * preferences.findComps.sqft.max >
                0
                  ? sum(property.sqft) *
                    preferences.findComps.sqft.max
                  : 0,
            }
        : '',
      lotsqft: sum(property.sqft)
        ? {
            min: /*sum(property.sqft) * preferences.findComps.sqft.min > 0 ? sum(property.sqft) * preferences.findComps.sqft.min : 0*/ 0,
            max: /*sum(property.sqft) * preferences.findComps.sqft.max > 0 ? sum(property.sqft) * preferences.findComps.sqft.max : 0*/ 0,
          }
        : '',
      year: property.year
        ? props.session.me.activeOrg.isRealeflow
          ? { min: 0, max: pepMaxYear }
          : {
              min:
                property.year + preferences.findComps.year.min > 0
                  ? property.year + preferences.findComps.year.min
                  : 0,
              max:
                property.year + preferences.findComps.year.max > 0
                  ? property.year + preferences.findComps.year.max
                  : 0,
            }
        : '',
    })
  }, [property.beds, property.baths, property.sqft, property.year])

  //OVERVIEW COMMENT: We could use a addCompProperties instead of addCompProperty but I don't feel that it will increase speed noticably as the images are already part of a multiple updatte and that is the main time taker
  const addComps = async (
    client,
    result,
    compProperties,
    addComp,
    imageUpload,
    resultMore,
  ) => {
    let { location } = property
    setCircle(true)
    let propertyFirstImagePairs = []
    let propertyImagePairs = []
    let newCompValues = isEmpty(props.compValues)
      ? []
      : [...props.compValues]
    for (let i = 0; i < result.length; i++) {
      const data = result[i]
      let compAddress = data.address
      let latitude = parseFloat(data.latitude)
      let longitude = parseFloat(data.longitude)
      let loc = { latitude: latitude, longitude: longitude }
      //SPECIFIC COMMENT: We get the lat, lng from api - we want to use that instead of calling geocode again.
      //let loc = await getGeoCode(`${compAddress} ${data.city}, ${data.state}`) || {}
      const distance =
        (await getCompDistance(
          { lat: location.latitude, lng: location.longitude },
          { lat: latitude, lng: longitude },
        )) || 0
      const [zipValues] = await getZipValues(client, data.zip)
      const stockpileCheck = await client.query({
        query: GET_STOCKPILE,
        variables: {
          addressInput: {
            address: data.address,
            city: data.city,
            county: zipValues ? zipValues.county : '',
            state: data.state,
          },
        },
        errorPolicy: 'ignore',
      })
      const stockpileValues = stockpileCheck
        ? stockpileCheck.data
        : null
      const { stockedImages } = stockpileValues || {}
      let images = []
      if (data.images) {
        var object = JSON.parse(data.images)
        forEach(object, (value) => {
          images.push(value)
        })
      } else if (stockedImages)
        images =
          !isEmpty(stockedImages.url) || stockedImages.first
            ? compact(
                concat([stockedImages.first], stockedImages.url),
              ) || []
            : compact([
                stockedImages.streetView,
                stockedImages.staticMap,
              ]) || []
      if (latitude && longitude) {
        const MAP_KEY = googleApiKey
        if (isEmpty(images)) {
          await new Promise((resolve, reject) => {
            try {
              var service = window.google
                ? new window.google.maps.StreetViewService()
                : 0
              if (service !== 0) {
                service.getPanoramaByLocation(
                  { lat: Number(latitude), lng: Number(longitude) },
                  50,
                  function (panoData) {
                    if (panoData !== null) {
                      var ManLatLng = panoData.location.latLng
                      const heading =
                        window.google.maps.geometry.spherical.computeHeading(
                          ManLatLng,
                          new window.google.maps.LatLng(
                            Number(latitude),
                            Number(longitude),
                          ),
                        )
                      images.push(
                        `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${latitude},${longitude}&heading=${heading}&key=${MAP_KEY}`,
                      )
                    }
                    resolve()
                  },
                )
              } else resolve()
            } catch (error) {
              console.log(error)
            }
          })
          images.push(
            `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&size=400x400&markers=color:blue%7C${latitude},${longitude}&key=${MAP_KEY}`,
          )
        }
      }
      let saleDate = null
      let date = toString(data.soldDate)
      date = replace(date, /-/g, '')
      if (date) {
        const year = Number(date.substring(0, 4))
        const month = Number(date.substring(4, 6))
        const day = Number(date.substring(6, 8))
        date = new Date(year, month - 1, day)
        saleDate = date
      }
      let potential = Boolean(data.potential)
      await addComp({
        variables: {
          compPropertyInput: {
            mainProperty: propertyId,
            address: data.address,
            city: data.city,
            state: data.state,
            zip: toString(data.zip),
            beds: parseInt(data.beds),
            baths: parseFloat(data.baths),
            sqft: parseInt(data.sqft),
            year: parseInt(data.yearBuilt),
            estval: parseInt(data.soldPrice),
            saleDate,
            distance,
            potential,
            location: loc,
            propertyType: data.propertyType,
            // rentalPrice: data.rentZestimate
            //   ? parseFloat(data.rentZestimate.amount[0]._)
            //   : parseFloat(''),
          },
        },
      }).then(({ data }) => {
        if (!isEmpty(images)) {
          if (includes(head(images), googleApiKey)) {
            propertyImagePairs.push({
              propertyId: data.addCompProperty._id,
              imageUrls: slice(images, 0) || [],
            })
          } else {
            propertyFirstImagePairs.push({
              propertyId: data.addCompProperty._id,
              imageUrls: slice(images, 0, 1) || [],
            })
            propertyImagePairs.push({
              propertyId: data.addCompProperty._id,
              imageUrls: slice(images, 1) || [],
            })
          }
        }
      })
      setProgress((i + 1) / result.length)
    }
    imageUploader(
      imageUpload,
      propertyFirstImagePairs,
      'compProperty',
      true,
    )
    imageUploader(
      imageUpload,
      propertyImagePairs,
      'compProperty',
      false,
    )
    if (resultMore) {
      enqueueSnackbar(`Comps added successfully - MORE TO COME`, {
        variant: 'success',
        action: (key) => (
          <Fragment>
            <IconButton
              onClick={() => {
                props.closeSnackbar(key)
              }}
              size="large"
            >
              <Close style={{ color: 'white' }} />
            </IconButton>
          </Fragment>
        ),
      })
    } else {
      enqueueSnackbar(`Comps added successfully`, {
        variant: 'success',
        action: (key) => (
          <Fragment>
            <IconButton
              onClick={() => {
                props.closeSnackbar(key)
              }}
              size="large"
            >
              <Close style={{ color: 'white' }} />
            </IconButton>
          </Fragment>
        ),
      })
      setCircle(false)
      pause()
    }
  }

  const dataAPI = async (
    lowercaseCities,
    soldDate,
    potentialSoldDate,
    queryZips,
    state,
    latitude,
    longitude,
    compOffset = false,
  ) => {
    let phpData = {
      apiKey: apiKey,
      orgID: apiID,
      action: 'find_comps',
      subAction: 'loop',
      type: 'Single Family',
      env:
        hostname === 'localhost' ||
        hostname === 'dev-admin.housefolios.com'
          ? 'dev'
          : 'live',
      cities: lowercaseCities,
      zips: queryZips,
      state: state,
      latitude: latitude,
      longitude: longitude,
      potentialSoldDate, //soldDate,
      soldDate,
      ...compParams,
      maxDistance,
    }

    if (compOffset || compOffset === 0) phpData.offset = compOffset

    const str_json = JSON.stringify(phpData)

    let response = { ok: false }
    try {
      response = await fetch(
        hostname === 'localhost'
          ? 'http://data.housefolios.com/housefoliosdev-api.php'
          : //? 'http://housefolios-data.test/housefoliosdev-api.php'
          hostname === 'dev-admin.housefolios.com'
          ? 'https://data.housefolios.com/housefoliosdev-api.php'
          : 'https://data.housefolios.com/housefolios-api.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: str_json,
        },
      )
    } catch (error) {
      console.log(error)
    }
    if (!response.ok) {
      enqueueSnackbar(
        `Something has gone wrong. Please contact support`,
        {
          variant: 'error',
          autoHideDuration: 3000,
        },
      )
      setCircle(false)
      pause()
      toggle()
      return null
    }
    let apiResponse = response.ok ? await response.json() : null
    return apiResponse
  }

  const findComps =
    (
      client,
      compProperties,
      addComp,
      imageUpload,
      override = false,
    ) =>
    async () => {
      let { location } = property
      setCircle(true)
      reset()
      const soldDate = new Date()
      soldDate.setMonth(soldDate.getMonth() - parseInt(soldBy))
      if (soldDate.getMonth() < 0) {
        soldDate.setMonth(soldDate.getMonth() + 12)
        soldDate.setFullYear(soldDate.getFullYear() - 1)
      }
      const lowercaseCities = cities.map((v) => toLower(v))

      let potentialSoldDate = new Date(
        soldDate.getFullYear() - 1,
        soldDate.getMonth(),
        soldDate.getDate(),
      )

      const {
        data: { zipTableByCityState },
      } = await client.query({
        query: GET_ZIP_BY_CITY_STATE,
        variables: {
          city: lowercaseCities,
          state: property.state,
        },
      })
      if (isEmpty(zipTableByCityState)) {
        enqueueSnackbar(
          `Something has gone wrong. Please contact support`,
          {
            variant: 'error',
            autoHideDuration: 3000,
          },
        )
        setFindCompPercent(0)
        setCircle(false)
        pause()
        setOpen(false)
        return
      }

      let queryZips = map(zipTableByCityState, (zip) => zip.zipcode)

      queryZips = uniq(concat(queryZips, zips))

      const {
        data: { callREAPI },
      } = await client.query({
        query: CALL_REAPI,
        variables: {
          REAPIInput: {
            mls_active:
              activeMLS === 'Both'
                ? undefined
                : activeMLS === 'Active MLS',
            cities: lowercaseCities,
            state: property.state,
            zips: queryZips,
            beds: compParams.beds,
            baths: compParams.baths,
            sqft: compParams.sqft,
            lotsqft: compParams.lotsqft,
            year: compParams.year,
            saleDate: {
              min: potentialSoldDate,
            },
            // price: compParams.price,
            propertyType:
              propertyType === 'ANY'
                ? undefined
                : propertyType || undefined,
            propertyClass: !isEmpty(propertyClass)
              ? map(propertyClass, (string) =>
                  parseInt(string.substring(0, 3)),
                )
              : undefined,
            compLimit: parseInt(compLimit),
            polygon:
              !isEmpty(path) && maxDistance === 'Draw Comp Area'
                ? map(path, (loc) => {
                    return { lat: loc.lat, lon: loc.lng }
                  })
                : undefined,
          },
        },
      })
      let result = callREAPI || []

      result = uniqWith(
        orderBy(result, 'soldDate', 'desc'),
        (obj, oth) => {
          //simplify once findComps return structure is determined
          return (
            toLower(obj.address) === toLower(oth.address) &&
            toLower(obj.city) === toLower(oth.city) &&
            toLower(obj.state) === toLower(oth.state)
          )
        },
      )

      result = compact(
        await Promise.all(
          map(result, async (comp) => {
            let compAddress = comp.address
            const compCity = comp.city
            const compState = comp.state
            if (
              !(
                toLower(property.address) === toLower(compAddress) &&
                toLower(property.city) === toLower(compCity) &&
                toLower(property.state) === toLower(compState)
              )
            )
              return comp
          }),
        ),
      )

      if (noDups)
        result = compact(
          await Promise.all(
            map(result, async (comp) => {
              let compAddress = comp.address
              const compCity = comp.city
              const compState = comp.state
              if (
                filter(compProperties, {
                  address: compAddress,
                  city: compCity,
                  state: compState,
                }).length === 0
              )
                return comp
            }),
          ),
        )

      result = compact(
        await Promise.all(
          map(result, async (data) => {
            let compAddress = data.address
            let compLocation = {
              latitude: data.latitude,
              longitude: data.longitude,
            }
            const distance =
              (await getCompDistance(
                { lat: location.latitude, lng: location.longitude },
                {
                  lat: compLocation.latitude,
                  lng: compLocation.longitude,
                },
              )) || 0
            // let saleTime = data.saleTime;
            // let saleDate = new Date(saleTime * 1000);
            // let distance = data.distance;
            let saleDate = null
            let date = toString(data.soldDate)
            date = replace(date, /-/g, '')
            if (date) {
              const year = Number(date.substring(0, 4))
              const month = Number(date.substring(4, 6))
              const day = Number(date.substring(6, 8))
              date = new Date(year, month - 1, day)
              saleDate = date
            }
            const soldPrice = data.price
            // let potential = data.potential;
            let targetMaxDistance =
              isEmpty(path) && maxDistance === 'Draw Comp Area'
                ? 1
                : Number(maxDistance)
            const isDrawArea =
              !isEmpty(path) && maxDistance === 'Draw Comp Area'
            if (
              !(
                (distance <= targetMaxDistance + 1 || isDrawArea) &&
                saleDate &&
                saleDate.getTime() >= potentialSoldDate.getTime()
              )
            )
              return
            let potential = !(
              (distance <= targetMaxDistance || isDrawArea) &&
              saleDate &&
              saleDate.getTime() >= soldDate.getTime()
            )

            return {
              ...data,
              potential,
            }
          }),
        ),
      )

      //SPECIFIC COMMENT: phpCoord sends data to php for stockpiling. We don't expect a response
      let phpCoordData = {
        apiKey: apiKey,
        orgID: apiID,
        action: 'coord_stockpile',
        env:
          hostname === 'localhost' ||
          hostname === 'dev-admin.housefolios.com'
            ? 'dev'
            : 'live',
        result,
      }

      const str_json_coord = JSON.stringify(phpCoordData)

      try {
        await fetch(
          hostname === 'localhost'
            ? 'http://data.housefolios.com/housefoliosdev-api.php'
            : //? 'http://housefolios-data.test/housefoliosdev-api.php'
            hostname === 'dev-admin.housefolios.com'
            ? 'https://data.housefolios.com/housefoliosdev-api.php'
            : 'https://data.housefolios.com/housefolios-api.php',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: str_json_coord,
          },
        )
      } catch (error) {
        console.log(error)
      }

      if (isEmpty(result)) {
        enqueueSnackbar(`We couldn't find comps at this time`, {
          variant: 'error',
          action: (key) => (
            <Fragment>
              <IconButton
                onClick={() => {
                  props.closeSnackbar(key)
                }}
                size="large"
              >
                <Close style={{ color: 'white' }} />
              </IconButton>
            </Fragment>
          ),
        })
        setOpenClose(true)
      } else {
        await addComps(
          client,
          result,
          compProperties,
          addComp,
          imageUpload,
          false,
        )
      }

      //  let resultMore = true
      //  let compOffset = 0

      //  let initialRunResponse = await dataAPI(lowercaseCities, soldDate, potentialSoldDate, queryZips, property.state, location.latitude, location.longitude)

      //  if (initialRunResponse)
      //  {
      //    if(initialRunResponse['compCount'] < 100 && initialRunResponse['compCount'] > 0 && !override)
      //    {
      //      setSuggestionOpenClose(true)
      //      setCompSuggestion("too few comps")
      //    }
      //    else if(initialRunResponse['compCount'] > 1000 && !override)
      //    {
      //       setSuggestionOpenClose(true)
      //       setCompSuggestion("too many comps")
      //     }
      //    else if(initialRunResponse['compCount']=== 0 && !override)
      //    {
      //     setSuggestionOpenClose(true)
      //     setCompSuggestion("no comps")
      //    }
      //    else
      //    {
      //      setFindCompPercent(0)
      //      let compCount = initialRunResponse.compCount
      //      setProgress(0)

      //      let emptyBatches = 0
      //      let prevComps = []
      //       while(resultMore === true)
      //       {
      //       let result = await dataAPI(lowercaseCities, soldDate, potentialSoldDate, queryZips, property.state, location.latitude, location.longitude, compOffset)
      //       let lastRound = has(result, "comps")
      //       let resultMoreCheck
      //       let resultComps

      //       if(lastRound)
      //       {
      //         resultMoreCheck = result.more
      //         resultComps = result.comps
      //       }
      //       else{
      //         resultMoreCheck = false
      //         resultComps = result
      //       }

      //       result = uniqWith(orderBy(resultComps, 'soldDate', 'desc'), (obj, oth) => {
      //         //simplify once findComps return structure is determined
      //         return toLower(obj.address) === toLower(oth.address) && toLower(obj.city) === toLower(oth.city) && toLower(obj.state) === toLower(oth.state)
      //       })
      //       //setHighlightComp(soldDate)
      //       result = compact(await Promise.all(map(result, async comp => {
      //         let compAddress = comp.address
      //         const compCity = comp.city
      //         const compState = comp.state
      //         if (!(toLower(property.address) === toLower(compAddress) && toLower(property.city) === toLower(compCity) && toLower(property.state) === toLower(compState)))
      //           return comp
      //       })))

      //       result = compact(await Promise.all(map(result, async comp => {
      //         let compAddress = comp.address
      //         const compCity = comp.city
      //         const compState = comp.state
      //         if (filter(prevComps, { address: compAddress, city: compCity, state: compState }).length === 0)
      //           return comp
      //       })))

      //       if (noDups)
      //         result = compact(await Promise.all(map(result, async comp => {
      //           let compAddress = comp.address
      //           const compCity = comp.city
      //           const compState = comp.state
      //           if (filter(compProperties, { address: compAddress, city: compCity, state: compState }).length === 0)
      //             return comp
      //         })))

      //         //SPECIFIC COMMENT: phpCoord sends data to php for stockpiling. We don't expect a response
      //         let phpCoordData = {
      //           apiKey: apiKey,
      //           orgID: apiID,
      //           action: 'coord_stockpile',
      //           env: hostname === 'localhost' || hostname === 'dev-admin.housefolios.com' ? 'dev' : 'live' ,
      //           result
      //         }

      //         const str_json_coord = JSON.stringify(phpCoordData)

      //         try {
      //            await fetch(
      //             hostname === 'localhost'
      //               ? 'http://data.housefolios.com/housefoliosdev-api.php'
      //               //? 'http://housefolios-data.test/housefoliosdev-api.php'
      //               : hostname === 'dev-admin.housefolios.com'
      //                 ? 'https://data.housefolios.com/housefoliosdev-api.php'
      //                 : 'https://data.housefolios.com/housefolios-api.php',
      //             {
      //               method: 'POST',
      //               headers: {
      //                 'Content-Type': 'application/json',
      //               },
      //               body: str_json_coord,
      //             },
      //           )
      //         } catch (error) {
      //           console.log(error)
      //         }

      //         if (isEmpty(result)) {
      //           emptyBatches++
      //           if(!resultMoreCheck && emptyBatches > compOffset / 100)
      //           {
      //           enqueueSnackbar(
      //             `We couldn't find comps at this time`,
      //             {
      //               variant: 'error',
      //               action: key => (
      //                 <Fragment>
      //                   <IconButton
      //                     onClick={() => {
      //                       props.closeSnackbar(key)
      //                     }}
      //                   >
      //                     <Close style={{ color: 'white' }} />
      //                   </IconButton>
      //                 </Fragment>
      //               ),
      //             },
      //           )
      //           setOpenClose(true)
      //           }
      //         }
      //         else {
      //           setFindCompPercentStep(result.length/parseFloat(compCount) * 100)
      //           await addComps(client, result, compProperties, addComp, imageUpload, resultMoreCheck)
      //           prevComps = concat(prevComps, result)
      //         }
      //         compOffset += 100
      //         setFindCompPercent((compOffset/parseFloat(compCount))*100)
      //         setProgress(0)
      //         resultMore = resultMoreCheck
      //       }

      //     }
      //   }

      setFindCompPercent(0)
      setFindCompPercentStep(0)
      setCircle(false)
      pause()
      setOpen(false)
    }

  const { loading, error, data } = useQuery(GET_STATE_VALUE, {
    variables: { state: property.state },
  })

  const { stateValue = {} } = data || {}

  const locationFields = [
    {
      label: 'City*',
      placeholder: 'City*',
      value: cities,
      options: stateValue ? sortBy(stateValue.cities) : [],
      setter: setCities,
      width: '12',
    },
    //  {
    //    label: 'Zip',
    //    placeholder: 'Zip',
    //    value: zips,
    //    options: stateValue ? sortBy(stateValue.zips) : [],
    //    setter: setZips,
    //    width: '6'
    //  },
  ]

  const propertyDetailsFields = [
    {
      label: 'Beds Min',
      placeholder: 'Beds Min',
      name: 'beds.min',
      value: compParams.beds.min,
      width: '6',
    },
    {
      label: 'Beds Max',
      placeholder: 'Beds Min',
      name: 'beds.max',
      value: compParams.beds.max,
      required: true,
      width: '6',
    },
    {
      label: 'Baths Min',
      placeholder: 'Baths Min',
      name: 'baths.min',
      value: compParams.baths.min,
      width: '6',
    },
    {
      label: 'Baths Max',
      placeholder: 'Baths Min',
      name: 'baths.max',
      value: compParams.baths.max,
      required: true,
      width: '6',
    },
    {
      label: 'Sqft Min',
      placeholder: 'Sqft Min',
      name: 'sqft.min',
      value: round(compParams.sqft.min),
      width: '6',
    },
    {
      label: 'Sqft Max',
      placeholder: 'Sqft Min',
      name: 'sqft.max',
      value: round(compParams.sqft.max),
      required: true,
      width: '6',
    },
    {
      label: 'Year Min',
      placeholder: 'Year Min',
      name: 'year.min',
      value: compParams.year.min,
      width: '6',
    },
    {
      label: 'Year Max',
      placeholder: 'Year Max',
      name: 'year.max',
      value: compParams.year.max,
      required: true,
      width: '6',
    },
    {
      label: 'Lot Sqft Min',
      placeholder: 'Lot Sqft Min',
      name: 'lotsqft.min',
      value: compParams.lotsqft.min,
      width: '6',
      noError: true,
    },
    {
      label: 'Lot Sqft Max',
      placeholder: 'Lot Sqft Max',
      name: 'lotsqft.max',
      value: compParams.lotsqft.max,
      width: '6',
      noError: true,
    },
  ]

  const otherFields = [
    {
      label: 'Max Distance (miles)*',
      placeholder: 'Max Distance (miles)*',
      value: maxDistance,
      suffix: 'miles',
      options: [
        'Draw Comp Area',
        '0.25',
        '.5',
        '.75',
        '1',
        '1.5',
        '2',
        '2.5',
        '3',
        '4',
        '5',
      ],
      setter: setMaxDistance,
      width: '6',
    },
    {
      label: 'Sold Within (months)*',
      placeholder: 'Sold Within (months)*',
      value: soldBy,
      suffix: 'months',
      options: ['3', '6', '12', '18', '24', '36', '48', '60'],
      setter: setSoldBy,
      width: '6',
    },
    //  {
    //   label: 'Market',
    //   placeholder: 'Both',
    //   value: activeMLS,
    //   options: ['Active MLS', 'Off Market', 'Both'],
    //   setter: setActiveMLS,
    //   width: '6'
    //  },
    {
      label: 'Property Type',
      placeholder: 'Property Type',
      value: propertyType,
      options: [
        'ANY',
        'SFR',
        'MFR',
        'LAND',
        'CONDO',
        'MOBILE',
        'OTHER',
      ],
      setter: setPropertyType,
      width: '6',
    },
    {
      label: 'Property Class',
      multiple: true,
      placeholder: 'Property Class',
      value: propertyClass,
      options: propertyClasses,
      setter: setPropertyClass,
      width: '6',
    },
  ]

  if (loading) return '...Loading'
  if (error) return error.message

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const notDisabled =
    every(
      propertyDetailsFields,
      (field) =>
        !isNil(field.value) &&
        (includes(field.name, 'min') ||
          field.value !== 0 ||
          field.noError),
    ) &&
    (!isEmpty(cities) || !isEmpty(zips)) &&
    compLimit &&
    !circle

  const notWarning = every(
    propertyDetailsFields,
    (field) =>
      !isNil(field.value) &&
      (field.required || field.value !== 0 || field.noError),
  )

  return (
    <>
      {!props.public && !props.button && (
        <ListItem button onClick={toggle}>
          <Search style={{ marginRight: 10 }} />
          Find Comps
        </ListItem>
      )}
      {!props.public && props.button && (
        <Button
          variant="contained"
          color="primary"
          style={{ fontWeight: 600 }}
          onClick={toggle}
        >
          Find Comps
        </Button>
      )}
      <Dialog
        open={open && !props.compsBackendLoading}
        onClose={closeFindComps}
        onKeyDown={stopPropagationForTab}
      >
        <DialogTitle>Find Comps</DialogTitle>
        <DialogContent className="p-4">
          <BlockUi
            blocking={circle}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={
              <div className="text-primary">
                <p>Connecting</p>
                <p>
                  {(findCompPercent + findCompPercentStep * progress >
                  100
                    ? 100
                    : findCompPercent + findCompPercentStep * progress
                  ).toFixed(1)}
                  % Complete
                </p>
              </div>
            }
          >
            {/* <BlockUi blocking={circle} loader={<PacmanLoader color={'var(--primary)'} loading={true} />} message={<div className="text-primary" ><p>Connecting</p></div>}> */}
            <DialogContentText>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
                className="mb-0"
              >
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 600 }}
                  >
                    Location
                  </Typography>
                </Grid>
                {map(locationFields, (section) => (
                  <Grid item xs={12} sm={section.width}>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={section.options}
                      value={section.value}
                      onChange={(event, newValue) => {
                        section.setter(newValue)
                      }}
                      disableCloseOnSelect
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={
                              <CheckBoxOutlineBlankIcon fontSize="small" />
                            }
                            checkedIcon={
                              <CheckBoxIcon fontSize="small" />
                            }
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={section.label}
                          placeholder={section.label}
                        />
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
                className="mb-0"
              >
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 600, marginBottom: -10 }}
                  >
                    Property Details
                  </Typography>
                </Grid>
                {map(propertyDetailsFields, (section) => (
                  <Grid item xs={12} sm={section.width}>
                    <TextField
                      variant="outlined"
                      size="small"
                      autoFocus={section.autoFocus}
                      margin="dense"
                      value={section.value || ''}
                      onChange={(event) =>
                        onChange(event, section.name)
                      }
                      // id="input-with-icon-textfield134"
                      label={section.label}
                      required={section.required}
                      placeholder={section.placeholder}
                      error={
                        section.noError
                          ? null
                          : !section.required
                          ? !section.value
                          : null
                      }
                      type="number"
                      min={0}
                      max={section.max ? section.max : null}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
                className="mb-0"
              >
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 600 }}
                  >
                    Other
                  </Typography>
                </Grid>
                {map(otherFields, (section) => (
                  <Grid item xs={12} sm={section.width}>
                    <Autocomplete
                      id="checkboxes-tags-demo"
                      options={section.options}
                      value={section.value}
                      multiple={section.multiple}
                      disableCloseOnSelect={section.multiple}
                      limitTags={2}
                      onChange={(event, newValue) => {
                        section.setter(newValue)
                      }}
                      disableClearable={!section.multiple}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={
                              <CheckBoxOutlineBlankIcon fontSize="small" />
                            }
                            checkedIcon={
                              <CheckBoxIcon fontSize="small" />
                            }
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {section.suffix &&
                          option !== 'Draw Comp Area'
                            ? option + ' ' + section.suffix
                            : option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={section.label}
                        />
                      )}
                    />
                  </Grid>
                ))}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    value={compLimit || ''}
                    onChange={(event) => {
                      const { value } = event.target
                      let newValue = value
                      if (newValue < 0) newValue = 0
                      else if (newValue > 250) newValue = 250
                      setCompLimit(newValue)
                    }}
                    // id="input-with-icon-textfield134"
                    label={'Comp Limit'}
                    required={true}
                    placeholder={'Comp Limit'}
                    error={!compLimit}
                    type="number"
                    min={0}
                    max={250}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
                className="mb-0"
              >
                {maxDistance === 'Draw Comp Area' && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        color="primary"
                        style={{ fontWeight: 600, marginBottom: -10 }}
                      >
                        Select Comp Search Area
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Map
                        properties={[property]}
                        streetView={false}
                        shared={props.shared || props.public}
                        path={path}
                        setPath={setPath}
                        noInfoWindow
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={noDups}
                            onClick={handleNoDups}
                          />
                        }
                        label="Don't import comp properties that already exist for this property"
                      />
                    </Grid>
                  </>
                )}
                {!notWarning && (
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                    wrap="nowrap"
                  >
                    <Grid item className="gridItem2">
                      <FontAwesomeIcon
                        icon={['fal', 'exclamation-triangle']}
                      />
                    </Grid>
                    <Grid item className="gridItem2">
                      <Typography>
                        Please fill in the highlighted fields to get
                        more accurate comps
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </DialogContentText>
          </BlockUi>
        </DialogContent>
        <DialogActions>
          {hiddenDown ? null : (
            <Button
              variant="outlined"
              size="small"
              href="/settings/organization"
            >
              <Typography>Edit comp criteria</Typography>
            </Button>
          )}

          {hiddenUp ? null : (
            <Button
              variant="outlined"
              size="small"
              href="/settings/organization"
            >
              <Typography>Edit</Typography>
            </Button>
          )}
          <Typography style={{ flex: 1 }} />
          <Button
            //  onClick={toggle}
            onClick={closeFindComps}
            variant="text"
            disabled={circle}
          >
            Cancel
          </Button>
          <Mutation
            mutation={IMAGE_UPLOAD_MULTI}
            refetchQueries={[
              {
                query: GET_COMPPROPERTIES,
                variables: {
                  propertyId: propertyId,
                },
              },
              {
                query: GET_PROPERTY,
                variables: { id: propertyId },
              },
            ]}
          >
            {(imageUploadMulti, { data, loading, error }) => (
              <Mutation
                mutation={ADD_COMPPROPERTY}
                refetchQueries={[
                  {
                    query: GET_COMPPROPERTIES,
                    variables: {
                      propertyId: propertyId,
                    },
                  },
                  {
                    query: GET_PROPERTY,
                    variables: { id: propertyId },
                  },
                ]}
              >
                {(addComp, { data, loading, error }) => (
                  <ApolloConsumer>
                    {(client) => (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          disabled={!notDisabled}
                          onClick={findComps(
                            client,
                            props.comps,
                            addComp,
                            imageUploadMulti,
                          )}
                        >
                          Find Comps
                        </Button>
                      </Grid>
                    )}
                  </ApolloConsumer>
                )}
              </Mutation>
            )}
          </Mutation>
        </DialogActions>
      </Dialog>

      {/* Dialog foor when comps backend is loading */}
      <Dialog
        open={open && props.compsBackendLoading}
        onClose={closeFindComps}
        onKeyDown={stopPropagationForTab}
      >
        <DialogTitle>Adding Comps In Progress</DialogTitle>
        <DialogContent className="p-4">
          <BlockUi
            blocking={circle}
            loader={
              <PacmanLoader color={'var(--primary)'} loading={true} />
            }
            message={
              <div className="text-primary">
                <p>Connecting</p>
                <p>{findCompPercent}% Complete</p>
              </div>
            }
          >
            <DialogContentText>
              Housefolios is adding comps for this property right now.
              Come back a bit later to check them out!
            </DialogContentText>
          </BlockUi>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeFindComps}
            variant="text"
            disabled={circle}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openClose}
        onClose={() => setOpenClose(false)}
        onKeyDown={stopPropagationForTab}
        classes={{ paper: 'shadow-lg rounded' }}
      >
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div
              className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130"
              style={{ backgroundColor: '#ffddca' }}
            >
              <FontAwesomeIcon
                icon={['far', 'location-exclamation']}
                className="d-flex align-self-center display-3"
                style={{ fontSize: 56, color: '#ff8132' }}
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">No Comps found</h4>
          <p className="mb-0 font-size-lg text-muted">
            It looks like we were not able to find comparable
            properties with the search criteria that was provided.
          </p>
          <div className="pt-4">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  fullWidth={true}
                  variant="contained"
                  onClick={() => {
                    setOpenClose(false)
                    toggle()
                  }}
                  style={{
                    backgroundColor: '#008aff',
                    color: 'white',
                  }}
                >
                  <FontAwesomeIcon
                    icon={['far', 'magnifying-glass']}
                    className="d-flex align-self-center display-3 font-size-lg mr-2"
                  />
                  <Typography>New Search</Typography>
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={suggestionOpenClose && Boolean(compSuggestion)}
        onClose={() => {
          setSuggestionOpenClose(false)
          //setCompSuggestion("")
        }}
        onKeyDown={stopPropagationForTab}
        classes={{ paper: 'shadow-lg rounded' }}
      >
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div
              className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130"
              style={{ backgroundColor: '#ffddca' }}
            >
              <FontAwesomeIcon
                icon={['far', 'location-exclamation']}
                className="d-flex align-self-center display-3"
                style={{ fontSize: 56, color: '#ff8132' }}
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">
            {compSuggestion === 'too few comps'
              ? "We didn't find many potential comps"
              : compSuggestion === 'too many comps'
              ? 'We found a lot of potential comps'
              : 'No comps were found with your current search criteria'}
          </h4>
          <p className="mb-0 font-size-lg text-muted">
            {compSuggestion === 'too few comps'
              ? 'Would you like to refine your search?'
              : compSuggestion === 'too many comps'
              ? 'Would you like to refine your search?'
              : 'Try expanding your search criteria'}
          </p>
          <div className="pt-4">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  fullWidth={true}
                  variant="contained"
                  onClick={() => {
                    setSuggestionOpenClose(false)
                    //setCompSuggestion("")
                    toggle()
                  }}
                  style={{
                    backgroundColor: '#008aff',
                    color: 'white',
                  }}
                >
                  <FontAwesomeIcon
                    icon={['far', 'pen']}
                    className="d-flex align-self-center display-3 font-size-lg mr-2"
                  />
                  <Typography>Refine Search</Typography>
                </Button>
              </Grid>
              {compSuggestion !== 'no comps' && (
                <Mutation
                  mutation={IMAGE_UPLOAD_MULTI}
                  refetchQueries={[
                    {
                      query: GET_COMPPROPERTIES,
                      variables: {
                        propertyId: propertyId,
                      },
                    },
                    {
                      query: GET_PROPERTY,
                      variables: { id: propertyId },
                    },
                  ]}
                >
                  {(imageUploadMulti, { data, loading, error }) => (
                    <Mutation
                      mutation={ADD_COMPPROPERTY}
                      refetchQueries={[
                        {
                          query: GET_COMPPROPERTIES,
                          variables: {
                            propertyId: propertyId,
                          },
                        },
                        {
                          query: GET_PROPERTY,
                          variables: { id: propertyId },
                        },
                      ]}
                    >
                      {(addComp, { data, loading, error }) => (
                        <ApolloConsumer>
                          {(client) => (
                            <Grid item>
                              <Button
                                fullWidth={true}
                                variant="contained"
                                onClick={() => {
                                  setSuggestionOpenClose(false)
                                  setCompSuggestion('')
                                  toggle()
                                  findComps(
                                    client,
                                    props.comps,
                                    addComp,
                                    imageUploadMulti,
                                    true,
                                  )()
                                }}
                                style={{
                                  backgroundColor: '#008aff',
                                  color: 'white',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={['far', 'magnifying-glass']}
                                  className="d-flex align-self-center display-3 font-size-lg mr-2"
                                />
                                <Typography>Search Anyway</Typography>
                              </Button>
                            </Grid>
                          )}
                        </ApolloConsumer>
                      )}
                    </Mutation>
                  )}
                </Mutation>
              )}
            </Grid>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default withSession(FindCompsComponent)
