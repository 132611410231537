import React from 'react'
import { Dialog, Box } from '@mui/material'

const VideoDialog = ({ open, onClose, videoSrc }) => {
  return (
    <Dialog
      open={Boolean(open)}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            width: '100%',
            height: 0,
            paddingBottom: '56.25%', // 16:9 aspect ratio
            position: 'relative',
          }}
        >
          <iframe
            width="100%"
            height="100%"
            title="Training Video"
            src={videoSrc}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              border: 0,
            }}
            allow="fullscreen"
            allowFullScreen
          />
        </Box>
      </Box>
    </Dialog>
  )
}

export default VideoDialog
