import React, { useState } from 'react'

import map from 'lodash/map'
import find from 'lodash/find'
import ceil from 'lodash/ceil'
import slice from 'lodash/slice'
import moment from 'moment'
import get from 'lodash/get'
import isString from 'lodash/isString'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import { useQuery } from '@apollo/client'
import { GET_AFFILIATE } from '@/housefolios-components/CreateAccount/CreateAccountCard/queries'

const affiliateHostnames = {
  'app.propertyedge.pro': 'pep',
  // 'app.dealsauce.io': 'astroflip',
  'app.dealsauce.io': 'pacemorby',
}

const urlAffiliateHelmetData = {
  'app.propertyedge.pro': {
    titleText: 'Property Edge Pro',
    icon: 'https://housefolios-platform.s3.amazonaws.com/affiliateImages/6477d23419964974995227cf-230609210604-72796-pep-icon.png',
  },
  'app.dealsauce.io': {
    titleText: 'Deal Sauce (Subto)',
    icon: 'https://housefolios-platform.s3.amazonaws.com/affiliateImages/-240417210421-70094-pace_morby_small.png',
  },
  'dev-admin.housefolios.com': {
    titleText: 'Dev Testing for Housefolios',
    icon: '/images/defaultSidebarSmallLogo.png',
  },
}

function useAffiliate({ memberValue = '' }) {
  let memberURL

  const hostname = import.meta.env.VITE_HOSTNAME

  if (
    hostname === 'localhost' ||
    hostname === 'dev-admin.housefolios.com'
  ) {
    const urlParams = new URLSearchParams(window.location.search)
    memberURL = urlParams.get('domain')
  } else {
    memberURL = window.location.hostname
  }

  const urlAffiliate = affiliateHostnames[memberURL] || ''
  const urlHelmetData = urlAffiliateHelmetData[memberURL]

  let result = useQuery(GET_AFFILIATE, {
    variables: {
      memberValue: memberValue || urlAffiliate,
    },
  })
  const affiliateLoading = result.loading
  const { affiliate = {} } = result.data || {}

  return [
    {
      affiliate,
      affiliateLoading,
      urlDomain: memberURL,
      urlAffiliate,
      urlHelmetData,
    },
  ]
}

export default useAffiliate
