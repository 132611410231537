import map from 'lodash/map'
import find from 'lodash/find'
import head from 'lodash/head'
import slice from 'lodash/slice'
import filter from 'lodash/filter'
import ceil from 'lodash/ceil'
import intersection from 'lodash/intersection'
import intersectionBy from 'lodash/intersectionBy'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import get from 'lodash/get'
import sum from 'lodash/sum'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import orderBy from 'lodash/orderBy'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import remove from 'lodash/remove'

// Function to compare two elements in descending order based on specific fields
export function desc(a, b, orderBy, marketOrderBy) {
  // console.log('Desc:', a, b, orderBy, marketOrderBy)

  // Determine the field to compare for each element based on marketplaces existence
  let orderByA = a.marketplaces ? marketOrderBy : orderBy
  let orderByB = b.marketplaces ? marketOrderBy : orderBy

  // Perform string comparison if both elements have strings in the specified fields
  if (isString(get(a, orderByA)) && isString(get(b, orderByB))) {
    // Handle cases where one or both fields are empty
    if (!get(b, orderByB) && !get(a, orderByA)) {
      return 0
    }
    if (!get(b, orderByB)) {
      return -1
    }
    if (!get(a, orderByA)) {
      return 1
    }

    // Compare numeric values if both fields contain numeric strings
    if (
      every(get(a, orderByA), (c) => c <= '9' && c >= '0') &&
      every(get(b, orderByB), (c) => c <= '9' && c >= '0')
    ) {
      if (Number(get(b, orderByB)) < Number(get(a, orderByA))) {
        return -1
      }
      if (Number(get(b, orderByB)) > Number(get(a, orderByA))) {
        return 1
      }
      return 0
    } else {
      if (
        // Perform case-insensitive string comparison otherwise
        get(b, orderByB).toLowerCase() <
        get(a, orderByA).toLowerCase()
      ) {
        return -1
      }
      if (
        get(b, orderByB).toLowerCase() >
        get(a, orderByA).toLowerCase()
      ) {
        return 1
      }
      return 0
    }
  }
  // If either element contains arrays in the specified fields, compare based on sum of array elements
  else if (isArray(get(a, orderByA)) || isArray(get(b, orderByB))) {
    let aSum = sum(get(a, orderByA))
    let bSum = sum(get(b, orderByB))
    if (bSum < aSum) {
      return -1
    }
    if (bSum > aSum) {
      return 1
    }
    return 0
  }
  // For non-string and non-array fields, perform basic comparison
  else {
    if (isNil(get(b, orderByB)) && isNil(get(a, orderByA))) {
      return 0
    }
    if (isNil(get(b, orderByB))) {
      return -1
    }
    if (isNil(get(a, orderByA))) {
      return 1
    }
    if (get(b, orderByB) < get(a, orderByA)) {
      return -1
    }
    if (get(b, orderByB) > get(a, orderByA)) {
      return 1
    }
    return 0
  }
}

// Function to stably sort an array based on a custom comparison function
export function stableSort(array, cmp) {
  // Map each element to a tuple containing the element and its original index
  const stabilizedThis = array.map((el, index) => [el, index])

  // Sort the mapped array based on custom comparison function and original indices
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  // Return the sorted elements without their indices
  return stabilizedThis.map((el) => el[0])
}

// Function to get the appropriate sorting function based on order type (asc or desc)
export function getSorting(order, orderBy, marketOrderBy) {
  return order === 'desc'
    ? // Return descending sort function if order is 'desc'
      (a, b) => desc(a, b, orderBy, marketOrderBy)
    : // Return ascending sort function if order is 'asc'
      (a, b) => -desc(a, b, orderBy, marketOrderBy)
}
