/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2024-11-07 14:12:31
 * @ Description: A table with comps for list view
 */

import React, { useState } from 'react'
import Pagination from '@mui/material/Pagination'

import map from 'lodash/map'
import split from 'lodash/split'
import ceil from 'lodash/ceil'
import slice from 'lodash/slice'
import head from 'lodash/head'
import get from 'lodash/get'
import isString from 'lodash/isString'
import every from 'lodash/every'
import isNil from 'lodash/isNil'
import includes from 'lodash/includes'
import intersection from 'lodash/intersection'
import replace from 'lodash/replace'
import compact from 'lodash/compact'

import {
  Avatar,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  Table,
  TableCell,
  TableSortLabel,
  Tooltip,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import useSorting, {
  getSorting,
  stableSort,
} from '@/hooks/useSorting'

function CenterPageCompListComponent(props) {
  const { comps, property, openEdit } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedComp, setSelectedComp] = useState('')
  const handleOpenMoreActions = (event, _id) => {
    setAnchorEl(event.currentTarget)
    setSelectedComp(_id)
  }
  const handleCloseMoreActions = () => {
    setAnchorEl(null)
    setSelectedComp('')
  }

  const [defaultOrderByTarget = 'saleDate', defaultOrder = 'desc'] =
    split(props.ordering || 'saleDate-desc', '-')

  const [{ order, orderBy }, { handleRequestSort }] = useSorting({
    defaultOrder,
    defaultOrderBy: defaultOrderByTarget,
  })

  // sets page in local storage for potential comps if it recieved a title. Need to change if we end up with more comp lists
  const [page, setPage] = useState(
    parseInt(
      localStorage.getItem(
        props.title
          ? `${property._id}-comps-potential-list-page`
          : `${property._id}-comps-actual-list-page`,
      ),
    ) || 1,
  )
  const handleChange = (event, value) => {
    if (props.title)
      localStorage.setItem(
        `${property._id}-comps-potential-list-page`,
        value,
      )
    else
      localStorage.setItem(
        `${property._id}-comps-actual-list-page`,
        value,
      )
    setPage(value)
  }

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })

  function formatDate(date) {
    var today = new Date(date),
      d = new Date(
        today.getTime() + Math.abs(today.getTimezoneOffset() * 60000),
      ),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = '' + d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    while (year.length < 4) year = '0' + year

    return [year, month, day].join('-')
  }

  const tableHeaders = [
    { title: 'Address', name: 'address' },
    { title: 'City', name: 'city' },
    { title: 'State', name: 'state' },
    { title: 'Zip', name: 'zip' },
    { title: 'Beds', name: 'beds' },
    { title: 'Baths', name: 'baths' },
    {
      title: 'SqFt',
      name: 'sqft',
      formatter: (x) => numberFormat.format(x),
    },
    { title: 'Year', name: 'year' },
    {
      title: 'Sold Price',
      name: 'estval',
      formatter: (x) => currencyFormat.format(x),
    },
    {
      title: 'Date Sold',
      name: 'saleDate',
      formatter: (x) => formatDate(x),
    },
    {
      title: 'Distance Away',
      name: 'distance',
      formatter: (x) => numberFormat.format(x),
    },
    {
      title: 'Days on Market (D.O.M)',
      name: 'daysOnMarket',
      formatter: (x) => {
        if (!isNil(x)) return numberFormat.format(x)
        else return '-'
      },
    },
    { title: 'Rehab Level', name: 'rehabLevel' },
    {
      title: 'Price Adjustment',
      name: 'priceAdjustment',
      formatter: (x) => Number(x) + '%',
    },
    {
      title: 'Date Added',
      name: 'createdAt',
      formatter: (x) => formatDate(x),
    },
    { title: 'Added By', name: 'createdBy.username' },
  ]

  const compare = intersection(
    map(comps, (comp) => comp._id),
    props.selected,
  )

  const visuallyHidden = {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }

  const count = ceil(comps.length / 25)
  if (page > count) setPage(count || 1)
  return (
    <Grid
      item
      xs={12}
      className="mb-spacing-6 d-flex"
      style={{ width: 1 }}
    >
      {' '}
      {/* Width is required to prevent table overflow. Doesn't affect size */}
      <Card className="mb-spacing-6-x2">
        <div className="card-header pr-2">
          <div className="card-header--title">
            <small>{property.address}</small>
            <b>{props.title ? props.title : 'Comps'}</b>
          </div>
        </div>
        <CardContent className="p-0">
          <div className="table-responsive">
            <Table className="table text-nowrap mb-0">
              <thead className="thead-light">
                <tr className="text-center">
                  {!props.shared && (
                    <TableCell>
                      {/* <Tooltip title="Select All">
                        <Checkbox
                          color="primary"
                          id="CustomCheckbox3"
                          className="align-self-start"
                          checked={compare.length > 0}
                          indeterminate={
                            compare.length < comps.length &&
                            compare.length > 0
                          }
                          onClick={props.selectAll}
                        />
                      </Tooltip> */}
                    </TableCell>
                  )}
                  <TableCell
                    key={'Address'}
                    //style={{ whiteSpace: 'nowrap' }}
                    sortDirection={
                      orderBy === 'address' ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === 'address'}
                      direction={order}
                      onClick={(event) =>
                        handleRequestSort(event, 'address')
                      }
                    >
                      {'Address'}
                      {orderBy === 'address' ? (
                        <span style={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Google Search</TableCell>
                  {map(slice(tableHeaders, 1), (column) => (
                    <TableCell
                      key={column.title}
                      //style={{ whiteSpace: 'nowrap' }}
                      align={column.align}
                      sortDirection={
                        orderBy === column.name ? order : false
                      }
                    >
                      {column.name ? (
                        <TableSortLabel
                          active={orderBy === column.name}
                          direction={order}
                          onClick={(event) =>
                            handleRequestSort(event, column.name)
                          }
                        >
                          {column.title}
                          {orderBy === column.name ? (
                            <span style={visuallyHidden}>
                              {order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        column.title
                      )}
                    </TableCell>
                  ))}
                  {!props.shared && <TableCell>Action</TableCell>}
                </tr>
              </thead>
              <tbody>
                {map(
                  slice(
                    stableSort(comps, getSorting(order, orderBy)),
                    (page - 1) * 25,
                    page * 25,
                  ),
                  (comp, index) => (
                    <tr style={{ position: 'relative' }}>
                      {!props.shared && (
                        <td className="text-center">
                          <Checkbox
                            color="primary"
                            id="CustomCheckbox4"
                            className="align-self-start"
                            checked={includes(
                              props.selected,
                              comp._id,
                            )}
                            onClick={props.selectComp(comp._id)}
                          />
                        </td>
                      )}
                      <td>
                        <div className="d-flex align-items-center">
                          <Avatar
                            className={
                              comp.potential ? 'bg-warning' : null
                            }
                            style={{
                              height: 30,
                              width: 30,
                              backgroundColor: comp.potential
                                ? null
                                : '#2f3f4d',
                              marginRight: 5,
                            }}
                          >
                            {comp.mapIndex + 1}
                          </Avatar>
                          <div className="avatar-icon-wrapper mr-2">
                            {compact(comp.images).length >= 5 ? (
                              <div className="avatar-icon rounded d-flex justify-content-center align-items-center">
                                <div
                                  className="blur-load"
                                  style={{
                                    backgroundImage:
                                      'url(' +
                                      head(comp.images).url_small +
                                      ')',
                                  }}
                                >
                                  <img
                                    src={head(comp.images).url}
                                    loading="lazy"
                                  />
                                </div>
                              </div>
                            ) : (
                              <a
                                href={
                                  props?.session?.me?.activeOrg
                                    ?.isRealeflow
                                    ? ''
                                    : `https://www.comehome.com/property-details/${replace(
                                        comp.address,
                                        /\s/g,
                                        '-',
                                      )}-${replace(
                                        comp.city,
                                        /\s/g,
                                        '-',
                                      )}-${comp.state}-${
                                        comp.zip
                                      }?modal=photo-list`
                                }
                                target="_blank"
                              >
                                <div
                                  onClick={(event) => {
                                    event.preventDefault()
                                    props?.session?.me?.activeOrg
                                      ?.isRealeflow
                                      ? ''
                                      : window.open(
                                          `https://www.comehome.com/property-details/${replace(
                                            comp.address,
                                            /\s/g,
                                            '-',
                                          )}-${replace(
                                            comp.city,
                                            /\s/g,
                                            '-',
                                          )}-${comp.state}-${
                                            comp.zip
                                          }?modal=photo-list`,
                                          '_blank',
                                        )
                                  }}
                                  className="avatar-icon rounded"
                                >
                                  <img
                                    src={
                                      props?.session?.me?.activeOrg
                                        ?.member === 'pacemorby' ||
                                      props?.session?.me?.activeOrg
                                        ?.member === 'astroflip'
                                        ? '/images/DealSauce-Logo-Tan.png'
                                        : props?.session?.me
                                            ?.activeOrg?.member ===
                                          'pep'
                                        ? '/images/pep-icon-only.png'
                                        : '/images/comehome_small.png'
                                    }
                                    alt="..."
                                  />
                                </div>
                              </a>
                            )}
                          </div>
                          <div>
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault()
                                openEdit(comp)
                              }}
                              className="font-weight-bold text-black"
                              title="..."
                            >
                              {comp.address}
                            </a>
                          </div>
                        </div>
                      </td>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            window.open(
                              `https://www.google.com/search?q=${comp?.address} ${comp?.city}, ${comp?.state}`,
                              '_blank',
                              // 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400',
                            )
                          }}
                        >
                          <FontAwesomeIcon
                            icon={['far', 'magnifying-glass']}
                          />
                        </IconButton>
                      </TableCell>
                      {map(slice(tableHeaders, 1), (column) => (
                        <td>
                          <div>
                            {column.formatter
                              ? column.formatter(
                                  get(comp, column.name),
                                )
                              : get(comp, column.name)}
                          </div>
                        </td>
                      ))}
                      {!props.shared && (
                        <td>
                          <div>
                            <Tooltip title="More Actions">
                              <IconButton
                                onClick={(event) =>
                                  handleOpenMoreActions(
                                    event,
                                    comp._id,
                                  )
                                }
                                style={{
                                  backgroundColor: '#e4eeff',
                                  height: 30,
                                  width: 30,
                                }}
                                size="large"
                              >
                                <FontAwesomeIcon
                                  icon={['far', 'ellipsis']}
                                  className="font-size-lg"
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={
                              Boolean(anchorEl) &&
                              comp._id === selectedComp
                            }
                            onClose={handleCloseMoreActions}
                            classes={{ list: 'p-0' }}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <div>
                              <List
                                component="div"
                                className="nav-list-square nav-neutral-primary"
                              >
                                <ListItem
                                  button
                                  onClick={() => {
                                    openEdit(comp)
                                    handleCloseMoreActions()
                                  }}
                                >
                                  <span>Edit Comp</span>
                                </ListItem>
                              </List>
                            </div>
                          </Menu>
                        </td>
                      )}
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          </div>
          <div className="divider" />
          <div className="divider" />
          {count && (
            <div className="p-3 d-flex justify-content-center">
              <Pagination
                className="pagination-first"
                size="small"
                count={count}
                page={page}
                onChange={handleChange}
              />
            </div>
          )}
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withSession(CenterPageCompListComponent)
