import Reactour from 'reactour'
import { Button, Typography, Grid } from '@mui/material'
import { includes } from 'lodash'
import { useLocation } from 'react-router-dom'

export const ChooseStrategyTour =
  ({
    isOpenChooseStrategyTour,
    closeChooseStrategyTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = !includes(pathname, '/Edit')
    const steps = [
      {
        selector: '[data-tour2="strategy-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Click "Edit Property" to see strategy section
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                goToTo(1)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="strategy-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ textAlign: 'center' }}
                >
                  You can change the strategy by clicking on the
                  "Strategy" card above.
                </Typography>
              </Grid>
            </Grid>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              goToTo(2)
            }
        },
      },
      {
        selector: '[data-tour="strategy-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ textAlign: 'center' }}
                >
                  What does the strategy do?
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  From the portfolio view, it displays the analytics
                  pertaining to your selected strategy on the property
                  card.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img
                  src={strategyTourImg}
                  alt=""
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          )
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenChooseStrategyTour}
        onRequestClose={closeChooseStrategyTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }
