import Reactour from 'reactour'
import { Button } from '@mui/material'
import { includes } from 'lodash'
import { useLocation } from 'react-router-dom'

//OVERVIEW COMMENT: Tour component included in the routes page to allow it to function on page change. update prevents losing focus on page change.
export const Tour =
  ({ isOpen, closeTour, location }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = !includes(pathname, '/Edit')
    const steps = [
      {
        selector: '[data-tour="step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <p>In this section, you can edit:</p>
              <ul>
                <li>Images</li>
                <li>
                  Property details (beds, baths, sqft, year, ect.
                </li>
                <li>Listing information</li>
                <li>Status</li>
                <li>Portfolio</li>
                <li>and More</li>
              </ul>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                goToTo(1)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="step-2"]',
        content: (
          <div>
            <p>
              To help ensure an accurate analysis, verify that all of
              these numbers are correct
            </p>
            <p>
              <i>
                *It's no fun to analyze a 3 bed property, just to find
                out later that it only has 2 beds and an
                office...that's a good way to waste time.
              </i>
            </p>
          </div>
        ),
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpen}
        onRequestClose={closeTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }
