import { gql } from '@apollo/client'

export const ADD_AFFILIATE = gql`
  mutation addAffiliate($affiliateInput: AffiliateInput!) {
    addAffiliate(affiliateInput: $affiliateInput) {
      name
      orgId
      hfpid
      memberValue
      defaultStrategy
      companyName
      email
      phone
      createdAt
      commission
      active
      createAccount
      signIn
      launchpad
      sidebar
      training
    }
  }
`

export const UPDATE_AFFILIATE = gql`
  mutation updateAffiliate(
    $affiliateInput: AffiliateInput!
    $orgId: ID
    $affiliateId: ID
  ) {
    updateAffiliate(
      affiliateInput: $affiliateInput
      orgId: $orgId
      affiliateId: $affiliateId
    ) {
      name
      orgId
      hfpid
      memberValue
      defaultStrategy
      companyName
      email
      phone
      createdAt
      commission
      active
      createAccount
      signIn
      launchpad
      sidebar
      training
    }
  }
`
