/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:26:27
 * @ Modified by: David Helmick
 * @ Modified time: 2024-12-17 12:18:48
 * @ Description: Top level component. Sets up the cache and graphql link.
 */

import React from 'react'
import ReactDOM from 'react-dom'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  ApolloLink,
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'

import { createUploadLink } from 'apollo-upload-client'
import includes from 'lodash/includes'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { SnackbarProvider } from 'notistack'
import { signOut } from '@/housefolios-components/SignOut'
import { cli } from '@google/maps'
import { saveAuthToken } from './utils/auth'
import { createRoot } from 'react-dom/client'

const httpLink = createUploadLink({ uri: import.meta.env.VITE_URI })

// Update the wsLink to include the authentication token
const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_WSURI,
    connectionParams: {
      authToken: localStorage.getItem('token'), // Ensure you're getting the correct token name from localStorage
    },
  }),
)

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return (
      kind === 'OperationDefinition' && operation === 'subscription'
    )
  },
  wsLink,
  httpLink,
)

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('token')
    if (token) {
      headers = { ...headers, 'x-token': token }
    }

    const hostname = import.meta.env.VITE_HOSTNAME
    let domain
    if (
      hostname === 'localhost' ||
      hostname === 'dev-admin.housefolios.com'
    ) {
      const urlParams = new URLSearchParams(window.location.search)
      domain = urlParams.get('domain')
    } else {
      domain = window.location.hostname
    }

    if (domain) {
      headers = { ...headers, domain: domain }
    }

    return { headers }
  })

  // return forward(operation)

  return forward(operation).map((data) => {
    try {
      const context = operation.getContext()
      if (context?.response) {
        const {
          response: { headers },
        } = context

        if (headers) {
          const refreshToken = headers.get('refreshToken')
          if (refreshToken) {
            saveAuthToken(refreshToken)
          }
        }
      }

      return data
    } catch (error) {
      console.log('authLinkResponseError', error)
    }
  })
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const expired = includes(message, 'Your session expired')

      if (message === 'UNAUTHENTICATED' || expired) {
        signOut(client)
      }
    })
  }

  if (networkError) {
    if (networkError.statusCode === 401) {
      signOut(client)
    }
  }
})

const link = ApolloLink.from([authLink, errorLink, terminatingLink])

const cache = new InMemoryCache({
  typePolicies: {
    // Property:
    // {
    //   keyFields: []
    // }
    //   Query: {
    //     fields: {
    //       properties: {
    //         keyArgs: false,
    //         merge(existing, incoming, { readField }) {
    //           const properties = existing
    //             ? { ...existing.properties }
    //             : {}
    //           incoming.properties.forEach((property) => {
    //             properties[readField('id', property)] = property
    //           })
    //           return {
    //             cursor: incoming.cursor,
    //             properties,
    //           }
    //         },
    //         read(existing) {
    //           if (existing) {
    //             return {
    //               cursor: existing.cursor,
    //               properties: Object.values(existing.properties),
    //             }
    //           }
    //         },
    //       },
    //     },
    //   },
    // },
    // typePolicies: {
    //   PaginatedProperties: {
    //     // Define field policies for PaginatedProperties type
    //     fields: {
    //       properties: {
    //         // Merge paginated results with existing cache data
    //         keyArgs: false, // Disable automatic key generation
    //         merge(existing = [], incoming) {
    //           console.log('existing', existing)
    //           console.log('incoming', incoming)
    //           return [...existing, ...incoming]
    //         },
    //       },
    //     },
    //   },
  },
})

const client = new ApolloClient({
  link,
  cache,
})

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <ApolloProvider client={client}>
    <SnackbarProvider maxSnack={3}>
      <App />
    </SnackbarProvider>
  </ApolloProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
