/**
 * @ Author: Housefolios
 * @ Create Time: 2021-10-05 11:07:32
 * @ Modified by: David Helmick
 * @ Modified time: 2024-06-06 16:12:18
 * @ Description: Dialog for editing a portfolio
 */

import React, { useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  MenuItem,
  TextField,
} from '@mui/material'

import Autocomplete from '@mui/material/Autocomplete'

import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'
import head from 'lodash/head'

import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { useMutation } from '@apollo/client'
import { UPDATE_PORTFOLIO } from '../mutations'
import { enqueueSnackbar } from 'notistack'
import { GET_PORTFOLIOS } from '../queries'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import withSession from '@/housefolios-components/Session/withSession'
import {
  filterPreferedStrategies,
  getStrategyName,
  getStrategyOptions,
} from '@/utils/stratagies'

function EditPortfolioComponent(props) {
  const { sidebarMenu, portfolio } = props

  const [openEditPortfolio, setEditPortfolio] = useState(false)
  const handleOpenEditPortfolio = () => {
    setEditPortfolio(true)
    setName(portfolio.name)
    setDescription(portfolio.description)
    setStrategy(portfolio.strategy)
    setPreferences(
      merge(
        cloneDeep(DEFAULT_PREFERENCES),
        props?.session?.me?.activeOrg?.memberPreferences,
        props?.session?.me?.activeOrg?.preferences,
        { general: { strategies: portfolio.strategies } },
      ),
    )
  }
  const handleCloseEditPortfolio = () => {
    setEditPortfolio(false)
  }

  const [name, setName] = useState(portfolio.name)
  const [description, setDescription] = useState(
    portfolio.description,
  )
  const [strategy, setStrategy] = useState(portfolio.strategy)

  const [preferences, setPreferences] = useState(
    merge(
      cloneDeep(DEFAULT_PREFERENCES),
      props?.session?.me?.activeOrg?.memberPreferences,
      props?.session?.me?.activeOrg?.preferences,
      { general: { strategies: portfolio.strategies } },
    ),
  )

  if (
    !includes(
      filterPreferedStrategies(
        preferences,
        props?.session?.me?.activeOrg?.member,
        true,
      ),
      strategy,
    )
  )
    setStrategy(
      head(
        filterPreferedStrategies(
          preferences,
          props?.session?.me?.activeOrg?.member,
          true,
        ),
      ),
    )

  const handleChangeDropdown = (event, value, collection, name) => {
    let newPreferences = { ...preferences }
    let newValue = value
    if (name === 'strategies') {
      newPreferences[collection][name] = map(
        newPreferences[collection][name],
        (preference) => {
          return {
            value: preference.value,
            active: includes(newValue, preference.value),
            memberOnly: preference.memberOnly,
          }
        },
      )
    } else newPreferences[collection][name] = newValue
    setPreferences(newPreferences)
  }

  const [updatePortfolio] = useMutation(UPDATE_PORTFOLIO)

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  return (
    <>
      {sidebarMenu && (
        <ListItem button onClick={handleOpenEditPortfolio}>
          <EditOutlinedIcon
            style={{ marginRight: 5, color: '#757575' }}
          />
          <span>Edit Portfolio</span>
        </ListItem>
      )}

      {/* {titleMenu && (
        <ListItem button onClick={handleOpenEditPortfolio}>
          <div className="nav-link-icon">
            <FontAwesomeIcon icon={['far', 'pen']} />
          </div>
          <span>Edit Portfolio</span>
        </ListItem>
      )} */}
      <Dialog
        open={openEditPortfolio}
        onClose={handleCloseEditPortfolio}
        classes={{ paper: 'shadow-lg rounded' }}
        maxWidth="xs"
        fullWidth={true}
        onKeyDown={stopPropagationForTab}
      >
        <DialogTitle>Edit Portfolio</DialogTitle>
        {/* <DialogContent className="p-4"> */}
        <ValidatorForm
          style={{
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={() =>
            updatePortfolio({
              variables: {
                id: portfolio._id,
                portfolioInput: {
                  name,
                  strategy,
                  strategies: get(preferences, 'general.strategies'),
                  description,
                },
              },
              refetchQueries: [
                {
                  query: GET_PORTFOLIOS,
                },
              ],
            }).then(() => {
              enqueueSnackbar(`Changes Saved`, {
                variant: 'success',
                autoHideDuration: 3000,
              })
              handleCloseEditPortfolio()
            })
          }
        >
          <DialogContent className="p-4">
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  margin="dense"
                  label="Portfolio Name"
                  fullWidth={true}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  required={true}
                  validators={['required', 'maxStringLength: 20']}
                  errorMessages={[
                    'This field is required',
                    'You cannot exceed 20 characters',
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  margin="dense"
                  label="Portfolio Description"
                  fullWidth={true}
                  value={description}
                  onChange={(event) =>
                    setDescription(event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="checkboxes-tags-demo"
                  multiple
                  disableCloseOnSelect
                  options={filterPreferedStrategies(
                    preferences,
                    props?.session?.me?.activeOrg?.member,
                    false,
                  )}
                  value={filterPreferedStrategies(
                    preferences,
                    props?.session?.me?.activeOrg?.member,
                    true,
                  )}
                  getOptionLabel={(option) =>
                    getStrategyName(
                      option,
                      props?.session?.me?.activeOrg?.member,
                    )
                  }
                  onChange={(event, newValue) => {
                    if (!isEmpty(newValue))
                      handleChangeDropdown(
                        event,
                        newValue,
                        'general',
                        'strategies',
                      )
                  }}
                  disableClearable
                  // isOptionEqualToValue={(option, value) =>
                  //   option === value.value && value.active
                  // }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Strategies"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  select
                  variant="outlined"
                  margin="dense"
                  label="Default Property Strategy"
                  fullWidth={true}
                  value={strategy}
                  onChange={(event) =>
                    setStrategy(event.target.value)
                  }
                  required={true}
                  validators={['required']}
                  errorMessages={['This field is required']}
                >
                  {getStrategyOptions(
                    props?.session?.me?.activeOrg?.member,
                    filterPreferedStrategies(
                      preferences,
                      props?.session?.me?.activeOrg?.member,
                      true,
                    ),
                  )}
                </TextValidator>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleCloseEditPortfolio}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginRight: 10 }}
            >
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
        {/* <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon
                icon={['fas', 'xmark']}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">
            Are you sure you want to delete this?
          </h4>
          <p className="mb-0 font-size-lg text-muted">
            All data will be deleted. This action cannot be undone.
          </p>
          <div className="pt-4">
            <Button
              variant="text"
              onClick={handleCloseEditPortfolio}
            //className="btn-neutral-secondary btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: '#f83145', color: 'white' }}
              onClick={handleCloseEditPortfolio}
            //className="btn-danger btn-pill mx-1"
            >
              <span className="btn-wrapper--label">Delete</span>
            </Button>
          </div>
        </div> */}
      </Dialog>
    </>
  )
}

export default withSession(EditPortfolioComponent)
