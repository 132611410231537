import get from 'lodash/get'
import { ExcelFormulas } from './excelFormulas'
import cloneDeep from 'lodash/cloneDeep'
import map from 'lodash/map'
import sum from 'lodash/sum'
import { Grid, Paper, Typography } from '@mui/material'

export const loanBalance = (args) => {
  const {
    financeAmount,
    netOperatingIncome,
    mortgagePayment,
    monthlyRate,
    holdingPeriod,
    payDownAccelerator,
    payDownGrowth,
    growthRateIncome,
    growthRateExpense,
  } = args

  let amortizationData = []
  let principal = 0
  let additionalPrincipal = 0
  let interest = 0
  let amtLeft = 0
  let monthYear
  let today = new Date()
  let month
  let year
  let maxHoldingPeriod = holdingPeriod

  let income = netOperatingIncome
  let expenses = mortgagePayment * 12

  let cashFlow = income - expenses
  let paydownMonthly =
    (payDownAccelerator / 100) * (cashFlow / 12) > 0
      ? (payDownAccelerator / 100) * (cashFlow / 12)
      : 0

  if (maxHoldingPeriod >= 600) maxHoldingPeriod = 600
  for (let index = 1; index < maxHoldingPeriod + 1; index++) {
    if (index === 1) {
      principal = mortgagePayment - financeAmount * monthlyRate
      interest = mortgagePayment - principal
      additionalPrincipal = paydownMonthly
      amtLeft = financeAmount - principal - paydownMonthly
      month = today.toLocaleString('default', { month: 'long' })
      year = today.getFullYear().toString()
      monthYear = month + ' ' + year
      amortizationData.push({
        principal,
        interest,
        additionalPrincipal,
        amtLeft,
        monthYear,
      })
    } else {
      if (amtLeft <= 0.01) {
        break
      }

      let currentDate = new Date(today.setMonth(today.getMonth() + 1))
      let currentMonth = currentDate.toLocaleString('default', {
        month: 'long',
      })
      principal = mortgagePayment - amtLeft * monthlyRate
      amtLeft = amtLeft - principal - paydownMonthly
      interest = mortgagePayment - principal
      additionalPrincipal = paydownMonthly
      let month = currentMonth
      let year = today.getFullYear().toString()
      monthYear = month + ' ' + year
      amortizationData.push({
        principal:
          additionalPrincipal + amtLeft <= 0
            ? principal + additionalPrincipal + amtLeft
            : principal,
        interest,
        additionalPrincipal:
          amtLeft <= 0
            ? additionalPrincipal + amtLeft <= 0
              ? 0
              : additionalPrincipal + amtLeft
            : additionalPrincipal,
        amtLeft: amtLeft <= 0 ? 0 : amtLeft,
        monthYear,
      })
      if (index % 12 === 0 && payDownGrowth) {
        income = income * (1 + growthRateIncome / 100)
        expenses = expenses * (1 + growthRateExpense / 100)
        cashFlow = income - expenses
        paydownMonthly =
          (payDownAccelerator / 100) * (cashFlow / 12) > 0
            ? (payDownAccelerator / 100) * (cashFlow / 12)
            : 0
      }
    }
  }
  return amortizationData
}

export const calculatePITI = (args) => {
  const {
    financeAmount,
    monthlyRate,
    propertyTaxes, // Annual property taxes
    propertyInsurance, // Annual property insurance
    startDate, // The start date of the loan
  } = args
  // Convert the interest rate to a monthly rate
  const annualRate = monthlyRate > 1 ? monthlyRate / 100 : monthlyRate
  const monthlyInterestRate = annualRate / 12

  // Calculate the number of months between the start date and today
  const today = new Date()
  const start = new Date(startDate)
  const monthsElapsed = Math.floor(
    (today - start) / (1000 * 60 * 60 * 24 * 30.44),
  ) // Approximate average month length

  // Calculate the monthly mortgage payment using the amortization formula
  const monthlyMortgagePayment =
    (financeAmount * monthlyInterestRate) /
    (1 - Math.pow(1 + monthlyInterestRate, -360))

  // Calculate property taxes and insurance per month
  const monthlyTaxes = propertyTaxes / 12
  const monthlyInsurance = propertyInsurance / 12

  // Initialize remaining balance and monthly payment variables
  let remainingBalance = financeAmount
  let principal = 0
  let interest = 0

  // Iterate through each month up to the current month to calculate the remaining balance, principal, and interest
  for (let month = 1; month <= monthsElapsed; month++) {
    interest = remainingBalance * monthlyInterestRate
    principal = monthlyMortgagePayment - interest
    remainingBalance -= principal
  }

  // Calculate the total monthly payment (PITI)
  const totalMonthlyPayment =
    monthlyMortgagePayment + monthlyTaxes + monthlyInsurance

  return {
    principal: principal.toFixed(2),
    interest: interest.toFixed(2),
    taxes: monthlyTaxes.toFixed(2),
    insurance: monthlyInsurance.toFixed(2),
    totalMonthlyPayment: totalMonthlyPayment.toFixed(2),
    remainingBalance: remainingBalance.toFixed(2),
  }
}

const parseDateString = (dateString) => {
  const year = dateString.substring(0, 4)
  const month = dateString.substring(4, 6)
  const day = dateString.substring(6, 8)
  const date = new Date(year, month - 1, day)
  return date
}

export const getPitiDataObject = (args) => {
  const { property, piti } = args

  return {
    creative: {
      asIsValue:
        property?.pitiData?.creative?.asIsValue ||
        property?.analysis?.creative?.offerPrice,
      principal:
        property?.pitiData?.creative?.principal || piti?.principal,
      interest:
        property?.pitiData?.creative?.interest || piti?.interest,
      taxes: property?.pitiData?.creative?.taxes || piti?.taxes,
      insurance:
        property?.pitiData?.creative?.insurance || piti?.insurance,
      existingMortgage:
        property?.pitiData?.creative?.existingMortgage ||
        Number(
          get(property, 'realeflowData.LoanAmount') ||
            get(
              property,
              'realeflowData.SubjectProperty.TotalLoans',
            ) ||
            0,
        ),
      loanType:
        property?.pitiData?.creative?.loanType ||
        get(
          property,
          'realeflowData.SubjectProperty.HistoryData.0.LookupCodes.LoanType',
        ) ||
        get(property, 'realeflowData.LoanType') ||
        '',
      originDate: property?.pitiData?.creative?.originData
        ? new Date(property?.pitiData?.creative?.originData)
        : get(property, 'realeflowData.RecordingDate')
          ? new Date(get(property, 'realeflowData.RecordingDate'))
          : get(
                property,
                'realeflowData.SubjectProperty.Assessor.curr_sale_recording_date',
              )
            ? parseDateString(
                get(
                  property,
                  'realeflowData.SubjectProperty.Assessor.curr_sale_recording_date',
                ),
              )
            : undefined,
      rent:
        property?.pitiData?.creative?.rent ||
        sum(property?.acquisition?.analysis?.rent),
      avgRate:
        property?.pitiData?.creative?.avgRate ||
        (property?.assumptions?.avgRateType === 'Weekly'
          ? sum(property?.assumptions?.avgRate) / 7
          : sum(property?.assumptions?.avgRate)),
      moneyToSeller: property?.pitiData?.creative?.moneyToSeller,
      arrears: property?.pitiData?.creative?.arrears,
      agentFee: property?.pitiData?.creative?.agentFee,
      assignmentFee: property?.pitiData?.creative?.assignmentFee,
      transactionCoordinatorExpenses:
        property?.pitiData?.creative?.transactionCoordinatorExpenses,
      lendingExpenses: property?.pitiData?.creative?.lendingExpenses,
      estRehab: property?.pitiData?.creative?.estRehab,
      furnishings: property?.pitiData?.creative?.furnishings,
      holdingCosts: property?.pitiData?.creative?.holdingCosts,
      HML: {
        ...cloneDeep(property?.pitiData?.creative?.HML),
        loanStartDate: new Date(
          property?.pitiData?.creative?.HML?.loanStartDate,
        ),
      },
      PML: {
        ...cloneDeep(property?.pitiData?.creative?.PML),
        loanStartDate: new Date(
          property?.pitiData?.creative?.PML?.loanStartDate,
        ),
      },
      existingLoans:
        map(property?.pitiData?.creative?.existingLoans, (loan) => {
          return {
            ...cloneDeep(loan),
            loanStartDate: new Date(loan?.loanStartDate),
          }
        }) || [],
      equityBalance: property?.pitiData?.creative?.equityBalance,
      sellerInterestRate:
        property?.pitiData?.creative?.sellerInterestRate,
      monthlyPayment: property?.pitiData?.creative?.monthlyPayment,
      sellerTerm: property?.pitiData?.creative?.sellerTerm,
    },
    cash: {
      tcExpenses: property?.pitiData?.cash?.tcExpenses,
      assignmentToSelf: property?.pitiData?.cash?.assignmentToSelf,
      dispoExpenses: property?.pitiData?.cash?.dispoExpenses,
      jvConnectorFee: property?.pitiData?.cash?.jvConnectorFee,
      closingCost: property?.pitiData?.cash?.closingCost,
      agentExpense: property?.pitiData?.cash?.agentExpense,
      renovationExpenses:
        property?.pitiData?.cash?.renovationExpenses,
      optionalExpense: property?.pitiData?.cash?.optionalExpense,
    },
  }
}

export const getPitiTotal = (property) => {
  return (
    Number(property?.pitiData?.creative?.principal) +
      Number(property?.pitiData?.creative?.interest) +
      Number(property?.pitiData?.creative?.taxes) +
      Number(property?.pitiData?.creative?.insurance) || 0
  )
}

export const getCreativeEntryFee = (property) => {
  return (
    Number(property?.pitiData?.creative?.moneyToSeller || 0) +
    Number(property?.pitiData?.creative?.arrears || 0) +
    Number(property?.pitiData?.creative?.agentFee || 0) +
    Number(property?.pitiData?.creative?.assignmentFee || 0) +
    Number(
      property?.pitiData?.creative?.transactionCoordinatorExpenses ||
        0,
    ) +
    Number(property?.pitiData?.creative?.lendingExpenses || 0) +
    Number(property?.pitiData?.creative?.estRehab || 0) +
    Number(property?.pitiData?.creative?.furnishings || 0) +
    Number(property?.pitiData?.creative?.holdingCosts || 0)
  )
}

export const getCashEntryFee = (property) => {
  return (
    Number(property?.pitiData?.cash?.tcExpenses || 0) +
    Number(property?.pitiData?.cash?.assignmentToSelf || 0) +
    Number(property?.pitiData?.cash?.dispoExpenses || 0) +
    Number(property?.pitiData?.cash?.jvConnectorFee || 0) +
    Number(property?.pitiData?.cash?.closingCost || 0) +
    Number(property?.pitiData?.cash?.agentExpense || 0) +
    Number(property?.pitiData?.cash?.renovationExpenses || 0) +
    Number(property?.pitiData?.cash?.optionalExpense || 0)
  )
}

export const getMortgage = (args) => {
  const { interestOnlyLoan, term, interestRate, loanAmount } =
    args || {}
  return interestOnlyLoan
    ? (interestRate * loanAmount) / 12 / 100 || 0
    : term > 0
      ? -1 *
          ExcelFormulas.PMT({
            rate: interestRate / 100 / 12,
            numPeriods: term,
            presentValue: loanAmount,
          }) || 0
      : 0
}

export const ContactInformation = ({ property }) => {
  if (
    property?.realeflowData?.MLS_Curr_Status ||
    property?.realeflowData?.ListingStatus
  )
    return (
      <Grid item>
        <Paper
          elevation={3}
          style={{
            padding: '20px',
            marginBottom: '20px',
            flex: 1,
          }}
        >
          <Typography variant="h5" component="div">
            Contact Information
          </Typography>
          <Typography variant="body1">
            <strong>Listing Status:</strong>{' '}
            {property?.realeflowData?.MLS_Curr_Status ||
              property?.realeflowData?.ListingStatus}
          </Typography>
          <Typography variant="body1">
            <strong>List Agent Name:</strong>{' '}
            {property?.realeflowData?.MLS_Curr_ListAgentName ||
              property?.realeflowData?.ListAgentName ||
              property?.realeflowData?.MLS_Prev_ListAgentName ||
              property?.realeflowData?.SoldAgentName}
          </Typography>
          <Typography variant="body1">
            <strong>List Agent Office:</strong>{' '}
            {property?.realeflowData?.MLS_Curr_ListAgentOffice ||
              property?.realeflowData?.ListAgentOffice ||
              property?.realeflowData?.MLS_Prev_ListAgentOffice ||
              property?.realeflowData?.SoldAgentOffice}
          </Typography>
          <Typography variant="body1">
            <strong>List Agent Phone:</strong>{' '}
            {property?.realeflowData?.MLS_Curr_ListAgentPhone ||
              property?.realeflowData?.ListAgentPhone ||
              property?.realeflowData?.MLS_Prev_ListAgentPhone ||
              property?.realeflowData?.SoldAgentPhone}
          </Typography>
          <Typography variant="body1">
            <strong>List Agent Email:</strong>{' '}
            {property?.realeflowData?.MLS_Curr_ListAgentEmail ||
              property?.realeflowData?.ListAgentEmail ||
              property?.realeflowData?.MLS_Prev_ListAgentEmail ||
              property?.realeflowData?.SoldAgentEmail}
          </Typography>
        </Paper>
      </Grid>
    )
  else return null
}
