import { useSubscription } from '@apollo/client'
import React, { useEffect } from 'react'
import { NEW_MESSAGE_SUBSCRIPTION } from '../housefolios-components/SupportChat/subscription'
import { Snackbar, Alert, IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

export default function useNewMessageSubscription(
  myId,
  withUser,
  showNotification = false,
) {
  const { loading, data, error } = useSubscription(
    NEW_MESSAGE_SUBSCRIPTION,
    {
      variables: {
        myId,
        withUser,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newMessage = subscriptionData.data.newMessage

        // Append the new message to the existing messages
        return Object.assign({}, prev, {
          messages: [...prev.messages, newMessage],
        })
      },
    },
  )
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (showNotification && data)
      enqueueSnackbar(
        `Chat Message Received from ${data?.newMessage?.sender}: ${data?.newMessage?.text}`,
        {
          variant: 'success',
          autoHideDuration: null,
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
          action: (key) => (
            <>
              <IconButton onClick={() => closeSnackbar(key)} size="large">
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            </>
          ),
        },
      )
  }, [data, showNotification])

  return {
    loading,
    data,
    error,
  }
}
