import { gql } from '@apollo/client'
import { AssumptionsFragments } from '@/housefolios-components/AssumptionsSettings/fragments'

export const GET_PORTFOLIOS = gql`
  query portfolios {
    portfolios {
      _id
      name
      strategy
      strategies
      description
      propertyCount
      defaultPortfolio
      assumptions {
        ...assumptions
      }
      organization {
        _id
        name
      }
    }
  }
  ${AssumptionsFragments.assumptions}
`

export const GET_ORGS_PORTFOLIO_SHARED_WITH = gql`
  query orgsPortfolioSharedWith($portfolioId: ID!) {
    orgsPortfolioSharedWith(portfolioId: $portfolioId) {
      _id
      marketPlaces {
        name
        url
        meetingURL
        contactEmail
        googleAnalytics
        marketplaceVideoURL
        preApprovedURL
        status
        featuredProperties
        propertyCount
        submittedPropertyCount
      }
      sharedPortfolios {
        _id
        name
        strategy
        strategies
        description
        propertyCount
        defaultPortfolio
        sharePermission
        assumptions {
          ...assumptions
        }
        organization {
          _id
          name
        }
      }
      sharedMarketplaces {
        name
        url
        meetingURL
        contactEmail
        googleAnalytics
        marketplaceVideoURL
        preApprovedURL
        status
        featuredProperties
        propertyCount
        sharePermission
        shareDate
      }
      sharedProperties
      meetingUrl
      phone
      email
      website
      legalDescription
      CMALegalDescription
      delinquent
      name
      address
      city
      state
      zip
      stripeCustomerID
      subscriptions
      logo {
        url
        url_small
      }
      createdAt
      createdBy {
        _id
      }
    }
  }
  ${AssumptionsFragments.assumptions}
`
