import React, { useEffect } from 'react'

import { PageTitle } from '@/layout-components'
import { ExampleWrapperSeamless } from '@/layout-components'

import remove from 'lodash/remove'
import concat from 'lodash/concat'
import every from 'lodash/every'
import includes from 'lodash/includes'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import {
  Button,
  Card,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ROITKCard from '@/housefolios-components/ROITK/ROITKComponents'

import withSession from '@/housefolios-components/Session/withSession'

function CoachingOfferPage(props) {
  const hiddenSmDown = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  )
  const hiddenSmUp = useMediaQuery((theme) =>
    theme.breakpoints.up('sm'),
  )
  function loadScript(url) {
    let script = document.createElement('script')
    script.type = 'text/javascript'

    if (script.readyState) {
      //IE
      script.onreadystatechange = function () {
        if (
          script.readyState == 'loaded' ||
          script.readyState == 'complete'
        ) {
          script.onreadystatechange = null
        }
      }
    } else {
      //Others
      script.onload = function () {}
    }

    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  useEffect(() => {
    loadScript('https://jaredgarfield.kartra.com/resources/js/popup')
  }, [])

  return (
    <>
      <PageTitle
        titleHeading="Coaching OFFER"
        titleDescription="An offer for Growth and Pro members of ROITK, Funding Faceoff, and NextLevel."
      ></PageTitle>
      <ExampleWrapperSeamless>
        {(props?.session?.me?.activeOrg?.member === 'roitk' ||
          props?.session?.me?.activeOrg?.member ===
            'fundingfaceoff' ||
          props?.session?.me?.activeOrg?.member === 'nextlevel' ||
          props?.session?.me?.activeOrg?.member === 'dbi') &&
        (includes(props.session.me.subscriptionPlan, 'growth') ||
          includes(props.session.me.subscriptionPlan, 'pro')) ? (
          <>
            {hiddenSmDown ? null : (
              <Grid container direction="row" spacing={4}>
                <Grid item xs={12} sm={7}>
                  <Grid container direction="column" spacing={4}>
                    <Grid item>
                      <ROITKCard />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Grid container direction="column" spacing={4}>
                    <Grid item xs={12}>
                      <Button
                        style={{
                          maxWidth: 500,
                          backgroundColor: '#368cff',
                          color: 'white',
                          height: '60px',
                          fontSize: 24,
                        }}
                        fullWidth
                        className="dg_popup js_kartra_trackable_object"
                        data-kt-type="checkout"
                        data-kt-value="933a8a6ed3889fe46bee6aa7788f6486"
                        data-kt-owner="DpwwOlp7"
                        rel="933a8a6ed3889fe46bee6aa7788f6486"
                        href="javascript:void(0);"
                      >
                        {/* <img src="//kartra.s3.amazonaws.com/internal/checkout_button_blue.png" /> */}
                        <FontAwesomeIcon
                          icon={['far', 'cart-shopping']}
                          className="mr-2"
                        />
                        <Typography
                          style={{
                            fontWeight: 600,
                            fontSize: 20,
                            marginLeft: 12,
                          }}
                        >
                          Sign Up Now
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item style={{ maxWidth: 530 }}>
                      {/* <Card className="card-box w-100"> */}
                      <div className="d-flex justify-content-center">
                        <iframe
                          src="https://app.kartra.com/video_front/index_pages/u4BpnOiMFIoQ/0/?autoplay=false&amp;mute_on_start=false&amp;show_controls=true"
                          /*width="640"*/ width="100%"
                          /*height="564"*/ height="300"
                          frameborder="0"
                          allow="autoplay; fullscreen"
                          allowfullscreen
                        />
                      </div>
                      {/* </Card> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {hiddenSmUp ? null : (
              <Grid container direction="row" spacing={4}>
                <Grid item xs={12}>
                  <Grid container direction="column" spacing={4}>
                    <Grid item xs={12}>
                      <Button
                        style={{
                          maxWidth: 500,
                          backgroundColor: '#368cff',
                          color: 'white',
                          height: '60px',
                          fontSize: 24,
                        }}
                        fullWidth
                        className="dg_popup js_kartra_trackable_object"
                        data-kt-type="checkout"
                        data-kt-value="933a8a6ed3889fe46bee6aa7788f6486"
                        data-kt-owner="DpwwOlp7"
                        rel="933a8a6ed3889fe46bee6aa7788f6486"
                        href="javascript:void(0);"
                      >
                        {/* <img src="//kartra.s3.amazonaws.com/internal/checkout_button_blue.png" /> */}
                        <FontAwesomeIcon
                          icon={['far', 'cart-shopping']}
                          className="mr-2"
                        />
                        <Typography
                          style={{
                            fontWeight: 600,
                            fontSize: 20,
                            marginLeft: 12,
                          }}
                        >
                          Sign Up Now
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item style={{ maxWidth: 530 }}>
                      {/* <Card className="card-box w-100"> */}
                      <div className="d-flex justify-content-center">
                        <iframe
                          src="https://app.kartra.com/video_front/index_pages/u4BpnOiMFIoQ/0/?autoplay=false&amp;mute_on_start=false&amp;show_controls=true"
                          /*width="640"*/ width="100%"
                          /*height="564"*/ height="300"
                          frameborder="0"
                          allow="autoplay; fullscreen"
                          allowfullscreen
                        />
                      </div>
                      {/* </Card> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Grid container direction="column" spacing={4}>
                    <Grid item>
                      <ROITKCard />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Typography>
            This offer is not avaliable to this organization.
          </Typography>
        )}
      </ExampleWrapperSeamless>
    </>
  )
}

export default withSession(CoachingOfferPage)
