/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: David Helmick
 * @ Modified time: 2022-06-24 20:07:35
 * @ Description: Dialog to delete a property or selected properties
 */

import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material'

import map from 'lodash/map'

// import DeleteIcon from '@mui/icons-material/Delete';
// import { useMutation } from '@apollo/client';
// import { DELETE_PORTFOLIO } from '../mutations';
// import { GET_PORTFOLIOS } from '@/housefolios-pages/Launchpad/queries';
// import { GET_ME } from '@/housefolios-components/Session/queries';
import { enqueueSnackbar } from 'notistack'
import { DELETE_PROPERTIES } from '../mutation'
import { useMutation } from '@apollo/client'
import { GET_PROPERTIES, GET_TRASH } from '../queries'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { GET_PROPERTIES_QUERY_LIMIT } from '@/utils/pagination'

function DeletePropertiesComponent(props) {
  const { bulkActionsNotifications, rightClickMenu, sidebarMenu } =
    props
  const [openDeletePortfolio, setDeletePortfolio] = useState(false)
  const [loader, setLoader] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const handleOpenDeleteProperties = () => {
    setDeletePortfolio(true)
    setConfirm(false)
  }
  const handleCloseDeleteProperties = () => {
    setDeletePortfolio(false)
    setLoader(false)
  }

  const [deleteProperties] = useMutation(DELETE_PROPERTIES)

  const handleDelete = () => {
    //if (props.gotToPortfolio) localStorage.setItem('portfolio', props.portfolioId)

    setLoader(true)
    deleteProperties({
      awaitRefetchQueries: true,
      variables: { ids: props.selected },
      refetchQueries: [
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: [props.portfolioId],
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PROPERTIES,
          variables: {
            portfolioId: map(
              props.portfolios,
              (portfolio) => portfolio._id,
            ),
            limit: GET_PROPERTIES_QUERY_LIMIT,
          },
        },
        {
          query: GET_PORTFOLIOS,
        },
        {
          query: GET_ME,
        },
        {
          query: GET_TRASH,
        },
      ],
    })
      .then(() => {
        enqueueSnackbar(`Selected Properties Deleted`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        handleCloseDeleteProperties()
        if (props.setSelected) props.setSelected([])
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to Delete Selected Properties`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
        handleCloseDeleteProperties()
      })
  }

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  return (
    <>
      {bulkActionsNotifications && (
        <Tooltip title="Bulk Delete">
          <IconButton
            onClick={handleOpenDeleteProperties}
            size="large"
          >
            <FontAwesomeIcon
              icon={['far', 'trash-can']}
              style={{ color: 'white' }}
            />
          </IconButton>
        </Tooltip>
      )}
      {rightClickMenu && (
        <ListItem button onClick={handleOpenDeleteProperties}>
          <div className="nav-link-icon">
            <FontAwesomeIcon icon={['far', 'trash']} />
          </div>
          <span>Delete Property</span>
        </ListItem>
      )}
      {sidebarMenu && (
        <ListItem
          button
          onClick={handleOpenDeleteProperties}
          style={{ color: 'red' }}
        >
          <div className="nav-link-icon">
            <FontAwesomeIcon icon={['far', 'trash']} />
          </div>
          <Typography>Empty Trash</Typography>
        </ListItem>
      )}
      <Dialog
        open={openDeletePortfolio}
        onClose={handleCloseDeleteProperties}
        classes={{ paper: 'shadow-lg rounded' }}
        onKeyDown={stopPropagationForTab}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">Deleting Property Data</div>
          }
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                <FontAwesomeIcon
                  icon={['far', 'trash-can']}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              {sidebarMenu
                ? 'Are you sure you want to empty the trash'
                : 'Delete Properties'}
              ?
            </h4>
            <p className="mb-0 font-size-lg text-muted">
              <FormControl component="fieldset" className="pr-4">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirm}
                        onChange={() => setConfirm(!confirm)}
                      />
                    }
                    label={
                      sidebarMenu
                        ? 'All data will be deleted.  This action cannot be undone'
                        : "Delete these properties. This action can't be undone"
                    }
                  />
                </FormGroup>
              </FormControl>
            </p>
            <div className="pt-4">
              <Button
                variant="text"
                onClick={handleCloseDeleteProperties}
              >
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: '#f83145', color: 'white' }}
                onClick={handleDelete}
                disabled={!confirm}
                href={props.gotToPortfolio ? '/my-properties' : null}
              >
                <span className="btn-wrapper--label">Delete</span>
              </Button>
            </div>
          </div>
        </BlockUi>
      </Dialog>
    </>
  )
}

export default DeletePropertiesComponent
