/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:20:09
 * @ Modified by: David Helmick
 * @ Modified time: 2024-07-31 17:06:09
 * @ Description: Scrolls to top of page
 */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return children || null;
};

export default ScrollToTop
