import React from 'react'
import { useTokenExpiryChecker } from '@/hooks/useTokenExpiryChecker'
import SessionDialog from '@/housefolios-components/Dialogs/Session'

function RenewSession() {
  const { renewSession } = useTokenExpiryChecker()
  return renewSession && <SessionDialog />
}

export default RenewSession
