import React, { useEffect, useState } from 'react'

import clsx from 'clsx'

import remove from 'lodash/remove'
import map from 'lodash/map'
import slice from 'lodash/slice'
import orderBy from 'lodash/orderBy'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'

import { connect } from 'react-redux'

import {
  Avatar,
  Backdrop,
  Badge,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'

import {
  setSidebarToggleMobile,
  setNotificationsDrawerToggle,
} from '../../reducers/ThemeOptions'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import HeaderDots from '@/layout-components/HeaderDots'
import HeaderUserbox from '@/layout-components/HeaderUserbox'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import IconButton from '@mui/material/IconButton'
import withSession from '@/housefolios-components/Session/withSession'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GET_NOTIFICATIONS } from '@/housefolios-components/Notifications/queries'
import { Query } from '@apollo/client/react/components'
import { useMutation } from '@apollo/client'
import { MARK_OLD } from '@/housefolios-components/Notifications/mutations'
import ManageButton from '@/housefolios-components/Notifications/ManageButton'
import Realeflow from '@/housefolios-components/Realeflow'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { GET_AFFILIATES } from '@/housefolios-components/CreateAccount/CreateAccountCard/queries'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_WORKFLOWS } from '@/housefolios-components/Properties/queries'
import { GET_CARDS } from '@/housefolios-components/Properties/queries'
import { useNavigate } from 'react-router-dom'

// const drawerWidth = 540;
// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//   },
// }));

const Header = (props) => {
  // const classes = useStyles()
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile,
  } = props

  const hostname = import.meta.env.VITE_HOSTNAME

  const [currentMember, setCurrentMember] = useState(
    props?.session?.me?.activeOrg?.member || '',
  )

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const changeOrgMember = (newMember) => {
    updateOrganization({
      variables: {
        id: props.session.me.activeOrg._id,
        organizationInput: {
          member: newMember || '',
        },
      },
      refetchQueries: [
        { query: GET_ME },
        { query: GET_WORKFLOWS },
        { query: GET_CARDS },
      ],
    })
  }

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile)
  }

  let navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  const today = new Date()

  const [markOld] = useMutation(MARK_OLD)

  const { notificationsDrawerToggle, setNotificationsDrawerToggle } =
    props

  const toggleNotificationsDrawer = () => {
    setNotificationsDrawerToggle(!notificationsDrawerToggle)
  }

  return (
    <Query query={GET_NOTIFICATIONS} pollInterval={5 * 60 * 1000}>
      {({ loading, error, refetch, data }) => {
        if (loading)
          return (
            <Backdrop
              open={loading}
              style={{ color: '#fff', zIndex: 2000 }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )
        if (error) return `Error! ${error.message}`

        let notifications = [...data.notifications] || []

        let oldNotifications = remove(notifications, ['old', true])

        remove(notifications, ['old', true])

        return (
          <>
            <div
              className={clsx('app-header', {
                'app-header--shadow': headerShadow,
                'app-header--opacity-bg': headerBgTransparent,
              })}
              style={{ background: '#fff' }}
            >
              <div className="app-header--pane">
                <button
                  className={clsx(
                    'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
                    { 'is-active': sidebarToggleMobile },
                  )}
                  onClick={toggleSidebarMobile}
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
                {!props.hideBackButton && (
                  <Tooltip title="Previous Page">
                    <IconButton onClick={goBack} size="large">
                      <KeyboardBackspaceOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <div className="app-header--pane">
                {props.session.me.subscriptionPlan === 'trial' && (
                  <Button
                    variant="contained"
                    margin="dense"
                    color="warning"
                    style={{
                      marginRight: 20,
                      borderRadius: 10,
                      backgroundColor: '#368CFF',
                      color: 'white',
                    }}
                    onClick={props.toggleSubscribe}
                  >
                    <Typography>Upgrade</Typography>
                  </Button>
                )}
                {(hostname === 'localhost' ||
                  hostname === 'dev-admin.housefolios.com' ||
                  props.session.me.housefoliosAdmin) && (
                  <Query query={GET_AFFILIATES}>
                    {({ loading, error, data }) => {
                      if (loading) return 'Loading'
                      if (error) return `Error! ${error.message}`
                      const { affiliates } = data
                      return (
                        <Select
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          className="m-2"
                          displayEmpty
                          //id=""
                          select
                          // label="Current Affiliate"
                          value={currentMember}
                          onChange={(event) => {
                            setCurrentMember(event.target.value)
                            changeOrgMember(event.target.value)
                          }}
                          style={{ marginRight: 10 }}
                        >
                          <MenuItem
                            key={'Not an Affiliate Member'}
                            value={''}
                          >
                            Not an Affiliate Member
                          </MenuItem>
                          {map(affiliates, (affiliate) => (
                            <MenuItem
                              key={affiliate?.companyName}
                              value={affiliate?.memberValue}
                            >
                              {affiliate?.companyName}
                            </MenuItem>
                          ))}
                        </Select>
                      )
                    }}
                  </Query>
                )}
                {props?.session?.me?.activeOrg?.member !== 'RWN' && (
                  <HeaderDots {...props} />
                )}
                {props.session.me.activeOrg.isRealeflow && (
                  <Realeflow />
                )}
                <IconButton
                  onClick={toggleNotificationsDrawer}
                  // onClick={isEmpty(notifications) ? () => props.history.push('/notifications') : handleOpenNotifications}
                  // onClick={isEmpty(notifications) ? () => props.history.push('/notifications') : handleOpenMenu}
                  className={
                    hostname === 'localhost' ||
                    hostname === 'dev-admin.housefolios.com'
                      ? 'mr-4'
                      : ''
                  }
                  size="large"
                >
                  <Badge
                    badgeContent={notifications.length}
                    color="primary"
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'bell']}
                      className="font-size-lg"
                    />
                  </Badge>
                </IconButton>
                <HeaderUserbox {...props} />
                {/* <HeaderDrawer /> */}
              </div>

              {/* <div className="app-drawer-content">
            <Tooltip arrow title="Close drawer" placement="left">
              <Button
                size="small"
                onClick={toggleHeaderDrawer}
                className="close-drawer-btn bg-white p-0 d-40"
                id="CloseDrawerTooltip">
                <div
                  className={clsx('navbar-toggler hamburger hamburger--elastic', {
                    'is-active': headerDrawerToggle
                  })}>
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </div>
              </Button>
            </Tooltip>
            <div className="vh-100 shadow-overflow">
              <PerfectScrollbar>
                <Typography>Test</Typography>
              </PerfectScrollbar>
            </div>
          </div> */}

              {/* <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            {map(slice(orderBy(notifications, 'actionDateTime', 'desc'), 0, 3), notification => {
              const time = new Date(notification.actionDateTime)
              const diff = today.getTime() - time.getTime()
              let timeLeftText = 'now'
              let timeLeft = diff / 1000
              if (timeLeft > 1) {
                timeLeftText = `${Math.floor(timeLeft)} seconds`
                timeLeft /= 60
                if (timeLeft > 1) {
                  timeLeftText = `${Math.floor(timeLeft)} minutes`
                  timeLeft /= 60
                  if (timeLeft > 1) {
                    timeLeftText = `${Math.floor(timeLeft)} hours`
                    timeLeft /= 24
                    if (timeLeft > 1) {
                      timeLeftText = `${Math.floor(timeLeft)} days`
                    }
                  }
                }
              }
              return (
                <MenuItem onClick={() => { props.history.push('/notifications'); handleCloseMenu() }}>
                  <ListItemText
                    primary={notification.action}
                    secondary={timeLeftText}
                  />
                  {!notification.system && (
                    <ListItemSecondaryAction>
                      <IconButton onClick={(event) =>  { markOld({ variables: { id: notification._id }}); event.stopPropagation(); }} edge="end">
                        <Check />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </MenuItem>
            )})}
            {isEmpty(notifications) && (
              <ListItem>
                <div className="text-center">
                  <div className="avatar-icon-wrapper rounded-circle m-0">
                    <div style={{ backgroundColor: '#cfe1fa', color: '#008aff' }} className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-80">
                      <FontAwesomeIcon
                        icon={['fas', 'bell']}
                        className="d-flex align-self-center display-4"
                      />
                    </div>
                  </div>
                  <p className="font-weight-bold mt-4">No New Notifications</p>
                </div>
              </ListItem>
            )}
            <MenuItem onClick={() => { props.history.push('/notifications'); handleCloseMenu() }}>See All Notifications</MenuItem>
          </Menu> */}
            </div>

            <div className="notification-drawer-content">
              <div>
                <PerfectScrollbar>
                  <div className="p-4">
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={2}>
                        <Tooltip
                          arrow
                          title="Close drawer"
                          placement="left"
                        >
                          <Button
                            size="small"
                            onClick={toggleNotificationsDrawer}
                            className="close-notification-drawer-btn bg-white p-0 d-40"
                            id="CloseDrawerTooltip"
                          >
                            <div
                              className={clsx(
                                'navbar-toggler hamburger hamburger--elastic',
                                {
                                  'is-active':
                                    notificationsDrawerToggle,
                                },
                              )}
                            >
                              <span className="hamburger-box">
                                <span className="hamburger-inner" />
                              </span>
                            </div>
                          </Button>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="h6"
                          style={{ marginLeft: 5 }}
                        >
                          Notifications
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <Divider />
                  <Grid
                    container
                    direction="column"
                    style={{ padding: 15 }}
                  >
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item style={{ marginTop: 10 }}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Typography variant="subtitle1">
                                New
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="text"
                                onClick={() => {
                                  navigate('/notifications')
                                }}
                              >
                                See All
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <List>
                            {map(
                              slice(
                                orderBy(
                                  notifications,
                                  'actionDateTime',
                                  'desc',
                                ),
                              ),
                              (notification, index) => {
                                const time = new Date(
                                  notification.actionDateTime,
                                )
                                const diff =
                                  today.getTime() - time.getTime()
                                let timeLeftText = 'now'
                                let timeLeft = diff / 1000
                                if (timeLeft > 1) {
                                  timeLeftText = `${Math.floor(
                                    timeLeft,
                                  )} seconds`
                                  timeLeft /= 60
                                  if (timeLeft > 1) {
                                    timeLeftText = `${Math.floor(
                                      timeLeft,
                                    )} minutes`
                                    timeLeft /= 60
                                    if (timeLeft > 1) {
                                      timeLeftText = `${Math.floor(
                                        timeLeft,
                                      )} hours`
                                      timeLeft /= 24
                                      if (timeLeft > 1) {
                                        timeLeftText = `${Math.floor(
                                          timeLeft,
                                        )} days`
                                      }
                                    }
                                  }
                                }
                                return (
                                  <>
                                    <ListItem
                                      key={`new ${index}`}
                                      onClick={(event) => {
                                        navigate('/notifications')
                                        markOld({
                                          variables: {
                                            id: notification._id,
                                          },
                                        })
                                        event.stopPropagation()
                                      }}
                                    >
                                      {/*
                                       */}
                                      <ListItemText
                                        disableTypography={true}
                                        primary={
                                          <Grid container>
                                            <Grid item xs={12}>
                                              <Typography
                                                variant="subtitle1"
                                                style={{
                                                  whiteSpace:
                                                    'normal',
                                                }}
                                              >
                                                {notification.action}{' '}
                                                {notification.actionLink ? (
                                                  <a
                                                    href={
                                                      notification.actionLink
                                                    }
                                                    target={
                                                      notification.actionLinkTarget ||
                                                      '_blank'
                                                    }
                                                    rel="noreferrer"
                                                    style={{
                                                      color:
                                                        '#368CFF',
                                                      textDecoration:
                                                        'underline',
                                                    }}
                                                  >
                                                    here
                                                  </a>
                                                ) : null}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Typography
                                                variant="subtitle2"
                                                color="primary"
                                                style={{
                                                  whiteSpace:
                                                    'normal',
                                                }}
                                              >
                                                {timeLeftText}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        }
                                        secondary={
                                          <Grid container>
                                            <Typography
                                              style={{ flex: 1 }}
                                            />
                                            <ManageButton
                                              manageNotificationsButton={
                                                true
                                              }
                                              section={notification}
                                            />
                                          </Grid>
                                        }
                                      />
                                      <ListItemSecondaryAction>
                                        <IconButton
                                          onClick={(event) => {
                                            markOld({
                                              variables: {
                                                id: notification._id,
                                              },
                                            })
                                            event.stopPropagation()
                                          }}
                                          edge="end"
                                          size="large"
                                        >
                                          <FiberManualRecordIcon color="primary" />
                                        </IconButton>
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                  </>
                                )
                              },
                            )}
                            {isEmpty(notifications) && (
                              <ListItem
                                key="no new"
                                style={{ textAlign: 'center' }}
                              >
                                <ListItemText>
                                  No New Notifications
                                </ListItemText>
                              </ListItem>
                            )}
                            {/* <MenuItem onClick={() => { props.history.push('/notifications'); handleCloseMenu() }}>See All Notifications</MenuItem> */}
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="subtitle1">
                            Older
                          </Typography>
                        </Grid>
                        <Grid item>
                          <List>
                            {map(
                              slice(
                                orderBy(
                                  oldNotifications,
                                  'actionDateTime',
                                  'desc',
                                ),
                              ),
                              (notification, index) => {
                                const time = new Date(
                                  notification.actionDateTime,
                                )
                                const diff =
                                  today.getTime() - time.getTime()
                                let timeLeftText = 'now'
                                let timeLeft = diff / 1000
                                if (timeLeft > 1) {
                                  timeLeftText = `${Math.floor(
                                    timeLeft,
                                  )} seconds`
                                  timeLeft /= 60
                                  if (timeLeft > 1) {
                                    timeLeftText = `${Math.floor(
                                      timeLeft,
                                    )} minutes`
                                    timeLeft /= 60
                                    if (timeLeft > 1) {
                                      timeLeftText = `${Math.floor(
                                        timeLeft,
                                      )} hours`
                                      timeLeft /= 24
                                      if (timeLeft > 1) {
                                        timeLeftText = `${Math.floor(
                                          timeLeft,
                                        )} days`
                                      }
                                    }
                                  }
                                }
                                return (
                                  <ListItem
                                    key={`old ${index}`}
                                    onClick={() => {
                                      navigate('/notifications')
                                    }}
                                  >
                                    <ListItemAvatar>
                                      <Avatar>
                                        <FontAwesomeIcon
                                          icon={['fas', 'bell']}
                                          className="d-flex align-self-center"
                                        />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      disableTypography={true}
                                      primary={
                                        <Grid container>
                                          <Grid item xs={12}>
                                            <Typography
                                              variant="subtitle1"
                                              style={{
                                                whiteSpace: 'normal',
                                              }}
                                            >
                                              {notification.action}
                                              {notification.actionLink ? (
                                                <a
                                                  href={
                                                    notification.actionLink
                                                  }
                                                  target={
                                                    notification.actionLinkTarget ||
                                                    '_blank'
                                                  }
                                                  rel="noreferrer"
                                                  style={{
                                                    color: '#368CFF',
                                                    textDecoration:
                                                      'underline',
                                                  }}
                                                >
                                                  here
                                                </a>
                                              ) : null}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Typography
                                              variant="subtitle2"
                                              color="primary"
                                              style={{
                                                whiteSpace: 'normal',
                                              }}
                                            >
                                              {timeLeftText}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      }
                                      secondary={
                                        <Grid container>
                                          <Typography
                                            style={{ flex: 1 }}
                                          />
                                          <ManageButton
                                            manageNotificationsButton={
                                              true
                                            }
                                            section={notification}
                                          />
                                        </Grid>
                                      }
                                    />
                                    <ListItemSecondaryAction>
                                      {/* <IconButton onClick={(event) =>  { markOld({ variables: { id: notification._id }}); event.stopPropagation(); }} edge="end"> */}
                                      {/* <FiberManualRecordIcon color='primary' /> */}
                                      {/* </IconButton> */}
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                )
                              },
                            )}
                            {isEmpty(oldNotifications) && (
                              <ListItem
                                key="no old"
                                style={{ textAlign: 'center' }}
                              >
                                <ListItemText>
                                  No Old Notifications
                                </ListItemText>
                              </ListItem>
                            )}
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </PerfectScrollbar>
              </div>
            </div>

            <div
              onClick={toggleNotificationsDrawer}
              className={clsx('app-drawer-overlay', {
                'is-active': notificationsDrawerToggle,
              })}
            />
          </>
        )
      }}
    </Query>
  )
}

const mapStateToProps = (state) => ({
  headerShadow: state.DeprecatedThemeOptions.headerShadow,
  headerBgTransparent:
    state.DeprecatedThemeOptions.headerBgTransparent,
  sidebarToggleMobile:
    state.DeprecatedThemeOptions.sidebarToggleMobile,
  notificationsDrawerToggle:
    state.DeprecatedThemeOptions.notificationsDrawerToggle,
})

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) =>
    dispatch(setSidebarToggleMobile(enable)),
  setNotificationsDrawerToggle: (enable) =>
    dispatch(setNotificationsDrawerToggle(enable)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSession(Header))
