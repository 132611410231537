/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-12-23 13:03:23
 * @ Description: General options menu in title bar
 */

import React, { useState } from 'react'

import map from 'lodash/map'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'

import {
  Button,
  Collapse,
  Dialog,
  List,
  ListItem,
  Menu,
  Tooltip,
  Typography,
} from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Query } from '@apollo/client/react/components'
import { GET_TRAINING_BUTTONS } from '@/housefolios-components/TrainingVideos/queries'
import withSession from '@/housefolios-components/Session/withSession'
import { useNavigate } from 'react-router-dom'
import VideoDialog from '@/housefolios-components/Dialogs/VideoDialog/'
import ContactDialog from '@/housefolios-components/Dialogs/ContactSupport'
import useAffiliate from '@/hooks/useAffiliate'

// import TempChat from '../../TempChat';

function HelpButton(props) {
  const [anchorElMoreOptionsMenu, setAnchorElMoreOptionsMenu] =
    useState(null)
  const handleClickMoreOptionsMenu = (event) => {
    setAnchorElMoreOptionsMenu(event.currentTarget)
  }
  const handleCloseMoreOptionsMenu = () => {
    setAnchorElMoreOptionsMenu(null)
  }
  const [openMoreOptionsMenu, setOpenMoreOptionsMenu] =
    React.useState(true)
  const handleMoreOptionsClick = () => {
    setOpenMoreOptionsMenu(!openMoreOptionsMenu)
  }
  const [openFAQButtons, setOpenFAQButtons] = React.useState(false)
  const handleFAQButtonsClick = () => {
    setOpenFAQButtons(!openFAQButtons)
  }
  const [openTourButtons, setOpenTourButtons] = React.useState(false)
  const handleTourButtonsClick = () => {
    setOpenTourButtons(!openTourButtons)
  }
  const [openVideoButtons, setOpenVideoButtons] =
    React.useState(false)
  const handleVideoButtonsClick = () => {
    setOpenVideoButtons(!openVideoButtons)
  }

  const [showVideo, setShowVideo] = useState('')
  const [openContact, setOpenContact] = useState(false)

  const [{ affiliate, urlAffiliate }] = useAffiliate({
    memberValue: props?.session?.me?.activeOrg?.member,
  })

  let navigate = useNavigate()

  const closeVideo = () => {
    setShowVideo('')
  }

  const [openDialog, setOpenDialog] = useState(false)
  const handleClickOpen = () => {
    setOpenDialog(true) // Open the dialog
  }

  const handleClose = () => {
    setOpenDialog(false) // Close the dialog
  }

  return (
    <>
      <Tooltip title="Helpful Tips">
        <Button
          variant="contained"
          size="small"
          className="d-40 btn-warning"
          onClick={handleClickMoreOptionsMenu}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon
              icon={[
                'far',
                props.page === 'Marketplace'
                  ? 'circle-question'
                  : 'ellipsis-vertical',
              ]}
              className="font-size-lg"
            />
          </span>
        </Button>
      </Tooltip>

      <Menu
        anchorEl={anchorElMoreOptionsMenu}
        keepMounted
        open={Boolean(anchorElMoreOptionsMenu)}
        onClose={handleCloseMoreOptionsMenu}
        classes={{ list: 'p-0' }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="dropdown-menu-xl">
          <List
            component="div"
            className="nav-list-square nav-neutral-primary"
          >
            <ListItem button onClick={handleMoreOptionsClick}>
              <div className="nav-link-icon">
                <FontAwesomeIcon
                  icon={['far', 'circle-question']}
                  style={{ color: '#f4772e' }}
                />
              </div>
              <span className="text-warning">Need Some Help</span>
              <Typography style={{ flex: 1 }} />
              {openMoreOptionsMenu ? (
                <ExpandLess style={{ color: '#f4782d' }} />
              ) : (
                <ExpandMore style={{ color: '#f4782d' }} />
              )}
            </ListItem>
            <Collapse
              in={openMoreOptionsMenu}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {/* <ListItem button style={{ paddingLeft: 30 }}>
                  <div className="nav-link-icon">
                    <FontAwesomeIcon icon={['far', 'circle-play']} />
                  </div>
                  <span>Watch Video</span>
                </ListItem>
                <ListItem button style={{ paddingLeft: 30 }}>
                  <div className="nav-link-icon">
                    <FontAwesomeIcon icon={['far', 'square-question']} />
                  </div>
                  <span>FAQs</span>
                </ListItem>
                <ListItem button style={{ paddingLeft: 30 }}>
                  <div className="nav-link-icon">
                    <FontAwesomeIcon icon={['far', 'compass']} />
                  </div>
                  <span>Take a tour</span>
                </ListItem> */}
                {!urlAffiliate && (
                  <ListItem
                    key="chat"
                    button
                    style={{ paddingLeft: 30 }}
                    className="nav-link-simple"
                    onClick={() => navigate('/support-chat')}
                  >
                    <div className="nav-link-icon">
                      <FontAwesomeIcon
                        icon={['far', 'message-lines']}
                      />
                    </div>
                    <span>Live Chat</span>
                  </ListItem>
                )}
                {urlAffiliate && (
                  <ListItem
                    key="contactsupport"
                    button
                    style={{ paddingLeft: 30 }}
                    className="nav-link-simple"
                    onClick={() => setOpenContact(true)}
                  >
                    <div className="nav-link-icon">
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={['far', 'phone']}
                        size="1x"
                        fixedWidth
                      />
                    </div>
                    <span>Contact Support</span>
                  </ListItem>
                )}
                <Query
                  query={GET_TRAINING_BUTTONS}
                  variables={{
                    page: props.page,
                    member: props?.session?.me?.activeOrg?.member,
                  }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return 'loading'
                    const { trainingButtons = [] } = data
                    const faqButtons = filter(trainingButtons, [
                      'type',
                      'faq',
                    ])
                    const tourButtons = filter(trainingButtons, [
                      'type',
                      'tour',
                    ])
                    const videoButtons = filter(trainingButtons, [
                      'type',
                      'video',
                    ])
                    return (
                      <>
                        {!isEmpty(faqButtons) && (
                          <>
                            <ListItem
                              key="help"
                              button
                              onClick={handleFAQButtonsClick}
                            >
                              <div className="nav-link-icon">
                                <FontAwesomeIcon
                                  icon={['far', 'circle-question']}
                                />
                              </div>
                              <span>FAQ</span>
                              <Typography style={{ flex: 1 }} />
                              {openFAQButtons ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItem>
                            <Collapse
                              in={openFAQButtons}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {map(faqButtons, (trainingButton) => (
                                  <ListItem
                                    key={trainingButton._id}
                                    button
                                    component={
                                      trainingButton.link ? 'a' : null
                                    }
                                    href={trainingButton.link || null}
                                    target={
                                      trainingButton.linkTarget ||
                                      null
                                    }
                                    onClick={
                                      trainingButton.popup &&
                                      trainingButton.url
                                        ? () =>
                                            setShowVideo(
                                              trainingButton.url,
                                            )
                                        : null
                                    }
                                    style={{ paddingLeft: 30 }}
                                    className="nav-link-simple"
                                  >
                                    <div className="nav-link-icon">
                                      <FontAwesomeIcon
                                        icon={trainingButton.icon}
                                      />
                                    </div>
                                    <span>{trainingButton.text}</span>
                                  </ListItem>
                                ))}
                              </List>
                            </Collapse>
                          </>
                        )}
                        {!isEmpty(tourButtons) && (
                          <>
                            <ListItem
                              key="help"
                              button
                              onClick={handleTourButtonsClick}
                            >
                              <div className="nav-link-icon">
                                <FontAwesomeIcon
                                  icon={['far', 'circle-question']}
                                />
                              </div>
                              <span>Tour</span>
                              <Typography style={{ flex: 1 }} />
                              {openTourButtons ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItem>
                            <Collapse
                              in={openTourButtons}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {map(
                                  tourButtons,
                                  (trainingButton) => (
                                    <ListItem
                                      key={trainingButton._id}
                                      button
                                      component={
                                        trainingButton.link
                                          ? 'a'
                                          : null
                                      }
                                      href={
                                        trainingButton.link || null
                                      }
                                      target={
                                        trainingButton.linkTarget ||
                                        null
                                      }
                                      onClick={
                                        trainingButton.popup &&
                                        trainingButton.url
                                          ? () =>
                                              setShowVideo(
                                                trainingButton.url,
                                              )
                                          : null
                                      }
                                      style={{ paddingLeft: 30 }}
                                      className="nav-link-simple"
                                    >
                                      <div className="nav-link-icon">
                                        <FontAwesomeIcon
                                          icon={trainingButton.icon}
                                        />
                                      </div>
                                      <span>
                                        {trainingButton.text}
                                      </span>
                                    </ListItem>
                                  ),
                                )}
                              </List>
                            </Collapse>
                          </>
                        )}
                        {!isEmpty(videoButtons) && (
                          <>
                            <ListItem
                              key="help"
                              button
                              onClick={handleVideoButtonsClick}
                            >
                              <div className="nav-link-icon">
                                <FontAwesomeIcon
                                  icon={['far', 'circle-question']}
                                />
                              </div>
                              <span>Video</span>
                              <Typography style={{ flex: 1 }} />
                              {openVideoButtons ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItem>
                            <Collapse
                              in={openVideoButtons}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {map(
                                  videoButtons,
                                  (trainingButton) => (
                                    <ListItem
                                      key={trainingButton._id}
                                      button
                                      component={
                                        trainingButton.link
                                          ? 'a'
                                          : null
                                      }
                                      href={
                                        trainingButton.link || null
                                      }
                                      target={
                                        trainingButton.linkTarget ||
                                        null
                                      }
                                      onClick={
                                        trainingButton.popup &&
                                        trainingButton.url
                                          ? () =>
                                              setShowVideo(
                                                trainingButton.url,
                                              )
                                          : null
                                      }
                                      style={{ paddingLeft: 30 }}
                                      className="nav-link-simple"
                                    >
                                      <div className="nav-link-icon">
                                        <FontAwesomeIcon
                                          icon={trainingButton.icon}
                                        />
                                      </div>
                                      <span>
                                        {trainingButton.text}
                                      </span>
                                    </ListItem>
                                  ),
                                )}
                              </List>
                            </Collapse>
                          </>
                        )}
                      </>
                    )
                  }}
                </Query>
              </List>
            </Collapse>
          </List>
        </div>
      </Menu>

      <VideoDialog
        open={showVideo}
        onClose={closeVideo}
        videoSrc={showVideo}
      />
      <ContactDialog
        open={openContact}
        handleClose={() => setOpenContact(false)}
        affiliate={affiliate?.memberValue}
      />

      {/* <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={handleCloseHelp}>
        <div className="dropdown-menu-xl overflow-hidden p-0">
          <div className="grid-menu grid-menu-2col">
            <Grid container spacing={0}>
              {map(inputFields, section => (
                <Grid item sm={6}>
                  <Button
                    variant="text"
                    className={section.className || "btn-outline-secondary border-0 w-100 d-block btn-transition-none rounded-0 shadow-none py-4 px-1"}>
                    <FontAwesomeIcon
                      icon={[`${section.icon1}`, `${section.icon2}`]}
                      className="h2 d-block mb-2 mx-auto mt-1 text-warning"
                    />
                    <div className="font-weight-bold text-black">{section.title}</div>
                    <div className="font-size-md mb-1 text-black-50">
                      {section.subTitle}
                    </div>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </Menu> */}
    </>
  )
}

export default withSession(HelpButton)
