/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-16 11:36:56
 * @ Modified by: Daniel Clark
 * @ Modified time: 2024-09-10 11:38:03
 * @ Description: Component to handle signins from external sites
 */

import React, { Component } from 'react'
import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

import includes from 'lodash/includes'
import map from 'lodash/map'

import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'

const EXTERNAL_SIGN_IN = gql`
  mutation externalSignIn(
    $identifier: String!
    $userInput: userInput!
    $memberField: String
    $memberRole: String
    $subscriptionType: String
  ) {
    externalSignIn(
      identifier: $identifier
      userInput: $userInput
      memberField: $memberField
      memberRole: $memberRole
      subscriptionType: $subscriptionType
    ) {
      token
      reset
    }
  }
`

const hostName = import.meta.env.VITE_HOSTNAME

class ExternalSignIn extends Component {
  componentDidMount = () => {
    let referrer =
      import.meta.env.VITE_HOSTNAME === 'app.housefolios.com'
        ? this.props.referrer
        : this.props.testReferrer
    let windowUrl = window.location.search
    const params = new URLSearchParams(windowUrl)
    let firstname = params.get('first_name')
    let lastname = params.get('last_name')
    let email = params.get('email')
    let identifier = params.get('token')
    let member = params.get('member')
    let subscriptionType = params.get('subscription_type')
    // console.log(
    //   'email',
    //   email,
    //   'first_name',
    //   firstname,
    //   'last_name',
    //   lastname,
    //   'identifier',
    //   identifier,
    // )
    // let password = (
    //   Math.random()
    //     .toString(36)
    //     .replace(/[^a-z]+/g, '') + '00000000000000000'
    // ).substr(0, 15)
    let password = email + identifier
    //if (includes(referrer, document.referrer) || includes(map(referrer, ref => ref+'/'), document.referrer)) {
    if (!identifier) window.location.replace(document.referrer)
    else {
      this.props
        .externalSignIn({
          variables: {
            userInput: {
              profile: {
                firstname,
                lastname,
              },
              email,
              password,
            },
            identifier,
            memberField: member,
            subscriptionType,
          },
        })
        .then(async ({ data }) => {
          if (data.externalSignIn.reset) {
            this.props.navigate(
              `/resetpassword/${data.externalSignIn.token}`,
            )
          } else {
            localStorage.setItem('token', data.externalSignIn.token)
            localStorage.setItem('trialBanner', 'true')
            if (data && data.externalSignIn) {
              if (data.externalSignIn.member)
                document.cookie = `SourceSignUp=${data.externalSignIn.member}; expires=Wed, 31 Dec 2025 12:00:00 UTC;`
            }
            this.props.refetch()
          }

          // this.props.history.push('/')
        })
        .catch((error) => {
          console.log(error)
        })
    }
    //}
  }
  render() {
    return (
      <BlockUi
        blocking={true}
        style={{ height: window.innerHeight }}
        loader={
          <PacmanLoader color={'var(--primary)'} loading={true} />
        }
        message={<div className="text-primary">Verifying User</div>}
      />
    )
  }
}

export default graphql(EXTERNAL_SIGN_IN, {
  name: 'externalSignIn',
})(ExternalSignIn)
