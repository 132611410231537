/**
 * @ Author: Housefolios
 * @ Create Time: 2021-11-22 08:46:15
 * @ Modified by: TJ Felman
 * @ Modified time: 2022-07-12 12:03:33
 * @ Description: Allows users to send an email for reseting their password
 */

import React, { Fragment, useState } from 'react'
import MailOutlineTwoToneIcon from '@mui/icons-material/MailOutlineTwoTone'

import {
  Button,
  Grid,
  Hidden,
  InputAdornment,
  IconButton,
  Link,
  Card,
  CardContent,
  useMediaQuery,
} from '@mui/material'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { Mutation } from '@apollo/client/react/components'
import { FORGOT_PASSWORD } from '../queries'
import { enqueueSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import SignInFooter from '../SignInFooter/index'

const hostName = import.meta.env.VITE_HOSTNAME

function ForgotPasswordComponent(props) {
  const [login, setLogin] = useState('')

  const hiddenSmDown = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  )

  const onSubmit = (event, forgotPassword) => {
    forgotPassword()
      .then(async ({ data }) => {
        enqueueSnackbar(
          'Check your email for a reset password link',
          {
            variant: 'success',
            action: (key) => (
              <Fragment>
                <IconButton
                  onClick={() => {
                    props.closeSnackbar(key)
                  }}
                  size="large"
                >
                  <CloseIcon style={{ color: 'white' }} />
                </IconButton>
              </Fragment>
            ),
          },
        )
        window.location.href = '/signin'
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          action: (key) => (
            <Fragment>
              <IconButton
                onClick={() => {
                  props.closeSnackbar(key)
                }}
                size="large"
              >
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            </Fragment>
          ),
        })
      })
    event.preventDefault()
  }

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className="min-vh-100"
      >
        {hiddenSmDown ? null : (
          <Grid item style={{ textAlign: 'center' }}>
            {/* <img src={logo} alt="Housefolios" /> */}
          </Grid>
        )}
        <Grid item style={{ padding: 10 }}>
          <Mutation mutation={FORGOT_PASSWORD} variables={{ login }}>
            {(forgotPassword, { data, loading, error }) => (
              <ValidatorForm
                className="form-signin"
                style={{ marginBottom: 0 }}
                onSubmit={(event) => onSubmit(event, forgotPassword)}
              >
                <Card>
                  <CardContent>
                    <div className="text-center mb-5">
                      <h1 className="display-4 mb-1 font-weight-bold">
                        Recover Password
                      </h1>
                      <p className="font-size-lg mb-0 text-black-50">
                        Forgot your password? No worries, we're here
                        to help!
                      </p>
                    </div>
                    <div>
                      <label className="font-weight-bold mb-2">
                        Email Address
                      </label>
                      <TextValidator
                        fullWidth
                        variant="outlined"
                        id="textfield-email"
                        onChange={(event) =>
                          setLogin(event.target.value)
                        }
                        placeholder="Enter email"
                        value={login}
                        //error={error}
                        //helperText={error ? error.message : ''}
                        validators={[
                          'required',
                          'maxStringLength: 50',
                          'isEmail',
                        ]}
                        errorMessages={[
                          'This field is required',
                          'You cannot exceed 50 characters',
                          'Not a valid email address',
                        ]}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlineTwoToneIcon
                                style={{ color: '#04a3e3' }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="text-center mb-5">
                      <Button
                        fullWidth
                        type="submit"
                        className="text-uppercase font-weight-bold font-size-sm mt-4"
                        style={{
                          backgroundColor: '#04a3e3',
                          color: 'white',
                        }}
                      >
                        Send password
                      </Button>
                    </div>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item className="gridItem2">
                        <Link
                          href={
                            hostName === 'localhost' ||
                            hostName === 'dev-admin.housefolios.com'
                              ? '/createAccount'
                              : 'https://housefolios.com/pricing'
                          }
                        >
                          Create account
                        </Link>
                      </Grid>
                      <Grid item className="gridItem2">
                        <Link href="/signin">Sign in</Link>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </ValidatorForm>
            )}
          </Mutation>
        </Grid>
        <SignInFooter />
      </Grid>
    </>
  )
}

export default ForgotPasswordComponent
