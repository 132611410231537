import Reactour from 'reactour'
import { Button, Typography, Grid } from '@mui/material'
import { includes } from 'lodash'
import { useLocation } from 'react-router-dom'

export const AnalyzeCompsTour =
  ({
    isOpenAnalyzeCompsTour,
    closeAnalyzeCompsTour,
  }) => {
    let goToTo
    let shifted = false
    const { pathname } = useLocation()
    const lockTour = !includes(pathname, '/Comps')
    const steps = [
      {
        selector: '[data-tour="comps-step-1"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <div>
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Click "Comps" to see comparable properties
              </Typography>
            </div>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              if (!shifted) {
                goToTo(1)
                shifted = true
              }
            }
        },
      },
      {
        selector: '[data-tour="comps-step-2"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ textAlign: 'center' }}
                >
                  What is the purpose of comps?
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: 600 }}>
                  The purpose of comps is to identify what the after
                  repair value (ARV) is for the property you are
                  analyzing.
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  You find the ARV by looking at comps that are in a
                  similar condition to what your property will be in
                  once it is fixed up.
                </Typography>
              </Grid>
            </Grid>
          )
        },
        action: (node) => {
          if (node)
            node.onclick = () => {
              goToTo(2)
            }
        },
      },
      {
        selector: '[data-tour="comps-step-3"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ textAlign: 'center' }}
                >
                  Click here to add comps
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  In a perfect world, comps would be an exact match to
                  your property...but that is not the case. Which is
                  why we must adjust the comps.
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Does this property have more desirable
                  characteristics than your property (ie better yard,
                  newer, mature trees, better location, etc.).{' '}
                  <b>If so, adjust the slider accordingly.</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img
                  src={addCompImage}
                  alt="Add comp"
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          )
        },
      },
      {
        selector: '[data-tour="comps-step-4"]',
        content: ({ goTo, inDOM }) => {
          goToTo = goTo
          return (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ textAlign: 'center' }}
                >
                  Adjust comp defaults
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  You can also adjust the default comp parameters by
                  going to the settings tab in the left navigation
                  menu and clicking on organization. In the
                  organization tab you'll find "organization
                  preferences", which is where you can adjust the comp
                  values.
                </Typography>
              </Grid>
            </Grid>
          )
        },
      },
    ]
    return (
      <Reactour
        steps={steps}
        isOpen={isOpenAnalyzeCompsTour}
        onRequestClose={closeAnalyzeCompsTour}
        update={pathname}
        startAt={0}
        lastStepNextButton={
          <Button color="primary" variant="contained">
            End Tour
          </Button>
        }
        disableKeyboardNavigation={lockTour}
        disableDotsNavigation={lockTour}
        showButtons={!lockTour}
      />
    )
  }
