import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Header, SidebarCollapsed } from '@/layout-components'
import { Outlet } from 'react-router-dom'

const CollapsedSidebar = (props) => {
  const {
    contentBackground,
    sidebarToggleMobile,
    //headerDrawerToggle,
    notificationsDrawerToggle,
    headerSearchHover,
  } = props

  return (
    <>
      <div
        className={clsx(
          'app-wrapper app-sidebar-collapsed app-sidebar-fixed app-header-fixed vh-100',
          contentBackground,
          {
            'app-sidebar-mobile-open': sidebarToggleMobile,
            'header-drawer-open': notificationsDrawerToggle,
            'notification-drawer-open': notificationsDrawerToggle,
            'search-wrapper-open': headerSearchHover,
          },
        )}
      >
        <SidebarCollapsed />
        <div className="app-main">
          <Header />
          <div className="app-content">
            <div className="app-inner-content-layout--main">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

CollapsedSidebar.propTypes = {
  children: PropTypes.node,
}

const mapStateToProps = (state) => ({
  contentBackground: state.DeprecatedThemeOptions.contentBackground,
  sidebarToggleMobile:
    state.DeprecatedThemeOptions.sidebarToggleMobile,
  //headerDrawerToggle: state.DeprecatedThemeOptions.headerDrawerToggle,
  notificationsDrawerToggle:
    state.DeprecatedThemeOptions.notificationsDrawerToggle,
  headerSearchHover: state.DeprecatedThemeOptions.headerSearchHover,
})

export default connect(mapStateToProps)(CollapsedSidebar)
