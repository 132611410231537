import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  Typography,
  IconButton,
  Tooltip,
  Button,
  Grid,
  Backdrop,
  CircularProgress,
  CardContent,
  Select,
  MenuItem,
  Divider,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import RemoveIcon from '@mui/icons-material/Remove'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  map,
  filter,
  compact,
  includes,
  isEmpty,
  find,
  get,
  head,
  sum,
  truncate,
  padStart,
  isNil,
  toUpper,
} from 'lodash'
import ContactAgent from '../../../Marketplace/ContactAgent'
import DisplayRealeflowData from '@/housefolios-components/Dialogs/DisplayRealeflowData'
import { percentageFormat } from '@/utils/number'
import CountUp from 'react-countup'
import CommentsDialog from '@/housefolios-components/Dialogs/Comments'
import {
  ContactInformation,
  getCashEntryFee,
  getCreativeEntryFee,
  getPitiTotal,
} from '@/utils/loanBalance'
import SubtoPITICash from '@/housefolios-components/Dialogs/SubtoPITI/Cash/'
import SubtoPITICreative from '@/housefolios-components/Dialogs/SubtoPITI/Creative/'

const StatusBadge = ({ section }) => (
  <div className="d-flex justify-content-between py-2">
    <span className="font-weight-bold">{section.name}:</span>
    <span className="badge badge-success">
      {section.formatter || '-'}
    </span>
  </div>
)

const SummaryDetail = ({ section }) => (
  <div className="d-flex justify-content-between py-2">
    <span className="font-weight-bold">{section.name}:</span>
    <span className="text-black-50">
      {section.formatter
        ? section.formatter.format(section.value) || '-'
        : section.value || '-'}
    </span>
  </div>
)

const SummaryDetails = ({
  summaryDetails,
  strategyCards,
  property,
  preferences,
  currencyFormat,
  analysis,
}) => (
  <div className="font-size-sm py-3 rounded-sm">
    {map(summaryDetails, (section) =>
      section.name !== 'Status' ? (
        <SummaryDetail key={section.name} section={section} />
      ) : (
        <StatusBadge key={section.name} section={section} />
      ),
    )}
    <div
      className="font-size-sm p-3 bg-secondary rounded-sm"
      style={{ marginLeft: -10, marginRight: -10 }}
    >
      <div className="d-flex justify-content-between">
        <span className="font-weight-bold">ARV:</span>
        <span className="text-black-50">
          {head(
            compact(
              map(
                filter(strategyCards, (card) =>
                  !isEmpty(property.strategies)
                    ? includes(property.strategies, card.name)
                    : find(get(preferences, 'general.strategies'), {
                        value: card.name,
                        active: true,
                      }),
                ),
                (card) => card.afterRepairValue,
              ),
            ),
          )
            ? currencyFormat.format(
                head(
                  compact(
                    map(
                      filter(strategyCards, (card) =>
                        !isEmpty(property.strategies)
                          ? includes(property.strategies, card.name)
                          : find(
                              get(preferences, 'general.strategies'),
                              {
                                value: card.name,
                                active: true,
                              },
                            ),
                      ),
                      (card) => card.afterRepairValue,
                    ),
                  ),
                ),
              )
            : currencyFormat.format(0)}
        </span>
      </div>
      <div className="d-flex justify-content-between py-2">
        <span className="font-weight-bold">Rent:</span>
        <span className="text-black-50">
          {currencyFormat.format(sum(analysis.rent))}
        </span>
      </div>
    </div>
  </div>
)

const GorillaDetails = ({
  analysis,
  analytics,
  currencyFormat,
  numberFormat,
  gorillaSplit,
  gorillaFinalFee,
}) => (
  <div
    className="font-size-md p-3 bg-secondary rounded-sm"
    style={{ marginLeft: -10, marginRight: -10 }}
  >
    <div className="d-flex justify-content-between">
      <span className="font-weight-bold">Purchase Price:</span>
      <span className="text-black-50">
        {currencyFormat.format(analysis.offerPrice)}
      </span>
    </div>
    <div className="d-flex justify-content-between py-2">
      <span className="font-weight-bold">Renovation:</span>
      <span className="text-black-50">
        {currencyFormat.format(analysis.rehabCost)}
      </span>
    </div>
    <div className="d-flex justify-content-between py-2">
      <span className="font-weight-bold">Hold Time:</span>
      <span className="text-black-50">
        {numberFormat.format(
          isNil(analysis.rehabDays)
            ? (analysis.rehabCost || 0) / 500
            : analysis.rehabDays,
        )}{' '}
        days
      </span>
    </div>
    <div className="d-flex justify-content-between py-2">
      <span className="font-weight-bold">Sale Price:</span>
      <span className="text-black-50">
        {currencyFormat.format(analysis.resellPrice)}
      </span>
    </div>
    <div className="d-flex justify-content-between py-2">
      <span className="font-weight-bold">My Profit:</span>
      <span className="text-black-50">
        {currencyFormat.format(
          analytics.fixFlip.saleProceeds * gorillaSplit <
            gorillaFinalFee
            ? analytics.fixFlip.saleProceeds - gorillaFinalFee
            : analytics.fixFlip.saleProceeds * (1 - gorillaSplit),
        )}
      </span>
    </div>
    <div className="d-flex justify-content-between py-2">
      <span className="font-weight-bold">Gorilla Profit:</span>
      <span className="text-black-50">
        {currencyFormat.format(
          analytics.fixFlip.saleProceeds * gorillaSplit <
            gorillaFinalFee
            ? 0
            : analytics.fixFlip.saleProceeds * gorillaSplit -
                gorillaFinalFee,
        )}
      </span>
    </div>
  </div>
)

const PacemorbyDetails = ({
  pitiTotal,
  creativeEntryFee,
  cashEntryFee,
  percentageOfEntryFee,
}) => (
  <div
    className="d-flex flex-column justify-content-center align-items-center"
    style={{ padding: '20px' }}
  >
    {/* Row 1: Entry Fees */}
    <div
      className="d-flex flex-row justify-content-between"
      style={{ width: '100%' }}
    >
      <div
        className="d-flex flex-column align-items-center"
        style={{ flex: 1 }}
      >
        <Typography
          variant="h5"
          style={{ fontWeight: 600, marginBottom: '10px' }}
        >
          Cash Entry Fee
        </Typography>
        <Typography variant="h4" style={{ fontWeight: 600 }}>
          {cashEntryFee}
        </Typography>
      </div>

      <div
        className="d-flex flex-column align-items-center"
        style={{ flex: 1 }}
      >
        <Typography
          variant="h5"
          style={{ fontWeight: 600, marginBottom: '10px' }}
        >
          Creative Entry Fee
        </Typography>
        <Typography variant="h4" style={{ fontWeight: 600 }}>
          {creativeEntryFee}
        </Typography>
      </div>
    </div>

    {/* Row 2: PITI Total and % of Purchase Price */}
    <div
      className="d-flex flex-row justify-content-between"
      style={{ width: '100%', marginTop: '30px' }}
    >
      <div
        className="d-flex flex-column align-items-center"
        style={{ flex: 1 }}
      >
        <Typography
          variant="h5"
          style={{ fontWeight: 600, marginBottom: '10px' }}
        >
          PITI Total
        </Typography>
        <Typography variant="h4" style={{ fontWeight: 600 }}>
          {pitiTotal}
        </Typography>
      </div>

      <div
        className="d-flex flex-column align-items-center"
        style={{ flex: 1 }}
      >
        <Typography
          variant="h5"
          style={{ fontWeight: 600, marginBottom: '10px' }}
        >
          % of Purchase Price
        </Typography>
        <Typography variant="h4" style={{ fontWeight: 600 }}>
          {percentageOfEntryFee}
        </Typography>
      </div>
    </div>
  </div>
)

const PITICard = ({ property, analysis, userId }) => {
  const [dealSauceView, setDealSauceView] = useState('creative')
  const [openPITICashData, setOpenPITICashData] = useState(false)
  const [openPITICreativeData, setOpenPITICreativeData] =
    useState(false)

  const pitiTotal = getPitiTotal(property)
  const creativeEntryFee = getCreativeEntryFee(property)
  const cashEntryFee = getCashEntryFee(property)

  return (
    <Card className="card-box w-100">
      <div
        className="card-header"
        style={{ marginBottom: 5, marginTop: 5 }}
      >
        <div className="card-header--title">
          <b>Deal Sauce Summary</b>
        </div>
        <Select
          value={dealSauceView}
          name="dealSauceView"
          onChange={(event) => setDealSauceView(event.target.value)}
          style={{ width: '100%' }}
        >
          <MenuItem value="cash" key="cash">
            Cash
          </MenuItem>
          <MenuItem value="creative" key="creative">
            Creative
          </MenuItem>
        </Select>
      </div>
      <Divider />
      <CardContent>
        {dealSauceView === 'cash' && (
          <>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid container className="text-black-50 font-size-sm">
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">Entry Fee</div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div
                        className="d-flex p-1 align-items-center"
                        style={{ minHeight: 50 }}
                      >
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={cashEntryFee}
                            duration={0}
                            separator=","
                            delay={1}
                            decimals={0}
                            decimal="."
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      Max Allowable Offer
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div
                        className="d-flex p-1 align-items-center"
                        style={{ minHeight: 50 }}
                      >
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              analysis.afterRepairValue - cashEntryFee
                            }
                            duration={0}
                            separator=","
                            delay={1}
                            decimals={0}
                            decimal="."
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid item xs={12} style={{ marginTop: 35 }}>
              <Grid container direction="column" spacing={4}>
                <ContactInformation property={property} />
              </Grid>
            </Grid>
            <CommentsDialog property={property} userId={userId} />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: 25 }}
              onClick={(e) => setOpenPITICashData(true)}
            >
              <Typography>View Cash Underwriting</Typography>
            </Button>
          </>
        )}
        {dealSauceView === 'creative' && (
          <>
            <div className="rounded p-2 mb-2 bg-secondary text-center border-light border-1">
              <Grid container className="text-black-50 font-size-sm">
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">Entry Fee</div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={creativeEntryFee}
                            duration={1}
                            separator=","
                            delay={1}
                            decimals={0}
                            decimal="."
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">PITI</div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={pitiTotal}
                            duration={1}
                            separator=","
                            delay={1}
                            decimals={0}
                            decimal="."
                            prefix="$"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={12}
                  className="d-flex justify-content-center"
                >
                  <div>
                    <div className="text-dark pb-1">
                      % of Purchase Price
                    </div>
                    <div className="font-size-lg d-flex align-items-center justify-content-center text-second">
                      <div className="d-flex p-1 align-items-center">
                        <span className="d-20 w-auto">
                          <CountUp
                            start={0}
                            end={
                              analysis?.offerPrice &&
                              analysis.offerPrice !== 0
                                ? (creativeEntryFee /
                                    analysis.offerPrice) *
                                  100
                                : 0
                            }
                            duration={1}
                            delay={1}
                            decimals={2}
                            decimal="."
                            suffix="%"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid container>
              <Grid item xs={12}>
                <Grid container direction="column" spacing={4}>
                  <ContactInformation property={property} />
                </Grid>
              </Grid>
              <CommentsDialog property={property} userId={userId} />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: 25 }}
                onClick={(e) => setOpenPITICreativeData(true)}
              >
                <Typography>View Creative Underwriting</Typography>
              </Button>
            </Grid>
          </>
        )}
      </CardContent>
      <SubtoPITICash
        open={openPITICashData}
        onClose={() => setOpenPITICashData(false)}
        propertyId={property?._id}
        address={property?.address}
        city={property.city}
        state={property.state}
        zipcode={property?.zip}
      />
      <SubtoPITICreative
        open={openPITICreativeData}
        onClose={() => setOpenPITICreativeData(false)}
        propertyId={property?._id}
        address={property?.address}
        city={property.city}
        state={property.state}
        zipcode={property?.zip}
      />
    </Card>
  )
}

const CardSummary = (props) => {
  const {
    property,
    dateCreatedString,
    analysis,
    analytics,
    currencyFormat,
    numberFormat,
    strategyCards,
    gorillaSplit,
    gorillaFinalFee,
    loading,
    setLoading,
    callPropertyExplorerReport,
    setOpenMemberDisclaimer,
    setOpenMember,
    toggleLimit,
    userId,
  } = props

  // Safely access activeOrgMember
  const activeOrgMember =
    props.props.session?.me?.activeOrg?.member || null

  const summaryDetails = [
    {
      name: 'Status',
      value: property.status,
      formatter: property.status || '-',
    },
    {
      name: 'Asking Price',
      value: analysis.listPrice,
      formatter: currencyFormat,
    },
    { name: 'Neighborhood', value: toUpper(analysis.neighborhood) },
    { name: 'MLS/Source ID', value: property.MLSID },
  ]

  const renderAction = () => {
    if (props.public || props.shared) return null

    if (activeOrgMember === 'gorillacapital') {
      const fixFlipRatio =
        (analytics.fixFlip.onTargetFlip +
          analytics.fixFlip.aboveTargetFlip) /
        (analytics.fixFlip.belowTargetFlip +
          analytics.fixFlip.onTargetFlip +
          analytics.fixFlip.aboveTargetFlip)

      return (
        <IconButton
          size="small"
          style={{
            backgroundColor:
              fixFlipRatio >= 0.66
                ? '#3edf6e'
                : fixFlipRatio >= 0.33
                  ? '#ff9700'
                  : '#ff3f3d',
            color: 'white',
          }}
        >
          {fixFlipRatio >= 0.66 ? (
            <ArrowUpwardIcon />
          ) : fixFlipRatio >= 0.33 ? (
            <RemoveIcon />
          ) : (
            <ArrowDownwardIcon />
          )}
        </IconButton>
      )
    }

    return (
      <Tooltip title="Edit Summary Details">
        <Button
          onClick={() => props.toggleCenter('Edit')}
          className="d-44 bg-neutral-first rounded"
        >
          <FontAwesomeIcon
            icon={['far', 'pen']}
            className="text-first font-size-lg"
          />
        </Button>
      </Tooltip>
    )
  }

  const renderContent = () => {
    if (activeOrgMember === 'gorillacapital') {
      return (
        <GorillaDetails
          {...{
            analysis,
            analytics,
            currencyFormat,
            numberFormat,
            gorillaSplit,
            gorillaFinalFee,
          }}
        />
      )
    }

    if (
      activeOrgMember === 'pacemorby' ||
      activeOrgMember === 'astroflip'
    ) {
      return (
        <PITICard
          property={property}
          analysis={analysis}
          userId={userId}
        />
      )
      // const pitiTotal = currencyFormat.format(
      //   Number(property?.pitiData?.creative?.principal) +
      //     Number(property?.pitiData?.creative?.interest) +
      //     Number(property?.pitiData?.creative?.taxes) +
      //     Number(property?.pitiData?.creative?.insurance) || 0,
      // ) // Replace with actual value
      // const creativeEntryFee =
      //   Number(property?.pitiData?.creative?.moneyToSeller || 0) +
      //   Number(property?.pitiData?.creative?.arrears || 0) +
      //   Number(property?.pitiData?.creative?.agentFee || 0) +
      //   Number(property?.pitiData?.creative?.assignmentFee || 0) +
      //   Number(
      //     property?.pitiData?.creative
      //       ?.transactionCoordinatorExpenses || 0,
      //   ) +
      //   Number(property?.pitiData?.creative?.lendingExpenses || 0) +
      //   Number(property?.pitiData?.creative?.estRehab || 0) +
      //   Number(property?.pitiData?.creative?.furnishings || 0) +
      //   Number(property?.pitiData?.creative?.holdingCosts || 0)

      // const cashEntryFee =
      //   Number(property?.pitiData?.cash?.tcExpenses || 0) +
      //   Number(property?.pitiData?.cash?.assignmentToSelf || 0) +
      //   Number(property?.pitiData?.cash?.dispoExpenses || 0) +
      //   Number(property?.pitiData?.cash?.jvConnectorFee || 0) +
      //   Number(property?.pitiData?.cash?.closingCost || 0) +
      //   Number(property?.pitiData?.cash?.agentExpense || 0) +
      //   Number(property?.pitiData?.cash?.renovationExpenses || 0) +
      //   Number(property?.pitiData?.cash?.optionalExpense || 0)

      // return (
      //   <PacemorbyDetails
      //     pitiTotal={pitiTotal}
      //     creativeEntryFee={currencyFormat.format(creativeEntryFee)}
      //     cashEntryFee={currencyFormat.format(cashEntryFee)}
      //     percentageOfEntryFee={percentageFormat.format(
      //       creativeEntryFee / analysis.offerPrice || 0,
      //     )}
      //   />
      // )
    }

    return (
      <SummaryDetails
        {...{
          summaryDetails,
          strategyCards,
          property,
          preferences: props.preferences,
          currencyFormat,
          analysis,
        }}
      />
    )
  }

  if (activeOrgMember === 'pacemorby') {
    return (
      <Card
        className="card-box p-4"
        style={{
          borderRadius: 0,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography
            variant="h6"
            style={{ fontWeight: 600, marginBottom: '20px' }}
          >
            Summary
          </Typography>
          {renderContent()}
        </div>
        <Grid
          container
          direction="column"
          spacing={1}
          style={{ marginTop: 'auto' }}
        >
          {!props.shared &&
            activeOrgMember !== 'gorillacapital' &&
            activeOrgMember !== 'paulmccomas' && (
              <Grid item>
                <ContactAgent property={property} propertySummary />
              </Grid>
            )}
          {property.realeflowData && (
            <Grid item>
              <DisplayRealeflowData property={property} button />
            </Grid>
          )}
        </Grid>
      </Card>
    )
  }

  // Default content for other org members
  return (
    <Card
      className="card-box p-4"
      style={{ borderRadius: 0, height: '100%' }}
    >
      <CardHeader
        title={
          <Typography variant="h6" style={{ fontWeight: 600 }}>
            Summary
          </Typography>
        }
        subheader={
          <Typography style={{ color: '#a5a8a9' }}>
            Added by{' '}
            {property.createdBy
              ? `${property.createdBy.profile.firstname} ${property.createdBy.profile.lastname[0]}`
              : 'Unknown'}{' '}
            on {dateCreatedString}
          </Typography>
        }
        action={renderAction()}
        className="p-0"
      />
      {property.description && (
        <div className="scroll-area-xs rounded bg-white">
          <PerfectScrollbar>
            <p
              className="text-black-50 font-size-md mb-0"
              style={{ marginTop: 10 }}
            >
              {property.description}
            </p>
          </PerfectScrollbar>
        </div>
      )}
      <div className="divider mt-4" />
      {renderContent()}
      <div className="divider mb-4" />
      <Grid container direction="column" spacing={1}>
        {!props.shared &&
          activeOrgMember !== 'gorillacapital' &&
          activeOrgMember !== 'paulmccomas' && (
            <Grid item>
              <ContactAgent property={property} propertySummary />
            </Grid>
          )}
        {property.realeflowData && (
          <Grid item>
            <DisplayRealeflowData property={property} button />
          </Grid>
        )}
        {!props.shared &&
          (activeOrgMember === 'roitk' ||
            activeOrgMember === 'dbi') && (
            <>
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  className="btn-primary font-weight-bold text-uppercase font-size-sm hover-scale-sm"
                  onClick={() => {
                    setLoading(true)
                    callPropertyExplorerReport({
                      variables: {
                        propertyId: property._id,
                        address: property.address,
                        zipcode: padStart(
                          truncate(property.zip, {
                            length: 5,
                            omission: '',
                          }),
                          5,
                          '0',
                        ),
                      },
                      refetchQueries: [
                        {
                          query: GET_PROPERTY,
                          variables: { id: property._id },
                        },
                      ],
                    }).then(() => {
                      setLoading(false)
                    })
                  }}
                  disabled={
                    property.houseCanaryPropertyExplorerLink ||
                    loading
                  }
                >
                  {'Property Explorer Report'}
                </Button>
              </Grid>
              {property.houseCanaryPropertyExplorerLink && (
                <Grid item>
                  <a
                    href={property.houseCanaryPropertyExplorerLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Property Explorer Report
                  </a>
                </Grid>
              )}
              <Backdrop
                open={loading}
                style={{ color: '#fff', zIndex: 2000 }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </>
          )}
        {(activeOrgMember === 'gorillacapital' ||
          activeOrgMember === 'paulmccomas') && (
          <>
            {property.jv ? (
              <Card className="card-box">
                <CardContent className="px-4 py-3">
                  <div className="pb-3 d-flex justify-content-between">
                    Property Submission Status
                  </div>
                  <div className="d-flex align-items-center justify-content-start">
                    <span
                      style={{
                        color: 'white',
                        backgroundColor: '#368CFF',
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      {property.jvStatus}
                    </span>
                  </div>
                </CardContent>
              </Card>
            ) : property.lender ? (
              <Card className="card-box">
                <CardContent className="px-4 py-3">
                  <div className="pb-3 d-flex justify-content-between">
                    Property Submission Status
                  </div>
                  <div className="d-flex align-items-center justify-content-start">
                    <span
                      style={{
                        color: 'white',
                        backgroundColor: '#368CFF',
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      {property.lenderStatus}
                    </span>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <Button
                fullWidth
                className="btn-success"
                onClick={
                  props.fixFlipPercentage < 100
                    ? () => setOpenMemberDisclaimer(true)
                    : () => setOpenMember(true)
                }
              >
                <span className="btn-wrapper--icon">
                  <img
                    src="/images/white-gorilla-only.png"
                    alt="gorilla"
                    height={50}
                  />
                </span>
                <span
                  className="btn-wrapper--label"
                  style={{ fontSize: 16 }}
                >
                  Submit to Gorilla
                </span>
              </Button>
            )}
          </>
        )}
      </Grid>
    </Card>
  )
}

export default CardSummary
