/**
 * @ Author: Housefolios
 * @ Create Time: 2023-03-22 13:12:34
 * @ Modified by: Daniel Clark
 * @ Modified time: 2025-02-04 12:11:45
 * @ Description: Dialog for updating access to a shared marketplace
 */

import React, { useState } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import map from 'lodash/map'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'

import { enqueueSnackbar } from 'notistack'
import withSession from '@/housefolios-components/Session/withSession'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { Query } from '@apollo/client/react/components'
import {
  GET_MARKETPLACES,
  GET_ORGS_MARKETPLACE_SHARED_WITH,
} from '../queries'
import { useMutation } from '@apollo/client'
import {
  REMOVE_SHARED_MARKETPLACE,
  UPDATE_SHARED_MARKETPLACE,
} from '../mutations'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { Close } from '@mui/icons-material'

function UpdateSharedMarketplaceComponent(props) {
  const {
    orgId,
    marketplaceURL,
    rightMenu,
    sidebarMenu,
    shareAccess,
    canList,
  } = props

  const [openUpdateMarketplace, setOpenUpdateMarketplace] =
    useState(null)
  const [loader, setLoader] = useState(false)

  const [sharePermission, setSharePermission] = useState(
    props.sharePermission || 'viewOnly',
  )

  const handleOpenUpdateMarketplace = (event) => {
    setOpenUpdateMarketplace(true)
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const handleCloseUpdateMarketplace = (event) => {
    setOpenUpdateMarketplace(false)
    setLoader(false)
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const [updateSharedMarketplace] = useMutation(
    UPDATE_SHARED_MARKETPLACE,
  )

  return (
    <>
      {rightMenu && (
        <ListItem button onClick={handleOpenUpdateMarketplace}>
          <Close style={{ marginRight: 5, color: '#757575' }} />
          <span>Update Shared Marketplace</span>
        </ListItem>
      )}
      {shareAccess && (
        <IconButton
          onClick={handleOpenUpdateMarketplace}
          size="large"
        >
          <FontAwesomeIcon icon={['far', 'pen-to-square']} />
        </IconButton>
      )}
      {sidebarMenu && (
        <Tooltip
          title="Update Marketplace"
          classes={{ tooltip: 'tooltip-first' }}
          arrow
        >
          <IconButton
            onClick={handleOpenUpdateMarketplace}
            style={{
              backgroundColor: 'white',
              width: 30,
              height: 30,
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        open={openUpdateMarketplace}
        onClose={handleCloseUpdateMarketplace}
        maxWidth="sm"
        fullWidth={true}
        scroll="body"
        onClick={(event) => event.stopPropagation()}
      >
        <DialogTitle>{'Update Access'}</DialogTitle>
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              Removing Shared Marketplace
            </div>
          }
        >
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  select
                  name="sharePermission"
                  variant="outlined"
                  margin="dense"
                  label="Permission"
                  fullWidth={true}
                  // style={{ width: 175 }}
                  value={sharePermission}
                  onChange={(event) =>
                    setSharePermission(event.target.value)
                  }
                  required={true}
                >
                  <MenuItem key={'viewOnly'} value={'viewOnly'}>
                    {'View Only'}
                  </MenuItem>
                  <MenuItem
                    key={'canListDelist'}
                    value={'canListDelist'}
                  >
                    {'Can List - Needs Approval'}
                  </MenuItem>
                  <MenuItem
                    key={'canListDelistAutoApprove'}
                    value={'canListDelistAutoApprove'}
                  >
                    {'Can List - No Approval'}
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={handleCloseUpdateMarketplace}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateSharedMarketplace({
                  variables: {
                    orgId,
                    url: marketplaceURL,
                    sharePermission,
                  },
                  refetchQueries: [
                    {
                      query: GET_ORGS_MARKETPLACE_SHARED_WITH,
                      variables: {
                        url: marketplaceURL,
                      },
                    },
                    {
                      query: GET_ME,
                    },
                    {
                      query: GET_MARKETPLACES,
                      variables: {
                        marketplaces: [marketplaceURL],
                        approved: true,
                      },
                    },
                  ],
                })
                  .then(() => {
                    enqueueSnackbar(
                      `Marketplace Access Succsesfully Updated`,
                      {
                        variant: 'success',
                        autoHideDuration: 3000,
                      },
                    )
                    handleCloseUpdateMarketplace()
                  })
                  .catch((error) => {
                    enqueueSnackbar(
                      `Failed to Update Marketplace Access`,
                      {
                        variant: 'error',
                        autoHideDuration: 3000,
                      },
                    )
                    handleCloseUpdateMarketplace()
                  })
              }}
            >
              Update
            </Button>
          </DialogActions>
        </BlockUi>
      </Dialog>
    </>
  )
}
export default withSession(UpdateSharedMarketplaceComponent)
