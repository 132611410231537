/**
 * @ Author: Housefolios
 * @ Create Time: 2022-05-25 14:41:45
 * @ Modified by: David Helmick
 * @ Modified time: 2024-08-02 12:34:37
 * @ Description: Page component for adding shared properties to the user's orgs.
 */

import React, { useState } from 'react'

import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Checkbox,
  List,
  ListItem,
  Backdrop,
  CircularProgress,
} from '@mui/material'

import includes from 'lodash/includes'
import remove from 'lodash/remove'
import map from 'lodash/map'
import find from 'lodash/find'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'

import housefoliosLogo from '@/assets/images/HousefoliosCloudTransparent.png'
import { useMutation } from '@apollo/client'

import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import withSession from '@/housefolios-components/Session/withSession'
import { ADD_SHARED_PROPERTIES } from '../mutation'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { enqueueSnackbar } from 'notistack'
import { DEFAULT_PREFERENCES } from '@/housefolios-components/OrganizationSettings/OrganizationPreferences'
import useAffiliate from '@/hooks/useAffiliate'
import { useNavigate } from 'react-router-dom'

function AddSharedPropertiesComponent(props) {
  const [selected, setSelected] = useState(
    compact(
      map(props.session.me.roles, (roleOrg) => {
        if (
          roleOrg.organization &&
          roleOrg.role[0] !== 'deactive' &&
          roleOrg.organization._id !== 'superAdmin'
        )
          return roleOrg.organization._id
      }),
    ),
  )
  const [loader, setLoader] = React.useState(false)

  const [addSharedProperties] = useMutation(ADD_SHARED_PROPERTIES)

  const urlParams = new URLSearchParams(window.location.search)
  let token = urlParams.get('sharedPropertiesToken')

  const preferences = merge(
    cloneDeep(DEFAULT_PREFERENCES),
    props?.session?.me?.activeOrg?.memberPreferences,
    props?.session?.me?.activeOrg?.preferences,
    props.session.me.preferences,
  )

  const toggleOrg = (orgId) => {
    let newSelected = [...selected]
    if (includes(newSelected, orgId))
      remove(newSelected, (select) => select === orgId)
    else newSelected.push(orgId)
    setSelected(newSelected)
  }

  const navigate = useNavigate()
  if (!token) navigate('/')

  const [{ affiliate, affiliateLoading, urlAffiliate }] =
    useAffiliate({})

  const selectAll = () => {
    if (
      selected.length <
      filter(
        props.session.me.roles,
        (roleOrg) =>
          roleOrg.organization &&
          roleOrg.role[0] !== 'deactive' &&
          roleOrg.organization._id !== 'superAdmin',
      ).length
    )
      setSelected(
        compact(
          map(props.session.me.roles, (roleOrg) => {
            if (
              roleOrg.organization &&
              roleOrg.role[0] !== 'deactive' &&
              roleOrg.organization._id !== 'superAdmin'
            )
              return roleOrg.organization._id
          }),
        ),
      )
    else setSelected([])
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    setLoader(true)

    if (props.token) token = props.token

    for (let i = 0; i < selected.length; i++) {
      await addSharedProperties({
        variables: {
          token,
          orgId: selected[i],
        },
        refetchQueries: [{ query: GET_ME }],
      })
        .then(async ({ data }) => {
          setLoader(false)
          enqueueSnackbar(
            `Added shared properties to org ${
              find(
                props.session.me.roles,
                (role) => role.organization._id === selected[i],
              ).organization.name
            }`,
            {
              variant: 'success',
              autoHideDuration: 3000,
            },
          )
          //props.history.push(routes.PROPERTIES + 'card')
        })
        .catch((error) => {
          setLoader(false)
          const message = error.message
          if (includes(message, 'expired'))
            enqueueSnackbar('Token has expired', {
              variant: 'error',
              autoHideDuration: 3000,
            })
          else if (includes(message, 'user'))
            enqueueSnackbar('Token intended for a different user', {
              variant: 'error',
              autoHideDuration: 3000,
            })
          else if (includes(message, 'Org'))
            enqueueSnackbar('Org owns this properties', {
              variant: 'error',
              autoHideDuration: 3000,
            })
          else if (includes(message, 'Properties'))
            enqueueSnackbar('Properties already added', {
              variant: 'error',
              autoHideDuration: 3000,
            })
          else
            enqueueSnackbar('Token is invalid', {
              variant: 'error',
              autoHideDuration: 3000,
            })
          console.log(error)
        })
    }

    if (props.handleClose) props.handleClose()
    else
      navigate(
        get(preferences, 'general.defaultPage.value') || '/launchpad',
      )
  }

  if (affiliateLoading)
    return (
      <Backdrop open={true} style={{ color: '#fff', zIndex: 2000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )

  return (
    <div
      className={
        props.handleClose ? '' : 'app-wrapper bg-white min-vh-100'
      }
    >
      <div className={props.handleClose ? '' : 'app-main min-vh-100'}>
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content py-5">
                <Card>
                  <CardContent>
                    <Grid container direction="column" spacing={4}>
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          wrap="nowrap"
                          alignItems="center"
                        >
                          <Grid item>
                            <Grid container direction="column">
                              <Grid item>
                                <Typography
                                  variant="h6"
                                  style={{ fontWeight: 600 }}
                                >
                                  Add Shared Properties
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography>
                                  Choose the organizations to add the
                                  shared properties to
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <img
                              src={
                                urlAffiliate
                                  ? get(
                                      affiliate,
                                      'sidebar.sidebarMemberSmallImage.0.url',
                                    ) || housefoliosLogo
                                  : housefoliosLogo
                              }
                              alt="Housefolios"
                              style={{ width: 60, height: 50 }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* <Grid item>
                         <Button
                           variant='outlined'
                           fullWidth={true}
                         >
                           <FontAwesomeIcon
                             style={{ marginRight: 10 }}
                             icon={['fab', 'google']}
                             size="1x"
                           />
                           <Typography>SIGN IN WITH GOOGLE</Typography>
                         </Button>
                       </Grid>
                       <Grid item style={{ textAlign: 'center' }}>
                         <Typography>OR</Typography>
                       </Grid> */}
                      <Grid item>
                        <BlockUi
                          blocking={loader}
                          loader={
                            <PacmanLoader
                              color={'var(--primary)'}
                              loading={loader}
                            />
                          }
                          message={
                            <div className="text-primary">
                              Adding Shared Properties
                            </div>
                          }
                        >
                          <Grid container direction="row" spacing={2}>
                            <Grid item xs={12}>
                              <div
                                className="d-flex align-items-center"
                                style={{ width: '100%' }}
                              >
                                <div className="avatar-icon-wrapper d-50 mr-3">
                                  <Checkbox
                                    checked={
                                      selected.length ===
                                      filter(
                                        props.session.me.roles,
                                        (roleOrg) =>
                                          roleOrg.organization &&
                                          roleOrg.role[0] !==
                                            'deactive' &&
                                          roleOrg.organization._id !==
                                            'superAdmin',
                                      ).length
                                    }
                                    indeterminate={
                                      !isEmpty(selected) &&
                                      selected.length <
                                        filter(
                                          props.session.me.roles,
                                          (roleOrg) =>
                                            roleOrg.organization &&
                                            roleOrg.role[0] !==
                                              'deactive' &&
                                            roleOrg.organization
                                              ._id !== 'superAdmin',
                                        ).length
                                    }
                                    //onChange={() => toggleOrg(roleOrg.organization._id)}
                                    onClick={selectAll}
                                  />
                                </div>
                                <div>
                                  <div className="font-weight-bold font-size-sm text-black">
                                    Select All
                                  </div>
                                  {/* <div className="d-flex align-items-center font-size-xs">
                                      <div style={{ backgroundColor: '#797d95' }} className="badge badge-circle border-white border-1 mr-2">Completed</div>
                                      <div style={{ color: '#797d95' }}>Not Selected</div>
                                    </div> */}
                                </div>
                                <div style={{ flex: 1 }} />
                                <div>
                                  {/* <Chip color="primary" label="Share marketplace" 
                                        // onClick={(e) => {
                                        //   toggleOrg(roleOrg.organization._id)
                                        // }}
                                    /> */}
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <List
                                component="div"
                                className="list-group-flush"
                                style={{
                                  height: 400,
                                  overflow: 'scroll',
                                }}
                              >
                                {map(
                                  props.session.me.roles,
                                  (roleOrg) => {
                                    if (
                                      roleOrg.organization &&
                                      roleOrg.role[0] !==
                                        'deactive' &&
                                      roleOrg.organization._id !==
                                        'superAdmin'
                                    ) {
                                      return (
                                        <ListItem
                                          component="a"
                                          button
                                          href={null}
                                          disableRipple
                                          onClick={(e) => {
                                            toggleOrg(
                                              roleOrg.organization
                                                ._id,
                                            )
                                          }}
                                          className="d-flex bg-white hover-scale-rounded justify-content-between align-items-center"
                                        >
                                          <div
                                            className="d-flex align-items-center"
                                            style={{ width: '100%' }}
                                          >
                                            <div className="avatar-icon-wrapper d-50 mr-3">
                                              <Checkbox
                                                style={{
                                                  marginRight: 8,
                                                }}
                                                checked={includes(
                                                  selected,
                                                  roleOrg.organization
                                                    ._id,
                                                )}
                                                //onChange={() => toggleOrg(roleOrg.organization._id)}
                                              />
                                            </div>
                                            <div>
                                              <div className="font-weight-bold font-size-sm text-black">
                                                {
                                                  roleOrg.organization
                                                    .name
                                                }
                                              </div>
                                              {/* <div className="d-flex align-items-center font-size-xs">
                                               <div style={{ backgroundColor: '#797d95' }} className="badge badge-circle border-white border-1 mr-2">Completed</div>
                                               <div style={{ color: '#797d95' }}>Not Selected</div>
                                             </div> */}
                                            </div>
                                            <div
                                              style={{ flex: 1 }}
                                            />
                                            {/* <div>
                                             <Chip color="primary" label="Share properties" 
                                                 // onClick={(e) => {
                                                 //   toggleOrg(roleOrg.organization._id)
                                                 // }}
                                             />
                                           </div> */}
                                          </div>
                                        </ListItem>
                                      )
                                    }
                                  },
                                )}
                              </List>
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                type="submit"
                                // color='primary'
                                fullWidth={true}
                                onClick={onSubmit}
                                style={{
                                  backgroundColor: '#04a3e3',
                                  color: 'white',
                                }}
                                disabled={isEmpty(selected)}
                              >
                                <Typography>
                                  Add Properties
                                </Typography>
                              </Button>
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                fullWidth={true}
                                onClick={
                                  props.handleClose
                                    ? props.handleClose
                                    : () =>
                                        navigate(
                                          get(
                                            preferences,
                                            'general.defaultPage.value',
                                          ) || '/launchpad',
                                        )
                                }
                              >
                                <Typography>
                                  {props.handleClose
                                    ? 'Close'
                                    : 'Continue to Default Page'}
                                </Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </BlockUi>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withSession(AddSharedPropertiesComponent)
