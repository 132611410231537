import { gql } from '@apollo/client'
import { AssumptionsFragments } from './fragments'

export const SAVE_ASSUMPTIONS = gql`
  mutation saveAssumptions(
    $documentId: ID!
    $documentType: String!
    $assumptionsInput: AssumptionsInput!
  ) {
    saveAssumptions(
      assumptionsInput: $assumptionsInput
      documentId: $documentId
      documentType: $documentType
    ) {
      ...assumptions
    }
  }
  ${AssumptionsFragments.assumptions}
`

export const SAVE_ASSUMPTIONS_MULTI = gql`
  mutation saveAssumptionsMulti(
    $documentIds: [ID]!
    $documentType: String!
    $assumptionsInput: AssumptionsInput!
  ) {
    saveAssumptionsMulti(
      assumptionsInput: $assumptionsInput
      documentIds: $documentIds
      documentType: $documentType
    ) {
      ...assumptions
    }
  }
  ${AssumptionsFragments.assumptions}
`

export const SAVE_CHILDREN_ASSUMPTIONS = gql`
  mutation saveChildrenAssumptions(
    $documentId: ID!
    $documentType: String!
    $assumptionsInput: AssumptionsInput!
    $optionThree: Boolean
  ) {
    saveChildrenAssumptions(
      assumptionsInput: $assumptionsInput
      documentId: $documentId
      documentType: $documentType
      optionThree: $optionThree
    ) {
      ...assumptions
    }
  }
  ${AssumptionsFragments.assumptions}
`

export const RESET_ASSUMPTIONS = gql`
  mutation resetAssumptions(
    $parentId: ID
    $childId: ID!
    $parentType: String
    $childType: String!
    $affiliateReset: Boolean
  ) {
    resetAssumptions(
      parentId: $parentId
      childId: $childId
      parentType: $parentType
      childType: $childType
      affiliateReset: $affiliateReset
    ) {
      ...assumptions
    }
  }
  ${AssumptionsFragments.assumptions}
`
