/**
 * @ Author: Housefolios
 * @ Create Time: 2022-05-13 13:51:02
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-03 13:32:24
 * @ Description: Dialog to remove options from dropdowns
 */

import React, { useState } from 'react'

import remove from 'lodash/remove'
import pull from 'lodash/pull'
import findIndex from 'lodash/findIndex'
import isNil from 'lodash/isNil'
import cloneDeep from 'lodash/cloneDeep'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import { GET_PROPERTIES } from '@/housefolios-components/Properties/queries'
import { enqueueSnackbar } from 'notistack'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import withSession from '@/housefolios-components/Session/withSession'
import { UPDATE_LOT } from '@/housefolios-components/Lots/mutations'
import { UPDATE_PROPERTIES } from '@/housefolios-components/Portfolio/mutations'
import { GET_PORTFOLIOS } from '@/housefolios-components/Portfolio/queries'
import { GET_ME } from '@/housefolios-components/Session/queries'
import { GET_LOT } from '@/housefolios-components/Lots/queries'
import { DeleteOutlined } from '@mui/icons-material'
import { UPDATE_ORGANIZATION } from '@/housefolios-components/OrganizationSettings/mutations'
import { UPDATE_AFFILIATE } from '@/housefolios-components/PartnerPortal/mutations'
import useAffiliate from '@/hooks/useAffiliate'
import { GET_AFFILIATE } from '@/housefolios-components/CreateAccount/CreateAccountCard/queries'

function RemoveOptionComponent(props) {
  const {
    removeOption,
    removeOptionLabel,
    removeOptionValue,
    removeOptionItemLabel,
  } = props
  const [loader, setLoader] = useState(false)
  const [openRemoveOption, setOpenRemoveOption] = useState(false)
  const [removeOptionConfirm, setRemoveOptionConfirm] =
    useState(false)

  const handleOpenRemoveOption = (event) => {
    setOpenRemoveOption(true)
    setRemoveOptionConfirm(false)
    setLoader(false)
    event.stopPropagation()
  }

  const handleCloseRemoveOption = () => {
    setOpenRemoveOption(false)
    setRemoveOptionConfirm(false)
    setLoader(false)
  }

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)
  const [updateAffiliate] = useMutation(UPDATE_AFFILIATE)

  const [{ affiliate }] = useAffiliate({
    memberValue: props?.session?.me?.activeOrg?.member,
  })

  return (
    <>
      {props.menuItem ? (
        <MenuItem
          key={removeOptionValue}
          value={removeOptionValue}
          onClick={handleOpenRemoveOption}
        >
          <Grid container alignItems="center" direction="row">
            <Grid item className="gridItem2">
              {removeOptionLabel}
            </Grid>
          </Grid>
        </MenuItem>
      ) : (
        <Grid item>
          <IconButton onClick={handleOpenRemoveOption} size="small">
            <DeleteOutlined fontSize="medium" />
          </IconButton>
        </Grid>
      )}

      <Dialog
        open={openRemoveOption}
        onClose={handleCloseRemoveOption}
        classes={{ paper: 'shadow-lg rounded' }}
        onClick={(event) => event.stopPropagation()}
        //maxWidth="xs"
        fullWidth={true}
      >
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              Deleting {removeOptionLabel}
            </div>
          }
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                <FontAwesomeIcon
                  icon={['far', 'trash-can']}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              Delete {removeOptionLabel}?
            </h4>
            <p className="mb-0 font-size-lg text-muted">
              <FormControl component="fieldset" className="pr-4">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={removeOptionConfirm}
                        onChange={() =>
                          setRemoveOptionConfirm(!removeOptionConfirm)
                        }
                      />
                    }
                    label={`Delete ${removeOptionLabel} - ${
                      removeOptionItemLabel || removeOptionValue
                    }. This action can't be undone`}
                  />
                </FormGroup>
              </FormControl>
            </p>
            <div className="pt-4">
              <Button
                variant="text"
                onClick={handleCloseRemoveOption}
              >
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: '#f83145', color: 'white' }}
                onClick={() => {
                  const { activeOrg } = props.session.me
                  setLoader(true)
                  let newOptions = props.isAffiliate
                    ? cloneDeep(affiliate[removeOption]) || []
                    : cloneDeep(activeOrg[removeOption]) || []
                  if (removeOption === 'rehabTiers') {
                    const index = findIndex(newOptions, (n) => {
                      return (
                        n.value === removeOptionValue &&
                        (!removeOptionItemLabel ||
                          n.label === removeOptionItemLabel ||
                          n.title === removeOptionItemLabel)
                      )
                    })
                    if (index >= 0) {
                      // This should make it trigger between true and false with undefined being treated as true
                      // undefined = false == true = false
                      // false = true == true = true
                      // true = true == false = false
                      newOptions[index].active =
                        !isNil(newOptions[index].active) ==
                        !newOptions[index].active
                    }
                  } else {
                    pull(newOptions, removeOptionValue)
                    remove(
                      newOptions,
                      (n) =>
                        n.value === removeOptionValue &&
                        (!removeOptionItemLabel ||
                          n.label === removeOptionItemLabel ||
                          n.title === removeOptionItemLabel),
                    )
                  }
                  let organizationInput = {}
                  organizationInput[removeOption] = newOptions
                  if (props.isAffiliate) {
                    updateAffiliate({
                      variables: {
                        affiliateId: affiliate._id,
                        affiliateInput: organizationInput,
                      },
                      refetchQueries: [
                        {
                          query: GET_ME,
                        },
                        {
                          query: GET_AFFILIATE,
                          variables: {
                            memberValue: affiliate?.memberValue,
                          },
                        },
                      ],
                    })
                      .then(() => {
                        enqueueSnackbar(`Option has been removed`, {
                          variant: 'success',
                          autoHideDuration: 3000,
                        })
                        handleCloseRemoveOption()
                      })
                      .catch(() => {
                        enqueueSnackbar(
                          `Option has failed to be removed`,
                          {
                            variant: 'error',
                            autoHideDuration: 3000,
                          },
                        )
                        handleCloseRemoveOption()
                      })
                  } else {
                    updateOrganization({
                      variables: {
                        id: activeOrg._id,
                        organizationInput,
                      },
                      refetchQueries: [
                        {
                          query: GET_ME,
                        },
                      ],
                    })
                      .then(() => {
                        enqueueSnackbar(`Option has been removed`, {
                          variant: 'success',
                          autoHideDuration: 3000,
                        })
                        handleCloseRemoveOption()
                      })
                      .catch(() => {
                        enqueueSnackbar(
                          `Option has failed to be removed`,
                          {
                            variant: 'error',
                            autoHideDuration: 3000,
                          },
                        )
                        handleCloseRemoveOption()
                      })
                  }
                }}
                disabled={!removeOptionConfirm || loader}
                href={props.gotToPortfolio ? '/my-properties' : null}
              >
                <span className="btn-wrapper--label">Delete</span>
              </Button>
            </div>
          </div>
        </BlockUi>
      </Dialog>
    </>
  )
}

export default withSession(RemoveOptionComponent)
