/**
 * @ Author: Housefolios
 * @ Create Time: 2021-12-01 15:08:58
 * @ Modified by: David Helmick
 * @ Modified time: 2025-03-28 15:44:18
 * @ Description: Dialog for the editing of property reports
 */

import React, { Component, Fragment } from 'react'
import truncate from 'lodash/truncate'
import isNull from 'lodash/isNull'
import map from 'lodash/map'
import slice from 'lodash/slice'
import compact from 'lodash/compact'
import indexOf from 'lodash/indexOf'
import isEqual from 'lodash/isEqual'
import find from 'lodash/find'
import matchesProperty from 'lodash/matchesProperty'
import isEmpty from 'lodash/isEmpty'
import { Mutation, Query } from '@apollo/client/react/components'
import Avatar from '@mui/material/Avatar'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
//import { Link, LinkIcon, GetAppIcon } from '@mui/icons-material/'
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { makeStyles, withStyles } from '@mui/styles'
import clsx from 'clsx'
import GetAppIcon from '@mui/icons-material/GetApp'
import Dropzone from 'react-dropzone'
import loadImage from 'blueimp-load-image'
import PropertyPDF from './pdf'
import {
  TextValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator'
import { Check, Close } from '@mui/icons-material'
import withSession from '@/housefolios-components/Session/withSession'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GET_TEAM } from '@/housefolios-components/Team/queries'
import { IMAGE_UPLOAD } from '@/housefolios-components/TitleButtons/AddPropertyButton/mutations'
import { GET_PROPERTY } from '../queries'
import { UPDATE_PROPERTY } from '../mutations'
import ProformaLogoUploader from './proformaLogoUploader'
import { getStrategyName } from '@/utils/stratagies'
import { enqueueSnackbar } from 'notistack'

const MAXSIZE = 100000000 //bytes
const FILETYPES = ['image/*']

const INITIAL_STATE = {
  activeTab: 0,
  // steps: ['Pictures', 'Proforma Notes' /*'Pages (Coming Soon)'*/],
  circle: false,
  show: false,
  backDrop: false,
  legalDisclaimer: false,
}

class EditProforma extends Component {
  state = {
    show: false,
    proformaDescription:
      this.props.proformaDescription ||
      truncate(this.props.description, { length: 300 }) ||
      '',
    proformaImages: !isEmpty(this.props.proformaImages)
      ? this.props.proformaImages
      : map(slice(this.props.images, 0, 4), (image) => image.url),
    proformaImagePage: this.props.proformaImagePage,
    proformaLogo: this.props.proformaLogo,
    proformaFloorPlan: this.props.proformaFloorPlan,
    proformaRehabCost: !isNull(this.props.proformaRehabCost)
      ? this.props.proformaRehabCost
      : true,
    proformaHideAddress: !isNull(this.props.proformaHideAddress)
      ? this.props.proformaHideAddress
      : true,
    proformaContact: '',
    ...INITIAL_STATE,
    steps:
      this.props.session.me.activeOrg.southernImpressions &&
      this.props.type === 'marketing'
        ? ['Pictures', 'Logo', 'Floor Plan', 'Proforma Notes']
        : [
            'Pictures',
            'Logo',
            'Proforma Notes' /*'Pages (Coming Soon)'*/,
          ],
    file: this.props.property.proformaFile,
    tab: this.props?.session?.me?.activeOrg?.member ? 1 : 0,
    circle: false,
    backDrop: false,
    type: this.props.type || 'buyHold',
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.type !== prevProps.type) {
      this.setState({
        type: this.props.type || 'buyHold',
      })
    }
  }

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab })
  }

  // toggleModal = () => {
  //     const { show } = this.state
  //     this.setState({ show: !show })
  // }
  toggleModal = () => {
    this.setState((prevState) => {
      return {
        show: !prevState.show,
        proformaDescription:
          this.props.proformaDescription ||
          truncate(this.props.description, { length: 300 }) ||
          '',
        proformaImages: !isEmpty(this.props.proformaImages)
          ? this.props.proformaImages
          : map(slice(this.props.images, 0, 4), (image) => image.url),
        proformaImagePage: this.props.proformaImagePage,
        proformaLogo: this.props.proformaLogo,
        proformaFloorPlan: this.props.proformaFloorPlan,
        proformaRehabCost: !isNull(this.props.proformaRehabCost)
          ? this.props.proformaRehabCost
          : true,
        proformaHideAddress: !isNull(this.props.proformaHideAddress)
          ? this.props.proformaHideAddress
          : true,
      }
    })
  }

  handleCheck = (event) => {
    const { name = '' } = event.target
    let proformaRehabCost = this.state.proformaRehabCost
    let proformaHideAddress = this.state.proformaHideAddress
    let legalDisclaimer = this.state.legalDisclaimer
    let proformaImagePage = this.state.proformaImagePage

    if (name === 'proformaRehabCost') {
      proformaRehabCost = !this.state.proformaRehabCost
      this.setState({ proformaRehabCost })
    }
    if (name === 'hideAddress') {
      proformaHideAddress = !this.state.proformaHideAddress
      this.setState({ proformaHideAddress })
    }
    if (name === 'legalDisclaimer') {
      legalDisclaimer = !this.state.legalDisclaimer
      this.setState({ legalDisclaimer })
    }
    if (name === 'proformaImagePage') {
      proformaImagePage = !this.state.proformaImagePage
      this.setState({ proformaImagePage })
    }
  }

  handleImageUrl = async (
    imageUrl,
    imageArguments = 0.2,
    minSize = 100000,
    maxSize = 200000,
  ) => {
    if (imageUrl === '') return ''
    // let fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1)
    // if (startsWith(imageUrl, 'data:'))
    //   fileName = fileName.substring(0, 30)
    let dataURL
    let blob
    await this.getBase64ImageFromUrl(imageUrl)
      .then((result) => {
        dataURL = result[0]
        blob = result[1]
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar(
          `Failed to fetch image. If problem persists, contact support`,
          {
            variant: 'error',
            action: (key) => (
              <Fragment>
                <IconButton
                  onClick={() => {
                    this.props.closeSnackbar(key)
                  }}
                  size="large"
                >
                  <Close style={{ color: 'white' }} />
                </IconButton>
              </Fragment>
            ),
          },
        )
      })

    if (!blob) return null
    return dataURL

    // const fileType = dataURL.substring(
    //   dataURL.indexOf(':') + 1,
    //   dataURL.indexOf(';'),
    // )

    // // const preCompressionBase64String = split(dataURL, ',')[1]
    // // const preCompressionFileSize = parseInt(
    // //   preCompressionBase64String.replace(/=/g, '').length * 0.75,
    // // )

    // // if (preCompressionFileSize > MAXSIZE) {
    // //   return ''
    // // }
    // // if (!includes(FILETYPES, fileType)) {
    // //   return ''
    // // }

    // if (blob.size > maxSize) {
    //   const decrease = maxSize / blob.size
    //   await this.imageCompressFunction(
    //     dataURL,
    //     fileType,
    //     decrease,
    //     data => {
    //       dataURL = data
    //       fetch(dataURL)
    //         .then(res => res.blob())
    //         .then(res => (blob = res))
    //     },
    //   )
    // } else if (blob.size > minSize) {
    //   await this.imageCompressFunction(
    //     dataURL,
    //     fileType,
    //     imageArguments,
    //     data => {
    //       dataURL = data
    //       fetch(dataURL)
    //         .then(res => res.blob())
    //         .then(res => (blob = res))
    //     },
    //   )
    // }
    // const base64String = split(dataURL, ',')[1]
    // const fileSize = parseInt(
    //   base64String.replace(/=/g, '').length * 0.75,
    // )

    // const {
    //   data: { getSignedRequest },
    // } = await this.props.getSignedRequest({
    //   variables: {
    //     fileInput: {
    //       filename: fileName,
    //       filetype: fileType,
    //       collection: 'property',
    //     },
    //   },
    // })

    // const { signedRequest, url } = getSignedRequest

    // const options = {
    //   headers: {
    //     'Content-type': fileType,
    //   },
    // }
    // await axios.put(signedRequest, blob, options)

    // return {
    //   filename: fileName,
    //   filetype: fileType,
    //   filesize: fileSize,
    //   url: url,
    // }
  }

  getBase64ImageFromUrl = async (imageUrl) => {
    var res = await fetch(imageUrl)
    var blob = await res.blob()

    return new Promise((resolve, reject) => {
      var reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve([reader.result, blob])
        },
        false,
      )

      reader.onerror = () => {
        return reject(this)
      }
      reader.readAsDataURL(blob)
    })
  }

  imageCompressFunction = async (
    dataUrl,
    imageType,
    imageArguments,
    resolve,
  ) => {
    var image, canvas, ctx, newDataUrl

    new Promise(function (resolve) {
      image = new Image()
      image.src = dataUrl
      resolve('Done : ')
    }).then((d) => {
      canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      ctx = canvas.getContext('2d')
      ctx.drawImage(image, 0, 0, image.width, image.height)
      //log(ctx);
      newDataUrl = canvas.toDataURL(imageType, imageArguments)
      resolve(newDataUrl)
    })
  }

  readMultiFiles = (files) => {
    return Promise.all(
      map(files, (file) => {
        return new Promise((resolve, reject) => {
          loadImage(
            file,
            (img) => {
              resolve(img.toDataURL(file.type))
            },
            {
              orientation: true,
              canvas: true,
            },
          )
        })
      }),
    )
  }

  onDrop = (imageUpload) => (files, rejectedFiles) => {
    //const { addImages } = this.props
    // const { circle } = this.state
    if (files.length <= 0) {
      this.setState({ error: 'upload a valid file' })
    } else {
      this.setState({ circle: true })
      this.readMultiFiles(files).then(async (results) => {
        for (let i = 0; i < results.length; i++) {
          results[i] = await this.handleImageUrl(results[i])
        }
        let images = compact(results)
        imageUpload({
          variables: {
            documentId: this.props.propertyId,
            imageUrls: images,
            collection: 'property',
          },
          refetchQueries: [
            {
              query: GET_PROPERTY,
              variables: { id: this.props.propertyId },
            },
          ],
        }).then(({ data }) => {
          this.setState({ circle: false })
        })
      })
    }
  }

  clickImage = (image) => {
    let { proformaImages } = this.state

    const check = indexOf(proformaImages, image)
    if (check === -1 && proformaImages.length < 4)
      proformaImages.push(image)
    else if (check !== -1)
      proformaImages = proformaImages.filter(
        (_, index) => index !== check,
      )
    this.setState({ proformaImages: proformaImages })
  }

  clickImageLogo = (image) => {
    let { proformaLogo } = this.state
    if (!isEqual(proformaLogo, image)) proformaLogo = image
    else proformaLogo = null
    this.setState({ proformaLogo: proformaLogo })
  }

  clickImageFloorPlan = (image) => {
    let { proformaFloorPlan } = this.state
    if (!isEqual(proformaFloorPlan, image)) proformaFloorPlan = image
    else proformaFloorPlan = null
    this.setState({ proformaFloorPlan: proformaFloorPlan })
  }

  handleBackDrop = (updateProperty) => {
    this.setState({
      backDrop: true,
    })
    // updateProperty().then(async ({ data }) => {
    //   //this.handleClick()
    // })
  }

  handleClick = () => {
    this.setState({ ...INITIAL_STATE })
  }

  CustomConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg, #56ccf2 0%, #2f80ed 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg, #56ccf2 0%, #2f80ed 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
  })(StepConnector)

  useCustomStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( 136deg, #56ccf2 0%, #2f80ed 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
        'linear-gradient( 136deg, #56ccf2 0%, #2f80ed 100%)',
    },
  })

  CustomStepIcon = (props) => {
    const classes = this.useCustomStepIconStyles()
    const { active, completed } = props

    const icons = {
      1: <i className={'fas fa-arrow-up'} />,
      2: <i className={'fas fa-columns'} />,
      3: <i className={'fas fa-flag'} />,
    }

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    )
  }

  onChange = (event) => {
    const { /*name = '',*/ value = '' /*type = ''*/ } = event.target
    let newValue = value
    let proformaDescription = this.state.proformaDescription
    proformaDescription = newValue
    this.setState({ proformaDescription })
  }

  onChangeContact = (event) => {
    const { name = '', value = '' } = event.target
    if (
      this.props.session.me.activeOrg.southernImpressions &&
      name === 'type'
    ) {
      if (value === 'marketing')
        this.setState((prevState) => {
          return {
            steps: [
              'Pictures',
              'Logo',
              'Floor Plan',
              'Proforma Notes',
            ],
            activeTab:
              prevState.activeTab < 4 ? prevState.activeTab : 3,
          }
        })
      else
        this.setState((prevState) => {
          return {
            steps: ['Pictures', 'Logo', 'Proforma Notes'],
            activeTab:
              prevState.activeTab < 3 ? prevState.activeTab : 2,
          }
        })
    }
    this.setState({ [name]: value })
  }

  handleChange = (event, newValue) => {
    this.setState({ tab: newValue })
  }

  uploadFile = (newFile) => {
    this.setState({ file: newFile })
  }

  deleteFile = () => {
    this.setState({ file: {} })
  }

  saveUpload = (updateProperty) => {
    this.setState({ circle: true })
    updateProperty().then((data) => {
      this.handleClick()
    })
  }

  render() {
    const { images, propertyId, property } = this.props

    const { type } = this.state

    const { proformaLogos } = this.props.session.me.activeOrg

    const pdfProperty = {
      _id: property._id,
      address: property.address,
      city: property.city,
      state: property.state,
      zip: property.zip,
      beds: property.beds,
      baths: property.baths,
      sqft: property.sqft,
      year: property.year,
      proformaDescription: property.proformaDescription,
      proformaImages: property.proformaImages,
      proformaImagePage: property.proformaImagePage,
      proformaLogo: property.proformaLogo,
      proformaFloorPlan: property.proformaFloorPlan,
      proformaRehabCost: property.proformaRehabCost,
      proformaHideAddress: property.proformaHideAddress,
      proformaContact: property.proformaContact,
      portfolio: property.portfolio,
      assumptions: property.assumptions,
      acquisition: property.acquisition,
    }

    const uploadPics =
      undefined !== images && images.length <= 0 ? true : false
    const {
      proformaImages,
      proformaImagePage,
      proformaLogo,
      proformaFloorPlan,
      proformaRehabCost,
      proformaHideAddress,
      proformaDescription,
      proformaContact,
      circle,
      backDrop,
      legalDisclaimer = false,
      tab,
    } = this.state
    const disabled = proformaDescription
      ? proformaDescription.length > 300 ||
        !legalDisclaimer ||
        !proformaContact
        ? true
        : false
      : true

    let imageCheck = map(proformaImages, (image) => {
      for (let i = 0; i < images.length; i++)
        if (images[i].url === image) return image
    })
    imageCheck = compact(imageCheck)
    if (!isEqual(proformaImages, imageCheck))
      this.setState({ proformaImages: imageCheck })
    const continueDisabled = proformaImages.length > 0 ? false : true
    const { activeTab, steps } = this.state

    return (
      <div onClick={(event) => event.stopPropagation()}>
        <ListItem button onClick={this.toggleModal}>
          <div className="nav-link-icon">
            <FontAwesomeIcon icon={['far', 'file-lines']} />
          </div>
          <span>Edit Proforma</span>
        </ListItem>

        <Dialog
          open={this.state.show}
          onClose={this.toggleModal}
          // dialogclassname="modal-proforma"
          fullWidth={true}
          maxWidth="md"
          onKeyDown={(event) => event.stopPropagation()}
        >
          {/* <Tabs value={tab} onChange={this.handleChange}>
            <Tab label='Create Proforma' disabled={this.props?.session?.me?.activeOrg?.member} />
            <Tab label='Upload Proforma' />
          </Tabs> */}
          <DialogTitle style={{ maxHeight: 250 }}>
            {type === 'buyHold' ? (
              <DialogTitle>
                {getStrategyName(
                  'Buy & Hold',
                  this.props?.session?.me?.activeOrg?.member,
                )}{' '}
                Proforma
              </DialogTitle>
            ) : type === 'fixFlip' ? (
              <DialogTitle>
                {getStrategyName(
                  'Fix & Flip',
                  this.props?.session?.me?.activeOrg?.member,
                )}{' '}
                Proforma
              </DialogTitle>
            ) : type === 'shortTermRental' ? (
              <DialogTitle>
                {getStrategyName(
                  'Short Term Rental',
                  this.props?.session?.me?.activeOrg?.member,
                )}{' '}
                Proforma
              </DialogTitle>
            ) : type === 'marketing' ? (
              <DialogTitle>
                {getStrategyName(
                  'Turnkey: End Buyer',
                  this.props?.session?.me?.activeOrg?.member,
                )}{' '}
                Proforma
              </DialogTitle>
            ) : (
              <DialogTitle>
                {getStrategyName(
                  'Wholesale',
                  this.props?.session?.me?.activeOrg?.member,
                )}
              </DialogTitle>
            )}
            <Stepper
              activeStep={activeTab}
              color="primary"
              style={{ borderTop: 'transparent', padding: 24 }}
              //style={{ width: '75%' }}
            >
              {map(steps, (label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </DialogTitle>
          <DialogContent>
            <Mutation
              mutation={IMAGE_UPLOAD}
              refetchQueries={[
                {
                  query: GET_PROPERTY,
                  variables: { id: propertyId },
                },
              ]}
            >
              {(imageUpload, { data, loading, error }) => (
                <DialogContent
                  onClick={(event) => event.stopPropagation()}
                >
                  <Grid container>
                    <Grid item sm={12}>
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth={true}
                      >
                        <InputLabel>{'Proforma type:'}</InputLabel>
                        <Select
                          name="type"
                          className="form-control"
                          value={type}
                          onChange={(event) =>
                            this.onChangeContact(event)
                          }
                          label="Proforma type:"
                          inputProps={{
                            name: 'type',
                          }}
                        >
                          <MenuItem key="buyhold" value={'buyHold'}>
                            <ListItemText
                              primary={getStrategyName(
                                'Buy & Hold',
                                this.props?.session?.me?.activeOrg
                                  ?.member,
                              )}
                            />
                          </MenuItem>
                          <MenuItem key="fixFlip" value={'fixFlip'}>
                            <ListItemText
                              primary={getStrategyName(
                                'Fix & Flip',
                                this.props?.session?.me?.activeOrg
                                  ?.member,
                              )}
                            />
                          </MenuItem>
                          <MenuItem
                            key="marketing"
                            value={'marketing'}
                          >
                            <ListItemText
                              primary={
                                this.props.session.me.activeOrg
                                  .southernImpressions
                                  ? getStrategyName(
                                      'Build to Rent',
                                      this.props?.session?.me
                                        ?.activeOrg?.member,
                                    )
                                  : 'End Buyer'
                              }
                            />
                          </MenuItem>
                          <MenuItem
                            key="shortTermRental"
                            value={'shortTermRental'}
                          >
                            <ListItemText
                              primary={getStrategyName(
                                'Short Term Rental',
                                this.props?.session?.me?.activeOrg
                                  ?.member,
                              )}
                            />
                          </MenuItem>
                          {this.props?.session?.me?.activeOrg
                            ?.member !== 'astroflip' && (
                            <MenuItem
                              key="wholesale"
                              value={'wholesale'}
                            >
                              <ListItemText
                                primary={getStrategyName(
                                  'Wholesale',
                                  this.props?.session?.me?.activeOrg
                                    ?.member,
                                )}
                              />
                            </MenuItem>
                          )}
                          {this.props?.session?.me?.activeOrg
                            ?.member === 'astroflip' && (
                            <MenuItem
                              key="astroflip"
                              value={'astroflip'}
                            >
                              <ListItemText
                                primary={getStrategyName(
                                  'Astroflip',
                                  this.props?.session?.me?.activeOrg
                                    ?.member,
                                )}
                              />
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {steps[activeTab] === 'Pictures' &&
                        (!uploadPics ? (
                          <Grid container>
                            <Grid item sm={12}>
                              <h3>Select Proforma Display Images</h3>
                            </Grid>
                            <Grid item sm={12} md={7}>
                              <Card
                                text="black"
                                style={{
                                  width: '100%',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  backgroundColor: 'white',
                                  height: 450,
                                  overflowY: 'scroll',
                                  boxShadow: 'none',
                                }}
                              >
                                <CardContent>
                                  {map(images, (url) => {
                                    const image = url.url
                                    const check = indexOf(
                                      proformaImages,
                                      image,
                                    )
                                    if (check === -1)
                                      return (
                                        <span
                                          style={{
                                            position: 'relative',
                                            height: '110px',
                                            width: '160px',
                                            display: 'inline-block',
                                          }}
                                          key={image}
                                        >
                                          <div
                                            className="blur-load"
                                            style={{
                                              backgroundImage:
                                                'url(' +
                                                url.url_small +
                                                ')',
                                              backgroundSize:
                                                '150px 100px',
                                              height: '110px',
                                              width: '160px',
                                            }}
                                          >
                                            <img
                                              src={image}
                                              key={image}
                                              alt=""
                                              loading="lazy"
                                              style={{
                                                width: '150px',
                                                height: '100px',
                                                margin: '5px',
                                              }}
                                              onClick={() =>
                                                this.clickImage(image)
                                              }
                                            />
                                          </div>
                                        </span>
                                      )
                                    else
                                      return (
                                        <span
                                          style={{
                                            position: 'relative',
                                            height: '110px',
                                            width: '160px',
                                            display: 'inline-block',
                                          }}
                                          key={image}
                                        >
                                          <div
                                            className="blur-load"
                                            style={{
                                              backgroundImage:
                                                'url(' +
                                                url.url_small +
                                                ')',
                                              backgroundSize:
                                                '150px 100px',
                                              height: '110px',
                                              width: '160px',
                                            }}
                                          >
                                            <img
                                              src={image}
                                              key={image}
                                              alt=""
                                              style={{
                                                position: 'relative',
                                                width: '150px',
                                                height: '100px',
                                                margin: '5px',
                                              }}
                                              loading="lazy"
                                            />
                                          </div>
                                          <span
                                            onClick={() =>
                                              this.clickImage(image)
                                            }
                                            style={{
                                              position: 'absolute',
                                              right: 0,
                                              bottom: 0,
                                              margin: '5px',
                                              fontSize: '50px',
                                              textAlign: 'center',
                                              width: '150px',
                                              height: '100px',
                                              color: 'white',
                                              backgroundColor:
                                                'black',
                                              opacity: 0.4,
                                            }}
                                          >
                                            <span
                                              style={{
                                                opacity: 1,
                                              }}
                                            >
                                              {check + 1}
                                            </span>
                                          </span>
                                        </span>
                                      )
                                  })}
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid item sm={12} md={5}>
                              <img
                                src="/images/proforma_image.png"
                                alt=""
                                style={{
                                  boxShadow: '0 4px 10px 0 #bbbbbb',
                                  width: '100%',
                                }}
                              />
                              <FormControlLabel
                                key="proformaImagePage"
                                control={
                                  <Checkbox
                                    name="proformaImagePage"
                                    type="checkbox"
                                    required={true}
                                    value={
                                      this.state.proformaImagePage
                                    }
                                    icon={
                                      <CheckBoxOutlineBlankIcon fontSize="large" />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon
                                        fontSize="large"
                                        style={{ color: '#2296F3' }}
                                      />
                                    }
                                    checked={
                                      this.state.proformaImagePage
                                    }
                                    onChange={(event) =>
                                      this.handleCheck(event)
                                    }
                                    color="primary"
                                    style={{ marginTop: 'auto' }}
                                  />
                                }
                                label="Include all property images on the end of the report"
                              ></FormControlLabel>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container>
                            <Grid item sm={12}>
                              <h3>Select Proforma Display Images</h3>
                            </Grid>
                            <Grid item sm={12} md={6}>
                              <Dropzone
                                onDrop={this.onDrop(imageUpload)}
                                accept={FILETYPES}
                                maxSize={MAXSIZE}
                                multiple={true}
                              >
                                {({
                                  getRootProps,
                                  getInputProps,
                                }) => (
                                  <section style={{ padding: 0 }}>
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <Card
                                        style={{
                                          boxShadow: 'none',
                                        }}
                                      >
                                        <CardContent>
                                          <div>
                                            <Avatar
                                              sizes="75px"
                                              style={{
                                                fontSize: '75px',
                                                width: '160px',
                                                height: '160px',
                                                margin: '10px',
                                                marginLeft: '120px',
                                                backgroundColor:
                                                  '#e9e9e9',
                                                alignSelf: 'center',
                                              }}
                                            >
                                              <GetAppIcon
                                                style={{
                                                  color: '#949494',
                                                }}
                                                fontSize="inherit"
                                                className="icon-flipped"
                                              />
                                            </Avatar>
                                            <div
                                              style={{
                                                fontSize: '22px',
                                                color: 'grey',
                                              }}
                                            >
                                              Drag and drop a file you
                                              want to upload
                                            </div>
                                          </div>
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            style={{
                                              margin: 10,
                                              marginLeft: 140,
                                            }}
                                          >
                                            Select Files
                                          </Button>
                                          {circle && (
                                            <div>
                                              {/* <Grid
                                              container
                                              justifyContent="center"
                                            >
                                              <Grid item> */}
                                              <CircularProgress
                                                style={{
                                                  marginLeft: '44%',
                                                  marginTop: 10,
                                                  marginBottom: 10,
                                                }}
                                              />
                                              {/* </Grid>
                                            </Grid> */}
                                            </div>
                                          )}
                                        </CardContent>
                                      </Card>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            </Grid>
                            <Grid item sm={12} md={6}>
                              <img
                                src="/images/proforma_image.png"
                                alt=""
                                style={{
                                  border: '2px',
                                  borderColor: 'grey',
                                  borderStyle: 'solid',
                                }}
                              />
                              <FormControlLabel
                                key="proformaImagePage"
                                control={
                                  <Checkbox
                                    name="proformaImagePage"
                                    type="checkbox"
                                    value={
                                      this.state.proformaImagePage
                                    }
                                    icon={
                                      <CheckBoxOutlineBlankIcon fontSize="large" />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon
                                        fontSize="large"
                                        style={{ color: '#2296F3' }}
                                      />
                                    }
                                    checked={
                                      this.state.proformaImagePage
                                    }
                                    onChange={(event) =>
                                      this.handleCheck(event)
                                    }
                                    color="primary"
                                    style={{ marginTop: 'auto' }}
                                  />
                                }
                                label="Include all property images on the end of the report"
                              ></FormControlLabel>
                            </Grid>
                          </Grid>
                        ))}
                      {steps[activeTab] === 'Logo' && (
                        <Grid container>
                          <Grid item sm={12}>
                            <h3>Select Logo for Proforma</h3>
                          </Grid>
                          <Grid item sm={12}>
                            <p>
                              Defaults to Organization Logo if none
                              are selected
                            </p>
                          </Grid>
                          <Grid item sm={12}>
                            <ProformaLogoUploader />
                          </Grid>
                          <Grid item sm={12}>
                            <Card
                              text="black"
                              style={{
                                width: '100%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                backgroundColor: 'white',
                                height: 450,
                                overflowY: 'scroll',
                                boxShadow: 'none',
                              }}
                            >
                              <CardContent>
                                {map(proformaLogos, (url) => {
                                  const image = url.url
                                  const check = proformaLogo === image
                                  if (!check)
                                    return (
                                      <span
                                        style={{
                                          position: 'relative',
                                          height: '110px',
                                          width: '260px',
                                          display: 'inline-block',
                                        }}
                                        key={image}
                                      >
                                        <div
                                          className="blur-load"
                                          style={{
                                            backgroundImage:
                                              'url(' +
                                              url.url_small +
                                              ')',
                                            backgroundSize:
                                              '250px 100px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '110px',
                                            width: '260px',
                                          }}
                                        >
                                          <img
                                            src={image}
                                            key={image}
                                            alt=""
                                            style={{
                                              width: '250px',
                                              height: '100px',
                                              margin: '5px',
                                            }}
                                            onClick={() =>
                                              this.clickImageLogo(
                                                image,
                                              )
                                            }
                                            loading="lazy"
                                          />
                                        </div>
                                      </span>
                                    )
                                  else
                                    return (
                                      <span
                                        style={{
                                          position: 'relative',
                                          height: '110px',
                                          width: '260px',
                                          display: 'inline-block',
                                        }}
                                        key={image}
                                      >
                                        <div
                                          className="blur-load"
                                          style={{
                                            backgroundImage:
                                              'url(' +
                                              url.url_small +
                                              ')',
                                            backgroundSize:
                                              '250px 100px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '110px',
                                            width: '260px',
                                          }}
                                        >
                                          <img
                                            src={image}
                                            key={image}
                                            alt=""
                                            style={{
                                              position: 'relative',
                                              width: '250px',
                                              height: '100px',
                                              margin: '5px',
                                            }}
                                            onClick={() =>
                                              this.clickImageLogo(
                                                image,
                                              )
                                            }
                                            loading="lazy"
                                          />
                                        </div>
                                        <span
                                          onClick={() =>
                                            this.clickImageLogo(image)
                                          }
                                          style={{
                                            position: 'absolute',
                                            right: 0,
                                            bottom: 0,
                                            margin: '5px',
                                            fontSize: '50px',
                                            textAlign: 'center',
                                            width: '250px',
                                            height: '100px',
                                            color: 'white',
                                            backgroundColor: 'black',
                                            opacity: 0.4,
                                          }}
                                        >
                                          <span
                                            style={{
                                              opacity: 1,
                                            }}
                                          >
                                            <Check
                                              style={{
                                                width: 50,
                                                height: 50,
                                              }}
                                            />
                                          </span>
                                        </span>
                                      </span>
                                    )
                                })}
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      )}
                      {steps[activeTab] === 'Floor Plan' && (
                        <Grid container>
                          <Grid item sm={12}>
                            <h3>Select Floor Plan Image</h3>
                          </Grid>
                          <Grid item sm={12}>
                            <Card
                              text="black"
                              style={{
                                width: '100%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                backgroundColor: 'white',
                                height: 450,
                                overflowY: 'scroll',
                                boxShadow: 'none',
                              }}
                            >
                              <CardContent>
                                {map(images, (url) => {
                                  const image = url.url
                                  const check =
                                    proformaFloorPlan === image
                                  if (!check)
                                    return (
                                      <span
                                        style={{
                                          position: 'relative',
                                          width: '160px',
                                          height: '110px',
                                          display: 'inline-block',
                                        }}
                                        key={image}
                                      >
                                        <div
                                          className="blur-load"
                                          style={{
                                            backgroundImage:
                                              'url(' +
                                              url.url_small +
                                              ')',
                                            backgroundSize:
                                              '150px 100px',
                                            display: 'flex',
                                            width: '160px',
                                            height: '110px',
                                          }}
                                        >
                                          <img
                                            src={image}
                                            key={image}
                                            alt=""
                                            style={{
                                              width: '150px',
                                              height: '100px',
                                              margin: '5px',
                                            }}
                                            onClick={() =>
                                              this.clickImageFloorPlan(
                                                image,
                                              )
                                            }
                                            loading="lazy"
                                          />
                                        </div>
                                      </span>
                                    )
                                  else
                                    return (
                                      <span
                                        style={{
                                          position: 'relative',
                                          width: '160px',
                                          height: '110px',
                                          display: 'inline-block',
                                        }}
                                        key={image}
                                      >
                                        <div
                                          className="blur-load"
                                          style={{
                                            backgroundImage:
                                              'url(' +
                                              url.url_small +
                                              ')',
                                            backgroundSize:
                                              '150px 100px',
                                            display: 'flex',
                                            width: '160px',
                                            height: '110px',
                                          }}
                                        >
                                          <img
                                            src={image}
                                            key={image}
                                            alt=""
                                            style={{
                                              position: 'relative',
                                              width: '150px',
                                              height: '100px',
                                              margin: '5px',
                                            }}
                                            loading="lazy"
                                          />
                                        </div>
                                        <span
                                          onClick={() =>
                                            this.clickImageFloorPlan(
                                              image,
                                            )
                                          }
                                          style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            margin: '5px',
                                            fontSize: '50px',
                                            textAlign: 'center',
                                            width: '150px',
                                            height: '100px',
                                            color: 'white',
                                            backgroundColor: 'black',
                                            opacity: 0.4,
                                          }}
                                        >
                                          <span
                                            style={{
                                              opacity: 1,
                                            }}
                                          >
                                            {1}
                                          </span>
                                        </span>
                                      </span>
                                    )
                                })}
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      )}
                      {steps[activeTab] === 'Proforma Notes' && (
                        <ValidatorForm
                          style={{
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Grid container direction="column">
                            <Grid item>
                              <h3>Proforma Notes</h3>
                            </Grid>
                            <Grid item sm={12}>
                              <TextValidator
                                key="proformaDescriptionKey"
                                name="proformaDescription"
                                label="Description*"
                                className="form-control"
                                fullWidth={true}
                                autoFocus
                                value={proformaDescription}
                                multiline
                                rows="12"
                                onChange={(event) =>
                                  this.onChange(event)
                                }
                                onBlur={(event) =>
                                  this.onChange(event)
                                }
                                validators={[
                                  'required',
                                  'maxStringLength: 300',
                                ]}
                                errorMessages={[
                                  'This field is required',
                                  'You cannot exceed 300 characters',
                                ]}
                                margin="dense"
                                variant="outlined"
                              />
                              <Grid item sm={12}>
                                <Query query={GET_TEAM}>
                                  {({
                                    loading,
                                    error,
                                    refetch,
                                    data,
                                  }) => {
                                    if (loading) return 'Loading...'
                                    if (error)
                                      return `Error! ${error.message}`
                                    const { teamMembers = [] } = data
                                    const orgCreator = find(
                                      teamMembers,
                                      matchesProperty(
                                        '_id',
                                        this.props.session.me
                                          .activeOrg.createdBy._id,
                                      ),
                                    )
                                    if (isEmpty(proformaContact))
                                      this.setState({
                                        proformaContact:
                                          orgCreator._id,
                                      })
                                    return (
                                      <FormControl
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth={true}
                                      >
                                        <InputLabel>
                                          {
                                            'Contact Info on Proforma:'
                                          }
                                        </InputLabel>
                                        <Select
                                          name="proformaContact"
                                          className="form-control"
                                          value={proformaContact}
                                          onChange={(event) =>
                                            this.onChangeContact(
                                              event,
                                            )
                                          }
                                          renderValue={(value) => (
                                            <Typography>
                                              {find(
                                                teamMembers,
                                                (member) =>
                                                  member._id ===
                                                  value,
                                              )
                                                ? `${
                                                    find(
                                                      teamMembers,
                                                      (member) =>
                                                        member._id ===
                                                        value,
                                                    ).profile
                                                      .firstname
                                                  } ${
                                                    find(
                                                      teamMembers,
                                                      (member) =>
                                                        member._id ===
                                                        value,
                                                    ).profile.lastname
                                                  }`
                                                : ''}
                                            </Typography>
                                          )}
                                          label="Contact Info on Proforma:"
                                          inputProps={{
                                            name: 'proformaContact',
                                          }}
                                        >
                                          {map(
                                            teamMembers,
                                            (
                                              member, //replace rehab with users
                                            ) => (
                                              <MenuItem
                                                value={member._id}
                                              >
                                                <ListItemText
                                                  primary={`${member.profile.firstname} ${member.profile.lastname}`}
                                                  secondary={`${member.email} | ${member.phone}`}
                                                />
                                              </MenuItem>
                                            ),
                                          )}
                                        </Select>
                                      </FormControl>
                                    )
                                  }}
                                </Query>
                              </Grid>
                              <Grid item sm={12}>
                                {/* <FormControlLabel
                            <h3>Proforma Notes</h3>
                          </Grid>
                          <Grid item sm={7}>
                            <TextValidator
                              key="proformaDescriptionKey"
                              name="proformaDescription"
                              label="Description*"
                              className="form-control"
                              fullWidth={true}
                              value={description}
                              multiline
                              rows="12"
                              onChange={event => this.onChange(event)}
                              onBlur={event => this.onChange(event)}
                              validators={['required', 'maxStringLength: 300']}
                              errorMessages={['This field is required', 'You cannot exceed 300 characters']}
                              margin="dense"
                              variant="outlined"
                            />
                            <Grid
                              item
                              sm={12}
                              //style={{ marginTop: 200 }}
                            >
                              {/* <FormControlLabel
                                key="proformaRehabCostKey"
                                control={
                                  <Checkbox
                                    name="proformaRehabCost"
                                    type="checkbox"
                                    value={this.state.proformaRehabCost}
                                    checked={
                                      this.state.proformaRehabCost
                                    }
                                    onChange={event =>
                                      this.handleCheck(event)
                                    }
                                    color="primary"
                                    style={{ marginTop: 'auto' }}
                                  />
                                }
                                label="Include Estimated Rehab Cost in Proforma"
                              ></FormControlLabel> */}
                                {type === 'marketing' && (
                                  <FormControlLabel
                                    key="hideAddressKey"
                                    control={
                                      <Checkbox
                                        name="hideAddress"
                                        type="checkbox"
                                        value={
                                          this.state
                                            .proformaHideAddress
                                        }
                                        icon={
                                          <CheckBoxOutlineBlankIcon fontSize="large" />
                                        }
                                        checkedIcon={
                                          <CheckBoxIcon
                                            fontSize="large"
                                            style={{
                                              color: '#2296F3',
                                            }}
                                          />
                                        }
                                        checked={
                                          this.state
                                            .proformaHideAddress
                                        }
                                        onChange={(event) =>
                                          this.handleCheck(event)
                                        }
                                        color="primary"
                                        style={{ marginTop: 'auto' }}
                                      />
                                    }
                                    label="Hide Address in Turnkey: End Buyer Proforma"
                                  ></FormControlLabel>
                                )}
                              </Grid>
                              <Grid item>
                                <FormControlLabel
                                  key="legalDisclaimer"
                                  style={{ margin: 0 }}
                                  control={
                                    <Checkbox
                                      name="legalDisclaimer"
                                      type="checkbox"
                                      required={true}
                                      value={
                                        this.state.legalDisclaimer
                                      }
                                      icon={
                                        <CheckBoxOutlineBlankIcon fontSize="large" />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon
                                          fontSize="large"
                                          style={{ color: '#2296F3' }}
                                        />
                                      }
                                      checked={
                                        this.state.legalDisclaimer
                                      }
                                      onChange={(event) =>
                                        this.handleCheck(event)
                                      }
                                      validators={['required']}
                                      errorMessages={[
                                        'This field is required',
                                      ]}
                                      color="primary"
                                      style={{ marginTop: 'auto' }}
                                    />
                                  }
                                  label="By creating this proforma, I represent and warrant that I am the creator and owner of the photos, or have the necessary licenses, rights, consents, and permissions to use the selected photos for marketing purposes."
                                ></FormControlLabel>
                              </Grid>
                            </Grid>
                          </Grid>
                          {backDrop && (
                            <Backdrop
                              open={this.state.backDrop}
                              style={{
                                color: '#fff',
                                zIndex: 100000,
                              }}
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          )}
                        </ValidatorForm>
                      )}
                    </Grid>
                  </Grid>
                </DialogContent>
              )}
            </Mutation>
          </DialogContent>
          <DialogActions>
            <Mutation
              mutation={UPDATE_PROPERTY}
              variables={{
                id: propertyId,
                propertyInput: {
                  proformaDescription,
                  proformaImages,
                  proformaImagePage,
                  proformaLogo,
                  proformaFloorPlan,
                  proformaRehabCost,
                  proformaHideAddress,
                  proformaContact,
                },
              }}
              refetchQueries={[
                {
                  query: GET_PROPERTY,
                  variables: { id: propertyId },
                },
              ]}
            >
              {(updateProperty, { data, loading, error }) => (
                <div>
                  {activeTab !== 0 && (
                    <>
                      <Button
                        onClick={(event) => {
                          this.setActiveTab(activeTab - 1)
                          event.stopPropagation()
                        }}
                        className="mx-2"
                      >
                        Back
                      </Button>
                    </>
                  )}
                  {activeTab !== steps.length - 1 && (
                    <>
                      <Button
                        onClick={(event) => {
                          this.setActiveTab(activeTab + 1)
                          event.stopPropagation()
                        }}
                        variant="contained"
                        color="primary"
                        disabled={continueDisabled}
                        className="mx-2"
                      >
                        Continue
                      </Button>
                    </>
                  )}
                  {activeTab === steps.length - 1 && (
                    <>
                      <PropertyPDF
                        property={pdfProperty}
                        propertyId={propertyId}
                        editProforma={true}
                        type={type || 'marketing'}
                        handleBackDrop={this.handleBackDrop}
                        updateProperty={updateProperty}
                        handleClick={this.handleClick}
                        disabled={disabled}
                      />
                    </>
                  )}
                </div>
              )}
            </Mutation>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withSession(EditProforma)
