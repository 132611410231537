/**
 * @ Author: Housefolios
 * @ Create Time: 2022-04-27 09:40:50
 * @ Modified by: David Helmick
 * @ Modified time: 2022-12-15 10:36:41
 * @ Description: Dialog to unshare a property or selected properties
 */

import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItem,
  Tooltip,
} from '@mui/material'

import map from 'lodash/map'
import concat from 'lodash/concat'

import { enqueueSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import { GET_SHARED_PROPERTIES } from '../queries'
import { REMOVE_SHARED_PROPERTIES } from '../mutation'
import { GET_ME } from '@/housefolios-components/Session/queries'
import withSession from '@/housefolios-components/Session/withSession'
import BlockUi from 'react-block-ui'
import { PacmanLoader } from 'react-spinners'
import { GET_ORGS_PROPERTY_SHARED_WITH } from '@/housefolios-components/Property/queries'

function RemoveSharedPropertiesComponent(props) {
  const { bulkActionsNotifications, rightClickMenu, shareAccess } =
    props
  const [loader, setLoader] = useState(false)
  const [openRemove, setOpenRemove] = useState(false)

  const toggleOpen = () => {
    setOpenRemove(!openRemove)
  }

  const [removeSharedProperties] = useMutation(
    REMOVE_SHARED_PROPERTIES,
  )

  const handleRemove = () => {
    setLoader(true)
    removeSharedProperties({
      variables: {
        propertyIds: props.selected,
        orgId: props.orgId,
      },
      refetchQueries: concat(
        [
          {
            query: GET_SHARED_PROPERTIES,
            variables: {},
          },
          {
            query: GET_ME,
          },
        ],
        map(props.selected, (propertyId) => {
          return {
            query: GET_ORGS_PROPERTY_SHARED_WITH,
            variables: {
              propertyId,
            },
          }
        }),
      ),
    })
      .then(() => {
        enqueueSnackbar(`Selected Properties Removed`, {
          variant: 'success',
          autoHideDuration: 3000,
        })
        if (props.setSelected) props.setSelected([])
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to Remove Selected Properties`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      })
      .finally(() => {
        setLoader(false)
        toggleOpen()
      })
  }

  const isRecipient = props.orgId === props.session.me.activeOrg._id

  return (
    <>
      {bulkActionsNotifications && (
        <Tooltip title="Bulk Remove">
          <IconButton
            onClick={toggleOpen}
            disabled={loader}
            size="large"
          >
            <FontAwesomeIcon
              icon={['far', 'trash-can']}
              style={{ color: 'white' }}
            />
          </IconButton>
        </Tooltip>
      )}
      {rightClickMenu && (
        <ListItem button onClick={toggleOpen} disabled={loader}>
          <div className="nav-link-icon">
            <FontAwesomeIcon icon={['far', 'trash']} />
          </div>
          <span>Remove Properties</span>
        </ListItem>
      )}
      {shareAccess && (
        <IconButton onClick={toggleOpen} size="large">
          <FontAwesomeIcon icon={['far', 'circle-xmark']} />
        </IconButton>
      )}
      <Dialog
        open={openRemove}
        onClose={toggleOpen}
        maxWidth="sm"
        fullWidth={true}
        scroll="body"
      >
        <DialogTitle>
          {isRecipient ? 'Remove Shared Properties' : 'Remove Access'}
        </DialogTitle>
        <BlockUi
          blocking={loader}
          loader={
            <PacmanLoader color={'var(--primary)'} loading={true} />
          }
          message={
            <div className="text-primary">
              Removing Shared Properties
            </div>
          }
        >
          <DialogContent>
            <DialogContentText>
              {isRecipient
                ? 'Are you sure you want to remove this? You will lose access to these shared properties.'
                : 'Are you sure you want to remove access to these properties for this user?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={toggleOpen}>
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: '#f83145', color: 'white' }}
              onClick={handleRemove}
            >
              Remove
            </Button>
          </DialogActions>
        </BlockUi>
      </Dialog>
    </>
  )
}

export default withSession(RemoveSharedPropertiesComponent)
