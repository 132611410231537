/**
 * @ Author: Housefolios
 * @ Create Time: 2021-09-16 13:21:50
 * @ Modified by: David Helmick
 * @ Modified time: 2023-06-14 13:48:16
 * @ Description: Dialog component in withSession that informs users that they have reached a limit. Has option to upgrade to more expensive plans
 */

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import isEmpty from 'lodash/isEmpty'

import {
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
} from '@mui/material'

import { enqueueSnackbar } from 'notistack'
import { useMutation } from '@apollo/client'
import { CREATE_SESSION } from '@/housefolios-components/LaunchpadComponents/mutations'
//import BonusProperties from '@/housefolios-components/Properties/BonusProperties';

function UpsellComponent(props) {
  const [createBillingPortalSession] = useMutation(CREATE_SESSION)
  const { openLimit, closeLimit } = props

  // const [openEditPortfolio, setEditPortfolio] = useState(false);
  // const handleOpenEditPortfolio = () => {
  //   setEditPortfolio(true);
  // }
  // const handleCloseEditPortfolio = () => {
  //   setEditPortfolio(false);
  // }

  const changePlan = async () => {
    var session = await createBillingPortalSession()

    window.open(
      session.data.createBillingPortalSession.url,
      '_self',
      '',
    )
  }

  var today = new Date()
  var resetDate = !isEmpty(props.session.me.activeOrg.subscriptions)
    ? props.session.me.activeOrg.subscriptions[0].current_period_end
      ? new Date(
          props.session.me.activeOrg.subscriptions[0]
            .current_period_end * 1000,
        )
      : null
    : props.session.me.activeOrg.parentCorp &&
      !isEmpty(props.session.me.activeOrg.parentCorp.subscriptions)
    ? props.session.me.activeOrg.parentCorp.subscriptions[0]
        .current_period_end
      ? new Date(
          props.session.me.activeOrg.parentCorp.subscriptions[0]
            .current_period_end * 1000,
        )
      : null
    : !isEmpty(
        props?.session?.me?.activeOrg?.memberProvidedSubscriptions,
      )
    ? props?.session?.me?.activeOrg?.memberProvidedSubscriptions[0]
        .current_period_end
      ? new Date(
          props?.session?.me?.activeOrg
            ?.memberProvidedSubscriptions[0].current_period_end *
            1000,
        )
      : null
    : null
  if (!resetDate) {
    var month = today.getMonth()
    resetDate = new Date()
    if (month === 11) {
      resetDate.setFullYear(resetDate.getFullYear() + 1, 0, 1)
    } else resetDate.setMonth(month + 1, 1)
  }
  if (
    (resetDate.getFullYear() > today.getFullYear() &&
      (today.getMonth() !== 11 || resetDate.getMonth() !== 0)) ||
    resetDate.getMonth() > today.getMonth() + 1 ||
    resetDate.getMonth() === today.getMonth() + 1 ||
    resetDate.getDate() > today.getDate()
  ) {
    if (resetDate.getDate() > today.getDate()) {
      resetDate.setFullYear(today.getFullYear(), today.getMonth())
      if (resetDate.getMonth() !== today.getMonth())
        resetDate.setDate(0)
    } else {
      resetDate.setFullYear(today.getFullYear(), today.getMonth() + 1)
      if (
        resetDate.getMonth() !== today.getMonth() + 1 &&
        resetDate.getMonth() !== 0
      )
        resetDate.setDate(0)
    }
  }
  const timeToReset = resetDate - today
  const daysToReset = Math.floor(timeToReset / 1000 / 60 / 60 / 24)

  const text = {
    property: {
      icon: ['far', 'house-chimney'],
      title: 'Property Limit Reached',
      text: `Your monthly limit will reset in ${daysToReset} days.`,
      bonusProperties: true,
    },
    team: {
      icon: ['far', 'user-plus'],
      title: 'Team Member Limit Reached',
      text: 'You must upgrade to add more team members.',
    },
    portfolio: {
      icon: ['far', 'folder'],
      title: 'Portfolio Limit Reached',
      text: 'You must upgrade to create more portfolios.',
    },
    marketplace: {
      icon: ['far', 'sign-hanging'],
      title: 'Marketplace Limit Reached',
      text: 'You must upgrade to create more marketplaces.',
    },
    paul: {
      image: '/images/PaulMcComasHeadShot.png',
      title: 'Upgrade Plan',
      // text: 'Paul is accepting JV deals exclusively from Growth and Pro users'
      text: 'Now accepting JV deals exclusively from Growth and Pro users',
    },
  }

  return (
    <>
      <Dialog
        open={Boolean(openLimit)}
        onClose={closeLimit}
        classes={{ paper: 'shadow-lg rounded' }}
        scroll="body"
      >
        <Card>
          <CardContent>
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div
                  style={{
                    backgroundColor: '#b3d7ff',
                    color: '#008aff',
                  }}
                  className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper m-0 d-130"
                >
                  {text[openLimit].icon && (
                    <FontAwesomeIcon
                      icon={text[openLimit].icon}
                      className="d-flex align-self-center display-2 "
                    />
                  )}
                  {text[openLimit].image && (
                    <img
                      src={text[openLimit].image}
                      alt="paulmccomas"
                      width={100}
                    />
                  )}
                </div>
              </div>
              <h4 className="font-weight-bold mt-4">
                {text[openLimit].title}
                {/* Team Member Limit Reached
                  Portfolio Limit Reached
                  Marketplace Limit Reached */}
              </h4>
              <p className="mb-0 font-size-lg text-muted">
                {text[openLimit].text}
              </p>
              <div className="pt-4">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                >
                  {/* {text[openLimit].bonusProperties && (
                    <Grid item>
                      <BonusProperties session={props.session} upsell={true} />
                    </Grid>
                  )} */}
                  <Grid item>
                    <Button
                      onClick={changePlan}
                      variant="contained"
                      color="primary"
                    >
                      See Plans
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </CardContent>
        </Card>
      </Dialog>
    </>
  )
}

export default UpsellComponent
